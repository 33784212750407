<div style="margin-top:0em; padding-top:0em; display:flex">
  <form *ngIf="aircraft_equipment_form" [formGroup]="aircraft_equipment_form">
    <div style="margin-top:-1em">
      <div class="example-action-buttons">
        <button mat-button (click)="accordion.openAll()">Expand All</button>
        <button mat-button (click)="accordion.closeAll()">Collapse All</button>
      </div>
      
      <!-- #docregion multi -->
      <mat-accordion class="example-headers-align" multi>
        <!-- #enddocregion multi -->
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
            <mat-panel-title>
              Wake Category (Item 9)
            </mat-panel-title>
            <mat-panel-description>
              <div matTooltip={{wakeCategoryTooltip}} matTooltipClass="tooltipLineBreak">{{wakeCategoryString}}</div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <table mat-table [dataSource]="dataSourceWake" class="example-container">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef width="10%"> </th>
                <td mat-cell *matCellDef="let rowWake">
                  <mat-checkbox color="primary" class="mat-checkbox mt-2" id="{{rowWake.aircraftEquipmentChildID}}" (click)="$event.stopPropagation()"
                                (change)="selectWakeChange($event,rowWake)"
                                [checked]="rowWake.selected"
                                [disabled]="isFormDisabled" >
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="shortName">
                <th mat-header-cell *matHeaderCellDef width="10%">  </th>
                <td mat-cell *matCellDef="let element"> {{element.shortName}} </td>
              </ng-container>
              <ng-container matColumnDef="longName" width="80%">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element"> {{element.longName}} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let rowWake; columns: displayedColumns;"></tr>

              <ng-container matColumnDef="groupHeader">
                <td style="background-color:#c1e4f7;  border: 0.0625em solid lightgrey;" colspan="999" mat-cell *matCellDef="let groupBy"><strong>{{groupBy.subCategory}}</strong></td>
              </ng-container>

              <tr mat-row *matRowDef="let rowWake; columns: ['groupHeader']; when: isGroup"> </tr>

            </table>
          </div>
          <mat-action-row>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
          <mat-expansion-panel-header [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
            <mat-panel-title>
              Radio Navigation Communication Equipment (Item 10a)
            </mat-panel-title>
            <mat-panel-description>
              <div matTooltip={{radioTooltip}} matTooltipClass="tooltipLineBreak">{{radioString}}</div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div style="max-height:25em;overflow-y:scroll;overflow-x:hidden">
            <table mat-table [dataSource]="dataSourceRadio" class="example-container">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef width="10%"> </th>
                <td mat-cell *matCellDef="let rowRadio">
                  <mat-checkbox color="primary" class="mat-checkbox mt-2" id="{{rowRadio.aircraftEquipmentChildID}}" (click)="$event.stopPropagation()"
                                (change)="selectRadioChange($event,rowRadio)"
                                [checked]="rowRadio.selected"
                                [disabled]="rowRadio.isDisabled || isFormDisabled">
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="shortName">
                <th mat-header-cell *matHeaderCellDef width="10%">  </th>
                <td mat-cell *matCellDef="let element"> {{element.shortName}} </td>
              </ng-container>
              <ng-container matColumnDef="longName" width="80%">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element"> {{element.longName}} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let rowRadio; columns: displayedColumns;"></tr>

              <ng-container matColumnDef="groupHeader">
                <td style="background-color:#c1e4f7;  border: 0.0625em solid lightgrey;" colspan="999" mat-cell *matCellDef="let groupBy"><strong>{{groupBy.subCategory}}</strong></td>
              </ng-container>

              <tr mat-row *matRowDef="let rowRadio; columns: ['groupHeader']; when: isGroup"> </tr>

            </table>
          </div>
          <mat-action-row>
            <button mat-button color="accent" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
          <mat-expansion-panel-header [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
            <mat-panel-title>
              Surveillance Equipment (Item 10b)
            </mat-panel-title>
            <mat-panel-description>
              <div matTooltip={{surTooltip}} matTooltipClass="tooltipLineBreak">{{surString}}</div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div style="max-height:25em;overflow-y:scroll;overflow-x:hidden">
            <table mat-table [dataSource]="dataSourceSur" class="example-container">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef width="10%"> </th>
                <td mat-cell *matCellDef="let rowSur">
                  <mat-checkbox color="primary" class="mat-checkbox mt-2" id="{{rowSur.aircraftEquipmentChildID}}" (click)="$event.stopPropagation()"
                                (change)="selectSurChange($event,rowSur)"
                                [checked]="rowSur.selected"
                                [disabled]="rowSur.isDisabled || isFormDisabled">
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="shortName">
                <th mat-header-cell *matHeaderCellDef width="10%">  </th>
                <td mat-cell *matCellDef="let element"> {{element.shortName}} </td>
              </ng-container>
              <ng-container matColumnDef="longName" width="80%">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element"> {{element.longName}} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let rowSur; columns: displayedColumns;"></tr>

              <ng-container matColumnDef="groupHeader">
                <td style="background-color:#c1e4f7;  border: 0.0625em solid lightgrey;" colspan="999" mat-cell *matCellDef="let groupBy"><strong>{{groupBy.subCategory}}</strong></td>
              </ng-container>

              <tr mat-row *matRowDef="let rowSur; columns: ['groupHeader']; when: isGroup"> </tr>

            </table>
          </div>
          <mat-action-row>
            <button mat-button color="accent" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
          <mat-expansion-panel-header [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
            <mat-panel-title>
              Performance Based Navigation (Item 18 - PBN/)
            </mat-panel-title>
            <mat-panel-description>
              <div matTooltip={{pbnTooltip}} matTooltipClass="tooltipLineBreak">{{pbnString}}</div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div style="max-height:25em;overflow-y:scroll;overflow-x:hidden">
            <table mat-table [dataSource]="dataSourcePBN" class="example-container">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef width="10%"> </th>
                <td mat-cell *matCellDef="let rowPBN">
                  <mat-checkbox color="primary" class="mat-checkbox mt-2" id="{{rowPBN.aircraftEquipmentChildID}}" #equip (click)="$event.stopPropagation()"
                                (change)="selectPBNChange($event,rowPBN)"
                                [checked]="rowPBN.selected"
                                [disabled]="isFormDisabled" >
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="shortName">
                <th mat-header-cell *matHeaderCellDef width="10%">  </th>
                <td mat-cell *matCellDef="let element"> {{element.shortName}} </td>
              </ng-container>
              <ng-container matColumnDef="longName" width="80%">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element"> {{element.longName}} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let rowPBN; columns: displayedColumns;"></tr>

              <ng-container matColumnDef="groupHeader">
                <td style="background-color:#c1e4f7;  border: 0.0625em solid lightgrey;" colspan="999" mat-cell *matCellDef="let groupBy"><strong>{{groupBy.subCategory}}</strong></td>
              </ng-container>

              <tr mat-row *matRowDef="let rowPBN; columns: ['groupHeader']; when: isGroup"> </tr>

            </table>
          </div>
          <mat-action-row>
            <button mat-button color="accent" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)">
          <mat-expansion-panel-header [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
            <mat-panel-title>
              Additional NAV Equipment (Item 18 - NAV/)
            </mat-panel-title>
            <mat-panel-description>
              {{navString}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <input type="text" class="form-control inputBox" formControlName="navRemarks"  [attr.disabled]="navDisabled ? true : null" />
          <mat-action-row>
            <button mat-button color="accent" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)">
          <mat-expansion-panel-header [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
            <mat-panel-title>
              Additional COM Equipment (Item 18 - COM/)
            </mat-panel-title>
            <mat-panel-description>
              {{comString}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <input type="text" class="form-control inputBox" style="width:100%" formControlName="comRemarks"  [attr.disabled]="comDisabled ? true : null" />
          <mat-action-row>
            <button mat-button color="accent" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)">
          <mat-expansion-panel-header [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
            <mat-panel-title>
              Additional DAT Equipment (Item 18 - DAT/)
            </mat-panel-title>
            <mat-panel-description>
              {{datString}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <input type="text" class="form-control inputBox" style="width:100%" formControlName="datRemarks"  [attr.disabled]="datDisabled ? true : null" />
          <mat-action-row>
            <button mat-button color="accent" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 7" (opened)="setStep(7)">
          <mat-expansion-panel-header [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
            <mat-panel-title>
              Additional SUR Equipment (Item 18 - SUR/)
            </mat-panel-title>
            <mat-panel-description>
              {{addlSurString}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <input type="text" class="form-control inputBox" style="width:100%" formControlName="surRemarks"  [attr.disabled]="surDisabled ? true : null" />
          <mat-action-row>
            <button mat-button color="accent" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 8" (opened)="setStep(8)">
          <mat-expansion-panel-header [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
            <mat-panel-title>
              US RNAV (Item 18 - NAV/)
            </mat-panel-title>
            <mat-panel-description>
              <div matTooltip={{rnavTooltip}} matTooltipClass="tooltipLineBreak">{{rnavString}}</div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="d-inline-flex">
            <div>
              <div>
                <div class="mr-1 text-right small form-group">Departure</div>
              </div>
              <div>
                <div class="mr-1 text-right small form-group">Enroute</div>
              </div>
              <div>
                <div class="mr-1 text-right small form-group">Arrival</div>
              </div>
            </div>
            <div>
              <div class="ml-1 form-group">
                <input type="text" *ngIf="f" formControlName="departure" class="form-control inputBox " style="width:15em" />
              </div>
              <div class="ml-1 form-group">
                <input type="text" *ngIf="f" formControlName="enroute" class="form-control inputBox " style="width:15em" />
              </div>
              <div class="ml-1 form-group">
                <input type="text" *ngIf="f" formControlName="arrival" class="form-control inputBox " style="width:15em" />
              </div>
            </div>
          </div>
          <mat-action-row>
            <button mat-button color="accent" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 9" (opened)="setStep(9)">
          <mat-expansion-panel-header [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
            <mat-panel-title>
              Emergency and Survival Equipment (Item 19)
            </mat-panel-title>
            <mat-panel-description>
              {{emergString}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div style="font-size:small">
            <div class="mt-2">


              <div class="mr-1 font-weight-bold">Aircraft Colors</div>
              <div class="form-group"><input type="text" *ngIf="f" formControlName="colors" class="form-control inputBox " style="width:15em" /></div>


              <div class="mr-2">
                <div class="mr-1 font-weight-bold">Dinghies</div>

                <div class="d-inline-flex">

                  <div class="mr-2 form-group">
                    <label for="dinghy_number">Number</label>
                    <div style="margin-top:-.5em">
                      <input type="number" *ngIf="f" formControlName="dinghy_number" class="form-control inputBox " style="width:5em" />
                    </div>
                  </div>
                  <div class="mr-2 form-group">
                    <label for="dinghy_capacity">Capacity</label>
                    <div style="margin-top:-.5em">
                      <input type="number" *ngIf="f" formControlName="dinghy_capacity" class="form-control inputBox " style="width:5em" />
                    </div>
                  </div>
                  <div class="mr-2 form-group">
                    <label for="dinghy_number">Cover</label>
                    <div class="ml-2" style="margin-top:-.45em">
                      <mat-checkbox class="mat-checkbox" color="primary" formControlName="dinghy_cover"></mat-checkbox>
                    </div>
                  </div>
                  <div class="mr-1 form-group">
                    <label for="dinghy_color">Color</label>
                    <div style="margin-top:-.5em">
                      <input type="text" *ngIf="f" formControlName="dinghy_color" class="form-control inputBox " style="width:8em" />
                    </div>
                  </div>

                </div>

              </div>
              <div class="d-inline-flex">
                <div class="mr-3">

                  <div class="mr-1 font-weight-bold">Survival Equipment</div>
                  <div class="form-group">
                    <div class="mr-1"><mat-checkbox class="mat-checkbox" color="primary" formControlName="polar">Polar</mat-checkbox></div>
                    <div class="mr-1"><mat-checkbox class="mat-checkbox" color="primary" formControlName="desert">Desert</mat-checkbox></div>
                    <div class="mr-1"><mat-checkbox class="mat-checkbox" color="primary" formControlName="maritime">Maritime</mat-checkbox></div>
                    <div class="mr-1"><mat-checkbox class="mat-checkbox" color="primary" formControlName="jungle">Jungle</mat-checkbox></div>
                  </div>
                </div>
                <div class="mr-3">

                  <div class="mr-1 font-weight-bold">Life Jackets</div>
                  <div class="form-group">
                    <div class="mr-1"><mat-checkbox class="mat-checkbox" color="primary" formControlName="light">Light</mat-checkbox></div>
                    <div class="mr-1"><mat-checkbox class="mat-checkbox" color="primary" formControlName="floures">Floures</mat-checkbox></div>
                    <div class="mr-1"><mat-checkbox class="mat-checkbox" color="primary" formControlName="uhf">UHF</mat-checkbox></div>
                    <div class="mr-1"><mat-checkbox class="mat-checkbox" color="primary" formControlName="vhf">VHF</mat-checkbox></div>
                  </div>
                </div>
                <div class="mr-2">

                  <div class="mr-1 font-weight-bold">Emergency Radio</div>
                  <div class="form-group">
                    <div class="mr-1"><mat-checkbox class="mat-checkbox" color="primary" formControlName="uhf_radio">UHF</mat-checkbox></div>
                    <div class="mr-1"><mat-checkbox class="mat-checkbox " color="primary" formControlName="vhf_radio">VHF</mat-checkbox></div>
                    <div class="mr-1"><mat-checkbox class="mat-checkbox " color="primary" formControlName="elt_radio">ELT</mat-checkbox></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-action-row>
            <button mat-button color="accent" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">End</button>
          </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
  
</div>
    
  </form>
</div>
<div *ngIf="userType=='internal'" class="d-inline-flex mt-2" style="height:3em">
  <button mat-button class="btn-high btn-primary mr-2" (click)="clickSave()">Save</button>
  <div >
    <app-success-message [successMsg]="" *ngIf="showSuccessMsg"></app-success-message>
  </div>
  <div *ngIf="errMsg!=''" class="alert alert-danger small p-0" style="white-space:pre-wrap;width:max-content">{{errMsg}}</div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
