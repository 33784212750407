<div style="height: 100%;" [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle [cdkDragDisabled]="!isDialog">
  <div style="display: inline-flex; justify-content: space-between; width: 100%;">
    <div style="margin-left:1em">
      <div style="display: inline-flex;">
        <div class="material-icons-outlined mr-1" style="margin-top: 0.3em;">
          airplane_ticket
        </div>
        <div style="margin-left: 0.5em; margin-bottom: 0.5em;">
          <h3 style="margin-bottom: 0em;">Airport</h3>
          <h5 *ngIf="isNoteRevisions" style="font-weight: 100; font-size: small;">Revision Notes Queue</h5>
          <h5 *ngIf="!isNoteRevisions" style="font-weight: 100; font-size: small;">Document Expiration Queue</h5>
        </div>
      </div>
    </div>

    <div style="display: flex;">
      <button mat-icon-button *ngIf="isDialog" (click)="openGlobalSettingsDashboardComponent()"> <i class="material-icons-outlined"
          style="font-size:xx-large;  ">arrow_circle_left</i> </button>
      <button mat-icon-button *ngIf="isDialog" (click)="clickRouteNew(routeString,'')"> <i
          class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>
      <button mat-icon-button *ngIf="isDialog" mat-dialog-close><i class="material-icons"
          style="font-size: x-large;">close </i></button>
    </div>
  </div>

  <form [formGroup]="airport_revision_notes_form">
    <div style="margin-top:0em; padding-left: 1em; padding-right:1em">
      <div style="background-color: white; padding: 1em; width: 100%; font-size: small;">
        <div>
          Filter Options
        </div>

        <div style="display: inline-flex; width: 100%; justify-content: space-between;">
          <div style="display: inline-flex;">
            <div style="display: inline-flex; border-right: 0.0625em lightgrey solid; height: 2.5em;">
              <mat-radio-group>
                <mat-radio-button style="font-size: small; padding: 0.5em 0.5em 0.5em 0em;" checked value="7" (change)="changeDaysAhead($event)">Current Week</mat-radio-button>
                <mat-radio-button style="font-size: small; padding: 0.5em;" value="15" (change)="changeDaysAhead($event)">15 Days Out</mat-radio-button>
                <mat-radio-button style="font-size: small; padding: 0.5em;" value="30" (change)="changeDaysAhead($event)">30 Days Out</mat-radio-button>
                <mat-radio-button style="font-size: small; padding: 0.5em;" value="choose" (change)="changeDaysAhead($event)">Choose Month</mat-radio-button>
              </mat-radio-group>
              <!--Month list-->
              <div *ngIf="showMonthList" style="position: absolute; float: left; margin-top: 0.25em; margin-left: 38.5em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display: flex">
                  <div style="margin-right:2em">
                    <div style="font-weight: bold; text-decoration:underline">Year</div>
                    <div>
                     
                     
                      <div style="display: flex; margin-top:0.5em">
                        <div style="margin-right:0.25em">
                          <input formControlName="selectedyearsearch"
                                 type="radio"
                                 [value]="currentYear" selected />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.15em">
                          <label>{{currentYear}}</label>
                        </div>
                      </div>
                     
                      <div style="display: flex">
                        <div style="margin-right:0.25em">
                          <input formControlName="selectedyearsearch"
                                 type="radio"
                                 [value]="nextYear"  />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.15em">
                          <label>{{nextYear}}</label>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                  <div style="min-width: 14em">
                    <div style="display:flex; justify-content:space-between">
                      <div style="font-weight: bold; text-decoration:underline; white-space: nowrap">Months:</div>
                      <div style="float:right" (click)="showMonthList=!showMonthList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                    </div>

                    <!--month -->
                    <div  style="margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                      <div  *ngFor="let v of filteredMonthList | async; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                        <div #tripmonthRefs [attr.filterID]="v.filterID"  style="display: flex; font-size: small; margin-top:-0.5em">
                          <div>
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="checkMonthChange($event, v)" [checked]="v.selected">{{v.filterName}}</mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--month  end-->


                  </div>

                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetMonth()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneMonth()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--Month list end-->  
            </div>
  
            <div style="display: inline-flex;">
              <div style="margin-left: 0.75em; margin-top: -1.2em;">
                ICAO/IATA
                <div class="input-group searchbar" style="height: 2em; margin-top: 0.2em; background-color: #eaf0fd;">
                  <div>
                    <span style="font-size: x-small; background-color: #eaf0fd;line-height:1;" class="input-group-text material-icons-outlined searchbarleft pointer"
                          cdkOverlayOrigin #trigger="cdkOverlayOrigin">search</span>
                  </div>

                  <input formControlName="airport_search" style="width:14em;height:1.5em; padding: 0em; font-size: small;"
                    class="form-control form-control-sm searchbarinput" [ngStyle]="{'background-color' : f.airport_search.disabled ? '#e9ecef' : '#eaf0fd'}" (keyup)="filterBySearchKey($event)" (mousedown)="$event.stopPropagation()"/>
                
                    <div>
                      <span style="font-size: x-small; background-color: #eaf0fd;line-height:1;" class="input-group-text material-icons-outlined searchbarright pointer" (click)="clickReset(true)"
                            cdkOverlayOrigin #trigger="cdkOverlayOrigin">close</span>
                    </div>
                </div>
                
              </div>
  
              <div style="margin-left: 1em; margin-top: -1.2em;">
                Country
                <select class="form-control" formControlName="country" style="width:14em;height:2em; padding: 0em; margin-top: 0.2em; font-size: small;"
                  [ngStyle]="{'background-color' : f.country.disabled ? '#e9ecef' : '#eaf0fd'}" (change)="changeCountry($event)">
                  <option value="" selected>-----</option>
                  <option *ngFor="let country of countryList" [value]="country.countryName">{{country.countryName}}</option>
                </select>
              </div>
              <span class="material-icons pointer" style="font-size:small;margin-top:1em;margin-left: 1em;" (click)="clickReset()">close</span>
            </div>
          </div>
  
          <div style="background-color: white; margin-top: 0.5em; font-size: small;">
            Total Records: {{totalRecords}}
          </div>
        </div>
      </div>

      <div
        style="overflow-y: auto; height: 25em; width: 100%; padding-left: 1em; padding-right: 1em; padding-bottom: 1em; background-color: white; scrollbar-color: #c1c1c1 white">
        <table *ngIf="isNoteRevisions" mat-table [dataSource]="filteredRecordList" style="width: 100%;">
          <tr mat-row >
            <ng-container matColumnDef="airport_name">
              <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: center;">
                Airport
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="align-content: center; text-align: center; color:Highlight; cursor:pointer; text-decoration:underline" 
                (click)="openAirportDetails(element.airportID)" [ngClass]="{'row-highlight': selectedRowIndex === i}">{{element.icao}}&nbsp;{{element.iata}}</td>
            </ng-container>
            <ng-container matColumnDef="revision">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                Revision
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="align-content: center; text-align: center;" 
                [ngClass]="{'row-highlight': selectedRowIndex === i}"
                [matTooltip]="element.revisionRemarks" (dblclick)="editAirportRevisionNotes(element, i)" >
                {{element.revisionDate}}
              </td>
            </ng-container>
            <ng-container matColumnDef="days_until_due">
              <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 5em;">
                Days To Due
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="align-content: center; text-align: center; width: 5em;" 
                [ngClass]="{'row-highlight': selectedRowIndex === i, 'row-alert': element.daysUntilDue < 0}"
                (dblclick)="editAirportRevisionNotes(element, i)" >
                {{element.daysUntilDue}}
              </td>
            </ng-container>
            <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef style="text-align: end;">
                Category
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="align-content: center; text-align: end; font-weight: bold;" 
                [ngClass]="{'row-highlight': selectedRowIndex === i}"
                (dblclick)="editAirportRevisionNotes(element, i)">{{element.noteType}}</td>
            </ng-container>
            <ng-container matColumnDef="notes">
              <th mat-header-cell *matHeaderCellDef style="text-align: start;">
                Notes
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="align-content: center; text-align: start;" 
                [ngClass]="{'row-highlight': selectedRowIndex === i}"
                (dblclick)="editAirportRevisionNotes(element, i)">{{element.airportNote}}</td>
            </ng-container>
            <ng-container matColumnDef="option">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                Option
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="align-content: center; text-align: center;" 
                [ngClass]="{'row-highlight': selectedRowIndex === i}">
                <button mat-button
                  class="btn-med" (click)="editAirportRevisionNotes(element, i)">Edit</button>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>

        <table *ngIf="!isNoteRevisions" mat-table [dataSource]="filteredRecordList" style="width: 100%;">
          <tr mat-row >
            <ng-container matColumnDef="airport_name">
              <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: center;">
                Airport
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="align-content: center; text-align: center; color:Highlight; cursor:pointer;" 
                (click)="openAirportDetails(element.airportID)" [ngClass]="{'row-highlight': selectedRowIndex === i}">
                <div style="display: inline-flex; text-decoration:underline">
                  {{element.icao}}
                  <div *ngIf="element.iata != undefined">&nbsp;{{element.iata}}</div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="expiration">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                Expiration
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="align-content: center; text-align: center;" 
                [ngClass]="{'row-highlight': selectedRowIndex === i}"
                [matTooltip]="element.revisionRemarks" (dblclick)="editAirportDocumentExpiration(element, i)" >
                {{element.expirationDate}}
              </td>
            </ng-container>
            <ng-container matColumnDef="days_until_due">
              <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 5em;">
                Days To Expiration
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="align-content: center; text-align: center; width: 5em;" 
                [ngClass]="{'row-highlight': selectedRowIndex === i, 'row-alert': element.daysUntilDue < 0}"
                (dblclick)="editAirportDocumentExpiration(element, i)" >
                {{element.daysUntilDue}}
              </td>
            </ng-container>
            <ng-container matColumnDef="document_name">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                Document Name
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="align-content: center;" 
                [ngClass]="{'row-highlight': selectedRowIndex === i}"
                (dblclick)="editAirportDocumentExpiration(element, i)">
                <div style="width: max-content; margin: auto; color:Highlight; cursor:pointer; text-decoration: underline;" (click)="previewDocument(element)">{{element.documentName}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="option">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                Option
              </th>
              <td mat-cell *matCellDef="let element; let i = index" style="align-content: center; text-align: center;" 
                [ngClass]="{'row-highlight': selectedRowIndex === i}">
                <button mat-button
                  class="btn-med" (click)="editAirportDocumentExpiration(element, i)">Edit</button>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
    </div>
  </form>

  <div *ngIf="isDialog" style="margin-left: 1em; margin-top: 0.5em;">
    <button mat-button class="btn-low" (click)="clickClose(true)" style="background-color:grey; color: white;">
      <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
        <div>Close</div>
      </div>
    </button>&nbsp;
  </div>
</div>
