import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { TripModel } from '../models/trip.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopManifestDocumentModel } from '../models/ground-stop-manifest-document.model';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { TripLegPersonStateModel } from '../models/trip-leg-person-state.model';
import { SelectionModel } from '@angular/cdk/collections';
import { TripPersonEditorClientDialogComponent } from './trip-person-editor-client-dialog.component';
import { TripLegClientService } from '../services/trip-leg-client.service';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { ReturnObjModel } from '../models/return-obj.model';
import { TripSummarySpecificReportDialogComponent } from '../ground-stops/trip-summary-specific-report-dialog.component';
import { ManageContactDetailsComponent } from '../persons/manage-contact-details.component';

@Component({
  selector: 'app-trip-personlist-editor-client-dialog',
  templateUrl: './trip-personlist-editor-client-dialog.component.html',
  styleUrls: ['./trip-personlist-editor-client-dialog.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('Legs per page') }
  ],
})

export class TripPersonlistEditorClientDialogComponent implements OnInit, AfterViewInit {

  dialogTitle: string;
  customerGUID: string;
  aircraftGUID: string;
  tripCodeGUID: string;

  trip_personlist_editor_client_form: UntypedFormGroup;
  loading = false;

  submitted = false;
  errMsg: string;
  errMsg2: string;
  msg: string;

  showSpin: boolean = false;
  userType: string;
  client_name: string;
  registration: string;
  trip_code: string;
  speed: number;
  route: string;
  isModified: boolean = false;

  allGroundStopList: GroundStopModel[] = [];
  activeGroundStopList: GroundStopModel[] = [];

  showSuccessMsg: boolean = false;

  displayedColumn: string[] = []; // = ['person_type', 'person_name', 'minor', 'display_leg_1', 'display_leg_2', 'display_leg_3', 'display_leg_4', 'display_leg_5', 'display_leg_6', 'display_leg_7', 'display_leg_8', 'display_leg_9', 'display_leg_10'];

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  lockdownTrip: boolean = false;
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";

  activeLegList: TripLegModel[] = [];
  displayLegList: TripLegModel[] = [];

  dataChanged: boolean;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  totalLegCount: number = 0;
  personList: GroundStopManifestModel[] = [];
  showAvaiPersonList: boolean = false;
  person_role: string = "PIC";
  picList: GroundStopManifestModel[] = [];
  avaiPICList: GroundStopManifestModel[] = [];

  sicList: GroundStopManifestModel[] = [];
  avaiSICList: GroundStopManifestModel[] = [];
  crewList: GroundStopManifestModel[] = [];
  avaiCrewList: GroundStopManifestModel[] = [];
  paxList: GroundStopManifestModel[] = [];
  avaiPaxList: GroundStopManifestModel[] = [];
  tripPersonList: TripLegPersonStateModel[] = [];
  additional_options_pic: string = "0";
  additional_options_sic: string = "0";
  additional_options_crew: string = "0";
  additional_options_pax: string = "0";
  alreadyHaveCrewOnTrip: boolean = false;
  alreadyHavePaxOnTrip: boolean = false;
  upPII: boolean = false;
  selection = new SelectionModel<TripLegPersonStateModel>(true, []);
  lastLegDatePassed: boolean = false;
  hideSelectColumn: boolean = false;
  showSaveBtn: boolean = false;
  grantAccessToManageManifestProfiles: boolean;
  successMsg = "";
  returnObj = new ReturnObjModel();
  showSubmitBtn: boolean = true;
  showCancelBtn: boolean = true;
  showPersonActionBtn: boolean = false;
  removeLable: string = "";
  canEdit: boolean = true;
  showTripSummaryReportBtn: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<TripPersonlistEditorClientDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService,
    private readonly _tripLegService: TripLegService, private readonly _dialog: MatDialog, private readonly _groundStopClientService: GroundStopClientService
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }

    this.tripCodeGUID = _data.tripCodeGUID;
    this.aircraftGUID = _data.aircraftGUID;
    if (_data.canEdit != null && _data.canEdit != undefined)
      this.canEdit = _data.canEdit;

  }

  getAllData(): Observable<any[]> {

    let request = new TripModel();
    request.customerGUID = this.customerGUID;
    request.tripCodeGUID = this.tripCodeGUID;

    let getTripResponse = this._tripLegService.getActiveTripLegListForPersonEditBytId(request);
    return forkJoin([getTripResponse]);
  }

  ngOnInit() {

    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.errMsg2 = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.msg = "";

    this.route = "";

    this.client_name = "";
    this.registration = "";
    this.trip_code = "";

    this.allGroundStopList = [];
    this.activeGroundStopList = [];

    this.activeLegList = [];
    this.showSuccessMsg = false;

    //this.lockdownTrip = false;
    this.personList = [];

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].model != null) {
          this.activeLegList = responses[0].model;
          this.trip_code = this.activeLegList[0].tripCode;
          if (this.trip_code == "") {
            this.trip_code = this.activeLegList[0].tempTripCode;
          }
          this.lockdownTrip = this.activeLegList[0].lockdownTrip;
          this.lastLegDatePassed = this.activeLegList[this.activeLegList.length - 1].nextArrivalDateTimePassed;
          let gs: GroundStopModel;
          let i: number = 0;
          this.activeLegList.forEach((x, index) => {
            gs = new GroundStopModel();
            //gs.groundStopGUID = x.groundStopGUID;
            gs.icao = x.departureICAO;
            this.activeGroundStopList.push(gs);
            i = index;
          });

          // add last active stop 
          gs = new GroundStopModel();
          //gs.groundStopGUID = this.activeLegList[i].nextArrivalGroundStopGUID;
          gs.icao = this.activeLegList[i].nextArrivalICAO;
          this.activeGroundStopList.push(gs);

          this.totalLegCount = this.activeLegList.length;
          this.totalPageNumber = Math.ceil(this.totalLegCount / this.pageSize)
          let displayLeg: TripLegModel;
          if (this.currentPageIndex == 0) {
            this.activeLegList.forEach((x, index) => {
              if (index < this.pageSize) {
                displayLeg = new TripLegModel();
                displayLeg = x;
                this.displayLegList.push(displayLeg);
              }
            });
          }

          this.displayedColumn = [];
          this.displayedColumn.push("blank");
          this.displayedColumn.push("person_type");
          this.displayedColumn.push("person_name");
          this.displayedColumn.push("minor");
          this.displayLegList.forEach((x) => {
            this.displayedColumn.push("display_leg_" + x.tripLegOrder);
          });
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (this.lockdownTrip || this.lastLegDatePassed) {
        this.hideSelectColumn = true;
      }
      this.initControls();
      this.getLegPersonList('', '', true);
      this.showSpin = false;

    });


  }

  initControls() {

    this.trip_personlist_editor_client_form = this._formBuilder.group({
      person_search_keyword: [''],
      person_role: this.person_role,
      additional_options_pic: [this.additional_options_pic],
      additional_options_sic: [this.additional_options_sic],
      additional_options_crew: [this.additional_options_crew],
      additional_options_pax: [this.additional_options_pax],
    });
  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //  this.routeRef.nativeElement.focus();
    //}, 500);
  }

  get f() { return this.trip_personlist_editor_client_form.controls; }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.pageSize = e.pageSize;
    }

    this.setDisplayLeg();
  }

  getLegPersonList(personGUID: string, personType: string, initialLoad: boolean) {
    this._authService.updateAccessTime();
    this.showSpin = true;
    let request = new TripModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.customerGUID = this.customerGUID;
    request.personGUID = personGUID;
    request.personRole = personType;
    this._tripLegService.getTripLegPersonListBytId<ResponseModel<GroundStopManifestModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (personGUID == "") {
          this.personList = response.model;

          //if (initialLoad && this.personList.length > 0) {
          //  let crewList: GroundStopManifestModel[] = [];
          //  let paxList: GroundStopManifestModel[] = [];
          //  crewList = this.personList.filter(x => x.personType != 'PAX');
          //  paxList = this.personList.filter(x => x.personType == 'PAX');
          //  if (crewList.length > 0) {
          //    this.alreadyHaveCrewOnTrip = true;
          //  }
          //  if (paxList.length > 0) {
          //    this.alreadyHaveCrewOnTrip = true;
          //  }
          //}

          this.buildTripPersonList(true);
        }
        else {
          let pl: GroundStopManifestModel[] = [];

          pl = response.model;
          let i: number = this.personList.findIndex(x => x.personGUID.toLowerCase() == personGUID.toLowerCase() && x.personType == personType);
          this.personList[i].tripLegList = pl[0].tripLegList;
          this.buildTripPersonList(true);

        }
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  buildTripPersonList(showRemoveLabel: boolean) {
    let oldselectedList: TripLegPersonStateModel[];
    oldselectedList = [];
    this.selection.selected.forEach(row => {
      oldselectedList.push(row);
    });

    this.tripPersonList = [];
    let hasEdit: boolean = false;
    let hasNewlyAdded: boolean = false;
    let s: string = '';
    let firstLegOnPerson: boolean = false;
    this.personList.forEach(p => {
      let tp: TripLegPersonStateModel;
      tp = new TripLegPersonStateModel();
      tp.person = p;
      tp.tripLegState = [];
      tp.expiredPassportList = [];
      tp.isPassportNotRequiredList = [];
      tp.personDocumentTooltipList = [];
      //this.displayLegList.forEach(l => {
      //  tp.tripLegState.push(this.getPersonTripLegState(p, l, this.personList));
      //});
      //this.tripPersonList.push(tp);

      hasEdit = false;
      hasNewlyAdded = false;
      firstLegOnPerson = false;

      this.activeLegList.forEach(l => {
        l.hasCrewPax = false;
        s = this.getPersonTripLegState(p, l, this.personList);
        if (s == 'add_circle') {
          hasNewlyAdded = true;
          if (firstLegOnPerson == false) {
            firstLegOnPerson = true;
            p.groundStopGUID = l.groundStopGUID;
            p.nextArrivalGroundStopGUID = l.nextArrivalGroundStopGUID;
            p.groundStopCountryGUID = l.departureCountryGUID;
          }
        }
        if (s == 'check_circle') {
          hasEdit = true;
        }
        tp.tripLegState.push(s);


        let index = p.tripLegList.findIndex(x => x.leg_IDs == l.leg_IDs);
        if (index >= 0) {
          tp.expiredPassportList.push(p.tripLegList[index].hasExpiredPassport);
          tp.isPassportNotRequiredList.push(p.tripLegList[index].isPassportNotRequired);
          tp.personDocumentTooltipList.push(p.tripLegList[index].personDocumentTooltip);
        }
        else {
          tp.expiredPassportList.push(null);
          tp.isPassportNotRequiredList.push(null);
          tp.personDocumentTooltipList.push(null);

        }


      });

      tp.newlyAdded = false;
      if (hasNewlyAdded && !hasEdit) {
        tp.newlyAdded = true;
      }
      if (!tp.newlyAdded) {
        p.groundStopGUID = "";
        p.nextArrivalGroundStopGUID = "";
        p.groundStopCountryGUID = "";
      }
      this.tripPersonList.push(tp);


    });

    this.selection = new SelectionModel<TripLegPersonStateModel>(true, []);
    if (oldselectedList.length > 0) {
      oldselectedList.forEach(row => {
        this.tripPersonList.forEach(x => {
          if (row.person.personGUID.toLowerCase() == x.person.personGUID.toLowerCase() && row.person.personType == x.person.personType) {
            this.selection.select(x);
          }
        });
      });
    }

    let nlist: TripLegPersonStateModel[] = [];
    nlist = this.tripPersonList.filter(x => x.person.tripLegList.length == 0);
    if (nlist.length > 0) {
      this.showSaveBtn = true;
      this.removeLable = "Remove";
    }
    else {
      this.showSaveBtn = false;
      this.removeLable = "";
    }
    //nlist = this.tripPersonList.filter(x => x.newlyAdded == true);
    //if (nlist.length > 0) {
    //  this.showSaveBtn = true;
    //  if (!showRemoveLabel) {
    //    this.removeLable = "";
    //  }
    //  else {
    //    this.removeLable = "Remove";
    //  }

    //}
    //else {
    //  this.showSaveBtn = false;
    //  this.removeLable = "";
    //}
    if (this.tripPersonList.length > 0) {

      this.tripPersonList.forEach(a => {
        let p = new GroundStopManifestModel();
        p = a.person;
        if (p.tripLegList.length > 0) {
          let i1: number = p.tripLegList.findIndex(x => x.personLegType != 'Arrival')
          if (i1 >= 0) {
            p.tripLegList.forEach(x => {
              let i: number;
              i = this.activeLegList.findIndex(y => y.groundStopGUID.toLowerCase() == x.groundStopGUID.toLowerCase() && y.nextArrivalGroundStopGUID.toLowerCase() == x.nextArrivalGroundStopGUID.toLowerCase());
              if (i >= 0 && x.personLegType != 'Arrival') {
                this.activeLegList[i].hasCrewPax = true;
              }
            })
          }
        }

      });
    }

    if (this.tripPersonList.length > 0) {
      if (this.activeLegList.findIndex(x => x.hasCrewPax == true) >= 0) {
        this.showTripSummaryReportBtn = true;
      }
      else {
        this.showTripSummaryReportBtn = false;
      }
    }
    else {
      this.showTripSummaryReportBtn = false;
    }

  }

  getPersonTripLegState(p: GroundStopManifestModel, l: TripLegModel, ps: GroundStopManifestModel[]): string {
    if (p.personType == 'PAX' && l.isDeptFerryFlight) {
      return '';
    }

    if (p.tripLegList.findIndex(x => x.leg_IDs == l.leg_IDs) >= 0) {
      return "check_circle";
    }

    //if (p.personType == 'SIC') {
    //  var i = 0;
    //}

    if (p.personType == 'PIC' || p.personType == 'SIC') {
      if (ps.findIndex(x => x.tripLegList.findIndex(z => z.leg_IDs == l.leg_IDs) >= 0 && x.personType == p.personType && x.personGUID.toLowerCase() != p.personGUID.toLowerCase()) >= 0) {
        return '';
      }
    }


    if (!l.nextArrivalDateTimePassed) {
      var f = false;
      ps.forEach(x => {
        if (x.tripLegList.findIndex(y => y.leg_IDs == l.leg_IDs) >= 0 && x.personGUID.toLowerCase() == p.personGUID.toLowerCase()) {
          f = true;
        }
      });
      if (f) {
        return '';
      }
      if (this.lockdownTrip)
        return '';
      else
        return 'add_circle';
    }
    return '';
  }

  setDisplayLeg() {

    this.displayLegList = [];
    let currentViewStartLeg: number;
    let currentViewEndLeg: number;
    currentViewStartLeg = this.currentPageIndex * this.pageSize;
    currentViewEndLeg = currentViewStartLeg + this.pageSize;
    this.displayLegList = this.activeLegList.filter((x, index) => x.tripLegOrder > currentViewStartLeg && x.tripLegOrder <= currentViewEndLeg);
    this.displayedColumn = [];
    this.displayedColumn.push("blank");
    this.displayedColumn.push("person_type");
    this.displayedColumn.push("person_name");
    this.displayedColumn.push("minor");
    this.displayLegList.forEach((x) => {
      this.displayedColumn.push("display_leg_" + x.tripLegOrder);
    });
    this.buildTripPersonList(true);
  }

  // manifest
  clickAddPerson() {
    this.getAvaiPersonList();
  }

  getAvaiPersonList() {
    //var root = this;
    this.showPersonActionBtn = false;
    this.showAvaiPersonList = true;
    this.picList = [];
    this.sicList = [];
    this.crewList = [];
    this.paxList = [];

    let request = new GroundStopManifestModel();
    request.customerGUID = this.customerGUID;
    request.personType = this.person_role;
    request.searchKeyword = this.f.person_search_keyword.value;
    request.groundStopCountryGUID = "";
    request.additionalOptions = 0;
    switch (request.personType) {
      case "PIC":
        request.additionalOptions = Number(this.additional_options_pic);
        break;
      case "SIC":
        request.additionalOptions = Number(this.additional_options_sic);
        break;
      case "Crew":
        request.additionalOptions = Number(this.additional_options_crew);
        break;
      case "PAX":
        request.additionalOptions = Number(this.additional_options_pax);
        break;
    }

    this._groundStopAdvancedService.getPersonByCustomerIdSearchKeyword<ResponseModel<GroundStopManifestModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.picList = [];
        this.sicList = [];
        this.crewList = [];
        this.paxList = [];

        if (response.model.length > 0) {
          let p: GroundStopManifestModel;
          response.model.forEach(x => {
            p = new GroundStopManifestModel();
            p.personGUID = x.personGUID;
            p.personName = x.personName;
            p.firstName = x.firstName;
            p.lastName = x.lastName;
            p.middleName = x.middleName;
            p.selected = false;
            p.personType = this.person_role;
            p.isMinorOnboard = x.isMinorOnboard;
            p.disabled = false;
            p.groundStopManifestDocumentList = x.groundStopManifestDocumentList;
            switch (this.person_role) {
              case "PIC":
                p.personTypeOrder = 1;
                this.picList.push(p);
                break;
              case "SIC":
                p.personTypeOrder = 2;
                this.sicList.push(p);
                break;
              case "Crew":
                p.personTypeOrder = 3;
                this.crewList.push(p);
                break;
              case "PAX":
                p.personTypeOrder = 4;
                this.paxList.push(p);
                break;
            }

          });
          this.setAvailablePersonList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    });

  }

  setAvailablePersonList() {
    let i: number;
    switch (this.person_role) {
      case "PIC":
        this.avaiPICList = [];
        if (this.personList.length > 0) {
          this.picList.forEach(x => {
            i = this.personList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase() && x.personType == y.personType);
            if (i == -1) {
              this.avaiPICList.push(x);
            }
          });
        }
        else {
          this.picList.forEach(x => {
            this.avaiPICList.push(x);
          });
        }
      case "SIC":
        this.avaiSICList = [];
        if (this.personList.length > 0) {
          this.sicList.forEach(x => {
            i = this.personList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase() && x.personType == y.personType);
            if (i == -1) {
              this.avaiSICList.push(x);
            }
          });
        }
        else {
          this.sicList.forEach(x => {
            this.avaiSICList.push(x);
          });
        }
        break;
      case "Crew":
        this.avaiCrewList = [];
        if (this.personList.length > 0) {
          this.crewList.forEach(x => {
            i = this.personList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase() && x.personType == y.personType);
            if (i == -1) {
              this.avaiCrewList.push(x);
            }
          });
        }
        else {
          this.crewList.forEach(x => {
            this.avaiCrewList.push(x);
          });
        }
        break;
      case "PAX":
        this.avaiPaxList = [];
        if (this.personList.length > 0) {
          this.paxList.forEach(x => {
            i = this.personList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase() && x.personType == y.personType);
            if (i == -1) {
              this.avaiPaxList.push(x);
            }
          });
        }
        else {
          this.paxList.forEach(x => {
            this.avaiPaxList.push(x);
          });
        }
        break;
    }
  }

  personSearchKeywordChange(e: any) {
    this.getAvaiPersonList();
  }

  personRoleChange(e: any) {
    this.person_role = e.target.value;
    this.f.person_search_keyword.setValue('');
    this.getAvaiPersonList();
  }

  checkPersonChange(e: any, item: GroundStopManifestModel) {
    switch (this.person_role) {
      case "PIC":
        //this.avaiPICList.forEach(x => {
        //  if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
        //    x.selected = e.checked;
        //  }
        //});
        this.avaiPICList.forEach(x => {
          if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
            if (this.avaiPICList.filter(x => x.selected == true).length < 1) {
              x.selected = e.checked;
            }
            else {
              x.selected = false;
            }
          }
        });
        if (this.avaiPICList.filter(x => x.selected == true).length == 1) {
          this.avaiPICList.forEach(x => {
            if (x.selected == false) {
              x.disabled = true;
            }
          });
        }
        else {
          if (this.avaiPICList.filter(x => x.selected == true).length == 0) {
            this.avaiPICList.forEach(x => {
              x.disabled = false;
            });
          }
        }
        if (this.avaiPICList.filter(x => x.selected == true).length > 0) {
          this.showPersonActionBtn = true;
        }
        else {
          this.showPersonActionBtn = false;
        }
        break;
      case "SIC":
        //this.avaiSICList.forEach(x => {
        //  if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
        //    x.selected = e.checked;
        //  }
        //});
        this.avaiSICList.forEach(x => {
          if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
            if (this.avaiSICList.filter(x => x.selected == true).length < 1) {
              x.selected = e.checked;
            }
            else {
              x.selected = false;
            }
          }
        });
        if (this.avaiSICList.filter(x => x.selected == true).length == 1) {
          this.avaiSICList.forEach(x => {
            if (x.selected == false) {
              x.disabled = true;
            }
          });
        }
        else {
          if (this.avaiSICList.filter(x => x.selected == true).length == 0) {
            this.avaiSICList.forEach(x => {
              x.disabled = false;
            });
          }
        }
        if (this.avaiSICList.filter(x => x.selected == true).length > 0) {
          this.showPersonActionBtn = true;
        }
        else {
          this.showPersonActionBtn = false;
        }
        break;
      case "Crew":
        this.avaiCrewList.forEach(x => {
          if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
            x.selected = e.checked;
          }
        });
        if (this.avaiCrewList.filter(x => x.selected == true).length > 0) {
          this.showPersonActionBtn = true;
        }
        else {
          this.showPersonActionBtn = false;
        }
        break;
      case "PAX":
        this.avaiPaxList.forEach(x => {
          if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
            x.selected = e.checked;
          }
        });
        if (this.avaiPaxList.filter(x => x.selected == true).length > 0) {
          this.showPersonActionBtn = true;
        }
        else {
          this.showPersonActionBtn = false;
        }
        break;
    }

  }

  clickDonePerson() {
    //this.showAvaiPersonList = false;
    this.errMsg = "";
    let data: GroundStopManifestModel[] = [];
    data = JSON.parse(JSON.stringify(this.personList));
    this.personList = [];
    let p: GroundStopManifestModel;
    let i: number = 0;

    switch (this.person_role) {
      case "PIC":
        this.avaiPICList.forEach(x => {
          if (x.selected == true) {
            p = new GroundStopManifestModel();
            p = JSON.parse(JSON.stringify(x));
            p.personTypeOrder = 1;
            p.tripLegList = [];
            data.push(p);
          }
        });

        break;
      case "SIC":
        this.avaiSICList.forEach(x => {
          if (x.selected == true) {
            p = new GroundStopManifestModel();
            p = JSON.parse(JSON.stringify(x));
            p.personTypeOrder = 2;
            p.tripLegList = [];
            data.push(p);
          }
        });
        break;
      case "Crew":
        this.avaiCrewList.forEach(x => {
          if (x.selected == true) {
            p = new GroundStopManifestModel();
            p = JSON.parse(JSON.stringify(x));
            p.personTypeOrder = 3;
            p.tripLegList = [];
            data.push(p);
          }
        });
        break;
      case "PAX":
        this.avaiPaxList.forEach(x => {
          if (x.selected == true) {
            p = new GroundStopManifestModel();
            p = JSON.parse(JSON.stringify(x));
            p.personTypeOrder = 4;
            p.tripLegList = [];
            data.push(p);
          }
        });
        break;
    }

    data.forEach(x => {
      p = new GroundStopManifestModel();
      p = JSON.parse(JSON.stringify(x));
      this.personList.push(p);
    });

    this.sortPersonList();
    this.buildTripPersonList(false);
    this.setAvailablePersonList();

    let gsmList: GroundStopManifestModel[] = [];
    let gsm: GroundStopManifestModel;
    let ig: number = -1;
    this.tripPersonList.forEach(x => {
      if (x.newlyAdded) {
        gsm = new GroundStopManifestModel();
        gsm.personGUID = x.person.personGUID;
        gsm.personName = x.person.personName;
        gsm.groundStopManifestTypeDescription = x.person.personType;
        gsm.personType = x.person.personType;
        gsm.groundStopGUID = x.person.groundStopGUID;
        gsm.applyCrewToAllGroundStops = true;
        gsm.applyPaxToAllGroundStops = true;
        gsm.customerGUID = this.customerGUID;
        gsm.tripCodeGUID = this.tripCodeGUID;
        gsm.groundStopManifestDocumentList = [];
        gsm.nextArrivalGroundStopGUID = x.person.nextArrivalGroundStopGUID;
        gsm.groundStopCountryGUID = x.person.groundStopCountryGUID;
        gsm.tripLegList = [];//p.tripLegList;
        if (gsmList.length > 0) {
          i = gsmList.findIndex(y => y.personGUID.toLowerCase() == x.person.personGUID.toLowerCase() && y.personType != x.person.personType && y.groundStopGUID.toLowerCase() == x.person.groundStopGUID.toLowerCase() && y.nextArrivalGroundStopGUID.toLowerCase() == x.person.nextArrivalGroundStopGUID.toLowerCase());
          if (i == -1) {
            gsmList.push(gsm);
          }
        }
        else
          gsmList.push(gsm);
      }
    });
    if (gsmList.length > 0) {
      this.showSpin = true;
      this.addPersonToLeg(gsmList, false, '', null);
    }

    this.showAvaiPersonList = false;
  }

  sortPersonList() {
    this.personList.sort((a, b) => (a.personTypeOrder > b.personTypeOrder) ? 1 : (a.personTypeOrder == b.personTypeOrder ? (a.personName > b.personName ? 1 : -1) : -1));
  }

  clickResetPerson() {
    this.f.person_search_keyword.setValue('');
    switch (this.person_role) {
      case "PIC":
        this.avaiPICList.forEach(x => {
          x.selected = false;
        });
        break;
      case "SIC":
        this.avaiSICList.forEach(x => {
          x.selected = false;
        });
        break;
      case "Crew":
        this.avaiCrewList.forEach(x => {
          x.selected = false;
        });
        break;
      case "PAX":
        this.avaiPaxList.forEach(x => {
          x.selected = false;
        });
        break;
    }

  }

  changePICAdditionalOptions(e: any) {
    this.additional_options_pic = e.target.value;
    this.getAvaiPersonList();

  }

  changeSICAdditionalOptions(e: any) {
    this.additional_options_sic = e.target.value;
    this.getAvaiPersonList();

  }

  changeCrewAdditionalOptions(e: any) {
    this.additional_options_crew = e.target.value;
    this.getAvaiPersonList();

  }

  changePaxAdditionalOptions(e: any) {
    this.additional_options_pax = e.target.value;
    this.getAvaiPersonList();
    this.dataChanged = true;
  }
  // manifest end

  clickEditPerson(e: any, item: TripLegPersonStateModel, l: TripLegModel, actionType: string) {
    let p: GroundStopManifestModel = new GroundStopManifestModel();
    p = item.person;
    this._authService.updateAccessTime();
    if (actionType == 'Edit') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;

      dialogConfig.data = {
        tripCodeGUID: this.tripCodeGUID, customerGUID: this.customerGUID, personGUID: p.personGUID, groundStopGUID: l.groundStopGUID, nextArrivalGroundStopGUID: l.nextArrivalGroundStopGUID,
        departureCountryGUID: l.departureCountryGUID, personType: p.personType, actionType: actionType, v: this._authService.getCurrentTimeNumber()
      };

      const dialogRef = this._dialog.open(TripPersonEditorClientDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result.refresh == true) {
          this.isModified = true;
          if (result.actionName == "") {
            this.getLegPersonList(p.personGUID, p.personType, false);
          }
          else {
            if (result.actionName == "remove") {
              let i: number;
              i = this.personList.findIndex(x => x.personGUID.toLowerCase() == p.personGUID.toLowerCase() && x.personType == p.personType);
              this.personList.splice(i, 1);
              this.buildTripPersonList(true);
            }
          }
        }
      });
    }
    else {
      this.addPersonToCurrentLeg(p, l, item);
    }
  }

  clickRemoveUnSavedPerson(e: any, p: GroundStopManifestModel) {
    this.errMsg = "";
    let i: number;
    i = this.personList.findIndex(x => x.personGUID.toLowerCase() == p.personGUID.toLowerCase() && x.personType == p.personType);
    this.personList.splice(i, 1);
    this.buildTripPersonList(true);
  }

  addPersonToCurrentLeg(p: GroundStopManifestModel, l: TripLegModel, item: TripLegPersonStateModel) {
    this.errMsg = "";
    let applyCrewToAllStops: boolean;
    applyCrewToAllStops = false;
    let applyPaxToAllStops: boolean;
    applyPaxToAllStops = false;

    let selPerson: GroundStopManifestModel;

    let gsm: GroundStopManifestModel;
    gsm = new GroundStopManifestModel();
    gsm.personGUID = p.personGUID;
    gsm.personName = p.personName;
    gsm.groundStopManifestTypeDescription = p.personType;
    gsm.personType = p.personType;
    gsm.groundStopGUID = l.groundStopGUID;
    gsm.applyCrewToAllGroundStops = applyCrewToAllStops;
    gsm.applyPaxToAllGroundStops = applyPaxToAllStops;
    gsm.customerGUID = this.customerGUID;
    gsm.tripCodeGUID = this.tripCodeGUID;
    gsm.groundStopManifestDocumentList = [];
    gsm.nextArrivalGroundStopGUID = l.nextArrivalGroundStopGUID;
    gsm.groundStopCountryGUID = l.departureCountryGUID;
    gsm.tripLegList = [];//p.tripLegList;
    gsm.isTempTrip = true;
    let request: GroundStopManifestModel[] = [];
    request.push(gsm);
    this.addPersonToLeg(request, false, '', item);

  }

  addPersonToLeg(request: GroundStopManifestModel[], loaddingSign: boolean, actionName, item: TripLegPersonStateModel) {
    this.loading = loaddingSign;
    this._tripLegService.addPersonToLeg<ResponseModel<GroundStopManifestModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.isModified = true;
          if (response.model.length == 1 && request.length == 1 && !loaddingSign) {
            this.getLegPersonList(request[0].personGUID, request[0].personType, false);
            if (item != null)
              this.selection.deselect(item);
          }
          else {
            if (response.model.length == request.length) {
              //this.showSuccessMsg = true;
              //setTimeout(() => {
              //  this.showSuccessMsg = false;
              //}, 1000);

              this.selection = new SelectionModel<TripLegPersonStateModel>(true, []);

              if (actionName == 'close') {
                this.clickClose();
              }
              else {
                this.getLegPersonList("", "", false);
              }

            }
            else {
              this.errMsg = "Some of crew / pax were not successfully saved to our system.";
              this.getLegPersonList("", "", false);
            }

          }

        }
        else {
          //
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })
  }

  checkSavePersonChange(e: any, item: TripLegPersonStateModel) {
    this.errMsg = "";
    e ? this.selection.toggle(item) : null;
  }

  //clickSave(actionName: string) {
  //  this.errMsg = "";
  //  this._authService.updateAccessTime();

  //  this.submitted = true;
  //  if (this.loading == true) {
  //    return;
  //  }
  //  this.errMsg = "";
  //  let selectedList: TripLegPersonStateModel[];
  //  selectedList = [];
  //  this.selection.selected.forEach(row => {
  //    selectedList.push(row);
  //  });

  //  if (selectedList.length == 0) {
  //    this.errMsg = "Please select at least one person.";
  //    return;
  //  }

  //  let gsmList: GroundStopManifestModel[] = [];
  //  let gsm: GroundStopManifestModel;
  //  let i: number = -1;
  //  selectedList.forEach(x => {
  //    gsm = new GroundStopManifestModel();
  //    gsm.personGUID = x.person.personGUID;
  //    gsm.personName = x.person.personName;
  //    gsm.groundStopManifestTypeDescription = x.person.personType;
  //    gsm.personType = x.person.personType;
  //    gsm.groundStopGUID = x.person.groundStopGUID;
  //    gsm.applyCrewToAllGroundStops = true;
  //    gsm.applyPaxToAllGroundStops = true;
  //    gsm.customerGUID = this.customerGUID;
  //    gsm.tripCodeGUID = this.tripCodeGUID;
  //    gsm.groundStopManifestDocumentList = [];
  //    gsm.nextArrivalGroundStopGUID = x.person.nextArrivalGroundStopGUID;
  //    gsm.groundStopCountryGUID = x.person.groundStopCountryGUID;
  //    gsm.tripLegList = [];//p.tripLegList;
  //    if (gsmList.length > 0) {
  //      i = gsmList.findIndex(y => y.personGUID.toLowerCase() == x.person.personGUID.toLowerCase() && y.personType != x.person.personType && y.groundStopGUID.toLowerCase() == x.person.groundStopGUID.toLowerCase());
  //      if (i >= 0) {
  //        this.errMsg = x.person.personName + " can not take more than one roles on same legs.";
  //        return;
  //      }
  //      else {
  //        gsmList.push(gsm);
  //      }
  //    }
  //    else
  //      gsmList.push(gsm);
  //  });

  //  if (this.errMsg != "") {
  //    return;
  //  }

  //  this.addPersonToLeg(gsmList, true, actionName, null);

  //}

  clickManagePerson() {
    // window.open('/persons/customer-personlist?customerGUID=' + this.customerGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGUID, tripCodeGUID: this.tripCodeGUID };
    config.position = { left: "10px", top: "140px" };

    config.height = "auto";
    config.maxHeight = "80em";
    config.width = "81em";
    this._dialog.open(ManageContactDetailsComponent, config);
  }

  clickClose() {
    this._dialogRef.close(this.returnObj);
    //if (this.selection.selected.length > 0) {
    //  let s = "Do you want to save the current selections before close the page?";
    //  this.confirmDialogRef = this._dialogService.openConfirmDialog(s);
    //  this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
    //    this.eventSubscription.unsubscribe();
    //    if (result) {
    //      this.clickSave('close');
    //    }
    //    else {
    //      this._dialogRef.close(this.isModified);
    //    }
    //  });
    //}
    //else {
    //  this._dialogRef.close(this.isModified);
    //}
  }

  clickSubmit() {
    this.errMsg = "";
    this._authService.updateAccessTime();

    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    let s = "Are you sure that you want to submit the trip to FPI?";
    this.confirmDialogRef = this._dialogService.openConfirmDialog(s);
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        this.showCancelBtn = false;
        this.submitTrip();
      }
      else {
        this._dialogRef.close(this.returnObj);
      }
    });

  }

  submitTrip() {
    this.loading = true;
    let request = new TripModel();
    request.customerGUID = this.customerGUID;
    request.tripCodeGUID = this.tripCodeGUID;

    this._groundStopClientService.createTripCodeFromTempTripCodeBytId<ResponseModel<TripModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let t = new TripModel();
          t = response.model;
          if (t.tripCode != "") {
            this.isModified = true;
            this.returnObj.refresh = this.isModified;
            this.returnObj.newId = t.tripCodeGUID.toLowerCase();
            this.returnObj.aircraftGUID = this.aircraftGUID;
            this.returnObj.customerGUID = this.customerGUID;
            this.tripCodeGUID = t.tripCodeGUID.toLowerCase();
            this.successMsg = "Your trip has been successfully submitted to our system";
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
              this.clickClose();
            }, 1000);
          }
          else {
            this.errMsg = "Failed to submit the trip to our system. Please try again later.";
            this.showCancelBtn = true;
          }
          //this.showSuccessMsg = true;
          //this.successMsg = "Your trip has been successfully submitted to our system";
          //setTimeout(() => {
          //  this.showSuccessMsg = false;
          //  this._dialogRef.close(false);
          //}, 1000);

        }
        else {
          this.errMsg = "Failed to submit the trip to our system. Please try again later.";
          this.showCancelBtn = true;
        }
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
          this.showCancelBtn = true;
        }
      }
      this.loading = false;
      //this._dialogRef.close(this.returnObj);
    })

  }

  clickCancel() {
    this.errMsg = "";
    let s = "Are you sure that you want to cancel the trip?";
    this.confirmDialogRef = this._dialogService.openConfirmDialog(s);
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        this.showSubmitBtn = false;
        this.cancelTrip();
      }
      else {
        this.returnObj.refresh = false;
        //this._dialogRef.close(this.returnObj);
      }
    });

  }


  cancelTrip() {
    let request = new TripModel();
    request.tripCodeGUID = this.tripCodeGUID;

    this._groundStopClientService.cancelTripBytId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.showSuccessMsg = true;
          this.returnObj.refresh = false;
          this.successMsg = "Your trip has been successfully removed from our system";
          setTimeout(() => {
            this.showSuccessMsg = false;
            this._dialogRef.close(this.returnObj);
          }, 1000);

        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
          this._dialogRef.close(this.returnObj);
        }
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
          this._dialogRef.close(this.returnObj);
        }
      }
      //this.loading = false;
      //this._dialogRef.close(this.returnObj);
    })


  }

  getPersonDocListTooltip(p: GroundStopManifestModel, l: TripLegModel) {
    let s: string = "";
    let i: number;

    if (p.capacityID == 1) {
      if (p.tripLegList.length > 0) {
        i = p.tripLegList.findIndex(x => x.groundStopGUID.toLowerCase() == l.groundStopGUID.toLowerCase() && x.nextArrivalGroundStopGUID.toLowerCase() == l.nextArrivalGroundStopGUID.toLowerCase());
        if (i >= 0) {
          if (p.tripLegList[i].personLegType != '' && p.tripLegList[i].personLegType !== 'Departure,Arrival') {
            s = p.tripLegList[i].personLegType.substring(0, 1) + ": ";
          }
          s += p.personType + " " + p.lastName + ", ";
          if (p.firstName != "") {
            s += p.firstName + " ";
          }
          if (p.middleName != "") {
            s += p.middleName;
          }
          s += "\n";
          if (p.gender != "") {
            s += "Gender: " + p.gender.substring(0, 1) + "\n";
          }
          if (p.dob != "") {
            s += "DOB: " + p.dob + "\n";
          }
          p.tripLegList[i].groundStopManifestDocumentList.forEach(y => {
            if (y.legType != "") {
              s += y.legType.substring(0, 1) + ": ";
            }
            s += y.documentTypeDescription + " " + y.documentNumber + " " + y.isO2Letter + " ";
            if (y.documentDOE != "") {
              s += y.documentDOE;
            }
            s += "\n";
          });
        }
      }
    }

    return s;
  }
  clickTripSummary(leg: TripLegModel) {
    let gsId: string = "";

    if (leg != null) {
      gsId = leg.groundStopGUID;
    }
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.restoreFocus = false;
    config.data = { tripCodeGUID: this.tripCodeGUID, customerGUID: this.customerGUID, groundStopGUID: gsId, includeCrewPax: true };
    this._dialog.open(TripSummarySpecificReportDialogComponent, config);
  }

}
