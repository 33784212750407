<div *ngIf="upPII" style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3em;">
      <i class="material-icons-outlined" style="font-size: x-large;cursor: pointer; color: gray">event_available</i>
    </div>
    <div style="margin-top:0.3125em;">
      <div><h5>Trip Leg</h5></div>
      <div style="display: flex; margin-top:-0.5em">
        <div style="margin-right:1em">
          <div style="font-size: small">Registration</div>
          <div style="font-weight: bold; font-size: small">{{registration}}</div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">Trip Code</div>
          <div style="font-weight: bold; font-size: small">{{trip_code}}</div>
        </div>
        <!--<div style="margin-right:1em">
          <div style="font-size: small">Business Stop</div>
          <div style="font-size: small; display: flex"><div>{{prevICAO!=''?prevICAO+'-':''}}</div><div style="font-weight: bold">{{icao}}</div><div>{{nextICAO!=''?'-'+nextICAO:''}}</div></div>
        </div>-->
      </div>
    </div>
  </div>
  <div>
    <form *ngIf="leg_detail_editor_form" [formGroup]="leg_detail_editor_form">
      <div style="padding-left: 1em; padding-right: 1em;">
        <div style="background-color: white; padding-left:1em">
          <!--<div style="margin-right:1em">
            <label for="ground_stop_type_select" style="font-size:small">Business Stop Type:&nbsp;</label>
            <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
              <div style="margin-top:0em;">
                <select *ngIf="f" formControlName="ground_stop_type_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width: 13em" [style.background-color]="groundStopTypeColor==''? '#eaf0fd':groundStopTypeColor" (change)="gsTypeChange($event)">
                  <option *ngFor="let gst of groundStopTypeList" [ngValue]="gst.groundStopTypeGUID" [style.background-color]="gst.colorCode==''? 'white':gst.colorCode">{{gst.groundStopTypeDescription}}</option>
                </select>
              </div>
            </div>
          </div>-->
          <!--<div style="height:0.5em; background-color: #f8f8f8; margin-left:-1em"></div>-->
          <div style="display: flex">
            <!--departure-->
            <div style="padding-top:0.5em; padding-bottom:0.5em; padding-left:1em; padding-right:1em">
              <div style="display:flex; ">
                <div style="margin-left:0em; margin-right:0.5em; "><i class="material-icons" style="color:black; background-color:lightskyblue; border: black 0.0625em solid; font-size:x-large">flight_takeoff</i></div>
                <div style="font-weight: 500; color: Highlight">Departure {{departureICAO}}{{departureIATA!=''?' / '+departureIATA:''}}</div>
              </div>
              <div style="display:flex">
                <div style="font-size:small" class="rotateUTC-90">UTC</div>
                <div style="margin-top:-0.25em; margin-left:-0.75em; width:8em">
                  <label for="departureDateUTC" style="font-size:small">Date</label>
                  <div style="display:flex;margin-top:-0.5em">
                    <input type="text" [matDatepicker]="departureDateUTCpicker" #departureDateUTC placeholder="MM/DD/YYYY" formControlName="departureDateUTC" class="form-control" [ngClass]="{ 'is-invalid': submittedDepDate && f.departureDateUTC.errors }" style="background-color: #eaf0fd; padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;" (dateChange)="dateUTCChange('D', $event)" />
                    <mat-datepicker-toggle matSuffix [for]="departureDateUTCpicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                    <mat-datepicker #departureDateUTCpicker [startAt]="depStartAt"></mat-datepicker>
                  </div>
                  <div *ngIf="submittedDepDate && f.departureDateUTC.errors" style="margin-top:0em">
                    <div *ngIf="f.departureDateUTC.errors.isValidDate==false" style="margin-left:0em; background-color: #f9d2de;padding-left:0.25em;margin-top:-0.5em; width:7em; font-size:small; display:flex">Invalid date.</div>
                  </div>
                </div>
                <div style="margin-top:-0.25em; margin-left:0em; width:5em;">
                  <label for="departureTimeUTC" style="font-size:small">Time</label>
                  <div style="display:flex;margin-top:-0.5em">
                    <input type="text" matInput placeholder="HH:MM" formControlName="departureTimeUTC" maxlength="5" class="form-control" [ngClass]="{ 'is-invalid': f.departureTimeUTC.errors }" style="background-color: #eaf0fd; width:4em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeUTCChange('D', $event)" />
                  </div>
                  <div *ngIf="f.departureTimeUTC.errors">
                    <div *ngIf="f.departureTimeUTC.errors.pattern" style="background-color: #f9d2de;margin-left: 0em; padding-left:0.25em;margin-top:0em; width:6em; font-size:small">Invalid time</div>
                  </div>
                </div>
                <div style="margin-right:0.5em;margin-top:-.25em">
                  <label for="ete" style="font-size:small">ETE Hrs</label>
                  <div style="display:flex;margin-top:-0.5em">
                    <input type="text" formControlName="ete" class="form-control" placeholder="HH:MM" [ngClass]="{ 'is-invalid': f.ete.errors }" style="background-color: #eaf0fd; width:6em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em" (change)="eteTimeChange($event)" />
                  </div>
                  <div *ngIf="f.ete.errors">
                    <div *ngIf="f.ete.errors.pattern" style="background-color: #f9d2de;margin-left: 0em; padding-left:0.25em;margin-top:0em; width:6em; font-size:small">Invalid ETE</div>
                  </div>
                </div>
              </div>
              <div *ngIf="submittedDepDate && f.departureDateUTC.errors" style="margin-top:0em">
                <div *ngIf="f.departureDateUTC.errors.validateCompareArrival_DepartureDateTime==false" style="background-color: #f9d2de;padding-left:0.25em;margin-top:-0.5em; font-size:small; display:flex; width:17em">Departure date must be earlier than arrival date.</div>
              </div>
              <div *ngIf="f.departureTimeUTC.errors">
                <div *ngIf="f.departureTimeUTC.errors.validateCompareArrival_DepartureDateTime==false" style="background-color: #f9d2de;padding-left:0.25em;margin-top:-0.5em; width:17em; font-size:small; display:flex">Departure time must be ealier than arrival time.</div>
              </div>
              <div style="display:flex">
                <div style="font-size:small" class="rotateLocal-90">Local</div>
                <div style="margin-top:0.95em; margin-left:-0.5em">
                  <!--<label for="departureDateLocal" style="font-size:small">Date (mm/dd/yyyy)</label>-->
                  <div style="display:flex;margin-top:-0.5em">
                    <input type="text" placeholder="MM/DD/YYYY" formControlName="departureDateLocal" class="form-control" readonly style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small" maxlength="10" />
                  </div>
                </div>
                <div style="margin-top:0.85em; margin-left:1.85em">
                  <!--<label for="departureTimeLocal" style="font-size:small">Time (hh:mm)</label>-->
                  <div style="display:flex;margin-top:-0.35em">
                    <input type="text" matInput placeholder="HH:MM" formControlName="departureTimeLocal" maxlength="5" class="form-control" readonly style="width:4em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em" />
                  </div>
                </div>
              </div>
              <div style="margin-top:0.5em;display: flex">
                <div style="margin-right:0.5em">
                  <label for="departure_status_select" style="font-size:small">Departure Status</label>
                  <div style="margin-top: -0.5em">
                    <select *ngIf="f" formControlName="departure_status_select" [style.background-color]="selectedDepartureStatusColor==''? '#eaf0fd':selectedDepartureStatusColor" style="font-size: small;border-radius:0.25em; border-color: lightgrey" (change)="arrivalDepartureStatusChange('D', $event)">
                      <option *ngFor="let ad of arrival_departureStatusList" [ngValue]="ad.arrival_DepartureStatusID" [style.background-color]="ad.colorCode==''? 'white':ad.colorCode">{{ad.arrival_DepartureStatusDescription}}</option>
                    </select>
                  </div>
                </div>
                <div style="margin-right:0.5em;margin-top:1.25em">
                  <mat-slide-toggle style="transform:scale(.8)" color="primary" (change)="isOnHold=!isOnHold" [checked]="isOnHold" formControlName="isOnHold">On Hold Leg</mat-slide-toggle>
                </div>

              </div>
              <div style="margin-top:0.5em;display: flex">

                <div style="margin-right:0.5em">
                  <label for="dep_fartype_select" style="font-size:small">FAR Type</label>
                  <div style="margin-top:-0.5em;">
                    <select *ngIf="f" formControlName="dep_fartype_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; background-color:#eaf0fd ">
                      <option value="0" selected></option>
                      <option *ngFor="let fr of farTypeList" [ngValue]="fr.farTypeID">{{fr.farTypeDescription}}</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label for="dep_CallSign" style="font-size:small">Call Sign</label>
                  <div style="display:flex;margin-top:-0.35em">
                    <input type="text" formControlName="dep_CallSign" class="form-control" style="background-color: #eaf0fd; width:6em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em" />
                  </div>
                </div>
              </div>
              <ng-template #tipContent>
                <div class="d-inline-flex">
                  <div>
                    <div *ngIf="(deptCrewList.length>0 || deptPaxList.length>0) && (isCrewMismatch || isPaxMismatch)"><b>Departure</b></div>
                    <div *ngIf="deptCrewList.length>0">Crew</div>
                    <div *ngFor="let m of deptCrewList">
                      {{m.personType.substring(0,1)}}: {{m.personName}}
                    </div>
                    <div *ngIf="deptPaxList.length>0">Pax</div>
                    <div *ngFor="let m of deptPaxList">
                      {{m.personName}}
                    </div>
                  </div>
                  <div class="ml-2" *ngIf="isCrewMismatch || isPaxMismatch">
                    <div *ngIf="arrvCrewList.length>0 || arrvPaxList.length>0"><b>Arrival</b></div>
                    <div *ngIf="arrvCrewList.length>0">Crew</div>
                    <div *ngFor="let m of arrvCrewList">
                      {{m.personType.substring(0,1)}}: {{m.personName}}
                    </div>
                    <div *ngIf="arrvPaxList.length>0">Pax</div>
                    <div *ngFor="let m of arrvPaxList">
                      {{m.personName}}
                    </div>
                  </div>
                </div>
              </ng-template>
              <div style="margin-top:0.5em;display: flex" [ngbTooltip]="tipContent" tooltipClass="ngb-tooltip" placement="start">

                <div style="margin-right:0.5em">
                  <label style="font-size:small">PIC: {{picCount}}</label>
                </div>
                <div style="margin-right:0.5em">
                  <label style="font-size:small">SIC: {{sicCount}}</label>
                </div>
                <div style="margin-right:0.5em">
                  <label style="font-size:small">CREW: {{crewCount}}</label>
                </div>
                <div style="margin-right:0.5em">
                  <label style="font-size:small">PAX: {{paxCount}}</label>
                </div>
              </div>
              <div style="margin-top:0.5em;display: flex">

                <div>
                  <div style="margin-right:2em; display:flex">
                    <mat-slide-toggle color="primary" formControlName="dep_ferry_flight" style="transform: scale(0.8)"></mat-slide-toggle><div style="margin-top:0em; margin-left:0.25em; font-size: small">Ferry Flight</div>
                  </div>
                  <div style="margin-right:2em; display:flex">
                    <mat-slide-toggle color="primary" formControlName="dep_pets" style="transform: scale(0.8)"></mat-slide-toggle><div style="margin-top:0em; margin-left:0.25em; font-size: small">Pets On Board</div>
                  </div>
                  <div style="display:flex">
                    <mat-slide-toggle color="primary" formControlName="dep_weapons" style="transform: scale(0.8)"></mat-slide-toggle><div style="margin-top:0em; margin-left:0.25em; font-size: small">Weapons On Board</div>
                  </div>
                </div>
              </div>
            </div>
            <!--departure end-->
            <div style="width:0.5em; background-color: #f8f8f8"></div>
            <!--arrival-->
            <div style="padding-top:0.5em; padding-bottom:0.5em; padding-left:1em; padding-right:1em">
              <div style="display:flex; ">
                <div style="margin-left:0em; margin-right:0.5em; "><i class="material-icons" style="color:black; background-color:lightskyblue; border: black 0.0625em solid; font-size:x-large">flight_land</i></div>
                <div style="font-weight: 500; color: Highlight">Arrival {{arrivalICAO}}{{arrivalIATA!=''?' / '+arrivalIATA:''}}</div>
              </div>
              <div style="display:flex">
                <div style="font-size:small" class="rotateUTC-90">UTC</div>
                <div style="margin-top:-0.25em; margin-left:-0.75em; width:8em">
                  <label for="arrivalDateUTC" style="font-size:small">Date</label>
                  <div style="display:flex;margin-top:-0.5em">
                    <input type="text" [matDatepicker]="arrivalDateUTCpicker" #arrivalDateUTC placeholder="MM/DD/YYYY" formControlName="arrivalDateUTC" class="form-control" [ngClass]="{ 'is-invalid': submittedArrDate && f.arrivalDateUTC.errors }" style="background-color: #eaf0fd; padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;" maxlength="10" (dateChange)="dateUTCChange('A', $event)" />
                    <mat-datepicker-toggle matSuffix [for]="arrivalDateUTCpicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                    <mat-datepicker #arrivalDateUTCpicker [startAt]="arrStartAt"></mat-datepicker>
                  </div>
                  <div *ngIf="submittedArrDate && f.arrivalDateUTC.errors" style="margin-top:0em">
                    <div *ngIf="f.arrivalDateUTC.errors.isValidDate==false" style="margin-left:0em; background-color: #f9d2de;padding-left:0.25em;margin-top:-0.5em; width:7em; font-size:small; display:flex">Invalid date.</div>
                  </div>
                </div>
                <div style="margin-top:-0.25em; margin-left:0em; width:5em;">
                  <label for="arrivalTimeUTC" style="font-size:small">Time</label>
                  <div style="display:flex;margin-top:-0.5em">
                    <input type="text" matInput placeholder="HH:MM" formControlName="arrivalTimeUTC" maxlength="5" class="form-control" [ngClass]="{ 'is-invalid': f.arrivalTimeUTC.errors }" style="background-color: #eaf0fd; width:4em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeUTCChange('A', $event)" />
                  </div>
                  <div *ngIf="f.arrivalTimeUTC.errors">
                    <div *ngIf="f.arrivalTimeUTC.errors.pattern" style="background-color: #f9d2de;margin-left: 0em; padding-left:0.25em;margin-top:0em; width:6em; font-size:small">Invalid time</div>
                  </div>
                </div>
              </div>
              <div *ngIf="submittedDepDate && f.departureDateUTC.errors" style="margin-top:0em">
                <div *ngIf="f.departureDateUTC.errors.validateCompareArrival_DepartureDateTime==false" style="background-color: #f9d2de;padding-left:0.25em;margin-top:-0.5em; font-size:small; display:flex; width:17em">Departure date must be later than arrival date.</div>
              </div>
              <div *ngIf="f.arrivalTimeUTC.errors">
                <div *ngIf="f.arrivalTimeUTC.errors.validateCompareArrival_DepartureDateTime==false" style="background-color: #f9d2de;padding-left:0.25em;margin-top:-0.5em; width:17em; font-size:small; display:flex">Departure time must be later than arrival time.</div>
              </div>
              <div style="display:flex">
                <div style="font-size:small" class="rotateLocal-90">Local</div>
                <div style="margin-top:0.95em; margin-left:-0.5em">
                  <!--<label for="departureDateLocal" style="font-size:small">Date (mm/dd/yyyy)</label>-->
                  <div style="display:flex;margin-top:-0.5em">
                    <input type="text" placeholder="MM/DD/YYYY" formControlName="arrivalDateLocal" class="form-control" readonly style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small" maxlength="10" />
                  </div>
                </div>
                <div style="margin-top:0.85em; margin-left:1.85em">
                  <!--<label for="departureTimeLocal" style="font-size:small">Time (hh:mm)</label>-->
                  <div style="display:flex;margin-top:-0.35em">
                    <input type="text" matInput placeholder="HH:MM" formControlName="arrivalTimeLocal" maxlength="5" class="form-control" readonly style="width:4em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em" />
                  </div>
                </div>
              </div>
              <div style="margin-top:0.5em;display: flex">
                <div style="margin-right:0.5em">
                  <label for="arrival_status_select" style="font-size:small">Arrival Status</label>
                  <div style="margin-top: -0.5em">
                    <select *ngIf="f" formControlName="arrival_status_select" [style.background-color]="selectedArrivalStatusColor==''? '#eaf0fd':selectedArrivalStatusColor" style="font-size: small;border-radius:0.25em; border-color: lightgrey" (change)="arrivalDepartureStatusChange('D', $event)">
                      <option *ngFor="let ad of arrival_departureStatusList" [ngValue]="ad.arrival_DepartureStatusID" [style.background-color]="ad.colorCode==''? 'white':ad.colorCode">{{ad.arrival_DepartureStatusDescription}}</option>
                    </select>
                  </div>
                </div>


              </div>
              <div style="margin-top:2em">
                <mat-slide-toggle color="primary" formControlName="tech_stop_select" style="transform: scale(0.8)">Tech Stop</mat-slide-toggle>
                <!--<label for="tech_stop_select" style="font-size:small;margin-top:0em">Tech Stop</label>
  <div style="margin-top:-0.5em;margin-bottom:0em; margin-left:0em">
    <select *ngIf="f" formControlName="tech_stop_select" style="font-size: small;background-color:#eaf0fd; width:4em; border-radius:0.25em; border-color: lightgrey">
      <option value="1">Yes</option>
      <option value="0" selected>No</option>
    </select>
  </div>-->
              </div>
              <div style="height:1.5em"></div>
              <div *ngIf="isCrewMismatch">
                <div class="d-inline-flex">
                  <div style="margin-top:.2em"><i class="material-icons-two-tone yellow mr-1" style="font-size: large;">warning</i></div> <div style="font-weight: 500; color: Highlight">Crew Mismatch</div>
                </div>
              </div>
              <div *ngIf="isPaxMismatch">
                <div class="d-inline-flex">
                  <div style="margin-top:.2em"><i class="material-icons-two-tone yellow mr-1" style="font-size: large;">warning</i></div> <div style="font-weight: 500; color: Highlight">Pax Mismatch</div>
                </div>
              </div>
            </div>


            <!--arrival end-->
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="showSpin" style="min-width: 20em">
    <app-spin></app-spin>
  </div>
  <div>
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
      <div style="margin-right:0.5em">
        <button type="button" mat-button class="btn-high" *ngIf="loading==false && showSuccessMsg==false && !lockdownTrip && !showSpin" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Submit</div></div></button>&nbsp;
        <button type="button" mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>&nbsp;
        <!--<button type="button" mat-button class="btn-low" (click)="cancelAirport()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">block</i><div>Cancel Airport</div></div></button>-->
      </div>
      <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
      <div *ngIf="errMsg2!=''" class="d-block" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg2}}</label></div>

      <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]="successMsg"></app-success-message>
      </div>

    </div>

  </div>
</div>

