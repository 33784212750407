import { v4 as uuidv4 } from 'uuid';
import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryModel } from '../models/country.model';
import { ResponseModel } from '../models/response.model';
import { CountryService } from '../services/country.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CountryDetailsComponent } from './country-details.component';
import { MatLegacyDialogRef as MatDialogRef,MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-country-locator',
    templateUrl: './country-locator.component.html',
    styleUrls: ['./country-locator.component.css']
})
/** country-locator component*/
export class CountryLocatorComponent implements OnInit {
  isLoading: boolean = false;
  icao: string = "";
  countryID: number = 0;
  country_locator_form: UntypedFormGroup;
  searchType: string = "country";
  countryList: CountryModel[];
  countryErrorList: string[] = [];
  searchKey: string = null;
  errMsg: string="";
  submitted: boolean = false;
  minRunway: number = 5000;

  uniqueId = uuidv4();
  username: string;
  dialogRefs:[{countryId:number, dialogRef: MatDialogRef<CountryDetailsComponent, any>}]=[{countryId:null,dialogRef:null}];
    /** country-locator ctor */
  constructor(private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, 
    private readonly _countryService: CountryService,private readonly _dialog: MatDialog,
    private readonly _commonService: CommonService, @Inject(MAT_DIALOG_DATA) private _data: any, ) {
      this.username = _data.username;
  }

    ngOnInit() {
      this.initControls();
      this.getCountryList();
  }

  initControls() {
    this.country_locator_form = this._formBuilder.group({
      search_by:['',Validators.required]

    });
  }

  get f() { return this.country_locator_form.controls; }

  getCountryList() {
    this.submitted = true;
    this.errMsg = "";
    this.countryErrorList = []; 
    // if (this.country_locator_form.valid) {
      this.isLoading = true;
      let request = new CountryModel();
      this.searchKey =  this.f.search_by.value;  //this.getSearchKey(); 
      request.searchKey = this.searchKey != undefined ? this.searchKey : null;
      this._countryService.getCountryBySearchKey<ResponseModel<CountryModel[]>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {            
            this.countryList = response.model;
            if (this.countryList.length > 0) {

              this.countryID = this.countryList[0].countryID; 
            }

            else
              this.errMsg = "No results found";
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          } 
          let searchKeys = request.searchKey.split(',');
          searchKeys?.forEach((key) => { 
            const matchingDocument = this.countryList.findIndex(ap => ap.countryName && ap.countryName?.toLowerCase().includes(key?.toLowerCase()));

            if(matchingDocument == -1 && this.searchType == 'country'){
              this.countryErrorList.push(key);
            }
          });
        }
        else
          this.errMsg = "No results found";
        this.isLoading = false;
      });
      this.submitted = false;

    // }
    
  }
  getCountryList2() {
    // this._customerService.getCustomerHomebaseAirportList<ResponseModel<CustomerHomebaseAirportModel[]>>(request).subscribe(response => {

    let countryResponse = this._commonService.getCountryList<ResponseModel<CountryModel[]>>().subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.countryList = response.model;
          this.countryID = this.countryList[0].countryID;
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }

    });
 
  }

  selectCountry(a: any) {
    this.icao = a.icao;
   // this.countryID = a.countryID;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    // dialogConfig.minWidth = '60%';
    // dialogConfig.maxWidth = '60%';
    // dialogConfig.width = "58em";
    // dialogConfig.maxHeight = '43em';
    dialogConfig.position = { right: "100px", top: "40px"};

    dialogConfig.data = { countryID: a.countryID, username: this.username };
    if (a.selected) {
      let dRef = this.dialogRefs.filter(d => d.countryId == a.countryID)[0]?.dialogRef;
      if (dRef != null && dRef != undefined)
        dRef.close();
    }
    
    setTimeout(() => {
      const dialogRef = this._dialog.open(CountryDetailsComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        let idx = this.dialogRefs.findIndex(d => d.countryId == a.countryID);
        this.dialogRefs.splice(idx, 1);
        a.selected = false;
      });

      this.dialogRefs.push({ countryId: a.countryID, dialogRef: dialogRef });

      a.selected = true;
    }, 250);
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return (value / 1000) + 'k';
    }

    return value;
  }

  getSearchKey(): string {
    let searchVal: string = this.f.search_by.value;
    if (searchVal) {
      searchVal = searchVal.replaceAll('-', ',');
      searchVal = searchVal.replaceAll(' ', ',');
    }
    return searchVal;
  }

  clearInputBox() {
    this.f.search_by.setValue("");
    this.icao = "";
    this.countryID = null;
    this.countryList = [];
    this.countryErrorList = [];
    this.getCountryList();
  }

  isMinimized: boolean = false;
  conditionValue: number = 3;



 
  
  minimizeDialog() {
    this.isMinimized = true;
 
    //this.displayMessage = this.messageGUID == ""? 'New Message': ((this.subject.length>0 && this.subject.length>14 )? this.subject.substr(0,14) : this.subject )

    if (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-container') != undefined &&
      document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-container') != null)
      (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-container') as HTMLElement).style.transform = 'none';

    
      // (document.getElementsByTagName('mat-dialog-container')[0] as HTMLElement).style.height = '0';
      // (document.querySelector('.custom-dialog-container .mat-dialog-container') as HTMLElement).classList.add('minimizeStyles'); // = 'minimizeStyles'
      (document.querySelector('.custom-dialog-container .mat-dialog-container') as HTMLElement).className = 'minimizeStyles'

  } 
  maximizeDialog() {
    this.isMinimized = false;

    // (document.querySelector('.custom-dialog-container .mat-dialog-container') as HTMLElement).style.all = 'initial';
    // (document.querySelector('.mat-dialog-container') as HTMLElement).style.height = '100%';
    // (document.querySelector('.mat-dialog-container') as HTMLElement).style.width = '100%';
    // (document.querySelector('.mat-dialog-container') as HTMLElement).style.background = '#f8f8f8';
    (document.querySelector('.minimizeStyles')  ).className = 'custom-dialog-container';
    (document.getElementsByTagName('mat-dialog-container')[0] as HTMLElement).classList.add('mat-dialog-container');
    // (document.querySelector('.minimizeStyles') as HTMLElement).classList.remove("minimizeStyles"); // = 'maximizeStyles'

  }

}

