import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { VendorService } from '../services/vendor.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { DocumentTypeModel } from '../models/document-type.model';
import { VendorServiceDocumentTypeModel } from '../models/vendor-service-document-type.model';
import { VendorDocumentModel } from '../models/vendor-document.model';
import { GlobalConstant } from '../common-utility/global-constant';
import { FileItem, FileUploader, ParsedResponseHeaders, FileUploadModule, FileSelectDirective } from 'ng2-file-upload';
import { FileModel } from '../models/file.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ServiceTypeModel } from '../models/service-type.model';

@Component({
  selector: 'app-vendor-document-edit-dialog',
  templateUrl: './vendor-document-edit-dialog.component.html',
  styleUrls: ['./vendor-document-edit-dialog.component.css']
})

export class VendorDocumentEditDialogComponent implements OnInit, AfterViewInit {
  @Input() dialogTitle: string;
  @Input() vendorDocumentGUID: string;
  @Input() vendorGUID: string;
  vendor_document_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsgDocType: string;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  uploadinDoc: boolean = false;
  documentTypeList: DocumentTypeModel[];
  avaiDocumentTypeList: DocumentTypeModel[];
  selDocumentTypeList: DocumentTypeModel[];
  vendorSvcDocTypeList: VendorServiceDocumentTypeModel[];
  avaiVendorSvcDocTypeList: VendorServiceDocumentTypeModel[];
  selVendorSvcDocTypeList: VendorServiceDocumentTypeModel[];
  document_name: string;
  //extension: string;
  //remarks: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  displayedColumn: string[] = ['documentType'];
  //personId: string;
  isModified: boolean = false;
  userType: string;
  showDocType: boolean = true;
  allowedFileType: string;
  uploader: FileUploader;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  docSize: string;
  effectiveDate: Date;
  expirationDate: Date;
  minExpirationDate: Date = new Date();
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  serviceTypeList: ServiceTypeModel[];
  selectedServiceTypeID: number;
  selectedDocumentTypeGuid: string;
  //showVendorSvcDocType: boolean = false;
  
  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;

  allowedFileTypeList: string[];
  maxFileSize: number;
  maxFileUploadNumber: number;
  showSuccessMsg: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<VendorDocumentEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _vendorService: VendorService,
    private readonly _dialogService: DialogService
  ) {
    this.vendorDocumentGUID = _data.vendorDocumentGUID;
    if (_data.vendorGUID == null) {
      this.vendorGUID = "";
    }
    else {
      this.vendorGUID = _data.vendorGUID;
    }
    this.dialogTitle = _data.dialogTitle;

  }

  getAllData(): Observable<any[]> {
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();
    if (this.userType == "internal") {
      let getServiceTypeResponse = this._commonService.getServiceTypeList();
      let getDocTypeResponse = this._commonService.getDocumentTypeList<ResponseModel<DocumentTypeModel[]>>();      
      if (this.vendorDocumentGUID != "") {
        let vendorDocResponse = this._vendorService.getVendorDocumentByvdId(this.vendorDocumentGUID);
        return forkJoin([getAllowedDocTypeResponse, getServiceTypeResponse, getDocTypeResponse, vendorDocResponse]);
      }
      else
        return forkJoin([getAllowedDocTypeResponse, getServiceTypeResponse, getDocTypeResponse]);
    }
    else {
      if (this.vendorDocumentGUID != "") {
        let vendorDocResponse = this._vendorService.getVendorDocumentByvdId(this.vendorDocumentGUID);
        return forkJoin([getAllowedDocTypeResponse, vendorDocResponse]);
      }
      
    }
    
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.allowedFileType = "";// new Array(GlobalConstant.allowedFileTypeList).toString().split(",").join(", ");
    this.errMsg = "";
    this.msg = "";
    this.errMsgDocType = "";
    this.showDocType =true;
    this.fileList = [];
    this.avaiDocumentTypeList = [];
    this.selDocumentTypeList = [];
    this.avaiVendorSvcDocTypeList=[];
    this.selVendorSvcDocTypeList = [];
    this.document_name = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.docSize = "";
    this.effectiveDate=null;
    this.expirationDate = null;
    this.minExpirationDate = new Date();
    this.serviceTypeList = [];
    this.selectedServiceTypeID = 0;
    this.allowedFileTypeList = [];
    this.maxFileSize = 0;
    this.maxFileUploadNumber = 0;
    this.showSuccessMsg = false;
    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",  
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
    this.finishAllUploads = true;
    this.uploader.clearQueue();
    };
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "vendor";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    let getData = true;
    if (this.userType != "internal") {
      if (this.vendorDocumentGUID == "") {
        getData = false;
        this.initControls();
        this.showSpin = false;
        
      }

    }

    if (getData == true) {
      this.getAllData().subscribe(responses => {
        if (responses[0] != null) {
          if (responses[0].code == "200" && responses[0].message == "") {
            if (responses[0].model != null) {
              let fileObj = new FileModel();
              fileObj = responses[0].model;
              this.allowedFileTypeList = fileObj.allowedFileTypeList;
              this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
              this.maxFileSize = fileObj.fileSize;
              this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
            }
          }
          else {
            if (responses[0].code == "401") {
              this._authService.signOut();
            }
          }
        }

        if (this.userType == "internal") {
          if (responses[1] != null) {
            if (responses[1].code == "200" && responses[1].message == "") {
              this.serviceTypeList = responses[1].model;
            }
            else {
              if (responses[1].code == "401") {
                this._authService.signOut();
              }
            }
          }

          if (responses[2] != null) {
            if (responses[2].code == "200" && responses[2].message == "") {
              this.documentTypeList = responses[2].model;
              this.documentTypeList = this.documentTypeList.filter(v => v.isVendor == true);
            }
            else {
              if (responses[2].code == "401") {
                this._authService.signOut();
              }
            }
          }

          if (this.vendorDocumentGUID != null && this.vendorDocumentGUID != "") {
            if (responses[3] != null) {
              if (responses[3].code == "200" && responses[3].message == "") {
                let vendorDoc = new VendorDocumentModel();
                vendorDoc = responses[3].model;
                this.document_name = vendorDoc.documentName;
                this.selectedDocumentTypeGuid = vendorDoc.documentTypeGUID;
                this.modifiedBy = vendorDoc.modifiedBy;
                this.modifiedDate = vendorDoc.modifiedDate;
                if (vendorDoc.effectiveDate != null && vendorDoc.effectiveDate != "")
                  this.effectiveDate = new Date(vendorDoc.effectiveDate);
                if (vendorDoc.expirationDate != null && vendorDoc.expirationDate != "")
                  this.expirationDate = new Date(vendorDoc.expirationDate);
                if (vendorDoc.documentSizeInKB > 0)
                  this.docSize = vendorDoc.documentSizeInKB.toString() + " KB";
                if (vendorDoc.isActive == true)
                  this.isActive = 1;
                else
                  this.isActive = 0;
                let obj = new FileModel();
                obj.fileName = vendorDoc.documentName;
                obj.bFile = vendorDoc.bFile;
                obj.mimeType = vendorDoc.mimeType;
                obj.fileType = vendorDoc.fileExtension;

                this.fileList.push(obj)
              }
              else {
                if (responses[3].code == "401") {
                  this._authService.signOut();
                }
              }
            }
          }
        }
        else {
          if (this.vendorDocumentGUID != null && this.vendorDocumentGUID != "") {
            if (responses[1] != null) {
              if (responses[1].code == "200" && responses[1].message == "") {
                let vendorDoc = new VendorDocumentModel();
                vendorDoc = responses[1].model;
                this.document_name = vendorDoc.documentName;
                this.modifiedBy = vendorDoc.modifiedBy;
                this.modifiedDate = vendorDoc.modifiedDate;
                if (vendorDoc.effectiveDate != null && vendorDoc.effectiveDate != "")
                  this.effectiveDate = new Date(vendorDoc.effectiveDate);
                if (vendorDoc.expirationDate != null && vendorDoc.expirationDate != "")
                  this.expirationDate = new Date(vendorDoc.expirationDate);
                if (vendorDoc.documentSizeInKB > 0)
                  this.docSize = vendorDoc.documentSizeInKB.toString() + " KB";
                if (vendorDoc.isActive == true)
                  this.isActive = 1;
                else
                  this.isActive = 0;
                let obj = new FileModel();
                obj.fileName = vendorDoc.documentName;
                obj.bFile = vendorDoc.bFile;
                obj.mimeType = vendorDoc.mimeType;
                obj.fileType = vendorDoc.fileExtension;

                this.fileList.push(obj)
              }
              else {
                if (responses[1].code == "401") {
                  this._authService.signOut();
                }
              }
            }
          }
        }

        
        this.initControls();

        this.showSpin = false;

        //this.setDocumentTypeList(this.selectedServiceTypeID);

        //if (this.userType == "internal") {
        //  this.setVendorSvcDocumentTypeList();
        //  this.setDocumentTypeList(this.selectedServiceTypeID);
        //}


      });
    }

  }

  initControls() {
    this.vendor_document_edit_form = this._formBuilder.group({
      doc_status: this.isActive,
      document_name: [this.document_name, Validators.required],
      size: [this.docSize],
      effectiveDate: [this.effectiveDate],
      expirationDate: [this.expirationDate],
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      document: [null],
      document_type: [this.selectedDocumentTypeGuid,Validators.required]
      //service_type_select: [this.selectedServiceTypeID]
    });
  }

  ngAfterViewInit() {

  }

  get f() { return this.vendor_document_edit_form.controls; }

  serviceTypeOnchange(e) {
    this.selectedServiceTypeID = e.target.value;
    this.avaiDocumentTypeList = this.documentTypeList.filter(
      (thing, i, arr) => arr.findIndex(t => t.documentTypeGUID === thing.documentTypeGUID && t.selected == false && (t.serviceTypeID == this.selectedServiceTypeID || this.selectedServiceTypeID ==0)) === i
    );
  }

  clickAddItem(e: any, item: DocumentTypeModel) {
    this.documentTypeList.forEach(x => {
      if (x.documentTypeGUID.toLowerCase() == item.documentTypeGUID.toLowerCase()) {
        x.selected = true;
      }
    })
    this.setDocumentTypeList(this.f.service_type_select.value);

  }

  clickRemoveItem(e: any, item: DocumentTypeModel) {
    this.documentTypeList.forEach(x => {
      if (x.documentTypeGUID.toLowerCase() == item.documentTypeGUID.toLowerCase()) {
        x.selected = false;
      }
    })
    this.setDocumentTypeList(this.f.service_type_select.value);

  }

  //setDocumentTypeList() {
  //  this.errMsg = "";
  //  this.avaiDocumentTypeList = this.documentTypeList.filter(u => u.selected == false);
  //  this.selDocumentTypeList = this.documentTypeList.filter(u => u.selected == true);
  //}

  setDocumentTypeList(sid: number) {
    this.errMsg = "";
    this.avaiDocumentTypeList = this.documentTypeList.filter(
      (thing, i, arr) => arr.findIndex(t => t.documentTypeGUID === thing.documentTypeGUID && t.selected == false && (t.serviceTypeID==sid || sid==0)) === i
    );
    this.selDocumentTypeList = this.documentTypeList.filter(
      (thing, i, arr) => arr.findIndex(t => t.documentTypeGUID === thing.documentTypeGUID && t.selected == true) === i 
    );
    this.selectedDocumentTypeGuid = this.selDocumentTypeList[0].documentTypeGUID;
    this.f.document_type.setValue(this.selectedDocumentTypeGuid);
  }

  clickSelectAll() {
    this.documentTypeList.forEach(x => {
      if (this.selectedServiceTypeID == 0) {
        x.selected = true;
      }
      else {
        if (this.selectedServiceTypeID == x.serviceTypeID) {
          x.selected = true;
        }
      }
      //x.selected = true;
    })

    this.setDocumentTypeList(this.f.service_type_select.value);
  }

  clickRemoveAll() {
    this.documentTypeList.forEach(x => {
      x.selected = false;
    })

    this.setDocumentTypeList(this.f.service_type_select.value);
  }

  clickAddVendorSvcDocItem(e: any, item: VendorServiceDocumentTypeModel) {
    this.vendorSvcDocTypeList.forEach(x => {
      if (x.vendorServiceDocumentTypeGUID.toLowerCase() == item.vendorServiceDocumentTypeGUID.toLowerCase()) {
        x.selected = true;
      }
    })
    this.setVendorSvcDocumentTypeList();

  }

  clickRemoveVendorSvcDocItem(e: any, item: VendorServiceDocumentTypeModel) {
    this.vendorSvcDocTypeList.forEach(x => {
      if (x.vendorServiceDocumentTypeGUID.toLowerCase() == item.vendorServiceDocumentTypeGUID.toLowerCase()) {
        x.selected = false;
      }
    })
    this.setVendorSvcDocumentTypeList();

  }

  setVendorSvcDocumentTypeList() {
    this.errMsg = "";
    this.avaiVendorSvcDocTypeList = this.vendorSvcDocTypeList.filter(u => u.selected == false);   
    this.selVendorSvcDocTypeList = this.vendorSvcDocTypeList.filter(u => u.selected == true);
    
  }

  clickSelectAllVendorSvcDoc() {
    this.vendorSvcDocTypeList.forEach(x => {
      x.selected = true;
    })

    this.setVendorSvcDocumentTypeList();
  }

  clickRemoveAllVendorSvcDoc() {
    this.vendorSvcDocTypeList.forEach(x => {
      x.selected = false;
    })

    this.setVendorSvcDocumentTypeList();
  }

  clickCustomizeDocumentType() {
    if (!this.showDocType)
      this.showDocType = true;
  }

  clickCustomizeVendorSvcDocumentType() {
    if (this.showDocType)
      this.showDocType = false;
  }

  // upload file
  importFile(event: any) {
    this.finishAllUploads = false;
    this.fileList = [];
    var files = [];
    this.errMsg = ""
    //this.errDocMsg = "";
    if (event.target.files.length > this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {
      //if (this.errMsg != "") {
      //  this.errMsg += "\n";
      //}
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      this.finishAllUploads = true;
      this.uploader.clearQueue();
      return;
    }
    else {
      if (event.target.files.length > 1) {
        //if (this.errMsg != "") {
        //  this.errMsg += "\n";
        //}
        this.errMsg = 'Only allow to upload one file.';
        this.finishAllUploads = true;
        this.uploader.clearQueue();
        return;
      }
      else {
        for (var i = 0; i < event.target.files.length; i++) {
          let filename = event.target.files[i].name;
          //var a = CustomValidators.checkValidFileType(filename);
          var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
          if (a) {
            if (event.target.files[i].size >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {
              //if (this.errMsg != "") {
              //  this.errMsg += "\n";
              //}
              this.errMsg = 'The file size "' + filename + '" is too big to upload.';
              this.finishAllUploads = true;
              this.uploader.clearQueue();
              return;
            }
            else {
              files.push(event.target.files[i]);

            }
          }
          else {
            //if (this.errMsg != "") {
            //  this.errMsg += "\n";
            //}
            //this.uploader.removeFromQueue(this.uploader.queue[0]);
            this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
            this.finishAllUploads = true;
            this.uploader.clearQueue();
            return;
          }
        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
      return;
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
  }

  uploadSubmit() {    
    this.finishAllUploads = false;
    this.uploader.uploadAll();    
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            let obj = new FileModel();
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            this.f.document_name.setValue(obj.fileName);
            this.f.size.setValue(obj.fileSize.toString()+ " KB");
            this.fileList.push(obj)
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  clickPreviewDocument() {
    let item = this.fileList[0];
    let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
    let file = new Blob([byteArray], { type: item.mimeType });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  clickRemoveDocument() {
    this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the document?");
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        this.fileList = [];
        this.document_name = '';
        this.f.document_name.setValue(this.document_name);
      }
    })

  }

  onFileClick(event) {
    event.target.value = '';
  }
  // upload file end

  clickSave() {
    this.errMsg = "";
    this.errMsgDocType=""
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    if (this.effectiveDateRef.nativeElement.value !== "") {
      if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));     
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.effectiveDate.setErrors({ isValidDate: f1 });
          
        }
      }
      else {
        this.f.effectiveDate.setErrors({ isValidDate: false });
        
      }
    }

    if (this.expirationDateRef.nativeElement.value !== "") {
      if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null) {
        let s2 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
        let f2 = CustomValidators.isValidDate(s2);
        if (!f2) {
          this.f.expirationDate.setErrors({ isValidDate: f2 });

        }
      }
      else {
        this.f.expirationDate.setErrors({ isValidDate: false });

      }
    }

    //if (this.userType == "internal") {
    //  if (this.selDocumentTypeList.length == 0) {
    //    this.errMsgDocType = "You must select at least one document type.";
    //  }
    //}

    if (this.fileList.length == 0 && this.f.document_name.value!="") {
      if (this.errMsg != "") {
        this.errMsg += "\n";
      }
      this.errMsg += "Please upload a document.";
    }
    if (this.vendor_document_edit_form.invalid || this.errMsg != "" || this.errMsgDocType!="") {
      return;
    }
    this.loading = true;
    let obj = new VendorDocumentModel();
    obj.vendorGUID = "";
    if (this.userType=="internal")
      obj.vendorGUID = this.vendorGUID;
    obj.vendorDocumentGUID = "";
    if (this.vendorDocumentGUID != "" && this.vendorDocumentGUID != null)
      obj.vendorDocumentGUID = this.vendorDocumentGUID;
    obj.documentName = this.f.document_name.value;
    obj.documentTypeGUID = this.f.document_type.value;
    //if (this.selDocumentTypeList.length>0)
    //  obj.documentTypeGUIDList = Array.prototype.map.call(this.selDocumentTypeList, s => s.documentTypeGUID).toString();
    obj.vendorServiceDocumentTypeGUIDList = "";
    //if (this.userType == "internal") {
    //  if (this.selVendorSvcDocTypeList.length > 0) {
    //    obj.vendorServiceDocumentTypeGUIDList = Array.prototype.map.call(this.selVendorSvcDocTypeList, s => s.vendorServiceDocumentTypeGUID).toString();
    //  }
    //}
    if (this.f.doc_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;
    obj.effectiveDate = "";
    if (this.f.effectiveDate.value !=null) {
      obj.effectiveDate = this.effectiveDateRef.nativeElement.value;
    }
    obj.expirationDate = "";
    if (this.f.expirationDate.value != null) {
      obj.expirationDate = this.expirationDateRef.nativeElement.value;
    }
    obj.bFile = "";
    if (this.fileList.length > 0) {
      obj.bFile = this.fileList[0].bFile;
      obj.fileExtension = this.fileList[0].fileType;
      obj.fileSize = this.fileList[0].fileSize;
    }

    this._vendorService.saveVendorDocument<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.vendorDocumentGUID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          //if (obj.vendorDocumentGUID=="")
          //  this._dialogService.openAlertDialog("Added successfully.");
          //else
          //  this._dialogService.openAlertDialog("Modified successfully.");
          //this._dialogService.openAlertDialog("");
          this.isModified = true;
          //this._dialogRef.close(this.isModified);
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })


  }

  clickAddNew() {
    this._authService.updateAccessTime();
    //this.allowedFileType = new Array(GlobalConstant.allowedFileTypeList).toString().split(",").join(", ");
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.errMsgDocType = "";
    this.showDocType = true;
    this.fileList = [];
    this.finishAllUploads = true;
    this.avaiDocumentTypeList = [];
    this.selDocumentTypeList = [];
    this.avaiVendorSvcDocTypeList = [];
    this.selVendorSvcDocTypeList = [];
    this.document_name = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.selectedDocumentTypeGuid = "";
    this.isActive = 1;
    this.vendorDocumentGUID = "";
    this.dialogTitle = "ADD New Record";
    this.docSize = "";
    this.effectiveDate = null;
    this.expirationDate = null;
    this.selectedServiceTypeID = 0;
    this.initControls();
    if (this.userType == "internal") {
      this.documentTypeList.forEach(x => {
        x.selected = false;
      })
      this.setDocumentTypeList(this.selectedServiceTypeID);
    }
    //this.documentTypeList.forEach(x => {
    //  x.selected = false;
    //})
    //this.avaiDocumentTypeList = this.documentTypeList.filter(u => u.selected == false);

    //if (this.userType == "internal") {
    //  this.vendorSvcDocTypeList.forEach(x => {
    //    x.selected = false;
    //  })
    //  this.avaiVendorSvcDocTypeList = this.vendorSvcDocTypeList.filter(u => u.selected == false);
    //}


  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }
}
