export class CategoryModel {
  categoryID: number;
  audienceID: number;
  categoryTitle: string;
  categoryDescription: string;
  sortOrderNumber: number;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
}
