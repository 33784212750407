<div mat-dialog-content style="margin-top:-1em;padding-bottom:0;">

  <form novalidate *ngIf="flight_brief_sections_form" [formGroup]="flight_brief_sections_form">

    <div class="container" style="background-color:white;min-width:35em;padding-top:1em;padding-bottom:1em;margin-top:-.5em">
      <app-spin *ngIf="showSpin"></app-spin>

      <!--<div>
    <h6>Include Sections in Flight Brief</h6>
    <div class="d-flex">
      <div>
        <div class="d-flex flex-column" *ngFor="let sec of flightBriefSectionList, let i=index">
          <mat-checkbox *ngIf="i<flightBriefSectionList.length/2" (change)="checkFlightBriefSection($event,sec)" (click)="$event.stopPropagation()" [checked]="selection2.isSelected(sec)">{{sec.sectionName}}</mat-checkbox>
        </div>
      </div>
      <div class="ml-2">
        <div class="d-flex flex-column" *ngFor="let sec of flightBriefSectionList, let i=index">
          <mat-checkbox *ngIf="i>=flightBriefSectionList.length/2" (change)="checkFlightBriefSection($event,sec)" (click)="$event.stopPropagation()" [checked]="selection2.isSelected(sec)">{{sec.sectionName}}</mat-checkbox>
        </div>
      </div>
    </div>
  </div>-->
      <div>

        <div *ngIf="documentList && documentList.length>0">

          <div class="example-action-buttons" align="right">
            <button mat-button (click)="openAll()" color="primary" style="outline:none; font-size: small">Expand All</button>
            <button mat-button (click)="closeAll()" color="primary" style="outline: none; font-size: small">Collapse All</button>
          </div>
          <div style="overflow-y:auto">
            <mat-accordion class="example-headers-align" [multi]="true">
              <mat-expansion-panel *ngFor="let item of documentCategoryList, let i=index" [expanded]="item.panelOpenState" (opened)="item.panelOpenState=true" (closed)="item.panelOpenState=false" style="border: 0.0625em solid lightgray">
                <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'" style="margin-left:-1em; font-weight:500; font-size:medium !important; text-align: left !important">
                  <mat-panel-title>
                    <div id="panel_{{i}}" #focusOnThisPanel>
                      {{item.documentCategory}}&nbsp;&nbsp;{{item.documentSelectedCountDisplay}}
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <table mat-table *ngIf="item.documentList" [dataSource]="item.documentList" class="example-container">
                    <tr>
                      <ng-container matColumnDef="documentType">
                        <th mat-header-cell *matHeaderCellDef style="width:20%">
                          Task
                        </th>
                        <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-left:0.0625em solid lightgrey !important">{{element.documentType}}</td>
                      </ng-container>
                      <ng-container matColumnDef="documentName">
                        <th mat-header-cell *matHeaderCellDef style="width:55%">
                          Document
                        </th>
                        <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;"><a style="color:blue; cursor:pointer; text-decoration:underline" (click)="clickPreviewDocument($event, element)">{{element.documentName}}</a>&nbsp;({{element.documentSizeInKB}}KB)</td>
                      </ng-container>
                      <ng-container matColumnDef="check">
                        <th mat-header-cell *matHeaderCellDef style="width:10%;">
                          Select
                        </th>
                        <td mat-cell *matCellDef="let element; let j = index;" style="line-height: 1.5625em;">
                          <div style="margin-top:0.5em">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="checkDocumentChange($event, element, i+j)" [checked]="selection.isSelected(element)"></mat-checkbox>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="sortOrderNo">
                        <th mat-header-cell *matHeaderCellDef style="width:15%;">
                          Sort Order
                        </th>
                        <td mat-cell *matCellDef="let element; let j = index;" style="line-height: 1.5625em;border-right:0.0625em solid lightgrey !important">
                          <input type="number" style="width:3em;margin-top:.5em" class="form-control form-control-sm inputBox" id="sortOrder{{i+j}}" [disabled]="!selection.isSelected(element)" (change)="setSortOrder($event, element)" value="{{element.sortOrderNo}}" />
                        </td>
                      </ng-container>

                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                  </table>
                </div>
                <mat-action-row  align="right">
                  <button mat-button [hidden]="i==0" color="primary" (click)="prevStep(i)" style="font-size: small;margin-top:-1em">Previous</button>
                  <button mat-button color="primary" (click)="nextStep(i)" style="font-size: small;margin-top:-1em">{{i<(documentCategoryList.length-1)? 'Next': 'End'}}</button>
                </mat-action-row>

              </mat-expansion-panel>
            </mat-accordion>
          </div>

        </div>
      </div>
    </div>
  </form>
</div>
<div class="d-inline-flex">
  <div class="d-inline-flex" style="height:max-content">
    <button mat-button class="mt-2 btn-high" (click)="clickSave(true)"><span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>Save</button>
    <button mat-button class="mt-2 ml-2 btn-high" (click)="buildPDF()"><span *ngIf="isLoadingPDF" class="spinner-border spinner-border-sm mr-1"></span>Build PDF</button>
    <button mat-button class="mt-2 ml-2 btn-low" (click)="clickClose()">Close</button>
    <div *ngIf="errMsg!=''" class="alert alert-danger small ml-2 mt-2 p-0" style="white-space:pre-wrap;width:max-content">{{errMsg}}</div>
  </div>
  <div>
    <div *ngIf="showSuccessMsg" class="mt-1 ml-2">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
    <div *ngIf="showSuccessMsg2" class="mt-1 ml-2">
      <app-success-message [successMsg]="'PDF created'"></app-success-message>
    </div>
  </div>
</div>
