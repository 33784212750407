<div style="height: 100%;" [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle [cdkDragDisabled]="!isDialog">
  <div style="display: inline-flex; justify-content: space-between; width: 100%;">
    <div style="margin-left:1em">
      <div style="display: inline-flex;">
        <i class="material-symbols-outlined" style="margin-top: 0.3em;">
          inventory
        </i>
        <div style="margin-left: 0.5em; margin-bottom: 0.5em;">
          <h3 style="margin-bottom: 0em;">Audit Report - Person Profile</h3>
          <h5 style="color: #1976e6;">{{selectedClientName}}</h5>
        </div>
      </div>
    </div>

    <div style="display: flex;">
      <button mat-icon-button *ngIf="isDialog" (click)="openManageContactDetailsComponent()"> <i class="material-icons-outlined"
          style="font-size:xx-large;  ">arrow_circle_left</i> </button>
      <button mat-icon-button *ngIf="isDialog" (click)="clickRouteNew('persons/person-audit-report','')"> <i
          class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>
      <button mat-icon-button *ngIf="isDialog" mat-dialog-close><i class="material-icons"
          style="font-size: x-large;">close </i></button>
    </div>
  </div>

  <form *ngIf="customer_audit_report_form" [formGroup]="customer_audit_report_form">
    <div style="margin-top:0em;background-color:white; padding: 1em 1em 1em 1em; height: 42em;">
      <div style="display: inline-flex; justify-content: space-between; width: 100%; height: 3em;">
        <div style="margin-left: 0em; display: flex; flex-direction: column;">
          <label for="effectiveDate" style="font-size:small">Effective Date Range</label>
          <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em; display: inline-flex;">
            <input type="date" class="form-control form-control-sm inputBox" formControlName="effectiveDateRangeStart"
              style="width: 7em" (change)="getPersonAuditReport()"/>
            <input type="date" class="form-control form-control-sm inputBox" formControlName="effectiveDateRangeEnd"
              style="width: 7em; margin-left: 0.5em;" (change)="getPersonAuditReport()"/>
          </div>
          <div *ngIf="submitted && f.effectiveDateRangeStart.errors || f.effectiveDateRangeEnd.errors" class="alert alert-danger p-0 small">
            <div *ngIf="f.effectiveDateRangeStart.errors.required || f.effectiveDateRangeEnd.errors.required">Required</div>
          </div>
        </div>

        <div style="align-content: center; margin-left: 1em;">
          <mat-radio-button style="font-size: small; padding: 1.5em 0.5em 0.5em 0em;" formControlName="personType"
             (click)="filterPersonAuditsByPersonType('All')" checked value="All">All</mat-radio-button>
          <mat-radio-button style="font-size: small; padding: 0.5em;" formControlName="personType" (click)="filterPersonAuditsByPersonType('Internal')" value="Internal">Internal</mat-radio-button>
          <mat-radio-button style="font-size: small; padding: 0.5em;" formControlName="personType" (click)="filterPersonAuditsByPersonType('Client')" value="Client">Client</mat-radio-button>
        </div>
        <div style="align-content: center; display: inline-flex;">
          <div style="margin-top: 0.25em; margin-left: 1em; font-size: small; align-content: center;">
            <button *ngIf="isInternalFullVersion" mat-button class="btn-med" style="margin-right: 1em;" (click)="toggleFilterPopup(0)">
              Client Account{{clientAccountFilterText}}
            </button>
            <button mat-button class="btn-med" style="margin-right: 1em;" (click)="toggleFilterPopup(1)">
              Person Record{{personRecordFilterText}}
            </button>
            <button mat-button class="btn-med" style="margin-right: 1em;" (click)="toggleFilterPopup(2)">
              Change Type{{changeTypeFilterText}}
            </button>
            <button mat-button class="btn-med" style="margin-right: 1em;" (click)="toggleFilterPopup(3)">
              Modified By Username{{usernameFitlerText}}
            </button>
          </div>
          <div style="display: flex; flex-direction: column;">
            <button type="button" mat-button class="btn-low" (click)="clickResetAll()" style="height:1.5em;width:7em">
              <div style="display: flex;margin-top:-0.125em">
                <div style="margin-top: 0.125em; margin-left:-0.25em">
                  <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">restart_alt</i>
                </div>
                <div>
                  RESET
                </div>
              </div>
            </button>
            <button type="button" mat-button class="btn-low" (click)="getPersonAuditReport()" style="height:1.5em; width:7em; margin-top: 0.25em;">
              <div style="display: flex;margin-top:-0.125em">
                <div style="margin-top: 0.25em; margin-left:-0.25em">
                  <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>
                </div>
                <div>
                  REFRESH
                </div>
              </div>
            </button>
          </div>
        </div>

        <div *ngIf="showClientAccountList" style="position: absolute; float: left; min-width: 12em; margin-top: 3.25em; margin-left: 43em; background-color: white; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
          <div style="display:flex; justify-content:space-between">
            <div style="font-weight: bold; text-decoration:underline">Client Accounts</div>
            <div style="float:right" (click)="showClientAccountList=!showClientAccountList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
          </div>
          <div *ngIf="distinctClientAccounts.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
            <div *ngFor="let record of distinctClientAccounts; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
              <div style="display: flex; font-size: small; margin-top:-0.5em">
                <div>
                  <mat-checkbox (mousedown)="$event.stopPropagation();" (change)="record.selected = !record.selected" [checked]="record.selected">{{record.customerName}}</mat-checkbox>
                </div>
                <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{c.customerName}}</div></div>-->
              </div>
            </div>
          </div>
          <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
          <div style="display: flex; float: right">
            <div style="margin-right:0.5em">
              <button type="button" mat-button class="btn-low" (click)="clickReset('Client Accounts')" style="height:2em">RESET</button>
            </div>
            <div>
              <button type="button" mat-button class="btn-high" (click)="clickDone('Client Accounts')" style="height:2em">DONE</button>
            </div>
          </div>
        </div>

        <div *ngIf="showPersonRecordList" style="position: absolute; float: left; min-width: 12em; margin-top: 3.25em; margin-left: 43em; background-color: white; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
          <div style="display:flex; justify-content:space-between">
            <div style="font-weight: bold; text-decoration:underline">Person Records</div>
            <div style="float:right" (click)="showPersonRecordList=!showPersonRecordList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
          </div>
          <div *ngIf="distinctPersonRecords.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
            <div *ngFor="let record of distinctPersonRecords; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
              <div style="display: flex; font-size: small; margin-top:-0.5em">
                <div>
                  <mat-checkbox (mousedown)="$event.stopPropagation();" (change)="record.selected = !record.selected" [checked]="record.selected">{{record.personRecord}}</mat-checkbox>
                </div>
                <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{c.customerName}}</div></div>-->
              </div>
            </div>
          </div>
          <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
          <div style="display: flex; float: right">
            <div style="margin-right:0.5em">
              <button type="button" mat-button class="btn-low" (click)="clickReset('Person Records')" style="height:2em">RESET</button>
            </div>
            <div>
              <button type="button" mat-button class="btn-high" (click)="clickDone('Person Records')" style="height:2em">DONE</button>
            </div>
          </div>
        </div>

        <div *ngIf="showChangeTypeList" style="position: absolute; float: left; min-width: 12em; margin-top: 3.25em; margin-left: 50em; background-color: white; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
          <div style="display:flex; justify-content:space-between">
            <div style="font-weight: bold; text-decoration:underline">Change Type</div>
            <div style="float:right" (click)="showChangeTypeList=!showChangeTypeList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
          </div>
          <div *ngIf="distinctChangeTypes.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
            <div *ngFor="let record of distinctChangeTypes; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
              <div style="display: flex; font-size: small; margin-top:-0.5em">
                <div>
                  <mat-checkbox (mousedown)="$event.stopPropagation();" (change)="record.selected = !record.selected" [checked]="record.selected">{{record.changeType}}</mat-checkbox>
                </div>
                <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{c.customerName}}</div></div>-->
              </div>
            </div>
          </div>
          <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
          <div style="display: flex; float: right">
            <div style="margin-right:0.5em">
              <button type="button" mat-button class="btn-low" (click)="clickReset('Change Types')" style="height:2em">RESET</button>
            </div>
            <div>
              <button type="button" mat-button class="btn-high" (click)="clickDone('Change Types')" style="height:2em">DONE</button>
            </div>
          </div>
        </div>

        <div *ngIf="showUsernameList" style="position: absolute; float: left; min-width: 12em; margin-top: 3.25em; margin-left: 62em; background-color: white; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
          <div style="display:flex; justify-content:space-between">
            <div style="font-weight: bold; text-decoration:underline">Username</div>
            <div style="float:right" (click)="showUsernameList=!showUsernameList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
          </div>
          <div *ngIf="distinctUsernames.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
            <div *ngFor="let record of distinctUsernames; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
              <div style="display: flex; font-size: small; margin-top:-0.5em">
                <div>
                  <mat-checkbox (mousedown)="$event.stopPropagation();" (change)="record.selected = !record.selected" [(checked)]="record.selected">{{record.userName}}</mat-checkbox>
                </div>
                <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{c.customerName}}</div></div>-->
              </div>
            </div>
          </div>
          <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
          <div style="display: flex; float: right">
            <div style="margin-right:0.5em">
              <button type="button" mat-button class="btn-low" (click)="clickReset('Usernames')" style="height:2em">RESET</button>
            </div>
            <div>
              <button type="button" mat-button class="btn-high" (click)="clickDone('Usernames')" style="height:2em">DONE</button>
            </div>
          </div>
        </div>
        <div style="font-size: small; color: #1976e6; align-content: center; margin-left: 1em;" class="link" (click)="exportToExcel()">
          Export to Excel
        </div>
      </div>
      <div>
        <div style="font-size: small; text-align: end; margin-bottom: 1em;">
          Total Records: {{totalRecords}}
        </div>
        <div style="height: 35em; overflow-y: auto; scrollbar-color: #c1c1c1 white">
          <table mat-table [dataSource]="filteredRecordList" style="width: 100%;">
            <tr>
              <ng-container matColumnDef="transaction_date_username">
                <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 17em;">
                  Transaction Date (z) & Username
                </th>
                <td mat-cell *matCellDef="let element" style="align-content: center; text-align: left !important;">
                  {{element.transactionDateTime}}
                </td>
              </ng-container>
              <ng-container *ngIf="isInternalFullVersion" matColumnDef="client_acct">
                <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 17em;">
                  Client Account
                </th>
                <td mat-cell *matCellDef="let element" style="align-content: center; text-align: left !important;">
                  {{element.customerName}}
                </td>
              </ng-container>
              <ng-container matColumnDef="person_record">
                <th mat-header-cell *matHeaderCellDef style="text-align: left; width: 15em;">
                  Person Record
                </th>
                <td mat-cell *matCellDef="let element" style="align-content: center; text-align: left !important;">
                  {{element.personRecord}}
                </td>
              </ng-container>
              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 15em;">
                  Type
                </th>
                <td mat-cell *matCellDef="let element" style="align-content: center; text-align: left !important;">
                  {{element.isNewRecord ? 'New Record' : 'Update'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="change_type">
                <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 15em;">
                  Change Type
                </th>
                <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center;" >
                  {{element.changeType}}
                </td>
              </ng-container>
              <ng-container matColumnDef="change_request">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                  Change Request
                </th>
                <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center; white-space: pre-line;">
                  {{element.changeRequest}}
                </td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
      </div>
    </div>
  </form>
</div>