import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserModel } from '../models/user.model';
import { AuthenticateService } from '../services/authenticate.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { CustomerModel } from '../models/customer.model';
import { VendorModel } from '../models/vendor.model';
import { Observable, forkJoin, of } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { CommonService } from '../services/common.service';
import { ResponseModel } from '../models/response.model';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DialogService } from '../services/dialog.service';
import { CustomerGroupService } from '../services/customer-group-service';
import { CustomerGroupModel } from '../models/customer-group.model';
import { AdminServicesClientComponent } from './admin-services-client.component';

@Component({
  selector: 'app-reset-password-admin',
  templateUrl: './reset-password-admin.component.html',
  styleUrls: ['./reset-password-admin.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }  
  ]
})

export class ResetPasswordAdminComponent implements OnInit, AfterViewInit {
  reset_password_admin_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  user: UserModel;
  errMsg: string;
  msg: string;
  customerList: CustomerModel[];
  vendorList: VendorModel[];
  //userTypeList: UserTypeModel[];
  userList: UserModel[];
  selectedUserType: string; 
  userType: string;
  showSpin: boolean = true;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  showPage: boolean = false;
  //displayedColumn: string[] = ['userType', 'customerName', 'username', 'email', 'action'];
  displayedColumn: string[] = ['username', 'email', 'effectiveDate', 'expirationDate', 'action'];
  scrHeight: number;
  ua: number;
  grantAccessToAccountGrouping: boolean = false;
  customerGUID: string = "";
  isPopup: boolean = false;
  selectedCustomerName: string = "";
  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService, private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService, private readonly _dialogService: DialogService, private readonly _customerGroupService: CustomerGroupService,
    private readonly _dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private readonly _dialogRef: MatDialogRef<ResetPasswordAdminComponent>) {
      
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if(this.data) {
      this.isPopup = true;
    }
    this.ua = 0;// Number(localStorage.getItem('ua'));
    if (localStorage.getItem('up.isA') != null && localStorage.getItem('up.isA') != "") {
      if (localStorage.getItem('up.isA').toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
        this.ua = 1;
      }
    }
    if (this.ua != 1) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this._router.navigate(['/common-utility/blank']);
      return;
    }
    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }
    this.errMsg = "";
    this.msg = "";  
    this.vendorList = [];
    this.customerList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerList = responses[0].model;
          if (this.customerList.length > 0) {
            if (this.customerGUID == "")
              this.customerGUID = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();
          }          
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }   
      
      this.initControls();

      if(this.isPopup) {
        this.clickUserType(this.data.userType);
      }
      else {
        if (this.userType == "internal") {
          this.selectedUserType = 'internal';
          this.loadUsers();
        }
        else {
          this.loadUsers();
        }
      }

      this.showSpin = false;

    });
    
  }

  getAllData(): Observable<any[]> {
    let getCustomersResponse;
    if (this.userType == "internal") {
      getCustomersResponse = of(null);
      //let getVendorsResponse = this._commonService.getVendors<ResponseModel<VendorModel[]>>();          
    }
    else {
      if (this.userType == "customer") {
        if (this.grantAccessToAccountGrouping) {
          let req = new CustomerGroupModel();
          getCustomersResponse = this._customerGroupService.getSelectedCustomerListBycgrpId(req);
        }
        else {
          getCustomersResponse = of(null);
        }
      }
      else {
        getCustomersResponse = of(null);
      }
    }
    return forkJoin([getCustomersResponse]);
  }

  initControls() {
    this.reset_password_admin_form = this._formBuilder.group({     
      customer_select: this.customerGUID,
      vendor_select: null,     
      email: [''],        
      username: ['']    
    });

  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //  this.showSpin = false;
    //}, 100);

  }

  get f() { return this.reset_password_admin_form.controls; }

  clickUserType(u) {
    if (this.selectedUserType != u) {
      this.userList = [];
      this.selectedUserType = u;
      this.f.customer_select.setValue('');
      this.f.vendor_select.setValue('');
      this.errMsg = "";
      this.msg = "";
      this.totalRecordsText = '';
      this.totalRecords = 0;
      this.currentPageIndex = 0;
      this.totalPageNumber = 0;
      if (u == "customer") {
        if(this.isPopup) {
          this.customerGUID = this.data.customerGUID;
        }
        else {
          if(this.customerGUID === undefined || this.customerGUID === "") {
            this._route.queryParams.subscribe(params => {
              this.customerGUID = params['customerGUID'];
              this.f.customer_select.setValue(this.customerGUID);
            });
          }
        }
        
        this.loadClientList();
        
      }
      else {
        if (u == "vendor") {
          this.loadVendorList();
        }
      }
      if (this.selectedUserType == "internal") {
        this.loadUsers();
      }
      
    }
  }

  loadClientList() {
    this.showSpin = true;
    this._commonService.getCustomers<ResponseModel<CustomerModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.customerList = response.model;
        this.selectedCustomerName = this.customerList.find(x => x.customerGUID === this.customerGUID)?.customerName;
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.showSpin = false;
      
      this.initControls();
      this.customerChange();
    })
  }

  loadVendorList() {
    this.showSpin = true;
    this._commonService.getVendors<ResponseModel<VendorModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.vendorList = response.model;
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.showSpin = false;
    })
  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
    }
    this.loadUsers();

  }

  customerChange(e: any = null) {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = "";
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    if (this.f.customer_select.value == "") {
      this.errMsg = "Please select a client";
      return;
    }
    this.loadUsers();
  }


  vendorChange(e: any) {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = "";
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    if (this.f.vendor_select.value == "") {
      this.errMsg = "Please select a vendor";
      return;
    }
    this.loadUsers();
  }

  onSubmit() {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = "";
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    if (this.f.username.value == '' && this.f.email.value == '') {
      if (this.userType != "internal") {
        if (this.f.username.value == '' && this.f.email.value == '') {
          this.errMsg = "Please enter at lease one field either usename or email.";
          return;
        }
      }
      else {
        if (this.selectedUserType != "internal") {
          if (this.selectedUserType == "customer") {
            if (this.f.customer_select.value == "") {
              this.errMsg = "Please enter at lease one field usename or email or choose a client";
              return;
            }
          }
          if (this.selectedUserType == "vendor") {
            if (this.f.vendor_select.value == "") {
              this.errMsg = "Please enter at lease one field usename or email or choose a vendor";
              return;
            }
          }
        }
        else {
          this.errMsg = "Please enter at lease one field either usename or email.";
          return;
        }        
      }
    }

    this.user = new UserModel();
    this.user.email = this.f.email.value;  
    this.user.username = this.f.username.value;
    this.user.customerGuid = null;
    this.user.vendorGuid = null;
    
    if (this.userType == "internal") {
      if (this.selectedUserType == "customer")
        this.user.customerGuid = this.f.customer_select.value;       
      if (this.selectedUserType == "vendor")
        this.user.vendorGuid = this.f.vendor_select.value;    
    }
    
    if (this.userType == "internal") {
      this.user.userType = this.selectedUserType;
    }

    this.loadUsers();

  }

  loadUsers() {
    this.errMsg = "";
    this.msg = "";
    this.loading = true;
    this._authService.updateAccessTime();
    this.user = new UserModel();
    this.user.vendorGuid = null;
    this.user.customerGuid = null;
    if (this.userType == "internal") {
      if (this.f.vendor_select.value != '' && this.f.vendor_select.value != null)
        this.user.vendorGuid = this.f.vendor_select.value;
      if (this.f.customer_select.value != '' && this.f.customer_select.value != null)
        this.user.customerGuid = this.f.customer_select.value;
      this.user.userType = this.selectedUserType;
    }
    else {
      if (this.grantAccessToAccountGrouping && this.customerList.length>0) {
        this.user.customerGuid = this.f.customer_select.value;
      }
    }
    this.user.username = "";
    if (this.f.username.value != "") {
      this.user.username = this.f.username.value;
    }
    this.user.email = "";
    if (this.f.email.value != "") {
      this.user.email= this.f.email.value;
    }
    this.user.pageIndex = this.currentPageIndex;
    this.user.pageSize = this.pageSize;
    this.user.totalRecords = this.totalRecords;

     this._identityService.getUsersByUsernameEmail<ResponseModel<UserModel[]>>(this.user).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model.length > 0) {
            this.userList = response.model;
            this.userList.forEach(x => {
              x.hasResetPwd = false;
              x.loading = false;
            });
            if (this.userList[0].totalRecords > 0) {
              //this.totalRecords = this.userList[0].totalRecords.toString() + ' record count';
              this.totalRecords = this.userList[0].totalRecords;
              this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
              if (this.userList[0].totalRecords == 1)
                this.totalRecordsText = this.userList[0].totalRecords.toString() + ' record';
              else
                this.totalRecordsText = this.userList[0].totalRecords.toString() + ' records';

            }
          }
          else {
            this.userList = [];
            if (this.f.username.value != "" || this.f.email.value != "") {
              if (this.f.username.value != "" && this.f.email.value == "") {
                this.msg = "Username not found";
              }
              else {
                if (this.f.email.value != "" && this.f.username.value == "") {
                  this.msg = "Email address not found";
                }
                else {
                  this.msg = "Username and email address not found";
                }
              }
            }
            else {
              this.msg = "No records found.";
            }
          }
        }
        else {
          this.userList = [];
        }
        this.loading = false;
      })
      

  }

  clickShowAllUsers() {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = "";
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    
    this._authService.updateAccessTime();
    if (this.userType == "internal" && this.selectedUserType != "internal") {
      if (this.selectedUserType == "customer") {
        if (this.f.customer_select.value == "") {
          this.errMsg = "Please select a client";
          return;
        }
      }

      if (this.selectedUserType == "vendor") {
        if (this.f.vendor_select.value == "") {
          this.errMsg = "Please select a vendor";
          return;
        }
      }
      
    }
    this.f.username.setValue('');
    this.f.email.setValue('');
    this.loadUsers();

  }


  clickResetPwd(event, item: UserModel) {
    if (item.loading == false)
      item.loading = true;
    else
      return;
    //this.userList.forEach(x => {
    //  if (x.username == item.username) {
    //    x.loading = true;
    //  }
    //})
    let request = new IdentityRequestModel();
    request.userId = item.userId;
    request.username = item.username;
    //item.hasResetPwd = true;
    this._identityService.resetPasswordByAdmin<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {
          //console.log(item);
          console.log(response.model);
          item.hasResetPwd = response.model;
          
          //this.userList.forEach(x => {
          //  if (x.username == item.username) {
          //    x.hasResetPwd = true;
          //  }
          //})
        }
        else {
          //this.userList = [];
          item.hasResetPwd = false;
          //this.userList.forEach(x => {
          //  if (x.username == item.username) {
          //    x.hasResetPwd = false;
          //  }
          //})
        }
        item.loading = false;
        //this.userList.forEach(x => {
        //  if (x.username == item.username) {
        //    x.loading = false;
        //  }
        //})
      }
      else {

        this.userList = [];
      }
      item.loading = false;
    })

  }

  clickClose(val) {
    this._dialogRef.close(val);
  }

  openAdminServicesComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3";
    //config.minWidth = "76em";
    //config.maxWidth = "92em";//"1000px";
    config.height = "max-content";
    config.width = "max-content";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "10px", top: "140px" };
    config.data = { gsId: "", tripCodeId: "", isDialog: true, v: this._authService.getCurrentTimeNumber(), 
      customerGUID: this.customerGUID, customerName: this.selectedCustomerName, userType: this.data.userType };

    this.clickClose(true);

    this._dialog.open(AdminServicesClientComponent, config);
  }

}
