import { Component, Inject, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatAccordion } from '@angular/material/expansion';
import { get } from 'http';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { AirportFrequencyModel } from '../models/airport-frequency.model';
import { AirportNotesModel } from '../models/airport-notes.model';
import { AirportModel } from '../models/airport.model';
import { CountryContentModel } from '../models/country-content.model';
import { CountryRegistrationPrefixModel } from '../models/country-registration-prefix.model';
import { CountryModel } from '../models/country.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { HandlingNotesModel } from '../models/handling-notes.model';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { ResponseModel } from '../models/response.model';
import { VendorModel } from '../models/vendor.model';
import { AirportService } from '../services/airport.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { VendorService } from '../services/vendor.service';
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from 'file-saver';
import Docxtemplater from 'docxtemplater';
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { AirportNotesEditDialogComponent } from './airport-notes-edit-dialog.component';
import { NoteTypeModel } from '../models/note-type.model';
import { CountryContentEditDialogComponent } from '../country/country-content-edit-dialog.component';
import { BusinessRulesModel } from '../models/business-rules.model';
import { BusinessRulesService } from '../services/business-rules-service';
import { AirportDocumentModel } from '../models/airport-document.model';
import { AirportDocumentsEditDialogComponent } from './airport-documents-edit-dialog.component';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { AircraftDocumentModel } from '../models/aircraft-document.model';
import { DocumentTypeModel } from '../models/document-type.model';
import { ChangeDetectorRef } from '@angular/core';
import { CountryDocumentModel } from '../models/country-document.model';
import { CountryService } from '../services/country.service';
import { CountryDocumentsEditDialogComponent } from '../country/country-documents-edit-dialog.component';
import { NavigationExtras, Router } from '@angular/router';
import { VendorProfileEditDialogComponent } from '../vendors/vendor-profile-edit-dialog.component';
import { HoursObjModel } from '../models/hoursObj.model';
import { TemplateService } from '../services/template.service';
import { VendorHoursModel } from '../models/vendor-hours.model';
import { VendorDocumentModel } from '../models/vendor-document.model';
import { AirportDetailsEditComponent } from './airport-details-edit.component';

//export interface Marker {
//  latitude: number;
//  longitude: number;
//}
function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

@Component({
    selector: 'app-airport-details',
    templateUrl: './airport-details.component.html',
    styleUrls: ['./airport-details.component.css']
})
/** airport-details component*/
export class AirportDetailsComponent implements OnInit, OnChanges {
  @Input() icao: string;
  @Input() airportID: number;
  @Input() airports: AirportModel[] = [];
  icaoIata: string;
  groundStopGUID: string;
  tripCodeGUID: string;
  errMsg: string;
  airportData: AirportModel = new AirportModel();
  airportNotes: AirportNotesModel[];
  airportDocuments: AirportDocumentModel[];
  airportFrequency: AirportFrequencyModel[];
  countryData: CountryModel = new CountryModel();
  vendorData: VendorModel[];
  vendorRole: VendorModel[];
  allVendorData: VendorModel[];
  fuelSuppliers: VendorModel[];
  registrationPrefix: CountryRegistrationPrefixModel[];
  countryContent: CountryContentModel[];
  countryContentSegment: CountryContentModel[];
  countryContentChild: CountryContentModel[];
  allHandlingNotes: HandlingNotesModel[];
  airportHandlingNotes: HandlingNotesModel[];
  vendorHandlingNotes: HandlingNotesModel[];
  customsNotes: HandlingNotesModel[];
  tripCode: string;
  arrivalDateTime: string;
  departureDateTime: string;
  arrivalDateTimeLocal: string;
  departureDateTimeLocal: string;
  nextICAO: string;
  prevICAO: string="";
  registration: string;
  mapLink: string;
  countryMapLink: string;
  latitude: number;
  longitude: number;
  detailType: string = "airport";
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  isLoading: boolean = false;
  loadValue: number = 0;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  loadingWord: boolean = false;
  loadingPDF: boolean = false;
  noteCount: number = 0;
  userType: string;
  businessRulesList: BusinessRulesModel[];
  customerGuid: string = "";
  isPreferred: boolean = true;
  displayedColumn: string[] = ['brID', 'customerName', 'registration', 'far_type', 'country', 'icao', 'service_class', 'service_type', 'vendorName'];

  msg: string = "";
  showSpin: boolean = false; 
  recordList: AirportDocumentModel[];
  loading: boolean;
  airportDocumentGUID: string = "";
  countryDocumentGUID: string = "";
  documentTypeList: DocumentTypeModel[];
  openAccordions: boolean = false;
  countryDocuments: CountryDocumentModel[];
  countryID: number;
  countryDocumentTypeList:DocumentTypeModel[];
  grantAccessToAirportDocs: boolean = false;
  grantAccessToVendorProfiles: boolean = false;
  ssnVendorID: number;
  hoursData: HoursObjModel[];
  briefType: string = "internal";
  vendorDetails: VendorModel;
  relatedVendorDetails: VendorModel;
  vendorHours: VendorHoursModel[];
  selectedSSNVendorID: number;
  selectedSSNVendorName: string;
  nearbyAirports: AirportModel[];
  distance: string = "50";
  showClose: boolean = false;
    /** airport-details ctor */
  constructor(private readonly _airportService: AirportService, private readonly _countryService: CountryService, private readonly _commonService: CommonService, private readonly _vendorService: VendorService, private readonly _businessRulesService: BusinessRulesService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, @Inject(MAT_DIALOG_DATA) private _data: any, 
    private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog, private cdr: ChangeDetectorRef,
    private _router: Router, private readonly _templateService:TemplateService) {
    if (_data.tripCodeGUID == undefined) {
      this.icaoIata = "";
      this.groundStopGUID = "";
      this.tripCodeGUID = "";
    }
    else {
      this.icao = _data.icao;
      this.icaoIata = _data.icaoIata;
      this.groundStopGUID = _data.groundStopGUID;
      this.tripCodeGUID = _data.tripCodeGUID;
      this.customerGuid = _data.customerGUID;
    }
    if (_data.icao != undefined) {
      this.icao = _data.icao;
    }
    if (_data.showClose != undefined) {
      this.showClose = _data.showClose;
    }

  }
    ngOnChanges(changes: SimpleChanges) {
      this.isLoading = true;
      this._authService.updateAccessTime();
      this.airportDocuments = [];
      this.getData();
    }
  ngOnInit() {
    this.isLoading = true;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this.grantAccessToAirportDocs = false;
    this.grantAccessToVendorProfiles = false;

    if (localStorage.getItem('up.airportDoc') == 'true') {
      this.grantAccessToAirportDocs = true;
    }
    if (localStorage.getItem('up.vPro') == 'true') {
      this.grantAccessToVendorProfiles = true;
    }
    this._authService.updateAccessTime();
    if (this.airportID == undefined) {
      let request = new AirportModel();
      request.route = this.icao;
      this._airportService.getAirportByICAO<ResponseModel<AirportModel[]>>(request).subscribe(response => {
        if (response.model != null) {
          if (response.message == "" && response.code == "200") {
            this.airportID = response.model[0].airportID;
            this.getData();
          }
        }
      });
    }
    //else
    //  this.getData();
  }


  initHoursData() {
    this.hoursData = [
      { dayOfWeek: "Sunday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sun_Open", closeTimeObjName: "sun_Close", closedObjName: "sunClosed", hasHours: false },
      { dayOfWeek: "Monday", abbreviation: "M", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "mon_Open", closeTimeObjName: "mon_Close", closedObjName: "monClosed", hasHours: false },
      { dayOfWeek: "Tuesday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "tue_Open", closeTimeObjName: "tue_Close", closedObjName: "tueClosed", hasHours: false },
      { dayOfWeek: "Wednesday", abbreviation: "W", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "wed_Open", closeTimeObjName: "wed_Close", closedObjName: "wedClosed", hasHours: false },
      { dayOfWeek: "Thursday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "thu_Open", closeTimeObjName: "thu_Close", closedObjName: "thuClosed", hasHours: false },
      { dayOfWeek: "Friday", abbreviation: "F", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "fri_Open", closeTimeObjName: "fri_Close", closedObjName: "friClosed", hasHours: false },
      { dayOfWeek: "Saturday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sat_Open", closeTimeObjName: "sat_Close", closedObjName: "satClosed", hasHours: false }
    ]
  }

  getAllData(): Observable<any[]> {
    let airportResponse = this._airportService.getAirportDataByAirportID<ResponseModel<AirportModel>>(this.airportID)
    let noteTypeResponse = this._commonService.getNoteTypeListByxId<ResponseModel<NoteTypeModel>>(this.airportID.toString(), "Airport");
    let getDocTypeResponse = this._airportService.getAirportDocumentTypeList<ResponseModel<DocumentTypeModel[]>>(this.airportDocumentGUID);
    let getCountryDocTypeResponse = this._countryService.getCountryDocumentTypeList<ResponseModel<DocumentTypeModel[]>>(this.countryDocumentGUID);

    let getGSResponse;
    if (this.groundStopGUID != "") {
      let request = new GroundStopModel();
      request.groundStopGUID = this.groundStopGUID;
      request.tripCodeGUID = this.tripCodeGUID;
      getGSResponse = this._groundStopAdvancedService.getGroundStopForTaskEditBygsId<ResponseModel<GroundStopModel>>(request);

    }
 
    else
      getGSResponse = of(null);
    return forkJoin([airportResponse, noteTypeResponse, getGSResponse, getDocTypeResponse, getCountryDocTypeResponse]);
  }

  getData() {
    this.getAllData().subscribe(responses => {
     
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.airportData = responses[0].model;
          this.nearbyAirports = this.airportData.nearbyAirports.filter(x => x.distanceFromAirport <= Number(this.distance));
          if (this.airportData.iata == null)
            this.icaoIata = this.airportData.icao;
          else
            this.icaoIata = this.airportData.icao + ' / ' + this.airportData.iata;
          if (this.airportData.latitude != null && this.airportData.longitude != null) {
            
            this.latitude = this.airportData.latitude;
            this.longitude = this.airportData.longitude;           
            this.mapLink = "https://maps.google.com/maps?q=" + this.airportData.latitude.toString() + "," + this.airportData.longitude.toString() + "&t=k&z=13&ie=UTF8&iwloc=&output=embed";
          }
          else {
            this.mapLink = "https://maps.google.com/maps?q=" + this.icao + "&t=k&z=13&center=29.98444444,-95.34138889&ie=UTF8&iwloc=&output=embed";
          }
          this.airportNotes = this.airportData.airportNotes;
          this.airportDocuments = this.airportData.documentList;
          this.airportFrequency = this.airportData.airportFrequencies;          
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
          this._commonService.getCountryDetailsByCountryId<ResponseModel<CountryModel>>(this.airportData.countryId).subscribe(response => {
            if (response != null) {
              if (response.code == "200" && response.message == "") {
                this.countryData = response.model;
                this.registrationPrefix = this.countryData.registrationPrefixes;
                this.countryContent = this.countryData.countryContent;
                this.countryDocuments = this.countryData.documentList;
                this.updateCountryDocumentTypes();
                if (this.countryContent != null)
                  this.countryContentSegment = this.countryContent.filter((n, i, arr) => arr.findIndex(c => c.countryContentSegment === n.countryContentSegment) === i);
                this.countryMapLink = "https://maps.google.com/maps?q=" + this.countryData.countryName.replace(" ", "+") + "&t=k&z=5&center=29.98444444,-95.34138889&ie=UTF8&iwloc=&output=embed";
              }
            }

          });
          this._vendorService.getPermitVendorsByAirportID<ResponseModel<VendorModel[]>>(this.airportData.airportID).subscribe(response => {
            if (response != null) {
              if (response.code == "200" && response.message == "") {
                this.vendorData = response.model;
              }
              else {
                if (response.code == "401") {
                  this._authService.signOut();
                }
              }
            }
          });
        this._vendorService.getVendorDetailsByAirportID<ResponseModel<VendorModel[]>>(this.airportData.airportID).subscribe(response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              this.allVendorData = response.model;
              if (this.allVendorData != null) {
                this.vendorRole = this.allVendorData.filter((n, i, arr) => arr.findIndex(c => c.vendorRoleDescription === n.vendorRoleDescription) === i);
                this.fuelSuppliers = this.allVendorData.filter((n, i, arr) => arr.findIndex(c => c.vendorName === n.vendorName && c.vendorRoleDescription === "Fuel Supplier") === i);
                //this.fuelSuppliers = this.fuelSuppliers.filter((n, i, arr) => arr.findIndex(c => c.vendorName === n.vendorName) === i);

              }

            }
            else {
              if (response.code == "401") {
                this._authService.signOut();
              }
            }
          }
        });

      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.noteCount = responses[1].model.length;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }

      }
      if (this.groundStopGUID != "") {
        if (responses[2] != null) {
          if (responses[2].code == "200" && responses[2].message == "") {
            let gs = new GroundStopModel();
            gs = responses[2].model;
            this.tripCode = gs.tripCode;
            //this.icao = gs.icao;
            //this.icaoIata = gs.icaO_IATA;
            this.arrivalDateTime = gs.arrivalDateTextUTC;
            this.departureDateTime = gs.departureDateTextUTC;
            this.arrivalDateTimeLocal = gs.arrivalDateTextLocal;
            this.departureDateTimeLocal = gs.departureDateTextLocal;
            this.nextICAO = gs.nextICAO;
            this.prevICAO = gs.prevICAO;
            this.registration = gs.registration;
            this.aircraftManufacturer = gs.aircraftManufacturer;
            this.aircraftModel = gs.aircraftModel;
            this.aircraftInfo = gs.aircraftInfo;
          }
          else {
            if (responses[2].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.documentTypeList = responses[3].model;
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          this.countryDocumentTypeList = responses[4].model;
          
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      } 

      let request = new BusinessRulesModel();
      request.effectiveDate = new Date().toUTCString();
      request.expirationDate = "";
      request.selectedICAOList = "All," + this.icao;
      request.selectedCountryGUIDList = this.airportData.countryGUID;
      request.selectedApprovalStatusIDList = '1';
      if (this.customerGuid != null)
        request.selectedCustomerGUIDList = "allclients," + this.customerGuid;
      this._businessRulesService.getBusinessRulesByConditions<ResponseModel<BusinessRulesModel[]>>(request).subscribe(response => {
        if (response != null) {
          if (response.message == "" && response.code == "200") {
            this.businessRulesList = response.model;
          }
          else {
            if (response.code == "401") {
              this._authService.signOut()
            }
            else
              this.errMsg = "An error has occurred.";
          }
        }
      });
      this.isLoading = false;
      this.updateAirportDocumentTypes();

    });
     
   

  }
  generateHandlingNotes(format: string, vendorGUID:string) {

    this._vendorService.getVendorDetailsByVendorID<ResponseModel<VendorModel>>(vendorGUID).subscribe(response => {
      if (response.code == "200" && response.message == "") {
        this.vendorDetails = response.model;
        if (this.vendorDetails != null) {
          this.vendorHours = this.vendorDetails.vendorHours;
          if (this.vendorHours != null) {
            this.vendorHours.forEach(h => {
              this.initHoursData();
              if (!h.is24Hours) {
                let sameHours = true;
                let openTime = h.sun_Open;
                let closeTime = h.sun_Close;
                let utcLocal = "Local";
                if (h.isUTC)
                  utcLocal = "UTC";
                closeTime += ' ' + utcLocal;
                this.hoursData.forEach(v => {
                  let utcLocal = "Local";
                  if (h.isUTC)
                    utcLocal = "UTC";
                  switch (v.dayOfWeek) {
                    case "Sunday":
                      if (h.is24Hours || h.sunClosed == 2)
                        v.is24Hours = true;
                      else
                        v.is24Hours = false;
                      v.openTime = h.sun_Open;
                      v.closeTime = h.sun_Close + ' ' + utcLocal;
                      v.isClosed = h.sunClosed;
                      v.hasHours = h.hasSun;
                      if (v.is24Hours || v.isClosed)
                        v.hasHours = true;
                      break;
                    case "Monday":
                      if (h.is24Hours || h.monClosed == 2)
                        v.is24Hours = true;
                      else
                        v.is24Hours = false;
                      v.openTime = h.mon_Open;
                      v.closeTime = h.mon_Close + ' ' + utcLocal;
                      v.isClosed = h.monClosed;
                      v.hasHours = h.hasMon;
                      if (v.is24Hours || v.isClosed)
                        v.hasHours = true;
                      break;
                    case "Tuesday":
                      if (h.is24Hours || h.tueClosed == 2)
                        v.is24Hours = true;
                      else
                        v.is24Hours = false;
                      v.openTime = h.tue_Open;
                      v.closeTime = h.tue_Close + ' ' + utcLocal;
                      v.isClosed = h.tueClosed;
                      v.hasHours = h.hasTue;
                      if (v.is24Hours || v.isClosed)
                        v.hasHours = true;
                      break;
                    case "Wednesday":
                      if (h.is24Hours || h.wedClosed == 2)
                        v.is24Hours = true;
                      else
                        v.is24Hours = false;
                      v.openTime = h.wed_Open;
                      v.closeTime = h.wed_Close + ' ' + utcLocal;
                      v.isClosed = h.wedClosed;
                      v.hasHours = h.hasWed;
                      if (v.is24Hours || v.isClosed)
                        v.hasHours = true;
                      break;
                    case "Thursday":
                      if (h.is24Hours || h.thuClosed == 2)
                        v.is24Hours = true;
                      else
                        v.is24Hours = false;
                      v.openTime = h.thu_Open;
                      v.closeTime = h.thu_Close + ' ' + utcLocal;
                      v.isClosed = h.thuClosed;
                      v.hasHours = h.hasThu;
                      if (v.is24Hours || v.isClosed)
                        v.hasHours = true;
                      break;
                    case "Friday":
                      if (h.is24Hours || h.friClosed == 2)
                        v.is24Hours = true;
                      else
                        v.is24Hours = false;
                      v.openTime = h.fri_Open;
                      v.closeTime = h.fri_Close + ' ' + utcLocal;
                      v.isClosed = h.friClosed;
                      v.hasHours = h.hasFri;
                      if (v.is24Hours || v.isClosed)
                        v.hasHours = true;
                      break;
                    case "Saturday":
                      if (h.is24Hours || h.satClosed == 2)
                        v.is24Hours = true;
                      else
                        v.is24Hours = false;
                      v.openTime = h.sat_Open;
                      v.closeTime = h.sat_Close + ' ' + utcLocal;
                      v.isClosed = h.satClosed;
                      v.hasHours = h.hasSat;
                      if (v.is24Hours || v.isClosed)
                        v.hasHours = true;
                      break;
                  }
                  if (openTime != v.openTime || closeTime != v.closeTime)
                    sameHours = false;
                });
                h.sameHours = sameHours;
                h.openTime = openTime;
                h.closeTime = closeTime;
                h.vendorDailyHours = this.hoursData.filter(x => x.hasHours == true);
              }
            });
          }

          if (this.vendorDetails.relatedVendors != null && this.selectedSSNVendorID != -1) {
            if (this.selectedSSNVendorID != null) {
              this.relatedVendorDetails = this.vendorDetails.relatedVendors.find(x => x.vendorID == this.selectedSSNVendorID);
              this.ssnVendorID = this.relatedVendorDetails.vendorID;
              this.selectedSSNVendorName = this.relatedVendorDetails.vendorName;
            }
            else
              if (this.groundStopGUID == undefined) {
                this.relatedVendorDetails = this.vendorDetails.relatedVendors[0];
                this.ssnVendorID = this.relatedVendorDetails.vendorID;
                this.selectedSSNVendorName = this.relatedVendorDetails.vendorName;
              }
          }
          else {
            this.vendorDetails.relatedVendors = null;
            this.ssnVendorID = 0;
          }

        }

        this._templateService.generateHandlingBrief(format, this.briefType, this.vendorDetails, this.relatedVendorDetails, this.vendorHours, this.icao).subscribe(r => {
          if (r.remarks != "") {
            this.errMsg = r.remarks;
          }
          switch (format) {
            case "word": this.loadingWord = false;
              break;
            case "pdf": this.loadingPDF = false;
              break;
          }


        }); 
      }
      else {
        if (response.code == "401")
          this._authService.signOut();
      }
    });

    //this._vendorService.getVendorDetailsByVendorID<ResponseModel<VendorModel>>(vendorGUID).subscribe(response => {
    //  if (response != null) {
    //    if (response.code == "200" && response.message == "") {
    //      let handlingNotes = response.model;

    //      var self = this;
    //      let filePath = window.origin + "/assets/templates/HandlingNotesTemplate.docx";
    //      //let filePath = "https://localhost:5001/assets/templates/HandlingNotesTemplate.docx";

    //      loadFile(filePath, function (
    //        error,
    //        content
    //      ) {
    //        if (error) {
    //          throw error;
    //        }
    //        const zip = new PizZip(content);
    //        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });

    //        doc.setData({
    //          icao: self.icao,
    //          vendorName: handlingNotes.vendorName,
    //          vendorPerson: handlingNotes.vendorPersons,
    //          vendorContacts: handlingNotes.vendorComms,
    //          airportNotes: handlingNotes.airportNotes,
    //          customsNotes: handlingNotes.customsNotes,
    //          handlingNotes: handlingNotes.handlingNotes

    //        });
    //        try {
    //          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
    //          doc.render();
    //        } catch (error) {
    //          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
    //          function replaceErrors(key, value) {
    //            if (value instanceof Error) {
    //              return Object.getOwnPropertyNames(value).reduce(function (
    //                error,
    //                key
    //              ) {
    //                error[key] = value[key];
    //                return error;
    //              },
    //                {});
    //            }
    //            return value;
    //          }

    //          if (error.properties && error.properties.errors instanceof Array) {
    //            const errorMessages = error.properties.errors
    //              .map(function (error) {
    //                return error.properties.explanation;
    //              })
    //              .join("\n");
    //            //console.log("errorMessages", errorMessages);
    //            self.errMsg = "There was an error creating the report."
    //            // errorMessages is a humanly readable message looking like this:
    //            // 'The tag beginning with "foobar" is unopened'
    //          }
    //          this.loading = false;
    //          throw error;
    //        }
    //        const out = doc.getZip().generate({
    //          type: "blob",
    //          mimeType:
    //            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    //        });
    //        if (format == 'word') {
    //          saveAs(out, "Handling Notes - " + self.icao + ".docx");
    //          self.loadingWord = false;
    //        }
    //        else {
    //          let uri;
              
    //            uri = 'https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf'
    //          const functionURI = uri;

    //          let headers = new HttpHeaders();
    //          const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
    //          headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');


    //          httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {

    //            var fileURL = URL.createObjectURL(data.body);
    //            window.open(fileURL);
    //            self.loadingPDF = false;
    //          });



    //        }
    //      });
    //    }

    //    else
    //      if (response.code == "401")
    //        this._authService.signOut();
    //  }
    //});
  }

  editAirportNote(note: AirportNotesModel) {
    let s = "";
    let airportNoteID = 0;
    if (note == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      airportNoteID = note.airportNotesID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, airportID: this.airportID, airportNoteID: airportNoteID };
    const dialogRef = this._dialog.open(AirportNotesEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }

    });
  }

  editCountryContent(country: CountryContentModel) {
    let s = "";
    let countryContentSegmentID = 0;
    if (country == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      countryContentSegmentID = country.countryContentSegmentID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, countryID: this.airportData.countryId, countryContentSegmentID: countryContentSegmentID };
    const dialogRef = this._dialog.open(CountryContentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();

      }

    });
  }
 

  editAirportDocument(doc: AirportDocumentModel) {
    let s = "";
    let action = "";
    let airportDocumentGUID = "";
    if (doc == null) {
      s = "ADD New Record";
      action = "add";
    }
    else {
      s = "EDIT Record";
      action = "edit";
      airportDocumentGUID = doc.airportDocumentGUID;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = { dialogTitle: s, airportID: this.airportID,   icao: this.icao,
      action: action,  airportDocumentGUID: airportDocumentGUID, document: doc
    };
    const dialogRef = this._dialog.open(AirportDocumentsEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData(); 
      }

    });
  }

  downloadDocument(item: AirportDocumentModel){
    let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
    let file = new Blob([byteArray], { type: item.mimeType });
    saveAs(file, item.documentName);
  } 

  downloadAllAttachments() {
    // this.documentList.forEach((att) => {
    //   this.downloadAttachment(att);                        (click)="downloadAttachment(item)"
    // });
  } 

  getAirportDocuments(item: AirportDocumentModel) {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = false;
    this._authService.updateAccessTime();
    

    this._airportService.getAirportDocument<ResponseModel<AirportDocumentModel[]>>(item).subscribe(response => {
      if (response != null && response.code == "200") {

        // let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        // let file = new Blob([byteArray], { type: item.mimeType });
        // saveAs(file, item.documentName);

        let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        let file = new Blob([byteArray], { type: item.mimeType });
        file.size
        var fileURL = URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = item.documentName;
        link.click();
        // this.downloading1 = false;
 
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    });
 

  }

  updateAirportDocumentTypes() {

    this.airportDocuments?.forEach((airportDoc) => {
      const matchingDocument = this.documentTypeList.find((docType) => docType.documentTypeGUID === airportDoc.documentTypeGUIDList);
      if (matchingDocument) {
        airportDoc.documentTypeList = matchingDocument.documentTypeDescription;
      }
    });
  }

  expandAccordions(){
    this.openAccordions = true;
    this.cdr.detectChanges(); // Trigger change detection
  }

  collapseAccordions(){
    this.openAccordions = false;
    this.cdr.detectChanges(); // Trigger change detection
  }


  
  editCountryDocument(doc: CountryDocumentModel) {
    let s = "";
    let action = "";
    let countryDocumentGUID = "";
    if (doc == null) {
      s = "ADD New Record";
      action = "add";
    }
    else {
      s = "EDIT Record";
      action = "edit";
      countryDocumentGUID = doc.countryDocumentGUID;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = { dialogTitle: s, countryID: this.airportData.countryId,   icao: this.icao, countryName: this.countryData.countryName,
      action: action,  countryDocumentGUID: countryDocumentGUID, document: doc
    };
    const dialogRef = this._dialog.open(CountryDocumentsEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData(); 
      }

    });
  }

  downloadCountryDocument(item: CountryDocumentModel){
    let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
    let file = new Blob([byteArray], { type: item.mimeType });
    saveAs(file, item.documentName);
  } 
 
  getCountryDocuments(item: CountryDocumentModel) {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = false;
    this._authService.updateAccessTime();
    

    this._countryService.getCountryDocument<ResponseModel<CountryDocumentModel[]>>(item).subscribe(response => {
      if (response != null && response.code == "200") {

        // let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        // let file = new Blob([byteArray], { type: item.mimeType });
        // saveAs(file, item.documentName);

        let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        let file = new Blob([byteArray], { type: item.mimeType });
        file.size
        var fileURL = URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = item.documentName;
        link.click();
        // this.downloading1 = false;
 
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    });
 

  }

  updateCountryDocumentTypes() {

    this.countryDocuments?.forEach((countryDoc) => {
      const matchingDocument = this.countryDocumentTypeList.find((docType) => docType.documentTypeGUID === countryDoc.documentTypeGUIDList);
      if (matchingDocument) {
        countryDoc.documentTypeList = matchingDocument.documentTypeDescription;
      }
    });
  }

  
  clickEdit(event: any, profile: VendorModel) {
    // this.setSearchKeys(); 
    event.stopPropagation();
    window.open('/vendors/vendor-profile-setup?vendorGUID=' + profile.vendorGUID +     "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');

  }

  isDateExpired(dateString: string): boolean {
    if(dateString != null)
      return new Date(dateString) < new Date(); 
   return false; 
  }


  viewDocument(doc: VendorDocumentModel) {
    let vendorDocumentID = doc.vendorDocumentGUID;
    this._vendorService.getVendorDocumentByvdId<ResponseModel<VendorDocumentModel>>(vendorDocumentID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let docObj = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
          let file = new Blob([byteArray], { type: docObj.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);

        }
        else {
          this.errMsg = "No Document returned.";
        }
      }
      else {
        this.errMsg = "No Document returned.";
      }
    })

  }

  filterNearbyAirports() {
    this.nearbyAirports = this.airportData.nearbyAirports.filter(x => x.distanceFromAirport <= Number(this.distance));
  }

  openAirportDetails(airport: AirportModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    dialogConfig.data = {
      icao: airport.icao,
      showClose:true

    };
    this._dialog.open(AirportDetailsComponent, dialogConfig);
  }

  editAirport() {
    let s = "";
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
  //  dialogConfig.width = "100%";
    dialogConfig.maxHeight= "95vh";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { airportID: this.airportID };
    const dialogRef = this._dialog.open(AirportDetailsEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }

    });
  }


}
