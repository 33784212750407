import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
//import { CommonService } from '../services/common.service';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { CustomerService } from '../services/customer.service';
import { AircraftModel } from '../models/aircraft.model';
import { CustomerModel } from '../models/customer.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { DistanceTimeModel } from '../models/distance-time.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { TripModel } from '../models/trip.model';
import { GroundStopService } from '../services/ground-stop.service';
import { FARTypeModel } from '../models/far-type.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { element } from 'protractor';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { CustomerGroupService } from '../services/customer-group-service';
import { CustomerGroupModel } from '../models/customer-group.model';
import { TripPersonlistEditorClientDialogComponent } from '../trip-legs-client/trip-personlist-editor-client-dialog.component';

@Component({
  selector: 'app-ground-stop-creator-client-dialog',
  templateUrl: './ground-stop-creator-client-dialog.component.html',
  styleUrls: ['./ground-stop-creator-client-dialog.component.css']
})

export class GroundStopCreatorClientDialogComponent implements OnInit {
  //@Input() dialogTitle: string;
  //@Input() customerGUID: string;

  dialogTitle: string;
 
  ground_stop_creator_client_form: UntypedFormGroup;
  loading = false;

  submitted = false;
  errMsg: string;
  errMsg2: string;
  duplicateIATAErrMsg: string;
  errMsg3: string = "";

  showSpin: boolean = false;
  //userType: string;
  //customerList: CustomerModel[];
  aircraftList: AircraftModel[];
  route: string;
  selectedAircraft: AircraftModel;
  selectedAircraftGUID: string;
  selectedSpeed: number;
  selectTripCodeType: string;
  //displayedColumn: string[] = ['addressUse'];
  isModified: boolean = false;
  loadingAirport: boolean = false;
  groundStopList: GroundStopModel[];
  cityPairList: GroundStopModel[];
  airportList: AirportModel[];
  validatedAirport: boolean = false;
  showSuccessMsg: boolean = false;

  displayedColumnCityPair: string[] = ['departure_airport', 'departureDate_Time', 'arrival_airport', 'arrivalDate_Time',];
  displayedColumnCityPair2: string[] = ['business_stop'];
  farTypeList: FARTypeModel[];
  returnObj = new ReturnObjModel();
  validatedTripCode: boolean = false;
  @ViewChild('submitted_date') submitted_dateRef: ElementRef;
  submitMaxDate: Date = new Date();
  time_type: string;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  minDate: Date = new Date();
  upSubmitTrip: boolean = false;
  isActiveClient: boolean;
  grantAccessToAccountGrouping: boolean = false;
  customerList: CustomerModel[] = [];
  customerGUID: string = "";
  tripCodeGUID: string = "";
  constructor(private readonly _dialogRef: MatDialogRef<GroundStopCreatorClientDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _groundStopClientService: GroundStopClientService,
    private readonly _customerService: CustomerService, private readonly _airportService: AirportService, private readonly _groundStopService: GroundStopService,
    private readonly _dialogService: DialogService, private readonly _customerGroupService: CustomerGroupService, private readonly _dialog: MatDialog
  ) {

    if (_data.aircraftGUID == null || _data.aircraftGUID == undefined) {
      this.selectedAircraftGUID = "";
    }
    else {
      this.selectedAircraftGUID = _data.aircraftGUID;
    }
    this.dialogTitle = _data.dialogTitle;
    this.selectTripCodeType = _data.tripType;
    

  }

  getAllData(): Observable<any[]> {
    let getFARResponse = this._groundStopService.getFARTypeList();
    let getAircraftResponse=this._customerService.getRegistrationListBycIduId<ResponseModel<AircraftModel[]>>('')
    let getClientResponse= this._customerService.getCustomerBycId('');
    let getClistResponse;
    if (this.grantAccessToAccountGrouping) {
      //getClientResponse = of(null);
      let req = new CustomerGroupModel();
      getClistResponse = this._customerGroupService.getSelectedCustomerListBycgrpId(req);
    }
    else {
      //getClientResponse = this._customerService.getCustomerBycId('');
      getClistResponse = of(null);
    }
    return forkJoin([getFARResponse,getAircraftResponse, getClientResponse, getClistResponse]);
   
  }

  ngOnInit() {
    
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.submitTrip') != 'true') {
      this.upSubmitTrip = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    else {
      this.upSubmitTrip = true;
    }
    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;     
    }
   
    this.showSpin = true;
    this.isActiveClient = false;
    this.errMsg = "";
    this.errMsg2 = "";
    this.duplicateIATAErrMsg = "";
    this.errMsg3 = "";
    //this.customerList = [];
    this.aircraftList = [];
    this.route = "";
    if (this.selectTripCodeType=="")
      this.selectTripCodeType = "t-currentYear";
    this.groundStopList = [];
    this.airportList = [];
    this.cityPairList = [];
    this.farTypeList = [];
    this.validatedAirport = false;
    this.showSuccessMsg = false;
    this.time_type = "local";
   
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.farTypeList = responses[0].model;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.aircraftList = responses[1].model;
          if (this.selectedAircraftGUID != "") {
            this.selectedAircraft = this.aircraftList.find(x => x.aircraftGuid.toLowerCase() == this.selectedAircraftGUID.toLowerCase());
          }
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          if (responses[2].model != null) {
            let c = new CustomerModel();
            c = responses[2].model;
            if (c.customerTypeId == 1)
              this.isActiveClient = true;
            if (!this.isActiveClient) {
              this._dialogService.openAlertDialog("Permission denied.", "OK");
              this.clickClose();
              return;
            }
              

          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.customerList = responses[3].model;
          this.customerList = this.customerList.filter(x => x.accountID != '');
          this.customerGUID = this.customerList.find(x=>x.isDefaultClient==true).customerGUID.toLowerCase();
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;

    });
    

  }

  initControls() {
   
    this.ground_stop_creator_client_form = this._formBuilder.group({
      customer_select: [this.customerGUID],
      aircraft_select: [this.selectedAircraft, Validators.required],
      fartype_select: [0, Validators.required],
      route: [this.route, Validators.compose([
        Validators.required,
        Validators.pattern("^[-a-zA-Z0-9,\* \n\r]*$")]
      )],
      //client_reference: [''],     
      client_trip_number: [],     
      special_instruction: '',
      //includeTripEstimate: false,
      accquireAllPermits: false,
      includeFuel: false,
      includeCrewHotel: false,
      includePaxHotel: false,
      includeCrewTransportation: false,
      includePaxTransportation: false,
      //requiredSecurity: false,
      time_type: [this.time_type]

    });

  }

  get f() { return this.ground_stop_creator_client_form.controls; }

  customerChange(e: any) {
    if (this.grantAccessToAccountGrouping) {
      this.customerGUID = this.f.customer_select.value; //e.target.value;
    }
    else {
      this.customerGUID = '';
    }  
    this._customerService.getRegistrationListBycIduId<ResponseModel<AircraftModel[]>>(this.customerGUID).subscribe(response => {
      if (response != null && response.code == "200") {
        this.aircraftList = [];
        this.aircraftList = response.model;
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get aircraft. Please try again later.";
        }
      }
      this.loading = false;
    })
  }

  aircraftChange(e: any) {
    if (e.target.value != null && e.target.value != '') {
      this.selectedAircraft = this.f.aircraft_select.value;
      this.selectedAircraftGUID = this.selectedAircraft.aircraftGuid;
      if (this.selectedAircraft.speed == 0) {
        this.selectedSpeed = 460;
      }
      else {
        this.selectedSpeed = this.selectedAircraft.speed;
      }
    }
    else {
      this.selectedAircraftGUID = "";
      this.selectedSpeed = 460;
    }


  }

  timeTypeChangeChange(e: any) {
    this.time_type = e.target.value;
  }
  //tripCodeTypeChange(e: any) {
  //  if (e.target.value != 'f') {
  //    this.f.trip_code.clearValidators();
  //    this.f.trip_code.updateValueAndValidity();
  //  }
  //  else {
  //    this.f.trip_code.setValidators([Validators.required, Validators.pattern("^[-a-zA-Z0-9]*$"),]);
  //    this.f.trip_code.updateValueAndValidity();
  //  }

  //}

  //tripCodeChange(e: any) {
  //  if (e.target.value != '') {
  //    this.validatedTripCode = true;
  //    this.checkTripCode().subscribe(res1 => {
  //      if ((res1 != null || res1 != undefined) && res1 == true) {

  //      }
  //    });
  //  }

  //}

  //checkTripCode(): Observable<boolean> {
  //  return new Observable<boolean>(ob => {
  //    let request = new TripModel();
  //    request.tripCode = this.f.trip_code.value;

  //    this._groundStopService.checkTripCode<ResponseModel<boolean>>(request).subscribe(response => {
  //      if (response.code == "200") {
  //        if (response.model == true) {
  //          this.f.trip_code.setErrors({ alreadyExisted: true });
  //          ob.next(false);
  //        }
  //        else {
  //          ob.next(true);
  //        }
  //      }
  //      else {
  //        if (response.code == "401") {
  //          //this.errMsg = response.message
  //          this._authService.signOut();
  //        }
  //        ob.next(false);
  //      }
  //      //this.loading = false;
  //    })


  //  });

  //}

  clickValidateAirport() {
    this.errMsg = "";
    this.duplicateIATAErrMsg = "";
    this.loadingAirport = true;
    this.validatedAirport = true;
    if (this.f.route.errors || this.f.aircraft_select.value == "" || this.f.aircraft_select.value == null ) {
      
      this.loadingAirport = false;
      return;
    }

    let request = new AirportModel();

    request.route = this.f.route.value;
    this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.groundStopList = [];
        this.cityPairList = [];
        this.airportList = response.model;
        if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
          let re = /,/gi;
          this.f.route.setValue(Array.prototype.map.call(this.airportList, s => s.icao).toString().replace(re, "-"));
          let i = 0;
          let cityPairOrder = 0;
          this.airportList.forEach((x, index) => {
            let obj = new GroundStopModel();
            obj.icao = x.icao;
            obj.iata = x.iata;
            obj.latitude = null;
            obj.longitude = null;
            if (x.latitude != null && x.longitude != null) {
              obj.latitude = x.latitude;
              obj.longitude = x.longitude;
            }
            obj.arrivalTimeUTC = "";
            obj.departureTimeUTC = "";
            obj.icaO_IATA = x.icao;
            if (x.iata != "") {
              obj.icaO_IATA += " / " + x.iata;
            }
            obj.departureDateUTC = null;
            obj.arrivalDateUTC = null;
            obj.arrivalDateTextUTC = "";
            obj.departureDateTextUTC = "";
            obj.isValidArrDate = true;
            obj.isValidArrTime = true;
            obj.isValidDeptDate = true;
            obj.isValidDeptTime = true;
            obj.arrReadOnly = false;
            obj.depReadOnly = false;
            obj.departureUTCTimeConversion = "";
            obj.arrivalUTCTimeConversion = "";
            //if (i == 0) {
            //  obj.arrReadOnly = true;
            //}
            //else {
            //  if (i == (this.airportList.length - 1)) {
            //    obj.depReadOnly = true;
            //  }
            //}
            i = i + 1;
            obj.groundStopSequenceID = i;
            obj.ete = "";

            obj.depStartAt = new Date();
            obj.arrStartAt = new Date();
            //this.groundStopList.push(obj);
            // add city pair
            cityPairOrder += 1;
            if (index < (this.airportList.length - 1)) {
              obj.departureICAO = obj.icao;
              obj.departureICAO_IATA = obj.departureICAO;
              if (obj.iata != "") {
                if (obj.departureICAO_IATA != "") {
                  obj.departureICAO_IATA += " / ";
                }
                obj.departureICAO_IATA += obj.iata;
              }
              obj.departureLatitude = null;
              obj.departureLongitude = null;
              if (x.latitude != null && x.longitude != null) {
                obj.departureLatitude = x.latitude;
                obj.departureLongitude = x.longitude;
              }
              obj.departureUTCTimeConversion = x.utcTimeConversionDisplay;
              obj.departureDateUTC = null;
              obj.departureDateTextUTC = "";
              obj.departureTimeUTC = "";
              obj.departureDateLocal = null;
              obj.departureDateTextLocal = "";
              obj.departureTimeLocal = "";
              obj.arrivalICAO = this.airportList[index + 1].icao
              obj.arrivalICAO_IATA = obj.arrivalICAO;
              if (this.airportList[index + 1].iata != "") {
                if (obj.arrivalICAO_IATA != "")
                  obj.arrivalICAO_IATA += " / ";
                obj.arrivalICAO_IATA += this.airportList[index + 1].iata;
              }
              obj.arrivalLatitude = null;
              obj.arrivalLongitude = null;
              if (this.airportList[index + 1].latitude != null && this.airportList[index + 1].longitude != null) {
                obj.arrivalLatitude = this.airportList[index + 1].latitude;
                obj.arrivalLongitude = this.airportList[index + 1].longitude;
              }
              obj.arrivalUTCTimeConversion = this.airportList[index + 1].utcTimeConversionDisplay;
              obj.arrivalDateUTC = null;
              obj.arrivalDateTextUTC = "";
              obj.arrivalTimeUTC = "";
              obj.arrivalDateLocal = null;
              obj.arrivalDateTextLocal = "";
              obj.arrivalTimeLocal = "";
              this.cityPairList.push(obj);

            }
            // add city pair end

          });
          //let x = this.cityPairList;
          this.validatedAirport = false;
        }
        if (this.airportList[0].wrongAirportErrMsg != "") {
          this.errMsg = "Invalid airports: " + this.airportList[0].wrongAirportErrMsg;
          this.validatedAirport = true;//false;
        }
        if (this.airportList[0].duplicateIATAErrMsg != "") {
          this.duplicateIATAErrMsg = "Duplicated IATAs: " + this.airportList[0].duplicateIATAErrMsg + ".";
          this.duplicateIATAErrMsg += " Please change to use ICAO.";
          this.validatedAirport = true;
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to validate airport. Please try again later.";
          this.loadingAirport = false;
        }
      }
      this.loadingAirport = false;
    })

  }

  dateInputOnchange(dateType: string, e: any, item: GroundStopModel) {
    if (dateType == "A") {
      item.arrivalDateUTC = null;
      item.arrivalDateLocal = null;
    }
    if (dateType == "D") {
      item.departureDateUTC = null;
      item.departureDateLocal = null;
    }
  }

  dateOnchange(dateType: string, e: any, item: GroundStopModel, dataIndex: number) {
    this.errMsg2 = "";
    if (this.time_type == 'utc') {
      if (dateType == "D") {
        item.departureDateTextLocal = "";
        item.departureDateLocal = null;
        item.departureTimeLocal = "";      
        if (e.target._elementRef.nativeElement.value != "") {
          if (e.target.value == null) {
            item.isValidDeptDate = false;
            item.departureDateTextUTC = e.target._elementRef.nativeElement.value;
          }
          else {
            if (new Date(e.target.value).getUTCFullYear() >= new Date().getUTCFullYear()) {
              item.isValidDeptDate = true;
              item.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(e.target.value);
              //this.getAirportUTCTimeConversion(item, "D", this.time_type);
              this.getAirportUTCTimeConversion(item, "D", this.time_type).subscribe(res1 => {
                if (res1 == true) {
                  this.calculateArrivalDateTime(item);
                  item.depStartAt = new Date(item.departureDateTextUTC);
                  this.cityPairList.forEach((x, index) => {
                    if (index >= dataIndex) {
                      x.arrStartAt = item.depStartAt;
                      x.depStartAt = item.depStartAt;
                    }
                  });
                }
              });
            }
            else {
              item.isValidDeptDate = false;
              item.departureDateTextUTC = e.target._elementRef.nativeElement.value;
            }            
            
          }
        }
        else {
          item.isValidDeptDate = true;
          item.departureDateTextUTC = "";
          item.departureDateUTC = null;
        }
      }

      if (dateType == "A") {
        item.arrivalDateTextLocal = "";
        item.arrivalDateLocal = null;
        item.arrivalTimeLocal = "";      
        if (e.target._elementRef.nativeElement.value != "") {
          if (e.target.value == null) {
            item.isValidArrDate = false;
            item.arrivalDateTextUTC = e.target._elementRef.nativeElement.value;
          }
          else {
            if (new Date(e.target.value).getUTCFullYear() >= new Date().getUTCFullYear()) {
              item.isValidArrDate = true;
              item.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(e.target.value);
              //this.getAirportUTCTimeConversion(item, "A", this.time_type);
              this.getAirportUTCTimeConversion(item, "A", this.time_type).subscribe(res1 => {
                if (res1 == true) {
                  item.arrStartAt = new Date(item.arrivalDateTextUTC);
                  this.calculateDepartureDateTime(item);
                  this.cityPairList.forEach((x, index) => {
                    if (index > dataIndex) {
                      x.depStartAt = item.arrStartAt;
                      x.arrStartAt = item.arrStartAt;
                    }

                  });
                }
              });
            }
            else {
              item.isValidArrDate = false;
              item.arrivalDateTextUTC = e.target._elementRef.nativeElement.value;
            }            
            
          }
        }
        else {
          item.isValidArrDate = true;
          item.arrivalDateTextUTC = "";
          item.arrivalDateUTC = null;
        }
      }
    }
    else { 
      if (dateType == "D") {
        item.departureDateTextUTC = "";
        item.departureDateUTC = null;
        item.departureTimeUTC = "";      
        if (e.target._elementRef.nativeElement.value != "") {
          if (e.target.value == null) {
            item.isValidDeptDate = false;
            item.departureDateTextLocal = e.target._elementRef.nativeElement.value;
          }
          else {
            if (new Date(e.target.value).getUTCFullYear() >= new Date().getUTCFullYear()) {
              item.isValidDeptDate = true;
              item.departureDateTextLocal = CustomValidators.formatDateToMMDDYYYY(e.target.value);
              //this.getAirportUTCTimeConversion(item, "D", this.time_type);
              this.getAirportUTCTimeConversion(item, "D", this.time_type).subscribe(res1 => {
                if (res1 == true) {
                  this.calculateArrivalDateTime(item);
                  item.depStartAt = new Date(item.departureDateTextLocal);
                  this.cityPairList.forEach((x, index) => {
                    if (index >= dataIndex) {
                      x.arrStartAt = item.depStartAt;
                      x.depStartAt = item.depStartAt;
                    }
                  });
                }
              });
            }
            else {
              item.isValidDeptDate = false;
              item.departureDateTextLocal = e.target._elementRef.nativeElement.value;
            }                      
          }
        }
        else {
          item.isValidDeptDate = true;
          item.departureDateTextLocal = "";
          item.departureDateLocal = null;
        }
      }

      if (dateType == "A") {
        item.arrivalDateTextUTC = "";
        item.arrivalDateUTC = null;
        item.arrivalTimeUTC = ""; 
        if (e.target._elementRef.nativeElement.value != "") {
          if (e.target.value == null) {
            item.isValidArrDate = false;
            item.arrivalDateTextLocal = e.target._elementRef.nativeElement.value;
          }
          else {
            if (new Date(e.target.value).getUTCFullYear() >= new Date().getUTCFullYear()) {
              item.isValidArrDate = true;
              item.arrivalDateTextLocal = CustomValidators.formatDateToMMDDYYYY(e.target.value);
              //this.getAirportUTCTimeConversion(item, "A", this.time_type);
              this.getAirportUTCTimeConversion(item, "A", this.time_type).subscribe(res1 => {
                if (res1 == true) {
                  item.arrStartAt = new Date(item.arrivalDateTextLocal);
                  this.calculateDepartureDateTime(item);
                  this.cityPairList.forEach((x, index) => {
                    if (index > dataIndex) {
                      x.depStartAt = item.arrStartAt;
                      x.arrStartAt = item.arrStartAt;
                    }

                  });
                }
              });
            }
            else {
              item.isValidArrDate = false;
              item.arrivalDateTextLocal = e.target._elementRef.nativeElement.value;
            }           
          }
        }
        else {
          item.isValidArrDate = true;
          item.arrivalDateTextLocal = "";
          item.arrivalDateLocal = null;
        }
      }

    }

  }

  timeOnchange(dateType: string, e: any, item: GroundStopModel) {
    this.errMsg2 = "";
    if (this.time_type == 'utc') {
      if (dateType == "D") {
        item.departureDateTextLocal = "";
        item.departureDateLocal = null;
        item.departureTimeLocal = ""; 
        if (e.target.value != "") {
          let timeObj = new DateTimeObjModel();
          timeObj.timeString = e.target.value;
          timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
          item.departureTimeUTC = timeObj.timeString;
          item.isValidDeptTime = timeObj.isValidDateTime;
          if (item.isValidDeptTime) {
            //this.getAirportUTCTimeConversion(item, "D", this.time_type);
            this.getAirportUTCTimeConversion(item, "D", this.time_type).subscribe(res1 => {
              if (res1 == true) {
                this.calculateArrivalDateTime(item);
              }
            });
            
          }

        }
        else {
          item.departureTimeUTC = "";
          item.isValidDeptTime = true;
        }
      }

      if (dateType == "A") {
        item.arrivalDateTextLocal = "";
        item.arrivalDateLocal = null;
        item.arrivalTimeLocal = ""; 
        if (e.target.value != "") {
          let timeObj = new DateTimeObjModel();
          timeObj.timeString = e.target.value;
          timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
          item.arrivalTimeUTC = timeObj.timeString;
          item.isValidArrTime = timeObj.isValidDateTime;
          if (item.isValidArrTime) {
           //this.getAirportUTCTimeConversion(item, "A", this.time_type);
            this.getAirportUTCTimeConversion(item, "A", this.time_type).subscribe(res1 => {
              if (res1 == true) {
                this.calculateDepartureDateTime(item);
              }
            });
            
          }

        }
        else {
          item.arrivalTimeUTC = "";
          item.isValidArrTime = true;
        }
      }
    }
    else {
      if (dateType == "D") {
        item.departureDateTextUTC = "";
        item.departureDateUTC = null;
        item.departureTimeUTC = ""; 
        if (e.target.value != "") {
          let timeObj = new DateTimeObjModel();
          timeObj.timeString = e.target.value;
          timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
          item.departureTimeLocal = timeObj.timeString;
          item.isValidDeptTime = timeObj.isValidDateTime;
          if (item.isValidDeptTime) {
            //this.getAirportUTCTimeConversion(item, "D", this.time_type);
            this.getAirportUTCTimeConversion(item, "D", this.time_type).subscribe(res1 => {
              if (res1 == true) {
                this.calculateArrivalDateTime(item);
              }
            });
            
          }

        }
        else {
          item.departureTimeLocal = "";
          item.isValidDeptTime = true;
        }
      }

      if (dateType == "A") {
        item.arrivalDateTextUTC = "";
        item.arrivalDateUTC = null;
        item.arrivalTimeUTC = ""; 
        if (e.target.value != "") {
          let timeObj = new DateTimeObjModel();
          timeObj.timeString = e.target.value;
          timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
          item.arrivalTimeLocal = timeObj.timeString;
          item.isValidArrTime = timeObj.isValidDateTime;
          if (item.isValidArrTime) {
            //this.getAirportUTCTimeConversion(item, "A", this.time_type);
            this.getAirportUTCTimeConversion(item, "A", this.time_type).subscribe(res1 => {
              if (res1 == true) {
                this.calculateDepartureDateTime(item);
              }
            });
            
          }

        }
        else {
          item.arrivalTimeLocal = "";
          item.isValidArrTime = true;
        }
      }
    }
    
  }

  getAirportUTCTimeConversion(objCityPair: GroundStopModel, dateType: string, time_type: string): Observable<boolean> {
    return new Observable<boolean>(ob => {
      let request = new AirportModel();
      if (time_type == 'utc') {
        if (dateType == "D") {
          request.icao = objCityPair.departureICAO;
          request.airportUTCDateTime = objCityPair.departureDateTextUTC;
          if (objCityPair.departureTimeUTC != "") {
            request.airportUTCDateTime += " " + objCityPair.departureTimeUTC;
          }
        }
        if (dateType == "A") {
          request.icao = objCityPair.arrivalICAO;
          request.airportUTCDateTime = objCityPair.arrivalDateTextUTC;
          if (objCityPair.arrivalTimeUTC != "") {
            request.airportUTCDateTime += " " + objCityPair.arrivalTimeUTC;
          }
        }

        this._airportService.getAirportLocalTimeFromUTC<ResponseModel<AirportModel>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            let obj = new AirportModel();
            obj = response.model;
            if (dateType == "D") {
              objCityPair.departureUTCTimeConversion = obj.utcTimeConversionDisplay;
              //if (objCityPair.departureDateTextUTC != "" && objCityPair.departureTimeUTC != "") {
                let ldt = obj.airportLocalDateTime;
                if (ldt != "") {
                  let i = ldt.indexOf(' ');
                  let d = ldt.substring(0, i);
                  let t = ldt.substring(i + 1);
                  
                  objCityPair.departureDateLocal = new Date(d);
                  objCityPair.departureDateTextLocal = d;
                  if (objCityPair.departureTimeUTC != "")
                    objCityPair.departureTimeLocal = t;
                  else
                    objCityPair.departureTimeLocal = "";
                  
                }
              //}
            }
            if (dateType == "A") {
              objCityPair.arrivalUTCTimeConversion = obj.utcTimeConversionDisplay;
              //if (objCityPair.arrivalDateTextUTC != "" && objCityPair.arrivalTimeUTC != "") {
                let ldt = obj.airportLocalDateTime;
                if (ldt != "") {
                  let i = ldt.indexOf(' ');
                  let d = ldt.substring(0, i);
                  let t = ldt.substring(i + 1);

                  objCityPair.arrivalDateLocal = new Date(d);
                  objCityPair.arrivalDateTextLocal = d;
                  if (objCityPair.arrivalTimeUTC != "")
                    objCityPair.arrivalTimeLocal = t;
                  else
                    objCityPair.arrivalTimeLocal = "";

                }
              //}
            }
            ob.next(true);
            //let ldt = obj.airportLocalDateTime;
            //if (ldt != "") {
            //  let i = ldt.indexOf(' ');
            //  let d = ldt.substring(0, i);
            //  let t = ldt.substring(i + 1);
            //  if (dateType == "A") {
            //    objCityPair.arrivalDateLocal = new Date(d);
            //    objCityPair.arrivalDateTextLocal = d;
            //    objCityPair.arrivalTimeLocal = t;
            //  }
            //  if (dateType == "D") {
            //    objCityPair.departureDateLocal = new Date(d);
            //    objCityPair.departureDateTextLocal = d;
            //    objCityPair.departureTimeLocal = t;
            //  }
            //}


          }
          else {
            ob.next(false);
            if (response.code == "401") {

              this._authService.signOut();
            }

          }

        })

      }
      else {
        if (dateType == "D") {
          request.icao = objCityPair.departureICAO;
          request.airportLocalDateTime = objCityPair.departureDateTextLocal;
          if (objCityPair.departureTimeLocal != "") {
            request.airportLocalDateTime += " " + objCityPair.departureTimeLocal;
          }
        }
        if (dateType == "A") {
          request.icao = objCityPair.arrivalICAO;
          request.airportLocalDateTime = objCityPair.arrivalDateTextLocal;
          if (objCityPair.arrivalTimeLocal != "") {
            request.airportLocalDateTime += " " + objCityPair.arrivalTimeLocal;
          }
        }

        this._airportService.getAirportUTCTimeFromLocal<ResponseModel<AirportModel>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            let obj = new AirportModel();
            obj = response.model;
            if (dateType == "D") {
              objCityPair.departureUTCTimeConversion = obj.utcTimeConversionDisplay;
              
              let ldt = obj.airportUTCDateTime;
              if (ldt != "") {
                let i = ldt.indexOf(' ');
                let d = ldt.substring(0, i);
                let t = ldt.substring(i + 1);
                  
                objCityPair.departureDateUTC = new Date(d);//
                objCityPair.departureDateTextUTC = d;
                if (objCityPair.departureTimeLocal != "")
                  objCityPair.departureTimeUTC = t;
                else
                  objCityPair.departureTimeUTC = "";                  
              }
             
            }
            if (dateType == "A") {
              objCityPair.arrivalUTCTimeConversion = obj.utcTimeConversionDisplay;
              
              let ldt = obj.airportUTCDateTime;
              if (ldt != "") {
                let i = ldt.indexOf(' ');
                let d = ldt.substring(0, i);
                let t = ldt.substring(i + 1);

                objCityPair.arrivalDateUTC = new Date(d);
                objCityPair.arrivalDateTextUTC = d;
                if (objCityPair.arrivalTimeLocal != "")
                  objCityPair.arrivalTimeUTC = t;
                else
                  objCityPair.arrivalTimeUTC = "";

              }
              
            }
            ob.next(true);
            //let ldt = obj.airportUTCDateTime;
            //if (ldt != "") {
            //  let i = ldt.indexOf(' ');
            //  let d = ldt.substring(0, i);
            //  let t = ldt.substring(i + 1);
            //  if (dateType == "A") {
            //    objCityPair.arrivalDateUTC = new Date(d);
            //    objCityPair.arrivalDateTextUTC = d;
            //    objCityPair.arrivalTimeUTC = t;
            //  }
            //  if (dateType == "D") {
            //    objCityPair.departureDateUTC = new Date(d);
            //    objCityPair.departureDateTextUTC = d;
            //    objCityPair.departureTimeUTC = t;
            //  }
            //}

          }
          else {
            ob.next(false);
            if (response.code == "401") {

              this._authService.signOut();
            }

          }

        })

      }
      //ob.next(false);
    });
  }

  calculateArrivalDateTime(objCityPair: GroundStopModel) {
    if (objCityPair.departureDateTextUTC != "" && objCityPair.departureTimeUTC != "" && objCityPair.arrivalDateTextUTC != "" && objCityPair.arrivalTimeUTC != "") {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("You updated the Departure Date / Time it might impact Arrival Date time. Do you want to update arrival date / time?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          objCityPair.arrivalDateTextUTC = "";
          objCityPair.arrivalTimeUTC = "";
          objCityPair.arrivalDateTextLocal = "";
          objCityPair.arrivalTimeLocal = "";
          this.getArrivalDateTime(objCityPair);
        }
      });
    }
    else {
      this.getArrivalDateTime(objCityPair);
    }


  }

  getArrivalDateTime(objCityPair: GroundStopModel) {
    if (objCityPair.departureDateTextUTC != "" && objCityPair.departureTimeUTC != "" && (objCityPair.arrivalDateTextUTC == "" || objCityPair.arrivalTimeUTC == "")) {
      if (objCityPair.isValidDeptDate && objCityPair.isValidDeptTime) {
        if (objCityPair.departureLatitude != null && objCityPair.departureLongitude != null && objCityPair.arrivalLatitude != null && objCityPair.arrivalLongitude != null) {
          let request = new DistanceTimeModel();
          request.departureLatitude = objCityPair.departureLatitude;
          request.departureLongitude = objCityPair.departureLongitude;
          request.arrivalLatitude = objCityPair.arrivalLatitude;
          request.arrivalLongitude = objCityPair.arrivalLongitude;
          request.airSpeed = this.selectedSpeed; //460;
          request.bias = 15;
          this._airportService.getDistanceTripTime<ResponseModel<DistanceTimeModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              let obj = new DistanceTimeModel();
              obj = response.model;
              let h = Number(obj.tripTimeHour);
              let m = Number(obj.tripTimeMinute);
              let dt = new Date(objCityPair.departureDateTextUTC + " " + objCityPair.departureTimeUTC);
              let adt = UtilityFunctions.addHours(dt, h);
              adt = UtilityFunctions.addMinutes(dt, m);

              objCityPair.arrivalDateUTC = adt
              objCityPair.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(objCityPair.arrivalDateUTC);
              let ah = adt.getHours();
              let am = adt.getMinutes();
              if (am < 10) {
                objCityPair.arrivalTimeUTC = ah.toString() + ":0" + am.toString();
              }
              else {
                objCityPair.arrivalTimeUTC = ah.toString() + ":" + am.toString();
              }
              objCityPair.ete = obj.tripTime;
              //this.getAirportUTCTimeConversion(objCityPair, "A", "utc");
              this.getAirportUTCTimeConversion(objCityPair, "A", "utc").subscribe(res1 => {
                if (res1 == true) {

                }
              });
            }
            else {
              if (response.code == "401") {
                //this.errMsg = response.message
                this._authService.signOut();
              }

            }
            this.loading = false;
          })
        }
      }
    }
  }

  calculateDepartureDateTime(objCityPair: GroundStopModel) {
    if (objCityPair.arrivalDateTextUTC != "" && objCityPair.arrivalTimeUTC != "" && (objCityPair.departureDateTextUTC == "" || objCityPair.departureTimeUTC == "")) {
      if (objCityPair.isValidArrDate && objCityPair.isValidArrTime) {
        if (objCityPair.departureLatitude != null && objCityPair.departureLongitude != null && objCityPair.arrivalLatitude != null && objCityPair.arrivalLongitude != null) {
          let request = new DistanceTimeModel();
          request.departureLatitude = objCityPair.departureLatitude;
          request.departureLongitude = objCityPair.departureLongitude;
          request.arrivalLatitude = objCityPair.arrivalLatitude;
          request.arrivalLongitude = objCityPair.arrivalLongitude;
          request.airSpeed = this.selectedSpeed; //460;
          request.bias = 15;
          this._airportService.getDistanceTripTime<ResponseModel<DistanceTimeModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              let obj = new DistanceTimeModel();
              obj = response.model;
              let h = Number(obj.tripTimeHour);
              let m = Number(obj.tripTimeMinute);
              let dt = new Date(objCityPair.arrivalDateTextUTC + " " + objCityPair.arrivalTimeUTC);
              let ddt = UtilityFunctions.addHours(dt, -h);
              ddt = UtilityFunctions.addMinutes(dt, -m);
              objCityPair.departureDateUTC = ddt;
              objCityPair.depStartAt = objCityPair.departureDateUTC;
              objCityPair.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(objCityPair.departureDateUTC);
              let dh = ddt.getHours();
              let dm = ddt.getMinutes();
              if (dm < 10) {
                objCityPair.departureTimeUTC = dh.toString() + ":0" + dm.toString();
              }
              else {
                objCityPair.departureTimeUTC = dh.toString() + ":" + dm.toString();
              }
              objCityPair.ete = obj.tripTime;
              //this.getAirportUTCTimeConversion(objCityPair, "D", "utc");
              this.getAirportUTCTimeConversion(objCityPair, "D", "utc").subscribe(res1 => {
                if (res1 == true) {

                }
              });
            }
            else {
              if (response.code == "401") {
                //this.errMsg = response.message
                this._authService.signOut();
              }

            }
            this.loading = false;
          })


        }
      }
    }

  }

  clickSave() {
    this.errMsg2 = "";
    this._authService.updateAccessTime();
    if (this.f.fartype_select.value == 0) {
      this.f.fartype_select.setErrors({ required: true });
    }
    //this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure that you want to submit the trip to FPI?");
    //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
    //  this.eventSubscription.unsubscribe();
    //  if (result) {
        this.submitted = true;
        if (this.ground_stop_creator_client_form.invalid || this.errMsg != "") {
          return;
        }

        this.clickSave2();
    //  }
      
    //});

  }

  clickSave2() {
    this.errMsg2 = "";
    this.errMsg3 = "";
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    
    this.cityPairList.forEach(x => {
      x.isValidArrDate = true;
      x.isValidArrTime = true;
      x.isValidDeptDate = true;
      x.isValidDeptTime = true;
      x.arrivalPetsOnBoard = false;
      x.departurePetsOnBoard = false;
      x.arrivalWeaponsOnBoard = false;
      x.departurePetsOnBoard = false;

    });

    let hasAllValidFields = true;
    this.cityPairList.forEach((x, index) => {
      
      if (x.departureDateTextUTC != "") {
        if (new Date(x.departureDateTextUTC).getUTCFullYear() < new Date().getUTCFullYear()) {
          x.isValidDeptDate = false;
          if (hasAllValidFields == true) {
            hasAllValidFields = x.isValidDeptDate;
          }
        }
        else {
          x.isValidDeptDate = CustomValidators.isValidDate(x.departureDateTextUTC);
          if (hasAllValidFields == true && x.isValidDeptDate == false) {
            hasAllValidFields = x.isValidDeptDate;
          }
          if (x.isValidDeptDate) {
            x.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.departureDateTextUTC));
            x.departureDateUTC = new Date(x.departureDateTextUTC);
          }
        }
        
      }
      
      if (x.departureTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.departureTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.departureTimeUTC = timeObj.timeString;
        let depttv = timeObj.isValidDateTime;
        x.isValidDeptTime = depttv;
        if (hasAllValidFields == true && depttv == false) {
          hasAllValidFields = depttv;
        }
      }

      if (x.arrivalDateTextUTC != "") {
        if (new Date(x.arrivalDateTextUTC).getUTCFullYear() < new Date().getUTCFullYear()) {
          x.isValidArrDate = false;
          if (hasAllValidFields == true) {
            hasAllValidFields = x.isValidArrDate;
          }
        }
        else {
          x.isValidArrDate = CustomValidators.isValidDate(x.arrivalDateTextUTC);
          if (hasAllValidFields == true && x.isValidArrDate == false) {
            hasAllValidFields = x.isValidArrDate;
          }
          if (x.isValidArrDate) {
            x.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.arrivalDateTextUTC));
            x.arrivalDateUTC = new Date(x.arrivalDateTextUTC);
          }
        }        
      }

      if (x.arrivalTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.arrivalTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.arrivalTimeUTC = timeObj.timeString;
        let arrtv = timeObj.isValidDateTime;
        x.isValidArrTime = arrtv;
        if (hasAllValidFields == true && arrtv == false) {
          hasAllValidFields = arrtv;
        }
      }

      if (x.departureDateUTC != null && x.arrivalDateUTC != null) {
        if (x.departureDateUTC.getTime() > x.arrivalDateUTC.getTime()) {
          this.errMsg2 = "Departure date must be earlier than the arrival date.";
          return;
        }
        else {
          if (x.departureTimeUTC != "" && x.arrivalTimeUTC != "") {
            let td = new Date(x.departureDateTextUTC + " " + x.departureTimeUTC);
            let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);

            if (td.getTime() > ta.getTime()) {
              this.errMsg2 = "Departure date time must be earlier than the arrival date time.";
              return;
            }
            else {
              let dif = (ta.getTime() - td.getTime()) / 1000 / 3600;
              if (dif >= 24) {
                this.errMsg2 = "ETE can not be more than 24 hours.";
                return;
              }
            }
          }
        }
      }

      if (index > 0) {
        if (x.departureDateUTC != null) {
          if (this.cityPairList[index - 1].departureDateUTC != null) {
            let d1 = new Date(this.cityPairList[index - 1].departureDateUTC);
            let d2 = new Date(x.departureDateUTC);
            if (d1.getTime() > d2.getTime()) {
              this.errMsg2 = "The city pairs must be in chronological order.";
              return;
            }
          }
          else {
            if (this.cityPairList[index - 1].arrivalDateUTC != null) {
              let d1 = new Date(this.cityPairList[index - 1].arrivalDateUTC);
              let d2 = new Date(x.departureDateUTC);
              if (d1.getTime() > d2.getTime()) {
                this.errMsg2 = "The city pairs must be in chronological order.";
                return;
              }
            }
          }

          if (x.departureTimeUTC != "") {
            if (this.cityPairList[index - 1].departureDateUTC != null && this.cityPairList[index - 1].departureTimeUTC != "") {
              let d1 = new Date(this.cityPairList[index - 1].departureDateTextUTC + " " + this.cityPairList[index - 1].departureTimeUTC);
              let d2 = new Date(x.departureDateTextUTC + " " + x.departureTimeUTC);
              if (d1.getTime() > d2.getTime()) {
                this.errMsg2 = "The city pairs must be in chronological order.";
                return;
              }
            }
            else {
              if (this.cityPairList[index - 1].arrivalDateUTC != null && this.cityPairList[index - 1].arrivalTimeUTC != "") {
                let d1 = new Date(this.cityPairList[index - 1].arrivalDateTextUTC + " " + this.cityPairList[index - 1].arrivalTimeUTC);
                let d2 = new Date(x.departureDateTextUTC + " " + x.departureTimeUTC);
                if (d1.getTime() > d2.getTime()) {
                  this.errMsg2 = "The city pairs must be in chronological order.";
                  return;
                }
              }
            }
          }
        }

      }

    });

    this.groundStopList = [];
    let gs: GroundStopModel;
    this.cityPairList.forEach((x, index) => {
      gs = new GroundStopModel();
      gs.icao = x.departureICAO;
      gs.ete = x.ete;
      gs.arrivalPetsOnBoard = false;
      gs.departurePetsOnBoard = false;
      gs.departureWeaponsOnBoard = false;
      gs.arrivalWeaponsOnBoard = false;
      gs.isTechStop = false;
      gs.callSign = "";
      if (index == 0) {
        gs.arrivalDateUTC = null;
        gs.arrivalDateTextUTC = "";
        gs.arrivalTimeUTC = "";
        gs.arrivalDateLocal = null;
        gs.arrivalDateTextLocal = "";
        gs.arrivalTimeLocal = "";
        gs.arrivalUTCTimeConversion = "";
        gs.departureDateUTC = x.departureDateUTC;
        gs.departureDateTextUTC = x.departureDateTextUTC;
        gs.departureTimeUTC = x.departureTimeUTC;
        gs.departureDateLocal = x.departureDateLocal;
        gs.departureDateTextLocal = x.departureDateTextLocal;
        gs.departureTimeLocal = x.departureTimeLocal;
        gs.departureUTCTimeConversion = x.departureUTCTimeConversion;
      }
      else {
        if ((index > 0)) {
          gs.arrivalDateUTC = this.cityPairList[index - 1].arrivalDateUTC;
          gs.arrivalDateTextUTC = this.cityPairList[index - 1].arrivalDateTextUTC;
          gs.arrivalTimeUTC = this.cityPairList[index - 1].arrivalTimeUTC;
          gs.arrivalDateLocal = this.cityPairList[index - 1].arrivalDateLocal;
          gs.arrivalDateTextLocal = this.cityPairList[index - 1].arrivalDateTextLocal;
          gs.arrivalTimeLocal = this.cityPairList[index - 1].arrivalTimeLocal;
          gs.arrivalUTCTimeConversion = this.cityPairList[index - 1].arrivalUTCTimeConversion;

          gs.departureDateUTC = x.departureDateUTC;
          gs.departureDateTextUTC = x.departureDateTextUTC;
          gs.departureTimeUTC = x.departureTimeUTC;         
          gs.departureDateLocal = x.departureDateLocal;
          gs.departureDateTextLocal = x.departureDateTextLocal;
          gs.departureTimeLocal = x.departureTimeLocal;
          gs.departureUTCTimeConversion = x.departureUTCTimeConversion;
        }
      }
      this.groundStopList.push(gs);

    });

    // add last one
    gs = new GroundStopModel();
    gs.icao = this.cityPairList[this.cityPairList.length - 1].arrivalICAO;
    gs.arrivalDateUTC = this.cityPairList[this.cityPairList.length - 1].arrivalDateUTC;
    gs.arrivalDateTextUTC = this.cityPairList[this.cityPairList.length - 1].arrivalDateTextUTC;
    gs.arrivalTimeUTC = this.cityPairList[this.cityPairList.length - 1].arrivalTimeUTC;
    gs.arrivalDateLocal = this.cityPairList[this.cityPairList.length - 1].arrivalDateLocal;
    gs.arrivalDateTextLocal = this.cityPairList[this.cityPairList.length - 1].arrivalDateTextLocal;
    gs.arrivalTimeLocal = this.cityPairList[this.cityPairList.length - 1].arrivalTimeLocal;
    gs.arrivalUTCTimeConversion = this.cityPairList[this.cityPairList.length - 1].arrivalUTCTimeConversion;
    gs.departureDateUTC = null;
    gs.departureDateTextUTC = "";
    gs.departureTimeUTC = "";
    gs.departureDateLocal = null;
    gs.departureDateTextLocal = "";
    gs.departureTimeLocal = "";
    gs.departureUTCTimeConversion = "";
    gs.ete = "";
    gs.arrivalPetsOnBoard = false;
    gs.departurePetsOnBoard = false;
    gs.departureWeaponsOnBoard = false;
    gs.arrivalWeaponsOnBoard = false;
    gs.isTechStop = false;
    gs.callSign = "";
    this.groundStopList.push(gs);
    // add last one end

    this.groundStopList.forEach((x, index) => {
      if (x.arrivalDateTextUTC != "") {
        x.isValidArrDate = CustomValidators.isValidDate(x.arrivalDateTextUTC);
        if (hasAllValidFields == true && x.isValidArrDate == false) {
          hasAllValidFields = x.isValidArrDate;
        }
        if (x.isValidArrDate) {
          x.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.arrivalDateTextUTC));
          x.arrivalDateUTC = new Date(x.arrivalDateTextUTC);
        }
      }

      if (x.arrivalTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.arrivalTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.arrivalTimeUTC = timeObj.timeString;
        let arrtv = timeObj.isValidDateTime;
        x.isValidArrTime = arrtv;
        if (hasAllValidFields == true && arrtv == false) {
          hasAllValidFields = arrtv;
        }
      }
      // dept
      if (x.departureDateTextUTC != "") {
        if (x.departureDateTextUTC != "") {
          x.isValidDeptDate = CustomValidators.isValidDate(x.departureDateTextUTC);
          if (hasAllValidFields == true && x.isValidDeptDate == false) {
            hasAllValidFields = x.isValidDeptDate;
          }
          if (x.isValidDeptDate) {
            x.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.departureDateTextUTC));
            x.departureDateUTC = new Date(x.departureDateTextUTC);
          }
        }
      }

      if (x.departureTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.departureTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.departureTimeUTC = timeObj.timeString;
        let depttv = timeObj.isValidDateTime;
        x.isValidDeptTime = depttv;
        if (hasAllValidFields == true && depttv == false) {
          hasAllValidFields = depttv;
        }
      }

      if (index > 0) {
        let prev = this.groundStopList[index - 1];
        if (x.arrivalDateUTC != null && prev.departureDateUTC != null) {
          if (x.arrivalDateUTC.getTime() < prev.departureDateUTC.getTime()) {
            this.errMsg2 = "Arrival date can not be earlier than the departure date of the previous business stop.";
            return;
          }
          else {
            if (x.arrivalTimeUTC != "" && prev.departureTimeUTC != "") {
              let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);
              let td = new Date(prev.departureDateTextUTC + " " + prev.departureTimeUTC);
              if (ta.getTime() < td.getTime()) {
                this.errMsg2 = "Arrival date time can not be earlier than the departure date time of the previous business stop.";
                return;
              }
              else {
                let dif = (ta.getTime() - td.getTime()) / 1000 / 3600;
                if (dif >= 24) {
                  this.errMsg2 = "ETE can not be more than 24 hours.";
                  return;
                }

              }
            }
          }
        }

        if (x.arrivalDateUTC != null && x.departureDateUTC != null) {
          if (x.arrivalDateUTC.getTime() > x.departureDateUTC.getTime()) {
            this.errMsg2 = "Arrival date time can not be later than the next departure date time.";
            return;
          }
          else {
            if (x.arrivalTimeUTC != "" && x.departureTimeUTC != "") {
              let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);
              let td = new Date(x.departureDateTextUTC + " " + x.departureTimeUTC);
              if (ta.getTime() > td.getTime()) {
                this.errMsg2 = "Arrival date time can not be later than the next departure date time.";
                return;

              }
            }

          }

        }
      }

    });

    if (this.ground_stop_creator_client_form.invalid || this.errMsg != "" || this.errMsg2 != "" || !hasAllValidFields) {
      return;
    }

   
    this.loading = true;

    let obj = new TripModel();
    obj.customerGUID = "";
    if (this.grantAccessToAccountGrouping) {
      obj.customerGUID = this.f.customer_select.value;
    }
    obj.aircraftGUID = this.selectedAircraftGUID;// this.f.aircraft_select.value;
    obj.fARTypeID = Number(this.f.fartype_select.value);
    obj.fARTypeDescription = "";
    if (this.f.fartype_select.value != "0") {
      obj.fARTypeDescription = this.farTypeList.find(x => x.farTypeID == obj.fARTypeID).farTypeDescription;
    }    
    obj.tripCodeType = this.selectTripCodeType; // this.f.trip_code_type_select.value;   
    obj.customerReference = ""; //this.f.client_reference.value;   
    obj.customerTripID = this.f.client_trip_number.value;
    obj.clientInstructions = this.f.special_instruction.value;
    obj.include_TripEstimate = false;// this.f.includeTripEstimate.value;
    obj.include_AcquireAllPermits = this.f.accquireAllPermits.value;
    obj.include_FuelQuotesArrangementsOnAllLegs = this.f.includeFuel.value;
    obj.include_CrewHotelQuotesArrangementsOnAllLegs = this.f.includeCrewHotel.value;
    obj.include_PAXHotelQuotesArrangementsOnAllLegs = this.f.includePaxHotel.value;
    obj.include_CrewTranspOnAllLegs = this.f.includeCrewTransportation.value;
    obj.include_PAXTranspOnAllLegs = this.f.includePaxTransportation.value;
    obj.include_SecurityCoordinationOnAllLegs = false;// this.f.requiredSecurity.value;
   
    obj.groundStopList = this.groundStopList;
    
    this._groundStopClientService.createTempTripByClient<ResponseModel<TripModel>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {         
          let t = new TripModel();
          t = response.model;
          if (t.tripCode != "") {
            this.isModified = true;
            this.returnObj.refresh = this.isModified;
            this.returnObj.newId = t.tripCodeGUID.toLowerCase();
            this.returnObj.aircraftGUID = obj.aircraftGUID;
            this.returnObj.customerGUID = obj.customerGUID;
            this.customerGUID = obj.customerGUID;
            this.tripCodeGUID = t.tripCodeGUID.toLowerCase();        

            //this.showSuccessMsg = true;
            //setTimeout(() => {
            //  this.showSuccessMsg = false;                          
            //  this.clickClose();
            //}, 1000);
           
            // open another dialog box
            //this.openTripPersonSelection();
            this.clickClose();
                   
          }
        }
        else {          
          this.errMsg = "Failed to create the business stops at this time. Please try later.";
          
        }
      }
      else {
        if (response.code == "401") {
          if (response.message != "") {
            this.errMsg3 = response.message;
          }
          else {
            this._authService.signOut();
          }
        }
        else {
          this.errMsg3 = "Failed to create the business stops at this time. Please try later.";
        }
      }
      this.loading = false;
    })

  }

  openTripPersonSelection() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, customerGUID: this.customerGUID, tripCodeGUID: this.tripCodeGUID, aircraftGUID: this.selectedAircraftGUID, v: this._authService.getCurrentTimeNumber() };
    const dialogRef = this._dialog.open(TripPersonlistEditorClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        
        //this.getUsernameList().subscribe(res1 => {

        //});
        //this.getTripBytId();
        //this.clickSubmit();

        
      }

    });

  }

  clickClose() {
    //this._dialogRef.close(this.isModified);
    this._dialogRef.close(this.returnObj);
  }

  //clickSaveTest() {
  //  this.customerGUID = "B00C8207-4290-44EF-A756-FF3972839273";
  //  this.tripCodeGUID = "C24449D6-1907-400F-B3F6-D3690D3F4EE1";
  //  this.selectedAircraftGUID = "5B62B4EA-1C82-48D5-A367-B0D0559D6B88";
  //  this.openTripPersonSelection();
  //  this.clickClose();
  //}
}
