import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog) {

  }

  openConfirmDialog(msg, setHeight?: boolean, buttonText1?:string, buttonText2?:string, dialogTitle?:string) {
    if (setHeight == undefined)
      setHeight = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //dialogConfig.width = "500px";
    //dialogConfig.height = "200px";
    dialogConfig.width = "35em";//"31.25em";
    //dialogConfig.height = "12.5em";
    if (setHeight) {
     dialogConfig.height = "12.5em";
    }
    if (setHeight)
      dialogConfig.panelClass = "mat-dialog-container"
    else
      dialogConfig.panelClass = "custom-dialog-container2"
    dialogConfig.data = { dialogTitle: dialogTitle, message: msg, buttonText1:buttonText1, buttonText2:buttonText2 };
    return this.dialog.open(ConfirmDialogComponent, dialogConfig);
  }

  openAlertDialog(msg: string, buttonText?: string, dialogTitle?: string, setHeight?:boolean) {
    if (setHeight == undefined)
      setHeight = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    if (setHeight)
      dialogConfig.panelClass = "mat-dialog-container"
    else
      dialogConfig.panelClass = "custom-dialog-container2"
    //dialogConfig.width = "500px";
    //dialogConfig.height = "200px";
    dialogConfig.width = "31.25em";
    if (setHeight)
      dialogConfig.height = "10.5em";   
    dialogConfig.data = { dialogTitle: dialogTitle, message: msg, buttonText: buttonText};
    this.dialog.open(AlertDialogComponent, dialogConfig);
  }

}
