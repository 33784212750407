<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="width: 100%; height: 100%;" >

  <div   style="display: flex; width: 100%; justify-content: space-between; background-color: #f0f0f0; margin-top: -1em;">
    <div style="margin-top:0.3125em;display: flex;">
      <span class="material-symbols-outlined" style="font-size: x-large; margin-left: -0.3em;margin-right: 0.2em;">description</span><h5>{{title}} Document Type</h5>
    </div>
    <div style="display: flex;" >
      <button mat-icon-button *ngIf="isDialog"  (click)="clickRouteNew('documents/document-type-list','')">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>    
      <button mat-icon-button *ngIf="isDialog"  mat-dialog-close><i class="material-icons" style="font-size: x-large;">close </i></button>
    </div>
  </div>
  <div   style="background-color:white;  height: 85%; margin-bottom: 1em; width: 100%; overflow-x: auto; scrollbar-width: none;">
    <div style="padding: 0.5em; background-color:white ; ">
      
   
      <form *ngIf="document_type_edit_form" [formGroup]="document_type_edit_form">
      
        <div style="display: flex; margin-top: -0.5em;">
          <div
            style="justify-content:flex-start;padding-left: 1em; padding-top:0.75em; padding-bottom:0.5em; margin-right:1em;">
            <div style="margin-right:1em">
              <div><label for="service_type" style="font-weight:bold">Display</label></div>
              <div style="margin-top:-0.25em;">
              
                <div style=" display: flex;">
                 
                  <div style="display: flex; width: 10em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="isCrew" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">Crew</div>
                  </div>
                  <div style="display: flex; width: 6em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="isPax" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">Pax</div>
                  </div>
              
                  <div style="display: flex; width: 11em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="isSubDocument" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">IsSubDocument</div>
                  </div>
              
                  <div style="display: flex; width: 9em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="isVendor" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">Vendor</div>
                  </div>
              
                </div>
                <div style=" display: flex;">
  
                  <div style="display: flex; width: 10em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="isCustomer" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">Customer</div>
                  </div>
              
              
                  <div style="display: flex; width: 6em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="isAircraft" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">Aircraft</div>
                  </div>
              
                  <div style="display: flex; width: 11em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="isCountry" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">Country</div>
                  </div>
              
                  <div style="display: flex; width: 9em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="isTask" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">Task</div>
                  </div>
              
                </div>
               
                <div style=" display: flex;">
  
                  <div style="display: flex; width: 10em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="hasSubdocument" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">HasSubdocument</div>
                  </div>
               
              
              
                  <div style="display: flex; width: 6em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="isAirport" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">Airport</div>
                  </div>
              
                  <div style="display: flex; width: 11em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="isPermitRequiredDoc" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">PermitRequiredDoc</div>
                  </div>
              
                  <div style="display: flex; width: 9em;">
                    <div style="margin-right:0.25em">
                      <input formControlName="isMiscellaneous" type="checkbox" name="doc_type"   />
                    </div>
                    <div style="margin-top:-0.2em;white-space: nowrap">Miscellaneous</div>
                  </div> 
  
                </div>
              
              
              
              
              
              </div>
            </div> 
          </div> 
          <!-- <button class="btn-low" mat-button  (click)="getData()"   style="margin-left: -1em;height: 2em; margin-top: 6em; background-color:grey; color: white; margin-right: 0.5em; ">Refresh</button> -->
  
        </div>
      
        <div style="margin-top: 1em; margin-left: 1em;">

          <div   style="display:flex;  padding: 5px;">
            <label for="doc_name" style="font-size:small; width: 8em;">Document Type </label>
            <div  style="width: 28em;" >
              <input type="text"    formControlName="doc_name"   class="form-control" style="height: 1.5em; background-color:#eaf0fd;"  />
            </div>
          </div>

          <div   style="display:flex;  padding: 5px;">
            <label for="doc_code" style="font-size:small; width: 10em;">Document Code</label>
            <div  style="width: 4em;" >
              <input type="text"    formControlName="doc_code"   class="form-control" style="height: 1.5em; background-color:#eaf0fd;"  />
            </div>
          </div>


          <div  *ngIf="title == 'Edit'"   style="display:flex;  padding: 5px;">
            <label for="sortOrder" style="font-size:small; width: 10em;">Sort Order</label>
            <div  style="width: 4em;" >
              <input type="text"    formControlName="sortOrder"   class="form-control" style="height: 1.5em; background-color:#eaf0fd;"  />
            </div>
          </div>

          <div *ngIf="title == 'Add'"   >
            
            <div   style="display:flex;  padding: 5px;">
              <label for="sortOrderNo" style="font-size:small; width: 10em;">Sort Order For Crew</label>
              <div  style="width: 4em;" >
                <input type="text"    formControlName="sortOrderNo"   class="form-control" style="height: 1.5em; background-color:#eaf0fd;"  />
              </div>
            </div>
            
            <div   style="display:flex;  padding: 5px;">
              <label for="apisSortOrderNo" style="font-size:small; width: 10em;">Sort Order For Pax</label>
              <div  style="width: 4em;" >
                <input type="text"    formControlName="apisSortOrderNo"   class="form-control" style="height: 1.5em; background-color:#eaf0fd;"  />
              </div>
            </div>
            
            <div   style="display:flex;  padding: 5px;">
              <label for="sortOrderCountryReq" style="font-size:small; width: 10em;">Sort Order For Country Required</label>
              <div  style="width: 4em;" >
                <input type="text"    formControlName="sortOrderCountryReq"   class="form-control" style="height: 1.5em; background-color:#eaf0fd;"  />
              </div>
            </div>

          </div>

          <div  *ngIf="title == 'Edit'"   style="display:flex;  padding: 5px;">
            <label for="sortOrderCountryReq" style="font-size:small; width: 10em;">Disable Record</label>
            <div   >
              <select formControlName="isActive" class="form-control form-control-sm dropdown"  >
                <option value="true">No</option>
                <option value="false">Yes</option>
              </select>            
            </div>
          </div>


 
        </div>


  
   
    </form>
  
    </div>
  </div>
  <div style="justify-content:flex-start;  padding-bottom:0.5em; margin-top: -0.5em; display: flex; height: 2em;">
  
    <button mat-button class="btn-high mr-1" (click)="clickSave()">&nbsp;
      <mat-icon *ngIf="loading ">
        <svg class="black-spinner" viewBox="0 0 50 50">
          <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="black" stroke-width="4"></circle>
        </svg>
      </mat-icon>Save</button>
    <!-- <button    mat-button class="btn-high mr-1"  (click)="clickUpdate()" >Update</button> -->

    <button mat-button style="margin-left: 0.5em;" class="btn-low" (click)="clickClose(true)">Close</button>

    <div  style="margin-left: 2em;">
      <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display: inline-block; white-space: pre-wrap;">
        <label style="font-size: small; padding: 0em; margin: 0em;">{{errMsg}}</label>
      </div>
      <div *ngIf="showSuccessMsg" >
        <app-success-message [successMsg]="successMsg"></app-success-message>
        <div *ngIf="errMsg!=''"  class="alert d-flex align-items-center" role="alert" style=" background-color:#f9d2de; min-width:25em; padding-left:0.25em; padding-right:0.25em; font-size:small;height:2.5em">
           <div>{{errMsg}}</div>
        </div>
      </div>
      <!--<app-success-message *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>-->
    </div>
  </div>
</div>
