import { Component } from '@angular/core';

@Component({
  selector: 'submit-current-event',
  templateUrl: './submit-current-event.component.html',
  styleUrls: ['./submit-current-event.component.css']
})
/** airport-bi-dashboard component*/
export class SubmitCurrentEventComponent {
  
    constructor() {

    }
}
