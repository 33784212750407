import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { AircraftDocumentModel } from '../models/aircraft-document.model';
import { ResponseModel } from '../models/response.model';
import { AircraftService } from '../services/aircraft.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { AircraftDocumentEditDialogComponent } from './aircraft-document-edit-dialog.component';

@Component({
    selector: 'app-aircraft-documents',
    templateUrl: './aircraft-documents.component.html',
    styleUrls: ['./aircraft-documents.component.css']
})
/** aircraft-documents component*/
export class AircraftDocumentsComponent implements OnInit, OnChanges {
  aircraft_documents_form: UntypedFormGroup;
  @Input() aircraftId: string;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = true;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  customerGUID: string;
  text_search: string;
  filter_by: string;
  recordList: AircraftDocumentModel[];

  displayedColumn: string[] = ['document_name', 'preview', 'doc_type', 'effective_date', 'expiration_date','data_entry_status', 'disabled', 'action'];

  @Input() includeDisabledRecords: boolean
  

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _aircraftService: AircraftService) {

  }

  ngOnInit() {


  }

  ngOnChanges(changes: SimpleChanges): void {
    this.aircraft_documents_form = this._formBuilder.group({

    });

   // this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
      this.includeDisabledRecords = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getSearchResults();

  }


  getSearchResults() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = false;
    this._authService.updateAccessTime();
    let request = new AircraftDocumentModel();
    request.aircraftGUID = this.aircraftId;
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = !this.includeDisabledRecords;

    this._aircraftService.getAircraftDocument<ResponseModel<AircraftDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

          }

        }
        else {
          this.recordList = [];
          this.msg = "No records found.";
        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    })


  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    this.getSearchResults();

  }


  clickEdit(event: any, doc: AircraftDocumentModel) {
   
    let s = "";
    let action = "";
    let aircraftDocumentGUID = "";
    if (doc == null) {
      s = "Add New Record";
      action = "add";
    }
    else {
      if (this.userType=='internal')
        s = "Edit Record";
      else
        s = "View Record"
      action = "edit";
      aircraftDocumentGUID = doc.aircraftDocumentGUID;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //if (this.userType == "internal") {
    //  dialogConfig.width = "50";//"1000px";
    //}
    //else {
    //  dialogConfig.width = "20em";
    //}
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.data = { dialogTitle: s, action: action, aircraftGUID: this.aircraftId, aircraftDocumentGUID: aircraftDocumentGUID };
    
    const dialogRef = this._dialog.open(AircraftDocumentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSearchResults();
      }
    });

  }

  clickPreviewDoc(event: any, doc: AircraftDocumentModel) {
    let aircraftDocumentGUID = doc.aircraftDocumentGUID;
    this._aircraftService.getAircraftDocumentByadId<ResponseModel<AircraftDocumentModel>>(aircraftDocumentGUID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let docObj = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
          let file = new Blob([byteArray], { type: docObj.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);

        }
        else {
          this.errMsg = "No Document returned.";
        }
      }
      else {
        this.errMsg = "No Document returned.";
      }
    })

  }

}
