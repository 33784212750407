<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle>
  <div style="padding:1em; background: linear-gradient(to bottom, white,#c1e4f7);border-bottom:black solid 0.0625em;">
    <div class="d-flex justify-content-between">
      <span class="mr-1 material-icons-outlined ">fact_check</span>
      <h5>Leg Details</h5>
      <!--<div class="small d-flex align-items-center">
    <span style="margin-top:-.35em" class="ml-2 material-icons-two-tone {{leg.checklistStatusIconColor}}">
      {{leg.checklistStatusIconName}}
    </span><label>{{leg.checklistStatusDescription}}</label><label *ngIf="leg.checklistStatusSecondaryDescription!=null">&nbsp;({{leg.checklistStatusSecondaryDescription}})</label>
  </div>-->
    <div *ngIf="leg.departureOnHold || leg.nextArrivalOnHold" style="margin-top:0.1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
      <div style="margin-top:-0.3em; text-align:center">On Hold</div>
    </div>
    </div>
    <div *ngIf="legList" class="d-flex small justify-content-start">
      <div>
        <div>
          Departure (Z)
        </div>
        <div class="font-weight-bold">
          {{leg.departureDateTimeUTCText}}
        </div>
      </div>
      <div class="ml-2">
        <div>
          UTC
        </div>
        <div class="font-weight-bold">
          {{legList.departureUTCTimeConversion}}
        </div>
      </div>
      <div class="ml-2">
        <div>
          Leg
        </div>
        <div class="font-weight-bold">
          {{leg.departureICAO}} - {{leg.nextArrivalICAO}}
        </div>
      </div>
      <div class="ml-2">
        <div>
          ETE
        </div>
        <div class="font-weight-bold">
          {{legList.ete}}
        </div>
      </div>
      <div class="ml-2">
        <div>
          Arrival (Z)
        </div>
        <div class="font-weight-bold">
          {{leg.nextArrivalDateTimeUTCText}}
        </div>
      </div>
      <div class="ml-2">
        <div>
          UTC
        </div>
        <div class="font-weight-bold">
          {{legList.nextArrivalUTCTimeConversion}}
        </div>
      </div>
      <div class="ml-2">
        <div>
          Registration
        </div>
        <div class="font-weight-bold">
          {{leg.registration}}
        </div>
      </div>
      <div class="ml-2">
        <div>
          FAR Type
        </div>
        <div class="font-weight-bold">
          {{legList.departureFARTypeDescription}}
        </div>
      </div>
      <div class="ml-2">
        <div>
          Trip Code
        </div>
        <div class="font-weight-bold">
          {{legList.tripCode}}
        </div>
      </div>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
<div>
  <div class="d-inline-flex">
    <div class="d-flex">


      <div *ngIf="legList " class="small p-3" style="font-size: small;">
        <div class="d-flex justify-content-between">
          <div *ngIf="legList" style="min-width: 16em">
            <div style="display:flex;margin-top:-0.5em; overflow-y:auto">
              <div style="min-width: 10.5em;">
                <div *ngIf="legList.crewCount>0" style="white-space:nowrap;font-weight: bold">Crew ({{legList.crewCount}})</div>
                <div style="display: flex; margin-top: -0.4em; height: 1.5em" *ngIf="legList.pic!=null"><div style="white-space:nowrap; font-weight: bold; margin-right:0.25em">P:</div><div class="divHover" [style.cursor]="grantAccessToManageManifestProfiles?'pointer':'unset'" matTooltipClass="tooltipLineBreak" matTooltip="{{legList.pic.tooltip}}" (click)="clickPersonName($event, legList, legList.pic)">{{legList.pic.personNameAbbr}}</div><div *ngIf="legList.isPICChanged" style="cursor: pointer; margin-top:0.25em" title="PIC changed"><i class="material-icons-two-tone icon_red2" style="font-size: medium">people_alt</i></div><div *ngIf="legList.pic.hasRequiredDocument==false && (!legList.departureDateTimePassed && !legList.lockdownTrip)" title="{{legList.pic.missingInfoTooltip}}" [style.cursor]="grantAccessToManageManifestProfiles?'pointer':'unset'" style="margin-top:0.25em" (click)="clickPersonName($event, legList, legList.pic);"><i class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i></div><div *ngIf="legList.pic.passportCount>1" title="Two passports" style="margin-top:0.25em"><i class="material-icons-two-tone icon_orange" style="font-size: medium">content_copy</i></div></div>
                <div style="display: flex; margin-top: -0.4em; height: 1.5em" *ngIf="legList.sic!=null"><div style="white-space:nowrap; font-weight: bold; margin-right:0.25em">S:</div><div class="divHover" [style.cursor]="grantAccessToManageManifestProfiles?'pointer':'unset'" matTooltipClass="tooltipLineBreak" matTooltip="{{legList.sic.tooltip}}" (click)="clickPersonName($event, legList, legList.sic)">{{legList.sic.personNameAbbr}}</div><div *ngIf="legList.isSICChanged" style="cursor: pointer; margin-top:0.25em" title="SIC changed"><i class="material-icons-two-tone icon_red2" style="font-size: medium">people_alt</i></div><div *ngIf="legList.sic.hasRequiredDocument==false && (!legList.departureDateTimePassed && !legList.lockdownTrip)" title="{{legList.sic.missingInfoTooltip}}" [style.cursor]="grantAccessToManageManifestProfiles?'pointer':'unset'" style="margin-top:0.25em" (click)="clickPersonName($event, legList, legList.sic);"><i class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i></div><div *ngIf="legList.sic.passportCount>1" title="Two passports" style="margin-top:0.25em"><i class="material-icons-two-tone icon_orange" style="font-size: medium">content_copy</i></div></div>
                <div *ngFor="let p of legList.tripCrewList; let i=index" style="display: flex; margin-top: -0.4em; height: 1.5em">
                  <div style="white-space:nowrap; font-weight: bold; width:1em;">{{i==0? 'C:' :''}}</div><div style="white-space:nowrap" [style.cursor]="grantAccessToManageManifestProfiles?'pointer':'unset'" class="divHover" matTooltipClass="tooltipLineBreak" matTooltip="{{p.tooltip}}" (click)="clickPersonName($event, legList,p)">{{p.personNameAbbr}}</div><div *ngIf="p.hasRequiredDocument==false && (!legList.departureDateTimePassed && !legList.lockdownTrip)" [style.cursor]="grantAccessToManageManifestProfiles?'pointer':'unset'" style="margin-top:0.25em" (click)="clickPersonName($event, legList,p)" title="{{p.missingInfoTooltip}}"><i class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i></div><div *ngIf="p.passportCount>1" title="Two passports" style="margin-top:0.25em"><i class="material-icons-two-tone icon_orange" style="font-size: medium">content_copy</i></div>
                </div>
                <div *ngIf="legList.crewCount==0" style="white-space: nowrap;">
                  <div>Missing Crew</div>
                </div>
              </div>
              <div style="margin-left:0.5em">
                <div *ngIf="legList.paxCount>0" style="white-space:nowrap; font-weight:bold">PAX ({{legList.paxCount}})</div>
                <div *ngFor="let p of legList.tripPAXList" style="display: flex; margin-top: -0.4em; height: 1.5em">
                  <div style="white-space:nowrap;" [style.cursor]="grantAccessToManageManifestProfiles?'pointer':'unset'" class="divHover" matTooltipClass="tooltipLineBreak" matTooltip="{{p.tooltip}}" (click)="clickPersonName($event, legList,p)">{{p.personNameAbbr}}</div>
                  <div *ngIf="p.hasRequiredDocument==false && (!legList.departureDateTimePassed && !legList.lockdownTrip)" [style.cursor]="grantAccessToManageManifestProfiles?'pointer':'unset'" style="margin-top:0.25em" title="{{p.missingInfoTooltip}}" (click)="clickPersonName($event, legList,p)"><i class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i></div>
                  <div style="margin-top:0.25em" title="Minor on board" *ngIf="p.isMinorOnboard==true && (!legList.departureDateTimePassed && !legList.lockdownTrip)"><i class="material-icons-two-tone icon_green" style="font-size: medium">assignment_ind<!--warning_amber--></i></div><div *ngIf="p.passportCount>1" title="Two passports" style="margin-top:0.25em"><i class="material-icons-two-tone icon_orange" style="font-size: medium">content_copy</i></div>
                </div>
                <div *ngIf="legList.isDeptFerryFlight && legList.paxCount==0">
                  <div style="white-space:nowrap; font-weight: bold">PAX</div>
                  <div>Ferry Flight</div>
                </div>
              </div>
            </div>
            <div style="font-size: x-small">
              <div *ngIf="legList.departurePetsOnBoard"><mat-checkbox disabled [checked]="legList.departurePetsOnBoard" style="transform: scale(0.5)"></mat-checkbox>Pets on Board</div>
              <div *ngIf="legList.departureWeaponsOnBoard" style="margin-top:-1em"><mat-checkbox disabled [checked]="legList.departureWeaponsOnBoard" style="transform: scale(0.5)"></mat-checkbox>Weapons on Board</div>
            </div>
          </div>
          <div>
            <a [routerLink]="" style="color:black" (click)="openLegend()">Legend</a>
          </div>
        </div>
        <div class="d-inline-flex mt-2">
          <div style="min-width:23em">
            <div class="d-flex justify-content-center">
              <h6>Departure</h6>
            </div>
            <div style="height:3em;">
              <div *ngIf="leg.departureVendorList[0].vendorName!=''"><span class="font-weight-bold">Handler: </span>{{leg.departureVendorList[0].vendorName}}</div>
              <div *ngIf="leg.departureVendorList[1].vendorName!=''"><span class="font-weight-bold">SSN: </span>{{leg.departureVendorList[1].vendorName}}</div>
            </div>
            <div class="font-small font-weight-bold">
              Services
            </div>
            <div class="mt-1" *ngIf="departureServiceList" style="max-height:21em;overflow-y:auto;overflow-x:hidden;width:max-content">
              <div *ngIf="departureServiceList.length==0">None</div>
              <div *ngIf="departureServiceList.length>0">
                <div class="d-inline-flex">
                  <div>
                    <ng-template ngFor let-task [ngForOf]="departureServiceList">

                      <div style="display: flex;height:1.5em;" class="m-1 divHover">
                        <div *ngIf="task.strikeOutService"><s style="color:red" title="{{task.vendorName}}"><a style="cursor: pointer" (click)="clickServiceType($event,leg, task, true)">{{task.serviceTypeDescription}}</a></s></div>
                        <div *ngIf="!task.strikeOutService" title="{{task.vendorName}}"><a style="cursor: pointer" (click)="clickServiceType($event,leg, task, true)">{{task.serviceTypeDescription}}<span *ngIf="task.countryName!='' && task.countryName!=null"> ({{task.countryName}})</span></a></div>
                        <!--<div style="margin-top:0.25em" *ngIf="task.checklistIconColor!='green'"><span style="font-size:medium" class="material-icons-two-tone {{task.checklistIconColor}}">{{task.checklistIconName}}</span></div>-->
                        <div *ngIf="task.hasTaskDocument" style="margin-top:0.25em" title="documents"><i class="material-icons" style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                        <div *ngIf="task.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{task.publicNote}}"><i class="material-icons" style="font-size: medium;">notes</i></div>
                        <div *ngIf="!task.isActive" title="disabled" style="margin-top:0.25em"><i class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                      </div>
                    </ng-template>
                  </div>
                  <div>
                    <ng-template ngFor let-task [ngForOf]="departureServiceList">
                      <div style="height:1.5em;width:13em" class="m-1">
                        <div>
                          <div class="pl-1 pr-1" style="font-weight: 500; margin-bottom: .2em; border-radius: .25em; border: black solid 0.0625em; text-align: center"
                               [style.background-color]="task.taskStatusFontColor" [style.color]="task.taskStatusFontColor=='#003300' || task.taskStatusFontColor=='#6666ff' || task.taskStatusFontColor == '#ff0000' || task.taskStatusFontColor == 'black'? 'white' :'black'">{{task.taskStatusDescription}}</div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-left:1em;margin-right:1em; width:.15em;background-color:darkgray"></div>
          <div style="min-width:23em">
            <div class="d-flex justify-content-center">
              <h6>Arrival</h6>
            </div>
            <div style="height:3em;">
              <div *ngIf="leg.nextArrivalVendorList[0].vendorName!=''"><span class="font-weight-bold">Handler: </span>{{leg.nextArrivalVendorList[0].vendorName}}</div>
              <div *ngIf="leg.nextArrivalVendorList[1].vendorName!=''"><span class="font-weight-bold">SSN: </span>{{leg.nextArrivalVendorList[1].vendorName}}</div>
            </div>
            <div class="font-small font-weight-bold">
              Services
            </div>
            <div class="mt-1" *ngIf="arrivalServiceList" style="max-height:21em;overflow-y:auto;overflow-x:hidden">
              <div *ngIf="arrivalServiceList.length==0">None</div>
              <div *ngIf="arrivalServiceList.length>0">
                <div class="d-inline-flex">
                  <div style="width:max-content">
                    <ng-template ngFor let-task [ngForOf]="arrivalServiceList">

                      <div style="display: flex;height:1.5em;" class="m-1 divHover">
                        <div *ngIf="task.strikeOutService"><s style="color:red" title="{{task.vendorName}}"><a style="cursor: pointer" (click)="clickServiceType($event,leg, task, false)">{{task.serviceTypeDescription}}</a></s></div>
                        <div *ngIf="!task.strikeOutService" title="{{task.vendorName}}"><a style="cursor: pointer" (click)="clickServiceType($event,leg, task, false)">{{task.serviceTypeDescription}}<span *ngIf="task.countryName!='' && task.countryName!=null"> ({{task.countryName}})</span></a></div>
                        <!--<div style="margin-top:0.25em" *ngIf="task.checklistIconColor!='green'"><span style="font-size:medium" class="material-icons-two-tone {{task.checklistIconColor}}">{{task.checklistIconName}}</span></div>-->
                        <div *ngIf="task.hasTaskDocument" style="margin-top:0.25em" title="documents"><i class="material-icons" style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                        <div *ngIf="task.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{task.publicNote}}"><i class="material-icons" style="font-size: medium;">notes</i></div>
                        <div *ngIf="!task.isActive" title="disabled" style="margin-top:0.25em"><i class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                      </div>
                    </ng-template>
                  </div>
                  <div>
                    <ng-template ngFor let-task [ngForOf]="arrivalServiceList">
                      <div>
                        <div style="height:1.5em;width:13em" class="m-1">
                          <div class="pl-1 pr-1" style="font-weight: 500; margin-bottom: .2em; border-radius: .25em; border: black solid 0.0625em; text-align: center"
                               [style.background-color]="task.taskStatusFontColor" [style.color]="task.taskStatusFontColor=='#003300' || task.taskStatusFontColor=='#6666ff' || task.taskStatusFontColor == '#ff0000'|| task.taskStatusFontColor == 'black'? 'white' :'black'">{{task.taskStatusDescription}}</div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="mt-2 ml-1 mr-2" style="width:32em;height:30em;" id="map1"></div>
  </div>
</div>
<div class="mt-2 p-3" style="border-top: solid black .0625em;">
  <button mat-button (click)="openTrip()" class="btn-high">OPEN TRIP</button>
  <button mat-button class="btn-low ml-2" (click)="clickEmail()">SEND MESSAGE</button>
  <button mat-button mat-dialog-close (click)="clickClose()" class="btn-low ml-2">CLOSE</button>
</div>
