export class CustomerHomebaseAirportModel {
  customerGUID: string;
  customerHomebaseAirportID: number;
  airportID: number;
  icao: string;
  iata: string;
  airportName: string;
  airportLocation: string;
  modifiedBy: string;
  modifiedDate: string;
}
