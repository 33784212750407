export class AircraftDocumentModel {
  aircraftGUID: string;
  aircraftDocumentGUID: string;
  documentTypeGUIDList: string;
  documentTypeList: string;
  documentName: string;
  fileExtension: string;
  effectiveDate: string;
  expirationDate: string;
  documentSizeInKB: number;
  fileSize: number;
  bFile: string;
  mimeType: string;
  hasDocument: boolean;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  filterBy: string;
  textSearch: string;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  doesNotExpire: boolean;
  isExpired: boolean;
  dataEntryStatusID: number;
  dataEntryStatusDescription: string;
  customerName: string;
  customerGUID: string;

}
