import { Component, ElementRef, Inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyListOption as MatListOption, MatLegacySelectionList as MatSelectionList, MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { CustomerModel } from '../models/customer.model';
import { FlightBriefModel } from '../models/flight-brief.model';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { MessageTemplateModel } from '../models/message-template.model';
import { MessageTextModel } from '../models/message-text-model';
import { ResponseModel } from '../models/response.model';
import { TripModel } from '../models/trip.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopService } from '../services/ground-stop.service';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { VendorService } from '../services/vendor.service';
import { VendorModel } from '../models/vendor.model';
import { element } from 'protractor';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { forkJoin, Observable } from 'rxjs';
import { ReturnObjModel } from '../models/return-obj.model';
import { DocumentModel } from '../models/document.model';
import { DocumentCategoryModel } from '../models/document-category.model';
import { SelectionModel } from '@angular/cdk/collections';
import { FlightBriefSectionModel } from '../models/flight-brief-sections.model';
import { LegPermitsModel } from '../models/leg-permits-model';


const datepipe: DatePipe = new DatePipe('en-US')

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
var expressions = require("angular-expressions");
var assign = require("lodash/assign");
// define your filter functions here, for example
// to be able to write {clientname | lower}
expressions.filters.lower = function (input) {
  // This condition should be used to make sure that if your input is
  // undefined, your output will be undefined as well and will not
  // throw an error
  if (!input) return input;
  return input.toLowerCase();
};
function angularParser(tag) {
  tag = tag
    .replace(/^\.$/, "this")
    .replace(/(�|�)/g, "'")
    .replace(/(�|�)/g, '"');
  const expr = expressions.compile(tag);
  return {
    get: function (scope, context) {
      let obj = {};
      const scopeList = context.scopeList;
      const num = context.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = assign(obj, scopeList[i]);
      }
      return expr(scope, obj);
    },
  };
}

function parser(tag) {
  // We write an exception to handle the tag "$pageBreakExceptLast"
  if (tag === "$pageBreakExceptLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (!isLast) {
          return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }
  if (tag === "$pageBreak") {
    return {
      get(scope, context) {
        return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';

      },
    };
  }



  // We use the angularParser as the default fallback
  // If you don't wish to use the angularParser,
  // you can use the default parser as documented here:
  // https://docxtemplater.com/docs/configuration#default-parser
  return angularParser(tag);
}

@Component({
  selector: 'app-compile-flight-brief-dialog',
  templateUrl: './compile-flight-brief-dialog.component.html',
  styleUrls: ['./compile-flight-brief-dialog.component.css'],
  encapsulation: ViewEncapsulation.None,
})
/** generate-message-template component*/
export class CompileFlightBriefDialogComponent implements OnInit {
  allTaskDocumentList: DocumentModel[];
  taskDocumentList: DocumentModel[];
  flightBriefList: DocumentModel[];
  flightBrief: FlightBriefModel;
  flightBriefSectionList: FlightBriefSectionModel[];
  compile_flight_brief_form: UntypedFormGroup;
  @Input() gstId: string;
  @Input() tripCodeGUID: string;
  @Input() selectedStops: GroundStopModel[];
  @Input() tripCode: string;
  @Input() registration: string;
  reportName: string = "Flight Brief";
  errMsg: string="";
  submitted: boolean = false;
  briefType: string;
  isModified: boolean;
  icao: string;
  selectDocumentList: DocumentModel[] = [];
  documentCategoryList: DocumentCategoryModel[]=[];
  showSpin: boolean = false;
  isLoading: boolean = false;
  isPreviewLoading: boolean = false;
  format: string;
  addressType: string = "Email";
  includeCoverPage: boolean = false;
  includeDomesticCoverPage: boolean = false;
  includeInternationalCoverPage: boolean = false;
  hasTo: boolean = false;
  hasAddress: boolean = false;
  excludeDocuments: boolean = false;
  selection = new SelectionModel<DocumentModel>(true, []);
  displayedColumn: string[] = ['documentType', 'documentName', 'check', 'sortOrderNo'];
  displayedColumn2: string[] = ['documentType', 'documentName'];

  /** generate-message-template ctor */
  constructor(private readonly _authService: AuthenticateService, private readonly _vendorService: VendorService, private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _groundStopService: GroundStopService,
    private readonly _commonService: CommonService, private readonly _formBuilder: UntypedFormBuilder, private readonly _dialogRef: MatDialogRef<CompileFlightBriefDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,private el: ElementRef) {
    this.gstId = _data.gstId;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.selectedStops = _data.selectedStops;
    this.icao = _data.icao;
    this.tripCode = _data.tripCode;
    this.registration = _data.registration;
    if (_data.serviceTypeID == 79)
      this.reportName = "Trip Brief";
    if (_data.serviceTypeID == 32 || _data.serviceTypeID == 85 || _data.serviceTypeID == 87)
      this.reportName = "Wx Outlook";
  }
  ngOnInit() {
    this._authService.updateAccessTime();
    this.initControls();

    this.getData();
  }

  getAllData(): Observable<any[]> {
    let request = new FlightBriefModel();

    request.groundStopTaskGUID = this.gstId;
    request.tripCodeGUID = this.tripCodeGUID;
    request.selectedGroundStopList = [];
    this.selectedStops.forEach(v => {
      request.selectedGroundStopList.push(v.groundStopGUID);
    });

    let groundStopTaskDocumentResponse = this._groundStopAdvancedService.getGroundStopTaskDocumentForFlightBriefBygstId<ResponseModel<DocumentModel[]>>(request);
    let flightBriefResponse = this._groundStopAdvancedService.getFlightBrief<ResponseModel<FlightBriefModel>>(request);
      let sectionResponse = this._groundStopService.getFlightBriefSectionsBygstId(this.gstId);
    
    return forkJoin([groundStopTaskDocumentResponse, flightBriefResponse, sectionResponse]);

  }

  getData() {
    this.showSpin = true;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
         if (responses[0].code == "200" && responses[0].message == "") {
          this.allTaskDocumentList = responses[0].model;
          
          let tempCategetory: string = "";
          this.allTaskDocumentList.forEach(x => {
            if (tempCategetory != x.documentCategory) {
              tempCategetory = x.documentCategory;
              let y = new DocumentCategoryModel();
              y.documentCategory = x.documentCategory;
              y.documentList = this.allTaskDocumentList.filter(a => a.documentCategory == tempCategetory);
              let n = y.documentList.filter(d => d.selected == true).length;
              if (n > 0) {
                y.documentSelectedCountDisplay = "(Selected " + n.toString() + ")";
              }
              this.documentCategoryList.push(y);
            }
            x.selected == true ? this.selection.toggle(x) : null;
          });

       
        }


          this.flightBriefList = this.allTaskDocumentList.filter(v => v.documentType == this.reportName && v.fileExtension.toLowerCase() == "pdf");
        //if (this.flightBriefList.length == 1)
        //  this.flightBriefList[0].selected = true;
        this.taskDocumentList = this.allTaskDocumentList.filter(v => v.fileExtension.toLowerCase() != "pdf");
          if (this.flightBriefList.length == 0) {
            this.briefType = "newBrief";
            this.format = "pdf";
          }
          else {
            //this.f.flight_brief_list.setValue(this.flightBriefList[0]);
            this.briefType = "existingBrief";
            this.format = "pdf"           

        }
        this.taskDocumentList = this.allTaskDocumentList.filter(v => v.documentType != this.reportName && v.selected == true);
       //-   this.taskDocumentList= this.flightBriefList.concat(this.taskDocumentList);
        }
      

      if (responses[1] != null) {

        if (responses[1].code == "200" && responses[1].message == "") {
          this.flightBrief = responses[1].model;
          this.flightBrief.handlingNotes = [];
          var self = this;
          if (this.flightBrief.reportID == null)
            this.flightBrief.reportID = UtilityFunctions.makeID(10).toUpperCase();
          if (this.flightBrief.handlingVendorGUIDs != null) {
            this.flightBrief.handlingVendorGUIDs.forEach(v => {
              this._vendorService.getVendorDetailsByVendorID<ResponseModel<VendorModel>>(v.split("|")[0]).subscribe(r => {
                if (r.model != null) {
                  if (r.code == "200" && r.message == "") {
                    r.model.vendorName = r.model.vendorName.toUpperCase();
                    this.flightBrief.handlingNotes.push(r.model);
                  }
                }
              });
            });
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.flightBriefSectionList = responses[2].model;
        }

      }
      this.showSpin = false;

      if (this.taskDocumentList.length > 0) {
        for (let i = 0; i < this.taskDocumentList.length; i++) {
          this.compile_flight_brief_form.addControl("doc" + i.toString(), new UntypedFormControl());
          this.compile_flight_brief_form.addControl("docOrder" + i.toString(), new UntypedFormControl({ value: '', disabled: true }));
        }
      }
    
    });
     

  }

  initControls() {
    this.compile_flight_brief_form = this._formBuilder.group({
      flight_brief_list: '',
      cover_note: '',
      summary_to: '',
      summary_address:''
    });
  }

  get f() { return this.compile_flight_brief_form.controls; }


  onSelectionChange(options: MatListOption[]) {
    this.selectDocumentList = options.map(o => o.value);
  }

  clickPreviewDocument(e: any, item: DocumentModel) {
    this._authService.updateAccessTime();
    let request = new DocumentModel();
    request.documentCategory = item.documentCategory;
    request.documentGUID = item.documentGUID;
    this._groundStopService.getTripDocumentById<ResponseModel<DocumentModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let doc = new DocumentModel();
          doc = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
          let file = new Blob([byteArray], { type: doc.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
        //else {
        //  this.msg = "No documents returned";
        //}
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }

    })

  }
  setDocList(event: any, i: number, doc: DocumentModel) {

    if (event.checked) {
      if (this.selectDocumentList.indexOf(doc) == -1) {
        this.selectDocumentList.push(doc);
        this.compile_flight_brief_form.get("docOrder" + i.toString()).enable();
      }
    }
    else {
      this.selectDocumentList.splice(this.selectDocumentList.indexOf(doc), 1);
      this.compile_flight_brief_form.get("docOrder" + i.toString()).disable();
    }
  }

  setOrderList(i:number, doc: DocumentModel,event:any) {
    if (this.selectDocumentList.indexOf(doc) != -1) {
      this.selectDocumentList[this.selectDocumentList.indexOf(doc)].sortOrderNo = event.target.value;
    }
  }

  compileFlightBrief(format: string, isPreview:boolean) {
    this.errMsg = "";
    //if (this.selectDocumentList.length==0 && this.briefType == "existingBrief" && format == "pdf") {
    //  this.errMsg = "Please select document(s).";
    //  return;
    //}

    //if (this.flightBriefList.length == 0 && this.briefType == "existingBrief" && format == "pdf") {
    //  this.errMsg = "No existing brief.";
    //  return;
    //}

 
    this.selectDocumentList = this.flightBriefList.filter(v => v.selected == true);
    if (this.selectDocumentList.length == 0 && this.taskDocumentList.length == 0) {
      this.errMsg = "Please select document(s).";
      return;
    }
    if (isPreview)
      this.isPreviewLoading = true;
    else
      this.isLoading = true;

    if (this.selectDocumentList.length > 0) {
      this.selectDocumentList.forEach(v => {
        if (v.sortOrderNo == undefined)
          v.sortOrderNo = 99;
      });

      this.selectDocumentList.sort((a, b) => a.sortOrderNo - b.sortOrderNo);
      //this.taskDocumentList = this.allTaskDocumentList.filter(v => v.documentType != "Flight Brief" && v.selected==true);
    }
    this.taskDocumentList.forEach(v => {
      if (v.sortOrderNo == undefined)
        v.sortOrderNo = 99;
    });
    this.taskDocumentList.sort((a, b) => a.sortOrderNo - b.sortOrderNo);
    if (!this.excludeDocuments)
      this.selectDocumentList = this.selectDocumentList.concat(this.taskDocumentList);
    if (this.briefType == "newBrief" || format == "word" || this.includeCoverPage) {
      var self = this;
      let uplinkProvider = "";
      let hasPermits = false;
      let hasSlots = false;
      let overflightPermits = null;
      let landingPermits = null;
      let overflightPermitLegs = [];
      let landingPermitLegs = [];
      let taskNotes = [];
      if (this.flightBrief.permits != null) {
        hasPermits = true;
        overflightPermits = this.flightBrief.permits.filter(v => v.serviceTypeDescription == "OVERFLIGHT PERMIT");
        let tempLeg: string = "";
        overflightPermits.forEach(x => {
          let leg = x.departureICAO + '-' + x.arrivalICAO;
          if (tempLeg != leg) {
            tempLeg = leg;
            overflightPermitLegs.push({ leg: leg, permits: overflightPermits.filter(a => a.departureICAO + '-' + a.arrivalICAO == tempLeg) });
          }
        });
        landingPermits = this.flightBrief.permits.filter(v => v.serviceTypeDescription.includes("LANDING PERMIT"));
        tempLeg = "";
        landingPermits.forEach(x => {
          let leg = x.departureICAO + '-' + x.arrivalICAO;
          if (tempLeg != leg) {
            tempLeg = leg;
            let y = new LegPermitsModel();
            landingPermitLegs.push({ leg: leg, permits: landingPermits.filter(a => a.departureICAO + '-' + a.arrivalICAO == tempLeg) });

          }
        });
      }
      let hasOverflights = false;
      if (overflightPermits != null)
        if (overflightPermits.length>0)
          hasOverflights = true;
      let hasLanding = false;
      if (landingPermits != null)
        if (landingPermits.length>0)
          hasLanding = true;
      if (this.flightBrief.slotsPPR != null)
          hasSlots = true;
      if (this.flightBrief.flightPlan != null) {
        if (this.flightBrief.flightPlan.length > 0)
          uplinkProvider = this.flightBrief.flightPlan[0].uplinkProvider;
      }
            
      let filePath = "";
      //if (this.includeCoverPage)
      //  filePath = window.origin + "/assets/templates/FlightBriefTemplateWithCoverPage.docx";
      //  //filePath = "https://localhost:5001/assets/templates/FlightBriefTemplateWithCoverPage.docx";
      //else
        filePath = window.origin + "/assets/templates/FlightBriefTemplate.docx";
        //filePath = "https://localhost:5001/assets/templates/FlightBriefTemplate2.docx";
            loadFile(filePath, function (
              error,
              content
            ) {
              if (error) {
                throw error;
              }
              const zip = new PizZip(content);
              const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser });
              let reportDate = datepipe.transform(new Date().toUTCString(), 'MMM-dd-yyyy HH:mm', "UTC");
              self.flightBrief.reportDate = reportDate;
              if (self.f.summary_to.value == "")
                self.hasTo = false;
              else
                self.hasTo = true;
              if (self.f.summary_address.value == "")
                self.hasAddress= false;
              else
                self.hasAddress = true;
              doc.setData({
                includeImportantNote: true,
                includeAirportInformation: false,
                includePermits: true,
                includeSlots: true,
                includeWeather: false,
                includeNotams: false,
                includeFlightPlan: false,
                importantNote: self.flightBrief.importantNote == null ? '' : self.flightBrief.importantNote,
                hasUplink: self.flightBrief.hasArincUplink,
                weatherNote: self.flightBrief.weatherNote,
                notamsNote: self.flightBrief.notamsNote,
                flightPlan: self.flightBrief.flightPlan,
                  uplinkProvider: uplinkProvider,
                airport: self.flightBrief.handlingNotes,
                  hasPermits: hasPermits,
                  hasOverflight: hasOverflights,
                overflightPermits: overflightPermitLegs,
                  hasLanding: hasLanding,
                  landingPermits: landingPermitLegs,
                  hasSlots: hasSlots,
                slotsPPR: self.flightBrief.slotsPPR,
                customs: self.flightBrief.groundStopTasks,
                tripCode: self.tripCode,
                tailNo: self.registration,
                clientName: self.flightBrief.customerName.toUpperCase(),
                minDate: self.flightBrief.minDate,
                maxDate: self.flightBrief.maxDate,
                route: self.flightBrief.tripRoute,
                  reportDate: reportDate,
                hasTo: self.hasTo,
                  //reportTo: self.f.summary_to.value,
                hasAddress: self.hasAddress,
                  //address: self.f.summary_address.value,
                  //addressType: self.addressType,
                  //  coverPageNote: self.f.cover_note.value,
                reportID: self.flightBrief.reportID,                
                reportName: self.reportName.toUpperCase()


              });
              try {
                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                doc.render();
              } catch (error) {
                // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                function replaceErrors(key, value) {
                  if (value instanceof Error) {
                    return Object.getOwnPropertyNames(value).reduce(function (
                      error,
                      key
                    ) {
                      error[key] = value[key];
                      self.isLoading = false;
                      return error;
                    },
                      {});
                  }
                  return value;
                }
                if (error.properties && error.properties.errors instanceof Array) {
                  const errorMessages = error.properties.errors
                    .map(function (error) {
                      return error.properties.explanation;
                    })
                    .join("\n");
                  console.log("errorMessages", errorMessages);
                  self.errMsg = "There was an error creating the report."
                  // errorMessages is a humanly readable message looking like this:
                  // 'The tag beginning with "foobar" is unopened'
                }

                throw error;
              }
              const out = doc.getZip().generate({
                type: "blob",
                mimeType:
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              });
          
              var reader = new FileReader();

              reader.readAsDataURL(out);
              let request = new FlightBriefModel();
              reader.onloadend = function () {
                let taskDocument = new DocumentModel();
                taskDocument.bFile = (<string>reader.result).split(',')[1]
                taskDocument.mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                request.flightBriefDocument = taskDocument;
                request.groundStopTaskDocuments = self.selectDocumentList;
                if (self.flightBriefSectionList.find(v=>v.sectionName=="Flight Plan").selected)
                  request.icaoForms = self.flightBrief.icaoForms;
                request.weatherCharts = [];
                request.reportDate = self.flightBrief.reportDate;
                if (self.flightBrief.weatherCharts != null) {
                  self.flightBrief.weatherCharts.forEach(v => {
                    request.weatherCharts.push(v);
                  });
                }
                if (format == "pdf")
                  request.docFormat = "new";
                else
                  request.docFormat = format;
                request.reportID = self.flightBrief.reportID;
                self._groundStopAdvancedService.compileFlightBrief<ResponseModel<FlightBriefModel>>(request).subscribe(response => {
                  if (response != null && response.code == "200") {

                    let obj = response.model.flightBriefDocument;
                    let mimeType = "";
                    if (format == "word")
                      mimeType = "application / vnd.openxmlformats - officedocument.wordprocessingml.document";
                    else
                      mimeType = "application/pdf";
                    let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
                    let file = new Blob([byteArray], { type: mimeType });


                    if (format == 'word') {
                      saveAs(file, "FPI Flight Brief - " + self.icao + ".docx");
                      self.isLoading = false;
                      let obj = new ReturnObjModel();
                      obj.refresh = self.isModified;
                      let remarks = "Flight Brief created by " + localStorage.getItem('un') + "; Format - " + self.format + "; Cover Page -";
                      if (self.includeCoverPage)
                        remarks += "Yes;";
                      else
                        remarks += "No;";

                      remarks += "Task Documents Included - " + self.selectDocumentList.length;
                      obj.remarks = remarks;
                      self._dialogRef.close(obj);

                      //    });


                    }
                    else {

                      let taskDocument = new GroundStopTaskDocumentModel();
                      taskDocument.documentName = "FPI " + self.reportName + " - " + self.registration + " " + self.flightBrief.tripRoute + " - " + request.reportID + ".pdf";
                      taskDocument.fileExtension = "pdf";
                      taskDocument.documentSizeInKB = Math.round(file.size / 1024);
                      taskDocument.mimeType = mimeType;
                      taskDocument.bFile = obj.bFile;
                      taskDocument.groundStopTaskGUID = self.gstId;
                      taskDocument.reportID = request.reportID;
                      taskDocument.tripCode = self.tripCode;
                      if (!isPreview) {
                        self._groundStopAdvancedService.insertGroundStopTaskDocument<ResponseModel<number>>(taskDocument).subscribe(response => {
                          if (response != null && response.code == "200") {
                            self.isModified = true;

                            self._dialogRef.close(self.isModified);
                          }
                        });
                      }
                      var fileURL = URL.createObjectURL(file);
                      window.open(fileURL);
                    }
                    self.isLoading = false;
                    self.isPreviewLoading = false;
                  }
                  else {
                    if (response.code == "401") {
                      self._authService.signOut();
                    }
                    else {
                      self.errMsg = "An error occurred generating flight brief.";
                      self.isLoading = false;
                      self.isPreviewLoading = false;
                    }
                  }


                });
              }

            });
          }

    else {

      //if (this.includeCoverPage) {
      //  var self = this;
      //  let filePath = "https://www.flightproconnect.com/assets/templates/CoverPageTemplate.docx";
      //  //let filePath = "https://localhost:5001/assets/templates/CoverPageTemplate.docx";

      //  loadFile(filePath, function (
      //    error,
      //    content
      //  ) {
      //    if (error) {
      //      throw error;
      //    }
      //    const zip = new PizZip(content);
      //    const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser });
      //    let reportDate = datepipe.transform(new Date().toUTCString(), 'MMM-dd-yyyy HH:mm', "UTC");
      //    self.flightBrief.reportDate = reportDate;
      //    if (self.f.summary_to.value == "")
      //      self.hasTo = false;
      //    else
      //      self.hasTo = true;
      //    if (self.f.summary_address.value == "")
      //      self.hasAddress = false;
      //    else
      //      self.hasAddress = true;
      //    doc.setData({
      //      tripCode: self.tripCode,
      //      tailNo: self.registration,
      //      reportDate: reportDate,
      //      hasTo: self.hasTo,
      //      reportTo: self.f.summary_to.value,
      //      hasAddress:self.hasAddress,
      //      address: self.f.summary_address.value,
      //      addressType: self.addressType,
      //      coverPageNote: self.f.cover_note.value,
      //      route: self.flightBrief.tripRoute,
      //      reportID: self.flightBrief.reportID,
      //      reportType: self.reportName
      //    });
      //    try {
      //      // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
      //      doc.render();
      //    } catch (error) {
      //      // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
      //      function replaceErrors(key, value) {
      //        if (value instanceof Error) {
      //          return Object.getOwnPropertyNames(value).reduce(function (
      //            error,
      //            key
      //          ) {
      //            error[key] = value[key];
      //            self.isLoading = false;
      //            return error;
      //          },
      //            {});
      //        }
      //        return value;
      //      }
      //      if (error.properties && error.properties.errors instanceof Array) {
      //        const errorMessages = error.properties.errors
      //          .map(function (error) {
      //            return error.properties.explanation;
      //          })
      //          .join("\n");
      //        console.log("errorMessages", errorMessages);
      //        self.errMsg = "There was an error creating the report."
      //        // errorMessages is a humanly readable message looking like this:
      //        // 'The tag beginning with "foobar" is unopened'
      //      }

      //      throw error;
      //    }
      //    const out = doc.getZip().generate({
      //      type: "blob",
      //      mimeType:
      //        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      //    });

      //    var reader = new FileReader();

      //    reader.readAsDataURL(out);

      //    reader.onloadend = function () {
      //      let request = new FlightBriefModel();
      //      let coverPage = new DocumentModel();
      //      coverPage.bFile = (<string>reader.result).split(',')[1]
      //      coverPage.mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      //      request.coverPage = coverPage;
      //      request.docFormat = "existing";
      //      request.flightBriefDocument = self.selectDocumentList[0];
      //      request.groundStopTaskDocuments = self.selectDocumentList.slice(1);
      //      request.reportID = self.flightBrief.reportID;
      //      request.reportDate = self.flightBrief.reportDate;
      //      self._groundStopAdvancedService.compileFlightBrief<ResponseModel<FlightBriefModel>>(request).subscribe(response => {
      //        if (response != null) {
      //          if (response.code == "200" && response.message == "") {
      //            let obj = response.model.flightBriefDocument;

      //            let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
      //            let file = new Blob([byteArray], { type: "application/pdf" });

      //            //var fileURL = URL.createObjectURL(file);
      //            //window.open(fileURL);
      //            let taskDocument = new GroundStopTaskDocumentModel();
      //            taskDocument.documentName = "FPI " + self.reportName + " - " + self.registration + " " + self.flightBrief.tripRoute + " - " + request.reportID + ".pdf";
      //            taskDocument.fileExtension = "pdf";
      //            taskDocument.documentSizeInKB = Math.round(file.size / 1024);
      //            taskDocument.mimeType = "application/pdf";
      //            taskDocument.bFile = obj.bFile;
      //            taskDocument.groundStopTaskGUID = self.gstId;
      //            taskDocument.reportID = request.reportID;
      //            taskDocument.tripCode = self.tripCode;
      //            self._groundStopAdvancedService.insertGroundStopTaskDocument<ResponseModel<number>>(taskDocument).subscribe(response => {
      //              if (response != null) {
      //                if (response.code == "200") {

      //                  var fileURL = URL.createObjectURL(file);
      //                  window.open(fileURL);
      //                  self.isModified = true;
      //                  self.isLoading = false;
      //                  let obj = new ReturnObjModel();
      //                  obj.refresh = self.isModified;
      //                  //let remarks = "Flight Brief created by " + localStorage.getItem('un') + "; Format - " + self.format + "; Cover Page -";
      //                  //if (self.includeCoverPage)
      //                  //  remarks += "Yes;";
      //                  //else
      //                  //  remarks += "No;";

      //                  //remarks += "Task Documents Included - " + self.selectDocumentList.length;
      //                  //obj.remarks = remarks;
      //                  obj.remarks = request.reportID;
      //                  self._dialogRef.close(obj);
      //                }
      //                else {
      //                  if (response.code == "401") {
      //                    self._authService.logout;
      //                  }
      //                  else {
      //                    self.errMsg = response.message;
      //                  }
      //                }
      //              }
      //              else
      //                self.isLoading = false;
      //            });
      //          }
      //          else {
      //            if (response.code == "401") {
      //              self._authService.logout;
      //            }
      //            else {
      //              self.errMsg = response.message;
      //            }
      //          }
      //        }
      //        self.isLoading = false;
      //      });
      //    }
      //  });
      //}

     // else {
        let request = new FlightBriefModel();
        request.docFormat = "existing";
      request.flightBriefDocument = this.selectDocumentList[0];
      //if (!this.excludeDocuments)
        request.groundStopTaskDocuments = this.selectDocumentList.slice(1);
        request.reportID = this.flightBrief.reportID;
        request.reportDate = datepipe.transform(new Date().toUTCString(), 'MMM-dd-yyyy HH:mm', "UTC");
        this._groundStopAdvancedService.compileFlightBrief<ResponseModel<FlightBriefModel>>(request).subscribe(response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              let obj = response.model.flightBriefDocument;

              let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
              let file = new Blob([byteArray], { type: "application/pdf" });
              if (isPreview) {
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                this.isPreviewLoading = false;
              }
              else {
                let taskDocument = new GroundStopTaskDocumentModel();
                taskDocument.documentName = "FPI " + this.reportName + " - " + this.registration + " " + this.flightBrief.tripRoute + " - " + request.reportID + ".pdf";
                taskDocument.fileExtension = "pdf";
                taskDocument.documentSizeInKB = Math.round(file.size / 1024);
                taskDocument.mimeType = "application/pdf";
                taskDocument.bFile = obj.bFile;
                taskDocument.groundStopTaskGUID = this.gstId;
                taskDocument.reportID = request.reportID;
                taskDocument.tripCode = this.tripCode;
                this._groundStopAdvancedService.insertGroundStopTaskDocument<ResponseModel<number>>(taskDocument).subscribe(response => {
                  if (response != null) {
                    if (response.code == "200") {

                      var fileURL = URL.createObjectURL(file);
                      window.open(fileURL);
                      this.isModified = true;
                      this.isLoading = false;
                      let obj = new ReturnObjModel();
                      obj.refresh = this.isModified;
                      let remarks = "Flight Brief created by " + localStorage.getItem('un') + "; Format - " + this.format + "; Cover Page -";
                      if (this.includeCoverPage)
                        remarks += "Yes;";
                      else
                        remarks += "No;";

                      remarks += "Task Documents Included - " + this.selectDocumentList.length;
                      obj.remarks = request.reportID;
                      this._dialogRef.close(obj);
                    }
                    else {
                      if (response.code == "401") {
                        this._authService.logout;
                      }
                      else {
                        this.errMsg = response.message;
                      }
                    }
                  }
                  else
                    this.isLoading = false;
                });
              }
            }
            else {
              if (response.code == "401") {
                this._authService.logout;
              }
              else {
                this.errMsg = response.message;
              }
            }
          }
          this.isLoading = false;
        });
      
      //}
        
    }
  }
  changeFormat(format:any) {
    if (format.value == "word") {
      this.taskDocumentList = this.allTaskDocumentList.filter(v => v.fileExtension.toLowerCase() != "pdf");
    }
    else {
      this.flightBriefList = this.allTaskDocumentList.filter(v => v.documentCategory == "Flight Brief" && v.fileExtension.toLowerCase() == "pdf");
      this.taskDocumentList = this.allTaskDocumentList.filter(v => v.documentCategory != "Flight Brief" || (v.documentCategory == "Flight Brief" && v.fileExtension.toLowerCase() != "pdf"));
      this.taskDocumentList = this.flightBriefList.concat(this.taskDocumentList);
    }


  }

  checkDocumentChange(e: any, item: DocumentModel, i: number) {
    this._authService.updateAccessTime();
    //this.errMsg = "";
    e ? this.selection.toggle(item) : null;
    item.selected = e.checked;
    let n = 0;
    let currentDoc: DocumentModel[];
    currentDoc = this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentList;
    currentDoc.forEach(y => {
      if (y.selected == true) {
        n += 1;
      }
    });

    if (n > 0) {
      this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentSelectedCountDisplay = "(Selected " + n.toString() + ")";
    }
    else {
      this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentSelectedCountDisplay = "";
    }

    if (e.checked) {
      var control;
      control = this.el.nativeElement.querySelector('[id="sortOrder' + i.toString() + '"]');
      setTimeout(() => {
        control.focus();
      }, 50);
    }
  }

  setSortOrder(e: any, item: DocumentModel) {
    this._authService.updateAccessTime();
    item.sortOrderNo = Number(e.target.value);
  }
}
