import { Component, Inject, OnInit } from '@angular/core';
import { AuthenticateService } from '../services/authenticate.service';
import { MessageService } from '../services/message.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MessageCenterModel } from '../models/message-center.model';
import { ResponseModel } from '../models/response.model';
import { MessageModel } from '../models/message.model';
import { DialogService } from '../services/dialog.service';
import { EmailClientDialogComponent } from './email-client-dialog.component';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-trip-messages-client',
  templateUrl: './trip-messages-client.component.html',
  styleUrls: ['./trip-messages-client.component.css']
})
export class TripMessagesClientComponent implements OnInit {
  isLoading: boolean = false;
  grantAccessToMessages: boolean = false;
  tripCodeGUID: string = "";
  groundStopGUID: string = "";
  groundStopTaskGUID: string = "";
  messageList: MessageModel[];
  filteredMessageList: MessageModel[];
  errMsg: string = "";
  totalCount: number;
  inCount: number;
  outCount: number;
  messageType: string = "both";
  displayedColumns: string[] = ['type', 'date', 'leg', 'service', 'sender', 'subject', 'body', 'hasAttachments', 'action'];
  selectedGroundStopList: GroundStopModel[];
  tripCode: string;
  registration: string;
  icao: string;
  arrivalDateTime: string;
  departureDateTime: string;
  arrivalDateTimeLocal: string;
  departureDateTimeLocal: string;
  nextICAO: string;
  prevICAO: string;
  serviceTypeDescription: string;

  constructor(private readonly _authService: AuthenticateService,
    private readonly _messageService: MessageService,
    private readonly _groundStopAdvancedService:GroundStopAdvancedService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _dialogRef: MatDialogRef<TripMessagesClientComponent>,
    private readonly _dialogService: DialogService,
    private readonly _dialog: MatDialog
  )
  {
    this.registration = _data.registration;
    this.tripCode = _data.tripCode;
    this.selectedGroundStopList = _data.selectedGroundStopList;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.groundStopGUID = "";
    if (_data.groundStopGUID != null && _data.groundStopGUID != undefined) {
      this.groundStopGUID = _data.groundStopGUID;
    }
    this.groundStopTaskGUID = "";
    if (_data.groundStopTaskGUID != null && _data.groundStopTaskGUID != undefined) {
      this.groundStopTaskGUID = _data.groundStopTaskGUID;
    }
    if (_data.serviceTypeDescription != null && _data.serviceTypeDescription != undefined) {
      this.serviceTypeDescription = _data.serviceTypeDescription.toUpperCase();
    }
  }
  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToMessages = false;
    if (localStorage.getItem('up.msg') == 'true') {
      this.grantAccessToMessages = true;
    }


    if (!this.grantAccessToMessages) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this._dialogRef.close();
      return;
    }
    this.getData();
  }

  getAllData() {
    let gsRequest = new GroundStopModel();
    gsRequest.groundStopGUID = this.groundStopGUID;
    gsRequest.tripCodeGUID = this.tripCodeGUID;
    let gsResponse = of(null);
    if (this.groundStopTaskGUID != "")
      gsResponse = this._groundStopAdvancedService.getGroundStopForTaskEditBygsId<ResponseModel<GroundStopModel>>(gsRequest);
    let request = new MessageCenterModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundstopTaskGUID = this.groundStopTaskGUID
    request.timeFrame = 0
    request.pageNumber = 0;
    request.messageFolderID = 0;
    request.sort = "desc";
    request.pageSize = 0;
    request.includeTrips = true;
    let msgResponse = this._messageService.getMessageListByConditionsClient<ResponseModel<MessageCenterModel>>(request);
    return forkJoin([gsResponse, msgResponse]);
  }

  getData() {
    this.isLoading = true;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].message == "" && responses[0].code == "200") {
          let gs = new GroundStopModel();
          gs = responses[0].model;
          this.tripCode = gs.tripCode;
          this.icao = gs.icao;
          this.arrivalDateTime = gs.arrivalDateTextUTC;
          this.departureDateTime = gs.departureDateTextUTC;
          this.arrivalDateTimeLocal = gs.arrivalDateTextLocal;
          this.departureDateTimeLocal = gs.departureDateTextLocal;
          this.nextICAO = gs.nextICAO;
          this.prevICAO = gs.prevICAO;
          this.registration = gs.registration;

        }
      }
      if (responses[1] != null) {
        if (responses[1].message == "" && responses[1].code == "200") {
          this.messageList = responses[1].model.messages;
          
          this.filteredMessageList = this.messageList;
          this.totalCount = this.messageList?.length;
          this.inCount = this.messageList?.filter(x => x.messageFolderID == 1).length;
          this.outCount = this.messageList?.filter(x => x.messageFolderID == 4).length;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
          else {
            this.errMsg = "An error occurred retrieving messages.";
          }
        }
      }
      this.isLoading = false;
    });
  }

  messageTypeChange(){
    switch (this.messageType) {
      case "both":
        this.filteredMessageList = this.messageList;
        break;
      case "in":
        this.filteredMessageList = this.messageList.filter(x => x.messageFolderID == 1);
        break;
      case "out":
        this.filteredMessageList = this.messageList.filter(x => x.messageFolderID == 4);
        break;
    }
  }

  openMessage(message:MessageModel) {
      let s = "";
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      //dialogConfig.width = "65em";//"1000px";
      //dialogConfig.height = "28em";//"500px";
      dialogConfig.disableClose = true;
      dialogConfig.hasBackdrop = false;
      dialogConfig.position = { top: "40px" };
      

      dialogConfig.data = {
        dialogTitle: s, tripCodeGUID: "", messageGUID: message.messageGUID, isDraft: message.isDraft,
        v: this._authService.getCurrentTimeNumber(), allMessageData: this.messageList,
        messageFolderID: 0, messageKeyId: message.messageKeyID, messagesOpened: 1
      };

      const dialogRef = this._dialog.open(EmailClientDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          //this.isModified = true;
          this.getData();

        }

      });
    
  }


  newMessage() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID,groundStopTaskGUID:this.groundStopTaskGUID
    };

    const dialogRef = this._dialog.open(EmailClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });


  }

}
