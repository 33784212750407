import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { TripModel } from '../models/trip.model';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { AccountingStatusModel } from '../models/accounting-status.model';
import { TripRevisionModel } from '../models/trip-revision.model';
import { TripAuditUserCommentDialogComponent } from './trip-audit-user-comment-dialog.component';

@Component({
  selector: 'app-trip-audit-dialog',
  templateUrl: './trip-audit-dialog.component.html',
  styleUrls: ['./trip-audit-dialog.component.css']
})

export class TripAuditDialogComponent implements OnInit {
  userType: string;
  customerGUID: string;
  aircraftGUID: string;
  tripCodeGUID: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  groundStopGUIDList: string;
  userName: string;
  pastHours: number;
  trip_audit_form: UntypedFormGroup;
  errGSMsg: string;
  errTSMsg: string;
  errMsg: string;
  msg: string;
    
  showSpin: boolean = true;
  
  v: number = this._authService.getCurrentTimeNumber();
  
  tripList: TripModel[];
  
  displayedColumn: string[] = ['transactionDate', 'changeRequestType', 'changeRequest', 'userComments', 'action'];
  
  auditHourText: string;
  hideColumn: boolean = false;
  upPII: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<TripAuditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService) {
    this.customerGUID = _data.customerGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.aircraftGUID = _data.aircraftGUID;
    this.userName = _data.userName;
    this.pastHours = _data.pastHours;
    this.groundStopGUID = "";
    if (_data.groundStopGUID != null && _data.groundStopGUID != undefined && _data.groundStopGUID != "") {
      this.groundStopGUID = _data.groundStopGUID;
    }
    this.groundStopTaskGUID = "";
    if (_data.groundStopTaskGUID != null && _data.groundStopTaskGUID != undefined && _data.groundStopTaskGUID != "") {
      this.groundStopTaskGUID = _data.groundStopTaskGUID;
    }
    this.groundStopGUIDList = "";
    if (_data.groundStopGUIDList != null && _data.groundStopGUIDList != undefined && _data.groundStopGUIDList != "") {
      this.groundStopGUIDList = _data.groundStopGUIDList;
    }
  }

  ngOnInit() {
   
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.errGSMsg = "";
    this.errTSMsg = "";
    this.errMsg = "";
    this.msg = "";
    this.tripList = [];
    this.auditHourText = "";
    switch (this.pastHours) {
      case 1:
        this.auditHourText = "Past 1 hour";
        break;
      case 0:
        this.auditHourText = "All time";
        break;
      default:
        this.auditHourText = "Past "+this.pastHours.toString()+" hours";
        break;
    }

    if (this.userName == "") {
      this.auditHourText += " by all users"
    }
    else {
      this.auditHourText += " by " + this.userName;
    }
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    if (this.userType == 'customer') {
      this.hideColumn = true;
    }
   this.initControls();

   this.getRevision();

  }

  initControls() {
    this.trip_audit_form = this._formBuilder.group({
     
    });

    
  }

  get f() { return this.trip_audit_form.controls; }

  getRevision() {
    let obj = new TripRevisionModel();
    obj.tripCodeGUID = this.tripCodeGUID;
    obj.customerGUID = this.customerGUID;
    obj.aircraftGUID = this.aircraftGUID;
    obj.groundStopGUID = this.groundStopGUID;
    obj.groundStopTaskGUID = this.groundStopTaskGUID;
    obj.groundStopGUIDList = this.groundStopGUIDList;
    obj.userName = this.userName;
    obj.pastHours = Math.round(this.pastHours);
    this._groundStopService.getTripRevisionBytIdHours<ResponseModel<TripModel[]>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.tripList = response.model;
          this.tripList.forEach(x => {
            x.tripRevisionExcludeTripOpenList = [];
            x.tripRevisionForTripOpenList = [];    
            if (x.tripRevisionList.length > 0) {                        
              x.tripRevisionList.forEach(y => {
                if (y.isTripOpened) {
                  x.tripRevisionForTripOpenList.push(y);
                }
                else {
                  x.tripRevisionExcludeTripOpenList.push(y)
                }
              })
            }          
          })
        }
        else {
          this.msg = "No data returned";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.showSpin = false;
    })
  }

  clickOpenUserComment(e: any, item: TripRevisionModel) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripRevisionID: item.tripRevisionID, userComments: item.userComments, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditUserCommentDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        item.userComments = result.remarks;
      }

    });
  }

  clickClose() {
    this._dialogRef.close();
  }
  
  
  

  
  
 

}
