
export class GlobalConstant {
  //public static get baseUrl(): string { return '/api/'; }
  public static get baseUrl(): string { return '/local/'; }
  public static get maxIdleTime(): number { return 60; }
  //public static get allowedFileTypeList(): string[] { return ['txt', 'pdf', 'jpg', 'png', 'jpeg', 'gif', 'rtf'] } //
  public static get allowedFileTypeList(): string[] { return ['pdf', 'jpg', 'png', 'jpeg', 'gif'] } // 
  public static get maxFileUploadSize(): number { return 26214400; } // byte; 5MB =5 * 1024  =5120 KB * 1024=5242880 byte

  public static get maxTotalFileUploadSize(): number { return 15728640; } // byte; 15 M
  public static get maxUploadFileNum(): number { return 6; }
  public static get opsEmail(): string { return "ops@flightprointl.com" }
  public static get salesEmail(): string { return "sales@flightprointl.com" }
  public static get fuelEmail(): string { return "Fuel@flightprointl.com" }
  public static get accountingEmail(): string { return "Accounting@flightprointl.com" }
  public static get opsPhoneWorldwide(): string { return "+1 346-415-6900" }
  public static get opsPhoneDoemstic(): string { return "+1 866 356 1055" }
  public static get fpiOfficeAddress(): string { return "6002 Rogerdale Rd Suite 425, Houston, TX 77072 USA" }
  public static get fpiUrl(): string { return "www.flightprointernational.com" }
  public static get contingencyGroundStopTypeGUID(): string { return "9523BC15-7E5F-4022-B5C6-574F7EA63649" }
}


