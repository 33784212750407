<div style="margin-top: 0em; padding-top: 0em; width: 100%;   ">

  <form *ngIf="fuelpricing_form" [formGroup]="fuelpricing_form">

    <!-- <div >
      <div style="  border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; ">
        <div style="     display: flex; justify-content: end;" > 
          <div style="display: flex;"> 
            <button  mat-icon-button  (click)="clickRoute('fuelmenu/fuel-menu')" > <i class="material-icons"
                style="font-size: x-large;">close </i> </button>
          </div>
        </div>
        <div> 
        

        </div>
      </div>
    </div> -->
    <div style="display:flex;overflow-y: auto; max-height: 42em;">
      <div>
                    <!--<button type="button" mat-button class="btn-low" style="height:1.5em;">-->
                    <!--(click)="clickSubmit()"-->
                    <!--<div style="display: flex;margin-top:-0.125em">
                  <div style="margin-top: 0.25em; margin-left:-0.25em">
                    <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">download</i>
                  </div>
                  <div>
                    Export to Excel
                  </div>
                </div>
              </button>-->
         
        <div style="background-color: #f8f8f8; /*width: 72em; padding: 1em; min-height: 28em;  border: 1px solid;  margin: 0.3em 0em 1em 2em*/ ">
          <div style="display: flex;padding-left:1em; justify-content: space-between; padding-right: 1em; padding-top: 0.5em;" cdkDrag
               cdkDragRootElement=".cdk-overlay-pane"
               cdkDragHandle>
            <div style="margin-top:0.3125em; display: flex;"><span style="margin-right: 0.2em;" class="material-symbols-outlined">publish</span><h5>Import New Fuel Prices </h5></div>
            <div style="display: flex;"> 

              <!-- <button mat-button class="btn-med" style="margin-right: 1em;"  (click)="clickRoute('fuelmenu/fuel-menu')" >Ret</button> -->
              <button type="button" mat-button class="btn-low" style=" margin-top: 0.75em; height: 2em; margin-right: 1em;" (click)="clickRoute('fuelmenu/fuel-menu')">
                <!--(click)="clickReset()"-->
                <div style="display: flex;margin-top:-0.125em">
                  <div style="margin-top: 0.125em; margin-left:-0.25em;   ">
                    <i class="material-icons" style="font-size: medium;cursor: pointer; ">arrow_back</i>
                  </div>
                  <div>
                    Return to Fuel Dashboard
                  </div>
                </div>
              </button>


              <button  mat-icon-button  (click)=" clickClose()" > <i class="material-icons"
                  style="font-size: x-large;">close </i> </button>
            </div>
          </div>
          <div>
            <div style="display:flex; margin-top:-0.5em; padding-left: 1em; padding-right:1em">
              <div style="display: flex; margin-top: 0em; min-height: 7em; padding-top:0.5em"> <!-- width:34em; -->
                
                <div style="background-color: white;min-height:6em; font-size: small; padding-left: .5em; padding-right: .5em; margin-top: 0em;padding-top:0.5em; min-width: 40em;">
                  <div style="margin-top:0.5em; margin-right:0.625em;margin-left:0.5em ">
                    <div style="display: flex; padding: 1em;">
                      <label for="fs_select" style="font-size:small">Select Fuel Supplier:&nbsp;</label>
                      <div style="display: flex;">
                        <div style="margin-top: 0em; margin-left: 1em;">
                          <!--<select #fs_select id="fs_select" class="form-control form-control-sm dropdown" (change)="fuelSupplierChange($event,fs_select.value)">-->
                          <select formControlName="fs_select" style="font-size: small;border-radius:0.25em;width: 19em; background-color:#eaf0fd" (change)="fuelSupplierChange($event)">
                            <option value="default">Select a Fuel Supplier</option>
                            <option *ngFor="let fuel of fuelSupplierList" [value]="fuel.lK_FuelSupplierGUID">{{fuel.fuelSupplierName}}</option>
                          </select><!--*ngIf="f"-->
                        </div>

                        <button *ngIf="!isFileInputDisabled" mat-button class="btn-low" style="margin-left: 1em; height: 2em;" (click)="clickEdit()">EDIT</button>
                        <div *ngIf="(submitted || validatedAirport) && f.customer_select.errors" class="invalid-feedback" style="margin-top:-2em">
                          <div *ngIf="f.customer_select.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:16em">Please select Fuel Supplier.</div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; padding: 1em 1em 2em 1em;">
                      <!--<input [attr.disabled]="isFileInputDisabled ? '' : null" #document formControlName="document" id="document" type="file" [uploader]="uploader" accept="*" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />-->
                      <input style="display: none" [attr.disabled]="isFileInputDisabled ? '' : null" #document formControlName="document" id="document" type="file" [uploader]="uploader" accept=".xlsx" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />
                      <!--<input style="display: none" type="file" (change)="onFileSelect($event)" #hiddenfileinput>-->
                      <button mat-button class="button" [disabled]="isFileInputDisabled" (click)="document.click()">Select a File to Import</button><!--<i class="material-icons left">cloud</i>-->
                      <input #filename [attr.disabled]="true" id="filename" type="text" class="text-line" [value]="attachedfilename" />

                    </div>



 



                    <div style="background-color:white;padding:.5em;margin-top:1em">
                      <h5 style="text-align: center;"> Import Default Values</h5>
                      <div class="no-gutters" style="display: flex;">
                        <div style="margin-right: 1em; border: 1px solid #ccc;  border-radius: 4px; padding: 3px; width: 45%;">
                          <label style=" font-weight: bold; font-size:small">Set Defualt Expiration Date</label>
                        
                          <div style="display: flex;margin-left: 1.5em;">
                            <div>
                              <label  style="font-size:small; margin-right: 0.5em;">Domestic US</label>
                            </div>
                            <div>
                              <div  style="background-color: #f8f8f8; width: 6em; border-radius: 4px; text-align: center;">
                                <div  tabindex="0"  >
                                  <div >{{domFrequencyVal}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style="display: flex;margin-left: 1.5em;">
                            <div>
                              <label   style="font-size:small; margin-right: 0.5em;">International </label>
                            </div>
                            <div>
                              <div  style="background-color: #f8f8f8; width: 6em; border-radius: 4px; text-align: center;">
                                <div  >
                                  <div >{{intFrequencyVal}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
            
            
            
                        <div style="border: 1px solid #ccc;  border-radius: 4px; padding: 3px;">
                          <label style=" font-weight: bold; font-size:small">Taxes And Fees Included?</label>
            
                          <div style="display: flex; margin-left: 2em;"> 
                            <div>
                              <label  style="font-size:small; margin-right: 0.5em;">Domestic US</label>
                            </div>
                            <div style=" margin-left: 1.4em;">
                                <div style="background-color: #f8f8f8; width: 6em; border-radius: 4px; text-align: center;">
                                   {{taxesIncludedDomVal}} 
                                </div> 
                              </div>
                          </div>
              
                          <div style="display: flex; margin-left: 2em;"> 
                            <div>
                              <label   style="font-size:small; margin-right: -0.5em;">International</label>
                            </div>
                            <div>
                              <div  style=" margin-left: 2.4em;">
                                <div   style="background-color: #f8f8f8; width: 6em; border-radius: 4px; text-align: center;">
                                  <div  >{{taxesIncludedIntVal}}</div>
                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style="display: flex;margin-top: 1em; ">
                        <div  >
                      
                          <div style="display: flex; margin-right: 3em;">
                            <div style="vertical-align: top; width: 12em;">
                              <label style="font-size:small;">Scale needs Adjustment</label>
                            </div>
                            <div>
                              <div style="background-color: #f8f8f8; width: 6em; border-radius: 4px; text-align: center;">
                                <div style="  margin-left: 0.4em ">
                                  <div>{{fuelscaleneedsadjustmentVal}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style=" width: 100%; ">
                      
                            <div style="display: flex; margin-right: 1em;">
                              <div style="width: 12em; text-align: end;">
                                <label style="font-size:small; margin-right: 1.5em; margin-top: -0.3em;">Unit of Measure</label>
                              </div>
                              <div>
                                <div style="  background-color: #f8f8f8; width: 6em; border-radius: 4px; text-align: center;">
                                  <div style="margin-top: 0.3em;">
                                    <div> {{unitofmeasureVal}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex;">
                              <div style="width: 12em;  text-align: end;">
                                <label style="font-size:small; margin-right: 1.5em; margin-top: -0.3em;">Currency</label>
                              </div>
                              <div>
                                <div style="background-color: #f8f8f8; width: 6em; border-radius: 4px; text-align: center;">
                                  <div style="margin-top: 0.3em; margin-left: 0.4em;">
                                    <div>{{currency}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <label style="font-size:small">Exclude Countries:</label>
                        </div>
                        <div *ngIf="selectedCountryList != undefinded" style="max-height: 11em;   overflow-y: auto;">
                          <div *ngFor="let country of selectedCountryList" style="background-color: #f8f8f8; padding: 5px; border-radius: 4px;">
                            <div style="margin-left:0.45em; ">
                              <div> {{country.countryName}}</div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="selectedCountryList == undefinded">
                          <div  style="margin-left:0.45em;"> n/a</div>
                        </div>
                      
                      </div>
            
            
                    </div>
















                  </div> 
                  <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em; white-space:pre-wrap"><label style="color:black; font-size: small">{{errMsg}}</label></div>
                  <div *ngIf="showSuccessMsg" style="margin-top: 0.5em;">
                    <app-success-message [successMsg]="successMsg"></app-success-message>
                  </div>
                </div>

                
                <div style="background-color: white; margin-left: 2em;">
                  <div style=" width: 92%; margin: 0em 2em 0em 1em;">
                    <div  > Required Fields*</div>
                    <div style="display:flex">
                      <div style=" margin-right: 3em;">
                        <table style="max-width: 22em; min-width: 18em;">
                          <tr>
                            <td   style=" padding-left: 0.5em !important;" style="padding: 0.5em;"><b>Approved Fields</b></td>
                          </tr>
                                  
                          <tr>
                            <td   style="color: red; padding-left: 0.5em !important; margin: 1em 0 0.5em 0; display:flex;" >At least one of   ICAO, IATA, FAAID  is required** </td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important; display:flex;" >ICAO*<span style="color: red;" *ngIf="missingColumns?.includes('ICAO')">&nbsp; -> Missing</span></td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important; display:flex;" >IATA*<span  style="color: red;" *ngIf="missingColumns?.includes('IATA')">&nbsp; -> Missing</span></td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important; display:flex;" >FAAID*<span  style="color: red;" *ngIf="missingColumns?.includes('FAAID')">&nbsp; -> Missing</span></td>
                          </tr>
                
                          <tr>
                            <td   style=" padding-left: 0.5em !important; display:flex;"  >IntoPlaneAgent*<span  style="color: red;" *ngIf="missingColumns?.includes('IntoPlaneAgent')">&nbsp; -> Missing</span></td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important;">EffectiveDate</td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important;">ExpiryDate</td>
                          </tr>
                          <tr>
                            <td  *ngIf="fuelscaleneedsadjustmentVal != 'Yes'" style=" padding-left: 0.5em !important; display:flex;" >Min_UnitOfMeasure*<span  style="color: red;" *ngIf="missingColumns?.includes('Max_UnitOfMeasure')">&nbsp; -> Missing</span></td>
                          </tr>
                          <tr>
                            <td  *ngIf="fuelscaleneedsadjustmentVal != 'Yes'"  style=" padding-left: 0.5em !important; display:flex;" > Max_UnitOfMeasure*<span  style="color: red;" *ngIf="missingColumns?.includes('Min_UnitOfMeasure')">&nbsp; -> Missing</span></td>
                          </tr>
                          <tr>
                            <td  *ngIf="fuelscaleneedsadjustmentVal != 'No'"  style=" display: flex; padding-left: 0.5em !important; display:flex; padding-right: 0.5em !important;" >MinMax_UnitOfMeasure*<span  style="color: red;" *ngIf="missingColumns?.includes('MinMax_UnitOfMeasure')">&nbsp; -> Missing</span></td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important;">UnitOfMeasure</td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important;">Currency</td>
                          </tr>
                          <tr>
                            <td   style="display: flex; padding-left: 0.5em !important; display:flex; padding-right: 0.5em !important;" >BaseFuel <span style="color: blue; ">(International)*<span  style="color: red;" *ngIf="missingColumns?.includes('BaseFuel')">&nbsp; -> Missing</span></span></td> 
                          </tr>
                          <tr>
                            <td   style="display: flex; padding-left: 0.5em !important; display:flex; padding-right: 1em !important;" >TotalPrices <span style="color: blue; ">(US Domestic)*<span  style="color: red;" *ngIf="missingColumns?.includes('TotalPrices')">  -> Missing</span></span></td>
                          </tr>
                          <!-- <tr>
                            <td   style=" padding-left: 0.5em !important;">TaxesIncluded</td>
                          </tr> -->
                          <tr>
                            <td   style=" padding-left: 0.5em !important;">ClientNotes</td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important;">InternalNotes</td>
                          </tr>
                        </table>
                      </div>
                      <div>
                        <table>
                          <tr>
                            <td  style="padding: 0.5em;"><b>Unit Of Measure</b></td>
                          </tr>
                          <tr>
                            <td><span>&nbsp;</span> </td>
                          </tr>
                          <tr>
                            <td style=" padding-left: 0.5em !important; padding-right: 3em !important;" >Values that are valid</td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important;"><span>&nbsp;</span> </td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important;">Gallons</td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important;">Liters</td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important;">MetricTons</td>
                          </tr>
                          <tr>
                            <td   style=" padding-left: 0.5em !important;">Kilograms</td>
                          </tr>
                        </table>
                        <div>
                          ** Can Upload Excel Files (.xlsx) Only.
                        </div>
 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="padding: 0.5em 0em 0.6em 1em; display: flex; ">
              <div style="display: flex;">
                <!-- <button  id="btnsubmit" mat-button [disabled]="loading || !showButton || !isValidated" class="btn-high" (click)="clickSend()">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Submit
                </button> -->
                <!-- <div *ngIf="revisefuelpricinglist" style=" font-size: small;">
                  Success: {{successCount}}     Failed:  {{failureCount}}
                </div> -->
                <button mat-button class="btn-high"  [disabled]="(loading || !showButton)" style="margin-left: 1em;" (click)="validateFileData()">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                   Submit
                </button>
                <!-- <button  id="btnsubmit" mat-button style="margin-left: 1em;" [disabled]="loading || !showButton || !isValidated" class="btn-high" (click)="clickSend()">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Submit
                </button> -->
                <button *ngIf="fromFuelPricingSummary" [style.display]="fromFuelPricingSummary ? 'block' : 'none'" id="btnsubmit" mat-button [disabled]="loading" class="btn-low" (click)="clickClose()" style="margin-left: 0.5em">
                  
                  <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">close</span>Close
                </button>
              </div>
             
              <div style="width: 16em; margin-left: 1em;" align = "end">
                <!--(click)="getCountryDocuments(element)"-->
                <button mat-button class="btn-low" (click)="downloadFile()" matTooltipClass="tooltipLineBreak"
                   matTooltip="Fuel pricing Template">Download Template</button>
              </div>
            </div>

            <!--</form>-->
          </div>

        </div>
        <div *ngIf="loading" style=" margin-left: 2em ; width: 36em;">
          <span style="font-size:small;color:red">**This may take time.Do not reload or refresh the page while prices are being uploaded</span>
        </div>
        <div *ngIf="revisefuelpricinglist && revisefuelpricinglist.length>0" style=" margin-left: 2em ; ">
          <h5>Total Records: {{totalRecordsCount | number}}. Invalid Airports:  {{failureCount | number}} Records. {{successCount | number}} Records Updated Succesfully. {{excludedCount | number}} Records Excluded</h5>
          <span style="font-size:small;color:red">**Airport not found with below IATA and ICAO</span>
          <table style="width: 36em;" mat-table *ngIf="revisefuelpricinglist" [dataSource]="revisefuelpricinglist" class="example-container mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="IATA">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:-0.25em;">IATA</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.iata}}</td>
              </ng-container>
              <ng-container matColumnDef="ICAO">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:-0.25em;">ICAO</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.icao}}</td>
              </ng-container>
              <ng-container matColumnDef="FAAID">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:-0.25em;">FAAID</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.faaid}}</td>
              </ng-container>
              <ng-container matColumnDef="FuelSupplierShortname">
                <th mat-header-cell *matHeaderCellDef style="width:7%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Fuel Supplier </div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.fuelSupplierShortname}}</td>
              </ng-container>
              <ng-container matColumnDef="ModifiedDate">
                <th mat-header-cell *matHeaderCellDef style="width:7%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Modified Date</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.modifiedDate | date: 'MM/dd/yy'}}</td>
              </ng-container>
              <ng-container matColumnDef="ModifiedBy">
                <th mat-header-cell *matHeaderCellDef style="width:7%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Modified By</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.modifiedBy}}</td>
              </ng-container>
            </tr>

            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
      </div>

      <!-- <div style=" width: 100%; margin: 2em;">
        <div style="display:flex">
          <div style=" margin: 0 4em 0 4em;">
            <table>
              <tr><td><b>Approved Fields</b></td></tr>
              <tr><td><span>&nbsp;</span> </td></tr>
              <tr><td>ICAO</td></tr>
              <tr><td>IATA</td></tr>
              <tr><td>IntoPlaneAgent</td></tr>
              <tr><td>EffectiveDate</td></tr>
              <tr><td>ExpiryDate</td></tr>
              <tr><td>Min_UnitOfMeasure</td></tr>
              <tr><td>Max_UnitOfMeasure</td></tr>
              <tr><td>UnitOfMeasure</td></tr>
              <tr><td>Currency</td></tr>
              <tr><td>BaseFuel</td></tr>
              <tr><td>TaxesIncluded</td></tr>
              <tr><td>ClientNotes</td></tr>
              <tr><td>InternalNotes</td></tr>
            </table>
          </div>
          <div>
            <table>
              <tr><td><b>UnitOfMeasure</b></td></tr>
              <tr><td><span>&nbsp;</span> </td></tr>
              <tr><td>Values that are valid</td></tr>
              <tr><td><span>&nbsp;</span> </td></tr>
              <tr><td>Gallons</td></tr>
              <tr><td>Liters</td></tr>
              <tr><td>MetricTons</td></tr>
              <tr><td>Kilograms</td></tr>
            </table>
            <table>
              <tr><td><b>Currency</b></td></tr>
              <tr><td><span>&nbsp;</span> </td></tr>
              <tr><td>Use the valid exchange rate currency code</td></tr> 
            </table>
            <table>
              <tr><td><b>TaxesIncluded</b></td></tr>
              <tr><td><span>&nbsp;</span> </td></tr>
              <tr><td>values "yes" or "no"</td></tr> 
            </table>
          </div>
        </div>
      </div>        
    -->


    
        <!--<span style="color:red">
    ** Retrieve the latest prices received from the <b>Selected Fuel Supplier</b>, add them to the
    <b>
      <a style="color: #ff0047; text-decoration: underline; background-color: transparent; font-size: larger; font-style: italic;" href="javascript:void(0)" (click)="downloadFile()" matTooltipClass="tooltipLineBreak"
         matTooltip="Fuel pricing Template"> Template</a>
    </b>, and upload them.
  </span>
  <img src="../../assets/images/FuelPriceDataEgImage.png" style="width:120%" />-->
    </div>
  </form>

</div>

