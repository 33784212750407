import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { TripModel } from '../models/trip.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopManifestDocumentModel } from '../models/ground-stop-manifest-document.model';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { PersonTravelDocSelectionDialogComponent } from '../ground-stop-advanced/person-travel-doc-selection-dialog.component';
import { ReturnObjModel } from '../models/return-obj.model';
import { CustomerPersonDetailsComponent } from '../persons/customer-person-details.component';

@Component({
  selector: 'app-trip-person-editor-client-dialog',
  templateUrl: './trip-person-editor-client-dialog.component.html',
  styleUrls: ['./trip-person-editor-client-dialog.component.css'],

})

export class TripPersonEditorClientDialogComponent implements OnInit, AfterViewInit {

  dialogTitle: string;
  customerGUID: string;
  tripCodeGUID: string;
  personGUID: string;
  groundStopGUID: string;
  nextArrivalGroundStopGUID: string;
  personType: string;
  departureCountryGUID: string;
  actionType: string;
  //alreadyHaveCrewOnTrip: boolean;
  //alreadyHavePaxOnTrip: boolean;
  trip_person_editor_client_form: UntypedFormGroup;
  loading = false;

  submitted = false;
  errMsg: string;
  errMsg2: string;
  msg: string;

  showSpin: boolean = false;

  isModified: boolean = false;

  showSuccessMsg: boolean = false;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  dataChanged: boolean;


  personName: string = "";
  leg: string = "";
  sameDocumentForDepArr: boolean = true;
  addToAllLeg: boolean = false;
  depDocumentList: GroundStopManifestDocumentModel[] = [];
  arrDocumentList: GroundStopManifestDocumentModel[] = [];
  returnObj = new ReturnObjModel();
  lockdownTrip: boolean;
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";
  tripLeg: TripLegModel;
  person: GroundStopManifestModel;
  capacityID: number = 0;
  legOrder: number;
  arrivalDateTimePassed: boolean = false;
  upPII: boolean = false;
  grantAccessToManageManifestProfiles: boolean;
  constructor(private readonly _dialogRef: MatDialogRef<TripPersonEditorClientDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService,
    private readonly _tripLegService: TripLegService, private readonly _dialog: MatDialog
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }
    this.tripCodeGUID = _data.tripCodeGUID;
    //this.tripLeg = _data.tripLeg;
    //this.person = _data.p;
    this.personGUID = _data.personGUID;
    this.groundStopGUID = _data.groundStopGUID;
    this.nextArrivalGroundStopGUID = _data.nextArrivalGroundStopGUID;
    this.personType = _data.personType;
    this.departureCountryGUID = _data.departureCountryGUID;
    this.actionType = _data.actionType;
    //this.alreadyHaveCrewOnTrip = _data.alreadyHaveCrewOnTrip;
    //this.alreadyHavePaxOnTrip = _data.alreadyHavePaxOnTrip;

  }

  getAllData(): Observable<any[]> {
    let reqTrip = new TripModel();
    reqTrip.tripCodeGUID = this.tripCodeGUID;
    reqTrip.customerGUID = this.customerGUID;
    reqTrip.personGUID = this.personGUID;
    reqTrip.personRole = this.personType;
    let getActiveLegResponse = this._tripLegService.getActiveTripLegListForPersonEditBytId(reqTrip);
    let getPersonLegResponse = this._tripLegService.getTripLegPersonListBytId(reqTrip);
    let request = new TripLegModel();
    //request.groundStopGUID = this.tripLeg.groundStopGUID;
    //request.nextArrivalGroundStopGUID = this.tripLeg.nextArrivalGroundStopGUID;
    //request.personGUID = this.person.personGUID
    request.groundStopGUID = this.groundStopGUID;
    request.nextArrivalGroundStopGUID = this.nextArrivalGroundStopGUID;
    request.personGUID = this.personGUID
    request.customerGUID = this.customerGUID;
    request.personType = this.personType;
    let getPersonLegDocResponse;
    if (this.actionType == 'Edit') {
      getPersonLegDocResponse = this._tripLegService.getPersonDocumentForLegBygsIdpId(request);
    }
    else {
      getPersonLegDocResponse = of(null);
    }
    let getPersonLegDefaultDocResponse;
    if (this.actionType == 'Add') {
      let requestDoc = new GroundStopManifestModel();
      requestDoc.groundStopCountryGUID = this.departureCountryGUID;
      requestDoc.personType = this.personType;
      requestDoc.personGUID = this.personGUID
      requestDoc.customerGUID = this.customerGUID;
      getPersonLegDefaultDocResponse = this._groundStopAdvancedService.getCustomerPersonDefaultTravelDocumentBypId(requestDoc);
    }
    else {
      getPersonLegDefaultDocResponse = of(null);
    }
    return forkJoin([getActiveLegResponse, getPersonLegResponse, getPersonLegDocResponse, getPersonLegDefaultDocResponse]);
  }

  ngOnInit() {

    this.showSpin = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.errMsg2 = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.msg = "";

    this.showSuccessMsg = false;

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].model != null) {
          let legList: TripLegModel[] = [];
          legList = responses[0].model;
          this.tripLeg = legList.find(x => x.groundStopGUID.toLowerCase() == this.groundStopGUID.toLowerCase() && x.nextArrivalGroundStopGUID.toLowerCase() == this.nextArrivalGroundStopGUID.toLowerCase());
          this.lockdownTrip = this.tripLeg.lockdownTrip;
          this.arrivalDateTimePassed = this.tripLeg.nextArrivalDateTimePassed;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].model != null) {
          this.person = responses[1].model[0];
          this.capacityID = this.person.capacityID;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].model != null) {
          let doclist: GroundStopManifestDocumentModel[];
          doclist = responses[2].model;
          if (doclist.length > 0) {
            this.depDocumentList = doclist.filter(x => x.legType == 'Departure');
            this.arrDocumentList = doclist.filter(x => x.legType == 'Arrival');
          }
          //if (this.arrDocumentList.length == 0) {
          //  this.sameDocumentForDepArr = true;
          //}
          //else {
            let hasdiffdoc: boolean = false;
            if (this.depDocumentList.length == this.arrDocumentList.length) {
              this.arrDocumentList.forEach(x => {
                if (this.depDocumentList.findIndex(y => y.personTravelDocumentGUID.toLowerCase() == x.personTravelDocumentGUID.toLowerCase()) == -1) {
                  hasdiffdoc = true;
                }
              });
              this.sameDocumentForDepArr = !hasdiffdoc;
            }
            else {
              this.sameDocumentForDepArr = false;
            }

          //}
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].model != null) {
          this.depDocumentList = responses[3].model;
          if (this.depDocumentList.length > 0) {
            this.sameDocumentForDepArr = true;
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.actionType == "Add") {
        this.dataChanged = true;
      }
      this.personType = this.person.personType;
      this.personName = this.person.personName;

      this.leg = "LEG " + this.tripLeg.tripLegOrder.toString() + " " + this.tripLeg.leg_ICAOs;
      this.legOrder = this.tripLeg.tripLegOrder;

      this.initControls();

    });



  }

  initControls() {

    this.trip_person_editor_client_form = this._formBuilder.group({
      sameDocumentForDepArr: [this.sameDocumentForDepArr],
      addToAllLeg: [false],
      //removeFromCurrentLeg:[true],
      removeFromAllActiveLeg: [false],
      removeFromTrip: [false],
    });
  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //  this.routeRef.nativeElement.focus();
    //}, 500);
  }

  get f() { return this.trip_person_editor_client_form.controls; }

  clickSelectDocument(legType: string) {
    let s = "";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "45em";//"1000px";
    dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    let sdoc = "";

    if (legType == 'Departure') {
      if (this.depDocumentList.length > 0) {
        sdoc = Array.prototype.map.call(this.depDocumentList, s => s.personTravelDocumentGUID).toString();
      }
    }

    if (legType == 'Arrival') {
      if (this.depDocumentList.length > 0) {
        sdoc = Array.prototype.map.call(this.arrDocumentList, s => s.personTravelDocumentGUID).toString();
      }
    }

    dialogConfig.data = {
      dialogTitle: s, personGUID: this.person.personGUID, personName: this.person.personName, personType: this.person.personType, selectedPersonTravelDocumentGUIDList: sdoc,
      v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(PersonTravelDocSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.dataChanged = true;
        if (legType == 'Departure') {
          this.depDocumentList = [];
          this.depDocumentList = result.groundStopManifestDocumentList;
        }
        if (legType == 'Arrival') {
          this.arrDocumentList = [];
          this.arrDocumentList = result.groundStopManifestDocumentList;
        }
      }
    });

  }

  sameDocumentForDepArrChange(e: any) {
    this.dataChanged = true;
    this.sameDocumentForDepArr = e.checked;
    if (this.sameDocumentForDepArr) {
      this.arrDocumentList = [];
    }
    else {
      this.getCustomerPersonDefaultDocument();
    }
  }

  addToAllLegChange(e: any) {
    this.dataChanged = true;
    this.addToAllLeg = e.checked;
  }

  //removeFromCurrentLegChange(e:any) {
  //  this.dataChanged = true;
  //  //this.removeFromCurrentLeg= e.checked;
  //}

  removeFromAllActiveLegChange(e: any) {
    this.dataChanged = true;
    //this.removeFromAllActiveLeg = e.checked;
  }

  removeFromTripChange(e: any) {
    this.dataChanged = true;
    //this.removeFromTrip = e.checked;
  }

  getCustomerPersonDefaultDocument() {
    let requestDoc = new GroundStopManifestModel();
    requestDoc.personType = this.person.personType;
    requestDoc.personGUID = this.person.personGUID
    requestDoc.customerGUID = this.customerGUID;
    if (this.sameDocumentForDepArr) {
      requestDoc.groundStopCountryGUID = this.tripLeg.departureCountryGUID;
    }
    else {
      requestDoc.groundStopCountryGUID = this.tripLeg.nextArrivalCountryGUID;
    }
    this._groundStopAdvancedService.getCustomerPersonDefaultTravelDocumentBypId<ResponseModel<GroundStopManifestDocumentModel[]>>(requestDoc).subscribe(response => {
      if (response != null && response.code == "200") {

        this.arrDocumentList = [];
        this.arrDocumentList = response.model;
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  clickSave(actionName: string) {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.actionType != 'RemoveOption' && this.actionType != 'Remove') {
      let applyCrewToAllStops: boolean;
      applyCrewToAllStops = false;
      let applyPaxToAllStops: boolean;
      applyPaxToAllStops = false;
      if (this.f.addToAllLeg.value == true) {
        applyCrewToAllStops = true;
        applyPaxToAllStops = true;
      }

      let selPerson: GroundStopManifestModel;

      let gsm: GroundStopManifestModel;
      gsm = new GroundStopManifestModel();
      gsm.personGUID = this.person.personGUID;
      gsm.personName = this.person.personName;
      gsm.groundStopManifestTypeDescription = this.person.personType;
      gsm.personType = this.person.personType;
      gsm.groundStopGUID = this.tripLeg.groundStopGUID;
      gsm.applyCrewToAllGroundStops = applyCrewToAllStops;
      gsm.applyPaxToAllGroundStops = applyPaxToAllStops;
      gsm.customerGUID = this.customerGUID;
      gsm.tripCodeGUID = this.tripCodeGUID;
      gsm.groundStopManifestDocumentList = this.depDocumentList;
      gsm.nextArrivalGroundStopGUID = this.tripLeg.nextArrivalGroundStopGUID;
      if (this.sameDocumentForDepArr) {
        gsm.nextArrivalGroundStopManifestDocumentList = this.depDocumentList;
      }
      else {
        gsm.nextArrivalGroundStopManifestDocumentList = this.arrDocumentList;
      }
      gsm.isTempTrip = true;
      gsm.tripLegList = this.person.tripLegList;
      //gsm.alreadyHaveCrewOnTrip = this.alreadyHaveCrewOnTrip;
      //gsm.alreadyHavePaxOnTrip = this.alreadyHavePaxOnTrip;
      this.loading = true;
      this._tripLegService.saveGroundStopManifestAndDocumentForLeg<ResponseModel<boolean>>(gsm).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model) {
            this.isModified = true;
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
            }, 1000);
            this.returnObj.refresh = this.isModified;
            this.returnObj.actionName = "";
            this._dialogRef.close(this.returnObj);
          }
          else {
            //
            //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        this.loading = false;
      })

    }
    else {
      let s: string = "";

      //if (this.f.removeFromCurrentLeg.value == false && this.f.removeFromAllActiveLeg.value == false && this.f.removeFromTrip.value == false) {
      //  this.errMsg = "Please select at least one removing type.";
      //  return;
      //}
      if (actionName == 'RemoveOption') {
        if (this.f.removeFromAllActiveLeg.value == false && this.f.removeFromTrip.value == false) {
          this.errMsg = "Please select at least one removing type.";
          return;
        }
      }
      if (actionName == 'Remove') {
        s = "Are you sure that you want to remove the person from the current leg?";
      }
      else {
        if (this.f.removeFromTrip.value == true) {
          s = "Are you sure that you want to remove the person from whole trip?";
        }
        else {
          if (this.f.removeFromAllActiveLeg.value == true) {
            s = "Are you sure that you want to remove the person from current leg and all next active legs?";
          }
          //else {
          //  s = "Are you sure that you want to remove the person from the current leg?";
          //}
        }
      }

      this.confirmDialogRef = this._dialogService.openConfirmDialog(s);
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          let selPerson: GroundStopManifestModel;

          let gsm: GroundStopManifestModel;
          gsm = new GroundStopManifestModel();
          gsm.personGUID = this.person.personGUID;
          gsm.personName = this.personName;
          gsm.groundStopManifestTypeDescription = this.person.personType;
          gsm.personType = this.person.personType;
          gsm.groundStopGUID = this.tripLeg.groundStopGUID;
          gsm.nextArrivalGroundStopGUID = this.tripLeg.nextArrivalGroundStopGUID;
          gsm.customerGUID = this.customerGUID;
          gsm.tripCodeGUID = this.tripCodeGUID;
          gsm.removeFromAllActiveLeg = false;
          gsm.removeFromTrip = false;
          if (this.actionType == 'RemoveOption') {
            gsm.removeFromAllActiveLeg = this.f.removeFromAllActiveLeg.value;
            gsm.removeFromTrip = this.f.removeFromTrip.value;
          }
          gsm.isTempTrip = true;
          this.loading = true;
          this._tripLegService.removeGroundStopManifestForLeg<ResponseModel<boolean>>(gsm).subscribe(response => {
            if (response != null && response.code == "200") {
              if (response.model) {
                this.isModified = true;
                this.showSuccessMsg = true;
                setTimeout(() => {
                  this.showSuccessMsg = false;
                }, 1000);
                this.returnObj.refresh = this.isModified;

                if ((gsm.removeFromAllActiveLeg && this.legOrder == 1) || gsm.removeFromTrip) {
                  this.returnObj.actionName = "remove";
                }
                else {
                  this.returnObj.actionName = "";
                }

                this._dialogRef.close(this.returnObj);
              }
              else {
                this.errMsg = "Failed to save the record. Please try again later.";
              }
            }
            else {
              if (response.code == "401") {
                //this.errMsg = response.message
                this._authService.signOut();
              }
              else {
                this.errMsg = "Failed to save the record. Please try again later.";
              }
            }
            this.loading = false;
          })


        }
        else {
          if (actionName == 'Remove') {
            this.actionType = 'Edit';
          }
        }
      });


    }

  }

  clickRemovePerson() {
    this.actionType = 'Remove';
    this.clickSave('Remove');
    //this.removeFromCurrentLegRef.nativeElement.focus();
    //setTimeout(() => {
    //  this.routeRef.nativeElement.focus();
    //}, 500);
  }

  clickRemovePersonOption() {
    this.actionType = 'RemoveOption';
    //this.removeFromCurrentLegRef.nativeElement.focus();
    //setTimeout(() => {
    //  this.routeRef.nativeElement.focus();
    //}, 500);
  }

  clickClose() {
    if (this.dataChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes you just made before close the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave('');
        }
        else {
          this.returnObj.refresh = false;
          this._dialogRef.close(this.returnObj);
        }
      });
    }
    else {
      this.returnObj.refresh = false;
      this._dialogRef.close(this.returnObj);
    }
  }

  clickOpenPersonDetail() {
    let personClassGUID: string = "";
    let personClassDescription: string = "";
    if (this.personType == 'PIC' || this.personType == 'SIC' || this.personType == 'Crew') {
      personClassGUID = "F82AA48E-31B7-4667-9FAA-24204DC4A65B";
      personClassDescription = "Crew";
    }
    if (this.personType != 'PAX') {
      personClassGUID = "72433A83-D1FF-4391-B92C-564A817C5438";
      personClassDescription = "PAX";
    }
    if (personClassGUID != "") {
      /*window.open('/persons/customer-person-details?pId=' + this.personGUID + '&selectedPersonClassGUID=' + personClassGUID + '&selectedPersonClassDescription=' + personClassDescription + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');*/
      // window.open('/persons/customer-person-details?pId=' + this.personGUID + '&selectedPersonClassGUID=' + personClassGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.panelClass = "custom-dialog-container";
      dialogConfig.maxHeight = "95vh";
      dialogConfig.hasBackdrop = false;
      dialogConfig.data = { personGUID: this.personGUID, customerGUID: this.customerGUID, selectedPersonClassGUID: personClassGUID, selectedPersonClassDescription: personClassDescription };

      const dialogRef = this._dialog.open(CustomerPersonDetailsComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getAllData();
        }

      });
    }
  }


}
