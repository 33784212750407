<div *ngIf="upPII" style="background-color:#f8f8f8; overflow-x:hidden; overflow-y:hidden">
  <div style="display: flex; padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div>
      <i class="material-icons-outlined" style="font-size: xx-large;cursor: pointer; color: gray">contact_page</i>
    </div>
    <div style="margin-top:0.3125em;display:flex"><h5>Contacts </h5></div>  
    <!-- Contacts {{userType=='internal'?'- Delivery Comms':''}} -->
  </div>
  <div>
    <form *ngIf="email_selection_client_form" [formGroup]="email_selection_client_form">
      <div style="display:flex; margin-top:0em; padding-left: 1em; padding-right:1em; ">
        <div style="margin-top:0em;background-color:white; padding:0.5em 0.5em 0.5em 0.5em;">
          <div style="display: flex">
            <div style="margin-right:0.5em" *ngIf="(openFrom=='task' || (openFrom=='email' && tripCodeGUID!='' && groundStopTaskGUID=='')) && showCommsInTrip">
              <div *ngIf="loading" class="spinner-border spinner-border-sm mr-1" style="margin-left:3.5em;margin-top:0.25em; z-index:100; position:absolute"></div>
              <button mat-button class="btn-high" [disabled]="loading" style="padding-left:0.25em !important; padding-right: 0.25em !important" (click)="clickSaveComm()">Save as Default Comms</button>
            </div>
            <div style="margin-right:0.5em" *ngIf="openFrom=='email'">
              <button mat-button class="btn-high" style="padding-left:0.25em !important; padding-right: 0.25em !important" (click)="clickSelectComm()">Select</button>
            </div>
            <div>
              <button mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
            </div>
            <div *ngIf="errMsg!=''" class="d-block" style="margin-top:0em; margin-left:0.25em; display:flex; background-color:#f9d2de; padding-left:0.25em; min-width:21em;font-size: small ">{{errMsg}}</div>
            <div *ngIf="showSuccessMsg" style="margin-top: 0em; margin-left: 0.25em;">
              <app-success-message [successMsg]="successMsg"></app-success-message>
            </div>
          </div>
          <div style="font-size: small; font-weight: bold;">Selected Comms</div>
          <!-- <div style="display: flex">
            <div style="font-size:small; margin-right:0.25em">To</div>
            <div>
              <div style="margin-right:0.5em">
                <textarea formControlName="selected_to" style="width:70em; height:3em;font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>
              </div>
              <div *ngIf="submitted && f.selected_to.errors" class="invalid-feedback" style="margin-top:0em; display:flex">
                <div *ngIf="f.selected_to.errors.validateEmail==false" style="background-color: #f9d2de;margin-top:-0.25em;padding-left:0.25em; min-width:9em">{{wrongToMsg}}</div>
                <div></div>
              </div>
            </div>
          </div> -->
          <div style="display: flex">
            <div style="font-size:small; margin-right:0.25em">Cc</div>
            <div>
              <div style="margin-right:0.5em">
                <textarea formControlName="selected_cc" style="width:70em; height:3em;font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>
              </div>
              <div *ngIf="submitted && f.selected_cc.errors" class="invalid-feedback" style="margin-top:-0.25em; display:flex">
                <div *ngIf="f.selected_cc.errors.validateEmail==false" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; min-width:9em">{{wrongCcMsg}}</div>
                <div></div>
              </div>
            </div>
          </div>
          <div class="example-action-buttons" *ngIf="personClassList.length>0 || gsList.length>0" style="margin-left:-1em; margin-top:-0.5em">
            <button mat-button (click)="openAll()" color="primary" style="outline:none; font-size: small">Expand All</button>
            <button mat-button (click)="closeAll()" color="primary" style="outline: none; font-size: small">Collapse All</button>
          </div>
          <div style="max-height:31em; overflow-y: auto; margin-top:-0.5em">
            <!--crew/pax-->
            <div style="margin-left:-1em">
              <mat-accordion class="example-headers-align" [multi]="true" style="padding-left:0em !important">
                <mat-expansion-panel *ngFor="let item of personClassList, let i=index"  [hidden]="item.personClassDescription  == 'PAX'"  [expanded]="item.panelOpenState" (opened)="item.panelOpenState=true" (closed)="item.panelOpenState=false">
                  <mat-expansion-panel-header [collapsedHeight]="'2em'" [hidden]="item.personClassDescription  == 'PAX'"  [expandedHeight]="'2em'" style="font-weight:500; font-size:medium !important">
                    <mat-panel-title>
                      <div>
                         {{item.personClassDescription}}
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div>
                    <table mat-table *ngIf="item.personList" [dataSource]="item.personList" class="example-container mat-elevation-z8">
                      <tr>
                        <!-- <ng-container matColumnDef="to">
                          <th mat-header-cell *matHeaderCellDef style="width:2%">
                            <div style="margin-left: 0.5em">To</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; vertical-align:top ">
                            <div *ngFor="let c of element.commAddressList; let i=index">
                              <div style="margin-left:0.45em;" class="mat-checkbox-smaller" [style.margin-top.em]="i==0?0.25:0">
                                <mat-checkbox (click)="$event.stopPropagation()" (change)="checkToChange($event, c)" [checked]="selectionTo.isSelected(c)"></mat-checkbox>
                              </div>
                            </div>
                          </td>
                        </ng-container> -->
                        <ng-container matColumnDef="cc">
                          <th mat-header-cell *matHeaderCellDef style="width:2%">
                            <div style="margin-left: 0.5em">Cc</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; vertical-align:top ">
                            <div *ngFor="let c of element.commAddressList; let i=index">
                              <div style="margin-left:0.45em;" class="mat-checkbox-smaller" [style.margin-top.em]="i==0?0.25:0">
                                <mat-checkbox (click)="$event.stopPropagation()" (change)="checkCcChange($event, c)" [checked]="selectionCc.isSelected(c)"></mat-checkbox>
                              </div>
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="personType">
                          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:20%">
                            <div style="margin-left: 0.5em"></div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left !important; vertical-align:top; white-space:normal" >
                            <div title="{{element.personType}}">{{element.personType}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="contact">
                          <th mat-header-cell *matHeaderCellDef style="text-align: left !important;width:18%">
                            <div style="margin-left: 0.5em">Contact</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; vertical-align: top; text-align: left !important;">{{element.personName}}</td>
                        </ng-container>
                        <ng-container matColumnDef="jobTitle">
                          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:13%">
                            <div style="margin-left: 0.5em">Title</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left !important; vertical-align:top">
                            {{element.jobTitle}}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="comms">
                          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:45%">
                            <div style="margin-left: 0.5em">Comms</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left !important; ">
                            <div *ngFor="let c of element.commAddressList; let i=index" style="display: flex">
                              <div style="margin-right: 0.5em; width:4em">{{c.commAddressType}}</div>
                              <div style="margin-right: 0.5em; min-width:18em">{{c.address}}</div>
                              <div style="margin-right: 0.5em; min-width: 10em">{{c.commRemarks}}</div>
                              <!--<div *ngIf="userType=='internal'"><a mat-button class="btn btn-group-sm2" style="align-self:center;" (click)="clickSelect($event, c);">Select</a></div>-->
                            </div>
                          </td>
                        </ng-container>
                      </tr>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnPerson; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnPerson"></tr>
                    </table>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <!--crew/pax end-->
            <!--vendor-->
            <div *ngFor="let g of gsList; let i=index" style="font-size: small;">
              <div style="font-weight: bold">{{g.icao}}{{g.iata!=''?' / '+g.iata:''}}&nbsp;{{g.airportName!=''?g.airportName+', ':''}}{{g.airportCountryName}}</div>
              <div style="margin-left:-1em">
                <mat-accordion class="example-headers-align" [multi]="true" style="padding-left:0em !important">
                  <mat-expansion-panel *ngFor="let item of g.groundStopTaskList, let i=index" [expanded]="item.panelOpenState" (opened)="item.panelOpenState=true" (closed)="item.panelOpenState=false">
                    <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'" style="font-weight:500; font-size:medium !important">
                      <mat-panel-title>
                        <div>
                          {{item.countryName!=''?item.countryName+' '+item.serviceTypeDescription:item.serviceTypeDescription}}&nbsp;-&nbsp;{{item.vendorName}}
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                      <table mat-table *ngIf="item.personList" [dataSource]="item.personList" class="example-container mat-elevation-z8">
                        <tr>
                          <!-- <ng-container matColumnDef="to">
                            <th mat-header-cell *matHeaderCellDef style="width:2%">
                              <div style="margin-left: 0.5em">To</div>
                            </th>
                            <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; vertical-align:top ">
                              <div *ngFor="let c of element.commAddressList; let i=index">
                                <div style="margin-left:0.45em;" class="mat-checkbox-smaller" [style.margin-top.em]="i==0?0.25:0">
                                  <mat-checkbox (click)="$event.stopPropagation()" (change)="checkToChange($event, c)" [checked]="selectionTo.isSelected(c)"></mat-checkbox>
                                </div>
                              </div>
                            </td>
                          </ng-container> -->
                          <ng-container matColumnDef="cc">
                            <th mat-header-cell *matHeaderCellDef style="width:2%">
                              <div style="margin-left: 0.5em">Cc</div>
                            </th>
                            <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;vertical-align:top ">
                              <div *ngFor="let c of element.commAddressList; let i=index">
                                <div style="margin-left:0.45em;" class="mat-checkbox-smaller" [style.margin-top.em]="i==0?0.25:0">
                                  <mat-checkbox (click)="$event.stopPropagation()" (change)="checkCcChange($event, c)" [checked]="selectionCc.isSelected(c)"></mat-checkbox>
                                </div>
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="jobTitle">
                            <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:20%">
                              <div style="margin-left: 0.5em">Title</div>
                            </th>
                            <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left !important; vertical-align:top">
                              {{element.jobTitle}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="contact">
                            <th mat-header-cell *matHeaderCellDef style="text-align: left !important;width:20%">
                              <div style="margin-left: 0.5em">Contact</div>
                            </th>
                            <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; vertical-align:top">{{element.personName}}</td>
                          </ng-container>
                          <ng-container matColumnDef="comms">
                            <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:56%">
                              <div style="margin-left: 0.5em">Comms</div>
                            </th>
                            <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left !important; ">
                              <div *ngFor="let c of element.commAddressList; let i=index" style="display: flex">
                                <div style="margin-right: 0.5em; width:4em">{{c.commAddressType}}</div>
                                <div style="margin-right: 0.5em; min-width:17em">{{c.address}}</div>
                                <div style="margin-right: 0.5em; min-width: 10em">{{c.commRemarks}}</div>
                                <!--<div><a mat-button class="btn btn-group-sm2" style="align-self:center;" (click)="clickSelect($event, c);">Select</a></div>-->
                              </div>
                            </td>
                          </ng-container>

                        </tr>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnVendor; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnVendor"></tr>
                      </table>


                    </div>

                  </mat-expansion-panel>
                </mat-accordion>
              </div>

            </div>
            <!--vendor end-->

          </div>

        </div>
      </div>
    </form>
  </div>
  <!--<div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; display: flex;height:3em">
    <div style="margin-right:1em">

      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
    </div>

  </div>-->
</div>

<!--<app-spin *ngIf="showSpin"></app-spin>-->
