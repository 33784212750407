import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { findIndex, first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
//import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
//import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopManifestDocumentModel } from '../models/ground-stop-manifest-document.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ReturnObjModel } from '../models/return-obj.model';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { FileModel } from '../models/file.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { MessageAttachmentModel } from '../models/message-attachment';

@Component({
  selector: 'app-task-document-selection-dialog',
  templateUrl: './task-document-selection-dialog.component.html',
  styleUrls: ['./task-document-selection-dialog.component.css']
})

export class TaskDocumentSelectionDialogComponent implements OnInit {

  dialogTitle: string;
  groundStopTaskGUID: string;
  serviceTypeDescription: string;
  serviceTypeID: number;
  task_doc_selection_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  //selectedTaskDocumentGUIDList: string = "";
  attachFileList: MessageAttachmentModel[] = [];
  taskDocList: GroundStopTaskDocumentModel[]=[];
  selection = new SelectionModel<GroundStopTaskDocumentModel>(true, []);

  displayedColumn: string[] = ['select', 'documentName'];
  isModified: boolean = false;
  upPII: boolean = false;
  checkedDocCount: number = 0;
  maxFileUploadNumber: number;
  maxTempDocId: number = 0;
  totalFileSize: number = 0;
  maxFileSize: number = 0;
  currentPageTotalFileSize: number = 0;
  constructor(private readonly _dialogRef: MatDialogRef<TaskDocumentSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    private readonly _commonService: CommonService,
    private readonly _dialogService: DialogService
  ) {
    this.groundStopTaskGUID = _data.groundStopTaskGUID;
    this.dialogTitle = _data.dialogTitle;
    this.attachFileList = _data.attachFileList;
    this.serviceTypeID = _data.serviceTypeID;
    this.totalFileSize = 0;
    if (_data.totalFileSize != null && _data.totalFileSize != undefined) {
      this.totalFileSize = _data.totalFileSize;
    }
    this.currentPageTotalFileSize = this.totalFileSize;
  }

  getAllData(): Observable<any[]> {

    let request = new GroundStopTaskModel();
    request.groundStopTaskGUID = this.groundStopTaskGUID;
   
    let getTaskDoc = this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId<ResponseModel<GroundStopTaskDocumentModel[]>>(request);
    let getServiceTypeResponse = this._groundStopAdvancedService.getServiceClass_ServiceTypeList<ResponseModel<ServiceTypeModel[]>>(0);
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();
    return forkJoin([getTaskDoc, getServiceTypeResponse, getAllowedDocTypeResponse]);

  }

  ngOnInit() {
    
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.taskDocList = responses[0].model;
          let fIndex: number = -2;
          this.taskDocList.forEach(row => {
            row.selected = false;
            if (this.attachFileList.length > 0) {
              fIndex = this.attachFileList.findIndex(x => x.groundStopTaskDocumentGUID!="" && x.groundStopTaskDocumentGUID.toLowerCase() == row.groundStopTaskDocumentGUID.toLowerCase());
              if (fIndex >= 0) {
                row.selected = true;
              }
            }
            if (row.selected) {
              this.selection.select(row);
            }
          });
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          let sList: ServiceTypeModel[] = [];
          sList= responses[1].model;
          this.serviceTypeDescription = sList.filter(x => x.serviceTypeID == this.serviceTypeID)[0].serviceTypeDescription;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          if (responses[2].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[2].model;
            //this.allowedFileTypeList = fileObj.allowedFileTypeList;
            //this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.attachFileList.length > 0) {
        this.maxTempDocId = this.attachFileList[this.attachFileList.length-1].tempId;
      }
      this.initControls();

      this.showSpin = false;


    });

  }

  initControls() {
    this.task_doc_selection_form = this._formBuilder.group({

    });
  }

  get f() { return this.task_doc_selection_form.controls; }

  clickPreviewDocument(e: any, item: GroundStopTaskDocumentModel) {
    let request = new GroundStopTaskDocumentModel();
    request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
    if (item.groundStopTaskDocumentGUID != "") {
      this._groundStopAdvancedService.getGroundStopTaskDocumentBygstdId<ResponseModel<GroundStopTaskDocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new GroundStopTaskDocumentModel();
            obj = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
            let file = new Blob([byteArray], { type: obj.mimeType });
            var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display:none");
            a.href = fileURL;
            a.target = "_blank";
            switch (obj.fileExtension) {
              case "doc":
              case "docx":
              case "xls":
              case "xlsx":
                a.download = obj.documentName;
            }
            a.click();
            document.body.removeChild(a);

          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
      let file = new Blob([byteArray], { type: item.mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

  }

  checkDocChange(e: any, item: GroundStopTaskDocumentModel) {
    e ? this.selection.toggle(item) : null;
    let fileSize: number = item.documentSizeInKB * 1024;
    if (e.checked) {
      this.checkedDocCount += 1;
      this.currentPageTotalFileSize += fileSize;
      if (this.currentPageTotalFileSize >= this.maxFileSize) {
        this.errMsg += 'The total file size over the limit. Please uncheck it.';
        return;
      }
      if (this.attachFileList.length > 0) 
      {
        if ((this.attachFileList.length + this.checkedDocCount) > this.maxFileUploadNumber) {
          this.errMsg += 'The maximum attach files are ' + this.maxFileUploadNumber.toString() + '. Please uncheck it.';
          return;
        }
      }

    }
    else {
      if (this.checkedDocCount > 0) {
        this.checkedDocCount -= 1;
        if (this.currentPageTotalFileSize > 0) {
          this.currentPageTotalFileSize -= fileSize;
        }
      }
    }
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    let selectedList = [];
    this.currentPageTotalFileSize = this.totalFileSize;
    this.selection.selected.forEach(row => {
      this.currentPageTotalFileSize += (row.documentSizeInKB * 1024);
    });
    if (this.currentPageTotalFileSize > this.maxFileSize) {
      this.errMsg = 'The total file size over the limit. Please uncheck some documents.';
      return;
    }

    this.selection.selected.forEach(row => {
      selectedList.push(row);
    });
   
    if (this.attachFileList.length > 0) {
      if ((this.attachFileList.length + selectedList.length) > this.maxFileUploadNumber) {
        this.errMsg = 'The maximum attach files are ' + this.maxFileUploadNumber.toString() + '.';
        return;
      }
    }
 
    let n: number = 0;
    let request: GroundStopTaskDocumentModel;
    selectedList.forEach(x => {
      this.loading = true;
      request = new GroundStopTaskDocumentModel();
      request.groundStopTaskDocumentGUID = x.groundStopTaskDocumentGUID;
      this._groundStopAdvancedService.getGroundStopTaskDocumentBygstdId<ResponseModel<GroundStopTaskDocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new MessageAttachmentModel();
            let obj2 = new GroundStopTaskDocumentModel();
            obj2 = response.model;
            let fIndex: number = -2;
            if (this.attachFileList.length > 0) {
              fIndex = this.attachFileList.findIndex(y => y.groundStopTaskDocumentGUID!="" && y.groundStopTaskDocumentGUID.toLowerCase() == obj2.groundStopTaskDocumentGUID.toLowerCase());
            }
            if (fIndex < 0) {
              this.maxTempDocId += 1;
              obj.documentName = obj2.documentName;
              //obj.filePath ="";
              obj.fileSize = obj2.documentSizeInKB;
              obj.bFile = obj2.bFile;
              obj.mimeType = obj2.mimeType;
              obj.fileExtension = obj2.fileExtension;
              obj.tempId = this.maxTempDocId;
              obj.groundStopTaskDocumentGUID = obj2.groundStopTaskDocumentGUID
              obj.messageAttachmentGUID = "";
              obj.documentGUID = "";
              this.attachFileList.push(obj);
            }
            n++;
            if (n == selectedList.length) {
              let response = new ReturnObjModel();
              response.refresh = true;
              response.msgAttachementList = this.attachFileList;

              this._dialogRef.close(response);
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }


      })

    });


    
  }

  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;
    this._dialogRef.close(response);
  }


}
