<div *ngIf="upPII" style="background-color: #f8f8f8;padding-left:1em; padding-right:1em; padding-top:1em">
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    
    <div style="margin-top:-0.5em;"><h5>Change Registration for this Trip</h5></div>
  </div>
  <div style="background-color: white; padding-left:1em; padding-right:1em">
    <form *ngIf="aircraft_selection_form" [formGroup]="aircraft_selection_form">
      <div style="display: flex">
        <div style="margin-right:2em">
          <div>
            <label style="font-size:medium; font-weight: bold">Select Registration</label>
          </div>

          <div *ngFor="let a of aircraftList;" style="font-size:small; display:flex">
            <div>
              <input formControlName="aircraft"
                     type="radio"
                     name="aircraft"
                     [value]="a" (change)="changeAircraft(a)" />
            </div>
            <div style="margin-top:-0.25em">{{a.registration }}</div>
          </div>
        </div>
        <div>
          <div style="font-size: medium; font-weight: bold">
            Revision Comment Required
          </div>
          <div style="font-size: small; ">
            <textarea formControlName="revisionComment" [ngClass]="{ 'is-invalid': submitted && f.revisionComment.errors }" style="width:25em; height:10em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd; border-radius:0.25em">   
           </textarea>
          </div>
          <div style="height:1.5em">
            <div *ngIf="submitted && f.revisionComment.errors" style="margin-top:0em">
              <div *ngIf="f.revisionComment.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:20em; font-size: small">Comment is a required field <!--and at least 15 characters-->.</div>
              <!--<div *ngIf="f.revisionComment.errors?.minlength" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:26em; font-size: small">Remarks is a required field and at least 15 characters.</div>-->
            </div>
          </div>
        </div>
     </div>
    </form>
  </div>
  <div>
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
      <div style="margin-right:0.5em">
        <button type="button" class="btn btn-primary" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Update</button>&nbsp;
        <button type="button" class="btn btn-secondary" (click)="clickClose()" style="color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
      </div>
      <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
      <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:23em"><label style="color:black; font-size: small">{{errMsg}}</label></div>

    </div>
  </div>
</div>
