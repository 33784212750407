<div *ngIf="upPII" style="background-color:#f8f8f8;min-height:28em">
  <!--<div style="background-color:#f8f8f8;">-->
  <div style="display: flex; justify-content: space-between;" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;">
      <h5>Documents for {{personType + " - "+ personName}}</h5>
    </div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
      <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
      </div>-->
  </div>
  <div>
    <form *ngIf="person_travel_doc_selection_form" [formGroup]="person_travel_doc_selection_form">
      <div style="padding-left: 1em; padding-right:1em">
        <div style="height: 23em;  background-color: white; padding-top:0.5em">
          <div style="margin-left:1em;margin-top:0em;height:22em; overflow-y:auto">
            <table mat-table *ngIf="docList" [dataSource]="docList" class="example-container2 mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Select</div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div style="margin-top:-0.25em" class="mat-checkbox-smaller"
                      *ngIf="element.dataEntryStatusDescription=='' || element.selected">
                      <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="checkPersonTravelDocChange($event, element)" [checked]="selection.isSelected(element)"
                        style="margin-top:0.5em"
                        [disabled]="element.disabled || ( element.documentTypeDescription == 'Passport' && disablePassportDocSelection)"></mat-checkbox>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="documentType">
                  <th mat-header-cell *matHeaderCellDef style="width:20%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Document&nbsp;Type</div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display:flex">
                      <div>{{element.documentTypeDescription}}</div>
                      <div [ngClass]="{'alert-danger': element.dataEntryStatusDescription!=''}" style="cursor: pointer"
                        title="Click to approve the document" (click)="clickUnApprovedDoc($event, element)">
                        {{element.dataEntryStatusDescription!=''?'('+element.dataEntryStatusDescription+')':'' }}</div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="documentNumber">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Number</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.documentNumber}}</td>
                </ng-container>
                <ng-container matColumnDef="documentIssuingCountry">
                  <th mat-header-cell *matHeaderCellDef style="width:20%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Issuing&nbsp;Country</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.documentIssuingCountryName}}</td>
                </ng-container>
                <ng-container matColumnDef="whenToUse">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                    <div style="margin-top:-0.25em;">When&nbsp;to&nbsp;Use</div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="white-space: nowrap">{{element.documentUsageRule}}</td>
                </ng-container>
                <ng-container matColumnDef="subDoc">
                  <th mat-header-cell *matHeaderCellDef style="width:20%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Visas</div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="white-space: pre-wrap">{{element.visaCountryName}}</td>
                </ng-container>
                <ng-container matColumnDef="documentDOI">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Effective&nbsp;Date</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.documentDOI}}</td>
                </ng-container>
                <ng-container matColumnDef="documentDOE">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Expiration&nbsp;Date&nbsp;</div>
                  </th>
                  <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger': element.isExpired}">
                    {{element.documentDOE}}</td>
                </ng-container>
              </tr>

              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;">
    <button mat-button class="btn-high" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"></span>
      <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i>
        <div>Select</div>
      </div>
    </button>&nbsp;
    <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;">
      <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
        <div>Close</div>
      </div>
    </button>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>