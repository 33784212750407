import { CustomerPersonBridgeModel } from "./customer-person-bridge.model";
import { CustomerModel } from "./customer.model";

export class CustomerGroupModel {
  customerGroupName: string;
  customerGroupGUID: string;
  internalNotes: string;
  customerCount: number;
  isActive: boolean;
  accountCreationDate: string;
  modifiedBy: string;
  modifiedDate: string;
  selectedCustomerGUIDList: string;
  customerGUID: string;
  errMsg: string;
  tooltip: string;
  customerList: CustomerModel[];
  personAccountList: CustomerPersonBridgeModel[];
  personCustomerGUID: string;
}
