import { FileModel } from "./file.model";
import { GroundStopManifestDocumentModel } from "./ground-stop-manifest-document.model";
import { MessageAttachmentModel } from "./message-attachment";
import { ServiceClassModel } from "./service-class.model";
import { TaxesAndFeesModel } from "./taxesandfees.model";
import { VendorModel } from "./vendor.model";

export class ReturnObjModel {
  refresh: boolean=false;
  newId: string = "";
  actionName: string;
  countryGUID: string;
  hotelChainGUID: string;
  groundStopManifestDocumentList: GroundStopManifestDocumentModel[];
  serviceClassList: ServiceClassModel[];
  applyAllGroundStops: boolean = false;
  hasAlternateAirport: boolean = false;
  groundStopTypeDescription: string;
  remarks: string;
  aircraftGUID: string;
  customerGUID: string;
  tripCodeType: string;
  vendorDetails: VendorModel[];
  docBFile: string;
  docSize: number;
  to: string;
  cc: string;
  msgAttachementList: MessageAttachmentModel[];
  isFerryFlight: boolean;
  taxesAndFees: TaxesAndFeesModel;
}
