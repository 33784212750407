export class CountryContentModel {
  countryContentID: number;
  countryID: number;
  countryContentSegment: string;
  countryContentSegmentID: number;
  countryContentCategory: string;
  countryContentCategoryOrig: string;
  countryContentCategoryID: number;
  countryContentDescription: string;
  icon: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  CategorySortOrder: number;
  hexColor: string;
}
