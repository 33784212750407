<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<div style="background-color:#f8f8f8;">
  <div  cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>{{dialogTitle}}</h5></div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
      <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
      </div>-->
  </div>
  <div>
    <form *ngIf="aircraft_comm_edit_form" [formGroup]="aircraft_comm_edit_form" (ngSubmit)="clickSave()"  >
      <div style="display:flex; margin-top:0em; padding-left: 1em">
        <div style="margin-top:0em;background-color:white; padding-left:0.5em; height: 20em;width:22em">
          <div style="display:flex">
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="comm_address_type_select" style="font-size:small">Type</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="comm_address_type_select" class="form-control form-control-sm dropdown" (change)="commAddressTypeChange($event)">
                  <option *ngFor="let cat of commAddressTypeList" [ngValue]="cat.commAddressTypeGUID">{{cat.commAddressTypeDesc}}</option>
                </select>
              </div>
            </div>
            <div style="margin-top:0em; margin-right:0.625em">
              <label for="preferred" style="font-size:small">Preferred</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="preferred" class="form-control form-control-sm dropdown">
                  <option value="1" selected>Yes</option>
                  <option value="0">No</option>
                  <!--<option *ngFor="let ar of addressRatingList" [ngValue]="ar.addressRatingGUID">{{ar.addressRatingDescription}}</option>-->
                </select>
              </div>
            </div>
            <div *ngIf="personCommsAddressGUID!=''" class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="comm_address_status" style="font-size:small">Disable Record</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="comm_address_status" class="form-control form-control-sm dropdown">
                  <option value="1" selected>No</option>
                  <option value="0">Yes</option>
                </select>
              </div>
            </div>
          </div>
          <div style="display:flex">
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="address" style="font-size:small">Address</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="address" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="addressChange()" />
              </div>
              <div *ngIf="submitted && f.address.errors" class="invalid-feedback" style="margin-top:-2em">
                <div *ngIf="f.address.errors.required">Address is a required field.</div>
              </div>
            </div>
            <div *ngIf="selectedCommAddressType=='Phone'" style="margin-left: 0em;margin-top:0em;">
              <label for="extension" style="font-size:small">Extension</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em">
                <input type="text" formControlName="extension" class="form-control" maxlength="5" [ngClass]="{ 'is-invalid': submitted && f.extension.errors }" style="padding:0em; width:5em;height:1.5625em; font-size:small; background-color:#eaf0fd" />
              </div>
              <div *ngIf="submitted && f.extension.errors" class="invalid-feedback">
                <div *ngIf="f.extension.errors.pattern">Extension must be digits</div>
              </div>
            </div>
          </div>
          <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; white-space:pre-wrap"><label style="color:red; font-size: small">{{errMsg}}</label></div>
          <div style="display:flex">
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="remarks" style="font-size:small">Remarks</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em">
                <textarea formControlName="remarks" class="form-control" maxlength=255 style="width:17em; height:8em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>
              </div>
            </div>


            <div *ngIf="personCommsAddressGUID!=''" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="modifiedBy" style="font-size:small">Modified By</label>
              <div style="margin-top:-0.5em">
                <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
              </div>
              <label for="modifiedDate" style="font-size:small">Modified Date</label>
              <div style="margin-top:-0.5em">
                <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
              </div>
            </div>
          </div>
          <div *ngIf="userType=='internal' && action=='edit'" class="mt-1" style="width:max-content">
            <label for="data_entry_status" style="font-size:small">Data Entry Status</label>
            <div style="margin-top:-0.5em">
              <select *ngIf="f" formControlName="data_entry_status" class="form-control form-control-sm dropdown">
                <option *ngFor="let d of dataEntryStatusList" [ngValue]="d.dataEntryStatusID">{{d.dataEntryStatusDescription}}</option>
              </select>
            </div>
          </div>
          <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>
        </div>
        <div style="width:0.5em; background-color: #f8f8f8;"></div>
        <div style="margin-top:0em; padding-left: 0.5em; padding-right:0em;margin-right:1em; background-color: white">
          <div *ngIf="action=='add' || userType=='internal'" style="font-size: small; margin-left:0em">Choose Address Use</div>
          <div style="display:flex">
            <div *ngIf="action=='add' || userType=='internal'" style="height: 18em;width:12em; overflow-y: auto;overflow-x:hidden;">
              <table mat-table *ngIf="avaiAddressUseList" [dataSource]="avaiAddressUseList" class="example-container2">
                <tr>
                  <ng-container matColumnDef="addressUse">
                    <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                      Address Use
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickAddItem($event, element)">{{element.addressUseDescription}}</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>
              
            </div>
            <div *ngIf="action=='add' || userType=='internal'"  style="margin-top: 2em;margin-left:0em">
              <button mat-button type="button" class="btn-high" [hidden]="avaiAddressUseList && avaiAddressUseList.length==0" (click)="clickSelectAll()" style="width: 7em">Select All</button><br /><br />
              <button mat-button type="button" class="btn-low" [hidden]="selAddressUseList && selAddressUseList.length==0" (click)="clickRemoveAll()" style="width: 7em">Remove All</button>
            </div>
            <div style="margin-left:1em;height: 18em;width:12em; overflow-y: auto;overflow-x:hidden">
              <table mat-table *ngIf="selAddressUseList" [dataSource]="selAddressUseList" class="example-container2 mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="addressUse">
                    <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                      Selected Address Use
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a [style.cursor]="action=='add' || userType=='internal'? 'pointer':''" style="text-decoration:underline; color: blue" (click)="clickRemoveItem($event, element)">{{element.addressUseDescription}}</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>
              
            </div>
          </div>
        </div>
      </div>
    </form>

  </div>

  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; display:flex;height:3em">
    <div style="margin-right: 1em">
      <button *ngIf="action=='add' || userType=='internal'" mat-button class="btn-high mr-1" (click)="clickSave()">Save</button>
      <button mat-button *ngIf="personCommsAddressGUID!='' && (action=='add' || userType=='internal')" type="button" class="btn-low mr-1" (click)="clickAddNew()">Add new Record</button>
      <button mat-button class="btn-low" (click)="clickClose()">Close</button>
    </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]=""></app-success-message>
    </div>

  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>





