import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { ServiceClassModel } from '../models/service-class.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { MatAccordion } from '@angular/material/expansion';
import { SelectionModel } from '@angular/cdk/collections';
import { ViewportScroller } from '@angular/common';
import { GroundStopService } from '../services/ground-stop.service';
import { CustomerModel } from '../models/customer.model';
import { TripModel } from '../models/trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { DocumentModel } from '../models/document.model';
import { DocumentCategoryModel } from '../models/document-category.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { TripLegService } from '../services/trip-leg.service';
import { TripLegModel } from '../models/trip-leg.model';
import { MessageAttachmentModel } from '../models/message-attachment';
import { CommonService } from '../services/common.service';
import { FileModel } from '../models/file.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { CustomerDocumentModel } from '../models/customer-document.model';
import { CustomerDocumentEditDialogComponent } from '../customers/customer-document-edit-dialog.component';
import { AircraftDocumentEditDialogComponent } from '../aircraft/aircraft-document-edit-dialog.component';
import { AircraftDocumentModel } from '../models/aircraft-document.model';
import { PersonTravelDocumentModel } from '../models/person-travel-document.model';
import { PersonPassportEditDialogComponent } from '../persons/person-passport-edit-dialog.component';
import { PersonTravelDocEditDialogComponent } from '../persons/person-travel-doc-edit-dialog.component';

@Component({
  selector: 'app-trip-document-selection-dialog',
  templateUrl: './trip-document-selection-dialog.component.html',
  styleUrls: ['./trip-document-selection-dialog.component.css']
})

export class TripDocumentSelectionDialogComponent implements OnInit {

  //groundStopGUID: string;
  //nextArrivalGroundStopGUID: string;
  customerGUID: string;
  tripCodeGUID: string;
  aircraftGUID: string;
  trip_document_selection_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;

  documentList: DocumentModel[] = [];
  documentCategoryList: DocumentCategoryModel[] = [];

  displayedColumn: string[] = ['check', 'documentType', 'documentName', 'expirationDate'];
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  tempFileNameDisplay: string;
  selection = new SelectionModel<DocumentModel>(true, []);

  successMsg = "";//"Downloads successfully";
  currentLeg: TripLegModel;
  upPII: boolean = false;
  upClient: boolean = false;
  upAircraft: boolean = false;
  pageFrom: string = "";
  attachFileList: MessageAttachmentModel[] = [];
  checkedDocCount: number = 0;
  maxFileUploadNumber: number;
  maxTempDocId: number = 0;
  tripCode: string = "";
  totalFileSize: number = 0;
  maxFileSize: number = 0;
  currentPageTotalFileSize: number = 0;
  userType: string = "";
  constructor(private readonly _dialogRef: MatDialogRef<TripDocumentSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialog: MatDialog,
    private readonly _tripLegService: TripLegService, private readonly _groundStopService: GroundStopService,
    private readonly _dialogService: DialogService, private readonly _commonService: CommonService, private readonly _groundStopClientService: GroundStopClientService
  ) {
    //this.groundStopGUID = _data.groundStopGUID;
    //this.nextArrivalGroundStopGUID = _data.nextGroundStopGUID;
    this.customerGUID = _data.customerGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.aircraftGUID = _data.aircraftGUID;
    this.pageFrom = _data.pageFrom;
    if (_data.attachFileList != null && _data.attachFileList != undefined)
      this.attachFileList = _data.attachFileList;
    this.totalFileSize = 0;
    if (_data.totalFileSize != null && _data.totalFileSize != undefined) {
      this.totalFileSize = _data.totalFileSize;
    }
    this.currentPageTotalFileSize = this.totalFileSize;

  }

  getAllData(): Observable<any[]> {
    let req = new AircraftTripModel();
    req.tripCodeGUID = this.tripCodeGUID;
    req.customerGUID = this.customerGUID;
    req.aircraftGUID = this.aircraftGUID;
    let tripResponse;      
    tripResponse = this._groundStopClientService.getTripBytId(req);   
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();
    return forkJoin([tripResponse, getAllowedDocTypeResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }
    if (localStorage.getItem('up.cPro') != 'true') {
      this.upClient = false;
    }
    else {
      this.upClient = true;
    }
    if (localStorage.getItem('up.acPro') != 'true') {
      this.upAircraft = false;
    }
    else {
      this.upAircraft = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.documentList = [];
    this.documentCategoryList = [];
    this.tempFileNameDisplay = "";
    this.showSuccessMsg = false;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].model != null) {
          let trip = new TripModel();
          trip = responses[0].model;
          this.tripCode = trip.tripCode;       
          this.tempFileNameDisplay = this.tripCode;        
          this.tempFileNameDisplay += "_";
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (responses[1].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[1].model;
            //this.allowedFileTypeList = fileObj.allowedFileTypeList;
            //this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.attachFileList.length > 0) {
        this.maxTempDocId = this.attachFileList[this.attachFileList.length - 1].tempId;
      }

      this.initControls();
      this.getData();

    });

  }

  initControls() {
    this.trip_document_selection_form = this._formBuilder.group({

    });

  }

  get f() { return this.trip_document_selection_form.controls; }

  getData() {
    this._authService.updateAccessTime();
    let request = new TripModel();
    request.customerGUID = this.customerGUID;
    request.aircraftGUID = this.aircraftGUID;   
    request.tripCodeGUID = this.tripCodeGUID;
    this.showSpin = true;
    this._groundStopService.getTripDocumentListBytId<ResponseModel<DocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.documentList = [];
        this.documentCategoryList = [];
        if (response.model.length > 0) {
          this.documentList = response.model;
          let tempCategetory: string = "";
          this.documentList.forEach(x => {
            if (tempCategetory != x.documentCategory) {
              tempCategetory = x.documentCategory;
              let y = new DocumentCategoryModel();
              y.documentCategory = x.documentCategory;
              y.documentList = this.documentList.filter(a => a.documentCategory == tempCategetory);
              this.documentCategoryList.push(y);
            }
            x.selected = false;
            let fIndex: number = -2;
            if (this.attachFileList.length > 0) {
              fIndex = this.attachFileList.findIndex(a => a.documentGUID != "" && a.documentGUID.toLowerCase() == x.documentGUID.toLowerCase());
              if (fIndex >= 0) {
                x.selected = true;
              }
            }
            if (x.selected) {
              this.selection.select(x);
            }
          });
          this.documentCategoryList[0].panelOpenState = true;

        }
        else {
          this.msg = "No documents returned";
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }

      this.showSpin = false;
    })

  }

  checkDocumentChange(e: any, item: DocumentModel) {
    this._authService.updateAccessTime();
    this.errMsg = "";
    e ? this.selection.toggle(item) : null;
    item.selected = e.checked;
    let n = 0;
    let currentDoc: DocumentModel[];
    currentDoc = this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentList;
    currentDoc.forEach(y => {
      if (y.selected == true) {
        n += 1;
      }
    });

    if (n > 0) {
      this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentSelectedCountDisplay = "(Selected " + n.toString() + ")";
    }
    else {
      this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentSelectedCountDisplay = "";
    }

    if (this.pageFrom == 'email') {
      let fileSize: number = item.documentSizeInKB * 1024;
      if (e.checked) {
        this.checkedDocCount += 1;
        this.currentPageTotalFileSize += fileSize;
        if (this.currentPageTotalFileSize >= this.maxFileSize) {
          this.errMsg += 'The total file size over the limit. Please uncheck it.';
          return;
        }
        if (this.attachFileList.length > 0) {
          if ((this.attachFileList.length + this.checkedDocCount) > this.maxFileUploadNumber) {
            this.errMsg += 'The maximum attach files are ' + this.maxFileUploadNumber.toString() + '. Please uncheck it.';
            return;
          }
        }
      }
      else {
        if (this.checkedDocCount > 0) {
          this.checkedDocCount -= 1;
          if (this.currentPageTotalFileSize > 0) {
            this.currentPageTotalFileSize -= fileSize;
          }
        }
      }
    }

  }

  nextStep(currentStep: number) {
    this._authService.updateAccessTime();
    this.documentCategoryList[currentStep].panelOpenState = false;
    if (currentStep < (this.documentCategoryList.length - 1)) {
      let next = currentStep + 1;
      this.documentCategoryList[next].panelOpenState = true;

    }

  }
  prevStep(currentStep: number) {
    this._authService.updateAccessTime();
    this.documentCategoryList[currentStep].panelOpenState = false;
    let prev = currentStep - 1;
    this.documentCategoryList[prev].panelOpenState = true;
  }

  openAll() {
    this._authService.updateAccessTime();
    this.documentCategoryList.forEach(x => x.panelOpenState = true);
  }

  closeAll() {
    this._authService.updateAccessTime();
    this.documentCategoryList.forEach(x => x.panelOpenState = false);
  }

  clickPreviewDocument(e: any, item: DocumentModel) {
    this._authService.updateAccessTime();
    let request = new DocumentModel();
    request.documentCategory = item.documentCategory;
    request.documentGUID = item.documentGUID;
    this._groundStopService.getTripDocumentById<ResponseModel<DocumentModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let doc = new DocumentModel();
          doc = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
          let file = new Blob([byteArray], { type: doc.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
        //else {
        //  this.msg = "No documents returned";
        //}
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }

    })

  }


  //clickDownload() {
  //  this._authService.updateAccessTime();
  //  this.submitted = true;
  //  if (this.loading == true) {
  //    return;
  //  }

  //  let selectedList: DocumentModel[];
  //  selectedList = [];
  //  this.selection.selected.forEach(row => {
  //    selectedList.push(row);
  //  });

  //  if (selectedList.length == 0) {
  //    this.errMsg = "Please select at least one document.";
  //    return;
  //  }

  //  let n: number = 0;
  //  selectedList.forEach(x => {
  //    this.loading = true;
  //    let request = new DocumentModel();
  //    request.documentCategory = x.documentCategory;
  //    request.documentGUID = x.documentGUID;
  //    this._groundStopService.getTripDocumentById<ResponseModel<DocumentModel>>(request).subscribe(response => {
  //      if (response != null && response.code == "200") {
  //        if (response.model != null) {
  //          let doc = new DocumentModel();
  //          doc = response.model;
  //          let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
  //          let file = new Blob([byteArray], { type: doc.mimeType });
  //          var fileURL = URL.createObjectURL(file);
  //          var link = document.createElement('a');
  //          link.href = fileURL;
  //          link.download = this.tempFileNameDisplay + x.documentName;
  //          link.click();
  //          n += 1;
  //          this.loading = false;
  //          if (n == selectedList.length) {
  //            this.showSuccessMsg = true;
  //            setTimeout(() => {
  //              this.showSuccessMsg = false;
  //            }, 1000);
  //          }
  //        }
  //      }
  //      else {
  //        if (response.code == "401") {
  //          this._authService.signOut();
  //        }
  //      }


  //    })

  //  });

  //}

  clickSelectDoc() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    let selectedList = [];
    this.currentPageTotalFileSize = this.totalFileSize;
    this.selection.selected.forEach(row => {
      this.currentPageTotalFileSize += (row.documentSizeInKB * 1024);
    });
    if (this.currentPageTotalFileSize > this.maxFileSize) {
      this.errMsg = 'The total file size over the limit. Please uncheck some documents.';
      return;
    }

    this.selection.selected.forEach(row => {
      selectedList.push(row);
    });

    if (this.attachFileList.length > 0) {
      if ((this.attachFileList.length + selectedList.length) > this.maxFileUploadNumber) {
        this.errMsg = 'The maximum attach files are ' + this.maxFileUploadNumber.toString() + '.';
        return;
      }
    }

    let n: number = 0;
    let request: DocumentModel;
    selectedList.forEach(x => {
      this.loading = true;
      request = new DocumentModel();
      request.documentCategory = x.documentCategory;
      request.documentGUID = x.documentGUID;
      this._groundStopService.getTripDocumentById<ResponseModel<DocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new MessageAttachmentModel();
            let obj2 = new DocumentModel();
            obj2 = response.model;
            let fIndex: number = -2;
            if (this.attachFileList.length > 0) {
              fIndex = this.attachFileList.findIndex(y => y.documentGUID != "" && y.documentGUID.toLowerCase() == obj2.documentGUID.toLowerCase());
              let a: number = 0;
            }
            if (fIndex < 0) {
              this.maxTempDocId += 1;
              obj.documentName = obj2.documentName;
              //obj.filePath ="";
              obj.fileSize = obj2.documentSizeInKB;
              obj.bFile = obj2.bFile;
              obj.mimeType = obj2.mimeType;
              obj.fileExtension = obj2.fileExtension;
              obj.tempId = this.maxTempDocId;
              obj.documentGUID = obj2.documentGUID;
              obj.messageAttachmentGUID = "";
              obj.groundStopTaskDocumentGUID = "";
              this.attachFileList.push(obj);
            }
            n++;
            if (n == selectedList.length) {
              let response = new ReturnObjModel();
              response.refresh = true;
              response.msgAttachementList = this.attachFileList;

              this._dialogRef.close(response);
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }


      })

    });
  }

  clickDownloadDoc() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    let selectedList = [];


    this.selection.selected.forEach(row => {
      selectedList.push(row);
    });


    let n: number = 0;
    let request: DocumentModel;
    selectedList.forEach(x => {
      this.loading = true;
      request = new DocumentModel();
      request.documentCategory = x.documentCategory;
      request.documentGUID = x.documentGUID;
      this._groundStopService.getTripDocumentById<ResponseModel<DocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let doc = new DocumentModel();
            doc = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
            let file = new Blob([byteArray], { type: doc.mimeType });
            var fileURL = URL.createObjectURL(file);
            var link = document.createElement('a');
            link.href = fileURL;
            link.download = this.tempFileNameDisplay + x.documentName;
            link.click();
            n += 1;
            this.loading = false;
            if (n == selectedList.length) {
              this.showSuccessMsg = true;
              setTimeout(() => {
                this.showSuccessMsg = false;
              }, 1000);
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }


      });

    });
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  clickUnApprovedDoc(e: any, item: DocumentModel) {
    
    switch (item.documentTypeDescription) {
      case "Client Document":
        let objC = new CustomerDocumentModel();
        objC.customerGUID = this.customerGUID;
        objC.customerDocumentGUID = item.documentGUID;
        this.openClientDocEdit(objC);
        break;
      case "Aircraft Document":
        let objA = new AircraftDocumentModel();
        objA.aircraftGUID=this.aircraftGUID       
        objA.aircraftDocumentGUID = item.documentGUID;
        this.openAircraftDocEdit(objA);
        break;
      case "Passport":
        let objP = new PersonTravelDocumentModel();
        objP.personTravelDocumentGUID = item.documentGUID;
        objP.personGUID = item.personGUID;
        this.openPassportEdit(objP);
        break;
      default:
        let objD = new PersonTravelDocumentModel();
        objD.personTravelDocumentGUID = item.documentGUID;
        objD.documentTypeGUID = item.documentTypeGUID;
        objD.personGUID = item.personGUID;
        this.openTravelDocEdit(objD);
        break;
    }
    
  }

  openClientDocEdit(doc: CustomerDocumentModel) {   
    let s = "";  
    s = "Edit Document";
   
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
   
    dialogConfig.disableClose = true;
    
    dialogConfig.data = { dialogTitle: s, customerGUID: doc.customerGUID, customerDocumentGUID: doc.customerDocumentGUID };
    

    const dialogRef = this._dialog.open(CustomerDocumentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.getSearchResults();
        this.documentList = [];
        this.getData();
      }
    });

  }

  openAircraftDocEdit(doc: AircraftDocumentModel) {

    let s = "";
    let action = "";
    
    s = "EDIT Record";
    action = "edit";
   
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
   
    dialogConfig.disableClose = true;
    dialogConfig.data = { dialogTitle: s, action: action, aircraftGUID: this.aircraftGUID, aircraftDocumentGUID: doc.aircraftDocumentGUID };

    const dialogRef = this._dialog.open(AircraftDocumentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });

  }

  openPassportEdit(doc: PersonTravelDocumentModel) {
    let s = "Edit Record";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: doc.personGUID, personTravelDocumentGUID: doc.personTravelDocumentGUID };
   
    const dialogRef = this._dialog.open(PersonPassportEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {      
        this.getData();
      }
    });

  }

  openTravelDocEdit(doc: PersonTravelDocumentModel) {
    let s = "";
      
    s = "Edit Record"  
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: doc.personGUID, personTravelDocumentGUID: doc.personTravelDocumentGUID, documentTypeGUID: doc.documentTypeGUID.toLowerCase() };
   
    const dialogRef = this._dialog.open(PersonTravelDocEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }

    });

  }
}
