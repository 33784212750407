<div *ngIf="upPII" style="background-color:#f8f8f8;min-height:28em;min-width: 60em">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div>
      <i class="material-icons-outlined" style="font-size: xx-large; color: gray">airline_stops</i>
    </div>
    <div style="margin-top:0.3125em;"><h5>Manage Trip Legs&nbsp;&nbsp;{{trip_code}}</h5></div>
  </div>
  <div>
    <form *ngIf="leglist_editor_form" [formGroup]="leglist_editor_form">
      <div style="margin-top:0em; padding-left: 1em; padding-right:1em; font-size: small">
        <div style="display: flex; padding-left:0em; height: 5.75em ">
          <div style="width:100%;background-color: white; padding-left:0.5em">
            <div style="display: flex">
              <div style="margin-left:2.75em">Client:</div>
              <div style="margin-left:0.5em; font-weight: bold">{{client_name}}</div>
            </div>
            <div style="display: flex">
              <div>Registration:</div>
              <div style="margin-left:0.5em; font-weight: bold">{{registration}}</div>
            </div>
            <div style="display: flex">
              <div style="margin-left:2.75em">Route: </div>
              <div style="margin-left:0.5em; ">
                <!--<div *ngIf="existingGroundStopList.length>12" style="display: flex" class="divHover" (mouseover)="showWholeRoute=true" (mouseout)="showWholeRoute=!showWholeRoute">
                  <div *ngFor="let gs of existingGroundStopList; let i=index">
                    <div *ngIf="i<=10" style="display: flex">
                      <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==existingGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i==11" style="display: flex">
                      <div [style.background-color]="gs.groundStopTypeColor">......</div>
                    </div>
                  </div>
                </div>-->
                <div style="display: flex; flex-wrap: wrap;">
                  <div *ngFor="let gs of existingGroundStopList; let i=index">
                    <div style="display: flex">
                      <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==existingGroundStopList.length-1? '': '-'}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showWholeRoute" style="margin-top:0.25em; margin-left: 5.5em; background-color: #f8f8f8; position:absolute; z-index:1000; border: 0.0625em solid lightgray; border-radius:0.25em; padding-left:0.25em; padding-right:0.25em">
              <div style="font-weight: bold">Route: </div>
              <div *ngFor="let gs of existingGroundStopList; let i=index">
                <div *ngIf="i % 11==0" style="display: flex">
                  <div style="display: flex">
                    <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                  <div *ngIf="i + 1 < existingGroundStopList.length" style="display: flex">
                    <div [style.background-color]="existingGroundStopList[i+1].groundStopTypeColor">{{existingGroundStopList[i+1].icao}}</div><div>{{(i+1)==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                  <div *ngIf="i + 2 < existingGroundStopList.length" style="display: flex">
                    <div [style.background-color]="existingGroundStopList[i+2].groundStopTypeColor">{{existingGroundStopList[i+2].icao}}</div><div>{{(i+2)==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                  <div *ngIf="i + 3 < existingGroundStopList.length" style="display: flex">
                    <div [style.background-color]="existingGroundStopList[i+3].groundStopTypeColor">{{existingGroundStopList[i+3].icao}}</div><div>{{(i+3)==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                  <div *ngIf="i + 4 < existingGroundStopList.length" style="display: flex">
                    <div [style.background-color]="existingGroundStopList[i+4].groundStopTypeColor">{{existingGroundStopList[i+4].icao}}</div><div>{{(i+4)==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                  <div *ngIf="i + 5 < existingGroundStopList.length" style="display: flex">
                    <div [style.background-color]="existingGroundStopList[i+5].groundStopTypeColor">{{existingGroundStopList[i+5].icao}}</div><div>{{(i+5)==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                  <div *ngIf="i + 6 < existingGroundStopList.length" style="display: flex">
                    <div [style.background-color]="existingGroundStopList[i+6].groundStopTypeColor">{{existingGroundStopList[i+6].icao}}</div><div>{{(i+6)==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                  <div *ngIf="i + 7 < existingGroundStopList.length" style="display: flex">
                    <div [style.background-color]="existingGroundStopList[i+7].groundStopTypeColor">{{existingGroundStopList[i+7].icao}}</div><div>{{(i+7)==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                  <div *ngIf="i + 8 < existingGroundStopList.length" style="display: flex">
                    <div [style.background-color]="existingGroundStopList[i+8].groundStopTypeColor">{{existingGroundStopList[i+8].icao}}</div><div>{{(i+8)==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                  <div *ngIf="i + 9 < existingGroundStopList.length" style="display: flex">
                    <div [style.background-color]="existingGroundStopList[i+9].groundStopTypeColor">{{existingGroundStopList[i+9].icao}}</div><div>{{(i+9)==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                  <div *ngIf="i + 10 < existingGroundStopList.length" style="display: flex">
                    <div [style.background-color]="existingGroundStopList[i+10].groundStopTypeColor">{{existingGroundStopList[i+10].icao}}</div><div>{{(i+10)==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <!--<div style="width:1em"></div>-->
          <!--<div style="min-width:36em;background-color: white; padding-left:0.5em; padding-right:0.5em">
            <div>Add new Airport Stops (ICAO / IATA)</div>
            <div>All comma, dash, blank space bewteen new airports</div>
            <div style="display:flex">
              <div style="margin-left: 0em; margin-top:0.2em">
                <input type="text" formControlName="route" #routeRef class="form-control" [ngClass]="{ 'is-invalid': (submitted || validatedAirport) && f.route.errors }" style="width:23em; height: 1.625em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd" />
              </div>
              <div style="margin-top:0em; margin-left:0.5em">
                <button type="button" mat-button class="btn-high" *ngIf="!lockdownTrip" [hidden]="f.route.value=='' || f.route.value.trim()==''" [disabled]="loadingAirport" (click)="clickValidateAirport()" style="padding:0em"><span *ngIf="loadingAirport" class="spinner-border spinner-border-sm mr-1"></span>Add Business Stops</button>
              </div>
            </div>
            <div *ngIf="(submitted || validatedAirport) && f.route.errors" class="invalid-feedback">             
              <div *ngIf="f.route.errors.pattern" style="background-color:#f9d2de; padding-left:0.25em; width:14em;margin-top:0.25em">Invalid characters for airport</div>
              <div *ngIf="f.route.errors.wrongAirport==true" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; min-width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
              <div *ngIf="f.route.errors.duplicateIATA==true" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; min-width:16em"><label style="color:black; font-size: small">{{duplicateIATAErrMsg}}</label></div>

            </div>
            
          </div>-->
        </div>
        <div style="margin-top:0.5em; min-height: 23em;">
          <div style="padding-left: 0em; padding-top:0em; padding-right:0em; background-color: white">
            <div style="height: 35em; overflow-y: auto;overflow-x:hidden;">
              <table mat-table *ngIf="displayLegList" [dataSource]="displayLegList" class="example-container2">
                <tr>
                  <ng-container matColumnDef="leginfo">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" style="border-bottom:none; text-align: left !important; padding-top:0em !important"></th>
                  </ng-container>
                  <ng-container matColumnDef="departure_arrival">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style="border-bottom:none; text-align: left !important; padding-top:0em !important">
                      <div style="display: flex; margin-left:7em; font-size:x-small">
                        <div style="margin-right:0.5em; margin-top:0.25em;">Choose</div>
                        <div style="display: flex; margin-top:0.5em; margin-right:0.5em">
                          <div style="margin-right:0.25em">
                            <input formControlName="time_type"
                                   type="radio"
                                   value="utc" [checked]="time_type=='utc'?true:false" (click)="timeTypeChange($event)" />
                          </div>
                          <div style="white-space: nowrap;margin-top:-0.15em">
                            <label>UTC</label>
                          </div>
                        </div>
                        <div style="display: flex; margin-top:0.5em">
                          <div style="margin-right:0.25em">
                            <input formControlName="time_type"
                                   type="radio"
                                   value="local" [checked]="time_type=='local'?true:false" (click)="timeTypeChange($event)" />
                          </div>
                          <div style="white-space: nowrap;margin-top:-0.15em">
                            <label>Local</label>
                          </div>
                        </div>
                      </div>
                    </th>
                  </ng-container>
                  <ng-container matColumnDef="other">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="16" style="border-bottom:none; text-align: left !important; padding-top:0em !important"></th>
                  </ng-container>
                </tr>
                <tr>
                  <ng-container matColumnDef="leg">
                    <th mat-header-cell *matHeaderCellDef style="border-bottom:none; text-align: center !important; padding-top:0em !important">Leg</th>
                  </ng-container>
                  <ng-container matColumnDef="hold">
                    <th mat-header-cell *matHeaderCellDef style="border-bottom:none; text-align: center !important; padding-top:0em !important">On</th>
                  </ng-container>
                  <ng-container matColumnDef="h1">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style="border-bottom:none; text-align: left !important; padding-top:0em !important"></th>
                  </ng-container>
                  <ng-container matColumnDef="tech">
                    <th mat-header-cell *matHeaderCellDef style="border-bottom:none; text-align: center !important; padding-top:0em !important">Tech</th>
                  </ng-container>
                  <ng-container matColumnDef="h2">
                    <th mat-header-cell *matHeaderCellDef style="border-bottom:none; text-align: left !important; padding-top:0em !important"></th>
                  </ng-container>
                  <ng-container matColumnDef="departure">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style="border-bottom:none; text-align: center !important; padding-top:0em !important">Departure</th>
                  </ng-container>
                  <ng-container matColumnDef="arrival">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style="border-bottom:none; text-align: center !important; padding-top:0em !important">Arrival</th>
                  </ng-container>
                  <ng-container matColumnDef="h3">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="4" style="border-bottom:none; text-align: left !important; padding-top:0em !important"></th>
                  </ng-container>
                  <ng-container matColumnDef="ferry">
                    <th mat-header-cell *matHeaderCellDef style="border-bottom:none; text-align: center !important; padding-top:0em !important">Ferry</th>
                  </ng-container>
                  <ng-container matColumnDef="h4">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" style="border-bottom:none; text-align: left !important; padding-top:0em !important"></th>
                  </ng-container>
                </tr>
                <tr>
                  <ng-container matColumnDef="leg_num">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap; width:2%">
                      #
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">{{element.displayTripLegOrder}}<!--{{element.tripLegOrder}}--></td>
                  </ng-container>
                  <ng-container matColumnDef="on_hold">
                    <th mat-header-cell *matHeaderCellDef style="width: 4%;">
                      Hold
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                      <div style="margin-top:0.25em"><mat-checkbox class="mat-checkbox-smaller" [checked]="element.departureOnHold" [(ngModel)]="element.departureOnHold" [ngModelOptions]="{standalone: true}" [disabled]="element.depReadOnly || element.cancelled || element.nextArrivalDateTimePassed" (change)="onHoldChange($event, element)"></mat-checkbox></div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="dept">
                    <th mat-header-cell *matHeaderCellDef style="width: 6%; text-align: left !important">
                      Dept
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left; font-weight: bold" [style.background-color]="element.departureGroundStopTypeColor">
                      {{element.departureICAO}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="arrv">
                    <th mat-header-cell *matHeaderCellDef style="width:6%; text-align: left !important">
                      Arrv
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left !important; font-weight: bold" [style.background-color]="element.nextArrivalGroundStopTypeColor">
                      {{element.nextArrivalICAO}}
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="techStop">
                    <th mat-header-cell *matHeaderCellDef style="width:4%;">
                      Stop
                    </th>
                    <!--<td mat-cell *matCellDef="let element" style="line-height: 1.5625em;text-align: left !important">
    <div style="margin-top:0.25em;">
      <mat-select class="mat-option-smaller" disableOptionCentering [disabled]="(element.depReadOnly && element.arrReadOnly) || element.cancelled || element.nextArrivalDateTimePassed" [style.background-color]="(element.depReadOnly && element.arrReadOnly) || element.cancelled || element.nextArrivalDateTimePassed? '': '#eaf0fd' " style="font-size: small; text-align: left; padding-left:0.25em; border: solid lightgray 0.0625em !important; border-radius:0.25em; width:4.5em; height:1.5em" [(ngModel)]="element.isNextArrivalTechStopNumber" [ngModelOptions]="{standalone: true}" (selectionChange)="techStopChange($event,element)">
        <mat-option [value]="1" style="height: 1.5em !important; ">Yes</mat-option>
        <mat-option [value]="0" style="height: 1.5em !important; ">No</mat-option>
      </mat-select>
    </div>
  </td>-->
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                      <div style="margin-top:0.25em"><mat-checkbox class="mat-checkbox-smaller" [checked]="element.isNextArrivalTechStopNumber" [(ngModel)]="element.isNextArrivalTechStopNumber" [ngModelOptions]="{standalone: true}" [disabled]="element.depReadOnly || element.cancelled || element.nextArrivalDateTimePassed" (change)="techStopChange($event, element)"></mat-checkbox></div>
                    </td>
                    <ng-container matColumnDef="contingency">
                      <th mat-header-cell *matHeaderCellDef style="width:15%; text-align: left !important">
                        Con.
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                        <!--<div *ngIf="element.hasContingencyStops" (click)="manageContingencies(element)" class="pointer">{{element.contingencyCountText}}</div>-->
                        {{element.departureGroundStopTypeGUID==contingencyGroundStopTypeGUID && element.isDepartureRepositioning || element.nextArrivalGroundStopTypeGUID==contingencyGroundStopTypeGUID && element.isNextArrivalRepositioning? 'R':element.departureGroundStopTypeGUID==contingencyGroundStopTypeGUID && !element.isDepartureRepositioning || element.nextArrivalGroundStopTypeGUID==contingencyGroundStopTypeGUID && !element.isNextArrivalRepositioning? 'B':''}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="departureDate_Time">
                      <th mat-header-cell *matHeaderCellDef style="width:15%; text-align: left !important">
                        Date / Time
                      </th>
                      <td mat-cell *matCellDef="let element; let rowIdx = index;" style="line-height: 1.5625em; text-align: left !important">
                        <div style="display:flex;margin-top:0em; margin-left:0em">
                          <div style="display:flex;margin-top: 0.4em">
                            <div>
                              <input type="text" matInput [matDatepicker]="deptdatepicker" [min]="minDate" placeholder="mm/dd/yyyy" [readonly]="element.depReadOnly || element.cancelled || element.nextArrivalDateTimePassed || (!element.isDepartureEditable && element.departureGroundStopTypeGUID != contingencyGroundStopTypeGUID)" [value]="time_type=='utc'?element.departureDateUTC_DTType:element.departureDateLocal_DTType" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidDeptDate,  'is-valid1': element.isValidDeptDate }"
                                     [(ngModel)]="time_type=='utc'?element.departureDateUTC_DTType:element.departureDateLocal_DTType" [ngModelOptions]="{standalone: true}" style="padding: 0.25em; width: 6em; height: 1.4em; font-size: small; border-radius:0.25em" maxlength="10" [style.background-color]="element.depReadOnly || element.cancelled || element.nextArrivalDateTimePassed? '': '#eaf0fd' " (dateChange)="dateOnchange('D', $event, element, displayLegList.indexOf(element))" (dateInput)="dateInputOnchange('D', $event, element, displayLegList.indexOf(element))" />
                            </div>
                            <div style="width:2.2em; margin-top:-1em">
                              <mat-datepicker-toggle matSuffix [for]="deptdatepicker" *ngIf="element.depReadOnly==false && !element.cancelled && !element.nextArrivalDateTimePassed" style="margin-top: -1em; margin-left: -0.85em; height: 1.5em; padding:0em;"></mat-datepicker-toggle>
                              <mat-datepicker #deptdatepicker [startAt]="element.depStartAt"></mat-datepicker>
                            </div>
                          </div>
                          <div style="margin-right:0.5em;margin-top: 0.5em">
                            <input type="text" matInput placeholder="hh:mm" maxlength="5" [readonly]="element.depReadOnly || element.cancelled || element.nextArrivalDateTimePassed || (!element.isDepartureEditable && element.departureGroundStopTypeGUID != contingencyGroundStopTypeGUID)" [value]="time_type=='utc'?element.departureTimeUTC:element.departureTimeLocal" [(ngModel)]="time_type=='utc'?element.departureTimeUTC:element.departureTimeLocal" [ngModelOptions]="{standalone: true}" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidDeptTime,  'is-valid1': element.isValidDeptTime }" [style.background-color]="element.depReadOnly || element.cancelled || element.nextArrivalDateTimePassed? '': '#eaf0fd' " style="width:4em;height:1.4em; font-size: small;padding:0.25em; border-radius:0.25em" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('D', $event, element, displayLegList.indexOf(element))" />
                          </div>
                        </div>
                        <div style="display:flex">
                          <div style="width:6.5em">
                            <mat-error *ngIf="!element.isValidDeptDate">
                              <div style="margin-left:0em; display:flex;margin-top:-0.25em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black;text-align:center;">Invalid date.</div>
                            </mat-error>
                          </div>
                          <mat-error *ngIf="!element.isValidDeptTime">
                            <div style="margin-left:2em; display:flex;margin-top:-0.25em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black; text-align:center">Invalid time.</div>
                          </mat-error>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="departure_status">
                      <th mat-header-cell *matHeaderCellDef style="width:8%; text-align: left !important">
                        Status
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left !important">
                        <div style="margin-top:0.25em;">
                          <mat-select disableOptionCentering [disabled]="(element.depReadOnly && element.arrReadOnly) || element.cancelled || element.nextArrivalDateTimePassed" [style.background-color]="element.arrReadOnly || element.cancelled || element.nextArrivalDateTimePassed? '' : element.departureStatusColorCode=='transparent'? '#eaf0fd': element.departureStatusColorCode" style="font-size: small; text-align: left; padding-left:0.25em; border: solid lightgray 0.0625em !important; border-radius:0.25em; height:1.5em" [(ngModel)]="element.departureStatusID" [ngModelOptions]="{standalone: true}" (selectionChange)="statusChange($event,element,'D')">
                            <mat-option *ngFor="let ad of adStatusList" [value]="ad.arrival_DepartureStatusID" style="height: 1.5em !important; " [style.background-color]="ad.colorCode==''? 'white':ad.colorCode">{{ad.arrival_DepartureStatusDescription}}</mat-option>
                          </mat-select>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="arrivalDate_Time">
                      <th mat-header-cell *matHeaderCellDef style="width:15%; text-align: left !important">
                        Date / Time
                      </th>
                      <td mat-cell *matCellDef="let element; let rowIdx = index;" style="line-height: 1.5625em;text-align: left !important">
                        <div style="display:flex;margin-top:0em; margin-left:0em">
                          <div style="display:flex;margin-top: 0.4em">
                            <div>
                              <input type="text" matInput [matDatepicker]="arrdatepicker" [min]="minDate" placeholder="mm/dd/yyyy" [readonly]="element.arrReadOnly || element.cancelled || element.nextArrivalDateTimePassed || (!element.isNextArrivalEditable && element.nextArrivalGroundStopTypeGUID != contingencyGroundStopTypeGUID)" [value]="time_type=='utc'?element.nextArrivalDateUTC_DTType:element.nextArrivalDateLocal_DTType" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidArrDate,  'is-valid1': element.isValidArrDate }"
                                     [(ngModel)]="time_type=='utc'?element.nextArrivalDateUTC_DTType:element.nextArrivalDateLocal_DTType" [ngModelOptions]="{standalone: true}" style="padding: 0.25em; width: 6em; height: 1.4em; font-size: small; border-radius:0.25em" maxlength="10" [style.background-color]="element.arrReadOnly || element.cancelled || element.nextArrivalDateTimePassed? '': '#eaf0fd' " (dateChange)="dateOnchange('A', $event, element, displayLegList.indexOf(element))" (dateInput)="dateInputOnchange('A', $event, element, displayLegList.indexOf(element))" />
                            </div>
                            <div style="width:2.2em; margin-top:-1em">
                              <mat-datepicker-toggle matSuffix [for]="arrdatepicker" *ngIf="element.arrReadOnly==false && !element.cancelled && !element.nextArrivalDateTimePassed" style="margin-top: -1em; margin-left: -0.85em; height: 1.5em; padding:0em;"></mat-datepicker-toggle>
                              <mat-datepicker #arrdatepicker [startAt]="element.arrStartAt"></mat-datepicker>
                            </div>
                          </div>
                          <div style="margin-right:0.5em;margin-top: 0.5em">
                            <input type="text" matInput placeholder="hh:mm" maxlength="5" [readonly]="element.arrReadOnly || element.cancelled || element.nextArrivalDateTimePassed|| (!element.isNextArrivalEditable && element.nextArrivalGroundStopTypeGUID != contingencyGroundStopTypeGUID)" [value]="time_type=='utc'?element.nextArrivalTimeUTC:element.nextArrivalTimeLocal" [(ngModel)]="time_type=='utc'?element.nextArrivalTimeUTC:element.nextArrivalTimeLocal" [ngModelOptions]="{standalone: true}" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidArrTime,  'is-valid1': element.isValidArrTime }" [style.background-color]="element.arrReadOnly || element.cancelled || element.nextArrivalDateTimePassed? '': '#eaf0fd' " style="width:4em;height:1.4em; font-size: small;padding:0.25em; border-radius:0.25em" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('A', $event, element, displayLegList.indexOf(element))" />
                          </div>
                        </div>
                        <div style="display:flex">
                          <div style="width:6.5em">
                            <mat-error *ngIf="!element.isValidArrDate">
                              <div style="margin-left:0em; display:flex;margin-top:-0.25em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black;text-align:center;">Invalid date.</div>
                            </mat-error>
                          </div>
                          <mat-error *ngIf="!element.isValidArrTime">
                            <div style="margin-left:2em; display:flex;margin-top:-0.25em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black; text-align:center">Invalid time.</div>
                          </mat-error>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="arrival_status">
                      <th mat-header-cell *matHeaderCellDef style="width:8%; text-align: left !important">
                        Status
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left !important">
                        <div style="margin-top:0.25em;">
                          <mat-select disableOptionCentering [disabled]="(element.depReadOnly && element.arrReadOnly && element.groundStopTypeGUID!='868a62b1-9cd6-499b-abfa-46542ccb7424') || element.cancelled || element.nextArrivalDateTimePassed" [style.background-color]="element.arrReadOnly || element.cancelled || element.nextArrivalDateTimePassed? '' : element.departureStatusColorCode=='transparent'? '#eaf0fd': element.departureStatusColorCode" style="font-size: small; text-align: left; padding-left:0.25em; border: solid lightgray 0.0625em !important; border-radius:0.25em;  height:1.5em" [(ngModel)]="element.nextArrivalStatusID" [ngModelOptions]="{standalone: true}" (selectionChange)="statusChange($event,element)">
                            <mat-option *ngFor="let ad of adStatusList" [value]="ad.arrival_DepartureStatusID" style="height: 1.5em !important; " [style.background-color]="ad.colorCode==''? 'white':ad.colorCode">{{ad.arrival_DepartureStatusDescription}}</mat-option>
                          </mat-select>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="ete">
                      <th mat-header-cell *matHeaderCellDef style="width:8%; text-align:left !important">
                        ETE
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;text-align:left !important">
                        <div style="margin-top:0.25em;">
                          <input type="text" matInput maxlength="5" placeholder="HH:MM" [readonly]="(element.depReadOnly && element.arrReadOnly && element.groundStopTypeGUID!='868a62b1-9cd6-499b-abfa-46542ccb7424') || element.cancelled || element.nextArrivalDateTimePassed" [value]="element.ete" [(ngModel)]="element.ete" [ngModelOptions]="{standalone: true}" class="form-control" [style.background-color]="(element.depReadOnly && element.arrReadOnly) || element.cancelled || element.nextArrivalDateTimePassed? '': '#eaf0fd' " style="width:4em;height:1.5em; font-size: small;padding:0.25em; border-radius:0.25em" (change)="eteTimeChange($event, element)" />
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="eteChanged">
                      <th mat-header-cell *matHeaderCellDef style="width:8%; text-align:center !important">
                        Confirmed
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;text-align:center !important">
                        <div style="margin-top:0.25em;">
                          {{element.eteChanged ? 'Yes': ''}}
                        </div>
                      </td>
                    </ng-container>

                  </ng-container>
                    <ng-container matColumnDef="crew_count">
                      <th mat-header-cell *matHeaderCellDef style="width:4%; ">
                        Crew
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                        {{element.crewCount>0?element.crewCount:''}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="pax_count">
                      <th mat-header-cell *matHeaderCellDef style="width:4%; ">
                        PAX
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                        {{element.paxCount>0?element.paxCount:''}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="ferry_flight">
                      <th mat-header-cell *matHeaderCellDef style="width: 4%">
                        Flight
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                        <div style="margin-top:0.5em"><mat-checkbox class="mat-checkbox-smaller" [checked]="element.isDeptFerryFlight" [(ngModel)]="element.isDeptFerryFlight" [ngModelOptions]="{standalone: true}" [disabled]="element.depReadOnly || element.cancelled || element.nextArrivalDateTimePassed" (change)="ferryFlightChange($event, element)"></mat-checkbox></div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="farType">
                      <th mat-header-cell *matHeaderCellDef style="width:8%; text-align: left !important">
                        FAR Type
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left !important">
                        <div style="margin-top:0.25em;">
                          <mat-select disableOptionCentering [disabled]="(element.depReadOnly && element.arrReadOnly && element.departureGroundStopTypeGUID!='868a62b1-9cd6-499b-abfa-46542ccb7424') || element.cancelled || element.nextArrivalDateTimePassed" [style.background-color]="(element.depReadOnly && element.arrReadOnly) || element.cancelled || element.nextArrivalDateTimePassed? '': '#eaf0fd' " style="font-size: small; text-align: left; padding-left:0.25em; border: solid lightgray 0.0625em !important; border-radius:0.25em; width:6em; height:1.5em" [(ngModel)]="element.departureFARTypeID" [ngModelOptions]="{standalone: true}" (selectionChange)="farTypeChange($event,element)">
                            <mat-option [value]="0" style="height: 1.5em !important; " selected></mat-option>
                            <mat-option *ngFor="let f of farTypeList" [value]="f.farTypeID" style="height: 1.5em !important; ">{{f.farTypeDescription}}</mat-option>
                          </mat-select>
                        </div>
                        <div style="display:flex">
                          <div style="width:6.5em">
                            <mat-error *ngIf="element.departureFARTypeID==0 && !element.cancelled && !element.nextArrivalDateTimePassed && element.departureGroundStopTypeGUID!=contingencyGroundStopTypeGUID">
                              <div style="margin-left:0em; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.125em; width:7em; font-size: x-small; color: black;text-align:left;">FAR is required.</div>
                            </mat-error>
                          </div>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="callSign">
                      <th mat-header-cell *matHeaderCellDef style="width:8%; text-align:left !important">
                        Call Sign
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;text-align:left !important">
                        <div style="margin-top:0.25em;">
                          <input type="text" matInput maxlength="20" [readonly]="(element.depReadOnly && element.arrReadOnly) || element.cancelled || element.nextArrivalDateTimePassed" [value]="element.departureCallSign" [(ngModel)]="element.departureCallSign" [ngModelOptions]="{standalone: true}" class="form-control" [style.background-color]="(element.depReadOnly && element.arrReadOnly) || element.cancelled || element.nextArrivalDateTimePassed? '': '#eaf0fd' " style="width:6em;height:1.5em; font-size: small;padding:0.25em; border-radius:0.25em" (change)="callSignChange($event, element)" />
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="pets">
                      <th mat-header-cell *matHeaderCellDef style="width: 4%">
                        Pets
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                        <div style="margin-top:0.5em"><mat-checkbox class="mat-checkbox-smaller" [checked]="element.departurePetsOnBoard" [(ngModel)]="element.departurePetsOnBoard" [ngModelOptions]="{standalone: true}" [disabled]="element.depReadOnly || element.cancelled || element.nextArrivalDateTimePassed" (change)="petChange($event, element)"></mat-checkbox></div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="weapons">
                      <th mat-header-cell *matHeaderCellDef style="width: 4%">
                        Weapons
                      </th>
                      <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                        <div style="margin-top:0.5em"><mat-checkbox class="mat-checkbox-smaller" [checked]="element.departureWeaponsOnBoard" [(ngModel)]="element.departureWeaponsOnBoard" [ngModelOptions]="{standalone: true}" [disabled]="element.depReadOnly || element.cancelled || element.nextArrivalDateTimePassed" (change)="weaponChange($event, element)"></mat-checkbox></div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef style="width:4%">
                        Action
                      </th>
                      <td mat-cell *matCellDef="let element; let i = index;" style="line-height: 1.5625em;">
                        <div class="d-flex">
                          <div style="margin-top:0.25em" *ngIf="!element.depReadOnly && !element.arrReadOnly && !element.cancelled && !element.nextArrivalDateTimePassed && element.departureGroundStopTypeGUID!=contingencyGroundStopTypeGUID && element.nextArrivalGroundStopTypeGUID!=contingencyGroundStopTypeGUID && !element.departureDateTimePassed && element.nextArrivalGroundStopGUID!=''"><i class="material-icons-outlined" style="font-size: x-large;cursor: pointer;color:gray" title="Insert a stop between" (click)="clickInsertLeg($event, element,'between');">add_location</i></div>
                          <div style="margin-top:0.25em" *ngIf="!element.depReadOnly && !element.arrReadOnly && !element.cancelled && !element.nextArrivalDateTimePassed && element.departureGroundStopTypeGUID!=contingencyGroundStopTypeGUID && element.nextArrivalGroundStopTypeGUID!=contingencyGroundStopTypeGUID && !element.departureDateTimePassed && element.nextArrivalGroundStopGUID!='' && i==0"><i class="material-icons-outlined" style="font-size: x-large;cursor: pointer;color:gray;transform:rotate(180deg)" title="Insert a stop before" (click)="clickInsertLeg($event, element,'before');">low_priority</i></div>
                          <div style="margin-top:0.25em" *ngIf="i==displayLegList.length-1"><i class="material-icons-outlined" style="font-size: x-large;cursor: pointer;color:gray" title="Insert a stop after" (click)="clickInsertLeg($event, element,'after');">low_priority</i></div>
                        </div>
                      </td>
                    </ng-container>
</tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn3; sticky: true"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>
              </table>

            </div>
            <!--<div class="d-flex justify-content-end pb-2 pr-2">
              <button mat-button class="btn-med"  (click)="clickInsertLeg($event, null, 0)">Add Airport</button>
            </div>-->
          </div>
        </div>
        
      </div>
</form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
    <div style="margin-right:1em; height: 2em; display: flex;">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <button type="button" mat-button class="btn-high" style="height: 2em;    margin-top: -0.15em;" *ngIf="legList && legList.length>0  && loading==false && showSuccessMsg==false && !lockdownTrip && !showSpin" [disabled]="loading" (click)="clickSave('', null)"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save</button>&nbsp;
      <!--<button type="button" mat-button class="btn-med" *ngIf="!showSpin"><div style="display:flex"><div>Arrange Airports</div></div></button>&nbsp;-->
        <button type="button" mat-button class="btn-med" *ngIf="!showSpin" (click)="cancelAirports()" style=" height: 2em; margin-top:-.25em">Manage Cancellations</button>&nbsp;
      <button type="button" mat-button class="btn-med" *ngIf="!showSpin" style=" height: 2em; margin-top:-.25em" (click)="manageContingencies()">Manage Contingencies</button>&nbsp;
      <button type="button" mat-button class="btn-med" *ngIf="!showSpin" style=" height: 2em; margin-top:-.25em" (click)="manageRouteSequence()">Manage Route Sequence</button>&nbsp;
      <button type="button" style="height: 2em;    margin-top: -0.15em;" mat-button class="btn-low" (click)="clickClose()"><div style=" display:flex"><div>Close</div></div></button>&nbsp;
    
      <!--<div  *ngIf="!showSuccessMsg" style="margin-left: 1.5em;"> ** Confirmed Value will be turned off if you changed it more than 2 Hours
      </div>-->
    </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
    <div *ngIf="errMsg2!=''" class="d-block" style="margin:0; display:flex;margin-top:0.0em; background-color:#f9d2de; padding-left:0.25em; min-width:22em; white-space:pre-wrap"><label style="color:black; font-size: small">{{errMsg2}}</label></div>
  </div>
</div>
<div *ngIf="showSpin" style="z-index:300; margin-top: -26em ; margin-left: 20em; position: fixed; min-width: 20em">
  <app-spin ></app-spin>
</div>
