import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { ServiceClassModel } from '../models/service-class.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { MatAccordion } from '@angular/material/expansion';
import { SelectionModel } from '@angular/cdk/collections';
import { ViewportScroller } from '@angular/common';
import { ReturnObjModel } from '../models/return-obj.model';
import { TripModel } from '../models/trip.model';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopModel } from '../models/ground-stop.model';

@Component({
  selector: 'app-ground-stop-service-type-selection-dialog',
  templateUrl: './ground-stop-service-type-selection-dialog.component.html',
  styleUrls: ['./ground-stop-service-type-selection-dialog.component.css']
})

export class GroundStopServiceTypeSelectionDialogComponent implements OnInit {

  dialogTitle: string;
  groundStopGUID: string;
  tripCodeGUID: string;
  serviceClassID: number;
  icao: string;
  customerGUID: string;

  ground_stop_service_type_selection_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  airportInfo: string;
  serviceClassList: ServiceClassModel[];
  recordList: ServiceTypeModel[];
  displayedColumn: string[] = ['check','serviceType', 'qty'];
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  selection = new SelectionModel<ServiceTypeModel>(true, []);
  //@ViewChildren('focusOnThisPanel') focusOnThisPanel: QueryList<ElementRef>
  returnObj = new ReturnObjModel();
  lockdownTrip: boolean = false;
  upPII: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<GroundStopServiceTypeSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialog: MatDialog,
    private readonly _airportService: AirportService, private readonly _groundStopService: GroundStopService,
    private readonly _dialogService: DialogService //, private viewportScroller: ViewportScroller
  ) {
    this.groundStopGUID = _data.groundStopGUID;

    this.dialogTitle = _data.dialogTitle;
    this.serviceClassID = _data.serviceClassID;
    this.icao = _data.icao;
    this.tripCodeGUID = _data.tripCodeGUID;
    if (_data.customerGUID == null) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }
  }

  getAllData(): Observable<any[]> {
    let req1 = new AirportModel();
    req1.route = this.icao;
    let getAirprotResponse = this._airportService.getAirportByICAO<ResponseModel<AirportModel[]>>(req1);
    let getServiceTypeResponse = this._groundStopAdvancedService.getServiceClass_ServiceTypeList(0);
    let obj1 = new TripModel();
    obj1.tripCodeGUID = this.tripCodeGUID;
    let lockdownTripResponse = this._groundStopService.getLockdownTripBytId(obj1);
    
    return forkJoin([getAirprotResponse, getServiceTypeResponse, lockdownTripResponse]);

  }

  ngOnInit() {
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }

    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";

    this.airportInfo = "";
    this.serviceClassList = [];
    this.recordList = [];
    this.showSuccessMsg = false;
    this.lockdownTrip = false;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let a = new AirportModel();
          a = responses[0].model[0];
          this.airportInfo = a.icao;
          if (a.iata != "") {
            this.airportInfo += " / " + a.iata;
          }
          this.airportInfo += " " + a.airportName + " " + a.isO2Letter;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.recordList = responses[1].model;
          let y: ServiceClassModel;
          let tempClassID = -1;
          this.recordList.forEach((x) => {
            if (tempClassID != x.serviceClassID) {
              tempClassID = x.serviceClassID;
              y = new ServiceClassModel();
              y.serviceClassID = x.serviceClassID;
              y.serviceClassDescription = x.serviceClassDescription;
              y.serviceTypeList = this.recordList.filter(a => a.serviceClassID == tempClassID);
              y.serviceTypeList.forEach(x => x.quantity = 1);             
              this.serviceClassList.push(y);
            }
            
          });
          if (this.serviceClassID == 0) {
            this.serviceClassList[0].panelOpenState = true;
          }
          else {
            let expandIndex: number;
            expandIndex= this.serviceClassList.findIndex(x => x.serviceClassID == this.serviceClassID);
            this.serviceClassList[expandIndex].panelOpenState = true;
          }
         
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.lockdownTrip = responses[2].model;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;

    });

  }

  initControls() {
    this.ground_stop_service_type_selection_form = this._formBuilder.group({
      saveToAllStops: []//,
      //checkVendorAssignment: [false]
    });
    
  }

  get f() { return this.ground_stop_service_type_selection_form.controls; }

  nextStep(currentStep: number) {
    this.serviceClassList[currentStep].panelOpenState = false;
    if (currentStep < (this.serviceClassList.length - 1)) {
      let next = currentStep + 1;
      this.serviceClassList[next].panelOpenState = true;
      //this.focusOnThisPanel.toArray()[next].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      //this.viewportScroller.scrollToAnchor('panel_${next}');
      //document.querySelector('#panel_' + next.toString()).scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

  }
  prevStep(currentStep: number) {       
    this.serviceClassList[currentStep].panelOpenState = false;
    let prev = currentStep - 1;
    this.serviceClassList[prev].panelOpenState = true;    
    //this.focusOnThisPanel.toArray()[prev].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //this.viewportScroller.scrollToAnchor('panel_${prev}');
    //document.querySelector('#panel_' + prev.toString()).scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  openAll() {  
    this.serviceClassList.forEach(x => x.panelOpenState = true);
  }

  closeAll() { 
    this.serviceClassList.forEach(x => x.panelOpenState = false);
  }

  qtyChange(e: any, item: ServiceTypeModel) {
    if (e.target.value != "") {
      let n = Number(e.target.value);
      if (n > 10) {
        e.target.value = 10;
      }
      else {
        if (n <= 0) {
          e.target.value = 1;
        }
      }
    }
    
  }

  checkServiceTypeChange(e: any, item: ServiceTypeModel) {  
    e?this.selection.toggle(item): null;
    if (e.checked == true) {
      if (item.quantity == 0 || item.quantity == null || item.quantity == undefined) {
        item.quantity = 1;
      }
    }
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    let selectedList: ServiceTypeModel[];
    selectedList = [];
    this.selection.selected.forEach(row => {
      selectedList.push(row);
    });

    if (selectedList.length == 0) {
        this.errMsg = "Please select at least one service type.";
        return;
    }

    this.loading = true;
    let req = new GroundStopTaskModel();
    req.groundStopGUID = this.groundStopGUID;
    req.tripCodeGUID = this.tripCodeGUID;
    req.customerGUID = this.customerGUID;
    req.applyAllGroundStops = false;
    if (this.f.saveToAllStops.value == true) {
      req.applyAllGroundStops = true;
    }
    req.checkVendorAssignment = false;
    //if (this.f.checkVendorAssignment.value == true) {
    //  req.checkVendorAssignment = true;
    //}
    req.serviceTypeIDList = "";
    let selectedServiceClassList: ServiceClassModel[];
    selectedServiceClassList = [];
    let tempServiceClassName: string = "";
    selectedList.forEach(x => {
      if (tempServiceClassName != x.serviceClassDescription) {
        tempServiceClassName = x.serviceClassDescription;
        let y = new ServiceClassModel();
        y.serviceClassDescription = x.serviceClassDescription;
        y.serviceClassID = x.serviceClassID;
        selectedServiceClassList.push(y);
      }
      for (var i = 0; i<x.quantity; i++) {
        if (req.serviceTypeIDList != "") {
          req.serviceTypeIDList += ",";
        }
        req.serviceTypeIDList += x.serviceClassID.toString()+"-"+x.serviceTypeID.toString();
      }            
    });

    this._groundStopAdvancedService.createGroundStopTask<ResponseModel<boolean>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.isModified = true;         
          this.returnObj.refresh = true;
          this.returnObj.serviceClassList = selectedServiceClassList;
          this.returnObj.applyAllGroundStops = req.applyAllGroundStops;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);
          //this.clickClose();
        }
        else {
          //this._dialogService.openAlertDialog("Failed to create services at this time. Please try later.");
          this.errMsg = "Failed to create servies. Please try again later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to create servies. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  clickClose() {
    //this._dialogRef.close(this.isModified);
    this._dialogRef.close(this.returnObj);
  }


}
