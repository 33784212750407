import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CommAddressTypeModel } from '../models/comm-address-type.model';
import { AddressRatingModel } from '../models/address-rating.model';
import { AddressUseModel } from '../models/address-use.model';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { PersonClassModel } from '../models/person-class.model';
import { PersonService } from '../services/person.service';
import { CountryModel } from '../models/country.model';
import { PersonRoleModel } from '../models/person-role.model';
import { PersonModel } from '../models/person.model';
import { CustomerPersonEditDialogComponent } from './customer-person-edit-dialog.component';
import { FileItem, FileUploader, ParsedResponseHeaders, FileUploadModule, FileSelectDirective } from 'ng2-file-upload';
import { GlobalConstant } from '../common-utility/global-constant';
import { FileModel } from '../models/file.model';
import { PersonTravelDocumentModel } from '../models/person-travel-document.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { PersonPassportEditDialogComponent } from './person-passport-edit-dialog.component';
import { PersonVisaEditDialogComponent } from './person-visa-edit-dialog.component';

@Component({
  selector: 'app-person-passport-visalist-dialog',
  templateUrl: './person-passport-visalist-dialog.component.html',
  styleUrls: ['./person-passport-visalist-dialog.component.css']
})

export class PersonPassportVisalistDialogComponent implements OnInit {
  @Input() personGUID: string;
  @Input() personTravelDocumentGUID: string;
  person_passport_form: UntypedFormGroup;
  personTravelSubDocumentGUID: string;

  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = false;
  
  firstName: string;
  lastName: string;
  middleName: string;
  documentNumber: string;
  effectiveDate: string;
  expirationDate: string;
  remarks: string;
  selectedIssuingCountryName: string;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  hasDocument: boolean = false;
  person_doc_status: string;
  doc_usage_rule_select: string;
  selectedCountryList: CountryModel[];
  includeDisabledRecords: boolean = false;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 8;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  customerGUID: string;
  text_search: string;
  filter_by: string;
  recordList: PersonTravelDocumentModel[];
  isModified: boolean = false;

  displayedColumn: string[] = ['documentNumber', 'issuingCountry', 'effectiveDate', 'expirationDate', 'name', 'notes', 'data_entry_status', 'disabled', 'action'];
  upPII: boolean = false;
  grantAccessToManageManifestProfiles: boolean;
  userType: string;
  readonly: boolean;
  hideColumn: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _personService: PersonService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute,
    private readonly _dialog: MatDialog, private readonly _dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialogRef: MatDialogRef<PersonPassportVisalistDialogComponent>) {
    this.personGUID = _data.personGUID;
    this.personTravelDocumentGUID = _data.personTravelDocumentGUID;
  }

  getAllData(): Observable<any[]> {
    let getPassportResponse = this._personService.getCustomerPersonTravelDocumentByptdId<ResponseModel<PersonTravelDocumentModel>>(this.personTravelDocumentGUID);
    let request = new PersonTravelDocumentModel();
    request.personTravelDocumentGUID = this.personTravelDocumentGUID;
    request.isActive = true;
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.personGUID = this.personGUID;
    let getVisaResponse = this._personService.getCustomerPersonTravelSubDocument<ResponseModel<PersonTravelDocumentModel[]>>(request);   
    return forkJoin([getPassportResponse, getVisaResponse]);
    
  }

  ngOnInit() {
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }
    if (!this.upPII || !this.grantAccessToManageManifestProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.showSpin = true;
    this.firstName = "";
    this.lastName = "";
    this.middleName = "";
    this.documentNumber = "";
    this.effectiveDate = null;
    this.expirationDate = null;
    this.remarks = "";
    this.selectedIssuingCountryName = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.person_doc_status = "";
    this.doc_usage_rule_select = "";
    this.selectedCountryList = [];
    this.hasDocument = false;
    this.errMsg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType == 'customer') {
      this.hideColumn = true;
    }
    if (this.userType == 'customer' && this.personTravelDocumentGUID != '') {
      this.readonly = true;
    }
    else {
      this.readonly = false;
    }
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let doc = new PersonTravelDocumentModel();
          doc = responses[0].model;
          this.documentNumber = doc.documentNumber;
          this.selectedIssuingCountryName = doc.documentIssuingCountryName;
          this.effectiveDate = doc.documentDOI;
          this.expirationDate = doc.documentDOE;
          this.firstName = doc.firstName;
          this.lastName = doc.lastName;
          this.middleName = doc.middleName;
          this.remarks = doc.documentBriefNotes;
          if (doc.isActive) {
            this.person_doc_status = "Yes";
          }
          else {
            this.person_doc_status = "No";
          }
          this.modifiedBy = doc.modifiedBy;
          this.modifiedDate = doc.modifiedDate;
          this.doc_usage_rule_select = doc.documentUsageRule;
          this.hasDocument = doc.hasDocument;
          this.selectedCountryList = doc.countryList;
          
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.recordList = responses[1].model;
          if (this.recordList.length > 0) {
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize);
          }
          }

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;

    });
    
  }

  initControls() {
    this.person_passport_form = this._formBuilder.group({
      firstName: [this.firstName],
      lastName: [this.lastName],
      middleName: [this.middleName],
      documentNumber: [this.documentNumber],
      effectiveDate: [this.effectiveDate],
      expirationDate: [this.expirationDate],
      remarks: [this.remarks],
      issing_country_select: [this.selectedIssuingCountryName],
      person_doc_status: this.person_doc_status,
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      doc_usage_rule_select: [this.doc_usage_rule_select],
      includeDisabledRecords: [this.includeDisabledRecords]
    });
  }

  getPersonPassportInfo() {
    this._personService.getCustomerPersonTravelDocumentByptdId<ResponseModel<PersonTravelDocumentModel>>(this.personTravelDocumentGUID,false).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let doc = new PersonTravelDocumentModel();
          doc = response.model;
          this.documentNumber = doc.documentNumber;
          this.selectedIssuingCountryName = doc.documentIssuingCountryName;
          this.effectiveDate = doc.documentDOI;
          this.expirationDate = doc.documentDOE;
          this.firstName = doc.firstName;
          this.lastName = doc.lastName;
          this.middleName = doc.middleName;
          this.remarks = doc.documentBriefNotes;
          if (doc.isActive) {
            this.person_doc_status = "Yes";
          }
          else {
            this.person_doc_status = "No";
          }
          this.modifiedBy = doc.modifiedBy;
          this.modifiedDate = doc.modifiedDate;
          this.doc_usage_rule_select = doc.documentUsageRule;
          this.hasDocument = doc.hasDocument;
          this.selectedCountryList = doc.countryList;

        }
        else {
          this.errMsg = "Failed to load the person information";
        }
      }
      else {
        this.errMsg = "Errors to load the person information";
      }
      this.loading = false;
      this.showSpin = false;

      this.initControls();

    })


  }

  clickPreviewDocument() {
    let personTravelDocumentGUID = this.personTravelDocumentGUID;
    this._personService.getCustomerPersonTravelDocumentByptdId<ResponseModel<PersonTravelDocumentModel>>(personTravelDocumentGUID,true).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let docObj = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
          let file = new Blob([byteArray], { type: docObj.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);

        }
        else {
          this.errMsg = "No Document returned.";
        }
      }
      else {
        this.errMsg = "No Document returned.";
      }
    })
  }

  get f() { return this.person_passport_form.controls; }

  includeDisabledRecordsOnChange(event: any) {
    this.includeDisabledRecords = event.target.checked;
    this.getSearchResults();
  }

  getSearchResults() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new PersonTravelDocumentModel();
    request.personTravelDocumentGUID = this.personTravelDocumentGUID;
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = !this.includeDisabledRecords;
    request.personGUID = this.personGUID;
    this._personService.getCustomerPersonTravelSubDocument<ResponseModel<PersonTravelDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.recordList = [];
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            //this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize);
          }

        }
        else {
          this.recordList = [];
          this.msg = "No records found.";
        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    })


  }

  //pageIndexChange(e: any) {
  //  //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
  //  this.currentPageIndex = e.pageIndex;
  //  this.getSearchResults();


  //}

  clickEditPassport() {
    let s = "Edit Record";
  
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = false;
  //  dialogConfig.width = "65em";//"1000px";
   // dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: this.personGUID,customerGUID: this.customerGUID, personTravelDocumentGUID: this.personTravelDocumentGUID };
    //dialogConfig.data = { dialogTitle: s };

    const dialogRef = this._dialog.open(PersonPassportEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
       this.isModified = true;
        this.getPersonPassportInfo();
      }
    });

  }

  clickEditVisa(e: any, doc: PersonTravelDocumentModel) {
    let s = "";
    let personTravelSubDocumentGUID = "";
    if (doc == null) {
      s = "New Record";
    }
    else {
      s = "Edit Record";
      personTravelSubDocumentGUID = doc.personTravelSubDocumentGUID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //dialogConfig.width = "65.5em";//"1000px";
    //dialogConfig.height = "21em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personTravelDocumentGUID: this.personTravelDocumentGUID,customerGUID: this.customerGUID, personTravelSubDocumentGUID: personTravelSubDocumentGUID, personGUID: this.personGUID };

    const dialogRef = this._dialog.open(PersonVisaEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isModified = result;
        this.getSearchResults();
      }
    });
  }

  clickPreviewSubDocument(e: any, doc: PersonTravelDocumentModel) {
    let personTravelSubDocumentGUID = doc.personTravelSubDocumentGUID;
    this._personService.getCustomerPersonTravelSubDocumentByptsdId<ResponseModel<PersonTravelDocumentModel>>(personTravelSubDocumentGUID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let docObj = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
          let file = new Blob([byteArray], { type: docObj.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);

        }
        else {
          this.errMsg = "No Document returned.";
        }
      }
      else {
        this.errMsg = "No Document returned.";
      }
    })
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

}
