<div *ngIf="upPII" style="margin-top:0em; padding-top:0em;">
  <form *ngIf="trip_code_search_form" [formGroup]="trip_code_search_form">
    <div class="divLeft">
      <div style="display: flex">
        <div style="margin-right:1em;display:flex"><mat-icon class="mr-1">search</mat-icon><h5>Search</h5></div>
      </div>
      <div style="padding-left:0.25em; padding-top:0em; padding-right:0.5em; margin-top:0em; ">
       
        <div style="margin-left: 0em;margin-top:0em;display: flex; font-size: small ">
          <div>
            <mat-radio-group class="form-control" (change)="updateFilter()" [(ngModel)]="filterOption" [ngModelOptions]="{standalone: true}" style="display: flex; flex-direction: row; transform: scale(.8); align-items: flex-start;border:none!important;background-color:transparent;margin-left:-2em;margin-top:-.5em">
              <mat-radio-button class="mr-3" [checked]="filterOption=='trips'" [value]="'trips'">Trips</mat-radio-button>
              <mat-radio-button class="mr-3" [checked]="filterOption=='aircraft'" [value]="'aircraft'">Aircraft</mat-radio-button>
              <mat-radio-button class="mr-3" [checked]="filterOption=='crewpax'" [value]="'crewpax'">Crew/Pax/Support</mat-radio-button>
              <mat-radio-button [checked]="filterOption=='users'" [value]="'users'">Users</mat-radio-button>

            </mat-radio-group>
          </div>          
          <div>
                <input ngbPopover="{{searchField}}" #p1="ngbPopover" triggers="focus" popoverClass="tooltipLineBreak" placement="bottom" popoverTitle="Search Options" type="text" formControlName="text_search" placeholder="Enter search field" class="form-control" style="width:20em;height:1.5em; padding:0.125em; font-size:small" [ngClass]="{ 'is-invalid': submitted && f.text_search.errors }" (keyup)="updateSearchText()" />
              
              <!--<div *ngIf="submitted && f.text_search.errors && selectedCustomerList.length == 0" style="margin-left:0.25em">
                <div *ngIf="f.text_search.errors.required && selectedCustomerList.length == 0" style="background-color:#f9d2de; padding-left:0.25em;margin-top:0em; font-size: small">*Required</div>
              </div>-->

            </div>
          
          <div style="margin-left:2em;margin-top:0em;margin-bottom:.5em; display: flex;justify-content:space-between;width:61em">
            <div class="d-flex">
              <div>
                <button mat-button class="btn-high" (click)="clickSearch(p1)">Search</button>&nbsp;
                <button type="button" mat-button class="btn-low" (click)="clickReset()">Reset</button>&nbsp;
              </div>
              <mat-divider [vertical]="true" class="mx-2" *ngIf="filterOption=='trips'"></mat-divider>
              <div *ngIf="filterOption=='trips'">

                <!--<label style="font-size:small">Filters</label>-->
                <mat-radio-group class="form-control" (change)="updateFlightType()" [(ngModel)]="flightType" [ngModelOptions]="{standalone: true}" style="display: flex; flex-direction: row; transform: scale(.8); align-items: flex-start;border:none!important;background-color:transparent;margin-top:-.5em">
                  <mat-radio-button class="mr-3" [checked]="filterOption==0" [value]="0">All</mat-radio-button>
                  <mat-radio-button class="mr-3" [checked]="filterOption==1" [value]="1">International</mat-radio-button>
                  <mat-radio-button [checked]="filterOption==2" [value]="2">Domestic</mat-radio-button>

                </mat-radio-group>
              </div>
              <div style="width:12em"><app-spin *ngIf="showSpin"></app-spin></div>
            </div>
            <div style="margin-left:0.5em">{{totalRecordsText}}</div>
            <div *ngIf="errMsg!=''" style="margin-left: 0.5em; margin-top: 0em; background-color: #f9d2de; padding-left: 0.25em; width: 21em; color: black; font-size: small;height:1.5em">{{errMsg}}</div>
          </div>
        </div>
        <div>
          <table mat-table *ngIf="recordList && filterOption=='trips'" [dataSource]="recordList" class="example-container">
            <tr>
              <ng-container matColumnDef="tripcode">
                <th mat-header-cell *matHeaderCellDef style="width:5%; text-align:left !important">
                  <div style="margin-top:-0.25em;">Trip Codes</div>
                </th>
                <td mat-cell *matCellDef="let element" style="white-space:nowrap; text-align:left !important" [style.background-color]="element.tripCodeType=='Consultation'? 'lightgreen': '' || element.tripCodeType=='Estimate'? 'lightblue':'' " (click)="clickOpen($event, element);">
                  <div style="font-weight: bold">{{element.tripCodeDesc}}</div>
                  <div style="display: flex">
                    <div style="font-size:x-small;margin-top:-0.25em;">{{element.isActive && element.tripCode!=''? 'Active': '' || element.tripCodeType!=''? element.tripCodeType: ''}}</div>
                    <div *ngIf="element.isNewTrip" style="margin-top:-0.25em; margin-left:0.25em"><i class="material-icons-two-tone icon_orange" title="New Trip" style="font-size: medium;">new_releases</i></div>
                  </div>
                  <div style="font-size:x-small;margin-top:-0.25em;color: darkgreen">{{element.tripCloseOutID>1 && element.tripCodeType==''? element.tripCloseOutDescription:''}}</div>
                  <div style="font-size:x-small;margin-top:-0.25em;color: darkgreen">{{element.tripCloseOutID>1 && element.tripCodeType==''? 'Date '+ element.tripCloseOutDate:''}}</div>
                  <div style="font-size:x-small;margin-top:-0.25em;color: darkgreen">{{element.tripCloseOutID>1 && element.tripCodeType==''? element.tripCloseOutSubmittedBy:''}}</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="registration">
                <th mat-header-cell *matHeaderCellDef style="width:15%; text-align:left !important">
                  <div style="margin-top:-0.25em;">Registration</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;" (click)="clickOpen($event, element);">
                  <div style="font-weight:bold">{{element.registration}}</div>
                  <div style="font-size:x-small;margin-top:-0.25em; white-space:nowrap">{{element.aircraftManufacturer + " "+element.aircraftModel}}</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="clientName">
                <th mat-header-cell *matHeaderCellDef style="width:20%; text-align:left !important">
                  <div style="margin-top:-0.25em;">Client Account</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important" (click)="clickOpen($event, element);">
                  <div style="font-weight:bold">{{element.customerName}}</div>
                  <div style="font-size:x-small;margin-top:-0.25em; white-space:nowrap">{{element.customerCreditStatus}}</div>
                </td>
              </ng-container>
              <!--<ng-container matColumnDef="crew">
        <th mat-header-cell *matHeaderCellDef style="width:10%;">
          <div style="margin-top:-0.25em;">Crew</div>
        </th>
        <td mat-cell *matCellDef="let element">{{element.crewCount>0? element.crewCount.toString():''}}</td>
      </ng-container>
      <ng-container matColumnDef="pax">
        <th mat-header-cell *matHeaderCellDef style="width:10%;">
          <div style="margin-top:-0.25em;">Passengers</div>
        </th>
        <td mat-cell *matCellDef="let element">{{element.paxCount>0? element.paxCount.toString():''}}</td>
      </ng-container>-->
              <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef style="width:10%">
                  <div style="margin-top:-0.25em;">Trip Creation<span style="display:block">Date / By</span></div>
                </th>
                <td mat-cell *matCellDef="let element" (click)="clickOpen($event, element);"><div>{{element.createdDate}}</div><span style="display:block">{{element.createdBy}}</span></td>
              </ng-container>
              <ng-container matColumnDef="dateRange">
                <th mat-header-cell *matHeaderCellDef style="width:12%;">
                  <div style="margin-top:-0.25em;">Date Range</div>
                  <div style="margin-top:-0.25em;font-size:x-small; white-space:nowrap">(First Departure - Last Arrival)</div>
                </th>
                <td mat-cell *matCellDef="let element" style="font-weight:bold; text-align: left !important;"><div style="margin-left:2em" (click)="clickOpen($event, element);">{{element.dateRange}}</div></td>
              </ng-container>
              <ng-container matColumnDef="route">
                <th mat-header-cell *matHeaderCellDef style="width:20%; text-align:left !important">
                  <div style="margin-top:-0.25em;">Route</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important" (click)="clickOpen($event, element);">
                  <div *ngFor="let gs of element.groundStopList; let i=index">
                    <div *ngIf="i % 9==0" style="display: flex">
                      <div style="display: flex">
                        <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==element.groundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 1 < element.groundStopList.length" style="display: flex">
                        <div [style.background-color]="element.groundStopList[i+1].groundStopTypeColor">{{element.groundStopList[i+1].icao}}</div><div>{{(i+1)==element.groundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 2 < element.groundStopList.length" style="display: flex">
                        <div [style.background-color]="element.groundStopList[i+2].groundStopTypeColor">{{element.groundStopList[i+2].icao}}</div><div>{{(i+2)==element.groundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 3 < element.groundStopList.length" style="display: flex">
                        <div [style.background-color]="element.groundStopList[i+3].groundStopTypeColor">{{element.groundStopList[i+3].icao}}</div><div>{{(i+3)==element.groundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 4 < element.groundStopList.length" style="display: flex">
                        <div [style.background-color]="element.groundStopList[i+4].groundStopTypeColor">{{element.groundStopList[i+4].icao}}</div><div>{{(i+4)==element.groundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 5 < element.groundStopList.length" style="display: flex">
                        <div [style.background-color]="element.groundStopList[i+5].groundStopTypeColor">{{element.groundStopList[i+5].icao}}</div><div>{{(i+5)==element.groundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 6 < element.groundStopList.length" style="display: flex">
                        <div [style.background-color]="element.groundStopList[i+6].groundStopTypeColor">{{element.groundStopList[i+6].icao}}</div><div>{{(i+6)==element.groundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 7 < element.groundStopList.length" style="display: flex">
                        <div [style.background-color]="element.groundStopList[i+7].groundStopTypeColor">{{element.groundStopList[i+7].icao}}</div><div>{{(i+7)==element.groundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 8 < element.groundStopList.length" style="display: flex">
                        <div [style.background-color]="element.groundStopList[i+8].groundStopTypeColor">{{element.groundStopList[i+8].icao}}</div><div>{{(i+8)==element.groundStopList.length-1? '': '-'}}</div>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="clientInstruction">
                <th mat-header-cell *matHeaderCellDef style="width:10%;text-align: left !important">
                  <div style="margin-top:-0.25em;">Trip Notes<!--Client<br />Instructions--></div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important">
                  <!--<div style="cursor:pointer; white-space:pre-wrap" [ngbTooltip]="tipContent" tooltipClass="ngb-tooltip-instructions">{{ element.clientInstructions.substring(0,19) }}</div>
          <ng-template #tipContent>
            <div [innerHTML]="element.clientInstructions"></div>
          </ng-template>-->
                  <button *ngIf="element.clientInstructions!=''" mat-button class="btn-low" (click)="viewNotes(element)">Notes</button>
                </td>
              </ng-container>
              <ng-container matColumnDef="clientRef">
                <th mat-header-cell *matHeaderCellDef style="width:8%;text-align: left !important">
                  <div style="margin-top:-0.25em;">Client Trip#</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important" (click)="clickOpen($event, element);">
                  {{element.customerTripID}}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width:5%">
                  <!--<div>Action</div><div style="margin-top:0em;padding-bottom:0.25em"><a mat-button class="btn-high" style="align-self:center" (click)="clickAdd($event, null);">Add</a></div>-->
                </th>
                <td mat-cell *matCellDef="let element">
                  <a mat-button class="btn-low" style="align-self:center" (click)="clickOpen($event, element);">
                    Open
                  </a>
                </td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumnTrip; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnTrip"></tr>
          </table>

          <table mat-table *ngIf="recordList && filterOption=='crewpax'" [dataSource]="recordList" class="example-container">
            <tr>
              <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef style="width:18%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Client Name</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef style="width:18%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Name</div>
                </th>
                <td mat-cell *matCellDef="let element"><div innerHTML="{{element.lastName + ', ' + element.firstName + ' ' + element.middleName | highlight : searchText}}"></div></td>
              </ng-container>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef style="width:17%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Title</div>
                </th>
                <td mat-cell *matCellDef="let element"><div innerHTML="{{element.jobTitle | highlight : searchText}}"></div></td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef style="width:17%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Role</div>
                </th>
                <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.personRoleList}}</td>
              </ng-container>              
              <ng-container matColumnDef="comms">
                <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Comms</div>
                </th>
                <td mat-cell *matCellDef="let element"><div style="white-space:pre-wrap" innerHTML="{{element.personCommAddressList | highlight : searchText}}"></div></td>
              </ng-container>


              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Action</div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a mat-button class="btn-low grid" style="align-self:center">
                    Edit
                  </a>

                </td>
              </ng-container>
            </tr>

            <tr mat-header-row *matHeaderRowDef="displayedColumnCrewPax; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnCrewPax" (click)="clickEditPerson(row);" style="cursor: pointer"></tr>
          </table>
          <table mat-table [dataSource]="recordList" *ngIf="filterOption=='aircraft'" class="example-container">
            <tr>
              <ng-container matColumnDef="registration">
                <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Registration</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.registration}}</td>
              </ng-container>
              <ng-container matColumnDef="aircraft_type">
                <th mat-header-cell *matHeaderCellDef style="width:15%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Aircraft Type</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.aircraftTypeDescription}}</td>
              </ng-container>
              <ng-container matColumnDef="client_name">
                <th mat-header-cell *matHeaderCellDef
                    style="width:10%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Client Name</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
              </ng-container>

              <ng-container matColumnDef="mtow">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:0em;">MTOW</div>
                  <div>Lbs</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.mtow | number}}</td>
              </ng-container>
              <ng-container matColumnDef="height">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:0em;">Height</div>
                  <div>Ft</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.height}}</td>
              </ng-container>
              <ng-container matColumnDef="wingspan">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:0em;">Wingspan</div>
                  <div>Ft</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.wingspan}}</td>
              </ng-container>
              <ng-container matColumnDef="homebase">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Homebase</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.homebaseAirport}}</td>
              </ng-container>
              <ng-container matColumnDef="has_preferences">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Preference Load</div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasPreferences" class="material-icons"
                     style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_notes">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Notes</div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasNotes" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_documents">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Documents</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.documentCount==0? '': element.documentCount}}</td>
              </ng-container>
              <ng-container matColumnDef="has_owner_lessee">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:0em;">
                    Aircraft Owner
                  </div>
                  <div>Lessee Info</div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasOwner" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_operator">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:0em;">Aircraft Operator</div>
                  <div>Info</div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasOperator" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_trips">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div style="margin-top:0em;">Active</div>
                  <div>Trips</div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasTrips" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="remarks">
        <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
          <div style="margin-top:-0.25em;">Remarks</div>
        </th>
        <td mat-cell *matCellDef="let element">{{element.remarks}}</td>
      </ng-container>-->
              <ng-container matColumnDef="disabled">
                dd
                <th [hidden]="userType!='internal'" mat-header-cell *matHeaderCellDef
                    style="width:5%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Disabled</div>
                </th>
                <td [hidden]="userType!='internal'" mat-cell *matCellDef="let element">
                  {{
element.isActive? '&nbsp;':
        'Yes'
                  }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div>Action</div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a mat-button class="btn-low grid" style="align-self:center">
                    Edit
                  </a>
                </td>
              </ng-container>
            </tr>

            <tr mat-header-row *matHeaderRowDef="displayedColumnAircraft; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnAircraft" (click)="clickEditAircraft(row);"></tr>
          </table>
          <table mat-table *ngIf="recordList && filterOption=='users'" [dataSource]="recordList" class="example-container">

            <tr>
              <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                  Client Name
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left; padding-left: 0.125em;">{{element.customerName}}</td>
              </ng-container>
              <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                  Username
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left; padding-left: 0.125em;" ><div innerHTML="{{element.username | highlight : searchText }}"></div></td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                  Name
                </th>
                <td mat-cell *matCellDef="let element" style="white-space: nowrap; text-align: left;"><div innerHTML="{{element.lastName}}, {{element.firstName | highlight : searchText }}"></div></td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                  Email
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left "><div innerHTML="{{element.email | highlight : searchText }}"></div></td>
              </ng-container>
              <ng-container matColumnDef="account_status">
                <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                  Account Status
                </th>
                <td mat-cell *matCellDef="let element">{{element.isActive? 'Active': 'Not Active'}}</td>
              </ng-container>
              <ng-container matColumnDef="admin_access">
                <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                  Account Access
                </th>
                <td mat-cell *matCellDef="let element">{{element.isAdmin? 'Admin': 'Standard'}}</td>
              </ng-container>
              <ng-container matColumnDef="effectiveDate">
                <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                  Effective Date
                </th>
                <td mat-cell *matCellDef="let element">{{element.effectiveDate}}</td>
              </ng-container>
              <ng-container matColumnDef="expirationDate">
                <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">
                  Expiration Date
                </th>
                <td mat-cell *matCellDef="let element">{{element.expirationDate}}</td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important;">Action</th>
                <td mat-cell *matCellDef="let element; let i=index ">
                  <a mat-button class="btn-high grid">
                    Edit
                  </a>
                </td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumnUser; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnUser"></tr>
          </table>
        </div>

      </div>
    </div>
  </form>
</div>
