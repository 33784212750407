import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { CustomerService } from '../services/customer.service';
import { AircraftModel } from '../models/aircraft.model';
import { CustomerModel } from '../models/customer.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { DistanceTimeModel } from '../models/distance-time.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { TripModel } from '../models/trip.model';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { FARTypeModel } from '../models/far-type.model';

@Component({
  selector: 'app-ground-stop-editor-dialog',
  templateUrl: './ground-stop-editor-dialog.component.html',
  styleUrls: ['./ground-stop-editor-dialog.component.css']
})

export class GroundStopEditorDialogComponent implements OnInit, AfterViewInit {
  //@Input() dialogTitle: string;
  //@Input() customerGUID: string;

  dialogTitle: string;
  customerGUID: string;
  aircraftGUID: string;
  tripCodeGUID: string;
  currentIndex: number;
  ground_stop_editor_form: UntypedFormGroup;
  loading = false;

  submitted = false;
  errMsg: string;
  errMsg2: string;
  duplicateIATAErrMsg: string;
  msg: string;

  showSpin: boolean = true;
  userType: string;
  client_name: string;
  registration: string;
  trip_code: string;
  tripCodeType: string;
  trip_status: string;
  speed: number;
  route: string;
  isModified: boolean = false;
  loadingAirport: boolean = false;
  existingGroundStopList: GroundStopModel[];
  groundStopList: GroundStopModel[];
  airportList: AirportModel[];
  gsTypeList: GroundStopTypeModel[];
  validatedAirport: boolean = false;
  notCancelledGroundStopList: GroundStopModel[];
  showSuccessMsg: boolean = false;
  
  //displayedColumn: string[] = ['airport', 'gsType', 'arrivalDate_Time', 'departureDate_Time', 'services', 'adjustSequence'];
  displayedColumn: string[] = ['airport', 'gsType', 'farType', 'callSign', 'techStop', 'arrivalDate_Time', 'departureDate_Time', 'services', 'adjustSequence'];

  @ViewChild('routeRef', {static: false}) routeRef: ElementRef;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  farTypeList: FARTypeModel[];
  lockdownTrip: boolean = false;
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  upPII: boolean = false;
  errMsgFAR: string = "";
  constructor(private readonly _dialogRef: MatDialogRef<GroundStopEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _customerService: CustomerService,
    private readonly _airportService: AirportService,
    private readonly _groundStopService: GroundStopService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    private readonly _dialogService: DialogService
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }
    
    if (_data.tripCodeGUID == null || _data.tripCodeGUID == undefined) {
      this.tripCodeGUID = "";
    }
    else {
      this.tripCodeGUID = _data.tripCodeGUID;
    }
    this.dialogTitle = _data.dialogTitle;
    this.currentIndex = _data.currentIndex;

  }

  getAllData(): Observable<any[]> {
    let request = new TripModel();
    request.customerGUID = this.customerGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    //let getTripResponse = this._groundStopService.getTripBytcId<ResponseModel<TripModel>>(request)
    let getTripResponse = this._groundStopService.getTripGroundStopBytcId<ResponseModel<TripModel>>(request)
    let getGroundStopTypeList = this._groundStopService.getGroundStopTypeList();
    let getFARListResponse = this._groundStopService.getFARTypeList();
    return forkJoin([getTripResponse, getGroundStopTypeList, getFARListResponse]);   

  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.errMsg2 = "";
    this.duplicateIATAErrMsg = "";
    this.msg = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.route = "";
    
    this.client_name = "";
    this.registration = "";
    this.trip_code = "";
    this.trip_status = "";
    this.tripCodeType = "";
    this.aircraftGUID = "";
    this.existingGroundStopList = [];
    this.groundStopList = [];
    this.airportList = [];
    this.validatedAirport = false;
    this.gsTypeList = [];
    this.notCancelledGroundStopList = [];
    this.showSuccessMsg = false;
    this.farTypeList = [];
    this.lockdownTrip = false;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
   
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].model != null) {
          let trip = new TripModel();
          trip = responses[0].model;
          this.client_name = trip.customerName;
          this.registration = trip.registration;
          this.trip_code = trip.tripCode;
          this.tripCodeType = trip.tripCodeType;
          this.trip_status = trip.tripCloseOutDescription;
          this.aircraftGUID = trip.aircraftGUID;
          this.lockdownTrip = trip.lockdownTrip;
          this.speed = 460;
          if (trip.speed > 0) {
            this.speed = trip.speed;
          }
          trip.groundStopList.forEach(x => {
            x.isValidArrDate = true;
            x.isValidArrTime = true;
            x.isValidDeptDate = true;
            x.isValidDeptTime = true;
            x.fARTypeID = x.farTypeID;
            this.existingGroundStopList.push(x);
          });
          this.groundStopList = trip.groundStopList;
          this.groundStopList.forEach(x => {
            x.isValidArrDate = true;
            x.isValidArrTime = true;
            x.isValidDeptDate = true;
            x.isValidDeptTime = true;
            x.serviceClassTaskStatusList.forEach(y => {
              if (x.groundStopTypeDescription == "Consultation" || x.groundStopTypeDescription == "Estimates") {
                y.taskStatusBackgroundColor = "white";
              }
              
            }
            );
          });

          this.setNotCancelledGroundStopList();

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.gsTypeList = responses[1].model;
          
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.farTypeList = responses[2].model;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;

    });
    

  }

  initControls() {
    if (this.customerGUID != "") {
      this.customerGUID = this.customerGUID.toLowerCase();
    }
    this.ground_stop_editor_form = this._formBuilder.group({
      //client_name: [this.client_name],
      //registration: [this.registration],
      //trip_code: [this.trip_code],
      //trip_status: [this.trip_status],
      route: [this.route, Validators.compose([
        //Validators.required,
        Validators.pattern("^[-a-zA-Z0-9,\* \n\r]*$")]
      )]
      
    });

    switch (this.tripCodeType) {
      case "Estimate":
        this.gsTypeList = this.gsTypeList.filter(x => x.groundStopTypeDescription.indexOf('Estimate') != -1 || x.groundStopTypeDescription == "Cancellation")
        break;
      case "Consultation":
        this.gsTypeList = this.gsTypeList.filter(x => x.groundStopTypeDescription.indexOf('Consultation') != -1 || x.groundStopTypeDescription == "Cancellation")
        break;
      default:
        this.gsTypeList = this.gsTypeList.filter(x => x.groundStopTypeDescription != "Estimates" && x.groundStopTypeDescription != "Consultation")
        break;
    }


  }

  ngAfterViewInit() {
    //this.usernameRef.nativeElement.focus();
    setTimeout(() => {
      this.routeRef.nativeElement.focus();
    }, 500);
  }

  get f() { return this.ground_stop_editor_form.controls; }

  setNotCancelledGroundStopList() {  
    this.notCancelledGroundStopList = this.groundStopList.filter(x => x.groundStopTypeDescription != 'Cancellation');
  }

  

  gsTypeSelectOnChange(e: any, itemGS: GroundStopModel) {
    let itemGroundStopTypeGUID = e.source.value;
    let itemGroundStopTypeDescription = this.gsTypeList.filter(v => v.groundStopTypeGUID.toLowerCase() == itemGroundStopTypeGUID.toLowerCase())[0].groundStopTypeDescription;
    let itemGroundStopTypeColor = this.gsTypeList.filter(v => v.groundStopTypeGUID.toLowerCase() == itemGroundStopTypeGUID.toLowerCase())[0].colorCode;

    var allServiceCancelled = null;
    if (itemGS.serviceClassTaskStatusList != null && itemGS.serviceClassTaskStatusList !=undefined && itemGS.serviceClassTaskStatusList.length>0) {
      allServiceCancelled = itemGS.serviceClassTaskStatusList.find(x => x.cancelled == false);
    }  
    //if (itemGroundStopTypeDescription == 'Cancellation') {
    if (itemGroundStopTypeGUID.toLowerCase()== this.cancellationGroundStopTypeGUID) { 
      if (itemGS.serviceClassTaskStatusList.length > 0 && (allServiceCancelled != undefined && allServiceCancelled != null)) {
        this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to cancell the stop and all related services?");
        this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
          this.eventSubscription.unsubscribe();
          if (result) {
            itemGS.groundStopTypeGUID = itemGroundStopTypeGUID;
            itemGS.groundStopTypeDescription = itemGroundStopTypeDescription;
            itemGS.groundStopTypeColor = itemGroundStopTypeColor;
            itemGS.cancelled = true;
            itemGS.cancelAllServices = true;
            itemGS.serviceClassTaskStatusList.forEach(x => {
              x.cancelled = true;
              //x.taskStatusBackgroundColor = this.gsTypeList.find(x => x.groundStopTypeDescription == "Cancellation").colorCode;
              x.taskStatusBackgroundColor = this.gsTypeList.find(x => x.groundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID).colorCode;
            });
            this.recalculateArrivalTimeBySkipCanncelledStop(itemGS.groundStopSequenceID);
            
          }
          else {
            e.source.value = this.gsTypeList.filter(v => v.groundStopTypeDescription == itemGS.groundStopTypeDescription)[0].groundStopTypeGUID;
            itemGS.cancelled = false;
            itemGS.cancelAllServices = false;
            
          }
        });
      }
      else {
        itemGS.cancelled = true;
        itemGS.cancelAllServices = false;
        itemGS.groundStopTypeGUID = itemGroundStopTypeGUID;
        itemGS.groundStopTypeDescription = itemGroundStopTypeDescription;
        itemGS.groundStopTypeColor = itemGroundStopTypeColor;
        this.recalculateArrivalTimeBySkipCanncelledStop(itemGS.groundStopSequenceID);
      }


    }
    else {
      let currentGSType = itemGroundStopTypeDescription;
      let originalGSType = itemGS.groundStopTypeDescription;
     
      itemGS.groundStopTypeGUID = itemGroundStopTypeGUID;
      itemGS.groundStopTypeDescription = itemGroundStopTypeDescription;
      itemGS.groundStopTypeColor = itemGroundStopTypeColor;
      itemGS.cancelled = false;
  
      if (itemGS.serviceClassTaskStatusList.length > 0) {
        this._groundStopService.getServiceClassTaskStatusBygsId<ResponseModel<GroundStopTaskModel[]>>(itemGS).subscribe(response => {
          if (response != null && response.code == "200") {
            itemGS.serviceClassTaskStatusList = response.model;
            if (itemGS.groundStopTypeDescription == "Consultation" || itemGS.groundStopTypeDescription == "Estimates") {
              if (itemGS.serviceClassTaskStatusList.length > 0) {
                itemGS.serviceClassTaskStatusList.forEach(x => {
                  if (x.cancelled == false) {
                    x.taskStatusBackgroundColor = "white";
                  }
                });
              }
            }
            else {
              itemGS.serviceClassTaskStatusList.forEach(x => {
                if (x.cancelled == false) {
                  if (x.isNotCompleted == false) {
                    x.taskStatusBackgroundColor = "lightgreen";
                  }
                  else {
                    x.taskStatusBackgroundColor = '#ed6058';//"lightpink";
                  }
                }
                else {
                  //x.taskStatusBackgroundColor = this.gsTypeList.find(x => x.groundStopTypeDescription == "Cancellation").colorCode;
                  x.taskStatusBackgroundColor = this.gsTypeList.find(x => x.groundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID).colorCode;
                }
              });
            }

            this.setNotCancelledGroundStopList();
            //if (originalGSType == "Cancellation" && currentGSType != "Cancellation") {
            if (itemGS.oldGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID && itemGS.groundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID) {
              this.notCancelledGroundStopList.forEach((x, index) => {
                if (x.groundStopSequenceID == itemGS.groundStopSequenceID) {
                  if ((index + 1) < this.notCancelledGroundStopList.length) {
                    if (currentGSType != "Contingency" || this.notCancelledGroundStopList[index + 1].groundStopTypeDescription != "Contingency") {
                      this.notCancelledGroundStopList[index + 1].arrivalTimeUTC = "";
                      this.notCancelledGroundStopList[index + 1].arrivalDateTextUTC = "";
                      this.calculateArrivalDateTime(itemGS.groundStopSequenceID);
                    }
                  }
                }
              });
             
            }
          }

        })
      }
      else {
        this.setNotCancelledGroundStopList();
      }

    }  
    
  }

  recalculateArrivalTimeBySkipCanncelledStop(gsSequenceID: number) {
    let index1: number = -1;
    let index2: number = -1;
    let prevGroundStopSequenceID: number = 0;
    this.notCancelledGroundStopList.forEach((x, index) => {
      if (x.groundStopSequenceID == gsSequenceID) {
        if (index > 0) {
          index1 = index - 1;
        }
        if ((index + 1) < this.notCancelledGroundStopList.length) {
          index2 = index + 1;
        }

      }
    });

    if (index1 >= 0 && index2 > 0 ) {    
      prevGroundStopSequenceID = this.notCancelledGroundStopList[index1].groundStopSequenceID;
      this.notCancelledGroundStopList[index2].arrivalDateUTC = null;
      this.notCancelledGroundStopList[index2].arrivalDateTextUTC = "";
      this.notCancelledGroundStopList[index2].arrivalTimeUTC = "";
    }
    this.setNotCancelledGroundStopList();

    if (prevGroundStopSequenceID > 0 && (this.notCancelledGroundStopList[index1].groundStopTypeDescription != "Contingency" || this.notCancelledGroundStopList[index2].groundStopTypeDescription != "Contingency")) {
      //this.notCancelledGroundStopList[index2].arrivalDateUTC = null;
      //this.notCancelledGroundStopList[index2].arrivalDateTextUTC = "";
      //this.notCancelledGroundStopList[index2].arrivalTimeUTC = "";
      this.calculateArrivalDateTime(prevGroundStopSequenceID);
    }

  }

  clickValidateAirport() {
    this.errMsg = "";
    this.duplicateIATAErrMsg = "";
    this.loadingAirport = true;
    this.validatedAirport = true;

    if (this.f.route.errors || this.f.route.value == "") {
      if (this.f.route.value == "") {
        this.f.route.setErrors({required: true});
      }
      this.loadingAirport = false;
      return;
    }

    let request = new AirportModel();

    request.route = this.f.route.value;
    this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
   
        var data : GroundStopModel[];
        data = [];
        this.existingGroundStopList.forEach(x => {
          data.push(x);
        });
        var startAt = null;
        if (data.length > 0) {
          if (data[data.length - 1].arrivalDateUTC != null) {
            startAt = data[data.length - 1].arrivalDateUTC;            
          }
        }   
        //this.groundStopList = [];   
        this.airportList = response.model;
        if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
          this.groundStopList = [];   
          let re = /,/gi;
          this.f.route.setValue(Array.prototype.map.call(this.airportList, s => s.icao).toString().replace(re, "-"));
          let i = data.length;

          this.airportList.forEach(x => {
            let obj = new GroundStopModel();
            obj.groundStopGUID = "";
            obj.icao = x.icao;
            obj.iata = x.iata;
            obj.latitude = null;
            obj.longitude = null;
            if (x.latitude != null && x.longitude != null) {
              obj.latitude = x.latitude;
              obj.longitude = x.longitude;
            }
            obj.arrivalTimeUTC = "";
            obj.departureTimeUTC = "";
            obj.icaO_IATA = x.icao;
            if (x.iata != "") {
              obj.icaO_IATA += " / " + x.iata;
            }
            obj.departureDateUTC = null;
            obj.arrivalDateUTC = null;
            obj.arrivalDateTextUTC = "";
            obj.departureDateTextUTC = "";
            obj.isValidArrDate = true;
            obj.isValidArrTime = true;
            obj.isValidDeptDate = true;
            obj.isValidDeptTime = true;
            obj.arrStartAt = startAt;
            obj.depStartAt = startAt;
            obj.depReadOnly = false;
            obj.arrReadOnly = false;
            obj.moveUp = true;
            obj.moveDown = true;
            i = i + 1;
            obj.groundStopSequenceID = i;
            obj.ete = "";
            let gstobj = new GroundStopTypeModel();
            switch (this.tripCodeType) {
              case "Estimate":
                gstobj = this.gsTypeList.filter(x => x.groundStopTypeGUID.toLowerCase() == 'aee7e455-78c9-40a5-868f-efd8c2121608')[0];
                break;
              case "Consultation":
                gstobj = this.gsTypeList.filter(x => x.groundStopTypeGUID.toLowerCase() == 'f7791c29-2cd9-40d8-8f6a-9d4a7c93ee66')[0];
                break;
              default:
                gstobj = this.gsTypeList.filter(x => x.groundStopTypeGUID.toLowerCase() == '868a62b1-9cd6-499b-abfa-46542ccb7424')[0];
                break;
            }
            //gstobj= this.gsTypeList.filter(x => x.groundStopTypeDescription == 'Active')[0];
            obj.groundStopTypeGUID = gstobj.groundStopTypeGUID;
            obj.groundStopTypeDescription = gstobj.groundStopTypeDescription;
            obj.groundStopTypeColor = gstobj.colorCode;
            obj.cancelled = false;
            obj.fARTypeID = 0;
            obj.farTypeID = 0;
            data.push(obj);
          });

          //data.forEach((x, index) => {
          //  if (index < (data.length - 1)) {
          //    if (i == 0) {
          //      x.arrReadOnly = true;
          //    }
          //    if (data[index + 1].moveUp == true) {
          //      x.moveDown = true;
          //    }
          //  }
          //  if (index == data.length - 1) {
          //    x.moveDown = false;
          //    x.depReadOnly = true;
          //  }
          //});

          this.setGroundStopList(data);
          data.forEach(x => {
            this.groundStopList.push(x);
          });
          
          this.setNotCancelledGroundStopList();
          
          this.validatedAirport = false;
        }
        if (this.airportList[0].wrongAirportErrMsg != "") {
          this.errMsg = "Invalid airports: " + this.airportList[0].wrongAirportErrMsg;
          this.validatedAirport = true;
        }
        if (this.airportList[0].duplicateIATAErrMsg != "") {
          this.duplicateIATAErrMsg = "Duplicated IATAs: " + this.airportList[0].duplicateIATAErrMsg + "\n";
          this.duplicateIATAErrMsg += "Please change to use ICAO.";
          this.validatedAirport = true;
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to validate airport. Please try again later.";
          this.loadingAirport = false;
        }
      }
      this.loadingAirport = false;
    })

  }

  dateInputOnchange(dateType: string, e: any, item: GroundStopModel) {
    if (dateType == "A") {
      item.arrivalDateUTC = null;
    }
    if (dateType == "D") {
      item.departureDateUTC = null;
    }
  }

  dateOnchange(dateType: string, e: any, item: GroundStopModel) {
    this.errMsg2 = "";
    let gsIndex: number = -1;
    gsIndex = this.notCancelledGroundStopList.findIndex(x => x.groundStopGUID == item.groundStopGUID);
    if (dateType == "A") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          item.isValidArrDate = false;
          item.arrivalDateTextUTC = e.target._elementRef.nativeElement.value;
        }
        else {
          item.isValidArrDate = true;
          item.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(e.target.value);
          item.arrStartAt = new Date(item.arrivalDateTextUTC);
          item.depStartAt = new Date(item.arrivalDateTextUTC);
          this.calculateDepartureDateTime(item.groundStopSequenceID);
          this.groundStopList.forEach(x => {
            if (x.groundStopSequenceID > item.groundStopSequenceID) {
              x.arrStartAt = item.depStartAt;
              x.depStartAt = item.depStartAt;
           
            }
          });
        }
      }
      else {
        item.isValidArrDate = true;
        item.arrivalDateTextUTC = "";
        item.arrivalDateUTC = null;
        if (gsIndex > 0) {
          let obj1 = this.notCancelledGroundStopList[gsIndex - 1];
          obj1.ete = "";
        }
      }
    }

    if (dateType == "D") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          item.isValidDeptDate = false;
          item.departureDateTextUTC = e.target._elementRef.nativeElement.value;
        }
        else {
          item.isValidDeptDate = true;
          item.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(e.target.value);
          this.calculateArrivalDateTime(item.groundStopSequenceID);
          item.depStartAt = new Date(item.departureDateTextUTC);
          this.groundStopList.forEach(x => {
            if (x.groundStopSequenceID > item.groundStopSequenceID) {
              x.arrStartAt = item.depStartAt;
              x.depStartAt = item.depStartAt;
              
            }
          });
        }
      }
      else {
        item.isValidDeptDate = true;
        item.departureDateTextUTC = "";
        item.departureDateUTC = null;
        item.ete = "";
        if ((gsIndex + 1) < this.notCancelledGroundStopList.length) {
          let obj2 = this.notCancelledGroundStopList[gsIndex + 1];
          if (obj2.arrivalDateTextUTC != "" && obj2.arrivalTimeUTC != "") {
            //this.confirmDialogRef = this._dialogService.openConfirmDialog("You updated the Departure Date / Time it might impact next Arrival Date time. Do you want to update next arrival date / time?");
            //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            //  this.eventSubscription.unsubscribe();
            //  if (result) {
            //    obj2.arrivalDateTextUTC = "";
            //    obj2.arrivalDateUTC = null;
            //    obj2.arrivalTimeUTC = "";
            //  }
            //});
              obj2.arrivalDateTextUTC = "";
              obj2.arrivalDateUTC = null;
              obj2.arrivalTimeUTC = "";

          }
        }
      }
    }

  }

  timeOnchange(dateType: string, e: any, item: GroundStopModel) {
    this.errMsg2 = "";
    let gsIndex: number = -1;
    gsIndex = this.notCancelledGroundStopList.findIndex(x => x.groundStopGUID == item.groundStopGUID);
    if (dateType == "A") {
      if (e.target.value != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        item.arrivalTimeUTC = timeObj.timeString;
        item.isValidArrTime = timeObj.isValidDateTime;
        if (item.isValidArrTime)
          this.calculateDepartureDateTime(item.groundStopSequenceID);
      }
      else {
        item.arrivalTimeUTC = "";
        item.isValidArrTime = true;
        if (gsIndex > 0) {
          let obj1 = this.notCancelledGroundStopList[gsIndex - 1];
          obj1.ete = "";
        }
      }
    }
    if (dateType == "D") {
      if (e.target.value != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        item.departureTimeUTC = timeObj.timeString;
        item.isValidDeptTime = timeObj.isValidDateTime;
        if (item.isValidDeptTime)
          this.calculateArrivalDateTime(item.groundStopSequenceID);
      }
      else {
        item.departureTimeUTC = "";
        item.isValidDeptTime = true;
        item.ete = "";
        if ((gsIndex + 1) < this.notCancelledGroundStopList.length) {
          let obj2 = this.notCancelledGroundStopList[gsIndex + 1];
          if (obj2.arrivalDateTextUTC != "" && obj2.arrivalTimeUTC != "") {
            //this.confirmDialogRef = this._dialogService.openConfirmDialog("You updated the Departure Date / Time it might impact next Arrival Date time. Do you want to update next arrival date / time?");
            //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            //  this.eventSubscription.unsubscribe();
            //  if (result) {
            //    obj2.arrivalDateTextUTC = "";
            //    obj2.arrivalDateUTC = null;
            //    obj2.arrivalTimeUTC = "";
            //  }
            //});
            obj2.arrivalDateTextUTC = "";
            obj2.arrivalDateUTC = null;
            obj2.arrivalTimeUTC = "";

          }
        }
      }
    }

  }

  calculateArrivalDateTime(sequestId: number) {
    let obj1 = new GroundStopModel();
    
    let gsIndex: number =-1;
    this.notCancelledGroundStopList.forEach((x, index) => {
      if (x.groundStopSequenceID == sequestId) {
        gsIndex = index;
      }
    });

    if (gsIndex != -1) {
      obj1 = this.notCancelledGroundStopList[gsIndex];
      if ((gsIndex + 1) < this.notCancelledGroundStopList.length) {
        let obj2 = this.notCancelledGroundStopList[gsIndex + 1];
        
        if (obj1.departureDateTextUTC != "" && obj1.departureTimeUTC != "" && obj2.arrivalDateTextUTC != "" && obj2.arrivalTimeUTC != "") {       
          //this.confirmDialogRef = this._dialogService.openConfirmDialog("You updated the Departure Date / Time it might impact next Arrival Date time. Do you want to update next arrival date / time?");
          //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
          //  this.eventSubscription.unsubscribe();
          //  if (result) {
          //    obj2.arrivalDateTextUTC = "";
          //    obj2.arrivalTimeUTC = "";
          //    this.getArrivalDateTime(obj1, obj2, gsIndex);
          //  }
          //});
            obj2.arrivalDateTextUTC = "";
            obj2.arrivalTimeUTC = "";
            this.getArrivalDateTime(obj1, obj2, gsIndex);

        }
        else {
          this.getArrivalDateTime(obj1, obj2, gsIndex);
        }
       
      }
    }
  }

  getArrivalDateTime(obj1: GroundStopModel, obj2: GroundStopModel, gsIndex: number) {
    if (obj1.departureDateTextUTC != "" && obj1.departureTimeUTC != "" && (obj2.arrivalDateTextUTC == "" || obj2.arrivalTimeUTC == "")
      && (obj1.groundStopTypeDescription != "Contingency" || obj2.groundStopTypeDescription != "Contingency")) {
      if (obj1.isValidDeptDate && obj1.isValidDeptTime) {
        if (obj1.latitude != null && obj1.longitude != null && obj2.latitude != null && obj2.longitude != null) {
          let request = new DistanceTimeModel();
          request.departureLatitude = obj1.latitude;
          request.departureLongitude = obj1.longitude;
          request.arrivalLatitude = obj2.latitude;
          request.arrivalLongitude = obj2.longitude;
          request.airSpeed = this.speed; //this.selectedSpeed; //460;
          request.bias = 15;
          this._airportService.getDistanceTripTime<ResponseModel<DistanceTimeModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              let obj = new DistanceTimeModel();
              obj = response.model;
              let h = Number(obj.tripTimeHour);
              let m = Number(obj.tripTimeMinute);
              let dt = new Date(obj1.departureDateTextUTC + " " + obj1.departureTimeUTC);
              let adt = UtilityFunctions.addHours(dt, h);
              adt = UtilityFunctions.addMinutes(dt, m);
              this.notCancelledGroundStopList.forEach((x, index) => {
                if ((gsIndex + 1) == index) {
                  x.arrivalDateUTC = adt
                  x.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(x.arrivalDateUTC);
                  let ah = adt.getHours();
                  let am = adt.getMinutes();
                  if (am < 10) {
                    x.arrivalTimeUTC = ah.toString() + ":0" + am.toString();
                  }
                  else {
                    x.arrivalTimeUTC = ah.toString() + ":" + am.toString();
                  }

                  //x.ete = obj.tripTime;
                }

                if (gsIndex == index) {
                  x.ete = obj.tripTime; // in departure 
                }

              });

            }
            else {
              if (response.code == "401") {
                //this.errMsg = response.message
                this._authService.signOut();
              }

            }
            this.loading = false;
          })
        }
      }
    }
  }

  calculateDepartureDateTime(sequestId: number) {
    let obj2 = new GroundStopModel();
    let gsIndex2: number = 0;// = sequestId - 1;
    this.notCancelledGroundStopList.forEach((x, index) => {
      if (x.groundStopSequenceID == sequestId) {
        gsIndex2 = index;
      }
    });
    if (gsIndex2 > 0) {
      obj2 = this.notCancelledGroundStopList[gsIndex2];   
      let gsIndex1 = gsIndex2 - 1;
      let obj1 = this.notCancelledGroundStopList[gsIndex1];
      if ((obj1.departureDateTextUTC == "" || obj1.departureTimeUTC == "") && obj2.arrivalDateTextUTC != "" && obj2.arrivalTimeUTC != ""
        && (obj1.groundStopTypeDescription != "Contingency" || obj2.groundStopTypeDescription != "Contingency")) {
        if (obj2.isValidArrDate && obj2.isValidArrTime) {
          if (obj1.latitude != null && obj1.longitude != null && obj2.latitude != null && obj2.longitude != null) {
            let request = new DistanceTimeModel();
            request.departureLatitude = obj1.latitude;
            request.departureLongitude = obj1.longitude;
            request.arrivalLatitude = obj2.latitude;
            request.arrivalLongitude = obj2.longitude;
            request.airSpeed = this.speed; //this.selectedSpeed; // 460;
            request.bias = 15;
            this._airportService.getDistanceTripTime<ResponseModel<DistanceTimeModel>>(request).subscribe(response => {
              if (response != null && response.code == "200") {
                let obj = new DistanceTimeModel();
                obj = response.model;
                let h = Number(obj.tripTimeHour);
                let m = Number(obj.tripTimeMinute);
                let dt = new Date(obj2.arrivalDateTextUTC + " " + obj2.arrivalTimeUTC);
                let ddt = UtilityFunctions.addHours(dt, -h);
                ddt = UtilityFunctions.addMinutes(dt, -m);
                this.notCancelledGroundStopList.forEach((x, index) => {
                  if (index == gsIndex1) {
                    x.departureDateUTC = ddt
                    x.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(x.departureDateUTC);
                    let ah = ddt.getHours();
                    let am = ddt.getMinutes();
                    if (am < 10) {
                      x.departureTimeUTC = ah.toString() + ":0" + am.toString();
                    }
                    else {
                      x.departureTimeUTC = ah.toString() + ":" + am.toString();
                    }

                    x.ete = obj.tripTime;

                  }

                  //if (x.groundStopSequenceID == sequestId) {
                  //  x.ete = obj.tripTime;
                  //}

                });

              }
              else {
                if (response.code == "401") {
                  //this.errMsg = response.message
                  this._authService.signOut();
                }

              }
              this.loading = false;
            })
          }
        }
      }
    }
  }

  clickMoveDown(e: any, item: GroundStopModel) {
    var currentItemOrder = item.groundStopSequenceID
    var nextItemOrder = this.groundStopList[item.groundStopSequenceID].groundStopSequenceID;
    this.groundStopList[currentItemOrder - 1].groundStopSequenceID = nextItemOrder;   
    this.groundStopList[currentItemOrder].groundStopSequenceID = currentItemOrder;  
    this.sortObjectList();

  }

  clickMoveUp(e: any, item: GroundStopModel) {
    var currentItemOrder = item.groundStopSequenceID
    var previousItemOrder = this.groundStopList[item.groundStopSequenceID-2].groundStopSequenceID;
    this.groundStopList[currentItemOrder - 1].groundStopSequenceID = previousItemOrder;
    this.groundStopList[currentItemOrder-2].groundStopSequenceID = currentItemOrder;
    this.sortObjectList();
  }

  clickRemove(e: any, item: GroundStopModel) {
    var currentItemOrder = item.groundStopSequenceID;
    this.groundStopList.splice(currentItemOrder - 1, 1);
    this.groundStopList.forEach(x => {
      if (x.groundStopSequenceID > currentItemOrder) {
        x.groundStopSequenceID -= 1;
      }
    });
    this.sortObjectList();
      
  }

  sortObjectList() {
    this.groundStopList.sort((a, b) => (a.groundStopSequenceID > b.groundStopSequenceID) ? 1 : -1);
    let data = this.groundStopList;
    this.setGroundStopList(data);
    this.groundStopList = [];
    data.forEach(x => {
      this.groundStopList.push(x);
    });
    
    let obj = this.groundStopList.filter(x => x.groundStopGUID == '');
    let s = Array.prototype.map.call(obj, s => s.icao).toString();
    let re = /,/gi;
    this.f.route.setValue(s.replace(re, "-"));
    this.setNotCancelledGroundStopList();

  }

  setGroundStopList(data: GroundStopModel[]) {
    data.forEach((x, index) => {
      let d = new Date();
      let uy = d.getUTCFullYear();
      let umon = d.getUTCMonth();
      let ud = d.getUTCDate();
      let uh = d.getUTCHours();
      let umin=d.getUTCMinutes()
      
      let currentUTCDate = new Date(uy, umon, ud);
      let currentUTCDateTime = new Date(uy, umon, ud, uh, umin);
      x.arrReadOnly = false;
      
      if (x.arrivalDateUTC != null) {
        if (x.arrivalDateUTC < currentUTCDate) {
          x.arrReadOnly = true;
        }
        else {
          if (x.arrivalTimeUTC != "") {
            if (new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC) <= currentUTCDateTime) {
              x.arrReadOnly = true;
            }
          }
        }
      }
      

      x.depReadOnly = false;   
      if (x.departureDateUTC != null) {
        if (x.departureDateUTC < currentUTCDate) {
          x.depReadOnly = true;
        }
        else {
          if (x.departureTimeUTC != "") {
            if (new Date(x.departureDateTextUTC + " " + x.departureTimeUTC) <= currentUTCDateTime) {
              x.depReadOnly = true;
            }
          }
        }
      }
      

      x.moveDown = true;
      x.moveUp = true;
      if (x.arrReadOnly || x.depReadOnly) {
        x.moveDown = false;
        x.moveUp = false;
      }


    });

    data.forEach((x, index) => {
      if (index == 0) {
        x.moveUp = false;
        x.arrReadOnly = true;
      }

      if (index > 0) {
        if (data[index].depReadOnly || data[index].arrReadOnly) {
          //for (var j = 0; j < index; j++) {
          //  data[j].depReadOnly = true;
          //  data[j].arrReadOnly = true;
          //  data[j].moveUp = false;
          //  data[j].moveDown = false;
          //}
          data[index].moveUp = false;
          data[index].moveDown = false;
        }
        //if (data[index - 1].moveDown == false) {
        //  data[index].moveUp = false;
        //}
      }

      if (index == (data.length - 1)) {
        x.moveDown = false;
        x.depReadOnly = true;
      }

    })

    //return data;
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.errMsg2 = "";
    this.errMsgFAR = "";
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    this.groundStopList.forEach(x => {
      x.isValidArrDate = true;
      x.isValidArrTime = true;
      x.isValidDeptDate = true;
      x.isValidDeptTime = true;
      if (x.isTechStopNumber == 1) {
        x.isTechStop = true;
      }
      else {
        x.isTechStop = false;
      }

      if (x.farTypeID != x.fARTypeID) {
        x.fARTypeID = x.farTypeID;
      }
    });

    let hasAllValidFields = true;
    let fillFAR: boolean = true;
    this.notCancelledGroundStopList.forEach((x, index) => {
      if ((x.fARTypeID == 0 && x.farTypeID == 0) && !x.dateTimePassed && index < (this.notCancelledGroundStopList.length-1)) {       
        fillFAR = false;
      }
    })

    if (!fillFAR) {
      return;
    }
    this.notCancelledGroundStopList = this.notCancelledGroundStopList.filter(x => x.groundStopTypeDescription !='Contingency');
    this.notCancelledGroundStopList.forEach((x, index) => {
      if (x.arrivalDateTextUTC != "") {
        x.isValidArrDate = CustomValidators.isValidDate(x.arrivalDateTextUTC);
        if (hasAllValidFields == true && x.isValidArrDate == false) {
          hasAllValidFields = x.isValidArrDate;
        }
        if (x.isValidArrDate) {
          x.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.arrivalDateTextUTC));
          x.arrivalDateUTC = new Date(x.arrivalDateTextUTC);
        }       
      }

      if (x.arrivalTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.arrivalTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.arrivalTimeUTC = timeObj.timeString;
        let arrtv = timeObj.isValidDateTime;
        x.isValidArrTime = arrtv;
        if (hasAllValidFields == true && arrtv == false) {
          hasAllValidFields = arrtv;
        }
      }
      // dept
      if (x.departureDateTextUTC != "") {
        if (x.departureDateTextUTC != "") {
          x.isValidDeptDate = CustomValidators.isValidDate(x.departureDateTextUTC);
          if (hasAllValidFields == true && x.isValidDeptDate == false) {
            hasAllValidFields = x.isValidDeptDate;
          }
          if (x.isValidDeptDate) {
            x.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.departureDateTextUTC));
            x.departureDateUTC = new Date(x.departureDateTextUTC);
          }
        }
      }

      if (x.departureTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.departureTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.departureTimeUTC = timeObj.timeString;
        let depttv = timeObj.isValidDateTime;
        x.isValidDeptTime = depttv;
        if (hasAllValidFields == true && depttv == false) {
          hasAllValidFields = depttv;
        }
      }

      if (index > 0) {
        let prev = this.notCancelledGroundStopList[index - 1];
        if (x.arrivalDateUTC != null && prev.departureDateUTC != null) {
          if (x.arrivalDateUTC.getTime() < prev.departureDateUTC.getTime()) {
            //if (this.errMsg2 != "") {
            //  this.errMsg2 += "\n";
            //}
            //this.errMsg2 += "Arrival date at " + x.icao + " can not be earlier than the departure date of the previous ground stop."
            this.errMsg2 = "Arrival date can not be earlier than the departure date of the previous business stop.";
            return;
          }
          else {
            if (x.arrivalTimeUTC != "" && prev.departureTimeUTC != "") {
              let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);
              let td = new Date(prev.departureDateTextUTC + " " + prev.departureTimeUTC);
              if (ta.getTime() < td.getTime()) {
                //if (this.errMsg2 != "") {
                //  this.errMsg2 += "\n";
                //}
                //this.errMsg2 += "Arrival date time at " + x.icao + " can not be earlier than the departure date time of the previous ground stop.";
                this.errMsg2 = "Arrival date time can not be earlier than the departure date time of the previous business stop.";
                return;
              }
              else {
                let dif = (ta.getTime() - td.getTime()) / 1000 / 3600;
                if (dif >= 24) {
                  this.errMsg2 = "ETE can not be more than 24 hours.";
                  return;
                }

              }
            }
          }
        }

        if (x.arrivalDateUTC != null && x.departureDateUTC != null) {
          if (x.arrivalDateUTC.getTime() > x.departureDateUTC.getTime()) {
            //if (this.errMsg2 != "") {
            //  this.errMsg2 += "\n";
            //}
            //this.errMsg2 += "Arrival date time at " + x.icao + " can not be later than the next departure date time."
            this.errMsg2 = "Arrival date time can not be later than the next departure date time.";
            return;
          }
          else {
            if (x.arrivalTimeUTC != "" && x.departureTimeUTC != "") {
              let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);
              let td = new Date(x.departureDateTextUTC + " " + x.departureTimeUTC);
              if (ta.getTime() > td.getTime()) {
                //if (this.errMsg2 != "") {
                //  this.errMsg2 += "\n";
                //}
                //this.errMsg2 += "Arrival date time at " + x.icao + " can not be later than the next departure date time."
                this.errMsg2 = "Arrival date time can not be later than the next departure date time.";
                return;

              }
            }
            
          }

        }
      }

    });
    
    if (this.ground_stop_editor_form.invalid || this.errMsg != "" || this.errMsg2 != "" || !hasAllValidFields) {
      return;
    }

    this.loading = true;
    
    let obj = new TripModel();
    obj.customerGUID = "";
    if (this.userType == "internal") {
      obj.customerGUID = this.customerGUID;
    }
    obj.aircraftGUID = this.aircraftGUID;
    obj.tripCode = this.trip_code;
    obj.tripCodeGUID = this.tripCodeGUID;
    obj.groundStopList = this.groundStopList;

    this._groundStopService.updateGroundStops<ResponseModel<TripModel>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          if (response.model.message != "") {
            this.errMsg = response.model.message;
          }
          else {
            if (response.model.tripCodeGUID != "") {
              this.isModified = true;
              this.showSuccessMsg = true;
              setTimeout(() => {
                this.showSuccessMsg = false;
                this.clickClose();
              }, 1000);
              //this._dialogService.openAlertDialog("");
              //this.clickClose();
            }
            else {
              //this._dialogService.openAlertDialog("Failed to update the trip at this time. Please try later.");
              this.errMsg = "Failed to update the trip at this time. Please try later.";
              //this._dialogRef.close(this.isModified);
            }
          }
        }
        else {
          this.errMsg = "Failed to update the trip at this time. Please try later.";
        }               
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to update the trip at this time. Please try later.";
        }
      }
      this.loading = false;
    })

  }



  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  farTypeSelectOnChange(e: any, itemGS: GroundStopModel) {
    if (itemGS.farTypeID > 0 && itemGS.fARTypeID == 0) {
      itemGS.fARTypeID = itemGS.farTypeID;
    }
    else {
      if (itemGS.farTypeID == 0 && itemGS.fARTypeID > 0) {
        itemGS.farTypeID = itemGS.fARTypeID;
      }
    }
  }
}
