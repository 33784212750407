<div style="background-color:#f8f8f8;overflow-y:hidden " cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="display: flex; justify-content:space-between">
    <div style="display: flex;padding-left:1em; padding-top:0.25em; float:left; min-width:50em">

      <div style="margin-right:1em">
        <div style="font-size: small">Registration</div>
        <div style="font-weight: bold; font-size: small">{{registration}}</div>
      </div>
      <div style="margin-right:1em">
        <div style="font-size: small">Trip Code</div>
        <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
      </div>
      <!-- <div style="margin-right:1em">
        <div style="font-size: small">Business Stop</div>
        <div style="font-size: small; display: flex">
          <div>{{prevICAO!=''?prevICAO+'-':''}}</div>
          <div style="font-weight: bold">{{icao}}</div>
          <div>{{nextICAO!=''?'-'+nextICAO:''}}</div>
        </div>
      </div> -->
      <div style="margin-right:1em; margin-top: -0.2em;" *ngIf="departureDateTime!=''">
        <div style="font-size: small; display: inline-flex;">Departure<div
          style="margin-left: 0.5em; color: #007bff; text-decoration: underline; font-weight: bold;"
          (click)="openAirportDetails(icao, nextICAO)" class="link">{{icao}}</div>
      </div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{departureDateTime}}</div>
        <div style="font-size: small;margin-top:-0.5em">
          {{departureDateTimeLocal!=''?departureDateTimeLocal:''}}</div>
      </div>
      <div style="margin-right:1em; margin-top: -0.2em;" *ngIf="nextArrivalDateTime!=''">
        <div style="font-size: small; display: inline-flex;">Arrival
          <div style="margin-left: 0.5em; color: #007bff; text-decoration: underline; font-weight: bold;" (click)="openAirportDetails(icao, nextICAO)">
            {{nextICAO}}</div>
        </div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{nextArrivalDateTime}}</div>
        <div style="font-size: small; margin-top:-0.5em"> {{nextArrivalDateTimeLocal!=''?nextArrivalDateTimeLocal:''}}
        </div>
      </div>
      <div>
        <div style="font-size: small;">{{aircraftManufacturer}} {{aircraftModel}}</div>
        <div style="font-size: small;">{{aircraftInfo}}</div>
      </div>
      <form [formGroup]="internal_task_edit_form" *ngIf="internal_task_edit_form">
        <div *ngIf="isRevisionRequiredValue==1">
          <div [ngClass]="f.isRevisionRequired.value==true?'alert alert-danger d-flex align-items-center':'alert alert-success d-flex align-items-center'" 
          style="height:2.5em; margin-bottom:0em; margin-right:2em;padding-left:0.25em; padding-right:0.25em; font-size:small; display: flex; border-radius:0.25em; margin-left: 10em; margin-top: 0.75em;">
            <div>
              <mat-slide-toggle formControlName="isRevisionRequired" style="transform: scale(0.9) " [checked]="isRevisionRequired">
              </mat-slide-toggle>
            </div>
            <div *ngIf="f.isRevisionRequired.value==true">
              <div>Revision Required - </div>
              <div style="margin-top:-0.5em">Turn off to Acknowledge</div>
            </div>
            <div *ngIf="f.isRevisionRequired.value==false">
              <div style="margin-top:0em;">Revision Acknowledged</div>
            </div>
            <div>
              <div *ngIf="f.isRevisionRequired.value==true" style="margin-top:0.5em">
                <i class="material-icons-two-tone icon_orange" style="font-size: x-large;">notifications_active</i>
              </div>
              <div *ngIf="f.isRevisionRequired.value==false" style="margin-top:0.5em">
                <i class="material-icons" style="font-size: x-large; background-color: transparent; color: black; border-color: #5e5e5e">task_alt</i>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isRevisionRequiredValue==2">
          <div [ngClass]="f.isRevisionRequired.value==true?'alert alert-danger d-flex align-items-center':'alert alert-success d-flex align-items-center'" 
          style="height:2.5em; margin-bottom:0em; margin-right:2em;padding-left:0.25em; padding-right:0.25em; font-size:small; display: flex; border-radius:0.25em; margin-left: 10em; margin-top: 0.75em;">
            <div>
              <mat-slide-toggle formControlName="isRevisionRequired" style="transform: scale(0.9) " [checked]="isRevisionRequired">
              </mat-slide-toggle>
            </div>
            <div *ngIf="f.isRevisionRequired.value==true">
              <div>Revision Required - </div>
              <div style="margin-top:-0.5em">Turn off to Acknowledge</div>
            </div>
            <div *ngIf="f.isRevisionRequired.value==false">
              <div style="margin-top:0em">Revision Acknowledged</div>
            </div>
            <div>
              <div *ngIf="f.isRevisionRequired.value==true" style="margin-top:0.5em">
                <i class="material-icons-two-tone icon_red" style="font-size: x-large;">notifications_active</i>
              </div>
              <div *ngIf="f.isRevisionRequired.value==false" style="margin-top:0.5em">
                <i class="material-icons" style="font-size: x-large; background-color: transparent; color: black; border-color: #5e5e5e">task_alt</i>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="onHoldText !=''"
      style="margin-top:1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
      <div style="margin-top: -0.1em; text-align: center; font-weight: normal; font-size: small;">On Hold</div>
    </div>
    <div style="float: right; display: inline-flex; margin-left: -1em;">
      <button mat-icon-button>
        <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium"
          title="Copy note to clipboard">content_copy</i>
      </button>
      <!-- <button mat-icon-button>
          <i class="material-symbols-outlined" style="cursor: pointer;" title="Previous Task">expand_less</i>
        </button>
        <button mat-icon-button>
          <i class="material-symbols-outlined" style="cursor: pointer;" title="Next Task">expand_more</i>
        </button> -->
      <button mat-icon-button mat-dialog-close>
        <i class="material-icons" style="cursor: pointer;" title="Close">close </i>
      </button>
    </div>

  </div>
  <div>
    <app-spin style="width: 15em;" *ngIf="showSpin"></app-spin>
    <form [formGroup]="internal_task_edit_form" *ngIf="internal_task_edit_form">
      <div style="display:flex; margin-top:0em; padding-left: 1em; padding-right:1em">
        <div style="margin-top:0.5em;background-color:white; padding-left:1em; padding-bottom: 0.75em; width: 65em">
          <!-- <div style="display:flex; justify-content: space-between"> -->
          <div class="d-inline-flex" style="width: 100%;">
            <div style="border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right:.25em;margin-top:.5em" [style.background-color]="taskColor">
            </div>
            <div style="display: flex; justify-content: space-between; width: 100%;">
              <div style="margin-top:0.25em;">
                <h5>{{serviceTypeDesc}}</h5>
              </div>
              <div style="margin-bottom: 1em; margin-right: 0.6em;">
                <button *ngIf="serviceTypeID==80 || serviceTypeID==81" mat-button class="btn-high" style="height: 2em; margin-top: 0.5em; margin-right: 1em;" [disabled]="isGARLoading" (click)="generateUKGAR()"><span *ngIf="isGARLoading" class="spinner-border spinner-border-sm mr-1"></span>Create UK GAR</button>
                <button *ngIf="serviceTypeID==101" mat-button class="btn-high" style="height: 2em; margin-top: 0.5em; margin-right: 1em;" [disabled]="isGARLoading" (click)="generateFuelQuote()"><span *ngIf="isGARLoading" class="spinner-border spinner-border-sm mr-1"></span>Create Quote Template</button>

                <button mat-button class="btn-high" style="height: 2em; margin-top: 0.5em; margin-right: 1em;"
                        (click)="clickEmail()">
                  <span class="material-symbols-outlined">
                    send
                  </span>
                  Email
                </button>
                <button mat-button class="btn-high" style="height: 2em; margin-top: 0.5em; margin-right: 1em;"
                        (click)="clickTripMessages()">
                  <span class="material-symbols-outlined">
                    mail
                  </span>
                  Trip Messages
                </button>

              </div>
            </div>
          </div>
          <div style="display: flex">


          </div>

          <div style="display: flex;">
            <div style="display: inline-flex;">
              <div style="margin-left:0em; padding-right: 0.5em;">
                <label for="task_status_select" style="font-size:small">Operational Status</label>
                <div style="margin-top:-0.35em;">
                  <select *ngIf="f" formControlName="task_status_select"
                          style="width: 17em; height:1.5625em; padding: 0; font-size: small;font-weight: 500; border-radius:0.25em; background-color:#eaf0fd"
                          class="form-control" (change)="taskStatusChange($event)">
                    <option *ngFor="let t of taskStatusList" [ngValue]="t.taskStatusGUID">
                      {{t.taskStatusDescription}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div style="display:inline-flex">
            <div style="margin-left:0em;margin-top:0em;">
              <label for="confirmation_number" style="font-size:small">{{numberType}}</label>
              <div style="display:flex;margin-top:-0.5em">
                <input type="text" formControlName="confirmation_number" class="form-control"
                       style="width:17em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd" (mousedown)="$event.stopPropagation()" />
              </div>
            </div>

            <div style="display: flex; flex-direction: row; margin-bottom: 1em;">
              <div *ngIf="due_datetime==''" style="margin-left:3em;margin-top:0em">
                <div style="font-size: small; margin-top:0.25em">
                  Lead
                  Time
                </div>
                <div style="display:flex;margin-top:0.05em; font-size:small">
                  <input type="text" formControlName="lead_time" maxlength="9" placeholder="?d?h?m" class="form-control"
                         [ngClass]="{ 'is-invalid': (submitted || lead_timeChanged) && f.lead_time.errors }"
                         style="width:5em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd"
                         (change)="leadTimeChange($event)" (mousedown)="$event.stopPropagation()" />
                </div>
                <div *ngIf="(submitted || lead_timeChanged) && f.lead_time.errors">
                  <div *ngIf="f.lead_time.errors.pattern"
                       style="background-color:#f9d2de; margin-left:-10em; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">
                    Invalid lead time. format: ?d?h?m
                  </div>
                </div>
              </div>

              <div *ngIf="due_datetime==''" style="margin-left:1em;width:4.25em ">
                <div style="font-size: small; margin-top:0.25em">
                  Due
                  Date
                </div>
                <div style="display:flex;margin-top:0.05em">
                  <input type="text" formControlName="due_date" maxlength="9" placeholder="?d?h?m" class="form-control"
                         [ngClass]="{ 'is-invalid': (submitted || due_dateChanged) && f.due_date.errors }"
                         style="width:5em;height:1.5625em; font-size: small;padding:0.25em; border-radius:0.25em; background-color:#eaf0fd"
                         (change)="dueDateChange($event)" (mousedown)="$event.stopPropagation()" />
                </div>
                <div *ngIf="(submitted || due_dateChanged) && f.due_date.errors">
                  <div *ngIf="f.due_date.errors.pattern"
                       style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">
                    Invalid due date. format: ?d?h?m
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div style="width:14em; height:5.5em; font-size:small; padding:0em 0em 0em 0em;
            white-space: pre-wrap; overflow: auto; border-radius: 0.25em;border: 1px solid rgb(206, 212, 218);
            margin-top: -8em; display: flex; margin-bottom: 2em; margin-left: 63em; align-items: center;">

              <div style="width: 100%; text-align: center;">
                <label for="immediateAction" style="font-size:small; height: 2em;">Immediate Priority</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="immediateAction"
                          style="width:7em;height:1.5625em; font-size: small;padding:0em; margin-left: 3.35em; border-radius:0.25em; background-color:#eaf0fd"
                          class="form-control">
                    <option value="0" selected>No</option>
                    <option value="1">Yes</option>
                  </select>
                </div>
              </div>
            </div>
            <div style="width:15em; height:1.75em; margin-top: -1em; margin-left: 62em; font-size:small;
              text-align: center; font-weight: 500; background-color: beige;">
              Internal Task
            </div>
            <div>
              <div style="justify-content: space-between; width: 100%; padding-top: 0.5em;">
                <div style="display: inline-flex; max-height: 1em;">
                  <label style="width:55em; margin-bottom: 0em;">Internal Notes</label>
                  <button type="button" mat-button color="primary" style="outline:none; font-size: small" [matMenuTriggerFor]="menu">Export</button>
                  <mat-menu #menu="matMenu">
                    <div class="example-box" (click)="exportNote('word')">Word</div>
                    <div class="example-box" (click)="exportNote('pdf')">PDF</div>
                    <div class="example-box" (click)="exportNote('text')">Text</div>
                    <div class="example-box" [cdkCopyToClipboard]="clickCopyNote()">Copy to Clipboard</div>
                  </mat-menu>
                  <button mat-icon-button (click)="clickOpenInNew(2)" style="cursor: pointer; margin-top: -0.75em;">
                    <i class="material-icons" style="font-size:x-large; color: gray">open_in_full</i>
                  </button>
                </div>
                <textarea formControlName="internal_note" class="form-control" style="width:77.5em; height: 20em;font-family:Courier New, Courier, monospace; font-size:small;
                    padding:0em 0em 0em 0.25em;background-color:#eaf0fd" (ngModelChange)="updateNotes($event, false)"
                          (mousedown)="$event.stopPropagation()">
                    {{internal_notes}}
                  </textarea>
              </div>
              <ng-template #internalNotesWrapper>
                <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="background-color:white;">
                  <div id="internal_notes" class="form-control landing-notes" style="height: max-content; font-size:small; padding:1em 1em 1em 1em;background-color: white;
                      white-space: pre-wrap; overflow: auto;" readonly>
                    <div style="display: inline-flex; justify-content: space-between; width: 100%;">
                      Internal Notes
                      <button mat-icon-button mat-dialog-close style="margin-top: -0.5em" (click)="closeNotesPopup()">
                        <i class="material-icons" style="cursor: pointer;" title="Close">close </i>
                      </button>
                    </div>
                    <textarea id="internal-notes" formControlName="internal_note" class="form-control" style="width:45em; height:45em;font-family:Courier New, Courier, monospace;font-size:small;
                        padding:0em 0em 0em 0.25em;background-color:#eaf0fd" (mousedown)="$event.stopPropagation()"
                              (ngModelChange)="updateNotes($event, false)">
                        {{internal_notes}}
                    </textarea>
                    <button mat-button mat-dialog-close class="btn-low"
                            style="background-color:grey; color: white; margin-top: 1em;"
                            (click)="closeNotesPopup(internal_notes)">
                      <div style="display:flex">
                        <i class="material-icons"
                           style="font-size: medium; margin-top:0.25em">close</i>
                        <div>Close</div>
                      </div>
                    </button>&nbsp;
                  </div>
                </div>
              </ng-template>

              <div>
                <div style="display: inline-flex; width: 32.625em; height: 1em; padding-top: 0.5em;">
                  <div style="width: 28em; display: inline-flex;">
                    <label style="margin-bottom: 0em;">Documents</label>
                    <label mat-button class="btn-high"
                           style="border: 0.0625em solid transparent; background-color: transparent; height:1.5em; border-radius: 0.25em; cursor:pointer; margin-left: 0.5em; margin-right: 0.5em;">
                      <input #document formControlName="document" id="document" type="file" ng2FileDrop
                             [uploader]="uploader" multiple style="display:none" accept="*" (change)="importFile($event)"
                             maxFileSize="28000000" (click)="onFileClick($event)" />
                      <div style="display: flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: white; transform: rotate(-90deg);">attachment</i><div>Load New Documents</div></div><span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
                    </label>
                  </div>
                </div>

                <div style="display: inline-flex; width: 100%; justify-content: space-between;">
                  <div style="border-radius: 0.25em;border: 1px solid rgb(206, 212, 218);
                    height: 5em; width: 45em;" ng2FileDrop [uploader]="uploader" (onFileDrop)="dropFile($event)"
                       accept="*" multiple>
                    <div style="height: 100%;">
                      <div style="overflow-y:auto; height: 100%;">
                        <div *ngFor="let item of taskDocumentList; let i=index" style="margin-top:-0.25em">
                          <div style="font-size: small; display:flex; padding:0em; height: 2em;">
                            <div>
                              <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline"
                                 title="{{item.documentName}}"
                                 (click)="clickPreviewDocument($event, item)">{{item.documentName}}</a>
                            </div>
                            <div *ngIf="item.groundStopTaskDocumentGUID=='' && hasExistingDocument"
                                 style="margin-top:0.5em; font-size:x-small">
                              <i class="material-icons"
                                 style="color:green; font-size: small; ">fiber_new</i>
                            </div>
                            <div style="margin-top:0.25em; margin-right: 0.25em" *ngIf="!lockdownTrip">
                              <i class="material-icons" style="color:red; font-size: small; cursor: pointer;"
                                 title="click to remove the document"
                                 (click)="clickRemoveDocument($event, item)">cancel</i>
                            </div>
                            <div *ngIf="!item.isInternal" title="Toggle Public/Internal"
                                 style="cursor: pointer; user-select: none;" (click)="toggleInternalFile(item)">
                              <i class="material-symbols-outlined">more_horiz</i>
                            </div>
                            <div *ngIf="item.isInternal" title="Toggle Public/Internal"
                                 style="cursor: pointer; background-color:#f9d2de; border-radius:0.25em; height: 1.5em; user-select: none;"
                                 class="d-block" (click)="toggleInternalFile(item)">Internal...</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style="height: 5em;
                    margin-right: 1em;">
                    <div *ngIf="isGTSBillable || isThirdPartyBillable"
                         style="font-weight: bold; font-size: small; margin-left: 0.5em; margin-right: 0.5em; border-bottom: 1px solid #aaa;">
                      Accounting Status
                    </div>
                    <div style="display: flex; flex-direction: row; width: 15em;">
                      <div *ngIf="isGTSBillable" style="margin-left:0.5em;">
                        <label for="acct_status_select" style="font-size:small">GTS - Status</label>
                        <div *ngIf="!canChangeBilling" style="margin-top:-0.5em; color: #155724">
                          {{selectedAcctStatusDescription}}
                        </div>
                        <div *ngIf="canChangeBilling">
                          <select *ngIf="f" formControlName="acct_status_select"
                                  style="width:10em; font-size: small; font-weight:500; border-radius:0.25em;border-color:lightgrey;background-color:#eaf0fd">
                            <option *ngFor="let a of acctStatusList" [ngValue]="a.accountingStatusGUID">
                              {{a.accountingStatusDescription}}
                            </option>
                          </select>
                        </div>
                      </div>

                      <!--<div *ngIf="isThirdPartyBillable" style="margin-left:3em">
                      <label for="acct_status_tp_select" style="font-size:small">Third Party - Status</label>
                      <div *ngIf="!canChangeBilling" style="margin-top:-0.5em; color: #155724">
                        {{selectedTaskStatusDescription}}
                      </div>
                      <div *ngIf="canChangeBilling">
                        <select *ngIf="f" formControlName="acct_status_tp_select"
                          style="width:10em; font-size: small; font-weight:500; border-radius:0.25em;border-color:lightgrey;background-color:#eaf0fd">
                          <option *ngFor="let a of acctStatus_TPList" [ngValue]="a.accountingStatusGUID">
                            {{a.accountingStatusDescription}}</option>
                        </select>
                      </div>
                    </div>-->
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </form>
  </div>
  <div
    style="justify-content:space-between;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display:flex; height:2.75em">
    <div style="display: inline-flex;">
      <button *ngIf="!lockdownTrip && !showSpin" mat-button class="btn-high" (click)="clickSave()"><span *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"></span>
        <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i>
          <div>Save</div>
        </div>
      </button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;">
        <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
          <div>Close</div>
        </div>
      </button>&nbsp;
      <!-- <button *ngIf="businessRulesID==0 && landing_permit_task_edit_form" class="btn-low" mat-button
          (click)="clickPromoteBusinessRule()">
          <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">grading</i>
            <div>Promote Business Rule</div>
          </div>
        </button>&nbsp; -->
      <button mat-button class="btn-low" *ngIf="hasTaskAudit==true" (click)="clickAudit()">
        <div style="display:flex"><i class="material-icons"
            style="font-size: medium; margin-top:0.25em">manage_search</i>
          <div>Audit</div>
        </div>
      </button>&nbsp;
      <div *ngIf="errMsg2!=''" class="d-block"
        style="margin-top:0em; margin-left:0.25em; display:flex; background-color:#f9d2de; padding-left:0.25em; width:24em;font-size: small ">
        {{errMsg2}}</div>
      <div *ngIf="showSuccessMsg" style="margin-top: 0em;">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
      <div *ngIf="errMsg!=''" class="d-block"
        style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; max-width:40em; ">
        <label style="color:black; font-size: small">{{errMsg}}</label>
      </div>
    </div>

    <form [formGroup]="internal_task_edit_form" *ngIf="internal_task_edit_form">
      <div style="font-size: small; float: right; margin-right: 3em; margin-top: -0.5em; display: inline-flex;">
        <div style="margin-right: 2em; margin-top: -0.25em;">
          <label for="record_status" style="font-size:small">Disable Record</label>
          <div style="margin-top:-0.5em;">
            <select *ngIf="f" formControlName="record_status"
              style="font-size: small;background-color:#eaf0fd;border-radius:0.25em;width: 7em; height:1.5625em; padding: 0;"
              class="form-control"
              (change)="record_statusChange($event)">
              <option value="1" selected>No</option>
              <option value="0">Yes</option>
            </select>
          </div>
        </div>
        <div>
          <div>
            Modified By: {{modifiedBy}}
          </div>
          <div>
            Modified Date: {{modifiedDate}}
          </div>
        </div>
        <div *ngIf="businessRulesID != 0" style="margin-left: 3em; font-size: small;">
          BrID
          <div>
            {{businessRulesID}}
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
