<div *ngIf="upAirportCountry" style="width: 100%; overflow:hidden"
     [cdkDragDisabled]="isOpenedNew" cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle [ngStyle]="{'padding': isOpenedNew ? '0.5em' : '0em'}">
  <div class="d-flex justify-content-between">
    <div class="d-inline-flex" [ngStyle]="{'margin-top': isOpenedNew ? '0.5em' : '0em'}">
      <div class="material-icons-outlined mr-1">airplane_ticket</div>
      <h5>Manage Airports</h5>
    </div>
    <div *ngIf="!isOpenedNew">
      <span class="material-icons-outlined pointer" (click)="openGlobalSettingsDashboardComponent()" style="margin-top:0em;margin-right:.5em">arrow_circle_left</span>
      <mat-icon (click)="openInNew()" style="margin-right:.5em;cursor:pointer">open_in_new</mat-icon>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>

  <div class="d-inline-flex justify-content-between" style="width:100%">
    <div class="d-inline-flex">
      <div class="form-group mr-2">
        <label for="text_search" style="font-size:small">Enter ICAO or IATA</label>
        <div class="input-group searchbar" style="margin-top:-.3em;width: 30em; height: 1.5625em; background-color: #eaf0fd;">
          <div>
            <span style="font-size: x-small; background-color: #eaf0fd;line-height:1;" class="input-group-text material-icons-outlined searchbarleft pointer" (click)="getAirportData('airport')"
                  cdkOverlayOrigin #trigger="cdkOverlayOrigin">search</span>
          </div>
          <!--<div class="input-group-prepend searchbarleft">
        <span class="input-group-text material-icons-outlined searchbarleft">search</span>
      </div>-->
          <input type="text" [(ngModel)]="airportList" class="form-control form-control-sm searchbarinput"
                 (keyup.enter)="getAirportData('airport')" (click)="minRunway=false" (mousedown)="$event.stopPropagation()">

          <div>
            <span style="font-size: x-small; background-color: #eaf0fd;line-height:1;" class="input-group-text material-icons-outlined searchbarright pointer" (click)="clickReset()">close</span>
          </div>
        </div>
      </div>
      <mat-divider vertical class="mr-2"></mat-divider>
      <div class="form-group mr-2">
        <label style="font-size:small">Country</label>
        <select [(ngModel)]="countryID" style="margin-top:-.35em" class="form-control form-control-sm dropdown" (click)="minRunway=true" (change)="getAirportData('country')" (mousedown)="$event.stopPropagation()">
          <option value="0">
          </option>
          <option *ngFor="let country of countryList" [ngValue]="country.countryID">
            {{country.countryName}}
          </option>
        </select>
      </div>
      <span class="material-icons pointer" style="font-size:small;margin-top:2.5em" (click)="clickReset()">close</span>
      <div>
        <mat-slide-toggle style="transform:scale(.8);margin-top:1.5em" [(ngModel)]="minRunway" color="primary" (change)="getAirportData(callFrom)">Runway Length > 5000ft</mat-slide-toggle>
      </div>
      <mat-divider vertical class="mr-2"></mat-divider>
      <div class="form-group mr-2">
        <label style="font-size:small">Upcoming Trips</label>
        <select [(ngModel)]="daysOut" style="margin-top:-.35em" class="form-control form-control-sm dropdown" (click)="minRunway=false" (change)="getAirportData('daysout')" (mousedown)="$event.stopPropagation()">
          <option value="0"></option>
          <option value="7">7 Days Out</option>
          <option value="14">14 Days Out</option>
          <option value="30">30 Days Out</option>
        </select>
      </div>
      <div style="margin-top:1em">
        <app-spin *ngIf="showSpin"></app-spin>
      </div>
    </div>
  </div>
  <div>
    <div style="font-size:small;margin-top:-1em;margin-right:1.5em;float:right" *ngIf="airports">
      Total Records: {{airports.length}}
    </div>
    <div class="d-inline-flex p-2" style="background-color:white;height:30em;width:100%">
      <div style="height:30em;width:71%;overflow:auto">
        <table mat-table [dataSource]="airports" class="example-container">
          <tr>
            <ng-container matColumnDef="icao">
              <th mat-header-cell *matHeaderCellDef width="5%">
                ICAO
              </th>
              <td mat-cell *matCellDef="let element" [ngClass]="{ 'alert-danger' : element.airportID==0 }">{{element.icao}}</td>
            </ng-container>
            <ng-container matColumnDef="iata">
              <th mat-header-cell *matHeaderCellDef width="5%">
                IATA
              </th>
              <td mat-cell *matCellDef="let element" [ngClass]="{ 'alert-danger' : element.airportID==0 }">{{element.iata}}</td>
            </ng-container>
            <ng-container matColumnDef="airport">
              <th mat-header-cell *matHeaderCellDef width="20%">
                Airport
              </th>
              <td mat-cell *matCellDef="let element" style="text-align:left !important;" [ngClass]="{ 'alert-danger' : element.airportID==0 }">{{element.airportName}} {{element.airportLocationWithCountryNameAbbr}}</td>
            </ng-container>
            <ng-container matColumnDef="utc">
              <th mat-header-cell *matHeaderCellDef width="5%">
                UTC Offset
              </th>
              <td mat-cell *matCellDef="let element">{{element.currentUTCConversion}}</td>
            </ng-container>
            
            <ng-container matColumnDef="airport_of_entry">
              <th mat-header-cell *matHeaderCellDef width="6%" matTooltip="Airport of Entry">
                AOE
              </th>
              <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.airportOfEntry=='Yes'? 'beige':'', 'font-weight': element.airportOfEntry=='Yes'? '500':''}"><div>{{element.airportOfEntry}}</div></td>
            </ng-container>
            <ng-container matColumnDef="restrictions">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Restrictions
              </th>
              <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.hasRestrictions? 'beige':'', 'font-weight': element.hasRestrictions? '500':''}">{{element.hasRestrictions==null? '-' : element.hasRestrictions? 'Yes':'No'}}</td>
            </ng-container>
            <ng-container matColumnDef="slots">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Slots
              </th>
              <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.requiresSlotsBool? 'beige':'', 'font-weight': element.requiresSlotsBool? '500':''}">{{element.requiresSlotsBool==null? '-' : element.requiresSlotsBool? 'Yes':'No'}}</td>
            </ng-container>
            <ng-container matColumnDef="ppr">
              <th mat-header-cell *matHeaderCellDef width="5%">
                PPR
              </th>
              <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.requiresPPR? 'beige':'', 'font-weight': element.requiresPPR? '500':''}">{{element.requiresPPR==null? '-' : element.requiresPPR? 'Yes':'No'}}</td>
            </ng-container>
            <ng-container matColumnDef="landing_permit_private">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Landing Private
              </th>
              <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.requiresLandingPermitPrivate? 'beige':'', 'font-weight': element.requiresLandingPermitPrivate? '500':''}">{{element.requiresLandingPermitPrivate==null? '-' : element.requiresLandingPermitPrivate? 'Yes':'No'}}</td>
            </ng-container>
            <ng-container matColumnDef="landing_permit_charter">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Landing Charter
              </th>
              <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.requiresLandingPermitCharter? 'beige':'', 'font-weight': element.requiresLandingPermitCharter? '500':''}">{{element.requiresLandingPermitCharter==null? '-' : element.requiresLandingPermitCharter? 'Yes':'No'}}</td>
            </ng-container>
            <ng-container matColumnDef="advisories">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Advisories
              </th>
              <td mat-cell *matCellDef="let element">{{element.advisoryCount>0? 'Yes':'No'}}</td>
            </ng-container>
            <ng-container matColumnDef="revisions">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Revisions Required
              </th>
              <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.requiresRevision? 'beige':'', 'font-weight': element.requiresRevision? '500':''}" [ngClass]="{'alert-danger': element.hasExpiredRevisions}">{{element.requiresRevision? 'Yes':'No'}}</td>
            </ng-container>
            <ng-container matColumnDef="expired_documents">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Expired Documents
              </th>
              <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger bold': element.hasExpiredDocuments}">{{element.hasExpiredDocuments? 'Yes':'No'}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div>Action</div><div style="margin-top:0em"><a mat-button class="btn-high grid" style="align-self:center" (click)="clickEdit(null);">Add</a></div>
              </th>
              <td mat-cell *matCellDef="let element">
                <a *ngIf="element.airportID!=0" mat-button class="btn-low grid" style="align-self:center">
                  Edit
                </a>

              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickEdit(row)"></tr>
        </table>

      </div>
      <div class="ml-2" style="width:29%;">
        <div style="position: relative;" (mousedown)="$event.stopPropagation()">
          <div style="width:100%;height:38.3em;border-radius:.25em" id="manage-airport-map"></div>
          <div *ngIf="airports.length>0" style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
            fullscreen
          </div>
        </div>

      </div>
      

    </div>


  </div>
</div>

