import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { UserModel } from '../models/user.model';
import { AuthenticateService } from '../services/authenticate.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { CustomerModel } from '../models/customer.model';
import { VendorModel } from '../models/vendor.model';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { CommonService } from '../services/common.service';
import { ResponseModel } from '../models/response.model';
import { AircraftModel } from '../models/aircraft.model';
import { UserPermissionAdminEditDialogComponent } from './user-permission-admin-edit-dialog.component';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogService } from '../services/dialog.service';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';

@Component({
  selector: 'app-user-permission-registration-edit-dialog',
  templateUrl: './user-permission-registration-edit-dialog.component.html',
  styleUrls: ['./user-permission-registration-edit-dialog.component.css']
})

export class UserPermissionRegistrationEditDialogComponent implements OnInit, AfterViewInit, OnChanges {
  user_permission_registration_form: UntypedFormGroup;

  loading = false;
  submitted = false;
  user: UserModel;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  @Input() userId: string;
  @Input() customerGuid: string;
  @Input() tabName: string;
  @Input() userType: string;
  @Input() locked: boolean;
  isModified: boolean=false;
 
  userList: UserModel[];
  //isActive: boolean;
  
  //displayedColumn: string[] = ['username', 'name', 'email'];
  displayedColumnAC: string[] = ['registration'];
  
  aircraftList: AircraftModel[];
  avaiAircraftList: AircraftModel[];
  selAircraftList: AircraftModel[];

  //@Output() tabChanged = new EventEmitter();
  @Input() admin_access_select: boolean;
  @Input() account_status_select: boolean;
  @Input() effectiveDateText: string;
  @Input() expirationDateText: string;
  @Input() isValidateEffectiveDate: boolean;
  @Input() isValidateExpirationDate: boolean;
  showSuccessMsg: boolean = false; 
  ua: number;
  ut: string;
  checkAllRegistration: boolean;
  dataChanged: boolean = false;
  @Output() public sendDataToParent = new EventEmitter();
  
  //private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  //private eventSubscription: Subscription;
  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService, private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService, private readonly _dialogRef: MatDialogRef<UserPermissionAdminEditDialogComponent>,
    private readonly _dialogService: DialogService) {

  }

  getAllData(): Observable<any[]> {
    var request = new UserModel()
    request.userId = this.userId;
    request.customerGuid = this.customerGuid;
    //let getUserResponse = this._identityService.getUserWithAnyStatusByUserId<ResponseModel<UserModel>>(request);
    let getAircraftResponse = this._identityService.getRegistrationListByCustomerGUIDUserId<ResponseModel<AircraftModel[]>>(request);
    //return forkJoin([getUserResponse, getAircraftResponse]);
    return forkJoin([getAircraftResponse]);
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.ua = 0;// Number(localStorage.getItem('ua'));
    this.ut = localStorage.getItem('ut').toLowerCase();
    if (localStorage.getItem('up.isA') != null && localStorage.getItem('up.isA') != "") {
      if (localStorage.getItem('up.isA').toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
        this.ua = 1;
      }
    }
    if (this.ua != 1) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.aircraftList = [];
    this.avaiAircraftList = [];
    this.selAircraftList = [];
    this.showSpin = true;
    
    this.checkAllRegistration = false;

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.aircraftList = responses[0].model;
          this.setAircraftList();
          if (this.selAircraftList.length == 0) {
            this.checkAllRegistration = true;
          }

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.loading = false;
      this.showSpin = false;

      this.initControls();

    });



  }

  initControls() {
    this.user_permission_registration_form = this._formBuilder.group({
      checkAllRegistration: [this.checkAllRegistration]
      //customer_select: null,
      //account_status_select: [this.user.isActive],
      //admin_access_select: [this.user.isAdmin]

    });


  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.admin_access_select && changes.admin_access_select.currentValue)
      this.admin_access_select = changes.admin_access_select.currentValue;
    if (changes.account_status_select && changes.account_status_select.currentValue)
      this.account_status_select = changes.account_status_select.currentValue;
    if (changes.effectiveDateText && changes.effectiveDateText.currentValue)
      this.effectiveDateText = changes.effectiveDateText.currentValue;
    if (changes.expirationDateText && changes.expirationDateText.currentValue)
      this.expirationDateText = changes.expirationDateText.currentValue;
    if (changes.isValidateEffectiveDate && changes.isValidateEffectiveDate.currentValue)
      this.isValidateEffectiveDate = changes.isValidateEffectiveDate.currentValue;
    if (changes.isValidateExpirationDate && changes.isValidateExpirationDate.currentValue)
      this.isValidateExpirationDate = changes.isValidateExpirationDate.currentValue;
    if (changes.locked)
      this.locked = changes.locked.currentValue;
    this.showSuccessMsg = false;
  }

  get f() { return this.user_permission_registration_form.controls; }

  checkAllRegistrationChange(e: any) {
    this.errMsg = "";
    this.dataChanged = true;
    //setTimeout(() => {
      this.sendDataToParentEndPoint()
    //}, 500);

  }

  clickAddItem(e: any, item: AircraftModel) {
    this.aircraftList.forEach(x => {
      if (x.aircraftGuid.toLowerCase() == item.aircraftGuid.toLowerCase()) {
        x.selected = true;
      }
    })
    this.setAircraftList();
    this.dataChanged = true;
    //setTimeout(() => {
            this.sendDataToParentEndPoint()
    //      }, 500);
   
  }

  clickRemoveItem(e: any, item: AircraftModel) {
    this.aircraftList.forEach(x => {
      if (x.aircraftGuid.toLowerCase() == item.aircraftGuid.toLowerCase()) {
        x.selected = false;
      }
    })
    this.setAircraftList();
    this.dataChanged = true;
    //setTimeout(() => {
      this.sendDataToParentEndPoint()
    //}, 500);

  }

  clickSelectAll() {
    this.aircraftList.forEach(x => {
      x.selected = true;
    })

    this.setAircraftList();
    this.dataChanged = true;
    //setTimeout(() => {
      this.sendDataToParentEndPoint()
    //}, 500);

  }

  clickRemoveAll() {
    this.aircraftList.forEach(x => {
      x.selected = false;
    })

    this.setAircraftList();
    this.dataChanged = true;
    //setTimeout(() => {
      this.sendDataToParentEndPoint()
    //}, 500);

  }

  setAircraftList() {
    this.avaiAircraftList = this.aircraftList.filter(u => u.selected == false);
    this.selAircraftList = this.aircraftList.filter(u => u.selected == true);
    
  }

  sendDataToParentEndPoint() {
    this.sendDataToParent.emit({ dataChanged: this.dataChanged});
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    if (!this.isValidateEffectiveDate || !this.isValidateExpirationDate) {
      return;
    }
    if (this.loading == true) {
      return;
    }

    this.loading = true;
    this.showSpin = true;

    //if (this.f.checkAllRegistration.value == false && this.selAircraftList.length == 0) {
    //  this.errMsg = "Please select at least one aircraft";
    //  return;
    //}
    //else {
      if (this.f.checkAllRegistration.value == true) {
        this.selAircraftList = [];
      }
    //}

    var request = new UserModel();
    request.isActive = this.account_status_select;
    //request.isAdmin = this.admin_access_select;
    if (this.admin_access_select) {
      request.isAB = localStorage.getItem('up.isA');
    }
    else {
      request.isAB = localStorage.getItem('up.isB');
    }
    request.effectiveDate = this.effectiveDateText;
    request.expirationDate = this.expirationDateText;
    request.locked = this.locked;
    request.customerGuid = this.customerGuid;
    request.userId = this.userId;
    
    request.aircraftGuidList = Array.prototype.map.call(this.selAircraftList, s => s.aircraftGuid).toString();
    
    this._identityService.saveUserAircraftAccessByUserId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {
          //var s = "The user info and aircraft selections have been saved.";
          //this._dialogService.openAlertDialog(s);
          this.dataChanged = false;
          this.isModified = true;
          this.showSuccessMsg = true;
          //this.sendDataToParent.emit({ dataChanged: this.dataChanged, isModified: this.isModified });
          setTimeout(() => {
            this.sendDataToParentEndPoint()
          }, 500);
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
        }
        else {
          this.errMsg = "Failed to save to our database.";
        }
      }
      else {
        this.errMsg = "Failed to save to our database.";
      }
      this.loading = false;
      this.showSpin = false;

    })

  }

  clickClose() {  
    //this._dialogRef.close(this.isModified);
    this._dialogRef.close(true);
  }

  //clickBackToUserPermissionAdmin() {
  //  this._router.navigate(['/user-accounts/user-permission-admin']);
  //}

  //clickTab(s: string) {
  //  this.tabChanged.emit({ tabName: s });
  //  //switch (s) {
  //  //  case "registration":
  //  //    this._router.navigate(['/user-accounts/user-permission-registration-edit', { tabName: s, userId: this.userId, customerGuid: this.customerGuid }]);
  //  //    break;
  //  //  case "restrict":
  //  //    this._router.navigate(['/user-accounts/user-permission-restrict-edit', { tabName: s, userId: this.userId, customerGuid: this.customerGuid }]);
  //  //    break;
  //  //  case "pic":
  //  //  case "sic":
  //  //  case "pax":
  //  //    this._router.navigate(['/user-accounts/user-permission-person-edit', { tabName: s, userId: this.userId, customerGuid: this.customerGuid }]);
  //  //    break;

  //  //}

  //}

}
