import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDecimal]'
})
export class DecimalDirective {
  @Input() decimalPlaces: number = 4;

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value;

    // Validate the input using a regular expression
    const regex = new RegExp(`^-?\\d*(\\.\\d{0,${this.decimalPlaces}})?$`);
    if (!regex.test(inputValue)) {
      // If the input is invalid, remove the last character
      inputElement.value = inputValue.slice(0, -1);
    }
  }
}
