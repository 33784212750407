<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-1em" mat-dialog-title>
  Vendor Locator
</div>
<div mat-dialog-content style="margin-top:-.5em;padding-bottom:0; background-color:white;border-radius:.25em" class="mx-1 pb-3">

  <form *ngIf="vendor_locator_form" [formGroup]="vendor_locator_form">
    <div class="d-flex justify-content-start mt-1" style="margin-left:-.5em">
      <div>
        <div>
          <mat-radio-group class="example-radio-group" [(ngModel)]="searchType" [ngModelOptions]="{standalone: true}">
            <mat-radio-button style="transform:scale(.75);font-size:large" value="airport">ICAO/IATA</mat-radio-button>
            <mat-radio-button style="transform:scale(.75);font-size:large;margin-left:-.75em" value="vendor">Vendor Name</mat-radio-button>
            <mat-radio-button style="transform:scale(.75);font-size:large;margin-left:-.75em" value="city">City Name</mat-radio-button>
            <mat-radio-button style="transform:scale(.75);font-size:large;margin-left:-.75em" value="country">Country Name</mat-radio-button>

          </mat-radio-group>
        </div>
        <div class="col mt-1">
          <input type="text" class="form-control form-control-sm inputBox" required formControlName="search_by" [ngClass]="{ 'is-invalid': submitted && f.search_by.errors }" />
          <span class="invalid-tooltip alert-danger p-0" style="font-size:small;">
            Enter a search field.
          </span>
        </div>
      </div>

      <div class="ml-3">
        <button mat-raised-button class="btn-high mt-4" (click)="getVendorList()">Search</button>
      </div>
    </div>
    <div *ngIf="errMsg!=''">
      <span style="font-size:small" class="alert-info small p-1">{{errMsg}}</span>
    </div>
    <div class="d-inline-flex" *ngIf="dataSource!=null&&dataSource.data.length>0">
      <div class="mt-2" style="background-color:white;min-width:max-content;max-height:30em;min-height:7em;overflow:auto;padding:1em">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">          
          <mat-tree-node *matTreeNodeDef="let node;" matTreeNodePadding class="ml-1">
            <button mat-icon-button disabled></button>
            <div *ngIf="node.mainVendorName=='' || (node.intoPlaneAgent !='' && node.intoPlaneAgent !=null)" [ngClass]="{ 'ml-3': node.mainVendorName!='', 'font-weight-bold':node.preferred}" (click)="selectVendor(node)" class="txtColor">
              <div>
                <span>{{node.name}}</span><span *ngIf="node.mainVendorName==''">&nbsp;- {{node.icao}}&nbsp; {{node.iata}}</span><i class="material-icons-two-tone blue" matTooltip="Preferred" *ngIf="node.preferred">grade</i>
              </div>
            </div>
            <div *ngIf="node.mainVendorName!='' && (node.intoPlaneAgent =='' || node.intoPlaneAgent == null)" [ngClass]="{ 'ml-3': node.mainVendorName!='','font-weight-bold':node.preferred}" (click)="selectVendor(node)" class="txtColor">
              <div>
                <span>{{node.name}}</span><span *ngIf="node.mainVendorName==''">&nbsp;- {{node.icao}}&nbsp; {{node.iata}}</span><i class="material-icons-two-tone blue" matTooltip="Preferred" *ngIf="node.preferred">grade</i>
              </div>
            </div>
          </mat-tree-node>          
          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding [ngClass]="{ 'ml-3': node.icao!=null }">
            <button mat-icon-button matTreeNodeToggle>
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <div *ngIf="node.id>0 && node.icao!=null" [ngClass]="{'font-weight-bold':node.preferred}" (click)="selectVendor(node)" class="txtColor">{{node.name}}<span *ngIf="node.icao!=null">&nbsp;- {{node.icao}}&nbsp; {{node.iata}}</span><i class="material-icons-two-tone blue" matTooltip="Preferred" *ngIf="node.preferred">grade</i></div>
            <div *ngIf="node.icao==null">{{node.name}}</div>
            <div *ngIf="node.id==0">{{node.name}}<span *ngIf="node.icao!=null">&nbsp;- {{node.icao}}&nbsp; {{node.iata}}</span></div>
          </mat-tree-node>
        </mat-tree>
        <!--<mat-accordion class="example-headers-align" multi>
          <ng-template ngFor let-element [ngForOf]="vendorRoles" let-i="index">

            <mat-expansion-panel *ngIf="vendorRoles!=null && vendorRoles.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
                <mat-panel-title>
                  <mat-icon>{{element.iconName}}</mat-icon>
                  {{element.vendorRole}}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-selection-list #airports [multiple]="false" style="max-height:20em;overflow:auto;width:max-content">
                <mat-list-option *ngFor="let v of element.vendor" [value]="v" (click)="selectVendor(v)">
                  {{v.vendorName}} {{v.icao}} {{v.countryName}}
                </mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
          </ng-template>
        </mat-accordion>-->

      </div>


      <div class="mt-4 ml-4" style="min-width:45em;" *ngIf="vendorID!=0 && vendorList.length>0">
        <app-vendor-details [vendorID]="vendorID" [icao]="icao"></app-vendor-details>
      </div>
    </div>



  </form>
</div>

<mat-dialog-actions align="left">
  <button mat-button mat-dialog-close class="btn-low">Close</button>
</mat-dialog-actions>
<app-spin *ngIf="isLoading"></app-spin>
