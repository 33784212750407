import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ResponseModel } from '../models/response.model';
import { MessageModel } from '../models/message.model';
import { MessageLinkModel } from '../models/message-link';
import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';


@Component({
  selector: 'app-clipboard-details-dialog',
  templateUrl: './clipboard-details-dialog.component.html',
  styleUrls: ['./clipboard-details-dialog.component.css']
})

export class ClipboardDetailsDialogComponent implements OnInit {
 
  clipboard_details_dialog_form: UntypedFormGroup;
 
  dialogTitle: string;
  tripCodeGUID: string;
  messageGUID: string;
  messageGUIDList: string;
  allMessageData: MessageModel[];
  messageLinkList:MessageLinkModel[]; 
  linkDetailsList: {
    custGUID: string,
    tripList: {
      tripCodeGUID: string,
      gsList: {
        gsGUID: string,
        gsTaskList: {
          messageLinkGUID: string,
          groundStopTaskGUID: string
        }[]
      }[]
    }[]
  }[]; 
  isDraft: boolean = false;
  displayedColumn: string[] = ['messageFolder', 'action' ];
  copied: boolean;
  showSuccessCopy: boolean;

  constructor(private readonly _dialogRef: MatDialogRef<ClipboardDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
  private readonly formBuilder: UntypedFormBuilder,
    ) {
   
      this.dialogTitle = this._data.dialogTitle;
      this.messageGUID = this._data.messageGUID;
      this.messageGUIDList = this._data.messageGUIDList;
      this.messageLinkList = this._data.messageLinkList;
   //   this.linkDetailsList = this._data.linkDetailsList;
      this.isDraft = false;
      if (this._data.isDraft != null && this._data.isDraft != undefined && this._data.isDraft != "")
        this.isDraft = this._data.isDraft
      this.allMessageData = [];
      if (this._data.allMessageData != null && this._data.allMessageData != undefined && this._data.allMessageData != "")
        this.allMessageData = this._data.allMessageData
   
  }

  ngOnInit() {
    this.linkDetailsList = [];
    this.messageLinkList.forEach(v => {
      let client: any = { 
        custGUID: v.customerGUID,
        tripList:null
       }
      let tripdetails = function (msgLinkList: MessageLinkModel[]) {
        let triplist = [];
        msgLinkList.forEach(t => {
          let tripdetail = {
            tripCodeGUID: t.tripCodeGUID,
            gsList: null
          };

          let legdetails = function (msgLinkList: MessageLinkModel[]) {
            let leglist = [];
            msgLinkList.forEach(l => {
              let gs = {
                gsGUID: l.groundStopGUID,
                gsTaskList: msgLinkList.filter(x => x.groundStopGUID == l.groundStopGUID)
              };
              if (leglist.findIndex(l2 => l2.gsGUID == l.groundStopGUID) == -1) {
                leglist.push(gs);
              }
            });
            return leglist;
          }

          if (triplist.findIndex(l => l.tripCodeGUID == t.tripCodeGUID) == -1) {
            tripdetail.gsList = legdetails(msgLinkList.filter(l => l.tripCodeGUID == t.tripCodeGUID));
            triplist.push(tripdetail);
          }
        });

        return triplist;
      }
      
      if (this.linkDetailsList.findIndex(l => l.custGUID == v.customerGUID) == -1) {
        client.tripList = tripdetails(this.messageLinkList.filter(c => c.customerGUID == v.customerGUID));
        this.linkDetailsList.push(client);
      }

    });
  } 
 
  clickClose( ) {
    this._dialogRef.close( ); 
  }
  
  copyToClipboard(copyGuid) {
    const textField = document.createElement('textarea');
    textField.value = copyGuid;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    this.copied = true;
    this.showSuccessCopy = true;

    // Optional: Reset the "copied" state after a certain time
    setTimeout(() => {
      this.copied = false;
      this.showSuccessCopy = false;

    }, 2000); // Reset after 2 seconds
  }


}











