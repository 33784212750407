import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { FuelService } from '../services/fuel.service';
import { Title } from '@angular/platform-browser';
import { ReturnObjModel } from '../models/return-obj.model';
import { CustomerModel } from '../models/customer.model';
import { FuelMenuComponent } from '../fuelmenu/fuel-menu.component';
import { MissingTaxesAirportModel } from '../models/mssing-taxes-airport.model';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { GlobalConstant } from '../common-utility/global-constant';
import { FuelPriceFileModel } from '../models/fuelpricefile.model';
import { FileModel } from '../models/file.model';
import { CustomValidators } from '../common-utility/custom.validators';
import { FuelPriceLocationUploadComponent } from './fuel-price-locations-upload.component';
import { FuelSupplierLocationProfilesModel } from '../models/fuel-supplier-location-profiles.model';
import { map, startWith } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';

interface ICycle {
  cycleName: string,
  selected: boolean
}

@Component({
  selector: 'app-location-preferences',
  templateUrl: './location-preferences.component.html',
  styleUrls: ['./location-preferences.component.css']
})


export class LocationPreferencesComponent implements OnInit {

  location_preferences_dialog_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg1: string;
  msg: string;
  showSpin: boolean = false;


  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  selectedTabName: string;
  reloadChildPage: boolean;
  totalRecordsText: string;
  totalRecords: number;
  pageSize: number = 15;
  totalPageNumber: number = 0;
  v: number = this._authService.getCurrentTimeNumber();
  startDate: Date;
  endDate: Date;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  pageFrom: string = "";
  sort_by: string;
  grantAccessToFuel: boolean = false;



  showClientList: boolean = false;
  selectedClientList: CustomerModel[];
  selectedClientCountText: string = "";
  filteredClientList: Observable<CustomerModel[]>;
  clientList: CustomerModel[];
  //selectedClientList: FuelClientMarkupModel[]; 


  includeDisabled: boolean = false;

  includeDisabledRecords: boolean;
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;


  displayedColumn: string[] =/*'action1',*/[   'countryName', 'icao',   'iata', 'faaid', 'intoPlaneAgent',   'isDaily' ,'isWeekly' ,'isBiWeekly','isMonthly', 'isBiMonthly','is30Days',  'status', 'isActive'  ];



  websiteEnvironment: string = "";
  currentPageIndex: number = 0;


  iscountryorairport: string = "country";
  sortedData: any[] = [];
  modifiedRecordsList: any[] = [];
  missingTaxesAirportList: MissingTaxesAirportModel[];
  fromMissingTaxesAirport: boolean = false;

  sortedData2: any[] = [];
  missingTaxesAirportAllDetailsList: MissingTaxesAirportModel[];
  fromMissingTaxesAirportAllDetails: boolean = false;
  showSpin2: boolean = false;
  searchFiltervalue: number = 1;
  isDialog: boolean = false;
  icao: string;
  airportID: number = 0;
  hasExistingDocument: boolean = false;
  taskDocumentList: GroundStopTaskDocumentModel[];


  controlValueChanged: boolean = false;

  uploader: FileUploader;
  finishAllUploads: boolean = false;

  attachedfilename: string = '';
  fs_select: string;         // ='default';
  maxTempDocId: number = 0;
  fuelPriceFileModel: FuelPriceFileModel;
  fileList: FileModel[];
  fuelSupplier: string;
  allowedFileTypeList: ['csv', 'xlsx', 'xls'];

  allowedFileType: string;
  @ViewChild('document') fileInput!: ElementRef;
  showButton: boolean = true;
  isFileInputDisabled: boolean = true;
  fuelSupplierGUID: string;
  fuelSupplierLocationProfilelist: FuelSupplierLocationProfilesModel[];
  fuelSupplierLocationProfile: FuelSupplierLocationProfilesModel;
  successMsg: string = '';
  showSuccessMsg: boolean = false;
  fuelSupplierName: string = '';
  isModified: boolean = false;
  countryData: FuelSupplierLocationProfilesModel[] = [];
  cycleData: ICycle[] = [];
  airportData: FuelSupplierLocationProfilesModel[] = [];
  statusData: FuelSupplierLocationProfilesModel[] = [];
  intoPlaneAgentData: FuelSupplierLocationProfilesModel[] = [];

  showAirportList: boolean = false;
  selectedAirportList: FuelSupplierLocationProfilesModel[] = [];
  airportList: FuelSupplierLocationProfilesModel[] = [];
  filteredAirportList: Observable<FuelSupplierLocationProfilesModel[]>;
  selectedAirportCountText: string = '';

  showCountryList: boolean = false;
  selectedCountryList: FuelSupplierLocationProfilesModel[] = [];
  countryList: FuelSupplierLocationProfilesModel[] = [];
  filteredCountryList: Observable<FuelSupplierLocationProfilesModel[]>;
  selectedCountryCountText: string = '';

  showIntoPlaneAgentList: boolean = false;
  selectedIntoPlaneAgentList: FuelSupplierLocationProfilesModel[] = [];
  intoplaneagentList: FuelSupplierLocationProfilesModel[] = [];
  filteredIntoPlaneAgentList: Observable<FuelSupplierLocationProfilesModel[]>;
  selectedIntoPlaneAgentCountText: string = '';
  isActive: boolean = true;

  showStatusList: boolean = false;
  selectedStatusList: FuelSupplierLocationProfilesModel[] = [];
  statusList: FuelSupplierLocationProfilesModel[] = [];
  filteredStatusList: Observable<FuelSupplierLocationProfilesModel[]>;
  selectedStatusCountText: string = '';


  showCycleList: boolean = false;
  selectedCycleList: ICycle[] = [];
  cycleList: ICycle[] = [];
  filteredCycleList: Observable<ICycle[]>;
  selectedCycleCountText: string = '';
  sortedDataTotal: FuelSupplierLocationProfilesModel[] = [];


  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  isTotalCountChanged: boolean = false;
  monthlyTooltip: string = 'It Calculates Dates from 1st day of the Month to the Last day of the Month';
  thirtyDaysTooltip: string = 'It Calculates Dates from exactly 1 month before to 1 Month Later';


  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _fuelService: FuelService,
    private readonly _dialogService: DialogService, private _titleService: Title,
    @Optional() private readonly _dialogRef: MatDialogRef<LocationPreferencesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any, private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    //private readonly _dialogRef: MatDialogRef<MissingPricesAirportComponent>,
  ) {
    if (this.data) {
      this.isDialog = true;
      this.fuelSupplierGUID = data.fuelSupplierGUID;
      this.fuelSupplierName = data.fuelSupplierName;
      // this.icao = data.icao;
      // this.airportID = data.airportID;
    }

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToFuel = false;
    if (localStorage.getItem('up.fuel') == 'true') {
      this.grantAccessToFuel = true;
    }


    if (!this.grantAccessToFuel) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.errMsg = "";
    this.errMsg1 = "";
    this.msg = "";
    this.totalRecordsText = "";


    this.clientList = [];
    this.selectedClientList = [];

    this.sortedData = [];
    this.sortedData2 = [];
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate());

    if (!this.isDialog) {
      this._route.queryParams.subscribe(params => {
        this.fuelSupplierGUID = params["fsId"];
        // this.customerGUID = params["customerId"]; 
        //console.log("query param: "+params["pf"]); 
      });
    }

    this.initControls();

    this.clickSubmit();



    this.fs_select = 'default';
    this.showButton = true;
    this.isFileInputDisabled = false;

    this.getData2();

  }

  initControls() {
    this.location_preferences_dialog_form = this._formBuilder.group({


      document: [{ value: '', disabled: true }],
      fs_select: [this.fs_select],
      filename: [this.attachedfilename],
      includeCountryAll: [true],
      showAllDetails: [false],
      countrysearch: [''],
      airportSearch: [''],
      statussearch: [''],
      cyclesearch: [''],
      intoplaneagentsearch: [''],
      // includeDisabledRecords: [this.includeDisabledRecords]
    });
  }


  get f() { return this.location_preferences_dialog_form.controls; }



  sortByChange(e: any) {
    this.clickSubmit();
  }






  clickSubmit() {
    this.submitted = true;
    this.errMsg = "";
    this.getData2();
  }




  pageIndexChange(e: any) {
    this.clickSubmit();
  }



  // clickClose() {
  //   let response = new ReturnObjModel();
  //   response.refresh = false; 
  //   //  this._dialogRef.close();
  //   //this._dialogRef.close(this.isModified);
  // }


  showAllDetailsOnChange() {

    this.paginator.pageIndex = 0;
    this.getData2();
  }



  clickOpenInNew() {
    //  this._dialogRef.close(true);

    window.open('missing-prices/location-preferences?fsId=' + this.fuelSupplierGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber());
    // window.open('/ground-stops/trip-details-internal?customerId=' + custGUID +  + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');

  }


  impotFuelPriceLocationPreferences(isAllDetails: boolean) {
    let s = "";
    //  const dialogConfig = new MatDialogConfig();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    // this.fromCurrencyMain = true;
    // this.fromFuelPricingSummary = true;
    // dialogConfig.hasBackdrop = false;


    dialogConfig.data = {
      dialogTitle: s, fuelSupplierGUID: this.fuelSupplierGUID, isAllDetails: isAllDetails
    };


    const dialogRef = this._dialog.open(FuelPriceLocationUploadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      let a: any = result;
      this.getData2(true);
      this.isModified = false;

      this.isTotalCountChanged = true;
    });
    // this.getData(true);

  }


  getData2(isInit: boolean = false) {
    this.showSpin = true;
    let request = new FuelSupplierLocationProfilesModel();



    if (this.selectedStatusList.length == this.statusList.length) {
      request.selectedStatusIdList = "";
    }
    else {
      this.selectedStatusList = this.selectedStatusList.filter(x => x.statusID != 0);
      request.selectedStatusIdList = Array.prototype.map.call(this.selectedStatusList, s => s.statusID).toString();
    }


    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryIdList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryID != 0);
      request.selectedCountryIdList = Array.prototype.map.call(this.selectedCountryList, s => s.countryID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedAirportIdList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedAirportIdList = Array.prototype.map.call(this.selectedAirportList, s => s.airportID).toString();
    }



    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }


    if (this.selectedCycleList.length == this.cycleList.length) {
      request.selectedCycleIdList = "";
    }
    else {
      this.selectedCycleList = this.selectedCycleList.filter(x => x.cycleName != 'all');
      request.selectedCycleIdList = Array.prototype.map.call(this.selectedCycleList, s => 'is' + s.cycleName).toString();
    }


    request.isActive = this.isActive;
    // if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
    //   request.isActive = !this.f.includeDisabledRecords.value;
    // }
    request.fuelSupplierGUID = this.fuelSupplierGUID;


    if (this.paginator == undefined) {
      request.pageIndex = 0;
      request.pageSize = 25;
    }
    else {
      request.pageIndex = this.paginator.pageIndex;
      request.pageSize = this.paginator.pageSize;
    }

    // request.selectedCycleIdList = '';  //'IsDaily,IsMonthly';
    // request.selectedStatusIdList = '';  //'1,3';

    this._fuelService.getFuelSupplierLocationProfilesByConditions<ResponseModel<FuelSupplierLocationProfilesModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          if (response.model != null) {

            this.fuelSupplierLocationProfile = response.model;
            this.isModified = false;
            this.sortedData = this.fuelSupplierLocationProfile?.profileList?.slice();
            if (response.model.profileList.length > 0) {
              if (this.fuelSupplierLocationProfile.profileList != null) {
                this.totalRecords = this.fuelSupplierLocationProfile.profileList[0].totalRecords;
                this.totalRecordsText = this.totalRecords + " total records"
              }
            }

            this.countryData = this.fuelSupplierLocationProfile?.countryFilter?.slice();
            this.airportData = this.fuelSupplierLocationProfile?.airportFilter?.slice();
            this.intoPlaneAgentData = this.fuelSupplierLocationProfile?.intoPlaneAgentFilter?.slice();
            this.statusData = this.fuelSupplierLocationProfile?.statusList?.slice();

            this.cycleData = [];
            this.fuelSupplierLocationProfile?.cycleFilter?.forEach(c => this.cycleData.push({ cycleName: c, selected: false }));


          }
          else {
            this.sortedData = [];
          }

        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
      this.showSpin = false;
    });
  }


  checkValidCycle(element: FuelSupplierLocationProfilesModel){
    if(element.isDaily || element.isWeekly || element.isMonthly || element.isBiWeekly || element.isBiMonthly || element.is30Days) {}
    else{
      element.status = 'Imported';
      this.isModified = true;
    }
  }

  changeIsDailyValue(element: FuelSupplierLocationProfilesModel) {
    element.isModified = true;
    element.isWeekly = false;
    element.isMonthly = false;
    element.isBiWeekly = false;
    element.isBiMonthly = false; 
    element.is30Days = false; 
    element.status = 'Pending';
    this.isModified = true;

    element.fuelSupplierGUID = this.fuelSupplierGUID;

    let id = element.fuelSupplierLocationProfileGUID;
    let index = this.modifiedRecordsList.findIndex(mrl => mrl.fuelSupplierLocationProfileGUID == id);

    if (index == -1) {
      this.modifiedRecordsList.push(element);
    }
    else {
      this.modifiedRecordsList.splice(index, 1);
      this.modifiedRecordsList.push(element);

    }
    this.checkValidCycle(element);
  }

  changeIsWeeklyValue(element: FuelSupplierLocationProfilesModel) {
    element.isModified = true;
    element.isDaily = false;
    element.isMonthly = false;
    element.isBiWeekly = false;
    element.isBiMonthly = false; 
    element.is30Days = false; 
    element.status = 'Pending';
    this.isModified = true;
    element.fuelSupplierGUID = this.fuelSupplierGUID;
    let id = element.fuelSupplierLocationProfileGUID;
    let index = this.modifiedRecordsList.findIndex(mrl => mrl.fuelSupplierLocationProfileGUID == id);

    if (index == -1) {
      this.modifiedRecordsList.push(element);
    }
    else {
      this.modifiedRecordsList.splice(index, 1);
      this.modifiedRecordsList.push(element);

    }

    this.checkValidCycle(element);

  }

  changeIsMonthlyValue(element: FuelSupplierLocationProfilesModel) {
    element.isModified = true;
    element.isDaily = false;
    element.isWeekly = false;
    element.isBiWeekly = false;
    element.isBiMonthly = false; 
    element.is30Days = false; 
    element.status = 'Pending';
    this.isModified = true;
    element.fuelSupplierGUID = this.fuelSupplierGUID;
    let id = element.fuelSupplierLocationProfileGUID;
    let index = this.modifiedRecordsList.findIndex(mrl => mrl.fuelSupplierLocationProfileGUID == id);

    if (index == -1) {
      this.modifiedRecordsList.push(element);
    }
    else {
      this.modifiedRecordsList.splice(index, 1);
      this.modifiedRecordsList.push(element);

    }
    this.checkValidCycle(element);
  }

  changeIsBiMonthlyValue(element: FuelSupplierLocationProfilesModel) {
    element.isModified = true;
    element.isDaily = false;
    element.isWeekly = false;
    element.isBiWeekly = false;
    element.isMonthly = false; 
    element.is30Days = false; 
    element.status = 'Pending';
    this.isModified = true;
    element.fuelSupplierGUID =  this.fuelSupplierGUID;
    let id = element.fuelSupplierLocationProfileGUID;
    let index = this.modifiedRecordsList.findIndex(mrl => mrl.fuelSupplierLocationProfileGUID == id);

    if (index == -1) {
      this.modifiedRecordsList.push(element);
    }
    else{
      this.modifiedRecordsList.splice(index, 1 );
      this.modifiedRecordsList.push(element);

    }
    this.checkValidCycle(element);
  }


  changeIs30DaysValue(element: FuelSupplierLocationProfilesModel){
    element.isModified = true;
    element.isDaily = false;
    element.isWeekly = false; 
    element.isBiWeekly = false;
    element.isBiMonthly = false; 
    element.isMonthly = false; 
    element.status = 'Pending';
    this.isModified = true;
    element.fuelSupplierGUID = this.fuelSupplierGUID;
    let id = element.fuelSupplierLocationProfileGUID;
    let index = this.modifiedRecordsList.findIndex(mrl => mrl.fuelSupplierLocationProfileGUID == id);

    if (index == -1) {
      this.modifiedRecordsList.push(element);
    }
    else {
      this.modifiedRecordsList.splice(index, 1);
      this.modifiedRecordsList.push(element);

    }
    this.checkValidCycle(element);
  }

  changeisBiWeeklyValue(element: FuelSupplierLocationProfilesModel) {
    element.isModified = true;
    element.isDaily = false;
    element.isWeekly = false;
    element.isMonthly = false; 
    element.isBiMonthly = false; 
    element.is30Days = false; 
    element.fuelSupplierGUID =  this.fuelSupplierGUID;
    // element.isBiWeekly = false;
    element.status = 'Pending';
    this.isModified = true;
    let id = element.fuelSupplierLocationProfileGUID;
    let index = this.modifiedRecordsList.findIndex(mrl => mrl.fuelSupplierLocationProfileGUID == id);

    if (index == -1) {
      this.modifiedRecordsList.push(element);
    }
    else {
      this.modifiedRecordsList.splice(index, 1);
      this.modifiedRecordsList.push(element);

    }
    this.checkValidCycle(element);

  }

  changeCycleValue(element: FuelSupplierLocationProfilesModel) {
    element.isModified = true;
    element.status = 'Pending';
    this.isModified = true;
    element.fuelSupplierGUID = this.fuelSupplierGUID;
    let id = element.fuelSupplierLocationProfileGUID;
    let index = this.modifiedRecordsList.findIndex(mrl => mrl.fuelSupplierLocationProfileGUID == id);

    if (index == -1) {
      this.modifiedRecordsList.push(element);
    }
    else {
      this.modifiedRecordsList.splice(index, 1);
      this.modifiedRecordsList.push(element);

    }

  }

  exportToExcel() {

    let request = new FuelSupplierLocationProfilesModel();



    if (this.selectedStatusList.length == this.statusList.length) {
      request.selectedStatusIdList = "";
    }
    else {
      this.selectedStatusList = this.selectedStatusList.filter(x => x.statusID != 0);
      request.selectedStatusIdList = Array.prototype.map.call(this.selectedStatusList, s => s.statusID).toString();
    }


    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryIdList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryID != 0);
      request.selectedCountryIdList = Array.prototype.map.call(this.selectedCountryList, s => s.countryID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedAirportIdList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedAirportIdList = Array.prototype.map.call(this.selectedAirportList, s => s.airportID).toString();
    }



    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }


    if (this.selectedCycleList.length == this.cycleList.length) {
      request.selectedCycleIdList = "";
    }
    else {
      this.selectedCycleList = this.selectedCycleList.filter(x => x.cycleName != 'all');
      request.selectedCycleIdList = Array.prototype.map.call(this.selectedCycleList, s => 'is' + s.cycleName).toString();
    }


    request.isActive = this.isActive;
    // if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
    //   request.isActive = !this.f.includeDisabledRecords.value;
    // }
    request.fuelSupplierGUID = this.fuelSupplierGUID;


    request.pageIndex = 0;
    request.pageSize = this.totalRecords;


    // request.selectedCycleIdList = '';  //'IsDaily,IsMonthly';
    // request.selectedStatusIdList = '';  //'1,3';

    this._fuelService.getFuelSupplierLocationProfilesByConditions<ResponseModel<FuelSupplierLocationProfilesModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          if (response.model != null) {

            this.fuelSupplierLocationProfile = response.model;
            this.isModified = false;
            this.sortedDataTotal = this.fuelSupplierLocationProfile?.profileList?.slice();
            if (response.model.profileList.length > 0) {
              if (this.fuelSupplierLocationProfile.profileList != null) {
                // this.totalRecords = this.fuelSupplierLocationProfile.profileList[0].totalRecords;
                // this.totalRecordsText = this.totalRecords + " total records"
                let wb=new Workbook();
                let ws =wb.addWorksheet("Location Preferences")
                
                let header=[ 'CountryName', 'ICAO',   'IATA',  'FAAID',  'IntoPlaneAgent',  'IsDaily' ,'IsWeekly','IsBiWeekly' ,'IsMonthly' ,'IsBiMonthly', 'Is30Days', 'Status', 'IsActive' ]
                ws.addRow(header);
                this.sortedDataTotal.forEach( al => {
                  
                  ws.addRow([al.countryName, al.icao , al.iata, al.faaid,  al.intoPlaneAgent,   al.isDaily == true? 'x':'', al.isWeekly == true? 'x':'', al.isBiWeekly == true? 'x':'',
                  al.isMonthly == true? 'x':'', al.isBiMonthly == true? 'x':'', al.is30Days == true? 'x':'',  al.status, al.isActive == true? 'x':'' ]);
                } )
            
                wb.xlsx.writeBuffer().then((data)=>{
                  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                  saveAs(blob, "Location Preferences.xlsx");
                });
              }
            }



          }
          else {
            this.sortedData = [];
          }

        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
      this.showSpin = false;
    });


  }

  clickSave() {


    this._fuelService.updateFuelSupplierLocationProfiles<ResponseModel<boolean>>(this.modifiedRecordsList).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {


          this.isTotalCountChanged = true;

          this.isModified = false;
          this.showSuccessMsg = true;
          this.successMsg = "Fuel Supplier Location profiles Saved Successfully.";

          setTimeout(() => {
            this.showSuccessMsg = false;
            // this._dialogRef.close();
            this.getData2();
            this.modifiedRecordsList = [];
          }, 1000);


        }
        else {

          this.errMsg = "Failed to Save Fuel Supplier Location profiles ";

        }
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to Save Fuel Supplier Location profiles ";
        }
      }
      this.loading = false;
    });

  }

  clickClose() {
    this._dialogRef.close(this.isTotalCountChanged);

  }


  // airport
  clickAirportFilter() {
    this.getAirportList();
  }

  getAirportList() {

    this.hideAllFilters();
    this.showAirportList = true;
    // this.showFuelSupplierList = false; 
    // this.showIntoPlaneAgentList = false; 
    // this.showCountryList = false; 
    this.airportList = [];

    let a = new FuelSupplierLocationProfilesModel();

    this.airportData.forEach(x => {
      a = new FuelSupplierLocationProfilesModel();
      a.icao = x.icao;
      a.airportID = x.airportID;
      a.selected = false;
      if (this.selectedAirportList.length > 0) {
        let f: FuelSupplierLocationProfilesModel[];
        f = this.selectedAirportList.filter(y => y.airportID == x.airportID);
        if (f.length > 0) {
          a.selected = true;
        }
      }
      this.airportList.push(a);
    });
    this.airportList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.icao.localeCompare(b.icao));
    a = new FuelSupplierLocationProfilesModel();
    a.airportID = -1;
    a.icao = "Select All";
    a.selected = false;
    this.airportList.unshift(a);
    this.filteredAirportList = this.f.airportSearch.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.airportName)),
      map(airportName => (airportName ? this._filterAirport(airportName) : this.airportList.slice())),
    );
    this.setAirportList();
  }

  displayNameAirport(airport: FuelSupplierLocationProfilesModel): string {
    return airport && airport.icao ? airport.icao : '';
  }

  private _filterAirport(name: string): FuelSupplierLocationProfilesModel[] {
    const filterValue = name.toLowerCase();

    return this.airportList.filter(option => option.icao.toLowerCase().includes(filterValue));
  }

  checkAirportChange(e: any, item: FuelSupplierLocationProfilesModel) {
    if (item.airportID == -1) {
      this.airportList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.airportList.forEach(x => {
        if (item.airportID == x.airportID) {
          x.selected = e.checked;
        }
      });
    }

    this.setAirportList();
  }

  setAirportList() {
    this.selectedAirportList = this.airportList.filter(u => u.selected == true);
    this.selectedAirportCountText = "";
    if (this.airportList.length > 0) {
      if (this.selectedAirportList.length == this.airportList.length) {
        this.selectedAirportCountText = ": All";
      }
      else {
        if (this.selectedAirportList.length > 0)
          this.selectedAirportCountText = ": " + this.selectedAirportList.length.toString();
      }
    }
  }

  clickDoneAirport() {
    this.showAirportList = false;
    this.clickSubmit();
  }

  clickResetAirport() {
    this.airportList.forEach(x => {
      x.selected = false;
    });
    this.f.airportSearch.setValue("");
    this.setAirportList();
    this.clickSubmit();
  }
  // airport end


  //country
  clickCountryFilter() {
    this.getCountryList();
  }

  getCountryList() {

    this.hideAllFilters();
    this.showCountryList = true;
    // this.showIntoPlaneAgentList = false;
    // this.showAirportList = false;




    this.countryList = [];
    let c = new FuelSupplierLocationProfilesModel();

    this.countryData.forEach(x => {
      c = new FuelSupplierLocationProfilesModel();
      c.countryName = x.countryName
      // c.countryGUID = x.countryGUID;
      c.countryID = x.countryID;
      c.selected = false;
      if (this.selectedCountryList.length > 0) {
        let f: FuelSupplierLocationProfilesModel[];
        f = this.selectedCountryList.filter(y => y.countryID == x.countryID);
        //f = this.selectedCountryList.filter(y => y.countryGUID.toLowerCase() == x.countryGUID.toLowerCase());
        if (f.length > 0) {
          c.selected = true;
        }
      }
      this.countryList.push(c);
    });
    this.countryList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.countryName.localeCompare(b.countryName));
    c = new FuelSupplierLocationProfilesModel();
    c.countryID = 0;
    //c.countryGUID = "all";
    c.countryName = "Select All";
    c.selected = false;
    this.countryList.unshift(c);
    this.filteredCountryList = this.f.countrysearch.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.countryName)),
      map(countryName => (countryName ? this._filterCountry(countryName) : this.countryList.slice())),
    );
    this.setCountryList();
  }

  displayNameCountry(country: FuelSupplierLocationProfilesModel): string {
    return country && country.countryName ? country.countryName : '';
  }

  private _filterCountry(name: string): FuelSupplierLocationProfilesModel[] {
    const filterValue = name.toLowerCase();

    return this.countryList.filter(option => option.countryName.toLowerCase().includes(filterValue));
  }

  checkCountryChange(e: any, item: FuelSupplierLocationProfilesModel) {
    if (item.countryID == 0) {
      this.countryList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.countryList.forEach(x => {
        if (item.countryID == x.countryID) {
          x.selected = e.checked;
        }
      });
    }
    this.setCountryList();
  }

  setCountryList() {
    this.selectedCountryList = this.countryList.filter(u => u.selected == true);
    this.selectedCountryCountText = "";
    if (this.countryList.length > 0) {
      if (this.selectedCountryList.length == this.countryList.length) {
        this.selectedCountryCountText = ": All";
      }
      else {
        if (this.selectedCountryList.length > 0)
          this.selectedCountryCountText = ": " + this.selectedCountryList.length.toString();
      }
    }
  }

  clickDoneCountry() {
    this.showCountryList = false;
    this.clickSubmit();
  }

  clickResetCountry() {
    this.countryList.forEach(x => {
      x.selected = false;
    });
    this.f.countrysearch.setValue("");
    this.setCountryList();
    this.clickSubmit();
  }
  //country end


  // IntoPlaneAgent
  clickIntoPlaneAgentFilter() {
    this.getIntoPlaneAgentList();
  }

  getIntoPlaneAgentList() {

    this.hideAllFilters();
    this.showIntoPlaneAgentList = true;
    // this.showAirportList = false;
    // this.showCountryList = false;
    this.intoplaneagentList = [];

    let a = new FuelSupplierLocationProfilesModel();

    this.intoPlaneAgentData.forEach(x => {
      a = new FuelSupplierLocationProfilesModel();
      a.intoPlaneAgent = x.intoPlaneAgent;
      //a.fuelSupplierShortname = x.fuelSupplierShortname;
      // a.selected = false;
      if (this.selectedIntoPlaneAgentList.length > 0) {
        let f: FuelSupplierLocationProfilesModel[];
        f = this.selectedIntoPlaneAgentList.filter(y => y.intoPlaneAgent == x.intoPlaneAgent);
        if (f.length > 0) {
          a.selected = true;
        }
      }
      this.intoplaneagentList.push(a);
    });
    this.intoplaneagentList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.intoPlaneAgent.localeCompare(b.intoPlaneAgent));
    a = new FuelSupplierLocationProfilesModel();
    a.intoPlaneAgent = "";// -1;
    a.intoPlaneAgent = "Select All";
    a.selected = false;
    this.intoplaneagentList.unshift(a);
    this.filteredIntoPlaneAgentList = this.f.intoplaneagentsearch.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.intoPlaneAgent)),
      map(intoPlaneAgent => (intoPlaneAgent ? this._filterIntoPlaneAgent(intoPlaneAgent) : this.intoplaneagentList.slice())),
    );
    this.setIntoPlaneAgentList();
  }

  displayNameIntoPlaneAgent(intoplaneagent: FuelSupplierLocationProfilesModel): string {
    return intoplaneagent && intoplaneagent.intoPlaneAgent ? intoplaneagent.intoPlaneAgent : '';
  }

  private _filterIntoPlaneAgent(name: string): FuelSupplierLocationProfilesModel[] {
    const filterValue = name.toLowerCase();

    return this.intoplaneagentList.filter(option => option.intoPlaneAgent.toLowerCase().includes(filterValue));
  }

  checkIntoPlaneAgentChange(e: any, item: FuelSupplierLocationProfilesModel) {
    if (item.intoPlaneAgent == 'Select All') {
      this.intoplaneagentList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.intoplaneagentList.forEach(x => {
        if (item.intoPlaneAgent == x.intoPlaneAgent) {
          x.selected = e.checked;
        }
      });
    }

    this.setIntoPlaneAgentList();
  }

  setIntoPlaneAgentList() {
    this.selectedIntoPlaneAgentList = this.intoplaneagentList.filter(u => u.selected == true);
    this.selectedIntoPlaneAgentCountText = "";
    if (this.intoplaneagentList.length > 0) {
      if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
        this.selectedIntoPlaneAgentCountText = ": All";
      }
      else {
        if (this.selectedIntoPlaneAgentList.length > 0)
          this.selectedIntoPlaneAgentCountText = ": " + this.selectedIntoPlaneAgentList.length.toString();
      }
    }
  }

  clickDoneIntoPlaneAgent() {
    this.showIntoPlaneAgentList = false;
    this.clickSubmit();
  }

  clickResetIntoPlaneAgent() {
    this.intoplaneagentList.forEach(x => {
      x.selected = false;
    });
    this.f.intoplaneagentsearch.setValue("");
    this.setIntoPlaneAgentList();
    this.clickSubmit();
  }
  // IntoPlaneAgent end
  showDisableConfirmationAlert() {
    this.confirmDialogRef = this._dialogService.openConfirmDialog([' Are you Sure You Want To Disable All ' + this.totalRecords.toLocaleString('en-US') + ' Records ']);
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        //user clicked yes
        this.deactivateAllRecords();
      }
      else {
        //user clicked no

        // this._dialogRef.close(this.isModified);
      }
    });
  }

  deactivateAllRecords() {


    let request = new FuelSupplierLocationProfilesModel();



    if (this.selectedStatusList.length == this.statusList.length) {
      request.selectedStatusIdList = "";
    }
    else {
      this.selectedStatusList = this.selectedStatusList.filter(x => x.statusID != 0);
      request.selectedStatusIdList = Array.prototype.map.call(this.selectedStatusList, s => s.statusID).toString();
    }


    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryIdList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryID != 0);
      request.selectedCountryIdList = Array.prototype.map.call(this.selectedCountryList, s => s.countryID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedAirportIdList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedAirportIdList = Array.prototype.map.call(this.selectedAirportList, s => s.airportID).toString();
    }



    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }


    if (this.selectedCycleList.length == this.cycleList.length) {
      request.selectedCycleIdList = "";
    }
    else {
      this.selectedCycleList = this.selectedCycleList.filter(x => x.cycleName != 'all');
      request.selectedCycleIdList = Array.prototype.map.call(this.selectedCycleList, s => 'is' + s.cycleName).toString();
    }


    request.isActive = this.isActive;
    // if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
    //   request.isActive = !this.f.includeDisabledRecords.value;
    // }
    request.fuelSupplierGUID = this.fuelSupplierGUID;


    request.pageIndex = 0;
    request.pageSize = this.totalRecords;

    this._fuelService.deactivateAllLocationProfilesByFuelSupplierGUID<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {

          this.getData2();

          this.isTotalCountChanged = true;

        }
      }

    });

  }

  searchFilterChange(e: any) {
    this.sortedData = [];
    this.totalRecords = 0;
    switch (e.target.value) {
      case 'active':
        this.searchFiltervalue = 1;
        this.isActive = true;
        this.getData2();
        break;
      case 'disable':
        this.searchFiltervalue = 0;
        this.isActive = false;
        this.getData2();

        break;

        break;
      default:
        this.searchFiltervalue = 1;
        this.getData2();
        return;
    }
    this.clickReset();

  }




  //status
  clickStatusFilter() {
    this.getStatusList();
  }

  getStatusList() {

    this.hideAllFilters();
    this.showStatusList = true;
    // this.showIntoPlaneAgentList = false;
    // this.showAirportList = false;




    this.statusList = [];
    let c = new FuelSupplierLocationProfilesModel();

    this.statusData.forEach(x => {
      c = new FuelSupplierLocationProfilesModel();
      c.status = x.status
      // c.statusGUID = x.statusGUID;
      c.statusID = x.statusID;
      c.selected = false;
      if (this.selectedStatusList.length > 0) {
        let f: FuelSupplierLocationProfilesModel[];
        f = this.selectedStatusList.filter(y => y.statusID == x.statusID);
        //f = this.selectedStatusList.filter(y => y.statusGUID.toLowerCase() == x.statusGUID.toLowerCase());
        if (f.length > 0) {
          c.selected = true;
        }
      }
      this.statusList.push(c);
    });
    this.statusList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.status.localeCompare(b.status));
    c = new FuelSupplierLocationProfilesModel();
    c.statusID = 0;
    //c.statusGUID = "all";
    c.status = "Select All";
    c.selected = false;
    this.statusList.unshift(c);
    this.filteredStatusList = this.f.statussearch.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.status)),
      map(status => (status ? this._filterStatus(status) : this.statusList.slice())),
    );
    this.setStatusList();
  }

  displayNameStatus(status: FuelSupplierLocationProfilesModel): string {
    return status && status.status ? status.status : '';
  }

  private _filterStatus(name: string): FuelSupplierLocationProfilesModel[] {
    const filterValue = name.toLowerCase();

    return this.statusList.filter(option => option.status.toLowerCase().includes(filterValue));
  }

  checkStatusChange(e: any, item: FuelSupplierLocationProfilesModel) {
    if (item.statusID == 0) {
      this.statusList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.statusList.forEach(x => {
        if (item.statusID == x.statusID) {
          x.selected = e.checked;
        }
      });
    }
    this.setStatusList();
  }

  setStatusList() {
    this.selectedStatusList = this.statusList.filter(u => u.selected == true);
    this.selectedStatusCountText = "";
    if (this.statusList.length > 0) {
      if (this.selectedStatusList.length == this.statusList.length) {
        this.selectedStatusCountText = ": All";
      }
      else {
        if (this.selectedStatusList.length > 0)
          this.selectedStatusCountText = ": " + this.selectedStatusList.length.toString();
      }
    }
  }

  clickDoneStatus() {
    this.showStatusList = false;
    this.clickSubmit();
  }

  clickResetStatus() {
    this.statusList.forEach(x => {
      x.selected = false;
    });
    this.f.statussearch.setValue("");
    this.setStatusList();
    this.clickSubmit();
  }
  //status end



  //cycle
  clickCycleFilter() {
    this.getCycleList();
  }

  getCycleList() {
    this.hideAllFilters();
    this.showCycleList = true;
    // this.showIntoPlaneAgentList = false;
    // this.showAirportList = false;
    // this.showCountryList = false;
    // this.showStatusList = false;




    this.cycleList = [];
    let c: ICycle;  //= {} as ICycle;

    // let c:ICycle = {};
    // let c = {};

    this.cycleData.forEach(x => {
      c = {} as ICycle;
      c.cycleName = x.cycleName
      // c.cycleGUID = x.cycleGUID;
      // c.cycleID = x.cycleID;
      c.selected = false;
      if (this.selectedCycleList.length > 0) {
        let f;
        f = this.selectedCycleList.filter(y => y.cycleName == x.cycleName);
        //f = this.selectedCycleList.filter(y => y.cycleGUID.toLowerCase() == x.cycleGUID.toLowerCase());
        if (f.length > 0) {
          c.selected = true;
        }
      }
      this.cycleList.push(c);
    });
    this.cycleList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.cycleName.localeCompare(b.cycleName));
    // c = new FuelSupplierLocationProfilesModel();
    c = {} as ICycle;

    // c.cycleID = 0;
    //c.cycleGUID = "all";
    c.cycleName = "Select All";
    c.selected = false;
    this.cycleList.unshift(c);
    this.filteredCycleList = this.f.cyclesearch.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.cycleName)),
      map(cycleName => (cycleName ? this._filterCycle(cycleName) : this.cycleList.slice())),
    );
    this.setCycleList();
  }

  // displayNameCycle(cycle: FuelSupplierLocationProfilesModel): string {
  //   return cycle && cycle.cycleName ? cycle.cycleName : '';
  // }

  private _filterCycle(name: string): ICycle[] {
    const filterValue = name.toLowerCase();

    return this.cycleList.filter(option => option.cycleName.toLowerCase().includes(filterValue));
  }

  checkCycleChange(e: any, item: ICycle) {
    if (item.cycleName == 'Select All') {
      this.cycleList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.cycleList.forEach(x => {
        if (item.cycleName == x.cycleName) {
          x.selected = e.checked;
        }
      });
    }
    this.setCycleList();
  }

  setCycleList() {
    this.selectedCycleList = this.cycleList.filter(u => u.selected == true);
    this.selectedCycleCountText = "";
    if (this.cycleList.length > 0) {
      if (this.selectedCycleList.length == this.cycleList.length) {
        this.selectedCycleCountText = ": All";
      }
      else {
        if (this.selectedCycleList.length > 0)
          this.selectedCycleCountText = ": " + this.selectedCycleList.length.toString();
      }
    }
  }

  clickDoneCycle() {
    this.showCycleList = false;
    this.clickSubmit();
  }

  clickResetCycle() {
    this.cycleList.forEach(x => {
      x.selected = false;
    });
    this.f.cyclesearch.setValue("");
    this.setCycleList();
    this.clickSubmit();
  }
  //cycle end

  hideAllFilters() {
    this.showCycleList = false;
    this.showIntoPlaneAgentList = false;
    this.showAirportList = false;
    this.showCountryList = false;
    this.showStatusList = false;
  }



  clickReset() {
    this.hideAllFilters();

    this.includeDisabledRecords = false;
    this.selectedStatusList = [];
    this.selectedCycleList = [];
    this.selectedCountryList = [];
    this.selectedAirportList = [];
    this.selectedIntoPlaneAgentList = [];

    this.selectedStatusCountText = "";
    this.selectedCycleCountText = "";
    this.selectedCountryCountText = "";
    this.selectedAirportCountText = "";
    this.selectedIntoPlaneAgentCountText = "";
    this.sortedData = [];



    // this.getDataConditions = 'active';

    //this.clickSubmit();

    this.initControls();
    this.getData2();
  }

}



