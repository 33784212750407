<div *ngIf="ua==1 || ut=='i'">
  <div style="height:21.5em">
    <form *ngIf="user_permission_restrict_client_form" [formGroup]="user_permission_restrict_client_form" (ngSubmit)="clickSave()">
      <div style="font-size: small">
        <!--<div style="height: 3em; background-color:#f8f8f8;margin-left:-1.25em; margin-right: -1.25em; position: relative"><div style="margin-left:0.5em; position:absolute; bottom:0.25em; font-weight: bold">Restric Access</div></div>-->
        <div style="display: flex; margin-left:-1em; margin-top:1em">
          <div class="d-flex flex-column" style="font-size: medium; width:25em;  ">
            <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToPIIData" formControlName="grantAccessToPIIData" (change)="restrictPIIChange($event)">Grant Access To PII Data</mat-slide-toggle>
            <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToManageManifestProfiles" formControlName="grantAccessToManageManifestProfiles" (change)="restrictChange($event, '')">Grant Access To Manage Manifest Profiles</mat-slide-toggle>
            <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessCompanyProfiles" formControlName="grantAccessCompanyProfiles" (change)="restrictChange($event)">Grant Access Company Profiles</mat-slide-toggle>
            <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessAircraftProfiles" formControlName="grantAccessAircraftProfiles" (change)="restrictChange($event)">Grant Access Aircraft Profiles</mat-slide-toggle>
            <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessSubmitTripsandRequestQuotes" formControlName="grantAccessSubmitTripsandRequestQuotes" (change)="restrictChange($event)">Grant Access Submit Trips and Request Quotes</mat-slide-toggle>
            <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToMessages" formControlName="grantAccessToMessages" (change)="restrictMessageChange($event)">Grant Access To Messages and Inbox</mat-slide-toggle>
            <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToAllMessages" formControlName="grantAccessToAllMessages" (change)="restrictChange($event)">Grant Access To All Messages and Inbox</mat-slide-toggle>
            <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToManageCompanyEvents" formControlName="grantAccessToManageCompanyEvents" (change)="restrictChange($event)">Grant Access To Manage Company Events</mat-slide-toggle>
            <div style="display: flex; margin-left: 0.75em">
              <div><mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="grantAccessToAccountGrouping" formControlName="grantAccessToAccountGrouping" (change)="restrictAccountGroupingChange($event)">Grant Access To Account Grouping</mat-slide-toggle></div>
              <div>
                <div *ngIf="grantAccessToAccountGrouping" style="margin-top:0.5em">
                  <select *ngIf="f" formControlName="customer_group_select" style="font-size: small;min-width:10em;border-radius:0.25em; border-color:lightgrey; background-color: #eaf0fd;" [ngClass]="{ 'is-invalid': submitted && f.customer_group_select.errors, 'is-valid': !f.customer_group_select.errors}">
                    <option value="">Select a Group</option>
                    <option *ngFor="let grp of customerGroupList" [ngValue]="grp.customerGroupGUID">{{grp.customerGroupName}}</option>
                  </select>
                </div>
                <div *ngIf="submitted  && f.customer_group_select.errors" class="invalid-feedback" style="margin-top:-2em">
                  <div *ngIf="f.customer_group_select.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:16em">customer group is a required field.</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      
    </form>
  </div>
  <div style="padding-left: 0em; padding-bottom:0.5em; display:flex">
    <div style="margin-right:1em">
      <button mat-button class="btn-high" (click)="clickSave()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Save
      </button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white">&nbsp;Close&nbsp;</button>
    </div>
    <div *ngIf="showSuccessMsg" style="margin-top:-0.25em">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
    <div style="margin-left:0em; margin-top:0em;">
      <div *ngIf="errMsg!=''" style="background-color: #f9d2de; padding-left: 0.25em; width: 18em; font-size: small">{{errMsg}}</div>
    </div>
  </div>

</div>
