export class CustomerDocumentModel {
  customerGUID: string;
  customerDocumentGUID: string;
  documentTypeGUIDList: string;
  documentTypeList: string;
  vendorServiceDocumentTypeGUIDList: string;
  vendorServiceDocumentTypeList: string;
  commAddressType: string;
  displayDetails: string;
  documentName: string;
  fileExtension: string;
  effectiveDate: string;
  expirationDate: string;
  documentSizeInKB: number;
  fileSize: number;
  bFile: string;
  mimeType: string;
  hasDocument: boolean;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  filterBy: string;
  textSearch: string;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  dataEntryStatusID: number;
  customerName: string;
  isExpired: boolean;
}
