<div style="width: 40em; height:25em; padding-left:3em; padding-top:1.5em; padding-right:3em">
  <div style="padding-top:0.5em">
    <img src="../../assets/images/FlightProLogoWhiteBG.png" style="border:0em" />
  </div>

  <div style="padding-top:1em;text-align:left;">
    <h5>Warning</h5>
  </div>
  <div style="margin-top:-0.75em"><hr /></div>
  <div style="font-size:large; margin-top:1em">
    Your current session is about to expire. For security, this session automatically ends after {{maxTokenExpirationMinutes}}<!--60--> minutes of inactivity.
  </div>
  <div style="margin-top: 1em">
    Your current session will expire in {{expirationMinutesDisplay}} ...
  </div>
  <div style="text-align:center; padding-top:2em">
    <button class="btn btn-sm btn-secondary" (click)="clickLogout()">&nbsp;&nbsp;Log Out&nbsp;&nbsp;</button>&nbsp;
    <button class="btn btn-sm btn-primary" (click)="clickContinue()">&nbsp;Continue with Session&nbsp;</button>&nbsp;
  </div>
</div>
