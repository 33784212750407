import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Component, Inject, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { Observable, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { AirportModel } from '../models/airport.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { ResponseModel } from '../models/response.model';
import { TripModel } from '../models/trip.model';
import { AirportService } from '../services/airport.service';
import { AuthenticateService } from '../services/authenticate.service';
import { DialogService } from '../services/dialog.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { CONTAINER_DATA, GroundStopService } from '../services/ground-stop.service';
import { TripLegService } from '../services/trip-leg.service';
import { ContingencyStopComponent } from './contingency-stop.component';
import { ManageContingencyDialogComponent } from './manage-contingency-dialog.component';

export interface SelectedGroundStop {
  groundStop: GroundStopModel;
  isBackup: boolean;
  prevGroundStop: GroundStopModel;
  nextGroundStop: GroundStopModel;
} 

@Component({
  selector: 'app-contingency-edit-dialog',
  templateUrl: './contingency-edit-dialog.component.html',
  styleUrls: ['./contingency-edit-dialog.component.css']
})
export class ContingencyEditDialogComponent implements OnInit {

  aircraftGUID: string;
  @Input() tripCodeGUID: string;
  customerGUID: string;
  errMsg: string="";
  contingencyList: GroundStopModel[] = [];
  groundStopList: GroundStopModel[] = [];
  displayedColumn: string[] = ['icao', 'action'];
 tripCode: string;
  registration: string;
  showSpin: boolean = false;
  @Input() selectedGS: SelectedGroundStop;
  buttonText: string = "Select";
  showSuccessMsg: boolean = false;
  isNext: boolean = false;
  duplicateIATAErrMsg: string;
  wrongAirportErrMsg: string;
  airportList: AirportModel[];
  validatedAirport: boolean;
  isRepositioning: boolean = true;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  @ViewChild(MatTable) table: MatTable<any>;
  contingency_edit_form: UntypedFormGroup;
  insertedLegDepICAO: string = "";
  newICAO: string = "New ICAO";
  dataChanged: boolean = false;
  componentPortal: ComponentPortal<ManageContingencyDialogComponent>;
  submitted: boolean = false;
  loading: boolean = false;
  isModified: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<ContingencyEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any, @Inject(CONTAINER_DATA) public componentData: any, 
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _dialogService: DialogService,
    private readonly _groundStopService: GroundStopService, private readonly _airportService: AirportService, private readonly _tripLegService: TripLegService,
    private readonly _contingencyStop: ContingencyStopComponent, private _injector: Injector
  ) {
    this.aircraftGUID = _data.aircraftGUID;
    this.tripCodeGUID = componentData.tripCodeGUID;
    this.selectedGS = componentData.selectedGS;
    this.groundStopList = componentData.groundStopList;
    this.customerGUID = _data.customerGUID;
    this.tripCode = _data.tripCode;
    this.registration = _data.registration;
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.getData();
  }


  getData() {
    this.showSpin = true;
    //let request = new AircraftTripModel();
    //request.aircraftGUID = this.aircraftGUID;
    //request.customerGUID = this.customerGUID;
    //request.startDateText = localStorage.getItem('trip.sd');
    //request.endDateText = localStorage.getItem('trip.ed');
    //request.tripCodeGUID = this.tripCodeGUID;
    let request = new GroundStopModel;
    request.groundStopGUID = this.selectedGS.groundStop.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    this._groundStopService.getGroundStopBygsId<ResponseModel<GroundStopModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.contingencyList = response.model.contingencyStopList;//.groundStopList;
          if (this.contingencyList == null) {
            this.contingencyList = [];
            let c = new GroundStopModel();
            c.contingencyGroundStopGUID = this.selectedGS.groundStop.groundStopGUID;
            c.groundStopTypeGUID = GlobalConstant.contingencyGroundStopTypeGUID;
            c.icao = "";
            c.isRepositioning = this.selectedGS.isBackup;
            c.contingencySequence = 1;
            c.farTypeID = this.selectedGS.groundStop.farTypeID;
            this.contingencyList.push(c);
          }
          this.isRepositioning = this.contingencyList[0].isRepositioning;
          this.insertedLegDepICAO = this.contingencyList[0].icao;
          if (this.insertedLegDepICAO!="" && this.insertedLegDepICAO!=null)
            this.newICAO = this.insertedLegDepICAO;
          if (this.isRepositioning) {
            let c = new GroundStopModel();
            c = new GroundStopModel();
            c.contingencyGroundStopGUID = this.selectedGS.groundStop.groundStopGUID;
            c.groundStopTypeGUID = GlobalConstant.contingencyGroundStopTypeGUID;
            c.icao = this.selectedGS.groundStop.icao;
            c.isRepositioning = true;
            c.contingencySequence = 2;
            c.farTypeID = this.selectedGS.groundStop.farTypeID;
            this.contingencyList.push(c);
          }
          
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMsg = response.message;
        }

      }
      this.showSpin = false;
      this.initControls();
    });
  }

  initControls() {
    this.contingency_edit_form = this._formBuilder.group({
      insertedLegDepICAO: this.insertedLegDepICAO
    });
  }

  addContingencyStop(gs:GroundStopModel, isBefore:boolean) {
    let c = new GroundStopModel();
    c.contingencyGroundStopGUID = this.selectedGS.groundStop.groundStopGUID;
    c.isRepositioning = this.isRepositioning;
    c.farTypeID = this.selectedGS.groundStop.farTypeID;
    c.groundStopTypeGUID = GlobalConstant.contingencyGroundStopTypeGUID;
    if (isBefore)
      this.contingencyList.splice(gs.contingencySequence - 1, 0, c);
    else
      this.contingencyList.splice(gs.contingencySequence + 1, 0, c);
    let i = 1;
    this.contingencyList.forEach(x =>{
      x.contingencySequence = i;
      i++;
    })
    this.table.renderRows(); 
  }

  removeContingencyStop(gs: GroundStopModel) {
      this.contingencyList.splice(gs.contingencySequence - 1, 1);
    let i = 1;
    this.contingencyList.forEach(x => {
      x.contingencySequence = i;
      i++;
    })
    this.table.renderRows();
  }

  icaoChange(e: any, gs: GroundStopModel) {

    if (e.target.value != "") {
      if (gs == undefined)
        gs = this.contingencyList[0];
      this.validateAirport(e, gs).subscribe(res => {
        if ((res != null || res != undefined) && res == true) {
          this.dataChanged = true;
        }

      });

    }
  }

  validateAirport(e:any, gs:GroundStopModel): Observable<boolean> {

    return new Observable<boolean>(ob => {
      this._authService.updateAccessTime();
      this.duplicateIATAErrMsg = "";
      this.wrongAirportErrMsg = "";
      let request = new AirportModel();
      request.route = e.target.value;
      try {
        this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            this.airportList = response.model;
            if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
              this.airportList.forEach(x => {
                gs.icao = x.icao;
                gs.latitude = x.latitude;
                gs.longitude = x.longitude;
                this.f.insertedLegDepICAO.setValue(x.icao);
                 // this.getArrivalDateTime();

              });
              this.validatedAirport = false;

            }
            if (this.airportList[0].wrongAirportErrMsg != "") {
              this.wrongAirportErrMsg = "Invalid airport"; // + this.airportList[0].wrongAirportErrMsg;
            //  this.f.insertedLegDepICAO.setErrors({ wrongAirportValidate: true });

              this.validatedAirport = true;
            }
            if (this.airportList[0].duplicateIATAErrMsg != "") {

              this.duplicateIATAErrMsg = "Duplicated IATAs: " + this.airportList[0].duplicateIATAErrMsg + ". Please change to use ICAO.";
              //this.f.insertedLegDepICAO.setErrors({ duplicateIATAValidate: true });
              this.validatedAirport = true;
            }
            if (this.wrongAirportErrMsg != "" || this.duplicateIATAErrMsg != "") {
              ob.next(false);
            }
            else {
              ob.next(true);
            }
          }
          else {
            if (response.code == "401") {
              //this.errMsg = response.message
              this._authService.signOut();
            }
            else {
              ob.next(null);
            }
          }
        })
      }
      catch (error) {
        ob.next(null);
      }

    });

  }
  get f() { return this.contingency_edit_form.controls; }

  icaoKeyDown(e: any) {

    this.newICAO = this.f.insertedLegDepICAO.value;

  }

  clickBack() {
    this._contingencyStop.selectedPortal = this.componentPortal;

    
  
    this.componentPortal = new ComponentPortal(ManageContingencyDialogComponent, null, this.createInjector({
      customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID, tripCodeGUID: this.tripCodeGUID, registration: this.registration,
      tripCode: this.tripCode, groundStopGuid: this.selectedGS.groundStop.groundStopGUID, isNext: false, selectedGS: this.selectedGS
 }));
    this._contingencyStop.selectedPortal = this.componentPortal;
  
}


createInjector(dataToPass): PortalInjector {
  const injectorTokens = new WeakMap();
  injectorTokens.set(CONTAINER_DATA, dataToPass);
  return new PortalInjector(this._injector, injectorTokens);
}
  clickSave() {

    this._authService.updateAccessTime();
    this.errMsg = "";
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.contingency_edit_form.invalid) {
      return;
    }

    this.loading = true;
    
    this.contingencyList.forEach(x => {
      if (x.icao == "") {
        this.errMsg = "ICAO required";
      }
      else {
        x.icaO_IATA = x.icao;
        x.groundStopSequenceID = this.selectedGS.groundStop.groundStopSequenceID + x.contingencySequence;
        x.farTypeID = this.selectedGS.groundStop.departureFARTypeID;
        x.callSign = this.selectedGS.groundStop.departureCallSign;
      }

    });
    if (this.errMsg != "")
      return;

    let request = new TripModel();
    request.customerGUID = this.customerGUID;
    request.aircraftGUID = this.aircraftGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopList = this.contingencyList;
    this._tripLegService.insertGroundStopAirportList<ResponseModel<TripModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
          this.isModified = true;
          this.showSuccessMsg = true;
          this.dataChanged = false;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);

      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to insert business stop at this time. Please try later.";
        }
      }
      this.loading = false;
    });

  }
  clickClose() {
    if (this.dataChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes you just made before close the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(this.isModified);
        }
      });
    }
    else {
      this._dialogRef.close(this.isModified);
    }
  }


 }
  

