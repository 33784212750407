
export class CountryDocumentModel {
  countryID: number;
  countryDocumentGUID: string;
  documentName: string;
  documentDescription: string;
  fileExtension: string;
  fileSize: number;
  containerPath: string;
  containerName: string;
  bFile: string;
  mimeType: string;
  effectiveDate: string;
  expirationDate: string;
  doesNotExpire: boolean; 
  documentTypeGUIDList: string;
  documentTypeList: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: boolean;
  documentID: number;
  documentTypeID: number;
  showToClient: boolean;
  userType: string;
  isModified: boolean = false;
}
