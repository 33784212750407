import { Component, Input, OnInit } from '@angular/core';
import { BusinessRulesModel } from '../models/business-rules.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { BusinessRulesService } from '../services/business-rules-service';

@Component({
  selector: 'app-airport-business-rules',
  templateUrl: './airport-business-rules.component.html',
  styleUrls: ['./airport-business-rules.component.css']
})
export class AirportBusinessRulesComponent implements OnInit {
  @Input() icao: string;
  @Input() customerGuid: string;
  @Input() countryGUID: string;
  businessRulesList: BusinessRulesModel[];
  errMsg: string;
  displayedColumn: string[] = ['brID', 'customerName', 'registration', 'far_type', 'country', 'icao', 'service_class', 'service_type', 'vendorName'];

  constructor(private readonly _authService: AuthenticateService, private readonly _businessRulesService: BusinessRulesService) {

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (this.icao == "")
      return;
    let request = new BusinessRulesModel();
    request.effectiveDate = new Date().toUTCString();
    request.expirationDate = "";
    request.selectedICAOList = this.icao;
    request.selectedCountryGUIDList = this.countryGUID;
    request.selectedApprovalStatusIDList = '1';
    if (this.customerGuid != null)
      request.selectedCustomerGUIDList = "allclients," + this.customerGuid;
    this._businessRulesService.getBusinessRulesByConditions<ResponseModel<BusinessRulesModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.businessRulesList = response.model;
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
    });
  }

}
