import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ElementRef, Output, EventEmitter, TemplateRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';
import { AirportLocatorComponent } from '../airports/airport-locator.component';
import { ClientFeedbackComponent } from '../common-utility/client-feedback.component';
import { GroundStopCreatorClientDialogComponent } from '../ground-stops-client/ground-stop-creator-client-dialog.component';
import { DocumentDownloadsDialogComponent } from '../ground-stops/document-downloads-dialog.component';
import { GenerateMessageTemplateComponent } from '../ground-stops/generate-message-template.component';
import { GroundStopCreatorCityPairDialogComponent } from '../ground-stops/ground-stop-creator-citypair-dialog.component';
import { TripSummaryReportDialogComponent } from '../ground-stops/trip-summary-report-dialog.component';
import { EmailClientDialogComponent } from '../messages-client/email-client-dialog.component';
import { MenuModel } from "../models/menu.model";
import { TripModel } from '../models/trip.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MenuService } from '../services/menu.service';
import { VendorLocatorComponent } from '../vendors/vendor-locator.component';
import { NewFeaturesDialogComponent } from '../common-utility/new-features-dialog.component';
import { PrivacyPolicyDialogComponent } from '../common-utility/privacy-policy-dialog.component';
import { TermsOfUseDialogComponent } from '../common-utility/terms-of-use-dialog.component';
import { ContactUsComponent } from '../common-utility/contact-us.component';
import { SubmitCurrentEventComponent } from '../current-events/submit-current-event.component';
import { SignatureEditorComponent } from '../user-accounts/signature-editor.component';
import { TripPersonlistEditorClientDialogComponent } from '../trip-legs-client/trip-personlist-editor-client-dialog.component';


@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})

export class MenuItemComponent implements OnInit {
  @Input() items!: MenuModel[];
  @ViewChild('menu', { static: true }) menu!: TemplateRef<any>;

  constructor(private readonly _authService: AuthenticateService, public readonly _datepipe: DatePipe, private readonly _menuService: MenuService,
    private _router: Router, private readonly dialog: MatDialog, private _titleService: Title) {
  }
  ngOnInit() {
  }
  //menuSelected() {
  //  this.menu.expanded = !this.menu.expanded;
  //}


 
  hasChildren(item: MenuModel) {
    return item.items && item.items.length > 0;
  }

  clickRoute(e: any, item: MenuModel) {
    if (item.display == "Trip Schedules") {
      localStorage.removeItem('trip.sd');
      localStorage.removeItem('trip.ed');
      localStorage.removeItem('trip.tco');
      localStorage.removeItem('trip.idemo');
      localStorage.removeItem('trip.ord');
      localStorage.removeItem('trip.clist');
      localStorage.removeItem('trip.aclist');
      localStorage.removeItem('trip.aplist');
      localStorage.removeItem('trip.prole');
      localStorage.removeItem('trip.plist');
      localStorage.removeItem('trip.vlist');
      localStorage.removeItem('trip.gstlist');
      localStorage.removeItem('trip.cclist');
      localStorage.removeItem('trip.tclist');
      localStorage.removeItem('trip.filterTime');
    }

    switch (item.route) {
      case "dialog":
        this.openDialog();
        break;

      case "document-downloads-dialog":
        this.openDocumentDownloadsDialog();
        break;
      case "airport-locator-dialog":
        this.openAirportLocator();
        break;
      case "add-new-trip-dialog":
        this.openAddNewTripDialog();
        break;
      case "vendor-locator-dialog":
        this.openVendorLocator();
        break;
      case "trip-summary-report":
        this.openTripSummaryReport();
        break;
      case "trip-creator-client-dialog":
        this.openGroundStopCreatorClientDialog('');
        break;
      case "trip-creator-client-Estimate-dialog":
        this.openGroundStopCreatorClientDialog('e');
        break;
      case "client-feedback":
        this.openClientFeedback();
        break;
      case "email-client-dialog":
        this.openClientEmail();
        break;
      case "new-features":
        this.openNewFeatures();
        break;
      case "contact-us":
        this.openContactUs();
        break;
      case "submit-current-event":
        this.openSubmitCurrentEvent();
        break;
      case "dev-environment":
        this.openDevSite();
        break;
      default:
        //this._titleService.setTitle("FlightProConnect");
        this.gotoRoutePage(item.route, item.title);
        break
    }

  }

  openDialog() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.minWidth = "35em"
    config.restoreFocus = false;
    config.data = { gstId: "", gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(GenerateMessageTemplateComponent, config);
    // Manually restore focus to the menu trigger since the element that
    // opens the dialog won't be in the DOM any more when the dialog closes.
    // dialogRef.afterClosed().subscribe(() => this.menuTrigger.focus());
  }

  openDocumentDownloadsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "46em";//"1000px";
    //dialogConfig.minHeight = "39em";//"500px";
    dialogConfig.minHeight = "40em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { v: this._authService.getCurrentTimeNumber() };

    const dialogRef = this.dialog.open(DocumentDownloadsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  gotoRoutePage(routePage: string, display: string) {
    let navigationExtras: NavigationExtras;// = {
    //  queryParams: {
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    switch (display) {
      case "Register new User - Client":
        navigationExtras = {
          queryParams: {
            selectedUserType: 'customer',
            v: this._authService.getCurrentTimeNumber()
          }
        }
        break;

      case "Register new User - FPC Operations":
        navigationExtras = {
          queryParams: {
            selectedUserType: 'internal',
            v: this._authService.getCurrentTimeNumber()
          }
        }
        break;
      default:
        navigationExtras = {
          queryParams: {
            v: this._authService.getCurrentTimeNumber()
          }
        }
        break;

    }
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    this._router.navigate([routePage], navigationExtras);
  }

  openAirportLocator() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.minWidth = "35em"
    config.restoreFocus = false;
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(AirportLocatorComponent, config);
  }

  openAddNewTripDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: "", customerGUID: "", v: this._authService.getCurrentTimeNumber() };

    const dialogRef = this.dialog.open(GroundStopCreatorCityPairDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        let trip: TripModel;
        trip = new TripModel();
        trip.tripCodeGUID = result.newId;
        trip.aircraftGUID = result.aircraftGUID;
        trip.customerGUID = result.customerGUID;
        trip.tripCodeType = result.tripCodeType;
        this.clickOpenTripDetail(trip);

      }

    });
  }

  clickOpenTripDetail(p: TripModel) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "tripId": p.tripCodeGUID,
        "aircraftId": p.aircraftGUID,
        "customerId": p.customerGUID,
        "pf": "",
        tType: p.tripCodeType,
        v: this._authService.getCurrentTimeNumber()
      }
    };
    this._router.navigate(['/ground-stops/trip-details-internal'], navigationExtras);

  }

  openVendorLocator() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.minWidth = "35em"
    config.restoreFocus = false;
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(VendorLocatorComponent, config);
  }

  openTripSummaryReport() {

    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.restoreFocus = false;
    config.data = { tripCodeGUID: "" };
    this.dialog.open(TripSummaryReportDialogComponent, config);
  }

  openGroundStopCreatorClientDialog(tripType: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.minWidth = "46em";//"1000px";
    //dialogConfig.minHeight = "39em";//"500px";
    //dialogConfig.minHeight = "40em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { tripType: tripType, v: this._authService.getCurrentTimeNumber() };

    const dialogRef = this.dialog.open(GroundStopCreatorClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        let trip: TripModel;
        trip = new TripModel();
        trip.tripCodeGUID = result.newId;
        trip.aircraftGUID = result.aircraftGUID;
        trip.customerGUID = result.customerGUID;
        this.openTripPersonListEditorClient(trip.tripCodeGUID, trip.aircraftGUID, trip.customerGUID);
      }
    });
  }

  clickFeedback() {

  }

  openClientFeedback() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    this.dialog.open(ClientFeedbackComponent, config);
  }

  openClientEmail() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", groundStopGUID: "", groundStopTaskGUID: ""
    };

    const dialogRef = this.dialog.open(EmailClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });


  }


  openNewFeatures() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    this.dialog.open(NewFeaturesDialogComponent, config);
  }

  openPrivacy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    this.dialog.open(PrivacyPolicyDialogComponent, dialogConfig);

  }

  openTerm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    this.dialog.open(TermsOfUseDialogComponent, dialogConfig);

  }

  openContactUs() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    //dialogConfig.panelClass = "custom-dialog-container";

    dialogConfig.data = {
      isInternal: true
    };
    this.dialog.open(ContactUsComponent, dialogConfig);

  }

  openSubmitCurrentEvent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    this.dialog.open(SubmitCurrentEventComponent, config);
  }

  openDevSite() {
    window.open("https://fpconnectdev.azurewebsites.net", "_blank");
  }


  clickSignature() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", groundStopGUID: "", groundStopTaskGUID: ""
    };

    const dialogRef = this.dialog.open(SignatureEditorComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  openTripPersonListEditorClient(tripCodeGUID: string, aircraftGUID: string, customerGUID: string) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, tripCodeGUID: tripCodeGUID, aircraftGUID: aircraftGUID, customerGUID: customerGUID };

    const dialogRef = this.dialog.open(TripPersonlistEditorClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {

        let trip: TripModel;
        trip = new TripModel();
        trip.tripCodeGUID = result.newId;
        trip.aircraftGUID = result.aircraftGUID;
        trip.customerGUID = result.customerGUID;
        this.clickOpenTripDetailClient(trip);
        //this.getSearchResults();
      }

    });

  }

  clickOpenTripDetailClient(p: TripModel) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "tripId": p.tripCodeGUID,
        "aircraftId": p.aircraftGUID,
        "customerId": p.customerGUID,
        "pf": "",
        tType: p.tripCodeType,
        v: this._authService.getCurrentTimeNumber()
      }
    };
    this._router.navigate(['/ground-stops-client/trip-details-client'], navigationExtras);

  }
}
