import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { IdentityRequestModel } from '../models/identity-request.model';
import { Subscription } from 'rxjs';
import { ResponseModel } from '../models/response.model';
import { IdentityService } from '../services/identity.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit, AfterViewInit {
  reset_password_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  username: string;
  errMsg: string;
  msg: string;
  success: boolean = false;
  @ViewChild('usernameRef') usernameRef: ElementRef;
  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService) {

  }

  ngOnInit() {
    //this.username = this._route.snapshot.paramMap.get('username');
    this.username = localStorage.getItem('tun');
    this.reset_password_form = this.formBuilder.group({
      username: [this.username, Validators.required]
      
    });

    this.errMsg = "";
    this.msg = "";
  }

  ngAfterViewInit() {
    this.usernameRef.nativeElement.focus();
  }

  get f() { return this.reset_password_form.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.reset_password_form.invalid) {
      return;
    }
    this.loading = true;
    let request = new IdentityRequestModel();
    request.username = this.f.username.value;
    
    this._identityService.resetPassword<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {
          //if (response.message == "")
          //  this.msg = "We have sent temporary password to your email";
          //else
          this.msg = response.message;
          this.success = true;

        }
        else {
          this.errMsg = response.message;
        }
      }
      else {
        if (response.code == "401") {
          this.errMsg = response.message
        }
        else {
          this.errMsg = "Failed to reset password. Please try again later.";
        }
      }
      this.loading = false;
    })
    
  }

  //clickTestSendEmail() {
  //  this._identityService.testSendEmail<ResponseModel<boolean>>().subscribe(response => {
  //    if (response != null && response.code == "200") {
  //      if (response.model == true) {
  //        this.msg = response.message + "\nPlease click <a routerLink='' (click)='clickPassword()'>here</a> to go back to password page";
  //      }
  //      else {
  //        this.errMsg = response.message;
  //      }
  //    }
  //    else {
  //      if (response.code == "401") {
  //        this.errMsg = response.message
  //      }
  //      else {
  //        this.errMsg = "";
  //      }
  //    }
  //    this.loading = false;
  //  })
  //}

  clickPassword() {
    this._router.navigate(['/user-accounts/password', { username: this.f.username.value }]);
  }
  clickBackToSignIn() {
    this._router.navigate(['']);
  }

  openTerm() {
    window.open("../../assets/term.html", "_blank");
  }
}
