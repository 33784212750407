import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable } from 'rxjs';
import { CustomValidators } from '../common-utility/custom.validators';
import { AircraftRegulatoryServiceModel } from '../models/aircraft-regulatory-service.model';
import { GovernmentServiceTypeModel } from '../models/government-service-type.model';
import { RenewalStatusModel } from '../models/renewal-status.model';
import { ReportFrequecyModel } from '../models/report-frequency.model';
import { ResponseModel } from '../models/response.model';
import { VendorModel } from '../models/vendor.model';
import { AircraftService } from '../services/aircraft.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-aircraft-regulatory-services-edit-dialog',
  templateUrl: './aircraft-regulatory-services-edit-dialog.component.html',
  styleUrls: ['./aircraft-regulatory-services-edit-dialog.component.css']
})
/** aircraft-regulatory-services-edit-dialog component*/
export class AircraftRegulatoryServicesEditDialogComponent implements OnInit {
  aircraft_regulatory_services_edit_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() aircraftGuid: string;
  @Input() aircraftRegulatoryServiceGuid: string;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  governmentServiceList: GovernmentServiceTypeModel[];
  reportFrequencyList: ReportFrequecyModel[];
  renewalStatusList: RenewalStatusModel[];
  vendorList: VendorModel[];
  selectedGovernmentServiceTypeId: number;
  selectRenewalStatusId: number;
  authorizationNumber: string;
  isFPIArranged: number;
  obtainedByVendorGuid: string;
  remarks: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  filingRequired: number;
  processed_by: string;
  serviceDate: Date;
  effectiveDate: Date;
  expirationDate: Date;
  contactedDate: Date;
  dueDate: Date;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  isDisabled: boolean = false;
  userType: string;
  authNumber: string = "Authorization Number";
  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;
  @ViewChild('serviceDate') serviceDateRef: ElementRef;
  @ViewChild('contactedDate') contactedDateRef: ElementRef;
  @ViewChild('dueDate') dueDateRef: ElementRef;
  /** aircraft-regulatory-services-edit-dialog ctor */
  constructor(private readonly _dialogRef: MatDialogRef<AircraftRegulatoryServicesEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _aircraftService: AircraftService,
    private readonly _dialogService: DialogService
  ) {
    this.aircraftGuid = data.aircraftGuid;
    this.aircraftRegulatoryServiceGuid = data.aircraftRegulatoryServiceGuid;
    this.dialogTitle = data.dialogTitle

  }

  getAllData(): Observable<any[]> {
    let getGovernmentServiceTypeResponse = this._commonService.getGovernmentServiceList<ResponseModel<GovernmentServiceTypeModel[]>>(false);
    let getReportFrequencyTypeResponse = this._commonService.getReportFrequencyList<ResponseModel<ReportFrequecyModel[]>>();
    let getRenewalStatusTypeResponse = this._commonService.getRenewalStatusList<ResponseModel<RenewalStatusModel[]>>();
    let getVenderListResponse = this._commonService.getVendors<ResponseModel<VendorModel[]>>();
    if (this.aircraftRegulatoryServiceGuid != null && this.aircraftRegulatoryServiceGuid != "") {      
      let getAircraftRegualtoryResponse = this._aircraftService.getAircraftRegulatoryServiceByAircraftRegulatoryServiceGUID(this.aircraftRegulatoryServiceGuid);
      return forkJoin([getGovernmentServiceTypeResponse, getReportFrequencyTypeResponse, getRenewalStatusTypeResponse, getVenderListResponse, getAircraftRegualtoryResponse]);
    }
    else
      return forkJoin([getGovernmentServiceTypeResponse, getReportFrequencyTypeResponse, getRenewalStatusTypeResponse, getVenderListResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.isDisabled = true;
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this.errMsg = "";
    this.msg = "";
    this.selectedGovernmentServiceTypeId = null;
    this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.effectiveDate = null;
    this.expirationDate = null;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.governmentServiceList = responses[0].model;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.reportFrequencyList = responses[1].model;
          
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.renewalStatusList = responses[2].model;
          this.selectRenewalStatusId = 1;
       
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.vendorList = responses[3].model;
          this.vendorList = this.vendorList.filter(v => v.canObtainMEA == true);
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.aircraftRegulatoryServiceGuid != null && this.aircraftRegulatoryServiceGuid != "") {
        if (responses[4] != null) {
          if (responses[4].code == "200" && responses[4].message == "") {
            let aircraftReg = new AircraftRegulatoryServiceModel();
            aircraftReg = responses[4].model;
            this.selectedGovernmentServiceTypeId = aircraftReg.governmentServiceTypeID;
            this.selectRenewalStatusId = aircraftReg.renewalStatusID;
            if (aircraftReg.serviceDate != null && aircraftReg.serviceDate != "")
              this.serviceDate = new Date(aircraftReg.serviceDate);
            if (aircraftReg.effectiveDate != null && aircraftReg.effectiveDate != "")
              this.effectiveDate = new Date(aircraftReg.effectiveDate);
            if (aircraftReg.expirationDate != null && aircraftReg.expirationDate != "")
              this.expirationDate = new Date(aircraftReg.expirationDate);
            this.authorizationNumber = aircraftReg.authorizationNumber;
            if (aircraftReg.isFPIArranged == true)
              this.isFPIArranged = 1;
            else
              this.isFPIArranged = 0;
            this.obtainedByVendorGuid = aircraftReg.obtainedByVendorGuid;
            this.remarks = aircraftReg.remarks;
            this.modifiedBy = aircraftReg.modifiedBy;
            this.modifiedDate = aircraftReg.modifiedDate;
            if (aircraftReg.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
          
          }
          else {
            if (responses[4].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }

      this.initControls();
      this.typeChange();

      this.showSpin = false;


    });

  }

  get f() { return this.aircraft_regulatory_services_edit_form.controls; }


  initControls() {
    this.aircraft_regulatory_services_edit_form = this._formBuilder.group({
      gov_service_type: [{ value: this.selectedGovernmentServiceTypeId, disabled: this.isDisabled }, Validators.required],
      authorization_number: { value: this.authorizationNumber, disabled: this.isDisabled },
      arranged_by: [{ value: this.isFPIArranged, disabled: this.isDisabled }, Validators.required],
      effectiveDate: [{ value: this.effectiveDate, disabled: this.isDisabled }, Validators.required],
      expirationDate: [{ value: this.expirationDate, disabled: this.isDisabled }, Validators.required],
      serviceDate: { value: this.serviceDate, disabled: this.isDisabled },
      contactedDate: { value: this.contactedDate, disabled: this.isDisabled },
      dueDate: { value: this.dueDate, disabled: this.isDisabled },
      obtained_by: { value: this.obtainedByVendorGuid, disabled: this.isDisabled },
      renewal_status: [{ value: this.selectRenewalStatusId, disabled: this.isDisabled }, Validators.required],
      modifiedBy: { value: this.modifiedBy, disabled: this.isDisabled },
      modifiedDate: { value: this.modifiedDate, disabled: this.isDisabled },
      reg_status: { value: this.isActive, disabled: this.isDisabled },
      remarks: { value: this.remarks, disabled: this.isDisabled }
    });
  }

  typeChange() {
    this.f.authorization_number.clearValidators();
    this.f.obtained_by.clearValidators();
    if (!this.isDisabled)
      this.f.authorization_number.enable();
    this.f.authorization_number.setValue(this.authorizationNumber);
    switch (this.f.gov_service_type.value) {
      case 4: //Mexico MEA
        this.authNumber = "Authorization Number";
        this.f.authorization_number.setValue("Not Required")
        this.f.authorization_number.disable();
        this.f.obtained_by.setValidators(Validators.required);
        break;
      case 6: //TSA Waiver
        this.authNumber = "Waiver Number";
        this.f.authorization_number.setValidators(Validators.required);
        break;
      case 10: //CBP Decale
        this.authNumber = "CBP Decal";
        this.f.authorization_number.setValidators(Validators.required);
        break;
      default:
        this.authNumber = "Authorization Number";

    }
    this.aircraft_regulatory_services_edit_form.get("authorization_number").updateValueAndValidity();
    this.aircraft_regulatory_services_edit_form.get("obtained_by").updateValueAndValidity();
    
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";

    if (this.effectiveDateRef.nativeElement.value !== "") {
      if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.effectiveDate.setErrors({ isValidDate: f1 });

        }
      }
      else {
        this.f.effectiveDate.setErrors({ isValidDate: false });

      }
    }

    if (this.expirationDateRef.nativeElement.value !== "") {
      if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null) {
        let s2 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
        let f2 = CustomValidators.isValidDate(s2);
        if (!f2) {
          this.f.expirationDate.setErrors({ isValidDate: f2 });

        }
      }
      else {
        this.f.expirationDate.setErrors({ isValidDate: false });

      }
    }



    if (this.aircraft_regulatory_services_edit_form.invalid || this.errMsg != "") {
      return;
    }

    let obj = new AircraftRegulatoryServiceModel();
    obj.aircraftRegulatoryServiceGuid = null;
    if (this.aircraftRegulatoryServiceGuid != "" && this.aircraftRegulatoryServiceGuid != null)
      obj.aircraftRegulatoryServiceGuid = this.aircraftRegulatoryServiceGuid;
    obj.aircraftGuid = this.aircraftGuid;
    obj.governmentServiceTypeID = this.f.gov_service_type.value;
    obj.authorizationNumber = this.f.authorization_number.value;
    obj.effectiveDate = null;
    if (this.f.effectiveDate.value != null) {
      obj.effectiveDate = this.effectiveDateRef.nativeElement.value;
    }
    obj.expirationDate = null;
    if (this.f.expirationDate.value != null) {
      obj.expirationDate = this.expirationDateRef.nativeElement.value;
    }
    obj.serviceDate = null;
    if (this.f.serviceDate.value != null) {
      obj.serviceDate = this.serviceDateRef.nativeElement.value;
    }
    obj.contactedDate = null;
    if (this.f.contactedDate.value != null) {
      obj.contactedDate = this.contactedDateRef.nativeElement.value;
    }
    obj.dueDate = null;
    if (this.f.dueDate.value != null) {
      obj.dueDate = this.dueDateRef.nativeElement.value;
    }
    
    obj.obtainedByVendorGuid = this.f.obtained_by.value;
    obj.renewalStatusID = this.f.renewal_status.value;
    obj.remarks = this.f.remarks.value;
    if (this.f.arranged_by.value == "1")
      obj.isFPIArranged = true;
    else
      obj.isFPIArranged = false;
    
    if (this.f.reg_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;
   


    this._aircraftService.saveAircraftRegulatoryService<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.aircraftRegulatoryServiceGuid = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString().padStart(2, "0");
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this._dialogRef.close(this.isModified);
          }, 1000);
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");

        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

}
