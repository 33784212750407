<div style=" width: 70em; margin: -1em;">
  <!--background-color: #f8f8f8;-->
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left:1em;"><h5 class="mat-h2" style="margin-bottom: 0.2em;">{{dialogTitle}} Fuel Pricing  </h5></div>

    <div *ngIf="dialogTitle=='Edit'" style="margin-right: 1em; font-size: small;"> <i>fuelPricingId: {{fuelPricingId}}</i></div>
  </div>

  <div>
    <form *ngIf="fuel_pricing_edit" [formGroup]="fuel_pricing_edit" novalidate>
      <div style=" margin-top:0em; padding-left: 1em;padding-right:1em">
        <div style="background-color:white;padding:.5em; display: flex;" >
          <div>

          <table style="width: 38em;   " class="table">
            <tr>
              <td> <label style="font-size:small">Airport</label></td>
              <td>
                <div style="display:flex">
                  <div style="margin-top: -0.35em; display: flex; max-height: 1.5em; width: 5em;">
                    <input [readonly]="dialogTitle == 'Edit'" class="form-control form-control-sm inputBox" (keyup)="icaoValidation($event)" [ngClass]="dialogTitle != 'Edit'?'form-control form-control-sm inputBox':'inputreadonly'" required formControlName="icao" maxlength="4" style=" margin-right: 1em;" />
                    
                    <!-- <input  class="form-control form-control-sm inputBox" (keyup)="icaoValidation($event)"   required formControlName="icao" maxlength="4" style=" margin-right: 1em;" />  -->
                    <!--[ngClass]="{ 'is-invalid': submitted && f.airporticao.errors }"-->

 


                  </div>
                  <div *ngIf="!validateICAO && !isExistingPrice" class="alert alert-danger p-0 small" style="margin-top: -0.5em; height: 1.5em; width: 6em; margin-bottom: 0em; ">
                    <!--<div *ngIf="f.fuelsupplier.errors.required">Invalid</div>-->
                    <div>Invalid ICAO</div>
                  </div>
                  <div *ngIf="submitted && f.icao.errors" class="alert alert-danger p-0 small" style="margin-top: -0.5em; height: 1.5em; width: 6em; margin-bottom: 0em; ">
                    <div *ngIf="f.icao.errors.required">Required.</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <label style="font-size:small">Fuel Supplier</label>
              </td>
              <td>
                <div style="margin-top: -0.35em; text-align: left; width: 50%;">

                  <!-- <select [hidden]="isExistingPrice" [attr.disabled]="!isValidICAO ? '' : null" formControlName="fuelsupplier" [ngClass]="isValidICAO?'form-control form-control-sm dropdown':'inputinactive'" (change)="fuelsupplierChange()">-->
                  <select [attr.disabled]="!isValidICAO ? '' : null" formControlName="fuelsupplier" [ngClass]="isValidICAO?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputreadonly'" (change)="fuelsupplierChange(false)">
                    <option value="default">Select a Fuel Supplier</option>
                    <option *ngFor="let fuel of fuelSupplierList" [value]="fuel.fuelSupplierGUID">{{fuel.fuelSupplierName}}</option>
                  </select>
                  <!--   <input [readonly]="isExistingPrice" [hidden]="!isExistingPrice" class="form-control form-control-sm inputBox" [ngClass]="!isExistingPrice?'form-control form-control-sm inputBox':'inputreadonly'" formControlName="fuelsupplier1" maxlength="5" [value]="fuelSupplierShortname1" />-->
 
                  <div *ngIf="submitted && f.fuelsupplier.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.fuelsupplier.errors.required">Please Select</div>
                  </div>
                </div>

              </td>
            </tr>
            <tr>
              <td>
                <label style="font-size:small">Into Plane Agent</label>
              </td>
              <td>
                <div style="margin-top: -0.35em; width: 80%;">
                  <!--<select [hidden]="isExistingPrice" [attr.disabled]="!isValidICAO ? '' : null" formControlName="intoplaneagent" class="form-control form-control-sm dropdown">-->
                  <select style="width: 35em;"  [attr.disabled]="!isValidICAO ? '' : null" formControlName="intoplaneagent" [ngClass]="isValidICAO?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputreadonly'" (change)="intoplaneagentChange()">

                    <!--  <option *ngIf="isValidICAO" value="other">Other</option>-->
                    <!--<option value="other" (click)="isotherintoplaneagent = true">Other</option>-->
                    <option *ngFor="let ipa of intoplaneagentList" [value]="ipa.intoPlaneAgent">{{ ipa.intoPlaneAgent}}</option>
                  </select>
                
                  <!--  <input [hidden]="!isExistingPrice" class="form-control form-control-sm inputBox" [ngClass]="!isExistingPrice?'form-control form-control-sm inputBox':'inputreadonly'" formControlName="fuelsupplier" maxlength="5" [value]="fuelSupplierShortname" />-->

                  <!-- <input [hidden]="dialogTitle != 'Edit'" type="text" class="form-control form-control-sm inputBox" [ngClass]="dialogTitle != 'Edit'?'form-control form-control-sm inputBox':'inputreadonly'" formControlName="intoplaneagent" /> -->

                  <input [hidden]="!isotherintoplaneagent" type="text" class="form-control form-control-sm inputBox" [ngClass]="dialogTitle != 'Edit'?'form-control form-control-sm inputBox':'inputreadonly'" formControlName="otherintoplaneagent" style="margin-top:0.3em" />
                  <div *ngIf="submitted && f.intoplaneagent.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.intoplaneagent.errors.required">Required.</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td style="vertical-align: top;">
                <label style="font-size:small">Effective Date</label>
              </td>
              <td>
                <div style="margin-top: -0.35em; width: 50%;">
                  <input type="date" class="form-control form-control-sm inputBox" formControlName="effectivedate" />
                  <div *ngIf="submitted && f.effectivedate.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.effectivedate.errors.required">Required.</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td style="vertical-align: top;">
                <label style="font-size:small">Expiry Date</label>
              </td>
              <td>
                <div style="margin-top: -0.35em; width: 50%;">
                  <input type="date" class="form-control form-control-sm inputBox" formControlName="expirydate" />
                  <!--<div *ngIf="submitted && f.expirydate.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.expirydate.errors.required">Required.</div>
                  </div>-->
                      <div *ngIf="f.expirydate.errors && f.expirydate.errors.matEndDateInvalid" class="alert-danger p-0" style="font-size:small;">Expiration date cannot be before effective date.</div>

                </div>
              </td>
            </tr>
            <!--<tr  *ngIf="!isExistingPrice">
              <td style="vertical-align: top;">
                <label style="font-size:small"> Qty</label>
              </td>
              <td>
                <div style="margin-top: -0.35em;  ">
                  <table class="" formArrayName="quantities">                    
                    <tr *ngFor="let quantity of quantities().controls; let i=index" [formGroupName]="i">
                      <td>
                        <label style="font-size:small">Min</label>
                      </td>
                      <td>
                        <input type="text" formControlName="minqty" class="form-control form-control-sm inputBox" style="max-width: 4em;">
                        <div *ngIf="submitted && f.minqty.errors" class="alert alert-danger p-0 small">
                          <div *ngIf="f.minqty.errors.required">Required.</div>
                        </div>
                      </td>
                      <td>
                        <label style="font-size:small">Max</label>
                      </td>
                      <td>
                        <input type="text" formControlName="maxqty" class="form-control form-control-sm inputBox" style="max-width: 4em;">
                        <div *ngIf="submitted && f.maxqty.errors" class="alert alert-danger p-0 small">
                          <div *ngIf="f.maxqty.errors.required">Required.</div>
                        </div>
                      </td>
                      <td>
                        <button *ngIf="quantities().length > 1" (click)="removeQuantity(i)" class="btn-low"><span style="margin: -1em; font-size: 10px;" class="material-symbols-outlined">remove</span></button>
                        <button type="button" (click)="addQuantity()" class="btn-high"><span style="margin-right: 0.2em;font-size:10px;" class="material-symbols-outlined">add</span></button>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>-->
            <tr> <!--*ngIf="isExistingPrice">-->
              <td style="vertical-align: top;">
                <label style="font-size:small">Min Qty</label>
              </td>
              <td>
                <div style="margin-top: -0.35em; width: 50%;">
                  <input type="number" class="form-control form-control-sm inputBox" formControlName="minqty" />
                  <!--<div *ngIf="submitted && f.c.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.minqty.errors.required">Required.</div>
                  </div>-->
                </div>
              </td>
            </tr>
            <tr> <!--*ngIf="isExistingPrice">-->
              <td style="vertical-align: top;">
                <label style="font-size:small">Max Qty</label>
              </td>
              <td>
                <div style="margin-top: -0.35em; width: 50%;">
                  <input type="number" class="form-control form-control-sm inputBox"   formControlName="maxqty"    />  
                  <div *ngIf="submitted && f.maxqty?.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.maxqty.errors.required">Required.</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td style="vertical-align: top;">
                <label style="font-size:small">Currency</label>
              </td>
              <td>
                <div style="margin-top: -0.35em; width: 50%;">
                  <select *ngIf="f" formControlName="currency" class="form-control form-control-sm dropdown"  (change)="convertToUSD()">
                    <option *ngFor="let c of currencylist" [value]="c.currencyID">{{c.currencyID}}</option>
                  </select>
                </div>
              </td>
            </tr>
            <!-- <div style="display: contents;"> -->
              <tr>
                <td style="vertical-align: top;">
                  <label style="font-size:small">Unit of Measure</label>
                </td>
                <td>
                  <div style="margin-top: -0.35em; width: 50%;">
                    <select formControlName="unitofmeasure" class="form-control form-control-sm dropdown"  (change)="convertToUSD()">
                      <!--(change)="fuelSupplierChange($event)">-->
                      <!--<option value="default">Select a Fuel Supplier</option>-->
                      <option *ngFor="let uom of unitofmeasurelist" [value]="uom.lk_UnitOfMeasureID">{{uom.unitOfMeasure}}</option>
                    </select>
                    <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Please select
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="vertical-align: top;">
                  <label style="font-size:small">Base Fuel</label>
                </td>
                <td>
                  <div style="margin-top: -0.35em; width: 50%;">
                    <input type="number" class="form-control form-control-sm inputBox" formControlName="basefuel"  (input)="convertToUSD()" />
                    <!-- <div *ngIf="submitted && f.basefuel.errors" class="alert alert-danger p-0 small">
                      <div *ngIf="f.basefuel.errors.required">Required.</div>
                    </div> -->
                  </div>
                </td>
              
              </tr>
            <!-- </div> -->


            <tr>
              <td style="vertical-align: top;">
                <label style="font-size:small">Tax Included</label>
              </td>
              <td>
                <div style="margin-top: -0.35em; text-align: left">
                  <mat-slide-toggle style="transform:scale(.8); height:2em; " color="primary" [checked]="taxesincluded" formControlName="taxesincluded"></mat-slide-toggle>

                  <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    Tax Included is required.
                  </div>
                </div>
              </td>
            </tr>
           <!--  <tr>
              <td style="vertical-align: top;">
                <label style="font-size:small">Internal Notes</label>
              </td>
              <td>
                <div>
                  <textarea formControlName="internalnotes" class="form-control" maxlength=255 style="resize: none;   height: 5em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd"></textarea>

                </div>
              </td>
            </tr>
            <tr>
              <td style="vertical-align: top;">
                <label style="font-size:small">Supplier Notes</label>
              </td>
              <td>
                <div>
                  <textarea formControlName="clientnotes" class="form-control" maxlength=255 style="resize: none;   height: 5em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd"></textarea>

                </div>
              </td>
            </tr> -->
          </table>

            <div>
              <div style="display: flex;">
                <table *ngIf="showConvertor" class="tableConverter" style="font-size: small; margin-left: 6em; padding: 1em; width: 15em; height: 6.3em; text-align: center; ">
                  <tr style="background: #eaf0fd;"><td colspan="2"><b>Currency Converter</b></td></tr>
                  <tr><td> {{ originalPrice }}</td><td> <b>{{currencyconverter1}}</b></td></tr>
                  <tr><td>{{USDConversion  }}</td><td><b>{{currencyconverter2  }}</b></td></tr>
                </table>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div style="vertical-align: top;">
                <label style="font-size:small">Internal Notes</label>
              </div>
              <div>
                <div>
                  <textarea formControlName="internalnotes" class="form-control" 
                    style="resize: none;   height: 11em; width: 30em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd"></textarea>
          
                </div>
              </div>
            </div>
            <div>
              <div style="vertical-align: top;">
                <label style="font-size:small">Supplier Notes</label>
              </div>
              <div>
                <div>
                  <textarea formControlName="clientnotes" class="form-control" 
                    style="resize: none;   height: 23em; width: 30em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd"></textarea>
          
                </div>
              </div>
            </div>
          </div>
 

        </div>



        <div class="d-inline-flex mt-2 justify-content-between" style="width:100%;margin-bottom:-1em;">
          <div style="width:35%">
            <div style="background-color: white; padding: .3em 0em 0em .3em">
              <div class="col">
                <label style="font-size:small">Disable Record</label>
                <div style="margin-top: -0.35em; display: inline-block;">
                  <select formControlName="isActive" class="form-control form-control-sm dropdown" style="  margin-left: 0.4em ">
                    <option value="1">No</option>
                    <option value="0">Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-column">
            <div>
              <label for="modifiedBy" style="font-size:small">Modified By: {{modifiedBy}}</label>
            </div>
            <div style="margin-top:-.5em">
              <label for="modifiedDate" style="font-size:small">Modified Date: {{modifiedDate}}</label>
            </div>

          </div>
          <!--<div>
    <div *ngIf="isExistingPrice" style="margin-bottom: -1.5em;margin-top:.5em">
      <div style="background-color: white; padding: .3em 0em 0 .3em; display: inline-block; width: 34%; ">
        <div class="d-inline-flex">
          <div class="no-gutters">-->
          <!-- <div class="col">
    <label style="font-size:small">Fuel Pricing ID</label>
    <div style="margin-top: -0.35em; display: inline-block; width: 3.6em; margin-left: 0.6em;">

      <input class="form-control form-control-sm inputBox  inputreadonly" readonly formControlName="fuelPricingId" />
    </div>
  </div> -->
          <!--<div class="col">
          <label style="font-size:small">Disable Record</label>
          <div style="margin-top: -0.35em; display: inline-block;">
            <select formControlName="isActive" class="form-control form-control-sm dropdown" style="  margin-left: 0.4em ">
              <option value="1">No</option>
              <option value="0">Yes</option>
            </select>
          </div>
        </div>

      </div>
    </div>
  </div>


  <div class="flex-column">
    <div>
      <label for="modifiedBy" style="font-size:small">Modified By: {{modifiedBy}}</label>
    </div>
    <div style="margin-top:-.5em">
      <label for="modifiedDate" style="font-size:small">Modified Date: {{modifiedDate}}</label>
    </div>

  </div>-->
          <!--<table class="table" style="text-align: left; margin: 0em;">-->
          <!--*ngIf="showModified"-->
          <!--<tr>
      <td> <label for="modifiedBy" style="font-size:small">Modified By : </label></td>
      <td>
        <div style="margin-top:-0.5em">
          <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width: 8em; height: 1.5625em; font-size: small; padding: 0em; border: none; background-color: transparent;" />
        </div>
      </td>
    </tr>
    <tr>
      <td><label for="modifiedDate" style="font-size:small">Modified Date : </label></td>
      <td>
        <div style="margin-top:-0.5em">
          <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width: 8em; height: 1.5625em; font-size: small; padding: 0em; border: none; background-color: transparent;" />
        </div>
      </td>
    </tr>
  </table>-->
          <!--</div>
  </div>-->
       
        </div>

      </div>
    </form>

  </div>
  <div class="d-inline-flex">
    <div *ngIf="!ifsuccess" style="justify-content: flex-start; padding-left: 1em; padding-top: 0.5em; padding-bottom: 0.5em; min-width: 33%;">
      <button formcontrolname="savebutton" [disabled]="!controlValueChanged   ? 'disabled' : null" mat-button class="btn-high" (click)="clickSave()">
        <!--<span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">save</span>-->
        SAVE
      </button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()">
        <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">close</span>CLOSE
      </button>&nbsp;
      <button *ngIf="isExistingPrice" formcontrolname="duplicatebutton" mat-button class="btn-high" (click)="clickDuplicate()">
        <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">content_copy</span>Duplicate
      </button>&nbsp;      
      <button [disabled]="!isValidICAO" mat-button class="btn-med" (click)="addNewTax()">
        <!--<span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">save</span>-->
        Manage Taxes
      </button>
    </div>
    <div class="mt-2">
      <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display: inline-block; white-space: pre-wrap;">
        <label style="font-size: small; padding: 0em; margin: 0em;">{{errMsg}}</label>
      </div>
      <div style="font-size:small" *ngIf="showDuplicateText"><span>** Add more tiers for the same Client if needed</span></div>
      <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]="successMsg"></app-success-message>
      </div>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>





