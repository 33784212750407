<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle mat-dialog-title>
  <div class="d-inline-flex">
    Manage Profile Sharing
    <div *ngIf="customerGroup && customerGroup.length==1">
      <h5 style="color: #146ec3;margin-top:.25em;margin-left:.5em">{{customerGroup[0].customerGroupName}}</h5>
    </div>
  </div>
  </div>
<div mat-dialog-content style="background-color: white; margin-top:-.5em" class="mx-1 p-1">
  <mat-slide-toggle style="transform:scale(.8); margin-bottom:.5em" color="primary" (change)="toggleAll($event)" [checked]="allChecked">Select All</mat-slide-toggle>
  
  <ng-template ngFor let-cg [ngForOf]="customerGroup" let-i="index">
    <div>{{cg.customerGroupName}}</div>
    <div class="d-flex flex-column">
      <ng-template ngFor let-element [ngForOf]="cg.personAccountList" let-i="index">
        <mat-slide-toggle style="transform:scale(.8);margin-left:-2em" color="primary" (change)="updateAccountGroup($event, element)" [checked]="element.isActive">{{element.customerName}}<span *ngIf="element.customerType!='Active'"> - {{element.customerType}}</span></mat-slide-toggle>
      </ng-template>
    </div>

  </ng-template>

</div>
<div class="mt-2 d-inline-flex">
  <div>
    <button mat-button class="btn btn-high mr-1" (click)="clickSave()">Save</button>
    <button mat-button class="btn btn-low mr-1" [mat-dialog-close]>Close</button>
  </div>
    <app-success-message *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>

</div>
