import { Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { ApisCredentialsModel } from '../models/apis-credentials.model'; 
import { Dialog } from '@angular/cdk/dialog';
import { ApisAccountEditComponent } from '../apis/apis-account-edit.component';
import { TemplateModel } from '../models/template.model';
import { TemplateEditComponent } from './template-edit.component';
import { MaintenanceDashboardComponent } from '../common-utility/maintenance-dashboard.component';
import { GlobalSettingsDashboardComponent } from '../common-utility/global-settings-dashboard.component';

@Component({
    selector: 'app-template-dashboard',
  templateUrl: './template-dashboard.component.html',
  styleUrls: ['./template-dashboard.component.css']
})
/** airport-details component*/
export class TemplateDashboardComponent implements OnInit {
  isLoading: boolean;
  isDialog: boolean = false;
  errMessage: string = "";
  recordList: TemplateModel[];
  filteredRecordList: TemplateModel[];
  includeDisabledTemplate: boolean = false;
  grantAccessToAPIS: boolean = false;
  totalRecords: number = 0;

  isAdmin: boolean = false;

  displayedColumn: string[] = [  'templateName', 'services', 'multipleLegs','action'];
    /** airport-details ctor */
  constructor(@Optional() private readonly _dialogRef: MatDialogRef<TemplateDashboardComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) private data: any,private readonly _commonService: CommonService,@Optional() @Inject(MAT_DIALOG_DATA) private _data: any, 
  private readonly _authService: AuthenticateService,@Optional() private readonly _dialog: MatDialog) {
    

  }
    
  ngOnInit() {
    if(this._data) {
      this.isDialog = true;
    }

    this._authService.updateAccessTime();
    if (localStorage.getItem('up.msgTemplates') == 'true') {
      this.isAdmin = true;
    } 

    this.isLoading = true;
    this.getData();
  }

  getData() {
    let request = new TemplateModel();
    request.messageTemplateID = 0;
    this._commonService.getTemplateList<ResponseModel<TemplateModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.recordList = response.model;
          this.filteredRecordList = this.recordList.filter(x => x.isActive === !this.includeDisabledTemplate);
          this.totalRecords = this.filteredRecordList.length;
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMessage = "An error has occured."
        }
      }
      this.isLoading = false;
    });
   

  }

  clickRouteNew(route: string, display: string) {
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }

  clickClose(val) {
    this._dialogRef.close(val);
  }


  openGlobalSettingsDashboardComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.maxWidth = "92em";//"1000px";
    config.height = "41.5em";//"1000px";
    config.width = "45em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    config.position = { left: "10px", top: "140px" };
    if (this.isDialog) {
      this.clickClose(true);
    }
    this._dialog.open(GlobalSettingsDashboardComponent, config);
  }

  clickEdit(t: TemplateModel, e: any) {
    e.stopPropagation();
    let s = "";
    let messageTemplateID = 0;
    if (t == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      messageTemplateID = t.messageTemplateID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    // dialogConfig.disableClose = true;
    dialogConfig.panelClass="custom-dialog-container2"
    dialogConfig.data = { dialogTitle: s,  messageTemplateID :   messageTemplateID, templateList: this.recordList};
    const dialogRef = this._dialog.open(TemplateEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }

    });
  }
  
  includeDisabledTemplateChange(e: any) {
    if(e.checked) {
      this.includeDisabledTemplate = true;
      this.filteredRecordList = this.recordList.filter(x => x.isActive === false);
      this.totalRecords = this.filteredRecordList.length;
    }
    else {
      this.includeDisabledTemplate = false;
      this.filteredRecordList = this.recordList.filter(x => x.isActive === true);
      this.totalRecords = this.filteredRecordList.length;
    }
  }

}
