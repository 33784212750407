import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { ResponseModel } from '../models/response.model';
import { TripModel } from '../models/trip.model';
import { AirportService } from '../services/airport.service';
import { AuthenticateService } from '../services/authenticate.service';
import { DialogService } from '../services/dialog.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { CONTAINER_DATA, GroundStopService } from '../services/ground-stop.service';
import { TripLegService } from '../services/trip-leg.service';
import { ContingencyEditDialogComponent } from './contingency-edit-dialog.component';
import { ContingencyStopComponent } from './contingency-stop.component';

export interface SelectedGroundStop {
  groundStop: GroundStopModel;
  isBackup: boolean;
  prevGroundStop: GroundStopModel;
  nextGroundStop: GroundStopModel;
} 

@Component({
  selector: 'app-manage-contingency-dialog',
  templateUrl: './manage-contingency-dialog.component.html',
  styleUrls: ['./manage-contingency-dialog.component.css']
})
export class ManageContingencyDialogComponent implements OnInit {

  aircraftGUID: string;
  tripCodeGUID: string;
  customerGUID: string;
  errMsg: string="";
  groundStopList: GroundStopModel[] = [];
  activeGroundStopList: GroundStopModel[] = [];
  displayedColumn: string[] = ['select', 'icao', 'date_time', 'status', 'has_contigency'];
  tripCode: string;
  registration: string;
  showSpin: boolean = false;
  selectedGS: SelectedGroundStop;
  buttonText: string = "Select";
  showSuccessMsg: boolean = false;
  isNext: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  editPortal = new ComponentPortal(ContingencyEditDialogComponent);

  constructor(private readonly _dialogRef: MatDialogRef<ManageContingencyDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _dialogService: DialogService,
    private readonly _contingencyStop: ContingencyStopComponent, private readonly _groundStopClientService: GroundStopClientService, private _injector: Injector
    
  ) {
    this.aircraftGUID = _data.aircraftGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.customerGUID = _data.customerGUID;
    this.tripCode = _data.tripCode;
    this.registration = _data.registration;
    this.isNext = _data.isNext;
    this.selectedGS = _data.selectedGS;
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    
    this.getData();
  }


  getData() {
    this.showSpin = true;
    let request = new AircraftTripModel();
    request.aircraftGUID = this.aircraftGUID;
    request.customerGUID = this.customerGUID;
    request.startDateText = localStorage.getItem('trip.sd');
    request.endDateText = localStorage.getItem('trip.ed');
    request.tripCodeGUID = this.tripCodeGUID;
    this._groundStopClientService.getTripBytId<ResponseModel<TripModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.groundStopList = response.model.groundStopList;
          this.activeGroundStopList = response.model.activeGroundStopList.filter(x => x.groundStopTypeGUID.toUpperCase() !="9523BC15-7E5F-4022-B5C6-574F7EA63649");
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMsg = response.message;
        }

      }
      this.showSpin = false;
    });
  }

  updateSelection(e: any, item: GroundStopModel,i:number) {
    if (item.cancelled)
      this.buttonText = "Save";
    else
      this.buttonText = "Next";
    let isBackup: boolean = false;
    if (item.contingencyStopList !=null)
      isBackup = item.contingencyStopList[0].isRepositioning;
    let prevGS = null;
    let nextGS = null;
    if (i > 0) {
      //while (!isActive && i>-1) { --removed do to uncancelling on hold
      //  if (!this.groundStopList[i - 1].cancelled)
      //    prevGS = this.groundStopList[i - 1].groundStopGUID;
      //  i--;
      //}
      prevGS = this.activeGroundStopList[i - 1];
    }
    if (i < this.activeGroundStopList.length-1) {
      //while (!isActive && i < this.groundStopList.length) {--removed do to uncancelling on hold
      //  if (!this.groundStopList[i + 1].cancelled)
      //    nextGS = this.groundStopList[i + 1].groundStopGUID;
      //  i++;
      //}
      nextGS = this.activeGroundStopList[i + 1];
    }
    this.selectedGS = { groundStop: item, isBackup: isBackup, prevGroundStop:prevGS, nextGroundStop:nextGS }
    
  }

  clickSave() {
    if (this.selectedGS.groundStop.hasContingencyStops) {
      this.editPortal = new ComponentPortal(ContingencyEditDialogComponent, null, this.createInjector({ selectedGS: this.selectedGS, tripCodeGUID: this.tripCodeGUID, groundStopList:this.groundStopList }));
      this._contingencyStop.selectedPortal = this.editPortal;
    }
    else
      this._dialogService.openConfirmDialog("Choose contingency type", undefined, "Backup", "Repositioning","Manage Contingencies").afterClosed().subscribe(r => {
        if (r == undefined)
          r = false;
        this.selectedGS.isBackup = !r;
     


        this.editPortal = new ComponentPortal(ContingencyEditDialogComponent, null, this.createInjector({ selectedGS: this.selectedGS, tripCodeGUID: this.tripCodeGUID, groundStopList: this.groundStopList }));
        this._contingencyStop.selectedPortal = this.editPortal;
      });
  }


  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

 }

   
  
  

