<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-1em" mat-dialog-title>Company Events</div>
<app-spin *ngIf="showSpin"></app-spin>

<div class="small position-relative" *ngIf="companyEvent!=null" style="background-color: white; font-size: small; padding: 1em; margin-top: -1em; min-width: 30em; width: 70em; max-height: 45em; overflow-y: auto ">
  <div>
    <div *ngIf="companyEvent.documentWeblink==null || companyEvent.documentWeblink==''">
      <h6>{{companyEvent.companyEventTitle}}</h6>
    </div>
    <div *ngIf="companyEvent.documentWeblink!=null && companyEvent.documentWeblink!=''">
      <h6><span class="a1" (click)="openLink(companyEvent.documentWeblink)">{{companyEvent.companyEventTitle}}</span></h6>
    </div>

  </div>
  <div>
    <div>
      {{companyEvent.effectiveDate}}<span *ngIf="companyEvent.expirationDate!=null && companyEvent.expirationDate!= companyEvent.effectiveDate">
        - {{companyEvent.expirationDate}}
      </span>
    </div>

    <div *ngIf="companyEvent.validTimeStart!=null">
      {{companyEvent.validTimeStart}}-{{companyEvent.validTimeEnd}} {{companyEvent.isUTC? 'UTC':'Local'}}
    </div>
  </div>

  <!--<div class="mt-2">
    <div style="white-space:pre-wrap">
      {{companyEvent.companyEventStory}}
    </div>
  </div>-->

  <ckeditor #notesCKEditor [editor]="Editor" [config]="CKEConfig" (ready)="onReady($event)" [disabled]="isDisabled"></ckeditor>
  <div *ngIf="companyEvent.contentDocuments && companyEvent.contentDocuments.length>0" class="mt-2 p-1 rounded" style="background-color:#f8f8f8 ;width:53em;overflow-y:auto;max-height:6.75em">
    <div>
      Attachments:
    </div>
    <ng-template ngFor let-doc [ngForOf]="companyEvent.contentDocuments">
      <div><a class="a1" (click)="clickPreviewDoc($event, doc)">{{doc.documentName}}</a></div>
    </ng-template>
  </div>

</div>
<div class="mt-2">
  <button mat-button mat-dialog-close (click)="clickClose()" class="btn-low">Close</button>
</div>
