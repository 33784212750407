export class SignInModel {
  username: string;
  login: boolean;
  customerName: string;
  userType: string;
  pwdExpirationDate: string;
  isAdmin: boolean;
  forceChangePwd: string;
  firstName: string;
  isA: string;
  isAB: string;
  //constructor(u: string, l: boolean) {
  //  this.username = u;
  //  this.login = l;
  //}
}
