import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { DistanceTimeModel } from '../models/distance-time.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { GroundStopService } from '../services/ground-stop.service';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { TripLegModel } from '../models/trip-leg.model';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { TripLegService } from '../services/trip-leg.service';

@Component({
  selector: 'app-leg-insert-dialog',
  templateUrl: './leg-insert-dialog.component.html',
  styleUrls: ['./leg-insert-dialog.component.css']
})

export class LegInsertDialogComponent implements OnInit, AfterViewInit {

  dialogTitle: string;
  customerGUID: string;
  aircraftGUID: string;
  tripCodeGUID: string;
  priorGroundStopGUID: string;
  groundStopGUID: string;
  nextArrivalGroundStopGUID: string;
  nextGroundStopGUID: string;
  leg_insert_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  duplicateIATAErrMsg: string;
  wrongAirportErrMsg: string;
  newICAO: string = "New ICAO";
  showSpin: boolean = false;

  isModified: boolean = false;
  loadingAirport: boolean = false;

  airportList: AirportModel[];
  validatedAirport: boolean = false;

  showSuccessMsg: boolean = false;
  latitude: number;
  longitude: number;
  arrivalDateUTC_DTType: Date;
  arrivalDateUTC: string;
  arrivalTimeUTC: string;
  arrivalDateLocal_DTType: Date;
  arrivalDateLocal: string;
  arrivalTimeLocal: string;
  ete: string;
  @ViewChild('insertedLegDepICAO', { static: false }) insertedLegDepICAORef: ElementRef;
  upPII: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";
  //legList: TripLegModel[] = [];
  //existingLegList: TripLegModel[] = [];
  dataChanged: boolean;
  insertedLegDepICAO: string = "";
  currentLeg: TripLegModel;
  priorLeg: TripLegModel;
  nextLeg: TripLegModel;
  legList: TripLegModel[];
  currentLegNum: number = 0;
  speed: number;
  insertType: string;
  constructor(private readonly _dialogRef: MatDialogRef<LegInsertDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _airportService: AirportService, private readonly _groundStopService: GroundStopService,
    private readonly _groundStopClientService: GroundStopClientService, private readonly _dialogService: DialogService,
    private readonly _tripLegService: TripLegService
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }
    this.legList = _data.legList;
    this.currentLegNum = _data.currentLeg;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.priorGroundStopGUID = _data.previousGroundStopGUID;
    this.groundStopGUID = _data.groundStopGUID;
    this.nextArrivalGroundStopGUID = _data.nextArrivalGroundStopGUID;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.aircraftGUID = _data.aircraftGUID;
    this.dialogTitle = _data.dialogTitle;
    this.speed = _data.speed;
    this.insertType = _data.insertType;

  }

  getAllData(): Observable<any[]> {
    let req = new TripLegModel();
    //req.groundStopGUID = this.priorGroundStopGUID;
    //req.nextArrivalGroundStopGUID = this.groundStopGUID;
    //req.aircraftGUID = this.aircraftGUID;
    //let priorLegResponse;
    //if (this.priorGroundStopGUID != "")
    //  priorLegResponse = this._tripLegService.getTripLegBydgIdagId(req);
    //else
    //  priorLegResponse = of(null);
    //req = new TripLegModel();
    req.groundStopGUID = this.groundStopGUID;
    req.nextArrivalGroundStopGUID = this.nextArrivalGroundStopGUID;
    req.aircraftGUID = this.aircraftGUID;
    let legResponse = this._tripLegService.getTripLegBydgIdagId(req);
    req = new TripLegModel();
    req.groundStopGUID = this.nextArrivalGroundStopGUID;
    req.nextArrivalGroundStopGUID = this.nextGroundStopGUID;
    req.aircraftGUID = this.aircraftGUID;
    let nextLegResponse;
    if (this.nextGroundStopGUID != "")
      nextLegResponse = this._tripLegService.getTripLegBydgIdagId(req);
    else
      nextLegResponse = of(null);
    return forkJoin([legResponse, nextLegResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }

    this.duplicateIATAErrMsg = "";
    this.wrongAirportErrMsg = "";
    this.airportList = [];
    this.validatedAirport = false;
    this.showSuccessMsg = false;
    if (this.insertType!="before")
      this.priorLeg = this.legList.filter(x => x.displayTripLegOrder == this.currentLegNum-1)[0];
    if (this.insertType != "after")
      this.nextLeg = this.legList.filter(x => x.displayTripLegOrder == this.currentLegNum + 1)[0];
    this.currentLeg = this.legList.filter(x=>x.displayTripLegOrder==this.currentLegNum)[0];
    //this.getAllData().subscribe(responses => {
    //  //if (responses[0] != null) {
    //  //  if (responses[0].code == "200" && responses[0].model != null) {
    //  //    this.priorLeg = new TripLegModel();
    //  //    this.priorLeg = responses[0].model;

    //  //  }
    //  //  else {
    //  //    if (responses[0].code == "401") {
    //  //      this._authService.signOut();
    //  //    }
    //  //  }
    //  //}

    //  if (responses[0] != null) {
    //    if (responses[0].code == "200" && responses[0].model != null) {
    //      this.currentLeg = new TripLegModel();
    //      this.currentLeg = responses[0].model;

    //    }
    //    else {
    //      if (responses[0].code == "401") {
    //        this._authService.signOut();
    //      }
    //    }
    //  }

    //  if (responses[1] != null) {
    //    if (responses[1].code == "200" && responses[1].model != null) {
    //      this.nextLeg = new TripLegModel();
    //      this.nextLeg = responses[1].model;

    //    }
    //    else {
    //      if (responses[1].code == "401") {
    //        this._authService.signOut();
    //      }
    //    }
    //  }

    this.initControls();
    this.showSpin = false;

    //});


  }

  initControls() {
    this.leg_insert_form = this._formBuilder.group({
      insertedLegDepICAO: [this.insertedLegDepICAO, Validators.compose([
        Validators.required,
        Validators.pattern("^[-a-zA-Z0-9\*]*$")]
      )],
    });


  }

  get f() { return this.leg_insert_form.controls; }

  ngAfterViewInit() {
    setTimeout(() => {
      this.insertedLegDepICAORef.nativeElement.focus();
    }, 500);
  }

  icaoChange(e: any) {

    if (e.target.value != "") {

      this.validateAirport().subscribe(res => {
        if ((res != null || res != undefined) && res == true) {
          this.dataChanged = true;          
        }

      });

    }
  }

  validateAirport(): Observable<boolean> {

    return new Observable<boolean>(ob => {
      this._authService.updateAccessTime();
      this.duplicateIATAErrMsg = "";
      this.wrongAirportErrMsg = "";
      let request = new AirportModel();
      request.route = this.f.insertedLegDepICAO.value;
      try {
        this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            this.airportList = response.model;
            if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
              this.airportList.forEach(x => {
                this.f.insertedLegDepICAO.setValue(x.icao);
                this.insertedLegDepICAO = x.icao;
                this.newICAO = x.icao;
                this.latitude = x.latitude;
                this.longitude = x.longitude;
                if (this.currentLegNum < this.legList.length && this.currentLegNum > -1)
                  this.getArrivalDateTime();

              });
              this.validatedAirport = false;

            }
            if (this.airportList[0].wrongAirportErrMsg != "") {
              this.wrongAirportErrMsg = "Invalid airport"; // + this.airportList[0].wrongAirportErrMsg;
              this.f.insertedLegDepICAO.setErrors({ wrongAirportValidate: true });

              this.validatedAirport = true;
            }
            if (this.airportList[0].duplicateIATAErrMsg != "") {

              this.duplicateIATAErrMsg = "Duplicated IATAs: " + this.airportList[0].duplicateIATAErrMsg + ". Please change to use ICAO.";
              this.f.insertedLegDepICAO.setErrors({ duplicateIATAValidate: true });
              this.validatedAirport = true;
            }
            if (this.wrongAirportErrMsg != "" || this.duplicateIATAErrMsg != "") {
              ob.next(false);
            }
            else {
              ob.next(true);
            }
          }
          else {
            if (response.code == "401") {
              //this.errMsg = response.message
              this._authService.signOut();
            }
            else {
              ob.next(null);
            }
          }
        })
      }
      catch (error) {
        ob.next(null);
      }

    });

  }

  clickSave() {
    if (this.arrivalDateUTC==null || this.arrivalDateUTC=='' || this.arrivalDateUTC==undefined)
      this.validateAirport().subscribe(res => {
        if ((res != null || res != undefined) && res == true) {
          this.dataChanged = true;
          this.updateData();
        }

      }); 
    else {
      this.updateData();
      }
  }

  updateData() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.leg_insert_form.invalid) {
      return;
    }

    this.loading = true;
    
    let obj = new GroundStopModel();
    obj.customerGUID = this.customerGUID;
    obj.aircraftGUID = this.aircraftGUID;
    obj.tripCodeGUID = this.tripCodeGUID;
    obj.icaO_IATA = this.f.insertedLegDepICAO.value
    //if (this.currentLegNum == -1) {
    if (this.insertType == "before") {
      obj.groundStopSequenceID = this.currentLeg.groundStopSequenceID;
      obj.farTypeID = this.currentLeg.departureFARTypeID;
      obj.callSign = this.currentLeg.departureCallSign;
      obj.nextGroundStopGUID = this.currentLeg.groundStopGUID;
      obj.groundStopTypeGUID = this.currentLeg.departureGroundStopTypeGUID;
    }
    else {

      if (this.insertType == "between") {
      //if ((this.currentLegNum > -1 && this.currentLegNum < this.legList.length) || this.currentLegNum == 0) {
        obj.groundStopSequenceID = this.currentLeg.groundStopSequenceID + 1;
        //if (this.currentLegNum == 0 || this.currentLegNum == this.legList.length - 1)
        obj.nextGroundStopGUID = this.currentLeg.nextArrivalGroundStopGUID;
        //else
        //obj.nextGroundStopGUID = this.nextLeg.groundStopGUID;
        obj.prevGroundStopGUID = this.currentLeg.groundStopGUID;
        obj.groundStopTypeGUID = this.currentLeg.departureGroundStopTypeGUID;
        obj.arrivalDateUTC = this.arrivalDateUTC_DTType;
        obj.arrivalTimeUTC = this.arrivalTimeUTC;
        obj.ete = this.ete;
        obj.farTypeID = this.currentLeg.departureFARTypeID;
        obj.callSign = this.currentLeg.departureCallSign;
      }
      else { 
        obj.groundStopSequenceID = this.currentLeg.nextArrivalGroundStopSequenceID + 1;
        obj.farTypeID = this.currentLeg.departureFARTypeID;
        obj.callSign = this.currentLeg.departureCallSign;
        obj.prevGroundStopGUID = this.currentLeg.nextArrivalGroundStopGUID;
        obj.groundStopTypeGUID = this.currentLeg.departureGroundStopTypeGUID;
       
      }

    }





    this._tripLegService.insertGroundStopAirport<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.isModified = true;
          this.showSuccessMsg = true;
          this.dataChanged = false;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);

        }
        else {
          this.errMsg = "Failed to insert business stop at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to insert business stop at this time. Please try later.";
        }
      }
      this.loading = false;
    });

  }
  getAirportUTCTimeConversion(): Observable<boolean> {
    return new Observable<boolean>(ob => {
      let f: boolean = false;
      let request = new AirportModel();
      request.icao = this.newICAO;
      request.airportUTCDateTime = this.arrivalDateUTC;
      if (this.arrivalTimeUTC != "") {
        request.airportUTCDateTime += " " + this.arrivalTimeUTC;
      }
      else {
        this.arrivalDateLocal_DTType = this.arrivalDateUTC_DTType;
        this.arrivalDateLocal = this.arrivalDateUTC;
        this.arrivalTimeLocal = "";
        ob.next(true);
        f = true;
      }

      if (f == false) {
        this._airportService.getAirportLocalTimeFromUTC<ResponseModel<AirportModel>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            let obj = new AirportModel();
            obj = response.model;
            //objCityPair.nextArrivalUTCTimeConversion = obj.utcTimeConversionDisplay;
            let ldt = obj.airportLocalDateTime;
            if (ldt != "") {
              let i = ldt.indexOf(' ');
              let d = ldt.substring(0, i);
              let t = ldt.substring(i + 1);

              this.arrivalDateLocal_DTType = new Date(d);
              this.arrivalDateLocal = d;
              if (this.arrivalTimeUTC != "")
                this.arrivalTimeLocal = t;
              else
                this.arrivalTimeLocal = "";

            }


            ob.next(true);

          }
          else {
            ob.next(false);
            if (response.code == "401") {

              this._authService.signOut();
            }

          }

        })
      }
    });
  }


  //calculateArrivalDateTime(objCityPair: TripLegModel) {
  //  if (objCityPair.departureDateUTC != "" && objCityPair.departureTimeUTC != "" && objCityPair.nextArrivalDateUTC != "" && objCityPair.nextArrivalTimeUTC != "") {
  //    objCityPair.nextArrivalDateUTC = "";
  //    objCityPair.nextArrivalTimeUTC = "";
  //    objCityPair.nextArrivalDateLocal = "";
  //    objCityPair.nextArrivalTimeLocal = "";
  //    this.getArrivalDateTime(objCityPair);

  //  }
  //  else {
  //    this.getArrivalDateTime(objCityPair);
  //  }
  //}

  getArrivalDateTime() {
    if (this.currentLeg.departureDateUTC != "" && this.currentLeg.departureTimeUTC != "") {
      if (this.currentLeg.departureLatitude != null && this.currentLeg.departureLongitude != null && this.latitude != null && this.longitude != null) {
        let request = new DistanceTimeModel();
        request.departureLatitude = this.currentLeg.departureLatitude;
        request.departureLongitude = this.currentLeg.departureLongitude;
        request.arrivalLatitude = this.latitude;
        request.arrivalLongitude = this.longitude;
        request.airSpeed = this.speed; //460;
        request.bias = 15;
        this._airportService.getDistanceTripTime<ResponseModel<DistanceTimeModel>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            let obj = new DistanceTimeModel();
            obj = response.model;
            let h = Number(obj.tripTimeHour);
            let m = Number(obj.tripTimeMinute);
            let dt = new Date(this.currentLeg.departureDateUTC + " " + this.currentLeg.departureTimeUTC);
            let adt = UtilityFunctions.addHours(dt, h);
            adt = UtilityFunctions.addMinutes(dt, m);

            this.arrivalDateUTC_DTType = adt
            this.arrivalDateUTC = CustomValidators.formatDateToMMDDYYYY(this.arrivalDateUTC_DTType);
            let ah = adt.getHours();
            let am = adt.getMinutes();
            if (am < 10) {
              this.arrivalTimeUTC = ah.toString() + ":0" + am.toString();
            }
            else {
              this.arrivalTimeUTC = ah.toString() + ":" + am.toString();
            }
            this.ete = obj.tripTime;
            //this.getAirportUTCTimeConversion().subscribe(res1 => {
            //  if (res1 == true) {

            //  }
            //});
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }

          }
          this.loading = false;
        })
      }

    }
  }


  clickClose() {
    if (this.dataChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes you just made before close the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(this.isModified);
        }
      });
    }
    else {
      this._dialogRef.close(this.isModified);
    }
  }

  icaoKeyDown(e: any) {

    this.newICAO = this.f.insertedLegDepICAO.value;

  }


}
