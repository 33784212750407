import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-feedback',
  templateUrl: './client-feedback.component.html',
  styleUrls: ['./client-feedback.component.css']
})
/** airport-bi-dashboard component*/
export class ClientFeedbackComponent implements OnInit{

  userType: string;
    constructor() {

  }

  ngOnInit() {
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
  }
}
