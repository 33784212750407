export class DistanceTimeModel {
  departureLatitude: number;
  departureLongitude: number;
  arrivalLatitude: number;
  arrivalLongitude: number;
  distanceNauticalMile: number;
  distanceStatuteMile: number;
  distanceKilometer: number;
  tripTime: string;
  tripTimeHour: string;
  tripTimeMinute: string;
  airSpeed: number;
  bias: number;
}
