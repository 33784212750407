<div  style="background-color:#f8f8f8;  height: 100%;">
  <div style="padding: 0.5em; background-color:white ; height: 100%;">


    <div style="display: flex;padding-left:1em;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div style="margin: 4em 4em 2.5em 3em;">
        <h5 *ngIf="!downloadFinished" style="text-align: center;">Please Wait Downloading </h5>
        <h5 *ngIf="!downloadFinished" style="text-align: center;">Files {{downloadCount}} of {{totalDocuments}} </h5>
        
        <h5 *ngIf="downloadFinished" style="text-align: center;"> Download Completed</h5>
        <!-- <app-spin *ngIf="showSpin"></app-spin> -->
        <mat-progress-bar  *ngIf="showSpin" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>

 
    <div style="display: flex">
      <div
        style="justify-content:flex-start;padding-left: 1em; padding-top:0.75em; padding-bottom:0.5em; margin-left: 8em;  ">
        <button class="btn-low" mat-button (click)="clickClose()" style="background-color:grey; color: white; margin-left: 0em;">Close</button>

 

      </div>
      <!-- <div *ngIf="errMsg!=''" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;"><label style="color:black; font-size: small">{{errMsg}}</label></div> -->
      <!-- <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
        <app-success-message [successMsg]=""></app-success-message>
      </div> -->
    </div>
  </div>
</div>
