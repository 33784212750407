<div style="margin-top:0em; padding-top:0em; display:flex">
  <form [formGroup]="customer_regulatory_service_form">
    <div style="margin-top:-1em" >
      <div>
        <table mat-table [dataSource]="recordList" class="example-container">
          <tr>
            <ng-container matColumnDef="service_name">
              <th mat-header-cell *matHeaderCellDef>
                Service Type
              </th>
              <td mat-cell *matCellDef="let element">{{element.governmentServiceType}}</td>
            </ng-container>
            <ng-container matColumnDef="arranged_by">
              <th mat-header-cell *matHeaderCellDef>
                Arranged By
              </th>
              <td mat-cell *matCellDef="let element">{{element.arrangedBy}}</td>
            </ng-container>
            <ng-container matColumnDef="service_date">
              <th mat-header-cell *matHeaderCellDef>
                Service Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.serviceDate}}</td>
            </ng-container>
            <ng-container matColumnDef="authorization_number">
              <th mat-header-cell *matHeaderCellDef>
                Account or <br />Authorization Number
              </th>
              <td mat-cell *matCellDef="let element">{{element.authorizationNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="effective_date">
              <th mat-header-cell *matHeaderCellDef>
                Effective Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.effectiveDate}}</td>
            </ng-container>
            <ng-container matColumnDef="expiration_date">
              <th mat-header-cell *matHeaderCellDef>
                Expiration Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.expirationDate}}</td>
            </ng-container>

            <ng-container matColumnDef="renewal_status">
              <th mat-header-cell *matHeaderCellDef>
                Renewal Status
              </th>
              <td mat-cell *matCellDef="let element">{{element.renewalStatusDescription}}</td>
            </ng-container>
            <ng-container matColumnDef="remarks">
              <th mat-header-cell *matHeaderCellDef>
                Remarks
              </th>
              <td mat-cell *matCellDef="let element"><a *ngIf="element.remarks.length>50" style="cursor:pointer" matTooltipClass="tooltipLineBreak" matTooltip="{{element.remarks}}">{{ element.remarks.substring(0,49)+'...' }}</a><span *ngIf="element.remarks.length<=50">{{element.remarks}}</span></td>
            </ng-container>
            <ng-container matColumnDef="disabled">
              <th mat-header-cell *matHeaderCellDef>
                Disabled
              </th>
              <td mat-cell *matCellDef="let element">{{element.isActive? '':'Yes'}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                Action <br /><a mat-button class="btn-high grid" (click)="clickEdit($event,null)">Add</a>
              </th>
              <td mat-cell *matCellDef="let element; let i=index " width="10%">
                <a mat-button class="btn-low grid" (click)="clickEdit($event, element);">
                  Edit
                </a>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
      <div>
        <mat-paginator style="border: none" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                       [pageIndex]="currentPageIndex"  [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
        </mat-paginator>
      </div>
    </div>
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
