import { AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';
import { GlobalConstant } from '../common-utility/global-constant';
import { DateTimeObjModel } from '../models/dateTimeObj.model';

export class CustomValidators {
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true }
    }

    return null;    
  }

  static cannotContainEmailSign(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf('@') >= 0) {
      return { cannotContainEmailSign: true }
    }

    return null;
  }

  //static passwordMatchValidator(password: string, confirm_password: string) {
  //  if (password != "" && confirm_password != "") {
  //    if (password != confirm_password) {
  //      return false;
  //    }
  //  }
  //  return true;
  //}

  static textMatchValidator(text: string, confirm_text: string) {
    if (text != "" && confirm_text != "") {
      if (text != confirm_text) {
        return false;
      }
    }
    return true;
  }

  static internalEmailValidator(iEmailList: string[], email: string, userType: string) {
    //if (iEmailList.length==0 || userType.toLowerCase() != "internal") {
    if (iEmailList.length == 0) {
      return true;
    }
    var f = false;
    iEmailList.forEach(x => {
      if ((email.toLowerCase().endsWith(x) && userType.toLowerCase() == "internal") || (!email.toLowerCase().endsWith(x) && userType.toLowerCase() != "internal")) {
      //if (email.toLowerCase().lastIndexOf(x) != -1) {
        f = true;
      }        
    });
    return f;
  }

  static checkValidFileType(filename: string) {
    var k = filename.lastIndexOf('.');
    var ext = filename.substring(k + 1).toLocaleLowerCase();
    var a = GlobalConstant.allowedFileTypeList.find(e => e === ext)
    if (a && a != undefined)
      return true;
    else
      return false;
  }

  static checkValidFileType2(filename: string, allowedFileTypeList: string[]) {
    var k = filename.lastIndexOf('.');
    var ext = filename.substring(k + 1).toLocaleLowerCase();
    var a = allowedFileTypeList.find(e => e === ext)
    if (a && a != undefined)
      return true;
    else
      return false;
  }

  static validateCommonAddress(address: string, type: string) {
    switch (type.toLowerCase()) {
      case 'office phone':
      case 'home phone':
      case 'mobile phone':
      //case 'fax':
      case 'operations phone':
      case 'mobile':
        let regexpPhone: RegExp = /^[+0-9- ()]+$/;
        return regexpPhone.test(address);
      case 'email':
        let regexpEmail: RegExp = /^[''a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,100}$/;
        return regexpEmail.test(address);
      case 'aftn':
        let regexpAftn: RegExp = /^[a-zA-Z0-9]{8}$/;
        return regexpAftn.test(address);
      case 'arinc':
      case 'sita':
        let regexpArinc: RegExp = /^[a-zA-Z0-9]{7}$/;
        return regexpArinc.test(address);
      case 'fax':
        let regexpEmailForFax: RegExp = /^[''a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,100}$/;
        let ve = regexpEmailForFax.test(address);
        let regexpPhoneForPhone: RegExp = /^[+0-9- ()]+$/;
        let vp = regexpPhoneForPhone.test(address);
        if (ve == true || vp == true) {
          return true;
        }
        else {
          return false;
        }
    }

    return false;

  }

  static formatDateToMMDDYYYY(md: Date) {
    let d = new Date(md);
    let y = d.getFullYear();
    let m = d.getMonth() + 1;
    let dt = d.getDate();
    let s = m.toString() + "/" + dt.toString() + "/" + y.toString();
    return s
  }

  static isValidDate(dateString: string) {
    if (dateString == "") {
      return true;
    }
    let d = new Date(dateString);
    let s = this.formatDateToMMDDYYYY(d);
    let d2 = new Date(s);
    
    //if (dateString != s) {
    //  return false;
    //}
    if (d.getTime() != d2.getTime()) {
      return false;
    }
    else {
      let minDate = new Date("1/1/1900")
      if (d.getTime() < minDate.getTime())
        return false;
      else
        return true;
    
    
    }


    //let dateformat = /^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/;

    //// Match the date format through regular expression      
    //if (dateString.match(dateformat)) {
    //  let operator = dateString.split('/');

    //  // Extract the string into month, date and year      
    //  let datepart = [];
    //  if (operator.length > 1) {
    //    datepart = dateString.split('/');
    //  }
    //  let month = parseInt(datepart[0]);
    //  let day = parseInt(datepart[1]);
    //  let year = parseInt(datepart[2]);

    //  // Create list of days of a month      
    //  let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    //  if (month == 1 || month > 2) {
    //    if (day > ListofDays[month - 1]) {
    //      ///This check is for Confirming that the date is not out of its range      
    //      return false;
    //    }
    //  } else if (month == 2) {
    //    let leapYear = false;
    //    if ((!(year % 4) && year % 100) || !(year % 400)) {
    //      leapYear = true;
    //    }
    //    if ((leapYear == false) && (day >= 29)) {
    //      return false;
    //    } else
    //      if ((leapYear == true) && (day > 29)) {
    //        console.log('Invalid date format!');
    //        return false;
    //      }
    //  }
    //} else {
    //  //console.log("Invalid date format!");
    //  return false;
    //}
    //return true;
  }   

  static isValid24HHMMTimeFormat(dateObj: DateTimeObjModel) {
    let t = dateObj.timeString.trim();
    if (t != "" && (t.length > 5 || (t.length == 5 && t.indexOf(":") == -1))) {
      dateObj.isValidDateTime = false;
      dateObj.timeString = t;
      return dateObj;
    }
    else {
      let m = "";
      let s = "";
      if (t.indexOf(":") == -1) {
        switch (t.length) {
          case 1:
          case 2:
            t = t + ":00";
            break;
          case 3:
            m = t.substring(0, 1);
            s = t.substring(1);
            t = m + ":" + s;
            break;
          case 4:
            m = t.substring(0, 2);
            s = t.substring(2);
            t = m + ":" + s;
            break;
        }

      }
    }
    let regexpTime: RegExp = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    dateObj.isValidDateTime = regexpTime.test(t);
    dateObj.timeString = t;
    return dateObj;
  }

  static isValidDateRange(dateString1: string, dateString2: string) {
    if (dateString1 == "" || dateString2 == "") {
      return true;
    }
    let d1 = new Date(dateString1);
    let d2 = new Date(dateString2);
    
      if (d1.getTime() > d2.getTime())
        return false;
      else
        return true;


    }

  
}
