<div style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em " cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.15em"><i class="material-icons" style="font-size: x-large;color: gray; margin-top:0.15em">hotel</i></div>
    <div style="margin-top:0.3125em;"><h5>Hotel Property</h5></div>
  </div>
  <div>
    <form *ngIf="hotel_client_form" [formGroup]="hotel_client_form">
      <div style="margin-top:0em; padding-left: 1em;padding-right:1em; ">
        <div style="background-color: white; padding-left:0.5em; padding-right:0.5em; padding-top: 0.5em; padding-bottom:1em; display: flex">
          <!--left-->
          <div style="margin-right:0.5em">
            <div style="display:flex">
              <div style="margin-right:0.5em">
                <label for="hotelName" style="font-size:small">Hotel Name</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em;font-size: small;min-width:17em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">{{hotelName}}                 
                </div>               
              </div>
              <div>
                <label for="hotel_chain_select" style="font-size:small">Chain</label>
                <div style="margin-top:-0.5em; margin-bottom:0em;font-size: small;min-width:19em; min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">{{hotelChainName}}                  
                </div>               
              </div>
            </div>
            <div style="display:flex">
              <div>
                <label for="street_address" style="font-size:small">Address</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <textarea formControlName="street_address" readonly class="form-control" maxlength=255 style="width:17em; height:8em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd" [ngClass]="{ 'is-invalid': submitted && f.street_address.errors }"></textarea>
                </div>                
              </div>
              <div style="margin-left:0.5em">
                <div>
                  <label for="city" style="font-size:small">City</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em;font-size: small;min-width:19em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">{{city}}               
                  </div>
                  
                </div>
                <div>
                  <label for="state_province" style="font-size:small">State / Province</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em;font-size: small;min-width:19em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                    {{state_province}}
                       </div>
                </div>
                <div>
                  <label for="zipcode" style="font-size:small">Zip/Postal Code</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em;font-size: small;min-width:10em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                    {{zipcode}}                    
                  </div>
                </div>

              </div>
            </div>
            <div>
              <label for="country_select" style="font-size:small">Country</label>
              <div class="form-group" style="margin-top:-0.5em;font-size: small;min-width:31em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">{{hotelCountryName}}
              </div>
            </div>
          </div>
          <!--left end-->
          <!--right-->
          <div>
            <div style="display: flex">
              <div style="margin-right: 0.5em">
                <label for="near_airport" style="font-size:small">Nearest Airport</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em;font-size: small;min-width:7em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{near_airport}}                
                </div>
              </div>
              <div style="margin-right: 0.5em">
                <label for="distance_from_airport" style="font-size:small">Distance from Airport</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; display:flex;font-size: small;min-width:7em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{distance_from_airport>0?distance_from_airport.toString()+' Miels':''}}
                  
                </div>
              </div>
             
              <div style=" margin-right: 0.5em">
                <label for="modifiedBy" style="font-size:small">Modified By</label>
                <div style="margin-top:-0.5em">
                  <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
                </div>
              </div>
              <div style=" margin-right: 0.5em">
                <label for="modifiedDate" style="font-size:small">Modified Date</label>
                <div style="margin-top:-0.5em">
                  <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
                </div>
              </div>
            </div>
           
            <div>
              <label for="remarks" style="font-size:small">Remarks</label>
              <div style="margin-left: 0em; margin-top:-0.5em">
                <textarea formControlName="remarks" readonly class="form-control" maxlength=255 style="width:40em; height:4em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
                </textarea>
              </div>
            </div>
            <div style="display: flex" *ngIf="comm_addres1!=''">
              <div style="margin-right:0.5em">
                <label for="comm_address_type1_select" style="font-size:small">Type</label>
                <div style="margin-top:-0.5em;font-size: small;min-width:14em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{commAddressType1}}
                  
                </div>
                
              </div>
              <div style="margin-right:0.5em;">
                <label for="comm_address1" style="font-size:small">Address</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em;font-size: small;min-width:13em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{comm_addres1 }}                 
                </div>               
              </div>
              <div *ngIf="commAddressType1=='Office Phone'" style="margin-left: 0em;margin-top:0em;">
                <label for="extension1" style="font-size:small">Extension</label>
                <div style="margin-left: 0em; margin-top:-0.5em;font-size: small;min-width:5em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{extension1}}                 
                </div>

              </div>
            </div>
            <div style="display: flex" *ngIf="comm_addres2!=''">
              <div style="margin-right:0.5em">
                <label for="comm_address_type2_select" style="font-size:small">Type</label>
                <div style="margin-top:-0.5em;font-size: small;min-width:14em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{commAddressType2}}
                </div>
              </div>
              <div style="margin-right:0.5em;">
                <label for="comm_address2" style="font-size:small">Address</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em;font-size: small;min-width:13em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{comm_addres2 }}                 
                </div>
                
              </div>
              <div *ngIf="commAddressType2=='Office Phone'" style="margin-left: 0em;margin-top:0em;">
                <label for="extension2" style="font-size:small">Extension</label>
                <div style="margin-left: 0em; margin-top:-0.5em;font-size: small;min-width:5em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{extension2}}
                 
                </div>
              </div>
            </div>
            <div style="display: flex" *ngIf="comm_addres3!=''">
              <div style="margin-right:0.5em">
                <label for="comm_address_type3_select" style="font-size:small">Type</label>
                <div style="margin-top:-0.5em;font-size: small;min-width:14em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{commAddressType3}}
                  
                </div>
              </div>
              <div style="margin-right:0.5em;">
                <label for="comm_address3" style="font-size:small">Address</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em;font-size: small;min-width:13em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{comm_addres3 }}                  
                </div>               
              </div>
              <div *ngIf="commAddressType3=='Office Phone'" style="margin-left: 0em;margin-top:0em;">
                <label for="extension3" style="font-size:small">Extension</label>
                <div style="margin-left: 0em; margin-top:-0.5em;font-size: small;min-width:5em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{extension3}}
                  
                </div>
              </div>
            </div>

          </div>
          <!--right end-->
        </div>
      </div>
    </form>
  </div>
  <div style="display: flex">
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;margin-right:1em">
      <!--<button class="btn btn-primary" [disabled]="loading" (click)="clickSave('')"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Save&nbsp;</button>&nbsp;-->
      <button mat-button class="btn-low" (click)="clickClose()" >Close</button>
    </div>
    <!--<div *ngIf="errMsg!=''" class="d-block" style="display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em; width:19em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
    <div *ngIf="showSuccessMsg" style="margin-top: 0.5em;">
      <app-success-message [successMsg]=""></app-success-message>
    </div>-->
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>

