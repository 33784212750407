<div *ngIf="upPII && grantAccessToManageManifestProfiles" style="background-color:#f8f8f8;" >
  <div style="display: flex; justify-content: space-between;" cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>Travel Document</h5></div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
    <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
  </div>-->
  </div>
  <div>
    <form *ngIf="person_doc_selection_form" [formGroup]="person_doc_selection_form" >
      <div style="margin-top:0em; padding-left: 1em;padding-right:1em">
        <div style="margin-top:0em;background-color:white; padding-left:0.5em;padding-right:0.5em">
          <div>
            <div style="display:flex">
              <div>
                <div style="margin-right: 0.625em;margin-top:0em">
                  <label for="doc_type_select" style="font-size:small">Select Document Type</label>
                  <div style="margin-top:-0.5em;">
                    <select *ngIf="f" formControlName="doc_type_select" [ngClass]="{ 'is-invalid': submitted && f.doc_type_select.errors }" class="form-control form-control-sm dropdown" (change)="docTypeOnChange($event)">
                      <option [ngValue]="null" selected></option>
                      <option *ngFor="let doc of documentTypeList" [ngValue]="doc">{{doc.documentTypeDescription}}</option>
                    </select>
                  </div>
                  <div *ngIf="submitted && f.doc_type_select.errors" class="invalid-feedback" style="margin-top:-2em">
                    <div *ngIf="f.doc_type_select.errors.required" style="background-color: #f9d2de;margin-top:0.5em;padding-left:0.25em; width:16em">Document type is a required field.</div>
                  </div>
                </div>
              </div>
              <div class="d-inline-flex" style="justify-content:flex-start;padding-left: 1em; padding-top:1.25em;padding-right:1em;padding-bottom:1em">
                <button mat-button class="btn-high" *ngIf="(selectedDocumentTypeDescription!='Visa') || (selectedDocumentTypeDescription=='Visa' && selectedPersonTravelDocumentGUID!='')" (click)="clickSubmit()">Submit</button>&nbsp;
                <button mat-button class="btn-low" (click)="clickAddTravelDocument(0)" style="background-color:grey; color: white">&nbsp;Close&nbsp;</button>
              </div>
            </div>
          </div> 
          <div>
            <div *ngIf="selectedDocumentTypeDescription=='Visa' && selectedDocumentTypeDescription!=''" style="margin-right: 0.625em;margin-top:0em;padding-bottom:1em">
              <label for="passport_select" style="font-size:small">Please Select a Passport</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="passport_select" class="form-control form-control-sm dropdown"  (change)="passportOnChange($event)">
                  <option *ngFor="let ps of passportList" [ngValue]="ps.personTravelDocumentGUID">{{ps.personTravelDocumentInfo}}</option>
                  <option value="-1">Create new Passport</option>
                </select>
              </div>
              <div *ngIf="submitted && f.doc_type_select.errors" class="invalid-feedback" style="margin-top:-2em">
                <div *ngIf="f.doc_type_select.errors.required" style="background-color: #f9d2de;margin-top:0.5em;padding-left:0.25em; width:16em">Document type is a required field.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div style="height:2em"></div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
