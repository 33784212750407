import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { AuthenticateService } from '../services/authenticate.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { CustomerModel } from '../models/customer.model';
import { VendorModel } from '../models/vendor.model';
import { Observable, forkJoin } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { CommonService } from '../services/common.service';
import { ResponseModel } from '../models/response.model';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomerService } from '../services/customer.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CustomerProfileSetupEditComponent } from './customer-profile-setup-edit.component';
import { CustomerProfileMainEditDialogComponent } from './customer-profile-main-edit-dialog.component';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-customer-profile-setup',
  templateUrl: './customer-profile-setup.component.html',
  styleUrls: ['./customer-profile-setup.component.css']
})

export class CustomerProfileSetupComponent implements OnInit {
  customer_profile_setup_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  customerList: CustomerModel[];
  userType: string;
  customerType: number = 1;
  showSpin: boolean = true;
  selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  customerGuid: string;
  text_search: string;
  filter_by: string;
  recordList: CustomerModel[];
  limitTo: number = 10;
  scrHeight: number;
  displayedColumn: string[];
  displayedColumn1: string[] = ['customer_name', 'acct_create_date', 'credit_status', 'billing_status', 'requires_backup_invoice', 'sales_territory', 'scheduling_software','halt_services',
    'has_notes', 'sales_notes','has_ukapd', 'has_eurocontrol', 'has_seneam', 'has_cocesna', 'has_boe','has_vwp','has_part375','action'];
  displayedColumn2: string[] = ['customer_name', 'acct_create_date', 'credit_status', 'sales_territory', 'has_notes','sales_notes', 'action'];
  displayedColumn3: string[] = ['customer_name', 'acct_create_date', 'sales_territory', 'has_notes', 'sales_notes','action'];


  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog, private _titleService:Title,
    private readonly _customerService: CustomerService) {

  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.customerList = [];
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.selectedTabName = 'active';
    this.displayedColumn = this.displayedColumn1;
    this.customerType = 1
    this.customerGuid = "";
    this.text_search = "";
    this.filter_by = "CreditStatus";
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }
       
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    if (this.userType == "internal") {
      this._commonService.getCustomerListByType<ResponseModel<CustomerModel[]>>(this.customerType).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model.length > 0) {
            this.customerList = response.model;
          }
          else {

          }
        }
        this.showSpin = false;

      })

    }

    this.initControls();

    this.getSearchResults(this.customerType);
  }

  initControls() {
    this.customer_profile_setup_form = this._formBuilder.group({
      customer_select: this.customerGuid,
      text_search: [this.text_search],
      filter_by: [this.filter_by]
    });
  }

  get f() { return this.customer_profile_setup_form.controls; }

  customerChange(e: any) {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults(this.customerType);

  }

  clickReset() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.selectedTabName = 'active';
    this.customerGuid = "";
    this.text_search = "";
    this.filter_by = "CreditStatus";
    this.initControls();
  }

  clickShowAllRecords() {
    this.errMsg = "";
    //if (this.f.customer_select.value == "") {
    //  this.errMsg = "Please select a customer";
    //  return;
    //}
    this.f.text_search.setValue('');
    this.getSearchResults(this.customerType);

  }

  onSubmit() {
    this.errMsg = ""
    //if (this.f.customer_select.value == "") {
    //  this.errMsg = "Please select a customer";
    //  return;
    //}
    if (this.f.text_search.value == "") {
      this.errMsg = "Please enter a keyword.";
      return;
    }
    
    this.loading = true;
    this.getSearchResults(this.customerType);
  }

  getSearchResults(cusType: number) {
    this.totalRecords = 0;
    this.showSpin = true;
    this.loading = true;  
    this._authService.updateAccessTime();
    let request = new CustomerModel();
    request.customerGUID = this.f.customer_select.value;
    if (request.customerGUID == "") {
      request.customerGUID = null;
    }
    request.textSearch = this.f.text_search.value;
    request.filterBy = this.f.filter_by.value;
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.customerTypeId = cusType;
   

    this._customerService.getCustomerProfileList<ResponseModel<CustomerModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
            this.msg = "";
          }

        }
        else {
          this.recordList = [];
          //this.msg = "No data returned.";
        }
      }
      else {
        this.recordList = [];
        this.msg = "";
      }
      this.loading = false;
      this.showSpin = false;

    })

  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
    }
    this.getSearchResults(this.customerType);

  }

  clickTab(s: string) {

      switch (s) {
        case "active":
          this.customerType = 1;
          this.selectedTabName = 'active';
          this.displayedColumn = this.displayedColumn1;          
          break;
        case "prospect":
          this.customerType = 2;
          this.selectedTabName = 'prospect';
          this.displayedColumn = this.displayedColumn2;
          break;
        case "lead":
          this.customerType = 3;
          this.selectedTabName = 'lead';
          this.displayedColumn = this.displayedColumn3;
          break;
        case "suspended":
          this.customerType = 4;
          this.selectedTabName = 'suspended';
          this.displayedColumn = this.displayedColumn1;
          break;
        case "closed":
          this.customerType = 5;
          this.selectedTabName = 'closed';
          this.displayedColumn = this.displayedColumn1;
          break;       

    }
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
  
    this.customerList = [];
    this._commonService.getCustomerListByType<ResponseModel<CustomerModel[]>>(this.customerType).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.customerList = response.model;
        }
        else {

        }
      }
    });
    this.getSearchResults(this.customerType);
    
  }

  clickEdit(event: any, profile: CustomerModel) {

    this._router.navigate(['/customers/customer-profile-setup-edit', { customerGuid: profile.customerGUID }]);
    
    

  }

  clickAdd(event: any, profile: CustomerModel) {
    let s = "";
    let customerGUID = null;
    if (profile == null) {
      s = "Add New Profile";
    }
    else {
      s = "Edit";
      customerGUID = profile.customerGUID;
    }

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "customerGuid": customerGUID
      }
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
  //  dialogConfig.width = "46.5em";//"1000px";
  //  dialogConfig.height = "13em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, customerGUID: this.f.customer_select.value, customerGuid: customerGUID };
    const dialogRef = this._dialog.open(CustomerProfileMainEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        switch (result.actionName) {
          case "edit":
            this._router.navigate(['/customers/customer-profile-setup-edit', { customerGuid: result.newId }]);
            break;
          case "search":
            this.getSearchResults(this.customerType);
            break;
        }
      }

   
    });


  }

  }





