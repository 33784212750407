<div style="background-color:#f8f8f8;height:100%">
  <div style="display: flex; justify-content: space-between;">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>Address</h5></div>
  </div>
  <div style="margin-top:0em">
    <form *ngIf="vendor_address_edit_form" [formGroup]="vendor_address_edit_form" (ngSubmit)="clickSave()">
      <div style="margin-left: .5em;margin-top:0em;margin-right:0.5em;">
        
        <div style="display:flex;margin-top:0em;background-color:white;padding-left:0.5em;padding-right:0.5em;">



          <div style="display:flex">
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="address" style="font-size:small">Address</label>
              <div style="margin-top:-0.5em;">
                <textarea spellcheck="true" formControlName="address" class="form-control" style="width:40em; height:12em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>
              </div>
            </div>
            <div *ngIf="vendorAddressID!=0" class="form-group" style="margin-left: 0em; margin-top:0em; margin-bottom:0em;width:10em">
              <label for="address_status" style="font-size:small">Disable Record</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="note_status" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color: lightgrey">
                  <option value="1" selected>No</option>
                  <option value="0">Yes</option>
                </select>
              </div>

              <div style="display:flex;margin-top:1em">
                <div>
                  <div class="form- group" style="margin-left: 0em; margin-bottom:0em">
                    <label for="modifiedBy" style="font-size:small">Modified by</label>
                    <div style="margin-top:-0.5em;">
                      <input type="text" formControlName="modifiedBy" readonly class="form-control" style="padding-left:0em;width:10em;height:1.5625em;font-size:small" />
                    </div>
                  </div>
                  <div class="form-group" style="margin-left: 0em; margin-bottom:0em">
                    <label for="modifiedDate" style="font-size:small">Modified Date</label>
                    <div style="margin-top:-0.5em;">
                      <input type="text" formControlName="modifiedDate" readonly class="form-control" style="padding-left: 0em; width: 10em; height: 1.5625em; font-size: small" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>

      </div>
    </form>

  </div>
  <div class="d-inline-flex">
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em">
      <button mat-raised-button class="btn-high" (click)="clickSave()">Save</button>&nbsp;
      <button mat-raised-button *ngIf="vendorAddressID!=0" class="btn-high" (click)="clickAddNew()">Add New Record</button>&nbsp;
      <button mat-raised-button class="btn-med" (click)="clickClose()">Close</button>

    </div>
    <app-success-message *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>





