<table *ngIf="businessRulesList && businessRulesList.length>0" mat-table [dataSource]="businessRulesList" class="example-container">
  <tr>
    <ng-container matColumnDef="brID">
      <th mat-header-cell *matHeaderCellDef width="5%">
        BR ID
      </th>
      <td mat-cell *matCellDef="let element">{{element.businessRulesID}}</td>
    </ng-container>
    <ng-container matColumnDef="customerName">
      <th mat-header-cell *matHeaderCellDef width="20%">
        Client
      </th>
      <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
    </ng-container>
    <ng-container matColumnDef="registration">
      <th mat-header-cell *matHeaderCellDef width="10%">
        Registration
      </th>
      <td mat-cell *matCellDef="let element">{{element.registrationList}}</td>
    </ng-container>
    <ng-container matColumnDef="far_type">
      <th mat-header-cell *matHeaderCellDef width="10%">
        FAR Type
      </th>
      <td mat-cell *matCellDef="let element">{{element.farTypeDescription}}</td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef width="10%">
        Country
      </th>
      <td mat-cell *matCellDef="let element">{{element.countryName}}</td>
    </ng-container>
    <ng-container matColumnDef="icao">
      <th mat-header-cell *matHeaderCellDef width="5%">
        ICAO
      </th>
      <td mat-cell *matCellDef="let element">{{element.icao}}</td>
    </ng-container>
    <ng-container matColumnDef="service_class">
      <th mat-header-cell *matHeaderCellDef width="10%">
        Service Class
      </th>
      <td mat-cell *matCellDef="let element">{{element.serviceClassDescription}}</td>
    </ng-container>
    <ng-container matColumnDef="service_type">
      <th mat-header-cell *matHeaderCellDef width="10%">
        Service Type
      </th>
      <td mat-cell *matCellDef="let element">{{element.serviceTypeDescription}}</td>
    </ng-container>
    <ng-container matColumnDef="vendorName">
      <th mat-header-cell *matHeaderCellDef width="20%">
        Vendor
      </th>
      <td mat-cell *matCellDef="let element">{{element.vendorName}}</td>
    </ng-container>
  </tr>
  <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
</table>
