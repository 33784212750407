import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { formatDate, DatePipe } from "@angular/common";
import { AuthenticateService } from '../services/authenticate.service';
import { Subject } from "rxjs";
import { SignInModel } from "../models/sign-in.model";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [DatePipe]
})
export class HeaderComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  auth: Subject<SignInModel>;

  login: boolean;
  username: string;
  customerName: string;
  pwdExpirationDate: string;

  constructor(private readonly _authService: AuthenticateService, public readonly _datepipe: DatePipe) {  
    this.auth = _authService.getSignIn();
    var username = localStorage.getItem('username');
    if (username != null && username != '') {
      this.login = true;
      this.username = username;
      this.customerName = localStorage.getItem('customerName');
      this.pwdExpirationDate = '';
      if (localStorage.getItem('pwdExpirationDate') != '' && localStorage.getItem('pwdExpirationDate') != null) {
        this.pwdExpirationDate = this._datepipe.transform(localStorage.getItem('pwdExpirationDate'), 'mediumDate');        
      }
    }
  }

  ngOnInit() {
    this.auth.subscribe(v => {
      this.login = v.login;
      this.username = v.username;
      this.customerName = v.customerName;
      this.pwdExpirationDate = '';
      if (v.pwdExpirationDate != '' && v.pwdExpirationDate != null) {
        this.pwdExpirationDate = this._datepipe.transform(v.pwdExpirationDate, 'mediumDate');
      }
    });
  }
  public onToggleSidenav = () => {
    if (this.login) {
      this.sidenavToggle.emit();
    }
  }

  formatDisplayDate(s: string) {
    if (s != '') {
      s = formatDate(s, 'mediumDate','en-US');
    }
    return s;
  }
}
