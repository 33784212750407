import { v4 as uuidv4 } from 'uuid';
import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AirportModel } from '../models/airport.model';
import { ResponseModel } from '../models/response.model';
import { AirportService } from '../services/airport.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CountryModel } from '../models/country.model';
import { CountryService } from '../services/country.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-airport-locator',
    templateUrl: './airport-locator.component.html',
    styleUrls: ['./airport-locator.component.css']
})
/** airport-locator component*/
export class AirportLocatorComponent implements OnInit {
  isLoading: boolean = false;
  icao: string = "";
  airportID: number = 0;
  airport_locator_form: UntypedFormGroup;
  searchType: string = "airport";
  airportList: AirportModel[];
  airportErrorList: string[] = [];
  errMsg: string="";
  submitted: boolean = false;
  minRunway: number = 5000;

  uniqueId = uuidv4();
    /** airport-locator ctor */

  countryList: CountryModel[];
  countryErrorList: string[] = [];
  searchKey: string = null;
  countryID: number = 0;
  fuelTaskIcao: string = '';

  constructor(private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,@Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _countryService: CountryService,  private readonly _airportService: AirportService) {
      if(_data.fuelTaskIcao != undefined && _data.fuelTaskIcao != null){
        this.fuelTaskIcao = _data.fuelTaskIcao;
      }

  }

  ngOnInit() {
    this.initControls();
    if(this.fuelTaskIcao != ''){
      this.filterByFuelTaskIcao();
    }
    
    if(this._data.searchKey !== undefined) {
      this.searchKey = this._data.searchKey;
      this.f.search_by.setValue(this.searchKey);
      this.getAirportList();
    }
  }


  initControls() {
    this.airport_locator_form = this._formBuilder.group({
      search_by:['',Validators.required]

    });
  }

  get f() { return this.airport_locator_form.controls; }

  getAirportList() {
    this.submitted = true;
    this.errMsg = "";
    this.airportErrorList = [];
    // this.icao = "";
    if (this.airport_locator_form.valid) {
      this.isLoading = true;
      let request = new AirportModel();
      request.searchKey = this.getSearchKey();
      request.searchType = this.searchType;
      request.runwayMaxLength = this.minRunway;
      this._airportService.getAirportBySearchKey<ResponseModel<AirportModel[]>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {            
            this.airportList = response.model;
            if (this.airportList.length > 0) {

              this.airportID = this.airportList[0].airportID;
              this.icao = this.airportList[0].icao;
            }

            else
              this.errMsg = "No results found";
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          }
          let searchKeys = request.searchKey.split(',');
          searchKeys?.forEach((key) => {
            const matchingDocument = this.airportList.findIndex((ap) => key?.toLowerCase() == ap.icao?.toLowerCase() ||  key?.toLowerCase() == ap.iata?.toLowerCase() );
            if(matchingDocument == -1 && this.searchType == 'airport'){
              this.airportErrorList.push(key);
            }
          });
        }
        else
          this.errMsg = "No results found";
        this.isLoading = false;
      });
      this.submitted = false;

    }
    
  }

  getCountryList() {
    this.submitted = true;
    this.errMsg = "";
    this.countryErrorList = []; 
    // if (this.country_locator_form.valid) {
      this.isLoading = true;
      let request = new CountryModel();
      this.searchKey = this.getSearchKey(); 
      request.searchKey = this.searchKey != undefined ? this.searchKey : null;
      this._countryService.getCountryBySearchKey<ResponseModel<CountryModel[]>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {            
            this.countryList = response.model;
            if (this.countryList.length > 0) {

              this.countryID = this.countryList[0].countryID; 
            }

            else
              this.errMsg = "No results found";
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          } 
          let searchKeys = request.searchKey.split(',');
          searchKeys?.forEach((key) => { 
            const matchingDocument = this.countryList.findIndex(ap => ap.countryName && ap.countryName?.toLowerCase().includes(key?.toLowerCase()));

            if(matchingDocument == -1 && this.searchType == 'country'){
              this.countryErrorList.push(key);
            }
          });
        }
        else
          this.errMsg = "No results found";
        this.isLoading = false;
      });
      this.submitted = false;

    // }
    
  }
  selectAirport(a: any) {
    this.icao = a.icao;
    this.airportID = a.airportID;
  }

  selectCountry(a: any) {
    this.icao = a.icao;
    this.countryID = a.countryID;
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return (value / 1000) + 'k';
    }

    return value;
  }

  getSearchKey(): string {
    let searchVal: string = this.f.search_by.value;
    if (searchVal) {
      searchVal = searchVal.replaceAll('-', ',');
      searchVal = searchVal.replaceAll(' ', ',');
      searchVal = searchVal.replaceAll(',,,,', ','); 
      searchVal = searchVal.replaceAll(',,,', ',');
      searchVal = searchVal.replaceAll(',,', ',');
    }
    
    // if(searchVal?.length > 2)
    //   return '';
    // else{
      return searchVal;
    // }
    
  }

  clearInputBox() {
    this.f.search_by.setValue("");
    this.icao = "";
    this.airportID = null;
    this.airportList = [];
    this.airportErrorList = [];
  
    if(this.searchType == 'country'){
      this.getCountryList();
    }

  }

  isMinimized: boolean = false;
  conditionValue: number = 3;



 
  
  // minimizeDialog() {
  //   this.isMinimized = true;
 
  //   //this.displayMessage = this.messageGUID == ""? 'New Message': ((this.subject.length>0 && this.subject.length>14 )? this.subject.substr(0,14) : this.subject )

  //   if (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-container') != undefined &&
  //     document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-container') != null)
  //     (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-container') as HTMLElement).style.transform = 'none';

    
  //     (document.querySelector('.custom-dialog-container .mat-dialog-container') as HTMLElement).className = 'minimizeStyles';
  //     // (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-pane') as HTMLElement).style.height = '0';

  // }

  // // height: 100%;
  // // background: #f8f8f8;
  // // width: 100%;
  // maximizeDialog() {
  //   this.isMinimized = false;
                                                          
  //   (document.querySelector('.minimizeStyles')  ).className = 'custom-dialog-container';
  //   (document.getElementsByTagName('mat-dialog-container')[0] as HTMLElement).classList.add('mat-dialog-container');
  //   // (document.getElementById(this.uniqueId).parentElement.closest('.mat-dialog-container') as HTMLElement).classList.add('mat-dialog-container');


  //   // (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-pane') as HTMLElement).style.height = '40em';


  // }

  
  minimizeDialog() {
    this.isMinimized = true;
   
    if (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-pane') != undefined &&
      document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-pane') != null)
      (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-pane') as HTMLElement).style.transform = 'none'
  }

  maximizeDialog() {
    this.isMinimized = false;
  }

  openNewTab() {
    // window.open('http://localhost:62262/airport-locator/', '_blank');
    window.open('http://localhost:62262/airport-locator/blank' + "?v=" + this._authService.getCurrentTimeNumber());
  }

  isActiveAirport(selectedAirport: any): boolean {
    // Implement your logic to determine if the airport is active
    // For example, compare the selectedAirport with some property in your component
    return selectedAirport.icao === this.icao;
  }

  filterByFuelTaskIcao() {
    this.submitted = true;
    this.errMsg = "";
    this.airportErrorList = [];
      this.isLoading = true;
      let request = new AirportModel();
      request.searchKey = this.fuelTaskIcao;
      request.searchType = 'airport';
      request.runwayMaxLength = this.minRunway;
      this.f.search_by.setValue(this.fuelTaskIcao);
      this._airportService.getAirportBySearchKey<ResponseModel<AirportModel[]>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {            
            this.airportList = response.model;
            if (this.airportList.length > 0) {

              this.airportID = this.airportList[0].airportID;
              this.icao = this.airportList[0].icao;
            }

            else
              this.errMsg = "No results found";
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          }
          let searchKeys = request.searchKey.split(',');
          searchKeys?.forEach((key) => {
            const matchingDocument = this.airportList.findIndex((ap) => key?.toLowerCase() == ap.icao?.toLowerCase() ||  key?.toLowerCase() == ap.iata?.toLowerCase() );
            if(matchingDocument == -1 && this.searchType == 'airport'){
              this.airportErrorList.push(key);
            }
          });
        }
        else
          this.errMsg = "No results found";
        this.isLoading = false;
      });
      this.submitted = false;

 

  }


}

