import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
//import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';
import { FileModel } from '../models/file.model';
import { FileItem, FileUploader, ParsedResponseHeaders, FileSelectDirective, FileDropDirective } from 'ng2-file-upload';
import * as DecoupledEditor from '../ckeditor5/ckeditor';
import { MessageAttachmentModel } from '../models/message-attachment';
import { MessageModel } from '../models/message.model';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { TripModel } from '../models/trip.model';
import { MessageService } from '../services/message.service';
import { IdentityService } from '../services/identity.service';
import { UserModel } from '../models/user.model';
import { IdentityRequestModel } from '../models/identity-request.model';
import { CKEditorConfig } from '../ckeditor5/ckeditor-config';

@Component({
  selector: 'app-signature-editor',
  templateUrl: './signature-editor.component.html',
  styleUrls: ['./signature-editor.component.css']
})

export class SignatureEditorComponent implements OnInit {
  // ckeditor
  public Editor = DecoupledEditor;

  public CKEConfig = CKEditorConfig.ckeditorConfig;

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  isDisabled: boolean;
  @ViewChild('signatureCKEditor') signatureCKEditorRef: any;//ElementRef;
  // ckeditor end

  dialogTitle: string;
  
  signature_editor_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  showSpin: boolean = false;
  userType: string;
  showSuccessMsg: boolean =false;
  successMsg: string = "";// "Email sent successfully";
  
  userName: string;
  signatureContent: string="";
  
  constructor(private readonly _dialogRef: MatDialogRef<SignatureEditorComponent>, private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _dialog: MatDialog, private readonly _commonService: CommonService, private readonly _groundStopClientService: GroundStopClientService,
    private readonly _dialogService: DialogService, private readonly _messageService: MessageService, private readonly _identityService: IdentityService
  ) {
  
  }

  getAllData(): Observable<any[]> {
    let req3 = new IdentityRequestModel();
    let userResponse = this._identityService.getUser(req3);
    
    return forkJoin([userResponse]);
  }

  ngOnInit() {
    this.isDisabled = false;
    this._authService.updateAccessTime();
   
    this.showSpin = true;
    //this.userName = localStorage.getItem('un');
    this.errMsg = "";
    
    this.signatureContent = '';
   
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    
    this.getAllData().subscribe(responses => {     
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (responses[0].model != null) {
            let user = new UserModel();
            user = responses[0].model;
            this.userName = user.username;
            this.signatureContent = user.signatureInCKEditorFormat;
            
          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();
      this.showSpin = false;
    })


  }

  initControls() {
    this.signature_editor_form = this._formBuilder.group({      
      signatureCKEditor: [this.signatureContent],   

    });
  }

  get f() { return this.signature_editor_form.controls; }

  clickSend() {
    
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    
    if (this.signature_editor_form.invalid || this.errMsg != "") {
      return;
    }

    this.loading = true;
    let request = new UserModel();
    request.signature = this.signatureCKEditorRef.editorInstance.getData();

    this._identityService.saveUserSignature<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);
        }
        else {
          this.errMsg = "Failed to send email at this time. Please try later.";

        }
      }
      else {
        if (response.code == "401") {
          if (response.message != "") {
            this.errMsg = response.message;
          }
          else {
            this._authService.signOut();
          }
        }
        else {
          this.errMsg = "Failed to send email at this time. Please try later.";
        }
      }
      this.loading = false;
    })

  }

  clickClose() {
    this._dialogRef.close();
  }

}
