export class DocumentTypeModel {
  documentTypeID: number;
  documentTypeGUID: string;
  documentTypeDescription: string;
  selected: boolean;
  serviceTypeID: number;
  countryName: string;
  docCount: number = 0;
  isSelected: boolean = false;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  hasEffectiveDate: boolean;
  hasExpirationDate: boolean;
  countryID: number;
  isCrew: boolean;
  isPax: boolean;
  isVendor: boolean;
  isClient: boolean;
  isAircraft: boolean;
  isMiscellaneous: boolean = false;
  hasDocumentNumber: boolean;
  isSubDocument: boolean;
  isCountry: boolean;
  isTask: boolean;
  hasSubdocument: boolean;
  isAirport: boolean;
  isCustomer: boolean;
  isPermitRequiredDoc: boolean;
  docType:string; 
  sortOrderNo: number;
  apisSortOrderNo: number;
  sortOrderCountryReq: number; 
  documentCode: string;
}
