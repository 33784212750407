import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { IdentityRequestModel } from '../models/identity-request.model';
//import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
//import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
//import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
//import { CustomerService } from '../services/customer.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { HotelModel } from '../models/hotel.model';
import { CountryModel } from '../models/country.model';
import { ResponseModel } from '../models/response.model';
import { HotelEditDialogComponent } from './hotel-edit-dialog.component';
import { HotelChainModel } from '../models/hotel-chain.model';
import { ThirdPartyVendorsComponent } from '../common-utility/third-party-vendors.component';
//import { PersonModel } from '../models/person.model';
//import { CustomerPersonEditDialogComponent } from './customer-person-edit-dialog.component';
// { PersonService } from '../services/person.service';
//import { PersonClassModel } from '../models/person-class.model';

@Component({
  selector: 'app-hotellist',
  templateUrl: './hotellist.component.html',
  styleUrls: ['./hotellist.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})

export class HotellistComponent implements OnInit {
  hotellist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = true;
  //selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  //customerGUID: string;
  text_search: string;
  filter_by: string;
  includeDisabledRecords: boolean;
  recordList: HotelModel[];
  //inputPersonClassGUID: string;
  //limitTo: number = 10;
  countryList: CountryModel[];
  selectedCountryGUID: string;
  hotelChainList: HotelChainModel[];

  displayedColumn: string[] = ['hotelName', 'hotelChainName','address', 'near_airport', 'distance', 'remarks', 'disabled', 'action'];
  scrHeight: number;
  isDialog: boolean = false;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog  ,
    @Optional() private readonly _dialogRef: MatDialogRef<HotellistComponent>,@Optional() @Inject(MAT_DIALOG_DATA) private _data: any,  ) {

      if(_data){
        this.isDialog = true;
      }
  }

  getAllData(): Observable<any[]> {
    let getCountryResponse = this._commonService.getCountryListWithUSAFirst<ResponseModel<CountryModel[]>>();
    let getHotelChainResponse = this._commonService.getHotelChainList<ResponseModel<HotelChainModel[]>>();
    return forkJoin([getCountryResponse, getHotelChainResponse]);
    
  }

  ngOnInit() {
    
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.countryList = [];
    this.hotelChainList = [];
    this.selectedCountryGUID = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }
    //this._route.queryParams.subscribe(params => {
    //  if (params["customerGUID"] == null || params["customerGUID"] == "undefined") {
    //    this.inputPersonClassGUID = null;
    //    this.customerGUID = "";
    //    this.text_search = "";
    //    this.filter_by = "Name";
    //  }
    //  else {
    //    this.customerGUID = params["customerGUID"];
    //    this.inputPersonClassGUID = params["personClassGUID"];
    //    this.text_search = params["text_search"];
    //    this.filter_by = params["filter_by"];
    //    this.includeDisabledRecords = params["includeDisabledRecords"];
    //  }

    //});

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.countryList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.hotelChainList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;

      //this.getSearchResults();
      
    });

  }

  initControls() {
    this.hotellist_form = this._formBuilder.group({
      hotelName: [''],
      hotel_chain_select:[''],
      airport: [''],
      city: [''],
      country_select:[''],
      includeDisabledRecords: this.includeDisabledRecords
    });
  }

  get f() { return this.hotellist_form.controls; }

  includeDisabledRecordsOnChange(e: any) {
    this.includeDisabledRecords = e.checked;
    //this.recordList = [];
    //this.totalRecordsText = '';
    //this.totalRecords = 0;
    //this.currentPageIndex = 0;
    //this.totalPageNumber = 0;
    //this.getSearchResults();
    this.resetSearch(e);
  }

  resetSearch(e: any) {
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults();
  }
  //clickShowAllRecords() {
  //  this.errMsg = "";
  //  this.msg = "";
  //  this.recordList = [];
  //  this.totalRecordsText = '';
  //  this.totalRecords = 0;
  //  this.currentPageIndex = 0;
  //  this.totalPageNumber = 0;
  //  this.text_search = "";
  //  this.f.text_search.setValue(this.text_search);
  //  if (this.userType == "internal") {
  //    if (this.f.customer_select.value == "") {
  //      this.errMsg = "Please select a client";
  //      return;
  //    }
  //  }
  //  this.getSearchResults();
  //}

  onSubmit() {

  }

  getSearchResults() {
    this.errMsg = "";
    this.msg = "";

    if (this.f.hotelName.value == "" && this.f.hotel_chain_select.value == "" && this.f.airport.value == "" && this.f.city.value == "" && this.f.country_select.value == "") {
      this.errMsg = "Please fill at least one field to get search results";
      return;
    }

    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new HotelModel();
    request.hotelName = this.f.hotelName.value;
    request.hotelChainGUID = this.f.hotel_chain_select.value;
    request.icao = this.f.airport.value;
    request.city = this.f.city.value;
    request.countryGUID = this.f.country_select.value;
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = true;
    if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      request.isActive = !this.f.includeDisabledRecords.value;
    }
    
    this._commonService.getHotelByConditions<ResponseModel<HotelModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
          }

        }
        else {
          this.recordList = [];
          //this.msg = "No data return.";         
          this.msg = "No records found.";

          this.totalRecordsText = '';
          this.totalRecords = 0;
          this.currentPageIndex = 0;
          this.totalPageNumber = 0;
        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    })


  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
    }

    this.getSearchResults();

  }

  //clickReset() {
  //  this.errMsg = "";
  //  this.msg = "";
  //  this.recordList = [];
  //  this.totalRecordsText = '';
  //  this.totalRecords = 0;
  //  this.currentPageIndex = 0;
  //  this.totalPageNumber = 0;
  //  this.selectedPersonClass = this.personClassList[0];
  //  this.customerGUID = "";
  //  this.text_search = "";
  //  this.filter_by = "Name";
  //  this.initControls();
  //}

  clickEdit(event: any, p: HotelModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //dialogConfig.width = "58em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    let hotelGUID = "";
    if (p != null) {
      hotelGUID = p.hotelGUID;
    }
    dialogConfig.data = { countryId: this.f.country_select.value, hotelChainId: this.f.hotel_chain_select.value, hotelGUID: hotelGUID };
    const dialogRef = this._dialog.open(HotelEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.refresh) {
          if (this.f.hotelName.value == "" && this.f.hotel_chain_select.value == "" && this.f.airport.value == "" && this.f.city.value == "" && this.f.country_select.value == "") {
            this.f.country_select.setValue(result.countryGUID.toLowerCase());
            this.f.hotel_chain_select.setValue(result.hotelChainGUID.toLowerCase());
          }
          this.getSearchResults();
        }

      }
    });

  }



  openThirdPartyVendorComponent(){
    // if(this.isDialog){  
    //   this.clickClose(true);
    // } 
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "23em";//"1000px";
    config.width = "43em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {    isDialog: true,    v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(ThirdPartyVendorsComponent, config);
  }


}
