<div *ngIf="upPII && grantAccessToManageManifestProfiles" style="background-color:#f8f8f8;" >
  <div style="display: flex; justify-content: space-between;" cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>{{dialogTitle}} <span>Travel Membership</span></h5></div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
      <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
      </div>-->
  </div>
  <div>
    <form *ngIf="person_reward_edit_form" [formGroup]="person_reward_edit_form" (ngSubmit)="clickSave()">
      <div style="margin-top:0em; padding-left: 1em;padding-right:1em;">
        <div style="margin-top:0em;background-color:white; padding-left:0.5em;padding-right:.5em" class="ml-1 mr-1 p-3">
          <div style="margin-top:0em; margin-right:0.625em">
            <label for="reward_type_select" style="font-size:small">Type</label>
            <div style="margin-top:-0.5em;">
              <select style="width:max-content" *ngIf="f" formControlName="reward_type_select" class="form-control form-control-sm dropdown" (change)="rewardTypeChange($event)">
                <option value="hotel" selected>Hotel</option>
                <option value="airline">Airline</option>
                <option value="carRental">Car Rental</option>
              </select>
            </div>
          </div>
          <div>
            <div tyle="margin-top:0em; margin-right:0.625em">
              <label style="font-size:small">Company</label>

              <div *ngIf="selectedRewardType=='hotel'" style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="hotel_select" style="min-width:16em;max-width:max-content" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && selectedRewardType=='hotel' && f.hotel_select.errors }">
                  <option value="">Company Name</option>
                  <option *ngFor="let h of hotelList" [ngValue]="h.hotelRewardsProgramGUID">{{h.hotelRewardsProgramName}}</option>
                </select>
              </div>
              <div *ngIf="submitted && selectedRewardType=='hotel' && f.hotel_select.errors" class="invalid-feedback" style="margin-top:-2em">
                <div *ngIf="f.hotel_select.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:13em; margin-top:0.25em">Company is a required field.</div>
              </div>
              <div *ngIf="selectedRewardType=='airline'" style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="airline_select" style="min-width:16em;max-width:max-content" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && selectedRewardType=='airline' && f.airline_select.errors }">
                  <option value="">Company Name</option>
                  <option *ngFor="let a of airlineList" [ngValue]="a.airlineGUID">{{a.airlineDescription}}</option>
                </select>
              </div>
              <div *ngIf="submitted && selectedRewardType=='airline' && f.airline_select.errors" class="invalid-feedback" style="margin-top:-2em">
                <div *ngIf="f.airline_select.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:13em; margin-top:0.25em">Company is a required field.</div>
              </div>
              <div *ngIf="selectedRewardType=='carRental'" style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="car_rental_select" style="min-width:16em;max-width:max-content" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && selectedRewardType=='carRental' && f.car_rental_select.errors }">
                  <option value="">Company Name</option>
                  <option *ngFor="let c of carRentalList" [ngValue]="c.carRentalGUID">{{c.carRentalDescription}}</option>
                </select>
              </div>
              <div *ngIf="submitted && selectedRewardType=='carRental' && f.car_rental_select.errors" class="invalid-feedback" style="margin-top:-2em">
                <div *ngIf="f.car_rental_select.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:13em; margin-top:0.25em">Company is a required field.</div>
              </div>
            </div>

            <div>
              <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                <label for="reward_acct_num" style="font-size:small">Reward Account #</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="reward_acct_num" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.reward_acct_num.errors }" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="addressChange()" />
                </div>
                <div *ngIf="submitted && f.reward_acct_num.errors" class="invalid-feedback" style="margin-top:-2em">
                  <div *ngIf="f.reward_acct_num.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:16em; margin-top:0.25em">Account number is a required field.</div>
                </div>
              </div>
            </div>
            <div class="d-inline-flex form-group">
              <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                <label for="reward_status_desc" style="font-size:small">Reward Status</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <select *ngIf="f" formControlName="reward_status_desc" class="form-control form-control-sm dropdown">
                    <option *ngFor="let r of rewardStatusList" [ngValue]="r.rewardStatusID">{{r.rewardStatusDescription}}</option>
                  </select>
                </div>

              </div>
              <div style="margin-right: 0.625em;">
                <label for="preferred" style="font-size:small">Preferred</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="preferred" class="form-control form-control-sm dropdown">
                    <option value="1" selected>Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              </div>
              <div *ngIf="personRewardAccountsGUID!='' && userType=='internal'" style="margin-top:0em;">
                <label for="data_entry_status_select" style="font-size:small">Data Entry Status</label>
                <div style="margin-top:-0.5em;margin-bottom:0em">
                  <select *ngIf="f" formControlName="data_entry_status_select" class="form-control form-control-sm dropdown">
                    <option *ngFor="let d of dataEntryStatusList" [ngValue]="d.dataEntryStatusID">{{d.dataEntryStatusDescription}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div>
              <div *ngIf="personRewardAccountsGUID!=''" style="display:flex">
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="reward_status" style="font-size:small">Disable Record</label>
                  <div style="margin-top:-0.5em;">
                    <select *ngIf="f" formControlName="reward_status" class="form-control form-control-sm dropdown">
                      <option value="1" selected>No</option>
                      <option value="0">Yes</option>
                    </select>
                  </div>
                </div>
                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="modifiedBy" style="font-size:small">Modified By<!--{{personRewardAccountsGUID!=''? 'Modified by': 'Created by' }}--></label>
                  <div style="margin-top:-0.5em;">
                    <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
                  </div>
                </div>
                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="modifiedDate" style="font-size:small">Modified Date<!--{{personRewardAccountsGUID!=''? 'Modified Date': 'Date' }}--></label>
                  <div style="margin-top:-0.5em;">
                    <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="errMsg!=''" class="d-block" style="display:flex;margin-top:0.25em; white-space:pre-wrap; background-color:#f9d2de; width:18em; height:1.25em; padding-left:0.25em;"><div style="color:black; font-size: small">{{errMsg}}</div></div>
          <div *ngIf="showSuccessMsg">
            <app-success-message [successMsg]=""></app-success-message>
          </div>
        </div>
      </div>
</form>
  </div>

  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;">
    <button mat-button class="btn-high" [disabled]="loading" *ngIf="(personRewardAccountsGUID=='' && userType=='customer') || userType=='internal'" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Save&nbsp;</button>&nbsp;
    <button mat-button *ngIf="personRewardAccountsGUID!='' && userType=='internal'" class="btn-high" (click)="clickAddNew()">&nbsp;Add new Record&nbsp;</button>&nbsp;
    <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white">&nbsp;Close&nbsp;</button>
  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>
