import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from "../services/authenticate.service";
import { FlightRouteModel } from "../models/flight-route.model";
import { MatTooltip } from "@angular/material/tooltip";
import { TripTaskStatusModel } from "../models/trip-task-status.model";
import { AirportModel } from "../models/airport.model";
import { AirportLocatorComponent } from "../airports/airport-locator.component";
import { AirportService } from "../services/airport.service";
import { ResponseModel } from "../models/response.model";

@Component({
  selector: 'app-flight-plan-modal-popup',
  templateUrl: './flight-plan-modal-popup.component.html',
  styleUrls: ['./flight-plan-modal-popup.component.css']
})

export class FlightPlanModalPopupComponent implements OnInit {
  isDialog: boolean = false;
  displayedColumn: string[];
  displayedColumn1: string[] = ['trip_code', 'registration', 'departure', 'ete', 'route'];
  displayedColumn2: string[] = ['preferred', 'airport', 'distance', 'airport_of_entry', 'restrictions', 'slots', 'ppr', 'landing_permit'];

  recordList: any[] = [];
  filteredRecordList: any[] = [];
  taskStatusList: TripTaskStatusModel[];
  userType: string = "";
  showSpin: boolean = false;
  departureICAO: string = "";
  arrivalICAO: string = "";
  aircraftGUID: string = "";
  tripCodeGUID: string = "";
  customerGUID: string = "";
  view: string = "";
  tripCode: string = "";
  alternateDepartureAirports: AirportModel[];
  alternateArrivalAirports: AirportModel[];
  filteredAlternateDepartureAirports: AirportModel[];
  filteredAlternateArrivalAirports: AirportModel[];
  filteredPreferredAlternateDepartureAirports: AirportModel[];
  filteredPreferredAlternateArrivalAirports: AirportModel[];
  preferredAirportsFetched: boolean = false;
  topThreeAirportsFetched: boolean = false;
  recordCount: number = 0;
  distance: number = 50;
  showPreferred: boolean = true;
  showTopThree: boolean = true;
  totalAlternateDepartureAirports: number = 0;
  totalAlternateArrivalAirports: number = 0;
  airportID: number = 0;
  nextAirportID: number = 0;
  farTypeID: number = 0;

  @ViewChild("routeTooltip") routeTooltip: MatTooltip;

  constructor(@Optional() private readonly _dialogRef: MatDialogRef<FlightPlanModalPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _dialog: MatDialog,
    private readonly _authService: AuthenticateService,
    private readonly _airportService: AirportService) {

  }

  ngOnInit() {
    this.displayedColumn = this.displayedColumn1;

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    if (this.data) {
      this.departureICAO = this.data.departureICAO;
      this.arrivalICAO = this.data.arrivalICAO;
      this.aircraftGUID = this.data.aircraftGUID;
      this.customerGUID = this.data.customerGUID;
      this.taskStatusList = this.data.taskStatusList;
      this.view = this.data.view;
      this.tripCode = this.data.tripCode;
      this.airportID = this.data.airportID;
      this.nextAirportID = this.data.nextAirportID;
      this.farTypeID = this.data.farTypeID;

      if (this.view === 'alternate_airports') {
        this.displayedColumn = this.displayedColumn2;
        this.findNearbyAirportsByDistance(this.distance, true, true);
        
      }

      else if (this.userType == "internal") {
        this.data.data.forEach(item => {
          this.filteredRecordList.push(item);
        })
      }

      this.recordCount = this.filteredRecordList.length;
    }
  }


  findNearbyAirportsByDistance(distance: number, isPreferred: boolean, topThree: boolean) {
    let request = new AirportModel();
    request.airportID = this.airportID;
    request.farTypeID = this.farTypeID;
    request.countryId = 0;
    request.distance = distance;
    request.isPreferred = isPreferred;
    if(topThree)
      request.recordLimit = 3;

    this.showSpin = true;
    this._airportService.getNearbyAirportList<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.alternateDepartureAirports = response.model;
        this.filteredAlternateDepartureAirports = response.model;
        this.filteredPreferredAlternateDepartureAirports = response.model;
        this.totalAlternateDepartureAirports = this.filteredAlternateDepartureAirports.length;

       // this.filterNearbyAirports(this.showPreferred, this.showTopThree);
      }
    })

    request.airportID = this.nextAirportID;
    this._airportService.getNearbyAirportList<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.alternateArrivalAirports = response.model;
        this.filteredAlternateArrivalAirports = response.model;
        this.filteredPreferredAlternateArrivalAirports = response.model;
        this.totalAlternateArrivalAirports = this.filteredAlternateArrivalAirports.length;

        this.showSpin = false;
       // this.filterNearbyAirports(this.showPreferred, this.showTopThree);
      }
    })
  }

  toggleShowTopThree() {
    this.showTopThree = !this.showTopThree;
  
    this.findNearbyAirportsByDistance(this.distance, this.showPreferred, this.showTopThree)
  }

  toggleShowPreferred() {
    this.showPreferred = !this.showPreferred;

    this.findNearbyAirportsByDistance(this.distance, this.showPreferred, this.showTopThree)
  }

  filterNearbyAirports(showPreferred: boolean, showTopThree: boolean) {
    this.filteredAlternateDepartureAirports = this.alternateDepartureAirports.filter(x => x.distanceFromAirport <= Number(this.distance));
    this.filteredAlternateArrivalAirports = this.alternateArrivalAirports.filter(x => x.distanceFromAirport <= Number(this.distance));

    if (showPreferred) {
      this.filteredAlternateDepartureAirports = this.filteredPreferredAlternateDepartureAirports;
      this.filteredAlternateArrivalAirports = this.filteredPreferredAlternateArrivalAirports;
    }
    if (showTopThree) {
      this.filteredAlternateDepartureAirports = this.filteredAlternateDepartureAirports.slice(0, 3);
      this.filteredAlternateArrivalAirports = this.filteredAlternateArrivalAirports.slice(0, 3);
    }

    this.totalAlternateDepartureAirports = this.filteredAlternateDepartureAirports.length;
    this.totalAlternateArrivalAirports = this.filteredAlternateArrivalAirports.length;
  }

  openAirportDetails(airportCode: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "78em";//"1000px";
    dialogConfig.height = "38.75em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      searchKey: airportCode
    };

    const dialogRef = this._dialog.open(AirportLocatorComponent, dialogConfig);
  }

  openInNew(e: any) {
    window.open('/ground-stops/trip-details-internal?tripId=' + e.tripCodeGUID +
      '&aircraftId=' + e.aircraftGUID + '&customerId=' + e.customerGUID + '&pf=&tType=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }

  displayTooltip() {
    this.routeTooltip.disabled = false;
    this.routeTooltip.show()
    setTimeout(() => {
      this.routeTooltip.disabled = true;
    }, 1000);
  }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return `${value}`;
  }

  copyPermitData() {
    let s: string = "";

    this.filteredRecordList.forEach(record => {
      if (record.countryName !== null) {
        s += " " + record.countryName;
      }

      if (record.fpiConfirmationReference !== null) {
        s += " " + record.fpiConfirmationReference;
      }

      if (record.serviceDate !== null && record.serviceTime !== null) {
        s += " " + record.serviceDate + " " + record.serviceTime;
      }

      s += '\n';
    })

    return s;
  }
}
