import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { GroundStopService } from '../services/ground-stop.service';
import { ResponseModel } from '../models/response.model';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { AuthenticateService } from '../services/authenticate.service';

export class TaskGroup {
  taskGroupDescription: string;
  taskStatusList: TripTaskStatusModel[];
}
@Component({
  selector: 'app-icon-legend-dialog',
  templateUrl: './icon-legend-dialog.component.html',
  styleUrls: ['./icon-legend-dialog.component.css']
})

export class IconLegendDialogComponent implements OnInit {
  dialogTitle: string;
  message: string;
  taskStatusList: TripTaskStatusModel[];
  taskGroupList: TaskGroup[];
  legendType: string
 userType: string;
  constructor(private _dialogRef: MatDialogRef<IconLegendDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _groundStopService: GroundStopService, private readonly _authService: AuthenticateService ) {
    //_dialogRef.disableClose = true;
    this.dialogTitle = _data.dialogTitle;
    //this.message = _data.message;
    this.legendType = _data.legendType
  }

  ngOnInit() {
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this._authService.updateAccessTime();
    this.taskStatusList = [];
    this.taskGroupList = [];
    this._groundStopService.getTripTaskStatusList<ResponseModel<TripTaskStatusModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.taskStatusList = response.model;
        let tempGroup: string = "";
        this.taskStatusList.sort((a, b) => a.taskGroupSort - b.taskGroupSort);
        this.taskStatusList.forEach(x => {
          if (tempGroup != x.taskGroupDescription) {
            tempGroup = x.taskGroupDescription;
            let y = new TaskGroup();
            y.taskGroupDescription = x.taskGroupDescription;
            y.taskStatusList = this.taskStatusList.filter(a => a.taskGroupDescription == tempGroup);
            this.taskGroupList.push(y);
          }
        });
      }
      
    })

  }

  clickClose() {
    this._dialogRef.close();
  }
}
