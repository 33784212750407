import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { PersonClassModel } from '../models/person-class.model';
import { PersonService } from '../services/person.service';
import { CountryModel } from '../models/country.model';
import { PersonRoleModel } from '../models/person-role.model';
import { PersonModel } from '../models/person.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { PersonTravelDocumentModel } from '../models/person-travel-document.model';

@Component({
  selector: 'app-person-travel-doc-country-selection-dialog',
  templateUrl: './person-travel-doc-country-selection-dialog.component.html',
  styleUrls: ['./person-travel-doc-country-selection-dialog.component.css']
})

export class PersonTravelDocCountrySelectionDialogComponent implements OnInit {
  //@Input() dialogTitle: string;
  //@Input() personGUID: string;
  //@Input() personTravelDocumentGUID: string;
  personGUID: string;
  personTravelDocumentGUID: string;
  //@Input() inputPersonClass: PersonClassModel;
  returnObj: ReturnObjModel;
  countryList: CountryModel[];
  avaiCountryList: CountryModel[];
  selCountryList: CountryModel[];
  displayedColumn: string[] = ['country'];
  showSpin: boolean = true;
  grantAccessToManageManifestProfiles: boolean;
  constructor(private readonly _dialogRef: MatDialogRef<PersonTravelDocCountrySelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _personService: PersonService,
    private readonly _dialogService: DialogService,
    private readonly _dialog: MatDialog
  ) {
    this.personGUID = _data.personGUID;
    this.personTravelDocumentGUID = _data.personTravelDocumentGUID;
    this.selCountryList=_data.selectedCountryList  

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    if (!this.grantAccessToManageManifestProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.countryList = [];
    this.avaiCountryList = [];
    this.showSpin = true;
    let request = new PersonTravelDocumentModel();
    request.personTravelDocumentGUID = this.personTravelDocumentGUID;
    request.countryGUIDList = "";
    if (this.selCountryList.length > 0) {
      request.countryGUIDList = Array.prototype.map.call(this.selCountryList, s => s.countryGUID).toString();;
    }
    request.personGUID = this.personGUID;
    this._personService.getPersonTravelDocumentCountryList<ResponseModel<CountryModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.countryList = response.model;
          this.setCountryList();
          this.showSpin = false;
        }
       
      }

    })


  }

  clickAddItem(e: any, item: CountryModel) {
    this.countryList.forEach(x => {
      if (x.countryGUID.toLowerCase() == item.countryGUID.toLowerCase()) {
        x.selected = true;
      }
    })
    this.setCountryList();

  }

  clickRemoveItem(e: any, item: CountryModel) {
    this.countryList.forEach(x => {
      if (x.countryGUID.toLowerCase() == item.countryGUID.toLowerCase()) {
        x.selected = false;
      }
    })
    this.setCountryList();

  }

  setCountryList() {
    this.avaiCountryList = this.countryList.filter(u => u.selected == false);
    this.selCountryList = this.countryList.filter(u => u.selected == true);
  }

  clickSelectAll() {
    this.countryList.forEach(x => {
      x.selected = true;
    })

    this.setCountryList();
  }

  clickRemoveAll() {
    this.countryList.forEach(x => {
      x.selected = false;
    })

    this.setCountryList();
  }


  clickClose() {
    this._dialogRef.close(this.selCountryList);
  }

}
