<div *ngIf="upPII && grantAccessToManageManifestProfiles" style="margin-top:0em; padding-top:0em; display:flex">
  <form *ngIf="customer_person_maillist_form" [formGroup]="customer_person_maillist_form">
    <div>
      
      <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container">
        <tr>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef style="width:20%; border-bottom:none;text-align:center !important">
              <!--<div style="display:flex; justify-content:flex-start;">
                <span class="material-icons-outlined" style="flex-grow:1; flex-basis: 0;padding-left:.125em">
                  contact_mail
                </span>
                <div style="flex-grow:2; flex-basis: 0;padding-left:1.25em">Type</div>
              </div>-->
              <div style="width:100%;padding-right:.25em;padding-left:.25em" class="d-flex justify-content-between">
                <div class="material-icons-outlined">contact_mail</div>
                <div>Type</div>
                <div>&nbsp;</div>
              </div>
</th>
            <td mat-cell *matCellDef="let element">{{element.mailAddressTypeList}}</td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef style="width:35%; border-bottom:none">
              <div style="margin-top:-0.25em;">Address</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.fullAddress}}</td>
          </ng-container>
          <ng-container matColumnDef="permanentAddress">
            <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
              <div style="margin-top:0em;">Permanent</div><div>Address</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.isPermanentAddress? 'Yes': ''}}</td>
          </ng-container>
          <ng-container matColumnDef="usedForAPIS">
            <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
              <div style="margin-top:0em;">Used for</div><div>APIS</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.isAPISAddress? 'Yes': ''}}</td>
          </ng-container>
          <ng-container matColumnDef="data_entry_status">
            <th mat-header-cell *matHeaderCellDef style="width:15%">
              <div style="margin-top:-0.25em;">Data Entry Status</div>
            </th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger': element.dataEntryStatusID!=2}">{{ element.dataEntryStatusDescription }}</td>
          </ng-container>
          <ng-container matColumnDef="disabled">
            <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:5%; border-bottom:none">
              <div style="margin-top:-0.25em;">Disabled</div>
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="hideColumn">{{element.isActive? '&nbsp;': 'Yes'}}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
              <div>Action</div><!--<div style="margin-top:0em"><a mat-button class="btn-high grid" style="align-self:center" (click)="clickEdit($event, null);">Add</a></div>-->
            </th>
            <td mat-cell *matCellDef="let element">
              <a mat-button class="btn-low grid" *ngIf="userType=='internal'" style="align-self:center">
                Edit
              </a>
              <a mat-button class="btn-low grid" *ngIf="userType=='customer'" style="align-self:center">
                View
              </a>
            </td>
          </ng-container>
        </tr>

        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"  (click)="clickEdit($event, row);"></tr>
      </table>
    </div>
    <!--<div>
      <mat-paginator *ngIf="recordList && totalPageNumber>1" style="border: none;width:80em;" #paginator [length]="totalRecords" [pageSize]="pageSize"
                     [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
      </mat-paginator>
    </div>-->
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
