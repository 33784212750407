<div style="margin-top: 0em; padding-top: 0em; width: 88%; margin-bottom: 2em; ">

  <form *ngIf="currency_form" [formGroup]="currency_form">
    <div style="background-color: #f8f8f8; /* padding: 1em; min-height: 28em; */ border: 1px solid; width: 32em; margin: 2em 0em 1em 2em ">
      <div style="display: flex;padding-left:1em;" cdkDrag
           cdkDragRootElement=".cdk-overlay-pane"
           cdkDragHandle>
        <div style="margin-top:0.3125em;"><h5>Import Exchange Rates </h5></div>
      </div>
      <div>
        <div style="display:flex; margin-top:-0.5em; padding-left: 1em; padding-right:1em">
          <div style="margin-top: 0em; min-height: 7em; width:30em;padding-top:0.5em">
            <div style="background-color: white;min-height:6em; font-size: small; padding-left:0.5em; padding-right:0.5em; margin-top: 0em;padding-top:0.5em">
              <div style="margin-top:0.5em; margin-right:0.625em;margin-left:0.5em ">
                <!--<div style="display: flex; padding: 1em;">
    <label for="fs_select" style="font-size:small">Select Fuel Supplier:&nbsp;</label>
    <div>
      <div style="margin-top: 0em; margin-left: 1em;">
        <select formControlName="fs_select" style="font-size: small;border-radius:0.25em;width: 15em; background-color:#eaf0fd" (change)="fuelSupplierChange($event)">
          <option value="default">Select a Fuel Supplier</option>
          <option *ngFor="let fuel of fuelSupplierList" [value]="fuel.lK_FuelSupplierGUID">{{fuel.fuelSupplierShortname}}</option>
        </select>
      </div>
      <div *ngIf="(submitted || validatedAirport) && f.customer_select.errors" class="invalid-feedback" style="margin-top:-2em">
        <div *ngIf="f.customer_select.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:16em">Please select Fuel Supplier.</div>
      </div>
    </div>
  </div>-->
                <div style="display: flex; padding: 1em 1em 2em 1em;">
                  <!--<input [attr.disabled]="isFileInputDisabled ? '' : null" #document formControlName="document" id="document" type="file" [uploader]="uploader" accept="*" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />-->
                  <input style="display: none"   #document  id="document" type="file" [uploader]="uploader" accept="*" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />
                  <!--<input style="display: none" type="file" (change)="onFileSelect($event)" #hiddenfileinput>-->
                  <button mat-button class="button"   (click)="document.click()">Select a File to Import</button><!--<i class="material-icons left">cloud</i>-->
                  <input #filename [attr.disabled]="true" id="filename" type="text" class="text-line" [value]="attachedfilename" />

                </div>
              </div>
              <div>
              </div>
              <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em; white-space:pre-wrap"><label style="color:black; font-size: small">{{errMsg}}</label></div>
              <div *ngIf="showSuccessMsg" style="margin-top: 0.5em;">
                <app-success-message [successMsg]="successMsg"></app-success-message>
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 0.5em 0em 0.6em 1em; display: flex; ">
          <button [style.display]="showButton ? 'block' : 'none'" id="btnsubmit" mat-button [disabled]="loading" class="btn-high" (click)="clickSend()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Submit
          </button>
          <div *ngIf="revisefuelpricinglist" style=" font-size: small;">
            Success: {{successCount}}     Failed:  {{failureCount}}
          </div>
          <button *ngIf="fromCurrencyMain" [style.display]="fromCurrencyMain ? 'block' : 'none'" id="btnsubmit" mat-button [disabled]="loading" class="btn-low" (click)="clickClose()" style="margin-left: 0.5em">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">close</span>Close
          </button>
        </div>

        <!--</form>-->
      </div>

    </div>
 
  </form>

</div>

