import { Component } from '@angular/core';

@Component({
    selector: 'app-customer-bi-dashboard',
  templateUrl: './customer-bi-dashboard.component.html',
  styleUrls: ['./customer-bi-dashboard.component.css']
})
/** airport-bi-dashboard component*/
export class CustomerBiDashboardComponent {
  
    constructor() {

    }
}
