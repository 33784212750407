import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';

import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { PersonTravelDocSelectionDialogComponent } from './person-travel-doc-selection-dialog.component';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { ReturnObjModel } from '../models/return-obj.model';
import { TripModel } from '../models/trip.model';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopManifestTypeModel } from '../models/ground-stop-manifest-type.model';
import { GroundStopModel } from '../models/ground-stop.model';

@Component({
  selector: 'app-ground-stop-crew-pax-edit-dialog',
  templateUrl: './ground-stop-crew-pax-edit-dialog.component.html',
  styleUrls: ['./ground-stop-crew-pax-edit-dialog.component.css']
})

export class GroundStopCrewPaxEditDialogComponent implements OnInit {
  
  dialogTitle: string;
  customerGUID: string;
  groundStopGUID: string;
  //maxGroundStopSequenceID: number;
  currentGroundStopSequenceID: number;
  tripCodeGUID: string;
  nextGroundStopGUID: string;
  currentIndex: number;
  ground_stop_crew_pax_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  manifest_type: string;
  personType: string;
  includePassport: boolean;
  includePICSICTravelDoc: boolean;
  personList: GroundStopManifestModel[];
  avaiPersonList: GroundStopManifestModel[];
  selPersonList: GroundStopManifestModel[];
  selPIC: GroundStopManifestModel[];
  selSIC: GroundStopManifestModel[];
  selCrewList: GroundStopManifestModel[];
  selPAXList: GroundStopManifestModel[];
  
  selAllCrewPaxList: GroundStopManifestModel[];
  paxCount: string;
  crewCount: string;
  displayedColumn: string[] = ['personName'];
  isModified: boolean = false;
  showLoadCrewPreviousDeparture: boolean = false;
  showLoadCrewArrival: boolean = false;
  showLoadPaxPreviousDeparture: boolean = false;
  showLoadPaxArrival: boolean = false;
  dataChanged: boolean = false;
  showSuccessMsg: boolean = false;
  returnObj = new ReturnObjModel();
  lockdownTrip: boolean = false;
  countryGUID: string;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  icao_iata: string;
  arrivalDateTime: string;
  departureDateTime: string;
  arrivalDateTimeLocal: string;
  departureDateTimeLocal: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  registration: string;
  utcTimeConversion: string;
  tripCode: string;
  icao: string;
  isDeptFerryFlight: boolean;
  isArrFerryFlight: boolean;
  additional_options_pic:string="0";
  additional_options_sic: string = "0";
  additional_options_crew: string = "0";
  additional_options_pax: string = "0";
  upPII: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<GroundStopCrewPaxEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService,
    private readonly _dialogService: DialogService
  ) {
    this.groundStopGUID = _data.groundStopGUID;
    if (_data.customerGUID == null) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }
    this.dialogTitle = _data.dialogTitle;
    this.currentGroundStopSequenceID = _data.currentGroundStopSequenceID;
    this.currentIndex = _data.currentIndex;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.manifest_type = _data.manifestType;

  }

  getAllData(): Observable<any[]> {
    let request = new GroundStopManifestModel();
    request.groundStopGUID = this.groundStopGUID;
    request.groundStopManifestType = this.manifest_type;
    let getSelCrewPaxeResponse = this._groundStopAdvancedService.getPersonForEditBygsId<ResponseModel<GroundStopManifestModel[]>>(request);    
    //let obj1 = new TripModel();
    //obj1.tripCodeGUID = this.tripCodeGUID;
    //let lockdownTripResponse = this._groundStopService.getLockdownTripBytId(obj1);
    let request1 = new GroundStopModel();
    request1.groundStopGUID = this.groundStopGUID;
    request1.tripCodeGUID = this.tripCodeGUID;
    let getGSResponse = this._groundStopAdvancedService.getGroundStopForTaskEditBygsId<ResponseModel<GroundStopModel>>(request1); 
    return forkJoin([getSelCrewPaxeResponse, getGSResponse]);
  }

  ngOnInit() {
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    //if (this.manifest_type=="")
    this.manifest_type = "Arrival";  
    if (this.currentIndex==0) {
      this.manifest_type = "Departure";
    }
    
    this.personType = "";
    this.includePassport = false;
    this.includePICSICTravelDoc = false;
    
    this.personList = [];
    this.avaiPersonList = [];
    this.selPersonList = [];
    this.selPIC=[];
    this.selSIC=[];
    this.selCrewList=[];
    this.selPAXList=[];
   
    this.selAllCrewPaxList = [];
    this.dataChanged = false;
    this.showSuccessMsg = false;
    this.lockdownTrip = false;
    this.isDeptFerryFlight = false;
    this.isArrFerryFlight = false;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.selAllCrewPaxList = responses[0].model;
          //this.setSelectedPersonList(this.personType);
          let crewlist: GroundStopManifestModel[];
          let paxlist: GroundStopManifestModel[];
          crewlist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription != 'PAX');
          paxlist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'PAX');
          if (crewlist.length == 0 || paxlist.length == 0) {
            if (this.manifest_type == 'Arrival') {
              //this.showLoadPreviousDeparture = true;            
              this.checkShowPreviousDeparturePersonInfo();
            }
            else {
              if (this.manifest_type == 'Departure') {
                //this.showLoadArrival = true;                
                this.checkShowArrivalPersonInfo();
              }
            }
          }
          
          //if (this.selAllCrewPaxList.length == 0) {
          //  if (this.manifest_type == 'Arrival') {
          //    //this.showLoadPreviousDeparture = true;            
          //    this.checkShowPreviousDeparturePersonInfo();                       
          //  }
          //  else {
          //    if (this.manifest_type == 'Departure') {
          //      //this.showLoadArrival = true;                
          //      this.checkShowArrivalPersonInfo();              
          //    }
          //  }
          //}


        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      //if (responses[1] != null) {
      //  if (responses[1].code == "200" && responses[1].message == "") {
      //    this.lockdownTrip = responses[1].model;
      //  }
      //  else {
      //    if (responses[1].code == "401") {
      //      this._authService.signOut();
      //    }
      //  }
      //}
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          let gs = new GroundStopModel();
          gs = responses[1].model;
          this.tripCode = gs.tripCode;
          this.icao = gs.icao;
          this.icao_iata = gs.icaO_IATA;
          
          this.arrivalDateTime = gs.arrivalDateTextUTC;
          this.departureDateTime = gs.departureDateTextUTC;
          this.arrivalDateTimeLocal = gs.arrivalDateTextLocal;
          this.departureDateTimeLocal = gs.departureDateTextLocal;        
          this.registration = gs.registration;
          this.aircraftManufacturer = gs.aircraftManufacturer;
          this.aircraftModel = gs.aircraftModel;
          this.aircraftInfo = gs.aircraftInfo;
          this.countryGUID = gs.countryGUID;
          this.lockdownTrip = gs.lockdownTrip;
          this.utcTimeConversion = gs.utcTimeConversion;
          this.isDeptFerryFlight = gs.isDeptFerryFlight;
          this.isArrFerryFlight = gs.isArrFerryFlight;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.manifest_type == 'Arrival' && this.isArrFerryFlight==true){
        this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription !='PAX');
      }
      if (this.manifest_type == 'Departure' && this.isDeptFerryFlight == true) {
        this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription != 'PAX');
      }
      this.setSelectedPersonList(this.personType);
      this.initControls();
      this.setAdditionalOptions();
      this.showSpin = false;

    });

  }

  initControls() {
    this.ground_stop_crew_pax_edit_form = this._formBuilder.group({
      manifest_type: [this.manifest_type],
      saveCrewToAllStops: [],
      savePaxToAllStops:[],
      //includePassport: [this.includePassport],
      //includePICSICTravelDoc: [this.includePICSICTravelDoc],
      search_keyword: [''],
      //loadPreviousDeparture:[false],
      //loadArrival: [false]
      loadCrewPreviousDeparture: [false],
      loadCrewArrival: [false],
      loadPaxPreviousDeparture: [false],
      loadPaxArrival: [false],
      isDeptFerryFlight: [this.isDeptFerryFlight],
      isArrFerryFlight: [this.isArrFerryFlight],
      additional_options_pic: [this.additional_options_pic],
      additional_options_sic: [this.additional_options_sic],
      additional_options_crew: [this.additional_options_crew],
      additional_options_pax: [this.additional_options_pax],
    });
  }

  get f() { return this.ground_stop_crew_pax_edit_form.controls; }

  setAdditionalOptions() {
    let piclist: GroundStopManifestModel[] = [];
    let siclist: GroundStopManifestModel[] = [];
    let crewlist: GroundStopManifestModel[] = [];
    let paxlist: GroundStopManifestModel[] = [];
    piclist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'PIC' && x.capacityID == 12);
    if (piclist.length > 0) {
      this.additional_options_pic = "12";
    }
    else {
      this.additional_options_pic = "0";
    }
   
    this.f.additional_options_pic.setValue(this.additional_options_pic);
    siclist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'SIC' && x.capacityID==13);
    if (siclist.length > 0) {
      this.additional_options_sic = "13";
    }
    else {
      this.additional_options_sic = "0";
    }
    this.f.additional_options_sic.setValue(this.additional_options_sic);
    crewlist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'Crew' && x.capacityID ==6);
    if (crewlist.length > 0) {
      this.additional_options_crew = "6";
    }
    else {
      crewlist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'Crew' && x.capacityID == 7)
      if (crewlist.length > 0) {
        this.additional_options_crew = "7";
      }
      else {
        crewlist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'Crew' && x.capacityID == 8)
        if (crewlist.length > 0) {
          this.additional_options_crew = "8";
        }
        else {
          this.additional_options_crew = "0";
        }
      }
    }
    this.f.additional_options_crew.setValue(this.additional_options_crew);
    paxlist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'PAX' && x.capacityID == 9);
    if (paxlist.length > 0) {
      this.additional_options_pax = "9";
    }
    else {
      paxlist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'PAX' && x.capacityID == 10)
      if (paxlist.length > 0) {
        this.additional_options_pax ="10";
      }
      else {
        paxlist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'PAX' && x.capacityID == 11)
        if (paxlist.length > 0) {
          this.additional_options_pax = "11";
        }
        else {
          this.additional_options_pax = "0";
        }
      }
    }
    this.f.additional_options_pax.setValue(this.additional_options_pax);
  }

  changeManifestType(e: any) {
    //if (this.currentGroundStopSequenceID <= 1 || this.currentGroundStopSequenceID == this.maxGroundStopSequenceID) {
    //  return false;
    //}
    this.f.loadCrewPreviousDeparture.setValue(false);
    this.f.loadPaxPreviousDeparture.setValue(false);
    this.f.loadCrewArrival.setValue(false);
    this.f.loadPaxArrival.setValue(false);
    if ((e.target.value == "Arrival" && this.currentIndex == 0) || ((this.nextGroundStopGUID == "" || this.nextGroundStopGUID == null || this.nextGroundStopGUID == undefined)
      && e.target.value == "Departure")) {
      return false;
    }
    
    if (this.dataChanged == true) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before move to " + e.target.value + "?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave(this.manifest_type);
        }
        else {
          this.manifest_type = e.target.value;
          this.changeManifestTypeNext();
        }
      });
    }
    else {
      this.manifest_type = e.target.value;
      this.changeManifestTypeNext();
    }
    

  }

  changeManifestTypeNext() {
    this.selAllCrewPaxList = [];
    this.showSpin = true;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.personType = "";
          this.selAllCrewPaxList = [];
          this.selPersonList = [];
          this.avaiPersonList = [];
          this.selAllCrewPaxList = responses[0].model;
          if (this.manifest_type == 'Arrival' && this.isArrFerryFlight == true) {
            this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription != 'PAX');
          }
          if (this.manifest_type == 'Departure' && this.isDeptFerryFlight == true) {
            this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription != 'PAX');
          }
          this.setSelectedPersonList("");
          this.dataChanged = false;
          let crewlist: GroundStopManifestModel[];
          let paxlist: GroundStopManifestModel[];
          crewlist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription != 'PAX');
          paxlist = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'PAX');
          if (crewlist.length == 0 || paxlist.length == 0) {
            if (this.manifest_type == 'Arrival') {
              //this.showLoadPreviousDeparture = true;            
              this.checkShowPreviousDeparturePersonInfo();
            }
            else {
              if (this.manifest_type == 'Departure') {
                //this.showLoadArrival = true;                
                this.checkShowArrivalPersonInfo();
              }
            }
          }
          else {
            this.setAdditionalOptions();
          }
          //if (this.selAllCrewPaxList.length == 0) {
          //  if (this.manifest_type == 'Arrival') {
          //    //this.showLoadPreviousDeparture = true;             
          //    this.checkShowPreviousDeparturePersonInfo();
          //  }
          //  else {
          //    if (this.manifest_type == 'Departure') {
          //      //this.showLoadArrival= true;
          //      this.checkShowArrivalPersonInfo();
          //    }
          //  }
          //}

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.showSpin = false;

    });
  }

  checkShowPreviousDeparturePersonInfo() {
    let request = new GroundStopManifestModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopSequenceID = this.currentGroundStopSequenceID;
    request.groundStopManifestType = "Departure";
    this._groundStopAdvancedService.getPersonForEditByprevgsId<ResponseModel<GroundStopManifestModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          //this.showLoadPreviousDeparture = true;
          let crewlist: GroundStopManifestModel[];
          let paxlist: GroundStopManifestModel[];
          crewlist=response.model.filter(x=>x.groundStopManifestTypeDescription!='PAX')
          paxlist = response.model.filter(x => x.groundStopManifestTypeDescription == 'PAX')
          if (this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription != 'PAX').length==0 && crewlist.length > 0) {
            this.showLoadCrewPreviousDeparture = true;
          }
          if (this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'PAX').length == 0 && paxlist.length > 0) {
            this.showLoadPaxPreviousDeparture = true;
          }
          
        }
      }
    })
  }

  checkShowArrivalPersonInfo() {
    let request = new GroundStopManifestModel();
    request.groundStopGUID = this.groundStopGUID;
    request.groundStopManifestType = "Arrival";
    this._groundStopAdvancedService.getPersonForEditBygsId<ResponseModel<GroundStopManifestModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          //this.showLoadArrival = true;
          let crewlist: GroundStopManifestModel[];
          let paxlist: GroundStopManifestModel[];
          crewlist = response.model.filter(x => x.groundStopManifestTypeDescription != 'PAX')
          paxlist = response.model.filter(x => x.groundStopManifestTypeDescription == 'PAX')
          if (this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription != 'PAX').length == 0 &&  crewlist.length > 0) {
            this.showLoadCrewArrival = true;
          }
          if (this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'PAX').length == 0 && paxlist.length > 0) {
            this.showLoadPaxArrival = true;
          }
          
        }
      }
    })
  }

  loadCrewPreviousDepartureChange(e: any) {
    if (this.f.loadCrewPreviousDeparture.value == true) {
      this.loadCrewPaxByPreviousGroundStop('departure', "Crew");
    }
    else {      
      for (var i = (this.selAllCrewPaxList.length - 1); i >= 0; i--) {
        if (this.selAllCrewPaxList[i].groundStopManifestTypeDescription != 'PAX' && this.selAllCrewPaxList[i].loadFromPrevDeparture==true) {
          this.selAllCrewPaxList.splice(i, 1);
        }
      }
      this.setSelectedPersonList(this.personType);
      this.loadCrewPaxByGroundStopGUID('arrival', "Crew");
      this.setSelectedPersonList("");
      
    }
    
  }

  loadPaxPreviousDepartureChange(e: any) {
    if (this.f.loadPaxPreviousDeparture.value == true) {
      this.loadCrewPaxByPreviousGroundStop('departure', "PAX");
    }
    else {
      for (var i = (this.selAllCrewPaxList.length - 1); i >= 0; i--) {
        if (this.selAllCrewPaxList[i].groundStopManifestTypeDescription == 'PAX' && this.selAllCrewPaxList[i].loadFromPrevDeparture == true) {
          this.selAllCrewPaxList.splice(i, 1);
        }
      }
      this.setSelectedPersonList(this.personType);
      this.loadCrewPaxByGroundStopGUID('arrival', "PAX");
      this.setSelectedPersonList("");
    }

  }

  loadCrewArrivalChange(e: any) {
    if (this.f.loadCrewArrival.value == true) {
      this.loadCrewPaxByGroundStopGUID('arrival', "Crew");
    }
    else {
      for (var i = (this.selAllCrewPaxList.length - 1); i >= 0; i--) {
        if (this.selAllCrewPaxList[i].groundStopManifestTypeDescription != 'PAX' && this.selAllCrewPaxList[i].loadFromArrival == true) {
          this.selAllCrewPaxList.splice(i, 1);
        }
      }
      this.setSelectedPersonList('');
    }

  }

  loadPaxArrivalChange(e: any) {
    if (this.f.loadPaxArrival.value == true) {
      this.loadCrewPaxByGroundStopGUID('arrival', "PAX");
    }
    else {
      for (var i = (this.selAllCrewPaxList.length - 1); i >= 0; i--) {
        if (this.selAllCrewPaxList[i].groundStopManifestTypeDescription == 'PAX' && this.selAllCrewPaxList[i].loadFromArrival == true) {
          this.selAllCrewPaxList.splice(i, 1);
        }
      }
      this.setSelectedPersonList('');
    }

  }

  loadCrewPaxByPreviousGroundStop(loadType: string, personType: string) {
    let request = new GroundStopManifestModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopSequenceID = this.currentGroundStopSequenceID;
    request.groundStopManifestType = loadType;
    
    this._groundStopAdvancedService.getPersonForEditByprevgsId<ResponseModel<GroundStopManifestModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          //this.selAllCrewPaxList = response.model;
          if (personType == 'Crew') {
            response.model.forEach(x => {
              if (x.groundStopManifestTypeDescription != 'PAX') {
                let i: number;
                i= this.selAllCrewPaxList.findIndex(u => u.groundStopManifestTypeDescription == "PIC");
                if (x.groundStopManifestTypeDescription == "PIC" && i!=-1) {
                  this.selAllCrewPaxList[i] = x;
                }
                else {
                  i = this.selAllCrewPaxList.findIndex(u => u.groundStopManifestTypeDescription == "SIC");
                  if (x.groundStopManifestTypeDescription == "SIC" && i != -1) {
                    this.selAllCrewPaxList[i] = x;
                  }
                  else {
                    this.selAllCrewPaxList.push(x);
                  }
                }
                x.loadFromPrevDeparture = true;
                
              }
            })
          }
          else {          
            response.model.forEach(x => {
              if (x.groundStopManifestTypeDescription == 'PAX') {
                x.loadFromPrevDeparture = true;
                this.selAllCrewPaxList.push(x);
              }
            })
          }
          
          this.setSelectedPersonList("");
          this.dataChanged = true;
        }
        this.setAvailablePersonList(this.personType);
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }

      }
      this.loading = false;
    })
  }


  loadCrewPaxByGroundStopGUID(loadType: string, personType: string) {
    let request = new GroundStopManifestModel();
    request.groundStopGUID = this.groundStopGUID;
    request.groundStopManifestType = loadType;
    
    this._groundStopAdvancedService.getPersonForEditBygsId<ResponseModel<GroundStopManifestModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          //this.selAllCrewPaxList = response.model;
          if (personType == 'Crew') {
            response.model.forEach(x => {
              if (x.groundStopManifestTypeDescription != 'PAX') {
                let i: number;
                i = this.selAllCrewPaxList.findIndex(u => u.groundStopManifestTypeDescription == "PIC");
                if (x.groundStopManifestTypeDescription == "PIC" && i != -1) {
                  this.selAllCrewPaxList[i] = x;
                }
                else {
                  i = this.selAllCrewPaxList.findIndex(u => u.groundStopManifestTypeDescription == "SIC");
                  if (x.groundStopManifestTypeDescription == "SIC" && i != -1) {
                    this.selAllCrewPaxList[i] = x;
                  }
                  else {
                    this.selAllCrewPaxList.push(x);
                  }
                }
                x.loadFromArrival = true;
                
              }
            })
          }
          else {
            response.model.forEach(x => {
              if (x.groundStopManifestTypeDescription == 'PAX') {
                x.loadFromArrival = true;
                this.selAllCrewPaxList.push(x);
              }
            })
          }
          this.setSelectedPersonList("");
          this.dataChanged = true;
        }
        this.setAvailablePersonList(this.personType);
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }

      }
      this.loading = false;
    })
  }

  clickSelectCrewPax(personType: string) {
    this.personType = personType;//.toLocaleUpperCase();
    this.getCrewPaxByCustomerGUIDSearchKeyword();
    this.setSelectedPersonList(this.personType);
    this.setAdditionalOptions();
   
  }

  searchKeywordChange(e: any) {
    this.getCrewPaxByCustomerGUIDSearchKeyword();
    
  }

  getCrewPaxByCustomerGUIDSearchKeyword() {
    let request = new GroundStopManifestModel();
    request.customerGUID = this.customerGUID;
    request.personType = this.personType;
    request.searchKeyword = this.f.search_keyword.value;
    request.groundStopCountryGUID = this.countryGUID;
    switch (request.personType) {
      case "PIC":
        request.additionalOptions = Number(this.additional_options_pic);
        break;
      case "SIC":
        request.additionalOptions = Number(this.additional_options_sic);
        break;
      case "Crew":
        request.additionalOptions = Number(this.additional_options_crew);
        break;
      case "PAX":
        request.additionalOptions = Number(this.additional_options_pax);
        break;
    }
    
    this.personList = [];
    //this.selPersonList = [];
    this._groundStopAdvancedService.getPersonByCustomerIdSearchKeyword<ResponseModel<GroundStopManifestModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.personList = [];
        if (response.model.length >0) {
          this.personList = response.model;
          
        }
        this.setAvailablePersonList(this.personType);
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        
      }
      this.loading = false;
    })

  }


  setAvailablePersonList(personType: string) {
    if (this.selAllCrewPaxList.length > 0) {
      this.avaiPersonList = this.personList.filter(u => this.selAllCrewPaxList.map(v => v.personGUID.toLowerCase()).indexOf(u.personGUID.toLowerCase()) == -1);
    }
    else {
        this.avaiPersonList = this.personList.filter(u => u.selected == false);
    }
  }

  setSelectedPersonList(personType: string) {
    this.errMsg = "";     
    switch (personType) {
      case "PIC":
        this.selPIC = this.selAllCrewPaxList.filter(u => u.groundStopManifestTypeDescription.indexOf('PIC') >= 0);
        this.selPersonList = this.selPIC;        
        break;
      case "SIC":
        this.selSIC = this.selAllCrewPaxList.filter(u => u.groundStopManifestTypeDescription.indexOf('SIC') >= 0);
        this.selPersonList = this.selSIC;       
        break;
      case "Crew":
        this.selCrewList = this.selAllCrewPaxList.filter(u => u.groundStopManifestTypeDescription.indexOf('Crew') >= 0);
        this.selPersonList = this.selCrewList;
        break;
      case "PAX":
        this.selPAXList = this.selAllCrewPaxList.filter(u => u.groundStopManifestTypeDescription.indexOf('PAX') >= 0);
        this.selPersonList = this.selPAXList;
        break;
      default:
        this.selPIC = this.selAllCrewPaxList.filter(u => u.groundStopManifestTypeDescription.indexOf('PIC') >= 0);
        this.selSIC = this.selAllCrewPaxList.filter(u => u.groundStopManifestTypeDescription.indexOf('SIC') >= 0);
        this.selCrewList = this.selAllCrewPaxList.filter(u => u.groundStopManifestTypeDescription.indexOf('Crew') >= 0);
        this.selPAXList = this.selAllCrewPaxList.filter(u => u.groundStopManifestTypeDescription.indexOf('PAX') >= 0);
        break;

    }
    this.crewCount = "";
    this.paxCount = "";
    let c = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription != 'PAX');
    if (c != null && c.length > 0) {
      this.crewCount = c.length.toString();
    }
    let p = this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'PAX');
    if (p != null && p.length > 0) {
      this.paxCount = p.length.toString();
    }

  } 

  clickAddItem(e: any, item: GroundStopManifestModel) {
    switch (this.personType) {
      case "PIC":
        if (this.selPersonList.length == 1) {
            this._dialogService.openAlertDialog("Only limit one PIC");
            return;
        }
        break;
      case "SIC":
        if (this.selPersonList.length == 1) {
          this._dialogService.openAlertDialog("Only limit one SIC");
          return;
        }
        break;
    }
    
    this.personList.forEach(x => {
      if (x.personGUID.toLowerCase() == item.personGUID.toLowerCase()) {
        x.groundStopGUID = this.groundStopGUID;
        x.loadFromArrival = false;
        x.loadFromPrevDeparture = false;
        //x.groundStopManifestDocumentList = [];
        this.selAllCrewPaxList.push(x);
      }
    })
    this.dataChanged = true;
    this.setAvailablePersonList(this.personType);
    this.setSelectedPersonList(this.personType);

  }

  clickRemoveItem(e: any, item: GroundStopManifestModel) {

    this.selAllCrewPaxList.forEach((y, index) => {
      if (y.personGUID.toLowerCase() == item.personGUID.toLowerCase()) {
        this.selAllCrewPaxList.splice(index, 1);
      }
    });
    if (this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription != 'PAX').length == 0 || this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'PAX').length == 0) {
      if (this.manifest_type == 'Arrival') {                  
        this.checkShowPreviousDeparturePersonInfo();
      }
      else {
        if (this.manifest_type == 'Departure') {               
          this.checkShowArrivalPersonInfo();
        }
      }
    }
    this.dataChanged = true;
    this.setSelectedPersonList(this.personType);
    this.setAvailablePersonList(this.personType);
  }

  clickSelectAll() {

    switch (this.personType) {
      case "PIC":
          if (this.personList.length > 1 || this.selPIC.length>0) {
            this._dialogService.openAlertDialog("Only limit one PIC");
            return;
          }
        break;
      case "SIC":
            if (this.personList.length > 1 || this.selSIC.length>0) {
              this._dialogService.openAlertDialog("Only limit one SIC");
              return;
            }
        break;
    }

    this.avaiPersonList.forEach(x => {
      x.groundStopGUID = this.groundStopGUID;
      x.loadFromArrival = false;
      x.loadFromPrevDeparture = false;
      x.groundStopManifestDocumentList = [];
      this.selAllCrewPaxList.push(x);
    })
    this.dataChanged = true;
    this.setAvailablePersonList(this.personType);
    this.setSelectedPersonList(this.personType);
  }

  clickRemoveAll() {
    this.selPersonList.forEach(x => {
        this.selAllCrewPaxList.forEach((y, index) => {
          if (x.personGUID.toLowerCase() == y.personGUID.toLowerCase()) {
            this.selAllCrewPaxList.splice(index, 1);
          }
       });
    });
    if (this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription != 'PAX').length == 0 || this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'PAX').length == 0) {
      if (this.manifest_type == 'Arrival') {
        this.checkShowPreviousDeparturePersonInfo();
      }
      else {
        if (this.manifest_type == 'Departure') {
          this.checkShowArrivalPersonInfo();
        }
      }
    }
    this.dataChanged = true;
    this.setSelectedPersonList(this.personType);
    this.setAvailablePersonList(this.personType);
  }

  clickSelectDocument(pId: string, pName: string, pType: string) {
    let s = "";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "45em";//"1000px";
    dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    let sdoc = "";
    let p = new GroundStopManifestModel();

    if (this.selAllCrewPaxList.length > 0) {
      p = this.selAllCrewPaxList.find(x => x.personGUID.toLowerCase() == pId.toLowerCase());
      if (p != null && p.groundStopManifestDocumentList!=null) {
        if (p.groundStopManifestDocumentList.length > 0) {
          sdoc = Array.prototype.map.call(p.groundStopManifestDocumentList, s => s.personTravelDocumentGUID).toString();
        }
      }     
    }  

    dialogConfig.data = {
      dialogTitle: s, personGUID: pId, personName: pName, personType: pType, selectedPersonTravelDocumentGUIDList: sdoc,
      v: this._authService.getCurrentTimeNumber()};

    const dialogRef = this._dialog.open(PersonTravelDocSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        if (result.groundStopManifestDocumentList.length > 0) {
          this.dataChanged = true;
          p.groundStopManifestDocumentList = result.groundStopManifestDocumentList;
        }
        else {
          p.groundStopManifestDocumentList = [];
        }               
      }
    });

  }

  isFerryFlightChange(e: any, mtype: string) {
    if (e.source.checked == true) {
      this.selPAXList = [];   
    }
    if (mtype == 'd') {
      this.isDeptFerryFlight = e.source.checked;
    }
    else {
      this.isArrFerryFlight = e.source.checked;
    }

    this.dataChanged = true;
  }

  changePICAdditionalOptions(e: any) {
    this.additional_options_pic = e.target.value;
    
    this.getCrewPaxByCustomerGUIDSearchKeyword();
    if (Number(this.additional_options_pic) == 0)
      this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.capacityID != 12);
    this.setSelectedPersonList(this.personType);
    this.dataChanged = true;
  }

  changeSICAdditionalOptions(e: any) {
    this.additional_options_sic = e.target.value;
    this.getCrewPaxByCustomerGUIDSearchKeyword();
    if (Number(this.additional_options_sic) == 0)
      this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.capacityID != 13);
    this.setSelectedPersonList(this.personType);
    this.dataChanged = true;    
  }

  changeCrewAdditionalOptions(e: any) {
    this.additional_options_crew = e.target.value;
    this.getCrewPaxByCustomerGUIDSearchKeyword();
    switch (Number(this.additional_options_crew)) {
      case 6:
        this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.capacityID != 7 && x.capacityID != 8);
        break;
      case 7:
        this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.capacityID != 6 && x.capacityID != 8);
        break;
      case 8:
        this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.capacityID != 7 && x.capacityID != 6);
        break;
      default:
        this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.capacityID != 6 && x.capacityID != 7 && x.capacityID != 8);
        break;
    }
    this.setSelectedPersonList(this.personType);
    this.dataChanged = true;
  }

  changePaxAdditionalOptions(e: any) {
    this.additional_options_pax = e.target.value;
    this.getCrewPaxByCustomerGUIDSearchKeyword();
    switch (Number(this.additional_options_pax)) {
      case 9:
        this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.capacityID != 10 && x.capacityID != 11);
        break;
      case 10:
        this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.capacityID != 9 && x.capacityID != 11);
        break;
      case 11:
        this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.capacityID != 10 && x.capacityID != 9);
        break;
      default:
        this.selAllCrewPaxList = this.selAllCrewPaxList.filter(x => x.capacityID != 9 && x.capacityID != 10 && x.capacityID != 11);
        break;
    }
    this.setSelectedPersonList(this.personType);
    this.dataChanged = true;
  }

  clickSave(actionName: string) {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    //if (this.f.saveToAllStops.value == true) {
    //  this.manifest_type = "";
    //}

    let applyCrewToAllStops: boolean;
    applyCrewToAllStops = false;
    if (this.f.saveCrewToAllStops.value == true) {
      applyCrewToAllStops = true;
    }
    let applyPaxToAllStops: boolean;
    applyPaxToAllStops = false;
    if (this.f.savePaxToAllStops.value == true) {
      applyPaxToAllStops = true;
    }
    
    if ((this.manifest_type == 'Departure' && this.f.isDeptFerryFlight.value == true) || (this.manifest_type == 'Arrival' && this.f.isArrFerryFlight.value == true)) {
      this.selAllCrewPaxList=this.selAllCrewPaxList.filter(x => x.groundStopManifestTypeDescription != 'PAX');
    }
    if (this.selAllCrewPaxList.length > 0) {
      this.selAllCrewPaxList.forEach(x => {
        //x.groundStopManifestTypeDescription = this.manifest_type + " " + x.groundStopManifestTypeDescription;
        //x.groundStopManifestTypeDescription = x.groundStopManifestTypeDescription;
        x.groundStopGUID = this.groundStopGUID;
        x.groundStopManifestType = this.manifest_type;
        x.applyCrewToAllGroundStops = applyCrewToAllStops;
        x.applyPaxToAllGroundStops = applyPaxToAllStops;
        x.customerGUID = this.customerGUID;
        x.tripCodeGUID = this.tripCodeGUID;
        if (this.manifest_type == 'Departure' && this.f.isDeptFerryFlight.value == true) {
          x.isDeptFerryFlight = this.f.isDeptFerryFlight.value;
        }
        if (this.manifest_type == 'Arrival' && this.f.isArrFerryFlight.value == true) {
          x.isArrFerryFlight = this.f.isArrFerryFlight.value;
        }
        
      });
    }
    else {
      let obj = new GroundStopManifestModel();
      obj.groundStopGUID = this.groundStopGUID;
      obj.personGUID = "";
      obj.groundStopManifestType = this.manifest_type;
      obj.applyCrewToAllGroundStops = applyCrewToAllStops;
      obj.applyPaxToAllGroundStops = applyPaxToAllStops;
      obj.customerGUID = this.customerGUID;
      obj.tripCodeGUID = this.tripCodeGUID;
      //obj.isDeptFerryFlight = this.f.isDeptFerryFlight.value;
      if (this.manifest_type == 'Departure' && this.f.isDeptFerryFlight.value == true) {
        obj.isDeptFerryFlight = this.f.isDeptFerryFlight.value;
      }
      if (this.manifest_type == 'Arrival' && this.f.isArrFerryFlight.value == true) {
        obj.isArrFerryFlight = this.f.isArrFerryFlight.value;
      }
      this.selAllCrewPaxList.push(obj);
    }
    
    this.loading = true;
    this._groundStopAdvancedService.saveGroundStopManifestAndDocument<ResponseModel<boolean>>(this.selAllCrewPaxList).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.manifest_type = this.f.manifest_type.value;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);

          if (this.manifest_type == 'Arrival') {
            this.f.loadCrewPreviousDeparture.setValue(false);
            this.f.loadPaxPreviousDeparture.setValue(false);
            this.showLoadCrewPreviousDeparture = false;
            this.showLoadPaxPreviousDeparture = false;
          }
          else {
            if (this.manifest_type == 'Departure') {
              this.f.loadCrewArrival.setValue(false);
              this.f.loadPaxArrival.setValue(false);
              this.showLoadCrewArrival = false;
              this.showLoadPaxArrival = false;
            }
          }
          this.isModified = true;
          this.returnObj.refresh = this.isModified;
          if (applyCrewToAllStops || applyPaxToAllStops)
            this.returnObj.applyAllGroundStops = true;
          else
            this.returnObj.applyAllGroundStops = false;
          if (actionName != "") {
            {
              if (actionName == 'close') {
                //this._dialogRef.close(this.isModified);
                this._dialogRef.close(this.returnObj);
              }
              else {
                this.changeManifestTypeNext();
              }
            }
          }
          this.dataChanged = false;
          //if (actionName == "") {
          //  this._dialogService.openAlertDialog("");
          //}
          
        }
        else {
          //
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  

  clickClose() {
    if (this.dataChanged == true) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before close?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result==true) {
          this.clickSave('close');
        }
        else {
          //this._dialogRef.close(this.isModified);
          this._dialogRef.close(this.returnObj);
        }
      });
    }
    else {
      //this._dialogRef.close(this.isModified);
      this._dialogRef.close(this.returnObj);
    }

    //this._dialogRef.close(this.isModified);
  }

  clickPersonName(e: any, item: GroundStopManifestModel) {
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "pId": item.personGUID,
    //    "selectedPersonClassDescription": item.personClassDescription,
    //    "selectedPersonClassGUID": item.personClassGUID,
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    //this._router.navigate(['/persons/customer-person-details'], navigationExtras);
    //window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID + '&selectedPersonClassDescription=' + item.personClassDescription + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
    window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');

  }

}
