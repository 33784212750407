import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { PrivacyPolicyDialogComponent } from './privacy-policy-dialog.component';

@Component({
    selector: 'terms-of-use-dialog',
  templateUrl: './terms-of-use-dialog.component.html',
  styleUrls: ['./terms-of-use-dialog.component.css']
})
/** privacy-policy.dialog component*/
export class TermsOfUseDialogComponent {
    /** privacy-policy.dialog ctor */
    constructor(private readonly _dialog: MatDialog) {

  }

  openPrivacyPolicy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    this._dialog.open(PrivacyPolicyDialogComponent, dialogConfig);
  }
}
