import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CustomerModel } from '../models/customer.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { MessageTemplateModel } from '../models/message-template.model';
import { MessageTextModel } from '../models/message-text-model';
import { ResponseModel } from '../models/response.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { TripSummaryModel } from '../models/trip-summary.model';
import { TripModel } from '../models/trip.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopService } from '../services/ground-stop.service';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from 'file-saver';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { VendorModel } from '../models/vendor.model';
import { VendorService } from '../services/vendor.service';
import { EADDRINUSE } from 'constants';
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { forkJoin, Observable, of } from 'rxjs';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CustomerGroupService } from '../services/customer-group-service';
import { FlightBriefModel } from '../models/flight-brief.model';
import { TemplateService } from '../services/template.service';

const datepipe: DatePipe = new DatePipe('en-US')

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
var expressions = require("angular-expressions");
var assign = require("lodash/assign");
// define your filter functions here, for example
// to be able to write {clientname | lower}
expressions.filters.lower = function (input) {
  // This condition should be used to make sure that if your input is
  // undefined, your output will be undefined as well and will not
  // throw an error
  if (!input) return input;
  return input.toLowerCase();
};
function angularParser(tag) {
  tag = tag
    .replace(/^\.$/, "this")
    .replace(/(�|�)/g, "'")
    .replace(/(�|�)/g, '"');
  const expr = expressions.compile(tag);
  return {
    get: function (scope, context) {
      let obj = {};
      const scopeList = context.scopeList;
      const num = context.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = assign(obj, scopeList[i]);
      }
      return expr(scope, obj);
    },
  };
}

function parser(tag) {
  // We write an exception to handle the tag "$pageBreakExceptLast"
  if (tag === "$pageBreakExceptLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (!isLast) {
          return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }
  if (tag === "$pageBreak") {
    return {
      get(scope, context) {
        return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';

      },
    };
  }



  // We use the angularParser as the default fallback
  // If you don't wish to use the angularParser,
  // you can use the default parser as documented here:
  // https://docxtemplater.com/docs/configuration#default-parser
  return angularParser(tag);
}
@Component({
  selector: 'app-trip-summary-specific-report-dialog',
  templateUrl: './trip-summary-specific-report-dialog.component.html',
  styleUrls: ['./trip-summary-specific-report-dialog.component.css']
})
/** trip-summary-report-dialog component*/
export class TripSummarySpecificReportDialogComponent implements OnInit {
  /** trip-summary-report-dialog ctor */

  //customerList: CustomerModel[];
  //tripCodeList: TripModel[];
  groundStopList: GroundStopModel[];
  listType: string = "filter";
  trip_summary_specific_report_form: UntypedFormGroup;
  hasTemplate: boolean = false;
  errMsg: string = "";
  submitted: boolean = false;
  selectedGroundStopList: GroundStopModel[] = [];
  includeCrewPax: boolean = false;
  includeServices: boolean = false;
  includeHandling: boolean = false;
  includeCoverPage: boolean = false;
  checkServiceTypeArray: UntypedFormControl; // for mat-select
  serviceTypeList: ServiceTypeModel[];
  selectedServiceTypeList: ServiceTypeModel[] = [];
  tripSummary: TripSummaryModel;
  loadingWord: boolean = false;
  loadingPDF: boolean = false;
  addressType: string = "Email";
  hasTo: boolean = false;
  hasAddress: boolean = false;
  userType: string = "";
  grantAccessToAccountGrouping: boolean = false;
  upPII: boolean = false;
  customerName: string = "";
  @Input() tripCodeGUID: string;
  @Input() customerGUID: string;
  isMobile: boolean = false;
  groundStopGUID: string;
  showSpin: boolean = true;
  /** generate-message-template ctor */
  constructor(private readonly _authService: AuthenticateService, private readonly _groundStopService: GroundStopService, private readonly _groundStopClientService: GroundStopClientService, private readonly _groundStopAdvanceService: GroundStopAdvancedService, private readonly _vendorService: VendorService,
    private readonly _commonService: CommonService, private readonly _customerGroupService: CustomerGroupService, private readonly _formBuilder: UntypedFormBuilder, private readonly _dialogRef: MatDialogRef<TripSummarySpecificReportDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _templateService: TemplateService) {

    this.tripCodeGUID = _data.tripCodeGUID;
    this.customerGUID = _data.customerGUID;
    this.groundStopGUID = "";
    if (_data.groundStopGUID != null && _data.groundStopGUID != undefined && _data.groundStopGUID!='')
      this.groundStopGUID = _data.groundStopGUID;
    this.includeCrewPax = false;
    if (_data.includeCrewPax != null && _data.includeCrewPax != undefined && _data.includeCrewPax != '')
      this.includeCrewPax = _data.includeCrewPax;
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (Number(localStorage.getItem('mobile')) == 1)
      this.isMobile = true;
    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }
    this.customerName = localStorage.getItem('cn');
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    //
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].model != null) {
          this.groundStopList = responses[0].model;
          this.groundStopList = this.groundStopList.filter(v => v.nextGroundStopGUID != '');
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      } 
     
      this.initControls();
      if (this.upPII == true) {
        this.includeCrewPax = true;
        this.createTripSummary('pdf');
      }
    });

  }

  getAllData(): Observable<any[]> {
    
    let request = new GroundStopModel();
    
    request.tripCodeGUID = this.tripCodeGUID;
    request.customerGUID = this.customerGUID;
    
    let getGSListResponse = this._groundStopService.getGroundStopPairListBytId<ResponseModel<GroundStopModel[]>>(request);
    return forkJoin([getGSListResponse]);
    
  }


  initControls() {
    this.trip_summary_specific_report_form = this._formBuilder.group({
      
    });

  }

  get f() { return this.trip_summary_specific_report_form.controls; }


  createTripSummary(format: string) {
    this.submitted = true;
    this.errMsg = "";
    if (this.trip_summary_specific_report_form.invalid)
      return;
    //if (format == "word")
    //  this.loadingWord = true;
    //else
    //  this.loadingPDF = true;
    let request = new TripSummaryModel();
    if (this.tripCodeGUID == "")
      request.tripCodeGUID = "";// this.f.trip_code.value;
    else
      request.tripCodeGUID = this.tripCodeGUID;
    request.selectedGroundStopList = [];
    if (this.groundStopGUID != '')
      request.selectedGroundStopList.push(this.groundStopGUID);
    else {
         this.groundStopList.forEach(v => {
            request.selectedGroundStopList.push(v.groundStopGUID);
         });
    }
    
    //if (this.selectedServiceTypeList.length == 0)
    //  request.selectServiceTypeIDs = Array.prototype.map.call(this.serviceTypeList, s => s.serviceTypeID).toString();
    //else
    //  request.selectServiceTypeIDs = Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
    request.selectServiceTypeIDs = "";
    this._groundStopAdvanceService.getTripSummary<ResponseModel<TripSummaryModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let tripSummary = response.model;
          var self = this;
          tripSummary.handlingNotes = [];

          let obj = new FlightBriefModel();
          obj.handlingVendorGUIDs = tripSummary.handlingVendorGUIDs;
          obj.handlingNotes = [];
          if (obj.handlingVendorGUIDs != null) {
            this._templateService.getVendorDetails(obj).subscribe(x => {
              if (x.refresh)
                obj.handlingNotes.sort(function (a, b) {
                  if (a.pageIndex < b.pageIndex) { return -1; }
                  if (a.pageIndex > b.pageIndex) { return 1; }
                  return 0
                });
              tripSummary.handlingNotes = obj.handlingNotes;
              this.createReport(format, tripSummary);

            });


          }
          else {
            this.createReport(format, tripSummary);
          }
       
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else {
            this.loadingPDF = false;
            this.loadingWord = false;
            this.errMsg = "There was an error creating the report.";
          }
          this.showSpin = false;
        }
      }
      else {
        this.loadingPDF = false;
        this.loadingWord = false;
        this.errMsg = "No data found.";
        this.showSpin = false;
      }

    });
  }

  createReport(format: string, tripSummary: TripSummaryModel) {
    let filePath = ""
    //if (this.includeServices == false && this.includeCrewPax == false) {
    //  if (this.includeCoverPage)
    //    filePath = window.origin + "/assets/templates/TripSummaryCondensedTemplateWithCoverPage.docx";
    //  else
    //    filePath = window.origin + "/assets/templates/TripSummaryCondensedTemplate.docx";
    //}
    //else {
    //  if (this.includeCoverPage)
    //    filePath = window.origin + "/assets/templates/TripSummaryTemplateWithCoverPage.docx";
    //  else
        filePath = window.origin + "/assets/templates/TripSummaryTemplate.docx";
    //  //filePath = "https://localhost:5001/assets/templates/TripSummaryTemplate.docx";
    //}

    let self = this;
    loadFile(filePath, function (
      error,
      content
    ) {
      if (error) {
        self.loadingWord = false;
        self.loadingPDF = false;
        self.errMsg = "An error occurred generating the report."
        throw error;
      }
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser });
      let reportDate = datepipe.transform(new Date().toUTCString(), 'MMM-dd-yyyy HH:mm', "UTC");
      let reportID = UtilityFunctions.makeID(10).toUpperCase();
      let hasImportantNotes = true;
      //if (self.f.important_note.value == "")
        hasImportantNotes = false;
      //if (self.f.summary_to.value == "")
        self.hasTo = false;
      //else
      //  self.hasTo = true;
      //if (self.f.summary_address.value == "")
        self.hasAddress = false;
      //else
      //  self.hasAddress = true;
      doc.setData({
        reportType: "Trip Summary",
        hasImportantNotes: hasImportantNotes,
        coverPageNote: '', //self.f.cover_note.value,
        importantNotes: '', //self.f.important_note.value,
        tripCode: tripSummary.tripCode,
        tailNo: tripSummary.registration,
        clientName: tripSummary.customerName.toUpperCase(),
        minDate: tripSummary.minDate,
        maxDate: tripSummary.maxDate,
        route: tripSummary.tripRoute,
        reportDate: reportDate,
        reportID: reportID,
        hasTo: self.hasTo,
        reportTo: '',//self.f.summary_to.value,
        hasAddress: self.hasAddress,
        address: '',//self.f.summary_address.value,
        addressType: '',//self.addressType,
        includeCrewPax: self.includeCrewPax,
        includeServices: self.includeServices,
        tripSummaryLegs: tripSummary.tripSummaryLegs,
        includeBrief: self.includeHandling,
        airport: tripSummary.handlingNotes
      });
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key];
              self.loadingWord = false;
              return error;
            },
              {});
          }
          return value;
        }
        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          console.log("errorMessages", errorMessages);
          self.errMsg = "There was an error creating the report.";
          // errorMessages is a humanly readable message looking like this:
          // 'The tag beginning with "foobar" is unopened'
        }
        self.loadingWord = false;

        throw error;
      }
      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });

      if (format == "word") {
        saveAs(out, "Trip Summary Report - " + tripSummary.tripCode + ".docx");
        self.loadingWord = false;
        self.showSpin = false;
      }
      else {
        const functionURI = 'https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf';

        let headers = new HttpHeaders();
        const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
        headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');


        httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {

          var fileURL = URL.createObjectURL(data.body);
          window.open(fileURL);
          self.loadingPDF = false;
          self.showSpin = false;
          self._dialogRef.close();
        });
      }
    });
  }
}



