import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as atlas from 'azure-maps-control';
import { AuthenticationType, ControlPosition } from 'azure-maps-control';
import { Subject, Subscription, from } from 'rxjs';
import { MapPointsModel } from '../models/map-points.model';
import { AirportModel } from '../models/airport.model';

@Component({
  selector: 'app-airport-map',
  templateUrl: './airport-map.component.html',
  styleUrls: ['./airport-map.component.css']
})
/** azure-map2 component*/
export class AirportMapComponent {


  latLongs: MapPointsModel[];
  map2: atlas.Map;
  points: any = [];
  tileLayer: any;
  layerName: string = "";
  distance: number;
  airports: AirportModel[];
  from: string;
  radius: number;
  maxDistance: number;
  weatherTileUrl: string = 'https://{azMapsDomain}/map/tile?api-version=2.0&tilesetId={layerName}&zoom={z}&x={x}&y={y}';
  showRoute: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
    
  ) {
    this.latLongs = _data.latLongs;
    this.distance = _data.distance;
    this.airports = _data.airports;
    this.from = _data.from;
    this.radius = _data.radius;
    this.maxDistance = _data.maxDistance;
    this.showRoute = _data.showRoute;
  }


  ngOnInit() {
    this.createMap();
  }

  //Create an instance of the map2 control and set some options.
  createMap() {
    this.map2 = new atlas.Map('map2', {
      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map2.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map2.sources.add(dataSource);
      self.map2.controls.add([
        new atlas.control.ZoomControl(),
        new atlas.control.CompassControl(),
        new atlas.control.PitchControl(),
        new atlas.control.StyleControl()
      ], {
        position: atlas.ControlPosition.TopRight
      });

      if (self.from == "details") {
        if (self.distance <= 100)
          self.radius = 25;
        if (self.distance > 100 && self.distance <= 200)
          self.radius = 50;
        if (self.distance > 200 && self.distance <= 500)
          self.radius = 100;
        if (self.distance > 500)
          self.radius = 150;
        if (self.latLongs.length > 1) {
          let maxRadius = self.distance * 1852;
          let increment = self.radius * 926;
          for (let i = increment; i <= maxRadius; i = i + increment) {
            var data = new atlas.data.Feature(new atlas.data.Point([self.latLongs[0].longitude, self.latLongs[0].latitude]), {
              title: i / 926 / 2 + " NM",
              display: "radius",
              subType: "Circle",
              radius: i,
              color: "transparent",
              strokecolor: "transparent",
              offset: [0, -.5]
            });
            let r = i / 926 / 2;
            if (r < 25 || r <= self.maxDistance + (self.radius / 2))
              dataSource.add(data);

            //Create a polygon layer to render the filled in area of the polygon.
            var polygonLayer = new atlas.layer.PolygonLayer(dataSource, 'myPolygonLayer', {
              fillColor: 'transparent',
              filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
            });

            //Create a line layer for greater control of rendering the outline of the polygon.
            var lineLayer = new atlas.layer.LineLayer(dataSource, 'myLineLayer', {
              strokeColor: 'blue',
              strokeWidth: 1.5
            });

            var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
              iconOptions: {
                image: 'none'
              },
              textOptions: {
                textField: ['get', 'label']
              },
              filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]
            })
            /*Create and add a polygon layer to render the polygon to the map*/
            self.map2.layers.add([polygonLayer, lineLayer, symbolLayer]);
          }


        }
        var labels = calculatePolygonLabels(dataSource.getShapes(), 'title');
        dataSource.add(labels);
        self.latLongs.forEach((v, i) => {
          dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
            title: v.title,
            strokecolor: v.isMainPoint ? '#cc0000' : v.isPreferred ? '#ffd800' : 'DodgerBlue',
            color: "white",
            display: "dot",
            offset: [0, -1]
          }));
          self.points.push([v.longitude, v.latitude]);


        });
      }
      else {
        //self.airports.forEach((v, i) => {
        //  dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
        //    title: v.icao,
        //    strokecolor: "darkblue",
        //    color: "white",
        //    display: "dot",
        //    offset: [0, -1]
        //  }));
        //  self.points.push([v.longitude, v.latitude]);


        //});
        self.airports.forEach(v => {
          if (v.longitude != null && v.latitude != null) {
            var startPosition = [v.longitude, v.latitude];
            var endPosition = [v.nextLongitude, v.nextLatitude];
            var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
              title: v.icao,
              name: v.airportName,
              airport: v,
                  strokecolor: "darkblue",
                  color: "white",
                  display: "dot",
                  offset: [0, -1]
              

            });

            var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
              title: v.nextICAO,
              name: v.nextAirportName,
              strokecolor: "darkblue",
              color: "white",
              display: "dot",
              offset: [0, -1]
            });

            self.points.push(startPosition);
            self.points.push(endPosition);
            var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
            var poly = new atlas.data.LineString(path);
            if (v.nextAirportID != null && (self.showRoute))
              dataSource.add([poly, startPoint, endPoint]);
            else
              dataSource.add([startPoint]);
          }
        });
      }

      var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
        iconOptions: {
          image: ['get', 'image'],
          cluster: false,
          optional: true,
          allowOverlap: true,
          size: .75
        },
        textOptions: {
          textField:  [
            'match',
            ['get', 'showText'],
            1, '',
            0, ['get', 'title'],
            ''
          ],            
          offset: ['get', 'offset'],
          cluster: false,
          allowOverlap: true,
          size: 12
        },
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      });
      self.map2.layers.add([new atlas.layer.LineLayer(dataSource, null, {
        strokeColor: "black",
        strokeWidth: 1.5
      }),
        symbolLayer
      ]);
      self.map2.layers.add(new atlas.layer.BubbleLayer(dataSource, null, {
        radius: 4,
        strokeColor: ['get', 'strokecolor'],
        strokeWidth: 6,
        color: ['get', 'color'],
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      }));

      //Add a layer for rendering point data.
      self.map2.layers.add(new atlas.layer.SymbolLayer(dataSource, null, {
        iconOptions: {
          //Hide the icon image.
          image: "none"
        },

        textOptions: {
          textField: ['get', 'title'],
          offset: ['get', 'offset'],
          allowOverlap: true,
          size: 16
        },
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      }));
        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        if ((self.from =="details" && self.latLongs.length == 1) || self.from=="manage" && self.airports.length==1) {
          let center;
          if (self.from == "details")
            center = [self.latLongs[0].longitude, self.latLongs[0].latitude]
          else
            center = [self.airports[0].longitude, self.airports[0].latitude]
          self.map2.setCamera({
            center:center,
            zoom: 12
          });
        }
        else {
          self.map2.setCamera({
            bounds: bbox,
            padding: 90
          });
        }
     //   self.updateTileLayer();
      


    });
  }

  updateTileLayer() {
    

  var tileUrl = this.weatherTileUrl.replace('{layerName}', this.layerName);

    if (!this.tileLayer) {
    //Create a tile layer and add it to the map below the label layer.
    this.tileLayer = new atlas.layer.TileLayer({
      tileUrl: tileUrl,
      opacity: 0.9,
      tileSize: 256
    });

      this.map2.layers.add(this.tileLayer, 'labels');
  } else {
    this.tileLayer.setOptions({
      tileUrl: tileUrl
    });
  }
}
}

function calculatePolygonLabels(shapes, labelPropertyName) {
  var labels = [];

  for (var i = 0; i < shapes.length; i++) {

    var circlePolygon = shapes[i].circlePolygon;

    // Get the coordinates of the polygon edge
    if (circlePolygon != undefined) {
      var edgeCoordinates = circlePolygon.geometry.coordinates[0][0];

      var prop = {};
      prop[labelPropertyName] = shapes[i].circlePolygon.properties[labelPropertyName];
      prop["display"] = shapes[i].circlePolygon.properties["display"];
      prop["color"] = shapes[i].circlePolygon.properties["color"];
      prop["strokecolor"] = shapes[i].circlePolygon.properties["strokecolor"];
      prop["offset"] = shapes[i].circlePolygon.properties["offset"];
      labels.push(new atlas.data.Feature(new atlas.data.Point(edgeCoordinates), prop));

    }
  }

  return labels;
}
