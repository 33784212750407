import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { UserModel } from '../models/user.model';
import { AuthenticateService } from '../services/authenticate.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { CustomerModel } from '../models/customer.model';
import { VendorModel } from '../models/vendor.model';
import { Observable, forkJoin } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { CommonService } from '../services/common.service';
import { ResponseModel } from '../models/response.model';
import { PersonModel } from '../models/person.model';
import { UserPermissionAdminEditDialogComponent } from './user-permission-admin-edit-dialog.component';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogService } from '../services/dialog.service';
import { GlobalConstant } from '../common-utility/global-constant';

@Component({
  selector: 'app-user-permission-person-edit-dialog',
  templateUrl: './user-permission-person-edit-dialog.component.html',
  styleUrls: ['./user-permission-person-edit-dialog.component.css']
})

export class UserPermissionPersonEditDialogComponent implements OnInit, AfterViewInit, OnChanges {
  user_permission_person_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  user: UserModel;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  @Input() userId: string;
  @Input() customerGuid: string;
  @Input() tabName: string;
  @Input() userType: string;
  @Input() locked: boolean;
  isModified: boolean=false;
  userList: UserModel[];
  //isActive: boolean;
  
  //displayedColumn: string[] = ['username', 'name', 'email'];
  displayedColumnP: string[] = ['name'];
  displayedColumnP2: string[] = ['name2']
  personList: PersonModel[];
  selPersonList: PersonModel[];
  avaiPersonList: PersonModel[];
  personType: string;
  //@Output() tabChanged = new EventEmitter();
  @Input() admin_access_select: boolean;
  @Input() account_status_select: boolean;
  @Input() effectiveDateText: string;
  @Input() expirationDateText: string;
  @Input() isValidateEffectiveDate: boolean;
  @Input() isValidateExpirationDate: boolean;
  showSuccessMsg: boolean = false;
  ua: number;
  ut: string;
  checkAllPerson: boolean;
  dataChanged: boolean = false;
  @Output() public sendDataToParent = new EventEmitter();
  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService, private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService, private readonly _dialogRef: MatDialogRef<UserPermissionAdminEditDialogComponent>, private readonly _dialogService: DialogService) {

  }

  getAllData(request: UserModel): Observable<any[]> {
    //let getUserResponse = this._identityService.getUserWithAnyStatusByUserId<ResponseModel<UserModel>>(request);
    let getPersonResponse = this._identityService.getPersonListByCustomerGUIDUserId<ResponseModel<PersonModel[]>>(request);
    //return forkJoin([getUserResponse, getPersonResponse]);
    return forkJoin([getPersonResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.ua = 0;// Number(localStorage.getItem('ua'));
    this.ut = localStorage.getItem('ut').toLowerCase();
    if (localStorage.getItem('up.isA') != null && localStorage.getItem('up.isA') != "") {
      if (localStorage.getItem('up.isA').toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
        this.ua = 1;
      }
    }
    if (this.ua != 1) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.personList = [];
    this.selPersonList = [];
    this.avaiPersonList = [];
    this.showSpin = true;
    
    this.personType = this.tabName.toUpperCase();
    
    var request = new UserModel()
    request.userId = this.userId;
    request.customerGuid = this.customerGuid;
    request.personType = this.tabName;
    this.showSuccessMsg = false;
    this.checkAllPerson = false;
    this.getAllData(request).subscribe(responses => {      

      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.personList = responses[0].model;
          
          this.setPersonList();
          if (this.selPersonList.length == 0) {
            this.checkAllPerson = true;
          }

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.loading = false;
      this.showSpin = false;

      this.initControls();

    });
    
  }

  initControls() {
    this.user_permission_person_form = this._formBuilder.group({
      checkAllPerson: [this.checkAllPerson]
      ////customer_select: null,
      //account_status_select: [this.user.isActive],
      //admin_access_select: [this.user.isAdmin]//,

    });


  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.admin_access_select && changes.admin_access_select.currentValue)
      this.admin_access_select = changes.admin_access_select.currentValue;
    if (changes.account_status_select && changes.account_status_select.currentValue)
      this.account_status_select = changes.account_status_select.currentValue;
    if (changes.effectiveDateText && changes.effectiveDateText.currentValue)
      this.effectiveDateText = changes.effectiveDateText.currentValue;
    if (changes.expirationDateText && changes.expirationDateText.currentValue)
      this.expirationDateText = changes.expirationDateText.currentValue;
    if (changes.isValidateEffectiveDate && changes.isValidateEffectiveDate.currentValue)
      this.isValidateEffectiveDate = changes.isValidateEffectiveDate.currentValue;
    if (changes.isValidateExpirationDate && changes.isValidateExpirationDate.currentValue)
      this.isValidateExpirationDate = changes.isValidateExpirationDate.currentValue;
    if (changes.locked)
      this.locked = changes.locked.currentValue;
    this.showSuccessMsg = false;
  }

  get f() { return this.user_permission_person_form.controls; }

  checkAllPersonChange(e: any) {
    this.errMsg = "";
    this.dataChanged = true;
    //setTimeout(() => {
      this.sendDataToParentEndPoint()
    //}, 500);
  }

  clickAddItem(e: any, item: PersonModel) {
    this.personList.forEach(x => {
      if (x.personGuid.toLowerCase() == item.personGuid.toLowerCase()) {
        x.selected = true;
      }
    })
    this.setPersonList();
    this.dataChanged = true;
    //setTimeout(() => {
      this.sendDataToParentEndPoint()
    //}, 500);
  }

  clickRemoveItem(e: any, item: PersonModel) {
    this.personList.forEach(x => {
      if (x.personGuid.toLowerCase() == item.personGuid.toLowerCase()) {
        x.selected = false;
      }
    })
    this.setPersonList();
    this.dataChanged = true;
    //setTimeout(() => {
      this.sendDataToParentEndPoint()
    //}, 500);
  }

  clickSelectAll() {
    this.personList.forEach(x => {
      x.selected = true;
    })
    this.setPersonList();
    this.dataChanged = true;
    //setTimeout(() => {
      this.sendDataToParentEndPoint()
    //}, 500);
  }

  clickRemoveAll() {
    this.personList.forEach(x => {
      x.selected = false;
    })
    this.setPersonList();
    this.dataChanged = true;
    //setTimeout(() => {
      this.sendDataToParentEndPoint()
    //}, 500);
  }

  setPersonList() {
    this.avaiPersonList = this.personList.filter(u => u.selected == false);
    this.selPersonList = this.personList.filter(u => u.selected == true);
  }

  sendDataToParentEndPoint() {
    this.sendDataToParent.emit({ dataChanged: this.dataChanged});
  }

 clickSave() {
    this._authService.updateAccessTime();
    this.errMsg = "";
   this.msg = "";
   if (!this.isValidateEffectiveDate || !this.isValidateExpirationDate) {
     return;
   }
   if (this.loading == true) {
     return;
   }
    this.loading = true;
    this.showSpin = true;

   //if (this.f.checkAllPerson.value == false && this.selPersonList.length == 0) {
   //  this.errMsg = "Please select at least one person";
   //  return;
   //}
   //else {
     if (this.f.checkAllPerson.value == true) {
       this.selPersonList = [];
     }
   //}

   var request = new UserModel();
   request.isActive = this.account_status_select;
   //request.isAdmin = this.admin_access_select;
   if (this.admin_access_select) {
     request.isAB = localStorage.getItem('up.isA');
   }
   else {
     request.isAB = localStorage.getItem('up.isB');
   }
   request.effectiveDate = this.effectiveDateText;
   request.expirationDate = this.expirationDateText;
   request.locked = this.locked;
   request.userId = this.userId;
   request.personType = this.tabName;
   request.personGuidList = Array.prototype.map.call(this.selPersonList, s => s.personGuid).toString();
   request.customerGuid = this.customerGuid;
    this._identityService.saveUserPersonAccessByUserId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {
          this.dataChanged = false;
          this.isModified = true;
          this.showSuccessMsg = true;
          //this.sendDataToParent.emit({ dataChanged: this.dataChanged, isModified: this.isModified });
          setTimeout(() => {
            this.sendDataToParentEndPoint()
          }, 500);
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
        }
        else {
          this.errMsg = "Failed to save to our database.";
        }
      }
      else {
        this.errMsg = "Failed to save to our database.";
      }
      this.loading = false;
      this.showSpin = false;

    })

  }

  clickClose() {
    //this._dialogRef.close(this.isModified);
    this._dialogRef.close(true);
  }

  //clickBackToUserPermissionAdmin() {
  //  this._router.navigate(['/user-accounts/user-permission-admin']);
  //}

  //clickTab(s: string) {
  //  this._authService.updateAccessTime();
  //  if (s != "registration" && s != "restrict") {
  //    if (s != this.tabName) {
  //      this.tabName = s;
  //      this.personList = [];
  //      this.selPersonList = [];
  //      this.avaiPersonList = [];

  //      this.personType = this.tabName.toLocaleUpperCase();
  //      this.errMsg = "";
  //      this.msg = "";
  //      // get person data by type
  //      var request = new UserModel()
  //      request.userId = this.userId;
  //      request.customerGuid = this.customerGuid;
  //      request.personType = this.tabName;
  //      this._identityService.getPersonListByCustomerGUIDUserId<ResponseModel<PersonModel[]>>(request).subscribe(response => {
  //        if (response != null && response.code == "200") {
  //          this.personList = response.model;
  //          this.setPersonList();

  //        }

  //      })
  //    }
  //  }
  //  else {
  //    //if (s == "registration")
  //    //  this._router.navigate(['/user-accounts/user-permission-registration-edit', { tabName: s, userId: this.userId, customerGuid: this.customerGuid }]);
  //    //if (s == "restrict")
  //    //  this._router.navigate(['/user-accounts/user-permission-restrict-edit', { tabName: s, userId: this.userId, customerGuid: this.customerGuid }]);

  //    this.tabChanged.emit({ tabName: s });
  //  }

  //}

}
