<div *ngIf="upPII" style="margin-top:0em; padding-top:0em;">
  <form *ngIf="trip_audit_dashboard_form" [formGroup]="trip_audit_dashboard_form">
    <div class="divLeft">
      <div style="display:flex">
        <div style="padding-left:0.25em; padding-right:1.25em;">
          <div style="display: flex">
            <div style="margin-right:1em"><h5>Audit Trail Dashboard</h5></div>
          </div>
          <div style="display: flex; font-size: small">
            <div style="display:flex; margin-right:1em">
              <div style="width:7em; margin-right:1em">
                Revision Rolling Window {{queryHrText}}
              </div>
              <div>
                <div class="slidecontainer" style="margin-top:-2em; margin-right:1em">
                  <input type="range" formControlName="queryHour" min="0" max="6" class="slider" [value]="queryIndex" step="1" (change)="querySlideChange($event)">
                </div>
              </div>
            </div>
            <div style="margin-right:3em">
              <div style="display: flex">
                <div>From:&nbsp;</div><div>{{fromDateText}}</div>
              </div>
              <div>
                <div style="display: flex">
                  <div>To:&nbsp;</div><div>{{toDateText}}</div>
                </div>
              </div>
            </div>
            <div style="margin-right:1em; margin-top:1em">
              <button type="button" mat-button class="btn-high" style="min-width: 5em;" (click)="clickRefresh()">
                <div style="display:flex">
                  <div style="margin-top: 0.25em">
                    <i class="material-icons" style="font-size: larger;cursor: pointer; color: white; ">refresh</i>
                  </div>
                  <div>Refresh</div>
                </div>
              </button>
            </div>
            <div>
                <div *ngIf="userType=='internal'" style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="includeDemo" style="transform: scale(0.75)" (change)="includeDemoOnChange($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em"><label for="includeDemo" style="font-size:small">&nbsp;Include Demo Trips</label></div>
                </div>
                <div *ngIf="userType=='internal'" style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="showMobileOnly" style="transform: scale(0.75)" (change)="showMobileOnlyChange($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em"><label for="showMobileOnly" style="font-size:small">&nbsp;Filter For Mobile</label></div>
                </div>
              <div style="margin-top:0em">{{totalRecordsText}}</div>
            </div>
            <div style="display: flex; margin-left:1em">
              <div style="margin-right: 1em; display:flex">
                <div style="margin-top:0.25em">
                  <input formControlName="user_type"
                         type="radio" name="user_type"
                         value="" (click)="userTypeChange($event, '')" />
                </div>
                <div style="white-space: nowrap;margin-top:0em;margin-left:0.25em">
                  <label>All</label>
                </div>
              </div>
              <div style="display: flex; margin-right:1em">
                <div style="margin-top:0.25em">
                  <input formControlName="user_type"
                         type="radio" name="user_type"
                         value="internal" (click)="userTypeChange($event, 'internal')" />
                </div>
                <div style="white-space: nowrap;margin-top:0em;margin-left:0.25em">
                  <label>Ops</label>
                </div>
              </div>
              <div style="display: flex">
                <div style="margin-top:0.25em">
                  <input formControlName="user_type"
                         type="radio" name="user_type"
                         value="customer" (click)="userTypeChange($event, 'customer')" />
                </div>
                <div style="white-space: nowrap;margin-top:0em;margin-left:0.25em">
                  <label>Clients</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container mat-elevation-z8">
          <tr>
            <ng-container matColumnDef="userName">
              <th mat-header-cell *matHeaderCellDef style="width:20em; text-align:left !important">
                <div style="margin-top:-0.25em;">Username</div>
              </th>
              <td mat-cell *matCellDef="let element" style="width:20em; text-align: left !important;">
                <div style="display:flex; margin-left:-0.5em">
                  <div style="margin-top:0.25em; margin-right:0.5em">
                    <span *ngIf="element.expand==false" class="material-icons" style="cursor: pointer; font-size: medium" title="click to expand" (click)="expandCollapseUsername($event, element, 'e')">navigate_next</span>
                    <span *ngIf="element.expand==true" class="material-icons" style="cursor: pointer; font-size: medium" title="click to collapse" (click)="expandCollapseUsername($event, element, 'c')">expand_more</span>
                  </div>
                  <div [style.background-color]="element.isExternal && user_type==''? '#c7e8ac': 'transparent'">{{element.userName}}</div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="otherInfo">
              <th mat-header-cell *matHeaderCellDef style="text-align:left !important">
                <div style="display:flex; margin-left:2em">
                  <div style="width:25em">
                    <div style="margin-top:-0.25em;">Client Acct</div>
                    <div style="margin-top:-0.25em;">Count</div>
                  </div>
                  <div style="width:25em">
                    <div style="margin-top:-0.25em;">Trip Code</div>
                    <div style="margin-top:-0.25em;">Count</div>
                  </div>
                  <div style="width:8em">
                    <div style="margin-top:-0.25em;">Transaction</div>
                    <div style="margin-top:-0.25em;">Count</div>
                  </div>
                  <div style="width:7em">
                    <div style="margin-top:-0.25em;">Trip Open</div>
                    <div style="margin-top:-0.25em;">Count</div>
                  </div>
                  <div style="width:13em">
                    Action
                  </div>
                </div>
                </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important;">
                <div style="display:flex">
                  <div style="width:3em"></div>
                  <div style="width:23em; ">{{element.clientCount}}</div>
                  <div style="width:23em; ">{{element.tripCodeCount}}</div>
                  <div style="width:7.5em; ">{{element.transactionCount>0?element.transactionCount:''}}</div>
                  <div style="width:2.5em; ">{{element.tripOpenCount>0?element.tripOpenCount:''}}</div>
                </div>
                <div *ngIf="element.expand==true">
                  <div *ngFor="let c of element.customerList">
                    <div style="display:flex">
                      <div style="width:27em; display:flex; margin-left:-1em">
                        <div style="margin-top:0.25em; margin-right:1em">
                          <span *ngIf="c.expand==false" class="material-icons" style="cursor: pointer; font-size: medium" title="click to expand" (click)="expandCollapseClient($event, c, 'e')">navigate_next</span>
                          <span *ngIf="c.expand==true" class="material-icons" style="cursor: pointer; font-size: medium" title="click to collapse" (click)="expandCollapseClient($event, c, 'c')">expand_more</span>
                        </div>
                        <div>{{c.customerName}}</div>
                      </div>
                      <div style="width:23em; ">{{c.tripCodeCount}}</div>
                      <div style="width:7.5em; ">{{c.transactionCount?c.transactionCount:''}}</div>
                      <div style="width:2.5em; ">{{c.tripOpenCount?c.tripOpenCount:''}}</div>
                    </div>
                    <div *ngIf="c.expand==true">
                      <div *ngFor="let t of c.tripList">
                        <div style="display:flex; height:1.75em">
                          <div style="width:20em">&nbsp;</div>
                          <div style="width:29em">Trip Code: {{t.tripCode}}&nbsp;Tail: {{t.registration}}</div>
                          <div style="width:7.5em">{{t.transactionCount?t.transactionCount:''}}</div>
                          <div style="width:2.5em">{{t.tripOpenCount?t.tripOpenCount:''}}</div>
                          <div style="width:13em; display: flex">
                            <div style="margin-right:0.25em">
                              <a mat-button class="btn-high grid" style="align-self:center" (click)="clickTripRevisions($event, t, element);">
                                Revisions
                              </a>
                            </div>
                            <div style="margin-right:0.25em">
                              <a mat-button class="btn-high grid" style="align-self:center" (click)="clickOpenTrip($event, t);">
                                Open Trip
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>            
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
    </div>
</form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
