export class PersonCommsAddressModel {
  customerGUID: string;
  personCommsAddressGUID: string;
  personGUID: string;
  addressUseGUIDList: string;
  addressUseDescriptionList: string;
  commAddressTypeGUID: string;
  commAddressType: string;
  address: string;
  extension: string;
  addressRatingGUID: string;
  addressRatingDescription: string;
  commRemarks: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  filterBy: string;
  textSearch: string;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  aircraftGUID: string;
  isPreferred: boolean;
  vendorGUID: string;
  includeInBrief: boolean;
  dataEntryStatusID: number;
  dataEntryStatusDescription: string;
  selectedTo: boolean;
  selectedCc: boolean;
  email: string;
  displayDetails: string;
  filterDataEntryStatus: boolean;
}
