export class CustomerAccountingProfileModel {
  customerGUID: string;
  customerAccountingProfileGUID: string;
  acctType: string;
  billToID: string;
  billTo_AccountName: string;
  referencePO: string;
  aircraftID: string;
  registration: string;
  servicesID: number;
  serviceTypeDescription: string;
  crewID: string;
  crewName: string;
  paxName: string;
  paxID: string;
  effectiveDate: string;
  expirationDate: string;
  remarks: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  filterBy: string;
  textSearch: string;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
}
