<div *ngIf="upPII" style="background-color:#f8f8f8;min-height:10em;min-width: 30em">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div>
      <i class="material-icons-outlined" style="font-size: xx-large; color: gray; margin-top:0.1em">help_outline</i>
    </div>
    <div style="margin-top:0.3125em;"><h5>FERRY FLIGHT LEG Setup</h5></div>
  </div>
  <div>
    <form *ngIf="leg_ferry_flight_editor_form" [formGroup]="leg_ferry_flight_editor_form">
      <div style="padding-left:1em; padding-right:1em; ">
        <div style="background-color: white; padding-left:0.5em; padding-bottom:0.5em">
          <div style="font-weight: bold">{{leg}}</div>
          <div style="margin-left:-0.5em; margin-top:1em;font-size: large">
            <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="isDeptFerryFlight" >Ferry Flight Leg</mat-slide-toggle>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; ">
    <div style="margin-right:1em;">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <button type="button" mat-button class="btn-high" *ngIf="loading==false && showSuccessMsg==false && !showSpin" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>SAVE</div></div></button>&nbsp;
      <button type="button" mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>CLOSE</div></div></button>
    </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
    <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0.0em; background-color:#f9d2de; padding-left:0.25em; min-width:22em; white-space:pre-wrap"><label style="color:black; font-size: small">{{errMsg}}</label></div>
  </div>
</div>
