
<div style="background-color:#f8f8f8"  cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>
  <div class="p-2 d-flex justify-content-between align-items-center">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="d-inline-flex">
      <mat-icon>help_outline</mat-icon>
      <h5>Help Guides</h5>
    </div>  
      <button mat-icon-button [mat-dialog-close]>
        <mat-icon>close</mat-icon>
      </button>
    
  </div>
  <div class="d-flex justify-content-center" style="margin-top:-.5em;color:deepskyblue"><h6>{{guide.helpGuideTitle}}</h6></div>

</div>
<div class="p-2">

  <div id="mainBodyHTML" name="mainBodyHTML" #mainBodyHTML style="height:35em;width:59em;overflow:auto;border:solid thin;border-color:lightgray;border-radius:.25em" [innerHTML]="getHTML(guide.mainBodyHTML)">

  </div>
  <div class="p-2 d-flex justify-content-between">
    <div>
      <button mat-button class="btn-high" (click)="editHelpGuide()" *ngIf="isAdmin">Edit</button>
    </div>
    <div>
      <span style="font-size:small">Last Updated: {{guide.modifiedDate}}</span>
    </div>
  </div>
 
  </div>
