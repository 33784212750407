<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-1em" mat-dialog-title>Headlines</div>
<app-spin *ngIf="showSpin"></app-spin>

<div class="small" *ngIf="headline!=null" style="background-color: white;font-size:small; font-size: small; padding: 1em; margin-top: -1em; min-width: 30em; width: 70em; max-height: 45em;">
  <div>
    <div *ngIf="headline.documentWeblink==null || headline.documentWeblink==''">
      <h6>{{headline.headlineTitle}}</h6>
    </div>
    <div *ngIf="headline.documentWeblink!=null && headline.documentWeblink!=''">
      <h6><span class="a1" (click)="openLink(headline.documentWeblink)">{{headline.headlineTitle}}</span></h6>
    </div>

  </div>
  <div>
    <div>
      {{headline.effectiveDate}}<span *ngIf="headline.expirationDate!=null && headline.expirationDate!= headline.effectiveDate">
        - {{headline.expirationDate}}
      </span>
    </div>

    <div *ngIf="headline.validTimeStart!=null">
      {{headline.validTimeStart}}-{{headline.validTimeEnd}} {{headline.isUTC? 'UTC':'Local'}}
    </div>
  </div>
    <!--<div style="white-space:pre-wrap">
      {{headline.headlineStory}}
    </div>-->
    <ckeditor #notesCKEditor [editor]="Editor" [config]="CKEConfig" (ready)="onReady($event)" [disabled]="isDisabled"></ckeditor>

  
  <div *ngIf="headline.contentDocuments && headline.contentDocuments.length>0" class="p-1 rounded mt-2" style="background-color:#f8f8f8; max-height:6.75em;overflow-y:auto ">
    <div>
      Attachments:
    </div>
    <ng-template ngFor let-doc [ngForOf]="headline.contentDocuments">
      <div><a class="a1" (click)="clickPreviewDoc($event, doc)">{{doc.documentName}}</a></div>
    </ng-template>
  </div>

</div>
<div class="mt-2">
  <button mat-button mat-dialog-close (click)="clickClose()" class="btn-low">Close</button>
</div>
