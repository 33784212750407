import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { IndustryTypeModel } from '../models/industry-type.model';
import { CustomerTypeModel } from '../models/customer-type.model';
import { MarketingRegionModel } from '../models/marketing-region.model';
import { ThirdPartyScreeningModel } from '../models/third-party-screening.model';
import { CustomerModel } from '../models/customer.model';
import { CreditCheckModel } from '../models/credit-check.model';
import { NoteTypeModel } from '../models/note-type.model';
import { CustomerNoteModel } from '../models/customer-note.model';
import { AirportModel } from '../models/airport.model';
import { CustomerHomebaseAirportModel } from '../models/customer-homebase-airport.model';



@Component({
  selector: 'app-customer-homebase-edit-dialog',
  templateUrl: './customer-homebase-edit-dialog.component.html',
  styleUrls: ['./customer-homebase-edit-dialog.component.css']
})
/** customer-profile-setup-add component*/
export class CustomerHomebaseEditDialogComponent implements OnInit {
  customer_homebase_edit_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() customerGuid: string;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  airportList: AirportModel[];
  isModified: boolean;
  customerHomebaseAirportID: number;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 8;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  displayedColumn: string[] = ['icao', 'iata', 'airport_name', 'airport_location', 'action'];
  showSuccessMsg: boolean = false;


  constructor(private readonly _dialogRef: MatDialogRef<CustomerHomebaseEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _customerService: CustomerService,
    private readonly _dialogService: DialogService
  ) {
    this.customerGuid = data.customerGUID;
    this.dialogTitle = data.dialogTitle;

  }


  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
   
    this.initControls();
    this.showSpin = false;
    
  
  }

  initControls() {
    this.customer_homebase_edit_form = this._formBuilder.group({
      airport_search: ""

    });
  }
  get f() { return this.customer_homebase_edit_form.controls; }

  

  clickSave(event: any, airport: AirportModel) {
    this._authService.updateAccessTime();
    this.submitted = true;
    
    if (this.customer_homebase_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    let obj = new CustomerHomebaseAirportModel();
    if (airport != null) {
      obj.airportID = airport.airportID;
      obj.customerGUID = this.customerGuid;
      this._customerService.saveCustomerHomebaseAirport<ResponseModel<number>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.customerHomebaseAirportID = response.model;
          //this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this._dialogRef.close(this.isModified);
          }, 1000);
          
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
     }
      this.loading = false;
    })
    }
    
   

  }

  clickSearchAirport() {
    this._authService.updateAccessTime();
    if (this.f.airport_search.value != null && this.f.airport_search.value != "") {
      let request = new AirportModel();
      request.searchField = this.f.airport_search.value;
      request.pageIndex = this.currentPageIndex;
      request.pageSize = this.pageSize;
      this._commonService.getAirportSearchResults<ResponseModel<AirportModel[]>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
          if (response.model.length > 0) {
            this.airportList = response.model;
            if (this.airportList[0].totalRecords > 0) {
              this.totalRecordsText = this.airportList[0].totalRecords.toString() + ' records found.';
              this.totalRecords = this.airportList[0].totalRecords;
              this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

            }
          }
          else {
            this.msg = "No records found.";

          }
        }
        }
      });
    } 
  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    this.clickSearchAirport();

  }

  clickClose() {
     this._dialogRef.close(this.isModified);
  }


}
