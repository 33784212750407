import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { CustomerService } from '../services/customer.service';
import { AircraftModel } from '../models/aircraft.model';
import { CustomerModel } from '../models/customer.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { DistanceTimeModel } from '../models/distance-time.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { TripModel } from '../models/trip.model';
import { GroundStopService } from '../services/ground-stop.service';
import { FARTypeModel } from '../models/far-type.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';

@Component({
  selector: 'app-ground-stop-creator-citypair-dialog',
  templateUrl: './ground-stop-creator-citypair-dialog.component.html',
  styleUrls: ['./ground-stop-creator-citypair-dialog.component.css']
})

export class GroundStopCreatorCityPairDialogComponent implements OnInit {
  //@Input() dialogTitle: string;
  //@Input() customerGUID: string;

  dialogTitle: string;
  customerGUID: string;

  ground_stop_creator_citypair_form: UntypedFormGroup;
  loading = false;

  submitted = false;
  errMsg: string;
  errMsg2: string;
  duplicateIATAErrMsg: string;
  msg: string;

  showSpin: boolean = true;
  userType: string;
  customerList: CustomerModel[];
  aircraftList: AircraftModel[];
  route: string;
  selectedAircraft: AircraftModel;
  selectedAircraftGUID: string;
  selectedSpeed: number;
  selectTripCodeType: string;
  //displayedColumn: string[] = ['addressUse'];
  isModified: boolean = false;
  loadingAirport: boolean = false;
  groundStopList: GroundStopModel[];
  cityPairList: GroundStopModel[];
  airportList: AirportModel[];
  validatedAirport: boolean = false;
  showSuccessMsg: boolean = false;
  
  displayedColumnCityPair: string[] = ['departure_airport', 'departureDate_Time', 'arrival_airport', 'arrivalDate_Time',];
  displayedColumnCityPair2: string[] = ['business_stop'];
  farTypeList: FARTypeModel[];
  returnObj = new ReturnObjModel();
  validatedTripCode: boolean = false;
  @ViewChild('submitted_date') submitted_dateRef: ElementRef;
  submitMaxDate: Date = new Date();

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  upPII: boolean = false;
  addBRTasks: boolean = true;
  minDate: Date;// = new Date();
  internalNonStandardProcedures: string = "";
  constructor(private readonly _dialogRef: MatDialogRef<GroundStopCreatorCityPairDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _customerService: CustomerService,
    private readonly _airportService: AirportService,
    private readonly _groundStopService: GroundStopService,
    private readonly _dialogService: DialogService
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }
    if (_data.aircraftGUID == null || _data.aircraftGUID == undefined) {
      this.selectedAircraftGUID = "";
    }
    else {
      this.selectedAircraftGUID = _data.aircraftGUID;
    }
    this.dialogTitle = _data.dialogTitle;

  }

  getAllData(): Observable<any[]> {
    let getFARResponse = this._groundStopService.getFARTypeList();
    if (this.userType == "internal") {
      let getClientListResponse = this._commonService.getCustomers();
      
      if (this.customerGUID != "") {
        let getAircraftResponse = this._customerService.getRegistrationListByCustomerGUID<ResponseModel<AircraftModel[]>>(this.customerGUID)
        return forkJoin([getFARResponse, getClientListResponse, getAircraftResponse]);
      }
      else {
        return forkJoin([getFARResponse, getClientListResponse]);
      }
    }
    else {
      let getAircraftResponse = this._customerService.getRegistrationListByCustomerGUID<ResponseModel<AircraftModel[]>>(this.customerGUID)
      return forkJoin([getFARResponse, getAircraftResponse]);
    }

  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }

    this.errMsg = "";
    this.errMsg2 = "";
    this.duplicateIATAErrMsg = "";
    this.msg = "";
    this.customerList = [];
    this.aircraftList = [];
    this.route = "";
    this.selectTripCodeType = "t-currentYear";
    this.groundStopList = [];
    this.airportList = [];
    this.cityPairList = [];
    this.farTypeList = [];
    this.validatedAirport = false;
    this.showSuccessMsg = false;
    let d = new Date();
    let uyear: number = d.getUTCFullYear();
    let umonth: number = d.getUTCMonth();
    let udate: number = d.getUTCDate();
    let uhour: number = d.getUTCHours();
    let uminute: number = d.getUTCMinutes();
    this.minDate = new Date(uyear, umonth, udate, uhour, uminute);

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    if (this.userType == "internal") {
      this.getAllData().subscribe(responses => {
        if (responses[0] != null) {
          if (responses[0].code == "200" && responses[0].message == "") {
            this.farTypeList = responses[0].model;

          }
          else {
            if (responses[0].code == "401") {
              this._authService.signOut();
            }
          }
        }

        if (responses[1] != null) {
          if (responses[1].code == "200" && responses[1].message == "") {
            this.customerList = responses[1].model;
            this.customerList = this.customerList.filter(x => x.accountID != '' && x.customerTypeId==1);
          }
          else {
            if (responses[1].code == "401") {
              this._authService.signOut();
            }
          }
        }

        if (this.customerGUID != "") {
          if (responses[2] != null) {
            if (responses[2].code == "200" && responses[2].message == "") {
              this.aircraftList = responses[2].model;
              if (this.selectedAircraftGUID != "") {
                this.selectedAircraft = this.aircraftList.find(x => x.aircraftGuid.toLowerCase() == this.selectedAircraftGUID.toLowerCase());
              }
            }
            else {
              if (responses[2].code == "401") {
                this._authService.signOut();
              }
            }
          }

        }

        this.initControls();

        this.showSpin = false;

      });
    }
    else {
      this.getAllData().subscribe(responses => {
        if (responses[0] != null) {
          if (responses[0].code == "200" && responses[0].message == "") {
            this.farTypeList = responses[0].model;

          }
          else {
            if (responses[0].code == "401") {
              this._authService.signOut();
            }
          }
        }

        if (responses[1] != null) {
          if (responses[1].code == "200" && responses[1].message == "") {
            this.aircraftList = responses[1].model;
            if (this.selectedAircraftGUID != "") {
              this.selectedAircraft = this.aircraftList.find(x => x.aircraftGuid.toLowerCase() == this.selectedAircraftGUID.toLowerCase());
            }
          }
          else {
            if (responses[1].code == "401") {
              this._authService.signOut();
            }
          }
        }

        this.initControls();

        this.showSpin = false;

      });
    }

  }

  initControls() {
    if (this.customerGUID != "") {
      this.customerGUID = this.customerGUID.toLowerCase();
    }
    this.ground_stop_creator_citypair_form = this._formBuilder.group({
      customer_select: [this.customerGUID],
      aircraft_select: [this.selectedAircraft, Validators.required],
      fartype_select: [0, Validators.required],
      trip_code_type_select: [this.selectTripCodeType],
      trip_code: ['', Validators.pattern("^[-a-zA-Z0-9]*$")],
      route: [this.route, Validators.compose([
        Validators.required,
        Validators.pattern("^[-a-zA-Z0-9,\* \n\r]*$")]
      )],
      //client_reference: [''],
      submittedBy: [''],
      client_trip_number: [],
      submitted_date: [new Date(), Validators.required],
      addBRTasks: this.addBRTasks

    });

  }

  get f() { return this.ground_stop_creator_citypair_form.controls; }

  customerChange(e: any) {
    this.customerGUID = this.f.customer_select.value; //e.target.value;
    this._customerService.getRegistrationListByCustomerGUID<ResponseModel<AircraftModel[]>>(this.customerGUID).subscribe(response => {
      if (response != null && response.code == "200") {
        this.aircraftList = response.model;
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get aircraft. Please try again later.";
        }
      }
      this.loading = false;
    })
  }

  aircraftChange(e: any) {
    if (e.target.value != null && e.target.value != '') {
      this.selectedAircraft = this.f.aircraft_select.value;
      this.selectedAircraftGUID = this.selectedAircraft.aircraftGuid;
      if (this.selectedAircraft.speed == 0) {
        this.selectedSpeed = 460;
      }
      else {
        this.selectedSpeed = this.selectedAircraft.speed;
      }      
    }
    else {
      this.selectedAircraftGUID = "";
      this.selectedSpeed = 460;
    }


  }

  tripCodeTypeChange(e: any) {
    if (e.target.value != 'f') {
      this.f.trip_code.clearValidators();
      this.f.trip_code.updateValueAndValidity();
    }
    else {
      this.f.trip_code.setValidators([Validators.required, Validators.pattern("^[-a-zA-Z0-9]*$"),]);
      this.f.trip_code.updateValueAndValidity();
    }

  }

  tripCodeChange(e: any) {
    if (e.target.value != '') {
      this.validatedTripCode = true;
      this.checkTripCode().subscribe(res1 => {
        if ((res1 != null || res1 != undefined) && res1 == true) {

        }
      });
    }

  }

  checkTripCode(): Observable<boolean> {
    return new Observable<boolean>(ob => {
      let request = new TripModel();
      request.tripCode = this.f.trip_code.value;

      this._groundStopService.checkTripCode<ResponseModel<boolean>>(request).subscribe(response => {
        if (response.code == "200") {
          if (response.model == true) {
            this.f.trip_code.setErrors({ alreadyExisted: true });
            ob.next(false);
          }
          else {
            ob.next(true);
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          ob.next(false);
        }
        //this.loading = false;
      })


    });

  }

  clickValidateAirport() {
    this.errMsg = "";
    this.duplicateIATAErrMsg = "";
    this.loadingAirport = true;
    this.validatedAirport = true;
    if (this.f.route.errors || this.f.aircraft_select.value == "" || this.f.aircraft_select.value == null || (this.userType == "internal" && this.f.customer_select.value == "")) {
      if (this.userType == "internal" && this.f.customer_select.value == "") {
        this.f.customer_select.setErrors({ required: true });
      }
      this.loadingAirport = false;
      return;
    }

    let request = new AirportModel();

    request.route = this.f.route.value;
    this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.groundStopList = [];
        this.cityPairList = [];
        this.airportList = response.model;
        if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
          let re = /,/gi;
          this.f.route.setValue(Array.prototype.map.call(this.airportList, s => s.icao).toString().replace(re, "-"));
          let i = 0;
          let cityPairOrder = 0;
          this.airportList.forEach((x, index) => {
            let obj = new GroundStopModel();
            obj.icao = x.icao;
            obj.iata = x.iata;
            obj.latitude = null;
            obj.longitude = null;
            if (x.latitude != null && x.longitude != null) {
              obj.latitude = x.latitude;
              obj.longitude = x.longitude;
            }
            obj.arrivalTimeUTC = "";
            obj.departureTimeUTC = "";
            obj.icaO_IATA = x.icao;
            if (x.iata != "") {
              obj.icaO_IATA += " / " + x.iata;
            }
            obj.departureDateUTC = null;
            obj.arrivalDateUTC = null;
            obj.arrivalDateTextUTC = "";
            obj.departureDateTextUTC = "";
            obj.isValidArrDate = true;
            obj.isValidArrTime = true;
            obj.isValidDeptDate = true;
            obj.isValidDeptTime = true;
            obj.arrReadOnly = false;
            obj.depReadOnly = false;
            obj.departureUTCTimeConversion = "";
            obj.arrivalUTCTimeConversion = "";
            //if (i == 0) {
            //  obj.arrReadOnly = true;
            //}
            //else {
            //  if (i == (this.airportList.length - 1)) {
            //    obj.depReadOnly = true;
            //  }
            //}
            i = i + 1;
            obj.groundStopSequenceID = i;
            obj.ete = "";

            obj.depStartAt = new Date();
            obj.arrStartAt = new Date();
            //this.groundStopList.push(obj);
            // add city pair
            cityPairOrder += 1;
            if (index < (this.airportList.length - 1)) {
              obj.departureICAO = obj.icao;
              obj.departureICAO_IATA = obj.departureICAO;
              if (obj.iata != "") {
                if (obj.departureICAO_IATA != "") {
                  obj.departureICAO_IATA += " / ";
                }
                obj.departureICAO_IATA += obj.iata;
              }
              obj.departureLatitude = null;
              obj.departureLongitude = null;
              if (x.latitude != null && x.longitude != null) {
                obj.departureLatitude = x.latitude;
                obj.departureLongitude = x.longitude;
              }
              obj.departureUTCTimeConversion = x.utcTimeConversionDisplay;
              obj.arrivalICAO = this.airportList[index + 1].icao
              obj.arrivalICAO_IATA = obj.arrivalICAO;
              if (this.airportList[index + 1].iata != "") {
                if (obj.arrivalICAO_IATA != "")
                  obj.arrivalICAO_IATA += " / ";
                obj.arrivalICAO_IATA += this.airportList[index + 1].iata;
              }
              obj.arrivalLatitude = null;
              obj.arrivalLongitude = null;
              if (this.airportList[index + 1].latitude != null && this.airportList[index + 1].longitude != null) {
                obj.arrivalLatitude = this.airportList[index + 1].latitude;
                obj.arrivalLongitude = this.airportList[index + 1].longitude;
              }
              obj.arrivalUTCTimeConversion = this.airportList[index + 1].utcTimeConversionDisplay;
              this.cityPairList.push(obj);

            }
            // add city pair end

          });
          //let x = this.cityPairList;
          this.validatedAirport = false;
        }
        if (this.airportList[0].wrongAirportErrMsg != "") {
          this.errMsg = "Invalid airports: " + this.airportList[0].wrongAirportErrMsg;
          this.validatedAirport = true;//false;
        }
        if (this.airportList[0].duplicateIATAErrMsg != "") {
          this.duplicateIATAErrMsg = "Duplicated IATAs: " + this.airportList[0].duplicateIATAErrMsg + "\n";
          this.duplicateIATAErrMsg += "Please change to use ICAO.";
          this.validatedAirport = true;
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to validate airport. Please try again later.";
          this.loadingAirport = false;
        }
      }
      this.loadingAirport = false;
    })

  }

  dateInputOnchange(dateType: string, e: any, item: GroundStopModel) {
    if (dateType == "A") {
      item.arrivalDateUTC = null;
    }
    if (dateType == "D") {
      item.departureDateUTC = null;
    }
  }

  dateOnchange(dateType: string, e: any, item: GroundStopModel, dataIndex: number) {
    this.errMsg2 = "";
    if (dateType == "D") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          item.isValidDeptDate = false;
          item.departureDateTextUTC = e.target._elementRef.nativeElement.value;
        }
        else {
          if (new Date(e.target.value).getUTCFullYear() >= new Date().getUTCFullYear()) {
            item.isValidDeptDate = true;
            item.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(e.target.value);
            this.getAirportUTCTimeConversion(item, "D");
            this.calculateArrivalDateTime(item);
            item.depStartAt = new Date(item.departureDateTextUTC);
            this.cityPairList.forEach((x, index) => {
              if (index >= dataIndex) {
                x.arrStartAt = item.depStartAt;
                x.depStartAt = item.depStartAt;
              }
            });
          }
          else {
            item.departureDateTextUTC = e.target._elementRef.nativeElement.value;
            item.isValidDeptDate = false;
          }        
        }
      }
      else {
        item.isValidDeptDate = true;
        item.departureDateTextUTC = "";
        item.departureDateUTC = null;
      }
    }

    if (dateType == "A") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          item.isValidArrDate = false;
          item.arrivalDateTextUTC = e.target._elementRef.nativeElement.value;
        }
        else {
          if (new Date(e.target.value).getUTCFullYear() >= new Date().getUTCFullYear()) {
            item.isValidArrDate = true;
            item.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(e.target.value);
            this.getAirportUTCTimeConversion(item, "A");
            item.arrStartAt = new Date(item.arrivalDateTextUTC);
            this.calculateDepartureDateTime(item);
            this.cityPairList.forEach((x, index) => {
              if (index > dataIndex) {
                x.depStartAt = item.arrStartAt;
                x.arrStartAt = item.arrStartAt;
              }

            });
          }
          else {
            item.isValidArrDate = false;
            item.arrivalDateTextUTC = e.target._elementRef.nativeElement.value;
          }           
        }
      }
      else {
        item.isValidArrDate = true;
        item.arrivalDateTextUTC = "";
        item.arrivalDateUTC = null;
      }
    }
    let x = 0;
  }

  timeOnchange(dateType: string, e: any, item: GroundStopModel) {
    this.errMsg2 = "";
    if (dateType == "D") {
      if (e.target.value != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        item.departureTimeUTC = timeObj.timeString;
        item.isValidDeptTime = timeObj.isValidDateTime;
        if (item.isValidDeptTime) {
          this.getAirportUTCTimeConversion(item, "D");
          this.calculateArrivalDateTime(item);
        }
        
      }
      else {
        item.departureTimeUTC = "";
        item.isValidDeptTime = true;
      }
    }

    if (dateType == "A") {
      if (e.target.value != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        item.arrivalTimeUTC = timeObj.timeString;
        item.isValidArrTime = timeObj.isValidDateTime;
        if (item.isValidArrTime) {
          this.getAirportUTCTimeConversion(item, "A");
          this.calculateDepartureDateTime(item);
        }
          
      }
      else {
        item.arrivalTimeUTC = "";
        item.isValidArrTime = true;
      }
    }

  }

  getAirportUTCTimeConversion(objCityPair: GroundStopModel, dateType: string) {
    let request = new AirportModel();
    if (dateType == "D") {
      objCityPair.departureUTCTimeConversion = "";
      request.icao = objCityPair.departureICAO;
      request.airportUTCDateTime = objCityPair.departureDateTextUTC;
      if (objCityPair.departureTimeUTC != "") {
        request.airportUTCDateTime += " " + objCityPair.departureTimeUTC;
      }
    }
    if (dateType == "A") {
      objCityPair.arrivalUTCTimeConversion = "";
      request.icao = objCityPair.arrivalICAO;
      request.airportUTCDateTime = objCityPair.arrivalDateTextUTC;
      if (objCityPair.arrivalTimeUTC != "") {
        request.airportUTCDateTime += " " + objCityPair.arrivalTimeUTC;
      }
    }
   
    this._airportService.getAirportLocalTimeFromUTC<ResponseModel<AirportModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        let obj = new AirportModel();
        obj = response.model;
        if (dateType == "D") {
          objCityPair.departureUTCTimeConversion = obj.utcTimeConversionDisplay;
        }
        if (dateType == "A") {
          objCityPair.arrivalUTCTimeConversion = obj.utcTimeConversionDisplay;
        }
       
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.loading = false;
    })

  }

  calculateArrivalDateTime(objCityPair: GroundStopModel) {
    if (objCityPair.departureDateTextUTC != "" && objCityPair.departureTimeUTC != "" && objCityPair.arrivalDateTextUTC != "" && objCityPair.arrivalTimeUTC != "") {
      //this.confirmDialogRef = this._dialogService.openConfirmDialog("You updated the Departure Date / Time it might impact Arrival Date time. Do you want to update arrival date / time?");
      //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      //  this.eventSubscription.unsubscribe();
      //  if (result) {
      //    objCityPair.arrivalDateTextUTC = "";
      //    objCityPair.arrivalTimeUTC = "";
      //    this.getArrivalDateTime(objCityPair);
      //  }
      //});
      objCityPair.arrivalDateTextUTC = "";
      objCityPair.arrivalTimeUTC = "";
      this.getArrivalDateTime(objCityPair);
    }
    else {
      this.getArrivalDateTime(objCityPair);
    }
    
    
  }

  getArrivalDateTime(objCityPair: GroundStopModel) {
    if (objCityPair.departureDateTextUTC != "" && objCityPair.departureTimeUTC != "" && (objCityPair.arrivalDateTextUTC == "" || objCityPair.arrivalTimeUTC == "")) {
      if (objCityPair.isValidDeptDate && objCityPair.isValidDeptTime) {
        if (objCityPair.departureLatitude != null && objCityPair.departureLongitude != null && objCityPair.arrivalLatitude != null && objCityPair.arrivalLongitude != null) {
          let request = new DistanceTimeModel();
          request.departureLatitude = objCityPair.departureLatitude;
          request.departureLongitude = objCityPair.departureLongitude;
          request.arrivalLatitude = objCityPair.arrivalLatitude;
          request.arrivalLongitude = objCityPair.arrivalLongitude;
          request.airSpeed = this.selectedSpeed; //460;
          request.bias = 15;
          this._airportService.getDistanceTripTime<ResponseModel<DistanceTimeModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              let obj = new DistanceTimeModel();
              obj = response.model;
              let h = Number(obj.tripTimeHour);
              let m = Number(obj.tripTimeMinute);
              let dt = new Date(objCityPair.departureDateTextUTC + " " + objCityPair.departureTimeUTC);
              let adt = UtilityFunctions.addHours(dt, h);
              adt = UtilityFunctions.addMinutes(dt, m);

              objCityPair.arrivalDateUTC = adt
              objCityPair.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(objCityPair.arrivalDateUTC);
              let ah = adt.getHours();
              let am = adt.getMinutes();
              if (am < 10) {
                objCityPair.arrivalTimeUTC = ah.toString() + ":0" + am.toString();
              }
              else {
                objCityPair.arrivalTimeUTC = ah.toString() + ":" + am.toString();
              }
              objCityPair.ete = obj.tripTime;
              this.getAirportUTCTimeConversion(objCityPair, "A");
            }
            else {
              if (response.code == "401") {
                //this.errMsg = response.message
                this._authService.signOut();
              }

            }
            this.loading = false;
          })
        }
      }
    }
  }

  calculateDepartureDateTime(objCityPair: GroundStopModel) {
    if (objCityPair.arrivalDateTextUTC != "" && objCityPair.arrivalTimeUTC != "" && (objCityPair.departureDateTextUTC == "" || objCityPair.departureTimeUTC == "")) {
      if (objCityPair.isValidArrDate && objCityPair.isValidArrTime) {
        if (objCityPair.departureLatitude != null && objCityPair.departureLongitude != null && objCityPair.arrivalLatitude != null && objCityPair.arrivalLongitude != null) {
          let request = new DistanceTimeModel();
          request.departureLatitude = objCityPair.departureLatitude;
          request.departureLongitude = objCityPair.departureLongitude;
          request.arrivalLatitude = objCityPair.arrivalLatitude;
          request.arrivalLongitude = objCityPair.arrivalLongitude;
          request.airSpeed = this.selectedSpeed; //460;
          request.bias = 15;
          this._airportService.getDistanceTripTime<ResponseModel<DistanceTimeModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              let obj = new DistanceTimeModel();
              obj = response.model;
              let h = Number(obj.tripTimeHour);
              let m = Number(obj.tripTimeMinute);
              let dt = new Date(objCityPair.arrivalDateTextUTC + " " + objCityPair.arrivalTimeUTC);
              let ddt = UtilityFunctions.addHours(dt, -h);
              ddt = UtilityFunctions.addMinutes(dt, -m);
              objCityPair.departureDateUTC = ddt;
              objCityPair.depStartAt = objCityPair.departureDateUTC;
              objCityPair.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(objCityPair.departureDateUTC);
              let dh = ddt.getHours();
              let dm = ddt.getMinutes();
              if (dm < 10) {
                objCityPair.departureTimeUTC = dh.toString() + ":0" + dm.toString();
              }
              else {
                objCityPair.departureTimeUTC = dh.toString() + ":" + dm.toString();
              }
              objCityPair.ete = obj.tripTime;
              this.getAirportUTCTimeConversion(objCityPair, "D");
            }
            else {
              if (response.code == "401") {
                //this.errMsg = response.message
                this._authService.signOut();
              }

            }
            this.loading = false;
          })


        }
      }
    }
  
  }

  clickSave() {
    this.errMsg2 = "";
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.submitted_dateRef.nativeElement.value !== "") {
      if (this.f.submitted_date.value != "" && this.f.submitted_date.value != null) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.submitted_date.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.submitted_date.setErrors({ isValidDate: f1 });

        }
      }
      else {
        this.f.submitted_date.setErrors({ isValidDate: false });

      }
    }

    if (this.f.fartype_select.value == 0) {
      this.f.fartype_select.setErrors({ required: true });
    }
    
    if (this.ground_stop_creator_citypair_form.invalid || this.errMsg != "") {
      return;
    }
    if (this.f.trip_code_type_select.value == 'f') {
      this.checkTripCode().subscribe(res1 => {
        if (res1 != null || res1 != undefined) {
          if (res1 == false) {
            return;
          }
          else {
            this.clickSave2();
          }

        }

      });
    }
    else {
      this.clickSave2();
    }


  }

  clickSave2() {
    this.errMsg2 = "";
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.userType == "internal") {
      if (this.f.customer_select.value == "") {
        this.f.customer_select.setErrors({ required: true });
      }
    }

    this.cityPairList.forEach(x => {
      x.isValidArrDate = true;
      x.isValidArrTime = true;
      x.isValidDeptDate = true;
      x.isValidDeptTime = true;
      x.arrivalPetsOnBoard = false;
      x.departurePetsOnBoard = false;
      x.arrivalWeaponsOnBoard = false;
      x.departurePetsOnBoard = false;

    });

    let hasAllValidFields = true;
    this.cityPairList.forEach((x, index) => {
      // dept
      
      if (x.departureDateTextUTC != "") {
        let a: number = new Date(x.departureDateTextUTC).getUTCFullYear();
        let b: number = new Date().getUTCFullYear();
        if (new Date(x.departureDateTextUTC).getUTCFullYear() < new Date().getUTCFullYear()) {
          x.isValidDeptDate = false;
          if (hasAllValidFields == true) {
            hasAllValidFields = x.isValidDeptDate;
          }
        }
        else {
          x.isValidDeptDate = CustomValidators.isValidDate(x.departureDateTextUTC);
          if (hasAllValidFields == true && x.isValidDeptDate == false) {
            hasAllValidFields = x.isValidDeptDate;
          }
          if (x.isValidDeptDate) {
            x.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.departureDateTextUTC));
            x.departureDateUTC = new Date(x.departureDateTextUTC);
          }
        }       
      }
      

      if (x.departureTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.departureTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.departureTimeUTC = timeObj.timeString;
        let depttv = timeObj.isValidDateTime;
        x.isValidDeptTime = depttv;
        if (hasAllValidFields == true && depttv == false) {
          hasAllValidFields = depttv;
        }
      }

      if (x.arrivalDateTextUTC != "") {
        if (new Date(x.arrivalDateTextUTC).getUTCFullYear() < new Date().getUTCFullYear()) {
          x.isValidArrDate = false;
          if (hasAllValidFields == true) {
            hasAllValidFields = x.isValidArrDate;
          }
        }
        else {
          x.isValidArrDate = CustomValidators.isValidDate(x.arrivalDateTextUTC);
          if (hasAllValidFields == true && x.isValidArrDate == false) {
            hasAllValidFields = x.isValidArrDate;
          }
          if (x.isValidArrDate) {
            x.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.arrivalDateTextUTC));
            x.arrivalDateUTC = new Date(x.arrivalDateTextUTC);
          }
        }
      }

      if (x.arrivalTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.arrivalTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.arrivalTimeUTC = timeObj.timeString;
        let arrtv = timeObj.isValidDateTime;
        x.isValidArrTime = arrtv;
        if (hasAllValidFields == true && arrtv == false) {
          hasAllValidFields = arrtv;
        }
      }

      if (x.departureDateUTC != null && x.arrivalDateUTC != null) {
        if (x.departureDateUTC.getTime()> x.arrivalDateUTC.getTime() ) {        
          this.errMsg2 = "Departure date must be earlier than the arrival date.";
          return;
        }
        else {
          if (x.departureTimeUTC != "" && x.arrivalTimeUTC != "") {
            let td = new Date(x.departureDateTextUTC + " " + x.departureTimeUTC);
            let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);
            
            if (td.getTime() > ta.getTime()) {          
              this.errMsg2 = "Departure date time must be earlier than the arrival date time.";
              return;
            }
            else {
              let dif = (ta.getTime() - td.getTime()) / 1000 / 3600;
              if (dif >= 24) {
                this.errMsg2 = "ETE can not be more than 24 hours.";
                return;
              }
            }
          }
        }
      }

      if (index > 0) {
        if (x.departureDateUTC != null){
          if (this.cityPairList[index - 1].departureDateUTC != null) {
            let d1 = new Date(this.cityPairList[index - 1].departureDateUTC);
            let d2 = new Date(x.departureDateUTC);
            if (d1.getTime() > d2.getTime()) {
              this.errMsg2 = "The city pairs must be in chronological order.";
              return;
            }
          }
          else {
            if (this.cityPairList[index - 1].arrivalDateUTC != null) {
              let d1 = new Date(this.cityPairList[index - 1].arrivalDateUTC);
              let d2 = new Date(x.departureDateUTC);
              if (d1.getTime() > d2.getTime()) {
                this.errMsg2 = "The city pairs must be in chronological order.";
                return;
              }
            }
          }

          if (x.departureTimeUTC != "") {
            if (this.cityPairList[index - 1].departureDateUTC != null && this.cityPairList[index - 1].departureTimeUTC!="") {
              let d1 = new Date(this.cityPairList[index - 1].departureDateTextUTC + " " + this.cityPairList[index - 1].departureTimeUTC);
              let d2 = new Date(x.departureDateTextUTC + " " + x.departureTimeUTC);
              if (d1.getTime() > d2.getTime()) {
                this.errMsg2 = "The city pairs must be in chronological order.";
                return;
              }
            }
            else {
              if (this.cityPairList[index - 1].arrivalDateUTC != null && this.cityPairList[index - 1].arrivalTimeUTC!="") {
                let d1 = new Date(this.cityPairList[index - 1].arrivalDateTextUTC + " " + this.cityPairList[index - 1].arrivalTimeUTC);
                let d2 = new Date(x.departureDateTextUTC + " " + x.departureTimeUTC);
                if (d1.getTime() > d2.getTime()) {
                  this.errMsg2 = "The city pairs must be in chronological order.";
                  return;
                }
              }
            }
          }
        }

      }

    });

    if (!hasAllValidFields) {
      return;
    }
    this.groundStopList = [];
    let gs: GroundStopModel;
    this.cityPairList.forEach((x, index) => {
      gs = new GroundStopModel();
      gs.icao = x.departureICAO;
      gs.ete = x.ete;
      gs.arrivalPetsOnBoard = false;
      gs.departurePetsOnBoard = false;
      gs.departureWeaponsOnBoard = false;
      gs.arrivalWeaponsOnBoard = false;
      gs.isTechStop = false;
      gs.callSign = "";
      if (index == 0) {
        gs.arrivalDateUTC = null;
        gs.arrivalDateTextUTC = "";
        gs.arrivalTimeUTC = "";
        gs.departureDateUTC = x.departureDateUTC;
        gs.departureDateTextUTC = x.departureDateTextUTC;
        gs.departureTimeUTC = x.departureTimeUTC;
      }
      else {
        if ((index>0)) {
          gs.arrivalDateUTC = this.cityPairList[index - 1].arrivalDateUTC;
          gs.arrivalDateTextUTC = this.cityPairList[index - 1].arrivalDateTextUTC;
          gs.arrivalTimeUTC = this.cityPairList[index - 1].arrivalTimeUTC;
          gs.departureDateUTC = x.departureDateUTC;
          gs.departureDateTextUTC = x.departureDateTextUTC;
          gs.departureTimeUTC = x.departureTimeUTC;
        }
      }
      this.groundStopList.push(gs);

    });

    // add last one
    gs = new GroundStopModel();
    gs.icao = this.cityPairList[this.cityPairList.length - 1].arrivalICAO;
    gs.arrivalDateUTC = this.cityPairList[this.cityPairList.length - 1].arrivalDateUTC;
    gs.arrivalDateTextUTC = this.cityPairList[this.cityPairList.length - 1].arrivalDateTextUTC;
    gs.arrivalTimeUTC = this.cityPairList[this.cityPairList.length - 1].arrivalTimeUTC;
    gs.departureDateUTC = null;
    gs.departureDateTextUTC = "";
    gs.departureTimeUTC = "";
    gs.ete = "";
    gs.arrivalPetsOnBoard = false;
    gs.departurePetsOnBoard = false;
    gs.departureWeaponsOnBoard = false;
    gs.arrivalWeaponsOnBoard = false;
    gs.isTechStop = false;
    gs.callSign = "";
    this.groundStopList.push(gs);
    // add last one end

    this.groundStopList.forEach((x, index) => {
      if (x.arrivalDateTextUTC != "") {
        x.isValidArrDate = CustomValidators.isValidDate(x.arrivalDateTextUTC);
        if (hasAllValidFields == true && x.isValidArrDate == false) {
          hasAllValidFields = x.isValidArrDate;
        }
        if (x.isValidArrDate) {
          x.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.arrivalDateTextUTC));
          x.arrivalDateUTC = new Date(x.arrivalDateTextUTC);
        }
      }

      if (x.arrivalTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.arrivalTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.arrivalTimeUTC = timeObj.timeString;
        let arrtv = timeObj.isValidDateTime;
        x.isValidArrTime = arrtv;
        if (hasAllValidFields == true && arrtv == false) {
          hasAllValidFields = arrtv;
        }
      }
      // dept
      if (x.departureDateTextUTC != "") {
        if (x.departureDateTextUTC != "") {
          x.isValidDeptDate = CustomValidators.isValidDate(x.departureDateTextUTC);
          if (hasAllValidFields == true && x.isValidDeptDate == false) {
            hasAllValidFields = x.isValidDeptDate;
          }
          if (x.isValidDeptDate) {
            x.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.departureDateTextUTC));
            x.departureDateUTC = new Date(x.departureDateTextUTC);
          }
        }
      }

      if (x.departureTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.departureTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.departureTimeUTC = timeObj.timeString;
        let depttv = timeObj.isValidDateTime;
        x.isValidDeptTime = depttv;
        if (hasAllValidFields == true && depttv == false) {
          hasAllValidFields = depttv;
        }
      }

      if (index > 0) {
        let prev = this.groundStopList[index - 1];
        if (x.arrivalDateUTC != null && prev.departureDateUTC != null) {
          if (x.arrivalDateUTC.getTime() < prev.departureDateUTC.getTime()) {
            this.errMsg2 = "Arrival date can not be earlier than the departure date of the previous business stop.";
            return;
          }
          else {
            if (x.arrivalTimeUTC != "" && prev.departureTimeUTC != "") {
              let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);
              let td = new Date(prev.departureDateTextUTC + " " + prev.departureTimeUTC);
              if (ta.getTime() < td.getTime()) {               
                this.errMsg2 = "Arrival date time can not be earlier than the departure date time of the previous business stop.";
                return;
              }
              else {
                let dif = (ta.getTime() - td.getTime()) / 1000 / 3600;
                if (dif >= 24) {
                  this.errMsg2 = "ETE can not be more than 24 hours.";
                  return;
                }

              }
            }
          }
        }

        if (x.arrivalDateUTC != null && x.departureDateUTC != null) {
          if (x.arrivalDateUTC.getTime() > x.departureDateUTC.getTime()) {
            this.errMsg2 = "Arrival date time can not be later than the next departure date time.";
            return;
          }
          else {
            if (x.arrivalTimeUTC != "" && x.departureTimeUTC != "") {
              let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);
              let td = new Date(x.departureDateTextUTC + " " + x.departureTimeUTC);
              if (ta.getTime() > td.getTime()) {
                this.errMsg2 = "Arrival date time can not be later than the next departure date time.";
                return;

              }
            }

          }

        }
      }

    });


    if (this.ground_stop_creator_citypair_form.invalid || this.errMsg != "" || this.errMsg2 != "" || !hasAllValidFields) {
      return;
    }

    this.loading = true;

    let obj = new TripModel();
    obj.customerGUID = "";
    if (this.userType == "internal") {
      obj.customerGUID = this.f.customer_select.value;
    }
    obj.aircraftGUID = this.selectedAircraftGUID;// this.f.aircraft_select.value;
    obj.fARTypeID = Number(this.f.fartype_select.value);
    obj.tripCodeType = this.f.trip_code_type_select.value;
    obj.tripCode = "";
    if (obj.tripCodeType == 'f') {
      obj.tripCode = this.f.trip_code.value;
    }
    obj.customerReference = "";// this.f.client_reference.value;
    obj.submittedBy = this.f.submittedBy.value;
    obj.customerTripID = this.f.client_trip_number.value;
    obj.submitDate = CustomValidators.formatDateToMMDDYYYY(this.f.submitted_date.value);
    obj.groundStopList = this.groundStopList;
    obj.addBRTasks = this.f.addBRTasks.value;
    obj.opsNotes = this.internalNonStandardProcedures;
    this._groundStopService.createTrip<ResponseModel<TripModel>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          if (response.model.message != "") {
            if (response.model.failType == 'Duplicated trip codes') {
              this.f.trip_code.setErrors({ alreadyExisted: true });
            }
            else {
              this.errMsg = response.model.message;
            }
          }
          else {
            let t = new TripModel();
            t = response.model;
            if (t.tripCode != "") {
              this.isModified = true;
              this.returnObj.refresh = this.isModified;
              //this.returnObj.newId = obj.customerGUID;
              this.returnObj.newId = t.tripCodeGUID.toLowerCase();
              this.returnObj.aircraftGUID = obj.aircraftGUID;
              this.returnObj.customerGUID = obj.customerGUID;
              this.returnObj.tripCodeType = "";
              if (obj.tripCodeType == "e" || obj.tripCodeType == "c") {
                this.returnObj.tripCodeType = obj.tripCodeType;
              }
              if (t.emailSent == true) {
                this.showSuccessMsg = true;
                setTimeout(() => {
                  this.showSuccessMsg = false;
                  this.clickClose();
                }, 1000);
              }
              else {
                this.errMsg = "Failed to send email at this time. Please try later.";
              }
            }
          }

        }
        else {
          //this._dialogService.openAlertDialog("Failed to create the business stops at this time. Please try later.");
          this.errMsg = "Failed to create the business stops at this time. Please try later.";
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to create the business stops at this time. Please try later.";
        }
      }
      this.loading = false;
    })

  }



  clickClose() {
    //this._dialogRef.close(this.isModified);
    this._dialogRef.close(this.returnObj);
  }


}
