//import { FuelSupplierFileModel } from "./fuelsupplierFile.model";

export class FuelSupplierModel {
  

  fuelSupplierGUID: string;
  fuelSupplierName: string;
  fuelSupplierShortname: string;
  lk_FuelFrequencyDomGUID: string;
  lk_FuelFrequencyIntGUID: string;
  fuelScaleNeedsAdjustment: boolean;
  lk_UnitOfMeasureID: number;
  lk_CurrencyID: number;
  currencyID: string;
  isAllowImport: boolean;
  removeExtraRows: boolean;
  internalNotes: string;
  fuelSupplierWebsite: string;
  fuelReleaseEmail: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  taxesIncluded: boolean;

  allowfuelpriceimport: boolean;


  effectiveDate: Date;
  expirationDate: Date;
  selectedICAOList: string;
  selectedCountryGUIDList: string;
  selectedFuelSupplierList: string;
  selectedIntoPlaneAgentList: string;


  pageSize: number;
  pageIndex: number;
  totalRecords: number;
  taxesIncludedDom: boolean;
  taxesIncludedInt: boolean;
  
  
  selected: boolean;
  excludedCountries: string;
  profileCount: number;
}
