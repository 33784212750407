import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DocumentTypeModel } from '../models/document-type.model';
import { ResponseModel } from '../models/response.model';
import { CommonService } from '../services/common.service';
import { Title } from '@angular/platform-browser';
import { AuthenticateService } from '../services/authenticate.service';
import { DocumentTypeEditComponent } from './document-type-edit.component';

@Component({
  selector: 'app-document-type-list',
  templateUrl: './document-type-list.component.html',
  styleUrls: ['./document-type-list.component.css']
})

export class DocumentTypeListComponent implements OnInit {
  document_type_list_form: UntypedFormGroup;
  // loading = false;
  isDialog: boolean = false;
  isCrew: boolean;
  isPax: boolean;
  isSubDocument: boolean;
  isVendor: boolean;
  isCustomer: boolean;
  isAircraft: boolean;
  isCountry: boolean;
  isTask: boolean;
  hasSubdocument: boolean;
  isAirport: boolean;
  isPermitRequiredDoc: boolean;
  isMiscellaneous: boolean;
  displayedColumn: string[] = ['doc_type', 'action'];
  displayedColumnCrew: string[] = ['doc_type', 'sort',  'action'];
  displayedColumnPax: string[] = ['doc_type', 'doc_code', 'sort',  'action'];
  // displayedColumns: string[] = ['name', 'weight', 'symbol', 'position'];
  // columnsToDisplay: string[] = ['name', 'weight', 'symbol', 'position'];
  showSpin: boolean = false;
  documentTypeList: DocumentTypeModel;
  // documentTypeList: DocumentTypeModel = [];
  // columnsToDisplay: string[] = this.displayedColumns.slice();
  
  docType: string;

  constructor(
    @Optional() private readonly _dialogRef: MatDialogRef<DocumentTypeListComponent>, private readonly _commonService: CommonService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,  private readonly _authService: AuthenticateService, 
    private readonly dialog: MatDialog, private readonly _formBuilder: UntypedFormBuilder,private _titleService:Title  ,
    ){

      if (this.data) {
        this.isDialog = true;
        // this.username = this.data.username;
      }  
   
  }

  ngOnInit() {

    // this._authService.updateAccessTime();

    this.initControls();
    this.getData();


   }

  initControls() {
    this.document_type_list_form = this._formBuilder.group({
      doc_type: ['IsCrew']
    });


  }

  get f() { return this.document_type_list_form.controls; }



 

  clickClose() {
    this._dialogRef.close(); 
  }

  documentTypeChange(e: any){
    switch (this.f.doc_type.value) {
      case 'Crew':
        this.disableAllDocTypes();
        this.isCrew = true; // Removed line
        break;

      case 'Pax':
        this.disableAllDocTypes();
        this.isPax = true; // Removed line
        break;

      case 'IsSubDocument':
        this.disableAllDocTypes();
        this.isSubDocument = true; // Removed line
        break;

      case 'Vendor':
        this.disableAllDocTypes();
        this.isVendor = true; // Removed line
        break;

      case 'Customer':
        this.disableAllDocTypes();
        this.isCustomer = true; // Removed line
        break;

      case 'Aircraft':
        this.disableAllDocTypes();
        this.isAircraft = true; // Removed line
        break;

      case 'Country':
        this.disableAllDocTypes();
        this.isCountry = true; // Removed line
        break;

      case 'Task':
        this.disableAllDocTypes();
        this.isTask = true; // Removed line
        break;

      case 'HasSubdocument':
        this.disableAllDocTypes();
        this.hasSubdocument = true; // Removed line
        break;

      case 'Airport':
        this.disableAllDocTypes();
        this.isAirport = true; // Removed line
        break;

      case 'PermitRequiredDoc':
        this.disableAllDocTypes();
        this.isPermitRequiredDoc = true; // Removed line
        break;

      case 'Miscellaneous':
        this.disableAllDocTypes();
        this.isMiscellaneous = true; // Removed line
        break;

      default:
        break;
    }
    this.getData();
  }


  getData(){

 
    let request = new DocumentTypeModel();
    
   
    // request.isCrew =  this.isCrew ; 
    // request.isPax = this.isPax;
    // request.isSubDocument = this.isSubDocument;
    // request.isVendor = this.isVendor;
    // request.isCustomer = this.isCustomer;
    // request.isAircraft = this.isAircraft;
    // request.isCountry = this.isCountry;
    // request.isTask = this.isTask;
    // request.hasSubdocument = this.hasSubdocument;
    // request.isAirport = this.isAirport;
    // request.isPermitRequiredDoc = this.isPermitRequiredDoc;
    // request.isMiscellaneous = this.isMiscellaneous;
     
 
    request.docType = this.f.doc_type.value;
    request.documentTypeID = 0;
    this.showSpin = true;

    let getUpcomingTripCloseoutListResponse = this._commonService.getAllDocumentTypeList<ResponseModel<DocumentTypeModel>>(request).subscribe(response => {

      // console.log(response)
      if (response != null) {
        if (response.code == "200" && response.message == "") {

 
          //this._titleService.setTitle("Document Type");
          
          // this.documentTypeList = [];

          this.documentTypeList = response.model;
          this.showSpin = false;
          // this.initControls();

        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }

    });

  }



  disableAllDocTypes(){
    this.isCrew = false;
    this.isPax = false;
    this.isSubDocument = false;
    this.isVendor = false;
    this.isCustomer = false;
    this.isAircraft = false;
    this.isCountry = false;
    this.isTask = false;
    this.hasSubdocument = false;
    this.isAirport = false;
    this.isPermitRequiredDoc = false;
    this.isMiscellaneous = false;

  }

  clickRouteNew(route: string,display: string){
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }


  
  openDocumentTypeEdit(e: any, element: DocumentTypeModel){
    // clickRoute('account-groups/account-grouplist','')
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    // config.height = "22em";//"1000px";
    config.maxWidth = "64.5em";//"1000px";
    config.width = "50em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {    v: this._authService.getCurrentTimeNumber(), isDialog : true , documentTypeID: element.documentTypeID, title: 'Edit' , doc_type : this.f.doc_type.value };
    this.dialog.open(DocumentTypeEditComponent, config);
  }

  
  openDocumentTypeAdd(){
    // clickRoute('account-groups/account-grouplist','')
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    // config.height = "22em";//"1000px";
    config.maxWidth = "64.5em";//"1000px";
    config.width = "50em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {    v: this._authService.getCurrentTimeNumber(), isDialog : true , documentTypeID: 0, title: 'Add' , doc_type : this.f.doc_type.value };
    this.dialog.open(DocumentTypeEditComponent, config);
  }

}
