<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-1em" mat-dialog-title>Generate Documents</div>
<div mat-dialog-content style="margin-top:-1em;padding-bottom:0; background-color:white;"class="mx-1">
  <form novalidate *ngIf="generate_task_documents_form" [formGroup]="generate_task_documents_form">
    <div class="row">
      <div class="col-12 form-group">
        <label for="template_select"  style="font-size:small">Select Document</label>
        <select *ngIf="f" formControlName="template_select" class="form-control form-control-sm dropdown" style="margin-top:-.35em">
          <option value="gendec">GenDec</option>
          <option value="gendec-s">Singapore GenDec</option>
          <option value="gendec-p">Paris GenDec</option>
          <option value="cbp">CBP 7507</option>
        </select>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 form-group justify-content-start">
        <mat-radio-group id="pii" color="primary" (change)="changePII($event)">
          <mat-radio-button [checked]="includePII==1" value="1" style="transform:scale(.75)">Include PII</mat-radio-button>
          <mat-radio-button [checked]="includePII==2" class="ml-1" value="2" style="transform:scale(.75)">Hide PII</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 form-group justify-content-start">
        <mat-radio-group id="docFormat" color="primary" (change)="changeFormat($event)">
          <mat-radio-button [checked]="format=='pdf'" class="ml-1" value="pdf" style="transform:scale(.75)">PDF</mat-radio-button>
          <mat-radio-button [checked]="format=='word'" value="word" style="transform:scale(.75)">Word</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div>{{errMsg}}</div>
  </form>
</div>

<div align="start">
  <button class="mt-2 btn btn-primary" [disabled]="loading" (click)="generateDoc()" ><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Generate&nbsp;</button>
  <button class="mt-2 ml-2 btn btn-secondary" mat-dialog-close>&nbsp;Close&nbsp;</button>
</div>
