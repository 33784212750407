export class GroundStopAlternateAirportModel {
  groundStopAlternateAirportGUID: string;
  groundStopGUID: string;
  groundStopTypeDescription:string
  icao: string;
  iata: string;
  icaO_IATA: string;
  airportName: string;
  airportState_CountryCode: string;
  runwayMaxLength: number;
  elevation: number;
  Latitude: number
  Longitude:number
  distance:number
  alternateAirportSequenceNumber: string;
  alternateAirportTypeDescription: string;
  alternateAirportSequenceNumberID: number;
  alternateAirportTypeID: number;
  includeVendorAssignment: boolean;
  vendorName: string;
  isActive: boolean;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  centerLatitude: number;
  centerLongitude: number;
  vendorGUID: string;
  nextGroundStopGUID: string;
  airportID: number;
}
