import { Component, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomValidators } from '../common-utility/custom.validators';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { AircraftModel } from '../models/aircraft.model';
import { AirportModel } from '../models/airport.model';
import { BusinessRulesApprovalStatusModel } from '../models/business-rules-approval-status.model';
import { BusinessRulesModel } from '../models/business-rules.model';
import { CountryModel } from '../models/country.model';
import { CustomerCreditStatusModel } from '../models/customer-credit-status.model';
import { CustomerModel } from '../models/customer.model';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { ResponseModel } from '../models/response.model';
import { ServiceClassModel } from '../models/service-class.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { TripLegTypeModel } from '../models/trip-leg-type.model';
import { TripModel } from '../models/trip.model';
import { VendorModel } from '../models/vendor.model';
import { AuthenticateService } from '../services/authenticate.service';
import { BusinessRulesService } from '../services/business-rules-service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { BusinessRulesEditComponent } from './business-rules-edit.component';

@Component({
  selector: 'app-manage-business-rules',
  templateUrl: './manage-business-rules.component.html',
  styleUrls: ['./manage-business-rules.component.css']
})

export class ManageBusinessRulesComponent implements OnInit {
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  manage_business_rules_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg1: string;
  msg: string;
  customerList: CustomerModel[];
  //userType: string;
  showSpin: boolean = false;

  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  selectedTabName: string;
  reloadChildPage: boolean;
  totalRecordsText: string;
  totalRecords: number;
  v: number = this._authService.getCurrentTimeNumber();
  businessRulesList: BusinessRulesModel[] = [];
  startDate: Date;
  endDate: Date;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  pageFrom: string = "";
  sort_by: string;
  upbRule: boolean = false;
  aircraftList: AircraftModel[];
  selectedAircraftList: AircraftModel[];
  showClientList: boolean = false;
  showAircraftList: boolean = false;
  airportList: AirportModel[];
  selectedAirportList: AirportModel[];
  showAirportList: boolean = false;
  showPersonList: boolean = false;
  pilotList: GroundStopManifestModel[];
  selectedPilotList: GroundStopManifestModel[];
  crewList: GroundStopManifestModel[];
  selectedCrewList: GroundStopManifestModel[];
  paxList: GroundStopManifestModel[];
  selectedPaxList: GroundStopManifestModel[];
  person_role: string;
  service: string = "service_class";
  showVendorList: boolean = false;
  showCountryList: boolean = false;
  showLegTypeList: boolean = false;
  showServiceList: boolean = false;
  showStatusList: boolean = false;
  showExclusionList: boolean = false;
  vendorList: VendorModel[];
  orgVendorList: VendorModel[];
  selectedVendorList: VendorModel[];
  orgCustomerList: CustomerModel[];
  orgAircraftList: AircraftModel[];
  selectedCustomerList: CustomerModel[];
  clientList: CustomerModel[];
  filteredClientList: Observable<CustomerModel[]>;
  filteredAircraftList: Observable<AircraftModel[]>;
  filteredAirportList: Observable<AirportModel[]>;
  filteredVendorList: Observable<VendorModel[]>;
  filteredCountryList: Observable<CountryModel[]>;
  filteredServiceList: Observable<ServiceTypeModel[]>;
  filteredServiceClassList: Observable<ServiceClassModel[]>;
  selectedCountryList: CountryModel[];
  countryList: CountryModel[];
  legTypeList: TripLegTypeModel[];
  selectedLegTypeList: TripLegTypeModel[];
  selectedClientCountText: string = "";
  selectedClientList: CustomerModel[] = [];
  customerGUIDList: string = "";
  selectedAircraftCountText: string = "";
  selectedAirportCountText: string = "";
  selectedPersonCountText: string = "";
  selectedVendorCountText: string = "";
  selectedCountryCountText: string = "";
  selectedLegTypeCountText: string = "";
  selectedServicesCountText: string = "";
  selectedAprrovalStatusCountText: string = "";
  selectedExclusionCountText: string = "";
  excludeFromHomebase: boolean = false;
  avoidLocation: boolean = false;
  includeDisabled: boolean = false;
  includeExpired: boolean = false;
  filterPending: boolean = false;
  serviceTypeList: ServiceTypeModel[];
  serviceClassList: ServiceClassModel[];
  selectedServiceTypeList: ServiceTypeModel[];
  selectedServiceClassList: ServiceClassModel[];
  approvalStatusList: BusinessRulesApprovalStatusModel[];
  selectedApprovalStatusList: BusinessRulesApprovalStatusModel[];
  sortedData: BusinessRulesModel[];
  @ViewChildren('tripClientRefs', { read: ViewContainerRef }) tripClientRefs: QueryList<ViewContainerRef>;
  @ViewChildren('tripAircraftRefs', { read: ViewContainerRef }) tripAircraftRefs: QueryList<ViewContainerRef>;
  @ViewChildren('tripAirportRefs', { read: ViewContainerRef }) tripAirportRefs: QueryList<ViewContainerRef>;
  @ViewChildren('tripVendorRefs', { read: ViewContainerRef }) tripVendorRefs: QueryList<ViewContainerRef>;
  @ViewChildren('tripPersonRefs', { read: ViewContainerRef }) tripPersonRefs: QueryList<ViewContainerRef>;
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;


  displayedColumn: string[] = ['brID2', 'status', 'clientName', 'registration', 'mtow', 'crew', 'pax', 'farType', 'effectiveDate', 'expirationDate',
    'timeFrame', 'isUTC', 'daysOfWeek', 'country', 'airport', 'legType', 'avoid', /*'serviceClass',*/ 'serviceType', 'taskStatus', 'vendor', 'action2'];
  displayedColumn2: string[] = ['brID', 'businessRule', 'period', 'interest', 'services', 'action'];

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _dialogService: DialogService, private readonly _businessRulesService: BusinessRulesService
  ) {

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.bRule') != 'true') {
      this.upbRule = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    else {
      this.upbRule = true;
    }

   // this.showSpin = true;
    this.errMsg = "";
    this.errMsg1 = "";
    this.msg = "";
    this.customerList = [];
    this.totalRecordsText = "";
    this.orgCustomerList = [];
    this.orgAircraftList = [];
    this.customerList = [];
    this.selectedCustomerList = [];
    this.aircraftList = [];
    this.selectedAircraftList = [];
    this.airportList = [];
    this.selectedAirportList = [];
    this.pilotList = [];
    this.selectedPilotList = [];
    this.crewList = [];
    this.selectedCrewList = [];
    this.paxList = [];
    this.selectedPaxList = [];
    this.person_role = "crew";
    this.orgVendorList = [];
    this.vendorList = [];
    this.selectedVendorList = [];
    this.countryList = [];
    this.selectedCountryList = [];
    this.legTypeList = [];
    this.selectedLegTypeList = [];
    this.serviceTypeList = [];
    this.serviceClassList = [];
    this.selectedServiceTypeList = [];
    this.selectedServiceClassList = [];
    this.approvalStatusList = [];
    this.selectedApprovalStatusList = [];
    this.sortedData = [];
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate());
    this.initControls();
   // this.getData(true);


  }

  initControls() {
    this.manage_business_rules_form = this._formBuilder.group({
      clientSearch: [''],
      person_role: this.person_role,
      person_search_keyword: [''],
      vendor_search: [''],
      aircraftSearch: '',
      startDate: [this.startDate],
      endDate: [null],
      sort_by: [this.sort_by],
      airportSearch: '',
      country_search: '',
      service_search: '',
      service: 'service_class',
      brID:''
    });

    //this.checkGSTypeArray = new FormControl(this.selectedGSTypeList); // for mat-select


  }

  get f() { return this.manage_business_rules_form.controls; }

  dateOnchange(dateType: string, e: any) {
    if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidStartDate = false;
          this.f.startDate.setErrors({ isValidDate: false });
        }
        else {
          this.isValidStartDate = true;
        }
      }
      else {
        this.isValidStartDate = true;
      }
    }

    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidEndDate = false;
          this.f.endDate.setErrors({ isValidDate: false })
        }
        else {
          this.isValidEndDate = true;
        }
      }
      else {
        this.isValidEndDate = true;
      }
    }

    this.clickSubmit();

  }

  sortByChange(e: any) {
    this.clickSubmit();
  }
  // client
  clickClientFilter() {
    this.getClientList();
  }

  getClientList() {
    this.showClientList = true;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showCountryList = false;
    this.showLegTypeList = false;
    this.showServiceList = false;
    this.showStatusList = false;
    this.showExclusionList = false;
    this.clientList = [];
    let request = new BusinessRulesModel();
    request.effectiveDate = this.f.startDate.value;
    request.expirationDate = this.f.endDate.value;
    
    this._businessRulesService.getBusinessRulesClientList<ResponseModel<CustomerModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {          
          let c = new CustomerModel();
          response.model.forEach(x => {
            c = new CustomerModel();
            c.customerName = x.customerName
            c.customerGUID = x.customerGUID;
            c.selected = false;
            if (this.selectedClientList.length > 0) {
              let f: CustomerModel[];
              f = this.selectedClientList.filter(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
              if (f.length > 0) {
                c.selected = true;
              }
            }
            this.clientList.push(c);
          });
          this.clientList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.customerName.localeCompare(b.customerName));
          c = new CustomerModel();
          c.customerName = "Select All"
          c.customerGUID = "";
          c.selected = false;
          this.clientList.unshift(c);
          this.filteredClientList = this.f.clientSearch.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.customerName)),
            map(customerName => (customerName ? this._filterCustomer(customerName) : this.clientList.slice())),
          );
          this.setClientList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameCustomer(customer: CustomerModel): string {
    return customer && customer.customerName ? customer.customerName : '';
  }

  private _filterCustomer(name: string): CustomerModel[] {
    const filterValue = name.toLowerCase();

    return this.clientList.filter(option => option.customerName.toLowerCase().includes(filterValue));
  }

  checkClientChange(e: any, item: CustomerModel) {
    if (item.customerGUID == 'all') {
      this.clientList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.clientList.forEach(x => {
        if (item.customerGUID == x.customerGUID) {
          x.selected = e.checked;
        }
      });
    }
    this.setClientList();
  }

  setClientList() {
    this.selectedClientList = this.clientList.filter(u => u.selected == true);
    this.selectedClientCountText = "";
    if (this.clientList.length > 0) {
      if (this.selectedClientList.length == this.clientList.length) {
        this.selectedClientCountText = ": All";
      }
      else {
        if (this.selectedClientList.length > 0)
          this.selectedClientCountText = ": " + this.selectedClientList.length.toString();
      }
    }
  }

  clickDoneClient() {
    this.showClientList = false;
    this.clickSubmit();
  }

  clickResetClient() {
    this.clientList.forEach(x => {
      x.selected = false;
    });
    this.f.clientSearch.setValue("");
    this.setClientList();
    this.clickSubmit();
  }

  // client end

  // aircraft
  clickAircraftFilter() {
    this.getAircraftList();
  }

  getAircraftList() {
    this.showAircraftList = true;
    this.showClientList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showCountryList = false;
    this.showLegTypeList = false
    this.showServiceList = false;
    this.showStatusList = false;
    this.showExclusionList = false;
    this.aircraftList = [];
    let request = new BusinessRulesModel();
    request.effectiveDate = this.f.startDate.value;
    this._businessRulesService.getBusinessRulesAircraftList<ResponseModel<AircraftTripModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new AircraftModel();        
          response.model.forEach(x => {
            a = new AircraftModel();
            a.registration = x.registration
            a.aircraftGuid = x.aircraftGUID;
            a.selected = false;
            if (this.selectedAircraftList.length > 0) {
              let f: AircraftModel[];
              f = this.selectedAircraftList.filter(y => y.aircraftGuid.toLowerCase() == x.aircraftGUID.toLowerCase());
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.aircraftList.push(a);
          });
          this.aircraftList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.registration.localeCompare(b.registration));
          a = new AircraftModel();
          a.registration = "Select All";
          a.aircraftGuid = "all";
          a.selected = false;
          this.aircraftList.unshift(a);
          this.filteredAircraftList = this.f.aircraftSearch.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.aircraftName)),
            map(aircraftName => (aircraftName ? this._filterAircraft(aircraftName) : this.aircraftList.slice())),
          );
          this.setAircraftList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameAircraft(aircraft: AircraftModel): string {
    return aircraft && aircraft.registration ? aircraft.registration : '';
  }

  private _filterAircraft(name: string): AircraftModel[] {
    const filterValue = name.toLowerCase();

    return this.aircraftList.filter(option => option.registration.toLowerCase().includes(filterValue));
  }

  checkAircraftChange(e: any, item: AircraftModel) {
    if (item.aircraftGuid == 'all') {
      this.aircraftList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.aircraftList.forEach(x => {
        if (item.aircraftGuid == x.aircraftGuid) {
          x.selected = e.checked;
        }
      });
    }
    this.setAircraftList();
  }

  setAircraftList() {
    this.selectedAircraftList = this.aircraftList.filter(u => u.selected == true);
    this.selectedAircraftCountText = "";
    if (this.aircraftList.length > 0) {
      if (this.selectedAircraftList.length == this.aircraftList.length) {
        this.selectedAircraftCountText = ": All";
      }
      else {
        if (this.selectedAircraftList.length > 0)
          this.selectedAircraftCountText = ": " + this.selectedAircraftList.length.toString();
      }
    }
  }

  clickDoneAircraft() {
    this.showAircraftList = false;
    this.clickSubmit();
  }

  clickResetAircraft() {

    this.aircraftList.forEach(x => {
      x.selected = false;
    });
    this.f.aircraftSearch.setValue("");
    this.setAircraftList();
    this.clickSubmit();
  }

  // aircraft end

  // airport
  clickAirportFilter() {
    this.getAirportList();
  }

  getAirportList() {
    this.showAirportList = true;
    this.showClientList = false;
    this.showAircraftList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showCountryList = false;
    this.showLegTypeList = false;
    this.showServiceList = false;
    this.showStatusList = false;
    this.showExclusionList = false;
    this.airportList = [];
    let request = new BusinessRulesModel();
    request.effectiveDate = this.f.startDate.value;
    this._businessRulesService.getBusinessRulesAirportList<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new AirportModel();
        
          response.model.forEach(x => {
            a = new AirportModel();
            a.icao = x.icao;
            a.airportID = x.airportID;
            a.selected = false;
            if (this.selectedAirportList.length > 0) {
              let f: AirportModel[];
              f = this.selectedAirportList.filter(y => y.airportID == x.airportID);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.airportList.push(a);
          });
          this.airportList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.icao.localeCompare(b.icao));
          a = new AirportModel();
          a.airportID = -1;
          a.icao = "Select All";
          a.selected = false;
          this.airportList.unshift(a);
          this.filteredAirportList = this.f.airportSearch.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.airportName)),
            map(airportName => (airportName ? this._filterAirport(airportName) : this.airportList.slice())),
          );
          this.setAirportList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameAirport(airport: AirportModel): string {
    return airport && airport.icao ? airport.icao : '';
  }

  private _filterAirport(name: string): AirportModel[] {
    const filterValue = name.toLowerCase();

    return this.airportList.filter(option => option.icao.toLowerCase().includes(filterValue));
  }

  checkAirportChange(e: any, item: AirportModel) {
    if (item.airportID == -1) {
      this.airportList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.airportList.forEach(x => {
        if (item.airportID == x.airportID) {
          x.selected = e.checked;
        }
      });
    }

    this.setAirportList();
  }

  setAirportList() {
    this.selectedAirportList = this.airportList.filter(u => u.selected == true);
    this.selectedAirportCountText = "";
    if (this.airportList.length > 0) {
      if (this.selectedAirportList.length == this.airportList.length) {
        this.selectedAirportCountText = ": All";
      }
      else {
        if (this.selectedAirportList.length > 0)
          this.selectedAirportCountText = ": " + this.selectedAirportList.length.toString();
      }
    }
  }

  clickDoneAirport() {
    this.showAirportList = false;
    this.clickSubmit();
  }

  clickResetAirport() {
    this.airportList.forEach(x => {
      x.selected = false;
    });
    this.f.airportSearch.setValue("");
    this.setAirportList();
    this.clickSubmit();
  }
  // airport end

  // manifest
  clickManifestFilter() {
    this.getTripPersonList();
  }

  getTripPersonList() {
    var root = this;
    this.showClientList = false;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = true;
    this.showVendorList = false;
    this.showCountryList = false;
    this.showLegTypeList = false;
    this.showServiceList = false;
    this.showStatusList = false;
    this.showExclusionList = false;
    switch (this.f.person_role.value) {
      case "pilot":
        this.pilotList = [];
        break;
      case "crew":
        this.crewList = [];
        break;
      case "passenger":
        this.paxList = [];
        break;
    }

    let req = new BusinessRulesModel();
    req.effectiveDate = this.f.startDate.value;
    req.expirationDate = this.f.endDate.value;
    req.personRole = this.person_role;
    this._businessRulesService.getBusinessRulesPersonList<ResponseModel<GroundStopManifestModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        switch (this.f.person_role.value) {
          case "pilot":
            this.pilotList = [];
            break;
          case "crew":
            this.crewList = [];
            break;
          case "passenger":
            this.paxList = [];
            break;
        }
        if (response.model.length > 0) {
          let p = new GroundStopManifestModel();
          p.personName = "Select All";
          p.personGUID = "all";
          p.selected = false;
          p.personType = this.person_role;
          switch (this.f.person_role.value) {
            case "pilot":
              this.pilotList.push(p);
              break;
            case "crew":
              this.crewList.push(p);
              break;
            case "passenger":
              this.paxList.push(p);
              break;
          }

          response.model.forEach(x => {
            p = new GroundStopManifestModel();
            p.personGUID = x.personGUID;
            p.personName = x.personName;
            p.selected = false;
            p.personType = this.person_role;
            switch (this.person_role) {
              case "pilot":
                if (this.selectedPilotList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedPilotList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.pilotList.push(p);

                break;
              case "crew":
                if (this.selectedCrewList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedCrewList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.crewList.push(p);
                break;
              case "passenger":
                if (this.selectedPaxList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedPaxList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.paxList.push(p);
                break;
            }

          });
          let i: number;
          switch (this.person_role) {
            case "pilot":
              if (this.selectedPilotList.length > 0) {
                this.selectedPilotList.forEach(x => {
                  let n: number;
                  n = this.pilotList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (n == -1) {
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.pilotList.push(p);
                  }
                });
              }
              this.setPersonList();
              if (this.selectedPilotList.length > 0 && this.selectedPilotList.length != this.pilotList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.pilotList;
                this.pilotList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.pilotList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.pilotList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.pilotList.push(x);
                  }
                });
              }
              //i = this.pilotList.findIndex(x => x.selected == true);
              //if (i != -1) {
              //  setTimeout(function () {
              //    var selectedItem = root.tripPersonRefs.find(r => r.element.nativeElement.getAttribute('personGUID').toLowerCase() === root.pilotList[i].personGUID.toLowerCase());
              //    selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
              //  }, 500);
              //}
              break;
            case "crew":
              if (this.selectedCrewList.length > 0) {
                this.selectedCrewList.forEach(x => {
                  let n: number;
                  n = this.crewList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (n == -1) {
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.crewList.push(p);
                  }
                });
              }
              this.setPersonList();
              if (this.selectedCrewList.length > 0 && this.selectedCrewList.length != this.crewList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.crewList;
                this.crewList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.crewList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.crewList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.crewList.push(x);
                  }
                });
              }
              //i = this.crewList.findIndex(x => x.selected == true);
              //if (i != -1) {
              //  setTimeout(function () {
              //    var selectedItem = root.tripPersonRefs.find(r => r.element.nativeElement.getAttribute('personGUID').toLowerCase() === root.crewList[i].personGUID.toLowerCase());
              //    selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
              //  }, 500);
              //}
              break;
            case "passenger":
              if (this.selectedPaxList.length > 0) {
                this.selectedPaxList.forEach(x => {
                  let n: number;
                  n = this.paxList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (n == -1) {
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.paxList.push(p);
                  }
                });
              }
              this.setPersonList();
              if (this.selectedPaxList.length > 0 && this.selectedPaxList.length != this.paxList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.paxList;
                this.paxList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.paxList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.paxList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.paxList.push(x);
                  }
                });
              }
              //i = this.paxList.findIndex(x => x.selected == true);
              //if (i != -1) {
              //  setTimeout(function () {
              //    var selectedItem = root.tripPersonRefs.find(r => r.element.nativeElement.getAttribute('personGUID').toLowerCase() === root.paxList[i].personGUID.toLowerCase());
              //    selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
              //  }, 500);
              //}
              break;
          }




        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
      //else {
      //  if (response.code == "401") {
      //    this._authService.signOut();
      //  }
      //  else {
      //    //this.errMsg = "Failed to get trips at this time. Please try later.";
      //  }
      //}

    })

  }

  personSearchKeywordChange(e: any) {
    this.getTripPersonList();
  }

  personRoleChange(e: any) {
    this.person_role = e.target.value;
    this.f.person_search_keyword.setValue('');
    this.selectedPilotList = [];
    this.selectedCrewList = [];
    this.selectedPaxList = [];

    this.selectedPersonCountText = "";
    this.getTripPersonList();
  }

  checkPersonChange(e: any, item: GroundStopManifestModel) {
    switch (this.person_role) {
      case "pilot":
        if (item.personGUID == 'all') {
          this.pilotList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.pilotList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();

        break;
      case "crew":
        if (item.personGUID == 'all') {
          this.crewList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.crewList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();
        break;
      case "passenger":
        if (item.personGUID == 'all') {
          this.paxList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.paxList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();
        break;
    }
    this.reloadChildPage = false;

  }

  setPersonList() {
    this.selectedPersonCountText = "";
    let n: number = 0;
    switch (this.person_role) {
      case "pilot":
        let excludeallplist: GroundStopManifestModel[]
        excludeallplist = this.pilotList.filter(x => x.personGUID != 'all' && x.selected == false);
        if (excludeallplist.length == 0) {
          this.pilotList.find(x => x.personGUID == 'all').selected = true;
        }
        else {
          this.pilotList.find(x => x.personGUID == 'all').selected = false;
        }
        this.selectedPilotList = this.pilotList.filter(u => u.selected == true);
        if (this.pilotList.length > 0) {
          if (this.selectedPilotList.length == this.pilotList.length && this.f.person_search_keyword.value == '') {
            this.selectedPersonCountText = ": Pilot All";
          }
          else {
            if (this.selectedPilotList.length > 0) {
              if (this.pilotList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedPilotList.length - 1;
              }
              else {
                n = this.selectedPilotList.length;
              }
              this.selectedPersonCountText = ": Pilot " + n.toString();
            }
          }
        }
        break;
      case "crew":
        let excludeallclist: GroundStopManifestModel[]
        excludeallclist = this.crewList.filter(x => x.personGUID != 'all' && x.selected == false);
        //if (excludeallclist.length == 0) {
        //  this.crewList.find(x => x.personGUID == 'all').selected = true;
        //}
        //else {
        //  this.crewList.find(x => x.personGUID == 'all').selected = false;
        //}
        this.selectedCrewList = this.crewList.filter(u => u.selected == true);
        if (this.crewList.length > 0) {
          if (this.selectedCrewList.length == this.crewList.length && this.f.person_search_keyword.value == '') {
            this.selectedPersonCountText = ": Crew All";
          }
          else {
            //if (this.selectedCrewList.length > 0)
            //  this.selectedPersonCountText = ": Crew " + this.selectedCrewList.length.toString();
            if (this.selectedCrewList.length > 0) {
              if (this.crewList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedCrewList.length - 1;
              }
              else {
                n = this.selectedCrewList.length;
              }
              this.selectedPersonCountText = ": Crew " + n.toString();
            }

          }
        }
        break;
      case "passenger":
        let excludeallpxlist: GroundStopManifestModel[]
        excludeallpxlist = this.paxList.filter(x => x.personGUID != 'all' && x.selected == false);
        //if (excludeallpxlist.length == 0) {
        //  this.paxList.find(x => x.personGUID == 'all').selected = true;
        //}
        //else {
        //  this.paxList.find(x => x.personGUID == 'all').selected = false;
        //}
        this.selectedPaxList = this.paxList.filter(u => u.selected == true);
        if (this.paxList.length > 0) {
          if (this.selectedPaxList.length == this.paxList.length && this.f.person_search_keyword.value == '') {
            this.selectedPersonCountText = ": Pax All";
          }
          else {
            //if (this.selectedPaxList.length > 0)
            //  this.selectedPersonCountText = ": Passenger " + this.selectedPaxList.length.toString();
            if (this.selectedPaxList.length > 0) {
              if (this.paxList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedPaxList.length - 1;
              }
              else {
                n = this.selectedPaxList.length;
              }
              this.selectedPersonCountText = ": Pax " + n.toString();
            }
          }
        }
        break;
    }

  }

  clickDonePerson() {
    this.showPersonList = false;
    this.clickSubmit();
  }

  clickResetPerson() {
    this.f.person_search_keyword.setValue('');
    switch (this.person_role) {
      case "pilot":
        this.selectedPilotList = [];
        //this.pilotList.forEach(x => {
        //  x.selected = false;
        //});
        //this.setPersonList();
        break;
      case "crew":
        this.selectedCrewList = [];
        //this.crewList.forEach(x => {
        //  x.selected = false;
        //});
        //this.setPersonList();
        break;
      case "passenger":
        this.selectedPaxList = [];
        //this.paxList.forEach(x => {
        //  x.selected = false;
        //});
        //this.setPersonList();
        break;
    }
    this.getTripPersonList();
    this.showPersonList = false;
    this.clickSubmit();
  }

  // manifest end

  // vendor
  clickVendorFilter() {
    this.getVendorList();
  }

  getVendorList() {
    this.showVendorList = true;
    this.showClientList = false;
    this.showAircraftList = false;
    this.showPersonList = false;
    this.showAirportList = false;
    this.showCountryList = false;
    this.showLegTypeList = false;
    this.showServiceList = false;
    this.showStatusList = false;
    this.showExclusionList = false;
    this.vendorList = [];
    let request = new BusinessRulesModel();
    request.effectiveDate = this.f.startDate.value;
    request.expirationDate = this.f.endDate.value;
    this._businessRulesService.getBusinessRulesVendorList<ResponseModel<VendorModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let v = new VendorModel();    
         
          response.model.forEach(x => {
            v = new VendorModel();
            v.vendorName = x.vendorName;
            v.vendorGUID = x.vendorGUID;
            v.selected = false;
            if (this.selectedVendorList.length > 0) {
              let f: VendorModel[];
              f = this.selectedVendorList.filter(y => y.vendorGUID == x.vendorGUID);
              if (f.length > 0) {
                v.selected = true;
              }
            }
            this.vendorList.push(v);
          });
          this.vendorList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.vendorName.localeCompare(b.vendorName));
          v = new VendorModel();
          v.vendorGUID = "all";
          v.vendorName = "Select All";
          v.selected = false;
          this.vendorList.unshift(v);
          this.filteredVendorList = this.f.vendor_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.vendorName)),
            map(vendorName => (vendorName ? this._filterVendor(vendorName) : this.vendorList.slice())),
          );
          this.setVendorList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameVendor(vendor: VendorModel): string {
    return vendor && vendor.vendorName ? vendor.vendorName : '';
  }

  private _filterVendor(name: string): VendorModel[] {
    const filterValue = name.toLowerCase();

    return this.vendorList.filter(option => option.vendorName.toLowerCase().includes(filterValue));
  }

  checkVendorChange(e: any, item: VendorModel) {
    if (item.vendorGUID == 'all') {
      this.vendorList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.vendorList.forEach(x => {
        if (item.vendorGUID == x.vendorGUID) {
          x.selected = e.checked;
        }
      });
    }
    this.setVendorList();
  }

  setVendorList() {
    this.selectedVendorList = this.vendorList.filter(u => u.selected == true);
    this.selectedVendorCountText = "";
    if (this.vendorList.length > 0) {
      if (this.selectedVendorList.length == this.vendorList.length) {
        this.selectedVendorCountText = ": All";
      }
      else {
        if (this.selectedVendorList.length > 0)
          this.selectedVendorCountText = ": " + this.selectedVendorList.length.toString();
      }
    }
  }

  clickDoneVendor() {
    this.showVendorList = false;
    this.clickSubmit();
  }

  clickResetVendor() {
    this.vendorList.forEach(x => {
      x.selected = false;
    });
    this.f.vendor_search.setValue("");
    this.setVendorList();
    this.clickSubmit();
  }

  // vendor end

  //country
  clickCountryFilter() {
    this.getCountryList();
  }

  getCountryList() {
    this.showCountryList = true;
    this.showClientList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showAircraftList = false;
    this.showLegTypeList = false;
    this.showServiceList = false;
    this.showStatusList = false;
    this.showExclusionList = false;
    this.countryList = [];
    let request = new BusinessRulesModel();
    request.effectiveDate = this.f.startDate.value;
    request.expirationDate = this.f.endDate.value;
    this._businessRulesService.getBusinessRulesCountryList<ResponseModel<CountryModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let c = new CountryModel();
          
          response.model.forEach(x => {
            c = new CountryModel();
            c.countryName = x.countryName
            c.countryGUID = x.countryGUID;
            c.selected = false;
            if (this.selectedCountryList.length > 0) {
              let f: CountryModel[];
              f = this.selectedCountryList.filter(y => y.countryGUID.toLowerCase() == x.countryGUID.toLowerCase());
              if (f.length > 0) {
                c.selected = true;
              }
            }
            this.countryList.push(c);
          });
          this.countryList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.countryName.localeCompare(b.countryName));
          c = new CountryModel();
          c.countryGUID = "all";
          c.countryName = "Select All";
          c.selected = false;
          this.countryList.unshift(c);
          this.filteredCountryList = this.f.country_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.countryName)),
            map(countryName => (countryName ? this._filterCountry(countryName) : this.countryList.slice())),
          );
          this.setCountryList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }


  displayNameCountry(country: CountryModel): string {
    return country && country.countryName ? country.countryName : '';
  }

  private _filterCountry(name: string): CountryModel[] {
    const filterValue = name.toLowerCase();

    return this.countryList.filter(option => option.countryName.toLowerCase().includes(filterValue));
  }

  checkCountryChange(e: any, item: CountryModel) {
    if (item.countryGUID == 'all') {
      this.countryList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.countryList.forEach(x => {
        if (item.countryGUID == x.countryGUID) {
          x.selected = e.checked;
        }
      });
    }
    this.setCountryList();
  }

  setCountryList() {
    this.selectedCountryList = this.countryList.filter(u => u.selected == true);
    this.selectedCountryCountText = "";
    if (this.countryList.length > 0) {
      if (this.selectedCountryList.length == this.countryList.length) {
        this.selectedCountryCountText = ": All";
      }
      else {
        if (this.selectedCountryList.length > 0)
          this.selectedCountryCountText = ": " + this.selectedCountryList.length.toString();
      }
    }
  }

  clickDoneCountry() {
    this.showCountryList = false;
    this.clickSubmit();
  }

  clickResetCountry() {
    this.countryList.forEach(x => {
      x.selected = false;
    });
    this.f.countrySearch.setValue("");
    this.setCountryList();
    this.clickSubmit();
  }
  //country end


  //status
  clickStatusFilter() {
    this.getStatusList();
  }

  clickExclusionFilter() {
    this.showLegTypeList = false;
    this.showClientList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showAircraftList = false;
    this.showCountryList = false;
    this.showServiceList = false;
    this.showStatusList = false;
    this.showExclusionList = true;
  }

  clickDoneExclusions() {
    this.showExclusionList = false;
    this.clickSubmit();
  }

  clickResetExclusions() {
    this.excludeFromHomebase = false;
    this.avoidLocation = false;
    this.f.brID.setValue(null);
    this.selectedExclusionCountText = "";
    this.clickSubmit();
  }

  setExclusionText() {
    this.selectedExclusionCountText = "";
    let exclusionCount = 0;
    if (this.excludeFromHomebase)
      exclusionCount += 1;
    if (this.avoidLocation)
      exclusionCount += 1;
    if (this.f.brID.value != null && this.f.brID.value != "")
      exclusionCount += 1;
    if (exclusionCount == 3)
      this.selectedExclusionCountText = ": All";
    else
      if (exclusionCount > 0)
        this.selectedExclusionCountText = ": " + exclusionCount.toString();


  }

  getStatusList(isInit:boolean=false) {
    this.showLegTypeList = false;
    this.showClientList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showAircraftList = false;
    this.showCountryList = false;      
    this.showServiceList = false;
    this.showExclusionList = false;
    if (!isInit)
      this.showStatusList = true;

    this.approvalStatusList = [];
    let request = new BusinessRulesModel();
    request.effectiveDate = this.f.startDate.value;
    request.expirationDate = this.f.endDate.value;
    this._businessRulesService.getBusinessRulesStatusList<ResponseModel<BusinessRulesApprovalStatusModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let s = new BusinessRulesApprovalStatusModel();                    
          response.model.forEach(x => {
            s = new BusinessRulesApprovalStatusModel();
            s.businessRulesApprovalStatusDescription = x.businessRulesApprovalStatusDescription
            s.businessRulesApprovalStatusID = x.businessRulesApprovalStatusID;
            s.selected = false;
            if (this.selectedApprovalStatusList.length > 0) {
              let f: BusinessRulesApprovalStatusModel[];
              f = this.selectedApprovalStatusList.filter(y => y.businessRulesApprovalStatusID == x.businessRulesApprovalStatusID);
              if (f.length > 0) {
                s.selected = true;
              }
            }
            this.approvalStatusList.push(s);
          });
          this.approvalStatusList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.businessRulesApprovalStatusDescription.localeCompare(b.businessRulesApprovalStatusDescription));
          s = new BusinessRulesApprovalStatusModel();
          s.businessRulesApprovalStatusID = -1;
          s.businessRulesApprovalStatusDescription = "Select All";
          s.selected = false;
          this.approvalStatusList.unshift(s);
          this.setStatusList(isInit);
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    });
  }

  checkStatusChange(e: any, item: BusinessRulesApprovalStatusModel) {
    if (item.businessRulesApprovalStatusID == -1) {
      this.approvalStatusList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.approvalStatusList.forEach(x => {
        if (item.businessRulesApprovalStatusID == x.businessRulesApprovalStatusID) {
          x.selected = e.checked;
        }
      });
    }
    this.setStatusList();
  }

  setStatusList(isInit: boolean = false) {
    if (isInit) {
      if (this.approvalStatusList.some(u => u.businessRulesApprovalStatusID == 1))
        this.approvalStatusList.find(u => u.businessRulesApprovalStatusID == 1).selected = true;
      if (this.approvalStatusList.some(u => u.businessRulesApprovalStatusID == 2))
        this.approvalStatusList.find(u => u.businessRulesApprovalStatusID == 2).selected = true;
    }
    this.selectedApprovalStatusList = this.approvalStatusList.filter(u => u.selected == true);
    this.selectedAprrovalStatusCountText = "";
    if (this.approvalStatusList.length > 0) {
      if (this.selectedApprovalStatusList.length == this.approvalStatusList.length) {
        this.selectedAprrovalStatusCountText = ": All";
      }
      else {
        if (this.selectedApprovalStatusList.length > 0)
          this.selectedAprrovalStatusCountText = ": " + this.selectedApprovalStatusList.length.toString();
      }
    }
  }

  clickDoneStatus() {
    this.showStatusList = false;
    this.clickSubmit();
  }

  clickResetStatus() {
    this.approvalStatusList.forEach(x => {
      x.selected = false;
    });
    this.setStatusList();
    this.clickSubmit();
  }

  //status end

  //service
  clickServiceFilter() {
    this.getServiceList();
  }

  getServiceList() {
    this.showServiceList = true;
    this.showClientList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showAircraftList = false;
    this.showLegTypeList = false;    
    this.showStatusList = false;
    this.showExclusionList = false;
    this.serviceTypeList = [];
    this.serviceClassList = [];
    let request = new BusinessRulesModel();
    request.effectiveDate = this.f.startDate.value;
    request.expirationDate = this.f.endDate.value;
    this._businessRulesService.getBusinessRulesServiceList<ResponseModel<ServiceTypeModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let s = new ServiceTypeModel();
          response.model.forEach(x => {
            s = new ServiceTypeModel();            
            s.serviceTypeDescription = x.serviceTypeDescription;
            s.serviceTypeID = x.serviceTypeID;
            s.serviceClassID = x.serviceClassID;
            s.serviceClassDescription = x.serviceClassDescription;
            s.selected = false;
            if (this.selectedServiceTypeList.length > 0) {
              let f: ServiceTypeModel[];
              f = this.selectedServiceTypeList.filter(y => y.serviceTypeID == x.serviceTypeID);
              if (f.length > 0) {
                s.selected = true;
              }
            }
            this.serviceTypeList.push(s);
          });
          let y: ServiceClassModel;
          let tempClassID = -2;
          this.serviceTypeList.sort(function (a, b) {
            if (a.serviceClassDescription < b.serviceClassDescription) { return -1; }
            if (a.serviceClassDescription > b.serviceClassDescription) { return 1; }
            return 0
          });
          this.serviceTypeList.forEach((x) => {
            if (tempClassID != x.serviceClassID) {              
              tempClassID = x.serviceClassID;
              y = new ServiceClassModel();
              y.serviceClassID = x.serviceClassID;
              y.serviceClassDescription = x.serviceClassDescription;
              y.serviceTypeList = this.serviceTypeList.filter(a => a.serviceClassID == tempClassID);
              y.selected = false;
              y.serviceTypeList.forEach(x => x.quantity = 1);
              if (this.selectedServiceClassList.length > 0) {
                let v: ServiceClassModel[];
                v = this.selectedServiceClassList.filter(y => y.serviceClassID == x.serviceClassID);
                if (v.length > 0) {
                  y.selected = true;
                }
              }
              this.serviceClassList.push(y);
            }

          });

          this.serviceTypeList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.serviceTypeDescription.localeCompare(b.serviceTypeDescription));
          s = new ServiceTypeModel();
          s.serviceTypeID = -1;
          s.serviceTypeDescription = 'Select All';
          s.serviceClassID = -1;
          s.serviceClassDescription = 'Select All';
          s.selected = false;
          this.serviceTypeList.unshift(s);

          this.serviceClassList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.serviceClassDescription.localeCompare(b.serviceClassDescription));
          let c = new ServiceClassModel();
          c.serviceClassID = -1;
          c.serviceClassDescription = 'Select All';
          c.selected = false;
          this.serviceClassList.unshift(c);

          this.filteredServiceList = this.f.service_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.serviceName)),
            map(serviceName => (serviceName ? this._filterService(serviceName) : this.serviceTypeList.slice())),
          );
          this.filteredServiceClassList = this.f.service_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.serviceName)),
            map(serviceName => (serviceName ? this._filterServiceClass(serviceName) : this.serviceClassList.slice())),
          );
          this.setServiceList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameService(service: any, service_type: string) {
    if (service_type == 'service_type')
      this.displayNameSerivceType(service);
    else
      this.displayNameSerivceClass(service);

  }
  displayNameSerivceType(service: ServiceTypeModel): string {
    return service && service.serviceTypeDescription ? service.serviceTypeDescription : '';
  }

  private _filterService(name: string): ServiceTypeModel[] {
    const filterValue = name.toLowerCase();

    return this.serviceTypeList.filter(option => option.serviceTypeDescription.toLowerCase().includes(filterValue));
  }


  displayNameSerivceClass(service: ServiceClassModel): string {
    return service && service.serviceClassDescription ? service.serviceClassDescription : '';
  }

  private _filterServiceClass(name: string): ServiceClassModel[] {
    const filterValue = name.toLowerCase();

    return this.serviceClassList.filter(option => option.serviceClassDescription.toLowerCase().includes(filterValue));
  }

  checkServiceChange(e: any, item: ServiceTypeModel) {
    if (item.serviceTypeID == -1) {
      this.serviceTypeList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.serviceTypeList.forEach(x => {
        if (item.serviceTypeID == x.serviceTypeID) {
          x.selected = e.checked;
        }
      });
    }
    if (item.serviceClassID == -1) {
      this.serviceClassList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.serviceClassList.forEach(x => {
        if (item.serviceClassID == x.serviceClassID) {
          x.selected = e.checked;
        }
      });
    }
    this.setServiceList();
  }

  setServiceList() {
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
    this.selectedServicesCountText = "";
    if (this.serviceTypeList.length > 0) {
      if (this.selectedServiceTypeList.length == this.serviceTypeList.length) {
        this.selectedServicesCountText = ": All";
      }
      else {
        if (this.selectedServiceTypeList.length > 0)
          this.selectedServicesCountText = ": " + this.selectedServiceTypeList.length.toString();
      }
    }

    this.selectedServiceClassList = this.serviceClassList.filter(u => u.selected == true);
    this.selectedServicesCountText = "";
    if (this.serviceClassList.length > 0) {
      if (this.selectedServiceClassList.length == this.serviceClassList.length) {
        this.selectedServicesCountText = ": All";
      }
      else {
        if (this.selectedServiceClassList.length > 0)
          this.selectedServicesCountText = ": " + this.selectedServiceClassList.length.toString();
      }
    }
  }

  clickDoneService() {
    this.showServiceList = false;
    this.clickSubmit();
  }

  clickResetService() {
    this.serviceTypeList.forEach(x => {
      x.selected = false;
    });
    this.f.service_search.setValue("");
    this.setServiceList();
    this.clickSubmit();
  }

  serviceTypeChange(e: any) {
    this.service = e.target.value;
    this.f.service_search.setValue('');
    this.selectedServiceTypeList = [];
    this.selectedServiceClassList = [];
    
    this.selectedServicesCountText = "";
    this.getServiceList();
  }

//service end
  //leg type
  clickLegTypeFilter() {
    this.getLegTypeList();
  }

  getLegTypeList() {
    this.showLegTypeList = true;
    this.showClientList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showAircraftList = false;
    this.showCountryList = false;
    this.showStatusList = false;
    this.showServiceList = false;
    this.showExclusionList = false;
    this.legTypeList = [];
    let request = new BusinessRulesModel();
    request.effectiveDate = this.f.startDate.value;
    request.expirationDate = this.f.endDate.value;
    this._businessRulesService.getBusinessRulesLegTypeList<ResponseModel<TripLegTypeModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let t = new TripLegTypeModel();                   
          response.model.forEach(x => {
            t = new TripLegTypeModel();
            t.tripLegTypeDescription = x.tripLegTypeDescription
            t.tripLegTypeID = x.tripLegTypeID;
            t.selected = false;
            if (this.selectedLegTypeList.length > 0) {
              let f: TripLegTypeModel[];
              f = this.selectedLegTypeList.filter(y => y.tripLegTypeID == x.tripLegTypeID);
              if (f.length > 0) {
                t.selected = true;
              }
            }
            this.legTypeList.push(t);
          });
          this.legTypeList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.tripLegTypeDescription.localeCompare(b.tripLegTypeDescription));

          t = new TripLegTypeModel();
          t.tripLegTypeID = -1;
          t.tripLegTypeDescription = "Select All";
          t.selected = false;
          this.legTypeList.unshift(t);
          this.setLegTypeList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  checkLegTypeChange(e: any, item: TripLegTypeModel) {
    if (item.tripLegTypeID == -1) {
      this.legTypeList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.legTypeList.forEach(x => {
        if (item.tripLegTypeID == x.tripLegTypeID) {
          x.selected = e.checked;
        }
      });
    }
    this.setLegTypeList();
  }

  setLegTypeList() {
    this.selectedLegTypeList = this.legTypeList.filter(u => u.selected == true);
    this.selectedLegTypeCountText = "";
    if (this.legTypeList.length > 0) {
      if (this.selectedLegTypeList.length == this.legTypeList.length) {
        this.selectedLegTypeCountText = ": All";
      }
      else {
        if (this.selectedLegTypeList.length > 0)
          this.selectedLegTypeCountText = ": " + this.selectedLegTypeList.length.toString();
      }
    }
  }

  clickDoneLegType() {
    this.showLegTypeList = false;
    this.clickSubmit();
  }

  clickResetLegType() {
    this.legTypeList.forEach(x => {
      x.selected = false;
    });
    this.setLegTypeList();
    this.clickSubmit();
  }
//leg type end

  clickSubmit() {
    this.submitted = true;
    this.errMsg = "";
    if (this.manage_business_rules_form.invalid || this.errMsg != "") {
      return;
    }

    if (this.showClientList) {
      this.getClientList();
    }
    if (this.showAircraftList) {
      this.getAircraftList();
    }
    if (this.showAirportList) {
      this.getAirportList();
    }
    if (this.showPersonList) {
      this.getTripPersonList();
    }
    if (this.showVendorList) {
      this.getVendorList();
    }

    this.getData();
  }

  getData(isInit: boolean = false) {
    this.showSpin = true;
    let request = new BusinessRulesModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    if (this.selectedClientList.length == this.orgCustomerList.length) {
      request.selectedCustomerGUIDList = "";
    }
    else {
      this.selectedCustomerList = this.selectedClientList.filter(x => x.customerGUID != 'all');
      request.selectedCustomerGUIDList = Array.prototype.map.call(this.selectedCustomerList, s => s.customerGUID).toString();
    }
    if (this.selectedAircraftList.length == this.aircraftList.length) {
      request.selectedAircraftGUIDList = "";
    }
    else {
      this.selectedAircraftList = this.selectedAircraftList.filter(x => x.aircraftGuid != 'all');
    request.selectedAircraftGUIDList = Array.prototype.map.call(this.selectedAircraftList, s => s.aircraftGuid).toString();
    }
    //if (this.selectedAirportList.length == this.airportList.length) {
    //  request.selectedICAOList = "";
    //}
    //else {
    request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    //}

    request.personRole = this.person_role;
    switch (this.person_role) {
      case "pilot":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedPilotList.length == this.pilotList.length) {
            request.selectedPersonGUIDList = "";
          }
          else {
            request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPilotList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedPilotList = this.selectedPilotList.filter(x => x.personGUID != 'all');
          request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPilotList, s => s.personGUID).toString();
        }
        break;
      case "crew":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedCrewList.length == this.crewList.length) {
            request.selectedPersonGUIDList = "";
          }
          else {
            request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedCrewList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedCrewList = this.selectedCrewList.filter(x => x.personGUID != 'all');
          request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedCrewList, s => s.personGUID).toString();
        }
        break;
      case "passenger":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedPaxList.length == this.paxList.length) {
            request.selectedPersonGUIDList = "";
          }
          else {
            request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPaxList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedPaxList = this.selectedPaxList.filter(x => x.personGUID != 'all');
          request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPaxList, s => s.personGUID).toString();
        }
        break;
    }
    if (this.selectedVendorList.length == this.orgVendorList.length) {
      request.selectedVendorGUIDList = "";
    }
    else {
      this.selectedVendorList = this.selectedVendorList.filter(x => x.vendorGUID != 'all');
      request.selectedVendorGUIDList = Array.prototype.map.call(this.selectedVendorList, s => s.vendorGUID).toString();
    }
    //if (this.selectedCountryList.length == this.orgVendorList.length) {
    //  request.selectedVendorGUIDList = "";
    //}
    //else {
    this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
    request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    //}

    //this.selectedLegTypeList = this.selectedLegTypeList.filter(x => x.tripLegTypeID != 'all');
    request.selectedLegTypeIDList = Array.prototype.map.call(this.selectedLegTypeList, s => s.tripLegTypeID).toString();
    if (isInit) {
      request.selectedApprovalStatusIDList = '1,2';
      this.getStatusList(true);
    }
    else
    request.selectedApprovalStatusIDList = Array.prototype.map.call(this.selectedApprovalStatusList, s => s.businessRulesApprovalStatusID).toString();
    request.selectedServiceClassIDList = Array.prototype.map.call(this.selectedServiceClassList, s => s.serviceClassID).toString();
    request.selectedServiceTypeIDList = Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
    request.isActive = this.includeDisabled;
    request.includeExpired = this.includeExpired;
    request.excludeFromHomebase = this.excludeFromHomebase;
    request.avoidLocation = this.avoidLocation;
    request.businessRulesID = 0;
    if (this.f.brID.value != null && this.f.brID.value != "")
      request.businessRulesID = Number(this.f.brID.value);
    if (this.paginator == undefined) {
      request.pageIndex = 0;
      request.pageSize = 25;
    }
    else {
      request.pageIndex = this.paginator.pageIndex;
      request.pageSize = this.paginator.pageSize;
    }
    this._businessRulesService.getBusinessRulesByConditions<ResponseModel<BusinessRulesModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.businessRulesList = response.model;
          if (this.businessRulesList != null) {
            this.sortedData = this.businessRulesList.slice();
          }
          this.totalRecords = this.businessRulesList[0].totalRecords;
          this.totalRecordsText = this.totalRecords + " total records"
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
      this.showSpin = false;
    });
  }

  clickEdit(event: any, br: BusinessRulesModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    let businessRulesID = 0;
    let s = "Add Business Rule";
    if (br != null) {
      businessRulesID = br.businessRulesID;
      s = "Edit Business Rule " + businessRulesID.toString();
    }
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { businessRulesID: businessRulesID, title: s };


    const dialogRef = this._dialog.open(BusinessRulesEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.sortedData.length == 0)
          this.f.brID.setValue(result);
        this.getData();
      }
    });
    //  let navigationExtras: NavigationExtras = {
    //    queryParams: {
    //      "businessRulesID": br.businessRulesID        

    //    }
    //  };
    //this._router.navigate(['/business-rules/business-rules-edit'], navigationExtras);
  }

  clickReset() {
    this.showLegTypeList = false;
    this.showClientList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showAircraftList = false;
    this.showCountryList = false;
    this.showStatusList = false;
    this.showServiceList = false;
    this.showExclusionList = false;

    this.selectedLegTypeList = [];
    this.selectedLegTypeCountText = "";
    this.selectedClientList = [];
    this.selectedClientCountText = "";
    this.selectedAircraftList = [];
    this.selectedAircraftCountText = "";
    this.selectedPaxList = [];
    this.selectedCrewList = [];
    this.selectedPersonCountText = "";
    this.selectedAirportList = [];
    this.selectedAirportCountText = "";
    this.selectedCountryList = [];
    this.selectedCountryCountText = "";
    this.selectedVendorList = [];
    this.selectedVendorCountText = "";
    this.selectedServiceClassList = [];
    this.selectedServiceTypeList = [];
    this.selectedServicesCountText = "";
    this.selectedApprovalStatusList = [];
    this.selectedAprrovalStatusCountText = "";
    this.f.startDate.setValue(null);
    this.f.endDate.setValue(null);
    this.businessRulesList = [];
    this.avoidLocation = false;
    this.excludeFromHomebase = false;
    this.f.brID.setValue(null);
    this.selectedExclusionCountText = "";
    this.sortedData = [];
    
    //this.clickSubmit();

  }

  sortData(sort: Sort) {
    const data = this.businessRulesList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {       
        case 'brID':
          return compare(a.businessRulesID, b.businessRulesID, isAsc);
        case 'status':
          return compare(a.approvalStatus, b.approvalStatus, isAsc);
        case 'clientName':
          return compare(a.customerName, b.customerName, isAsc);
        case 'registration':
          return compare(a.registrationList, b.registrationList, isAsc);
        case 'mtow':
          return compare(a.mtowMin, b.mtowMin, isAsc);       
        case 'farType':
          return compare(a.farTypeDescription, b.farTypeDescription, isAsc);
        case 'effectiveDate':
          return compare(new Date(a.effectiveDate), new Date(b.effectiveDate), isAsc);
        case 'expirationDate':
          return compare(new Date(a.expirationDate), new Date(b.expirationDate), isAsc);
        case 'country':
          return compare(a.countryName == null ? 'All' : a.countryName, b.countryName == null? 'All' : b.countryName, isAsc);
        case 'airport':
          return compare(a.icao == null ? 'All' : a.icao, b.icao == null ? 'All' : b.icao, isAsc);
        case 'legType':
          return compare(a.legType == null ? 'All' : a.legType, b.legType == null ? 'All' : b.legType, isAsc);
        case 'avoid':
          return compare(a.avoidLocation, b.avoidLocation, isAsc);
        case 'serviceClass':
          return compare(a.serviceClassDescription, b.serviceClassDescription, isAsc);
        case 'serviceType':
          return compare(a.serviceTypeDescription == null ? 'All' : a.serviceTypeDescription, b.serviceTypeDescription == null ? 'All' : b.serviceTypeDescription, isAsc);
        case 'taskStatus':
          return compare(a.taskStatusDescription == null ? 'All' : a.taskStatusDescription, b.taskStatusDescription == null ? 'All' : b.taskStatusDescription, isAsc);
        case 'vendor':
          return compare(a.vendorName == null ? 'All' : a.vendorName, b.vendorName == null ? 'All' : b.vendorName, isAsc);
        default:
          return 0;
      }
    });

    function compare(a: number | string | Date | boolean, b: number | string | Date | boolean, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }


  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    //this.currentPageIndex = e.pageIndex;
    //if (this.pageSize != e.pageSize) {
    //  this.totalRecords = 0;
    //  this.totalRecordsText = "";
    //  this.totalPageNumber = 0;
    //  this.pageSize = e.pageSize;
    //  this.request.pageSize = this.pageSize;
    //  this.request.totalRecords = this.totalRecords;
    //}
    //this.request.pageIndex = this.currentPageIndex;
    this.clickSubmit();

  }

}



