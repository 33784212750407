import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserModel } from '../models/user.model';
import { ResponseModel } from '../models/response.model';
import { IdentityService } from '../services/identity.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { UserPermissionPersonEditComponent } from './user-permission-person-edit.component';
import { UserPermissionRegistrationEditComponent } from './user-permission-registration-edit.component';
import { UserPermissionRegistrationEditDialogComponent } from './user-permission-registration-edit-dialog.component';
import { UserPermissionPersonEditDialogComponent } from './user-permission-person-edit-dialog.component';
import { UserPermissionRestrictClientEditDialogComponent } from './user-permission-restrict-client-edit-dialog.component';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-user-permission-client-edit-dialog',
  templateUrl: './user-permission-client-edit-dialog.component.html',
  styleUrls: ['./user-permission-client-edit-dialog.component.css']

})

export class UserPermissionClientEditDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(UserPermissionRegistrationEditDialogComponent) acPermission;
  @ViewChild(UserPermissionPersonEditDialogComponent) pPermission;
  @ViewChild(UserPermissionRestrictClientEditDialogComponent) rPermission;
  userId: string;
  customerGuid: string;
  tabName: string;
  userType: string;
  user_permission_client_edit_form: UntypedFormGroup;
  userList: UserModel[];
  user: UserModel;
  //isActive: boolean;
  displayedColumn: string[] = ['username', 'name', 'email'];
  admin_access_select: boolean;
  account_status_select: boolean;
  //personType: string;
  //@Output() tabChanged = new EventEmitter();
  ua: number;
  ut: string;
  childDataChanged: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  effectiveDate: Date;
  expirationDate: Date;
  effectiveDateText: string;
  expirationDateText: string;
  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;
  submittedEffectiverDate: boolean = false;
  submittedExpirationDate: boolean = false;
  isValidateEffectiveDate: boolean = true;
  isValidateExpirationDate: boolean = true;
  minDate: Date = new Date();
  locked: boolean = false;
  lockedDateTime: string = "";
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _route: ActivatedRoute, private readonly _identityService: IdentityService, private readonly _dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _dialogService: DialogService) {
    this.tabName = _data.tabName;
    this.customerGuid = _data.customerGuid;
    this.userId = _data.userId;
    this.userType = _data.userType;
  }

  getAllData(): Observable<any[]> {
    let request = new UserModel()
    request.userId = this.userId;
    request.customerGuid = this.customerGuid;
    request.personType = this.tabName;
    let getUserResponse = this._identityService.getUserWithAnyStatusByUserId<ResponseModel<UserModel>>(request);
    return forkJoin([getUserResponse]);

  }

  ngOnInit() {
    this.userList = [];
    this._authService.updateAccessTime();
    this.ua = 0;// Number(localStorage.getItem('ua'));
    this.ut = localStorage.getItem('ut').toLowerCase();
    if (localStorage.getItem('up.isA') != null && localStorage.getItem('up.isA') != "") {
      if (localStorage.getItem('up.isA').toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
        this.ua = 1;
      }
    }
    if (this.ua != 1) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");     
      return;
    }

   
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (responses[0].model != null && responses[0].model.username != "") {
            this.userList.push(responses[0].model);
            this.user = responses[0].model;
            this.account_status_select = this.user.isActive;
            if (this.user.isAB.toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
              this.user.isAdmin = true;
            }
            else {
              this.user.isAdmin = false;
            }
            this.admin_access_select = this.user.isAdmin;
            this.effectiveDate = null;
            this.effectiveDateText = "";
            if (this.user.effectiveDate != "") {
              this.effectiveDate = new Date(this.user.effectiveDate);
              this.effectiveDateText = this.user.effectiveDate;
            }
            this.expirationDate = null;
            this.expirationDateText = "";
            if (this.user.expirationDate != "") {
              this.expirationDate = new Date(this.user.expirationDate);
              this.expirationDateText = this.user.expirationDate;
            }
            this.locked = this.user.locked;
            if (this.user.lockedDateTime != null) {
              let d = new Date(this.user.lockedDateTime);
              let m = d.getMonth() + 1;
              let ms: string = "";
              if (d.getMinutes() < 10) {
                ms = "0" + d.getMinutes().toString();
              }
              else {
                ms = d.getMinutes().toString();
              }
              this.lockedDateTime = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + ms;
              //this.lockedDateTime = this.user.lockedDateTime;
            }
          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();
      this.checkFormControlValueChanged();
      //this.account_status_select = this.user.isActive;
      //this.admin_access_select = this.user.isAdmin;

    });
  }

  initControls() {

    this.user_permission_client_edit_form = this._formBuilder.group({
      //customer_select: null,
      account_status_select: [this.account_status_select],
      admin_access_select: [this.admin_access_select],
      effectiveDate: this.effectiveDate,
      expirationDate: [this.expirationDate],
      locked: [this.locked],
      lockedDateTime: [this.lockedDateTime]
    });

  }

  ngAfterViewInit() {

  }

  get f() { return this.user_permission_client_edit_form.controls; }


  checkFormControlValueChanged(): void {
    this.user_permission_client_edit_form.get("account_status_select").valueChanges.subscribe(x => {
      this.account_status_select = this.f.account_status_select.value;
      this.childDataChanged=true;
    });
    this.user_permission_client_edit_form.get("admin_access_select").valueChanges.subscribe(x => {
      this.admin_access_select = this.f.admin_access_select.value;
      this.childDataChanged=true;
    });
    this.user_permission_client_edit_form.get("effectiveDate").valueChanges.subscribe(x => {
      this.effectiveDate = this.f.effectiveDate.value;
      this.childDataChanged=true;
    });
    this.user_permission_client_edit_form.get("expirationDate").valueChanges.subscribe(x => {
      this.expirationDate = this.f.expirationDate.value;
      this.childDataChanged=true;
    });
    this.user_permission_client_edit_form.get("locked").valueChanges.subscribe(x => {
      this.locked = this.f.locked.value;
      this.childDataChanged = true;
    });
  }

  lockedOnChange(e: any) {
    this.locked = e.checked;
    //if (!this.locked) {
    //  this.f.lockedDateTime.setValue('');
    //}
  }

  admin_access_selectChange(e: any) {
    this.admin_access_select = this.f.admin_access_select.value;
  }

  account_status_selectChange(e: any) {
    this.account_status_select = this.f.account_status_select.value;
  }

  effectiveDateChange(e: any) {
    this.submittedEffectiverDate = true;
    this.effectiveDate = e.target.value;
    this.effectiveDateText = "";
    this.isValidateEffectiveDate = true;
    if (this.effectiveDate != null) {
      this.effectiveDateText = this.effectiveDateRef.nativeElement.value;
    }
    else {
      if (this.effectiveDateRef.nativeElement.value != "") {
        this.f.effectiveDate.setErrors({ isValidDate: false });
        this.isValidateEffectiveDate = false;
      }
      else {
        this.f.effectiveDate.setErrors(null);
      }
    }
  }

  expirationDateChange(e: any) {
    this.submittedExpirationDate = true;
    this.expirationDate = e.target.value;
    this.expirationDateText = "";
    this.isValidateExpirationDate = true;
    if (this.expirationDate != null) {
      this.expirationDateText = this.expirationDateRef.nativeElement.value;
    }
    else {
      if (this.expirationDateRef.nativeElement.value != "") {
        this.f.expirationDate.setErrors({ isValidDate: false });
        this.isValidateExpirationDate = false;
      }
      else {
        this.f.expirationDate.setErrors(null);
      }
    }
  }

  clickTab(s: string) {
    this._authService.updateAccessTime();
    if (!this.isValidateEffectiveDate || !this.isValidateExpirationDate) {
      return;
    }
    if (s != this.tabName) {
      if (this.childDataChanged) {
        this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before leave the page?");
        this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
          this.eventSubscription.unsubscribe();
          if (result == true) {           
            switch (this.tabName) {
              case "registration":
                this.acPermission.clickSave();
                break;
              case "pic":
                this.pPermission.clickSave();
                break;
              case "sic":
                this.pPermission.clickSave();
                break;
              case "pax":
                this.pPermission.clickSave();
                break;
              case "restrict":
                this.rPermission.clickSave();
                break;
            }
          }
          else {
            //this._dialogRef.close(this.isModified);
          }
          
          this.tabName = s;
          this.childDataChanged = false;
          
          
          
        });

      }
      else {
        this.tabName = s;
        this.childDataChanged = false;
        
      }
        
    }
  }

  onSendDataToParent(e: any) {
    
    this.childDataChanged = e.dataChanged;
  }
  //childTabChanged(e) {
  //  this.tabName = e.tabName;
  //}

}

