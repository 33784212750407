import { Component, Inject, OnInit } from '@angular/core';
import { FuelService } from '../services/fuel.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthenticateService } from '../services/authenticate.service';
import { ResponseModel } from '../models/response.model';
import { FuelQuoteSummary } from '../models/fuel-quote-summary';
import { TemplateService } from '../services/template.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { TripDocumentModel } from '../models/trip-document.model';
import { FuelQuoteSummaryHistoryComponent } from './fuel-quote-summary-history.component';

@Component({
  selector: 'app-fuel-quote-summary',
  templateUrl: './fuel-quote-summary.component.html',
  styleUrls: ['./fuel-quote-summary.component.css']
})
export class FuelQuoteSummaryComponent implements OnInit {

  tripCodeGUID: string;
  fuelQuoteList = new MatTableDataSource<FuelQuoteSummary>();
  isLoading: boolean = false;
  isLoadingReport: boolean = false;
  errMsg: string = "";
  displayedColumns: string[] = ['selected', 'airport', 'arrivalDate', 'departureDate', 'fuelSupplier', 'intoPlaneAgent', 'option'];
  selection = new SelectionModel<FuelQuoteSummary>(true, []);
  remarks: string = "";
  tripCode: string = "";
  constructor(
    private readonly _fuelService: FuelService,
    private readonly _dialogRef: MatDialogRef<FuelQuoteSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _dialog: MatDialog,
    private readonly _authService: AuthenticateService,
    private readonly _templateService: TemplateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService) {
    this.tripCodeGUID = _data.tripCodeGUID
    this.tripCode = _data.tripCode;
  }

  ngOnInit(): void {
    this._authService.updateAccessTime();
    this.getFuelQuotes();
  }

  getFuelQuotes() {
    this.isLoading = true;
    this._fuelService.getFuelQuoteBytID<ResponseModel<FuelQuoteSummary[]>>(this.tripCodeGUID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let fuel = response.model
          fuel.forEach(x => {
            if (x.fuelQuote!=null) {
              x.hasDomestic = Number(x.fuelQuote[0].totalPrice) != Number(x.fuelQuote[0].totalPriceDomestic) ? true : false;
              if (x.fuelQuote[0].taxesAndFeesXML != null) {
                var parseString = require('xml2js').parseString;
                var xml = x.fuelQuote[0].taxesAndFeesXML;
                let self = this;
                parseString(xml, function (err, result) {
                  x.taxes = result.TaxesAndFees.TaxFee;
                });
              }
              }
            });        
          this.fuelQuoteList.data = fuel;
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          else {
            this.errMsg = "An error has occurred retrieving fuel quotes."
          }
        }
      }
      this.isLoading = false;
    });
  }

  generateQuoteSummary() {
    this._authService.updateAccessTime();
    this.isLoadingReport = true;
    let reportID = this.tripCode + '-' + UtilityFunctions.makeID(4).toUpperCase();
    this._templateService.generateFuelQuoteSummary(this.selection.selected,this.remarks, reportID).subscribe(x => {
      if (x.remarks != "") {
        this.errMsg = x.remarks;
      }
      if (x.docBFile != "") {
        let request = new FuelQuoteSummary();
        request.tripCodeGUID = this.tripCodeGUID;
        request.includedAirports = Array.prototype.map.call(this.selection.selected, s => s.gsInfo.icao).toString();
        request.remarks = this.remarks;
        this._fuelService.insertFuelQuoteSummary<ResponseModel<number>>(request).subscribe(response => {
          if (response != null) {
            if (response.message == "" && response.code == "200") {
              let fuelQuoteSummaryID = response.model;
              if (fuelQuoteSummaryID != 0) {
                let tripDocument = new TripDocumentModel();
                tripDocument.documentName = "FuelQuoteSummary - " + request.includedAirports.replace(/,/g, "-") + " - " + reportID + ".pdf";
                tripDocument.fileExtension = "pdf";
                tripDocument.documentSizeInKB = x.docSize
                tripDocument.mimeType = "application/pdf";
                tripDocument.bFile = x.docBFile;
                tripDocument.TripCodeGUID = this.tripCodeGUID;
                tripDocument.tripCode = this.tripCode;
                tripDocument.fuelQuoteSummaryID = fuelQuoteSummaryID;
                tripDocument.reportID = reportID;
                this._groundStopAdvancedService.insertTripDocument<ResponseModel<number>>(tripDocument).subscribe(response => {
                  if (response != null) {
                    if (response.code == "200" && response.message == "") {

                    }
                  }
                });
              }

            }
          }
        });
      }
      this.isLoadingReport = false;
    });
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.fuelQuoteList.data.filter(x => x.fuelQuote != null).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.fuelQuoteList.data.filter(x => x.fuelQuote != null));
  }

  checkBox(row: any) {
    this.selection.toggle(row);

  }

  clickPreviewDocument(e: any, item: GroundStopTaskDocumentModel, id: string = "") {
    this._authService.updateAccessTime();
    let request = new GroundStopTaskDocumentModel();
    if (id != "")
      request.groundStopTaskDocumentGUID = id;
    else
      request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
    if (request.groundStopTaskDocumentGUID != "") {
      this._groundStopAdvancedService.getGroundStopTaskDocumentBygstdId<ResponseModel<GroundStopTaskDocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new GroundStopTaskDocumentModel();
            obj = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
            let file = new Blob([byteArray], { type: obj.mimeType });
            var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display:none");
            a.href = fileURL;
            a.target = "_blank";
            switch (obj.fileExtension) {
              case "doc":
              case "docx":
              case "xls":
              case "xlsx":
                a.download = obj.documentName;
            }
            a.click();
            document.body.removeChild(a);

          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
      let file = new Blob([byteArray], { type: item.mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

  }

  openSummaryHistory() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "55em";
    dialogConfig.panelClass = "custom-dialog-container";

    dialogConfig.data = {
      tripCodeGUID: this.tripCodeGUID,
      tripCode: this.tripCode
    };

    const dialogRef = this._dialog.open(FuelQuoteSummaryHistoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
      }
    });
  }

}
