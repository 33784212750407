import { Component, Inject, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { ApisCredentialsModel } from '../models/apis-credentials.model';
import { ApisAccountEditComponent } from './apis-account-edit.component';
import { Dialog } from '@angular/cdk/dialog';

@Component({
    selector: 'app-apis-account',
  templateUrl: './apis-account.component.html',
  styleUrls: ['./apis-account.component.css']
})
/** airport-details component*/
export class ApisAccountComponent implements OnInit {
  isLoading: boolean;
  errMessage: string = "";
  recordList: ApisCredentialsModel[];
  grantAccessToAPIS: boolean = false;
  displayedColumn: string[] = ['apisType', 'userName', 'password', 'effectiveDate','action'];
    /** airport-details ctor */
  constructor(private readonly _commonService: CommonService, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog) {
    

  }
    
  ngOnInit() {
    this.isLoading = true;
    this.grantAccessToAPIS = false;
    if (localStorage.getItem('up.apis') == 'true') {
      this.grantAccessToAPIS = true;
    }
    this._authService.updateAccessTime();
    
      this.getData();
  }

  getData() {
    this._commonService.getApisCredentials<ResponseModel<ApisCredentialsModel[]>>().subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.recordList = response.model;
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMessage = "An error has occured."
        }
      }
      this.isLoading = false;
    });
   

  }

  clickEdit(apis: ApisCredentialsModel) {
    let s = "";
    let apisCredentialsID = 0;
    if (apis == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      apisCredentialsID = apis.apisCredentialsID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass="custom-dialog-container"
    dialogConfig.data = { dialogTitle: s, apisCredentialsID: apisCredentialsID };
    const dialogRef = this._dialog.open(ApisAccountEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }

    });
  }
  

}
