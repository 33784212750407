
  <div style="background-color:#f8f8f8;height:100%">
    <div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-1em" >
      <div style="padding-left:1em;margin-top:0.3125em;"><h5>Apis Credentials</h5></div>
    </div>
    <div style="margin-top:0em">
      <form *ngIf="apis_account_edit_form" [formGroup]="apis_account_edit_form" (ngSubmit)="clickSave()">
        <div style="margin-left: .5em;margin-top:0em;margin-right:0.5em;">
          <div>
            <div class="form-group" style="margin-left: .5em;margin-top:0em;margin-right:0.625em;">

              <label for="note_type" style="font-size:small">Apis Type</label>
              <div style="margin-top:-0.5em;">
                <label for="note_type" style="font-size:small">
                  {{apisType}}</label>
                <!--  <select *ngIf="f" formControlName="apis_type" class="form-control form-control-sm dropdown">
    <option *ngFor="let apisType of apisTypeList" [ngValue]="apisType.apisTypeID">{{apisType.apisTypeDescription
                  }}</option>
                  </select>-->



</div>
              </div>
            <div class="form-group" style="margin-left: .5em;margin-top:0em;margin-right:0.625em;">

              <label for="note_type" style="font-size:small">User Name</label>
              <div style="margin-top:-0.5em;">
                <!--<input type="text" *ngIf="f" formControlName="user_name" class="form-control form-control-sm dropdown" />-->
                <label style="font-size:small">{{apisUserName}}</label>
              </div>
            </div>
            <div class="form-group" style="margin-left: .5em;margin-top:0em;margin-right:0.625em;">

              <label for="note_type" style="font-size:small">Password</label>
              <div style="margin-top:-0.5em;">
                <input type="text" *ngIf="f" formControlName="password" class="form-control form-control-sm dropdown" />

              </div>
            </div>
            <div class="form-group" style="margin-left: .5em;margin-top:0em;margin-right:0.625em;">

              <label for="note_type" style="font-size:small">Effective Date</label>
              <div style="display:flex;margin-top:-0.5em">
                <input tabindex="4" type="text" [matDatepicker]="effectivedatepicker" placeholder="MM/DD/YYYY" formControlName="effective_date" #effectiveDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.effective_date.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                <mat-datepicker-toggle matSuffix [for]="effectivedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                <mat-datepicker #effectivedatepicker></mat-datepicker>
              </div>
            </div>
            <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display:inline-block; white-space:pre-wrap;margin-top:.5em"><label style="font-size: small">{{errMsg}}</label></div>

          </div>

          <div style="display:flex;margin-top:0em;background-color:white;padding-left:0.5em;padding-right:0.5em;">



            

          </div>

          <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>

        </div>
      </form>

    </div>
    <div class="d-inline-flex">
      <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em">
        <button mat-raised-button class="btn-high" (click)="clickSave()">Save</button>&nbsp;
        <!--<button mat-raised-button *ngIf="apisCredentialsID!=0" class="btn-high" (click)="clickAddNew()">Add New Record</button>&nbsp;-->
        <button mat-raised-button class="btn-med" (click)="clickClose()">Close</button>

      </div>
      <app-success-message *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>
    </div>
  </div>
  <app-spin *ngIf="showSpin"></app-spin>





