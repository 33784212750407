export class PersonRewardAccountsModel {
  personGUID: string;
  personRewardAccountsGUID: string;
  rewardType: string;
  companyName: string;
  hotelRewardsProgramGUID: string;
  hotelRewardsProgramName: string;
  airlineGUID: string;
  airlineDescription: string;
  carRentalGUID: string;
  carRentalDescription: string;
  rewardAcctNum: string;
  rewardStatusID: number;
  rewardStatusDescription: string;
  isPreferred: boolean;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  pageSize: number;
  pageIndex: number;
  totalRecords: number;
  dataEntryStatusID: number;
  dataEntryStatusDescription: string;
  filterDataEntryStatus: boolean;
  customerGUID: string;
}
