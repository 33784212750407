import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { CustomerModel } from '../models/customer.model';
import { TripLegModel } from '../models/trip-leg.model';
import { VendorModel } from '../models/vendor.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
import { AirportDetailsComponent } from '../airports/airport-details.component';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { TripModel } from '../models/trip.model';
import { AircraftModel } from '../models/aircraft.model';
import { AirportModel } from '../models/airport.model';
import { TripLegService } from '../services/trip-leg.service';
import { ChecklistDashboardClientDialogComponent } from '../current-events-client/checklist-dashboard-client-dialog.component';
import { ChecklistDashboardDialogComponent } from '../current-events/checklist-dashboard-dialog.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-trip-leglist',
  templateUrl: './trip-leglist.component.html',
  styleUrls: ['./trip-leglist.component.css']
})

export class TripLeglistComponent implements OnInit {
  
  trip_leglist_form: UntypedFormGroup;
  errMsg: string;
  msg: string;
  showSpin: boolean = false;
 
  v: number = this._authService.getCurrentTimeNumber();
  
  totalRecordsText: string;
  recordList: TripLegModel[]=[];
  displayedColumn: string[] = ['registration', 'depAirport', 'arrAirport', 'pic_sic', 'crewCount', 'paxCount', 'legStatus', 'tripCode', 'clientName', 'action'];
  customerList: CustomerModel[] = [];
 
  includeDemo: boolean;
  
  startDate: Date=null;
  endDate: Date=null;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  
  upPII: boolean = false;
  registrationList: AircraftModel[] = [];
  selectedRegistrationList: AircraftModel[] = [];
  showClientList: boolean = false;
  showAircraftList: boolean = false;
  airportList: AirportModel[] = [];
  selectedAirportList: AirportModel[] = [];
  showAirportList: boolean = false;
  showPersonList: boolean = false;
  pilotList: GroundStopManifestModel[] = [];
  selectedPilotList: GroundStopManifestModel[] = [];
  crewList: GroundStopManifestModel[] = [];
  selectedCrewList: GroundStopManifestModel[] = [];
  paxList: GroundStopManifestModel[] = [];
  selectedPaxList: GroundStopManifestModel[] = [];
  person_role: string="pilot";
  showVendorList: boolean = false;
  vendorList: VendorModel[] = [];
  orgVendorList: VendorModel[] = [];
  selectedVendorList: VendorModel[] = [];
  orgCustomerList: CustomerModel[] = [];
  selectedCustomerList: CustomerModel[] = [];
  selectedRegistrationCountText: string = "";
  selectedAirportCountText: string = "";
  selectedPersonCountText: string = "";
  selectedClientCountText: string = "";
  selectedVendorCountText: string = "";
  selectedTripCodeCountText: string = "";
  showTripCodeList: boolean = false;
  
  tripCodeList: TripModel[] = [];
  selectedTripCodeList: TripModel[] = [];
  hour_select: string;
  submitted: boolean = false;
  minDate: Date = new Date("9/1/2020");
  orgTripCodeList: TripModel[];
  orgRegistrationList: AircraftModel[];
  orgAirportList: AirportModel[];
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _tripLegService: TripLegService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService, private _titleService:Title) {

  }

  getAllData(): Observable<any[]> {
    //if (this.userType == "internal") {
    //  let getClientListResponse = this._tripLegService.getClientListWithTrip();
    //  return forkJoin([getClientListResponse]);
    //}
    //else {
    //  return forkJoin([null]);
    //}
    return null;
  }

  ngOnInit() {
   
    this._authService.updateAccessTime();
    
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    else {
      this.upPII = true;
    }
    this.startDate = new Date();
    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate() + 30);
    this.errMsg = "";
    this.msg = "";
    this.totalRecordsText = "";
    this.recordList = [];
    
    this.hour_select = "12";

    this.initControls();
    
    this.getData();
   
  }

  initControls() {
    this.trip_leglist_form = this._formBuilder.group({
      includeDemo: [false],
      hour_select: [this.hour_select],
      startDate: [this.startDate],
      endDate: [this.endDate],
      client_search: [''],
      person_role: this.person_role,
      person_search_keyword: [''],
      vendor_search: [''],
      tripcode_search: [''],
      aircraft_search: [''],
      airport_search: ['']
    });

  }

  get f() { return this.trip_leglist_form.controls; }

  hourSelectChange(e: any) {
    if (this.f.hour_select.value == "custom") {
      if (this.f.startDate.value == null && this.f.endDate.value == null) {
        this.startDate = new Date();
        this.f.startDate.setValue(this.startDate);
        this.endDate = new Date();
        this.endDate.setDate(this.endDate.getDate() + 30);
        this.f.endDate.setValue(this.endDate);
      }
    }
    else {
      this.f.startDate.updateValueAndValidity();
      this.f.endDate.updateValueAndValidity();
    }

    this.clickSubmit();
  }

  dateOnchange(dateType: string, e: any) {
    if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidStartDate = false;
          this.f.startDate.setErrors({ isValidDate: false });
        }
        else {
          this.isValidStartDate = true;
        }
      }
      else {
        //this.isValidStartDate = true;
        this.f.startDate.setErrors({ required: true });
      }
    }

    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidEndDate = false;
          this.f.endDate.setErrors({ isValidDate: false })
        }
        else {
          this.isValidEndDate = true;
        }
      }
      else {
        
        this.f.endDate.setErrors({ required: true });
      }
    }

    this.clickSubmit();
    

  }

  clickSubmit() {
    this.submitted = true;
    if (this.f.hour_select.value == "custom") {
      if (this.f.startDate.value == null) {
        this.f.startDate.setErrors({ required: true });
      }
      if (this.f.endDate.value == null) {
        this.f.endDate.setErrors({ required: true });
      }
      let dt1: Date = new Date(this.f.startDate.value);
      let dt2: Date = new Date(this.f.endDate.value);

      let dt: any = dt2.getTime() - dt1.getTime();
      if (dt > (90*24*60 * 60000)) { 
        this.f.endDate.setErrors({ maxDateRange: false });
      }
    }
    this.recordList = [];

    if (this.trip_leglist_form.invalid || this.errMsg != "") {
      return;
    }

    if (this.showClientList) {
      this.getTripClientList();
    }
    if (this.showAircraftList) {
      this.getTripAircraftList();
    }
    if (this.showAirportList) {
      this.getTripAirportList();
    }
    if (this.showPersonList) {
      this.getTripPersonList();
    }
    if (this.showVendorList) {
      this.getTripVendorList();
    }
    if (this.showTripCodeList) {
      this.getTripCodeList();
    }
    this.getData();
  }


  includeDemoOnChange(e: any) {
    //this.includeDemo = e.checked; // mat checkbox
    
    this.clickSubmit();

  }

  // client
  clickClientFilter() {
    this.getTripClientList();
  }

  getTripClientList() {
    var root = this;
    this.showClientList = true;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showTripCodeList = false;
    this.customerList = [];
    this.orgCustomerList = [];
    let req = new TripModel();
    req.includeDemo = this.f.includeDemo.value;
    req.queryHour = 0;
    req.queryTimeText = "";
    req.startDate = null;
    req.startDateText = "";
    req.endDate = null;
    req.endDateText = "";
    if (this.f.hour_select.value == "custom") {
      if (this.f.startDate.value != null) {
        let templocal1: Date = new Date(this.f.startDate.value);
        let temputc1: Date;
        temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
        req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
      }

      if (this.f.endDate.value != null) {
        let templocal2: Date = new Date(this.f.endDate.value);
        let temputc2: Date;
        temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
        req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
      }
    }
    else {
      if (this.f.hour_select.value.indexOf('d') != -1) {
        req.queryTimeText = this.f.hour_select.value;
      }
      else {
        req.queryHour = Number(this.f.hour_select.value);
      }
    }
    
    this._tripLegService.getTripClientListForLegByDateTimeRange<ResponseModel<TripModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.customerList = [];
        this.orgCustomerList = [];
        if (response.model.length > 0) {
          let c = new CustomerModel();
          c.customerName = "Select All";
          c.customerGUID = "all";
          c.selected = false;
          this.customerList.push(c);
          response.model.forEach(x => {
            c = new CustomerModel();
            c.customerName = x.customerName;
            c.customerGUID = x.customerGUID;
            c.selected = false;
            if (this.selectedCustomerList.length > 0) {
              let f: CustomerModel[];
              f = this.selectedCustomerList.filter(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
              if (f.length > 0) {
                c.selected = true;
              }
            }
            this.customerList.push(c);
          });
          this.orgCustomerList = this.customerList;
          this.getCustomerListBykeyword(this.f.client_search.value);

          if (this.selectedCustomerList.length > 0 && this.selectedCustomerList.length != this.customerList.length) {
            let data: CustomerModel[] = [];
            data = this.customerList;
            this.customerList = [];

            data.forEach(x => {
              if (x.customerGUID == "all") {
                this.customerList.push(x);
              }
              else {
                if (x.selected) {
                  this.customerList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.customerGUID != "all") {
                this.customerList.push(x);
              }
            });
          }      

        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })

  }

  checkClientChange(e: any, item: CustomerModel) {
    if (item.customerGUID == 'all') {
      this.customerList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.customerList.forEach(x => {
        if (item.customerGUID.toLowerCase() == x.customerGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setClientList();
    
  }

  setClientList() {

    let excludeallclist: CustomerModel[]
    excludeallclist = this.customerList.filter(x => x.customerGUID != 'all' && x.selected == false);
    if (excludeallclist.length == 0) {
      this.customerList.find(x => x.customerGUID == 'all').selected = true;
    }
    else {
      this.customerList.find(x => x.customerGUID == 'all').selected = false;
    }
    this.selectedCustomerList = this.customerList.filter(u => u.selected == true);
    this.selectedClientCountText = "";
    if (this.orgCustomerList.length > 0) {
      if (this.selectedCustomerList.length == this.orgCustomerList.length) {
        this.selectedClientCountText = ": All";
      }
      else {
        if (this.selectedCustomerList.length > 0) {
          let f: CustomerModel[] = this.selectedCustomerList.filter(x => x.customerGUID == "all")
          if (f.length > 0) {
            let n: number = this.selectedCustomerList.length - 1;
            this.selectedClientCountText = ": " + n.toString();
          }
          else {
            this.selectedClientCountText = ": " + this.selectedCustomerList.length.toString();
          }
        }
      }
    }

  }

  clientSearchChange(e: any) {
    let cname: string = e.target.value;
    if (cname != "") {
      cname = cname.toLowerCase();
    }

    this.getCustomerListBykeyword(cname);

  }

  getCustomerListBykeyword(cname: string) {

    this.customerList = this.orgCustomerList.filter(option => option.customerName.toLowerCase().includes(cname) || option.customerGUID == "all");
    if (this.selectedCustomerList.length > 0) {
      this.selectedCustomerList.forEach(x => {
        let i: number;
        i = this.customerList.findIndex(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
        if (i == -1) {
          let c = new CustomerModel();
          c.customerName = x.customerName;
          c.customerGUID = x.customerGUID;
          c.selected = true;
          this.customerList.push(c);
        }
      });
    }
    this.setClientList();
    if (this.selectedCustomerList.length > 0 && this.selectedCustomerList.length != this.customerList.length) {
      let data: CustomerModel[] = [];
      data = this.customerList;
      this.customerList = [];

      data.forEach(x => {
        if (x.customerGUID == "all") {
          this.customerList.push(x);
        }
        else {
          if (x.selected) {
            this.customerList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.customerGUID != "all") {
          this.customerList.push(x);
        }
      });
    }

  }

  clickDoneClient() {
    this.showClientList = false;
    this.clickSubmit();
  }

  clickResetClient() {
    this.f.client_search.setValue('');
    this.selectedCustomerList = [];
    this.customerList = this.orgCustomerList;
    this.customerList.forEach(x => {
      x.selected = false;
    });
    this.setClientList();
    this.showClientList = false;
    this.clickSubmit();
  }
  // client end

  // aircraft
  clickAircraftFilter() {
    this.getTripAircraftList();
  }

  getTripAircraftList() {
    var root = this;
    this.showClientList = false;
    this.showAircraftList = true;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;   
    this.showTripCodeList = false;
    this.registrationList = [];
    let req = new TripModel();
    req.includeDemo = this.f.includeDemo.value;
    req.queryHour = 0;
    req.queryTimeText = "";
    req.startDate = null;
    req.startDateText = "";
    req.endDate = null;
    req.endDateText = "";
    if (this.f.hour_select.value == "custom") {
      if (this.f.startDate.value != null) {
        let templocal1: Date = new Date(this.f.startDate.value);
        let temputc1: Date;
        temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
        req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
      }

      if (this.f.endDate.value != null) {
        let templocal2: Date = new Date(this.f.endDate.value);
        let temputc2: Date;
        temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
        req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
      }
    }
    else {
      if (this.f.hour_select.value.indexOf('d') != -1) {
        req.queryTimeText = this.f.hour_select.value;
      }
      else {
        req.queryHour = Number(this.f.hour_select.value);
      }
    }

    this._tripLegService.getTripRegistrationListForLegByDateTimeRange<ResponseModel<TripModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.registrationList = [];
        if (response.model.length > 0) {
          let ac = new AircraftModel();
          ac.registration = "Select All";
          ac.aircraftGuid = "all";
          ac.selected = false;
          this.registrationList.push(ac);
          response.model.forEach(x => {
            ac = new AircraftModel();
            ac.aircraftGuid = x.aircraftGUID;
            ac.registration = x.registration;
            ac.selected = false;
            if (this.selectedRegistrationList.length > 0) {
              let f: AircraftModel[];
              f = this.selectedRegistrationList.filter(y => y.aircraftGuid.toLowerCase() == x.aircraftGUID.toLowerCase());
              if (f.length > 0) {
                ac.selected = true;
              }
            }
            this.registrationList.push(ac);
          });

          //this.setRegistrationList();
          this.orgRegistrationList = this.registrationList;
          this.getAircraftListBykeyword(this.f.aircraft_search.value);

          if (this.selectedRegistrationList.length > 0 && this.selectedRegistrationList.length != this.registrationList.length) {
            let data: AircraftModel[] = [];
            data = this.registrationList;
            this.registrationList = [];

            data.forEach(x => {
              if (x.aircraftGuid == "all") {
                this.registrationList.push(x);
              }
              else {
                if (x.selected) {
                  this.registrationList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.aircraftGuid != "all") {
                this.registrationList.push(x);
              }
            });
          }
         
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
      
    })

  }

  checkRegistrationChange(e: any, item: AircraftModel) {
    if (item.aircraftGuid == 'all') {
      this.registrationList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.registrationList.forEach(x => {
        if (item.aircraftGuid.toLowerCase() == x.aircraftGuid.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setRegistrationList();
   
  }

  setRegistrationList() {
    let excludeallaclist: AircraftModel[]
    excludeallaclist = this.registrationList.filter(x => x.aircraftGuid != 'all' && x.selected == false);
    if (excludeallaclist.length == 0) {
      this.registrationList.find(x => x.aircraftGuid == 'all').selected = true;
    }
    else {
      this.registrationList.find(x => x.aircraftGuid == 'all').selected = false;
    }
    this.selectedRegistrationList = this.registrationList.filter(u => u.selected == true);
    this.selectedRegistrationCountText = "";
    if (this.orgRegistrationList.length > 0) {
      if (this.selectedRegistrationList.length == this.orgRegistrationList.length) {
        this.selectedRegistrationCountText = ": All";
      }
      else {
        if (this.selectedRegistrationList.length > 0) {
          let f: AircraftModel[] = this.selectedRegistrationList.filter(x => x.aircraftGuid == "all")
          if (f.length > 0) {
            let n: number = this.selectedRegistrationList.length - 1;
            this.selectedRegistrationCountText = ": " + n.toString();
          }
          else {
            this.selectedRegistrationCountText = ": " + this.selectedRegistrationList.length.toString();
          }
        }
      }
    }
    //if (this.registrationList.length > 0) {
    //  if (this.selectedRegistrationList.length == this.registrationList.length) {
    //    this.selectedRegistrationCountText = ": All";
    //  }
    //  else {
    //    if (this.selectedRegistrationList.length > 0)
    //      this.selectedRegistrationCountText = ": " + this.selectedRegistrationList.length.toString();
    //  }
    //}

  }

  aircraftSearchChange(e: any) {
    let cname: string = e.target.value;
    if (cname != "") {
      cname = cname.toLowerCase();
    }

    this.getAircraftListBykeyword(cname);

  }

  getAircraftListBykeyword(cname: string) {

    this.registrationList = this.orgRegistrationList.filter(option => option.registration.toLowerCase().includes(cname) || option.aircraftGuid == "all");
    if (this.selectedRegistrationList.length > 0) {
      this.selectedRegistrationList.forEach(x => {
        let i: number;
        i = this.registrationList.findIndex(y => y.aircraftGuid.toLowerCase() == x.aircraftGuid.toLowerCase());
        if (i == -1) {
          let c = new AircraftModel();
          c.registration = x.registration;
          c.aircraftGuid = x.aircraftGuid;
          c.selected = true;
          this.registrationList.push(c);
        }
      });
    }
    this.setRegistrationList();
    if (this.selectedRegistrationList.length > 0 && this.selectedRegistrationList.length != this.registrationList.length) {
      let data: AircraftModel[] = [];
      data = this.registrationList;
      this.registrationList = [];

      data.forEach(x => {
        if (x.aircraftGuid == "all") {
          this.registrationList.push(x);
        }
        else {
          if (x.selected) {
            this.registrationList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.aircraftGuid != "all") {
          this.registrationList.push(x);
        }
      });
    }

  }


  clickDoneAircraft() {
    this.showAircraftList = false;
    this.clickSubmit();
  }

  clickResetAircraft() {
    this.registrationList.forEach(x => {
      x.selected = false;
    });
    this.setRegistrationList();
    this.showAircraftList = false;
    this.clickSubmit();
  }
  // aircraft end

  // airport
  clickAirportFilter() {
    this.getTripAirportList();
  }

  getTripAirportList() {
    var root = this;
    this.showClientList = false;
    this.showAircraftList = false;
    this.showAirportList = true;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showTripCodeList = false;
    this.airportList = [];
    let req = new TripModel();
    req.includeDemo = this.f.includeDemo.value;
    req.queryHour = 0;
    req.queryTimeText = "";
    req.startDate = null;
    req.startDateText = "";
    req.endDate = null;
    req.endDateText = "";
    if (this.f.hour_select.value == "custom") {
      if (this.f.startDate.value != null) {
        let templocal1: Date = new Date(this.f.startDate.value);
        let temputc1: Date;
        temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
        req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
      }

      if (this.f.endDate.value != null) {
        let templocal2: Date = new Date(this.f.endDate.value);
        let temputc2: Date;
        temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
        req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
      }
    }
    else {
      if (this.f.hour_select.value.indexOf('d') != -1) {
        req.queryTimeText = this.f.hour_select.value;
      }
      else {
        req.queryHour = Number(this.f.hour_select.value);
      }
    }
    this._tripLegService.getTripAirportListForLegByDateTimeRange<ResponseModel<GroundStopModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.airportList = [];
        if (response.model.length > 0) {
          let ap = new AirportModel();
          ap.icao = "Select All";
          ap.iata = "";
          ap.airportID = 0;
          ap.selected = false;
          this.airportList.push(ap);

          response.model.forEach((x, index) => {
            ap = new AirportModel();
            ap.icao = x.icao;
            ap.iata = x.iata;
            ap.airportID = index + 1;
            ap.selected = false;
            if (this.selectedAirportList.length > 0) {
              let f: AirportModel[];
              f = this.selectedAirportList.filter(y => y.icao.toLowerCase() == x.icao.toLowerCase());
              if (f.length > 0) {
                ap.selected = true;
              }
            }
            this.airportList.push(ap);

          });
          //this.setAirportList();
          this.orgAirportList = this.airportList;
          this.getAirportListBykeyword(this.f.airport_search.value);
          if (this.selectedAirportList.length > 0 && this.selectedAirportList.length != this.airportList.length) {
            let data: AirportModel[] = [];
            data = this.airportList;
            this.airportList = [];

            data.forEach(x => {
              if (x.airportID == 0) {
                this.airportList.push(x);
              }
              else {
                if (x.selected) {
                  this.airportList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.airportID != 0) {
                this.airportList.push(x);
              }
            });
          }         
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
     
    })

  }

  checkAirportChange(e: any, item: AirportModel) {
    if (item.airportID == 0) {
      this.airportList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.airportList.forEach(x => {
        if (item.airportID == x.airportID) {
          x.selected = e.checked;
        }
      });
    }
    this.setAirportList();
    //this.reloadChildPage = false;
  }

  setAirportList() {
    let excludeallaplist: AirportModel[]
    excludeallaplist = this.airportList.filter(x => x.airportID != 0 && x.selected == false);
    if (excludeallaplist.length == 0) {
      this.airportList.find(x => x.airportID == 0).selected = true;
    }
    else {
      this.airportList.find(x => x.airportID == 0).selected = false;
    }
    this.selectedAirportList = this.airportList.filter(u => u.selected == true);
    this.selectedAirportCountText = "";
    if (this.orgAirportList.length > 0) {
      if (this.selectedAirportList.length == this.orgAirportList.length) {
        this.selectedAirportCountText = ": All";
      }
      else {
        if (this.selectedAirportList.length > 0) {
          let f: AirportModel[] = this.selectedAirportList.filter(x => x.airportID == 0)
          if (f.length > 0) {
            let n: number = this.selectedAirportList.length - 1;
            this.selectedAirportCountText = ": " + n.toString();
          }
          else {
            this.selectedAirportCountText = ": " + this.selectedAirportList.length.toString();
          }
        }
      }
    }
    //if (this.airportList.length > 0) {
    //  if (this.selectedAirportList.length == this.airportList.length) {
    //    this.selectedAirportCountText = ": All";
    //  }
    //  else {
    //    if (this.selectedAirportList.length > 0)
    //      this.selectedAirportCountText = ": " + this.selectedAirportList.length.toString();
    //  }
    //}
  }

  airportSearchChange(e: any) {
    let cname: string = e.target.value;
    if (cname != "") {
      cname = cname.toLowerCase();
    }

    this.getAirportListBykeyword(cname);

  }

  getAirportListBykeyword(cname: string) {

    this.airportList = this.orgAirportList.filter(option => option.icao.toLowerCase().includes(cname) || option.airportID == 0);
    if (this.selectedAirportList.length > 0) {
      this.selectedAirportList.forEach(x => {
        let i: number;
        i = this.airportList.findIndex(y => y.icao.toLowerCase() == x.icao.toLowerCase());
        if (i == -1) {
          let c = new AirportModel();
          c.icao = x.icao;
          c.airportID = x.airportID;
          c.selected = true;
          this.airportList.push(c);
        }
      });
    }
    this.setAirportList();
    if (this.selectedAirportList.length > 0 && this.selectedAirportList.length != this.airportList.length) {
      let data: AirportModel[] = [];
      data = this.airportList;
      this.airportList = [];

      data.forEach(x => {
        if (x.airportID == 0) {
          this.airportList.push(x);
        }
        else {
          if (x.selected) {
            this.airportList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.airportID != 0) {
          this.airportList.push(x);
        }
      });
    }

  }

  clickDoneAirport() {
    this.showAirportList = false;
    this.clickSubmit();
  }

  clickResetAirport() {
    this.airportList.forEach(x => {
      x.selected = false;
    });
    this.setAirportList();
    this.showAirportList = false;
    this.clickSubmit();
  }
  // airport end

  // manifest
  clickManifestFilter() {
    this.getTripPersonList();
  }

  getTripPersonList() {
    var root = this;
    this.showClientList = false;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = true;
    this.showVendorList = false;
    this.showTripCodeList = false;
    switch (this.f.person_role.value) {
      case "pilot":
        this.pilotList = [];
        break;
      case "crew":
        this.crewList = [];
        break;
      case "passenger":
        this.paxList = [];
        break;
    }

    let req = new TripModel();
    req.includeDemo = this.f.includeDemo.value;
    req.queryHour = 0;
    req.queryTimeText = "";
    req.startDate = null;
    req.startDateText = "";
    req.endDate = null;
    req.endDateText = "";
    if (this.f.hour_select.value == "custom") {
      if (this.f.startDate.value != null) {
        let templocal1: Date = new Date(this.f.startDate.value);
        let temputc1: Date;
        temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
        req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
      }

      if (this.f.endDate.value != null) {
        let templocal2: Date = new Date(this.f.endDate.value);
        let temputc2: Date;
        temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
        req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
      }
    }
    else {
      if (this.f.hour_select.value.indexOf('d') != -1) {
        req.queryTimeText = this.f.hour_select.value;
      }
      else {
        req.queryHour = Number(this.f.hour_select.value);
      }
    }
    req.personTextSearch = this.f.person_search_keyword.value;
    req.personRole = this.person_role;
    this._tripLegService.getTripPersonListForLegByDateTimeRange<ResponseModel<GroundStopManifestModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        switch (this.f.person_role.value) {
          case "pilot":
            this.pilotList = [];
            break;
          case "crew":
            this.crewList = [];
            break;
          case "passenger":
            this.paxList = [];
            break;
        }
        if (response.model.length > 0) {
          let p = new GroundStopManifestModel();
          p.personName = "Select All";
          p.personGUID = "all";
          p.selected = false;
          p.personType = this.person_role;
          switch (this.f.person_role.value) {
            case "pilot":
              this.pilotList.push(p);
              break;
            case "crew":
              this.crewList.push(p);
              break;
            case "passenger":
              this.paxList.push(p);
              break;
          }

          response.model.forEach(x => {
            p = new GroundStopManifestModel();
            p.personGUID = x.personGUID;
            p.personName = x.personName;
            p.selected = false;
            p.personType = this.person_role;
            switch (this.person_role) {
              case "pilot":
                if (this.selectedPilotList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedPilotList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.pilotList.push(p);

                break;
              case "crew":
                if (this.selectedCrewList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedCrewList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.crewList.push(p);
                break;
              case "passenger":
                if (this.selectedPaxList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedPaxList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.paxList.push(p);
                break;
            }

          });
          let i: number;
          switch (this.person_role) {
            case "pilot":
              if (this.selectedPilotList.length > 0) {
                this.selectedPilotList.forEach(x => {
                  let n: number;
                  n = this.pilotList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (n == -1) {
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.pilotList.push(p);
                  }
                });
              }
              this.setPersonList();
              if (this.selectedPilotList.length > 0 && this.selectedPilotList.length != this.pilotList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.pilotList;
                this.pilotList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.pilotList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.pilotList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.pilotList.push(x);
                  }
                });
              }
              
              break;
            case "crew":
              if (this.selectedCrewList.length > 0) {
                this.selectedCrewList.forEach(x => {
                  let n: number;
                  n = this.crewList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (n == -1) {
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.crewList.push(p);
                  }
                });
              }
              this.setPersonList();
              if (this.selectedCrewList.length > 0 && this.selectedCrewList.length != this.crewList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.crewList;
                this.crewList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.crewList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.crewList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.crewList.push(x);
                  }
                });
              }
              
              break;
            case "passenger":
              if (this.selectedPaxList.length > 0) {
                this.selectedPaxList.forEach(x => {
                  let n: number;
                  n = this.paxList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (n == -1) {
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.paxList.push(p);
                  }
                });
              }
              this.setPersonList();
              if (this.selectedPaxList.length > 0 && this.selectedPaxList.length != this.paxList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.paxList;
                this.paxList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.paxList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.paxList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.paxList.push(x);
                  }
                });
              }
              
              break;
          }




        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
      

    })

  }

  personSearchKeywordChange(e: any) {
    this.getTripPersonList();
  }

  personRoleChange(e: any) {
    this.person_role = e.target.value;
    this.f.person_search_keyword.setValue('');
    this.selectedPilotList = [];
    this.selectedCrewList = [];
    this.selectedPaxList = [];

    this.selectedPersonCountText = "";
    this.getTripPersonList();
  }

  checkPersonChange(e: any, item: GroundStopManifestModel) {
    switch (this.person_role) {
      case "pilot":
        if (item.personGUID == 'all') {
          this.pilotList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.pilotList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();

        break;
      case "crew":
        if (item.personGUID == 'all') {
          this.crewList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.crewList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();
        break;
      case "passenger":
        if (item.personGUID == 'all') {
          this.paxList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.paxList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();
        break;
    }
    //this.reloadChildPage = false;

  }

  setPersonList() {
    this.selectedPersonCountText = "";
    let n: number = 0;
    switch (this.person_role) {
      case "pilot":
        let excludeallplist: GroundStopManifestModel[]
        excludeallplist = this.pilotList.filter(x => x.personGUID != 'all' && x.selected == false);
        if (excludeallplist.length == 0) {
          this.pilotList.find(x => x.personGUID == 'all').selected = true;
        }
        else {
          this.pilotList.find(x => x.personGUID == 'all').selected = false;
        }
        this.selectedPilotList = this.pilotList.filter(u => u.selected == true);
        if (this.pilotList.length > 0) {
          if (this.selectedPilotList.length == this.pilotList.length && this.f.person_search_keyword.value == '') {
            this.selectedPersonCountText = ": Pilot All";
          }
          else {
            if (this.selectedPilotList.length > 0) {
              if (this.pilotList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedPilotList.length - 1;
              }
              else {
                n = this.selectedPilotList.length;
              }
              this.selectedPersonCountText = ": Pilot " + n.toString();
            }
          }
        }
        break;
      case "crew":
        let excludeallclist: GroundStopManifestModel[]
        excludeallclist = this.crewList.filter(x => x.personGUID != 'all' && x.selected == false);
        if (excludeallclist.length == 0) {
          this.crewList.find(x => x.personGUID == 'all').selected = true;
        }
        else {
          this.crewList.find(x => x.personGUID == 'all').selected = false;
        }
        this.selectedCrewList = this.crewList.filter(u => u.selected == true);
        if (this.crewList.length > 0) {
          if (this.selectedCrewList.length == this.crewList.length && this.f.person_search_keyword.value == '') {
            this.selectedPersonCountText = ": Crew All";
          }
          else {
            //if (this.selectedCrewList.length > 0)
            //  this.selectedPersonCountText = ": Crew " + this.selectedCrewList.length.toString();
            if (this.selectedCrewList.length > 0) {
              if (this.crewList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedCrewList.length - 1;
              }
              else {
                n = this.selectedCrewList.length;
              }
              this.selectedPersonCountText = ": Crew " + n.toString();
            }

          }
        }
        break;
      case "passenger":
        let excludeallpxlist: GroundStopManifestModel[]
        excludeallpxlist = this.paxList.filter(x => x.personGUID != 'all' && x.selected == false);
        if (excludeallpxlist.length == 0) {
          this.paxList.find(x => x.personGUID == 'all').selected = true;
        }
        else {
          this.paxList.find(x => x.personGUID == 'all').selected = false;
        }
        this.selectedPaxList = this.paxList.filter(u => u.selected == true);
        if (this.paxList.length > 0) {
          if (this.selectedPaxList.length == this.paxList.length && this.f.person_search_keyword.value == '') {
            this.selectedPersonCountText = ": Passenger All";
          }
          else {
            //if (this.selectedPaxList.length > 0)
            //  this.selectedPersonCountText = ": Passenger " + this.selectedPaxList.length.toString();
            if (this.selectedPaxList.length > 0) {
              if (this.paxList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedPaxList.length - 1;
              }
              else {
                n = this.selectedPaxList.length;
              }
              this.selectedPersonCountText = ": Passenger " + n.toString();
            }
          }
        }
        break;
    }

  }

  clickDonePerson() {
    this.showPersonList = false;
    this.clickSubmit();
  }

  clickResetPerson() {
    this.f.person_search_keyword.setValue('');
    switch (this.person_role) {
      case "pilot":
        this.selectedPilotList = [];
       
        break;
      case "crew":
        this.selectedCrewList = [];
        
        break;
      case "passenger":
        this.selectedPaxList = [];
        
        break;
    }
    this.getTripPersonList();
    this.showPersonList = false;
    this.clickSubmit();
  }

  // manifest end
  // vendor
  clickVendorFilter() {
    this.getTripVendorList();
  }

  getTripVendorList() {
    var root = this;
    this.showClientList = false;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = true;
    this.showTripCodeList = false;
    this.vendorList = [];
    this.orgVendorList = [];
    let req = new TripModel();
    req.includeDemo = this.f.includeDemo.value;
    req.queryHour = 0;
    req.queryTimeText = "";
    req.startDate = null;
    req.startDateText = "";
    req.endDate = null;
    req.endDateText = "";
    if (this.f.hour_select.value == "custom") {
      if (this.f.startDate.value != null) {
        let templocal1: Date = new Date(this.f.startDate.value);
        let temputc1: Date;
        temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
        req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
      }

      if (this.f.endDate.value != null) {
        let templocal2: Date = new Date(this.f.endDate.value);
        let temputc2: Date;
        temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
        req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
      }
    }
    else {
      if (this.f.hour_select.value.indexOf('d') != -1) {
        req.queryTimeText = this.f.hour_select.value;
      }
      else {
        req.queryHour = Number(this.f.hour_select.value);
      }
    }

    this._tripLegService.getTripVendorListForLegByDateTimeRange<ResponseModel<VendorModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.vendorList = [];
        this.orgVendorList = [];
        if (response.model.length > 0) {
          let v = new VendorModel();
          v.vendorName = "Select All";
          v.vendorGUID = "all";
          v.icao = "";
          v.selected = false;
          this.vendorList.push(v);
          response.model.forEach(x => {
            v = new VendorModel();
            v.vendorName = x.vendorName;
            v.vendorGUID = x.vendorGUID;
            v.icao = x.icao;
            v.selected = false;
            if (this.selectedVendorList.length > 0) {
              let f: VendorModel[];
              f = this.selectedVendorList.filter(y => y.vendorGUID.toLowerCase() == x.vendorGUID.toLowerCase());
              if (f.length > 0) {
                v.selected = true;
              }
            }
            this.vendorList.push(v);
          });
          this.orgVendorList = this.vendorList;
          this.getVendorListBykeyword(this.f.vendor_search.value);

          if (this.selectedVendorList.length > 0 && this.selectedVendorList.length != this.vendorList.length) {
            let data: VendorModel[] = [];
            data = this.vendorList;
            this.vendorList = [];

            data.forEach(x => {
              if (x.vendorGUID == "all") {
                this.vendorList.push(x);
              }
              else {
                if (x.selected) {
                  this.vendorList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.vendorGUID != "all") {
                this.vendorList.push(x);
              }
            });
          }
          
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })

  }

  checkVendorChange(e: any, item: VendorModel) {
    if (item.vendorGUID == 'all') {
      this.vendorList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.vendorList.forEach(x => {
        if (item.vendorGUID.toLowerCase() == x.vendorGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setVendorList();
    //this.reloadChildPage = false;
  }

  setVendorList() {
    let excludeallvlist: VendorModel[]
    excludeallvlist = this.vendorList.filter(x => x.vendorGUID != 'all' && x.selected == false);
    if (excludeallvlist.length == 0) {
      this.vendorList.find(x => x.vendorGUID == 'all').selected = true;
    }
    else {
      this.vendorList.find(x => x.vendorGUID == 'all').selected = false;
    }
    this.selectedVendorList = this.vendorList.filter(u => u.selected == true);
    this.selectedVendorCountText = "";
    if (this.orgVendorList.length > 0) {
      if (this.selectedVendorList.length == this.orgVendorList.length) {
        this.selectedVendorCountText = ": All";
      }
      else {
        if (this.selectedVendorList.length > 0) {
          let f: VendorModel[] = this.selectedVendorList.filter(x => x.vendorGUID == "all")
          if (f.length > 0) {
            let n: number = this.selectedVendorList.length - 1;
            this.selectedVendorCountText = ": " + n.toString();
          }
          else {
            this.selectedVendorCountText = ": " + this.selectedVendorList.length.toString();
          }
        }
      }
    }
  }

  vendorSearchChange(e: any) {
    let vname: string = e.target.value;
    if (vname != "") {
      vname = vname.toLowerCase();

    }

    this.getVendorListBykeyword(vname);

  }

  getVendorListBykeyword(vname: string) {
    if (vname != "")
      vname = vname.trim();
    this.vendorList = this.orgVendorList.filter(option => option.vendorName.toLowerCase().includes(vname) || option.vendorGUID == "all");
    if (this.selectedVendorList.length > 0) {
      this.selectedVendorList.forEach(x => {
        let i: number;
        i = this.vendorList.findIndex(y => y.vendorGUID.toLowerCase() == x.vendorGUID.toLowerCase());
        if (i == -1) {
          let v = new VendorModel();
          v.vendorName = x.vendorName;
          v.vendorGUID = x.vendorGUID;
          v.icao = x.icao;
          v.selected = true;
          this.vendorList.push(v);
        }
      });
    }
    this.setVendorList();
    if (this.selectedVendorList.length > 0 && this.selectedVendorList.length != this.vendorList.length) {
      let data: VendorModel[] = [];
      data = this.vendorList;
      this.vendorList = [];

      data.forEach(x => {
        if (x.vendorGUID == "all") {
          this.vendorList.push(x);
        }
        else {
          if (x.selected) {
            this.vendorList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.vendorGUID != "all") {
          this.vendorList.push(x);
        }
      });
    }
  }

  clickDoneVendor() {
    this.showVendorList = false;
    this.clickSubmit();
  }

  clickResetVendor() {
    this.f.vendor_search.setValue('');
    this.selectedVendorList = [];
    this.vendorList = this.orgVendorList;
    this.vendorList.forEach(x => {
      x.selected = false;
    });
    this.setVendorList();
    this.showVendorList = false;
    this.clickSubmit();
  }

  // vendor end

  // trip code
  clickTripCodeFilter() {
    this.getTripCodeList();
  }

  getTripCodeList() {
    var root = this;
    this.showClientList = false;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showTripCodeList = true;
    this.tripCodeList = [];
    let req = new TripModel();
    req.includeDemo = this.f.includeDemo.value;
    req.queryHour = 0;
    req.queryTimeText = "";
    req.startDate = null;
    req.startDateText = "";
    req.endDate = null;
    req.endDateText = "";
    if (this.f.hour_select.value == "custom") {
      if (this.f.startDate.value != null) {
        let templocal1: Date = new Date(this.f.startDate.value);
        let temputc1: Date;
        temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
        req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
      }

      if (this.f.endDate.value != null) {
        let templocal2: Date = new Date(this.f.endDate.value);
        let temputc2: Date;
        temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
        req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
      }
    }
    else {
      if (this.f.hour_select.value.indexOf('d') != -1) {
        req.queryTimeText = this.f.hour_select.value;
      }
      else {
        req.queryHour = Number(this.f.hour_select.value);
      }
    }

    this._tripLegService.getTripCodeListForLegByDateTimeRange<ResponseModel<TripModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.tripCodeList = [];
        if (response.model.length > 0) {
          let t = new TripModel();
          t.tripCode = "Select All";
          t.tripCodeGUID = "all";
          t.selected = false;
          this.tripCodeList.push(t);
          response.model.forEach(x => {
            t = new TripModel();
            t.tripCode = x.tripCode;
            t.tripCodeGUID = x.tripCodeGUID;
            t.selected = false;
            if (this.selectedTripCodeList.length > 0) {
              let f: TripModel[];
              f = this.selectedTripCodeList.filter(y => y.tripCodeGUID.toLowerCase() == x.tripCodeGUID.toLowerCase());
              if (f.length > 0) {
                t.selected = true;
              }
            }
            this.tripCodeList.push(t);
          });

          //this.setTripCodeList();
          this.orgTripCodeList = this.tripCodeList;
          this.getTripCodeListBykeyword(this.f.tripcode_search.value);

          if (this.selectedTripCodeList.length > 0 && this.selectedTripCodeList.length != this.tripCodeList.length) {
            let data: TripModel[] = [];
            data = this.tripCodeList;
            this.tripCodeList = [];

            data.forEach(x => {
              if (x.tripCodeGUID == "all") {
                this.tripCodeList.push(x);
              }
              else {
                if (x.selected) {
                  this.tripCodeList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.tripCodeGUID != "all") {
                this.tripCodeList.push(x);
              }
            });
          }          
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
      
    })

  }

  checkTripCodeChange(e: any, item: TripModel) {
    if (item.tripCodeGUID == 'all') {
      this.tripCodeList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.tripCodeList.forEach(x => {
        if (item.tripCodeGUID.toLowerCase() == x.tripCodeGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setTripCodeList();
    
  }

  setTripCodeList() {
    let excludealltclist: TripModel[]
    excludealltclist = this.tripCodeList.filter(x => x.tripCodeGUID != 'all' && x.selected == false);
    if (excludealltclist.length == 0) {
      this.tripCodeList.find(x => x.tripCodeGUID == 'all').selected = true;
    }
    else {
      this.tripCodeList.find(x => x.tripCodeGUID == 'all').selected = false;
    }
    this.selectedTripCodeList = this.tripCodeList.filter(u => u.selected == true);
    this.selectedTripCodeCountText = "";
    if (this.tripCodeList.length > 0) {
      if (this.selectedTripCodeList.length == this.tripCodeList.length) {
        this.selectedTripCodeCountText = ": All";
      }
      else {
        if (this.selectedTripCodeList.length > 0)
          this.selectedTripCodeCountText = ": " + this.selectedTripCodeList.length.toString();
      }
    }

  }

  tripCodeSearchChange(e: any) {
    let tname: string = e.target.value;
    if (tname != "") {
      tname = tname.toLowerCase();
    }

    this.getTripCodeListBykeyword(tname);

  }

  getTripCodeListBykeyword(tname: string) {

    this.tripCodeList = this.orgTripCodeList.filter(option => option.tripCode.toLowerCase().includes(tname) || option.tripCodeGUID == "all");
    if (this.selectedTripCodeList.length > 0) {
      this.selectedTripCodeList.forEach(x => {
        let i: number;
        i = this.tripCodeList.findIndex(y => y.tripCodeGUID.toLowerCase() == x.tripCodeGUID.toLowerCase());
        if (i == -1) {
          let c = new TripModel();
          c.tripCode = x.tripCode;
          c.tripCodeGUID = x.tripCodeGUID;
          c.selected = true;
          this.tripCodeList.push(c);
        }
      });
    }
    this.setTripCodeList();
    if (this.selectedTripCodeList.length > 0 && this.selectedTripCodeList.length != this.tripCodeList.length) {
      let data: TripModel[] = [];
      data = this.tripCodeList;
      this.tripCodeList = [];

      data.forEach(x => {
        if (x.tripCodeGUID == "all") {
          this.tripCodeList.push(x);
        }
        else {
          if (x.selected) {
            this.tripCodeList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.tripCodeGUID != "all") {
          this.tripCodeList.push(x);
        }
      });
    }

  }

  clickDoneTripCode() {
    this.showTripCodeList = false;
    this.clickSubmit();
  }

  clickResetTripCode() {
    this.tripCodeList.forEach(x => {
      x.selected = false;
    });
    this.setTripCodeList();
    this.showTripCodeList = false;
    this.clickSubmit();
  }
  // trip code end

  getData() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.showSpin = true;
    this.totalRecordsText = "";
    let request = new TripModel();
    request.queryHour = 0;
    request.queryTimeText = "";
    request.startDate = null;
    request.startDateText = "";
    request.endDate = null;
    request.endDateText = "";
    if (this.f.hour_select.value != "custom") {
      if (this.f.hour_select.value.indexOf('d') != -1) {
        request.queryTimeText = this.f.hour_select.value;
      }
      else {
        request.queryHour = Number(this.f.hour_select.value);
      }
    }
    else {     
      if (this.f.startDate.value != null) {
        let templocal1: Date = new Date(this.f.startDate.value);
        let temputc1: Date;
        temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
        request.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
      }     
      if (this.f.endDate.value != null) {
        let templocal2: Date = new Date(this.f.endDate.value);
        let temputc2: Date;
        temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
        request.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
      }
    }
    request.includeDemo = this.f.includeDemo.value;
    
    if (this.selectedCustomerList.length == this.orgCustomerList.length) {
      request.selectedCustomerGUIDList = "";
    }
    else {
      this.selectedCustomerList = this.selectedCustomerList.filter(x => x.customerGUID != 'all');
      request.selectedCustomerGUIDList = Array.prototype.map.call(this.selectedCustomerList, s => s.customerGUID).toString();
    }
    if (this.selectedRegistrationList.length == this.registrationList.length) {
      request.selectedAircraftGUIDList = "";
    }
    else {
      request.selectedAircraftGUIDList = Array.prototype.map.call(this.selectedRegistrationList, s => s.aircraftGuid).toString();
    }
    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    request.personRole = this.person_role;
    switch (this.person_role) {
      case "pilot":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedPilotList.length == this.pilotList.length) {
            request.selectedPersonGUIDList = "";
          }
          else {
            request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPilotList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedPilotList = this.selectedPilotList.filter(x => x.personGUID != 'all');
          request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPilotList, s => s.personGUID).toString();
        }
        break;
      case "crew":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedCrewList.length == this.crewList.length) {
            request.selectedPersonGUIDList = "";
          }
          else {
            request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedCrewList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedCrewList = this.selectedCrewList.filter(x => x.personGUID != 'all');
          request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedCrewList, s => s.personGUID).toString();
        }
        break;
      case "passenger":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedPaxList.length == this.paxList.length) {
            request.selectedPersonGUIDList = "";
          }
          else {
            request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPaxList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedPaxList = this.selectedPaxList.filter(x => x.personGUID != 'all');
          request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPaxList, s => s.personGUID).toString();
        }
        break;
    }
    if (this.selectedVendorList.length == this.orgVendorList.length) {
      request.selectedVendorGUIDList = "";
    }
    else {
      this.selectedVendorList = this.selectedVendorList.filter(x => x.vendorGUID != 'all');
      request.selectedVendorGUIDList = Array.prototype.map.call(this.selectedVendorList, s => s.vendorGUID).toString();
    }
    
    if (this.selectedTripCodeList.length == this.tripCodeList.length) {
      request.selectedTripCodeGUIDList = "";
    }
    else {
      request.selectedTripCodeGUIDList = Array.prototype.map.call(this.selectedTripCodeList, s => s.tripCodeGUID).toString();
    }
    
    this._tripLegService.getTripLegListByConditions<ResponseModel<TripLegModel[]>>(request).subscribe(response => {
      this.recordList = [];
      this.totalRecordsText = "";
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList.length > 1) {
            this.totalRecordsText = this.recordList.length.toString() + " Records Found";
          }
          else {
            this.totalRecordsText = this.recordList.length.toString() + " Record Found";
          }

        }
        else {
          this.totalRecordsText = "No record found";


        }
        this.showSpin = false;
        
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    //  this._titleService.setTitle("Daily View");
    });
   

  }

  clickRefresh() {
    this.clickSubmit();
    //this.getData();

  }

  clickOpen(e: any, item: TripLegModel) {
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "tripId": item.tripCodeGUID,
    //    "aircraftId": item.aircraftGUID,
    //    "customerId": item.customerGUID,
    //    "pf": "",
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
  }

  clickVendorName(e: any, itemLeg: TripLegModel, itemVendor: VendorModel, stopType: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    let icao: string;
    if (stopType == "D") {
      icao = itemLeg.departureICAO;
    }
    else {
      icao = itemLeg.nextArrivalICAO;
    }

    dialogConfig.data = {
      vendorId: itemVendor.vendorGUID,
      icao: icao
    };
    this._dialog.open(VendorDetailsComponent, dialogConfig);
  }

  clickICAO(e: any, gs: TripLegModel, legType: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    if (legType == "D") {
      dialogConfig.data = {
        icao: gs.departureICAO,
        icaoIata: gs.departureICAO_IATA,
        groundStopGUID: gs.groundStopGUID,
        tripCodeGUID: gs.tripCodeGUID
      };
    }
    if (legType == "A") {
      dialogConfig.data = {
        icao: gs.nextArrivalICAO,
        icaoIata: gs.nextArrivalICAO_IATA,
        groundStopGUID: gs.nextArrivalGroundStopGUID,
        tripCodeGUID: gs.tripCodeGUID
      };
    }

    this._dialog.open(AirportDetailsComponent, dialogConfig);
  }

  openChecklist(obj: TripLegModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = "70em";
    //dialogConfig.panelClass = "custom-dialog-container2";
    //if (this.userType == 'internal')
    //  obj.customerGUID = Array.prototype.map.call(this.selectedClientList, s => s.customerGUID).toString();
    dialogConfig.data = { leg: obj };
    this._dialog.open(ChecklistDashboardDialogComponent, dialogConfig);
  }

  clickReset() {
    this.startDate = new Date();
    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate() + 30);
    this.errMsg = "";
    this.msg = "";
    this.totalRecordsText = "";
    this.recordList = [];
    this.person_role = "pilot";
    this.hour_select = "12";
    this.registrationList = [];
    this.selectedRegistrationList= [];
    this.showClientList= false;
    this.showAircraftList= false;
    this.airportList = [];
    this.selectedAirportList = [];
    this.showAirportList= false;
    this.showPersonList = false;
    this.pilotList = [];
    this.selectedPilotList = [];
    this.crewList= [];
    this.selectedCrewList = [];
    this.paxList= [];
    this.selectedPaxList= [];   
    this.showVendorList= false;
    this.vendorList = [];
    this.orgVendorList = [];
    this.selectedVendorList = [];
    this.orgCustomerList= [];
    this.selectedCustomerList = [];
    this.selectedRegistrationCountText = "";
    this.selectedAirportCountText = "";
    this.selectedPersonCountText = "";
    this.selectedClientCountText= "";
    this.selectedVendorCountText = "";
    this.selectedTripCodeCountText = "";
    this.showTripCodeList = false;

    this.tripCodeList = [];
    this.selectedTripCodeList = [];
    this.initControls();
    this.clickSubmit();
  }

}
