<div style="margin-top:0em; padding-top:0em; display:flex">
  <form *ngIf="vendor_comms_form" [formGroup]="vendor_comms_form">
    <div style="margin-top:-1em">
      <table mat-table [dataSource]="recordList" class="example-container">
        <tr>
          <ng-container matColumnDef="addressUse">
            <th mat-header-cell *matHeaderCellDef style="width:35%">
              <div style="margin-top:-0.25em;">Address Use</div>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.addressUseDescriptionList}}</td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef style="width:15%">
              <div style="margin-top:-0.25em;">Address</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.address}}<span *ngIf="element.extension!=null && element.extension!=''"> Ext. {{element.extension}}</span></td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef style="width:10%">
              <div style="margin-top:-0.25em;">Type</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.commAddressType}}</td>
          </ng-container>
          <ng-container matColumnDef="isPreferred">
            <th mat-header-cell *matHeaderCellDef style="width:10%">
              Preferred
            </th>
            <td mat-cell *matCellDef="let element">
              <i *ngIf="element.isPreferred" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
            </td>
          </ng-container>
          <ng-container matColumnDef="remark">
            <th mat-header-cell *matHeaderCellDef style="width:15%">
              <div style="margin-top:-0.25em;">Remark</div>
            </th>
            <td mat-cell *matCellDef="let element"><a *ngIf="element.commRemarks.length>15" style="cursor:pointer" matTooltipClass="tooltipLineBreak" matTooltip="{{element.commRemarks}}">{{ element.commRemarks.substring(0,14)+'...' }}</a><span *ngIf="element.commRemarks.length<=15">{{element.commRemarks}}</span></td>
          </ng-container>
          <ng-container matColumnDef="disabled">
            <th mat-header-cell *matHeaderCellDef style="width:5%">
              <div style="margin-top:-0.25em;">Disabled</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.isActive? '&nbsp;': 'Yes'}}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width:5%">
              <div>Action</div><div style="margin-top:0em;padding-bottom:0.25em"><a mat-button class="btn-high grid" style="align-self:center" (click)="clickEdit($event, null);">Add</a></div>
            </th>
            <td mat-cell *matCellDef="let element">
              <a mat-button class="btn-low grid" style="align-self:center" (click)="clickEdit($event, element);">
                Edit
              </a>
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
    </div>
    <div>
      <mat-paginator *ngIf="recordList && totalPageNumber>1" style="border: none;width:80em;" #paginator [length]="totalRecords" [pageSize]="pageSize"
                     [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
      </mat-paginator>
    </div>
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
