
<div style="background-color:#f8f8f8"  cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>
  <div class="p-2 d-flex justify-content-between align-items-center">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="d-inline-flex">
      <mat-icon>help_outline</mat-icon>
      <h5>Help Guides</h5>
    </div>
    <button mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="d-flex justify-content-center" style="margin-top:-.5em;color:deepskyblue"><h6>{{category.categoryTitle}}</h6></div>
 
</div>
<div class="p-2" style="height:36em">
    <mat-selection-list [multiple]="false" style="margin-left:-1em; max-height:35em;overflow:auto">
      <mat-list-option *ngFor="let guide of helpGuideList" (click)="showDetails(guide.helpGuideGUID)">
        <span class="d-flex"><span class="material-icons-outlined mr-1">description</span>{{guide.helpGuideTitle}}</span>
      </mat-list-option>
    </mat-selection-list>
  </div>
  <div class="p-2" *ngIf="isAdmin">
    <button mat-button class="btn-med" (click)="addNewTopic()">Add New Topic</button>
  </div>
