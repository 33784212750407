import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopService } from '../services/ground-stop.service';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { AccountingStatusModel } from '../models/accounting-status.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';

import { FileModel } from '../models/file.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { VendorModel } from '../models/vendor.model';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import { ServiceArrangementModel } from '../models/service-arrangement.model';
import { VendorContactModel } from '../models/vendor-contact.model';
import { HandlingNotesModel } from '../models/handling-notes.model';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
import { GenerateMessageTemplateComponent } from '../ground-stops/generate-message-template.component';
import { TripAuditDialogComponent } from '../ground-stops/trip-audit-dialog.component';
import { DatePipe } from '@angular/common';
import { TemplateService } from '../services/template.service';
import { EmailInternalDialogComponent } from '../messages/email-internal-dialog.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TripLegModel } from '../models/trip-leg.model';
import { FuelService } from '../services/fuel.service';
import { FuelTaskModel } from '../models/fuel-task.model';
import { FuelClientMarkupModel } from '../models/fuel-client-markup.model';
import { TaxesAndFeesModel } from '../models/taxesandfees.model';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { AirportService } from '../services/airport.service';
import { TaxesAndFeesComponent } from '../taxesandfees/taxesandfees.component';
import { FuelQuoteHistoryDialogComponent } from './fuel-quote-history-dialog.component';
import { TripMessageCenterComponent } from '../ground-stops/trip-message-center.component';
import { LocationMetricsComponent } from '../fuelmenu/location-metrics.component';
import { FuelQuoteSummaryComponent } from './fuel-quote-summary.component';
import { FuelQuoteSummaryHistoryComponent } from './fuel-quote-summary-history.component';
import { FuelPricingSummaryComponent } from '../fuelmenu/fuel-pricing-summary.component';

const datepipe: DatePipe = new DatePipe('en-US')


@Component({
  selector: 'app-fuel-task-edit-dialog',
  templateUrl: './fuel-task-edit-dialog.component.html',
  styleUrls: ['./fuel-task-edit-dialog.component.css']
})

export class FuelTaskEditDialogComponent implements OnInit {

  dialogTitle: string;
  customerGUID: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  //maxGroundStopSequenceID: number;
  //currentGroundStopSequenceID: number;
  tripCodeGUID: string;
  icao: string;
  iata: string;
  serviceTypeID: number;
  serviceTypeDesc: string;
  //boxHeight: string;
  vendorReq: boolean;

  fuel_task_edit_form: UntypedFormGroup;
  loading = false;
  loadingDoc = false;
  submitted = false;
  errMsg: string;
  errMsg2: string;
  msg: string;
  showSpin: boolean = true;
  showSpinFuel: boolean = true;
  icao_iata: string;
  airportName: string;
  airportCountry: string;
  arrivalDateTime: string;
  departureDateTime: string;
  arrivalDateTimeLocal: string;
  departureDateTimeLocal: string;
  nextICAO_IATA: string;
  nextAirportName: string;
  nextCountry: string;
  nextCountryGUID: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  countryGUID: string;
  lead_time: string;
  lead_datetime: string;
  lead_timeChanged: boolean = false;
  due_date: string;
  due_datetime: string;
  due_dateChanged: boolean = false;
  selectedTaskStatusGUID: string;
  taskStatusList: TripTaskStatusModel[];
  acctStatusList: AccountingStatusModel[];
  selectedAcctStatusGUID: string;
  selectedTabName: string;
  internal_note: string;
  quote_note: string;
  supplier_note: string;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;

  fuelPricingList: FuelTaskModel[];
  fuelQuoteList: FuelTaskModel[] = [];
  fuelClientMarkup: FuelClientMarkupModel = null;
  intoPlaneAgentList: VendorModel[];
  leadTime_Min_Value: number;
  dueDate_Min_Value: number;
  selectedFuelSupplierGUID: string;
  //selectedIntoPlaneAgentName: string;
  selectedFuelSupplierName: string;
  hasExistingDocument: boolean = false;
  serviceSubTitle: string;
  tripCode: string;
  hasTaskAudit: boolean;
  isRevisionRequired: boolean;
  isRevisionRequiredValue: number;

  //displayedColumn: string[] = ['personName'];
  isModified: boolean = false;
  taskDocumentList: GroundStopTaskDocumentModel[];
  maxTempDocId: number = 0;
  allowedFileType: string;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  docSize: string;

  allowedFileTypeList: string[];
  maxFileSize: number;
  maxFileUploadNumber: number;
  showSuccessMsg: boolean = false;
  serviceArrangementList: ServiceArrangementModel[];
  selectedServiceArrangementID: number;
  displayedColumnPrice: string[] = ['fuel_supplier', 'into_plane', 'effective_date', 'expiry_date', 'quantity', 'base_fuel', 'taxes', 'commissions', 'markup', 'exchange_rate_markup', 'total_cost', 'total_all', 'total_dom', 'option'];
  displayedColumnQuote: string[] = ['quantity', 'base_fuel', 'taxes', 'commissions', 'markup', 'exchange_rate_markup', 'total_cost', 'total_all', 'total_dom', 'remove'];
  displayedColumnPastQuote: string[] = ['modified', 'fuel_supplier', 'into_plane', 'expiry_date', 'status', 'option'];
  hasPastQuote: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  userType: string;
  nextICAO: string;
  prevICAO: string;
  registration: string;
  lockdownTrip: boolean = false;
  vendorContacts: VendorContactModel[];
  handlingNotes: HandlingNotesModel[];
  loadingWord: boolean = false;
  loadingPDF: boolean = false;
  revision_counter: number = 0;
  flightBriefGroundStopGUIDs: string[] = [];
  groundStopList: TripLegModel[] = [];
  selectedGroundStopList: TripLegModel[] = [];
  checkFlightBriefStops: UntypedFormControl;
  weatherChartIds: string[] = [];
  loadingFlightBrief: boolean = false;
  serviceType_LeadTime: string = "";
  serviceType_LeadTime_Min: string = "";
  serviceType_DueDate: string = "";
  serviceType_DueDate_Min: string = "";
  serviceType_CalculateFrom: string = "";
  showOrginalLeadTimeDueDate: boolean = false;

  leg: string;
  nextGroundStopGUID: string;

  selected = new UntypedFormControl(0);
  selectedNotams = new UntypedFormControl(0);
  businessRuleID: number = 0;
  aircraftGUID: string;
  avoidList: GroundStopTaskModel[];
  avoidLocation: boolean = false;
  avoidLocationMsg: string = "";
  avoidBusinessRuleID: number = 0;
  upPII: boolean = false;
  upFuel: boolean = false;
  methodOfPayment: string = "";
  customsVendorList: VendorModel[];
  depGroundStopGUID: string = "";
  destroyed = new Subject<void>();
  maxSize: number;
  taskLabel: string = "";
  displayNameMap = new Map([
    [Breakpoints.XSmall, 10],
    [Breakpoints.Small, 12],
    [Breakpoints.Medium, 14],
    [Breakpoints.Large, 16],
    [Breakpoints.XLarge, 26],
  ]);
  totalMessageAttachmentFileSize: number = 0;
  immediateAction: number = 0;
  clientSetpupStatusGUID: string = "73abb6e5-ef14-4c01-b390-089f658a819f";
  onHoldText: string = "";
  clientName: string;
  farTypeID: number;
  defaultEmail: string;
  relatedGroundStopGUID: string;
  serviceLocation: string;
  arrivalDate: Date;
  selectedTaxesAndFees: TaxesAndFeesModel[];
  clickedRows = new Set<FuelTaskModel>();
  @ViewChild(MatTable) table: MatTable<any>;
  showExpired: boolean = false;
  taxesAndFeesXML: string;
  priceTaxesAndFeesXML: string;
  airportNotes: string;
  showAirportNotes: boolean = false;
  vendorList: VendorModel[];
  hasDomestic: boolean = false;
  cityName: string;
  priceMsg: string = "";
  taskColor: string = "";
  hasCommissions: boolean = false;
  gsInfo: GroundStopModel;
  showPriceTax: boolean = false;
  taxesIncluded: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<FuelTaskEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService,
    private readonly _dialogService: DialogService,
    private readonly _fuelService: FuelService, private readonly _airportService: AirportService,
    private readonly _templateService: TemplateService, breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.maxSize = this.displayNameMap.get(query) ?? 14;
          }
        }
      });
    this.groundStopGUID = _data.groundStopGUID; // either dep or arr
    this.serviceTypeID = _data.serviceTypeID;
    this.vendorReq = _data.vendorReq;
    this.dialogTitle = _data.dialogTitle;
    //this.currentGroundStopSequenceID = _data.currentGroundStopSequenceID;
    //this.maxGroundStopSequenceID = _data.maxGroundStopSequenceID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.icao = _data.icao;
    this.groundStopTaskGUID = _data.groundStopTaskGUID;
    //this.boxHeight = _data.boxHeight.toString() + "em";
    this.serviceTypeDesc = _data.serviceTypeDesc;
    this.customerGUID = _data.customerGUID;
    this.leg = _data.leg;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.depGroundStopGUID = _data.depGroundStopGUID;
    this.clientName = _data.clientName;
  }

  getAllData(): Observable<any[]> {
    let request = new GroundStopModel();
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    let getGSResponse = this._groundStopAdvancedService.getGroundStopForTaskEditBygsId<ResponseModel<GroundStopModel>>(request);
    let getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    let getAcctStatusResponse = this._groundStopAdvancedService.getAccountingStatusList();
    let req2 = new GroundStopTaskModel();
    req2.groundStopTaskGUID = this.groundStopTaskGUID;
    req2.groundStopGUID = this.groundStopGUID
    let getTaskResponse = this._groundStopAdvancedService.getGroundStopTaskBygstId(req2);
    let getTaskDocResponse = this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId(req2);
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();


    let getServiceArrangementResponse;
    if (this.vendorReq == true || this.serviceTypeID == 56) {
      getServiceArrangementResponse = this._groundStopAdvancedService.getServiceArrangementList();
    }
    else {
      getServiceArrangementResponse = of(null);
    }

    return forkJoin([getGSResponse, getTaskStatusResponse, getAcctStatusResponse, getTaskResponse, getTaskDocResponse,
      getAllowedDocTypeResponse,
      getServiceArrangementResponse]);

  }

  getFuelData(): Observable<any[]> {
    let fuel = new FuelTaskModel()
    fuel.arrivalDateTime = datepipe.transform(this.arrivalDate, 'MM/dd/yyyy');
    fuel.icao = this.icao;
    fuel.customerGUID = this.customerGUID;
    fuel.farTypeID = this.farTypeID;
    fuel.showExpired = this.showExpired;
    fuel.groundStopTaskGUID = this.groundStopTaskGUID;
    let getFuelPricingResponse = this._fuelService.getFuelPricingListForFuelTask(fuel);
    //let getFuelMarkupResponse = this._fuelService.getFuelClientMarkupByLocation(fuel);
    let getFuelQuoteResponse = this._fuelService.getFuelQuoteBygstID(this.groundStopTaskGUID, false);
    let getFuelNoteResponse = this._airportService.getAirportNotesByICAONoteType(this.icao, 75);

    return forkJoin([getFuelPricingResponse, getFuelQuoteResponse, getFuelNoteResponse])
  }
  async ngOnInit() {
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (localStorage.getItem('up.fuel') != 'true') {
      this.upFuel = false;
      this.selectedTabName = 'quote';
    }
    else {
      this.upFuel = true;
      this.selectedTabName = "pricing";
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }

    this.showSpin = true;
    this._authService.updateAccessTime();
    this.allowedFileType = ""; // new Array(GlobalConstant.allowedFileTypeList).toString().split(",").join(", ");
    this.errMsg = "";
    this.errMsg2 = "";
    this.msg = "";
    this.tripCode = "";
    this.icao_iata = "";
    this.airportName = "";
    this.airportCountry = "";
    this.arrivalDateTime = "";
    this.departureDateTime = "";
    this.arrivalDateTimeLocal = "";
    this.departureDateTimeLocal = "";
    this.nextICAO_IATA = "";
    this.nextAirportName = "";
    this.nextCountry = "";
    this.aircraftManufacturer = "";
    this.aircraftModel = "";
    this.aircraftInfo = "";
    this.lead_time = "";
    this.lead_datetime = "";
    this.lead_timeChanged = false;
    this.due_date = "";
    this.due_datetime = "";
    this.due_dateChanged = false;
    this.taskStatusList = [];
    this.acctStatusList = [];
    this.selectedTaskStatusGUID = "";
    this.selectedAcctStatusGUID = "";
    this.internal_note = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.fuelPricingList = [];
    this.intoPlaneAgentList = [];
    this.selectedFuelSupplierGUID = "";
    this.selectedFuelSupplierName = "";
    this.leadTime_Min_Value = 0;
    this.dueDate_Min_Value = 0;
    this.fileList = [];
    this.taskDocumentList = [];
    this.maxTempDocId = 0;
    this.hasExistingDocument = false;
    this.allowedFileTypeList = [];
    this.maxFileSize = 0;
    this.maxFileUploadNumber = 0;
    this.serviceSubTitle = "";
    this.showSuccessMsg = false;
    this.nextICAO = "";
    this.prevICAO = "";
    this.registration = "";
    this.serviceArrangementList = [];
    this.selectedServiceArrangementID = 0;
    this.hasTaskAudit = false;
    this.isRevisionRequiredValue = 0;
    this.isRevisionRequired = false;
    this.avoidList = [];
    this.customsVendorList = [];
    this.totalMessageAttachmentFileSize = 0;
    this.immediateAction = 0;
    this.defaultEmail = "";
    this.cityName = "";

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    await this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          //let this.gsInfo = new GroundStopModel();
          this.gsInfo = responses[0].model;
          this.tripCode = this.gsInfo.tripCode;
          this.icao = this.gsInfo.icao;
          this.iata = this.gsInfo.iata;
          this.icao_iata = this.gsInfo.icaO_IATA;
          this.airportName = this.gsInfo.airportName;
          this.airportCountry = this.gsInfo.airportState_CountryCode;
          this.arrivalDateTime = this.gsInfo.arrivalDateTextUTC;
          this.departureDateTime = this.gsInfo.departureDateTextUTC;
          this.arrivalDateTimeLocal = this.gsInfo.arrivalDateTextLocal;
          this.departureDateTimeLocal = this.gsInfo.departureDateTextLocal;
          this.nextICAO_IATA = this.gsInfo.nextICAO_IATA;
          this.nextAirportName = this.gsInfo.nextAirportName;
          this.nextICAO = this.gsInfo.nextICAO;
          this.nextCountry = this.gsInfo.nextAirportCity_CountryCode;
          this.nextCountryGUID = this.gsInfo.nextCountryGUID.toLowerCase();
          this.prevICAO = this.gsInfo.prevICAO;
          this.registration = this.gsInfo.registration;
          this.aircraftManufacturer = this.gsInfo.aircraftManufacturer;
          this.aircraftModel = this.gsInfo.aircraftModel;
          this.aircraftInfo = this.gsInfo.aircraftInfo;
          this.countryGUID = this.gsInfo.countryGUID;
          this.lockdownTrip = this.gsInfo.lockdownTrip;
          this.aircraftGUID = this.gsInfo.aircraftGUID;
          this.farTypeID = this.gsInfo.farTypeID;
          this.arrivalDate = this.gsInfo.arrivalDateUTC;
          this.vendorList = this.gsInfo.vendorList;
          this.cityName = this.gsInfo.cityName;
          this.clientName = this.gsInfo.customerName;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.taskStatusList = responses[1].model;

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          let tempacctStatusList: AccountingStatusModel[];
          tempacctStatusList = [];
          tempacctStatusList = responses[2].model;
          //this.acctStatusList = responses[2].model;
          this.acctStatusList = tempacctStatusList.filter(x => x.isGTSFlag == true);

        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          if (responses[3].model != null) {
            let gst = new GroundStopTaskModel();
            gst = responses[3].model;
            //this.serviceTypeID = gst.serviceTypeID;
            this.serviceTypeDesc = gst.serviceTypeDescription;
            this.modifiedBy = gst.modifiedBy;
            this.modifiedDate = gst.modifiedDate;

            if (gst.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
            //this.vendorReq = gst.vendorRequired;
            this.selectedTaskStatusGUID = gst.taskStatusGUID.toLowerCase();
            this.taskColor = this.taskStatusList.filter(x => x.taskStatusGUID.toLowerCase() == this.selectedTaskStatusGUID)[0].hexColor;

            this.lead_time = gst.leadTime_Min;
            this.due_date = gst.dueDate_Min;
            this.lead_datetime = gst.lead_DateTimeDisplay;
            this.due_datetime = gst.due_DateDisplay;
            this.hasTaskAudit = gst.hasTaskAudit;
            this.isRevisionRequiredValue = gst.isRevisionRequiredValue;
            if (this.isRevisionRequiredValue != 0) {
              this.isRevisionRequired = true;
            }
            this.serviceType_LeadTime = gst.serviceType_LeadTime;
            this.serviceType_LeadTime_Min = gst.serviceType_LeadTime_Min;
            this.serviceType_DueDate = gst.serviceType_DueDate;
            this.serviceType_DueDate_Min = gst.serviceType_DueDate_Min;
            this.serviceType_CalculateFrom = gst.serviceType_CalculateFrom;
            this.internal_note = gst.internalNote;
            this.defaultEmail = gst.defaultEmail;
            this.businessRuleID = gst.businessRulesID;
            this.relatedGroundStopGUID = gst.relatedGroundStopGUID;
            this.serviceLocation = gst.serviceLocation;

            if (gst.immediateActionRequired) {
              this.immediateAction = 1;
            }
            else {
              this.immediateAction = 0;
            }
            if (gst.serviceLocation == 'D' && gst.departureOnHold) {
              this.onHoldText = "On Hold";
            }
            else {
              if (gst.serviceLocation == 'A' && gst.arrivalOnHold) {
                this.onHoldText = "On Hold";
              }
            }

            this.getFuelPrices();
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          if (responses[4].model.length > 0) {
            this.taskDocumentList = responses[4].model;
            this.hasExistingDocument = true;
          }
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          if (responses[5].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[5].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }




      this.initControls();



      this.showSpin = false;

    });

  }

  async getFuelPrices(callFrom: string = ""): Promise<void> {
    this.priceMsg = "";
    this.showSpinFuel = true;
    this.getFuelData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.fuelPricingList = responses[0].model;
          if (this.fuelPricingList.length > 0)
            this.fuelClientMarkup = this.fuelPricingList[0].clientMarkup;
          if (this.fuelPricingList.some(x => x.fuelCommissions != null))
            this.hasCommissions = true;
          else
            this.hasCommissions = false;
          if (this.fuelPricingList.length == 0)
            this.priceMsg = "No Pricing Found";
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      else {
        this.priceMsg = "No Pricing Found";

      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.fuelQuoteList = responses[1].model.filter(x => x.isActive);
          if (responses[1].model.some(x => x.groundStopTaskDocumentGUID != ""))
            this.hasPastQuote = true;
          if (this.fuelQuoteList.length > 0) {
            if (this.fuelQuoteList[0].totalPrice != this.fuelQuoteList[0].totalPriceDomestic)
              this.hasDomestic = true;
            else
              this.hasDomestic = false;
            this.quote_note = this.fuelQuoteList[0].quoteNote;
            this.supplier_note = this.fuelQuoteList[0].clientNotes;
            if (callFrom != 'expired') {

              this.selectedTabName = 'quote';
              this.f.quote_note.setValue(this.quote_note);
              this.f.supplier_note.setValue(this.supplier_note);
            }
            this.taxesIncluded = this.fuelQuoteList[0].taxesIncluded;
            if (this.fuelQuoteList[0].taxesAndFeesXML != null) {
              var parseString = require('xml2js').parseString;
              var xml = this.fuelQuoteList[0].taxesAndFeesXML;
              let self = this;
              parseString(xml, function (err, result) {
                self.taxesAndFeesXML = result.TaxesAndFees.TaxFee;

              });
            }
          }
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.airportNotes = "";
          responses[2].model.forEach(x => {
            if (this.airportNotes != "") {
              this.airportNotes += "\n";
            }
            this.airportNotes += x.airportNote;
          })
          //if (this.internal_note == "") {
          //  this.f.internal_note.setValue(this.airportNotes);
          //}
        }
      }
      this.showSpinFuel = false;
    });
  }

  initControls() {
    this.fuel_task_edit_form = this._formBuilder.group({
      lead_time: [this.lead_time, Validators.pattern("^(([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?)$")],
      //lead_datetime: [this.lead_datetime],
      due_date: [this.due_date, Validators.pattern("^([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?$")],
      //due_datetime: [this.due_datetime],
      task_status_select: [this.selectedTaskStatusGUID],
      internal_note: [{ value: this.internal_note, disabled: !this.upFuel }],
      quote_note: [{ value: this.quote_note, disabled: !this.upFuel }],
      supplier_note: [this.supplier_note],
      record_status: this.isActive,
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      document: [null],
      isRevisionRequired: [this.isRevisionRequired],
      /*revision_counter: [this.revision_counter],*/
      immediateAction: this.immediateAction
    });

  }

  get f() { return this.fuel_task_edit_form.controls; }


  leadTimeChange(e: any) {
    if (e.target.value != "") {
      this.lead_timeChanged = true;
      if (this.f.lead_time.errors) {
        return;
      }
      this.lead_timeChanged = false;
      this.calculateLeadTime_DueDate('l', e.target.value);
    }
    else {
      //this.f.lead_datetime.setValue('');
      this.leadTime_Min_Value = 0;
    }
  }

  dueDateChange(e: any) {
    if (e.target.value != "") {
      this.due_dateChanged = true;
      if (this.f.due_date.errors) {
        return;
      }
      this.due_dateChanged = false;
      this.calculateLeadTime_DueDate('d', e.target.value);
    }
    else {
      //this.f.due_datetime.setValue('');
      this.dueDate_Min_Value = 0;
    }
  }

  calculateLeadTime_DueDate(type: string, s: string) {
    if (type == 'l') {
      //this.f.lead_datetime.setValue('');
    }
    if (type == 'd') {
      //this.f.due_datetime.setValue('');
    }
    if (s != "") {
      let d = 0;
      s = s.toLowerCase();
      var i = -1;
      i = s.indexOf('d');
      if (i > 0) {
        let sd = s.substring(0, i);
        if (Number.isInteger(Number(sd))) {
          d = Number(sd);
          s = s.substring(i + 1);
        }
      }

      let h = 0;
      if (s != "") {
        i = s.indexOf('h');
        if (i > 0) {
          let sh = s.substring(0, i);
          if (Number.isInteger(Number(sh))) {
            h = Number(sh);
            s = s.substring(i + 1);
          }
        }
      }

      let mi = 0;
      if (s != "") {
        i = s.indexOf('m');
        if (i > 0) {
          let sm = s.substring(0, i);
          if (Number.isInteger(Number(sm))) {
            mi = Number(sm);
            s = s.substring(i + 1);
          }
        }
      }

      let totalmin = 0;
      if (d > 0) {
        totalmin += d * 24 * 60;
      }
      if (h > 0) {
        totalmin += h * 60;
      }
      if (mi > 0) {
        totalmin += mi;
      }
      if (type == 'l') {
        this.leadTime_Min_Value = totalmin;
      }
      if (type == 'd') {
        this.dueDate_Min_Value = totalmin;
      }
      if (totalmin > 0) {
        if (type == 'l') {
          this.leadTime_Min_Value = totalmin;
        }
        if (type == 'd') {
          this.dueDate_Min_Value = totalmin;
        }
        if (this.arrivalDateTime != "") {
          let dt = new Date(this.arrivalDateTime);
          dt.setMinutes(dt.getMinutes() - totalmin);
          if (dt != null) {
            let m = dt.getMonth() + 1;
            let tempMin = dt.getMinutes();
            let stempMin = "";
            if (tempMin < 10) {
              stempMin = "0" + tempMin.toString();
            }
            else {
              stempMin = tempMin.toString();
            }
            let sdt = m.toString() + "/" + dt.getDate().toString() + '/' + dt.getFullYear().toString() + ' ' + dt.getHours().toString() + ':' + stempMin;
            //if (type == 'l') {
            //  this.f.lead_datetime.setValue(sdt);             
            //}
            //if (type == 'd') {
            //  this.f.due_datetime.setValue(sdt);
            //  this.f.due_datetime.setErrors(null);
            //}
          }
        }
        else {
          if (this.departureDateTime != "") {
            let dt = new Date(this.departureDateTime);
            dt.setMinutes(dt.getMinutes() - totalmin);
            if (dt != null) {
              let m = dt.getMonth() + 1;
              let tempMin = dt.getMinutes();
              let stempMin = "";
              if (tempMin < 10) {
                stempMin = "0" + tempMin.toString();
              }
              else {
                stempMin = tempMin.toString();
              }
              let sdt = m.toString() + "/" + dt.getDate().toString() + '/' + dt.getFullYear().toString() + ' ' + dt.getHours().toString() + ':' + stempMin;
              //if (type == 'l') {
              //  this.f.lead_datetime.setValue(sdt);              
              //}
              //if (type == 'd') {
              //  this.f.due_datetime.setValue(sdt);
              //  this.f.due_datetime.setErrors(null);
              //}
            }
          }
        }
      }

    }

  }



  clickPreviewDocument(e: any, item: GroundStopTaskDocumentModel, id: string = "") {
    this._authService.updateAccessTime();
    let request = new GroundStopTaskDocumentModel();
    if (id != "")
      request.groundStopTaskDocumentGUID = id;
    else
      request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
    if (request.groundStopTaskDocumentGUID != "") {
      this._groundStopAdvancedService.getGroundStopTaskDocumentBygstdId<ResponseModel<GroundStopTaskDocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new GroundStopTaskDocumentModel();
            obj = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
            let file = new Blob([byteArray], { type: obj.mimeType });
            var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display:none");
            a.href = fileURL;
            a.target = "_blank";
            switch (obj.fileExtension) {
              case "doc":
              case "docx":
              case "xls":
              case "xlsx":
                a.download = obj.documentName;
            }
            a.click();
            document.body.removeChild(a);

          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
      let file = new Blob([byteArray], { type: item.mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

  }

  clickRemoveDocument(e: any, item: GroundStopTaskDocumentModel) {
    this.errMsg = "";
    this.isModified = true;
    if (item.groundStopTaskDocumentGUID != "") {
      // remove doc from database, get newlist and then add unsaved file list
      let request = new GroundStopTaskDocumentModel();
      request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
      request.fuelQuoteGUID = this.fuelQuoteList[0].fuelQuoteGUID;
      this._groundStopAdvancedService.removeGroundStopTaskDocumentBygstdocId<ResponseModel<boolean>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model) {
            this.getTaskDocumentList();
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      this.fileList.forEach((x, index) => {
        if (x.tempId == item.tempId) {
          this.totalMessageAttachmentFileSize -= item.fileSize * 1024;
          this.fileList.splice(index, 1);
        }
      }
      );
      this.taskDocumentList.forEach(
        (x, index) => {
          if (x.tempId == item.tempId && x.groundStopTaskDocumentGUID == "") {
            this.taskDocumentList.splice(index, 1);
          }
        }
      )

    }

  }

  getTaskDocumentList() {
    let request = new GroundStopTaskModel()
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId<ResponseModel<GroundStopTaskDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.taskDocumentList = [];
        this.taskDocumentList = response.model;
        if (this.taskDocumentList.length > 0) {
          this.hasExistingDocument = true;
        }
        if (this.fileList.length > 0) {
          this.fileList.forEach(x => {
            let obj = new GroundStopTaskDocumentModel();
            obj.documentName = x.fileName;
            obj.fileExtension = x.fileType;
            obj.documentSizeInKB = x.fileSize;
            obj.mimeType = x.mimeType;
            //obj.bFile = x.bFile;
            obj.tempId = x.tempId;
            obj.groundStopTaskDocumentGUID = "";
            this.taskDocumentList.push(obj);
          });
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    })
  }

  clickCopyHeader() {
    let firstlinestring: string = "";
    let secondlinestring: string = "";
    // registration
    firstlinestring = "Registration"; // 12 characters
    secondlinestring = this.registration;
    if (this.registration.length > 12) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.registration, 12);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.registration, 12);
    }
    // registration end
    // trip code
    firstlinestring += "Trip Code"; // 9
    secondlinestring += this.tripCode;
    if (this.tripCode.length > 9) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.tripCode, 9);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.tripCode, 9);
    }
    // trip code end
    // business stop
    firstlinestring += "Business Stop"; // 13
    let bs: string = "";
    if (this.prevICAO != "") {
      bs = this.prevICAO + "-";
    }
    bs += this.icao;
    if (this.nextICAO != "") {
      bs += "-" + this.nextICAO;
    }
    secondlinestring += bs;
    if (bs.length > 13) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, bs, 13);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, bs, 13);
    }
    // business stop end
    // arrival
    let arr1stLineCharacterNumber = firstlinestring.length;
    let arr2ndLineCharacterNumber = secondlinestring.length;
    if (this.arrivalDateTime != "") {
      firstlinestring += "Arrival(" + this.icao + ")"; // 13
      let at: string = this.arrivalDateTime;
      secondlinestring += at;
      if (at.length > 13) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, at, 13);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, at, 13);
      }
    }
    let arr1stLineCharacterNumber2 = firstlinestring.length;
    let arr2ndLineCharacterNumber2 = secondlinestring.length;
    // arrival end
    // departure
    let dep1stLineCharacterNumber = firstlinestring.length;
    let dep2ndLineCharacterNumber = secondlinestring.length;
    if (this.departureDateTime != "") {
      firstlinestring += "Departure(" + this.icao + ")"; // 15
      let dt: string = this.departureDateTime;
      secondlinestring += dt;
      if (dt.length > 15) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, dt, 15);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, dt, 15);
      }
    }
    // departure
    // aircraft info
    firstlinestring += this.aircraftManufacturer + " " + this.aircraftModel;
    secondlinestring += this.aircraftInfo;
    // aircraft info end
    let thirdEmptySpaceNum = 0;
    let thirdLineString = "";
    if (this.arrivalDateTimeLocal != "") {
      if (arr1stLineCharacterNumber > arr2ndLineCharacterNumber) {
        thirdEmptySpaceNum = arr1stLineCharacterNumber;
      }
      else {
        thirdEmptySpaceNum = arr2ndLineCharacterNumber;
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.arrivalDateTimeLocal;
    }
    if (this.departureDateTimeLocal != "") {
      if (this.arrivalDateTime != "") {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber - arr1stLineCharacterNumber2 + 2;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber - arr2ndLineCharacterNumber2 + 2;
        }
      }
      else {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber;
        }
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.departureDateTimeLocal;
    }

    let s: string;
    s = firstlinestring + "\n" + secondlinestring;
    if (thirdLineString != "") {
      s += "\n" + thirdLineString;
    }
    return s;

  }

  getFirstLineString(firstLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (localString.length - headerCharacterNumber); i++) {
      firstLinesString += " ";
    }
    firstLinesString += "  ";
    return firstLinesString;
  }

  getSecondLineString(secondLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (headerCharacterNumber - localString.length); i++) {
      secondLinesString += " ";
    }
    secondLinesString += "  ";
    return secondLinesString;
  }

  clickSave(callFrom: string = "") {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.errMsg2 = "";
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.fileList.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      return;
    }
    if (this.fileList.length == 0 && this.taskDocumentList.length == 0 && this.f.task_status_select.value.toLowerCase() == 'ae2ee6b7-437a-4f5a-9bdb-0e0a810de2a8') {
      this.errMsg2 = "Cannot mark confirmed without a quote document.";
      return;
    }

    if (this.totalMessageAttachmentFileSize >= this.maxFileSize) {
      this.errMsg = "The total file size over the limit. Please remove some of documents.";
      return;
    }

    if (this.fuel_task_edit_form.invalid || this.errMsg != "") {
      return;
    }

    if (this.f.lead_time.value != "" && this.leadTime_Min_Value == 0) {
      this.calculateLeadTime_DueDate('l', this.f.lead_time.value);
    }
    if (this.f.due_date.value != "" && this.dueDate_Min_Value == 0) {
      this.calculateLeadTime_DueDate('d', this.f.due_date.value);
    }

    let request = new GroundStopTaskModel();

    request.groundStopGUID = this.groundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;

    request.fuelIntoPlaneAgentName = "";
    if (this.fuelQuoteList.length > 0)
      request.fpiConfirmationReference = this.fuelQuoteList[0].reportID;


    request.isActive = true;
    if (this.f.record_status.value == "1")
      request.isActive = true;
    else
      request.isActive = false;
    request.taskStatusGUID = this.f.task_status_select.value;
    //request.accountingStatusGUID_TP = this.f.acct_status_tp_select.value;
    request.leadTime_Min = this.f.lead_time.value;
    request.leadTime_Min_Value = this.leadTime_Min_Value;
    //  request.fpiConfirmationReference = this.f.confirmation_number.value;
    //if (this.serviceTypeID != 35)
    // request.serviceTime = this.f.service_time.value;
    request.dueDate_Min = this.f.due_date.value;
    request.dueDate_Min_Value = this.dueDate_Min_Value;

    if (this.f.isRevisionRequired.value == true) {
      request.isRevisionRequiredValue = this.isRevisionRequiredValue;
    }
    else {
      request.isRevisionRequiredValue = 0;//this.f.isRevisionRequired.value;
    }

    request.revisionCounter = 0; //Number(this.f.revision_counter.value);
    request.internalNote = this.f.internal_note.value;

    request.groundStopTaskDocumentList = [];

    if (this.fileList.length > 0) {
      this.fileList.forEach(x => {
        let obj = new GroundStopTaskDocumentModel();
        obj.documentName = x.fileName;
        obj.fileExtension = x.fileType;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.groundStopTaskDocumentGUID = "";
        request.groundStopTaskDocumentList.push(obj);
      });
    }


    request.immediateActionRequired = false;
    if (this.f.immediateAction.value == 1) {
      request.immediateActionRequired = true;
    }
    this.loading = true;

    request.isAttachmentIcon = (
      (request.groundStopTaskDocumentList?.filter(doc => doc.isInternal === false).length > 0) ||
      (this.taskDocumentList?.filter(doc => doc.isInternal === false).length > 0)
    ) ? true : false;
    request.isInternalAttachmentIcon = (
      (request.groundStopTaskDocumentList?.filter(doc => doc.isInternal === true).length > 0) ||
      (this.taskDocumentList?.filter(doc => doc.isInternal === true).length > 0)
    ) ? true : false;
    
    request.isInternalNoteIcon = (request.internalNote.trim() != '' && request.internalNote.trim() != null) ? true : false;


    this.saveData(request).subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200") {
          if (responses[0].model) {
            this.fileList = [];
            this.maxTempDocId = 0;
            this.errMsg = "";
            this.errMsg2 = "";
            this.getTaskDocumentList();
            //this._dialogService.openAlertDialog("");
            this.isModified = true;
            let d = new Date();
            let m = d.getMonth() + 1;
            let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
            this.modifiedBy = localStorage.getItem('un');
            this.modifiedDate = dt;
            this.showSuccessMsg = true;


          }
          else {
            //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
            this.errMsg2 = "Failed to save this record at this time. Please try later.";
          }
        }
        else {
          if (responses[0].code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.errMsg2 = "Failed to save the record. Please try again later.";
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200") {
          this.showSuccessMsg = true
          this.hasTaskAudit = true;
          if (callFrom == 'quote') {
            this.selectedTabName = 'quote';
            this.fuelQuoteList = responses[1].model;
            this.quote_note = this.fuelQuoteList[0].quoteNote;
            this.internal_note = this.fuelQuoteList[0].internalNotes;
            this.supplier_note = this.fuelQuoteList[0].clientNotes;
            this.fuelQuoteList[0].modifiedBy = this.modifiedBy;
            this.fuelQuoteList[0].modifiedDate = this.modifiedDate;
            if (this.f.internal_note.value.indexOf(this.internal_note) == -1)
              this.f.internal_note.setValue(this.f.internal_note.value + this.f.internal_note.value != "" ? "\r\n" : "" + this.internal_note);
            this.f.quote_note.setValue(this.quote_note);
            this.f.supplier_note.setValue(this.supplier_note);
            if (Number(this.fuelQuoteList[0].totalPrice) != Number(this.fuelQuoteList[0].totalPriceDomestic))
              this.hasDomestic = true;
            else
              this.hasDomestic = false;
            this.taxesIncluded = this.fuelQuoteList[0].taxesIncluded;
            if (this.fuelQuoteList[0].taxesAndFeesXML != null) {
              var parseString = require('xml2js').parseString;
              var xml = this.fuelQuoteList[0].taxesAndFeesXML;
              let self = this;
              parseString(xml, function (err, result) {
                self.taxesAndFeesXML = result.TaxesAndFees.TaxFee;
              });

            }
          }
        }
        else {
          if (responses[1].code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.showSuccessMsg = false;
            this.errMsg2 = "Failed to save the record. Please try again later.";
          }
        }
      }
      if (this.showSuccessMsg) {
        setTimeout(() => {
          this.showSuccessMsg = false;

        }, 1000); //change to 1 second
      }
      this.loading = false;
    });

  }

  clickTab(s: string) {
    if (this.selectedTabName != s) {
      this.selectedTabName = s;

    }
  }


  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  generateMessageTemplate() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.data = { gstId: this.groundStopTaskGUID, gsId: this.groundStopGUID, tripCodeId: this.tripCodeGUID, serviceTypeId: this.serviceTypeID, v: this._authService.getCurrentTimeNumber(), farTypeID: this.farTypeID };
    this._dialog.open(GenerateMessageTemplateComponent, config);
  }

  clickAudit() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: "", aircraftGUID: "", tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID,
      groundStopTaskGUID: this.groundStopTaskGUID, pastHours: 0,
      userName: "", v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }


  clickEmail() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    let taskGroundStopGUID: string = "";
    if (this.groundStopGUID.toLowerCase() == this.nextGroundStopGUID.toLowerCase()) {
      taskGroundStopGUID = this.nextGroundStopGUID
    }
    else {
      taskGroundStopGUID = this.groundStopGUID
    }
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.depGroundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, nextGroundStopGUID: this.nextGroundStopGUID, serviceTypeID: this.serviceTypeID,
      aircraftGUID: this.aircraftGUID, customerGUID: this.customerGUID, taskGroundStopGUID: taskGroundStopGUID, v: this._authService.getCurrentTimeNumber(),
      fromTaskEditDaialog: true, clientName: this.clientName, tripCode: this.tripCode, leg: this.leg, serviceDesc: this.serviceTypeDesc,
      servcieStatus: this.taskStatusList.filter(s => s.taskStatusGUID == this.selectedTaskStatusGUID)[0].taskStatusDescription, farTypeID: this.farTypeID, defaultEmail: this.defaultEmail, lockdownTrip: this.lockdownTrip
    };

    const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        this.isModified = true;
      }

    });
  }

  updateMarkup(e: any, item: FuelTaskModel, isQuote: boolean) {
    if (item.clientMarkup == null) {
      item.clientMarkup = new FuelClientMarkupModel()
    }
    item.clientMarkup.markupAmt = Number(e.target.value);
    item.markupAmount = Number(e.target.value);
    if (isQuote) {
      item.clientPrice = item.priceInUSD + item.markupAmount + item.fuelCommissionAmount;
      item.totalPrice = item.clientPrice + item.taxAmount;
      item.totalPriceDomestic = item.clientPrice + item.taxAmountDomestic;
    }
    this.table.renderRows();

  }

  createFuelQuote(item: FuelTaskModel) {
    let msg = "Create Fuel Quote?"
    if (this.fuelQuoteList.length > 0) {
      msg += "\nThis will replace the existing quote."
    }
    this.confirmDialogRef = this._dialogService.openConfirmDialog(msg);
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (!result) {
        return;
      }
      else {
        this.fuelPricingList.forEach(x => {
          if (x.fuelSupplierGUID == item.fuelSupplierGUID && x.intoPlaneAgent.toLowerCase() == item.intoPlaneAgent.toLowerCase())
            x.selected = true;
          else
            x.selected = false;
        });
        this.fuelQuoteList = this.fuelPricingList.filter(x => x.selected);
        this.fuelQuoteList.every(x => x.fuelQuoteGUID = null);
        this.fuelQuoteList.every(x => x.quoteNote = x.clientNotes);
        this.fuelQuoteList.every(x => x.clientPrice = x.priceInUSD + x.fuelCommissionAmount + x.markupAmount);
        this.f.quote_note.setValue(this.fuelQuoteList[0].clientNotes);
        this.clickSave("quote");

      }
    });
  }

  openVendorDetails(vendorGUID: string) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    dialogConfig.data = {
      vendorId: vendorGUID,
      icao: this.icao
    };
    this._dialog.open(VendorDetailsComponent, dialogConfig);

  }

  addNewTax(callFrom: string) {

    let s = "";
    //  const dialogConfig = new MatDialogConfig();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    let supplier;
    let itp;
    if (callFrom == 'quote') {
      supplier = this.fuelQuoteList[0].fuelSupplierGUID;
      itp = this.fuelQuoteList[0].intoPlaneAgent;
    }
    else {
      this.clickedRows.forEach(x => {
        supplier = x.fuelSupplierGUID;
        itp = x.intoPlaneAgent;
      });

    }


    dialogConfig.data = {
      dialogTitle: s, fromFuelTask: true, selectedIcao: 'All,' + this.icao, iscountryorairport: 'airport', selectedIATA: this.iata, selectedCountry: this.countryGUID, selectedFuelSupplier: 'allfuelsuppliers,' + supplier, selectedIntoPlaneAgent: 'All;' + itp
    };



    const dialogRef = this._dialog.open(TaxesAndFeesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (callFrom == "quote") {
          let fuel = new FuelTaskModel()
          fuel.arrivalDateTime = datepipe.transform(this.arrivalDate, 'MM/dd/yyyy');
          fuel.airportID = this.fuelQuoteList[0].airportID;
          fuel.farTypeID = this.farTypeID;
          fuel.fuelSupplierGUID = this.fuelQuoteList[0].fuelSupplierGUID;
          fuel.intoPlaneAgent = this.fuelQuoteList[0].intoPlaneAgent
          this._fuelService.getFuelTaxesAndFeesBySupplier<ResponseModel<TaxesAndFeesModel[]>>(fuel).subscribe(response => {
            if (response != null) {
              if (response.code == "200" && response.message == "") {
                this.fuelQuoteList.every(x => x.taxesAndFees = response.model);
                this._fuelService.saveFuelQuote<ResponseModel<FuelTaskModel[]>>(this.fuelQuoteList).subscribe(response => {
                  if (response != null) {
                    if (response.code == "200" && response.message == "") {
                      this.fuelQuoteList = response.model;
                      if (Number(this.fuelQuoteList[0].totalPrice) != Number(this.fuelQuoteList[0].totalPriceDomestic))
                        this.hasDomestic = true;
                      else
                        this.hasDomestic = false;
                      this.taxesIncluded = this.fuelQuoteList[0].taxesIncluded;
                      if (this.fuelQuoteList[0].taxesAndFeesXML != null) {
                        var parseString = require('xml2js').parseString;
                        var xml = this.fuelQuoteList[0].taxesAndFeesXML;
                        let self = this;
                        parseString(xml, function (err, result) {
                          self.taxesAndFeesXML = result.TaxesAndFees.TaxFee;

                        });
                      }
                    }
                  }
                });
              }
            }
          });
        }
        else {
          this.getFuelPrices('expired');
          this.clickedRows = new Set<FuelTaskModel>();
          this.showPriceTax = false;
        }
      }

    });
  }

  generateQuote(format: string = 'pdf') {
    this.errMsg2 = "";
    this.loadingDoc = true;
    this.fuelQuoteList.every(x => x.icao = this.icao);
    this.fuelQuoteList.every(x => x.iata = this.iata);
    this.fuelQuoteList.every(x => x.cityName = this.cityName);

    this.fuelQuoteList.every(x => x.clientName = this.clientName);
    this.fuelQuoteList.every(x => x.quoteNote = this.f.quote_note.value);
    let reportID = this.tripCode + '-' + UtilityFunctions.makeID(4).toUpperCase();
    this._fuelService.saveFuelQuote<ResponseModel<FuelTaskModel[]>>(this.fuelQuoteList).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.isModified = true;
          this.fuelQuoteList = response.model;
          this.fuelQuoteList.every(x => x.taxesAndFeesList = this.taxesAndFeesXML);
          this._templateService.generateFuelQuote(this.fuelQuoteList, this.gsInfo, reportID).subscribe(x => {
            if (x.remarks != "") {
              this.errMsg = x.remarks;
              this.loadingDoc = false;
            }
            switch (format) {
              case "word": this.loadingWord = false;
                break;
              case "pdf": this.loadingPDF = false;
                if (x.docBFile != undefined) {
                  let taskDocument = new GroundStopTaskDocumentModel();
                  taskDocument.documentName = "FuelQuote - " + this.icao + " - " + reportID + ".pdf";
                  taskDocument.fileExtension = "pdf";
                  taskDocument.documentSizeInKB = x.docSize
                  taskDocument.mimeType = "application/pdf";
                  taskDocument.bFile = x.docBFile
                  taskDocument.groundStopTaskGUID = this.groundStopTaskGUID;
                  taskDocument.tripCode = this.tripCode;
                  taskDocument.fuelQuoteGUID = this.fuelQuoteList[0].fuelQuoteGUID;
                  taskDocument.reportID = reportID;
                  this._groundStopAdvancedService.insertGroundStopTaskDocument<ResponseModel<number>>(taskDocument).subscribe(response => {
                    if (response != null) {
                      if (response.code == "200") {
                        this.getTaskDocumentList();
                        this.fuelQuoteList.every(x => x.reportID == reportID);
                        this.hasPastQuote = true;
                        this.loadingDoc = false;
                      }
                      else {
                        if (response.code == "401")
                          this._authService.signOut();
                        else {
                          this.errMsg2 = "An error has occurred.";
                          this.loadingDoc = false;
                        }
                      }
                    }

                  });
                }
                break;
            }
          });
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          else {
            this.errMsg2 = "An error occured saving the record.";
          }
        }
      }
    });

  }

  saveData(request: any): Observable<any[]> {

    let taskResponse = this._groundStopAdvancedService.updateGroundStopTaskBygstId(request);
    let fuelQuoteResponse;
    if (this.fuelQuoteList.length > 0) {
      this.fuelQuoteList.every(x => x.quoteNote = this.f.quote_note.value);
      if (this.taskDocumentList.length > 0) {
        this.fuelQuoteList.every(x => x.groundStopTaskDocumentGUID = this.taskDocumentList[0].groundStopTaskDocumentGUID);
      }
      fuelQuoteResponse = this._fuelService.saveFuelQuote(this.fuelQuoteList);
    }
    else {
      fuelQuoteResponse = of(null);
    }

    return forkJoin([taskResponse, fuelQuoteResponse]);

  }


  clickQuoteHistory() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: "", aircraftGUID: "", tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID,
      groundStopTaskGUID: this.groundStopTaskGUID, pastHours: 0,
      userName: "", v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(FuelQuoteHistoryDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  openTripMessages() {
    //  openMessages(e: any, gsItem: GroundStopModel, gstItem: GroundStopTaskModel, serviceLocation: string) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "92em";//"1000px";
    dialogConfig.width = "89em";//"1000px";
    // dialogConfig.height = "42.5em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID,
      nextGroundStopGUID: this.nextGroundStopGUID, serviceTypeID: this.serviceTypeID,
      aircraftGUID: this.gsInfo.aircraftGUID, customerGUID: this.customerGUID, groundStopTaskGUID: this.groundStopTaskGUID, hidePastStop: false,
      registration: this.registration, tripCodeText: this.tripCode, serviceLocation: 'A', includeActiveStopOnly: false,
      lockdownTrip: this.lockdownTrip
    };

    this._dialog.open(TripMessageCenterComponent, dialogConfig);

  }


  clickLocationMetrics() {

    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "43em";//"1000px";
    config.width = "51em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), icao: this.icao, searchFiltervalue: "" };
    this._dialog.open(LocationMetricsComponent, config);


  }


  manageFuelQuotes() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "60em";
    dialogConfig.panelClass = "custom-dialog-container";

    dialogConfig.data = {
      tripCodeGUID: this.tripCodeGUID,
      tripCode: this.tripCode
    };
    if (localStorage.getItem('up.fuel') == 'true') {
      const dialogRef = this._dialog.open(FuelQuoteSummaryComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result.refresh == true) {
        }
      });
    }
    else {
      const dialogRef = this._dialog.open(FuelQuoteSummaryHistoryComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result.refresh == true) {
        }
      });
    }
  }


  clickRow(row: any) {
    if (this.clickedRows.has(row)) {
      this.clickedRows.delete(row);
      this.showPriceTax = false;
    }
    else {
      this.showPriceTax = true;
      this.clickedRows = new Set<FuelTaskModel>();
      this.clickedRows.add(row);
      this.taxesIncluded = row.taxesIncluded;
      if (row.taxesAndFeesXML != null) {
        var parseString = require('xml2js').parseString;
        var xml = row.taxesAndFeesXML;
        let self = this;
        parseString(xml, function (err, result) {
          self.priceTaxesAndFeesXML = result.TaxesAndFees.TaxFee;

        });
      }
      else {
        this.priceTaxesAndFeesXML = null;
      }
    }
  }


  managePrices() {

    let s = "";
    //  const dialogConfig = new MatDialogConfig();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    let supplier;
    let itp;
    if (this.clickedRows.size > 0) {
      this.clickedRows.forEach(x => {
        supplier = x.fuelSupplierGUID;
        itp = x.intoPlaneAgent;
      });

    }

    dialogConfig.data = {
      dialogTitle: s, fromFuelTask: true, selectedIcao: this.icao, selectedFuelSupplier: supplier, selectedIntoPlaneAgent: itp
    };



    const dialogRef = this._dialog.open(FuelPricingSummaryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.getFuelPrices('expired');
        this.clickedRows = new Set<FuelTaskModel>();
        this.showPriceTax = false;

      }

    });
  }

  removePrice(item: FuelTaskModel) {
   
    this.confirmDialogRef = this._dialogService.openConfirmDialog("Remove selected price?");
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (!result) {
        return;
      }
      else {

        const index = this.fuelQuoteList.indexOf(item, 0);
        if (index > -1) {
          this.fuelQuoteList.splice(index, 1);
          this.table.renderRows();
          this._fuelService.saveFuelQuote<ResponseModel<FuelTaskModel[]>>(this.fuelQuoteList).subscribe(response => {
            if (response != null) {
              if (response.code == "200" && response.message == "") {
                this.fuelQuoteList = response.model;
                if (Number(this.fuelQuoteList[0].totalPrice) != Number(this.fuelQuoteList[0].totalPriceDomestic))
                  this.hasDomestic = true;
                else
                  this.hasDomestic = false;
                if (this.fuelQuoteList[0].taxesAndFeesXML != null) {
                  var parseString = require('xml2js').parseString;
                  var xml = this.fuelQuoteList[0].taxesAndFeesXML;
                  let self = this;
                  parseString(xml, function (err, result) {
                    self.taxesAndFeesXML = result.TaxesAndFees.TaxFee;

                  });
                }
              }
            }
          });
        }
      }
    });
  }
}
