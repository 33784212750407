import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuModel } from "../models/menu.model";
import { AuthenticateService } from '../services/authenticate.service';
import { MenuService } from '../services/menu.service';
import { Subject } from "rxjs";
import { SignInModel } from "../models/sign-in.model";


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {
  @Output() public navNodeClick = new EventEmitter();
  auth: Subject<SignInModel>;
  menus: MenuModel[];

  constructor(private readonly _authService: AuthenticateService, private readonly _menuService: MenuService) {
    this.auth = _authService.getSignIn();
  }


  ngOnInit() {
    var username = localStorage.getItem('username');
    if (username != null && username != '') {
      this._menuService.getUserMenus().subscribe(response => {
        this.menus = response;
      })
    }

    this.auth.subscribe(v => {
      if (v.login) {
        this._menuService.getUserMenus().subscribe(response => {
            this.menus = response;
        })
      }
    });

  }

  menuSelected() {
    this.navNodeClick.emit();
  }
}
