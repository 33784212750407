import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { CustomerModel } from '../models/customer.model';
import { ResponseModel } from '../models/response.model';
import { SchedulingSoftwareModel } from '../models/scheduling-software.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';
import { CKEditorConfig } from '../ckeditor5/ckeditor-config';
import * as DecoupledEditor from '../ckeditor5/ckeditor';

@Component({
  selector: 'app-customer-note',
  templateUrl: './customer-note.component.html',
  styleUrls: ['./customer-note.component.css']
})
/** customer-note component*/
export class CustomerNoteComponent implements OnInit, OnChanges {
  customer_note_form: UntypedFormGroup;
  @Input() customerId: string;
  @Output() getModifiedBy = new EventEmitter<string>();
  @Output() getModifiedDate = new EventEmitter<string>();
  errMsg: string;
  msg: string;
  showSpin: boolean;
  userType: string;
  internalNote: string;
  salesNote: string;
  submitted: boolean;
  modifiedBy: string;
  modifiedDate: string;
  showSuccessMsg: boolean = false;
  controlValueChanged: boolean = false;
  schedulingSoftwareID: number;
  schedulingSoftwareList: SchedulingSoftwareModel;
  nonStandardProcedures: string = "";
  @Output() public updateParentPage = new EventEmitter();

  public Editor = DecoupledEditor;

  public CKEConfig = CKEditorConfig.ckeditorConfigNoUploads;

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );

    const clipboardPlugin = editor.plugins.get('ClipboardPipeline');
    const editingView = editor.editing.view;

    editor.setData(this.internalNote);
  }


  isDisabled: boolean;
  @ViewChild('notesCKEditor') notesCKEditorRef: any;//ElementRef;
  @ViewChild('nonStandardNotesCKEditor') nonStandardNotesCKEditorRef: any;//ElementRef;
  
  /** customer-note ctor */
  constructor(public cd: ChangeDetectorRef, private readonly _authService: AuthenticateService, private readonly _commonService: CommonService, private readonly _dialogService: DialogService,
    private readonly _formBuilder: UntypedFormBuilder, private _route: ActivatedRoute, private _customerService: CustomerService) {

  }
    ngOnChanges(changes: SimpleChanges) {
      this.errMsg = "";
      this.msg = "";
      this.showSpin = true;
      this._authService.updateAccessTime();

      switch (localStorage.getItem('ut').toLowerCase()) {
        case "i":
          this.userType = "internal";
          break;
        case "c":
          this.userType = "customer";
          break;
        case "v":
          this.userType = "vendor";
          break;
        default:
          this.userType = "";
          break;
      }
      this.getData();
     // this.initControls()
     
      
    }
  ngOnInit() {
  }

  initControls() {
    this.customer_note_form = this._formBuilder.group({
      internal_note: this.internalNote,
      sales_note: this.salesNote,
      non_standard_procedures: this.nonStandardProcedures,
      scheduling_software:this.schedulingSoftwareID
    });
  }

  getAllData(): Observable<any[]> {
    let getSchedulingSoftwareResponse = this._commonService.getSchedulingSoftwareList<ResponseModel<SchedulingSoftwareModel>>();
    let getCustomerReponse = this._customerService.getCustomerBycId<ResponseModel<CustomerModel>>(this.customerId);    
    return forkJoin([getSchedulingSoftwareResponse, getCustomerReponse]);
  }

  getData() {
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.schedulingSoftwareList = responses[0].model;
        }
        else {
          if (responses[0].code == "401")
            this._authService.signOut();
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          let customer = new CustomerModel();
          customer = responses[1].model;
          this.internalNote = customer.internalNote.replace(/\n/g, "<br>");
          this.salesNote = customer.salesNote;
          this.schedulingSoftwareID = customer.schedulingSoftwareID;
          this.nonStandardProcedures = customer.internalNonStandardProcedures;
          this.initControls()
          this.checkFormControlValueChanged();
        }
        else {
          if (responses[1].code == "401")
            this._authService.signOut();
        }
      }
    });
  }

  checkFormControlValueChanged(): void {
    this.customer_note_form.valueChanges.subscribe(x => {
      this.controlValueChanged = true;
      this.updateParentPage.emit(true);
    });
  }

  get f() { return this.customer_note_form.controls; }

  clickSave() {
    this.submitted = true;
    let obj = new CustomerModel();
    obj.customerGUID = this.customerId;
    obj.internalNote = this.notesCKEditorRef.editorInstance.getData().replace(/<p>/gi, '<p style=\"margin:0\">');
    obj.salesNote = this.f.sales_note.value;
    obj.schedulingSoftwareID = this.f.scheduling_software.value;
    obj.internalNonStandardProcedures = this.nonStandardNotesCKEditorRef.editorInstance.getData().replace(/<p>/gi, '<p style=\"margin:0\">');
    obj.pageName = 'note';
    this._customerService.saveCustomer<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.customerId = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString().padStart(2, "0");
          this.modifiedBy = localStorage.getItem('un');
          this.modifiedDate = dt;
          this.getModifiedBy.emit(this.modifiedBy);
          this.getModifiedDate.emit(this.modifiedDate);
          this.controlValueChanged = false;
         // this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
        }
      }
    });
          
  }
}
