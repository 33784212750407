
<div style="background-color:#f8f8f8;height:100%">
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle style="display: inline-flex; justify-content: space-between;width:100%">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>Regulatory Services</h5></div>
    <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display:inline-block; white-space:pre-wrap;margin-top:.3125em;margin-left:.5em"><label style="font-size: small">{{errMsg}}</label></div>
  </div>
  <div style="margin-top:0em">
    <form *ngIf="customer_regulatory_services_edit_form" [formGroup]="customer_regulatory_services_edit_form" (ngSubmit)="clickSave()">

      <div style="display:flex; margin-top:0em; padding-left: 1em">
        <div style="margin-top:0em;background-color:white; padding-left:0.5em">
          <div style="display:inline-flex">
            <div class="form-group mr-3">
              <label for="gov_service_type" style="font-size:small">Service Type</label>
              <div style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="gov_service_type" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.gov_service_type.errors }" (change)="typeChange()">
                  <option *ngFor="let serviceType of governmentServiceList" [ngValue]="serviceType.governmentServiceTypeID">{{serviceType.governmentServiceTypeDesc}}</option>
                </select>
              </div>
              <div *ngIf="submitted && f.gov_service_type.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.gov_service_type.errors.required">Service Type is required.</div>
              </div>
            </div>
            <div class="form-group">
              <label for="arranged_by" style="font-size:small">Arranged By</label>
              <div style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="arranged_by" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.arranged_by.errors }">
                  <option value="1" selected>Flight Pro</option>
                  <option value="0">Client</option>
                </select>
              </div>
              <div *ngIf="submitted && f.arranged_by.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.arranged_by.errors.required">Arranged By is required.</div>
              </div>
            </div>
          </div>
          <div>
            <div style="display:inline-flex">
              <div class="form-group mr-2">
                <label for="serviceDate" style="font-size:small">Service Date</label>
                <div style="display:flex;margin-top:-0.35em">
                  <input type="text" [matDatepicker]="servicedatepicker" placeholder="mm/dd/yyyy" formControlName="serviceDate" #serviceDate class="form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted && f.serviceDate.errors }" style="padding: 0.25em; width: 6.5em; background-color:#eaf0fd;height: 1.5625em; font-size: small" maxlength="10" />
                  <mat-datepicker-toggle matSuffix [for]="servicedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                  <mat-datepicker #servicedatepicker></mat-datepicker>
                </div>
              </div>
              <div class="form-group mr-2">
                <label for="effectiveDate" style="font-size:small">Effective Date</label>
                <div style="display:flex;margin-top:-0.35em">
                  <input type="text" [matDatepicker]="effectivedatepicker" placeholder="mm/dd/yyyy" formControlName="effectiveDate" #effectiveDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.effectiveDate.errors }" style="padding: 0.25em; width: 6.5em; background-color:#eaf0fd;height: 1.5625em; font-size: small" maxlength="10" />
                  <mat-datepicker-toggle matSuffix [for]="effectivedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                  <mat-datepicker #effectivedatepicker></mat-datepicker>
                </div>
                <div *ngIf="submitted && f.effectiveDate.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.effectiveDate.errors.required">Effective Date is required.</div>
                </div>
              </div>
              <div class="form-group" *ngIf="f.renewal_status.value !=7">
                <label for="expirationDate" style="font-size:small">Expiration Date</label>
                <div style="display:flex;margin-top:-0.35em">
                  <input type="text" [matDatepicker]="expirationdatepicker" placeholder="mm/dd/yyyy" formControlName="expirationDate" #expirationDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.expirationDate.errors }" style="padding: 0.25em;background-color:#eaf0fd; width: 6.5em; height: 1.5625em; font-size: small" maxlength="10" />
                  <mat-datepicker-toggle matSuffix [for]="expirationdatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                  <mat-datepicker #expirationdatepicker></mat-datepicker>
                </div>
                <div *ngIf="submitted && f.expirationDate.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.expirationDate.errors.required">Expiration Date is required.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="authorization_number" style="font-size:small">{{authNumber}}</label>
            <div style="margin-top:-0.35em;">
              <input type="text" *ngIf="f" formControlName="authorization_number" style="padding: 0em;width:12em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.authorization_number.errors }" />
            </div>
            <div *ngIf="submitted && f.authorization_number.errors" class="alert alert-danger p-0 small" style="width:max-content">
              <div *ngIf="f.authorization_number.errors.required">{{authNumber}} is required.</div>
            </div>
          </div>
          <div class="form-group" *ngIf="f.gov_service_type.value==5">
            <label for="report_frequency" style="font-size:small">Reporting Frequency</label>
            <div style="margin-top:-0.35em;">
              <select *ngIf="f" formControlName="report_frequency" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.report_frequency.errors }" style="width:min-content" (change)="reportFrequencyChange()">
                <option *ngFor="let rpt of reportFrequencyList" [ngValue]="rpt.reportFrequencyID">{{rpt.reportFrequencyDescription}}</option>
              </select>
            </div>
            <div *ngIf="submitted && f.report_frequency.errors" class="alert alert-danger p-0 small">
              <div *ngIf="f.report_frequency.errors.required">Reporting Frequency is required.</div>
            </div>
          </div>

          <div style="display:inline-flex">
            <div class="form-group mr-3">
              <label for="renewal_status" style="font-size:small">Renewal Status</label>
              <div style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="renewal_status" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.renewal_status.errors }">
                  <option *ngFor="let renewal of renewalStatusList" [ngValue]="renewal.renewalStatusID">{{renewal.renewalStatusDescription}}</option>
                </select>
              </div>
              <div *ngIf="submitted && f.renewal_status.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.renewal_status.errors.required">Renewal Status is required.</div>
              </div>
            </div>
            <div class="d-inline-flex" *ngIf="f.renewal_status.value ==2">
              <div class="form-group mr-2">
                <label for="contactedDate" style="font-size:small">Contacted Date</label>
                <div style="display:flex;margin-top:-0.35em">
                  <input type="text" [matDatepicker]="contacteddatepicker" placeholder="mm/dd/yyyy" formControlName="contactedDate" #contactedDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contactedDate.errors }" style="padding: 0.25em;background-color:#eaf0fd; width: 6.5em; height: 1.5625em; font-size: small" maxlength="10" />
                  <mat-datepicker-toggle matSuffix [for]="contacteddatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                  <mat-datepicker #contacteddatepicker></mat-datepicker>
                </div>

              </div>
              <div class="form-group">
                <label for="dueDate" style="font-size:small">Due Date</label>
                <div style="display:flex;margin-top:-0.35em">
                  <input type="text" [matDatepicker]="duedatepicker" placeholder="mm/dd/yyyy" formControlName="dueDate" #dueDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dueDate.errors }" style="padding: 0.25em;background-color:#eaf0fd; width: 6.5em; height: 1.5625em; font-size: small" maxlength="10" />
                  <mat-datepicker-toggle matSuffix [for]="duedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                  <mat-datepicker #duedatepicker></mat-datepicker>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div style="margin-top:0em; padding-left: 0.5em; padding-right:.5em;margin-right:1em; background-color: white">
          <div style="font-size: small; margin-left:0em">Remarks</div>
          <div class="d-flex form-group">
            <textarea formControlName="remarks" class="form-control" maxlength=255 style="width:30em; height:7em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>

          </div>
          <div *ngIf="customerRegulatoryServiceGuid!=''" style="display:inline-flex;">
            <div class="form-group mr-2">
              <label for="reg_status" style="font-size:small">Deactive Service</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="reg_status" class="form-control form-control-sm dropdown">
                  <option value="1" selected>No</option>
                  <option value="0">Yes</option>
                </select>
              </div>
            </div>
            <div class="form-group mr-2">
              <label for="modifiedBy" style="font-size:small">Modified by</label>
              <div style="margin-top:-0.5em;">
                <input type="text" formControlName="modifiedBy" readonly class="form-control" style="padding:0em; width:8em;height:1.5625em;font-size:small" />
              </div>
            </div>
            <div class="form-group">
              <label for="modifiedDate" style="font-size:small">Modified Date</label>
              <div style="margin-top:-0.5em;">
                <input type="text" formControlName="modifiedDate" readonly class="form-control" style="padding: 0em; width: 8em; height: 1.5625em; font-size: small" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>

  </div>
  <div class="d-inline-flex">
    <div style="justify-content:flex-start;padding-left: 1em; padding-right:1em; padding-top:0.5em; padding-bottom:1em">
      <button mat-button class="btn-high" (click)="clickSave()">Save</button>&nbsp;
      <!--<button *ngIf="customerRegulatoryServiceGuid!=''" class="btn btn-primary" (click)="clickAddNew()">&nbsp;Add New Record&nbsp;</button>&nbsp;-->
      <button mat-button class="btn-low" (click)="clickClose()">Close</button>

    </div>
    <div *ngIf="showSuccessMsg" style="margin-top: .5em;">
      <app-success-message [successMsg]=""></app-success-message>
    </div>

  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>




