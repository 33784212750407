<div *ngIf="upPII" style="background-color:#f8f8f8;min-width: 71em">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3em">
      <i class="material-icons-outlined" style="font-size: x-large; color: gray">task_alt</i>
    </div>
    <div style="margin-top:0.3125em;"><h5>Services</h5></div>
  </div>
  <div>
    <form *ngIf="trip_servicelist_editor_form" [formGroup]="trip_servicelist_editor_form">
      <div style="margin-top:0em; padding-left: 1em; padding-right:1em; font-size: small">
        <div style="background-color: white; min-height:25em; padding-left:0.5em;padding-right:0.5em; padding-bottom:0.5em">
          <div style="display: flex; font-weight: bold;">
            <div style="margin-right:0.25em">Trip Code</div>
            <div style="margin-right:1em; font-weight: normal">{{trip_code}}</div>
            <div style="margin-left:2em;margin-right:0.25em">Registration</div>
            <div style="font-weight: normal">{{registration}}</div>
          </div>
          <div style="display: flex; font-weight: bold;">
            <div style="margin-right:0.25em">Route</div>
            <div style="font-weight:normal;display: flex">
              <div *ngFor="let gs of activeGroundStopList; let i=index" style="display: flex">
                <div style="display: flex">
                  <div style="display: flex">
                    <div  [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==activeGroundStopList.length-1? '': '-'}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex">
            <div *ngFor="let s of serviceClassList; let i=index" style="margin-top:0.5em; display: flex">
              <div style="display: flex; margin-right: 0.5em">
                <div>
                  <input *ngIf="f" formControlName="service_class"
                         type="radio" name="service_class"
                         [value]="s" (click)="changeServiceClass($event, s)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em;margin-left:0.25em">
                  <label>{{s.serviceClassDescription}}</label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div *ngIf="totalPageNumber>1" style="display: flex; justify-content: left; margin-top:0em; margin-left:-0.5em; padding: 0em 0em 0em 0em;">
              <mat-paginator style="border: none;font-size:medium; font-weight: bold; color:black" #paginator *ngIf="activeLegList && totalPageNumber>1" [length]="totalLegCount" [pageSize]="pageSize"
                             [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
              </mat-paginator>
            </div>
            <div style="height: 35em;overflow-y: auto;overflow-x:hidden;">
              <table mat-table *ngIf="tripServiceTypeList" [dataSource]="tripServiceTypeList" class="example-container2 mat-elevation-z8">
                <tr>
                  <!--<ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap;" [hidden]="hideSelectColumn">
                      <div style="margin-top:-0.25em;">Select</div>
                      <div style="margin-top:-0.25em;">All</div>
                      <div style="margin-top:-0.25em;">Legs</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;" [hidden]="hideSelectColumn">
                      <div style="margin-top:0.5em" class="mat-checkbox-smaller" title="Save to all future active legs if selected">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkServiceTypeChange($event, element.serviceType)" [checked]="selection.isSelected(element.serviceType)"></mat-checkbox>
                      </div>
                    </td>
                  </ng-container>-->
                  <ng-container matColumnDef="service_name">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap; text-align:left !important">
                      <div style="margin-top:-0.25em;">Services</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; min-width: 10em; text-align: left !important ">
                      {{element.serviceType.serviceTypeDescription}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="display_leg_{{l.tripLegOrder}}" *ngFor="let l of displayLegList; let i = index">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap; width:7em;" [style.background-color]="l.nextArrivalDateTimePassed?'#E9ECEF':l.departureGroundStopTypeColor">
                      <div style="margin-top:-0.25em;">LEG {{l.tripLegOrder}}</div>
                      <div style="margin-top:-0.25em;font-weight: normal">{{l.leg_ICAOs}}</div>
                      <div style="margin-top:-0.25em;font-weight: normal">D:&nbsp;{{l.departureDateTimeUTCText}}</div>
                      <div style="margin-top:-0.25em;font-weight: normal">A:&nbsp;{{l.nextArrivalDateTimeUTCText}}</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                      <div *ngIf="element.tripLegState[i]=='check_circle'" (click)="clickEditService($event, element.serviceType, l,'Edit')" style="cursor: pointer"><i class="material-icons-outlined" style="font-size: x-large; margin-top:0em;color: green;">check_circle</i></div>
                      <div *ngIf="element.tripLegState[i]=='add_circle'" (click)="clickEditService($event, element.serviceType, l, 'Add')" style="cursor: pointer"><i class="material-icons-outlined" style="font-size: x-large; margin-top:0em;color: #7dacff;">add_circle</i></div>
                    </td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
          </div>

        </div>       
      </div>
  </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
    <div style="margin-right:1em;">
      <!--<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <button type="button" mat-button class="btn-high" *ngIf="loading==false && showSuccessMsg==false && !hideSelectColumn" [disabled]="loading" (click)="clickSave(null,'')"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>SAVE</div></div></button>&nbsp;-->
      <button type="button" mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>CLOSE</div></div></button>
    </div>
    <div *ngIf="errMsg!=''" class="d-block" style="margin-left:1em; display:flex;margin-top:-0.5em;background-color:#f9d2de; padding-left:0.25em; min-width:16em;"><label style="color:black; font-size: small">{{errMsg}}</label></div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
  </div>
</div>
<!--<div *ngIf="showSpin" style="z-index:300; margin-top: -26em ; margin-left: 20em; position:absolute; min-width: 20em">
  <app-spin></app-spin>
</div>-->
<div *ngIf="showSpin" style="z-index:300; margin-top: -26em ; margin-left: 20em; position: fixed; min-width: 20em">
  <app-spin></app-spin>
</div>
