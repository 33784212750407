<div style="margin-top: 0em; padding-top: 0em;  margin-bottom: 2em; " [ngClass]="{ 'normalCss':!isDialog ,'dialogCss':isDialog  }">
  <form *ngIf="fuel_Commissions_form" [formGroup]="fuel_Commissions_form">
    <div class="divLeft">
      <div  *ngIf="!isDialog"  style="display: flex">
        <div style="margin-right:1em; display: flex;"><span style="margin-right: 0.2em;  " class="material-symbols-outlined">payments</span><h5>Fuel Commissions</h5></div>
      </div>
      <div  *ngIf="isDialog"  style="display: flex;justify-content: space-between;"  cdkDrag  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle >
        <div style="margin-right:1em; display: flex;"><span style="margin-right: 0.2em;  " class="material-symbols-outlined">payments</span><h5>Fuel Commissions</h5></div>
      
        <div> 
          <button mat-icon-button  (click)="clickOpenInNew()">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>  
          <button  *ngIf="isDialog" mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button> 
        </div>
      </div>
      
      
      <div style="background-color: #f8f8f8; padding-left: 0.25em; padding-top: 0.25em; padding-right: 0.5em; margin-top: -0.5em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; width: 100%; height: 4.5em">
        <div style="margin-left:0em;padding:0em; display: flex; ">

          


          <div><!--</div> style="min-width:32em">-->
            <div style="display: flex;margin-right:1.5em">
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:9em;" [ngClass]="selectedFuelSupplierCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickFuelSupplierFilter()">Fuel Supplier{{selectedFuelSupplierCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:10em;" [ngClass]="selectedIntoPlaneAgentCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickIntoPlaneAgentFilter()">Into Plane Agent{{selectedIntoPlaneAgentCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedCountryCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickCountryFilter()">Country{{selectedCountryCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:8em;" [ngClass]="selectedAirportCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickAirportFilter()">Airport{{selectedAirportCountText}}</button>
              </div>
 
            </div>
            <div style="display:flex; min-width: 20em">

              
              <div *ngIf="showFuelSupplierList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 0em; background-color: white; border-top: 0.0625em lightgrey solid;
              border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;
                border-radius: 0.25em; z-index: 1000; position: absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Fuel Supplier Code</div>
                  <div style="float:right" (click)="showFuelSupplierList=!showFuelSupplierList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="fuelsuppliersearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameFuelSupplier($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="fuelsupplierList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let a of filteredFuelSupplierList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div [attr.fuelSupplierShortname]="a.fuelSupplierShortname" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkFuelSupplierChange($event, a)" [checked]="a.selected">{{a.fuelSupplierShortname}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetFuelSupplier()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneFuelSupplier()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--FuelSupplier list end-->
              <!--Into Plane Agent list-->
              <div *ngIf="showIntoPlaneAgentList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 9.4em; background-color: white;
                border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;
                border-radius: 0.25em; z-index: 1000;position:absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Into Plane Agent</div>
                  <div style="float:right" (click)="showIntoPlaneAgentList=!showIntoPlaneAgentList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="intoplaneagentsearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameIntoPlaneAgent($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="intoplaneagentList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let a of filteredIntoPlaneAgentList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div [attr.intoPlaneAgent]="a.intoPlaneAgent" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkIntoPlaneAgentChange($event, a)" [checked]="a.selected">{{a.intoPlaneAgent}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetIntoPlaneAgent()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneIntoPlaneAgent()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--Into Plane Agent list end-->
              <!--country list--><!--margin-left: 32.25em;-->
              <div *ngIf="showCountryList" style="float: left; min-width: 11em; margin-top: 0.25em; background-color: white; margin-left: 19.6em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Countries</div>
                  <div style="float:right" (click)="showCountryList=!showCountryList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="country_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />  <!--(keyup)="countrySearchChange($event)"-->
                    </div>
                  </div>
                </div>
                <div *ngIf="countryList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let v of filteredCountryList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                    <div #tripCountryRefs [attr.countryGUID]="v.countryGUID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkCountryChange($event, v)" [checked]="v.selected">{{v.countryName}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetCountry()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneCountry()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--country list end-->
              <!--Airport list-->
              <div *ngIf="showAirportList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 27em; background-color: white;
               border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid;
               font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Airport ICAO</div>
                  <div style="float:right" (click)="showAirportList=!showAirportList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="airportSearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameAirport($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="airportList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let a of filteredAirportList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div #tripAirportRefs [attr.icao]="a.icao" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkAirportChange($event, a)" [checked]="a.selected">{{a.icao}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right; ">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetAirport()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneAirport()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--Airport list end-->

            </div>

          </div>

          <div style="display:flex">
            <div style="margin-top: 0.125em; margin-right: 0.5em; width: 15em; display: grid;">
              <div>
                <input type="checkbox" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)" /><label for="includeDisabledRecords" style="font-size:small">&nbsp;Show disabled records</label>
              </div>
              <div>
                <input type="checkbox" formControlName="displayExipredCommissions" (change)="displayExipredCommissionsOnChange($event)" /><label for="displayExipredCommissions" style="font-size:small">&nbsp;Display expired Commissions</label>
              </div>

              <div style="display:flex">
                <!--<div style="font-size:small; margin-top: -.25em; margin-left: 0.3125em;">{{totalRecordsText}}</div>-->
                <div *ngIf="errMsg!=''" class="d-block" style="margin-left:0.5em; display:flex;margin-top:-0.5em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
              </div>
            </div>



            <div style="margin-top:0.125em; margin-right:0.5em;width:6em">
              <div>
                <button type="button" mat-button class="btn-low" (click)="clickReset()" style="height:1.5em;width:7em">
                  <div style="display: flex;margin-top:-0.125em">
                    <div style="margin-top: 0.125em; margin-left:-0.25em">
                      <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">restart_alt</i>
                    </div>
                    <div>
                      RESET
                    </div>
                  </div>
                </button>
                <button type="button" mat-button class="btn-low" (click)="clickSubmit()" style="height:1.5em;width:7em">
                  <div style="display: flex;margin-top:-0.125em">
                    <div style="margin-top: 0.25em; margin-left:-0.25em">
                      <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>
                    </div>
                    <div>
                      REFRESH
                    </div>
                  </div>
                </button>
              </div>

              <div style="display:flex">
                <!--<div style="font-size:small; margin-top: -.25em; margin-left: 0.3125em;">{{totalRecordsText}}</div>-->
                <div *ngIf="errMsg!=''" class="d-block" style="margin-left:0.5em; display:flex;margin-top:-0.5em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
              </div>


            </div>

            <div style="margin-top:0.125em; margin-right:0.5em;">
              <div>
                <button type="button" mat-button class="btn-low"  (click)="clickRoute('fuelmenu/fuel-menu')">
                  <!--(click)="clickReset()"-->
                  <div style="display: flex;margin-top:-0.125em">
                    <div style="margin-top: 0.125em; margin-left:-0.25em">
                      <i class="material-icons" style="font-size: medium;cursor: pointer; ">arrow_back</i>
                    </div>
                    <div>
                      Return to Fuel Dashboard
                    </div>
                  </div>
                </button>
                <div>
                  <div style="width:6em"><app-spin *ngIf="showSpin"></app-spin></div>
                  <div><label *ngIf="sortedData.length==0" style="font-size:small">Select filters to view records.</label></div>
                </div>
                <!--<button type="button" mat-button class="btn-low" style="height:1.5em;">-->
                <!--(click)="clickSubmit()"-->
                <!--<div style="display: flex;margin-top:-0.125em">
      <div style="margin-top: 0.25em; margin-left:-0.25em">
        <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">download</i>
      </div>
      <div>
        Export to Excel
      </div>
    </div>
  </button>-->
              </div>
            </div>

          </div>


          <div>

          </div>

        </div>

      </div>

      <div><!--class="mt-2">-->
        <div class="d-flex justify-content-between" ><!--</div> style="margin-top: -1.05em;">-->

          <div class="d-inline-flex">
            <div *ngIf="selection.hasValue()" style=" display: flex;padding-top: 1em;  ">
              <!-- <button mat-icon-button *ngIf="selection.hasValue()" [matMenuTriggerFor]="menu" style="margin-top:-1em"><mat-icon style="font-size:medium;margin-top:-.5em;line-height:1.5">more_horiz</mat-icon></button> -->
              <button mat-button class="btn" (click)="deleteFP(true)">Delete</button>
              <button mat-button class="btn" (click)="clearStatusFP(false)">Clear Status</button>
              <button mat-button class="btn" (click)="printFP(false)">Print</button>
            </div>
          </div>
          <mat-paginator *ngIf="sortedData.length>0" style="background-color:transparent;" [pageSize]="25"
                         [showFirstLastButtons]="true"
                         [length]="totalRecords"
                         [pageSizeOptions]="[25, 50, 100]"
                         aria-label="Select page" (page)="pageEvent = pageIndexChange($event)">
          </mat-paginator>  
          <!--[pageSizeOptions]="[25, 50, 100, totalRecords]"-->
        </div>

        <div  [ngClass]="{  'dialogCss2':isDialog  }"> 
          <table mat-table [dataSource]="sortedData" matSort matSortActive="FuelCommissionsID" matSortDirection="asc" (matSortChange)="sortData($event)" class="example-container">

            <tr>
              

              <ng-container matColumnDef="Country">
                <th mat-header-cell *matHeaderCellDef   class=" ">
                  Country
                </th>
                <td mat-cell *matCellDef="let element">{{ element.countryName}}</td>
              </ng-container>
              <ng-container matColumnDef="ICAO">
                <th mat-header-cell *matHeaderCellDef   class="thStyle">
                  ICAO
                </th>
                <td mat-cell *matCellDef="let element">{{element.icao==null?'All':element.icao  }}</td>
              </ng-container>
              <ng-container matColumnDef="IATA">
                <th mat-header-cell *matHeaderCellDef  class="thStyle">
                  IATA
                </th>
                <td mat-cell *matCellDef="let element">{{element.iata==null?'All':element.iata }}</td>
              </ng-container>
              <ng-container matColumnDef="fuelSupplier">
                <th mat-header-cell *matHeaderCellDef   class=" ">
                  Fuel Supplier
                </th>
                <td mat-cell *matCellDef="let element">{{ element.fuelSupplierName==null?'All':element.fuelSupplierName}}</td>
              </ng-container>
              <ng-container matColumnDef="intoplaneagent">
                <th mat-header-cell *matHeaderCellDef   class=" ">
                  Into Plane Agent
                </th>
                <td mat-cell *matCellDef="let element">{{ element.intoPlaneAgent==null?'All':element.intoPlaneAgent}}</td>
              </ng-container>
              <ng-container matColumnDef="commissionAmt">
                <th mat-header-cell *matHeaderCellDef   class="thStyle">
                  Commission Amt
                </th>
                <td mat-cell *matCellDef="let element"  >{{ element.commissionAmt}}</td>
              </ng-container>
              <ng-container matColumnDef="EffectiveDate">
                <th mat-header-cell *matHeaderCellDef class="thStyle">
                  Effective Date
                </th>
                <td mat-cell *matCellDef="let element"> {{element.effectiveDate}} </td>
              </ng-container>

              <ng-container matColumnDef="ExpiryDate">
                <th mat-header-cell *matHeaderCellDef class="thStyle">
                  Expiry Date
                </th>
                <td mat-cell *matCellDef="let element">{{element.expiryDate}}</td>
              </ng-container>

              <ng-container matColumnDef="InternalNotes">
                <th mat-header-cell *matHeaderCellDef   class="thStyle">
                  Internal Notes
                </th>
                <td mat-cell *matCellDef="let element">
                  {{element.internalNotes}}
                </td>
              </ng-container>
              <ng-container matColumnDef="ModifiedDate">
                <th mat-header-cell *matHeaderCellDef   class="thStyle">
                  Modified Date
                </th>
                <td mat-cell *matCellDef="let element">{{element.modifiedDate}}</td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="thStyle">
                  <div style="margin-top:-0.25em;">Action</div>
                  <div><a mat-button class="btn-high grid" style="align-self:center" (click)="clickEdit($event, element);">Add</a></div>
                </th>
                <td mat-cell class="mat-cell2" *matCellDef="let element; let i=index ">
                  <a mat-button class="btn-low grid">
                    <span>Edit</span>
                  </a>
                </td>
              </ng-container>
            </tr>
            <!--<tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>-->
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row (click)="clickEdit($event, row)" *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
        <!--<div style="display: inline-flex; max-width: 25em !important; margin-top: 1em; margin-bottom: 0em">-->
          <!--*ngIf="!showNewFolderInput"-->
          <!--<button mat-button class="btn-high " style="  text-align: left; " (click)="clickEdit($event, row)">
            <i class="material-icons" style="font-size:  large; margin-top: -.2em;">add </i>
            Add
          </button>-->
          <!--<button type="button" mat-button class="btn-low" (click)="importFuelPricing()" style="margin-left: 1em;">
            <div style="display:flex">
              <i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i>
              <div>Import</div>
            </div>
          </button>-->
          <!--<button type="button" mat-button class="btn-low" (click)="clickClose()" style="margin-left: 1em;">
    <div style="display:flex">
      <i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
      <div>CLOSE</div>
    </div>
  </button>&nbsp;-->
        <!--</div>-->
      </div>
    </div>
  </form>
</div>
