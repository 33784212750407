<div class="p-2">
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div><img src="../../assets/images/FlightProLogoWhiteBG.png" style="width: 14.375em;height:5em;border:0em" /></div>
  </div>
  <div style="background-color:white;padding:.5em;font-size:small;margin-top:-.35em;margin-left:.5em;margin-right:.5em">
    <h5>Flight Pro International – Operations</h5>
    <p>Experienced, Knowledgeable, Trustworthy 24 / 7 / 365</p>
    <div><strong>Customer Support Phone</strong>: 346-415-6900</div>
    <div>
      <strong>Customer Support Email</strong>: Ops@flightprointl.com
      <div *ngIf="isInternal">
        <strong>Fuel Estimates/Release</strong>: Fuel@flightprointl.com
      </div>
    </div>
    <p></p>
    <div>
      <div><strong>Toll-Free USA/Canada</strong>: +1 866 356 1055</div>
      <div><strong>Worldwide</strong>: +1 346 415 6900</div>
    </div>
    <div>
      <div *ngIf="isInternal"><strong>Invoicing Support</strong>: Accounting@flightprointl.com</div>
      <div><strong>Sales Email Address</strong>: Sales@flightprointl.com</div>
    </div>
    <p></p>
    <div class="d-flex">
      <div>
        <div><strong>Office Address</strong></div>
        <div>
          6002 Rogerdale Rd Suite 425,
        </div>
        <div>
          Houston, TX 77072
        </div>
      </div>
      <div style="width:20em;height:20em;margin-left:3em">
        <app-azure-map-inset [markerTitle]="'Flight Pro International'" [defaultLat]="29.711157014809455" [defaultLon]="-95.56097346782617">
        </app-azure-map-inset>
      </div>
    </div>
  </div>
  <div>
    <button mat-button mat-dialog-close class="btn-low">Close</button>
  </div>
</div>
