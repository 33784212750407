import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { TripRevisionModel } from '../models/trip-revision.model';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BusinessRulesEditComponent } from '../business-rules/business-rules-edit.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopTaskClientDialogComponent } from '../ground-stop-advanced-client/ground-stop-task-client-dialog.component';
import { FlightFollowingClientDialogComponent } from '../ground-stop-advanced-client/flight-following-client-dialog.component';
import { TripMessagesClientComponent } from '../messages-client/trip-messages-client.component';

//export class TableElement {
//  legItem: TripLegModel;
//  expanded: boolean;
//}

@Component({
  selector: 'app-ground-stop-leg-tasklist-client',
  templateUrl: './ground-stop-leg-tasklist-client.component.html',
  styleUrls: ['./ground-stop-leg-tasklist-client.component.css'],

})

export class GroundStopLegTasklistClientComponent implements OnInit, OnChanges {

  @Input() requestInput: TripModel;
  //@Input() reloadChildPage: boolean;
  @Input() legItem: TripLegModel;
  @Input() refreshChildColumn: string;
  @Input() refreshChildRow: boolean;
  @Input() updateTask: boolean;
  @Input() customerGUID: string;

  request: TripModel;
  ground_stop_leg_tasklist_client_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = false;

  //recordList: TripLegModel[];
  usernameList: TripRevisionModel[] = [];
  @Output() public updateParentPage = new EventEmitter();
  depTaskList: GroundStopTaskModel[] = [];
  arrTaskList: GroundStopTaskModel[] = [];
  scrHeight: number;
  upPII: boolean = false;
  
  //displayedColumn: string[] = ['flag', 'service', 'brID', 'message_in_count', 'message_out_count'];
  //displayedColumn2: string[] = ['blank1', 'br', 'message']; // 2,2

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _tripLegService: TripLegService,
    private readonly _dialogService: DialogService
  ) {

  }

  getAllData(): Observable<any[]> {
    
    let reqD = new GroundStopModel();
    reqD.groundStopGUID = this.legItem.groundStopGUID;
    reqD.taskType = "D";
    reqD.tripTaskStatusGUIDList = this.request.tripTaskStatusGUIDList;
    reqD.serviceTypeIDList = "";//this.request.serviceTypeIDList;
    reqD.accountingStatusGUIDList = "";// this.request.accountingStatusGUIDList;
    reqD.includeDisabledTask = false;//this.request.includeDisabledTask;
    reqD.tripCodeGUID = this.legItem.tripCodeGUID;
    let depTaskResponse;
    depTaskResponse = this._tripLegService.getTaskListBygIdTaskType(reqD);

    let reqA = new GroundStopModel();
    reqA.groundStopGUID = this.legItem.nextArrivalGroundStopGUID;
    reqA.taskType = "A";
    reqA.tripTaskStatusGUIDList = this.request.tripTaskStatusGUIDList;
    reqA.serviceTypeIDList = "";//this.request.serviceTypeIDList;
    reqA.accountingStatusGUIDList = "";// this.request.accountingStatusGUIDList;
    reqA.includeDisabledTask = false;// this.request.includeDisabledTask;
    reqA.tripCodeGUID = this.legItem.tripCodeGUID;
    let arrTaskResponse;
    arrTaskResponse = this._tripLegService.getTaskListBygIdTaskType(reqA);
    return forkJoin([depTaskResponse, arrTaskResponse]);

  }


  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //if (!this.reloadChildPage && this.refreshChildColumn == "") {     
    //    return;      
    //}
    if (!this.refreshChildRow && (this.refreshChildColumn == "" || changes["updateTask"] != undefined)) {
      return;
    }
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.request = (JSON.parse(JSON.stringify(this.requestInput)));

    if (this.refreshChildColumn == "") {
      this.showSpin = true;
      this.getAllData().subscribe(responses => {
        
        if (responses[0] != null) {
          if (responses[0].code == "200" && responses[0].message == "") {
            this.depTaskList = responses[0].model;
          }
          else {
            if (responses[0].code == "401") {
              this._authService.signOut();
            }
          }
        }

        if (responses[1] != null) {
          if (responses[1].code == "200" && responses[1].message == "") {
            this.arrTaskList = responses[1].model;
          }
          else {
            if (responses[1].code == "401") {
              this._authService.signOut();
            }
          }
        }

        this.initControls();

        this.showSpin = false;
        this.refreshChildColumn = "";

      });
    }
    else {
      //this.getTripRevisionUsernameListBygIdList();
    }
  }

  initControls() {
    this.ground_stop_leg_tasklist_client_form = this._formBuilder.group({

    })
  }


  get f() { return this.ground_stop_leg_tasklist_client_form.controls; }

  clickServiceType(e: any, gstItem: GroundStopTaskModel, taskType: string) {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "54em";
    let dialogHeight = "";
    if (gstItem.serviceTypeID == 1 || gstItem.vendorRequired == true || gstItem.serviceTypeID == 35 || gstItem.serviceTypeID == 3
      || gstItem.serviceTypeID == 7 || gstItem.serviceTypeID == 20 || gstItem.serviceTypeID == 56) {

      dialogConfig.minHeight = "39.2em";
    }
    else {
      //dialogConfig.height = "37em";
      //dialogHeight = "37em";
      dialogConfig.minHeight = "37em";
    }
    //dialogConfig.height = "40em";//"500px";
    dialogConfig.disableClose = true;
    let leg: string = "";
    leg = this.legItem.departureICAO;
    leg += "-" + this.legItem.nextArrivalICAO;
    let icao: string = "";
    let gsId: string = "";
    let countryId: string = "";
    if (taskType == 'D') {
      icao = this.legItem.departureICAO;
      gsId = this.legItem.groundStopGUID;
      countryId = this.legItem.departureCountryGUID;
    }
    else {
      icao = this.legItem.nextArrivalICAO;
      gsId = this.legItem.nextArrivalGroundStopGUID;
      countryId = this.legItem.nextArrivalCountryGUID;
    }

    dialogConfig.data = {
      groundStopGUID: gsId, tripCodeGUID: this.legItem.tripCodeGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID,
      serviceTypeID: gstItem.serviceTypeID, vendorReq: gstItem.vendorRequired, countryId: countryId, icao: icao,
      serviceTypeDesc: gstItem.serviceTypeDescription, v: this._authService.getCurrentTimeNumber(),
      customerGUID: this.customerGUID, leg: leg, nextGroundStopGUID: this.legItem.nextArrivalGroundStopGUID
    };

    if (gstItem.serviceTypeID != 69 && gstItem.serviceTypeID != 70) {
      const dialogRef = this._dialog.open(GroundStopTaskClientDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          //this.showSpin = true;
          //this.getTaskListByTaskType(taskType);
          //this.updateParentPage.emit({ updateSection: 'leg' });
        }
      });
    }
    else {
      const dialogRef = this._dialog.open(FlightFollowingClientDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          //this.showSpin = true;
          //this.getTaskListByTaskType(taskType);
          //this.updateParentPage.emit({ updateSection: 'leg' });
        }
      });
    }

  }

  //openBusinessRule(brID: number) {
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.autoFocus = true;
  //  dialogConfig.disableClose = true;

  //  let businessRulesID = 0;
  //  let s = "Add Business Rule";
  //  businessRulesID = brID
  //  s = "View Business Rule " + businessRulesID.toString();
  //  let isReadOnly = true;
  //  dialogConfig.panelClass = "custom-dialog-container";
  //  dialogConfig.data = { businessRulesID: businessRulesID, title: s, readonly: isReadOnly };


  //  const dialogRef = this._dialog.open(BusinessRulesEditComponent, dialogConfig);

  //}

  openMessages(e: any, gstItem: GroundStopTaskModel, taskType: string) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container"
    let icao: string = "";
    let gsId: string = "";
    if (taskType == 'D') {
      icao = this.legItem.departureICAO;
      gsId = this.legItem.groundStopGUID;
    }
    else {
      icao = this.legItem.nextArrivalICAO;
      gsId = this.legItem.nextArrivalGroundStopGUID;
    }

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.legItem.tripCodeGUID, tripCode: this.legItem.tripCode, registration: gstItem.registration, groundStopGUID: gsId, groundStopTaskGUID: gstItem.groundStopTaskGUID, serviceTypeDescription: gstItem.serviceTypeDescription
    };

    const dialogRef = this._dialog.open(TripMessagesClientComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }


}
