export class UserPermissionModel {
  grantAccessToManageManifestProfiles: boolean;
  grantAccessCompanyProfiles: boolean;
  grantAccessAircraftProfiles: boolean;
  grantAccessSubmitTripsandRequestQuotes: boolean;
  grantAccessToMessages: boolean;
  grantAccessToManageCompanyEvents: boolean;
  grantAccessCreateTripAlerts: boolean;
  grantAccessToInvoices: boolean;
  grantAccessToHeadlineNews: boolean;
  grantAccessToReleaseEvents: boolean;
  grantAccessToLockedTrips: boolean;
  grantAccessToCompanyBusinessRules: boolean;
  grantAccessToVendorProfiles: boolean;
  grantAccessToPIIData: boolean;
  grantAccessToManageAccountGrouping: boolean;
  grantAccessToAccountGrouping: boolean;
  grantAccessToAllMessages: boolean;
  grantAccessToAPISCredentials: boolean;
  grantAccessToFuel: boolean;
  grantAccessToAirportDocuments: boolean;
  customerGroupGUID: string;
  isA: string;
  isB: string;
  isAB: string;
  grantAccessToTripCloseout: boolean;
  grantAccessSubmitToBilling: boolean;
  grantAccessToMessageTemplates: boolean;
  grantAccessToHelpGuides: boolean;
  grantAccessToAccountingDashboard: boolean;
}
