import { TripLegModel } from "./trip-leg.model";

export class ServiceTypeModel {
  serviceTypeID: number;
  serviceTypeDescription: string;
  serviceClassID: number;
  serviceClassDescription: string;
  selected: boolean;
  quantity: number;
  serviceClassSelected: boolean;
  vendorRequired: boolean;
  tripLegList: TripLegModel[];
  serviceLocation: string;
  filterGroup: number;
  filterColumn: number;
  filterOrderBy: number;
}
