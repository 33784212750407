import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticateService } from '../services/authenticate.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']

})

export class FeedbackComponent implements OnInit {
  constructor(
    private readonly _authService: AuthenticateService, private _router: Router) {
    
  }
  ngOnInit() {
    this._authService.updateAccessTime();
  }
  
}
