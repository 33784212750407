<div *ngIf="upPII" style="background-color:#f8f8f8;min-width: 30em">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3em" *ngIf="actionType=='Edit'">
      <i class="material-icons-outlined" style="font-size: xx-large; color: green">check_circle</i>
    </div>
    <div style="margin-top:0.3em" *ngIf="actionType=='Add'">
      <i class="material-icons-outlined" style="font-size: xx-large; color: #337dff">add_circle</i>
    </div>
    <div style="margin-top:0.3125em;">
      <h5>
        {{actionType + ' Service to Legs'}}
      </h5>
    </div>
  </div>
  <div>
    <form *ngIf="trip_service_editor_form" [formGroup]="trip_service_editor_form">
      <div style="margin-top:0em; padding-left: 1em; padding-right:1em; font-size: small">
        <div style="background-color: white; min-height:25em; padding-left:0.5em;padding-right:0.5em; padding-bottom:0.5em; min-width:38em">
          <div style="font-weight: bold">{{serviceTypeDescription}}</div>
          <div style="font-weight: bold">{{leg}}</div>
          <div style="height: 3em"></div>
          <div class="d-flex flex-column" style="margin-left:-1.4em; font-size: medium;">
            <div style="display: flex" *ngIf="serviceLocation=='D' || tripLegOrder==1">
              <div style="margin-left:2.95em; width:2.25em">
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" #removeFromCurrentLegRef formControlName="addToLegDep" (change)="addToLegDepChange($event)"></mat-slide-toggle>
              </div>
              <div style="margin-top: 0.25em; display: flex; width: 22em; font-size: small"><div style="margin-right:0.25em">Add</div><div style="font-weight: bold; margin-right: 0.25em">{{serviceTypeDescription}}</div><div>to the Departure</div></div>
              <div style="margin-top: 0.25em; display: flex;font-size: small">
                <div>Qty:</div>
                <div style="margin-left:0.5em; margin-top:0.25em">
                  <input type="number" min="1" max="10" matInput maxlength="2" formControlName="depQty" class="form-control inputBox" style="width:3em;border-radius:0.25em" (keyup)="depQtyChange($event)" />
                </div>
              </div>
            </div>
            <div style="display: flex" *ngIf="serviceLocation=='A'">
              <div style="margin-left:2.95em; width:2.25em">
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" #removeFromCurrentLegRef formControlName="addToLegArr" (change)="addToLegArrChange($event)"></mat-slide-toggle>
              </div>
              <div style="margin-top: 0.25em; display: flex; width: 22em; font-size: small "><div style="margin-right:0.25em">Add</div><div style="font-weight: bold; margin-right: 0.25em">{{serviceTypeDescription}}</div><div>to the Arrival</div></div>
              <div style="margin-top: 0.25em; display: flex; font-size: small">
                <div>Qty:</div>
                <div style="margin-left:0.5em; margin-top:0.25em">
                  <input type="number" min="1" max="10" matInput maxlength="2" formControlName="arrQty" class="form-control inputBox" style="width:3em;border-radius:0.25em" (keyup)="arrQtyChange($event)" />
                </div>
              </div>
            </div>
            <div style="display: flex" >
              <div style="margin-left:2.95em; width:2.25em">
                <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="addToAllLegs" (change)="addToAllLegsChange($event)"></mat-slide-toggle>
              </div>
              <div style="margin-top: 0.25em; display: flex; font-size: small "><div style="margin-right:0.25em">Add</div><div style="font-weight: bold; margin-right: 0.25em">{{serviceTypeDescription}}</div><div>to ALL NEXT ACTIVE LEGS</div></div>
              
            </div>
          </div>
        </div>
      </div>
</form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
    <div style="margin-right:1em;">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <button type="button" mat-button class="btn-high" *ngIf="loading==false && showSuccessMsg==false && !lockdownTrip" [disabled]="loading" (click)="clickSave('')"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>SAVE</div></div></button>&nbsp;
      <button type="button" mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>CLOSE</div></div></button>
    </div>  
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
    <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0.0em; background-color:#f9d2de; padding-left:0.25em; max-width:23em; white-space:pre-wrap"><label style="color:black; font-size: small">{{errMsg}}</label></div>
  </div>
</div>
