<div style="margin-top:-0.4em; padding: 0.5em; "  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  
  <div style="   background-color: #f0f0f0;   display: flex; justify-content: space-between;" >

    <div style="display: inline-flex;">
      <i class="material-icons-outlined" style="margin-top: 0.2em; margin-right: 0.4em;">engineering</i>
      <h3>Maintenance</h3>
    </div>
    <div *ngIf="isDialog" style="display: flex;"> 

      <!-- <button type="button" mat-button class="btn-low" style=" margin-top: 0.75em; height: 2em; margin-right: 1em;"  (click)="openMaintenanceComponent()">
        <div style="display: flex;margin-top:-0.125em">
          <div style="margin-top: 0.125em; margin-left:-0.25em;   ">
            <i class="material-icons" style="font-size: medium;cursor: pointer; ">arrow_back</i>
          </div>
          <div>
            Return to Maintenance Dashboard
          </div>
        </div>
      </button> -->
      
      <button mat-icon-button    (click)="openMaintenanceComponent()" >  <i class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i> </button>    
      <button mat-icon-button   (click)="clickRouteNew('maintenance/fpc-picklist-data','')">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>    

      <button  mat-icon-button mat-dialog-close> <i class="material-icons"
          style="font-size: x-large;">close </i> </button>
    </div>
  </div>

  <div   style=" background-color: white;padding: 0.6em;">
    <div>

      <div> 
 
          <div style="display:flex">
            <div>
 
              <div style=" margin-top: 1em;">
                <h4>FPC Picklist Data</h4>
                <h6 style="width: 36em;">Control and Customize Picklist Options</h6>
                <hr />
                <!-- <button formcontrolname="exchangerate" mat-button class="btn-menu"  style="font-size: small;" >
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">fact_check</span>Client Documents
                </button>&nbsp;
                <button formcontrolname="taxesandfees" mat-button class="btn-menu" style="font-size: small;"  >
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">fact_check</span>Crew & Pax Documents
                </button>&nbsp;
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;" >
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">fact_check</span>Hotel, Airline, Car Rental Company Names
                </button>&nbsp; -->

                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Client Documents</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;
   
                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Crew & Pax Documents</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;   

                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: -1.3em; padding-right: 1em;">
                          <span class="button-name">Hotel, Airline, Car Rental Company Names</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;
   
              </div>
              
              <div>
                <!-- <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" >
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    fact_check
                  </span>Country Documents
                </button>&nbsp; 
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" >
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    fact_check
                  </span>Airport Documents
                </button>&nbsp; 
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" >
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    fact_check
                  </span>Vendor Documents
                </button>&nbsp; -->


                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"  (click)="openCountryDocumentsPicklistComponent()"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Country Documents</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;

                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Airport Documents</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;

                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Vendor Documents</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;

              </div>
              
              <div>
                <!-- <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" >
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    fact_check
                  </span>Fuel Taxes & Fees Description Picklist
                </button>&nbsp; 
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" >
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    fact_check
                  </span>Person address tags for comms
                </button>&nbsp; 
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" >
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    fact_check
                  </span>Open?
                </button>&nbsp; -->

                



                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"  (click)="openTaxesAndFeesDescriptionDialog()"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0em">
                          <span class="button-name">Fuel Taxes & Fees Description Picklist</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;
                
                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td style="padding-right: 2em; ">
                        <div style="margin-top:-0.5em; margin-left: -.9em">
                          <span class="button-name">Person address tags for comms</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;
                
                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: .5em">
                          <span class="button-name">Open?</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;

              </div>
              
              <div>
                <!-- <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" >
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    fact_check
                  </span>Aircraft Documents
                </button>&nbsp; 
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" >
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    fact_check
                  </span>Aircraft Make Picklist
                </button>&nbsp; 
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" >
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    fact_check
                  </span>Aircraft Model Picklist
                </button>&nbsp; -->




                
                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Aircraft Documents</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;
                
                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Aircraft Make Picklist</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;
                
                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small; margin: 0.5em 0;"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td style="width: 2.5em;"> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Aircraft Model Picklist</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;

              </div>
              


             
            </div>

 
          </div>

      </div>
    </div>
  </div>
</div>

