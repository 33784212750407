import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { TripRevisionModel } from '../models/trip-revision.model';
import { FlightPlanPreferenceService } from '../services/flight-plan-preference.service';
import { FlightPlanPreferenceModel } from '../models/flight-plan-preference';
import { FlightPlanPreferenceAuditModel } from '../models/flight-plan-preference-audit.model';

@Component({
  selector: 'app-flight-plan-preference-audit-dialog',
  templateUrl: './flight-plan-preference-audit-dialog.component.html',
  styleUrls: ['./flight-plan-preference-audit-dialog.component.css']
})

export class FlightPlanPreferenceAuditDialogComponent implements OnInit {
  userType: string;
  flightPlanPreferenceID: number;
  fpp_audit_form: UntypedFormGroup;
  errMsg: string;
  msg: string;
    
  showSpin: boolean = true;
  
  v: number = this._authService.getCurrentTimeNumber();
  
  auditList: FlightPlanPreferenceAuditModel[];
  
  displayedColumn: string[] = ['field_name', 'old_value', 'new_value', 'modified_by','modified_date'];
  
  auditHourText: string;
  hideColumn: boolean = false;
  upPII: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<FlightPlanPreferenceAuditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _flightPlanPreferenceService: FlightPlanPreferenceService, private readonly _dialogService: DialogService) {
    this.flightPlanPreferenceID = _data.flightPlanPreferenceID;
    
  }

  ngOnInit() {
   
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.auditList = [];
    this.auditHourText = "";
    
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    if (this.userType == 'customer') {
      this.hideColumn = true;
    }
   this.initControls();

   this.getRevision();

  }

  initControls() {
    this.fpp_audit_form = this._formBuilder.group({
     
    });

    
  }

  get f() { return this.fpp_audit_form.controls; }

  getRevision() {
   
    this._flightPlanPreferenceService.getFlightPlanPreferenceAuditByfppId<ResponseModel<FlightPlanPreferenceAuditModel[]>>(this.flightPlanPreferenceID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.auditList = response.model;         
        }
        else {
          this.msg = "No data returned";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.showSpin = false;
    })
  }


  clickClose() {
    this._dialogRef.close();
  }
  
  
  

  
  
 

}
