import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})

export class ConfirmDialogComponent implements OnInit {
  dialogTitle: string;
  message: string;
  buttonText1: string;
  buttonText2: string;

  constructor(private _dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any) {
    _dialogRef.disableClose = true;
    this.dialogTitle = _data.dialogTitle;
    this.message = _data.message;
    this.buttonText1 = _data.buttonText1;
    this.buttonText2 = _data.buttonText2;
    if (this.dialogTitle == "" || this.dialogTitle == null || this.dialogTitle == undefined) {
      this.dialogTitle = "Confirmation Required";
    }
    if (this.buttonText1 == "" || this.buttonText1 == null || this.buttonText1 == undefined) {
      this.buttonText1 = "Yes";
    }
    if (this.buttonText2 == "" || this.buttonText2 == null || this.buttonText2 == undefined) {
      this.buttonText2 = "No";
    }
  }

  ngOnInit() {

  }

  close() {
    this._dialogRef.close();
  }
}
