<div *ngIf="upPII && grantAccessCompanyProfiles" style="background-color:#f8f8f8;" >
  <div style="display: flex; justify-content: space-between;" cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>{{dialogTitle}}</h5></div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
      <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
      </div>-->
  </div>
  <div>
    <form *ngIf="customer_comm_edit_form" [formGroup]="customer_comm_edit_form" (ngSubmit)="clickSave()">
      <div style="display:flex; margin-top:0em; padding-left: 1em">
        <div style="margin-top:0em;background-color:white; padding-left:0.5em; height: 20em">
          <div style="display:flex">
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="comm_address_type_select" style="font-size:small">Type</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="comm_address_type_select" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color:lightgrey" (change)="commAddressTypeChange($event)">
                  <option *ngFor="let cat of commAddressTypeList" [ngValue]="cat.commAddressTypeGUID">{{cat.commAddressTypeDesc}}</option>
                </select>
              </div>
            </div>
            <div  style="margin-top:0em; margin-right:0.625em">
              <label for="is_preferred" style="font-size:small">Preferred</label>
              <div style="margin-top:-0.5em;">
                <mat-checkbox  (click)="$event.stopPropagation()" color="primary" formControlName="isPreferred" class="mat-checkbox ml-3" [checked]="isPreferred" (change)="checkPreferred($event)"></mat-checkbox>              
              </div>
            </div>
            <div *ngIf="personCommsAddressGUID!='' && userType=='internal'" style="margin-left:1em; margin-right:0.625em">
              <label for="data_entry_status_select" style="font-size:small">Data Entry Status</label>
              <div style="margin-top:-0.35em;margin-bottom:0em">
                <select *ngIf="f" formControlName="data_entry_status_select"
                  style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color:lightgrey">
                  <option *ngFor="let d of dataEntryStatusList" [ngValue]="d.dataEntryStatusID">{{d.dataEntryStatusDescription}}
                  </option>
                </select>
              </div>
            </div>
            <!-- <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="comm_address_status" style="font-size:small">Disable Record</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="comm_address_status" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color:lightgrey">
                  <option value="1" selected>No</option>
                  <option value="0">Yes</option>
                </select>
              </div>
            </div> -->
          </div>
          <div style="display:flex">
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="address" style="font-size:small">{{addressLabel}}</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="address" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" style="padding: 0em;width:30em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="addressChange()" />
              </div>
              <div *ngIf="submitted && f.address.errors" class="invalid-feedback" style="margin-top:-2em">
                <div *ngIf="f.address.errors.required">Address is a required field.</div>
              </div>
            </div>
            <div *ngIf="selectedCommAddressType=='Phone'" style="margin-left: 0em;margin-top:0em;">
              <label for="extension" style="font-size:small">Extension</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em">
                <input type="text" formControlName="extension" class="form-control" maxlength="5" [ngClass]="{ 'is-invalid': submitted && f.extension.errors }" style="padding:0em; width:5em;height:1.5625em; font-size:small; background-color:#eaf0fd" />
              </div>
              <div *ngIf="submitted && f.extension.errors" class="invalid-feedback">
                <div *ngIf="f.extension.errors.pattern">Extension must be digits</div>
              </div>
            </div>
          </div>
          <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; white-space:pre-wrap"><label style="color:red; font-size: small">{{errMsg}}</label></div>
          <div style="display:flex">
            <div>
              <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                <label for="remarks" style="font-size:small">Remarks</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.5em">
                  <textarea formControlName="remarks" class="form-control" maxlength=255 style="width:30em; height:13.5em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>
                </div>
              </div>
              <!-- <div *ngIf="personCommsAddressGUID!='' && userType=='internal'" style="margin-top:-1em; margin-right:0.625em">
                <label for="data_entry_status_select" style="font-size:small">Data Entry Status</label>
                <div style="margin-top:-0.35em;margin-bottom:0em">
                  <select *ngIf="f" formControlName="data_entry_status_select" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color:lightgrey">
                    <option *ngFor="let d of dataEntryStatusList" [ngValue]="d.dataEntryStatusID">{{d.dataEntryStatusDescription}}</option>
                  </select>
                </div>
              </div> -->
            </div>
            <!-- <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="modifiedBy" style="font-size:small">Modified By</label>
              <div style="margin-top:-0.5em">
                <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
              </div>
              <label for="modifiedDate" style="font-size:small">Modified Date</label>
              <div style="margin-top:-0.5em">
                <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
              </div>
            </div> -->
          </div>
          <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>
        </div>
        <div style="width:0.5em; background-color: #f8f8f8;"></div>
        <div style="margin-top:0em; padding-left: 0.5em; padding-right:0em;margin-right:1em; background-color: white">
          
          <div style="display:flex">
            <div *ngIf="userType=='internal' || (userType=='customer' && personCommsAddressGUID=='')" style="height: 18em;width:12em; overflow-y: auto;overflow-x:hidden;">
              <table mat-table *ngIf="avaiAddressUseList" [dataSource]="avaiAddressUseList" class="example-container2 mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="addressUse">
                    <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">
                      Address Use
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickAddItem($event, element)">{{element.addressUseDescription}}</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>
            </div>
            <div *ngIf="userType=='internal' || (userType=='customer' && personCommsAddressGUID=='')" style="margin-top: 2em;margin-left:0em">
              <button type="button" mat-button class="btn-high" [hidden]="avaiAddressUseList && avaiAddressUseList.length==0" (click)="clickSelectAll()" style="width: 7em">Select All</button><br /><br />
              <button type="button" mat-button class="btn-low" [hidden]="selAddressUseList && selAddressUseList.length==0" (click)="clickRemoveAll()" style="width: 7em">&nbsp;Remove All&nbsp;</button>
            </div>
            <div style="margin-left:1em;height: 18em;width:12em; overflow-y: auto;overflow-x:hidden">
              <table mat-table *ngIf="selAddressUseList" [dataSource]="selAddressUseList" class="example-container2 mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="addressUse">
                    <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">
                      Selected Address Use Types
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickRemoveItem($event, element)">{{element.addressUseDescription}}</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
          </div>
        </div>
      </div>

      <div
        style="justify-content:space-between;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display:flex; height:3em">
        <div style="margin-right:1em">
          <button mat-button class="btn-high"
            *ngIf="(personCommsAddressGUID=='' && userType=='customer') || userType=='internal'" [disabled]="loading"
            (click)="clickSave()"><span *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Save&nbsp;</button>&nbsp;
          <button *ngIf="personCommsAddressGUID!='' && userType=='internal'" mat-button class="btn-high"
            (click)="clickAddNew()">&nbsp;Add new Record&nbsp;</button>&nbsp;
          <button mat-button class="btn-low" (click)="clickClose()"
            style="background-color:grey; color: white">&nbsp;Close&nbsp;</button>
        </div>
      
        <div style="display: flex;">
        
          <div class="form-group" style="display: flex; margin-top:0em; margin-right:2em; margin-left: 0em;">
            <label for="comm_address_status" style="font-size:small">Disable Record</label>
            <div style="margin: -0.2em 0 0 .5em;">
              <select *ngIf="f" formControlName="comm_address_status"
                style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color:lightgrey">
                <option value="1" selected>No</option>
                <option value="0">Yes</option>
              </select>
            </div>
          </div>
        
          <div style="font-size: small; margin-right: 1.5em;">
            <div>
              Modified By: {{modifiedBy}}
            </div>
            <div>
              Modified Date: {{modifiedDate}}
            </div>
          </div>
        </div>
        
        <div *ngIf="showSuccessMsg" style="margin-top: -0.25em;">
          <app-success-message [successMsg]=""></app-success-message>
        </div>
      </div>

    </form>

  </div>

  <!-- <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display:flex; height:3em">
    <div style="margin-right:1em">
      <button mat-button class="btn-high" *ngIf="(personCommsAddressGUID=='' && userType=='customer') || userType=='internal'" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Save&nbsp;</button>&nbsp;
      <button *ngIf="personCommsAddressGUID!='' && userType=='internal'" mat-button class="btn-high" (click)="clickAddNew()">&nbsp;Add new Record&nbsp;</button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white">&nbsp;Close&nbsp;</button>
    </div>

    <div class="form-group" style="display: flex; margin-top:0em; margin-right:0.625em">
      <label for="comm_address_status" style="font-size:small">Disable Record</label>
      <div style="margin-top:-0.5em;">
        <select *ngIf="f" formControlName="comm_address_status"
          style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color:lightgrey">
          <option value="1" selected>No</option>
          <option value="0">Yes</option>
        </select>
      </div>
    </div>

    <div *ngIf="showSuccessMsg" style="margin-top: -0.25em;">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
  </div> -->

</div>
<app-spin *ngIf="showSpin"></app-spin>





