export class CustomerRegulatoryServiceModel {
  customerRegulatoryServiceGuid: string
  customerGuid: string;
  governmentServiceType: string;
  governmentServiceTypeID: number;
  renewalStatusID: number;
  reportFrequencyID: number;
  authorizationNumber: string;
  serviceDate: string;
  effectiveDate: string;
  expirationDate: string;
  arrangedBy: string;
  isFPIArranged: boolean;
  contactedDate: string;
  dueDate: string;
  renewalStatusDescription: string;
  remarks: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: boolean;
  totalRecords: number;
  pageSize: number;
  pageIndex: number; 
}
