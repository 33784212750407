<div style="height: 100%;" [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="display: inline-flex; justify-content: space-between; width: 100%;">
    <div style="margin-left:1em">
      <div style="display: inline-flex;">
        <i class="material-symbols-outlined" style="margin-top: 0.3em;">
          inventory
        </i>
        <div style="margin-left: 0.5em; margin-bottom: 0.5em;">
          <h3 style="margin-bottom: 0em;">Manage Client Profiles</h3>
          <h5 style="font-weight: 100; font-size: small;">Access all client information</h5>
        </div>
      </div>
    </div>
    
    <div style="display: flex;">
      <button mat-icon-button *ngIf="isDialog" (click)="openMaintenanceComponent()" >  <i class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i> </button>
      <button mat-icon-button *ngIf="isDialog" (click)="clickRouteNew('customers/customer-profile-setup2','')">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>    
      <button mat-icon-button *ngIf="isDialog" mat-dialog-close><i class="material-icons" style="font-size: x-large;">close </i></button>
    </div>
  </div>
  <form [formGroup]="customer_profile_setup_form">
    <div style="margin-top:0em; padding-left: 1em; padding-right:1em">
      <div style="background-color: white; padding: 1em; width: 100%; font-size: small;">
        <div style="display: inline-flex;">
          <div style="display: flex; flex-direction: column; margin-left: 0.4em;">
            <div style="display: inline-flex;">
              Filter Client Accounts
              <i (click)="resetFilters()" class="material-symbols-outlined">
                delete
              </i>
            </div>

            <div style="display: inline-flex;">
              <input type="text" class="form-control" formControlName="text_filter" style="width:14em;height:1.6em; font-size: small;"
                [ngStyle]="{'background-color' : f.text_filter.disabled ? '#e9ecef' : '#eaf0fd'}"
                maxlength="50" (keyup)="searchClientAccounts($event)" (mousedown)="$event.stopPropagation()"/>
            </div>
          </div>

          <div style="margin-left: 1em;">
            Account Grouping
            <select class="form-control" formControlName="account_grouping" style="width:14em;height:1.5em; padding: 0em; margin-top: 0.4em; font-size: small;"
              [ngStyle]="{'background-color' : f.account_grouping.disabled ? '#e9ecef' : '#eaf0fd'}" 
              (change)="filterClientAccountsByAccountGrouping($event)">
              <option value="" selected>-----</option>
              <option *ngFor="let group of accountGroupingList">{{group.customerGroupName}}</option>
            </select>
          </div>

          <div style="margin-left: 1em;">
            Sales Team
            <select class="form-control" formControlName="sales_team" style="width:14em;height:1.5em; padding: 0em; margin-top: 0.4em; font-size: small;"
              [ngStyle]="{'background-color' : f.sales_team.disabled ? '#e9ecef' : '#eaf0fd'}"  
              (change)="filterClientAccountsBySalesTeam($event)">
              <option value="" selected>-----</option>
              <option *ngFor="let team of salesTeamList">{{team.marketingRegionDesc}}</option>
            </select>
          </div>

          <div style="margin-left: 1em;">
            Status
            <select class="form-control" formControlName="status" style="width:14em;height:1.5em;padding: 0em; margin-top: 0.4em; font-size: small;"
              [ngStyle]="{'background-color' : f.status.disabled ? '#e9ecef' : '#eaf0fd'}"  
              (change)="filterClientAccountsByStatus($event)">
              <option *ngFor="let customerType of customerTypeList">{{customerType.customerTypeDesc}}</option>
            </select>
          </div>
        </div>

        <div style="background-color: white; margin-left: 50em; margin-top: 0.5em;">
          {{totalRecords}} Records Count
        </div>
      </div>

      <div style="overflow-y: auto; height: 25em; padding-left: 1em; padding-right: 1em; background-color: white; scrollbar-color: #c1c1c1 white">
        <table mat-table [dataSource]="filteredRecordList">
          <tr>
            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: center;">
                Client Accounts
              </th>
              <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
            </ng-container>
            <ng-container matColumnDef="acct_setup">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                Account Setup
              </th>
              <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center;"><button mat-button
                  class="btn-med" (click)="openSelection('Acct Setup', element.customerGUID)">Open</button></td>
            </ng-container>
            <ng-container matColumnDef="client_information">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                Client Information
              </th>
              <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center;"><button mat-button
                  class="btn-med" (click)="openSelection('Client Information', element.customerGUID)">Open</button></td>
            </ng-container>
            <ng-container matColumnDef="aircraft">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                Aircraft
              </th>
              <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center;"><button mat-button
                  class="btn-med" (click)="openSelection('Aircraft', element.customerGUID)">Open</button></td>
            </ng-container>
            <ng-container matColumnDef="crew_pax_support">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                Crew, PAX & Support
              </th>
              <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center;"><button mat-button
                  class="btn-med" (click)="openSelection('Crew, PAX & Support', element.customerGUID)">Open</button></td>
            </ng-container>
            <ng-container matColumnDef="admin_services">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                Admin Services
              </th>
              <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center;"><button mat-button
                  class="btn-med" (click)="openSelection('Admin Services', element.customerGUID)">Open</button></td>
            </ng-container>
            <ng-container matColumnDef="feedback">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                Feedback
              </th>
              <td mat-cell *matCellDef="let element"></td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
    </div>
  </form>
  <div style="margin-top: 0.5em; margin-left: 1em; display: inline-flex;">
    <button (click)="openAddClientComponent()" mat-button class="btn-high" style="margin-right: 1em;">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <i *ngIf="!loading" class="material-icons">add</i>
      Add new Client Account
    </button>
    <button (click)="openAccountGroupingComponent()" mat-button class="btn-med" style="margin-right: 1em;">
      <i class="material-icons"></i>
      Manage Account Grouping
    </button>
  </div>
</div>
