export class FlightPlanPreferenceModel {
  flightPlanPreferenceID: number;
  groundStopTaskGUID: string;
  cruiseSpeed: string;
  cruiseSpeedClientRequested: boolean;
  flightLevel: string;
  flightLevelClientRequested: boolean;
  payload: string;
  payloadClientRequested: boolean;
  departureFuel: string;
  departureFuelClientRequested: boolean;
  fuelOverDestination: string;
  fodClientRequested: boolean;
  hold: string;
  holdClientRequested: boolean;
  reserve: string;
  reserveClientRequested: boolean;
  tank: string;
  tankClientRequested: boolean;
  flightRoute: string;
  remarks: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  groundStopGUID: string;
  nextGroundStopGUID: string;
  departureICAO: string;
  departureIATA: string;
  departureICAO_IATA: string;
  departureDateUTC: string;
  departureTimeUTC: string;
  departureDateTextUTC: string;
  departureDateTimeUTCDisplay: string;
  departureDateLocal: string;
  departureTimeLocal: string;
  departureDateTimeLocal: string;
  departureDateTextLocal: string;
  departureUTCTimeConversion: string;
  nextArrivalICAO: string;
  nextArrivalIATA: string;
  nextArrivalICAO_IATA: string;
  nextArrivalDateUTC: string;
  nextArrivalTimeUTC: string;
  nextArrivalDateTextUTC: string;
  nextArrivalDateTimeUTCDisplay: string;
  nextArrivalDateLocal: string;
  nextArrivalTimeLocal: string;
  nextArrivalDateTimeLocal: string;
  nextArrivalDateTextLocal: string;
  nextArrivalUTCTimeConversion: string;
  crewCount: number;
  paxCount: number;
  departureDateTimePassed: boolean;
  nextArrivalDateTimePassed: boolean;
  registration: string;
  etps: string;
  etpsClientRequested: boolean;
  alternateAirports: string;
  altClientRequested: boolean;
  aircraftManufacturer: string;
  aircraftTypeDesignator: string;
  cargo: string;
  cargoClientRequested: boolean;
}
