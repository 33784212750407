
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle style="margin-top:-.5em" mat-dialog-title class="d-flex justify-content-between">

      <div class="d-flex"><div>Fuel Quote - Trip Summary </div><div style="margin-left:1em;margin-top:.125em; font-weight:500;font-size:medium;color:gray">Trip Code {{tripCode}}</div></div>
    <div><mat-icon mat-dialog-close style="margin-top:.5em">close</mat-icon></div>
  </div>
  <div mat-dialog-content style="margin-top:-.75em">
    <div style="height:30em;background-color:white;" class="p-2">
      <div style="overflow:auto;height:20em">
        <table #table mat-table [dataSource]="fuelQuoteList">
          <tr>
            <ng-container matColumnDef="selected">
              <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 10%;width:10%;">
                <mat-checkbox style="height:1.5em;width:1.5em;" (change)="$event ? toggleAllRows() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>

              </th>
              <td mat-cell *matCellDef="let row; let i=index;" style="text-align:center !important;flex:0 0 10%;width:10%" [ngClass]="{ 'alert-danger':row.fuelQuote!=null && row.fuelQuote[0].isExpired }"  matTooltip="{{row.fuelQuote!=null && row.fuelQuote[0].isExpired? 'Exp: ' + row.fuelQuote[0].expiryDate + ' (Z)':''}}">                
                  <mat-checkbox *ngIf="row.fuelQuote!=null  && row.fuelQuote.groundStopTaskDocumentGUID!=''" style="height:1.5em;width:1.5em" (click)="$event.stopPropagation()"
                                (change)="$event ? checkBox(row) : null"
                                [checked]="selection.isSelected(row)">
                  </mat-checkbox>                
              </td>
            </ng-container>

            <ng-container matColumnDef="airport">
              <th mat-header-cell *matHeaderCellDef style="flex:0 0 7%;width:7%;">
                Airport
              </th>
              <td mat-cell *matCellDef="let element" style="flex:0 0 7%;width:7%;">{{element.gsInfo.icao}}</td>
            </ng-container>
            <ng-container matColumnDef="arrivalDate">
              <th mat-header-cell *matHeaderCellDef style="flex:0 0 15%;width:15%;">
                Arrival
              </th>
              <td mat-cell *matCellDef="let element" style="flex:0 0 15%;width:15%;">{{element.gsInfo.arrivalDateTextUTC!=''? element.gsInfo.arrivalDateTextUTC:'TBA'}}<br />{{element.gsInfo.arrivalDateTextLocal!=''?element.gsInfo.arrivalDateTextLocal:''}}</td>
            </ng-container>
            <ng-container matColumnDef="departureDate">
              <th mat-header-cell *matHeaderCellDef style="flex:0 0 15%;width:15%;">
                Departure
              </th>
              <td mat-cell *matCellDef="let element" style="flex:0 0 15%;width:15%;">{{element.gsInfo.departureDateTextUTC!=''? element.gsInfo.departureDateTextUTC:'TBA'}}<br />{{element.gsInfo.departureDateTextLocal!=''?element.gsInfo.departureDateTextLocal:''}}</td>
            </ng-container>
            <ng-container matColumnDef="fuelSupplier">
              <th mat-header-cell *matHeaderCellDef style="flex:0 0 25%;width:25%;">
                Fuel Supplier
              </th>
              <td mat-cell *matCellDef="let element; let i=index" style="flex:0 0 25%;width:25%;">
                {{element.fuelQuote!=null? element.fuelQuote[0].fuelSupplierName : 'No Fuel Quote'}}
              </td>
            </ng-container>
            <ng-container matColumnDef="intoPlaneAgent">
              <th mat-header-cell *matHeaderCellDef style="flex:0 0 25%;width:25%;">
                Into Plane Agent
              </th>
              <td mat-cell *matCellDef="let element" style="flex:0 0 25%;width:25%;"> {{element.fuelQuote!=null? element.fuelQuote[0].intoPlaneAgent:''}}</td>
            </ng-container>

            <ng-container matColumnDef="option">
              <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 8%;width:8%;">
                <div>Option</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 8%;width:8%;">
                <button *ngIf="element.fuelQuote!=null && element.fuelQuote.groundStopTaskDocumentGUID!=''" mat-button class="btn-high grid" (click)="clickPreviewDocument($event, null, element.fuelQuote[0].groundStopTaskDocumentGUID)">Open</button>
                <div *ngIf="element.fuelQuote!=null && element.fuelQuote.groundStopTaskDocumentGUID==''">No Quote Document</div>
                
              </td>
            </ng-container>


          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <app-spin *ngIf="isLoading"></app-spin>
      </div>
      <div class="form-group">
        <label style="font-size:small">Remarks (optional)</label>
        <textarea  [(ngModel)]="remarks" style="width:30em;height:8em" class="form-control inputBox"></textarea>
      </div>
     
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button class="btn-high mr-2" (click)="generateQuoteSummary()" [disabled]="isLoadingReport || selection.selected.length==0"><span *ngIf="isLoadingReport" class="spinner-border spinner-border-sm mr-1"></span>Create Report</button>
    <button mat-button class="btn-med mr-2" (click)="openSummaryHistory()">Quote Summary History</button>
    <button mat-button mat-dialog-close class="btn-low">Close</button> 
  </div>
