<div mat-dialog-title cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
	<h5 style="align-content:center">Flight Pro International, LLC Terms of Use Agreement</h5>
</div>
<div mat-dialog-content style="background-color:white;padding:.5em;font-size:small;margin-top:-.35em;margin-left:.5em;margin-right:.5em">
	<div>
		<p style="font-weight:bold !important;">
			Updated: September 1, 2023
		</p>
		<ol>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Introduction</span>
				<p></p>
				<p>
					Flight Pro International, LLC (the “Company,” “we,” “our” or “us”) owns and operates certain websites, including www.flightprointernational.com, www.flightprointl.com, www.flightproconnect.com, www.fltprointl.com, wwww.fpiconnect.com, and www.fpitrips.com, our social media accounts, mobile applications, and other tools, technology and programs (collectively the “Platform”), as well as services associated with or made available through the Platform (collectively the “Services”). These Terms of Use and any additional guidelines procedures, or rules that may apply to a specific feature of the Platform or Services (collectively the “Terms”) govern your use and access to the Platform and Services and constitutes a legally binding agreement between you (“User”, “your” or “you”) and Company (the “Agreement”). This Agreement applies to the extent you use the Services as a visitor, registered user, or in any other capacity permitted by these terms.
				</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Your Privacy Notice</span>
				<p></p>
				<p>
					Registration data and other personally identifiable information will be collected and used by Company consistent with our <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a>, which is incorporated herein for all purposes. Please review our <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a>, which governs your use of the Services and is incorporated into this Agreement. By accessing the Platform, using the Services, or registering for an account, you agree that we may collect, process, and disclose your personal information in accordance with our <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a>.
				</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Your Agreement to the Terms</span>
				<p></p>
				<p>
					By allowing for the registering of you for an account, using the Platform or using or purchasing our Services or by clicking accept or agree to these Terms when this option is made available to you, you accept and agree to be bound and are bound by these Terms and the Company’s <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a>, and consent to the collection and use of your data in accordance with Company’s <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a>. If you do not agree to all of these Terms and the <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a>, please do not use or access our Platform or use our Services.
				</p>
				<p>Company reserves the right, at its sole discretion, to change, modify, revise, add or remove portions of these Terms at any time, without notice and without liability towards you. All changes are effective immediately when we post them. Be sure to check this page periodically for updates, as your continued use of the Platform is your acceptance of any changed Terms. Company will not be held responsible or liable for the timeliness of or removal of information, failure to store information, inaccuracy of information or improper delivery of information.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Account Registration & Eligibility</span>
				<p></p>
				<p>In order to access and use certain areas or features of the Platform, you may need to provide certain information and/or answer certain questions. Each registration is for a single user only. You agree to (a) provide accurate, current and complete information, (b) maintain and promptly update, as necessary, your information, (c) be responsible for the acts or omissions of any third party who has authority to access or use the Platform on your behalf, and (d) immediately notify Company if you discover or otherwise suspect any security breaches related to the Platform. If you provide information that is untrue, inaccurate, not current or incomplete, we may suspend and/or terminate any and all current or future use of the Platform.</p>
				<p>You may not authorize others to use your account and you may not assign or otherwise transfer your account to any other person or entity. Should you suspect that any unauthorized party may be using your password or account, you shall notify Company’s Customer Service Team by sending an email to <a href="FPIAdmin@flightprointl.com">FPIAdmin@flightprointl.com.</a></p>
				<p>By providing information and/or answering questions, you also consent to receive electronic communications from the Company (e.g., via email or by posting notices to the Platform). These communications may include notices and are part of your relationship with us. You agree that any notices, agreements, disclosures or other communications that we send to you electronically will satisfy any legal communication requirements, including, but not limited to, that such communications be in writing. You should maintain copies of electronic communications from the Company by printing a paper copy or saving an electronic copy.</p>
				<p>Access to your account on the Platform is accomplished through the use of an email address and a password, which you will create. Your password should be kept strictly confidential to prevent unauthorized use. You agree to notify the Company promptly of any unauthorized use of your password and you will remain liable for any use of the Platform until you notify the Company of the unauthorized use of your password.</p>
				<p>If you open an account to use the Services on behalf of a company, organization, or other entity, then (i) “you” includes you and that entity, and (ii) you represent and warrant that you are an authorized representative of the entity with the authority to bind the entity to the Agreement and that you agree to the Agreement on the entity’s behalf.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Company is Not Liable</span>
				<p></p>
				<p>Company shall not be liable and you may be liable for losses, damages, liability, expenses, and fees incurred by Company or a third party arising from someone else using your account, regardless of whether you have notified us of such unauthorized use. If you provide any information that is untrue, inaccurate, not current, or incomplete, or Company has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, Company has the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof). You agree not to create an account or use the Services if you have been previously removed by Company, or if you have been previously banned from use of the Services.</p>
				<p>
					If you wish to deactivate your account for any reason, send a request to <a href="mailto:FPIAdmin@flightprointl.com">FPIAdmin@flightprointl.com</a>. If your account has been deactivated. Company may, but is not obligated to, reactivate your account upon written request to its Customer Service Team by sending an email to <a href="mailto:FPIAdmin@flightprointl.com">FPIAdmin@flightprointl.com</a>.
				</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Use of Suppliers</span>
				<p></p>
				<p>Company uses vendors and/or service providers and other licensors/suppliers, and works with other third parties, to provide hosting of and otherwise facilitate this Platform, to provide other services in support of this Platform, to create this Platform and its content, and in support of any Services that may be available through or described on this Platform, and each is hereinafter individually and collectively referred to as a “Supplier.”</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Contributions</span>
				<p></p>
				<p>
					You are entirely responsible for the content of, and any harm resulting from, your sending of or uploading, information, content, photographs, video or postings to or through the Platform or to the Company (collectively, a “Contribution”). When you create or make available a Contribution to the Platform or the Company, you hereby agree, represent and warrant that:
				</p>
				<ol style="list-style-type:lower-alpha;font-weight:normal">
					<li>
						the creation, distribution, transmission, public display and performance, accessing, downloading and copying of your Contribution does not and will not infringe any proprietary, confidentiality, copyright, patent, trademark, trade secret rights or other intellectual property rights (collectively “IP Rights”) of any third party;
						<p></p>
					</li>
					<li>
						you are the owner or license holder, or have the authority and legal right to use the Contribution;
						<p></p>
					</li>
					<li>
						your Contribution does not contain any viruses, worms, Trojan horses, malicious code or other harmful or destructive content;
						<p></p>
					</li>
					<li>
						your Contribution will not be obscene, lewd, lascivious, filthy, violent, harassing or otherwise objectionable, libelous or slanderous, does not advocate the violent overthrow of the government of the United States, does not incite, encourage or threaten immediate physical harm against another, does not violate any applicable law, regulation, or rule, and does not violate the privacy or publicity rights of any third party;
						<p></p>
					</li>
					<li>
						your Contribution does not contain material that solicits personal information from anyone under 18 or exploit people under the age of 18 in a sexual or violent manner, and does not violate any federal or state law concerning child pornography, child privacy, or otherwise intended to protect the health or wellbeing of minors;
						<p></p>
					</li>
					<li>
						if your employer has rights to intellectual property you create, you have either (i) received permission from your employer to make available the Contribution, or (ii) secured from your employer a waiver as to all rights in or to your Contribution;
						<p></p>
					</li>
					<li>
						your Contribution does not violate any state or federal law designed to regulate electronic advertising;
						<p></p>
					</li>
					<li>
						your Contribution does not amount to trolling, or the making of controversial statements for the purpose of generating responses by others;
						<p></p>
					</li>
					<li>
						your Contribution does not constitute, contain, install or attempt to install or promote spyware, malware or other computer code, whether on Company’s or others computers or equipment, designed to enable you or others to gather information about or monitor the online or other activities of another party;
						<p></p>
					</li>
					<li>
						your Contribution does not inundate the Platform with communications or other traffic suggesting no serious intent to use the Platform or its Services;
						<p></p>
					</li>
					<li>
						your Contribution does not otherwise violate, or link to material that violates, any provision of this Agreement;
						<p></p>
					</li>
					<li>
						your Contribution does not contain pictures, data, audio or visual files, or any other content that is excessive in size, as determined by Company in its sole discretion;
						<p></p>
					</li>
					<li>
						use by Company of your Contribution will not violate or infringe any “IP Rights” of any third person;
						<p></p>
					</li>
					<li>
						if you learn that the Contribution provided by you to Company, or any portion thereof, are in violation of any IP Rights of any third-person you will immediately notify Company and take such action as is necessary to modify the Contribution to cure any violation;
						<p></p>
					</li>
					<li>
						you agree to and shall assume all liability for all royalties, licenses, patent fees, and any other charges made in connection with any photographs, videos, information or content contained in your Contribution;
						<p></p>
					</li>
					<li>
						if your Contribution contains any pictures, videos or comments regarding anyone under the age of 18 years of age you represent and warrant that you are that person’s parent or legal guardian and that you have all legal permissions to post such Contribution and that you hereby irrevocably consent to the use by Company of each of your child’s name, likeness and voice contained in the Contribution in photographs, social media posts, video recordings, electronic images or in any other medium or in any form for any lawful purpose, including without limitation, trade, exhibition, illustration, promotion, publicity, advertising and electronic publication in any media, throughout the world in perpetuity, including but not limited to, on the world wide web, without payment or any other consideration to you or anyone else including your child;
						<p></p>
					</li>
					<li>
						you hereby irrevocably consent to the use by Company of your name, likeness and voice contained in the Contribution in photographs, social media posts, video recordings, electronic images or in any other medium or in any form for any lawful purpose, including without limitation, trade, exhibition, illustration, promotion, publicity, advertising and electronic publication in any media, throughout the world in perpetuity, including but not limited to, on the world wide web, without payment or any other consideration to you or anyone else;
						<p></p>
					</li>
					<li>
						you hereby irrevocably consent to Company’s use and hereby assign to the Company all current and future rights, title and interest you have in the Contribution (including all content and all rights embodied therein) throughout the world and hereby agree that Company may exploit, post, edit, alter, copy, exhibit, publish, distribute or otherwise use the Contribution, without limitation, including licensing the use of any such Contribution to third-parties without compensation of any kind, and without further permission or notification to me; and
						<p></p>
					</li>
					<li>
						YOU HEREBY IRREVOCABLY WAIVE, HOLD HARMLESS, RELEASE AND FOREVER DISCHARGE THE COMPANY, ITS OFFICERS, DIRECTORS, MEMBERS, EMPLOYEES (AND ALL PERSONS ACTING UNDER ITS PERMISSION OR AUTHORITY) TO THE FULLEST EXTENT PERMITTED BY LAW FROM ANY AND ALL CLAIMS, DEMANDS, AND CAUSES OF ACTION WHICH YOU, YOUR HEIRS, REPRESENTATIVES, EXECUTORS, ADMINISTRATORS, OR ANY OTHER PERSONS ACTING ON YOUR BEHALF, YOUR CHILD’S BEHALF, YOUR FAMILY’S BEHALF OR ON BEHALF OF YOUR ESTATE HAVE OR MAY HAVE BY REASON OF ANY CONTRIBUTION PROVIDED BY YOU TO THE COMPANY INCLUDING BUT NOT LIMITED TO CLAIMS BASED UPON INVASION OF PRIVACY, LIBEL, SLANDER, COPYRIGHT INFRINGEMENT, RIGHT OF PUBLICITY, DEFAMATION, FALSE ENDORSEMENT, OR CLAIM OF VISUAL OR AUDIO ALTERATION OR FAULTY MECHANICAL REPRODUCTION.  THIS RELEASE ALSO INCLUDES WITHOUT LIMITATION ANY CLAIMS RELATED TO BLURRING, DISTORTION, ALTERATION, OPTICAL ILLUSION, DIGITAL ALTERATION, USE IN THE COMPOSITE FORM, WHETHER INTENTIONAL OR OTHERWISE, OR USE OF A FICTITIOUS NAME, THAT MAY OCCUR OR BE PRODUCED IN THE PROCESSING OR PUBLICATION OF THE CONTRIBUTION.
						<p></p>
					</li>
				</ol>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Your Conduct and Responsible Use of the Services</span>
				<p></p>
				<p>
					You agree that you will not:
				</p>
				<ol style="list-style-type:lower-alpha;font-weight:normal">
					<li>
						use the Services or the Platform for any criminal or tortious activity, including child pornography, fraud, trafficking in obscene material, harassment, defamation, stalking, spamming, copyright infringement, unfair competition, trademark or service mark infringement, patent infringement, or theft of trade secrets;
						<p></p>
					</li>
					<li>
						transmit chain letters or junk email to other users;
						<p></p>
					</li>
					<p></p>
					<li>
						advertise to, or solicit any user or Partner to buy any of your products or services;
						<p></p>
					</li>
					<li>
						use any information obtained from the Platform in order to contact, advertise to, solicit, or sell to any user or Partner;
						<p></p>
					</li>
					<li>
						engage in any automated use of the system, such as using scripts to add friends or send comments or messages;
						<p></p>
					</li>
					<li>
						interfere with, disrupt, or create an undue burden on the Platform or the networks or services connected to the Platform;
						<p></p>
					</li>
					<li>
						attempt to impersonate another user or person;
						<p></p>
					</li>
					<li>
						use the username of another user;
						<p></p>
					</li>
					<li>
						sell or otherwise transfer your profile or account;
						<p></p>
					</li>
					<li>
						use any information obtained from the Platform in order to harass, abuse, or harm another person;
						<p></p>
					</li>
					<li>
						scan, scrape, harvest, hack, monitor or copy from the Platform, any information, including but not limited, to sales, Platform usage, Platform layouts, promotion information, partner information, pricing information, codes, or similar data for any competitive purposes;
						<p></p>
					</li>
					<li>
						register an account and use the Services to learn how the Platform works so that you can compete against Company;
						<p></p>
					</li>
					<li>
						use the Services or the Platform for any purpose that is unlawful or prohibited by these Terms or by law, or that causes damage to Company on or through this Platform;
						<p></p>
					</li>
					<li>
						remove any copyright, trademark or other proprietary notices from any portion of the Platform;
						<p></p>
					</li>
					<li>
						reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Platform or Services except as expressly permitted by Company;
						<p></p>
					</li>
					<li>
						decompile, reverse engineer or disassemble the Platform and/or its code, nor attempt to do so;
						<p></p>
					</li>
					<li>
						link to, mirror or frame any portion of the Platform;
						<p></p>
					</li>
					<li>
						have any of your communications with or through this Platform, including without limitation any usernames or passwords you establish, infringe upon the rights of any third party or contain defamatory, libelous, abusive or obscene material, or violate any applicable local, state, national, international law or regulation;
						<p></p>
					</li>
					<li>
						attempt to gain unauthorized access to or impair any aspect of the Platform, Services, or their related systems or networks;
						<p></p>
					</li>
					<li>
						collect any content from the Platform, including without limitation, collecting, copying, or aggregating codes, promotions, and information relating to Partners, and providing such content to third parties in a manner that may divert traffic or sales from the Platform without Company’s express written permission;
						<p></p>
					</li>
					<li>
						interfere with or attempt to interfere with the proper operation of or any security measure used by any of the Platform;
						<p></p>
					</li>
					<li>
						use any of the Platform in a way that depletes web infrastructural resources, slows the transferring of loading of any web page, or interferes with the normal operation of them;
						<p></p>
					</li>
					<li>
						submit any content that is purposely inaccurate, fraudulent, or falsifies information in connection with your account or to create multiple accounts;
						<p></p>
					</li>
					<li>
						use the Platform or any of its contents to advertise or solicit, for any commercial, political, or religious purpose or to compete, directly or indirectly, with Company;
						<p></p>
					</li>
					<li>
						affect/interrupt or to attempt to affect/interrupt the operation of this Platform in any manner;
						<p></p>
					</li>
					<li>
						refuse to cooperate in any investigation or refuse to provide confirmation of your identity or any information you provide;
						<p></p>
					</li>
					<li>
						use your user account or the Services in a manner that we, our payment processor, Visa, MasterCard, American Express, Discover, or any other electronic funds transfer network reasonably believes to be an abuse of the card system or a violation of card association or network rules; or
						<p></p>
					</li>
					<li>
						otherwise violate the terms of this Agreement.
						<p></p>
					</li>
				</ol>
				<p>
					We may require, at any time, proof that you are following these rules. We reserve the right to take, or refrain from taking, any and all steps available to us, once we become aware of any violation of this Agreement, including, but not limited to, the following:
				</p>
				<ul style="list-style-type:disc">
					<li>
						Terminate this Agreement, limit your user account, and/or close or suspend your user account, immediately and without penalty to us;
					</li>
					<li>
						Refuse to provide the Services to you in the future;
					</li>
					<li>
						Limit your access to your user account or any of Services, including limiting your ability to pay with any of the payment methods linked to your user account;
					</li>
					<li>
						Disclose your identity or other information about you to a third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy;
					</li>
					<li>
						Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the website;
					</li>
					<li>
						Update inaccurate information you provided us; or
					</li>
					<li>
						Take legal action against you.
					</li>
				</ul>
				<p></p>
				<p>
					If you’ve violated this Agreement, then you’re also responsible for damages to Company caused by your violation of this Agreement.
				</p>
			</li>

			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Intellectual Property Rights</span>
				<p></p>
				<p>The content on the Platform (collectively “Materials”) including any Contribution, except for the trademarks, service marks and logos of third-parties contained therein (“Marks”), are owned by or licensed to Company, subject to copyright and other intellectual property rights under United States and foreign laws and international conventions. App Store and the App Store logo are trademarks of Apple, Inc. Google Play and the Google Play logo are trademarks of Google LLC.</p>
				<p>Materials on the Platform are provided to you AS IS for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners.</p>
				<p>Company reserves all rights not expressly granted in and to the Platform and the Materials. You are expressly not authorized to do so and agree to not engage in the use, copying, or distribution of any of the Materials other than expressly permitted by Company, including any use, copying, or distribution of either Company’s or third parties’ materials obtained through the Platform for any commercial purposes. If you download or print a copy of the Materials for personal use, you must retain all copyright and other proprietary notices contained therein. You agree not to circumvent, disable or otherwise interfere with security related features of the Platform or features that prevent or restrict use or copying of any Materials or enforce limitations on use of the Platform or the Materials therein.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">No License</span>
				<p></p>
				<p>The logos, trademarks, designs or information contained in this Platform are the property of Company or Company is a licensed user of such information and your reproduction, duplication, or otherwise exploitive use for any commercial purpose without the express consent of Company is prohibited.</p>
				<p>Your use of this Platform or any Services does not create, and nothing contained in this Platform shall be construed as creating or conferring to you, by implication, estoppel, or otherwise, a license or any other rights in trademarks, service marks, copyrights or other intellectual property rights or any other rights of Company, its Supplier(s), or any third party.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Submissions</span>
				<p></p>
				<p>Company welcomes your comments regarding our products and services. However, we do not want and cannot accept any ideas, feedback, proposals, materials, comments or suggestions you consider to be confidential or proprietary. Accordingly, you hereby assign and reserve no right, title or interest in and to any ideas, feedback, proposals, materials, photographs, reviews, comments or suggestions. This policy is intended to help Company avoid future misunderstandings when new products, services, and features developed internally by Company might be similar or even identical to your idea.</p>
				<p>If you send us or provide us any feedback, comment, suggestion, idea, proposal or other material (“Submission”), you will be agreeing and warranting, and Company will consider the Submission to be, non-confidential and non-proprietary and that Company may use such Submission or not, as it chooses in its sole discretion. Company shall have no obligations to you concerning the Submission, contractual or otherwise (including, but not limited to, an obligation to keep the Submission confidential), and shall not be liable for any use or disclosure of any Submission. Further, you understand and agree that the Submission becomes the sole property of Company. By submitting the Submission you also understand and agree that Company shall be entitled to unrestricted use of the Submission for any purpose whatsoever, commercial or otherwise, without compensation to you.</p>
				<p>For any Submissions provided by you to Company, you represent and warrant that:  (a) you are the owner or license holder, or has the authority to use any such Submission; (b) such Submission does not and will not violate or infringe any IP Rights of any third person; (c)  you have the legal right and authority to use such Submission; (d) Company has the legal right to use such Submission without restriction or limitation; (e) use by Company of the Submission will not violate any IP Rights of any third person; and (f) if you learn that any of the photos, videos, content or information provided by you in the Submission to Company, or any portion thereof, are in violation of any IP Rights of any third-person, then you will immediately notify Company and take such action as is necessary to modify the Submission to cure any violation.  You shall assume all liability for all royalties, licenses, patent fees, and any other charges made in connection with any Submission.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Links to Other Sites</span>
				<p></p>
				<p>Our Platform may provide links to co-branded, third-party supplier sites, in addition to other websites not operated by Company. Such links do not imply, nor should you infer, association, sponsorship, affiliation, or endorsement of material on any other site, and Company assumes no responsibility for such third-party web sites, including, but not limited to any content contained on such websites. Any creation by you of hyperlinks to our Platform is not allowed without the express written consent of Company. Any social media accounts you access through our Platform, or in connection with our Platform, shall be governed by the same terms and conditions as set forth herein.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Age Eligibility and Legal Capacity</span>
				<p></p>
				<p>The Services are not intended for access or use by children, especially those under the age of 18. If you are under the age of 18, you may not access or use the Services or provide information to us.</p>
				<p>By accessing or using the Services, you represent that you have legal capacity to enter into this Agreement and to fulfill your obligations set out in this Agreement. If you are not old enough to enter into contracts in your state (i.e., a minor), you need to review this Agreement with your parent or guardian before using the Services; if your parent or guardian does not agree with the Agreement as applied to you, then you are not authorized to access or use the Services.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Limitation of Liability</span>
				<p></p>
				<p>Except where prohibited by law, in no event shall Company be liable to you for any incidental, indirect, special, punitive, exemplary and/or consequential damages (including without limitation loss of profit, revenue or use) arising out of or in any way connected with our Platform or services offered by Company, whether in contract, warranty, tort (including negligence, whether active, passive or imputed), product liability, strict liability and/or other theory, even if we or our authorized representatives have been advised of the possibility of such damages.  Unless prohibited by law, in no event shall the aggregate liability of Company or any of their officers, directors, employees or insureds to you or any third party for damages, losses, and causes of action exceed the amount paid by you, if any, for accessing our Platform or $100, whichever is less.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Indemnity</span>
				<p></p>
				<p>YOU AGREE TO AND SHALL INDEMNIFY, DEFEND AND HOLD COMPANY, ITS AFFILIATES, DIRECTORS, OFFICERS, SHAREHOLDERS, AGENTS AND EMPLOYEES HARMLESS FROM AND AGAINST ANY ACTUAL OR THREATENED DEMANDS OF ANY KIND OR NATURE, LAWSUITS, ARBITRATIONS, CIVIL OR CRIMINAL LIABILITIES, LOSSES, DAMAGES, CAUSES OF ACTION, JUDGMENTS, AND EXPENSES INCLUDING COURT COSTS, EXPERT FEES, AND ATTORNEYS’ FEES MADE, OR ASSERTED, AGAINST COMPANY BY ANY THIRD PARTY DUE TO, ARISING OUT OF, OR IN CONNECTION WITH (A) ANY MATERIALS, CONTRIBUTIONS OR SUBMISSIONS PROVIDED TO COMPANY BY YOU, (B) YOUR BREACH OR VIOLATION OF ANY OF THESE TERMS, OUR <a routerLink="" (click)="openPrivacyPolicy()">PRIVACY POLICY</a> OR OUR NOTICE TO CALIFORNIA RESIDENTS, (C) YOUR USE OF THE PLATFORM OR SERVICES, (D) COMPANY’S USE OF MATERIALS, CONTRIBUTIONS OR SUBMISSIONS PROVIDED TO COMPANY BY YOU, (E) YOUR VIOLATION OF THE RIGHTS OF ANY THIRD PARTY INCLUDING PARTNERS, ADVERTISERS, SUPPLIERS, VENDORS, OR OTHER USERS, (F) YOUR POSTING OF ANY CONTRIBUTIONS, OR (G) YOUR REPRESENTATIONS OR WARRANTIES MADE TO COMPANY. YOU YOUR DUTY TO CONDUCT A DEFENSE OF COMPANY SHALL ARISE UPON NOTICE OF COMPANY TO YOU OF SUCH DEMAND OR THREATENED DEMAND. YOU SHALL CONDUCT ANY DEFENSE WITH DUE DILIGENCE AND IN GOOD FAITH WITH COUNSEL SATISFACTORY TO COMPANY. SHOULD YOU BREACH YOUR DEFENSE OR INDEMNITY OBLIGATIONS, AND FAIL TO CURE SUCH BREACH AFTER WRITTEN NOTICE AND A COMMERCIALLY REASONABLE TIME TO CURE BUT NO LATER THAN 30 DAYS, COMPANY MAY AT YOUR SOLE COST AND EXPENSE ASSUME THE DEFENSE AND DEFEND OR SETTLE THE ENTIRE CLAIM.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">DISCLAIMER OF WARRANTIES</span>
				<p></p>
				<p>
					YOU AGREE THAT THE PLATFORM, AND ALL OF ITS CONTENTS, AND EACH THIRD PARTY WEBSITE ACCESSIBLE FROM OR THROUGH THE PLATFORM, ARE PROVIDED TO YOU "AS IS" AND “AS AVAILABLE.”
				</p>
				<p>
					YOU AGREE THAT YOUR USE OF OUR PLATFORM OR ANY OF OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT OF THE RIGHTS OF THIRD PARTIES, DATA ACCURACY, SYSTEMS INTEGRATION, INFORMATION AND PROTECTION, OR QUIET ENJOYMENT IN CONNECTION WITH THE SERVICE AND/OR PLATFORM AND YOUR USE THEREOF.
				</p>
				<p>YOU AGREE THAT COMPANY UNDERTAKES NO OBLIGATION TO VERIFY OR MAINTAIN THE CORRECTNESS, ACCURACY, RELIABILITY AND MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE PLATFORM’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE PLATFORM AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR AND IS NOT LIABLE FOR ANY: (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE PLATFORM, (C) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM, 	(E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE PLATFORM BY ANY THIRD PARTY, AND/OR  (F) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE PLATFORM. COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY IT OR A THIRD PARTY THROUGH THE PLATFORM OR ANY HYPERLINKED WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND COMPANY WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>
				<p>AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. COMPANY DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF ANY THIRD PARTY. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE PLATFORM OR SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.</p>
				<p>YOU AGREE THAT COMPANY WILL NOT BE HELD RESPONSIBLE OR LIABLE FOR THE TIMELINESS OF OR REMOVAL OF INFORMATION, FAILURE TO STORE INFORMATION, INACCURACY OF INFORMATION, OR THE IMPROPER DELIVERY OF INFORMATION.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Company’s Right to Terminate Users</span>
				<p></p>
				<p>Without limiting any other provision of this agreement, company reserves the right to, in company’s sole discretion and without notice or liability deny access to and use of the platform or service to, any person for any reason or for no reason at all, including without limitation for breach of any representation, warranty or covenant contained in this agreement, or of any applicable law or regulation.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Non-commercial Use by Users Prohibited</span>
				<p></p>
				<p>The Services and our Platform are for the personal use of Users only and may not be used in connection with any commercial endeavors except those that are specifically approved by Company. For example, without limitation, you shall not and you agree not to do the following without the express written consent of Company: (a) make any unauthorized use of the Services or Platform, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email; or (b) engage in unauthorized copying, reproducing, saving, distributing, downloading, cataloguing, aggregating, scraping, framing of or linking to the Platform.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Copyright Policy</span>
				<p></p>
				<p><span style="text-decoration:underline">Reporting Claims of Copyright Infringement:</span>  We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible on or from the Platform infringe your copyright, you may request removal of those materials (or access to them) from the Platform by submitting written notification to our copyright agent designated below. In accordance with the Online Copyright Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) ("DMCA"), the written notice (the "DMCA Notice") must include substantially the following:</p>
				<p></p>
				<ul style="list-style-type:disc">
					<li>
						Your physical or electronic signature.
					</li>
					<li>
						Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Platform, a representative list of such works.
					</li>
					<li>
						Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.
					</li>
					<li>
						Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).
					</li>
					<li>
						A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.
					</li>
					<li>
						A statement that the information in the written notice is accurate.
					</li>
					<li>
						A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
					</li>
				</ul>
				<p></p>
				<p>Our designated copyright agent to receive DMCA Notices is:</p>
				<p>
					Flight Pro International, LLC<br />
					Attn: Richard Snider<br />
					6002 Rogerdale Rd, Suite 425<br />
					Houston, TX  77072<br />
					<a href="mailto:rsnider@flightprointl.com">rsnider@flightprointl.com</a><br />
					346-415-6906<br />
				</p>
				<p>
					If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be effective. Please be aware that if you knowingly materially misrepresent that material or activity on the Platform is infringing your copyright, you may be held liable for damages (including costs and attorney's fees) under Section 512(f) of the DMCA.
				</p>
				<p>
					<span style="text-decoration:underline">Counter-Notification Procedures:</span> If you believe that material you posted on the Platform was removed or access to it was disabled by mistake or misidentification, you may file a counter-notification with us (a "Counter-Notice") by submitting written notification to our copyright agent designated below. Pursuant to the DMCA, the Counter-Notice must include substantially the following:
				</p>
				<ul>
					<li>
						Your physical or electronic signature.
					</li>
					<li>
						An identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled.
					</li>
					<li>
						Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).
					</li>
					<li>
						A statement under penalty of perjury by you that you have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.
					</li>
					<li>
						A statement that you will consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located (or if you reside outside the United States for any judicial district in which the Platform may be found) and that you will accept service from the person (or an agent of that person) who provided the Platform with the complaint at issue.
					</li>
				</ul>
				<p>Our designated agent to receive Counter-Notices is:</p>
				<p>
					Flight Pro International, LLC<br />
					Attn: Richard Snider<br />
					6002 Rogerdale Rd, Suite 425<br />
					Houston, TX  77072<br />
					<a href="mailto:rsnider@flightprointl.com">rsnider@flightprointl.com</a><br />
					346-415-6906<br />
				</p>
				<p>The DMCA allows us to restore the removed content if the party filing the original DMCA Notice does not file a court action against you within ten business days of receiving the copy of your Counter-Notice.</p>
				<p>Please be aware that if you knowingly materially misrepresent that material or activity on the Platform was removed or disabled by mistake or misidentification, you may be held liable for damages (including costs and attorney's fees) under Section 512(f) of the DMCA.</p>
				<p><span style="text-decoration:underline">Repeat Infringers:</span> It is our policy in appropriate circumstances to disable and/or terminate the accounts of users who are repeat infringers.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Governing Law and Jurisdiction</span>
				<p></p>
				<p>These Terms and our <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a> shall be governed by and interpreted in accordance with the laws of the State of Texas, United States of America, without regard to principles of Conflicts of Law.</p>
				<p>By using this Platform you hereby irrevocably consent to the jurisdiction of the courts and arbitration located in Harris County, Texas, to the exclusion of all others, for any action arising from these Terms or our <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a>. Furthermore, you hereby agree to waive any right to contest venue or personal jurisdiction in Harris County, Texas.</p>
				<p>You agree that any dispute, cause of action or claim you may have arising out of or in any way relating to these Terms, the <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a> or your business relationship to Company must be commenced within two (2) years and one day from the date of the accrual of the cause of action, and that actions brought after this date will be barred.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Use Outside of the United States/Void Where Prohibited</span>
				<p></p>
				<p>Our Platform is operated by or for Company.  Company makes no representation that the information in our Platform is appropriate or available for use outside of the United States, and access to our Platform from territories where the content of our Platform may be illegal is prohibited. If you access our Platform from outside the United States, be advised our Platform may contain references to products and services that are not available or are prohibited in your country.</p>
				<p>Those who choose to access our Platform from outside of the United States do so based on their own initiative and are responsible for compliance with applicable local laws, about which Company makes no warranties or assumptions. Any offer for any feature, product or service on our Platform is void where prohibited.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Arbitration and Class Action Waiver</span>
				<p></p>
				<p>All disputes, controversies or claims arising out of or relating to these Terms (including its formation, performance, alleged breach, enforceability and validity), Company’s Services, Company’s Privacy Policy (including formation, performance, alleged breach, enforceability and validity), or your access to or use of the Platform, your providing of Submissions, your use of M made available by means of the Platform or third-party websites, your uploading Contributions to the Platform, or your purchasing of goods or services from us or third parties, shall be exclusively resolved by confidential binding arbitration in accordance with the laws of the Federal Arbitration Act before a single arbitrator.</p>
				<p>If you and Company cannot agree on the appointment of an arbitrator, then such arbitrator shall be appointed by the American Arbitration Association. The arbitration shall be conducted in accordance with the Commercial Arbitration Rules and Mediation Procedures of the American Arbitration Association. Other than the initial filing fees to be paid by the person or entity bringing the Arbitration, the costs of the arbitration shall be split 50/50 unless otherwise ordered by the Arbitrator for good cause; provided, however, that the arbitrator has no ability to award attorneys’ fees or costs incurred on any party’s behalf in connection with any arbitration proceeding. The Arbitrator is limited to deciding matters as they exist between you and Company only. The arbitration shall take place in Harris County, Texas. The Arbitrator shall follow Texas law, as may apply to a particular claim. In the event of litigation or to compel arbitration or to enforce an arbitration award under this Section, or to obtain an injunction under this Section, the parties hereby irrevocably agree that such proceedings must be brought a court located in Harris County, Texas. Both you and Company consent and submit to the personal jurisdiction and venue of state and federal courts located in Harris County, Texas.</p>
				<p>ARBITRATION MUST BE ON AN INDIVIDUAL BASIS. THIS MEANS NEITHER YOU NOR COMPANY MAY JOIN OR CONSOLIDATE CLAIMS IN ARBITRATION BY OR AGAINST EACH OTHER OR LITIGATE IN COURT OR ARBITRATE ANY CLAIMS AS A PLAINTIFF, REPRESENTATIVE OR MEMBER OF A CLASS OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. Only a court, and not an arbitrator, shall determine the validity and effect of this Class Action Waiver. If the Class Action Waiver is deemed to be unenforceable, you and COMPANY agree that this Agreement is otherwise silent as to any party’s ability to bring a class, collective or representative action in arbitration. <span style="text-decoration:underline">The Arbitrator may not consider and has no authority to consider any claims alleged as class or collective actions or as a representative matter.</span></p>
				<p>As noted, the Arbitrator's authority to resolve and make written awards is limited to claims between you and Company only. <span style="text-decoration:underline">Claims of different individuals or entities may not be joined or consolidated in any arbitration between you and Company, and the Arbitrator has no authority whatsoever over such other claims, unless agreed to in writing by you and Company.</span> Nonetheless, no arbitration award or decision will have any preclusive effect as to issues or claims in any dispute with anyone who is not a named party to the arbitration.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">No Agency</span>
				<p></p>
				<p>By entering into this Agreement, using the Platform or providing Contributions to Company you agree that you are not creating any agency, partnership, joint venture or other form of joint enterprise, employment or fiduciary relationship with Company. You also agree that neither you nor Company shall have authority to contract for or bind the other party in any manner whatsoever. This Platform, unless specifically otherwise identified, does not create a business or professional services relationship between you and Company and/or its Partners,  Supplier(s), advertisers or vendors.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Miscellaneous</span>
				<p></p>
				<p><span style="font-style:italic">Entire Agreement.</span> This Agreement constitutes the entire agreement between you and Company regarding the access to or use of Company’s Platform or Services. No modification or waiver by Company of any provisions herein shall be deemed to have been made unless made in writing and signed by an authorized representative of Company.</p>
				<p><span style="font-style:italic">Severance.</span> If any portion of these Terms or our <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a> are deemed unlawful, void or unenforceable, then that part shall be deemed severable from this Agreement and does not affect the validity and enforceability of any remaining provisions, which shall be construed in accordance with applicable law.</p>
				<p><span style="font-style:italic">Non-Waiver.</span> The failure of Company to act with respect to a breach of these Terms and/or our <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a> by you or others does not constitute a waiver and shall not limit any party’s rights with respect to such breach or any subsequent breaches.</p>
				<p><span style="font-style:italic">Assignment</span>. You cannot assign your rights under these Terms or our <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a> without our prior written agreement. We can in our sole discretion and without prior notice to you, assign our rights and obligations to any entity or entities that agree to be bound by the Terms or our <a routerLink="" (click)="openPrivacyPolicy()">Privacy Policy</a>.</p>
				<p><span style="font-style:italic">Non-Reliance.</span> EACH PARTY FURTHER AGREES THAT THEY CANNOT AND ARE NOT RELYING ON ANY REPRESENTATIONS OF THE OTHER PARTY IN DECIDING WHETHER TO ENTER INTO THIS AGREEMENT OR TO AGREE TO ANY OF THE TERMS OF THIS AGREEMENT. MOREOVER, EACH PARTY TO THIS AGREEMENT SPECIFICALLY DISCLAIMS AND WAIVES ANY CLAIMS THE PARTY MAY HAVE OF FRAUD, MISREPRESENTATION, NEGLIGENT MISREPRESENTATION, OR FRAUD IN THE INDUCEMENT RELATED TO THE ENTIRETY OF THIS OR ANY OTHER AGREEMENTS THE PARTIES HAVE WITH EACH OTHER.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Notices</span>
				<p></p>
				<p>If you have any questions, or comments about these Terms please contact Company at:</p>
				<p>
					Flight Pro International, LLC<br />
					Attn: Roberto Cormack<br />
					6002 Rogerdale Rd, Suite 425<br />
					Houston, TX  77072<br />
					<a href="mailto:FPIAdmin@flightprointl.com">FPIAdmin@flightprointl.com</a><br />
					346-415-6920<br />
				</p>
				<p>You agree that where Company requires that you provide an e-mail address, you are responsible for providing Company with your most current e-mail address. If you provide Company such email address you agree that any notice required by these Terms may be delivered to you using your most recent email address on file with the Company. In the event that the last e-mail address you provided to Company is not valid, or for any reason is not capable of delivering to you any notices required or permitted by these Terms, Company's dispatch of the e-mail containing such notice will nonetheless constitute effective notice. You may give notice to Company by sending an email to <a href="mailto:FPIAdmin@flightprointl.com">FPIAdmin@flightprointl.com</a>. Such notice shall be deemed given on the next business day after such e-mail is actually received by Company.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Notice to California Users</span>
				<p></p>
				<p>Under California Civil Code Section 1789.3, California users of the Services are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at Department of Consumer Affairs, Consumer Information Center, 1625 North Market Blvd., Suite N-112, Sacramento, California 95834, or by telephone at (800) 952-5210.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Updates</span>
				<p></p>
				<p>Company may make changes to these Terms from time to time. If Company makes changes, it will provide you with notice of such changes, such as by sending an email, providing a notice through the Platform, or updating the date at the top of these Terms. Unless Company says otherwise in its notice, the amended Terms will be effective immediately and your continued access to and use of the Platforms after Company provides such notice will confirm your acceptance of the changes. If you do not agree to the amended Terms, you must stop accessing and using the Platform.</p>
			</li>
		</ol>
</div>
</div>
<div mat-dialog-actions>
	<button mat-button mat-dialog-close class="btn-low">Close</button>
</div>
