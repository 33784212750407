import { Component, ElementRef, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
//import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
//import { LK_FuelSupplier } from '../models/lk_fuelsupplier.model'; 


import { ResponseModel } from '../models/response.model';
  
import { AuthenticateService } from '../services/authenticate.service'; 
import { FuelSupplierModel } from '../models/fuelsupplier.model';
import { HttpClient,   HttpHeaders } from '@angular/common/http';
import { GlobalConstant } from '../common-utility/global-constant'; 
import { FuelPriceFileModel } from '../models/fuelpricefile.model';
import { CustomValidators } from '../common-utility/custom.validators';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload'; 
import { FileModel } from '../models/file.model'; 
import { ReturnObjModel } from '../models/return-obj.model';
import { ReviseFuelPricingModel } from '../models/revise-fuel-pricing.model';
import { Title } from '@angular/platform-browser';
import { FuelService } from '../services/fuel.service';
import { DialogService } from '../services/dialog.service';
import { FuelPriceUploadStatus } from '../models/fuelPriceUploadStatus';
import { Router } from '@angular/router';
import { FuelMenuComponent } from '../fuelmenu/fuel-menu.component';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { CountryService } from '../services/country.service';
import { CountryModel } from '../models/country.model';
import { Domfrequencylist } from '../models/domfrequencylist.model';
import { UnitofmeasureModel } from '../models/unitofmeasure.model';
import { CurrencyModel } from '../models/currency.model';
import { LK_FuelFrequencyModel } from '../models/LK_FuelFrequency';
import { FuelSupplierEditDialogComponent } from '../fuelmenu/fuel-supplier-profile-edit-dialog.component';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { TemplateLegendDialogComponent } from '../templates/template-legend-dialog.component';
 

@Component({
  selector: 'app-fuelpricing',
  templateUrl: './fuelpricing.component.html',
  styleUrls: ['./fuelpricing.component.css']
})
export class FuelpricingComponent implements OnInit {
  @Input() isActive: number;
  @ViewChild('document') fileInput!: ElementRef; 
  fuelpricing_form: FormGroup;
  fuelSupplierList: FuelSupplierModel[];
  Lk_FuelSupplierGUID: string;
  fuelSupplier: string;
  errMsg: string;
  loading = false;   
  submitted: boolean; 
  isFileInputDisabled :boolean= true;
  //fuelpricefile: FuelPriceFileModel; 
   
  fuelSuppliersList: FuelPriceFileModel[] = [];
  fuelpriceList: FuelPriceFileModel[] = [];
  fuelPriceFileModel: FuelPriceFileModel; 

  finishUploads: boolean;  
  finishAllUploads: boolean; 
  allowedFileTypeList: ['csv', 'xlsx', 'xls']; 

  maxFileSize = 0;
  allowedFileType: string;
  uploader: FileUploader;
  fileList: FileModel[];

  file: File;
  selectedFile: File;


  showButton: boolean;
  fromFuelPricingSummary: boolean = false;
  attachedfilename: string='';
   
  fs_select: string;// ='default';
  maxTempDocId: number = 0;
  selectedValue: any;
  enablefileupload: boolean;
  showSuccessMsg: boolean;
  successMsg: string;
  websiteEnvironment: string = "";


  revisefuelpricinglist: ReviseFuelPricingModel[] = [];
  fuelPriceUploadStatus: FuelPriceUploadStatus;
  successCount: number;
  failureCount: number;
  excludedCount: number = 0;
  grantAccessToFuel: boolean = false;

  displayedColumn: string[] = ['IATA', 'ICAO','FAAID', 'FuelSupplierShortname', 'ModifiedDate', 'ModifiedBy']; 
  fuelSupplierName: string;
  fuelSupplierShortname: string;
  domfrequency: string;
  intlfrequency: string;
  fuelscaleneedsadjustment: boolean;
  taxesIncludedDom: boolean;
  taxesIncludedInt: boolean;
  isAllowImport: boolean;
  removeextrarows: boolean;
  internalNotes: string;
  fuelReleaseEmail: string;
  isActiveVar: boolean;
  selecteddomfrequency: string;
  modifiedBy: string;
  modifiedDate: string;
  excludedCountriesVal: number[];
  fuelSupplierWebsite: string;
  unitofmeasure: number ;
  unitofmeasureVal: string = 'n/a';
  currency: string = 'n/a';
  taxesIncludedDomVal: string = 'n/a';
  taxesIncludedIntVal: string = 'n/a';
  domFrequencyVal: string = 'n/a';
  intFrequencyVal: string = 'n/a';
  
  fuelscaleneedsadjustmentVal: string = 'n/a';
  domfrequencylist: Domfrequencylist[];
  intlfrequencylist: Domfrequencylist[];
  unitofmeasurelist: UnitofmeasureModel[];
  currencylist: CurrencyModel[];
  currencyID: number;
  countryList: CountryModel[];
  selectedCountryList: CountryModel[];
  missingColumns: string[] = [];// ["ICAO", "IATA","MinMax_UnitOfMeasure", "TotalPrices", "BaseFuel", "Min_UnitOfMeasure"];
  isValidated: boolean = false;
  expiredRecordsCount: number = 0;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  totalRecordsCount: number = 0;
  considerExpiredRecords: boolean = true;
  excludedCountryIds: string = '';
  
  constructor(
    private readonly _formBuilder: UntypedFormBuilder, private readonly _fuelService: FuelService,
    private readonly _authService: AuthenticateService, private http: HttpClient, private readonly _countryService: CountryService,
    private _titleService: Title, private _dialogService: DialogService, private _router: Router, private readonly _dialog: MatDialog,
    @Optional()  private readonly _dialogRef: MatDialogRef<FuelpricingComponent>,
     @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    if (data != null) {
      this.fromFuelPricingSummary = data.fromFuelPricingSummary;
    }
    this.isFileInputDisabled = true;
  }

  initControls() {
    this.fuelpricing_form = this._formBuilder.group({ 
      document: [{ value: '', disabled: true }],
      fs_select: [this.fs_select], 
      filename: [this.attachedfilename],       
    });
  }

  get f() { return this.fuelpricing_form.controls; }

  ngOnInit() {
    this.grantAccessToFuel = false;
    if (localStorage.getItem('up.fuel') == 'true') {
      this.grantAccessToFuel = true;
    }


    if (!this.grantAccessToFuel) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.Lk_FuelSupplierGUID = '';
    this.fuelSupplierList = [];

    this.showButton = false;
    this.isFileInputDisabled = true;
     

    this.fs_select = 'default';
    this._authService.updateAccessTime();

    this.errMsg = "";

    this.fileList = [];
    this.allowedFileTypeList = ['csv', 'xlsx', 'xls'];
    this.maxFileSize = 0;
 
    this.unitofmeasureVal = 'n/a';
    this.currency = 'n/a';
    this.taxesIncludedDomVal = 'n/a';
    this.taxesIncludedIntVal = 'n/a';
    this.domFrequencyVal = 'n/a';
    this.intFrequencyVal = 'n/a'; 
    this.fuelscaleneedsadjustmentVal = 'n/a'; 
    this.selectedCountryList = [];

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }
    this.fuelSuppliersList = [];

     this.uploader = new FileUploader({
        url: GlobalConstant.baseUrl + "Common/uploadFiles",
        method: "post",
        authToken: 'bearer ' + localStorage.getItem('token'),
        disableMultipart: false
      });
      this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
      this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
      this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
      this.uploader.onCompleteAll = () => {
        this.finishAllUploads = true;
        this.uploader.clearQueue();
      };
  
       
    this._fuelService.getActiveFuelSupplier<ResponseModel<FuelSupplierModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {

        if (response.model.length > 0) {
          this.fuelSupplierList = response.model;
        }
      }

    })
    
    this.getData().subscribe(responses => {
      if (responses != null) { }
      this.domfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';
      this.intlfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';


      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {

          this.domfrequencylist = responses[0].model;
          this.intlfrequencylist = responses[0].model;

          let domfreq = new LK_FuelFrequencyModel();
          let intlfreq = new LK_FuelFrequencyModel();

          domfreq = responses[0].model;
          intlfreq = responses[0].model;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.unitofmeasurelist = responses[1].model;


          let uom = new UnitofmeasureModel();

          uom = responses[0].model;
          if (uom.lk_UnitOfMeasureID == 'undefined') {
            this.unitofmeasureVal = uom.lk_UnitOfMeasureID;
          }

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.currencylist = responses[2].model;


          let curr = new CurrencyModel();

          curr = responses[0].model;
          if (curr.lk_currencyID != null) {
            this.currencyID = curr.lk_currencyID;
          }
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.countryList = responses[3].model; 
          // let curr = new CurrencyModel(); 
         this.countryList.forEach(c => c.selected = true);

        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

     


    });

   
    this.initControls();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onFileClick(event) {
    this.errMsg = "";
    event.target.value = '';
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

    let result = JSON.parse(response); //success server response 
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {

          result.model.forEach((item, index) => {
            let obj = new FileModel();
            this.maxTempDocId += 1;
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            obj.tempId = this.maxTempDocId;

            this.fileList.push(obj);

            let obj2 = new FuelPriceFileModel();
            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.documentSizeInKB = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.tempId = this.maxTempDocId;
            obj2.AttachmentGUID = "";
            obj2.groundStopTaskDocumentGUID = "";
            obj2.documentGUID = "";
             
            obj2.fuelSupplier = this.fuelSupplier;
             
            this.fuelPriceFileModel = obj2;
            this.attachedfilename = item.fileName;
            //    this.fsFile=obj2;
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
       
    }

  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }
  
  uploadSubmit() {
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }   

  importFile(event: any) {

    this.revisefuelpricinglist = null;
   
    this.finishAllUploads = false;

    var files = [];
    this.errMsg = ""
    if (event.target.files.length > 1) {//this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {     
     // this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;
        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {

          files.push(event.target.files[i]);


        }
        else {
          this.finishAllUploads = true;
          this.fileInput.nativeElement.value = '';
          this.showButton = false;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }
    }

    if (this.errMsg != "") {
      this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
    this.showButton = true;   
    setTimeout(() => {
      //this.setMessageBody(""),
        1000;
    });
  }

  clickSend() {
    this.errMsg = "";
    this._authService.updateAccessTime();
     
    this.loading = true;
    let request = new FuelPriceFileModel();
    request = this.fuelPriceFileModel;

    request.unitOfMeasureID = this.unitofmeasure;
    request.currency = this.currency; 
    request.taxesIncludedDom = this.taxesIncludedDomVal;
    request.taxesIncludedInt = this.taxesIncludedIntVal;
    request.expiryDom = this.domFrequencyVal;
    request.expiryInt = this.intFrequencyVal;
    request.considerExpiredRecords = this.considerExpiredRecords;
    request.excludedCountryIds  = this.excludedCountryIds;
    request.fuelSupplierGUID = this.Lk_FuelSupplierGUID;
     
    this._fuelService.uploadFuelPriceData<ResponseModel<FuelPriceUploadStatus>>(request).subscribe(response => { 
      if (response != null && response.code == "200") {
        if (response.model != null) {
      //    let m = response.model;
          let d = new Date();
          let m1 = d.getMonth() + 1;
          let dt = m1.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();


          this.fuelPriceUploadStatus = response.model;

          this.revisefuelpricinglist = this.fuelPriceUploadStatus.reviseFuelPricingList;
          this.missingColumns = this.fuelPriceUploadStatus.missingColumns;
           

          //if (m == 1) {
          //if (this.revisefuelpricinglist != null) {
          if (this.fuelPriceUploadStatus.status == true) {
            if (this.fuelPriceUploadStatus.reviseFuelPricingList.length != 0) {
              this.successCount = this.fuelPriceUploadStatus.reviseFuelPricingList[0].successCount;
              this.failureCount = this.fuelPriceUploadStatus.reviseFuelPricingList[0].failureCount;
              this.excludedCount = this.fuelPriceUploadStatus.reviseFuelPricingList[0].excludedCount;
              this.showButton = false;
              this.isFileInputDisabled = false;
              this.isFileInputDisabled = true;
              this.fileInput.nativeElement.value = '';
              this.isValidated = true;

              this.attachedfilename = '';
              this.fs_select = 'default';
              this.showSuccessMsg = true;
              this.successMsg = "Fuel Price Upload Successful. " ;        //+ this.successCount + " Records Updated.";
              this.loading = false; 

              if (this.fromFuelPricingSummary == true) {
                setTimeout(() => {
                  let returnResponse = new ReturnObjModel();
                  returnResponse.refresh = true;
                  // returnResponse.newId = this.fuelPricingId.toString();
                  // this.showSuccessMsg = false;
                  // this._dialogRef.close(returnResponse);
                }, 1000);
              }
              else {
                setTimeout(() => {
                  this.showSuccessMsg = false;
                  //this.clickClose(true);
                }, 1000);
              }
            }
            else {
              this.successCount = this.fuelPriceUploadStatus.datacount;
              this.failureCount = 0;
              this.showSuccessMsg = true;
              this.showButton = false;
              this.isFileInputDisabled = false;
              this.isFileInputDisabled = true;
              this.fileInput.nativeElement.value = '';

              this.attachedfilename = '';
              this.fs_select = 'default';
              this.successMsg = "Fuel Price Upload Successful.";
              this.loading = false; 

              if (this.fromFuelPricingSummary == true) {
                setTimeout(() => {
                  let returnResponse = new ReturnObjModel();
                  returnResponse.refresh = true;
                  // returnResponse.newId = this.fuelPricingId.toString();
                  this.showSuccessMsg = false;
                  // this._dialogRef.close(returnResponse);
                }, 1000);
              }
              else {
                setTimeout(() => {
                  this.showSuccessMsg = false;
                  //this.clickClose(true);
                }, 1000);
              }
            }
          }
          else {
            this.errMsg = "Failed to upload Fuel Price. Please try again.";
            this.loading = false; 
          }
        }
      }
      else{
        this.errMsg = "Failed to upload Fuel Price. Please try again."; //response.message.substring(0, 150);
        this.loading = false; 

      }

    })

  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }
  
  fuelSupplierChange(event: any) { 
    this.showSuccessMsg = false;

    this.unitofmeasureVal = 'n/a';
    this.currency = 'n/a';
    this.taxesIncludedDomVal = 'n/a';
    this.taxesIncludedIntVal = 'n/a';
    this.domFrequencyVal = 'n/a';
    this.intFrequencyVal = 'n/a';
    // this.unitofmeasurelist = [];
    // this.intlfrequencylist = [];
    // this.domfrequencylist = [];
    // this.intlfrequencylist = [];
    this.selectedCountryList = [];
    
    this.fuelscaleneedsadjustmentVal = 'n/a';
    this.revisefuelpricinglist = null;
    let fuelsupplier = event.target.options[event.target.options.selectedIndex].text;
    //  this.selectedCommAddressType = text;
   // this.revisefuelpricinglist = false;
     let index = event.target.options.selectedIndex;
    this.Lk_FuelSupplierGUID = this.fuelSupplierList[index-1].fuelSupplierGUID;
    let name =  this.fuelSupplierList[index-1].fuelSupplierName;
    const selectedValue = (event.target as HTMLSelectElement).value;
    if (index == 0) {
      this.showButton = false;
      this.isFileInputDisabled = true;
      this.fileInput.nativeElement.value = '';     
    }
    else {
      //this.showButton = true;
      this.isFileInputDisabled = false;
      this.fuelSupplier = fuelsupplier;     
    }

    this.getData().subscribe(responses => {
      if (responses != null) { }
      this.domfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';
      this.intlfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';


      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          let fs = new FuelSupplierModel();
          fs = responses[4].model;
          this.fuelSupplierName = fs.fuelSupplierName;
          this.fuelSupplierShortname = fs.fuelSupplierShortname;
          this.domfrequency = fs.lk_FuelFrequencyDomGUID;
          this.intlfrequency = fs.lk_FuelFrequencyIntGUID;
          this.fuelscaleneedsadjustment = fs.fuelScaleNeedsAdjustment;
          this.fuelscaleneedsadjustmentVal  = this.fuelscaleneedsadjustment  == true ? 'Yes' : 'No';

          this.taxesIncludedDom = fs.taxesIncludedDom;
          this.taxesIncludedDomVal  = this.taxesIncludedDom  == true ? 'Yes' : 'No';
          this.taxesIncludedInt = fs.taxesIncludedInt;
          this.taxesIncludedIntVal  = this.taxesIncludedInt  == true ? 'Yes' : 'No';
          this.unitofmeasure = fs.lk_UnitOfMeasureID;
          this.currency = fs.currencyID;
          this.isAllowImport = fs.isAllowImport;
          this.removeextrarows = fs.removeExtraRows;
          this.internalNotes = fs.internalNotes;

          this.fuelSupplierWebsite = fs.fuelSupplierWebsite;
          this.fuelReleaseEmail = fs.fuelReleaseEmail;
          this.isActiveVar = fs.isActive;
          this.selecteddomfrequency = fs.lk_FuelFrequencyDomGUID;
          this.modifiedBy = fs.modifiedBy;
          this.modifiedDate = fs.modifiedDate;
          // Assuming countryID is a number
          this.excludedCountriesVal = fs.excludedCountries?.split(',').map(id => +id);
          this.excludedCountryIds =   fs.excludedCountries;

        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      } 

      this.getFuelSupplierDefaultValues();

    });
  }

  clickClose() {
    let returnResponse = new ReturnObjModel();
    returnResponse.refresh = true;
   // returnResponse.newId = this.fuelPricingId.toString();
    //this.showSuccessMsg = true;
    this._dialogRef.close(returnResponse);
  }

  downloadFile() {
    let link = document.createElement("a");
    link.download = "FuelPricingTemplate";
    link.href = "assets/templates/FuelPricingTemplate.xlsx";
    link.click();
  }

  clickRoute(routePage: string) {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(FuelMenuComponent, config);   
    this.clickClose();
  }

  getData(): Observable<any[]>{
    let getDomFrequency = this._fuelService.getDomFrequency();
    //let getIntlFrequency = this._fuelService.getDomFrequency();
    let getUnitOfMeasure = this._fuelService.getUnitOfMeasure();
    let getCurrency = this._fuelService.getCurrency();
    //  this._commonService.getCountryListWithUSAFirst<ResponseModel<FuelSupplierModel[]>>();
    let getCountryResponse = this._countryService.getCountryBySearchKey<ResponseModel<CountryModel[]>>(new CountryModel()) ;


    if (this.Lk_FuelSupplierGUID != null && this.Lk_FuelSupplierGUID != "") {
      let getFuelSupplierResponse = this._fuelService.getfuelSupplierByfsId(this.Lk_FuelSupplierGUID);
      return forkJoin([getDomFrequency, getUnitOfMeasure, getCurrency,getCountryResponse, getFuelSupplierResponse, ]);//, getUnitOfMeasure, getCurrency]);
    }
    else {
      return forkJoin([getDomFrequency, getUnitOfMeasure, getCurrency, getCountryResponse]);//[getDomFrequency, getIntlFrequency, getUnitOfMeasure, getCurrency]);
    }
  }

  getFuelSupplierDefaultValues() {
    this.unitofmeasureVal = this.unitofmeasurelist.filter(ul => Number(ul.lk_UnitOfMeasureID) == this.unitofmeasure)[0].unitOfMeasure;
    const matchingItem = this.intlfrequencylist.find(il => il.lk_FuelFrequencyGUID == this.intlfrequency);


    this.domFrequencyVal = this.domfrequencylist.filter(dl => dl.lk_FuelFrequencyGUID === this.domfrequency)[0].lk_FuelFrequencyDescription;
    this.intFrequencyVal = this.intlfrequencylist.filter(dl => dl.lk_FuelFrequencyGUID === this.intlfrequency)[0].lk_FuelFrequencyDescription;
    // this.selectedCountryList = this.countryList.forEach(cl => cl.countryID == this.excludedCountriesVal.)

    this.selectedCountryList = this.countryList.filter(country =>
      this.excludedCountriesVal?.includes(country.countryID)
    );
    // this.excludedCountryIds = '';


  }
  clickReset(){
    
    this.Lk_FuelSupplierGUID = ''; 
    this.unitofmeasureVal = 'n/a';
    this.currency = 'n/a';
    this.taxesIncludedDomVal = 'n/a';
    this.taxesIncludedIntVal = 'n/a';
    this.domFrequencyVal = 'n/a';
    this.intFrequencyVal = 'n/a'; 
    this.fuelscaleneedsadjustmentVal = 'n/a'; 
    this.selectedCountryList = [];
  }

  
  clickEdit(event: any, p: FuelSupplierModel) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    // dialogConfig.hasBackdrop = false;
    dialogConfig.disableClose = false;
    //dialogConfig.width = "50em";//"1000px";
    //dialogConfig.height = "20em";//"500px";
    dialogConfig.disableClose = true;

    let fuelSupplierGUID = "";
    let s = "";
    if (p != null) {
      s = "EDIT Record";
      fuelSupplierGUID = p.fuelSupplierGUID;
    }
    else {
      s = "ADD New Record";
    }
    dialogConfig.data = { dialogTitle: s, fuelSupplierGUID: this.Lk_FuelSupplierGUID };
    const dialogRef = this._dialog.open(FuelSupplierEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.refresh) {
          this.getEditResults();
        } 

      }
    });

  }
  getEditResults() {

    this.getData().subscribe(responses => {
      if (responses != null) { }
      this.domfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';
      this.intlfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';


      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          let fs = new FuelSupplierModel();
          fs = responses[4].model;
          this.fuelSupplierName = fs.fuelSupplierName;
          this.fuelSupplierShortname = fs.fuelSupplierShortname;
          this.domfrequency = fs.lk_FuelFrequencyDomGUID;
          this.intlfrequency = fs.lk_FuelFrequencyIntGUID;
          this.fuelscaleneedsadjustment = fs.fuelScaleNeedsAdjustment;
          this.fuelscaleneedsadjustmentVal  = this.fuelscaleneedsadjustment  == true ? 'Yes' : 'No';

          this.taxesIncludedDom = fs.taxesIncludedDom;
          this.taxesIncludedDomVal  = this.taxesIncludedDom  == true ? 'Yes' : 'No';
          this.taxesIncludedInt = fs.taxesIncludedInt;
          this.taxesIncludedIntVal  = this.taxesIncludedInt  == true ? 'Yes' : 'No';
          this.unitofmeasure = fs.lk_UnitOfMeasureID;
          this.currency = fs.currencyID;
          this.isAllowImport = fs.isAllowImport;
          this.removeextrarows = fs.removeExtraRows;
          this.internalNotes = fs.internalNotes;

          this.fuelSupplierWebsite = fs.fuelSupplierWebsite;
          this.fuelReleaseEmail = fs.fuelReleaseEmail;
          this.isActiveVar = fs.isActive;
          this.selecteddomfrequency = fs.lk_FuelFrequencyDomGUID;
          this.modifiedBy = fs.modifiedBy;
          this.modifiedDate = fs.modifiedDate;
          // Assuming countryID is a number
          this.excludedCountriesVal = fs.excludedCountries?.split(',').map(id => +id);
          this.excludedCountryIds =   fs.excludedCountries;

        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      } 

      this.getFuelSupplierDefaultValues();

    });  }
  
    validateFileData() {
      this.errMsg = "";
      this._authService.updateAccessTime();
       
      this.isValidated = false;
      this.loading = true;
      let request = new FuelPriceFileModel();
      request = this.fuelPriceFileModel;
  
      request.unitOfMeasureID = this.unitofmeasure;
      request.currency = this.currency; 
      request.taxesIncludedDom = this.taxesIncludedDomVal;
      request.taxesIncludedInt = this.taxesIncludedIntVal;
      request.expiryDom = this.domFrequencyVal;
      request.expiryInt = this.intFrequencyVal;
       
      this._fuelService.validateFuelPriceData < ResponseModel<FuelPriceFileModel>>(request).subscribe(response => { 
        if (response != null && response.code == "200") {
          if (response.model != null){

            this.missingColumns = response.model.missingColumns;
            this.expiredRecordsCount = response.model.expiredRecordsCount;
            this.totalRecordsCount = response.model.totalRecordsCount;
            if(this.missingColumns.length == 0){
              this.successMsg = "All Columns are matched Succesfully. " ;        //+ this.successCount + " Records Updated.";
              

              if (this.expiredRecordsCount > 0) {
                let dialogTitle = ' Total Records - ' + this.totalRecordsCount + '. Expired Records - ' + this.expiredRecordsCount + '. Do you want to Proceed?';
                this.openConfirmationComponent(this.expiredRecordsCount, this.totalRecordsCount);
              }

              else{
                this.showSuccessMsg = true; 
                // this.isValidated = true;
                this.clickSend();
                setTimeout(() => {
                  this.showSuccessMsg = false;
                }, 2000);
              }
              // if(this.expiredRecordsCount > 0){
                // this.isValidated = this.showDraftAlert();
                // this.showDraftAlert(this.expiredRecordsCount, this.totalRecordsCount);

                // let dialogTitle = ' Total Records - ' + this.totalRecordsCount + '. Expired Records - '+ this.expiredRecordsCount +'. Do you want to Proceed?';
                // this.openConfirmationComponent(dialogTitle);
                // setTimeout(() => {
                  
                //   this.showSuccessMsg = true;
                // }, 1000);
              // }
              
              // this.isValidated = true;
              // setTimeout(() => {
              //   this.showSuccessMsg = false;

              // }, 1000);
            } 
            else{
              this.errMsg = 'Specified Columns are Missing in the Excel File.'
              this.isValidated = false;

              setTimeout(() => {
                this.errMsg = '';
              }, 2500);
            }
          }
        }
  
        // this.loading = false; 
      })
  
    }

  showDraftAlert(expiredCount: number, totalCount: number) {
    this.confirmDialogRef = this._dialogService.openConfirmDialog([' Total Records - ' + totalCount + '. Expired Records - '+ expiredCount +'. Do you want to Proceed?']);
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) { 
        this.showSuccessMsg = true; 
        // this.isValidated = true;
        this.clickSend();
        setTimeout(() => {
          this.showSuccessMsg = false;
        }, 2000);
        this.isValidated = result;

      }
      else {
        this.isValidated = result;
      }
    });
  }

  openConfirmationComponent(expiredCount: number, totalCount: number) {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container2"
    //config.minWidth = "76em";
    // config.maxWidth = "35em";//"1000px";
    config.height = "15em";//"1000px";
    config.restoreFocus = false;
    // config.hasBackdrop = false;
    // config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() ,expiredCount:expiredCount, totalCount: totalCount,  dialogTitle:'dialogTitle' };
    const dialogRef = this._dialog.open(TemplateLegendDialogComponent, config);  
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.showSuccessMsg = true; 
        this.considerExpiredRecords = true;
        this.clickSend();
        setTimeout(() => {
          this.showSuccessMsg = false;
        }, 2000);
        this.isValidated = true;
      }
      else if (result == 'filterExpired') {
        this.showSuccessMsg = true;  
        this.considerExpiredRecords = false;
        this.clickSend();
        setTimeout(() => {
          this.showSuccessMsg = false;
        }, 2000);
        this.isValidated = true;
      }
      else{
        this.isValidated = false;

      }

    });
  }


}

