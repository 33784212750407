import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticateService } from '../services/authenticate.service';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html'//,
  //styleUrls: ['./update-user.component.css']
})

export class BlankComponent implements OnInit {


  constructor(private readonly _authService: AuthenticateService) {


  }

  ngOnInit() {
    //console.log(localStorage.getItem('keepMeSignedIn'));
    this._authService.updateAccessTime();
  }
}
