
<div class="p-2 d-flex justify-content-between align-items-center" style="background-color:#f8f8f8"  cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>
  <div>&nbsp;</div>
  <div class="d-inline-flex">
    <span class="material-icons-outlined mr-1">help_outline</span>
    <h5>Help Guides</h5>
  </div>
  <button mat-icon-button [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="p-2">
  <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
    <ngb-panel id="custom-panel-1">
      <ng-template ngbPanelHeader let-opened="true">

      </ng-template>
      <ng-template ngbPanelContent>
        
        <mat-selection-list #airports [multiple]="false" style="margin-left:-1em;">
          <mat-list-option *ngFor="let cat of categoryList" (click)="showDetails(cat)">
            <span class="d-flex"><span class="material-icons-outlined mr-1">description</span>{{cat.categoryTitle}}</span>
          </mat-list-option>
        </mat-selection-list>
        <!--<ng-template ngFor let-cat [ngForOf]="categoryList">
    <div>
      <button mat-button (click)="showDetails(cat.categoryID)"><mat-icon>description</mat-icon>{{cat.categoryTitle}}</button>
    </div>
  </ng-template>-->
      </ng-template>
    </ngb-panel>
    <ngb-panel>
      <ng-template ngbPanelHeader>
        <button ngbPanelToggle class="accordion-button"><span class="material-icons-outlined mr-1">live_help</span>Need More Help?</button>
      </ng-template>
      <ng-template ngbPanelContent>
        <div style="font-size:small">
          <p>Experienced, Knowledgeable, Trustworthy 24 / 7 / 365</p>
          <div><strong>Customer Support Phone</strong>: 346-415-6900</div>
          <div>
            <strong>Customer Support Email</strong>: Ops@flightprointl.com
            <div *ngIf="isInternal">
              <strong>Fuel Estimates/Release</strong>: Fuel@flightprointl.com
            </div>
          </div>
          <p></p>
          <div>
            <div><strong>Toll-Free USA/Canada</strong>: +1 866 356 1055</div>
            <div><strong>Worldwide</strong>: +1 346 415 6900</div>
          </div>
          <div>
            <div *ngIf="isInternal"><strong>Invoicing Support</strong>: Accounting@flightprointl.com</div>
            <div><strong>Sales Email Address</strong>: Sales@flightprointl.com</div>
          </div>
          <p></p>

          <div>
            <div><strong>Office Address</strong></div>
            <div>
              6002 Rogerdale Rd Suite 425,
            </div>
            <div>
              Houston, TX 77072
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  
  
  
    <ngb-panel id="fbPanel" *ngIf="type == 'ops'">
      <ng-template ngbPanelHeader>
        <button ngbPanelToggle class="accordion-button"><span class="material-icons-outlined mr-1">
          chat
        </span>Submit Feedback</button>
      </ng-template>
      <ng-template ngbPanelContent>
        <textarea class="form-control" [(ngModel)] = "feedbackMsg" style="width:55em; height:10em;font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>
        <button mat-button (click)="submitFeedback()">Submit</button>
        <app-success-message *ngIf="showSuccessMsg" [successMsg]="'Feedback sent'"></app-success-message>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
