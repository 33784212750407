import { Component, Inject, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable } from 'rxjs';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { TripCloseOutModel } from '../models/trip-close-out.model';
import { CustomerAccountingProfileModel } from '../models/customer-accounting-profile.model';
import { TripSetupNoteDialogComponent } from './trip-setup-note-dialog.component';
import { MessageService } from '../services/message.service';
import { FPCBillingSummaryModel } from '../models/fpc-billing-summary.model';
import { DialogService } from '../services/dialog.service';
import { TripLegService } from '../services/trip-leg.service';
import { UtilityFunctions } from '../common-utility/utility.functions';
import PizZipUtils from 'pizzip/utils';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { TripSummaryLegModel } from '../models/trip-summary-leg.model';
import { Workbook } from 'exceljs';
import { TripLegModel } from '../models/trip-leg.model';
import { EmailInternalDialogComponent } from '../messages/email-internal-dialog.component';
import { FileModel } from '../models/file.model';
import { MessageAttachmentModel } from '../models/message-attachment';

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

var expressions = require("angular-expressions");
var assign = require("lodash/assign");
// define your filter functions here, for example
// to be able to write {clientname | lower}
expressions.filters.lower = function (input) {
  // This condition should be used to make sure that if your input is
  // undefined, your output will be undefined as well and will not
  // throw an error
  if (!input) return input;
  return input.toLowerCase();
};

function angularParser(tag) {
  tag = tag
    .replace(/^\.$/, "this")
    .replace(/(�|�)/g, "'")
    .replace(/(�|�)/g, '"');
  const expr = expressions.compile(tag);
  return {
    get: function (scope, context) {
      let obj = {};
      const scopeList = context.scopeList;
      const num = context.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = assign(obj, scopeList[i]);
      }
      return expr(scope, obj);
    },
  };
}

function parser(tag) {
  // We write an exception to handle the tag "$pageBreakExceptLast"
  if (tag === "$pageBreakExceptLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (!isLast) {
          return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }
  if (tag === "$pageBreak") {
    return {
      get(scope, context) {
        return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';

      },
    };
  }



  // We use the angularParser as the default fallback
  // If you don't wish to use the angularParser,
  // you can use the default parser as documented here:
  // https://docxtemplater.com/docs/configuration#default-parser
  return angularParser(tag);
}

const datepipe: DatePipe = new DatePipe('en-US')

@Component({
  selector: 'app-trip-setup-dialog',
  templateUrl: './trip-setup-dialog.component.html',
  styleUrls: ['./trip-setup-dialog.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})
export class TripSetupDialogComponent implements OnInit {
  
  customerGUID: string;
  tripCodeGUID: string;
  trip_setup_form: UntypedFormGroup;
  loading = false;
  downloading1 = false;
  downloading2 = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = false;
  tripCloseOutList: TripCloseOutModel[];  
  registration: string;
  tripCode: string;
  dateRange: string;
  groundStopList: GroundStopModel[];
  selectedTripCloseOutID: number;
  lockdown: boolean;
  removeFromExternalView: boolean;
  customerReference: string;
  remarks: string;
  successMsg: string = "Your changes have been saved";
  showSuccessMsg: boolean = false;
  
  clientAcctProfileList: CustomerAccountingProfileModel[];
  fpcBillingList: FPCBillingSummaryModel[];
  displayedColumnClientProfile: string[] = ['referencePO', 'registration', 'service', 'crew', 'pax'];
  displayedColumnBilling: string[] = ['type', 'invoiceNum', 'invoiceDate'];
  loadingEmailAlert: boolean = false;
  oldTripCloseOutDescription: string;
  
  submittedBy: string;
  client_trip_number: string;
  submitted_date: Date;
  @ViewChild('submitted_date') submitted_dateRef: ElementRef;
  submitMaxDate: Date = new Date();
  aircraftGUID: string;
  upLockTrip: boolean;
  isModified: boolean;
  reportList: string[] = ["report1","report2"];
  selectedReport: string;
  route: string;
  customerName: string;
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";

  
  @ViewChild('remarksWrapper') remarksTemplate: TemplateRef<any>;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog, private readonly _dialogService: DialogService,
    private readonly _groundStopService: GroundStopService, private readonly _messageService: MessageService,
    private readonly _dialogRef: MatDialogRef<TripSetupDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _tripLegService: TripLegService
  ) {
      this.tripCodeGUID = _data.tripCodeGUID;
      this.aircraftGUID = _data.aircraftGUID;
      this.customerName = _data.customerName;
      this.route = _data.route;
      this.customerGUID = "";
      if (_data.customerGUID != null && _data.customerGUID != undefined && _data.customerGUID != "") {
        this.customerGUID = _data.customerGUID;
      }
  }

  getAllData(): Observable<any[]> {
    let getTripCloseOutResponse = this._groundStopService.getTripCloseOutList<ResponseModel<TripCloseOutModel[]>>();
    let obj = new TripModel();
    obj.tripCodeGUID = this.tripCodeGUID;
    let getTripRespose = this._groundStopService.getTripForTripSetupBytId<ResponseModel<TripModel>>(obj);
    obj.aircraftGUID = this.aircraftGUID;
    obj.customerGUID = this.customerGUID;
    let clientAcctProfileResponse = this._groundStopService.getCustomerAccountingProfileByTrip(obj);
    let obj1 = new FPCBillingSummaryModel();
    obj1.tripCodeGUID = this.tripCodeGUID
    let fpcbillingResponse = this._groundStopService.getFPCBillingSummaryBytId(obj1);
    return forkJoin([getTripCloseOutResponse, getTripRespose, clientAcctProfileResponse, fpcbillingResponse]);
  }

  ngOnInit() {
    if (localStorage.getItem('up.lockTrip') != 'true') {
      this.upLockTrip = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    else {
      this.upLockTrip = true;
    }
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.tripCloseOutList = [];
    this.selectedTripCloseOutID = 0;
    this.isModified = false;
    this.registration = "";
    this.tripCode = "";
    this.dateRange = "";
    this.groundStopList = [];
    this.customerReference = "";
    this.remarks = "";
    this.showSuccessMsg = false;
    this.clientAcctProfileList = [];
    this.fpcBillingList = [];
    this.oldTripCloseOutDescription = "";
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.tripCloseOutList = responses[0].model;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          let trip = new TripModel();
          trip = responses[1].model;
          this.registration = trip.registration;
          this.tripCode = trip.tripCode;
          this.dateRange = trip.estimatedTripStartDate;
          if (this.dateRange != "") {
            this.dateRange += " - ";
          }
          if (trip.estimatedTripEndDate != "") {
            this.dateRange += trip.estimatedTripEndDate;
          }
          this.selectedTripCloseOutID = trip.tripCloseOutID;
          this.lockdown = trip.lockdownTrip;
          this.removeFromExternalView = trip.removeFromExternalView;
          this.customerReference = trip.customerReference;
          this.remarks = trip.remarks;
          this.client_trip_number = trip.customerTripID;
          this.submittedBy = trip.submittedBy;
          this.submitted_date = new Date(trip.submitDate);
          this.groundStopList = trip.groundStopList;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.clientAcctProfileList = responses[2].model;

        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.fpcBillingList = responses[3].model;

        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.oldTripCloseOutDescription = this.tripCloseOutList.find(x => x.tripCloseOutID == this.selectedTripCloseOutID).tripCloseOutDescription;
      this.initControls();

      this.showSpin = false;


    });

  }

  initControls() {
    this.trip_setup_form = this._formBuilder.group({
      trip_closeout_select: [this.selectedTripCloseOutID],
      checkLockdownTrip: [this.lockdown],
      checkRemoveFromExternalView: [this.removeFromExternalView],
      trip_closeout_report_select: [null],
      customerReference: [this.customerReference],
      remarks: [this.remarks],
      submittedBy: [this.submittedBy],
      client_trip_number: [this.client_trip_number],
      submitted_date: [this.submitted_date, Validators.required]

    })
  }

  get f() { return this.trip_setup_form.controls; }

  tripCloseOutChange(e: any) {
    let t: number = Number(this.f.trip_closeout_select.value);
    if (t == 3 || t == 4) {
      this.f.checkLockdownTrip.setValue(true);
    }
    else {
      if (t == 1 || t == 5 || t == 7) {
        this.f.checkLockdownTrip.setValue(false);
      }
    }
  }

  tripCloseOutReportChange(e: any) {
    this.selectedReport = e.target.value;
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    if (this.submitted_dateRef.nativeElement.value !== "") {
      if (this.f.submitted_date.value != "" && this.f.submitted_date.value != null) {      
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.submitted_dateRef.nativeElement.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.submitted_date.setErrors({ isValidDate: f1 });

        }
      }
      else {
        this.f.submitted_date.setErrors({ isValidDate: false });

      }
    }

    if (this.trip_setup_form.invalid) {
      return;
    }

    if (this.f.trip_closeout_select.value == 4 && this.f.checkLockdownTrip.value == true) {
      this.doSubmit('');
    }
    else {
      // pop up note dialog
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.minWidth = "30em";
      let dialogHeight = "";
      //dialogConfig.height = "37em";
      //dialogHeight = "37em";
      //dialogConfig.minHeight = "37em";

      //dialogConfig.height = "40em";//"500px";
      dialogConfig.disableClose = true;

      dialogConfig.data = {
        v: this._authService.getCurrentTimeNumber()
      };

      const dialogRef = this._dialog.open(TripSetupNoteDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result.refresh == true) {
          this.doSubmit(result.remarks);
        }
      });
      // pop up note dialog end
    }

  }

  doSubmit(remark: string) {
    this.loading = true;
    let obj = new TripModel();
    obj.customerGUID = this.customerGUID;
    obj.aircraftGUID = this.aircraftGUID;
    obj.tripCloseOutID = Number(this.f.trip_closeout_select.value);
    obj.tripCodeGUID = this.tripCodeGUID;
    obj.lockdownTrip = this.f.checkLockdownTrip.value;
    obj.removeFromExternalView = this.f.checkRemoveFromExternalView.value;
    obj.customerReference = this.f.customerReference.value;
    obj.submittedBy = this.f.submittedBy.value;
    obj.customerTripID = this.f.client_trip_number.value;
    
    obj.submitDate = CustomValidators.formatDateToMMDDYYYY(new Date(this.submitted_dateRef.nativeElement.value));

    obj.remarks = remark;

    this._groundStopService.updateTripSetupBytId<ResponseModel<TripModel>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.isModified = true;
          let t = new TripModel();
          t = response.model;
          if (t.remarks != "") {
            this.f.remarks.setValue(t.remarks);
            this.f.checkLockdownTrip.setValue(t.lockdownTrip);
            //this.updateParentPage.emit({ updateSection: 'tripSetup', lockdownTrip: t.lockdownTrip });
            if (obj.tripCloseOutID != 2 && obj.tripCloseOutID != 4) {
              if (t.emailSent == true) {
                this.showSuccessMsg = true;
              }
            }
            else {
              this.showSuccessMsg = true;
            }
            setTimeout(() => {
              this.showSuccessMsg = false;
              this.clickClose();
            }, 1000);
          }
          else {
            this.errMsg = "Failed to update the trip setup at this time. Please try later.";
          }

          //this.showSuccessMsg = true;
          if (this.showSuccessMsg == true) {
            setTimeout(() => {
              this.showSuccessMsg = false;
            }, 1000);
          }
          else {
            if (t.emailSent == false) {
              this.errMsg = "Failed to send email alert at this time. Please try later.";
            }
          }

        }
        else {
          this.errMsg = "Failed to update the trip setup at this time. Please try later.";
        }
        
        this.loading = false;

      }
      else {
        if (response.code == "401") {
          if (response.message!="")
            this.errMsg = response.message
          else
            this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to update the trip setup at this time. Please try later.";
        }
        this.loading = false;
      }
      
    })

  }
  
  clickClose() {
    this._dialogRef.close(this.isModified);
  }
  
  async downloadExcel(){
    this.downloading1 = true;
    let trip: TripModel = new TripModel();
    trip.aircraftGUID=this.aircraftGUID;
    trip.customerGUID=this.customerGUID;
    trip.tripCodeGUID=this.tripCodeGUID;
    trip.includeActiveStopOnly =false;
    trip.isFromLegEdit=false;
    trip.tripCode=this.tripCode;
   
      this._tripLegService.downloadTripCSV<ResponseModel<string>>(trip).subscribe(response => {
        if (response != null && response.code == "200" && response.model != null) {
          let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
          let file = new Blob([byteArray], { type: "text/plain" });
          file.size
          var fileURL = URL.createObjectURL(file);
          var link = document.createElement('a');
          link.href = fileURL;
          link.download = this.tripCode + ".xlsx";
          link.click();
          this.downloading1 = false;
          //else {
          //  this.msg = "No documents returned";
          //}
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
        this.downloading1 = false;

      });
  }

  downloadReport() {
    switch (this.selectedReport) {
      case "report1":
        this.downloadExcel();
        break;
      case "report2":
        this.downloadWordReport();
        break;
    }
  }

  downloadWordReport() {
    this.downloading2 = true;
    let request = new TripModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.customerGUID = this.customerGUID;
    request.aircraftGUID = this.aircraftGUID;
    request.groundStopTypeGUIDList = "";
    request.includeActiveStopOnly = false;
    request.isFromLegEdit = false;
    this._tripLegService.getGroundStopLegListByConditions<ResponseModel<TripLegModel[]>>(request, true).subscribe(response => {
      if (response != null && response.code == "200") {
        let legList = [];
        let existingLegList = [];
        
        if (response.model.length > 0) {
          legList = response.model;
          legList.forEach(leg => {
            if (leg.departureGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID || 
                  leg.nextArrivalGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID)
            {
              leg.hasLegStatus = true;
              leg.legStatus = "Cancelled";
            }
            else if (leg.departureGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID ||
                  leg.nextArrivalGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID)
            {
              leg.hasLegStatus = true;
              leg.legStatus = "Contingency";
            }
            else
            {
              leg.hasLegStatus = false;
              leg.legStatus = "";
            }
          });
          this.createReport(legList);
          
        }
        else {          
        }
        
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.downloading2 = false;
    });
  }

  createReport(legList:any[]) {
    const filePath = window.origin + "/assets/templates/TripCloseoutSummaryTemplate.docx";
    loadFile(filePath, (error, content) => {
      if(error) throw error;
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser });
      let reportDate = datepipe.transform(new Date().toUTCString(), 'MMM-dd-yyyy HH:mm', "UTC");
      let reportID = UtilityFunctions.makeID(10).toUpperCase();
      
      doc.setData({
        clientName: this.customerName,
        reportDate: reportDate,
        reportID: reportID,
        tripCode: this.tripCode,
        tailNo: this.registration,
        route: this.route,
        minDate:"01/01/2020", //1st leg dep date
        maxDate:"01/01/2021", //last leg arr date
        tripSummaryLegs:legList
      });
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key];
              this.downloading2 = false;
              return error;
            },
              {});
          }
          return value;
        }
        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          console.error("errorMessages", errorMessages);
          //self.errMsg = "There was an error creating the report.";
          // errorMessages is a humanly readable message looking like this:
          // 'The tag beginning with "foobar" is unopened'
        }
        this.downloading2 = false;

        throw error;
      }
      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });

      saveAs(out, "Trip Summary Close out Report - " + this.tripCode + ".docx");
      this.downloading2 = false;
    });
  }

  combinedReportCalls(){
    let trip: TripModel = new TripModel();
    trip.aircraftGUID = this.aircraftGUID;
    trip.customerGUID = this.customerGUID;
    trip.tripCodeGUID = this.tripCodeGUID;
    trip.includeActiveStopOnly = false;
    trip.isFromLegEdit = false;
    trip.tripCode = this.tripCode;
    this.downloading1 = true;
    let excelreport = this._tripLegService.downloadTripCSV<ResponseModel<string>>(trip);

    let request = new TripModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.customerGUID = this.customerGUID;
    request.aircraftGUID = this.aircraftGUID;
    request.groundStopTypeGUIDList = "";
    request.includeActiveStopOnly = false;
    request.isFromLegEdit = false;
    this.downloading2 = true;
    let wordreport = this._tripLegService.getGroundStopLegListByConditions<ResponseModel<TripLegModel[]>>(request, true)
    return forkJoin([excelreport, wordreport]);
  }

  sendMessageClick() {
    this.combinedReportCalls().subscribe((resp1) => {
      let fileList: FileModel[] = [];
      let messageAttachmentList: MessageAttachmentModel[] = [];
      let file: FileModel = new FileModel();
      file.fileName = this.tripCode + ".xlsx";

      file.bFile = resp1[0].model;
      file.mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      fileList.push(file);

      let msgAttachment: MessageAttachmentModel = new MessageAttachmentModel();
      msgAttachment.fileExtension = "xlsx";
      msgAttachment.documentName = this.tripCode + ".xlsx";
      msgAttachment.bFile = resp1[0].model;
      msgAttachment.mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      messageAttachmentList.push(msgAttachment);

      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.hasBackdrop = false;
      dialogConfig.data = {
        dialogTitle: "New Message", msgAttachments: messageAttachmentList, fileList: fileList
      };
this.downloading1 = false;
this.downloading2 = false;
      const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
        }

      });

      //file.bFile = response;


    });
  }

  
  // downloadSampleExcel(){
  //   let wb=new Workbook();
  //   let ws =wb.addWorksheet("Test")

  //   let header=["id","name"]
  //   ws.addRow(header);

  //   ws.addRow(["1","Mani"]);
  //   ws.addRow(["2","Mani1"]);

  //   wb.xlsx.writeBuffer().then((data)=>{
  //     let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     saveAs(blob,"test.xlsx");
  //   });
  // }


  
  clickOpenInNew( ) {
    this._dialog.open(this.remarksTemplate);

  }

}
