<div class="divLeft" *ngIf="ua==1 || userType=='internal'" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isPopup">
  <div style="display: flex; flex-direction: column;">
    <div style="display: inline-flex; width: 100%; justify-content: space-between;">
      <div style="display: flex; flex-direction: column;">
        <h3>ADMIN SERVICES \ User Access & Permissions {{selectedUserTypeDisplay!=""?'- '+selectedUserTypeDisplay:''}}
        </h3>
        <h5 *ngIf="isPopup" style="color: #1976e6;">
          {{selectedCustomerName}}
        </h5>
      </div>
  
      <div *ngIf="isPopup" style="display: flex">
        <button mat-icon-button (click)="openAdminServicesComponent()"> <i class="material-icons-outlined"
            style="font-size:xx-large;  ">arrow_circle_left</i> </button>
        <button mat-icon-button mat-dialog-close><i class="material-icons" style="font-size: x-large;">close
          </i></button>
      </div>
    </div>
    <div style="background-color: white; padding: 0.5em;">
      <div style="padding-left:0.25em; padding-right:1.25em; width:94em">
        
        <div>
          <form *ngIf="user_permission_client_form" [formGroup]="user_permission_client_form" (ngSubmit)="onSubmit()">
            <div style="margin-left:0em;padding:0em; display: flex" *ngIf="userType=='internal'">
              <div style="margin-top:0.5em; padding:0em; display: flex">
                <!--<div class="form-group form-inline" style="margin-bottom:0em; margin-right:0.5em">
                    <ul class="tabul">
                      <li [ngClass]="{ 'btn-high':selectedUserType=='internal','btn-low pointer':selectedUserType!='internal'  }" style="border-left: black 0.0625em solid;" (click)="clickUserType('internal')"><div style="margin-top:0.25em">FPI Operations</div></li>
                      <li [ngClass]="{ 'btn-high':selectedUserType=='customer','btn-low pointer':selectedUserType!='customer' }" (click)="clickUserType('customer')"><div style="margin-top:0.25em">Client</div></li>
                      <li [ngClass]="{ 'btn-high':selectedUserType=='vendor','btn-low pointer':selectedUserType!='vendor' }"><div style="margin-top:0.25em">Vendor</div></li>
                    </ul>
                  </div>-->
  
                <div *ngIf="selectedUserType=='customer' && !isPopup" style="margin-top:-0.85em; ">
                  <label for="customer_select" style="font-size:small">Select Client</label>
                  <div style="margin-top:-0.5em;">
                    <select *ngIf="f" formControlName="customer_select"
                      style="font-size: small;width:19.5em;border-radius:0.25em; border-color:lightgrey"
                      (change)="customerChange($event)">
                      <option value="">Client Name</option>
                      <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="userType=='customer' && grantAccessToAccountGrouping && customerList.length>0"
              style="margin-top:-0.85em; ">
              <label for="customer_select" style="font-size:small">Select Client</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="customer_select"
                  style="font-size: small;width:19.5em;border-radius:0.25em; border-color:lightgrey"
                  (change)="customerChange($event)">
                  <!--<option value="">Client Name</option>-->
                  <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                </select>
              </div>
            </div>
  
            <div style="display:flex; margin-top:0.5em">
              <div style="margin-left: 0em;margin-top:0em; padding:0em">
                <label for="text_search" style="font-size:small">Search by {{textSearchBy}}</label>
                <div style="margin-left: 0em; margin-top:-0.5em">
                  <input type="text" formControlName="text_search" class="form-control"
                    style="width:9.75em;height:1.4em" />
                </div>
              </div>
              <div style="margin-left: 0.3125em; margin-top:1.35em">
                <select *ngIf="f" formControlName="permission_type"
                  style="font-size: small;border-radius:0.25em; border-color:lightgrey"
                  (change)="permission_typeChange($event)">
                  <option value="Username" selected>Username</option>
                  <option value="Email">Email</option>
                  <option value="Registration">Registration</option>
                  <option value="PIC/SIC">PIC/SIC</option>
                  <option value="PAX">PAX</option>
                </select>
              </div>
              <div style="margin-left:1em">
                <label style="font-size:small; white-space:nowrap" for="admin_access_select">Account Access</label>
                <div style="margin-top:-0.55em">
                  <select *ngIf="f" formControlName="admin_access"
                    style="font-size: small;border-radius:0.25em; border-color:lightgrey" (change)="clickShowAllUsers($event)">
                    <option value="1">Admin</option>
                    <option value="0">Standard</option>
                  </select>
                </div>
              </div>
              <div style="margin-left: 0.3125em;margin-top:1.25em">
                <button [disabled]="loading" mat-button class="btn-high" style="width: 5em; font-size: small;">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Search
                </button>&nbsp;
                <button type="button" mat-button class="btn-low" style="font-size: small;"
                  (click)="clickShowAllUsers()">Show All Records</button>&nbsp;
                <button type="button" mat-button class="btn-low" (click)="clickReset()" style="width: 5em">Reset</button>
              </div>
              <div style="margin-left: 0.5em;margin-top:1.5em" *ngIf="showDisableCheckbox">
                <input type="checkbox" formControlName="includeDisabledRecords"
                  (change)="includeDisabledRecordsOnChange($event)" /><label for="includeDisabledRecords"
                  style="font-size:small">&nbsp;Include disabled records</label>
              </div>
              <div style="margin-left: 6.25em;margin-top:1.65em">{{totalRecordsText}}</div>
            </div>
            <div class="row" style="margin-left:0em">
              <div *ngIf="msg!=''" class="inline-block"><label style="color:forestgreen">&nbsp;{{msg}}</label></div>
            </div>
            <div class="row" style="margin-left:0em">
              <div *ngIf="errMsg!=''" class="inline-block"><label style="color: #c00000">{{errMsg}}</label></div>
            </div>
          </form>
        </div>
      </div>
      <div>
        <table mat-table *ngIf="userList && userList.length>0" [dataSource]="userList"
          class="example-container mat-elevation-z8">
          <tr>
            <ng-container matColumnDef="members_list">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" style="border-bottom:none"><!--Members List--></th>
            </ng-container>
            <ng-container matColumnDef="trip_access_filter">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" style="border-bottom:none">Trip Access and Filters
              </th>
            </ng-container>
            <ng-container matColumnDef="eeDate">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style="border-bottom:none"></th>
            </ng-container>
            <ng-container matColumnDef="restrict_access">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="10" style="border-bottom:none">Permissions</th>
            </ng-container>
            <ng-container matColumnDef="blank">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="hideColumn?2:3" style="border-bottom:none">&nbsp;</th>
            </ng-container>
          </tr>
          <tr>
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                Username
              </th>
              <td mat-cell *matCellDef="let element" style="text-align:left;padding-left:0.125em">{{element.username}}
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                Name
              </th>
              <td mat-cell *matCellDef="let element" style="white-space:nowrap;text-align:left">{{element.flName}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                Email
              </th>
              <td mat-cell *matCellDef="let element" style="text-align:left">{{element.email}}</td>
            </ng-container>
            <ng-container matColumnDef="account_status">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                <div>Account</div>
                <div>Status</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.isActive? 'Active': 'Not Active'}}</td>
            </ng-container>
            <ng-container matColumnDef="admin_access">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                Account<br />Access
              </th>
              <td mat-cell *matCellDef="let element">{{element.isAdmin? 'Admin': 'Standard'}}</td>
            </ng-container>
            <ng-container matColumnDef="registration">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                Registration
              </th>
              <td mat-cell *matCellDef="let element" style="white-space:pre-wrap;">
                {{element.registrationList==''?'All':element.registrationList}}</td>
            </ng-container>
            <ng-container matColumnDef="pic">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                PIC / SIC
              </th>
              <td mat-cell *matCellDef="let element" style="white-space:pre-wrap;">
                {{element.picList==''?'All':element.picList}}</td>
            </ng-container>
            <!--<ng-container matColumnDef="sic">
      <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
        SIC
      </th>
      <td mat-cell *matCellDef="let element" style="white-space:pre-wrap ">{{element.sicList==''?'All':element.sicList}}</td>
    </ng-container>-->
            <ng-container matColumnDef="pax">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                PAX
              </th>
              <td mat-cell *matCellDef="let element" style="white-space:pre-wrap;">
                {{element.paxList==''?'All':element.paxList}}</td>
            </ng-container>
            <ng-container matColumnDef="effectiveDate">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                Effective<br />Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.effectiveDate}}</td>
            </ng-container>
            <ng-container matColumnDef="expirationDate">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                Expiration<br />Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.expirationDate}}</td>
            </ng-container>
            <ng-container matColumnDef="grantAdmin">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                <div>Grant</div>
                <div style="margin-top:-0.5em">Access</div>
                <div style="margin-top:-0.5em">Admin</div>
                <div style="margin-top:-0.5em">Services</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.isAdmin" class="material-icons" style="color:green; margin-top:0em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantManifest">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                <div>Grant</div>
                <div style="margin-top:-0.5em">Access to</div>
                <div style="margin-top:-0.5em">Manifest</div>
                <div style="margin-top:-0.5em">Profiles</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToManageManifestProfiles" class="material-icons"
                  style="color:green; margin-top:0em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantCompany">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                <div>Grant</div>
                <div style="margin-top:-0.5em">Access</div>
                <div style="margin-top:-0.5em">Company</div>
                <div style="margin-top:-0.5em">Profiles</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessCompanyProfiles" class="material-icons"
                  style="color:green; margin-top:0em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantAircraft">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                <div>Grant</div>
                <div style="margin-top:-0.5em">Access</div>
                <div style="margin-top:-0.5em">Aircraft</div>
                <div style="margin-top:-0.5em">Profiles</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessAircraftProfiles" class="material-icons"
                  style="color:green; margin-top:0em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantSubmitTrip">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                <div>Grant</div>
                <div style="margin-top:-0.5em">Submit Trips</div>
                <div style="margin-top:-0.5em">and Request</div>
                <div style="margin-top:-0.5em">Quotes</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessSubmitTripsandRequestQuotes" class="material-icons"
                  style="color:green; margin-top:0em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantMessage">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                <div>Grant</div>
                <div style="margin-top:-0.5em">Access to</div>
                <div style="margin-top:-0.5em">Message</div>
                <div style="margin-top:-0.5em">Center</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToMessages" class="material-icons"
                  style="color:green; margin-top:0em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantAllMessage">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                <div>Grant</div>
                <div style="margin-top:-0.5em">Access to</div>
                <div style="margin-top:-0.5em">All</div>
                <div style="margin-top:-0.5em">Messages</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToAllMessages" class="material-icons"
                  style="color:green; margin-top:0em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantCompanyEvent">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                <div>Grant</div>
                <div style="margin-top:-0.5em">Manage</div>
                <div style="margin-top:-0.5em">Company</div>
                <div style="margin-top:-0.5em">Events</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToManageCompanyEvents" class="material-icons"
                  style="color:green; margin-top:0em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantInvoice">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important" hidden>
                <div>Grant</div>
                <div style="margin-top:-0.5em">Access to</div>
                <div style="margin-top:-0.5em">Invoices</div>
              </th>
              <td mat-cell *matCellDef="let element" hidden>
                <i *ngIf="element.grantAccessToInvoices" class="material-icons"
                  style="color:green; margin-top:0em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantPII">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                <div>Grant</div>
                <div style="margin-top:-0.5em">Access to</div>
                <div style="margin-top:-0.5em">PII Data</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToPIIData" class="material-icons"
                  style="color:green; margin-top:0em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="grantGrp">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                <div>Grant</div>
                <div style="margin-top:-0.5em">Access to</div>
                <div style="margin-top:-0.5em">Account</div>
                <div style="margin-top:-0.5em">Grouping</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.grantAccessToAccountGrouping" class="material-icons"
                  style="color:green; margin-top:0em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="locked">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important" [hidden]="hideColumn">
                Locked
              </th>
              <td mat-cell *matCellDef="let element" [hidden]="hideColumn">{{element.locked? 'Yes': ''}}</td>
            </ng-container>
            <ng-container matColumnDef="disabled">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">
                Disabled
              </th>
              <td mat-cell *matCellDef="let element">{{element.isActive? '': 'Yes'}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="font-weight: 600 !important">Action</th>
              <td mat-cell *matCellDef="let element; let i=index ">
                <a mat-button class="btn-high grid" (click)="clickEdit($event, element);">
                  Edit
                </a>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
  
      </div>
      <!--<div>
          <mat-paginator #paginator *ngIf="userList && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                         [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
          </mat-paginator>
        </div>-->
    </div>
  </div>
  

</div>
<app-spin *ngIf="showSpin"></app-spin>