  
<div style="margin-top:-1em;border:solid;border-width:thin;border-color:lightgray;padding:.5em">

    <form *ngIf="aircraft_profile_details_form" [formGroup]="aircraft_profile_details_form">
      <div class="container-fluid p-0" style="background-color:white;">
        <div class="row no-gutters"> 
          <div class="col-3 pr-1">
            <div style="height:.5em"></div>
            <div class="container-fluid" style="background-color:#f8f8f8;min-height:4em">
              <div class="row justify-content-start">
                <div class="col-sm-12">
                  <label for="uom" style="font-size:small" class="font-weight-bold">Units of Measure</label>
                  <div *ngIf="userType=='internal'" class="col-sm-12">
                    <mat-radio-group id="uom" aria-label="Select an option">
                      <mat-radio-button style="transform:scale(.75)" color="primary" (change)="changeUOM($event)" [checked]="isImperial==1" value="1">Imperial (lbs)</mat-radio-button>
                      <mat-radio-button style="transform:scale(.75)" color="primary" (change)="changeUOM($event)" [checked]="isImperial==0" value="0">Metric (kgs)</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div *ngIf="userType!='internal'" class="col-sm-12" style="font-size:small">
                    <label *ngIf="isImperial==1">Imperial (lbs)</label>
                    <label *ngIf="isImperial==0">Metric (kgs)</label>
                  </div>
                </div>
              </div>
            </div>            
            <div style="height:.5em"></div>
            <div class="container-fluid" style="background-color:#f8f8f8;min-height:30em">
              <div class="row justify-content-start">
                <div class="col-sm-12">
                  <label style="font-size:small" class="font-weight-bold">Preferred Profile</label>
                </div>
              </div>
              <div class="row justify-content-start">
                <div class="col-sm-12 font-weight-bold small">Climb</div>
              </div>
              <div class="row no-gutters justify-content-center">
                <div class="col-sm-4 pr-1 form-group">
                  <label for="climb_fl_100"  style="font-size:small">FL100</label>
                  <select readonly *ngIf="f" formControlName="climb_fl_100" class="form-control form-control-sm dropdown" style="margin-top:-0.35em;">
                    <option *ngFor="let s of climb100List" [ngValue]="s.aircraftTypeSpeedId">{{s.speedValue}}</option>
                  </select>                 
                </div>
                <div class="col-sm-4 px-1 form-group">
                  <label for="climb_fl_100_250" style="font-size:small">FL100-250</label>

                  <select *ngIf="f" formControlName="climb_fl_100_250" class="form-control form-control-sm dropdown" style="margin-top:-0.35em;">
                    <option *ngFor="let s of climb250List" [ngValue]="s.aircraftTypeSpeedId">{{s.speedValue}}</option>
                  </select>

                </div>
                <div class="col-sm-4 pl-1 form-group">
                  <label for="climb_top" style="font-size:small">Top of Climb</label>
                  <select *ngIf="f" formControlName="climb_top" class="form-control form-control-sm dropdown" style="margin-top:-0.35em;">
                    <option *ngFor="let s of climbTopList" [ngValue]="s.aircraftTypeSpeedId">{{s.speedValue}}</option>
                  </select>

                </div>
              </div>
              <div class="row justify-content-center no-gutters">
                <div class="col-sm-4 pr-1 form-group">
                  <label for="climb_bias" style="font-size:small">Bias (%)</label>
                  <select *ngIf="f" formControlName="climb_bias" class="form-control form-control-sm dropdown" style="margin-top:-0.35em;" [ngClass]="{ 'is-invalid': submitted && f.climb_bias.errors }">
                    <option *ngFor="let bias of biasDataSource" [ngValue]="bias.value">{{bias.value}}</option>
                  </select>

                </div>
                <div class="col-sm-4 px-1 form-group">
                  <label for="climb_fuel" style="font-size:small">Fuel {{units}}</label>
                    <input type="number" *ngIf="f" formControlName="climb_fuel" style="margin-top: -0.35em; padding: 0em; height: 1.5625em; font-size: small; background-color: #eaf0fd" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.climb_fuel.errors }" />
                  
                  <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    Fuel exceeds max value of {{maxPreferredFuel}} {{units}}.
                  </div>
                </div>
                <div class="col-sm-4 pl-1 form-group">
                  <label for="climb_mins" style="font-size:small">Minutes</label>
                  <input type="number" *ngIf="f" formControlName="climb_mins" style="margin-top: -0.35em; padding: 0em; height: 1.5625em; font-size: small; background-color: #eaf0fd" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.climb_mins.errors }" />

                </div>
              </div>
              <div class="row justify-content-start">
                <div class="col-sm-12 font-weight-bold small">En-Route</div>
              </div>
              <div class="row justify-content-center no-gutters ">
                <div class="col-sm-6 pr-1 form-group">
                  <label for="cruise" style="font-size:small">Primary Cruise</label>
                  <input type="text" *ngIf="f" formControlName="cruise" style="margin-top: -0.35em; padding: 0em; height: 1.5625em; font-size: small; background-color: #eaf0fd" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cruise.errors }" />
                  <span class="invalid-tooltip alert-danger p-0" style="font-size:small;">
                    Primary Cruise is required.
                  </span>

                </div>
                <div class="col-sm-6 pl-1 form-group">
                  <label for="p_cruise_bias" style="font-size:small">Bias (%)</label>
                  <select *ngIf="f" formControlName="p_cruise_bias" class="form-control form-control-sm dropdown" style="margin-top:-0.35em;" [ngClass]="{ 'is-invalid': submitted && f.p_cruise_bias.errors }">
                    <option *ngFor="let bias of biasDataSource" [ngValue]="bias.value">{{bias.value}}</option>
                  </select>

                </div>
              </div>
              <div class="row justify-content-start">
                <div class="col-sm-12">
                  <label for="alt_cruise" style="font-size:small">Secondary Cruise(s)</label>
                </div>
              </div>
              <div class="row justify-content-center no-gutters text-center" style="margin-top:-.5em">
                <div class="col pr-1 form-group">
                  <label for="alt_cruise1" style="font-size:x-small">1</label>
                  <input type="text" *ngIf="f" formControlName="alt_cruise1" style="margin-top: -0.35em; padding: 0em; height: 1.5625em; font-size: small; background-color: #eaf0fd" class="form-control" />

                </div>
                <div class="col px-1 form-group">
                  <label for="alt_cruise1" style="font-size:x-small">2</label>
                  <input type="text" *ngIf="f" formControlName="alt_cruise2" style="margin-top: -0.35em; padding: 0em; height: 1.5625em; font-size: small; background-color: #eaf0fd" class="form-control" />

                </div>
                <div class="col px-1 form-group">
                  <label for="alt_cruise1" style="font-size:x-small">3</label>
                  <input type="text" *ngIf="f" formControlName="alt_cruise3" style="margin-top: -0.35em; padding: 0em; height: 1.5625em; font-size: small; background-color: #eaf0fd" class="form-control" />

                </div>
                <div class="col px-1 form-group">
                  <label for="alt_cruise1" style="font-size:x-small">4</label>
                  <input type="text" *ngIf="f" formControlName="alt_cruise4" style="margin-top: -0.35em; padding: 0em; height: 1.5625em; font-size: small; background-color: #eaf0fd" class="form-control" />

                </div>
                <div class="col pl-1 form-group">
                  <label for="alt_cruise1" style="font-size:x-small">5</label>
                  <input type="text" *ngIf="f" formControlName="alt_cruise5" style="margin-top: -0.35em; padding: 0em; height: 1.5625em; font-size: small; background-color: #eaf0fd" class="form-control" />

                </div>
              </div>
              <div class="row justify-content-start">
                <div class="col-sm-12 font-weight-bold small">Descent</div>
              </div>
              <div class="row justify-content-center no-gutters">
                <div class="col-sm-4 pr-1 form-group">
                  <label for="descent_top" style="font-size:small">Top of Descent</label>
                  <select *ngIf="f" formControlName="descent_top" class="form-control form-control-sm dropdown" style="margin-top:-0.35em;">
                    <option *ngFor="let s of descentTopList" [ngValue]="s.aircraftTypeSpeedId">{{s.speedValue}}</option>
                  </select>

                </div>
                <div class="col-sm-4 px-1 form-group">
                  <label for="descent_fl_100_250" style="font-size:small">FL250-100</label>
                  <select *ngIf="f" formControlName="descent_fl_100_250" class="form-control form-control-sm dropdown" style="margin-top:-0.35em;">
                    <option *ngFor="let s of descent250List" [ngValue]="s.aircraftTypeSpeedId">{{s.speedValue}}</option>
                  </select>

                </div>
                <div class="col-sm-4 pl-1 form-group">
                  <label for="descent_fl_100"  style="font-size:small">FL100</label>
                  <select *ngIf="f" formControlName="descent_fl_100" class="form-control form-control-sm dropdown" style="margin-top:-0.35em;">
                    <option *ngFor="let s of descent100List" [ngValue]="s.aircraftTypeSpeedId">{{s.speedValue}}</option>
                  </select>

                </div>
              </div>
              <div class="row justify-content-center no-gutters">
                <div class="col-sm-4 pr-1 form-group">
                  <label for="descent_bias" style="font-size:small">Bias (%)</label>
                  <select *ngIf="f" formControlName="descent_bias" class="form-control form-control-sm dropdown" style="margin-top:-0.35em;" [ngClass]="{ 'is-invalid': submitted && f.descent_bias.errors }">
                    <option *ngFor="let bias of biasDataSource" [ngValue]="bias.value">{{bias.value}}</option>
                  </select>

                </div>
                <div class="col-sm-4 px-1 form-group">
                  <label for="descent_fuel" style="font-size:small">Fuel {{units}}</label>
                  <input type="number" *ngIf="f" formControlName="descent_fuel" style="margin-top: -0.35em; padding: 0em; height: 1.5625em; font-size: small; background-color: #eaf0fd" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.descent_fuel.errors }" />

                </div>
                <div class="col-sm-4 pl-1 form-group">
                  <label for="descent_mins" style="font-size:small">Minutes</label>
                  <input type="number" *ngIf="f" formControlName="descent_mins" style="margin-top: -0.35em; padding: 0em; height: 1.5625em; font-size: small; background-color: #eaf0fd" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.descent_mins.errors }" />

                </div>
              </div>
            </div>
          </div>
          <div class="col-6 px-1">
            <div style="height:.5em"></div>
            <div class="container-fluid" style="background-color:#f8f8f8;min-height:4.25em">
              <div class="row justify-content-start">
                <div class="col-sm-12 form-group">
                  <label for="engine_type" style="font-size:small" class="font-weight-bold">Engine Type</label>
                  <input type="text" *ngIf="f" formControlName="engine_type" style="margin-top:-.35em;padding: 0em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.engine_type.errors }" />
                  <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    Engine Type is required.
                  </span>
                </div>
              </div>
            </div>
            <div style="height:.5em"></div>
            <div class="container-fluid" style="background-color:#f8f8f8;min-height:15em">
              <div class="row justify-content-start">
                <div class="col-sm-12">
                  <label for="engine_type" style="font-size:small" class="font-weight-bold">Aircraft Weights</label>
                </div>
              </div>
              <div class="row justify-content-center no-gutters">

                <div class="col-sm-2 px-1 form-group">
                  <label for="mrw" style="font-size:small">MRW {{units}}</label>
                  <input type="number" *ngIf="f" formControlName="mrw" style="margin-top: -.35em" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.mrw.errors }" />
                  <div *ngIf="submitted && f.mrw.invalid" class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    <div *ngIf="f.mrw.errors.required">MRW is required</div>
                    
                  </div>
                </div>
                <div class="col-sm-2 pr-1 form-group">
                  <label for="mtow" style="font-size:small">MTOW {{units}}</label>
                  <input type="number" *ngIf="f" ngbPopover="{{maxMTOW}}" triggers="focus" placement="bottom" popoverTitle="Manufacturer Default" formControlName="mtow" style="margin-top: -.35em" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.mtow.errors }" />
                  <div *ngIf="submitted && f.mtow.invalid" class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    <div *ngIf="f.mtow.errors.required">MTOW is required</div>
                    <div *ngIf="f.mtow.errors.max">MTOW exceeds max threshold.</div>
                    <div *ngIf="f.mtow.errors.max">MTOW cannot exceed MRW.</div>
                  </div>

                </div>
                <div class="col-sm-2 px-1 form-group">
                  <label for="mlw" style="font-size:small">MLW {{units}}</label>
                  <input type="number" *ngIf="f" formControlName="mlw" style="margin-top: -.35em" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.mlw.errors }" />
                  <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    MLW is required.
                  </span>
                </div>
                <div class="col-sm-2 px-1 form-group">
                  <label for="mzfw" style="font-size:small">MZFW {{units}}</label>
                  <input type="number" *ngIf="f" formControlName="mzfw" style="margin-top: -.35em" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.mzfw.errors }" />
                  <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    MZFW is required.
                  </span>
                </div>
                <div class="col-sm-2 px-1 form-group">
                  <label for="bow" style="font-size:small">BOW {{units}}</label>
                  <input type="number" *ngIf="f" formControlName="bow" style="margin-top: -.35em" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.bow.errors }" />
                  <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    BOW is required.
                  </span>
                </div>
                <div class="col-sm-2 pl-1 form-group">
                  <label for="crew_in_bow" style="font-size:small">Crew in BOW</label>
                  <input type="number" *ngIf="f" formControlName="crew_in_bow" style="margin-top: -.35em" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.crew_in_bow.errors }" />
                  <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    Crew in BOW is required.
                  </span>
                </div>
              </div>
              <div class="row justify-content-start pt-2">
                <div class="col-sm-12 font-weight-bold small">Fuel</div>
              </div>
              <div class="row justify-content-center no-gutters">
                <div class="col-sm-2 pr-1 form-group">
                  <label for="max_fuel" style="font-size:small"><br /><br />Max Fuel {{units}}</label>
                  <input type="number" *ngIf="f" ngbPopover="{{maxFuelLimit}}" triggers="focus"  placement="bottom" popoverTitle="Manufacturer Default" formControlName="max_fuel" class="form-control inputBox" style="margin-top: -.35em" [ngClass]="{ 'is-invalid was-validated': submitted && f.max_fuel.errors }" />
                  <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    Max Fuel is required.
                  </div>
                </div>
                <div class="col-sm-2 px-1 form-group">
                  <label for="min_dispatch_fuel" style="font-size:small">Min<br />Departure<br />Fuel {{units}}</label>
                  <input type="number" *ngIf="f" formControlName="min_dispatch_fuel" style="padding: 0em;margin-top:-.35em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
                <div class="col-sm-2 px-1 form-group">
                  <label for="min_alt_fuel" style="font-size:small">Min<br />Burn to<br />Alternate {{units}}</label>
                  <input type="number" *ngIf="f" formControlName="min_alt_fuel" style="padding: 0em;height:1.5625em;margin-top:-.35em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
                <div class="col-sm-2 px-1 form-group">
                  <label for="min_landing_fuel" style="font-size:small">Min<br />Landing<br />Fuel {{units}}</label>
                  <input type="number" *ngIf="f" formControlName="min_landing_fuel" style="padding: 0em;margin-top:-.35em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.min_landing_fuel.errors }" />
                  <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    Max Landing Fuel is required.
                  </div>
                </div>
                <div class="col-sm-2 px-1 form-group">
                  <label for="taxi_fuel" style="font-size:small"><br /><br />Taxi Fuel {{units}}</label>
                  <input type="number" *ngIf="f" formControlName="taxi_fuel" style="padding: 0em;margin-top:-.35em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.taxi_fuel.errors }" />
                  <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    Taxi Fuel is required.
                  </div>
                </div>
                <div class="col-sm-2 pl-1 form-group">
                  <label for="taxi_fuel_burn_type" style="font-size:small"><br />Taxi Fuel<br />Burn Type</label>
                  <select *ngIf="f" formControlName="taxi_fuel_burn_type" class="form-control form-control-sm dropdown" style="margin-top:-.35em" [ngClass]="{ 'is-invalid': submitted && f.taxi_fuel_burn_type.errors }">
                    <option value="1">Fixed Burn</option>
                    <option value="2">Burn Per Hour</option>
                  </select>
                </div>
              </div>
            </div>
            <div style="height:.5em"></div>
            <div class="container-fluid" style="background-color:#f8f8f8;min-height:14.5em">
              <div class="row justify-content-start">
                <div class="col-sm-12">
                  <label for="engine_type" style="font-size:small" class="font-weight-bold">ETP Section</label>
                </div>
              </div>
              <div class="row justify-content-center no-gutters" >
                <div class="col-sm-3 pr-1 form-group">
                  <label for="etp_tas" style="font-size:small"><br />TAS</label>
                  <input type="number" *ngIf="f" formControlName="etp_tas" class="form-control inputBox" style="margin-top: -.35em" [ngClass]="{ 'is-invalid was-validated': submitted && f.etp_tas.errors }" />
                </div>
                <div class="col-sm-3 px-1 form-group">
                  <label for="oxy_flight_level" style="font-size:small">O2 Initial<br />Flight Level</label>
                  <input type="number" *ngIf="f" formControlName="oxy_flight_level" class="form-control inputBox" style="margin-top: -.35em" [ngClass]="{ 'is-invalid was-validated': submitted && f.oxy_flight_level.errors }" />
                </div>
                <div class="col-sm-3 px-1 form-group">
                  <label for="oxy_min" style="font-size:small">O2 Duration<br />Minutes</label>
                  <input type="number" *ngIf="f" formControlName="etp_tas" class="form-control inputBox" style="margin-top: -.35em" [ngClass]="{ 'is-invalid was-validated': submitted && f.oxy_min.errors }" />
                </div>
                <div class="col-sm-3 pl-1 form-group">
                  <label for="etp_flight_level" style="font-size:small"><br />Flight Level</label>
                  <input type="number" *ngIf="f" formControlName="etp_flight_level" class="form-control inputBox" style="margin-top: -.35em" [ngClass]="{ 'is-invalid was-validated': submitted && f.etp_flight_level.errors }" />
                </div>
              </div>
              <div class="row justify-content-start pt-1">
                <div class="col-sm-12">
                  <label for="engine_type" style="font-size:small" class="font-weight-bold">ETOP Section</label>
                </div>
              </div>
              <div class="row justify-content-center no-gutters">
                <div class="col-sm-4 pr-1 form-group">
                  <label for="etop_min" style="font-size:small">Minutes</label>
                  <input type="number" *ngIf="f" formControlName="etop_min" class="form-control inputBox" style="margin-top: -.35em" [ngClass]="{ 'is-invalid was-validated': submitted && f.etop_min.errors }" />
                </div>
                <div class="col-sm-4 px-1 form-group">
                  <label for="etop_tas" style="font-size:small">TAS</label>
                  <input type="number" *ngIf="f" formControlName="etop_tas" class="form-control inputBox" style="margin-top: -.35em" [ngClass]="{ 'is-invalid was-validated': submitted && f.etop_tas.errors }" />
                </div>
                <div class="col-sm-4 pl-1 form-group">
                  <label for="etop_cruise" style="font-size:small">Cruise</label>
                  <input type="number" *ngIf="f" formControlName="etop_cruise" class="form-control inputBox" style="margin-top: -.35em" [ngClass]="{ 'is-invalid was-validated': submitted && f.etop_cruise.errors }" />
                </div>
              </div>             
            </div>
            </div>
          <div class="col-3 pl-1">
            <div style="height:.5em"></div>
            <div class="container-fluid" style="background-color:#f8f8f8;min-height:4.25em">
              <div class="row justify-content-start">
                <div class="col-sm-12 form-group">
                  <label for="country_select" style="font-size:small" class="font-weight-bold">Country of Registration</label>
                  <select *ngIf="f" formControlName="country_select" class="form-control form-control-sm dropdown" style="margin-top:-.35em" [ngClass]="{ 'is-invalid': submitted && f.country_select.errors }">
                    <option *ngFor="let cty of countryList" [ngValue]="cty.countryGUID">{{cty.countryName}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div style="height:.5em"></div>
            <div class="container-fluid" style="background-color:#f8f8f8;min-height:11em">
              <div class="row justify-content-start">
                <div class="col-sm-12">
                  <label for="engine_type" style="font-size:small" class="font-weight-bold">Other Information</label>
                </div>
              </div>
              <div class="row justify-content-center no-gutters">
                <div class="col-sm-3 pr-1 form-group">
                  <label for="max_fl" style="font-size:small">Max FL</label>
                  <input type="number" *ngIf="f" formControlName="max_fl" class="form-control inputBox" style="margin-top:-0.35em;" [ngClass]="{ 'is-invalid': submitted && f.max_fl.errors }" />
                </div>
                <div class="col-sm-3 px-1 form-group">
                  <label for="pax_seats" style="font-size:small">Pax Seats</label>
                  <input type="number" *ngIf="f" formControlName="pax_seats" class="form-control inputBox" style="margin-top:-0.35em;" [ngClass]="{ 'is-invalid': submitted && f.pax_seats.errors }" />
                </div>
                <div class="col-sm-3 px-1 form-group">
                  <label for="jump_seats" style="font-size:small">Jump Seats</label>
                  <input type="number" *ngIf="f" formControlName="jump_seats" class="form-control inputBox" style="margin-top:-0.35em;" [ngClass]="{ 'is-invalid': submitted && f.jump_seats.errors }" />
                </div>
                <div class="col-sm-3 pl-1 form-group">
                  <label for="other_seats" style="font-size:small">Other Seats</label>
                  <input type="number" *ngIf="f" formControlName="other_seats" class="form-control inputBox" style="margin-top:-0.35em;" [ngClass]="{ 'is-invalid': submitted && f.other_seats.errors }" />
                </div>
              </div>
              <div class="row justify-content-center no-gutters">
                <div class="col-sm-8 form-group pr-1">
                  <label for="homebase_airport" style="font-size:small">Homebase ICAO Identifier</label>
                  <select *ngIf="f" formControlName="homebase_airport" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.homebase_airport.errors }">
                    <option *ngFor="let airport of airportList" [ngValue]="airport.airportID">{{airport.icao + ' / ' + airport.iata}}</option>
                  </select>
                </div>
                <div *ngIf="userType=='internal'" class="col-sm-4 form-group pl-1 mt-4">
                  <button class="btn btn-sm btn-link" (click)="addHomebase()">Add</button>
                </div>
              </div>
            </div>
            <div style="height:.5em"></div>
            <div class="container-fluid" style="background-color:#f8f8f8;min-height:18.5em">
                <div class="row justify-content-start">
                  <div class="col-sm-12">
                    <label for="engine_type" style="font-size:small" class="font-weight-bold">Remarks (Internal)</label>
                  </div>
                </div>
                <div class="row justify-content-center no-gutters">
                  <div class="col-sm-12 form-group">
                    <textarea formControlName="remarks" *ngIf="f" class="form-control" maxlength=255 style="height:17em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">
                    </textarea>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
</form>
    <div *ngIf="userType=='internal'" class="d-inline-flex" style="height:3em">
      <div>
        <button mat-button class="btn-high m-3" (click)="clickSave()">Save</button>
      </div>
      <div class="ml-2 mt-3">
        <app-success-message [successMsg]="" *ngIf="showSuccessMsg"></app-success-message>
      </div>
    </div>
</div>
