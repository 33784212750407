import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { TaxesAndFeesDescriptionDialog } from '../fuelmenu/taxesandfees-description-dialog.component';
import { CountryDocumentTypeUpdateDialog } from '../country/country-document-type-update-dialog.component';
import { MaintenanceDashboardComponent } from './maintenance-dashboard.component';

@Component({
  selector: 'app-fpc-picklist-data',
  templateUrl: './fpc-picklist-data.component.html',
  styleUrls: ['./fpc-picklist-data.component.css']
})

export class FpcPicklistDataComponent implements OnInit {
  // userType: string;
  // tripRevisionID: number;
  // userComments: string;
  fpc_picklist_data_form: UntypedFormGroup;
  // loading = false;
  // v: number = this._authService.getCurrentTimeNumber();
  // returnObj = new ReturnObjModel();
  // controlValueChanged: boolean = false;
  // private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  // private eventSubscription: Subscription;
  isDialog: boolean = false;

  constructor(
    @Optional() private readonly _dialogRef: MatDialogRef<FpcPicklistDataComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _dialog: MatDialog,
    //private readonly _dialogRef: MatDialogRef<FpcPicklistDataComponent>, @Inject(MAT_DIALOG_DATA) _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
  ) {

    // this.tripRevisionID = _data.tripRevisionID;

    if (this.data) {
      this.isDialog = true;
    }
  }

  ngOnInit() {

    // this._authService.updateAccessTime();



  }

  initControls() {
    this.fpc_picklist_data_form = this._formBuilder.group({
      // userComments: [this.userComments]
    });

  }

  get f() { return this.fpc_picklist_data_form.controls; }




  clickClose(val) {
    this._dialogRef.close(val);
  }


  clickRouteNew(route: string, display: string) {
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }

  openTaxesAndFeesDescriptionDialog() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "43em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    this.clickClose(true);

    config.data = { v: this._authService.getCurrentTimeNumber(), dialogFrom: 'main' };
    this._dialog.open(TaxesAndFeesDescriptionDialog, config);
  }


  openCountryDocumentsPicklistComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "43em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    if (this.isDialog) {
      this.clickClose(true);
    }

    config.data = { v: this._authService.getCurrentTimeNumber(), dialogFrom: 'main' };
    this._dialog.open(CountryDocumentTypeUpdateDialog, config);
  }

  openMaintenanceComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "31em";
    config.width = "43em";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    config.position = { left: "10px", top: "140px" };
    if (this.isDialog) {
      this.clickClose(true);
    }
    this._dialog.open(MaintenanceDashboardComponent, config);
  }


}
