<div cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle style="margin: 0.5em 1em 0 1em;" mat-dialog-title>Generate Message Template</div>
<div *ngIf="!fromEmail" mat-dialog-content style="margin-top:-1em;padding-bottom:0;    width: 40em;margin: 0em 1em 0 1em;background: white;">
  <form novalidate *ngIf="generate_message_template_form" [formGroup]="generate_message_template_form">

    <div class="container" style="background-color:white;">
      <div *ngIf="gsId==''">
        <div class="row justify-content-left no-gutters">
          <div class="col-8 form-group">
            <label style="font-size:small" for="client_select">Client Name</label>
            <select required *ngIf="f" formControlName="client_select" class="form-control form-control-sm dropdown" (change)="getTripCodeList()" style="margin-top:-.35em" [ngClass]="{'is-invalid': f.client_select.errors && submitted}">
              <option value="">--Select--</option>
              <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
            </select>
            <div class="invalid-tooltip alert-danger py-0 px-1" style="font-size:small">Select a client.</div>
          </div>
          <div class="col form-group ml-2">
            <label style="font-size:small" for="trip_code">Trip Code</label>
            <select required *ngIf="f" formControlName="trip_code" class="form-control form-control-sm dropdown" #tripCode (change)="getGroundStopList()" style="margin-top:-.35em" [ngClass]="{'is-invalid': f.trip_code.errors && submitted}">
              <option value="">--Select--</option>
              <option *ngFor="let trip of tripCodeList" [ngValue]="trip.tripCodeGUID">{{trip.tripCode}}</option>
            </select>
            <div class="invalid-tooltip alert-danger py-0 px-1" style="font-size:small">Select a trip.</div>
          </div>
        </div>
      </div>
      <div *ngIf="gstId==''">
        <div class="row justify-content-left no-gutters" style="margin-top:-.5em">
          <div *ngIf="gsId==''" class="col-8 form-group">
            <label style="font-size:small" for="business_stop">Business Stop</label>
            <select required *ngIf="f" formControlName="business_stop" class="form-control form-control-sm dropdown" #businessStop (change)="getData()" style="margin-top:-.35em" [ngClass]="{'is-invalid': f.business_stop.errors && submitted}">
              <option value="">--Select--</option>
              <option *ngFor="let gs of groundStopList" [ngValue]="gs.groundStopGUID">{{gs.groundStopBriefInfo}}</option>
            </select>
            <div class="invalid-tooltip alert-danger py-0 px-1" style="font-size:small">Select a business stop.</div>
          </div>
          <div [ngClass]="{'col form-group ml-2': gsId=='', 'col form-group mt-1 w-auto': gsId!=''}">
            <label style="font-size:small" for="task_list">Business Stop Service</label>
            <select required *ngIf="f" formControlName="task_list" class="form-control form-control-sm dropdown" #serviceType (change)="filterTemplateList()" style="margin-top:-.35em" [ngClass]="{'is-invalid': f.task_list.errors && submitted}">
              <option value="">--Select--</option>
              <option *ngFor="let task of taskList" [ngValue]="task">{{task.serviceTypeDescription}}&nbsp;{{task.countryName!=''? ' - '+task.countryName : ''}}</option>
            </select>
            <div class="invalid-tooltip alert-danger py-0 px-1" style="font-size:small">Select a service.</div>
          </div>
        </div>
      </div>

      <div class="row justify-content-left no-gutters" [ngStyle]="{'margin-top': templateMargin}">
        <div [ngClass]="{'col-8  form-group pb-2': gsId=='', 'col form-group': gsId!=''}">
        
          


          <!-- <div *ngIf="listType=='all'" style="margin-top: 1em;">
            <select required *ngIf="f" formControlName="message_template" (change)="templateOnChange($event, t)" class="form-control form-control-sm dropdown" #messageTemplate style="margin-top:-.35em" [ngClass]="{'is-invalid': f.message_template.errors && submitted}">
              <option selected value="">--Select--</option>
              <option *ngFor="let t of messageTemplateList"  [ngValue]="t.messageTemplateID">{{t.messageTemplateName}}</option>
            </select>
          </div>
          <div *ngIf="listType=='filter'"  style="margin-top: 1em;">
            <select required *ngIf="f" formControlName="message_template" (change)="templateOnChange($event, t)" class="form-control form-control-sm dropdown" #messageTemplate style="margin-top:-.35em" [ngClass]="{'is-invalid': f.message_template.errors && submitted}">
              <option selected value="">--Select--</option>
              <option *ngFor="let t of filteredList"   [ngValue]="t.messageTemplateID">{{t.messageTemplateName}}</option>
            </select>
          </div> 
          
          <div style=" margin-top: 1.5em;display: flex;" *ngIf="serviceTypeId == 1 && showMultipleAirportsOption">
            <label for="checkFlightBriefStops" style="font-size:small">Included Stops</label>
            <div style="margin-top:-0.6em;">
              
              
              <mat-select #matFlightBriefStops placeholder="None" panelClass="  placeholder1" [formControl]="checkFlightBriefStops"  (click)="setDefaultGroundStop()"
                multiple [(ngModel)]="selectedGroundStopList2"
                style="margin-left: 2em !important;  margin-top: 0.5em !important;max-width: 10em !important;min-width: 10em !important;border: solid lightgray 0.0625em; font-size: small; color:black; border-radius: 0.25em; padding-left:0.25em; height:1.65em;width:17em; background-color: #eaf0fd;">
                <mat-select-trigger >
                  <div style="display:flex;" >
                    <div *ngFor="let g of selectedGroundStopList; let i=index"
                      style="margin-top:0.4em;margin-left:0em; height:1.35em; padding:0em;display:flex ">
                      <div style="margin-top:-0.5em; margin-left:0em; white-space:nowrap;">{{ g == undefined ? 'Select All' : (i === 0
                        ? g.icao : ', ' +g.icao) }}</div>
                    </div>
                  </div>
                </mat-select-trigger>
                <div style="margin-left:0em ;  margin-top: 0.5em !important;">
                  <mat-checkbox class="mat-option" [value]="'All'" (click)="$event.stopPropagation()"
                    (change)="onGroundStopListChange($event, g, 'all')" [checked]="selectedAll"
                    style="height:1.5em; line-height:1.5em !important;"> Select All</mat-checkbox>
                  <mat-option *ngFor="let g of groundStopList" [value]="g"
                    (onSelectionChange)="onGroundStopListChange($event, g, 'option')"
                    style="height:1.5em; line-height:1.5em !important;">{{g.icao}} </mat-option>
                </div>
              </mat-select>

            

            </div>
          </div>-->


          <div class="invalid-tooltip alert-danger py-0 px-1" style="font-size:small">Select a template.</div>
        </div>

      </div>
      
      <div class="row pb-2">
        <!-- <div class="col">
          <button mat-button class="btn-high" [disabled]="showMultipleAirportsOption && groundStopGuidList == ''" (click)="generateTemplate()">Generate Template</button>
        </div> -->
        <div class="col-sm-auto">
          <div *ngIf="errMsg!=''" class="alert alert-danger p-0">{{errMsg}}</div>
        </div>
      </div>
      <div *ngIf="hasTemplate">
        <div class="row">
          <div class="col-sm-12">
            <label style="font-size:small" for="subject_line">Subject Line</label>&nbsp;
            <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy subject to clipboard" [cdkCopyToClipboard]="f.subject_line.value">
              content_copy
            </i>
          </div>

        </div>
        <div class="row">
          <div class="col-sm-12 form-group">
            <input type="text" formControlName="subject_line" class="form-control inputBox" />
          </div>
        </div>
        <div class="row" style="margin-top:-.5em">

          <div class="col-sm-12">
            <label style="font-size:small" for="message_text">Message Text</label>
            &nbsp;
            <!--<i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy message to clipboard" [cdkCopyToClipboard]="f.message_text.value">-->
            <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy message to clipboard" [cdkCopyToClipboard]="clickCopyTemplate()">            
              content_copy
            </i>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-12 form-group">
            <textarea *ngIf="f" formControlName="message_text" class="form-control inputBox" style="width:65em;height:17em;font-family:Courier New, Courier, monospace;font-size:small"></textarea>
          </div>

        </div>
      </div>
    </div>
    </form>
</div>
<div style="padding: 1em;" >
  <!-- <mat-radio-group class="example-radio-group " [(ngModel)]="listType" [ngModelOptions]="{standalone: true}">
    <mat-radio-button style="transform:scale(.75);font-size:medium;" value="filter">Filter for Task</mat-radio-button>
    <mat-radio-button style="transform:scale(.75);font-size:medium;margin-left:-.75em" value="all">Show All</mat-radio-button>
  </mat-radio-group> -->

  <div   style=" display: flex; font-size: medium; margin-top:-0.5em; margin-left:-0.75em; width: 100%;">
    <!-- <label style="font-size:small; margin-top: 0.5em;" for="message_template">Message Template:</label>&nbsp; -->
    <div style="display: flex; width: 57%;">
      <div style="margin-top: 0.5em; margin-left: 2em; font-size: small;">Filter For Task</div>
      <mat-slide-toggle style="transform:scale(.8); height:2em;margin-left: -0.7em; " color="primary" [checked]="taskType"
        formControlName="taskType" (change)="onTaskTypeChange($event)">Show All Templates</mat-slide-toggle>
    </div>
    
    <mat-slide-toggle *ngIf="showMultipleAirportsOption" style="transform:scale(.8); height:2em; " color="primary" [checked]=true
     (change)="onHidePastStopChange($event)">Hide Past Airports</mat-slide-toggle>
  </div>

  <div style="background: white; display: flex; width: 97%;">
    <div *ngIf="listType=='all'" class="mt-1" style="background-color:#f8f8f8; height: 25em; width: 22em; margin-left: 1em; ">
      <mat-selection-list *ngIf="listType=='all'" #messageTemplate [multiple]="false" style="overflow:auto; max-height: 24em;">
        <mat-list-option *ngFor="let t of messageTemplateList" [value]="t.messageTemplateID" (click)="selectTemplate(t.messageTemplateID)" [class.active-option]="isActiveTemplate(t)"
          style="height: 30px !important;">
          <span style="display: flex;"> {{t.messageTemplateName}}
          </span> </mat-list-option> 
      </mat-selection-list>

    </div>

    <div *ngIf="listType=='filter'" class="mt-1" style="background-color:#f8f8f8; height: 25em; width: 22em; margin-left: 1em; ">
      <mat-selection-list *ngIf="listType=='filter'" #messageTemplate [multiple]="false" style="overflow:auto; max-height: 24em;">
        <mat-list-option *ngFor="let t of filteredList" [value]="t.messageTemplateID" (click)="selectTemplate(t.messageTemplateID)" [class.active-option]="isActiveTemplate(t)"
          style="height: 30px !important;">
          <span style="display: flex;"> {{t.messageTemplateName}}
          </span> </mat-list-option>
      
      </mat-selection-list>

    </div>

    <div *ngIf=" showMultipleAirportsOption" class="mt-1" style="background-color:#f8f8f8; width: 18em; margin-left: 1em; ">
    
      
      <!-- <mat-selection-list *ngIf="listType=='filter'" #airportList [multiple]="true" style="overflow:auto; max-height: 23em;">
        
        <mat-list-option *ngFor="let g of groundStopList" [value]="g" (click)="selectAirport($event, g, 'option')"
          style="height: 30px !important;">
          <span style="display: flex;"> {{g.icao}}
          </span> </mat-list-option>
      
      </mat-selection-list> -->
      <div style="margin-top: -.35em; width: 10em;" aria-placeholder="none">
        <div style="    height: 25em; overflow-y: auto; width: em; padding-left: 0.5em;">
          <div>
            <div (click)="$event.stopPropagation()">
              <mat-checkbox [(ngModel)]="isAllSelected" [checked]="isAllSelected" (change)="toggleSelectAll()">Select All</mat-checkbox>
            </div>
          </div>
          <div *ngFor="let g of groundStopList">
            <div (click)="$event.stopPropagation()">
              <mat-checkbox [(ngModel)]="g.selected" (change)="selectAirport($event, g)">{{ g.groundStopTypeDescription != 'Contingency' ? g.icao : g.icao + ' - (Contingency)'}}</mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>

  <div *ngIf="showMultipleAirportsOption" style=" display: flex; background-color: #f8f8f8;margin: 1em;">
    <div> Selected Airports:  </div>
    <!-- <div *ngFor="let Airport of airportList" style=" padding: 5px; border-radius: 4px;">
      <div style="margin-left:0.45em; display: flex; ">
        <div style=" display: flex"> {{Airport}}, </div>
      </div>
    </div> -->
    <div style=" display: flex; margin-left: 1em;"> {{airportString}} </div>

  </div>
  <div  style="justify-content: space-between; width: 95%; display: flex; margin: 1em 1em 0 1em;    margin-top: 1em;"> 
    <div>
      <button mat-button class="btn-high" [disabled]="showMultipleAirportsOption && groundStopGuidList == '' || selectedTemplateID == -1 || loading" (click)="generateTemplate()">
        <mat-icon *ngIf="loading">
          <svg class="black-spinner" viewBox="0 0 50 50">
            <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="black" stroke-width="4"></circle>
          </svg>
        </mat-icon>
        Generate Template</button>
        <!-- <button mat-button class="btn-high" style="margin-left: 1em;" (click)="addNewTemplate()">Add New Template</button> -->
      </div>
      <button  class="btn-low" style="margin-left: 18em;"  mat-button mat-dialog-close>&nbsp;Close&nbsp;</button>
  </div>
</div>
