<div *ngIf="upPII" style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <!--<div>
      <i class="material-icons" style="font-size: xx-large;cursor: pointer; color: gray">airline_stops</i>
    </div>-->
    <div style="margin-top:0.3125em;"><h5>Audit Trail Details</h5></div>
  </div>
  <div>
    <form *ngIf="fpp_audit_form" [formGroup]="fpp_audit_form">
      <div style="padding-left: 1em; padding-right:1em">
        <div style="background-color: white; padding-left: 1em; padding-right:1em; max-height:38em;overflow-y: auto">
                    
            <!--<div *ngIf="i>0"><hr style="background-color: black; margin-bottom:-0.15em" /></div>
            <div style="display:flex">
              <div style="margin-right:1em">
                <div>Trip Code</div>
                <div style="font-weight: bold">{{trip.tripCode}}</div>
              </div>
              <div style="margin-right:1em">
                <div>Registration</div>
                <div style="font-weight: bold">{{trip.registration}}</div>
              </div>
              <div>
                {{trip.aircraftManufacturer}}&nbsp;{{trip.aircraftModel}}
              </div>
            </div>-->
            <div>
              <table mat-table [dataSource]="auditList" class="example-container">
                <tr>
                  <ng-container matColumnDef="field_name">
                    <th mat-header-cell *matHeaderCellDef style="width:20%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em; white-space: nowrap">Field Name</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div>{{element.fieldName}}</div>                      
                    </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="old_value">
                    <th mat-header-cell *matHeaderCellDef style="width:15%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em;">Old Value</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div>{{element.oldValue}}</div>
                      
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="new_value">
                    <th mat-header-cell *matHeaderCellDef style="width:15%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em;">New Value</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div style="white-space:pre-wrap">{{element.newValue}}</div>

                    </td>
                  </ng-container>
                  <ng-container matColumnDef="modified_by">
                    <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:10%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em;">Modified By</div>
                    </th>
                    <td mat-cell *matCellDef="let element" [hidden]="hideColumn" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div style="white-space: pre-wrap">{{element.modifiedBy}}</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="modified_date">
                    <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:10%">
                      <div>Modified Date</div>
                    </th>
                    <td mat-cell *matCellDef="let element" [hidden]="hideColumn">
                      <div>{{element.modifiedDate}}</div>
                    </td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
          
          
        </div>
      </div>
    </form>
  </div>
      <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
        <div style="margin-right:1em">
          <button type="button" mat-button class="btn-low" (click)="clickClose()" ><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
        </div>

      </div>
  </div>
  <app-spin *ngIf="showSpin"></app-spin>
