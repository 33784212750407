<div style="margin-top:0em; padding-top:0em; display:flex">
  <form *ngIf="aircraft_documents_form" [formGroup]="aircraft_documents_form">
    <div style="margin-top:-1em">
      <table mat-table [dataSource]="recordList" class="example-container">
        <tr>
          <ng-container matColumnDef="document_name">
            <th mat-header-cell *matHeaderCellDef style="width: 30%">
              Document Name
            </th>
            <td mat-cell *matCellDef="let element">{{element.documentName}}</td>
          </ng-container>
          <ng-container matColumnDef="preview">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">
              &nbsp;
            </th>
            <td mat-cell *matCellDef="let element">
              <a class="a1" *ngIf="element.hasDocument" (click)="clickPreviewDoc($event, element);">Preview</a>
            </td>
          </ng-container>
          <ng-container matColumnDef="doc_type">
            <th mat-header-cell *matHeaderCellDef style="width: 20%">
              Type
            </th>
            <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.documentTypeList}}</td>
          </ng-container>
          <ng-container matColumnDef="effective_date">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">
              Effective Date
            </th>
            <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.effectiveDate}}</td>
          </ng-container>
          <ng-container matColumnDef="expiration_date">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">
              Expiration Date
            </th>
            <td mat-cell *matCellDef="let element" style="white-space:pre-wrap" [ngClass]="{'alert-danger': element.isExpired}">{{element.expirationDate}}</td>
          </ng-container>
          <ng-container matColumnDef="data_entry_status" style="width: 10%">
            <th mat-header-cell *matHeaderCellDef>
              Data Entry Status
            </th>
            <td mat-cell *matCellDef="let element">{{element.dataEntryStatusDescription}}</td>
          </ng-container>
          <ng-container matColumnDef="disabled" style="width: 5%">
            <th [hidden]="userType!='internal'" mat-header-cell *matHeaderCellDef>
              Disabled
            </th>
            <td [hidden]="userType!='internal'" mat-cell *matCellDef="let element">{{element.isActive? '': 'Yes'}}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width: 5%"><div>Action</div><div style="margin-top:0em;padding-bottom:0.25em">
              <a mat-button class="btn-high grid" style="align-self:center" (click)="clickEdit($event, null);">Add</a></div></th>
            <td mat-cell *matCellDef="let element; let i=index ">
              <a mat-button class="btn-low grid" (click)="clickEdit($event, element);">
                <span *ngIf="userType=='internal'">Edit</span>
                <span *ngIf="userType!='internal'">View</span>
              </a>
            </td>
          </ng-container>
        </tr> 
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
     
    </div>
    <!--<div>
      <mat-paginator style="border: none;width:108em;margin-top:1em" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSize]="pageSize"
                     [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
      </mat-paginator>
    </div>-->
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
