<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-1em" mat-dialog-title>Generate APIS</div>
<div mat-dialog-content style="margin-top:-1em;padding-bottom:0; background-color:white;" class="mx-1 pb-3">
  <form novalidate *ngIf="generate_apis_91_form" [formGroup]="generate_apis_91_form">
   
    <mat-card class="mb-2 mt-2">
      <mat-card-header>
        <mat-card-title>
          Emergency Contact
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="form-group row">
          <div class="col">
            <label for="emergencyFirst" style="font-size:small">First Name</label>
            <input type="text" required formControlName="emergencyFirst" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': f.emergencyFirst.errors }" />
            <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
              Required.
            </span>
          </div>
          <div class="col">
            <label for="emergencyLast" style="font-size:small">Last Name</label>
            <input type="text" required formControlName="emergencyLast" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': f.emergencyLast.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="emergencyPhone" style="font-size:small">Phone Number</label>
            <input type="text" required formControlName="emergencyPhone" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': f.emergencyPhone.errors }" />
          </div>
          <div class="col">
            <label for="emergencyEmail" style="font-size:small">Email</label>
            <input type="text" formControlName="emergencyEmail" class="form-control form-control-sm inputBox" />
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="mb-2">
      <mat-card-header>
        <mat-card-title>
          {{InboundOutbound}} Itinerary
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="form-group row">
          <div class="col">
            <label for="departureAirport" style="font-size:small">Departure Airport</label>
            <input type="text" required [readonly]="apisManifest.departureIsApisApproved && isOutbound || isInbound" formControlName="departureAirport" class="form-control form-control-sm text-uppercase" (change)="validateAirport('departureAirport')" [ngClass]="{ 'is-invalid': f.departureAirport.errors, 'inputBoxReadOnly': apisManifest.departureIsApisApproved && isOutbound || isInbound, 'inputBox': apisManifest.departureIsApisApproved==false && isOutbound  }" />
            <span class="text-danger" *ngIf="isDepApisApproved==false && isOutbound">&nbsp; *Not an approved APIS airport.</span>
          </div>
          <div class="col">
            <label for="departureCity" style="font-size:small">Departure City</label>
            <input type="text" readonly formControlName="departureCity" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.departureCity.errors }" />
          </div>
          <div *ngIf="isInbound==false" class="col">
            <label for="departureState" style="font-size:small">Departure State</label>
            <input type="text" readonly formControlName="departureState" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.departureState.errors }" />
          </div>
          <div *ngIf="isInbound" class="col">
            <label for="departureCountry" style="font-size:small">Departure Country</label>
            <input type="text" readonly formControlName="departureCountry" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.departureCountry.errors }" />
          </div>
        </div>
        <div *ngIf="isInbound==false" class="form-group row">
          <div class="col">
            <label for="depPlaceDescription" style="font-size:small">Place Description</label>
            <input type="text" formControlName="depPlaceDescription" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': f.depPlaceDescription.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="localDepDate" style="font-size:small">Local Departure Date</label>
            <input type="text" readonly formControlName="localDepDate" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.localDepDate.errors }" />
          </div>
          <div class="col">
            <label for="localDepTime" style="font-size:small">Local Departure Time</label>
            <input type="text" readonly formControlName="localDepTime" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.localDepTime.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="borderCrossing" style="font-size:small">Border Crossing</label>
            <input type="text" formControlName="borderCrossing" class="form-control form-control-sm inputBox" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label style="font-size:small">Foreign Airports in {{lastNext}} 24 hrs</label>
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <input type="text" formControlName="foreignAirport1" class="form-control form-control-sm inputBox" />
          </div>
          <div class="col">
            <input type="text" formControlName="foreignAirport2" class="form-control form-control-sm inputBox" />
          </div>
          <div class="col">
            <input type="text" formControlName="foreignAirport3" class="form-control form-control-sm inputBox" />
          </div>
          <div class="col">
            <input type="text" formControlName="foreignAirport4" class="form-control form-control-sm inputBox" />
          </div>
          <div class="col">
            <input type="text" formControlName="foreignAirport5" class="form-control form-control-sm inputBox" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="arrivalAirport" style="font-size:small">Arrival Airport</label>
            <input type="text" required readonly formControlName="arrivalAirport" class="form-control form-control-sm inputBoxReadOnly" (change)="validateAirport('arrivalAirport')" [ngClass]="{ 'is-invalid': f.arrivalAirport.errors}" />
            <span class="text-danger" *ngIf="isArrApisApproved==false && isInbound">&nbsp; *Not an approved APIS airport.</span>
          </div>
          <div class="col">
            <label for="arrivalCity" style="font-size:small">Arrival City</label>
            <input type="text" readonly formControlName="arrivalCity" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.arrivalCity.errors }" />
          </div>
          <div *ngIf="isInbound" class="col">
            <label for="arrivalState" style="font-size:small">Arrival State</label>
            <input type="text" readonly formControlName="arrivalState" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.arrivalState.errors }" />
          </div>
          <div *ngIf="isInbound==false" class="col">
            <label for="arrivalCountry" style="font-size:small">Arrival Country</label>
            <input type="text" readonly formControlName="arrivalCountry" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.arrivalCountry.errors }" />
          </div>
        </div>
        <div *ngIf="isInbound" class=" form-group row">
          <div class="col">
            <label for="arrPlaceDescription" style="font-size:small">Place Description</label>
            <input type="text" formControlName="arrPlaceDescription" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': f.arrPlaceDescription.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="localArrDate" style="font-size:small">Local Arrival Date</label>
            <input type="text" readonly formControlName="localArrDate" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.localArrDate.errors }" />
          </div>
          <div class="col">
            <label for="localArrTime" style="font-size:small">Local Arrival Time</label>
            <input type="text" readonly formControlName="localArrTime" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.localArrTime.errors }" />
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="mb-2">
      <mat-card-header>
        <mat-card-title>
          Aircraft
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="form-group row">
          <div class="col">
            <label for="tailNumber" style="font-size:small">Tail Number</label>
            <input type="text" readonly formControlName="tailNumber" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.tailNumber.errors }" />
          </div>
          <div class="col">
            <label for="aircraftType" style="font-size:small">Aircraft Type</label>
            <input type="text" readonly formControlName="aircraftType" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.aircraftType.errors }" />
          </div>
          <div class="col">
            <label for="color" style="font-size:small">Color</label>
            <input type="text" readonly formControlName="color" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.color.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="callSign" style="font-size:small">Call Sign</label>
            <input type="text" readonly formControlName="callSign" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
          <div class="col">
            <label for="cbpDecal" style="font-size:small">CBP Decal</label>
            <input type="text" readonly formControlName="cbpDecal" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'valid-info': f.cbpDecal.value =='' }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label style="font-size:small;">Operator</label>
          </div>
        </div>
        <div *ngIf="apisManifest.operatorCompany!='' && apisManifest.operatorCompany!=null" class="form-group row">
          <div class="col">
            <label for="operatorCompany" style="font-size:small">Company Name</label>
            <input type="text" readonly formControlName="operatorCompany" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.operatorCompany.errors }" />
          </div>
        </div>
        <div *ngIf="apisManifest.operatorCompany=='' || apisManifest.operatorCompany==null" class="form-group row">
          <div class="col">
            <label for="operatorFirst" style="font-size:small">First Name</label>
            <input type="text" readonly formControlName="operatorFirst" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.operatorFirst.errors }" />
          </div>
          <div class="col">
            <label for="operatorMiddle" style="font-size:small">Middle Name</label>
            <input type="text" readonly formControlName="operatorMiddle" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
          <div class="col">
            <label for="operatorLast" style="font-size:small">Last Name</label>
            <input type="text" readonly formControlName="operatorLast" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.operatorLast.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="operatorStreet1" style="font-size:small">Street Address 1</label>
            <input type="text" readonly formControlName="operatorStreet1" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.operatorStreet1.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="operatorStreet2" style="font-size:small">Street Address 2</label>
            <input type="text" readonly formControlName="operatorStreet2" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="operatorCity" style="font-size:small">City</label>
            <input type="text" readonly formControlName="operatorCity" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.operatorCity.errors }" />
          </div>
          <div class="col">
            <label for="operatorState" style="font-size:small">State/Province</label>
            <input type="text" readonly formControlName="operatorState" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
          <div class="col">
            <label for="operatorZip" style="font-size:small">Zip/Postal</label>
            <input type="text" readonly formControlName="operatorZip" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
          <div class="col">
            <label for="operatorCountry" style="font-size:small">Country</label>
            <input type="text" readonly formControlName="operatorCountry" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.operatorCountry.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="operatorPhone" style="font-size:small">Phone</label>
            <input type="text" readonly formControlName="operatorPhone" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.operatorPhone.errors }" />
          </div>
          <div class="col">
            <label for="operatorFax" style="font-size:small">Fax</label>
            <input type="text" readonly formControlName="operatorFax" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
          <div class="col">
            <label for="operatorEmail" style="font-size:small">Email</label>
            <input type="text" readonly formControlName="operatorEmail" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.operatorEmail.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label style="font-size:small;">Owner/Lessee</label>
          </div>
        </div>
        <div *ngIf="apisManifest.ownerCompany!='' && apisManifest.ownerCompany!=null" class="form-group row">
          <div class="col">
            <label for="ownerCompany" style="font-size:small">Company Name</label>
            <input type="text" readonly formControlName="ownerCompany" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.ownerCompany.errors }" />
          </div>
        </div>
        <div *ngIf="apisManifest.ownerCompany=='' || apisManifest.ownerCompany==null" class="form-group row">
          <div class="col">
            <label for="ownerFirst" style="font-size:small">First Name</label>
            <input type="text" readonly formControlName="ownerFirst" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.ownerFirst.errors }" />
          </div>
          <div class="col">
            <label for="ownerMiddle" style="font-size:small">Middle Name</label>
            <input type="text" readonly formControlName="ownerMiddle" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
          <div class="col">
            <label for="ownerLast" style="font-size:small">Last Name</label>
            <input type="text" readonly formControlName="ownerLast" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.ownerLast.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="ownerStreet1" style="font-size:small">Street Address 1</label>
            <input type="text" readonly formControlName="ownerStreet1" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.ownerStreet1.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="ownerStreet2" style="font-size:small">Street Address 2</label>
            <input type="text" readonly formControlName="ownerStreet2" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="ownerCity" style="font-size:small">City</label>
            <input type="text" readonly formControlName="ownerCity" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
          <div class="col">
            <label for="ownerState" style="font-size:small">State/Province</label>
            <input type="text" readonly formControlName="ownerState" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
          <div class="col">
            <label for="ownerZip" style="font-size:small">Zip/Postal</label>
            <input type="text" readonly formControlName="ownerZip" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
          <div class="col">
            <label for="ownerCountry" style="font-size:small">Country</label>
            <input type="text" readonly formControlName="ownerCountry" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.ownerCountry.errors }" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <label for="ownerPhone" style="font-size:small">Phone</label>
            <input type="text" readonly formControlName="ownerPhone" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.ownerPhone.errors }" />
          </div>
          <div class="col">
            <label for="ownerFax" style="font-size:small">Fax</label>
            <input type="text" readonly formControlName="ownerFax" class="form-control form-control-sm inputBoxReadOnly" />
          </div>
          <div class="col">
            <label for="ownerEmail" style="font-size:small">Email</label>
            <input type="text" readonly formControlName="ownerEmail" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="{ 'is-invalid': f.ownerEmail.errors }" />
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="mb-2">
      <mat-card-header>
        <mat-card-title>
          Crew
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-template ngFor let-crew [ngForOf]="apisManifest.crewList" let-i="index">
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small;font-weight:bold">Crew {{i+1}}</label>
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small">Traveler Type</label>
              <input readonly required formControlName="crewTravelerType{{i}}" #crewTravelerType id="crewTravelerType{{i}}" type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewTravelerType)" />

            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small">First Name</label>
              <input readonly required formControlName="crewFirstName{{i}}" id="crewFirstName{{i}}" #crewFirstName type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewFirstName)" />
            </div>
            <div class="col">
              <label style="font-size:small">Middle Name</label>
              <input readonly formControlName="crewMiddleName{{i}}" type="text" class="form-control form-control-sm inputBoxReadOnly" />
            </div>
            <div class="col">
              <label style="font-size:small">Last Name</label>
              <input readonly required formControlName="crewLastName{{i}}" id="crewLastName{{i}}" #crewLastName htype="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewLastName)" />
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small">Birthdate</label>
              <input readonly required formControlName="crewBirthdate{{i}}" id="crewBirthdate{{i}}" #crewBirthdate type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewBirthdate)" />
              <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                  {{errMessage}}
              </span>
            </div>
            <div class="col">
              <label style="font-size:small">Sex</label>
              <input readonly required formControlName="crewSex{{i}}" id="crewSex{{i}}" #crewSex type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewSex)" />
            </div>
            <div class="col">
              <label style="font-size:small">Residence Country</label>
              <input readonly required formControlName="crewResidence{{i}}" id="crewResidence{{i}}" #crewResidence type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewResidence)" />
            </div>
            <div class="col">
              <label style="font-size:small">Citizenship Country</label>
              <input readonly required formControlName="crewCitizenship{{i}}" id="crewCitizenship{{i}}" #crewCitizenship type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewCitizenship)" />
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small;">Permanent Address</label>
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small">Street Address</label>
              <input readonly required formControlName="crewPermAddressStreet{{i}}" id="crewPermAddressStreet{{i}}" #crewPermAddressStreet type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewPermAddressStreet)" />
            </div>
            <div class="col">
              <label style="font-size:small">City</label>
              <input readonly required formControlName="crewPermAddressCity{{i}}" id="crewPermAddressCity{{i}}" #crewPermAddressCity type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewPermAddressCity)" />
            </div>
            <div class="col">
              <label style="font-size:small">State/Province</label>
              <input readonly formControlName="crewPermAddressState{{i}}" type="text" class="form-control form-control-sm inputBoxReadOnly" />
            </div>
            <div class="col">
              <label style="font-size:small">Postal Code</label>
              <input readonly formControlName="crewPermAddressPostal{{i}}" type="text" class="form-control form-control-sm inputBoxReadOnly" />
            </div>
            <div class="col">
              <label style="font-size:small">Country</label>
              <input readonly required formControlName="crewPermAddressCountry{{i}}" id="crewPermAddressCountry{{i}}" #crewPermAddressCountry type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewPermAddressCountry)" />
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small;">Address in US</label>
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small">Street Address</label>
              <input readonly required formControlName="crewUSAddressStreet{{i}}" id="crewUSAddressStreet{{i}}" #crewUSAddressStreet type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewUSAddressStreet)" />
            </div>
            <div class="col">
              <label style="font-size:small">City</label>
              <input readonly required formControlName="crewUSAddressCity{{i}}" id="crewUSAddressCity{{i}}" #crewUSAddressCity type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewUSAddressCity)" />
            </div>
            <div class="col">
              <label style="font-size:small">State/Province</label>
              <input readonly required formControlName="crewUSAddressState{{i}}" id="crewUSAddressState{{i}}" #crewUSAddressState type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewUSAddressState)" />
            </div>
            <div class="col">
              <label style="font-size:small">Postal Code</label>
              <input readonly required formControlName="crewUSAddressPostal{{i}}" id="crewUSAddressPostal{{i}}" #crewUSAddressPostal type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewUSAddressPostal)" />
            </div>
          </div>
          <div *ngFor="let doc of crew.apisTravelDocuments; let j=index">
            <div class="row form-group">
              <div class="col">
                <label style="font-size:small;">Crew Document {{j+1}}</label>
              </div>
            </div>
            <div class="row form-group">
              <div class="col">
                <label style="font-size:small">Document Type</label>
                <input readonly formControlName="crew{{i}}docType{{j}}" #crewDocType type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewDocType)" />
              </div>
              <div class="col">
                <label style="font-size:small">Document Code</label>
                <input readonly formControlName="crew{{i}}docCode{{j}}" id="crew{{i}}docCode{{j}}" #crewDocCode type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewDocCode)" />
              </div>
              <div class="col">
                <label style="font-size:small">Document Number</label>
                <input readonly formControlName="crew{{i}}docNumber{{j}}" id="crew{{i}}docNumber{{j}}" #crewDocNumber type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewDocNumber)" />
              </div>
              <div class="col">
                <label style="font-size:small">Document Expiry</label>
                <input readonly formControlName="crew{{i}}docExpiry{{j}}" id="crew{{i}}docExpiry{{j}}" #crewDocExpiry type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewDocExpiry)" />
              </div>
              <div class="col">
                <label style="font-size:small">Document Country</label>
                <input readonly formControlName="crew{{i}}docCountry{{j}}" id="crew{{i}}docCountry{{j}}" #crewDocCountry type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(crewDocCountry)" />
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small;">Contact Tracing</label>
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small">Contact Phone 1</label>
              <input formControlName="crewContactPhone1{{i}}" type="text" class="form-control form-control-sm inputBox" />
            </div>
            <div class="col">
              <label style="font-size:small">Contact Phone 2</label>
              <input formControlName="crewContactPhone2{{i}}" type="text" class="form-control form-control-sm inputBox" />
            </div>
            <div class="col">
              <label style="font-size:small">Contact Email</label>
              <input formControlName="crewContactEmail{{i}}" type="text" class="form-control form-control-sm inputBox" />
            </div>
          </div>
        </ng-template>

      </mat-card-content>
    </mat-card>
    <mat-card class="mb-2">
      <mat-card-header>
        <mat-card-title>
          Passengers
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-template ngFor let-pax [ngForOf]="apisManifest.paxList" let-i="index">
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small;font-weight:bold">Passenger {{i+1}}</label>
            </div>
          </div>

          <div class="row form-group">
            <div class="col">
              <label style="font-size:small">First Name</label>
              <input readonly required formControlName="paxFirstName{{i}}" id="paxFirstName{{i}}" #paxFirstName type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxFirstName)" />
            </div>
            <div class="col">
              <label style="font-size:small">Middle Name</label>
              <input readonly formControlName="paxMiddleName{{i}}" type="text" class="form-control form-control-sm inputBoxReadOnly" />
            </div>
            <div class="col">
              <label style="font-size:small">Last Name</label>
              <input readonly required formControlName="paxLastName{{i}}" id="paxLastName{{i}}" #paxLastName type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxLastName)" />
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small">Birthdate</label>
              <input readonly required formControlName="paxBirthdate{{i}}" id="paxBirthdate{{i}}" #paxBirthdate type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxBirthdate)" />
              <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                  {{errMessage}}
              </span>
            </div>
            <div class="col">
              <label style="font-size:small">Sex</label>
              <input readonly required formControlName="paxSex{{i}}" id="paxSex{{i}}" #paxSex type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxSex)" />
            </div>
            <div class="col">
              <label style="font-size:small">Residence Country</label>
              <input readonly required formControlName="paxResidence{{i}}" id="paxResidence{{i}}" #paxResidence type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxResidence)" />
            </div>
            <div class="col">
              <label style="font-size:small">Citizenship Country</label>
              <input readonly required formControlName="paxCitizenship{{i}}" id="paxCitizenship{{i}}" #paxCitizenship type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxCitizenship)" />
            </div>
          </div>

          <div class="row form-group">
            <div class="col">
              <label style="font-size:small;">Address in US</label>
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small">Street Address</label>
              <input readonly required formControlName="paxUSAddressStreet{{i}}" id="paxUSAddressStreet{{i}}" #paxUSAddressStreet type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxUSAddressStreet)" />
            </div>
            <div class="col">
              <label style="font-size:small">City</label>
              <input readonly required formControlName="paxUSAddressCity{{i}}" id="paxUSAddressCity{{i}}" #paxUSAddressCity type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxUSAddressCity)" />
            </div>
            <div class="col">
              <label style="font-size:small">State/Province</label>
              <input readonly required formControlName="paxUSAddressState{{i}}" id="paxUSAddressState{{i}}" #paxUSAddressState type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxUSAddressState)" />
            </div>
            <div class="col">
              <label style="font-size:small">Postal Code</label>
              <input readonly required formControlName="paxUSAddressPostal{{i}}" id="paxUSAddressPostal{{i}}" #paxUSAddressPostal type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxUSAddressPostal)" />
            </div>
          </div>
          <div *ngFor="let doc of pax.apisTravelDocuments; let j=index">
            <div class="row form-group">
              <div class="col">
                <label style="font-size:small;">Passenger Document {{j+1}}</label>
              </div>
            </div>
            <div class="row form-group">
              <div class="col">
                <label style="font-size:small">Document Type</label>
                <input readonly formControlName="pax{{i}}docType{{j}}" #paxDocType type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxDocType)" />
              </div>
              <div class="col">
                <label style="font-size:small">Document Code</label>
                <input readonly formControlName="pax{{i}}docCode{{j}}" id="pax{{i}}docCode{{j}}" #paxDocCode type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxDocCode)" />
              </div>
              <div class="col">
                <label style="font-size:small">Document Number</label>
                <input readonly formControlName="pax{{i}}docNumber{{j}}" id="pax{{i}}docNumber{{j}}" #paxDocNumber type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxDocNumber)" />
              </div>
              <div class="col">
                <label style="font-size:small">Document Expiry</label>
                <input readonly formControlName="pax{{i}}docExpiry{{j}}" id="pax{{i}}docExpiry{{j}}" type="text" class="form-control form-control-sm inputBoxReadOnly" #paxDocExpiry [ngClass]="setClass(paxDocExpiry)" />
              </div>
              <div class="col">
                <label style="font-size:small">Document Country</label>
                <input readonly formControlName="pax{{i}}docCountry{{j}}" id="pax{{i}}docCountry{{j}}" #paxDocCountry type="text" class="form-control form-control-sm inputBoxReadOnly" [ngClass]="setClass(paxDocCountry)" />
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small">Contact Tracing</label>
            </div>
          </div>
          <div class="row form-group">
            <div class="col">
              <label style="font-size:small">Contact Phone 1</label>
              <input formControlName="paxContactPhone1{{i}}" type="text" class="form-control form-control-sm inputBox" />
            </div>
            <div class="col">
              <label style="font-size:small">Contact Phone 2</label>
              <input formControlName="paxContactPhone2{{i}}" type="text" class="form-control form-control-sm inputBox" />
            </div>
            <div class="col">
              <label style="font-size:small">Contact Email</label>
              <input formControlName="paxContactEmail{{i}}" type="text" class="form-control form-control-sm inputBox" />
            </div>
          </div>
        </ng-template>

      </mat-card-content>
    </mat-card>

  </form>
</div>

<div align="start" class="d-inline-flex">
  <div>
    <button mat-button class="mt-2 btn-high" [disabled]="loadingXml || (hasNoDocument && !checkNoDocuments)" (click)="generate(false)"><span *ngIf="loadingXml" class="spinner-border spinner-border-sm mr-1"></span>Generate XML</button>
    <button mat-button class="mt-2 ml-2 btn-high" [disabled]="submittingXml || (hasNoDocument && !checkNoDocuments)" (click)="generate(true)"><span *ngIf="submittingXml" class="spinner-border spinner-border-sm mr-1"></span>Submit APIS</button>
    <button mat-button class="mt-2 ml-2 btn-high" [disabled]="loadingReport" (click)="generateReport()"><span *ngIf="loadingReport" class="spinner-border spinner-border-sm mr-1"></span>Generate Report</button>
    <button mat-button class="mt-2 ml-2 btn-low" (click)="close()" mat-dialog-close>Close</button>
  </div>
  <div *ngIf="hasNoDocument" style="margin-left:-2.75em;margin-top:.5em">
    <mat-checkbox [(ngModel)]="checkNoDocuments" style="transform:scale(.8)">By checking this box I understand I am submitting a traveler who has no documents.</mat-checkbox>
  </div>
  <div class="mt-1 ml-2">
    <app-success-message *ngIf="showSuccessMessage" [successMsg]="'Manifest submission successful. Check audit for confirmation code.'"></app-success-message>
  </div>
  <div *ngIf="errMsg!=''" class="ml-2 mt-2 small alert alert-danger py-0 px-1">{{errMsg}}</div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
