import { Component, OnInit, OnChanges, SimpleChanges, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MessageModel } from '../models/message.model';
import { MessageService } from '../services/message.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ResponseModel } from '../models/response.model';
import { EmailInternalDialogComponent } from '../messages/email-internal-dialog.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { TripModel } from '../models/trip.model';
import { TripLegService } from '../services/trip-leg.service';
import { TripLegModel } from '../models/trip-leg.model';
import { LinkMessageLegList, LinkMessageServiceList } from '../models/link-message-list.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { map, startWith, switchMap } from 'rxjs/operators';
import { MessageCenterModel } from '../models/message-center.model';
import { TripMessageRequest } from '../models/trip-message-request.model';
import { UntypedFormControl } from '@angular/forms';
import { ServiceClassModel } from '../models/service-class.model';
import { UserModel } from '../models/user.model';
import { IdentityService } from '../services/identity.service';
import { Observable, forkJoin } from 'rxjs';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { MatLegacySnackBar as MatSnackBar, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { IdentityRequestModel } from '../models/identity-request.model';
import { MatTabsModule } from '@angular/material/tabs';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { DialogService } from '../services/dialog.service';


export interface legFilterDTO {
  departure: string;
  arrival: string;
  departureDateTime: Date;
  arrivalDateTime: Date;
  dateText: string;
  groundStopGUID: string;
  nextGroundStopGUID: string;
  selected: boolean;
}

@Component({
  selector: 'app-trip-message-center',
  templateUrl: './trip-message-center.component.html',
  styleUrls: ['./trip-message-center.component.css'],
   providers: [
     { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
   ],
  //encapsulation: ViewEncapsulation.None
})

export class TripMessageCenterComponent implements OnInit, AfterViewInit, OnChanges {

  messageList = new MatTableDataSource<MessageModel>();
  selection = new SelectionModel<MessageModel>(true, []);
  displayedColumn: string[] = ['message_type', 'date_time', 'services',  'sender', 'subject', 'body', 'has_attachments', 'action2'];
  displayedColumn2: string[] = ['departure', 'dep_date_time', 'arrival', 'arr_date_time', 'messageCount'];

  showSpin: boolean = false;
  showspinleg: boolean = false;
  isModified: boolean = false;
  customerGUID: string;
  groundStopGUID: string;
  nextGroundStopGUID: string;
  groundStopTaskGUID: string;
  tripCodeGUID: string;
  serviceTypeID: number;
  aircraftGUID: string;

  registration: string;
  trip_code: string;
  hidePastStop: boolean = false;
  includeActiveStopOnly: boolean = true;
  disabled: boolean = true;

  
  selectedLinkList: LinkMessageServiceList[] = [];
  existingLinkList: LinkMessageLegList[] = [];
  displayLinkList: LinkMessageLegList[] = [];
  totalRecordsText: string;
  errMsg: string;
  legFilterList: legFilterDTO[] = [];
  selectedLegFilterList: legFilterDTO[] = [];
  showlegFilter: boolean = false;
  selectedServiceTypeList: ServiceTypeModel[];
  totalMessageCount: string = "";
  totalInMsgCount: string = "";
  totalOutMsgCount: string = "";
  resultsLength = 0;
  InBoundRecords = 0;
  OutBoundRecords = 0;
  selectedGroundStopList:string;
  InboundValue = 2;

  checkServiceTypeArray: UntypedFormControl; // for mat-select
  allCompleteOps: boolean = false;
  allCompleteWx: boolean = false;
  allCompleteStatus: boolean = false;
  selectedServiceCountText: string = "";
  selectedLegCountText: string = "";
  checkTaskStatusArray: UntypedFormControl; // for mat-select
  showServiceFilter: boolean = false;
  showrecipientList: boolean = true;
  serviceClassList: ServiceClassModel[];
  selectedServiceClassList: ServiceClassModel[];
  orgServiceTypeList: ServiceTypeModel[];
  serviceTypeList: ServiceTypeModel[];
  showTaskStatusFilter: boolean = false;
  selectedLegIndex: Number;
  selectedMsgIndex: Number;
  showClientOnly:boolean=false;
  showTripOnly:boolean=false;
  
  isButtonDisabled:boolean = true;
  isButtonEnabled: boolean = false;
  inOutFiltervalue: string = 'both';
  clientCount:number=0;
  tripCount:number=0;
  taskCount:number=0;
  bothCount:number=0;
  showPersonList: boolean = false;
  
  includeDisabledTasks: boolean = false;
  routeLegList: GroundStopModel[] = [];
  activeGroundStopList: GroundStopModel[] = [];
  allGroundStopList: GroundStopModel[] = [];
  
  searchFrom: string = null;
  searchSubject: string = null;
  searchTo: string = null;
  searchFiltervalue: number = 3;
  searchQuery: string = '';
  selectedCustomerGUID: string = "";
  searchParam = null;
  searchAll: string = null; //   'egypt';
  searchAssigned: string = null;
  
  currentPageNumber: number = 0;
  grantAccessToMessages: boolean;
  showCommsInTrip:boolean = false;  
  serviceLocation: string;
  
  selectedValue:number = 2;
  showBothTripAndTask:boolean=true;

  
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;
  lockdownTrip:boolean = false; 
  selectedMessageGUID: string = "";
  
  constructor(private readonly _authService: AuthenticateService, private readonly _messageService: MessageService, private readonly _dialogRef: MatDialogRef<TripMessageCenterComponent>,
    private readonly _dialog: MatDialog, private readonly _tripLegService: TripLegService, private readonly _identityService: IdentityService,
    @Inject(MAT_DIALOG_DATA) _data: any, private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    private readonly _groundStopClientService: GroundStopClientService, private _snackBar: MatSnackBar, private readonly _dialogService: DialogService
  ) {
    this.tripCodeGUID = _data.tripCodeGUID;
    // this.hidePastStop = _data.hidePastStop;
    if (_data.source || _data.serviceLocation)
      this.includeActiveStopOnly = _data.includeActiveStopOnly;
    
    this.groundStopGUID = "";
    if (_data.groundStopGUID != null && _data.groundStopGUID != undefined && _data.groundStopGUID != "")
      this.groundStopGUID = _data.groundStopGUID;
    this.groundStopTaskGUID = "";
    if (_data.groundStopTaskGUID != null && _data.groundStopTaskGUID != undefined && _data.groundStopTaskGUID != "")
      this.groundStopTaskGUID = _data.groundStopTaskGUID;
    this.nextGroundStopGUID = "";
    if (_data.nextGroundStopGUID != null && _data.nextGroundStopGUID != undefined && _data.nextGroundStopGUID != "")
      this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.customerGUID = "";
    if (_data.customerGUID != null && _data.customerGUID != undefined && _data.customerGUID != "")
      this.customerGUID = _data.customerGUID;
    this.serviceTypeID = 0;
    if (_data.serviceTypeID != null && _data.serviceTypeID != undefined && _data.serviceTypeID != "")
      this.serviceTypeID = _data.serviceTypeID;
    this.aircraftGUID = "";
    if (_data.aircraftGUID != null && _data.aircraftGUID != undefined && _data.aircraftGUID != "")
      this.aircraftGUID = _data.aircraftGUID;

      this.registration = _data.registration;
      this.trip_code = _data.tripCodeText;
    if (this.groundStopGUID != "" && _data.source)
      this.selectedGroundStopList = this.groundStopGUID;
    
    this.inOutFiltervalue = !_data.source ? 'both': 'both';
    this.selectedLegIndex = _data.selectedLegIndex;
    this.serviceLocation = _data.serviceLocation;
    this.lockdownTrip = _data.lockdownTrip;



    
  }
  ngAfterViewInit(): void {
    
    if (this.paginator != undefined)
      {
        this.getAllData().subscribe(responses => {
  
          if (responses[0] != null) {
            if (responses[0].code == "200" && responses[0].message == "") {
              this.orgServiceTypeList = responses[0].model;
              this.serviceTypeList = this.orgServiceTypeList.filter(x => x.filterGroup != null);
              this.serviceTypeList.sort((a, b) => a.filterGroup - b.filterGroup || a.filterColumn - b.filterColumn || a.filterOrderBy - b.filterOrderBy);
    
              this.setServiceTypeList(false);
    
            } else {
              if (responses[0].code == "401") {
                this._authService.signOut();
              }
            }
          }

          if (responses.length > 1 && responses[1] != null) {
            if (responses[1].code == "200" && responses[1].message == "") {
              let t = new TripModel();
              t = responses[1].model;
              this.allGroundStopList = t.groundStopList;
              this.activeGroundStopList = t.activeGroundStopList;
              if (this.includeActiveStopOnly) {
                this.routeLegList = this.activeGroundStopList;
              }
              else {
                this.routeLegList = this.allGroundStopList;
              }
            } else {
              if (responses[1].code == "401") {
                this._authService.signOut();
              }
            }
          }

          // if (responses.length>0 && responses[2] != null) {
          //   if (responses[2].code == "200" && responses[2].message == "") {
          //     let user = responses[2].model;
          //     // this.userServiceTypeList = user.taskQueueServiceIDList;
          //     if (user.taskQueueServiceIDList != "") {
          //       this.serviceTypeList.forEach(x => {
          //         if (("," + user.taskQueueServiceIDList + ",").indexOf("," + x.serviceTypeID.toString() + ",") > -1) {
          //           x.selected = true;
          //         }
          //       });
          //       this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected);
          //     }
          //     this.clickDoneServiceType();
          //   }
          //   else {
          //     if (responses[2].code == "401") {
          //       this._authService.signOut();
          //     }
          //   }
          // }
          // else{
            this.serviceTypeList.forEach(x => {
              if (x.serviceTypeID == this.serviceTypeID) {
                x.selected = true;
              }
            });
            this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected);
          
            this.clickDoneServiceType();
          // }


    
    
          this.initControls();
          if (this.selectedServiceTypeList != null) {
            if (this.selectedServiceTypeList.length == this.serviceTypeList.length)
              this.selectedServiceCountText = ": All";
            else {
              if (this.selectedServiceTypeList.length == 0)
                this.selectedServiceCountText = "";
              else
                this.selectedServiceCountText = ": " + this.selectedServiceTypeList.length.toString();
            }
          }
          else
            this.selectedServiceCountText = ": All";
          
          //this.tabChanged(2);
    
        });

        this._messageService.selectedMessageGUID.subscribe(resp=>this.selectedMessageGUID = resp);

      }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.messageList.data = [];
    this.selectedMessageGUID = "";
    this.showSpin = false; 
    this.showspinleg = false;
    this._authService.updateAccessTime();
    this.initControls();
  }

  getMessages(){
    this.selectedMessageGUID = "";
    if (this.paginator != undefined)
      this.paginator.page.observers = [];
    if (this.paginator == undefined) return;
    
    this.showSpin = true;
    //this.resultsLength =0;
    //this.InBoundRecords = 0;
    //this.OutBoundRecords = 0;
    this.paginator.page.pipe(
      startWith({}),
      switchMap(() => {
        let request: TripMessageRequest = new TripMessageRequest();
        request.customerGUID = this.customerGUID;
        request.tripCodeGUID = this.tripCodeGUID;
        if (this.currentPageNumber != this.paginator.pageIndex) {
          this.messageList.data = [];
          this.currentPageNumber = this.paginator.pageIndex;
        }
        request.pageIndex = this.paginator.pageIndex;
        request.pageSize = this.paginator.pageSize;
        if (this.showClientOnly == false && this.showTripOnly == false  && this.showBothTripAndTask == false )
          request.groundstopGuidList = this.selectedGroundStopList ?? this.groundStopGUID;
        request.hidePastLegs = this.hidePastStop;
        request.showActiveLegs = this.includeActiveStopOnly;
        request.showClientOnly = this.showClientOnly;
        request.showTripOnly = this.showTripOnly;     
        request.showBothTripAndTask = this.showBothTripAndTask; 
        request.showDisabledTasks = this.includeDisabledTasks;
        request.searchFrom = this.searchFrom;
        request.searchSubject = this.searchSubject;
        request.searchTo = this.searchTo;
        request.searchAll = this.searchAll;   
        request.searchAssigned = this.searchAssigned; 
        if (this.showClientOnly==false && this.showTripOnly==false  && this.showBothTripAndTask == false  )
        request.groundStopTaskGUID = this.groundStopTaskGUID;


        if (this.inOutFiltervalue != 'both')
          request.isInbound = this.inOutFiltervalue;

        //request.inbound = this.InboundValue;
        if(this.selectedServiceTypeList != undefined)
        if (this.selectedServiceTypeList.length == this.serviceTypeList.length) {
          request.serviceTypeIdList = "";
        }
        else {
          request.serviceTypeIdList = Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
        }
        return this._messageService.getTripMessageListByConditions<ResponseModel<MessageCenterModel>>(request);
      }),
      map(response => {
        if (response!.code == "401") {
          this._authService.signOut();
        }
        if (response.model === null) {
          return [];
        }

        return response.model;
      })).subscribe((response:MessageCenterModel) => {
        if(response.messages.length>0)
        {
          this.messageList = new MatTableDataSource<(MessageModel)>(response.messages);
          this.showSpin = false;
          this.totalMessageCount = this.resultsLength > 0 ? ": " + this.resultsLength.toString() : ""
          //this.resultsLength = response.totalRecords;
          //this.InBoundRecords = response.inboundRecords;
          //this.OutBoundRecords = response.outboundRecords;
          //this.clientCount = response.clientCount;
          //this.tripCount = response.tripCount;
          //this.taskCount = response.taskCount;
        }
        else this.messageList = new MatTableDataSource<MessageModel>();
        
        this.resultsLength = response.totalRecords;
        this.InBoundRecords = response.inboundRecords;
        this.OutBoundRecords = response.outboundRecords;
        this.clientCount = response.clientCount;
        this.tripCount = response.tripCount;
        this.taskCount = response.taskCount;
        this.bothCount = response.bothCount;
        this.showSpin = false;
    });
    
  }

  getLegList() {
    this.showspinleg = true;
    let request = new TripModel();
    request.tripCodeType = "";
    request.customerGUID = this.customerGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    request.aircraftGUID = this.aircraftGUID;
    request.includeActiveStopOnly = this.includeActiveStopOnly;
    request.groundStopTypeGUIDList = '';
    request.isFromLegEdit = false;
    this._tripLegService.getSimpleGroundStopLegList<ResponseModel<TripLegModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          var result = response.model;

          this.existingLinkList = [];

          if (response.model.length > 0) {
            let leg: LinkMessageLegList;
            response.model.forEach(x => {
              leg = new LinkMessageLegList();
              leg.departure = x.departureICAO;
              leg.arrival = x.nextArrivalICAO;
              leg.departureDateTime = x.departureDateTimeUTCDisplay;
              leg.arrivalDateTime = x.nextArrivalDateTimeUTCDisplay;
              leg.departureDateTextUTC = x.departureDateTextUTC;
              leg.nextArrivalDateTextUTC = x.nextArrivalDateTextUTC;
              leg.groundStopGUID = x.groundStopGUID;
              leg.nextGroundStopGUID = x.nextArrivalGroundStopGUID;
              leg.colorCode = x.departureGroundStopTypeColor;
              leg.nextColorCode = x.nextArrivalGroundStopTypeColor;
              leg.arrivalDateTimePassed = x.nextArrivalDateTimePassed;
              if (x.messageCount > 0)
                leg.messageCount = x.messageCount;
              leg.arrivalServiceList = [];
              leg.departureServiceList = [];

              this.existingLinkList.push(leg);
            });
          }
          this.displayLinkList = this.existingLinkList;
          if (this.serviceLocation == 'A') {
            this.selectedLegIndex = this.displayLinkList.findIndex(leg => leg.nextGroundStopGUID == this.groundStopGUID);
            this.selectedGroundStopList = this.groundStopGUID;
          }
          else if (this.serviceLocation == 'D') {
            this.selectedLegIndex = this.displayLinkList.findIndex(leg => leg.groundStopGUID == this.groundStopGUID);
            this.selectedGroundStopList = this.groundStopGUID;
          }

        }
        else {
          this.totalRecordsText = "No record found";
          this.displayLinkList = [];
          this.existingLinkList = [];
        }
        this.showspinleg = false;
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      //this.clickDoneLegFilter();
      this.showspinleg = false;
    });
  }

  getAllData(): Observable<any[]> {
    let getService = this._groundStopAdvancedService.getServiceClass_ServiceTypeList(1);
    let req3 = new IdentityRequestModel();
    let userResponse = this._identityService.getUser(req3);
    let request = new AircraftTripModel();
    request.aircraftGUID = this.aircraftGUID;
    request.customerGUID = this.customerGUID;
    request.startDateText = localStorage.getItem('trip.sd');
    request.endDateText = localStorage.getItem('trip.ed');
    request.tripCodeGUID = this.tripCodeGUID;
    let  getTripResponse = this._groundStopClientService.getTripBytId(request);
    if (this.serviceTypeID > 0)
      return forkJoin([getService, getTripResponse]);
    return forkJoin([getService, getTripResponse, userResponse]);
  }


  ngOnInit() {
    this.showSpin = true;
    this.showspinleg = true;
    this._authService.updateAccessTime();
    this.grantAccessToMessages = false;
    if (localStorage.getItem('up.msg') == 'true') {
      this.grantAccessToMessages = true;
    }


    if (!this.grantAccessToMessages) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this._dialogRef.close(false);
      return;
    }

    if (this.groundStopTaskGUID != "") {
      this.showClientOnly = false;
      this.showTripOnly = false;
      this.showBothTripAndTask = false;
      this.isButtonEnabled = false;
      this.isButtonDisabled = true;
      this.selectedValue = 1;
    }
    else {
      this.showClientOnly = false;
      this.showTripOnly = false;
      this.showBothTripAndTask = true;
      this.isButtonEnabled = true;
      this.isButtonDisabled = false;
      this.selectedValue = 2;
    }
    this.selectedServiceClassList = [];
    this.getLegList();
    // this.selectedLegGroundStop = this.displayLinkList.filter(dll => dll.groundStopGUID = this.groundStopGUID and (dll.arrivalServiceList.) )
  }
  initControls() {
    
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  openMessage(message: MessageModel,j) {
    let s = "";
    this.selectedMessageGUID = message.messageGUID;

    this.selectedMsgIndex = j;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.position = { top: "40px"};
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", messageGUID: message.messageGUID, isDraft: message.isDraft, 
      v: this._authService.getCurrentTimeNumber(), allMessageData:this.messageList.data, lockdownTrip: this.lockdownTrip
    };

    const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // this.selectedMessageGUID = "";

      if (result == true) {
        //this.isModified = true;
        // this.getData(true);
        this.getMessages();

      }

    });
  }

  hidePastStopChange(e: any) {
    this.messageList.data = [];
    this.hidePastStop = e.checked;
    this.selectedGroundStopList = null;
    this.getMessages();
    //this.getLegList();
    this.selectedLegIndex = null;

  }
  showActiveLegsOnly(e) {
    this.messageList.data = [];
    this.includeActiveStopOnly = e.checked;
    this.selectedGroundStopList = null;
    this.getLegList();
    this.getMessages();
    this.selectedLegIndex = null;
    if (this.includeActiveStopOnly) {
      this.routeLegList = this.activeGroundStopList;
    }
    else {
      this.routeLegList = this.allGroundStopList;
    }
  }
  includeDisabledTasksChange(e: any) {
    this.includeDisabledTasks = e.checked;
    //this.selectedGroundStopList = null;
    this.getMessages();
    //this.getLegList();
    //this.selectedLegIndex = null;

  }
  

  filterMessagesWithLegs(leg: LinkMessageLegList, i) {
    this.selectedServiceTypeList = [];
    this.selectedServiceCountText = ": All"; 
    this.clickResetServiceType();

    if (this.selectedLegIndex != null && this.selectedLegIndex==i) {
      this.selectedGroundStopList = null;
      this.groundStopGUID = null;
      this.selectedLegIndex = null;
      this.getMessages();
    }
    else {
      this.selectedGroundStopList = leg.groundStopGUID + ',' + leg.nextGroundStopGUID;
      this.getMessages();
      this.selectedLegIndex = i;
    }

  }
    
  updateAllComplete(group: number) {
    switch (group) {
      case 1:
        this.allCompleteOps = this.serviceTypeList.filter(x => x.filterGroup == group).every(t => t.selected);
        break;
      case 2:
        this.allCompleteWx = this.serviceTypeList.filter(x => x.filterGroup == group).every(t => t.selected);
        break;

    }
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
    if (this.selectedServiceTypeList.length == this.serviceTypeList.length)
      this.selectedServiceCountText = ": All";
    else {
      if (this.selectedServiceTypeList.length == 0)
        this.selectedServiceCountText = "";
      else
        this.selectedServiceCountText = ": " + this.selectedServiceTypeList.length.toString();
    }
  }
  onServiceTypeChange(e: any, item: ServiceTypeModel) {
    //item.selected = e.source.selected;
    item.selected = e.checked;
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
    this.updateAllComplete(item.filterGroup);

  }

  setServiceTypeList(selected: boolean) {
    if (this.selectedServiceClassList.length == 0) {
      this.serviceTypeList = this.orgServiceTypeList.filter(x => x.filterGroup != null);
      this.serviceTypeList.sort((a, b) => a.filterGroup - b.filterGroup || a.filterColumn - b.filterColumn || a.filterOrderBy - b.filterOrderBy);
      this.serviceTypeList.forEach(x => {
        x.selected = false;
        x.serviceClassSelected = false;
      });
    }
    else {
      this.serviceTypeList = this.orgServiceTypeList.filter(u => u.serviceClassSelected == selected);
    }
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);

    this.checkServiceTypeArray = new UntypedFormControl(this.selectedServiceTypeList); // for mat-select
    this.updateAllComplete(1);
    this.updateAllComplete(2);
  }

  clickResetServiceType() {
    this.serviceTypeList.every(t => t.selected = false);
    this.setServiceTypeList(false);
    this.showServiceFilter = false;
    this.selectedServiceCountText = "";
    this.groundStopTaskGUID = "";
    // this.getMessages();
  }

  clickDoneServiceType() {
    this.showServiceFilter = false;
    this.getMessages();
  }


  clickServiceFilter() {
    this.showServiceFilter = !this.showServiceFilter;
    this.showTaskStatusFilter = false;

    this.showlegFilter = false;
  }

  someComplete(group: number): boolean {
    switch (group) {
      case 1:
        return this.serviceTypeList.filter(t => t.selected && t.filterGroup == group).length > 0 && !this.allCompleteOps;
      case 2:
        return this.serviceTypeList.filter(t => t.selected && t.filterGroup == group).length > 0 && !this.allCompleteWx;
    }
  }

  selectAllServiceType(completed: boolean, group: number) {
    switch (group) {
      case 1:
        this.allCompleteOps = completed;
        this.serviceTypeList.filter(x => x.filterGroup == group).forEach(t => {
          t.selected = completed;

        });
        break;
      case 2:
        this.allCompleteWx = completed;
        this.serviceTypeList.filter(x => x.filterGroup == group).forEach(t => {
          t.selected = completed;

        });
        break;
    }
    this.updateAllComplete(group);
  }

  clickSaveSelections(type: string) {
    this._authService.updateAccessTime();
    let request = new UserModel();
    if (type.toLowerCase() == 'service') {
      request.taskQueueServiceIDList = "";
      request.taskQueueServiceIDList = Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
      request.taskQueueStatusList = null;
    }

    this._identityService.saveUserProfile<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this._snackBar.openFromComponent(SuccessComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            data: type,
            panelClass: ['green-snackbar']
          });

        }
        else {
          this._snackBar.open('Error Saving', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['red-snackbar']
          });

        }
      }
      else {
        if (response.code == "401") {
          if (response.message != "") {
            this._snackBar.open(response.message, '', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
              panelClass: ['red-snackbar']
            });
          }
          else {
            this._authService.signOut();
          }
        }
        else {
          this._snackBar.open('Error Saving', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['red-snackbar']
          });

        }
      }
    })
  }

  clickEmail() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, tripCode:this.trip_code,  groundStopGUID: '', groundStopTaskGUID: '', nextGroundStopGUID: '', serviceTypeID: 0,
      aircraftGUID: this.aircraftGUID, customerGUID: this.customerGUID, taskGroundStopGUID: '', showCommsInTrip: this.showCommsInTrip, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        //this.isModified = true;

      }

    });

  }

  tabChanged(source){
    this.selectedServiceTypeList = [];
    this.selectedServiceCountText = ": All";

    // let s = {index:3};
    // s.index
    switch (source.index) {

      case 0:
        this.showClientOnly = false;
        this.showTripOnly = true;
        this.showBothTripAndTask = false;
        this.isButtonEnabled =true;
        this.isButtonDisabled = false;
        this.selectedValue = 0;
        break;
      case 1:
        this.showClientOnly = false;
        this.showTripOnly = false;
        this.showBothTripAndTask = false;
        this.isButtonEnabled =false;
        this.isButtonDisabled =true;
        this.selectedValue = 1;
        break;

      case 2:
        this.showClientOnly = false;
        this.showTripOnly = false;
        this.showBothTripAndTask = true;
        this.isButtonEnabled =true;
        this.isButtonDisabled =false;
        this.selectedValue = 2;
        break;
      case 3:
        this.showClientOnly = true;
        this.showTripOnly = false;
        this.showBothTripAndTask = false;
        this.isButtonEnabled =true;
        this.isButtonDisabled =false;
        this.selectedValue = 3;

  
        break;
      default:
        this.selectedValue = 1;
        this.getMessages();
        return;
    }
    this.paginator.pageIndex = 0;
    this.getMessages();

  }

  clientTab(){
    let s = {index:3};
    this.tabChanged(s);
  }
  
  disableButton() {
    this.isButtonDisabled =false;
    this.isButtonEnabled =true;
  }

  enableButton() {
    this.isButtonDisabled = false ;
    this.isButtonEnabled =true;
  }

  filterInboundOutbound(e){
    this.inOutFiltervalue = e.target.value;
    this.paginator.pageIndex = 0;
    this.getMessages();
  }




  clickDonerecipient() {
    this.showrecipientList = false;
    this.getMessages();
  }
  clickrecipientFilter() {
    this.showrecipientList = !this.showrecipientList;
    this.showTaskStatusFilter = false;
    this.showServiceFilter = false;
    this.showlegFilter = false;
  }


  
  searchMessages(e){
    this.messageList.data = [];

    // this.searchFiltervalue = e.target.value; 
    if (e.target.value.trim() == '' ) { 
      this.searchFrom = null;
      this.searchTo = null;
      this.searchAll = null;
      this.searchAssigned = null;
      this.searchSubject = null;
      this.getMessages();
    }
    else{
      switch (this.searchFiltervalue) {
        case 0:
          this.searchFrom = e.target.value;
          this.searchParam = e.target.value;
          this.getMessages();
          break;
        case 1:
          this.searchTo = e.target.value;
          this.searchParam = e.target.value;
          this.getMessages();
          break;
        case 2:
          this.searchSubject = e.target.value;
          this.searchParam = e.target.value;
          this.getMessages();
          break;
        case 3:
          this.searchAll = e.target.value;
          this.searchParam = e.target.value;
          this.getMessages();
          break;
        case 4:
          this.searchAssigned = e.target.value;
          this.searchParam = e.target.value;
          this.getMessages();
          break;

        default:
          this.searchAll = e.target.value;
          this.searchParam = e.target.value;
          this.getMessages();
          return;
      }
    }

  }

  searchFilterChange(e) {
    if (this.searchParam != null || this.searchParam.length > 0) { 
      switch (e.target.value) {
        case 'from':
          this.searchFiltervalue = 0;
          this.searchFrom = this.searchParam;
          this.searchTo = null;
          this.searchSubject = null;
          this.searchAll = null;
          this.searchAssigned = null;
          this.getMessages();

          break;
        case 'to':
          this.searchFiltervalue = 1;
          this.searchFrom = null;
          this.searchTo = this.searchParam;
          this.searchSubject = null;
          this.searchAll = null;
          this.searchAssigned = null;
          this.getMessages();

          break;
        case 'subject':
          this.searchFiltervalue = 2;
          this.searchFrom = null;
          this.searchTo = null;
          this.searchSubject = this.searchParam;
          this.searchAll = null;
          this.searchAssigned = null;
          this.getMessages();

          break;

        case 'all':
          this.searchFiltervalue = 3;
          this.searchFrom = null;
          this.searchTo = null;
          this.searchSubject = null;
          this.searchAll = this.searchParam;
          this.searchAssigned = null;
          this.getMessages();

          break;
        case 'assigned':
          this.searchFiltervalue = 4;
          this.searchFrom = null;
          this.searchTo = null;
          this.searchSubject = null;
          this.searchAll = null;
          this.searchAssigned = this.searchParam;
          this.getMessages();

          break;
        default:
          this.searchFiltervalue = 3;
          this.searchAll = this.searchParam;
          this.getMessages();

          return;
      }
    }
  }

  resetSearchFilter(){
    this.messageList.data = [];
    this.groundStopTaskGUID = ""; 
    this.clickResetServiceType();
    this.searchFrom = null;
    this.searchSubject = null;
    this.searchTo = null;
    this.searchParam = null;
    this.searchAll = null; 
    this.searchParam = null;
    this.selectedCustomerGUID = '';
    this.searchAssigned = null;
    this.getMessages();
 
    this.searchQuery = ''; 
  }

  clickReset(){
    this.clickResetServiceType();
    this.selectedGroundStopList = null;
    this.groundStopGUID = null;
    this.selectedLegIndex = null;
    this.getMessages();
  }

}




@Component({
  selector: 'success-component',
  template: `<div class="small d-flex align-items-center">
    <i class= "material-icons">check_circle</i>
      <div>{{data}} Selections Saved</div>
</div>`,
  styles: [],
})
export class SuccessComponent { constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { } }

