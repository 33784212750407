import { Component, Inject, OnInit } from '@angular/core';
import { FuelService } from '../services/fuel.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthenticateService } from '../services/authenticate.service';
import { ResponseModel } from '../models/response.model';
import { FuelQuoteSummary } from '../models/fuel-quote-summary';
import { TemplateService } from '../services/template.service';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { TripDocumentModel } from '../models/trip-document.model';

@Component({
  selector: 'app-fuel-quote-summary-history',
  templateUrl: './fuel-quote-summary-history.component.html',
  styleUrls: ['./fuel-quote-summary-history.component.css']
})
export class FuelQuoteSummaryHistoryComponent implements OnInit {

  tripCodeGUID: string;
  fuelQuoteList: FuelQuoteSummary[] = [];
  isLoading: boolean = false;
  isLoadingReport: boolean = false;
  errMsg: string = "";
  displayedColumns: string[] = ['createdBy', 'airports', 'remarks', 'report_id', 'option'];
  remarks: string = "";
  tripCode: string = "";

  constructor(
    private readonly _fuelService: FuelService,
    private readonly _dialogRef: MatDialogRef<FuelQuoteSummaryHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService) {
    this.tripCodeGUID = _data.tripCodeGUID
    this.tripCode = _data.tripCode;
  }

  ngOnInit(): void {
    this._authService.updateAccessTime();
    this.getFuelQuotes();
  }

  getFuelQuotes() {
    this.isLoading = true;
    this._fuelService.getFuelQuoteSummaryBytID<ResponseModel<FuelQuoteSummary[]>>(this.tripCodeGUID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
        this.fuelQuoteList = response.model;
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          else {
            this.errMsg = "An error has occurred retrieving fuel quotes."
          }
        }
      }
      this.isLoading = false;
    });
  }

  clickPreviewDocument(e: any, id: string = "") {
    let request = new TripDocumentModel();
    if (id != "")
      request.tripDocumentGUID = id;
    else
      return;
    if (request.tripDocumentGUID != "") {
      this._groundStopAdvancedService.getTripDocumentBytdId<ResponseModel<TripDocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new TripDocumentModel();
            obj = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
            let file = new Blob([byteArray], { type: obj.mimeType });
            var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display:none");
            a.href = fileURL;
            a.target = "_blank";
            switch (obj.fileExtension) {
              case "doc":
              case "docx":
              case "xls":
              case "xlsx":
                a.download = obj.documentName;
            }
            a.click();
            document.body.removeChild(a);

          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }

  }



}
