<div  style="background-color:#f8f8f8;  height: 100%; padding: .5em;">
  <div style="padding: 0.5em; background-color:#f8f8f8; height: 100%;">


    <div style="display: flex; padding: 0.3em; background-color:white;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div style="margin-top:0.3125em;">
        <h4>    <i class="material-symbols-outlined" style="margin-top: .2em;">content_copy</i>Clipboard Details</h4>
      </div>
    </div>

    <div style="height: 0.5em;   "></div>


    <div *ngIf="messageLinkList.length>0  " style="max-height: 14em;  overflow: auto; padding: 1.5em;background-color:white;" >
      <h5   style="display: inline-flex;">Link Details </h5>
      <!-- <div *ngIf="autoLinkVisible" style="display: inline-flex; margin-right: 0.5em;"><b>Auto Link Details</b></div> -->


      <!-- <ng-template ngFor let-link [ngForOf]="messageLinkList">
 
        <span class="d-flex flex-column"><b>MessageLinkGUID: </b> <span>{{link.messageLinkGUID}}</span> </span> 
        <span class="d-flex flex-column"><b>CustomerGUID: </b> <span style="font-size: small;">{{link.custGUID}}</span></span> 
        <span class="d-flex flex-column"><b>TripCodeGUID: </b> <span>{{link.tripCodeGUID}}</span> </span> 
        <span class="d-flex flex-column"><b>GroundStopGUID: </b> <span>{{link.groundStopGUID}}</span> </span> 
        <span class="d-flex flex-column"><b>GroundStopTaskGUID: </b> <span>{{link.groundStopTaskGUID}}</span> </span> 
        <span class="d-flex flex-column"><b>GroundStopTaskGUID: </b> <span>{{link.groundStopTaskGUID}}</span> </span> 
        
      </ng-template> -->
      <div *ngFor="let link of linkDetailsList">
       <span *ngIf="link.custGUID" class="d-flex flex-column" style=" margin-top: 0.5em;"><h6>CustomerGUID: </h6></span>
         <div *ngIf="link.custGUID" style="display: flex; margin-top: -0.35em;">
          <span style="width: 23em; ">{{link.custGUID}}</span> 
          <button class="btn-low" mat-button (click)="copyToClipboard(link.custGUID)" >Copy</button>
        </div>
      
        <div  *ngFor="let trip of link.tripList">
          <span *ngIf="trip.tripCodeGUID" class="d-flex flex-column" style=" margin-top: 0.5em;"><h6>TripCodeGUID: </h6> </span>
          <div *ngIf="trip.tripCodeGUID" style="display: flex; margin-top: -0.35em;">
            <span style="width: 23em; ">{{trip.tripCodeGUID}}</span>
            <button class="btn-low" mat-button (click)="copyToClipboard(trip.tripCodeGUID)" >Copy</button>
          </div>
          <div *ngFor="let gs of trip.gsList">
            <span *ngIf="gs.gsGUID" class="d-flex flex-column" style=" margin-top: 0.5em;"><h6>GroundStopGUID: </h6> </span>
            <div *ngIf="gs.gsGUID" style="display: flex; margin-top: -0.35em;">
              <span style="width: 23em; ">{{gs.gsGUID}}</span>
              <button class="btn-low" mat-button (click)="copyToClipboard(gs.gsGUID)" >Copy</button>
            </div>
            <div *ngFor="let gst of gs.gsTaskList">
              <span *ngIf="gst.groundStopTaskGUID" class="d-flex flex-column" style=" margin-top: 0.5em;"><h6>GroundStopTaskGUID: </h6> </span>
              <div *ngIf="gst.groundStopTaskGUID"  style="display: flex; margin-top: -0.35em;">
                <span style="width: 23em; ">{{gst.groundStopTaskGUID}}</span>
                <button class="btn-low" mat-button (click)="copyToClipboard(gst.groundStopTaskGUID)" >Copy</button>
              </div>
              <span *ngIf="gst.messageLinkGUID"  class="d-flex flex-column" style=" margin-top: 0.5em;"><h6>MessageLinkGUID: </h6> </span>
              <div *ngIf="gst.messageLinkGUID"  style="display: flex; margin-top: -0.35em;">
                <span style="width: 23em; ">{{gst.messageLinkGUID}}</span>
                <button class="btn-low" mat-button (click)="copyToClipboard(gst.messageLinkGUID)" >Copy</button>
              </div>
        
            </div>
          </div>
        </div>
      </div>
    </div>


    <div style="display: flex;  ">
      <div
        style="justify-content:flex-start;padding-left: 1em; padding-top:0.75em; padding-bottom:0.5em; margin-right:1em; height:3em">


         <button class="btn-low" mat-button (click)="clickClose( )" style="background-color:grey; color: white; margin-left: 0em;">X Close</button>


        <!-- <button type="button" mat-button class="btn-low"  >
        <div style="display:flex"><div>Legs</div></div>
      </button>&nbsp; -->

      </div>
      <!-- <div *ngIf="errMsg!=''" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;"><label style="color:black; font-size: small">{{errMsg}}</label></div> -->
      <!-- <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
        <app-success-message [successMsg]=""></app-success-message>
      </div> -->
    </div>
    <div *ngIf="showSuccessCopy">
      <app-success-message [successMsg]="successCopy"></app-success-message>
    </div>
  </div>
</div>
