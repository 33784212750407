<div *ngIf="upAirportCountry" style="width: 75em;margin-bottom:-1em "
     [cdkDragDisabled]="isOpenedNew" cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle  [ngStyle]="{'padding': isOpenedNew ? '0.5em' : '0em'}">
  <div class="d-flex justify-content-between">
    <div class="d-inline-flex"  [ngStyle]="{'margin-top': isOpenedNew ? '0.5em' : '0em'}">
      <div class="material-icons-outlined mr-1">airplane_ticket</div>
      <h5>AIRPORT DETAILS</h5>
      <div class="ml-3 mr-2" style="font-weight:400">
        {{icao}} {{iata}} {{airportName}} {{airportLocationWithCountryNameAbbr}}
      </div>
      <div *ngIf="flagFile" style="width:3em;margin-bottom:.5em">
       <img style="border: 1px solid lightgray" [src]="getFlag(flagFile)" />
      </div>
    </div>
    <div *ngIf="!isOpenedNew">
      <!--<span class="material-icons-outlined pointer" (click)="openClientProfileSetupComponent()" style="margin-top:.5em;margin-right:.5em">arrow_circle_left</span>-->
      <mat-icon (click)="openInNew()" style="margin-right:.5em;cursor:pointer">open_in_new</mat-icon>
      <mat-icon mat-dialog-close >close</mat-icon>
    </div>
  </div>
  <div>
    <form *ngIf="airport_details_form" [formGroup]="airport_details_form">
      <div class="p-2" style="background-color:white">
        <div class="d-inline-flex">
          <div>
            <div>
              <div class="d-inline-flex">
                <div class="form-group mr-2">
                  <label style="font-size:small">ICAO</label>
                  <input (mousedown)="$event.stopPropagation()" type="text" formControlName="icao" class="form-control inputBox" style="width:4em;margin-top:-.35em" [ngClass]="{ 'is-invalid': submitted && f.icao.errors }" />
                  <div *ngIf="f.icao.errors && submitted" class="alert-danger p-0" style="font-size:small">
                    <div *ngIf="f.icao.errors.minlength || f.icao.errors.maxlength">Must be 4 characters</div>
                    <div *ngIf="f.icao.errors.required">Required.</div>
                  </div>
                </div>
                <div class="form-group mr-2">
                  <label style="font-size:small">IATA</label>
                  <input (mousedown)="$event.stopPropagation()" type="text" formControlName="iata" class="form-control inputBox" style="width: 4em; margin-top: -.35em" [ngClass]="{ 'is-invalid': submitted && f.iata.errors }" />
                  <div *ngIf="f.iata.errors && submitted" class="alert-danger p-0" style="font-size:small">
                    <div *ngIf="f.iata.errors.minlength || f.iata.errors.maxlength">Must be 3 characters</div>
                    <div *ngIf="f.iata.errors.required">Required.</div>
                  </div>
                </div>
                <div class="form-group mr-2">
                  <label style="font-size:small">FAA</label>
                  <input (mousedown)="$event.stopPropagation()" type="text" formControlName="faaid" class="form-control inputBox" style="width: 4em; margin-top: -.35em" [ngClass]="{ 'inputBoxReadOnly': countryID && countryID != 161 && countryID != 167}" />
                </div>
                <div class="form-group mr-2">
                  <label style="font-size:small">Airport Name</label>
                  <input (mousedown)="$event.stopPropagation()" type="text" formControlName="airport_name" class="form-control inputBox" style="margin-top:-.35em;width:15em" [ngClass]="{ 'is-invalid': submitted && f.airport_name.errors }" />
                  <div *ngIf="f.airport_name.errors && submitted" class="alert-danger p-0" style="font-size:small">
                    <div *ngIf="f.airport_name.errors.required">Required.</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="form-group mr-2">
                <label style="font-size:small">Country</label>
                <select *ngIf="f" formControlName="country_select" style="margin-top:-.35em" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.country_select.errors }" (change)="updateCityAndStateList(f.country_select.value)">
                  <option *ngFor="let country of countryList" [ngValue]="country.countryID">
                    {{country.countryName}}
                  </option>
                </select>
                <div *ngIf="f.country_select.errors && submitted" class="alert-danger p-0" style="font-size:small">
                  <div *ngIf="f.country_select.errors.required">Required.</div>
                </div>
              </div>
            </div>
            <div>
              <div class="d-inline-flex">

                <div class="form-group mr-2">
                  <label style="font-size:small">City</label>
                  <input (mousedown)="$event.stopPropagation()" matInput type="text" [formControl]="cityControl" class="form-control inputBox" style="margin-top:-.35em; width:15em" [matAutocomplete]="auto" />
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayCity">
                    <mat-option *ngFor="let city of filteredCity | async" [value]="city" class="small-option">
                      {{city.cityName}}
                    </mat-option>
                  </mat-autocomplete>
                  <div *ngIf="cityControl.errors && submitted" class="alert-danger p-0" style="font-size:small">
                    <div *ngIf="cityControl.errors.required">Required.</div>
                  </div>
                </div>
                <div>
                  <div class="form-group mr-2">
                    <label style="font-size:small">Airport Of Entry</label>
                    <select *ngIf="f" formControlName="airport_of_entry" style="margin-top:-.35em" class="form-control form-control-sm dropdown">
                      <option value=""></option>
                      <option *ngFor="let aoe of airportOfEntryList" [ngValue]="aoe.airportOfEntryID">
                        {{aoe.airportOfEntryDescription}}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
            <div class="d-inline-flex">
              <div class="form-group mr-2">
                <label style="font-size:small">State/Province</label>
                <input (mousedown)="$event.stopPropagation()" matInput type="text" [formControl]="stateControl" class="form-control inputBox" style="margin-top:-.35em; width:15em" [matAutocomplete]="autoState" />
                <mat-autocomplete autoActiveFirstOption #autoState="matAutocomplete" [displayWith]="displayState">
                  <mat-option *ngFor="let state of filteredState | async" [value]="state" class="small-option">
                    {{state.stateProvinceName}}
                  </mat-option>
                </mat-autocomplete>

              </div>
              <div class="form-group mr-2">
                <label style="font-size:small">Distance From City</label>
                <input (mousedown)="$event.stopPropagation()" type="text" formControlName="distance_from_city" class="form-control inputBox" style="margin-top:-.35em;width:13em" />
              </div>
            </div>
          </div>
          <mat-divider [vertical]="true" class="mr-2 mat-divider-orange" style="margin-top: 5em; height: 10em; "></mat-divider>
          <div>
            <div>
              <div class="d-inline-flex">

                <!--<div class="form-group mr-2" *ngIf="!showDegrees">
            <label style="font-size:small">Latitude</label>
            <input (mousedown)="$event.stopPropagation()" type="text" formControlName="latitude" class="form-control inputBox" style="margin-top:-.35em;width:10em" (change)="updateMap()" />
            <div *ngIf="f.latitude.errors && submitted" class="alert-danger p-0" style="font-size:small">
              <div *ngIf="f.latitude.errors.required">Required.</div>
            </div>
            <label style="font-size:small;height:1.5em">{{latitudeDMS}}</label>
          </div>-->
                <div class="mr-2 form-group">
                  <div style="margin-bottom: -.45em">
                    <label style="font-size:small">Latitude</label>
                  </div>
                  <div style="display:inline-flex">
                    <input (mousedown)="$event.stopPropagation()" type="text" formControlName="latDeg" class="form-control inputBox" style="width:2.5em;margin-right:.25em;" (change)="updateLatLong()" />
                    <input (mousedown)="$event.stopPropagation()" type="text" formControlName="latMin" class="form-control inputBox" style="width:2.5em;margin-right:.25em;" (change)="updateLatLong()" />
                    <input (mousedown)="$event.stopPropagation()" type="text" formControlName="latSec" class="form-control inputBox" style="width:2.5em;margin-right:.25em;" (change)="updateLatLong()" />
                    <select *ngIf="f" class="form-control form-control-sm dropdown" formControlName="latDirection" (mousedown)="$event.stopPropagation()" (change)="updateLatLong()">
                      <option value="N">N</option>
                      <option value="S">S</option>
                    </select>
                  </div>
                  <div *ngIf="(f.latDeg.errors || f.latMin.errors || f.latSec.errors) && submitted" class="alert-danger p-0" style="font-size:small">
                    <div *ngIf="(f.latDeg.errors.required || f.latMin.errors.required || f.latSec.errors.required)">Required.</div>
                  </div>
                  <div style="height:1em">
                    <label style="font-size:small;" (mousedown)="$event.stopPropagation()">{{latitude}}</label>
                  </div>
                </div>
                <div class="mr-2 form-group">
                  <div style="margin-bottom: -.45em">
                    <label style="font-size:small">Longitude</label><button *ngIf="tempLat!=latitude || tempLong !=longitude" mat-button class="btn-med grid" style="padding: 0 .5em 0 .5em !important;margin-left:.5em;margin-top:-.5em" (click)="updateCoordinates()">Update Coords</button>
                  </div>
                  <div style="display:inline-flex">
                    <input (mousedown)="$event.stopPropagation()" type="text" formControlName="longDeg" class="form-control inputBox" style="width:2.5em;margin-right:.25em;" (change)="updateLatLong()" />
                    <input (mousedown)="$event.stopPropagation()" type="text" formControlName="longMin" class="form-control inputBox" style="width:2.5em;margin-right:.25em;" (change)="updateLatLong()" />
                    <input (mousedown)="$event.stopPropagation()" type="text" formControlName="longSec" class="form-control inputBox" style="width:2.5em;margin-right:.25em;" (change)="updateLatLong()" />
                    <select *ngIf="f" class="form-control form-control-sm dropdown" formControlName="longDirection" (mousedown)="$event.stopPropagation()" (change)="updateLatLong()">
                      <option value="E">E</option>
                      <option value="W">W</option>
                    </select>
                  </div>
                  <div *ngIf="(f.longDeg.errors || f.longMin.errors || f.longSec.errors) && submitted" class="alert-danger p-0" style="font-size:small">
                    <div *ngIf="(f.longDeg.errors.required || f.longMin.errors.required || f.longSec.errors.required)">Required.</div>
                  </div>
                  <div style="height:1em">
                    <label style="font-size: small;" (mousedown)="$event.stopPropagation()">{{longitude}}</label>
                  </div>
                </div>
                <!--<div class="form-group mr-2">
            <label style="font-size:small">Longitude</label>
            <input (mousedown)="$event.stopPropagation()" type="text" formControlName="longitude" class="form-control inputBox" style="margin-top: -.35em; width: 10em" (change)="updateMap()" />
            <div *ngIf="f.longitude.errors && submitted" class="alert-danger p-0" style="font-size:small">
              <div *ngIf="f.longitude.errors.required">Required.</div>
            </div>
            <label style="font-size: small; height: 1.5em">{{showDegrees? longitude : longitudeDMS}}</label>
          </div>-->
              </div>
            </div>

            <div>
              <div class="form-group mr-2">
                <label style="font-size:small">Preferred Alternate</label>
                <select *ngIf="f" formControlName="isPreferredAlternate" style="margin-top:-.35em" class="form-control form-control-sm dropdown">
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
              </div>
            </div>
            <div style="margin-top:-.75em">
              <div class="form-group mr-2">
                <label style="font-size:small">Remarks</label>
                <textarea (mousedown)="$event.stopPropagation()" formControlName="remarks" class="form-control inputBox" style="width:20em; height:6.5em; margin-top:-.35em; padding:0em 0em 0em 0em"></textarea>
              </div>
            </div>

          </div>

          <div [ngStyle]="{'width': airportID==0? '30.5em':''}">
            <div (mousedown)="$event.stopPropagation()" style="position: relative;">
              <div [ngStyle]="{'width': airportID==0? '30.5em':'41em'}" style="height:20em;border-radius:.25em" id="{{'airport-map'+airportID}}"></div>
              <div *ngIf="latitude && longitude" style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
                fullscreen
              </div>
              <!--<div *ngIf="showOnMap" id="radius"  style="position:absolute; top:2em;left:.5em;">
          <mat-slider (mousedown)="$event.stopPropagation()" style="margin-top:-.7em" thumbLabel tickInterval="25" step="25" min="25" max="200" color="primary" [(ngModel)]="radius" [ngModelOptions]="{standalone: true}" (change)="updateRadius()"></mat-slider>
        </div>-->

            </div>
          </div>
        </div>
        <div *ngIf="airportID!=0" class="d-inline-flex justify-content-between" style="font-size:small;margin-top:-1.5em;margin-bottom:-.5em;width:100%">
          <div  class="d-inline-flex" >
            <div>Restrictions:&nbsp;{{hasRestrictions? 'Yes':'No'}}</div><mat-divider vertical class="mx-1"></mat-divider>
            <div>Slots:&nbsp;{{isRequiredSlots==null? '-' : isRequiredSlots==1? 'Yes': isRequiredSlots==2? 'See Notes':'No'}}</div><mat-divider vertical class="mx-1"></mat-divider>
            <div>PPR:&nbsp;{{isRequiredPPR==null? '-' : isRequiredPPR==1? 'Yes': isRequiredPPR==2? 'See Notes':'No'}}</div><mat-divider vertical class="mx-1"></mat-divider>
            <div>Landing Permit Private:&nbsp;{{countryRequiresLandingPermitPrivate}}</div><mat-divider vertical class="mx-1"></mat-divider>
            <div>Landing Permit Charter:&nbsp;{{countryRequiresLandingPermitCharter}}</div><mat-divider vertical class="mx-1"></mat-divider>
            <div>Revisons:&nbsp;{{requiresRevision? 'Yes':'No'}}</div><mat-divider vertical class="mx-1"></mat-divider>
            <div>Expired Docs:&nbsp;{{hasExpiredDocuments? 'Yes':'No'}}</div>
          </div>
          <div>
            Current UTC Offset: {{currentUTCConversion}}
          </div>
        </div>
      </div>
    </form>
    <section class="mt-2">
      <mat-button-toggle-group [(ngModel)]="selectedTab" [ngModelOptions]="{standalone: true}">
        <!--<mat-button-toggle *ngFor="let pc of personClassList; let i=index" [value]="pc">{{pc.personClassDescription}} {{pc.personClassCount}}</mat-button-toggle>-->
        <mat-button-toggle checked [value]="'notes'">Airport Notes</mat-button-toggle>
        <mat-button-toggle [value]="'details'">Airport Details</mat-button-toggle>
        <mat-button-toggle [value]="'alternates'">Alternates</mat-button-toggle>
        <mat-button-toggle [value]="'documents'">Documents</mat-button-toggle>
        <mat-button-toggle [value]="'businessRules'">Business Rules</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-slide-toggle *ngIf="selectedTab=='notes' || selectedTab=='documents'" style="transform:scale(.8)" color="primary" [(ngModel)]="showDisabled" [ngModelOptions]="{standalone: true}">Show Disabled</mat-slide-toggle>
      <div *ngIf="selectedTab=='alternates'" class="d-inline-flex">
        <mat-checkbox style="transform:scale(.8);" color="primary" [(ngModel)]="isPreferred" [ngModelOptions]="{standalone: true}">Preferred</mat-checkbox>
        <label style="font-size:small;margin-top:.2em;width:10em">Distance in NM: {{distance}}</label>
        <mat-slider (mousedown)="$event.stopPropagation()" style="margin-top:-.7em" thumbLabel tickInterval="50" step="50" min="50" max="1000" color="primary" [(ngModel)]="distance" [ngModelOptions]="{standalone: true}"></mat-slider>
        <mat-slide-toggle style="transform:scale(.8)" color="primary" [(ngModel)]="showOnMap" [ngModelOptions]="{standalone: true}" (change)="updateMap()">Show On Map</mat-slide-toggle>
      </div> 
    </section>
    <div style="height: 15em; overflow-y: auto; overflow-x: hidden; background-color:white;" [ngStyle]="{'margin-top': selectedTab=='alternates'? '-.65em':'0em'}">
      <div *ngIf="selectedTab=='notes'">
        <app-airport-notes [airportID]="airportID" [isActive]="!showDisabled"></app-airport-notes>
      </div>
      <div *ngIf="selectedTab=='details'" style="padding: .5em; border-top: 0.0625em solid lightgrey; ">
        <form *ngIf="airport_details_form" [formGroup]="airport_details_form">
          <div class="d-inline-flex">
            <div>
              <div class="form-group mr-2">
                <label style="font-size:small">Airport Type</label>
                <select *ngIf="f" formControlName="airportType" style="margin-top:-.35em" class="form-control form-control-sm dropdown">
                  <option value=""></option>
                  <option value="Civil">Civil</option>
                  <option value="Military">Military</option>
                  <option value="Joint Civil / Military">Joint Civil / Military</option>
                </select>
              </div>
              <div class="form-group mr-2">
                <label style="font-size:small">Max Runway Length (ft)</label>
                <input (mousedown)="$event.stopPropagation()" *ngIf="f" type="text" formControlName="runway_length" class="form-control inputBox" style="margin-top:-.35em" />
              </div>

              <div class="form-group mr-2">
                <label style="font-size:small">Elevation (ft)</label>
                <input (mousedown)="$event.stopPropagation()" *ngIf="f" type="text" formControlName="elevation" class="form-control inputBox" style="margin-top:-.35em" />
              </div>
              
            </div>
            <div>
              <div class="form-group mr-2">
                <label style="font-size:small">Approach</label>
                <input (mousedown)="$event.stopPropagation()" *ngIf="f" type="text" formControlName="approach" class="form-control inputBox" style="margin-top:-.35em" />
              </div>

              <div class="form-group mr-2">
                <label style="font-size:small">Max Runway Width (ft)</label>
                <input (mousedown)="$event.stopPropagation()" *ngIf="f" type="text" formControlName="runway_width" class="form-control inputBox" style="margin-top:-.35em" />
              </div>
              <div class="form-group mr-2">
                <label style="font-size:small">Variation</label>
                <input (mousedown)="$event.stopPropagation()" *ngIf="f" type="text" formControlName="variation" class="form-control inputBox" style="margin-top:-.35em" />
              </div>

              <!--<div class="form-group mr-2" *ngIf="f.runway_length.value && f.runway_width.value && f.primary_runway.value">
            <label style="font-size:small">Display</label>
            <div style="margin-top:-.35em">
              <label style="font-size:small">
                {{f.runway_length.value + 'x' + f.runway_width.value + ', ' + f.primary_runway.value}}
              </label>
            </div>
          </div>-->
            </div>
            <div>
              <div>
                <div class="form-group mr-2">


                  <label style="font-size:small">

                    Lighting
                  </label>


                  <input (mousedown)="$event.stopPropagation()" *ngIf="f" type="text" formControlName="lighting" class="form-control inputBox" style="margin-top:-.35em" />
                </div>
                <div class="form-group mr-2">
                  <label style="font-size:small">Primary Runway ID</label>
                  <input (mousedown)="$event.stopPropagation()" *ngIf="f" type="text" formControlName="primary_runway" class="form-control inputBox" style="margin-top:-.35em" />
                </div>
                <div class="form-group mr-2">
                  <label style="font-size:small">Fuel Type</label>
                  <input (mousedown)="$event.stopPropagation()" *ngIf="f" type="text" formControlName="fuel_type" class="form-control inputBox" style="margin-top:-.35em" />
                </div>
              </div>
            </div>
            <div>
              <div class="form-group mr-2">
                <label style="font-size:small">Apis Approved</label>
                <select *ngIf="f" formControlName="isApisApproved" style="margin-top:-.35em" class="form-control form-control-sm dropdown">
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
              </div>
            </div>
            <mat-divider vertical class="mr-2"></mat-divider>
            <div>
              <div>
                <div class="d-inline-flex">
                  <div class="form-group mr-2" style="width:10em">
                    <label style="font-size:small">UTC Conversion</label>
                    <input (mousedown)="$event.stopPropagation()" *ngIf="f" type="text" placeholder="±hh:mm" formControlName="utc_conversion" class="form-control inputBox" style="margin-top:-.35em" (change)="calculateUTC('conversion')" />
                  </div>
                  <div class="form-group mr-2">
                    <label style="font-size:small">UTC Offset Minutes</label>
                    <input (mousedown)="$event.stopPropagation()" *ngIf="f" type="text" formControlName="utc_offset" class="form-control inputBox" style="margin-top:-.35em" (change)="calculateUTC('offset')" />
                  </div>

                </div>
              </div>
              <div>
                <div class="d-inline-flex">
                  <div class="form-group mr-2" style="width:10em">
                    <label style="font-size:small">Has Daylight Saving</label>
                    <select *ngIf="f" formControlName="has_dls" style="margin-top:-.35em" class="form-control form-control-sm dropdown" (change)="showDLS=!showDLS">
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                  </div>
                  <div *ngIf="showDLS">
                    <div class="d-inline-flex">
                      <div class="form-group mr-2">
                        <label style="font-size:small">DLS Start</label>
                        <div style="margin-top:-.35em">
                          <label style="font-size:small">
                            {{dlsStart}}
                          </label>
                        </div>
                      </div>
                      <div class="form-group mr-2">
                        <label style="font-size:small">DLS End</label>
                        <div style="margin-top:-.35em">
                          <label style="font-size:small">
                            {{dlsEnd}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
      <div *ngIf="selectedTab=='alternates'">
        <app-nearby-airports [airportID]="airportID" [isPreferred]="isPreferred" [distance]="distance" (updateParentPage)="onUpdateParentPage($event)"></app-nearby-airports>
      </div>
      <div *ngIf="selectedTab=='documents'">
        <app-airport-documents [airportID]="airportID" [isActive]="!showDisabled"></app-airport-documents>
      </div>
      <div *ngIf="selectedTab=='businessRules'">
        <app-airport-business-rules [icao]="icao" [countryGUID]="countryGUID" [customerGuid]=""></app-airport-business-rules>
      </div>
      
    </div>
    <mat-divider></mat-divider>
    <div style="width:100%" class="d-inline-flex justify-content-between">
      <div class="d-inline-flex mt-3">
        <div>
          <button mat-button class="btn btn-high mr-2" (click)="clickSave()">Save</button>
        </div>
        <div *ngIf="!isOpenedNew">
          <button mat-button class="btn btn-low mr-2" mat-dialog-close>Close</button>
        </div>
        <app-success-message style="margin-top:-.45em" *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>
      </div>
      <div  *ngIf="airportID!=0" class="d-inline-flex">
        <form *ngIf="airport_details_form" [formGroup]="airport_details_form">
          <div class="form-group form-inline mt-3">
            <label style="font-size: small; margin-right: .5em">Disable Record </label>
            <select *ngIf="f" formControlName="isActive" style="margin-right:1.5em" class="form-control form-control-sm dropdown">
              <option value="false">Yes</option>
              <option value="true">No</option>
            </select>
          </div>
        </form>
          <div class="mt-2">
            <div *ngIf="modifiedBy!=''" style="font-size:small">
              <label style="width:7em;text-align:right;margin-right:.5em">Modified By: </label>{{modifiedBy}}
            </div>
            <div *ngIf="modifiedDate!=''" style="font-size:small;margin-top:-.75em">
              <label style="width: 7em; text-align: right; margin-right: .5em ">Modified Date: </label>{{modifiedDate}}
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
