import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationExtras, Route, Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { CustomerHomebaseEditDialogComponent } from '../customers/customer-homebase-edit-dialog.component';
import { AircraftTypeSpeedModel } from '../models/aircraft-type-speed.model';
import { AircraftModel } from '../models/aircraft.model';
import { AirportModel } from '../models/airport.model';
import { CountryModel } from '../models/country.model';
import { CustomerHomebaseAirportModel } from '../models/customer-homebase-airport.model';
import { FlightPlanProviderModel } from '../models/flight-plan-provider.model';
import { ReservePolicyModel } from '../models/reserve-policy.model';
import { ResponseModel } from '../models/response.model';
import { AircraftService } from '../services/aircraft.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';

export interface BiasData {
  value: number;
}

const BIAS_DATA: BiasData[] = [
  { value: -10 },
  { value: -9 },
  { value: -8 },
  { value: -7 },
  { value: -6 },
  { value: -5 },
  { value: -4 },
  { value: -3 },
  { value: -2 },
  { value: -1 },
  { value: 0 },
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
  { value: 15 },
  { value: 16 },
  { value: 17 },
  { value: 18 },
  { value: 19 },
  { value: 20 },
  { value: 21 },
  { value: 22 },
  { value: 23 },
  { value: 24 },
  { value: 25 },
];

@Component({
  selector: 'app-aircraft-profile-details',
  templateUrl: './aircraft-profile-details.component.html',
  styleUrls: ['./aircraft-profile-details.component.css'],
})


/** aricraft-profile-details component*/
export class AircraftProfileDetailsComponent implements OnInit {
  aircraft_profile_details_form: UntypedFormGroup;
  aircraftGuid: string;
  errMsg: string;
  showSpin: boolean;
  loading: boolean;
  submitted: boolean;
  msg: string;
  userType: string;
  engineType: string;
  mtow: number;
  mrw: number;
  mlw: number;
  mzfw: number;
  bow: number;
  crewInBOW: number;
  maxFuel: number;
  speed: number;
  height: number;
  wingspan: number;
  paxSeats: number;
  jumpSeats: number;
  otherSeats: number;
  etp_tas: number;
  etpFlightLevel: number;
  etpOxyDurationMin: number;
  etpOxyInitFlightLevel: number;
  etops_tas: number;
  etopsMinutes: number;
  etopsCruise: number;
  isModified: boolean;
  customerGuid: string;
  textSearch: string;
  filterBy: string;
  selectedAirportID: number;
  flightPlanProviderId: number;
  remarks: string;
  climb100: number;
  climb250: number;
  climbTop: number;
  climbBias: number;
  climbFuel: number;
  climbMins: number;
  primaryCruise: string;
  cruiseBias: number;
  altCruise1: string;
  altCruise2: string;
  altCruise3: string;
  altCruise4: string;
  altCruise5: string;
  descentTop: number;
  descent250: number;
  descent100: number;
  descentBias: number;
  descentFuel: number;
  descentMins: number;
  maxFL: number;
  minDispatchFuel: number;
  minAltFuel: number;
  minLandingFuel: number;
  taxiFuel: number;
  taxiFuelBurnType: string;
  domReserve: string;
  intlReserve: string;
  fileByAircraft: number;
  filingCompanyName: string;
  isImperial: number;
  flagDiv: boolean = false;
  reserveList: ReservePolicyModel[];
  fpList: FlightPlanProviderModel[];
  airportList: CustomerHomebaseAirportModel[]; 
  countryList: CountryModel[];
  climb100List: AircraftTypeSpeedModel[];
  climb250List: AircraftTypeSpeedModel[];
  climbTopList: AircraftTypeSpeedModel[];
  descentTopList: AircraftTypeSpeedModel[];
  descent250List: AircraftTypeSpeedModel[];
  descent100List: AircraftTypeSpeedModel[];
  homebaseAirport: string;
  uplinkFrequency: string="";
  uplinkVendor: string;
  registration: string;
  countryOfRegistration: string;
  reserveFuel: string;
  holdFuel: string;
  hexCode: string;
  length: number;
  maxRange: number;
  maxMTOW: number;
  maxFuelLimit: number;
  maxPreferredFuel: number = 2000;
  units: string = "(lbs)";
  speedList: AircraftTypeSpeedModel[];
  hasChanges: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  biasDataSource = BIAS_DATA;
  showSuccessMsg: boolean = false;
  isDisabled: boolean = false;
  isPopup: boolean = false;
  constructor(private readonly _authService: AuthenticateService, private readonly _commonService: CommonService, private readonly _aircraftService: AircraftService, private readonly _dialogService: DialogService,
    private readonly _formBuilder: UntypedFormBuilder, private _route: ActivatedRoute, private _customerService: CustomerService, private readonly _dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private _data: any,
    @Optional() private readonly _dialogRef: MatDialogRef<AircraftProfileDetailsComponent>,) {

  }


  ngOnInit() {
    if(this._data) {
      this.isPopup = true;
    }
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    if(this.isPopup) {
      this.aircraftGuid = this._data.aircraftGUID;
      this.customerGuid = this._data.customerGUID;
      this.textSearch = this._data.textSearch;
      this.filterBy = this._data.filterBy;
    }
    else {
      this._route.queryParams.subscribe(params => {
        this.aircraftGuid = params["aircraftGuid"];
        this.customerGuid = params["customerGuid"];
        this.textSearch = params["textSearch"];
        this.filterBy = params["filterBy"];
      });
    }
    
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.isDisabled = true;
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    
    this.getData();
 
    

  }

  getAllData(): Observable<any[]> {
    let getReservePolicyListResponse = this._commonService.getReservePolicyList<ResponseModel<ReservePolicyModel[]>>();
    let getFPProviderResponse = this._commonService.getFlightPlanProviderList<ResponseModel<FlightPlanProviderModel[]>>();
    let countryResponse = this._commonService.getCountryList<ResponseModel<CountryModel[]>>();
    let aircraftResponse = this._aircraftService.getAircraftProfileByaId<ResponseModel<AircraftModel>>(this.aircraftGuid);
    return forkJoin([getReservePolicyListResponse, getFPProviderResponse, countryResponse, aircraftResponse]);
  }

  getData() {
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.reserveList = responses[0].model;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.fpList = responses[1].model;

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.countryList = responses[2].model;

        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          let aircraft = responses[3].model;          
          this.engineType = aircraft.engineType;
          this.mtow = aircraft.mtow;
          this.mrw = aircraft.mrw;
          this.mlw = aircraft.mlw;
          this.mzfw = aircraft.mzfw;
          this.bow = aircraft.bow;
          this.crewInBOW = aircraft.crewInBOW;
          this.maxFuel = aircraft.maxFuel;
          this.speed = aircraft.speed;
          this.height = aircraft.height;
          this.wingspan = aircraft.wingspan;
          this.paxSeats = aircraft.paxSeats;
          this.jumpSeats = aircraft.jumpSeats;
          this.otherSeats = aircraft.otherSeats;
          this.etp_tas = aircraft.etP_TAS;
          this.etpFlightLevel = aircraft.etpFlightLevel;
          this.etpOxyDurationMin = aircraft.etpOxyDurationMin;
          this.etpOxyInitFlightLevel = aircraft.etpOxyInitFlightLevel;
          this.etops_tas = aircraft.etopS_TAS;
          this.etopsMinutes = aircraft.etopsMinutes;
          this.etopsCruise = aircraft.etopsCruise;
          this.flightPlanProviderId= aircraft.flightPlanProviderID;
          this.remarks = aircraft.remarks;
          this.climb100 = aircraft.climb100;
          this.climb250 = aircraft.climb250;
          this.climbTop = aircraft.climbTop;
          this.climbBias = aircraft.climbBias;
          this.climbFuel = aircraft.climbFuel;
          this.climbMins = aircraft.climbMins;
          this.primaryCruise = aircraft.primaryCruise;
          this.cruiseBias = aircraft.cruiseBias;
          this.altCruise1 = aircraft.altCruise1;
          this.altCruise2 = aircraft.altCruise2;
          this.altCruise3 = aircraft.altCruise3;
          this.altCruise4 = aircraft.altCruise4;
          this.altCruise5 = aircraft.altCruise5;
          this.descentTop = aircraft.descentTop;
          this.descent250 = aircraft.descent250;
          this.descent100 = aircraft.descent100;
          this.descentBias = aircraft.descentBias;
          this.descentFuel = aircraft.descentFuel;
          this.descentMins = aircraft.descentMins;
          this.maxFL = aircraft.maxFL;
          this.minDispatchFuel = aircraft.minDispatchFuel;
          this.minAltFuel = aircraft.minAlternateFuel;
          this.minLandingFuel = aircraft.minLandingFuel;
          this.taxiFuel = aircraft.taxiFuel;
          this.taxiFuelBurnType = aircraft.taxiFuelBurnType.toString();
          this.domReserve = aircraft.domesticReserve;
          this.intlReserve = aircraft.internationalReserve;
          if (aircraft.fileByAircraftRegistration == true)
            this.fileByAircraft = 1;
          else
            this.fileByAircraft = 0;
          if (aircraft.isImperial == true) {
            this.isImperial = 1;
            this.units = "(lbs)";
          }
          else {
            this.isImperial = 0;
            this.units = "(kgs)";
            this.maxPreferredFuel = Math.round(this.maxPreferredFuel * 2.2046);
          }
          this.filingCompanyName = aircraft.filingCompanyName;
          this.homebaseAirport = aircraft.homebaseAirportID;
          this.customerGuid = aircraft.customerGuid;
          this.registration = aircraft.registration;
          this.uplinkVendor = aircraft.uplinkVendor;          
          if (aircraft.uplinkFrequency!=null)
            this.uplinkFrequency = aircraft.uplinkFrequency.toString();
          this.countryOfRegistration = aircraft.countryOfRegistration;
          this.reserveFuel = aircraft.reserveFuel;
          this.holdFuel = aircraft.holdFuel;
          this.hexCode = aircraft.hexCode;
          this.length = aircraft.length;
          this.maxRange = aircraft.maxRange;
          this.maxMTOW = aircraft.maxMTOW;
          this.maxFuelLimit = aircraft.maxFuelLimit;
          let request = new CustomerHomebaseAirportModel();
          request.customerGUID = aircraft.customerGuid;
          this._customerService.getCustomerHomebaseAirportList<ResponseModel<CustomerHomebaseAirportModel[]>>(request).subscribe(response => {
            if (response != null) {
              if (response.code == "200" && response.message == "") {
                this.airportList = response.model;

              }
              else {
                if (response.code == "401") {
                  this._authService.signOut();
                }
              }
            }
          });

          this.getAircraftTypeSpeedList(aircraft.aircraftTypeId).subscribe(responses => {
            if (responses[0] != null) {
              if (responses[0].code == "200" && responses[0].message == "") {
                this.climb100List = responses[0].model;

              }
              else {
                if (responses[0].code == "401") {
                  this._authService.signOut();
                }
              }
            }

            if (responses[1] != null) {
              if (responses[1].code == "200" && responses[1].message == "") {
                this.climb250List = responses[1].model;

              }
              else {
                if (responses[1].code == "401") {
                  this._authService.signOut();
                }
              }
            }

            if (responses[2] != null) {
              if (responses[2].code == "200" && responses[2].message == "") {
                this.climbTopList = responses[2].model;

              }
              else {
                if (responses[2].code == "401") {
                  this._authService.signOut();
                }
              }
            }

            
            if (responses[3] != null) {
              if (responses[3].code == "200" && responses[3].message == "") {
                this.descentTopList = responses[3].model;

              }
              else {
                if (responses[3].code == "401") {
                  this._authService.signOut();
                }
              }
            }

            if (responses[4] != null) {
              if (responses[4].code == "200" && responses[4].message == "") {
                this.descent250List = responses[4].model;

              }
              else {
                if (responses[4].code == "401") {
                  this._authService.signOut();
                }
              }
            }

            if (responses[5] != null) {
              if (responses[5].code == "200" && responses[5].message == "") {
                this.descent100List = responses[5].model;

              }
              else {
                if (responses[5].code == "401") {
                  this._authService.signOut();
                }
              }
            }


          });         

          this.initControls();
          this.setValidation();
          this.checkChanges();
        }
      }

      else {
        if (responses[3].code == "401") {
          this._authService.signOut();
        }
      }

    
      
    });





  }
      
  getAircraftTypeSpeedList(aircraftTypeId: number): Observable<any[]> {
   
    let request = new AircraftTypeSpeedModel();
    request.aircraftTypeId = aircraftTypeId;
    request.aircraftSpeedProfileTypeId = 1;
    let speedResponse100 = this._aircraftService.getAircraftTypeSpeed<ResponseModel<AircraftTypeSpeedModel[]>>(request);
    let request1 = new AircraftTypeSpeedModel();
    request1.aircraftTypeId = aircraftTypeId;
    request1.aircraftSpeedProfileTypeId = 2;
    let speedResponse250 = this._aircraftService.getAircraftTypeSpeed<ResponseModel<AircraftTypeSpeedModel[]>>(request1);
    let request2 = new AircraftTypeSpeedModel();
    request2.aircraftTypeId = aircraftTypeId;
    request2.aircraftSpeedProfileTypeId = 3;
    let speedResponseTop = this._aircraftService.getAircraftTypeSpeed<ResponseModel<AircraftTypeSpeedModel[]>>(request2);
    let request3 = new AircraftTypeSpeedModel();
    request3.aircraftTypeId = aircraftTypeId;
    request3.aircraftSpeedProfileTypeId = 4;
    let speedResponseDTop = this._aircraftService.getAircraftTypeSpeed<ResponseModel<AircraftTypeSpeedModel[]>>(request3);
    let request4 = new AircraftTypeSpeedModel();
    request4.aircraftTypeId = aircraftTypeId;
    request4.aircraftSpeedProfileTypeId = 5;
    let speedResponseD250 = this._aircraftService.getAircraftTypeSpeed<ResponseModel<AircraftTypeSpeedModel[]>>(request4);
    let request5 = new AircraftTypeSpeedModel();
    request5.aircraftTypeId = aircraftTypeId;
    request5.aircraftSpeedProfileTypeId = 6;
    let speedResponseD100 = this._aircraftService.getAircraftTypeSpeed<ResponseModel<AircraftTypeSpeedModel[]>>(request5);

    return forkJoin([speedResponse100, speedResponse250, speedResponseTop, speedResponseDTop,speedResponseD250,speedResponseD100]);
   
  }


  initControls() {
    this.aircraft_profile_details_form = this._formBuilder.group({

      engine_type: [{ value: this.engineType, disabled: this.isDisabled }/*, Validators.required*/],
      climb_fl_100: { value: this.climb100, disabled: this.isDisabled },
      climb_fl_100_250: {
        value: this.climb250, disabled: this.isDisabled
      },
      climb_top: {
        value: this.climbTop, disabled: this.isDisabled
      },
      climb_bias: [{ value: this.climbBias, disabled: this.isDisabled }],
      climb_fuel: [{ value: this.climbFuel, disabled: this.isDisabled }, Validators.max(2000)],
      climb_mins: [{ value: this.climbMins, disabled: this.isDisabled }, Validators.max(20)],
      cruise: [{ value: this.primaryCruise, disabled: this.isDisabled }/*, Validators.required*/],
      p_cruise_bias: [{ value: this.cruiseBias, disabled: this.isDisabled }],
      alt_cruise1: {
        value: this.altCruise1, disabled: this.isDisabled
      },
      alt_cruise2: {
        value: this.altCruise2, disabled: this.isDisabled
      },
      alt_cruise3: {
        value: this.altCruise3, disabled: this.isDisabled
      },
      alt_cruise4: {
        value: this.altCruise4, disabled: this.isDisabled
      },
      alt_cruise5: {
        value: this.altCruise5, disabled: this.isDisabled
      },
      descent_top: {
        value: this.descentTop, disabled: this.isDisabled
      },
      descent_fl_100_250: {
        value: this.descent250, disabled: this.isDisabled
      },
      descent_fl_100: {
        value: this.descent100, disabled: this.isDisabled
      },
      descent_bias: [{ value: this.descentBias, disabled: this.isDisabled }],
      descent_fuel: [{ value: this.descentFuel, disabled: this.isDisabled }, Validators.max(2000)],
      descent_mins: [{ value: this.descentMins, disabled: this.isDisabled }, Validators.max(20)],
      max_fl: {
        value: this.maxFL, disabled: this.isDisabled
      },
      pax_seats: {
        value: this.paxSeats, disabled: this.isDisabled
      },
      jump_seats: {
        value: this.jumpSeats, disabled: this.isDisabled
      },
      other_seats: {
        value: this.otherSeats, disabled: this.isDisabled
      },
      homebase_airport: {
        value: this.homebaseAirport, disabled: this.isDisabled
      },
      uom: {
        value: this.isImperial, disabled: this.isDisabled
      },
      mtow: [{ value: this.mtow, disabled: this.isDisabled }/*, Validators.required*/],
      mrw: [{ value: this.mrw, disabled: this.isDisabled }/*, Validators.required*/],
      mlw: [{ value: this.mlw, disabled: this.isDisabled }/*, Validators.required*/],
      mzfw: [{ value: this.mzfw, disabled: this.isDisabled }/*, Validators.required*/],
      bow: [{ value: this.bow, disabled: this.isDisabled }/*, Validators.required*/],
      crew_in_bow: [{ value: this.crewInBOW, disabled: this.isDisabled }/*, Validators.required*/],
      max_fuel: [{ value: this.maxFuel, disabled: this.isDisabled }/*, Validators.required*/],
      min_dispatch_fuel: {
        value: this.minDispatchFuel, disabled: this.isDisabled
      },
      min_alt_fuel: {
        value: this.minDispatchFuel, disabled: this.isDisabled
      },
      min_landing_fuel: [{ value: this.minLandingFuel, disabled: this.isDisabled }/*, Validators.required*/],
      taxi_fuel: [{ value: this.taxiFuel, disabled: this.isDisabled }/*, Validators.required*/],
      taxi_fuel_burn_type: [{ value: this.taxiFuelBurnType, disabled: this.isDisabled }/*, Validators.required*/],
      dom_res: {
        value: this.domReserve, disabled: this.isDisabled
      },
      intl_res: {
        value: this.intlReserve, disabled: this.isDisabled
      },
      etp_tas: {
        value: this.etp_tas, disabled: this.isDisabled
      },
      etp_flight_level: {
        value: this.etpFlightLevel, disabled: this.isDisabled
      },
      oxy_min: {
        value: this.etpOxyDurationMin, disabled: this.isDisabled
      },
      oxy_flight_level: {
        value: this.etpOxyInitFlightLevel, disabled: this.isDisabled
      },
      etop_min: {
        value: this.etopsMinutes, disabled: this.isDisabled
      },
      etop_tas: {
        value: this.etops_tas, disabled: this.isDisabled
      },
      etop_cruise: {
        value: this.etopsCruise, disabled: this.isDisabled
      },
      remarks: {
        value: this.remarks, disabled: this.isDisabled
      },
      country_select: {
        value: this.countryOfRegistration, disabled: this.isDisabled
      },

    });

  }

  checkChanges() {

    const mtow = <UntypedFormControl>this.aircraft_profile_details_form.get('mtow');
    const mrw = <UntypedFormControl>this.aircraft_profile_details_form.get('mrw');

    mtow.valueChanges.subscribe(value => {
      mtow.setValidators([Validators.max(mrw.value)/*, Validators.required*/])
      
      mtow.updateValueAndValidity();
    });

    mrw.valueChanges.subscribe(value => {
      mtow.setValidators([Validators.max(mrw.value)/*, Validators.required*/])

      mtow.updateValueAndValidity();
    });


    this.aircraft_profile_details_form.valueChanges.subscribe(value => {
      this.hasChanges = true;
    });
  }


  get f() { return this.aircraft_profile_details_form.controls; }

  setValidation() {
    this.f.mtow.clearValidators();
    this.f.max_fuel.clearValidators();
    this.f.climb_fuel.clearValidators();
    this.f.descent_fuel.clearValidators();
    this.f.mtow.setValidators([/*Validators.required,*/ Validators.max(this.maxMTOW*1.1)]);
    this.f.max_fuel.setValidators([/*Validators.required,*/ Validators.max(this.maxFuelLimit*1.1)]);
    this.f.climb_fuel.setValidators(Validators.max(this.maxPreferredFuel));
    this.f.descent_fuel.setValidators(Validators.max(this.maxPreferredFuel));
    this.f.mtow.updateValueAndValidity();
    this.f.max_fuel.updateValueAndValidity();
    this.f.descent_fuel.updateValueAndValidity();
    
  }

  changeUOM(event: any) {
    this.isImperial = event.value;
    if (event.value == 0) {
      this.f.mtow.setValue(Math.round(this.f.mtow.value / 2.2046));
      this.f.mrw.setValue(Math.round(this.f.mrw.value / 2.2046));
      this.f.mlw.setValue(Math.round(this.f.mlw.value / 2.2046));
      this.f.mzfw.setValue(Math.round(this.f.mzfw.value / 2.2046));
      this.f.bow.setValue(Math.round(this.f.bow.value / 2.2046));
      this.f.max_fuel.setValue(Math.round(this.f.max_fuel.value / 2.2046));
      this.f.min_dispatch_fuel.setValue(Math.round(this.f.min_dispatch_fuel.value / 2.2046));
      this.f.min_alt_fuel.setValue(Math.round(this.f.min_alt_fuel.value / 2.2046));
      this.f.min_landing_fuel.setValue(Math.round(this.f.min_landing_fuel.value / 2.2046));
      this.f.taxi_fuel.setValue(Math.round(this.f.taxi_fuel.value / 2.2046));
      this.f.climb_fuel.setValue(Math.round(this.f.climb_fuel.value / 2.2046));
      this.f.descent_fuel.setValue(Math.round(this.f.descent_fuel.value / 2.2046));
      this.maxRange = Math.round(this.maxRange / 2.2046);
      this.maxMTOW = Math.round(this.maxMTOW / 2.2046);
      this.maxFuelLimit = Math.round(this.maxFuelLimit / 2.2046);
      this.maxPreferredFuel = Math.round(this.maxPreferredFuel / 2.2046);
      this.units = "(kgs)";
    }
    else {
      this.f.mtow.setValue(Math.round(this.f.mtow.value * 2.2046));
      this.f.mrw.setValue(Math.round(this.f.mrw.value * 2.2046));
      this.f.mlw.setValue(Math.round(this.f.mlw.value * 2.2046));
      this.f.mzfw.setValue(Math.round(this.f.mzfw.value * 2.2046));
      this.f.bow.setValue(Math.round(this.f.bow.value * 2.2046));
      this.f.max_fuel.setValue(Math.round(this.f.max_fuel.value * 2.2046));
      this.f.min_dispatch_fuel.setValue(Math.round(this.f.min_dispatch_fuel.value * 2.2046));
      this.f.min_alt_fuel.setValue(Math.round(this.f.min_alt_fuel.value * 2.2046));
      this.f.min_landing_fuel.setValue(Math.round(this.f.min_landing_fuel.value * 2.2046));
      this.f.taxi_fuel.setValue(Math.round(this.f.taxi_fuel.value * 2.2046));
      this.f.climb_fuel.setValue(Math.round(this.f.climb_fuel.value * 2.2046));
      this.f.descent_fuel.setValue(Math.round(this.f.descent_fuel.value * 2.2046));
      this.maxRange = Math.round(this.maxRange * 2.2046);
      this.maxMTOW = Math.round(this.maxMTOW * 2.2046);
      this.maxFuelLimit = Math.round(this.maxFuelLimit * 2.2046);
      this.maxPreferredFuel = Math.round(this.maxPreferredFuel * 2.2046);
      this.units = "(lbs)";
      
    }

    this.setValidation();

  }

  addHomebase() {
    let s = "Add New Record";
    let customerHomebaseAirportID = 0;    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, customerGUID: this.customerGuid, customerHomebaseAirportID: customerHomebaseAirportID };
    const dialogRef = this._dialog.open(CustomerHomebaseEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let request = new CustomerHomebaseAirportModel();
        request.customerGUID = this.customerGuid;
        this._customerService.getCustomerHomebaseAirportList<ResponseModel<CustomerHomebaseAirportModel[]>>(request).subscribe(response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              this.airportList = response.model;

            }
            else {
              if (response.code == "401") {
                this._authService.signOut();
              }
            }
          }
        });

      }

    });


  }
  clickSave() {
    
    this.loading = true;
    this.submitted = true;   
    if (this.errMsg != "")
      return;
    this.errMsg = "";
    if (this.aircraft_profile_details_form.invalid) {
      return;    
    }
    this.hasChanges = false;
    let obj = new AircraftModel();
    obj.aircraftGuid = "";
    obj.customerList = "";
    obj.pageName = "Details";
    if (this.aircraftGuid != "" && this.aircraftGuid != null)
      obj.aircraftGuid = this.aircraftGuid;
    obj.engineType = this.f.engine_type.value;
    obj.mtow = this.f.mtow.value;
    obj.mrw = this.f.mrw.value;
    obj.mlw = this.f.mlw.value;
    obj.mzfw = this.f.mzfw.value;
    obj.bow = this.f.bow.value;
    obj.crewInBOW = this.f.crew_in_bow.value;
    obj.maxFuel = this.f.max_fuel.value;
    obj.paxSeats = this.f.pax_seats.value;
    obj.jumpSeats = this.f.jump_seats.value;
    obj.otherSeats = this.f.other_seats.value;
    obj.etP_TAS = this.f.etp_tas.value;
    obj.etpFlightLevel = this.f.etp_flight_level.value;
    obj.etpOxyDurationMin = this.f.oxy_min.value;
    obj.etpOxyInitFlightLevel = this.f.oxy_flight_level.value;
    obj.etopS_TAS = this.f.etop_tas.value;
    obj.etopsMinutes = this.f.etop_min.value;
    obj.etopsCruise = this.f.etop_cruise.value;
    obj.climb100 = this.f.climb_fl_100.value;
    obj.climb250 = this.f.climb_fl_100_250.value;
    obj.climbTop = this.f.climb_top.value;
    obj.climbBias = this.f.climb_bias.value;
    obj.climbFuel = this.f.climb_fuel.value;
    obj.climbMins = this.f.climb_mins.value;
    obj.primaryCruise = this.f.cruise.value;
    obj.cruiseBias = this.f.p_cruise_bias.value;
    obj.altCruise1 = this.f.alt_cruise1.value;
    obj.altCruise2 = this.f.alt_cruise2.value;
    obj.altCruise3 = this.f.alt_cruise3.value;
    obj.altCruise4 = this.f.alt_cruise4.value;
    obj.altCruise5 = this.f.alt_cruise5.value;
    obj.descentTop = this.f.descent_top.value;
    obj.descent250 = this.f.descent_fl_100_250.value;
    obj.descent100 = this.f.descent_fl_100.value;
    obj.descentBias = this.f.descent_bias.value;
    obj.descentFuel = this.f.descent_fuel.value;
    obj.descentMins = this.f.descent_mins.value;
    obj.primaryCruise = this.f.cruise.value;
    obj.maxFL = this.f.max_fl.value;
    obj.minDispatchFuel = this.f.min_dispatch_fuel.value;
    obj.minAlternateFuel = this.f.min_alt_fuel.value;
    obj.minLandingFuel = this.f.min_landing_fuel.value;
    obj.taxiFuel = this.f.taxi_fuel.value;
    switch (this.f.taxi_fuel_burn_type.value) {
      case "1":
        obj.taxiFuelBurnType = 1;
        break;
      case "2":
        obj.taxiFuelBurnType = 2;
        break;
      default:
        obj.taxiFuelBurnType = 0;
    }
    
    obj.homebaseAirportID = this.f.homebase_airport.value;
    if (this.isImperial == 1)
      obj.isImperial = true;
    else
      obj.isImperial = false;
    obj.registration = this.registration;
    
    obj.remarks = this.f.remarks.value;
    obj.countryOfRegistration = this.f.country_select.value;

    this._aircraftService.saveAircraft<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.hasChanges = false;
          this.aircraftGuid = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          //this.f.modifiedBy.setValue(localStorage.getItem('un'));
          //this.f.modifiedDate.setValue(dt);
          this.showSuccessMsg = true;
          this.isModified = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");

        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    });

  }

 
}
