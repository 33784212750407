<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<div class="divLeft" style="width: min-content;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div>
    <div style="display: flex; justify-content: space-between;">
      <div style="display: flex; flex-direction: column;">
        <h3 style="font-weight: 200;">My Account</h3>
        <h3>Security</h3>
      </div>
      <div style="display: flex;" *ngIf="!isReset">
        <button mat-icon-button mat-dialog-close>
          <i class="material-icons" style="font-size: x-large;">close </i>
        </button>
      </div>
    </div>
    <div style="display: inline-flex; width: max-content;">
      <i class="material-symbols-outlined" style="margin-top: 0.2em; margin-right: 0.5em;">key</i>
      <h3 style="font-weight: 200;">Change Password</h3>
    </div>
    <div>
      <form *ngIf="change_password_form" [formGroup]="change_password_form" (ngSubmit)="onSubmit()">
        <div *ngIf="msg==''">
          <div class="d-block" style="margin-bottom: 1em; width: 25em; font-weight: 300; font-size: small;">
            <label style="color: black">
              Update credentials and change
              your password associated with your account to enhance security Web and Mobile.
            </label>
          </div>
          <div *ngIf="!passwordValid" class="form-group">
            <label for="old_password" style="width: max-content;">Enter your current password</label>
            <div style="display: inline-flex;">
              <div class="row" style="margin-left:0em; white-space:nowrap; display: inline-flex !important;">
                <input [type]="hideOPwd ? 'password' : 'text'" formControlName="old_password" class="form-control"
                       [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }"
                       style="width:15.75em;height:1.5625em;background-color:#eaf0fd" maxlength="50"
                       (change)="onKeyup($event, 1)" (keyup)="onKeyup($event, 1)" (mousedown)="$event.stopPropagation()" /><i *ngIf="showOPwdEye"
                                                                                                                              class="material-icons small" matSuffix (click)="hideOPwd = !hideOPwd"
                                                                                                                              style="cursor: pointer;margin-top: 0.625em;margin-left:-1.25em">
                  {{
hideOPwd ? 'visibility' :
                  'visibility_off'
                  }}
                </i>
              </div>
              <div class="form-group" style="margin-left: 3em;">
                <button *ngIf="!passwordValid" mat-button class="btn-high" style="margin-right: .5em;" (click)="validatePassword()">
                  Submit
                </button>
              </div>
            </div>
            <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
              <div *ngIf="f.old_password.errors.required">Old Password is required</div>
            </div>
          </div>
          <div *ngIf="passwordValid">
            <div class="col-8">
              <div class="form-group">
                <label for="password">New Password</label>
                <div class="row" style="margin-left:0em; white-space:nowrap">
                  <input [type]="hidePwd ? 'password' : 'text'" formControlName="password" class="form-control"
                         [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                         style="height:1.5625em;background-color:#eaf0fd" maxlength="50"
                         (change)="onKeyup($event, 2)" (keyup)="onKeyup($event, 2)" (mousedown)="$event.stopPropagation()" /><i *ngIf="showPwdEye"
                                                                                                                                class="material-icons small" matSuffix (click)="hidePwd = !hidePwd"
                                                                                                                                style="cursor: pointer;margin-top: 0.625em;margin-left:-1.25em">
                    {{
hidePwd ? 'visibility' :
                    'visibility_off'
                    }}
                  </i>
                </div>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">New password is required</div>
                  <div *ngIf="f.password.errors.minlength">
                    New password must be at least {{minPwdLength}} characters
                    long with no empty space
                  </div>
                  <div *ngIf="f.password.errors.pattern">
                    New password must contain at least 1 upper letter, one lower
                    letter, one number, one special characters with no empty space
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="form-group">
                <label for="confirm_password">Confirm New Password</label>
                <div class="row" style="margin-left:0em; white-space:nowrap">
                  <input [type]="hideCPwd ? 'password' : 'text'" formControlName="confirm_password" class="form-control"
                         [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }"
                         style="height:1.5625em;background-color:#eaf0fd" maxlength="50"
                         (change)="onKeyup($event, 3)" (keyup)="onKeyup($event, 3)" (mousedown)="$event.stopPropagation()" /><i *ngIf="showCPwdEye"
                                                                                                                                class="material-icons small" matSuffix (click)="hideCPwd = !hideCPwd"
                                                                                                                                style="cursor: pointer;margin-top: 0.625em;margin-left:-1.25em">
                    {{
hideCPwd ? 'visibility' :
                    'visibility_off'
                    }}
                  </i>
                </div>
                <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
                  <div *ngIf="f.confirm_password.errors.required">Confirm new password is required</div>
                  <div *ngIf="f.confirm_password.errors.passwordMatchValidator">Passwords do not match.</div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="passwordValid" class="row">
            <div class="col-12">
              <label style="font-size:small">
                **Password requirements:
                <ul>
                  <!--<li>12 characters minimum</li>-->
                  <li>{{minPwdLength}} characters minimum</li>
                  <li>at least one upper case letter</li>
                  <li>at least one lower case letter</li>
                  <li>at least one number</li>
                  <li>at least one special character</li>
                  <li>Cannot use past {{checkPastPasswordNumber}} passwords.</li>
                </ul>
              </label>
            </div>
          </div>
        </div>
      </form>
      <div *ngIf="errMsg!=''" class="d-block"><label style="color:red">{{errMsg}}</label></div>
      <div *ngIf="passwordValid" class="form-group" style="display: inline-flex;">
        <button mat-button class="btn-high" style="margin-right: .5em;"
                [disabled]="change_password_form.status !=='VALID'" (click)="onSave()">
          <i class="material-icons">add</i>
          Save
        </button>
        <button *ngIf="!isReset" mat-button class="btn-low-no-shadow" mat-dialog-close>
          <i class="material-icons">close</i>
          Close
        </button>
      </div>
      <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]="successMsg"></app-success-message>
      </div>
    </div>
  </div>
</div>
