import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { PersonClassModel } from '../models/person-class.model';
import { PersonService } from '../services/person.service';
import { CountryModel } from '../models/country.model';
import { PersonModel } from '../models/person.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { DocumentUsageRuleModel } from '../models/document-usage-rule.model';
import { FileModel } from '../models/file.model';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { GlobalConstant } from '../common-utility/global-constant';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { PersonTravelDocCountrySelectionDialogComponent } from './person-travel-doc-country-selection-dialog.component';
import { PersonTravelDocumentModel } from '../models/person-travel-document.model';
import { DataEntryStatusModel } from '../models/data-entry-status.model';

@Component({
  selector: 'app-person-passport-edit-dialog',
  templateUrl: './person-passport-edit-dialog.component.html',
  styleUrls: ['./person-passport-edit-dialog.component.css']
})

export class PersonPassportEditDialogComponent implements OnInit, AfterViewInit {
  //@Input() dialogTitle: string;
  //@Input() personGUID: string;
  //@Input() personTravelDocumentGUID: string;
  //@Input() inputPersonClass: PersonClassModel;
  dialogTitle: string;
  personGUID: string;
  personTravelDocumentGUID: string;

  person_passport_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = false;
  countryList: CountryModel[];
  docUsageRuleList: DocumentUsageRuleModel[];
  firstName: string;
  lastName: string;
  middleName: string;
  personFirstName: string;
  personLastName: string;
  personMiddleName: string;
  documentNumber: string;
  effectiveDate: Date;
  expirationDate: Date;
  remarks: string;
  selectedIssuingCountryName: string;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  //displayedColumn: string[] = ['personRole'];
  userType: string;
  isModified: boolean = false;
  returnObj: ReturnObjModel;
  showVisaButton: boolean = false;
  success: boolean = false;

  selectedDocUsage: DocumentUsageRuleModel;
  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;

  allowedFileType: string;
  uploader: FileUploader;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  docSize: string;
  allowedFileTypeList: string[];
  maxFileSize: number;
  maxFileUploadNumber: number;

  selectedDocumentTypeGUID: string;
  selectedCountryGUIDList: string;
  selectedCountryNameList: string;
  selectedCountryList: CountryModel[];
  showAddCountryButton: boolean = false;
  showSuccessMsg: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  upPII: boolean = false;
  grantAccessToManageManifestProfiles: boolean;
  
  readonly: boolean;
  dataEntryStatusList: DataEntryStatusModel[];
  selectedDataEntryStatusID: number;
  removeDocument: boolean = false;
  customerGUID: string;
  constructor(private readonly _dialogRef: MatDialogRef<PersonPassportEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _personService: PersonService,
    private readonly _dialogService: DialogService,
    private readonly _dialog: MatDialog
  ) {
    this.personGUID = _data.personGUID;
    this.personTravelDocumentGUID = _data.personTravelDocumentGUID;
    //this.inputPersonClass = data.selectedPersonClass;
    //if (data.customerGUID == null) {
    //  this.customerGUID = "";
    //}
    //else {
    //  this.customerGUID = data.customerGUID;
    //}
    this.dialogTitle = _data.dialogTitle;
    this.customerGUID = _data.customerGUID;

  }

  getAllData(): Observable<any[]> {
    let getDocUsageRuleResponse = this._personService.getDocumentUsageRuleList<ResponseModel<DocumentUsageRuleModel[]>>();
    let getCountryResponse = this._commonService.getCountryListWithUSAFirst<ResponseModel<CountryModel[]>>();
    let getPassportDocTypeID = this._personService.getPassportDocumenTypeID();
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();
    let getPersonDocResponse;
    let getPersonResponse;
    if (this.personTravelDocumentGUID != null && this.personTravelDocumentGUID != "") {
      getPersonDocResponse = this._personService.getCustomerPersonTravelDocumentByptdId(this.personTravelDocumentGUID,false);
      getPersonResponse = this._personService.getPersonBypId(this.personGUID);
    }
    else {
      getPersonDocResponse = of(null);
      getPersonResponse = this._personService.getPersonBypId(this.personGUID);
    }
    let dataEntryStatusResponse;
    if (this.userType == "internal") {
      dataEntryStatusResponse = this._commonService.getDataEntryStatusList<ResponseModel<DataEntryStatusModel[]>>();
    }
    else {
      dataEntryStatusResponse = of(null);
    }
    return forkJoin([getDocUsageRuleResponse, getCountryResponse, getPassportDocTypeID, getAllowedDocTypeResponse, getPersonDocResponse, getPersonResponse, dataEntryStatusResponse]);
    //if (this.personTravelDocumentGUID != null && this.personTravelDocumentGUID != "") {
    //  let getPersonDocResponse = this._personService.getCustomerPersonTravelDocumentByptdId(this.personTravelDocumentGUID);
    //  return forkJoin([getDocUsageRuleResponse, getCountryResponse, getPassportDocTypeID, getAllowedDocTypeResponse, getPersonDocResponse]);
    //}
    //else {     
    //  let getPersonResponse = this._personService.getPersonBypId(this.personGUID);
    //  return forkJoin([getDocUsageRuleResponse, getCountryResponse, getPassportDocTypeID, getAllowedDocTypeResponse, getPersonResponse]);
    //}
    
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }
    if (!this.upPII || !this.grantAccessToManageManifestProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType == 'customer' && this.personTravelDocumentGUID != '') {
      this.readonly = true;
    }
    else {
      this.readonly = false;
    }

    this.showSpin = true;
    
    this.allowedFileType = "";// new Array(GlobalConstant.allowedFileTypeList).toString().split(",").join(", ");
    this.errMsg = "";
    this.msg = "";
    //this.personClassList = [];
    this.countryList = [];
    this.docUsageRuleList = [];
    this.firstName = "";
    this.lastName = "";
    this.middleName = "";
    this.personFirstName = "";
    this.personLastName = "";
    this.personMiddleName = "";
    this.documentNumber = "";
    this.effectiveDate = null;
    this.expirationDate = null;
    this.remarks = "";
    this.selectedIssuingCountryName = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.showVisaButton = false;
    this.returnObj = new ReturnObjModel();
    this.fileList = [];
    this.showAddCountryButton = false;
    this.selectedDocUsage = null;
    this.selectedCountryList = [];
    this.isModified = false;
    this.allowedFileTypeList = [];
    this.maxFileSize = 0;
    this.maxFileUploadNumber = 0;
    this.showSuccessMsg = false;
    this.dataEntryStatusList=[];
    this.selectedDataEntryStatusID = 0;
    this.removeDocument = false;
    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.docUsageRuleList = responses[0].model;
          //this.selectedPersonClass = this.personClassList.find(x => x.personClassGUID.toLowerCase() == this.inputPersonClass.personClassGUID.toLowerCase());
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.countryList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.selectedDocumentTypeGUID = responses[2].model;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          if (responses[3].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[3].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.personTravelDocumentGUID != null && this.personTravelDocumentGUID != "") {
        if (responses[4] != null) {
          if (responses[4].code == "200" && responses[4].message == "") {
            let doc = new PersonTravelDocumentModel();
            doc = responses[4].model;
            this.documentNumber = doc.documentNumber;
            this.selectedIssuingCountryName = doc.documentIssuingCountryName;
            this.effectiveDate = null;
            if (doc.documentDOI!="")
              this.effectiveDate = new Date(doc.documentDOI);
            this.expirationDate = null;
            if (doc.documentDOE!="")
              this.expirationDate = new Date(doc.documentDOE);
            this.firstName = doc.firstName;
            this.lastName = doc.lastName;
            this.middleName = doc.middleName;
            this.remarks = doc.documentBriefNotes;
            if (doc.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
            this.modifiedBy = doc.modifiedBy;
            this.modifiedDate = doc.modifiedDate;
            //this.selectedDocUsage = new DocumentUsageRuleModel();
            this.selectedDocUsage = null;
            if (doc.documentUsageRuleGUID != "") {
              this.selectedDocUsage = this.docUsageRuleList.find(x => x.documentUsageRuleGUID.toLowerCase() == doc.documentUsageRuleGUID.toLowerCase());
              if (this.selectedDocUsage.documentUsageRuleDesc.toLowerCase().indexOf("specified countries") != -1) {
                this.showAddCountryButton = true;
              }
              else {
                this.showAddCountryButton = false;
              }
            }
            this.selectedDataEntryStatusID = doc.dataEntryStatusID;
            this.selectedCountryList = doc.countryList;
            if (doc.hasDocument) {
              let obj = new FileModel();
              obj.bFile = doc.bFile;
              obj.mimeType = doc.mimeType;
              obj.fileType = doc.fileExtension;
              this.fileList.push(obj)
            }
            
          }
          else {
            if (responses[4].code == "401") {
              this._authService.signOut();
            }
          }
        }
        //if (responses[6] != null) {
        //  if (responses[6].code == "200" && responses[6].message == "") {
        //    this.dataEntryStatusList = responses[6].model;

        //  }
        //  else {
        //    if (responses[6].code == "401") {
        //      this._authService.signOut();
        //    }
        //  }
        //}

      }
      
        if (responses[5] != null) {
          if (responses[5].code == "200" && responses[5].message == "") {
            let person = new PersonModel();
            person = responses[5].model;
            if (this.personTravelDocumentGUID == null || this.personTravelDocumentGUID == "") {
              this.firstName = person.firstName;
              this.lastName = person.lastName;
              this.middleName = person.middleName;
            }
            this.personFirstName = person.firstName;
            this.personLastName = person.lastName;
            this.personMiddleName = person.middleName;
          }
          else {
            if (responses[5].code == "401") {
              this._authService.signOut();
            }
          }
        }     

      
      if (responses[6] != null) {
        if (responses[6].code == "200" && responses[6].message == "") {
          this.dataEntryStatusList = responses[6].model;

        }
        else {
          if (responses[6].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.initControls();

      this.setControlStatus();

      this.showSpin = false;


    });

    

  }

  initControls() {
    this.person_passport_edit_form = this._formBuilder.group({
      firstName: [this.firstName, Validators.required],
      lastName: [this.lastName, Validators.required],
      middleName: [this.middleName],
      documentNumber: [this.documentNumber, Validators.required],
      effectiveDate: this.effectiveDate,
      expirationDate: [this.expirationDate],
      remarks: [this.remarks],
      issuing_country_select: [this.selectedIssuingCountryName, Validators.required],
      person_doc_status: this.isActive,
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      doc_usage_rule_select:[this.selectedDocUsage, Validators.required],
      document: [null],
      data_entry_status_select: [this.selectedDataEntryStatusID],
    });
  }

  setControlStatus() {
    if (this.userType == 'customer' && this.personTravelDocumentGUID != '') {
      this.person_passport_edit_form.get('firstName').disable();
      this.person_passport_edit_form.get('lastName').disable();
      this.person_passport_edit_form.get('middleName').disable();
      this.person_passport_edit_form.get('documentNumber').disable();
      this.person_passport_edit_form.get('effectiveDate').disable();
      this.person_passport_edit_form.get('expirationDate').disable();
      this.person_passport_edit_form.get('remarks').disable();
      this.person_passport_edit_form.get('issuing_country_select').disable();
      this.person_passport_edit_form.get('person_doc_status').disable();
      this.person_passport_edit_form.get('doc_usage_rule_select').disable();
    }
    else {
      this.person_passport_edit_form.get('firstName').enable();
      this.person_passport_edit_form.get('lastName').enable();
      this.person_passport_edit_form.get('middleName').enable();
      this.person_passport_edit_form.get('documentNumber').enable();
      this.person_passport_edit_form.get('effectiveDate').enable();
      this.person_passport_edit_form.get('expirationDate').enable();
      this.person_passport_edit_form.get('remarks').enable();
      this.person_passport_edit_form.get('issuing_country_select').enable();
      this.person_passport_edit_form.get('person_doc_status').enable();
      this.person_passport_edit_form.get('doc_usage_rule_select').enable();
    }
  }


  get f() { return this.person_passport_edit_form.controls; }

  ngAfterViewInit() {

  }

  clickSelectCountry() {
    let s = "";
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.width = "53em";//"1000px";
    dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: this.personGUID, personTravelDocumentGUID: this.personTravelDocumentGUID, selectedCountryList:this.selectedCountryList };
    //dialogConfig.data = { dialogTitle: s };

    const dialogRef = this._dialog.open(PersonTravelDocCountrySelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.selectedCountryList = result;
      }
    });


  }

  docUsageOnchange(e: any) {
    this.errMsg = "";
    this.selectedDocUsage = this.f.doc_usage_rule_select.value;
    if (this.selectedDocUsage == null || this.selectedDocUsage==undefined) {
      this.showAddCountryButton = false;
    }
    else {
      if (this.selectedDocUsage.documentUsageRuleDesc.toLowerCase().indexOf("specified countries") != -1) {
        this.showAddCountryButton = true;
      }
      else {
        this.showAddCountryButton = false;
      }
    }
  }

  // upload file
  importFile(event: any) {
    this.finishAllUploads = false;
    this.fileList = [];
    var files = [];
    this.errMsg = ""
    //this.errDocMsg = "";
    if (event.target.files.length > this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {
      //if (this.errMsg != "") {
      //  this.errMsg += "\n";
      //}
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      this.finishAllUploads = true;
      this.uploader.clearQueue();
      return;
    }
    else {
      if (event.target.files.length > 1) {
        //if (this.errMsg != "") {
        //  this.errMsg += "\n";
        //}
        this.errMsg = 'Only allow to upload one file.';
        this.finishAllUploads = true;
        this.uploader.clearQueue();
        return;
      }
      else {
        for (var i = 0; i < event.target.files.length; i++) {
          let filename = event.target.files[i].name;
          //var a = CustomValidators.checkValidFileType(filename);
          var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
          if (a) {
            if (event.target.files[i].size >= this.maxFileSize) { // GlobalConstant.maxFileUploadSize) {
              //if (this.errMsg != "") {
              //  this.errMsg += "\n";
              //}
              this.errMsg = 'The file size "' + filename + '" is too big to upload.';
              this.finishAllUploads = true;
              this.uploader.clearQueue();
              return;
            }
            else {
              files.push(event.target.files[i]);

            }
          }
          else {
            //if (this.errMsg != "") {
            //  this.errMsg += "\n";
            //}
            //this.uploader.removeFromQueue(this.uploader.queue[0]);
            this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
            this.finishAllUploads = true;
            this.uploader.clearQueue();
            return;
          }
        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
      return;
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            let obj = new FileModel();
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            
            this.fileList.push(obj)
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  clickPreviewDocument() {
    if (this.fileList.length > 0 && this.fileList[0].bFile != '') {
      let item = this.fileList[0];
      let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
      let file = new Blob([byteArray], { type: item.mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    else {
      this._personService.getCustomerPersonTravelDocumentByptdId<ResponseModel<PersonTravelDocumentModel>>(this.personTravelDocumentGUID, true).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let doc = new PersonTravelDocumentModel();
            doc = response.model;

            if (doc.hasDocument) {
              let docObj = response.model;
              let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
              let file = new Blob([byteArray], { type: docObj.mimeType });
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);
            }

          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          }
        }
      });
    }
    
    
  }

  clickRemoveDocument() {
    this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the document?");
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        this.fileList = [];
        this.removeDocument = true;
      }
    })

  }

  onFileClick(event) {
    event.target.value = '';
  }
  // upload file end

  checkDuplicateDocument(): Observable<number> {
    return new Observable<number>(ob => {
      let request = new PersonTravelDocumentModel();
      request.personGUID = this.personGUID;
      request.documentNumber = this.f.documentNumber.value;
      request.documentIssuingCountryName = this.f.issuing_country_select.value;
      request.personTravelDocumentGUID = this.personTravelDocumentGUID;
      request.documentTypeGUID = this.selectedDocumentTypeGUID;
      try {
        this._personService.checkCustomerPersonTravelDocument<ResponseModel<number>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            //if (response.model >0) {                                
              ob.next(response.model);
            //}
            //else {
            //  ob.next(null);
            //}
          }
          else {
            ob.next(-1);
          }
        });
      }
      catch (error) {
        //console.log(error);
        ob.next(-1);
      }
    });

  }

  clickSave() {
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;

    if (this.loading == true) {
      return;
    }

    if (this.f.documentNumber.value != "" && this.f.issuing_country_select.value!="") {
      this.checkDuplicateDocument().subscribe(res => {
        if ((res != null || res != undefined) && res >= 0) {
          //this.f.username.setValue(res);
          if (res == 0) {
            this.formValidation();
          }
          else {
            this.errMsg = "Duplicate information, please check.";
            return;
          }
        }
      })
    }
    else {
      this.formValidation();
    }


  }

  formValidation() {
    //this.errMsg = "";
    //this._authService.updateAccessTime();
    //this.submitted = true;

    //if (this.loading == true) {
    //  return;
    //}
    if (this.effectiveDateRef.nativeElement.value !== "") {
      if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.effectiveDate.setErrors({ isValidDate: f1 });

        }
      }
      else {
        this.f.effectiveDate.setErrors({ isValidDate: false });

      }
    }

    if (this.expirationDateRef.nativeElement.value !== "") {
      if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null) {
        let s2 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
        let f2 = CustomValidators.isValidDate(s2);
        if (!f2) {
          this.f.expirationDate.setErrors({ isValidDate: f2 });

        }
      }
      else {
        this.f.expirationDate.setErrors({ isValidDate: false });

      }
    }

    if (this.selectedDocUsage != null && this.selectedDocUsage != undefined) {
      if (this.selectedDocUsage.documentUsageRuleDesc.toLowerCase().indexOf("specified countries") >0) {
        if (this.selectedCountryList.length == 0) {
          this.errMsg = "You must select at least one country.";
        }
      }
      
    }

    if (this.person_passport_edit_form.invalid || this.errMsg != "") {
      return;
    }
    
    if (this.personFirstName != this.f.firstName.value || this.personMiddleName != this.f.middleName.value || this.personLastName != this.f.lastName.value) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Passport name does not match person record.\n \n Continue?", false);
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result)
          this.saveData();
      });
    }
    else
      this.saveData();


  }
  saveData() {
    this.loading = true;
    let obj = new PersonTravelDocumentModel();
    obj.personTravelDocumentGUID = "";
    obj.personGUID = this.personGUID;
    if (this.personTravelDocumentGUID != "" && this.personTravelDocumentGUID != null)
      obj.personTravelDocumentGUID = this.personTravelDocumentGUID;
    obj.documentTypeGUID = this.selectedDocumentTypeGUID;
    obj.documentTypeDescription = "";
    obj.countryGUIDList = "";
    if (this.selectedCountryList.length > 0)
      obj.countryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    obj.firstName = this.f.firstName.value;
    obj.lastName = this.f.lastName.value;
    obj.middleName = "";
    if (this.f.middleName.value != "")
      obj.middleName = this.f.middleName.value;
    obj.documentNumber = this.f.documentNumber.value;
    obj.documentIssuingCountryName = this.f.issuing_country_select.value;
    obj.documentDOI = this.effectiveDateRef.nativeElement.value;
    obj.documentDOE = this.expirationDateRef.nativeElement.value;
    obj.documentBriefNotes = "";
    if (this.f.remarks.value != "")
      obj.documentBriefNotes = this.f.remarks.value;
    obj.documentUsageRuleGUID = "";
    if (this.f.doc_usage_rule_select.value != null && this.f.doc_usage_rule_select.value != undefined && this.f.doc_usage_rule_select.value != '')
      obj.documentUsageRuleGUID = this.selectedDocUsage.documentUsageRuleGUID;
    obj.isActive = true;
    if (obj.personTravelDocumentGUID != "") {
      if (this.f.person_doc_status.value == "1")
        obj.isActive = true;
      else
        obj.isActive = false;
    }
    obj.bFile = "";
    if (this.fileList.length > 0) {
      obj.bFile = this.fileList[0].bFile;
      obj.fileExtension = this.fileList[0].fileType;
      obj.fileSize = this.fileList[0].fileSize;
    }
    obj.dataEntryStatusID = 0;
    if (this.userType == 'internal')
      obj.dataEntryStatusID = Number(this.f.data_entry_status_select.value);
    obj.removeDocument = this.removeDocument;
    obj.customerGUID = this.customerGUID;
    this._personService.savePersonTravelDocument<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.personTravelDocumentGUID = response.model;
          if (this.userType == 'internal' && obj.personTravelDocumentGUID == "" && this.personTravelDocumentGUID != "") {
            this.selectedDataEntryStatusID = 2;
            this.f.data_entry_status_select.setValue(this.selectedDataEntryStatusID);
          }
          this.returnObj.refresh = true;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.showSuccessMsg = true;
          if (obj.personTravelDocumentGUID == "") {
            this.showVisaButton = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
            }, 5000)
            //this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          }
          else {
            //this._dialogService.openAlertDialog("Your information has been submitted successfully.");
            setTimeout(() => {
              this.showSuccessMsg = false;
              this.clickClose();
            }, 1000)
            //this.clickClose();
          }
          this.isModified = true;

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })
  }
  clickAddVisa() {
    this.returnObj.refresh = false;
    this.returnObj.newId = this.personTravelDocumentGUID;
    this._dialogRef.close(this.returnObj); // go back to PersonDocList page
  }

  //clickOpenContactDetails() {
  //  this.returnObj.refresh = false;
  //  this.returnObj.newId = this.personGUID;
  //  this._dialogRef.close(this.returnObj);
  //}

  clickClose() {
    this._dialogRef.close(this.returnObj);
  }

  
}
