import { Component, Inject, OnInit, ViewChild, AfterViewInit, ElementRef, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { TripModel } from '../models/trip.model';
import { GroundStopService } from '../services/ground-stop.service';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { FARTypeModel } from '../models/far-type.model';
import { TripLegModel } from '../models/trip-leg.model';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { TripLegService } from '../services/trip-leg.service';
import { LinkMessageLegList, LinkMessageServiceList } from '../models/link-message-list.model';
import { MessageModel } from '../models/message.model';
import { MessageService } from '../services/message.service';
import { ServiceClassModel } from '../models/service-class.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { UserModel } from '../models/user.model';
import { IdentityService } from '../services/identity.service';
import { MatLegacySnackBar as MatSnackBar, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { IdentityRequestModel } from '../models/identity-request.model';
import { NavigationExtras } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatAccordion } from '@angular/material/expansion';
import { DatePipe } from '@angular/common';
import { MessageLinkModel } from '../models/message-link';

export interface legFilterDTO {
  departure: string;
  arrival: string;
  departureDateTime: Date;
  arrivalDateTime: Date;
  dateText: string;
  groundStopGUID: string;
  nextGroundStopGUID: string;
  selected: boolean;
}

@Component({
  selector: 'app-task-service-dialog',
  templateUrl: './task-service-dialog.component.html',
  styleUrls: ['./task-service-dialog.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TaskServiceDialogComponent implements OnInit, AfterViewInit, AfterContentChecked {

  dialogTitle: string;
  customerGUID: string;
  aircraftGUID: string;
  tripCodeGUID: string;
  messageGUID: string;
  messageGUIDList: string;
  currentIndex: number;
  task_service_dialog_form: UntypedFormGroup;
  loadingServices = true;
  loadingService = false;
  loadingIndex: number;

  submitted = false;
  errMsg: string;
  errMsg2: string;
  duplicateIATAErrMsg: string;
  msg: string;

  showSpin: boolean = false;
  userType: string;
  client_name: string;
  registration: string;
  trip_code: string;
  speed: number;
  route: string;
  isModified: boolean = false;
  loadingAirport: boolean = false;
  existingGroundStopList: GroundStopModel[] = [];
  groundStopList: GroundStopModel[] = [];
  airportList: AirportModel[];
  showLegsFilter: boolean = false;

  validatedAirport: boolean = false;
  showSuccessMsg: boolean = false;

  displayedColumn: string[] = ['legSelected', 'departure', 'arrival', 'expand'];
  @ViewChild('routeRef', { static: false }) routeRef: ElementRef;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  farTypeList: FARTypeModel[];
  lockdownTrip: boolean = false;

  time_type: string;
  minDate: Date = new Date();
  upPII: boolean = false;
  showLinkMsg: boolean = false;
  serviceClassList: ServiceClassModel[];
  selectedServiceClassList: ServiceClassModel[];
  orgServiceTypeList: ServiceTypeModel[];
  serviceTypeList: ServiceTypeModel[];
  legFilterList: legFilterDTO[] = [];
  selectedLegFilterList: legFilterDTO[] = [];
  userServiceTypeList: string;
  selectedServiceTypeList: ServiceTypeModel[];
  userTaskStatusList: string;
  selectedTaskStatusList: TripTaskStatusModel[];
  taskStatusList: TripTaskStatusModel[];
  checkServiceTypeArray: UntypedFormControl; // for mat-select
  allCompleteOps: boolean = false;
  allCompleteWx: boolean = false;
  allCompleteStatus: boolean = false;
  selectedServiceCountText: string = "";
  selectedLegCountText: string = "";
  checkTaskStatusArray: UntypedFormControl; // for mat-select
  showServiceFilter: boolean = false;
  showlegFilter: boolean = false;
  showTaskStatusFilter: boolean = false;
  totalRecordsText: string;
  recordList: GroundStopTaskModel[];
  date_type: string;
  totalRecords: number = 0;
  totalPageNumber: number = 0;
  pageSize: number = 50;
  currentPageIndex: number = 0;
  hideOnHold: boolean = true;
  text_search: string;
  filter_by: string;
  hidePastStop: boolean = true;
  showCancelled: boolean = true;
  showSuccessMessage: boolean = false;
  refreshChildColumn: string;
  requestInput: TripModel;
  selectedTaskCountText: string = "";

  selectedLinkList: LinkMessageServiceList[] = [];
  existingLinkList: LinkMessageLegList[] = [];
  displayLinkList: LinkMessageLegList[] = [];

  request: TripModel;
  messageLinkList: MessageLinkModel[];
  activeGroundStopList: GroundStopModel[] = [];
  allGroundStopList: GroundStopModel[] = [];

  @ViewChild(MatAccordion) leglistAccordion: MatAccordion;

  constructor(private readonly _dialogRef: MatDialogRef<TaskServiceDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _airportService: AirportService, private readonly _groundStopService: GroundStopService,
    private readonly _groundStopClientService: GroundStopClientService, private readonly _dialogService: DialogService,
    private readonly _tripLegService: TripLegService, private readonly _messageService: MessageService,
    private readonly _dialog: MatDialog, private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    private readonly _identityService: IdentityService, private _snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }

    this.tripCodeGUID = _data.tripCodeGUID;
    this.messageGUID = _data.messageGUID;
    this.messageGUIDList = _data.messageGUIDList;
    this.aircraftGUID = _data.aircraftGUID;
    this.dialogTitle = _data.dialogTitle;
    this.currentIndex = _data.currentIndex;
    this.messageLinkList = _data.messageLinkList;

  }

  getAllData(): Observable<any[]> {
    let request = new AircraftTripModel();
    request.customerGUID = this.customerGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    let getTripResponse = this._groundStopClientService.getTripBytId(request);
    let getService = this._groundStopAdvancedService.getServiceClass_ServiceTypeList(1);
    let req3 = new IdentityRequestModel();
    let userResponse = this._identityService.getUser(req3);
    return forkJoin([getTripResponse, getService, userResponse]);
  }

  ngOnInit() {
    this.hidePastStop = true;
    this.selectedServiceClassList = [];
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.errMsg2 = "";
    this.duplicateIATAErrMsg = "";
    this.msg = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.route = "";

    this.client_name = "";
    this.registration = "";
    this.trip_code = "";
    this.existingGroundStopList = [];
    this.groundStopList = [];
    this.airportList = [];
    this.validatedAirport = false;
    this.showSuccessMsg = false;
    this.farTypeList = [];
    this.lockdownTrip = false;
    this.time_type = "utc";
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].model != null) {
          let t = new TripModel();
          t = responses[0].model;
          this.existingGroundStopList = t.groundStopList;
          this.activeGroundStopList = t.activeGroundStopList;
          this.allGroundStopList = t.groundStopList;

          if (this.showCancelled) {
            this.existingGroundStopList = t.activeGroundStopList;
          }
          else {
            this.existingGroundStopList = t.groundStopList;
          }

          this.lockdownTrip = t.lockdownTrip;
          this.trip_code = t.tripCode;
          this.speed = t.speed;
          this.registration = t.registration;
          this.client_name = t.customerName;

          t.activeGroundStopList.forEach((leg, index, list) => {
            if (index + 1 != list.length && this.legFilterList.findIndex(l => l.groundStopGUID == leg.groundStopGUID && l.nextGroundStopGUID == list[index + 1]?.nextGroundStopGUID) == -1) {
              this.legFilterList.push({
                departure: leg.icao,
                arrival: list[index + 1].icao,
                departureDateTime: new Date(leg.departureDateTextLocal + ' ' + leg.departureTimeLocal),
                arrivalDateTime: new Date(list[index + 1].arrivalDateTextLocal + ' ' + list[index + 1].arrivalTimeLocal),
                dateText: this.dateTextforFilter(leg.departureDateTextUTC, list[index + 1].arrivalDateTextUTC),
                groundStopGUID: leg.groundStopGUID,
                nextGroundStopGUID: list[index + 1].groundStopGUID,
                selected: false
              });
            }
          });

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.orgServiceTypeList = responses[1].model;
          this.serviceTypeList = this.orgServiceTypeList.filter(x => x.filterGroup != null);
          this.serviceTypeList.sort((a, b) => a.filterGroup - b.filterGroup || a.filterColumn - b.filterColumn || a.filterOrderBy - b.filterOrderBy);

          this.setServiceTypeList(false);
        } else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        // if (responses[2].code == "200" && responses[2].message == "") {
        //   // let user = responses[2].model;
        //   // this.userServiceTypeList = user.taskQueueServiceIDList;
        //   // if (user.taskQueueServiceIDList != "") {
        //   //   this.serviceTypeList.forEach(x => {
        //   //     if (("," + user.taskQueueServiceIDList + ",").indexOf("," + x.serviceTypeID.toString() + ",") > -1) {
        //   //       x.selected = true;
        //   //     }
        //   //   });
        //   //   this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected);
        //   // }

        // }
        // else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
         
      }

      this.initControls();
      if (this.selectedServiceTypeList != null) {
        if (this.selectedServiceTypeList.length == this.serviceTypeList.length)
          this.selectedServiceCountText = ": All";
        else {
          if (this.selectedServiceTypeList.length == 0)
            this.selectedServiceCountText = "";
          else
            this.selectedServiceCountText = ": " + this.selectedServiceTypeList.length.toString();
        }
      }
      else
        this.selectedServiceCountText = ": All";
      this.getLegList();      
    });


  }

  dateTextforFilter(departureDate, arrivalDate): string {
    let dateText: string;
    if (departureDate && arrivalDate)
      dateText = '(' + new DatePipe('en-US').transform(departureDate, 'MM/dd') + '-' + new DatePipe('en-US').transform(arrivalDate, 'MM/dd') + ')';
    else if (departureDate)
      dateText = '(' + new DatePipe('en-US').transform(departureDate, 'MM/dd') + '-' + ')';
    else if (arrivalDate)
      dateText = '(' + '-' + new DatePipe('en-US').transform(arrivalDate, 'MM/dd') + ')';
    else dateText = null;
    return dateText;
  }

  initControls() {
    if (this.customerGUID != "") {
      this.customerGUID = this.customerGUID.toLowerCase();
    }
    this.task_service_dialog_form = this._formBuilder.group({
      //route: [this.route, Validators.compose([
      //  Validators.pattern("^[-a-zA-Z0-9,\* \n\r]*$")]
      //)],
      //time_type: [this.time_type],
      hidePastStop: [this.hidePastStop],
    });


  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //  this.routeRef.nativeElement.focus();
    //}, 500);
  }

  get f() { return this.task_service_dialog_form.controls; }

  getLegList() {
    this.showSpin = true;
    this.request = new TripModel();
    this.request.tripCodeType = "";
    this.request.customerGUID = this.customerGUID;
    this.request.tripCodeGUID = this.tripCodeGUID;
    this.request.aircraftGUID = this.aircraftGUID;
    this.request.includeActiveStopOnly = this.showCancelled;
    this.request.groundStopTypeGUIDList = '';
    this.request.isFromLegEdit = false;
    if (this.selectedServiceTypeList.length == this.serviceTypeList.length) {
      this.request.serviceTypeIDList = "";
    }
    else {
      this.request.serviceTypeIDList = Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
    }
    this._tripLegService.getSimpleGroundStopLegList<ResponseModel<TripLegModel[]>>(this.request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          var result = response.model;

          this.existingLinkList = [];

          if (response.model.length > 0) {
            let leg: LinkMessageLegList;
            response.model.forEach(x => {
              leg = new LinkMessageLegList();
              leg.departure = x.departureICAO;
              leg.arrival = x.nextArrivalICAO;
              leg.departureDateTime = x.departureDateTimeUTCDisplay;
              leg.arrivalDateTime = x.nextArrivalDateTimeUTCDisplay;
              leg.departureDateTextUTC = x.departureDateTextUTC;
              leg.nextArrivalDateTextUTC = x.nextArrivalDateTextUTC;
              leg.groundStopGUID = x.groundStopGUID;
              leg.nextGroundStopGUID = x.nextArrivalGroundStopGUID;
              leg.colorCode = x.departureGroundStopTypeColor;
              leg.nextColorCode = x.nextArrivalGroundStopTypeColor;
              leg.arrivalDateTimePassed = x.nextArrivalDateTimePassed;
              leg.arrivalServiceList = [];
              leg.departureServiceList = [];

              this.existingLinkList.push(leg);
            });
          }
          this.displayLinkList = this.existingLinkList;
        }
        else {
          this.totalRecordsText = "No record found";
          this.displayLinkList = [];
          this.existingLinkList = [];
        }
        this.showSpin = false;
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.clickDoneLegFilter();
      this.showSpin = false;
    });
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }


  updateSelectedLinkTasks(link: LinkMessageServiceList) {
    link.selected = !link.selected;
    if (link.selected)
      this.selectedLinkList.push(link);
    else {
      let idx = this.selectedLinkList.findIndex(s => s.serviceTypeID == link.serviceTypeID);
      this.selectedLinkList.splice(idx, 1);
    }

    this.showLinkMsg = this.selectedLinkList.length > 0
    this.selectedTaskCountText = this.selectedLinkList.length > 0 ? ": " + this.selectedLinkList.length.toString() : ""
  }


  linkMessage() {
    var messages: MessageModel[] = [];
    let msgGuids;
    if (this.messageGUIDList) {
      msgGuids = this.messageGUIDList.split(',');
      msgGuids.forEach(msgGuid => {
        this.selectedLinkList.forEach(sl => {
          let message = new MessageModel();
          message.messageGUID = msgGuid;
          message.customerGUID = this.customerGUID;
          message.tripCodeGUID = this.tripCodeGUID;
          message.groundStopGUID = sl.groundStopGUID;
          message.groundStopTaskGUID = sl.groundStopTaskGUID;
  
          messages.push(message);
        });
      });
    }
    else {
      this.selectedLinkList.forEach(sl => {
        if (this.messageLinkList == undefined || this.messageLinkList?.findIndex(l => l.groundStopTaskGUID == sl.groundStopTaskGUID) == -1) {
          let message = new MessageModel();
          message.messageGUID = this.messageGUID;
          message.customerGUID = this.customerGUID;
          message.tripCodeGUID = this.tripCodeGUID;
          message.groundStopGUID = sl.groundStopGUID;
          message.groundStopTaskGUID = sl.groundStopTaskGUID;

          messages.push(message);
        }
      });
      this.messageLinkList?.forEach(ml=>{
        if(this.selectedLinkList.findIndex(l=>l.groundStopTaskGUID == ml.groundStopTaskGUID) == -1){
          let message = new MessageModel();
          message.messageGUID = this.messageGUID;
          message.customerGUID = ml.customerGUID;
          message.tripCodeGUID = ml.tripCodeGUID;
          message.groundStopGUID = ml.groundStopGUID;
          message.groundStopTaskGUID = ml.groundStopTaskGUID;
          message.messageLinkGUID = ml.messageLinkGUID
          messages.push(message);
        }
      });
    }
    if (this.selectedLinkList.length > 0)
      this._messageService.insertMessageLink<ResponseModel<MessageModel>>(messages).subscribe(response => {
        if (response.code == "200") {
          if (this.messageGUIDList)
            msgGuids.forEach(msgGuid => {
              this._messageService.assignAndMovetoInbox(msgGuid);
            });
          else
            this._messageService.assignAndMovetoInbox(this.messageGUID);
          
          this.showSuccessMessage = true;
          setTimeout(() => {
            this._dialogRef.close(true);
          }, 1500);
        }
      });
  }


  updateAllComplete(group: number) {
    switch (group) {
      case 1:
        this.allCompleteOps = this.serviceTypeList.filter(x => x.filterGroup == group).every(t => t.selected);
        break;
      case 2:
        this.allCompleteWx = this.serviceTypeList.filter(x => x.filterGroup == group).every(t => t.selected);
        break;

    }
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
    if (this.selectedServiceTypeList.length == this.serviceTypeList.length)
      this.selectedServiceCountText = ": All";
    else {
      if (this.selectedServiceTypeList.length == 0)
        this.selectedServiceCountText = "";
      else
        this.selectedServiceCountText = ": " + this.selectedServiceTypeList.length.toString();
    }
  }
  onServiceTypeChange(e: any, item: ServiceTypeModel) {
    //item.selected = e.source.selected;
    item.selected = e.checked;
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
    this.updateAllComplete(item.filterGroup);

  }

  onLegFilterChange(e: any, item: { legName: string, selected: boolean }) {
    item.selected = e.checked;
    this.selectedLegFilterList = this.legFilterList.filter(l => l.selected);
  }

  setServiceTypeList(selected: boolean) {
    if (this.selectedServiceClassList.length == 0) {
      this.serviceTypeList = this.orgServiceTypeList.filter(x => x.filterGroup != null);
      this.serviceTypeList.sort((a, b) => a.filterGroup - b.filterGroup || a.filterColumn - b.filterColumn || a.filterOrderBy - b.filterOrderBy);
      this.serviceTypeList.forEach(x => {
        x.selected = false;
        x.serviceClassSelected = false;
      });
    }
    else {
      this.serviceTypeList = this.orgServiceTypeList.filter(u => u.serviceClassSelected == selected);
    }
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);

    this.checkServiceTypeArray = new UntypedFormControl(this.selectedServiceTypeList); // for mat-select
    this.updateAllComplete(1);
    this.updateAllComplete(2);
  }

  clickResetServiceType() {
    this.serviceTypeList.every(t => t.selected = false);
    this.setServiceTypeList(false);
    this.showServiceFilter = false;
    this.selectedServiceCountText = "";
    this.getLegList();
  }

  clickDoneServiceType(subFilter = false) {
    this.showServiceFilter = false;
    this.getLegList();
  }


  clickServiceFilter() {
    this.showServiceFilter = !this.showServiceFilter;
    this.showTaskStatusFilter = false;

    this.showlegFilter = false;
  }

  someComplete(group: number): boolean {
    switch (group) {
      case 1:
        return this.serviceTypeList.filter(t => t.selected && t.filterGroup == group).length > 0 && !this.allCompleteOps;
      case 2:
        return this.serviceTypeList.filter(t => t.selected && t.filterGroup == group).length > 0 && !this.allCompleteWx;
    }
  }

  selectAllServiceType(completed: boolean, group: number) {
    switch (group) {
      case 1:
        this.allCompleteOps = completed;
        this.serviceTypeList.filter(x => x.filterGroup == group).forEach(t => {
          t.selected = completed;

        });
        break;
      case 2:
        this.allCompleteWx = completed;
        this.serviceTypeList.filter(x => x.filterGroup == group).forEach(t => {
          t.selected = completed;

        });
        break;
    }
    this.updateAllComplete(group);
  }

  clickSaveSelections(type: string) {
    this._authService.updateAccessTime();
    let request = new UserModel();
    if (type.toLowerCase() == 'service') {
      request.taskQueueServiceIDList = "";
      request.taskQueueServiceIDList = Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
      request.taskQueueStatusList = null;
    }

    this._identityService.saveUserProfile<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this._snackBar.openFromComponent(SuccessComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            data: type,
            panelClass: ['green-snackbar']
          });

        }
        else {
          this._snackBar.open('Error Saving', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['red-snackbar']
          });

        }
      }
      else {
        if (response.code == "401") {
          if (response.message != "") {
            this._snackBar.open(response.message, '', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
              panelClass: ['red-snackbar']
            });
          }
          else {
            this._authService.signOut();
          }
        }
        else {
          this._snackBar.open('Error Saving', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['red-snackbar']
          });

        }
      }
    })
  }

  clickOpenTrip() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "tripId": this.tripCodeGUID,
        "customerId": this.customerGUID,
        "aircraftId": this.aircraftGUID,
        "pf": "",
        v: this._authService.getCurrentTimeNumber()
      }
    };
    window.open('/ground-stops/trip-details-internal?customerId=' + this.customerGUID + "&tripId=" + this.tripCodeGUID + "&aircraftId=" + this.aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank',
    "toolbar=yes,resizable=yes");
  }

  clickDoneLegFilter(subFilter = false) {
    let filterLegList = [];
    if (this.selectedLegFilterList.length > 0) {
      if (!subFilter) {
        this.selectedLegFilterList.forEach(lf => {
          let filterll = this.existingLinkList.filter(l => (l.groundStopGUID == lf.groundStopGUID || l.groundStopGUID == lf.nextGroundStopGUID) && l.departure == lf.departure && l.arrival == lf.arrival);
          if (filterll != null && filterll.length > 0)
            filterll.forEach(fll => filterLegList.push(fll));
        });
        this.displayLinkList = filterLegList;
      }
      else {
        this.selectedLegFilterList.forEach(lf => {
          let filterll = this.displayLinkList.filter(l => (l.groundStopGUID == lf.groundStopGUID || l.groundStopGUID == lf.nextGroundStopGUID) && l.departure == lf.departure && l.arrival == lf.arrival);
          if (filterll != null && filterll.length > 0)
            filterll.forEach(fll => filterLegList.push(fll));
        });
        this.displayLinkList = filterLegList;
      }
    }
    else if (this.selectedLegFilterList.length == 0 && this.selectedServiceTypeList.length == 0)
      this.displayLinkList = this.existingLinkList;
    else if (!subFilter && this.selectedLegFilterList.length == 0) this.clickResetLegFilter()
    this.selectedLegCountText = this.selectedLegFilterList.length > 0 ? ": " + this.selectedLegFilterList.length.toString() : "";

    this.showlegFilter = false;
  }

  clickResetLegFilter() {
    this.legFilterList.forEach(t => t.selected = false);
    this.selectedLegFilterList = [];
    this.displayLinkList = this.existingLinkList;
    this.selectedLegCountText= "";

    this.showlegFilter = false;
  }

  clicklegFilter() {
    this.showlegFilter = !this.showlegFilter;
    this.showServiceFilter = false;
  }

  hidePastStopChange(e: any) {
    this.hidePastStop = e.checked;
    // this.showLegsFilter = true;

  }

  showActiveLegsOnly(e: any) {
    this.showCancelled = e.checked;
    this.showSpin = true;
    this.getLegList();
    this.getAllData();
    if (this.showCancelled) {
      this.existingGroundStopList = this.activeGroundStopList;
    }
    else {
      this.existingGroundStopList = this.allGroundStopList;
    }
  }

  loadServices(gsGUID, nextArrivalGSGUID, leg: LinkMessageLegList, index) {
    if (leg.arrivalServiceList.length > 0 || leg.departureServiceList.length > 0)
      return;

    this.loadingIndex = index;
    this.loadingService = true;
    let reqD = new GroundStopModel();
    reqD.groundStopGUID = gsGUID;
    reqD.taskType = "D";
    reqD.tripTaskStatusGUIDList = this.request.tripTaskStatusGUIDList;
    reqD.serviceTypeIDList = this.request.serviceTypeIDList;
    reqD.accountingStatusGUIDList = this.request.accountingStatusGUIDList;
    reqD.includeDisabledTask = this.request.includeDisabledTask;
    let depTaskResponse = this._tripLegService.getTaskListBygIdTaskType<ResponseModel<any>>(reqD);


    let reqA = new GroundStopModel();
    reqA.groundStopGUID = nextArrivalGSGUID;
    reqA.taskType = "A";
    reqA.tripTaskStatusGUIDList = this.request.tripTaskStatusGUIDList;
    reqA.serviceTypeIDList = this.request.serviceTypeIDList;
    reqA.accountingStatusGUIDList = this.request.accountingStatusGUIDList;
    reqA.includeDisabledTask = this.request.includeDisabledTask;
    let arrTaskResponse = this._tripLegService.getTaskListBygIdTaskType<ResponseModel<any>>(reqA);

    forkJoin([depTaskResponse, arrTaskResponse]).subscribe(responses => {
      //departure services
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          leg.departureServiceList = [];
          responses[0].model.forEach(e => {
            let service = new LinkMessageServiceList();
            service.serviceTypeID = e.serviceTypeID;
            service.serviceTypeDescription = e.serviceTypeDescription;
            service.serviceLocation = e.serviceLocation;
            service.groundStopGUID = e.groundStopGUID;
            service.groundStopTaskGUID = e.groundStopTaskGUID;
            service.countryName = e.countryName;
            service.confirmation = e.fpiConfirmationReference;
            service.vendorName = e.vendorName;
            service.taskStatusColor = e.taskStatusFontColor;
            service.selected = this.messageLinkList?.findIndex(l=>l.leg == leg.departure+'-'+leg.arrival && l.groundStopTaskGUID == service.groundStopTaskGUID)>=0;
            leg.departureServiceList.push(service);
            if(service.selected)
              this.selectedLinkList.push(service);
          });
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      //arrival servicecs
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          leg.arrivalServiceList = [];
          responses[1].model.forEach(e => {
            let service = new LinkMessageServiceList();
            service.serviceTypeID = e.serviceTypeID;
            service.serviceTypeDescription = e.serviceTypeDescription;
            service.serviceLocation = e.serviceLocation;
            service.groundStopGUID = e.groundStopGUID;
            service.groundStopTaskGUID = e.groundStopTaskGUID;
            service.countryName = e.countryName;
            service.confirmation = e.fpiConfirmationReference;
            service.vendorName = e.vendorName;
            service.taskStatusColor = e.taskStatusFontColor;
            service.selected = this.messageLinkList?.findIndex(l=> l.leg == leg.departure+'-'+leg.arrival && l.groundStopTaskGUID == service.groundStopTaskGUID)>=0;
            leg.arrivalServiceList.push(service);
            if(service.selected)
              this.selectedLinkList.push(service);
          });
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.loadingServices = false;
      this.loadingService = false;
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

}

@Component({
  selector: 'success-component',
  template: `<div class="small d-flex align-items-center">
    <i class= "material-icons">check_circle</i>
      <div>{{data}} Selections Saved</div>
</div>`,
  styles: [],
})
export class SuccessComponent { constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { } }

