import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CustomerGroupService } from '../services/customer-group-service';
import { CustomerGroupAuditModel } from '../models/customer-group-audit.model';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-account-group-audit-dialog',
  templateUrl: './account-group-audit-dialog.component.html',
  styleUrls: ['./account-group-audit-dialog.component.css']
})

export class AccountGroupAuditDialogComponent implements OnInit {
  customerGroupGUID: string;
  customerGroupName: string;
  account_group_audit_form: UntypedFormGroup;
  
  errMsg: string;
  msg: string;

  showSpin: boolean = false;

  v: number = this._authService.getCurrentTimeNumber();

  recordList: CustomerGroupAuditModel[];

  displayedColumn: string[] = ['transactionDate', 'changeRequestType', 'changeRequest'];
  grantAccessToManageAccountGrouping: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<AccountGroupAuditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _customerGroupService: CustomerGroupService, private readonly _dialogService: DialogService,) {
    this.customerGroupGUID = _data.customerGroupGUID;

  }

  getAllData(): Observable<any[]> {

    let request = new CustomerGroupModel();
    request.customerGroupGUID = this.customerGroupGUID
    let getClientGrpResponse = this._customerGroupService.getCustomerGroupBycgrpId<ResponseModel<CustomerGroupModel>>(request);
    return forkJoin([getClientGrpResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToManageAccountGrouping = false;
    if (localStorage.getItem('up.mGrp') != null && localStorage.getItem('up.mGrp') == 'true') {
      this.grantAccessToManageAccountGrouping = true;
    }

    if (!this.grantAccessToManageAccountGrouping) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let obj = new CustomerGroupModel();
          obj = responses[0].model;
          this.customerGroupName = obj.customerGroupName;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.getAudit();
      this.showSpin = false;

    });

    

  }

  initControls() {
    this.account_group_audit_form = this._formBuilder.group({

    });


  }

  get f() { return this.account_group_audit_form.controls; }

  getAudit() {
    let obj = new CustomerGroupModel();
    obj.customerGroupGUID = this.customerGroupGUID
    
    this._customerGroupService.getCustomerGroupAuditBycgrpId<ResponseModel<CustomerGroupAuditModel[]>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
        }
        else {
          this.msg = "No data returned";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.showSpin = false;
    })
  }

  

  clickClose() {
    this._dialogRef.close();
  }








}
