import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType, HttpClientModule, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { GlobalConstant } from "../common-utility/global-constant";
import { CustomerGroupModel } from "../models/customer-group.model";
import { CustomerModel } from "../models/customer.model";

@Injectable()
export class CustomerGroupService {
  constructor(private readonly http: HttpClient) {

  }

  getAvailableCustomerListBycgrpId<T>(request: CustomerGroupModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "CustomerGroup/getAvailableCustomerListBycgrpId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  saveCustomerGroup<T>(request: CustomerGroupModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "CustomerGroup/saveCustomerGroup", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  checkCustomerGroupName<T>(request: CustomerGroupModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "CustomerGroup/checkCustomerGroupName", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  getAllCustomerGroup<T>(request: CustomerGroupModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "CustomerGroup/getAllCustomerGroup", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getCustomerGroupBycgrpId<T>(request: CustomerGroupModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "CustomerGroup/getCustomerGroupBycgrpId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  addCustomerToCustomerGroup<T>(request: CustomerGroupModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "CustomerGroup/addCustomerToCustomerGroup", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  removeCustomerFromCustomerGroup<T>(request: CustomerGroupModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "CustomerGroup/removeCustomerFromCustomerGroup", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getSelectedCustomerListBycgrpId<T>(request: CustomerGroupModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "CustomerGroup/getSelectedCustomerListBycgrpId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getCustomerGroupAuditBycgrpId<T>(request: CustomerGroupModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "CustomerGroup/getCustomerGroupAuditBycgrpId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getCustomerGroupListBycId<T>(request: CustomerGroupModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "CustomerGroup/getCustomerGroupListBycId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getCustomerWithGroupingBycId<T>(cId: string): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "CustomerGroup/getCustomerWithGroupingBycId?cId=" + cId, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }


}
