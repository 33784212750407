<div style="position:relative; background-color:#f8f8f8;height:100%">
  <div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="display: inline-flex; justify-content: space-between; width:100%">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>Operator / Owner/Lessee</h5></div>
    <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display:inline-block; white-space:pre-wrap;margin-top:.3125em;margin-left:.5em"></div>
  </div>
  <div style="margin-top:0em">
    <form *ngIf="aircraft_owner_operator_edit_form" [formGroup]="aircraft_owner_operator_edit_form" (ngSubmit)="clickSave()">
      <div style="margin-left: .5em;margin-top:0em;margin-right:0.5em;">

        <div style="margin-top:0em;background-color:white;padding-left:0.5em;padding-right:0.5em;padding-bottom:.5em">


          <div style="display:inline-flex">
            <div>
              <div class="form-group" style="margin-top:0em;margin-right:0.625em;">

                <label for="owner_operator_type" style="font-size:small">Type</label>
                <div style="margin-top:-0.35em;">
                  <select *ngIf="f" formControlName="owner_operator_type" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.owner_operator_type.errors }">
                    <option value="1">Owner/Lessee</option>
                    <option value="2">Operator</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.owner_operator_type.errors" class="alert alert-danger p-0 small " >
                  <div *ngIf="f.owner_operator_type.errors.required">Select operator or owner/lessee.</div>
                </div>
              </div>

            </div>
           

          </div>

          <div style="display:flex">
            <div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="company_name" style="font-size:small">Company Name</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="company_name" style="padding: 0em;width:12em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>
              <div style="font-size:small" class="small mt-1">
                <label>OR</label>
              </div>
              <div style="margin-top:-.65em; margin-right:0.625em">
                <label for="last_name" style="font-size:small">Last Name</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="last_name" style="padding: 0em;width:8em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="first_name" style="font-size:small">First Name</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="first_name" style="padding: 0em;width:8em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="middle_name" style="font-size:small">Middle Name</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="middle_name" style="padding: 0em;width:8em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>
              <div *ngIf="submitted && errMsg!=''" class="alert alert-danger p-0 small ">
                <div>Enter either company name or person name.</div>
              </div>
              
            </div>
            <div>

              <div style="margin-top:0em; margin-right:0.625em" >
                <label for="phone" style="font-size:small">Phone</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" style="padding: 0em;width:8em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>
              <div *ngIf="submitted && f.phone.errors" class="alert alert-danger p-0 small d-inline-block ">
                <div *ngIf="f.phone.errors?.required">Phone is required.</div>
                <div *ngIf="f.phone.errors?.pattern">Phone is invalid.</div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="fax" style="font-size:small">Fax</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="fax" style="padding: 0em;width:8em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>
              <div *ngIf="submitted && f.fax.errors" class="alert alert-danger p-0 small d-inline-block ">
                <div *ngIf="f.fax.errors?.pattern">Fax is invalid.</div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="email_address" style="font-size:small">Email</label>
                <div style="margin-top:-0.35em;">
                  <input type="email" *ngIf="f" formControlName="email_address" [ngClass]="{ 'is-invalid': submitted && f.email_address.errors }" style="padding: 0em;width:12em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>
              <div *ngIf="submitted && f.email_address.errors" class="alert alert-danger p-0 small d-inline-block">
                <div *ngIf="f.email_address.errors?.required">Email is required.</div>
                <div *ngIf="f.email_address.errors?.email">Email is invalid.</div>
              </div>
            </div>
            <div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="address1" style="font-size:small">Street Address 1</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="address1" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" style="padding: 0em;width:12em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>
              <div *ngIf="submitted && f.address1.errors" class="alert alert-danger p-0 small ">
                <div *ngIf="f.address1.errors.required">Street Address 1 is required.</div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="address2" style="font-size:small">Street Address 2</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="address2" style="padding: 0em;width:12em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="city" style="font-size:small">City</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" style="padding: 0em;width:12em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>
              <div *ngIf="submitted && f.city.errors" class="alert alert-danger p-0 small ">
                <div *ngIf="f.city.errors.required">Street Address 1 is required.</div>
              </div>
            </div>
            <div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="state_province" style="font-size:small">State/Province</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                  <input *ngIf="f.country_select.value !='6aa9b42f-a693-4d74-83d8-a1500b0f1133' && f.country_select.value !='0b740c4d-5200-4f28-9da1-eee5af7b2b63'" type="text" formControlName="state" class="form-control" maxlength="255" style="padding: 0em;width:12em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                  <select *ngIf="f && f.country_select.value =='6aa9b42f-a693-4d74-83d8-a1500b0f1133' || f.country_select.value == '0b740c4d-5200-4f28-9da1-eee5af7b2b63'" formControlName="state" class="form-control form-control-sm dropdown" style="width:12em">
                    <option></option>
                    <option *ngFor="let state of stateProvinceList" [ngValue]="state.stateProvinceAbbreviation">{{state.stateProvinceAbbreviation}}</option>
                  </select>
                </div>
              </div>
              <!--<div style="margin-top:0em; margin-right:0.625em">
                <label for="state" style="font-size:small">State/Province</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="state" maxlength="2" style="padding: 0em;width:12em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>-->
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="zip" style="font-size:small">Zip/Postal Code</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="zip" style="padding: 0em;width:12em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />
                </div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em;">
                <label for="country_select" style="font-size:small">Country</label>
                <div style="margin-top:-0.35em;">
                  <select *ngIf="f" formControlName="country_select" class="form-control form-control-sm dropdown" (change)="getStateList()" [ngClass]="{ 'is-invalid': submitted && f.country_select.errors }">
                    <option value="" selected></option>
                    <option *ngFor="let cty of countryList" [ngValue]="cty.countryGUID">{{cty.countryName}}</option>
                  </select>
                </div>
              </div>
              <div style="margin-top:-1em" *ngIf="submitted && f.country_select.errors" class="alert alert-danger p-0 small d-inline-block">
                <div *ngIf="f.country_select.errors?.required">Country is required.</div>
              </div>
            </div>
            <div *ngIf="aircraftOperatorOwnerLesseeID!=0" class="form-group" style="margin-left: 0em; margin-top:0em; margin-bottom:0em;width:10em">

              <div>
                <div class="form- group" style="margin-left: 0em">
                  <label for="record_status" style="font-size:small">Disable Record</label>
                  <div style="margin-top:-0.35em;">
                    <select *ngIf="f" formControlName="record_status" class="form-control form-control-sm" style="padding:0;font-size: small;background-color:#eaf0fd;height: 1.5625em;width:5em">
                      <option value="1" selected>No</option>
                      <option value="0">Yes</option>
                    </select>
                  </div>
                </div>
                <div class="form- group" style="margin-left: 0em">
                  <label for="modifiedBy" style="font-size:small">Modified by</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="modifiedBy" readonly class="form-control" style="padding-left:0em;width:10em;height:1.5625em;font-size:small" />
                  </div>
                </div>
                <div class="form-group" style="margin-left: 0em">
                  <label for="modifiedDate" style="font-size:small">Modified Date</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="modifiedDate" readonly class="form-control" style="padding-left: 0em; width: 10em; height: 1.5625em; font-size: small" />
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>


      </div>
    </form>

  </div>
  <div class="d-inline-flex" style="height:3em">
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;">
      <button *ngIf="userType=='internal'" mat-button class="btn-high mr-1" (click)="clickSave()">Save</button>
      <button mat-button *ngIf="aircraftOperatorOwnerLesseeID!=0 && userType=='internal'" class="btn-high mr-1" (click)="clickAddNew()">Add New Record</button>
      <button mat-button class="btn-low" (click)="clickClose()">Close</button>

    </div>
    <div class="ml-2 mt-2">
      <app-success-message [successMsg]="" *ngIf="showSuccessMsg"></app-success-message>
    </div>
  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>





