export class DocumentModel {
  documentGUID: string;
  documentType: string;
  documentName: string;       
  fileExtension: string;
  documentSizeInKB: number;
  fileSize: string;
  bFile: string;
  mimeType: string;
  documentCategory: string;
  selected: boolean;
  documentExpirationDate: string;
  isExpired: boolean;
  sortOrderNo: number;
  dataEntryStatusID: number;
  dataEntryStatusDescription: string;
  documentTypeDescription: string;
  documentTypeGUID: string;
  personGUID: string;
}
