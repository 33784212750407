import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CKEditorConfig } from '../ckeditor5/ckeditor-config';
import * as DecoupledEditor from '../ckeditor5/ckeditor';

@Component({
  selector: 'app-display-dialog',
  templateUrl: './display-dialog.component.html',
  styleUrls: ['./display-dialog.component.css']
})

export class DisplayDialogComponent  {
  public Editor = DecoupledEditor;

  public CKEConfig = CKEditorConfig.ckeditorConfigClient;

  public onReady(editor) {

    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()

    );
    const toolbarElement = editor.ui.view.toolbar.element;
    toolbarElement.style.display = 'none';
    editor.enableReadOnlyMode('a');
    editor.setData(this.displayText);
  }
  dialogTitle: string;
  displayText: string;

  constructor(private readonly _dialogRef: MatDialogRef<DisplayDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,

  ) {

    this.dialogTitle = _data.dialogTitle;
    this.displayText = _data.displayText;

  }


}
