import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { CommAddressTypeModel } from '../models/comm-address-type.model';
import { AddressRatingModel } from '../models/address-rating.model';
import { AddressUseModel } from '../models/address-use.model';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { PersonService } from '../services/person.service';
import { PersonRewardAccountsModel } from '../models/person-reward-accounts.model';
import { HotelModel } from '../models/hotel.model';
import { AirlineModel } from '../models/airline.model';
import { CarRentalModel } from '../models/car-rental.model';
import { HotelRewardsProgramModel } from '../models/hotel-rewards-program.model';
import { RewardStatusModel } from '../models/reward-status.model';
import { DataEntryStatusModel } from '../models/data-entry-status.model';

@Component({
  selector: 'app-person-reward-edit-dialog',
  templateUrl: './person-reward-edit-dialog.component.html',
  styleUrls: ['./person-reward-edit-dialog.component.css']
})

export class PersonRewardEditDialogComponent implements OnInit, AfterViewInit {
  //@Input() dialogTitle: string;
  //@Input() personRewardAccountsGUID: string;
  //@Input() personId: string;
  dialogTitle: string;
  personRewardAccountsGUID: string;
  personId: string;
  //@Input() customerGUID: string;
  person_reward_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = false;  
  rewardAcctNum: string;
  selectedHotelRewardsProgramGUID: string;
  selectedAirlineGUID: string;
  selectedCarRentalGUID: string;
  selectedRewardType: string;
  hotelList: HotelRewardsProgramModel[];
  airlineList: AirlineModel[];
  carRentalList: CarRentalModel[];
  rewardStatusList: RewardStatusModel;
  selectedRewardStatusId: number;
  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  isPreferred: number;
  //displayedColumn: string[] = ['addressUse'];
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  upPII: boolean = false;
  grantAccessToManageManifestProfiles: boolean;
  userType: string;
  readonly: boolean;
  dataEntryStatusList: DataEntryStatusModel[];
  selectedDataEntryStatusID: number;
  customerGUID: string;
  constructor(private readonly _dialogRef: MatDialogRef<PersonRewardEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _personService: PersonService,
    private readonly _dialogService: DialogService
  ) {
    this.personRewardAccountsGUID = _data.personRewardAccountsGUID;
    if (_data.personId == null) {
      this.personId = "";
    }
    else {
      this.personId = _data.personId;
    }
    this.dialogTitle = _data.dialogTitle;
    this.customerGUID = _data.customerGUID;

  }

  getAllData(): Observable<any[]> {
    let getHotelResponse = this._commonService.getHotelRewardsProgramList<ResponseModel<HotelRewardsProgramModel[]>>();
    let getAirlineResponse = this._commonService.getAirlineList<ResponseModel<AirlineModel[]>>();
    let getCarRentalResponse = this._commonService.getCarRentalList<ResponseModel<CarRentalModel[]>>();
    let getRewardStatusResponse = this._commonService.getRewardStatusList<ResponseModel<RewardStatusModel[]>>();
    let getPersonRwdResponse;
    if (this.personRewardAccountsGUID != null && this.personRewardAccountsGUID != "") {
      getPersonRwdResponse = this._personService.getCustomerPersonRewardAccountsByprwdId(this.personRewardAccountsGUID);
    }
    else
      getPersonRwdResponse = of(null);
    let dataEntryStatusResponse;
    if (this.userType == "internal") {
      dataEntryStatusResponse = this._commonService.getDataEntryStatusList<ResponseModel<DataEntryStatusModel[]>>();
    }
    else {
      dataEntryStatusResponse = of(null);
    }
    return forkJoin([getHotelResponse, getAirlineResponse, getCarRentalResponse, getRewardStatusResponse, getPersonRwdResponse, dataEntryStatusResponse]);
    //if (this.personRewardAccountsGUID != null && this.personRewardAccountsGUID != "") {
    //  let getPersonRwdResponse = this._personService.getCustomerPersonRewardAccountsByprwdId(this.personRewardAccountsGUID);
    //  return forkJoin([getHotelResponse, getAirlineResponse, getCarRentalResponse, getRewardStatusResponse, getPersonRwdResponse]);
    //}
    //else
    //  return forkJoin([getHotelResponse, getAirlineResponse, getCarRentalResponse, getRewardStatusResponse]);
  }

  ngOnInit() {
    
    this._authService.updateAccessTime();
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }
    if (!this.upPII || !this.grantAccessToManageManifestProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
    this.hotelList = [];
    this.airlineList = [];
    this.carRentalList = [];
    this.selectedAirlineGUID = "";
    this.selectedCarRentalGUID = "";
    this.selectedHotelRewardsProgramGUID = "";
    this.rewardAcctNum = "";
    this.selectedRewardType = "hotel";
    
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.showSuccessMsg = false;
    this.dataEntryStatusList=[];
    this.selectedDataEntryStatusID=0;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType == 'customer' && this.personRewardAccountsGUID != '') {
      this.readonly = true;
    }
    else {
      this.readonly = false;
    }
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.hotelList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.airlineList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.carRentalList = responses[2].model;         
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.rewardStatusList = responses[3].model;
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.personRewardAccountsGUID != null && this.personRewardAccountsGUID != "") {
        if (responses[4] != null) {
          if (responses[4].code == "200" && responses[4].message == "") {
            let rwd = new PersonRewardAccountsModel();
            rwd = responses[4].model;
            this.selectedRewardType = rwd.rewardType;
            if (rwd.airlineGUID!="")
              this.selectedAirlineGUID = rwd.airlineGUID.toLowerCase();
            if (rwd.carRentalGUID!="")
              this.selectedCarRentalGUID = rwd.carRentalGUID.toLowerCase();
            if (rwd.hotelRewardsProgramGUID != "")
              this.selectedHotelRewardsProgramGUID = rwd.hotelRewardsProgramGUID.toLowerCase();
            this.rewardAcctNum = rwd.rewardAcctNum;
            this.selectedRewardStatusId = rwd.rewardStatusID;
            if (rwd.isPreferred == true) 
              this.isPreferred = 1;            
            else
              this.isPreferred = 0;
            this.modifiedBy = rwd.modifiedBy;
            this.modifiedDate = rwd.modifiedDate;
            if (rwd.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
            this.selectedDataEntryStatusID = rwd.dataEntryStatusID;
          }
          else {
            if (responses[4].code == "401") {
              this._authService.signOut();
            }
          }
        }
        
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          this.dataEntryStatusList = responses[5].model;

        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.setControlStatus();

      this.showSpin = false;

      
    });

  }

  ngAfterViewInit() {

  }

  initControls() {
    this.person_reward_edit_form = this._formBuilder.group({
      reward_type_select: [this.selectedRewardType, Validators.required], 
      hotel_select: this.selectedHotelRewardsProgramGUID,
      airline_select: this.selectedAirlineGUID,
      car_rental_select: this.selectedCarRentalGUID, 
      reward_status: this.isActive,
      reward_acct_num: [this.rewardAcctNum, Validators.required],
      reward_status_desc: this.selectedRewardStatusId,
      preferred:this.isPreferred,
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      data_entry_status_select: [this.selectedDataEntryStatusID],
    });

  }

  setControlStatus() {
    if (this.userType == 'customer' && this.personRewardAccountsGUID != '') {
      this.person_reward_edit_form.get('reward_type_select').disable();
      this.person_reward_edit_form.get('hotel_select').disable();
      this.person_reward_edit_form.get('airline_select').disable();
      this.person_reward_edit_form.get('car_rental_select').disable();
      this.person_reward_edit_form.get('reward_status').disable();
      this.person_reward_edit_form.get('reward_acct_num').disable();
      this.person_reward_edit_form.get('reward_status_desc').disable();
      this.person_reward_edit_form.get('preferred').disable();
    }
    else {
      this.person_reward_edit_form.get('reward_type_select').enable();
      this.person_reward_edit_form.get('hotel_select').enable();
      this.person_reward_edit_form.get('airline_select').enable();
      this.person_reward_edit_form.get('car_rental_select').enable();
      this.person_reward_edit_form.get('reward_status').enable();
      this.person_reward_edit_form.get('reward_acct_num').enable();
      this.person_reward_edit_form.get('reward_status_desc').enable();
      this.person_reward_edit_form.get('preferred').enable();
    }
  }

  get f() { return this.person_reward_edit_form.controls; }

  rewardTypeChange(e: any) {
    this.errMsg = "";
    this.selectedRewardType = e.target.value;
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";
    if (this.loading == true) {
      return;
    }
    switch (this.f.reward_type_select.value) {
      case "hotel":
        if (this.f.hotel_select.value == "") {
          //this.errMsg = "Select a hotel";
          this.f.hotel_select.setErrors({ required: true });
        }
        break;
      case "airline":
        if (this.f.airline_select.value == "") {
          //this.errMsg = "Select an airline";
          this.f.airline_select.setErrors({ required: true });
        }
        break;
      case "carRental":
        if (this.f.car_rental_select.value == "") {
          //this.errMsg = "Select a car rental";
          this.f.car_rental_select.setErrors({ required: true });
        }
        break;
    }
    
    if (this.person_reward_edit_form.invalid || this.errMsg != "") {
      return;
    }
    
    this.loading = true;
    let obj = new PersonRewardAccountsModel();
    obj.personRewardAccountsGUID = "";
    if (this.personRewardAccountsGUID != "" && this.personRewardAccountsGUID != null)
      obj.personRewardAccountsGUID = this.personRewardAccountsGUID;
    obj.airlineGUID = "";
    obj.carRentalGUID = "";
    obj.hotelRewardsProgramGUID = "";
    switch (this.f.reward_type_select.value) {
      case "hotel":
        obj.hotelRewardsProgramGUID = this.f.hotel_select.value;
        break;
      case "airline":
        obj.airlineGUID = this.f.airline_select.value;
        break;
      case "carRental":
        obj.carRentalGUID = this.f.car_rental_select.value;
        break;
    }  
    obj.rewardAcctNum = this.f.reward_acct_num.value;
    obj.rewardStatusID = 0;
    if (this.f.reward_status_desc.value!=null)
      obj.rewardStatusID = this.f.reward_status_desc.value;
    obj.isPreferred = false;
    if (this.f.preferred.value == "1")
      obj.isPreferred = true;
    else
      obj.isPreferred = false;
    obj.isActive = true;
    if (obj.personRewardAccountsGUID != "") {
      if (this.f.reward_status.value == "1")
        obj.isActive = true;
      else
        obj.isActive = false;
    }
    obj.personGUID = this.personId;
    obj.dataEntryStatusID = 0;
    obj.customerGUID = this.customerGUID;
    if (this.userType == 'internal')
      obj.dataEntryStatusID = Number(this.f.data_entry_status_select.value);
    this._personService.savePersonRewardAccounts<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.personRewardAccountsGUID = response.model;
          if (this.userType == 'internal' && obj.personRewardAccountsGUID == "" && this.personRewardAccountsGUID != "") {
            this.selectedDataEntryStatusID = 2;
            this.f.data_entry_status_select.setValue(this.selectedDataEntryStatusID);
          }
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          //if (obj.personRewardAccountsGUID == "")
          //  this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          //else
          //  this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          this.isModified = true;
         
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          if (response.message != "")
            this.errMsg = response.message
          else
            this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.selectedAirlineGUID = "";
    this.selectedCarRentalGUID = "";
    this.selectedHotelRewardsProgramGUID = "";
    this.rewardAcctNum = "";
    this.selectedRewardType = "hotel";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.personRewardAccountsGUID = "";
    this.dialogTitle = "ADD";
    this.initControls();
    
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }


}
