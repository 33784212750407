import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as atlas from 'azure-maps-control';
import { AuthenticationType } from 'azure-maps-control';
import { IconLegendDialogComponent } from '../common-utility/icon-legend-dialog.component';
import { FlightFollowingClientDialogComponent } from '../ground-stop-advanced-client/flight-following-client-dialog.component';
import { GroundStopTaskClientDialogComponent } from '../ground-stop-advanced-client/ground-stop-task-client-dialog.component';
import { FlightFollowingEditDialogComponent } from '../ground-stop-advanced/flight-following-edit-dialog.component';
import { GroundStopTaskEditDialogComponent } from '../ground-stop-advanced/ground-stop-task-edit-dialog.component';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { ResponseModel } from '../models/response.model';
import { TripLegModel } from '../models/trip-leg.model';
import { TripModel } from '../models/trip.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerService } from '../services/customer.service';
import { GroundStopService } from '../services/ground-stop.service';
import { TripLegService } from '../services/trip-leg.service';
import { Observable, forkJoin } from 'rxjs';
import { TripPersonEditorDialogComponent } from '../trip-legs/trip-person-editor-dialog.component';
import { FuelTaskEditDialogComponent } from '../fuel-quote/fuel-task-edit-dialog.component';
import { OverFlightPermitTaskComponent } from '../ground-stop-advanced/overflight-permit-task.component';
import { LandingPermitPrivateTaskComponent } from '../ground-stop-advanced/landing-permit-private-task.component';
import { SlotArrivalTaskComponent } from '../ground-stop-advanced/slot-arrival-task.component';
import { SlotDepartureTaskComponent } from '../ground-stop-advanced/slot-departure-task.component';
import { LandingPermitCharterTaskComponent } from '../ground-stop-advanced/landing-permit-charter-task.component';
import { InternalGroundStopTaskEditDialogComponent } from '../ground-stop-advanced/internal-ground-stop-task-edit-dialog.component';
import { InternalPublicGroundStopTaskEditDialogComponent } from '../ground-stop-advanced/internal-public-ground-stop-task-edit-dialog.component';
import { FlightPlanTaskComponent } from '../ground-stop-advanced/flight-plan-task.component';

@Component({
  selector: 'app-checklist-dashboard-dialog',
  templateUrl: './checklist-dashboard-dialog.component.html',
  styleUrls: ['./checklist-dashboard-dialog.component.css']
})

export class ChecklistDashboardDialogComponent implements OnInit {
  @Input() leg: TripLegModel;
  groundStopList: GroundStopModel[];
  legList: TripLegModel;
  departureGS: GroundStopModel;
  arrivalGS: GroundStopModel;
  departureServiceList: GroundStopTaskModel[];
  arrivalServiceList: GroundStopTaskModel[];
  showSpin: boolean = false;
  title: string;
  tripCode: string;
  tripLeg: string;
  points: any[];
  crewList: GroundStopManifestModel[];
  paxList: GroundStopManifestModel[];
  grantAccessToManageManifestProfiles: boolean;
  grantAccessToVendorProfiles: boolean;
  upPII: boolean = false;
  /** company-event-dialog ctor */
  constructor(private readonly _dialogRef: MatDialogRef<ChecklistDashboardDialogComponent>, private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _customerService: CustomerService, private readonly _dialog: MatDialog, private readonly _groundStopService: GroundStopService, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _tripLegService: TripLegService) {
    this.leg = _data.leg;
  }

  ngOnInit() {
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
      return;
    }
    else {
      this.upPII = true;
    }

    this._authService.updateAccessTime();

    this.grantAccessToManageManifestProfiles = false;
    this.grantAccessToVendorProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    if (localStorage.getItem('up.vPro') == 'true') {
      this.grantAccessToVendorProfiles = true;
    }
    this.getData();

  }


  getAllData(): Observable<any[]> {
    let req = new TripLegModel();
    req.groundStopGUID = this.leg.groundStopGUID;
    req.nextArrivalGroundStopGUID = this.leg.nextArrivalGroundStopGUID;
    req.aircraftGUID = this.leg.aircraftGUID
    let legResponse = this._tripLegService.getTripLegBydgIdagId(req);
    let reqD = new GroundStopModel();
    reqD.groundStopGUID = this.leg.groundStopGUID;
    reqD.taskType = "D";
    //reqD.tripTaskStatusGUIDList = this.request.tripTaskStatusGUIDList;
    //reqD.serviceTypeIDList = this.request.serviceTypeIDList;
    //reqD.accountingStatusGUIDList = this.request.accountingStatusGUIDList;
    reqD.includeDisabledTask = false;
    let depTaskResponse;
    depTaskResponse = this._tripLegService.getTaskListBygIdTaskType(reqD);

    let reqA = new GroundStopModel();
    reqA.groundStopGUID = this.leg.nextArrivalGroundStopGUID;
    reqA.taskType = "A";
    //reqA.tripTaskStatusGUIDList = this.request.tripTaskStatusGUIDList;
    //reqA.serviceTypeIDList = this.request.serviceTypeIDList;
    //reqA.accountingStatusGUIDList = this.request.accountingStatusGUIDList;
    reqA.includeDisabledTask = false;
    let arrTaskResponse;
    arrTaskResponse = this._tripLegService.getTaskListBygIdTaskType(reqA);
    return forkJoin([legResponse, depTaskResponse, arrTaskResponse]);

  }


  getData() {
    this.showSpin = true;
    //let request = new TripModel();
    //request.customerGUID = this.leg.customerGUID;
    ////if (this.userType == "internal") {
    ////  this.request.customerGUID = this.customerGUID;
    ////}
    //request.aircraftGUID = this.leg.aircraftGUID;    
    //request.tripCodeGUID = this.leg.tripCodeGUID;
    //request.groundStopTypeGUIDList = "868a62b1-9cd6-499b-abfa-46542ccb7424,a2c7bf22-d054-4564-949b-f0d55d1f6b10,9523bc15-7e5f-4022-b5c6-574f7ea63649";
    //request.tripTaskStatusGUIDList = "";
    //request.serviceTypeIDList = "";//Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
    ////this.request.tripAlertFilter = "";//this.f.trip_alert_filter.value;
    //this.request.accountingStatusGUIDList = Array.prototype.map.call(this.selectedAccountingStatusList, s => s.accountingStatusGUID).toString();
    //this.request.pastHours = this.auditHr;
    //request.includeDisabledTask = false;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {

          this.legList = responses[0].model;

        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.departureServiceList = responses[1].model;
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.arrivalServiceList = responses[2].model;
        }
      }
      this.createMap();
      this.showSpin = false;
    });




  }


  clickPersonName(e: any, itemLeg: TripLegModel, item: GroundStopManifestModel) {
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "pId": item.personGUID,
    //    "selectedPersonClassDescription": item.personClassDescription,
    //    "selectedPersonClassGUID": item.personClassGUID,
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    //this._router.navigate(['/persons/customer-person-details'], navigationExtras);
    e.stopPropagation();
    if (item.capacityID != 1) {
      return;
    }


    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    let personType: string = "";
    personType = item.groundStopManifestTypeDescription;
    personType = personType.replace("Departure ", "");
    personType = personType.replace("Arrival ", "");
    let alreadyHaveCrewOnTrip: boolean = false;
    let alreadyHavePaxOnTrip: boolean = false;
    if (personType != 'PAX') {
      alreadyHaveCrewOnTrip = true;
    }
    else {
      alreadyHavePaxOnTrip = true;
    }
    dialogConfig.data = {
      tripCodeGUID: itemLeg.tripCodeGUID, customerGUID: itemLeg.customerGUID, personGUID: item.personGUID, groundStopGUID: itemLeg.groundStopGUID, nextArrivalGroundStopGUID: itemLeg.nextArrivalGroundStopGUID,
      departureCountryGUID: itemLeg.departureCountryGUID, personType: personType, actionType: 'Edit', v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripPersonEditorDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        this.getData();
      }
    });



  }

  createMap() {
    let map = new atlas.Map('map1', {
      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      map.sources.add(dataSource);
      var zoomControl = new atlas.control.ZoomControl();

      /* Add the zoom control to the map*/
      map.controls.add(zoomControl, {
        position: atlas.ControlPosition.TopRight
      });

      var startPosition = [self.leg.departureLongitude, self.leg.departureLatitude];
      var endPosition = [self.leg.nextArrivalLongitude, self.leg.nextArrivalLatitude];
      var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
        title: self.leg.departureICAO,
        name: self.leg.departureAirportName
      });

      var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
        title: self.leg.nextArrivalICAO,
        name: self.leg.nextArrivalAirportName
      });

      self.points.push(startPosition);
      self.points.push(endPosition);
      var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
      var poly = new atlas.data.LineString(path);
      dataSource.add([poly, startPoint, endPoint]);

      var popupTemplate = "<div class='popup'>{name}</div>";
      var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
        iconOptions: {
          image: 'pin-round-darkblue',
          cluster: false,
          optional: true,
          allowOverlap: true,
          size: .5
        },
        textOptions: {
          textField: ['get', 'title'],
          offset: [0, -1.5],
          cluster: false,
          allowOverlap: true,
          size: 10
        },
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      });
      map.layers.add([new atlas.layer.LineLayer(dataSource, null, {
        strokeColor: 'black',
        strokeWidth: 1.5
      }),
        symbolLayer
      ]);
      var popup = new atlas.Popup({
        pixelOffset: [0, -18],
        closeButton: false
      });
      //Add a hover event to the symbol layer.
      map.events.add('mouseover', symbolLayer, function (e) {
        //Make sure that the point exists.
        if (e.shapes && e.shapes.length > 0) {
          var pos = e.position;
          var offset = [0, 0];
          var properties;
          var content, coordinate;

          if (e.shapes[0] instanceof atlas.Shape) {
            properties = e.shapes[0].getProperties();

            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].getType() === 'Point') {
              coordinate = e.shapes[0].getCoordinates();
              content = popupTemplate.replace(/{name}/g, properties.name);
              offset = [0, -18];
            }
          } else {
            properties = e.shapes[0].properties;
            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].type === 'Point') {
              coordinate = e.shapes[0].geometry.coordinates;
              content = popupTemplate.replace(/{name}/g, properties.name);
              offset = [0, -18];
            }
          }
          popup.setOptions({
            //Update the content of the popup.
            content: content,

            //Update the popup's position with the symbol's coordinate.
            position: coordinate

          });
          //Open the popup.
          popup.open(map);
        }
      });

      map.events.add('mouseleave', symbolLayer, function () {
        popup.close();
      });

      var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

      map.setCamera({
        bounds: bbox,
        padding: 40
      });


    });
  }

  clickServiceType(e: any, gsItem: TripLegModel, gstItem: GroundStopTaskModel, isDeparture: boolean) {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "54em";
    let dialogHeight = "";
    if (gstItem.serviceTypeID == 1 || gstItem.vendorRequired == true || gstItem.serviceTypeID == 35 || gstItem.serviceTypeID == 3
      || gstItem.serviceTypeID == 7 || gstItem.serviceTypeID == 20 || gstItem.serviceTypeID == 56) {

      dialogConfig.minHeight = "39.2em";
    }
    else {
      dialogConfig.minHeight = "37em";
    }
    dialogConfig.disableClose = true;
    let leg: string = "";
    leg = gsItem.departureICAO + "-" + gsItem.nextArrivalICAO;
    let gsGUID;
    let icao;
    let countryId;
    if (isDeparture) {
      gsGUID = gsItem.groundStopGUID;
      icao = gsItem.departureICAO;
      countryId = gsItem.departureCountryGUID;
    }
    else {
      gsGUID = gsItem.nextArrivalGroundStopGUID;
      icao = gsItem.nextArrivalICAO;
      countryId = gsItem.nextArrivalCountryGUID;
    }
    dialogConfig.data = {
      groundStopGUID: gsGUID, tripCodeGUID: gsItem.tripCodeGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID,
      serviceTypeID: gstItem.serviceTypeID, vendorReq: gstItem.vendorRequired, countryId: countryId, icao: icao,
      serviceTypeDesc: gstItem.serviceTypeDescription, v: this._authService.getCurrentTimeNumber(),
      customerGUID: gsItem.customerGUID, leg: leg, nextGroundStopGUID: gsItem.nextArrivalGroundStopGUID
    };

    //if (gstItem.serviceTypeID != 69 && gstItem.serviceTypeID != 70 && gstItem.serviceTypeID != 44 && gstItem.serviceTypeID != 45 && gstItem.serviceTypeID != 46
    //  && gstItem.serviceTypeID != 48 && gstItem.serviceTypeID != 49 && gstItem.serviceTypeID != 60 && gstItem.serviceTypeID != 61 && gstItem.serviceTypeID != 62) {

    let dialogRef;

    switch (gstItem.formType) {
      case 1: //ops legacy
        dialogRef = this._dialog.open(GroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 2: //OpsOverflightPermitForm
        dialogRef = this._dialog.open(OverFlightPermitTaskComponent, dialogConfig);
        break;
      case 3: //OpsSlotForm
        dialogRef = this._dialog.open(SlotArrivalTaskComponent, dialogConfig);
        break;
      case 4: //InternalOnly
        dialogRef = this._dialog.open(InternalGroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 5: //InternalPublic
        dialogRef = this._dialog.open(InternalPublicGroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 7: //FuelTaskForm
        dialogRef = this._dialog.open(FuelTaskEditDialogComponent, dialogConfig);
        break;
      case 8: //landing
        dialogRef = this._dialog.open(LandingPermitPrivateTaskComponent, dialogConfig);
        break;
      case 9: //flight plan
        dialogRef = this._dialog.open(FlightPlanTaskComponent, dialogConfig);
        break;
      default:
        dialogRef = this._dialog.open(GroundStopTaskEditDialogComponent, dialogConfig);
        break;
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });



  }

  clickClose() {
    this._dialogRef.close();
  }


  openTrip() {
    window.open('/ground-stops/trip-details-internal?tripId=' + this.leg.tripCodeGUID + '&aircraftId=' + this.leg.aircraftGUID + '&customerId=' + this.leg.customerGUID + '&pf=&tType=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }

  openLegend() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    let legendType: string = "legStatus";
    dialogConfig.data = {
      dialogTitle: s, legendType: legendType
    };

    const dialogRef = this._dialog.open(IconLegendDialogComponent, dialogConfig);

  }
}
