<div *ngIf="upPII" style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3125em;"><h5>Audit Comments</h5></div>
  </div>
  <div>
    <form *ngIf="trip_audit_user_comment_form" [formGroup]="trip_audit_user_comment_form">
      <div style="padding-left: 1em; padding-right:1em">
        <div style="background-color: white; padding-left: 1em; padding-right:1em;">
          <div>
            <label for="userComments" style="font-size:small; font-weight: bold">User comments</label>
            <div style="margin-left: 0em; margin-top:-0.5em">
              <textarea formControlName="userComments" style="width:37em; height:12em;font-family:Courier New, Courier, monospace; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd; border-radius:0.25em" (change)="userCommentsChange($event)">   
           </textarea>
            </div>
          </div>

        </div>
      </div>
</form>  
  </div>
  <div style="display: flex">
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.75em; padding-bottom:0.5em; margin-right:1em; height:3em">
      <button class="btn-high" mat-button [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Save</div></div></button>&nbsp;
      <button class="btn-low" mat-button (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
    </div>
    <div *ngIf="errMsg!=''" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;"><label style="color:black; font-size: small">{{errMsg}}</label></div>
    <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
  </div>

  </div>
