<div style="background-color:#f8f8f8">
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5 class="mat-h2">Vendor Hours - {{dialogTitle}}</h5></div>

  </div>

  <div>
    <form *ngIf="vendor_hours_edit_form" [formGroup]="vendor_hours_edit_form" novalidate>
      <div style=" margin-top:0em; padding-left: 1em;padding-right:1em;">
        <div style="margin-top:0em;padding-left:0.5em;padding-right:0.5em;padding-bottom:1em;">          
          <!--<div style="background-color: white;padding:.5em;">
            <div style="display:flex">

              <div>
                <label for="effectiveDate" style="font-size:small">Effective Date</label>
                <div style="display:flex;margin-top:-0.35em">
                  <input type="text" [matDatepicker]="effectivedatepicker" tabindex="4" placeholder="MM/DD/YYYY" formControlName="effectiveDate" #effectiveDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.effectiveDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                  <mat-datepicker-toggle matSuffix [for]="effectivedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                  <mat-datepicker #effectivedatepicker></mat-datepicker>
                </div>
              </div>
              <div class="ml-2">
                <label for="expirationDate" style="font-size:small">Expiration Date</label>
                <div style="display:flex;margin-top:-0.35em">
                  <input type="text" [matDatepicker]="expirationdatepicker" tabindex="5" placeholder="MM/DD/YYYY" formControlName="expirationDate" #expirationDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.expirationDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                  <mat-datepicker-toggle matSuffix [for]="expirationdatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                  <mat-datepicker #expirationdatepicker></mat-datepicker>
                </div>

              </div>
              <div class="ml-2">
                <label for="hours_type" style="font-size:small">Hours Type</label>
                <div style="margin-top:-0.35em;">
                  <select *ngIf="f" formControlName="hours_type" [ngClass]="{ 'is-invalid': submitted && f.hours_type.errors }" class="form-control form-control-sm dropdown">
                    <option value="" selected></option>
                    <option *ngFor="let hours of hoursTypeList" [ngValue]="hours.hoursTypeID">{{hours.hoursTypeDescription}}</option>
                  </select>

                </div>
              </div>

            </div>
            <div>
              <mat-slide-toggle [checked]="isUTC" formControlName="isUTC">UTC</mat-slide-toggle>
            </div>-->
            <!--<div>
              <mat-checkbox formControlName="isOvertime">Overtime Available</mat-checkbox>
              <mat-checkbox formControlName="isSeasonal">Seasonal</mat-checkbox>
            </div>-->
            
          <!--</div>-->
            <div style="background-color:white;margin-top:.5em;padding:.5em;">
              <div class="d-inline-flex">
                <div>
                  <div>
                    <label for="hours_type" style="font-size:small">Hours Type</label>
                    <div style="margin-top:-0.35em;">
                      <select style="width:max-content" required *ngIf="f" formControlName="hours_type" [ngClass]="{ 'is-invalid': submitted && f.hours_type.errors }" class="form-control form-control-sm dropdown">
                        <option *ngFor="let hours of hoursTypeList" [ngValue]="hours.hoursTypeID">{{hours.hoursTypeDescription}}</option>
                      </select>

                    </div>
                  </div>
                  <div>
                    <label for="effectiveDate" style="font-size:small">Effective Date</label>
                    <div style="display:flex;margin-top:-0.35em">
                      <input type="text" required [matDatepicker]="effectivedatepicker" tabindex="4" placeholder="MM/DD/YYYY" formControlName="effectiveDate" #effectiveDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.effectiveDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                      <mat-datepicker-toggle matSuffix [for]="effectivedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                      <mat-datepicker #effectivedatepicker></mat-datepicker>
                    </div>
                  </div>
                  <div>
                    <label for="expirationDate" style="font-size:small">Expiration Date</label>
                    <div style="display:flex;margin-top:-0.35em">
                      <input type="text" [matDatepicker]="expirationdatepicker" tabindex="5" placeholder="MM/DD/YYYY" formControlName="expirationDate" #expirationDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.expirationDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                      <mat-datepicker-toggle matSuffix [for]="expirationdatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                      <mat-datepicker #expirationdatepicker></mat-datepicker>
                    </div>

                  </div>
                  <div class="d-flex flex-column" style="width:12em;margin-left:-.5em" >
                    <mat-slide-toggle style="transform:scale(.8)" color="primary" [checked]="is24Hours" formControlName="is24Hours" (change)="toggle24($event,is24Hours)">24/7 Daily</mat-slide-toggle>
                    <mat-slide-toggle *ngIf="f.is24Hours.value==false" style="transform:scale(.8)" color="primary" [checked]="isUTC" formControlName="isUTC">UTC</mat-slide-toggle>
                    <mat-slide-toggle *ngIf="f.is24Hours.value==false" style="transform:scale(.8)" color="primary" [checked]="isOvertime" formControlName="isOvertime">Overtime Available</mat-slide-toggle>
                    <mat-slide-toggle *ngIf="f.is24Hours.value==false" style="transform:scale(.8)" color="primary" [checked]="isSeasonal" formControlName="isSeasonal">Seasonal</mat-slide-toggle>
                  </div>

                </div>
                <!--<div> <mat-checkbox formControlName="is24Hours">24 Hours</mat-checkbox></div>-->
                <div>
                  <table mat-table [dataSource]="hoursData" class="example-container">
                    <tr>
                      <ng-container matColumnDef="day_of_week">
                        <th mat-header-cell *matHeaderCellDef width="20%">

                        </th>
                        <td mat-cell *matCellDef="let element">{{element.dayOfWeek}}</td>
                      </ng-container>
                      <ng-container matColumnDef="time">
                        <th mat-header-cell *matHeaderCellDef width="70%">

                        </th>
                        <td mat-cell *matCellDef="let element">{{element.isClosed==1? 'Closed' : element.is24Hours? '24 Hours': element.openTime==null || element.openTime==''? ' ': element.openTime + ' - ' +  element.closeTime}}</td>
                      </ng-container>
                      <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef width="10%">

                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align:right !important">
                          <button mat-icon-button (click)="clickEdit(element)">
                            <mat-icon class="material-icons-outlined">edit</mat-icon>
                          </button>
                        </td>
                      </ng-container>
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                  </table>
                  <!--<ng-template ngFor let-hours [ngForOf]="hoursData" let-i="index">
      <div class="row">
        <div class="col">{{hours.dayOfWeek}}</div>
        <div *ngIf="hours.openTime!=''&&hours.openTime!=null" class="col">{{hours.openTime}}</div>
        <div *ngIf="hours.closeTime!='' && hours.closeTime!=null" class="col">{{hours.closeTime}}</div>
        <div *ngIf="hours.is24Hours" class="col">{{hours.is24Hours? 'open 24 hours':''}}</div>
      </div>
    </ng-template>-->
                  <!--<table style="font-size:small" cellpadding="5">
      <tr><td></td><td>Open</td><td>Close</td></tr>
      <tr>
        <td>Monday</td>
        <td><input type="text" formControlName="mon_open" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.mon_open.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('mon_open', $event)" /></td>
        <td><input type="text" formControlName="mon_close" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.mon_close.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('mon_close', $event)" /></td>
      </tr>
      <tr>
        <td>Tuesday</td>
        <td><input type="text" formControlName="tue_open" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.tue_open.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('tue_open', $event)" /></td>
        <td><input type="text" formControlName="tue_close" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.tue_close.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('tue_close', $event)" /></td>
      </tr>
      <tr>
        <td>Wednesday</td>
        <td><input type="text" formControlName="wed_open" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.wed_open.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('wed_open', $event)" /></td>
        <td><input type="text" formControlName="wed_close" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.wed_close.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('wed_close', $event)" /></td>
      </tr>
      <tr>
        <td>Thursday</td>
        <td><input type="text" formControlName="thu_open" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.thu_open.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('thu_open', $event)" /></td>
        <td><input type="text" formControlName="thu_close" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.thu_close.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('thu_close', $event)" /></td>
      </tr>
      <tr>
        <td>Friday</td>
        <td><input type="text" formControlName="fri_open" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.fri_open.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('fri_open', $event)" /></td>
        <td><input type="text" formControlName="fri_close" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.fri_close.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('fri_close', $event)" /></td>
      </tr>
      <tr>
        <td>Saturday</td>
        <td><input type="text" formControlName="sat_open" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.sat_open.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('sat_open', $event)" /></td>
        <td><input type="text" formControlName="sat_close" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.sat_close.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('sat_close', $event)" /></td>
      </tr>
      <tr>
        <td>Sunday</td>
        <td><input type="text" formControlName="sun_open" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.sun_open.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('sun_open', $event)" /></td>
        <td><input type="text" formControlName="sun_close" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.sun_close.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('sun_close', $event)" /></td>
      </tr>
    </table>-->
                </div>

                <!--<div>
    <div class="ml-2">
      <mat-radio-group formControlName="isUTC">
        <mat-radio-button value="1" [checked]="isUTC" style="transform:scale(.8)">UTC</mat-radio-button>
        <mat-radio-button value="0" [checked]="isUTC==false" style="transform:scale(.8);margin-left:.5em">Local</mat-radio-button>
      </mat-radio-group>
    </div>
    <div style="margin-top:-.25em">
      <mat-checkbox (change)="checkApplyEveryday($event)">Apply to Everyday</mat-checkbox>
    </div>
  </div>-->

              </div>
            </div>
            <div style="background-color:white;margin-top:.5em;padding:.5em;">
              <div class="d-flex">
                <div>
                  <label style="font-size:small">Remarks</label>
                  <div style="margin-top:-.35em">
                    <textarea style="width:30em;height:5em" formControlName="remarks" class="form-control form-control-sm inputBox"></textarea>
                  </div>
                </div>
                <!--<div class="d-flex flex-column mt-4">
                  <mat-checkbox formControlName="isOvertime">Overtime Available</mat-checkbox>
                  <mat-checkbox formControlName="isSeasonal">Seasonal</mat-checkbox>
                </div>-->
                
                  
                </div>
              <div *ngIf="vendorHoursID!=0" class="d-inline-flex">
                <div>
                  <label for="hours_status" style="font-size:small">Disable Record</label>
                  <div style="margin-top:-0.5em;">
                    <select *ngIf="f" formControlName="hours_status" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color: lightgrey">
                      <option value="1" selected>No</option>
                      <option value="0">Yes</option>
                    </select>
                  </div>
                </div>
                <div>

                  <div class="ml-3">
                    <label for="modifiedBy" style="font-size:small">Modified by</label>
                    <div style="margin-top:-0.5em;">
                      <input type="text" formControlName="modifiedBy" readonly class="form-control" style="padding-left:0em;width:10em;height:1.5625em;font-size:small" />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="ml-3">
                    <label for="modifiedDate" style="font-size:small">Modified Date</label>
                    <div style="margin-top:-0.5em;">
                      <input type="text" formControlName="modifiedDate" readonly class="form-control" style="padding-left: 0em; width: 10em; height: 1.5625em; font-size: small" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>
        
      </div>
      
    </form>
  </div>
  <div class="d-inline-flex">
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em">
      <button mat-button class="btn-high" (click)="clickSave()">SAVE</button>&nbsp;
      <button mat-button mat-close class="btn-low" (click)="clickClose()">CLOSE</button>&nbsp;

    </div>
    <div class="mt-2">
      <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display:inline-block; white-space:pre-wrap;margin-top:.5em"><label style="font-size: small">{{errMsg}}</label></div>
      <app-success-message *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
