<div  [id]="uniqueId" >
  <!--[ngClass]="{  'mini1': conditionValue === 1 && isMinimized === true,
      'mini2': conditionValue === 2 && isMinimized === true,
      'mini3': conditionValue === 3 && isMinimized === true }" -->
  <div class="dialog-buttons minimizedDialog"  cdkDrag cdkDragRootElement=".dialog-buttons" cdkDragHandle
    style="border-radius:5px; margin-bottom: -0.5em; margin-right: -0.4em; display: flex; justify-content: space-between;">
  
  
    <div *ngIf="isMinimized" style="margin-top: 0.5em; display: flex;">
      <mat-icon style="font-size: large; margin-top:0.2em; margin-left: 0.2em; margin-right: 0.2em;"> flight</mat-icon>
      <span> Country Locator</span> </div>
    
      <!-- <button *ngIf = "!isMinimized" mat-icon-button (click)="minimizeDialog()">  <i class="material-icons" style="font-size:x-large;  ">remove</i> </button> -->    
    <div style="display: flex;">
      <button *ngIf="isMinimized" mat-icon-button (click)="maximizeDialog()"> <i class="material-icons" style="font-size: large; margin-right: -1.2em; ">open_in_full</i> </button>
      <button *ngIf="isMinimized" mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button>
    </div>
  </div>

<div class="model-content" [ngClass]="{'minimized': isMinimized}">

  <div style="display: flex; justify-content: space-between;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div   style="margin-top:-1em" mat-dialog-title>
      Country Locator 
    </div>
    <!-- <button   mat-icon-button (click)="clickClose()">     <i class="material-icons" style="font-size: x-large;">close      </i> </button> -->
    <div style="    margin-top: -1.3em;">
      
      <button *ngIf = "!isMinimized" mat-icon-button (click)="minimizeDialog()">  <i class="material-icons" style="font-size:x-large;  ">remove</i> </button>    

      <button mat-dialog-close mat-icon-button><i class="material-icons" style="font-size:  x-large;">close </i> </button>
    </div>
  </div>

  <div mat-dialog-content style="margin-top:-.5em;padding-bottom:0; background-color:white;border-radius:.25em; height: 35em !important; max-height: 35em;" class="mx-1 pb-3">

    
    <form *ngIf="country_locator_form" [formGroup]="country_locator_form">
      <div class="d-inline-flex" style="width: max-content;">
        <div >
          <div class="d-flex justify-content-start mt-1" style="margin-left:-.5em">
            <div>
              
              <div style="display: flex;">
                <div   style="display: flex;">
                  <div style="width: max-content; margin-right: 0.5em;">
                    <label class="font-small">Select a Country </label>
                  </div>
    
                </div >
                <div class="col mt-1" style="display: flex;" >
                  <div style="display: flex;">
                    <input type="text" class="form-control form-control-sm inputBox" required formControlName="search_by"
                      style="height: 1.7625em !important; margin-left: -1em; min-width: 11em;" [style.min-width]="searchType == 'TripCode'? '8em' : '11em'"
                      [ngClass]="{ 'is-invalid': submitted && f.search_by.errors }" />
                  </div>
                  <span class="invalid-tooltip alert-danger p-0" style="font-size:small;">
                    Enter a search field.
                  </span>
                </div>
                <button mat-button class="btn-high " style="margin-left: 0.5em;" (click)="getCountryList()">Search</button>
                <button mat-button style="margin-left: 0.5em;" class="btn-low" (click)="clearInputBox()">Reset</button>
              </div>
            </div>

            <!-- <div class="ml-3">
              <button mat-raised-button class="btn-high mt-1" (click)="getCountryList()">Search</button>
            </div> -->
          </div>
          <div *ngIf="errMsg!=''">
            <span class="alert-info small p-1">{{errMsg}}</span>
          </div>

          <!-- <div class="d-inline-flex">                {{a.cityName}} -->
            <div class="mt-1" style="background-color:white">
              <mat-selection-list #countrys [multiple]="false" style="overflow:auto; max-height: 31em;">
                <mat-list-option *ngFor="let a of countryList" [value]="a" (click)="selectCountry(a)">
                  <span style="display: flex;">  {{a.countryName}} 
                  </span> </mat-list-option>
                  <mat-list-option *ngFor="let a of countryErrorList"  >
                    <span style="display: flex; color: red;"><span style="display: flex;"><b>Can not find Results for Keyword {{a}}  </b></span>&nbsp; 
                  </span>
                  </mat-list-option>
              </mat-selection-list> 

            </div>
        <!-- </div> -->
        </div>

        <!-- <div class="mt-4 ml-2" style="min-width:45em; max-width: 45em;"  *ngIf="countryID">
          <app-country-details  [countryID]="countryID"  ></app-country-details>
        </div> -->
      
      </div>
    </form>
  </div>


  <app-spin *ngIf="isLoading"></app-spin>
</div>
</div>