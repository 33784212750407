<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<div class="divCenter">
    <div>
        <div style="margin-left:2.5em">
            <img src="../../assets/images/FlightProLogoWhiteBG.png" style="cursor: pointer" (click)="openCompanyMarketPage()" />
        </div>
        <div style="border-style: solid; border-width: 0.0625em; border-radius: 0.3125em; border-color: lightgray;padding-top: 1em;padding-bottom:1em; margin-top:2em;">
            <form *ngIf="usernameForm" [formGroup]="usernameForm" (ngSubmit)="onSubmit()">
                <div style="text-align:center">
                    <h2>Flight Pro Connect {{websiteEnvironment}}</h2>
                </div>
                <div class="d-flex justify-content-center">
                    <div style="margin-top:2em;">
                        <h5 class="mt-1">Sign In</h5>
                        <div class="d-block" style="height:1.5em"></div>
                        <div class="form-group d-block">
                            <div style="display:flex; justify-content:space-between;width:18.75em">
                                <label for="username">Username</label><a routerLink="" (click)="clickGetMyUsername()">Forgot your username?</a>
                            </div>
                            <div>
                                <div>
                                    <input type="text" formControlName="username" #usernameRef class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" style="width:18.75em; background-color:#eaf0fd" maxlength="50" />
                                </div>
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">Username is required</div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="errMsg!=''" style="display:inline; white-space:pre-wrap"><label style="color:red">{{errMsg}}</label></div>
                        <div class="form-group" style="display:block">
                            <button [disabled]="loading" class="btn btn-primary" style="width:18.75em">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Continue
                            </button>
                        </div>
                        <div style="font-size: small; margin-top: 3em">
                            By Continuing, you agree to Flight Pro International's<br />
                            <a routerLink="" (click)="openTerm()" style="text-decoration: none">Terms of Use</a> and <a routerLink="" (click)="openPrivacy()" style="text-decoration: none">Privacy Notice</a>
                        </div>
                        <!--<div style="height:4.5em">
                        <div style="display: flex; padding-top: 1em">-->
                        <!--<div class="triangle-right-sm" style="display: inline-block;padding-right:0.125em; cursor: pointer" (click)="showHelp=!showHelp"></div>-->
                        <!--<div style="display: inline-block;cursor: pointer;margin-left:-0.25em;" (click)="showHelp=!showHelp">
                              <i class="material-icons" style="color:black;">arrow_drop_down</i>
                            </div>
                            <div style="font-size: small; margin-left: -0.5em;display: inline-block;">
                              <a [routerLink]="" (click)="showHelp=!showHelp" style="text-decoration:none">Need help?</a>
                            </div>
                          </div>
                          <div *ngIf="showHelp" style="display: inline-block; font-size: small; margin-left:0.25em; margin-top:-0.75em; padding: 0.3125em; vertical-align: middle; border: 0.0625em lightgrey solid; border-radius: 0.3125em">
                            Please contact our Support Team at (346) 415 - 6900<br />Email Support@FlightProIntl.com
                          </div>
                        </div>-->
                    </div>
                </div>
            </form>
        </div>
        <div style="display:flex;margin-top:1em;">
            <div style="font-size:small;"><a routerLink="" (click)="openTerm()" style="text-decoration: none">Terms of Use</a></div>
            <div style="font-size:small; margin-left: 6em;"><a routerLink="" (click)="openPrivacy()" style="text-decoration: none">Privacy Notice</a></div><div style="font-size:small; margin-left:10em;"><a routerLink="" (click)="openContactUs()" style="text-decoration: none">Help</a></div>
        </div>
    </div>
</div>
<div *ngIf="maintenanceMessage!=''" class="divCenter" style="display:flex;margin-top:1em;">
    <div>
        <div class="bg-warning p-2" style="width:42.5em;border-radius:.25em;font-size:medium" [innerHTML]="getHTML(maintenanceMessage)">
            
        </div>
    </div>
</div>
<div class="divCenter" >
  <a href="https://apps.apple.com/us/app/flight-pro-international/id6447209261" target="_blank" rel="noopener noreferrer"><img src="../../assets/images/app-store-badge-sz-300x89.png" width="182" height="54"/></a>
  <a href="https://play.google.com/store/apps/details?id=com.fpc.flightprointl" target="_blank" rel="noopener noreferrer"><img src="../../assets/images/google-play-badge-sz-300x89.png" width="182" height="54" /></a>
</div>
<!--<div class="divCenter" style="display:flex;margin-top:1em;">
  <div>
    <div class="bg-warning p-2" style="width:42.5em;border-radius:.25em;font-size:medium">
      Maintenance will be performed on Flight Pro Connect on Wednesday, June 8th beginning at 1400 UTC. This maintenance will include an outage or intermittent slow responses from the website for up to 24 hours.
  <br /><br />If you are unable to log into Flight Pro Connect, please contact our 24/7 operations center for support at
  <br />
  Phone: +1 346 415 6900
  <br />
  Email:   <a href="mailto:ops@flightprointl.com">ops@flightprointl.com</a>
      Flight Pro Connect maintenance update is scheduled for today from 4:00 pm – 6:00 pm (CST).<br /><br />If you have any website issues or other questions, please contact Operations at<br /><a href="mailto:ops@flightprointl.com">ops@flightprointl.com</a>, or call +1 (346) 415-6900.
    </div>
  </div>
</div>-->
<!--<div class="divCenter" style="display:flex;margin-top:1em;">
  <div>
    <div style="border-radius:.25em;font-size:small">
      Maintenance has been completed as of June 8th 1440 UTC. Thank you for your patience.
    </div>
  </div>
</div>-->
