import Alignment from './ckeditor';
import Autoformat from './ckeditor';
import BlockQuote from './ckeditor';
import Bold from './ckeditor';
import CloudServices from './ckeditor';
import Essentials from './ckeditor';
import Font from './ckeditor';
import FontBackgroundColor from './ckeditor';
import FontColor from './ckeditor';
import FontFamily from './ckeditor';
import FontSize from './ckeditor';
import Heading from './ckeditor';
import HtmlEmbed from './ckeditor';
import Image from './ckeditor';
import ImageCaption from './ckeditor';
import ImageResize from './ckeditor';
import ImageStyle from './ckeditor';
import ImageToolbar from './ckeditor';
import ImageUpload from './ckeditor';
import Indent from './ckeditor';
import IndentBlock from './ckeditor';
import Italic from './ckeditor';
import Link from './ckeditor';
import List from './ckeditor';
import ListProperties from './ckeditor';
import MediaEmbed from './ckeditor';
import PageBreak from './ckeditor';
import Paragraph from './ckeditor';
import PasteFromOffice from './ckeditor';
import Strikethrough from './ckeditor';
import Subscript from './ckeditor';
import Superscript from './ckeditor';
import Table from './ckeditor';
import TableCellProperties from './ckeditor';
import TableProperties from './ckeditor';
import TableToolbar from './ckeditor';
import TextTransformation from './ckeditor';
import TodoList from './ckeditor';
import Underline from './ckeditor';
import { MyUploadAdapter } from 'src/app/ckeditor5/uploader';
import GeneralHtmlSupport from './ckeditor';
import HorizontalLine from './ckeditor';
import Style from './ckeditor';
import RemoveFormat from './ckeditor';
import Clipboard from './ckeditor';
/*import ImageRemoveEventCallbackPlugin from './ckeditor';*/
//import ExportPdf from './ckeditor';
//import ExportWord from './ckeditor';

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}
export class CKEditorConfig {

  public static get ckeditorConfig(): any {
    return {
      licenseKey: 'N2s4OGdqeDdST00xQ2ZrM0NjVWJjRUNvcmd2aXJPMmcxdEN1YlZSeDZ5Yzg3S3I0Wi9kZnB6TjNKeSswcWJEQmZ4VXotTWpBeU16RXdNVGM9',
      placeholder: 'Type the content here!',
      startupFocus: true,
      Plugin: this.ckeditorPlugin,
      fontFamily: this.ckeditorConfigFontFamily,
      fontSize: this.ckeditorConfigFontSize,
      toolbar: this.ckeditorConfigToolbar,
      fontBackgroundColor: this.ckeditorFontBackgroundColor,
      fullPage: true,
      allowedContent: true,
      shouldNotGroupWhenFull: true,
      extraPlugins: [MyCustomUploadAdapterPlugin],
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true
          }
        ]        
      },
      style: {
        definitions: [

          {
            name: 'Signature',
            element: 'p',
            classes: ['signature-body']
          },
          {
            name: 'Email Body',
            element: 'p',
            classes: ['email-body']
          },
        ]
      },
      
    };
  }

  public static get ckeditorConfigNoUploads(): any {
    return {
      licenseKey: 'N2s4OGdqeDdST00xQ2ZrM0NjVWJjRUNvcmd2aXJPMmcxdEN1YlZSeDZ5Yzg3S3I0Wi9kZnB6TjNKeSswcWJEQmZ4VXotTWpBeU16RXdNVGM9',
      placeholder: 'Type the content here!',
      startupFocus: true,
      Plugin: this.ckeditorPlugin,
      fontFamily: this.ckeditorConfigFontFamily,
      fontSize: this.ckeditorConfigFontSize,
      toolbar: this.ckeditorConfigToolbar,
      fontBackgroundColor: this.ckeditorFontBackgroundColor,
      fullPage: true,
      allowedContent: true,
      shouldNotGroupWhenFull: true,
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true
          }
        ]        
      },
      style: {
        definitions: [

          {
            name: 'Signature',
            element: 'p',
            classes: ['signature-body']
          },
          {
            name: 'Email Body',
            element: 'p',
            classes: ['email-body']
          },
        ]
      },
      
    };
  }

  public static get ckeditorConfigClient(): any {
    return {
      licenseKey: 'N2s4OGdqeDdST00xQ2ZrM0NjVWJjRUNvcmd2aXJPMmcxdEN1YlZSeDZ5Yzg3S3I0Wi9kZnB6TjNKeSswcWJEQmZ4VXotTWpBeU16RXdNVGM9',
      //placeholder: 'Type the content here!',
      startupFocus: true,
      Plugin: this.ckeditorPlugin,
      fontFamily: this.ckeditorConfigFontFamily,
      fontSize: this.ckeditorConfigFontSize,
      toolbar: this.ckeditorConfigToolbarClient,
      fontBackgroundColor: this.ckeditorFontBackgroundColor,
      fullPage: true,
      allowedContent: true,
      shouldNotGroupWhenFull: true,
      extraPlugins: [MyCustomUploadAdapterPlugin],
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true
          }
        ]
      }

    };
  }


  public static get ckeditorConfigFontFamily(): any {
    return ['Default', 'Arial', 'Courier New', 'Georgia', 'Lucida Sans Unicode', 'Tahoma', 'Time New Roman', 'Trebuchet MS', 'Verdana']
  }

  public static get ckeditorConfigFontSize(): any {
    return ['Tiny', 'Small', 'Default', 'Big', 'Huge'];
  }

  public static get ckeditorConfigToolbar(): any {
    return {
     items:[ 'style', 'heading', '|', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'subscript', 'superscript',
      'Alignment', 'numberedList', 'bulletedList', 'link', 'undo', 'redo', 
        'insertTable', 'tableProperties', 'indent', 'outdent', 'removeFormat'/*'pageBreak', 'exportPdf', 'exportWord'*/],
      
    };
  }

  public static get ckeditorConfigToolbarClient(): any {
    return {
      items: ['heading', '|', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'subscript', 'superscript',
        'Alignment', 'numberedList', 'bulletedList', 'link', 'undo', 'redo',
        'insertTable', 'tableProperties', 'indent', 'outdent', 'removeFormat'/*'pageBreak', 'exportPdf', 'exportWord'*/],

    };
  }

  public static get ckeditorFontBackgroundColor(): any {
    return [
      {
        color: 'hsl(0, 0%, 0%)',
        label: 'Black'
      },
      {
        color: 'hsl(0, 0%, 30%)',
        label: 'Dim grey'
      },
      {
        color: 'hsl(0, 0%, 60%)',
        label: 'Grey'
      },
      {
        color: 'hsl(0, 0%, 90%)',
        label: 'Light grey'
      },
      {
        color: 'hsl(0, 0%, 100%)',
        label: 'White',
        hasBorder: true
      },
      {
        color: 'hsl(0, 75%, 60%)',
        label: 'Red'
      },
      {
        color: 'hsl(30, 75%, 60%)',
        label: 'Orange'
      },
      {
        color: 'hsl(60, 75%, 60%)',
        label: 'Yellow'
      },
      {
        color: 'hsl(90, 75%, 60%)',
        label: 'Light green'
      },
      {
        color: 'hsl(120, 75%, 60%)',
        label: 'Green'
      },
      {
        color: 'hsl(150, 75%, 60%)',
        label: 'Aquamarine'
      },
      {
        color: 'hsl(180, 75%, 60%)',
        label: 'Turquoise'
      },
      {
        color: 'hsl(210, 75%, 60%)',
        label: 'Light blue'
      },
      {
        color: 'hsl(240, 75%, 60%)',
        label: 'Blue'
      },
      {
        color: 'hsl(270, 75%, 60%)',
        label: 'Purple'
      }
    ];
  }

  public static get ckeditorPlugin(): any {
    return [
      Alignment,
      Autoformat,
      BlockQuote,
      Bold,
      CloudServices,
      Essentials,
      Font,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      Heading,
      HtmlEmbed,
      Image,
      ImageCaption,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      List,
      ListProperties,
      MediaEmbed,
      PageBreak,
      Paragraph,
      PasteFromOffice,
      Strikethrough,
      Subscript,
      Superscript,
      Table,
      TableCellProperties,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
      GeneralHtmlSupport,
      HorizontalLine,
      Style,
      RemoveFormat,
      Clipboard
      /*ImageRemoveEventCallbackPlugin*/
      //ExportPdf,
      //ExportWord
    ];
  }
}
