<div *ngIf="upPII" style="margin-top:0em; padding-top:0em;">
  <form *ngIf="trip_leglist_form" [formGroup]="trip_leglist_form">
    <div class="divLeft">
      <div style="display: flex">
        <div style="margin-right:1em"><h5>Daily View</h5></div>
      </div>
      <div style="background-color:#f8f8f8; padding-left:0.25em; padding-top:0.25em; padding-right:0.5em; margin-top:-0.5em; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; width:93em; height:4.5em">
        <div style="margin-left:0em;padding:0em; display: flex; ">
          <div>
            <div style="border: 0.0125em solid #337dff; margin-right:0.5em; padding-left:0.25em; border-radius: 0.25em; font-size: small; height:4.75em; min-width: 16em">
              <div style="display: flex">
                <div style="margin-right:0.25em">
                  <div>Trip Schedules</div>
                  <div style="margin-top:-0.5em">Rolling Window</div>
                </div>
                <div style="margin-top:0.25em">
                  <select formControlName="hour_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey;" (change)="hourSelectChange($event)">
                    <option value="12">12 hrs</option>
                    <option value="24">24 hrs</option>
                    <option value="48">48 hrs</option>
                    <option value="72">72 hrs</option>
                    <option value="7d">7 days</option>
                    <option value="14d">14 days</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>
              </div>
              <div *ngIf="f.hour_select.value=='custom'" style="display: flex; margin-top:-0.5em">
                <mat-date-range-input [rangePicker]="picker" [min]="minDate" style="width:12em; margin-top:0.125em;display: flex ">
                  <input matStartDate formControlName="startDate" placeholder="Start date" maxlength="10"  [ngClass]="{ 'is-invalid1': f.startDate.errors }" (dateChange)="dateOnchange('S', $event)" style="font-size:small;margin-top:0em; margin-right:0em;background-color: white; border-radius:0.25em;padding-left:0.125em; ">
                  <input matEndDate formControlName="endDate" placeholder="End date" maxlength="10" [ngClass]="{ 'is-invalid1': f.endDate.errors}" (dateChange)="dateOnchange('E', $event)" style="font-size:small;margin-left:0em; background-color: white; border-radius:0.25em;padding-left:0.125em ">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker" style="margin-top:0em; margin-left:-1em"></mat-datepicker-toggle>
                <mat-date-range-picker #picker ></mat-date-range-picker>
              </div>
            </div>

          </div>
          <div style="min-width:55em">
            <div style="display: flex;">
              <div style="margin-top:0.5em; margin-right:0.25em;">
                <button type="button" mat-button class="btn-low" style="width:8em; padding-left:0.5em !important" [ngClass]="selectedClientCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickClientFilter()">Client Acct{{selectedClientCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em;">
                <button type="button" mat-button class="btn-low" style="width:7em" [ngClass]="selectedRegistrationCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickAircraftFilter()">Aircraft{{selectedRegistrationCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:11.5em; padding-left:0.5em !important" [ngClass]="selectedPersonCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickManifestFilter()">Manifest{{selectedPersonCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em; padding-left:0.65em !important" [ngClass]="selectedAirportCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickAirportFilter()">Airport{{selectedAirportCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em; padding-left:0.65em !important" [ngClass]="selectedVendorCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickVendorFilter()">Vendor{{selectedVendorCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em; padding-left:0.65em !important" [ngClass]="selectedTripCodeCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickTripCodeFilter()">Trip Code{{selectedTripCodeCountText}}</button>
              </div>
            </div>
            <div style="display:flex; min-width: 20em">
              <div *ngIf="f.startDate.errors" class="small alert alert-danger p-0" style="width:max-content;margin-top:0.125em;height:1.5em;">
                <div *ngIf="f.startDate.errors.isValidDate==false">Invalid start date.</div>
                <div *ngIf="f.startDate.errors.required==true">Start date is required.</div>
              </div>
              <div *ngIf="f.endDate.errors" class="small alert alert-danger p-0" style="width:max-content;margin-top:0.125em;height:1.5em">
                <div *ngIf="f.endDate.errors.isValidDate==false">Invalid end date.</div>
                <div *ngIf="f.endDate.errors.required==true">End date is required.</div>
                <div *ngIf="f.endDate.errors.maxDateRange==false">Maximum date range is 90 days</div>
              </div>
              <!--client list-->
              <div *ngIf="showClientList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 0em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Client Accounts</div>
                  <div style="float:right" (click)="showClientList=!showClientList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="client_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="clientSearchChange($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="customerList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let c of customerList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                    <div #tripClientRefs [attr.customerGUID]="c.customerGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkClientChange($event, c)" [checked]="c.selected">{{c.customerName}}</mat-checkbox>
                      </div>
                      <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{c.customerName}}</div></div>-->
                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetClient()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneClient()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--client list end-->
              <!--Aircraft list-->
              <div *ngIf="showAircraftList" style="float: left; min-width: 11em; margin-top: 0.25em; margin-left: 8em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Registration</div>
                  <div style="float:right" (click)="showAircraftList=!showAircraftList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="aircraft_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="aircraftSearchChange($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="registrationList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let r of registrationList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                    <div #tripAircraftRefs [attr.aircraftGUID]="r.aircraftGuid" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkRegistrationChange($event, r)" [checked]="r.selected">{{r.registration}}</mat-checkbox>
                      </div>
                      <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{r.registration}}</div></div>-->
                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetAircraft()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneAircraft()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--Aircraft list end-->
              <!--Airport list-->
            <div *ngIf="showAirportList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 26em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
              <div style="display:flex; justify-content:space-between">
                <div style="font-weight: bold; text-decoration:underline;">Airport ICAO</div>
                <div style="float:right" (click)="showAirportList=!showAirportList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
              </div>
              <div style="font-size: small; margin-left:0em">
                <div style="margin-right:0.5em;">
                  <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                    <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                    <input type="text" formControlName="airport_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="airportSearchChange($event)" />
                  </div>
                </div>
              </div>
              <div *ngIf="airportList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                <div *ngFor="let a of airportList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                  <div #tripAirportRefs [attr.icao]="a.icao" style="display: flex; font-size: small; margin-top:-0.5em">
                    <div>
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="checkAirportChange($event, a)" [checked]="a.selected">{{a.icao}}</mat-checkbox>
                    </div>
                    <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{a.icao}}</div></div>-->
                  </div>
                </div>
              </div>
              <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
              <div style="display: flex; float: right">
                <div style="margin-right:0.5em">
                  <button type="button" mat-button class="btn-low" (click)="clickResetAirport()" style="height:2em">RESET</button>
                </div>
                <div>
                  <button type="button" mat-button class="btn-high" (click)="clickDoneAirport()" style="height:2em">DONE</button>
                </div>
              </div>
            </div>
              <!--Airport list end-->
              <!--manifest list-->
              <div *ngIf="showPersonList" style="float: left; margin-top: 0.25em; margin-left: 15em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display: flex">
                  <div style="margin-right:2em">
                    <div style="font-weight: bold; text-decoration:underline">Role</div>
                    <div>
                      <div style="display: flex; margin-top:0.5em">
                        <div style="margin-right:0.25em">
                          <input formControlName="person_role"
                                 type="radio"
                                 value="pilot" (click)="personRoleChange($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.15em">
                          <label>Pilot</label>
                        </div>
                      </div>
                      <div style="display: flex">
                        <div style="margin-right:0.25em">
                          <input formControlName="person_role"
                                 type="radio"
                                 value="crew" (click)="personRoleChange($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.15em">
                          <label>Crew</label>
                        </div>
                      </div>
                      <div style="display: flex">
                        <div style="margin-right:0.25em">
                          <input formControlName="person_role"
                                 type="radio"
                                 value="passenger" (click)="personRoleChange($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.15em">
                          <label>Passenger</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="min-width: 14em">
                    <div style="display:flex; justify-content:space-between">
                      <div style="font-weight: bold; text-decoration:underline; white-space: nowrap">{{person_role=='pilot'?'PIC / SIC':person_role | titlecase}}</div>
                      <div style="float:right" (click)="showPersonList=!showPersonList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                    </div>
                    <div style="font-size: small; margin-left:0em">
                      <div style="margin-right:0.5em;">
                        <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                          <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                          <input type="text" formControlName="person_search_keyword" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="personSearchKeywordChange($event)" />
                        </div>
                      </div>
                    </div>
                    <!--pilot-->
                    <div *ngIf="person_role=='pilot' && pilotList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                      <div *ngFor="let p of pilotList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                        <div #tripPersonRefs [attr.personGUID]="p.personGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                          <div>
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)" [checked]="p.selected">{{p.personName}}</mat-checkbox>
                          </div>
                          <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em; white-space: nowrap">{{p.personName}}</div></div>-->
                        </div>
                      </div>
                    </div>
                    <!--pilot end-->
                    <!--crew-->
                    <div *ngIf="person_role=='crew' && crewList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                      <div *ngFor="let p of crewList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                        <div #tripPersonRefs [attr.personGUID]="p.personGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                          <div>
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)" [checked]="p.selected">{{p.personName}}</mat-checkbox>
                          </div>
                          <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em; white-space: nowrap">{{p.personName}}</div></div>-->
                        </div>
                      </div>
                    </div>
                    <!--crew end-->
                    <!--passenger-->
                    <div *ngIf="person_role=='passenger' && paxList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                      <div *ngFor="let p of paxList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                        <div #tripPersonRefs [attr.personGUID]="p.personGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                          <div>
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)" [checked]="p.selected">{{p.personName}}</mat-checkbox>
                          </div>
                          <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em; white-space: nowrap">{{p.personName}}</div></div>-->
                        </div>
                      </div>
                    </div>
                    <!--passenger end-->
                  </div>

                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetPerson()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDonePerson()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--manifest list end-->
              <!--vendor list-->
              <div *ngIf="showVendorList" style="float: left; min-width: 11em; margin-top: 0.25em; margin-left: 30em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Vendors</div>
                  <div style="float:right" (click)="showVendorList=!showVendorList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="vendor_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="vendorSearchChange($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="vendorList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let v of vendorList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                    <div #tripVendorRefs [attr.vendorGUID]="v.vendorGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkVendorChange($event, v)" [checked]="v.selected">{{v.icao!=''?v.vendorName+' - '+v.icao:v.vendorName}}</mat-checkbox>
                      </div>
                      <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{v.icao!=''?v.vendorName+' - '+v.icao:v.vendorName}}</div></div>-->
                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetVendor()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneVendor()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--vendor list end-->
              <!--trip code list-->
              <div *ngIf="showTripCodeList" style="position: absolute; float: left; min-width: 13em; margin-top: 0.25em; margin-left: 40em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display: flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Trip Codes</div>
                  <div style="float:right" (click)="showTripCodeList=!showTripCodeList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="tripcode_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="tripCodeSearchChange($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="tripCodeList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let t of tripCodeList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                    <div #tripCodeRefs [attr.tripCodeGUID]="t.tripCodeGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkTripCodeChange($event, t)" [checked]="t.selected">{{t.tripCode}}</mat-checkbox>
                      </div>
                      <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{t.tripCode}}</div></div>-->
                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetTripCode()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneTripCode()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--trip code list end-->
            </div>
          </div>
          <div>
            <div style="display: flex">
              <div style="margin-top:0.125em; margin-right:0.5em; width:7em">
                <div>
                  <button type="button" mat-button class="btn-low" (click)="clickReset()" style="height:1.5em;width:7em">
                    <div style="display: flex;margin-top:-0.125em">
                      <div style="margin-top: 0.125em; margin-left:-0.25em">
                        <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">restart_alt</i>
                      </div>
                      <div>
                        RESET
                      </div>
                    </div>
                  </button>
                  <button type="button" mat-button class="btn-low" (click)="clickSubmit()" style="height:1.5em; width:7em">
                    <div style="display: flex;margin-top:-0.125em">
                      <div style="margin-top: 0.25em; margin-left:-0.25em">
                        <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>
                      </div>
                      <div>
                        REFRESH
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div style="margin-left: 0em;margin-top:0em; margin-right:0.5em">
                <div style="font-size: small; font-weight: bold">Include</div>
                <div style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="includeDemo" style="transform: scale(0.75)" (change)="includeDemoOnChange($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em"><label for="includeDemo" style="font-size:small">&nbsp;Demo Trips</label></div>
                </div>
              </div>
            </div>
          <div style="display:flex; margin-top:-0.25em"><div style="font-size:small; margin-top: 0em; margin-left: 0.3125em;">{{totalRecordsText}}</div><div *ngIf="errMsg!=''" class="d-block" style="margin-left:0.5em; display:flex;margin-top:-0.5em; background-color:#f9d2de; padding-left:0.25em; width:18em"><label style="color:black; font-size: small">{{errMsg}}</label></div></div>
          </div>
          </div>
      </div>
      <div style="margin-top:0.5em">
        <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container mat-elevation-z8">
          <tr>
            <ng-container matColumnDef="registration">
              <th mat-header-cell *matHeaderCellDef style="width:8%; text-align:left !important">
                <div style="margin-top:-0.25em;">Aircraft</div>
                <div style="margin-top:-0.25em;">& FAR</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important;cursor: pointer" >
                <div style="font-weight:bold">{{element.registration}}</div>
                <div style="font-size:small;margin-top:-0.25em; white-space:nowrap">{{element.farTypeDescription}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="depAirport">
              <th mat-header-cell *matHeaderCellDef style="width:26%; text-align:left !important">
                <div style="display:flex;margin-top:-0.25em;">
                  <div style="width:18em">Departure</div>
                  <div>ETE</div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important">
                <div style="display: flex">
                  <div>
                    <div style="display: flex">
                      <div *ngIf="element.departureICAO!='XXXX'" class="divHover" style="cursor: pointer;font-weight: bold">{{element.departureICAO_IATA}}</div>
                      <div *ngIf="element.departureICAO=='XXXX'" style="font-weight: bold">{{element.departureICAO_IATA}}</div>
                      <div></div>
                    </div>
                    <div style="display: flex">
                      <div style="display:flex; width:16.5em">
                        <div style="font-weight: bold;">{{element.departureDateTimeUTCText}}</div>
                        <div style="margin-left:0.5em">{{element.departureUTCConversion}}</div>
                      </div>
                      <div>{{element.ete}}</div>
                    </div>
                    <div>{{element.departureDateTimeLocalText}}</div>
                    <div *ngIf="element.departureVendorList.length>0">
                      <div *ngFor="let p of element.departureVendorList" style="margin-left:0em;display: flex ">
                        <div style="white-space:nowrap;cursor: pointer" class="divHover" title="{{ p.vendorName }}">{{p.vendorRoleDescription.substr(0,1)+ ': '+p.vendorName}}</div>
                        <div></div>
                      </div>
                    </div>
                    <div *ngIf="element.isDepartureHomeBaseAirport" style="border-radius: 0.5em; width: 6.25em; margin-bottom:0.125em; height: 1.25em; border: gray solid 0.0625em;background-color:#19967d; color: white; padding-left:0.5em"><div style="margin-top:-0.3em; margin-left:0em">Home Base</div></div>
                    <div style="display: flex">
                      <div *ngIf="element.departureStatusDescription=='Actual'" style="border-radius: 0.5em;min-width: 6em; height: 1.25em; border: gray solid 0.0625em;" [style.background-color]="element.departureStatusColorCode">
                        <div style="margin-top:-0.3em; text-align:center">{{element.departureStatusDescription}}</div>
                      </div>
                      <div></div>
                    </div>
                    <div *ngIf="element.departureOnHold" style="margin-top:0.1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
                      <div style="margin-top:-0.3em; text-align:center">On Hold</div>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            
            <ng-container matColumnDef="arrAirport">
              <th mat-header-cell *matHeaderCellDef style="width:22%; text-align:left !important">
                <div style="margin-top:-0.25em;">Arrival</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important">
                <div style="display: flex">
                  <div>
                    <div style="display: flex">
                      <div *ngIf="element.nextArrivalICAO!='XXXX'" class="divHover" style="cursor: pointer;font-weight: bold">{{element.nextArrivalICAO_IATA}}</div>
                      <div *ngIf="element.nextArrivalICAO=='XXXX'" style="font-weight: bold">{{element.nextArrivalICAO_IATA}}</div>
                      <div></div>
                    </div>
                    <div style="display:flex">
                      <div style="font-weight: bold">{{element.nextArrivalDateTimeUTCText}}</div>
                      <div style="margin-left:0.5em">{{element.nextArrivalUTCConversion}}</div>
                    </div>
                    <div>{{element.nextArrivalDateTimeLocalText}}</div>
                    <div *ngIf="element.nextArrivalVendorList.length>0">
                      <div *ngFor="let p of element.nextArrivalVendorList" style="margin-left:0em;display: flex">
                        <div style="white-space:nowrap;cursor: pointer" class="divHover" title="{{ p.vendorName }}">{{p.vendorRoleDescription.substr(0,1)+ ': '+p.vendorName}}</div>
                        <div></div>
                      </div>
                    </div>
                    <div *ngIf="element.isNextArrivalHomeBaseAirport" style="border-radius: 0.5em; width: 6.25em; margin-bottom:0.125em; height: 1.25em; border: gray solid 0.0625em;background-color:#19967d; color: white; padding-left:0.5em"><div style="margin-top:-0.3em; margin-left:0em">Home Base</div></div>
                    <div style="display: flex">
                      <div *ngIf="element.nextArrivalStatusDescription=='Actual'" style="border-radius: 0.5em;min-width: 6em; height: 1.25em; border: gray solid 0.0625em;" [style.background-color]="element.nextArrivalStatusColorCode">
                        <div style="margin-top:-0.3em; text-align:center">{{element.nextArrivalStatusDescription}}</div>
                      </div>
                      <div></div>
                    </div>
                    <div *ngIf="element.nextArrivalOnHold" style="margin-top:0.1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
                      <div style="margin-top:-0.3em; text-align:center">On Hold</div>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            
            <ng-container matColumnDef="pic_sic">
              <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important">
                <div style="margin-top:-0.25em;">PIC / SIC</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important; cursor: pointer" >
                <div *ngIf="element.picPersonGUID!=''" title="{{element.picPersonName}}">{{'P: '+element.picPersonNameAbbr}}</div>
                <div *ngIf="element.sicPersonGUID!=''" title="{{element.sicPersonName}}">{{'S: '+element.sicPersonNameAbbr}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="crewCount">
              <th mat-header-cell *matHeaderCellDef style="width:3%;">
                <div style="margin-top:-0.25em;">Crew</div>
              </th>
              <td mat-cell *matCellDef="let element" style="cursor: pointer" >
                <div *ngIf="element.crewCount>0">{{element.crewCount}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paxCount">
              <th mat-header-cell *matHeaderCellDef style="width:3%;">
                <div style="margin-top:-0.25em;">PAX</div>
              </th>
              <td mat-cell *matCellDef="let element" style="cursor: pointer" >
                <div *ngIf="element.paxCount>0">{{element.paxCount}}</div>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="legStatus">
              <th mat-header-cell *matHeaderCellDef style="width:3%; text-align:left !important">
                <div style="margin-top:-0.25em;">Leg</div>
                <div style="margin-top:-0.25em;">Status</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important; cursor: pointer" >
                <!--<span style="cursor:pointer" (click)="openChecklist(element)" class="material-icons-two-tone {{element.checklistStatusIconColor}}">
        {{element.checklistStatusIconName}}
      </span>-->
                <span class="material-icons-two-tone {{element.checklistStatusIconColor}}">
                  {{element.checklistStatusIconName}}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="tripCode">
              <th mat-header-cell *matHeaderCellDef style="width:8%; text-align:left !important">
                <div style="margin-top:-0.25em;">Trip Codes</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important; cursor: pointer" >
                <div style="font-weight:bold">{{element.tripCode}}</div>
                <div>{{element.tripCloseOutDescription}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="clientName">
              <th mat-header-cell *matHeaderCellDef style="width:15%; text-align:left !important">
                <div style="margin-top:-0.25em;">Client Account</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important; cursor: pointer" >
                <div style="font-weight:bold">{{element.customerName}}</div>
                <div style="font-size:x-small;margin-top:-0.25em; white-space:nowrap">{{element.tripCloseOutDescription}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width:3%">
                <div>Action</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div style="margin-top:0.25em">
                  <a mat-button class="btn-low grid" style="align-self:center" >
                    Open
                  </a>
                </div>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickOpen($event, row)" style="cursor:pointer"></tr>
        </table>
      </div>
    </div>
  </form>
</div>
<!--<div style="z-index:100; top: 23.5em ; left: 32em; position:absolute; min-width: 30em">
  <app-spin *ngIf="showSpin"></app-spin>
</div>-->
<div style="z-index:100; margin-top: 0.5em ; margin-left: 40em; max-width: 20em">
  <app-spin *ngIf="showSpin"></app-spin>
</div>
