import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AircraftEquipmentModel } from '../models/aircraft-equipment.model';
import { AircraftNoteModel } from '../models/aircraft-note.model';
import { AircraftModel } from '../models/aircraft.model';
import { ResponseModel } from '../models/response.model';
import { AircraftService } from '../services/aircraft.service';
import { AuthenticateService } from '../services/authenticate.service';
import { DialogService } from '../services/dialog.service';

export interface GroupBy {
  subCategory: string;
  selected: boolean;
  aircraftEquipmentChildID: number;
  shortName: string;
  longName: string;
  isGroupBy: boolean;
  isDisabled: boolean;
}
export interface EquipmentList {
  selected: boolean;
  aircraftEquipmentChildID: number;
  shortName: string;
  longName: string;
  aircraftEquipmentParentID: number;
  isDisabled: boolean;
}
@Component({
  selector: 'app-aircraft-equipment',
  templateUrl: './aircraft-equipment.component.html',
  styleUrls: ['./aircraft-equipment.component.css']
})
/** aircraft-equipment component*/
export class AircraftEquipmentComponent implements OnInit, OnChanges {
  aircraft_equipment_form: UntypedFormGroup;
  @Input() aircraftId: string;
  customerGuid: string;
  textSearch: string;
  filterBy: string;
  errMsg: string;
  showSpin: boolean;
  loading: boolean;
  msg: string;
  userType: string;
  subCat: string = "";
  wakeCategoryString: string;
  wakeCategoryTooltip: string;
  radioTooltip: string;
  radioString: string;
  surString: string;
  surTooltip: string;
  pbnString: string;
  pbnTooltip: string;
  navString: string;
  comString: string;
  datString: string;
  addlSurString: string;
  rnavString: string;
  rnavTooltip: string;
  emergString: string;
  remarks: string;
  departure: string;
  enroute: string;
  arrival: string;
  color: string;
  dinghy_number: number;
  dinghy_capacity: number;
  dinghy_color: number;
  cover: boolean = false;
  polar: boolean = false;
  desert: boolean = false;
  maritime: boolean = false;
  jungle: boolean = false;
  light: boolean = false;
  floures: boolean = false;
  vhf: boolean = false;
  uhf: boolean = false;
  uhf_radio: boolean = false;
  vhf_radio: boolean = false;
  elt_radio: boolean = false;
  isDisabled: boolean = false;
  navRemarks: string;
  comRemarks: string;
  datRemarks: string;
  surRemarks: string;
  navDisabled: boolean = false;
  comDisabled: boolean = false;
  datDisabled: boolean = false;
  surDisabled: boolean = false;
  submitted: boolean = false;
  selectedEquip: string;
  selectedWake: string;
  selectedRadio: string;
  selectedSur: string;
  selectedPBN: string;
  showSuccessMsg: boolean = false;
  controlValueChanged: boolean = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Input() includeDisabledRecords: boolean;
  tableList: (EquipmentList | GroupBy)[];
  recordList: AircraftEquipmentModel[];
  recordListChild: AircraftEquipmentModel[];
  displayedColumns: string[] = ['select', 'shortName', 'longName'];
  isModified: boolean;
  dataSource = new MatTableDataSource<(EquipmentList | GroupBy)>();
  selection = new SelectionModel<(EquipmentList | GroupBy)>(true, []);
  dataSourceWake = new MatTableDataSource<(EquipmentList | GroupBy)>();
  selectionWake = new SelectionModel<(EquipmentList | GroupBy)>(true, []);
  dataSourceRadio = new MatTableDataSource<(EquipmentList | GroupBy)>();
  selectionRadio = new SelectionModel<(EquipmentList | GroupBy)>(true, []);
  dataSourceSur = new MatTableDataSource<(EquipmentList | GroupBy)>();
  selectionSur = new SelectionModel<(EquipmentList | GroupBy)>(true, []);
  dataSourcePBN = new MatTableDataSource<(EquipmentList | GroupBy)>();
  selectionPBN = new SelectionModel<(EquipmentList | GroupBy)>(true, []);
  isFormDisabled: boolean = false;

  /** aircraft-notes ctor */
  constructor(private _router: Router, private readonly _authService: AuthenticateService, private readonly _aircraftService: AircraftService, private readonly _dialogService: DialogService,
    private readonly _formBuilder: UntypedFormBuilder, private _route: ActivatedRoute, private readonly _dialog: MatDialog) {

  }

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    if (this.step == 1)
      this.populateDAT();
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  populateDAT() {
    let hasJ = false;
    this.dataSourceRadio.data.forEach(v => {
      if (v.aircraftEquipmentChildID >= 12 && v.aircraftEquipmentChildID <= 16)
        if (v.selected) {
          hasJ = true;
        }
    });
    if (this.dataSourceRadio.data.find(v => v.aircraftEquipmentChildID == 8).selected) {
      if (this.f.datRemarks.value == null || this.f.datRemarks.value == "") {
        if (hasJ)
          this.f.datRemarks.setValue("DAT / 1FANS2PDC");
        else
          this.f.datRemarks.setValue("DAT / 1PDC");
      }

    }
    else {
      if (this.f.datRemarks.value == null || this.f.datRemarks.value == "") {
        if (hasJ)
          this.f.datRemarks.setValue("DAT / 1FANS");        
      }
    }

    if (this.dataSourceRadio.data.find(v => v.aircraftEquipmentChildID == 8).selected || hasJ) {
      if (this.dataSourceRadio.data.find(v => v.aircraftEquipmentChildID == 37).selected == false) {
        this.dataSourceRadio.data.find(v => v.aircraftEquipmentChildID == 37).selected = true
        this.radioString += this.dataSourceRadio.data.find(v => v.aircraftEquipmentChildID == 37).shortName;
        this.radioTooltip += this.dataSourceRadio.data.find(v => v.aircraftEquipmentChildID == 37).longName;
      }
    }
    
  }

  toggleSUR() {
    //let hasJ = false;
    //let hasP = false;
    //let has10a = false;
    //let has10b = false;
    //this.dataSourceRadio.data.forEach(v => {
    //  if (v.aircraftEquipmentChildID >= 10 && v.aircraftEquipmentChildID <= 16)
    //    if (v.selected) {
    //      hasJ = true;
    //    }
    //  if (v.aircraftEquipmentChildID >= 87 && v.aircraftEquipmentChildID <= 88)
    //    if (v.selected) {
    //      hasP = true;
    //    }
    //});

    //if (hasJ && hasP)
    //  has10a = true;

    //if (this.dataSourceSur.data.find(v => v.aircraftEquipmentChildID == 54).selected || this.dataSourceSur.data.find(v => v.aircraftEquipmentChildID == 55))
    //  has10b = true;

    //if (has10a && has10b)
    if (this.dataSourceSur.data.find(v => v.aircraftEquipmentChildID == 54).selected || this.dataSourceSur.data.find(v => v.aircraftEquipmentChildID == 48).selected || this.dataSourceSur.data.find(v => v.aircraftEquipmentChildID == 49).selected)
      this.surDisabled = false;
    else
      this.surDisabled = true;
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.aircraft_equipment_form = this._formBuilder.group({

    });

    // this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
      this.includeDisabledRecords = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        this.isFormDisabled = true;
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
   // this.initControls();
    this.getData();
 
   

  }
  get f() { return this.aircraft_equipment_form.controls; }

  initControls() {
    this.aircraft_equipment_form = this._formBuilder.group({
      comRemarks: { value: this.comRemarks, disabled: this.isFormDisabled },
      navRemarks: { value: this.navRemarks, disabled: this.isFormDisabled },
      datRemarks: { value: this.datRemarks, disabled: this.isFormDisabled },
      surRemarks: { value: this.surRemarks, disabled: this.isFormDisabled },
      departure: { value: this.departure, disabled: this.isFormDisabled },
      enroute: { value: this.enroute, disabled: this.isFormDisabled },
      arrival: { value: this.arrival, disabled: this.isFormDisabled },
      colors: { value: this.color, disabled: this.isFormDisabled },
      dinghy_number: { value: this.dinghy_number, disabled: this.isFormDisabled },
      dinghy_capacity: { value: this.dinghy_capacity, disabled: this.isFormDisabled },
      dinghy_color: { value: this.dinghy_color, disabled: this.isFormDisabled },
      dinghy_cover: { value: this.cover, disabled: this.isFormDisabled },
      polar: { value: this.polar, disabled: this.isFormDisabled },
      desert: { value: this.desert, disabled: this.isFormDisabled },
      maritime: { value: this.maritime, disabled: this.isFormDisabled },
      jungle: { value: this.jungle, disabled: this.isFormDisabled }, 
      light: { value: this.light, disabled: this.isFormDisabled },
      floures: { value: this.floures, disabled: this.isFormDisabled },
      uhf: { value: this.uhf, disabled: this.isFormDisabled },
      vhf: { value: this.vhf, disabled: this.isFormDisabled },
      uhf_radio: { value: this.uhf_radio, disabled: this.isFormDisabled },
      vhf_radio: { value: this.vhf_radio, disabled: this.isFormDisabled },
      elt_radio: { value: this.elt_radio, disabled: this.isFormDisabled }
    });
    this.checkFormControlValueChanged();
  }

  getData() {
    this._aircraftService.getAircraftEquipmentListByaId<ResponseModel<AircraftEquipmentModel[]>>(this.aircraftId).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          if (response.model.length > 0) {
            this.recordList = response.model;
            for (let i = 0; i < this.recordList.length; i++) {
              switch (this.recordList[i].aircraftEquipmentParentID) {
                case 1:
                  this.getChildData(1);
                  this.dataSourceWake = this.dataSource;
                  this.selectionWake = new SelectionModel<(EquipmentList | GroupBy)>(true, this.dataSourceWake.data);
                  this.wakeCategoryString = this.recordList[i].aircraftEquipmentString;
                  this.wakeCategoryTooltip = this.recordList[i].aircraftEquipmentTooltip;
                  break;
                case 2:
                  this.getChildData(2);
                  this.dataSourceRadio = this.dataSource;
                  this.selectionRadio = new SelectionModel<(EquipmentList | GroupBy)>(true, this.dataSourceRadio.data);
                  this.radioString = this.recordList[i].aircraftEquipmentString;
                  this.radioTooltip = this.recordList[i].aircraftEquipmentTooltip;

                  break;
                case 3:
                  this.getChildData(3);
                  this.dataSourceSur = this.dataSource;
                  this.selectionSur = new SelectionModel<(EquipmentList | GroupBy)>(true, this.dataSourceSur.data);
                  this.surString = this.recordList[i].aircraftEquipmentString;
                  this.surTooltip = this.recordList[i].aircraftEquipmentTooltip;
                  break;
                case 4:
                  this.getChildData(4);
                  this.dataSourcePBN = this.dataSource;
                  this.selectionPBN = new SelectionModel<(EquipmentList | GroupBy)>(true, this.dataSourcePBN.data);
                  this.pbnString = this.recordList[i].aircraftEquipmentString;
                  this.pbnTooltip = this.recordList[i].aircraftEquipmentTooltip;
                  break;
                case 5:
                  this.getChildData(5);
                  this.navString = this.recordList[i].aircraftEquipmentString;
                  break;
                case 6:
                  this.getChildData(6);
                  this.comString = this.recordList[i].aircraftEquipmentString;
                  break;
                case 7:
                  this.getChildData(7);
                  this.datString = this.recordList[i].aircraftEquipmentString;
                  break;
                case 8:
                  this.getChildData(8);
                  this.addlSurString = this.recordList[i].aircraftEquipmentString;
                  break;
                case 9:
                  this.getChildData(9);
                  this.rnavString = this.recordList[i].aircraftEquipmentString;
                  this.rnavTooltip = this.recordList[i].aircraftEquipmentTooltip;
                  break;
                case 10:
                  this.getChildData(10);
                  this.emergString = this.recordList[i].aircraftEquipmentString;
                  break;
              }
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });

    this.initControls();
    


  }


  checkFormControlValueChanged(): void {
    this.aircraft_equipment_form.valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
  }

  getChildData(parentId: number) {

    let request = new AircraftEquipmentModel();
    request.aircraftGuid = this.aircraftId;
    request.aircraftEquipmentParentID = parentId;
    //  this.tableList = undefined;

    this._aircraftService.getAircraftEquipmentByParent<ResponseModel<AircraftEquipmentModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.recordListChild = response.model;
        }
      }
      if (parentId < 5) {
        this.recordListChild.forEach(v =>
          this.buildDataSource(v, parentId)
        );

        this.dataSource = new MatTableDataSource<(EquipmentList | GroupBy)>(this.tableList);
      }
      switch (parentId) {
        case 1:
          this.tableList = undefined;
          this.dataSourceWake = this.dataSource;
          this.selectionWake = new SelectionModel<(EquipmentList | GroupBy)>(true, this.dataSourceWake.data);
          break;
        case 2:
          this.tableList = undefined;
          this.dataSourceRadio = this.dataSource;
          this.selectionRadio = new SelectionModel<(EquipmentList | GroupBy)>(true, this.dataSourceRadio.data);
          break;
        case 3:
          this.tableList = undefined;
          this.dataSourceSur = this.dataSource;
          this.selectionSur = new SelectionModel<(EquipmentList | GroupBy)>(true, this.dataSourceSur.data);
          break;
        case 4:
          this.tableList = undefined;
          this.dataSourcePBN = this.dataSource;
          this.selectionPBN = new SelectionModel<(EquipmentList | GroupBy)>(true, this.dataSourcePBN.data);
          break;
        case 5:
          this.navRemarks = this.recordListChild[0].remarks;
          this.navDisabled = this.recordListChild[0].isDisabled;
          break;
        case 6:
          this.comRemarks = this.recordListChild[0].remarks;
          this.comDisabled = this.recordListChild[0].isDisabled;
          break;
        case 7:
          this.datRemarks = this.recordListChild[0].remarks;
          this.datDisabled = this.recordListChild[0].isDisabled;
          break;
        case 8:
          this.surRemarks = this.recordListChild[0].remarks;
          this.surDisabled = this.recordListChild[0].isDisabled;
          break;
        case 9:
          this.departure = this.recordListChild.find(v => v.aircraftEquipmentChildID == 84).remarks;
          this.enroute = this.recordListChild.find(v => v.aircraftEquipmentChildID == 85).remarks;
          this.arrival = this.recordListChild.find(v => v.aircraftEquipmentChildID == 86).remarks;
          this.initControls();
          break;
        case 10:
          this.color = this.recordListChild[0].color;
          this.dinghy_number = this.recordListChild[0].dinghyNumber;
          this.dinghy_capacity = this.recordListChild[0].dinghyCapacity;
          this.dinghy_color = this.recordListChild[0].dinghyColor;
          this.cover = this.recordListChild[0].cover;
          this.polar = this.recordListChild[0].polar;
          this.desert = this.recordListChild[0].desert;
          this.maritime = this.recordListChild[0].maritime;
          this.jungle = this.recordListChild[0].jungle;
          this.light = this.recordListChild[0].light;
          this.floures = this.recordListChild[0].floures;
          this.vhf = this.recordListChild[0].vhf;
          this.uhf = this.recordListChild[0].uhf
          this.uhf_radio = this.recordListChild[0].uhfRadio;
          this.vhf_radio = this.recordListChild[0].vhfRadio;
          this.elt_radio = this.recordListChild[0].eltRadio;
          this.initControls();
          break;
      }

    });


  }

  buildDataSource(v: AircraftEquipmentModel, parentId: number) {

    if (this.subCat != v.aircraftEquipmentSubcategory) {
      if (this.tableList == undefined || this.tableList.length == 0) {
        this.tableList = [{ subCategory: v.aircraftEquipmentSubcategory, isGroupBy: true, selected: false, aircraftEquipmentChildID: 0, shortName: '', longName: '', isDisabled: false }];
      }
      else {
        this.tableList = this.tableList.concat([{ subCategory: v.aircraftEquipmentSubcategory, isGroupBy: true, selected: false, aircraftEquipmentChildID: 0, shortName: '', longName: '', aircraftEquipmentParentID: 0, isDisabled: false }]);
      }
    }
    if (this.tableList == undefined) {
      this.tableList = [{ selected: v.selected, aircraftEquipmentChildID: v.aircraftEquipmentChildID, shortName: v.shortName, longName: v.longName, aircraftEquipmentParentID: parentId, isDisabled: v.isDisabled }];
    }
    else {
      this.tableList = this.tableList.concat([{ selected: v.selected, aircraftEquipmentChildID: v.aircraftEquipmentChildID, shortName: v.shortName, longName: v.longName, aircraftEquipmentParentID: parentId, isDisabled: v.isDisabled }]);
      this.subCat = v.aircraftEquipmentSubcategory;
    }



  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }



  selectWakeChange(event: any, rowWake: (AircraftEquipmentModel)) {
    this.controlValueChanged = true;
    rowWake.selected = event.checked;
    this.wakeCategoryString = "";
    this.wakeCategoryTooltip = "";

    this.selectionWake.toggle(rowWake);
    this.dataSourceWake.data.forEach(v => {
      if (v.selected && v.aircraftEquipmentChildID != rowWake.aircraftEquipmentChildID) {
        v.selected = false;
      }
      if (rowWake.selected) {
        this.wakeCategoryString = rowWake.shortName;
        this.wakeCategoryTooltip = rowWake.shortName + '=' + rowWake.longName;
      }

    });

  }

  selectRadioChange(event: any, rowRadio: (AircraftEquipmentModel)) {
    this.controlValueChanged = true;
    rowRadio.selected = event.checked;

    this.selectionRadio.toggle(rowRadio);
    this.radioString = "";
    this.radioTooltip = "";

    this.isDisabled = false;
    if (rowRadio.aircraftEquipmentChildID == 4 && rowRadio.selected == true) {
      this.dataSourceRadio.data.find(v => v.aircraftEquipmentChildID == 5).selected = false;
    }
    if (rowRadio.aircraftEquipmentChildID == 5 && rowRadio.selected == true) {
      this.dataSourceRadio.data.find(v => v.aircraftEquipmentChildID == 4).selected = false;
    }
    if (this.dataSourceRadio.data.find(v => v.aircraftEquipmentChildID == 4).selected == false && this.dataSourceRadio.data.find(v => v.aircraftEquipmentChildID == 5).selected == false)
      this.isDisabled = true;
    this.dataSourceRadio.data.forEach(v => {
      if (v.aircraftEquipmentChildID != 5 && v.aircraftEquipmentChildID != 4) {
        v.isDisabled = this.isDisabled;
        if (this.isDisabled == true)
          v.selected = false;
      }
      if (v.selected) {
        this.radioString += v.shortName;
        this.radioTooltip += v.shortName + '=' + v.longName + '\n';
      }
    });
    if (rowRadio.aircraftEquipmentChildID == 37 && rowRadio.selected == false) {
      this.f.navRemarks.setValue('');
      this.f.datRemarks.setValue('');
      this.f.comRemarks.setValue('');
      this.f.surRemarks.setValue('');
      this.f.navRemarks.disable();
      this.f.comRemarks.disable();
      this.f.datRemarks.disable();
      this.f.surRemarks.disable();
    }
    else {
      this.f.navRemarks.enable();
      this.f.comRemarks.enable();
      this.f.datRemarks.enable();
      this.f.surRemarks.enable();
    }

    this.toggleSUR();
  }

  selectSurChange(event: any, rowSur: (AircraftEquipmentModel)) {
    this.controlValueChanged = true;
    rowSur.selected = event.checked;
    this.surString = "";
    this.surTooltip = "";

    this.selectionSur.toggle(rowSur);

    this.isDisabled = false;
    if (rowSur.aircraftEquipmentChildID == 38) {
      this.isDisabled = rowSur.selected;
      this.dataSourceSur.data.forEach(v => {
        if (v.aircraftEquipmentChildID != 38) {
          v.isDisabled = this.isDisabled;
          if (this.isDisabled == true)
            v.selected = false;
        }
      });
    }

    if (rowSur.aircraftEquipmentChildID >= 39 && rowSur.aircraftEquipmentChildID <= 47 && rowSur.selected) {
      this.dataSourceSur.data.forEach(v => {
        if (v.aircraftEquipmentChildID != rowSur.aircraftEquipmentChildID && v.aircraftEquipmentChildID >= 39 && v.aircraftEquipmentChildID <=47) {
          v.selected = false;
        }
      });
    }

    if (rowSur.aircraftEquipmentChildID >= 48 && rowSur.aircraftEquipmentChildID <= 53 && rowSur.selected) {
      this.dataSourceSur.data.forEach(v => {
        if (v.aircraftEquipmentChildID != rowSur.aircraftEquipmentChildID && v.aircraftEquipmentChildID >= 48 && v.aircraftEquipmentChildID <= 53) {
          v.selected = false;
        }
      });
    }

    //if (rowSur.aircraftEquipmentChildID == 54 && rowSur.selected == true) {
    //  this.dataSourceSur.data.find(v => v.aircraftEquipmentChildID == 55).selected = false;
    //}
    //if (rowSur.aircraftEquipmentChildID == 55 && rowSur.selected == true) {
    //  this.dataSourceSur.data.find(v => v.aircraftEquipmentChildID == 54).selected = false;
    //}

    if (this.dataSourceSur.data.find(v => v.aircraftEquipmentChildID == 41).selected || this.dataSourceSur.data.find(v => v.aircraftEquipmentChildID == 42).selected || this.dataSourceSur.data.find(v => v.aircraftEquipmentChildID == 44).selected || this.dataSourceSur.data.find(v => v.aircraftEquipmentChildID == 46).selected) {
      this.isDisabled = false;
    }
    else {
      this.isDisabled = true;
    }
    this.dataSourceSur.data.forEach(v => {
      if (v.aircraftEquipmentChildID >= 48 && v.aircraftEquipmentChildID <= 55) {
        v.isDisabled = this.isDisabled;
        if (this.isDisabled)
          v.selected = false;
      }
      if (v.selected) {
        this.surString += v.shortName;
        this.surTooltip += v.shortName + '=' + v.longName + '\n';
      }
    });

    this.toggleSUR();
    
  }

  selectPBNChange(event: any, rowPBN: (AircraftEquipmentModel)) {
    this.controlValueChanged = true;
    rowPBN.selected = event.checked;

    this.selectionPBN.toggle(rowPBN);
    this.pbnString = "";
    this.pbnTooltip = "";
    this.dataSourcePBN.data.forEach(v => {
      
      if (v.selected) {
        this.pbnString += v.shortName;
        this.pbnTooltip += v.shortName + '=' + v.longName + '\n';
      }
    });
  }


  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";
    this.selectedEquip = "";
    this.selectedWake = "";
    this.selectedRadio = "";
    this.selectedSur = "";
    this.selectedPBN = "";
    let hasRadio = false;
    let obj = new AircraftEquipmentModel();
    this.dataSourceWake.data.forEach(row => {
      if (row.selected) {
        if (this.selectedWake != "")
          this.selectedWake += ",";
        this.selectedWake += row.aircraftEquipmentChildID.toString();
      }
    });

    this.selectedEquip = this.selectedWake;
    if (this.selectedWake == "")
      this.errMsg = "You must select a Wake Category.";

    this.dataSourceRadio.data.forEach(row => {
      if (row.selected) {
        if (row.aircraftEquipmentChildID == 4 || row.aircraftEquipmentChildID == 5)
          hasRadio = true;
        if (this.selectedRadio != "")
          this.selectedRadio += ",";
        this.selectedRadio += row.aircraftEquipmentChildID.toString();
      }
    });


    if (this.selectedRadio != "") {
      if (this.selectedEquip != "")
        this.selectedEquip += ",";
      this.selectedEquip += this.selectedRadio;
    }

    if (hasRadio == false) {
      if (this.errMsg != "")
        this.errMsg += "\n";
      this.errMsg += "You must select a Radio Navigation option.";
    }
    this.dataSourceSur.data.forEach(row => {
      if (row.selected) {
        if (this.selectedSur != "")
          this.selectedSur += ",";
        this.selectedSur += row.aircraftEquipmentChildID.toString();
      }
    });


    if (this.selectedSur != "") {
      if (this.selectedEquip != "")
        this.selectedEquip += ",";
      this.selectedEquip += this.selectedSur;
    }


    this.dataSourcePBN.data.forEach(row => {
      if (row.selected) {
        if (this.selectedPBN != "")
          this.selectedPBN += ",";
        this.selectedPBN += row.aircraftEquipmentChildID.toString();
      }
    });


    if (this.selectedPBN != "") {
      if (this.selectedEquip != "")
        this.selectedEquip += ",";
      this.selectedEquip += this.selectedPBN;
    }


    if (this.errMsg != "")
      return;

    obj.aircraftGuid = this.aircraftId;
    obj.equipmentList = this.selectedEquip;
    obj.navRemarks = this.f.navRemarks.value;
    obj.comRemarks = this.f.comRemarks.value;
    obj.datRemarks = this.f.datRemarks.value;
    obj.surRemarks = this.f.surRemarks.value;

    obj.departure = this.f.departure.value;
    obj.enroute = this.f.enroute.value;
    obj.arrival = this.f.arrival.value;

    obj.color = this.f.colors.value;
    obj.dinghyNumber = this.f.dinghy_number.value;
    obj.dinghyCapacity = this.f.dinghy_capacity.value;
    obj.dinghyColor = this.f.dinghy_color.value;
    obj.cover = this.f.dinghy_cover.value;
    obj.polar = this.f.polar.value;
    obj.desert = this.f.desert.value;
    obj.maritime = this.f.maritime.value;
    obj.jungle = this.f.jungle.value;
    obj.light = this.f.light.value;
    obj.floures = this.f.floures.value;
    obj.uhf = this.f.uhf.value;
    obj.vhf = this.f.vhf.value;
    obj.uhfRadio = this.f.uhf_radio.value;
    obj.vhfRadio = this.f.vhf_radio.value;
    obj.eltRadio = this.f.elt_radio.value;

    this._aircraftService.saveAircraftEquipment<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.aircraftId = response.model;
          //this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          this.isModified = true;
          this.controlValueChanged = false;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
        //  this.getData();

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    });




  }


}
