<div style="margin-top:-1em !important;padding-top:1em;padding-bottom:1.5em;" cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle>
  <div class="d-inline-flex">
    <h5>
      Add Tasks From Business Rule
    </h5>
    <div *ngIf="groundStopGUID==undefined">
      <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="hidePastStop" (change)="hidePastStopChange($event)">Hide Prior Legs<!--Hide Past Stops--></mat-slide-toggle>
      <!--<mat-slide-toggle style="transform:scale(.8); height:2em; margin-left:-1em" color="primary" [checked]="includeActiveStopOnly" (change)="includeActiveStopOnlyChange($event)">Show Active Legs Only</mat-slide-toggle>-->
    </div>
  </div>
</div>
<div mat-dialog-content style="background-color:white;margin-left:.1em;margin-right:.1em;margin-top:-1em;margin-bottom:-.9em;border-radius:.25em">
  <div  style="font-size:small" *ngIf="allTaskList && allTaskList.length==0">None</div>
  <ng-template ngFor let-tl [ngForOf]="allTaskList">
    <mat-card class="mt-2 mb-2" *ngIf="!hidePastStop || hidePastStop && !tl.isPastLeg">
      <mat-card-title style="font-size:medium !important">{{tl.departureICAO}}/{{tl.arrivalICAO}} {{tl.departureDateTime}} - {{tl.arrivalDateTime}}</mat-card-title>
      <mat-card-content>
        <div class="d-inline-flex" *ngIf="tl.taskListDep!=null && tl.taskListArr!=null">
          <div class="d-flex flex-column p-2"  style="min-width: 20em">
            <label>{{tl.departureICAO}}</label>
            <div style="font-size:small" *ngIf="tl.taskListDep.length==0">No Services</div>
            <div  *ngIf="tl.taskListDep.length>0" class="d-flex">
              <div class="d-flex flex-column">
                <label style="font-size:small">Departure Serivces</label>
                <ng-template ngFor let-task [ngForOf]="tl.taskListDep" class="pb-1" style="margin-left:-1em">
                  <mat-slide-toggle [checked]="task.selected" [disabled]="!task.disabled" color="primary" (change)="setSelectedServices(task,false,$event)" style="line-height: 1.5em; transform: scale(.9) ">
                    <span matTooltip="{{task.internalNote}}">{{task.serviceTypeDescription}}{{task.vendorName!=null?'-'+ task.vendorName:''}} {{task.taskStatusGUID =='144b6b7d-94cb-44ff-bcf4-4006001c5ab6' || task.taskStatusGUID==null || task.taskStatusGUID==''? '':'('+ task.taskStatusDescription + ')'}}<span *ngIf="task.internalNote!=null && task.internalNote!=''" class="material-icons-outlined" style="font-size: medium; margin-top: .25em">info</span></span>
                  </mat-slide-toggle>
                </ng-template>
              </div>
              <div class="d-flex flex-column align-items-center">
                <label style="font-size:small">Not Required</label>
                <ng-template ngFor let-task [ngForOf]="tl.taskListDep" class="pb-1" style="margin-left:-1em">
                  <mat-checkbox [disabled]="!task.disabled" color="primary" style="line-height: 1.5em; transform: scale(.9) " (change)="setSelectedServices(task,true,$event)"></mat-checkbox>
                </ng-template>

              </div>
            </div>
            </div>
          <div class="d-flex flex-column p-2"  style="min-width: 20em">
            <label>{{tl.arrivalICAO}}</label>
            <div style="font-size:small" *ngIf="tl.taskListArr.length==0">No Services</div>
            <div  *ngIf="tl.taskListArr.length>0" class="d-flex">
              <div class="d-flex flex-column">
                <label style="font-size:small">Arrival Serivces</label>
                <ng-template ngFor let-task [ngForOf]="tl.taskListArr" class="pb-1" style="margin-left:-1em">
                  <mat-slide-toggle [checked]="task.selected" [disabled]="!task.disabled" color="primary" (change)="setSelectedServices(task,false,$event)" style="line-height: 1.5em; transform: scale(.9) ">
                    <span matTooltip="{{task.internalNote}}">{{task.serviceTypeDescription}}{{task.vendorName!=null?'-'+ task.vendorName:''}} {{task.taskStatusGUID =='144b6b7d-94cb-44ff-bcf4-4006001c5ab6' || task.taskStatusGUID==null || task.taskStatusGUID==''? '':'('+ task.taskStatusDescription + ')'}}<span *ngIf="task.internalNote!=null && task.internalNote!=''" class="material-icons-outlined" style="font-size: medium; margin-top: .25em">info</span></span>
                  </mat-slide-toggle>
                </ng-template>
              </div>
              <div class="d-flex flex-column align-items-center">                
                <label style="font-size:small">Not Required</label>
                  <ng-template ngFor let-task [ngForOf]="tl.taskListArr" class="pb-1" style="margin-left:-1em">
                    <mat-checkbox [disabled]="!task.disabled" color="primary" style="line-height: 1.5em; transform: scale(.9) " (change)="setSelectedServices(task,true,$event)"></mat-checkbox>
                  </ng-template>
                
              </div>
              </div>
            </div>
          
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button class="btn-high mr-2" [disabled]="submitted" (click)="clickSave()"><span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>SAVE</button>
  <button mat-button mat-dialog-close class="btn-low">CLOSE</button>
  <div class="ml-2">
    <app-success-message *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>
  </div>
  <div class="ml-2" *ngIf="errMsg!=''">
    <label class="alert alert-danger py-0 px-1">{{errMsg}}</label>
  </div>
</div>
