<div>
  <div style="display: flex; justify-content: space-between;padding-left:1em; margin-top:-0.5em;   " cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div>
      <h5>{{dialogTitle}}</h5>
    </div>


    <button mat-icon-button (click)="clickClose(false)"> <i class="material-icons" style="font-size: x-large;">close
      </i>
    </button>
  </div>
  <div style="padding: 0.5em;padding-bottom: 0em; background-color:white ; ">



    <div>
      <form *ngIf="advisory_tags_form" [formGroup]="advisory_tags_form">



        <div *ngIf="advisoryTagsList  && title !='hotel'">
          <div style="margin-bottom: 1em; padding-bottom: 0.5em; height: 32.5em; overflow: auto;">
            <table mat-table *ngIf="advisoryTagsList  && title !='hotel'" [dataSource]="advisoryTagsList"
              class="example-container " style="width: 30em; margin: 1em;">
              <tr>


                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef style="width:5%">
                    <div>Select</div>
                    <div style="margin-top:0em;padding-bottom:0.25em"></div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="width: 5%;">

                    <mat-checkbox (change)="tagOnChange($event, element)" [checked]="element.selected"> </mat-checkbox>

                  </td>
                </ng-container>


                <ng-container matColumnDef="advisoryTagClass">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important">
                    <div style="margin-top:-0.25em;">Type</div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 10%;">
                    <div style="display: flex;">
                      <div>{{element.advisoryTagTypeClass}}</div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="advisoryTag">
                  <th mat-header-cell *matHeaderCellDef style="width:15%; text-align:left !important">
                    <div style="margin-top:-0.25em;"> Tag Description</div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 15%;">
                    <div style="display: flex;">
                      <div>{{element.description}}</div>

                    </div>
                  </td>
                </ng-container>

              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>
          </div>
        </div>





        <!-- <div *ngIf="errMsg!=''" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;"><label style="color:black; font-size: small">{{errMsg}}</label></div> -->
        <!-- <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
        <app-success-message [successMsg]=""></app-success-message>
      </div> -->

      </form>
    </div>
  </div>

  <div style="display: flex;margin-top: 0.5em; ">
    <button class="btn-high" mat-button style="margin-left: 1em;" (click)="clickUpdateTags()">Update</button>
    <button class="btn-low" mat-button (click)="clickClose(false)"
      style="background-color:grey; color: white; margin-left: 1em;">Close</button>
  </div>


</div>