import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopService } from '../services/ground-stop.service';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { FileItem, FileUploader, ParsedResponseHeaders, FileUploadModule, FileSelectDirective } from 'ng2-file-upload';
import { FileModel } from '../models/file.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';
import { VendorModel } from '../models/vendor.model';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from 'file-saver';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
import { TripAuditDialogComponent } from '../ground-stops/trip-audit-dialog.component';
import { DatePipe } from '@angular/common';
import { GroundStopAdvancedClientService } from '../services/ground-stop-advanced-client.service';
import { HotelClientDialogComponent } from '../hotels/hotel-client-dialog.component';
import { EmailClientDialogComponent } from '../messages-client/email-client-dialog.component';
import { FeedbackDialogComponent } from '../common-utility/feedback-dialog.component';

const datepipe: DatePipe = new DatePipe('en-US')

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
var expressions = require("angular-expressions");
var assign = require("lodash/assign");
// define your filter functions here, for example
// to be able to write {clientname | lower}
expressions.filters.lower = function (input) {
  // This condition should be used to make sure that if your input is
  // undefined, your output will be undefined as well and will not
  // throw an error
  if (!input) return input;
  return input.toLowerCase();
};
function angularParser(tag) {
  tag = tag
    .replace(/^\.$/, "this")
    .replace(/( | )/g, "'")
    .replace(/( | )/g, '"');
  const expr = expressions.compile(tag);
  return {
    get: function (scope, context) {
      let obj = {};
      const scopeList = context.scopeList;
      const num = context.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = assign(obj, scopeList[i]);
      }
      return expr(scope, obj);
    },
  };
}

function parser(tag) {
  const lowerRegex = /\[lower\]$/;
  const upperRegex = /\[upper\]$/;
  let changeCase = "";
  if (lowerRegex.test(tag)) {
    changeCase = "lower";
    // transform tag from "user[lower]" to "user"
    tag = tag.replace(lowerRegex, "");
  }
  if (upperRegex.test(tag)) {
    changeCase = "upper";
    // transform tag from "user[upper]" to "user"
    tag = tag.replace(upperRegex, "");
  }
  if (changeCase != "") {
    return {
      get: function (scope) {
        let result = null;
        // scope will be {user: "John"}
        if (tag === ".") {
          result = scope;
        } else {
          // Here we use the property "user" of the object {user: "John"}
          result = scope[tag];
        }

        if (typeof result === "string") {
          if (changeCase === "upper") {
            return result.toUpperCase();
          } else if (changeCase === "lower") {
            return result.toLowerCase();
          }
        }
        return result;
      }
    }
  }
  if (tag === "$pageBreakExceptLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (!isLast) {
          return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }
  if (tag === "$pageBreak") {
    return {
      get(scope, context) {
        return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';

      },
    };
  }



  // We use the angularParser as the default fallback
  // If you don't wish to use the angularParser,
  // you can use the default parser as documented here:
  // https://docxtemplater.com/docs/configuration#default-parser
  return angularParser(tag);
}

@Component({
  selector: 'app-ground-stop-task-client-dialog',
  templateUrl: './ground-stop-task-client-dialog.component.html',
  styleUrls: ['./ground-stop-task-client-dialog.component.css']
})

export class GroundStopTaskClientDialogComponent implements OnInit {

  dialogTitle: string;
  customerGUID: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  tripCodeGUID: string;
  icao: string;
  serviceTypeID: number;
  serviceTypeDesc: string;
  vendorReq: boolean;

  ground_stop_task_edit_client_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg2: string;
  msg: string;
  showSpin: boolean = true;
  icao_iata: string;
  airportName: string;
  airportCountry: string;
  arrivalDateTime: string;
  departureDateTime: string;
  arrivalDateTimeLocal: string;
  departureDateTimeLocal: string;
  nextICAO_IATA: string;
  nextAirportName: string;
  nextCountry: string;
  nextCountryGUID: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string
  selectedTaskStatusGUID: string;
  taskStatusList: TripTaskStatusModel[];
  confirmation_number: string;
  service_date: string;// Date;
  service_time: string;
  requiresServiceDate: boolean = false;
  selectedTabName: string;
  public_note: string;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  recall_number: string;
  fpRecallNumberList: GroundStopTaskModel[];
  intoPlaneAgentList: VendorModel[];
  overflightCountryList: GroundStopTaskModel[];
  permit_order_number: string;
  selectedFBOGUID: string;
  selectedVendorGUID: string;
  selectedOverflightCountryGUID: string;
  selectedLandingCountryGUID: string;
  selectedLandingVendorGUID: string;
  selectedFPRecallNumber: string;
  selectedHotelGUID: string;
  selectedFuelSupplierGUID: string;
  selectedFuelSupplierName: string;
  selectedVendorName: string;
  selectedCountryName: string;
  selectedHotelName: string;
  selectedTaskStatusDescription: string;
  selectedFuelIntoPlaneAgentName: string;
  hasExistingDocument: boolean = false;
  countryGUID: string;
  serviceSubTitle: string;
  tripCode: string;
  hasTaskAudit: boolean;
  isModified: boolean = false;
  taskDocumentList: GroundStopTaskDocumentModel[];

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  userType: string;
  nextICAO: string;
  prevICAO: string;
  registration: string;
  lockdownTrip: boolean = false;

  leg: string;
  nextGroundStopGUID: string;
  upPII: boolean = false;
  onHoldText: string = "";
  taskLabel: string = "";
  showVendorToClient: boolean = false;
  taskColor: string = "";


  airportID: number;
  countryID: number;
  hasFeedback: boolean = false; 
  isPastTask: boolean = false;

  yourDate: Date  = new Date();
  // yourDate2: Date  = new Date();
 
  utcDate = this.datePipe.transform(this.yourDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ', 'UTC');

  targetArrivalDate: Date = new Date();
  targetDepartureDate: Date = new Date();
  serviceLocation: string = '';
  countryName: string = ''; 
  // this.targetDate  = new Date(this.arrivalDateTime);


  constructor(private readonly _dialogRef: MatDialogRef<GroundStopTaskClientDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService,
    private readonly _dialogService: DialogService, private datePipe: DatePipe, 
    private readonly _groundStopAdvancedClientService: GroundStopAdvancedClientService
  ) {
    this.groundStopGUID = _data.groundStopGUID;
    this.serviceTypeID = _data.serviceTypeID;
    this.vendorReq = _data.vendorReq;
    this.dialogTitle = _data.dialogTitle;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.icao = _data.icao;
    this.groundStopTaskGUID = _data.groundStopTaskGUID;
    this.countryGUID = _data.countryId;
    this.serviceTypeDesc = _data.serviceTypeDesc;
    this.customerGUID = _data.customerGUID;
    this.leg = _data.leg;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;

  }

  getAllData(): Observable<any[]> {
    let request = new GroundStopModel();
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    let getGSResponse = this._groundStopAdvancedService.getGroundStopForTaskEditBygsId<ResponseModel<GroundStopModel>>(request);
    let req2 = new GroundStopTaskModel();
    req2.groundStopTaskGUID = this.groundStopTaskGUID;
    req2.groundStopGUID = this.groundStopGUID
    let getTaskResponse = this._groundStopAdvancedClientService.getGroundStopTaskForClientBygstId(req2);
    let getTaskDocResponse = this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId(req2);

    //let getfpRecallNumberResponse;
    //if (this.serviceTypeID == 3) {
    //  getfpRecallNumberResponse = this._groundStopAdvancedService.getFlightPlanRecallNumberListBygsId(req2);
    //}
    //else {
    //  getfpRecallNumberResponse = of(null);
    //}

    return forkJoin([getGSResponse, getTaskResponse, getTaskDocResponse]);

  }

  ngOnInit() {

    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }


    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.showSpin = true;
    //this.allowedFileType = ""; // new Array(GlobalConstant.allowedFileTypeList).toString().split(",").join(", ");
    this.errMsg = "";
    this.errMsg2 = "";
    this.msg = "";
    this.tripCode = "";
    this.icao_iata = "";
    this.airportName = "";
    this.airportCountry = "";
    this.arrivalDateTime = "";
    this.departureDateTime = "";
    this.arrivalDateTimeLocal = "";
    this.departureDateTimeLocal = "";
    this.nextICAO_IATA = "";
    this.nextAirportName = "";
    this.nextCountry = "";
    this.aircraftManufacturer = "";
    this.aircraftModel = "";
    this.aircraftInfo = "";
    this.taskStatusList = [];
    this.selectedTaskStatusGUID = "";
    this.confirmation_number = "";
    this.service_date = null;
    this.service_time = null;
    this.selectedTabName = "public";
    this.public_note = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.recall_number = "";
    this.fpRecallNumberList = [];
    this.intoPlaneAgentList = [];
    this.overflightCountryList = [];
    this.permit_order_number = "";
    this.selectedFBOGUID = "";
    this.selectedVendorGUID = "";
    this.selectedOverflightCountryGUID = "";
    this.selectedLandingCountryGUID = "";
    this.selectedLandingVendorGUID = "";
    this.selectedFPRecallNumber = "";
    this.selectedHotelGUID = "";
    this.selectedFuelSupplierGUID = "";
    this.selectedFuelSupplierName = "";
    this.taskDocumentList = [];
    this.hasExistingDocument = false;
    this.serviceSubTitle = "";
    this.nextICAO = "";
    this.prevICAO = "";
    this.registration = "";
    this.hasTaskAudit = false;

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let gs = new GroundStopModel();
          gs = responses[0].model;
          this.tripCode = gs.tripCode;
          this.icao = gs.icao;
          this.icao_iata = gs.icaO_IATA;
          this.airportName = gs.airportName;
          this.airportCountry = gs.airportState_CountryCode;
          this.arrivalDateTime = gs.arrivalDateTextUTC;
          this.departureDateTime = gs.departureDateTextUTC;
          
          this.targetArrivalDate  = new Date(this.arrivalDateTime);
          this.targetDepartureDate  = new Date(this.departureDateTime);

          if(this.arrivalDateTime == '' ){
            this.isPastTask = false;

          }
          if(this.yourDate > this.targetArrivalDate || this.yourDate > this.targetDepartureDate){

            this.isPastTask = true;
          }



          this.arrivalDateTimeLocal = gs.arrivalDateTextLocal;
          this.departureDateTimeLocal = gs.departureDateTextLocal;
          this.nextICAO_IATA = gs.nextICAO_IATA;
          this.nextAirportName = gs.nextAirportName;
          this.nextICAO = gs.nextICAO;
          this.nextCountry = gs.nextAirportCity_CountryCode;
          this.nextCountryGUID = gs.nextCountryGUID.toLowerCase();
          this.prevICAO = gs.prevICAO;
          this.registration = gs.registration;
          this.aircraftManufacturer = gs.aircraftManufacturer;
          this.aircraftModel = gs.aircraftModel;
          this.aircraftInfo = gs.aircraftInfo;
          this.countryGUID = gs.countryGUID;
          this.lockdownTrip = gs.lockdownTrip;
          this.airportID = gs.airportID;
          this.countryID = gs.countryID;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (responses[1].model != null) {
            let gst = new GroundStopTaskModel();
            gst = responses[1].model;

            this.serviceTypeDesc = gst.serviceTypeDescription;
            this.modifiedBy = gst.modifiedBy;
            this.modifiedDate = gst.modifiedDate;

            if (gst.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;

            this.selectedTaskStatusGUID = gst.taskStatusGUID.toLowerCase();
            this.requiresServiceDate = gst.requiresServiceDate;
            if (gst.serviceDate != "")
              this.service_date = gst.serviceDate;
            if (gst.serviceTime != "")
              this.service_time = gst.serviceTime;
            this.recall_number = gst.flightPlanRecallNo;
            this.getOverflightCountryList();
            this.confirmation_number = gst.fpiConfirmationReference;
            this.serviceSubTitle = gst.serviceSubTitle;
            if (this.serviceSubTitle == "") {
              this.serviceSubTitle = "FBO / Handler";
            }
            this.hasTaskAudit = gst.hasTaskAudit;
            this.selectedVendorName = gst.vendorName;
            this.selectedVendorGUID = gst.vendorGUID.toLowerCase();
            this.selectedCountryName = gst.countryName;
            this.selectedHotelName = gst.hotelName;
            this.selectedTaskStatusDescription = gst.taskStatusDescription;
            this.public_note = gst.publicNote;
            this.recall_number = gst.flightPlanRecallNo;
            this.selectedFPRecallNumber = gst.flightPlanRecallNo;
            this.taskLabel = gst.taskLabel;
            if (gst.overflightPermitOrderNo > 0)
              this.permit_order_number = gst.overflightPermitOrderNo.toString();
            this.selectedHotelGUID = gst.hotelGUID.toLowerCase();
            this.selectedFuelSupplierGUID = gst.fuelSupplierGUID.toLowerCase();
            this.selectedFuelSupplierName = gst.fuelSupplierName;
            this.selectedFuelIntoPlaneAgentName = gst.fuelIntoPlaneAgentName;

            this.hasFeedback = gst.hasFeedback;
            this.serviceLocation = gst.serviceLocation;

            if (gst.serviceLocation == 'D' && gst.departureOnHold) {
              this.onHoldText = "On Hold";
            }
            else {
              if (gst.serviceLocation == 'A' && gst.arrivalOnHold) {
                this.onHoldText = "On Hold";
              }
            }
            this.showVendorToClient = gst.showVendorToClient;
            this.taskColor = gst.taskStatusFontColor;
          }
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          if (responses[2].model.length > 0) {
            this.taskDocumentList = responses[2].model;
            this.hasExistingDocument = true;
          }
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      //if (responses[3] != null) {
      //  if (responses[3].code == "200" && responses[3].message == "") {
      //    this.fpRecallNumberList = responses[3].model;
      //  }
      //  else {
      //    if (responses[3].code == "401") {
      //      this._authService.signOut();
      //    }
      //  }
      //}

      this.initControls();

      this.showSpin = false;

    });

  }

  initControls() {
    this.ground_stop_task_edit_client_form = this._formBuilder.group({

      public_note: [this.public_note],

    });

  }

  get f() { return this.ground_stop_task_edit_client_form.controls; }

  getOverflightCountryList() {
    if (this.serviceTypeID == 35 && this.recall_number != "") {
      let request = new GroundStopTaskModel()
      request.groundStopGUID = this.groundStopGUID;
      request.flightPlanRecallNo = this.recall_number;
      this._groundStopAdvancedService.getOverflightCountryListBygsIdfpNum<ResponseModel<GroundStopTaskModel[]>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          this.overflightCountryList = response.model;

        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }
        //this.loading = false;
      })
    }
  }



  clickPreviewDocument(e: any, item: GroundStopTaskDocumentModel) {
    let request = new GroundStopTaskDocumentModel();
    request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
    if (item.groundStopTaskDocumentGUID != "") {
      this._groundStopAdvancedService.getGroundStopTaskDocumentBygstdId<ResponseModel<GroundStopTaskDocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new GroundStopTaskDocumentModel();
            obj = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
            let file = new Blob([byteArray], { type: obj.mimeType });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
      let file = new Blob([byteArray], { type: item.mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

  }

  clickCopyNote() {
    let s: string = "";
    switch (this.selectedTabName) {
      case "public":
        s = this.f.public_note.value;
        break;
      case "vendor":
        s = this.f.vendor_note.value;
        break;
      case "internal":
        s = this.f.internal_note.value;
        break;
      default:
        s = this.f.public_note.value;
        break;
    }
    return s;
  }

  clickCopyHeader() {
    let firstlinestring: string = "";
    let secondlinestring: string = "";
    // registration
    firstlinestring = "Registration"; // 12 characters
    secondlinestring = this.registration;
    if (this.registration.length > 12) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.registration, 12);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.registration, 12);
    }
    // registration end
    // trip code
    firstlinestring += "Trip Code"; // 9
    secondlinestring += this.tripCode;
    if (this.tripCode.length > 9) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.tripCode, 9);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.tripCode, 9);
    }
    // trip code end
    // business stop
    firstlinestring += "Business Stop"; // 13
    let bs: string = "";
    if (this.prevICAO != "") {
      bs = this.prevICAO + "-";
    }
    bs += this.icao;
    if (this.nextICAO != "") {
      bs += "-" + this.nextICAO;
    }
    secondlinestring += bs;
    if (bs.length > 13) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, bs, 13);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, bs, 13);
    }
    // business stop end
    // arrival
    let arr1stLineCharacterNumber = firstlinestring.length;
    let arr2ndLineCharacterNumber = secondlinestring.length;
    if (this.arrivalDateTime != "") {
      firstlinestring += "Arrival(" + this.icao + ")"; // 13
      let at: string = this.arrivalDateTime;
      secondlinestring += at;
      if (at.length > 13) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, at, 13);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, at, 13);
      }
    }
    let arr1stLineCharacterNumber2 = firstlinestring.length;
    let arr2ndLineCharacterNumber2 = secondlinestring.length;
    // arrival end
    // departure
    let dep1stLineCharacterNumber = firstlinestring.length;
    let dep2ndLineCharacterNumber = secondlinestring.length;
    if (this.departureDateTime != "") {
      firstlinestring += "Departure(" + this.icao + ")"; // 15
      let dt: string = this.departureDateTime;
      secondlinestring += dt;
      if (dt.length > 15) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, dt, 15);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, dt, 15);
      }
    }
    // departure
    // aircraft info
    firstlinestring += this.aircraftManufacturer + " " + this.aircraftModel;
    secondlinestring += this.aircraftInfo;
    // aircraft info end
    let thirdEmptySpaceNum = 0;
    let thirdLineString = "";
    if (this.arrivalDateTimeLocal != "") {
      if (arr1stLineCharacterNumber > arr2ndLineCharacterNumber) {
        thirdEmptySpaceNum = arr1stLineCharacterNumber;
      }
      else {
        thirdEmptySpaceNum = arr2ndLineCharacterNumber;
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.arrivalDateTimeLocal;
    }
    if (this.departureDateTimeLocal != "") {
      if (this.arrivalDateTime != "") {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber - arr1stLineCharacterNumber2 + 2;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber - arr2ndLineCharacterNumber2 + 2;
        }
      }
      else {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber;
        }
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.departureDateTimeLocal;
    }

    let s: string;
    s = firstlinestring + "\n" + secondlinestring;
    if (thirdLineString != "") {
      s += "\n" + thirdLineString;
    }
    return s;

  }

  getFirstLineString(firstLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (localString.length - headerCharacterNumber); i++) {
      firstLinesString += " ";
    }
    firstLinesString += "  ";
    return firstLinesString;
  }

  getSecondLineString(secondLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (headerCharacterNumber - localString.length); i++) {
      secondLinesString += " ";
    }
    secondLinesString += "  ";
    return secondLinesString;
  }

  clickHotel() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //dialogConfig.width = "58em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    let hotelGUID = "";
    hotelGUID = this.selectedHotelGUID;

    dialogConfig.data = { countryId: this.countryGUID, hotelChainId: "", hotelGUID: hotelGUID, v: this._authService.getCurrentTimeNumber() };
    const dialogRef = this._dialog.open(HotelClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.refresh) {

        }

      }
    });

  }

  //clickContact() {
  //  let s = "";
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.autoFocus = true;
  //  //dialogConfig.width = "65em";//"1000px";
  //  //dialogConfig.height = "28em";//"500px";
  //  dialogConfig.disableClose = true;

  //  dialogConfig.data = {
  //    dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, v: this._authService.getCurrentTimeNumber()
  //  };

  //  const dialogRef = this._dialog.open(DeliveryCommsDialogComponent, dialogConfig);
  //  dialogRef.afterClosed().subscribe(result => {


  //  });

  //}

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  openVendorDetails() {
    if (this.selectedVendorGUID == null || this.selectedVendorGUID == "" || this.selectedVendorGUID == "4383ada6-2161-49e6-965f-47fc67fa5cd4")
      return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    dialogConfig.data = {
      vendorId: this.selectedVendorGUID,
      icao: this.icao,
      gsID: this.groundStopGUID
    };
    this._dialog.open(VendorDetailsComponent, dialogConfig);
  }

  clickAudit() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: "", aircraftGUID: "", tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID,
      groundStopTaskGUID: this.groundStopTaskGUID, pastHours: 0,
      userName: "", v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  clickEmail() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID
    };

    const dialogRef = this._dialog.open(EmailClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }

  
  clickFeedback(){
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "42.5em";//"1000px";
    config.width = "40em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { serviceTypeDesc: this.serviceTypeDesc, icao_iata: this.icao_iata, tripCode: this.tripCode, registration : this.registration, arrivalDateTime : this.arrivalDateTime,
      departureDateTime: this.departureDateTime, isTaskFeedback: true,  serviceLocation: this.serviceLocation, selectedCountryName : this.selectedCountryName,
      selectedVendorName: this.selectedVendorName,
       airportID: this.airportID,  countryID: this.countryID,
      //  aircraftGUID : this.aircraftGUID, 
        customerGUID: this.customerGUID,  tripCodeGUID: this.tripCodeGUID,  groundStopGUID: this.groundStopGUID,  groundStopTaskGUID: this.groundStopTaskGUID
      ,v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(FeedbackDialogComponent, config);
  }


}
