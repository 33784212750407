import { Component, Inject, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ReturnObjModel } from '../models/return-obj.model';
import { DocumentTypeModel } from '../models/document-type.model';
import { PersonTravelDocumentModel } from '../models/person-travel-document.model';
import { AuthenticateService } from '../services/authenticate.service';
import { PersonService } from '../services/person.service';
import { ResponseModel } from '../models/response.model';
import { forkJoin, Observable } from 'rxjs';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-customer-person-travel-doc-selection-dialog',
  templateUrl: './customer-person-travel-doc-selection-dialog.component.html',
  styleUrls: ['./customer-person-travel-doc-selection-dialog.component.css']
})

export class CustomerPersonTravelDocSelectionDialogComponent implements OnInit {
  @Input() personGUID: string;
  dialogTitle: string;
  message: string;
  person_doc_selection_form: UntypedFormGroup;
  showSpin: boolean = true;
  submitted: boolean = false;
  errMsg: string;
  documentTypeList: DocumentTypeModel[];
  passportList: PersonTravelDocumentModel[];
  selectedDocumentType: DocumentTypeModel;
  selectedDocumentTypeDescription: string;
  selectedPersonTravelDocumentGUID: string;
  upPII: boolean = false;
  grantAccessToManageManifestProfiles: boolean;
  constructor(private _dialogRef: MatDialogRef<CustomerPersonTravelDocSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _personService: PersonService, private readonly _dialogService: DialogService) {
    //this.dialogTitle = _data.dialogTitle;
    this.personGUID = _data.personGUID;
    _dialogRef.disableClose = true;
  }

  getAllData(): Observable<any[]> {
    let getDocTypeResponse = this._personService.getAllCustomerPersonDocumentTypeList<ResponseModel<DocumentTypeModel[]>>();
    let request = new PersonTravelDocumentModel();
    request.personGUID = this.personGUID;
    let getPassportResponse = this._personService.getCustomerPersonPassport<ResponseModel<PersonTravelDocumentModel[]>>(request);   
    return forkJoin([getDocTypeResponse, getPassportResponse]);
   
  }


  ngOnInit() {
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }
    if (!this.upPII || !this.grantAccessToManageManifestProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this._dialogRef.close();
      return;
    }
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.documentTypeList = [];
    this.passportList = [];
    this.errMsg = "";
    this.selectedDocumentTypeDescription = "";
    this.selectedPersonTravelDocumentGUID = "-1";
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.documentTypeList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.passportList = responses[1].model;
          if (this.passportList.length>0)
            this.selectedPersonTravelDocumentGUID = this.passportList[0].personTravelDocumentGUID.toLowerCase();
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;


    });

  }

  initControls() {
    this.person_doc_selection_form = this._formBuilder.group({
      doc_type_select: [null, Validators.required],
      passport_select: [this.selectedPersonTravelDocumentGUID]
    });
  }

  get f() { return this.person_doc_selection_form.controls; }

  docTypeOnChange(e: any) {  
    this.selectedDocumentType = this.f.doc_type_select.value;
    if (this.f.doc_type_select.value != null) {  
      this.selectedDocumentTypeDescription = this.selectedDocumentType.documentTypeDescription;
    }
    else {
      this.selectedDocumentTypeDescription = "";
    }
    //if (this.selectedDocumentTypeDescription == "Visa") {
    //  let request = new PersonTravelDocumentModel();
    //  request.personGUID = this.personGUID;
    //  this._personService.getCustomerPersonPassport<ResponseModel<PersonTravelDocumentModel[]>>(request).subscribe(response => {
    //    if (response != null && response.code == "200") {
    //      if (response.model != null) {
    //        this.passportList = response.model;
    //        if (this.passportList.length>0)
    //          this.selectedPersonTravelDocumentGUID = this.passportList[0].personTravelDocumentGUID.toLowerCase();
    //      }
    //      else {

    //      }
    //    }
    //    else {
    //      if (response.code == "401") {
    //        //this.errMsg = response.message
    //        this._authService.signOut();
    //      }
    //      else {
    //        //this.errMsg = "Failed to save the record. Please try again later.";
    //      }
    //    }

    //  })
    //}
    //else {
    //  this.passportList = [];
    //}
    
  }

  passportOnChange(e: any) {
    //this.selectedPersonTravelDocumentGUID = this.f.passport_select.value;   
    //this.clickAddTravelDocument(3);
  }


  clickSubmit() {
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.person_doc_selection_form.invalid || this.errMsg != "") {
      return;
    }

    let n = 0;
    switch (this.selectedDocumentTypeDescription.toLowerCase()) {
      case "passport":
        n = 1;
        this.selectedPersonTravelDocumentGUID = "";
        break;
      case "visa":
        n = 3;
        if (this.f.passport_select.value == "-1") {
          this.selectedPersonTravelDocumentGUID = "";
        }
        else {
          this.selectedPersonTravelDocumentGUID = this.f.passport_select.value;
        }
        break;
      default:
        n = 2;
        this.selectedPersonTravelDocumentGUID = "";
        break;
    }
   
    this.clickAddTravelDocument(n);
  }

  clickAddTravelDocument(e: number) {
    let obj = new ReturnObjModel();
    
    switch (e) {
      case 0:
        obj.actionName = "";      
        break;
      case 1:
        obj.actionName = "passport";
        obj.newId = this.selectedDocumentType.documentTypeGUID;
        break;
      case 2:
        obj.actionName = "traveldoc";
        obj.newId = this.selectedDocumentType.documentTypeGUID;
        break;
      case 3:
        obj.actionName = "visa";
        obj.newId = this.selectedPersonTravelDocumentGUID;
        break;
    }
    this._dialogRef.close(obj);

  }

  //close() {
  //  this._dialogRef.close();
  //}

}
