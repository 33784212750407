import { Component } from '@angular/core';

@Component({
    selector: 'app-fuel-bi-dashboard',
  templateUrl: './fuel-bi-dashboard.component.html',
  styleUrls: ['./fuel-bi-dashboard.component.css']
})
/** airport-bi-dashboard component*/
export class FuelBiDashboardComponent {
  
    constructor() {

    }
}
