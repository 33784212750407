<div style="margin-top: 0em; padding-top: 0em; padding: 0.5em 0 0 0.5em;  margin-bottom: 2em; "  [ngClass]="{ 'normalCss':!isDialog ,'dialogCss':isDialog  }" >
  <form *ngIf="missing_taxes_airport_form" [formGroup]="missing_taxes_airport_form">
    <div  >
      <div  *ngIf="!isDialog"  style="display: flex; justify-content: space-between;">
        <div style="margin-right:1em;display: flex; padding: 0.5em;"><span class="material-symbols-outlined"> description </span><h5>Missing Taxes Airport List Rolling 6 Months</h5></div>
        <div>
          <div style="width:6em">
             <app-spin *ngIf="showSpin2" ></app-spin>
          </div>
         </div>
      </div>

      
      <div  *ngIf="isDialog"  style="display: flex; justify-content: space-between;" cdkDrag  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle >
        <div style="margin-right:1em;display: flex; padding: 0.5em;"><span class="material-symbols-outlined"> description </span><h5>Missing Taxes Airport List Rolling 6 Months</h5></div>
        <div>
          <div style="width:6em">
             <app-spin *ngIf="showSpin2" ></app-spin>
          </div>
          <button mat-icon-button  (click)="clickOpenInNew()">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>  
          <button  *ngIf="isDialog" mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button>
         </div>
      </div>


      <div style="background-color: #f8f8f8; padding-left: 0.25em; padding-top: 0.25em; padding-right: 0.5em; margin-top: -0.5em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; width: 65em; height: 4.5em">
        <div style="margin-left:0em;padding:0em; display: flex; "> 
          

          
            
          <div style="display:flex;justify-content: space-between; width: 100%;">
            <div style="margin-top:0.125em; margin-right:0.5em;display: flex;width:8em">
              <div style="display: flex;">
                <button type="button" mat-button class="btn-low" style="height: 2em;margin-left:0em;"  (click)="excelReport()"> Export to Excel</button>
                <button mat-button class="btn-low"  (click)="getData()" style="height: 2em;display: flex; color: #337dff;margin-left:1em; "><i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>REFRESH</button>
              </div>


            </div>
            <div style="display: flex;">
              <div>
                <div   style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="includeCountryAll" style="transform: scale(0.75);margin-top: 0.15em;" (change)="includeCountryAllOnChange($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em"><label for="includeCountryAll" style="font-size:small;margin-top: 0.7em;">&nbsp;Exclude Country Taxes</label></div>
                </div>

                <div   style="display: flex;margin-top:0em">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="showAllDetails" style="transform: scale(0.75);margin-top: 0.15em;" (change)="showAllDetailsOnChange($event)"></mat-checkbox>
                  <div style="margin-top:-0.25em"><label for="showAllDetails" style="font-size:small;margin-top: 0.7em;">&nbsp;Show All Details</label></div>
                </div>

              </div>
              <button mat-button class="btn-low" style="height: 2em;margin-top: 0.2em; margin-left: 0.5em;display: flex;" (click)="clickRoute('fuelmenu/fuel-menu')" ><i class="material-icons" style="font-size: medium;cursor: pointer; ">arrow_back</i>Return to Fuel Dashboard</button>
          </div>

          </div>   
        </div> 
      </div>

      <div  *ngIf="!f.showAllDetails.value"  [ngClass]="{  'dialogCss2':isDialog  }" ><!--class="mt-2">-->
        
        <div >
          <div class="d-flex " style="width: 46em;justify-content: end;" align="end"  ><!--</div> style="margin-top: -1.05em;">-->
            <mat-paginator *ngIf="sortedData.length>0" style="background-color:transparent;" [pageSize]="15"
              [showFirstLastButtons]="true" [length]="totalRecords" [pageSizeOptions]="[15,25, 50, 100]" aria-label="Select page"
              (page)="pageEvent = pageIndexChange($event)">
            </mat-paginator>
            <!--[pageSizeOptions]="[25, 50, 100, totalRecords]"-->
          </div>
        </div>
        <table mat-table [dataSource]="sortedData" matSort  matSortDirection="asc"  style="width: 45em; margin-left: 0.5em;">

          <tr>
   
            <ng-container matColumnDef="Country">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Country" style="padding-left: 0.5em !important">
                Country
              </th>
              <td mat-cell *matCellDef="let element"  style="text-align: left !important; padding-left: 0.5em !important;">{{ element.countryName}}</td>
            </ng-container>
            <ng-container matColumnDef="ICAO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="ICAO" >
                ICAO
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important;">{{element.icao==null?'All':element.icao  }}</td>
            </ng-container>
            <ng-container matColumnDef="IATA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="IATA" >
                IATA
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important;">{{element.iata==null?'All':element.iata }}</td>
            </ng-container>
            <ng-container matColumnDef="FuelQuoteCount">
              <th mat-header-cell *matHeaderCellDef   >
                Fuel Quote
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: right;">{{ element.fuelQuoteCount}}</td>
            </ng-container>
            <ng-container matColumnDef="FuelReleaseCount">
              <th mat-header-cell *matHeaderCellDef >
                Fuel Release 
              </th>
              <td mat-cell *matCellDef="let element"> {{element.fuelReleaseCount}} </td>
            </ng-container>

            <ng-container matColumnDef="RegistrationCount">
              <th mat-header-cell *matHeaderCellDef class="thStyle">
                Registration
              </th>
              <td mat-cell *matCellDef="let element">{{element.registrationCount}}</td>
            </ng-container>
 
             <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef class="thStyle">
                <div style="margin-top:-0.25em;">Action</div>
              </th>
              <td mat-cell class="mat-cell2" *matCellDef="let element; let i=index ">
                <a mat-button class="btn-low grid"  (click)="clickAdd($event, element)">
                  <span>Add</span>
                </a>
              </td>
            </ng-container>
          </tr>
           <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row  *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
 
      </div>

      <div *ngIf="f.showAllDetails.value"  style="height: 28em;  overflow-y: auto;"><!--class="mt-2">-->
        <div class="d-flex " style="width: 65em; justify-content: end;" ><!--</div> style="margin-top: -1.05em;">-->


          <mat-paginator *ngIf="sortedData2.length>0" style="background-color:transparent;" [pageSize]="15"
                         [showFirstLastButtons]="true"
                         [length]="totalRecords"
                         [pageSizeOptions]="[15, 25, 50, 100]"
                         aria-label="Select page" (page)="pageEvent = pageIndexChange($event)">
          </mat-paginator>  
          <!--[pageSizeOptions]="[25, 50, 100, totalRecords]"-->
        </div>

        <table mat-table [dataSource]="sortedData2" matSort  matSortDirection="asc"  style="width: 65em; margin-left: 0.5em;">

          <tr>
   
            <ng-container matColumnDef="Country">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Country" style="padding-left: 0.5em !important">
                Country
              </th>
              <td mat-cell *matCellDef="let element"  style="text-align: left !important; padding-left: 0.5em !important;">{{ element.countryName}}</td>
            </ng-container>
            <ng-container matColumnDef="ICAO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="ICAO" >
                ICAO
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important;">{{element.icao==null?'All':element.icao  }}</td>
            </ng-container>
            <ng-container matColumnDef="IATA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="IATA" >
                IATA
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important;">{{element.iata==null?'All':element.iata }}</td>
            </ng-container>
            <ng-container matColumnDef="CustomerName">
              <th mat-header-cell *matHeaderCellDef   >
                Customer  
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: right;">{{ element.customerName}}</td>
            </ng-container>
            <ng-container matColumnDef="TripCode">
              <th mat-header-cell *matHeaderCellDef   >
                Trip Code
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: right;"><a href="javascript:void(0)"  (click)="clickOpenTrip(element.tripCodeGUID,element.customerGUID,element.aircraftGUID);">{{ element.tripCode}}</a></td>
            </ng-container>
            <ng-container matColumnDef="Registration">
              <th mat-header-cell *matHeaderCellDef   >
                Registration  
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: right;">{{ element.registration}}</td>
            </ng-container>
            <ng-container matColumnDef="ArrivalDate">
              <th mat-header-cell *matHeaderCellDef   >
                Arrival Date
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: right;">{{ element.arrivalDateUTC}}</td>
            </ng-container>
            <ng-container matColumnDef="FuelQuoteCount">
              <th mat-header-cell *matHeaderCellDef   >
                Fuel Quote
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: right;">{{ element.fuelQuoteCount}}</td>
            </ng-container>
            
            
            <ng-container matColumnDef="FuelReleaseCount">
              <th mat-header-cell *matHeaderCellDef >
                Fuel Release 
              </th>
              <td mat-cell *matCellDef="let element"> {{element.fuelReleaseCount}} </td>
            </ng-container>

 
             <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef class="thStyle">
                <div style="margin-top:-0.25em;">Action</div>
              </th>
              <td mat-cell class="mat-cell2" *matCellDef="let element; let i=index ">
                <a mat-button class="btn-low grid" (click)="clickAdd($event, element)">
                  <span>Add</span>
                </a>
              </td>
            </ng-container>
          </tr>
           <tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>
          <tr mat-row  *matRowDef="let row; columns: displayedColumn2"></tr>
        </table>
 
      </div>
    </div>
  </form>
</div>
