import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BusinessRulesEditComponent } from '../business-rules/business-rules-edit.component';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { TripLegModel } from '../models/trip-leg.model';
import { TripRevisionModel } from '../models/trip-revision.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerService } from '../services/customer.service';

export interface AlertList {
  customerName: string;
  categoryName: string;  
  alertObject: any;
}

export interface CustomerAlertList {
  customerName: string;
  alertList: AlertList[];
}
export interface RevisionList {
  registration: string;
  icao: string;
  departureDateTimeUTC: string;
  departureDateTimeLocal: string;
  arrivalDateTimeUTC: string;
  arrivalDateTimeLocal: string;
  revisionList: TripRevisionModel[];
}

@Component({
    selector: 'app-trip-alert-dialog',
  templateUrl: './trip-alert-dialog.component.html',
  styleUrls: ['./trip-alert-dialog.component.css']
})

export class TripAlertDialogComponent implements OnInit {
  @Input() alertObject: any;
  @Input() alertType: string;
  @Input() manifest: GroundStopManifestModel[];
  @Input() legs: TripLegModel[];
  manifestLegs: TripLegModel[] = [];
  revisions: RevisionList[] = [];
  showSpin: boolean = false;
  title: string;
  tripCode: string;
  tripLeg: string;
  alertList: AlertList[] = [];
  customerAlertList: CustomerAlertList[] = [];
  displayedColumn: string[] = ['transactionDate', 'changeRequestType', 'changeRequest'];

    /** company-event-dialog ctor */
  constructor(private readonly _dialogRef: MatDialogRef<TripAlertDialogComponent>, private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder, 
    private readonly _customerService: CustomerService, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialog: MatDialog,) {
    this.alertObject = _data.alertObject;
    this.alertType = _data.alertType;
    this.manifest = _data.manifest;
    this.legs = _data.legs;
  }

  ngOnInit() {
    let customerName;
    let groundStopGUID;
    let list: AlertList[] = [];
    this.alertObject.sort(function (a, b) {
      if (a.tripCode < b.tripCode) { return -1; }
      if (a.tripCode > b.tripCode) { return 1; }
      return 0
    });
    this.legs.sort(function (a, b) {
      if (a.tripCode < b.tripCode) { return -1; }
      if (a.tripCode > b.tripCode) { return 1; }
      return 0
    });    
    switch (this.alertType) {
      case "expiredManifest":
        this.title = "Expired Travel Documents";
        this.manifest = this.manifest.filter(x => x.expiredDocCount > 0);
        this.legs.forEach(v => {
          if (Array.prototype.map.call(this.manifest, s => s.groundStopGUID).toString().indexOf(v.groundStopGUID) > -1)
            this.manifestLegs.push(v);
        });       
        this.manifestLegs.forEach(v => {
          if (customerName != v.customerName) {
            customerName = v.customerName;
            if (groundStopGUID != v.groundStopGUID) {
              groundStopGUID = v.groundStopGUID;
              let list: AlertList[] = [];
              list.push({ customerName: v.customerName, categoryName: v.tripCode + "/" + v.departureICAO + "-" + v.nextArrivalICAO + "/" + v.departureDateTimeUTCText, alertObject: this.manifestLegs.filter(x => x.groundStopGUID == groundStopGUID) });
              this.customerAlertList.push({ customerName: customerName, alertList: list });
            }
          }
        });
        break;
      case "missingManifest":
      case "tbaCrew":
      case "tbaPax":
      case "followUpCrew":
      case "followUpPax":
        switch (this.alertType) {
          case "missingManifest":
            this.title = "Missing Manifest Documents";
            this.manifest = this.manifest.filter(x => x.hasRequiredDocument == false && x.lastName.toLowerCase() != 'crew tba'
              && x.lastName.toLowerCase() != 'pax tba' && x.lastName.toLowerCase() != 'crew followup' && x.lastName.toLowerCase() != 'pax followup');
            break;
          case "tbaCrew":
            this.title = "Legs With TBA Crew";
            this.manifest = this.manifest.filter(x => x.lastName.toLowerCase() == 'crew tba');
            break;
          case "tbaPax":
            this.title = "Legs With TBA PAX";
            this.manifest = this.manifest.filter(x => x.lastName.toLowerCase() == 'pax tba');
            break;
          case "followUpCrew":
            this.title = "Legs Needing Crew Follow Up";
            this.manifest = this.manifest.filter(x => x.lastName.toLowerCase() == 'crew followup');
            break;
          case "followUpPax":
            this.title = "Legs Needing PAX Follow Up";
            this.manifest = this.manifest.filter(x => x.lastName.toLowerCase() == 'pax followup');
            break;
        }        
        //this.manifest = this.manifest.filter(x => x.hasPersonTravelDoc==false);
        this.legs.forEach(v => {
          if (Array.prototype.map.call(this.manifest, s => s.groundStopGUID).toString().indexOf(v.groundStopGUID) > -1)
            this.manifestLegs.push(v);
        });
        this.manifestLegs.forEach(v => {

          if (groundStopGUID != v.groundStopGUID) {
            groundStopGUID = v.groundStopGUID;
            list.push({ customerName: v.customerName, categoryName: v.tripCode + "/" + v.departureICAO + "-" + v.nextArrivalICAO + "/" + v.departureDateTimeUTCText, alertObject: this.manifestLegs.filter(x => x.groundStopGUID == groundStopGUID) });
          }
        });
        list.forEach(v => {
          if (customerName != v.customerName) {
            customerName = v.customerName;
            this.customerAlertList.push({ customerName: customerName, alertList: list.filter(x => x.customerName == customerName) });
          }

        });
        break;
      case "task":
      case "vendorTask":
      case "onHold":
        if (this.alertType == "task")
          this.title = "Tasks Waiting for Client Reply";
        else {
          if (this.alertType == "vendorTask")
            this.title = "Tasks Waiting for Vendor Reply";
          else
            this.title = "On Hold";
        }
        this.legs.forEach(v => {
          if (Array.prototype.map.call(this.alertObject, s => s.groundStopGUID).toString().indexOf(v.groundStopGUID) > -1)
            this.manifestLegs.push(v);
        });        
        
        this.manifestLegs.forEach(v => {

          if (groundStopGUID != v.groundStopGUID) {
            groundStopGUID = v.groundStopGUID;
            list.push({ customerName: v.customerName, categoryName: v.tripCode + "/" + v.departureICAO + "-" + v.nextArrivalICAO + "/" + v.departureDateTimeUTCText, alertObject: this.alertObject.filter(x => x.groundStopGUID == groundStopGUID) });
          }
        });
        list.forEach(v => {
          if (customerName != v.customerName) {
            customerName = v.customerName;
            this.customerAlertList.push({ customerName: customerName, alertList: list.filter(x => x.customerName == customerName) });
          }

        });
        break;
      case "aircraft":
        this.title = "Expired Aircraft Documents";
        let registration;
        this.alertObject.forEach(v => {
          if (customerName != v.customerName) {
            customerName = v.customerName;
            if (registration != v.registration) {
              registration = v.registration;
              let list: AlertList[]=[];             
              list.push({ customerName: v.customerName, categoryName: registration, alertObject: this.alertObject.filter(x => x.customerName == customerName && x.registration == registration) });
              this.customerAlertList.push({ customerName: customerName, alertList: list });
            }
          }
        });
        break;
      case "customer":
        this.title = "Expired Client Documents";        
        this.alertObject.forEach(v => {
          if (customerName != v.customerName) {
            customerName = v.customerName;
            this.alertList.push({ customerName: v.customerName, categoryName: customerName, alertObject: this.alertObject.filter(x => x.customerName == customerName) });
          }
        });
        break;
      case "missingCrew":
      case "missingPax":
      case "ssn":
      case "openTask":
        switch (this.alertType) {
          case "missingCrew":
            this.title = "Legs Missing Crew";
            break;
          case "missingPax":
            this.title = "Legs Missing PAX";
            break;
          case "ssn":
            this.title = "Legs Missing SSN Task";
            break;
          case "openTask":
            this.title = "Open Tasks on Cancelled Stops";
            break;
        }
        let tempTripCode: string = "";
        this.alertObject.sort(function (a, b) {
          if (a.tripCode < b.tripCode) { return -1; }
          if (a.tripCode > b.tripCode) { return 1; }
          return 0
        });        
        this.alertObject.forEach(v => {          
            
            if (tempTripCode != v.tripCode) {
              tempTripCode = v.tripCode;       
              list.push({ customerName: v.customerName, categoryName: tempTripCode + ' / ' + v.registration, alertObject: this.alertObject.filter(x => x.tripCode == tempTripCode) });
            }                      
        });
          list.forEach(v => {
            if (customerName != v.customerName) {
              customerName = v.customerName;
              this.customerAlertList.push({ customerName: customerName, alertList: list.filter(x => x.customerName==customerName) });  
            }
            
        });
        break;
      case "revisions":
        this.title = this.alertObject[0].tripCode + " Audit Trail Details - Past 24 Hours";
        let tempGS: string = "";
        this.alertObject[0].revisions.forEach(x => {
          if (tempGS != x.groundStopGUID) {
            tempGS = x.groundStopGUID;
            this.revisions.push({
              registration: x.registration, icao: x.icao, departureDateTimeUTC: x.departureDateTimeUTC, departureDateTimeLocal: x.departureDateTimeLocal, arrivalDateTimeUTC: x.arrivalDateTimeUTC, arrivalDateTimeLocal: x.arrivalDateTimeLocal,
              revisionList: this.alertObject[0].revisions.filter(y => y.groundStopGUID.toLowerCase() == x.groundStopGUID.toLowerCase())
            });
          }
        });
        break;
      case "businessRules":
        this.title = "Business Rules Needing Approval";
        //this.alertObject.forEach(v => {
        //  if (customerName != v.customerName) {
        //    customerName = v.customerName;
        //    this.alertList.push({ customerName: v.customerName, categoryName: customerName, alertObject: this.alertObject.filter(x => x.customerName == customerName) });
        //  }
        //});
        break;     
    }
    
  }
   
  

  clickClose() {
    this._dialogRef.close();
  }

  openTrip(p: any) {
    window.open('/ground-stops/trip-details-internal?tripId=' + p.alertObject[0].tripCodeGUID + '&aircraftId=' + p.alertObject[0].aircraftGUID + '&customerId=' + p.alertObject[0].customerGUID + '&pf=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }

  openCustomer(p: any) {
    window.open('customers/customer-documentlist?tabName=document&customerGuid=' + p.alertObject[0].customerGUID + '&text_search=&filter_by=DocumentName', '_blank');
  }

  openAircraft(p: any) {
    window.open('/aircraft/aircraft-profile-details?customerGuid=' + p.alertObject[0].customerGUID + '&aircraftGuid=' + p.alertObject[0].aircraftGUID + '&tab=documents&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }

  openBusinessRule(brID: number) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;

      let businessRulesID = 0;
      let s = "Add Business Rule";
      businessRulesID = brID
      s = "Edit Business Rule " + businessRulesID.toString();
      dialogConfig.panelClass = "custom-dialog-container";
      dialogConfig.data = { businessRulesID: businessRulesID, title: s };

      this._dialog.open(BusinessRulesEditComponent, dialogConfig);



  }
}
