import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as atlas from 'azure-maps-control';
import { AuthenticationType, ControlPosition } from 'azure-maps-control';
import { Subject, Subscription } from 'rxjs';
import { TripLegModel } from '../models/trip-leg.model';

export class TableElement {
  legItem: TripLegModel;
  expanded: boolean;
  refreshChildRow: boolean;
  updateTask: boolean;
}
@Component({
  selector: 'app-azure-map',
  templateUrl: './azure-map.component.html',
  styleUrls: ['./azure-map.component.css']
})
/** azure-map2 component*/
export class AzureMapComponent {


  @Input() legList: TableElement[];
  map2: atlas.Map;
  points: any = [];
  tileLayer: any;
  layerName: string = "";
 weatherTileUrl:string = 'https://{azMapsDomain}/map/tile?api-version=2.0&tilesetId={layerName}&zoom={z}&x={x}&y={y}';
  constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
    
  ) {
      this.legList = _data.legList;
    
  }


  ngOnInit() {
    this.createMap();
  }

  //Create an instance of the map2 control and set some options.
  createMap() {
    this.map2 = new atlas.Map('map2', {
      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map2.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map2.sources.add(dataSource);
      self.map2.controls.add([
        new atlas.control.ZoomControl(),
        new atlas.control.CompassControl(),
        new atlas.control.PitchControl(),
        new atlas.control.StyleControl()
      ], {
        position: atlas.ControlPosition.TopRight
      });

      if (self.legList.length > 0) {
        self.legList.forEach((v, i) => {
          var startPosition = [v.legItem.departureLongitude, v.legItem.departureLatitude];
          var endPosition = [v.legItem.nextArrivalLongitude, v.legItem.nextArrivalLatitude];
          var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
            title: v.legItem.departureICAO,
            name: v.legItem.departureAirportName
          });

          var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
            title: v.legItem.nextArrivalICAO,
            name: v.legItem.nextArrivalAirportName
          });
          self.points.push(startPosition);
          self.points.push(endPosition);
          var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
          var poly = new atlas.data.LineString(path);
          dataSource.add([poly, startPoint, endPoint]);

        });

        var popupTemplate = "<div class='popup'>{name}</div>";
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            image: 'pin-round-darkblue',
            cluster: false,
            optional: true,
            allowOverlap: true,
            size: .5
          },
          textOptions: {
            textField: ['get', 'title'],
            offset: [0, -1.5],
            cluster: false,
            allowOverlap: true,
            size: 10
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        });
        self.map2.layers.add([new atlas.layer.LineLayer(dataSource, null, {
          strokeColor: "black",
          strokeWidth: 1.5
        }),
          symbolLayer
        ]);
        var popup = new atlas.Popup({
          pixelOffset: [0, -18],
          closeButton: false
        });
        //Add a hover event to the symbol layer.
        self.map2.events.add('mouseover', symbolLayer, function (e) {
          //Make sure that the point exists.
          if (e.shapes && e.shapes.length > 0) {
            var pos = e.position;
            var offset = [0, 0];
            var properties;
            var content, coordinate;

            if (e.shapes[0] instanceof atlas.Shape) {
              properties = e.shapes[0].getProperties();

              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].getType() === 'Point') {
                coordinate = e.shapes[0].getCoordinates();
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            } else {
              properties = e.shapes[0].properties;
              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].type === 'Point') {
                coordinate = e.shapes[0].geometry.coordinates;
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            }
            popup.setOptions({
              //Update the content of the popup.
              content: content,

              //Update the popup's position with the symbol's coordinate.
              position: coordinate

            });
            //Open the popup.
            popup.open(self.map2);
          }
        });

        self.map2.events.add('mouseleave', symbolLayer, function () {
          popup.close();
        });

        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        self.map2.setCamera({
          bounds: bbox,
          padding: 90
        });
     //   self.updateTileLayer();
      }


    });
  }

  updateTileLayer() {
    

  var tileUrl = this.weatherTileUrl.replace('{layerName}', this.layerName);

    if (!this.tileLayer) {
    //Create a tile layer and add it to the map below the label layer.
    this.tileLayer = new atlas.layer.TileLayer({
      tileUrl: tileUrl,
      opacity: 0.9,
      tileSize: 256
    });

      this.map2.layers.add(this.tileLayer, 'labels');
  } else {
    this.tileLayer.setOptions({
      tileUrl: tileUrl
    });
  }
}
}
