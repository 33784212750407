import { MessageModel } from "./message.model";

export class MessageEmbeddedImageModel extends MessageModel{
  documentName: string;
  mimeType: string;
  imageBase64String: string;
  isInline: boolean;
  contentId: string;
  containerName: string;
  containerPath: string;
  messageEmbeddedImageGUID: string;
}
