import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AircraftProfileDetailsComponent } from './aircraft/aircraft-profile-details.component';
import { AircraftProfileEditComponent } from './aircraft/aircraft-profile-edit.component';
import { AircraftProfileComponent } from './aircraft/aircraft-profile.component';
import { BlankComponent } from './common-utility/blank.component';
import { CustomerCommlistComponent } from './customers/customer-commlist.component';
import { CustomerDocumentlistComponent } from './customers/customer-documentlist.component';
import { CustomerMaillistComponent } from './customers/customer-maillist.component';
import { CustomerProfileSetupEditComponent } from './customers/customer-profile-setup-edit.component';
import { CustomerProfileSetupComponent } from './customers/customer-profile-setup.component';
import { MainComponent } from './main/main.component';
import { MenuComponent } from './nav-menu/menu.component';
import { CustomerPersonCommlistComponent } from './persons/customer-person-commlist.component';
import { CustomerPersonDetailsComponent } from './persons/customer-person-details.component';
import { CustomerPersonMaillistComponent } from './persons/customer-person-maillist.component';
import { CustomerPersonTravelDoclistComponent } from './persons/customer-person-travel-doclist.component';
import { CustomerPersonlistComponent } from './persons/customer-personlist.component';
import { MenuService } from './services/menu.service';
import { ChangePasswordComponent } from './user-accounts/change-password.component';
import { FeedbackComponent } from './user-accounts/feedback.component';
import { GetUsernameComponent } from './user-accounts/get-username.component';
//import { LogonComponent } from './user-accounts/logon.component';
import { LogoutComponent } from './user-accounts/logout.component';
import { PasswordComponent } from './user-accounts/password.component';
import { RegisterUserComponent } from './user-accounts/register-user.component';
import { ResetPasswordAdminComponent } from './user-accounts/reset-password-admin.component';
import { ResetPasswordComponent } from './user-accounts/reset-password.component';
import { UpdateMyAccountComponent } from './user-accounts/update-my-account.component';
//import { UpdateUserComponent } from './user-accounts/update-user.component';
import { UserPermissionAdminComponent } from './user-accounts/user-permission-admin.component';
//import { UserPermissionPersonEditComponent } from './user-accounts/user-permission-person-edit.component';
//import { UserPermissionRegistrationEditComponent } from './user-accounts/user-permission-registration-edit.component';
//import { UserPermissionRestrictEditComponent } from './user-accounts/user-permission-restrict-edit.component';
import { UsernameComponent } from './user-accounts/username.component';
//import { TripQuickSearchControlComponent } from './ground-stops/trip-quick-search-control.component';
//import { TripDetailsComponent } from './ground-stops/trip-details.component';
//import { GroundStopDetailsComponent } from './ground-stops/ground-stop-details.component';
import { HotellistComponent } from './hotels/hotellist.component';
import { AirportBiDashboardComponent } from './airports/airport-bi-dashboard.component';
import { VendorBiDashboardComponent } from './vendors/vendor-bi-dashboard.component';
import { CovidBiDashboardComponent } from './flight-watch/covid-bi-dashboard.component';
import { DevTicketsBiDashboardComponent } from './bi-dashboards/dev-tickets-bi-dashboard.component';
import { IncommingTripLegComponent } from './ground-stops/incomming-trip-leg.component';
import { TripAuditDashboardComponent } from './ground-stops/trip-audit-dashboard.component';
//import { TaskQueueDashboardComponent } from './ground-stop-advanced/task-queue-dashboard.component';
import { TaskQueuelistComponent } from './ground-stop-advanced/task-queuelist.component';
import { VendorProfileListComponent } from './vendors/vendor-profile-list.component';
import { VendorProfileSetupComponent } from './vendors/vendor-profile-setup.component';
import { CustomerBiDashboardComponent } from './bi-dashboards/customer-bi-dashboard.component';
import { CustomerProfileBiDashboardComponent } from './bi-dashboards/customer-profile-bi-dashboard.component';
import { VendorPersonDetailsComponent } from './vendors/vendor-person-details.component';
import { UserPermissionClientComponent } from './user-accounts/user-permission-client.component';
import { TripQuickSearchControlClientComponent } from './ground-stops-client/trip-quick-search-control-client.component';
import { TripDetailsClientComponent } from './ground-stops-client/trip-details-client.component';
import { CurrentEventsDashboardClientComponent } from './current-events-client/current-events-dashboard-client.component';
import { CompanyEventListComponent } from './current-events/company-event-list.component';
import { CurrentEventsDashboardComponent } from './current-events/current-events-dashboard.component';
import { HeadlineListComponent } from './current-events/headline-list.component';
import { TripDetailsInternalComponent } from './ground-stops/trip-details-internal.component';
import { TripQuickSearchControlInternalComponent } from './ground-stops/trip-quick-search-control-internal.component';
import { AccountGrouplistComponent } from './account-groups/account-grouplist.component';
import { TripLeglistComponent } from './trip-legs/trip-leglist.component';
import { ManageBusinessRulesComponent } from './business-rules/manage-business-rules.component';
import { BusinessRulesEditComponent } from './business-rules/business-rules-edit.component';
import { FuelBiDashboardComponent } from './bi-dashboards/fuel-bi-dashboard.component';
import { TripCodeSearchComponent } from './ground-stops/trip-code-search.component';
import { TripLeglistClientComponent } from './trip-legs-client/trip-leglist-client.component';
import { SignatureEditorComponent } from './user-accounts/signature-editor.component';
import { FpiMarketingBiComponent } from './bi-dashboards/fpi-marketing-bi.component';
import { MessageCenterComponent } from './messages/message-center.component';
import { FinanceBiDashboardComponent } from './bi-dashboards/finance-bi-dashboard.component';
import { TripQuoteBiDashboardComponent } from './bi-dashboards/trip-quote-bi-dashboard.component';
import { LegMatchBiDashboardComponent } from './bi-dashboards/leg-match-bi-dashboard.component';
import { MessageBiDashboardComponent } from './bi-dashboards/message-bi-dashboard.component';
import { MessageImportComponent } from './messages/message-import.component';
import { FuelpricingComponent } from './fuelpricing/fuelpricing.component';
//import { FuelPriceUpload1Component } from './fuelpricing/fuel-price-upload1.component';
import { FuelPriceUploadComponent } from './fuel-price-upload/fuel-price-upload.component';
import { FuelSupplierComponent } from './fuelmenu/fuel-supplier-profile.component';
import { FuelPricingSummaryComponent } from './fuelmenu/fuel-pricing-summary.component';
import { MessageCenterClientComponent } from './messages-client/message-center-client.component';
import { ReviseFuelPricingComponent } from './fuelmenu/revise-fuel-pricing.component';
import { TaxesAndFeesComponent } from './taxesandfees/taxesandfees.component'; 
import { CurrencyComponent } from './currency/currency.component';
import { FuelClientMarkupComponent } from './fuelmenu/fuel-client-markup.component';
import { CountryDetailsComponent } from './country/country-details.component';
import { FuelCommissionsComponent } from './fuelmenu/fuel-commissions.component';
import { FuelMenuComponent } from './fuelmenu/fuel-menu.component';
import { MissingTaxesAirportComponent } from './taxesandfees/missing-taxes-airport.component';
import { MissingTaxesAirportAllDetailsComponent } from './taxesandfees/missing-taxes-airport-all-details.component';
import { TemplateDashboardComponent } from './templates/template-dashboard.component';
import { MissingPricesAirportComponent } from './fuelmenu/missing-prices-airport.component'; 
import { CountryDocumentTypeUpdateDialog } from './country/country-document-type-update-dialog.component';
import { TaxesAndFeesDescriptionDialog } from './fuelmenu/taxesandfees-description-dialog.component';
import { FuelExchangeRateMarkupComponent } from './fuelmenu/fuel-markup-exchange.component';
import { LocationMetricsComponent } from './fuelmenu/location-metrics.component';
import { LocationPreferencesComponent } from './fuelmenu/location-preferences.component';
import { FuelTaskQueuelistComponent } from './fuelmenu/fuel-task-queuelist.component';
import { TripCloseOutQueueComponent } from './current-events/trip-closeout-queue.component';
import { MaintenanceDashboardComponent } from './common-utility/maintenance-dashboard.component';
import { ThirdPartyVendorsComponent } from './common-utility/third-party-vendors.component';
import { GlobalSettingsDashboardComponent } from './common-utility/global-settings-dashboard.component';
import { FpcPicklistDataComponent } from './common-utility/fpc-picklist-data.component';
import { AccountingDashboardComponent } from './accounting/accounting-dashboard.component';
import { TripCloseOutBiilingCompletedComponent } from './accounting/trip-closeout-biiling-completed.component';
import { DocumentTypeListComponent } from './documents/document-type-list.component';
import { DocumentTypeEditComponent } from './documents/document-type-edit.component';
import { CustomerProfileSetupComponent2 } from './customers/customer-profile-setup2.component';
import { ManageContactDetailsComponent } from './persons/manage-contact-details.component';
import { GtsBillableServicesComponent } from './accounting/gts-billable-services.component';
import { CustomerInformationDialogComponent } from './customers/customer-information-dialog.component';
import { AirportDetailsEditComponent } from './airports/airport-details-edit.component';
import { ManageAirportsComponent } from './airports/manage-airports.component';
import { AirportRevisionModalComponent } from './airports/airport-revision-modal-dialog.component';
import { AdvisoryDetailsComponent } from './advisory/advisory-details.component';
import { PersonAuditReportComponent } from './persons/person-audit-report.component';


 
const routes: Routes = [
  //{ path: '', component: HomeComponent, pathMatch: 'full' },
  //{ path: 'counter', component: CounterComponent },
  //{ path: 'fetch-data', component: FetchDataComponent },
  //{ path: '', component: LogonComponent, pathMatch: 'full' },
  { path: '', component: UsernameComponent, pathMatch: 'full' },
  //{ path: 'user-accounts/logon', component: LogonComponent },
  { path: 'main/main', component: MainComponent },
  { path: 'nav-menu/menu', component: MenuComponent },

  { path: 'user-accounts/logout', component: LogoutComponent },
  { path: 'user-accounts/register-user', component: RegisterUserComponent },
  { path: 'user-accounts/change-password', component: ChangePasswordComponent },
  { path: 'user-accounts/reset-password', component: ResetPasswordComponent },
  //{ path: 'user-accounts/update-user', component: UpdateUserComponent },
  { path: 'common-utility/blank', component: BlankComponent },
  { path: 'user-accounts/update-my-account', component: UpdateMyAccountComponent },
  { path: 'user-accounts/get-username', component: GetUsernameComponent },
  { path: 'user-accounts/username', component: UsernameComponent },
  { path: 'user-accounts/password', component: PasswordComponent },
  { path: 'user-accounts/reset-password-admin', component: ResetPasswordAdminComponent },
  { path: 'user-accounts/user-permission-admin', component: UserPermissionAdminComponent },
  //{ path: 'user-accounts/user-permission-person-edit', component: UserPermissionPersonEditComponent },
  //{ path: 'user-accounts/user-permission-registration-edit', component: UserPermissionRegistrationEditComponent },
  //{ path: 'user-accounts/user-permission-restrict-edit', component: UserPermissionRestrictEditComponent },
  { path: 'user-accounts/feedback', component: FeedbackComponent },
  { path: 'customers/customer-backoffice', component: CustomerInformationDialogComponent },
  { path: 'customers/customer-commlist', component: CustomerCommlistComponent },
  { path: 'customers/customer-maillist', component: CustomerMaillistComponent },
  { path: 'customers/customer-documentlist', component: CustomerDocumentlistComponent},
  { path: 'customers/customer-profile-setup', component: CustomerProfileSetupComponent, data: { title: 'Customer Profile Setup' } },
  { path: 'customers/customer-profile-setup2', component: CustomerProfileSetupComponent2, data: { title: 'Manage Client Profiles' } },
  { path: 'customers/customer-profile-setup-edit', component: CustomerProfileSetupEditComponent },
  { path: 'persons/customer-personlist', component: CustomerPersonlistComponent },
  { path: 'persons/customer-person-details', component: CustomerPersonDetailsComponent },
  { path: 'aircraft/manage-aircraft-profile', component: AircraftProfileComponent },
  { path: 'aircraft/aircraft-profile-details', component: AircraftProfileEditComponent },
  //{ path: 'ground-stops/trip-quick-search-control', component: TripQuickSearchControlComponent },
  //{ path: 'ground-stops/trip-details', component: TripDetailsComponent },
  //{ path: 'ground-stops/ground-stop-details', component: GroundStopDetailsComponent },
  { path: 'hotels/hotellist', component: HotellistComponent },
  { path: 'airports/airport-bi-dashboard', component: AirportBiDashboardComponent },
  { path: 'vendors/vendor-bi-dashboard', component: VendorBiDashboardComponent },
  { path: 'customers/customer-bi-dashboard', component: CustomerBiDashboardComponent },
  { path: 'flight-watch/covid-bi-dashboard', component: CovidBiDashboardComponent },
  { path: 'bi-dashboards/dev-tickets-bi-dashboard', component: DevTicketsBiDashboardComponent, data: { title: 'Dev Tickets Dashboard' } },
  { path: 'ground-stops/incomming-trip-leg', component: IncommingTripLegComponent },
  { path: 'ground-stops/trip-audit-dashboard', component: TripAuditDashboardComponent, data: { title: 'Audit Trail' } },
  //{ path: 'ground-stop-advanced/task-queue-dashboard', component: TaskQueueDashboardComponent },
  { path: 'ground-stop-advanced/task-queuelist', component: TaskQueuelistComponent, data: { title: 'Task Queue' } },
  { path: 'vendors/vendor-profile-list', component: VendorProfileListComponent, data: { title: 'Vendor Profiles' } },
  { path: 'vendors/vendor-profile-setup', component: VendorProfileSetupComponent },
  { path: 'customers/customer-profile-bi-dashboard', component: CustomerProfileBiDashboardComponent },
  { path: 'vendor/vendor-person-details', component: VendorPersonDetailsComponent },
  { path: 'user-accounts/user-permission-client', component: UserPermissionClientComponent },
  { path: 'ground-stops-client/trip-quick-search-control-client', component: TripQuickSearchControlClientComponent },
  { path: 'ground-stops-client/trip-details-client', component: TripDetailsClientComponent },
  { path: 'current-events-client/current-events-dashboard', component: CurrentEventsDashboardClientComponent },
  { path: 'current-events/company-events-list', component: CompanyEventListComponent },
  { path: 'current-events/current-events-dashboard', component: CurrentEventsDashboardComponent },
  { path: 'current-events/headline-list', component: HeadlineListComponent },
  { path: 'ground-stops/trip-details-internal', component: TripDetailsInternalComponent },
  { path: 'ground-stops/trip-quick-search-control-internal', component: TripQuickSearchControlInternalComponent, data: { title: 'Trip Schedules' } },
  { path: 'account-groups/account-grouplist', component: AccountGrouplistComponent, data: { title: 'Account Grouping' } },
  { path: 'trip-legs/trip-leglist', component: TripLeglistComponent, data: { title: 'Daily View' } },
  { path: 'business-rules/manage-business-rules', component: ManageBusinessRulesComponent, data: { title: 'Business Rules' } },
  { path: 'vendors/fuel-bi-dashboard', component: FuelBiDashboardComponent, data: { title: 'Fuel Dashboard' } },
  { path: 'ground-stops/trip-code-search', component: TripCodeSearchComponent, data: { title: 'Search' } },
  { path: 'trip-legs-client/trip-leglist-client', component: TripLeglistClientComponent, data: { title: 'Daily View' } },
  { path: 'bi-dashboards/fpi-marketing-bi', component: FpiMarketingBiComponent, data: { title: 'Marketing Dashboard' } },
  { path: 'messages/message-center', component: MessageCenterComponent, data: { title: 'Message Center' } },
  { path: 'bi-dashboards/finance-bi-dashboard', component: FinanceBiDashboardComponent, data: { title: 'Finance Dashboard' } },
  { path: 'bi-dashboards/trip-quote-bi-dashboard', component: TripQuoteBiDashboardComponent, data: { title: 'Trip Quote Dashboard' } },
  { path: 'bi-dashboards/leg-match-bi-dashboard', component: LegMatchBiDashboardComponent, data: { title: 'Leg Match Dashboard' } },
  { path: 'bi-dashboards/message-bi-dashboard', component: MessageBiDashboardComponent, data: { title: 'Message Dashboard' } },
  { path: 'messages/message-import', component: MessageImportComponent, data: { title: 'Message Import' } },
  { path: 'fuelpricing/fuelpricing', component: FuelpricingComponent, data: { title: 'Fuel Import' } },
  //{ path: 'fuelpricing/fuel-price-upload1', component: FuelPriceUpload1Component },
  { path: 'fuel-price-upload/fuel-price-upload', component: FuelPriceUploadComponent },
  { path: 'fuelmenu/fuel-supplier-profile', component: FuelSupplierComponent, data: { title: 'Fuel Supplier Profile' } },
  { path: 'fuelmenu/fuel-pricing-summary', component: FuelPricingSummaryComponent, data: { title: 'Fuel Pricing' } },
  { path: 'messages-client/message-center-client', component: MessageCenterClientComponent, data: { title: 'Message Center' } },
  { path: 'fuelmenu/revise-fuel-pricing', component: ReviseFuelPricingComponent, data: { title: 'Invalid Airports' } },
  { path: 'taxesandfees/taxesandfees', component: TaxesAndFeesComponent, data: { title: 'Taxes and Fees' } },
  { path: 'currency/currency', component: CurrencyComponent, data: { title: 'Exchange Rates' } },
  { path: 'fuelmenu/fuel-client-markup', component: FuelClientMarkupComponent, data: { title: 'Fuel Client Markup' } },
  { path: 'fuelmenu/fuel-commissions', component: FuelCommissionsComponent, data: { title: 'Fuel Commissions' } },
  { path: 'fuelmenu/missing-taxes-airport', component: MissingTaxesAirportComponent, data: { title: 'Missing Taxes' } },
  { path: 'fuelmenu/missing-taxes-airport-all-details', component: MissingTaxesAirportAllDetailsComponent, data: { title: 'Missing Taxes' } },
  { path: 'fuelmenu/fuelmenu', component: FuelMenuComponent },
  { path: 'template/template-dashbord', component: TemplateDashboardComponent, data: { title: 'Message Templates' } },
  { path: 'fuelmenu/missing-prices-airport', component: MissingPricesAirportComponent },
  { path: 'country-details', component: CountryDetailsComponent },
  { path: 'country-document-type-update', component: CountryDocumentTypeUpdateDialog },
  { path: 'fuelmenu/taxesandfees-description', component: TaxesAndFeesDescriptionDialog }, 
  { path: 'fuelmenu/fuel-markup-exchange', component: FuelExchangeRateMarkupComponent }, 
  { path: 'missing-prices/location-metrics', component: LocationMetricsComponent, data: { title: 'Location Metrics' } }, 
  { path: 'missing-prices/location-preferences', component: LocationPreferencesComponent, data: { title: 'Location Preferences' } }, 
  { path: 'fuelmenu/fuel-task-queuelist', component: FuelTaskQueuelistComponent, data: { title: 'Fuel Task Queue' } },
  { path: 'current-events/trip-closeout-queue', component: TripCloseOutQueueComponent, data: { title: 'Trip Closeout Queue' } },
  { path: 'maintenance/maintenance-dashboard', component: MaintenanceDashboardComponent, data: { title: 'Maintenance' } },
  { path: 'maintenance/third-party-vendors', component: ThirdPartyVendorsComponent, data: { title: 'Third Party Vendors' } },
  { path: 'maintenance/global-settings', component: GlobalSettingsDashboardComponent, data: { title: 'Global Settings' } },
  { path: 'maintenance/fpc-picklist-data', component: FpcPicklistDataComponent, data: { title: 'Picklist' } },
  { path: 'accounting/accounting-dashboard', component: AccountingDashboardComponent },
  { path: 'accounting/trip-closeout-billing-completed', component: TripCloseOutBiilingCompletedComponent, data: { title: 'Billing Completed Dashboard' } },
  { path: 'documents/document-type-list', component: DocumentTypeListComponent },
  { path: 'documents/document-type-edit', component: DocumentTypeEditComponent },
  { path: 'persons/manage-contact-details', component: ManageContactDetailsComponent },
  { path: 'accounting/gts-billable-services', component: GtsBillableServicesComponent },
  { path: 'airports/airport-details-edit', component: AirportDetailsEditComponent },
  { path: 'airports/manage-airports', component: ManageAirportsComponent },
  { path: 'airports/airport-note-revision-dialog', component: AirportRevisionModalComponent },
  { path: 'airports/airport-document-expiration-dialog', component: AirportRevisionModalComponent },
  { path: 'advisory-details', component: AdvisoryDetailsComponent },
  { path: 'persons/person-audit-report', component: PersonAuditReportComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

