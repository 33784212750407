<div>
  <div style="padding-left:1em; padding-top:0.5em">
    <h5>{{dialogTitle}}</h5>
  </div>

  <div style="padding-top:1em;text-align:center;white-space:pre-line">
    {{message}}
  </div>

  <div style="text-align:center; padding-top:2em">
    <button mat-button class="btn-low" (click)="close()">&nbsp;{{buttonText}}<!--Continue-->&nbsp;</button>&nbsp;
  </div>
</div>
