export class LK_FuelFrequencyModel {

  Lk_FuelFrequencyGUID: string;
  Lk_FuelFrequencyCode: string;
  Lk_FuelFrequencyDescription: string;
  IsActive: boolean;
  ModifiedBy: string;
  ModifiedDate: Date;
 
}
