<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-1em" mat-dialog-title>Company Events Announcement</div>
<app-spin *ngIf="showSpin"></app-spin>

<div style="background-color:white;padding:1em;margin-top:-1em;min-width:30em;" class="no-gutters">
  <form *ngIf="company_event_edit_form" [formGroup]="company_event_edit_form" (ngSubmit)="clickSave()">
    <div class="d-inline-flex">
      <div>
        <div class="row">
          <div class="col">
            <label style="font-size:small">Event Title</label>
            <div style="margin-top:-0.35em;">
              <input type="text" class="form-control form-control-sm inputBox" required style="width:25em" formControlName="eventTitle" [ngClass]="{ 'is-invalid': submitted && f.eventTitle.errors }" />
              <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
                Event Title is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="font-size:small">Web Link</label>
            <div style="margin-top:-0.35em;">
              <input type="text" class="form-control form-control-sm inputBox" style="width:25em" formControlName="webLink" />

            </div>
          </div>
        </div>
      </div>
      <div class="ml-2">
        <div>
          <div class="d-inline-flex">
            <div>
              <label for="startDate" style="font-size:small">Display Date Start</label>
              <div style="display:flex;margin-top:-0.35em">
                <input type="text" [matDatepicker]="startdatepicker" tabindex="4" placeholder="MM/DD/YYYY" formControlName="startDate" #startDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                <mat-datepicker-toggle matSuffix [for]="startdatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                <mat-datepicker #startdatepicker></mat-datepicker>
              </div>
              <div *ngIf="submitted && f.startDate.errors" class="invalid-tooltip p-0" style="font-size:small">
                <div *ngIf="f.startDate.errors.isValidDate==false">Invalid start date. format: MM/DD/YYYY</div>
                <div *ngIf="f.startDate.errors.isValidDateRange==false">Start date must be before end date.</div>
              </div>
            </div>
            <div>
              <label for="endDate" style="font-size:small">Display Date End</label>
              <div style="display:flex;margin-top:-0.35em">
                <input type="text" [matDatepicker]="enddatepicker" tabindex="5" placeholder="MM/DD/YYYY" formControlName="endDate" #endDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.endDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                <mat-datepicker-toggle matSuffix [for]="enddatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                <mat-datepicker #enddatepicker></mat-datepicker>
              </div>
              <div *ngIf="submitted && f.endDate.errors" class="alert-danger p-0" style="font-size:small">
                <div *ngIf="f.endDate.errors.isValidDate==false">Invalid end date. format: MM/DD/YYYY</div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top:-.45em">
          <div class="d-inline-flex"  >
            <div>
              <label for="effectiveDate" style="font-size:small">Effective Date</label>
              <div style="display:flex;margin-top:-0.35em">
                <input type="text" [matDatepicker]="effectivedatepicker" tabindex="4" placeholder="MM/DD/YYYY" formControlName="effectiveDate" #effectiveDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.effectiveDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                <mat-datepicker-toggle matSuffix [for]="effectivedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                <mat-datepicker #effectivedatepicker></mat-datepicker>
              </div>
              <div *ngIf="submitted && f.effectiveDate.errors" class="alert-danger p-0" style="font-size:small">
                <div *ngIf="f.effectiveDate.errors.isValidDate==false">Invalid effective date. format: MM/DD/YYYY</div>
                <div *ngIf="f.effectiveDate.errors.required">Effective date is required.</div>
                <div *ngIf="f.effectiveDate.errors.isValidDateRange==false">Effective date must be before expiration date.</div>
              </div>
            </div>
            <div>
              <label for="expirationDate" style="font-size:small">Expiration Date</label>
              <div style="display:flex;margin-top:-0.35em">
                <input type="text" [matDatepicker]="expirationdatepicker" tabindex="5" placeholder="MM/DD/YYYY" formControlName="expirationDate" #expirationDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.expirationDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                <mat-datepicker-toggle matSuffix [for]="expirationdatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                <mat-datepicker #expirationdatepicker></mat-datepicker>
              </div>
              <div *ngIf="submitted && f.expirationDate.errors" class="alert-danger p-0" style="font-size:small;width:max-content">
                <div *ngIf="f.expirationDate.errors.isValidDate==false">Invalid expiration date. format: MM/DD/YYYY</div>
              </div>
            </div>


            <div>
              <label style="font-size:small">Valid Time Start</label>
              <div style="margin-top:-0.35em;">
                <input type="text" formControlName="validTimeStart" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.validTimeStart.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('validTimeStart', $event)" />
              </div>
            </div>
            <div class="ml-2">
              <label style="font-size:small">Valid Time End</label>
              <div style="margin-top:-0.35em;">
                <input type="text" formControlName="validTimeEnd" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.validTimeEnd.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('validTimeEnd', $event)" />
              </div>
            </div>

            <div>
              <div class="mt-4">
                <mat-slide-toggle style="transform:scale(.8)" color="primary" [checked]="isUTC" formControlName="isUTC">UTC</mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="eventStory" style="font-size:small">Event Story</label>
        <div style="margin-top:-0.35em;">
          <!--<textarea formControlName="eventStory" required class="form-control" style="width:45em; height:12em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd" [ngClass]="{ 'is-invalid': submitted && f.eventStory.errors }">   
            </textarea>-->
            <ckeditor #notesCKEditor data="ckEditorData" formControlName="eventStory" [editor]="Editor" [config]="CKEConfig" (ready)="onReady($event)"  [disabled]="isDisabled"></ckeditor>
          
          <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
            Event Story is required.
          </div>
        </div>
      </div>
    </div>
    <div class="d-inline-flex">
    <div class="mt-1">
      <div style="width:45em; min-height:2em; background-color: #E9ECEF; border-radius:0.25em; font-size: small; padding-left: 0.25em ">
        <div style="display: flex; margin-top:0.25em;">
          Attachments: <div style="margin-left:0.25em;margin-top:0.125em">
            <label class="btn btn-high">
              <input #document formControlName="document" id="document" type="file" multiple style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />
              <div style="display: flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: white; transform: rotate(-90deg);">attachment</i><div>Load New</div></div><span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
            </label>
          </div>
          <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:28em; "><label style="color:black; font-size: small">{{errMsg}}</label></div>
        </div>
        <!--document list-->
        <div style="height:5em;overflow-y:auto">
          <div *ngFor="let item of contentDocumentList; let i=index">
            <div style="font-size: small; display:flex; padding:0em;">
              <div><a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" title="{{item.documentName}}" (click)="clickPreviewDoc($event, item)">{{item.documentName}}</a></div>
              <div *ngIf="item.contentDocumentGUID=='' && hasExistingDocument" style="margin-top:0.5em; font-size:x-small"><i class="material-icons" style="color:green; font-size: small; ">fiber_new</i></div>
              <div style="margin-top:0.25em; margin-left: 0.25em"><i class="material-icons" style="color:red; font-size: small; cursor: pointer;" title="click to remove the document" (click)="clickRemoveDocument($event, item)">cancel</i></div>
            </div>
          </div>
        </div>
        <!--document list end-->
      </div>
    </div>
    <div *ngIf="companyEventID!=0" class="d-inline-flex ml-2">
      <div>
        <label for="event_status" style="font-size:small">Disable Record</label>
        <div style="margin-top:-0.5em;">
          <select *ngIf="f" formControlName="event_status" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color: lightgrey">
            <option value="1" selected>No</option>
            <option value="0">Yes</option>
          </select>
        </div>
      </div>
      <div>

        <div class="ml-3">
          <label for="modifiedBy" style="font-size:small">Modified by</label>
          <div style="margin-top:-0.5em;">
            <input type="text" formControlName="modifiedBy" readonly class="form-control" style="padding-left:0em;width:10em;height:1.5625em;font-size:small" />
          </div>
        </div>
      </div>
      <div>
        <div class="ml-3">
          <label for="modifiedDate" style="font-size:small">Modified Date</label>
          <div style="margin-top:-0.5em;">
            <input type="text" formControlName="modifiedDate" readonly class="form-control" style="padding-left: 0em; width: 10em; height: 1.5625em; font-size: small" />
          </div>
        </div>
      </div>
    </div>
      </div>
  </form>
</div>
<div class="mt-2 d-inline-flex">
  <div>
    <button mat-button (click)="clickSave()" class="btn-high">Save</button>
    <button mat-button mat-dialog-close (click)="clickClose()" class="btn-low ml-2">Close</button>
  </div>
  <div *ngIf="showSuccessMsg" class="ml-2">
    <app-success-message [successMsg]=""></app-success-message>
  </div>
  <div *ngIf="errMsg2!=''" class="ml-2 alert alert-danger small" style="width:max-content">
    {{errMsg2}}
  </div>
</div>
