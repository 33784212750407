import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UserModel } from "../models/user.model";
import { IdentityService } from "../services/identity.service";
import { ResponseModel } from "../models/response.model";
import { AuthenticateService } from "../services/authenticate.service";
import { IdentityRequestModel } from "../models/identity-request.model";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'multi-factor-auth',
  templateUrl: './multi-factor-auth.component.html',
  styleUrls: ['./password-recovery.component.css']
})

export class MultiFactorAuthComponent implements OnInit {
  mfa_form: UntypedFormGroup;
  mfaEnabled: boolean = true;
  phoneNumber: string = '';
  email: string = '';
  errMsg: string = '';
  successMsg: string;
  showSuccessMsg: boolean;
  hideOPwd: boolean = true;
  showOPwdEye: boolean = false;
  user: UserModel;
  currentPassword: string;
  passwordValid: boolean = false;
  userType: string;
  internalEmailEndsWithList: string[];

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _identityService: IdentityService,
    private readonly _authService: AuthenticateService,
    private readonly _dialogRef: MatDialogRef<MultiFactorAuthComponent>) {

  }

  ngOnInit(): void {
    this.user = new UserModel();

    this.initControls();
    this.loadDefaultInfo();
  }

  initControls(): void {
    this.mfa_form = this._formBuilder.group({
      old_password: [null, Validators.required],
      phone_number: [this.phoneNumber, Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9- ().]{10,12}$")]
      )],
      email: [this.email, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,100}$")]
      )]
    })
  }

  loadDefaultInfo(): void {
    var request = new IdentityRequestModel();
    try {
        this._identityService.getUser<ResponseModel<UserModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
                this.email = response.model.mfaEmail;
                this.phoneNumber = response.model.mfaPhoneNumber;
                this.userType = response.model.userType;
                this.mfa_form = this._formBuilder.group({
                  old_password: [null, Validators.required],
                  phone_number: [this.phoneNumber, Validators.compose([
                    Validators.required,
                    Validators.pattern("^[0-9- ().]{10,12}$")]
                  )],
                  email: [this.email, Validators.compose([
                    Validators.required,
                    Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,100}$")]
                  )]
                });
                if (this.userType.toLowerCase() == "internal") {
                    this._identityService.getInternalEmailEndsWith<ResponseModel<string[]>>().subscribe(response1 => {
                        if (response1 != null && response1.code == "200") {
                            if (response1.message == "") {
                                this.internalEmailEndsWithList = response1.model;
                            }
                        }
                    });
                }
            }
            else {
                if (response.code == "401") {
                    this._authService.signOut();
                }
            }
        });
    }
    catch (error) {
        //console.log(error);
    }
}

  get f() { return this.mfa_form.controls; }

  onKeyup(event, controlflag: number) {
    this.errMsg = "";
    if (event.target.value != "") {
      switch (controlflag) {
        case 1:
          this.showOPwdEye = true;
          this.currentPassword = event.target.value;
          break;
        case 2:
          this.phoneNumber = event.target.value;
          break;
        case 3:
          this.email = event.target.value;
          break;
      }
    }
    else {
      switch (controlflag) {
        case 1:
          this.showOPwdEye = false;
          this.currentPassword = event.target.value;
          break;
        case 2:
          this.phoneNumber = event.target.value;
          break;
        case 3:
          this.email = event.target.value;
          break;
      }
    }
  }

  onMobileChange(e: any) {
    this.phoneNumber = e.target.value;
    this.user.mfaPhoneNumber= this.phoneNumber;
  }

  onEmailChange(e: any) {
    this.email = e.target.value;
    this.user.mfaEmail = this.email;
  }

  sendPasswordRecoveryMessage(usingSms: boolean) {
    this.user.isUsingSms = usingSms;
    this._identityService.sendPasswordRecoveryMessage(this.user).subscribe(response => {
    });
  }

  onSave() {
    this.user.mfaPhoneNumber = this.f.phone_number.value;
    this.user.mfaEmail = this.f.email.value;
    this.user.isActive = true;
    this.user.password = this.currentPassword;
    this.user.username = localStorage.getItem('un');
    this.user.isUsingOnlyContactInfo = true;
    
    this._identityService.validatePassword<ResponseModel<boolean>>(this.user).subscribe(response => {
      this.passwordValid = response.model;

      if(!this.passwordValid) {
        this.errMsg = "Current password is incorrect.";
        return;
      }
      else {
        this._identityService.updateUser<ResponseModel<UserModel>>(this.user).subscribe(response => {
          if (response != null && response.code == "200") {
            this.showSuccessMsg = true;
            this.successMsg = "Personal info successfully updated."
            setTimeout(() => {this._dialogRef.close()}, 2000);
          }
          else {
            this.errMsg = "Error: " + response.message;
          }
        })
      }
    })
  }

  validatePassword() {
    this.user.password = this.currentPassword;
    this.user.username = localStorage.getItem('un');

    this._identityService.validatePassword<ResponseModel<boolean>>(this.user).subscribe(response => {
      this.passwordValid = response.model;

      if (!this.passwordValid) {
        this.errMsg = "Current password is incorrect.";
        return;
      }
    })
  }
}