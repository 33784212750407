import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { DistanceTimeModel } from '../models/distance-time.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { GroundStopService } from '../services/ground-stop.service';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { TripLegModel } from '../models/trip-leg.model';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { TripLegService } from '../services/trip-leg.service';
import { TripModel } from '../models/trip.model';
import { ReturnObjModel } from '../models/return-obj.model';

@Component({
  selector: 'app-leg-ferry-flight-editor-dialog',
  templateUrl: './leg-ferry-flight-editor-dialog.component.html',
  styleUrls: ['./leg-ferry-flight-editor-dialog.component.css']
})

export class LegFerryFlightEditorDialogComponent implements OnInit {

  dialogTitle: string;
  customerGUID: string;
  //aircraftGUID: string;
  tripCodeGUID: string;
  groundStopGUID: string;
  nextArrivalGroundStopGUID: string;
  leg_ferry_flight_editor_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  
  showSpin: boolean = false;

  isModified: boolean = false;

  showSuccessMsg: boolean = false;

  upPII: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";
  //legList: TripLegModel[] = [];
  //existingLegList: TripLegModel[] = [];
  dataChanged: boolean;
  /*insertedLegDepICAO: string = "";*/
  currentLeg: TripLegModel;
  lockdownTrip: boolean;
  arrivalDateTimePassed: boolean = false;
  leg: string = "";
  isDeptFerryFlight: boolean = false;
  returnObj = new ReturnObjModel();
  constructor(private readonly _dialogRef: MatDialogRef<LegFerryFlightEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _airportService: AirportService, private readonly _groundStopService: GroundStopService,
    private readonly _groundStopClientService: GroundStopClientService, private readonly _dialogService: DialogService,
    private readonly _tripLegService: TripLegService
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }

    this.tripCodeGUID = _data.tripCodeGUID;
    this.groundStopGUID = _data.groundStopGUID;
    this.nextArrivalGroundStopGUID = _data.nextArrivalGroundStopGUID;
    //this.aircraftGUID = _data.aircraftGUID;
    //this.dialogTitle = _data.dialogTitle;

  }

  getAllData(): Observable<any[]> {
    let reqTrip = new TripModel();
    reqTrip.tripCodeGUID = this.tripCodeGUID;
    reqTrip.customerGUID = this.customerGUID;
   
    let getActiveLegResponse = this._tripLegService.getActiveTripLegListForPersonEditBytId(reqTrip);
    return forkJoin([getActiveLegResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }

    //this.duplicateIATAErrMsg = "";
    //this.wrongAirportErrMsg = "";
    //this.airportList = [];
    //this.validatedAirport = false;
    this.showSuccessMsg = false;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].model != null) {
          let legList: TripLegModel[] = [];
          legList = responses[0].model;
          this.currentLeg = legList.find(x => x.groundStopGUID.toLowerCase() == this.groundStopGUID.toLowerCase() && x.nextArrivalGroundStopGUID.toLowerCase() == this.nextArrivalGroundStopGUID.toLowerCase());
          this.lockdownTrip = this.currentLeg.lockdownTrip;
          this.arrivalDateTimePassed = this.currentLeg.nextArrivalDateTimePassed;
          this.leg = "LEG " + this.currentLeg.tripLegOrder.toString() + " " + this.currentLeg.leg_ICAOs;
          this.isDeptFerryFlight = this.currentLeg.isDeptFerryFlight;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();
      this.showSpin = false;

    });


  }

  initControls() {
    this.leg_ferry_flight_editor_form = this._formBuilder.group({
      isDeptFerryFlight: this.isDeptFerryFlight
      
    });


  }

  get f() { return this.leg_ferry_flight_editor_form.controls; }

  

  

  clickSave() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.leg_ferry_flight_editor_form.invalid) {
      return;
    }

    this.loading = true;

    let obj = new TripLegModel();
    obj.customerGUID = this.customerGUID;   
    obj.tripCodeGUID = this.tripCodeGUID;
    obj.groundStopGUID = this.groundStopGUID;
    obj.nextArrivalGroundStopGUID = this.nextArrivalGroundStopGUID;
    obj.isDeptFerryFlight = this.f.isDeptFerryFlight.value;

    this._tripLegService.updateFerryFlightByLeg<ResponseModel<boolean>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.returnObj.refresh = true;
          this.returnObj.isFerryFlight = obj.isDeptFerryFlight;
          this.isModified = true;
          this.showSuccessMsg = true;
          this.dataChanged = false;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);

        }
        else {
          this.errMsg = "Failed to update ferry flight at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to update ferry flight at this time. Please try later.";
        }
      }
      this.loading = false;
    })

  }

  clickClose() {
    if (this.dataChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes you just made before close the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(this.returnObj);
        }
      });
    }
    else {
      this._dialogRef.close(this.returnObj);
    }
  }


}
