
<div *ngIf="grantAccessToMessages" style="height:40em">
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle style="margin-top:-1em" mat-dialog-title class="d-flex justify-content-between">
    <div>
      <div class="d-flex"><span class="material-icons-outlined mt-1 mr-1">email</span>Trip Message Center<span *ngIf="groundStopTaskGUID!=''">&nbsp;- {{serviceTypeDescription}}</span></div>
      <div class="d-flex">
        <div>
          <button mat-button class="btn-high larger" style="margin-right:1em;margin-top:.5em" (click)="newMessage()">
            <!--<mat-icon>email</mat-icon>-->
            New Message
          </button>
        </div>
        <div class="d-flex">
          <div style="margin-right:1em">
            <div style="font-size: small;font-weight:normal">Registration</div>
            <div style="font-weight: bold; font-size: small;margin-top:-1em">{{registration}}</div>
          </div>
          <div style="margin-right:1em">
            <div style="font-size: small; font-weight: normal">Trip Code</div>
            <div style="font-weight: bold; font-size: small; margin-top: -1em">{{tripCode}}</div>
          </div>
          <div *ngIf="groundStopTaskGUID==''">
            <div style="font-size: small; font-weight: normal">Route</div>
            <div style="display: flex; font-weight: bold; font-size: small; margin-top: -1em">
              <div *ngFor="let gs of selectedGroundStopList; let i=index">
                <div style="display: flex;height:fit-content">
                  <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==selectedGroundStopList.length-1? '': '-'}}</div>
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="groundStopTaskGUID!=''" class="d-flex">
            <div style="margin-right:1em">
              <div style="font-size: small; font-weight: normal">Business Stop</div>
              <div style="font-size: small; display: flex; margin-top: -1em " *ngIf="prevICAO"><div style="font-weight: normal">{{prevICAO!=''?prevICAO+'-':''}}</div><div style="font-weight: bold">{{icao}}</div><div style="font-weight: normal">{{nextICAO!=''?'-'+nextICAO:''}}</div></div>
            </div>
            <div style="margin-right:1em" *ngIf="arrivalDateTime!=''">
              <div style="font-size: small; font-weight: normal">Arrival({{icao}})</div>
              <div style="font-size: small;font-weight: bold; margin-top:-1.25em">{{arrivalDateTime}}</div>
              <div style="font-size: small; margin-top: -1.5em; font-weight: normal"> {{arrivalDateTimeLocal!=''?arrivalDateTimeLocal:''}}</div>
            </div>
            <div style="margin-right:1em" *ngIf="departureDateTime!=''">
              <div style="font-size: small; font-weight: normal">Departure({{icao}})</div>
              <div style="font-size: small;font-weight: bold; margin-top:-1.25em">{{departureDateTime}}</div>
              <div style="font-size: small;margin-top:-1.5em;font-weight:normal"> {{departureDateTimeLocal!=''?departureDateTimeLocal:''}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div><mat-icon mat-dialog-close style="margin-top:.5em">close</mat-icon></div>
  </div>
  <div style="background-color:white" class="mx-1 p-2">
    <div>
      <label style="font-weight:bold">Messages</label>
      <mat-radio-group [(ngModel)]="messageType" (change)="messageTypeChange()">
        <mat-radio-button value="both">Both ({{totalCount}})</mat-radio-button>
        <mat-radio-button value="in">Inbound ({{inCount}})</mat-radio-button>
        <mat-radio-button value="out">Outbound ({{outCount}})</mat-radio-button>
      </mat-radio-group>
    </div>
    <div style="overflow:auto;max-height:30em">

      <table mat-table [dataSource]="filteredMessageList" >
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%">Type</th>
          <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">{{element.messageFolderID==1? 'In':'Out'}}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 10%;width:10%"> Date (Z) </th>
          <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 10%;width:10%"> {{element.sentDateTime}} </td>
        </ng-container>
        <ng-container matColumnDef="leg">
          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; flex: 0 0 10%; width: 8%"> Leg </th>
          <td mat-cell *matCellDef="let element" style="text-align: left !important; flex: 0 0 10%; width: 8%">{{element.leg}}</td>
        </ng-container>
        <ng-container matColumnDef="service">
          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; flex: 0 0 10%; width: 10%"> Service </th>
          <td mat-cell *matCellDef="let element" style="text-align: left !important; flex: 0 0 10%; width: 10%">{{element.serviceTypeDescription}}</td>
        </ng-container>
        <ng-container matColumnDef="sender">
          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; flex: 0 0 10%; width: 12%"> Sender/Recipient </th>
          <td mat-cell *matCellDef="let element" style="text-align: left !important; flex: 0 0 10%; width: 12%">{{element.sender}}  </td>
        </ng-container>
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; flex: 0 0 15%; width: 25%"> Subject </th>
          <td mat-cell *matCellDef="let element" style="text-align: left !important; flex: 0 0 15%; width: 25%">{{element.subject}}</td>
        </ng-container>
        <ng-container matColumnDef="body">
          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; flex: 0 0 20%; width: 33%"> Body </th>
          <td mat-cell *matCellDef="let element" style="text-align:left !important;flex:0 0 20%;width:33%">{{element.bodyPreview=='' ? element.bodyTxt : element.bodyPreview}}</td>
        </ng-container>
        <ng-container matColumnDef="hasAttachments">
          <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 2%;width:2%">  </th>
          <td mat-cell *matCellDef="let element; let i=index" style="text-align:center !important;flex:0 0 2%;width:2%" (click)="openMessage(element)">
            <span *ngIf=element.hasAttachments class="material-symbols-outlined" style="transform:rotate(-45deg);font-size:medium">
              attachment
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="text-align: center !important; flex: 0 0 8%; width: 5%"> Action </th>
          <td mat-cell *matCellDef="let element" style="text-align: center !important; flex: 0 0 8%; width: 5%"><button mat-button class="btn-high grid" (click)="openMessage(element)">Open</button>  </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <app-spin *ngIf="isLoading"></app-spin>
</div>
