import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { CategoryModel } from '../models/category.model';
import { HelpGuideModel } from '../models/help-guide.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CONTAINER_DATA, HelpGuideService } from '../services/help-guide.service';
import { HelpGuideCategoriesComponent } from './help-guide-categories.component';
import { HelpGuidesComponent } from './help-guides.component';
import * as DecoupledEditor from '../ckeditor5/ckeditor';
import { CKEditorConfig } from '../ckeditor5/ckeditor-config';
import { HelpGuideTopicsComponent } from './help-guide-topics.component';
import { HelpGuideDetailsComponent } from './help-guide-details.component';
import { forkJoin, Observable, of } from 'rxjs';
import { AudienceModel } from '../models/audience.model';
import { DeviceTypeModel } from '../models/device-type.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-help-guide-details-edit',
  templateUrl: './help-guide-details-edit.component.html',
  styleUrls: ['./help-guide-details-edit.component.css']
})


export class HelpGuideDetailsEditComponent implements OnInit {
  public Editor = DecoupledEditor;

  public CKEConfig = CKEditorConfig.ckeditorConfig;

  public onReady(editor) {

    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()

    );

    editor.setData(this.mainBodyHTML);

  }


  isDisabled: boolean;
  @ViewChild('emailCKEditor') emailCKEditorRef: any;//ElementRef;
  help_guide_edit_form: FormGroup;
  guide: HelpGuideModel=new HelpGuideModel();
  helpGuideGuid: string;
  detailsPortal: ComponentPortal<HelpGuideDetailsComponent>;
  topicPortal: ComponentPortal<HelpGuideTopicsComponent>;
  categoryList: CategoryModel[];
  categoryListAll: CategoryModel[];
  audienceList: AudienceModel[];
  deviceTypeList: DeviceTypeModel[];
  errMsg: string = "";
  showSuccessMsg: boolean = false;
  ckEditorData: string = "";
  categoryID: number;
  audienceID: number;
  deviceTypeID: number;
  sortOrderNumber: number;
  helpGuideTitle: string;
  isActive: boolean=true;
  mainBodyHTML: string = "";
  isAdmin: boolean = false;
  modifiedBy: string="";
  modifiedDate: string;
  submitted: boolean = false;
  constructor(private readonly _authService: AuthenticateService, private readonly _helpGuideService: HelpGuideService,
    private readonly _formBuilder: FormBuilder,
    private readonly helpGuide: HelpGuidesComponent, @Inject(CONTAINER_DATA) public componentData: any, private _injector: Injector) {
    this.helpGuideGuid = componentData.helpGuideGUID;
    this.categoryID = componentData.categoryID;
  }
  ngOnInit() {
    this._authService.updateAccessTime();
    this.isAdmin = Boolean(localStorage.getItem('ua'));
    if (!this.isAdmin) {
      return;
    }
    this.getData();
  }

  getData() {
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.audienceList = responses[0].model;
        }
        else {
          if (responses[0].code == "401")
            this._authService.signOut();
          else
            this.errMsg = "An error has occurred."
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.categoryListAll = responses[1].model;
          this.categoryList = this.categoryListAll.filter(x => x.audienceID == this.categoryListAll.find(x => x.categoryID == this.categoryID).audienceID);

        }
        else {
          if (responses[1].code == "401")
            this._authService.signOut();
          else
            this.errMsg = "An error has occurred."
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.deviceTypeList = responses[2].model;
        }
        else {
          if (responses[2].code == "401")
            this._authService.signOut();
          else
            this.errMsg = "An error has occurred."
        }
      }
      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.guide = responses[3].model;
          this.categoryID= this.guide.categoryID;
          this.deviceTypeID= this.guide.deviceTypeID;
          this.sortOrderNumber= this.guide.sortOrderNumber;
          this.helpGuideTitle= this.guide.helpGuideTitle;
          this.isActive= this.guide.isActive;
          this.mainBodyHTML = this.guide.mainBodyHTML;
          this.modifiedBy = this.guide.modifiedBy;
          this.modifiedDate = this.guide.modifiedDate;
        }
        else {
          if (responses[3].code == "401")
            this._authService.signOut();
          else
            this.errMsg = "An error has occurred."
        }
      }

      this.initControls();
    });

  }

  updateCategoryList() {
    this.categoryList = this.categoryListAll.filter(x => x.audienceID == this.f.audience.value);
  }

  initControls() {
    this.help_guide_edit_form = this._formBuilder.group({
      category: [this.categoryID,Validators.required],
      audience: [this.categoryList.find(x => x.categoryID == this.categoryID).audienceID, Validators.required],
      device: [this.deviceTypeID,Validators.required],
      sort_order: [this.sortOrderNumber,Validators.required],
      is_active: [Number(this.isActive),Validators.required],
      helpGuideTitle: [this.helpGuideTitle,Validators.required]
    });
  }

  get f() { return this.help_guide_edit_form.controls; }

  getAllData(): Observable<any[]> {
    let audienceResponse = this._helpGuideService.getAudienceList();
    let categoryResponse = this._helpGuideService.getCategoryListByAudienceID(0);
    let deviceTypeReponse = this._helpGuideService.getDeviceTypeList();
    let helpGuideResponse;
    if (this.helpGuideGuid != null)
      helpGuideResponse = this._helpGuideService.getHelpGuideByHelpGuideGUID(this.helpGuideGuid);
    else
      helpGuideResponse = of(null);

    return forkJoin([audienceResponse, categoryResponse, deviceTypeReponse, helpGuideResponse]);
  }
  goBack() {
    if (this.helpGuideGuid == null) {
      let category = new CategoryModel();
      category.categoryID = this.categoryID;
      category.categoryTitle = this.categoryList.find(x => x.categoryID == this.categoryID).categoryTitle,
      this.topicPortal = new ComponentPortal(HelpGuideTopicsComponent, null, this.createInjector(category));
      this.helpGuide.selectedPortal = this.topicPortal;
    }
    else {
      this.detailsPortal = new ComponentPortal(HelpGuideDetailsComponent, null, this.createInjector(this.helpGuideGuid));
      this.helpGuide.selectedPortal = this.detailsPortal;
    }
  }


  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.submitted = true;
    if (!this.help_guide_edit_form.valid)
      return;
    let request = new HelpGuideModel();
    request.helpGuideGUID=""
    if (this.helpGuideGuid!=null && this.helpGuideGuid!="")
      request.helpGuideGUID = this.helpGuideGuid;
    request.categoryID = this.f.category.value;
    request.deviceTypeID = this.f.device.value;
    request.sortOrderNumber = Number(this.f.sort_order.value);
    request.isActive = Boolean(Number(this.f.is_active.value));
    request.helpGuideTitle = this.f.helpGuideTitle.value;
    request.mainBodyHTML = this.emailCKEditorRef.editorInstance.getData();
    request.mainBodyRaw = this.emailCKEditorRef.elementRef.nativeElement.innerText;
    this._helpGuideService.saveHelpGuide<ResponseModel<string>>(request).subscribe(response => {
      if (response.code == "200" && response.message == "") {
        if (response.model != "") {
          this.helpGuideGuid = response.model;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.goBack();
          }, 1000);
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else
          this.errMsg = response.message;
      }
    });
  }

 
}
