<div  style="background-color:#f8f8f8;  height: 100%; padding:  1em 1em 1em 1em;" >
  <div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em; float: right;"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <i class="material-icons" style="color:black;" (click)="clickClose(false)">close</i>
  </div>
  <div style="padding: 0.5em; background-color:white ; height: 100%;">


    <div style="display: flex;padding-left:1em;"  >
      <div style="margin-top:0.3125em;">
    <div style="padding-left:1em;margin-top:0.3125em; margin-left: -2em;"><h5 style="display: flex;"><span style="color:#146ec3;">{{documentName}}&nbsp;</span>  - Internal Notes </h5></div>
      </div>
    </div>

    <div >
      <div>
        <div >
          
          <div class="form-group" style="margin-top:1.7em; margin-left: 1em;  ">
            <h6 for="prefileRequirements" style="font-size:medium; margin-bottom: 1.5em;  ">Enter Internal Notes For Document: </h6>
            <div style="margin-top:-0.5em;">
              <textarea spellcheck="true" [(ngModel)]="internalNotes" class="form-control" maxlength="255" 
                style="width:39.3em; height:7em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">
                      </textarea>
            </div>
          </div>


          <!-- <div class="form-group" style="margin-top:3em; margin-right:5.625em; display: flex;">
            <label for="segmentNotes" style="font-size:small; width: 10em;">{{segmentDetails.countryContentSegment}} Notes: </label>
            <div style="margin-top:-0.5em;">
              <textarea spellcheck="true" formControlName="segmentNotes" class="form-control"
                style="width:32.5em; height:6em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">
                      </textarea>
            </div>
          </div> -->
       
          <div   *ngIf="userType=='internal'">
            <button type="button" mat-button class="btn-low grid" (click)="editCountryNotesAndDocuments()">EDIT</button>
          </div>
        </div>


      </div>
    
    </div>

    <div style="display: flex">
      <div
        style="justify-content:flex-start;padding-left: 1em; padding-top:0.75em; padding-bottom:0.5em; margin-right:1em; height:3em">
        <button class="btn-high" mat-button style="margin-left: 0em;" (click)="clickAdd()" >Add</button>
<!-- (click)="clickSave()" -->
        <button class="btn-low" mat-button (click)="clickClose(false)" style="background-color:grey; color: white; margin-left: 1em;">Close</button>
        <!-- <button class="btn-low" mat-button (click)="getData()" style="background-color:grey; color: white; margin-left: 1em;">refresh</button> -->
 
      </div>
      <!-- <div *ngIf="errMsg!=''" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;"><label style="color:black; font-size: small">{{errMsg}}</label></div> -->
      <!-- <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
        <app-success-message [successMsg]=""></app-success-message>
      </div> -->
    </div>
  </div>
</div>
