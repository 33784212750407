<div *ngIf="grantAccessToManageAccountGrouping" style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div>
      <div style="margin-top:0.3125em;"><h5>ACCOUNTING GROUPING</h5></div>
      <div style="margin-top:-0.5em;">Group Name: {{customerGroupName}}</div>
    </div>
  </div>
  <div>
    <form *ngIf="account_group_audit_form" [formGroup]="account_group_audit_form">
      <div style="padding-left: 1em; padding-right:1em">
        <div style="background-color: white; padding-left: 1em; padding-right:1em; max-height:38em;overflow-y: auto">               
            <div>
              <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="transactionDate">
                    <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em; white-space: nowrap">Transaction Date/Time</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div>{{element.modifiedDate}}</div>
                      <div style="font-weight:bold;">{{element.modifiedBy}}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="changeRequestType">
                    <th mat-header-cell *matHeaderCellDef style="width:25%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em;">Change Request Type</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div *ngIf="element.isNewClientGroup">New Client Group</div>
                      <div *ngIf="element.isClientGroupNameChange">Group Name Change</div>
                      <div *ngIf="element.isClientChange">Client Change</div>
                      <div *ngIf="element.isInternalNotesChange">Internal Notes Change</div>
                      <div *ngIf="element.isClientGroupActiveChange">Group Status Change</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="changeRequest">
                    <th mat-header-cell *matHeaderCellDef style="width:30%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em;">Change Request</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div style="white-space:pre-wrap">{{element.changeRequest}}</div>

                    </td>
                  </ng-container>                 
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>
            </div>
         
        </div>
      </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
    <div style="margin-right:1em">
      <button type="button" mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
    </div>

  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
