import { HoursObjModel } from "./hoursObj.model";

export class VendorHoursModel {
  vendorHoursID: number;
  vendorID: number;
  vendorGUID: string;
  hoursTypeDescription: string;
  hoursTypeID: number;
  is24Hours: boolean;
  isOvertimeAvailable: boolean;
  isSeasonal: boolean;
  effectiveDate: string;
  endDate: string;
  isUTC: boolean;
  sun_Open: string;
  sun_Close: string;
  mon_Open: string;
  mon_Close: string;
  tue_Open: string;
  tue_Close: string;
  wed_Open: string;
  wed_Close: string;
  thu_Open: string;
  thu_Close: string;
  fri_Open: string;
  fri_Close: string;
  sat_Open: string;
  sat_Close: string;         
  remarks: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  sunClosed: number;
  monClosed: number;
  tueClosed: number;
  wedClosed: number;
  thuClosed: number;
  friClosed: number;
  satClosed: number;
  hasSun: boolean;
  hasMon: boolean;
  hasTue: boolean;
  hasWed: boolean;
  hasThu: boolean;
  hasFri: boolean;
  hasSat: boolean;
  sameHours: boolean;
  openTime: string;
  closeTime: string;;
  vendorDailyHours: HoursObjModel[];

}
