<div style="background-color:#f8f8f8;height:100%">
  <div cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>Halt Services Remarks</h5></div>
  </div>
  <div style="margin-top:0em">
    <form *ngIf="customer_halt_services_edit_form" [formGroup]="customer_halt_services_edit_form" (ngSubmit)="clickSave()">
      <div  style="margin-left: .5em;margin-top:0em;margin-right:0.5em;">
        <div style="display:flex;margin-top:0em;background-color:white; padding-left:0.5em;padding-right:0.5em;">
          <div style="display:flex">
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="remarks " style="font-size:small">Remarks</label>
              <div style="margin-top:-0.5em;">
                <textarea formControlName="remarks" class="form-control" style="width:43em; height:15em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>
              </div>
              <div *ngIf="submitted && f.remarks.errors" class="alert alert-danger p-0 small">
                <div >Remarks must be at least 15 characters.</div>
              </div>
            </div>
            
          </div>

        </div>
          <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; white-space:pre-wrap"><label style="color:red; font-size: small">{{errMsg}}</label></div>

          <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>
       
      </div>
</form>

  </div>

  <div class="d-inline-flex" style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;height:3em">
    <div>
      <button mat-button class="btn-high" (click)="clickSave()">Save</button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()">Cancel</button>&nbsp;
    </div>
    <div class="ml-2" >
      <app-success-message [successMsg]="" *ngIf="showSuccessMsg"></app-success-message>
    </div>
  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>





