<div style="margin-top:0em; padding-top:0em; display:flex">
  <div class="divLeft">
    <div>
     
      <div>
        <form *ngIf="fuelsupplier_form" [formGroup]="fuelsupplier_form">
          <div>
            <div  *ngIf="isDialog"  style="background-color: #f8f8f8; border-radius: 0.25em; padding: 0.1em 0em 0em 0.1em;   display: inline-block; max-height: 5em; " 
                 [ngClass]="{ 'normalCss':!isDialog ,'dialogCss':isDialog  }"  cdkDrag  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle >
               <div  style="  float: left;    display: flex;" ><span style="margin-right: 0.2em;" class="material-symbols-outlined">manage_accounts</span><h5>Fuel Suppliers</h5></div>
              <div style="display: flex;  float: right;">
                <div class="form-group" style=" margin-bottom: 0.2em; margin-right: 3em;">
                   <div style="display: flex;">
                    <mat-slide-toggle style="transform:scale(.8); height:2em;margin-top:-0.5em" color="primary" formControlName="includeDisabledRecords" [checked]="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)"></mat-slide-toggle>
                    <div style="margin-top:-0.25em"><label for="includeDisabledRecords" style="font-size:small;">&nbsp;Show Disabled Records</label></div>
                  </div>
                  <div style=" font-size: small;text-align: end;">{{totalRecordsText}}</div>
                </div>
                <div style="margin-top:0.125em; margin-right:0.5em;">
                  <div style="display: flex;">
                    <div>
                      <button  *ngIf="dialogFrom != 'main'"  type="button" mat-button class="btn-low"  (click)="clickRoute('fuelmenu/fuel-menu')" >

                      <div style="display: flex;margin-top:-0.125em"> <div style="margin-top: 0.125em; margin-left:-0.25em">  <i class="material-icons" style="font-size: medium;cursor: pointer;  ">arrow_back</i>  </div> <div> Return to Fuel Dashboard </div> </div>
                      </button> 
                      <div style=" font-size: small;text-align: end; padding-right: 1em">{{totalRecordsText}}</div>

                    </div>

                    <button mat-icon-button *ngIf="dialogFrom == 'main'" style="margin-top: -0.65em;"  (click)="openThirdPartyVendorComponent()" >  <i class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i> </button>    
                    <button mat-icon-button  (click)="clickOpenInNew()" style="margin-top: -0.65em;">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>  
                    <button  *ngIf="isDialog" mat-icon-button mat-dialog-close style="margin-top: -0.65em;"> <i class="material-icons" style="font-size: x-large;">close </i> </button>
 
                  </div>
                </div>

              </div>
              <div *ngIf="msg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:forestgreen; font-size: small">&nbsp;{{msg}}</label></div>
              <div *ngIf="errMsg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:red; font-size: small">{{errMsg}}</label></div>

            </div>

            <div  *ngIf="!isDialog"  style="background-color: #f8f8f8; border-radius: 0.25em; padding: 0.1em 0em 0em 0.1em;   display: inline-block; max-height: 5em; " 
              [ngClass]="{ 'normalCss':!isDialog ,'dialogCss':isDialog  }"   >
                <div  style="  float: left;    display: flex;" ><span style="margin-right: 0.2em;" class="material-symbols-outlined">manage_accounts</span><h5>Fuel Suppliers</h5></div>
                <div style="display: flex;  float: right;">
                  <div class="form-group" style=" margin-bottom: 0.2em; margin-right: 3em;">
                      <div style="display: flex;">
                      <mat-slide-toggle style="transform:scale(.8); height:2em;margin-top:-0.5em" color="primary" formControlName="includeDisabledRecords" [checked]="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)"></mat-slide-toggle>
                      <div style="margin-top:-0.25em"><label for="includeDisabledRecords" style="font-size:small;">&nbsp;Show Disabled Records</label></div>
                    </div>
                    <!-- <div style=" font-size: small;text-align: end;">{{totalRecordsText}}</div> -->
                  </div>
                  <div style="margin-top:0.125em; margin-right:0.5em;">
                    <div>
                      <button type="button" mat-button class="btn-low"  (click)="clickRoute('fuelmenu/fuel-menu')" >

                      <div style="display: flex;margin-top:-0.125em"> <div style="margin-top: 0.125em; margin-left:-0.25em">  <i class="material-icons" style="font-size: medium;cursor: pointer;  ">arrow_back</i>  </div> <div> Return to Fuel Dashboard </div> </div>
                      </button> 
                      <div style=" font-size: small;text-align: end; padding-right: 1em">{{totalRecordsText}}</div>

                    </div>
                  </div>

                </div>
                <div *ngIf="msg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:forestgreen; font-size: small">&nbsp;{{msg}}</label></div>
                <div *ngIf="errMsg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:red; font-size: small">{{errMsg}}</label></div>

            </div>



          </div>
          <div>
            <div style="height: 31em !important;  overflow-y: auto !important;">

              <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container "  [ngClass]="{ 'normalCss':!isDialog ,'dialogCss':isDialog  }">
                <tr>
                  <ng-container matColumnDef="FuelSupplierShortname">
                    <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Fuel Supplier Code</div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.fuelSupplierShortname}}</td> [<!--matTooltip]="element.isActive == '1' ? 'Active' : 'DIsabled'" [class.setInactive]="!element.isActive"-->
                  </ng-container>
                  <ng-container matColumnDef="FuelSupplierName">
                    <th mat-header-cell *matHeaderCellDef style="width:19%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Fuel Supplier</div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.fuelSupplierName}}</td> <!--[class.setInactive]="!element.isActive"-->
                  </ng-container>
                  <ng-container matColumnDef="profileCount">
                    <th mat-header-cell *matHeaderCellDef style="width:6%; border-bottom:none">
                      <div style="margin-top:-0.25em;"> Profile Count  </div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding-right: 2em !important; text-align: right;"   [ngClass]="{ 'alert-danger':element.profileCount ==0 }"  > {{element.profileCount | number}}</td> <!--[class.setInactive]="!element.isActive"-->
                  </ng-container>
                  <ng-container matColumnDef="ModifiedDate">
                    <th mat-header-cell *matHeaderCellDef style="width:6%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Last Import</div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.modifiedDate  }}</td>
                    <!--<td mat-cell *matCellDef="let element">{{ validDateFormat(element.modifiedDate)  }}</td>-->
                    <!--<td mat-cell *matCellDef="let element">{{element.modifiedDate.toDate | date: 'MM/dd/yy'}}</td>-->
                  </ng-container>
                  <ng-container matColumnDef="InternalNotes">
                    <th mat-header-cell *matHeaderCellDef style="width:26%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Internal Notes </div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.internalNotes}}</td>
                  </ng-container>


                  <!--<ng-container matColumnDef="disabled">
    <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
      <div style="margin-top:-0.25em;">Disabled</div>
    </th>
    <td mat-cell *matCellDef="let element">
      {{element.IsActive? 'Yes': 'No'}}
      <i *ngIf="element.IsActive" class="material-icons" style="color:green; margin-top:0.25em;">done</i>

    </td>

  </ng-container>-->
                  <!--<td mat-cell *matCellDef="let element">{{element.IsActive? 'Yes': 'Yesaa'}}</td>-->


                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef style="width:3.5%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Action</div><div><a mat-button class="btn-high grid" style="align-self:center" (click)="clickEdit($event, element);">Add</a></div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <a mat-button class="btn-low grid" style="align-self:center">
                        <!--(click)="clickEdit($event, element);"-->
                        Edit
                      </a>
                    </td>
                  </ng-container>
                </tr>

                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <!--<tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>-->
                <tr mat-row (click)="clickEdit($event, row)" *matRowDef="let row; columns: displayedColumn"></tr>
              </table>
            </div>
            <div>
              <mat-paginator style="border: none;min-width:80em;" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                             [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
              </mat-paginator>
            </div>
          </div>
        </form>
      </div>
    </div>
   </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
