import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CustomerModel } from '../models/customer.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerService } from '../services/customer.service';
import { CustomerHaltServicesEditDialogComponent } from './customer-halt-services-edit-dialog.component';

@Component({
    selector: 'app-customer-halt-services',
    templateUrl: './customer-halt-services.component.html',
    styleUrls: ['./customer-halt-services.component.css']
})
/** customer-halt-services component*/
export class CustomerHaltServicesComponent {
    /** customer-halt-services ctor */
  customer_halt_services_form: UntypedFormGroup;
  @Input() customerId: string;
  textSearch: string;
  filterBy: string;
  errMsg: string;
  showSpin: boolean;
  loading: boolean;
  msg: string;
  userType: string;
  haltHandling: boolean;
  haltPermits: boolean;
  haltFuel: boolean;
  haltWeather: boolean;
  haltHotel: boolean;
  haltWebsite: boolean;
  haltSSN: boolean;
  haltAll: boolean;
  halt_remarks: string;
  haltUpdatedBy: string;
  haltUpdatedDate: string;
  customerName: string;
  controlValueChanged: boolean = false;


  @ViewChild("haltAllCk") haltAllCk;
  @ViewChild("haltHandlingCk") haltHandlingCk;
  @ViewChild("haltPermitsCk") haltPermitsCk;
  @ViewChild("haltFuelCk") haltFuelCk;
  @ViewChild("haltWeatherCk") haltWeatherCk;
  @ViewChild("haltHotelCk") haltHotelCk;
  @ViewChild("haltWebsiteCk") haltWebsiteCk;
  @ViewChild("haltSSNCk") haltSSNCk;

  constructor(private _router: Router, private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog,
    private readonly _customerService: CustomerService, private readonly _formBuilder: UntypedFormBuilder, private _route: ActivatedRoute) {

  }

  ngOnInit() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    //this._route.queryParams.subscribe(params => {
    //  this.aircraftGuid = params["aircraftGuid"];
    //  this.customerGuid = params["customerGuid"];
    //  this.textSearch = params["textSearch"];
    //  this.filterBy = params["filterBy"];
    //});
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
   

    this.getData();
   //this.initControls()


  }

  getData() {
    this._customerService.getCustomerBycId<ResponseModel<CustomerModel>>(this.customerId).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let customer = new CustomerModel();
          customer = response.model;
          this.haltHandling = customer.haltHandling;
          this.haltFuel = customer.haltFuelSetup;
          this.haltPermits = customer.haltPermits;
          this.haltWeather = customer.haltWeatherServices;
          this.haltHotel = customer.haltHotelSetup;
          this.haltWebsite = customer.haltWebsiteAccess;
          this.haltSSN = customer.haltSSN;
          this.halt_remarks = customer.haltRemarks;
          this.haltUpdatedBy = customer.haltModifiedBy;
          this.haltUpdatedDate = customer.haltDate;
          this.customerName = customer.customerName;
          if (this.haltFuel && this.haltHandling && this.haltHotel && this.haltPermits && this.haltWeather && this.haltWebsite && this.haltSSN)
            this.haltAll = true;
          this.initControls();
          this.checkFormControlValueChanged();
        }
        
      }
    });
    
  }

  checkFormControlValueChanged(): void {
    this.customer_halt_services_form.valueChanges.subscribe(x => {
      this.controlValueChanged = true;
      //this.updateParentPage.emit(true);
    });
  }

  initControls() {
    this.customer_halt_services_form = this._formBuilder.group({
     
      halt_remarks: this.halt_remarks,
      haltUpdatedBy: this.haltUpdatedBy,
      haltUpdatedDate: this.haltUpdatedDate,
    });
  }

  get f() { return this.customer_halt_services_form.controls; }

  checkHaltServices() {
    if (this.haltFuelCk.checked && this.haltHandlingCk.checked && this.haltHotelCk.checked && this.haltPermitsCk.checked && this.haltWeatherCk.checked && this.haltWebsiteCk.checked && this.haltSSNCk.checked)
      this.haltAll = true;
    else {
      this.haltAll = false;
    }
    this.controlValueChanged = true;
  }
  checkAllServices() {
    let checkAll = this.haltAllCk.checked;
    this.haltHandling = checkAll;
    this.haltPermits = checkAll;
    this.haltFuel = checkAll;
    this.haltHotel = checkAll;
    this.haltWeather = checkAll;
    this.haltWebsite = checkAll;
    this.haltSSN = checkAll;
    this.initControls;


  }

  clickSave() {
   
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
   // dialogConfig.width = "42em";//"1000px";
   // dialogConfig.height = "20em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      customerGUID: this.customerId,
      customerName:this.customerName,
      haltHandling: this.haltHandlingCk.checked,
      haltPermits: this.haltPermitsCk.checked,
      haltFuelSetup: this.haltFuelCk.checked,
      haltWeatherServices: this.haltWeatherCk.checked,
      haltHotelSetup: this.haltHotelCk.checked,
      haltWebsite: this.haltWebsiteCk.checked,
      haltSSN:this.haltSSNCk.checked
    };

    const dialogRef = this._dialog.open(CustomerHaltServicesEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();

      }

    });

  }

}
