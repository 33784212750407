export class AircraftModel {
  aircraftGuid: string;
  customerGuid: string;
  customerName: string;
  registration: string;
  callSign: string;
  selected: boolean;
  customerList: string;
  aircraftManufacturerId: number;
  aircraftTypeId: number;
  aircraftTypeDesignator: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: boolean;
  hasPreferences: boolean;
  hasNotes: boolean;
  hasDocuments: boolean;
  hasOwner: boolean;
  hasTrips: boolean;
  mtow: number;
  height: number;
  wingspan: number;
  homebaseAirport: string;
  aircraftTypeDescription: string;
  filterBy: string;
  textSearch: string;
  pageSize: number;
  pageIndex: number;
  totalRecords: number;
  documentCount: number;
  aircraftManufacturer: string;
  aircraftModel: string;
  engineType: string;
  mrw: number;
  mlw: number;
  mzfw: number;
  bow: number;
  crewInBOW: number;
  maxFuel: number;
  speed: number;
  paxSeats: number;
  jumpSeats: number;
  otherSeats: number;
  color: string;
  selcal: string;
  eltBeaconID: string;
  etP_TAS: number;
  etpFlightLevel: number;
  etpOxyDurationMin: number;
  etpOxyInitFlightLevel: number;
  etopS_TAS: number;
  etopsMinutes: number;
  etopsCruise: number;
  pageName: string;
  maxFL: number;
  minDispatchFuel: number;
  minAlternateFuel: number;
  minLandingFuel: number;
  taxiFuel: number;
  taxiFuelBurnType: number;
  domesticReserve: string;
  internationalReserve: string;
  homebaseAirportID: number;
  fileByAircraftRegistration: boolean;
  filingCompanyName: string;
  isImperial: boolean;
  uplinkFrequency: number;
  uplinkVendor: string;
  flightPlanProviderID: number;
  remarks: string;
  climb100: number;
  climb250: number;
  climbTop: number;
  climbBias: number;
  climbFuel: number;
  climbMins: number;
  primaryCruise: string;
  cruiseBias: number;
  altCruise1: string;
  altCruise2: string;
  altCruise3: string;
  altCruise4: string;
  altCruise5: string;
  descentTop: number;
  descent250: number;
  descent100: number;
  descentBias: number;
  descentFuel: number;
  descentMins: number;
  aircraftPhotoName: string;
  aircraftPhotoPath: string;
  actionName: string;
  countryOfRegistration: string;
  reserveFuel: string;
  holdFuel: string;
  reserveFuelDom: string;
  holdFuelDom: string;
  hexCode: string;
  length: number;
  maxRange: number;
  maxMTOW: number;
  maxFuelLimit: number;
}
