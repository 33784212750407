import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ResponseModel } from '../models/response.model';
import { VendorModel } from '../models/vendor.model';
import { AuthenticateService } from '../services/authenticate.service';
import { VendorService } from '../services/vendor.service';
import { HoursObjModel } from '../models/hoursObj.model';
import { VendorHoursModel } from '../models/vendor-hours.model';
import { TemplateService } from '../services/template.service';
import { GroundStopService } from '../services/ground-stop.service';
import { forkJoin, Observable, of } from 'rxjs';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { VendorNotesModel } from '../models/vendor-notes.model';
import { VendorNotesEditDialogComponent } from './vendor-notes-edit-dialog.component';
import { VendorCommEditDialogComponent } from './vendor-comm-edit-dialog.component';
import { VendorDocumentModel } from '../models/vendor-document.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { VendorDocumentEditDialogComponent } from './vendor-document-edit-dialog.component';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.css']
})
/** vendor-details component*/
export class VendorDetailsComponent implements OnInit, OnChanges {
  vendor_details_form: UntypedFormGroup;
  isLoading: boolean = false;
  @Input() vendorID: string = "";
  @Input() icao: string = "";
  vendor_locator_form: UntypedFormGroup;
  searchType: string = "vendor";
  vendorList: VendorModel[];
  vendorDetails: VendorModel;
  relatedVendorDetails: VendorModel;
  vendorHours: VendorHoursModel[];
  errMsg: string = "";
  submitted: boolean = false;
  showClose: boolean = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  loadingWord: boolean = false;
  loadingPDF: boolean = false;
  tooltipSigned: string = "Contract Required \n Contract Signed";
  tooltipNotSigned: string = "Contract Required \n Contract Not Signed";
  ssnVendorID: number;
  hoursData: HoursObjModel[];
  briefType: string = "internal";
  userType: string;
  groundStopGUID: string;
  selectedSSNVendorID: number;
  selectedSSNVendorName: string;

  constructor(private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _groundStopService: GroundStopService,
    private readonly _vendorService: VendorService, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _templateService: TemplateService, private readonly _dialog: MatDialog) {
    if (_data.vendorId != undefined) {
      this.vendorID = _data.vendorId;
      this.icao = _data.icao;
      this.groundStopGUID = _data.gsID;
      this.showClose = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initPage();
  }
  ngOnInit() {
    if (this.showClose) {
      this.initPage();
    }
    //this.initControls();
  }

  initPage() {
    this.isLoading = true;
    this._authService.updateAccessTime();
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        this.briefType = "client";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this.getData();
  }

  initHoursData() {
    this.hoursData = [
      { dayOfWeek: "Sunday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sun_Open", closeTimeObjName: "sun_Close", closedObjName: "sunClosed", hasHours: false },
      { dayOfWeek: "Monday", abbreviation: "M", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "mon_Open", closeTimeObjName: "mon_Close", closedObjName: "monClosed", hasHours: false },
      { dayOfWeek: "Tuesday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "tue_Open", closeTimeObjName: "tue_Close", closedObjName: "tueClosed", hasHours: false },
      { dayOfWeek: "Wednesday", abbreviation: "W", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "wed_Open", closeTimeObjName: "wed_Close", closedObjName: "wedClosed", hasHours: false },
      { dayOfWeek: "Thursday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "thu_Open", closeTimeObjName: "thu_Close", closedObjName: "thuClosed", hasHours: false },
      { dayOfWeek: "Friday", abbreviation: "F", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "fri_Open", closeTimeObjName: "fri_Close", closedObjName: "friClosed", hasHours: false },
      { dayOfWeek: "Saturday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sat_Open", closeTimeObjName: "sat_Close", closedObjName: "satClosed", hasHours: false }
    ]
  }


  initControls() {
    this.vendor_details_form = this._formBuilder.group({
      related_vendors: this.ssnVendorID

    });
  }

  getAllData(): Observable<any> {
    let ssnResponse;
    if (this.groundStopGUID != null && this.groundStopGUID != undefined)
      ssnResponse = this._groundStopService.getSSNVendorIDbygsID(this.groundStopGUID);
    else
      ssnResponse = of(null);
    let vendorResponse = this._vendorService.getVendorDetailsByVendorID(this.vendorID);
    return forkJoin([ssnResponse, vendorResponse]);
  }

  getData() {
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.selectedSSNVendorID = responses[0].model;
        }
        else {
          if (responses[0].code == "401")
            this._authService.signOut();
        }
      }
      else {
        this.selectedSSNVendorID = null;
      }
      if (responses[1] != null) {

        if (responses[1].code == "200" && responses[1].message == "") {
          this.vendorDetails = responses[1].model;
          if (this.vendorDetails != null) {
            this.vendorHours = this.vendorDetails.vendorHours;
            if (this.vendorHours != null) {
              this.vendorHours.forEach(h => {
                this.initHoursData();
                if (!h.is24Hours) {
                  let sameHours = true;
                  let openTime = h.sun_Open;
                  let closeTime = h.sun_Close;
                  let utcLocal = "Local";
                  if (h.isUTC)
                    utcLocal = "UTC";
                  closeTime += ' ' + utcLocal;
                  this.hoursData.forEach(v => {                    
                    let utcLocal = "Local";
                    if (h.isUTC)
                      utcLocal = "UTC";
                    switch (v.dayOfWeek) {
                      case "Sunday":
                        if (h.is24Hours || h.sunClosed == 2)
                          v.is24Hours = true;
                        else
                          v.is24Hours = false;
                        v.openTime = h.sun_Open;
                        v.closeTime = h.sun_Close + ' ' + utcLocal;
                        v.isClosed = h.sunClosed;
                        v.hasHours = h.hasSun;
                        if (v.is24Hours || v.isClosed)
                          v.hasHours = true;
                        break;
                      case "Monday":
                        if (h.is24Hours || h.monClosed == 2)
                          v.is24Hours = true;
                        else
                          v.is24Hours = false;
                        v.openTime = h.mon_Open;
                        v.closeTime = h.mon_Close + ' ' + utcLocal;
                        v.isClosed = h.monClosed;
                        v.hasHours = h.hasMon;
                        if (v.is24Hours || v.isClosed)
                          v.hasHours = true;
                        break;
                      case "Tuesday":
                        if (h.is24Hours || h.tueClosed == 2)
                          v.is24Hours = true;
                        else
                          v.is24Hours = false;
                        v.openTime = h.tue_Open;
                        v.closeTime = h.tue_Close + ' ' + utcLocal;
                        v.isClosed = h.tueClosed;
                        v.hasHours = h.hasTue;
                        if (v.is24Hours || v.isClosed)
                          v.hasHours = true;
                        break;
                      case "Wednesday":
                        if (h.is24Hours || h.wedClosed == 2)
                          v.is24Hours = true;
                        else
                          v.is24Hours = false;
                        v.openTime = h.wed_Open;
                        v.closeTime = h.wed_Close + ' ' + utcLocal;
                        v.isClosed = h.wedClosed;
                        v.hasHours = h.hasWed;
                        if (v.is24Hours || v.isClosed)
                          v.hasHours = true;
                        break;
                      case "Thursday":
                        if (h.is24Hours || h.thuClosed == 2)
                          v.is24Hours = true;
                        else
                          v.is24Hours = false;
                        v.openTime = h.thu_Open;
                        v.closeTime = h.thu_Close + ' ' + utcLocal;
                        v.isClosed = h.thuClosed;
                        v.hasHours = h.hasThu;
                        if (v.is24Hours || v.isClosed)
                          v.hasHours = true;
                        break;
                      case "Friday":
                        if (h.is24Hours || h.friClosed == 2)
                          v.is24Hours = true;
                        else
                          v.is24Hours = false;
                        v.openTime = h.fri_Open;
                        v.closeTime = h.fri_Close + ' ' + utcLocal;
                        v.isClosed = h.friClosed;
                        v.hasHours = h.hasFri;
                        if (v.is24Hours || v.isClosed)
                          v.hasHours = true;
                        break;
                      case "Saturday":
                        if (h.is24Hours || h.satClosed == 2)
                          v.is24Hours = true;
                        else
                          v.is24Hours = false;
                        v.openTime = h.sat_Open;
                        v.closeTime = h.sat_Close + ' ' + utcLocal;
                        v.isClosed = h.satClosed;
                        v.hasHours = h.hasSat;
                        if (v.is24Hours || v.isClosed)
                          v.hasHours = true;
                        break;
                    }
                    if (openTime != v.openTime || closeTime != v.closeTime)
                      sameHours = false;
                  });
                  h.sameHours = sameHours;
                  h.openTime = openTime;
                  h.closeTime = closeTime;
                  h.vendorDailyHours = this.hoursData.filter(x => x.hasHours == true);
                }
              });
            }

            if (this.vendorDetails.relatedVendors != null && this.selectedSSNVendorID != -1) {
              if (this.selectedSSNVendorID != null) {
                this.relatedVendorDetails = this.vendorDetails.relatedVendors.find(x => x.vendorID == this.selectedSSNVendorID);
                this.ssnVendorID = this.relatedVendorDetails.vendorID;
                this.selectedSSNVendorName = this.relatedVendorDetails.vendorName;
              }
              else
              if (this.groundStopGUID == undefined)
              {
                this.relatedVendorDetails = this.vendorDetails.relatedVendors[0];
                this.ssnVendorID = this.relatedVendorDetails.vendorID;
                this.selectedSSNVendorName = this.relatedVendorDetails.vendorName;
              }
            }
            else {
              this.vendorDetails.relatedVendors = null;
              this.ssnVendorID = 0;
            }

          }
        }
        else {
          if (responses[1].code == "401")
            this._authService.signOut();
        }
      }


      this.initControls();
      this.isLoading = false;
    });
  }

  generateHandlingNotes(format: string) {

    switch (format) {
      case "word": this.loadingWord = true;
        break;
      case "pdf": this.loadingPDF = true;
        break;
    }

    this._templateService.generateHandlingBrief(format, this.briefType, this.vendorDetails, this.relatedVendorDetails, this.vendorHours, this.icao).subscribe(r => {
      if (r.remarks != "") {
        this.errMsg = r.remarks;
      }
      switch (format) {
        case "word": this.loadingWord = false;
          break;
        case "pdf": this.loadingPDF = false;
          break;
      }


    });
  }

  updateComms(e: any) {
    if (this.vendor_details_form.get("related_vendors").value == "" || this.vendor_details_form.get("related_vendors").value == "None") {
      this.relatedVendorDetails = null;
    }
    else {
      this.relatedVendorDetails = this.vendorDetails.relatedVendors.filter(v => v.vendorID == this.vendor_details_form.get("related_vendors").value)[0];

    }

  }


  editComms(vendorGUID:string, comm: PersonCommsAddressModel) {
    let s = "";
    let personCommsAddressGUID = "";
    if (comm == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      personCommsAddressGUID = comm.personCommsAddressGUID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, vendorGUID: vendorGUID, personCommsAddressGUID: personCommsAddressGUID };

    const dialogRef = this._dialog.open(VendorCommEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();

      }
    });
  }

  editNotes(vendorGUID: string, note: VendorNotesModel) {
    let s = "";
    let vendorNoteID = 0;
    if (note == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      vendorNoteID = note.vendorNotesID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, vendorGUID: vendorGUID, vendorNoteID: vendorNoteID };
    const dialogRef = this._dialog.open(VendorNotesEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();

      }

    });
  }

  viewDocument(doc: VendorDocumentModel) {
    let vendorDocumentID = doc.vendorDocumentGUID;
    this._vendorService.getVendorDocumentByvdId<ResponseModel<VendorDocumentModel>>(vendorDocumentID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let docObj = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
          let file = new Blob([byteArray], { type: docObj.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);

        }
        else {
          this.errMsg = "No Document returned.";
        }
      }
      else {
        this.errMsg = "No Document returned.";
      }
    })

  }


  editDocument(vendorGUID:string, doc: VendorDocumentModel) {

    let s = "";
    let vendorDocumentGUID = "";
    if (doc == null) {
      s = "Add New Document";
    }
    else {
      s = "Edit Document";
      vendorDocumentGUID = doc.vendorDocumentGUID;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    if (this.userType == "internal") {
      dialogConfig.data = { dialogTitle: s, vendorGUID: vendorGUID, vendorDocumentGUID: vendorDocumentGUID };
    }
    else {
      dialogConfig.data = { dialogTitle: s, vendorDocumentGUID: vendorDocumentGUID };
    }

    const dialogRef = this._dialog.open(VendorDocumentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });

  }

}
