import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { ResponseModel } from '../models/response.model';
import { TripModel } from '../models/trip.model';
import { AuthenticateService } from '../services/authenticate.service';
import { BusinessRulesService } from '../services/business-rules-service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';

export interface TaskList {
  legNumber: number;
  departureICAO: string;
  arrivalICAO: string;
  departureDateTime: string;
  arrivalDateTime: string;
  taskListDep: GroundStopTaskModel[];
  taskListArr: GroundStopTaskModel[];
  isPastLeg: boolean;
}
const datepipe: DatePipe = new DatePipe('en-US');

@Component({
    selector: 'app-add-business-rules-tasks-dialog',
    templateUrl: './add-business-rules-tasks-dialog.component.html',
    styleUrls: ['./add-business-rules-tasks-dialog.component.css']
})
/** add-business-rules-tasks-dialog component*/
export class AddBusinessRulesTasksDialogComponent implements OnInit {

  add_br_tasks_form: UntypedFormGroup;
  @Input() tripCodeGUID: string;
  @Input() groundStopGUID: string;
  @Input() dICAO: string;
  @Input() aICAO: string;
  errMsg: string = "";
  showSuccessMsg: boolean = false;
  submitted: boolean = false;
  taskListDep: GroundStopTaskModel[];
  taskListArr: GroundStopTaskModel[];
  allTaskList: TaskList[] = [];
  @Input() includeActiveStopsOnly: boolean = false;
  @Input() hidePastStop: boolean = true;
  constructor(private readonly _businessRulesService: BusinessRulesService, private readonly _dialogRef: MatDialogRef<AddBusinessRulesTasksDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder,    
    private readonly _groundStopAdvancedService: GroundStopAdvancedService) {

    this.tripCodeGUID = _data.tripCodeGUID;
    this.groundStopGUID = _data.groundStopGUID;
    this.dICAO = _data.dICAO;
    this.aICAO = _data.aICAO;
    this.includeActiveStopsOnly = _data.isActive;
    this.hidePastStop = _data.hidePastStop;

  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.taskListDep = [];
    this.taskListArr = [];
    this.allTaskList = [];
    let request = new TripModel();
    request.tripCodeGUID = this.tripCodeGUID;
    let gsList: GroundStopModel[]=[];
    let g = new GroundStopModel();
    if (this.groundStopGUID != undefined)
      g.groundStopGUID = this.groundStopGUID;
    else
      g.groundStopGUID = "";
    gsList.push(g);
    request.groundStopList = gsList;
    request.includeActiveStopOnly = true; //this.includeActiveStopsOnly;
    this._businessRulesService.getBusinessRulesForGroundStopByTripCodeGUID<ResponseModel<GroundStopTaskModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let taskList = response.model;
          let legNumber = 0;
          taskList.forEach(x => {
            x.disabled = x.selected;            
          });
          taskList.forEach(v => {
            if (legNumber != v.legNumber) {
              legNumber = v.legNumber;
              this.taskListDep = taskList.filter(v => v.serviceLocation == 'D' && !v.avoidLocation && v.legNumber == legNumber);
              this.taskListArr = taskList.filter(v => v.serviceLocation == 'A' && !v.avoidLocation && v.legNumber == legNumber);
              let isPast = false;
              let currentDate = new Date();
              let currentDateNoTime = currentDate.setHours(0, 0, 0, 0);
              if (v.arrivalDateTime != null) {
                if (v.arrivalDateTime.substring(-1, 1) == " ") {
                  if (new Date(v.arrivalDateTime).getTime() < new Date(currentDateNoTime).getTime())
                    isPast = true;                  
                }
                else
                  if (new Date(v.arrivalDateTime).getTime() < currentDate.getTime())
                    isPast = true;
              }
              
              
              let departure="";
              if (v.departureDateTime != null && v.departureDateTime != " ")
                departure = datepipe.transform(new Date(v.departureDateTime), 'MM/dd HH:mm') + ' Z';
              if (v.departureDateTime!=null && v.departureDateTime.substring(-1, 1) == " " && departure != "")
                  departure = departure.replace("00\:00", "TBA");              
              else
                if (departure == "")
                  departure = "TBA";

              let arrival="";
              if (v.arrivalDateTime != null && v.arrivalDateTime != " ")
                arrival = datepipe.transform(new Date(v.arrivalDateTime), 'MM/dd HH:mm') + ' Z';
              if (v.arrivalDateTime != null && v.arrivalDateTime.substring(-1, 1) == " " && arrival !="")
                  arrival = arrival.replace("00\:00", "TBA");
              else
                if (arrival == "")
                  arrival = "TBA";
              this.allTaskList.push({ legNumber: legNumber, departureICAO: v.departureICAO, arrivalICAO: v.arrivalICAO, departureDateTime: departure, arrivalDateTime: arrival, taskListDep: this.taskListDep, taskListArr:this.taskListArr, isPastLeg: isPast});
              }
            
          });
          

        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else {
            this.errMsg = "An error has occurred.";
          }
        }

      }
    });
  }

  setSelectedServices(item: GroundStopTaskModel, isNotRequired:boolean, e:any) {    
    if (isNotRequired)       
      item.notRequired = e.checked;
    else 
      item.selected = !item.selected;
    
    
  }

  clickSave() {
    this.errMsg = "";
    let tasks: TaskList[] = [];
    let request: GroundStopTaskModel[] = [];
    let depTasks: GroundStopTaskModel[] = [];
    let arrTasks: GroundStopTaskModel[] = [];
    if (this.hidePastStop)
      tasks = this.allTaskList.filter(x => x.isPastLeg == false);
    else
      tasks = this.allTaskList;
    
    tasks.forEach(v => {
      v.taskListDep.forEach(y => {
        if (y.notRequired)
          y.taskStatusGUID = "55773F5D-EA25-406D-9E64-BA38BBF816D7";
      });
      v.taskListArr.forEach(y => {
        if (y.notRequired)
          y.taskStatusGUID = "55773F5D-EA25-406D-9E64-BA38BBF816D7";
      });
      let dep = v.taskListDep.filter(x => x.selected == true);
      depTasks= depTasks.concat(dep);
      let arr = v.taskListArr.filter(x => x.selected == true);
      arrTasks = arrTasks.concat(arr);
    });
    request = depTasks.concat(arrTasks);
    if (request.length > 0) {
      this.submitted = true;
      this._businessRulesService.insertTaskFromBusinessRules<ResponseModel<boolean>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.submitted = false;
            this.showSuccessMsg = true;
            this.getData();
            setTimeout(() => {
              this.showSuccessMsg = false;
              this._dialogRef.close(true);
            }, 1000);
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              this.submitted = false;
              this.errMsg = "An error has occurred.";
            }
          }
        }
      });
    }
  }

  hidePastStopChange(e: any) {
    this.hidePastStop = e.checked;
  }

  includeActiveStopOnlyChange(e: any) {
    this.includeActiveStopsOnly = e.checked;
    this.getData();
  }
}
