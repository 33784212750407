<div style="background-color: #f8f8f8;  padding: 0.5em;">
  <!--margin: -1em;-->
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle style="display: flex; justify-content: space-between;"  >
    <div style="padding-left:1em;"><h5 class="mat-h2">Fuel Supplier - {{dialogTitle}}</h5></div>
    <button  mat-icon-button  (click)="clickClose()" > <i class="material-icons" style="font-size: x-large;">close </i> </button>

  </div>

  <div>
    <form *ngIf="fuel_supplier_Profile_edit" [formGroup]="fuel_supplier_Profile_edit" novalidate>
      <div style=" margin-top:0em; padding-left: 1em;padding-right:1em">
        <!--  <div style="margin-top:0em;padding-left:0.5em;padding-right:0.5em;padding-bottom:1em;">-->
        <div style="background-color:white;padding:.5em;">

          <div style="display: flex;">
          <!-- <div class="d-inline-flex">-->
            <table class="table" style="margin-bottom: -0.5em;margin-left: -25em;height: 10em;margin-top: 1em;">
              <tr>
                <td>   <label style="font-size:small;">Fuel Supplier Name</label></td>
                <td style="width: 41%;">
                  <div style="margin-top:-0.35em;">
                    <input style="width: 13em;"  type="text" class="form-control form-control-sm inputBox" required formControlName="fuelSupplierName" [ngClass]="{ 'is-invalid': submitted && f.fuelSupplierName.errors }" />
                    <!--<div class="invalid-tooltip alert-danger p-0" style="font-size:small">
            Fuel Supplier  Name is required.
          </div>-->
                    <div *ngIf="submitted && f.fuelSupplierName.errors" class="alert alert-danger p-0 small">
                      <div *ngIf="f.fuelSupplierName.errors.required">Fuel Supplier Name is required.</div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td> <label style="font-size:small">Fuel Supplier Code</label></td>
                <td>
                  <div style="margin-top:-0.35em;">
                    <input [readonly]="showModified" class="form-control form-control-sm inputBox" [ngClass]="!showModified?'form-control form-control-sm inputBox':'inputreadonly'" required formControlName="fuelSupplierShortname" maxlength="5" style="width:20%" />

                    <div *ngIf="submitted && f.fuelSupplierShortname.errors" class="alert alert-danger p-0 small">
                      <div *ngIf="f.fuelSupplierShortname.errors.required">Fuel Supplier Code is required.</div>
                    </div>
                  </div>  <!--[disabled]="showModified   ? 'disabled' : null"-->
                </td>
              </tr>
              <tr>
                <td>
                  <label style="font-size:small">Website</label>
                </td>
                <td>
                  <div style="margin-top:-0.35em;">
                    <input type="text" class="form-control form-control-sm inputBox" formControlName="fuelSupplierWebsite" />
                    <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Fuel Supplier Website is required.
                    </div>
                  </div>

                </td>
              </tr>
              <tr>
                <td>
                  <label style="font-size:small">Fuel Release Email</label>
                </td>
                <td>
                  <div style="margin-top:-0.35em;">
                    <input type="text" class="form-control form-control-sm inputBox" formControlName="fuelReleaseEmail" />
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Fuel Release Email is required.
                    </span>
                  </div>
                </td>
              </tr>
 
 
              <!-- <tr>
                <td style="vertical-align: top;">
                  <label style="font-size:small">Internal Notes</label>
                </td>
                <td>
                  <div>
                    <textarea formControlName="internalNotes" class="form-control" maxlength=255 style="   min-height: 6em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd"></textarea>
                    <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Internal Notes is required.
                    </div>
                  </div>
                </td>
              </tr> -->

  

            </table>

            <div>
              <div>
                <div style="vertical-align: top;margin-left: 2em; ">
                  <label style="font-size:small">Internal Notes</label>
                </div>
                <div>
                  <div>
                    <textarea formControlName="internalNotes" class="form-control" style=" width: 30em; margin-left: 2em;  min-height: 14em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd"></textarea>
                    <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Internal Notes is required.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>

        <!--</div>-->

        <div style="background-color:white;padding:.5em;margin-top:1em; display: grid;width: 100%; justify-content: space-around;height: 13em;padding-bottom: 2em;">
          <h5 style="text-align: center;"> Import Default Values</h5>
            <div class="no-gutters" style="display: flex;">
              <div class="no-gutters" style="display: flex;">

                <div style="margin-right: 1em; border: 1px solid #ccc;  border-radius: 4px; padding: 6px;">
                  <label style="font-size:small">Set Defualt Expiration Date</label>

                  <div style="display: flex;margin-left: 1.5em;">
                    <div>
                      <label for="domfrequency" style="font-size:small; margin-right: 0.5em;">Domestic US</label>
                    </div>
                    <div>
                      <div>
                        <select formControlName="domfrequency" tabindex="0" class="form-control form-control-sm dropdown">
                          <option *ngFor="let f of domfrequencylist" [value]="f.lk_FuelFrequencyGUID">{{f.lk_FuelFrequencyDescription}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex;margin-left: 1.5em;">
                    <div>
                      <label for="intlfrequency" style="font-size:small; margin-right: 0.5em;">International </label>
                    </div>
                    <div>
                      <div>
                        <select *ngIf="f" formControlName="intlfrequency" class="form-control form-control-sm dropdown">
                          <option *ngFor="let f of intlfrequencylist" [value]="f.lk_FuelFrequencyGUID">{{f.lk_FuelFrequencyDescription}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>



                <div style="border: 1px solid #ccc;  border-radius: 4px; padding: 6px;">
                  <label style="font-size:small">Taxes And Fees Included?</label>

                  <div style="display: flex; margin-left: 2em;">
                    <div>
                      <label for="taxesincludedDom" style="font-size:small; margin-right: 0.5em;">Taxes Included Dom</label>
                    </div>
                    <div>
                      <div >
                        <select formControlName="taxesincludedDom" class="form-control form-control-sm dropdown" style="  margin-left: 0.4em; ">
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div style="display: flex; margin-left: 2em;">
                    <div>
                      <label for="taxesincludedInt" style="font-size:small; margin-right: -0.5em;">Taxes Included Int</label>
                    </div>
                    <div>
                      <div >
                        <select formControlName="taxesincludedInt" class="form-control form-control-sm dropdown" style="  margin-left: 2.4em; width: 3.6em; ">
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div style="margin-left: 1em;margin-top: 0em; border: 1px solid #ccc;  border-radius: 4px; padding: 6px;">
              <div style="display: flex;  margin-top: 1em; ">
            
                <div style="display: flex; margin-right: 3em;">
                  <div style="vertical-align: top; width: 9em;">
                    <label style="font-size:small">Scale needs Adjustment</label>
                  </div>
                  <div>
                    <div>
                      <select formControlName="fuelscaleneedsadjustment" class="form-control form-control-sm dropdown" style="  margin-left: 0.4em ">
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                </div> 

                <!-- <div style="display: flex;">
                  <div>
                    <label for="excludedCountries" style=" margin-right: 1em; font-size:small">Exclude Countries</label>
                  </div>
                  <div>
                    <div >
                      <mat-select placeholder="Select Countries" required panelClass="custom-mat-select-panel2 placeholder1"  formControlName="excludedCountries" multiple
                        style="background-color: #eaf0fd; border: solid lightgray 0.0625em; font-size: small; color: black; border-radius: 0.25em; padding-left: 0.25em; height: 1.65em; width: 9em;">
                        <div> <mat-option *ngFor="let c of countryList" [value]="c.countryID">  {{ c.countryName }}  </mat-option>
                        </div>
                      </mat-select>
                    </div>
                  </div>
                </div> -->
                
              </div>


              <div style="display: flex; margin-right: 1em;">
                <div style="width: 9em; text-align: end;">
                  <label for="unitofmeasure" style="font-size:small; margin-right: 0.5em; margin-top: -0.3em;">Unit of Measure</label>
                </div>
                <div>
                  <div style="margin-left: 0.4em;">
                    <select *ngIf="f" formControlName="unitofmeasure" style="margin-top: 0.3em;" class="form-control form-control-sm dropdown">
                      <option *ngFor="let f of unitofmeasurelist" [value]="f.lk_UnitOfMeasureID"> {{f.unitOfMeasure}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div style="display: flex;">
                <div style="width: 9em;  text-align: end;">
                  <label for="currency" style="font-size:small; margin-right: 0.5em; margin-top: -0.3em;">Currency</label>
                </div>
                <div>
                  <div >
                    <select *ngIf="f" formControlName="currency" style="margin-top: 0.3em; margin-left: 0.4em; width: 6.8em;" class="form-control form-control-sm dropdown">
                      <option *ngFor="let c of currencylist" [value]="c.currencyID">{{c.currencyID}}</option>
                    </select>
                  </div>
                </div>
              </div>


            </div>

            <div style="display: flex;margin-left: 1em;margin-top: 0em; border: 1px solid #ccc;  border-radius: 4px; padding: 17px 6px 6px 6px;">
              <div>
                <label for="excludedCountries" style=" margin-right: 1em; font-size:small; display: flex;"  matTooltip={{countryListValue}} matTooltipClass="tooltipLineBreak" >Exclude Countries <span *ngIf="excludedCountriesLength > 0" > - {{excludedCountriesLength}}:</span>  </label>
              </div>
              <div>
                <div>
                  <mat-select placeholder="None" required panelClass="custom-mat-select-panel2 placeholder1"
                    formControlName="excludedCountries" multiple
                    style="background-color: #eaf0fd; border: solid lightgray 0.0625em; font-size: small; color: black; border-radius: 0.25em; padding-left: 0.25em; height: 1.65em; width: 9em;">
                    <div> <mat-option *ngFor="let c of countryList" [value]="c.countryID"  (click)="filterOptions(c, $event)" > {{ c.countryName }} </mat-option>
                    </div>
                  </mat-select>
                </div>
              </div>
            </div>

          </div>
          <!-- <div style="display: flex;  margin-top: 1em; ">

            <div style="display: flex; margin-right: 3em;">
              <div style="vertical-align: top; width: 9em;">
                <label style="font-size:small">Scale needs Adjustment</label>
              </div>
              <div>
                <div >
                  <select formControlName="fuelscaleneedsadjustment" class="form-control form-control-sm dropdown" style="  margin-left: 0.4em ">
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
            </div>

            <div style="display: flex;">
              <div>
                <label for="excludedCountries" style=" margin-right: 1em; font-size:small">Exclude Countries</label>
              </div>
              <div>
                <div >
                  <mat-select placeholder="Select Countries" required panelClass="custom-mat-select-panel2 placeholder1"  formControlName="excludedCountries" multiple
                    style="background-color: #eaf0fd; border: solid lightgray 0.0625em; font-size: small; color: black; border-radius: 0.25em; padding-left: 0.25em; height: 1.65em; width: 7em;">
                    <div> <mat-option *ngFor="let c of countryList" [value]="c.countryID">  {{ c.countryName }}  </mat-option>
                    </div>
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
          <div style=" width: 100%; ">

            <div style="display: flex; margin-right: 1em;">
              <div style="width: 9em; text-align: end;">
                <label for="unitofmeasure" style="font-size:small; margin-right: 0.5em; margin-top: -0.3em;">Unit of Measure</label>
              </div>
              <div>
                <div style="margin-left: 0.4em;">
                  <select *ngIf="f" formControlName="unitofmeasure" style="margin-top: 0.3em;" class="form-control form-control-sm dropdown">
                    <option *ngFor="let f of unitofmeasurelist" [value]="f.lk_UnitOfMeasureID"> {{f.unitOfMeasure}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div style="display: flex;">
              <div style="width: 9em;  text-align: end;">
                <label for="currency" style="font-size:small; margin-right: 0.5em; margin-top: -0.3em;">Currency</label>
              </div>
              <div>
                <div >
                  <select *ngIf="f" formControlName="currency" style="margin-top: 0.3em; margin-left: 0.4em; width: 6.8em;" class="form-control form-control-sm dropdown">
                    <option *ngFor="let c of currencylist" [value]="c.currencyID">{{c.currencyID}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div> -->


        </div>
        <div style="display: flex; justify-content: end;">
          <div style="  padding: .5em; margin-top: 1em; display: inline-block;   text-align: center; ">
            <div class="d-inline-flex">
              <div class="no-gutters">
                <div class="col">
                  <label style="font-size:small">Disable Record</label>
                  <div style="margin-top: -0.35em; display: inline-block;">
                    <select formControlName="isActive" class="form-control form-control-sm dropdown" style="  margin-left: 0.4em ">
                      <option value="true">No</option>
                      <option value="false">Yes</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div style="display: inline-block; padding-left: 2em;">
            <div style="border-radius:.25em" class="d-inline-flex ml-2 p-1;">
              <!--<div style="background-color:#f8f8f8;border-radius:.25em" class="d-inline-flex ml-2 p-1">-->

              <table *ngIf="showModified" class="table" style="margin-bottom: -0.6em;">
                <tr>
                  <td> <label for="modifiedBy" style="font-size:small">Modified By : </label></td>
                  <td>
                    <div style="margin-top:-0.5em">
                      <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width: 8em; height: 1.5625em; font-size: small; padding: 0em; border: none; background-color: transparent;" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><label for="modifiedDate" style="font-size:small">Modified Date : </label></td>
                  <td>
                    <div style="margin-top:-0.5em">
                      <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width: 8em; height: 1.5625em; font-size: small; padding: 0em; border: none; background-color: transparent;" />
                    </div>
                  </td>
                </tr>
              </table>
              <div style=" margin-right: 0.5em">


              </div>
              <div style=" margin-right: 0.5em">


              </div>
            </div>
          </div>
        </div>

      </div>

      <!--</div>-->
    </form>

  </div>
  <div class="d-inline-flex">
    <div style="justify-content: flex-start; padding-left: 1em; padding-top: 0.5em; padding-bottom: 0.5em; min-width: 33%;">
      <!--<button   mat-button class="btn-high" (click)="clickSave()">SAVE</button>-->&nbsp;
      <button mat-button [disabled]="!controlValueChanged   ? 'disabled' : null" class="btn-high" style="margin-left: -0.7em;" (click)="clickSave()">SAVE</button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()">CLOSE</button>&nbsp;
      <button mat-button class="btn-high" (click)="openLocationPreferences()">Location Profile - {{profileCount | number}} </button>&nbsp;

    </div>
    <div class="mt-2">
      <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display: inline-block; white-space: pre-wrap;">
        <label style="font-size: small; padding: 0em; margin: 0em;">{{errMsg}}</label>
      </div>
      <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]="successMsg"></app-success-message>
        <div *ngIf="errMsg!=''"  class="alert d-flex align-items-center" role="alert" style=" background-color:#f9d2de; min-width:25em; padding-left:0.25em; padding-right:0.25em; font-size:small;height:2.5em">
           <div>{{errMsg}}</div>
        </div>
      </div>
      <!--<app-success-message *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>-->
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>





