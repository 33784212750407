<div>


  <div style="display: flex; justify-content: space-between;   margin-top:-0.5em;   " cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div>
      <h5>Hotels </h5>
    </div>


    <button mat-icon-button (click)="clickClose(false)"> <i class="material-icons" style="font-size: x-large;">close
      </i>
    </button>
  </div>

  <div style="padding: 0.5em;padding-bottom: 0em; background-color:white ; ">

    <div>
      <form *ngIf="advisory_hotels_form" [formGroup]="advisory_hotels_form">




        <div *ngIf="hotelList">
          <div style="margin-bottom: 1em; padding-bottom: 1em; height: 33em; overflow: auto;">
            <table mat-table *ngIf="hotelList" [dataSource]="hotelList" class="example-container "
              style="width: 50em; margin: 1em;">
              <tr>


                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef style="width:3%">
                    <div>Select</div>
                    <div style="margin-top:0em;padding-bottom:0.25em"></div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="width: 3%;">


                    <mat-radio-button [checked]="element.hotelGUID === selectedhotelGUID"
                      (change)="changeHotel($event, element)"></mat-radio-button>

                  </td>
                </ng-container>


                <ng-container matColumnDef="hotel">
                  <th mat-header-cell *matHeaderCellDef style="width:15%; text-align:left !important">
                    <div style="margin-top:-0.25em;">Hotel</div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 15%;">
                    <div style="display: flex;">
                      <div>{{element.hotelName}}</div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="distance">
                  <th mat-header-cell *matHeaderCellDef style="width:3%; text-align:left !important">
                    <div style="margin-top:-0.25em;"> Distance From Airport</div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 3%;">
                    <div style="display: flex;">
                      <div>{{element.distanceFromAirport.toFixed(4)}}</div>

                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef style="width:15%; text-align:left !important">
                    <div style="margin-top:-0.25em;"> Address</div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 15%;">
                    <div style="display: flex;">
                      <div>{{element.totalAddress}}</div>

                    </div>
                  </td>
                </ng-container>

              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>
          </div>
        </div>




        <!-- <div *ngIf="errMsg!=''" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;"><label style="color:black; font-size: small">{{errMsg}}</label></div> -->
        <!-- <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
        <app-success-message [successMsg]=""></app-success-message>
      </div> -->

      </form>
    </div>
  </div>



  <div style="display: flex;margin-top: 0.5em; ">
    <button class="btn-high" mat-button style="margin-left: 1em;" (click)="clickUpdateHotels()">Update</button>
    <button class="btn-high" mat-button style="margin-left: 1em;" (click)="clickResetHotels()">Reset</button>
    <button class="btn-low" mat-button (click)="clickClose(false)"
      style="background-color:grey; color: white; margin-left: 1em;">Close</button>
  </div>

</div>