<div style="margin-top:0em; padding-top:0em; display:flex">
  <form [formGroup]="customer_homebase_form">
    <div style="margin-top:-1em">
      <table mat-table [dataSource]="recordList" class="example-container">
        <tr>
          <ng-container matColumnDef="icao">
            <th mat-header-cell *matHeaderCellDef>
              ICAO
            </th>
            <td mat-cell *matCellDef="let element" >{{element.icao}}</td>
          </ng-container>
          <ng-container matColumnDef="iata">
            <th mat-header-cell *matHeaderCellDef>
              IATA
            </th>
            <td mat-cell *matCellDef="let element" >{{element.iata}}</td>
          </ng-container>
          <ng-container matColumnDef="airport_name">
            <th mat-header-cell *matHeaderCellDef>
              Name
            </th>
            <td mat-cell *matCellDef="let element" >{{element.airportName}}</td>
          </ng-container>
          <ng-container matColumnDef="airport_location">
            <th mat-header-cell *matHeaderCellDef>
              Location
            </th>
            <td mat-cell *matCellDef="let element">{{element.airportLocation}}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              Action <br /><a mat-button class="btn-high grid" style="width:7em" (click)="clickEditHomebase($event,null)">Add</a>
            </th>
            <td mat-cell *matCellDef="let element; let i=index " width="10%">
              <a mat-button class="btn-low grid" style="width:7em" (click)="clickRemoveHomebase($event, element);">
                Remove
              </a>
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
    </div>
 
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
