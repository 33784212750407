<div *ngIf="upPII" style="padding-bottom: 0.5em">
  <div style="margin-top:-1em"><hr style="width: 78em;border: 0.0625em solid darkgray;" /></div>
  <div style="display: flex; font-size: small; margin-top:-1em">
    <div style="width: 39.5em; text-align:left; background-color: transparent; margin-right:0.5em">
      <!--<div style="display: flex;font-weight: bold">
        <div style="width: 32.5em;">
        </div>
        <div style="width:2em"></div>
        <div style="width:5em">Messages</div>
      </div>-->
      <div style="display: flex; font-weight: bold">
        <div style="width: 30.5em">
          DEPARTURE SERVICES
        </div>
        <!--<div style="width:2em">BR</div>-->
        <!--<div style="width:2.5em">In</div>
        <div style="width:2.5em">Out</div>-->
        <div style="width:9em; text-align: center">Status</div>
      </div>
      <div *ngFor="let p of depTaskList">
        <div style="display: flex; width:39.5em" class="div-rowHover">
          <div style="width:32.5em; display:flex">
            <div (click)="clickServiceType($event,p, 'D')">
              <div style="display: flex;margin-top:0em; ">
                <!--<div style="border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right:0.125em; margin-top:0.35em" [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>-->
                <div style="display: flex;height:1.5em;margin-right:0.25em" class="divHover">
                  <div *ngIf="!p.strikeOutService" style="margin-right:0.0625em"><a title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription}}</a></div>
                  <div *ngIf="p.strikeOutService" style="margin-right:0.0625em"><s style="color:red"><a title="{{p.serviceTypeDescription}}"><span class="textHover">{{p.serviceTypeDescription}}</span></a></s></div>
                  <!--<div *ngIf="p.checklistIconColor!='green'" style="margin-top:0.25em">
          <span class="material-icons-two-tone {{p.checklistIconColor}}" style="font-size: medium">
            {{p.checklistIconName}}
          </span>
        </div>-->
                </div>
                <div style="display: flex;">
                  <div *ngIf="p.vendorName!='' && p.showVendorToClient==true" style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold" title="{{p.vendorName}}">{{p.countryName!='' || p.fpiConfirmationReference!=''?p.vendorName.substr(0,15):p.vendorName.substr(0,48)}}</div>
                  <div *ngIf="p.countryName!=''" style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold" title="{{p.countryName}}">{{p.countryName.substr(0,10)}}</div>
                  <div *ngIf="p.hotelName!=''" style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold" title="{{p.hotelName}}">{{p.hotelName.substr(0,10)}}</div>
                  <div *ngIf="p.fpiConfirmationReference!=''" style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold" title="{{p.fpiConfirmationReference}}">{{p.fpiConfirmationReference.substr(0,10)}}</div>
                  <div *ngIf="p.serviceDate!='' || p.serviceTime!=''" style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold">{{p.serviceDate | date:'dd-MMM'}}  {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</div>
                  <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i class="material-icons" style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                  <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{p.publicNote}}"><i class="material-icons" style="font-size: small;">notes</i></div>
                  <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                </div>
              </div>
              <div *ngFor="let t of p.tripLegList" style="margin-left:1.25em">
                <div style="margin-top: -0.25em;">{{t.departureICAO+ ' - '+t.nextArrivalICAO}}</div>
              </div>
              <div *ngFor="let f of p.flightFollowingList" style="margin-left:1.25em">
                <div style="margin-top: -0.25em; color: darkblue" title="{{f.subServiceTypeDescription}}">{{f.subServiceTypeDescription.substr(0,19)}}</div>
              </div>
            </div>
            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" (click)="openMessages($event,p, 'D')" style="margin-top:0.25em"><i class="material-icons" style="font-size: medium;">mail_outline</i></div>

          </div>
          <!--<div style="width:2em; cursor: pointer" (click)="openBusinessRule(p.businessRulesID)" matTooltip="{{p.businessRulesID>0?p.businessRulesID:''}}">
    <i class="material-icons-outlined" style="font-size:medium; color: darkgreen; font-weight:bold" *ngIf="p.businessRulesID>0">done</i>
  </div>-->
          <!--<div style="width:2.5em" (click)="clickServiceType($event,p, 'D')"></div>
  <div style="width:2.5em" (click)="clickServiceType($event,p, 'D')"></div>-->
        <div style="border-radius: 0.5em; width: 9em; height: 1.25em; border: gray solid 0.0625em;" [style.background-color]="p.taskStatusFontColor" (click)="clickServiceType($event,p, 'D')">
          <div style="margin-top:0em; text-align:center;font-size: x-small" [ngStyle]="{'color': p.taskStatusGUID=='55773f5d-ea25-406d-9e64-ba38bbf816d7'?'white':'black'}">{{p.taskStatusDescription}}</div>
        </div>
        </div>
      </div>
      
    </div>
    <div style="border-left: 0.0625em solid darkgray; margin-right: 1em; margin-bottom: 1em"></div>
    <div style="width: 39.5em; text-align: left; background-color: transparent">
      <!--<div style="display: flex;font-weight: bold">
        <div style="width: 32.5em;">
        </div>
        <div style="width:2em"></div>
        <div style="width:5em">Messages</div>
      </div>-->
      <div style="display: flex; font-weight: bold">
        <div style="width: 30.5em">
          ARRIVAL SERVICES
        </div>
        <!--<div style="width:2em">BR</div>-->
        <!--<div style="width:2.5em">In</div>
  <div style="width:2.5em">Out</div>-->
        <div style="width:9em; text-align: center">Status</div>
      </div>
      <div *ngFor="let p of arrTaskList">
        <div style="display: flex; width:39.5em" class="div-rowHover">
          <div style="width:32.5em; display:flex">
            <div (click)="clickServiceType($event,p, 'A')">
              <div style="display: flex;margin-top:0em; ">
                <!--<div style="border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right:0.125em; margin-top:0.35em" [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>-->
                <div style="display: flex;height:1.5em;margin-right:0.25em" class="divHover">
                  <div *ngIf="!p.strikeOutService" style="margin-right:0.0625em"><a title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription}}</a></div>
                  <div *ngIf="p.strikeOutService" style="margin-right:0.0625em"><s style="color:red"><a title="{{p.serviceTypeDescription}}"><span class="textHover">{{p.serviceTypeDescription}}</span></a></s></div>
                  <!--<div *ngIf="p.checklistIconColor!='green'" style="margin-top:0.25em">
          <span class="material-icons-two-tone {{p.checklistIconColor}}" style="font-size: medium">
            {{p.checklistIconName}}
          </span>
        </div>-->
                </div>
                <div style="display: flex;">
                  <div *ngIf="p.vendorName!='' && p.showVendorToClient==true" style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold" title="{{p.vendorName}}">{{p.countryName!='' || p.fpiConfirmationReference!=''?p.vendorName.substr(0,15):p.vendorName.substr(0,48)}}</div>
                  <div *ngIf="p.countryName!=''" style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold" title="{{p.countryName}}">{{p.countryName.substr(0,10)}}</div>
                  <div *ngIf="p.hotelName!=''" style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold" title="{{p.hotelName}}">{{p.hotelName.substr(0,10)}}</div>
                  <div *ngIf="p.fpiConfirmationReference!=''" style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold" title="{{p.fpiConfirmationReference}}">{{p.fpiConfirmationReference.substr(0,10)}}</div>
                  <div *ngIf="p.serviceDate!='' || p.serviceTime!=''" style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold">{{p.serviceDate | date:'dd-MMM'}}  {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</div>
                  <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i class="material-icons" style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                  <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{p.publicNote}}"><i class="material-icons" style="font-size: small;">notes</i></div>
                  <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i class="material-icons-outlined" style="font-size: medium;">unpublished</i></div>
                </div>
              </div>
              <div *ngFor="let t of p.tripLegList" style="margin-left:1.25em">
                <div style="margin-top: -0.25em;">{{t.departureICAO+ ' - '+t.nextArrivalICAO}}</div>
              </div>
              <div *ngFor="let f of p.flightFollowingList" style="margin-left:1.25em">
                <div style="margin-top: -0.25em; color: darkblue" title="{{f.subServiceTypeDescription}}">{{f.subServiceTypeDescription.substr(0,19)}}</div>
              </div>
            </div>
            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" (click)="openMessages($event,p, 'A')" style="margin-top:0.25em"><i class="material-icons" style="font-size: medium;">mail_outline</i></div>

          </div>
          <!--<div style="width:2em; cursor: pointer" (click)="openBusinessRule(p.businessRulesID)" matTooltip="{{p.businessRulesID>0?p.businessRulesID:''}}">
    <i class="material-icons-outlined" style="font-size:medium; color: darkgreen; font-weight:bold" *ngIf="p.businessRulesID>0">done</i>
  </div>-->
          <!--<div style="width:2.5em" (click)="clickServiceType($event,p, 'A')"></div>
  <div style="width:2.5em" (click)="clickServiceType($event,p, 'A')"></div>-->
          <div style="border-radius: 0.5em; width: 9em; height: 1.25em; border: gray solid 0.0625em;" [style.background-color]="p.taskStatusFontColor" (click)="clickServiceType($event,p, 'A')">
            <div style="margin-top:0em; text-align:center; font-size: x-small" [ngStyle]="{'color': p.taskStatusGUID=='55773f5d-ea25-406d-9e64-ba38bbf816d7'?'white':'black'}">{{p.taskStatusDescription}}</div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showSpin" style="margin-top: -3em; margin-left: -1em; position:relative; z-index:100">
    <span class="spinner-border spinner-border-sm mr-1"></span>
  </div>
</div>
