import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ApisManifest } from '../models/apis-manifest';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { MatAccordion } from '@angular/material/expansion';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { HttpClient, HttpHeaders, HttpParams, HttpXhrBackend } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { AirportModel } from '../models/airport.model';
import { AirportService } from '../services/airport.service';
import { DialogService } from '../services/dialog.service';
import { ReturnObjModel } from '../models/return-obj.model';
import { UploadTaskDocument } from '../ground-stop-advanced/upload-task-document';
import { ApisFlightManifestModel } from '../models/apis-flight-manifest';
import { ApisSubmissionModel } from '../models/apis-submission';
import { UtilityFunctions } from '../common-utility/utility.functions';

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

const datepipe: DatePipe = new DatePipe('en-US')


@Component({
  selector: 'app-generate-apis-caricom',
  templateUrl: './generate-apis-caricom.component.html',
  styleUrls: ['./generate-apis-caricom.component.css']
})


/** generate-apis component*/
export class GenerateApisCaricomComponent implements OnInit {
  @Input() groundStopGUID: string;
  @Input() tripCodeGUID: string;
  @Input() groundStopSequenceId: number
  @Input() groundStopTaskId: string;
  generate_apis_caricom_form: UntypedFormGroup;
  apisManifest: ApisManifest;
  isInbound: boolean = false;
  isOutbound: boolean = true;
  InboundOutbound: string = "Outbound";
  lastNext: string = "Next";

  isDepApisApproved: boolean;
  isArrApisApproved: boolean;
  public loadingReport: boolean = false;
  public loadingXml: boolean = false;
  public submittingXml: boolean = false;
  checkNoDocuments: boolean = false;
  airportList: AirportModel[];
  errMsg: string = "";
  showSpin: boolean = true;
  hasExpiredDocument: boolean = false;
  hasNoDocument: boolean = false;
  noDocumentList: string = "";
  expiredDocList: string = "";
  showSuccessMessage: boolean = false;
  isUpdated: boolean = false;
  transmissionType: string;
  documentCode: string = "";
  flightNumber: string = "";
  isInOneHour: boolean = false;
  isCrewChange: boolean;
  isPaxChange: boolean;
  isPaxAdd: boolean;
  showAll: string = "1";
  disable: boolean = false;
  constructor(private readonly _groundStopAdvanceService: GroundStopAdvancedService, private readonly _authService: AuthenticateService, private readonly _dialogRef: MatDialogRef<GenerateApisCaricomComponent>,
    private readonly _formBuilder: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialogService: DialogService, private el: ElementRef, private readonly _airportService: AirportService, private http: HttpClient) {
    this.groundStopGUID = _data.groundStopGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.groundStopSequenceId = _data.groundStopSequenceId;
    this.groundStopTaskId = _data.groundStopTaskGUID;

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.transmissionType = "C";
    this.getData();
  }

  initControls() {
    this.generate_apis_caricom_form = this._formBuilder.group({
      emergencyFirst: [this.apisManifest.emergencyContactFirst, Validators.required],
      emergencyLast: [this.apisManifest.emergencyContactLast, Validators.required],
      emergencyPhone: [this.apisManifest.emergencyContactPhone, Validators.required],
      emergencyEmail: this.apisManifest.emergencyContactEmail,
      departureAirport: this.apisManifest.departureICAO,
      departureCity: [this.apisManifest.departureCity, Validators.required],
      departureState: this.apisManifest.departureState,
      //depPlaceDescription: this.apisManifest.departurePlaceDescription,
      departureCountry: this.apisManifest.departureCountryCode,
      localDepDate: [this.apisManifest.departureDateLocal, Validators.required],
      localDepTime: [this.apisManifest.departureTimeLocal, Validators.required],
      //borderCrossing: '',
      //foreignAirport1: this.apisManifest.foreignAirports[0].icao,
      //foreignAirport2: this.apisManifest.foreignAirports[1].icao,
      //foreignAirport3: this.apisManifest.foreignAirports[2].icao,
      //foreignAirport4: this.apisManifest.foreignAirports[3].icao,
      //foreignAirport5: this.apisManifest.foreignAirports[4].icao,
      arrivalAirport: this.apisManifest.arrivalICAO,
      arrivalCity: [this.apisManifest.arrivalCity, Validators.required],
      arrivalState: this.apisManifest.arrivalState,
      //arrPlaceDescription: '',
      arrivalCountry: this.apisManifest.arrivalCountryCode,
      localArrDate: [this.apisManifest.arrivalDateLocal, Validators.required],
      localArrTime: [this.apisManifest.arrivalTimeLocal, Validators.required],
      tailNumber: [this.apisManifest.registration, Validators.required],
      aircraftType: [this.apisManifest.aircraftType, Validators.required],
      //color: [this.apisManifest.aircraftColor, Validators.required],
      //callSign: this.apisManifest.callSign,
      //cbpDecal: this.apisManifest.cbpDecal,
      //operatorCompany: this.apisManifest.operatorCompany,
      //operatorFirst: this.apisManifest.operatorFirstName,
      //operatorMiddle: this.apisManifest.operatorMiddleName,
      //operatorLast: this.apisManifest.operatorLastName,
      //operatorStreet1: [this.apisManifest.operatorAddress1, Validators.required],
      //operatorStreet2: this.apisManifest.operatorAddress2,
      //operatorCity: [this.apisManifest.operatorCity, Validators.required],
      //operatorState: this.apisManifest.operatorStateProvince,
      //operatorZip: this.apisManifest.operatorZip,
      //operatorCountry: [this.apisManifest.operatorCountryCode, Validators.required],
      //operatorPhone: [this.apisManifest.operatorPhone, Validators.required],
      //operatorFax: this.apisManifest.operatorFax,
      //operatorEmail: [this.apisManifest.operatorEmail, Validators.required],
      //ownerCompany: this.apisManifest.ownerCompany,
      //ownerFirst: this.apisManifest.ownerFirstName,
      //ownerMiddle: this.apisManifest.ownerMiddleName,
      //ownerLast: this.apisManifest.ownerLastName,
      //ownerStreet1: [this.apisManifest.ownerAddress1, Validators.required],
      //ownerStreet2: this.apisManifest.ownerAddress2,
      //ownerCity: [this.apisManifest.ownerCity, Validators.required],
      //ownerState: this.apisManifest.ownerStateProvince,
      //ownerZip: this.apisManifest.ownerZip,
      //ownerCountry: [this.apisManifest.ownerCountryCode, Validators.required],
      //ownerPhone: [this.apisManifest.ownerPhone, Validators.required],
      //ownerFax: this.apisManifest.ownerFax,
      //ownerEmail: [this.apisManifest.ownerEmail, Validators.required],
      flightNumber: [this.flightNumber, Validators.required]
      //carrierCode: [this.apisManifest.carrierCode, Validators.required]

    });

    if (this.apisManifest.crewList != null) {
      for (let i = 0; i < this.apisManifest.crewList.length; i++) {
        this.generate_apis_caricom_form.addControl("crewFirstName" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].firstName, Validators.required));
        this.generate_apis_caricom_form.addControl("crewMiddleName" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].middleName));
        this.generate_apis_caricom_form.addControl("crewLastName" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].lastName, Validators.required));
        this.generate_apis_caricom_form.addControl("crewBirthdate" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].dob, Validators.required));
        this.generate_apis_caricom_form.addControl("crewSex" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].sex, Validators.required));
        this.generate_apis_caricom_form.addControl("crewResidence" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].residenceCountry));
        if (this.isInbound) {
          this.generate_apis_caricom_form.get("crewResidence" + i.toString()).setValidators(Validators.required);
          this.generate_apis_caricom_form.get("crewResidence" + i.toString()).updateValueAndValidity();
        }
        this.generate_apis_caricom_form.addControl("crewCitizenship" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].citizenshipCountry, Validators.required));
        this.generate_apis_caricom_form.addControl("crewBirthCountry" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].countryOfBirth));
        this.generate_apis_caricom_form.addControl("crewBirthState" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].stateProvinceOfBirth));
        this.generate_apis_caricom_form.addControl("crewBirthCity" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].cityOfBirth));
        this.generate_apis_caricom_form.addControl("crewPermAddressStreet" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].permanentAddress.streetAddress));
        this.generate_apis_caricom_form.addControl("crewPermAddressCity" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].permanentAddress.city));
        this.generate_apis_caricom_form.addControl("crewPermAddressState" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].permanentAddress.stateProvince));
        this.generate_apis_caricom_form.addControl("crewPermAddressPostal" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].permanentAddress.postalCode));
        this.generate_apis_caricom_form.addControl("crewPermAddressCountry" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].permanentAddress.countryCode));
        //this.generate_apis_caricom_form.addControl("crewUSAddressStreet" + i.toString(), new FormControl(this.apisManifest.crewList[i].addressInUsa.streetAddress));
        //this.generate_apis_caricom_form.addControl("crewUSAddressCity" + i.toString(), new FormControl(this.apisManifest.crewList[i].addressInUsa.city));
        //this.generate_apis_caricom_form.addControl("crewUSAddressState" + i.toString(), new FormControl(this.apisManifest.crewList[i].addressInUsa.stateProvince));
        //this.generate_apis_caricom_form.addControl("crewUSAddressPostal" + i.toString(), new FormControl(this.apisManifest.crewList[i].addressInUsa.postalCode));
        this.generate_apis_caricom_form.addControl("crewTravelerType" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].travelerType + '-' + this.apisManifest.crewList[i].travelerTypeDescription));

        this.generate_apis_caricom_form.addControl("crewContactPhone1" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].contactPhone1));
        this.generate_apis_caricom_form.addControl("crewContactPhone2" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].contactPhone2));
        this.generate_apis_caricom_form.addControl("crewContactEmail" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].contactEmail));

        for (let j = 0; j < this.apisManifest.crewList[i].apisTravelDocuments.length; j++) {
          this.generate_apis_caricom_form.addControl("crew" + i.toString() + 'docType' + j.toString(), new UntypedFormControl(this.apisManifest.crewList[i].apisTravelDocuments[j].documentType || "No Document Provided"));
          this.generate_apis_caricom_form.addControl("crew" + i.toString() + 'docCode' + j.toString(), new UntypedFormControl(this.apisManifest.crewList[i].apisTravelDocuments[j].documentCode || "No Document Provided"));
          this.generate_apis_caricom_form.addControl("crew" + i.toString() + 'docNumber' + j.toString(), new UntypedFormControl(this.apisManifest.crewList[i].apisTravelDocuments[j].documentNumber || "No Document Provided"));
          this.generate_apis_caricom_form.addControl("crew" + i.toString() + 'docExpiry' + j.toString(), new UntypedFormControl(datepipe.transform(this.apisManifest.crewList[i].apisTravelDocuments[j].documentExpiry, 'MMM-dd-yyyy')));
          if (this.apisManifest.crewList[i].apisTravelDocuments[j].documentNumber == '' || this.apisManifest.crewList[i].apisTravelDocuments[j].documentNumber == null)
            this.generate_apis_caricom_form.get("crew" + i.toString() + 'docExpiry' + j.toString()).setValue("No Document Provided");
          if (this.generate_apis_caricom_form.get("crew" + i.toString() + 'docCode' + j.toString()).value == 'L')
            this.generate_apis_caricom_form.addControl("crew" + i.toString() + 'docCountry' + j.toString(), new UntypedFormControl(this.apisManifest.crewList[i].apisTravelDocuments[j].documentCountryCode || "MISSING"));
          else
            this.generate_apis_caricom_form.addControl("crew" + i.toString() + 'docCountry' + j.toString(), new UntypedFormControl(this.apisManifest.crewList[i].apisTravelDocuments[j].documentCountryCode || "No Document Provided"));
          if ((new Date(this.apisManifest.crewList[i].apisTravelDocuments[j].documentExpiry).getTime() <= new Date(this.f.localDepDate.value).getTime()) && this.apisManifest.crewList[i].apisTravelDocuments[j].documentExpiry != '') {
            this.hasExpiredDocument = true;
          }
          if (this.apisManifest.crewList[i].apisTravelDocuments[j].documentNumber == "") {
            this.hasNoDocument = true;
            if (this.noDocumentList != "")
              this.noDocumentList += "\n";
            this.noDocumentList += this.apisManifest.crewList[i].travelerType + " - " + this.apisManifest.crewList[i].lastName + ", " + this.apisManifest.crewList[i].firstName + " " + this.apisManifest.crewList[i].middleName;

          }
        }
      }
    }

    if (this.apisManifest.paxList != null) {
      for (let i = 0; i < this.apisManifest.paxList.length; i++) {
        this.generate_apis_caricom_form.addControl("paxFirstName" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].firstName, Validators.required));
        this.generate_apis_caricom_form.addControl("paxMiddleName" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].middleName));
        this.generate_apis_caricom_form.addControl("paxLastName" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].lastName, Validators.required));
        this.generate_apis_caricom_form.addControl("paxBirthdate" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].dob, Validators.required));
        this.generate_apis_caricom_form.addControl("paxSex" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].sex, Validators.required));
        this.generate_apis_caricom_form.addControl("paxResidence" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].residenceCountry));
        //if (this.isInbound) {
        //  this.generate_apis_caricom_form.get("paxResidence" + i.toString()).setValidators(Validators.required);
        //  this.generate_apis_caricom_form.get("paxResidence" + i.toString()).updateValueAndValidity();
        //}
        this.generate_apis_caricom_form.addControl("paxCitizenship" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].citizenshipCountry/*, Validators.required*/));
        if (this.isInbound) {
          this.generate_apis_caricom_form.addControl("paxUSAddressStreet" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].addressInUsa.streetAddress));
          this.generate_apis_caricom_form.addControl("paxUSAddressCity" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].addressInUsa.city));
          this.generate_apis_caricom_form.addControl("paxUSAddressState" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].addressInUsa.stateProvince));
          this.generate_apis_caricom_form.addControl("paxUSAddressPostal" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].addressInUsa.postalCode));
        }
        this.generate_apis_caricom_form.addControl("paxContactPhone1" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].contactPhone1));
        this.generate_apis_caricom_form.addControl("paxContactPhone2" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].contactPhone2));
        this.generate_apis_caricom_form.addControl("paxContactEmail" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].contactEmail));
        for (let j = 0; j < this.apisManifest.paxList[i].apisTravelDocuments.length; j++) {
          this.generate_apis_caricom_form.addControl("pax" + i.toString() + 'docType' + j.toString(), new UntypedFormControl(this.apisManifest.paxList[i].apisTravelDocuments[j].documentType || "No Document Provided"));
          this.generate_apis_caricom_form.addControl("pax" + i.toString() + 'docCode' + j.toString(), new UntypedFormControl(this.apisManifest.paxList[i].apisTravelDocuments[j].documentCode || "No Document Provided"));
          this.generate_apis_caricom_form.addControl("pax" + i.toString() + 'docNumber' + j.toString(), new UntypedFormControl(this.apisManifest.paxList[i].apisTravelDocuments[j].documentNumber || "No Document Provided"));
          this.generate_apis_caricom_form.addControl("pax" + i.toString() + 'docExpiry' + j.toString(), new UntypedFormControl(datepipe.transform(this.apisManifest.paxList[i].apisTravelDocuments[j].documentExpiry, 'MMM-dd-yyyy')));
          if (this.apisManifest.paxList[i].apisTravelDocuments[j].documentNumber == '' || this.apisManifest.paxList[i].apisTravelDocuments[j].documentNumber == null)
            this.generate_apis_caricom_form.get("pax" + i.toString() + 'docExpiry' + j.toString()).setValue("No Document Provided");
          this.generate_apis_caricom_form.addControl("pax" + i.toString() + 'docCountry' + j.toString(), new UntypedFormControl(this.apisManifest.paxList[i].apisTravelDocuments[j].documentCountryCode || "No Document Provided"));
          if ((new Date(this.apisManifest.paxList[i].apisTravelDocuments[j].documentExpiry).getTime() <= new Date(this.f.localDepDate.value).getTime()) && this.apisManifest.paxList[i].apisTravelDocuments[j].documentExpiry != '') {
            this.hasExpiredDocument = true;
          }
          if (this.apisManifest.paxList[i].apisTravelDocuments[j].documentNumber == "") {
            this.hasNoDocument = true;
            if (this.noDocumentList != "")
              this.noDocumentList += "\n";
            this.noDocumentList += this.apisManifest.paxList[i].travelerType + " - " + this.apisManifest.paxList[i].lastName + ", " + this.apisManifest.paxList[i].firstName + " " + this.apisManifest.paxList[i].middleName;

          }
        }

      }
    }

    if (this.hasExpiredDocument) {
      this._dialogService.openAlertDialog("Warning: Manifest has expired documents.  Confirm send ok.");
    }
    if (this.hasNoDocument) {
      this._dialogService.openAlertDialog("Warning: The following traveler(s) have no travel documents:\n" + this.noDocumentList + "\n\nAPIS submission must be coordinated with CBP.", "Confirm", undefined, false);
    }


  }

  getReferenceNumber(isCrew: boolean, isPax: boolean): Observable<any[]> {
    let getRefNumber = this._groundStopAdvanceService.getAPISReferenceNumber();
    let getCrewRefNumber;
    if (isCrew)
      getCrewRefNumber = this._groundStopAdvanceService.getAPISReferenceNumber();
    else
      getCrewRefNumber = of(null);
    let getPaxRefNumber;
    if (isPax)
      getPaxRefNumber = this._groundStopAdvanceService.getAPISReferenceNumber();
    else
      getPaxRefNumber = of(null);



    return forkJoin([getRefNumber, getCrewRefNumber, getPaxRefNumber]);
  }


  setValidation() {
    if (this.isInbound) {
      this.f.departureCountry.setValidators(Validators.required);
      this.f.departureCountry.updateValueAndValidity();
      this.f.arrivalAirport.setValidators(Validators.required);
      this.f.arrivalAirport.updateValueAndValidity();
      //this.f.arrivalState.setValidators(Validators.required);
      //this.f.arrivalState.updateValueAndValidity();
    }
    else {
      this.f.arrivalCountry.setValidators(Validators.required);
      this.f.arrivalCountry.updateValueAndValidity();
      this.f.departureAirport.setValidators(Validators.required);
      this.f.departureAirport.updateValueAndValidity();
      //this.f.departureState.setValidators(Validators.required);
      //this.f.departureState.updateValueAndValidity();
    }

    //if (this.apisManifest.operatorCompany != null && this.apisManifest.operatorCompany != "") {
    //  this.f.operatorCompany.setValidators(Validators.required);
    //  this.f.operatorCompany.updateValueAndValidity();
    //}
    //else {
    //  this.f.operatorFirst.setValidators(Validators.required);
    //  this.f.operatorFirst.updateValueAndValidity();
    //  this.f.operatorLast.setValidators(Validators.required);
    //  this.f.operatorLast.updateValueAndValidity();
    //}

    //if (this.apisManifest.ownerCompany != null && this.apisManifest.ownerCompany != "") {
    //  this.f.ownerCompany.setValidators(Validators.required);
    //  this.f.ownerCompany.updateValueAndValidity();
    //}
    //else {
    //  this.f.ownerFirst.setValidators(Validators.required);
    //  this.f.ownerFirst.updateValueAndValidity();
    //  this.f.ownerLast.setValidators(Validators.required);
    //  this.f.ownerLast.updateValueAndValidity();
    //}

    //if (this.apisManifest.departureIsApisApproved == false && this.isOutbound == true) {
    //  this.f.depPlaceDescription.setValidators(Validators.required);
    //  this.f.depPlaceDescription.updateValueAndValidity();
    //}

    //if (this.apisManifest.arrivalIsApisApproved == false && this.isInbound == true) {
    //  this.f.arrPlaceDescription.setValidators(Validators.required);
    //  this.f.arrPlaceDescription.updateValueAndValidity();
    //}
  }

  setClass(e: any): string {
    if (e.id.indexOf("docExpiry") != -1) {
      if (((new Date(e.value).getTime() <= new Date(this.f.localDepDate.value).getTime()) && e.value != '')) {
        return "valid-info";
      }
      else {
        if (this.generate_apis_caricom_form.get(e.id.replace("docExpiry", "docType")).value == "Passport") {
          if (e.value == "") {
            this.generate_apis_caricom_form.get(e.id.replace("docExpiry", "docType")).setErrors({ 'required': true });
            return "is-invalid";
          }
          else {
            this.generate_apis_caricom_form.get(e.id.replace("docExpiry", "docType")).clearValidators();
            this.generate_apis_caricom_form.get(e.id.replace("docExpiry", "docType")).updateValueAndValidity();
            return "";
          }
        }

      }

    }
    else
      if (e.id.indexOf("Birthdate") != -1) {
        if (((new Date(e.value).getTime() > new Date(this.f.localDepDate.value).getTime()) && e.value != '')) {
          this.generate_apis_caricom_form.get(e.id).setErrors({ 'invalidDate': true });
          return "is-invalid";
        }
        else {
          if (e.value == '' || e.value == null)
            return "is-invalid";
          else
            return "";
        }

      }
      else
        if (e.id.indexOf("crewBirthCountry") != -1 || e.id.indexOf("crewBirthState") != -1 || e.id.indexOf("crewBirthCity") != -1) {
          //if (e.id.indexOf("crewBirthState") != -1) {
          if (e.value == '' || e.value == null)
            return "valid-info";
        }
        else {
          if (e.value == '' || e.value == 'No Document' || e.value == 'MISSING') {
            if (e.id != '')
              if (this.generate_apis_caricom_form.get(e.id) != null) {
                this.generate_apis_caricom_form.get(e.id).setErrors({ 'required': true });
                return "is-invalid";
              }

          }
          else {
            return "";
          }
        }
  }

  isCitizen(e: ApisFlightManifestModel): boolean {
    if (e.citizenshipCountry == "USA" || e.apisTravelDocuments.findIndex(x => x.documentCode == 'C') > -1)
      return true;
    else
      return false;
  }

  getData() {
    this.showSpin = true;
    this.isCrewChange = false;
    this.isPaxChange = false;
    this.isPaxAdd = false;
    let request = new ApisManifest();
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopGUID = this.groundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskId;
    request.apisTypeID = 3;
    this._groundStopAdvanceService.getApisData<ResponseModel<ApisManifest>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.apisManifest = response.model;
          this.apisManifest.emergencyContactLast = localStorage.getItem('ln');
          this.apisManifest.emergencyContactFirst = localStorage.getItem('fn');
          this.apisManifest.emergencyContactEmail = "ops@flightprointl.com";
          this.apisManifest.emergencyContactPhone = "346 415 6900";
          this.apisManifest.emergencyContactFax = "346 415 6913";
          this.isDepApisApproved = this.apisManifest.departureIsApisApproved;
          this.isArrApisApproved = this.apisManifest.arrivalIsApisApproved;
          this.apisManifest.departurePlaceDescription = "";
          this.apisManifest.arrivalPlaceDescription = "";
          //this.documentCode = this.apisManifest.documentCode;
          this.flightNumber = this.apisManifest.flightNumber;
          this.isInOneHour = this.apisManifest.isInOneHour;
          if (this.isInOneHour)
            this.documentCode = "250+C";
          if (this.apisManifest.isInbound) {
            this.isInbound = true;
            this.isOutbound = false;
            this.InboundOutbound = "Inbound";
            this.lastNext = "Last";

          }

          //if (this.apisManifest.crewList != null) {
          //  if (this.apisManifest.crewList.findIndex(x => x.isNew == true) > -1 || this.apisManifest.crewList.findIndex(x => x.isUpdate == true) > -1)
          //    this.isCrewChange = true;
          //}
          //if (this.apisManifest.paxList != null) {
          //  if (this.apisManifest.paxList.findIndex(x => x.isNew == true) > -1)
          //    this.isPaxAdd = true;
          //  if (this.apisManifest.paxList.findIndex(x => x.isUpdate == true) > -1)
          //    this.isPaxChange = true;
          //}
          this.initControls();
          this.validateAirport("departureAirport");
          this.validateAirport("arrivalAirport");
          this.setValidation();

        }

        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
        this.showSpin = false;
      }
    });
  }

  get f() { return this.generate_apis_caricom_form.controls; }

  async generate(submit: boolean) {
    this.errMsg = "";

    if (this.generate_apis_caricom_form.invalid) {
      this.errMsg = "Missing one or more required fields."
      for (const key of Object.keys(this.generate_apis_caricom_form.controls)) {
        if (this.generate_apis_caricom_form.controls[key].invalid) {
          var invalidControl;
          invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          if (invalidControl == null)
            invalidControl = this.el.nativeElement.querySelector('[id="' + key + '"]');

          break;
        }
      }

      invalidControl.focus();


      return;
    }
    if (submit)
      this.submittingXml = true;
    else
      this.loadingXml = true;
    this.apisManifest.apisSubmissions = [];
    this.updateApisManifest();
    let isCrew = false;
    let isPax = false;
    let e: ApisSubmissionModel;
    if (this.apisManifest.crewList != null) {

      isCrew = true;
      e = new ApisSubmissionModel();
      e.transmissionType = "C";
      if (this.isInOneHour)
        e.documentCode = "250+CC";
      else
        e.documentCode = "250+C";

      e.hasNoDocuments = this.hasNoDocument;
      e.travelersWithNoDocuments = this.noDocumentList;
      this.apisManifest.apisSubmissions.push(e);

    }
    if (this.apisManifest.paxList != null) {
      isPax = true;
      e = new ApisSubmissionModel();
      e.documentCode = "745";
      e.transmissionType = "P";
      e.hasNoDocuments = this.hasNoDocument;
      e.travelersWithNoDocuments = this.noDocumentList;
      this.apisManifest.apisSubmissions.push(e);
    }

    this.getReferenceNumber(isCrew, isPax).subscribe(responses => {
      if (responses[0] != null)
        this.apisManifest.referenceNumber = responses[0].model;
      if (responses[1] != null)
        this.apisManifest.apisSubmissions.find(x => x.transmissionType == "C").referenceNumber = responses[1].model;
      if (responses[2] != null)
        this.apisManifest.apisSubmissions.find(x => x.transmissionType == "P").referenceNumber = responses[2].model;

      //this.apisManifest.apisSubmissions[0].edifact = this.generateEdifact(this.apisManifest).toUpperCase();
      this.apisManifest.apisSubmissions.forEach(x => {
        x.edifact = this.generateEdifact(x).toUpperCase();
      });

      //this.apisManifest.apisSubmissions[0].edifact
      //let file = new Blob([this.apisManifest.apisSubmissions[0].edifact], { type: 'application/text' })

      if (submit == false) {
        this.apisManifest.apisSubmissions.forEach(x => {
          let file = new Blob([x.edifact.toUpperCase()], { type: 'application/edifact' })
          saveAs(file, "APIS-" + this.apisManifest.registration + "-" + this.apisManifest.departureICAO + "-" + this.apisManifest.arrivalICAO + "-" + x.transmissionType + ".edi",)
          //this.dynamicDownloadByHtmlTag({
          //  fileName: "APIS-" + this.apisManifest.registration + "-" + this.apisManifest.departureICAO + "-" + this.apisManifest.arrivalICAO + "-" + x.transmissionType + ".edi",
          //  text: x.edifact.toUpperCase()
          //});
        });
        this.loadingXml = false;
      }
      else {
        let request = new ApisManifest();
        request.groundStopTaskGUID = this.groundStopTaskId;
        //request.crewEdifact = crewEdifact.toUpperCase();
        request.apisSubmissions = this.apisManifest.apisSubmissions;
        request.flightNumber = this.f.flightNumber.value;
        request.apisTypeID = 3;
        request.crewList = this.apisManifest.crewList;
        request.paxList = this.apisManifest.paxList;
        request.revisionNumber = this.apisManifest.revisionNumber;
        this._groundStopAdvanceService.submitApisManifest<ResponseModel<ApisManifest>>(request).subscribe(async response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              let apisResponse = response.model;
              if (apisResponse.apisSubmissions.findIndex(x => x.isSuccessful == false) > -1) {
                this.errMsg = "Submission failed.";
                apisResponse.apisSubmissions.forEach(x => {
                  if (!x.isSuccessful)
                    this.errMsg += " " + x.apisResponse;
                });
              }
              else {
                this.isUpdated = true;
                this.showSuccessMessage = true;

                await this.generateReport(apisResponse.apisSubmissions).then(finished => {
                  if (finished) {
                    this.showSuccessMessage = false;
                    this.close();
                  }
                });
              }
              this.submittingXml = false;

            }
            else {
              this.errMsg = response.message;
              this.submittingXml = false;
            }
          }
          else {
            this.errMsg = "There was a problem submitting the manifest.";
            this.submittingXml = false;
          }
        });
        //var reader = new FileReader();
        //let self = this;
        //reader.readAsDataURL(file);
        //let request = new ApisManifest();
        //reader.onloadend = function () {
        //  let apisSub: ApisSubmissionModel[] = [];
        //  let apis = new ApisSubmissionModel();
        //  apis.apisTypeID = 3;
        //  apis.edifact = (<string>reader.result).split(',')[1];
        //  apis.referenceNumber = self.apisManifest.referenceNumber;
        //  apis.documentCode = "";
        //  apis.transmissionType = "";
        //  apis.travelersWithNoDocuments = "";
        //  self.apisManifest.apisSubmissions.forEach(x => {
        //    apis.documentCode += x.documentCode;
        //    apis.transmissionType += x.transmissionType;
        //    apis.travelersWithNoDocuments += x.travelersWithNoDocuments;
        //  });
        //  apisSub.push(apis);
        //  request.groundStopTaskGUID = self.groundStopTaskId;
        //  //request.crewEdifact = crewEdifact.toUpperCase();
        //  request.apisSubmissions = apisSub;
        //  request.apisTypeID = 3;
        //  request.crewList = self.apisManifest.crewList;
        //  request.paxList = self.apisManifest.paxList;
        //  request.revisionNumber = self.apisManifest.revisionNumber;
        //  self._groundStopAdvanceService.submitApisManifest<ResponseModel<ApisManifest>>(request).subscribe(async response => {

        //    if (response != null) {
        //      if (response.code == "200" && response.message == "") {
        //        let apisResponse = response.model;
        //        if (apisResponse.apisSubmissions.findIndex(x => x.isSuccessful = false) > -1) {
        //          self.errMsg = "Submission failed.";
        //          apisResponse.apisSubmissions.forEach(x => {
        //            if (!x.isSuccessful)
        //              self.errMsg += " " + x.apisResponse;
        //          });
        //        }
        //        else {
        //          self.isUpdated = true;
        //          self.showSuccessMessage = true;
        //          setTimeout(() => {
        //            self.showSuccessMessage = false;
        //            self.close();
        //          }, 1000);
        //          //await self.generateReport(apisResponse.apisSubmissions).then(finished => {
        //          //  if (finished) {
        //          //    self.showSuccessMessage = false;
        //          //    self.close();
        //          //  }
        //          //});
        //        }
        //        self.submittingXml = false;

        //      }
        //      else {
        //        self.errMsg = response.message;
        //        self.submittingXml = false;
        //      }
        //    }
        //    else {
        //      self.errMsg = "There was a problem submitting the manifest.";
        //      self.submittingXml = false;
        //    }
        //  });
        //}
      }

      // this.loading = false;
    });

  }

  updateApisManifest() {
    this.apisManifest.emergencyContactFirst = this.f.emergencyFirst.value;
    this.apisManifest.emergencyContactLast = this.f.emergencyLast.value;
    this.apisManifest.emergencyContactPhone = this.f.emergencyPhone.value;
    this.apisManifest.emergencyContactEmail = this.f.emergencyEmail.value;
    //this.apisManifest.departurePlaceDescription = this.f.depPlaceDescription.value;
    //this.apisManifest.departureICAO = this.f.departureAirport.value.toUpperCase();
    //this.apisManifest.departureCity = this.f.departureCity.value;
    //this.apisManifest.departureState = this.f.departureState.value;
    //this.apisManifest.borderCrossing = this.f.borderCrossing.value;
    //this.apisManifest.arrivalPlaceDescription = this.f.arrPlaceDescription.value;
    //this.apisManifest.foreignAirports[0].icao = this.f.foreignAirport1.value.toUpperCase();
    //this.apisManifest.foreignAirports[1].icao = this.f.foreignAirport2.value.toUpperCase();
    //this.apisManifest.foreignAirports[2].icao = this.f.foreignAirport3.value.toUpperCase();
    //this.apisManifest.foreignAirports[3].icao = this.f.foreignAirport4.value.toUpperCase();
    //this.apisManifest.foreignAirports[4].icao = this.f.foreignAirport5.value.toUpperCase();
    //if (this.transmissionType == 'C') {
    for (let i = 0; i < this.apisManifest.crewList.length; i++) {
      this.apisManifest.crewList[i].contactPhone1 = this.generate_apis_caricom_form.get("crewContactPhone1" + i.toString()).value || "";
      this.apisManifest.crewList[i].contactPhone2 = this.generate_apis_caricom_form.get("crewContactPhone2" + i.toString()).value || "";
      this.apisManifest.crewList[i].contactEmail = this.generate_apis_caricom_form.get("crewContactEmail" + i.toString()).value || "";

    }
    //}
    //else {
    if (this.apisManifest.paxList != null) {
      for (let i = 0; i < this.apisManifest.paxList.length; i++) {
        this.apisManifest.paxList[i].contactPhone1 = this.generate_apis_caricom_form.get("paxContactPhone1" + i.toString()).value || "";
        this.apisManifest.paxList[i].contactPhone2 = this.generate_apis_caricom_form.get("paxContactPhone2" + i.toString()).value || "";
        this.apisManifest.paxList[i].contactEmail = this.generate_apis_caricom_form.get("paxContactEmail" + i.toString()).value || "";
      }
    }
    //}
  }

  generateEdifact(e: ApisSubmissionModel): string {

    let segCount = 1;
    let paxCount = 0;
    var edifact = "UNA:+.? '"; //FOR TEST USADHSTEST//regular USADHS'.
    edifact += "UNB+UNOA:4+FlightPro:ZZ+CARICOMAPIS:ZZ+" + datepipe.transform(new Date(), 'yyMMdd:HHmm') + "+" + e.referenceNumber + "++APIS'";
    edifact += "UNG+PAXLST+" + this.apisManifest.customerName + "+CARICOMAPIS+" + datepipe.transform(new Date(), 'yyMMdd:HHmm') + "+" + e.referenceNumber + "+UN+D:02B'";

    edifact += "UNH+" + e.referenceNumber + "+PAXLST:D:02B:UN:IATA'";
    segCount += 1;
    edifact += "BGM+" + e.documentCode + "'";
    segCount += 1;
    //edifact += "RFF+TN:" + this.apisManifest.tripCode + this.apisManifest.interchangeReferenceNumber + ":::'";
    edifact += "NAD+MS+++" + this.apisManifest.emergencyContactFirst + " " + this.apisManifest.emergencyContactLast + "'";
    segCount += 1;
    edifact += "COM+" + this.apisManifest.emergencyContactPhone + ":TE+" + this.apisManifest.emergencyContactFax + ":FX'";
    segCount += 1;
    edifact += "TDT+20+" + this.f.flightNumber.value + "'";
    segCount += 1;
    edifact += "LOC+125+";
    //edifact += this.isInbound ? "125+" : "87+";
    segCount += 1;
    edifact += this.apisManifest.departureICAO + "'";
    edifact += "DTM+189:" + datepipe.transform(this.apisManifest.departureDateLocal, 'yyMMdd') + this.apisManifest.departureTimeLocal.replace(':', '') + ":201'";
    segCount += 1;
    edifact += "LOC+87+";
    //edifact += this.isInbound ? "87+" : "125+";
    edifact += this.apisManifest.arrivalICAO + "'";
    segCount += 1;
    edifact += "DTM+232:" + datepipe.transform(this.apisManifest.arrivalDateLocal, 'yyMMdd') + this.apisManifest.arrivalTimeLocal.replace(':', '') + ":201'";
    segCount += 1;
    if (e.transmissionType == 'C' || e.transmissionType == 'CC') {
      this.apisManifest.crewList.forEach(x => {
        /*if (this.apisManifest.revisionNumber == 1 || (this.apisManifest.revisionNumber > 1 && (x.isUpdate || x.isNew))) {*/
        x.isSubmitted = true;
        edifact += "NAD+" + x.tType + "+++" + x.lastName + ":" + (x.firstName + " " + x.middleName).trim();
        //if (this.isInbound)
        edifact += "+" + x.permanentAddress.streetAddress + "+" + x.permanentAddress.city + "+" + x.permanentAddress.stateProvince + "+" + x.permanentAddress.postalCode + "+" + x.permanentAddress.countryCode;
        edifact += "'";
        segCount += 1;
        edifact += "ATT+2++" + x.sex + "'";
        segCount += 1;
        edifact += "DTM+329:" + datepipe.transform(x.dob, 'yyMMdd') + "'";
        segCount += 1;
        if (this.isInbound) {
          edifact += "LOC+22+" + this.apisManifest.arrivalICAO + "'";
          segCount += 1;
          edifact += "LOC+174+" + x.residenceCountry + "'";
          segCount += 1;
        }
        edifact += "LOC+178+" + this.apisManifest.departureICAO + "'";
        segCount += 1;
        edifact += "LOC+179+" + this.apisManifest.arrivalICAO + "'";
        segCount += 1;


        if (x.countryOfBirth != null) {
          edifact += "LOC+180+" + x.countryOfBirth;
          if (x.cityOfBirth != null)
            edifact += "+:::" + x.cityOfBirth;
          if (x.stateProvinceOfBirth != null)
            edifact += "+:::" + x.stateProvinceOfBirth;
          edifact += "'";
          segCount += 1;
        }
        //add contact info here phone 1 phone 2
        if (x.contactPhone1 != null && x.contactPhone1 != "") {
          edifact += "COM+" + x.contactPhone1.replace('-', ' ') + ":TE";
          if (x.contactPhone2 != null && x.contactPhone2 != "") {
            edifact += x.contactPhone2.replace('-', ' ') + ":TE";
          }
          edifact + "'";
          segCount += 1;
        }
        edifact += "EMP+1+" + x.travelerType + ":110:111'";
        edifact += "NAT+2+" + x.citizenshipCountry + "'";
        segCount += 1;
        x.apisTravelDocuments.forEach(y => {
          if (y.documentCode != "") {
            edifact += "DOC+" + y.documentCode + ":110:111+" + y.documentNumber + "'";
            segCount += 1;
            if (y.documentExpiry != "") {
              edifact += "DTM+36:" + datepipe.transform(y.documentExpiry, 'yyMMdd') + "'";
              segCount += 1;
            }
            edifact += "LOC+91+" + y.documentCountryCode + "'";
            segCount += 1;
          }
        });

      });
      edifact += "CNT+41:" + this.apisManifest.crewList.length.toString() + "'";
      segCount += 1;

    }
    else {
      this.apisManifest.paxList.forEach(x => {
        // if (this.apisManifest.revisionNumber == 1 || x.isNew || x.isUpdate) {
        x.isSubmitted = true;
        edifact += "NAD+" + x.tType + "+++" + x.lastName + ":" + (x.firstName + " " + x.middleName).trim();
        //if (this.isInbound && !this.isCitizen(x) && x.addressInUsa.streetAddress != null)
        //  edifact += "+" + x.addressInUsa.streetAddress + "+" + x.addressInUsa.city + "+" + x.addressInUsa.stateProvince + "+" + x.addressInUsa.postalCode + "+" + x.addressInUsa.countryCode;
        edifact += "'";
        segCount += 1;
        edifact += "ATT+2++" + x.sex + "'";
        segCount += 1;
        edifact += "DTM+329:" + datepipe.transform(x.dob, 'yyMMdd') + "'";
        segCount += 1;
        if (this.isInbound) {
          edifact += "LOC+22+" + this.apisManifest.arrivalICAO + "'";
          segCount += 1;
        }
        edifact += "LOC+178+" + this.apisManifest.departureICAO + "'";
        segCount += 1;
        edifact += "LOC+179+" + this.apisManifest.arrivalICAO + "'";
        segCount += 1;
        if (this.isInbound && x.residenceCountry != null) {
          edifact += "LOC+174+" + x.residenceCountry + "'";
          segCount += 1;
        }
        if (x.contactPhone1 != null && x.contactPhone1 != "") {
          edifact += "COM+" + x.contactPhone1.replace('-', ' ') + ":TE";
          if (x.contactPhone2 != null && x.contactPhone2 != "") {
            edifact += x.contactPhone2.replace('-', ' ') + ":TE";
          }
          edifact + "'";
          segCount += 1;
        }
        if (x.citizenshipCountry != null)
          edifact += "NAT+2+" + x.citizenshipCountry + "'";
        segCount += 1;
        x.apisTravelDocuments.forEach(y => {
          if (y.documentCode != "") {
            edifact += "DOC+" + y.documentCode + ":110:111+" + y.documentNumber + "'";
            segCount += 1;
            if (y.documentExpiry != "") {
              edifact += "DTM+36:" + datepipe.transform(y.documentExpiry, 'yyMMdd') + "'";
              segCount += 1;
            }
            edifact += "LOC+91+" + y.documentCountryCode + "'";
            segCount += 1;
          }
        });
        paxCount += 1;

      });

      edifact += "CNT+42:" + paxCount.toString() + "'";
      segCount += 1;

    }

    edifact += "UNT+" + segCount.toString() + "+" + e.referenceNumber + "'"


    edifact += "UNE+1+" + e.referenceNumber + "'";
    edifact += "UNZ+1+" + e.referenceNumber + "'";

    return edifact;
  }


  generateReport(apis: ApisSubmissionModel[] = []) {
    return new Promise<boolean>((finished) => {
      this.errMsg = "";
      this.loadingReport = true;
      const gst = this._groundStopAdvanceService;
      let gstId = this.groundStopTaskId;
      var manifestConfirmation = "";
      this.updateApisManifest();
      let e: ApisSubmissionModel;
      if (apis.length == 0) {
        if (this.showAll == "1") {
          e = new ApisSubmissionModel();
          e.apisResponse = "";
          apis.push(e);
        }
      }
      //apis.forEach(x => {
      //manifestConfirmation = x.apisResponse.substring(x.apisResponse.indexOf(':') + 1).trim();
      var self = this;
      let filePath = ""
      //if (x.apisResponse == "")
      filePath = window.origin + "/assets/templates/ApisReportCaricom.docx";
      //else {
      //  if (x.transmissionType == "C")
      //    filePath = window.origin + "/assets/templates/ApisReportCaricomCrew.docx";
      //  else
      //    filePath = window.origin + "/assets/templates/ApisReportCaricomPax.docx";
      //}
      let apisData = this.apisManifest;

      const datepipe: DatePipe = new DatePipe('en-US')

      loadFile(filePath, function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, nullGetter() { return ''; } });

        doc.setData({
          submitDate: apis[0].submittedDate,
          flightNumber: apisData.flightNumber,
          customerName: apisData.customerName,
          departureICAO: apisData.departureICAO,
          departureDate: apisData.departureDateLocal,
          departureTime: apisData.departureTimeLocal,
          arrivalICAO: apisData.arrivalICAO,
          arrivalDate: apisData.arrivalDateLocal,
          arrivalTime: apisData.arrivalTimeLocal,

          crewCount: apisData.crewList.length,
          paxCount: apisData.paxList != null ? apisData.paxList.length : 0,
          assemblyDate: datepipe.transform(new Date(), 'MMM-dd-YYYY HH:mm')
        });
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
                {});
            }
            return value;
          }
          console.log(JSON.stringify({ error: error }, replaceErrors));
          self.errMsg = JSON.stringify({ error: error }, replaceErrors);
          self.loadingReport = false;

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
            self.errMsg = "There was an error creating the report."
            // errorMessages is a humanly readable message looking like this:
            // 'The tag beginning with "foobar" is unopened'
          }
          this.loading = false;
          throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });

        //saveAs(out, "apisreport.docx");
        let uri;
        //if (window.origin.indexOf('dev') > -1 || window.origin.indexOf('localhost') > -1)
        //  uri = 'https://fpipdfconversion-dev.azurewebsites.net/api/ConvertToPdf'
        //else
          uri = 'https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf'
        const functionURI = uri;

        let headers = new HttpHeaders();
        const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
        //  headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
        var documentName: string;

        documentName = "APIS Submission Report - " + apisData.registration + "-" + apisData.departureICAO + "-" + apisData.arrivalICAO;
        //   if (x.apisResponse.substring(x.apisResponse.indexOf(':') + 1).trim() != "")
        //     documentName += "_" + x.apisResponse.substring(x.apisResponse.indexOf(':') + 1).trim();
        httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {

          let upload = new UploadTaskDocument();
          documentName += ".pdf"
          upload.uploadFile(data.body, gstId, documentName, gst, undefined, apisData.tripCode);
          //var fileURL = URL.createObjectURL(data.body);
          //if (manifestConfirmation == '')
          //  window.open(fileURL);
          self.loadingReport = false;
          finished(true);
        });


        //    });
      });

    });

  }


  validateAirport(airportType: string) {

    this.airportList = [];
    let request = new AirportModel();

    request.route = this.generate_apis_caricom_form.get(airportType).value;

    this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.airportList = response.model;
        if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
          this.airportList.forEach(x => {
            if (airportType == "departureAirport") {
              this.isDepApisApproved = x.isApisApproved;
              this.f.departureCity.setValue(x.cityName);
              this.f.departureState.setValue(x.stateProvinceAbbreviation);
            }
            else {
              this.isArrApisApproved = x.isApisApproved;
              this.f.arrivalCity.setValue(x.cityName);
              this.f.arrivalState.setValue(x.stateProvinceAbbreviation);
            }
          });
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }
    });
  }

  close() {
    let obj = new ReturnObjModel();
    obj.refresh = this.isUpdated;
    this._dialogRef.close(obj);
  }

  changeType(e: any) {
    this.errMsg = "";
    this.flightNumber = this.f.flightNumber.value;
    this.transmissionType = e.value;
    this.getData();
  }

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }
  private dynamicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }

}


