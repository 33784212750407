<div  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="display: flex; width: 100%; justify-content: space-between; background-color: #f0f0f0; margin-top: -1em;">
  <div style="margin-top:0.3125em;display: flex;">
    <span class="material-symbols-outlined" style="font-size: x-large; margin-left: -0.3em;margin-right: 0.2em;">description</span><h5>Document Types</h5>
  </div>
  <div style="display: flex;" >
    <button mat-icon-button *ngIf="isDialog"  (click)="clickRouteNew('documents/document-type-list','')">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>    
    <button mat-icon-button *ngIf="isDialog"  mat-dialog-close><i class="material-icons" style="font-size: x-large;">close </i></button>
  </div>
</div>
<div  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle  style="background-color:white;  width: 100%; overflow-x: auto; scrollbar-width: none;">
  <div style="padding: 0.5em; background-color:white ; ">
    
 
    <form *ngIf="document_type_list_form" [formGroup]="document_type_list_form">
    
      <div style="display: flex; margin-top: -0.5em;">
        <div
          style="justify-content:flex-start;padding-left: 1em; padding-top:0.75em; padding-bottom:0.5em; margin-right:1em;">
          <div style="margin-right:1em">
            <div><label for="service_type" style="font-weight:bold">Display</label></div>
            <div style="margin-top:-0.25em;">
            
              <div style=" display: flex;">
               
                <div style="display: flex; width: 10em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="IsCrew"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">Crew</div>
                </div>
                <div style="display: flex; width: 6em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="IsPax"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">Pax</div>
                </div>
            
                <div style="display: flex; width: 11em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="IsSubDocument"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">IsSubDocument</div>
                </div>
            
                <div style="display: flex; width: 9em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="IsVendor"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">Vendor</div>
                </div>
            
              </div>
              <div style=" display: flex;">

                <div style="display: flex; width: 10em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="IsCustomer"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">Customer</div>
                </div>
            
            
                <div style="display: flex; width: 6em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="IsAircraft"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">Aircraft</div>
                </div>
            
                <div style="display: flex; width: 11em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="IsCountry"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">Country</div>
                </div>
            
                <div style="display: flex; width: 9em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="IsTask"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">Task</div>
                </div>
            
              </div>
             
              <div style=" display: flex;">

                <div style="display: flex; width: 10em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="HasSubdocument"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">HasSubdocument</div>
                </div>
             
            
            
                <div style="display: flex; width: 6em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="IsAirport"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">Airport</div>
                </div>
            
                <div style="display: flex; width: 11em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="IsPermitRequiredDoc"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">PermitRequiredDoc</div>
                </div>
            
                <div style="display: flex; width: 9em;">
                  <div style="margin-right:0.25em">
                    <input formControlName="doc_type" type="radio" name="doc_type" value="IsMiscellaneous"
                      (change)="documentTypeChange($event)" />
                  </div>
                  <div style="margin-top:-0.2em;white-space: nowrap">Miscellaneous</div>
                </div> 

              </div>
            
            
            
            
            
            </div>
          </div> 
        </div> 
        <button class="btn-low" mat-button  (click)="getData()"   style="margin-left: -1em;height: 2em; margin-top: 6em; background-color:grey; color: white; margin-right: 0.5em; ">Refresh</button>

      </div>

      <div style="justify-content:flex-start; padding-left: 1em; margin-top: 1em;   margin-right:1em; ">
 
 


        <!-- <table mat-table [dataSource]="data" class="mat-elevation-z8">
          <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef> {{column}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table> -->

        <div *ngIf="f.doc_type.value != 'IsCrew' && f.doc_type.value != 'IsPax' && f.doc_type.value != 'IsMiscellaneous' && f.doc_type.value != 'IsPermitRequiredDoc'" style=" height: 28em; overflow-y: auto; width: 24em;">
          <table mat-table *ngIf="documentTypeList" [dataSource]="documentTypeList"
            class="example-container mat-elevation-z8" style="width: 20em; ">
            <tr> 
              <ng-container matColumnDef="doc_type">
                <th  mat-header-cell *matHeaderCellDef style="width: 7%; text-align: left !important; padding-left: 0.5em !important;">
                  Document Type
                </th>
                <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;text-align: left !important; padding-left: 0.5em !important;"  >
                  {{element.documentTypeDescription}}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width:5%">
                   <div>Action</div>
                  <button   mat-button class="btn-high" (click)="openDocumentTypeAdd()" >Add</button> 
                  <!-- <div style="margin-top:0em;padding-bottom:0.25em"></div> -->
                </th>
                <td mat-cell *matCellDef="let element" style="width: 5%;">
                  <!-- <a   mat-button   class="btn-low" style="align-self:center"  >  Edit </a> -->
                  <button   mat-button class="btn-low" (click)="openDocumentTypeEdit($event, element)" >Edit</button>  
  
                </td>
              </ng-container> 

            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>

        <div *ngIf="f.doc_type.value == 'IsCrew'" style=" height: 28em; overflow-y: auto; width: 29em;">
          <table mat-table *ngIf="documentTypeList" [dataSource]="documentTypeList"
            class="example-container mat-elevation-z8" style="width: 23em;">
            <tr> 
              <ng-container matColumnDef="doc_type">
                <th  mat-header-cell *matHeaderCellDef style="width: 7%; text-align: left !important; padding-left: 0.5em !important;">
                  Document Type
                </th>
                <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;text-align: left !important; padding-left: 0.5em !important;"  >
                  {{element.documentTypeDescription}}
                </td>
              </ng-container>
              <ng-container matColumnDef="sort">
                <th  mat-header-cell *matHeaderCellDef style="width:3%; text-align:center !important">
                  Sort Order
                </th>
                <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;"  >
                  {{element.sortOrderNo}}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width:5%">
                   <div>Action</div>
                  <button   mat-button class="btn-high" (click)="openDocumentTypeAdd()" >Add</button> 
                  <!-- <div style="margin-top:0em;padding-bottom:0.25em"></div> -->
                </th>
                <td mat-cell *matCellDef="let element" style="width: 5%;">
                  <!-- <a   mat-button   class="btn-low" style="align-self:center"  >  Edit </a> -->
                  <button   mat-button class="btn-low" (click)="openDocumentTypeEdit($event, element)" >Edit</button>  
  
                </td>
              </ng-container> 

            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumnCrew; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnCrew"></tr>
          </table>
        </div>
       
        <div *ngIf="f.doc_type.value == 'IsPax'" style=" height: 28em; overflow-y: auto; width: 34em;">
          <table mat-table *ngIf="documentTypeList" [dataSource]="documentTypeList"
            class="example-container mat-elevation-z8" style="width: 30em; ">
            <tr> 
              <ng-container matColumnDef="doc_type">
                <th  mat-header-cell *matHeaderCellDef style="width: 50%; text-align: left !important; padding-left: 0.5em !important;">
                  Document Type
                </th>
                <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;text-align: left !important; padding-left: 0.5em !important;"  >
                  {{element.documentTypeDescription}}
                </td>
              </ng-container>
              <ng-container matColumnDef="doc_code">
                <th  mat-header-cell *matHeaderCellDef style="width:15%; text-align:center !important">
                  Document Code
                </th>
                <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;"  >
                  {{element.documentCode}}
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="sort">
                <th  mat-header-cell *matHeaderCellDef style="width:3%; text-align:center !important">
                  Sort Order
                </th>
                <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;"  >
                  {{element.CountryID}}
                </td>
              </ng-container> -->
              <ng-container matColumnDef="sort">
                <th  mat-header-cell *matHeaderCellDef style="width:15%; text-align:center !important">
                  Sort Order
                </th>
                <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;"  >
                  {{element.apisSortOrderNo}}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width:20%">
                   <div>Action</div>
                  <button   mat-button class="btn-high" (click)="openDocumentTypeAdd()" >Add</button>   

                  <!-- <div style="margin-top:0em;padding-bottom:0.25em"></div> -->
                </th>
                <td mat-cell *matCellDef="let element" style="width: 5%;">
                  <!-- <a   mat-button   class="btn-low" style="align-self:center"  >  Edit </a> -->
                  <button   mat-button class="btn-low" (click)="openDocumentTypeEdit($event, element)" >Edit</button>  
  
                </td>
              </ng-container> 

            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumnPax; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnPax"></tr>
          </table>
        </div>
       
        <div *ngIf="f.doc_type.value == 'IsMiscellaneous' || f.doc_type.value == 'IsPermitRequiredDoc'" style=" height: 28em; overflow-y: auto; width: 29em;">
          <table mat-table *ngIf="documentTypeList" [dataSource]="documentTypeList"
            class="example-container mat-elevation-z8" style="width: 23em;">
            <tr> 
              <ng-container matColumnDef="doc_type">
                <th  mat-header-cell *matHeaderCellDef style="width: 7%; text-align: left !important; padding-left: 0.5em !important;">
                  Document Type
                </th>
                <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;text-align: left !important; padding-left: 0.5em !important;"  >
                  {{element.documentTypeDescription}}
                </td>
              </ng-container>
              <ng-container matColumnDef="sort">
                <th  mat-header-cell *matHeaderCellDef style="width:3%; text-align:center !important">
                  Sort Order
                </th>
                <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;"  >
                  {{element.sortOrderCountryReq}}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width:5%">
                   <div>Action</div>
                  <button   mat-button class="btn-high" (click)="openDocumentTypeAdd()" >Add</button> 
                  <!-- <div style="margin-top:0em;padding-bottom:0.25em"></div> -->
                </th>
                <td mat-cell *matCellDef="let element" style="width: 5%;">
                  <!-- <a   mat-button   class="btn-low" style="align-self:center"  >  Edit </a> -->
                  <button   mat-button class="btn-low" (click)="openDocumentTypeEdit($event, element)" >Edit</button>  
  
                </td>
              </ng-container> 

            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumnCrew; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnCrew"></tr>
          </table>
        </div>

      </div>
 
       

 
  </form>

  </div>
</div>
