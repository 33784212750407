import { AirportModel } from "./airport.model";

export class MapPointsModel {
  latitude: number;
  longitude: number;
  title: string;
  isMainPoint: boolean;
  airport: AirportModel;
  isPreferred: boolean;
}
