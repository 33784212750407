import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { AircraftModel } from '../models/aircraft.model';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CustomerModel } from '../models/customer.model';
import { ResponseModel } from '../models/response.model';
import { AircraftService } from '../services/aircraft.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerGroupService } from '../services/customer-group-service';
import { DialogService } from '../services/dialog.service';
import { AircraftMainEditDialogComponent } from './aircraft-main-edit-dialog.component';
import { CustomerInformationDialogComponent } from '../customers/customer-information-dialog.component';
import { CustomerProfileSetupEditComponent } from '../customers/customer-profile-setup-edit.component';
import { CustomerProfileSetupComponent2 } from '../customers/customer-profile-setup2.component';
import { AdminServicesClientComponent } from '../user-accounts/admin-services-client.component';
import { ManageContactDetailsComponent } from '../persons/manage-contact-details.component';
import { AircraftProfileDetailsComponent } from './aircraft-profile-details.component';
import { AircraftProfileEditComponent } from './aircraft-profile-edit.component';

@Component({
  selector: 'app-aircraft-profile',
  templateUrl: './aircraft-profile.component.html',
  styleUrls: ['./aircraft-profile.component.css']
})
/** aircraft-profile component*/
export class AircraftProfileComponent implements OnInit {
  aircraft_profile_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  customerGuid: string = "";
  selectedAircraftGuid: string;
  userType: string;
  customerList: CustomerModel[];
  aircraftList: AircraftModel[];
  recordList: AircraftModel[];
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  totalRecordsText: string;
  text_search: string;
  filter_by: string;
  scrHeight: number;
  includeDisabledRecords: boolean = false;
  showResults: boolean = false;
  grantAccessAircraftProfiles: boolean = false;
  grantAccessToAccountGrouping: boolean = false;
  isDialog: boolean = false;
  selectedClientName: string = "";
  displayedColumn: string[] = ['registration', 'aircraft_type', 'client_name', 'mtow', 'height', 'wingspan', 'homebase',
    'has_documents', 'has_owner_lessee', 'has_operator', 'has_trips', 'disabled', 'action'];

  constructor(private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _commonService: CommonService,
    private readonly _aircraftService: AircraftService, private readonly _dialog: MatDialog, private _router: Router, private readonly _customerGroupService: CustomerGroupService,
    private _route: ActivatedRoute, private readonly _dialogService: DialogService, @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private readonly _dialogRef: MatDialogRef<AircraftProfileComponent>) {
    if (data) {
      this.isDialog = true;
      this.customerGuid = data.customerGUID;
      this.selectedClientName = data.customerName;
    }
  }

  ngOnInit() {

    this.showSpin = true;
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.acPro') == 'true') {
      this.grantAccessAircraftProfiles = true;
    }
    else {
      this.grantAccessAircraftProfiles = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");

      return;
    }
    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }
    this.errMsg = "";
    this.msg = "";
    this.customerList = [];
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;

    //this.customerGuid = "";
    this.text_search = "";
    this.selectedAircraftGuid = "";
    this.filter_by = "Registration";
    this.includeDisabledRecords = true;
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }


    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    if (!this.isDialog) {
      this._route.queryParams.subscribe(params => {
        if (params["customerGuid"] == undefined || params["customerGuid"] == null) {
          this.customerGuid = "";
          this.selectedClientName = "";
          this.text_search = "";
          this.filter_by = "Registration";
        }
        else {
          this.customerGuid = params["customerGuid"];
          this.selectedClientName = params["customerName"];
          this.text_search = params["textSearch"];
          this.filter_by = params["filterBy"];
          this.showResults = true;
        }
      });
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerList = responses[0].model;
          if (this.userType == "customer" && this.grantAccessToAccountGrouping) {
            if (this.customerGuid == "")
              this.customerGuid = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();
          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.initControls();

      this.getSearchResults();
      this.showSpin = false;

    });
  }


  initControls() {
    this.aircraft_profile_form = this._formBuilder.group({
      customer_select: this.customerGuid,
      // registration_select: this.selectedAircraftGuid,
      text_search: [this.text_search],
      filter_by: [this.filter_by],
      includeDisabledRecords: !this.includeDisabledRecords
    });
  }

  get f() { return this.aircraft_profile_form.controls; }

  customerChange(e: any) {
    this.errMsg = "";
    this.msg = "";
    if (this.f.customer_select.value != "") {
      this.displayedColumn = ['registration', 'aircraft_type', 'mtow', 'height', 'wingspan', 'homebase', 'has_preferences', 'has_notes',
        'has_documents', 'has_owner_lessee', 'has_operator', 'has_trips', 'disabled', 'action'];
    }
    else {
      this.displayedColumn = ['registration', 'aircraft_type', 'client_name', 'mtow', 'height', 'wingspan', 'homebase', 'has_preferences', 'has_notes',
        'has_documents', 'has_owner_lessee', 'has_operator', 'has_trips', 'disabled', 'action'];
    }

    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.errMsg = "";
    this.msg = "";
    this.selectedAircraftGuid = "";
    this.text_search = "";
    this.getSearchResults();


  }

  registrationChange(e: any) {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults();


  }

  clickClose(val) {
    this._dialogRef.close(val);
  }

  clickReset() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.customerGuid = "";
    this.selectedAircraftGuid = "";
    this.text_search = "";
    this.filter_by = "Registration";
    if (this.userType == "customer" && this.grantAccessToAccountGrouping) {
      if (this.customerGuid == "")
        this.customerGuid = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();
    }
    this.initControls();
    this.getSearchResults();
  }

  getAllData(): Observable<any[]> {
    let getClistResponse;
    if (this.userType == 'internal') {
      getClistResponse = this._commonService.getCustomers();
    }
    else {
      if (this.grantAccessToAccountGrouping) {
        let req = new CustomerGroupModel();
        getClistResponse = this._customerGroupService.getSelectedCustomerListBycgrpId(req);
      }
      else {
        getClistResponse = of(null);
      }
    }
    return forkJoin([getClistResponse]);

  }

  filterAircraftByClient(e: any) {
    this.customerGuid = this.customerList.filter(x => x.customerName === e.target.value)[0]?.customerGUID;
    if(this.customerGuid !== undefined)
      this.getSearchResults();
  }

  toggleIncludeDisabledRecords(e: any) {
    this.includeDisabledRecords = !this.includeDisabledRecords;
    this.getSearchResults();
  }

  getSearchResults() {
    this.errMsg = "";

    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new AircraftModel();
    request.customerGuid = this.customerGuid;
    if (request.customerGuid == "") {
      request.customerGuid = null;
    }

    request.textSearch = "";
    request.filterBy = "";
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = this.includeDisabledRecords;



    this._aircraftService.getAircraftProfileListByCustomerGUID<ResponseModel<AircraftModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
            this.msg = "";
          }

        }
        else {
          this.recordList = [];
          this.msg = "No data returned.";
        }
      }
      else {
        this.recordList = [];
        this.msg = "";
      }
      this.loading = false;
      this.showSpin = false;

    })

  }

  includeDisabledRecordsOnChange(event: any) {
    //let x = event.target.checked;
    if (this.userType == "internal") {
      if (this.f.customer_select.value == "") {
        this.errMsg = "Please select a client";
        return;
      }
    }
    this.includeDisabledRecords = !this.f.includeDisabledRecords.value;
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults();
  }


  clickAdd(event: any, aircraft: AircraftModel) {
    let s = "";
    let aircraftGUID = "";
    if (aircraft == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      aircraftGUID = aircraft.aircraftGuid;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //  dialogConfig.width = "37.5em";
    // dialogConfig.height = "15em";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, aircraftGuid: aircraftGUID, customerGUID: this.customerGuid, customerName: this.selectedClientName };

    const dialogRef = this._dialog.open(AircraftMainEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        switch (result.actionName) {
          case "edit":
            this.openAircraftDetails(result.newId);
            break;
          case "search":
            this.getSearchResults();
            break;
        }
      }

    });

  }

  clickEdit(event: any, aircraft: AircraftModel) {
    if (this.isDialog) {
      const config = new MatDialogConfig();
      config.panelClass = "custom-dialog-container3"
      //config.disableClose = true;
      config.height = "57em";//"1000px";
      //config.maxWidth = "65em";//"1000px";
      config.width = "87em";//"1000px";
      config.maxWidth = "87em";

      // config.minHeight = "20em";
      config.restoreFocus = false;
      config.hasBackdrop = false;
      config.data = { v: this._authService.getCurrentTimeNumber(), 
        customerGUID: this.customerGuid, customerName: this.customerList.find(x => x.customerGUID === this.customerGuid).customerName,
        aircraftGUID: aircraft.aircraftGuid, textSearch: this.f.text_search.value, filterBy: this.f.filter_by.value };
      config.position = { left: "10px", top: "140px" };

      this.clickClose(true);

      this._dialog.open(AircraftProfileEditComponent, config);
    }
    else {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "aircraftGuid": aircraft.aircraftGuid,
          "customerGuid": this.f.customer_select.value,
          "textSearch": this.f.text_search.value,
          "filterBy": this.f.filter_by.value

        }
      };
      this._router.navigate(['/aircraft/aircraft-profile-details'], navigationExtras);
    }
  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
    }
    this.getSearchResults();

  }

  openAircraftDetails(aircraftGuid: string) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "aircraftGuid": aircraftGuid,
        "customerGuid": this.f.customer_select.value,
        "textSearch": this.f.text_search.value,
        "filterBy": this.f.filter_by.value

      }
    };
    this._router.navigate(['/aircraft/aircraft-profile-details'], navigationExtras);
  }

  clickRouteNew(route: string, display: string) {
    this._dialogRef.close(true);
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber() + "&customerGuid=" + this.customerGuid + "&customerName=" + this.selectedClientName);
  }

  openClientProfileSetupComponent() {
    if (this.isDialog) {
      this.clickClose(true);
    }
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.width = "55em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "10px", top: "140px" };
    config.data = { gsId: "", tripCodeId: "", isDialog: true, v: this._authService.getCurrentTimeNumber() };
    config.position = { left: "10px", top: "140px" };

    this._dialog.open(CustomerProfileSetupComponent2, config);
  }

  openSelection(columnName: string) {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "32.5em";//"1000px";
    //config.maxWidth = "65em";//"1000px";
    config.width = "65em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGuid, customerName: this.selectedClientName, userType: 'customer' };
    config.position = { left: "10px", top: "140px" };

    if (this.isDialog) {
      this.clickClose(true);
    }

    switch (columnName) {
      case 'Acct Setup':
        config.height = "50em";
        config.width = "90em";
        config.maxWidth = "90em";
        config.maxHeight = "50em";
        config.position = { left: "10px", top: "140px" };
        this._dialog.open(CustomerProfileSetupEditComponent, config);
        break;
      case 'Client Information':
        config.width = "69em";
        this._dialog.open(CustomerInformationDialogComponent, config);
        break;
      case 'Aircraft':
        config.width = "92em";
        config.maxWidth = "92em";
        this._dialog.open(AircraftProfileComponent, config);
        break;
      case 'Crew, PAX & Support':
        config.height = "auto";
        config.maxHeight = "80em";
        config.width = "81em";
        this._dialog.open(ManageContactDetailsComponent, config);
        break;
      case 'Admin Services':
        config.height = "max-content";
        config.width = "max-content";
        this._dialog.open(AdminServicesClientComponent, config);
        break;
    }

  }
}
