import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { BillingStatusModel } from '../models/billing-status.model';
import { CustomerAccountingProfileModel } from '../models/customer-accounting-profile.model';
import { CustomerModel } from '../models/customer.model';
import { RequiresBackupInvoicesModel } from '../models/requires-backup-invoices.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';
import { CustomerAccountingProfileEditDialogComponent } from './customer-accounting-profile-edit-dialog.component';

@Component({
  selector: 'app-customer-accounting-profile',
  templateUrl: './customer-accounting-profile.component.html',
  styleUrls: ['./customer-accounting-profile.component.css']
})
/** customer-accounting-profile component*/
export class CustomerAccountingProfileComponent implements OnInit, OnChanges {
  customer_accounting_profile_form: UntypedFormGroup;
  @Input() customerId: string;
  @Output() getModifiedBy = new EventEmitter<string>();
  @Output() getModifiedDate = new EventEmitter<string>();
  errMsg: string;
  msg: string;
  showSpin: boolean;
  userType: string;
  loading: boolean;
  submitted: boolean;
  selectedBillingStatusId: number;
  selectedRequiresBackupInvoicesId: number;
  referencePO: string;
  accountId: string;
  acctType: string;
  isActive: boolean;
  recordList: CustomerAccountingProfileModel[];
  currentPageIndex: number;
  pageSize: number = 10;
  totalRecords: number;
  totalRecordsText: string;
  @Input() includeDisabledRecords: boolean;
  accountID_msg: string = "";
  accountID_good: string = "";
  totalPageNumber: number;
  billingStatusList: BillingStatusModel[];
  requiresBackupInvoices: RequiresBackupInvoicesModel[];
  isModified: boolean;
  showSuccessMsg: boolean = false;
  controlValueChanged: boolean = false;
  modifiedBy: string;
  modifiedDate: string;


  displayedColumn: string[] = ['bill_to_id', 'bill_to_account', 'registration', 'service', 'crew', 'pax',
    'remarks', 'disabled', 'action'];
  /** customer-accounting-profile ctor */
  constructor(private readonly _authService: AuthenticateService, private readonly _commonService: CommonService, private readonly _dialogService: DialogService,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _dialog: MatDialog, private _route: ActivatedRoute, private _customerService: CustomerService) {
  }
  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {


    // this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
      this.includeDisabledRecords = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.acctType = "billTo";

    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getData();

  }

  initControls() {
    this.customer_accounting_profile_form = this._formBuilder.group({
      billing_status: this.selectedBillingStatusId,
      requires_backup_invoices: this.selectedRequiresBackupInvoicesId,
      accounting_id: [this.accountId,Validators.required],
      account_reference_po: this.referencePO,
      acctType: this.acctType
    });
  }

  getData() {
    let request = new CustomerAccountingProfileModel()
    request.customerGUID = this.customerId;
    request.acctType = this.acctType;
    request.isActive = this.includeDisabledRecords;

    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;

    this._customerService.getCustomerAccountingProfileList<ResponseModel<CustomerAccountingProfileModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          if (response.model.length > 0) {
            this.recordList = response.model;
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
            this.msg = "";
          }

          else {
            this.recordList = null;
          }
        }
      }
    });

    this.getAllData().subscribe(responses => {


      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (responses[0].model.length > 0) {
            this.billingStatusList = responses[0].model;
          }
          else {

          }
        }

      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (responses[1].model.length > 0) {
            this.requiresBackupInvoices = responses[1].model;
          }
          else {

          }
        }

      }


      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {

          let customer = new CustomerModel();
          customer = responses[2].model;
          this.accountId = customer.accountID;
          this.selectedBillingStatusId = customer.billingStatusId;
          this.selectedRequiresBackupInvoicesId = customer.requiresBackupInvoicesId;
          this.referencePO = customer.referencePO;

        }
        else {

        }


      }
      this.initControls();
      this.checkFormControlValueChanged();
    });


  }

  getAllData(): Observable<any[]> {


    let getBillingStatusListResponse = this._commonService.getBillingStatusList<ResponseModel<BillingStatusModel>>();
    let getRequiresBackupInvoicesResponse = this._commonService.getRequiresBackupInvoicesList<ResponseModel<RequiresBackupInvoicesModel>>();
    let getCustomerResponse = this._customerService.getCustomerBycId<ResponseModel<CustomerModel>>(this.customerId);
    return forkJoin([getBillingStatusListResponse, getRequiresBackupInvoicesResponse, getCustomerResponse]);

  }

  checkCustomerAccountID() {
    this.validateCustomerAccountID().subscribe(result => {

    });
  }
  validateCustomerAccountID(): Observable<boolean> {
    return new Observable<boolean>(ob => {
      let f: boolean = true;
      this.accountID_msg = "";
      this.accountID_good = "";
      let accountID = this.f.accounting_id.value;
      if (accountID.length != 3) {
        this.accountID_msg = "Account ID must be 3 characters.";
        f = false;
        ob.next(false);
      }
      if (f) {
        let regexp: RegExp = /^[a-zA-Z0-9]*$/;
        if (!regexp.test(accountID)) {
          this.accountID_msg = "Incorrect format.";
          f = false;
          ob.next(false);
        }
      }
      if (f) {
        this._customerService.checkCustomerAccountID<ResponseModel<number>>(accountID, this.customerId).subscribe(response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              if (response.model == 0) {
                this.accountID_good = "Account ID is available.";
                ob.next(true);
              }
              else {
                this.accountID_msg = "Account ID is not available.";
                ob.next(false);
              }
            }
          }
        });
      }
    });
  }

  clickEditAccounting(event: any, acctProfile: CustomerAccountingProfileModel) {
    let s = "";
    let customerAccountingProfileGUID = "";
    if (acctProfile != null) {
      customerAccountingProfileGUID = acctProfile.customerAccountingProfileGUID;
    }
    if (this.acctType == 'billTo') {
      s = "Setup Bill To Accounts";
    }
    else {
      s = "Customize PO# Reference by Registrations, Services, Crew, or Pax";
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //dialogConfig.width = "43em";//"1000px";
    //dialogConfig.height = "22em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, customerGUID: this.customerId, customerAccountingProfileGUID: customerAccountingProfileGUID, acctType: this.acctType };
    const dialogRef = this._dialog.open(CustomerAccountingProfileEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();

      }

    });


  }

  get f() { return this.customer_accounting_profile_form.controls; }

  setAcctType(event: any) {

    this.acctType = this.f.acctType.value;
    if (this.acctType == 'billTo') {
      this.displayedColumn = ['bill_to_id', 'bill_to_account', 'registration', 'service', 'crew', 'pax',
        'remarks', 'disabled', 'action'];
    }
    else {
      this.displayedColumn = ['reference_po', 'registration', 'service', 'crew', 'pax',
        'effective_date', 'expiration_date', 'remarks', 'disabled', 'action'];
    }
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getData();
  }


  checkFormControlValueChanged(): void {
    this.customer_accounting_profile_form.valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
  }

  saveAccountingData() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.validateCustomerAccountID().subscribe(result => {
      if (result == false)
        return;
      else {



        this.accountID_msg = "";
        this.accountID_good = "";

        this.loading = true;
        let obj = new CustomerModel();
        obj.customerGUID = this.customerId;
        obj.accountID = this.f.accounting_id.value;
        obj.requiresBackupInvoicesId = this.f.requires_backup_invoices.value;
        obj.billingStatusId = this.f.billing_status.value;
        obj.referencePO = this.f.account_reference_po.value;
        obj.pageName = "accounting";


        this._customerService.saveCustomer<ResponseModel<string>>(obj).subscribe(response => {
          if (response != null && response.code == "200") {
            if (response.model != "") {
              this.customerId = response.model;
              //this._dialogService.openAlertDialog("Your information has been submitted successfully.");
              this.isModified = true;
              let d = new Date();
              let m = d.getMonth() + 1;
              let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString().padStart(2, "0");
              this.modifiedBy = localStorage.getItem('un');
              this.modifiedDate = dt;
              this.getModifiedBy.emit(this.modifiedBy);
              this.getModifiedDate.emit(this.modifiedDate);
              this.controlValueChanged = false;
              this.showSuccessMsg = true;
              setTimeout(() => {
                this.showSuccessMsg = false;
              }, 1000);
              //this._dialogRef.close(this.isModified);

            }
            else {
              this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
              //this._dialogRef.close(this.isModified);
            }
          }
          else {
            if (response.code == "401") {
              //this.errMsg = response.message
              this._authService.signOut();
            }
            else {
              this.errMsg = "Failed to save the record. Please try again later.";
            }
          }
          this.loading = false;
        });
      }
    });
  }
}
