import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, Optional, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
//import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomValidators } from '../common-utility/custom.validators';
import { AirportModel } from '../models/airport.model';
import { CountryModel } from '../models/country.model';
import { FuelPricingModel } from '../models/fuel-pricing.mode';
import { FuelSupplierModel } from '../models/fuelsupplier.model';
import { IntoPlaneAgentModel } from '../models/intoplaneagent.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { FuelService } from '../services/fuel.service';
import { Title } from '@angular/platform-browser';
import { FuelpricingComponent } from '../fuelpricing/fuelpricing.component';
import { ReturnObjModel } from '../models/return-obj.model'; 
import { FuelCommissionsModel } from '../models/fuel-commissions.model';  
import { FuelCommissionsEditDialogComponent } from './fuel-commissions-edit-dialog.component';
import { FuelMenuComponent } from './fuel-menu.component';
//import { BusinessRulesEditComponent } from './business-rules-edit.component';

@Component({
  selector: 'app-fuel-commissions',
  templateUrl: './fuel-commissions.component.html',
  styleUrls: ['./fuel-commissions.component.css']
})

export class FuelCommissionsComponent implements OnInit {
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),

  });

  fuel_Commissions_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg1: string;
  msg: string;
  showSpin: boolean = false;


  fuelCommissionslist: FuelCommissionsModel[];
  matfuelCommissionslist = new MatTableDataSource<FuelCommissionsModel>();
  selection = new SelectionModel<FuelCommissionsModel>(true, []);

  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  selectedTabName: string;
  reloadChildPage: boolean;
  totalRecordsText: string;
  totalRecords: number;
  pageSize: number = 15;
  totalPageNumber: number = 0;
  v: number = this._authService.getCurrentTimeNumber();
  startDate: Date;
  endDate: Date;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  pageFrom: string = "";
  sort_by: string;
  grantAccessToFuel: boolean = false;



 

  showFuelSupplierList: boolean = false;
  selectedFuelSupplierList: FuelSupplierModel[];
  selectedFuelSupplierCountText: string = "";
  filteredFuelSupplierList: Observable<FuelSupplierModel[]>; 
  fuelsupplierList: FuelSupplierModel[];

  showIntoPlaneAgentList: boolean = false;
  selectedIntoPlaneAgentList: IntoPlaneAgentModel[];
  selectedIntoPlaneAgentCountText: string = "";
  filteredIntoPlaneAgentList: Observable<IntoPlaneAgentModel[]>; 
  intoplaneagentList: IntoPlaneAgentModel[];
   

  showAirportList: boolean = false;  
  selectedAirportList: AirportModel[]; 
  selectedAirportCountText: string = "";
  filteredAirportList: Observable<AirportModel[]>;  
  airportList: AirportModel[];
   

  //service: string = "service_class"; 
  showCountryList: boolean = false;
  selectedCountryList: CountryModel[];
  selectedCountryCountText: string = "";
  filteredCountryList: Observable<CountryModel[]>;
  countryList: CountryModel[];
   
  includeDisabled: boolean = false;

  includeDisabledRecords: boolean;
  displayExipredCommissions: boolean;
  sortedData: FuelCommissionsModel[];
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;


  displayedColumn: string[] =/*'action1',*/['Country', 'ICAO', 'IATA', 'fuelSupplier', 'intoplaneagent', 'commissionAmt', 'EffectiveDate', 'ExpiryDate',
    'InternalNotes', 'ModifiedDate', 'action'];
    //'Qty', 'UnitofMeasure',
    //'CurrencyID', 'BaseFuel', 'TaxesIncluded',  'ClientNotes',];
  fromFuelPricingSummary: boolean;
  websiteEnvironment: string = "";
  currentPageIndex: number = 0;


  iscountryorairport: string = "country";
  isDialog: boolean = false;
 


  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _fuelService: FuelService,
    private readonly _dialogService: DialogService, private _titleService: Title,
    @Optional() private readonly _dialogRef: MatDialogRef<FuelCommissionsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    //private readonly _dialogRef: MatDialogRef<FuelpricingComponent>,
  ) {

    if (this.data) {
      this.isDialog = true;
    }
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToFuel = false;
    if (localStorage.getItem('up.fuel') == 'true') {
      this.grantAccessToFuel = true;
    }


    if (!this.grantAccessToFuel) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.errMsg = "";
    this.errMsg1 = "";
    this.msg = "";
    this.totalRecordsText = "";



    this.fuelsupplierList = [];
    this.selectedFuelSupplierList = [];

    this.intoplaneagentList = [];
    this.selectedIntoPlaneAgentList = [];

    this.countryList = [];
    this.selectedCountryList = [];

    this.airportList = [];
    this.selectedAirportList = [];

    //this.clientList = [];
    //this.selectedClientList = [];

    this.sortedData = [];
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate());


    this.initControls();

    this.clickSubmit();

  }

  initControls() {
    this.fuel_Commissions_form = this._formBuilder.group({
      fuelsuppliersearch: [''],
      clientsearch: [''],
      countrysearch: [''],
      airportSearch: [''],
      intoplaneagentsearch:[''],
      sort_by: [this.sort_by],
      displayExipredCommissions: [this.displayExipredCommissions],



      includeDisabledRecords: [this.includeDisabledRecords]
    });
  }

  get f() { return this.fuel_Commissions_form.controls; }

  dateOnchange(dateType: string, e: any) {
    if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidStartDate = false;
          this.f.startDate.setErrors({ isValidDate: false });
        }
        else {
          this.isValidStartDate = true;
        }
      }
      else {
        this.isValidStartDate = true;
      }
    }

    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidEndDate = false;
          this.f.endDate.setErrors({ isValidDate: false })
        }
        else {
          this.isValidEndDate = true;
        }
      }
      else {
        this.isValidEndDate = true;
      }
    }

    this.clickSubmit();

  }

  sortByChange(e: any) {
    this.clickSubmit();
  }

  // fuelSupplier
  clickFuelSupplierFilter() {
    this.getFuelSupplierList();
  }

  getFuelSupplierList() {

    this.showFuelSupplierList = true;
    this.showAirportList = false;
    this.showCountryList = false;
    this.showIntoPlaneAgentList = false;

    this.fuelsupplierList = [];
    let request = new FuelCommissionsModel();// new FuelSupplierModel();
    

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }


    request.isActive = !this.f.includeDisabledRecords.value;
    request.isExpired = this.f.displayExipredCommissions.value == null ? false : this.f.displayExipredCommissions.value;
    this._fuelService.getFuelCommissionsFuelSupplierList<ResponseModel<FuelSupplierModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new FuelSupplierModel();

          response.model.forEach(x => {
            a = new FuelSupplierModel();
            a.fuelSupplierGUID = x.fuelSupplierGUID;
            a.fuelSupplierShortname = x.fuelSupplierShortname;
            // a.selected = false;
            if (this.selectedFuelSupplierList.length > 0) {
              let f: FuelSupplierModel[];
              f = this.selectedFuelSupplierList.filter(y => y.fuelSupplierGUID == x.fuelSupplierGUID);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.fuelsupplierList.push(a);
          });
          this.fuelsupplierList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.fuelSupplierShortname.localeCompare(b.fuelSupplierShortname));
          a = new FuelSupplierModel();
          a.fuelSupplierGUID = "";// -1;
          a.fuelSupplierShortname = "Select All";
          a.selected = false;
          this.fuelsupplierList.unshift(a);
          this.filteredFuelSupplierList = this.f.fuelsuppliersearch.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.fuelSupplierShortname)),
            map(fuelSupplierShortname => (fuelSupplierShortname ? this._filterFuelSupplier(fuelSupplierShortname) : this.fuelsupplierList.slice())),
          );
          this.setFuelSupplierList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameFuelSupplier(fs: FuelSupplierModel): string {
    return fs && fs.fuelSupplierShortname ? fs.fuelSupplierShortname : '';
  }

  private _filterFuelSupplier(name: string): FuelSupplierModel[] {
    const filterValue = name.toLowerCase();

    return this.fuelsupplierList.filter(option => option.fuelSupplierShortname.toLowerCase().includes(filterValue));
  }

  checkFuelSupplierChange(e: any, item: FuelSupplierModel) {
    if (item.fuelSupplierGUID == '') {
      this.fuelsupplierList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.fuelsupplierList.forEach(x => {
        if (item.fuelSupplierGUID == x.fuelSupplierGUID) {
          x.selected = e.checked;
        }
      });
    }

    this.setFuelSupplierList();
  }

  setFuelSupplierList() {
    this.selectedFuelSupplierList = this.fuelsupplierList.filter(u => u.selected == true);
    this.selectedFuelSupplierCountText = "";
    if (this.fuelsupplierList.length > 0) {
      if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
        this.selectedFuelSupplierCountText = ": All";
      }
      else {
        if (this.selectedFuelSupplierList.length > 0)
          this.selectedFuelSupplierCountText = ": " + this.selectedFuelSupplierList.length.toString();
      }
    }
  }

  clickDoneFuelSupplier() {
    this.showFuelSupplierList = false;
    this.clickSubmit();
  }

  clickResetFuelSupplier() {
    this.fuelsupplierList.forEach(x => {
      x.selected = false;
    });
    this.f.fuelsuppliersearch.setValue("");
    this.setFuelSupplierList();
    this.clickSubmit();
  }
  // fuelSupplier end

  // IntoPlaneAgent
  clickIntoPlaneAgentFilter() {
    this.getIntoPlaneAgentList();
  }

  getIntoPlaneAgentList() {
    this.showIntoPlaneAgentList = true;
    this.showAirportList = false;
    this.showCountryList = false;
    this.showFuelSupplierList = false;
    this.intoplaneagentList = [];
    let request = new FuelCommissionsModel();// new FuelSupplierModel();
  



    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    request.isActive = !this.f.includeDisabledRecords.value;
    request.isExpired = this.f.displayExipredCommissions.value == null ? false : this.f.displayExipredCommissions.value;
    this._fuelService.getFuelCommissionsIntoPlaneAgentList<ResponseModel<IntoPlaneAgentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new IntoPlaneAgentModel();

          response.model.forEach(x => {
            a = new IntoPlaneAgentModel();
            a.intoPlaneAgent = x.intoPlaneAgent;
            //a.fuelSupplierShortname = x.fuelSupplierShortname;
            // a.selected = false;
            if (this.selectedIntoPlaneAgentList.length > 0) {
              let f: IntoPlaneAgentModel[];
              f = this.selectedIntoPlaneAgentList.filter(y => y.intoPlaneAgent == x.intoPlaneAgent);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.intoplaneagentList.push(a);
          });
          this.intoplaneagentList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.intoPlaneAgent.localeCompare(b.intoPlaneAgent));
          a = new IntoPlaneAgentModel();
          a.intoPlaneAgent = "";// -1;
          a.intoPlaneAgent = "Select All";
          a.selected = false;
          this.intoplaneagentList.unshift(a);
          this.filteredIntoPlaneAgentList = this.f.intoplaneagentsearch.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.intoPlaneAgent)),
            map(intoPlaneAgent => (intoPlaneAgent ? this._filterIntoPlaneAgent(intoPlaneAgent) : this.intoplaneagentList.slice())),
          );
          this.setIntoPlaneAgentList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameIntoPlaneAgent(intoplaneagent: IntoPlaneAgentModel): string {
    return intoplaneagent && intoplaneagent.intoPlaneAgent ? intoplaneagent.intoPlaneAgent : '';
  }

  private _filterIntoPlaneAgent(name: string): IntoPlaneAgentModel[] {
    const filterValue = name.toLowerCase();

    return this.intoplaneagentList.filter(option => option.intoPlaneAgent.toLowerCase().includes(filterValue));
  }

  checkIntoPlaneAgentChange(e: any, item: IntoPlaneAgentModel) {
    if (item.intoPlaneAgent == 'Select All') {
      this.intoplaneagentList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.intoplaneagentList.forEach(x => {
        if (item.intoPlaneAgent == x.intoPlaneAgent) {
          x.selected = e.checked;
        }
      });
    }

    this.setIntoPlaneAgentList();
  }

  setIntoPlaneAgentList() {
    this.selectedIntoPlaneAgentList = this.intoplaneagentList.filter(u => u.selected == true);
    this.selectedIntoPlaneAgentCountText = "";
    if (this.intoplaneagentList.length > 0) {
      if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
        this.selectedIntoPlaneAgentCountText = ": All";
      }
      else {
        if (this.selectedIntoPlaneAgentList.length > 0)
          this.selectedIntoPlaneAgentCountText = ": " + this.selectedIntoPlaneAgentList.length.toString();
      }
    }
  }

  clickDoneIntoPlaneAgent() {
    this.showIntoPlaneAgentList = false;
    this.clickSubmit();
  }

  clickResetIntoPlaneAgent() {
    this.intoplaneagentList.forEach(x => {
      x.selected = false;
    });
    this.f.intoplaneagentsearch.setValue("");
    this.setIntoPlaneAgentList();
    this.clickSubmit();
  }
  // IntoPlaneAgent end

  //country
  clickCountryFilter() {
    this.getCountryList();
  }

  getCountryList() {
    this.showCountryList = true;
    this.showFuelSupplierList = false;
    this.showIntoPlaneAgentList = false;
    this.showAirportList = false;
    
    


    this.countryList = [];
    let request = new FuelCommissionsModel();// new FuelSupplierModel();
   // request.effectiveDate = "";

    //if (this.f.startDate.value != null) {
    //  let templocal1: Date = new Date(this.f.startDate.value);
    //  let temputc1: Date;
    //  temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
    //  request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    //}

    //request.expirationDate = "";
    //if (this.f.endDate.value != null) {
    //  let templocal2: Date = new Date(this.f.endDate.value);
    //  let temputc2: Date;
    //  temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
    //  request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    //}


    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }
     

    request.isActive = !this.f.includeDisabledRecords.value;
    request.isExpired = this.f.displayExipredCommissions.value == null ? false : this.f.displayExipredCommissions.value;
    this._fuelService.getFuelCommissionsCountryList<ResponseModel<CountryModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let c = new CountryModel();

          response.model.forEach(x => {
            c = new CountryModel();
            c.countryName = x.countryName
            c.countryGUID = x.countryGUID;
            c.countryID = x.countryID;
            c.selected = false;
            if (this.selectedCountryList.length > 0) {
              let f: CountryModel[];
              f = this.selectedCountryList.filter(y => y.countryID == x.countryID );
              //f = this.selectedCountryList.filter(y => y.countryGUID.toLowerCase() == x.countryGUID.toLowerCase());
              if (f.length > 0) {
                c.selected = true;
              }
            }
            this.countryList.push(c);
          });
          this.countryList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.countryName.localeCompare(b.countryName));
          c = new CountryModel();
          c.countryID = 0;
          //c.countryGUID = "all";
          c.countryName = "Select All";
          c.selected = false;
          this.countryList.unshift(c);
          this.filteredCountryList = this.f.countrysearch.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.countryName)),
            map(countryName => (countryName ? this._filterCountry(countryName) : this.countryList.slice())),
          );
          this.setCountryList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameCountry(country: CountryModel): string {
    return country && country.countryName ? country.countryName : '';
  }

  private _filterCountry(name: string): CountryModel[] {
    const filterValue = name.toLowerCase();

    return this.countryList.filter(option => option.countryName.toLowerCase().includes(filterValue));
  }

  checkCountryChange(e: any, item: CountryModel) {
    if (item.countryGUID == 'all') {
      this.countryList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.countryList.forEach(x => {
        if (item.countryGUID == x.countryGUID) {
          x.selected = e.checked;
        }
      });
    }
    this.setCountryList();
  }

  setCountryList() {
    this.selectedCountryList = this.countryList.filter(u => u.selected == true);
    this.selectedCountryCountText = "";
    if (this.countryList.length > 0) {
      if (this.selectedCountryList.length == this.countryList.length) {
        this.selectedCountryCountText = ": All";
      }
      else {
        if (this.selectedCountryList.length > 0)
          this.selectedCountryCountText = ": " + this.selectedCountryList.length.toString();
      }
    }
  }

  clickDoneCountry() {
    this.showCountryList = false;
    this.clickSubmit();
  }

  clickResetCountry() {
    this.countryList.forEach(x => {
      x.selected = false;
    });
    this.f.countrysearch.setValue("");
    this.setCountryList();
    this.clickSubmit();
  }
  //country end

  // airport
  clickAirportFilter() {
    this.getAirportList();
  }

  getAirportList() {

    this.showAirportList = true;
    this.showFuelSupplierList = false; 
    this.showIntoPlaneAgentList = false; 
    this.showCountryList = false; 
    
    
    this.airportList = [];
    let request = new FuelCommissionsModel();// new FuelSupplierModel();
   

    //if (this.f.startDate.value != null) {
    //  let templocal1: Date = new Date(this.f.startDate.value);
    //  let temputc1: Date;
    //  temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
    //  request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    //}

    //request.expirationDate = "";
    //if (this.f.endDate.value != null) {
    //  let templocal2: Date = new Date(this.f.endDate.value);
    //  let temputc2: Date;
    //  temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
    //  request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    //}



    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }
     

    request.isActive = !this.f.includeDisabledRecords.value;
    request.isExpired = this.f.displayExipredCommissions.value == null ? false : this.f.displayExipredCommissions.value;
    this._fuelService.getFuelCommissionsAirportList<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new AirportModel();

          response.model.forEach(x => {
            a = new AirportModel();
            a.icao = x.icao;
            a.airportID = x.airportID;
            a.selected = false;
            if (this.selectedAirportList.length > 0) {
              let f: AirportModel[];
              f = this.selectedAirportList.filter(y => y.airportID == x.airportID);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.airportList.push(a);
          });
          this.airportList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.icao.localeCompare(b.icao));
          a = new AirportModel();
          a.airportID = -1;
          a.icao = "Select All";
          a.selected = false;
          this.airportList.unshift(a);
          this.filteredAirportList = this.f.airportSearch.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.airportName)),
            map(airportName => (airportName ? this._filterAirport(airportName) : this.airportList.slice())),
          );
          this.setAirportList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameAirport(airport: AirportModel): string {
    return airport && airport.icao ? airport.icao : '';
  }

  private _filterAirport(name: string): AirportModel[] {
    const filterValue = name.toLowerCase();

    return this.airportList.filter(option => option.icao.toLowerCase().includes(filterValue));
  }

  checkAirportChange(e: any, item: AirportModel) {
    if (item.airportID == -1) {
      this.airportList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.airportList.forEach(x => {
        if (item.airportID == x.airportID) {
          x.selected = e.checked;
        }
      });
    }

    this.setAirportList();
  }

  setAirportList() {
    this.selectedAirportList = this.airportList.filter(u => u.selected == true);
    this.selectedAirportCountText = "";
    if (this.airportList.length > 0) {
      if (this.selectedAirportList.length == this.airportList.length) {
        this.selectedAirportCountText = ": All";
      }
      else {
        if (this.selectedAirportList.length > 0)
          this.selectedAirportCountText = ": " + this.selectedAirportList.length.toString();
      }
    }
  }

  clickDoneAirport() {
    this.showAirportList = false;
    this.clickSubmit();
  }

  clickResetAirport() {
    this.airportList.forEach(x => {
      x.selected = false;
    });
    this.f.airportSearch.setValue("");
    this.setAirportList();
    this.clickSubmit();
  }
  // airport end
   

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.fuelCommissionslist.length; //this.matfuelCommissionslist.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.fuelCommissionslist);// this.matfuelCommissionslist.data);
  }

  checkBox(row: any) {
    this.selection.toggle(row);

  }

  includeDisabledRecordsOnChange(event: any) {
    //let x = event.target.checked;
    //if (this.userType == "internal") {
    //if (this.f.customer_select.value == "") {
    //  this.errMsg = "Please select a client";
    //  return;
    //}
    // }
    // this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.clickSubmit();
  }

  displayExipredCommissionsOnChange(event: any) {
    //let x = event.target.checked;
    //if (this.userType == "internal") {
    //if (this.f.customer_select.value == "") {
    //  this.errMsg = "Please select a client";
    //  return;
    //}
    // }
    // this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.clickSubmit();
  }

 
  clickSubmit() {
    this.submitted = true;
    this.errMsg = "";
    if (this.fuel_Commissions_form.invalid || this.errMsg != "") {
      return;
    }

    //if (this.showClientList) {
    //  this.getClientList();
    //}
    //if (this.showAircraftList) {
    //  this.getAircraftList();
    //}

    if (this.showFuelSupplierList) {
      this.getFuelSupplierList();
    }
    if (this.showIntoPlaneAgentList) {
      this.getIntoPlaneAgentList();
    }
    if (this.showCountryList) {
      this.getCountryList();
    }
    if (this.showAirportList) {
      this.getAirportList();
    } 
    //if (this.showPersonList) {
    //  this.getTripPersonList();
    //}
    //if (this.showVendorList) {
    //  this.getVendorList();
    //}

    this.getData();
  }

  getData(isInit: boolean = false) {
    this.showSpin = true;
    let request = new FuelCommissionsModel();// new FuelSupplierModel();
    


    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }
    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryID != 0);
      request.selectedCountryIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryID).toString();
      //this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      //request.selectedCountryIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }
      

    request.isActive = true;
    if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      request.isActive = !this.f.includeDisabledRecords.value;
    }
    //request.isExpired = true;
    //if (this.f.displayExipredCommissions.value != null && this.f.displayExipredCommissions.value != "") {
    //  request.isExpired = this.f.displayExipredCommissions.value;
    //}

    request.isExpired = this.f.displayExipredCommissions.value == null ? false : this.f.displayExipredCommissions.value;

     
    if (this.paginator == undefined) {
      request.pageIndex = 0;
      request.pageSize = 25;
    }
    else {
      request.pageIndex = this.paginator.pageIndex;
      request.pageSize = this.paginator.pageSize;
    }
    this._fuelService.getFuelCommissionsByConditions<ResponseModel<FuelCommissionsModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.fuelCommissionslist = response.model;
          this.sortedData = this.fuelCommissionslist.slice();
          if (response.model.length > 0) {
            if (this.fuelCommissionslist != null) {
              this.totalRecords = this.fuelCommissionslist[0].totalRecords;
              this.totalRecordsText = this.totalRecords + " total records"
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
      this.showSpin = false;
    });
  }

  clickEdit(event: any, fcm: FuelCommissionsModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    let fuelCommissionsID = 0;
    let dialogTitle = "Add";
    if (fcm != null) {
      fuelCommissionsID = fcm.fuelCommissionID;
      dialogTitle = "Edit";
      dialogConfig.autoFocus = false;

    }
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { fuelCommissionsID: fuelCommissionsID, dialogTitle: dialogTitle };


    const dialogRef = this._dialog.open(FuelCommissionsEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //if (this.sortedData.length == 0)
          //this.f.brID.setValue(result);
        this.getData();
      }
    });
    //  let navigationExtras: NavigationExtras = {
    //    queryParams: {
    //      "businessRulesID": br.businessRulesID        

    //    }
    //  };
    //this._router.navigate(['/business-rules/business-rules-edit'], navigationExtras);
  }

  clickReset() { 
    this.showFuelSupplierList = false;
    this.showIntoPlaneAgentList = false;
    this.showCountryList = false;
    this.showAirportList = false;
    
    

    this.includeDisabledRecords = false;
    this.selection.clear();
     

    this.selectedFuelSupplierList = [];
    this.selectedIntoPlaneAgentList = [];
    this.selectedCountryList = [];
    this.selectedAirportList = [];

    this.selectedFuelSupplierCountText = "";
    this.selectedIntoPlaneAgentCountText = "";
    this.selectedCountryCountText = "";
    this.selectedAirportCountText = ""; 
  
    this.sortedData = [];

    //this.clickSubmit();

    this.initControls();
    this.getData();
  }

  sortData(sort: Sort) {
    const data = this.fuelCommissionslist.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fuelCommissionsGUID':
          return compare(a.fuelCommissionID, b.fuelCommissionID, isAsc); 
        //case 'CountryName':
        //  return compare(a.countryName == null ? 'All' : a.countryName, b.countryName == null ? 'All' : b.countryName, isAsc);
        //case 'ICAO':
        //  return compare(a.icao == null ? 'All' : a.icao, b.icao == null ? 'All' : b.icao, isAsc);
        //case 'FuelSuppliershortName':
        //  return compare(a.fuelSupplierName == null ? 'All' : a.fuelSupplierName, b.fuelSupplierName == null ? 'All' : b.fuelSupplierName, isAsc);
        //case 'IntoPlaneAgent':
        //  return compare(a.intoPlaneAgent == null ? 'All' : a.intoPlaneAgent, b.intoPlaneAgent == null ? 'All' : b.intoPlaneAgent, isAsc);

        default:
          return 0;
      }
    });

    function compare(a: number | string | Date | boolean, b: number | string | Date | boolean, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    //this.currentPageIndex = e.pageIndex;
    //if (this.pageSize != e.pageSize) {
    //  this.totalRecords = 0;
    //  this.totalRecordsText = "";
    //  this.totalPageNumber = 0;
    //  this.pageSize = e.pageSize;
    //this.request.pageSize = this.pageSize;
    //this.request.totalRecords = this.totalRecords;
    //}
    // this.request.pageIndex = this.currentPageIndex;
    this.clickSubmit();

  }

  importFuelPricing() {
    let s = "";
    //  const dialogConfig = new MatDialogConfig();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    this.fromFuelPricingSummary = true;
    // dialogConfig.hasBackdrop = false;


    dialogConfig.data = {
      dialogTitle: s, fromFuelPricingSummary: this.fromFuelPricingSummary
    };


    const dialogRef = this._dialog.open(FuelpricingComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }

  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;


    //  this._dialogRef.close();

    //this._dialogRef.close(this.isModified);
  }


  CloseFuelSupplierList() {
    this.showFuelSupplierList = false;
    this.clickResetFuelSupplier();
  }

  CloseIntoPlaneAgentList() {
    this.showIntoPlaneAgentList = false;
    this.clickResetIntoPlaneAgent();
  }

  CloseCountryList() {
    this.showCountryList = false;
    this.clickResetCountry();
  }

  CloseAirportList() {
    this.showAirportList = false;
    this.clickResetAirport();
  }


  clickRoute(routePage: string) {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px"};

    if(this.isDialog){
      // this.clickclose();
      this._dialogRef.close();
    }
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(FuelMenuComponent, config);   
  }

  clickOpenInNew( ){
    this._dialogRef.close(true);
    window.open('fuelmenu/fuel-commissions' + "?v=" + this._authService.getCurrentTimeNumber());
  }

}



