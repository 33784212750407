import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { CustomerModel } from '../models/customer.model';
import { ResponseModel } from '../models/response.model';
import { VendorHoursModel } from '../models/vendor-hours.model';
import { VendorNotesModel } from '../models/vendor-notes.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';
import { VendorService } from '../services/vendor.service';
import { VendorHoursEditDialogComponent } from './vendor-hours-edit-dialog.component';
import { VendorNotesEditDialogComponent } from './vendor-notes-edit-dialog.component';

@Component({
  selector: 'app-vendor-hours-list',
  templateUrl: './vendor-hours-list.component.html',
  styleUrls: ['./vendor-hours-list.component.css']
})
/** customer-note component*/
export class VendorHoursListComponent implements OnInit, OnChanges {
  vendor_hours_form: UntypedFormGroup;
  @Input() vendorGUID: string;
  errMsg: string;
  vendorId: number;
  msg: string;
  showSpin: boolean;
  userType: string;
  isActive: boolean;
  @Input() includeDisabledRecords: boolean
  recordList: VendorHoursModel[];
  displayedColumn: string[] = ['effective_date', 'expiration_date', 'hours_type', 'is24hours','sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday',
    'saturday', 'isUTC','remarks','disabled', 'action'];
  /** customer-note ctor */
  constructor(private readonly _authService: AuthenticateService, private readonly _commonService: CommonService, private readonly _dialogService: DialogService,
    private readonly _formBuilder: UntypedFormBuilder, private _route: ActivatedRoute, private _vendorService: VendorService, private readonly _dialog: MatDialog) {

  }

  ngOnInit() {


  }

  ngOnChanges(changes: SimpleChanges): void {
    this.vendor_hours_form = this._formBuilder.group({

    });

    // this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
      this.includeDisabledRecords = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getData();
  }


  initControls() {
    this.vendor_hours_form = this._formBuilder.group({

    });
  }

  get f() { return this.vendor_hours_form.controls; }

  getData() {
    let request = new VendorHoursModel();
    request.vendorGUID = this.vendorGUID;
    request.isActive = !this.includeDisabledRecords;

    this._vendorService.getVendorHoursByVendorID<ResponseModel<VendorHoursModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          if (response.model.length > 0) {
            this.recordList = response.model;

          }
          else {

          }
        }
      }
    });

  }

  clickEdit(event: any, hours: VendorHoursModel) {
    let s = "";
    let vendorHoursID = 0;
    if (hours == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      vendorHoursID = hours.vendorHoursID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, vendorGUID: this.vendorGUID, vendorHoursID: vendorHoursID };
    const dialogRef = this._dialog.open(VendorHoursEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();

      }

    });


  }


}
