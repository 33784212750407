import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DocumentTypeModel } from '../models/document-type.model';
import { ResponseModel } from '../models/response.model';

@Component({
  selector: 'app-document-type-edit',
  templateUrl: './document-type-edit.component.html',
  styleUrls: ['./document-type-edit.component.css']
})

export class DocumentTypeEditComponent implements OnInit {

  document_type_edit_form: UntypedFormGroup;
  isDialog: boolean = false;

  isCrew: boolean = false;
  isPax: boolean = false;
  isSubDocument: boolean = false;
  isVendor: boolean = false;
  isCustomer: boolean = false;
  isAircraft: boolean = false;
  isCountry: boolean = false;
  isTask: boolean = false;
  hasSubdocument: boolean = false;
  isAirport: boolean = false;
  isPermitRequiredDoc: boolean = false;
  isMiscellaneous: boolean = false;
  showSpin: boolean = false;
  documentTypeList: DocumentTypeModel;
  documentTypeID: number;
  
  isActive: boolean = true;
  sortOrderNo: number = null;
  apisSortOrderNo: number = null;
  sortOrderCountryReq: number = null;
  countryID: number = null;
  documentCode: string = null;
  title: string = '';
  sortOrder: number;
  documentTypeDescription: string = '';
  doc_type: string = '';
  showSuccessMsg: boolean;
  isModified: boolean;
  successMsg: string;
  loading: boolean = false;
  errMsg: string = '';



  constructor(  @Optional() private readonly _dialogRef: MatDialogRef<DocumentTypeEditComponent>, private readonly _commonService: CommonService,
  @Optional() @Inject(MAT_DIALOG_DATA) private data: any,  private readonly _authService: AuthenticateService, 
  private readonly _formBuilder: UntypedFormBuilder,
    ) {
   
      if (this.data) {
        this.isDialog = true;
        this.documentTypeID = data.documentTypeID;
        this.doc_type = data.doc_type;
        // this.username = this.data.username;
      }  
      if(data.documentTypeID == 0){
        this.title = 'Add';
      }
      else{
        this.title = 'Edit';
      }
   
  }

  ngOnInit() {

    // this._authService.updateAccessTime();

    this.getData();
    this.initControls();


   }

  initControls() {
    this.document_type_edit_form = this._formBuilder.group({
      // userComments: [this.userComments]
        isCrew: [this.isCrew],
        isPax: [this.isPax] ,
        isSubDocument: [this.isSubDocument],
        isVendor: [this.isVendor],
        isCustomer: [this.isCustomer],
        isAircraft: [this.isAircraft],
        isCountry: [this.isCountry],
        isTask: [this.isTask],
        hasSubdocument: [this.hasSubdocument],
        isAirport: [this.isAirport],
        isPermitRequiredDoc: [this.isPermitRequiredDoc],
        isMiscellaneous: [this.isMiscellaneous],
        
        sortOrder: [this.sortOrder],

        sortOrderNo: [this.sortOrderNo],
        apisSortOrderNo: [this.apisSortOrderNo],
        sortOrderCountryReq: [this.sortOrderCountryReq],
 
        doc_code: [this.documentCode],
        isActive: [this.isActive],
        doc_name: [this.documentTypeDescription],
 

    });


  }

  get f() { return this.document_type_edit_form.controls; }


 

  clickClose(val) {
    this._dialogRef.close(val);


  }

  
  getData(){

 
    let request = new DocumentTypeModel();     
 
    // request.docType = this.f.doc_type.value;
    request.documentTypeID = this.documentTypeID;
    this.showSpin = true;

    if(this.documentTypeID != 0){
      
      let getUpcomingTripCloseoutListResponse = this._commonService.getAllDocumentTypeList<ResponseModel<DocumentTypeModel>>(request).subscribe(response => {

        // console.log(response)
        if (response != null) {
          if (response.code == "200" && response.message == "") {

            this.documentTypeList = response.model;   
             
            this.isCrew = this.documentTypeList[0].isCrew;
            this.isPax = this.documentTypeList[0].isPax;
            this.isSubDocument = this.documentTypeList[0].isSubDocument;
            this.isVendor = this.documentTypeList[0].isVendor;
            this.isCustomer = this.documentTypeList[0].isCustomer;
            this.isAircraft = this.documentTypeList[0].isAircraft;
            this.isCountry = this.documentTypeList[0].isCountry;
            this.isTask = this.documentTypeList[0].isTask;
            this.hasSubdocument = this.documentTypeList[0].hasSubdocument;
            this.isAirport = this.documentTypeList[0].isAirport;
            this.isPermitRequiredDoc = this.documentTypeList[0].isPermitRequiredDoc;
            this.isMiscellaneous = this.documentTypeList[0].isMiscellaneous;          
            this.isActive = this.documentTypeList[0].isActive;
            this.sortOrderNo = this.documentTypeList[0].sortOrderNo;
            this.apisSortOrderNo = this.documentTypeList[0].apisSortOrderNo; 
            this.sortOrderCountryReq = this.documentTypeList[0].sortOrderCountryReq;
            this.countryID = this.documentTypeList[0].countryID;
            this.documentCode = this.documentTypeList[0].documentCode; 
            this.documentTypeDescription = this.documentTypeList[0].documentTypeDescription; 

            if(this.doc_type == 'IsCrew'){
              this.sortOrder = this.sortOrderNo;
            }

            if(this.doc_type == 'IsPax'){
              this.sortOrder = this.apisSortOrderNo;
            }
            if(this.doc_type == 'IsPermitRequiredDoc' || this.doc_type == 'IsMiscellaneous'){
              this.sortOrder = this.sortOrderCountryReq;
            }
            this.initControls();
            this.showSpin = false;

          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          }
        }

      });
      
    }

  }



  
  clickSave() {
    this.errMsg = "";
    this._authService.updateAccessTime();

      
    this.loading = true;
    let obj = new DocumentTypeModel();


    obj.documentTypeDescription = this.f.doc_name.value.trim();


    obj.documentTypeID = this.documentTypeID;
    obj.isCrew = this.f.isCrew.value;
    obj.isPax = this.f.isPax.value;
    obj.isSubDocument = this.f.isSubDocument.value;
    obj.isVendor = this.f.isVendor.value;
    obj.isCustomer = this.f.isCustomer.value;
    obj.isAircraft = this.f.isAircraft.value;
    obj.isCountry = this.f.isCountry.value;
    obj.isTask = this.f.isTask.value;
    obj.hasSubdocument = this.f.hasSubdocument.value;
    obj.isAirport = this.f.isAirport.value;
    obj.isPermitRequiredDoc = this.f.isPermitRequiredDoc.value;
    obj.isMiscellaneous = this.f.isMiscellaneous.value;
    obj.documentCode = this.f.doc_code.value;
    
    
    obj.isActive = this.f.isActive.value;
    
  
    if(this.title == 'Edit'){
      if(this.doc_type == 'IsCrew'){
        obj.sortOrderNo = this.f.sortOrder.value;
      }
  
      if(this.doc_type == 'IsPax'){
        obj.apisSortOrderNo = this.f.sortOrder.value;
      }
      if(this.doc_type == 'IsPermitRequiredDoc' || this.doc_type == 'IsMiscellaneous'){
        obj.sortOrderCountryReq = this.f.sortOrder.value;
      }
    }
    else{
      obj.sortOrderNo = this.f.sortOrderNo.value;
      obj.apisSortOrderNo = this.f.apisSortOrderNo.value;
      obj.sortOrderCountryReq = this.f.sortOrderCountryReq.value; 
    
    }
    
    // obj.taxesIncludedInt = this.f.taxesincludedInt.value == 'false' ? false : obj.taxesIncludedInt;

    // obj.modifiedBy = this.f.modifiedBy.value;
    // obj.modifiedDate = this.f.modifiedDate.value;



    this._commonService.saveDocumentType<ResponseModel<boolean>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {

          
          this.showSuccessMsg = true;

          this.loading = false; 
          this.isModified = true;

          if (this.title = 'Edit') {
            this.successMsg = "Document Type Updated Successfully.";
          }
          else {
            this.successMsg = "Document Type Saved Successfully.";
          } 
          setTimeout(() => {
            this.showSuccessMsg = false;
            this._dialogRef.close();
          }, 1000);


          //this._dialogRef.close(returnResponse);
          //}
        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg = "Fuel Supplier Code already exists.";
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    });
     
  }

}











 