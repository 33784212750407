import { GenDecManifestModel } from '../models/gendec-manifest.model'

export class GenDecModel {
  customerName: string;
  registration: string;
  departureICAO: string;
  arrivalICAO: string;
  flightDate: string;
  crewManifest: GenDecManifestModel[];
  paxManifest: GenDecManifestModel[];
  tripCodeGUID: string;
  groundStopGUID: string;
  isDeparture: boolean;
  includePII: boolean;
  callSign: string;
  tripCode: string;
  aircraftType: string;
}
