import { Component, Inject, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
//import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { CurrencyModel } from '../models/currency.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { DialogService } from '../services/dialog.service';
import { FuelService } from '../services/fuel.service';
import { CurrencyUploadComponent } from './currency-upload.component';
import { FuelMenuComponent } from '../fuelmenu/fuel-menu.component';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})
export class CurrencyComponent {
 currency_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = false;
  totalRecordsText: string;
  totalRecords: number = 0; 
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  currencyList: CurrencyModel[];

  currentDate: Date = new Date();

  displayedColumn: string[] = ['Currency', 'CurrencyDescription', 'ExchangeRate', 'Date', 'ModifiedBy','ModifiedDate'];
  scrHeight: number;
  websiteEnvironment: string = "";
  fromCurrencyMain: boolean;

  isOlderDate: boolean = false;


  grantAccessToFuel: boolean = false;
  isDialog: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _dialog: MatDialog,
    private readonly _fuelService: FuelService, @Optional() private readonly _dialogRef: MatDialogRef<CurrencyComponent>,
    @Optional()  @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _dialogService: DialogService, private _titleService: Title) {

      if (this._data) {
        this.isDialog = true;
      }
  }

  getAllData(): Observable<any[]> {
    let getCurrencyList = this._fuelService.getCurrencyList<ResponseModel<CurrencyModel[]>>(); 
    return forkJoin([getCurrencyList]);

  }


  ngOnInit() {
      this.grantAccessToFuel = false;
      if (localStorage.getItem('up.fuel') == 'true') {
        this.grantAccessToFuel = true;
      }

      if (!this.grantAccessToFuel) {
        this._dialogService.openAlertDialog("Permission denied.", "OK");
        return;
      }
      this._authService.updateAccessTime();

    //this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
   
    
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.currencyList = responses[0].model;
          this.totalRecords = this.currencyList.length;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }


      this.initControls();

      this.showSpin = false;

     // this.getSearchResults();


    });




  }

  initControls() {
    this.currency_form = this._formBuilder.group({
      //fuelSupplierName: [''],
      //fuelSupplierShortname: [''],
      //airport: [''],
      //city: [''],
      //country_select:[''], 
    });
  }

  get f() { return this.currency_form.controls; }

  getData(isInit: boolean = false) {
    
    
    this._fuelService.getCurrencyList<ResponseModel<CurrencyModel[]>>().subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.currencyList = response.model;
          this.totalRecords = this.currencyList.length;

        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
    });
  }


  importCurrencyList() {
    let s = "";
    //  const dialogConfig = new MatDialogConfig();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    this.fromCurrencyMain = true;
   // this.fromFuelPricingSummary = true;
    // dialogConfig.hasBackdrop = false;


    dialogConfig.data = {
      dialogTitle: s, fromCurrencyMain: this.fromCurrencyMain
    };


    const dialogRef = this._dialog.open(CurrencyUploadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      let a: any = result;
      this.getData(true);
    });
  }
  
  clickRoute(routePage: string) {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    if(this.isDialog)
      this._dialogRef.close(true);

    this._dialog.open(FuelMenuComponent, config);   
  }
   
  
   
  downloadTemplate() {


    let wb = new Workbook();
    let ws = wb.addWorksheet("Taxes And Fees Description")

    let header = ["Code" ,"Description" ,"Exchange Rate Date" ,"Exchange Rate"]
    ws.addRow(header);


    wb.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, "Currency Template.xlsx");
    });
  }


  clickRouteNew(route: string,display: string){
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }

 }
