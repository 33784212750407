<div style="margin-top:0em; padding-top:0em; display:flex"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <!-- <div   cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
    style="border-radius:5px; margin-bottom: -0.5em; margin-right: 1.4em; display: flex; justify-content: space-between;">
  
 
     <div style="display: flex;"> 
      <button  mat-icon-button mat-dialog-close> <i class="material-icons"
          style="font-size: x-large;">close </i> </button>
    </div>
  </div> -->

  <div class="divLeft">
    <div>

      <div> 
          <div>
            <div style="background-color: #f8f8f8; border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; ">
              <div style="     display: flex; justify-content: space-between;" >
                <h3>Accounting - Dashboard</h3>
                <div *ngIf="isDialog" style="display: flex;"> 
                  <button mat-icon-button    (click)="clickRouteNew('accounting/accounting-dashboard','')">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>    

                  <button  mat-icon-button mat-dialog-close> <i class="material-icons"
                      style="font-size: x-large;">close </i> </button>
                </div>
              </div>
              <div>
                <div class="form-group" style=" margin-bottom: 0.2em;">

                </div>
              

              </div>
            </div>
          </div>
          <div style="display:flex">
            <div style="width: 33em;">
              <div style=" margin-top: 1em;">
                <h4>Queues</h4>
                <hr />
               
              
                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small;width: 11.7em; height: 4em;"  (click)="clickRouteNew('current-events/trip-closeout-queue','')"> 
                  <table  style="margin-bottom: -0.6em;">
                    <tr>
                      <td> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">query_stats</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Trip Closeout</span>
                        </div>
                      </td>
                    </tr> 
                  </table>
                  </button>&nbsp;

                  <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small;width: 14em; height: 4em;"  (click)="clickRouteNew('accounting/trip-closeout-billing-completed','')"> 
                    <table  style="margin-bottom: -0.6em;">
                      <tr>
                        <td> <span class="material-symbols-outlined" style="font-size: xx-large; margin-left: -0.3em;">account_balance</span> </td>
                        <td>
                          <div style="margin-top:-0.5em; margin-left: 0.5em">
                            <span class="button-name">Billing Completed</span>
                          </div>
                        </td>
                      </tr> 
                    </table>
                    </button>&nbsp;
               

              </div>
              <div style=" margin-top: 1em;">
                <h4>Backoffice</h4>
                <hr />
                <button formcontrolname="exchangerate" mat-button class="btn-menu disableBtn"  style="font-size: small; width: 13em; height: 4em;" >
                  Vendor Fee Schedules
                </button>&nbsp;
                <button formcontrolname="fuelpricing" mat-button class="btn-menu disableBtn" style="font-size: small; width: 12em; height: 4em;"  >
                  FPC Service Pricing
                </button>&nbsp;
                
              </div>
              <div style=" margin-top: 1em;">
                <button formcontrolname="fuelpricing" mat-button class="btn-menu disableBtn" style="font-size: small; width: 15em; height: 4em;"  >
                  FPC Service Configuration
                </button>&nbsp;
                <button formcontrolname="taxesandfees" mat-button class="btn-menu disableBtn" style="font-size: small; width: 10em; height: 4em;" >
                  FPI Invoices
                </button>&nbsp;
              </div>
               

              
              <div style=" margin-top: 1em;">
                <h4>Reports</h4>
                <hr />
                <button formcontrolname="exchangerate" mat-button class="btn-menu"   (click)="clickRouteNew('/bi-dashboards/finance-bi-dashboard','')" style="font-size: small; width: 13em; height: 4em;"   >
                  Financial Dashboard
                </button>&nbsp;
                 <button formcontrolname="exchangerate" mat-button class="btn-menu "  (click)="openGtsBillableServicesComponent()" style="font-size: small; width: 13em; height: 4em;"   >
                  GTS Billable Services
                </button>&nbsp;
              <!--  <button formcontrolname="taxesandfees" mat-button class="btn-menu disableBtn" style="font-size: small; width: 13em; height: 4em;"     >
                  Expired or Missing Vendor Fee Schedules
                  
                </button>&nbsp;

                <button formcontrolname="fuelpricing" mat-button class="btn-menu disableBtn" style="font-size: small; width: 13em; height: 4em; " >
                   Incomplete Quotes
                </button>&nbsp; -->
          
              </div>

              <!-- <div style=" margin-top: -0.5em;">
                <button formcontrolname="fuelpricing" mat-button class="btn-menu disableBtn" style="font-size: small; width: 15em; height: 4em; " >
                   GTS & SSN pricing report
                </button>&nbsp;

                <button formcontrolname="fuelpricing" mat-button class="btn-menu disableBtn" style="font-size: small; width: 11em; height: 4em; " >
                   Forecast Model
                </button>&nbsp;
              </div> -->

             
            </div>

           <div>
            <div style=" margin-top: 1em; margin-left: 2em;">
              <h4>Trips Quotes</h4>
              <hr />
              <button formcontrolname="managefuelalerts" mat-button   (click)="clickRouteNew('/bi-dashboards/trip-quote-bi-dashboard','')"  class="btn-menu" style="font-size: small; width: 13em; height: 4em;" >
                Manage Trip Quotes
              </button>&nbsp;
              <button formcontrolname="clientfuelpreferences" mat-button class="btn-menu disableBtn" style="font-size: small; width: 15em; height: 4em;" >
                Third Party Taxes and Fees
              </button>&nbsp;
          

            </div>

            <div style=" margin-top: 1em; margin-left: 2em;">

              <button formcontrolname="Vendorpreferences" mat-button class="btn-menu disableBtn" style="font-size: small; width: 13em; height: 4em;" >
                Fuel Taxes and Fees
              </button>&nbsp;

            </div>
           </div>


            
          </div>

      </div>
    </div>
  </div>
</div>
