import { Component } from '@angular/core';

@Component({
  selector: 'app-leg-match-bi-dashboard',
  templateUrl: './leg-match-bi-dashboard.component.html',
  styleUrls: ['./leg-match-bi-dashboard.component.css']
})
/** airport-bi-dashboard component*/
export class LegMatchBiDashboardComponent {
  
    constructor() {

    }
}
