export class AircraftTripModel {
  aircraftGUID: string;
  registration: string;
  callSign: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  customerName: string;
  customerGUID: string;
  customerCreditStatus: string;
  groundStopTypeGUIDList: string;
  tripCloseOutID: number;
  pageSize: number;
  pageIndex: number;
  totalRecords: number;
  filterBy: string;
  textSearch: string;
  homeBaseAirport: string;
  tripCodeGUID: string;
  tas: number;
  maxRange: string;
  wingspan: string;
  bias: number;
  aircraftTypeDesignator: string;
  aircraftLength: string;
  tripCodeType: string;
  startDate: Date;
  endDate: Date;
  startDateText: string;
  endDateText: string;
  flightPlanProviderName: string;
  uplinkVendor: string;
  hasHaltServices: boolean;
}
