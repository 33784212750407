import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { VendorModel } from '../models/vendor.model';
import { CommonService } from '../services/common.service';
import { VendorDocumentModel } from '../models/vendor-document.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { VendorService } from '../services/vendor.service';
import { VendorDocumentEditDialogComponent } from './vendor-document-edit-dialog.component';
import { UtilityFunctions } from '../common-utility/utility.functions';


@Component({
  selector: 'app-vendor-documentlist',
  templateUrl: './vendor-documentlist.component.html',
  styleUrls: ['./vendor-documentlist.component.css']
})

export class VendorDocumentlistComponent implements OnInit,OnChanges {
  vendor_documentlist_form: UntypedFormGroup;
  @Input() vendorGUID: string;
  @Input() includeDisabledRecords: boolean;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  vendorList: VendorModel[];
  userType: string;
  showSpin: boolean = true;
  selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number =  15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  recordList: VendorDocumentModel[];

  //displayedColumn: string[] = ['document_name', 'preview', 'doc_type', 'vendor_svc_type', 'disabled', 'action'];
  displayedColumn: string[] = ['document_name', 'preview', 'doc_type', 'effective_date','expiration_date','disabled', 'action'];

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _vendorService: VendorService) {

  }
  ngOnInit() {


  }

  ngOnChanges(changes: SimpleChanges): void {
    this.vendor_documentlist_form = this._formBuilder.group({

    });

    // this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
      this.includeDisabledRecords = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getData();
  }

  initControls() {
    this.vendor_documentlist_form = this._formBuilder.group({
     
    });
  }

  get f() { return this.vendor_documentlist_form.controls; }

  clickEdit(event: any, doc: VendorDocumentModel) {
    
    let s = "";
    let vendorDocumentGUID = "";
    if (doc == null) {
      s = "Add New Document";
    }
    else {
      s = "Edit Document";
      vendorDocumentGUID = doc.vendorDocumentGUID;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    
    //if (this.userType == "internal") {
    //  dialogConfig.width = "65.5em";//"1000px";
    //}
    //else {
    //  dialogConfig.width = "20em";
    //}
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    if (this.userType == "internal") {
      dialogConfig.data = { dialogTitle: s, vendorGUID: this.vendorGUID, vendorDocumentGUID: vendorDocumentGUID };
    }
    else {
      dialogConfig.data = { dialogTitle: s, vendorDocumentGUID: vendorDocumentGUID };
    }
    
    const dialogRef = this._dialog.open(VendorDocumentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });

  }

  clickPreviewDoc(event: any, doc: VendorDocumentModel) {
    let vendorDocumentID = doc.vendorDocumentGUID;
    this._vendorService.getVendorDocumentByvdId<ResponseModel<VendorDocumentModel>>(vendorDocumentID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model!=null) {
          let docObj = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
          let file = new Blob([byteArray], { type: docObj.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);

        }
        else {
          this.errMsg = "No Document returned.";
        }
      }
      else {
        this.errMsg = "No Document returned.";
      }
    })
    
  }


  getData() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new VendorDocumentModel();
    request.vendorGUID = this.vendorGUID;
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = !this.includeDisabledRecords;

    this._vendorService.getVendorDocument<ResponseModel<VendorDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

          }

        }
        else {
          this.recordList = [];
          
            this.msg = "No records found.";
          
          //this.msg = "No data return.";
        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    })

  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    this.getData();

  }

  


}
