import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from 'src/app/services/dialog.service';
import { AdvisoryEditComponent } from './advisory-edit.component';
import { AdvisoryService } from '../services/advisory.service';
import { AdvisoryLookupModel, AdvisoryModel } from '../models/advisory.model';
import { ResponseModel } from '../models/response.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomValidators } from '../common-utility/custom.validators';

@Component({
  selector: 'app-/advisory-details',
  templateUrl: './advisory-details.component.html',
  styleUrls: ['./advisory-details.component.css']
})
export class AdvisoryDetailsComponent {

  advisory_details_form: UntypedFormGroup;
  isDialog: boolean = false;

  isCountry: boolean;
  isAirport: boolean;

  advisory: AdvisoryModel
  advisoryList: AdvisoryModel[] = [];
  displayedColumns: string[] = ['advisoryId', 'advisoryRating', 'effectiveDate', 'hotelName', 'advisoryType', 'issue',
    'resolution', 'isHeadlineNews', 'isPublicNews', 'advisorySourceDescription', 'action'];


  totalAdvisories: AdvisoryModel;
  countryData: AdvisoryLookupModel[] = [];
  airportData: AdvisoryLookupModel[] = [];
  hotelData: AdvisoryLookupModel[] = [];
  vendorData: AdvisoryLookupModel[] = [];
  ratingData: AdvisoryLookupModel[] = [];

  showAirportList: boolean = false;
  selectedAirportList: AdvisoryLookupModel[] = [];
  airportList: AdvisoryLookupModel[] = [];
  filteredAirportList: Observable<AdvisoryLookupModel[]>;
  selectedAirportCountText: string = '';

  showCountryList: boolean = false;
  selectedCountryList: AdvisoryLookupModel[] = [];
  countryList: AdvisoryLookupModel[] = [];
  filteredCountryList: Observable<AdvisoryLookupModel[]>;
  selectedCountryCountText: string = '';

  showRatingList: boolean = false;
  selectedRatingList: AdvisoryLookupModel[] = [];
  ratingList: AdvisoryLookupModel[] = [];
  filteredRatingList: Observable<AdvisoryLookupModel[]>;
  selectedRatingCountText: string = '';

  showVendorList: boolean = false;
  selectedVendorList: AdvisoryLookupModel[] = [];
  vendorList: AdvisoryLookupModel[] = [];
  filteredVendorList: Observable<AdvisoryLookupModel[]>;
  selectedVendorCountText: string = '';

  showHotelList: boolean = false;
  selectedHotelList: AdvisoryLookupModel[] = [];
  hotelList: AdvisoryLookupModel[] = [];
  filteredHotelList: Observable<AdvisoryLookupModel[]>;
  selectedHotelCountText: string = '';

  showAdvisoryTypeList: boolean = false;
  selectedAdvisoryTypeList: AdvisoryLookupModel[] = [];
  advisoryTypeList: AdvisoryLookupModel[] = [];
  filteredAdvisoryTypeList: Observable<AdvisoryLookupModel[]>;
  selectedAdvisoryTypeCountText: string = '';


  sortedData: any[];
  submitted: boolean;
  errMsg: string;
  advisoryTypeData: AdvisoryLookupModel[] = [];
  currentPageIndex: number = 0;
  isValideffectiveDate: boolean = false;
  isValidexpirationDate: boolean = false;
  totalRecordsText: string;
  showSpin: boolean;
  effectiveDateText: string;
  expirationDateText: string;
  effectiveDate: Date;
  expirationDate: Date;


  // @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;

  constructor(@Optional() private readonly _dialogRef: MatDialogRef<AdvisoryDetailsComponent>, private readonly _commonService: CommonService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any, private readonly _authService: AuthenticateService,
    private readonly _advisoryService: AdvisoryService, private readonly _dialog: MatDialog,
    private readonly _formBuilder: UntypedFormBuilder,
  ) {

    if (this.data) {
      this.isDialog = true;
      // this.username = this.data.username;
    }


  }

  ngOnInit() {

    // this._authService.updateAccessTime();
    let d = new Date();
    let uyear: number = d.getUTCFullYear();
    let umonth: number = d.getUTCMonth();
    let udate: number = d.getUTCDate();
    let uhour: number = d.getUTCHours();
    let uminute: number = d.getUTCMinutes();
    let d1: Date = new Date(uyear, umonth, udate, uhour, uminute);
    let hstartnumber: number = 672 * 60 * 60 * 1000;  // 2 weeks back
    let hendnumber: number = 672 * 60 * 60 * 1000;    // 4 weeks front
    let dt1: Date = new Date(d1.getTime() - hstartnumber);
    let dt2: Date = new Date(d1.getTime() + hendnumber);
    this.effectiveDate = dt1;
    this.expirationDate = dt2;
    let m1: number = dt1.getMinutes();
    let m1s: string = m1.toString();
    if (m1 < 10) {
      m1s = "0" + m1.toString();
    }
    //this.startTime = dt1.getHours().toString() + ":" + m1s;
    let m2: number = dt2.getMinutes();
    let m2s: string = m2.toString();
    if (m2 < 10) {
      m2s = "0" + m2.toString();
    }
    //this.endTime = dt2.getHours().toString() + ":" + m2s;
    // this.startTime = "0:00";
    // this.endTime = "23:59";


    this.getData();
    this.initControls();

  }

  initControls() {
    this.advisory_details_form = this._formBuilder.group({
      // userComments: [this.userComments]


      countrysearch: [''],
      airportSearch: [''],
      ratingsearch: [''],
      vendorsearch: [''],
      hotelsearch: [''],
      advisoryTypesearch: [''],
      effectiveDate: [this.effectiveDate],
      expirationDate: [this.expirationDate],
    });

  }


  // checkFormControlValueChanged(): void {
  //   this.advisory_details_form.get("effectiveDate").valueChanges.subscribe(x => {
  //     if (this.f.effectiveDate.value != 0) {
  //       this.hasCountry = true;
  //     }
  //     else {
  //       this.hasCountry = false;
  //     }

  //   });
  //   this.advisory_details_form.get("expirationDate").valueChanges.subscribe(x => {
  //     if (this.f.expirationDate.value != 0) {
  //       this.hasIcao = true;
  //     }
  //     else {
  //       this.hasIcao = false;
  //     }

  //   });


  // }

  get f() { return this.advisory_details_form.controls; }







  clickEdit(title: string, item: AdvisoryModel) {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.minHeight = "36.5em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px" };

    var advisoryId = 0;
    if (title == 'Add') {
      advisoryId = 0;

    }
    else {
      advisoryId = item.advisoryId;
    }

    config.data = { gsId: "", tripCodeId: "", dialogTitle: title, advisoryId: advisoryId, v: this._authService.getCurrentTimeNumber() };
    const dialogRef = this._dialog.open(AdvisoryEditComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }


  getData() {
    let request = new AdvisoryModel();



    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryIdList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.lookupId != 0);
      request.selectedCountryIdList = Array.prototype.map.call(this.selectedCountryList, s => s.lookupId).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedAirportIdList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.lookupId.toString() != 'all');
      request.selectedAirportIdList = Array.prototype.map.call(this.selectedAirportList, s => s.lookupId).toString();
    }

    if (this.selectedRatingList.length == this.ratingList.length) {
      request.selectedRatingIdList = "";
    }
    else {
      this.selectedRatingList = this.selectedRatingList.filter(x => x.lookupId != 0);
      request.selectedRatingIdList = Array.prototype.map.call(this.selectedRatingList, s => s.lookupId).toString();
    }



    if (this.selectedVendorList.length == this.vendorList.length) {
      request.selectedVendorIdList = "";
    }
    else {
      this.selectedVendorList = this.selectedVendorList.filter(x => x.lookupGUID != '');
      request.selectedVendorIdList = Array.prototype.map.call(this.selectedVendorList, s => s.vendorType + '|' + s.lookupGUID).toString();
    }






    if (this.selectedHotelList.length == this.hotelList.length) {
      request.selectedHotelIdList = "";
    }
    else {
      this.selectedHotelList = this.selectedHotelList.filter(x => x.lookupId != 0);
      request.selectedHotelIdList = Array.prototype.map.call(this.selectedHotelList, s => s.lookupId).toString();
    }

    if (this.selectedAdvisoryTypeList.length == this.advisoryTypeList.length) {
      request.selectedAdvisoryTypeIdList = "";
    }
    else {
      this.selectedAdvisoryTypeList = this.selectedAdvisoryTypeList.filter(x => x.lookupId != 0);
      request.selectedAdvisoryTypeIdList = Array.prototype.map.call(this.selectedAdvisoryTypeList, s => s.lookupId).toString();
    }

    request.effectiveDate = this.effectiveDate;
    request.expirationDate = this.expirationDate;


    // request.advisoryId = this.currentAdvisoryId;
    this._advisoryService.getAdvisoriesByConditions<ResponseModel<AdvisoryModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.advisoryList = [];
        this.totalAdvisories = response.model;
        this.advisoryList = response.model.advisoryList;

        this.countryData = this.totalAdvisories?.countryFilter?.slice();
        this.airportData = this.totalAdvisories?.airportFilter?.slice();
        this.ratingData = this.totalAdvisories?.ratingFilter?.slice();
        this.vendorData = this.totalAdvisories?.vendorFilter?.slice();
        this.hotelData = this.totalAdvisories?.hotelFilter?.slice();
        this.advisoryTypeData = this.totalAdvisories?.advisoryTypeFilter?.slice();



      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    })
  }



  getRecord() {

    let request = new AdvisoryModel();
    request.advisoryId = 1;
    this._advisoryService.getAdvisoryByAdvisoryID<ResponseModel<AdvisoryModel>>(request.advisoryId).subscribe(x => console.log(x));
  }



  hideAllFilters() {
    this.showAirportList = false;
    this.showCountryList = false;
    this.showRatingList = false;
    this.showVendorList = false;
    this.showHotelList = false;
    this.showAdvisoryTypeList = false;
  }



  clickReset() {
    this.hideAllFilters();

    // this.includeDisabledRecords = false; 
    this.selectedCountryList = [];
    this.selectedAirportList = [];
    this.selectedHotelList = [];
    this.selectedRatingList = [];
    this.selectedVendorList = [];
    this.selectedAdvisoryTypeList = [];

    this.selectedCountryCountText = "";
    this.selectedAirportCountText = "";
    this.selectedRatingCountText = "";
    this.selectedHotelCountText = "";
    this.selectedVendorCountText = "";
    this.selectedAdvisoryTypeCountText = "";



    this.sortedData = [];



    // this.getDataConditions = 'active';

    //this.clickSubmit();

    this.initControls();
    // this.getData();
  }





  clickSubmit() {
    this.submitted = true;
    this.errMsg = "";
    this.processDates();
    this.getData();
  }

  // airport
  clickAirportFilter() {
    this.getAirportList();
  }

  getAirportList() {

    this.hideAllFilters();
    this.showAirportList = true;
    // this.showFuelSupplierList = false; 
    // this.showIntoPlaneAgentList = false; 
    // this.showCountryList = false; 
    this.airportList = [];

    let a = new AdvisoryLookupModel();

    this.airportData.forEach(x => {
      a = new AdvisoryLookupModel();
      a.description = x.description;
      a.lookupId = x.lookupId;
      a.selected = false;
      if (this.selectedAirportList.length > 0) {
        let f: AdvisoryLookupModel[];
        f = this.selectedAirportList.filter(y => y.lookupId == x.lookupId);
        if (f.length > 0) {
          a.selected = true;
        }
      }
      this.airportList.push(a);
    });
    this.airportList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.description.localeCompare(b.description));
    a = new AdvisoryLookupModel();
    a.lookupId = -1;
    a.description = "Select All";
    a.selected = false;
    this.airportList.unshift(a);
    this.filteredAirportList = this.f.airportSearch.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.airportName)),
      map(airportName => (airportName ? this._filterAirport(airportName) : this.airportList.slice())),
    );
    this.setAirportList();
  }

  displayNameAirport(airport: AdvisoryLookupModel): string {
    return airport && airport.description ? airport.description : '';
  }

  private _filterAirport(name: string): AdvisoryLookupModel[] {
    const filterValue = name.toLowerCase();

    return this.airportList.filter(option => option.description.toLowerCase().includes(filterValue));
  }

  checkAirportChange(e: any, item: AdvisoryLookupModel) {
    if (item.lookupId == -1) {
      this.airportList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.airportList.forEach(x => {
        if (item.lookupId == x.lookupId) {
          x.selected = e.checked;
        }
      });
    }

    this.setAirportList();
  }

  setAirportList() {
    this.selectedAirportList = this.airportList.filter(u => u.selected == true);
    this.selectedAirportCountText = "";
    if (this.airportList.length > 0) {
      if (this.selectedAirportList.length == this.airportList.length) {
        this.selectedAirportCountText = ": All";
      }
      else {
        if (this.selectedAirportList.length > 0)
          this.selectedAirportCountText = ": " + this.selectedAirportList.length.toString();
      }
    }
  }

  clickDoneAirport() {
    this.showAirportList = false;
    this.clickSubmit();
  }

  clickResetAirport() {
    this.airportList.forEach(x => {
      x.selected = false;
    });
    this.f.airportSearch.setValue("");
    this.setAirportList();
    this.clickSubmit();
  }
  // airport end


  //country
  clickCountryFilter() {
    this.getCountryList();
  }

  getCountryList() {

    this.hideAllFilters();
    this.showCountryList = true;
    // this.showIntoPlaneAgentList = false;
    // this.showAirportList = false;




    this.countryList = [];
    let c = new AdvisoryLookupModel();

    this.countryData.forEach(x => {
      c = new AdvisoryLookupModel();
      c.description = x.description
      // c.countryGUID = x.countryGUID;
      c.lookupId = x.lookupId;
      c.selected = false;
      if (this.selectedCountryList.length > 0) {
        let f: AdvisoryLookupModel[];
        f = this.selectedCountryList.filter(y => y.lookupId == x.lookupId);
        //f = this.selectedCountryList.filter(y => y.countryGUID.toLowerCase() == x.countryGUID.toLowerCase());
        if (f.length > 0) {
          c.selected = true;
        }
      }
      this.countryList.push(c);
    });
    this.countryList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.description.localeCompare(b.description));
    c = new AdvisoryLookupModel();
    c.lookupId = 0;
    //c.countryGUID = "all";
    c.description = "Select All";
    c.selected = false;
    this.countryList.unshift(c);
    this.filteredCountryList = this.f.countrysearch.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.description)),
      map(description => (description ? this._filterCountry(description) : this.countryList.slice())),
    );
    this.setCountryList();
  }

  displayNameCountry(country: AdvisoryLookupModel): string {
    return country && country.description ? country.description : '';
  }

  private _filterCountry(name: string): AdvisoryLookupModel[] {
    const filterValue = name.toLowerCase();

    return this.countryList.filter(option => option.description.toLowerCase().includes(filterValue));
  }

  checkCountryChange(e: any, item: AdvisoryLookupModel) {
    if (item.lookupId == 0) {
      this.countryList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.countryList.forEach(x => {
        if (item.lookupId == x.lookupId) {
          x.selected = e.checked;
        }
      });
    }
    this.setCountryList();
  }

  setCountryList() {
    this.selectedCountryList = this.countryList.filter(u => u.selected == true);
    this.selectedCountryCountText = "";
    if (this.countryList.length > 0) {
      if (this.selectedCountryList.length == this.countryList.length) {
        this.selectedCountryCountText = ": All";
      }
      else {
        if (this.selectedCountryList.length > 0)
          this.selectedCountryCountText = ": " + this.selectedCountryList.length.toString();
      }
    }
  }

  clickDoneCountry() {
    this.showCountryList = false;
    this.clickSubmit();
  }

  clickResetCountry() {
    this.countryList.forEach(x => {
      x.selected = false;
    });
    this.f.countrysearch.setValue("");
    this.setCountryList();
    this.clickSubmit();
  }
  //country end


  //rating
  clickRatingFilter() {
    this.getRatingList();
  }

  getRatingList() {

    this.hideAllFilters();
    this.showRatingList = true;
    // this.showIntoPlaneAgentList = false;
    // this.showAirportList = false;




    this.ratingList = [];
    let c = new AdvisoryLookupModel();

    this.ratingData.forEach(x => {
      c = new AdvisoryLookupModel();
      c.description = x.description
      // c.ratingGUID = x.ratingGUID;
      c.lookupId = x.lookupId;
      c.selected = false;
      if (this.selectedRatingList.length > 0) {
        let f: AdvisoryLookupModel[];
        f = this.selectedRatingList.filter(y => y.lookupId == x.lookupId);
        //f = this.selectedRatingList.filter(y => y.ratingGUID.toLowerCase() == x.ratingGUID.toLowerCase());
        if (f.length > 0) {
          c.selected = true;
        }
      }
      this.ratingList.push(c);
    });
    this.ratingList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.description.localeCompare(b.description));
    c = new AdvisoryLookupModel();
    c.lookupId = 0;
    //c.ratingGUID = "all";
    c.description = "Select All";
    c.selected = false;
    this.ratingList.unshift(c);
    this.filteredRatingList = this.f.ratingsearch.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.description)),
      map(description => (description ? this._filterRating(description) : this.ratingList.slice())),
    );
    this.setRatingList();
  }

  displayNameRating(rating: AdvisoryLookupModel): string {
    return rating && rating.description ? rating.description : '';
  }

  private _filterRating(name: string): AdvisoryLookupModel[] {
    const filterValue = name.toLowerCase();

    return this.ratingList.filter(option => option.description.toLowerCase().includes(filterValue));
  }

  checkRatingChange(e: any, item: AdvisoryLookupModel) {
    if (item.lookupId == 0) {
      this.ratingList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.ratingList.forEach(x => {
        if (item.lookupId == x.lookupId) {
          x.selected = e.checked;
        }
      });
    }
    this.setRatingList();
  }

  setRatingList() {
    this.selectedRatingList = this.ratingList.filter(u => u.selected == true);
    this.selectedRatingCountText = "";
    if (this.ratingList.length > 0) {
      if (this.selectedRatingList.length == this.ratingList.length) {
        this.selectedRatingCountText = ": All";
      }
      else {
        if (this.selectedRatingList.length > 0)
          this.selectedRatingCountText = ": " + this.selectedRatingList.length.toString();
      }
    }
  }

  clickDoneRating() {
    this.showRatingList = false;
    this.clickSubmit();
  }

  clickResetRating() {
    this.ratingList.forEach(x => {
      x.selected = false;
    });
    this.f.ratingsearch.setValue("");
    this.setRatingList();
    this.clickSubmit();
  }
  //rating end


  //vendor
  clickVendorFilter() {
    this.getVendorList();
  }

  getVendorList() {

    this.hideAllFilters();
    this.showVendorList = true;
    // this.showIntoPlaneAgentList = false;
    // this.showAirportList = false;




    this.vendorList = [];
    let c = new AdvisoryLookupModel();

    this.vendorData.forEach(x => {
      c = new AdvisoryLookupModel();
      c.description = x.description
      // c.vendorGUID = x.vendorGUID;
      c.lookupGUID = x.lookupGUID;
      c.vendorType = x.vendorType;
      c.selected = false;
      if (this.selectedVendorList.length > 0) {
        let f: AdvisoryLookupModel[];
        f = this.selectedVendorList.filter(y => y.lookupGUID == x.lookupGUID);
        //f = this.selectedVendorList.filter(y => y.vendorGUID.toLowerCase() == x.vendorGUID.toLowerCase());
        if (f.length > 0) {
          c.selected = true;
        }
      }
      this.vendorList.push(c);
    });
    this.vendorList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.description.localeCompare(b.description));
    c = new AdvisoryLookupModel();
    c.lookupGUID = '';
    //c.vendorGUID = "all";
    c.description = "Select All";
    c.selected = false;
    this.vendorList.unshift(c);
    this.filteredVendorList = this.f.vendorsearch.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.description)),
      map(description => (description ? this._filterVendor(description) : this.vendorList.slice())),
    );
    this.setVendorList();
  }

  displayNameVendor(vendor: AdvisoryLookupModel): string {
    return vendor && vendor.description ? vendor.description : '';
  }

  private _filterVendor(name: string): AdvisoryLookupModel[] {
    const filterValue = name.toLowerCase();

    return this.vendorList.filter(option => option.description.toLowerCase().includes(filterValue));
  }

  checkVendorChange(e: any, item: AdvisoryLookupModel) {
    if (item.lookupGUID == '') {
      this.vendorList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.vendorList.forEach(x => {
        if (item.lookupGUID == x.lookupGUID) {
          x.selected = e.checked;
        }
      });
    }
    this.setVendorList();
  }

  setVendorList() {
    this.selectedVendorList = this.vendorList.filter(u => u.selected == true);
    this.selectedVendorCountText = "";
    if (this.vendorList.length > 0) {
      if (this.selectedVendorList.length == this.vendorList.length) {
        this.selectedVendorCountText = ": All";
      }
      else {
        if (this.selectedVendorList.length > 0)
          this.selectedVendorCountText = ": " + this.selectedVendorList.length.toString();
      }
    }
  }

  clickDoneVendor() {
    this.showVendorList = false;
    this.clickSubmit();
  }

  clickResetVendor() {
    this.vendorList.forEach(x => {
      x.selected = false;
    });
    this.f.vendorsearch.setValue("");
    this.setVendorList();
    this.clickSubmit();
  }
  //vendor end


  //hotel
  clickHotelFilter() {
    this.getHotelList();
  }

  getHotelList() {

    this.hideAllFilters();
    this.showHotelList = true;
    // this.showIntoPlaneAgentList = false;
    // this.showAirportList = false;




    this.hotelList = [];
    let c = new AdvisoryLookupModel();

    this.hotelData.forEach(x => {
      c = new AdvisoryLookupModel();
      c.description = x.description
      // c.hotelGUID = x.hotelGUID;
      c.lookupId = x.lookupId;
      c.selected = false;
      if (this.selectedHotelList.length > 0) {
        let f: AdvisoryLookupModel[];
        f = this.selectedHotelList.filter(y => y.lookupId == x.lookupId);
        //f = this.selectedHotelList.filter(y => y.hotelGUID.toLowerCase() == x.hotelGUID.toLowerCase());
        if (f.length > 0) {
          c.selected = true;
        }
      }
      this.hotelList.push(c);
    });
    this.hotelList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.description.localeCompare(b.description));
    c = new AdvisoryLookupModel();
    c.lookupId = 0;
    //c.hotelGUID = "all";
    c.description = "Select All";
    c.selected = false;
    this.hotelList.unshift(c);
    this.filteredHotelList = this.f.hotelsearch.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.description)),
      map(description => (description ? this._filterHotel(description) : this.hotelList.slice())),
    );
    this.setHotelList();
  }

  displayNameHotel(hotel: AdvisoryLookupModel): string {
    return hotel && hotel.description ? hotel.description : '';
  }

  private _filterHotel(name: string): AdvisoryLookupModel[] {
    const filterValue = name.toLowerCase();

    return this.hotelList.filter(option => option.description.toLowerCase().includes(filterValue));
  }

  checkHotelChange(e: any, item: AdvisoryLookupModel) {
    if (item.lookupId == 0) {
      this.hotelList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.hotelList.forEach(x => {
        if (item.lookupId == x.lookupId) {
          x.selected = e.checked;
        }
      });
    }
    this.setHotelList();
  }

  setHotelList() {
    this.selectedHotelList = this.hotelList.filter(u => u.selected == true);
    this.selectedHotelCountText = "";
    if (this.hotelList.length > 0) {
      if (this.selectedHotelList.length == this.hotelList.length) {
        this.selectedHotelCountText = ": All";
      }
      else {
        if (this.selectedHotelList.length > 0)
          this.selectedHotelCountText = ": " + this.selectedHotelList.length.toString();
      }
    }
  }

  clickDoneHotel() {
    this.showHotelList = false;
    this.clickSubmit();
  }

  clickResetHotel() {
    this.hotelList.forEach(x => {
      x.selected = false;
    });
    this.f.hotelsearch.setValue("");
    this.setHotelList();
    this.clickSubmit();
  }
  //hotel end



  //advisoryType
  clickAdvisoryTypeFilter() {
    this.getAdvisoryTypeList();
  }

  getAdvisoryTypeList() {

    this.hideAllFilters();
    this.showAdvisoryTypeList = true;
    // this.showIntoPlaneAgentList = false;
    // this.showAirportList = false;




    this.advisoryTypeList = [];
    let c = new AdvisoryLookupModel();

    this.advisoryTypeData.forEach(x => {
      c = new AdvisoryLookupModel();
      c.description = x.description
      // c.advisoryTypeGUID = x.advisoryTypeGUID;
      c.lookupId = x.lookupId;
      c.selected = false;
      if (this.selectedAdvisoryTypeList.length > 0) {
        let f: AdvisoryLookupModel[];
        f = this.selectedAdvisoryTypeList.filter(y => y.lookupId == x.lookupId);
        //f = this.selectedAdvisoryTypeList.filter(y => y.advisoryTypeGUID.toLowerCase() == x.advisoryTypeGUID.toLowerCase());
        if (f.length > 0) {
          c.selected = true;
        }
      }
      this.advisoryTypeList.push(c);
    });
    this.advisoryTypeList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.description.localeCompare(b.description));
    c = new AdvisoryLookupModel();
    c.lookupId = 0;
    //c.advisoryTypeGUID = "all";
    c.description = "Select All";
    c.selected = false;
    this.advisoryTypeList.unshift(c);
    this.filteredAdvisoryTypeList = this.f.advisoryTypesearch.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.description)),
      map(description => (description ? this._filterAdvisoryType(description) : this.advisoryTypeList.slice())),
    );
    this.setAdvisoryTypeList();
  }

  displayNameAdvisoryType(advisoryType: AdvisoryLookupModel): string {
    return advisoryType && advisoryType.description ? advisoryType.description : '';
  }

  private _filterAdvisoryType(name: string): AdvisoryLookupModel[] {
    const filterValue = name.toLowerCase();

    return this.advisoryTypeList.filter(option => option.description.toLowerCase().includes(filterValue));
  }

  checkAdvisoryTypeChange(e: any, item: AdvisoryLookupModel) {
    if (item.lookupId == 0) {
      this.advisoryTypeList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.advisoryTypeList.forEach(x => {
        if (item.lookupId == x.lookupId) {
          x.selected = e.checked;
        }
      });
    }
    this.setAdvisoryTypeList();
  }

  setAdvisoryTypeList() {
    this.selectedAdvisoryTypeList = this.advisoryTypeList.filter(u => u.selected == true);
    this.selectedAdvisoryTypeCountText = "";
    if (this.advisoryTypeList.length > 0) {
      if (this.selectedAdvisoryTypeList.length == this.advisoryTypeList.length) {
        this.selectedAdvisoryTypeCountText = ": All";
      }
      else {
        if (this.selectedAdvisoryTypeList.length > 0)
          this.selectedAdvisoryTypeCountText = ": " + this.selectedAdvisoryTypeList.length.toString();
      }
    }
  }

  clickDoneAdvisoryType() {
    this.showAdvisoryTypeList = false;
    this.clickSubmit();
  }

  clickResetAdvisoryType() {
    this.advisoryTypeList.forEach(x => {
      x.selected = false;
    });
    this.f.advisoryTypesearch.setValue("");
    this.setAdvisoryTypeList();
    this.clickSubmit();
  }
  //advisoryType end


  dateOnchange(dateType: string, e: any) {
    this.currentPageIndex = 0;
    if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValideffectiveDate = false;
          this.f.effectiveDate.setErrors({ isValidDate: false });
        }
        else {
          this.isValideffectiveDate = true;
        }
      }
      else {
        this.isValideffectiveDate = true;
      }
    }

    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidexpirationDate = false;
          this.f.expirationDate.setErrors({ isValidDate: false })
        }
        else {
          this.isValidexpirationDate = true;
        }
      }
      else {
        this.isValidexpirationDate = true;
      }
    }

    this.clickSubmit();

  }

  processDates() {
    this._authService.updateAccessTime();

    this.showSpin = true;
    this.totalRecordsText = "";
    let request = new AdvisoryModel();
    request.effectiveDate = this.f.effectiveDate.value;
    request.effectiveDateText = CustomValidators.formatDateToMMDDYYYY(this.f.effectiveDate.value);

    request.expirationDate = this.f.expirationDate.value;
    request.expirationDateText = CustomValidators.formatDateToMMDDYYYY(this.f.expirationDate.value);

    this.effectiveDate = request.effectiveDate;
    this.expirationDate = request.expirationDate;

    this.effectiveDate = request.effectiveDate;
    this.expirationDate = request.expirationDate;


    // if (this.f.startTime.value == "") {
    //   this.effectiveDateText = request.effectiveDateText + " 0:00"
    // }
    // else {
    //   this.effectiveDateText = request.effectiveDateText + " " + this.f.startTime.value;
    // }
    // if (this.f.endTime.value == "") {
    //   this.expirationDateText = request.expirationDateText + " 23:59"
    // }
    // else {
    //   this.expirationDateText = request.expirationDateText + " " + this.f.endTime.value;
    // }
  }


}











