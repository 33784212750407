import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from "../services/authenticate.service";
import { CustomerProfileSetupComponent2 } from "./customer-profile-setup2.component";
import { Observable, forkJoin, of } from "rxjs";
import { CommonService } from "../services/common.service";
import { CustomerGroupModel } from "../models/customer-group.model";
import { CustomerGroupService } from "../services/customer-group-service";
import { CustomerModel } from "../models/customer.model";
import { PersonCommsAddressModel } from "../models/person-comms-address.model";
import { ResponseModel } from "../models/response.model";
import { CustomerService } from "../services/customer.service";
import { PersonMailAddressModel } from "../models/person-mail-address.model";
import { CustomerDocumentModel } from "../models/customer-document.model";
import { CustomerCommEditDialogComponent } from "./customer-comm-edit-dialog.component";
import { CustomerMailEditDialogComponent } from "./customer-mail-edit-dialog.component";
import { CustomerDocumentEditDialogComponent } from "./customer-document-edit-dialog.component";
import { CustomerProfileSetupEditComponent } from "./customer-profile-setup-edit.component";
import { AircraftProfileComponent } from "../aircraft/aircraft-profile.component";
import { ActivatedRoute } from "@angular/router";
import { AdminServicesClientComponent } from "../user-accounts/admin-services-client.component";
import { ManageContactDetailsComponent } from "../persons/manage-contact-details.component";
import { UtilityFunctions } from "../common-utility/utility.functions";

@Component({
  selector: 'app-customer-information-dialog',
  templateUrl: './customer-information-dialog.component.html',
  styleUrls: ['./customer-information-dialog.component.css']
})

export class CustomerInformationDialogComponent implements OnInit {
  isDialog: boolean = false;
  totalRecords: number = 0;
  totalRecordsText: string = "";
  userType: string;
  errMsg: string;
  msg: string;
  grantAccessToAccountGrouping: boolean = false;
  loading: boolean = false;
  showSpin: boolean = false;
  showDisabledRecords: boolean = false;
  customerList: CustomerModel[] = [];
  customerGuid: string = "";
  recordList: any[] = [];
  filteredRecordList: any[] = [];
  displayedColumn: string[] = ['type', 'tag_information', 'details', 'data_entry_status', 'option'];
  selectedClientName: string = "";

  constructor(
    @Optional() private readonly _dialogRef: MatDialogRef<CustomerInformationDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _customerService: CustomerService,
    private readonly _customerGroupService: CustomerGroupService,
    private readonly _dialog: MatDialog,
    private _route: ActivatedRoute) {
    if (this.data) {
      this.isDialog = true;
      this.customerGuid = data.customerGUID;
      this.selectedClientName = data.customerName;
    }
  }

  ngOnInit() {
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    if(this.data) {
      this.customerGuid = this.data.customerGUID;
      this.selectedClientName = this.data.customerName;
    }
    else if (this.customerGuid == null || this.customerGuid == "" || this.customerGuid == undefined) {
      this._route.queryParams.subscribe(params => {
        this.customerGuid = params['customerGuid'];
        this.selectedClientName = params['customerName']
      });
    }

    this.getSearchResults();
  }

  getSearchResults() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this.recordList = [];
    this.filteredRecordList = [];
    this._authService.updateAccessTime();
    let request = new PersonCommsAddressModel();
    request.customerGUID = this.customerGuid;
    request.filterBy = "";
    request.totalRecords = this.totalRecords;
    request.isActive = true;
    if (this.showDisabledRecords) {
      request.isActive = false;
    }

    this._customerService.getCustomerOfficeCommsAddress<ResponseModel<PersonCommsAddressModel[]>>(request).toPromise().then(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = this.recordList.concat(response.model);
          this.filteredRecordList = this.recordList;
          if (this.filteredRecordList.length > 0) {
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalRecordsText = this.totalRecords.toString() + ' record count';
          }
        }
        else {
          this.recordList = [];
        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

      let request2 = new PersonMailAddressModel();
      request2.customerGUID = this.customerGuid
      request2.filterBy = "";
      request2.totalRecords = this.totalRecords;
      request2.isActive = true;
      if (this.showDisabledRecords) {
        request2.isActive = false;
      }
      this._customerService.getCustomerOfficeMailAddress<ResponseModel<PersonMailAddressModel[]>>(request2).toPromise().then(response => {
        if (response != null && response.code == "200") {
          if (response.model.length > 0) {
            this.recordList = this.recordList.concat(response.model);
            this.filteredRecordList = this.recordList;
            if (this.filteredRecordList.length > 0) {
              this.totalRecords = this.filteredRecordList.length;
              this.totalRecordsText = this.totalRecords.toString() + ' record count';
            }

          }
          // else {
          //   this.recordList = [];
          // }
        }
        // else {
        //   this.recordList = [];
        // }
        this.loading = false;
        this.showSpin = false;

        let request3 = new CustomerDocumentModel();
        request3.customerGUID = this.customerGuid;
        request3.filterBy = "";
        request.totalRecords = this.totalRecords;
        request3.isActive = true;
        if (this.showDisabledRecords) {
          request3.isActive = false;
        }

        this._customerService.getCustomerDocument<ResponseModel<CustomerDocumentModel[]>>(request3).toPromise().then(response => {
          if (response != null && response.code == "200") {
            if (response.model.length > 0) {
              this.recordList = this.recordList.concat(response.model);
              this.filteredRecordList = this.recordList;
              if (this.filteredRecordList.length > 0) {
                this.totalRecords = this.filteredRecordList.length;
                this.totalRecordsText = this.totalRecords.toString() + ' record count';
              }
            }
            // else {
            //   this.recordList = [];
            // }
          }
          // else {
          //   this.recordList = [];
          // }
          this.loading = false;
          this.showSpin = false;
        })
      })
    })
  }

  openSelection(columnName: string) {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "32.5em";//"1000px";
    //config.maxWidth = "65em";//"1000px";
    config.width = "65em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGuid, customerName: this.selectedClientName, userType: 'customer' };
    config.position = {left: "10px", top:"140px"};

    if (this.isDialog) {
      this.clickClose(true);
    }

    switch (columnName) {
      case 'Acct Setup':
        config.height = "50em";
        config.width = "90em";
        config.maxWidth = "90em";
        config.maxHeight = "50em";
        config.position = {left: "10px", top:"140px"};
        this._dialog.open(CustomerProfileSetupEditComponent, config);
        break;
      case 'Client Information':
        config.width = "69em";
        this._dialog.open(CustomerInformationDialogComponent, config);
        break;
      case 'Aircraft':
        config.width = "92em";
        config.maxWidth = "92em";
        this._dialog.open(AircraftProfileComponent, config);
        break;
      case 'Crew, PAX & Support':
        config.height = "auto";
        config.maxHeight = "80em";
        config.width = "81em";
        this._dialog.open(ManageContactDetailsComponent, config);
        break;
      case 'Admin Services':
        config.height = "max-content";
        config.width = "max-content";
        this._dialog.open(AdminServicesClientComponent, config);
        break;
    }

  }

  clickClose(val) {
    this._dialogRef.close(val);
  }

  clickRouteNew(route: string) {
    this._dialogRef.close(true);
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber() + "&customerGuid=" + this.customerGuid + "&customerName=" + this.selectedClientName);
  }

  openClientProfileSetupComponent() {
    if (this.isDialog) {
      this.clickClose(true);
    }
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.width = "55em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = {left: "10px", top:"140px"};
    config.data = { gsId: "", tripCodeId: "", isDialog: true, v: this._authService.getCurrentTimeNumber() };

    this._dialog.open(CustomerProfileSetupComponent2, config);
  }
  openEditInfoComponent(element: any) {
    const config = new MatDialogConfig();

    if (element.commAddressTypeGUID !== undefined && element.commAddressTypeGUID !== null) {
      var personCommsAddressGUID = element.personCommsAddressGUID;
      if (this.userType == "internal" || (this.userType == "customer" && this.grantAccessToAccountGrouping)) {
        config.data = { dialogTitle: "Edit Communications", customerGUID: this.customerGuid, personCommsAddressGUID: personCommsAddressGUID };
      }
      else {
        config.data = { dialogTitle: "Edit Communications", personCommsAddressGUID: personCommsAddressGUID };
      }
      const dialogRef = this._dialog.open(CustomerCommEditDialogComponent, config);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getSearchResults();

        }
      });
    }
    else if (element.mailAddressTypeList !== undefined && element.mailAddressTypeList !== null) {
      var personMailAddressGUID = element.personMailAddressGUID;
      if (this.userType == "internal" || (this.userType == "customer" && this.grantAccessToAccountGrouping)) {
        config.data = { dialogTitle: "Edit Communications", customerGUID: this.customerGuid, personMailAddressGUID: personMailAddressGUID };
      }
      else {
        config.data = { dialogTitle: "Edit Communications", personMailAddressGUID: personMailAddressGUID };
      }
      const dialogRef = this._dialog.open(CustomerMailEditDialogComponent, config);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getSearchResults();

        }
      });
    }
    else if (element.documentTypeList !== undefined && element.documentTypeList !== null) {
      var customerDocumentGUID = element.customerDocumentGUID;
      if (this.userType == "internal" || (this.userType == "customer" && this.grantAccessToAccountGrouping)) {
        config.data = { dialogTitle: "Edit Communications", customerGUID: this.customerGuid, customerDocumentGUID: customerDocumentGUID };
      }
      else {
        config.data = { dialogTitle: "Edit Communications", customerDocumentGUID: customerDocumentGUID };
      }
      const dialogRef = this._dialog.open(CustomerDocumentEditDialogComponent, config);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getSearchResults();

        }
      });
    }
  }

  openAddInfoComponent(s: string) {
    const config = new MatDialogConfig();

    switch (s) {
      case 'comms':
        config.data = { dialogTitle: 'Add New Communications', personCommsAddressGUID: "", customerGUID: this.customerGuid };
        const dialogRef1 = this._dialog.open(CustomerCommEditDialogComponent, config);
        dialogRef1.afterClosed().subscribe(result => {
          if (result) {
            this.getSearchResults();
          }
        });
        break;
      case 'mail':
        config.data = { dialogTitle: 'Add New Address', personMailAddressGUID: "", customerGUID: this.customerGuid };
        const dialogRef2 = this._dialog.open(CustomerMailEditDialogComponent, config);
        dialogRef2.afterClosed().subscribe(result => {
          if (result) {
            this.getSearchResults();
          }
        });
        break;
      case 'document':
        config.data = { dialogTitle: 'Add New Document', customerGUID: this.customerGuid, customerDocumentGUID: "" };
        const dialogRef3 = this._dialog.open(CustomerDocumentEditDialogComponent, config);
        dialogRef3.afterClosed().subscribe(result => {
          if (result) {
            this.getSearchResults();
          }
        });
        break;
    }
  }

  filterActiveOrDisabledRecords(isActive: boolean) {
    this.filteredRecordList = this.recordList.filter(x => x.isActive === isActive);
    this.totalRecords = this.filteredRecordList.length;
    this.totalRecordsText = this.totalRecords.toString() + ' record count';
  }

  filterDataEntryStatusRecords() {
    this.filteredRecordList = this.recordList.filter(x => x.dataEntryStatusID !== 2);
    this.totalRecords = this.filteredRecordList.length;
    this.totalRecordsText = this.totalRecords.toString() + ' record count';
  }

  clickPreviewDoc(event: any, doc: CustomerDocumentModel) {
    let customerDocumentGUID = doc.customerDocumentGUID;
    this._customerService.getCustomerDocumentBycdId<ResponseModel<CustomerDocumentModel>>(customerDocumentGUID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let docObj = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
          let file = new Blob([byteArray], { type: docObj.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);

        }
        else {
          this.errMsg = "No Document returned.";
        }
      }
      else {
        this.errMsg = "No Document returned.";
      }
    })

  }
}
