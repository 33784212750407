<div style="height: 100%;" [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle>
  <div style="display: inline-flex; justify-content: space-between; width: 100%;">
    <div style="margin-left:1em">
      <div style="display: inline-flex;">
        <i class="material-symbols-outlined" style="margin-top: 0.3em;">
          integration_instructions
        </i>
        <div style="margin-left: 0.5em;">
          <div style="display: inline-flex;">
            <h3 style="margin-bottom: 0em;">Client Instructions</h3>
            <h3 style="margin-bottom: 0em; margin-left: 0.5em; color: #007bff;">{{selectedClientName}}</h3>
          </div>
          
          <h5 style="color: #727a84">Internal Notes</h5>
        </div>
      </div>
    </div>

    <div style="display: flex; margin-left: 1em;">
      <button mat-icon-button *ngIf="isDialog" mat-dialog-close><i class="material-icons"
          style="font-size: x-large;">close </i></button>
    </div>
  </div>
  <div style="margin-top:0em; padding-left: 1em; padding-right:1em">
    <div style="background-color: white; padding: 1em; width: 100%; font-size: small; overflow-y: auto; height: 50em;">
      <div style="margin-bottom: 1em;">
        <h3 style="font-weight: bold; font-size: small; margin-bottom: 0em;">
          Non Standard Procedures
        </h3>
        <div [innerHTML]="getHTML(nonStandardClientInstructions)">
        </div>
      </div>

      <div>
        <h3 style="font-weight: bold; font-size: small; margin-bottom: 0em;">
          Client Instruction Notes
        </h3>
        <div style="white-space: pre-wrap;" [innerHTML]="getHTML(standardClientInstructions)">
        </div>
      </div>
    </div>
    <div style="margin-top: 0.5em; margin-bottom: 0.5em; display: inline-flex;">
      <button mat-button class="btn-low" mat-dialog-close style="background-color:grey; color: white;">
        <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
          <div>Close</div>
        </div>
      </button>

      <button mat-button class="btn-med" (click)="openCustomerProfile()" style="background-color:grey; color: white; margin-left: 0.5em;">
        <div>Open Client Profile</div>
      </button>
    </div>
  </div>
