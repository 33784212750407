<div *ngIf="upPII" style="background-color:#f8f8f8;min-width: 30em">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3em" *ngIf="actionType=='Edit' || actionType=='Remove'">
      <i class="material-icons-outlined" style="font-size: xx-large; color: green">check_circle</i>
    </div>
    <div style="margin-top:0.3em" *ngIf="actionType=='Add'">
      <i class="material-icons-outlined" style="font-size: xx-large; color: #337dff">add_circle</i>
    </div>
    <div style="margin-top:0.3em" *ngIf="actionType=='RemoveOption'">
      <i class="material-icons-outlined" style="font-size: xx-large; color: red">cancel</i>
    </div>
    <div style="margin-top:0.3125em;">
      <h5>
        {{actionType=='RemoveOption'?'Remove Person':actionType=='Edit' || actionType=='Remove'?'Edit Person':'Add Person to Legs'}}
      </h5>
    </div>
  </div>
  <div>
    <form *ngIf="trip_person_editor_client_form" [formGroup]="trip_person_editor_client_form">
      <div style="margin-top:0em; padding-left: 1em; padding-right:1em; font-size: small">
        <div style="background-color: white; min-height:25em; padding-left:0.5em;padding-right:0.5em; padding-bottom:0.5em; min-width:38em">
          <div style="display: flex; justify-content: space-between">
            <div>
              <div style="font-weight: bold">{{capacityID==1?personType+': ':''}}{{personName}}</div>
              <div style="font-weight: bold">{{leg}}</div>
            </div>
            <div style="margin-right:1.25em; margin-top:0.5em" *ngIf="grantAccessToManageManifestProfiles && capacityID==1">
              <button type="button" mat-button class="btn-low" (click)="clickOpenPersonDetail()" style="padding-left:0.25em !important; padding-right:0.25em !important">Open Person Profile</button>
            </div>
          </div>
          <div *ngIf="actionType!='RemoveOption'">
            <div style="margin-left:-1.4em; font-size: medium">
              <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="sameDocumentForDepArr" (change)="sameDocumentForDepArrChange($event)">Same Document for Departure and Arrival</mat-slide-toggle>
            </div>
            <div style="display: flex">
              <div>
                <div *ngIf="f.sameDocumentForDepArr.value==false" style="font-weight: bold">Departure Documents</div>
                <div style="margin-top:0em; min-height: 4em; background-color: #E9ECEF; width:25em; padding-left: 0.125em;font-size: small;border-radius:0.25em;margin-right:0.5em">
                  <div *ngFor="let d of depDocumentList">
                    <div style="margin-left:0.5em; margin-top:-0.25em">{{d.documentTypeDescription}}  {{d.documentNumber}} {{d.isO2Letter}} {{d.documentDOE}}</div>
                  </div>
                </div>
              </div>
              <div style="margin-top:1.5em" *ngIf="!lockdownTrip && !arrivalDateTimePassed">
                <button type="button" mat-button class="btn-med" (click)="clickSelectDocument('Departure')">Select Documents</button>
              </div>
            </div>
            <div style="display: flex" *ngIf="f.sameDocumentForDepArr.value==false">
              <div>
                <div style="font-weight: bold">Arrival Documents</div>
                <div style="margin-top:0em; min-height: 4em; background-color: #E9ECEF; width:25em; padding-left: 0.125em;font-size: small;border-radius:0.25em;margin-right:0.5em">
                  <div *ngFor="let d of arrDocumentList">
                    <div style="margin-left:0.5em; margin-top:-0.25em">{{d.documentTypeDescription}}  {{d.documentNumber}} {{d.isO2Letter}} {{d.documentDOE}}</div>
                  </div>
                </div>
              </div>
              <div style="margin-top:1.5em" *ngIf="!lockdownTrip && !arrivalDateTimePassed">
                <button type="button" mat-button class="btn-med" (click)="clickSelectDocument('Arrival')">Select Documents</button>
              </div>
            </div>
            <div style="margin-left:-0.5em; font-size: medium">
              <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="addToAllLeg" (change)="addToAllLegChange($event)">Add to All Active Legs</mat-slide-toggle>
            </div>
          </div>
          <div *ngIf="actionType=='RemoveOption'">
            <div style="height: 3em"></div>
            <div class="d-flex flex-column" style="margin-left:-1.4em; font-size: medium;">
              <!--<div style="display: flex" >
                <div style="margin-left:2.95em; width:2.25em">
                  <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" #removeFromCurrentLegRef formControlName="removeFromCurrentLeg" (change)="removeFromCurrentLegChange($event)"></mat-slide-toggle>
                </div>
                <div style="font-size: small; margin-top:0.25em; display: flex"><div style="margin-right:0.125em">Remove from</div><div style="font-weight: bold">{{leg}}</div></div>
              </div>-->
              <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="removeFromAllActiveLeg" (change)="removeFromAllActiveLegChange($event)">Remove from LEG {{legOrder}} and All NEXT Active Legs</mat-slide-toggle>
              <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" formControlName="removeFromTrip" (change)="removeFromTripChange($event)">Remove from Trip</mat-slide-toggle>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
    <div style="margin-right:1em;">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <button type="button" mat-button class="btn-high" *ngIf="loading==false && showSuccessMsg==false && !lockdownTrip && !arrivalDateTimePassed" [disabled]="loading" (click)="clickSave('')"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>SAVE</div></div></button>&nbsp;
      <button type="button" mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>CLOSE</div></div></button>
    </div>
    <div *ngIf="actionType!='RemoveOption' && actionType!='Remove' && actionType!='Add' && !lockdownTrip && !arrivalDateTimePassed" style="margin-right:0.5em"><button type="button" mat-button class="btn-low" (click)="clickRemovePerson()">Remove Person</button></div>
    <div *ngIf="actionType!='RemoveOption' && actionType!='Remove' && actionType!='Add' && !lockdownTrip && !arrivalDateTimePassed"><button type="button" mat-button class="btn-low" (click)="clickRemovePersonOption()">Remove Person Options</button></div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
    <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0.0em; background-color:#f9d2de; padding-left:0.25em; max-width:23em; white-space:pre-wrap"><label style="color:black; font-size: small">{{errMsg}}</label></div>
  </div>
</div>
