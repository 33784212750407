import { Component } from '@angular/core';

@Component({
  selector: 'app-trip-quote-bi-dashboard',
  templateUrl: './trip-quote-bi-dashboard.component.html',
  styleUrls: ['./trip-quote-bi-dashboard.component.css']
})
/** airport-bi-dashboard component*/
export class TripQuoteBiDashboardComponent {
  
    constructor() {

    }
}
