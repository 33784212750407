import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { ServiceClassModel } from '../models/service-class.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { MatAccordion } from '@angular/material/expansion';
import { SelectionModel } from '@angular/cdk/collections';
import { ViewportScroller } from '@angular/common';
import { GroundStopService } from '../services/ground-stop.service';
import { CustomerModel } from '../models/customer.model';
import { TripModel } from '../models/trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { DocumentModel } from '../models/document.model';
import { DocumentCategoryModel } from '../models/document-category.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { PersonTravelDocumentModel } from '../models/person-travel-document.model';
import { PersonTravelDocEditDialogComponent } from '../persons/person-travel-doc-edit-dialog.component';
import { AircraftDocumentEditDialogComponent } from '../aircraft/aircraft-document-edit-dialog.component';
import { PersonPassportEditDialogComponent } from '../persons/person-passport-edit-dialog.component';
import { CustomerDocumentEditDialogComponent } from '../customers/customer-document-edit-dialog.component';
import { AircraftDocumentModel } from '../models/aircraft-document.model';
import { CustomerDocumentModel } from '../models/customer-document.model';

@Component({
  selector: 'app-document-downloads-dialog',
  templateUrl: './document-downloads-dialog.component.html',
  styleUrls: ['./document-downloads-dialog.component.css']
})

export class DocumentDownloadsDialogComponent implements OnInit {

  //dialogTitle: string;
  groundStopGUID: string;
  customerGUID: string;
  tripCodeGUID: string;
  aircraftGUID: string;
  document_downloads_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  tripCodeList: TripModel[];
  customerList: CustomerModel[];
  groundStopList: GroundStopModel[];
  documentList: DocumentModel[];
  documentCategoryList: DocumentCategoryModel[];
  //recordList: ServiceTypeModel[];
  displayedColumn: string[] = ['documentType', 'documentName', 'expirationDate', 'check'];
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  tempFileNameDisplay: string;
  selection = new SelectionModel<DocumentModel>(true, []);
  //@ViewChildren('focusOnThisPanel') focusOnThisPanel: QueryList<ElementRef>
  successMsg = "Downloads successfully";
  upPII: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<DocumentDownloadsDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService,
    private readonly _dialogService: DialogService //, private viewportScroller: ViewportScroller
  ) {
    
  }

  getAllData(): Observable<any[]> {  
    let getClientListResponse = this._groundStopService.getClientListWithTrip();
    return forkJoin([getClientListResponse]);
   
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }

    this.customerList = [];
    this.tripCodeList = [];
    this.groundStopList = [];
    this.documentList = [];
    this.documentCategoryList = [];
    //this.recordList = [];
    this.customerGUID = "";
    this.groundStopGUID = "";
    this.tripCodeGUID = "";
    this.aircraftGUID = "";
    this.tempFileNameDisplay = "";
    this.showSuccessMsg = false;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;

    });

  }

  initControls() {
    this.document_downloads_form = this._formBuilder.group({
      customer_select: [''],
      trip_code_select: [''],
      ground_stop_select:[null]
    });

  }

  get f() { return this.document_downloads_form.controls; }

  customerChange(e: any) {
    this._authService.updateAccessTime();
    this.tripCodeList = [];
    this.groundStopList = [];
    this.groundStopGUID = "";
    this.tripCodeGUID = "";
    this.tempFileNameDisplay = "";
    this.documentList = [];
    this.documentCategoryList = [];
    this.f.trip_code_select.setValue('');
    this.selection.clear();
    this.errMsg = "";
    this.msg = "";
    if (e.target.value != "") {
      this.customerGUID = this.f.customer_select.value;
      this.getTripCodeListByCustomerGUID();
    }
    else {      
      this.customerGUID = "";
    }
  }

  tripCodeChange(e: any) {
    this._authService.updateAccessTime();
    this.groundStopList = [];
    this.groundStopGUID = "";
    this.aircraftGUID = "";
    this.tempFileNameDisplay = "";
    this.documentList = [];
    this.documentCategoryList = [];
    this.selection.clear();
    this.errMsg = "";
    this.msg = "";
    if (e.target.value != "") {
      this.tripCodeGUID = this.f.trip_code_select.value;
      this.getGroundStopList();
    }
    else {
      this.tripCodeGUID = "";
    }
  }

  groundStopChange(e: any) {
    this._authService.updateAccessTime();
    this.tempFileNameDisplay = "";
    this.groundStopGUID = "";
    this.aircraftGUID = "";
    this.documentList = [];
    this.documentCategoryList = [];
    this.selection.clear();
    this.errMsg = "";
    this.msg = "";
    if (e.target.value != "null") {
      let gs = new GroundStopModel();
      gs = this.f.ground_stop_select.value
      this.groundStopGUID = gs.groundStopGUID;
      this.aircraftGUID = gs.aircraftGUID;
      this.tempFileNameDisplay = this.tripCodeList.filter(v => v.tripCodeGUID == this.f.trip_code_select.value)[0].tripCode;
      this.tempFileNameDisplay +="_"+ gs.departureICAO;
      if (gs.departureDateTextUTC != "") {
        let i = gs.departureDateTextUTC.lastIndexOf('/');
        let d = gs.departureDateTextUTC.substring(0, i);
        d=d.replace("/","_")
        this.tempFileNameDisplay += "_" + d;
      }
      this.tempFileNameDisplay += "_";
      this.clickSearch();
    }

  }

  getTripCodeListByCustomerGUID() {
    this._groundStopService.getTripCodeListBycId<ResponseModel<TripModel[]>>(this.f.customer_select.value).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.tripCodeList = response.model;
        }
        else {
          
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }
      
    })

  }

  getGroundStopList() {
    let request = new GroundStopModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.customerGUID = this.customerGUID;
    this._groundStopService.getGroundStopPairListBytId<ResponseModel<GroundStopModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.groundStopList = response.model;
        }
        else {

        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }

    })

  }

  clickSearch() {
    this._authService.updateAccessTime();
    let request = new GroundStopModel();
    request.customerGUID = this.customerGUID;
    request.aircraftGUID = this.aircraftGUID;
    request.groundStopGUID = this.groundStopGUID
    this.showSpin = true;
    this._groundStopService.getTripDocumentListByIds<ResponseModel<DocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.documentList = [];
        this.documentCategoryList = [];
        if (response.model.length>0) {
          this.documentList = response.model;
          let tempCategetory: string = "";
          this.documentList.forEach(x => {
            if (tempCategetory != x.documentCategory) {
              tempCategetory = x.documentCategory;
              let y = new DocumentCategoryModel();
              y.documentCategory = x.documentCategory;             
              y.documentList = this.documentList.filter(a => a.documentCategory == tempCategetory);             
              this.documentCategoryList.push(y);
            }
          });
          this.documentCategoryList[0].panelOpenState = true;

        }
        else {
          this.msg = "No documents returned";
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }

      this.showSpin = false;
    })

  }

  checkDocumentChange(e: any, item: DocumentModel) {
    this._authService.updateAccessTime();
    this.errMsg = "";
    e ? this.selection.toggle(item) : null;
    item.selected = e.checked;
    let n = 0;
    let currentDoc: DocumentModel[];
    currentDoc = this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentList;
      currentDoc.forEach(y => {
        if (y.selected == true) {
          n += 1;
        }
      });

    if (n > 0) {
      this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentSelectedCountDisplay = "(Selected " + n.toString()+")";
    }
    else {
      this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentSelectedCountDisplay = "";
    }
  }

  nextStep(currentStep: number) {
    this._authService.updateAccessTime();
    this.documentCategoryList[currentStep].panelOpenState = false;
    if (currentStep < (this.documentCategoryList.length - 1)) {
      let next = currentStep + 1;
      this.documentCategoryList[next].panelOpenState = true;
      
    }

  }
  prevStep(currentStep: number) {
    this._authService.updateAccessTime();
    this.documentCategoryList[currentStep].panelOpenState = false;
    let prev = currentStep - 1;
    this.documentCategoryList[prev].panelOpenState = true;   
  }

  openAll() {
    this._authService.updateAccessTime();
    this.documentCategoryList.forEach(x => x.panelOpenState = true);
  }

  closeAll() {
    this._authService.updateAccessTime();
    this.documentCategoryList.forEach(x => x.panelOpenState = false);
  }

  clickPreviewDocument(e: any, item: DocumentModel) {
    this._authService.updateAccessTime();
    let request = new DocumentModel();
    request.documentCategory = item.documentCategory;
    request.documentGUID = item.documentGUID;
    this._groundStopService.getTripDocumentById<ResponseModel<DocumentModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model!=null) {
          let doc = new DocumentModel();
          doc = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
          let file = new Blob([byteArray], { type: doc.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
        //else {
        //  this.msg = "No documents returned";
        //}
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }

    })

  }
  

  clickDownload() {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    let selectedList: DocumentModel[];
    selectedList = [];
    this.selection.selected.forEach(row => {
      selectedList.push(row);
    });

    if (selectedList.length == 0) {
      this.errMsg = "Please select at least one document.";
      return;
    }

    
    let n: number = 0;
    selectedList.forEach(x => {
      this.loading = true;
      let request = new DocumentModel();
      request.documentCategory = x.documentCategory;
      request.documentGUID = x.documentGUID;
      this._groundStopService.getTripDocumentById<ResponseModel<DocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let doc = new DocumentModel();
            doc = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
            let file = new Blob([byteArray], { type: doc.mimeType });
            var fileURL = URL.createObjectURL(file);
            var link = document.createElement('a');
            link.href = fileURL;
            link.download = this.tempFileNameDisplay+x.documentName;
            link.click();
            n += 1;
            this.loading = false;
            if (n == selectedList.length) {
              this.showSuccessMsg = true;
              setTimeout(() => {
                this.showSuccessMsg = false;
              }, 1000);
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }

        
      })

    });
    
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  clickUnApprovedDoc(e: any, item: DocumentModel) {

    switch (item.documentTypeDescription) {
      case "Client Document":
        let objC = new CustomerDocumentModel();
        objC.customerGUID = this.customerGUID;
        objC.customerDocumentGUID = item.documentGUID;
        this.openClientDocEdit(objC);
        break;
      case "Aircraft Document":
        let objA = new AircraftDocumentModel();
        objA.aircraftGUID = this.aircraftGUID
        objA.aircraftDocumentGUID = item.documentGUID;
        this.openAircraftDocEdit(objA);
        break;
      case "Passport":
        let objP = new PersonTravelDocumentModel();
        objP.personTravelDocumentGUID = item.documentGUID;
        objP.personGUID = item.personGUID;
        this.openPassportEdit(objP);
        break;
      default:
        let objD = new PersonTravelDocumentModel();
        objD.personTravelDocumentGUID = item.documentGUID;
        objD.documentTypeGUID = item.documentTypeGUID;
        objD.personGUID = item.personGUID;
        this.openTravelDocEdit(objD);
        break;
    }

  }

  openClientDocEdit(doc: CustomerDocumentModel) {
    let s = "";
    s = "Edit Document";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, customerGUID: doc.customerGUID, customerDocumentGUID: doc.customerDocumentGUID };


    const dialogRef = this._dialog.open(CustomerDocumentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.getSearchResults();
        this.documentList = [];
        this.clickSearch();
      }
    });

  }

  openAircraftDocEdit(doc: AircraftDocumentModel) {

    let s = "";
    let action = "";

    s = "EDIT Record";
    action = "edit";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.disableClose = true;
    dialogConfig.data = { dialogTitle: s, action: action, aircraftGUID: this.aircraftGUID, aircraftDocumentGUID: doc.aircraftDocumentGUID };

    const dialogRef = this._dialog.open(AircraftDocumentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clickSearch();
      }
    });

  }

  openPassportEdit(doc: PersonTravelDocumentModel) {
    let s = "Edit Record";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: doc.personGUID, personTravelDocumentGUID: doc.personTravelDocumentGUID };

    const dialogRef = this._dialog.open(PersonPassportEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.clickSearch();
      }
    });

  }

  openTravelDocEdit(doc: PersonTravelDocumentModel) {
    let s = "";

    s = "Edit Record"

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: doc.personGUID, personTravelDocumentGUID: doc.personTravelDocumentGUID, documentTypeGUID: doc.documentTypeGUID.toLowerCase() };

    const dialogRef = this._dialog.open(PersonTravelDocEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clickSearch();
      }

    });

  }

}
