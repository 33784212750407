import { GroundStopAlternateAirportModel } from "./ground-stop-alternate-airport.model";
import { GroundStopManifestDocumentModel } from "./ground-stop-manifest-document.model";
import { GroundStopManifestModel } from "./ground-stop-manifest.model";
import { GroundStopTaskModel } from "./ground-stop-task.model";
import { GroundStopModel } from "./ground-stop.model";
import { VendorModel } from "./vendor.model";

export class TripLegModel {
  tripCode: string;
  tripCodeGUID: string;
  aircraftGUID: string;
  registration: string;
  customerGUID: string;
  customerName: string;
  tripCloseOutDescription: string;
  groundStopSequenceID: number;
  groundStopGUID: string;
  departurePIC: string;
  ete: string;
  departureICAO: string;
  departureIATA: string;
  departureICAO_IATA: string;
  departureDateUTC: string;
  departureTimeUTC: string;
  departureDateLocal: string;
  departureTimeLocal: string;
  departureUTCConversion: string;
  departureStatusDescription: string;
  departureStatusColorCode: string;
  isDepartureHomeBaseAirport: boolean;
  nextArrivalGroundStopGUID: string;
  nextArrivalICAO: string;
  nextArrivalIATA: string;
  nextArrivalICAO_IATA: string;
  nextArrivalDateUTC: string;
  nextArrivalTimeUTC: string;
  nextArrivalDateLocal: string;
  nextArrivalTimeLocal: string;
  nextArrivalUTCConversion: string;
  nextArrivalStatusDescription: string;
  nextArrivalStatusColorCode: string;
  isNextArrivalHomeBaseAirport: boolean;
  departureDateTimeUTCText: string;
  nextArrivalDateTimeUTCText: string;
  departureDateTimeLocalText: string;
  nextArrivalDateTimeLocalText: string;
  departureDateTimeUTC: Date;
  nextArrivalDateTimeUTC: Date;
  queryHour: number;
  filterBy: string;
  textSearch: string;
  includeDemo: boolean;
  departureVendorList: VendorModel[];
  nextArrivalVendorList: VendorModel[];
  departureServiceClassTaskStatusList: GroundStopTaskModel[];
  nextArrivalServiceClassTaskStatusList: GroundStopTaskModel[];
  departureLatitude: number;
  departureLongitude: number;
  nextArrivalLatitude: number;
  nextArrivalLongitude: number;
  crewList: string;
  paxList: string;
  departureManifest: GroundStopManifestModel[];
  arrivalManifest: GroundStopManifestModel[];
  departureAirportName: string;
  nextArrivalAirportName: string;
  isDeptFerryFlight: boolean;
  departureCrewCount: number;
  departurePaxCount: number;
  checklistStatusIconName: string;
  checklistStatusIconColor: string;
  checklistStatusDescription: string;
  checklistStatusSecondaryDescription: string;
  departureCountryGUID: string;
  nextArrivalCountryGUID: string;
  picPersonGUID: string;
  picPersonName: string;
  picPersonNameAbbr: string;
  sicPersonGUID: string;
  sicPersonName: string;
  sicPersonNameAbbr: string;
  fARTypeDescription: string;
  crewCount: number;
  paxCount: number;
  hasHaltServices: boolean;
  departurePetsOnBoard: boolean;
  departureWeaponsOnBoard: boolean;
  departureCallSign: string;
  departureFARTypeDescription: string;
  departureFAR_CallSign: string;
  eteChanged: boolean;
  isPICChanged: boolean;
  isSICChanged: boolean;
  tripCodeType: string;
  tripCodeDesc: string;
  tripEstimate: string;
  tripConsultation: string;
  tripCloseOutID: number;
  lockdownTrip: boolean;
  departureGroundStopTypeGUID: string;
  departureGroundStopTypeDescription: string;
  nextArrivalGroundStopTypeGUID: string;
  nextArrivalGroundStopTypeDescription: string;
  isNextArrFerryFlight: boolean;
  departureDateTextUTC: string;
  departureDateTimeUTCDisplay: string;
  departureDateTextLocal: string;
  departureStatusID: number;
  departureUTCTimeConversion: string;
  departureDateTimePassed: boolean;
  departureAirportState_CountryCode: string;
  departureCurrentUTCTimeConversion: string;
  isDepartureTechStop: boolean;
  nextArrivalDateTextUTC: string;
  nextArrivalDateTimeUTCDisplay: string;
  nextArrivalDateTextLocal: string;
  nextArrivalStatusID: number;
  nextArrivalUTCTimeConversion: string;
  nextArrivalDateTimePassed: boolean;
  nextArrivalAirportState_CountryCode: string;
  nextArrivalGroundStopSequenceID: number;
  nextArrivalCurrentUTCTimeConversion: string;
  isNextArrivalTechStop: boolean;
  tripLegOrder: number;
  departureGroundStopTypeColor: string;
  nextArrivalGroundStopTypeColor: string;
  tripLegGroundStopAlternateAirportList: GroundStopAlternateAirportModel[];
  departureGroundStopAlternateAirportList: GroundStopAlternateAirportModel[];
  nextArrivalGroundStopAlternateAirportList: GroundStopAlternateAirportModel[];
  etopGroundStopAlternateAirportList: GroundStopAlternateAirportModel[];
  etpGroundStopAlternateAirportList: GroundStopAlternateAirportModel[];
  allCrewPaxList: GroundStopManifestModel[];
  pic: GroundStopManifestModel;
  sic: GroundStopManifestModel;
  tripCrewList: GroundStopManifestModel[];
  tripPAXList: GroundStopManifestModel[];
  departureFARTypeID: number;
  arrReadOnly: boolean;
  depReadOnly: boolean;
  isNextArrivalTechStopNumber: number;
  cancelled: boolean;
  departureDateUTC_DTType: Date;
  nextArrivalDateUTC_DTType: Date;
  departureDateLocal_DTType: Date;
  nextArrivalDateLocal_DTType: Date;
  arrStartAt: Date;
  depStartAt: Date;
  isValidArrDate: boolean;
  isValidArrTime: boolean;
  isValidDeptDate: boolean;
  isValidDeptTime: boolean;
  displayTripLegOrder: number;
  leg_ICAOs: string;
  leg_IDs: string;
  personGUID: string;
  departureAirportInfo: string;
  nextArrivalAirportInfo: string;
  groundStopTaskList: GroundStopTaskModel[];
  departureGroundStopTaskList: GroundStopTaskModel[];
  nextArrivalGroundStopTaskList: GroundStopTaskModel[];
  speed: number;
  legGroundStopTypeGUID: string;
  tempTripCode: string;
  hasCrewPax: boolean;
  groundStopManifestDocumentList: GroundStopManifestDocumentModel[];
  groundStopManifestPassportCount: number;
  personLegType: string;
  personType: string;
  isDateTimeConflict: boolean;
  hasRequiredDocument: boolean;
  departureOnHold: boolean;
  nextArrivalOnHold: boolean;
  messageCount: number;
  hasContingencyStops: boolean;
  contingencyCount: number;
  contingencyCountText: string;
  isDepartureEditable: boolean;
  isNextArrivalEditable: boolean;
  isContingencyLeg: boolean;
  selected: boolean;
  groundStopBriefInfo: string;
  groundStopList: GroundStopModel[];
  hasExpiredPassport: boolean;
  isPassportNotRequired: boolean;
  personDocumentTooltip: string;
}
