<div *ngIf="grantAccessToManageAccountGrouping" style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em; " cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div>
      <div style="margin-top:0.3125em;"><h5>ACCOUNTING GROUPING</h5></div>
      <div style="margin-top:-0.5em;">Group Name: {{customerGroupName}}</div>
    </div>
  </div>
  <div>
    <form *ngIf="account_group_selection_form" [formGroup]="account_group_selection_form">
      <div style="margin-top:-0.5em; padding-left: 1em; padding-right:1em; ">
        <div>
          <div style="margin-top:1.5em; background-color: white; height: 25em; overflow-y:auto;">
            <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="customer_name">
                  <th mat-header-cell *matHeaderCellDef style="width:85%; text-align: left !important">
                    Client Account Name
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: left !important">{{element.customerName}}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef style="width:15%">
                    <div>Action</div><div style="margin-top:0em;padding-bottom:0.25em"><a mat-button class="btn-high grid" style="align-self:center" (click)="clickAdd($event);">Add</a></div>
                  </th>
                  <td mat-cell *matCellDef="let element">           
                      <a mat-button class="btn-low grid" style="align-self:center" (click)="clickRemove($event, element);">
                        Remove
                      </a>                    
                  </td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>
          </div>
          <!--client list-->
          <div *ngIf="showClientList" style="position: absolute; float: left; min-width: 12em; margin-top: -27.25em; margin-left: 25em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
            <div style="display:flex; justify-content:space-between">
              <div style="font-weight: bold; text-decoration:underline">Client Accounts</div>
              <div style="float:right" (click)="showClientList=!showClientList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
            </div>
            <div style="font-size: small; margin-left:0em">
              <div style="margin-right:0.5em;">
                <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                  <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                  <input type="text" formControlName="client_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="clientSearchChange($event)" />
                </div>
              </div>
            </div>
            <div *ngIf="customerList.length>0" style="max-height: 18em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
              <div *ngFor="let c of customerList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                <div #tripClientRefs [attr.customerGUID]="c.customerGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                  <div>
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="checkClientChange($event, c)" [checked]="c.selected">{{c.customerName}}</mat-checkbox>
                  </div>               
                </div>
              </div>
            </div>
            <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
            <div style="display: flex; float: right">
              <div style="margin-right:0.5em">
                <button type="button" mat-button class="btn-low" (click)="clickResetClient()" style="height:2em">RESET</button>
              </div>
              <div>
                <button type="button" mat-button class="btn-high" (click)="clickDoneClient()" style="height:2em">DONE</button>
              </div>
            </div>
          </div>
          <!--client list end-->
        </div>
      </div>
    </form>
  </div>
  <div style="justify-content:space-between;padding-left: 1em; padding-right:1em; padding-top:0.5em; padding-bottom:0.5em;display: flex;height:3em">
    <div style="display: flex;">
      <div style="margin-right:1em">
        <button mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
      </div>
      <div>
        <button mat-button class="btn-low" (click)="clickAudit()"><div style="display:flex"><div>Audit</div></div></button>
      </div>
      <div *ngIf="showSuccessMsg" style="margin-top: -0.25em;">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
    </div>
  </div>
</div>
