import { GroundStopAlternateAirportModel } from "./ground-stop-alternate-airport.model";
import { HandlingNotesModel } from "./handling-notes.model";
import { HoursObjModel } from "./hoursObj.model";
import { PersonCommsAddressModel } from "./person-comms-address.model";
import { PersonMailAddressModel } from "./person-mail-address.model";
import { PersonModel } from "./person.model";
import { VendorDocumentModel } from "./vendor-document.model";
import { VendorEquipmentModel } from "./vendor-equipment.model";
import { VendorFacilityModel } from "./vendor-facility.model";
import { VendorHoursModel } from "./vendor-hours.model";
import { VendorNotesModel } from "./vendor-notes.model";
import { VendorPersonModel } from "./vendor-person.model";
import { VendorServicesModel } from "./vendor-services.model";
import { VendorThirdPartyServicesModel } from "./vendor-third-party-services.model";

export class VendorModel {
  vendorGUID: string;
  vendorName: string
  vendorNameCom: string;
  icao: string;
  countryName: string;
  canObtainMEA: boolean;
  vendorID: number;
  vendorFuelID: number;
  permitType: string;
  vendorComms: PersonCommsAddressModel[];
  intoPlaneAgent: string;
  vendorNotes: VendorNotesModel[];
  groundHandler: string;
  contractRequired: string;
  contractSigned: string;
  facilityDescription: string;
  vendorRoleDescription: string;
  iconName: string;
  isPreferred: boolean;
  vendorServices: VendorServicesModel[];
  vendorEquipment: VendorEquipmentModel[];
  vendorFacilities: VendorFacilityModel[];
  vendorThirdPartyServices: VendorThirdPartyServicesModel[];
  vendorMailAddress: PersonMailAddressModel[];
  searchKey: string;
  searchType: string;
  hasHandlingNotes: boolean;
  hasAirportNotes: boolean;
  hasCustomsNotes: boolean;
  hasContacts: boolean;
  handlingNotes: HandlingNotesModel[];
  airportNotes: HandlingNotesModel[];
  customsNotes: HandlingNotesModel[];
  vendorRoleID: number;
  vendorPersons: VendorPersonModel[];
  mainVendorName: string;
  personList: PersonModel[];
  totalRecords: number;
  countryGUID: string;
  roleList: string;
  complianceStatusID: number;
  ratingID: number;
  contractStatusID: number;
  vendorMethodOfPaymentID: number;
  pageIndex: number;
  pageSize: number;
  contractStatusDescription: string;
  vendorComplianceStatusDescription: string;
  regulatoryStatus: string;
  operationStatus: string;
  accountingStatus: string;
  ratingDescription: string;
  vendorMethodOfPaymentDescription: string;
  sortOrder: string;
  facilityID: number;
  isRegulatoryCompliant: number;
  isOperationsCompliant: number;
  isAccountingCompliant: number;
  complianceStatusNote: string;
  regulatoryComplianceNote: string;
  accountingComplianceNote: string;
  operationsComplianceNote: string;
  accountCreationDate: string;
  modifiedBy: string;
  modifiedDate: string;
  legalEntityName: string;
  vendorStatusDescription: string;
  vendorStatusID: number;
  airportID: number;
  roleIDList: string;
  selected: boolean;
  relatedVendorList: string;
  relatedVendorIDList: string;
  serviceArrangementID: number;
  flightBriefNoteCount: number;
  serviceArrangement: string;
  complianceTooltip: string;
  sendEmail: boolean;
  permitTypeID: number;
  relatedVendors: VendorModel[];
  vendorHours: VendorHoursModel[];
  alternates: GroundStopAlternateAirportModel[];
  hasHours: boolean;
  customsType: string;
  customsTypeID: number;
  iata: string;
  vendorDocuments: VendorDocumentModel[];
  hexColor: string;
}
