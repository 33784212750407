import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { GroundStopCreatorDialogComponent } from './ground-stop-creator-dialog.component';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GlobalConstant } from '../common-utility/global-constant';
import { GroundStopCreatorCityPairDialogComponent } from './ground-stop-creator-citypair-dialog.component';
import { DialogService } from '../services/dialog.service';
import { CustomPageSizeOptionModel } from '../models/custom-page-size-option.model';
import { DisplayDialogComponent } from '../ckeditor5/display-dialog.component';

@Component({
  selector: 'app-trip-code-tactical-viewlist',
  templateUrl: './trip-code-tactical-viewlist.component.html',
  styleUrls: ['./trip-code-tactical-viewlist.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})

export class TripCodeTacticalViewlistComponent implements OnInit, OnChanges {
  //@Input() customerGUID: string;
  //@Input() text_search: string;
  //@Input() filter_by: string;
  //@Input() selectedGSTypeList: GroundStopTypeModel[];
  //@Input() includeClosedBilledRecords: boolean;
  @Input() request: TripModel;
  @Input() reloadChildPage: boolean;
  @Input() selectedTabName: string;
  @Input() v: number;
  @Output() public sendDataToParent = new EventEmitter();

  //customerGUID: string;
  trip_code_viewlist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  //customerList: CustomerModel[];
  userType: string;
  showSpin: boolean = false;
  //selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 50;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  customPageSizeOptionList: CustomPageSizeOptionModel[] = [];
  minPageSize: number = 50;
  maxPageSize: number = 100;
  recordList: TripModel[];
  subscription: Subscription;
  //displayedColumn: string[] = ['tripcode', 'registration', 'clientName', 'crew', 'pax', 'createdDate', 'dateRange', 'route', 'clientInstruction', 'additionalRequirement', 'action'];
  displayedColumn: string[] = ['tripcode', 'registration', 'clientName', 'crew', 'pax', 'createdDate', 'dateRange', 'route', 'clientInstruction', 'clientRef', 'action'];
  scrHeight: number;
  upPII: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _dialogService: DialogService
  ) {

  }

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.reloadChildPage || this.selectedTabName !='tripcode_tactical') {
      return;
    }

    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }

    this.trip_code_viewlist_form = this._formBuilder.group({

    });

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.customPageSizeOptionList = [];
    this.scrHeight = window.innerHeight;
    //if (this.scrHeight < 900) {
    //  this.pageSize = 10;
    //}
    //else {
    //  this.pageSize = 15;
    //}
    

    this.initControls();  
    this.getSearchResults();

  }


  initControls() {
    this.trip_code_viewlist_form = this._formBuilder.group({
      custom_page_size_select1: [this.pageSize]
    });
  }

  get f() { return this.trip_code_viewlist_form.controls; }

  getSearchResults() {
    this.showSpin = true;
    this.totalRecordsText = "";
    if (this.subscription)
      this.subscription.unsubscribe();
    this.subscription = this._groundStopService.getTripListForTripCodeViewInternalByConditions<ResponseModel<TripModel[]>>(this.request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0)  {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.request.pageSize)
            this.buildCustomPageSizeOption();
          }

        }
        else {
          this.totalRecordsText = "No record found";

          //this._dialogService.openAlertDialog("Failed to create the trip at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
        this.showSpin = false;
        this.sendDataToParent.emit({ totalRecordsText: this.totalRecordsText, customerId: this.request.customerGUID, totalRecords: this.totalRecords, totalPageNumber:this.totalPageNumber   }); 
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin =false;
    })
    
  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
      this.request.pageSize = this.pageSize;    
      this.request.totalRecords = this.totalRecords;
    }
    this.request.pageIndex = this.currentPageIndex;
    this.getSearchResults();

  }


  buildCustomPageSizeOption() {
    this.customPageSizeOptionList = [];
    let obj: CustomPageSizeOptionModel;
    if (this.totalRecords > 0 && this.totalRecords > this.minPageSize) {
      obj = new CustomPageSizeOptionModel();
      obj.pageSizeText = this.minPageSize.toString();
      obj.pageSizeValue = this.minPageSize;
      this.customPageSizeOptionList.push(obj);

      obj = new CustomPageSizeOptionModel();
      obj.pageSizeText = this.maxPageSize.toString();
      obj.pageSizeValue = this.maxPageSize;
      this.customPageSizeOptionList.push(obj);

      if (this.totalRecords > this.maxPageSize) {
        obj = new CustomPageSizeOptionModel();
        obj.pageSizeText = "All";
        obj.pageSizeValue = 10000;
        this.customPageSizeOptionList.push(obj);
      }

    }
  }

  customPageSizeChange(e: any, controlNumber: number) {
    this.currentPageIndex = 0;
    if (controlNumber == 1) {
      this.pageSize = this.f.custom_page_size_select1.value;
      //this.f.custom_page_size_select2.setValue(this.pageSize);
    }
    else {
      this.pageSize = this.f.custom_page_size_select2.value;
      this.f.custom_page_size_select1.setValue(this.pageSize);
    }
    this.getSearchResults();
  }


  clickAdd(event: any, p: TripModel) {   
    let s = ""; 
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, customerGUID: "", v: this._authService.getCurrentTimeNumber() };
    
    //const dialogRef = this._dialog.open(GroundStopCreatorDialogComponent, dialogConfig);
    const dialogRef = this._dialog.open(GroundStopCreatorCityPairDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        //if (this.userType == "internal" && this.request.customerGUID == "") {
        //  if (result.newId.toLowerCase() == 'b00c8207-4290-44ef-a756-ff3972839273') {
        //    this.request.customerGUID = result.newId;
        //  }
        //}
        let trip: TripModel;
        trip = new TripModel();
        trip.tripCodeGUID = result.newId;
        trip.aircraftGUID = result.aircraftGUID;
        trip.customerGUID = result.customerGUID;
        this.clickOpen(null,trip );
        //this.getSearchResults();
      }
      
    });
  }

  clickOpen(event: any, p: TripModel) {
    //this._router.navigate(['/ground-stops/trip-details', { pId: p.personGUID }]);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "tripId": p.tripCodeGUID,
        "aircraftId": p.aircraftGUID,
        "customerId": p.customerGUID,
        "pf": "",
        tType: p.tripCodeType,
        v: this._authService.getCurrentTimeNumber()
      }
    };
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    //this._router.navigate(['/ground-stops/trip-details-internal'], navigationExtras);
    window.open('/ground-stops/trip-details-internal?customerId=' + p.customerGUID + "&tripId=" + p.tripCodeGUID + "&aircraftId=" + p.aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
  }

  viewNotes(trip: TripModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { dialogTitle: "Trip Notes", displayText: trip.clientInstructions };

    this._dialog.open(DisplayDialogComponent, dialogConfig);
  }
}
