<div mat-dialog-content style="margin-top:-1em;padding-bottom:0;">
  <form novalidate *ngIf="weather_charts_form" [formGroup]="weather_charts_form">

    <div class="container" style="background-color:white;">
      <div>
        <app-spin *ngIf="showSpin"></app-spin>

        <div class="row justify-content-left no-gutters" style="min-width:55em">
          <div class="col-3 form-group">
            <label style="font-size:small">Region</label>
            <mat-selection-list #chartRegion (selectionChange)="onRegionChange(chartRegion.selectedOptions.selected)">
              <mat-list-option *ngFor="let region of chartRegionList" [value]="region">
                {{region.weatherChartRegion}}
              </mat-list-option>
            </mat-selection-list>
          </div>
          <div *ngIf="selectedRegionList.length>0" class="col-3 form-group">
            <label style="font-size:small">Category</label>
            <mat-selection-list #chartCategory (selectionChange)="onCategoryChange(chartCategory.selectedOptions.selected)">
              <mat-list-option *ngFor="let category of chartCategoryList" [value]="category">
                {{category.weatherChartCategory}}

              </mat-list-option>
            </mat-selection-list>
          </div>
          <div *ngIf="selectedCategoryList.length>0 && chartTypeList.length>0" class="col-3 form-group">
            <label style="font-size:small">Type</label>
            <mat-selection-list #chartType (selectionChange)="onTypeChange(chartType.selectedOptions.selected)">
              <mat-list-option *ngFor="let type of chartTypeList" [value]="type">
                {{type.weatherChartType}}
              </mat-list-option>
            </mat-selection-list>
          </div>
          <div *ngIf="selectedTypeList.length>0 && chartTimeRangeList.length>0" class="col-3 form-group">
            <label style="font-size:small">Time Range</label>
            <mat-selection-list #chartTimeRange (selectionChange)="onTimeRangeChange(chartTimeRange.selectedOptions.selected)">
              <mat-list-option *ngFor="let timeRange of chartTimeRangeList" [value]="timeRange">
                {{timeRange.weatherChartTimeRange}}
              </mat-list-option>
            </mat-selection-list>
          </div>

        </div>
        <div class="pb-2">
          <div style="display:flex; margin-top:0.5em" *ngIf="chartList.length>0">
            <div style="max-height: 20em; overflow-y: auto;overflow-x:hidden;">
              <table mat-table *ngIf="availChartList" [dataSource]="availChartList" class="example-container2">
                <tr>
                  <ng-container matColumnDef="chartURL">
                    <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">
                      Please Select Chart
                    </th>
                    <td mat-cell *matCellDef="let element" style="border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickAddItem($event, element)">{{element.weatherChartRegion + ' / ' + element.weatherChartCategory}}{{element.weatherChartTypeID==null? '': ' / '}}{{element.weatherChartType}}{{element.weatherChartTimeRangeID==null? '':' / '}}{{element.weatherChartTimeRange}}</a></td>
                  </ng-container>
                  <ng-container matColumnDef="preview">
                    <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">

                    </th>
                    <td mat-cell *matCellDef="let element" style="border-top: 0.0625em solid #000"><a href="{{element.weatherChartURL}}" target="_blank" type="button" mat-button class="btn-high" rel="noopener noreferrer">View Map</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
            <div style="margin-top: 2em;margin-left:1em">
              <button type="button" class="btn-high" mat-button [hidden]="availChartList && availChartList.length==0" (click)="clickSelectAll()" style="width: 7em">Select All</button><br /><br />
              <button type="button" class="btn-low" mat-button [hidden]="selChartList && selChartList.length==0" (click)="clickRemoveAll()" style="width: 7em">Remove All</button>
            </div>
            <div style="margin-left:1em;max-height: 20em; overflow-y: auto;overflow-x:hidden">
              <table mat-table *ngIf="selChartList" [dataSource]="selChartList" class="example-container2">
                <tr>
                  <ng-container matColumnDef="chartURL">
                    <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">
                      Selected Charts
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickRemoveItem($event, element)">{{element.weatherChartRegion + ' / ' + element.weatherChartCategory}}{{element.weatherChartTypeID==null? '': ' / '}}{{element.weatherChartType}}{{element.weatherChartTimeRangeID==null? '':' / '}}{{element.weatherChartTimeRange}}</a></td>
                  </ng-container>
                  <ng-container matColumnDef="preview">
                    <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">
                      Preview
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000;font-size:x-small"><a href="{{element.weatherChartURL}}" target="_blank" type="button" class="btn btn-primary" rel="noopener noreferrer">View Map</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>


            </div>
          </div>
        </div>
      </div>
      

      
      
      
    </div>
    </form>
</div>

<div class="d-inline-flex">
  <button type="button" mat-button class="mt-2 btn-high" (click)="saveWeatherCharts(true)"><span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>Save</button>
  <button type="button" mat-button class="mt-2 ml-2 btn-high" (click)="createDoc('wxword')"><span *ngIf="isLoadingWord" class="spinner-border spinner-border-sm mr-1"></span>Export to Word</button>
  <button type="button" mat-button class="mt-2 ml-2 btn-high" (click)="createDoc('wxpdf')"><span *ngIf="isLoadingPDF" class="spinner-border spinner-border-sm mr-1"></span>Export to PDF</button>
  <button class="mt-2 ml-2 btn-low" mat-button mat-dialog-close>Close</button>
  <div *ngIf="errMsg!=''" class="alert alert-danger small ml-2 mt-2 p-0" style="white-space:pre-wrap;width:max-content">{{errMsg}}</div>
  <div *ngIf="showSuccessMsg" class="mt-1 ml-2">
    <app-success-message [successMsg]=""></app-success-message>
  </div>
</div>
