<div style="background-color:#f8f8f8;">
    <div style="padding-left:1em; padding-right: 1em;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div>
            Are you sure you want to update the route sequence?
        </div>
        <div class="p-2">
            <button mat-button class="btn-high mr-2" (click)="updateRouteSequenceAndRefresh()">Save</button>
            <button mat-button class="btn-low" [mat-dialog-close]>Close</button>
          </div>
    </div>
</div>