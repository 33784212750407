import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CategoryModel } from '../models/category.model';
import { HelpGuideModel } from '../models/help-guide.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CONTAINER_DATA, HelpGuideService } from '../services/help-guide.service';
import { HelpGuideCategoriesComponent } from './help-guide-categories.component';
import { HelpGuideDetailsEditComponent } from './help-guide-details-edit.component';
import { HelpGuideTopicsComponent } from './help-guide-topics.component';
import { HelpGuidesComponent } from './help-guides.component';

@Component({
  selector: 'app-help-guide-details',
  templateUrl: './help-guide-details.component.html',
  styleUrls: ['./help-guide-details.component.css']
})


export class HelpGuideDetailsComponent implements OnInit {
  helpGuideGUID: string;
  guide: HelpGuideModel;
  componentPortal: ComponentPortal<HelpGuideTopicsComponent>;
  detailsPortal: ComponentPortal<HelpGuideDetailsEditComponent>;
  isAdmin: boolean = false;
  constructor(private readonly _authService: AuthenticateService, private readonly _helpGuideService: HelpGuideService, private sanitized: DomSanitizer,
    private readonly helpGuide: HelpGuidesComponent, @Inject(CONTAINER_DATA) public componentData: any, private _injector: Injector  ) {
    this.helpGuideGUID = componentData;
  }
    ngOnInit() {
      this._authService.updateAccessTime();
      if (localStorage.getItem('up.help') == 'true') {
        this.isAdmin = true;
      }       
     // this.isAdmin = Boolean(localStorage.getItem('ua'));
      this._helpGuideService.getHelpGuideByHelpGuideGUID<ResponseModel<HelpGuideModel>>(this.helpGuideGUID).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.guide = response.model;
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }

          }
        }
      });
    }


  goBack() {
    this.componentPortal = new ComponentPortal(HelpGuideTopicsComponent, null, this.createInjector(this.guide));
    this.helpGuide.selectedPortal = this.componentPortal;
  }

  editHelpGuide() {
    this.detailsPortal = new ComponentPortal(HelpGuideDetailsEditComponent, null, this.createInjector({ helpGuideGUID: this.guide.helpGuideGUID, categoryID: this.guide.categoryID }));
    this.helpGuide.selectedPortal = this.detailsPortal;

  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  getHTML(data: any) {
    return this.sanitized.bypassSecurityTrustHtml(data);
  }


}
