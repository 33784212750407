import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Route, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { AircraftTypeSpeedModel } from '../models/aircraft-type-speed.model';
import { AircraftModel } from '../models/aircraft.model';
import { AirportModel } from '../models/airport.model';
import { CountryModel } from '../models/country.model';
import { CustomerHomebaseAirportModel } from '../models/customer-homebase-airport.model';
import { FlightPlanProviderModel } from '../models/flight-plan-provider.model';
import { ReservePolicyModel } from '../models/reserve-policy.model';
import { ResponseModel } from '../models/response.model';
import { AircraftService } from '../services/aircraft.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-aircraft-flight-planning',
  templateUrl: './aircraft-flight-planning.component.html',
  styleUrls: ['./aircraft-flight-planning.component.css']
})
/** aricraft-profile-details component*/
export class AircraftFlightPlanningComponent implements OnInit {
  @Input() aircraftId: string;
  aircraft_flight_planning_form: UntypedFormGroup;
  aircraftGuid: string;
  errMsg: string;
  showSpin: boolean;
  loading: boolean;
  submitted: boolean;
  msg: string;
  userType: string;
  color: string;
  selcal: string;
  eltBeaconId: string;
  isModified: boolean;
  customerGuid: string;
  textSearch: string;
  filterBy: string;
  flightPlanProviderId: number;
  domReserve: string;
  intlReserve: string;
  fileByAircraft: number;
  filingCompanyName: string;
  flagDiv: boolean = false;
  reserveList: ReservePolicyModel[];
  fpList: FlightPlanProviderModel[];
  uplinkFrequency: string = "";
  uplinkVendor: string;
  registration: string;
  reserveFuel: string;
  holdFuel: string;
  reserveFuelDom: string;
  holdFuelDom: string;
  hexCode: string;
  isSame: boolean;
  isSameTxt: boolean;
  @ViewChild("chkSameAsIntl") chkSameAsIntl;
  @ViewChild("chkSameAsIntlTxt") chkSameAsIntlTxt;
  showSuccessMsg: boolean = false;
  controlValueChanged: boolean = false;
  isDisabled: boolean = false;
  constructor(private readonly _authService: AuthenticateService, private readonly _commonService: CommonService, private readonly _aircraftService: AircraftService, private readonly _dialogService: DialogService,
    private readonly _formBuilder: UntypedFormBuilder, private _route: ActivatedRoute, private _customerService: CustomerService, private el: ElementRef,) {

  }

  ngOnInit() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    this._route.queryParams.subscribe(params => {
      this.aircraftGuid = params["aircraftGuid"];
      this.customerGuid = params["customerGuid"];
      this.textSearch = params["textSearch"];
      this.filterBy = params["filterBy"];
    });
    this.aircraftGuid = this.aircraftId;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        this.isDisabled = true;
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this.getData();
    //this.initControls()


  }

  getAllData(): Observable<any[]> {
    let getReservePolicyListResponse = this._commonService.getReservePolicyList<ResponseModel<ReservePolicyModel[]>>();
    let getFPProviderResponse = this._commonService.getFlightPlanProviderList<ResponseModel<FlightPlanProviderModel[]>>();
    let aircraftResponse = this._aircraftService.getAircraftProfileByaId<ResponseModel<AircraftModel>>(this.aircraftGuid);
    return forkJoin([getReservePolicyListResponse, getFPProviderResponse, aircraftResponse]);
  }

  getData() {
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.reserveList = responses[0].model;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.fpList = responses[1].model;

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          let aircraft = responses[2].model;
          this.flightPlanProviderId = aircraft.flightPlanProviderID;
          this.domReserve = aircraft.domesticReserve;
          this.intlReserve = aircraft.internationalReserve;
          if (this.intlReserve != null && this.intlReserve != "")
              this.isSame = (this.domReserve == this.intlReserve);
          if (aircraft.fileByAircraftRegistration == true)
            this.fileByAircraft = 1;
          else
            this.fileByAircraft = 0;
          this.filingCompanyName = aircraft.filingCompanyName;
          this.customerGuid = aircraft.customerGuid;
          this.registration = aircraft.registration;
          this.uplinkVendor = aircraft.uplinkVendor;
          if (aircraft.uplinkFrequency != null)
            this.uplinkFrequency = aircraft.uplinkFrequency.toString();
          this.reserveFuel = aircraft.reserveFuel;
          this.holdFuel = aircraft.holdFuel;
          this.reserveFuelDom = aircraft.reserveFuelDom;
          this.holdFuelDom = aircraft.holdFuelDom;
          if (this.holdFuel == this.holdFuelDom && this.reserveFuel == this.reserveFuelDom && (this.holdFuel != null || this.reserveFuel != null) && (this.holdFuel != "" || this.reserveFuel !=""))
            this.isSameTxt = true;
          this.hexCode = aircraft.hexCode;
          this.color = aircraft.color;
          this.selcal = aircraft.selcal;
          this.eltBeaconId = aircraft.eltBeaconID; 
          this.initControls();
          //this.setValidation();

        }
      }

      else {
        if (responses[3].code == "401") {
          this._authService.signOut();
        }
      }


    });



    this.initControls();   
   // this.setValidation();
    this.checkFormControlValueChanged();

  }


  checkFormControlValueChanged(): void {
    this.aircraft_flight_planning_form.valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
  }


  checkSameAsIntl() {
    if (this.chkSameAsIntl._checked)
      this.f.dom_res.setValue(this.f.intl_res.value);
  }

  checkSameAsIntlTxt() {
    if (this.chkSameAsIntlTxt._checked) {
      this.f.reserveDom.setValue(this.f.reserve.value); 
      this.f.holdDom.setValue(this.f.hold.value);
    }
  }

  initControls() {
    this.aircraft_flight_planning_form = this._formBuilder.group({
      fp_provider: [{ value: this.flightPlanProviderId, disabled: this.isDisabled }/*, Validators.required*/],
      dom_res: {
        value: this.domReserve, disabled: this.isDisabled
    },
      intl_res: { value:  this.intlReserve, disabled: this.isDisabled },
      uplink_vendor: [{ value: this.uplinkVendor, disabled: this.isDisabled }/*, Validators.required*/],
      uplink_frequency: [{ value: this.uplinkFrequency, disabled: this.isDisabled }/*, Validators.required*/],
      color: {
        value: this.color, disabled: this.isDisabled
      },
      sel_cal: [{ value: this.selcal, disabled: this.isDisabled }, [Validators.pattern('^[a-zA-Z]*$'), Validators.minLength(4)]],
      elt_beacon: { value: this.eltBeaconId, disabled: this.isDisabled },
      file_by_aircraft: [{ value: this.fileByAircraft, disabled: this.isDisabled }/*, Validators.required*/],
      filing_company_name: [{ value: this.filingCompanyName, disabled: this.isDisabled }/*, Validators.required*/],
      reserve: { value: this.reserveFuel, disabled: this.isDisabled },
      hold: { value: this.holdFuel, disabled: this.isDisabled },
      reserveDom: { value: this.reserveFuelDom, disabled: this.isDisabled },
      holdDom: { value: this.holdFuelDom, disabled: this.isDisabled },
      hex_code: [{ value: this.hexCode, disabled: this.isDisabled }, Validators.pattern('^[a-zA-Z0-9]*$')]
    });
  }

  get f() { return this.aircraft_flight_planning_form.controls; }

  setValidation() {
      this.f.dom_res.clearValidators();
      this.f.intl_res.clearValidators();
      this.f.reserve.clearValidators();
      this.f.hold.clearValidators();
      switch (this.f.fp_provider.value) {
        case 1:
          this.f.dom_res.setValidators(Validators.required);
          this.f.intl_res.setValidators(Validators.required);
          break;
        case 2:
          this.f.reserve.setValidators(Validators.required);
          this.f.hold.setValidators(Validators.required);
          break;
      }
      this.f.dom_res.updateValueAndValidity();
      this.f.intl_res.updateValueAndValidity();
      this.f.reserve.updateValueAndValidity();
      this.f.hold.updateValueAndValidity();
    

  }
  

  changeFPProvider() {
   // this.setValidation();
  }

  clickSave() {
    this.loading = true;
    this.submitted = true;
    this.errMsg = "";
    if (this.aircraft_flight_planning_form.invalid || this.errMsg != "") {
      return;
    }
    let obj = new AircraftModel();
    obj.aircraftGuid = "";
    obj.customerList = "";
    obj.pageName = "FlightPlanning";
    if (this.aircraftGuid != "" && this.aircraftGuid != null)
      obj.aircraftGuid = this.aircraftGuid;
    obj.color = this.f.color.value;
    obj.selcal = this.f.sel_cal.value;
    obj.eltBeaconID = this.f.elt_beacon.value;
    obj.fileByAircraftRegistration = false;
    if (this.f.file_by_aircraft.value == "1")
      obj.fileByAircraftRegistration = true;
    else
      obj.fileByAircraftRegistration = false;
    obj.filingCompanyName = this.f.filing_company_name.value;
    obj.registration = this.registration;
    obj.flightPlanProviderID = this.f.fp_provider.value;
    if (obj.flightPlanProviderID == 1) {
      obj.domesticReserve = this.f.dom_res.value;
      obj.internationalReserve = this.f.intl_res.value;
      obj.reserveFuel = "";
      obj.holdFuel = "";
      obj.reserveFuelDom = "";
      obj.holdFuelDom = "";
      this.f.reserve.setValue("");
      this.f.hold.setValue("");
      this.f.reserveDom.setValue("");
      this.f.holdDom.setValue("");
    }
    else {
      obj.domesticReserve = null;
      obj.internationalReserve = null;
      obj.reserveFuel = this.f.reserve.value;
      obj.holdFuel = this.f.hold.value;
      obj.reserveFuelDom = this.f.reserveDom.value;
      obj.holdFuelDom = this.f.holdDom.value;
      this.f.dom_res.setValue("");
      this.f.intl_res.setValue("");
    }
    obj.uplinkVendor = this.f.uplink_vendor.value;
    // obj.uplinkFrequency = this.f.uplink_frequency.value;
    switch (this.f.uplink_frequency.value) {
      case "1":
        obj.uplinkFrequency = 1;
        break;
      case "2":
        obj.uplinkFrequency = 2;
        break;
      case "3":
        obj.uplinkFrequency = 3;
        break;
    }
    
    obj.hexCode = this.f.hex_code.value;

    this._aircraftService.saveAircraft<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.aircraftGuid = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          //this.f.modifiedBy.setValue(localStorage.getItem('un'));
          //this.f.modifiedDate.setValue(dt);
          this.isModified = true;
          this.controlValueChanged = false;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");

        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    });

  }


}
