import { Component, Inject, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GroundStopModel } from "../models/ground-stop.model";
import { Observable, forkJoin, of } from "rxjs";
import { GroundStopTaskModel } from "../models/ground-stop-task.model";
import { GroundStopAdvancedService } from "../services/ground-stop-advanced.service";
import { GroundStopService } from "../services/ground-stop.service";
import { ResponseModel } from "../models/response.model";
import { CommonService } from "../services/common.service";
import { BusinessRulesService } from "../services/business-rules-service";
import { GroundStopClientService } from "../services/ground-stop-client.service";
import { AccountingStatusModel } from "../models/accounting-status.model";
import { FileItem, FileUploader, ParsedResponseHeaders } from "ng2-file-upload";
import { FileModel } from "../models/file.model";
import { GroundStopTaskDocumentModel } from "../models/ground-stop-task-document.model";
import { TripTaskStatusModel } from "../models/trip-task-status.model";
import { AuthenticateService } from "../services/authenticate.service";
import { ActivatedRoute } from "@angular/router";
import { GlobalConstant } from "../common-utility/global-constant";
import { CustomValidators } from "../common-utility/custom.validators";
import { TripAuditDialogComponent } from "../ground-stops/trip-audit-dialog.component";
import { EmailInternalDialogComponent } from "../messages/email-internal-dialog.component";
import { TripMessageCenterComponent } from "../ground-stops/trip-message-center.component";
import { ConfirmDialogComponent } from "../common-utility/confirm-dialog.component";
import { DialogService } from "../services/dialog.service";
import { TemplateService } from "../services/template.service";
import { UtilityFunctions } from "../common-utility/utility.functions";
import { DatePipe, formatDate } from "@angular/common";
import { AirportLocatorComponent } from "../airports/airport-locator.component";
import { UkGarModel } from "../models/uk-gar.model";
import { UkGarDirectModel } from "../models/uk-gar-direct.model";
import { ValidateManifestDataComponent } from "./validate-manifest-data.component";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { HttpClient, HttpHeaders, HttpXhrBackend } from "@angular/common/http";
import { UploadTaskDocument } from "./upload-task-document";


function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

var expressions = require("angular-expressions");
var assign = require("lodash/assign");
// define your filter functions here, for example
// to be able to write {clientname | lower}
expressions.filters.lower = function (input) {
  // This condition should be used to make sure that if your input is
  // undefined, your output will be undefined as well and will not
  // throw an error
  if (!input) return input;
  return input.toLowerCase();
};
function angularParser(tag) {
  tag = tag
    .replace(/^\.$/, "this")
    .replace(/(�|�)/g, "'")
    .replace(/(�|�)/g, '"');
  const expr = expressions.compile(tag);
  return {
    get: function (scope, context) {
      let obj = {};
      const scopeList = context.scopeList;
      const num = context.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = assign(obj, scopeList[i]);
      }
      return expr(scope, obj);
    },

  };


}

function parser(tag) {
  // We write an exception to handle the tag "$pageBreakExceptLast"
  if (tag === "$pageBreakExceptLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (!isLast) {
          return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }
  if (tag === "$pageBreak") {
    return {
      get(scope, context) {
        return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';

      },
    };
  }
  if (tag === "$lineBreak") {
    return {
      get(scope, context) {
        return '<w:br/>';

      },
    };
  }
  if (tag === "$lineBreakIfLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (isLast) {
          return '<w:p><w:r><w:br/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }


  const expressionParser = require("docxtemplater/expressions.js");
  expressionParser.filters.toFixed = function (input, precision) {
    // In our example precision is the integer 2

    // Make sure that if your input is undefined, your
    // output will be undefined as well and will not
    // throw an error
    if (!input) return input;

    return input.toFixed(precision);
  };

  // We use the angularParser as the default fallback
  // If you don't wish to use the angularParser,
  // you can use the default parser as documented here:
  // https://docxtemplater.com/docs/configuration#default-parser
  return angularParser(tag);
}

const datepipe: DatePipe = new DatePipe('en-US')

@Component({
  selector: 'app-uk-gar-task',
  templateUrl: './uk-gar-task.component.html',
  styleUrls: ['./uk-gar-task.component.css']
})
export class UkGarTaskComponent implements OnInit {
  uk_gar_task_form: UntypedFormGroup
  taskStatusList: TripTaskStatusModel[];
  groundStopGUID: string;
  groundStopTaskGUID: string;
  tripCodeGUID: string;
  registration: string;
  tripCode: string = "";
  icao: string;
  nextICAO: string;
  prevICAO: string;
  arrivalDateTime: string = "";
  departureDateTime: string = "";
  arrivalDateTimeLocal: string = "";
  departureDateTimeLocal: string = "";
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  isRevisionRequired: boolean;
  vendorReq: boolean;
  lead_timeChanged: boolean = false;
  due_dateChanged: boolean = false;
  due_datetime: string;
  leadTime_Min_Value: number;
  dueDate_Min_Value: number;
  hasTaskAudit: boolean;
  errMsg2: string = '';
  serviceTypeDesc: string;
  isActive: number;
  immediateAction: number = 0;
  nextGroundStopGUID: string;
  aircraftGUID: string;
  customerGUID: string;
  clientName: string;
  depGroundStopGUID: string = "";
  selectedTaskStatusGUID: string;
  selectedTaskStatusDescription: string;
  leg: string;
  isModified: boolean = false;
  serviceTypeID: number;
  farTypeID: number;
  defaultEmail: string;
  lockdownTrip: boolean = false;
  lead_time: string = "";
  due_date: string = "";
  submitted: boolean = false;
  loading: boolean = false;
  showSuccessMsg: boolean = false;
  confirmation_number: string;
  public_notes: string = "";
  internal_notes: string = "";
  onHoldText: string = '';
  tripCodeType: string;
  selectedAcctStatusGUID: string;
  selectedAcctStatusDescription: string;
  selectedAcctStatusTPGUID: string;
  selectedAcctStatusTPDescription: string;
  acctStatusList: AccountingStatusModel[];
  acctStatus_TPList: AccountingStatusModel[];
  uploader: FileUploader;
  finishAllUploads: boolean = true;
  fileList: FileModel[] = [];
  errMsg: string = "";
  maxFileSize: number;
  maxFileUploadNumber: number;
  allowedFileTypeList: string[];
  totalMessageAttachmentFileSize: number = 0;
  allowedFileType: string;
  maxTempDocId: number = 0;
  taskDocumentList: GroundStopTaskDocumentModel[] = [];
  selectedGroundStopList: GroundStopModel[] = [];
  avoidLocationMsg: string;
  avoidBusinessRuleID: number;
  hasOverflightPermitDocument: boolean;
  avoidList: any;
  avoidLocation: boolean;
  canChangeBilling: boolean = false;
  businessRulesID: number;
  modifiedBy: string = "";
  modifiedDate: string = "";
  showDocuments: boolean = true;
  requiredDocumentCount: number = 0;
  hasExistingDocument: boolean = false;
  isGTSBillable: boolean = false;
  isThirdPartyBillable: boolean = false;
  hasModifiedDocuments: boolean = false;
  airportCountry: string = "";
  isDocLoading: boolean = false;
  taskColor: string = "";
  nextArrivalDateTimeLocal: string = "";
  nextArrivalDateTime: string = "";
  serviceLocation: string;
  showSpin: boolean = true;
  is48Hours: boolean = false;
  revisionCounter: number = 0;
  @ViewChild('internalNotesWrapper') internalNotesTemplate: TemplateRef<any>;
  @ViewChild('publicNotesWrapper') publicNotesTemplate: TemplateRef<any>;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  isRevisionRequiredValue: number;
  hasMissingInfo: boolean = false;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _dialogRef: MatDialogRef<UkGarTaskComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    private readonly _groundStopService: GroundStopService,
    private readonly _commonService: CommonService,
    private readonly _businessRulesService: BusinessRulesService,
    private readonly _groundStopClientService: GroundStopClientService,
    private readonly _authService: AuthenticateService,
    private readonly _route: ActivatedRoute,
    private readonly _dialogService: DialogService,
    private readonly _templateService: TemplateService,
    private readonly _dialog: MatDialog) {
    this.groundStopGUID = _data.groundStopGUID;
    this.groundStopTaskGUID = _data.groundStopTaskGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.icao = _data.icao;
    this.serviceTypeDesc = _data.serviceTypeDesc;
    this.customerGUID = _data.customerGUID;
    this.leg = _data.leg;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.depGroundStopGUID = _data.depGroundStopGUID;
    this.clientName = _data.clientName;
    this.serviceTypeID = _data.serviceTypeID;
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.nextICAO = "";
    this.prevICAO = "";
    this.registration = "";
    this.isActive = 1;
    this.leadTime_Min_Value = 0;
    this.dueDate_Min_Value = 0;
    this.tripCodeType = this._route.snapshot.paramMap.get('tType');
    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

    this.getAllData().subscribe(responses => {
      if (responses[0] !== null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let gs = new GroundStopModel();
          gs = responses[0].model;

          this.registration = gs.registration;
          this.tripCode = gs.tripCode;
          this.icao = gs.icao;
          this.arrivalDateTime = gs.arrivalDateTextUTC;
          this.departureDateTime = gs.departureDateTextUTC;
          this.arrivalDateTimeLocal = gs.arrivalDateTextLocal;
          this.departureDateTimeLocal = gs.departureDateTextLocal;
          this.prevICAO = gs.prevICAO;
          this.nextICAO = gs.nextICAO;
          this.aircraftManufacturer = gs.aircraftManufacturer;
          this.aircraftModel = gs.aircraftModel;
          this.aircraftInfo = gs.aircraftInfo;
          this.aircraftGUID = gs.aircraftGUID;
          this.farTypeID = gs.farTypeID;
          this.airportCountry = gs.airportState_CountryCode;
          this.nextArrivalDateTime = gs.nextArrivalDateUTC + ' ' + gs.nextArrivalTimeUTC.substring(0, gs.nextArrivalTimeUTC.lastIndexOf(':')) + ' Z';
          this.nextArrivalDateTimeLocal = gs.nextArrivalDateLocal + ' ' + gs.nextArrivalTimeLocal.substring(0, gs.nextArrivalTimeLocal.lastIndexOf(':')) + ' L';
          this.lockdownTrip = gs.lockdownTrip;

        }
      }
      if (responses[1].code == "200") {
        this.taskStatusList = responses[1].model;
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          let tempacctStatusList: AccountingStatusModel[];
          tempacctStatusList = [];
          tempacctStatusList = responses[2].model;
          //this.acctStatusList = responses[2].model;
          this.acctStatusList = tempacctStatusList.filter(x => x.isGTSFlag == true);
          this.acctStatus_TPList = tempacctStatusList.filter(x => x.isGTSFlag != true);
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[3] !== null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          let gst = new GroundStopTaskModel();
          gst = responses[3].model;

          this.due_datetime = gst.due_DateDisplay;
          this.lead_time = gst.leadTime_Min;
          this.due_date = gst.dueDate_Min;
          this.confirmation_number = gst.fpiConfirmationReference;
          this.public_notes = gst.publicNote;
          this.internal_notes = gst.internalNote;
          this.businessRulesID = gst.businessRulesID;
          this.hasTaskAudit = gst.hasTaskAudit;
          this.modifiedBy = gst.modifiedBy;
          this.modifiedDate = gst.modifiedDate;
          this.serviceTypeID = gst.serviceTypeID;
          this.isRevisionRequiredValue = gst.isRevisionRequiredValue;
          if (this.isRevisionRequiredValue != 0) {
            this.isRevisionRequired = true;
          }

          this.isGTSBillable = gst.isGTSBillable;
          this.isThirdPartyBillable = gst.isThirdPartyVisible;

          this.selectedTaskStatusGUID = gst.taskStatusGUID.toLowerCase();
          this.selectedAcctStatusGUID = gst.accountingStatusGUID;
          this.selectedAcctStatusTPGUID = gst.accountingStatusGUID_TP.toLowerCase();
          if (this.selectedAcctStatusGUID != "")
            this.selectedAcctStatusGUID = this.selectedAcctStatusGUID.toLowerCase();

          this.selectedAcctStatusDescription = this.acctStatusList.filter(x => x.accountingStatusGUID === this.selectedAcctStatusGUID)[0]?.accountingStatusDescription;
          this.selectedTaskStatusDescription = this.acctStatus_TPList.filter(x => x.accountingStatusGUID === this.selectedAcctStatusTPGUID)[0]?.accountingStatusDescription;

          if (gst.taskStatusDescription == "Client Setup") {
            this.selectedAcctStatusDescription = this.selectedTaskStatusDescription = "Do Not Bill";
          }

          if (localStorage.getItem('up.lockTrip') == 'true') {
            this.canChangeBilling = true;
          }
          else {
            this.canChangeBilling = false;
          }

          this.serviceLocation = gst.serviceLocation;
          if (gst.serviceLocation == 'D' && gst.departureOnHold) {
            this.onHoldText = "On Hold";
          }
          else {
            if (gst.serviceLocation == 'A' && gst.arrivalOnHold) {
              this.onHoldText = "On Hold";
            }
          }
          if (gst.isActive == true)
            this.isActive = 1;
          else
            this.isActive = 0;
          if (gst.immediateActionRequired) {
            this.immediateAction = 1;
          }
          else {
            this.immediateAction = 0;
          }

          this.taskColor = this.taskStatusList.filter(x => x.taskStatusGUID.toLowerCase() == this.selectedTaskStatusGUID)[0].hexColor;
          let d = Date.now();
          let d2: number;
          if (this.serviceTypeID == 80)
            d2 = new Date(this.arrivalDateTime).getTime();
          else
            d2 = new Date(this.departureDateTime).getTime();
          let dateDiff = d2 - d;
          this.is48Hours = dateDiff / 3600000 <= 48 ? true : false;
          this.revisionCounter = gst.revisionCounter;

        }
      }
      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          if (responses[4].model.length > 0) {
            this.taskDocumentList = responses[4].model;
            this.hasExistingDocument = true;
          }
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          if (responses[5].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[5].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[6] != null) {
        if (responses[6].code == "200" && responses[5].message == "") {
          if (responses[6].model != null) {
            let gar = <UkGarDirectModel[]>responses[6].model;
            for (let i = 0; i < gar.length; i++) {
              this.hasMissingInfo = gar[i].person.hasMissingInfo;
              if (this.hasMissingInfo)
                break;
            }
          }
        }
      }
      else {
        if (responses[6].code == "401") {
          this._authService.signOut();
        }
      }
    
      
      
      this.showSpin = false;
      this.initControls();
    });

   
  }

  initControls() {
    this.uk_gar_task_form = this._formBuilder.group({
      internal_note: [this.internal_notes],
      public_note: [this.public_notes],
      isRevisionRequired: [this.isRevisionRequired],
      task_status_select: [this.selectedTaskStatusGUID],
      acct_status_select: [this.selectedAcctStatusGUID],
      acct_status_tp_select: [this.selectedAcctStatusTPGUID],
      confirmation_number: [this.confirmation_number],
      record_status: this.isActive,
      immediateAction: this.immediateAction,
      lead_time: [this.lead_time, Validators.pattern("^(([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?)$")],
      due_date: [this.due_date, Validators.pattern("^([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?$")],
      document: [null],
    });
  }

  get f() { return this.uk_gar_task_form.controls; }

  clickClose() {
    if ((this.uk_gar_task_form.dirty && !this.isModified) || (this.hasModifiedDocuments && !this.isModified)) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes you just made before closing the page?");
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(this.isModified);
        }

        this.hasModifiedDocuments = false;
      });
    }
    else {
      this._dialogRef.close(this.isModified);
    }
  }

  clickOpenInNew(index: number) {
    switch (index) {
      case 1:
        this._dialog.open(this.publicNotesTemplate);
        break;
      case 2:
        this._dialog.open(this.internalNotesTemplate);
        break;
    }
  }

  clickPreviewDocument(e: any, item: GroundStopTaskDocumentModel) {
    let request = new GroundStopTaskDocumentModel();
    request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
    if (item.groundStopTaskDocumentGUID != "") {
      this._groundStopAdvancedService.getGroundStopTaskDocumentBygstdId<ResponseModel<GroundStopTaskDocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new GroundStopTaskDocumentModel();
            obj = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
            let file = new Blob([byteArray], { type: obj.mimeType });
            var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display:none");
            a.href = fileURL;
            a.target = "_blank";
            switch (obj.fileExtension) {
              case "doc":
              case "docx":
              case "xls":
              case "xlsx":
                a.download = obj.documentName;
            }
            a.click();
            document.body.removeChild(a);

          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
      let file = new Blob([byteArray], { type: item.mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

  }

  openAirportDetails(airportCode: string, nextAirportCode: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "78em";//"1000px";
    dialogConfig.height = "38.75em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      searchKey: `${airportCode},${nextAirportCode}`
    };

    const dialogRef = this._dialog.open(AirportLocatorComponent, dialogConfig);
  }

  updateNotes(event: any, isPublic: boolean) {
    if (isPublic)
      this.public_notes = event;
    else
      this.internal_notes = event;
  }

  closeNotesPopup() {
    this.f.internal_note.setValue(this.internal_notes);
  }

  onFileClick(event) {
    this.errMsg = "";
    event.target.value = '';
  }

  importFile(event: any) {
    this.errMsg = "";

    if ((this.fileList.length + event.target.files.length) > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.target.files.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event.target.files[i].size >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  this.finishAllUploads = true;
          //  return;
          //}
          //else {
          //  files.push(event.target.files[i]);

          //}
          if (event.target.files[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {             
            this.errMsg = 'Total file sizes are over limit.';
            this.finishAllUploads = true;
            return;
          }
          else {
            files.push(event.target.files[i]);

          }
        }
        else {
          this.finishAllUploads = true;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
  }

  taskStatusChange(e: any, item: TripTaskStatusModel) {
    this.taskColor = this.taskStatusList.filter(x => x.taskStatusGUID.toLowerCase() == this.f.task_status_select.value.toLowerCase())[0].hexColor;
    this.selectedTaskStatusGUID = this.f.task_status_select.value.toLowerCase();
  }

  leadTimeChange(e: any) {
    if (e.target.value != "") {
      this.lead_timeChanged = true;
      if (this.f.lead_time.errors) {
        return;
      }
      this.lead_timeChanged = false;
      this.calculateLeadTime_DueDate('l', e.target.value);
    }
    else {
      //this.f.lead_datetime.setValue('');
      this.leadTime_Min_Value = 0;
    }
  }

  dueDateChange(e: any) {
    if (e.target.value != "") {
      this.due_dateChanged = true;
      if (this.f.due_date.errors) {
        return;
      }
      this.due_dateChanged = false;
      this.calculateLeadTime_DueDate('d', e.target.value);
    }
    else {
      //this.f.due_datetime.setValue('');
      this.dueDate_Min_Value = 0;
    }
  }

  getAllData(): Observable<any[]> {
    let request = new GroundStopModel();
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    let getGSResponse = this._groundStopAdvancedService.getGroundStopForTaskEditBygsId<ResponseModel<GroundStopModel>>(request);
    let getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    let getAcctStatusResponse = this._groundStopAdvancedService.getAccountingStatusList();
    let req2 = new GroundStopTaskModel();
    req2.groundStopTaskGUID = this.groundStopTaskGUID;
    req2.groundStopGUID = this.groundStopGUID
    let getTaskResponse = this._groundStopAdvancedService.getGroundStopTaskBygstId(req2);
    let getTaskDocResponse = this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId(req2);
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();
    let req = new UkGarModel();
    req.tripCodeGUID = this.tripCodeGUID;
    req.groundStopGUID = this.groundStopGUID;
    req.groundStopTaskGUID = this.groundStopTaskGUID;
    let isDeparture = true;
    if (this.serviceTypeID == 80)
      isDeparture = false;
    req.isDeparture = isDeparture;
    let getGARresponse = this._groundStopAdvancedService.getDataForUkGarService(req, false);

    
    return forkJoin([getGSResponse, getTaskStatusResponse, getAcctStatusResponse, getTaskResponse, getTaskDocResponse,
      getAllowedDocTypeResponse, getGARresponse]);

  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            let obj = new FileModel();
            let obj2 = new GroundStopTaskDocumentModel();
            this.maxTempDocId += 1;
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            obj.tempId = this.maxTempDocId;
            obj.isInternal = item.isInternal;
            this.totalMessageAttachmentFileSize += item.fileSize * 1024;
            this.fileList.push(obj);

            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.documentSizeInKB = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.tempId = this.maxTempDocId;
            obj2.groundStopTaskDocumentGUID = "";
            this.taskDocumentList.push(obj2);
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  calculateLeadTime_DueDate(type: string, s: string) {
    if (type == 'l') {
      //this.f.lead_datetime.setValue('');
    }
    if (type == 'd') {
      //this.f.due_datetime.setValue('');
    }
    if (s != "") {
      let d = 0;
      s = s.toLowerCase();
      var i = -1;
      i = s.indexOf('d');
      if (i > 0) {
        let sd = s.substring(0, i);
        if (Number.isInteger(Number(sd))) {
          d = Number(sd);
          s = s.substring(i + 1);
        }
      }

      let h = 0;
      if (s != "") {
        i = s.indexOf('h');
        if (i > 0) {
          let sh = s.substring(0, i);
          if (Number.isInteger(Number(sh))) {
            h = Number(sh);
            s = s.substring(i + 1);
          }
        }
      }

      let mi = 0;
      if (s != "") {
        i = s.indexOf('m');
        if (i > 0) {
          let sm = s.substring(0, i);
          if (Number.isInteger(Number(sm))) {
            mi = Number(sm);
            s = s.substring(i + 1);
          }
        }
      }

      let totalmin = 0;
      if (d > 0) {
        totalmin += d * 24 * 60;
      }
      if (h > 0) {
        totalmin += h * 60;
      }
      if (mi > 0) {
        totalmin += mi;
      }
      if (type == 'l') {
        this.leadTime_Min_Value = totalmin;
      }
      if (type == 'd') {
        this.dueDate_Min_Value = totalmin;
      }
      if (totalmin > 0) {
        if (type == 'l') {
          this.leadTime_Min_Value = totalmin;
        }
        if (type == 'd') {
          this.dueDate_Min_Value = totalmin;
        }
        if (this.arrivalDateTime != "") {
          let dt = new Date(this.arrivalDateTime);
          dt.setMinutes(dt.getMinutes() - totalmin);
          if (dt != null) {
            let m = dt.getMonth() + 1;
            let tempMin = dt.getMinutes();
            let stempMin = "";
            if (tempMin < 10) {
              stempMin = "0" + tempMin.toString();
            }
            else {
              stempMin = tempMin.toString();
            }
            let sdt = m.toString() + "/" + dt.getDate().toString() + '/' + dt.getFullYear().toString() + ' ' + dt.getHours().toString() + ':' + stempMin;
            //if (type == 'l') {
            //  this.f.lead_datetime.setValue(sdt);             
            //}
            //if (type == 'd') {
            //  this.f.due_datetime.setValue(sdt);
            //  this.f.due_datetime.setErrors(null);
            //}
          }
        }
        else {
          if (this.departureDateTime != "") {
            let dt = new Date(this.departureDateTime);
            dt.setMinutes(dt.getMinutes() - totalmin);
            if (dt != null) {
              let m = dt.getMonth() + 1;
              let tempMin = dt.getMinutes();
              let stempMin = "";
              if (tempMin < 10) {
                stempMin = "0" + tempMin.toString();
              }
              else {
                stempMin = tempMin.toString();
              }
              let sdt = m.toString() + "/" + dt.getDate().toString() + '/' + dt.getFullYear().toString() + ' ' + dt.getHours().toString() + ':' + stempMin;
              //if (type == 'l') {
              //  this.f.lead_datetime.setValue(sdt);              
              //}
              //if (type == 'd') {
              //  this.f.due_datetime.setValue(sdt);
              //  this.f.due_datetime.setErrors(null);
              //}
            }
          }
        }
      }
    }
  }

  getTaskDocumentList() {
    let request = new GroundStopTaskModel()
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId<ResponseModel<GroundStopTaskDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.taskDocumentList = [];
        this.taskDocumentList = response.model;
        if (this.taskDocumentList.length > 0) {
          this.hasExistingDocument = true;
        }
        if (this.fileList.length > 0) {
          this.fileList.forEach(x => {
            let obj = new GroundStopTaskDocumentModel();
            obj.documentName = x.fileName;
            obj.fileExtension = x.fileType;
            obj.documentSizeInKB = x.fileSize;
            obj.mimeType = x.mimeType;
            obj.bFile = x.bFile;
            obj.tempId = x.tempId;
            obj.groundStopTaskDocumentGUID = "";
            obj.isInternal = x.isInternal;
            this.taskDocumentList.push(obj);
          });
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    })
  }

  dropFile(event: any) {
    this.errMsg = "";
    //setTimeout(() => {
    //  this.errMsg="", 1000;
    //});

    if ((this.fileList.length + event.length) > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      //this.finishAllUploads = true;
      //return;
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;
        setTimeout(() => this.removeFromUploaderQueue(filename), 100);
      }
    }
    else {
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event[i].size >= this.maxFileSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          //}
          if (event[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {
            if (this.errMsg != "") {
              this.errMsg += "\n";
            }
            this.errMsg += 'Total file sizes are over limit';
            setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          }

        }
        else {

          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          setTimeout(() => this.removeFromUploaderQueue(filename), 100);

        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }
    this.errMsg = "";
    this.uploadSubmit();
  }

  toggleInternalFile(item: GroundStopTaskDocumentModel) {
    var file = this.fileList.find(x => x.fileName == item.documentName);
    if (file !== undefined)
      file.isInternal = !file.isInternal;

    var document = this.taskDocumentList.find(x => x.documentName == item.documentName);
    document.isInternal = !document.isInternal;

    this.hasModifiedDocuments = true;
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.hasModifiedDocuments = true;
    this.uploader.uploadAll();
  }

  clickAudit() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: "", aircraftGUID: "", tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID,
      groundStopTaskGUID: this.groundStopTaskGUID, pastHours: 0,
      userName: "", v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }

  clickRemoveDocument(e: any, item: GroundStopTaskDocumentModel) {
    this.errMsg = "";
    this.isModified = true;
    this.hasModifiedDocuments = true;
    if (item.groundStopTaskDocumentGUID != "") {
      // remove doc from database, get newlist and then add unsaved file list
      let request = new GroundStopTaskDocumentModel();
      request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
      this._groundStopAdvancedService.removeGroundStopTaskDocumentBygstdocId<ResponseModel<boolean>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model) {
            this.getTaskDocumentList();
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      this.fileList.forEach((x, index) => {
        if (x.tempId == item.tempId) {
          this.totalMessageAttachmentFileSize -= item.fileSize * 1024;
          this.fileList.splice(index, 1);
        }
      }
      );
      this.taskDocumentList.forEach(
        (x, index) => {
          if (x.tempId == item.tempId && x.groundStopTaskDocumentGUID == "") {
            this.taskDocumentList.splice(index, 1);
          }
        }
      )
    }
  }

  clickEmail() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    let taskGroundStopGUID: string = "";
    if (this.groundStopGUID.toLowerCase() == this.nextGroundStopGUID.toLowerCase()) {
      taskGroundStopGUID = this.nextGroundStopGUID
    }
    else {
      taskGroundStopGUID = this.groundStopGUID
    }
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.depGroundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, nextGroundStopGUID: this.nextGroundStopGUID, serviceTypeID: this.serviceTypeID,
      aircraftGUID: this.aircraftGUID, customerGUID: this.customerGUID, taskGroundStopGUID: taskGroundStopGUID, v: this._authService.getCurrentTimeNumber(),
      fromTaskEditDaialog: true, clientName: this.clientName, tripCode: this.tripCode, leg: this.leg, serviceDesc: this.serviceTypeDesc, requiredGsId: this.groundStopGUID,
      servcieStatus: this.taskStatusList.filter(s => s.taskStatusGUID == this.selectedTaskStatusGUID)[0].taskStatusDescription, farTypeID: this.farTypeID, defaultEmail: this.defaultEmail, lockdownTrip: this.lockdownTrip, icao: this.icao
    };

    const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        this.isModified = true;
      }
    });
  }

  clickTripMessages() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "105em";//"1000px";
    dialogConfig.height = "45em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID,
      registration: this.registration, selectedGroundStopList: this.selectedGroundStopList, tripCodeText: this.tripCode,
      groundStopGUID: this.groundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, serviceLocation: this.serviceLocation, includeActiveStopOnly: false,
      lockdownTrip: this.lockdownTrip
    };

    const dialogRef = this._dialog.open(TripMessageCenterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        //this.isModified = true;
      }
    });
  }

  removeFromUploaderQueue(filename: string) {
    var index = -1;
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (filename == fileItem.name) {
        index = i;
        break;
      }
    }

    if (index >= 0) {
      this.uploader.queue[index].remove();
    }

  }

  clickSave(callFrom: string = "") {
    this._authService.updateAccessTime();
    this.errMsg = "";

    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.fileList.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      return;
    }

    if (this.totalMessageAttachmentFileSize >= this.maxFileSize) {
      this.errMsg = "The total file size over the limit. Please remove some of documents.";
      return;
    }

    if (this.uk_gar_task_form.invalid || this.errMsg != "") {
      return;
    }

    if (this.f.lead_time.value != "" && this.leadTime_Min_Value == 0) {
      this.calculateLeadTime_DueDate('l', this.f.lead_time.value);
    }
    if (this.f.due_date.value != "" && this.dueDate_Min_Value == 0) {
      this.calculateLeadTime_DueDate('d', this.f.due_date.value);
    }

    let request = new GroundStopTaskModel();

    request.groundStopGUID = this.groundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    request.serviceTypeDescription = this.serviceTypeDesc;
    request.vendorGUID = "";
    request.countryGUID = "";
    request.modifiedBy = this.modifiedBy;
    request.modifiedDate = this.modifiedDate;

    request.flightPlanRecallNo = "";
    request.hotelGUID = "";
    request.fuelSupplierGUID = "";
    request.fuelIntoPlaneAgentName = "";
    
    request.isActive = true;
    if (this.f.record_status.value == "1")
      request.isActive = true;
    else
      request.isActive = false;
    request.taskStatusGUID = this.f.task_status_select.value;
    request.accountingStatusGUID = this.f.acct_status_select.value;
    request.accountingStatusGUID_TP = this.f.acct_status_tp_select.value;
    request.leadTime_Min = this.f.lead_time.value;
    request.leadTime_Min_Value = this.leadTime_Min_Value;
    request.fpiConfirmationReference = this.f.confirmation_number.value;
    request.dueDate_Min = this.f.due_date.value;
    request.dueDate_Min_Value = this.dueDate_Min_Value;
    request.serviceArrangementID = 0;
    if (this.f.isRevisionRequired.value == true) {
      request.isRevisionRequiredValue = this.isRevisionRequiredValue;
    }
    else {
      request.isRevisionRequiredValue = 0;//this.f.isRevisionRequired.value;
    }

    request.revisionCounter = this.revisionCounter; //Number(this.f.revision_counter.value);
    request.publicNote = this.f.public_note.value;
    request.internalNote = this.f.internal_note.value;
    request.groundStopTaskDocumentList = [];

    if (this.fileList.length > 0) {
      this.fileList.forEach(x => {
        let obj = new GroundStopTaskDocumentModel();
        obj.documentName = x.fileName;
        obj.fileExtension = x.fileType;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.groundStopTaskDocumentGUID = "";
        obj.isInternal = x.isInternal;
        request.groundStopTaskDocumentList.push(obj);
      });
    }
    if (this.f.immediateAction.value == 1) {
      request.immediateActionRequired = true;
    }
    this.loading = true;


    request.isAttachmentIcon = (
      (request.groundStopTaskDocumentList?.filter(doc => doc.isInternal === false).length > 0) ||
      (this.taskDocumentList?.filter(doc => doc.isInternal === false).length > 0)
    ) ? true : false;
    request.isInternalAttachmentIcon = (request.groundStopTaskDocumentList?.length > 0 || this.taskDocumentList?.length > 0) ? true : false;
    request.isPublicNoteIcon = (request.publicNote.trim() != '' && request.publicNote.trim() != null) ? true : false;
    request.isInternalNoteIcon = (request.internalNote.trim() != '' && request.internalNote.trim() != null) ? true : false;


    this._groundStopAdvancedService.updateGroundStopTaskBygstId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.fileList = [];
          this.maxTempDocId = 0;
          this.errMsg = "";
          this.errMsg2 = "";
          this.getTaskDocumentList();
          //this._dialogService.openAlertDialog("");
          this.isModified = true;
          if (callFrom != "gar") {
            this.showSuccessMsg = true;
            this.modifiedBy = localStorage.getItem('un');
            this.modifiedDate = Date.now().toString();
            setTimeout(() => {
              this.showSuccessMsg = false;
              this.clickClose();
            }, 1000); //change to 1 second
          }
        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg2 = "Failed to save this record at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg2 = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

    if (this.fileList.length == 0 && this.taskDocumentList.length > 0) {
      this.taskDocumentList.forEach(x => {
        let obj = new GroundStopTaskDocumentModel();
        obj.documentName = x.documentName;
        obj.fileExtension = x.fileExtension;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.groundStopTaskDocumentGUID = x.groundStopTaskDocumentGUID;
        obj.isInternal = x.isInternal;
        request.groundStopTaskDocumentList.push(obj);
      })

      this._groundStopAdvancedService.updateExistingDocumentsBygstId<ResponseModel<boolean>>(request).subscribe(response => {

      })
    }
  }

  exportNote(format: string, isPublic: boolean) {
    this.errMsg2 = "";
    let s: string = "";
    if (isPublic)
      s = this.f.public_note.value;
    else
      s = this.f.internal_note.value;


    let includePageNo = false;
    if (this.serviceTypeID == 31 || this.serviceTypeID == 35 || this.serviceTypeID == 29)
      includePageNo = true;
    let docName = this.registration + ' ' + this.icao + '-' + this.nextICAO + ' ' + this.serviceTypeDesc + ' ' + datepipe.transform(new Date().toUTCString(), 'MMddyy', "UTC");
    this._templateService.exportNotes(this.serviceTypeDesc.toUpperCase(), s, format, docName, includePageNo).subscribe(r => {
      if (r.remarks != "")
        this.errMsg2 = r.remarks;
      if (r.refresh)
        this.getTaskDocumentList();

    });
  }

  clickCopyNote(isPublic: boolean) {
    let s: string = "";
    if (isPublic)
      s = this.f.public_note.value;
    else
      s = this.f.internal_note.value;

    let sNew: string = "";

    if (s != "") {
      var s1 = s.split('\n');
      for (var i = 0; i < s1.length; i++) {
        if (s1[i].trim() == "") {
          s1[i] = " ";
        }
        sNew += s1[i] + "\n";
      }

    }
    else {
      sNew = s;
    }
    return sNew;
  }

  generateUKGAR() {
    this.errMsg2 = "";
    this.isDocLoading = true;
    let isDeparture = true;
    if (this.serviceTypeID == 80)
      isDeparture = false;
    this._templateService.generateUKGAR(this.tripCodeGUID, this.groundStopGUID, this.groundStopTaskGUID, isDeparture).subscribe(r => {
      if (r.remarks != "")
        this.errMsg2 = r.remarks;
      this.isDocLoading = false;
    });
  }

  submitUKGar(hasMissingInfo) {
    if (hasMissingInfo)
      this.validateGAR(true);
    else {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Are sure you want to submit the GAR?");
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.clickSave('gar');
          let request = new UkGarModel();
          request.tripCodeGUID = this.tripCodeGUID;
          request.groundStopGUID = this.groundStopGUID;
          request.groundStopTaskGUID = this.groundStopTaskGUID;
          let isDeparture = true;
          if (this.serviceTypeID == 80)
            isDeparture = false;
          request.isDeparture = isDeparture;
          this._groundStopAdvancedService.getDataForUkGarService<ResponseModel<UkGarDirectModel[]>>(request, true).subscribe(response => {
            if (response != null) {
              if (response.code == "200" && response.message == "") {
                this.generateReport(response.model);
              }
            }

          });
          this._dialogRef.close(true);
        }
      });
    }
  }

  validateGAR(canSubmit: boolean = false) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = {
      tripCodeGUID: this.tripCodeGUID,
      groundStopGUID: this.groundStopGUID,
      groundStopTaskGUID: this.groundStopTaskGUID,
      serviceTypeID: this.serviceTypeID,
      canSubmit:canSubmit
    };

    this._dialog.open(ValidateManifestDataComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result)
        this.submitUKGar(false);
    });
  }

  generateReport(gar:UkGarDirectModel[]) {
    return new Promise<boolean>((finished) => {
      this.errMsg = "";
      const gst = this._groundStopAdvancedService;
      let gstId = this.groundStopTaskGUID;
      var self = this;
      let filePath = window.origin + "/assets/templates/GARReport.docx";
      let inboundOutbound = "Inbound";
      if (this.serviceTypeID == 80)
        inboundOutbound = "Outbound";
      loadFile(filePath, function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser, nullGetter() { return ''; } });

        doc.setData({
          InboundOutbound:inboundOutbound,
          gar:gar,
          assemblyDate: datepipe.transform(new Date(), 'MMM-dd-YYYY HH:mm')

        });
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
                {});
            }
            return value;
          }
          console.log(JSON.stringify({ error: error }, replaceErrors));
          self.errMsg = JSON.stringify({ error: error }, replaceErrors);
          

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
            self.errMsg = "There was an error creating the report."
            // errorMessages is a humanly readable message looking like this:
            // 'The tag beginning with "foobar" is unopened'
          }
          this.loading = false;
          throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });

        //saveAs(out, "apisreport.docx");
        let uri;
        //if (window.origin.indexOf('dev') > -1 || window.origin.indexOf('localhost') > -1)
        //  uri = 'https://fpipdfconversion-dev.azurewebsites.net/api/ConvertToPdf'
        //else
        uri = 'https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf'
        const functionURI = uri;

        let headers = new HttpHeaders();
        const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
        //  headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
        var documentName: string;

        documentName = gar[0].craft.registrationNumber + ' ' + (inboundOutbound == 'Inbound' ? 'DEP' : 'ARR') + ' GAR Report ' + (inboundOutbound == 'Inbound' ? datepipe.transform(gar[0].travel.departureDate, 'dd MMM') : datepipe.transform(gar[0].travel.arrivalDate,'dd MMM'));
        httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {

          let upload = new UploadTaskDocument();
          documentName += ".pdf"
          upload.uploadFile(data.body, gstId, documentName, gst, undefined, self.tripCode);
          var fileURL = URL.createObjectURL(data.body);
          finished(true);
        });



      });

    });

  }

}
