import { DocumentModel } from "./document.model";
import { FlightBriefAirportVendor } from "./flight-brief-airport-vendor.model";
import { FlightPlanModel } from "./flight-plan.model";
import { GroundStopTaskModel } from "./ground-stop-task.model";
import { PermitsModel } from "./permits.model";
import { VendorModel } from "./vendor.model";

export class FlightBriefModel {
  tripCodeGUID: string;
  groundStopTaskGUID: string;
  selectedGroundStopList: string[];
  importantNote: string;
  weatherNote: string;
  notamsNote: string;
  terminalForecast: string;
  metars: string;
  sigmets: string;
  airmets: string;
  pireps: string;
  tropicalAdvisory: string;
  vaAdvisory: string;
  departureNotams: string;
  destinationNotams: string;
  etpNotams: string;
  fdcNotams: string;
  trackMessage: string;
  flightPlan: FlightPlanModel[];
  flightBriefAirportVendors: FlightBriefAirportVendor[];
  groundStopTaskDocuments: DocumentModel[];
  groundStopTasks: GroundStopTaskModel[];
  handlingVendorGUIDs: string[];
  handlingNotes: VendorModel[];
  permits: PermitsModel[];
  slotsPPR: PermitsModel[];
  weatherCharts: string[];
  flightBriefDocument: DocumentModel;
  icaoForms: GroundStopTaskModel[];
  docFormat: string;
  customerName: string;
  minDate: string;
  maxDate: string;
  tripRoute: string;
  reportID: string;
  coverPage: DocumentModel;
  reportDate: string;
  hasArincUplink: boolean;
  includedDocuments:string;
  includedSections: string;
  docIsFlightBrief: boolean;
  includedTaskNotes: string;
  flightBriefFormat: string;
  wxOutlookPrelimPlanIDs: string;
  prelimPlans: GroundStopTaskModel[];
  synopsis: string;
  turbulence: string;
  tfrNotams: string;
}
