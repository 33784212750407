import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { UserModel } from '../models/user.model';
import { AuthenticateService } from '../services/authenticate.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { CustomerModel } from '../models/customer.model';
import { VendorModel } from '../models/vendor.model';
import { Observable, forkJoin, of } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { CommonService } from '../services/common.service';
import { ResponseModel } from '../models/response.model';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserPermissionAdminEditDialogComponent } from './user-permission-admin-edit-dialog.component';
import { DialogService } from '../services/dialog.service';
import { AdminServicesClientComponent } from './admin-services-client.component';


@Component({
  selector: 'app-user-permission-admin',
  templateUrl: './user-permission-admin.component.html',
  styleUrls: ['./user-permission-admin.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }  
  ]
})

export class UserPermissionAdminComponent implements OnInit, AfterViewInit {
  user_permission_admin_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  user: UserModel;
  errMsg: string;
  msg: string;
  customerList: CustomerModel[];
  vendorList: VendorModel[];
  userList: UserModel[];
  userType: string;
  selectedUserType: string; 
  textSearchBy: string;
  showSpin: boolean = true;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 10; //20;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  showPage: boolean = false;
  includeDisabledRecords: boolean;
  isPopup: boolean = false;
 
   //displayedColumn = ['username', 'name', 'email', 'account_status', 'admin_access', 'crew_trip_detail', 'pax_trip_detail', 'trip_document', 'create_event', 'create_alert', 'company_document', 'aircraft_preference', 'crew_pax_data_maintenance', 'trip_messages', 'vendor_invoiceupload', 'disabled', 'action'];
   //displayedColumn2 = ['members_list', 'admin', 'restrict_access', 'blank'];
  displayedColumn: string[] = ['username', 'name', 'email', 'account_status', 'admin_access', 'effectiveDate', 'expirationDate', 'action'];
  displayedColumn2: string[] = ['members_list', 'restrict_access', 'blank']; // 7, 14, 3
  scrHeight: number;
  ua: number;
  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService, private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog, private readonly _dialogService: DialogService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private readonly _dialogRef: MatDialogRef<UserPermissionAdminComponent>) {

  }

  //getAllData(): Observable<any[]> {
  //  if (this.userType == "internal") {
  //    //let getCustomersResponse = this._commonService.getCustomers<ResponseModel<CustomerModel[]>>();
  //    //let getVendorsResponse = this._commonService.getVendors<ResponseModel<VendorModel[]>>();
  //    let getVendorsResponse = of(null);
  //    //return forkJoin([getCustomersResponse, getVendorsResponse]);
  //    return forkJoin([getVendorsResponse]);
      
  //  }
  //}

  ngOnInit() {  
    if(this.data) {
      this.isPopup = true;
    }
    this._authService.updateAccessTime();
    this.ua = 0;// Number(localStorage.getItem('ua'));
    if (localStorage.getItem('up.isA') != null && localStorage.getItem('up.isA') != "") {
      if (localStorage.getItem('up.isA').toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
        this.ua = 1;
      }
    }
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.ua != 1) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this._router.navigate(['/common-utility/blank']);
      return;
    }
    let t = this._route.snapshot.paramMap.get('t');
    if (t == null || t == undefined || t == '') {
      this.selectedUserType = 'internal'
    }
    else {
      switch (Number(t)) {
        case 1:
          this.selectedUserType = 'internal'
          break;
        case 3:
          this.selectedUserType = 'vendor'
          break;
      }
    }
    this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
    this.customerList = [];
    this.vendorList = [];
    this.userList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }
    

    if (this.userType == "internal") {
      //this.getAllData().subscribe(responses => {
      //  //if (responses[0] != null) {
      //  //  if (responses[0].code == "200" && responses[0].message == "") {
      //  //    this.customerList = responses[0].model;
      //  //  }
      //  //  else {
      //  //    if (responses[0].code == "401") {
      //  //      this._authService.signOut();
      //  //    }
      //  //  }
      //  //}

      //  if (responses[0] != null) {
      //    if (responses[0].code == "200" && responses[0].message == "") {
      //      this.vendorList = responses[0].model;
      //    }
      //    else {
      //      if (responses[0].code == "401") {
      //        this._authService.signOut();
      //      }
      //    }
      //  }

      //  //if (this.userType == "internal") {
      //  //  this.selectedUserType = 'internal';
      //  //}

      //  this.initControls();
      //  if (this.selectedUserType == 'internal') {
      //    //this.displayedColumn = ['username', 'name', 'email', 'account_status', 'admin_access', 'crew_trip_detail', 'pax_trip_detail', 'trip_document', 'create_event', 'create_alert', 'company_document', 'aircraft_preference', 'crew_pax_data_maintenance', 'trip_messages', 'vendor_invoiceupload', 'disabled', 'action'];
      //    //this.displayedColumn2 = ['members_list', 'admin', 'restrict_access', 'blank'];
      //    this.getSearchResults();
      //  }

      //  this.showSpin = false;

      //});

      this.initControls();
      if (this.selectedUserType == 'internal') {
        this.getSearchResults();
      }

      this.showSpin = false;
    }
    else {
      this.initControls();
      this.getSearchResults();
    }    
  }

  initControls() {
    this.user_permission_admin_form = this._formBuilder.group({
      //customer_select: null,
      vendor_select: null,   
      account_status: ['1'],
      admin_access: ['1'],
      username: [''],
      fl_name: [''],
      email: [''],
      text_search: [''],
      permission_type: ['Username'],
      includeDisabledRecords: this.includeDisabledRecords
      
    });
    
    this.textSearchBy = "Username"; // this.f.permission_type.options[this.f.permission_type.options.selectedIndex].text;;
    //if (this.userType == "internal") {
      //this.f.customer_select.setValue('');
    //}
  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //  this.showSpin = false;
    //}, 100);

  }

  get f() { return this.user_permission_admin_form.controls; }

  clickUserType(u) {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;

    if (this.selectedUserType != u) {
      this.userList = [];
      this.selectedUserType = u;
      //this.f.customer_select.setValue('');
      this.f.vendor_select.setValue('');
      
      if (this.selectedUserType == "internal" || this.selectedUserType == "vendor") {
        this.displayedColumn = ['username', 'name', 'email', 'account_status', 'admin_access', 'crew_trip_detail', 'pax_trip_detail', 'trip_document', 'create_event', 'create_alert', 'company_document', 'aircraft_preference', 'crew_pax_data_maintenance', 'trip_messages', 'vendor_invoiceupload', 'disabled', 'action'];
        this.displayedColumn2 = ['members_list', 'admin', 'restrict_access', 'blank'];
        if (this.userType == "internal" && this.selectedUserType != "internal") {
          //this.loadVendorList();
            return;          
        }
        else
          this.getSearchResults();
      }
      else {
        //this.displayedColumn= ['username', 'name', 'email', 'account_status', 'admin_access', 'registration', 'pic', 'sic', 'pax', 'crew_trip_detail', 'pax_trip_detail', 'trip_document', 'create_event', 'create_alert', 'company_document', 'aircraft_preference', 'crew_pax_data_maintenance', 'trip_messages', 'vendor_invoiceupload', 'disabled', 'action'];
        //this.displayedColumn2= ['members_list', 'admin', 'trip_access_filter', 'restrict_access', 'blank'];
        if (this.selectedUserType == "customer") {
          this._router.navigate(['/user-accounts/user-permission-client']);
        }
      }
    }
  }

  clickClose(val) {
    this._dialogRef.close(val);
  }


  openAdminServicesComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.minWidth = "76em";
    //config.maxWidth = "92em";//"1000px";
    config.height = "24em";
    config.width = "max-content";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "10px", top: "140px" };
    config.data = { gsId: "", tripCodeId: "", isDialog: true, v: this._authService.getCurrentTimeNumber(), userType: this.data.userType };

    this.clickClose(true);

    this._dialog.open(AdminServicesClientComponent, config);
  }

  loadVendorList() {
    this.showSpin = true;
    this._commonService.getVendors<ResponseModel<VendorModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.vendorList = response.model;
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.showSpin = false;
    })
  }

  includeDisabledRecordsOnChange(event: any) {
    //let x = event.target.checked;
    if (this.userType == "internal" && this.selectedUserType != "internal") {
      //if (this.f.customer_select.value == "") {
      //  this.errMsg = "Please select a client";
      //  return;
      //}
      if (this.f.vendor_select.value == "") {
        this.errMsg = "Please select a vendor";
        return;
      }
    }
    this.userList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults();
  }

  clickShowAllUsers(e: any) {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;

    this._authService.updateAccessTime();
    if (this.userType == "internal" && this.selectedUserType !="internal") {
      //if (this.f.customer_select.value == "") {
      //  this.errMsg = "Please select a client";
      //  return;
      //}
      if (this.f.vendor_select.value == "") {
        this.errMsg = "Please select a vendor";
        return;
      }
    }
    //this.f.text_search.setValue('');
    this.getSearchResults();
   
  }

  onSubmit() {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this._authService.updateAccessTime();;
    if (this.userType == "internal" && this.selectedUserType != "internal") {
      //if (this.f.customer_select.value == "") {
      //  this.errMsg = "Please select a client";
      //  return;
      //}
      if (this.f.vendor_select.value == "") {
        this.errMsg = "Please select a vendor";
        return;
      }
    }
    //else {
      //if (this.f.username.value == "" && this.f.fl_name.value == "" && this.f.email.value == "" && this.f.text_search.value == "") {
    if (this.f.text_search.value == "") {
        this.errMsg = "Please enter a keyword.";
        return;
      }
    //}
    this.getSearchResults();
  }

  getSearchResults() {
    this.errMsg = "";
    this.msg = "";
    this.loading = true;
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.user = new UserModel();
    this.user.customerGuid = null;
    if (this.userType == "internal" && this.selectedUserType != "internal") {
      //this.user.customerGuid = this.f.customer_select.value;
      this.user.vendorGuid = this.f.vendor_select.value;
    }
      
    //if (this.f.account_status.value == "1")
    //  this.user.isActive = true;
    //else
    //  this.user.isActive = false;
    this.user.isActive = true;
    if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      this.user.isActive = !this.f.includeDisabledRecords.value;
    }
    this.user.adminAccess = Number(this.f.admin_access.value);
    //this.user.username = this.f.username.value;
    //this.user.flName = this.f.fl_name.value;
    //this.user.email = this.f.email.value;
    this.user.textSearch = this.f.text_search.value;
    this.user.tripAccessFilter = this.f.permission_type.value;
    this.user.pageIndex = this.currentPageIndex;
    this.user.pageSize = this.pageSize;
    this.user.totalRecords = this.totalRecords;
    this._identityService.getUserPermissionByConditions<ResponseModel<UserModel[]>>(this.user).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.userList = response.model;
          if (this.userList[0].totalRecords > 0) {
            if (this.userList[0].totalRecords==1)
              this.totalRecordsText = this.userList[0].totalRecords.toString() + ' record';
            else
              this.totalRecordsText = this.userList[0].totalRecords.toString() + ' records';
            this.totalRecords = this.userList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

            this.userList.forEach(x => {
              if (x.isAB.toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
                x.isAdmin = true;
              }
              else {
                x.isAdmin = false;
              }
            });
            
          }

        }
        else {
          this.userList = [];
          if (this.f.text_search.value != "") {
            switch (this.f.permission_type.value) {
              case "Username":
                this.msg = "Username not found.";
                break;
              case "Email":
                this.msg = "Email address not found.";
                break;
              case "Registration":
                this.msg = "Aircraft registration not found.";
                break;
              case "PIC/SIC":
                this.msg = "PIC/SIC not found.";
                break;
              case "PAX":
                this.msg = "PAX not found.";
                break;
            }
          }
          else {
            this.msg = "No records found.";
          }
            //this.msg = "No data return.";
        }
      }
      else {
        this.userList = [];
      }
      this.loading = false;
      this.showSpin = false;

    })

  }

  pageIndexChange(e: any)
  {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
    }
    this.getSearchResults();

  }

  //customerChange(e: any) {
  //  this.errMsg = "";
  //  this.msg = "";
  //  this.userList = [];
  //  this.totalRecordsText = "";
  //  this.totalRecords = 0;
  //  this.currentPageIndex = 0;
  //  this.totalPageNumber = 0;
  //  if (this.f.customer_select.value == "") {
  //    this.errMsg = "please select a client.";
  //    return;
  //  }
  //  this.getSearchResults();
  //}

  vendorChange(e: any) {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = "";
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    if (this.f.vendor_select.value == "") {
      this.errMsg = "please select a vendort.";
      return;
    }
    this.getSearchResults();
  }

  permission_typeChange(e: any) {
    let text = e.target.options[e.target.options.selectedIndex].text;
    this.textSearchBy = text;
    //this.f.text_search.setValue('');
  }

  clickEdit(e: any, item: UserModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "58em";//"1000px";
    //dialogConfig.height = "30em";//"500px";
    dialogConfig.disableClose = true;
    //if (item.userType.toLocaleLowerCase() != 'internal') {
    //  dialogConfig.data = { tabName: 'registration', userId: item.userId, customerGuid: item.customerGuid, userType: item.userType.toLocaleLowerCase() };
      
    //}
    //else {
      dialogConfig.data = { tabName: 'restrict', userId: item.userId, customerGuid: item.customerGuid, userType: item.userType.toLocaleLowerCase() };
    //}
    
    const dialogRef = this._dialog.open(UserPermissionAdminEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSearchResults();
      }
    });

  }

  clickReset() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    //this.selectedUserType = 'internal';
    this.initControls();
    this.userList = [];
    this.totalRecordsText = "";
    this.totalRecords = 0;
    this.textSearchBy = "Username";
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    if (this.selectedUserType == 'internal')
      this.getSearchResults();
  }


}
