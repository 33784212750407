export class ApisSubmissionModel {
  referenceNumber: string;
  documentCode: string;
  transmissionType: string;
  edifact: string;
  xml: string;
  apisResponse: string;
  submittedByID: string;
  submittedDate: string;
  hasNoDocuments: boolean;
  travelersWithNoDocuments: string;
  isSuccessful: boolean;
  errorMessage: string;
  statusDescription: string;
  apisTypeID: number;
}
