<div  style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.25em">
      <i class="material-icons-outlined" style="font-size: x-large;cursor: pointer; color: gray">description</i>
    </div>
    <div style="margin-top:0.3125em;"><h5>{{dialogTitle}}</h5></div>
  </div>
  <div>
    <form *ngIf="trip_notes_form" [formGroup]="trip_notes_form">
      <div style="margin-top:-0.5em; padding-left: 1em; padding-right:1em;">
        <div style="display:flex; font-size:small">
          <div style="background-color: white; padding-left:1em; padding-right:0.5em; width:42em">
            <div>Trip Details</div>
            <div style="display: flex">
              <div style="margin-right: 0.5em">
                <div>Trip Code</div>
                <div>{{tripCode}}</div>
              </div>
              <div style="margin-right: 0.5em">
                <div>Registration</div>
                <div>{{registration}}</div>
              </div>
              <div>
                <div>Route</div>
                <div>
                  <div *ngFor="let gs of selectedGroundStopList; let i=index">
                    <div *ngIf="i % 10==0" style="display: flex">
                      <div style="display: flex">
                        <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 1 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+1].groundStopTypeColor">{{selectedGroundStopList[i+1].icao}}</div><div>{{(i+1)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 2 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+2].groundStopTypeColor">{{selectedGroundStopList[i+2].icao}}</div><div>{{(i+2)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 3 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+3].groundStopTypeColor">{{selectedGroundStopList[i+3].icao}}</div><div>{{(i+3)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 4 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+4].groundStopTypeColor">{{selectedGroundStopList[i+4].icao}}</div><div>{{(i+4)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 5 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+5].groundStopTypeColor">{{selectedGroundStopList[i+5].icao}}</div><div>{{(i+5)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 6 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+6].groundStopTypeColor">{{selectedGroundStopList[i+6].icao}}</div><div>{{(i+6)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 7 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+7].groundStopTypeColor">{{selectedGroundStopList[i+7].icao}}</div><div>{{(i+7)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 8 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+8].groundStopTypeColor">{{selectedGroundStopList[i+8].icao}}</div><div>{{(i+8)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 9 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+9].groundStopTypeColor">{{selectedGroundStopList[i+9].icao}}</div><div>{{(i+9)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div style="width:1em"></div>
          <div *ngIf="pageFrom=='client'" style="background-color: white; padding-left:1em; padding-right:0.5em; width:27em">
            <div style="display:flex">
              <div style="margin-right:0.5em">Submitted By: {{submittedBy}}</div>
              <div style="margin-right:0.5em">Submitted Date: {{submitDate}}</div>
            </div>
            <!--<div style="display:flex">
        <label for="client_reference" style="width:6em">Reference #:</label>
        <div style="margin-top:0em;">
          <input type="text" formControlName="client_reference" class="form-control" maxlength="50" style="width:19em; height: 1.625em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd" />
        </div>
      </div>-->
            <div style="display:flex">
              <label for="client_trip_number" style="width:6em;">Client Trip #:</label>
              <div style="margin-top:0em;">
                <input type="text" formControlName="client_trip_number" class="form-control" maxlength="50" style="width:19em; height: 1.625em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd" />
              </div>
            </div>
          </div>
        </div>
        <div style="height:0.5em"></div>
        <div>
          <ckeditor #notesCKEditor data="ckEditorData" formControlName="notesCKEditor" [editor]="Editor" [config]="CKEConfig" (ready)="onReady($event)" style="line-height:1.2em" [disabled]="isDisabled"></ckeditor>
        </div>
        <!--<div style="font-size:small; background-color: white; padding-left:1em; padding-right:0.5em; padding-bottom:0.5em; width:70em">
    <mat-tab-group [color]="primary" mat-align-tabs="start">
      <mat-tab>
        <ng-template mat-tab-label>
          Operations Notes
          <mat-icon *ngIf="f.ops_notes.value!=''" class="example-tab-icon">notes</mat-icon>
        </ng-template>
        <textarea formControlName="ops_notes_add" class="form-control" style="width:68.3em; height:11.5em;font-family:Courier New, Courier, monospace;font-size:small; padding:0em 14em 0em .25em;background-color:#eaf0fd"></textarea>
        <textarea readonly formControlName="ops_notes" class="form-control mt-2" style="width:68.3em; height:21.5em;font-family:Courier New, Courier, monospace;font-size:small; padding:0em 14em 0em .25em;"></textarea>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          Weather Notes
          <mat-icon *ngIf="f.wx_notes.value!=''" class="example-tab-icon">notes</mat-icon>
        </ng-template>
        <textarea formControlName="wx_notes_add" class="form-control" style="width: 68.3em; height: 11.5em; font-family: Courier New, Courier, monospace; font-size: small; padding: 0em 14em 0em .25em; background-color: #eaf0fd"></textarea>
        <textarea readonly formControlName="wx_notes" class="form-control mt-2" style="width:68.3em; height:21.5em;font-family:Courier New, Courier, monospace;font-size:small; padding:0em 14em 0em .25em;"></textarea>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          Client Instructions
          <mat-icon *ngIf="f.special_instruction.value!=''" class="example-tab-icon">notes</mat-icon>
        </ng-template>
            <textarea formControlName="special_instruction" class="form-control" style="width:68em; height:33.5em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">
            </textarea>

      </mat-tab>
    </mat-tab-group>
  </div>-->
      </div>
    </form>
  </div>
  <div style="justify-content:space-between;padding-left: 1em; padding-right:1em; padding-top:0.5em; padding-bottom:0.5em;display: flex;height:3em">
    <div style="display: flex;">
      <div style="margin-right:1em">
        <button type="button" mat-button class="btn-high" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Save</div></div></button>&nbsp;
        <button type="button" mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
      </div>

      <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
      <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
    </div>
    <!--<div style="float: right">
      <button mat-button class="btn-low" (click)="clickAudit()"><div style="display:flex"><div>Revisions</div></div></button>
    </div>-->
  </div>
</div>
