
<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-.5em" mat-dialog-title>
  <h5 class="ml-2" *ngIf="vendorDetails!=null">
    {{vendorDetails.icao}}
  </h5>
  <h5 class="ml-2" *ngIf="vendorDetails!=null">
    {{vendorDetails.vendorName}} - {{icao}} - {{vendorDetails.vendorRoleDescription}}
    <span *ngIf="userType=='internal'">
      <i class="material-icons-two-tone ml-1 blue" matTooltip="Preferred" *ngIf="vendorDetails.isPreferred">grade</i>
      <i *ngIf="vendorDetails.contractRequired=='Yes' && vendorDetails.contractSigned=='Yes'" class="material-icons ml-2" matTooltipClass="tooltipLineBreak" matTooltip="{{tooltipSigned}}">checklist_rtl</i>
      <i *ngIf="vendorDetails.contractRequired=='Yes' && vendorDetails.contractSigned=='No'" class="material-icons ml-2" matTooltipClass="tooltipLineBreak" matTooltip="{{tooltipNotSigned}}">rule</i>
    </span>
  </h5>
  <!--<h6 class="ml-3" *ngIf="vendorDetails!=null && vendorDetails.groundHandler!=null && vendorDetails.groundHandler!=''">Ground Handler - {{vendorDetails.groundHandler}}</h6>
  <h6 class="ml-3" *ngIf="vendorDetails!=null && vendorDetails.vendorRoleID==1 && vendorDetails.groundHandler==null">SSN Coordination Required</h6>-->

</div>
<form *ngIf="vendor_details_form" [formGroup]="vendor_details_form">

  <div [ngClass]="{ 'mat-dialog-content': showClose }" style="padding-bottom:0; background-color:white;border-radius:.25em;min-width:45em;font-size:small" class="mx-1 pb-3">

    <div [ngClass]="{ 'mt-2': showClose }" *ngIf="vendorDetails!=null">
      <mat-card class="mb-2" *ngIf="vendorDetails.vendorRoleID==1">
        <mat-card-header>
          <mat-card-title style="color:#146ec3;">
            <mat-icon style="color:#146ec3;">flight</mat-icon>&nbsp;
            <label style="color:#146ec3;">FPI Handling Notes</label>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="userType=='internal'" class="d-inline-flex">
            <div>
              <mat-radio-group id="briefType" aria-label="Select an option" [(ngModel)]="briefType" [ngModelOptions]="{standalone: true}">
                <mat-radio-button style="transform:scale(.75)" color="primary" value="internal">Internal</mat-radio-button>
                <mat-radio-button style="transform:scale(.75)" color="primary" value="client">Client</mat-radio-button>
              </mat-radio-group>
            </div>

            <div *ngIf="vendorDetails.serviceArrangement!=null" style="font-weight:bold;color:#cc0000;margin-bottom:.35em">Note: All inquiries and service requests should be sent to {{vendorDetails.serviceArrangement}}.</div>
          </div>
          <div class="d-flex justify-content-start">
            <div>
              <label class="font-weight-bold">{{icao}} - {{vendorDetails.vendorName}}</label>
            </div>
            <div class="ml-2" *ngIf="vendorDetails.relatedVendors && vendorDetails.vendorRoleID==1">
              <div class="d-inline-flex" *ngIf="!(groundStopGUID!=null && groundStopGUID != undefinded)">
                <label for="related_vendors" style="font-size:small">SSN</label>
                <label class="ml-2" style="font-size:small" *ngIf="groundStopGUID!=null && groundStopGUID != undefinded">{{selectedSSNVendorName}}</label>
                <select *ngIf="!(groundStopGUID!=null && groundStopGUID != undefinded)" formControlName="related_vendors" class="ml-2 form-control form-control-sm dropdown" (change)="updateComms($event)">
                  <option *ngIf="vendorDetails.serviceArrangement == null || vendorDetails.serviceArrangement.includes('Handler')">None</option>
                  <option *ngFor="let ven of vendorDetails.relatedVendors" [ngValue]="ven.vendorID">{{ven.vendorName}}{{ven.ratingID==4? ' **Primary':''}}</option>
                </select>
              </div>
            </div>
            <div class="ml-2">
              <button mat-button class="btn-high grid" (click)="generateHandlingNotes('pdf')"><span *ngIf="loadingPDF" class="spinner-border spinner-border-sm mr-1"></span>PDF</button>
              <button *ngIf="userType=='internal'" mat-button class="btn-high grid ml-1" (click)="generateHandlingNotes('word')"><span *ngIf="loadingWord" class="spinner-border spinner-border-sm mr-1"></span>Word</button>
            </div>
          </div>
          <div *ngIf="userType=='internal'">
            <label>Payment Method: {{vendorDetails.vendorMethodOfPaymentDescription}}</label>
          </div>
          <hr />
          <div *ngIf="relatedVendorDetails && (relatedVendorDetails.vendorComms.length>0 || relatedVendorDetails.vendorPersons.length>0)">
            <div><label class="font-weight-bold">Primary Contacts - {{relatedVendorDetails.vendorName}}</label></div>
            <div>
              <table width="100%">
                <ng-template ngFor let-person [ngForOf]="relatedVendorDetails.vendorPersons" let-i="index">
                  <tr *ngIf="briefType=='internal' || (briefType=='client' && person.includeInBrief)">
                    <td width="20%">{{person.jobTitle}}</td>
                    <td>{{person.firstName}} {{person.lastName}}</td>
                  </tr>
                  <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
                    <tr *ngIf="briefType=='internal' || (briefType=='client' && comm.includeInBrief)"><td width="20%">{{person.jobTitle}} {{comm.commAddressType}}</td><td>{{comm.address}}</td></tr>
                  </ng-template>
                </ng-template>
                <ng-template *ngIf="relatedVendorDetails.vendorComms && relatedVendorDetails.vendorComms.length>0" ngFor let-comm [ngForOf]="relatedVendorDetails.vendorComms" let-i="index">
                  <tr><td width="20%">{{comm.commAddressType}}</td><td>{{comm.address}}</td></tr>
                </ng-template>
              </table>
            </div>
            <br />
          </div>
          <div *ngIf="(vendorDetails.vendorComms) && (vendorDetails.vendorPersons!=null || relatedVendorDetails.vendorPersons.length>0)">
            <div><label class="font-weight-bold">Primary Contacts - {{vendorDetails.vendorName}}</label></div>
            <div>
              <table width="100%">
                <ng-template ngFor let-person [ngForOf]="vendorDetails.vendorPersons" let-i="index">
                  <tr *ngIf="briefType=='internal' || (briefType=='client' && person.includeInBrief)">
                    <td width="20%">{{person.jobTitle}}</td>
                    <td>{{person.firstName}} {{person.lastName}}</td>
                  </tr>
                  <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
                    <tr *ngIf="briefType=='internal' || (briefType=='client' && comm.includeInBrief)">
                      <td width="20%">{{person.jobTitle}} {{comm.commAddressType}}</td>
                      <td>{{comm.address}}</td>
                    </tr>
                  </ng-template>
                </ng-template>
                <ng-template ngFor let-comm [ngForOf]="vendorDetails.vendorComms" let-i="index">
                  <tr><td width="20%">{{comm.commAddressType}}</td><td>{{comm.address}}</td></tr>
                </ng-template>
              </table>
            </div>
            <br />
          </div>
          <div *ngIf="vendorDetails.vendorHours!=null && vendorDetails.vendorHours.length>0">
            <div>
              <label class="font-weight-bold">Handler Hours</label>
            </div>
            <div>
              <ng-template ngFor let-hours [ngForOf]="vendorHours" let-i="index">
                <table width="100%">
                  <tr><td colspan="2"><span style="text-decoration:underline">{{hours.hoursTypeDescription}} Hours</span></td></tr>
                  <tr *ngIf="hours.endDate!=null"><td width="20%">Effective Date</td><td>{{hours.effectiveDate}}</td></tr>
                  <tr *ngIf="hours.endDate!=null"><td width="20%">Expiration Date</td><td>{{hours.endDate}}</td></tr>
                  <tr *ngIf="hours.is24Hours"><td colspan="2">24 Hours</td></tr>
                  <ng-template *ngIf="hours.is24Hours==0" ngFor let-element [ngForOf]="hours.vendorDailyHours" let-i="index">
                    <tr>
                      <td width="20%">{{element.dayOfWeek}}</td>
                      <td>{{element.isClosed==1? 'Closed' : element.is24Hours? '24 Hours': element.openTime==null || element.openTime==''? ' ': element.openTime + ' - ' +  element.closeTime}}</td>
                    </tr>
                  </ng-template>
                  <tr *ngIf="hours.remarks!=null && hours.remarks!=''"><td width="20%">Remarks</td><td>{{hours.remarks}}</td></tr>
                </table>
                <br />
              </ng-template>
            </div>

          </div>
          <div *ngIf="vendorDetails.airportNotes!=null && vendorDetails.airportNotes.length>0">
            <div>
              <label class="font-weight-bold">{{vendorDetails.airportNotes[0].groupName}}</label>
            </div>
            <div>
              <table width="100%">
                <ng-template ngFor let-note [ngForOf]="vendorDetails.airportNotes" let-i="index">
                  <tr><td width="20%" valign="top">{{note.noteTypeDescription}}</td><td style="white-space:pre-wrap">{{note.note}}</td></tr>
                </ng-template>
              </table>
            </div>
            <br />
          </div>
          <div *ngIf="vendorDetails.customsNotes!=null && vendorDetails.customsNotes.length>0">
            <div>
              <label class="font-weight-bold">{{vendorDetails.customsNotes[0].groupName}}</label>
            </div>
            <div>
              <table width="100%">
                <ng-template ngFor let-note [ngForOf]="vendorDetails.customsNotes" let-i="index">
                  <tr><td width="20%" valign="top">{{note.noteTypeDescription}}</td><td style="white-space:pre-wrap">{{note.note}}</td></tr>
                </ng-template>
              </table>
            </div>
            <br />
          </div>
          <div *ngIf="vendorDetails.handlingNotes!=null && vendorDetails.handlingNotes.length>0">
            <div>
              <label class="font-weight-bold">{{vendorDetails.handlingNotes[0].groupName}}</label>
            </div>
            <div>
              <table width="100%">
                <ng-template ngFor let-note [ngForOf]="vendorDetails.handlingNotes" let-i="index">
                  <tr><td width="20%" valign="top">{{note.noteTypeDescription}}</td><td style="white-space:pre-wrap">{{note.note}}</td></tr>
                </ng-template>
              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="mb-2" *ngIf="userType=='internal' && (((vendorDetails.vendorComms!=null && vendorDetails.vendorComms.length>0) || vendorDetails.vendorRoleDescription=='Customs') || (vendorDetails.vendorPersons!=null && vendorDetails.vendorPersons.length>0))">
        <mat-card-header>
          <mat-card-title style="color:#146ec3;">
            <mat-icon style="color:#146ec3;">question_answer</mat-icon>&nbsp;
            <label style="color:#146ec3">Comms</label>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row" style="margin-top:-3em;padding-right:.5em" *ngIf="userType=='internal' && vendorDetails.vendorRoleDescription=='Customs'">
            <div class="col-10">
            </div>
            <div class="col-2">
              <button type="button" mat-button class="btn-high grid mr-1" (click)="editComms(vendorDetails.vendorGUID,null)">ADD</button>
            </div>
          </div>
          <ng-template ngFor let-person [ngForOf]="vendorDetails.vendorPersons" let-i="index">
            <div class="row justify-content-end">
              <div class="col-3 align-items-end font-weight-bold">
                <label>{{person.jobTitle}}</label>
              </div>
              <div class="col-8">
                <label>{{person.firstName}} {{person.lastName}}</label>
              </div>
              <div class="col-1">
                &nbsp;
              </div>
            </div>
            <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
              <div class="row justify-content-end">
                <div class="col-3 align-items-end font-weight-bold">
                  <label>{{person.jobTitle}} {{comm.commAddressType}}</label>
                </div>
                <div class="col-4">
                  <label>{{comm.address}}</label>
                </div>
                <div class="col-5">
                  <label>{{comm.commRemarks}}</label>
                </div>
              </div>
            </ng-template>
          </ng-template>
          <ng-template ngFor let-comm [ngForOf]="vendorDetails.vendorComms" let-i="index">

            <div class="row justify-content-end" style="padding-right: .5em">
              <div class="col-3 align-items-end font-weight-bold">
                <label>{{comm.commAddressType}}</label>
              </div>
              <div class="col-4" style="word-wrap:break-word">
                <a *ngIf="comm.commAddressType=='Weblink'" href="{{comm.address}}" target="_blank" rel="noopener noreferrer">{{comm.address}}</a>
                <label *ngIf="comm.commAddressType!='Weblink'">{{comm.address}}</label>
              </div>
              <div class="col-3" style="word-wrap:break-word">
                <label>{{comm.commRemarks}}</label>
              </div>
              <div class="col-2">
                <button *ngIf="userType=='internal' && vendorDetails.vendorRoleDescription=='Customs'" type="button" mat-button class="btn-low grid" (click)="editComms(vendorDetails.vendorGUID,comm)">EDIT</button>
              </div>
            </div>
          </ng-template>
        </mat-card-content>
      </mat-card>
      <mat-card class="mb-2" *ngIf="userType=='internal' && ((vendorDetails.vendorNotes!=null && vendorDetails.vendorNotes.length>0) || vendorDetails.vendorRoleDescription=='Customs')">
        <mat-card-header>
          <mat-card-title style="color:#146ec3;">
            <mat-icon style="color:#146ec3;">text_snippet</mat-icon>&nbsp;
            <label style="color:#146ec3;">Notes</label>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <!--<div class="row" style="margin-top:-3em;padding-right:.5em" *ngIf="userType=='internal' && vendorDetails.vendorRoleDescription=='Customs'">
        <div class="col-10">
        </div>
        <div class="col-2">-->
          <!--<button type="button" mat-button class="btn-high grid" (click)="editNotes(vendorDetails.vendorGUID,null)">ADD</button>-->
          <!--</div>
      </div>-->
          <ng-template ngFor let-note [ngForOf]="vendorDetails.vendorNotes" let-i="index">
            <div *ngIf="(vendorDetails.vendorRoleID==1 && note.includeInBrief==false) || vendorDetails.vendorRoleID!=1" class="row justify-content-end" style="padding-right: .5em">
              <div class="col-3 align-items-end font-weight-bold">
                <label>{{note.noteTypeDesc}}</label>
              </div>
              <div class="col-9">
                <label style="white-space:pre-wrap">{{note.vendorNote}}</label>
              </div>
            </div>
          </ng-template>
        </mat-card-content>
      </mat-card>
      <mat-card class="mb-2" *ngIf="userType=='internal'">
        <mat-card-header>
          <mat-card-title style="color:#146ec3;">
            <mat-icon style="color:#146ec3;">description</mat-icon>&nbsp;
            <label style="color:#146ec3;">Documents</label>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row" style="margin-top:-3em;padding-right:.5em" *ngIf="userType=='internal' && (vendorDetails.vendorDocuments==null || vendorDetails.vendorDocuments.length==0)">
            <div class="col-11">
            </div>
            <div class="col-1">
              <button type="button" mat-button class="btn-high grid" (click)="editDocument(vendorDetails.vendorGUID,null)">ADD</button>
            </div>
          </div>
          <div  *ngIf="vendorDetails.vendorDocuments!=null && vendorDetails.vendorDocuments.length>0" class="row justify-content-end" style="padding-right: .5em;padding-top:.5em">
            <div class="col-3 align-items-end font-weight-bold">
              <label>Document Type</label>
            </div>
            <div class="col-4 align-items-end font-weight-bold">
              <label>Document Name</label>
            </div>
            <div class="col-2 align-items-end font-weight-bold">
              Effective
            </div>
            <div class="col-2 align-items-end font-weight-bold">
              Expires
            </div>
            <div class="col-1">
              <button type="button" mat-button class="btn-high grid" (click)="editDocument(vendorDetails.vendorGUID,null)">ADD</button>
            </div>
          </div>
          <ng-template ngFor let-doc [ngForOf]="vendorDetails.vendorDocuments" let-i="index">
            <div class="row justify-content-end" style="padding-right: .5em">
              <div class="col-3 align-items-end">
                <label>{{doc.documentTypeDescription}}</label>
              </div>
              <div class="col-4 align-items-end">
                <a class="link" style="white-space:pre-wrap;cursor:pointer" (click)="viewDocument(doc)">{{doc.documentName}}</a>
              </div>
              <div class="col-2 align-items-end">
                <label>{{doc.effectiveDate}}</label>
              </div>
              <div class="col-2 align-items-end">
                <label>{{doc.expirationDate}}</label>
              </div>
              <div class="col-1">
                <button *ngIf="userType=='internal'" type="button" mat-button class="btn-low grid" (click)="editDocument(vendorDetails.vendorGUID,doc)">EDIT</button>
              </div>
            </div>
          </ng-template>
        </mat-card-content>
      </mat-card>
      <mat-card class="mb-2" *ngIf="(vendorDetails.vendorServices!=null && vendorDetails.vendorServices.length>0) ||  (vendorDetails.vendorEquipment!=null && vendorDetails.vendorEquipment.length>0) || (vendorDetails.vendorFacilities!=null && vendorDetails.vendorFacilities.length>0) || (vendorDetails.vendorThirdPartyServices!=null && vendorDetails.vendorThirdPartyServices.length>0)">
        <mat-card-header>
          <mat-card-title style="color:#146ec3;">
            <mat-icon style="color:#146ec3;">miscellaneous_services</mat-icon>&nbsp;
            Service Profile
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row justify-content-start">
            <div class="col-3">
              <label class="font-weight-bold">
                Services
              </label>
              <ng-template ngFor let-service [ngForOf]="vendorDetails.vendorServices" let-i="index">
                <div>
                  <label>{{service.servicesDescription}}</label>
                </div>
              </ng-template>
            </div>
            <div class="col-3" *ngIf="vendorDetails.vendorEquipment!=null && vendorDetails.vendorEquipment.length>0">
              <label class="font-weight-bold">
                Equipment
              </label>
              <ng-template ngFor let-equip [ngForOf]="vendorDetails.vendorEquipment" let-i="index">
                <div>
                  <label>{{equip.equipmentDescription}}</label>
                </div>
              </ng-template>
            </div>

            <div class="col-3" *ngIf="vendorDetails.vendorFacilities!=null && vendorDetails.vendorFacilities.length>0">
              <label class="font-weight-bold">
                Facilities
              </label>
              <ng-template ngFor let-facility [ngForOf]="vendorDetails.vendorFacilities" let-i="index">
                <div>
                  <label>{{facility.facilityDescription}}</label>
                </div>
              </ng-template>
            </div>
            <div class="col-3" *ngIf="vendorDetails.vendorThirdPartyServices!=null && vendorDetails.vendorThirdPartyServices.length>0">
              <label class="font-weight-bold">
                Third Party
              </label>
              <ng-template ngFor let-tp [ngForOf]="vendorDetails.vendorThirdPartyServices" let-i="index">
                <div>
                  <label>{{tp.thirdPartyServiceDescription}}</label>
                </div>
              </ng-template>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <!--<mat-expansion-panel *ngIf="(vendorDetails.vendorServices!=null && vendorDetails.vendorServices.length>0) ||  (vendorDetails.vendorEquipment!=null && vendorDetails.vendorEquipment.length>0) || (vendorDetails.vendorFacilities!=null && vendorDetails.vendorFacilities.length>0) || (vendorDetails.vendorThirdPartyServices!=null && vendorDetails.vendorThirdPartyServices.length>0)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon style="color:#146ec3;">miscellaneous_services</mat-icon>&nbsp;
        <label style="color:#146ec3">Service Profile</label>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row justify-content-start">
      <div class="col-3">
        <label class="font-weight-bold">
          Services
        </label>
        <ng-template ngFor let-service [ngForOf]="vendorDetails.vendorServices" let-i="index">
          <div>
            <label>{{service.servicesDescription}}</label>
          </div>
        </ng-template>
      </div>
      <div class="col-3" *ngIf="vendorDetails.vendorEquipment!=null && vendorDetails.vendorEquipment.length>0">
        <label class="font-weight-bold">
          Equipment
        </label>
        <ng-template ngFor let-equip [ngForOf]="vendorDetails.vendorEquipment" let-i="index">
          <div>
            <label>{{equip.equipmentDescription}}</label>
          </div>
        </ng-template>
      </div>

      <div class="col-3" *ngIf="vendorDetails.vendorFacilities!=null && vendorDetails.vendorFacilities.length>0">
        <label class="font-weight-bold">
          Facilities
        </label>
        <ng-template ngFor let-facility [ngForOf]="vendorDetails.vendorFacilities" let-i="index">
          <div>
            <label>{{facility.facilityDescription}}</label>
          </div>
        </ng-template>
      </div>
      <div class="col-3" *ngIf="vendorDetails.vendorThirdPartyServices!=null && vendorDetails.vendorThirdPartyServices.length>0">
        <label class="font-weight-bold">
          Third Party
        </label>
        <ng-template ngFor let-tp [ngForOf]="vendorDetails.vendorThirdPartyServices" let-i="index">
          <div>
            <label>{{tp.thirdPartyServiceDescription}}</label>
          </div>
        </ng-template>
      </div>
    </div>

  </mat-expansion-panel>-->
      <mat-card *ngIf="vendorDetails.intoPlaneAgent!=null">
        <mat-card-header>
          <mat-card-title style="color:#146ec3;">
            <mat-icon style="color:#146ec3;">help_outline</mat-icon>&nbsp;
            <label style="color:#146ec3;">Into Plane Agent</label>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="vendorDetails.intoPlaneAgent!=null" class="row">
            <div class="col-3 font-weight-bold">
              <label>Into Plane Agent</label>
            </div>
            <div class="col-9">
              <label>{{vendorDetails.intoPlaneAgent}}</label>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

    </div>
  </div>
</form>
<mat-dialog-actions *ngIf="showClose==true" align="left">
  <button mat-button mat-dialog-close class="btn-low">Close</button>
</mat-dialog-actions>

<app-spin *ngIf="isLoading"></app-spin>

