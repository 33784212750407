import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorModel } from '../models/vendor.model';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { ResponseModel } from '../models/response.model';
import { VendorService } from '../services/vendor.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { VendorCommEditDialogComponent } from './vendor-comm-edit-dialog.component';

@Component({
    selector: 'app-vendor-comms',
    templateUrl: './vendor-comms.component.html',
    styleUrls: ['./vendor-comms.component.css']
})
/** vendor-comms component*/
export class VendorCommsComponent implements OnInit, OnChanges {
  @Input() vendorGUID: string;
  @Input() includeDisabledRecords: boolean;
  vendor_comms_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  //customerList: CustomerModel[];
  //userType: string;
  showSpin: boolean = true;
  //selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  personId: string;
  recordList: PersonCommsAddressModel[];
  limitTo: number = 10;

  displayedColumn: string[] = ['addressUse', 'address', 'type', 'isPreferred', 'remark', 'disabled', 'action'];


  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _vendorService: VendorService) {

  }

  ngOnInit() {


  }

  ngOnChanges(changes: SimpleChanges): void {
    this.vendor_comms_form = this._formBuilder.group({

    });

    this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
      this.includeDisabledRecords = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults();

  }

  get f() { return this.vendor_comms_form.controls; }

  clickEdit(event: any, comm: PersonCommsAddressModel) {
    let s = "";
    let personCommsAddressGUID = "";
    if (comm == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      personCommsAddressGUID = comm.personCommsAddressGUID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, vendorGUID: this.vendorGUID, personCommsAddressGUID: personCommsAddressGUID };

    const dialogRef = this._dialog.open(VendorCommEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSearchResults();

      }
    });

  }

  getSearchResults() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new PersonCommsAddressModel();
    request.vendorGUID = this.vendorGUID;
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = !this.includeDisabledRecords;

    this.showSpin = false;
    this._vendorService.getVendorCommsAddress<ResponseModel<PersonCommsAddressModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

          }

        }
        else {
          this.recordList = [];
          this.msg = "No records found.";

        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    });

  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    this.getSearchResults();

  }


}
