<div>
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3125em;padding-left:.5em;padding-right:.5em">
      <div><h5>Import Messages</h5></div>
      <div>{{title}} / {{selectedFolder.totalItems}} Messages</div>
      <div *ngIf="this.selectedFolder.isImported" style="font-size:small">
        {{selectedFolder.importedCount}} messages imported by {{selectedFolder.importedBy}} on {{selectedFolder.importedDate}}
      </div>
    </div>
  </div>
  <div style="margin-left:.5em;margin-right:.5em">
    <app-spin *ngIf="showSpin"></app-spin>
    <form *ngIf="message_import_form" [formGroup]="message_import_form">
      <div style="background-color:white;padding-left: 1em; padding-right: 1em;padding-bottom:.5em;padding-top:.5em">

        <div style="padding-left: 1em; padding-right: 1em;">

          <div class="form-group form-inline">
            <label style="font-size:small;width:8em" class="justify-content-start">Client</label>
            <select *ngIf="f" formControlName="customer_select" style="font-size: small;width:19.5em;border-radius:0.25em; border-color:lightgrey" (change)="customerChange($event)">
              <option value="" selected>Client Name</option>
              <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
            </select>
          </div>
          <div class="form-group form-inline">
            <label style="font-size:small;width:8em" class="justify-content-start">Trip Code</label>
            <select *ngIf="f" formControlName="trip_code" style="font-size: small;width:19.5em;border-radius:0.25em; border-color:lightgrey">
              <option value="" selected>Trip Code</option>
              <option *ngFor="let t of tripCodeList" [ngValue]="t.tripCodeGUID">{{t.tripCode}}</option>
            </select>
          </div>
  </div>

    </div>
      <div>
      <div class="d-inline-flex">
        <div *ngIf="f" class="p-2">

          <button [disabled]="loading ||  f.customer_select.value ==''" mat-button class="btn-high mr-2" (click)="clickSave()">{{f.trip_code.value !=''? 'Import to Trip':'Import to Client'}}</button>
          <!--<button [disabled]="isLoading" mat-button class="btn-high mr-2" (click)="clickBack()">Back</button>-->
          <button mat-button class="btn-low" (click)="clickCancel()">Cancel</button>
        </div>
        <div *ngIf="showSuccessMsg">
          <app-success-message [successMsg]="successMsg"></app-success-message>
        </div>
        <div *ngIf="errMsg!=''" class="alert alert-danger small px-1 py-0 mt-2">
          {{errMsg}}
        </div>
      </div>
    </div>
</form>
</div>
  </div>
