import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerHomebaseAirportModel } from '../models/customer-homebase-airport.model';
import { CustomerRegulatoryServiceModel } from '../models/customer-regulatory-service.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';
import { CustomerHomebaseEditDialogComponent } from './customer-homebase-edit-dialog.component';
import { CustomerRegulatoryServicesEditDialogComponent } from './customer-regulatory-service-edit-dialog.component';

@Component({
  selector: 'app-customer-homebase-airport',
  templateUrl: './customer-homebase-airport.component.html',
  styleUrls: ['./customer-homebase-airport.component.css']
})
/** aircraft-customer-services component*/
export class CustomerHomebaseAirportComponent implements OnInit, OnChanges {
  customer_homebase_form: UntypedFormGroup;
  @Input() customerId: string;
  textSearch: string;
  filterBy: string;
  errMsg: string;
  showSpin: boolean;
  loading: boolean;
  msg: string;
  userType: string;
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  isActive: boolean;
  @Input() includeDisabledRecords: boolean
  totalRecordsText: string;
  recordList: CustomerHomebaseAirportModel[];

  displayedColumn: string[] = ['icao', 'iata', 'airport_name', 'airport_location', 'action'];
  /** aircraft-notes ctor */
  constructor(private _router: Router, private readonly _authService: AuthenticateService, private readonly _customerService: CustomerService, private readonly _dialogService: DialogService,
    private readonly _formBuilder: UntypedFormBuilder, private _route: ActivatedRoute, private readonly _dialog: MatDialog) {

  }

  ngOnInit() {


  }

  ngOnChanges(changes: SimpleChanges): void {
    this.customer_homebase_form = this._formBuilder.group({

    });

    // this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
      this.includeDisabledRecords = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getData();
  }


  initControls() {
    this.customer_homebase_form = this._formBuilder.group({

    });
  }

  get f() { return this.customer_homebase_form.controls; }

  getData() {
    let request = new CustomerHomebaseAirportModel();
    request.customerGUID = this.customerId;

    //if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
    //  request.isActive = !this.f.includeDisabledRecords.value;
    //}

    this._customerService.getCustomerHomebaseAirportList<ResponseModel<CustomerHomebaseAirportModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          if (response.model.length > 0) {
            this.recordList = response.model;
            
            }
          }
          else {

          }
        
      }
    });

  }

  clickEditHomebase(event: any, airport: CustomerHomebaseAirportModel) {
    let s = "";
    let customerHomebaseAirportID = 0;
    if (airport == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      customerHomebaseAirportID = airport.customerHomebaseAirportID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "30em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, customerGUID: this.customerId, customerHomebaseAirportID: customerHomebaseAirportID };
    const dialogRef = this._dialog.open(CustomerHomebaseEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();

      }

    });


  }

  clickRemoveHomebase(event: any, airport: CustomerHomebaseAirportModel) {
    let customerHomebaseAirportID = 0;
    customerHomebaseAirportID = airport.customerHomebaseAirportID;
    let request = new CustomerHomebaseAirportModel();
    request.customerHomebaseAirportID = customerHomebaseAirportID;
    this._customerService.saveCustomerHomebaseAirport<ResponseModel<number>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {

          this.getData();

        }
      }

    });


  }


}
