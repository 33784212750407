<div *ngIf="upPII" style="background-color:#f8f8f8; overflow-x:hidden; overflow-y:hidden">
  <div style="display: flex; justify-content: space-between;height:1.5em" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;display:flex"><h5>Document Downloads</h5><!--&nbsp;-&nbsp;<div style="font-size: small; font-weight:400; margin-top:0.25em">{{airportInfo}}</div>--></div>
  </div>
  <div>
    <form *ngIf="document_downloads_form" [formGroup]="document_downloads_form">
      <div style="display:flex; margin-top:0em; padding-left: 1em; padding-right:1em">
        <div style="margin-top:0em;padding-left:0em;padding-bottom: 0.5em; min-width:54.5em">
          <div style="display: flex">
            <div style="margin-top:0em; margin-right:0.625em">
              <label for="customer_select" style="font-size:small">Client Acct:</label>
              <div style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="customer_select" class="form-control form-control-sm dropdown" (change)="customerChange($event)">
                  <option value="" selected>Client Name</option>
                  <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                </select>
              </div>
            </div>
            <div style="margin-top:0em; margin-right:0.625em" *ngIf="f.customer_select.value!=''">
              <label for="trip_code_select" style="font-size:small">Trip Code ID:</label>
              <div style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="trip_code_select" class="form-control form-control-sm dropdown" (change)="tripCodeChange($event)">
                  <option value="" selected>Trip Code</option>
                  <option *ngFor="let t of tripCodeList" [ngValue]="t.tripCodeGUID">{{t.tripCode}}</option>
                </select>
              </div>
            </div>
            <div style="margin-top:0em; margin-right:0.625em" *ngIf="f.trip_code_select && f.trip_code_select.value!=''">
              <label for="ground_stop_select" style="font-size:small">Business Airport:</label>
              <div style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="ground_stop_select" class="form-control form-control-sm dropdown" (change)="groundStopChange($event)">
                  <option value="null" selected>Ground Stop</option>
                  <option *ngFor="let g of groundStopList" [ngValue]="g">{{g.groundStopBriefInfo}}</option>
                </select>
              </div>
            </div>
            <!--<div style="margin-top:1.25em; margin-right:0.625em" *ngIf="f.ground_stop_select && f.ground_stop_select.value!=''">
              <button class="btn btn-primary" [disabled]="loading" (click)="clickSearch()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Search&nbsp;</button>&nbsp;              
            </div>-->
          </div>
          <div style="display: flex">
            <div style="height:1.5em; display:flex; min-width: 43em">
              <div style="font-size: small; color: Highlight; margin-right:0.25em" *ngIf="tempFileNameDisplay!=''">Filename Starting Prefix:</div><div style="color: black; font-size: small">{{tempFileNameDisplay}}</div>
            </div>
            <div style="font-size:small; color: green">{{msg}}</div>
          </div>
          <div style="margin-top:0em;background-color:white; padding-left:0.5em; height: 30.85em">
            <div *ngIf="documentList && documentList.length>0" style="height: 30.85em; overflow-y: auto">
              <div class="example-action-buttons">
                <button mat-button (click)="openAll()" color="primary" style="outline:none; font-size: small">Expand All</button>
                <button mat-button (click)="closeAll()" color="primary" style="outline: none; font-size: small">Collapse All</button>
              </div>
              <div style="overflow-y:auto">
                <mat-accordion class="example-headers-align" [multi]="true">
                  <mat-expansion-panel *ngFor="let item of documentCategoryList, let i=index" [expanded]="item.panelOpenState" (opened)="item.panelOpenState=true" (closed)="item.panelOpenState=false" style="border: 0.0625em solid lightgray; width: 53em">
                    <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'" style="margin-left:-1em; font-weight:500; font-size:medium !important; text-align: left !important">
                      <mat-panel-title>
                        <div id="panel_{{i}}" #focusOnThisPanel>
                          {{item.documentCategory}}&nbsp;&nbsp;{{item.documentSelectedCountDisplay}}
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div style="min-height: 6.5em; max-height: 15em;overflow-y: auto;overflow-x:hidden;">
                      <table mat-table *ngIf="item.documentList" [dataSource]="item.documentList" class="example-container mat-elevation-z8">
                        <tr>
                          <ng-container matColumnDef="documentType">
                            <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:18%">
                              <div style="margin-left: -2em">Document Type</div>
                            </th>
                            <td mat-cell *matCellDef="let element"  style="line-height: 1.5625em;border-left:0.0625em solid lightgrey !important;word-wrap:break-word"> {{element.documentTypeDescription}}</td>
                            </ng-container>
                            <ng-container matColumnDef="documentName">
                              <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:67%">
                                <div>Available Documents</div>
                              </th>
                              <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                                <div style="display:flex">
                                  <div style="margin-right:0.25em">
                                    <a style="color: blue; cursor: pointer; text-decoration: underline;word-break:break-word" (click)="clickPreviewDocument($event, element)">
                                      {{element.documentName}}
                                    </a>
                                  </div>
                                  <div style="margin-right:0.25em">({{element.documentSizeInKB}}KB)</div>
                                  <div [ngClass]="{'alert-danger': element.dataEntryStatusDescription!=''}" style="cursor: pointer" title="Click to approve the document" (click)="clickUnApprovedDoc($event, element)">{{element.dataEntryStatusDescription!=''?'('+element.dataEntryStatusDescription+')':'' }}</div>
                                </div>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="expirationDate">
                              <th mat-header-cell *matHeaderCellDef style="width:10%;">
                                <div style="margin-left: -2em">Expiration Date</div>
                              </th>
                              <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;" [ngClass]="{'alert-danger': element.isExpired}">{{element.documentExpirationDate}}</td>
                            </ng-container>
                            <ng-container matColumnDef="check">
                              <th mat-header-cell *matHeaderCellDef style="width:5%;">
                                Select
                              </th>
                              <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-right:0.0625em solid lightgrey !important">
                                <div style="margin-top:0.5em" *ngIf="element.dataEntryStatusDescription==''">
                                  <mat-checkbox (click)="$event.stopPropagation()" (change)="checkDocumentChange($event, element)" [checked]="selection.isSelected(element)"></mat-checkbox>
                                </div>
                              </td>
                            </ng-container>
                        </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                    </table>
</div>
                    <mat-action-row>
                      <button mat-button [hidden]="i==0" color="primary" (click)="prevStep(i)" style="font-size: small">Previous</button>
                      <button mat-button color="primary" (click)="nextStep(i)" style="font-size: small">{{i<(documentCategoryList.length-1)? 'Next': 'End'}}</button>
                    </mat-action-row>

                  </mat-expansion-panel>
                </mat-accordion>
              </div>

            </div>
           </div>
          </div>
        </div>
      </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; display: flex;height:2.75em">
    <div style="margin-right:1em">
      <button class="btn btn-primary" *ngIf="this.selection.selected.length" [disabled]="loading" (click)="clickDownload()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:-0.15em">file_download</i><div style="margin-top:-0.5em">Download</div></div></button>&nbsp;
      <button class="btn btn-secondary" (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
    </div>
    <div *ngIf="errMsg!=''" class="d-block" style="margin-left:1em; display:flex;margin-top:-0.5em;background-color:#f9d2de; padding-left:0.25em; width:16em;"><label style="color:black; font-size: small">{{errMsg}}</label></div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]="successMsg"></app-success-message>
    </div>
  </div>
</div>
<div style="z-index:100; margin-top: -25em ; margin-left: 15em; position:relative; max-width: 20em">
  <app-spin *ngIf="showSpin"></app-spin>
</div>
