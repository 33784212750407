import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'; 
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { FuelService } from '../services/fuel.service';
import { Title } from '@angular/platform-browser'; 
import { ReturnObjModel } from '../models/return-obj.model'; 
import { CustomerModel } from '../models/customer.model';
import { FuelMenuComponent } from '../fuelmenu/fuel-menu.component';
import { MissingTaxesAirportModel } from '../models/mssing-taxes-airport.model';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { LocationMetricsComponent } from './location-metrics.component';

@Component({
  selector: 'app-missing-prices-airport',
  templateUrl: './missing-prices-airport.component.html',
  styleUrls: ['./missing-prices-airport.component.css']
})

export class MissingPricesAirportComponent implements OnInit { 

  missing_prices_airport_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg1: string;
  msg: string;
  showSpin: boolean = false;

 
  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  selectedTabName: string;
  reloadChildPage: boolean;
  totalRecordsText: string;
  totalRecords: number;
  pageSize: number = 15;
  totalPageNumber: number = 0;
  v: number = this._authService.getCurrentTimeNumber();
  startDate: Date;
  endDate: Date;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  pageFrom: string = "";
  sort_by: string;
  grantAccessToFuel: boolean = false;



  showClientList: boolean = false;  
  selectedClientList: CustomerModel[];
  selectedClientCountText: string = "";
  filteredClientList: Observable<CustomerModel[]>;  
  clientList: CustomerModel[];
  //selectedClientList: FuelClientMarkupModel[]; 
 
   
  includeDisabled: boolean = false;

  includeDisabledRecords: boolean; 
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;


  displayedColumn: string[] =/*'action1',*/[   'Country', 'ICAO', 'IATA', 'ArrivalDate', 'FuelQuoteCount', 'FuelReleaseCount'  , 'Action' ];


  displayedColumn2: string[] =/*'action1',*/[   'Country', 'ICAO', 'IATA','CustomerName','TripCode','Registration','ArrivalDate', 'FuelQuoteCount', 'FuelReleaseCount', 'Action' ];

  websiteEnvironment: string = "";
  currentPageIndex: number = 0;


  iscountryorairport: string = "country";
  sortedData: any[] = [];
  missingTaxesAirportList: MissingTaxesAirportModel[];
  fromMissingTaxesAirport: boolean =false;

  sortedData2: any[] = [];
  missingTaxesAirportAllDetailsList: MissingTaxesAirportModel[];
  fromMissingTaxesAirportAllDetails: boolean =false;
  showSpin2: boolean = false;
  searchFiltervalue: number = 7;
  isDialog: boolean = false;
  

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _fuelService: FuelService,
    private readonly _dialogService: DialogService, private _titleService: Title,
    @Optional() private readonly _dialogRef: MatDialogRef<MissingPricesAirportComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    //private readonly _dialogRef: MatDialogRef<MissingPricesAirportComponent>,
  ) {
      if (this.data) {
        this.isDialog = true;
      }
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToFuel = false;
    if (localStorage.getItem('up.fuel') == 'true') {
      this.grantAccessToFuel = true;
    }


    if (!this.grantAccessToFuel) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.errMsg = "";
    this.errMsg1 = "";
    this.msg = "";
    this.totalRecordsText = ""; 
 

    this.clientList = [];
    this.selectedClientList = [];

    this.sortedData = [];
    this.sortedData2 = [];
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate());
    this.initControls();
    
    this.clickSubmit();
    

  }

  initControls() {
    this.missing_prices_airport_form = this._formBuilder.group({ 



      includeCountryAll: [true],
      showAllDetails: [false],
      // includeDisabledRecords: [this.includeDisabledRecords]
    });
  }

  get f() { return this.missing_prices_airport_form.controls; }

  

  sortByChange(e: any) {
    this.clickSubmit();
  }

  
 
    
 
 
  clickSubmit() {
    this.submitted = true;
    this.errMsg = "";
  

   
   
      this.getData();
  }

  getData(isInit: boolean = false) {
    this.showSpin = true;
    let request = new MissingTaxesAirportModel();// new FuelSupplierModel();
 
    // if (this.paginator == undefined) {
    //   request.pageIndex = 0;
    //   request.pageSize = 25;
    // }
    // else {
    //   request.pageIndex = this.paginator.pageIndex;
    //   request.pageSize = this.paginator.pageSize;
    // }
    request.dayFilter = this.searchFiltervalue;
    
    request.includeCountryAll = !this.f.includeCountryAll.value;
     this.showSpin2 = true;

    this._fuelService.getmissingPricesForAirports<ResponseModel<MissingTaxesAirportModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          
          this.missingTaxesAirportList = response.model;
          // this.missingTaxesAirportList.push(...this.missingTaxesAirportList);
          // this.missingTaxesAirportList.push(...this.missingTaxesAirportList);
          // this.missingTaxesAirportList.push(...this.missingTaxesAirportList);
          // this.missingTaxesAirportList.push(...this.missingTaxesAirportList);
          // this.missingTaxesAirportList.push(...this.missingTaxesAirportList);
 


          this.sortedData = this.missingTaxesAirportList.slice();
          this.showSpin2 = false;
          if (response.model.length > 0) {
            if (this.missingTaxesAirportList != null) {
              this.totalRecords = this.missingTaxesAirportList.length;
              this.totalRecordsText = this.totalRecords + " total records";

            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
    });
   

  }
 
 

  pageIndexChange(e: any) { 
    this.clickSubmit(); 
  }

  

  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;


    //  this._dialogRef.close();

    //this._dialogRef.close(this.isModified);
  }
  clickRoute(routePage: string) {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    if (this.isDialog)
      this._dialogRef.close(true);

    this._dialog.open(FuelMenuComponent, config);   
  }
 
  exportToExcel(){
    let request = new MissingTaxesAirportModel();// new FuelSupplierModel();
    // request.pageSize = this.totalRecords;
    // request.pageIndex = 0;

    request.dayFilter = this.searchFiltervalue;

    this._fuelService.getmissingPricesForAirports<ResponseModel<MissingTaxesAirportModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          let tempMissingList = response.model;
          // this.sortedData = tempMissingList.slice();
          if (response.model.length > 0) {
            if (tempMissingList != null) {
              this.totalRecords = tempMissingList[0].totalRecords;
              this.totalRecordsText = this.totalRecords + " total records"
              let wb=new Workbook();
              let ws =wb.addWorksheet("MissingPrices")
              
              let header=["Country Name","ICAO", "IATA",  "Arrival Date", "Fuel Quote Count", "Fuel Release Count" ]
              ws.addRow(header);
              tempMissingList.forEach( al => {
                
                ws.addRow([al.countryName, al.icao , al.iata, al.arrivalDateUTC, al.fuelQuoteCount, al.fuelReleaseCount ]);
              } )
          
              wb.xlsx.writeBuffer().then((data)=>{
                let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob,"MissingPrices.xlsx");
              });
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
    });



  }

  excelReport() {
    this.exportToExcel();
  }



  includeCountryAllOnChange() {
  
      this.getData();
    
    
  }

  showAllDetailsOnChange() {
  
      this.paginator.pageIndex = 0; 
      this.getData();
   }


  clickOpenTrip(tripCodeGUID, custGUID, aircraftGUID) {
    //this._router.navigate(['/ground-stops/trip-details', { pId: p.personGUID }]);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "tripId": tripCodeGUID,
        "customerId": custGUID,
        "aircraftId": aircraftGUID,
        "pf": "",
        v: this._authService.getCurrentTimeNumber()
      }
    };
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    //this._router.navigate(['/ground-stops/trip-details-internal'], navigationExtras);
    window.open('/ground-stops/trip-details-internal?customerId=' + custGUID + "&tripId=" + tripCodeGUID + "&aircraftId=" + aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
  }
  
  searchFilterChange(e: any) {
    switch (e.target.value) {
      case '7d':
        this.searchFiltervalue = 7;
        this.getData();
        break;
      case '14d':
        this.searchFiltervalue = 14;
        this.getData();
        
        break; 
      case '30d':
        this.searchFiltervalue = 30; 
        this.getData();
            
        break; 
      default:
        this.searchFiltervalue = 7; 
        this.getData();
        return;
    }
 
  }

  clickOpenInNew( ){
    this._dialogRef.close(true);
    window.open('fuelmenu/missing-prices-airport' + "?v=" + this._authService.getCurrentTimeNumber());
  }

  openLocationMetricsComponent(element: any ) {
    if(element.hasQuotes){

      const config = new MatDialogConfig();
      // config.panelClass = "custom-dialog-container"
      //config.disableClose = true;
      config.height = "43em";//"1000px";
      config.width = "51em";//"1000px";
      
      // config.minHeight = "20em";
      config.restoreFocus = false;
      config.hasBackdrop = false;
      this.clickClose();
      config.data = { v: this._authService.getCurrentTimeNumber(), icao: element.icao, countryName: element.countryName,  airportID: element.airportID, searchFiltervalue: this.searchFiltervalue  };
      this._dialog.open(LocationMetricsComponent, config);
    }

  }

}


