import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { ResponseModel } from '../models/response.model';
import { GroundStopService } from '../services/ground-stop.service';
import { AccountingDashboardComponent } from './accounting-dashboard.component';

@Component({
  selector: 'app-gts-billable-services',
  templateUrl: './gts-billable-services.component.html',
  styleUrls: ['./gts-billable-services.component.css']
})

export class GtsBillableServicesComponent implements OnInit {

  gts_billable_services_form: UntypedFormGroup;
  isDialog: boolean = false;
  isCrew: boolean;
  serviceClassID:number = 1;
  gtsBillableServices: GroundStopTaskModel[] = [];
  airportServices: GroundStopTaskModel[] = [];
  flightPlanServices: GroundStopTaskModel[] = [];
  preflightServices: GroundStopTaskModel[] = [];
  
  displayedColumn: string[] = ['service_type'];

  constructor(  @Optional() private readonly _dialogRef: MatDialogRef<GtsBillableServicesComponent>, private readonly _commonService: CommonService,
  @Optional() @Inject(MAT_DIALOG_DATA) private data: any,  private readonly _authService: AuthenticateService, private readonly _groundStopService: GroundStopService,
  private readonly _formBuilder: UntypedFormBuilder, private readonly _dialog: MatDialog,
    ) {
   
      if (this.data) {
        this.isDialog = true;
        // this.username = this.data.username;
      }     
  }

  ngOnInit() {
    // this._authService.updateAccessTime();
    // this.initControls();

    this.getData();
   }

  // initControls() {
  //   this.gts_billable_services_form = this._formBuilder.group({
  //     // userComments: [this.userComments]
  //   });
  // }

  // get f() { return this.gts_billable_services_form.controls; }

  getData(){


    let getUpcomingTripCloseoutListResponse = this._groundStopService.getAllBillableTasksByServiceClassID<ResponseModel<GroundStopTaskModel[]>>(this.serviceClassID).subscribe(response => {

      if (response != null) {
        if (response.code == "200" && response.message == "") {

          // response.model = null;
          // this.tripCloseoutList = [];

          this.gtsBillableServices = response.model;
          this.airportServices = this.gtsBillableServices.filter(service => service.serviceClassID === 1);
          this.flightPlanServices = this.gtsBillableServices.filter(service => service.serviceClassID === 5);
          this.preflightServices = this.gtsBillableServices.filter(service => service.serviceClassID === 7);

        
          // this.initControls();

        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }

    });

  }

  clickClose() {
    this._dialogRef.close();
  }

  
  openAccountingDashboardComponent(){
    if(this.isDialog){  
      this.clickClose();
    }     
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container2"
    //config.disableClose = true;
    config.height = "39.5em";//"1000px";
    config.maxWidth = "64.5em";//"1000px";
    config.width = "89em";//"1000px";
    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {    v: this._authService.getCurrentTimeNumber()   };
    this._dialog.open(AccountingDashboardComponent, config);
  }

    
  clickRouteNew(route: string,display: string){
    if(this.isDialog){  
      this.clickClose();
    }     
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }
  
}
