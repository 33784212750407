<div style="margin-top:0em; padding-top:0em; display:flex">
  <form [formGroup]="aircraft_regulatory_service_form">
    <div style="margin-top:-1em">
      <table mat-table [dataSource]="recordList" class="example-container">
        <tr>
          <ng-container matColumnDef="service_name">
            <th mat-header-cell *matHeaderCellDef>
              Service Type
            </th>
            <td mat-cell *matCellDef="let element" width="10%">{{element.governmentServiceType}}</td>
          </ng-container>
          <ng-container matColumnDef="arranged_by">
            <th mat-header-cell *matHeaderCellDef>
              Arranged By
            </th>
            <td mat-cell *matCellDef="let element" width="10%">{{element.arrangedBy}}</td>
          </ng-container>
          <ng-container matColumnDef="service_date">
            <th mat-header-cell *matHeaderCellDef>
              Service Date
            </th>
            <td mat-cell *matCellDef="let element" width="5%">{{element.serviceDate}}</td>
          </ng-container>
          <ng-container matColumnDef="authorization_number">
            <th mat-header-cell *matHeaderCellDef>
              Authorization Number
            </th>
            <td mat-cell *matCellDef="let element" width="20%">{{element.authorizationNumber}}</td>
          </ng-container>
          <ng-container matColumnDef="effective_date">
            <th mat-header-cell *matHeaderCellDef>
              Effective Date
            </th>
            <td mat-cell *matCellDef="let element" width="10%">{{element.effectiveDate}}</td>
          </ng-container>
          <ng-container matColumnDef="expiration_date">
            <th mat-header-cell *matHeaderCellDef>
              Expiration Date
            </th>
            <td mat-cell *matCellDef="let element" width="5%" [ngClass]="{'alert-danger': element.isExpired}">{{element.expirationDate}}</td>
          </ng-container>
          <ng-container matColumnDef="obtained_by">
            <th mat-header-cell *matHeaderCellDef>
              Obtained By
            </th>
            <td mat-cell *matCellDef="let element" width="10%">{{element.obtainedBy}}</td>
          </ng-container>
          <ng-container matColumnDef="renewal_status">
            <th mat-header-cell *matHeaderCellDef>
              Renewal Status
            </th>
            <td mat-cell *matCellDef="let element" width="10%">{{element.renewalStatusDescription}}</td>
          </ng-container>
          <ng-container matColumnDef="remarks">
            <th mat-header-cell *matHeaderCellDef>
              Remarks
            </th>
            <td mat-cell *matCellDef="let element" width="10%">{{element.remarks}}</td>
          </ng-container>
          <ng-container matColumnDef="disabled">
            <th [hidden]="userType!='internal'" mat-header-cell *matHeaderCellDef>
              Disabled
            </th>
            <td [hidden]="userType!='internal'" mat-cell *matCellDef="let element" width="5%">{{element.isActive? '':'Yes'}}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              Action <br /><a *ngIf="userType=='internal'" mat-button class="btn-high grid" (click)="clickEdit($event,null)">Add</a>
            </th>
            <td mat-cell *matCellDef="let element; let i=index" width="10%">
              <a mat-button class="btn-low grid" (click)="clickEdit($event, element);">
                <span *ngIf="userType=='internal'">Edit</span>
                <span *ngIf="userType!='internal'">View</span>
              </a>
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
    </div>
    <div>
      <mat-paginator style="border: none;width:108em;margin-top:1em" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSize]="pageSize"
                     [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
      </mat-paginator>
    </div>
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
