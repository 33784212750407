<div style="margin-top: 0em; padding-top: 0em;  margin-bottom: 2em; "   [ngClass]="{ 'normalCss':!isDialog ,'dialogCss':isDialog  }" >
  <form *ngIf="fuel_Client_Markup_form" [formGroup]="fuel_Client_Markup_form">
    <div class="divLeft">
      <div  *ngIf="isDialog"  style="display: flex; justify-content: space-between;" cdkDrag  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div style="margin-right:1em; display: flex;" ><span style="margin-right: 0.2em;    "   class="material-symbols-outlined">paid</span><h5>  Fuel Client Markup</h5></div>
        <div>
          <div style="width:6em"><app-spin *ngIf="showSpin"></app-spin></div>

          <button mat-icon-button  (click)="clickOpenInNew()">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>  
          <button  *ngIf="isDialog" mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button>
        </div>
      </div>

      <div  *ngIf="!isDialog"  style="display: flex; justify-content: space-between;" >
        <div style="margin-right:1em; display: flex;" ><span style="margin-right: 0.2em;    "   class="material-symbols-outlined">paid</span><h5>  Fuel Client Markup</h5></div>
        <div>
          <div style="width:6em"><app-spin *ngIf="showSpin"></app-spin></div> 
        </div>
      </div>
   
   
   
      <div style="background-color: #f8f8f8; padding-left: 0.25em; padding-top: 0.25em; padding-right: 0.5em; margin-top: -0.5em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; width: 100%; height: 4.5em">
        <div style="margin-left:0em;padding:0em; display: flex; ">

          


          <div><!--</div> style="min-width:32em">-->
            <div style="display: flex;">
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedClientCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickClientFilter()">Client{{selectedClientCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedCountryCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickCountryFilter()">Country{{selectedCountryCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:8em;" [ngClass]="selectedAirportCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickAirportFilter()">Airport{{selectedAirportCountText}}</button>
              </div>
              <!--<div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:9em;" [ngClass]="selectedFuelSupplierCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickFuelSupplierFilter()">Fuel Supplier{{selectedFuelSupplierCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:12em;" [ngClass]="selectedIntoPlaneAgentCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickIntoPlaneAgentFilter()">Into Plane Agent{{selectedIntoPlaneAgentCountText}}</button>
              </div>-->
            </div>
            <div style="display:flex; min-width: 20em">
              <!--<div *ngIf="f.startDate.errors" class="small alert alert-danger p-0" style="font-size:small; width:max-content;margin-top:0.125em;height:1.5em;">
                <div *ngIf="f.startDate.errors.isValidDate==false">Invalid start date.</div>
              </div>
              <div *ngIf="f.endDate.errors" class="small alert alert-danger p-0" style="font-size: small; width: max-content; margin-top: 0.125em; height: 1.5em">
                <div *ngIf="f.endDate.errors.isValidDate==false">Invalid end date.</div>
                <div *ngIf="f.endDate.errors.matEndDateInvalid">End date cannot be before start date.</div>
              </div>-->
              <!--client list--><!--margin-left: 32.25em;-->
              <div *ngIf="showClientList" style="float: left; min-width: 11em; margin-top: 0.25em; background-color: white; border-top: 0.0625em lightgrey solid;
              border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;   
              border-radius: 0.25em; z-index: 1000; position: absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Client</div>
                  <div style="float:right" (click)="CloseClientList()"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="clientsearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />  <!--(keyup)="clientSearchChange($event)"-->
                    </div>
                  </div>
                </div>
                <div *ngIf="clientList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let v of filteredClientList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                    <div #tripclientRefs [attr.customerGUID]="v.customerGUID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkClientChange($event, v)" [checked]="v.selected">{{v.customerName}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetClient()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneClient()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--client list end-->
              <!--country list--><!--margin-left: 32.25em;-->
              <div *ngIf="showCountryList" style="float: left; min-width: 11em; margin-top: 0.25em; background-color: white; border-top: 0.0625em lightgrey solid;
              border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; margin-left: 7.3em;
              border-radius: 0.25em; z-index: 1000; position: absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Countries</div>
                  <div style="float:right" (click)="CloseCountryList()"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="countrysearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />  <!--(keyup)="countrySearchChange($event)"-->
                    </div>
                  </div>
                </div>
                <div *ngIf="countryList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let v of filteredCountryList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                    <div #tripCountryRefs [attr.countryGUID]="v.countryGUID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkCountryChange($event, v)" [checked]="v.selected">{{v.countryName}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetCountry()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneCountry()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--country list end-->
              <!--Airport list-->
              <div *ngIf="showAirportList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 14.7em; background-color: white;
               border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid;
               font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Airport ICAO</div>
                  <div style="float:right" (click)="CloseAirportList()"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="airportSearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameAirport($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="airportList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let a of filteredAirportList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div #tripAirportRefs [attr.icao]="a.icao" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkAirportChange($event, a)" [checked]="a.selected">{{a.icao}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetAirport()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneAirport()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--Airport list end-->
              
            </div>

          </div>

          <div style="display:flex">
            <div style="margin-top: 0.125em; margin-right: 0.5em; width: 24em; display: grid;">
              <div>
                <input type="checkbox" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)" /><label for="includeDisabledRecords" style="font-size:small">&nbsp;Show disabled records</label>
              </div>
              <div>
                <input type="checkbox" formControlName="displayExipredMarkups" (change)="displayExipredMarkupsOnChange($event)" /><label for="displayExipredMarkups" style="font-size:small">&nbsp;Display expired Markups</label>
              </div>

              <div style="display:flex">
                <!--<div style="font-size:small; margin-top: -.25em; margin-left: 0.3125em;">{{totalRecordsText}}</div>-->
                <div *ngIf="errMsg!=''" class="d-block" style="margin-left:0.5em; display:flex;margin-top:-0.5em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
              </div>
            </div>



            <div style="margin-top:0.125em; margin-right:0.5em;width:6em">
              <div>
                <button type="button" mat-button class="btn-low" (click)="clickReset()" style="height:1.5em;width:7em">
                  <div style="display: flex;margin-top:-0.125em">
                    <div style="margin-top: 0.125em; margin-left:-0.25em">
                      <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">restart_alt</i>
                    </div>
                    <div>
                      RESET
                    </div>
                  </div>
                </button>
                <button type="button" mat-button class="btn-low" (click)="clickSubmit()" style="height:1.5em;width:7em">
                  <div style="display: flex;margin-top:-0.125em">
                    <div style="margin-top: 0.25em; margin-left:-0.25em">
                      <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>
                    </div>
                    <div>
                      REFRESH
                    </div>
                  </div>
                </button>
              </div>

              <div style="display:flex">
                <!--<div style="font-size:small; margin-top: -.25em; margin-left: 0.3125em;">{{totalRecordsText}}</div>-->
                <div *ngIf="errMsg!=''" class="d-block" style="margin-left:0.5em; display:flex;margin-top:-0.5em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
              </div>


            </div>
            <div style="margin-top:0.125em; margin-right:0.5em;width:6em">
              <div>
                <button type="button" mat-button class="btn-low"   (click)="clickRoute('fuelmenu/fuel-menu')">
                  <!--(click)="clickReset()"-->
                  <div style="display: flex;margin-top:-0.125em">
                    <div style="margin-top: 0.125em; margin-left:-0.25em">
                      <i class="material-icons" style="font-size: medium;cursor: pointer; ">arrow_back</i>
                    </div>
                    <div>
                      Return to Fuel Dashboard
                    </div>
                  </div>
                </button>
                <!--<button type="button" mat-button class="btn-low" style="height:1.5em;">-->
                  <!--(click)="clickSubmit()"-->
                  <!--<div style="display: flex;margin-top:-0.125em">
                    <div style="margin-top: 0.25em; margin-left:-0.25em">
                      <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">download</i>
                    </div>
                    <div>
                      Export to Excel
                    </div>
                  </div>
                </button>-->
              </div> 
            </div>


          </div>


          <div>

          </div>

        </div>

      </div>

      <div><!--class="mt-2">-->
        <div class="d-flex justify-content-between" ><!--</div> style="margin-top: -1.05em;">-->

          <div class="d-inline-flex">
            <div *ngIf="selection.hasValue()" style=" display: flex;padding-top: 1em;  ">
              <!-- <button mat-icon-button *ngIf="selection.hasValue()" [matMenuTriggerFor]="menu" style="margin-top:-1em"><mat-icon style="font-size:medium;margin-top:-.5em;line-height:1.5">more_horiz</mat-icon></button> -->
              <button mat-button class="btn" (click)="deleteFP(true)">Delete</button>
              <button mat-button class="btn" (click)="clearStatusFP(false)">Clear Status</button>
              <button mat-button class="btn" (click)="printFP(false)">Print</button>
            </div>
          </div>
          <mat-paginator *ngIf="sortedData.length>0" style="background-color:transparent;" [pageSize]="25"
                         [showFirstLastButtons]="true"
                         [length]="totalRecords"
                         [pageSizeOptions]="[25, 50, 100]"
                         aria-label="Select page" (page)="pageEvent = pageIndexChange($event)">
          </mat-paginator>  
          <!--[pageSizeOptions]="[25, 50, 100, totalRecords]"-->
        </div>

        <table mat-table [dataSource]="sortedData" matSort matSortActive="FuelClientMarkupGUID" matSortDirection="asc" (matSortChange)="sortData($event)" class="example-container">

          <tr>
            <!--<ng-container matColumnDef="action1">
    <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 3%;width:3%;">
      <mat-checkbox style="height:1.5em;width:1.5em;" (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>

    </th>
    <td mat-cell *matCellDef="let row" style="text-align:center !important;flex:0 0 3%;width:3%">
      <mat-checkbox style="height:1.5em;width:1.5em" (click)="$event.stopPropagation()"
                    (change)="$event ? checkBox(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>-->
            <!--<ng-container matColumnDef="FuelClientMarkupGUID">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="FuelClientMarkupGUID" class="thStyle">
      ID
    </th>
    <td mat-cell *matCellDef="let element" [matTooltip]="element.isActive == '1' ? 'Active' : 'DIsabled'" [ngClass]="{ 'alert-danger':!element.isActive }">{{element.fuelClientMarkupGUID}}</td>
  </ng-container>-->
            <ng-container matColumnDef="Client">
              <th mat-header-cell *matHeaderCellDef  class=" ">
                Client
              </th>
              <td mat-cell *matCellDef="let element">{{ element.customerName==null?'All':element.customerName}}</td>
              <!--<td mat-cell *matCellDef="let element" [ngClass]="{ 'alert-danger': element.approvalStatus=='Pending Approval' }">{{element.Customer}}</td>-->
            </ng-container>
            <ng-container matColumnDef="Country">
              <th mat-header-cell *matHeaderCellDef   class=" ">
                Country
              </th>
              <td mat-cell *matCellDef="let element">{{ element.countryName}}</td>
            </ng-container>
            <ng-container matColumnDef="ICAO">
              <th mat-header-cell *matHeaderCellDef  class="thStyle">
                ICAO
              </th>
              <td mat-cell *matCellDef="let element">{{element.icao==null?'All':element.icao  }}</td>
            </ng-container>
            <ng-container matColumnDef="IATA">
              <th mat-header-cell *matHeaderCellDef  class="thStyle">
                IATA
              </th>
              <td mat-cell *matCellDef="let element">{{element.iata==null?'All':element.iata }}</td>
            </ng-container>
            <ng-container matColumnDef="MarkupAmt">
              <th mat-header-cell *matHeaderCellDef   class="thStyle">
                Markup Amount
              </th>
              <td mat-cell *matCellDef="let element"  >{{ element.markupAmt}}</td>
            </ng-container>
            <ng-container matColumnDef="EffectiveDate">
              <th mat-header-cell *matHeaderCellDef class="thStyle">
                Effective Date
              </th>
              <td mat-cell *matCellDef="let element"> {{element.effectiveDate}} </td>
            </ng-container>

            <ng-container matColumnDef="ExpiryDate">
              <th mat-header-cell *matHeaderCellDef class="thStyle">
                Expiry Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.expiryDate}}</td>
            </ng-container>

            <ng-container matColumnDef="InternalNotes">
              <th mat-header-cell *matHeaderCellDef   class="thStyle">
                Internal Notes
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.internalNotes}}
              </td>
            </ng-container>
            <ng-container matColumnDef="ModifiedDate">
              <th mat-header-cell *matHeaderCellDef class="thStyle">
                Modified Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.modifiedDate}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="thStyle">
                <div style="margin-top:-0.25em;">Action</div>
                <div><a mat-button class="btn-high grid" style="align-self:center" (click)="clickEdit($event, element);">Add</a></div>
              </th>
              <td mat-cell class="mat-cell2" *matCellDef="let element; let i=index ">
                <a mat-button class="btn-low grid">
                  <span>Edit</span>
                </a>
              </td>
            </ng-container>
          </tr>
          <!--<tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>-->
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row (click)="clickEdit($event, row)" *matRowDef="let row; columns: displayedColumn"></tr>
        </table>

        <!--<div style="display: inline-flex; max-width: 25em !important; margin-top: 1em; margin-bottom: 0em">-->
          <!--*ngIf="!showNewFolderInput"-->
          <!--<button mat-button class="btn-high " style="  text-align: left; " (click)="clickEdit($event, row)">
            <i class="material-icons" style="font-size:  large; margin-top: -.2em;">add </i>
            Add
          </button>-->
          <!--<button type="button" mat-button class="btn-low" (click)="importFuelPricing()" style="margin-left: 1em;">
            <div style="display:flex">
              <i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i>
              <div>Import</div>
            </div>
          </button>-->
          <!--<button type="button" mat-button class="btn-low" (click)="clickClose()" style="margin-left: 1em;">
    <div style="display:flex">
      <i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
      <div>CLOSE</div>
    </div>
  </button>&nbsp;-->
        <!--</div>-->
      </div>
    </div>
  </form>
</div>
