<div style="margin-top:0em; padding-top:0em;">
  <form *ngIf="manage_business_rules_form" [formGroup]="manage_business_rules_form">
    <div class="divLeft">
      <div style="display: flex">
        <div style="margin-right:1em"><h5>MANAGE BUSINESS RULES</h5></div>
      </div>
      <div style="background-color:#f8f8f8; padding-left:0.25em; padding-top:0.25em; padding-right:0.5em; margin-top:-0.5em; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; width:100%; height:4.5em">
        <div style="margin-left:0em;padding:0em; display: flex; ">
          <div>
            <div style="border: 0.0125em solid #337dff; margin-right:0.5em; padding-left:0.25em; border-radius: 0.25em">
              <div style="display: flex;margin-top:0.25em"><div style="font-size: small; font-weight: bold">Filter by Date Range</div></div>
              <div style="display: flex; width: 12.25em; margin-top: -.75em">
                <mat-date-range-input [rangePicker]="picker" style="display: flex; margin-top:0.5em; ">
                  <input matStartDate formControlName="startDate" #startDate placeholder="Start date" maxlength="10" [ngClass]="{ 'is-invalid1': isValidStartDate==false,  'is-valid1': isValidStartDate==true }" (dateChange)="dateOnchange('S', $event)" style="font-size:small;margin-top:0em; margin-right:1em;background-color: white; border-radius:0.25em;padding-left:0.125em;padding-right:0.125em; min-width: 10em !important">
                  <input matEndDate formControlName="endDate" #endDate placeholder="End date" maxlength="10" [ngClass]="{ 'is-invalid1': !isValidEndDate,  'is-valid1': isValidEndDate }" (dateChange)="dateOnchange('E', $event)" style="font-size:small;margin-left:0em; background-color: white; border-radius:0.25em;padding-left:0.125em; width: 5em !important ">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker" style="margin-top:0.25em; margin-left:-0.7em"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

              </div>
            </div>
          </div>
          <div style="min-width:65.25em">
            <div style="display: flex;">
              <div style="margin-top:0.5em; margin-right:0.25em;">
                <button type="button" mat-button class="btn-low" style="min-width:7em;" [ngClass]="selectedClientCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickClientFilter()">Client{{selectedClientCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em;">
                <button type="button" mat-button class="btn-low" style="min-width:7em" [ngClass]="selectedAircraftCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickAircraftFilter()">Aircraft{{selectedAircraftCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:10em;" [ngClass]="selectedPersonCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickManifestFilter()">Manifest{{selectedPersonCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedAirportCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickAirportFilter()">Airport{{selectedAirportCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedCountryCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickCountryFilter()">Country{{selectedCountryCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedVendorCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickVendorFilter()">Vendor{{selectedVendorCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedServicesCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickServiceFilter()">Services{{selectedServicesCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedLegTypeCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickLegTypeFilter()">Leg Type{{selectedLegTypeCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:10em;" [ngClass]="selectedAprrovalStatusCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickStatusFilter()">Approval Status{{selectedAprrovalStatusCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedExclusionCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickExclusionFilter()">Other{{selectedExclusionCountText}}</button>
              </div>
            </div>
            <div style="display:flex; min-width: 20em">
              <div *ngIf="f.startDate.errors" class="small alert alert-danger p-0" style="font-size:small; width:max-content;margin-top:0.125em;height:1.5em;">
                <div *ngIf="f.startDate.errors.isValidDate==false">Invalid start date.</div>
              </div>
              <div *ngIf="f.endDate.errors" class="small alert alert-danger p-0" style="font-size: small; width: max-content; margin-top: 0.125em; height: 1.5em">
                <div *ngIf="f.endDate.errors.isValidDate==false">Invalid end date.</div>
                <div *ngIf="f.endDate.errors.matEndDateInvalid">End date cannot be before start date.</div>
              </div>
              <!--client list-->
              <div *ngIf="showClientList" style="position:absolute;float: left; min-width: 12em; margin-top: 0.25em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Clients</div>
                  <div style="float:right" (click)="showClientList=!showClientList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="clientSearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameCustomer($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="clientList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let c of filteredClientList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkClientChange($event, c)" [checked]="c.selected">{{c.customerName}}</mat-checkbox>
                      </div>
                      <!--<div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em"></div></div>-->
                    </div>
                  </div>
                </div>
                <div style="margin-top:-0.5em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetClient()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneClient()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--client list end-->
              <!--aircraft list-->
              <div *ngIf="showAircraftList" style="float: left; min-width: 11em; margin-top: 0.25em; margin-left: 7.25em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Registration</div>
                  <div style="float:right" (click)="showAircraftList=!showAircraftList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="aircraftSearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameAircraft($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="aircraftList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let c of filteredAircraftList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkAircraftChange($event, c)" [checked]="c.selected">{{c.registration}}</mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="margin-top:-0.5em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetAircraft()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneAircraft()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--aircraft list end-->
              <!--Airport list-->
              <div *ngIf="showAirportList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 25em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Airport ICAO</div>
                  <div style="float:right" (click)="showAirportList=!showAirportList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="airportSearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameAirport($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="airportList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let a of filteredAirportList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div #tripAirportRefs [attr.icao]="a.icao" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkAirportChange($event, a)" [checked]="a.selected">{{a.icao}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetAirport()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneAirport()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--Airport list end-->
              <!--manifest list-->
              <div *ngIf="showPersonList" style="float: left; margin-top: 0.25em; margin-left: 14.75em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display: flex">
                  <div style="margin-right:2em">
                    <div style="font-weight: bold; text-decoration:underline">Role</div>
                    <div>
                      <div style="display: flex">
                        <div style="margin-right:0.25em">
                          <input formControlName="person_role"
                                 type="radio"
                                 value="crew" (click)="personRoleChange($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.15em">
                          <label>Crew</label>
                        </div>
                      </div>
                      <div style="display: flex">
                        <div style="margin-right:0.25em">
                          <input formControlName="person_role"
                                 type="radio"
                                 value="passenger" (click)="personRoleChange($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.15em">
                          <label>Passenger</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="min-width: 14em">
                    <div style="display:flex; justify-content:space-between">
                      <div style="font-weight: bold; text-decoration:underline; white-space: nowrap">{{person_role=='pilot'?'PIC / SIC':person_role | titlecase}}</div>
                      <div style="float:right" (click)="showPersonList=!showPersonList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                    </div>
                    <div style="font-size: small; margin-left:0em">
                      <div style="margin-right:0.5em;">
                        <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                          <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                          <input type="text" formControlName="person_search_keyword" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="personSearchKeywordChange($event)" />
                        </div>
                      </div>
                    </div>
                    <!--pilot-->
                    <div *ngIf="person_role=='pilot' && pilotList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                      <div *ngFor="let p of pilotList; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                        <div #tripPersonRefs [attr.personGUID]="p.personGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                          <div>
                            <mat-checkbox style="transform: scale(0.75);" (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)" [checked]="p.selected"></mat-checkbox>
                          </div>
                          <div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em; white-space: nowrap">{{p.personName}}</div></div>
                        </div>
                      </div>
                    </div>
                    <!--pilot end-->
                    <!--crew-->
                    <div *ngIf="person_role=='crew' && crewList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                      <div *ngFor="let p of crewList; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                        <div #tripPersonRefs [attr.personGUID]="p.personGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                          <div>
                            <mat-checkbox style="transform: scale(0.75);" (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)" [checked]="p.selected"></mat-checkbox>
                          </div>
                          <div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em; white-space: nowrap">{{p.personName}}</div></div>
                        </div>
                      </div>
                    </div>
                    <!--crew end-->
                    <!--passenger-->
                    <div *ngIf="person_role=='passenger' && paxList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                      <div *ngFor="let p of paxList; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                        <div #tripPersonRefs [attr.personGUID]="p.personGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                          <div>
                            <mat-checkbox style="transform: scale(0.75);" (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)" [checked]="p.selected"></mat-checkbox>
                          </div>
                          <div style="margin-top:0.1em;margin-left:0em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em; white-space: nowrap">{{p.personName}}</div></div>
                        </div>
                      </div>
                    </div>
                    <!--passenger end-->
                  </div>

                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetPerson()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDonePerson()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--manifest list end-->
              <!--country list-->
              <div *ngIf="showCountryList" style="float: left; min-width: 11em; margin-top: 0.25em; margin-left: 32.25em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Countries</div>
                  <div style="float:right" (click)="showCountryList=!showCountryList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="country_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="countrySearchChange($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="countryList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let v of filteredCountryList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                    <div #tripCountryRefs [attr.countryGUID]="v.countryGUID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkCountryChange($event, v)" [checked]="v.selected">{{v.countryName}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetCountry()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneCountry()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--country list end-->
              <!--vendor list-->
              <div *ngIf="showVendorList" style="float: left; min-width: 11em; margin-top: 0.25em; margin-left: 39.5em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Vendors</div>
                  <div style="float:right" (click)="showVendorList=!showVendorList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="vendor_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameVendor($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="vendorList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let v of filteredVendorList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div #tripVendorRefs [attr.vendorGUID]="v.vendorGUID" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkVendorChange($event, v)" [checked]="v.selected">{{v.vendorName}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetVendor()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneVendor()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--vendor list end-->
              <!--leg type list-->
              <div *ngIf="showLegTypeList" style="float: left; min-width: 11em; margin-top: 0.25em; margin-left: 54em; background-color: white; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Leg Type</div>
                  <div style="float:right" (click)="showLegTypeList=!showLegTypeList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>

                <div *ngIf="legTypeList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let v of legTypeList; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div #tripLegTypeRefs [attr.legTypeID]="v.tripLegTypeID" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkLegTypeChange($event, v)" [checked]="v.selected">{{v.tripLegTypeDescription}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetLegType()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneLegType()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--leg type list end-->
              <!--service list-->
              <div *ngIf="showServiceList" style="float: left; margin-top: 0.25em; margin-left: 46.75em; background-color: white; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000 ">
                <div style="display: flex">
                  <div style="margin-right:2em">

                    <div>
                      <div style="display: flex">
                        <div style="margin-right:0.25em">
                          <input formControlName="service"
                                 type="radio"
                                 value="service_class" (click)="serviceTypeChange($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.15em">
                          <label>Service Class</label>
                        </div>
                      </div>
                      <div style="display: flex">
                        <div style="margin-right:0.25em">
                          <input formControlName="service"
                                 type="radio"
                                 value="service_type" (click)="serviceTypeChange($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.15em">
                          <label>Service Type</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="min-width: 14em">
                    <div style="display:flex; justify-content:space-between">
                      <div style="font-weight: bold; text-decoration:underline; white-space: nowrap">{{service=='service_type'?'Service Type':'Service Class'}}</div>
                      <div style="float:right" (click)="showServiceList=!showServiceList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                    </div>
                    <div style="font-size: small; margin-left:0em">
                      <div style="margin-right:0.5em;">
                        <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                          <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                          <input type="text" formControlName="service_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameService($event,service)" />
                        </div>
                      </div>
                    </div>
                    <!--type-->
                    <div *ngIf="service=='service_type' && serviceTypeList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                      <div *ngFor="let p of filteredServiceList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                        <div #tripPersonRefs [attr.serviceTypeID]="p.serviceTypeID" style="display: flex; font-size: small; margin-top:-0.5em">
                          <div>
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="checkServiceChange($event, p)" [checked]="p.selected">{{p.serviceTypeDescription}}</mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--type end-->
                    <!--class-->
                    <div *ngIf="service=='service_class' && serviceClassList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                      <div *ngFor="let p of filteredServiceClassList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                        <div #tripPersonRefs [attr.serviceClassID]="p.serviceClassID" style="display: flex; font-size: small; margin-top:-0.5em">
                          <div>
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="checkServiceChange($event, p)" [checked]="p.selected">{{p.serviceClassDescription}}</mat-checkbox>
                          </div>

                        </div>
                      </div>
                    </div>
                    <!--class end-->
                  </div>

                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetService()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneService()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--service list end-->
              <!--status list-->
              <div *ngIf="showStatusList" style="float: left; min-width: 11em; margin-top: 0.25em; margin-left: 61.45em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Approval Status</div>
                  <div style="float:right" (click)="showStatusList=!showStatusList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>

                <div *ngIf="approvalStatusList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let s of approvalStatusList; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div #statusListRefs [attr.businessRulesApprovalStatusID]="s.businessRulesApprovalStatusID" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkStatusChange($event, s)" [checked]="s.selected">{{s.businessRulesApprovalStatusDescription}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetStatus()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneStatus()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--status list end-->
              <!--exclusion list-->
              <div *ngIf="showExclusionList" style="float: left; min-width: 14.25em; margin-top: 0.25em; margin-left: 72.45em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Other</div>
                  <div style="float:right" (click)="showExclusionList=!showExclusionList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>

                <div style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div [style.margin-top.em]="0.5" class="mat-checkbox-smaller">
                    <div style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="excludeFromHomebase" (change)="setExclusionText()" [ngModelOptions]="{standalone: true}" [checked]="excludeFromHomebase">Exclude From Homebase</mat-checkbox>
                      </div>

                    </div>
                  </div>
                  <div [style.margin-top.em]="0.25" class="mat-checkbox-smaller">
                    <div style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="avoidLocation" (change)="setExclusionText()" [ngModelOptions]="{standalone: true}" [checked]="avoidLocation">Avoid Location</mat-checkbox>
                      </div>

                    </div>
                  </div>
                  <div [style.margin-top.em]="0.25">
                    <div style="display: flex; font-size: small; margin-top:-0.5em;margin-bottom:.25em">
                      <div class="form-inline">
                        BR ID <input type="text" style="width: 4em; padding: 0em; height: 1.5625em; font-size: small; margin-left: .5em" formControlName="brID" (change)="setExclusionText()" class="form-control form-control-sm" />
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetExclusions()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneExclusions()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--status list end-->
            </div>
          </div>

          <div style="margin-top:0.125em; margin-right:0.5em;width:7em">
            <div>
              <button type="button" mat-button class="btn-low" (click)="clickReset()" style="height:1.5em;width:7em">
                <div style="display: flex;margin-top:-0.125em">
                  <div style="margin-top: 0.125em; margin-left:-0.25em">
                    <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">restart_alt</i>
                  </div>
                  <div>
                    RESET
                  </div>
                </div>
              </button>
              <button type="button" mat-button class="btn-low" (click)="clickSubmit()" style="height:1.5em;width:7em">
                <div style="display: flex;margin-top:-0.125em">
                  <div style="margin-top: 0.25em; margin-left:-0.25em">
                    <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>
                  </div>
                  <div>
                    REFRESH
                  </div>
                </div>
              </button>
            </div>

            <div style="display:flex">
              <!--<div style="font-size:small; margin-top: -.25em; margin-left: 0.3125em;">{{totalRecordsText}}</div>-->
              <div *ngIf="errMsg!=''" class="d-block" style="margin-left:0.5em; display:flex;margin-top:-0.5em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
            </div>


          </div>
          <div>
            <div style="width:8em"><app-spin *ngIf="showSpin"></app-spin></div>
            <div><label *ngIf="sortedData.length==0" style="font-size:small">Select filters to view records.</label></div>
          </div>
          <div>

          </div>

        </div>

      </div>
      
      <div class="mt-2">
        <mat-paginator *ngIf="sortedData.length>0" style="background-color:transparent;margin-top:-1.5em" [pageSize]="25"
                       [showFirstLastButtons]="true"
                       [length]="totalRecords"
                       [pageSizeOptions]="[25, 50, 100, totalRecords]"
                       aria-label="Select page"  (page)="pageEvent = pageIndexChange($event)" >
        </mat-paginator>
        <table mat-table [dataSource]="sortedData" matSort matSortActive="brID" matSortDirection="asc" (matSortChange)="sortData($event)" class="example-container">
          <!-- Header row first group -->
          <tr>
            <ng-container matColumnDef="brID">
              <th mat-header-cell class="mat-header-cell2" mat-sort-header="brID" *matHeaderCellDef [attr.rowspan]="2">
                BR ID
              </th>
            </ng-container>
            <ng-container matColumnDef="businessRule">

              <th mat-header-cell *matHeaderCellDef
                  [style.text-align]="center"
                  [attr.colspan]="7">
                Business Rule
              </th>
            </ng-container>

            <!-- Header row second group -->
            <ng-container matColumnDef="period">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="5"> Period </th>
            </ng-container>
            <ng-container matColumnDef="interest">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="4"> Areas of Interest</th>
            </ng-container>
            <ng-container matColumnDef="services">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"> Services, Tasks & Vendors</th>
            </ng-container>
            <ng-container matColumnDef="action">
              <th class="mat-header-cell2" [attr.rowspan]="2" mat-header-cell *matHeaderCellDef>
                Action <br /><a mat-button class="btn-high grid" (click)="clickEdit($event,null)">Add</a>
              </th>

            </ng-container>
          </tr>
          <tr>
            <ng-container matColumnDef="brID2">
              <th hidden class="mat-header-cell2" mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell class="mat-cell2" *matCellDef="let element">{{element.businessRulesID}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="status">
                Status
              </th>
              <td mat-cell *matCellDef="let element" [ngClass]="{ 'alert-danger': element.approvalStatus=='Pending Approval' }">{{element.approvalStatus}}</td>
            </ng-container>
            <ng-container matColumnDef="clientName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="clientName">
                Client
              </th>
              <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
            </ng-container>
            <ng-container matColumnDef="registration">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="registration">
                Registration
              </th>
              <td mat-cell *matCellDef="let element">{{element.registrationList==''? 'All': element.registrationList}}</td>
            </ng-container>
            <ng-container matColumnDef="mtow">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="mtow">
                MTOW
              </th>
              <td mat-cell *matCellDef="let element">{{element.mtowMin==0 && element.mtowMax==0 ? '':element.mtowMin}} - {{element.mtowMin==0 && element.mtowMax==0 ? '':element.mtowMax}}</td>
            </ng-container>
            <ng-container matColumnDef="crew">
              <th mat-header-cell *matHeaderCellDef>
                Crew
              </th>
              <td mat-cell *matCellDef="let element" matTooltip="{{element.crewList}}">{{element.crewList==''? 'All' : element.crewCount}}</td>
            </ng-container>
            <ng-container matColumnDef="pax">
              <th mat-header-cell *matHeaderCellDef>
                Pax
              </th>
              <td mat-cell *matCellDef="let element" matTooltip="{{element.paxList}}">{{element.paxList==''? 'All' : element.paxCount}}</td>
            </ng-container>
            <ng-container matColumnDef="farType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="farType">
                FAR Type
              </th>
              <td mat-cell *matCellDef="let element">{{element.farTypeDescription==null? 'All':element.farTypeDescription}}</td>
            </ng-container>
            <ng-container matColumnDef="isUTC">
              <th mat-header-cell *matHeaderCellDef>
                UTC or<br />Local
              </th>
              <td mat-cell *matCellDef="let element">{{element.isUTC? 'UTC' : 'Local'}}</td>
            </ng-container>
            <ng-container matColumnDef="effectiveDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="effectiveDate">
                Effective<br />Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.effectiveDate}}</td>
            </ng-container>
            <ng-container matColumnDef="expirationDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="expirationDate">
                Expiration<br />Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.expirationDate}}</td>
            </ng-container>
            <ng-container matColumnDef="timeFrame">
              <th mat-header-cell *matHeaderCellDef>
                Time Frame
              </th>
              <td mat-cell *matCellDef="let element">{{element.hoursStart}} - {{element.hoursEnd}}</td>
            </ng-container>
            <ng-container matColumnDef="daysOfWeek">
              <th mat-header-cell *matHeaderCellDef>
                Days of<br />The Week
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.sun? 'S':''}}{{element.mon? 'M':''}}{{element.tue? 'T':''}}{{element.wed? 'W':''}}{{element.thu? 'T':''}}{{element.fri? 'F':''}}{{element.sat? 'S':''}}{{element.sun==false && element.mon==false && element.tue==false && element.wed==false && element.thu==false && element.fri==false && element.sat==false? 'All':''}}
              </td>
            </ng-container>
            <ng-container matColumnDef="country">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="country">
                Country
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.countryName=='' || element.countryName==null? 'All':element.countryName}}
              </td>
            </ng-container>
            <ng-container matColumnDef="airport">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="airport">
                Airport
              </th>
              <td mat-cell *matCellDef="let element">{{element.icao=='' || element.icao==null? 'All':element.icao}}</td>
            </ng-container>
            <ng-container matColumnDef="legType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="legType">
                Leg Type
              </th>
              <td mat-cell *matCellDef="let element">{{element.legType=='' || element.legType==null? 'All':element.legType}}</td>
            </ng-container>
            <ng-container matColumnDef="avoid">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="avoid">
                Avoid
              </th>
              <td mat-cell *matCellDef="let element">{{element.avoidLocation? 'Yes':''}}</td>
            </ng-container>
            <!--<ng-container matColumnDef="serviceClass">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="serviceClass">
          Service Class
        </th>
        <td mat-cell *matCellDef="let element">{{element.serviceClassDescription=='' || element.serviceClassDescription==null? 'All':element.serviceClassDescription}}</td>
      </ng-container>-->
            <ng-container matColumnDef="serviceType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="serviceType">
                Service Type
              </th>
              
              <td mat-cell *matCellDef="let element">{{element.serviceTypeDescription=='' || element.serviceTypeDescription==null? 'All':element.serviceTypeDescription}}</td>
            </ng-container>
            <ng-container matColumnDef="taskStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="taskStatus">
                Task Status
              </th>
              <td mat-cell *matCellDef="let element">{{element.taskStatusDescription}}</td>
            </ng-container>
            <ng-container matColumnDef="vendor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="vendor">
                Vendor
              </th>
              <td mat-cell *matCellDef="let element">{{element.vendorName=='' || element.vendorName==null? 'All':element.vendorName}}</td>
            </ng-container>
            <ng-container matColumnDef="action2">
              <th hidden mat-header-cell *matHeaderCellDef>
                Action
              </th>
              <td mat-cell class="mat-cell2" *matCellDef="let element; let i=index ">
                <a mat-button class="btn-low grid">
                  <span>Edit</span>
                </a>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row (click)="clickEdit($event, row)" *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
    </div>
  </form>
</div>
