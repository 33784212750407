import { Component, OnInit, Output, EventEmitter, HostListener, Input, Renderer2, ViewChild, TemplateRef } from '@angular/core';
import { formatDate, DatePipe } from "@angular/common";
import { AuthenticateService } from '../services/authenticate.service';
import { Subject } from "rxjs";
import { SignInModel } from "../models/sign-in.model";
import { MenuModel } from "../models/menu.model";
import { MenuService } from '../services/menu.service';
import { Router, Event, NavigationStart, NavigationEnd, ActivatedRoute, RouterEvent, NavigationExtras } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { GenerateMessageTemplateComponent } from '../ground-stops/generate-message-template.component';
import { DocumentDownloadsDialogComponent } from '../ground-stops/document-downloads-dialog.component';
import { SessionExpirationAlertDialogComponent } from '../common-utility/session-expiration-alert-dialog.component';
import { AirportLocatorComponent } from '../airports/airport-locator.component';
import { VendorLocatorComponent } from '../vendors/vendor-locator.component';
import { GroundStopCreatorCityPairDialogComponent } from '../ground-stops/ground-stop-creator-citypair-dialog.component';
import { TripModel } from '../models/trip.model';
import { TripSummaryReportDialogComponent } from '../ground-stops/trip-summary-report-dialog.component';
import { GroundStopCreatorClientDialogComponent } from '../ground-stops-client/ground-stop-creator-client-dialog.component';
import { ClientFeedbackComponent } from '../common-utility/client-feedback.component';
import { EmailClientDialogComponent } from '../messages-client/email-client-dialog.component';
import { NewFeaturesDialogComponent } from '../common-utility/new-features-dialog.component';
import { PrivacyPolicyDialogComponent } from '../common-utility/privacy-policy-dialog.component';
import { TermsOfUseDialogComponent } from '../common-utility/terms-of-use-dialog.component';
import { ContactUsComponent } from '../common-utility/contact-us.component';
import { SubmitCurrentEventComponent } from '../current-events/submit-current-event.component';
import { Title } from '@angular/platform-browser';
import { ResponseModel } from '../models/response.model';
import { SignatureEditorComponent } from '../user-accounts/signature-editor.component';
import { TripPersonlistEditorClientDialogComponent } from '../trip-legs-client/trip-personlist-editor-client-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { HelpGuidesComponent } from '../help-guides/help-guides.component';
import { UpdateMessageFolderComponent } from '../add-message-folder/update-message-folder.component';
import { ApisAccountComponent } from '../apis/apis-account.component';
import { CountryLocatorComponent } from '../country/country-locator.component';
import { NoopScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { EngineeringTicketsComponent } from '../common-utility/engineering-tickets.component';
import { FuelMenuComponent } from '../fuelmenu/fuel-menu.component';
import { TemplateDashboardComponent } from '../templates/template-dashboard.component';
import { CountryDocumentTypeUpdateDialog } from '../country/country-document-type-update-dialog.component';
import { UpdateMyAccountComponent } from '../user-accounts/update-my-account.component';
import { FeedbackDialogComponent } from '../common-utility/feedback-dialog.component';
import { MaintenanceDashboardComponent } from '../common-utility/maintenance-dashboard.component';
import { AccountingDashboardComponent } from '../accounting/accounting-dashboard.component';
import { AccountGrouplistComponent } from '../account-groups/account-grouplist.component';
import { DocumentTypeListComponent } from '../documents/document-type-list.component';
import { RegisterUserComponent } from '../user-accounts/register-user.component';
import { AdvisoryEditComponent } from '../advisory/advisory-edit.component';

const datepipe: DatePipe = new DatePipe('en-US');

@Component({
  selector: 'layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.css'],
  providers: [DatePipe]
})
export class LayoutHeaderComponent implements OnInit {

  auth: Subject<SignInModel>;

  login: boolean;
  username: string;
  customerName: string;
  pwdExpirationDate: string;
  userType: string;
  menus: MenuModel[];
  isShown: boolean = true;
  userAccess: string;
  forceChangePwd: string;
  showMenuBorder: boolean = false;
  isMoboile: boolean = false;
  scrWidth: number;
  currentRoute: string;
  countryAccess: boolean = false;
  accountingAccess: boolean = false;
  templateAccess: boolean = false;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    //this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  websiteEnvironment: string = "";
  utcText: string = "";
  refreshUTCTimeHandler: any;

  constructor(private readonly _authService: AuthenticateService, public readonly _datepipe: DatePipe, private readonly _menuService: MenuService,
    private _router: Router, private readonly dialog: MatDialog, private ren: Renderer2, private overlay: Overlay) {
    this.auth = _authService.getSignIn();
    this.getScreenSize();
    //_router.events.filter(event => event instanceof NavigationEnd)
    //  .subscribe(event => {
    //    this.currentRoute = event.url;
    //    console.log(event);
    //  });

  }

  ngOnInit() {
    //clearInterval(this.refreshUTCTimeHandler);


    this._router.events.subscribe((routeEvent: Event) => {
      if (routeEvent instanceof NavigationStart) {

      }
      if (routeEvent instanceof NavigationEnd) {
        if (routeEvent.url == '/user-accounts/password' || routeEvent.url == '/user-accounts/username' || routeEvent.url == '/user-accounts/reset-password' || routeEvent.url == '/user-accounts/get-username') {
          this.isShown = false;
        }
        else {
          this.isShown = true;
        }
      }

    })

    var ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|Macintosh|CriOS/i.test(ua)) {
      this.isMoboile = true;
    }

    window.addEventListener("resize", function () {
      return window.innerWidth;

    });

    this.showMenuBorder = false;
    this.menus = [];
    var username = localStorage.getItem('fn');

    if (username != null && username != '') {
      this.login = true;
      this.username = username;
      this.userType = localStorage.getItem('ut').toLowerCase();
      this.customerName = localStorage.getItem('cn');
      //if (localStorage.getItem('ua') == '1')
      if (localStorage.getItem('up.isA').toLowerCase() == localStorage.getItem('up.isAB').toLowerCase())
        this.userAccess = 'Admin Access';
      else
        this.userAccess = '';
      this.forceChangePwd = localStorage.getItem('fcp');

      //this.forceChangePwd = "true";
      //this.userType = localStorage.getItem('userType');
      //this.pwdExpirationDate = '';
      //if (localStorage.getItem('pwdExpirationDate') != '' && localStorage.getItem('pwdExpirationDate') != null) {
      //  this.pwdExpirationDate = this._datepipe.transform(localStorage.getItem('pwdExpirationDate'), 'mediumDate');
      //}
      if (this.forceChangePwd != "true") {
        this._menuService.getUserMenus().subscribe(response => {
          this.menus = response;
          this.setupRefreshUTCTime();
        });
      }
      else {
        this.menus = [];
        clearInterval(this.refreshUTCTimeHandler);
      }
      if (localStorage.getItem('up.airportDoc') == 'true') {
        this.countryAccess = true;
      }

      if (localStorage.getItem('up.acct') == 'true') {
        this.accountingAccess = true;
      }

      if (localStorage.getItem('up.msgTemplates') == 'true') {
        this.templateAccess = true;
      }


    }

    this.auth.subscribe(v => {
      this.login = v.login;
      if (this.login) {
        this.username = v.firstName;
        this.customerName = v.customerName;
        //this.userAccess= v.isAdmin?'Admin Access':'';
        this.userAccess = v.isA.toLowerCase() == v.isAB.toLowerCase() ? 'Admin Access' : '';
        this.userType = v.userType.toLowerCase().substring(0, 1);
        if (localStorage.getItem('up.airportDoc') == 'true') {
          this.countryAccess = true;
        }
        if (localStorage.getItem('up.acct') == 'true') {
          this.accountingAccess = true;
        }


        //this.pwdExpirationDate = '';
        //if (v.pwdExpirationDate != '' && v.pwdExpirationDate != null) {
        //  this.pwdExpirationDate = this._datepipe.transform(v.pwdExpirationDate, 'mediumDate');
        //}
        //v.forceChangePwd = "true";
        if (v.forceChangePwd != "true") {
          //setTimeout(() => {
          //  this._menuService.getUserMenus().subscribe(response => {
          //    this.menus = response;
          //  });
          //}, 1500);
          this._menuService.getUserMenus().subscribe(response => {
            this.menus = response;
            this.setupRefreshUTCTime();
          });
        }
        else {
          this.menus = [];
          clearInterval(this.refreshUTCTimeHandler);
        }
      }
      else {
        clearInterval(this.refreshUTCTimeHandler);
      }
    });

    this._menuService.getWebsiteEnviroment<ResponseModel<string>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.websiteEnvironment = response.model;
      }

    });


  }


  onMenuMouseover(e: any, m: MenuModel) {
    let x = m;
  }

  onMouseLeaveMenu(m: MenuModel) {
    let x = m;
  }

  formatDisplayDate(s: string) {
    if (s != '') {
      s = formatDate(s, 'mediumDate', 'en-US');
    }
    return s;
  }

  onToggleTopBanner() {
    this.isShown = !this.isShown;
  }

  openDialog() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.minWidth = "35em"
    config.restoreFocus = false;
    config.data = { gstId: "", gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(GenerateMessageTemplateComponent, config);
    // Manually restore focus to the menu trigger since the element that
    // opens the dialog won't be in the DOM any more when the dialog closes.
    // dialogRef.afterClosed().subscribe(() => this.menuTrigger.focus());
  }

  openDocumentDownloadsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "46em";//"1000px";
    //dialogConfig.minHeight = "39em";//"500px";
    dialogConfig.minHeight = "40em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { v: this._authService.getCurrentTimeNumber() };

    const dialogRef = this.dialog.open(DocumentDownloadsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  clickRoute(route: string, display: string) {
    if (display == "Trip Schedules") {
      localStorage.removeItem('trip.sd');
      localStorage.removeItem('trip.ed');
      localStorage.removeItem('trip.tco');
      localStorage.removeItem('trip.idemo');
      localStorage.removeItem('trip.ord');
      localStorage.removeItem('trip.clist');
      localStorage.removeItem('trip.aclist');
      localStorage.removeItem('trip.aplist');
      localStorage.removeItem('trip.prole');
      localStorage.removeItem('trip.plist');
      localStorage.removeItem('trip.vlist');
      localStorage.removeItem('trip.gstlist');
      localStorage.removeItem('trip.cclist');
      localStorage.removeItem('trip.tclist');
      localStorage.removeItem('trip.filterTime');
    }

    switch (route) {
      case "dialog":
        this.openDialog();
        break;

      case "document-downloads-dialog":
        this.openDocumentDownloadsDialog();
        break;
      case "airport-locator-dialog":
        this.openAirportLocator();
        break;
      case "add-new-trip-dialog":
        this.openAddNewTripDialog();
        break;
      case "vendor-locator-dialog":
        this.openVendorLocator();
        break;
      case "country-locator-dialog":
        this.openCountryLocator();
        break;
      case "trip-summary-report":
        this.openTripSummaryReport();
        break;
      case "trip-creator-client-dialog":
        this.openGroundStopCreatorClientDialog('');
        break;
      case "trip-creator-client-Estimate-dialog":
        this.openGroundStopCreatorClientDialog('e');
        break;
      case "client-feedback":
        this.openClientFeedback();
        break;
      case "feedback-dialog":
        this.openFeedbackDialogComponent();
        break;
      case "engineering-tickets":
        this.openEngineeringTickets();
        break;
      case "email-client-dialog":
        this.openClientEmail();
        break;
      case "new-features":
        this.openNewFeatures();
        break;
      case "contact-us":
        this.openContactUs();
        break;
      case "submit-current-event":
        this.openSubmitCurrentEvent();
        break;
      case "dev-environment":
        this.openDevSite();
        break;
      case "qa-environment":
        this.openQASite();
        break;
      case "help-guides":
        this.openHelpGuides(display);
        break;
      case "fee-schedule":
        window.open(window.origin + "/assets/FPI_Service_Fees_Jan_2024.pdf")
        break;
      case "apis-dialog":
        this.openAPISComponent();
        break;
      case "template-dashbord":
        this.openTemplateComponent();
        break;
      case "fuel-menu":
        this.openFuelComponent();
        break;
      case "help-desk":
        window.open("https://flightprointlcom.sharepoint.com/sites/HelpDesk2/SitePages/ITHelpdeskHome.aspx?xsdata=MDV8MDJ8fDA1YzIzZjk2YzdhOTRjMWJhYjk3MDhkY2MwNTRjMzFmfGFhZjQyZTY2YjRiNjQzZTBhYmNkYTE5YTYyNmZmMGQ2fDB8MHw2Mzg1OTY3MTc0NjMxODYzMjd8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPbVUxWlRSaE5URmxZV1ZsTnpRNVlUZzVaamN4T0dJMk5UUXpOemRsTkRRd1FIUm9jbVZoWkM1Mk1pOXRaWE56WVdkbGN5OHhOekkwTURjME9UUTBOalk1fDkwNGNlOTdkYmViZDQ1NWFhYjk3MDhkY2MwNTRjMzFmfDlmZGU4YjUzMDY4NzRiZWE5NDZiMzMwNTkxNjIzZWQ2&sdata=SHk4VjV5RzU2WCtocXREZ3FGQjdESlo4SHg2ekI1d2lTRHRocHZDMDBiQT0%3D&ovuser=aaf42e66-b4b6-43e0-abcd-a19a626ff0d6%2CSLogan%40flightprointl.com&OR=Teams-HL&CT=1724248244147&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDA3MTEyODgyNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D", "_blank")
        break;
      default:
        //this._titleService.setTitle("FlightProConnect");
        this.gotoRoutePage(route, display);
        break
    }

  }

  gotoRoutePage(routePage: string, display: string) {
    let navigationExtras: NavigationExtras;// = {
    //  queryParams: {
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    switch (display) {
      case "Register new User - Client":
        navigationExtras = {
          queryParams: {
            selectedUserType: 'customer',
            v: this._authService.getCurrentTimeNumber()
          }
        }

        break;

      case "Register new User - FPC Operations":
        navigationExtras = {
          queryParams: {
            selectedUserType: 'internal',
            v: this._authService.getCurrentTimeNumber()
          }
        }
        break;
      case "Add new User":
        const config = new MatDialogConfig();
        config.panelClass = "custom-dialog-container3";
        config.height = "max-content";
        config.width = "max-content";
        config.restoreFocus = false;
        config.hasBackdrop = false;
        config.data = { selectedUserType: 'customer', v: this._authService.getCurrentTimeNumber() };
        this.dialog.open(RegisterUserComponent, config);
        return;
        break;
      default:
        navigationExtras = {
          queryParams: {
            v: this._authService.getCurrentTimeNumber()
          }
        }

        break;

    }
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    this._router.navigate([routePage], navigationExtras);
  }

  openAirportLocator() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container" 
    config.maxWidth = "92em";//"1000px";
    //config.height = "40em";//"1000px";
    config.autoFocus = true;
    config.hasBackdrop = false;
    config.autoFocus = true;

    const inputElement = document.getElementById('myInput');

    config.scrollStrategy = new NoopScrollStrategy();

    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    const dialogRef = this.dialog.open(AirportLocatorComponent, config);

    dialogRef.afterOpened().subscribe(() => {
      const inputElement = document.getElementById('myInput');
      if (inputElement) {
        inputElement.focus();
      }
    });

  }

  openAddNewTripDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: "", customerGUID: "", v: this._authService.getCurrentTimeNumber() };

    const dialogRef = this.dialog.open(GroundStopCreatorCityPairDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        let trip: TripModel;
        trip = new TripModel();
        trip.tripCodeGUID = result.newId;
        trip.aircraftGUID = result.aircraftGUID;
        trip.customerGUID = result.customerGUID;
        trip.tripCodeType = result.tripCodeType;
        this.clickOpenTripDetail(trip);

      }

    });
  }

  clickOpenTripDetail(p: TripModel) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "tripId": p.tripCodeGUID,
        "aircraftId": p.aircraftGUID,
        "customerId": p.customerGUID,
        "pf": "",
        tType: p.tripCodeType,
        v: this._authService.getCurrentTimeNumber()
      }
    };
    this._router.navigate(['/ground-stops/trip-details-internal'], navigationExtras);

  }

  openVendorLocator() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.minWidth = "35em"
    config.restoreFocus = false;
    config.autoFocus = false;
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(VendorLocatorComponent, config);
  }

  openTripSummaryReport() {

    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.restoreFocus = false;
    config.data = { tripCodeGUID: "" };
    this.dialog.open(TripSummaryReportDialogComponent, config);
  }

  openGroundStopCreatorClientDialog(tripType: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.minWidth = "46em";//"1000px";
    //dialogConfig.minHeight = "39em";//"500px";
    //dialogConfig.minHeight = "40em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { tripType: tripType, v: this._authService.getCurrentTimeNumber() };

    const dialogRef = this.dialog.open(GroundStopCreatorClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        let trip: TripModel;
        trip = new TripModel();
        trip.tripCodeGUID = result.newId;
        trip.aircraftGUID = result.aircraftGUID;
        trip.customerGUID = result.customerGUID;
        this.openTripPersonListEditorClient(trip.tripCodeGUID, trip.aircraftGUID, trip.customerGUID);
      }
    });
  }

  clickFeedback() {

  }

  openClientFeedback() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    this.dialog.open(ClientFeedbackComponent, config);
  }

  openEngineeringTickets() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    this.dialog.open(EngineeringTicketsComponent, config);
  }

  openClientEmail() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", groundStopGUID: "", groundStopTaskGUID: ""
    };

    const dialogRef = this.dialog.open(EmailClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });


  }


  openNewFeatures() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    this.dialog.open(NewFeaturesDialogComponent, config);
  }

  openPrivacy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    this.dialog.open(PrivacyPolicyDialogComponent, dialogConfig);

  }

  openTerm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    this.dialog.open(TermsOfUseDialogComponent, dialogConfig);

  }

  openContactUs() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    //dialogConfig.panelClass = "custom-dialog-container";

    dialogConfig.data = {
      isInternal: true
    };
    this.dialog.open(ContactUsComponent, dialogConfig);

  }

  openSubmitCurrentEvent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    this.dialog.open(SubmitCurrentEventComponent, config);
  }

  openDevSite() {
    window.open("https://fpconnectdev.azurewebsites.net", "_blank");
  }

  openQASite() {
    window.open("https://fpconnect-staging.azurewebsites.net", "_blank");
  }

  clickSignature() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", groundStopGUID: "", groundStopTaskGUID: ""
    };

    const dialogRef = this.dialog.open(SignatureEditorComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  openTripPersonListEditorClient(tripCodeGUID: string, aircraftGUID: string, customerGUID: string) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, tripCodeGUID: tripCodeGUID, aircraftGUID: aircraftGUID, customerGUID: customerGUID };

    const dialogRef = this.dialog.open(TripPersonlistEditorClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {

        let trip: TripModel;
        trip = new TripModel();
        trip.tripCodeGUID = result.newId;
        trip.aircraftGUID = result.aircraftGUID;
        trip.customerGUID = result.customerGUID;
        this.clickOpenTripDetailClient(trip);
        //this.getSearchResults();
      }

    });

  }

  clickOpenTripDetailClient(p: TripModel) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "tripId": p.tripCodeGUID,
        "aircraftId": p.aircraftGUID,
        "customerId": p.customerGUID,
        "pf": "",
        tType: p.tripCodeType,
        v: this._authService.getCurrentTimeNumber()
      }
    };
    this._router.navigate(['/ground-stops-client/trip-details-client'], navigationExtras);

  }

  setupRefreshUTCTime() {
    if (this.login) {
      this.refreshUTCTimeHandler = setInterval(() => { this.getCurrentUTCDateTime(); }, 30000); // 30"
      this.getCurrentUTCDateTime();
    }
  }

  getCurrentUTCDateTime() {
    this.utcText = formatDate(new Date().toUTCString(), 'dd MMM HH:mm', 'en-US', '+0000');    
  }

  clearStorage() {
    localStorage.removeItem('trip.sd');
    localStorage.removeItem('trip.ed');
    localStorage.removeItem('trip.tco');
    localStorage.removeItem('trip.idemo');
    localStorage.removeItem('trip.ord');
    localStorage.removeItem('trip.clist');
    localStorage.removeItem('trip.aclist');
    localStorage.removeItem('trip.aplist');
    localStorage.removeItem('trip.prole');
    localStorage.removeItem('trip.plist');
    localStorage.removeItem('trip.vlist');
    localStorage.removeItem('trip.gstlist');
    localStorage.removeItem('trip.cclist');
    localStorage.removeItem('trip.tclist');
    localStorage.removeItem('trip.filterTime');
  }


  openHelpGuides(type: string) {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    //config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.minWidth = "60em"
    config.height = "44em";
    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), type: type };
    this.dialog.open(HelpGuidesComponent, config);
  }

  clickRouteNew(route: string, display: string) {
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }

  addNewMessageFolder() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", groundStopGUID: "", groundStopTaskGUID: ""
    };

    const dialogRef = this.dialog.open(UpdateMessageFolderComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  openAPISComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    //config.disableClose = true;

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(ApisAccountComponent, config);
  }

  openCountryLocator() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px" };
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber(), username: this.username, };
    this.dialog.open(CountryLocatorComponent, config);
  }

  openFuelComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px" };
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber(), username: this.username, };
    this.dialog.open(FuelMenuComponent, config);
  }

  openManageAccount() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    //config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px" };
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber(), username: this.username, };
    this.dialog.open(UpdateMyAccountComponent, config);
  }

  openTemplateComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    //config.disableClose = true;

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(TemplateDashboardComponent, config);
  }

  openCountryDocumentsPicklistComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "43em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(CountryDocumentTypeUpdateDialog, config);
  }

  openFeedbackDialogComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "34.5em";//"1000px";
    config.width = "40em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { isTaskFeedback: false, v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(FeedbackDialogComponent, config);
  }

  openMaintenanceComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "31em";
    config.width = "43em";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), username: this.username, };
    config.position = { left: "10px", top: "140px" };
    this.dialog.open(MaintenanceDashboardComponent, config);
  }

  openAccountingDashboardComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container2"
    //config.disableClose = true;
    config.height = "39.5em";//"1000px";
    config.maxWidth = "64.5em";//"1000px";
    config.width = "89em";//"1000px";
    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), username: this.username, };
    this.dialog.open(AccountingDashboardComponent, config);
  }

  openAccountGrouping() {
    // clickRoute('account-groups/account-grouplist','')
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container2"
    //config.disableClose = true;
    config.height = "32.5em";//"1000px";
    config.maxWidth = "64.5em";//"1000px";
    config.width = "37.5em";//"1000px";
    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(AccountGrouplistComponent, config);
  }

  openDocumentTypeList() {
    // clickRoute('account-groups/account-grouplist','')
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "42em";//"1000px";
    config.maxWidth = "64.5em";//"1000px";
    config.width = "50em";//"1000px";
    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(DocumentTypeListComponent, config);
  }

  clickAddAdvisory() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.minHeight = "36.5em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px" };

    let title = 'Add';
    let advisoryId = 0;

    config.data = { gsId: "", tripCodeId: "", dialogTitle: title, advisoryId: advisoryId, v: this._authService.getCurrentTimeNumber() };
    const dialogRef = this.dialog.open(AdvisoryEditComponent, config);


  }


}
