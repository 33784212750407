import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { TripModel } from '../models/trip.model';
import { ResponseModel } from '../models/response.model';
import { GroundStopService } from '../services/ground-stop.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonFilter, TripCloseoutQueueModel } from '../models/trip-closeout-queue.model';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { TripSetupDialogComponent } from '../ground-stops/trip-setup-dialog.component';
import { TripLegService } from '../services/trip-leg.service';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { TripLegModel } from '../models/trip-leg.model';
import PizZipUtils from 'pizzip/utils';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { Title } from '@angular/platform-browser';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { AccountingDashboardComponent } from './accounting-dashboard.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

var expressions = require("angular-expressions");
var assign = require("lodash/assign");
// define your filter functions here, for example
// to be able to write {clientname | lower}
expressions.filters.lower = function (input) {
  // This condition should be used to make sure that if your input is
  // undefined, your output will be undefined as well and will not
  // throw an error
  if (!input) return input;
  return input.toLowerCase();
};

function angularParser(tag) {
  tag = tag
    .replace(/^\.$/, "this")
    .replace(/(�|�)/g, "'")
    .replace(/(�|�)/g, '"');
  const expr = expressions.compile(tag);
  return {
    get: function (scope, context) {
      let obj = {};
      const scopeList = context.scopeList;
      const num = context.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = assign(obj, scopeList[i]);
      }
      return expr(scope, obj);
    },
  };
}

function parser(tag) {
  // We write an exception to handle the tag "$pageBreakExceptLast"
  if (tag === "$pageBreakExceptLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (!isLast) {
          return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }
  if (tag === "$pageBreak") {
    return {
      get(scope, context) {
        return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';

      },
    };
  }



  // We use the angularParser as the default fallback
  // If you don't wish to use the angularParser,
  // you can use the default parser as documented here:
  // https://docxtemplater.com/docs/configuration#default-parser
  return angularParser(tag);
}


const datepipe: DatePipe = new DatePipe('en-US')

@Component({
  selector: 'app-trip-closeout-biiling-completed',
  templateUrl: './trip-closeout-biiling-completed.component.html',
  styleUrls: ['./trip-closeout-biiling-completed.component.css']
})

export class TripCloseOutBiilingCompletedComponent implements OnInit {
  // userType: string;
  // tripRevisionID: number;
  // userComments: string;
  trip_close_out_billing_completed_form: UntypedFormGroup;
  // loading = false;
  // v: number = this._authService.getCurrentTimeNumber();
  // returnObj = new ReturnObjModel();
  // controlValueChanged: boolean = false;
  // private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  // private eventSubscription: Subscription;
  displayedColumn: string[] = ['tripcloseout_date','first_arrival_date', 'last_arrival_date',    'client', 'trip_code', 'registration', 'route',  'activeCount',  'cancelledCount', 'task_count',   'remove_from_external', 'options'];

  loading: boolean = false;
  tripCloseoutList: TripModel[] = [];
  tripCloseoutQueue: TripCloseoutQueueModel;

  
  // closeoutFilter: CommonFilter[] = [];
  clientFilter: CommonFilter[] = [];
  tripCodeFilter: CommonFilter[] = [];
  registrationFilter: CommonFilter[] = [];
  closeout_search: any; 
  selectedCloseoutCount: number;

 

  showClientList: boolean = false;
  selectedClientList: CommonFilter[] = [];
  selectedClientCountText: string = "";
  filteredClientList: Observable<CommonFilter[]>;
  clientList: CommonFilter[] = [];

  showTripCodeList: boolean = false;
  selectedTripCodeList: CommonFilter[] = [];
  selectedTripCodeCountText: string = "";
  filteredTripCodeList: Observable<CommonFilter[]>;
  tripCodeList: CommonFilter[] = [];

  showRegistrationList: boolean = false;
  selectedRegistrationList: CommonFilter[] = [];
  selectedRegistrationCountText: string = "";
  filteredRegistrationList: Observable<CommonFilter[]>;
  registrationList: CommonFilter[] = [];

 


  clientData: CommonFilter[] = [];
  monthData: CommonFilter[] = [];
  registrationData: CommonFilter[] = [];
  // closeoutData: CommonFilter[] = [];
  tripCodeData: CommonFilter[] = [];
  startDate: Date;
  endDate: Date;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  trip_type: string;
  date_type: string;
  downloading1: boolean = false;
  downloading2: boolean = false;
  errMsg: string = '';


  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";
  reportList: string[] = ["report1","report2"];
  isRemoveFromExternalView: boolean = true;
  isLockdown: boolean = true;
  isDemo: boolean = false;
  isOnHoldTrip: boolean = false;
  showSpin: boolean = false;
  totalRecords: number;
  totalRecordsText: string = '';
  selectedIndex: number = -1;

  
  monthFilter: CommonFilter[] = [];
  showMonthList: boolean = false;
  selectedMonthList: CommonFilter[] = [];
  selectedMonthCountText: string = "";
  filteredMonthList: Observable<CommonFilter[]>;
  monthList: CommonFilter[] = [];
  totalAirportServices: number= 0;
  totalFFServices: number= 0;
  totalFPServices: number= 0;
  totalPreflightServices: number= 0;
  totalServices: number = 0;
  totalActiveAirports: number = 0;
  totalCanXAirports: number = 0;
  totalClientCount: number = 0;
  // currentYear: number =0;

  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;

  displayNameMap = new Map([
    [Breakpoints.XSmall, 'xSmall'],
    [Breakpoints.Small, 'small'],
    [Breakpoints.Medium, 'medium'],
    [Breakpoints.Large, 'large'],
    [Breakpoints.XLarge, 'xLarge'],
  ]);
  destroyed = new Subject<void>();
  currentScreenSize: string;
  
  constructor(//private readonly _dialogRef: MatDialogRef<TripCloseOutBiilingCompletedComponent>, //@Inject(MAT_DIALOG_DATA) _data: any,
  private readonly _formBuilder: UntypedFormBuilder, private readonly _groundStopService: GroundStopService,
  private readonly _authService: AuthenticateService,  private readonly _tripLegService: TripLegService, 
  private readonly _dialog: MatDialog, private _titleService:Title  ,  breakpointObserver: BreakpointObserver
    ) {
      breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'unknown';
          }
        }
      });

    // this.tripRevisionID = _data.tripRevisionID;
    // this.userComments = _data.userComments;
  }

  ngOnInit() {

    // this._authService.updateAccessTime();

    let d = new Date();
    let uyear: number = d.getUTCFullYear();
    let umonth: number = d.getUTCMonth();
    let udate: number = d.getUTCDate();
    let uhour: number = d.getUTCHours();
    let uminute: number = d.getUTCMinutes();    
    let d1: Date = new Date(uyear, umonth, udate, uhour, uminute);
    let hstartnumber: number = 168 * 60 * 60 * 1000;    // 1 week back
    let hendnumber: number = 168 * 60 * 60 * 1000;
    let dt1: Date = new Date(d1.getTime() - hstartnumber);
    let dt2: Date = new Date(d1.getTime() + hendnumber);
    this.startDate = dt1;
    this.endDate = dt2;   
    // this.currentYear = d.getFullYear();

    this.trip_type = 'all'; //'leadtime';  
    this.date_type = 'currentWeek'; //'leadtime';  

    //this._titleService.setTitle("Billing Completed Dashboard");
    this.initControls();

    this.getData();
    this.populateMonthData();

   }

  initControls() {
    this.trip_close_out_billing_completed_form = this._formBuilder.group({
      closeout_search: [this.closeout_search],
      clientsearch: [''],
      tripCodesearch: [''],
      closeoutsearch: [''],
      registrationsearch: [''],
      monthsearch: [''],
      selectedyearsearch: [this.currentYear],
      startDate: [this.startDate, Validators.required],
      endDate: [this.endDate, Validators.required],
      trip_type: [this.trip_type],
      date_type: [this.date_type],

      isRemoveFromExternalView: [this.isRemoveFromExternalView],
      isLockdown: [this.isLockdown],
      isDemo: [this.isDemo],
      isOnHoldTrip: [this.isOnHoldTrip],
    });


  }

  get f() { return this.trip_close_out_billing_completed_form.controls; }

  get currentYear(){ return new Date().getFullYear();}
  get previousYear(){ return new Date().getFullYear() - 1;}



  serviceTypeChange2(e: any ) {
 
    this.getData();

  }
  dateTypeChange(e: any ) {
 
    if(this.f.date_type.value != 'choose')
    this.getData();

    else{
      this.clickMonthFilter();
      this.showMonthList = true;
    }

  }

  dateOnchange(dateType: string, e: any) {
    if (dateType == "S") {
       if (e.target._elementRef.nativeElement.value != "") {
         if (e.target.value == null) {
           this.isValidStartDate = false;
           this.f.startDate.setErrors({ isValidDate: false });
         }
         else {
           this.isValidStartDate = true;
         }
       }
       else {
         this.isValidStartDate = true;
       }
     }
 
     if (dateType == "E") {
       if (e.target._elementRef.nativeElement.value != "") {
         if (e.target.value == null) {
           this.isValidEndDate = false;
           this.f.endDate.setErrors({ isValidDate: false })
         }
         else {
           this.isValidEndDate = true;
         }
       }
       else {
         this.isValidEndDate = true;
       }
     }
 
   }

   
  clearCounts(){
    this.totalAirportServices= 0;
    this.totalFFServices= 0;
    this.totalFPServices= 0;
    this.totalPreflightServices= 0;
    this.totalServices = 0;
    this.totalActiveAirports = 0;
    this.totalCanXAirports = 0;
    this.totalClientCount = 0;
  }

  getData(){

    this.tripCloseoutList = [];
          
    this.clientFilter = [];
    this.tripCodeFilter = [];
    this.registrationFilter = [];
    // this.monthFilter = [];
    this.clearCounts();
    this.hideAllFilters();


    let request = new TripCloseoutQueueModel();
    
    // if(this.closeoutFilter?.length >0){
      
    //   request.tripCloseOutIDList =  Array.prototype.map.call(this.closeoutFilter.filter(x => x.selected), s => s.filterID).toString();
    // }
    // if(this.selectedClientList?.length >0){
      
    //   request.customerGUIDList =  Array.prototype.map.call(this.selectedClientList, s => s.filterID).toString();
    // }

    // request.startDate = this.f.startDate.value;
    // request.endDate = this.f.endDate.value;
    // request.includeTrips =  this.f.trip_type.value;
    request.dateType =  this.f.date_type.value ;//== 'choose'? '1': this.f.date_type.value ;
    request.isRemoveFromExternalView =  this.f.isRemoveFromExternalView.value;
    request.isDemo =  this.f.isDemo.value;
    request.isOnHoldTrip =  this.f.isOnHoldTrip.value;
    request.selectedYear =  this.f.selectedyearsearch.value;
    request.isBillingCompleted = true;

     
    if (this.selectedClientList?.length == this.clientList.length || this.selectedClientList?.length == 0) {
      request.customerGUIDList = "";
    }
    else {
      // this.selectedClientList = this.selectedClientList?.filter(x => x.filterID != 'all');
      request.customerGUIDList = Array.prototype.map.call(this.selectedClientList, s => s.filterID).toString();
    }
    
    if (this.selectedTripCodeList?.length == this.tripCodeList.length || this.selectedTripCodeList?.length == 0) {
      request.tripCodeGUIDList = "";
    }
    else {
      // this.selectedClientList = this.selectedClientList?.filter(x => x.filterID != 'all');
      request.tripCodeGUIDList = Array.prototype.map.call(this.selectedTripCodeList, s => s.filterID).toString();
    }
    
    if (this.selectedRegistrationList?.length == this.registrationList.length || this.selectedRegistrationList?.length == 0) {
      request.aircraftGUIDList = "";
    }
    else {
      // this.selectedClientList = this.selectedClientList?.filter(x => x.filterID != 'all');
      request.aircraftGUIDList = Array.prototype.map.call(this.selectedRegistrationList, s => s.filterID).toString();
    }
    
    if (this.selectedMonthList?.length == this.monthList.length || this.selectedMonthList?.length == 0) {
      request.monthIDList = "";
    }
    else {
      // this.selectedClientList = this.selectedClientList?.filter(x => x.filterID != 'all');
      request.monthIDList = Array.prototype.map.call(this.selectedMonthList, s => s.filterID).toString();
    }

     
    if (this.paginator == undefined) {
      request.pageIndex = 0;
      request.pageSize = 25;
    }
    else {
      request.pageIndex = this.paginator.pageIndex;
      request.pageSize = this.paginator.pageSize;
    }


    this.showSpin = true;

    let getUpcomingTripCloseoutListResponse = this._groundStopService.getUpcomingTripCloseoutListDashboard<ResponseModel<TripCloseoutQueueModel>>(request).subscribe(response => {

      // console.log(response)
      if (response != null) {
        if (response.code == "200" && response.message == "") {

          // response.model = null;
          this.tripCloseoutList = [];
          this.showSpin = false;
          //this._titleService.setTitle("Billing Completed Dashboard");
          
          this.clientFilter = [];
          this.tripCodeFilter = [];
          this.registrationFilter = [];
          this.totalRecordsText =  '';
          this.tripCloseoutQueue = response.model;
          this.tripCloseoutList = response.model.tripCloseoutList;

          this.tripCloseoutList?.forEach( x => x.clicked1 = false);
          this.tripCloseoutList?.forEach( x => x.clicked2 = false);
          this.tripCloseoutList?.forEach( x => x.downloading1 = false);
          this.tripCloseoutList?.forEach( x => x.downloading2 = false);

          // let t
          this.totalAirportServices = 0;
          this.tripCloseoutList?.forEach(x => this.totalAirportServices = this.totalAirportServices + x.aTaskCount);
          
          this.totalFPServices = 0;
          this.tripCloseoutList?.forEach(x => this.totalFPServices = this.totalFPServices + x.fpTaskCount);

          this.totalFFServices = 0;
          this.tripCloseoutList?.forEach(x => this.totalFFServices = this.totalFFServices + x.ffTaskCount);

          this.totalPreflightServices = 0;
          this.tripCloseoutList?.forEach(x => this.totalPreflightServices = this.totalPreflightServices + x.pTaskCount);
         
          this.totalServices = 0;
          this.tripCloseoutList?.forEach(x => this.totalServices = this.totalServices + x.taskCount);

          this.totalActiveAirports = 0;
          this.tripCloseoutList?.forEach(x => this.totalActiveAirports = this.totalActiveAirports + x.activeStops);

          this.totalCanXAirports = 0;
          this.tripCloseoutList?.forEach(x => this.totalCanXAirports = this.totalCanXAirports + x.cancelledStops);
          
          // this.selectedClientList.length == 0
          
          this.totalClientCount = 0;
          this.totalClientCount = this.selectedClientList.length == 0? response.model.clientFilter?.length : this.selectedClientList.length;




          this.totalRecords = this.tripCloseoutList?.length;
          if (this.tripCloseoutList?.length > 1) {
            this.totalRecordsText = this.totalRecords.toString() + " Records Found";
          } 
          
          
          this.clientFilter = response.model.clientFilter;
          this.tripCodeFilter = response.model.tripCodeFilter;
          this.registrationFilter = response.model.registrationFilter;


          this.clientFilter?.forEach( x => x.selected = false);
          this.tripCodeFilter?.forEach( x => x.selected = false);
          this.registrationFilter?.forEach( x => x.selected = false);

          this.clientData = response.model?.clientFilter?.slice();
          this.tripCodeData = response.model?.tripCodeFilter?.slice();
          this.registrationData = response.model?.registrationFilter?.slice();

 
          // this.initControls();

        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }

    });

  }


  // checkCloseoutChange(e: any, item: CommonFilter){
  //   // if(e.checked){
  //   // }
  //   item.selected = e.checked;
  //   this.selectedCloseoutCount = this.clientFilter.filter(x => x.selected ).length;
  //   this.selectedCloseoutCountText = ': ' +  this.selectedCloseoutCount.toString();
  // }
 

  clickSubmit() {
    this.getData();
    
  }
  
  //client
  clickClientFilter() {
    this.getClientList();
  }

  getClientList() {
    
    this.hideAllFilters();
    this.showClientList = true;
 
    
    


    this.clientList = [];
    let c = new CommonFilter();

      this.clientData.forEach(x => {
        c = new CommonFilter();
        c.filterName = x.filterName
        // c.filterID = x.filterID;
        c.filterID = x.filterID;
        c.selected = false;
        if (this.selectedClientList?.length > 0) {
          let f: CommonFilter[];
          f = this.selectedClientList.filter(y => y.filterID == x.filterID );
          //f = this.selectedClientList.filter(y => y.filterID.toLowerCase() == x.filterID.toLowerCase());
          if (f.length > 0) {
            c.selected = true;
          }
        }
        this.clientList.push(c);
      });
      this.clientList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.filterName.localeCompare(b.filterName));
      c = new CommonFilter();
      // c.filterID = 0;
      c.filterID = "all";
      c.filterName = "Select All";
      c.selected = false;
      this.clientList.unshift(c);
      this.filteredClientList = this.f.clientsearch.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.filterName)),
        map(filterName => (filterName ? this._filterClient(filterName) : this.clientList.slice())),
      );
      this.setClientList();
  }

  displayNameClient(client: CommonFilter): string {
    return client && client.filterName ? client.filterName : '';
  }

  private _filterClient(name: string): CommonFilter[] {
    const filterValue = name.toLowerCase();

    return this.clientList.filter(option => option.filterName.toLowerCase().includes(filterValue));
  }

  checkClientChange(e: any, item: CommonFilter) {
    if (item.filterID == "all") {
      this.clientList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.clientList.forEach(x => {
        if (item.filterID == x.filterID) {
          x.selected = e.checked;
        }
      });
    }
    this.setClientList();
  }

  setClientList() {
    this.selectedClientList = this.clientList.filter(u => u.selected == true);
    this.selectedClientCountText = "";
    if (this.clientList.length > 0) {
      if (this.selectedClientList.length == this.clientList.length) {
        this.selectedClientCountText = ": All";
      }
      else {
        if (this.selectedClientList.length > 0)
          this.selectedClientCountText = ": " + this.selectedClientList.length.toString();
      }
    }
  }

  clickDoneClient() {
    this.showClientList = false;
    this.clickSubmit();
  }

  clickResetClient() {
    this.clientList.forEach(x => {
      x.selected = false;
    });
    this.f.clientsearch.setValue("");
    this.setClientList();
    this.clickSubmit();
  }
  //client end


  
  
  //tripCode
  clickTripCodeFilter() {
    this.getTripCodeList();
  }

  getTripCodeList() {
    
    this.hideAllFilters();
    this.showTripCodeList = true;
 
    
    


    this.tripCodeList = [];
    let c = new CommonFilter();

      this.tripCodeData.forEach(x => {
        c = new CommonFilter();
        c.filterName = x.filterName
        // c.filterID = x.filterID;
        c.filterID = x.filterID;
        c.selected = false;
        if (this.selectedTripCodeList?.length > 0) {
          let f: CommonFilter[];
          f = this.selectedTripCodeList.filter(y => y.filterID == x.filterID );
          //f = this.selectedTripCodeList.filter(y => y.filterID.toLowerCase() == x.filterID.toLowerCase());
          if (f.length > 0) {
            c.selected = true;
          }
        }
        this.tripCodeList.push(c);
      });
      this.tripCodeList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.filterName.localeCompare(b.filterName));
      c = new CommonFilter();
      // c.filterID = 0;
      c.filterID = "all";
      c.filterName = "Select All";
      c.selected = false;
      this.tripCodeList.unshift(c);
      this.filteredTripCodeList = this.f.tripCodesearch.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.filterName)),
        map(filterName => (filterName ? this._filterTripCode(filterName) : this.tripCodeList.slice())),
      );
      this.setTripCodeList();
  }

  displayNameTripCode(tripCode: CommonFilter): string {
    return tripCode && tripCode.filterName ? tripCode.filterName : '';
  }

  private _filterTripCode(name: string): CommonFilter[] {
    const filterValue = name.toLowerCase();

    return this.tripCodeList.filter(option => option.filterName.toLowerCase().includes(filterValue));
  }

  checkTripCodeChange(e: any, item: CommonFilter) {
    if (item.filterID == "all") {
      this.tripCodeList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.tripCodeList.forEach(x => {
        if (item.filterID == x.filterID) {
          x.selected = e.checked;
        }
      });
    }
    this.setTripCodeList();
  }

  setTripCodeList() {
    this.selectedTripCodeList = this.tripCodeList.filter(u => u.selected == true);
    this.selectedTripCodeCountText = "";
    if (this.tripCodeList.length > 0) {
      if (this.selectedTripCodeList.length == this.tripCodeList.length) {
        this.selectedTripCodeCountText = ": All";
      }
      else {
        if (this.selectedTripCodeList.length > 0)
          this.selectedTripCodeCountText = ": " + this.selectedTripCodeList.length.toString();
      }
    }
  }

  clickDoneTripCode() {
    this.showTripCodeList = false;
    this.clickSubmit();
  }

  clickResetTripCode() {
    this.tripCodeList.forEach(x => {
      x.selected = false;
    });
    this.f.tripCodesearch.setValue("");
    this.setTripCodeList();
    this.clickSubmit();
  }
  //tripCode end
 
  
  
  //registration
  clickRegistrationFilter() {
    this.getRegistrationList();
  }

  getRegistrationList() {
    
    this.hideAllFilters();
    this.showRegistrationList = true;
 
    
    


    this.registrationList = [];
    let c = new CommonFilter();

      this.registrationData.forEach(x => {
        c = new CommonFilter();
        c.filterName = x.filterName
        // c.filterID = x.filterID;
        c.filterID = x.filterID;
        c.selected = false;
        if (this.selectedRegistrationList?.length > 0) {
          let f: CommonFilter[];
          f = this.selectedRegistrationList.filter(y => y.filterID == x.filterID );
          //f = this.selectedRegistrationList.filter(y => y.filterID.toLowerCase() == x.filterID.toLowerCase());
          if (f.length > 0) {
            c.selected = true;
          }
        }
        this.registrationList.push(c);
      });
      this.registrationList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.filterName.localeCompare(b.filterName));
      c = new CommonFilter();
      // c.filterID = 0;
      c.filterID = "all";
      c.filterName = "Select All";
      c.selected = false;
      this.registrationList.unshift(c);
      this.filteredRegistrationList = this.f.registrationsearch.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.filterName)),
        map(filterName => (filterName ? this._filterRegistration(filterName) : this.registrationList.slice())),
      );
      this.setRegistrationList();
  }

  displayNameRegistration(registration: CommonFilter): string {
    return registration && registration.filterName ? registration.filterName : '';
  }

  private _filterRegistration(name: string): CommonFilter[] {
    const filterValue = name.toLowerCase();

    return this.registrationList.filter(option => option.filterName.toLowerCase().includes(filterValue));
  }

  checkRegistrationChange(e: any, item: CommonFilter) {
    if (item.filterID == "all") {
      this.registrationList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.registrationList.forEach(x => {
        if (item.filterID == x.filterID) {
          x.selected = e.checked;
        }
      });
    }
    this.setRegistrationList();
  }

  setRegistrationList() {
    this.selectedRegistrationList = this.registrationList.filter(u => u.selected == true);
    this.selectedRegistrationCountText = "";
    if (this.registrationList.length > 0) {
      if (this.selectedRegistrationList.length == this.registrationList.length) {
        this.selectedRegistrationCountText = ": All";
      }
      else {
        if (this.selectedRegistrationList.length > 0)
          this.selectedRegistrationCountText = ": " + this.selectedRegistrationList.length.toString();
      }
    }
  }

  clickDoneRegistration() {
    this.showRegistrationList = false;
    this.clickSubmit();
  }

  clickResetRegistration() {
    this.registrationList.forEach(x => {
      x.selected = false;
    });
    this.f.registrationsearch.setValue("");
    this.setRegistrationList();
    this.clickSubmit();
  }
  //registration end


  
 

  //month

  
  populateMonthData() {
    for (let i = 1; i <= 12; i++) {
      this.monthData.push({
        filterID: i.toString(),
        filterName: this.getMonthName(i),
        selected: false
      });
    }

    this.monthFilter = this.monthData;
    // for (let i = 1; i <= 12; i++) {
    //   this.monthFilter.push({
    //     filterID: i.toString(),
    //     filterName: this.getMonthName(i),
    //     selected: false
    //   });
    // }
  }

  getMonthName(monthIndex: number): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthIndex - 1];
  }


  clickMonthFilter() {
    this.getMonthList();
  }

  getMonthList() {
    
    this.hideAllFilters();
    this.showMonthList = true;
  
    
    


    this.monthList = [];
    let c = new CommonFilter();

      this.monthData.forEach(x => {
        c = new CommonFilter();
        c.filterName = x.filterName
        // c.filterID = x.filterID;
        c.filterID = x.filterID;
        c.selected = false;
        if (this.selectedMonthList?.length > 0) {
          let f: CommonFilter[];
          f = this.selectedMonthList.filter(y => y.filterID == x.filterID );
          //f = this.selectedMonthList.filter(y => y.filterID.toLowerCase() == x.filterID.toLowerCase());
          if (f.length > 0) {
            c.selected = true;
          }
        }
        this.monthList.push(c);
      });
      // this.monthList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.filterName.localeCompare(b.filterName));
      c = new CommonFilter();
      // c.filterID = 0;
      // c.filterID = "all";
      // c.filterName = "Select All";
      // c.selected = false;
      // this.monthList.unshift(c);
      this.filteredMonthList = this.f.monthsearch.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.filterName)),
        map(filterName => (filterName ? this._filterMonth(filterName) : this.monthList.slice())),
      );
      this.setMonthList();
  }

  displayNameMonth(month: CommonFilter): string {
    return month && month.filterName ? month.filterName : '';
  }

  private _filterMonth(name: string): CommonFilter[] {
    const filterValue = name.toLowerCase();

    return this.monthList.filter(option => option.filterName.toLowerCase().includes(filterValue));
  }

  checkMonthChange(e: any, item: CommonFilter) {
    if (item.filterID == "all") {
      this.monthList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.monthList.forEach(x => {
        if (item.filterID == x.filterID) {
          x.selected = e.checked;
        }
      });
    }
    this.setMonthList();
  }

  setMonthList() {
    this.selectedMonthList = this.monthList.filter(u => u.selected == true);
    this.selectedMonthCountText = "";
    if (this.monthList.length > 0) {
      if (this.selectedMonthList.length == this.monthList.length) {
        this.selectedMonthCountText = ": All";
      }
      else {
        if (this.selectedMonthList.length > 0)
          this.selectedMonthCountText = ": " + this.selectedMonthList.length.toString();
      }
    }
  }

  clickDoneMonth() {
    this.showMonthList = false;
    this.clickSubmit();
  }

  clickResetMonth() {
    this.monthList.forEach(x => {
      x.selected = false;
    });
    this.f.monthsearch.setValue("");
    this.f.selectedyearsearch.setValue(this.currentYear);
    this.setMonthList();
    this.clickSubmit();
  }
  //month end

    
  hideAllFilters(){
    this.showClientList = false;

    this.showRegistrationList = false;
    this.showTripCodeList = false;
    this.showMonthList = false;
  }
  clickReset(){
 
    this.clientList.forEach(x => {
      x.selected = false;
    });
    this.f.clientsearch.setValue("");
    this.setClientList();

    this.tripCodeList.forEach(x => {
      x.selected = false;
    });
    this.f.tripCodesearch.setValue("");
    this.setTripCodeList();

    this.registrationList.forEach(x => {
      x.selected = false;
    });
    this.f.registrationsearch.setValue("");
    this.setRegistrationList();
    // this.clickSubmit();
    
    // this.clickResetClient();
    // this.clickResetRegistration();
    // this.clickResetCloseout();
    // this.clickResetTripCode();
    this.hideAllFilters();

    let d = new Date();
    let uyear: number = d.getUTCFullYear();
    let umonth: number = d.getUTCMonth();
    let udate: number = d.getUTCDate();
    let uhour: number = d.getUTCHours();
    let uminute: number = d.getUTCMinutes();    
    let d1: Date = new Date(uyear, umonth, udate, uhour, uminute);
    let hstartnumber: number = 168 * 60 * 60 * 1000;    // 1 week back
    let hendnumber: number = 168 * 60 * 60 * 1000;
    let dt1: Date = new Date(d1.getTime() - hstartnumber);
    let dt2: Date = new Date(d1.getTime() + hendnumber);
    this.startDate = dt1;
    this.endDate = dt2;   

    this.trip_type = 'all'; //'leadtime';  
    this.date_type = 'currentWeek'; //'leadtime';  
    this.f.date_type.setValue('currentWeek');

    this.f.isRemoveFromExternalView.setValue(true);
    this.f.isDemo.setValue(false);
    this.f.isOnHoldTrip.setValue(false);
    this.paginator.pageIndex = 0; 

    this.getData();
  }
  
  
  clickTripCloseout(e: any, item: TripModel, i: number) {
    e.stopPropagation();

    this.selectedIndex = i;
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: item.tripCodeGUID, customerGUID: item.customerGUID, aircraftGUID: item.aircraftGUID, v: this._authService.getCurrentTimeNumber(),
      customerName: item.customerName,   route: item.route// this.selectedGroundStopList.map(s=>s.icao).join('-'),
    };

    const dialogRef = this._dialog.open(TripSetupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.getTripBytId();
        // this.clickRefreshAudit();
        this.getData();
      }
      this.selectedIndex = -1;


    });
  }

  
  async downloadExcel(e: any, item: TripModel) {
    e.stopPropagation();
    this.downloading1 = true;
    item.clicked1 = true; 
    item.downloading1 = true;

    let trip: TripModel = new TripModel();
    trip.aircraftGUID=item.aircraftGUID;
    trip.customerGUID=item.customerGUID;
    trip.tripCodeGUID=item.tripCodeGUID;
    trip.includeActiveStopOnly =false;
    trip.isFromLegEdit=false;
    trip.tripCode=item.tripCode;
   
      this._tripLegService.downloadTripCSV<ResponseModel<string>>(trip).subscribe(response => {
        if (response != null && response.code == "200" && response.model != null) {
          let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
          let file = new Blob([byteArray], { type: "text/plain" });
          file.size
          var fileURL = URL.createObjectURL(file);
          var link = document.createElement('a');
          link.href = fileURL;
          link.download = item.tripCode + ".xlsx";
          link.click();
          this.downloading1 = false;
          item.clicked1 = false; 
          item.downloading1 = false;
          //else {
          //  this.msg = "No documents returned";
          //}
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
        this.downloading1 = false;
        item.clicked1 = false; 
        item.downloading1 = false;

      });
  }

  // downloadReport() {
  //   switch (this.selectedReport) {
  //     case "report1":
  //       this.downloadExcel();
  //       break;
  //     case "report2":
  //       this.downloadWordReport();
  //       break;
  //   }
  // }

  downloadWordReport(e: any, item: TripModel) {
    e.stopPropagation();

    this.downloading2 = true;
    item.clicked2 = true; 
    item.downloading2 = true;

    let request = new TripModel();
    request.tripCodeGUID = item.tripCodeGUID;
    request.customerGUID = item.customerGUID;
    request.aircraftGUID = item.aircraftGUID;
    request.groundStopTypeGUIDList = "";
    request.includeActiveStopOnly = false;
    request.isFromLegEdit = false;
    this._tripLegService.getGroundStopLegListByConditions<ResponseModel<TripLegModel[]>>(request, true).subscribe(response => {
      if (response != null && response.code == "200") {
        let legList = [];
        let existingLegList = [];
        
        if (response.model.length > 0) {
          legList = response.model;
          legList.forEach(leg => {
            if (leg.departureGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID || 
                  leg.nextArrivalGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID)
            {
              leg.hasLegStatus = true;
              leg.legStatus = "Cancelled";
            }
            else if (leg.departureGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID ||
                  leg.nextArrivalGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID)
            {
              leg.hasLegStatus = true;
              leg.legStatus = "Contingency";
            }
            else
            {
              leg.hasLegStatus = false;
              leg.legStatus = "";
            }
          });
          this.createReport(legList, item);
          
        }
        else {          
        }
        
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.downloading2 = false;
      item.clicked2 = false; 
      item.downloading2 = false;
    });
  }

  
  createReport(legList:any[], item: TripModel) {
    const filePath = window.origin + "/assets/templates/TripCloseoutSummaryTemplate.docx";
    loadFile(filePath, (error, content) => {
      if(error) throw error;
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser });
      let reportDate = datepipe.transform(new Date().toUTCString(), 'MMM-dd-yyyy HH:mm', "UTC");
      let reportID = UtilityFunctions.makeID(10).toUpperCase();
      
      doc.setData({
        clientName: item.customerName,
        reportDate: reportDate,
        reportID: reportID,
        tripCode: item.tripCode,
        tailNo: item.registration,
        route: item.route,
        minDate: item.estimatedTripStartDate == null ? 'TBA': item.estimatedTripStartDate, //1st leg dep date
        maxDate: item.estimatedTripEndDate == null ? 'TBA': item.estimatedTripEndDate, //last leg arr date
        tripSummaryLegs:legList
      });
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key];
              this.downloading2 = false;
              item.clicked2 = false; 
              item.downloading2 = false;
              return error;
            },
              {});
          }
          return value;
        }
        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          console.error("errorMessages", errorMessages);
          //self.errMsg = "There was an error creating the report.";
          // errorMessages is a humanly readable message looking like this:
          // 'The tag beginning with "foobar" is unopened'
        }
        this.downloading2 = false;
        item.clicked2 = false; 
        item.downloading2 = false;

        throw error;
      }
      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });

      saveAs(out, "Trip Summary Close out Report - " + item.tripCode + ".docx");
      this.downloading2 = false;
      item.clicked2 = false; 
      item.downloading2 = false;
    });
  }
 
  removeFromExternalViewChange(e: any){
    this.isRemoveFromExternalView = this.f.isRemoveFromExternalView.value; 
    this.isRemoveFromExternalView = e.checked;
    this.getData();
  }
  onDemoChange(e: any){
    this.isDemo = this.f.isDemo.value;
    this.isDemo = e.checked;
    this.getData();
  }


  OnHoldChange(e: any){
    this.isOnHoldTrip = e.checked;
    this.getData();
  }


  clickOpenTrip(e: any, item: TripModel) {
    e.stopPropagation();
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "tripId": item.tripCodeGUID,
    //    "aircraftId": item.aircraftGUID,
    //    "customerId": item.customerGUID,
    //    "pf": "",
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    //window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
    window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&gsId="+item.groundStopGUID+"&gstId="+item.groundStopTaskGUID+"&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');

  }

  

  openAccountingDashboardComponent(){
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container2"
    //config.disableClose = true;
    config.height = "39.5em";//"1000px";
    config.maxWidth = "64.5em";//"1000px";
    config.width = "89em";//"1000px";
    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {    v: this._authService.getCurrentTimeNumber()   };
    this._dialog.open(AccountingDashboardComponent, config);
  }

}

