export class HotelModel {
  hotelGUID: string;
  hotelChainName: string;
  hotelName: string;
  hotelChainGUID: string;
  streetAddress: string;
  city: string;
  state_Province: string;
  postalCode: string;
  countryGUID: string;
  icao: string;
  iata: string;
  distanceFromAirport: number;
  remarks: string;
  commAddressType1GUID: string;
  commAddress1: string;
  extension1: string;
  commAddressType2GUID: string;
  commAddress2: string;
  extension2: string;
  commAddressType3GUID: string;
  commAddress3: string;
  extension3: string;
  isActive: boolean;
  pageSize: number;
  pageIndex: number;
  totalRecords: number;
  modifiedBy: string;
  modifiedDate: string;
  countryName: string;
  commAddressType1: string;
  commAddressType2: string;
  commAddressType3: string;
  selected: boolean = false;
  totalAddress: string;

}
