import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { VendorModel } from '../models/vendor.model';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { VendorService } from '../services/vendor.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { PersonService } from '../services/person.service';
import { VendorPersonCommEditDialogComponent } from './vendor-person-comm-edit-dialog.component';
@Component({
  selector: 'app-vendor-person-commlist',
  templateUrl: './vendor-person-commlist.component.html',
  styleUrls: ['./vendor-person-commlist.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ]
})

export class VendorPersonCommlistComponent implements OnInit, OnChanges {
  @Input() personId: string;
  //@Input() tabName: string;
  @Input() includeDisabledRecords: boolean;
  vendor_person_commlist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  //vendorList: VendorModel[];
  //userType: string;
  showSpin: boolean = true;
  //selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  
  recordList: PersonCommsAddressModel[];
  limitTo: number = 10;

  displayedColumn: string[] = ['addressUse', 'address', 'type', 'preferred', 'remark', 'include_in_brief', 'disabled', 'action'];


  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _personService: PersonService, @Inject(DOCUMENT) private _document) {

  }

  ngOnInit() {
    

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.vendor_person_commlist_form = this._formBuilder.group({

    });

    this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
      this.includeDisabledRecords = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getResults();

  }

  get f() { return this.vendor_person_commlist_form.controls; }

  clickEdit(event: any, comm: PersonCommsAddressModel) {
    let s = "";
    let personCommsAddressGUID = "";
    if (comm == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      personCommsAddressGUID = comm.personCommsAddressGUID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "58em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    
    dialogConfig.data = { dialogTitle: s, personId: this.personId, personCommsAddressGUID: personCommsAddressGUID };
    
    const dialogRef = this._dialog.open(VendorPersonCommEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getResults();

      }
    });

  }

  getResults() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new PersonCommsAddressModel();
    request.personGUID = this.personId;
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = !this.includeDisabledRecords;  
   
    this.showSpin = false;
    this._personService.getCustomerPersonCommsAddress<ResponseModel<PersonCommsAddressModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

          }

        }
        else {
          this.recordList = [];
          this.msg = "No records found.";
          
        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    })

  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    this.getResults();

  }

  
}
