export class TaxesAndFeesModel {
  taxesAndFeesID: number;
  airportID: number;
  countryID: number;
  fuelSupplierGUID: string;
  intoPlaneAgent: string;
  lk_CurrencyID: number;
  currencyID: string;
  lk_TaxFeeDescriptionID: number;
  amount: number;
  minScale: number;
  maxScale: number;
  lk_UnitOfMeasureID: number;
  exempt91: boolean;
  exempt121: boolean;
  exempt135: boolean;
  exemptFARType: string;
  effectiveDate: string;
  expiryDate: string;
 // hasNoExpiryDate: boolean;

  autoCalculate: boolean;
  taxStandard: number;
  flatRate: boolean;
  flatRatePercent: number;
  flatFeeAmt: boolean;
  flatFeeAmount: number;
  perUnitPrice: number;
  calculateOnTotal: boolean;

  lk_FormulaID: number;
  internalNotes: string;
  clientNotes: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;

  icao: string;
  iata: string;
  countryName: string;

  expirationDate: string;
  selectedICAOList: string;
  selectedCountryGUIDList: string;
  selectedFuelSupplierList: string;
  selectedIntoPlaneAgentList: string;
  selectedTaxAndFeeDescIDList: string;
  selectedUnitOfMeasureIDList: string;


  fuelSuppliershortName: string;
  unitOfMeasure: string;
  currencyDescription: string;
  currency: string;
  taxFeeDescription: string;


  pageIndex: number;
  pageSize: number;
  totalRecords: number;

  selected: boolean;

  usd: number;
  usdConverstion: string;
  showExpiredOnly: boolean;
  exemptAll: boolean;
  isDomestic: boolean;
}
