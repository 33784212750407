import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IdentityService } from "../services/identity.service";
import { UserModel } from "../models/user.model";
import { IdentityRequestModel } from "../models/identity-request.model";
import { ResponseModel } from "../models/response.model";
import { AuthenticateService } from "../services/authenticate.service";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'personal-info',
    templateUrl: './personal-info.component.html',
    styleUrls: ['./personal-info.component.css']
})

export class PersonalInfoComponent implements OnInit {
    personal_info: UntypedFormGroup;
    user: UserModel;
    firstName: string;
    lastName: string;
    jobTitle: string
    phoneNumber: string;
    contactEmail: string;
    email: string;
    controlValueChanged: boolean = false;
    formValid: boolean = false;
    submitted: boolean = false;
    loading: boolean = false;
    userType: string;
    internalEmailEndsWithList: string[];
    showSuccessMsg: boolean = false;
    successMsg: string;
    errMsg: string = '';

    @Output() closeDialogEvent = new EventEmitter<boolean>();

    constructor(private readonly _formBuilder: UntypedFormBuilder,
        private readonly _identityService: IdentityService,
        private readonly _authService: AuthenticateService,
        private readonly _dialogRef: MatDialogRef<PersonalInfoComponent>) {

    }

    ngOnInit() {
        this.user = new UserModel();

        this.initControls();
        this.loadDefaultInfo();
    }

    initControls(): void {
        this.personal_info = this._formBuilder.group({
            first_name: [this.firstName, Validators.required],
            last_name: [this.lastName, Validators.required],
            job_title: [this.jobTitle, Validators.required],
            phone_number: [this.phoneNumber, Validators.compose([
                Validators.required,
                Validators.pattern("^[0-9- ().]*$")]
            )],
            email: [this.contactEmail, Validators.compose([
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,100}$")]
            )]
        })
    }

    loadDefaultInfo(): void {
        var request = new IdentityRequestModel();
        try {
            this._identityService.getUser<ResponseModel<UserModel>>(request).subscribe(response => {
                if (response != null && response.code == "200") {
                    this.userType = response.model.userType;
                    this.firstName = response.model.firstName;
                    this.lastName = response.model.lastName;
                    this.jobTitle = response.model.jobTitle;
                    this.contactEmail = response.model.contactEmail;
                    this.email = response.model.email;
                    this.phoneNumber = response.model.phoneNumber;
                    this.personal_info = this._formBuilder.group({
                        first_name: [this.firstName, Validators.required],
                        last_name: [this.lastName, Validators.required],
                        job_title: [this.jobTitle, Validators.required],
                        email: [this.contactEmail, Validators.compose([
                            Validators.required,
                            Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,100}$")]
                        )],
                        phone_number: [this.phoneNumber, Validators.compose([
                            Validators.required,
                            Validators.pattern("^[0-9- ().]*$")]
                        )]
                    });
                    if (this.userType.toLowerCase() == "internal") {
                        this._identityService.getInternalEmailEndsWith<ResponseModel<string[]>>().subscribe(response => {
                            if (response != null && response.code == "200") {
                                if (response.message == "") {
                                    this.internalEmailEndsWithList = response.model;
                                }
                            }
                        });
                    }
                }
                else {
                    if (response.code == "401") {
                        this._authService.signOut();
                    }
                }
            });


        }
        catch (error) {
            //console.log(error);
        }
    }

    get f() { return this.personal_info.controls; }

    onKeyup(event, controlflag: number) {
        this.errMsg = "";
        if (event.target.value != "") {
            switch (controlflag) {
                case 1:
                    this.firstName = event.target.value;
                    break;
                case 2:
                    this.lastName = event.target.value;
                    break;
                case 3:
                    this.jobTitle = event.target.value;
                    break;
                case 4:
                    this.phoneNumber = event.target.value;
                    break;
                case 5:
                    this.contactEmail = event.target.value;
                    break;
            }
        }
        else {
            switch (controlflag) {
                case 1:
                    this.firstName = event.target.value;
                    break;
                case 2:
                    this.lastName = event.target.value;
                    break;
                case 3:
                    this.jobTitle = event.target.value;
                    break;
                case 4:
                    this.phoneNumber = event.target.value;
                    break;
                case 5:
                    this.contactEmail = event.target.value;
                    break;
            }
        }
    }

    onSave(): void {
        this.user.firstName = this.f.first_name.value;
        this.user.lastName = this.f.last_name.value;
        this.user.jobTitle = this.f.job_title.value;
        this.user.phoneNumber = this.f.phone_number.value;
        this.user.contactEmail = this.f.email.value;
        this.user.email = this.email;
        this.user.isActive = true;
        this.submitted = true;
        this.loading = true;

        this._identityService.updateUser<ResponseModel<UserModel>>(this.user).subscribe(response => {
            if (response != null && response.code == "200") {
                this.showSuccessMsg = true;
                this.loading = false;
                this.successMsg = "Personal info successfully updated.";
                setTimeout(() => { this._dialogRef.close() }, 1000);
            }
            else {
                this.errMsg = "Error Updating Personal Information: " + response.message;
            }
        })
    }
}