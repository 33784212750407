<div style="background-color:#f8f8f8;overflow-y:hidden " cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div
    style="display: flex; width: 100%; justify-content: space-between; padding-left: 1em; padding-right: 1em; padding-top: 0.5em;">
    <div style="display: flex; flex-direction: column; width: 100%;">
      <h5 *ngIf="view === 'lookup_routes'">Lookup Routes</h5>
      <h5 *ngIf="view === 'permit_status'">Permit Status</h5>
      <h5 *ngIf="view === 'alternate_airports'">Alternate Airports</h5>
      <div style="display: inline-flex; width: 100%; justify-content: space-between;">
        <div>
          <div style="display: inline-flex;">Departure <h5 class="link" style="padding-left: 0.25em; color: #007bff;"
              (click)="openAirportDetails(departureICAO)">{{departureICAO}}</h5>
          </div>
          <div style="padding-left: 1em; display: inline-flex;">Arrival <h5 class="link"
              style="padding-left: 0.25em; color: #007bff;" (click)="openAirportDetails(arrivalICAO)">{{arrivalICAO}}
            </h5>
          </div>
          <app-spin style="width: 15em;" *ngIf="showSpin"></app-spin>
        </div>

        <div *ngIf="view ==='lookup_routes' || view === 'permit_status'">Total Records: {{recordCount}}</div>
      </div>
    </div>

    <div style="display: flex;">
      <button mat-icon-button mat-dialog-close><i class="material-icons" style="font-size: x-large;">close </i></button>
    </div>
  </div>

  <div style="padding-left: 1em; padding-right: 1em; padding-bottom: 1em;">
    <div style="background-color: white; padding-left: 1em;padding-right: 1em;padding-bottom: 1em; max-height: 41em; overflow-y: auto;">
      <table *ngIf="view === 'lookup_routes'" mat-table [dataSource]="filteredRecordList" style="width: 100%; margin-top: 1em;">
        <tr>
          <ng-container matColumnDef="trip_code">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
              Trip Code
            </th>
            <td mat-cell *matCellDef="let element" style="color: #007bff; text-decoration: underline;" class="link"
              (click)="openInNew(element)">{{element.tripCode}}</td>
          </ng-container>
          <ng-container matColumnDef="registration">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
              Registration
            </th>
            <td mat-cell *matCellDef="let element">{{element.registration}}</td>
          </ng-container>
          <ng-container matColumnDef="departure">
            <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 10em;">
              Departure (z)
            </th>
            <td mat-cell *matCellDef="let element" style="width: 10em; text-align: center;">
              {{element.departureDateUTCText}}</td>
          </ng-container>
          <ng-container matColumnDef="ete">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
              ETE
            </th>
            <td mat-cell *matCellDef="let element">{{element.eteMinText}}</td>
          </ng-container>
          <ng-container matColumnDef="route">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
              Route (Click to copy to clipboard)
            </th>
            <td mat-cell *matCellDef="let element" style="color: #007bff; text-decoration: underline;" class="link"
              (click)="displayTooltip()" [matTooltipDisabled]="true" #routeTooltip="matTooltip"
              matTooltip="Copied route to clipboard!" [cdkCopyToClipboard]="element.route">{{element.route}}</td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>

      <div *ngIf="view === 'permit_status'" style="margin-top: 1em;">
        <div *ngFor="let item of filteredRecordList">
          <div style="display: inline-flex;">
            <div
              style="border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right:.25em;margin-top:.15em"
              [style.background-color]="item.taskStatusBackgroundColor">
            </div>

            <div style="font-size: small;">
              {{item.serviceTypeDescription}}&nbsp;
            </div>

            <div *ngIf="item.countryName !== null" style="font-size: small; color: #007bff;">
              {{item.countryName}}&nbsp;
            </div>

            <div *ngIf="item.icao !== null && (item.serviceTypeDescription == 'Slot DEP' 
              || item.serviceTypeDescription == 'Slot ARR' 
              || item.serviceTypeDescription == 'PPR')" style="font-size: small; color: #007bff; display: inline-flex;">
              {{item.icao}}&nbsp;
              <div *ngIf="item.fpiConfirmationReference !== null">
                -&nbsp;
              </div>
            </div>
            

            <div *ngIf="item.fpiConfirmationReference !== null" style="font-size: small; color: #007bff;">
              {{item.fpiConfirmationReference}}&nbsp;
            </div>

            <div *ngIf="item.serviceDate !== null && item.serviceTime !== null"
              style="font-size: small; color: #007bff; display: inline-flex;">
              <div>{{item.serviceDate}}</div>
              <div style="margin-left: 0.5em;">{{item.serviceTime}}</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="view === 'alternate_airports'">
        <div>
          <div style="display: inline-flex; margin-top: 1em;">
            <div style="margin-top: 0.65em;">
              <label style="font-size:small; margin-top:-.1em;">Preferred</label>
              <input type="checkbox" (change)="toggleShowPreferred()" [(checked)]="showPreferred" style="margin-left: 0.5em;"/>
            </div>

            <div style="margin-top: 0.65em; margin-left: 1em;">
              <label style="font-size:small; margin-top:-.1em;">Closest 3</label>
              <input type="checkbox" (change)="toggleShowTopThree()" [(checked)]="showTopThree" style="margin-left: 0.5em;"/>
            </div>

            <div (mousedown)="$event.stopPropagation()" style="margin-left: 1em;">
              <label style="font-size:small; margin-top:-.1em; width: 10em;">Distance in NM: {{distance}}</label>
              <mat-slider thumbLabel step="50" min="50" max="1000"
                class="cdk-focused" [(ngModel)]="distance" color="primary" (change)="findNearbyAirportsByDistance(distance, showPreferred, showTopThree)" [disabled]="showSpin"></mat-slider>
            </div>
          </div>

          <div style="display: inline-flex; width: 100%; justify-content: space-between;">
            <div style="font-weight: bold;">Alternate Airports Near {{departureICAO}}</div>
            <div>Total Records: {{totalAlternateDepartureAirports}}</div>
          </div>
          
          <div style="max-height: 15em; overflow-y: auto; margin-bottom: 1em;">
            <table mat-table [dataSource]="filteredAlternateDepartureAirports" class="example-container">
              <tr>
                <ng-container matColumnDef="preferred">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Preferred
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.isPreferredAlternate? 'Yes':''}}</td>
                </ng-container>
                <ng-container matColumnDef="airport">
                  <th mat-header-cell *matHeaderCellDef width="20%">
                    Airport
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align:left !important; color:Highlight; cursor:pointer; text-decoration:underline" (click)="openAirportDetails(element.icao)">{{element.icao}} {{element.iata}} {{element.airportName}} {{element.airportLocationWithCountryNameAbbr}}</td>
                </ng-container>
                <ng-container matColumnDef="distance">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Distance
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.distanceFromAirport}}</td>
                </ng-container>
                <ng-container matColumnDef="airport_of_entry">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Airport of Entry
                  </th>
                  <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.airportOfEntry=='Yes'? 'darkgreen':'', 'font-weight': element.airportOfEntry=='Yes'? '500':''}"><div>{{element.airportOfEntry}}</div></td>
                </ng-container>
                <ng-container matColumnDef="restrictions">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Restrictions
                  </th>
                  <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.hasRestrictions? 'darkgreen':'', 'font-weight': element.hasRestrictions? '500':''}">{{element.hasRestrictions? 'Yes':'No'}}</td>
                </ng-container>
                <ng-container matColumnDef="slots">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Slots
                  </th>
                  <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.requiresSlotsBool? 'darkgreen':'', 'font-weight': element.requiresSlotsBool? '500':''}">{{element.requiresSlotsBool? 'Yes':'No'}}</td>
                </ng-container>
                <ng-container matColumnDef="ppr">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    PPR
                  </th>
                  <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.requiresPPR? 'darkgreen':'', 'font-weight': element.requiresPPR? '500':''}">{{element.requiresPPR? 'Yes':'No'}}</td>
                </ng-container>
                <ng-container matColumnDef="landing_permit">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Landing Permit
                  </th>
                  <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.requiresLandingPermit? 'darkgreen':'', 'font-weight': element.requiresLandingPermit? '500':''}">{{element.requiresLandingPermit? 'Yes':'No'}}</td>
                </ng-container>

              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>

            <!--<table mat-table [dataSource]="filteredAlternateDepartureAirports" style="width: 100%; text-align: center;">
    <tr>
      <ng-container matColumnDef="preferred">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 5em;">
          Preferred
        </th>
        <td mat-cell *matCellDef="let element" style="width: 5em;" class="link"
          (click)="openInNew(element.tripCode)">{{element.isPreferredAlternate == true ? 'Yes' : 'No'}}</td>
      </ng-container>
      <ng-container matColumnDef="airport_name">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 26em;">
          Airport Name
        </th>
        <td mat-cell *matCellDef="let element" style="width: 26em; text-align: left;">
          <div style="display: inline-flex; ">
            <div style="display: inline-flex;">
              <div class="link" style="color: #007bff; text-decoration: underline;"
                (click)="openAirportDetails(element.icao)">{{element.icao}}</div>
              <div class="link" style="color: #007bff; text-decoration: underline;"
                [ngStyle]="{'margin-left': element.icao !== undefined ? '0.5em' : '0em'}"
                (click)="openAirportDetails(element.iata)">{{element.iata}}</div>
            </div>
            <div style="margin-left: 0.5em;">{{element.airportName}},</div>
            <div style="margin-left: 0.5em;">{{element.countryName}}</div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="distance">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 11em;">
          Distance from {{departureICAO}}
        </th>
        <td mat-cell *matCellDef="let element" style="width: 11em;">{{element.distanceFromAirport}}</td>
      </ng-container>
      <ng-container matColumnDef="airport_of_entry">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">
          Airport of Entry
        </th>
        <td mat-cell *matCellDef="let element">{{element.airportOfEntry == true ? 'Yes' : 'No'}}</td>
      </ng-container>
      <ng-container matColumnDef="slots">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">
          Slots
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.requiresSlotsBool == true ? 'Yes' : 'No'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="ppr">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">
          PPR
        </th>
        <td mat-cell *matCellDef="let element">{{element.requiresPPR == true ? 'Yes' : 'No'}}</td>
      </ng-container>
      <ng-container matColumnDef="landing_permits">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">
          Landing Permits
        </th>
        <td mat-cell *matCellDef="let element">{{element.requiresLandingPermit == true ? 'Yes' : 'No'}}</td>
      </ng-container>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
  </table>-->
          </div>
        </div>

        <div>
          <div style="display: inline-flex; width: 100%; justify-content: space-between;">
            <div style="font-weight: bold;">Alternate Airports Near {{arrivalICAO}}</div>
            <div>Total Records: {{totalAlternateArrivalAirports}}</div>
          </div>

          <div style="max-height: 15em; overflow-y: auto; margin-bottom: 1em;">
            <table mat-table [dataSource]="filteredAlternateArrivalAirports" class="example-container">
              <tr>
                <ng-container matColumnDef="preferred">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Preferred
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.isPreferredAlternate? 'Yes':''}}</td>
                </ng-container>
                <ng-container matColumnDef="airport">
                  <th mat-header-cell *matHeaderCellDef width="20%">
                    Airport
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align:left !important; color:Highlight; cursor:pointer; text-decoration:underline" (click)="openAirportDetails(element.icao)">{{element.icao}} {{element.iata}} {{element.airportName}} {{element.airportLocationWithCountryNameAbbr}}</td>
                </ng-container>
                <ng-container matColumnDef="distance">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Distance
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.distanceFromAirport}}</td>
                </ng-container>
                <ng-container matColumnDef="airport_of_entry">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Airport of Entry
                  </th>
                  <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.airportOfEntry=='Yes'? 'darkgreen':'', 'font-weight': element.airportOfEntry=='Yes'? '500':''}"><div>{{element.airportOfEntry}}</div></td>
                </ng-container>
                <ng-container matColumnDef="restrictions">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Restrictions
                  </th>
                  <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.hasRestrictions? 'darkgreen':'', 'font-weight': element.hasRestrictions? '500':''}">{{element.hasRestrictions? 'Yes':'No'}}</td>
                </ng-container>
                <ng-container matColumnDef="slots">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Slots
                  </th>
                  <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.requiresSlotsBool? 'darkgreen':'', 'font-weight': element.requiresSlotsBool? '500':''}">{{element.requiresSlotsBool? 'Yes':'No'}}</td>
                </ng-container>
                <ng-container matColumnDef="ppr">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    PPR
                  </th>
                  <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.requiresPPR? 'darkgreen':'', 'font-weight': element.requiresPPR? '500':''}">{{element.requiresPPR? 'Yes':'No'}}</td>
                </ng-container>
                <ng-container matColumnDef="landing_permit">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    Landing Permit
                  </th>
                  <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.requiresLandingPermit? 'darkgreen':'', 'font-weight': element.requiresLandingPermit? '500':''}">{{element.requiresLandingPermit? 'Yes':'No'}}</td>
                </ng-container>

              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>

            <!--<table mat-table [dataSource]="filteredAlternateArrivalAirports" style="width: 100%; text-align: center;">
    <tr>
      <ng-container matColumnDef="preferred" >
        <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 5em;">
          Preferred
        </th>
        <td mat-cell *matCellDef="let element" style="width: 5em;" class="link"
          (click)="openInNew(element.tripCode)">{{element.isPreferredAlternate == true ? 'Yes' : 'No'}}</td>
      </ng-container>
      <ng-container matColumnDef="airport_name">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 26em;">
          Airport Name
        </th>
        <td mat-cell *matCellDef="let element" style="width: 26em; text-align: left;">
          <div style="display: inline-flex; text-align: left;">
            <div style="display: inline-flex;">
              <div class="link" style="color: #007bff; text-decoration: underline; "
                (click)="openAirportDetails(element.icao)">{{element.icao}}</div>
              <div class="link" style="color: #007bff; text-decoration: underline; "
                [ngStyle]="{'margin-left': element.icao !== undefined ? '0.5em' : '0em'}"
                (click)="openAirportDetails(element.iata)">{{element.iata}}</div>
            </div>
            <div style="margin-left: 0.5em;">{{element.airportName}},</div>
            <div style="margin-left: 0.5em;">{{element.countryName}}</div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="distance">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 11em;">
          Distance from {{arrivalICAO}}
        </th>
        <td mat-cell *matCellDef="let element" style="width: 11em;">{{element.distanceFromAirport}}</td>
      </ng-container>
      <ng-container matColumnDef="airport_of_entry">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">
          Airport of Entry
        </th>
        <td mat-cell *matCellDef="let element">{{element.airportOfEntry == true ? 'Yes' : 'No'}}</td>
      </ng-container>
      <ng-container matColumnDef="slots">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">
          Slots
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.requiresSlotsBool == true ? 'Yes' : 'No'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="ppr">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">
          PPR
        </th>
        <td mat-cell *matCellDef="let element">{{element.requiresPPR == true ? 'Yes' : 'No'}}</td>
      </ng-container>
      <ng-container matColumnDef="landing_permits">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">
          Landing Permits
        </th>
        <td mat-cell *matCellDef="let element">{{element.requiresLandingPermit == true ? 'Yes' : 'No'}}</td>
      </ng-container>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
  </table>-->
          </div>
          
        </div>
      </div>
    </div>

    <div *ngIf="view === 'permit_status'" style="display: inline-flex; margin-top: 0.5em;">
      <button mat-button class="btn-high" [cdkCopyToClipboard]="copyPermitData()">Copy</button>
      <button mat-button class="btn-low" mat-dialog-close
        style="background-color:grey; color: white; margin-left: 0.5em;">
        <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
          <div>Close</div>
        </div>
      </button>
    </div>
  </div>
</div>
