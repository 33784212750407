import { Component } from '@angular/core';

@Component({
    selector: 'app-message-bi-dashboard',
  templateUrl: './message-bi-dashboard.component.html',
  styleUrls: ['./message-bi-dashboard.component.css']
})
/** airport-bi-dashboard component*/
export class MessageBiDashboardComponent {
  
    constructor() {

    }
}
