<div *ngIf="grantAccessToManageAccountGrouping" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
  style="margin-top:0em; padding-top:0em; display:flex">
  <div class="divLeft">

    <div style="display: flex; width: 100%; justify-content: space-between;">
      <div style="display: inline-flex;">
        <i class="material-symbols-outlined" style="margin-top: 0.3em;">
          inventory
        </i>
        <div style="margin-left: 0.5em; margin-bottom: 0.5em;">
          <h3 style="margin-bottom: 0em;">Manage Account Grouping</h3>
          <h5 style="font-weight: 100; font-size: small;">Group Client account and define Sync up data</h5>
        </div>
      </div>
      <div style="display: flex;">
        <button mat-icon-button *ngIf="isDialog" (click)="openClientProfileSetupComponent()"> <i
            class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i> </button>
        <button mat-icon-button *ngIf="isDialog" (click)="clickRouteNew('account-groups/account-grouplist','')"> <i
            class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>
        <button mat-icon-button *ngIf="isDialog" mat-dialog-close><i class="material-icons"
            style="font-size: x-large;">close </i></button>
      </div>

    </div>


    <!-- <div style="margin-left: 0.3125em;margin-top:0em; font-size: small; display: flex">
        <div style="margin-right:0.25em"><input type="checkbox" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)" /></div><div style="margin-top:-0.25em">Include disabled records</div>
      </div> -->
    <form *ngIf="account_grouplist_form" [formGroup]="account_grouplist_form" (ngSubmit)="onSubmit()" style="background-color: white; padding: 1em;">
      <div style="margin-left: 0.3125em;margin-top:0em; font-size: small; display: flex">
        <div style="margin-right:0.25em"><input type="checkbox" formControlName="showDisabledRecords"
            (change)="showDisabledRecordsOnChange($event)" /></div>
        <div style="margin-top:-0.25em">Show disabled records</div>
      </div>
      <div style="height: 25em; overflow-y: auto; overflow-x: hidden !important;">
        <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container mat-elevation-z8">
          <tr>
            <ng-container matColumnDef="group_name">
              <th mat-header-cell *matHeaderCellDef
                style="width:25%; text-align: left !important; padding-left: 0.5em !important;">
                Client Group Name
              </th>
              <td mat-cell *matCellDef="let element"
                style="text-align: left !important; padding-left: 0.5em !important;">
                <a *ngIf="element.tooltip!=''" style="cursor:pointer" matTooltipClass="tooltipLineBreak">{{element.customerGroupName}}</a>
                <span
                  *ngIf="element.tooltip==''">{{element.customerGroupName}}</span><i
                  *ngIf="element.internalNotes != null && element.internalNotes != ''" class="material-icons"
                  matTooltipClass="tooltipLineBreak" matTooltip="{{element.internalNotes}}"
                  style="font-size: larger;cursor: pointer; margin-left: 0.3em; margin-top: 0.1em; ">notes</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="linked_account _count">
              <th mat-header-cell *matHeaderCellDef style="width:3%">
                <div>Account</div>
                <div style="margin-top:-0.25em">Linked</div>
                <div style="margin-top:-0.25em">Count</div>
              </th>
              <td mat-cell *matCellDef="let element"><a *ngIf="element.tooltip!=''" style="cursor:pointer"
                  matTooltipClass="tooltipLineBreak" matTooltip="{{element.tooltip}}">{{element.customerCount}}</a><span
                  *ngIf="element.tooltip==''">{{element.customerCount}}</span></td>
            </ng-container>
            <ng-container matColumnDef="create_date">
              <th mat-header-cell *matHeaderCellDef style="width:6%">
                Created Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.accountCreationDate}}</td>
            </ng-container>
            <!-- <ng-container matColumnDef="remark">
              <th mat-header-cell *matHeaderCellDef style="width:25%; text-align: left !important">
                Remark
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important"><a *ngIf="element.internalNotes.length>30" style="cursor:pointer" matTooltipClass="tooltipLineBreak" matTooltip="{{element.internalNotes}}">{{ element.internalNotes.substring(0,29)+'...' }}</a><span *ngIf="element.internalNotes.length<=30">{{element.internalNotes}}</span></td>
            </ng-container> -->
            <!-- <ng-container matColumnDef="disabled">
              <th mat-header-cell *matHeaderCellDef style="width:4%">
                Disabled
              </th>
              <td mat-cell *matCellDef="let element" >{{element.isActive? '&nbsp;': 'Yes'}}</td>
            </ng-container> -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width:5%">
                <div>Action</div>
                <div style="margin-top:0em;padding-bottom:0.25em"><a mat-button class="btn-high grid"
                    style="align-self:center" (click)="clickEdit($event, null);">Add</a></div>
              </th>
              <td mat-cell *matCellDef="let element">
                <a mat-button class="btn-low grid" style="align-self:center" (click)="clickEdit($event, element);">
                  Edit
                </a>

              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
    </form>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>