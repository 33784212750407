import { PermitManifestModel } from "./permit-manifest.model";

export class PermitsModel {
  serviceTypeDescription: string;
  departureICAO: string;
  departureAirportName: string;
  arrivalICAO: string;
  arrivalAirportName: string;
  alternateICAO: string;
  alternateAirportName: string;
  confirmationReference: string;
  taskNote: string;
  countryName: string;
  aircraftOperator: string;
  operatorPhone: string;
  operatorEmail: string;
  operatorAddress: string;
  managerName: string;
  managerPhone: string;
  aircraftType: string;
  callSign: string;
  registration: string;
  mtow: string;
  flightDate: string;
  departureCity: string;
  departureDate: string;
  departureTime: string;
  arrivalCity: string;
  arrivalDate: string;
  arrivalTime: string;
  route: string;
  groundStopGUID: string;
  tripCodeGUID: string;
  crewManifest: PermitManifestModel[];
  paxManifest: PermitManifestModel[];
  eltBeaconCode: string;
  ete: string;
  farTypeID: number;
  aircraftCountry: string;
  paxCount: number;
  paxNationalityList: string;
  pilotName: string;
  pilotLicense: string;
  dow: number;
  priorDepartureICAO: string;
  priorDepartureCity: string;
  priorDepartureDate: string;
  priorDepartureTime: string;
  nextArrivalICAO: string;
  nextArrivalCity: string;
  nextArrivalDate: string;
  nextArrivalTime: string;
  handlerName: string;
}
