import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthenticateService } from '../services/authenticate.service'
import { ResponseModel } from '../models/response.model';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service';
import { IdentityRequestModel } from '../models/identity-request.model';
import { Observable } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PersonalInfoComponent } from './personal-info.component';
import { ChangePasswordComponent } from './change-password.component';
import { PasswordRecoveryComponent } from './password-recovery.component';
import { MultiFactorAuthComponent } from './multi-factor-auth.component';
import { SignatureEditorComponent } from './signature-editor.component';
import { PushNotificationComponent } from './push-notification.component';

@Component({
  selector: 'app-update-my-account',
  templateUrl: './update-my-account.component.html',
  styleUrls: ['./update-my-account.component.css']
})

export class UpdateMyAccountComponent implements OnInit {
  update_my_account_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  user: UserModel;
  errMsg: string;
  msg: string;
  username: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  //inactivateUser: boolean;
  isReadonly: boolean;
  userType: string;
  internalEmailEndsWithList: string[];
  resetPassword: boolean = false;
  mfaEnabled: boolean = false;

  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService,
    private readonly _authService: AuthenticateService, private readonly dialog: MatDialog) {

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.internalEmailEndsWithList = [];
    //this.username = localStorage.getItem('username');
    
    //this.isReadonly = false;
    //if (localStorage.getItem('userType') == 'INTERNAL') {
    //  this.isReadonly = true;
    //}
    this.loadDefaultUserInfo();
  }

  loadDefaultUserInfo() {
    var request = new IdentityRequestModel();
    //request.username = this.username;
    try {
      this._identityService.getUser<ResponseModel<UserModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          this.username = response.model.username;
          this.userType = response.model.userType;
          this.firstName = response.model.firstName;
          this.lastName = response.model.lastName;
          this.email = response.model.email;
          this.phoneNumber = response.model.phoneNumber;
          //this.inactivateUser = !response.model.isActive;
          this.update_my_account_form = this._formBuilder.group({
            //username: [this.username, Validators.required],
            //username: [this.username, Validators.compose([
            //  Validators.required,
            //  Validators.minLength(8),
            //  Validators.maxLength(50),
            //  CustomValidators.cannotContainSpace
            //]
            //)],
            first_name: [this.firstName, Validators.required],
            last_name: [this.lastName, Validators.required],
            email: [this.email, Validators.compose([
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,100}$")]
            )],
            confirm_email: [this.email, Validators.required],
            phone_number: [this.phoneNumber, Validators.compose([
              Validators.required,
              Validators.pattern("^[0-9- ().]*$")]
            )],
            mfa_enabled: [this.mfaEnabled]

            //inactivate_user: this.inactivateUser
          });
          if (this.userType.toLowerCase() == "internal") {
            this._identityService.getInternalEmailEndsWith<ResponseModel<string[]>>().subscribe(response1 => {
              if (response1 != null && response1.code == "200") {
                if (response1.message == "") {
                  this.internalEmailEndsWithList = response1.model;
                }
              }
            });
          }

        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      });
    }
    catch (error) {
      //console.log(error);
    }
  }

  get f() { return this.update_my_account_form.controls; }

  onSubmit() {
    this.errMsg = "";
    this.submitted = true;
    if (this.f.first_name.value.trim() == "") {
      this.f.first_name.setErrors({ required: true });
      //return;
    }
    if (this.f.last_name.value.trim() == "") {
      this.f.last_name.setErrors({ required: true });
      //return;
    }
    if (this.f.email.value.trim() == "") {
      this.f.email.setErrors({ required: true });
      //return;
    }

    this.checkEmail().subscribe(res => {
      if ((res != null || res != undefined) && res == true) {
        this.errMsg = "The email address has already been used in the company";
        return;
      }

      this.formValidation();

    });

  }

  //onSubmit() {
  formValidation() {
    this.errMsg = "";
    this.submitted = true;
    //if (this.userType.toLowerCase() == 'internal') {
    //  if (this.f.username.value.toLowerCase().indexOf('@flightprointl.com') == -1 || this.f.email.value.toLowerCase().indexOf('@flightprointl.com') == -1) {
    //    this.errMsg += "Username and email for internal employee must be company email address.";
    //  }
    //}
    let f1 = true;
    if (this.f.email.value != '') {
      f1 = CustomValidators.internalEmailValidator(this.internalEmailEndsWithList, this.f.email.value, this.userType.toLocaleLowerCase());
      if (f1 == false) {
        this.f.email.setErrors({ internalEmailValidator: !f1 });
      }
    }
    //else {
    //  this.f.email.setErrors({ required: true });
    //}
    //let f2 = true;
    //if (this.f.username.value != '') {
    //  f2 = CustomValidators.internalEmailValidator(this.internalEmailEndsWithList, this.f.username.value, this.userType.toLocaleLowerCase());
    //  if (f2 == false) {
    //    this.f.username.setErrors({ internalEmailValidator: !f2 });
    //  }
    //}
    //else {
    //  this.f.username.setErrors({ required: true });
    //}

    let p = true;
    if (this.f.email.value != "" && this.f.email.value != null && this.f.confirm_email.value != "" && this.f.confirm_email.value != null) {
      let p = CustomValidators.textMatchValidator(this.f.email.value, this.f.confirm_email.value);
      if (this.update_my_account_form.invalid || p == false) {
        if (p == false) {
          this.f.confirm_email.setErrors({ textMatchValidator: !p });
        }
        //else {
        //  this.f.confirm_email.setErrors(null);
        //}
      }
    }

    //this.checkUsername(this.f.username.value).subscribe(res => {
    //  if ((res != null || res != undefined) && res == true) {
    //    this.f.username.setErrors({ checkExistingUsername: true });
    //  }
    //});

    if (this.update_my_account_form.invalid || this.errMsg != "") {
      return;
    }

    this.loading = true;
    this._authService.updateAccessTime();
    this.user = new UserModel();
    //this.user.username = this.f.username.value;
    this.user.firstName = this.f.first_name.value.trim();
    this.user.lastName = this.f.last_name.value.trim();
    this.user.email = this.f.email.value.trim();
    this.user.phoneNumber = this.f.phone_number.value.trim();
    this.user.isActive = true;// !this.f.inactivate_user.value;
    this.user.isNewUser = false;
    this.user.priorEmail = this.email;
    if (this.f.email.value.trim().toLowerCase() != this.email.toLowerCase()) 
      this.resetPassword = true;     
    else
      this.resetPassword = false;
    
    try {
      this._identityService.updateUser<ResponseModel<boolean>>(this.user).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model == true) {
            if (this.resetPassword) {
              let request = new IdentityRequestModel();
              request.username = this.username;
              this._identityService.resetPassword<ResponseModel<boolean>>(request).subscribe(response => {
                if (response != null && response.code == "200") {
                  if (response.model == true) {
                    this._authService.signOut();

                  }
                  else {
                    this.errMsg = response.message;
                  }
                }
                else {
                  if (response.code == "401") {
                    this.errMsg = response.message
                  }
                  else {
                    this.errMsg = "Failed to reset password. Please try again later.";
                  }
                }
                this.loading = false;
              });
              
            }
            else
              this.msg = "Your profile was updated successfully.";
          }
          else {
            if (response.message != "") {
              this.errMsg = response.message;
            }
            else {
              this.errMsg = "Failed to update your profile at this time. Please try later.";
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to update your profile at this time. Please try later.";
          }
        }
      });
    }
    catch (error) {
      //console.log(error);
    }
    this.loading = false;
    
  }

  checkEmail(): Observable<any> {
    return new Observable<any>(ob => {
      this.user = new UserModel();
      this.user.email = this.f.email.value;
      this.user.isNewUser = false;
      this.user.customerGuid = null;
      this.user.vendorGuid = null;      
      try {
        this._identityService.checkEmail<ResponseModel<number>>(this.user).subscribe(response => {
          if (response != null && response.code == "200") {
            if (Number(response.model) > 0) {
              //this.errMsg = '"' + this.f.username.value + '" has been used in our system. Please enter a different username.';                  
              ob.next(true);
            }
            else {
              ob.next(false);
            }
          }
          else {
            ob.next(null);
          }
        });
      }
      catch (error) {
        //console.log(error);
        ob.next(null);
      }
    });

  }

  checkUsername(username: string): Observable<any> {

    return new Observable<any>(ob => {
      this.user = new UserModel();
      this.user.isNewUser = false;
      this.user.username = username;
      try {
        this._identityService.checkUsername<ResponseModel<UserModel>>(this.user).subscribe(response => {
          if (response != null && response.code == "200") {
            if (Number(response.model) > 0) {
              //this.errMsg = '"' + this.f.username.value + '" has been used in our system. Please enter a different username.';                  
              ob.next(true);
            }
            else {
              ob.next(false);
            }
          }
          else {
            ob.next(null);
          }
        });
      }
      catch (error) {
        //console.log(error);
        ob.next(null);
      }
    });

  }

  usernameOnchange(e: any) {
    //console.log(e.target.value);  
    this.checkUsername(e.target.value).subscribe(res => {
      if ((res != null || res != undefined) && res == true) {
        //debugger;      
        this.f.username.setErrors({ checkExistingUsername: true });
          
        //debugger;
      }
      else {
        this.f.username.setErrors({ checkExistingUsername: false });
      }
    });
  }

  emailOnchange(e: any) {
    if (e.target.value.trim().toLowerCase() != this.email.toLowerCase()) 
      this.resetPassword = true;
    else
      this.resetPassword = false;
    
  }

  clickOpenInNew( ){
    window.open('updatemyaccount/updatemyaccount' + "?v=" + this._authService.getCurrentTimeNumber());
  }

  openPersonalInfo() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    //config.height = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() ,  username : this.username,};
    this.dialog.open(PersonalInfoComponent, config);
  }

  openChangePassword() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    //config.height = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() ,  username : this.username,};
    this.dialog.open(ChangePasswordComponent, config);
  }

  openPasswordRecovery() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    //config.height = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() ,  username : this.username,};
    this.dialog.open(PasswordRecoveryComponent, config);
  }

  openMFA() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    //config.height = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() ,  username : this.username,};
    this.dialog.open(MultiFactorAuthComponent, config);
  }

  openPushNotifications() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    config.width = "35em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() ,  username : this.username,};
    this.dialog.open(PushNotificationComponent, config);
  }

  openSignature() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    //config.height = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() ,  username : this.username,};
    this.dialog.open(SignatureEditorComponent, config);
  }
}
