import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig,  MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { GroundStopCreatorCityPairDialogComponent } from '../ground-stops/ground-stop-creator-citypair-dialog.component';
import { DialogService } from '../services/dialog.service';
import { TaskServiceDialogComponent } from './task-service-dialog.component';
import { MessageModel } from '../models/message.model';
import { MessageService } from '../services/message.service';
import { MessageLinkModel } from '../models/message-link';


@Component({
  selector: 'app-link-message-trip-detail-viewlist',
  templateUrl: './link-message-trip-detail-viewlist.component.html',
  styleUrls: ['./link-message-trip-detail-viewlist.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})

export class LinkMessageTripDetailViewlistComponent implements OnInit, OnChanges {

  @Input() request: TripModel;
  @Input() reloadChildPage: boolean;
  @Input() selectedTabName: string;
  @Input() v: number;
  @Input() messageGUID: string;
  @Input() messageGUIDList: string;
  @Input() customerGUID: string;
  @Input() messageLinkList: MessageLinkModel[];
  @Input() linkedTripCodedGUID: string;
  @Output() sendDataToParent = new EventEmitter<any>();
 
  link_message_trip_detail_viewlist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = false;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  selectedClient: string = "";
  recordList: TripModel[];
  displayedColumn: string[] = ['tripcode', 'registration', 'dateRange', 'route', 'linkToTrip','action' ];
  scrHeight: number;
  upPII: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _dialogService: DialogService,
    private readonly _messageService: MessageService, private readonly _dialogRef: MatDialogRef<LinkMessageTripDetailViewlistComponent>
  ) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.reloadChildPage || this.selectedTabName != 'tripcode_detail') {
      return;
    }

    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }

    this.link_message_trip_detail_viewlist_form = this._formBuilder.group({

    });

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }


    this.showSpin = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }
    this.request.pageSize = 0;// this.pageSize;
    this.request.pageIndex = this.currentPageIndex;
    this.request.totalRecords = this.totalRecords;
    this.initControls();
    this.getSearchResults();

  }


  initControls() {
    this.link_message_trip_detail_viewlist_form = this._formBuilder.group({

    });
  }

  get f() { return this.link_message_trip_detail_viewlist_form.controls; }

  getSearchResults() {
    this.showSpin = true;
    this.totalRecordsText = "";
    this.request.sortBy = "departureDate desc";
    this.request.pageIndex = -1;
    this.request.pageSize = -1;
    this._groundStopService.getTripListForTripCodeViewInternalByConditions<ResponseModel<TripModel[]>>(this.request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          
          if(this.linkedTripCodedGUID)
            this.recordList = this.recordList.filter(t=>t.tripCodeGUID == this.linkedTripCodedGUID);
          if (this.recordList.length > 0 && this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
          }

        }
        else {
          this.totalRecordsText = "No record found";

        }
        this.showSpin = false;
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  pageIndexChange(e: any) {
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
      this.request.pageSize = this.pageSize;
      this.request.totalRecords = this.totalRecords;
    }
    this.request.pageIndex = this.currentPageIndex;
    this.getSearchResults();

  }


  openTasks(event: any, p: TripModel) {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "61em";//"1000px";
    dialogConfig.height = "42em";//"500px";
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { dialogTitle: s, customerGUID: p.customerGUID, tripCodeGUID: p.tripCodeGUID, aircraftGUID: p.aircraftGUID, 
      groundStopTypeGUIDList: this.request.groundStopTypeGUIDList, v: this._authService.getCurrentTimeNumber(),
      messageGUID:this.messageGUID, messageGUIDList: this.messageGUIDList, messageLinkList: this.messageLinkList };
    const dialogRef = this._dialog.open(TaskServiceDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._dialogRef.close(result);
      }

    });

  }

  linkMessageToTrip(element:TripModel){
    var messages: MessageModel[] = [];

    let msgGuids;
    if (this.messageGUIDList) {
      msgGuids = this.messageGUIDList.split(',');
      msgGuids.forEach(msgGuid => {
        let message = new MessageModel();
        message.messageGUID = msgGuid;
        message.customerGUID = this.customerGUID;
        message.tripCodeGUID = element.tripCodeGUID;

        messages.push(message);
      });
    }
    else{
      let message = new MessageModel();
        message.messageGUID = this.messageGUID;
        message.customerGUID = this.customerGUID;
        message.tripCodeGUID = element.tripCodeGUID;

        messages.push(message);

        this.messageLinkList?.forEach(ml=>{
        
          let message = new MessageModel();
          message.messageGUID = this.messageGUID;
          message.customerGUID = this.customerGUID;
          message.tripCodeGUID = element.tripCodeGUID;
          message.groundStopGUID = ml.groundStopGUID;
          message.groundStopTaskGUID = ml.groundStopTaskGUID;
          message.messageLinkGUID = ml.messageLinkGUID
          messages.push(message);
        
      });
    }
    
      this._messageService.insertMessageLink<ResponseModel<MessageModel>>(messages).subscribe(response => {
        if (response.code == "200") {
          if (this.messageGUIDList)
          msgGuids.forEach(msgGuid => {
            this._messageService.assignAndMovetoInbox(msgGuid);
          });
        else
          this._messageService.assignAndMovetoInbox(this.messageGUID);
          this.sendDataToParent.emit({ modified: true, showSuccessMsg: true });
        }
      });
  }



}
