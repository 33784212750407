import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CommAddressTypeModel } from '../models/comm-address-type.model';
import { AddressRatingModel } from '../models/address-rating.model';
import { AddressUseModel } from '../models/address-use.model';
import { VendorService } from '../services/vendor.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-vendor-comm-edit-dialog',
  templateUrl: './vendor-comm-edit-dialog.component.html',
  styleUrls: ['./vendor-comm-edit-dialog.component.css']
})

export class VendorCommEditDialogComponent implements OnInit, AfterViewInit {
  @Input() dialogTitle: string;
  @Input() personCommsAddressGUID: string;
  @Input() vendorGUID: string;
  vendor_comm_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  addressRatingList: AddressRatingModel[];
  commAddressTypeList: CommAddressTypeModel[];
  addressUseList: AddressUseModel[];
  avaiAddressUseList: AddressUseModel[];
  selAddressUseList: AddressUseModel[];
  selectedCommAddressType: string;
  selectedCommAddressTypeId: string
  selectedAddressRatingId: string;
  address: string;
  extension: string;
  remarks: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  displayedColumn: string[] = ['addressUse'];
  personId: string;
  isModified: boolean = false;
  isPreferred: boolean;
  addressLabel: string = "Phone";
  showSuccessMsg: boolean = false;
  selection = new SelectionModel<AddressUseModel>(true, []);

  constructor(private readonly _dialogRef: MatDialogRef<VendorCommEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _vendorService: VendorService,
    private readonly _dialogService: DialogService
    ) {
    this.personCommsAddressGUID = _data.personCommsAddressGUID;
    if (_data.vendorGUID == null) {
      this.vendorGUID = "";
    }
    else {
      this.vendorGUID = _data.vendorGUID;
    }
    this.dialogTitle = _data.dialogTitle;

  }

  getAllData(): Observable<any[]> {
    let getCommAddressTypeResponse = this._commonService.getCommAddressTypeList<ResponseModel<CommAddressTypeModel[]>>();
    let getAddressRatingResponse = this._commonService.getAddressRatingList<ResponseModel<AddressRatingModel[]>>();
    let getAddressUseResponse = this._commonService.getCommAddressUseList<ResponseModel<AddressUseModel[]>>(this.personCommsAddressGUID);
    //let getPersonIdResponse = this._vendorService.getPersonId<ResponseModel<string>>(this.vendorGUID);
    let getPersonIdResponse = this._vendorService.getVendorPersonId<ResponseModel<string>>(this.vendorGUID);
    if (this.personCommsAddressGUID != null && this.personCommsAddressGUID != "") {
      let getPersonCommsResponse = this._commonService.getPersonCommsAddressBypcaId(this.personCommsAddressGUID);
      return forkJoin([getCommAddressTypeResponse, getAddressRatingResponse, getAddressUseResponse, getPersonIdResponse, getPersonCommsResponse]);
    }
    else
      return forkJoin([getCommAddressTypeResponse, getAddressRatingResponse, getAddressUseResponse, getPersonIdResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.avaiAddressUseList = [];
    this.selAddressUseList = [];
    this.selectedCommAddressType = "";
    this.selectedCommAddressTypeId = "";
    this.selectedAddressRatingId="";
    this.address="";
    this.extension="";
    this.remarks="";
    this.modifiedBy="";
    this.modifiedDate="";
    this.isActive = 1;
    this.showSuccessMsg = false;
   this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.commAddressTypeList = responses[0].model;
          this.selectedCommAddressTypeId = this.commAddressTypeList[0].commAddressTypeGUID;
          this.selectedCommAddressType = this.commAddressTypeList[0].commAddressTypeDesc;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
       
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.addressRatingList = responses[1].model;
          this.selectedAddressRatingId = this.addressRatingList[0].addressRatingGUID;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.addressUseList = responses[2].model;
          this.addressUseList = this.addressUseList.filter(v => v.isVendor == true);
          //this.setAddressUseList();
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.personId = responses[3].model;
          //this.setAddressUseList();
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.personCommsAddressGUID != null && this.personCommsAddressGUID != "") {
        if (responses[4] != null) {
          if (responses[4].code == "200" && responses[4].message == "") {
            let personComm = new PersonCommsAddressModel();
            personComm = responses[4].model;
            this.selectedCommAddressTypeId = personComm.commAddressTypeGUID;
            this.selectedCommAddressType = personComm.commAddressType;
            switch (this.selectedCommAddressType) {
              case "Office Phone":
              case "Home Phone":
              case "Mobile Phone":
              case "Fax":
                this.addressLabel = "Phone";
                break;
              default:
                this.addressLabel = "Address";
                break;
            }
            this.selectedAddressRatingId = personComm.addressRatingGUID;
            this.address = personComm.address;
            this.extension = personComm.extension;
            this.remarks = personComm.commRemarks;
            this.modifiedBy = personComm.modifiedBy;
            this.modifiedDate = personComm.modifiedDate;
            if (personComm.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
            this.isPreferred = personComm.isPreferred;
            this.addressUseList.forEach(v => {
              v.selected == true ? this.selection.toggle(v) : null;
            });
          }
          else {
            if (responses[4].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }
       
      this.initControls();
      
      this.showSpin = false;


    });

  }

  ngAfterViewInit() {
    
  }

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.addressUseList != null && this.addressUseList.every(t => t.selected);
  }

  someComplete(): boolean {
    if (this.addressUseList == null) {
      return false;
    }
    return this.addressUseList.filter(t => t.selected).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.addressUseList == null) {
      return;
    }
    this.addressUseList.forEach(t => {
      t.selected = completed;
      if (completed)
        this.selection.select(t);
      else
        this.selection.deselect(t);
    });

  }


  checkAddress(e: any, item: AddressUseModel) {
    this._authService.updateAccessTime();
    e ? this.selection.toggle(item) : null;
    item.selected = e.checked;
    this.updateAllComplete();
  }


  initControls() {
    this.vendor_comm_edit_form = this._formBuilder.group({
      comm_address_type_select: this.selectedCommAddressTypeId, //this.commAddressTypeList[0].commAddressTypeGUID,
     // address_rating_select: this.selectedAddressRatingId, //[this.addressRatingList[0].addressRatingGUID],
      comm_address_status: this.isActive,
      address: [this.address, Validators.required],
      extension: [this.extension, Validators.pattern("^[0-9- ().]*$")],
      remarks: [this.remarks],
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate]

    });
  }

  get f() { return this.vendor_comm_edit_form.controls; }

  addressChange() {
    this.errMsg = "";
    let typevalue = this.f.comm_address_type_select.value; //;this.vendor_comm_edit_form.get("comm_address_type_select").value;
    let type = this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.f.comm_address_type_select.value)[0].commAddressTypeDesc; //this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.vendor_comm_edit_form.get("comm_address_type_select").value)[0].commAddressTypeDesc;
    this.validateCommAddress(this.vendor_comm_edit_form.get("address").value, type);
  }

  validateCommAddress(address: string, type: string) {
    switch (type.toLowerCase()) {
      case 'aftn':         
      case 'arinc':     
      case 'sita':
        this.f.address.setValue(this.f.address.value.toUpperCase());
        break;
    }
    let r = CustomValidators.validateCommonAddress(address, type);
    if (r == false) {
      switch (type.toLowerCase()) {
        case 'office phone':
          this.errMsg = "Invalid phone number.";
          return;
        case 'home phone':
          this.errMsg = "Invalid phone number.";
          return;
        case 'mobile phone':
          this.errMsg = "Invalid mobile phone number.";
          return;
        case 'fax':
          this.errMsg = "Invalid fax number.";
          return;
        case 'email':
          this.errMsg = "Invalid email.";
          return;
        case 'aftn':
          this.errMsg = "AFTN must be 8 alphanumeric characters.";
          return;
        case 'arinc':
          this.errMsg = "ARINC must be 7 alphanumeric characters.";
          return;
        case 'sita':
          this.errMsg = "SITA must be 7 alphanumeric characters.";
          return;
      }
    }
  }

  commAddressTypeChange(e: any) {
    this.errMsg = "";
    let text = e.target.options[e.target.options.selectedIndex].text;
    this.selectedCommAddressType = text;
    this.f.extension.setValue('');
    switch (text) {
      case "Office Phone":
      case "Home Phone":
      case "Mobile Phone":
        this.addressLabel = "Phone";
        break;
      default:
        this.addressLabel = "Address";

    }
  }

  


  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    this.errMsg = "";
    let type = this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.f.comm_address_type_select.value)[0].commAddressTypeDesc;
    if (this.f.address.value != "") {
      this.validateCommAddress(this.f.address.value, type);
    }   
    this.selAddressUseList = this.addressUseList.filter(v => v.selected == true);
    if (this.selAddressUseList.length == 0) {
      if (this.errMsg != "") {
        this.errMsg += "\n";
        this.errMsg += "You must select at least one address use.";
      }
      else
        this.errMsg = "You must select at least one address use.";
    }
    if (this.vendor_comm_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    let obj = new PersonCommsAddressModel(); 
     obj.personCommsAddressGUID="";
    if (this.personCommsAddressGUID != "" && this.personCommsAddressGUID != null)
      obj.personCommsAddressGUID = this.personCommsAddressGUID;
    obj.commAddressTypeGUID = this.f.comm_address_type_select.value;
  //  obj.addressRatingGUID = this.f.address_rating_select.value;
    obj.addressUseGUIDList = Array.prototype.map.call(this.selAddressUseList, s => s.addressUseGUID).toString();
    obj.address = this.f.address.value;
    obj.extension = "";
    obj.isPreferred = this.isPreferred;
    if ((type.toLowerCase() == "office phone" || type.toLowerCase() == "operations phone") && this.f.extension.value != "")
      obj.extension = this.f.extension.value;
    if (this.f.comm_address_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;
    obj.commRemarks = this.f.remarks.value;
    obj.personGUID = this.personId;
    
    this._commonService.savePersonCommsAddress<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.personCommsAddressGUID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          //if (obj.personCommsAddressGUID=="")
          //  this._dialogService.openAlertDialog("Added successfully.");
          //else
          //  this._dialogService.openAlertDialog("Modified successfully.");
          //this._dialogService.openAlertDialog("");
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          //this._dialogRef.close(this.isModified);

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  checkPreferred(event: any) {
    this.isPreferred = event.checked;
  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.avaiAddressUseList = [];
    this.selAddressUseList = [];
    this.selectedCommAddressType = "";
    this.selectedCommAddressTypeId = this.commAddressTypeList[0].commAddressTypeGUID;
    this.selectedCommAddressType = this.commAddressTypeList[0].commAddressTypeDesc;
    this.selectedAddressRatingId = this.addressRatingList[0].addressRatingGUID;
    this.address = "";
    this.extension = "";
    this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.personCommsAddressGUID = "";
    this.dialogTitle = "ADD New Record";
    this.initControls();    
    this.addressUseList.forEach(x => {
      x.selected = false;
    })
    this.avaiAddressUseList = this.addressUseList.filter(u => u.selected == false);
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }


}
