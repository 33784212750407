<div *ngIf="upPII" style="background-color:#f8f8f8;min-width: 71em">
  <div style="display: flex;padding-left:1em;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div style="margin-top:0.3em">
      <i class="material-icons-outlined" style="font-size: x-large; color: gray">manage_accounts</i>
    </div>
    <div style="margin-top:0.3125em;">
      <h5>Manifest</h5>
    </div>
  </div>
  <div>
    <form *ngIf="trip_personlist_editor_client_form" [formGroup]="trip_personlist_editor_client_form">
      <div style="margin-top:0em; padding-left: 1em; padding-right:1em; font-size: small">
        <div
          style="background-color: white; min-height:25em; padding-left:0.5em;padding-right:0.5em; padding-bottom:0.5em">
          <div style="display: flex; font-weight: bold;">
            <!--<div style="margin-right:0.25em">Trip Code</div>
            <div style="margin-right:1em">{{trip_code}}</div>-->
            <div style="margin-right:0.25em">Route</div>
            <div style="font-weight:normal;display: flex">
              <div *ngFor="let gs of activeGroundStopList; let i=index" style="display: flex">
                <div style="display: flex">
                  <div style="display: flex">
                    <div>{{gs.icao}}</div>
                    <div>{{i==activeGroundStopList.length-1? '': '-'}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="height:2.5em; margin-top:0.5em">
            <div style="display: flex">
              <div>
                <button type="button" *ngIf="!lockdownTrip && !lastLegDatePassed && canEdit" mat-button class="btn-high"
                  (click)="clickAddPerson()">
                  <div style="display:flex">Add Person to Manifest</div>
                </button>&nbsp;
                <button type="button" *ngIf="grantAccessToManageManifestProfiles" mat-button class="btn-low"
                  (click)="clickManagePerson()">
                  <div style="display:flex">Manage Crew/Passenger</div>
                </button>&nbsp;
                <button *ngIf="showTripSummaryReportBtn" type="button" mat-button class="btn-low"
                  (click)="clickTripSummary(null)">
                  <div style="display:flex">Export Manifest to PDF</div>
                </button>
              </div>
              <div *ngIf="showSpin" style="z-index:300; width: 20em;margin-left:1em ">
                <app-spin></app-spin>
              </div>
            </div>
            <!--manifest list-->
            <div *ngIf="showAvaiPersonList"
              style="position:absolute; float: left; margin-top: 0.25em; margin-left: 15em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
              <div style="display: flex">
                <div style="margin-right:2em">
                  <div style="font-weight: bold; text-decoration:underline">Role</div>
                  <div>
                    <div style="display: flex; margin-top:0.5em">
                      <div style="margin-right:0.25em">
                        <input formControlName="person_role" type="radio" value="PIC"
                          (click)="personRoleChange($event)" />
                      </div>
                      <div style="white-space: nowrap;margin-top:-0.15em">
                        <label>PIC</label>
                      </div>
                    </div>
                    <div style="display: flex; margin-top:0.25em">
                      <div style="margin-right:0.25em">
                        <input formControlName="person_role" type="radio" value="SIC"
                          (click)="personRoleChange($event)" />
                      </div>
                      <div style="white-space: nowrap;margin-top:-0.15em">
                        <label>SIC</label>
                      </div>
                    </div>
                    <div style="display: flex">
                      <div style="margin-right:0.25em">
                        <input formControlName="person_role" type="radio" value="Crew"
                          (click)="personRoleChange($event)" />
                      </div>
                      <div style="white-space: nowrap;margin-top:-0.15em">
                        <label>Crew</label>
                      </div>
                    </div>
                    <div style="display: flex">
                      <div style="margin-right:0.25em">
                        <input formControlName="person_role" type="radio" value="PAX"
                          (click)="personRoleChange($event)" />
                      </div>
                      <div style="white-space: nowrap;margin-top:-0.15em">
                        <label>Passenger</label>
                      </div>
                    </div>
                  </div>
                  <div style="font-weight: bold; margin-bottom:0.5em">Additional Options</div>
                  <div style="display: flex" *ngIf="f.person_role.value=='PIC'">
                    <div style="display: flex; margin-right:1em">
                      <div>
                        <input formControlName="additional_options_pic" type="radio" value="0"
                          (click)="changePICAdditionalOptions($event)" />
                      </div>
                      <div style="white-space: nowrap;margin-top:-0.25em">
                        <label>None</label>
                      </div>
                    </div>
                    <div style="display: flex; margin-right:1em">
                      <div>
                        <input formControlName="additional_options_pic" type="radio" value="12"
                          (click)="changePICAdditionalOptions($event)" />
                      </div>
                      <div style="white-space: nowrap;margin-top:-0.25em">
                        <label>TBA PIC</label>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex" *ngIf="f.person_role.value=='SIC'">
                    <div style="display: flex; margin-right:1em">
                      <div>
                        <input formControlName="additional_options_sic" type="radio" value="0"
                          (click)="changeSICAdditionalOptions($event)" />
                      </div>
                      <div style="white-space: nowrap;margin-top:-0.25em">
                        <label>None</label>
                      </div>
                    </div>
                    <div style="display: flex; margin-right:1em">
                      <div>
                        <input formControlName="additional_options_sic" type="radio" value="13"
                          (click)="changeSICAdditionalOptions($event)" />
                      </div>
                      <div style="white-space: nowrap;margin-top:-0.25em">
                        <label>TBA SIC</label>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="f.person_role.value=='Crew'">
                    <div style="display: flex">
                      <div style="display: flex; margin-right:1em">
                        <div>
                          <input formControlName="additional_options_crew" type="radio" value="0"
                            (click)="changeCrewAdditionalOptions($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.25em">
                          <label>None</label>
                        </div>
                      </div>
                      <div style="display: flex; margin-right:1em">
                        <div>
                          <input formControlName="additional_options_crew" type="radio" value="6"
                            (click)="changeCrewAdditionalOptions($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.25em">
                          <label>TBA Crew</label>
                        </div>
                      </div>
                    </div>
                    <!--<div >
                      <div style="display: flex; margin-right:1em">
                        <div>
                          <input formControlName="additional_options_crew"
                                 type="radio"
                                 value="7" (click)="changeCrewAdditionalOptions($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.25em">
                          <label>Unknown Crew</label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style="display: flex; margin-right:0.5em">
                        <div>
                          <input formControlName="additional_options_crew"
                                 type="radio"
                                 value="8" (click)="changeCrewAdditionalOptions($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.25em">
                          <label>Followup Required</label>
                        </div>
                      </div>
                    </div>-->
                  </div>
                  <div *ngIf="f.person_role.value=='PAX'">
                    <div style="display: flex">
                      <div style="display: flex; margin-right:1em">
                        <div>
                          <input formControlName="additional_options_pax" type="radio" value="0"
                            (click)="changePaxAdditionalOptions($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.25em">
                          <label>None</label>
                        </div>
                      </div>
                      <div style="display: flex; margin-right:1em">
                        <div>
                          <input formControlName="additional_options_pax" type="radio" value="9"
                            (click)="changePaxAdditionalOptions($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.25em">
                          <label>TBA PAX</label>
                        </div>
                      </div>
                    </div>
                    <!--<div>
                      <div style="display: flex; margin-right:1em">
                        <div>
                          <input formControlName="additional_options_pax"
                                 type="radio"
                                 value="10" (click)="changePaxAdditionalOptions($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.25em">
                          <label>Unknown PAX</label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style="display: flex; margin-right:0.5em">
                        <div>
                          <input formControlName="additional_options_pax"
                                 type="radio"
                                 value="11" (click)="changePaxAdditionalOptions($event)" />
                        </div>
                        <div style="white-space: nowrap;margin-top:-0.25em">
                          <label>Followup Required</label>
                        </div>
                      </div>
                    </div>-->
                  </div>
                </div>
                <div style="min-width: 14em">
                  <div style="display:flex; justify-content:space-between">
                    <div style="font-weight: bold; text-decoration:underline; white-space: nowrap">
                      {{person_role=='PAX'?'Passenger':person_role}}</div>
                    <div style="float:right" (click)="showAvaiPersonList=!showAvaiPersonList"><i class="material-icons"
                        style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                  </div>
                  <div style="font-size: small; margin-left:0em">
                    <div style="margin-right:0.5em;">
                      <div
                        style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                        <div><i class="material-icons"
                            style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                        <input type="text" formControlName="person_search_keyword" class="input-noborder"
                          placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;"
                          (keyup)="personSearchKeywordChange($event)" />
                      </div>
                    </div>
                  </div>
                  <!--pic-->
                  <div *ngIf="person_role=='PIC' && picList.length>0"
                    style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                    <div *ngFor="let p of avaiPICList; let i=index" [style.margin-top.em]="i==0?0.125:-1"
                      class="mat-checkbox-smaller">
                      <div #tripPersonRefs [attr.personGUID]="p.personGUID"
                        style="display: flex; font-size: small; margin-top:-0.5em">
                        <div>
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)"
                            [disabled]="p.disabled" [checked]="p.selected">{{p.personName}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--pic end-->
                  <!--sic-->
                  <div *ngIf="person_role=='SIC' && sicList.length>0"
                    style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                    <div *ngFor="let p of avaiSICList; let i=index" [style.margin-top.em]="i==0?0.125:-1"
                      class="mat-checkbox-smaller">
                      <div #tripPersonRefs [attr.personGUID]="p.personGUID"
                        style="display: flex; font-size: small; margin-top:-0.5em">
                        <div>
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)"
                            [disabled]="p.disabled" [checked]="p.selected">{{p.personName}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--sic end-->
                  <!--crew-->
                  <div *ngIf="person_role=='Crew' && crewList.length>0"
                    style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                    <div *ngFor="let p of avaiCrewList; let i=index" [style.margin-top.em]="i==0?0.125:-1"
                      class="mat-checkbox-smaller">
                      <div #tripPersonRefs [attr.personGUID]="p.personGUID"
                        style="display: flex; font-size: small; margin-top:-0.5em">
                        <div>
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)"
                            [checked]="p.selected">{{p.personName}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--crew end-->
                  <!--passenger-->
                  <div *ngIf="person_role=='PAX' && paxList.length>0"
                    style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                    <div *ngFor="let p of avaiPaxList; let i=index" [style.margin-top.em]="i==0?0.125:-1"
                      class="mat-checkbox-smaller">
                      <div #tripPersonRefs [attr.personGUID]="p.personGUID"
                        style="display: flex; font-size: small; margin-top:-0.5em">
                        <div>
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="checkPersonChange($event, p)"
                            [checked]="p.selected">{{p.personName}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--passenger end-->
                </div>

              </div>
              <div style="margin-top:2em; height:0.75em">
                <hr color="#f8f8f8" />
              </div>
              <div style="display: flex; float: right" *ngIf="showPersonActionBtn">
                <div style="margin-right:0.5em">
                  <button type="button" mat-button class="btn-low" (click)="clickResetPerson()"
                    style="height:2em">RESET</button>
                </div>
                <div>
                  <button type="button" mat-button class="btn-high" (click)="clickDonePerson()"
                    style="height:2em">Done</button>
                </div>
              </div>
            </div>
            <!--manifest list end-->
          </div>
          <div>
            <div *ngIf="totalPageNumber>1"
              style="display: flex; justify-content: left; margin-top:-1.25em; margin-left:-0.5em; padding: 0em 0em 0em 0em;">
              <mat-paginator style="border: none; font-size: medium; font-weight: bold; color: black" #paginator
                *ngIf="activeLegList && totalPageNumber>1" [length]="totalLegCount" [pageSize]="pageSize"
                [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2"
                (page)="pageEvent = pageIndexChange($event)">
              </mat-paginator>
            </div>
            <div style="height: 35em;overflow-y: auto;overflow-x:hidden;">
              <table mat-table *ngIf="tripPersonList" [dataSource]="tripPersonList"
                class="example-container2 mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="blank">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap; width:3.5em;"
                      [hidden]="hideSelectColumn">
                      <!--Remove-->{{removeLable}}
                      <!--<div *ngIf="showSaveBtn">
    <div style="margin-top:-0.25em;">Select</div>
    <div style="margin-top:-0.25em;">All</div>
    <div style="margin-top:-0.25em;">Legs</div>
  </div>-->
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;" [hidden]="hideSelectColumn">
                      <div style="display: flex; margin-left:0.5em">
                        <!--<div *ngIf="element.newlyAdded" style="margin-top:0.5em" class="mat-checkbox-smaller" title="Save to all future active legs if selected">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="checkSavePersonChange($event, element)" [checked]="selection.isSelected(element)"></mat-checkbox>
                        </div>-->
                        <div style="margin-left:0.125em; margin-top:0.45em; cursor: pointer"
                          *ngIf="removeLable!='' && element.person.tripLegList.length==0"
                          (click)="clickRemoveUnSavedPerson($event, element.person);" title="Remove the person">
                          <i class="material-icons-outlined" style="font-size: medium; color: red">cancel</i>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="person_type">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap; width:3em;">
                      <div style="margin-top:-0.25em;">Type</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">{{element.person.personType}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="person_name">
                    <th mat-header-cell *matHeaderCellDef
                      style="white-space:nowrap; width:17em; text-align: left !important;">
                      <div style="margin-top:-0.25em;">Full Name + DOB</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;text-align: left !important;">
                      <div>{{element.person.personName}}</div>
                      <div *ngIf="element.person.dob!=''" style="margin-top:-0.25em">{{element.person.dob}}</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="minor">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap; width:4.5em;">
                      <div style="margin-top:-0.25em;">Minor</div>
                      <div style="margin-top:-0.25em;">On Board</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                      {{element.person.isMinorOnboard?'Yes':''}}</td>
                  </ng-container>
                  <ng-container matColumnDef="display_leg_{{l.tripLegOrder}}"
                    *ngFor="let l of displayLegList; let i = index">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap; width:6em;"
                      [style.background-color]="l.nextArrivalDateTimePassed?'#E9ECEF':''">
                      <div style="margin-top:-0.25em;">LEG {{l.tripLegOrder}}</div>
                      <div style="margin-top:-0.25em;font-weight: normal">{{l.leg_ICAOs}}</div>
                      <div style="margin-top:-0.25em;font-weight: normal">{{l.isDeptFerryFlight?'(Ferry FLT)':''}}</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;"
                      [style.background-color]="element.tripLegState[i+currentPageIndex*pageSize]=='check_circle' && element.person.capacityID==1  
                       &&  element.expiredPassportList[i+currentPageIndex*pageSize]   &&  element.isPassportNotRequiredList[i+currentPageIndex*pageSize] == false ? '#f9d2de': 'transparent'">
                      <div *ngIf="canEdit">
                        <div *ngIf="element.tripLegState[i+currentPageIndex*pageSize]=='check_circle'"
                          (click)="clickEditPerson($event, element, l,'Edit')" style="cursor: pointer"><i
                            class="material-icons-outlined"
                            style="font-size: x-large; margin-top:0em;color: green;">check_circle</i></div>
                        <div *ngIf="element.tripLegState[i+currentPageIndex*pageSize]=='add_circle'"
                          (click)="clickEditPerson($event, element, l, 'Add')" style="cursor: pointer"><i
                            class="material-icons-outlined"
                            style="font-size: x-large; margin-top:0em;color: #7dacff;">add_circle</i></div>
                      </div>
                      <div *ngIf="!canEdit">
                        <div *ngIf="element.tripLegState[i+currentPageIndex*pageSize]=='check_circle'"
                          matTooltipClass="tooltipLineBreak"
                          matTooltip="{{getPersonDocListTooltip(element.person, l)}}"><i class="material-icons-outlined"
                            style="font-size: x-large; margin-top:0em;color: green;">check_circle</i></div>
                      </div>
                    </td>



                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div
    style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
    <div *ngIf="canEdit" style="margin-right:1em; display: flex; font-size: small;">
      <!--<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <button type="button" mat-button class="btn-high" *ngIf="showSaveBtn && loading==false && showSuccessMsg==false && !hideSelectColumn" [disabled]="loading" (click)="clickSave(null,'')"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>SAVE</div></div></button>&nbsp;-->
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <button type="button" mat-button class="btn-high" *ngIf="showSubmitBtn && loading==false && showSuccessMsg==false"
        [disabled]="loading" (click)="clickSubmit()"><span *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"></span>
        <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i>
          <div>Submit</div>
        </div>
      </button>&nbsp;
      <button type="button" mat-button class="btn-low" *ngIf="showCancelBtn" (click)="clickCancel()">
        <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
          <div>Cancel</div>
        </div>
      </button>

      <div style="margin-left: 1.5em; display: flex;">
        <div style="background-color: #f9d2de; height: 1.5em; width: 2em; display: inline-block;">
        </div>&nbsp; - Indicates
        Missing or Expired Passport
      </div>

    </div>
    <div *ngIf="!canEdit" style="margin-right:1em; display: flex; font-size: small;">
      <button type="button" mat-button class="btn-low" *ngIf="showCancelBtn" [mat-dialog-close]>
        <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
          <div>Close</div>
        </div>
      </button>

      <div style="margin-left: 1.5em; display: flex;">
        <div style="background-color: #f9d2de; height: 1.5em; width: 2em; display: inline-block;">
        </div>&nbsp; - Indicates
        Missing or Expired Passport
      </div>

    </div>
    <div *ngIf="errMsg!=''" class="d-block"
      style="margin-left:1em; display:flex;margin-top:-0.5em;background-color:#f9d2de; padding-left:0.25em; min-width:16em;">
      <label style="color:black; font-size: small">{{errMsg}}</label>
    </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]="successMsg"></app-success-message>
    </div>
  </div>
</div>
<!--<div *ngIf="showSpin" style="z-index:300; margin-top: -26em ; margin-left: 20em; position:fixed; min-width: 20em">
  <app-spin></app-spin>
</div>-->