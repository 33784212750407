import { Component, Inject, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CustomerModel } from '../models/customer.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-client-halt-services-view-dialog',
  templateUrl: './client-halt-services-view-dialog.component.html',
  styleUrls: ['./client-halt-services-view-dialog.component.css']
})

export class ClientHaltServicesViewDialogComponent {
  client_halt_services_view_form: UntypedFormGroup;
  customerGUID: string;
  textSearch: string;
  filterBy: string;
  errMsg: string;
  showSpin: boolean;
  loading: boolean;
  msg: string;
  userType: string;
  haltHandling: boolean;
  haltPermits: boolean;
  haltFuel: boolean;
  haltWeather: boolean;
  haltHotel: boolean;
  haltWebsite: boolean;
  haltSSN: boolean;
  haltAll: boolean;
  halt_remarks: string;
  haltUpdatedBy: string;
  haltUpdatedDate: string;
  customerName: string;
  controlValueChanged: boolean = false;


  @ViewChild("haltAllCk") haltAllCk;
  @ViewChild("haltHandlingCk") haltHandlingCk;
  @ViewChild("haltPermitsCk") haltPermitsCk;
  @ViewChild("haltFuelCk") haltFuelCk;
  @ViewChild("haltWeatherCk") haltWeatherCk;
  @ViewChild("haltHotelCk") haltHotelCk;
  @ViewChild("haltWebsiteCk") haltWebsiteCk;
  @ViewChild("haltSSNCk") haltSSNCk;
  upPII: boolean = false;
  constructor(private _router: Router, private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog,
    private readonly _customerService: CustomerService, private readonly _formBuilder: UntypedFormBuilder, private _route: ActivatedRoute,
    private readonly _dialogRef: MatDialogRef<ClientHaltServicesViewDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _dialogService: DialogService
  ) {
   
    this.customerGUID = _data.customerGUID;
    
  }

  ngOnInit() {
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    //this._route.queryParams.subscribe(params => {
    //  this.aircraftGuid = params["aircraftGuid"];
    //  this.customerGuid = params["customerGuid"];
    //  this.textSearch = params["textSearch"];
    //  this.filterBy = params["filterBy"];
    //});
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getData();
   
  }

  getData() {
    this._customerService.getCustomerBycId<ResponseModel<CustomerModel>>(this.customerGUID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let customer = new CustomerModel();
          customer = response.model;
          this.haltHandling = customer.haltHandling;
          this.haltFuel = customer.haltFuelSetup;
          this.haltPermits = customer.haltPermits;
          this.haltWeather = customer.haltWeatherServices;
          this.haltHotel = customer.haltHotelSetup;
          this.haltWebsite = customer.haltWebsiteAccess;
          this.haltSSN = customer.haltSSN;
          this.halt_remarks = customer.haltRemarks;
          this.haltUpdatedBy = customer.haltModifiedBy;
          this.haltUpdatedDate = customer.haltDate;
          this.customerName = customer.customerName;
          if (this.haltFuel && this.haltHandling && this.haltHotel && this.haltPermits && this.haltWeather && this.haltWebsite && this.haltSSN)
            this.haltAll = true;
          this.initControls();
        }

      }
      this.showSpin = false;
    });

  }

  initControls() {
    this.client_halt_services_view_form = this._formBuilder.group({
      halt_remarks: this.halt_remarks
     
    });
  }

  get f() { return this.client_halt_services_view_form.controls; } 

  clickClose() {
    this._dialogRef.close(true);
  }

}
