import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticateService } from '../services/authenticate.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CustomerGroupService } from '../services/customer-group-service';
import { PersonService } from '../services/person.service';
import { DialogService } from '../services/dialog.service';
import { PersonModel } from '../models/person.model';
import { Observable, forkJoin, of } from 'rxjs';
import { ResponseModel } from '../models/response.model';
import { PersonClassModel } from '../models/person-class.model';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CustomerModel } from '../models/customer.model';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { CustomerPersonEditDialogComponent } from './customer-person-edit-dialog.component';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { EULisa } from '../models/eu-lisa.model';
import { CustomerPersonModel } from '../models/customer-person.model';
import { CustomerPersonDetailsComponent } from './customer-person-details.component';
import { CustomerProfileSetupComponent2 } from '../customers/customer-profile-setup2.component';
import { CustomerProfileSetupEditComponent } from '../customers/customer-profile-setup-edit.component';
import { CustomerInformationDialogComponent } from '../customers/customer-information-dialog.component';
import { AircraftProfileComponent } from '../aircraft/aircraft-profile.component';
import { AdminServicesClientComponent } from '../user-accounts/admin-services-client.component';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { PersonAuditReportComponent } from './person-audit-report.component';

@Component({
  selector: 'app-manage-contact-details',
  templateUrl: './manage-contact-details.component.html',
  styleUrls: ['./manage-contact-details.component.css']
})
export class ManageContactDetailsComponent implements OnInit {
  contact_details_form: UntypedFormGroup;
  showSpin: boolean = false;
  upPII: boolean = false;  
  grantAccessToManageManifestProfiles: boolean = false;
  grantAccessToAccountGrouping: boolean = false;
  recordList: PersonModel[];
  filterOption: number = 1;
  personClassList: PersonClassModel[];
  selectedPersonClass: PersonClassModel;
  inputPersonClassGUID: string=null;
  customer: CustomerModel=null;
  customerGUID: string="";
  totalRecordsText: string = "";
  tripCodeList: TripModel[]=[];
  msg: string = "";
  isOpenedNew: boolean = false;
  userType: string;
  totalClientCount: number = 1;
  tripCodeGUID: string = "";
  customerList: CustomerModel[];
  displayedColumns: string[] = ['name', 'title', 'role', 'sync_count', 'data_entry_status', 'action'];

  constructor(private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _dialog: MatDialog, private readonly _personService: PersonService, private readonly _dialogService: DialogService,
    private readonly _customerGroupService: CustomerGroupService, private readonly _route: ActivatedRoute, private readonly _groundStopService: GroundStopService, private readonly _groundStopClientService:GroundStopClientService,
    @Optional() @Inject(MAT_DIALOG_DATA) private _data: any, private _router: Router, @Optional() private readonly _dialogRef: MatDialogRef<ManageContactDetailsComponent>,) {
    if (_data) {
      if (_data.customerGUID != null && _data.customerGUID != undefined) {
        this.customerGUID = _data.customerGUID;
      }
      if (_data.tripCodeGUID != null && _data.tripCodeGUID != undefined) {
        this.tripCodeGUID = _data.tripCodeGUID;
      }
    }
  }

    ngOnInit() {
      this._authService.updateAccessTime();
      this._route.url.subscribe(o => {
        if (o[0].path == "")
          this.isOpenedNew == false;
        else {
          this.isOpenedNew = true;
          this._route.queryParams.subscribe(params => {
            if (params["customerGUID"] != null && params["customerGUID"] != undefined) {
              this.customerGUID = params["customerGUID"];
            }
            if (params["tripCodeGUID"] != null && params["tripCodeGUID"] != undefined) {
              this.tripCodeGUID = params["tripCodeGUID"];
            }
          });
        }
      });
         
      switch (localStorage.getItem('ut').toLowerCase()) {
        case "i":
          this.userType = "internal";
          break;
        case "c":
          this.userType = "customer";
          break;
        case "v":
          this.userType = "vendor";
          break;
        default:
          this.userType = "";
          break;
      }
      this.grantAccessToManageManifestProfiles = false;
      if (localStorage.getItem('up.mPro') == 'true') {
        this.grantAccessToManageManifestProfiles = true;
      }
      this.upPII = false;
      if (localStorage.getItem('up.pii') == 'true') {
        this.upPII = true;
      }

      if (!this.upPII || !this.grantAccessToManageManifestProfiles) {
        this._dialogService.openAlertDialog("Permission denied.", "OK");
        return;
      }


      if (localStorage.getItem('up.cGrp') == 'true') {
        this.grantAccessToAccountGrouping = true;
      }
      this.showSpin = true;
      this.getData().subscribe(responses => {
        if (responses[0] != null) {
          if (responses[0].code == "200" && responses[0].message == "") {
            this.personClassList = responses[0].model;
            if (this.inputPersonClassGUID == null) {
              this.selectedPersonClass = this.personClassList[0];
            }
            else {
              this.selectedPersonClass = this.personClassList.find(x => x.personClassGUID.toLowerCase() == this.inputPersonClassGUID.toLowerCase());
            }
          }
          else {
            if (responses[0].code == "401") {
              this._authService.signOut();
            }
          }
        }

        if (responses[1] != null) {
          if (responses[1].code == "200" && responses[1].message == "") {
            this.customer = responses[1].model;
            
            if (this.customer.customerGroup != null) {
              if (this.customer.customerGroup.length == 1)
                this.totalClientCount = this.customer.customerGroup[0].customerList.length;
              else {

                this.customer.customerGroup.forEach(x => {
                  this.totalClientCount += x.customerList.length - 1;
                });
              }
            }
          }
          else {
            if (responses[1].code == "401") {
              this._authService.signOut();
            }
          }
        }

        if (responses[2] != null) {
          if (responses[2].code == "200" && responses[2].message == "") {
            this.tripCodeList = responses[2].model;
          }
          else {
            if (responses[2].code == "401") {
              this._authService.signOut();
            }
          }
        }
        if (responses[3] != null) {
          if (responses[3].code == "200" && responses[3].message == "") {
            this.customerList = responses[3].model;
            if (this.customerList.length > 0) {
              if (this.userType == "customer" && this.customerGUID == "") {
                this.customerGUID = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();
              }
            }
          }
          else {
            if (responses[3].code == "401") {
              this._authService.signOut();
            }
          }
        }
        this.initControls();
        this.getPersonList();       
        this.showSpin = false;
      });
    
  }


  initControls() {
    this.contact_details_form = this._formBuilder.group({
      customer_select:this.customerGUID.toLowerCase(),
      search_options: "Name",
      text_search:"",
      trip_code: this.tripCodeGUID      
    });
  }

  get f() { return this.contact_details_form.controls; }

  getData(): Observable<any[]> {
    let getPersonClassResponse = this._personService.getPersonClassList<ResponseModel<PersonClassModel[]>>();
    let getCustomerResponse = this._customerGroupService.getCustomerWithGroupingBycId(this.customerGUID);
    let getTripCodeResponse;
    let getClientListResponse;
    if (this.userType == "customer") {
      getTripCodeResponse = this._groundStopClientService.getTripCodeListForClientBycIduId(this.customerGUID, true);
      if (this.grantAccessToAccountGrouping) {
        let req = new CustomerGroupModel();
        getClientListResponse = this._customerGroupService.getSelectedCustomerListBycgrpId(req);
      }
      else {
        getClientListResponse = of(null);
      }
    }
    else {
      getTripCodeResponse = this._groundStopService.getTripCodeListBycId(this.customerGUID, true);
      getClientListResponse = of(null);
    }
    return forkJoin([getPersonClassResponse, getCustomerResponse, getTripCodeResponse, getClientListResponse]);

  }

  customerChange(e: any) {
    this.msg = "";
    this.recordList = [];
    this.tripCodeList = [];
    this.customerGUID = this.f.customer_select.value;
    if (e.target.value != "") {
      this.getPersonList();
      this._groundStopClientService.getTripCodeListForClientBycIduId<ResponseModel<TripModel[]>>(this.customerGUID, true).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.tripCodeList = response.model;
            this.f.trip_code.setValue("");
          }
          else {
            if (response.code == "401")
              this._authService.signOut();
            else
              this.msg = "An error has occurred.";
          }
        }
      });
      this._customerGroupService.getCustomerWithGroupingBycId<ResponseModel<CustomerModel>>(this.customerGUID).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.customer = response.model;
          }
          else {
            if (response.code == "401")
              this._authService.signOut();
            else
              this.msg = "An error has occurred.";
          }
        }
      });
    }
    
  }


  getPersonList() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.msg = "";
    let request = new PersonModel();
    
    request.customerGUID = this.customerGUID;
    request.textSearch = this.f.text_search.value;
    request.filterBy = this.f.search_options.value;
    request.tripCodeGUID = this.f.trip_code.value;
    request.selectedPersonClassGUID = this.selectedPersonClass.personClassGUID;
    if (this.filterOption != 3)
      request.isActive = true;
    else
      request.isActive = false;
    if (this.filterOption == 2)
      request.filterDataEntryStatus = true;
    else
      request.filterDataEntryStatus = false;

    this._personService.getCustomerPersonByConditions<ResponseModel<CustomerPersonModel>>(request).subscribe(response => {
      this.personClassList.forEach(x => x.personClassCount = 0);
      if (response.model != null && response.code == "200") {
        if (response.model.customerPersonClass != null) {
          response.model.customerPersonClass.forEach(x => {
            this.personClassList.find(v => v.personClassGUID.toLowerCase() == x.personClassGUID.toLowerCase()).personClassCount = x.personClassCount
          });
        }
        if (response.model.customerPersons && response.model.customerPersons.length > 0) {
          this.recordList = response.model.customerPersons;
          this.totalRecordsText = this.recordList.length.toString() + ' record count';
          

        }
        else {
          this.recordList = [];
          if (this.f.text_search.value != "") {
            switch (this.f.search_options.value) {
              case "Name":
                this.msg = "Name not found.";
                break;
              case "Title":
                this.msg = "Title not found.";
                break;
              case "Comms":
                this.msg = "Comms not found.";
                break;
            }
          }
          else {
            this.msg = "No records found.";
          }
        }
      }
      else {
        this.recordList = [];
      }

      this.showSpin = false;

    });
  }

  openInNew() {
    this._dialogRef.close();
    let route = "/persons/manage-contact-details?customerGUID=" + this.customerGUID;
    if (this.tripCodeGUID != "")
      route += "&tripCodeGUID=" + this.tripCodeGUID;
    window.open(route, '_blank');

  }

  clickAdd() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: "Create New Contact", personGUID: "", customerGUID: this.customerGUID, selectedPersonClass: this.selectedPersonClass };
    
    const dialogRef = this._dialog.open(CustomerPersonEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.getPersonList();
      }
      else {
        if (result.newId != "") {
          // goto person details pages
          let navigationExtras: NavigationExtras = {
            queryParams: {
              "pId": result.newId,
              "selectedPersonClassGUID": this.selectedPersonClass.personClassGUID

            }
          };
          this._router.navigate(['/persons/customer-person-details'], navigationExtras);
        }
      }

    });
  }

  clickEdit(p: PersonModel) {
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "pId": p.personGUID,
    //    "selectedPersonClassDescription": this.selectedPersonClass.personClassDescription,
    //    "selectedPersonClassGUID": this.selectedPersonClass.personClassGUID

    //  }
    //};
    //this._router.navigate(['/persons/customer-person-details'], navigationExtras);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.maxHeight = "95vh";
    dialogConfig.data = { personGUID: p.personGUID, customerGUID: this.customerGUID, selectedPersonClassGUID: this.selectedPersonClass.personClassGUID, selectedPersonClassDescription: this.selectedPersonClass.personClassDescription };

    const dialogRef = this._dialog.open(CustomerPersonDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPersonList();
      }

    });



  }


  clickReset() {
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.selectedPersonClass = this.personClassList[0];
    this.initControls();
    this.getPersonList();

  }


  openClientProfileSetupComponent() {
    if (!this.isOpenedNew) {
      this._dialogRef.close(true);
    }
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.width = "55em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "10px", top: "140px" };
    config.data = { gsId: "", tripCodeId: "", isDialog: true, v: this._authService.getCurrentTimeNumber() };

    this._dialog.open(CustomerProfileSetupComponent2, config);
  }


  openSelection(columnName: string) {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "32.5em";//"1000px";
    //config.maxWidth = "65em";//"1000px";
    config.width = "65em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGUID, customerName: this.customer.customerName, userType: 'customer' };
    config.position = { left: "10px", top: "140px" };

    if (!this.isOpenedNew) {
      this._dialogRef.close(true);
    }

    switch (columnName) {
      case 'Acct Setup':
        config.height = "50em";
        config.width = "90em";
        config.maxWidth = "90em";
        config.maxHeight = "50em";
        config.position = { left: "10px", top: "140px" };
        this._dialog.open(CustomerProfileSetupEditComponent, config);
        break;
      case 'Client Information':
        config.width = "69em";
        this._dialog.open(CustomerInformationDialogComponent, config);
        break;
      case 'Aircraft':
        config.width = "92em";
        config.maxWidth = "92em";
        this._dialog.open(AircraftProfileComponent, config);
        break;
      case 'Admin Services':
        config.height = "max-content";
        config.width = "max-content";
        this._dialog.open(AdminServicesClientComponent, config);
        break;
    }

  }

  openPersonAuditReport() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "48em";//"1000px";
    //config.maxWidth = "65em";//"1000px";
    config.width = "82em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGUID, 
      customerName: this.customer.customerName, userType: 'customer', isInternalFullVersion: false };
    config.position = { left: "10px", top: "140px" };

    if (!this.isOpenedNew) {
      this._dialogRef.close(true);
    }

    this._dialog.open(PersonAuditReportComponent, config);
  }
}
