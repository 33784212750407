import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Component, Inject, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { CustomerModel } from '../models/customer.model';
import { OutlookMailFolder } from '../models/outlook-mail-folder.model';
import { ResponseModel } from '../models/response.model';
import { TripModel } from '../models/trip.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { CONTAINER_DATA, MessageService } from '../services/message.service';
import { FolderSelectionDialogComponent } from './folder-selection-dialog.component';
import { MessageImportComponent } from './message-import.component';

export interface SelectedFolder {
  displayName: string;
  folderID: string;
  totalItems: number;
  hasChildFolders: boolean;
  parentFolder: SelectedFolder;
  customerGUID: string;
  parentFolderId: string;
  isImported: boolean;
  importedCount: number;
  importedBy: string;
  importedDate: string;
} 

@Component({
  selector: 'app-message-import-dialog',
  templateUrl: './message-import-dialog.component.html',
  styleUrls: ['./message-import-dialog.component.css']
})
export class MessageImportDialogComponent implements OnInit {

  showSpin: boolean = false;


  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  @ViewChild(MatTable) table: MatTable<any>;
  message_import_form: UntypedFormGroup;
  errMsg: string = "";
  componentPortal: ComponentPortal<FolderSelectionDialogComponent>;
  submitted: boolean = false;
  loading: boolean = false;
  isModified: boolean = false;
  customerList: CustomerModel[];
  selectedFolder: SelectedFolder;
  customerGUID: string="";
  tripCodeGUID: string="";
  @Input() folderID: string;
  tripCodeList: TripModel[];
  displayName: string;
  title: string = "";
  showCustomer: boolean = false;
  showSuccessMsg: boolean = false;
  constructor(@Inject(CONTAINER_DATA) public componentData: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _dialogService: DialogService,
    private readonly _groundStopService: GroundStopService, private readonly _messageImport: MessageImportComponent,
    private _injector: Injector, private readonly _commonService: CommonService, private readonly _messageService:MessageService
  ) {
    this.selectedFolder = componentData.selectedFolder;
    this.title = componentData.title;
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerList = responses[0].model;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.tripCodeList = responses[1].model;
          this.tripCodeList.forEach(x => {
            if (this.selectedFolder.displayName.startsWith(x.tripCode))
              this.tripCodeGUID = x.tripCodeGUID;
          });

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.initControls();
    });

  
    
  }

  getAllData(): Observable<any[]> {
    let getClistResponse = this._commonService.getCustomers();
    let getTripResponse = this._groundStopService.getTripCodeListBycId(this.selectedFolder.customerGUID);
    
    return forkJoin([getClistResponse, getTripResponse]);
  }

  customerChange(e: any) {
    this.tripCodeGUID = "";
    this._groundStopService.getTripCodeListBycId<ResponseModel<TripModel[]>>(this.f.customer_select.value).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.tripCodeList = response.model;
          this.tripCodeList.forEach(x => {
            if (this.displayName.startsWith(x.tripCode))
              this.tripCodeGUID = x.tripCodeGUID;
          });

        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    })
  }

  initControls() {
    this.message_import_form = this._formBuilder.group({
      customer_select: this.selectedFolder.customerGUID,
      trip_code:this.tripCodeGUID
    });
  }

 
  get f() { return this.message_import_form.controls; }


  clickCancel() {

  
    this.componentPortal = new ComponentPortal(FolderSelectionDialogComponent, null, this.createInjector("AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA="));
    this._messageImport.selectedPortal = this.componentPortal;
  
}


createInjector(dataToPass): PortalInjector {
  const injectorTokens = new WeakMap();
  injectorTokens.set(CONTAINER_DATA, dataToPass);
  return new PortalInjector(this._injector, injectorTokens);
}
  clickSave() {

    this._authService.updateAccessTime();
    this.errMsg = "";
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.message_import_form.invalid) {
      return;
    }
    this.showSpin = true;
    this.loading = true;
    let request = new OutlookMailFolder();
    request.mailFolderId = this.selectedFolder.folderID;
    request.customerGUID = this.f.customer_select.value;
    request.tripCodeGUID = this.f.trip_code.value;
    this._messageService.importMessagesByFolderID<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          if (response.model == true) {
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
            //  this.selectedFolder.parentFolderId = "AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA=";
              this.clickCancel();
            }, 1000);
          }
          else {
            this.errMsg = "There was an error importing messages.";
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          else {
            this.errMsg = "Error.";
          }
        }
      }
      this.loading = false;
      this.showSpin = false;
    });
   

  }
  


 }
  

