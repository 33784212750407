<div style="margin-top:0em; padding-top:0em; display:flex">
  <form *ngIf="aircraft_owner_operator_form" [formGroup]="aircraft_owner_operator_form">
    <div style="margin-top:-1em">
      <table mat-table [dataSource]="recordList" class="example-container">
        <tr>
          <ng-container matColumnDef="owner_operator_type">
            <th mat-header-cell *matHeaderCellDef>
              Type
            </th>
            <td mat-cell *matCellDef="let element" width="10%">{{element.ownerOperatorType}}</td>
          </ng-container>
          <ng-container matColumnDef="company_name">
            <th mat-header-cell *matHeaderCellDef>
              Company Name
            </th>
            <td mat-cell *matCellDef="let element" width="15%">{{element.companyName}}</td>
          </ng-container>
          <ng-container matColumnDef="person_name">
            <th mat-header-cell *matHeaderCellDef>
              Name
            </th>
            <td mat-cell *matCellDef="let element" width="15%">{{element.personName}}</td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>
              Address
            </th>
            <td mat-cell *matCellDef="let element" width="20%">{{element.ownerOperatorAddress}}</td>
          </ng-container>
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>
              Phone
            </th>
            <td mat-cell *matCellDef="let element" width="10%">{{element.phoneNumber}}</td>
          </ng-container>
          <ng-container matColumnDef="fax">
            <th mat-header-cell *matHeaderCellDef>
              Fax
            </th>
            <td mat-cell *matCellDef="let element" width="10%">{{element.faxNumber}}</td>
          </ng-container>
          <ng-container matColumnDef="disabled">
            <th [hidden]="userType!='internal'" mat-header-cell *matHeaderCellDef>
              Disabled
            </th>
            <td [hidden]="userType!='internal'" mat-cell *matCellDef="let element" width="10%">{{element.isActive? '':'Yes'}}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              Action <br /><a *ngIf="userType=='internal'" mat-button class="btn-high grid" (click)="clickEdit($event,null)">Add</a>
            </th>
            <td mat-cell *matCellDef="let element; let i=index " width="10%">
              <a mat-button class="btn-low grid" (click)="clickEdit($event, element);">
                <span *ngIf="userType=='internal'">Edit</span>
                <span *ngIf="userType!='internal'">View</span>
              </a>
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
    </div>
    <div>
      <mat-paginator style="border: none;width:108em;margin-top:1em" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSize]="pageSize"
                     [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
      </mat-paginator>
    </div>
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
