import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, Optional, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
//import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomValidators } from '../common-utility/custom.validators';
import { AirportModel } from '../models/airport.model';
import { CountryModel } from '../models/country.model';
import { FuelPricingModel } from '../models/fuel-pricing.mode';
import { FuelSupplierModel } from '../models/fuelsupplier.model';
import { IntoPlaneAgentModel } from '../models/intoplaneagent.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { FuelService } from '../services/fuel.service';
import { Title } from '@angular/platform-browser';
import { FuelpricingComponent } from '../fuelpricing/fuelpricing.component';
import { ReturnObjModel } from '../models/return-obj.model';
import { FuelPricingEditDialogComponent } from './fuel-pricing-edit-dialog.component';
import { FuelMenuComponent } from './fuel-menu.component';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { CurrencyModel } from '../models/currency.model';
//import { BusinessRulesEditComponent } from './business-rules-edit.component';

@Component({
  selector: 'app-fuel-pricing-summary',
  templateUrl: './fuel-pricing-summary.component.html',
  styleUrls: ['./fuel-pricing-summary.component.css']
})

export class FuelPricingSummaryComponent implements OnInit {
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),

  });

  fuel_pricing_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg1: string;
  msg: string;
  showSpin: boolean = false;


  fuelpricinglist: FuelPricingModel[];
  matfuelPricingList = new MatTableDataSource<FuelPricingModel>();
  selection = new SelectionModel<FuelPricingModel>(true, []);

  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  selectedTabName: string;
  reloadChildPage: boolean;
  totalRecordsText: string;
  totalRecords: number;
  pageSize: number = 15;
  totalPageNumber: number = 0;
  v: number = this._authService.getCurrentTimeNumber();
  startDate: Date;
  endDate: Date;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  pageFrom: string = "";
  sort_by: string;
  grantAccessToFuel: boolean = false;

  airportList: AirportModel[];
  selectedAirportList: AirportModel[];
  showAirportList: boolean = false;

  fuelsupplierList: FuelSupplierModel[];
  selectedFuelSupplierList: FuelSupplierModel[];
  showFuelSupplierList: boolean = false;

  intoplaneagentList: IntoPlaneAgentModel[];
  selectedIntoPlaneAgentList: IntoPlaneAgentModel[];
  showIntoPlaneAgentList: boolean = false;
  showCurrencyList: boolean = false;
  currentPageIndex: number = 0;

  //service: string = "service_class"; 
  showCountryList: boolean = false;
  selectedCountryList: CountryModel[];
  filteredCountryList: Observable<CountryModel[]>;
  selectedCountryCountText: string = "";
  countryList: CountryModel[];
  filteredAirportList: Observable<AirportModel[]>;
  filteredFuelSupplierList: Observable<FuelSupplierModel[]>;
  filteredIntoPlaneAgentList: Observable<IntoPlaneAgentModel[]>;
  selectedAirportCountText: string = "";
  selectedIntoPlaneAgentCountText: string = "";
  selectedFuelSupplierCountText: string = "";
  includeDisabled: boolean = false;
  
  includeDisabledRecords: boolean = false;
  
  getDataConditions : string = 'active';
  activeTrips: string;
  sortedData: FuelPricingModel[];
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;
  
  
  displayedColumn: string[] =/*'action1',*/['FuelPricingID', 'CountryName', 'ICAO', 'IATA', 'FuelSuppliershortName', 'IntoPlaneAgent', 'EffectiveDate', 'ExpiryDate', 'Qty', 'UnitofMeasure',
  'CurrencyID', 'BaseFuel', 'TaxesIncluded', 'InternalNotes', 'ClientNotes', 'ModifiedDate', 'action'];
  fromFuelPricingSummary: boolean;
  websiteEnvironment: string = "";
  fuelpricinglist2: FuelPricingModel[];
  sortedData2: FuelPricingModel[];
  isNoPrice: boolean = false;
  isExpired: boolean = false;
  fuelPriceCurrencyList:CurrencyModel[]=[];
  selectedFuelPriceCurrencyList:CurrencyModel[]=[]; 
  filteredfuelPriceCurrencyList: Observable<CountryModel[] | CurrencyModel[]>;
  selectedCurrencyCountText: string = "";
  fromFuelTask: boolean = false;
  selectedIcao: string = "";
  selectedFuelSupplier: string = "";
  selectedIntoPlaneAgent: string = "";
  isUpdated: boolean = false;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _fuelService: FuelService,
    private readonly _dialogService: DialogService, private _titleService: Title,
    @Optional() private readonly _dialogRef: MatDialogRef<FuelPricingSummaryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
  ) {

    if (data != null) {

      if (data.selectedIcao != undefined)
        this.selectedIcao = data.selectedIcao;
      if (data.selectedFuelSupplier != undefined)
        this.selectedFuelSupplier = data.selectedFuelSupplier;
      if (data.selectedIntoPlaneAgent != undefined)
        this.selectedIntoPlaneAgent = data.selectedIntoPlaneAgent;
      if (data.fromFuelTask != undefined)
        this.fromFuelTask = data.fromFuelTask;
      
    }
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToFuel = false;
    if (localStorage.getItem('up.fuel') == 'true') {
      this.grantAccessToFuel = true;
    }


    if (!this.grantAccessToFuel) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.errMsg = "";
    this.errMsg1 = "";
    this.msg = "";
    this.totalRecordsText = "";
    this.airportList = [];
    this.selectedAirportList = [];
    this.fuelsupplierList = [];
    this.intoplaneagentList = [];
    this.selectedFuelSupplierList = [];
    this.selectedIntoPlaneAgentList = [];
    this.selectedFuelPriceCurrencyList = [];
    this.countryList = [];
    this.selectedCountryList = [];
    this.sortedData = [];
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate());

    this.initControls();

    this.clickSubmit();

  }

  initControls() {
    this.fuel_pricing_form = this._formBuilder.group({
      country_search: [''],
      currency_search: [''],
      airportSearch: [''],
      fuelsupplier_search: '',
      intoplaneagent_search: '',
      startDate: [this.startDate],
      endDate: [null],
      sort_by: [this.sort_by],


      includeDisabledRecords: this.includeDisabledRecords,
      isExpired: this.isExpired,
      isNoPrice: this.isNoPrice,
      activeTrips: this.activeTrips
    });
  }

  get f() { return this.fuel_pricing_form.controls; }

  dateOnchange(dateType: string, e: any) {
    if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidStartDate = false;
          this.f.startDate.setErrors({ isValidDate: false });
        }
        else {
          this.isValidStartDate = true;
        }
      }
      else {
        this.isValidStartDate = true;
      }
    }

    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidEndDate = false;
          this.f.endDate.setErrors({ isValidDate: false })
        }
        else {
          this.isValidEndDate = true;
        }
      }
      else {
        this.isValidEndDate = true;
      }
    }

    this.clickSubmit();

  }

  sortByChange(e: any) {
    this.clickSubmit();
  }


  //country
  clickCountryFilter() {
    this.getCountryList();
  }

  getCountryList() {
    this.showCountryList = true;
    //this.showClientList = false;
    this.showAirportList = false;
    this.showCurrencyList = false;
    this.showFuelSupplierList = false;
    this.showIntoPlaneAgentList = false;


    this.countryList = [];
    let request = new FuelPricingModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }


    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }
    if (this.selectedFuelPriceCurrencyList.length == this.fuelPriceCurrencyList.length) {
      request.selectedFuelPriceCurrencyList = "";
    }
    else {
      this.selectedFuelPriceCurrencyList = this.selectedFuelPriceCurrencyList.filter(x => x.currencyID != 'all');
      request.selectedFuelPriceCurrencyList = Array.prototype.map.call(this.selectedFuelPriceCurrencyList, s => s.currencyID).toString();
    }
    request.isActive = !this.f.includeDisabledRecords.value;
    
    
    request.isExpired =  this.f.isExpired.value;
    request.isNoPrice =  this.f.isNoPrice.value; 
    
    this._fuelService.getCountryList<ResponseModel<CountryModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let c = new CountryModel();

          response.model.forEach(x => {
            c = new CountryModel();
            c.countryName = x.countryName
            c.countryGUID = x.countryGUID;
            c.selected = false;
            if (this.selectedCountryList.length > 0) {
              let f: CountryModel[];
              f = this.selectedCountryList.filter(y => y.countryGUID.toLowerCase() == x.countryGUID.toLowerCase());
              if (f.length > 0) {
                c.selected = true;
              }
            }
            this.countryList.push(c);
          });
          this.countryList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.countryName.localeCompare(b.countryName));
          c = new CountryModel();
          c.countryGUID = "all";
          c.countryName = "Select All";
          c.selected = false;
          this.countryList.unshift(c);
          this.filteredCountryList = this.f.country_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.countryName)),
            map(countryName => (countryName ? this._filterCountry(countryName) : this.countryList.slice())),
          );
          this.setCountryList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameCountry(country: CountryModel): string {
    return country && country.countryName ? country.countryName : '';
  }

  private _filterCountry(name: string): CountryModel[] {
    const filterValue = name.toLowerCase();

    return this.countryList.filter(option => option.countryName.toLowerCase().includes(filterValue));
  }

  checkCountryChange(e: any, item: CountryModel) {
    if (item.countryGUID == 'all') {
      this.countryList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.countryList.forEach(x => {
        if (item.countryGUID == x.countryGUID) {
          x.selected = e.checked;
        }
      });
    }
    this.setCountryList();
  }

  setCountryList() {
    this.selectedCountryList = this.countryList.filter(u => u.selected == true);
    this.selectedCountryCountText = "";
    if (this.countryList.length > 0) {
      if (this.selectedCountryList.length == this.countryList.length) {
        this.selectedCountryCountText = ": All";
      }
      else {
        if (this.selectedCountryList.length > 0)
          this.selectedCountryCountText = ": " + this.selectedCountryList.length.toString();
      }
    }
  }

  clickDoneCountry() {
    this.showCountryList = false;
    this.clickSubmit();
  }

  clickResetCountry() {
    this.countryList.forEach(x => {
      x.selected = false;
    });
    this.f.country_search.setValue("");
    this.setCountryList();
    this.clickSubmit();
  }
  //country end

  // airport
  clickAirportFilter() {
    this.getAirportList();
  }

  getAirportList() {

    this.showAirportList = true;
    this.showCountryList = false;
    this.showCurrencyList = false;
    this.showFuelSupplierList = false;
    this.showIntoPlaneAgentList = false;
    this.airportList = [];
    let request = new FuelPricingModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }

    if (this.selectedFuelPriceCurrencyList.length == this.fuelPriceCurrencyList.length) {
      request.selectedFuelPriceCurrencyList = "";
    }
    else {
      this.selectedFuelPriceCurrencyList = this.selectedFuelPriceCurrencyList.filter(x => x.currencyID != 'all');
      request.selectedFuelPriceCurrencyList = Array.prototype.map.call(this.selectedFuelPriceCurrencyList, s => s.currencyID).toString();
    }

    request.isExpired =  this.f.isExpired.value;
    request.isNoPrice =  this.f.isNoPrice.value; 

    request.isActive = !this.f.includeDisabledRecords.value;
    this._fuelService.getAirportList<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new AirportModel();

          response.model.forEach(x => {
            a = new AirportModel();
            a.icao = x.icao;
            a.airportID = x.airportID;
            a.selected = false;
            if (this.selectedAirportList.length > 0) {
              let f: AirportModel[];
              f = this.selectedAirportList.filter(y => y.airportID == x.airportID);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.airportList.push(a);
          });
          this.airportList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.icao.localeCompare(b.icao));
          a = new AirportModel();
          a.airportID = -1;
          a.icao = "Select All";
          a.selected = false;
          this.airportList.unshift(a);
          this.filteredAirportList = this.f.airportSearch.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.airportName)),
            map(airportName => (airportName ? this._filterAirport(airportName) : this.airportList.slice())),
          );
          this.setAirportList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameAirport(airport: AirportModel): string {
    return airport && airport.icao ? airport.icao : '';
  }

  private _filterAirport(name: string): AirportModel[] {
    const filterValue = name.toLowerCase();

    return this.airportList.filter(option => option.icao.toLowerCase().includes(filterValue));
  }

  checkAirportChange(e: any, item: AirportModel) {
    if (item.airportID == -1) {
      this.airportList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.airportList.forEach(x => {
        if (item.airportID == x.airportID) {
          x.selected = e.checked;
        }
      });
    }

    this.setAirportList();
  }

  setAirportList() {
    this.selectedAirportList = this.airportList.filter(u => u.selected == true);
    this.selectedAirportCountText = "";
    if (this.airportList.length > 0) {
      if (this.selectedAirportList.length == this.airportList.length) {
        this.selectedAirportCountText = ": All";
      }
      else {
        if (this.selectedAirportList.length > 0)
          this.selectedAirportCountText = ": " + this.selectedAirportList.length.toString();
      }
    }
  }

  clickDoneAirport() {
    this.showAirportList = false;
    this.clickSubmit();
  }

  clickResetAirport() {
    this.airportList.forEach(x => {
      x.selected = false;
    });
    this.f.airportSearch.setValue("");
    this.setAirportList();
    this.clickSubmit();
  }
  // airport end

  // fuelSupplier
  clickFuelSupplierFilter() {
    this.getFuelSupplierList();
  }

  getFuelSupplierList() {

    this.showFuelSupplierList = true;
    this.showAirportList = false;
    this.showCountryList = false;
    this.showCurrencyList = false;
    this.showIntoPlaneAgentList = false;

    this.fuelsupplierList = [];
    let request = new FuelPricingModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }

    if (this.selectedFuelPriceCurrencyList.length == this.fuelPriceCurrencyList.length) {
      request.selectedFuelPriceCurrencyList = "";
    }
    else {
      this.selectedFuelPriceCurrencyList = this.selectedFuelPriceCurrencyList.filter(x => x.currencyID != 'all');
      request.selectedFuelPriceCurrencyList = Array.prototype.map.call(this.selectedFuelPriceCurrencyList, s => s.currencyID).toString();
    }


    request.isActive = !this.f.includeDisabledRecords.value;
    
    request.isExpired =  this.f.isExpired.value;
    request.isNoPrice =  this.f.isNoPrice.value; 
    
  this.getDataConditions = 'active';

    this._fuelService.getFuelPricingSupplierList<ResponseModel<FuelSupplierModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new FuelSupplierModel();

          response.model.forEach(x => {
            a = new FuelSupplierModel();
            a.fuelSupplierGUID = x.fuelSupplierGUID;
            a.fuelSupplierShortname = x.fuelSupplierShortname;
            a.fuelSupplierName = x.fuelSupplierName;
            // a.selected = false;
            if (this.selectedFuelSupplierList.length > 0) {
              let f: FuelSupplierModel[];
              f = this.selectedFuelSupplierList.filter(y => y.fuelSupplierGUID == x.fuelSupplierGUID);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.fuelsupplierList.push(a);
          });
          this.fuelsupplierList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.fuelSupplierShortname.localeCompare(b.fuelSupplierShortname));
          a = new FuelSupplierModel();
          a.fuelSupplierGUID = "";// -1;
          a.fuelSupplierShortname = "Select All";
          a.fuelSupplierName = "Select All";
          a.selected = false;
          this.fuelsupplierList.unshift(a);
          this.filteredFuelSupplierList = this.f.fuelsupplier_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.fuelSupplierShortname)),
            map(fuelSupplierShortname => (fuelSupplierShortname ? this._filterFuelSupplier(fuelSupplierShortname) : this.fuelsupplierList.slice())),
          );
          this.setFuelSupplierList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameFuelSupplier(fs: FuelSupplierModel): string {
    return fs && fs.fuelSupplierShortname ? fs.fuelSupplierShortname : '';
  }

  private _filterFuelSupplier(name: string): FuelSupplierModel[] {
    const filterValue = name.toLowerCase();

    return this.fuelsupplierList.filter(option => option.fuelSupplierShortname.toLowerCase().includes(filterValue));
  }

  checkFuelSupplierChange(e: any, item: FuelSupplierModel) {
    if (item.fuelSupplierGUID == '') {
      this.fuelsupplierList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.fuelsupplierList.forEach(x => {
        if (item.fuelSupplierGUID == x.fuelSupplierGUID) {
          x.selected = e.checked;
        }
      });
    }

    this.setFuelSupplierList();
  }

  setFuelSupplierList() {
    this.selectedFuelSupplierList = this.fuelsupplierList.filter(u => u.selected == true);
    this.selectedFuelSupplierCountText = "";
    if (this.fuelsupplierList.length > 0) {
      if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
        this.selectedFuelSupplierCountText = ": All";
      }
      else {
        if (this.selectedFuelSupplierList.length > 0)
          this.selectedFuelSupplierCountText = ": " + this.selectedFuelSupplierList.length.toString();
      }
    }
  }

  clickDoneFuelSupplier() {
    this.showFuelSupplierList = false;
    this.clickSubmit();
  }

  clickResetFuelSupplier() {
    this.fuelsupplierList.forEach(x => {
      x.selected = false;
    });
    this.f.fuelsupplier_search.setValue("");
    this.setFuelSupplierList();
    this.clickSubmit();
  }
  // fuelSupplier end

  // IntoPlaneAgent
  clickIntoPlaneAgentFilter() {
    this.getIntoPlaneAgentList();
  }

  getIntoPlaneAgentList() {
    this.showIntoPlaneAgentList = true;
    this.showAirportList = false;
    this.showCountryList = false;
    this.showCurrencyList = false;
    this.showFuelSupplierList = false;
    this.intoplaneagentList = [];
    let request = new FuelPricingModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }

    if (this.selectedFuelPriceCurrencyList.length == this.fuelPriceCurrencyList.length) {
      request.selectedFuelPriceCurrencyList = "";
    }
    else {
      this.selectedFuelPriceCurrencyList = this.selectedFuelPriceCurrencyList.filter(x => x.currencyID != 'all');
      request.selectedFuelPriceCurrencyList = Array.prototype.map.call(this.selectedFuelPriceCurrencyList, s => s.currencyID).toString();
    }


    request.isActive = !this.f.includeDisabledRecords.value;
    
    request.isExpired =  this.f.isExpired.value;
    request.isNoPrice =  this.f.isNoPrice.value; 

    this._fuelService.getIntoPlaneAgentList<ResponseModel<IntoPlaneAgentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new IntoPlaneAgentModel();

          response.model.forEach(x => {
            a = new IntoPlaneAgentModel();
            a.intoPlaneAgent = x.intoPlaneAgent;
            //a.fuelSupplierShortname = x.fuelSupplierShortname;
            // a.selected = false;
            if (this.selectedIntoPlaneAgentList.length > 0) {
              let f: IntoPlaneAgentModel[];
              f = this.selectedIntoPlaneAgentList.filter(y => y.intoPlaneAgent == x.intoPlaneAgent);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.intoplaneagentList.push(a);
          });
          this.intoplaneagentList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.intoPlaneAgent.localeCompare(b.intoPlaneAgent));
          a = new IntoPlaneAgentModel();
          a.intoPlaneAgent = "";// -1;
          a.intoPlaneAgent = "Select All";
          a.selected = false;
          this.intoplaneagentList.unshift(a);
          this.filteredIntoPlaneAgentList = this.f.intoplaneagent_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.intoPlaneAgent)),
            map(intoPlaneAgent => (intoPlaneAgent ? this._filterIntoPlaneAgent(intoPlaneAgent) : this.intoplaneagentList.slice())),
          );
          this.setIntoPlaneAgentList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameIntoPlaneAgent(intoplaneagent: IntoPlaneAgentModel): string {
    return intoplaneagent && intoplaneagent.intoPlaneAgent ? intoplaneagent.intoPlaneAgent : '';
  }

  private _filterIntoPlaneAgent(name: string): IntoPlaneAgentModel[] {
    const filterValue = name.toLowerCase();

    return this.intoplaneagentList.filter(option => option.intoPlaneAgent.toLowerCase().includes(filterValue));
  }

  checkIntoPlaneAgentChange(e: any, item: IntoPlaneAgentModel) {
    if (item.intoPlaneAgent == 'Select All') {
      this.intoplaneagentList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.intoplaneagentList.forEach(x => {
        if (item.intoPlaneAgent == x.intoPlaneAgent) {
          x.selected = e.checked;
        }
      });
    }

    this.setIntoPlaneAgentList();
  }

  setIntoPlaneAgentList() {
    this.selectedIntoPlaneAgentList = this.intoplaneagentList.filter(u => u.selected == true);
    this.selectedIntoPlaneAgentCountText = "";
    if (this.intoplaneagentList.length > 0) {
      if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
        this.selectedIntoPlaneAgentCountText = ": All";
      }
      else {
        if (this.selectedIntoPlaneAgentList.length > 0)
          this.selectedIntoPlaneAgentCountText = ": " + this.selectedIntoPlaneAgentList.length.toString();
      }
    }
  }

  clickDoneIntoPlaneAgent() {
    this.showIntoPlaneAgentList = false;
    this.clickSubmit();
  }

  clickResetIntoPlaneAgent() {
    this.intoplaneagentList.forEach(x => {
      x.selected = false;
    });
    this.f.intoplaneagent_search.setValue("");
    this.setIntoPlaneAgentList();
    this.clickSubmit();
  }
  // IntoPlaneAgent end

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.fuelpricinglist.length; //this.matfuelPricingList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.fuelpricinglist);// this.matfuelPricingList.data);
  }

  checkBox(row: any) {
    this.selection.toggle(row);

  }

  includeDisabledRecordsOnChange(event: any) {
    //let x = event.target.checked;
    //if (this.userType == "internal") {
    //if (this.f.customer_select.value == "") {
    //  this.errMsg = "Please select a client";
    //  return;
    //}
    // }
    // this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.clickSubmit();
  }
  includeDisabledReactiveTripsOnChangecordsOnChange(event: any) {
    this.activeTrips = '';
    //this.totalRecordsText = '';
    //this.totalRecords = 0;
    //this.currentPageIndex = 0;
    //this.totalPageNumber = 0;
    //this.clickSubmit();
  }

  clickSubmit() {
    this.submitted = true;
    this.errMsg = "";
    if (this.fuel_pricing_form.invalid || this.errMsg != "") {
      return;
    }

    //if (this.showClientList) {
    //  this.getClientList();
    //}
    //if (this.showAircraftList) {
    //  this.getAircraftList();
    //}
    if (this.showCountryList) {
      this.getCountryList();
    }
    if (this.showAirportList) {
      this.getAirportList();
    }
    if (this.showFuelSupplierList) {
      this.getFuelSupplierList();
    }
    if (this.showIntoPlaneAgentList) {
      this.getIntoPlaneAgentList();
    }
    if (this.showCurrencyList) {
      this.clickCurrencyFilter();
    }
    //if (this.showPersonList) {
    //  this.getTripPersonList();
    //}
    //if (this.showVendorList) {
    //  this.getVendorList();
    //}

    this.getData();
  }

  getData(isInit: boolean = false) {
    this.showSpin = true;
    let request = new FuelPricingModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }

    if (this.selectedFuelPriceCurrencyList.length == this.fuelPriceCurrencyList.length) {
      request.selectedFuelPriceCurrencyList = "";
    }
    else {
      this.selectedFuelPriceCurrencyList = this.selectedFuelPriceCurrencyList.filter(x => x.currencyID != 'all');
      request.selectedFuelPriceCurrencyList = Array.prototype.map.call(this.selectedFuelPriceCurrencyList, s => s.currencyID).toString();
    }

    request.isActive = true;
    if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      request.isActive = !this.f.includeDisabledRecords.value;
    }

    if (this.fromFuelTask) {
      request.selectedICAOList = this.selectedIcao;
      request.selectedFuelSupplierList = this.selectedFuelSupplier;
      request.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgent;
    }


    //if (this.selectedCountryList.length == this.orgVendorList.length) {
    //  request.selectedVendorGUIDList = "";
    //}
    //else {
    //this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
    //request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    //}


    //request.FuelPricingID = 0;
    //if (this.f.FuelPricingID.value != null && this.f.brID.value != "")
    //  request.FuelPricingID = Number(this.f.brID.value);
    if (this.paginator == undefined) {
      request.pageIndex = 0;
      request.pageSize = 25;
    }
    else {
      request.pageIndex = this.paginator.pageIndex;
      request.pageSize = this.paginator.pageSize;
    }
    request.isExpired =  this.f.isExpired.value;
    request.isNoPrice =  this.f.isNoPrice.value; 
    
    this._fuelService.getFuelPricesByConditions<ResponseModel<FuelPricingModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.fuelpricinglist = response.model;
          this.sortedData = this.fuelpricinglist.slice();
          if (response.model.length > 0) {
            if (this.fuelpricinglist != null) {
              this.totalRecords = this.fuelpricinglist[0].totalRecords;
              this.totalRecordsText = this.totalRecords + " total records"
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
      this.showSpin = false;
    });
  }

  clickEdit(event: any, fp: FuelPricingModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    let fuelPricingId = 0;
    let dialogTitle = "Add";
    if (fp != null) {
      fuelPricingId = fp.fuelPricingID;
      dialogTitle = "Edit";
      dialogConfig.autoFocus = false;

    }
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { fuelPricingId: fuelPricingId, dialogTitle: dialogTitle };


    const dialogRef = this._dialog.open(FuelPricingEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //if (this.sortedData.length == 0)
        //this.f.brID.setValue(result);
        this.isUpdated = true;
        this.getData();
      }
    });
    //  let navigationExtras: NavigationExtras = {
    //    queryParams: {
    //      "businessRulesID": br.businessRulesID        

    //    }
    //  };
    //this._router.navigate(['/business-rules/business-rules-edit'], navigationExtras);
  }

  clickReset() {
    //this.showLegTypeList = false;
    //this.showClientList = false;
    this.showCountryList = false;
    this.showCurrencyList = false;
    this.showAirportList = false;
    this.showFuelSupplierList = false;
    this.showIntoPlaneAgentList = false;

    this.includeDisabledRecords = false;
    this.selection.clear();
    //this.showPersonList = false;
    //this.showVendorList = false;
    //this.showAircraftList = false;
    //this.showStatusList = false;
    //this.showServiceList = false;
    //this.showExclusionList = false;

    //this.selectedLegTypeList = [];
    //this.selectedLegTypeCountText = "";
    //this.selectedClientList = [];
    //this.selectedClientCountText = "";
    //this.selectedAircraftList = [];
    //this.selectedAircraftCountText = "";
    //this.selectedPaxList = [];
    //this.selectedCrewList = [];
    //this.selectedPersonCountText = "";
    this.selectedCountryList = [];
    this.selectedAirportList = [];
    this.selectedFuelSupplierList = [];
    this.selectedIntoPlaneAgentList = [];
    this.selectedCountryCountText = "";
    this.selectedAirportCountText = "";
    this.selectedFuelSupplierCountText = "";
    this.selectedIntoPlaneAgentCountText = "";
    this.selectedFuelPriceCurrencyList = [];
    this.selectedCurrencyCountText = "";
    //this.selectedVendorList = [];
    //this.selectedVendorCountText = "";
    //this.selectedServiceClassList = [];
    //this.selectedServiceTypeList = [];
    //this.selectedServicesCountText = "";
    //this.selectedApprovalStatusList = [];
    //this.selectedAprrovalStatusCountText = "";
    this.f.startDate.setValue(null);
    this.f.endDate.setValue(null);
    //this.businessRulesList = [];
    //this.avoidLocation = false;
    //this.excludeFromHomebase = false;
    //this.f.brID.setValue(null);
    // this.selectedExclusionCountText = "";
    this.sortedData = [];

    document.querySelectorAll('input[name="getData_type"]').forEach((radio: any) => {
      radio.checked = false;
    });

    document.querySelectorAll('input[name="getData_type"]').forEach((radio: any, index: number) => {
      radio.checked = index === 0; // Select the first option
    });

    
    this.f.includeDisabledRecords.setValue(false);
    this.f.isExpired.setValue(false);
    this.f.isNoPrice.setValue(false);
    this.getDataConditions = 'active';

    //this.clickSubmit();

    this.initControls();
    this.getData();
  }

  sortData(sort: Sort) {
    //'FuelPricingID', 'CountryName', 'ICAO', 'FuelSuppliershortName', 
    const data = this.fuelpricinglist.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'FuelPricingID':
          return compare(a.fuelPricingID, b.fuelPricingID, isAsc);
        case 'CountryName':
          return compare(a.countryName == null ? 'All' : a.countryName, b.countryName == null ? 'All' : b.countryName, isAsc);
        case 'ICAO':
          return compare(a.icao == null ? 'All' : a.icao, b.icao == null ? 'All' : b.icao, isAsc);
        case 'FuelSuppliershortName':
          return compare(a.fuelSupplierName == null ? 'All' : a.fuelSupplierName, b.fuelSupplierName == null ? 'All' : b.fuelSupplierName, isAsc);
        case 'IntoPlaneAgent':
          return compare(a.intoPlaneAgent == null ? 'All' : a.intoPlaneAgent, b.intoPlaneAgent == null ? 'All' : b.intoPlaneAgent, isAsc);
        case 'ModifiedDate':
          return compare(a.modifiedDate == null ? 'All' : a.modifiedDate, b.modifiedDate == null ? 'All' : b.modifiedDate, isAsc);
        case 'ExpiryDate':
          return compare(a.expiryDate == null ? 'All' : a.expiryDate, b.expiryDate == null ? 'All' : b.expiryDate, isAsc);
        case 'EffectiveDate':
          return compare(a.effectiveDate == null ? 'All' : a.effectiveDate, b.effectiveDate == null ? 'All' : b.effectiveDate, isAsc);

        default:
          return 0;
      }
    });

    function compare(a: number | string | Date | boolean, b: number | string | Date | boolean, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    //this.currentPageIndex = e.pageIndex;
    //if (this.pageSize != e.pageSize) {
    //  this.totalRecords = 0;
    //  this.totalRecordsText = "";
    //  this.totalPageNumber = 0;
    //  this.pageSize = e.pageSize;
    //this.request.pageSize = this.pageSize;
    //this.request.totalRecords = this.totalRecords;
    //}
    // this.request.pageIndex = this.currentPageIndex;
    this.clickSubmit();

  }

  importFuelPricing() {
    let s = "";
    //  const dialogConfig = new MatDialogConfig();
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.width = "76em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    this.fromFuelPricingSummary = true;
    dialogConfig.hasBackdrop = false;



    dialogConfig.data = {
      dialogTitle: s, fromFuelPricingSummary: this.fromFuelPricingSummary
    };


    const dialogRef = this._dialog.open(FuelpricingComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }

  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;


    //  this._dialogRef.close();

    this._dialogRef.close(this.isUpdated);
  }

  clickRoute(routePage: string) {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(FuelMenuComponent, config);   
  }

 
  
  exportToExcel(data: any[], filename: string): void {
    
   

    let wb=new Workbook();
    let ws =wb.addWorksheet("MissingTaxes") 
    let  header = [ "ID", "Country", "ICAO", "IATA", "Fuel Supplier", "Into Plane Agent", "Effective Date", "Expiry Date", "Quantity", "Unit of Measure", 
    "Currency", "Base Fuel", "Taxes Included", "Internal Notes", "Client Notes", "Modified Date" ];
    ws.addRow(header);
    data.forEach( sd => { 
      ws.addRow([sd.fuelPricingID, sd.countryName, sd.icao , sd.iata, sd.fuelSupplierName , sd.intoPlaneAgent, sd.effectiveDate, sd.expiryDate, sd.qty , sd.unitOfMeasure, 
        sd.currencyID, sd.baseFuel.toFixed(4),  sd.taxesIncluded==true?'Yes':'No' , sd.internalNotes, sd.clientNotes, sd.modifiedDate ]); 
    } ) 
    wb.xlsx.writeBuffer().then((data)=>{
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob,"FuelPrices.xlsx");
    });
  }

  exportData( ) {
    this.showSpin = true;
    let request = new FuelPricingModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }

    if (this.selectedFuelPriceCurrencyList.length == this.fuelPriceCurrencyList.length) {
      request.selectedFuelPriceCurrencyList = "";
    }
    else {
      this.selectedFuelPriceCurrencyList = this.selectedFuelPriceCurrencyList.filter(x => x.currencyID != 'all');
      request.selectedFuelPriceCurrencyList = Array.prototype.map.call(this.selectedFuelPriceCurrencyList, s => s.currencyID).toString();
    }

    request.isActive = true;
    if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      request.isActive = !this.f.includeDisabledRecords.value;
    }
 
      request.pageIndex = 0;
      request.pageSize = this.totalRecords; 
      
      request.isExpired =  this.f.isExpired.value;
      request.isNoPrice =  this.f.isNoPrice.value; 

    this._fuelService.getFuelPricesByConditions<ResponseModel<FuelPricingModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.fuelpricinglist2 = response.model;
          this.sortedData2 = this.fuelpricinglist2.slice();
          if (response.model.length > 0) {
            if (this.fuelpricinglist2 != null) {
              this.exportToExcel(this.sortedData2,'FuelPrices');

            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
      this.showSpin = false;
    });
  }

  getDataConditionsOnChange(e: any){
    switch (e.target.value) {
      case 'active':
        this.f.includeDisabledRecords.setValue(false);
        this.f.isExpired.setValue(false);
        this.f.isNoPrice.setValue(false);
        this.getData();
        break;
      case 'disabled':
        this.f.includeDisabledRecords.setValue(true);
        this.f.isExpired.setValue(false);
        this.f.isNoPrice.setValue(false);
        this.getData();
        break;
      case 'expired':
        this.f.includeDisabledRecords.setValue(false);
        this.f.isExpired.setValue(true);
        this.f.isNoPrice.setValue(false);
        this.getData();
        break;
      case 'noPrice':
        this.f.includeDisabledRecords.setValue(true);
        this.f.isExpired.setValue(false);
        this.f.isNoPrice.setValue(true);
        this.getData();
        break;
    }

  }

  clickCurrencyFilter(){
    
    let request = new FuelPricingModel();// new FuelSupplierModel();
    request.effectiveDate = "";
    this.fuelPriceCurrencyList = [];    
    
    this.showCountryList = false;
    //this.showClientList = false;
    this.showAirportList = false; 
    this.showFuelSupplierList = false;
    this.showIntoPlaneAgentList = false;

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).toString();
    }

    if (this.selectedFuelPriceCurrencyList.length == this.fuelPriceCurrencyList.length) {
      request.selectedFuelPriceCurrencyList = "";
    }
    else {
      this.selectedFuelPriceCurrencyList = this.selectedFuelPriceCurrencyList.filter(x => x.currencyID != 'all');
      request.selectedFuelPriceCurrencyList = Array.prototype.map.call(this.selectedFuelPriceCurrencyList, s => s.currencyID).toString();
    }

    request.isActive = !this.f.includeDisabledRecords.value;
    
    request.isExpired =  this.f.isExpired.value;
    request.isNoPrice =  this.f.isNoPrice.value; 

    this._fuelService.getFuelPriceCurrencyList<ResponseModel<CurrencyModel[]>>(request).subscribe(response => {
      // if (response != null && response.code == "200") {
      //   if (response.model.length > 0) {
      //     this.fuelPriceCurrencyList = response.model;
      //     this.showCurrencyList = true;
      //   }
      // }
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let c = new CurrencyModel();
          this.showCurrencyList = true;

          response.model.forEach(x => {
            c = new CurrencyModel();
            c.currencyID = x.currencyID
            c.lk_currencyID = x.lk_currencyID;
            c.selected = false;
            if (this.selectedFuelPriceCurrencyList.length > 0) {
              let f: CurrencyModel[];
              f = this.selectedFuelPriceCurrencyList.filter(y => y.currencyID == x.currencyID);
              if (f.length > 0) {
                c.selected = true;
              }
            }
            this.fuelPriceCurrencyList.push(c);
          });
          this.fuelPriceCurrencyList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.currencyID.localeCompare(b.currencyID));
          c = new CurrencyModel();
          // c.lk_currencyID = "all";
          c.lk_currencyID = 0;
          c.currencyID = "Select All";
          c.selected = false;
          this.fuelPriceCurrencyList.unshift(c);
          this.filteredfuelPriceCurrencyList = this.f.currency_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.currencyID)),
            map(currencyID => (currencyID ? this._filterCurrency(currencyID) : this.fuelPriceCurrencyList.slice())),
          );
          this.setCurrencyList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    });
  } 
  
  private _filterCurrency(name: string): CurrencyModel[] {
    const filterValue = name.toLowerCase();

    return this.fuelPriceCurrencyList.filter(option => option.currencyID.toLowerCase().includes(filterValue));
  }
  setCurrencyList() {
    this.selectedFuelPriceCurrencyList = this.fuelPriceCurrencyList.filter(u => u.selected == true);
    this.selectedCurrencyCountText = "";
    if (this.fuelPriceCurrencyList.length > 0) {
      if (this.selectedFuelPriceCurrencyList.length == this.fuelPriceCurrencyList.length) {
        this.selectedCurrencyCountText = ": All";
      }
      else {
        if (this.selectedFuelPriceCurrencyList.length > 0)
          this.selectedCurrencyCountText = ": " + this.selectedFuelPriceCurrencyList.length.toString();
      }
    }
  }

  checkCurrencyChange(e: any, item: CurrencyModel) {
    if (item.currencyID == 'Select All') {
      this.fuelPriceCurrencyList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.fuelPriceCurrencyList.forEach(x => {
        if (item.currencyID == x.currencyID) {
          x.selected = e.checked;
        }
      });
    }
    this.setCurrencyList();
  }


  clickDoneCurrency() {
    this.showCurrencyList = false;
    this.clickSubmit();
  }

  clickResetCurrency() {
    this.fuelPriceCurrencyList.forEach(x => {
      x.selected = false;
    });
    this.f.currency_search.setValue("");
    this.setCurrencyList();
    this.clickSubmit();
  }
  //Currency  end



}



