import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerModel } from '../models/customer.model';
import { DialogService } from '../services/dialog.service';
import { CustomerGroupService } from '../services/customer-group-service';
import { CustomerGroupModel } from '../models/customer-group.model';
import { ResponseModel } from '../models/response.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { AccountGroupAuditDialogComponent } from './account-group-audit-dialog.component';

@Component({
  selector: 'app-account-group-selection-dialog',
  templateUrl: './account-group-selection-dialog.component.html',
  styleUrls: ['./account-group-selection-dialog.component.css']
})

export class AccountGroupSelectionDialogComponent implements OnInit {
  customerGroupGUID: string;
  account_group_selection_form: UntypedFormGroup;

  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = false;

  customerList: CustomerModel[] = [];
  orgCustomerList: CustomerModel[] = [];
  selectedCustomerList: CustomerModel[] = [];
  recordList: CustomerModel[] = [];
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  displayedColumn: string[] = ['customer_name', 'action'];
  grantAccessToManageAccountGrouping: boolean = false;
  showClientList: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  customerGroupName: string = "";
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _customerGroupService: CustomerGroupService, private readonly _route: ActivatedRoute,
    private readonly _dialog: MatDialog, private readonly _dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialogRef: MatDialogRef<AccountGroupSelectionDialogComponent>) {
    this.customerGroupGUID = _data.customerGroupGUID;

  }

  getAllData(): Observable<any[]> {
    
    let request = new CustomerGroupModel();
    request.customerGroupGUID=this.customerGroupGUID   
    let getClientGrpResponse = this._customerGroupService.getCustomerGroupBycgrpId<ResponseModel<CustomerGroupModel>>(request);
    return forkJoin([getClientGrpResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToManageAccountGrouping = false;
    if (localStorage.getItem('up.mGrp')!=null && localStorage.getItem('up.mGrp') == 'true') {
      this.grantAccessToManageAccountGrouping = true;
    }   

    if (!this.grantAccessToManageAccountGrouping) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    
    this.customerList = [];
    
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let obj = new CustomerGroupModel();
          obj = responses[0].model;
          this.customerGroupName = obj.customerGroupName;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();
      this.getSelectedClientList();
      this.showSpin = false;

    });

    
  }

  initControls() {
    this.account_group_selection_form = this._formBuilder.group({
      client_search: [''] //,
    });
  }

  get f() { return this.account_group_selection_form.controls; }

  getSelectedClientList() {
    this.recordList = [];
    let req = new CustomerGroupModel();
    req.customerGroupGUID = this.customerGroupGUID
    this._customerGroupService.getSelectedCustomerListBycgrpId<ResponseModel<CustomerModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.recordList = [];
        this.recordList = response.model;
      }
      else {

      }

    })

  }

  // client
  clickAdd(e: any) {
    this.showClientList = true;
    this.getClientList();

  }

  getClientList() {
    var root = this;
    this.showClientList = true; 
    this.customerList = [];
    this.orgCustomerList = [];
    let req = new CustomerGroupModel();
    req.customerGroupGUID=this.customerGroupGUID
    this._customerGroupService.getAvailableCustomerListBycgrpId<ResponseModel<CustomerModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.customerList = [];
        this.orgCustomerList = [];
        if (response.model.length > 0) {
          let c = new CustomerModel();
          c.customerName = "Select All";
          c.customerGUID = "all";
          c.selected = false;
          this.customerList.push(c);
          response.model.forEach(x => {
            c = new CustomerModel();
            c.customerName = x.customerName;
            c.customerGUID = x.customerGUID;
            c.selected = false;
            this.customerList.push(c);
          });
          this.orgCustomerList = this.customerList;
          this.getCustomerListBykeyword(this.f.client_search.value);
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })

  }

  checkClientChange(e: any, item: CustomerModel) {
    if (item.customerGUID == 'all') {
      this.customerList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.customerList.forEach(x => {
        if (item.customerGUID.toLowerCase() == x.customerGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setClientList();
    
  }

  setClientList() {

    let excludeallclist: CustomerModel[]
    excludeallclist = this.customerList.filter(x => x.customerGUID != 'all' && x.selected == false);
    if (excludeallclist.length == 0) {
      this.customerList.find(x => x.customerGUID == 'all').selected = true;
    }
    else {
      this.customerList.find(x => x.customerGUID == 'all').selected = false;
    }
    this.selectedCustomerList = this.customerList.filter(u => u.selected == true);
    
  }

  clientSearchChange(e: any) {
    let cname: string = e.target.value;
    if (cname != "") {
      cname = cname.toLowerCase();
    }

    this.getCustomerListBykeyword(cname);

  }

  getCustomerListBykeyword(cname: string) {

    this.customerList = this.orgCustomerList.filter(option => option.customerName.toLowerCase().includes(cname) || option.customerGUID == "all");
    if (this.selectedCustomerList.length > 0) {
      this.selectedCustomerList.forEach(x => {
        let i: number;
        i = this.customerList.findIndex(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
        if (i == -1) {
          let c = new CustomerModel();
          c.customerName = x.customerName;
          c.customerGUID = x.customerGUID;
          c.selected = true;
          this.customerList.push(c);
        }
      });
    }
    this.setClientList();
    if (this.selectedCustomerList.length > 0 && this.selectedCustomerList.length != this.customerList.length) {
      let data: CustomerModel[] = [];
      data = this.customerList;
      this.customerList = [];

      data.forEach(x => {
        if (x.customerGUID == "all") {
          this.customerList.push(x);
        }
        else {
          if (x.selected) {
            this.customerList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.customerGUID != "all") {
          this.customerList.push(x);
        }
      });
    }

  }

  clickDoneClient() {
    this.showClientList = false;
    this.saveClientSelection();
  }

  saveClientSelection() {
    let request = new CustomerGroupModel();
    if (this.selectedCustomerList.length == this.customerList.length) {
      this.selectedCustomerList = this.selectedCustomerList.filter(x=>x.customerGUID.toLowerCase()!="all");
      request.selectedCustomerGUIDList = Array.prototype.map.call(this.selectedCustomerList, s => s.customerGUID).toString()
    }
    else {
      request.selectedCustomerGUIDList = Array.prototype.map.call(this.selectedCustomerList, s => s.customerGUID).toString();
    }
    request.customerGroupGUID = this.customerGroupGUID;
    if (request.selectedCustomerGUIDList != "") {
      this._customerGroupService.addCustomerToCustomerGroup<ResponseModel<boolean>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model == true) {
            this.isModified = true;
            this.showSuccessMsg = true;
            this.getSelectedClientList();
            setTimeout(() => {
              this.showSuccessMsg = false;
              
            }, 1000);

          }
        }

      })
    }  
  }

  clickResetClient() {
    this.f.client_search.setValue('');
    this.selectedCustomerList = [];
    this.customerList = this.orgCustomerList;
    this.customerList.forEach(x => {
      x.selected = false;
    });
    this.setClientList();
    this.showClientList = false;
  }
  // client end

  clickRemove($event, item: CustomerModel) {
    this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the client - "+item.customerName+"?");
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        let request = new CustomerGroupModel();
        request.customerGUID = item.customerGUID;
        request.customerGroupGUID = this.customerGroupGUID;
        if (request.selectedCustomerGUIDList != "") {
          this._customerGroupService.removeCustomerFromCustomerGroup<ResponseModel<boolean>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              if (response.model == true) {
                this.isModified = true;
                this.getSelectedClientList();
              }
            }

          })
        }  
      }
    })
  }

  clickAudit() {
    let s = "";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "58em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, customerGroupGUID: this.customerGroupGUID };

    const dialogRef = this._dialog.open(AccountGroupAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getData();
      }
    });
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

}
