import { FuelClientMarkupModel } from "./fuel-client-markup.model";
import { FuelCommissionsModel } from "./fuel-commissions.model";
import { FuelExchangeRateMarkupModel } from "./fuel-exchange-rate-markup.model";
import { GroundStopModel } from "./ground-stop.model";
import { TaxesAndFeesModel } from "./taxesandfees.model";

export class FuelTaskModel {
  fuelPricingID: number;
  icao: string;
  iata: string;
  cityName: string;
  clientName: string;
  fuelSupplierName: string;
  fuelSupplierGUID: string;
  intoPlaneAgent: string;
  effectiveDate: string;
  expiryDate: string;
  minQty: number;
  maxQty: number;
  qty: string;
  baseFuel: number;
  priceInUSD: number;
  taxesIncluded: boolean;
  internalNotes: string;
  clientNotes: string;
  modifiedBy: string;
  modifiedDate: string;
  unitOfMeasure: string;
  currencyID: string;
  clientMarkup: FuelClientMarkupModel;
  taxesAndFees: TaxesAndFeesModel[];
  groundStopTaskGUID: string;
  arrivalDateTime: string;
  customerGUID: string;
  fuelClientMarkupGUID: string;
  farTypeID: number;
  showExpired: boolean;
  isExpired: boolean;
  selected: boolean;
  markupAmount: number;
  markupAmountList: string;
  taxAmount: number;
  totalPrice: number;
  taxesAndFeesXML: string;
  defaultMarkup: string;
  quoteNote: string;
  airportID: number;
  totalPriceDomestic: number;
  taxAmountDomestic: number;
  taxesAndFeesList: string;
  fuelQuoteGUID: string;
  groundStopTaskDocumentGUID: string;
  isActive: boolean;
  fuelCommissions: FuelCommissionsModel;
  fuelCommissionAmount: number;
  exchangeRateMarkup: FuelExchangeRateMarkupModel;
  exchangeRateMarkupAmount: number;
  clientPrice: number;
  reportID: string;
  gsInfo: GroundStopModel;
}
