import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { TermsOfUseDialogComponent } from './terms-of-use-dialog.component';

@Component({
    selector: 'app-privacy-policy-dialog',
    templateUrl: './privacy-policy-dialog.component.html',
    styleUrls: ['./privacy-policy-dialog.component.css']
})
/** privacy-policy.dialog component*/
export class PrivacyPolicyDialogComponent {
    /** privacy-policy.dialog ctor */
    constructor(private readonly _dialog: MatDialog) {

  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  openTerms() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    this._dialog.open(TermsOfUseDialogComponent, dialogConfig);

  }
}
