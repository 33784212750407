<div style="background-color:#f8f8f8;height:100%">
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle style="display: inline-flex;width:100%">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>Notes</h5></div>
    <div *ngIf="airportNoteID!=0" style="margin-top:.45em;margin-left:.75em">
      <label>{{noteTypeDescription}}</label>
    </div>
  </div>
  <div style="margin-top:0em">
    <form *ngIf="airport_notes_edit_form" [formGroup]="airport_notes_edit_form" (ngSubmit)="clickSave()">
      <div style="margin-left: .5em;margin-top:0em;margin-right:0.5em;">
        <div style="display:inline-flex;width:100%;justify-content:space-between">
          <div style="display:inline-flex">
            <div *ngIf="airportNoteID==0" class="form-group mr-3">
              <label for="note_type" style="font-size:small">Note Type</label>
              <div  style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="note_type" class="form-control form-control-sm dropdown" (change)="updateNoteType()">
                  <option *ngFor="let note of noteTypeList" [ngValue]="note.noteTypeID">{{note.noteTypeDescription}}</option>
                </select>
              </div>
             

          </div>
            <div *ngIf="requiresYN" class="mr-5 ml-2">
              <div>
                <label style="font-size:small">Requires {{noteTypeDescription}}</label>
              </div>
              <div style="margin-top:-.75em;margin-left:-.3em">
                <mat-radio-group [(ngModel)]="isRequired" [ngModelOptions]="{standalone: true}">
                  <mat-radio-button style="transform:scale(.75);font-size:large" [value]=true>Yes </mat-radio-button>
                  <mat-radio-button style="transform:scale(.75);font-size:large" [value]=false>No</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="d-inline-flex" *ngIf="selectedNoteTypeId==60 || selectedNoteTypeId==61">
              <div class="form-group mr-2">
                <label style="font-size:small">Lead</label>
                <input type="text" formControlName="lead_time" placeholder="?d?h?m" class="form-control inputBox" style="margin-top:-.35em;width:5em" />
                <div *ngIf="submitted && f.lead_time.errors">
                  <div *ngIf="f.lead_time.errors.pattern" style="background-color:#f9d2de; margin-left:-10em; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Invalid lead time. format: ?d?h?m</div>
                </div>
              </div>
              <div class="form-group mr-2">
                <label style="font-size:small;margin-top:-1.25em">Expected <br />Approval</label>
                <input type="text" formControlName="approval" placeholder="?d?h?m" class="form-control inputBox" style="margin-top:-.35em;width:5em" />
                <div *ngIf="submitted && f.approval.errors">
                  <div *ngIf="f.approval.errors.pattern" style="background-color:#f9d2de; margin-left:-10em; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Invalid approval time. format: ?d?h?m</div>
                </div>
              </div>
              <div *ngIf="selectedNoteTypeId==60" class="form-group mr-1">
                <label style="font-size:small">Valid Time</label>
                <input type="text" formControlName="valid_time_start" placeholder="?h?m" class="form-control inputBox" style="margin-top:-.35em;width:5em" />
               
              </div>
              <div *ngIf="selectedNoteTypeId==60" class="form-group mr-2">
                <label style="font-size:small;margin-left:-.5em">(-/+)</label>
                <input type="text" formControlName="valid_time_end" placeholder="h?m" class="form-control inputBox" style="margin-top:-.35em;width:5em" />
                <div *ngIf="submitted && (f.valid_time_end.errors || f.valid_time_start.errors)">
                  <div *ngIf="f.valid_time_end.errors.pattern || f.valid_time_start.errors.pattern" style="background-color:#f9d2de; margin-left:-10em; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Invalid time. format: h?m</div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top:-1em">
            <div *ngIf="businessRuleCount" class="text-center alert-success rounded p-1 mr-2 mb-2" style="font-size:small">
              <div>Business Rules {{businessRuleCount}}</div>
            </div>
            <div *ngIf="advisoryCount" class="text-center alert-danger rounded p-1 mr-2" style="font-size:small">
              <div>Advisories {{advisoryCount}}</div>
            </div>
          </div>
        </div>
        <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display:inline-block; white-space:pre-wrap;margin-top:.5em"><label style="font-size: small">{{errMsg}}</label></div>


        <div style="display:flex;margin-top:0em;background-color:white;padding-left:0.5em;padding-right:0.5em;">



          <div style="display:flex">
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="airport_note" style="font-size:small">Notes</label>
              <div style="margin-top:-0.5em;">
                <textarea spellcheck="true" formControlName="airport_note" class="form-control" style="width:40em; height:12em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>
              </div>
            </div>
            <mat-divider [vertical]="true" class="mr-2 my-2"></mat-divider>
            <div>
              <div class="form-group" style="margin-left: 0em; margin-bottom:0em">
                <label for="revisionDate" style="font-size:small">Revision Date</label>
                <div style="margin-top:-0.5em;">
                  <input type="date" class="form-control form-control-sm inputBox" formControlName="revisionDate"
                         style=" width: 7em" />
                </div>
              </div>
              <div class="form-group mr-2">
                <label style="font-size:small">Remarks</label>
                <textarea (mousedown)="$event.stopPropagation()" formControlName="remarks" class="form-control inputBox" style="width:20em; height:6.5em; margin-top:-.35em; padding:0em 0em 0em 0em"></textarea>
              </div>
            </div>
            <!--<div *ngIf="airportNoteID!=0" class="form-group" style="margin-left: 0em; margin-top:0em; margin-bottom:0em;width:10em">
        <label for="note_status" style="font-size:small">Disable Record</label>
        <div style="margin-top:-0.5em;">
          <select *ngIf="f" formControlName="note_status" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color: lightgrey">
            <option value="1" selected>No</option>
            <option value="0">Yes</option>
          </select>
        </div>

        <div style="display:flex;margin-top:1em">
          <div>
            <div class="form- group" style="margin-left: 0em; margin-bottom:0em">
              <label for="modifiedBy" style="font-size:small">Modified by</label>
              <div style="margin-top:-0.5em;">
                <input type="text" formControlName="modifiedBy" readonly class="form-control" style="padding-left:0em;width:10em;height:1.5625em;font-size:small" />
              </div>
            </div>
            <div class="form-group" style="margin-left: 0em; margin-bottom:0em">
              <label for="modifiedDate" style="font-size:small">Modified Date</label>
              <div style="margin-top:-0.5em;">
                <input type="text" formControlName="modifiedDate" readonly class="form-control" style="padding-left: 0em; width: 10em; height: 1.5625em; font-size: small" />
              </div>
            </div>
          </div>
        </div>
      </div>-->
          </div>

        </div>

        <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>

      </div>
    </form>

  </div>
  <div class="d-inline-flex">
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em">
      <button mat-raised-button class="btn-high" (click)="clickSave()">Save</button>&nbsp;
      <!--<button mat-raised-button *ngIf="airportNoteID!=0" class="btn-high" (click)="clickAddNew()">Add New Record</button>&nbsp;-->
      <button mat-raised-button class="btn-med" (click)="clickClose()">Close</button>

    </div>
    <app-success-message *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>
  </div>
  <div  *ngIf="airportNoteID!=0" class="d-inline-flex float-right">
    <form *ngIf="airport_notes_edit_form" [formGroup]="airport_notes_edit_form">
      <div class="form-group form-inline mt-2">
        <label style="font-size: small; margin-right: .5em">Disable Record </label>
        <select *ngIf="f" formControlName="note_status" style="margin-right:1.5em" class="form-control form-control-sm dropdown">
          <option value="1" selected>No</option>
          <option value="0">Yes</option>
        </select>
      </div>
    </form>
    <div>
      <div *ngIf="modifiedBy!=''" style="font-size:small">
        <label style="width:7em;text-align:right;margin-right:.5em">Modified By: </label>{{modifiedBy}}
      </div>
      <div *ngIf="modifiedDate!=''" style="font-size:small;margin-top:-.75em">
        <label style="width: 7em; text-align: right; margin-right: .5em ">Modified Date: </label>{{modifiedDate}}
      </div>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>





