import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, Inject, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable } from 'rxjs';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { DocumentCategoryModel } from '../models/document-category.model';
import { DocumentModel } from '../models/document.model';
import { FlightBriefModel } from '../models/flight-brief.model';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { ResponseModel } from '../models/response.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { AuthenticateService } from '../services/authenticate.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopService } from '../services/ground-stop.service';

export interface FlightBriefSections {
  sectionName: string;
  selected: boolean;
}

@Component({
    selector: 'app-flight-brief-documents',
  templateUrl: './flight-brief-documents.component.html',
  styleUrls: ['./flight-brief-documents.component.css'],
  encapsulation: ViewEncapsulation.None
})

/** flight-brief-preferences component*/
export class FlightBriefDocumentsComponent {
/** flight-brief-preferences ctor */

  flight_brief_sections_form: UntypedFormGroup;
  documentList: DocumentModel[];
  documentCategoryList: DocumentCategoryModel[];
  flightBriefSectionList: FlightBriefSections[];
  taskNoteList: ServiceTypeModel[];
  @Input() briefFormat: string;
  @Input() gstId: string;
  @Input() tripCodeGUID: string;
  @Input() selectedStops: GroundStopModel[];
  @Input() tripCode: string;
  sections: FlightBriefSections[] = [{ sectionName: 'Important Note', selected: false }];
  displayedColumn: string[] = ['documentType', 'documentName', 'check', 'sortOrderNo'];
  selection = new SelectionModel<DocumentModel>(true, []);
  selection2 = new SelectionModel<FlightBriefSections>(true, []);
  showSpin: boolean = false;
  isLoading: boolean = false;
  isLoadingPDF: boolean = false;
  errMsg: string = "";
  showSuccessMsg: boolean = false;
  showSuccessMsg2: boolean = false;
  selectedDocuments: DocumentModel[];
  reportName: string = "Flight Brief";

  constructor(private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder, private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    private readonly _groundStopService: GroundStopService, @Inject(MAT_DIALOG_DATA) private _data: any, private el: ElementRef, private readonly _dialogRef: MatDialogRef<FlightBriefDocumentsComponent>, ) {
    this.gstId = _data.gstId;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.tripCode = _data.tripCode;
    this.selectedStops = _data.selectedStops;
    this.briefFormat = _data.briefFormat;
    if (_data.serviceTypeID == 79)
      this.reportName = "Trip Brief";
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.documentCategoryList = [];
    this.showSpin = true;
    this.initControls();
    this.getData();

  }
  initControls() {
    this.flight_brief_sections_form = this._formBuilder.group({
      flight_brief_list: '',
      cover_note: '',
      summary_to: '',
      summary_address:''
    });
  }

  get f() { return this.flight_brief_sections_form.controls; }

  getAllData(): Observable<any[]> {

    let request = new FlightBriefModel();

    request.groundStopTaskGUID = this.gstId;
    request.tripCodeGUID = this.tripCodeGUID;
    request.selectedGroundStopList = [];
    this.selectedStops.forEach(v => {
      request.selectedGroundStopList.push(v.groundStopGUID);
    });    

    let sectionResponse = this._groundStopService.getFlightBriefSectionsBygstId(this.gstId);
    let docResponse = this._groundStopAdvancedService.getGroundStopTaskDocumentForFlightBriefBygstId<ResponseModel<DocumentModel[]>>(request);
    let noteResponse = this._groundStopService.getFlightBriefTaskNoteBygstId<ResponseModel<ServiceTypeModel[]>>(request);
    return forkJoin([sectionResponse, docResponse, noteResponse]);
  }


  getData() {

    this.getAllData().subscribe(responses => {
      if (responses[0] != null && responses[0].code == "200") {
        this.flightBriefSectionList = responses[0].model;
        this.flightBriefSectionList.forEach(v => {
          v.selected == true ? this.selection2.toggle(v) : null;
        })
        
      }
      if (responses[1] != null && responses[1].code=="200"){
        if (responses[1].model.length > 0) {
          this.documentList = responses[1].model;
          this.documentList = this.documentList.filter(v => v.documentType != this.reportName);
          let tempCategetory: string = "";
          this.documentList.forEach(x => {
            if (tempCategetory != x.documentCategory) {
              tempCategetory = x.documentCategory;
              let y = new DocumentCategoryModel();
              y.documentCategory = x.documentCategory;
              y.documentList = this.documentList.filter(a => a.documentCategory == tempCategetory);
              let n = y.documentList.filter(d => d.selected == true).length;
              if (n > 0) {
                y.documentSelectedCountDisplay = "(Selected " + n.toString() + ")";
              }
              this.documentCategoryList.push(y);
            }
            x.selected == true ? this.selection.toggle(x) : null;
          });
          this.selectedDocuments = this.documentList.filter(v => v.selected == true);
          if (this.documentCategoryList.length > 0)
            this.documentCategoryList[0].panelOpenState = true;


        }
      }

      if (responses[2] != null && responses[2].code == "200") {
        this.taskNoteList = responses[2].model;
      }

      this.showSpin = false;
    });

  }

  checkFlightBriefSection(e: any, item: FlightBriefSections) {
    this._authService.updateAccessTime();
    e ? this.selection2.toggle(item) : null;
    item.selected = e.checked;
  }


  checkDocumentChange(e: any, item: DocumentModel, i:number) {
    this._authService.updateAccessTime();
    //this.errMsg = "";
    e ? this.selection.toggle(item) : null;
    item.selected = e.checked;
    let n = 0;
    let currentDoc: DocumentModel[];
    currentDoc = this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentList;
    currentDoc.forEach(y => {
      if (y.selected == true) {
        n += 1;
      }
    });

    if (n > 0) {
      this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentSelectedCountDisplay = "(Selected " + n.toString() + ")";
    }
    else {
      this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentSelectedCountDisplay = "";
    }

    if (e.checked) {
      var control;
      control = this.el.nativeElement.querySelector('[id="sortOrder' + i.toString() + '"]');
      setTimeout(() => {
        control.focus();  
      }, 50);
    }
  }

  setSortOrder(e: any, item: DocumentModel) {
    this._authService.updateAccessTime();
    item.sortOrderNo = Number(e.target.value);
  }

  nextStep(currentStep: number) {
    this._authService.updateAccessTime();
    this.documentCategoryList[currentStep].panelOpenState = false;
    if (currentStep < (this.documentCategoryList.length - 1)) {
      let next = currentStep + 1;
      this.documentCategoryList[next].panelOpenState = true;

    }

  }
  prevStep(currentStep: number) {
    this._authService.updateAccessTime();
    this.documentCategoryList[currentStep].panelOpenState = false;
    let prev = currentStep - 1;
    this.documentCategoryList[prev].panelOpenState = true;
  }

  openAll() {
    this._authService.updateAccessTime();
    this.documentCategoryList.forEach(x => x.panelOpenState = true);
  }

  closeAll() {
    this._authService.updateAccessTime();
    this.documentCategoryList.forEach(x => x.panelOpenState = false);
  }

  clickPreviewDocument(e: any, item: DocumentModel) {
    this._authService.updateAccessTime();
    let request = new DocumentModel();
    request.documentCategory = item.documentCategory;
    request.documentGUID = item.documentGUID;
    this._groundStopService.getTripDocumentById<ResponseModel<DocumentModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let doc = new DocumentModel();
          doc = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
          let file = new Blob([byteArray], { type: doc.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
        //else {
        //  this.msg = "No documents returned";
        //}
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }

    })

  }

  clickSave(showSuccess:boolean) {
    this.errMsg = "";
    this.isLoading = true;
    let selectedSections = this.flightBriefSectionList.filter(v => v.selected == true);
    let selectedTasks = this.taskNoteList.filter(v => v.selected == true);
    let flightBriefSectionsIds = Array.prototype.map.call(selectedSections, s => s.flightBriefReportSectionID).toString();
    let includedDocuments = this.documentList.filter(v => v.selected == true);
    let serviceIds = Array.prototype.map.call(selectedTasks, s => s.serviceTypeID).toString();
    let request = new FlightBriefModel();
    request.groundStopTaskGUID = this.gstId;
    request.includedSections = flightBriefSectionsIds;
    request.includedTaskNotes = serviceIds;
    request.includedDocuments = "";
    request.flightBriefFormat = this.briefFormat;
    includedDocuments.forEach(v => {
      if (request.includedDocuments != "")
        request.includedDocuments += ",";
      request.includedDocuments += v.documentGUID + "|" + v.sortOrderNo;
    });
    request.docIsFlightBrief = false;
    this._groundStopAdvancedService.saveFlightBriefSettings<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.selectedDocuments = includedDocuments;
          if (showSuccess) {
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;

            }, 1000);
          }
        }
        else {
          if (response.code == "401")
            this._authService.signOut;
          else
            this.errMsg = "An error has occurred."
        }
      }
      this.isLoading = false;
    });
  }

  buildPDF() {
    this.clickSave(false);
    this.isLoadingPDF = true;
    let request = new FlightBriefModel();
    request.docFormat = "existing";
    request.flightBriefDocument = new DocumentModel();
    let selectDocumentList = this.documentList.filter(v => v.selected == true);
    selectDocumentList.sort((a, b) => a.sortOrderNo - b.sortOrderNo);
    request.flightBriefDocument = selectDocumentList[0];
    request.groundStopTaskDocuments = selectDocumentList.slice(1);
    //request.groundStopTaskDocuments = this.documentList.filter(v => v.selected == true);
    this._groundStopAdvancedService.compileFlightBrief<ResponseModel<FlightBriefModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let obj = response.model.flightBriefDocument;

          let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
          let file = new Blob([byteArray], { type: "application/pdf" });
          
            let taskDocument = new GroundStopTaskDocumentModel();
            taskDocument.documentName = "OpsDocuments.pdf";
            taskDocument.fileExtension = "pdf";
            taskDocument.documentSizeInKB = Math.round(file.size / 1024);
            taskDocument.mimeType = "application/pdf";
            taskDocument.bFile = obj.bFile;
            taskDocument.groundStopTaskGUID = this.gstId;
            taskDocument.tripCode = this.tripCode;
            this._groundStopAdvancedService.insertGroundStopTaskDocument<ResponseModel<number>>(taskDocument).subscribe(response => {
              if (response != null) {
                if (response.code == "200") {
                  this.isLoadingPDF = false;
                  this.showSuccessMsg2 = true;
                  setTimeout(() => {
                    this.showSuccessMsg2 = false;
                  }, 1000);
                }
                else {
                  if (response.code == "401") {
                    this._authService.logout;
                  }
                  else {
                    this.errMsg = response.message;
                  }
                }
              }
              else
                this.isLoadingPDF = false;
            });
          
        }
        else {
          if (response.code == "401") {
            this._authService.logout;
          }
          else {
            this.errMsg = response.message;
          }
        }
      }
      this.isLoadingPDF = false;
    });
  }

  clickClose() {
    this._dialogRef.close();
  }

}
