<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<div class="greyBack" style="margin-top: 0em;padding:.5em" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div>
    <div>
      <div
        style="border-radius: 0.25em; padding: 0.5em 0.5em 0em 1em; width: 100%; display: inline-block; max-height: 5em;">
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex; flex-direction: column;">
            <div style="display: inline-flex;">
              <i class="material-icons-outlined" style="margin-top: 0.2em;">account_circle</i>
              <h3>My Account</h3>
            </div>
            <label for="username">Username: {{username}}</label>
          </div>
          <div style="display: flex;">
            <button mat-icon-button mat-dialog-close>
              <i class="material-icons" style="font-size: x-large;">close </i>
            </button>
          </div>
        </div>
        <div>
          <div class="form-group" style=" margin-bottom: 0.2em;">
          </div>
        </div>
        <div *ngIf="msg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label
            style="color:forestgreen; font-size: small">&nbsp;{{msg}}</label></div>
        <div *ngIf="errMsg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label
            style="color:red; font-size: small">{{errMsg}}</label></div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; background-color: white; margin: 1em">
      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 17em; background-color: #f2faff !important; color: Black !important" (click)="openPersonalInfo()">
          <i class="material-icons-outlined" style="float: left;">photo_camera_front</i>
          Personal Info
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Update details for your basic information, including your contact phone numbers and email addresses.​
        </div>
      </div>
      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 17em; background-color: #f2faff !important; color: Black !important" (click)="openChangePassword()">
          <i class="material-symbols-outlined" style="float: left;">key</i>
          Change Password
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Update credentials and change the password linked to your account to bolster security across both web and
          mobile platforms.​
        </div>
      </div>
      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 17em; background-color: #f2faff !important; color: Black !important"
          (click)="openPasswordRecovery()">
          <i class="material-icons-outlined" style="float: left;">lock_open</i>
          Password Recovery
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Password recovery via email and mobile enables users to regain account access by receiving a recovery email or
          text message with a secure link or instructions for ​
          resetting the password.​
        </div>
      </div>
      <!-- <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" 
          style="margin-right: 1em; height: 3em; width: 15em; text-wrap: wrap; line-height: 1em !important;" (click)="openMFA()">
          <i class="material-icons-outlined" style="float: left;">gpp_maybe</i>
          Multi Factor Authentication (MFA)
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Multi-factor authentication (MFA) demands two authentication factors: the password and a one-time code sent
          via SMS or email. This adds an extra layer of security, even if the password is compromised, enhancing
          protection against unauthorized access.​
        </div>
      </div> 
      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 17em; background-color: #f2faff !important; color: Black !important"
          (click)="openMFA()">
          <i class="material-symbols-outlined" style="float: left;">gpp_maybe</i>
          Two-factor Authentication
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Set up MFA.
        </div>
      </div>
      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 17em; background-color: #f2faff !important; color: Black !important"
          (click)="openPushNotifications()">
          <i class="material-symbols-outlined" style="float: left;">notifications_active</i>
          Push Notification Alerts
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Set up Push Notifications.
        </div>
      </div>-->
      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; width: 17em; background-color: #f2faff !important; color: Black !important"
          (click)="openSignature()">
          <i class="material-symbols-outlined" style="float: left;">signature</i>
          Signature
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Create a signature for your emails sent from FPC.
        </div>
      </div>
    </div>
  </div>
</div>
