<div class="divLeft" style=" margin-top: 0em;" [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div>
    <div>
      <div
        style="border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; ">
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex; flex-direction: column;">
            <div style="display: inline-flex;">
              <i class="material-icons-outlined" style="margin-top: 0.2em; margin-right: 0.4em;">engineering</i>
              <h3>Maintenance</h3>
            </div>

          </div>
          <div style="display: flex;">
            <button mat-icon-button *ngIf="isDialog"  (click)="clickRouteNew('maintenance/maintenance-dashboard','')">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>    
            <button mat-icon-button *ngIf="isDialog"  mat-dialog-close><i class="material-icons" style="font-size: x-large;">close </i></button>
          </div>
        </div>
        <div> 
        </div>
        
      </div>
    </div>


    <div style="display: flex; flex-direction: column; background-color: white;">
      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em;  width: 15em; background-color: #f2faff !important; color: Black !important" 
          (click)="openCustomerProfileSetup()">
          <i class="material-icons-outlined" style="float: left;">assignment_turned_in</i>
          <span>Manage Client Profiles</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Activate new accounts, suspend services, configure accounting profile.​
        </div>
      </div>

      <div style="border-top: 0.0625em solid lightgrey; display: flex; flex-direction: column;">
        <div style="font-weight: bold; padding-left: 1em;">
          Internal Use Only
        </div>
        <div style="display: inline-flex; padding: 1em;">
          <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em;  width: 15em; background-color: #f2faff !important; color: Black !important"  (click)="openInternalAdminServicesComponent()" >
            <i class="material-symbols-outlined" style="float: left;">Settings_account_box</i>
            <span style="margin-left: -1.2em;"> Admin Services</span>
          </button>
          <div style="font-weight: 300; font-size: small; width: 30em;">
            Restricted - Manage FPC internal user profiles OPS, Fuel, Travel, and Accounting.
          </div>
        </div>

        <div style="display: inline-flex; padding: 1em;">
          <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em;  width: 15em; background-color: #f2faff !important; color: Black !important"  (click)="openThirdPartyVendorComponent()" >
            <i class="material-symbols-outlined" style="float: left;">store</i>
            <span style="margin-left: -1.2em;"> Third Party Vendors</span>
          </button>
          <div style="font-weight: 300; font-size: small; width: 30em;">
            Create vendor, hotel and fuel Supplier accounts, delineating roles and furnishing essential details such as communication channels, contacts, location information and required documents.​
          </div>
        </div>
  
  
        <div style="display: inline-flex; padding: 1em;">
          <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em;  width: 15em; background-color: #f2faff !important; color: Black !important"  (click)="openGlobalSettingsDashboardComponent()"  >
            <i class="material-icons-outlined" style="float: left;">tune</i>
            <span  style="margin-left: -1.3em;">FPC Global Settings</span>
          </button>
          <div style="font-weight: 300; font-size: small; width: 30em;">
           Configuration of message center settings, business rules and various database settings and APIs.​
          </div>
        </div>
  
  
        <div style="display: inline-flex; padding: 1em;">
          <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em;  width: 15em; background-color: #f2faff !important; color: Black !important"  (click)="openFpcPicklistDataComponent()"  >
            <i class="material-symbols-outlined" style="float: left;">fact_check</i>
            <span  style="margin-left: -2.5em;">FPC Picklist Data</span>
          </button>
          <div style="font-weight: 300; font-size: small; width: 30em;">
            Manage and tailor Picklist Options for numerous predefined fields utilized in FPC Forms.
          </div>
        </div>
      </div>
      

      
    </div>
  </div>
</div>