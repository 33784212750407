import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CustomerProfileSetupEditComponent } from "./customer-profile-setup-edit.component";
import { AuthenticateService } from "../services/authenticate.service";
import { GroundStopClientService } from "../services/ground-stop-client.service";
import { ResponseModel } from "../models/response.model";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-customer-instructions',
  templateUrl: './customer-instructions.component.html',
  styleUrls: ['./customer-instructions.component.css']
})

export class CustomerInstructionsComponent implements OnInit {
  isDialog: boolean = true;
  selectedClientName: string = "";
  selectedClientGUID: string = "";
  nonStandardClientInstructions: string = "";
  standardClientInstructions: string = "";

  constructor(@Optional() private readonly _dialogRef: MatDialogRef<CustomerInstructionsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _dialog: MatDialog,
    private readonly _authService: AuthenticateService,
    private readonly _groundStopClientService: GroundStopClientService,
    private sanitized: DomSanitizer) {

  }

  ngOnInit(): void {
    if (this.data) {
      this.selectedClientName = this.data.clientName;
      this.selectedClientGUID = this.data.clientGUID;
      this.standardClientInstructions = this.data.clientInstructions;
    }

    this._groundStopClientService.getInternalNonStandardProcedures<ResponseModel<string>>(this.selectedClientGUID).subscribe(response => {
      if(response != null && response.code == "200") {
        this.nonStandardClientInstructions = response.model;
      }
    })
  }

  openCustomerProfile() {
    const config = new MatDialogConfig();

    config.panelClass = "custom-dialog-container3"
    config.height = "50em";
    config.width = "90em";
    config.maxWidth = "90em";
    config.maxHeight = "50em";
    config.position = { left: "10px", top: "140px" };
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.selectedClientGUID, customerName: this.selectedClientName, userType: 'customer' };
    this._dialog.open(CustomerProfileSetupEditComponent, config);
  }

  getHTML(data: any) {
    if(data === undefined || data === '' || data === null) {
      return '';
    }

    return this.sanitized.bypassSecurityTrustHtml(data);
  }
}