export class VendorDocumentModel {
  vendorGUID: string;
  vendorID: number;
  vendorDocumentGUID: string;
  vendorDocumentID: number;
  vendorDocumentTypeID: number;
  vendorDocumentTypeDescription: string;
  documentTypeGUIDList: string;
  documentTypeList: string;
  documentTypeGUID: string;
  vendorServiceDocumentTypeGUIDList: string;
  vendorServiceDocumentTypeList: string;
  documentName: string;
  fileExtension: string;
  effectiveDate: string;
  expirationDate: string;
  documentSizeInKB: number;
  fileSize: number;
  bFile: string;
  mimeType: string;
  hasDocument: boolean;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  filterBy: string;
  textSearch: string;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  showToClient: boolean;
}
