<div [ngClass]="isDialog? 'greyBack':''" *ngIf="grantAccessAircraftProfiles"
  style="margin-top:0em; padding-top:0em; display:flex; height: 100%;" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle [cdkDragDisabled]="!isDialog">
  <div>
    <div style="display: inline-flex; justify-content: space-between; width: 100%;">
      <div style="margin-left:1em">
        <div style="display: inline-flex;">
          <i class="material-symbols-outlined" style="margin-top: 0.3em;">
            flight
          </i>
          <div style="margin-left: 0.5em; margin-bottom: 0.5em;">
            <h3 style="margin-bottom: 0em;">Manage Aircraft Profile</h3>
            <h5 *ngIf="userType === 'internal'" style="color: #1976e6;">{{selectedClientName}}</h5>
            <div *ngIf="userType === 'customer' && grantAccessToAccountGrouping" style="display: inline-flex; margin-top: 0.5em;">
              <div style="font-size: small;">Select Client</div>
              <select class="form-control" style="width:14em;height:1.5em;font-size: small; margin-left: 0.5em; padding: 0em" (change)="filterAircraftByClient($event)">
                <option *ngFor="let customer of customerList">{{customer.customerName}}</option>
              </select>
            </div>

          </div>
        </div>
      </div>
      <div *ngIf="isDialog">
        <button mat-button class="btn-low" (click)="openSelection('Acct Setup')">Client Profile</button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;"
                (click)="openSelection('Client Information')">
          Client Information
        </button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;"
                (click)="openSelection('Crew, PAX & Support')">
          Crew, PAX & Support
        </button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;" (click)="openSelection('Admin Services')">
          Admin
          Services
        </button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;">Feedback</button>
      </div>
      <div style="display: flex;">
        <button mat-icon-button *ngIf="isDialog" (click)="openClientProfileSetupComponent()">
          <i class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i>
        </button>
        <button mat-icon-button *ngIf="isDialog" (click)="clickRouteNew('aircraft/manage-aircraft-profile')">
          <i class="material-icons" style="font-size:x-large;  ">open_in_new</i>
        </button>
        <button mat-icon-button *ngIf="isDialog" mat-dialog-close>
          <i class="material-icons"
             style="font-size: x-large;">close </i>
        </button>
      </div>
    </div>
    <div style="background-color: white; padding: 0.5em; height: 26em;">
      <div *ngIf="msg!=''" class="inline-block"><label style="color:forestgreen">&nbsp;{{msg}}</label></div>
      <div *ngIf="errMsg!=''" class="inline-block"><label style="color: #c00000">{{errMsg}}</label></div>

      <div>
        <div *ngIf="userType=='internal'">
          <mat-slide-toggle style="transform:scale(.8);font-size:medium" color="primary"
                            (click)="toggleIncludeDisabledRecords($event)">Show Disabled Records</mat-slide-toggle>
        </div>
        <table mat-table [dataSource]="recordList" class="example-container">
          <tr>
            <ng-container matColumnDef="registration">
              <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                <div style="margin-top:-0.25em;">Registration</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.registration}}</td>
            </ng-container>
            <ng-container matColumnDef="aircraft_type">
              <th mat-header-cell *matHeaderCellDef style="width:15%; border-bottom:none">
                <div style="margin-top:-0.25em;">Aircraft Type</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.aircraftTypeDescription}}</td>
            </ng-container>
            <ng-container matColumnDef="client_name">
              <th [hidden]="userType!='internal'" mat-header-cell *matHeaderCellDef
                  style="width:10%; border-bottom:none">
                <div style="margin-top:-0.25em;">Client Name</div>
              </th>
              <td [hidden]="userType!='internal'" mat-cell *matCellDef="let element">{{element.customerName}}</td>
            </ng-container>

            <ng-container matColumnDef="mtow">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:0em;">MTOW</div>
                <div>Lbs</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.mtow | number}}</td>
            </ng-container>
            <ng-container matColumnDef="height">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:0em;">Height</div>
                <div>Ft</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.height}}</td>
            </ng-container>
            <ng-container matColumnDef="wingspan">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:0em;">Wingspan</div>
                <div>Ft</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.wingspan}}</td>
            </ng-container>
            <ng-container matColumnDef="homebase">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:-0.25em;">Homebase</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.homebaseAirport}}</td>
            </ng-container>
            <ng-container matColumnDef="has_preferences">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:-0.25em;">Preference Load</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.hasPreferences" class="material-icons"
                   style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="has_notes">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:-0.25em;">Notes</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.hasNotes" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="has_documents">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:-0.25em;">Documents</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.documentCount==0? '': element.documentCount}}</td>
            </ng-container>
            <ng-container matColumnDef="has_owner_lessee">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:0em;">
                  Aircraft Owner
                </div>
                <div>Lessee Info</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.hasOwner" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="has_operator">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:0em;">Aircraft Operator</div>
                <div>Info</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.hasOperator" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <ng-container matColumnDef="has_trips">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:0em;">Active</div>
                <div>Trips</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <i *ngIf="element.hasTrips" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
              </td>
            </ng-container>
            <!-- <ng-container matColumnDef="remarks">
          <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
            <div style="margin-top:-0.25em;">Remarks</div>
          </th>
          <td mat-cell *matCellDef="let element">{{element.remarks}}</td>
        </ng-container>-->
            <ng-container matColumnDef="disabled">
              <th [hidden]="userType!='internal'" mat-header-cell *matHeaderCellDef
                  style="width:5%; border-bottom:none">
                <div style="margin-top:-0.25em;">Disabled</div>
              </th>
              <td [hidden]="userType!='internal'" mat-cell *matCellDef="let element">
                {{
element.isActive? '&nbsp;':
                'Yes'
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div>Action</div>
                <div style="margin-top:0em">
                  <a *ngIf="userType=='internal'" mat-button class="btn-high grid"
                     style="align-self:center" (click)="clickAdd($event, null);">Add</a>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <a mat-button class="btn-low grid" style="align-self:center">
                  <span *ngIf="userType=='internal'">Edit</span>
                  <span *ngIf="userType!='internal'">View</span>
                </a>
              </td>
            </ng-container>
          </tr>

          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickEdit($event, row);"></tr>
        </table>
      </div>
      
      <div>
        <mat-paginator style="border: none" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords"
                       [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize" [pageIndex]="currentPageIndex"
                       [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
        </mat-paginator>
      </div>
      <app-spin *ngIf="showSpin"></app-spin>
    </div>
  </div>
</div>
