import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
//import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
//import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { TripModel } from '../models/trip.model';
import { CustomerCreditStatusModel } from '../models/customer-credit-status.model';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { UserModel } from '../models/user.model';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { AircraftModel } from '../models/aircraft.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { AirportModel } from '../models/airport.model';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { DialogService } from '../services/dialog.service';
import { IconLegendDialogComponent } from '../common-utility/icon-legend-dialog.component';
import { CustomerGroupService } from '../services/customer-group-service';
import { CustomerGroupModel } from '../models/customer-group.model';
//import { PersonModel } from '../models/person.model';
//import { CustomerPersonEditDialogComponent } from './customer-person-edit-dialog.component';
//import { PersonService } from '../services/person.service';
//import { PersonClassModel } from '../models/person-class.model';

@Component({
  selector: 'app-trip-quick-search-control-client',
  templateUrl: './trip-quick-search-control-client.component.html',
  styleUrls: ['./trip-quick-search-control-client.component.css'],
  //encapsulation: ViewEncapsulation.None

})

export class TripQuickSearchControlClientComponent implements OnInit {
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  trip_quick_search_control_client_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg1: string;
  msg: string;
  
  showSpin: boolean = true;
  
  includeClosedBilledRecords: boolean;
  selectedTabName: string;
  reloadChildPage: boolean;
  totalRecordsText: string;
  v: number = this._authService.getCurrentTimeNumber();
  request: TripModel;
 
  startDate: Date;
  endDate: Date;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  pageFrom: string = "";
  sort_by: string;
  
  registrationList: AircraftModel[];
  selectedRegistrationList: AircraftModel[];
  showAircraftList: boolean = false;
  airportList: AirportModel[];
  selectedAirportList: AirportModel[];
  showAirportList: boolean = false;
  showPersonList: boolean = false;
  pilotList: GroundStopManifestModel[];
  selectedPilotList: GroundStopManifestModel[];
  crewList: GroundStopManifestModel[];
  selectedCrewList: GroundStopManifestModel[];
  paxList: GroundStopManifestModel[];
  selectedPaxList: GroundStopManifestModel[];
  person_role: string;
  selectedRegistrationCountText: string="";
  selectedAirportCountText: string = "";
  selectedPersonCountText: string = "";
  upPII: boolean = false;
  selectedCustomerList: CustomerModel[]=[];
  customerList: CustomerModel[] = [];
  grantAccessToAccountGrouping: boolean = false;
  showClientList: boolean = false;
  selectedClientCountText: string = "";
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopClientService: GroundStopClientService, private readonly _identityService: IdentityService,
    private readonly _dialogService: DialogService, private readonly _customerGroupService: CustomerGroupService
  ) {

  }

  getAllData(): Observable<any[]> {
    this.registrationList = [];
    let req = new TripModel();
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    let getACResponse;
    if (localStorage.getItem('trip.aclist') != null && localStorage.getItem('trip.aclist') != "") {
      getACResponse = this._groundStopClientService.getTripRegistrationListBycIduId(req);
    }
    else {
      getACResponse = of(null);
    }
    let getAPResponse;
    if (localStorage.getItem('trip.aplist') != null && localStorage.getItem('trip.aplist') != "") {
      getAPResponse = this._groundStopClientService.getTripAirportListBycId(req);
    }
    else {
      getAPResponse = of(null);
    }

    let getPersonRespone;
    req.personRole = this.person_role;
    if (localStorage.getItem('trip.plist') != null && localStorage.getItem('trip.plist') != "") {
      getPersonRespone = this._groundStopClientService.getTripPersonListBycIduId(req);
    }
    else {
      getPersonRespone = of(null);
    }

    let getClientResponse;    
    if (this.grantAccessToAccountGrouping) {
      if (localStorage.getItem('trip.clist') != null && localStorage.getItem('trip.clist') != "") {
        getClientResponse = this._groundStopClientService.getTripClientListBycGrpId(req);
      }
      else {
        getClientResponse = of(null);
      }
    }
    else {
      getClientResponse = of(null);
    }
    return forkJoin([getACResponse, getAPResponse, getPersonRespone, getClientResponse ]);
  }
 
  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
     
      return;
    }
    else {
      this.upPII = true;
    }
    
    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }

    this._route.queryParams.subscribe(params => {
      this.pageFrom = params["pf"];

    });
    if (this.pageFrom == null || this.pageFrom == undefined) {
      this.pageFrom = "";
    }
    if (localStorage.getItem('trip.filterTime') != null) {
      var lastStoreTime = Number(localStorage.getItem('trip.filterTime'));
      var now = Date.now();
      if ((now - lastStoreTime) >= (480 * 60000)) // 8 hr
      {
        localStorage.removeItem('trip.sd');
        localStorage.removeItem('trip.ed');
        localStorage.removeItem('trip.aclist');
        localStorage.removeItem('trip.aplist');
        localStorage.removeItem('trip.prole');
        localStorage.removeItem('trip.plist');
        localStorage.removeItem('trip.tco');
        localStorage.removeItem('trip.ord');
        localStorage.removeItem('trip.clist');
      }
    }

    //if (this.pageFrom == "") {
    //  //localStorage.removeItem('trip.cid');
    //  localStorage.removeItem('trip.sd');
    //  localStorage.removeItem('trip.ed');
    //  localStorage.removeItem('trip.aclist');
    //  localStorage.removeItem('trip.aplist');
    //  localStorage.removeItem('trip.prole');
    //  localStorage.removeItem('trip.plist');
    //  localStorage.removeItem('trip.tco');    
    //  localStorage.removeItem('trip.ord');
    //  localStorage.removeItem('trip.clist');
    //}

    this.showSpin = true;
    
    this.errMsg = "";
    this.errMsg1 = "";
    this.msg = "";
    this.includeClosedBilledRecords = false;
    
    this.selectedTabName = 'tripcode_tactical';
    this.reloadChildPage = false;
    
    this.totalRecordsText = "";
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.endDate = null;//new Date();
    //this.endDate.setDate(this.endDate.getDate() + 28);
    this.registrationList=[];
    this.selectedRegistrationList=[];   
    this.airportList=[];
    this.selectedAirportList = [];
    this.pilotList=[];
    this.selectedPilotList=[];
    this.crewList=[];
    this.selectedCrewList=[];
    this.paxList=[];
    this.selectedPaxList = [];
    this.person_role = "pilot";
    if (this.selectedTabName != 'aircraft') {
      this.sort_by = "departureDate desc";
    }
    else {
      this.sort_by = "registration asc";
    }

    if (this.pageFrom == "tripdetail") {    
      if (localStorage.getItem('trip.sd') != null && localStorage.getItem('trip.sd') != "") {
        this.startDate = new Date(localStorage.getItem('trip.sd'));
      }
      if (localStorage.getItem('trip.ed') != null && localStorage.getItem('trip.ed') != "") {
        this.endDate = new Date(localStorage.getItem('trip.ed'));
      }
      this.person_role = localStorage.getItem('trip.prole');
      
      if (localStorage.getItem('trip.tco') != null && localStorage.getItem('trip.tco') != "") {
        if (Number(localStorage.getItem('trip.tco')) == 1)
          this.includeClosedBilledRecords = false;
      }
      else {
        this.includeClosedBilledRecords = true;
      }
       
      if (localStorage.getItem('trip.ord') != null && localStorage.getItem('trip.ord') != "") {
        this.sort_by = localStorage.getItem('trip.ord');
      }

      if (localStorage.getItem('trip.prole') != null && localStorage.getItem('trip.prole') != "") {
        this.person_role = localStorage.getItem('trip.prole');
      }

    }

    this.initControls();

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (responses[0].model.length > 0) {
            let ac = new AircraftModel();
            ac.registration = "Select All";
            ac.aircraftGuid = "all";
            ac.selected = false;
            this.registrationList.push(ac);
            responses[0].model.forEach(x => {
              ac = new AircraftModel();
              ac.aircraftGuid = x.aircraftGUID;
              ac.registration = x.registration;
              ac.selected = false;
              this.registrationList.push(ac);
            });
            if (localStorage.getItem('trip.aclist') != null && localStorage.getItem('trip.aclist') != "") {
              let s: string = localStorage.getItem('trip.aclist');
              let sa: string[] = s.split(',');
              sa.forEach(x => {
                let i: number = this.registrationList.findIndex(y => y.aircraftGuid.toLowerCase() == x.toLowerCase());
                if (i != -1) {
                  this.registrationList[i].selected = true;
                }
              })

            }

            this.setRegistrationList();
          }
          
        }
        //else {
        //  if (responses[0].code == "401") {
        //    this._authService.signOut();
        //  }
        //}
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (responses[1].model.length > 0) {
            let ap = new AirportModel();
            ap.icao = "Select All";
            ap.iata = "";
            ap.airportID = 0;
            ap.selected = false;
            this.airportList.push(ap);
            let i: number = 1;
            responses[1].model.forEach(x => {
              ap = new AirportModel();
              ap.icao = x.icao;
              ap.iata = x.iata;
              ap.airportID = i;
              ap.selected = false;
              
              this.airportList.push(ap);
              i = i + 1;
            });
            
            if (localStorage.getItem('trip.aplist') != null && localStorage.getItem('trip.aplist') != "") {
              let s: string = localStorage.getItem('trip.aplist');
              let sa: string[] = s.split(',');
              sa.forEach(x => {
                let i: number = this.airportList.findIndex(y => y.icao.toLowerCase() == x.toLowerCase());
                if (i != -1) {
                  this.airportList[i].selected = true;
                }
              })

            }

            this.setAirportList();
          }

        }
        
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          if (responses[2].model.length > 0) {           
              let p = new GroundStopManifestModel();
              p.personName = "Select All";
              p.personGUID = "all";
              p.selected = false;
              p.personType = this.person_role;
              switch (this.f.person_role.value) {
                case "pilot":
                  this.pilotList.push(p);
                  break;
                case "crew":
                  this.crewList.push(p);
                  break;
                case "passenger":
                  this.paxList.push(p);
                  break;
              }

              let i: number = 1;
              responses[2].model.forEach(x => {
                p = new GroundStopManifestModel();
                p.personGUID = x.personGUID;
                p.personName = x.personName;
                p.selected = false;
                p.personType = this.person_role;
                switch (this.person_role) {
                  case "pilot":
                    
                    this.pilotList.push(p);

                    break;
                  case "crew":
                   
                    this.crewList.push(p);
                    break;
                  case "passenger":
                    
                    this.paxList.push(p);
                    break;
                }

              });

              switch (this.person_role) {
                case "pilot":
                  if (localStorage.getItem('trip.plist') != null && localStorage.getItem('trip.plist') != "") {
                    let s: string = localStorage.getItem('trip.plist');
                    let sa: string[] = s.split(',');
                    sa.forEach(x => {
                      let i: number = this.pilotList.findIndex(y => y.personGUID.toLowerCase() == x.toLowerCase());
                      if (i != -1) {
                        this.pilotList[i].selected = true;
                      }
                    });
                    
                  }
                                    
                  this.setPersonList();
                  break;
                case "crew":
                  if (localStorage.getItem('trip.plist') != null && localStorage.getItem('trip.plist') != "") {
                    let s: string = localStorage.getItem('trip.plist');
                    let sa: string[] = s.split(',');
                    sa.forEach(x => {
                      //let i: number = this.crewList.findIndex(y => y.personGUID.toLowerCase() == x.toLowerCase());
                      let i: number = this.crewList.findIndex(y => y.personGUID.toLowerCase().indexOf(x.toLowerCase())!=-1);
                      if (i != -1) {
                        this.crewList[i].selected = true;
                      }
                    });

                  }
                  
                  this.setPersonList();
                  break;
                case "passenger":
                  if (localStorage.getItem('trip.plist') != null && localStorage.getItem('trip.plist') != "") {
                    let s: string = localStorage.getItem('trip.plist');
                    let sa: string[] = s.split(',');
                    sa.forEach(x => {
                      //let i: number = this.paxList.findIndex(y => y.personGUID.toLowerCase() == x.toLowerCase());
                      let i: number = this.paxList.findIndex(y => y.personGUID.toLowerCase().indexOf(x.toLowerCase()) != -1);
                      if (i != -1) {
                        this.paxList[i].selected = true;
                      }
                    });

                  }
                  
                  this.setPersonList();
                  break;
              }
            
          }

        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          let c = new CustomerModel();
          c.customerGUID = "all";
          c.customerName = "Select All";
          c.selected = false;
          this.customerList.push(c);
          responses[3].model.forEach(x => {
            c = new CustomerModel();
            c.customerGUID = x.customerGUID;
            c.customerName = x.customerName;
            c.selected = false;
            this.customerList.push(c);
          });
          if (localStorage.getItem('trip.clist') != null && localStorage.getItem('trip.clist') != "") {
            let c2: string = localStorage.getItem('trip.clist');
            let c1: string[] = c2.split(',');
            c1.forEach(x => {
              let i: number = this.customerList.findIndex(y => y.customerGUID.toLowerCase() == x.toLowerCase());
              if (i != -1) {
                this.customerList[i].selected = true;
              }
            })
          }
          
          this.setClientList();
        }
        
      }

      this.openChildPage();
           
    });

    this.showSpin = false;  
      
  }

  initControls() {
    this.trip_quick_search_control_client_form = this._formBuilder.group({     
      includeClosedBilledRecords: [this.includeClosedBilledRecords],
      startDate: [this.startDate],
      endDate: [this.endDate],
      sort_by: [this.sort_by],
      person_role: this.person_role,
      person_search_keyword:''

    });

    
  }

  get f() { return this.trip_quick_search_control_client_form.controls; }
 
  includeClosedBilledRecordsOnChange(e: any) {
    this.includeClosedBilledRecords = e.checked; // mat checkbox    
    this.clickSubmit();
  }

  dateOnchange(dateType: string, e: any) {
    if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidStartDate = false;
          this.f.startDate.setErrors({ isValidDate: false });
        }
        else {
          this.isValidStartDate = true;
        }
      }
      else {
        this.isValidStartDate = true;
      }
    }

    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidEndDate = false;
          this.f.endDate.setErrors({ isValidDate: false })
        }
        else {
          this.isValidEndDate = true;
        }
      }
      else {
        this.isValidEndDate = true;
      }
    }

    this.clickSubmit();    

  }

  sortByChange(e: any) {
    this.clickSubmit();
  }

  // aircraft
  clickAircraftFilter() {    
    this.getTripAircraftList();     
  }

  getTripAircraftList() {
    this.showAircraftList = true;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showClientList = false;
    this.registrationList = [];
    let req = new TripModel();
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    this._groundStopClientService.getTripRegistrationListBycIduId<ResponseModel<TripModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let ac = new AircraftModel();
          ac.registration = "Select All";
          ac.aircraftGuid = "all";
          ac.selected = false;
          this.registrationList.push(ac);
          response.model.forEach(x => {
            ac = new AircraftModel();
            ac.aircraftGuid = x.aircraftGUID;
            ac.registration = x.registration;
            ac.selected = false;
            if (this.selectedRegistrationList.length > 0) {
              let f: AircraftModel[];
               f= this.selectedRegistrationList.filter(y => y.aircraftGuid.toLowerCase() == x.aircraftGUID.toLowerCase());
              if (f.length > 0) {
                ac.selected = true;
              }
            }           
            this.registrationList.push(ac);
          });
          
          this.setRegistrationList();

          if (this.selectedRegistrationList.length > 0 && this.selectedRegistrationList.length != this.registrationList.length) {
            let data: AircraftModel[] = [];
            data = this.registrationList;
            this.registrationList = [];

            data.forEach(x => {
              if (x.aircraftGuid == "all") {
                this.registrationList.push(x);
              }
              else {
                if (x.selected) {
                  this.registrationList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.aircraftGuid != "all") {
                this.registrationList.push(x);
              }
            });
          }         

          
        }
        else {
          //this.totalRecordsText = "No record found";
        }
        
      }
      //else {
      //  if (response.code == "401") {
      //    this._authService.signOut();
      //  }
      //  else {
      //    //this.errMsg = "Failed to get trips at this time. Please try later.";
      //  }
      //}
      
    })
  }

  checkRegistrationChange(e: any, item: AircraftModel) {
    if (item.aircraftGuid == 'all') {
      this.registrationList.forEach(x => {        
          x.selected = e.checked;     
      });
    }
    else {
      this.registrationList.forEach(x => {
        if (item.aircraftGuid.toLowerCase() == x.aircraftGuid.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setRegistrationList();
  }

  setRegistrationList() {
    let excludeallaclist: AircraftModel[]
    excludeallaclist= this.registrationList.filter(x => x.aircraftGuid != 'all' && x.selected == false);
    if (excludeallaclist.length == 0) {
      this.registrationList.find(x => x.aircraftGuid == 'all').selected = true;
    }
    else {
      this.registrationList.find(x => x.aircraftGuid == 'all').selected = false;
    }
    this.selectedRegistrationList = this.registrationList.filter(u => u.selected == true);
    this.selectedRegistrationCountText = "";
    if (this.registrationList.length > 0) {
      if (this.selectedRegistrationList.length == this.registrationList.length) {
        this.selectedRegistrationCountText = ": All";
      }
      else {
        if (this.selectedRegistrationList.length > 0)
          this.selectedRegistrationCountText = ": "+this.selectedRegistrationList.length.toString();
      }
    }
    
  }

  clickDoneAircraft() {
    this.showAircraftList = false;
    this.clickSubmit();
  }

  clickResetAircraft() {
    this.registrationList.forEach(x => {
      x.selected = false;
    });
    this.setRegistrationList();
    this.showAircraftList = false;
    this.clickSubmit();
  }
  // aircraft end

  // airport
  clickAirportFilter() {
    this.getTripAirportList();
  }

  getTripAirportList() {
    this.showAircraftList = false;
    this.showAirportList = true;
    this.showPersonList = false;
    this.showClientList = false;
    this.airportList = [];
    let req = new TripModel();
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    this._groundStopClientService.getTripAirportListBycIduId<ResponseModel<GroundStopModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let ap = new AirportModel();
          ap.icao = "Select All";
          ap.iata = "";
          ap.airportID = 0;
          ap.selected = false;
          this.airportList.push(ap);
          let i: number = 1;
          response.model.forEach(x => {
            ap = new AirportModel();
            ap.icao = x.icao;
            ap.iata = x.iata;
            ap.airportID = i;
            ap.selected = false;
            if (this.selectedAirportList.length > 0) {
              let f: AirportModel[];
              f = this.selectedAirportList.filter(y => y.icao.toLowerCase() == x.icao.toLowerCase());
              if (f.length > 0) {
                ap.selected = true;
              }
            }         
            this.airportList.push(ap);
            i = i + 1;
          });
          this.setAirportList();

          if (this.selectedAirportList.length > 0 && this.selectedAirportList.length != this.airportList.length) {
            let data: AirportModel[] = [];
            data = this.airportList;
            this.airportList = [];

            data.forEach(x => {
              if (x.airportID == 0) {
                this.airportList.push(x);
              }
              else {
                if (x.selected) {
                  this.airportList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.airportID != 0) {
                this.airportList.push(x);
              }
            });
          }         
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
      //else {
      //  if (response.code == "401") {
      //    this._authService.signOut();
      //  }
      //  else {
      //    //this.errMsg = "Failed to get trips at this time. Please try later.";
      //  }
      //}

    })
  }

  checkAirportChange(e: any, item: AirportModel) {
    if (item.airportID== 0) {
      this.airportList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.airportList.forEach(x => {
        if (item.airportID == x.airportID) {
          x.selected = e.checked;
        }
      });
    }
    this.setAirportList();
  }

  setAirportList() {
    let excludeallaplist: AirportModel[]
    excludeallaplist = this.airportList.filter(x => x.airportID != 0 && x.selected == false);
    if (excludeallaplist.length == 0) {
      this.airportList.find(x => x.airportID== 0).selected = true;
    }
    else {
      this.airportList.find(x => x.airportID == 0).selected = false;
    }
    this.selectedAirportList = this.airportList.filter(u => u.selected == true);
    this.selectedAirportCountText = "";
    if (this.airportList.length > 0) {
      if (this.selectedAirportList.length == this.airportList.length) {
        this.selectedAirportCountText = ": All";
      }
      else {
        if (this.selectedAirportList.length > 0)
          this.selectedAirportCountText = ": " + this.selectedAirportList.length.toString();
      }
    }
  }

  clickDoneAirport() {
    this.showAirportList = false;
    this.clickSubmit();
  }

  clickResetAirport() {
    this.airportList.forEach(x => {
      x.selected = false;
    });
    this.setAirportList();
    this.showAirportList = false;
    this.clickSubmit();
  }
  // airport end


  // manifest
  clickManifestFilter() {    
     this.getTripPersonList();   
  }

  getTripPersonList() {
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = true;
    this.showClientList = false;
    switch (this.f.person_role.value) {
      case "pilot":
        this.pilotList=[];
        break;
      case "crew":
        this.crewList=[];
        break;
      case "passenger":
        this.paxList=[];
        break;
    }
    
    let req = new TripModel();
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    req.personTextSearch = this.f.person_search_keyword.value;
    req.personRole = this.person_role;
    this._groundStopClientService.getTripPersonListBycIduId<ResponseModel<GroundStopManifestModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        switch (this.f.person_role.value) {
          case "pilot":
            this.pilotList = [];
            break;
          case "crew":
            this.crewList = [];
            break;
          case "passenger":
            this.paxList = [];
            break;
        }
        if (response.model.length > 0) {          
          let p = new GroundStopManifestModel();
          p.personName = "Select All";
          p.personGUID = "all";          
          p.selected = false;
          p.personType = this.person_role;
          switch (this.f.person_role.value) {
            case "pilot":
              this.pilotList.push(p);
              break;
            case "crew":
              this.crewList.push(p);
              break;
            case "passenger":
              this.paxList.push(p);
              break;
          }
          
          let i: number = 1;
          response.model.forEach(x => {
            p = new GroundStopManifestModel();
            p.personGUID = x.personGUID;
            p.personName = x.personName;      
            p.selected = false;
            p.personType = this.person_role;
            switch (this.person_role) {
              case "pilot":
                if (this.selectedPilotList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedPilotList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.pilotList.push(p);

                break;
              case "crew":
                if (this.selectedCrewList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedCrewList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.crewList.push(p);
                break;
              case "passenger":
                if (this.selectedPaxList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedPaxList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.paxList.push(p);
                break;
            }

          });
          
          switch (this.person_role) {
            case "pilot":
              if (this.selectedPilotList.length > 0) {
                this.selectedPilotList.forEach(x => {
                  let i: number;
                  i = this.pilotList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (i==-1) {                  
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.pilotList.push(p);
                  }
                });               
              }              
              this.setPersonList();
              if (this.selectedPilotList.length > 0 && this.selectedPilotList.length != this.pilotList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.pilotList;
                this.pilotList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.pilotList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.pilotList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.pilotList.push(x);
                  }
                });
              }
              break;
            case "crew":
              if (this.selectedCrewList.length > 0) {
                this.selectedCrewList.forEach(x => {
                  let i: number;
                  i = this.crewList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (i == -1) {
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.crewList.push(p);
                  }
                });
              }
              this.setPersonList();
              if (this.selectedCrewList.length > 0 && this.selectedCrewList.length != this.crewList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.crewList;
                this.crewList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.crewList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.crewList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.crewList.push(x);
                  }
                });
              }
              break;
            case "passenger":
              if (this.selectedPaxList.length > 0) {
                this.selectedPaxList.forEach(x => {
                  let i: number;
                  i = this.paxList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (i == -1) {
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.paxList.push(p);
                  }
                });
              }
              this.setPersonList();
              if (this.selectedPaxList.length > 0 && this.selectedPaxList.length != this.paxList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.paxList;
                this.paxList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.paxList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.paxList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.paxList.push(x);
                  }
                });
              }
              break;
          }
          
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
      //else {
      //  if (response.code == "401") {
      //    this._authService.signOut();
      //  }
      //  else {
      //    //this.errMsg = "Failed to get trips at this time. Please try later.";
      //  }
      //}

    })
  }

  personSearchKeywordChange(e: any) {
    this.getTripPersonList();
  }

  personRoleChange(e: any) {   
    this.person_role = e.target.value;
    this.f.person_search_keyword.setValue('');
    this.selectedPilotList = [];
    this.selectedCrewList = [];
    this.selectedPaxList = [];
    
    this.selectedPersonCountText = "";
    this.getTripPersonList();
  }

  checkPersonChange(e: any, item: GroundStopManifestModel) {
    switch (this.person_role) {
      case "pilot":
        if (item.personGUID == 'all') {
          this.pilotList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.pilotList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();

        break;
      case "crew":
        if (item.personGUID == 'all') {
          this.crewList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.crewList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();
        break;
      case "passenger":
        if (item.personGUID == 'all') {
          this.paxList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.paxList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();
        break;
    }
    
  }

  setPersonList() {
    this.selectedPersonCountText = "";
    let n: number = 0;
    switch (this.person_role) {
      case "pilot":
        let excludeallplist: GroundStopManifestModel[]
        excludeallplist = this.pilotList.filter(x => x.personGUID != 'all' && x.selected == false);
        if (excludeallplist.length == 0) {
          this.pilotList.find(x => x.personGUID == 'all').selected = true;
        }
        else {
          this.pilotList.find(x => x.personGUID == 'all').selected = false;
        }
        this.selectedPilotList = this.pilotList.filter(u => u.selected == true);
        if (this.pilotList.length > 0) {
          if (this.selectedPilotList.length == this.pilotList.length && this.f.person_search_keyword.value == '') {
           this.selectedPersonCountText = ": Pilot All";
          }
          else {
            //if (this.selectedPilotList.length > 0)
            //  this.selectedPersonCountText = ": Pilot " + this.selectedPilotList.length.toString();
            if (this.selectedPilotList.length > 0) {
              if (this.pilotList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedPilotList.length - 1;
              }
              else {
                n = this.selectedPilotList.length;
              }
              this.selectedPersonCountText = ": Pilot " + n.toString();
            }
          }
        }
        break;
      case "crew":
        let excludeallclist: GroundStopManifestModel[]
        excludeallclist = this.crewList.filter(x => x.personGUID != 'all' && x.selected == false);
        if (excludeallclist.length == 0) {
          this.crewList.find(x => x.personGUID == 'all').selected = true;
        }
        else {
          this.crewList.find(x => x.personGUID == 'all').selected = false;
        }
        this.selectedCrewList = this.crewList.filter(u => u.selected == true);
        if (this.crewList.length > 0) {
          if (this.selectedCrewList.length == this.crewList.length && this.f.person_search_keyword.value == '') {
            this.selectedPersonCountText = ": Crew All";
          }
          else {
            //if (this.selectedCrewList.length > 0)
            //  this.selectedPersonCountText = ": Crew " + this.selectedCrewList.length.toString();
            if (this.selectedCrewList.length > 0) {
              if (this.crewList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedCrewList.length - 1;
              }
              else {
                n = this.selectedCrewList.length;
              }
              this.selectedPersonCountText = ": Crew " + n.toString();
            }
          }
        }
        break;
      case "passenger":
        let excludeallpxlist: GroundStopManifestModel[]
        excludeallpxlist = this.paxList.filter(x => x.personGUID != 'all' && x.selected == false);
        if (excludeallpxlist.length == 0) {
          this.paxList.find(x => x.personGUID == 'all').selected = true;
        }
        else {
          this.paxList.find(x => x.personGUID == 'all').selected = false;
        }
        this.selectedPaxList = this.paxList.filter(u => u.selected == true);
        if (this.paxList.length > 0) {
          if (this.selectedPaxList.length == this.paxList.length && this.f.person_search_keyword.value == '') {
            this.selectedPersonCountText = ": Passenger All";
          }
          else {
            //if (this.selectedPaxList.length > 0)
            //  this.selectedPersonCountText = ": Passenger " + this.selectedPaxList.length.toString();
            if (this.selectedPaxList.length > 0) {
              if (this.paxList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedPaxList.length - 1;
              }
              else {
                n = this.selectedPaxList.length;
              }
              this.selectedPersonCountText = ": Passenger " + n.toString();
            }
          }
        }
        break;
    }

  }

  clickDonePerson() {
    this.showPersonList = false;
    this.clickSubmit();
  }

  clickResetPerson() {
    this.f.person_search_keyword.setValue('');
    switch (this.person_role) {
      case "pilot":
        this.selectedPilotList = [];
        //this.pilotList.forEach(x => {
        //  x.selected = false;
        //});
        //this.setPersonList();
        break;
      case "crew":
        this.selectedCrewList = [];
        //this.crewList.forEach(x => {
        //  x.selected = false;
        //});
        // this.setPersonList();
        break;
      case "passenger":
        this.selectedPaxList = [];
        //this.paxList.forEach(x => {
        //  x.selected = false;
        //});
        // this.setPersonList();
        break;
    }
    this.getTripPersonList();
    this.showPersonList = false;
    this.clickSubmit();
  }
  // manifest end

  // client
  clickClientFilter() {
    this.getTripClientList();
  }

  getTripClientList() {
    var root = this;
    this.showClientList = true;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = false;   
    this.customerList = [];    
    let req = new TripModel();    
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    this._groundStopClientService.getTripClientListBycGrpId<ResponseModel<TripModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.customerList = [];
        if (response.model.length > 0) {
          let c = new CustomerModel();
          c.customerName = "Select All";
          c.customerGUID = "all";
          c.selected = false;
          this.customerList.push(c);
          response.model.forEach(x => {
            c = new CustomerModel();
            c.customerName = x.customerName;
            c.customerGUID = x.customerGUID;
            c.selected = false;
            if (this.selectedCustomerList.length > 0) {
              let f: CustomerModel[];
              f = this.selectedCustomerList.filter(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
              if (f.length > 0) {
                c.selected = true;
              }
            }
            this.customerList.push(c);
          });
          //this.getCustomerListBykeyword(this.f.client_search.value);

          if (this.selectedCustomerList.length > 0 && this.selectedCustomerList.length != this.customerList.length) {
            let data: CustomerModel[] = [];
            data = this.customerList;
            this.customerList = [];

            data.forEach(x => {
              if (x.customerGUID == "all") {
                this.customerList.push(x);
              }
              else {
                if (x.selected) {
                  this.customerList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.customerGUID != "all") {
                this.customerList.push(x);
              }
            });
          }

        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })

  }

  checkClientChange(e: any, item: CustomerModel) {
    if (item.customerGUID == 'all') {
      this.customerList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.customerList.forEach(x => {
        if (item.customerGUID.toLowerCase() == x.customerGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setClientList();
    this.reloadChildPage = false;
  }

  setClientList() {

    let excludeallclist: CustomerModel[]
    excludeallclist = this.customerList.filter(x => x.customerGUID != 'all' && x.selected == false);
    if (excludeallclist.length == 0) {
      this.customerList.find(x => x.customerGUID == 'all').selected = true;
    }
    else {
      this.customerList.find(x => x.customerGUID == 'all').selected = false;
    }
    this.selectedCustomerList = this.customerList.filter(u => u.selected == true);
    this.selectedClientCountText = "";

    if (this.customerList.length > 0) {
      if (this.selectedCustomerList.length == this.customerList.length) {
        this.selectedClientCountText = ": All";
      }
      else {
        if (this.selectedCustomerList.length > 0) {
          let f: CustomerModel[] = this.selectedCustomerList.filter(x => x.customerGUID == "all")
          if (f.length > 0) {
            let n: number = this.selectedCustomerList.length - 1;
            this.selectedClientCountText = ": " + n.toString();
          }
          else {
            this.selectedClientCountText = ": " + this.selectedCustomerList.length.toString();
          }
        }
      }
    }

  }

  //clientSearchChange(e: any) {
  //  let cname: string = e.target.value;
  //  if (cname != "") {
  //    cname = cname.toLowerCase();
  //  }

  //  this.getCustomerListBykeyword(cname);

  //}

  //getCustomerListBykeyword(cname: string) {

  //  this.customerList = this.orgCustomerList.filter(option => option.customerName.toLowerCase().includes(cname) || option.customerGUID == "all");
  //  if (this.selectedCustomerList.length > 0) {
  //    this.selectedCustomerList.forEach(x => {
  //      let i: number;
  //      i = this.customerList.findIndex(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
  //      if (i == -1) {
  //        let c = new CustomerModel();
  //        c.customerName = x.customerName;
  //        c.customerGUID = x.customerGUID;
  //        c.selected = true;
  //        this.customerList.push(c);
  //      }
  //    });
  //  }
  //  this.setClientList();
  //  if (this.selectedCustomerList.length > 0 && this.selectedCustomerList.length != this.customerList.length) {
  //    let data: CustomerModel[] = [];
  //    data = this.customerList;
  //    this.customerList = [];

  //    data.forEach(x => {
  //      if (x.customerGUID == "all") {
  //        this.customerList.push(x);
  //      }
  //      else {
  //        if (x.selected) {
  //          this.customerList.push(x);
  //        }
  //      }
  //    });
  //    data.forEach(x => {
  //      if (!x.selected && x.customerGUID != "all") {
  //        this.customerList.push(x);
  //      }
  //    });
  //  }

  //}

  clickDoneClient() {
    this.showClientList = false;
    this.clickSubmit();
  }

  clickResetClient() {
    this.f.client_search.setValue('');
    this.selectedCustomerList = [];
    this.customerList.forEach(x => {
      x.selected = false;
    });
    this.setClientList();
    this.showClientList = false;
    this.clickSubmit();
  }
  // client end

  clickSubmit() {
    this.submitted = true;

    if (this.trip_quick_search_control_client_form.invalid || this.errMsg != "") {
      return;
    }

    this.openChildPage();

  }

  openChildPage() {
    this.errMsg = "";
    this.msg = "";

    this._authService.updateAccessTime();


    this.request = new TripModel();
    this.reloadChildPage = true;
    
    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      this.request.tripCloseOutID = 0;
    }
    else {
      this.request.tripCloseOutID = 1;
    }
   
    this.request.startDate = this.f.startDate.value;
    this.request.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      this.request.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    this.request.endDate = this.f.endDate.value;
    this.request.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      this.request.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    if (this.selectedRegistrationList.length == this.registrationList.length) {
      this.request.selectedAircraftGUIDList = "";
    }
    else {
      this.request.selectedAircraftGUIDList = Array.prototype.map.call(this.selectedRegistrationList, s => s.aircraftGuid).toString();
    }
    if (this.selectedAirportList.length == this.airportList.length) {
      this.request.selectedICAOList = "";
    }
    else {
      this.request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }
    
    this.request.personRole = this.person_role;
    switch (this.person_role) {
      case "pilot":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedPilotList.length == this.pilotList.length) {
            this.request.selectedPersonGUIDList = "";
          }
          else {
            this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPilotList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedPilotList = this.selectedPilotList.filter(x => x.personGUID != 'all');
          this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPilotList, s => s.personGUID).toString();
        }
        break;
      case "crew":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedCrewList.length == this.crewList.length) {
            this.request.selectedPersonGUIDList = "";
          }
          else {
            this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedCrewList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedCrewList = this.selectedCrewList.filter(x => x.personGUID != 'all');
          this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedCrewList, s => s.personGUID).toString();
        }
        break;
      case "passenger":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedPaxList.length == this.paxList.length) {
            this.request.selectedPersonGUIDList = "";
          }
          else {
            this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPaxList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedPaxList = this.selectedPaxList.filter(x => x.personGUID != 'all');
          this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPaxList, s => s.personGUID).toString();
        }
        break;
    }
    this.request.selectedCustomerGUIDList = "";
    if (this.grantAccessToAccountGrouping) {
      if (this.selectedCustomerList.length == this.customerList.length) {
        this.request.selectedCustomerGUIDList = "";
      }
      else {
        this.request.selectedCustomerGUIDList = Array.prototype.map.call(this.selectedCustomerList, s => s.customerGUID).toString();
      }
    }
    
    this.request.sortBy = this.f.sort_by.value;   
    localStorage.setItem('trip.sd', this.request.startDateText);
    localStorage.setItem('trip.ed', this.request.endDateText);
    localStorage.setItem('trip.aclist', this.request.selectedAircraftGUIDList);
    localStorage.setItem('trip.aplist', this.request.selectedICAOList);
    localStorage.setItem('trip.prole', this.request.personRole);
    localStorage.setItem('trip.plist', this.request.selectedPersonGUIDList);
    localStorage.setItem('trip.clist', this.request.selectedCustomerGUIDList);
    localStorage.setItem('trip.filterTime', Date.now().toString());
    localStorage.setItem('trip.ord', this.request.sortBy);
   
  }

  onSendDataToParent(e: any) {
    this.totalRecordsText = e.totalRecordsText;
    
  }

  //clickReset() {
  //  this.errMsg = "";
  //  this.errMsg1 = "";
  //  this.msg = "";
  //  this.totalRecordsText = "";
  //  this.includeClosedBilledRecords = false;
   
  //  this.reloadChildPage = true;
    
  //  this.pageFrom = "";
  //  this.startDate = new Date();
  //  this.startDate.setDate(this.startDate.getDate() - 7);
  //  this.endDate = new Date();
  //  this.endDate.setDate(this.endDate.getDate() + 28);

  //  localStorage.removeItem('trip.sd');
  //  localStorage.removeItem('trip.ed');
  //  localStorage.removeItem('trip.tco');
    
  //  this.f.includeClosedBilledRecords.setValue(false);
  //  this.f.startDate.setValue(this.startDate);
  //  this.f.endDate.setValue(this.endDate);
    
  //  setTimeout(() => {
  //    this.openChildPage();
  //  }, 1000);
    
  //}

  clickTab(s: string) {
    if (this.selectedTabName != s) {
      this.selectedTabName = s;
      if (this.selectedTabName == "aircraft") {
        this.f.sort_by.setValue("registration asc");
      }
      else {
        this.f.sort_by.setValue("departureDate asc");
      }
      
      this.openChildPage();
    }
  }

  clickLegend() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, legendType: 't'
    };

    const dialogRef = this._dialog.open(IconLegendDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });


  }


}
