import { Component, Inject, OnInit, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
//import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Observable, of } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import * as DecoupledEditor from '../ckeditor5/ckeditor';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { MessageService } from '../services/message.service';
import { IdentityService } from '../services/identity.service';
import { CKEditorConfig } from '../ckeditor5/ckeditor-config';
import { MessageEmbeddedImageModel } from '../models/message-embedded-image';
import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';
import { TemplateModel } from '../models/template.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { ServiceClassModel } from '../models/service-class.model';
import { BusinessRulesService } from '../services/business-rules-service';
import { BusinessRulesModel } from '../models/business-rules.model'; 

@Component({
  selector: 'app-template-edit-dialog',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.css']
})

export class TemplateEditComponent implements OnInit {
  // ckeditor

  public Editor = DecoupledEditor;

  public CKEConfig = CKEditorConfig.ckeditorConfig;
  editorIsModified: boolean = false;
  isReply: boolean = false;
  lockdownTrip: boolean = false;
  templateData: TemplateModel;
  errMessage: string;
  messageTemplateID: number;
  showServiceList: boolean = false;
  selectedServicesCountText: string = '';

  filteredServiceList: Observable<ServiceTypeModel[]>;
  filteredServiceClassList: Observable<ServiceClassModel[]>;
  service: string = "service_type"; 
  serviceTypeList: ServiceTypeModel[];
  serviceClassList: ServiceClassModel[];
  selectedServiceTypeList: ServiceTypeModel[];
  selectedServiceClassList: ServiceClassModel[];
  selectedClassList: number[] = [];
  filteredServiceTypeList: ServiceTypeModel[] = [];
  selectedServices: string = '';
  selectedServiceIds: string = '';
  showLegend:boolean = false;


  isDisabled2: boolean = false;

  isDisabled: boolean;
  @ViewChild('emailCKEditor') emailCKEditorRef: any;//ElementRef;
  // ckeditor end

  dialogTitle: string;
 
  
  template_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string = '';
  errMsg2: string = '';
  showSpin: boolean = false;
  userType: string;
  showSuccessMsg: boolean = false;
  successMsg: string = "Template Saved Successfully";
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  
  controlValueChanged: boolean = false;
  isModified: boolean = false;

  grantAccessToMessages: boolean = false;  
 
  emailContent: string; 
  subject: string = '';// 'Enter Subject Line here';
  template_name: string = '';// 'Enter Template Name here';
  service_list: string = "";  
 
  defaultSignature: string = ""; 
  messageGUID: string = "";
  // emailBody: string = "";
   
 
  isDraft: boolean = false; 
  embeddedImageList: MessageEmbeddedImageModel[] = [];
  displayType: string = "html";
  ckEditorData: string; 
  farTypeID: number;   

  isFlagged: boolean = false; 
  showCommsInTrip: boolean = false;

  isMultipleLegs: boolean = false; 
  currentkeywords: any;
  templateList: TemplateModel[];
  showSuccessCopy: boolean = false;
  copied: boolean = false;
  isAdmin: boolean = false;

  
  public onReady(editor) {


    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()

    );

    const clipboardPlugin = editor.plugins.get('ClipboardPipeline');
    const editingView = editor.editing.view;

    editingView.document.on('clipboardInput', (evt, data) => {

      
      const clipboardData = data.dataTransfer;

      if (clipboardData.types.includes('Files') && clipboardData.files.length > 0) {
        // Files are present in the clipboard (e.g., images).
        // Prevent the default behavior to stop pasting images.
        evt.stop();
      }
      if (editor.isReadOnly) {
        return;
      }
      const dataTransfer = data.dataTransfer;
      let content = plainTextToHtml(dataTransfer.getData('text/plain'));
      content = content.replace(/<p>/gi, '<p style="font-family:\'Courier New\', Courier, monospace;">');
      data.content = editor.data.htmlProcessor.toView(content);


      editingView.scrollToTheSelection();
    }, { priority: 'high' });
    
    const selection = editor.model.document.selection;
    const position = selection.getFirstPosition();

    // console.log('Current cursor position:', position);
    
  }





  constructor(private readonly _dialogRef: MatDialogRef<TemplateEditComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _dialog: MatDialog, private readonly _commonService: CommonService, private readonly _groundStopClientService: GroundStopClientService,
    private readonly _dialogService: DialogService, private readonly _messageService: MessageService, private readonly _identityService: IdentityService, 
    private readonly _businessRulesService: BusinessRulesService
  ) {
      
    this.dialogTitle = _data.dialogTitle;
    this.messageTemplateID = _data.messageTemplateID;
    this.templateList = _data.templateList;

  }
 
  ngOnInit() {

    if (localStorage.getItem('up.msgTemplates') == 'true') {
      this.isAdmin = true;
    }       
      this.initControls();
      this.showSpin = false;
      this.getServiceList();
      this.getTemplateData();     

  }

  initControls() {
    this.template_edit_form = this._formBuilder.group({
      template_name: [this.template_name],
      service_list: [this.service_list],
      subject: [this.subject, Validators.required],
      emailCKEditor: [this.emailContent ], 
      service_search: '',
      service: 'service_type',
      isMultipleLegs: [this.isMultipleLegs],
      record_status: [this.isActive]
    });
  }

  get f() { return this.template_edit_form.controls; }
 

  clickClose() {
    
    this._dialogRef.close(this.isModified);
  }


  setMessageBody(body: string) {
    let editor = this.emailCKEditorRef.editorInstance;

    let style='style="font-family:\'Courier New\', Courier, monospace;"';
    body = body.replace(/<p>/gi, '<p style="font-family:\'Courier New\', Courier, monospace;">');
    editor.setData("<pre><p " + style + ">" + body + "</p></pre>");

    // this.f.emailCKEditor.setValue('');
    // this.f.emailCKEditor.setValue("<pre>" + body + "</pre>");
    // // this.f.emailCKEditor.setValue(body);
  }
  
 getTemplateData(){

    let request = new TemplateModel();
    request.messageTemplateID = this.messageTemplateID;
     
      this._commonService.getTemplateList<ResponseModel<TemplateModel[]>>(request).subscribe(response => {
        if (response != null) {
          if (response.message == "" && response.code == "200") {
            this.templateData = response.model[0];
            if(this.dialogTitle == "EDIT Record"){

              let s: string = this.templateData.messageTemplateText;  

              s = s.replace("&NBSP;", " ");
              s = s.replace("&nbsp;", " ");

              this.setMessageBody(s);
              this.f.template_name.setValue(this.templateData.messageTemplateName);
              this.f.subject.setValue(this.templateData.subjectLine);
              this.selectedServiceIds = this.templateData.lk_ServiceTypeID;
              this.f.service_list.setValue(this.templateData.serviceList);
              this.isActive = this.templateData.isActive == true ? 1 : 0;
              this.f.record_status.setValue(this.isActive);
              this.modifiedBy = this.templateData.modifiedBy;
              this.modifiedDate = this.templateData.modifiedDate;
              let serviceArray = this.templateData.lk_ServiceTypeID.split(','); 
              let serviceArrayList = serviceArray.map(Number);

              this.serviceTypeList.forEach(item => {
                if (serviceArrayList.includes(item.serviceTypeID)) {
                    item.selected = true;
                }
            });              
            this.filteredServiceList = of(this.serviceTypeList).pipe(
                map(serviceTypes => serviceTypes.filter(type => this.selectedClassList.includes(type.serviceClassID)))
              );


              this.isMultipleLegs = this.templateData.isMultipleHandling;
              this.f.isMultipleLegs.setValue(this.templateData.isMultipleHandling);
            }
            this.setServiceList();
          }
          else {
            if (response.code == "401")
              this._authService.signOut();
            else
              this.errMessage = "An error has occured."
          }
        }
        this.loading = false;
      });

     
  }
   
    // s = s.replace("<pre>", "");
    // s = s.replace("</pre>", ""); 
    // s = s.replace("</pre>", ""); 
    // // s = s.replace("&NBSP;", " ");
    // s = s.replace("&nbsp;", " ");


  validateTemplateDetails(){ 
    // API to Be prepared 
    this.isDisabled2 = true;
    if(!this.isValidTemplateName()){
      this.isDisabled2 = false;

      return;
    }
    else{
      this.saveTemplateData();
    }
  }

  saveTemplateData(){

    this.errMsg = '';
    this.errMsg2 = '';
    let request = new TemplateModel();
    request.messageTemplateID = this.messageTemplateID;
    request.messageTemplateText = this.f.emailCKEditor.value;
    let s: string = this.emailCKEditorRef.editorInstance.getData();

    let s2: string = this.f.emailCKEditor.value;  
    s = s.replace("<br><br><br><br><br><br><br><br><br>", ""); 
    s = s.replace(/&nbsp;/g, " ");
    s = s.replace(/<\/pre>/g, "");
    s = s.replace(/<pre>/g, "");

    request.messageTemplateText = s;
    request.messageTemplateName = this.f.template_name.value;
    request.subjectLine = this.f.subject.value;
    request.lk_ServiceTypeID = this.selectedServiceIds;
    request.isActive = this.isActive == 0 ? false : true;
    request.isMultipleHandling = this.f.isMultipleLegs.value;
    request.modifiedBy = this.modifiedBy;
    request.modifiedDate = this.modifiedDate;
    let str: string = this.emailCKEditorRef.editorInstance.getData();
    str = str.replace(/&nbsp;/g, " ");
    str = str.replace(/<\/pre>/g, "");
    str = str.replace(/<pre>/g, "");


    request.Template = str;
    request.Template = this.formatEmailBody(request.Template); 
    request.TemplateTxt = this.emailCKEditorRef.elementRef.nativeElement.innerText;
    request.TemplateTxt = this.formatEmailBody(request.TemplateTxt);
    request.messageTemplateText = s;// request.TemplateTxt;
    


    this._commonService.insertUpdateTemplate<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          // alert('Success: ' + response.model)
          this.isModified = true;
          
          this.showSuccessMsg = true;
          setTimeout(() => {
            this._dialogRef.close(this.isModified);
            this.showSuccessMsg = false;

          }, 1500);
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMessage = "An error has occured."
        }
      }
      this.loading = false;
    });

  }

  record_statusChange(e: any) {
    this.isActive = Number(this.f.record_status.value);
    // if (this.isActive == 0) {
    //   this.clearValidatorsByServices();
    // }
    // else {
    //   this.backUpControlValues();
    //   this.initControls();
    //   this.clearValidatorsByServices();
    // }
  }

  isValidTemplateName() {

    const regex = /%([^%]+)%/g;
    this.currentkeywords =  this.emailCKEditorRef.elementRef.nativeElement.innerText.match(regex) || [];
    this.currentkeywords= this.currentkeywords.map(key => key.replace(/%/g, ''));
    
     
    if (this.f.template_name.value.trim() === '') {
      
      this.errMsg = "Template Name Is Empty";
      setTimeout(() => {
        this.errMsg = '';
      }, 2000);
      
      return false;
    }
    if (this.f.subject.value.trim() === '') {
      
      this.errMsg = "Subject Line Is Empty";
      setTimeout(() => {
        this.errMsg = '';
      }, 2000);
      
      return false;
    }
    
    if(this.f.service_list.value == ''){
      
      this.errMsg = "There are No Services Attached to the Template";
      setTimeout(() => {
        this.errMsg = '';
      }, 2000);
      
      return false;
    }
    
    
   let idx = this.templateList.findIndex(template => template.messageTemplateName.toLowerCase() == this.f.template_name.value.toLowerCase() && template.messageTemplateID != this.templateData.messageTemplateID);

   if(idx != -1){
    
    this.errMsg = "Template Name Already Exists";
    setTimeout(() => {
      this.errMsg = '';
    }, 2000);
    
    return false;
   }
    
       
   let inValidKeywords = this.currentkeywords.filter(elem => !this.templateData.validKeywords.includes(elem));
   let inValidKeywordsString = inValidKeywords?.join(', ');
   let boolvalue =   this.currentkeywords.every(elem => this.templateData.validKeywords.includes(elem));
   if (!boolvalue) {
     
    //  this.errMsg = "Some of the Keywords are Invalid. Invalid Keywords are : " + inValidKeywordsString ;
     this.errMsg2 = 'Some of the Keywords are Invalid. Invalid Keywords are : ' + inValidKeywordsString ;
     setTimeout(() => {
       this.errMsg = '';
       this.errMsg2 = '';
     }, 8000);
     
     return false;
   }

    return true;
  }

  

 formatEmailBody(templateTxt: string): string {
    const patternsToRemove = /Paragraph\n|Styles\n|Email Body\n|Signature\n|Heading [1-3]\n|Choose\n|heading\n|Choose heading\n/gi;

    // Remove specified patterns from the templateTxt
    templateTxt = templateTxt.replace(patternsToRemove, '').trim();

    return templateTxt;
}
 
  //service
  clickServiceFilter() {
    if (!this.isAdmin)
      return;
    this.showServiceList = true; 

    this.getServiceList();
  }

  getServiceList() {
    this.serviceTypeList = [];
    this.serviceClassList = [];
    // let request = new BusinessRulesModel(); 
    this._businessRulesService.getTemplatesServiceList<ResponseModel<ServiceTypeModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let s = new ServiceTypeModel();
          response.model.forEach(x => {
            s = new ServiceTypeModel();            
            s.serviceTypeDescription = x.serviceTypeDescription;
            s.serviceTypeID = x.serviceTypeID;
            s.serviceClassID = x.serviceClassID;
            s.serviceClassDescription = x.serviceClassDescription;
            s.selected = false;

            if (this.selectedServiceTypeList?.length > 0) {
              let f: ServiceTypeModel[];
              f = this.selectedServiceTypeList.filter(y => y.serviceTypeID == x.serviceTypeID);
              if (f.length > 0) {
                s.selected = true;
              }
            }
            this.serviceTypeList.push(s);
          });
          let y: ServiceClassModel;
          let tempClassID = -2;
          this.serviceTypeList.sort(function (a, b) {
            if (a.serviceClassDescription < b.serviceClassDescription) { return -1; }
            if (a.serviceClassDescription > b.serviceClassDescription) { return 1; }
            return 0
          });
          this.serviceTypeList.forEach((x) => {
            if (tempClassID != x.serviceClassID) {              
              tempClassID = x.serviceClassID;
              y = new ServiceClassModel();
              y.serviceClassID = x.serviceClassID;
              y.serviceClassDescription = x.serviceClassDescription;
              y.serviceTypeList = this.serviceTypeList.filter(a => a.serviceClassID == tempClassID);
              y.selected = false;
              y.serviceTypeList.forEach(x => x.quantity = 1);
              if (this.selectedServiceClassList?.length > 0) {
                let v: ServiceClassModel[];
                v = this.selectedServiceClassList.filter(y => y.serviceClassID == x.serviceClassID);
                if (v.length > 0) {
                  y.selected = true;
                }
              }
              this.serviceClassList.push(y);
            }

          });

          this.serviceTypeList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.serviceTypeDescription.localeCompare(b.serviceTypeDescription));
          s = new ServiceTypeModel();
          s.serviceTypeID = -1;
          s.serviceTypeDescription = 'Select All';
          // s.serviceClassID = -1;
          // s.serviceClassDescription = 'Select All';
          s.selected = false;
          this.serviceTypeList.unshift(s);
 
          this.filteredServiceTypeList = this.serviceTypeList;

          
          this.filteredServiceList = this.f.service_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.serviceName)),
            map(serviceName => (serviceName ? this._filterService(serviceName) : this.filteredServiceTypeList.slice())),
          );
          this.filteredServiceClassList = this.f.service_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.serviceName)),
            map(serviceName => (serviceName ? this._filterServiceClass(serviceName) : this.serviceClassList.slice())),
          );
          this.setServiceList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameService(service: any, service_type: string) {
    if (service_type == 'service_type')
      this.displayNameSerivceType(service);
    else
      this.displayNameSerivceClass(service);

  }
  displayNameSerivceType(service: ServiceTypeModel): string {
    return service && service.serviceTypeDescription ? service.serviceTypeDescription : '';
  }

  private _filterService(name: string): ServiceTypeModel[] {
    const filterValue = name.toLowerCase();

    return this.serviceTypeList.filter(option => option.serviceTypeDescription.toLowerCase().includes(filterValue));
  }


  displayNameSerivceClass(service: ServiceClassModel): string {
    return service && service.serviceClassDescription ? service.serviceClassDescription : '';
  }

  private _filterServiceClass(name: string): ServiceClassModel[] {
    const filterValue = name.toLowerCase();

    return this.serviceClassList.filter(option => option.serviceClassDescription.toLowerCase().includes(filterValue));
  }

  checkServiceChange(e: any, item: ServiceTypeModel) {
    if (item.serviceTypeID == -1) {
      this.serviceTypeList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.serviceTypeList.forEach(x => {
        if (item.serviceTypeID == x.serviceTypeID) {
          x.selected = e.checked;
        }
      });
    }

    this.selectedServices = ''; 
    this.setServiceList();
  }

  setServiceList() {
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
    this.selectedServicesCountText = "";
    if (this.serviceTypeList.length > 0) {
      if (this.selectedServiceTypeList.length == this.serviceTypeList.length) {
        this.selectedServicesCountText = ": All";
      }
      else {
        if (this.selectedServiceTypeList.length > 0)
          this.selectedServicesCountText = ": " + this.selectedServiceTypeList.length.toString();
      }
    }

    this.selectedServiceClassList = this.serviceClassList.filter(u => u.selected == true);
    this.selectedServicesCountText = "";
    if (this.serviceClassList.length > 0) {
      if (this.selectedServiceClassList.length == this.serviceClassList.length) {
        this.selectedServicesCountText = ": All";
      }
      else {
        if (this.selectedServiceClassList.length > 0)
          this.selectedServicesCountText = ": " + this.selectedServiceClassList.length.toString();
      }
    }
  }

  clickDoneService() {
    this.showServiceList = false;
    this.showLegend = false;

    this.clickSubmit();
  }

  clickResetService() {
    this.serviceTypeList.forEach(x => {
      x.selected = false;
    });
    this.f.service_search.setValue("");
    this.setServiceList();
    this.clickSubmit();
  }

  serviceTypeChange(e: any) {
    this.service = e.target.value;
    this.f.service_search.setValue('');
    this.selectedServiceTypeList = [];
    // this.selectedServiceClassList = [];
    
    this.selectedServicesCountText = "";
    this.getServiceList();
  }

//service end


  checkClassChange(e: any, item: ServiceClassModel) {
 
    let a = 0;
    if(e.checked){
      this.selectedClassList.push(item.serviceClassID);
      this.filteredServiceTypeList = this.serviceTypeList.filter(type => this.selectedClassList.includes(type.serviceClassID));
    }
    else{ 
      let index = this.selectedClassList.findIndex(c => c == item.serviceClassID)
      this.selectedClassList.splice(index, 1 );
 
      this.filteredServiceTypeList = this.serviceTypeList.filter(type => this.selectedClassList.includes(type.serviceClassID));
    }
  
    this.filteredServiceList = of(this.serviceTypeList).pipe(
      map(serviceTypes => serviceTypes.filter(type => this.selectedClassList.includes(type.serviceClassID)))
    );
    if(this.selectedClassList.length == 0){
      
      this.filteredServiceTypeList = this.serviceTypeList;
      this.filteredServiceList = of(this.serviceTypeList);

    } 

    this.setServiceList();
  }

  
  clickSubmit() {
    this.submitted = true;
    this.errMsg = "";
  
    this.selectedServices = '';
    this.selectedServiceIds = '';
    this.serviceTypeList.forEach(x => {
      if (x.selected) { 
        this.selectedServices += x.serviceTypeDescription + ', ';
        this.selectedServiceIds += x.serviceTypeID + ',';
      }
    });
    this.selectedServices = this.selectedServices.slice(0, -2);
    this.selectedServiceIds = this.selectedServiceIds.slice(0, -1);

    this.f.service_list.reset();
    this.f.service_list.setValue(this.selectedServices);

    // this.getData();
  }

  clickLegend( ) {
  
    this.showLegend = !this.showLegend;  
  }
  
  insertText(yourText: string) {
    const selection = this.emailCKEditorRef.editorInstance.model.document.selection;
    const position = selection.getFirstPosition(); 

    this.emailCKEditorRef.editorInstance.model.change((writer: any) => {
      writer.insertText(yourText, position);
    });

    // const insertedText = yourText; // Change this to the text you want to insert
    // const currentContent =  this.emailCKEditorRef.editorInstance.getData();

    // const newContent = currentContent + insertedText;
    // this.emailCKEditorRef.editorInstance.setData(newContent);
  }
  
  
  copyToClipboard() {
    const textField = document.createElement('textarea');
    textField.value = this.emailCKEditorRef.elementRef.nativeElement.innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    this.copied = true;
    this.showSuccessCopy = true;

    // Optional: Reset the "copied" state after a certain time
    setTimeout(() => {
      this.copied = false;
      this.showSuccessCopy = false;

    }, 2000); // Reset after 2 seconds
  }

}
