<div style="padding-left: 1em;">
  <div style="padding-top:0.5em; margin-top:0em">
    <h5>{{dialogTitle}}</h5>
  </div>
  <div style="display: flex">
    <div style="margin-top:1em">
      <i class="material-icons-two-tone icon_orange" style="font-size: xx-large">warning_amber</i>
    </div>
  <div style="padding-left:1em; padding-top:1em;white-space:pre-line">
    {{message}}
  </div>
  </div>

  <div style="display: flex; align-items: center; justify-content: center; padding-top: 2em;">
    <button mat-button class="btn-high" [mat-dialog-close]="true">{{buttonText1}}</button>&nbsp;&nbsp;
    <button mat-button class="btn-low" (click)="close()">{{buttonText2}}</button>&nbsp;
  </div>
</div>

