<div style="margin-top:0em; padding-top:0em; display:flex;">
  <form [formGroup]="customer_accounting_profile_form" *ngIf="customer_accounting_profile_form">
    <div class="d-inline-flex">
      <div style="background-color: #f8f8f8; width: 20em; padding-left: .5em">
        <div class="form-group" style="margin-top:.5em;margin-bottom:0em">
          Accounting Setup
        </div>
        <div style="display:inline-flex">

          <div class="form-group" style="margin-top:0em; margin-right:0.625em;">
            <label for="accounting_id" style="font-size:small">Trip Code Identifier</label>
            <label style="font-size:x-small">Should be a 3 alphanumeric characters, no special characters are allowed.</label>
            <div style="display:inline-flex">
              <div class="form-group" style="margin-left: 0em;  margin-bottom:0em">
                <input type="text" oninput="this.value = this.value.toUpperCase()" maxlength="3" formControlName="accounting_id" class="form-control" style="padding: 0em; width: 4em;background-color:#eaf0fd; height: 1.5625em; font-size: small" />
              </div>
              <div style="margin-top:-.4em;margin-left:.5em">
                <button class="btn btn-secondary" style="background-color:forestgreen;color:white;width:7em;" (click)="checkCustomerAccountID($event)">Validate Code</button>
              </div>
            </div>
          </div>



        </div>
        <div style="display:inline-block" *ngIf="accountID_msg!=''"><p style="font-size:small" class="p-0 alert alert-danger">&nbsp;{{accountID_msg}}&nbsp;</p></div>
        <div style="display:inline-block" *ngIf="accountID_good!=''"><p style="font-size:small" class="p-0 alert alert-success">&nbsp;{{accountID_good}}&nbsp;</p></div>
        <div>
          <div class="form-group" style="margin-top:0em; margin-right:0.625em">
            <label for="billing_status" style="font-size:small">Billing Status</label>
            <div style="margin-top:-0.35em;">
              <select *ngIf="f" formControlName="billing_status"  class="form-control form-control-sm dropdown" style="width:min-content">
                <option *ngFor="let bill of billingStatusList" [ngValue]="bill.billingStatusID">{{bill.billingStatusDescription}}</option>
              </select>
            </div>
          </div>
        </div>
        <div style="display:flex">
          <!--<div class="form-group" style="margin-top:0em; margin-right:0.625em">
            <label for="account_reference_po" style="font-size:small">Account Reference PO#<br />(include on all billing)</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
              <input type="text" formControlName="account_reference_po" class="form-control" style="padding: 0em; width: 8em; height: 1.5625em; background-color:#eaf0fd;font-size: small" />
            </div>
          </div>-->
          <div class="form-group" style="margin-top:0em; margin-right:0.625em">
            <label for="requires_backup_invoices" style="font-size:small">Include Backup Invoices</label>
            <div style="margin-top:-0.35em;">
              <select *ngIf="f" formControlName="requires_backup_invoices" class="form-control form-control-sm dropdown">
                <option *ngFor="let i of requiresBackupInvoices" [ngValue]="i.requiresBackupInvoicesID">{{i.requiresBackupInvoicesDesc}}</option>
              </select>

            </div>
          </div>
        </div>

        <div>

          <div class="form-group" style="margin-top:.5em">
            <div>
              <button mat-button class="btn-high" (click)="saveAccountingData()">Save</button>
            </div>
            <div class="mt-2">
              <app-success-message [successMsg]="" *ngIf="showSuccessMsg"></app-success-message>
            </div>
          </div>
        </div>

      </div>
      <div style="position:relative;background-color: #f8f8f8; width: 62em; margin-left: .5em; padding-left: .3em; padding-bottom: .5em">
        <div style="display:inline-flex">
          <div class="form-check">
            <input type="radio" class="form-check-input" style="margin-top:.5em" id="billTo" value="billTo" name="acctType" checked formControlName="acctType" (change)="setAcctType($event)">
            <label class="form-check-label" style="font-size:small" for="billTo">Setup Bill to Accounts</label>
          </div>

          <div style="margin-left:1em" class="form-check">
            <input type="radio" class="form-check-input" style="margin-top:.5em" id="refPO" value="refPO" name="acctType" formControlName="acctType" (change)="setAcctType($event)">
            <label class="form-check-label" style="font-size:small" for="refPO">Customize PO# Reference by Registrations, Services, Crew, or Pax</label>
          </div>
        </div>
        <div style="margin-top:.5em">
          <table mat-table [dataSource]="recordList" class="example-container">
            <tr>
              <ng-container matColumnDef="bill_to_id">
                <th mat-header-cell *matHeaderCellDef>
                  Bill To Code
                </th>
                <td mat-cell *matCellDef="let element">{{element.billToID}}</td>
              </ng-container>
              <ng-container matColumnDef="bill_to_account">
                <th mat-header-cell *matHeaderCellDef>
                  Bill To Account Name
                </th>
                <td mat-cell *matCellDef="let element">{{element.billTo_AccountName}}</td>
              </ng-container>
              <ng-container matColumnDef="reference_po">
                <th mat-header-cell *matHeaderCellDef>
                  Reference PO
                </th>
                <td mat-cell *matCellDef="let element">{{element.referencePO}}</td>
              </ng-container>
              <ng-container matColumnDef="registration">
                <th mat-header-cell *matHeaderCellDef>
                  Registration
                </th>
                <td mat-cell *matCellDef="let element">{{element.registration}}</td>
              </ng-container>
              <ng-container matColumnDef="service">
                <th mat-header-cell *matHeaderCellDef>
                  Service
                </th>
                <td mat-cell *matCellDef="let element">{{element.serviceTypeDescription}}</td>
              </ng-container>
              <ng-container matColumnDef="crew">
                <th mat-header-cell *matHeaderCellDef>
                  Crew
                </th>
                <td mat-cell *matCellDef="let element">{{element.crewName}}</td>
              </ng-container>
              <ng-container matColumnDef="pax">
                <th mat-header-cell *matHeaderCellDef>
                  Pax
                </th>
                <td mat-cell *matCellDef="let element">{{element.paxName}}</td>
              </ng-container>
              <ng-container matColumnDef="effective_date">
                <th mat-header-cell *matHeaderCellDef>
                  Effective Date
                </th>
                <td mat-cell *matCellDef="let element">{{element.effectiveDate}}</td>
              </ng-container>
              <ng-container matColumnDef="expiration_date">
                <th mat-header-cell *matHeaderCellDef>
                  Expiration Date
                </th>
                <td mat-cell *matCellDef="let element">{{element.expirationDate}}</td>
              </ng-container>
              <ng-container matColumnDef="remarks">
                <th mat-header-cell *matHeaderCellDef>
                  Remarks
                </th>
                <td mat-cell *matCellDef="let element">{{element.remarks}}</td>
              </ng-container>
              <ng-container matColumnDef="disabled">
                <th mat-header-cell *matHeaderCellDef>
                  Disabled
                </th>
                <td mat-cell *matCellDef="let element">{{element.isActive? '': 'Yes'}}</td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                  Action<br /><a mat-button class="btn-high grid" (click)="clickEditAccounting($event,null)">Add</a>
                </th>
                <td mat-cell *matCellDef="let element; let i=index ">
                  <a mat-button class="btn-low grid" (click)="clickEditAccounting($event, element);">Edit</a>
                </td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
        <div class="accounting-page">
          <mat-paginator style="background-color: #f8f8f8;  border: none" #paginatorAcct *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSize]="pageSize"
                         [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
          </mat-paginator>

        </div>
      </div>
    </div>
  </form>
</div>
