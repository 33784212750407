import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { TripModel } from '../models/trip.model';
import { GroundStopService } from '../services/ground-stop.service';
import { ReturnObjModel } from '../models/return-obj.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { element } from 'protractor';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { TripAuditDialogComponent } from '../ground-stops/trip-audit-dialog.component';
import { CKEditorConfig } from '../ckeditor5/ckeditor-config';
import * as DecoupledEditor from '../ckeditor5/ckeditor';

@Component({
  selector: 'app-trip-client-instruction-edit-dialog',
  templateUrl: './trip-client-instruction-edit-dialog.component.html',
  styleUrls: ['./trip-client-instruction-edit-dialog.component.css']
})

export class TripClientInstructionEditDialogComponent implements OnInit {
  public Editor = DecoupledEditor;

  public CKEConfig = CKEditorConfig.ckeditorConfigClient;

  public onReady(editor) {

    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()

    );
    editor.setData(this.special_instruction);
  }
  dialogTitle: string;
  @ViewChild('notesCKEditor') notesCKEditorRef: any;//ElementRef;
  trip_client_instruction_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  showSpin: boolean = false;
  tripCodeGUID: string;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  returnObj = new ReturnObjModel();
  upSubmitTrip: boolean = false;
  tripCode: string;
  registration: string;
  submittedBy: string;
  submitDate: string;
  client_reference: string;
  client_trip_number;
  special_instruction: string;
  selectedGroundStopList: GroundStopModel[];
  customerGUID: string;
  userType: string;
  constructor(private readonly _dialogRef: MatDialogRef<TripClientInstructionEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopClientService: GroundStopClientService,
    private readonly _groundStopService: GroundStopService,
    private readonly _dialogService: DialogService, private readonly _dialog: MatDialog
  ) {
    this.tripCodeGUID = _data.tripCodeGUID;
    this.dialogTitle = _data.dialogTitle;
    this.customerGUID = "";
    if (_data.customerGUID != null && _data.customerGUID != undefined && _data.customerGUID != "") {
      this.customerGUID = _data.customerGUID;
    }
  }

  getAllData(): Observable<any[]> {
    let request = new AircraftTripModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.customerGUID = this.customerGUID;
    let getTripResponse = this._groundStopClientService.getTripBytId(request);
    return forkJoin([getTripResponse]);
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType == "customer" && localStorage.getItem('up.submitTrip') != 'true') {
      this.upSubmitTrip = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    else {
      this.upSubmitTrip = true;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.showSuccessMsg = false;
    this.tripCode = "";
    this.registration = "";
    this.client_reference = "";
    this.client_trip_number = "";
    this.special_instruction = "";
    this.selectedGroundStopList = [];
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let trip=new TripModel();
          trip= responses[0].model;
          this.tripCode = trip.tripCode;
          this.registration = trip.registration;
          this.submittedBy = trip.submittedBy;
          this.submitDate = trip.submitDate;
          this.client_reference = trip.customerReference;
          this.client_trip_number = trip.customerTripID;
          this.special_instruction = trip.clientInstructions;
          this.selectedGroundStopList = trip.groundStopList;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;

    });


  }

  initControls() {

    this.trip_client_instruction_form = this._formBuilder.group({      
      //client_reference: [this.client_reference],
      client_trip_number: [this.client_trip_number],
      //special_instruction: [this.special_instruction]

    });

  }

  get f() { return this.trip_client_instruction_form.controls; }

  clickSave() {
    
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }  
   
    if (this.trip_client_instruction_form.invalid) {
      return;
    }

    this.loading = true;

    let obj = new TripModel();
    obj.tripCodeGUID = this.tripCodeGUID;  
    obj.customerReference = "";// this.f.client_reference.value;
    obj.customerTripID = this.f.client_trip_number.value;
    obj.clientInstructions = this.notesCKEditorRef.editorInstance.getData();// this.f.special_instruction.value;
    obj.customerGUID = this.customerGUID;
    this._groundStopClientService.updateTripClientInstructionBytId<ResponseModel<boolean>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);          
        }
        else {
          this.errMsg = "Failed to create the business stops at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          if (response.message != "") {
            this.errMsg = response.message;
          }
          else {
            this._authService.signOut();
          }
        }
        else {
          this.errMsg = "Failed to create the business stops at this time. Please try later.";
        }
      }
      this.loading = false;
    })

  }

  clickClose() {
    this._dialogRef.close(this.isModified);
    //this._dialogRef.close(this.returnObj);
  }

  clickAudit() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: "", aircraftGUID: "", tripCodeGUID: this.tripCodeGUID, groundStopGUID: '',
      groundStopTaskGUID: '', pastHours: 0,
      userName: "", v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

}
