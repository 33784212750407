import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as atlas from 'azure-maps-control';
import { AuthenticationType, ControlPosition } from 'azure-maps-control';
import { Subject, Subscription } from 'rxjs';
import { AirportModel } from '../models/airport.model';


@Component({
  selector: 'app-azure-map-inset',
  templateUrl: './azure-map-inset.component.html',
  styleUrls: ['./azure-map-inset.component.css']
})
/** azure-map component*/
export class AzureMapInsetComponent implements OnChanges {
  /** azure-map ctor */
  constructor() {

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.InitMap();    
  }

  @Input() defaultLat: number;
  @Input() defaultLon: number;
  @Input() markerTitle: string;
  @Input() airports: AirportModel[] = [];
  subscriptions: Subscription[] = [];
  map: any;
  points: any = [];

  ngOnInit() {
    this.InitMap();    
  }



  //Create an instance of the map control and set some options.
  InitMap() {
    let map;
    if (this.airports && this.airports.length > 0)
      map = new atlas.Map('azure-map', {
        // center: [this.airports[0].longitude, this.airports[0].latitude],
        // zoom: 0.01,
        view: "Auto",
        showBuildingModels: true,
        showLogo: false,
        style: "road",
        showFeedbackLink: false,
        //Add your Azure Maps subscription key client ID to the map SDK.
        authOptions: {
          authType: AuthenticationType.subscriptionKey,
          subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
        }
      });
    else
      map = new atlas.Map('azure-map', {
        center: [this.defaultLon, this.defaultLat],
        zoom: 12,
        view: "Auto",
        showBuildingModels: true,
        showLogo: false,
        style: "road",
        showFeedbackLink: false,
        //Add your Azure Maps subscription key client ID to the map SDK.
        authOptions: {
          authType: AuthenticationType.subscriptionKey,
          subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
        }
      });

      var self = this;
      self.points = [];

    //Wait until the map resources are ready.
    map.events.add('ready', function () {

     
        //Create a data source and add it to the map.
        var datasource = new atlas.source.DataSource();
        map.sources.add(datasource);

        

      //Create a point feature and add it to the data source.
      if (self.airports && self.airports.length > 0) {
        self.airports.forEach(ap => {
          if (ap.latitude && ap.longitude) {
            datasource.add(new atlas.data.Feature(new atlas.data.Point([ap.longitude, ap.latitude]), {
              title: ap.icao
            }));

            self.points.push([ap.longitude, ap.latitude]);
          }
        });
      } else {
        datasource.add(new atlas.data.Feature(new atlas.data.Point([self.defaultLon, self.defaultLat]), {
          title: self.markerTitle
        }));
        self.points.push([self.defaultLon, self.defaultLat]);
      }

        //Add a layer for rendering point data as symbols.
        map.layers.add(new atlas.layer.SymbolLayer(datasource, null, {
          
          textOptions: {
            //Convert the temperature property of each feature into a string and concatenate "°F".
            textField: ['get', 'title'],

            //Offset the text so that it appears on top of the icon.
            offset: [0, -2]
          }
        }));
       
 
        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);
 
       
      if (self.airports && self.airports.length > 0) {
        if (self.airports.length == 1) {
          map.setCamera({
            center: [self.airports[0].longitude, self.airports[0].latitude],
            zoom: 12
          });
        }
        else {
          map.setCamera({
            bounds: bbox,
            padding: 40,
          });
        }
      }
      else
        map.setCamera({
          center: [self.defaultLon,self.defaultLat],
          zoom: 12
        });
       
    
    });
  
  }


  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }
}
