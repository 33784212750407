export class VendorNotesModel {
  vendorNotesID: number;
  vendorID: number;
  vendorNote: string;
  noteTypeID: number;
  noteTypeDesc: string;
  groupName: string;
  vendorGUID: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  includeInBrief: boolean;
}
