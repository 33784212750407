<div class="divLeft" style="width: min-content;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div>
        <div style="display: flex; justify-content: space-between;">
            <div style="display: flex; flex-direction: column;">
                <h3 style="font-weight: 200;">My Account</h3>
                <h3>Security</h3>
              </div>
            <div style="display: flex;">
                <button mat-icon-button mat-dialog-close>
                  <i class="material-icons" style="font-size: x-large;">close </i>
                </button>
              </div>
        </div>
        <div style="font-weight:500">
            <div style="display: inline-flex; width: max-content;">
                <i class="material-icons" style="margin-top: 0.2em; margin-right: 0.5em;">lock_open</i>
                <h3 style="font-weight: 200;">Password Recovery</h3>
            </div>
            <div style="font-weight: 300; font-size: small; width: 30em; margin-bottom: 1em;">
                Password recovery via email and mobile enables users to regain account access by receiving a recovery
                email or
                text message with a secure link or instructions for ​
                resetting the password.​
            </div>
        </div>
        <form [formGroup]="password_recovery_form">

            <div *ngIf="!passwordValid" class="form-group">
                <label for="old_password" style="width: max-content;">Enter your current password</label>
                <div style="display: inline-flex;">
                    <div class="row" style="margin-left:0em; white-space:nowrap">
                        <input [type]="hideOPwd ? 'password' : 'text'" formControlName="old_password"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }"
                            style="width:15.75em;height:1.5625em;background-color:#eaf0fd" maxlength="50"
                            (change)="onKeyup($event, 1)" (keyup)="onKeyup($event, 1)" (mousedown)="$event.stopPropagation()"/><i *ngIf="showOPwdEye"
                            class="material-icons small" matSuffix (click)="hideOPwd = !hideOPwd"
                            style="cursor: pointer;margin-top: 0.625em;margin-left:-1.25em">{{hideOPwd ?
                            'visibility' :
                            'visibility_off'}}</i>
                    </div>
                    <div class="form-group" style="margin-left: 3em;">
                        <button mat-button class="btn-high" (click)="validatePassword()">Submit</button>
                    </div>
                </div>
                <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                    <div *ngIf="f.old_password.errors.required">Old Password is required</div>
                </div>
            </div>

            <div class="form-group" [hidden]="!passwordValid">
                <div>
                    <label for="phoneNumber">Mobile</label>
                    <!-- <label
                        style="font-style: italic; margin-left: 14em; width: 10em;">Send
                        a
                        sample recovery message</label> -->
                </div>
                <div style="display: inline-flex;">
                    <div class="row" style="margin-left:0em; white-space:nowrap">
                        <input type="text" formControlName="phone_number"
                            style="width:15.75em;height:1.5625em;background-color:#eaf0fd" class="form-control"
                            maxlength="50" (change)="onMobileChange($event)" (keyup)="onKeyup($event, 2)" (mousedown)="$event.stopPropagation()"/>
                    </div>
                    <!-- <div class="form-group" style="margin-left: 3em;">
                        <button mat-button class="btn-high"
                            [disabled]="phoneNumber === '' || f.phone_number.status !=='VALID' || !passwordValid"
                            (click)="sendPasswordRecoveryMessage(true)">Test</button>
                    </div> -->
                </div>
            </div>

            <div class="form-group" [hidden]="!passwordValid">
                <div>
                    <label for="email">Email</label>
                </div>
                <div style="display: inline-flex;">
                    <div class="row" style="margin-left:0em; white-space:nowrap">
                        <input type="email" formControlName="email"
                            style="width:15.75em;height:1.5625em;background-color:#eaf0fd" class="form-control"
                            maxlength="50" (change)="onEmailChange($event)" (keyup)="onKeyup($event, 3)" (mousedown)="$event.stopPropagation()"/>
                    </div>
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">email is required</div>
                        <div *ngIf="f.email.errors.pattern">Invalid email</div>
                        <div *ngIf="f.email.errors.internalEmailValidator">Company email address required.</div>
                    </div>
                    <!-- <div class="form-group" style="margin-left: 3em;">
                        <button mat-button class="btn-high" [disabled]="email === '' || f.email.status !=='VALID' || !passwordValid"
                            (click)="sendPasswordRecoveryMessage(false)">Test</button>
                    </div> -->
                </div>
            </div>

        </form>
        <div *ngIf="errMsg!=''" class="d-block"><label style="color:red">{{errMsg}}</label></div>
        <div *ngIf="passwordValid" class="form-group">
            <button mat-button class="btn-high" style="margin-right: .5em;"
                [disabled]="password_recovery_form.status !=='VALID' || !passwordValid" (click)="onSave()">
                <i class="material-icons">add</i>
                Save
            </button>
            <button mat-button class="btn-low-no-shadow" mat-dialog-close>
                <i class="material-icons">close</i>
                Close
            </button>
        </div>
        <div *ngIf="showSuccessMsg">
            <app-success-message [successMsg]="successMsg"></app-success-message>
        </div>
    </div>
</div>