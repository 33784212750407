<div style=" background: #f8f8f8 !important; " >
  <div style="background: #f8f8f8 !important; margin-bottom: 0.25em" align="end" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div><mat-icon class="pointer" (click)="clickClose()">close</mat-icon></div>

  </div>
  <div
    style="margin-top: 0em; margin-left: 1em; margin-right: 1em; background-color: white; padding-top: 0em; scroll-behavior: auto">
   
   
    <div  *ngIf="!isMisc"  style="max-height: 36em;  overflow: auto;  margin-bottom: 1em;">
      <div style=" background-color: white;margin-left:1em;margin-right:1em; margin-top:-0.3em">
        <div class="d-inline-flex" style=" text-align: center; padding: 0.5em; "><mat-icon>email</mat-icon>
          <h4 style="margin-left: 0.3em;">Required Permit Documents List </h4>
        </div>
      </div> 
      <div *ngFor="let docType of countryRequiredDocumentTypeList">
        <div style="margin-left:0.45em;  " class="mat-checkbox-smaller" [style.margin-top.em]="i==0?0.25:0">
          <div style="display: flex; justify-content: space-between;margin-right:0.45em;">
            
            <!-- <mat-checkbox (change)="checkT oChange($event, docType)"> {{docType.documentTypeDescription}}</mat-checkbox> -->
  
            <mat-checkbox (change)="checkToChange($event, docType)"> {{docType.documentTypeDescription}}</mat-checkbox>
            <div style="width: 3em;">
              <input type="text" [(ngModel)]="docType.docCount" (input)="docCountOnChange($event, docType)" class="form-control" style="height: 1.5em; background-color:#eaf0fd;" />
            </div> 
         
          </div>
        </div>
      </div>
    </div> 

   
    <div *ngIf="isMisc"  style="max-height: 36em;  overflow: auto;  margin-bottom: 1em;">
      <div style=" background-color: white;margin-left:1em;margin-right:1em; margin-top:-0.3em">
        <div class="d-inline-flex" style=" text-align: center; padding: 0.5em; "><mat-icon>email</mat-icon>
          <h4 style="margin-left: 0.3em;">Miscellaneous Documents List </h4>
        </div>
      </div> 
      <div *ngFor="let docType of countryRequiredMiscDocumentTypeList">
        <div style="margin-left:0.45em;  " class="mat-checkbox-smaller" [style.margin-top.em]="i==0?0.25:0">
          <div style="display: flex; justify-content: space-between;margin-right:0.45em;">
            
            <!-- <mat-checkbox (change)="checkT oChange($event, docType)"> {{docType.documentTypeDescription}}</mat-checkbox> -->
  
            <mat-checkbox (change)="checkToChange($event, docType)"> {{docType.documentTypeDescription}}</mat-checkbox>
            <div style="width: 3em;">
              <input type="text" [(ngModel)]="docType.docCount" (input)="docCountOnChange($event, docType)" class="form-control" style="height: 1.5em; background-color:#eaf0fd;" />
            </div> 
         
          </div>
        </div>
      </div>
    </div> 


  </div>

  <div style="padding-bottom:1em;padding-left:1em" layout="row" layout-align="end">
    <div>
      <div style="display: flex; max-width: 25em !important;  margin-top: 0em">

        <button *ngIf="!isMisc" mat-button class="btn-high" style="margin-right: 1em;" (click)="addSelectedDocTypes()">+Add</button>
        <button *ngIf="isMisc"  mat-button class="btn-high" style="margin-right: 1em;" (click)="addSelectedMiscDocTypes()">+Add</button>

        <button type="button" mat-button class="btn-low" (click)="clickClose()">
          <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
            <div>CLOSE</div>
          </div>
        </button>&nbsp; 
      </div>
      <!-- <app-success-message style="display: inline-flex; max-width: 30em !important;" *ngIf="showSuccessMessage"
        [successMsg]="successMsg"></app-success-message> -->

    </div>
    <!--<app-success-message ></app-success-message>-->
  </div>
</div>