<div mat-dialog-title cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
	<h5 style="align-content:center">Flight Pro International Privacy Policy</h5>
</div>
<div mat-dialog-content style="background-color:white;padding:.5em;font-size:small;margin-top:-.35em;margin-left:.5em;margin-right:.5em">
	<div>
		<p style="font-weight:bold !important;">
			Updated: September 1, 2023
		</p>
		<ol>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Introduction</span>
				<p></p>
				<p>
					Flight Pro International, LLC (the “Company,” “we,” “our” or “us”) respects your privacy and are committed to protecting it through our compliance with this policy. This Privacy Policy describes the types of information we may collect from you or that you may provide when you visit the websites www.flightprointernational.com, www.flightprointl.com; www.flightproconnect.com; www.fltprointl.com; www.fpiconnect.com; www.fpitrips.com, and our social media accounts, mobile applications, and other tools, technology and programs (collectively our “Platform”) and our practices for collecting, using, maintaining, and protecting that information.
				</p>
				<p>
					This policy applies to information we collect:
				</p>
				<ul>
					<li>
						On the websites www.flightprointernational.com; www.flightprointl.com; www.flightproconnect.com; www.fltprointl.com; www.fpiconnect.com; and www.fpitrips.com.
					</li>
					<li>
						In email, text, and other electronic messages between you and Company.
					</li>
					<li>
						Through interfaces that you use to gain access to, or when you use any of our Platform.
					</li>
					<li>
						Through interactions with social media platforms connected to our Platform.
					</li>
				</ul>
				<p></p>
				<p>
					Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Platform. By accessing or using our Platform or our Services or if you agreed to our <a routerLink="" (click)="openTerms()">Terms of Use Agreement</a>, you agree to this privacy policy. This policy may change from time to time (see <a routerLink="" (click)="scroll(changes)">Changes to Our Privacy Policy</a>). Your continued use of our Platform after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
				</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Personal Information We Collect About You</span>
				<p></p>
				<p>We collect several types of information from and about users of our Platform, including information:</p>
				<ul>
					<li>
						By which you may be personally identified, such as name, dates of birth, postal address, e-mail address, cell and work telephone numbers, passport details, travel details including flight manifests, locations details, credit card number, debit card number, or any other financial information and any other identifier by which you may be contacted online or offline ("personal information");
					</li>
					<li>
						Regarding your commercial transactions, including records of or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies; and
					</li>
					<li>
						About your internet connection, IP address, the equipment you use to access our Platform, your history on the Platform, location information, telemetry data, user history and usage details.
					</li>
				</ul>
				<p></p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">How We Collect Personal Information About You</span>
				<p></p>
				<p>We collect personal information about you:</p>
				<ul>
					<li>
						Directly from you when you voluntarily provide it to us.
					</li>
					<li>
						Automatically as you navigate through the Platform and from your browser or device. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies used by the Company.
					</li>
					<li>
						Records and copies of your correspondence (including email addresses), if you contact us or submit an online request.
					</li>
					<li>
						Through an account profile, if you create one with us.
					</li>
					<li>
						Through user history on our Platform.
					</li>
				</ul>
				<p></p>
				<ol style="list-style-type:upper-alpha;font-weight:bold">
					<li>
						<p style="font-weight:bold;font-style:italic;margin-left:.5em;">Information You Provide to Us</p>
						<p>The information that you provide to us through our Platform may include:</p>
						<ul>
							<li>
								Information that you provide by filling in forms on our Platform. This includes information provided at the time of using our service, posting material, or requesting further services. We may also ask you for information when request an assessment, purchase our products or services, talk with our employees, or when you report a problem with our Platform.
							</li>
							<li>
								Records and copies of your correspondence (including email addresses), if you contact us.
							</li>
							<li>
								Details of transactions and reservations you carry out through our Platform. You may be required to provide personal and financial information before using our Services and Platform.
							</li>
							<li>
								Through creation of accounts through our Platform.
							</li>
						</ul>
						<p></p>
					</li>
					<li>
						<p style="font-weight:bold;font-style:italic;margin-left:.5em;">Information We Collect About You Automatically</p>
						<p>As you navigate through and interact with our Platform, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
						<ul>
							<li>
								Details of your visits to our Platform, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Platform.
							</li>
							<li>
								Information about your computer and internet connection, including your IP address, operating system, and browser type.
							</li>
						</ul>
						<p></p>
						<p>We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). Contact for information on how you can opt out of behavioral tracking on this Platform and how we respond to web browser signals and other mechanisms that enable consumers to exercise choice about behavioral tracking.</p>
						<p>The information we collect automatically includes statistical and analytical data and in some instances personal information.  We collect such data automatically because it helps us to improve our Platform and to deliver a better and more personalized Service, including by enabling us to:</p>
						<ul>
							<li>
								Estimate our audience size and usage patterns.
							</li>
							<li>
								Store information about your preferences, allowing us to customize our Platform Interface according to your individual interests.
							</li>
							<li>
								Speed up your searches.
							</li>
							<li>
								Recognize you when you return to our Platform.
							</li>
						</ul>
						<p></p>

					</li>
					<li>
						<p style="font-weight:bold;font-style:italic;margin-left:.5em;">Third-Party Use of Cookies and Other Tracking Technologies</p>
						<p>We do not use third parties to collect personal information.</p>
					</li>
				</ol>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">How We Use Your Information</span>
				<p></p>
				<p>We use information that we collect about you or that you provide to us, including any personal information:</p>
				<ul>
					<li>
						To present our Platform and its contents to you.
					</li>
					<li>
						To provide you with information or services that you request from us.
					</li>
					<li>
						To fulfill any other purpose for which you provide it.
					</li>
					<li>
						To prevent potentially prohibited or illegal activities, comply with applicable law and enforce our Terms of Service.
					</li>
					<li>
						To notify you about changes to our Platform or any products or services we offer or provide though it.
					</li>
					<li>
						To provide the products and services you request.
					</li>
					<li>
						For creation of flight manifests.
					</li>
					<li>
						To interact with and contact you.
					</li>
					<li>
						To create, maintain, customize, and secure your account with us.
					</li>
					<li>
						To process your requests, purchases, transactions, and payments and prevent transactional fraud.
					</li>
					<li>
						For any other purpose with your consent.
					</li>
					<li>
						We may also use your information to contact you about our own and third-parties' goods and services that may be of interest to you.
					</li>
					<li>
						We do not sell your information to third parties. However, we may disclose a User’s personal information related to reviews, photographs submitted through our portal or photos you give the Company permission to disclose through any of our Platforms. For more information, please see our <a routerLink="" (click)="openTerms()">Terms of Use Agreement</a>.
					</li>
				</ul>
				<p></p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Your Choices About How We Use Your Information</span>
				<p></p>
				<p>We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</p>
				<ul>
					<li>
						<b>Tracking Technologies and Advertising.</b> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of our Platform may then be inaccessible or not function properly.
					</li>
				</ul>
				<p></p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Accessing and Correcting Your Information</span>
				<p></p>
				<p>
					You may also send us an email at <a href="mailto:FPIAdmin@flightprointl.com">FPIAdmin@flightprointl.com</a> to request access to, correct or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
				</p><p>
					If you delete your User Contributions from the Platform, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other Platform users. Proper access and use of information provided on the Platform, including User Contributions, is governed by our <a routerLink="" (click)="openTerms()">Terms of Use Agreement</a>.
				</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Residents of Canada</span>
				<p></p>
				<p>
					The Company is located in the United States and some of its service providers are located in the United States or elsewhere outside of Canada. As a result, your personal information will be processed and stored outside of Canada for the purposes described in this Privacy Policy. While outside of Canada, personal information will be subject to applicable local laws, which may permit government and national security authorities in those jurisdictions to access your personal information in certain circumstances.
				</p><p>
					We will maintain your personal information we collect on our servers, which are accessible by our authorized employees, representatives and service providers for the purposes detailed in this Privacy Policy. We do not sell or disclose your information. You may, however, request correction of your personal information or withdraw consent to collection by emailing <a href="mailto:FPIAdmin@flightprointl.com">FPIAdmin@flightprointl.com</a>.
				</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Residents of the European Union</span>
				<p></p>
				<p>
					This notice document (the “Notice”) sets forth the additional rights available to European Union (“EU”), United Kingdom (“UK”) and European Economic Area residents (“EEA”) and supplements the information contained in the Company’s Privacy Policy and applies solely to persons located in the EU, UK and EEA. The purpose of this notice is to comply with the EU General Data Protection Regulation 2016/679 (“GDPR”) and the UK GDPR (which is the UK law version of the EU General Data Protection Regulation by virtue of section 3 of the European Union (Withdrawal) Act 2018 and as amended by Schedule 1 to the Data Protection, Privacy and Electronic Communications (Amendments etc.) (EU Exit) Regulations 2019).  Any applicable terms defined in the GDPR or UK GDPR have the same meaning when used in this Notice. Similarly any terms defined in the Company’s Privacy Policy have the same meaning when used in this Notice.
				</p>
				<p>
					This Notice does not apply to workforce-related personal information such as a Company registration number, a Company email, or anonymized data.
				</p>
				<p style="font-weight:bold !important">Data Controllers</p>
				<p>
					In most instances, the Company acts as the Data Controller related to your personal information.  In other situations, we may be considered only a Processor of your personal information. In such case, the identity of the Data Controller will be disclosed to you by the Company or another third-party.
				</p>
				<p>
					In addition, the EU General Data Protection Regulation 2016/679 (“GDPR”) requires  organizations which are subject to the GDPR and are not established in the EEA, to designate a representative in the EEA to act on its behalf regarding GDPR compliance, and to deal with any supervisory authorities or data subjects in this respect. Similarly, the UK GDPR (which is the UK law version of the EU General Data Protection Regulation by virtue of section 3 of the European Union (Withdrawal) Act 2018 and as amended by Schedule 1 to the Data Protection, Privacy and Electronic Communications (Amendments etc.) (EU Exit) Regulations 2019) also requires organizations which are subject to the UK GDPR and are not established in the UK, to designate a representative in the UK to act on its behalf regarding UK GDPR compliance, and to deal with any supervisory authorities or data subjects in this respect.
				</p>
				<p>
					Company has appointed Alan Owen as its representative to act on its behalf if, and when, they undertake data processing activities in which the GDPR applies.  He may be contacted by emailing him at <a href="mailto:aowen@flightprointl.com">aowen@flightprointl.com</a>.
				</p>
				<p style="font-weight:bold !important">Your Rights and Choices</p>
				<p>The GDPR and the UK GDPR provides persons within the EU, UK, and EEA with specific rights regarding their personal data. This section describes these rights and explains how to exercise those rights.</p>
				<ol style="list-style-type:lower-alpha;font-weight:normal">
					<li>
						Right of access. You have the right to obtain: (i) confirmation of whether, and where, we are processing your personal information; (ii) information about the categories of personal information we are processing, the purposes for which we process your personal information and information as to how we determine applicable retention periods; (iii) information about the categories of recipients with whom we may share your personal information; and (iv) a copy of the personal information we hold about you.
					</li>
					<li>
						Right of portability. You have the right, in certain circumstances, to receive a copy of the personal information you have provided to us in a structured, commonly used, machine-readable format that supports re-use, or to request the transfer of your personal data to another person.
					</li>
					<li>
						Right to rectification. You have the right to obtain rectification of any inaccurate or incomplete personal information we hold about you without undue delay.  You may request that we complete and/or correct any personal data we have collected on you.
					</li>
					<li>
						Right to erasure. You have the right, in some circumstances, to require us to erase your personal information without undue delay if the continued processing of that personal information is not justified.
					</li>
					<li>
						Right to restriction. You have the right, in some circumstances, to require us to limit the purposes for which we process your personal information if the continued processing of the personal information in this way is not justified, such as where the accuracy of the personal information is contested by you.
					</li>
					<li>
						Right to object. You have a right to object to any processing based on our legitimate interests where there are grounds relating to your particular situation. There may be compelling reasons for continuing to process your personal information, and we will assess and inform you if that is the case. You can object to marketing activities for any reason.
					</li>
				</ol>
				<p></p>
				<p>
					If you are resident in France, you also have the right to set guidelines for the retention and communication of your personal information after your death.
				</p>
				<p>
					If you wish to exercise one of these rights, please contact us at <a href="mailto:FPIAdmin@flightprointl.com">FPIAdmin@flightprointl.com</a> and we will comply with any request to the extent required under applicable law.  We will seek to deal with your request without undue delay, and in any event in accordance with the requirements of the applicable law. Be sure to include your contact information so that we can accurately process your request in a timely manner.  Please note we may keep a record of your communications to help us resolve any issues.
				</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Persons Under the Age of 18 Should Not Use this Platform</span>
				<p></p>
				<p>Our Platform is not intended for persons under 18 years of age. No one under age 18 may provide any personal information to or on our Platform. We do not knowingly collect personal information from anyone under 18. If you are under 18, do not use or provide any information on our Platform or through any of its features, register on the Platform, make any purchases through the Platform, use any of the interactive or public comment features of our Platform, or provide any information about yourself to us, including your name, date of birth, address, telephone number, email address, or any screen name or user name you may use. </p>
				<p>If we learn we have collected or received personal information from a person under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a person under 18, please contact us at <a href="mailto:FPIAdmin@FlightProIntl.com">FPIAdmin@FlightProIntl.com</a> or through the contact information set forth in the <a routerLink="" (click)="scroll(contactUs)">Contact Us</a> section below.</p>
				<p>California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information. Please see <a routerLink="" (click)="scroll(statePrivacy)">Your State Privacy Rights</a> for more information.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Data Security</span>
				<p></p>
				<p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment will be encrypted using SSL technology.</p>
				<p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Platform, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
				<p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Platform. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Platform.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em" #changes>Changes to Our Privacy Policy</span>
				<p></p>
				<p>It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will provide notice to you and require you to agree to the new policy. The date the privacy policy was last revised, is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Platform and this privacy policy to check for any changes.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Retention Policy</span>
				<p></p>
				<p>We retain the information we collect about you for as long as is necessary for the purposes for which we originally collected it. We may retain personal information for legitimate business purposes or as required by law.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Notice of Collection for California Residents</span>
				<p></p>
				<p>
					Under California law, specific disclosures are required and California residents have additional rights regarding their personal information. To learn more about California residents' privacy rights, review the California Consumer Privacy Act (CCPA) and the California Privacy Rights Act. To exercise any of your rights as a California resident, please send an email to <a href="mailto:FPIAdmin@flightprointl.com">FPIAdmin@flightprointl.com</a> or write us at: 6002 Rogerdale Rd, Suite 425, Houston, TX 77072 and include your name, email address, and California postal address.
				</p>
				<p>
					The table below summarizes our general data handling practices, which are also described above in the Privacy Policy.
				</p>
				<table border="1" cellpadding="2" style="font-weight:normal">
					<tr><td width="80%" align="center"><b>Personal Information Category</b></td><td width="20%" align="center"><b>Sold or Shared</b></td></tr>
					<tr><td><b>Identifiers</b>, such as name, postal address, internet protocol address, email address, or other similar identifiers.</td><td align="center">No</td></tr>
					<tr><td><b>Personal information</b>, which includes a name, address, telephone number, bank account number, credit card number, debit card number, or any other financial information.</td><td align="center">No</td></tr>
					<tr><td><b>Commercial information</b>, such as records of products or services purchased, obtained, or considered, or other purchasing or consuming histories through our website.</td><td align="center">No</td></tr>
					<tr><td><b>Internet or other similar network activity</b>, such as information on a consumer's interaction with the website.</td><td align="center">No</td></tr>
				</table>
				<p></p>
				<p>We may use information that we collect about you or that you provide to us in the following ways:</p>
				<ul>
					<li>To present our website and its contents to you.</li>
					<li>
						To provide you with information, products, or services that you request from us.
					</li>
					<li>
						To fulfill any other purpose for which you provide it.
					</li>
					<li>
						To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.
					</li>
					<li>
						To send marketing emails or text messages notifying you of new products, services, or offers;
					</li>
					<li>
						To improve the content and navigation on our website; and
					</li>
					<li>
						To enforce our Terms or share such information with (i) the issuer of a criminal or civil subpoena; (ii) governmental authorities when legally required to do so; or (iii) governmental authorities investigating a crime.
					</li>
				</ul>
				<p></p>
				<p>
					Residents of California may have the following rights:
				</p>
				<ol style="list-style-type:lower-alpha;font-weight:bold">
					<li>
						Right to know and access your Personal Information. <span style="font-weight:normal">You have the right to:</span>
					</li>
					<ul>
						<li>
							know the categories of personal information, including sensitive personal information, that we collect and the categories of sources from which we got the information;
						</li>
						<li>
							know the business or commercial purposes for which we collect and provide personal information to others;
						</li>
						<li>
							know the categories of third parties and other entities to whom we provide personal information; and
						</li>
						<li>
							access the specific pieces of personal information we have collected about you.
						</li>
					</ul>
					<li>
						Right to deletion. <span style="font-weight:normal">In some circumstances, you may ask us to delete your personal information. These exceptions may include pending transactions or bidding or other incomplete transactions connected to your account.</span>
					</li>
					<li>
						Right to Correct Your Personal Information. <span style="font-weight:normal">You may request that we modify or update your personal information if it is inaccurate. Please be aware that not all personal information is able to be corrected, and we reserve the right to deny a request to correct your information where exceptions apply.</span>
					</li>
					<li>
						Right to Opt Out. <span style="font-weight:normal">You may limit or opt out of the use of your personal information for certain purposes, including the below. Please review our entire Privacy Policy for how we use your information. Please remember that cookie-level opt-outs are browser and device-specific and applied to the website with respect to which you complete your opt out request. If you use multiple devices such as your smartphone, laptop, or tablet to visit the website, you will need to submit separate requests from these devices.</span>
					</li>

					<ul>
						<li>
							<i>Right to Opt Out of Sale/Targeted Advertising</i>: We do not “share” personal information with third parties for targeted advertising purposes or in ways that that constitute a “sales” under the CCPA. If we do, you have the right to request that we not “sell” or “share” your personal information for these purposes on a going forward basis.
							<p>If you choose to use the website to interact with sellers and third parties, including if you successfully win an auction, we will provide the other user or third party, as appropriate, with your personal information to facilitate the transaction you requested (including your name and contact information). This type of sharing does not constitute a “sale” of your personal information by us as it is done at your direction.</p>
						</li>
						<li>
							<i>Right to Limit Processing of Sensitive Personal Information</i>: Certain pieces of personal information that we have about you may be considered “sensitive” under CCPA, including information needed to provide you with access to the website (i.e., account and payment information). These pieces of information are not subject to the right to limit.

						</li>
					</ul>
					<li>
						Right to be free from discrimination.  <span style="font-weight:normal">You may exercise any of the above rights without fear of being discriminated against.</span>

						<p>We use the information you provide to make your CCPA rights requests to verify your identity, locate the personal information we may hold about you, and act upon your request. We strongly recommend that you submit the email and postal address that you used when you created an account. After you submit a CCPA rights request, you may be required to verify access to the email address you submitted.</p>
					</li>
					<li>
						Authorized Agents
						<p>For any of the CCPA rights, you may designate an authorized agent to make a request on your behalf. In the request, you or your authorized agent must provide information sufficient for us to verify the identity of your agent and that your agent has been properly authorized to act on your behalf. This may take additional time to fulfill your request. If you are an authorized agent working on behalf of a consumer, you will need to fill out the relevant CCPA request form listed above on behalf of the consumer with the consumer’s information. Please note that the consumer you are working on behalf of must have a California postal address and will need to verify access to the email account used to submit the CCPA request.</p>
					</li>
				</ol>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em" #statePrivacy>Your State Privacy Rights</span>
				<p></p>
				<p>State consumer privacy laws may provide their residents with additional rights regarding our use of their personal information. Colorado, Connecticut, Virginia, and Utah each provide their state residents with rights to:</p>
				<ul>
					<li>Confirm whether we process their personal information.</li>
					<li>Access and delete certain personal information.</li>
					<li>Data portability.</li>
					<li>Opt-out of personal data processing for targeted advertising and sales.</li>
				</ul>
				<p></p>
				<p>Colorado, Connecticut, and Virginia also provide their state residents with rights to:</p>
				<ul>
					<li>Correct inaccuracies in their personal information, taking into account the information's nature processing purpose.</li>
					<li>Opt-out of profiling in furtherance of decisions that produce legal or similarly significant effects.</li>
				</ul>
				<p></p>
				<p>Nevada provides its residents with a limited right to opt-out of certain personal information sales.</p>
				<p>To exercise any of these rights as a resident of any of these states, please email <a href=mailto:FPIAdmin@flightprointl.com>FPIAdmin@flightprointl.com</a>.</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em" #contactUs>Contact Us</span>
				<p></p>
				<p>To ask questions, comment on, or to register a complaint or concern about this privacy policy and our privacy practices, or to request information relevant to your personal information, contact us at:</p>
				<p>
					Flight Pro International, Inc.<br />
					Attn: Privacy<br />
					6002 Rogerdale Rd., Suite 425<br />
					Houston, TX 77072<br />
					<a href=mailto:FPIAdmin@flightprointl.com>FPIAdmin@flightprointl.com</a><br />
					346-415-6900<br />
				</p>
			</li>
			<li style="font-weight:bold;">
				<span style="text-decoration:underline;margin-left:.5em">Policy Modifications</span>
				<p></p>
				<p>
					We may change this Privacy Policy from time to time. We will post any changes here, so be sure to check back periodically. This Policy was last updated on September 1, 2023.
				</p>
			</li>
		</ol>
	</div>
</div>
<div mat-dialog-actions>
	<button mat-button mat-dialog-close class="btn-low">Close</button>
</div>
