import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { CategoryModel } from '../models/category.model';
import { HelpGuideModel } from '../models/help-guide.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CONTAINER_DATA, HelpGuideService } from '../services/help-guide.service';
import { HelpGuideCategoriesComponent } from './help-guide-categories.component';
import { HelpGuideDetailsEditComponent } from './help-guide-details-edit.component';
import { HelpGuideDetailsComponent } from './help-guide-details.component';
import { HelpGuidesComponent } from './help-guides.component';

@Component({
  selector: 'app-help-guide-topics',
  templateUrl: './help-guide-topics.component.html',
  styleUrls: ['./help-guide-topics.component.css']
})


export class HelpGuideTopicsComponent implements OnInit {
  helpGuideList: HelpGuideModel[];
  componentPortal: ComponentPortal<HelpGuideCategoriesComponent>;
  detailsPortal: ComponentPortal<HelpGuideDetailsComponent>;
  editPortal: ComponentPortal<HelpGuideDetailsEditComponent>;
  category: CategoryModel;
  isAdmin: boolean=false;
  constructor(private readonly _authService: AuthenticateService, private readonly _helpGuideService: HelpGuideService, private _injector: Injector,
    private readonly _helpGuide: HelpGuidesComponent, @Inject(CONTAINER_DATA) public componentData: any  ) {
    this.category = componentData;
  }
    ngOnInit() {
      this._authService.updateAccessTime();
     // this.isAdmin = Boolean(localStorage.getItem('ua'));
      if (localStorage.getItem('up.help') == 'true') {
        this.isAdmin = true;
      }          
      
      this._helpGuideService.getHelpGuideListByCategoryID<ResponseModel<HelpGuideModel[]>>(this.category.categoryID).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.helpGuideList = response.model;
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }

          }
        }
      });
    }


  goBack() {
    this.componentPortal = new ComponentPortal(HelpGuideCategoriesComponent,null, this.createInjector(this.category.audienceID==1? 'ops':'client'));
    this._helpGuide.selectedPortal = this.componentPortal;
  }

  showDetails(helpGuideGUID: string) {
    this.detailsPortal = new ComponentPortal(HelpGuideDetailsComponent, null, this.createInjector(helpGuideGUID));
    this._helpGuide.selectedPortal = this.detailsPortal;
  }

  addNewTopic() {
    this.editPortal = new ComponentPortal(HelpGuideDetailsEditComponent, null, this.createInjector({ helpGuideGUID: null, categoryID: this.category.categoryID }));
    this._helpGuide.selectedPortal = this.editPortal;
  }


  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }
 
}
