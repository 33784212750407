<div style=" width: 500px; margin: -1em;">
  <!--background-color: #f8f8f8;-->
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left: 1em; display: flex; ">
      <span style="margin-top: 0.2em;" class="material-symbols-outlined">
        monetization_on
      </span><h5 class="mat-h2" style="margin-bottom: 0.2em;">{{dialogTitle}} Markups  </h5>
    </div>

  </div>

  <div>
    <form *ngIf="fuel_Client_Markup_edit" [formGroup]="fuel_Client_Markup_edit" novalidate>
      <div style=" margin-top:0em; padding-left: 1em;padding-right:1em">
        <div style="background-color:white;padding:.5em;">
          <div style="display:flex">
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="clientid" style="font-size:small">Client</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <select formControlName="clientid" class="form-control form-control-sm dropdown" style=" width: 15em">
                  <option value="0">All</option>
                  <option *ngFor="let customer of customerList" [value]="customer.customerID">{{customer.customerName}}</option>
                </select>

                <div *ngIf="submitted && f.clientid.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.clientid.errors.required">Please Select</div>
                </div>
              </div>

            </div>
          </div>

          <!--<div style="display:flex">
      <div class="form-group" style="margin-top:0em; margin-right:0.625em">
        <label for="countryid" style="font-size:small">Country</label>
        <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
          <select formControlName="countryid" class=" form-control form-control-sm dropdown" style="   width: 15em; margin-bottom: 0.2em" (change)="countrychange()">
            <option value="0">All</option>
            <option value="domestic">US Domestic</option>
            <option value="intl">International</option>
            <option *ngFor="let country of countryList" [value]="country.countryID">{{ country.countryName}}</option>
          </select>

          <div *ngIf="submitted && f.countryid.errors" class="alert alert-danger p-0 small">
            <div *ngIf="f.countryid.errors.required">Please Select</div>
          </div>
        </div>
      </div>


    </div>-->
          <div>
            <div style="background-color: #f8f8f8; border-radius: .25em; width: 100%; margin-top: 1em;">
              <!--class="d-inline-flex p-1;">-->
              <!--ml-2-->
              <div>
                <label style="font-size:small">Select Country or Aiport</label>
              </div>
              <div style="display:flex">

                <div>
                  <!--<mat-radio-group [disabled]="showModified" [(ngModel)]="iscountryorairport" style="display:flex;flex-direction:column;transform:scale(.8);" [ngModelOptions]="{standalone: true}">-->
                  <mat-radio-group [(ngModel)]="iscountryorairport" style="display:flex;flex-direction:column;transform:scale(.8);" [ngModelOptions]="{standalone: true}">
                    <!--(change)="countryairportselect()"-->
                    <mat-radio-button [checked]="iscountryorairport=='country'" value="country">Country</mat-radio-button>
                    <mat-radio-button [checked]="iscountryorairport=='airport'" value="airport">Airport</mat-radio-button>
                  </mat-radio-group>
                </div>


                <div class="ml-2" style="padding-top: 1em;">

                  <div *ngIf="iscountryorairport=='country'" class="col">
                    <!--<label for="flatfeeamount" style="font-size:small">Country</label>-->
                    <div style="margin-top:-0.35em;">

                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <select [disabled]="showModified" class=" form-control form-control-sm dropdown"
                                style="width: 24.6em;" formControlName="countryid" (change)="countrychange()">
                          <!--<option value="0">All</option>-->
                          <!--<select [disabled]="showModified" [ngClass]="!showModified?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputDisabled'"
        style="width: 24.6em;" formControlName="countryid"   (change)="countrychange()">
  <option value="0">All</option>-->
                          <!--<option value="domestic">US Domestic</option>
  <option value="intl">International</option>-->
                          <option value="0">All</option>
                          <option *ngFor="let country of countryList" [value]="country.countryID">{{ country.countryName}}</option>
                        </select>
                        <!--<select [disabled]="showModified" [ngClass]="!showModified?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputDisabled'"
                    style="width: 24.6em;" formControlName="country">-->
                        <!--(change)="countryChange($event)"-->
                        <!--<option value="default">All</option>-->
                        <!--<option *ngFor="let c of countrylist" [value]="c.countryID">{{c.countryName}}</option>
                  </select>-->
                        <div *ngIf="submitted && f.countryid.errors" class="alert alert-danger p-0 small">
                          <div *ngIf="f.countryid.errors.required">Please Select</div>
                        </div>
                      </div>
                      <!--<div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                <input type="number" class="form-control form-control-sm inputBox" formControlName="flatfeeamount" style="width: 7em;" />
                 </div>-->
                      <!--<div *ngIf="submitted && f.flatfeeamount.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.flatfeeamount.errors.required">Required</div>
                </div>
                <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                  Airport is required.
                </span>-->
                    </div>
                    <span style="  font-size: x-small;">**Taxes are applicable to <b>All Airports</b>  in the <b>Selected country</b>.</span>

                  </div>
                  <div class="col" *ngIf="iscountryorairport=='airport'" style="display: flex; margin-top: -0.7em;">
                    <!--<label for="flatratepercent" style="font-size:small">Percentage</label>-->
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                      <label for="icao" style="font-size:small">ICAO</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <input type="text" formControlName="icao"
                               class="form-control form-control-sm inputBox" maxlength="255" style="width:6em" (keyup)="icaoValidation($event,'icao')" /><!--[ngClass]="{ 'is-invalid': submitted && f.icao.errors }"-->
                        <!--<input [readonly]="showModified" type="text" formControlName="icao"
                   [ngClass]="showModified?'form-control form-control-sm inputBox inputDisabled ':'form-control form-control-sm inputBox'" maxlength="255" style="width:6em" (keyup)="icaoValidation($event,'icao')" />-->
                        <!--[ngClass]="{ 'is-invalid': submitted && f.icao.errors }"-->
                      </div>
                      <div *ngIf="submitted && f.icao.errors" class="alert alert-danger p-0 small">
                        <div *ngIf="f.icao.errors.required">Required.</div>
                      </div>
                    </div>
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0em;">
                      <label for="iata" style="font-size:small">IATA</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <input type="text" formControlName="iata"
                               class="form-control form-control-sm inputBox" maxlength="255" style="width:6em" (keyup)="icaoValidation($event,'iata')" />
                        <!--<input [readonly]="showModified" type="text" formControlName="iata"
                   [ngClass]="showModified?'form-control form-control-sm inputBox inputDisabled ':'form-control form-control-sm inputBox'" maxlength="255" style="width:6em" (keyup)="icaoValidation($event,'iata')" />-->
                      </div>
                      <div *ngIf="submitted && f.iata.errors" class="alert alert-danger p-0 small">
                        <div *ngIf="f.iata.required">Required.</div>
                      </div>
                    </div>
                    <div *ngIf="countrylength>0" style=" padding-top: 1.2em; margin-left: -0.3em;">
                      <div style="background-color:#f8f8f8;border-radius:.25em" class="d-inline-flex ml-2 p-1">
                        <label style="font-size:small">Country:  <b>{{countryName}}</b></label>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--<div style="display:flex">

      <div class="form-group" style="margin-top:0em; margin-right:0.625em">
        <label for="icao" style="font-size:small">ICAO</label>
        <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">

          <input type="text" class="form-control form-control-sm inputBox" [ngClass]="setAllCountrySelection?'form-control form-control-sm inputBox inputDisabled ':'form-control form-control-sm inputBox'" formControlName="icao" style="width:7em" (keyup)="icaoValidation($event,'icao')" />

        </div>
        <div *ngIf="submitted && f.icao.errors" class="alert alert-danger p-0 small">
          <div *ngIf="f.icao.errors.required">Please Select</div>
        </div>
      </div>
      <div class="form-group" style="margin-top:0em; margin-right:0.625em">
        <label for="iata" style="font-size:small">IATA</label>
        <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">

          <input type="text" class="form-control form-control-sm inputBox" [ngClass]="setAllCountrySelection?'form-control form-control-sm inputBox inputDisabled ':'form-control form-control-sm inputBox'" formControlName="iata" style="width:7em" (keyup)="icaoValidation($event,'iata')" />

        </div>
        <div *ngIf="submitted && f.iata.errors" class="alert alert-danger p-0 small">
          <div *ngIf="f.iata.errors.required">Please Select</div>
        </div>
      </div>
      <div class="form-group" style="margin-top:0em; margin-right:0.625em">
        <label for="iata" style="font-size:small"> </label>
        <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
          <input type="checkbox" formControlName="setAllCountrySelection" (change)="setAllCountrySelectionChange($event)" /><label for="setAllCountrySelection" style="font-size:small">&nbsp;All Airports</label>
        </div>
        <div *ngIf="submitted && f.iata.errors" class="alert alert-danger p-0 small">
          <div *ngIf="f.iata.errors.required">Please Select</div>
        </div>
      </div>


    </div>-->
          <div>
            <div style="display: flex; margin-bottom: -1.5em;">
              <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                <label for="markupAmt" style="font-size:small">Markup Amount</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                  <input type="number" class="form-control form-control-sm inputBox" appDecimal [decimalPlaces]="4" formControlName="markupAmt" />
                </div>
                <div *ngIf="submitted && f.markupAmt.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.markupAmt.errors.required">Required</div>
                </div>
              </div>
              <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                <label for="effectivedate" style="font-size:small">Effective Date</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                  <input type="date" class="form-control form-control-sm inputBox" formControlName="effectivedate" />
                </div>
                <div *ngIf="submitted && f.effectivedate.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.effectivedate.errors.required">Please Select</div>
                </div>
              </div>
              <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                <label for="expirydate" style="font-size:small">Expiry Date</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                  <input type="date" class="form-control form-control-sm inputBox" formControlName="expirydate" />
                </div>
                <!--<div *ngIf="submitted && f.expirydate.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.expirydate.errors.required">Please Select</div>
                </div>-->
              </div>

            </div>
            <div style="text-align: right;">
              <span style="font-size: x-small; margin-right: 2em; ">Expiry date should be left blank if there is no expiry date.</span>
            </div>
            <div *ngIf="f.expirydate.errors && f.expirydate.errors.matEndDateInvalid" style="text-align: -webkit-right;">
            <div  class="alert-danger p-0" style="font-size: small; width: 58%; margin-right: 1em;">Expiry date cannot be before effective date.</div>
            </div>

          </div>
          <div style="display:flex">

            <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; width: 100%;">
              <label for="internalnotes" style="font-size:small">Internal Notes</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <textarea formControlName="internalnotes" class="form-control"  style="resize: none; height: 8em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd;  "></textarea>
              </div>
            </div>
          </div>
          <div style="display:flex">

            <div style="display: flex; margin-top: 0.6em;">
              <label for="isActive" style="font-size:small">Active</label>
              <div class="form-group" style="margin: 0em 0 0 0.5em;">

                <select formControlName="isActive" class="form-control form-control-sm dropdown">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
          </div>


        </div>


        <div style="display: flex;">
          <div class="d-inline-flex">
            <!--<div *ngIf="!ifsuccess" style="justify-content: flex-start; padding-left: 1em; padding-top: 0.5em; padding-bottom: 0.5em; min-width: 33%;">-->
            <div style="justify-content: flex-start; padding-left: 1em; padding-top: 0.5em; padding-bottom: 0.5em; display: flex; height: 2.7em;">

              <button formcontrolname="savebutton" [disabled]="!controlValueChanged   ? 'disabled' : null" mat-button class="btn-high" (click)="clickSave()">
                <!--<span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">save</span>-->
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Save
              </button>&nbsp;
              <button mat-button class="btn-low" (click)="clickClose()">
                <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">close</span>CLOSE
              </button>&nbsp;
              <!--<button *ngIf="isExistingPrice" formcontrolname="duplicatebutton" mat-button style="background-color: #c1e4f7; color: black;" (click)="clickDuplicate()">-->
                <button *ngIf="isExistingPrice" formcontrolname="duplicatebutton" mat-button class="btn-high" (click)="clickDuplicate()">
                  <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">content_copy</span>Duplicate
                </button>&nbsp;
                <!--<button *ngIf="isExistingPrice" formcontrolname="duplicatebutton" mat-button style="background-color: #c1e4f7; color: black;" (click)="clickDuplicate()">
      <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">content_copy</span>Duplicate
    </button>&nbsp;-->
            </div>
          </div>


          <div *ngIf="isExistingPrice">

            <!--<div style="background-color: white; padding: .3em 0em 0 .3em; display: inline-block; width: 34%; ">-->
            <!--margin-top: 1em;text-align: center;-->
            <!--<div class="d-inline-flex">
      <div class="no-gutters">-->
            <!--<div class="col">
      <label style="font-size:small">Fuel Client Markup GUID</label>
      <div style="margin-top: -0.35em; display: inline-block; width: 3.6em; margin-left: 0.6em;">-->
            <!--margin-left: 0.4em;-->
            <!-- <input [readonly]="showModified" class="form-control form-control-sm inputBox" required formControlName="fuelSupplierShortname" maxlength="5" [ngClass]="{ 'is-invalid': submitted && f.fuelSupplierShortname.errors }" style="width:20%" />-->
            <!--<input class="form-control form-control-sm inputBox  inputreadonly" readonly formControlName="fuelClientMarkupGUID" />
        </div>
      </div>-->
            <!--<div class="col">
              <label style="font-size:small">Disable Record</label>
              <div style="margin-top: -0.35em; display: inline-block;">
                <select formControlName="isActive" class="form-control form-control-sm dropdown" style="  margin-left: 0.4em ">
                  <option value="1">No</option>
                  <option value="0">Yes</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>-->
            <div style="display: inline-block; ">
              <div style="border-radius:.25em" class="d-inline-flex ml-2 p-1;">

                <table class="table" style="text-align: left; margin: 0em;">
                  <!--*ngIf="showModified"-->
                  <tr>
                    <td> <label for="modifiedBy" style="font-size: x-small;">Modified By : </label></td>
                    <td>
                      <div  >
                        <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width: 8em; height: 1.5625em; font-size: x-small; padding: 0em; border: none; background-color: transparent;" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><label for="modifiedDate" style="font-size: x-small;">Modified Date : </label></td>
                    <td>
                      <div  >
                        <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width: 8em; height: 1.5625em; font-size: x-small; padding: 0em; border: none; background-color: transparent;" />
                      </div>
                    </td>
                  </tr>
                </table>

              </div>
            </div>
          </div>
        </div>


        <div class="mt-2">
          <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display: inline-block; white-space: pre-wrap;">
            <label style="font-size: small; padding: 0em; margin: 0em;">{{errMsg}}</label>
          </div>
          <div style="font-size:small" *ngIf="showDuplicateText"><span>** Add more tiers for the same Client if needed</span></div>
          <div *ngIf="showSuccessMsg">
            <app-success-message [successMsg]="successMsg"></app-success-message>
          </div>
        </div>
      </div>
    </form>

  </div>
  <!--<div class="d-inline-flex">
    <div *ngIf="!ifsuccess" style="justify-content: flex-start; padding-left: 1em; padding-top: 0.5em; padding-bottom: 0.5em; min-width: 33%;">
      <button formcontrolname="savebutton" [disabled]="!controlValueChanged   ? 'disabled' : null" mat-button class="btn-high" (click)="clickSave()">-->
        <!--<span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">save</span>-->
        <!--SAVE
      </button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()">
        <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">close</span>CLOSE
      </button>&nbsp;-->
      <!--<button *ngIf="isExistingPrice" formcontrolname="duplicatebutton" mat-button style="background-color: #c1e4f7; color: black;" (click)="clickDuplicate()">
        <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">content_copy</span>Duplicate
      </button>&nbsp;-->
    <!--</div>
    <div class="mt-2">
      <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display: inline-block; white-space: pre-wrap;">
        <label style="font-size: small; padding: 0em; margin: 0em;">{{errMsg}}</label>
      </div>
      <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]="successMsg"></app-success-message>
      </div>
    </div>
  </div>-->
</div>
<app-spin *ngIf="showSpin"></app-spin>





