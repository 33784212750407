import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ApisManifest } from '../models/apis-manifest';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { MatAccordion } from '@angular/material/expansion';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { HttpClient, HttpHeaders, HttpParams, HttpXhrBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AirportModel } from '../models/airport.model';
import { AirportService } from '../services/airport.service';
import { DialogService } from '../services/dialog.service';
import { ReturnObjModel } from '../models/return-obj.model';
import { UploadTaskDocument } from '../ground-stop-advanced/upload-task-document';
import { ApisSubmissionModel } from '../models/apis-submission';

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

var expressions = require("angular-expressions");
var assign = require("lodash/assign");
// define your filter functions here, for example
// to be able to write {clientname | lower}
expressions.filters.lower = function (input) {
  // This condition should be used to make sure that if your input is
  // undefined, your output will be undefined as well and will not
  // throw an error
  if (!input) return input;
  return input.toLowerCase();
};
function angularParser(tag) {
  tag = tag
    .replace(/^\.$/, "this")
    .replace(/(�|�)/g, "'")
    .replace(/(�|�)/g, '"');
  const expr = expressions.compile(tag);
  return {
    get: function (scope, context) {
      let obj = {};
      const scopeList = context.scopeList;
      const num = context.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = assign(obj, scopeList[i]);
      }
      return expr(scope, obj);
    },

  };


}

function parser(tag) {
  // We write an exception to handle the tag "$pageBreakExceptLast"
  if (tag === "$pageBreakExceptLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (!isLast) {
          return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }
  if (tag === "$pageBreak") {
    return {
      get(scope, context) {
        return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';

      },
    };
  }
  if (tag === "$lineBreak") {
    return {
      get(scope, context) {
        return '<w:br/>';

      },
    };
  }
  if (tag === "$lineBreakIfLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (isLast) {
          return '<w:p><w:r><w:br/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }


  const expressionParser = require("docxtemplater/expressions.js");
  expressionParser.filters.toFixed = function (input, precision) {
    // In our example precision is the integer 2

    // Make sure that if your input is undefined, your
    // output will be undefined as well and will not
    // throw an error
    if (!input) return input;

    return input.toFixed(precision);
  };

  // We use the angularParser as the default fallback
  // If you don't wish to use the angularParser,
  // you can use the default parser as documented here:
  // https://docxtemplater.com/docs/configuration#default-parser
  return angularParser(tag);
}

const datepipe: DatePipe = new DatePipe('en-US')


@Component({
  selector: 'app-generate-apis-91',
  templateUrl: './generate-apis-91.component.html',
  styleUrls: ['./generate-apis-91.component.css']
})


/** generate-apis component*/
export class GenerateApis91Component implements OnInit {
  @Input() groundStopGUID: string;
  @Input() tripCodeGUID: string;
  @Input() groundStopSequenceId: number
  @Input() groundStopTaskId: string;
  generate_apis_91_form: UntypedFormGroup;
  apisManifest: ApisManifest;
  isInbound: boolean = false;
  isOutbound: boolean = true;
  InboundOutbound: string = "Outbound";
  lastNext: string = "Next";

  isDepApisApproved: boolean;
  isArrApisApproved: boolean;
  public loadingReport: boolean = false;
  public loadingXml: boolean = false;
  public submittingXml: boolean = false;
  checkNoDocuments: boolean = false;
  airportList: AirportModel[];
  errMsg: string = "";
  showSpin: boolean = true;
  hasExpiredDocument: boolean = false;
  hasNoDocument: boolean = false;
  noDocumentList: string = "";
  expiredDocList: string = "";
  showSuccessMessage: boolean = false;
  isUpdated: boolean = false;
  errMessage: string = "";
  constructor(private readonly _groundStopAdvanceService: GroundStopAdvancedService, private readonly _authService: AuthenticateService, private readonly _dialogRef: MatDialogRef<GenerateApis91Component>,
    private readonly _formBuilder: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialogService: DialogService, private el: ElementRef, private readonly _airportService: AirportService, private http: HttpClient) {
    this.groundStopGUID = _data.groundStopGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.groundStopSequenceId = _data.groundStopSequenceId;
    this.groundStopTaskId = _data.groundStopTaskGUID;

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.getData();
  }

  initControls() {
    this.generate_apis_91_form = this._formBuilder.group({
      emergencyFirst: [this.apisManifest.emergencyContactFirst, Validators.required],
      emergencyLast: [this.apisManifest.emergencyContactLast, Validators.required],
      emergencyPhone: [this.apisManifest.emergencyContactPhone, Validators.required],
      emergencyEmail: this.apisManifest.emergencyContactEmail,
      departureAirport: this.apisManifest.departureICAO,
      departureCity: [this.apisManifest.departureCity, Validators.required],
      departureState: this.apisManifest.departureState,
      depPlaceDescription: this.apisManifest.departurePlaceDescription,
      departureCountry: this.apisManifest.departureCountryCode,
      localDepDate: [this.apisManifest.departureDateLocal, Validators.required],
      localDepTime: [this.apisManifest.departureTimeLocal, Validators.required],
      borderCrossing: '',
      foreignAirport1: this.apisManifest.foreignAirports[0].icao,
      foreignAirport2: this.apisManifest.foreignAirports[1].icao,
      foreignAirport3: this.apisManifest.foreignAirports[2].icao,
      foreignAirport4: this.apisManifest.foreignAirports[3].icao,
      foreignAirport5: this.apisManifest.foreignAirports[4].icao,
      arrivalAirport: this.apisManifest.arrivalICAO,
      arrivalCity: [this.apisManifest.arrivalCity, Validators.required],
      arrivalState: this.apisManifest.arrivalState,
      arrPlaceDescription: '',
      arrivalCountry: this.apisManifest.arrivalCountryCode,
      localArrDate: [this.apisManifest.arrivalDateLocal, Validators.required],
      localArrTime: [this.apisManifest.arrivalTimeLocal, Validators.required],
      tailNumber: [this.apisManifest.registration, Validators.required],
      aircraftType: [this.apisManifest.aircraftType, Validators.required],
      color: [this.apisManifest.aircraftColor, Validators.required],
      callSign: this.apisManifest.callSign,
      cbpDecal: this.apisManifest.cbpDecal,
      operatorCompany: this.apisManifest.operatorCompany,
      operatorFirst: this.apisManifest.operatorFirstName,
      operatorMiddle: this.apisManifest.operatorMiddleName,
      operatorLast: this.apisManifest.operatorLastName,
      operatorStreet1: [this.apisManifest.operatorAddress1, Validators.required],
      operatorStreet2: this.apisManifest.operatorAddress2,
      operatorCity: [this.apisManifest.operatorCity, Validators.required],
      operatorState: this.apisManifest.operatorStateProvince,
      operatorZip: this.apisManifest.operatorZip,
      operatorCountry: [this.apisManifest.operatorCountryCode, Validators.required],
      operatorPhone: [this.apisManifest.operatorPhone, Validators.required],
      operatorFax: this.apisManifest.operatorFax,
      operatorEmail: [this.apisManifest.operatorEmail, Validators.required],
      ownerCompany: this.apisManifest.ownerCompany,
      ownerFirst: this.apisManifest.ownerFirstName,
      ownerMiddle: this.apisManifest.ownerMiddleName,
      ownerLast: this.apisManifest.ownerLastName,
      ownerStreet1: [this.apisManifest.ownerAddress1, Validators.required],
      ownerStreet2: this.apisManifest.ownerAddress2,
      ownerCity: [this.apisManifest.ownerCity, Validators.required],
      ownerState: this.apisManifest.ownerStateProvince,
      ownerZip: this.apisManifest.ownerZip,
      ownerCountry: [this.apisManifest.ownerCountryCode, Validators.required],
      ownerPhone: [this.apisManifest.ownerPhone, Validators.required],
      ownerFax: this.apisManifest.ownerFax,
      ownerEmail: [this.apisManifest.ownerEmail, Validators.required]
    });

    if (this.apisManifest.crewList != null) {
      for (let i = 0; i < this.apisManifest.crewList.length; i++) {
        this.generate_apis_91_form.addControl("crewFirstName" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].firstName, Validators.required));
        this.generate_apis_91_form.addControl("crewMiddleName" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].middleName));
        this.generate_apis_91_form.addControl("crewLastName" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].lastName, Validators.required));
        this.generate_apis_91_form.addControl("crewBirthdate" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].dob, Validators.required));
        this.generate_apis_91_form.addControl("crewSex" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].sex, Validators.required));
        this.generate_apis_91_form.addControl("crewResidence" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].residenceCountry, Validators.required));
        this.generate_apis_91_form.addControl("crewCitizenship" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].citizenshipCountry, Validators.required));
        this.generate_apis_91_form.addControl("crewPermAddressStreet" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].permanentAddress.streetAddress));
        this.generate_apis_91_form.addControl("crewPermAddressCity" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].permanentAddress.city));
        this.generate_apis_91_form.addControl("crewPermAddressState" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].permanentAddress.stateProvince));
        this.generate_apis_91_form.addControl("crewPermAddressPostal" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].permanentAddress.postalCode));
        this.generate_apis_91_form.addControl("crewPermAddressCountry" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].permanentAddress.countryCode));
        this.generate_apis_91_form.addControl("crewUSAddressStreet" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].addressInUsa.streetAddress));
        this.generate_apis_91_form.addControl("crewUSAddressCity" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].addressInUsa.city));
        this.generate_apis_91_form.addControl("crewUSAddressState" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].addressInUsa.stateProvince));
        this.generate_apis_91_form.addControl("crewUSAddressPostal" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].addressInUsa.postalCode));
        this.generate_apis_91_form.addControl("crewTravelerType" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].travelerType));
        this.generate_apis_91_form.addControl("crewContactPhone1" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].contactPhone1));
        this.generate_apis_91_form.addControl("crewContactPhone2" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].contactPhone2));
        this.generate_apis_91_form.addControl("crewContactEmail" + i.toString(), new UntypedFormControl(this.apisManifest.crewList[i].contactEmail));

        for (let j = 0; j < this.apisManifest.crewList[i].apisTravelDocuments.length; j++) {
          this.generate_apis_91_form.addControl("crew" + i.toString() + 'docType' + j.toString(), new UntypedFormControl(this.apisManifest.crewList[i].apisTravelDocuments[j].documentType || "No Document Provided"));
          this.generate_apis_91_form.addControl("crew" + i.toString() + 'docCode' + j.toString(), new UntypedFormControl(this.apisManifest.crewList[i].apisTravelDocuments[j].documentCode || "No Document Provided"));
          this.generate_apis_91_form.addControl("crew" + i.toString() + 'docNumber' + j.toString(), new UntypedFormControl(this.apisManifest.crewList[i].apisTravelDocuments[j].documentNumber || "No Document Provided"));
          this.generate_apis_91_form.addControl("crew" + i.toString() + 'docExpiry' + j.toString(), new UntypedFormControl(datepipe.transform(this.apisManifest.crewList[i].apisTravelDocuments[j].documentExpiry, 'MMM-dd-yyyy')));
          if (this.apisManifest.crewList[i].apisTravelDocuments[j].documentNumber == '' || this.apisManifest.crewList[i].apisTravelDocuments[j].documentNumber == null)
            this.generate_apis_91_form.get("crew" + i.toString() + 'docExpiry' + j.toString()).setValue("No Document Provided");
          if (this.generate_apis_91_form.get("crew" + i.toString() + 'docCode' + j.toString()).value == 'L')
            this.generate_apis_91_form.addControl("crew" + i.toString() + 'docCountry' + j.toString(), new UntypedFormControl(this.apisManifest.crewList[i].apisTravelDocuments[j].documentCountryCode || "MISSING"));
          else
            this.generate_apis_91_form.addControl("crew" + i.toString() + 'docCountry' + j.toString(), new UntypedFormControl(this.apisManifest.crewList[i].apisTravelDocuments[j].documentCountryCode || "No Document Provided"));
          if ((new Date(this.apisManifest.crewList[i].apisTravelDocuments[j].documentExpiry).getTime() <= new Date(this.f.localDepDate.value).getTime()) && this.apisManifest.crewList[i].apisTravelDocuments[j].documentExpiry != '') {
            this.hasExpiredDocument = true;
          }
          if (this.apisManifest.crewList[i].apisTravelDocuments[j].documentNumber == "") {
            this.hasNoDocument = true;
            if (this.noDocumentList != "")
              this.noDocumentList += "\n";
            this.noDocumentList += this.apisManifest.crewList[i].travelerType + " - " + this.apisManifest.crewList[i].lastName + ", " + this.apisManifest.crewList[i].firstName + " " + this.apisManifest.crewList[i].middleName;
          }
        }
      }
    }

    if (this.apisManifest.paxList != null) {
      for (let i = 0; i < this.apisManifest.paxList.length; i++) {
        this.generate_apis_91_form.addControl("paxFirstName" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].firstName, Validators.required));
        this.generate_apis_91_form.addControl("paxMiddleName" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].middleName));
        this.generate_apis_91_form.addControl("paxLastName" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].lastName, Validators.required));
        this.generate_apis_91_form.addControl("paxBirthdate" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].dob, Validators.required));
        this.generate_apis_91_form.addControl("paxSex" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].sex, Validators.required));
        this.generate_apis_91_form.addControl("paxResidence" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].residenceCountry, Validators.required));
        this.generate_apis_91_form.addControl("paxCitizenship" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].citizenshipCountry, Validators.required));
        this.generate_apis_91_form.addControl("paxUSAddressStreet" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].addressInUsa.streetAddress));
        this.generate_apis_91_form.addControl("paxUSAddressCity" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].addressInUsa.city));
        this.generate_apis_91_form.addControl("paxUSAddressState" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].addressInUsa.stateProvince));
        this.generate_apis_91_form.addControl("paxUSAddressPostal" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].addressInUsa.postalCode));
        this.generate_apis_91_form.addControl("paxContactPhone1" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].contactPhone1));
        this.generate_apis_91_form.addControl("paxContactPhone2" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].contactPhone2));
        this.generate_apis_91_form.addControl("paxContactEmail" + i.toString(), new UntypedFormControl(this.apisManifest.paxList[i].contactEmail));
        for (let j = 0; j < this.apisManifest.paxList[i].apisTravelDocuments.length; j++) {
          this.generate_apis_91_form.addControl("pax" + i.toString() + 'docType' + j.toString(), new UntypedFormControl(this.apisManifest.paxList[i].apisTravelDocuments[j].documentType || "No Document Provided"));
          this.generate_apis_91_form.addControl("pax" + i.toString() + 'docCode' + j.toString(), new UntypedFormControl(this.apisManifest.paxList[i].apisTravelDocuments[j].documentCode || "No Document Provided"));
          this.generate_apis_91_form.addControl("pax" + i.toString() + 'docNumber' + j.toString(), new UntypedFormControl(this.apisManifest.paxList[i].apisTravelDocuments[j].documentNumber || "No Document Provided"));
          this.generate_apis_91_form.addControl("pax" + i.toString() + 'docExpiry' + j.toString(), new UntypedFormControl(datepipe.transform(this.apisManifest.paxList[i].apisTravelDocuments[j].documentExpiry, 'MMM-dd-yyyy')));
          if (this.apisManifest.paxList[i].apisTravelDocuments[j].documentNumber == '' || this.apisManifest.paxList[i].apisTravelDocuments[j].documentNumber == null)
            this.generate_apis_91_form.get("pax" + i.toString() + 'docExpiry' + j.toString()).setValue("No Document Provided");
          this.generate_apis_91_form.addControl("pax" + i.toString() + 'docCountry' + j.toString(), new UntypedFormControl(this.apisManifest.paxList[i].apisTravelDocuments[j].documentCountryCode || "No Document Provided"));
          if ((new Date(this.apisManifest.paxList[i].apisTravelDocuments[j].documentExpiry).getTime() <= new Date(this.f.localDepDate.value).getTime()) && this.apisManifest.paxList[i].apisTravelDocuments[j].documentExpiry != '') {
            this.hasExpiredDocument = true;
          }
          if (this.apisManifest.paxList[i].apisTravelDocuments[j].documentNumber == "") {
            this.hasNoDocument = true;
            if (this.noDocumentList != "")
              this.noDocumentList += "\n";
            this.noDocumentList += this.apisManifest.paxList[i].travelerType + " - " + this.apisManifest.paxList[i].lastName + ", " + this.apisManifest.paxList[i].firstName + " " + this.apisManifest.paxList[i].middleName;
          }
        }

      }
    }

    if (this.hasExpiredDocument) {
      this._dialogService.openAlertDialog("Warning: Manifest has expired documents.  Confirm send ok.");
    }
    if (this.hasNoDocument) {
      this._dialogService.openAlertDialog("Warning: The following traveler(s) have no travel documents:\n" + this.noDocumentList + "\n\nAPIS submission must be coordinated with CBP.", "Confirm", undefined, false);
    }

  }

  setValidation() {
    if (this.isInbound) {
      this.f.departureCountry.setValidators(Validators.required);
      this.f.departureCountry.updateValueAndValidity();
      this.f.arrivalAirport.setValidators(Validators.required);
      this.f.arrivalAirport.updateValueAndValidity();
      this.f.arrivalState.setValidators(Validators.required);
      this.f.arrivalState.updateValueAndValidity();
    }
    else {
      this.f.arrivalCountry.setValidators(Validators.required);
      this.f.arrivalCountry.updateValueAndValidity();
      this.f.departureAirport.setValidators(Validators.required);
      this.f.departureAirport.updateValueAndValidity();
      this.f.departureState.setValidators(Validators.required);
      this.f.departureState.updateValueAndValidity();
    }

    if (this.apisManifest.operatorCompany != null && this.apisManifest.operatorCompany != "") {
      this.f.operatorCompany.setValidators(Validators.required);
      this.f.operatorCompany.updateValueAndValidity();
    }
    else {
      this.f.operatorFirst.setValidators(Validators.required);
      this.f.operatorFirst.updateValueAndValidity();
      this.f.operatorLast.setValidators(Validators.required);
      this.f.operatorLast.updateValueAndValidity();
    }

    if (this.apisManifest.ownerCompany != null && this.apisManifest.ownerCompany != "") {
      this.f.ownerCompany.setValidators(Validators.required);
      this.f.ownerCompany.updateValueAndValidity();
    }
    else {
      this.f.ownerFirst.setValidators(Validators.required);
      this.f.ownerFirst.updateValueAndValidity();
      this.f.ownerLast.setValidators(Validators.required);
      this.f.ownerLast.updateValueAndValidity();
    }

    if (this.apisManifest.departureIsApisApproved == false && this.isOutbound == true) {
      this.f.depPlaceDescription.setValidators(Validators.required);
      this.f.depPlaceDescription.updateValueAndValidity();
    }

    if (this.apisManifest.arrivalIsApisApproved == false && this.isInbound == true) {
      this.f.arrPlaceDescription.setValidators(Validators.required);
      this.f.arrPlaceDescription.updateValueAndValidity();
    }
  }

  setClass(e: any): string {
    if (e.id.indexOf("docExpiry") != -1) {
      if (((new Date(e.value).getTime() <= new Date(this.f.localDepDate.value).getTime()) && e.value != '')) {
        return "valid-info";
      }
      else {
        if (this.generate_apis_91_form.get(e.id.replace("docExpiry", "docType")).value == "Passport") {
          if (e.value == "") {
            this.generate_apis_91_form.get(e.id.replace("docExpiry", "docType")).setErrors({ 'required': true });
            return "is-invalid";
          }
          else {
            this.generate_apis_91_form.get(e.id.replace("docExpiry", "docType")).clearValidators();
            this.generate_apis_91_form.get(e.id.replace("docExpiry", "docType")).updateValueAndValidity();
            return "";
          }
        }

      }

    }
    else
      if (e.id.indexOf("Birthdate") != -1) {
        if (((new Date(e.value).getTime() > new Date(this.f.localDepDate.value).getTime()) && e.value != '')) {
          this.generate_apis_91_form.get(e.id).setErrors({ 'invalidDate': true });
          this.errMessage = "Cannot be future date.";
          return "is-invalid";
        }
        else {
          if (e.value == '' || e.value == null)
            return "is-invalid";
          else
            return "";
        }
      }
      else {
        if (e.value == '' || e.value == 'No Document' || e.value == 'MISSING') {
          if (e.id != '')
            this.generate_apis_91_form.get(e.id).setErrors({ 'required': true });
          return "is-invalid";

        }
        else {
          return "";
        }
      }
  }

  getData() {
    let request = new ApisManifest();
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopGUID = this.groundStopGUID;
    request.isPrivate = true;
    request.apisTypeID = 1;
    //request.groundStopSequenceId = this.groundStopSequenceId;
    this._groundStopAdvanceService.getApisData<ResponseModel<ApisManifest>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.apisManifest = response.model;
          this.apisManifest.emergencyContactLast = localStorage.getItem('ln');
          this.apisManifest.emergencyContactFirst = localStorage.getItem('fn');
          this.apisManifest.emergencyContactEmail = "ops@flightprointl.com";
          this.apisManifest.emergencyContactPhone = "346-415-6900";
          this.isDepApisApproved = this.apisManifest.departureIsApisApproved;
          this.isArrApisApproved = this.apisManifest.arrivalIsApisApproved;
          this.apisManifest.departurePlaceDescription = "";
          this.apisManifest.arrivalPlaceDescription = "";
          switch (this.apisManifest.arrivalCountryCode) {
            case "USA":
            case "PRI":
            case "GUM":
            case "VIR":
            case "NMP":
              {
                this.isInbound = true;
                this.isOutbound = false;
                this.InboundOutbound = "Inbound";
                this.lastNext = "Last";
                break;
              }
          }
          if (this.isOutbound && !this.isDepApisApproved) {
            this.apisManifest.departurePlaceDescription = "Actual departure airport is " + this.apisManifest.departureICAO + " / " + this.apisManifest.departureCity + ", " + this.apisManifest.departureState;
            if (this.apisManifest.deptApprovedAirport != null) {
              this._dialogService.openAlertDialog("Departure airport " + this.apisManifest.departureICAO + " is not approved.  \n\nAPIS will be submitted as departing from " + this.apisManifest.deptApprovedAirport);
              this.apisManifest.departureICAO = this.apisManifest.deptApprovedAirport;

            }
          }
          if (this.isInbound && !this.isArrApisApproved) {
            this.apisManifest.arrivalPlaceDescription = "Actual arrival airport is " + this.apisManifest.arrivalICAO + " / " + this.apisManifest.arrivalCity + ", " + this.apisManifest.arrivalState;
            if (this.apisManifest.arrvApprovedAirport != null) {
              this._dialogService.openAlertDialog("Arrival airport " + this.apisManifest.arrivalICAO + " is not approved.  \n\nAPIS will be submitted as arriving from " + this.apisManifest.arrvApprovedAirport);
              this.apisManifest.arrivalICAO = this.apisManifest.arrvApprovedAirport;

            }
          }
          if (this.apisManifest.showApisReportFirst)
            this._dialogService.openAlertDialog("Client requests manifest report prior to APIS submittal.");
          this.initControls();
          this.validateAirport("departureAirport");
          this.validateAirport("arrivalAirport");
          this.setValidation();

        }

        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
        this.showSpin = false;
      }
    });
  }

  get f() { return this.generate_apis_91_form.controls; }

  generate(submit: boolean) {
    this.errMsg = "";
    if (this.isOutbound && !this.isDepApisApproved || this.isInbound && !this.isArrApisApproved) {
      if (this.isOutbound)
        this.errMsg = "Departure airport is not approved.";
      else
        this.errMsg = "Arrival airport is not approved.";
      return;
    }
    if (this.generate_apis_91_form.invalid) {
      this.errMsg = "Missing one or more required fields."
      for (const key of Object.keys(this.generate_apis_91_form.controls)) {
        if (this.generate_apis_91_form.controls[key].invalid) {
          var invalidControl;
          invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          if (invalidControl == null)
            invalidControl = this.el.nativeElement.querySelector('[id="' + key + '"]');

          break;
        }
      }

      invalidControl.focus();


      return;
    }
    if (submit)
      this.submittingXml = true;
    else
      this.loadingXml = true;

    this.updateApisManifest();
    this.generateApisXml(submit);
    // this.loading = false;

  }

  updateApisManifest() {
    this.apisManifest.emergencyContactFirst = this.f.emergencyFirst.value;
    this.apisManifest.emergencyContactLast = this.f.emergencyLast.value;
    this.apisManifest.emergencyContactPhone = this.f.emergencyPhone.value;
    this.apisManifest.emergencyContactEmail = this.f.emergencyEmail.value;
    this.apisManifest.departurePlaceDescription = this.f.depPlaceDescription.value;
    this.apisManifest.departureICAO = this.f.departureAirport.value.toUpperCase();
    this.apisManifest.departureCity = this.f.departureCity.value;
    this.apisManifest.departureState = this.f.departureState.value;
    this.apisManifest.borderCrossing = this.f.borderCrossing.value;
    this.apisManifest.arrivalPlaceDescription = this.f.arrPlaceDescription.value;
    this.apisManifest.foreignAirports[0].icao = this.f.foreignAirport1.value.toUpperCase();
    this.apisManifest.foreignAirports[1].icao = this.f.foreignAirport2.value.toUpperCase();
    this.apisManifest.foreignAirports[2].icao = this.f.foreignAirport3.value.toUpperCase();
    this.apisManifest.foreignAirports[3].icao = this.f.foreignAirport4.value.toUpperCase();
    this.apisManifest.foreignAirports[4].icao = this.f.foreignAirport5.value.toUpperCase();

    for (let i = 0; i < this.apisManifest.crewList.length; i++) {
      this.apisManifest.crewList[i].contactPhone1 = this.generate_apis_91_form.get("crewContactPhone1" + i.toString()).value || "";
      this.apisManifest.crewList[i].contactPhone2 = this.generate_apis_91_form.get("crewContactPhone2" + i.toString()).value || "";
      this.apisManifest.crewList[i].contactEmail = this.generate_apis_91_form.get("crewContactEmail" + i.toString()).value || "";

    }
    if (this.apisManifest.paxList != null) {
      for (let i = 0; i < this.apisManifest.paxList.length; i++) {
        this.apisManifest.paxList[i].contactPhone1 = this.generate_apis_91_form.get("paxContactPhone1" + i.toString()).value || "";
        this.apisManifest.paxList[i].contactPhone2 = this.generate_apis_91_form.get("paxContactPhone2" + i.toString()).value || "";
        this.apisManifest.paxList[i].contactEmail = this.generate_apis_91_form.get("paxContactEmail" + i.toString()).value || "";
      }
    }
  }

  generateApisXml(submit: boolean) {
    const { create } = require('xmlbuilder2');
    var apisString = `{
      "Manifest": {
        "Transaction": {
          "FlightType":  "GA",
          "SchemaVersion": "3.0",
          "SenderId": "APGA1WBF",
          "DateAssembled": "` + datepipe.transform(new Date(), 'yyyy-MM-dd') + `",
          "TimeAssembled": "` + datepipe.transform(new Date(), 'HH:mm:ss') + `",
          "EmergencyContact": {
            "LastName": "` + this.apisManifest.emergencyContactLast + `",
            "FirstName": "` + this.apisManifest.emergencyContactFirst + `",
            "MiddleName": "",
            "TelephoneNbr": "` + this.apisManifest.emergencyContactPhone + `",
            "EmailAddr": "` + this.apisManifest.emergencyContactEmail + `"
            },
          "Itinerary": {
            "` + this.InboundOutbound + `Itinerary": {
                  "` + this.InboundOutbound + `DepartureLocation": {
                        "AirportCode": "` + this.apisManifest.departureICAO + `",
                        "City": "` + this.apisManifest.departureCity + `",`
    if (!this.isInbound) {
      apisString += `"State": "` + this.apisManifest.departureState + `",
                        "PlaceDescription": "` + this.apisManifest.departurePlaceDescription + `"`
    }
    else
      apisString += `"CountryCode": "` + this.apisManifest.departureCountryCode + `"`
    apisString += `            },
         "LocalDepartureDate": "` + datepipe.transform(this.apisManifest.departureDateLocal, 'yyyy-MM-dd') + `",
          "LocalDepartureTime": "` + this.apisManifest.departureTimeLocal + `",
          "BorderCrossing": "` + this.apisManifest.borderCrossing + `",

        "` + this.InboundOutbound + `CompleteItinerary": {
              "ForeignAirport1": "` + this.apisManifest.foreignAirports[0].icao + `",
              "ForeignAirport2": "` + this.apisManifest.foreignAirports[1].icao + `",
              "ForeignAirport3": "` + this.apisManifest.foreignAirports[2].icao + `",
              "ForeignAirport4": "` + this.apisManifest.foreignAirports[3].icao + `",
              "ForeignAirport5": "` + this.apisManifest.foreignAirports[4].icao + `"
            },
        "` + this.InboundOutbound + `ArrivalLocation": {  "AirportCode": "` + this.apisManifest.arrivalICAO + `",
                        "City": "` + this.apisManifest.arrivalCity + `",`
    if (this.isInbound) {
      apisString += `"State": "` + this.apisManifest.arrivalState + `",
                        "PlaceDescription": "` + this.apisManifest.arrivalPlaceDescription + `"`
    }
    else
      apisString += `"CountryCode": "` + this.apisManifest.arrivalCountryCode + `"`
    apisString += `            },
         "LocalArrivalDate": "` + datepipe.transform(this.apisManifest.arrivalDateLocal, 'yyyy-MM-dd') + `",
          "LocalArrivalTime": "` + this.apisManifest.arrivalTimeLocal + `"
        }
          },
    "Aircraft":{
        "AircraftDetail": {
              "TailNumber": "` + this.apisManifest.registration + `",
              "TypeAircraft": "` + this.apisManifest.aircraftType + `",
              "Color":"` + this.apisManifest.aircraftColor + `",
              "CallSign": "` + this.apisManifest.callSign + `",
              "CBPDecalNumber": "` + this.apisManifest.cbpDecal + `"
          },
        "OperatorDetail": {`
    if (this.apisManifest.operatorCompany != null && this.apisManifest.operatorCompany != "") {
      apisString += `  "CompanyOperator": "` + this.apisManifest.operatorCompany + `",`
    }
    else {
      apisString += `  "PersonOperator": {
        "LastName": "` + this.apisManifest.operatorLastName + `",
        "FirstName": "` + this.apisManifest.operatorFirstName + `",
        "MiddleName": "` + this.apisManifest.operatorMiddleName + `"},`
    }
    apisString += `"OperatorContact": {
            "StreetAddr": "` + this.apisManifest.operatorAddress1 + `",
            "AddressSupplemental": "` + this.apisManifest.operatorAddress2 + `",
            "City": "` + this.apisManifest.operatorCity + `",
            "StateProvince": "` + this.apisManifest.operatorStateProvince + `",
            "ZipPostal": "` + this.apisManifest.operatorZip + `",
            "Country": "` + this.apisManifest.operatorCountryCode + `",
            "TelephoneNbr": "` + this.apisManifest.operatorPhone + `",
            "FaxNbr": "` + this.apisManifest.operatorFax + `",
            "EmailAddr": "` + this.apisManifest.operatorEmail + `"
          }
        },
      "OwnerDetail":{`
    if (this.apisManifest.ownerCompany != null && this.apisManifest.ownerCompany != "") {
      apisString += `  "CompanyOwnerOrLessee": "` + this.apisManifest.ownerCompany + `",`
    }
    else {
      apisString += `  "PersonOwnerOrLessee": {
        "LastName": "` + this.apisManifest.ownerLastName + `",
        "FirstName": "` + this.apisManifest.ownerFirstName + `",
        "MiddleName": "` + this.apisManifest.ownerMiddleName + `"},`
    }
    apisString += `"OwnerOrLesseeContact": {
            "StreetAddr": "` + this.apisManifest.ownerAddress1 + `",
            "AddressSupplemental": "` + this.apisManifest.ownerAddress2 + `",
            "City": "` + this.apisManifest.ownerCity + `",
            "StateProvince": "` + this.apisManifest.ownerStateProvince + `",
            "ZipPostal": "` + this.apisManifest.ownerZip + `",
            "Country": "` + this.apisManifest.ownerCountryCode + `",
            "TelephoneNbr": "` + this.apisManifest.ownerPhone + `",
            "FaxNbr": "` + this.apisManifest.ownerFax + `",
            "EmailAddr": "` + this.apisManifest.ownerEmail + `"
          }}
      }
    },
    "FlightManifest": {
   
     "Crew": [`
    let i = 1;
    this.apisManifest.crewList.forEach(v => {

      apisString += `{
        "CrewDocument1": [
          {
        "DocCode": "` + v.apisTravelDocuments[0].documentCode + `",
        "DocumentNbr": "` + v.apisTravelDocuments[0].documentNumber + `",
        "ExpiryDate": "`
      if (v.apisTravelDocuments[0].documentExpiry != "")
        apisString += datepipe.transform(v.apisTravelDocuments[0].documentExpiry, 'yyyy-MM-dd') + `",`
      else
        apisString += `",`
      apisString += `"CntryCode": "` + v.apisTravelDocuments[0].documentCountryCode + `"    }
        ],`
      if (v.apisTravelDocuments.length > 1) {
        apisString += `  "CrewDocument2": [
          {
        "DocCode": "` + v.apisTravelDocuments[1].documentCode + `",
        "DocumentNbr": "` + v.apisTravelDocuments[1].documentNumber + `",
        "ExpiryDate": "`
        if (v.apisTravelDocuments[1].documentExpiry != "")
          apisString += datepipe.transform(v.apisTravelDocuments[1].documentExpiry, 'yyyy-MM-dd') + `",`
        else
          apisString += `",`
        apisString += `"CntryCode": "` + v.apisTravelDocuments[1].documentCountryCode + `"    }
       ],`
      }
      apisString += `    "Surname": "` + v.lastName + `", 
      "FirstName": "` + v.firstName + `",
      "SecondName": "` + v.middleName + `",
      "Birthdate": "` + datepipe.transform(v.dob, 'YYYY-MM-dd') + `",
      "Sex": "` + v.sex + `",
      "ResidenceCntry": "` + v.residenceCountry + `",
      "CitizenshipCntry": "` + v.citizenshipCountry + `",
       "PermanentAddress": [
{
        "StreetAddr": "` + v.permanentAddress.streetAddress + `",
        "City": "` + v.permanentAddress.city + `",
        "StateProvince": "` + v.permanentAddress.stateProvince + `",
        "PostalCode": "` + v.permanentAddress.postalCode + `",
        "Country": "` + v.permanentAddress.countryCode + `"
}],
      "AddressWhileInUS": [
{
        "StreetAddr": "` + v.addressInUsa.streetAddress + `",
        "City": "` + v.addressInUsa.city + `",
        "State": "` + v.addressInUsa.stateProvince + `",
        "PostalCode": "` + v.addressInUsa.postalCode + `"
}],
  
      "TravelerType": "` + v.travelerType + `",
      "ContactPhone1": "` + v.contactPhone1 + `",
      "ContactPhone2": "` + v.contactPhone2 + `",
      "ContactEmail": "` + v.contactEmail + `"
      }`
      if (i != this.apisManifest.crewList.length)
        apisString += `,`
      i++;
    });


    apisString += `


],
    "Pax": [`
    i = 1;
    if (this.apisManifest.paxList != null) {
      this.apisManifest.paxList.forEach(v => {

        apisString += `{
        "PaxDocument1": [
          {
            "DocCode": "` + v.apisTravelDocuments[0].documentCode + `",
            "DocumentNbr": "` + v.apisTravelDocuments[0].documentNumber + `",
            "ExpiryDate": "`
        if (v.apisTravelDocuments[0].documentExpiry != "")
          apisString += datepipe.transform(v.apisTravelDocuments[0].documentExpiry, 'yyyy-MM-dd') + `",`
        else
          apisString += `",`
        apisString += `"CntryCode": "` + v.apisTravelDocuments[0].documentCountryCode + `"
          }
        ],`
        if (v.apisTravelDocuments.length > 1) {
          apisString += `  "PaxDocument2": [
        {
          "DocCode": "` + v.apisTravelDocuments[1].documentCode + `",
          "DocumentNbr": "` + v.apisTravelDocuments[1].documentNumber + `",
          "ExpiryDate": "`
          if (v.apisTravelDocuments[1].documentExpiry != "")
            apisString += datepipe.transform(v.apisTravelDocuments[1].documentExpiry, 'yyyy-MM-dd') + `",`
          else
            apisString += `",`
          apisString += `"CntryCode": "` + v.apisTravelDocuments[1].documentCountryCode + `"
        }
      ], `
        }
        apisString += `    "Surname": "` + v.lastName + `",
      "FirstName": "` + v.firstName + `",
      "SecondName": "` + v.middleName + `",
      "Birthdate": "` + datepipe.transform(v.dob, 'yyyy-MM-dd') + `",
      "Sex": "` + v.sex + `",
      "ResidenceCntry": "` + v.residenceCountry + `",
      "CitizenshipCntry": "` + v.citizenshipCountry + `",
  "AddressWhileInUS": [
{
        "StreetAddr": "` + v.addressInUsa.streetAddress + `",
        "City": "` + v.addressInUsa.city + `",
        "State": "` + v.addressInUsa.stateProvince + `",
        "PostalCode": "` + v.addressInUsa.postalCode + `"
}],
  
      "ContactPhone1": "` + v.contactPhone1 + `",
      "ContactPhone2": "` + v.contactPhone2 + `",
      "ContactEmail": "` + v.contactEmail + `"
      }`
        if (i != this.apisManifest.paxList.length)
          apisString += `, `
        i++;
      });
    }
    apisString += `
      ]}
    }
  }`

    const doc = create(apisString);
    var xml;
    if (submit == false) {
      xml = doc.end({ prettyPrint: true });
      let file = new Blob([xml], { type: 'application/xml' })
      saveAs(file, "APIS-" + this.apisManifest.registration + "-" + this.apisManifest.departureICAO + "-" + this.apisManifest.arrivalICAO + ".xml");
      this.loadingXml = false;
    }
    else {
      const xml = doc.end({ prettyPrint: false });
      let request = new ApisManifest();
      request.groundStopTaskGUID = this.groundStopTaskId;
      let apis = new ApisSubmissionModel();
      apis.xml = xml;
      apis.hasNoDocuments = this.hasNoDocument;
      apis.travelersWithNoDocuments = this.noDocumentList;
      request.apisSubmissions = [];
      request.apisSubmissions.push(apis);
      request.apisTypeID = 1;
      this._groundStopAdvanceService.submitApisManifest<ResponseModel<ApisManifest>>(request).subscribe(async response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let apisResponse = response.model;
            if (apisResponse.apisSubmissions[0].isSuccessful) {
              this.isUpdated = true;
              this.showSuccessMessage = true;
              await this.generateReport(apisResponse.apisSubmissions[0].apisResponse.substring(apisResponse.apisSubmissions[0].apisResponse.indexOf(':') + 1).trim()).then(finished => {
                if (finished) {
                  this.showSuccessMessage = false;
                  this.close();
                }
              });


            }
            else {
              this.errMsg = "Submission failed. " + apisResponse.apisSubmissions[0].apisResponse;
            }
            this.submittingXml = false;

          }
          else {
            this.errMsg = response.message;
            this.submittingXml = false;
          }
        }
        else {
          this.errMsg = "There was a problem submitting the manifest.";
          this.submittingXml = false;
        }
      });
    }

  }

  generateReport(manifestConfirmation: string = "") {
    return new Promise<boolean>((finished) => {
      this.errMsg = "";
      this.loadingReport = true;
      const gst = this._groundStopAdvanceService;
      let gstId = this.groundStopTaskId;
      this.updateApisManifest();
      var self = this;
      let filePath = window.origin + "/assets/templates/ApisReport.docx";      
      let apisData = this.apisManifest;
      let operatorName = "";
      let isInbound = this.isInbound;
      let isOutbound = this.isOutbound;
      let InboundOutbound = this.InboundOutbound;
      let lastNext = this.lastNext;
      const datepipe: DatePipe = new DatePipe('en-US')
      if (apisData.operatorCompany != null && apisData.operatorCompany != "") {
        operatorName = apisData.operatorCompany;
      }
      else {
        operatorName = apisData.operatorFirstName;
        if (apisData.operatorMiddleName != null && apisData.operatorMiddleName != "")
          operatorName += " " + apisData.operatorMiddleName;
        operatorName += " " + apisData.operatorLastName;
      }
      let ownerName = "";
      if (apisData.ownerCompany != null && apisData.ownerCompany != "") {
        ownerName = apisData.ownerCompany;
      }
      else {
        ownerName = apisData.ownerFirstName;
        if (apisData.ownerMiddleName != null && apisData.ownerMiddleName != "")
          ownerName += " " + apisData.ownerMiddleName;
        ownerName += " " + apisData.ownerLastName;
      }
      if (apisData.departureCountryCode == "USA") {
        this.isInbound = false;
        this.InboundOutbound = "Outbound";
      };
      loadFile(filePath, function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser, nullGetter() { return ''; } });

        doc.setData({
          manifestConfirmation: manifestConfirmation,
          isInbound: isInbound,
          isOutbound: isOutbound,
          InboundOutbound: InboundOutbound,
          lastNext: lastNext,
          emergencyContactFirst: apisData.emergencyContactFirst,
          emergencyContactLast: apisData.emergencyContactLast,
          emergencyContactPhone: apisData.emergencyContactPhone,
          emergencyContactEmail: apisData.emergencyContactEmail,
          departureICAO: apisData.departureICAO,
          departureCity: apisData.departureCity,
          departureState: apisData.departureState,
          departureCountry: apisData.departureCountryCode,
          departurePlaceDescription: apisData.departurePlaceDescription,
          departureDate: apisData.departureDateLocal,
          departureTime: apisData.departureTimeLocal,
          arrivalICAO: apisData.arrivalICAO,
          arrivalCity: apisData.arrivalCity,
          arrivalState: apisData.arrivalState,
          arrivalCountry: apisData.arrivalCountryCode,
          arrivalPlaceDescription: apisData.arrivalPlaceDescription,
          arrivalDate: apisData.arrivalDateLocal,
          arrivalTime: apisData.arrivalTimeLocal,
          borderCrossing: apisData.borderCrossing,
          foreignAirports: apisData.foreignAirports,
          registration: apisData.registration,
          aircraftType: apisData.aircraftType,
          aircraftColor: apisData.aircraftColor,
          callSign: apisData.callSign,
          cbpDecal: apisData.cbpDecal,
          operatorCompany: operatorName,
          operatorAddress1: apisData.operatorAddress1,
          operatorAddress2: apisData.operatorAddress2,
          operatorCity: apisData.operatorCity,
          operatorState: apisData.operatorStateProvince,
          operatorZip: apisData.operatorZip,
          operatorCountry: apisData.operatorCountryCode,
          operatorPhone: apisData.operatorPhone,
          operatorFax: apisData.operatorFax == null ? '' : apisData.operatorFax,
          operatorEmail: apisData.operatorEmail,
          ownerCompany: ownerName,
          ownerAddress1: apisData.ownerAddress1,
          ownerAddress2: apisData.ownerAddress2,
          ownerCity: apisData.ownerCity,
          ownerState: apisData.ownerStateProvince,
          ownerZip: apisData.ownerZip,
          ownerCountry: apisData.ownerCountryCode,
          ownerPhone: apisData.ownerPhone,
          ownerFax: apisData.ownerFax == null ? '' : apisData.ownerFax,
          ownerEmail: apisData.ownerEmail,
          crew: apisData.crewList,
          pax: apisData.paxList,
          assemblyDate: datepipe.transform(new Date(), 'MMM-dd-YYYY HH:mm')

        });
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
                {});
            }
            return value;
          }
          console.log(JSON.stringify({ error: error }, replaceErrors));
          self.errMsg = JSON.stringify({ error: error }, replaceErrors);
          self.loadingReport = false;

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
            self.errMsg = "There was an error creating the report."
            // errorMessages is a humanly readable message looking like this:
            // 'The tag beginning with "foobar" is unopened'
          }
          this.loading = false;
          throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });

        //saveAs(out, "apisreport.docx");
        let uri;
        //if (window.origin.indexOf('dev') > -1 || window.origin.indexOf('localhost') > -1)
        //  uri = 'https://fpipdfconversion-dev.azurewebsites.net/api/ConvertToPdf'
        //else
          uri = 'https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf'
        const functionURI = uri;

        let headers = new HttpHeaders();
        const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
        //  headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
        var documentName: string;

        documentName = "APIS Manifest Report - " + apisData.registration + "-" + apisData.departureICAO + "-" + apisData.arrivalICAO;
        if (manifestConfirmation != "")
          documentName += "_" + manifestConfirmation;
        httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {

          let upload = new UploadTaskDocument();
          documentName += ".pdf"
          upload.uploadFile(data.body, gstId, documentName, gst, undefined, apisData.tripCode);
          var fileURL = URL.createObjectURL(data.body);
          if (manifestConfirmation == '')
            window.open(fileURL);
          self.loadingReport = false;
          finished(true);
        });



      });

    });

  }


  validateAirport(airportType: string) {

    this.airportList = [];
    let request = new AirportModel();

    request.route = this.generate_apis_91_form.get(airportType).value;

    this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.airportList = response.model;
        if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
          this.airportList.forEach(x => {
            if (airportType == "departureAirport") {
              this.isDepApisApproved = x.isApisApproved;
              this.f.departureCity.setValue(x.cityName);
              this.f.departureState.setValue(x.stateProvinceAbbreviation);
            }
            else {
              this.isArrApisApproved = x.isApisApproved;
              this.f.arrivalCity.setValue(x.cityName);
              this.f.arrivalState.setValue(x.stateProvinceAbbreviation);
            }
          });
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }
    });
  }

  close() {
    let obj = new ReturnObjModel();
    obj.refresh = this.isUpdated;
    this._dialogRef.close(obj);
  }

}


