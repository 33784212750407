<div [ngClass]="isPopup? 'greyBack':'divLeft'" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isPopup">
  <div>    
    <div style="display: inline-flex; justify-content: space-between; width: 100%;">
      <div style="display: inline-flex;">
        <i class="material-symbols-outlined" style="margin-top: 0.3em;">
          dataset
        </i>
        <div style="margin-left: 0.5em; margin-bottom: 0.5em;">
          <h3 style="margin-bottom: 0em;">Client Setup</h3>
          <h5 style="color: #1976e6;">{{selectedClientName}}</h5>
        </div>
      </div>
      <div *ngIf="isPopup">
        <button mat-button class="btn-low" (click)="openSelection('Client Information')">Client Information</button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;" (click)="openSelection('Aircraft')">Aircraft</button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;" (click)="openSelection('Crew, PAX & Support')">Crew, PAX & Support</button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;" (click)="openSelection('Admin Services')">Admin Services</button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;">Feedback</button>
      </div>
      <div *ngIf="isPopup" style="display: flex;">
        <button mat-icon-button (click)="openClientProfileSetupComponent()" >  <i class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i> </button>
        <button mat-icon-button (click)="clickRouteNew('customers/customer-profile-setup-edit','')">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>    
        <button mat-icon-button mat-dialog-close><i class="material-icons" style="font-size: x-large;">close </i></button>
      </div>
    </div>
    <div>
      <form *ngIf="customer_profile_setup_edit_form" [formGroup]="customer_profile_setup_edit_form">
        <div style="margin-top:0em;background-color:white; padding-left:0em; height: 44em;" [ngStyle]="{'padding': isPopup ? '1em 1em 1em 1em' : '0.25em 0.25em 0.25em 0em'}">
          <div class="d-inline-flex">
            <div style="background-color: #ffffff;">
              <div style="border: 0.0625em solid lightgrey; border-radius: 0.25em; padding: 1em;">
                <div class="d-inline-flex">
                  <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em; width: 11.5em;">
                    <label for="customerName" style="font-size:small; font-weight: bold;">Client Name</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <div style="font-size: small;">{{customerName}}</div>
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-right:0.625em; width: 11.5em;">
                    <label for="legalEntityName" style="font-size:small; font-weight: bold;">Legal Entity Name</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <div style="font-size: small;">{{legalEntityName}}</div>
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-right:0.625em; width: 11.5em;">
                    <label for="dbaName" style="font-size:small; font-weight: bold;">DBA Name</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <div style="font-size: small;">{{dbaName}}</div>
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-right:0.625em; width: 11.5em;">
                    <label for="sales_territory" style="font-size:small; font-weight: bold;">Sales Territory</label>
                    <div style="margin-top:-0.35em;">
                      <div style="font-size: small;">{{marketingRegion}}</div>
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-right:0.625em; width: 11.5em;">
                    <label for="industry_type" style="font-size:small; font-weight: bold;">Industry Type</label>
                    <div style="margin-top:-0.35em;">
                      <div style="font-size: small;">{{industryType}}</div>
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-right:0.625em; width: 11.5em;">
                    <label for="stock_symbol" style="font-size:small; font-weight: bold;">Stock Symbol</label>
                    <div style="margin-top:-0.35em;">
                      <div style="font-size: small;">{{stock_symbol}}</div>
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-left:2em; right:0em">
                    <button mat-button class="btn-high" (click)="clickEditMain()">Edit</button>
                  </div>
                </div>
                <div class="position-relative">
                  <div class="d-inline-flex">
                    <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em; width: 11.5em;">
                      <label for="customer_type" style="font-size:small; font-weight: bold;">Account Status</label>
                      <div style="margin-top:-0.35em;">
                        <div style="font-size: small;">{{customerType}}</div>
                      </div>
                    </div>
                    <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em; width: 11.5em;">
                      <label for="account_status" style="font-size:small; font-weight: bold;">Credit Status</label>
                      <div style="margin-top:-0.35em;">
                        <div style="font-size: small;">{{creditStatus}}</div>
                      </div>
                    </div>
                    <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em; width: 11.5em;">
                      <label for="apis_carrier_code" style="font-size:small; font-weight: bold;">APIS Carrier Code</label>
                      <div style="margin-top:-0.35em;">
                        <div style="font-size: small;">{{apisCarrierCode}}</div>
                      </div>
                    </div>
  
                    <div class="form-group" style="margin-top:0em; margin-right:0.625em; width: 11.5em;">
                      <label for="acctCreationDate" style="font-size:small; font-weight: bold;">Account Creation Date</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <div style="font-size: small;">{{acctCreationDate}}</div>
                      </div>
                    </div>
                    <div class="form-group" style="margin-top:0em; margin-right:0.625em; width: 11.5em;">
                      <label for="acctCreationDate" style="font-size:small; font-weight: bold;">Requests Prelim APIS</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <div style="font-size: small;">{{showApisReportFirst === true ? 'Yes' : 'No'}}</div>
                      </div>
                    </div>
                    <div class="form-group" style="margin-top:0em; margin-right:0.625em; width: 11.5em;">
                      <label for="modifiedBy" style="font-size:small; font-weight: bold;">Modified by</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        <div style="font-size: small;">{{modifiedBy}}</div>
                      </div>
                    </div>
                    <div class="form-group" style="margin-top:0em; margin-right:0.625em; width: 11.5em;">
                      <label for="modifiedDate" style="font-size:small; font-weight: bold;">Modified Date</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        <div style="font-size: small;">{{modifiedDate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; white-space:pre-wrap"><label style="color:red; font-size: small">{{errMsg}}</label></div>

          <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>
          <div class="form-group form-inline mt-2" style="margin-bottom: 1em;">
            <ul class="tabul">
              <li [ngClass]="{ 'btn-high':selectedTabName=='regulatory','btn-low pointer':selectedTabName!='regulatory'  }" style="border-left: black 0.0625em solid;border-bottom-left-radius:0.25em;border-top-left-radius:0.25em;" (click)="clickTab('regulatory')"><div style="margin-top:0.25em">Regulatory Services</div></li>
              <li [ngClass]="{ 'btn-high':selectedTabName=='notes','btn-low pointer':selectedTabName!='notes' }" (click)="clickTab('notes')"><div style="margin-top:0.25em">Internal Notes</div></li>
              <li [ngClass]="{ 'btn-high':selectedTabName=='homebase','btn-low pointer':selectedTabName!='homebase' }" (click)="clickTab('homebase')"><div style="margin-top:0.25em">Homebase Airports</div></li>
              <li [ngClass]="{ 'btn-high':selectedTabName=='haltServices','btn-low pointer':selectedTabName!='haltServices' }" (click)="clickTab('haltServices')"><div style="margin-top:0.25em">Halt Services</div></li>
              <li [ngClass]="{ 'btn-high':selectedTabName=='accounting','btn-low pointer':selectedTabName!='accounting' }" (click)="clickTab('accounting')"><div style="margin-top:0.25em">Accounting</div></li>
            </ul>
            <div *ngIf="selectedTabName!='homebase' && selectedTabName !='haltServices' && selectedTabName !='notes'" class="form-group" style="margin-left: 0.5em;margin-top:.5em">
              <input type="checkbox" formControlName="includeDisabledRecords" (change)="checkDisabled($event)" /><label for="includeDisabledRecords" style="font-size:small">&nbsp;Include disabled records</label>
            </div>
          </div>

          <app-customer-regulatory-services *ngIf="selectedTabName=='regulatory'" [customerId]="customerGuid" [includeDisabledRecords]="includeDisabledRecords"></app-customer-regulatory-services>
          <app-customer-note #customerNote *ngIf="selectedTabName=='notes'" [customerId]="customerGuid" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-customer-note>
          <app-customer-homebase-airport *ngIf="selectedTabName=='homebase'" [customerId]="customerGuid" [includeDisabledRecords]="includeDisabledRecords"></app-customer-homebase-airport>
          <app-customer-halt-services #haltServices *ngIf="selectedTabName=='haltServices'" [customerId]="customerGuid"></app-customer-halt-services>
          <app-customer-accounting-profile #accountingProfile *ngIf="selectedTabName=='accounting'" [customerId]="customerGuid" [includeDisabledRecords]="includeDisabledRecords"></app-customer-accounting-profile>





        </div>


      </form>

    </div>


  </div>
  </div>
  <app-spin *ngIf="showSpin"></app-spin>





