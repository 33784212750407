export class FuelSupplierLocationProfilesModel {

  fuelSupplierName: string;
  fuelSuppliershortName: string;
  fuelSupplierGUID: string;
  countryName: string;
  countryID: number;
  airportID: number;
  icao: string;
  iata: string;
  faaid: string;
  intoPlaneAgent: string;
  effectiveDate: string;
  isDaily: boolean;
  isWeekly: boolean;
  isBiWeekly: boolean;
  // isQuarterly: boolean;
  isBiMonthly: boolean;
  is30Days: boolean;
  isMonthly: boolean;
  status: string;
  statusID: number;
  isActive: boolean;
  expirationDate: string;
  modifiedBy: string;
  modifiedDate: string;
  totalRecords: number;
  pageSize: number;
  pageIndex: number;
  missingColumns: string[];
  excludedCountryIds: string;
  selectedCountryIdList: string;
  selectedAirportIdList: string;
  selectedIntoPlaneAgentList: string;
  selectedStatusIdList: string;
  // selectedCycleList: string;
  selectedCycleIdList: string;
  fuelSupplierLocationProfileGUID: string;
  deactivateAllRecords: boolean;
  isModified: boolean;

  countryFilter: FuelSupplierLocationProfilesModel[];
  cycleFilter: string[];
  airportFilter: FuelSupplierLocationProfilesModel[];
  intoPlaneAgentFilter: FuelSupplierLocationProfilesModel[];
  profileList: FuelSupplierLocationProfilesModel[];
  statusList: FuelSupplierLocationProfilesModel[];
  selected: boolean; 

}
