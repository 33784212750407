<div style="margin-top: 0em; padding-top: 0em; width: 70%; margin-bottom: 2em; padding: 0.5em 1em 1em 1em ; ">
  <form *ngIf="missing_prices_airport_form" [formGroup]="missing_prices_airport_form">
    <div  >
      <div  *ngIf="isDialog"  style="display: flex; justify-content: space-between; width: 49.5em;" cdkDrag  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle >
        <div style="margin-right:1em;display: flex; padding: 0.5em;"><span class="material-symbols-outlined"> description </span><h5>Missing Airport Fuel Prices - Rolling {{searchFiltervalue}} Days</h5></div>
       
        
        <div>
          <div style="width:6em">
            <!-- <app-spin *ngIf="showSpin"></app-spin> -->
            <app-spin *ngIf="showSpin2" ></app-spin>
          </div>
        </div>



        <div *ngIf="isDialog" style="display: flex;"> 
          <button mat-icon-button  (click)="clickOpenInNew()">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>    

          <button  mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button>
        </div>
      </div>

      <div  *ngIf="!isDialog"  style="display: flex; justify-content: space-between; width: 49.5em;">
        <div style="margin-right:1em;display: flex; padding: 0.5em;"><span class="material-symbols-outlined"> description </span><h5>Missing Airport Prices List Rolling {{searchFiltervalue}} Days</h5></div>
        <div>
          <div style="width:6em">
            <!-- <app-spin *ngIf="showSpin"></app-spin> -->
            <app-spin *ngIf="showSpin2" ></app-spin>
          </div>
        </div> 
      </div>
    
    <div style="background-color: #f8f8f8; padding-left: 0.25em; padding-top: 0.25em; padding-right: 0.5em; margin-top: -0.5em; border-top: 0.0625em lightgrey solid; 
                    border-bottom: 0.0625em lightgrey solid; width: 46em; height: 2.5em">
        <div style="margin-left:0em;padding:0em; display: flex; "> 
          


                 <div>
            <div style="display: flex;   margin-left: 1em; margin-right: 1em; " align="end">
              <div style="margin-top:.5em; display:flex;   font-size:small">
                <div style="margin-top: -0.2em;"><b>Filter</b></div>
  
                <div style="display: flex; margin-left: 1em; ">
                  <div style="margin-right:0.25em">
                    <input id="f7d" type="radio" name="filter_type" value="7d" [checked]="searchFiltervalue==7"
                           (change)="searchFilterChange($event)" />
                  </div>
                  <label style="margin-top:-0.2em;white-space: nowrap " for="f7d">7 Days </label>
                </div>
  
                <div style="display: flex; margin-left: 1em; ">
                  <div style="margin-right:0.25em">
                    <input id="f14d" type="radio" name="filter_type" value="14d" [checked]="searchFiltervalue==14"
                           (change)="searchFilterChange($event)" />
                  </div>
                  <label style="margin-top:-0.2em;white-space: nowrap " for="f14d">14 Days </label>
                </div>
                <div style="display: flex; margin-left: 1em;">
                  <div style="margin-right:0.25em">
                    <input id="f30d" type="radio" name="filter_type" value="30d" [checked]="searchFiltervalue==30"
                           (change)="searchFilterChange($event)" />
                  </div>
                  <label style="margin-top:-0.2em; white-space: nowrap" for="f30d">30 Days </label>
                </div>
 
  
              </div>
            </div>
          </div>     

          <div style="display:flex;justify-content: end; width: 100%;">
            <div style="margin-top:0.125em; margin-right:0.5em;display: flex; ">
              <div style="display: flex;">
                <button type="button" mat-button class="btn-low" style="height: 2em;margin-left:0em;"  (click)="excelReport()"> Export to Excel</button>
                <button mat-button class="btn-low"  (click)="getData()" style="height: 2em;display: flex; color: #337dff;margin-left:1em; "><i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>REFRESH</button>
              </div>


            </div>
            <div style="display: flex;">

              <button mat-button class="btn-low" style="height: 2em;margin-top: 0.2em; margin-left: 0.5em;display: flex;" (click)="clickRoute('fuelmenu/fuel-menu')" ><i class="material-icons" style="font-size: medium;cursor: pointer; ">arrow_back</i>Return to Fuel Dashboard</button>
          </div>

          </div>   

        </div> 
      </div>

      <div   ><!--class="mt-2">-->
        
        <div>
          <div class="d-flex " style="width: 55em;justify-content: end; font-size: small;margin: 0.5em;" align="end"  ><!--</div> style="margin-top: -1.05em;">-->
            <!-- <mat-paginator *ngIf="sortedData.length>0" style="background-color:transparent;" [pageSize]="25"
              [showFirstLastButtons]="true" [length]="totalRecords" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page"
              (page)="pageEvent = pageIndexChange($event)">
            </mat-paginator> -->
            Total Records: {{totalRecords}}
          </div>
        </div>
        <div style="height: 32em; overflow-y: auto; width: 47em;">
          <table mat-table [dataSource]="sortedData" matSort  matSortDirection="asc"  style="width: 45em; margin-left: 0.5em;">

            <tr>
      
              <ng-container matColumnDef="Country">
                <th mat-header-cell *matHeaderCellDef   style="padding-left: 0.5em !important">
                  Country
                </th>
                <td mat-cell *matCellDef="let element"  style="text-align: left !important; padding-left: 0.5em !important;">{{ element.countryName}}</td>
              </ng-container>
              <ng-container matColumnDef="ICAO">
                <th mat-header-cell *matHeaderCellDef   >
                  ICAO
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;">{{element.icao  }}</td>
              </ng-container>
              <ng-container matColumnDef="IATA">
                <th mat-header-cell *matHeaderCellDef   >
                  IATA
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;">{{element.iata }}</td>
              </ng-container>
              <!-- <ng-container matColumnDef="TripCode">
                <th mat-header-cell *matHeaderCellDef   >
                  Trip Code
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: right;"><a href="javascript:void(0)"  (click)="clickOpenTrip(element.tripCodeGUID,element.customerGUID,element.aircraftGUID);">{{ element.tripCode}}</a></td>
              </ng-container> -->
              <ng-container matColumnDef="ArrivalDate">
                <th mat-header-cell *matHeaderCellDef   >
                  Earliest Arrival Date
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: right;">{{ element.arrivalDateUTC}}</td>
              </ng-container>

              <ng-container matColumnDef="FuelQuoteCount">
                <th mat-header-cell *matHeaderCellDef   >
                  Fuel Quote
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: right;">{{ element.fuelQuoteCount}}</td>
              </ng-container>
              <ng-container matColumnDef="FuelReleaseCount">
                <th mat-header-cell *matHeaderCellDef >
                  Fuel Release 
                </th>
                <td mat-cell *matCellDef="let element"> {{element.fuelReleaseCount}} </td>
              </ng-container>


                <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="thStyle">
                  <div style="margin-top:-0.25em;">Action</div>
                </th>
                <td mat-cell class="mat-cell2" *matCellDef="let element; let i=index ">
                  <a  *ngIf="element.hasQuotes" mat-button class="btn-low grid"  >
                    <span>Open</span>
                  </a>
                </td>
              </ng-container>
            </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row  *matRowDef="let row; columns: displayedColumn" (click)="openLocationMetricsComponent(row)"  ></tr>
            <!-- (click)="clickOpenTrip(row.tripCodeGUID,row.customerGUID,row.aircraftGUID);"   (click)="openLocationMetricsComponent(row)"  -->
          </table>
        </div>
      </div>


    </div>
  </form>
</div>
