import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { VendorService } from '../services/vendor.service';
import { DialogService } from '../services/dialog.service';
import { VendorAddressModel } from '../models/vendor-address.model';


@Component({
  selector: 'app-vendor-address-edit-dialog',
  templateUrl: './vendor-address-edit-dialog.component.html',
  styleUrls: ['./vendor-address-edit-dialog.component.css']
})
/** vendor-profile-setup-add component*/
export class VendorAddressEditDialogComponent implements OnInit {
  vendor_address_edit_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() vendorGuid: string;
  @Input() vendorAddressID: number;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  address: string;
  modifiedBy: string;
  modifiedDate: string;
  isModified: boolean;
  isActive: number;
  showSuccessMsg: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<VendorAddressEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _vendorService: VendorService,
    private readonly _dialogService: DialogService
  ) {
    this.vendorGuid = data.vendorGUID;
    this.vendorAddressID = data.vendorAddressID;
    this.dialogTitle = data.dialogTitle;

  }

  getAllData(): Observable<any[]> {


    let getVendorAddressResponse = this._vendorService.getVendorAddressByVendorAddressId<ResponseModel<VendorAddressModel>>(this.vendorAddressID);

    return forkJoin([getVendorAddressResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.getAllData().subscribe(responses => {

      if (this.vendorAddressID != null && this.vendorAddressID != 0) {
        if (responses[0] != null) {
          if (responses[0].code == "200" && responses[0].message == "") {
            let vendorAddress = new VendorAddressModel();
            vendorAddress = responses[0].model;
            this.address = vendorAddress.address;
            this.modifiedBy = vendorAddress.modifiedBy;
            this.modifiedDate = vendorAddress.modifiedDate;
            if (vendorAddress.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;

          }
        }

      }

      this.initControls();

      this.showSpin = false;


    });

  }

  initControls() {
    this.vendor_address_edit_form = this._formBuilder.group({
      address: this.address,
      note_status: this.isActive,
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate


    });
  }

  get f() { return this.vendor_address_edit_form.controls; }

  

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";
    
    if (this.f.address.value == null) {
      if (this.errMsg != "") {
        this.errMsg += "\n";
        this.errMsg += "Address is required.";
      }
      else
        this.errMsg = "Address is required.";    
    }
    if (this.vendor_address_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    let obj = new VendorAddressModel();
    obj.vendorAddressID = 0;
    if (this.vendorAddressID != 0 && this.vendorAddressID != null)
      obj.vendorAddressID = this.vendorAddressID;
    obj.vendorGUID = this.vendorGuid;
    obj.address= this.f.address.value;
    if (this.f.note_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;
    
    this._vendorService.saveVendorAddress<ResponseModel<number>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.vendorAddressID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          this.isModified = true;
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
     }
      this.loading = false;
    })

  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.vendorAddressID = 0;
    this.address = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;    
    this.initControls();    
  }

  clickClose() {
     this._dialogRef.close(this.isModified);
  }


}
