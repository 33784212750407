<div *ngIf="upPII" style="background-color:#f8f8f8;min-height:28em;min-width: 100%">
  <div style="display: flex;padding-left:1em;padding-top:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div class="d-inline-flex"><mat-icon>email</mat-icon><h4>&nbsp;   Link Messages </h4></div>
  </div>
  <div>
    <div style="margin-top:0em; padding-left: 1em; padding-right:1em; font-size: small">
      <div style="display: flex; padding-left:0em; height: 5.75em; ">
        <div style="background-color: white; padding-left: 0.5em; width: 100%;">
          <div style="display: flex">
            <div style="margin-left:2.75em">Client:</div>
            <div style="margin-left:0.5em; font-weight: bold">{{client_name}}</div>
          </div>
          <div style="display: flex">
            <div style="margin-top: 0.2rem;margin-left:2.75em">Registration:</div>
            <div style="margin-top: 0.2rem; margin-left: 0.5em; font-weight: bold">{{registration}}</div>

            <div style=" margin-left: 2.75em">Trip Code:</div>
            <a mat-button class="btn-low" (click)="clickOpenTrip();" style="align-self: center; margin-left: 0.5em">
              {{trip_code}}
            </a>

          </div>
          <div style="display: flex">
            <div style="margin-left:2.75em">Route: </div>
            <div style="margin-left:0.5em; ">
              <div style="display: flex; flex-wrap: wrap;">
                <div *ngFor="let gs of existingGroundStopList; let i=index">
                  <div style="display: flex">
                    <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==existingGroundStopList.length-1? '': '-'}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width:1em"></div>

      </div>
      <div style="height:0.2em; background-color: #f8f8f8;"></div>

      <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
        <div style="margin-right:1em;">
          <button type="button" mat-button class="btn-low" (click)="clicklegFilter() ">
            <div style="display:flex"><div>Legs{{selectedLegCountText}}</div></div>
          </button>&nbsp;
          <div *ngIf="showlegFilter" style="position:absolute;float: left; min-width: 11em; margin-top: 0.2em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
            <div style="margin-top:0.25em;" class="mat-checkbox-smaller">
              <div style="display:flex; justify-content:space-between">
                <div>
                  <b>Select Leg</b>
                </div>
                <div style="float:right" (click)="showlegFilter=!showlegFilter"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
              </div>
              <div class="d-flex border-bottom pb-2" style="flex-direction: column;">
                <div *ngFor="let leg of legFilterList; let i=index" style="display: flex;">
                  <div>
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="onLegFilterChange($event, leg)" [checked]="leg.selected"
                                  style="height:1.5em;  line-height:1.5em !important;"
                                  title="{{leg.legName}}">{{leg.departure+'-'+leg.arrival}}<div style="display: inline-flex;"> {{leg.dateText}}</div></mat-checkbox>
                  </div>
                </div>
                <!--*ngIf="leg.departureDateTime!=''"  title="{{leg.departureDateTime}}"-->
              </div>
            </div>
            <div class="d-flex justify-content-between pt-2">
              <div>
                <button class="btn-low  mr-1" mat-button (click)="clickResetLegFilter()">RESET</button>
                <button class="btn-high " mat-button (click)="clickDoneLegFilter()">DONE</button>
              </div>
            </div>
          </div>
          <!-- leg filter end -->
        </div>

        <div style="margin-right:1em;">
          <div style=" margin-right:0.25em;">
            <button type="button" mat-button class="btn-low" style="min-width:9em;" [ngClass]="selectedServiceCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickServiceFilter()">Services{{selectedServiceCountText}}</button>
          </div>

          <div *ngIf="showServiceFilter" style="position:absolute;float: left; min-width: 11em; margin-top: 0.2em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
            <div style="margin-top:0.25em;" class="mat-checkbox-smaller">
              <div style="display:flex; justify-content:space-between">
                <div>
                  <mat-checkbox style="height:1.5em;  line-height:1.5em !important;"
                                [checked]="allCompleteOps"
                                [indeterminate]="someComplete(1)"
                                (change)="selectAllServiceType($event.checked,1)">Select All Ops</mat-checkbox>
                </div>
                <div style="float:right" (click)="showServiceFilter=!showServiceFilter"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
              </div>
              <div class="d-flex border-bottom pb-2">
                <div>
                  <div *ngFor="let a of serviceTypeList; let i=index" style="display:flex;flex-direction:column;width:13em">
                    <div *ngIf="a.filterColumn==1 && a.filterGroup==1">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)" [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;" title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                    </div>
                  </div>
                </div>
                <div>
                  <div *ngFor="let a of serviceTypeList; let i=index" style="display: flex; flex-direction: column; width: 13em">
                    <div *ngIf="a.filterColumn==2 && a.filterGroup==1">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)" [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;" title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                    </div>
                  </div>
                </div>
                <div>
                  <div *ngFor="let a of serviceTypeList; let i=index" style="display: flex; flex-direction: column; width: 13em">
                    <div *ngIf="a.filterColumn==3 && a.filterGroup==1">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)" [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;" title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                    </div>
                  </div>
                </div>
                <div>
                  <div *ngFor="let a of serviceTypeList; let i=index" style="display: flex; flex-direction: column; width: 13em">
                    <div *ngIf="a.filterColumn==4 && a.filterGroup==1">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)" [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;" title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                    </div>
                  </div>
                </div>
                <div>
                  <div *ngFor="let a of serviceTypeList; let i=index" style="display: flex; flex-direction: column; width: 13em">
                    <div *ngIf="a.filterColumn==5 && a.filterGroup==1">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)" [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;" title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-2">
                <mat-checkbox style="height:1.5em;  line-height:1.5em !important;"
                              [checked]="allCompleteWx"
                              [indeterminate]="someComplete(2)"
                              (change)="selectAllServiceType($event.checked,2)">Select All Wx</mat-checkbox>
              </div>
              <div class="d-flex border-bottom pb-2">
                <div>
                  <div *ngFor="let a of serviceTypeList; let i=index" style="display: flex; flex-direction: column; width: 13em">
                    <div *ngIf="a.filterColumn==1 && a.filterGroup==2">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)" [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;" title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                    </div>
                  </div>
                </div>
                <div>
                  <div *ngFor="let a of serviceTypeList; let i=index" style="display: flex; flex-direction: column; width: 13em">
                    <div *ngIf="a.filterColumn==2 && a.filterGroup==2">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)" [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;" title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                    </div>
                  </div>
                </div>
                <div>
                  <div *ngFor="let a of serviceTypeList; let i=index" style="display: flex; flex-direction: column; width: 13em">
                    <div *ngIf="a.filterColumn==3 && a.filterGroup==2">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)" [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;" title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                    </div>
                  </div>
                </div>
                <div>
                  <div *ngFor="let a of serviceTypeList; let i=index" style="display: flex; flex-direction: column; width: 13em">
                    <div *ngIf="a.filterColumn==4 && a.filterGroup==2">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)" [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;" title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                    </div>
                  </div>
                </div>
                <div>
                  <div *ngFor="let a of serviceTypeList; let i=index" style="display: flex; flex-direction: column; width: 13em">
                    <div *ngIf="a.filterColumn==5 && a.filterGroup==2">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)" [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;" title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between pt-2">
                <div>
                  <button class="btn-low  mr-1" mat-button (click)="clickResetServiceType()">RESET</button>
                  <button class="btn-high " mat-button (click)="clickDoneServiceType()">DONE</button>
                </div>
                <!-- <div>
                  <button class="btn-med" mat-button (click)="clickSaveSelections('Service')">SAVE SELECTIONS</button>
                </div> -->
              </div>
            </div>
          </div>

        </div>
        <div style="margin-left:-0.5em">
          <mat-slide-toggle style="transform:scale(.9); height:2.5em" color="primary" [checked]="hidePastStop" (change)="hidePastStopChange($event)">Hide Prior Legs</mat-slide-toggle>
        </div>
        <div style="margin-left:-0.5em">
          <mat-slide-toggle style="transform:scale(.9); height:2.5em" color="primary" [checked]="showCancelled" (change)="showActiveLegsOnly($event)">Show Active legs only </mat-slide-toggle>
        </div>
        <div class="example-action-buttons" style=" margin-top: 0.35em; ">
          <button mat-button (click)="leglistAccordion.openAll()" color="primary" style="outline:none; font-size: small">Expand All</button>
          <button mat-button (click)="leglistAccordion.closeAll()" color="primary" style="outline: none; font-size: small">Collapse All</button>
        </div>
        <button type="button" mat-button class="btn-low" style="margin-left:7em" (click)="clickClose()">
          <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">arrow_back_ios</i><div>Back</div></div>
        </button>&nbsp;

      </div>

      <div style="height:0.2em; background-color: #f8f8f8;"></div>

      <div class="example" style="margin-top:0.5em; min-height: 34em;">
        <div style="padding-left: 0em; padding-top:0em; padding-right:0em; background-color: white">
          <div class="example" style="max-height: 34em; overflow-y: auto;overflow-x:hidden;">
          
          
            <mat-accordion displayMode="flat" multi class="mat-table">
              <section matSort class="mat-elevation-z8 mat-header-row">
                <div class="mat-header-cell"> Departure&nbsp; (z)</div>
                <div class="mat-header-cell d-flex">
                  Arrival&nbsp; (z)
                  <mat-progress-bar mode="indeterminate" style="width:30%;margin-left:.5em;margin-top:.75em" color="primary" *ngIf="showSpin"></mat-progress-bar>
                </div>
              </section>

              <mat-expansion-panel (opened)="loadServices(item.groundStopGUID, item.nextGroundStopGUID, item, i)" [expanded]="true" *ngFor="let item of displayLinkList; let i = index" [class.hidden]="hidePastStop?item.arrivalDateTimePassed:false" >
                <mat-expansion-panel-header class="mat-row" [class.hidden]="hidePastStop?item.arrivalDateTimePassed:false" [ngClass]="'color-' + (i % 2)" >
                  <span class="mat-cell" [style.background-color]="item.colorCode??item.nextColorCode"><b style="font-size: medium;">{{item.departure}}</b><span style="font-size: small;" class="d-flex">{{item.departureDateTextUTC!=''?item.departureDateTime :'TBA'}}</span> </span> 
                  <span class="mat-cell" [style.background-color]="item.colorCode??item.nextColorCode"><b style="font-size: medium;">{{item.arrival}}</b><span style="font-size: small;" class="d-flex">{{item.nextArrivalDateTextUTC!=''?item.arrivalDateTime :'TBA'}}</span></span>
                </mat-expansion-panel-header>
                <div>

                  <div class="float-container">
                    <div *ngIf="(loadingService && i==loadingIndex) || loadingServices" style="text-align: center;z-index:100">
                      <span class="spinner-border spinner-border-sm mr-1"></span>
                    </div>
                    <div class="float-child">
                      <div style="display: inline-flex; font-size: small;"><h6>Departure Services</h6></div>
                      <div style="display: flex;  " *ngFor="let arr of item.departureServiceList">
                        <div  style="width: 1.5em; ">
                          <mat-checkbox style=" display: inline-flex;" class="mat-checkbox-smaller" [checked]="arr.selected" (change)="updateSelectedLinkTasks(arr)">
                            <div style="margin-bottom: 0em; display: inline-flex; border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right: 0.125em; " [style.background-color]="arr.taskStatusColor" title="{{arr.taskStatusDescription}}"></div>
                            {{arr.serviceTypeDescription  }} <span style=" font-size: small; white-space: normal; display: flex; margin-top: 0em; color: darkblue; margin-right: 0.25em; font-size: small; font-weight: bold; display: inline-flex ">{{arr.vendorName}}  {{arr.countryName}}   {{arr.confirmation  }}   </span>
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                     <div class="float-child">
                      <div style="display: inline-flex; font-size: small;"><h6>Arrival Services</h6></div>
                      <div style="display: flex;  " *ngFor="let arr of item.arrivalServiceList">
                        <div  style="width: 1.5em; ">
                          <mat-checkbox style="margin-bottom:0em; display:inline-flex;" class="mat-checkbox-smaller" [checked]="arr.selected" (change)="updateSelectedLinkTasks(arr)">
                            <div style="margin-bottom: 0em; display: inline-flex; border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right: 0.125em; " [style.background-color]="arr.taskStatusColor" title="{{arr.taskStatusDescription}}"></div>
                            {{arr.serviceTypeDescription  }} <span style="font-size: small; width: 25em; white-space: normal; display: flex; margin-top: 0em; color: darkblue; margin-right: 0.25em; font-weight: bold; display: inline-flex ">{{arr.vendorName}}  {{arr.countryName}}  {{arr.confirmation  }}    </span>
                          </mat-checkbox>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          

          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
    <div style="margin-right:1em;" *ngIf="showLinkMsg">
      <button type="button" mat-button class="btn-high" (click)="linkMessage()">
        <div style="display:flex"><div>Link Message</div></div>
      </button>&nbsp;
    </div>
    <app-success-message *ngIf="showSuccessMessage"></app-success-message>
  </div>
</div>


