import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ResponseModel } from '../models/response.model';
import { MessageService } from '../services/message.service';
import { AuthenticateService } from '../services/authenticate.service';
import { MessageFolderModel } from '../models/message-folder.model';
import { CountryService } from '../services/country.service';
import { DocumentTypeModel } from '../models/document-type.model';
import { CountryContentDocModel } from '../models/country-content-doc.model';
import { CountryDocumentTypeEditDialog } from './country-document-type-edit-dialog.component';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { FpcPicklistDataComponent } from '../common-utility/fpc-picklist-data.component';
 
@Component({
  selector: 'app-country-document-type-update-dialog',
  templateUrl: './country-document-type-update-dialog.component.html',
  styleUrls: ['./country-document-type-update-dialog.component.css'],
})

export class CountryDocumentTypeUpdateDialog implements OnInit {

  loading = false;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  successMsg: string = "Document Updated Succesfully.";
  warningMsg: string = "Can not Enter Charecters like ~!@#$%^&*()_+{}|:<>,.;'[]\\";
  showWarningMsg: boolean = true;
  errorMsg: string = "";
  showErrorMsg: boolean = false;
  
  displayedColumn: string[] = ['messageFolder', 'action' ];
  toBeEditedID: number;
 
  hasSpecialCharacters: boolean = false;
  countryRequiredDocumentGUID: string = '';
  countryRequiredDocumentTypeList:DocumentTypeModel[] = [];
  searchFiltervalue: number = 0;
  isMiscellaneous: boolean = false;
  isActive: boolean = true;
  totalRecords: number;
  isDialog: boolean = false;
  dialogFrom: string = '';


  constructor(@Optional() private readonly _dialogRef: MatDialogRef<CountryDocumentTypeUpdateDialog>,
    @Optional()  @Inject(MAT_DIALOG_DATA) private _data: any,
     private readonly _messageService: MessageService, private readonly _countryService: CountryService,
    private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog) {

      if (this._data) {
        this.isDialog = true;
        this.dialogFrom = this._data.dialogFrom;
      }
  }
 
  ngOnInit() {
    this.showSuccessMsg = false; 
    this.showErrorMsg = false;
    this.errorMsg = "";

    // let request = new DocumentTypeModel();
    // request.documentTypeID = this.documentTypeID;
    // request.documentTypeDescription = this.editDocumentName;

 
  this.getData();

    
  }
 

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  editDocumentName(element: DocumentTypeModel ) {
    this._authService.updateAccessTime();
    let s = "Edit";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "45em"; // "1000px"
    // dialogConfig.height = "30em"; // "500px"
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    // dialogConfig.hasBackdrop = false;

    dialogConfig.data = { dialogTitle: s , documentTypeList: this.countryRequiredDocumentTypeList, documentTypeID:  element.documentTypeID, disableRecord: !element.isActive,
      isMiscellaneous: element.isMiscellaneous, modifiedBy: element.modifiedBy, modifiedDate: element.modifiedDate };

    const dialogRef = this._dialog.open(CountryDocumentTypeEditDialog, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this._dialogRef.close(result);
        this.ngOnInit();

      }
    });
  }

  addDocumentName(element: DocumentTypeModel ) {
    this._authService.updateAccessTime();
    let s = "Add";
    const dialogConfig = new MatDialogConfig();
     dialogConfig.width = "45em";  
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true; 

    dialogConfig.data = { dialogTitle: s , documentTypeList: this.countryRequiredDocumentTypeList, documentTypeID:  0}; 
    const dialogRef = this._dialog.open(CountryDocumentTypeEditDialog, dialogConfig); 
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this._dialogRef.close(result);
        this.ngOnInit();

      }
    });
  }
 
   
  // checkForSpecialCharacters() {
  //   const specialChars = "`~-=|'!@#$%^&*()_+{}[]:;<>,.?~\\/\"";// Define your list of special characters
  //   for (let i = 0; i < this.newFolderName.length; i++) {
  //     if (specialChars.includes(this.newFolderName[i])) {
  //       this.hasSpecialCharacters = true; // Special character found
  //       return;
  //     }
  //   }
  //   this.hasSpecialCharacters = false; // No special characters found
  // }
 
  searchFilterChange(e) {
    

    // if (this.searchParam != null || this.searchParam.length > 0) {
      switch (e.target.value) {
        case 'requiredDoc':
          this.searchFiltervalue = 0;
  
          this.isMiscellaneous = false;
            this.getData();
        
          break;
        case 'miscDoc':
          this.searchFiltervalue = 1;
          this.isMiscellaneous = true;

          this.getData();          
        
          break;
     
        default:
          this.searchFiltervalue = 0;
          this.isMiscellaneous = false; 
          
          this.getData();

          return;
      }
    // }
  }

  getData() {
    let request = new DocumentTypeModel();
    request.isActive = this.isActive ;
    request.isMiscellaneous = this.isMiscellaneous;


    let getDocTypeResponse = this._countryService.getCountryRequiredDocumentTypeListForUpdates<ResponseModel<DocumentTypeModel[]>>(request).subscribe(result =>{
      if(result.code == '200'  && result.message == ""){
        const filteredList = result.model;
        this.totalRecords = filteredList.length;

         this.countryRequiredDocumentTypeList= filteredList;  //.filter(item => !item.isMiscellaneous);
  
 
          // (this._data.selectedDocTypes as CountryContentDocModel[]).forEach(doc => {
          //   this.countryRequiredDocumentTypeList = this.countryRequiredDocumentTypeList.filter(d => d.documentTypeID != doc.documentTypeId);
          // });
          this.countryRequiredDocumentTypeList.forEach((docType) => {
            docType.docCount = 0;
          });
          this.countryRequiredDocumentTypeList.forEach((docType) => {
            docType.isSelected = false;
          });
        
        
      }
      if(result.code == '401'){
        this._authService.signOut();
      }
    });

   }

   checkNoExpiry(isChecked: boolean) {
    if (isChecked){
      this.isActive = false;
    }
    else{
      this.isActive = true;
    }
    this.getData();

  }

   
  exportToExcel(){
    let request = new DocumentTypeModel();
    request.isActive = this.isActive ;
    request.isMiscellaneous = this.isMiscellaneous;


    this._countryService.getCountryRequiredDocumentTypeListForUpdates<ResponseModel<DocumentTypeModel[]>>(request).subscribe(response =>{
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          let tempMissingList = response.model;
          // this.sortedData = tempMissingList.slice();
          if (response.model.length > 0) {
            if (tempMissingList != null) {
              // this.totalRecords = tempMissingList[0].totalRecords;
              // this.totalRecordsText = this.totalRecords + " total records"
              let wb=new Workbook();
              let ws =wb.addWorksheet("CountryDocuments")
              
              let header=['Document Name' ]
              ws.addRow(header);
              tempMissingList.forEach( al => {
                
                ws.addRow([al.documentTypeDescription ]);
              } )
          
              wb.xlsx.writeBuffer().then((data)=>{
                let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob,"CountryDocuments.xlsx");
              });
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errorMsg = "An error has occurred.";
        }
      }
    });
 
  }

  clickRouteNew(route: string,display: string){
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }

  
  openFpcPicklistDataComponent() {
    this.clickClose();
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "33em";//"1000px";
    config.width = "55em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "",     isDialog: true, v: this._authService.getCurrentTimeNumber()   };
    this._dialog.open(FpcPicklistDataComponent, config);  
  }

}
 

