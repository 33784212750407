import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CountryService } from '../services/country.service';
import { ResponseModel } from '../models/response.model';
import { DocumentTypeModel } from '../models/document-type.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CountryContentDocModel } from '../models/country-content-doc.model';

@Component({
  selector: 'app-country-required-permit-documents-dialog',
  templateUrl: './country-required-permit-documents-dialog.component.html',
  styleUrls: ['./country-required-permit-documents-dialog.component.css']
})

export class CountryRequiredPermitDocumentsComponent implements OnInit {
  // userType: string; 
  country_required_permit_documents_dialog_form: UntypedFormGroup;
  // loading = false; 
  countryRequiredDocumentGUID:string = '';
  countryRequiredDocumentTypeList:DocumentTypeModel[] = [];
  selectedDocTypeList:DocumentTypeModel[] = [];
  
  countryRequiredMiscDocumentTypeList:DocumentTypeModel[] = [];
  selectedMiscDocTypeList:DocumentTypeModel[] = [];

  isMisc: boolean;
  

  constructor(private readonly _dialogRef: MatDialogRef<CountryRequiredPermitDocumentsComponent>,
     @Inject(MAT_DIALOG_DATA) private _data: any,private readonly _formBuilder: UntypedFormBuilder, 
     private readonly _countryService: CountryService, private readonly _authService: AuthenticateService,
    ) {
   
    // this.tripRevisionID = _data.tripRevisionID;
    this.isMisc = _data.isMisc;
  }

  ngOnInit() {

    if(!this.isMisc){
      this.getData();
    }
    else{
      this.getMiscData();
    }


   }

  getData(){
  let getDocTypeResponse = this._countryService.getCountryRequiredDocumentTypeList<ResponseModel<DocumentTypeModel[]>>(this.countryRequiredDocumentGUID).subscribe(result =>{
    if(result.code == '200'  && result.message == ""){
      const filteredList = result.model;
      
       this.countryRequiredDocumentTypeList= filteredList.filter(item => !item.isMiscellaneous);

      if (this._data.selectedDocTypes){

        (this._data.selectedDocTypes as CountryContentDocModel[]).forEach(doc => {
          this.countryRequiredDocumentTypeList = this.countryRequiredDocumentTypeList.filter(d => d.documentTypeID != doc.documentTypeId);
        });
        this.countryRequiredDocumentTypeList.forEach((docType) => {
          docType.docCount = 0;
        });
        this.countryRequiredDocumentTypeList.forEach((docType) => {
          docType.isSelected = false;
        });
      }
      
    }
    if(result.code == '401'){
      this._authService.signOut();
    }
  })

  }

  getMiscData(){
    let getMiscDocTypeResponse = this._countryService.getCountryRequiredDocumentTypeList<ResponseModel<DocumentTypeModel[]>>(this.countryRequiredDocumentGUID).subscribe(result =>{
      if(result.code == '200'  && result.message == ""){
        const filteredList = result.model;
        
         this.countryRequiredMiscDocumentTypeList= filteredList.filter(item => item.isMiscellaneous);
  
        if (this._data.selectedDocTypes){
  
          (this._data.selectedDocTypes as CountryContentDocModel[]).forEach(doc => {
            this.countryRequiredMiscDocumentTypeList = this.countryRequiredMiscDocumentTypeList.filter(d => d.documentTypeID != doc.documentTypeId);
          });
          this.countryRequiredMiscDocumentTypeList.forEach((docType) => {
            docType.docCount = 0;
          });
          this.countryRequiredMiscDocumentTypeList.forEach((docType) => {
            docType.isSelected = false;
          });
        }
        
      }
      if(result.code == '401'){
        this._authService.signOut();
      }
    })
  
    }
  

  initControls() {
    this.country_required_permit_documents_dialog_form = this._formBuilder.group({
      // userComments: [this.userComments]
      doc_Count:[0]

    });


  }

    get f() { return this.country_required_permit_documents_dialog_form.controls; }


 

  clickClose() {
    this._dialogRef.close(null);
    // if (this.controlValueChanged == true) {
    //   this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save the change before close?");
    //   this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
    //     this.eventSubscription.unsubscribe();
    //     if (result == true) {
    //       //this.clickSave();
    //     }
    //     else {
    //       this._dialogRef.close(this.returnObj);
    //     }
    //   });
    // }
    // else {
    //   this._dialogRef.close(this.returnObj);
    // }

  }

  clickAdd(){
    this._dialogRef.close(this.selectedDocTypeList);

  }

  clickAddMisc(){
    this._dialogRef.close(this.selectedMiscDocTypeList);

  }

  // checkToChange1(e: any, docType:DocumentTypeModel){
  //   // console.log(e)
  //   if (e.checked ) {
  //     this.selectedDocTypeList.push(docType);
  //   } 
  //   else{
  //     let index = this.selectedDocTypeList.findIndex(d => d.documentTypeGUID == docType.documentTypeGUID)
  //     this.selectedDocTypeList.splice(index, 1 );

  //   }
  // }

 
  
  
  checkToChange(e: any, docType: DocumentTypeModel) {
    const docCount = docType.docCount; //this.f.doc_Count.value;

    if (e.checked) {
      if( docType.docCount == 0){
        docType.docCount = 1;
      }
      docType.isSelected = true;
    } 
    else {
      docType.docCount = 0;
      docType.isSelected = false;


    }
  }

  docCountOnChange(e: any, docType: DocumentTypeModel) {
  
    docType.docCount = docType.docCount;
    docType.docCount = docType.docCount;
  }

 
  addSelectedDocTypes() {
    this.countryRequiredDocumentTypeList.forEach(docType => {
      if (docType.isSelected) {
        for (let i = 0; i < docType.docCount; i++) {
          this.selectedDocTypeList.push(docType);
        }
      }
    });
     this.clickAdd();
  }
  
  addSelectedMiscDocTypes() {
    this.countryRequiredMiscDocumentTypeList.forEach(docType => {
      if (docType.isSelected) {
        for (let i = 0; i < docType.docCount; i++) {
          this.selectedMiscDocTypeList.push(docType);
        }
      }
    });
     this.clickAddMisc();
  }
 
}
  
 











