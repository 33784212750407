import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { ReturnObjModel } from '../models/return-obj.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component'; 
import { DatePipe, formatDate } from '@angular/common';
import { CurrencyModel } from '../models/currency.model'; 
import {  FuelService } from '../services/fuel.service';
import { AirportModel } from '../models/airport.model'; 
import { FuelClientMarkupModel } from '../models/fuel-client-markup.model';
import { CustomerModel } from '../models/customer.model';
import { CountryModel } from '../models/country.model';
import { FuelExchangeRateMarkupModel } from '../models/fuel-exchange-rate-markup.model';


@Component({
  selector: 'app-fuel-markup-exchange-edit',
  templateUrl: './fuel-markup-exchange-edit.component.html',
  styleUrls: ['./fuel-markup-exchange-edit.component.css']
})

export class FuelExchangeRateMarkupEditComponent implements OnInit, AfterViewInit {

  //@Input() isfromfuelpricing: boolean = true;
  @Input() dialogTitle: string;
  fuel_Markup_exchange_edit: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  customerList: CustomerModel[];
  countryList: CountryModel[]; 

  currencylist: CurrencyModel[];
  airportlist: AirportModel[];

  airportid: number;


  show: boolean = false;
  showModified: boolean = false;
  fuelClientMarkupGUID: string;
  fuelExchangeRateMarkupID: number;
  clientGUID: string;
  clientid: number;
  countryid: number;

  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  controlValueChanged: boolean;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  customerName: any; 
      
    

  scaleneedsadjustment: any;
  taxesIncluded: boolean = false;

  isAllowImport: any;

  allowfuelpriceimport: any;
  isallowfuelpriceimport: string;
  allowfuelpriceimport1: boolean;
  successMsg: string;
  icao: string;
  iata: string;
  markupAmount: number;
  effectivedate: string = new Date().toString();
  expirydate: string="";// = new Date().toString();  
  fuelsupplier: string;
  fuelsuppliername: string;
  isExistingPrice: boolean = false;

  isValidICAO: boolean = false;
  validateICAO: boolean = true; 

   

  fuelRangeForm: FormGroup;
  isDuplicate: boolean = false;
  ifsuccess: boolean = false;
    internalnotes: string;
    countryGUID: string;
  setAllCountrySelection: boolean = false;


  iscountryorairport: string = "country";
    countryName: string;
    countrylength: number;
    showDuplicateText: boolean=false;

  constructor(private readonly _dialogRef: MatDialogRef<FuelExchangeRateMarkupEditComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    //private readonly _fuelPricing: FuelPricingService,
    //private readonly _fuel: FuelSupplierService,
    private readonly _fuel: FuelService,
    private readonly _dialogService: DialogService,
    private datePipe: DatePipe,
    private fb: FormBuilder

  ) {
    this.fuelExchangeRateMarkupID = _data.fuelExchangeRateMarkupID;
    this.dialogTitle = _data.dialogTitle;

    this.isAllowImport = false;
     


    if (_data.dialogTitle == "Add") {
      this.showModified = false;
      this.isExistingPrice = false;

    }
    else {
      this.isValidICAO = true;
      this.showModified = true;
      this.isExistingPrice = true;
    }

    //this.fuelRangeForm = this.fb.group({ 
    //  quantities: this.fb.array([]),
    //});
  }

  getAllData(): Observable<any[]> { 

    let request = new FuelExchangeRateMarkupModel();

    let getCustomers = this._fuel.getCustomers(); //getActiveFuelSupplier();  //  getFuelSupplierList(); getActiveFuelSupplier
    // let getCountry = this._fuel.getFuelExchangeRateMarkupCountryList(request); 
    let getCountry = this._fuel.getClientMarkupCountryList(); 

    //let getAirportList = this._fuel.getAirportList();
    if (this.fuelExchangeRateMarkupID != 0) {// != null) {
      let getfuelClientMarkupByfcmId = this._fuel.getFuelExchangeRateMarkupRatebyId(this.fuelExchangeRateMarkupID);
      return forkJoin([getCustomers, getCountry, getfuelClientMarkupByfcmId]);//, getUnitOfMeasure, getCurrency]);
    }
    else {
      return forkJoin([getCustomers, getCountry]);//[getDomFrequency, getIntlFrequency, getUnitOfMeasure, getCurrency]);
    }
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    //this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;

    this.allowfuelpriceimport = 0;  

    this.isValidICAO = false;

    //this.clientGUID = 'default';
    this.clientid = 0;
    this.countryid = 0;


    //  this.fuelSupplierList = [];
    this.customerList = [];
    this.countryList = [];
    this.showSuccessMsg = false;

    //this.fuel_Markup_exchange_edit.get('effectivedate').patchValue(this.formatDate(new Date()));
    //this.fuel_Markup_exchange_edit.get('expirydate').patchValue(this.formatDate(new Date()));
    this.getAllData().subscribe(responses => {
      if (responses != null) { }
      //this.fuelsupplier = 'default';
      //this.intlfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';


      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {

          this.customerList = responses[0].model;

          //let customer = new CustomerModel();

          //customer = responses[0].model;
          //if (customer.customerGUID == 'undefined') {
          //  this.customerName = customer.customerName;
          //}

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.countryList = responses[1].model;


          let country = new CountryModel();

          country = responses[1].model;
          if (country.countryGUID == 'undefined') {
            this.countryGUID = country.countryGUID;
          }

          this.countryList = this.countryList.filter(c => ![259,260,161].includes(c.countryID) )

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          let fcm = new FuelExchangeRateMarkupModel();
          fcm = responses[2].model;


         // this.iscountryorairport = fcm.airportID == 0 ? 'country' : 'airport';

          this.fuelExchangeRateMarkupID = fcm.fuelExchangeRateMarkupID;
          this.clientid = fcm.customerID;
          this.countryid = fcm.countryID;
          this.airportid = fcm.airportID;
          this.icao = fcm.icao;
          this.iata = fcm.iata;
          this.markupAmount = fcm.markupAmount;
          this.isActive = fcm.isActive == true ? 1 : 0;

         // this.icaoValidation();

          this.iscountryorairport = fcm.airportID == 0 ? 'country' : 'airport';
        

          this.effectivedate = fcm.effectiveDate == null ? '' : formatDate(fcm.effectiveDate, 'yyyy-MM-dd', 'en');// taf.effectiveDate;
          this.expirydate = fcm.expiryDate == null ? '' : formatDate(fcm.expiryDate, 'yyyy-MM-dd', 'en');//  taf.expiryDate;
           
          //this.internalnotes = fcm.internalNotes;
          this.modifiedBy = fcm.modifiedBy;
          this.modifiedDate = fcm.modifiedDate;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.checkFormControlValueChanged();

      this.showSpin = false;



    });

    this.initControls();
    this.showSpin = false;
  }

  private formatDate(date) {
    if (date != null && date != '') {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    }
  }

  ngAfterViewInit() {

  }

  initControls() {
    this.fuel_Markup_exchange_edit = this._formBuilder.group({
      fuelExchangeRateMarkupID: [this.fuelExchangeRateMarkupID],
      //fuelClientMark upGUID: [this.fuelClientMa rkupGUID, Validators.required],
     // clientGUID: [this.clientGUID, Validators.required],
      clientid: [this.clientid, Validators.required],
      countryid: [this.countryid, Validators.required],
      icao: [this.icao, Validators.required],
      iata: [this.iata, Validators.required],
      //airportid: [this.airportid, Validators.required],
      markupAmount: [this.markupAmount, Validators.required],
      effectivedate: [formatDate(this.effectivedate, 'yyyy-MM-dd', 'en'), [Validators.required]],// [this.effectivedate, Validators.required],
      expirydate: [this.expirydate, Validators.required], //[formatDate(this.expirydate, 'yyyy-MM-dd', 'en'), [Validators.required]],//[this.expirydate, Validators.required],

      internalnotes: [this.internalnotes, Validators.required],
      isActive: [this.isActive],

     
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],

      setAllCountrySelection: [this.setAllCountrySelection]
    });
  }
  get f() { return this.fuel_Markup_exchange_edit.controls; }

  checkFormControlValueChanged(): void {
    this.fuel_Markup_exchange_edit.get("clientid").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Markup_exchange_edit.get("countryid").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Markup_exchange_edit.get("icao").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Markup_exchange_edit.get("iata").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Markup_exchange_edit.get("markupAmount").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Markup_exchange_edit.get("effectivedate").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Markup_exchange_edit.get("expirydate").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    }); 
    this.fuel_Markup_exchange_edit.get("internalnotes").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    }); 
    this.fuel_Markup_exchange_edit.get("isActive").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
  }

  setAllCountrySelectionChange(event: any) {
      this.setAllCountrySelection = this.f.setAllCountrySelection.value;
    if (this.f.setAllCountrySelection.value) {
      this.f.icao.disable();
      this.f.iata.disable();

      this.f.icao.setValue(null);
      this.f.iata.setValue(null);

      this.f.icao.setValidators(null);
      this.f.iata.setValidators(null);
    }
    else {
      this.f.icao.enable();
      this.f.iata.enable();

      this.f.icao.setValidators(Validators.required);
      this.f.iata.setValidators(Validators.required);
    }
  }

    
  clickSave() {
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;
     
 
    if (this.iscountryorairport == "airport") {
      if (this.f.icao.value == "") {
        this.f.icao.setErrors({ required: true });
      }
      if (this.f.iata.value == "") {
        this.f.iata.setErrors({ required: true });
      }
    }

 
    if (this.f.markupAmount.value == "" || this.f.markupAmount.value == null) {
      this.f.markupAmount.setErrors({ required: true });
     // return;
    } 

    if (this.f.expirydate.value != null && this.f.expirydate.value != '')
      if (this.f.effectivedate.value > this.f.expirydate.value) {
        this.f.expirydate.setErrors({ matEndDateInvalid: true });
        return;
      }

    
    if (this.f.effectivedate.value == "") {
      this.f.effectivedate.setErrors({ required: true });
    }
 
    let obj = new FuelExchangeRateMarkupModel();


    obj.fuelExchangeRateMarkupID = this.fuelExchangeRateMarkupID == null ? 0 : this.f.fuelExchangeRateMarkupID.value;
    if (this.isDuplicate) {
      obj.fuelExchangeRateMarkupID = 0;
    }
 
    obj.customerID = this.f.clientid.value;

    if (this.iscountryorairport == 'country') {

      obj.countryID = this.f.countryid.value;
      obj.airportID = 0;// this.f.airportid.value;
    }
    else {

      obj.countryID = this.countryid;// 0;// this.f.countryid.value;
      obj.airportID = this.airportid;// this.f.airportid.value;
    }

    //obj.countryid = this.f.countryid.value;
    //obj.airportid = this.f.airportid.value;// this.airportid;
    if (this.f.markupAmount.value != 0) {
      obj.markupAmount = this.f.markupAmount.value;
    }
    else {
      return;
    }

   // obj.fuelClientMarkupGUID = this.isExistingPrice == true ? this.fuelsupplier : this.f.fuelsupplier.value; 
    obj.effectiveDate = this.f.effectivedate.value;
    // obj.expiryDate = this.f.expirydate.value;// == null ? 0 : this.f.unitofmeasure.value; this.unitofmeasure;// this.selectedunitofmeasure;//
    // obj.internalNotes = this.f.internalnotes.value == null ? '' : this.f.internalnotes.value; 
    obj.expiryDate = '';
 //   obj.internalNotes = '';
    obj.isActive = this.f.isActive.value == '1' ? true : false;

 
    if(obj.markupAmount < 2 || obj.markupAmount > 10){
      this.errMsg = "Markup Amount Should be in the Range of 2% to 10%";
      setTimeout(() => {
        this.errMsg = "";

      }, 3000);
    }
    else{
 
      this._fuel.saveFuelExchangeRateMarkup<ResponseModel<number>>(obj).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model > 0) {
            // this.fuelSupplierGUID = response.model;
            let d = new Date();
            let m = d.getMonth() + 1;
            let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
            this.f.modifiedBy.setValue(localStorage.getItem('un'));
            this.f.modifiedDate.setValue(dt);
            this.controlValueChanged = false;
            // let returnResponse = new ReturnObjModel();
            // returnResponse.refresh = true;
            // returnResponse.newId = this.fuelClientMarkupGUID.toString();
            this.showSuccessMsg = true;


            // this._dialogService.openAlertDialog("Your information has been submitted successfully.");
            this.isModified = true;

 
              this.showSuccessMsg = true;
              this.successMsg = "Exchange Rate Markup Updated Successfully.";
              setTimeout(() => {
                this.showSuccessMsg = false;
                this.clickClose();
              }, 1000);
 
          }
          else {
             this.errMsg = "MarkUp Exhange Rate already exists For this Loaction.";
           }
        }
        else {
          if (response.code == "401") {
             this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        this.loading = false;
      })
    }
  }

  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;

    if (this.controlValueChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before closing the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(response);
        }
      });

    }
    else {
      this._dialogRef.close(response);
    }
    //this._dialogRef.close(this.isModified);
  }

  countrychange() {
    this.setAllCountrySelection = true;
    this.f.setAllCountrySelection.setValue(true);


    this.f.icao.setValue(null);
    this.f.iata.setValue(null);

    this.f.icao.setValidators(null);
    this.f.iata.setValidators(null);
    //this.f.setAllCountrySelection.value ==true;

  }

  icaoValidation(e: any, isicaoiata: string) { // appending the updated value to the variable
    let length: number = 0;
    length = isicaoiata == 'icao' ? 4 : 3;
    if (e.target.value.length >= length) {
      let icao: string = e.target.value;
      this._fuel.getCountryDetailsByICAO<ResponseModel<AirportModel>>(icao).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let airportModel = new AirportModel();

            airportModel = response.model;
            //this.country = response.model;
         //   this.fuel_Markup_exchange_edit.get('country').setValue(airportModel.countryName);

            if (isicaoiata == 'icao')
              this.fuel_Markup_exchange_edit.get('iata').setValue(airportModel.iata);


            if (isicaoiata == 'iata')
              this.fuel_Markup_exchange_edit.get('icao').setValue(airportModel.icao);

            //  this.icao = airportModel.icao;
            //this.iata = airportModel.iata;

            this.airportid = airportModel.airportID;
            this.countryid = airportModel.countryId;
            this.countryName = airportModel.countryName;
            this.countrylength = this.countryName.length;
            this.errMsg = '';
            //   this.isValidICAO = response.model;
            // this.initControls();
          }
          else {

            this.fuelsupplier = 'default';
            this.validateICAO = false;
          }

        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else { 
            this.errMsg = "Invalid ICAO.";
          }
        }
        this.loading = false;
      })
    }
    else {
      this.validateICAO = true;
      this.isValidICAO = false;
    }
  }

  clickDuplicate() {
    this.showDuplicateText = true;
    //this.showSuccessMsg = true;
    ////this.successMsg = "Duplicate Prices";
    //setTimeout(() => {
    //  this.showSuccessMsg = false;
    //}, 1000);
    this.dialogTitle = 'Add';
    this.isDuplicate = true
    this.isExistingPrice = false;
    this.isValidICAO = true;
    this.iscountryorairport = 'country';
    this.clientid = 0;
    this.countryid = 265;
    this.markupAmount = null;
    this.internalnotes = null;
    //this.minqty = null;
    //this.maxqty = null;
    //this.baseFuel = null;
    //this.fuelsupplierChange();
    this.initControls();
    this.checkFormControlValueChanged();
    this.submitted = false;
    //this.f.minqty.setErrors({ required: false });
    //this.f.maxqty.setErrors({ required: false });
    // this.clickSave();
  }

}


