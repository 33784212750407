<div style="margin-top:0em; padding-top:0em; display:flex;" >
  <form [formGroup]="customer_halt_services_form" *ngIf="customer_halt_services_form">
    <div class="d-inline-flex">
      <div style="background-color:#f8f8f8;width:20em;padding-left:.5em">
        <div style="margin-left: 0.5em;margin-top:.5em">
          HALT Services effective immediately
        </div>
        <div style="margin-left: 1em; font-size: small;" class="small">
          <mat-checkbox color="primary" #haltAllCk (click)="$event.stopPropagation()"
                        (change)="checkAllServices()"
                        [checked]="haltAll">
            ALL SERVICES
          </mat-checkbox>

        </div>
        <div style="margin-left: 2.5em; font-size: small;" class="small">
          <mat-checkbox id="haltHandlingCk" color="primary" #haltHandlingCk (click)="$event.stopPropagation()"
                        (change)="checkHaltServices()"
                        [checked]="haltHandling">
            Handling Services
          </mat-checkbox>

        </div>
        <div style="margin-left: 2.5em; font-size: small" class="small">
          <mat-checkbox color="primary" #haltPermitsCk (click)="$event.stopPropagation()"
                        (change)="checkHaltServices()"
                        [checked]="haltPermits">
            Permits
          </mat-checkbox>
        </div>
        <div style="margin-left: 2.5em; font-size: small" class="small">
          <mat-checkbox color="primary" #haltFuelCk (click)="$event.stopPropagation()"
                        (change)="checkHaltServices()"
                        [checked]="haltFuel">
            Fuel Setup/Arrangements
          </mat-checkbox>
        </div>
        <div style="margin-left: 2.5em; font-size: small;" class="small">
          <mat-checkbox color="primary" #haltWeatherCk (click)="$event.stopPropagation()"
                        (change)="checkHaltServices()"
                        [checked]="haltWeather">
            Weather Services
          </mat-checkbox>

        </div>
        <div style="margin-left: 2.5em; font-size: small" class="small">
          <mat-checkbox color="primary" #haltHotelCk (click)="$event.stopPropagation()"
                        (change)="checkHaltServices()"
                        [checked]="haltHotel">
            Hotel and Travel
          </mat-checkbox>

        </div>
        <div style="margin-left: 2.5em; font-size: small;" class="small">
          <mat-checkbox color="primary" #haltWebsiteCk (click)="$event.stopPropagation()"
                        (change)="checkHaltServices()"
                        [checked]="haltWebsite">
            Stop Access to FP Connect
          </mat-checkbox>

        </div>
        <div style="margin-left: 2.5em; font-size: small;" class="small">
          <mat-checkbox color="primary" #haltSSNCk (click)="$event.stopPropagation()"
                        (change)="checkHaltServices()"
                        [checked]="haltSSN">
            Special Services Network (SSN)
          </mat-checkbox>

        </div>
        <div style="padding-bottom:1em">
          <button mat-button class="btn-high" (click)="clickSave()">Save</button>
        </div>
      </div>
      <div (mousedown)="$event.stopPropagation()" style="background-color:#f8f8f8;width:45.5em;margin-left:0.5em">
        <div style="display:inline-flex">
          <div class="form-group" style="font-size:small;text-decoration:underline;margin-left: 1em;margin-top:.5em;">
            Halt Services Last Update Done by and Date
          </div>
          <div class="form-group" style="margin-top:0em; margin-right:0.625em;margin-left:.5em">
            <label for="haltUpdatedBy" style="font-size:small">Updated by</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
              <input type="text" readonly formControlName="haltUpdatedBy" class="form-control" style="padding: 0em; width: 8em; height: 1.5625em; font-size: small;background-color:white" />
            </div>
          </div>
          <div class="form-group" style="margin-top:0em; margin-right:0.625em;margin-left:.5em">
            <label for="haltUpdatedDate" style="font-size:small">Date </label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
              <input type="text" readonly formControlName="haltUpdatedDate" class="form-control" style="padding: 0em; width: 8em; height: 1.5625em; font-size: small;background-color:white" />
            </div>
          </div>
        </div>
        <div style="display:flex">
          <div class="form-group" style="margin-top:0em;margin-left:1em; margin-right:0.625em">
            <label for="halt_remarks" style="font-size:small">Remarks</label>
            <div style="margin-top:-0.5em;">
              <textarea formControlName="halt_remarks" readonly class="form-control" style="width:50em; height:15em; font-size:small; padding:0em 0em 0em 0em;">
            </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    </form>
</div>
