import { Component, Input, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html'//,
  //styleUrls: ['./spin.component.css']
})
export class SuccessMessageComponent implements OnInit {
  @Input() successMsg: string;

  ngOnInit() {

  }
}
