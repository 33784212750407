<div *ngIf="upPII" style="min-height: 16em;background-color: #f8f8f8;">
  <form *ngIf="ground_stop_alternate_airport_edit_form" [formGroup]="ground_stop_alternate_airport_edit_form">
    <div>
      <div style="display: flex">
        <div>
          <div style="display: flex;padding-left:1em" cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>
            <div style="margin-top:0.3em"><i class="material-icons-outlined" style="font-size: x-large;cursor: pointer; color: gray" >edit_location_alt</i></div>
            <div style="margin-top:0.3125em;"><h5>Alternate Airports</h5></div>
          </div>
          <div>
            <div style="margin-top:-0.5em; padding-left: 1em; display:flex; padding-right:1em">
              <div>
                <div style="margin-left: 1.5em;font-size: small">
                  {{icao_iata}} {{airportName}}, {{airportLocation}}
                </div>
                <div style="background-color: white; display:flex; padding-left:0.5em; padding-right:0.5em">
                  <div style="min-height: 10em; margin-top: 0em;padding-top:0.25em; min-width:52em">
                    <div style="display:flex; height: 7em;">
                      <div style="min-width: 30em">
                        <div style="font-size: small; font-weight: bold">Departure Alternate Airport for {{icao_iata}}</div>
                        <div style="display:flex; margin-top:-0.5em;">
                          <div style="margin-right:0.25em; width:4.5em">
                            <label for="dep_1" style="font-size:small; margin-left:2.25em">1</label>
                            <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                              <input type="text" formControlName="dep_1" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.dep_1.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('dep',1, $event)" />
                            </div>
                            <div *ngIf="f.dep_1.errors">
                              <div *ngIf="f.dep_1.errors && f.dep_1.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em;margin-top:0em; font-size:small">Invalid characters for airport</div>
                              <div *ngIf="f.dep_1.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em;margin-top:0em; font-size: small; white-space: nowrap;width:6.5em">{{dep_1_wrongAirportErrMsg}}</div>
                              <div *ngIf="f.dep_1.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:18em">{{dep_1_duplicateIATAErrMsg}}</div>
                            </div>
                          </div>
                          <div>
                            <label for="dep_1_vendor_select" style="font-size:small;">FBO / Handler</label>
                            <div style="margin-top:-0.65em;">
                              <select *ngIf="f" formControlName="dep_1_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                <option value="" selected>Select Handler</option>
                                <option *ngFor="let v of dep_1_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div style="display:flex; margin-top:-0.25em;">
                          <div style="width:4.5em; margin-right:0.25em">
                            <label for="dep_2" style="font-size:small; margin-left:2.25em">2</label>
                            <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                              <input type="text" formControlName="dep_2" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.dep_2.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('dep',2, $event)" />
                            </div>
                            <div *ngIf="f.dep_2.errors">
                              <div *ngIf="f.dep_2.errors && f.dep_2.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                              <div *ngIf="f.dep_2.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:6.5em">{{dep_2_wrongAirportErrMsg}}</div>
                              <div *ngIf="f.dep_2.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:18em">{{dep_2_duplicateIATAErrMsg}}</div>
                            </div>
                          </div>
                          <div>
                            <div style="margin-top:1.25em;">
                              <select *ngIf="f" formControlName="dep_2_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                <option value="" selected>Select Handler</option>
                                <option *ngFor="let v of dep_2_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                              </select>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div *ngIf="nextGroundStopGUID!=''">
                        <div style="font-size: small; font-weight: bold">Arrival Alternate Airport for {{next_icao_iata}}</div>
                        <div style="display:flex; margin-top:-0.5em;">
                          <div style="margin-right: 0.25em; width: 4.5em">
                            <label for="arr_1" style="font-size:small; margin-left:2.25em">1</label>
                            <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                              <input type="text" formControlName="arr_1" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.arr_1.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('arr',1, $event)" />
                            </div>
                            <div *ngIf="f.arr_1.errors">
                              <div *ngIf="f.arr_1.errors && f.arr_1.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid airport</div>
                              <div *ngIf="f.arr_1.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em;margin-top:0em; font-size: small; white-space: nowrap;width:6.5em">{{arr_1_wrongAirportErrMsg}}</div>
                              <div *ngIf="f.arr_1.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:18em">{{arr_1_duplicateIATAErrMsg}}</div>
                            </div>
                          </div>
                          <div>
                            <label for="arr_1_vendor_select" style="font-size:small;">FBO / Handler</label>
                            <!--<div style="margin-top:-0.65em;" *ngIf="arr_1!='' && !f.arr_1.errors">-->
                            <div style="margin-top:-0.65em;">
                              <select *ngIf="f" formControlName="arr_1_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                <option value="" selected>Select Handler</option>
                                <option *ngFor="let v of arr_1_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div style="display:flex; margin-top:-0.25em;">
                          <div style="width: 4.5em; margin-right:0.25em">
                            <label for="arr_2" style="font-size:small; margin-left:2.25em">2</label>
                            <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                              <input type="text" formControlName="arr_2" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.arr_2.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('arr',2, $event)" />
                            </div>
                            <div *ngIf="f.arr_2.errors">
                              <div *ngIf="f.arr_2.errors && f.arr_2.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                              <div *ngIf="f.arr_2.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:6.5em">{{arr_2_wrongAirportErrMsg}}</div>
                              <div *ngIf="f.arr_2.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em;margin-top:0em; font-size:small; white-space: nowrap;width:18em">{{arr_2_duplicateIATAErrMsg}}</div>
                            </div>
                          </div>
                          <div>
                            <div style="margin-top:1.25em;">
                              <select *ngIf="f" formControlName="arr_2_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                <option value="" selected>Select Handler</option>
                                <option *ngFor="let v of arr_2_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div *ngIf="nextGroundStopGUID!=''" style="min-width:42em">
                      <div style="height:0.5em; background-color: #f8f8f8; margin-left:-0.5em"></div>
                      <div style="min-height:12em">                      
                        <div style="font-size: small; font-weight: bold">ETP {{legDisplay}} </div>
                        <div style="display:flex; margin-top:-0.5em;">
                          <div style="min-width:30em">
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em">
                                <label for="etp_1" style="font-size:small; margin-left:2.25em">1</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etp_1" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etp_1.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etp',1, $event)" />
                                </div>
                                <div *ngIf="f.etp_1.errors">
                                  <div *ngIf="f.etp_1.errors && f.etp_1.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etp_1.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:6.5em">{{etp_1_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etp_1.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:18em">{{etp_1_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <label for="etp_1_vendor_select" style="font-size:small;">FBO / Handler</label>
                                <div style="margin-top:-0.65em;" >
                                  <select *ngIf="f" formControlName="etp_1_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etp_1_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etp_2" style="font-size:small; margin-left:2.25em">2</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etp_2" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etp_2.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etp',2, $event)" />
                                </div>
                                <div *ngIf="f.etp_2.errors">
                                  <div *ngIf="f.etp_2.errors && f.etp_2.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etp_2.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:6.5em">{{etp_2_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etp_2.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:18em">{{etp_2_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etp_2_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etp_2_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etp_3" style="font-size:small; margin-left:2.25em">3</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etp_3" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etp_3.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etp',3, $event)" />
                                </div>
                                <div *ngIf="f.etp_3.errors">
                                  <div *ngIf="f.etp_3.errors && f.etp_3.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etp_3.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:6.5em">{{etp_3_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etp_3.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:18em">{{etp_3_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etp_3_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etp_3_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etp_4" style="font-size:small; margin-left:2.25em">4</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etp_4" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etp_4.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etp',4, $event)" />
                                </div>
                                <div *ngIf="f.etp_4.errors">
                                  <div *ngIf="f.etp_4.errors && f.etp_4.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etp_4.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:6.5em">{{etp_4_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etp_4.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:18em">{{etp_4_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etp_4_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etp_4_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em">
                                <label for="etp_5" style="font-size:small; margin-left:2.25em">5</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etp_5" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etp_5.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etp',5, $event)" />
                                </div>
                                <div *ngIf="f.etp_5.errors">
                                  <div *ngIf="f.etp_5.errors && f.etp_5.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etp_5.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:6.5em">{{etp_5_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etp_5.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:18em">{{etp_5_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <label for="etp_5_vendor_select" style="font-size:small;">FBO / Handler</label>
                                <div style="margin-top:-0.65em;" >
                                  <select *ngIf="f" formControlName="etp_5_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etp_5_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etp_6" style="font-size:small; margin-left:2.25em">6</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etp_6" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etp_6.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etp',6, $event)" />
                                </div>
                                <div *ngIf="f.etp_6.errors">
                                  <div *ngIf="f.etp_6.errors && f.etp_6.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etp_6.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:6.5em">{{etp_6_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etp_6.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:18em">{{etp_6_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etp_6_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etp_6_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etp_7" style="font-size:small; margin-left:2.25em">7</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etp_7" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etp_7.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etp',7, $event)" />
                                </div>
                                <div *ngIf="f.etp_7.errors">
                                  <div *ngIf="f.etp_7.errors && f.etp_7.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etp_7.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:6.5em">{{etp_7_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etp_7.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:18em">{{etp_7_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etp_7_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etp_7_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etp_8" style="font-size:small; margin-left:2.25em">8</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etp_8" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etp_8.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etp',8, $event)" />
                                </div>
                                <div *ngIf="f.etp_8.errors">
                                  <div *ngIf="f.etp_8.errors && f.etp_8.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etp_8.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:6.5em">{{etp_8_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etp_8.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:18em">{{etp_8_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etp_8_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etp_8_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div style="height:0.5em; background-color: #f8f8f8; margin-left:-0.5em"></div>
                      <div style="min-height:12em">                      
                        <div style="font-size: small; font-weight: bold">ETOP {{legDisplay}} </div>
                        <div style="display:flex; margin-top:-0.5em;">
                          <div style="min-width:30em">
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em">
                                <label for="etop_1" style="font-size:small; margin-left:2.25em">1</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etop_1" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etop_1.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etop',1, $event)" />
                                </div>
                                <div *ngIf="f.etop_1.errors">
                                  <div *ngIf="f.etop_1.errors && f.etop_1.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etop_1.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em;margin-top:0em; font-size: small; white-space: nowrap;width:6.5em">{{etop_1_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etop_1.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em;margin-top:0em; font-size: small; white-space: nowrap;width:18em">{{etop_1_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <label for="etop_1_vendor_select" style="font-size:small;">FBO / Handler</label>
                                <div style="margin-top:-0.65em;" >
                                  <select *ngIf="f" formControlName="etop_1_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etop_1_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etop_2" style="font-size:small; margin-left:2.25em">2</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etop_2" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etop_2.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etop',2, $event)" />
                                </div>
                                <div *ngIf="f.etop_2.errors">
                                  <div *ngIf="f.etop_2.errors && f.etop_2.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em;margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etop_2.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em;margin-top:0em; font-size:small; white-space: nowrap;width:6.5em">{{etop_2_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etop_2.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:18em">{{etop_2_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etop_2_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etop_2_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etop_3" style="font-size:small; margin-left:2.25em">3</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etop_3" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etop_3.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etop',3, $event)" />
                                </div>
                                <div *ngIf="f.etop_3.errors">
                                  <div *ngIf="f.etop_3.errors && f.etop_3.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etop_3.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:6.5em">{{etop_3_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etop_3.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:18em">{{etop_3_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etop_3_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etop_3_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etop_4" style="font-size:small; margin-left:2.25em">4</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etop_4" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etop_4.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etop',4, $event)" />
                                </div>
                                <div *ngIf="f.etop_4.errors">
                                  <div *ngIf="f.etop_4.errors && f.etop_4.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etop_4.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:6.5em">{{etop_4_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etop_4.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:18em">{{etop_4_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etop_4_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etop_4_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em">
                                <label for="etop_5" style="font-size:small; margin-left:2.25em">5</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etop_5" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etop_5.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etop',5, $event)" />
                                </div>
                                <div *ngIf="f.etop_5.errors">
                                  <div *ngIf="f.etop_5.errors && f.etop_5.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etop_5.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:6.5em">{{etop_5_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etop_5.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:18em">{{etop_5_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <label for="etop_5_vendor_select" style="font-size:small;">FBO / Handler</label>
                                <div style="margin-top:-0.65em;" >
                                  <select *ngIf="f" formControlName="etop_5_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etop_5_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etop_6" style="font-size:small; margin-left:2.25em">6</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etop_6" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etop_6.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etop',6, $event)" />
                                </div>
                                <div *ngIf="f.etop_6.errors">
                                  <div *ngIf="f.etop_6.errors && f.etop_6.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etop_6.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:6.5em">{{etop_6_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etop_6.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:18em">{{etop_6_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etop_6_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etop_6_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etop_7" style="font-size:small; margin-left:2.25em">7</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etop_7" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etop_7.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etop',7, $event)" />
                                </div>
                                <div *ngIf="f.etop_7.errors">
                                  <div *ngIf="f.etop_7.errors && f.etop_7.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etop_7.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:6.5em">{{etop_7_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etop_7.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size: small; white-space: nowrap;width:18em">{{etop_7_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etop_7_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etop_7_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div style="display: flex">
                              <div style="margin-right:0.25em; width:4.5em; margin-top:-0.25em">
                                <label for="etop_8" style="font-size:small; margin-left:2.25em">8</label>
                                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                                  <input type="text" formControlName="etop_8" class="form-control" maxlength="4" [ngClass]="{ 'is-invalid': f.etop_8.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="icaoChange('etop',8, $event)" />
                                </div>
                                <div *ngIf="f.etop_8.errors">
                                  <div *ngIf="f.etop_8.errors && f.etop_8.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small">Invalid characters for airport</div>
                                  <div *ngIf="f.etop_8.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:6.5em">{{etop_8_wrongAirportErrMsg}}</div>
                                  <div *ngIf="f.etop_8.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.125em; margin-top:0em; font-size:small; white-space: nowrap;width:18em">{{etop_8_duplicateIATAErrMsg}}</div>
                                </div>
                              </div>
                              <div>
                                <div style="margin-top:1em;" >
                                  <select *ngIf="f" formControlName="etop_8_vendor_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey; width:28em; background-color:#eaf0fd">
                                    <option value="" selected>Select Handler</option>
                                    <option *ngFor="let v of etop_8_vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    
                  </div>
                  
                </div>
              </div>

            </div>
          </div>
        </div>
        
      </div>
      <div style="display: flex; height:3em">
        <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;margin-right:1em">
          <button type="button" mat-button class="btn-high" *ngIf="!lockdownTrip && !showSpin && !arrivalDateTimePassed" [disabled]="loading" (click)="clickSave('')"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Save</div></div></button>&nbsp;
          <button type="button" mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
        </div>
        <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
        <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
          <app-success-message [successMsg]=""></app-success-message>
        </div>
      </div>
    </div>
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
