<div *ngIf="upPII && grantAccessToManageManifestProfiles" style="margin-top:0em; padding-top:0em; display:flex">
  <div class="divLeft">
    <div>
      <h5>Manage Contact Details</h5>
      <div>
        <form *ngIf="personlist_form" [formGroup]="personlist_form" >
          <div style="margin-left:0em;padding:0em; display: flex">
            <div *ngIf="userType=='internal'" class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="customer_select" style="font-size:small">Select Client</label>
              <div style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="customer_select" class="form-control form-control-sm dropdown" (change)="customerChange($event)">
                  <option value="" selected>All</option>
                  <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                </select>
              </div>
            </div>
            <!--add-->
            <div *ngIf="userType=='customer' && grantAccessToAccountGrouping && customerList.length>0" class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="customer_select" style="font-size:small">Select Client</label>
              <div style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="customer_select" class="form-control form-control-sm dropdown" (change)="customerChange($event)">
                  <option value="" selected>All</option>
                  <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                </select>
              </div>
            </div>
            <!--end-->
            <div class="form-group">
              <label style="font-size:small">Filter by Class</label>
              <ul class="tabul" style="margin-top: -0.5em">
                <li *ngFor="let pc of personClassList; let i=index" [ngClass]="{ 'btn-high':selectedPersonClass==pc, 'btn-low pointer':selectedPersonClass!=pc }" (click)="clickTab(pc)">{{pc.personClassDescription}}</li>
              </ul>
            </div>
            <div style="margin-left: 0.5em;margin-top:0em; ">
              <label for="text_search" style="font-size:small">Search by {{textSearchBy}}</label>
              <div style="margin-left: 0em; margin-top:-0.35em">
                <input type="text" formControlName="text_search" class="form-control" style="width:9.75em;height:1.4em; padding:0em; font-size: small" />
              </div>
            </div>
            <div style="margin-left: 0.3125em; margin-top:0em">
              <label for="filter_by" style="font-size:small">Filter by</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em">
                <select *ngIf="f" formControlName="filter_by" class="form-control form-control-sm dropdown">
                  <option value="Name" selected>Name</option>
                  <option value="Title">Title</option>
                  <option value="Comms">Comms</option>
                </select>
              </div>
            </div>
            <div class="form-group" style="margin-left: 0.3125em;margin-top:1.25em">
              <button [disabled]="loading" mat-button class="btn-high" style="width: 5em;" (click)="getSearchResults()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Search
              </button>&nbsp;
              <!--<button type="button" class="btn btn-primary" (click)="clickShowAllRecords()" style="width: 10em">Show All Records</button>&nbsp;-->
              <button type="button" mat-button class="btn-low" (click)="clickReset()" style="width: 5em">Reset</button>&nbsp;

            </div>
            <div *ngIf="userType=='internal'" class="form-group" style="margin-left: 0.3125em;margin-top:1.25em">
              <input type="checkbox" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)" /><label for="includeDisabledRecords" style="font-size:small">&nbsp;Include disabled records</label>
            </div>
          </div>
          <div *ngIf="msg!=''" class="inline-block" style="margin-top:-1em"><label style="color:forestgreen">&nbsp;{{msg}}</label></div>
          <div *ngIf="errMsg!=''" class="inline-block" style="margin-top:-1em"><label style="color: #c00000">{{errMsg}}</label></div>

          <div>
            
            <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef style="width:18%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Name</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.lastName}}, {{element.firstName}}&nbsp;{{element.middleName}}</td>
                </ng-container>
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef style="width:17%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Title</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.jobTitle}}</td>
                </ng-container>
                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef style="width:17%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Role</div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.personRoleList}}</td>
                </ng-container>
                <ng-container matColumnDef="customerName">
                  <th mat-header-cell *matHeaderCellDef style="width:18%; border-bottom:none" [hidden]="hideColumnForGroup">
                    <div style="margin-top:-0.25em;">Company Name</div>
                  </th>
                  <td mat-cell *matCellDef="let element" [hidden]="hideColumnForGroup">{{element.customerName}}</td>
                </ng-container>
                <ng-container matColumnDef="data_entry_status">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Data Entry Status</div>
                  </th>
                  <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger': element.dataEntryStatusID!=2}">{{element.dataEntryStatusDescription}}</td>
                </ng-container>

                <ng-container matColumnDef="disabled">
                  <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:10%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Disabled</div>
                  </th>
                  <td mat-cell *matCellDef="let element" [hidden]="hideColumn">{{element.isActive? '&nbsp;': 'Yes'}}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Action</div><div *ngIf="(userType=='internal' && f.customer_select.value!='') || userType!='internal'"><a mat-button class="btn-high grid" style="align-self:center" (click)="clickAdd($event, null);">Add</a></div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a *ngIf="userType=='internal'" mat-button class="btn-low grid" style="align-self:center" (click)="clickEdit($event, element);">
                      Edit
                    </a>
                    <a *ngIf="userType=='customer'" mat-button class="btn-low grid" style="align-self:center" (click)="clickEdit($event, element);">
                      View
                    </a>
                  </td>
                </ng-container>
              </tr>

              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickEdit($event, row);" style="cursor: pointer"></tr>
            </table>
          </div>
          <!--<div>
            <mat-paginator style="border: none;width:80em;" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                           [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
            </mat-paginator>
          </div>-->
        </form>
      </div>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
