import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AirportService } from '../services/airport.service';
import { DialogService } from '../services/dialog.service';
import { NoteTypeModel } from '../models/note-type.model';
import { AirportNotesModel } from '../models/airport-notes.model';
import { ApisCredentialsModel } from '../models/apis-credentials.model';
import { ApisTypeModel } from '../models/apis-type.model';


@Component({
  selector: 'app-apis-account-edit',
  templateUrl: './apis-account-edit.component.html',
  styleUrls: ['./apis-account-edit.component.css']
})
/** airport-profile-setup-add component*/
export class ApisAccountEditComponent implements OnInit {
  apis_account_edit_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() apisCredentialsID: number;  
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  apisTypeList: ApisTypeModel[];
  apisTypeID: number;
  apisUserName: string;
  apisPassword: string;
  effectiveDate: Date;  
  modifiedBy: string;
  modifiedDate: string;
  isModified: boolean;
  isActive: number;
  showSuccessMsg: boolean = false;
  apisType: string;
  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;

  constructor(private readonly _dialogRef: MatDialogRef<ApisAccountEditComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _dialogService: DialogService
  ) {
    this.apisCredentialsID = data.apisCredentialsID;
    this.dialogTitle = data.dialogTitle;

  }

  getAllData(): Observable<any[]> {

    let getApisType = this._commonService.getApisTypeList<ResponseModel<ApisTypeModel[]>>();

    let getApisResponse = this._commonService.getApisCredentialsByApisCredentialsID<ResponseModel<ApisCredentialsModel>>(this.apisCredentialsID);

    return forkJoin([getApisType, getApisResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.apisTypeList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.apisCredentialsID != null && this.apisCredentialsID != 0) {
        if (responses[1] != null) {
          if (responses[1].code == "200" && responses[1].message == "") {
            let apisCredentials = new ApisCredentialsModel();
            apisCredentials = responses[1].model;
            this.apisTypeID = apisCredentials.apisTypeID;
            this.apisUserName = apisCredentials.apisUserName;
            this.apisPassword = apisCredentials.apisPassword;
            this.effectiveDate = new Date(apisCredentials.effectiveDate);
            this.modifiedBy = apisCredentials.modifiedBy;
            this.apisType = this.apisTypeList.filter(x => x.apisTypeID = this.apisTypeID)[0].apisTypeDescription;

          }
        }

      }

      this.initControls();

      this.showSpin = false;


    });

  }

  initControls() {
    this.apis_account_edit_form = this._formBuilder.group({
      apis_type: [ this.apisTypeID, Validators.required ],
      user_name: [this.apisUserName, Validators.required],
      password: [this.apisPassword],
      effective_date: [this.effectiveDate, Validators.required],
   

    });
  }

  get f() { return this.apis_account_edit_form.controls; }



  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";
    
    if (this.apis_account_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    let obj = new ApisCredentialsModel();
    obj.apisCredentialsID= 0;
    if (this.apisCredentialsID != 0 && this.apisCredentialsID != null)
      obj.apisCredentialsID = this.apisCredentialsID;
    obj.apisUserName = this.f.user_name.value;
    obj.apisPassword = this.f.password.value;
    obj.effectiveDate = this.effectiveDateRef.nativeElement.value;
    this._commonService.updateApisCredentials<ResponseModel<number>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {          
           this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.isModified = true;
            this.clickClose();
          }, 1000);
        
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  //clickAddNew() {
  //  this._authService.updateAccessTime();
  //  this.submitted = false;
  //  this.errMsg = "";
  //  this.msg = "";
  //  this.airportNoteID = 0;
  //  this.selectedNoteTypeId = null;
  //  this.airport_note = "";
  //  this.modifiedBy = "";
  //  this.modifiedDate = "";
  //  this.isActive = 1;
  //  this.noteTypeList = [];
  //  this.getAllData().subscribe(responses => {
  //    if (responses[0] != null) {
  //      if (responses[0].code == "200" && responses[0].message == "") {
  //        this.noteTypeList = responses[0].model;

  //        //   this.selectedAirportTypeId = null;// this.airportTypeList[0].airportTypeID;
  //      }
  //      else {
  //        if (responses[0].code == "401") {
  //          this._authService.signOut();
  //        }
  //      }
  //    }
  //  });
  //  this.initControls();
  //}

  clickClose() {
    this._dialogRef.close(this.isModified);
  }


}
