import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'; 
import { ResponseModel } from '../models/response.model';
import { MessageService } from '../services/message.service';
import { AuthenticateService } from '../services/authenticate.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MessageFolderModel } from '../models/message-folder.model';
import { DocumentTypeModel } from '../models/document-type.model';
import { CommonService } from '../services/common.service';
import { TaxesAndFeesDescModel } from '../models/taxesandfeesdesc.model';
import { FuelService } from '../services/fuel.service';

@Component({
  selector: 'app-taxesandfees-description-edit-dialog',
  templateUrl: './taxesandfees-description-edit-dialog.component.html',
  styleUrls: ['./taxesandfees-description-edit-dialog.component.css'],
})

export class TaxesAndFeesDescriptionEditDialog implements OnInit {

  loading = false;
  isModified: boolean = true; 
  message_folder_edit_form: UntypedFormGroup;
  documentTypeList: DocumentTypeModel[];
  messageFolderID: number;
  taxFeeDescription: string;
  requiredDocumentTypeList: DocumentTypeModel[];
 
  documentExists: boolean; 
  showSuccessMsg: boolean = false;
  successMsg: string = "Taxes And Fees Description Edited Succesfully."; 
  editDocumentName: string;

  hasSpecialCharacters: boolean = false;
  warningMsg: string = "Can not Enter Charecters like ~!@#$%^&*()_+{}|:<>,.;'[]\\";
  showWarningMsg: boolean = true;
  errorMsg: string = "";
  showErrorMsg: boolean = false;
  dialogTitle: string = '';
  documentTypeID: number;

  country_documnet_type_edit: UntypedFormGroup;
  disableRecord: boolean = false;
  isMiscellaneous: string;// boolean = false;
  isDisableRecord: string = 'false';// boolean = false;
  isMiscellaneousValue: boolean = false;
  searchFiltervalue: number = 0;
  modifiedBy: string;
  modifiedDate: string;
  taxandfeedesclist: TaxesAndFeesDescModel[];
  requiredTaxandfeedesclist: TaxesAndFeesDescModel[];
  lk_TaxFeeDescriptionID: number;
  requiredTAFDescID: number;
  tafEdit: boolean = false;
  errMsg: string = '';

  constructor(private readonly _dialogRef: MatDialogRef<TaxesAndFeesDescriptionEditDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _commonService: CommonService, 
    private readonly _fuel: FuelService, 
    private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder,
  ) {

    this.dialogTitle = _data.dialogTitle;
    this.taxandfeedesclist = _data.taxandfeedesclist;
    this.lk_TaxFeeDescriptionID = _data.lk_TaxFeeDescriptionID; 
    this.requiredTAFDescID = _data.lk_TaxFeeDescriptionID; 

    this.isDisableRecord = _data.disableRecord?.toString();
 


    this.modifiedBy = _data.modifiedBy;
    this.modifiedDate = _data.modifiedDate;
 
 
  }

  
  ngOnInit() {
    this.requiredTaxandfeedesclist = this.taxandfeedesclist.filter(d => d.lk_TaxFeeDescriptionID === this.lk_TaxFeeDescriptionID);
    this.taxFeeDescription = this.requiredTaxandfeedesclist[0]?.taxFeeDescription;
    this.editDocumentName = this.taxFeeDescription;
    this.showErrorMsg = false;
    this.errorMsg = "";
    this.showSuccessMsg = false;

    this.initControls();
  } 
 
  initControls() {
    this.country_documnet_type_edit = this._formBuilder.group({
      disableRecord: [this.disableRecord],
      isMiscellaneous: [this.isMiscellaneous],
    });
  }

  get f() { return this.country_documnet_type_edit.controls; }

  clickTAFdescSave(){ 
    let request = new TaxesAndFeesDescModel(); 

    if(this.isDisableRecord == 'true'){
    request.isActive = false;
    }
    if(this.isDisableRecord == 'false'){
    request.isActive = true;
    }
 
      
    request.lk_TaxFeeDescriptionID = this.requiredTAFDescID;
    request.taxFeeDescription = this.editDocumentName;
    if(request.taxFeeDescription != undefined && request.taxFeeDescription != null && request.taxFeeDescription.trim() != ''){

      this._fuel.insertUpdateTaxesAndFeesDescription<ResponseModel<TaxesAndFeesDescModel>>(request).subscribe(response => {

        if (response.code = "200") {

        
          
        this.documentExists = response.model.documentExists;
        if(!this.documentExists){
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false; 
            this.clickClose();
          }, 2000);
        }   
        else{

          this.errorMsg = this.documentExists ?   "Description Already Exists" : this.errorMsg ;   
          if ( this.errorMsg.length>5){
            this.showErrorMsg = true;
            setTimeout(() => {
              this.showErrorMsg = false; 
              this.errorMsg = '';
            }, 2000);
          }
        }       

       
      }
    });
    }
 
    if ( this.errorMsg.length>5)
      this.showErrorMsg = true;
    setTimeout(() => {
      this.showErrorMsg = false; 
    }, 1000);
  }
 
  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  checkForSpecialCharacters() {
    const specialChars = "`~-=|'!@#$%^&*()_+{}[]:;<>,.?~\\/\"";// Define your list of special characters
    for (let i = 0; i < this.editDocumentName.length; i++) {
      if (specialChars.includes(this.editDocumentName[i])) {
        this.hasSpecialCharacters = true; // Special character found
        return;
      }
    }
    this.hasSpecialCharacters = false; // No special characters found
  }
 
  // clickTAFdescSave2(){
    
  //   let request = new TaxesAndFeesDescModel();

    
  //   if (this.isDisableRecord == 'true') {
  //     request.isActive = false;
  //   }
  //   if (this.isDisableRecord == 'false') {
  //     request.isActive = true;
  //   }
 
      
  //   request.lk_TaxFeeDescriptionID = this.requiredTAFDescID;
  //   request.taxFeeDescription = this.editDocumentName;
  //   if(request.taxFeeDescription != undefined && request.taxFeeDescription != null && request.taxFeeDescription.trim() != ''){

  //     this._fuel.insertUpdateTaxesAndFeesDescription<ResponseModel<TaxesAndFeesDescModel>>(request).subscribe(response => {
  //       if(response != null){
  //         if(response.code == '200' && response.model.lk_TaxFeeDescriptionID > 0){ 
            
  //           if(this.f.tafDesc.value != response.model.lk_TaxFeeDescriptionID ){
  //             this.taxandfeedesclist.push(response.model);
  //             this.f.tafDesc.setValue(response.model.lk_TaxFeeDescriptionID);
              
  //           } 
  //           else{
  //             this.taxandfeedesclist.forEach(tdl => {
  //               if(tdl.lk_TaxFeeDescriptionID == request.lk_TaxFeeDescriptionID){
  //                 tdl.taxFeeDescription = response.model.taxFeeDescription;
  //               }
  //             }) 
  //           }
  //           this.tafEdit = false; 
  //         }
  //       }
        
  //     });

  //   }
  //   else{
  //     this.errMsg = 'Taxes And Fees Description Can Not be Null';
  //     setTimeout(() => {
  //       this.errMsg = '';
  //     }, 2000);
  //   }
    
  // }
 


}
