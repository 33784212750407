<div style=" background: #f8f8f8 !important; padding: 0 1.5em 1.5em 1.5em; height: 100%; "> 
  <div style="justify-content: space-between;"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="d-inline-flex" style=" text-align: center; padding: 0.5em;width: 100%; justify-content: space-between; ">
       <h4 style="margin-left: 0.3em;">{{dialogTitle}} Country Required & Miscellaneous Documents </h4>
       <button    mat-icon-button  (click)="clickClose()" > <i class="material-icons" style="font-size: x-large;">close </i> </button>

    </div> 
  </div>
  <div style="background-color: white !important;  height: 100%; "> 
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> 
    <div style=" background-color: white;margin-left:1em;margin-right:1em; padding-bottom: 2em;" >
      

      <div class="example-menu"> 
        <!-- <div  *ngIf="dialogTitle == 'Edit'" style="margin-left: 2em; margin-bottom: .5em; display: flex;" > Document Type:&nbsp;<h6 style="margin-top: 0.14em;margin-left: 2em;">{{documentTypeDescription}}</h6></div>  -->
        <div style="  display: flex; margin-left: 2em; margin-bottom: 1em;">
         <div style="display: flex; margin-top: 1.5em;">
          <span *ngIf="dialogTitle == 'Edit'"  style="margin-right: 1.4em; width: 8em;">Document Name: </span> 
          <span *ngIf="dialogTitle == 'Add'"  style="margin-right: 1.4em; width: 8em;">Document Name: </span> 
          <input  *ngIf="dialogTitle == 'Edit'"  type="text" style="width: 26em; background-color:#eaf0fd;" [(ngModel)]="editDocumentName"  class="form-control form-control-sm" placeholder="Enter New Name" />
        </div>
          <input *ngIf="dialogTitle == 'Add'" type="text" style="width: 26em; background-color:#eaf0fd;margin-top: 1.5em;" [(ngModel)]="editDocumentName"  class="form-control form-control-sm" placeholder="Enter Document Name" />
          <div *ngIf="showErrorMsg" class="small alert alert-danger p-0" style="font-size: smaller; width: max-content; margin-left: .5em;  ">
            <div>{{errorMsg}}</div>
            
          </div>           
        </div> 
        
        <div style="display: flex; justify-content: space-between;">
          <div style="margin-left: 10em;">
        
            <div style="display: flex; margin-left: 1em; ">
              <div style="margin-right:0.25em">
                <input id="fRequiredDocs" type="radio" name="doc_type" value="requiredDocs" [checked]="searchFiltervalue==0"
                  (change)="searchFilterOnChange($event)" />
              </div>
              <label style="margin-top:-0.2em;white-space: nowrap " for="fRequiredDocs">Country Required Documents </label>
            </div>
        
            <div style="display: flex; margin-left: 1em; ">
              <div style="margin-right:0.25em">
                <input id="fMiscDocs" type="radio" name="doc_type" value="miscDocs" [checked]="searchFiltervalue==1"
                  (change)="searchFilterOnChange($event)" />
              </div>
              <label style="margin-top:-0.2em;white-space: nowrap " for="fMiscDocs">Miscellaneous Documents </label>
            </div>
          </div>
        
          <div    *ngIf="dialogTitle == 'Edit'" >
            <label style="font-size:medium; width: 7em;">Disable Record</label>
            <div style="margin-top: -0.35em;  ">
              <select [(ngModel)]="isDisableRecord" class="form-control form-control-sm dropdown"
                style="margin-left: 4em;width: 4em; ">
                <option value=false>No</option>
                <option value=true>Yes</option>
              </select>
            </div>
          </div>
        </div>





        <!-- <div style="margin-left: 1em;">

          <div class="col">
            <label style="font-size:medium; width: 8em;">Is Miscellaneous</label>
            <div style="margin-top: -0.35em; display: inline-block;">
              <select  [(ngModel)]="isMiscellaneous"   class="form-control form-control-sm dropdown" style="margin-left: 1.9em;width: 4em; ">
                <option value=false>No</option>
                <option value=true>Yes</option>
              </select>
            </div>
          </div>
          
          <div class="col">
            <label style="font-size:medium; width: 8em;">Disable Record</label>
            <div style="margin-top: -0.35em; display: inline-block;">
              <select  [(ngModel)]="isDisableRecord"   class="form-control form-control-sm dropdown" style="margin-left: 1.9em;width: 4em; ">
                <option value=false>No</option>
                <option value=true>Yes</option>
              </select>
            </div>
          </div>
        </div> -->
 
      </div>
    </div> 
  </div> 

  <div style="display: inline-flex;   margin-left: 2em; margin-top: 0.5em; width: 95%;  justify-content: space-between;">

    <div style="display: flex;">
      <button mat-button class="btn-high"  style="height: 2em;display: flex;" (click)="updateFolderName( )">
        <span class="material-symbols-outlined" style="font-size:  large;">
          add
        </span>Save</button>

      <button type="button" mat-button class="btn-low" (click)="clickClose()" style="height: 2em;margin-left: 1em;">
        <div style="display:flex"> <i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
          <div>CLOSE</div>
        </div>
      </button>&nbsp;
    </div>

    <div   *ngIf="dialogTitle == 'Edit'" >
      <span style="display: flex; font-size: small;">ModifiedBy: {{modifiedBy}}</span>
      <span style="display: flex; font-size: small;">ModifiedDate: {{modifiedDate}}</span>
    </div>

  </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]="successMsg"></app-success-message>
    </div>
</div>