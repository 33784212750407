<div class="divLeft" style=" margin-top: 0em;" cdkDrag
  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div>
    <div>
      <div
        style="border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; ">
        <div style="display: inline-flex; justify-content: space-between; width: 100%;">
          <div style="display: flex; flex-direction: column;">
            <div style="display: inline-flex;">
              <i class="material-icons-outlined"
                style="margin-top: 0.2em; margin-right: 0.4em;">admin_panel_settings</i>
              <div>
                <h3 style="display: inline-flex;">Admin Services <h3 *ngIf="userType === 'internal'">&nbsp;- FPC Operations</h3></h3>
                <h5 *ngIf="userType === 'customer'" style="color: #1976e6; font-size: 1em;">{{selectedClientName}}</h5>
              </div>
            </div>

          </div>
          <div *ngIf="userType === 'customer'" style="padding-left: 1em; padding-right: 1em;">
            <button mat-button class="btn-low" (click)="openSelection('Acct Setup')">Client Profile</button>
            <button mat-button class="btn-low" style="margin-left: 0.5em;"
              (click)="openSelection('Client Information')">Client Information</button>
            <button mat-button class="btn-low" style="margin-left: 0.5em;"
              (click)="openSelection('Aircraft')">Aircraft</button>
            <button mat-button class="btn-low" style="margin-left: 0.5em;"
              (click)="openSelection('Crew, PAX & Support')">Crew, PAX & Support</button>
            <button mat-button class="btn-low" style="margin-left: 0.5em;">Feedback</button>
          </div>
          <div style="display: flex;">
            <button mat-icon-button (click)="openClientProfileSetupComponent()"> <i class="material-icons-outlined"
                style="font-size:xx-large;  ">arrow_circle_left</i> </button>
            <button mat-icon-button mat-dialog-close><i class="material-icons" style="font-size: x-large;">close
              </i></button>
          </div>
        </div>
        <div>
        </div>

      </div>
    </div>


    <div style="display: flex; flex-direction: column; background-color: white;">
      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow"
          style="margin-right: 1em; height: 3em;  width: 17em; background-color: #f2faff !important; color: Black !important"
          (click)="openRegisterNewUsers()">
          <i class="material-icons-outlined" style="float: left;">assignment_ind</i>
          <span>Register New Users</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Add new users to FPC
        </div>
      </div>


      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow"
          style="margin-right: 1em; height: 3em;  width: 17em; background-color: #f2faff !important; color: Black !important"
          (click)="openResetPassword()">
          <i class="material-symbols-outlined" style="float: left;">reset_wrench</i>
          <span style="margin-left: -1.2em;">Reset Password</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Full access to resetting user accounts remotely
        </div>
      </div>


      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow"
          style="margin-right: 1em; height: 3em;  width: 17em; background-color: #f2faff !important; color: Black !important"
          (click)="openManageUserPermissions()">
          <i class="material-symbols-outlined" style="float: left;">manage_accounts</i>
          <span style="margin-left: -1.3em;">Manage User Permissions</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Manage user permissions and deactivate accounts
        </div>
      </div>


      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow"
          style="margin-right: 1em; height: 3em;  width: 17em; background-color: #f2faff !important; color: Black !important"
          >
          <i class="material-symbols-outlined" style="float: left;">problem</i>
          <span style="margin-left: -2.5em;">Audit Reports</span>
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          FPC user logins detailed report, includes date/time and IP address. Use this to monitor suspicious login
          activity
        </div>
      </div>


    </div>
  </div>
</div>