import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { PersonService } from '../services/person.service';
import { CustomerPersonCommEditDialogComponent } from './customer-person-comm-edit-dialog.component';
import { DialogService } from '../services/dialog.service';
@Component({
  selector: 'app-customer-person-commlist',
  templateUrl: './customer-person-commlist.component.html',
  styleUrls: ['./customer-person-commlist.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ]
})

export class CustomerPersonCommlistComponent implements OnInit, OnChanges {
  @Input() personId: string;
  //@Input() tabName: string;
  @Input() includeDisabledRecords: boolean;
  @Input() customerGUID: string;

  customer_person_commlist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  //customerList: CustomerModel[];
  //userType: string;
  showSpin: boolean = true;
  //selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  
  recordList: PersonCommsAddressModel[];
  limitTo: number = 10;

  displayedColumn: string[] = ['addressUse', 'address', 'type', 'preferred', 'remark', 'data_entry_status', 'disabled', 'action'];
  displayedColumn2: string[] = ['title'];

  @Input() filterOption: number;
  @Output() public updateParentPage = new EventEmitter();
  upPII: boolean = false;
  grantAccessToManageManifestProfiles: boolean;
  userType: string;
  hideColumn: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _personService: PersonService, @Inject(DOCUMENT) private _document, private readonly _dialogService: DialogService) {

  }

  ngOnInit() {
    

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.customer_person_commlist_form = this._formBuilder.group({

    });

    //this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    //if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
    //  this.includeDisabledRecords = false;

    this.filterOption = changes.filterOption.currentValue;
    if (this.filterOption == undefined || this.filterOption == null)
      this.filterOption = 1;

    this._authService.updateAccessTime();
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }
    if (!this.upPII || !this.grantAccessToManageManifestProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");     
      return;
    }
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType == 'customer') {
      this.hideColumn = true;
    }
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getResults();

  }

  get f() { return this.customer_person_commlist_form.controls; }

  clickEdit(event: any, comm: PersonCommsAddressModel) {
    let s = "";
    let personCommsAddressGUID = "";
    if (comm == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      personCommsAddressGUID = comm.personCommsAddressGUID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "58em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    
    dialogConfig.data = { dialogTitle: s, personId: this.personId,customerGUID: this.customerGUID,  personCommsAddressGUID: personCommsAddressGUID };
    
    const dialogRef = this._dialog.open(CustomerPersonCommEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getResults();

      }
    });

  }

  public getResults() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new PersonCommsAddressModel();
    request.personGUID = this.personId;
    //request.pageIndex = this.currentPageIndex;
    //request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    if (this.filterOption != 3)
      request.isActive = true;
    else
      request.isActive = false;
    if (this.filterOption == 2)
      request.filterDataEntryStatus = true;
    else
      request.filterDataEntryStatus = false;
   
    this.showSpin = false;
    this._personService.getCustomerPersonCommsAddress<ResponseModel<PersonCommsAddressModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            //this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

          }

        }
        else {
          this.recordList = [];
          this.msg = "No records found.";
          
        }
      }
      else {
        this.recordList = [];
      }
      this.updateParentPage.emit({ commCount: this.totalRecords });     
      this.loading = false;
      this.showSpin = false;

    })

  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    this.getResults();

  }

  
}
