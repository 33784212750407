import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CustomerModel } from '../models/customer.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { MessageTemplateModel } from '../models/message-template.model';
import { MessageTextModel } from '../models/message-text-model';
import { ResponseModel } from '../models/response.model';
import { TripModel } from '../models/trip.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopService } from '../services/ground-stop.service';

@Component({
  selector: 'app-generate-message-template',
  templateUrl: './generate-message-template.component.html',
  styleUrls: ['./generate-message-template.component.css']
})
/** generate-message-template component*/
export class GenerateMessageTemplateComponent implements OnInit {
  messageTemplateList: MessageTemplateModel[];
  filteredList: MessageTemplateModel[];
  taskList: GroundStopTaskModel[];
  customerList: CustomerModel[];
  tripCodeList: TripModel[];
  groundStopList: GroundStopModel[];
  listType: string="filter";
  generate_message_template_form: UntypedFormGroup;
  @Input() gstId: string;
  @Input() gsId: string;
  @Input() tripCodeId: string;
  @Input() serviceTypeId: number;
  @Input() pf: string;
  @Input() farTypeID: number;
  @Input() customerGUID: string;

  hasTemplate: boolean = false;
  errMsg: string;
  submitted: boolean = false;
  templateMargin: string = "-.5em";
  @ViewChild("tripCode") tripCode: ElementRef;
  @ViewChild("businessStop") businessStop: ElementRef;
  @ViewChild("serviceType") serviceType: ElementRef;
  @ViewChild("messageTemplate") messageTemplate: ElementRef;
  icao: string;
  templateTextClipboard: string = "";

  selectedGroundStopList: GroundStopModel[] = [];
  selectedGroundStopList2: GroundStopModel[] = [];
  checkFlightBriefStops: UntypedFormControl;
  flightBriefGroundStopGUIDs: string[] = [];
  isMultipleHandling: boolean = false;
  showMultipleAirportsOption: boolean = false;
  groundStopGuidList: string = '';
  isOptionSelected: boolean = true;
  groundStopGuidList2: string = '';
  selectedAll: boolean = false;
  requiredGroundStop: GroundStopModel[];
  requiredGsId: string;
  selectedTemplateID: number = -1;
  taskType: boolean = false;
  stopType: boolean = false;
  hidePastStop: boolean = true;
  fromEmail: boolean = false;
  airportList: string[] = [];
  airportString: string = '';
  loading: boolean = false;
  isAmexCard: boolean = false;


  /** generate-message-template ctor */
  constructor(private readonly _authService: AuthenticateService, private readonly _groundStopService: GroundStopService, private readonly _groundStopAdvanceService: GroundStopAdvancedService, private readonly _customerService: CustomerService,
    private readonly _commonService: CommonService, private readonly _formBuilder: UntypedFormBuilder, private readonly _dialogRef: MatDialogRef<GenerateMessageTemplateComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,) {
    this.gstId = _data.gstId;
    this.gsId = _data.gsId;
    this.tripCodeId = _data.tripCodeId;
    this.customerGUID = _data.customerGUID;
    this.serviceTypeId = _data.serviceTypeId;
    this.pf = _data.pf;
    this.farTypeID = _data.farTypeID;
    this.fromEmail = _data.fromEmail
    this.isAmexCard = _data.isAmexCard

    this.requiredGsId = "";
    if (_data.requiredGsId != null && _data.requiredGsId != undefined && _data.requiredGsId != "")
      this.requiredGsId = _data.requiredGsId;

    if (this.gstId != "")
      this.templateMargin = ".5em";
    this.icao = _data.icao;


  }
  ngOnInit() {
    this._authService.updateAccessTime();
    this.getClientList();   
    this.initControls();
    if (this.gsId !=""){
      this.getData();
      this.getGroundStopListForMultipleAirports();
      // this.getGroundStopListForMultipleAirports2();
    }
    
  }

  getData() {

    let request = new GroundStopTaskModel();
    if (this.gsId == "") {
     // let groundStop = this.f.business_stop.value;
      request.groundStopGUID = this.f.business_stop.value;
      this.icao = this.groundStopList.find(x => x.groundStopGUID == request.groundStopGUID).icao;
    }
    else {
      request.groundStopGUID = this.gsId;
    }
    request.serviceClassID = 0;
    request.pageIndex = 0;
    request.pageSize = 100;
    this._groundStopAdvanceService.getGroundStopTaskBygsId<ResponseModel<GroundStopTaskModel[]>>(request).subscribe(response => {    
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.taskList = response.model;
         
        }
      }
    });
    this._groundStopService.getMessageTemplateList<ResponseModel<MessageTemplateModel[]>>(0).subscribe(response => {

      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.messageTemplateList = response.model;
          if (this.gsId != "")
            this.filterTemplateList();
          if (this.serviceTypeId == 1) {
            if (this.icao.substring(0,2)=="EG")    {
              this.f.message_template.setValue(15);                
              this.selectedTemplateID = 15;
            }        
            else if (this.icao.substring(0, 2) == "EI") {
              if (this.icao == "EIKN" || this.icao == "EIKY" || this.icao == "EIWT"){
                this.f.message_template.setValue(24);               
                this.selectedTemplateID = 24;
              }
              else{
                this.f.message_template.setValue(1);               
                this.selectedTemplateID = 1;
              }
            }
            else if(this.isAmexCard){
              this.f.message_template.setValue(24);               
              this.selectedTemplateID = 24;
            }
            else{
              this.f.message_template.setValue(1);               
              this.selectedTemplateID = 1;
            }
   
          }
          
          // landing
          if (this.serviceTypeId == 5){ 
            this.f.message_template.setValue(12);               
            this.selectedTemplateID = 12;
          }

          // Overflight
          if (this.serviceTypeId == 3){ 
            this.f.message_template.setValue(4);               
            this.selectedTemplateID = 4;
          }

          // // Crew Hotel
          // if (this.serviceTypeId == 20){ 
          //   this.f.message_template.setValue(20);               
          //   this.selectedTemplateID = 20;
          // }

          //  OB Gendec  
          if (this.serviceTypeId == 54){ 
            let requiredTemplate = null
            requiredTemplate  = this.messageTemplateList.filter(x => x.messageTemplateName === 'Outbound Gen Dec')
            if(requiredTemplate != null){

              this.f.message_template.setValue(requiredTemplate[0]?.messageTemplateID);               
              this.selectedTemplateID = requiredTemplate[0]?.messageTemplateID;
            }
          }

          //  Hotel Quote Request  
          if (this.serviceTypeId == 10){ 
            let requiredTemplate = null
            requiredTemplate  = this.messageTemplateList.filter(x => x.messageTemplateName === 'Hotel Quote Request')
            if(requiredTemplate != null){

              this.f.message_template.setValue(requiredTemplate[0]?.messageTemplateID);               
              this.selectedTemplateID = requiredTemplate[0]?.messageTemplateID;
            }
          }

          // // Cocesna
          // if (this.serviceTypeId == 71){ 
          //   this.f.message_template.setValue(8);               
          //   this.selectedTemplateID = 8;
          // }

          // CBP ARR
          if (this.serviceTypeId == 55){ 
            this.f.message_template.setValue(28);               
            this.selectedTemplateID = 28;
          }
          


          if(this.filteredList.length == 1){
            this.selectedTemplateID = this.filteredList[0].messageTemplateID;
          }
          if(this.selectedTemplateID != -1){ 
            this.selectTemplate(this.selectedTemplateID);
          }
        }
      }
    });
    if (this.gsId == "")
      this.serviceType.nativeElement.focus();
    else
      this.messageTemplate?.nativeElement.focus();
  }

  getClientList() {    
    this._commonService.getCustomerListByType<ResponseModel<CustomerModel[]>>(1).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.customerList = response.model;
        }
      }
    });
  }

  getTripCodeList() {
    this._groundStopService.getTripCodeListBycId<ResponseModel<TripModel[]>>(this.f.client_select.value).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.tripCodeList = response.model;
        }
      }
    });
    this.tripCode.nativeElement.focus();
  }

  getGroundStopList() {
    this.groundStopList = [];
    let request = new GroundStopModel();
    request.tripCodeGUID = this.f.trip_code.value;
    request.customerGUID = this.f.client_select.value;
    this._groundStopService.getGroundStopListBytId<ResponseModel<GroundStopModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.groundStopList = response.model;          
        }
      }
    });
    this.businessStop.nativeElement.focus();
  }

  initControls() {
    this.generate_message_template_form = this._formBuilder.group({
      message_template: ['', Validators.required],
      task_list: ['', Validators.required],
      message_text: '',
      subject_line: '',
      client_select: '',
      trip_code: '',
      business_stop:'',
      airportList:'',
      taskType: [this.taskType],
      stopType: [this.hidePastStop],

    });

    
    this.checkFlightBriefStops = new UntypedFormControl(this.selectedGroundStopList); // for mat-select
  }

  get f() { return this.generate_message_template_form.controls; }
    
  filterTemplateList() {
    let serviceType = "";
    if (this.serviceTypeId == undefined)
      serviceType = this.f.task_list.value.serviceTypeID+',';
    else
      serviceType = ',' + this.serviceTypeId.toString() + ',';
   
    switch (this.serviceTypeId) {
      case 55: {
        if (this.farTypeID == 1) {
          this.filteredList = this.messageTemplateList.filter(item => (',').concat(item.serviceTypeList.concat(',')).indexOf(serviceType) !== -1 && item.messageTemplateID != 7);
        }
        else {
          if (this.farTypeID == 2) {
            this.filteredList = this.messageTemplateList.filter(item => (',').concat(item.serviceTypeList.concat(',')).indexOf(serviceType) !== -1 && item.messageTemplateID != 28);
          }
          else {
            this.filteredList = this.messageTemplateList.filter(item => (',').concat(item.serviceTypeList.concat(',')).indexOf(serviceType) !== -1);
          }
        }
        break;
      }
      default: {
        this.filteredList = this.messageTemplateList.filter(item => (',').concat(item.serviceTypeList.concat(',')).indexOf(serviceType) !== -1);
        break;
      }
    }
      
    
  }

  generateTemplate() {
    this.submitted = true;
    let request = new MessageTextModel();
    if (this.gsId == "")
      request.groundStopGuid = this.f.business_stop.value;
    else
      request.groundStopGuid = this.gsId;
    if (this.gstId == "")
      request.groundStopTaskId = this.f.task_list.value.groundStopTaskGUID;
    else
      request.groundStopTaskId = this.gstId;
    request.messageTemplateID = this.selectedTemplateID;// this.f.message_template.value;
    if (this.tripCodeId == "")
      request.tripCodeGuid = this.f.trip_code.value;
    else
      request.tripCodeGuid = this.tripCodeId;

    request.groundStopGuidList = this.groundStopGuidList ;//'ac6c2e1c-b682-4d03-9fb2-b1a9d194b59c,16CF8C96-8B83-41B6-90EC-E9EACAA2EBB8,CD3BEB66-0AF8-47C9-9677-82C507E1E29C';
    this.loading = true;

    this._groundStopService.generateMessageTemplate<ResponseModel<MessageTextModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let message = new MessageTextModel();
          message = response.model;
          this.loading = false;
          if (this.pf == 'message') {
            this._dialogRef.close(message);
          }
          else {
            this.f.message_text.setValue(message.messageTemplateText);
            this.f.subject_line.setValue(message.subjectLine);
            this.hasTemplate = true;
          }
        }
      }
      else {
        this.errMsg="Template not found."
        this.loading = false;
      }
    });
  }

  clickCopyTemplate() {
    let sOld: string = this.f.message_text.value;
    let sNew: string = "";
    
    if (this.f.message_text.value != "") {
      let s: string = this.f.message_text.value;
      /*let s1: string[];*/
      var s1 = s.split('\n');
      for (var i = 0; i < s1.length; i++) {
        if (s1[i].trim() == "") {
          s1[i] = " ";
        }
        sNew += s1[i]+"\n";
      }

    }
    else {
      sNew = sOld;
    }
    return sNew;
    
  }

  isAllSelected: boolean = false;

  

  getGroundStopListForMultipleAirports2() {
    let getGroundStopList;
    let request = new GroundStopModel;
    // request.groundStopGUID = this.groundStopGUID;
    // request.tripCodeGUID = this.tripCodeGUID;
    // request.customerGUID = this.customerGUID;
    request.tripCodeGUID = this.tripCodeId; 
    request.customerGUID = this.customerGUID;
    request.groundStopGUID = this.gsId;

    getGroundStopList =this._groundStopService.getGroundStopPairListBytId<ResponseModel<GroundStopModel[]>>(request).subscribe(response => {
      if (response != null) {                                    

        if (response.code == "200" && response.message == "") {
          this.groundStopList = response.model;
          // this.groundStopList = this.groundStopList.filter(v => v.nextGroundStopGUID != '');
          this.groundStopList = this.groundStopList.filter(v => v.groundStopTypeDescription != 'Contingency');
          if (this.flightBriefGroundStopGUIDs != null && this.flightBriefGroundStopGUIDs.length > 0) {
            this.flightBriefGroundStopGUIDs.forEach(v => {
              this.groundStopList.forEach(x => {
                if (x.groundStopGUID.toLowerCase() == v.toLowerCase()) {
                  x.selected = true;
                }

              });

            });
            this.setGroundStopList();
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }

  getGroundStopListForMultipleAirports() {
    let getGroundStopList;
    let request = new GroundStopModel;
    // request.groundStopGUID = this.groundStopGUID;
    // request.tripCodeGUID = this.tripCodeGUID;
    // request.customerGUID = this.customerGUID;
    request.tripCodeGUID = this.tripCodeId; 
    request.customerGUID = this.customerGUID;
    request.groundStopGUID = this.gsId;
    request.hidePastStop = this.hidePastStop;
    getGroundStopList =this._groundStopService.getGroundStopList<ResponseModel<GroundStopModel[]>>(request).subscribe(response => {
      if (response != null) {                                    

        if (response.code == "200" && response.message == "") {
          this.groundStopList = response.model;
          // this.groundStopList = this.groundStopList.filter(v => v.nextGroundStopGUID != '');
          // this.groundStopList = this.groundStopList.filter(v => v.groundStopTypeDescription != 'Contingency');

          this.groundStopList.forEach(x => {
              x.selected = false;
          });
          this.setDefaultGroundStop(); 
        
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }


  templateOnChange(e: any, t: any){

    let selectedValue = this.f.message_template.value;

    let filteredRecord = this.messageTemplateList.filter(mtl => mtl.isMultipleHandling == true);
    for (const record of filteredRecord) {
      if (selectedValue == record.messageTemplateID) {
        this.showMultipleAirportsOption = true;
        // this.isOptionSelected = false;
        break;  
      } else {
        this.showMultipleAirportsOption = false;
        // this.isOptionSelected = true;
      }
    }

    setTimeout(() => {
      
      this.setDefaultGroundStop(); 
    }, 500);
    
  }


  toggleSelectAll() {
    // this.isAllSelected = !this.isAllSelected;
    this.groundStopList.forEach(g => g.selected = this.isAllSelected);
    this.airportList = this.groundStopList.filter(x => x.selected == true).map(x => x.icao);
    this.airportString = '';
    this.airportString = this.airportList.join(', ');


    this.setGroundStopList();
  }

  // onGroundStopListChange1(event: any, item: GroundStopModel) {
  //   item.selected = event.target.checked;
  //   this.setGroundStopList();
  // }

  // setGroundStopList1() {
  //   this.selectedGroundStopList = this.groundStopList.filter(u => u.selected);
  //   this.groundStopGuidList = this.selectedGroundStopList.map(x => x.groundStopGUID).join(',');
  // }
 
  onGroundStopListChange(e: any, item: GroundStopModel, str: string) {
    this.groundStopGuidList2 = this.groundStopList.map(x => x.groundStopGUID).join(',');

    if (e.source.value == 'All') {
      this.selectedAll = true;
      if (e.checked) {
        this.groundStopList.forEach(x => { x.selected = true; });
        // this.groundStopList.forEach(x => { x.selected = true; });
        this.setGroundStopList();
        this.selectedGroundStopList2=this.selectedGroundStopList;
      }
      else {
        // this.groundStopGuidList = '';
        this.groundStopList.forEach(x => { x.selected = false; });
        this.setGroundStopList();
      }
    
    } 
    else {
      this.selectedAll = false;
      this.groundStopList.forEach(x => {
        if (x.groundStopGUID.toLowerCase() == e.source.value.groundStopGUID.toLowerCase()) {
          x.selected = e.source.selected;
        }
      });
      this.setGroundStopList();

    }
    
    if(this.groundStopGuidList != ''){
      this.isOptionSelected = true;
    }
    else{
      this.isOptionSelected = false;

    }
  }
  
  setGroundStopList() {
    this.selectedGroundStopList = this.groundStopList.filter(u => u.selected == true);
    this.groundStopGuidList = this.selectedGroundStopList.map(x => x.groundStopGUID).join(',');
  
    this.checkFlightBriefStops = new UntypedFormControl(this.selectedGroundStopList); 
 }


  setDefaultGroundStop() {
    if (this.requiredGsId != undefined && this.requiredGsId != '') {
      let idx = this.groundStopList.findIndex(gs => gs.groundStopGUID.toLowerCase() == this.requiredGsId.toLowerCase());
      this.groundStopList[idx].selected = true;
      this.requiredGroundStop = this.groundStopList.filter(gs => gs.groundStopGUID.toLowerCase() == this.requiredGsId.toLowerCase());
      this.requiredGroundStop[0].selected = true;
      
      this.selectedGroundStopList = this.requiredGroundStop;
      this.setGroundStopList();
      this.airportList = this.groundStopList.filter(x => x.selected == true).map(x => x.icao);
      this.airportString = '';
      this.airportString = this.airportList.join(', ');

    }
  }

  // MessageTemplateModel[]
  selectTemplate(t: any){
    
    let selectedValue = t;//.messageTemplateID;
    this.selectedTemplateID = t;//.messageTemplateID;

    let filteredRecord = this.messageTemplateList.filter(mtl => mtl.isMultipleHandling == true);
    for (const record of filteredRecord) {
      if (selectedValue == record.messageTemplateID) {
        this.showMultipleAirportsOption = true;
        break;  
      } else {
        this.showMultipleAirportsOption = false;
      }
    }

    setTimeout(() => {
      
      this.setDefaultGroundStop(); 
    }, 500);


  }


  
  selectAirport(e: any, item: GroundStopModel, str: string) {
    this.groundStopGuidList2 = this.groundStopList.map(x => x.groundStopGUID).join(',');

    if (e.source.value == 'All') {
      this.selectedAll = true;
      if (e.checked) {
        this.groundStopList.forEach(x => { x.selected = true; });
        // this.groundStopList.forEach(x => { x.selected = true; });
        this.setGroundStopList();
        this.selectedGroundStopList2=this.selectedGroundStopList;
      }
      else {
        // this.groundStopGuidList = '';
        this.groundStopList.forEach(x => { x.selected = false; });
        this.setGroundStopList();
      }

      this.airportList = this.groundStopList.filter(x => x.selected == true).map(x => x.icao);
      this.airportString = '';
      this.airportString = this.airportList.join(', ');
    
    } 
    else {
      this.selectedAll = false;
      this.groundStopList.forEach(x => {
        if (x.groundStopGUID.toLowerCase() == item.groundStopGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
      this.setGroundStopList();

    }
    
    if(this.groundStopGuidList != ''){
      this.isOptionSelected = true;
    }
    else{
      this.isOptionSelected = false;

    }
    this.airportList = this.groundStopList.filter(x => x.selected == true).map(x => x.icao);
    this.airportString = '';
    this.airportString = this.airportList.join(', ');

  }
  
  onTaskTypeChange(e: any){
    if(e.checked)
      this.listType = 'all';
    else  
      this.listType = 'filter';

      this.selectedTemplateID = -1
      this.showMultipleAirportsOption = false;

      
      if(this.filteredList.length == 1 && e.checked == false){
        this.selectedTemplateID = this.filteredList[0].messageTemplateID;
      }
      if(this.selectedTemplateID != -1 && e.checked == false){ 
        this.selectTemplate(this.selectedTemplateID);
      }

  }

  onHidePastStopChange(e: any){
    if(e.checked){
      this.hidePastStop = true;
      this.getGroundStopListForMultipleAirports();
    }
    else{
      this.hidePastStop = false;
      this.getGroundStopListForMultipleAirports();
    }  

    this.selectedAll = false;
    this.isAllSelected = false;
    this.toggleSelectAll();

  }

  isActiveTemplate(t: any): boolean {
    // Implement your logic to determine if the airport is active
    // For example, compare the selectedAirport with some property in your component
    return t.messageTemplateID === this.selectedTemplateID;
  }

  addNewTemplate(){
    
  }

}
