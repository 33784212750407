import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, Optional, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'; 
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators'; 
import { AirportModel } from '../models/airport.model';
import { CountryModel } from '../models/country.model'; 
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { FuelService } from '../services/fuel.service';
import { Title } from '@angular/platform-browser'; 
import { ReturnObjModel } from '../models/return-obj.model'; 
import { FuelClientMarkupModel } from '../models/fuel-client-markup.model'; 
import { CustomerModel } from '../models/customer.model';
import { FuelMenuComponent } from '../fuelmenu/fuel-menu.component';
import { MissingTaxesAirportModel } from '../models/mssing-taxes-airport.model';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { TaxesAndFeesComponent } from './taxesandfees.component';

@Component({
  selector: 'app-missing-taxes-airport',
  templateUrl: './missing-taxes-airport.component.html',
  styleUrls: ['./missing-taxes-airport.component.css']
})

export class MissingTaxesAirportComponent implements OnInit { 

  missing_taxes_airport_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg1: string;
  msg: string;
  showSpin: boolean = false;

 
  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  selectedTabName: string;
  reloadChildPage: boolean;
  totalRecordsText: string;
  totalRecords: number;
  pageSize: number = 15;
  totalPageNumber: number = 0;
  v: number = this._authService.getCurrentTimeNumber();
  startDate: Date;
  endDate: Date;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  pageFrom: string = "";
  sort_by: string;
  grantAccessToFuel: boolean = false;



  showClientList: boolean = false;  
  selectedClientList: CustomerModel[];
  selectedClientCountText: string = "";
  filteredClientList: Observable<CustomerModel[]>;  
  clientList: CustomerModel[];
  //selectedClientList: FuelClientMarkupModel[]; 
 
   
  includeDisabled: boolean = false;

  includeDisabledRecords: boolean; 
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;


  displayedColumn: string[] =/*'action1',*/[   'Country', 'ICAO', 'IATA', 'FuelQuoteCount', 'FuelReleaseCount', 'RegistrationCount', 'Action' ];
  displayedColumn2: string[] =/*'action1',*/[   'Country', 'ICAO', 'IATA','CustomerName','TripCode','Registration','ArrivalDate', 'FuelQuoteCount', 'FuelReleaseCount', 'Action' ];

  websiteEnvironment: string = "";
  currentPageIndex: number = 0;


  iscountryorairport: string = "country";
  sortedData: any[] = [];
  missingTaxesAirportList: MissingTaxesAirportModel[];
  fromMissingTaxesAirport: boolean =false;

  sortedData2: any[] = [];
  missingTaxesAirportAllDetailsList: MissingTaxesAirportModel[];
  fromMissingTaxesAirportAllDetails: boolean =false;
  showSpin2: boolean = false;
  isDialog: boolean = false;


  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _fuelService: FuelService,
    private readonly _dialogService: DialogService, private _titleService: Title,
    @Optional() private readonly _dialogRef: MatDialogRef<MissingTaxesAirportComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    //private readonly _dialogRef: MatDialogRef<FuelpricingComponent>,
  ) {

    if (this.data) {
      this.isDialog = true;
    }
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToFuel = false;
    if (localStorage.getItem('up.fuel') == 'true') {
      this.grantAccessToFuel = true;
    }


    if (!this.grantAccessToFuel) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.errMsg = "";
    this.errMsg1 = "";
    this.msg = "";
    this.totalRecordsText = ""; 
 

    this.clientList = [];
    this.selectedClientList = [];

    this.sortedData = [];
    this.sortedData2 = [];
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate());

    this.initControls();

    this.clickSubmit();

  }

  initControls() {
    this.missing_taxes_airport_form = this._formBuilder.group({ 



      includeCountryAll: [true],
      showAllDetails: [false],
      // includeDisabledRecords: [this.includeDisabledRecords]
    });
  }

  get f() { return this.missing_taxes_airport_form.controls; }

  

  sortByChange(e: any) {
    this.clickSubmit();
  }

  
 
    
 
 
  clickSubmit() {
    this.submitted = true;
    this.errMsg = "";
  

   
    if (this.f.showAllDetails.value)
      this.getData2();
    else
      this.getData();
  }

  getData(isInit: boolean = false) {
    this.showSpin = true;
    let request = new MissingTaxesAirportModel();// new FuelSupplierModel();
    // request.effectiveDate = "";

    

    // request.isActive = true;
    // if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
    //   request.isActive = !this.f.includeDisabledRecords.value;
    // } 
    if (this.paginator == undefined) {
      request.pageIndex = 0;
      request.pageSize = 25;
    }
    else {
      request.pageIndex = this.paginator.pageIndex;
      request.pageSize = this.paginator.pageSize;
    }
    
    // request.includeCountr yAll = this.f.includeCoun tryAll.value != true ? this.f.includeCoun tryAll.value :false;
    request.includeCountryAll = !this.f.includeCountryAll.value;
    // request.showAllDetails = !this.f.showAllDetails.value;
    this.showSpin2 = true;

    this._fuelService.getmissingTaxesForAirports<ResponseModel<MissingTaxesAirportModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.missingTaxesAirportList = response.model;
          this.sortedData = this.missingTaxesAirportList.slice();
          this.showSpin2 = false;
          if (response.model.length > 0) {
            if (this.missingTaxesAirportList != null) {
              this.totalRecords = this.missingTaxesAirportList[0].totalRecords;
              this.totalRecordsText = this.totalRecords + " total records";

            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
    });
   

  }
 
 

  pageIndexChange(e: any) { 
    this.clickSubmit(); 
  }

  

  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;


    //  this._dialogRef.close();

    //this._dialogRef.close(this.isModified);
  }
  clickRoute(routePage: string) {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px"};
    if(this.isDialog){
      // this.clickclose();
      this._dialogRef.close();
    }
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(FuelMenuComponent, config);   
  }
 
  exportToExcel(){
    let request = new MissingTaxesAirportModel();// new FuelSupplierModel();
    request.pageSize = this.totalRecords;
    request.pageIndex = 0;

    this._fuelService.getmissingTaxesForAirports<ResponseModel<MissingTaxesAirportModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          let tempMissingList = response.model;
          // this.sortedData = tempMissingList.slice();
          if (response.model.length > 0) {
            if (tempMissingList != null) {
              this.totalRecords = tempMissingList[0].totalRecords;
              this.totalRecordsText = this.totalRecords + " total records"
              let wb=new Workbook();
              let ws =wb.addWorksheet("MissingTaxes")
              
              let header=["Country Name","ICAO", "IATA", "Fuel Quote Count", "Fuel Release Count", "Registration Count"]
              ws.addRow(header);
              tempMissingList.forEach( al => {
                
                ws.addRow([al.countryName, al.icao , al.iata, al.fuelQuoteCount, al.fuelReleaseCount, al.registrationCount]);
              } )
          
              wb.xlsx.writeBuffer().then((data)=>{
                let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob,"MissingTaxes.xlsx");
              });
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
    });



  }

  exportToExcel2(){
    let request = new MissingTaxesAirportModel();// new FuelSupplierModel();
    request.pageSize = this.totalRecords;
    request.pageIndex = 0;

    this._fuelService.getMissingTaxesAirportsAlldetails<ResponseModel<MissingTaxesAirportModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          let tempMissingList = response.model;
          // this.sortedData = tempMissingList.slice();
          if (response.model.length > 0) {
            if (tempMissingList != null) {
              this.totalRecords = tempMissingList[0].totalRecords;
              this.totalRecordsText = this.totalRecords + " total records"
              let wb=new Workbook();
              let ws =wb.addWorksheet("MissingTaxes")
              
              let header=["Country Name","ICAO", "IATA","Customer Name", "Trip Code","Registration","Arrival Date UTC", "Fuel Quote Count", "Fuel Release Count", "Registration Count"]
              ws.addRow(header);
              tempMissingList.forEach( al => {
                
                ws.addRow([al.countryName, al.icao , al.iata, al.customerName, al.tripCode, al.registration, al.arrivalDateUTC, al.fuelQuoteCount, al.fuelReleaseCount, al.registrationCount]);
              } )
          
              wb.xlsx.writeBuffer().then((data)=>{
                let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob,"MissingTaxesAllDetails.xlsx");
              });
            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
    });



  }

  excelReport(){
    if (this.f.showAllDetails.value)
      this.exportToExcel2();
    else
      this.exportToExcel();
  }

  clickAdd(event: any, element){
    let s = "";
    //  const dialogConfig = new MatDialogConfig();
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.width = "76em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    this.fromMissingTaxesAirport = true;
    dialogConfig.hasBackdrop = false;



    dialogConfig.data = {
      dialogTitle: s,   fromMissingTaxesAirport: this.fromMissingTaxesAirport, selectedIcao: element.icao, iscountryorairport: 'airport' , selectedIATA: element.iata,  
    };

    const dialogRef = this._dialog.open(TaxesAndFeesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this._dialogRef.close(result);
        this.ngOnInit();
    
      }

    });
  }

  includeCountryAllOnChange() {
    if (this.f.showAllDetails.value){
      // this.paginator.pageIndex = 0; 
      this.getData2();
    }
    else{
      // this.paginator.pageIndex = 0; 
      this.getData();
    }
    
  }

  showAllDetailsOnChange() {
    if (this.f.showAllDetails.value){
      this.paginator.pageIndex = 0; 
      this.getData2();
    }
    else{
      this.paginator.pageIndex = 0; 
      this.getData();
    }
  }

  getData2(isInit: boolean = false) {
    this.showSpin = true;
    let request = new MissingTaxesAirportModel();// new FuelSupplierModel();
    // request.effectiveDate = "";

    

    // request.isActive = true;
    // if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
    //   request.isActive = !this.f.includeDisabledRecords.value;
    // } 
    if (this.paginator == undefined) {
      request.pageIndex = 0;
      request.pageSize = 25;
    }
    else {
      request.pageIndex = this.paginator.pageIndex;
      request.pageSize = this.paginator.pageSize;
    }
    
    // request.includeCountry All = this.f.includeCountry All.value != true ? this.f.includeCountry All.value :false;
    request.includeCountryAll = !this.f.includeCountryAll.value;
    // request.showAllDetails = !this.f.showAllDetails.value;
    this.showSpin2 = true;

    this._fuelService.getMissingTaxesAirportsAlldetails<ResponseModel<MissingTaxesAirportModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.missingTaxesAirportAllDetailsList = response.model;
          this.sortedData2 = this.missingTaxesAirportAllDetailsList.slice();
          this.showSpin2 = false;
          if (response.model.length > 0) {
            if (this.missingTaxesAirportAllDetailsList != null) {
              this.totalRecords = this.missingTaxesAirportAllDetailsList[0].totalRecords;
              this.totalRecordsText = this.totalRecords + " total records"

            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
    });
   

  }

  clickOpenTrip(tripCodeGUID, custGUID, aircraftGUID) {
    //this._router.navigate(['/ground-stops/trip-details', { pId: p.personGUID }]);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "tripId": tripCodeGUID,
        "customerId": custGUID,
        "aircraftId": aircraftGUID,
        "pf": "",
        v: this._authService.getCurrentTimeNumber()
      }
    };
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    //this._router.navigate(['/ground-stops/trip-details-internal'], navigationExtras);
    window.open('/ground-stops/trip-details-internal?customerId=' + custGUID + "&tripId=" + tripCodeGUID + "&aircraftId=" + aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
  }


  clickOpenInNew( ){
    this._dialogRef.close(true);
    window.open('fuelmenu/missing-taxes-airport' + "?v=" + this._authService.getCurrentTimeNumber());
  }

  
}


