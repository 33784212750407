<div class="divLeft">
  <div>
    <h5>Vendor Profile Setup</h5>
    <div>
      <form *ngIf="vendor_profile_setup_form" [formGroup]="vendor_profile_setup_form">
        <div style="margin-top:0em;background-color:white; padding-left:0em">
          <div class="d-inline-flex">
            <div style="background-color:#f0f0f0">
              <div class="d-inline-flex">
                <div class="form-group" style="margin-left: .5em;margin-top:0em;margin-right:0.625em;">
                  <label for="vendorName" style="font-size:small">Vendor Name</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="text" formControlName="vendorName" class="form-control inputBoxReadOnly" style="width:18em" readonly />
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="legalEntityName" style="font-size:small">Legal Entity Name</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="text" formControlName="legalEntityName" class="form-control inputBoxReadOnly" style="width:18em" />
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="location" style="font-size:small">Location</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="text" formControlName="location" class="form-control inputBoxReadOnly" />
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="rating" style="font-size:small">Rating</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="rating" class="form-control" readonly style="padding: 0em;width:13em;background-color:white;height:1.5625em;font-size:small;" />
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="contractStatus" style="font-size:small">Contract Status</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="contractStatus" class="form-control" readonly style="padding: 0em;width:15em;background-color:white;height:1.5625em;font-size:small;" />
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="creationDate" style="font-size:small">Creation Date</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="creationDate" class="form-control text-uppercase" readonly style="padding: 0em;width:6em;background-color:white;height:1.5625em;font-size:small;" />
                  </div>

                </div>
                <div class="form-group" style="margin-right:0.625em; margin-top:1em ">
                  <button mat-raised-button class="btn-high" (click)="clickEdit()">EDIT</button>
                </div>
              </div>
              <div class="position-relative">
                <div class="d-inline-flex">
                  <div class="form-group" style="margin-left: .5em;margin-top:0em;margin-right:0.625em;">
                    <label for="account_type" style="font-size:small">Account Type</label>
                    <div style="margin-top:-0.35em;">
                      <input type="text" formControlName="account_type" class="form-control inputBoxReadOnly" readonly />
                    </div>
                  </div>
                  <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                    <label for="role" style="font-size:small">Role</label>
                    <div style="margin-top:-0.35em;">
                      <input type="text" formControlName="role" class="form-control inputBoxReadOnly" style="width:20em" readonly />
                    </div>
                  </div>
                  <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                    <label for="facility_type" style="font-size:small">Facility Type</label>
                    <div style="margin-top:-0.35em;">
                      <input type="text" formControlName="facility_type" class="form-control inputBoxReadOnly" style="width:10em" readonly />
                    </div>
                  </div>

                  <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                    <label for="compliance_status" style="font-size:small">Compliance Status</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <input type="text" formControlName="compliance_status" readonly class="form-control inputBoxReadOnly" style="padding: 0em; height: 1.5625em; font-size: small" />
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                    <label for="regulatory_status" style="font-size:small">Regulatory Status</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <input type="text" formControlName="regulatory_status" readonly class="form-control inputBoxReadOnly" style="padding: 0em; height: 1.5625em; font-size: small" />
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                    <label for="accounting_status" style="font-size:small">Accounting Status</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <input type="text" formControlName="accounting_status" readonly class="form-control inputBoxReadOnly" style="padding: 0em; height: 1.5625em; font-size: small" />
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                    <label for="operation_status" style="font-size:small">Operation Status</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <input type="text" formControlName="operation_status" readonly class="form-control inputBoxReadOnly" style="padding: 0em; height: 1.5625em; font-size: small" />
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div style="background-color:#f0f0f0">
              <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                <label for="modifiedBy" style="font-size:small">Modified by</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="modifiedBy" readonly class="form-control" style="padding: 0em; width: 10em; height: 1.5625em; font-size: small" />
                </div>
              </div>
              <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                <label for="modifiedDate" style="font-size:small">Modified Date</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="modifiedDate" readonly class="form-control" style="padding: 0em; width: 10em; height: 1.5625em; font-size: small" />
                  {{getModifiedDate}}
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; white-space:pre-wrap"><label style="color:red; font-size: small">{{errMsg}}</label></div>

          <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>
          <div class="form-group form-inline mt-2">
            <ul class="tabul">
              <li [ngClass]="{ 'btn-low':selectedTabName=='notes','btn-high pointer':selectedTabName!='notes'  }" style="border-left: black 0.0625em solid;" (click)="clickTab('notes')"><div style="margin-top:0.25em">Notes</div></li>
              <li [ngClass]="{ 'btn-low':selectedTabName=='comms','btn-high pointer':selectedTabName!='comms' }" (click)="clickTab('comms')"><div style="margin-top:0.25em">Comms</div></li>
              <li [ngClass]="{ 'btn-low':selectedTabName=='person','btn-high pointer':selectedTabName!='person' }" (click)="clickTab('person')"><div style="margin-top:0.25em">Contacts</div></li>
              <li [ngClass]="{ 'btn-low':selectedTabName=='hours','btn-high pointer':selectedTabName!='hours' }" (click)="clickTab('hours')"><div style="margin-top:0.25em">Hours</div></li>
              <li [ngClass]="{ 'btn-low':selectedTabName=='address','btn-high pointer':selectedTabName!='address' }" (click)="clickTab('address')"><div style="margin-top:0.25em">Address</div></li>
              <li [ngClass]="{ 'btn-low':selectedTabName=='document','btn-high pointer':selectedTabName!='document' }" (click)="clickTab('document')"><div style="margin-top:0.25em">Documents</div></li>
            </ul>
            <div style="display:flex; margin-left: 2em; margin-top:0.5em">
              <mat-checkbox color="primary" formControlName="includeDisabledRecords"  style="font-size:small" (change)="includeDisabledRecordsOnChange($event)">Include disabled records</mat-checkbox>
            </div>
          </div>
          <app-vendor-note #vendorNote *ngIf="selectedTabName=='notes'" [vendorGUID]="vendorGUID" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-note>
          <app-vendor-comms #vendorComms *ngIf="selectedTabName=='comms'" [vendorGUID]="vendorGUID" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-comms>
          <app-vendor-personlist #vendorPersonComms *ngIf="selectedTabName=='person'" [vendorGUID]="vendorGUID" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-personlist>
          <app-vendor-hours-list #vendorHours *ngIf="selectedTabName=='hours'" [vendorGUID]="vendorGUID" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-hours-list>
          <app-vendor-address #vendorAddress *ngIf="selectedTabName=='address'" [vendorGUID]="vendorGUID" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-address>
          <app-vendor-documentlist  #vendorDocument *ngIf="selectedTabName=='document'" [vendorGUID]="vendorGUID" [includeDisabledRecords]="includeDisabledRecords" (getModifiedBy)="getModifiedByHandler($event)" (getModifiedDate)="getModifiedDateHandler($event)"></app-vendor-documentlist> 
</div>

      </form>

    </div>

    <div style="justify-content:flex-start;padding-left: 0em; padding-top:1.5em; padding-bottom:0.5em">
      <button mat-button class="btn-low" (click)="clickClose()"><mat-icon style="margin-top:-.2em">arrow_back</mat-icon> Vendor Profiles</button>

    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>





