import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { CustomerModel } from '../models/customer.model';
import { OutlookMailFolder } from '../models/outlook-mail-folder.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CONTAINER_DATA, MessageService } from '../services/message.service';
import { MessageImportDialogComponent } from './message-import-dialog.component';
import { MessageImportComponent } from './message-import.component';

export interface SelectedFolder {
  displayName: string;
  folderID: string;
  totalItems: number;
  hasChildFolders: boolean;
  parentFolder: SelectedFolder;
  parentFolderId: string;
  customerGUID: string;
  isImported: boolean;
  importedCount: number;
  importedBy: string;
  importedDate: string;
} 

@Component({
  selector: 'app-folder-selection-dialog',
  templateUrl: './folder-selection-dialog.component.html',
  styleUrls: ['./folder-selection-dialog.component.css']
})
export class FolderSelectionDialogComponent implements OnInit {

  aircraftGUID: string;
  tripCodeGUID: string;
  customerGUID: string;
  errMsg: string="";
  displayedColumn: string[] = ['select', 'name', 'totalItems', 'childFolders', 'client', 'imported','imported_count', 'imported_by','imported_date'];
  tripCode: string;
  registration: string;
  showSpin: boolean = false;
  selectedFolder: SelectedFolder;
  buttonText: string = "Select";
  showSuccessMsg: boolean = false;
  isNext: boolean = false;
  folderList: OutlookMailFolder[] = [];
  allFolderList: OutlookMailFolder[] = [];
  customerList: CustomerModel[];
  folderID: string = "";
  parentFolderID: string = "";
  displayName: string = "";
  hasMessages: boolean = false;
  hasChildFolders: boolean = false;
  title: string="";
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  editPortal = new ComponentPortal(MessageImportDialogComponent);
  hideCustomer: boolean = true;
  constructor(  @Inject(CONTAINER_DATA) public componentData: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _messageImport: MessageImportComponent, private _injector: Injector,
    private readonly _messageService: MessageService, private readonly _commonService: CommonService
    
  ) {
    this.folderID = componentData;
  }

  ngOnInit() {
    if (this.folderID != "")
      this.hideCustomer = false;
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.getAllData(this.folderID).subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerList = responses[0].model;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.allFolderList = responses[1].model;

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.folderList = responses[2].model;
          this.folderList.forEach(x => {
            //if (x.mailFolderId == this.folderID)
            //  x.selected = true;
            //else
            //  x.selected = false;
            let index = this.allFolderList.findIndex(y => y.mailFolderId == x.mailFolderId);
            if (index > -1) {
              x.customerGUID = this.allFolderList[index].customerGUID;
              if (x.customerGUID!=null && x.customerGUID!="")
                x.customerName = this.customerList.filter(c => c.customerGUID == x.customerGUID)[0].customerName;
              x.tripCodeGUID = this.allFolderList[index].tripCodeGUID;
              x.isImported = this.allFolderList[index].isImported;
              x.importedBy = this.allFolderList[index].importedBy;
              x.importItemCount = this.allFolderList[index].importItemCount;
              x.lastImportDate = this.allFolderList[index].lastImportDate;
            }
            if (x.customerGUID == null || x.customerGUID == undefined || x.customerGUID == "") {
              index = this.customerList.findIndex(y => y.mailFolderID == x.mailFolderId)
              if (index > -1) {
                x.customerGUID = this.customerList[index].customerGUID;
              }
            }
          });

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.showSpin = false;
    });
  }

  getAllData(folderID:string): Observable<any[]> {
    let getClistResponse = this._commonService.getCustomers();
    let folderResponse = this._messageService.getOutlookMailFolders();
    let messageResponse = this._messageService.getMailFolders(folderID);

    return forkJoin([getClistResponse, folderResponse, messageResponse]);
  }

  getData(folderID:string) {
    this.showSpin = true;
    this.folderID = folderID;
    this._messageService.getMailFolders<ResponseModel<OutlookMailFolder[]>>(folderID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          
          this.folderList = response.model;
          if (this.selectedFolder != null && this.selectedFolder != undefined && folderID !="AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA=") {
            let folder = new OutlookMailFolder();
            folder.displayName = this.selectedFolder.displayName;
            folder.totalItemCount = this.selectedFolder.totalItems;
            folder.mailFolderId = this.selectedFolder.folderID;
            folder.parentFolderId = this.selectedFolder.parentFolderId;
            this.folderList.splice(0,0, folder);
          }
          this.folderList.forEach(x => {
            //if (x.mailFolderId == this.folderID)
            //  x.selected = true;
            //else
            //  x.selected = false;
            let index = this.allFolderList.findIndex(y => y.mailFolderId == x.mailFolderId);
            if (index > -1) {
              x.customerGUID = this.allFolderList[index].customerGUID;
              if (x.customerGUID == '' && this.customerGUID != '')
                x.customerGUID = this.customerGUID;
              if (x.customerGUID != null && x.customerGUID != "")
                x.customerName = this.customerList.filter(c => c.customerGUID == x.customerGUID)[0].customerName;
              x.tripCodeGUID = this.allFolderList[index].tripCodeGUID;
              x.isImported = this.allFolderList[index].isImported;
              x.importedBy = this.allFolderList[index].importedBy;
              x.importItemCount = this.allFolderList[index].importItemCount;
              x.lastImportDate = this.allFolderList[index].lastImportDate;
            }
            if (x.customerGUID == null || x.customerGUID == undefined || x.customerGUID == "") {
              index = this.customerList.findIndex(y => y.mailFolderID == x.mailFolderId)
              if (index > -1) {
                x.customerGUID = this.customerList[index].customerGUID;
              }
            }
            if (x.customerGUID == '' && this.customerGUID != '')
              x.customerGUID = this.customerGUID;
            if (x.customerGUID != null && x.customerGUID != "")
              x.customerName = this.customerList.filter(c => c.customerGUID == x.customerGUID)[0].customerName;
          });
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMsg = response.message;
        }

      }
      this.showSpin = false;
    });
  }

  updateSelection(e: any, item: OutlookMailFolder,i:number) {
    
    if (item.childFolderCount > 0)
      this.hasChildFolders = true;
    else
      this.hasChildFolders = false;
    if (item.totalItemCount > 0)
      this.hasMessages = true;
    else
      this.hasMessages = false;
    let parentFolder = this.selectedFolder;
    this.selectedFolder = {
      displayName: item.displayName, folderID: item.mailFolderId, totalItems: item.totalItemCount,
      hasChildFolders: this.hasChildFolders, parentFolder: parentFolder, customerGUID: item.customerGUID, parentFolderId: item.parentFolderId,
      isImported:item.isImported,importedBy:item.importedBy,importedCount:item.importItemCount,importedDate:item.lastImportDate
    };
    this.folderID = item.mailFolderId;
    this.parentFolderID = item.parentFolderId;
    this.customerGUID = item.customerGUID;
    this.displayName = item.displayName;
    this.clickSave();
    
  }

  clickSave() {
    this.hideCustomer = false;
    if (this.title.length > 0)
      this.title += " / ";
    this.title += this.selectedFolder.displayName;
    if (this.hasChildFolders) {
      this.getData(this.folderID);
     
    }
    else {
      this.editPortal = new ComponentPortal(MessageImportDialogComponent, null, this.createInjector({ selectedFolder: this.selectedFolder, title:this.title }));
        this._messageImport.selectedPortal = this.editPortal;
    }
   
  }

  clickBack() {
    this.title = "";
    let folderID = "";
    this.customerGUID = "";
    if (this.selectedFolder == undefined)
      folderID = "AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA=";
    else
      folderID = this.selectedFolder.parentFolderId;
    this.getData(folderID);
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

 }

   
  
  

