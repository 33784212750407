import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AircraftService } from '../services/aircraft.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { DocumentTypeModel } from '../models/document-type.model';
import { VendorServiceDocumentTypeModel } from '../models/vendor-service-document-type.model';
import { AircraftDocumentModel } from '../models/aircraft-document.model';
import { GlobalConstant } from '../common-utility/global-constant';
import { FileItem, FileUploader, ParsedResponseHeaders, FileUploadModule, FileSelectDirective } from 'ng2-file-upload';
import { FileModel } from '../models/file.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ServiceTypeModel } from '../models/service-type.model';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import { MatDatepicker } from '@angular/material/datepicker';
import { DataEntryStatusModel } from '../models/data-entry-status.model';

@Component({
  selector: 'app-aircraft-document-edit-dialog',
  templateUrl: './aircraft-document-edit-dialog.component.html',
  styleUrls: ['./aircraft-document-edit-dialog.component.css']
})

export class AircraftDocumentEditDialogComponent implements OnInit, AfterViewInit {
  @Input() dialogTitle: string;
  @Input() aircraftDocumentGUID: string;
  @Input() aircraftGUID: string;
  aircraft_document_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsgDocType: string;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  uploadinDoc: boolean = false;
  documentTypeList: DocumentTypeModel[];
  avaiDocumentTypeList: DocumentTypeModel[];
  selDocumentTypeList: DocumentTypeModel[];
  dataEntryStatusList: DataEntryStatusModel[];
  document_name: string;
  //extension: string;
  //remarks: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  displayedColumn: string[] = ['documentType'];
  //personId: string;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  userType: string;
  showDocType: boolean = true;
  allowedFileType: string;
  uploader: FileUploader;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  docSize: string;
  effectiveDate: Date;
  expirationDate: Date;
  minExpirationDate: Date = new Date();
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  serviceTypeList: ServiceTypeModel[];
  selectedServiceTypeID: number;
  selectedDocumentTypeGuid: string="";
  selectedDocumentTypeDescription: string="";
  doesNotExpire: boolean;
  dateLabel: string = "Effective Date";
  isDisabled: boolean = false;
  action: string;
  dataEntryStatusID: number;
  allowedFileTypeList: string[];
  maxFileSize: number;
  maxFileUploadNumber: number; 
  //showVendorSvcDocType: boolean = false;
  //@ViewChild('Aircraft_document_edit_form') ngForm: NgForm;
  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;
  constructor(private readonly _dialogRef: MatDialogRef<AircraftDocumentEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _aircraftService: AircraftService,
    private readonly _dialogService: DialogService
  ) {
    this.aircraftDocumentGUID = data.aircraftDocumentGUID;
    if (data.aircraftGUID == null) {
      this.aircraftGUID = "";
    }
    else {
      this.aircraftGUID = data.aircraftGUID;
    }
    this.dialogTitle = data.dialogTitle;
    this.action = data.action;

  }

  getAllData(): Observable<any[]> {
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();
    if (this.userType == "internal") {
      let getServiceTypeResponse = this._commonService.getServiceTypeList();
      let getDocTypeResponse = this._aircraftService.getAircraftDocumentTypeList<ResponseModel<DocumentTypeModel[]>>(this.aircraftDocumentGUID);
      let getDataEntryStatusResponse = this._commonService.getDataEntryStatusList();
      if (this.aircraftDocumentGUID != "") {
        let AircraftDocResponse = this._aircraftService.getAircraftDocumentByadId(this.aircraftDocumentGUID);
        return forkJoin([getAllowedDocTypeResponse,getServiceTypeResponse, getDocTypeResponse, getDataEntryStatusResponse, AircraftDocResponse]);
      }
      else
        return forkJoin([getAllowedDocTypeResponse,getServiceTypeResponse, getDocTypeResponse]);
    }
    else {
      if (this.aircraftDocumentGUID != "") {
        let AircraftDocResponse = this._aircraftService.getAircraftDocumentByadId(this.aircraftDocumentGUID);
        return forkJoin([AircraftDocResponse]);
      }
      
    }

  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.allowedFileTypeList = [];
    this.allowedFileType = ""; // new Array(GlobalConstant.allowedFileTypeList).toString().split(",").join(", "); 
    this.maxFileSize = 0;    // 5242880;   // 5 mb
    this.maxFileUploadNumber = 0; 
    this.errMsg = "";
    this.msg = "";
    this.errMsgDocType = "";
    this.showDocType =true;
    this.fileList = [];
    this.avaiDocumentTypeList = [];
    this.selDocumentTypeList = [];
    this.document_name = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.docSize = "";
    this.effectiveDate=null;
    this.expirationDate = null;
    this.minExpirationDate = new Date();
    this.serviceTypeList = [];
    this.selectedServiceTypeID = 0;
    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",  
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
    this.finishAllUploads = true;
    this.uploader.clearQueue();
    };
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        if (this.action!="add")
          this.isDisabled = true;
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    let getData = true;
    if (this.userType != "internal") {
      if (this.aircraftDocumentGUID == "") {
        getData = false;
        this.initControls();
        this.showSpin = false;
        
      }

    }

    if (getData == true) {
      this.getAllData().subscribe(responses => {
        if (responses[0] != null) {
          if (responses[0].code == "200" && responses[0].message == "") {
            if (responses[0].model != null) {
              let fileObj = new FileModel();
              fileObj = responses[0].model;
              this.allowedFileTypeList = fileObj.allowedFileTypeList;
              this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
              this.maxFileSize = fileObj.fileSize;   // 5242880;
              this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
            }
          }
          else {
            if (responses[0].code == "401") {
              this._authService.signOut();
            }
          }
        }

        if (this.userType == "internal") {
          if (responses[1] != null) {
            if (responses[1].code == "200" && responses[1].message == "") {
              this.serviceTypeList = responses[1].model;
            }
            else {
              if (responses[1].code == "401") {
                this._authService.signOut();
              }
            }
          }

          if (responses[2] != null) {
            if (responses[2].code == "200" && responses[2].message == "") {
              this.documentTypeList = responses[2].model;
            }
            else {
              if (responses[2].code == "401") {
                this._authService.signOut();
              }
            }
          }

          if (this.aircraftDocumentGUID != null && this.aircraftDocumentGUID != "") {

            if (responses[3] != null) {
              if (responses[3].code == "200" && responses[3].message == "") {
                this.dataEntryStatusList = responses[3].model;
              }
              else {
                if (responses[3].code == "401") {
                  this._authService.signOut();
                }
              }
            }

            if (responses[4] != null) {
              if (responses[4].code == "200" && responses[4].message == "") {
                let aircraftDoc = new AircraftDocumentModel();
                aircraftDoc = responses[4].model;
                this.document_name = aircraftDoc.documentName;
                this.modifiedBy = aircraftDoc.modifiedBy;
                this.modifiedDate = aircraftDoc.modifiedDate;
                if (aircraftDoc.effectiveDate != null && aircraftDoc.effectiveDate != "")
                  this.effectiveDate = new Date(aircraftDoc.effectiveDate);
                if (aircraftDoc.expirationDate != null && aircraftDoc.expirationDate != "")
                  this.expirationDate = new Date(aircraftDoc.expirationDate);
                if (aircraftDoc.documentSizeInKB > 0)
                  this.docSize = aircraftDoc.documentSizeInKB.toString() + " KB";
                if (aircraftDoc.isActive == true)
                  this.isActive = 1;
                else
                  this.isActive = 0;
                this.doesNotExpire = aircraftDoc.doesNotExpire;
                this.dataEntryStatusID = aircraftDoc.dataEntryStatusID;
                let obj = new FileModel();
                obj.fileName = aircraftDoc.documentName;
                obj.bFile = aircraftDoc.bFile;
                obj.mimeType = aircraftDoc.mimeType;
                obj.fileType = aircraftDoc.fileExtension;

                this.fileList.push(obj)
              }
              else {
                if (responses[4].code == "401") {
                  this._authService.signOut();
                }
              }
            }
          }
        }
        else {
          if (this.aircraftDocumentGUID != null && this.aircraftDocumentGUID != "") {
            if (responses[0] != null) {
              if (responses[0].code == "200" && responses[0].message == "") {
                let aircraftDoc = new AircraftDocumentModel();
                aircraftDoc = responses[0].model;
                this.document_name = aircraftDoc.documentName;
                this.modifiedBy = aircraftDoc.modifiedBy;
                this.modifiedDate = aircraftDoc.modifiedDate;
                if (aircraftDoc.effectiveDate != null && aircraftDoc.effectiveDate != "")
                  this.effectiveDate = new Date(aircraftDoc.effectiveDate);
                if (aircraftDoc.expirationDate != null && aircraftDoc.expirationDate != "")
                  this.expirationDate = new Date(aircraftDoc.expirationDate);
                if (aircraftDoc.documentSizeInKB > 0)
                  this.docSize = aircraftDoc.documentSizeInKB.toString() + " KB";
                if (aircraftDoc.isActive == true)
                  this.isActive = 1;
                else
                  this.isActive = 0;
                this.doesNotExpire = aircraftDoc.doesNotExpire;
                let obj = new FileModel();
                obj.fileName = aircraftDoc.documentName;
                obj.bFile = aircraftDoc.bFile;
                obj.mimeType = aircraftDoc.mimeType;
                obj.fileType = aircraftDoc.fileExtension;

                this.fileList.push(obj)
              }
              else {
                if (responses[0].code == "401") {
                  this._authService.signOut();
                }
              }
            }
          }
        }


        this.initControls();

        this.showSpin = false;

        //this.setDocumentTypeList(this.selectedServiceTypeID);

        if (this.userType == "internal") {
          //this.setVendorSvcDocumentTypeList();
          this.f.document_type.setValidators(Validators.required);
          this.f.document_type.updateValueAndValidity();
          this.setDocumentTypeList();
        }


      });


    }
    else {
      this._commonService.getAllowedFileTypeAndFileSize<ResponseModel<FileModel>>().subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            if (response.model != null) {
              let fileObj = new FileModel();
              fileObj = response.model;
              this.allowedFileTypeList = fileObj.allowedFileTypeList;
              this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
              this.maxFileSize = fileObj.fileSize;   // 5242880;
              this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
            }
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          }
        }
      })
      
    }
  }

  initControls() {
    this.aircraft_document_edit_form = this._formBuilder.group({
      doc_status: { value: this.isActive, disabled: this.isDisabled },
      document_name: [{ value: this.document_name, disabled: this.isDisabled }, Validators.required],
      size: [{ value: this.docSize, disabled: this.isDisabled }],
      effectiveDate: [{ value: this.effectiveDate, disabled: this.isDisabled }],
      expirationDate: [{ value: this.expirationDate, disabled: this.isDisabled }],
      modifiedBy: [{ value: this.modifiedBy, disabled: this.isDisabled }],
      modifiedDate: [{ value: this.modifiedDate, disabled: this.isDisabled }],
      document: null,
      service_type_select: [{ value: this.selectedServiceTypeID, disabled: this.isDisabled }],
      document_type: [{ value: this.selectedDocumentTypeGuid, disabled: this.isDisabled }],
      data_entry_status:this.dataEntryStatusID
    });

  }

  ngAfterViewInit() {

  }

  get f() { return this.aircraft_document_edit_form.controls; }

  docTypeChange(docType: any) {
    this.selectedDocumentTypeDescription = docType[docType.selectedIndex].text;
    this.setValidation(this.selectedDocumentTypeDescription);
    
  }

  setValidation(docType: string) {
    this.dateLabel = "Effective Date";
    this.f.effectiveDate.clearValidators();
    this.f.expirationDate.clearValidators();
    if (docType == "Aircraft Registration") {
      this.dateLabel = "Issue Date";
      this.f.effectiveDate.setValidators(Validators.required);
      //this.f.expirationDate.setValidators(Validators.required);
    }
    if (docType.includes("Insurance")) {
      this.f.effectiveDate.setValidators(Validators.required);
    }
    this.f.effectiveDate.updateValueAndValidity();
    this.f.expirationDate.updateValueAndValidity();
    
  }

  clickAddItem(e: any, item: DocumentTypeModel) {
    this.documentTypeList.forEach(x => {
      if (x.documentTypeGUID.toLowerCase() == item.documentTypeGUID.toLowerCase()) {
        x.selected = true;
      }
    })
    this.setDocumentTypeList();

  }

  clickRemoveItem(e: any, item: DocumentTypeModel) {
    this.documentTypeList.forEach(x => {
      if (x.documentTypeGUID.toLowerCase() == item.documentTypeGUID.toLowerCase()) {
        x.selected = false;
      }
    })
    this.setDocumentTypeList();

  }

  //setDocumentTypeList() {
  //  this.errMsg = "";
  //  this.avaiDocumentTypeList = this.documentTypeList.filter(u => u.selected == false);
  //  this.selDocumentTypeList = this.documentTypeList.filter(u => u.selected == true);
  //}

  setDocumentTypeList() {
    this.errMsg = "";
    this.avaiDocumentTypeList = this.documentTypeList.filter(
      (thing, i, arr) => arr.findIndex(t => t.documentTypeGUID === thing.documentTypeGUID && t.selected == false) === i
    );
    this.selDocumentTypeList = this.documentTypeList.filter(
      (thing, i, arr) => arr.findIndex(t => t.documentTypeGUID === thing.documentTypeGUID && t.selected == true) === i 
    );
    this.selectedDocumentTypeGuid = this.selDocumentTypeList[0].documentTypeGUID;
    this.selectedDocumentTypeDescription = this.selDocumentTypeList[0].documentTypeDescription;
    this.f.document_type.setValue(this.selectedDocumentTypeGuid);
    this.setValidation(this.selectedDocumentTypeDescription);
  }

  clickSelectAll() {
    this.documentTypeList.forEach(x => {
      if (this.selectedServiceTypeID == 0) {
        x.selected = true;
      }
      else {
        if (this.selectedServiceTypeID == x.serviceTypeID) {
          x.selected = true;
        }
      }
      //x.selected = true;
    })

    this.setDocumentTypeList();
  }

  clickRemoveAll() {
    this.documentTypeList.forEach(x => {
      x.selected = false;
    })

    this.setDocumentTypeList();
  }



  clickCustomizeDocumentType() {
    if (!this.showDocType)
      this.showDocType = true;
  }

  clickCustomizeVendorSvcDocumentType() {
    if (this.showDocType)
      this.showDocType = false;
  }

  // upload file
  importFile(event: any) {
    this.finishAllUploads = false;
    this.fileList = [];
    var files = [];

    this.errMsg = "";
    if (event.target.files.length > this.maxFileUploadNumber) {
      if (this.errMsg != "") {
        this.errMsg += "\n";
      }
      this.errMsg += 'The maximum uploas files are ' + this.maxFileUploadNumber + '.';
    }
    else {
      if (event.target.files.length > 1) {
        if (this.errMsg != "") {
          this.errMsg += "\n";
        }
        this.errMsg += 'Only allow to upload one file.';
      }
      else {
        for (var i = 0; i < event.target.files.length; i++) {
          let filename = event.target.files[i].name;
          //var a = this.checkValidFileType(filename);
          var a = CustomValidators.checkValidFileType(filename);
          if (a) {
            if (event.target.files[i].size >= this.maxFileSize) {
              if (this.errMsg != "") {
                this.errMsg += "\n";
              }
              this.errMsg += 'The file size "' + filename + '" is too big to upload.';
            }
            else {
              files.push(event.target.files[i]);
              this.f.document_name.setValue(filename);
            }
          }
          else {
            if (this.errMsg != "") {
              this.errMsg += "\n";
            }
            this.errMsg += 'The file type of "' + filename + '" is not allowed to upload.';
          }
        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      return;
    }

    this.uploadSubmit();
  }

  uploadSubmit() {    
    this.finishAllUploads = false;
    this.uploader.uploadAll();    
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            let obj = new FileModel();
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            this.f.size.setValue(obj.fileSize.toString()+ " KB");
            this.fileList.push(obj)
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  clickPreviewDocument() {
    let item = this.fileList[0];
    let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
    let file = new Blob([byteArray], { type: item.mimeType });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  clickRemoveDocument() {
    this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the document?");
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        this.fileList = [];
        this.document_name = '';
        this.f.document_name.setValue(this.document_name);
      }
    })

  }
  // upload file end

  clickSave() {
    this.errMsg = "";
    this.errMsgDocType=""
    this._authService.updateAccessTime();
    this.submitted = true;
    
    if (this.effectiveDateRef.nativeElement.value !== "") {
      if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));     
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.effectiveDate.setErrors({ isValidDate: f1 });
          
        }
      }
      else {
        this.f.effectiveDate.setErrors({ isValidDate: false });
        
      }
    }

    if (this.expirationDateRef.nativeElement.value !== "") {
      if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null) {
        let s2 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
        let f2 = CustomValidators.isValidDate(s2);
        if (!f2) {
          this.f.expirationDate.setErrors({ isValidDate: f2 });

        }
      }
      else {
        this.f.expirationDate.setErrors({ isValidDate: false });

      }
    }

    if (this.selectedDocumentTypeDescription.includes("Insurance")) {
      if (!this.doesNotExpire) {
        if (this.f.expirationDate.value=="")
        this.f.expirationDate.setErrors({ isRequired: true });
      }

    }

    //if (this.userType == "internal") {
    //  if (this.selDocumentTypeList.length == 0) {
    //    this.errMsgDocType = "You must select at least one document type.";
    //  }
    //}

    if (this.fileList.length == 0 && this.f.document_name.value!="") {
      if (this.errMsg != "") {
        this.errMsg += "\n";
      }
      this.errMsg += "Please upload a document.";
    }
    if (this.aircraft_document_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    let obj = new AircraftDocumentModel();
    obj.aircraftGUID = "";
    //if (this.userType=="internal")
    obj.aircraftGUID = this.aircraftGUID;
    obj.aircraftDocumentGUID = "";
    if (this.aircraftDocumentGUID != "" && this.aircraftDocumentGUID != null)
      obj.aircraftDocumentGUID = this.aircraftDocumentGUID;
    obj.documentName = this.f.document_name.value;
    obj.documentTypeGUIDList = "";
    obj.documentTypeGUIDList = this.f.document_type.value;

    //if (this.selDocumentTypeList.length>0)
    //  obj.documentTypeGUIDList = Array.prototype.map.call(this.selDocumentTypeList, s => s.documentTypeGUID).toString();
    
    if (this.f.doc_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;
    obj.effectiveDate = "";
    if (this.f.effectiveDate.value !=null) {
      obj.effectiveDate = this.effectiveDateRef.nativeElement.value;
    }
    obj.expirationDate = "";
    if (this.f.expirationDate.value != null) {
      obj.expirationDate = this.expirationDateRef.nativeElement.value;
    }
    obj.bFile = "";
    if (this.fileList.length > 0) {
      obj.bFile = this.fileList[0].bFile;
      obj.fileExtension = this.fileList[0].fileType;
      obj.fileSize = this.fileList[0].fileSize;
    }
    obj.doesNotExpire = this.doesNotExpire;
    if (this.f.data_entry_status.value != null)
      obj.dataEntryStatusID = this.f.data_entry_status.value;
    this._aircraftService.saveAircraftDocument<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.aircraftDocumentGUID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString().padStart(2, "0");
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);          
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this._dialogRef.close(this.isModified);
          }, 1000);
          

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. ";// + response.message;
        }
      }
      this.loading = false;
    })


  }

  checkNoExpiry(isChecked: boolean) {
    if (isChecked)
      this.f.expirationDate.setValue("");
    this.doesNotExpire = isChecked;
  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.allowedFileType = new Array(GlobalConstant.allowedFileTypeList).toString().split(",").join(", ");
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.errMsgDocType = "";
    this.showDocType = true;
    this.fileList = [];
    this.finishAllUploads = true;
    this.avaiDocumentTypeList = [];
    this.selDocumentTypeList = [];
    this.document_name = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.aircraftDocumentGUID = "";
    this.dialogTitle = "Add New Document";
    this.docSize = "";
    this.effectiveDate = null;
    this.expirationDate = null;
    this.selectedServiceTypeID = 0;
    this.selectedDocumentTypeGuid = "";
    this.doesNotExpire = false;
    this.initControls();
    if (this.userType == "internal") {
      this.documentTypeList.forEach(x => {
        x.selected = false;
      })
      this.setDocumentTypeList();
    }
    //this.documentTypeList.forEach(x => {
    //  x.selected = false;
    //})
    //this.avaiDocumentTypeList = this.documentTypeList.filter(u => u.selected == false);

    //if (this.userType == "internal") {
    //  this.vendorSvcDocTypeList.forEach(x => {
    //    x.selected = false;
    //  })
    //  this.avaiVendorSvcDocTypeList = this.vendorSvcDocTypeList.filter(u => u.selected == false);
    //}


  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }
}
