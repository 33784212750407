<div *ngIf="upPII" style="background-color:#f8f8f8; overflow-y: hidden">
  <div style="display: flex; justify-content:space-between">
    <div style="display: flex;padding-left:1em; padding-top:0.25em; float:left; min-width:46em" cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>

      <div style="margin-right:1em">
        <div style="font-size: small">Registration</div>
        <div style="font-weight: bold; font-size: small">{{registration}}</div>
      </div>
      <div style="margin-right:1em">
        <div style="font-size: small">Trip Code</div>
        <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
      </div>
      <div style="margin-right:1em">
        <div style="font-size: small">Business Stop</div>
        <div style="font-size: small; display: flex"><div>{{prevICAO!=''?prevICAO+'-':''}}</div><div style="font-weight: bold">{{icao}}</div><div>{{nextICAO!=''?'-'+nextICAO:''}}</div></div>
      </div>
      <div style="margin-right:1em" *ngIf="arrivalDateTime!=''">
        <div style="font-size: small">Arrival({{icao}})</div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{arrivalDateTime}}</div>
        <div style="font-size: small; margin-top:-0.5em"> {{arrivalDateTimeLocal!=''?arrivalDateTimeLocal:''}}</div>
      </div>
      <div style="margin-right:1em" *ngIf="departureDateTime!=''">
        <div style="font-size: small">Departure({{icao}})</div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{departureDateTime}}</div>
        <div style="font-size: small;margin-top:-0.5em"> {{departureDateTimeLocal!=''?departureDateTimeLocal:''}}</div>
      </div>
      <div>
        <div style="font-size: small;">{{aircraftManufacturer}} {{aircraftModel}}</div>
        <div style="font-size: small;">{{aircraftInfo}}</div>
      </div>
    </div>
    <div *ngIf="onHoldText!=''" style="margin-top:1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
      <div style="margin-top: -0.1em; text-align: center; font-weight: normal; font-size: small;">On Hold</div>
    </div>
    <div style="float: right;padding-top:0.75em; padding-right:1em">
      <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard" [cdkCopyToClipboard]="clickCopyHeader() ">content_copy</i>
    </div>
  </div>
  <div>
    <form *ngIf="flight_following_client_form" [formGroup]="flight_following_client_form">
      <div style="display:flex; margin-top:0em; padding-left: 1em; padding-right:1em">
        <div style="margin-top:0.5em;background-color:white; padding-left:1em; padding-bottom: 0.75em; width: 52em;min-height:32em">
          <div style="display:flex; justify-content: space-between">
            <div style="margin-top:0.25em;"><h5>{{serviceTypeDesc}}</h5></div>            
          </div>
          <div style="display: flex; ">
            <div style="margin-left:0em; margin-right:0.5em">
              <label for="task_status_select" style="font-size:small">Operational Status</label>
              <div style="margin-top:-0.5em; font-size:small; min-width: 13.25em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd; padding-right:0.25em">
                {{taskStatusDescription}}
              </div>
            </div>
            <!--<div style="margin-top:1.25em; margin-left:0em">
              <button mat-button class="btn-high" (click)="clickEmail()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em; width:1.125em">messages</i><div>Messages</div></div></button>&nbsp;
            </div>-->
          </div>
          <div style="display: flex;margin-top: 0.25em">
            <div style="width: 34em">
              <div style="font-size: small;">
                Workflow Task
              </div>
              <div *ngFor="let sst of flightFollowingList; let i=index" style="margin-top:0.25em">
                <div *ngIf="i % 4==0" style="display: flex">
                  <div style="display: flex; font-size: small">
                    <div>
                      <mat-checkbox style="transform: scale(0.75);" disabled [checked]="sst.selected"></mat-checkbox>
                    </div>
                    <div style="margin-top:0.1em;margin-left:0em; width: 9em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{sst.subServiceTypeDescription}}</div></div>
                  </div>
                  <div *ngIf="i + 1 < flightFollowingList.length" style="display: flex; font-size: small">
                    <div>
                      <mat-checkbox style="transform: scale(0.75);" disabled [checked]="flightFollowingList[i+1].selected"></mat-checkbox>
                    </div>
                    <div style="margin-top:0.1em;margin-left:0em;  width: 9em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{flightFollowingList[i+1].subServiceTypeDescription}}</div></div>
                  </div>
                  <div *ngIf="i + 2 < flightFollowingList.length" style="display: flex; font-size: small">
                    <div>
                      <mat-checkbox style="transform: scale(0.75);" disabled [checked]="flightFollowingList[i+2].selected"></mat-checkbox>
                    </div>
                    <div style="margin-top:0.1em;margin-left:0em; width: 9em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{flightFollowingList[i+2].subServiceTypeDescription}}</div></div>
                  </div>

                  <div *ngIf="i + 3 < flightFollowingList.length" style="display: flex; font-size: small">
                    <div>
                      <mat-checkbox style="transform: scale(0.75);" disabled [checked]="flightFollowingList[i+3].selected"></mat-checkbox>
                    </div>
                    <div style="margin-top:0.1em;margin-left:0em; width: 9em; height:1.2em; padding:0em;"><div style="margin-top:-0.3em">{{flightFollowingList[i+3].subServiceTypeDescription}}</div></div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display:flex;padding-left: 0.5em; ">                      
              <div>
                <div>
                  <label for="modifiedBy" style="font-size:small">Modified By</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:9em;height:1.5625em;font-size:small;padding:0" />
                  </div>
                </div>
                <div>
                  <label for="modifiedDate" style="font-size:small">Modified Date</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:9em;height:1.5625em;font-size:small;padding:0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <div class="form-inline" style="margin-top:0.5em; float:left">
              <div *ngIf="userType!='internal'">Note</div>
            </div>
            <div style="margin-top: 0.75em;float: right; margin-right:3em; height:1em; font-size: small; display: flex"><i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard" [cdkCopyToClipboard]="clickCopyNote()">content_copy</i><div [cdkCopyToClipboard]="clickCopyNote()" style="cursor: pointer">Copy Note to Clipboard</div></div>
          </div>
          <div>
            <textarea formControlName="public_note" class="form-control" readonly style="width:60em; height:18em;font-family:Courier New, Courier, monospace;font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display:flex; height:2.75em">
    <div>
      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>&nbsp;
      <button mat-button class="btn-high" (click)="clickEmail()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em; width:1.125em">messages</i><div>Messages</div></div></button>&nbsp;
      <!--<button mat-button class="btn-high" *ngIf="hasTaskAudit==true" (click)="clickAudit()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">manage_search</i><div>Audit</div></div></button>-->
    </div>
    
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
