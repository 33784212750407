<div class="greyBack" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="display: inline-flex; justify-content: space-between; width: 100%;">
    <div style="margin-left:1em">
      <div style="display: inline-flex;">
        <i class="material-symbols-outlined" style="margin-top: 0.3em;">
          route
        </i>
        <div style="margin-left: 0.5em; margin-bottom: 0.5em;">
          <h3 style="margin-bottom: 0em;">Flight Plan Preferences</h3>
          <h5 style="color: #1976e6;">{{registration}} - {{tripCode}} - {{clientName}}</h5>
        </div>
      </div>
    </div>
    
    <div style="display: flex;">
      <button mat-icon-button mat-dialog-close><i class="material-icons" style="font-size: x-large;">close </i></button>
    </div>
  </div>
  <div *ngIf="upPII" style="margin-top:0em; display:flex; padding: 1em 1em 1em 1em; background-color: white;">
    <form *ngIf="flightplan_leglist_form" [formGroup]="flightplan_leglist_form">
      <div class="d-inline-flex">
        <div style="max-height: 41.5em; overflow-y: auto; overflow-x:hidden">
  
          <table mat-table
                 [dataSource]="legList" multiTemplateDataRows
                 class="example-container">
            <!--<tr>
              <ng-container matColumnDef="expand_collapse">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumn.length" style="border-bottom:none; text-align: left !important ">
                  <a (click)="openAll()" class="primary" style="outline:none; font-size: small; cursor:pointer;color:#337dff; font-weight:600">Expand All</a>&nbsp;&nbsp;&nbsp;&nbsp;<a (click)="closeAll()" color="primary" style="outline: none; font-size: small; cursor:pointer;color:#337dff; font-weight:600">Collapse All</a>
                </th>
              </ng-container>
            </tr>-->
            <tr>
              <ng-container matColumnDef="departure">
                <th mat-header-cell *matHeaderCellDef style="width:8%; text-align:left !important">
                  <div style="margin-top:-0.25em;display: flex">
                    <div><i class="material-icons" style="font-size: large;">flight_takeoff</i></div><div>Departure</div>                  
                  </div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;">
                  <div>
                    <div style="display: flex">
                      <div *ngIf="element.departureICAO!='XXXX'" [ngClass]="element.isDepartureHomeBaseAirport?'homeBaseAirport divHover':'divHover'" style="cursor: pointer;font-weight: bold; font-size: medium; margin-right:0.25em" (click)="clickICAO($event, element.legItem, 'D')" title="{{element.departureAirportInfo}}">{{element.departureICAO_IATA}}</div>
                    </div>
                    <div *ngIf="element.departureICAO=='XXXX'" style="font-weight: bold; font-size: medium;">{{element.departureICAO_IATA}}</div>
                    <div style="display: flex;margin-top:-0.25em ">
                      <div style="display: flex;">
                        <div style="white-space: nowrap; font-weight: bold;">{{element.departureDateTextUTC!=''?element.departureDateTimeUTCDisplay+' Z':'TBA'}}</div>
                        <div style="margin-left:0.5em">{{element.departureUTCTimeConversion}}</div>
                      </div>
  
                    </div>
                    <div style="white-space: nowrap; margin-top:-0.5em">{{element.departureDateTextLocal!=''?element.departureDateTextLocal+' L':''}}</div>
                    <div style="font-size:x-small;margin-top:-0.25em">{{element.departureGroundStopTypeDescription!='Active'?element.departureGroundStopTypeDescription:''}}</div>
  
  
  
  
  
                  </div>
                  <div *ngIf="element.departureOnHold" style="margin-top:0.1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
                    <div style="margin-top:-0.3em; text-align:center">On Hold</div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="arrival">
                <th mat-header-cell *matHeaderCellDef style="width:8%; text-align:left !important">
                  <div style="margin-top:-0.25em;display: flex"><div><i class="material-icons" style="font-size: large;">flight_land</i></div><div>Arrival</div></div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;">
                  <div>
                    <div style="display: flex"><div *ngIf="element.nextArrivalICAO!='XXXX'" [ngClass]="element.isNextArrivalHomeBaseAirport?'homeBaseAirport divHover':'divHover'" style="cursor: pointer;font-weight: bold; font-size: medium; margin-right:0.25em" (click)="clickICAO($event, element.legItem, 'A')" title="{{element.nextArrivalAirportInfo}}">{{element.nextArrivalICAO_IATA}}</div></div>
                    <div *ngIf="element.nextArrivalICAO=='XXXX'" style="font-weight: bold; font-size: medium;">{{element.nextArrivalICAO_IATA}}</div>
                    <div style="display: flex; margin-top: -0.25em">
                      <div style="white-space: nowrap; font-weight: bold">{{element.nextArrivalDateTextUTC!=''?element.nextArrivalDateTimeUTCDisplay+' Z':'TBA'}}</div>
                      <div style="margin-left: 0.5em">{{element.nextArrivalUTCTimeConversion}}</div>
                    </div>
                    <div style="white-space: nowrap; margin-top:-0.5em">{{element.nextArrivalDateTextLocal!=''?element.nextArrivalDateTextLocal+' L':''}}</div>
  
  
                    <div style="font-size:x-small;margin-top:-0.25em">{{element.nextArrivalGroundStopTypeDescription!='Active'?element.nextArrivalGroundStopTypeDescription:''}}</div>
  
  
  
                  </div>
                  <div *ngIf="element.nextArrivalOnHold" style="margin-top:0.1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
                    <div style="margin-top:-0.3em; text-align:center">On Hold</div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="crew">
                <th mat-header-cell *matHeaderCellDef style="width:3%;text-align: left !important ">
                  <div style="margin-top:-0.25em;">Crew</div>
                </th>
                <td mat-cell *matCellDef="let element" >
                  <div>
                    <div *ngIf="element.crewCount>0" style="white-space:nowrap;font-weight: bold">{{element.crewCount}}</div>
                   
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="pax">
                <th mat-header-cell *matHeaderCellDef style="width:3%; text-align: left !important ">
                  <div style="margin-top:-0.25em;">PAX</div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div style="margin-left:0em">
                    <div *ngIf="element.paxCount>0" style="white-space:nowrap; font-weight:bold">{{element.paxCount}}</div>
                    
                  </div>
                </td>
              </ng-container>
              
              <ng-container matColumnDef="flight_plan_preferences">
                <th mat-header-cell *matHeaderCellDef style="width: 76%; text-align: left !important ">
                  <div style="margin-top:-0.25em;">Flight Plan Preferences</div>                
                </th>
                <td mat-cell *matCellDef="let element">
                  <div style="width:100%" class="d-flex" >
                    <div style="width:20%">
                      <div class="input-group">
                        <div style="text-align: right; width:37%; align-self: flex-end">CRZ SPD</div><div class="ml-1 " style="border-bottom: solid thin black; align-self: flex-end; width: 46%">{{element.cruiseSpeed}}</div><span style="font-size: small; align-self: flex-end " *ngIf="cruiseSpeedClientRequested" class="material-icons-outlined">add</span>
                      </div>
                      <div class="input-group">
                        <div style="text-align: right; width: 37%; align-self: flex-end">FLT LVL</div><div class="ml-1" style="border-bottom: solid thin black; width: 46%; align-self: flex-end">{{element.flightLevel}}</div><span style="font-size: small; align-self: flex-end" *ngIf="flightLevelClientRequested" class="material-icons-outlined">add</span>
                      </div>
                    </div>
                
                    <div style="width:20%">
                      <div class="input-group">
                        <div style="text-align: right; width: 39%; align-self: flex-end">ALT</div><div class="ml-1 " style="border-bottom:solid thin black;width:45%;align-self:flex-end">{{element.alternateAirports}}</div><span style="font-size:small" *ngIf="altClientRequested" class="material-icons-outlined">add</span>
                      </div>
                      <div class="input-group">
                        <div style="text-align: right; width: 39%; align-self: flex-end">DEP FUEL</div><div class="ml-1" style="border-bottom:solid thin black;width:45%;align-self:flex-end">{{element.departureFuel}}</div><span style="font-size:small" *ngIf="departureFuelClientRequested" class="material-icons-outlined">add</span>
                      </div>
                    </div>
                
                    <div style="width:20%">
                      <div class="input-group">
                        <div style="text-align: right;  width: 39%; align-self: flex-end">FOD</div><div class="ml-1" style="border-bottom: solid thin black; width: 45%; align-self: flex-end">{{element.fuelOverDestination}}</div><span style="font-size:small" *ngIf="fodClientRequested" class="material-icons-outlined">add</span>
                      </div>
                      <div class="input-group">
                        <div style="text-align: right; width: 39%; align-self: flex-end">Fuel@Alt</div><div class="ml-1 " style="border-bottom:solid thin black;width:45%;align-self:flex-end">{{element.tank}}</div><span style="font-size:small" *ngIf="tankClientRequested" class="material-icons-outlined">add</span>
                      </div>
                      <!-- <div class="input-group">
                        <div style="text-align: right; width: 20%; align-self: flex-end">ETPS</div><div class="ml-1" style="border-bottom:solid thin black;width:72%;align-self:flex-end">{{etps}}</div><span style="font-size:small" *ngIf="etpsClientRequested" class="material-icons-outlined">add</span>
                      </div> -->
                    </div>
                
                    <div style="width:20%">
                      <div class="input-group">
                        <div style="text-align: right; width: 23%; align-self: flex-end">HOLD</div><div class="ml-1" style="border-bottom: solid thin black; width: 50%; align-self: flex-end">{{element.hold}}</div><span style="font-size:small" *ngIf="holdClientRequested" class="material-icons-outlined">add</span>
                      </div>
                      <div class="input-group">
                        <div style="text-align: right; width: 23%; align-self: flex-end">RES</div><div class="ml-1 " style="border-bottom:solid thin black;width:50%;align-self:flex-end">{{element.reserve}}</div><span style="font-size:small" *ngIf="reserveClientRequested" class="material-icons-outlined">add</span>
                      </div>
                      <!-- <div class="input-group">
                        <div style="text-align: right; width: 20%; align-self: flex-end">ETPS</div><div class="ml-1" style="border-bottom:solid thin black;width:72%;align-self:flex-end">{{etps}}</div><span style="font-size:small" *ngIf="etpsClientRequested" class="material-icons-outlined">add</span>
                      </div> -->
                    </div>
                   
                    <div style="width:20%">
                      <!-- <div class="input-group">
                        <div style="text-align: right; width: 7%; align-self: flex-end">RTE</div><div class="ml-1" style="border-bottom:solid thin black;width:91%;text-align:left;align-self: flex-end">{{flightRoute}}</div>
                      </div> -->
                      <div class="input-group">
                        <div style="text-align:right;width:37%">PAX WT</div><div class="ml-1" style="border-bottom:solid thin black; width: 46%">{{element.payload}}</div><span style="font-size:small" *ngIf="payloadClientRequested" class="material-icons-outlined">add</span>
                      </div>
                      <div class="input-group">
                        <div style="text-align: right; width: 37%; align-self: flex-end">CARGO</div><div class="ml-1" style="border-bottom:solid thin black;width:45%;align-self:flex-end">{{element.cargo}}</div><span style="font-size:small" *ngIf="cargoClientRequested" class="material-icons-outlined">add</span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group">
                    <div style="text-align: right; width: 7%; align-self: flex-end">ATC RMK</div><div class="ml-1" 
                    style="border-bottom: solid thin black; width: 89.5%; text-align: left; align-self: flex-end; text-overflow: ellipsis; overflow: hidden; text-wrap: nowrap;" [matTooltip]="remarks">{{element.remarks}}</div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width:2%">
                  <div>Actions</div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div style="display: flex; margin-top: .5em; margin-bottom: .5em">
                    <button *ngIf="element.flightPlanPreferenceID==null" mat-button class="btn-high grid" (click)="addEditFPP(element)">ADD</button>
                    <button *ngIf="element.flightPlanPreferenceID!=null" mat-button class="btn-low grid" (click)="addEditFPP(element)">EDIT</button>
  
                  </div>
                  <div><i class="material-icons-outlined iconHover" style="font-size: x-large;cursor: pointer;" title="Configure Alternate Airports, ETP &ETOPS" (click)="clickEditAlternateAirport($event, element);">edit_location_alt</i></div>
                  <div *ngIf="element.flightPlanPreferenceID!=null"><i class="material-icons-outlined iconHover" style="font-size: x-large;cursor: pointer;" title="View Audit" (click)="clickAudit($event, element);">history</i></div>
                </td>
              </ng-container>
              
            </tr>
            
            <!--<tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>-->
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns:displayedColumn;"
                class="example-element-row" [class.hidden]="hidePastStop?element.nextArrivalDateTimePassed:false">
            </tr>
            
          </table>
        </div>
        
      </div>
    </form>
  </div>
  <div style="z-index:100; top: 23.5em ; left: 22em; position:absolute; min-width: 20em">
    <app-spin *ngIf="showSpin"></app-spin>
  </div>
</div>


