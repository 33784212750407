import { v4 as uuidv4 } from 'uuid';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { AirportModel } from '../models/airport.model';
import * as atlas from 'azure-maps-control';
import { AirportService } from '../services/airport.service';
import { VendorService } from '../services/vendor.service';
import { ResponseModel } from '../models/response.model';
import { VendorHoursModel } from '../models/vendor-hours.model';
import { HoursObjModel } from '../models/hoursObj.model';
import { VendorModel } from '../models/vendor.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MapPointsModel } from '../models/map-points.model';
import { AirportMapComponent } from '../airports/airport-map.component';
import { CommonService } from '../services/common.service';
import { CountryModel } from '../models/country.model';
import { CountryContentDocModel } from '../models/country-content-doc.model';
import { CountryDetailsComponent } from '../country/country-details.component';
import { TemplateService } from '../services/template.service';


@Component({
  selector: 'app-airport-brief',
  templateUrl: './airport-brief.component.html',
  styleUrls: ['./airport-brief.component.css']
})
export class AirportBriefComponent implements AfterViewInit {

  selectedAirport: AirportModel;
  airportID: number
  icao: string;
  customerGUID: string;
  farTypeID: number;
  farType: string;
  route: string;
  selectedTab: string = "handling";
  points: any = [];
  point: any;
  map: atlas.Map;
  nearbyMap: atlas.Map;
  handlerGUID: string;
  ssnGUID: string;
  vendorDetails: VendorModel;
  relatedVendorDetails: VendorModel;
  vendorHours: VendorHoursModel[];
  hoursData: HoursObjModel[];
  briefType: string = "client";
  nearbyAirports: AirportModel[];
  errMsg: string = "";
  latLongs: MapPointsModel[] = [];
  radius: number = 50;
  distance: number = 150;
  isPreferred: boolean = true;
  countryDetails: CountryModel;
  countryNotes: string;
  landingPermitNotes: string="";
  landingRequiredDocuments: CountryContentDocModel[] = [];
  landingPermitValidity: string = "";
  landingPermitLeadTime: string = "";
  landingPermitDueDate: string = "";
  showValidity: boolean = false;
  showLeadTime: boolean = false;
  shoowDueDate: boolean = false;
  showHandler: boolean = true;
  countryPermitNotRequired: boolean = true;
  displayedColumn: string[] = ['preferred', 'airport', 'distance', 'airport_of_entry', 'restrictions', 'slots', 'ppr', 'landing_permit'];
  callFrom: string;
  uniqueId = uuidv4();
  isMinimized: boolean = false;
  customerName: string = "";
  isLoadingVendor: boolean = false;
  isLoadingAirport: boolean = false;
  isLoadingNearby: boolean = false;
  isLoadingDoc: boolean = false;

  constructor(private _dialogRef: MatDialogRef<AirportBriefComponent>, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialog: MatDialog,private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService, private readonly _airportService: AirportService, private readonly _vendorService: VendorService, private sanitizer: DomSanitizer, private readonly _templateService:TemplateService) {
    this.selectedAirport = _data.selectedAirport,
      this.airportID = _data.airportID;
    this.icao = _data.icao;
    this.customerGUID = _data.customerGUID;
    this.farTypeID = _data.farTypeID;
    this.farType = _data.farType;
    this.route = _data.route;
    this.handlerGUID = _data.handlerGUID;
    this.ssnGUID = _data.ssnGUID;
    this.callFrom = _data.callFrom;
    if (_data.customerName != null && _data.customerName != undefined)
    this.customerName = _data.customerName;
  }
    ngAfterViewInit(): void {
      this._authService.updateAccessTime();
      this.getData();
  }

  getData() {   
    this.getAirportData();
    if (this.callFrom == 'planner') {
      if (this.handlerGUID != null && this.handlerGUID != undefined)
        this.getVendorData();
      else {
        this.selectedTab = 'notes';
        this.showHandler = false;
      }
    }
    this.getNearbyAirports();
    this.getCountryNotes();
  }

  getAirportData() {
    this.isLoadingAirport = true;
    this._airportService.getAirportDataByAirportID<ResponseModel<AirportModel>>(this.airportID, this.customerGUID, this.farTypeID, false).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.isLoadingAirport = false;
          this.selectedAirport = response.model;
          if (this.callFrom == 'alternate') {
            this.handlerGUID = this.selectedAirport.handler?.vendorGUID;
            this.ssnGUID = this.selectedAirport.ssn?.vendorGUID;
            if (this.handlerGUID != null && this.handlerGUID != undefined)
              this.getVendorData();
          }
          if (this.handlerGUID == null || this.handlerGUID == undefined) {
            this.showHandler = false;
          }
          else {
            this.showHandler = true;
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          this.isLoadingAirport = false;
        }
        this.createMap();
        //  this.showSpin = false;
      }
    });
  }

  getVendorData() {
    this.isLoadingVendor = true;
    if (this.handlerGUID != null && this.handlerGUID != undefined) {
      this._vendorService.getVendorDetailsByVendorID<ResponseModel<VendorModel>>(this.handlerGUID).subscribe(response => {
        if (response != null) {         
          if (response.code == "200" && response.message == "") {            
            this.vendorDetails = response.model;
            if (this.vendorDetails != null) {
              this.vendorHours = this.vendorDetails.vendorHours;
              if (this.vendorHours != null) {
                this.vendorHours.forEach(h => {
                  this.initHoursData();
                  if (!h.is24Hours) {
                    let sameHours = true;
                    let openTime = h.sun_Open;
                    let closeTime = h.sun_Close;
                    let utcLocal = "Local";
                    if (h.isUTC)
                      utcLocal = "UTC";
                    closeTime += ' ' + utcLocal;
                    this.hoursData.forEach(v => {
                      let utcLocal = "Local";
                      if (h.isUTC)
                        utcLocal = "UTC";
                      switch (v.dayOfWeek) {
                        case "Sunday":
                          if (h.is24Hours || h.sunClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.sun_Open;
                          v.closeTime = h.sun_Close + ' ' + utcLocal;
                          v.isClosed = h.sunClosed;
                          v.hasHours = h.hasSun;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Monday":
                          if (h.is24Hours || h.monClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.mon_Open;
                          v.closeTime = h.mon_Close + ' ' + utcLocal;
                          v.isClosed = h.monClosed;
                          v.hasHours = h.hasMon;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Tuesday":
                          if (h.is24Hours || h.tueClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.tue_Open;
                          v.closeTime = h.tue_Close + ' ' + utcLocal;
                          v.isClosed = h.tueClosed;
                          v.hasHours = h.hasTue;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Wednesday":
                          if (h.is24Hours || h.wedClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.wed_Open;
                          v.closeTime = h.wed_Close + ' ' + utcLocal;
                          v.isClosed = h.wedClosed;
                          v.hasHours = h.hasWed;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Thursday":
                          if (h.is24Hours || h.thuClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.thu_Open;
                          v.closeTime = h.thu_Close + ' ' + utcLocal;
                          v.isClosed = h.thuClosed;
                          v.hasHours = h.hasThu;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Friday":
                          if (h.is24Hours || h.friClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.fri_Open;
                          v.closeTime = h.fri_Close + ' ' + utcLocal;
                          v.isClosed = h.friClosed;
                          v.hasHours = h.hasFri;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Saturday":
                          if (h.is24Hours || h.satClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.sat_Open;
                          v.closeTime = h.sat_Close + ' ' + utcLocal;
                          v.isClosed = h.satClosed;
                          v.hasHours = h.hasSat;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                      }
                      if (openTime != v.openTime || closeTime != v.closeTime)
                        sameHours = false;
                    });
                    h.sameHours = sameHours;
                    h.openTime = openTime;
                    h.closeTime = closeTime;
                    h.vendorDailyHours = this.hoursData.filter(x => x.hasHours == true);
                  }
                });
                this.isLoadingVendor = false;
              }

              if (this.vendorDetails.relatedVendors != null && this.ssnGUID != "" && this.ssnGUID != null && this.ssnGUID != undefined) {
                this.relatedVendorDetails = this.vendorDetails.relatedVendors.find(x => x.vendorGUID == this.ssnGUID);

              }
            }
          }
          else {
            if (response.code == "401")
              this._authService.signOut();
            this.isLoadingVendor = false;
          }
        }

      }

      );
    }
  }

  initHoursData() {
    this.hoursData = [
      { dayOfWeek: "Sunday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sun_Open", closeTimeObjName: "sun_Close", closedObjName: "sunClosed", hasHours: false },
      { dayOfWeek: "Monday", abbreviation: "M", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "mon_Open", closeTimeObjName: "mon_Close", closedObjName: "monClosed", hasHours: false },
      { dayOfWeek: "Tuesday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "tue_Open", closeTimeObjName: "tue_Close", closedObjName: "tueClosed", hasHours: false },
      { dayOfWeek: "Wednesday", abbreviation: "W", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "wed_Open", closeTimeObjName: "wed_Close", closedObjName: "wedClosed", hasHours: false },
      { dayOfWeek: "Thursday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "thu_Open", closeTimeObjName: "thu_Close", closedObjName: "thuClosed", hasHours: false },
      { dayOfWeek: "Friday", abbreviation: "F", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "fri_Open", closeTimeObjName: "fri_Close", closedObjName: "friClosed", hasHours: false },
      { dayOfWeek: "Saturday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sat_Open", closeTimeObjName: "sat_Close", closedObjName: "satClosed", hasHours: false }
    ]
  }

  getNearbyAirports() {
    this.isLoadingNearby = true;
    let request = new AirportModel();
    request.airportID = this.airportID;
    request.countryId = 0;   
    request.distance = this.distance;
    request.isPreferred=this.isPreferred
    request.farTypeID = this.farTypeID;
    this.latLongs = [];
    this._airportService.getNearbyAirportList<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.nearbyAirports = response.model;
          let latLong = new MapPointsModel();
          latLong.latitude = this.selectedAirport.latitude;
          latLong.longitude = this.selectedAirport.longitude;
          latLong.title = this.selectedAirport.icao;
          latLong.isMainPoint = true;          
          this.latLongs.push(latLong);
          this.nearbyAirports.forEach(x => {
            let latLong = new MapPointsModel();
            latLong.latitude = x.latitude;
            latLong.longitude = x.longitude;
            latLong.title = x.icao;
            latLong.isMainPoint = false;
            latLong.airport = x;
            latLong.isPreferred = x.isPreferredAlternate;
            this.latLongs.push(latLong);
          });
          this.isLoadingNearby = false;
          if (this.selectedTab=="alternates")
            this.createNearbyMap();
        }
        else {
          if (response.code == "401")
            this._authService.logout();
          else
            this.errMsg = "An error occurred.";
          this.isLoadingNearby = false;
        }
      }
    });
  }

  openInNew() {
    this._dialogRef.close();
    window.open('trip-planner/airport-brief',"_blank");
    
  }
  changeTab() { 
    if (this.selectedTab == 'handling' || this.selectedTab == 'details')
      this.createMap();
    if (this.selectedTab == 'alternates' && this.nearbyAirports)
      this.createNearbyMap();
  }
  createMap() {
    if (this.map)
      this.map.dispose();
    this.map = new atlas.Map('brief-map' + this.airportID.toString(), {

      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      self.map.controls.add([
        new atlas.control.ZoomControl()
      ], {
        position: atlas.ControlPosition.TopRight
      });

      

      dataSource.add(new atlas.data.Feature(new atlas.data.Point([self.selectedAirport.longitude, self.selectedAirport.latitude]), {
        title: self.selectedAirport.icao,
        color: '#cc0000'
        }));
      self.points.push([self.selectedAirport.longitude, self.selectedAirport.latitude]);

      var bubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
        radius: 4,
        strokeColor: ['get', 'color'],
        strokeWidth: 6,
        color: "white",
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      });
      self.map.layers.add(bubbleLayer);

      //Add a layer for rendering point data.
      var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
        iconOptions: {
          //Hide the icon image.
          image: "none"
        },

        textOptions: {
          textField: ['get', 'title'],
          offset: [0, -1],
          allowOverlap: true,
          size: 16
        },
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      })
      self.map.layers.add(symbolLayer);


        self.map.setCamera({
          center: [self.selectedAirport.longitude, self.selectedAirport.latitude],
          zoom: 12
        });
    

    });

  }

  createNearbyMap() {
    const container = document.getElementById('nearby-map' + this.airportID.toString())
    if (container) {
   
    if (this.nearbyMap)
      this.nearbyMap.dispose();
    this.nearbyMap = new atlas.Map('nearby-map' + this.airportID.toString(), {

      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.nearbyMap.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.nearbyMap.sources.add(dataSource);
      self.nearbyMap.controls.add([
        new atlas.control.ZoomControl()
      ], {
        position: atlas.ControlPosition.TopRight
      });

      //Create a circle
      if (self.distance <= 100)
        self.radius = 25;
      if (self.distance > 100 && self.distance <= 200)
        self.radius = 50;
      if (self.distance > 200 && self.distance <= 500)
        self.radius = 100;
      if (self.distance > 500)
        self.radius = 150;
      if (self.latLongs.length > 1) {
        let maxRadius = self.distance * 1852;
        let increment = self.radius * 926;
        for (let i = increment; i <= maxRadius; i = i + increment) {
          var data = new atlas.data.Feature(new atlas.data.Point([self.latLongs[0].longitude, self.latLongs[0].latitude]), {
            title: i / 926 / 2 + " NM",
            display: "radius",
            subType: "Circle",
            radius: i,
            color: "transparent",
            strokecolor: "transparent",
            offset: [0, -.5]
          });
          let r = i / 926 / 2;
          if (r<25 || r <= self.nearbyAirports[self.nearbyAirports.length-1].distanceFromAirport+(self.radius/2))
          dataSource.add(data);

          //Create a polygon layer to render the filled in area of the polygon.
          var polygonLayer = new atlas.layer.PolygonLayer(dataSource, 'myPolygonLayer', {
            fillColor: 'transparent',
            filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
          });

          //Create a line layer for greater control of rendering the outline of the polygon.
          var lineLayer = new atlas.layer.LineLayer(dataSource, 'myLineLayer', {
            strokeColor: 'blue',
            strokeWidth: 1.5
          });

          var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
            iconOptions: {
              image: 'none'
            },
            textOptions: {
              textField: ['get', 'label']
            },
            filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]
          })
          /*Create and add a polygon layer to render the polygon to the map*/
          self.nearbyMap.layers.add([polygonLayer, lineLayer, symbolLayer]);
        }


      }
      var labels = calculatePolygonLabels(dataSource.getShapes(), 'title');
      dataSource.add(labels);
      self.latLongs.forEach((v, i) => {
        dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
          title: v.title,
          strokecolor: v.isMainPoint ? '#cc0000' : v.isPreferred ? '#ffd800': 'DodgerBlue',
          color:"white",
          display: "dot",
          offset: [0, -1],
          airport: v.airport
        }));
        self.points.push([v.longitude, v.latitude]);


      });
      var bubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
        radius: 4,
        strokeColor: ['get', 'strokecolor'],
        strokeWidth: 6,
        color: ['get', 'color'],
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      });
      self.nearbyMap.layers.add(bubbleLayer);


      self.nearbyMap.events.add('mouseover', bubbleLayer, function (e) {
        var properties;
        if (e.shapes[0] instanceof atlas.Shape) {
          properties = e.shapes[0].getProperties();
          if (properties.airport !=null && properties.airport!=undefined)
            self.nearbyMap.getCanvasContainer().style.cursor = 'pointer';
        }
      });

      self.nearbyMap.events.add('mouseleave', bubbleLayer, function () {
        self.nearbyMap.getCanvasContainer().style.cursor = 'grab';
      });

      self.nearbyMap.events.add('click', bubbleLayer, function (e) {
        var properties;
        var airport;
        if (e.shapes[0] instanceof atlas.Shape) {
          properties = e.shapes[0].getProperties();

          //If the shape is a point feature, show the popup at the points coordinate.
          if (e.shapes[0].getType() === 'Point') {
            airport = properties.airport;
          }
        } else {
          properties = e.shapes[0].properties;
          //If the shape is a point feature, show the popup at the points coordinate.
          if (e.shapes[0].type === 'Point') {
            airport = properties.airport;
          }
        }
        if (airport != null && airport != undefined)
          self.openAirportBrief(airport);
      });



      //Add a layer for rendering point data.
      var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
        iconOptions: {
          //Hide the icon image.
          image: "none"
        },

        textOptions: {
          textField: ['get', 'title'],
          offset: ['get', 'offset'],
          allowOverlap: true,
          size: 16
        },
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      })
      self.nearbyMap.layers.add(symbolLayer);
      
     
      var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

      if (self.latLongs.length == 1) {

        self.nearbyMap.setCamera({
          center: [self.latLongs[0].longitude, self.latLongs[0].latitude],
          zoom: 12
        });
      }
      else {
        self.nearbyMap.setCamera({
          bounds: bbox,
          padding: 40
        });
      }

    });
    }
  }

  getFlag(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/svg+xml;base64,${image}`
    );
  }


  openMapComponent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80em";
    dialogConfig.height = "40em";

    let latLongs: MapPointsModel[] = [];
    if (this.selectedTab == "alternates")
      latLongs = this.latLongs;
    else {
      let x = new MapPointsModel();
      x.latitude = this.selectedAirport.latitude;
      x.longitude = this.selectedAirport.longitude;
      x.title = this.selectedAirport.icao;
      x.isMainPoint = true;
      latLongs.push(x);
    }

    dialogConfig.data = {
      latLongs: latLongs,
      distance: this.distance,
      radius: this.radius,
      maxDistance: this.nearbyAirports[this.nearbyAirports.length - 1].distanceFromAirport,
      from: "details"
    };

    this._dialog.open(AirportMapComponent, dialogConfig);

  }

  getCountryNotes() {
    this._commonService.getCountryDetailsByCountryId<ResponseModel<CountryModel>>(this.selectedAirport.countryId).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.countryDetails = response.model;
          this.countryNotes = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == 15 && x.countryContentCategory.toLowerCase() == 'notes')[0]?.countryContentDescription.trim();
          this.countryPermitNotRequired = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == (this.farTypeID == 1 ? 3 : 4) && x.countryContentCategory.toLowerCase() == (this.farTypeID == 1 ? 'landing - private' : 'landing - charter'))[0]?.countryContentDescription.trim().toLowerCase()=='no';
          this.landingPermitNotes = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == (this.farTypeID == 1 ? 3 : 4) && x.countryContentCategory.toLowerCase() == 'notes')[0]?.countryContentDescription.trim();
          this.landingRequiredDocuments = this.countryDetails.countryContentSegmentDocs?.filter(x => x.countryContentSegmentId == (this.farTypeID == 1 ? 3 : 4));
          this.landingPermitValidity = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == (this.farTypeID == 1 ? 3 : 4) && x.countryContentCategory.toLowerCase() == 'validity time')[0]?.countryContentDescription.trim();
          this.landingPermitLeadTime = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == (this.farTypeID == 1 ? 3 : 4) && x.countryContentCategory.toLowerCase() == 'lead time')[0]?.countryContentDescription.trim();
          this.landingPermitDueDate = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == (this.farTypeID == 1 ? 3 : 4) && x.countryContentCategory.toLowerCase() == 'due date')[0]?.countryContentDescription.trim();
          if (this.landingPermitValidity != '' && this.landingPermitValidity != null && this.landingPermitValidity.toLowerCase().indexOf("see note") == -1)
            this.showValidity = true;
          if (this.landingPermitLeadTime != '' && this.landingPermitLeadTime != null && this.landingPermitLeadTime.toLowerCase().indexOf("see note") == -1)
            this.showLeadTime = true;
          if (this.landingPermitDueDate != '' && this.landingPermitDueDate != null && this.landingPermitDueDate.toLowerCase().indexOf("see note") == -1)
            this.shoowDueDate = true;
        }

      }
    });
  }

  openAirportBrief(airport: AirportModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";

    dialogConfig.data = {
      selectedAirport: airport,
      airportID: airport.airportID,
      icao: airport.icao,
      customerGUID: this.customerGUID,
      farTypeID: this.farTypeID,
      farType: this.farType,
      route: '',
      handlerGUID: airport.handler?.vendorGUID,
      ssnGUID: airport.ssn?.vendorGUID,
      callFrom:'alternate'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);
  }

  openCountryDetails() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.position = { right: "100px", top: "40px" };

    dialogConfig.data = { countryID: this.selectedAirport.countryId };
   
   this._dialog.open(CountryDetailsComponent, dialogConfig);
   
  }

  minimizeDialog() {
    this.isMinimized = true;

    if (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-pane') != undefined &&
      document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-pane') != null)
      (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-pane') as HTMLElement).style.transform = 'none'
  }

  maximizeDialog() {
    this.isMinimized = false;
  }

  downloadReport() {
    this.isLoadingDoc = true;
    this._templateService.generateTripPlannerBrief('pdf', 'internal', this.vendorDetails, this.relatedVendorDetails, this.vendorHours, this.customerName,this.farType, this.selectedAirport,this.nearbyAirports).subscribe(r => {
      if (r.remarks != "") {
        this.errMsg = r.remarks;
      }
      this.isLoadingDoc = false;

    });
  }
}
function calculatePolygonLabels(shapes, labelPropertyName) {
  var labels = [];

  for (var i = 0; i < shapes.length; i++) {

    var circlePolygon = shapes[i].circlePolygon;

    // Get the coordinates of the polygon edge
    if (circlePolygon != undefined) {
      var edgeCoordinates = circlePolygon.geometry.coordinates[0][0];

      var prop = {};
      prop[labelPropertyName] = shapes[i].circlePolygon.properties[labelPropertyName];
      prop["display"] = shapes[i].circlePolygon.properties["display"];
      prop["color"] = shapes[i].circlePolygon.properties["color"];
      prop["strokecolor"] = shapes[i].circlePolygon.properties["strokecolor"];
      prop["offset"] = shapes[i].circlePolygon.properties["offset"];
      labels.push(new atlas.data.Feature(new atlas.data.Point(edgeCoordinates), prop));

    }
  }

  return labels;
}
