export class TripMessageRequest {
  pageSize: number;
  pageIndex: number;
  customerGUID: string;
  tripCodeGUID: string;
  groundstopGuidList: string;
  serviceTypeIdList: string;
  hidePastLegs: boolean;
  showActiveLegs: boolean;
  showClientOnly: boolean;
  showTripOnly: boolean;
  isInbound: string;
  showDisabledTasks: boolean;
  searchFrom:string;
  searchSubject:string;
  searchTo: string;
  searchAll: string;
  groundStopTaskGUID: string;
  showBothTripAndTask: boolean;
  searchAssigned: string;
}
