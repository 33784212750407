import { Component, Inject, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})

export class AlertDialogComponent implements OnInit {
  dialogTitle: string;
  message: string;
  buttonText: string;

  constructor(private _dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any) {
    this.dialogTitle = _data.dialogTitle;
    this.message = _data.message;
    this.buttonText = _data.buttonText;
    if (this.dialogTitle == "" || this.dialogTitle == null || this.dialogTitle == undefined) {
      this.dialogTitle = "";
    }
    if (_data.message == "") {
      this.message = "Your information has been submitted successfully.";
    }
    if (this.buttonText == "" || this.buttonText == null || this.buttonText == undefined) {
      this.buttonText= "Continue";
    }
    _dialogRef.disableClose = true;
  }

  ngOnInit() {

  }

  close() {
    this._dialogRef.close();
  }
}
