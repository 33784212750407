<div *ngIf="upPII" style="background-color:#f8f8f8;min-height:28em;" >
<!--<div style="background-color:#f8f8f8;">-->
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div >
      <i class="material-icons-outlined" style="font-size: xx-large;cursor: pointer; color: gray">airline_stops</i>
    </div>
    <div style="margin-top:0.3125em;"><h5>Manage <!--Business--> Stops</h5></div>
  </div>
  <div>
    <form *ngIf="ground_stop_editor_form" [formGroup]="ground_stop_editor_form">
      <div style="margin-top:-0.5em; padding-left: 1em; padding-right:1em">
        <div style="display: flex; padding-left:1em; background-color: white;height: 5.75em ">
          <div style="width: 26em">
            <div style="margin-left: 0em;margin-top:0em;">
              <label for="route" style="font-size:small">Add individual Business Stops(ICAO / IATA):</label>
              <div style="font-size:x-small; margin-top:-1em; white-space:pre-wrap">allow comma, dash, blank space and new line between airports</div>
              <div style="display:flex">
                <div style="margin-left: 0em; margin-top:0.2em">
                  <input type="text" formControlName="route" #routeRef class="form-control" [ngClass]="{ 'is-invalid': (submitted || validatedAirport) && f.route.errors }" style="width:20em; height: 1.625em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd" />
                </div>
                <div style="margin-top:0em; margin-left:0.5em">
                  <button type="button" mat-button class="btn-high" *ngIf="!lockdownTrip" [hidden]="f.route.value=='' || f.route.value.trim()==''" [disabled]="loadingAirport" (click)="clickValidateAirport()" style="padding-left:0.5em !important; padding-right:0.5em !important"><span *ngIf="loadingAirport" class="spinner-border spinner-border-sm mr-1"></span>Add Business Stops</button>&nbsp;
                </div>
              </div>
              <div *ngIf="(submitted || validatedAirport) && f.route.errors" class="invalid-feedback">
                <div *ngIf="f.route.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:8em;margin-top:0.25em">route is required</div>
                <div *ngIf="f.route.errors.pattern" style="background-color:#f9d2de; padding-left:0.25em; width:14em;margin-top:0.25em">Invalid characters for airport</div>
              </div>
            </div>
            <!--<div *ngIf="errMsg!='' && f.route.value!='' && f.route.value.trim()!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
      <div *ngIf="duplicateIATAErrMsg!='' && f.route.value!='' && f.route.value.trim()!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{duplicateIATAErrMsg}}</label></div>
      <div *ngIf="errMsg2!=''" class="d-block" style="margin:0; display:flex;margin-top:0.0em; background-color:#f9d2de; padding-left:0.25em; width:22em; white-space:pre-wrap"><label style="color:black; font-size: small">{{errMsg2}}</label></div>-->
          </div>
          <div style="width:0.5em; background-color:#f8f8f8"></div>
          <div style="padding-left:1em">
            <div style="margin-top:0em ">
              <label style="font-size:small">Client&nbsp;</label>
              <div style="margin-top:-0.75em;font-weight: bold;font-size:small">
                {{client_name}}
              </div>
            </div>
            <div style="display: flex; margin-top:-0.25em">
              <div style="margin-right: 1em">
                <label style="font-size:small">Registration&nbsp;</label>
                <div style="margin-top:-0.5em;font-weight: bold;font-size:small">
                  {{registration}}
                </div>
              </div>
              <div style="margin-right: 1em">
                <label style="font-size:small">Trip Code&nbsp;</label>
                <div style="margin-top:-0.5em;font-weight: bold;font-size:small">
                  {{trip_code}}
                </div>
              </div>
              <div style="margin-right: 1em">
                <label style="font-size:small">Type&nbsp;</label>
                <div style="margin-top:-0.5em;font-weight: bold;font-size:small">
                  {{trip_status}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="errMsg!='' && f.route.value!='' && f.route.value.trim()!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
        <div *ngIf="duplicateIATAErrMsg!='' && f.route.value!='' && f.route.value.trim()!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{duplicateIATAErrMsg}}</label></div>
        <div *ngIf="errMsg2!=''" class="d-block" style="margin:0; display:flex;margin-top:0.0em; background-color:#f9d2de; padding-left:0.25em; width:22em; white-space:pre-wrap"><label style="color:black; font-size: small">{{errMsg2}}</label></div>
        <div style="margin-top:0.5em; min-height: 23em;">
          <div style="padding-left: 0em; padding-top:0em; padding-right:0em">
            <div style="height: 26em; overflow-y: auto;overflow-x:hidden;">
              <table mat-table *ngIf="groundStopList" [dataSource]="groundStopList" class="example-container2 mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="airport">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap">
                      Business Stops
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">{{element.icaO_IATA}}</td>
                  </ng-container>
                </tr>
                <tr>
                  <ng-container matColumnDef="gsType">
                    <th mat-header-cell *matHeaderCellDef>
                      Status
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                      <div style="margin-top:0em;">
                        <mat-select panelClass="custom-mat-select-panel3" disableOptionCentering [disabled]="element.depReadOnly && element.arrReadOnly" style="font-size: small; text-align: left; padding-left:0.25em; border: solid lightgray 0.0625em !important; border-radius:0.25em; width:7.5em; height:1.5em" [style.background-color]="element.groundStopTypeColor==''? '#eaf0fd':element.groundStopTypeColor" [(ngModel)]="element.groundStopTypeGUID" [ngModelOptions]="{standalone: true}" (selectionChange)="gsTypeSelectOnChange($event, element)">
                          <mat-option *ngFor="let gst of gsTypeList" [value]="gst.groundStopTypeGUID" style="height: 1.5em !important; " [style.background-color]="gst.colorCode==''? 'white':gst.colorCode">{{gst.groundStopTypeDescription}}</mat-option>
                        </mat-select>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="techStop">
                    <th mat-header-cell *matHeaderCellDef>
                      Tech Stop
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                      <div style="margin-top:0em;">
                        <mat-select disableOptionCentering [disabled]="(element.depReadOnly && element.arrReadOnly) || element.cancelled" [style.background-color]="(element.depReadOnly && element.arrReadOnly) || element.cancelled? '': '#eaf0fd' " style="font-size: small; text-align: left; padding-left:0.25em; border: solid lightgray 0.0625em !important; border-radius:0.25em; width:4.5em; height:1.5em" [(ngModel)]="element.isTechStopNumber" [ngModelOptions]="{standalone: true}">
                          <mat-option [value]="1" style="height: 1.5em !important; ">Yes</mat-option>
                          <mat-option [value]="0" style="height: 1.5em !important; ">No</mat-option>
                        </mat-select>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="farType">
                    <th mat-header-cell *matHeaderCellDef>
                      FAR Type
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index" style="line-height: 1.5625em;">
                      <div style="margin-top:0em;">
                        <mat-select disableOptionCentering [disabled]="(element.depReadOnly && element.arrReadOnly) || element.cancelled" [style.background-color]="(element.depReadOnly && element.arrReadOnly) || element.cancelled? '': '#eaf0fd' " style="font-size: small; text-align: left; padding-left:0.25em; border: solid lightgray 0.0625em !important; border-radius:0.25em; width:6em; height:1.5em" [(ngModel)]="element.farTypeID" [ngModelOptions]="{standalone: true}" (selectionChange)="farTypeSelectOnChange($event, element)">
                          <mat-option [value]="0" style="height: 1.5em !important; " selected></mat-option>
                          <mat-option *ngFor="let f of farTypeList" [value]="f.farTypeID" style="height: 1.5em !important; ">{{f.farTypeDescription}}</mat-option>
                        </mat-select>
                      </div>
                      <div style="display:flex">
                        <div style="width:6.5em">
                          <mat-error *ngIf="(element.farTypeID==0 && element.fARTypeID==0) && i<(groundStopList.length-1) && !element.cancelled && (!element.depReadOnly || !element.arrReadOnly)">
                            <div style="margin-left:0em; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.125em; width:7em; font-size: x-small; color: black;text-align:left;">FAR is required.</div>
                          </mat-error>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="callSign">
                    <th mat-header-cell *matHeaderCellDef>
                      Call Sign
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                      <div style="margin-top:0em;">
                        <input type="text" matInput maxlength="20" [readonly]="(element.depReadOnly && element.arrReadOnly) || element.cancelled" [value]="element.callSign" [(ngModel)]="element.callSign" [ngModelOptions]="{standalone: true}" class="form-control" [style.background-color]="(element.depReadOnly && element.arrReadOnly) || element.cancelled? '': '#eaf0fd' " style="width:6em;height:1.5em; font-size: small;padding:0.25em; border-radius:0.25em" />
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="arrivalDate_Time">
                    <th mat-header-cell *matHeaderCellDef>
                      Arrival
                      <div style="display:flex;"><div style="margin-left:2.5em;font-size: small; font-weight:500; white-space:nowrap">Date/Time (UTC)</div><div style="font-size: small; font-weight:500; margin-left: 3em; margin-right:0.25em">Pets</div><div style="font-size: small; font-weight:500">Weapons</div></div>
                    </th>
                    <td mat-cell *matCellDef="let element; let rowIdx = index;" style="line-height: 1.5625em;white-space:nowrap">
                      <div style="display:flex;margin-top:0em; margin-left:0.5em; padding:0em">
                        <div style="display:flex;margin-top: 0.5em">
                          <input type="text" matInput [matDatepicker]="arrdatepicker" placeholder="mm/dd/yyyy" [readonly]="element.arrReadOnly || element.cancelled" [value]="element.arrivalDateUTC" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidArrDate,  'is-valid1': element.isValidArrDate }"
                                 [(ngModel)]="element.arrivalDateUTC" [ngModelOptions]="{standalone: true}" style="padding: 0.25em; width: 6em; height: 1.4em; font-size: small; border-radius:0.25em" maxlength="10" [style.background-color]="element.arrReadOnly || element.cancelled? '': '#eaf0fd' " (dateChange)="dateOnchange('A', $event, element)" (dateInput)="dateInputOnchange('A', $event, element)" />
                          <mat-datepicker-toggle matSuffix [for]="arrdatepicker" *ngIf="element.arrReadOnly==false" style="margin-top:-1em; margin-left:-0.85em; padding:0em;height:1.5em"></mat-datepicker-toggle>
                          <mat-datepicker #arrdatepicker [startAt]="element.arrStartAt"></mat-datepicker>
                          <div style="width:2.2em" *ngIf="element.arrReadOnly==true"></div>
                        </div>
                        <div style="margin-right:0.5em;margin-top: 0.5em">
                          <input type="text" matInput placeholder="hh:mm" maxlength="5" [readonly]="element.arrReadOnly || element.cancelled" [value]="element.arrivalTimeUTC" [(ngModel)]="element.arrivalTimeUTC" [ngModelOptions]="{standalone: true}" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidArrTime,  'is-valid1': element.isValidArrTime }" [style.background-color]="element.arrReadOnly || element.cancelled? '': '#eaf0fd' " style="width:4em;height:1.4em; font-size: small;padding:0.25em; border-radius:0.25em" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('A', $event, element)" />
                        </div>
                        <div style="margin-top:0.5em; margin-right:1.25em">
                          <mat-checkbox [checked]="element.arrivalPetsOnBoard" [(ngModel)]="element.arrivalPetsOnBoard" [ngModelOptions]="{standalone: true}" [disabled]="element.arrReadOnly || element.cancelled"></mat-checkbox>
                        </div>
                        <div style="margin-top:0.5em;">
                          <mat-checkbox [checked]="element.arrivalWeaponsOnBoard" [(ngModel)]="element.arrivalWeaponsOnBoard" [ngModelOptions]="{standalone: true}" [disabled]="element.arrReadOnly || element.cancelled"></mat-checkbox>
                        </div>
                      </div>
                      <div style="display:flex">
                        <div style="width:6.5em">
                          <mat-error *ngIf="!element.isValidArrDate">
                            <div style="margin-left:2.5em; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black;text-align:center;">Invalid date.</div>
                          </mat-error>
                        </div>
                        <mat-error *ngIf="!element.isValidArrTime">
                          <div style="margin-left:4em; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black; text-align:center">Invalid time.</div>
                        </mat-error>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="departureDate_Time">
                    <th mat-header-cell *matHeaderCellDef>

                      Departure
                      <div style="display:flex;"><div style="margin-left:2.5em;font-size: small; font-weight:500; white-space:nowrap">Date/Time (UTC)</div><div style="font-size: small; font-weight:500; margin-left: 3em; margin-right:0.25em">Pets</div><div style="font-size: small; font-weight:500">Weapons</div></div>
                    </th>
                    <td mat-cell *matCellDef="let element; let rowIdx = index;" style="line-height: 1.5625em;">
                      <div style="display:flex;margin-top:0em; margin-left:0.5em">
                        <div style="display:flex;margin-top: 0.4em">
                          <input type="text" matInput [matDatepicker]="deptdatepicker" placeholder="mm/dd/yyyy" [readonly]="element.depReadOnly || element.cancelled" [value]="element.departureDateUTC" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidDeptDate,  'is-valid1': element.isValidDeptDate }"
                                 [(ngModel)]="element.departureDateUTC" [ngModelOptions]="{standalone: true}" style="padding: 0.25em; width: 6em; height: 1.4em; font-size: small; border-radius:0.25em" maxlength="10" [style.background-color]="element.depReadOnly || element.cancelled? '': '#eaf0fd' " (dateChange)="dateOnchange('D', $event, element)" (dateInput)="dateInputOnchange('D', $event, element)" />
                          <mat-datepicker-toggle matSuffix [for]="deptdatepicker" *ngIf="element.depReadOnly==false" style="margin-top: -1em; margin-left: -0.85em; height: 1.5em; padding:0em;"></mat-datepicker-toggle>
                          <mat-datepicker #deptdatepicker [startAt]="element.depStartAt"></mat-datepicker>
                          <div style="width:2.2em" *ngIf="element.depReadOnly==true"></div>
                        </div>
                        <div style="margin-right:0.5em;margin-top: 0.5em">
                          <input type="text" matInput placeholder="hh:mm" maxlength="5" [readonly]="element.depReadOnly || element.cancelled" [value]="element.departureTimeUTC" [(ngModel)]="element.departureTimeUTC" [ngModelOptions]="{standalone: true}" class="form-control" [ngClass]="{ 'is-invalid1': !element.isValidDeptTime,  'is-valid1': element.isValidDeptTime }" [style.background-color]="element.depReadOnly || element.cancelled? '': '#eaf0fd' " style="width:4em;height:1.4em; font-size: small;padding:0.25em; border-radius:0.25em" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('D', $event, element)" />
                        </div>
                        <div style="margin-top:0.5em; margin-right:1.25em">
                          <mat-checkbox [checked]="element.departurePetsOnBoard" [(ngModel)]="element.departurePetsOnBoard" [ngModelOptions]="{standalone: true}" [disabled]="element.depReadOnly || element.cancelled"><!--<div style="margin-left:-0.5em">Pets</div>--></mat-checkbox>
                        </div>
                        <div style="margin-top:0.5em;">
                          <mat-checkbox [checked]="element.departureWeaponsOnBoard" [(ngModel)]="element.departureWeaponsOnBoard" [ngModelOptions]="{standalone: true}" [disabled]="element.depReadOnly || element.cancelled"><!--<div style="margin-left:-0.5em">Weapons</div>--></mat-checkbox>
                        </div>
                      </div>
                      <div style="display:flex">
                        <div style="width:6.5em">
                          <mat-error *ngIf="!element.isValidDeptDate">
                            <div style="margin-left:2.5em; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black;text-align:center;">Invalid date.</div>
                          </mat-error>
                        </div>
                        <mat-error *ngIf="!element.isValidDeptTime">
                          <div style="margin-left:4em; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.125em; width:6em; font-size: x-small; color: black; text-align:center">Invalid time.</div>
                        </mat-error>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="services">
                    <th mat-header-cell *matHeaderCellDef>
                      Services
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                      <div style="display: flex;margin-left:0em">
                        <div *ngFor="let s of element.serviceClassTaskStatusList">
                          <div style="display:flex;margin-left:0.8em">
                            <div style="border-radius: 50%; width: 1.75em; height: 1.25em; border: black solid 0.0625em;" [style.background-color]="s.taskStatusBackgroundColor"></div>
                            <div style="font-size: medium; margin-left:-1.1em;margin-top:-0.1em" [style.color]="s.taskStatusFontColor" title="{{s.serviceClassDescription}}">{{s.serviceClassDescriptionAbbr}}</div>
                          </div>
                        </div>

                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="adjustSequence">
                    <th mat-header-cell *matHeaderCellDef>
                      Adjust<div style="margin-top:-0.5em; font-size:small;">Sequence</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">
                      <div style="display:flex; padding:0em; margin-left:0.5em;">
                        <div style="margin-top:0.5em; margin-left:0.25em;width:1.25em">
                          <i [hidden]="element.groundStopGUID!=''" class="material-icons" style="color:red; font-size: medium; cursor: pointer;" title="click to remove the ground stop" (click)="clickRemove($event, element)">cancel</i>
                          <span [hidden]="element.groundStopGUID==''">&nbsp;</span>
                        </div>
                        <div style="margin-top: 0.25em;margin-left:0.25em;width:1.25em">
                          <img [hidden]="!element.moveUp" src="../../assets/images/up.png" style="border:none; cursor:pointer;" title="click to move the ground stop up." (click)="clickMoveUp($event, element)" />
                          <span [hidden]="element.moveUp">&nbsp;</span>
                        </div>
                        <div style="margin-top: 0.25em;margin-left: 0.25em;width:1.25em">
                          <img [hidden]="!element.moveDown" src="../../assets/images/down.png" style="border:none; cursor:pointer;" title="click to move the ground stop downp." (click)="clickMoveDown($event, element)" />
                          <span [hidden]="element.moveDown">&nbsp;</span>
                        </div>

                      </div>
                    </td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
    <div style="margin-right:1em">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <button type="button" mat-button class="btn-high" *ngIf="groundStopList && groundStopList.length>0  && loading==false && showSuccessMsg==false && !lockdownTrip && !showSpin" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Submit</div></div></button>&nbsp;
      <button type="button" mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
    </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>


