import { MessageAttachmentModel } from "./message-attachment";
import { MessageEmbeddedImageModel } from "./message-embedded-image";
import { MessageFolderModel } from "./message-folder.model";
import { MessageLinkModel } from "./message-link";

export class MessageModel { 
  from: string;
  to: string[];
  subject: string;
  body: string;
  messageGUID: string;
  isInBound: boolean;
  sender: string;
  sendTo: string;
  sendCC: string;
  sendBCC: string;
  isFPCOps: boolean;
  isDraft: boolean;
  isSpam: boolean;
  messageLinkGUID: string;
  customerGUID: string;
  vendorGUID: string;
  tripCodeGUID: string;
  groundStopGUID: string;
  nextGroundStopGUID: string;
  groundStopTaskGUID: string;
  emailSent: boolean;
  errMsg: string;
  messageAttachmentList: MessageAttachmentModel[];
  serviceTypeID: number;
  bodyTxt: string;
  assignedTo: string;
  messageGUIDList: string;
  isPending: boolean;
  bodyInCKEditorFormat: string;
  messageFolderID: number;
  senderName: string;
  bodyPreview: string;
  loading: boolean;
  bodyHtml: string;
  sentDateTime: string;
  accountId: string;
  messageEmbeddedImageList: MessageEmbeddedImageModel[];
  messageLinkList: MessageLinkModel[];
  scheduleMessage: string;
  isActive: boolean;
  serviceName: string;
  arrivalDateTimePassed: boolean;
  linkDetailsToolTip: string;
  messageKeyID: string;
  actualSender: string;
  conversationID: string;
  outlookMessageID: string;
  messageType: string;
  canAutolink: boolean;
  draftDateTime:string;
  mainMessageGUID: string;
  isFlagged: boolean;
  isDelayed: boolean;
  receivedDateTime: string;
  tripCode: string;
  serviceTypeDescription: string;
  leg: string;
  replyToName: string;
  replyToEmail: string;
  customerName: string;
  attachmentCount: number;
  uploadCount: number;
  fromClientEmail: boolean;
}