
<div mat-dialog-content style="margin-top:-1em;padding-bottom:0;">

  <form novalidate *ngIf="compile_flight_brief_form" [formGroup]="compile_flight_brief_form">

    <div class="container" style="background-color:white;min-width:35em">
      <!--<div class="row">
    <div class="col">
      <mat-radio-group [(ngModel)]="format" style="display:flex;flex-direction:column;transform:scale(.8);margin-left:-4em" [ngModelOptions]="{standalone: true}" (change)="changeFormat($event)">
        <mat-radio-button value="word">Build in Word</mat-radio-button>
        <mat-radio-button value="pdf">Merge PDFs</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <hr *ngIf="format=='pdf'" />-->
      <div *ngIf="format=='pdf'" class="row">
        <div class="col d-flex flex-column">
          <mat-checkbox [(ngModel)]="includeCoverPage" [ngModelOptions]="{standalone: true}" value="true" style="font-size:small;">Add Cover Page</mat-checkbox>
          <!--<mat-checkbox [(ngModel)]="includeDomesticCoverPage" [ngModelOptions]="{standalone: true}" value="true" style="font-size:small;">Add Domestic Cover Page</mat-checkbox>
          <mat-checkbox [(ngModel)]="includeInternationalCoverPage" [ngModelOptions]="{standalone: true}" value="true" style="font-size:small;">Add International Cover Page</mat-checkbox>-->
        </div>
      </div>
      <!--<div *ngIf="includeCoverPage" class="row">
        <div class="col form-group">
          <label style="font-size:small">Cover Note</label>
          <textarea *ngIf="f" formControlName="cover_note" class="form-control inputBox" style="width:22em;height:8em;font-family:Courier New, Courier, monospace;font-size:small"></textarea>
        </div>
        <div class="col">
          <div>
            <div style="margin-top:3em;display:inline-flex">

              <label style="font-size:small">To:</label>
              <input class="form-control form-control-sm inputBox ml-1" formControlName="summary_to" style="width:21em" />

            </div>
            <div>
              <div class="d-inline-flex">
                <label style="font-size:small;margin-top:.25em">Address:</label>

                <mat-radio-group [(ngModel)]="addressType" [ngModelOptions]="{standalone: true}">
                  <mat-radio-button style="transform:scale(.75);font-size:medium;" value="Email">Email</mat-radio-button>
                  <mat-radio-button style="transform:scale(.75);font-size:medium;margin-left:-.75em" value="Fax">Fax</mat-radio-button>
                </mat-radio-group>
              </div>
              <div>
                <input class="form-control form-control-sm inputBox" formControlName="summary_address" style="width:22.5em;" />
              </div>
            </div>
          </div>
        </div>

      </div>-->

      <app-spin *ngIf="showSpin"></app-spin>


      <div *ngIf="flightBriefList && flightBriefList.length>0">
        <hr />
        <div class="row">
          <div style="font-size:small" class="col-8">
            <label style="font-size:small;font-weight:bold">Choose Brief</label>
          </div>
          <!--<div style="font-size:small" class="col-4">
        <label style="font-size:small;font-weight:bold">Sort Order</label>
      </div>-->
        </div>
        <div style="min-height: 6.5em;overflow-y: auto;overflow-x:hidden;">
          <table mat-table *ngIf="flightBriefList" [dataSource]="flightBriefList" class="example-container">
            <tr>
              <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef style="width:20%">
                  Task
                </th>
                <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-left:0.0625em solid lightgrey !important">{{element.documentType}}</td>
              </ng-container>
              <ng-container matColumnDef="documentName">
                <th mat-header-cell *matHeaderCellDef style="width:55%">
                  Document
                </th>
                <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;"><a style="color:blue; cursor:pointer; text-decoration:underline" (click)="clickPreviewDocument($event, element)">{{element.documentName}}</a>&nbsp;({{element.documentSizeInKB}}KB)</td>
              </ng-container>
              <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef style="width:10%;">
                  Select
                </th>
                <td mat-cell *matCellDef="let element; let i = index;" style="line-height: 1.5625em;">
                  <div style="margin-top:0.5em">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="checkDocumentChange($event, element, i)" [checked]="selection.isSelected(element)"></mat-checkbox>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="sortOrderNo">
                <th mat-header-cell *matHeaderCellDef style="width:15%;">
                  Sort Order
                </th>
                <td mat-cell *matCellDef="let element; let i = index;" style="line-height: 1.5625em;border-right:0.0625em solid lightgrey !important">
                  <input type="number" style="width:3em;margin-top:.5em" class="form-control form-control-sm inputBox" id="sortOrder{{i}}" [disabled]="!selection.isSelected(element)" (change)="setSortOrder($event, element)" value="{{element.sortOrderNo}}" />
                </td>
              </ng-container>

            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>

      </div>
      <div *ngIf="taskDocumentList && taskDocumentList.length>0">
        <hr />
        <div class="row">
          <div style="font-size:small" class="col-12">
            <label style="font-size:small;font-weight:bold">Included Documents</label>
            <mat-checkbox [(ngModel)]="excludeDocuments" [ngModelOptions]="{standalone: true}" value="true" style="font-size:small;margin-left:1em;">Exclude All Task Documents</mat-checkbox>
          </div>
          <!--<div style="font-size:small" class="col-4">
        <label style="font-size:small;font-weight:bold">Sort Order</label>
      </div>-->
        </div>
        <div *ngIf="!excludeDocuments" style="min-height: 6.5em;overflow-y: auto;overflow-x:hidden;">
          <table mat-table *ngIf="taskDocumentList" [dataSource]="taskDocumentList" class="example-container">
            <tr>
              <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef style="width:20%">
                  Task
                </th>
                <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-left:0.0625em solid lightgrey !important">{{element.documentType}}</td>
              </ng-container>
              <ng-container matColumnDef="documentName">
                <th mat-header-cell *matHeaderCellDef style="width:55%">
                  Document
                </th>
                <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;"><a style="color:blue; cursor:pointer; text-decoration:underline" (click)="clickPreviewDocument($event, element)">{{element.documentName}}</a>&nbsp;({{element.documentSizeInKB}}KB)</td>
              </ng-container>
              <!--<ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef style="width:10%;">
                  Select
                </th>
                <td mat-cell *matCellDef="let element; let i = index;" style="line-height: 1.5625em;">
                  <div style="margin-top:0.5em">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="checkDocumentChange($event, element, i)" [checked]="selection.isSelected(element)"></mat-checkbox>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="sortOrderNo">
                <th mat-header-cell *matHeaderCellDef style="width:15%;">
                  Sort Order
                </th>
                <td mat-cell *matCellDef="let element; let i = index;" style="line-height: 1.5625em;border-right:0.0625em solid lightgrey !important">
                  <input type="number" style="width:3em" class="form-control form-control-sm inputBox" id="sortOrder{{i}}" [disabled]="!selection.isSelected(element)" (change)="setSortOrder($event, element)" value="{{element.sortOrderNo}}" />
                </td>
              </ng-container>-->

            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn2"></tr>
          </table>
        </div>

      </div>
    </div>
  </form>
</div>

<div align="start" class="d-inline-flex">
  <button class="mt-2 btn-high" mat-button (click)="compileFlightBrief(format, false)"><span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>Merge PDF Brief</button>
  <button class="mt-2  ml-2 btn-high" mat-button (click)="compileFlightBrief(format, true)"><span *ngIf="isPreviewLoading" class="spinner-border spinner-border-sm mr-1"></span>Preview PDF Brief</button>
  <button class="mt-2 ml-2 btn-low" mat-button mat-dialog-close>Close</button>
  <div *ngIf="errMsg!=''" class="alert alert-danger small ml-2 mt-2 p-0" style="white-space:pre-wrap;width:max-content">{{errMsg}}</div>
</div>
