import { ContentDocumentModel } from "./content-document.model";

export class CompanyEventModel {
  companyEventID: number;
  customerGUID: string;
  isUTC: boolean;
  effectiveDate: string;
  expirationDate: string;
  validTimeStart: string;
  validTimeEnd: string;
  companyEventTitle: string;
  companyEventStory: string;
  companyEventStoryText: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  includeExpired: boolean;
  attachmentCount: number;
  contentDocuments: ContentDocumentModel[];
  displayDateStart: string;
  displayDateEnd: string;
  isDisplayOnly: boolean;
  documentWeblink: string;
}
