import { Component, Input, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html'//,
  //styleUrls: ['./spin.component.css']
})
export class WarningMessageComponent implements OnInit {
  @Input() warningMsg: string;

  ngOnInit() {

  }
}
