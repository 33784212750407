import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'; 
import { ResponseModel } from '../models/response.model';
import { MessageService } from '../services/message.service';
import { AuthenticateService } from '../services/authenticate.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MessageFolderModel } from '../models/message-folder.model';

@Component({
  selector: 'app-message-folder-edit-dialog',
  templateUrl: './message-folder-edit-dialog.component.html',
  styleUrls: ['./message-folder-edit-dialog.component.css'],
})

export class MessageFolderEditDialogComponent implements OnInit {

  loading = false;
  isModified: boolean = false; 
  message_folder_edit_form: UntypedFormGroup;
  messageFolderList: MessageFolderModel[];
  messageFolderID: number;
  messageFolderName: string;
  requiredMessageFolderList: MessageFolderModel[];

  folderExist: boolean; 
  showSuccessMsg: boolean = false;
  successMsg: string = "Folder Edited Succesfully. Please Refresh to view the changes"; 
  editFolderName: string;

  hasSpecialCharacters: boolean = false;
  warningMsg: string = "Can not Enter Charecters like ~!@#$%^&*()_+{}|:<>,.;'[]\\";
  showWarningMsg: boolean = true;
  errorMsg: string = "";
  showErrorMsg: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<MessageFolderEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _messageService: MessageService,
    private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder,
  ) {

    this.messageFolderList = _data.messageFolderList;
    this.messageFolderID = _data.messageFolderID;
  }

  
  ngOnInit() {
    this.requiredMessageFolderList = this.messageFolderList.filter(mf => mf.messageFolderID === this.messageFolderID);
    this.messageFolderName = this.requiredMessageFolderList[0].messageFolderName;
    this.editFolderName = '';
    this.showErrorMsg = false;
    this.errorMsg = "";
    this.showSuccessMsg = false;

  } 
  
  // initControls() {
  //   this.message_folder_edit_form = this._formBuilder.group({ 
  //   });
  // }

  // get f() { return this.message_folder_edit_form.controls; }

  updateFolderName(){ 
    if( !this.hasSpecialCharacters && this.editFolderName.length < 16 && this.editFolderName.trim() != ''){
      this._messageService.createOrUpdateMessageFolder<ResponseModel<boolean>>(this.editFolderName, this.messageFolderID).subscribe(result => {
        if (result.code = "200") {
          this.folderExist = result.model;
          
          this.errorMsg = this.folderExist ?   "Folder Already Exists" : this.errorMsg ;   
          if ( this.errorMsg.length>5){
            this.showErrorMsg = true;
            setTimeout(() => {
              this.showErrorMsg = false; 
            }, 2000);
          }

          if (!this.folderExist) {
            this.ngOnInit();
            this.editFolderName = '';
            this.folderExist = false;
            this.showSuccessMsg = true;
            this.isModified = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
              this.clickClose();
            }, 2000);
          }
        }
      });
    }
 
    this.errorMsg = this.hasSpecialCharacters ?  "Cannot Enter Special Characters" : this.errorMsg;
    this.errorMsg = this.editFolderName.length > 15 ?  "Folder Name is Too Large" : this.errorMsg;
    
    if ( this.errorMsg.length>5)
      this.showErrorMsg = true;
    setTimeout(() => {
      this.showErrorMsg = false; 
    }, 1000);
  }
 
  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  checkForSpecialCharacters() {
    const specialChars = "`~-=|'!@#$%^&*()_+{}[]:;<>,.?~\\/\"";// Define your list of special characters
    for (let i = 0; i < this.editFolderName.length; i++) {
      if (specialChars.includes(this.editFolderName[i])) {
        this.hasSpecialCharacters = true; // Special character found
        return;
      }
    }
    this.hasSpecialCharacters = false; // No special characters found
  }
 
 

}
