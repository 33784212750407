<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-1em" mat-dialog-title>{{title}}</div>
<app-spin *ngIf="showSpin"></app-spin>

<div style="font-size:small; background-color: white;
    padding: 1em;
    margin-top: -1em;
    min-width: 30em;
    max-height: 50em;
    width: 55em;
    height: 30em;overflow-y:auto">
  <div *ngIf="alertType=='expiredManifest'">
    <ng-template ngFor let-obj [ngForOf]="manifestLegs">
      <div class="font-weight-bold">
        <a routerLink="" (click)="openTrip(obj)">{{obj.tripCode}} / {{obj.departureICAO}}-{{obj.nextArrivalICAO}} / {{obj.departureDateTimeUTCText}}</a>
      </div>
      <ng-template *ngIf="upPII" ngFor let-person [ngForOf]="obj.departureManifest">
        <div *ngIf="person.expiredDocCount>0">
          {{person.firstName}} {{person.MiddleName}} {{person.lastName}}
        </div>
        <ng-template ngFor let-doc [ngForOf]="person.groundStopManifestDocumentList">
          <div *ngIf="doc.isExpiredForGroundStop">{{doc.documentTypeDescription}} - {{doc.documentNumber}} {{doc.isO2Letter}} {{doc.documentDOE}}</div>
        </ng-template>
      </ng-template>
      <ng-template *ngIf="upPII" ngFor let-person [ngForOf]="obj.arrivalManifest">
        <div *ngIf="person.expiredDocCount>0">
          {{person.firstName}} {{person.MiddleName}} {{person.lastName}}
        </div>
        <ng-template ngFor let-doc [ngForOf]="person.groundStopManifestDocumentList">
          <div *ngIf="doc.isExpiredForGroundStop">{{doc.documentTypeDescription}} - {{doc.documentNumber}} {{doc.isO2Letter}} {{doc.documentDOE}}</div>
        </ng-template>
      </ng-template>
    </ng-template>
  </div>
  <div *ngIf="alertType=='missingManifest'">
    <ng-template ngFor let-obj [ngForOf]="manifestLegs">
      <div class="font-weight-bold">
        <a routerLink="" (click)="openTrip(obj)">{{obj.tripCode}} / {{obj.departureICAO}}-{{obj.nextArrivalICAO}} / {{obj.departureDateTimeUTCText}}</a>
      </div>
      <ng-template *ngIf="upPII" ngFor let-person [ngForOf]="obj.departureManifest">
        <div *ngIf="person.hasRequiredDocument==false">
          {{person.firstName}} {{person.MiddleName}} {{person.lastName}}
        </div>
      </ng-template>
    </ng-template>
  </div>
  <div *ngIf="alertType=='task' || alertType=='onHold'">
    <ng-template ngFor let-obj [ngForOf]="manifestLegs">
      <div class="font-weight-bold">
        <a routerLink="" (click)="openTrip(obj)"> {{obj.tripCode}} / {{obj.departureICAO}}-{{obj.nextArrivalICAO}} / {{obj.departureDateTimeUTCText}}</a>
      </div>
      <ng-template ngFor let-task [ngForOf]="alertObject">
        <div *ngIf="obj.groundStopGUID==task.groundStopGUID">
          {{task.serviceTypeDescription}}
        </div>
      </ng-template>
      <div *ngIf="alertType=='onHold'" class="d-inline-flex">
        <div>
          <div class="ml-1" style="font-size:small;font-weight:500">Departure Tasks</div>
          <label class="ml-1" style="font-size:small" *ngIf="alertObject[0].departureGroundStopTaskList.length==0">None</label>
          <ng-template ngFor let-task [ngForOf]="alertObject[0].departureGroundStopTaskList">
            <div class="ml-1 flex-column">
              {{task.serviceTypeDescription}}
            </div>
          </ng-template>
        </div>
        <div>
          <div class="ml-4" style="font-size: small; font-weight: 500">Arrival Tasks</div>
          <label class="ml-4" style="font-size:small" *ngIf="alertObject[0].nextArrivalGroundStopTaskList.length==0">None</label>
          <ng-template ngFor let-task [ngForOf]="alertObject[0].nextArrivalGroundStopTaskList">
            <div class="ml-4 flex-column">
              {{task.serviceTypeDescription}}
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </div>
  <div *ngIf="alertType=='aircraft'">
    <ng-template ngFor let-obj [ngForOf]="alertList">
      <div class="ml-1 font-weight-bold">
        <a routerLink="" (click)="openAircraft(obj)">
          {{obj.categoryName}}
        </a>
      </div>
      <ng-template ngFor let-obj2 [ngForOf]="obj.alertObject">
        <div class="ml-1">
          {{obj2.documentName}} ({{obj2.documentTypeDescription}}) {{obj2.expirationDate}}
        </div>
      </ng-template>
      </ng-template>
</div>
  <div *ngIf="alertType=='customer'">
    <ng-template ngFor let-obj [ngForOf]="alertObject">
      <div>
        <a routerLink="" (click)="openCustomer()">{{obj.documentName}} ({{obj.documentTypeDescription}}) - {{obj.expirationDate}}</a>
      </div>
    </ng-template>
  </div>

  <div *ngIf="alertType=='missingCrew' || alertType=='missingPax'">
    <ng-template ngFor let-obj [ngForOf]="trip">
      <div style="font-weight:bold">
        <a routerLink="" (click)="openTrip(obj.legs[0])">{{obj.tripCode}}</a>
      </div>
      <ng-template ngFor let-leg [ngForOf]="obj.legs">
        <div>
          {{leg.departureICAO}}-{{leg.nextArrivalICAO}} / {{leg.departureDateTimeUTCText}}
        </div>
      </ng-template>
    </ng-template>
  </div>
  <!--<div *ngIf="alertType=='revisions'">
    <div>

      gs
      <div *ngFor="let gs of revisions">
        <div >
          <div style="display:flex; font-size:small">
            <div style="margin-right:1em">
              <div>Registration</div>
              <div style="font-weight: bold; margin-top:-0.25em">{{gs.registration}}</div>
            </div>
            <div style="margin-right:1em">
              <div>Business Stop</div>
              <div style="font-weight: bold; margin-top:-0.25em">{{gs.icao}}</div>
            </div>
            <div style="margin-right:1em" *ngIf="!gs.isFirstGroundStop">
              <div>Arrival ({{gs.icao}})</div>
              <div style="display:flex; margin-top:-0.25em" *ngIf="gs.arrivalDateTimeUTC!=''">
                <div style="font-weight: bold">{{gs.arrivalDateTimeUTC!=''?gs.arrivalDateTimeUTC+' Z':''}}</div><div style="font-weight:normal">&nbsp;/ {{gs.arrivalDateTimeLocal!=''?gs.arrivalDateTimeLocal+' L':''}}</div>
              </div>
            </div>
            <div style="margin-right:1em" *ngIf="!gs.isLastGroundStop">
              <div>Departure ({{gs.icao}})</div>
              <div style="display:flex; margin-top:-0.25em" *ngIf="gs.departureDateTimeUTC!=''"><div style="font-weight: bold">{{gs.departureDateTimeUTC!=''?gs.departureDateTimeUTC+' Z':''}}</div><div style="font-weight:normal">&nbsp;/ {{gs.departureDateTimeLocal!=''?gs.departureDateTimeLocal+' L':''}}</div></div>
            </div>
          </div>
          <div>
            <table mat-table *ngIf="gs.revisionList.length>0" [dataSource]="gs.revisionList" class="example-container">
              <tr>
                <ng-container matColumnDef="transactionDate">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important; border-right: 0.0625em solid lightgray">
                    <div style="margin-top:-0.25em; white-space: nowrap">Transaction Date/Time</div>
                  </th>
                  <td mat-cell *matCellDef="let elementGS" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                    <div>{{elementGS.transactionDateTime}}</div>
                    <div style="font-weight:bold">{{elementGS.userName}}</div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="changeRequestType">
                  <th mat-header-cell *matHeaderCellDef style="width:25%; text-align:left !important; border-right: 0.0625em solid lightgray">
                    <div style="margin-top:-0.25em;">Change Request Type</div>
                  </th>
                  <td mat-cell *matCellDef="let elementGS" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                    <div *ngIf="elementGS.isNewGroundStop">New Ground Stop</div>
                    <div *ngIf="elementGS.isGroundStopCancellation">Ground Stop Cancellation</div>
                    <div *ngIf="elementGS.isAirportChange">Airport Change</div>
                    <div *ngIf="elementGS.isArrivalDateTimeChange">Arrival Date Time Change</div>
                    <div *ngIf="elementGS.isDepartureDateTimeChange">Departure Date Time Change</div>
                    <div *ngIf="elementGS.isCrewChange">Crew Change</div>
                    <div *ngIf="elementGS.isPAXChange">PAX Change</div>
                    <div *ngIf="elementGS.isTaskStatusChange">Task Status Change</div>
                    <div *ngIf="elementGS.isTaskVendorChange">Task Vendor Change</div>
                    <div *ngIf="elementGS.isTaskConfirmationChange">Task Confirmation Change</div>
                    <div *ngIf="elementGS.isTaskCountryChange">Task Country Change</div>
                    <div *ngIf="elementGS.isTaskNotesChange">Task Notes Change</div>
                    <div *ngIf="elementGS.isTaskDocumentsChange">Task Documents Change</div>
                    <div *ngIf="elementGS.isTaskRevisionRequiredChange">Task Revision Required Change</div>
                    <div *ngIf="elementGS.isTaskRevisionCounterChange">Task Revision Counter Change</div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="changeRequest">
                  <th mat-header-cell *matHeaderCellDef style="width:30%; text-align:left !important; border-right: 0.0625em solid lightgray">
                    <div style="margin-top:-0.25em;">Change Request</div>
                  </th>
                  <td mat-cell *matCellDef="let elementGS" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                    <div style="white-space:pre-wrap">{{elementGS.changeRequest}}</div>

                  </td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>

          </div>

        </div>
      </div>
      gs end
    </div>

  </div>-->
</div>
<div class="mt-2">
  <button mat-button mat-dialog-close (click)="clickClose()" class="btn-low">Close</button>
</div>
