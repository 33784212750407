import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
//import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
//import { CommAddressTypeModel } from '../models/comm-address-type.model';
//import { AddressRatingModel } from '../models/address-rating.model';
//import { AddressUseModel } from '../models/address-use.model';
//import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopService } from '../services/ground-stop.service';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';
import { FlightFollowingModel } from '../models/flight-following.model';
import { GenerateMessageTemplateComponent } from '../ground-stops/generate-message-template.component';
import { TripAuditDialogComponent } from '../ground-stops/trip-audit-dialog.component';
import { EmailClientDialogComponent } from '../messages-client/email-client-dialog.component';

@Component({
  selector: 'app-flight-following-client-dialog',
  templateUrl: './flight-following-client-dialog.component.html',
  styleUrls: ['./flight-following-client-dialog.component.css']
})

export class FlightFollowingClientDialogComponent implements OnInit {
  dialogTitle: string;
  customerGUID: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  tripCodeGUID: string;
  icao: string;
  serviceTypeID: number;
  serviceTypeDesc: string;
  vendorReq: boolean;

  flight_following_client_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg2: string;
  msg: string;
  showSpin: boolean = true;
  lockdownTrip: boolean = false;
  showSuccessMsg: boolean = false;
  icao_iata: string;
  airportName: string;
  airportCountry: string;
  arrivalDateTime: string;
  departureDateTime: string;
  arrivalDateTimeLocal: string;
  departureDateTimeLocal: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  selectedTaskStatusGUID: string;
  public_note: string;  
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  countryGUID: string;

  tripCode: string;

  isModified: boolean = false;

  flightFollowingList: FlightFollowingModel[];
  selectedFlightFollowingList: FlightFollowingModel[];
  //private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  //private eventSubscription: Subscription;
  //userType: string;

  registration: string;

  leg: string;
  nextGroundStopGUID: string;
  nextICAO: string;
  prevICAO: string;
  taskStatusDescription: string;
  hasTaskAudit: boolean;
  upPII: boolean = false;
  onHoldText: string = "";
  constructor(private readonly _dialogRef: MatDialogRef<FlightFollowingClientDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService,
    private readonly _dialogService: DialogService
  ) {
    this.groundStopGUID = _data.groundStopGUID;
    this.serviceTypeID = _data.serviceTypeID;
    this.vendorReq = _data.vendorReq;
    this.dialogTitle = _data.dialogTitle;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.icao = _data.icao;
    this.groundStopTaskGUID = _data.groundStopTaskGUID;
    this.countryGUID = _data.countryId;
    this.leg = _data.leg;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;
  }

  getAllData(): Observable<any[]> {
    let request = new GroundStopModel();
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    let getGSResponse = this._groundStopAdvancedService.getGroundStopForTaskEditBygsId<ResponseModel<GroundStopModel>>(request);
    let req2 = new GroundStopTaskModel();
    req2.groundStopTaskGUID = this.groundStopTaskGUID;
    req2.groundStopGUID = this.groundStopGUID;
    let getTaskResponse = this._groundStopAdvancedService.getGroundStopTaskForFlightFollowingBygstId(req2);

    return forkJoin([getGSResponse, getTaskResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.showSpin = true;
    
    this.errMsg = "";
    this.errMsg2 = "";
    this.msg = "";
    this.tripCode = "";
    this.icao_iata = "";
    this.airportName = "";
    this.airportCountry = "";
    this.arrivalDateTime = "";
    this.departureDateTime = "";
    this.arrivalDateTimeLocal = "";
    this.departureDateTimeLocal = "";
    this.aircraftManufacturer = "";
    this.aircraftModel = "";
    this.aircraftInfo = "";   
    this.selectedTaskStatusGUID = "";   
    this.public_note = "";   
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.showSuccessMsg = false;
    this.registration = "";
    this.flightFollowingList = [];
    this.selectedFlightFollowingList = [];
    this.taskStatusDescription = "";
    this.nextICAO = "";
    this.prevICAO = "";
    this.hasTaskAudit = false;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let gs = new GroundStopModel();
          gs = responses[0].model;
          this.tripCode = gs.tripCode;
          this.icao = gs.icao;
          this.icao_iata = gs.icaO_IATA;
          this.airportName = gs.airportName;
          this.airportCountry = gs.airportState_CountryCode;
          this.arrivalDateTime = gs.arrivalDateTextUTC;
          this.departureDateTime = gs.departureDateTextUTC;
          this.arrivalDateTimeLocal = gs.arrivalDateTextLocal;
          this.departureDateTimeLocal = gs.departureDateTextLocal;
          this.registration = gs.registration;
          this.aircraftManufacturer = gs.aircraftManufacturer;
          this.aircraftModel = gs.aircraftModel;
          this.aircraftInfo = gs.aircraftInfo;
          this.countryGUID = gs.countryGUID;
          this.lockdownTrip = gs.lockdownTrip;
          this.nextICAO = gs.nextICAO;
          this.prevICAO = gs.prevICAO;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (responses[1].model != null) {
            let gst = new GroundStopTaskModel();
            gst = responses[1].model;
            //this.serviceTypeID = gst.serviceTypeID;
            this.serviceTypeDesc = gst.serviceTypeDescription;
            this.modifiedBy = gst.modifiedBy;
            this.modifiedDate = gst.modifiedDate;
            //this.isRevisionRequiredValue = gst.isRevisionRequiredValue;
            //if (this.isRevisionRequiredValue != 0) {
            //  this.isRevisionRequired = true;
            //}
            //this.revision_counter = gst.revisionCounter;
            if (gst.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;

            this.selectedTaskStatusGUID = gst.taskStatusGUID.toLowerCase();
            this.taskStatusDescription = gst.taskStatusDescription;
            this.flightFollowingList = gst.flightFollowingList;
            this.setFlightFollowingList();
            this.public_note = gst.publicNote;
            this.hasTaskAudit = gst.hasTaskAudit;
            if (gst.serviceLocation == 'D' && gst.departureOnHold) {
              this.onHoldText = "On Hold";
            }
            else {
              if (gst.serviceLocation == 'A' && gst.arrivalOnHold) {
                this.onHoldText = "On Hold";
              }
            }
          }
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;

    });

  }

  initControls() {
    this.flight_following_client_form = this._formBuilder.group({    
      public_note: [this.public_note],     
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      
    });
  }

  get f() { return this.flight_following_client_form.controls; }

  

  setFlightFollowingList() {
    this.selectedFlightFollowingList = this.flightFollowingList.filter(u => u.selected == true);
  }

  

  clickCopyNote() {
    let s: string = "";
   
    s = this.f.public_note.value;
       
    return s;
  }

  clickCopyHeader() {
    let firstlinestring: string = "";
    let secondlinestring: string = "";
    // registration
    firstlinestring = "Registration"; // 12 characters
    secondlinestring = this.registration;
    if (this.registration.length > 12) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.registration, 12);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.registration, 12);
    }
    // registration end
    // trip code
    firstlinestring += "Trip Code"; // 9
    secondlinestring += this.tripCode;
    if (this.tripCode.length > 9) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.tripCode, 9);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.tripCode, 9);
    }
    // trip code end
    // business stop
    firstlinestring += "Business Stop"; // 13
    let bs: string = "";
    if (this.prevICAO != "") {
      bs = this.prevICAO + "-";
    }
    bs += this.icao;
    if (this.nextICAO != "") {
      bs += "-" + this.nextICAO;
    }
    secondlinestring += bs;
    if (bs.length > 13) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, bs, 13);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, bs, 13);
    }
    // business stop end
    // arrival
    let arr1stLineCharacterNumber = firstlinestring.length;
    let arr2ndLineCharacterNumber = secondlinestring.length;
    if (this.arrivalDateTime != "") {
      firstlinestring += "Arrival(" + this.icao + ")"; // 13
      let at: string = this.arrivalDateTime;
      secondlinestring += at;
      if (at.length > 13) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, at, 13);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, at, 13);
      }
    }
    let arr1stLineCharacterNumber2 = firstlinestring.length;
    let arr2ndLineCharacterNumber2 = secondlinestring.length;
    // arrival end
    // departure
    let dep1stLineCharacterNumber = firstlinestring.length;
    let dep2ndLineCharacterNumber = secondlinestring.length;
    if (this.departureDateTime != "") {
      firstlinestring += "Departure(" + this.icao + ")"; // 15
      let dt: string = this.departureDateTime;
      secondlinestring += dt;
      if (dt.length > 15) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, dt, 15);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, dt, 15);
      }
    }
    // departure
    // aircraft info
    firstlinestring += this.aircraftManufacturer + " " + this.aircraftModel;
    secondlinestring += this.aircraftInfo;
    // aircraft info end
    let thirdEmptySpaceNum = 0;
    let thirdLineString = "";
    if (this.arrivalDateTimeLocal != "") {
      if (arr1stLineCharacterNumber > arr2ndLineCharacterNumber) {
        thirdEmptySpaceNum = arr1stLineCharacterNumber;
      }
      else {
        thirdEmptySpaceNum = arr2ndLineCharacterNumber;
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.arrivalDateTimeLocal;
    }
    if (this.departureDateTimeLocal != "") {
      if (this.arrivalDateTime != "") {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber - arr1stLineCharacterNumber2 + 2;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber - arr2ndLineCharacterNumber2 + 2;
        }
      }
      else {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber;
        }
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.departureDateTimeLocal;
    }

    let s: string;
    s = firstlinestring + "\n" + secondlinestring;
    if (thirdLineString != "") {
      s += "\n" + thirdLineString;
    }
    return s;

  }

  getFirstLineString(firstLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (localString.length - headerCharacterNumber); i++) {
      firstLinesString += " ";
    }
    firstLinesString += "  ";
    return firstLinesString;
  }

  getSecondLineString(secondLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (headerCharacterNumber - localString.length); i++) {
      secondLinesString += " ";
    }
    secondLinesString += "  ";
    return secondLinesString;
  }

  clickClose() {    
    this._dialogRef.close(this.isModified);
  }

  clickAudit() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: "", aircraftGUID: "", tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID,
      groundStopTaskGUID: this.groundStopTaskGUID, pastHours: 0,
      userName: "", v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  clickEmail() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID
    };

    const dialogRef = this._dialog.open(EmailClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }



}
