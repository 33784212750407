import { Component, Inject, OnInit, AfterViewInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
// import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { forkJoin, Observable, Subscription } from 'rxjs'; 
import { DialogService } from '../services/dialog.service'; 
import { StateProvinceModel } from '../models/state-province.model'; 
import { ReturnObjModel } from '../models/return-obj.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { Domfrequencylist } from '../models/domfrequencylist.model';
import { UnitofmeasureModel } from '../models/unitofmeasure.model';

import { FuelSupplierModel } from '../models/fuelsupplier.model'; 
import { LK_FuelFrequencyModel } from '../models/LK_FuelFrequency';
import { CurrencyModel } from '../models/currency.model';
import { FuelService } from '../services/fuel.service';
import { CountryModel } from '../models/country.model';
import { CountryService } from '../services/country.service'; 
import { LocationPreferencesComponent } from './location-preferences.component';


@Component({
  selector: 'app-fuel-supplier-profile-edit',
  templateUrl: './fuel-supplier-profile-edit-dialog.component.html',
  styleUrls: ['./fuel-supplier-profile-edit-dialog.component.css']
})

export class FuelSupplierEditDialogComponent implements OnInit, AfterViewInit {

  @Input() dialogTitle: string;
  fuel_supplier_Profile_edit: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  fuelSupplierList: FuelSupplierModel[];
  domfrequencylist: Domfrequencylist[];
  intlfrequencylist: Domfrequencylist[];
  unitofmeasurelist: UnitofmeasureModel[];
  currencylist: CurrencyModel[];
  show: boolean = false;
  selectedallowfuelpriceimport: boolean;


  selecteddomfrequency: string;

  selectedunitofmeasure: number;
  selectedintlfrequency: string;
  selectedcurrency: number;
  showModified: boolean = false; 
  stateProvinceList: StateProvinceModel[]; 
  fuelSupplierGUID: string;

  modifiedBy: string;
  modifiedDate: string;
  isActive: any;// number; 
  controlValueChanged: boolean;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  fuelSupplierName: any;
  fuelSupplierShortname: any;
  lk_fuelfrequencyDomGUID: any;
  lk_fuelfrequencyIntGUID: any;
  //fuelscaleneedsadjustment: any;

  fuelscaleneedsadjustment: boolean = false;
  readonly: boolean = false;
  lk_unitofMeasureid: any;

  removeextrarows: any;

  fuelSupplierWebsite: any;
  fuelReleaseEmail: any;
  internalNotes: any;
  unitofmeasure: any;
  domfrequency: string  ='38678be2-03d2-4a3c-b700-2bc6f0262f03';// any;
  intlfrequency: string = '38678be2-03d2-4a3c-b700-2bc6f0262f03';// any;

  domfrequencyVal:    any = 1;
  intlfrequencyVal:   any;
  currency: any;

  scaleneedsadjustment: any;
  // taxesIncluded: boolean = false;// any;
  taxesIncludedDom: boolean = false;// any;
  taxesIncludedInt: boolean = false;// any;

  isAllowImport: any;// = false;

  allowfuelpriceimport: any;// boolean=false;
  isallowfuelpriceimport: string;
  allowfuelpriceimport1: boolean;
  successMsg: string;
  excludedCountriesVal: number[] = [];
  countryList: CountryModel[];
  selectedCountryList: CountryModel[];
  profileCount: number;
  countryListValue: string = '';
  countryNames: any = [];
  excludedCountriesLength: number = 0;
  locationProfileChanged: boolean = false;
  isTotalCountChanged: boolean = false;


  constructor(private readonly _dialogRef: MatDialogRef<FuelSupplierEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _fuelService: FuelService,    private readonly _countryService: CountryService,
    private readonly _dialogService: DialogService,        private readonly _dialog: MatDialog,


  ) {
    this.fuelSupplierGUID = _data.fuelSupplierGUID;
    this.dialogTitle = _data.dialogTitle;

    this.isAllowImport = true;
    this.isActive = true;
    this.currency = 'USD';

    if (_data.dialogTitle == "ADD New Record") {
      this.showModified = false;
    }
    else {
      this.showModified = true;
    }
  }

  getAllData(): Observable<any[]> {
    let getDomFrequency = this._fuelService.getDomFrequency();
    //let getIntlFrequency = this._fuelService.getDomFrequency();
    let getUnitOfMeasure = this._fuelService.getUnitOfMeasure();
    let getCurrency = this._fuelService.getCurrency();
    //  this._commonService.getCountryListWithUSAFirst<ResponseModel<FuelSupplierModel[]>>();
    let getCountryResponse = this._countryService.getCountryBySearchKey<ResponseModel<CountryModel[]>>(new CountryModel()) ;


    if (this.fuelSupplierGUID != null && this.fuelSupplierGUID != "") {
      let getFuelSupplierResponse = this._fuelService.getfuelSupplierByfsId(this.fuelSupplierGUID);
      return forkJoin([getDomFrequency, getUnitOfMeasure, getCurrency,getCountryResponse, getFuelSupplierResponse, ]);//, getUnitOfMeasure, getCurrency]);
    }
    else {
      return forkJoin([getDomFrequency, getUnitOfMeasure, getCurrency, getCountryResponse]);//[getDomFrequency, getIntlFrequency, getUnitOfMeasure, getCurrency]);
    }
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    //this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = true;

    this.allowfuelpriceimport = 0;
    this.unitofmeasure = 1;


    this.fuelSupplierList = [];
    this.fuelSupplierList = [];
    this.stateProvinceList = [];
    this.domfrequencylist = [];
    this.showSuccessMsg = false;

    this.isAllowImport = true;
    this.show = true;
    this.excludedCountriesVal = [];

    this.getAllData().subscribe(responses => {
      if (responses != null) { }
      // this.domfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';
      // this.intlfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';


      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {

          this.domfrequencylist = responses[0].model;
          this.intlfrequencylist = responses[0].model;

          let domfreq = new LK_FuelFrequencyModel();
          let intlfreq = new LK_FuelFrequencyModel();

          domfreq = responses[0].model;
          intlfreq = responses[0].model;
          

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.unitofmeasurelist = responses[1].model;


          let uom = new UnitofmeasureModel();

          uom = responses[0].model;
          if (uom.lk_UnitOfMeasureID == 'undefined') {
            this.unitofmeasure = uom.lk_UnitOfMeasureID;
          }

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.currencylist = responses[2].model;


          let curr = new CurrencyModel();

          curr = responses[0].model;
          if (curr.lk_currencyID != null) {
            this.currency = curr.lk_currencyID;
          }
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.countryList = responses[3].model; 
          // let curr = new CurrencyModel(); 
         this.countryList.forEach(c => c.selected = true);

        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          let fs = new FuelSupplierModel();
          fs = responses[4].model;
          this.fuelSupplierName = fs.fuelSupplierName;
          this.fuelSupplierShortname = fs.fuelSupplierShortname;
          this.domfrequency = fs.lk_FuelFrequencyDomGUID;
          this.intlfrequency = fs.lk_FuelFrequencyIntGUID;
          this.fuelscaleneedsadjustment = fs.fuelScaleNeedsAdjustment;
          this.taxesIncludedDom = fs.taxesIncludedDom;
          this.taxesIncludedInt = fs.taxesIncludedInt;
          this.unitofmeasure = fs.lk_UnitOfMeasureID;
          this.currency = fs.currencyID;
          this.isAllowImport = fs.isAllowImport;
          this.removeextrarows = fs.removeExtraRows;
          this.internalNotes = fs.internalNotes;

          this.fuelSupplierWebsite = fs.fuelSupplierWebsite;
          this.fuelReleaseEmail = fs.fuelReleaseEmail;
          this.isActive = fs.isActive;
          this.selecteddomfrequency = fs.lk_FuelFrequencyDomGUID;
          this.modifiedBy = fs.modifiedBy;
          this.modifiedDate = fs.modifiedDate;
          this.profileCount = fs.profileCount;

          this.excludedCountriesVal = fs.excludedCountries?.split(',').map(id => +id);


          // this.countryListValue = fs.excludedCountries?.join(',');
          this.countryNames =[];

          // if(this.excludedCountriesVal != [])
          this.excludedCountriesVal?.forEach(excludedId => { 
            let country = this.countryList.find(country => country.countryID === excludedId); 
            if (country) {
              this.countryNames.push(country.countryName);
            }
        });
        this.excludedCountriesLength = this.countryNames.length;
         
        this.countryListValue = this.countryNames.join(", ");
        

        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();
      // this.domfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';
      // this.intlfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03'; 
      this.f.domfrequency.setValue(this.domfrequency);
      // this.f.domfrequency = new UntypedFormControl(this.domfrequency); // for mat-select

      this.checkFormControlValueChanged();

      this.showSpin = false;



    });

    // this.initControls();
    this.showSpin = false;
  }

  ngAfterViewInit() {

  }

  initControls() {
    this.fuel_supplier_Profile_edit = this._formBuilder.group({
      fuelSupplierName: [this.fuelSupplierName, Validators.required],
      fuelSupplierShortname: [this.fuelSupplierShortname, Validators.required],
      unitofmeasure: [this.unitofmeasure],
      // currency: [this.currency],

      isAllowImport: [this.isAllowImport],

      domfrequency: [this.domfrequency],
      intlfrequency: [this.intlfrequency],
      currency: [this.currency],
      fuelscaleneedsadjustment: [{
        value: this.fuelscaleneedsadjustment, disabled: this.readonly
      }],
      taxesincludedDom: [this.taxesIncludedDom],
      taxesincludedInt: [this.taxesIncludedInt],

      internalNotes: [this.internalNotes],
      fuelSupplierWebsite: [this.fuelSupplierWebsite],
      fuelReleaseEmail: [this.fuelReleaseEmail],
      isActive: [this.isActive],
      modifiedBy: [this.modifiedBy],

      modifiedDate: [this.modifiedDate],
      excludedCountries: [this.excludedCountriesVal],

    });
  }

  get f() { return this.fuel_supplier_Profile_edit.controls; }

  checkFormControlValueChanged(): void {
    this.fuel_supplier_Profile_edit.get("fuelSupplierName").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("fuelSupplierShortname").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("fuelSupplierWebsite").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("fuelReleaseEmail").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("internalNotes").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("isAllowImport").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });    
    this.fuel_supplier_Profile_edit.get("domfrequency").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("unitofmeasure").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("intlfrequency").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("currency").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("fuelscaleneedsadjustment").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("taxesincludedDom").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("taxesincludedInt").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("isActive").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_supplier_Profile_edit.get("excludedCountries").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    //this.fuel_supplier_Profile_edit.get("fuelSupplierWebsite").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.fuel_supplier_Profile_edit.get("fuelReleaseEmail").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.fuel_supplier_Profile_edit.get("internalNotes").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //}); 
  }

  clickSave() {
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;

    if (this.f.fuelSupplierName.value.trim() == "") {
      this.f.fuelSupplierName.setErrors({ required: true });
    }
    if (this.f.fuelSupplierShortname.value.trim() == "") {
      this.f.fuelSupplierShortname.setErrors({ required: true });
    }

    if (!this.checkValidations()) {
      return;
    }
    else { 
      
      this.loading = true;
      let obj = new FuelSupplierModel();


      obj.fuelSupplierGUID = this.fuelSupplierGUID;
      obj.fuelSupplierName = this.f.fuelSupplierName.value.trim();
      obj.fuelSupplierShortname = this.f.fuelSupplierShortname.value.trim();
      obj.lk_FuelFrequencyDomGUID = this.f.domfrequency.value;
      obj.lk_FuelFrequencyIntGUID = this.f.intlfrequency.value;
      obj.lk_UnitOfMeasureID = this.f.unitofmeasure.value;// == null ? 0 : this.f.unitofmeasure.value; this.unitofmeasure;// this.selectedunitofmeasure;//
      obj.currencyID = this.f.currency.value;// == null ? 0 : this.f.currency.value;this.currency;// this.selectedcurrency;//
      obj.isAllowImport = true; // this.isAllowImport;
      obj.allowfuelpriceimport = true; //  this.isAllowImport == 0 ? false : true; //this.f.allowfuelpriceimport.value; 
      obj.internalNotes = this.f.internalNotes.value == null ? '' : this.f.internalNotes.value;
      obj.fuelSupplierWebsite = this.f.fuelSupplierWebsite.value == null ? '' : this.f.fuelSupplierWebsite.value;
      obj.fuelReleaseEmail = this.f.fuelReleaseEmail.value == null ? '' : this.f.fuelReleaseEmail.value;
      obj.isActive = this.f.isActive.value == 'false' ? false : true;// this.f.disablerecords.value;
      obj.fuelScaleNeedsAdjustment = this.f.fuelscaleneedsadjustment.value == 'true' ? true : false;
      obj.taxesIncludedDom = this.f.taxesincludedDom.value;
      obj.taxesIncludedDom = this.f.taxesincludedDom.value == 'true' ? true : obj.taxesIncludedDom;
      obj.taxesIncludedDom = this.f.taxesincludedDom.value == 'false' ? false : obj.taxesIncludedDom;
      obj.taxesIncludedInt = this.f.taxesincludedInt.value;
      // obj.taxesIncludedInt = this.f.taxesincludedInt.value=='true' ? true : false; 
      obj.taxesIncludedInt = this.f.taxesincludedInt.value == 'true' ? true : obj.taxesIncludedInt;
      obj.taxesIncludedInt = this.f.taxesincludedInt.value == 'false' ? false : obj.taxesIncludedInt;

      obj.modifiedBy = this.f.modifiedBy.value;
      obj.modifiedDate = this.f.modifiedDate.value;

      obj.excludedCountries = this.f.excludedCountries.value?.join();


      this._fuelService.saveFuelSupplier<ResponseModel<boolean>>(obj).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model == true) {
            // this.fuelSupplierGUID = response.model;
            let d = new Date();
            let m = d.getMonth() + 1;
            let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
            this.f.modifiedBy.setValue(localStorage.getItem('un'));
            this.f.modifiedDate.setValue(dt);
            // this.controlValueChanged = false;
            let returnResponse = new ReturnObjModel();
            returnResponse.refresh = true;
            returnResponse.newId = this.fuelSupplierGUID;
            this.showSuccessMsg = true;


            // this._dialogService.openAlertDialog("Your information has been submitted successfully.");
            this.isModified = true;

            if (this.showModified = true) {
              this.successMsg = "Fuel Supplier Updated Successfully.";
            }
            else {
              this.successMsg = "Fuel Supplier Saved Successfully.";
            }


            setTimeout(() => {
              this.showSuccessMsg = false;
              this._dialogRef.close(returnResponse);
            }, 1000);


            //this._dialogRef.close(returnResponse);
            //}
          }
          else {
            //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
            this.errMsg = "Fuel Supplier Code already exists.";
            //this._dialogRef.close(this.isModified);
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        this.loading = false;
      });
    }
  }

  checkValidations() {
 

    if(this.f.domfrequency.value == ''){
      this.errMsg = "Expiry Date for Domestic Is Empty";
      setTimeout(() => {
        this.errMsg = '';
      }, 2000);
      return false;
    }
    if(this.f.intlfrequency.value == ''){
      this.errMsg = "Expiry Date for International Is Empty";
      setTimeout(() => {
        this.errMsg = '';
      }, 2000);
      return false;
    }

    return true;

  }
   
  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;

    if (this.controlValueChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before close the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(response);
        }
      });

    }
    else {
      this._dialogRef.close(false || this.isTotalCountChanged );  //this.locationProfileChanged);
    }
    //this._dialogRef.close(this.isModified);
  }
   
  //event handler for the select element's change event
  allowfpimport(event: any) {
    //update the ui
    if (event.target.value == 'true') {
      this.show = true;
      //  this.allowfuelpriceimport = true;
      this.isAllowImport = true;
    } else {
      this.show = false;
      //   this.allowfuelpriceimport = false;
      this.isAllowImport = false;
    }
  }

  excludeCountryList(event: any, c: CountryModel) {
    if (event.currentTarget.ariaSelected)
      c.selected = true;
    else
      c.selected = false;
    //this.getSearchResults();
  }

  openLocationPreferences(){
    this.locationProfileChanged = true;

    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "43em";//"1000px";
    config.width = "70em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // this.clickClose();
    config.data = { v: this._authService.getCurrentTimeNumber() , fuelSupplierGUID: this.fuelSupplierGUID, fuelSupplierName : this.fuelSupplierName};
    const dialogRef = this._dialog.open(LocationPreferencesComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isTotalCountChanged = true;
        this.ngOnInit();

      }

    });
  }

  filterOptions(c: CountryModel, e: any){
 
    // if(this.countryListValue == ''){
    //   this.countryListValue =  c.countryName ;
    // }
    // else{

      // this.countryNames.push(c.countryName);

      // this.countryListValue = this.countryNames.join(", ");  
      //} 

      this.countryListValue = '';
      this.countryNames = [];
      
      if (this.f.excludedCountries && this.f.excludedCountries.value) {
          const excludedIds: any[] = this.f.excludedCountries.value;
      
          excludedIds?.forEach((excludedId: any) => {
              const country = this.countryList.find(country => country.countryID === excludedId);
              if (country) {
                  this.countryNames.push(country.countryName);
              }
          });
      
          this.countryListValue = this.countryNames.join(", ");
          this.excludedCountriesLength = excludedIds.length;
      }
      
  }
  
   
}
