import { Component, Inject, OnInit } from "@angular/core";
import { GroundStopModel } from "../models/ground-stop.model";
import { AircraftTripModel } from "../models/aircraft-trip.model";
import { GroundStopClientService } from "../services/ground-stop-client.service";
import { ResponseModel } from "../models/response.model";
import { TripModel } from "../models/trip.model";
import { AuthenticateService } from "../services/authenticate.service";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RouteSequenceConfirmationDialog } from "./manage-route-sequence-confirmation-dialog.compnent";

@Component({
    selector: 'app-manage-route-sequence-dialog',
    templateUrl: './manage-route-sequence-dialog.component.html',
    styleUrls: ['./manage-route-sequence-dialog.component.css']
})

export class ManageRouteSequenceDialog implements OnInit {
    tripCode: string;
    registration: string;
    groundStopList: GroundStopModel[] = [];
    tripID: number;
    displayedColumn: string[] = ['sequence_number', 'icao', 'date_time', 'status'];
    showSpin: boolean = false;
    aircraftGUID: string;
    tripCodeGUID: string;
    customerGUID: string;
    errMsg: string = "";
    showSuccessMsg: boolean = false;
    successMsg: string = "";
    buttonText: string = "Update Route Sequence";
    sequenceModified: boolean = false;
    modifiedBy: string = "";

    constructor(private readonly _groundStopClientService: GroundStopClientService,
        private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private readonly _dialogRef: MatDialogRef<ManageRouteSequenceDialog>) {
        this.aircraftGUID = _data.aircraftGUID;
        this.tripCodeGUID = _data.tripCodeGUID;
        this.customerGUID = _data.customerGUID;
        this.tripCode = _data.tripCode;
        this.registration = _data.registration;
    }

    ngOnInit(): void {
        this.getData();
    }

    getData() {
        this.showSpin = true;
        let request = new AircraftTripModel();
        request.aircraftGUID = this.aircraftGUID;
        request.customerGUID = this.customerGUID;
        request.startDateText = localStorage.getItem('trip.sd');
        request.endDateText = localStorage.getItem('trip.ed');
        request.tripCodeGUID = this.tripCodeGUID;

        this._groundStopClientService.getTripBytId<ResponseModel<TripModel>>(request).subscribe(response => {
            if (response != null) {
                if (response.code == "200" && response.message == "") {
                    this.groundStopList = response.model.groundStopList;
                    this.tripID = response.model.tripID;
                }
                else {
                    if (response.code == "401")
                        this._authService.signOut();
                    else
                        this.errMsg = response.message;
                }
            }
            this.showSpin = false;
        });
    }

    updateSequenceNumber(e: any, index: number) {
        if (!isNaN(parseInt(e.target.value))) {
            this.sequenceModified = true;
            this.groundStopList[index].groundStopSequenceID = parseInt(e.target.value);
        }
        else {
            this.sequenceModified = false;
        }
    }

    checkForDuplicates(array: number[]): boolean {
        let valuesAlreadySeen = []

        for (let i = 0; i < array.length; i++) {
            let value = array[i]
            if (valuesAlreadySeen.indexOf(value) !== -1) {
                return true
            }
            valuesAlreadySeen.push(value)
        }
        return false
    }

    checkForNonConsecutive(array: number[]): boolean {
        array.sort(this.compareNumbers);
        for (var i = 1; i < array.length; i++) {
            if (array[i] != array[i - 1] + 1) {
                return true;
            }
        }
        return false;
    }

    compareNumbers(a: number, b: number) : number {
        return a - b;
    }

    openConfirmationDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.hasBackdrop = false;

        dialogConfig.data = {
            customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID, tripCodeGUID: this.tripCodeGUID, registration: this.registration,
            groundStopList: this.groundStopList, tripID: this.tripID
        };

        const dialogRef = this._dialog.open(RouteSequenceConfirmationDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            this._dialogRef.close(result);
        });
    }

    updateRouteSequence() {
        this.showSuccessMsg = false;        

        //Check for duplicate and/or skipped sequenceIDs before updating in db
        if (this.checkForDuplicates(this.groundStopList.map(x => x.groundStopSequenceID))) {
            this.errMsg = "Cannot have duplicate sequence IDs.";
            return;
        }
        if (this.checkForNonConsecutive(this.groundStopList.map(x => x.groundStopSequenceID))) {
            this.errMsg = "Sequence IDs must be consecutive.";
            return;
        }

        this.openConfirmationDialog();
    }
}