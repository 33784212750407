import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, Optional, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
//import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomValidators } from '../common-utility/custom.validators';
import { AirportModel } from '../models/airport.model';
import { CountryModel } from '../models/country.model';
import { FuelPricingModel } from '../models/fuel-pricing.mode';
import { FuelSupplierModel } from '../models/fuelsupplier.model';
import { IntoPlaneAgentModel } from '../models/intoplaneagent.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { FuelService } from '../services/fuel.service';
import { Title } from '@angular/platform-browser';
import { FuelpricingComponent } from '../fuelpricing/fuelpricing.component';
import { ReturnObjModel } from '../models/return-obj.model';
import { FuelPricingEditDialogComponent } from './fuel-pricing-edit-dialog.component';
import { ReviseFuelPricingModel } from '../models/revise-fuel-pricing.model';
import { FuelMenuComponent } from './fuel-menu.component';
import { Workbook } from 'exceljs';
//import { BusinessRulesEditComponent } from './business-rules-edit.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-revise-fuel-pricing',
  templateUrl: './revise-fuel-pricing.component.html',
  styleUrls: ['./revise-fuel-pricing.component.css']
})
 
   

export class ReviseFuelPricingComponent implements OnInit {
  reviseFuelPricing_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string; 
  showSpin: boolean;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;   
  revisefuelpricelist: ReviseFuelPricingModel[];  

  displayedColumn: string[] = ['IATA', 'ICAO', 'FAAID', 'FuelSupplierShortname' ]; 
  scrHeight: number;
  websiteEnvironment: string = "";
  grantAccessToFuel: boolean = false;
  isDialog: boolean = false;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router,
     private readonly _formBuilder: UntypedFormBuilder, private readonly _dialog: MatDialog,
     @Optional() private readonly _dialogRef: MatDialogRef<ReviseFuelPricingComponent>,
     @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
      private readonly _fuelService: FuelService , private _titleService: Title, private readonly _dialogService:DialogService) {

        if (this.data) {
          this.isDialog = true;
        }
  }

  getAllData(): Observable<any[]> {
    let getReviseFuelPricing = this._fuelService.getReviseFuelPricing<ResponseModel<ReviseFuelPricingModel[]>>(); 
    //let getCountryResponse = this._commonService.getCountryListWithUSAFirst<ResponseModel<CountryModel[]>>();
    //let getHotelChainResponse = this._commonService.getHotelChainList<ResponseModel<HotelChainModel[]>>();
    return forkJoin([getReviseFuelPricing]);

  }

  ngOnInit() {
    this.grantAccessToFuel = false;
    if (localStorage.getItem('up.fuel') == 'true') {
      this.grantAccessToFuel = true;
    }


    if (!this.grantAccessToFuel) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.showSpin = true; 
    this.errMsg = "";
    this.msg = ""; 
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.revisefuelpricelist = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }


      this.initControls();

      this.showSpin = false;

      this.getSearchResults();


    });


 
 
  }

  initControls() {
    this.reviseFuelPricing_form = this._formBuilder.group({
      fuelSupplierName: [''],
      fuelSupplierShortname: [''],
      //airport: [''],
      //city: [''],
      //country_select:[''], 
    });
  }

  get f() { return this.reviseFuelPricing_form.controls; }

 
  
  getSearchResults() {
    this.errMsg = "";
    this.msg = "";


    this.showSpin = true;  
     

    this._fuelService.getReviseFuelPricing<ResponseModel<ReviseFuelPricingModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.revisefuelpricelist = response.model; 
          this.totalRecordsText = response.model.length + ' Records count';
          //this.totalRecords = this.revisefuelpricelist.totalRecords;
          this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
          //}

        }
        else {
          this.revisefuelpricelist = [];
          this.msg = "No records found.";

          this.totalRecordsText = '';
          this.totalRecords = 0;
          this.currentPageIndex = 0;
          this.totalPageNumber = 0;
        }
      }
      else {
        this.revisefuelpricelist = [];
      }
      this.loading = false;
      this.showSpin = false;

    })


  }

  clickRoute(routePage: string) {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px"};
    if(this.isDialog){
      // this.clickclose();
      this._dialogRef.close();
    }
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(FuelMenuComponent, config);   
  }
  
  // clickRoute(routePage: string) {
  //   this._router.navigate([routePage]);
  // }

  //pageIndexChange(e: any) {
  //  //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
  //  this.currentPageIndex = e.pageIndex;
  //  if (this.pageSize != e.pageSize) {
  //    this.totalRecords = 0;
  //    this.totalRecordsText = "";
  //    this.totalPageNumber = 0;
  //    this.pageSize = e.pageSize;
  //  }

  //  this.getSearchResults();

  //}

   
  exportToExcel(){

    let wb=new Workbook();
    let ws =wb.addWorksheet("InvalidAirports")
    
    let header=["ICAO", "IATA","FAAID", "Fuel Supplier"]
    ws.addRow(header);
    this.revisefuelpricelist.forEach( fpl => {
      
      ws.addRow([  fpl.icao , fpl.iata,  fpl.faaid, fpl.fuelSupplierName]);
    } )

    wb.xlsx.writeBuffer().then((data)=>{
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob,"InvalidAirports.xlsx");
    });


  }

 
  clickOpenInNew( ){
    this._dialogRef.close(true);
    window.open('fuelmenu/revise-fuel-pricing' + "?v=" + this._authService.getCurrentTimeNumber());
  }




}
