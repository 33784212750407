import { Component } from '@angular/core';

@Component({
    selector: 'app-vendor-bi-dashboard',
  templateUrl: './vendor-bi-dashboard.component.html',
  styleUrls: ['./vendor-bi-dashboard.component.css']
})
/** airport-bi-dashboard component*/
export class VendorBiDashboardComponent {
    /** airport-bi-dashboard ctor */
    constructor() {

    }
}
