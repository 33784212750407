import { CustomerGroupModel } from "./customer-group.model";
import { TripModel } from "./trip.model";

export class CustomerModel {
  customerGUID: string;
  customerName: string;
  legalEntityName: string;
  operatorName: string;
  acctCreationDate: string;
  customerAccountStatus: string;
  customerBillingStatus: string;
  salesTerritory: string;
  haltServices: boolean; 
  hasNotes: boolean; 
  hasUKAPD: boolean; 
  hasEuroControl: boolean; 
  hasSeneam: boolean; 
  hasMexicoMultipleEntry: boolean; 
  hasCocesna: boolean; 
  filterBy: string;
  textSearch: string; 
  pageSize: number; 
  pageIndex: number; 
  totalRecords: number; 
  customerTypeId: number;
  accountStatusId: number;
  thirdPartyScreeningStatusId: number;
  marketingRegionId: number;
  industryId: number;
  modifiedBy: string;
  modifiedDate: string;
  stockSymbol: string;
  thirdPartyScreeningStatusDesc: string;
  industryDesc: string;
  customerTypeDesc: string;
  pageName: string;
  creditCheckID: number;
  creditCheckDesc: string;
  salesNote: string;
  requiresBackupInvoicesId: number;
  requiresBackupInvoicesDesc: string;
  haltDate: string;
  haltModifiedBy: string;
  haltRemarks: string;
  haltWeatherServices: boolean;
  haltPermits: boolean;
  haltHandling: boolean;
  haltFuelSetup: boolean;
  haltHotelSetup: boolean;
  haltWebsiteAccess: boolean;
  haltSSN: boolean;
  emailBody: string;
  accountID: string;
  billingStatusId: number;
  billingStatusDescription: string;
  referencePO: string;
  selected: boolean;
  creditStatus: string;
  creditStatusId: number;
  internalNote: string;
  hasBOE: boolean;
  hasVWP: boolean;
  hasPart135: boolean;
  apisCarrierCode: string;
  tripCodeCount: number;
  transactionCount: number;
  tripList: TripModel[];
  expand: boolean;
  schedulingSoftwareID: number;
  isDefaultClient: boolean;
  showApisReportFirst: boolean;
  tripOpenCount: number;
  mailFolderID: string;
  customerID: number;
  customerGroup: CustomerGroupModel[];
  internalNonStandardProcedures: string;
}
