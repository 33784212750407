import { TripModel } from "./trip.model";

export class TripCloseoutQueueModel {

  tripCodeGUIDList: string;
  aircraftGUIDList: string;
  customerGUIDList: string;
  tripCloseOutIDList: string; 
  monthIDList: string;

  closeoutFilter: CommonFilter[];
  clientFilter: CommonFilter[];
  tripCodeFilter: CommonFilter[];
  registrationFilter: CommonFilter[];
  tripCloseoutList: TripModel[];
  
  startDate: Date;
  endDate: Date;
  pageSize: number;
  pageIndex: number;
  includeTrips: string;
  dateType: string;
  isRemoveFromExternalView: boolean;
  isLockdown: boolean;
  isDemo: boolean;
  isOnHoldTrip: boolean;
  selectedYear: number;
  isBillingCompleted: boolean;
  includeTBA: boolean;
  
 

}

export class CommonFilter
{
  filterName: string;
  filterID: string;
  selected: boolean = false;
   
}
