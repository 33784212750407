import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { CompanyEventModel } from '../models/company-event.model';
import { ContentDocumentModel } from '../models/content-document.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';
import { CKEditorConfig } from '../ckeditor5/ckeditor-config';
import * as DecoupledEditor from '../ckeditor5/ckeditor';

@Component({
    selector: 'app-company-event-dialog',
    templateUrl: './company-event-dialog.component.html',
    styleUrls: ['./company-event-dialog.component.css']
})

/** company-event-dialog component*/
export class CompanyEventDialogComponent implements OnInit {
  public Editor = DecoupledEditor;

  public CKEConfig = CKEditorConfig.ckeditorConfigClient;

  public onReady(editor) {

    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()

    );
    const toolbarElement = editor.ui.view.toolbar.element;
    toolbarElement.style.display = 'none';
    editor.enableReadOnlyMode('a');
    //const clipboardPlugin = editor.plugins.get('ClipboardPipeline');
    //const editingView = editor.editing.view;

    //editingView.document.on('clipboardInput', (evt, data) => {
    //  if (editor.isReadOnly) {
    //    return;
    //  }
    //  const dataTransfer = data.dataTransfer;
    //  let content = plainTextToHtml(dataTransfer.getData('text/plain'));
    //  content = content.replace(/<p>/gi, '<p class=\"email-body\">')
    //  data.content = editor.data.htmlProcessor.toView(content);

    //  editingView.scrollToTheSelection();
    //}, { priority: 'high' });


    //const imageUploadEditing = editor.plugins.get('ImageUploadEditing');

    //imageUploadEditing.on('uploadComplete', (evt, { data, imageElement }) => {

    //  let img = new MessageEmbeddedImageModel();
    //  img.imageBase64String = data.model[0].bFile;
    //  img.documentName = data.model[0].fileName;
    //  img.mimeType = data.model[0].mimeType;
    //  img.isInline = true;
    //  img.contentId = data.model[0].fileName;
    //  this.embeddedImageList.push(img);
    //});
    //let data = "";
    //if (!this.isDraft)
    //  data += "<p class=\"email-body\"><br><br></p>" + this.defaultSignature
    //data += this.emailBody;
    //editor.setData(data);
    ////editor.focus();
    //editor.execute('style', 'Email Body');
    // editor.execute('style', { name: 'Email Body' });
    //editor.execute('fontSize', { value: '14px' });
    editor.setData(this.companyEvent.companyEventStory);
  }
  @Input() companyEventID: number;
  companyEvent: CompanyEventModel;
  showSpin: boolean = false;
    /** company-event-dialog ctor */
  constructor(private readonly _dialogRef: MatDialogRef<CompanyEventDialogComponent>, private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder, 
    private readonly _customerService: CustomerService, @Inject(MAT_DIALOG_DATA) private _data: any,) {
    this.companyEventID = _data.companyEventID;   

  }

  ngOnInit() {
    this.showSpin = true;
    this.getData();
  }
   
  getData() {
    this._customerService.getCompanyEventBycIdceId<ResponseModel<CompanyEventModel>>(this.companyEventID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.companyEvent = response.model;
        }
      }
      this.showSpin = false;
    });
  }


  clickPreviewDoc(event: any, doc: ContentDocumentModel) {

    let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
    let file = new Blob([byteArray], { type: doc.mimeType });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }


  openLink(link: string) {
    let url: string = '';
    if (!/^http[s]?:\/\//.test(link)) {
      link = '//' + link;
    }

    window.open(link, '_blank');
  }

  clickClose() {
    this._dialogRef.close();
  }
}
