<div style=" background: #f8f8f8 !important; padding: 1.5em; height: 100%; "> 
  <div style="background-color: white !important;  height: 100%; "> 
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> 
    <div style=" background-color: white;margin-left:1em;margin-right:1em; padding-bottom: 2em;" >
      <div style="justify-content: space-between;"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="d-inline-flex" style=" text-align: center; padding: 0.5em; ">
           <h4 style="margin-left: 0.3em;">Edit Message Folder Name</h4>

        </div> 
      </div>

      <div class="example-menu"> 
        <div style="margin-left: 2em; margin-bottom: .5em; display: flex;" >Folder Name:&nbsp;<h6 style="margin-top: 0.14em;">{{messageFolderName}}</h6></div> 
        <div style="width: 25em; display: flex; margin-left: 2em; margin-bottom: 1em;">
          <input type="text" style="width: 13em;" [(ngModel)]="editFolderName" maxlength="15" class="form-control form-control-sm" placeholder="Enter New Name" />
          <div *ngIf="showErrorMsg" class="small alert alert-danger p-0" style="font-size: smaller; width: max-content; margin-left: .5em;  ">
            <div>{{errorMsg}}</div>
            
          </div>          <!-- <button mat-button class="btn" (click)="updateFolderName( )">Update</button> -->
          <!-- <div style="margin-left: 1.3em; font-size: small;"> *Can only Enter Upto 15 Characters</div> -->
          <!-- <button mat-button class="btn" (click)="cancelEditFolder()">Cancel</button> -->
        </div> 
        <div *ngIf="!showSuccessMsg && showWarningMsg" style="height:1.5em; margin-top:0em; width: 26.5em; margin-bottom: .5em; margin-right:1em" >
          <app-warning-message [warningMsg]="warningMsg"></app-warning-message>
        </div>
      </div>
    </div> 
  </div> 

  <div style="display: inline-flex; max-width: 25em !important; margin-left: 2em; margin-top: 0.5em">

  <!-- <div> -->
    <!-- [disabled]=" editFolderName == '' || editFolderName.trim() == ''" -->
    <button mat-button class="btn-high"   (click)="updateFolderName( )">Update</button>

    <button type="button" mat-button class="btn-low" (click)="clickClose()" style="margin-left: 1em;">
      <div style="display:flex"> <i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
        <div>CLOSE</div>
      </div>
    </button>&nbsp;

  </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]="successMsg"></app-success-message>
    </div>
</div>