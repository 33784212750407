<form *ngIf="help_guide_edit_form" [formGroup]="help_guide_edit_form">
  <div style="background-color:#f8f8f8"  cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>
    <div class="p-2 d-flex justify-content-between align-items-center">
      <button mat-icon-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="d-inline-flex">
        <mat-icon>help_outline</mat-icon>
        <h5>Help Guides</h5>
      </div>
      <button mat-icon-button [mat-dialog-close]>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="d-flex justify-content-center" style="margin-top:-1em">
      <mat-form-field style="width:30em;align-content:center">
        <mat-label>Title</mat-label>
        <input matInput formControlName="helpGuideTitle">
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-start ml-3" style="padding-bottom:.5em">
      <div class="form-inline mr-2">
        <label style="font-size:small;margin-right:.5em">Audience</label>
        <select required formControlName="audience" [ngClass]="{ 'is-invalid': submitted && f.audience.errors }" class="form-control form-control-sm dropdown" (change)="updateCategoryList()">
          <option *ngFor="let audience of audienceList" [ngValue]="audience.audienceID">{{audience.audienceDescription}}</option>
        </select>
      </div>
      <div class="form-inline mr-2">
        <label style="font-size: small; margin-right: .5em">Category</label>
        <select formControlName="category" [ngClass]="{ 'is-invalid': submitted && f.category.errors }" class="form-control form-control-sm dropdown">
          <option *ngFor="let cat of categoryList" [ngValue]="cat.categoryID">{{cat.categoryTitle}}</option>
        </select>
      </div>
      <div class="form-inline mr-2">
        <label style="font-size: small; margin-right: .5em">Device</label>
        <select required formControlName="device" [ngClass]="{ 'is-invalid': submitted && f.device.errors }" class="form-control form-control-sm dropdown">
          <option *ngFor="let device of deviceTypeList" [ngValue]="device.deviceTypeID">{{device.deviceTypeDescription}}</option>
        </select>
      </div>
      <div class="form-inline mr-2">
        <label style="font-size: small; margin-right: .5em">Sort Order</label>
        <input type="text" formControlName="sort_order" class="form-control inputBox" style="width:3em" />
      </div>
      <div class="form-inline mr-2">
        <label style="font-size: small; margin-right: .5em">Disable</label>
        <select formControlName="is_active" [ngClass]="{ 'is-invalid': submitted && f.is_active.errors }" class="form-control form-control-sm dropdown">
          <option value="1">No</option>
          <option value="0">Yes</option>
        </select>
      </div>
    </div>
  </div>
  <div class="px-2" style="width:60em">
    <ckeditor #emailCKEditor data="ckEditorData" name="emailCKEditor" [editor]="Editor" [config]="CKEConfig" (ready)="onReady($event)" [disabled]="isDisabled" style="line-height:1.2em; background-color:#ffffff !important;"></ckeditor>
  </div>
</form>

<div class="d-flex justify-content-between">
  <div class="p-2 d-inline-flex">
    <div>
      <button mat-button class="btn-high mr-2" (click)="clickSave()">Save</button>
      <button mat-button class="btn-low" (click)="goBack()">Close</button>
    </div>
    <div *ngIf="errMsg!=''" style="font-size:small" class="alert alert-danger p-1 ml-2">
      {{errMsg}}
    </div>
  </div>
  <div *ngIf="showSuccessMsg" style="margin-top: .5em;">
    <app-success-message [successMsg]=""></app-success-message>
  </div>
  
  <div class="mr-2" *ngIf="modifiedBy!=''">
    <span style="font-size:small">Modified By: {{modifiedBy}} {{modifiedDate}}</span>
  </div>
</div>
