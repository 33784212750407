<!DOCTYPE html>
<html lang='en'>
<head>
  <meta http-equiv='Cache-Control' content='no-cache, max-age=0, no-store, must-revalidate, post-check=0' />
  <meta http-equiv='Pragma' content='no-cache' />
  <meta http-equiv='Expires' content='-1' />
  <meta charset='utf-8' />
  <title>FLightProConnect</title>
  <base href='/' />
  <meta name='viewport' content='width=device-width, initial-scale=1' />
  <link rel='icon' type='image/x-icon' href='favicon.ico' />
</head>
<body>
  <layout-header></layout-header>
  <!--<div *ngIf='showLoadingIndicator' class='spinner'></div>-->
  <router-outlet></router-outlet>
  <!--<layout-footer></layout-footer>-->
</body>
</html>
<!--<body>
  <app-nav-menu></app-nav-menu>
  <div class='container'>
    <router-outlet></router-outlet>
  </div>
</body>-->
