<table mat-table [dataSource]="nearbyAirports" class="example-container">
  <tr>
    <ng-container matColumnDef="preferred">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Preferred
      </th>
      <td mat-cell *matCellDef="let element">{{element.isPreferredAlternate? 'Yes':''}}</td>
    </ng-container>
    <ng-container matColumnDef="airport">
      <th mat-header-cell *matHeaderCellDef width="20%">
        Airport
      </th>
      <td mat-cell *matCellDef="let element" style="text-align:left !important; color:Highlight; cursor:pointer; text-decoration:underline" (click)="openAirportDetails(element)">{{element.icao}} {{element.iata}} {{element.airportName}} {{element.airportLocationWithCountryNameAbbr}}</td>
    </ng-container>
    <ng-container matColumnDef="distance">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Distance
      </th>
      <td mat-cell *matCellDef="let element">{{element.distanceFromAirport}}</td>
    </ng-container>
    <ng-container matColumnDef="airport_of_entry">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Airport of Entry
      </th>
      <td mat-cell *matCellDef="let element"  [ngStyle]="{'color': element.airportOfEntry=='Yes'? 'darkgreen':'', 'font-weight': element.airportOfEntry=='Yes'? '500':''}"><div>{{element.airportOfEntry}}</div></td>
    </ng-container>
    <ng-container matColumnDef="restrictions">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Restrictions
      </th>
      <td mat-cell *matCellDef="let element"  [ngStyle]="{'color': element.hasRestrictions? 'darkgreen':'', 'font-weight': element.hasRestrictions? '500':''}">{{element.hasRestrictions? 'Yes':'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="slots">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Slots
      </th>
      <td mat-cell *matCellDef="let element"  [ngStyle]="{'color': element.requiresSlotsBool? 'darkgreen':'', 'font-weight': element.requiresSlotsBool? '500':''}">{{element.requiresSlotsBool? 'Yes':'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="ppr">
      <th mat-header-cell *matHeaderCellDef width="5%">
        PPR
      </th>
      <td mat-cell *matCellDef="let element"  [ngStyle]="{'color': element.requiresPPR? 'darkgreen':'', 'font-weight': element.requiresPPR? '500':''}">{{element.requiresPPR? 'Yes':'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="landing_permit_private">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Landing Permit Private
      </th>
      <td mat-cell *matCellDef="let element"  [ngStyle]="{'color': element.requiresLandingPermitPrivate? 'darkgreen':'', 'font-weight': element.requiresLandingPermitPrivate? '500':''}">{{element.requiresLandingPermitPrivate? 'Yes':'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="landing_permit_charter">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Landing Permit Charter
      </th>
      <td mat-cell *matCellDef="let element"  [ngStyle]="{'color': element.requiresLandingPermitCharter? 'darkgreen':'', 'font-weight': element.requiresLandingPermitCharter? '500':''}">{{element.requiresLandingPermitCharter? 'Yes':'No'}}</td>
    </ng-container>

  </tr>
  <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
</table>
