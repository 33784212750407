export class GroundStopTaskDocumentModel {
  groundStopTaskGUID: string;
  groundStopTaskDocumentGUID: string;
  documentName: string;
  fileExtension: string; 
  documentSizeInKB: number;
  fileSize: number;
  bFile: string;
  mimeType: string;
  tempId: number;
  reportID: string;
  modifiedDate: Date;
  cityPair: string;
  containerName: string;
  containerPath: string;
  tripCode: string;
  taskDocErrMsg: string;
  selected: boolean;
  fuelQuoteGUID: string;
  isInternal: boolean;
}
