export class BusinessRulesModel {
  businessRulesID: number;
  customerName: string;
  registrationList: string;
  crewList: string;
  paxList: string;
  farTypeDescription: string;
  isUTC: boolean;
  effectiveDate: string;
  expirationDate: string;
  hoursStart: string;
  hoursEnd: string;
  sun: boolean;
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  countryName: string;
  icao: string;
  serviceClassDescription: string;
  serviceTypeDescription: string;
  methodOfPaymentDescription: string;
  remarks: string;
  selectedPersonGUIDList: string;
  selectedICAOList: string;
  selectedAircraftGUIDList: string;
  selectedCustomerGUIDList: string;
  selectedVendorGUIDList: string;
  selectedCountryGUIDList: string;
  sortBy: string;
  personRole: string;
  selectedLegTypeIDList: string;
  vendorName: string;
  approvalStatus: string;
  legType: string;
  customerID: number;
  farTypeID: number;
  legTypeID: number;
  serviceClassID: number;
  serviceTypeID: number;
  businessRules_ServiceStatusID: number;
  vendorID: number;
  leadTime: number;
  leadTimeValue: string;
  serviceDueDateTime: number;
  dueDateValue: string;
  businessRules_ApprovalStatusID: number;
  customerOnboardRule: boolean;
  customerApprovalRequired: boolean;
  customerGUID: string;
  aircraftGUIDList: string;
  picGuidList: string;
  paxGuidList: string;
  countryGUID: string;
  mtowMin: number;
  mtowMax: number;
  wingSpanMin: number;
  wingSpanMax: number;
  isActive: boolean;
  airportID: number;
  vendorGUID: string;
  modifiedBy: string;
  modifiedDate: string;
  serviceStatus: string;
  includeExpired: boolean;
  selectedApprovalStatusIDList: string;
  selectedServiceClassIDList: string;
  selectedServiceTypeIDList: string;
  taskStatusGUID: string;
  taskStatusDescription: string;
  avoidLocation: boolean;
  permitCountryGUID: string;
  excludeFromHomebase: boolean;
  immediateActionRequired: boolean;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
}
