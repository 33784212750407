import { ComponentPortal, Portal, PortalInjector } from "@angular/cdk/portal";
import { AfterViewInit, Component, Inject, Injector, Input, OnInit } from "@angular/core";
import { ManageRouteSequenceDialog } from "../ground-stops/manage-route-sequence-dialog.component";
import { ManageContingencyDialogComponent } from "../ground-stops/manage-contingency-dialog.component";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CONTAINER_DATA } from '../services/help-guide.service';

@Component({
    selector: 'app-route-sequence-dialog',
    templateUrl: './route-sequence-dialog.component.html',
    styleUrls: ['./route-sequence-dialog.component.css']
  })

export class RouteSequenceDialogComponent implements AfterViewInit {
    @Input() selectedPortal: Portal<any>;
    componentPortal: ComponentPortal<ManageRouteSequenceDialog>;
    type: string;
    
    constructor(@Inject(MAT_DIALOG_DATA) private _data: any, private _injector: Injector) {
        this.type = _data.type;
    }

    ngAfterViewInit(): void {
        this.componentPortal = new ComponentPortal(ManageRouteSequenceDialog, null, this.createInjector(this.type));
        this.selectedPortal = this.componentPortal;
    }

    createInjector(dataToPass): PortalInjector {
        const injectorTokens = new WeakMap();
        injectorTokens.set(CONTAINER_DATA, dataToPass);
        return new PortalInjector(this._injector, injectorTokens);
      }
}