import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { TripModel } from '../models/trip.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopManifestDocumentModel } from '../models/ground-stop-manifest-document.model';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { TripPersonEditorDialogComponent } from './trip-person-editor-dialog.component';
import { TripLegPersonStateModel } from '../models/trip-leg-person-state.model';
import { ServiceClassModel } from '../models/service-class.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { TripLegServiceStateModel } from '../models/trip-leg-service-state.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { TripServiceEditorDialogComponent } from './trip-service-editor-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { GlobalConstant } from '../common-utility/global-constant';

@Component({
  selector: 'app-trip-servicelist-editor-dialog',
  templateUrl: './trip-servicelist-editor-dialog.component.html',
  styleUrls: ['./trip-servicelist-editor-dialog.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('Legs per page') }
  ],
})

export class TripServicelistEditorDialogComponent implements OnInit, AfterViewInit {

  dialogTitle: string;
  customerGUID: string;
  aircraftGUID: string;
  tripCodeGUID: string;

  trip_servicelist_editor_form: UntypedFormGroup;
  loading = false;

  submitted = false;
  errMsg: string;
  errMsg2: string;
  msg: string;

  showSpin: boolean = false;
  userType: string;
  client_name: string;
  registration: string;
  trip_code: string;
  speed: number;
  route: string;
  isModified: boolean = false;

  allGroundStopList: GroundStopModel[] = [];
  activeGroundStopList: GroundStopModel[] = [];

  showSuccessMsg: boolean = false;

  displayedColumn: string[] = []; // = ['person_type', 'person_name', 'minor', 'display_leg_1', 'display_leg_2', 'display_leg_3', 'display_leg_4', 'display_leg_5', 'display_leg_6', 'display_leg_7', 'display_leg_8', 'display_leg_9', 'display_leg_10'];

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  lockdownTrip: boolean = false;
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";

  activeLegList: TripLegModel[] = [];
  displayLegList: TripLegModel[] = [];

  dataChanged: boolean;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  totalLegCount: number = 0;

  serviceClassList: ServiceClassModel[] = [];
  selectedServiceClass: ServiceClassModel = new ServiceClassModel();

  serviceTypeList: ServiceTypeModel[] = [];
  tripServiceTypeList: TripLegServiceStateModel[] = [];
  selection = new SelectionModel<ServiceTypeModel>(true, []);
  lastLegDatePassed: boolean = false;
  hideSelectColumn: boolean = false;
  upPII: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<TripServicelistEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService,
    private readonly _tripLegService: TripLegService, private readonly _dialog: MatDialog
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }

    this.tripCodeGUID = _data.tripCodeGUID;
    this.aircraftGUID = _data.aircraftGUID;

  }

  getAllData(): Observable<any[]> {

    let request = new TripModel();
    request.customerGUID = this.customerGUID;
    request.tripCodeGUID = this.tripCodeGUID;

    let getTripResponse = this._tripLegService.getActiveTripLegListForPersonEditBytId(request);
    let getServiceClass = this._groundStopAdvancedService.getServiceClassList();
    return forkJoin([getTripResponse, getServiceClass]);
  }

  ngOnInit() {

    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.errMsg2 = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.msg = "";

    this.route = "";

    this.client_name = "";
    this.registration = "";
    this.trip_code = "";

    this.allGroundStopList = [];
    this.activeGroundStopList = [];

    this.activeLegList = [];
    this.showSuccessMsg = false;

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].model != null) {
          this.activeLegList = responses[0].model;
          this.trip_code = this.activeLegList[0].tripCode;
          this.lockdownTrip = this.activeLegList[0].lockdownTrip;
          this.registration = this.activeLegList[0].registration;
          this.lastLegDatePassed = this.activeLegList[this.activeLegList.length - 1].nextArrivalDateTimePassed;
          let gs: GroundStopModel;
          let i: number = 0;
          this.activeLegList.forEach((x, index) => {
            gs = new GroundStopModel();
            //gs.groundStopGUID = x.groundStopGUID;
            gs.icao = x.departureICAO;
            // gs.groundStopTypeColor = x.departureGroundStopTypeGUID.toUpperCase()==GlobalConstant.contingencyGroundStopTypeGUID? x.departureGroundStopTypeColor:'';
            this.activeGroundStopList.push(gs);
            i = index;
          });

          // add last active stop 
          gs = new GroundStopModel();
          //gs.groundStopGUID = this.activeLegList[i].nextArrivalGroundStopGUID;
          gs.icao = this.activeLegList[i].nextArrivalICAO;
          this.activeGroundStopList.push(gs);

          this.totalLegCount = this.activeLegList.length;
          this.totalPageNumber = Math.ceil(this.totalLegCount / this.pageSize)
          let displayLeg: TripLegModel;
          if (this.currentPageIndex == 0) {
            this.activeLegList.forEach((x, index) => {
              if (index < this.pageSize) {
                displayLeg = new TripLegModel();
                displayLeg = x;
                this.displayLegList.push(displayLeg);
              }
            });
          }

          this.displayedColumn = [];
          //this.displayedColumn.push("select");                           
          this.displayedColumn.push("service_name");
          this.displayLegList.forEach((x) => {
            this.displayedColumn.push("display_leg_" + x.tripLegOrder);
          });
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.serviceClassList = responses[1].model;
          this.serviceClassList = this.serviceClassList.filter(x => x.serviceClassID != 0);
          this.selectedServiceClass = this.serviceClassList[0];
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.lockdownTrip || this.lastLegDatePassed) {
        this.hideSelectColumn = true;
      }
      this.initControls();
      this.getLegServiceTypeList();
      this.showSpin = false;

    });


  }

  initControls() {

    this.trip_servicelist_editor_form = this._formBuilder.group({
      service_class: [this.selectedServiceClass]

    });
  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //  this.routeRef.nativeElement.focus();
    //}, 500);
  }

  get f() { return this.trip_servicelist_editor_form.controls; }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.pageSize = e.pageSize;
    }

    this.setDisplayLeg();
  }

  getLegServiceTypeList() {
    this._authService.updateAccessTime();
    this.showSpin = true;
    let request = new TripModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.customerGUID = this.customerGUID;
    request.serviceClassID = this.selectedServiceClass.serviceClassID;
    this._tripLegService.getTripLegServiceTypeListBytIdsClass<ResponseModel<ServiceTypeModel[]>>(request).subscribe(response => {
      this.serviceTypeList = [];
      if (response != null && response.code == "200") {
        this.serviceTypeList = response.model;
        this.buildTripServiceTypeList();
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  buildTripServiceTypeList() {
    this.tripServiceTypeList = [];
    this.serviceTypeList.forEach(p => {
      let ts: TripLegServiceStateModel;
      ts = new TripLegServiceStateModel();
      p.selected = false;
      ts.serviceType = p;
      ts.tripLegState = [];
      this.displayLegList.forEach(l => {
        ts.tripLegState.push(this.getServiceTripLegState(p, l));
      });
      this.tripServiceTypeList.push(ts);
    });
  }

  getServiceTripLegState(s: ServiceTypeModel, l: TripLegModel): string {

    if (s.tripLegList.findIndex(x => x.leg_IDs == l.leg_IDs) >= 0) {
      return "check_circle";
    }

    //if (!l.nextArrivalDateTimePassed && !this.lockdownTrip) {           
    //    return 'add_circle';
    //}
    if (!this.lockdownTrip) {
      return 'add_circle';
    }
    return '';
  }

  setDisplayLeg() {
    this.displayLegList = [];
    let currentViewStartLeg: number;
    let currentViewEndLeg: number;
    currentViewStartLeg = this.currentPageIndex * this.pageSize;
    currentViewEndLeg = currentViewStartLeg + this.pageSize;
    this.displayLegList = this.activeLegList.filter((x, index) => x.tripLegOrder > currentViewStartLeg && x.tripLegOrder <= currentViewEndLeg);
    this.displayedColumn = [];
    /*this.displayedColumn.push("select");*/
    this.displayedColumn.push("service_name");
    this.displayLegList.forEach((x) => {
      this.displayedColumn.push("display_leg_" + x.tripLegOrder);
    });

    this.buildTripServiceTypeList();
  }

  changeServiceClass(e: any, item: ServiceClassModel) {
    if (this.selection.selected.length > 0) {
      let s = "Do you want to save the current selections before move to other service class?";
      this.confirmDialogRef = this._dialogService.openConfirmDialog(s);
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave(item, '');
        }
        else {
          this.selection = new SelectionModel<ServiceTypeModel>(true, []);
          this.selectedServiceClass = item;
          this.getLegServiceTypeList();
        }
      });
    }
    else {
      this.selection = new SelectionModel<ServiceTypeModel>(true, []);
      this.selectedServiceClass = item;
      this.getLegServiceTypeList();
    }

  }

  checkServiceTypeChange(e: any, item: ServiceTypeModel) {
    e ? this.selection.toggle(item) : null;
  }

  clickEditService(e: any, p: ServiceTypeModel, l: TripLegModel, actionType: string) {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      tripCodeGUID: this.tripCodeGUID, customerGUID: this.customerGUID, serviceTypeID: p.serviceTypeID, groundStopGUID: l.groundStopGUID, nextArrivalGroundStopGUID: l.nextArrivalGroundStopGUID,
      actionType: actionType, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripServiceEditorDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        this.isModified = true;
        this.getLegServiceTypeList();
      }
    });

  }

  clickSave(item: ServiceClassModel, actionName: string) {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    this.errMsg = "";
    let selectedList: ServiceTypeModel[];
    selectedList = [];
    this.selection.selected.forEach(row => {
      selectedList.push(row);
    });

    if (selectedList.length == 0) {
      this.errMsg = "Please select at least one service type.";
      return;
    }

    let legList: TripLegModel[] = [];
    let fIndex: number;
    legList = this.activeLegList.filter(x => !x.nextArrivalDateTimePassed);
    let gstList: GroundStopTaskModel[] = [];
    let gst: GroundStopTaskModel;
    selectedList.forEach(x => {
      legList.forEach(y => {
        if (x.serviceLocation == "D") {
          gst = new GroundStopTaskModel();
          gst.groundStopGUID = y.groundStopGUID;
          gst.serviceLocation = "D";
          gst.serviceTypeID = x.serviceTypeID;
          gstList.push(gst);
        }
        else {
          gst = new GroundStopTaskModel();
          gst.groundStopGUID = y.nextArrivalGroundStopGUID;
          gst.serviceLocation = "A";
          gst.serviceTypeID = x.serviceTypeID;
          gstList.push(gst);
        }
      });
    });

    this.loading = true;
    this._tripLegService.createGroundStopTaskFromLeg<ResponseModel<boolean>>(gstList).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          if (item != null) {
            this.selectedServiceClass = item;
          }
          this.selection = new SelectionModel<ServiceTypeModel>(true, []);
          if (actionName == 'close') {
            this.clickClose();
          }
          else {
            this.getLegServiceTypeList();
          }

        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    });


  }

  clickClose() {
    if (this.selection.selected.length > 0) {
      let s = "Do you want to save the current selections before close the page?";
      this.confirmDialogRef = this._dialogService.openConfirmDialog(s);
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          let item = new ServiceClassModel();
          item = this.selectedServiceClass;
          this.clickSave(item, 'close');
        }
        else {
          this._dialogRef.close(this.isModified);
        }
      });
    }
    else {
      this._dialogRef.close(this.isModified);
    }
  }


}
