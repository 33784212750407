import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { AircraftService } from '../services/aircraft.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { NoteTypeModel } from '../models/note-type.model';
import { AircraftNoteModel } from '../models/aircraft-note.model';
import { CountryModel } from '../models/country.model';
import { AircraftOperatorOwnerModel } from '../models/aircraft-operator-owner.model';
import { CustomerModel } from '../models/customer.model';
import { StateProvinceModel } from '../models/state-province.model';

@Component({
    selector: 'app-aircraft-owner-operator-edit-dialog',
    templateUrl: './aircraft-owner-operator-edit-dialog.component.html',
    styleUrls: ['./aircraft-owner-operator-edit-dialog.component.css']
})
/** aircraft-owner-operator-edit-dialog component*/
export class AircraftOwnerOperatorEditDialogComponent implements OnInit {
  aircraft_owner_operator_edit_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() aircraftGuid: string;
  @Input() aircraftOperatorOwnerLesseeID: number;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  countryList: CountryModel[];
  stateProvinceList: StateProvinceModel[];
  ownerOperatorType: number;
  lastName: string;
  firstName: string;
  middleName: string;
  companyName: string;
  phone: string;
  fax: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  countryGuid: string;
  zip: string;
  modifiedBy: string;
  modifiedDate: string;
  isModified: boolean;
  isActive: number;
  customerGuid: string;
  showSuccessMsg: boolean = false;
  isDisabled: boolean = false;
  userType: string;
  constructor(private readonly _dialogRef: MatDialogRef<AircraftOwnerOperatorEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _aircraftService: AircraftService,
    private readonly _dialogService: DialogService
  ) {
    this.aircraftGuid = data.aircraftGuid;
    this.aircraftOperatorOwnerLesseeID = data.aircraftOperatorOwnerLesseeID;
    this.dialogTitle = data.dialogTitle;

  }

  getAllData(): Observable<any[]> {

    let getCountryResponse = this._commonService.getCountryListWithUSAFirst<ResponseModel<CountryModel[]>>();
    let getStateProvinceResponse = this._commonService.getStateProvinceList<ResponseModel<StateProvinceModel[]>>('6AA9B42F-A693-4D74-83D8-A1500B0F1133');
    let getaircraftOwnerOperatorResponse;
    if (this.aircraftOperatorOwnerLesseeID != null && this.aircraftOperatorOwnerLesseeID != 0) {
      getaircraftOwnerOperatorResponse = this._aircraftService.getAircraftOperatorOwnerLesseeByAOOID<ResponseModel<AircraftOperatorOwnerModel>>(this.aircraftOperatorOwnerLesseeID);

    }
    else
      getaircraftOwnerOperatorResponse = of(null);
    return forkJoin([getCountryResponse, getStateProvinceResponse, getaircraftOwnerOperatorResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.isDisabled = true;
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this.errMsg = "";
    this.msg = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.countryList = responses[0].model;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.stateProvinceList = responses[1].model;

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }      

      if (this.aircraftOperatorOwnerLesseeID != null && this.aircraftOperatorOwnerLesseeID != 0) {
        if (responses[2] != null) {
          if (responses[2].code == "200" && responses[2].message == "") {
            let aircraftOwnerOperator = new AircraftOperatorOwnerModel();
            aircraftOwnerOperator = responses[2].model;
            this.ownerOperatorType = aircraftOwnerOperator.ownerOperatorTypeID;
          //  this.customerGuid = aircraftOwnerOperator.customerGuid;
            this.lastName = aircraftOwnerOperator.lastName;
            this.firstName = aircraftOwnerOperator.firstName;
            this.middleName = aircraftOwnerOperator.middleName;
            this.companyName = aircraftOwnerOperator.companyName;
            this.phone = aircraftOwnerOperator.phoneNumber;
            this.fax = aircraftOwnerOperator.faxNumber;
            this.email = aircraftOwnerOperator.email;
            this.address1 = aircraftOwnerOperator.streetAddress1;
            this.address2 = aircraftOwnerOperator.streetAddress2;
            this.city = aircraftOwnerOperator.city;
            this.state = aircraftOwnerOperator.stateProvince;
            this.countryGuid = aircraftOwnerOperator.countryGuid;
            this.zip = aircraftOwnerOperator.zipPostal;
            this.modifiedBy = aircraftOwnerOperator.modifiedBy;
            this.modifiedDate = aircraftOwnerOperator.modifiedDate;
            if (aircraftOwnerOperator.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;

          }
          else {
            if (responses[1].code == "401") {
              this._authService.signOut();
            }
          }
        }

      }

      this.initControls();

      this.showSpin = false;


    });
  
  }

  changeType() {
    if (this.f.owner_operator_type.value == 1) {
      this.f.client_select.clearValidators();
    }
    else {
      this.f.client_select.setValidators(Validators.required);
    }
    this.f.client_select.updateValueAndValidity;
  }

  initControls() {
    this.aircraft_owner_operator_edit_form = this._formBuilder.group({
      owner_operator_type: [{ value: this.ownerOperatorType, disabled: this.isDisabled }, Validators.required],
      last_name: { value: this.lastName, disabled: this.isDisabled },
      first_name: { value: this.firstName, disabled: this.isDisabled },
      middle_name: { value: this.middleName, disabled: this.isDisabled },
      company_name: { value: this.companyName, disabled: this.isDisabled },
      phone: [{ value: this.phone, disabled: this.isDisabled }, [Validators.required, Validators.pattern(/^[0-9- ()]+$/)]],
      fax: [{ value: this.fax, disabled: this.isDisabled }, Validators.pattern(/^[0-9- ()]+$/)],
      email_address: [{ value: this.email, disabled: this.isDisabled }, [Validators.required, Validators.email]],
      address1: [{ value: this.address1, disabled: this.isDisabled }, Validators.required],
      address2: { value: this.address2, disabled: this.isDisabled },
      city: [{ value: this.city, disabled: this.isDisabled }, Validators.required],
      zip: { value: this.zip, disabled: this.isDisabled },
      state: { value: this.state, disabled: this.isDisabled },
      country_select: [{ value: this.countryGuid, disabled: this.isDisabled }, Validators.required],
      record_status: { value: this.isActive, disabled: this.isDisabled },
      modifiedBy: { value: this.modifiedBy, disabled: this.isDisabled },
      modifiedDate: { value: this.modifiedDate, disabled: this.isDisabled }
    });
  }

  get f() { return this.aircraft_owner_operator_edit_form.controls; }

  

  clickSave() {
    this._authService.updateAccessTime();
    
    this.submitted = true;
    this.errMsg = "";
    if (this.f.last_name.value == null && this.f.company_name.value == null) {
      this.errMsg = "Enter either company name or person name.";
    }
   
    if (this.aircraft_owner_operator_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    let obj = new AircraftOperatorOwnerModel();
    obj.aircraftOperatorOwnerLesseeID = 0;
    if (this.aircraftOperatorOwnerLesseeID != 0 && this.aircraftOperatorOwnerLesseeID != null)
      obj.aircraftOperatorOwnerLesseeID = this.aircraftOperatorOwnerLesseeID;
    obj.aircraftGUID = this.aircraftGuid;
    obj.customerGuid = null;
    if (this.f.owner_operator_type.value == "1") {
      obj.ownerOperatorTypeID = 1;
    }
    else {
      obj.ownerOperatorTypeID = 2;
   //   obj.customerGuid = this.f.client_select.value
    }
    obj.lastName = this.f.last_name.value;
    obj.firstName = this.f.first_name.value;
    obj.middleName = this.f.middle_name.value;
    obj.companyName = this.f.company_name.value;
    obj.phoneNumber = this.f.phone.value;
    obj.faxNumber = this.f.fax.value;
    obj.email = this.f.email_address.value;
    obj.streetAddress1 = this.f.address1.value;
    obj.streetAddress2 = this.f.address2.value;
    obj.city = this.f.city.value;
    obj.zipPostal = this.f.zip.value;
    obj.stateProvince = this.f.state.value;
    obj.countryGuid = this.f.country_select.value;
    
    if (this.f.record_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;

    this._aircraftService.saveAircraftOperatorOwnerLessee<ResponseModel<number>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.aircraftOperatorOwnerLesseeID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString().padStart(2, "0");
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.aircraftOperatorOwnerLesseeID = 0;
    this.ownerOperatorType = 0;
    this.lastName = "";
    this.firstName = "";
    this.middleName = "";
    this.companyName = "";
    this.phone = "";
    this.fax = "";
    this.email = "";
    this.address1 = "";
    this.address2 = "";
    this.city = "";
    this.state = "";
    this.zip = "";
    this.countryGuid = "";
    this.customerGuid = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;   
    this.initControls();
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }


  getStateList() {
    let countryGUID = this.f.country_select.value;
    if (countryGUID == '6aa9b42f-a693-4d74-83d8-a1500b0f1133') {
      this._commonService.getStateProvinceList<ResponseModel<StateProvinceModel[]>>(countryGUID).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.stateProvinceList = response.model;
            this.state = "";
          }
        }
      });
    }
  }
}
