
export class AdvisoryDocumentModel {
  advisoryId: number;
  advisoryDocumentID: number;
  documentName: string;
  fileExtension: string;
  documentSizeInKB: number;
  containerName: string;
  containerPath: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  totalRecords: number;
  bFile: string;
  mimeType: string;
  isUploaded: boolean = false;
  tempId: number;
  fileSize: number;
}
