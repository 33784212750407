<div *ngIf="upPII && grantAccessToManageManifestProfiles" style="background-color:#f8f8f8;" >
  <div style="display: flex; justify-content: space-between;" cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>{{dialogTitle}}</h5></div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
      <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
    </div>-->
  </div>
  <div>
    <form *ngIf="customer_person_mail_edit_form" [formGroup]="customer_person_mail_edit_form" (ngSubmit)="clickSave()">
      <div style="display:flex; margin-top:0em; padding-left: 1em;padding-right:1em" >
        <div style="margin-top:0em;background-color:white; padding-left:0.5em;">
          <div style="display:flex">
            <div>
              <div style="display:flex">
                <div>
                  <!--for address-->
                  <div style="margin-top:0em; margin-right:0.625em">
                    <label for="address1" style="font-size:small">Street Address 1</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="address1" class="form-control" tabindex=1 [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" maxlength="255" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                    </div>
                    <div *ngIf="submitted && f.address1.errors" class="alert alert-danger p-0 small">
                      <div *ngIf="f.address1.errors.required">Address 1 is required.</div>
                    </div>
                    <div style="margin-top:0em; margin-right:0.625em">
                      <label for="address2" style="font-size:small">Street Address 2</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        <input type="text" formControlName="address2" class="form-control" tabindex=2 maxlength="255" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                      </div>
                    </div>
                    <div style="margin-top:0em; margin-right:0.625em">
                      <label for="address3" style="font-size:small">Street Address 3</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        <input type="text" formControlName="address3" class="form-control" tabindex=3 maxlength="255" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                      </div>
                    </div>
                    <!--for address end-->
                  </div>
                </div>
                <div>
                  <!--city-->
                  <div style="margin-top:0em; margin-right:0.625em">
                    <label for="address2" style="font-size:small">City</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="city" class="form-control" tabindex=4 maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                    </div>
                    <div *ngIf="submitted && f.city.errors" class="alert alert-danger p-0 small">
                      <div *ngIf="f.city.errors.required">City is required.</div>
                    </div>
                  </div>
                  <div style="margin-top:0em; margin-right:0.625em">
                    <label for="state_province" style="font-size:small">State/Province</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input *ngIf="f.country_select.value !='6aa9b42f-a693-4d74-83d8-a1500b0f1133'" type="text" formControlName="state_province" class="form-control" tabindex=5 maxlength="255" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                      <select *ngIf="f && f.country_select.value =='6aa9b42f-a693-4d74-83d8-a1500b0f1133'" formControlName="state_province" tabindex=5 class="form-control form-control-sm dropdown">
                        <option *ngFor="let state of stateProvinceList" [ngValue]="state.stateProvinceName">{{state.stateProvinceName}}</option>
                      </select>
                    </div>
                  </div>
                  <div style="margin-top:0em; margin-right:0.625em">
                    <label for="zipcode" style="font-size:small">Zip/Postal Code</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="zipcode" class="form-control" tabindex=6 maxlength="255" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                    </div>
                    <div *ngIf="submitted && f.zipcode.errors" class="alert alert-danger p-0 small">
                      <div *ngIf="f.zipcode.errors.maxlength" >Max 9 characters for APIS.</div>
                    </div>
                  </div>

                  <!--city end-->
                </div>
                <div>
                  <div style="margin-top:0em; margin-right:0.625em">
                    <label for="is_permnnent_address" style="font-size:small">Permanent Address</label>
                    <div style="margin-top:-0.5em;margin-bottom:0em">
                      <select *ngIf="f" formControlName="is_permnnent_address" style="width:8em;" tabindex=8 class="form-control form-control-sm dropdown">
                        <option value="1" selected>Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>
                  <div style="margin-top:0em; margin-right:0.625em">
                    <label for="is_apis_address" style="font-size:small">Used for APIS</label>
                    <div style="margin-top: -0.5em;margin-bottom:0em">
                      <select *ngIf="f" formControlName="is_apis_address" style="width:8em;" tabindex=9 class="form-control form-control-sm dropdown" (change)="updatePostalValidation()">
                        <option value="1" selected>Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="country_select" style="font-size:small">Country</label>
                <div class="form-group" style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="country_select" tabindex=7 class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.country_select.errors }">
                    <option *ngFor="let cty of countryList" [ngValue]="cty.countryGUID">{{cty.countryName}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.country_select.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.country_select.errors.required">Country is required.</div>
                </div>
              </div>
            </div>
            <div>
              
            </div>
          </div>
          <div style="display:flex">
            <div *ngIf="personMailAddressGUID!='' && userType=='internal'" style="margin-top:0em; margin-right:0.625em">
              <label for="data_entry_status_select" style="font-size:small">Data Entry Status</label>
              <div style="margin-top:-0.35em;margin-bottom:0em">
                <select *ngIf="f" formControlName="data_entry_status_select" class="form-control form-control-sm dropdown" >
                  <option *ngFor="let d of dataEntryStatusList" [ngValue]="d.dataEntryStatusID">{{d.dataEntryStatusDescription}}</option>
                </select>
              </div>
            </div>
            <!--disable-->
            <div *ngIf="personMailAddressGUID!=''" style="margin-top:0em; margin-right:0.625em">
              <label for="mail_address_status" style="font-size:small">Disable Record</label>
              <div style="margin-top:-0.35em;margin-bottom:0em">
                <select *ngIf="f" formControlName="mail_address_status" class="form-control form-control-sm dropdown">
                  <option value="1" selected>No</option>
                  <option value="0">Yes</option>
                </select>
              </div>
            </div>
            <div *ngIf="personMailAddressGUID!=''" style="margin-left: 0em;margin-top:0em;margin-right:.625em;">
              <label for="modifiedBy" style="font-size:small">Modified By<!--{{personMailAddressGUID!=''? 'Modified by': 'Created by' }}--></label>
              <div style="margin-bottom: 0em; margin-top:-0.35em; ">
                <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small; padding:0em" />
              </div>
            </div>
            <div *ngIf="personMailAddressGUID!=''" style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="modifiedDate" style="font-size:small">Modified Date<!--{{personMailAddressGUID!=''? 'Modified by': 'Created by' }}--></label>
              <div style="margin-bottom: 0em; margin-top:-0.35em; ">
                <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
              </div>
            </div>

            <!--disable end-->
          </div>
          <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; white-space:pre-wrap; background-color:#f9d2de;padding-left:0.25em; width:20em"><label style="color:black; font-size: small">{{errMsg}}</label></div>

        </div>
        <div style="width:0.5em; background-color: #f8f8f8;"></div>
        <div style="margin-top:0em; padding-left: 0.5em; padding-right:0em;background-color: white;padding-top:.5em">
          <!--<div style="font-size: small; margin-left:0em">Select Address Types</div>-->
          <div style="display:flex">
            <div *ngIf="userType=='internal' || (userType=='customer' && personMailAddressGUID=='')" style="height: 18em;width:12em; overflow-y: auto;overflow-x:hidden">
              <table mat-table *ngIf="avaiMailAddressTypeList" [dataSource]="avaiMailAddressTypeList" class="example-container2 mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="addressType">
                    <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">
                      Mail Address Type
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickAddItem($event, element)">{{element.mailAddressTypeDesc}}</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
            <div *ngIf="userType=='internal' || (userType=='customer' && personMailAddressGUID=='')" style="margin-top: 2em; margin-left:0em">
              <button type="button" mat-button class="btn-high" [hidden]="avaiMailAddressTypeList && avaiMailAddressTypeList.length==0" (click)="clickSelectAll()" style="width: 7em">Select All</button><br /><br />
              <button type="button" mat-button class="btn-low" [hidden]="selMailAddressTypeList && selMailAddressTypeList.length==0" (click)="clickRemoveAll()" style="width: 7em">Remove All</button>
            </div>
            <div style="margin-left:1em; height: 18em;width:12em; overflow-y: auto;overflow-x:hidden">
              <table mat-table *ngIf="selMailAddressTypeList" [dataSource]="selMailAddressTypeList" class="example-container2 mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="addressType">
                    <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">
                      Selected Address Types
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickRemoveItem($event, element)">{{element.mailAddressTypeDesc}}</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
          </div>
          <div *ngIf="submitted && selMailAddressTypeList.length == 0" class="alert alert-danger p-0 small" style="width:max-content">You must select at least one address type.</div>
          </div>
        </div>
    </form>

  </div>

  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom: 0.5em; display: flex; height:3em">
    <div style="margin-right:1em">
      <button mat-button class="btn-high" [disabled]="loading" *ngIf="(personMailAddressGUID=='' && userType=='customer') || userType=='internal'" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Save&nbsp;</button>&nbsp;
      <button mat-button class="btn-high" *ngIf="personMailAddressGUID!='' && userType=='internal'" (click)="clickAddNew()">&nbsp;Add new Record&nbsp;</button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white">&nbsp;&nbsp;Close&nbsp;&nbsp;</button>
    </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>
