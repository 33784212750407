<div style="background-color:#f8f8f8;height:100%">
  <div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="display: flex; justify-content: space-between;">

    <div style="padding-left:1em;margin-top:0.3125em;">
      <h5>
        Aircraft Profile
      </h5>
    </div>
  </div>
  <div>
    <form [formGroup]="aircraft_main_edit_form" (ngSubmit)="clickSave()">
      <div style="display:flex; margin-top:0em; padding-left: 1em;padding-right:1em">
        <div>
          <div style="margin-top:0em;background-color:white; padding-left:0.5em; ">
            <div class="d-flex">
              <div *ngIf="userType=='internal'" class="form-group" style="margin-top:0em; margin-right:0.625em;">
                <label for="customer_select" style="font-size:small">Client</label>
                <div style="margin-top:-0.35em; font-size: small;">
                  <!-- <select *ngIf="f" formControlName="customer_select" class="form-control form_control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.customer_select.errors }">
                    <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                  </select> -->
                  {{selectedCustomerName}}
                </div>
                <div *ngIf="submitted && f.customer_select.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="submitted && f.customer_select.errors.required">Client is a required field.</div>
                </div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="registration" style="font-size:small">Registration</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="registration" style="padding: 0em;width:8em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control text-uppercase" [ngClass]="{ 'is-invalid': submitted && f.registration.errors }" />
                </div>
                <div *ngIf="submitted && f.registration.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="submitted && f.registration.errors.required">Registration is a required field.</div>
                </div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="registration" style="font-size:small">Call Sign</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="call_sign" style="padding: 0em;width:8em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control"  />
                </div>
              </div>

            </div>
            <div style="display:flex">
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="aircraft_manufacturer" style="font-size:small">Aircraft Manufacturer</label>
                <div class="form-group" style="margin-top:-0.35em;">
                  <select *ngIf="f" (change)="changeManufacturer($event)" formControlName="aircraft_manufacturer" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.aircraft_manufacturer.errors }">
                    <option value="0" selected></option>
                    <option *ngFor="let man of acManufacturerList" [ngValue]="man.aircraftManufacturerID">{{man.aircraftManufacturerName}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.aircraft_manufacturer.errors" class="alert alert-danger p-0 small" style="margin-top:-1em">
                  <div *ngIf="submitted && f.aircraft_manufacturer.errors.required">Aircraft manufacturer is a required field.</div>
                </div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="aircraft_model" style="font-size:small">Aircraft Model</label>
                <div class="form-group" style="margin-top:-0.35em;">
                  <select *ngIf="f" formControlName="aircraft_model" (change)="changeACModel($event)" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.aircraft_model.errors }">
                    <option value="0" selected></option>
                    <option *ngFor="let at of acTypeList" [ngValue]="at.aircraftTypeID">{{at.aircraftModel}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.aircraft_model.errors" class="alert alert-danger p-0 small" style="margin-top:-1em">
                  <div *ngIf="f.aircraft_model.errors.required">Aircraft model is a required field.</div>
                </div>
              </div>
              <div style="margin-top:0em; margin-right:0.625em">
                <label for="aircraft_type" style="font-size:small">Type</label>
                <div style="margin-top:-0.35em;">
                  <input type="text" *ngIf="f" formControlName="aircraft_type" readonly style="padding: 0em;width:6em;height:1.5625em;font-size:small;background-color:#eaf0fd" class="form-control" />

                </div>
              </div>

            </div>
            <div *ngIf="aircraftGuid!=''">
              <div style="display:inline-flex;margin-left:0em;margin-bottom:.5em">
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="aircraft_status" style="font-size:small">Disable Record</label>
                  <div style="margin-top:-0.35em;margin-bottom:0em">
                    <select *ngIf="f" formControlName="aircraft_status" class="form-control form-control-sm dropdown">
                      <option value="1" selected>No</option>
                      <option value="0">Yes</option>
                    </select>
                  </div>
                </div>

                <div>
                  <label for="modifiedBy" style="font-size:small">Modified By</label>
                  <div style="margin-top:-0.35em;margin-right:.625em;">
                    <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
                  </div>
                </div>
                <div>
                  <label for="modifiedDate" style="font-size:small">Modified Date</label>
                  <div style="margin-top:-0.35em;margin-right:.625em;">
                    <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!--<div style="margin-top:1em;background-color:white; padding-left:0.5em; ">
            <div style="margin-top:0em; margin-right:0.625em;display:inline-flex">
              <div>
                <label for="cus_filter" style="font-size:small">Filter Client List</label>
                <div style="margin-top:-0.5em;">
                  <input type="text" *ngIf="f" formControlName="cus_filter" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd;" class="form-control" />

                </div>
              </div>
              <div>
                <button class="btn btn-primary" style="margin-top:1.3em;margin-left:.5em" (click)="filterClientList()">Search</button>
              </div>
            </div>
            <div style="margin-top:.5em; padding-left: 0em; padding-right:0em;margin-right:1em;">

              <div style="display:flex">
                <div style="height: 18em;width:16em; overflow-y: auto;overflow-x:hidden;">
                  <table mat-table *ngIf="availClientList" [dataSource]="availClientList" class="example-container2">
                    <tr>
                      <ng-container matColumnDef="clientName">
                        <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                          Available ACTIVE Client Account List
                        </th>
                        <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickAddItem($event, element)">{{element.customerName}}</a></td>
                      </ng-container>
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                  </table>

                </div>
                <div style="margin-top: 2em;margin-left:1em">
                  <button type="button" class="btn btn-primary" [hidden]="availClientList && availClientList.length==0" (click)="clickSelectAll()" style="width: 7em">Select All</button><br /><br />
                  <button type="button" class="btn btn-secondary" [hidden]="selClientList && selClientList.length==0" (click)="clickRemoveAll()" style="width: 7em">&nbsp;Remove All&nbsp;</button>
                </div>
                <div style="margin-left:1em;height: 18em;width:16em; overflow-y: auto;overflow-x:hidden">
                  <table mat-table *ngIf="selClientList" [dataSource]="selClientList" class="example-container2">
                    <tr>
                      <ng-container matColumnDef="clientName">
                        <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                          Selected Client Accounts
                        </th>
                        <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickRemoveItem($event, element)">{{element.customerName}}</a></td>
                      </ng-container>
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>-->

        </div>

      </div>
    </form>
  </div>

  <div>
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em">
      <button mat-button class="btn-high" (click)="clickSave()">Save</button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()">Close</button>&nbsp;
      <button mat-button *ngIf="aircraftGuid!='' && dialogTitle!='EDIT Record'" class="btn-high" (click)="clickAircraftProfile()">Aircraft Profile</button>
    </div>
    <div class="ml-3" style="width:min-content">
      <app-success-message [successMsg]="" *ngIf="showSuccessMsg"></app-success-message>
    </div>
    <div class="ml-3" >
      <label *ngIf="errMsg!=''" class="alert alert-danger py-0 px-1 small mt-2">{{errMsg}}</label>
    </div>
  </div>
</div>
