import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { CustomerService } from '../services/customer.service';
import { AircraftModel } from '../models/aircraft.model';
import { GroundStopService } from '../services/ground-stop.service';
import { TripRevisionModel } from '../models/trip-revision.model';
import { ReturnObjModel } from '../models/return-obj.model';

@Component({
  selector: 'app-aircraft-selection-dialog',
  templateUrl: './aircraft-selection-dialog.component.html',
  styleUrls: ['./aircraft-selection-dialog.component.css']
})

export class AircraftSelectionDialogComponent implements OnInit {
  //@Input() dialogTitle: string;
  //@Input() customerGUID: string;

  dialogTitle: string;
  customerGUID: string;
  tripCodeGUID: string;
  aircraft_selection_form: UntypedFormGroup;
  loading = false;

  submitted = false;
  errMsg: string;
  msg: string;

  showSpin: boolean = true;
  userType: string;
  aircraftList: AircraftModel[];
  selectedAircraft: AircraftModel;
  selectedAircraftGUID: string;
  isModified: boolean = false;
  oldAircraftGUID: string;
  showSuccessMsg: boolean = false;
  returnObj = new ReturnObjModel();
  upPII: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<AircraftSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _customerService: CustomerService,
    private readonly _groundStopService: GroundStopService,
    private readonly _dialogService: DialogService
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }
    if (_data.aircraftGUID == null || _data.aircraftGUID == undefined) {
      this.selectedAircraftGUID = "";
    }
    else {
      this.selectedAircraftGUID = _data.aircraftGUID;
    }
    this.oldAircraftGUID = this.selectedAircraftGUID;
    if (_data.tripCodeGUID == null || _data.tripCodeGUID == undefined) {
      this.tripCodeGUID = "";
    }
    else {
      this.tripCodeGUID = _data.tripCodeGUID;
    }
    this.dialogTitle = _data.dialogTitle;

  }

  getAllData(): Observable<any[]> {  
    let getAircraftResponse = this._customerService.getRegistrationListByCustomerGUID<ResponseModel<AircraftModel[]>>(this.customerGUID)
    return forkJoin([getAircraftResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }

    this.msg = "";
    
    this.aircraftList = [];
    
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
 
    this.getAllData().subscribe(responses => {
      
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.aircraftList = responses[0].model;
          if (this.selectedAircraftGUID != "") {
            this.selectedAircraft = this.aircraftList.find(x => x.aircraftGuid.toLowerCase() == this.selectedAircraftGUID.toLowerCase());
          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;

    });
    

  }

  initControls() {
    this.aircraft_selection_form = this._formBuilder.group({
      aircraft: [this.selectedAircraft],
      revisionComment: ['', Validators.required]
      

    });

  }

  get f() { return this.aircraft_selection_form.controls; }

  changeAircraft(item: AircraftModel) {
    this.errMsg = "";
  }

  clickSave() {
    
    this._authService.updateAccessTime();
    this.submitted = true;
    

    if (this.aircraft_selection_form.invalid || this.errMsg != "") {
      return;
    }

    let ac: AircraftModel;
    ac = this.f.aircraft.value;
    this.selectedAircraftGUID = ac.aircraftGuid;

    if (this.selectedAircraftGUID.toLowerCase() == this.oldAircraftGUID.toLowerCase()) {
      this.errMsg = "You did not change aircraft";
      return;
    }  
    
    let request = new TripRevisionModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.aircraftGUID = this.selectedAircraftGUID;
    request.userComments = this.f.revisionComment.value;

    this._groundStopService.updateAircraftBytId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.showSuccessMsg = true;
          this.isModified = true;
          this.returnObj.refresh = this.isModified;
          this.returnObj.aircraftGUID = this.selectedAircraftGUID;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);

        }
        else {
          this.errMsg = "Failed to update the aircraft at this time. Please try later.";
        }
        //this.showSpin = false;
        this.loading = false;

      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to update the aircraft at this time. Please try later.";
        }
        this.loading = false;
      }
      //this.showSpin = false;
    })   

       
  }

  clickClose() {
    //this._dialogRef.close(this.isModified);
    this._dialogRef.close(this.returnObj);
  }


}
