import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { CommAddressTypeModel } from '../models/comm-address-type.model';
import { AddressRatingModel } from '../models/address-rating.model';
import { AddressUseModel } from '../models/address-use.model';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { DataEntryStatusModel } from '../models/data-entry-status.model';

@Component({
  selector: 'app-customer-comm-edit-dialog',
  templateUrl: './customer-comm-edit-dialog.component.html',
  styleUrls: ['./customer-comm-edit-dialog.component.css']
})

export class CustomerCommEditDialogComponent implements OnInit, AfterViewInit {
  //@Input() dialogTitle: string;
  //@Input() personCommsAddressGUID: string;
  //@Input() customerGUID: string;
  dialogTitle: string;
  personCommsAddressGUID: string;
  customerGUID: string;
  customer_comm_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = false;
  addressRatingList: AddressRatingModel[];
  commAddressTypeList: CommAddressTypeModel[];
  addressUseList: AddressUseModel[];
  avaiAddressUseList: AddressUseModel[];
  selAddressUseList: AddressUseModel[];
  selectedCommAddressType: string;
  selectedCommAddressTypeId: string
  selectedAddressRatingId: string;
  address: string;
  extension: string;
  remarks: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  displayedColumn: string[] = ['addressUse'];
  personId: string;
  isModified: boolean = false;
  isPreferred: boolean=false;
  addressLabel: string = "Number";
  showSuccessMsg: boolean = false;
  userType: string;
  upPII: boolean = false;
  grantAccessCompanyProfiles: boolean = false;
  isDisabled: boolean = false;
  dataEntryStatusList: DataEntryStatusModel[];
  selectedDataEntryStatusID: number = 0;
  constructor(private readonly _dialogRef: MatDialogRef<CustomerCommEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _customerService: CustomerService,
    private readonly _dialogService: DialogService
    ) {
    this.personCommsAddressGUID = _data.personCommsAddressGUID;
    if (_data.customerGUID == null) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }
    this.dialogTitle = _data.dialogTitle;

  }

  getAllData(): Observable<any[]> {
    let getCommAddressTypeResponse = this._commonService.getCommAddressTypeList<ResponseModel<CommAddressTypeModel[]>>();
    let getAddressRatingResponse = this._commonService.getAddressRatingList<ResponseModel<AddressRatingModel[]>>();
    let getAddressUseResponse = this._commonService.getCommAddressUseList<ResponseModel<AddressUseModel[]>>(this.personCommsAddressGUID);
    let getPersonIdResponse = this._customerService.getCustomerOfficePersonId<ResponseModel<string>>(this.customerGUID);
    let getPersonCommsResponse;
    if (this.personCommsAddressGUID != null && this.personCommsAddressGUID != "") {
      getPersonCommsResponse = this._commonService.getPersonCommsAddressBypcaId(this.personCommsAddressGUID);
    }
    else {
      getPersonCommsResponse = of(null);
    }
    let dataEntryStatusResponse;
    if (this.userType == "internal") {
      dataEntryStatusResponse = this._commonService.getDataEntryStatusList<ResponseModel<DataEntryStatusModel[]>>();
    }
    else {
      dataEntryStatusResponse = of(null);
    }
    return forkJoin([getCommAddressTypeResponse, getAddressRatingResponse, getAddressUseResponse, getPersonIdResponse, getPersonCommsResponse, dataEntryStatusResponse]);
    //if (this.personCommsAddressGUID != null && this.personCommsAddressGUID != "") {
    //  let getPersonCommsResponse = this._commonService.getPersonCommsAddressBypcaId(this.personCommsAddressGUID);
    //  return forkJoin([getCommAddressTypeResponse, getAddressRatingResponse, getAddressUseResponse, getPersonIdResponse, getPersonCommsResponse]);
    //}
    //else
    //  return forkJoin([getCommAddressTypeResponse, getAddressRatingResponse, getAddressUseResponse, getPersonIdResponse]);
  }

  ngOnInit() { 
    this._authService.updateAccessTime();
    this.grantAccessCompanyProfiles = false;
    if (localStorage.getItem('up.cPro') == 'true') {
      this.grantAccessCompanyProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }

    if (!this.upPII || !this.grantAccessCompanyProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
    this.avaiAddressUseList = [];
    this.selAddressUseList = [];
    this.selectedCommAddressType = "";
    this.selectedCommAddressTypeId = "";
    this.selectedAddressRatingId="";
    this.address="";
    this.extension="";
    this.remarks="";
    this.modifiedBy="";
    this.modifiedDate="";
    this.isActive = 1;
    this.showSuccessMsg = false;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType == 'customer' && this.personCommsAddressGUID!='') {
      this.isDisabled = true;
    }
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.commAddressTypeList = responses[0].model;
          this.selectedCommAddressTypeId = this.commAddressTypeList[0].commAddressTypeGUID;
          this.selectedCommAddressType = this.commAddressTypeList[0].commAddressTypeDesc;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
       
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.addressRatingList = responses[1].model;
          this.selectedAddressRatingId = this.addressRatingList[0].addressRatingGUID;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.addressUseList = responses[2].model;
          //this.setAddressUseList();
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.personId = responses[3].model;
          //this.setAddressUseList();
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.personCommsAddressGUID != null && this.personCommsAddressGUID != "") {
        if (responses[4] != null) {
          if (responses[4].code == "200" && responses[4].message == "") {
            let personComm = new PersonCommsAddressModel();
            personComm = responses[4].model;
            this.selectedCommAddressTypeId = personComm.commAddressTypeGUID;
            this.selectedCommAddressType = personComm.commAddressType;
            switch (this.selectedCommAddressType) {
              case "Office Phone":
              case "Home Phone":
              case "Mobile Phone":
              case "Fax":
                this.addressLabel = "Number";
                break;
              default:
                this.addressLabel = "Address";
                break;
            }
            this.selectedAddressRatingId = personComm.addressRatingGUID;
            this.address = personComm.address;
            this.extension = personComm.extension;
            this.remarks = personComm.commRemarks;
            this.modifiedBy = personComm.modifiedBy;
            this.modifiedDate = personComm.modifiedDate;
            if (personComm.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
            this.isPreferred = personComm.isPreferred;
            this.selectedDataEntryStatusID = personComm.dataEntryStatusID;
          }
          else {
            if (responses[4].code == "401") {
              this._authService.signOut();
            }
          }
        }
       
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          this.dataEntryStatusList = responses[5].model;

        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();
      
      this.showSpin = false;

      this.setAddressUseList();
      //setTimeout(() => {
      //  this._chr.detectChanges();
      //}, 500);

    });

  }

  ngAfterViewInit() {
    
  }

  initControls() {
    this.customer_comm_edit_form = this._formBuilder.group({
      comm_address_type_select: { value: this.selectedCommAddressTypeId, disabled: this.isDisabled },
      comm_address_status: { value: this.isActive, disabled: this.isDisabled},
      address: [{ value: this.address, disabled: this.isDisabled}, Validators.required],
      extension: [{ value: this.extension, disabled: this.isDisabled}, Validators.pattern("^[0-9- ().]*$")],
      remarks: { value: this.remarks, disabled: this.isDisabled },
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      isPreferred: { value: this.isPreferred, disabled: this.isDisabled },
      data_entry_status_select: [this.selectedDataEntryStatusID],

    });
  }

  get f() { return this.customer_comm_edit_form.controls; }

  addressChange() {
    this.errMsg = "";
    let typevalue = this.f.comm_address_type_select.value; //;this.customer_comm_edit_form.get("comm_address_type_select").value;
    let type = this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.f.comm_address_type_select.value)[0].commAddressTypeDesc; //this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.customer_comm_edit_form.get("comm_address_type_select").value)[0].commAddressTypeDesc;
    this.validateCommAddress(this.customer_comm_edit_form.get("address").value, type);
  }

  validateCommAddress(address: string, type: string) {
    switch (type.toLowerCase()) {
      case 'aftn':         
      case 'arinc':     
      case 'sita':
        this.f.address.setValue(this.f.address.value.toUpperCase());
        break;
    }
    let r = CustomValidators.validateCommonAddress(address, type);
    if (r == false) {
      switch (type.toLowerCase()) {
        case 'office phone':
          this.errMsg = "Invalid phone number.";
          return;
        case 'home phone':
          this.errMsg = "Invalid phone number.";
          return;
        case 'mobile phone':
        case 'mobile':
          this.errMsg = "Invalid mobile phone number.";
          return;
        case 'operations phone':
          this.errMsg = "Invalid operations phone.";
          return;
        case 'fax':
          this.errMsg = "Invalid fax number.";
          return;
        case 'email':
          this.errMsg = "Invalid email.";
          return;
        case 'aftn':
          this.errMsg = "AFTN must be 8 alphanumeric characters.";
          return;
        case 'arinc':
          this.errMsg = "ARINC must be 7 alphanumeric characters.";
          return;
        case 'sita':
          this.errMsg = "SITA must be 7 alphanumeric characters.";
          return;
      }
    }
  }

  commAddressTypeChange(e: any) {
    this.errMsg = "";
    let text = e.target.options[e.target.options.selectedIndex].text;
    this.selectedCommAddressType = text;
    this.f.extension.setValue('');
    switch (text) {
      case "Office Phone":
      case "Home Phone":
      case "Mobile Phone":
      case "Mobile":
      case "Fax":
      case "Operations Phone":
        this.addressLabel = "Number";
        break;
      default:
        this.addressLabel = "Address";

    }
  }

  clickAddItem(e: any, item: AddressUseModel) {
    this.addressUseList.forEach(x => {
      if (x.addressUseGUID.toLowerCase() == item.addressUseGUID.toLowerCase()) {
        x.selected = true;
      }
    })
    this.setAddressUseList();

  }

  clickRemoveItem(e: any, item: AddressUseModel) {
    this.addressUseList.forEach(x => {
      if (x.addressUseGUID.toLowerCase() == item.addressUseGUID.toLowerCase()) {
        x.selected = false;
      }
    })
    this.setAddressUseList();

  }

  setAddressUseList() {
    this.errMsg = "";
    this.avaiAddressUseList = this.addressUseList.filter(u => u.selected == false);
    this.selAddressUseList = this.addressUseList.filter(u => u.selected == true);
  }

  clickSelectAll() {
    this.addressUseList.forEach(x => {
      x.selected = true;
    })

    this.setAddressUseList();
  }

  clickRemoveAll() {
    this.addressUseList.forEach(x => {
      x.selected = false;
    })

    this.setAddressUseList();
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    let type = this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.f.comm_address_type_select.value)[0].commAddressTypeDesc;
    if (this.f.address.value != "") {
      this.validateCommAddress(this.f.address.value, type);
    }   

    if (this.selAddressUseList.length == 0) {
      if (this.errMsg != "") {
        this.errMsg += "\n";
        this.errMsg += "You must select at least one address use.";
      }
      else
        this.errMsg = "You must select at least one address use.";
    }
    if (this.customer_comm_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    let obj = new PersonCommsAddressModel(); 
     obj.personCommsAddressGUID="";
    if (this.personCommsAddressGUID != "" && this.personCommsAddressGUID != null)
      obj.personCommsAddressGUID = this.personCommsAddressGUID;
    obj.commAddressTypeGUID = this.f.comm_address_type_select.value;
  //  obj.addressRatingGUID = this.f.address_rating_select.value;
    obj.addressUseGUIDList = Array.prototype.map.call(this.selAddressUseList, s => s.addressUseGUID).toString();
    obj.address = this.f.address.value;
    obj.extension = "";
    obj.isPreferred = this.isPreferred;
    if (type.toLowerCase() == "phone" && this.f.extension.value!="")
      obj.extension = this.f.extension.value;
    if (this.f.comm_address_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;
    obj.commRemarks = this.f.remarks.value;
    obj.personGUID = this.personId;
    obj.dataEntryStatusID = 0;
    if (this.userType == 'internal')
      obj.dataEntryStatusID = Number(this.f.data_entry_status_select.value);
    this._commonService.savePersonCommsAddress<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.personCommsAddressGUID = response.model;
          if (this.userType == 'internal' && obj.personCommsAddressGUID == "" && this.personCommsAddressGUID != "") {
            this.selectedDataEntryStatusID = 2;
            this.f.data_entry_status_select.setValue(this.selectedDataEntryStatusID);
          }
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          //if (obj.personCommsAddressGUID=="")
          //  this._dialogService.openAlertDialog("Added successfully.");
          //else
          //  this._dialogService.openAlertDialog("Modified successfully.");
          //this._dialogService.openAlertDialog("");
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          //this._dialogRef.close(this.isModified);

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  checkPreferred(event: any) {
    this.isPreferred = event.checked;
  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.avaiAddressUseList = [];
    this.selAddressUseList = [];
    this.selectedCommAddressType = "";
    this.selectedCommAddressTypeId = this.commAddressTypeList[0].commAddressTypeGUID;
    this.selectedCommAddressType = this.commAddressTypeList[0].commAddressTypeDesc;
    this.selectedAddressRatingId = this.addressRatingList[0].addressRatingGUID;
    this.address = "";
    this.extension = "";
    this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.personCommsAddressGUID = "";
    this.dialogTitle = "ADD New Record";
    this.initControls();    
    this.addressUseList.forEach(x => {
      x.selected = false;
    })
    this.avaiAddressUseList = this.addressUseList.filter(u => u.selected == false);
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }


}
