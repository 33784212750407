import { Component, Inject, OnInit } from "@angular/core";
import { GroundStopClientService } from "../services/ground-stop-client.service";
import { TripModel } from "../models/trip.model";
import { ResponseModel } from "../models/response.model";
import { GroundStopModel } from "../models/ground-stop.model";
import { AuthenticateService } from "../services/authenticate.service";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-manage-route-sequence-confirmation-dialog',
    templateUrl: './manage-route-sequence-confirmation-dialog.component.html',
    styleUrls: ['./manage-route-sequence-confirmation-dialog.component.css']
})

export class RouteSequenceConfirmationDialog implements OnInit {
    tripCode: string;
    aircraftGUID: string;
    tripCodeGUID: string;
    customerGUID: string;
    tripID: number;
    errMsg: string = "";
    showSuccessMsg: boolean = false;
    successMsg: string = "";
    
    constructor(private readonly _groundStopClientService: GroundStopClientService,
        private readonly _authService: AuthenticateService, @Inject(MAT_DIALOG_DATA) private _data: any,
        private readonly _dialogRef: MatDialogRef<RouteSequenceConfirmationDialog>) {

    }

    ngOnInit(): void {
        
    }

    updateRouteSequenceAndRefresh() {
        let request = new TripModel();
        request.aircraftGUID = this._data.aircraftGUID;
        request.customerGUID = this._data.customerGUID;
        request.startDateText = localStorage.getItem('trip.sd');
        request.endDateText = localStorage.getItem('trip.ed');
        request.tripCodeGUID = this._data.tripCodeGUID;
        request.tripID = this._data.tripID;
        request.groundStopList = this._data.groundStopList;

        this._groundStopClientService.updateTripSequenceIds<ResponseModel<TripModel>>(request).subscribe(response => {
            if (response != null) {
                if (response.code == "200" && response.message == "") {
                    this.errMsg = "";
                    this.showSuccessMsg = true;
                    this.successMsg = "Successfully updated sequence numbers.";
                    this._dialogRef.close(request);
                }
                else {
                    if (response.code == "401")
                        this._authService.signOut();
                    else
                        this.errMsg = response.message;
                }
            }
        });
    }

}