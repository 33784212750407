import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, ViewEncapsulation, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { TripLegModel } from '../models/trip-leg.model';
import { TripModel } from '../models/trip.model';
import { DisplayDialogComponent } from '../ckeditor5/display-dialog.component';
import { Title } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';
import { CustomerModel } from '../models/customer.model';
import { CommonFilter } from '../models/trip-closeout-queue.model';
import { AircraftModel } from '../models/aircraft.model';
import { AircraftService } from '../services/aircraft.service';
import { PersonModel } from '../models/person.model';
import { PersonService } from '../services/person.service';
import { IdentityService } from '../services/identity.service';
import { UserModel } from '../models/user.model';
import { CustomerPersonDetailsComponent } from '../persons/customer-person-details.component';
import { AircraftProfileEditComponent } from '../aircraft/aircraft-profile-edit.component';


@Component({
  selector: 'app-trip-code-search',
  templateUrl: './trip-code-search.component.html',
  styleUrls: ['./trip-code-search.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class TripCodeSearchComponent implements OnInit {
  trip_code_search_form: UntypedFormGroup;
  errMsg: string;
  msg: string;
  showSpin: boolean = false;

  v: number = this._authService.getCurrentTimeNumber();
  
  totalRecordsText: string;
  recordList: TripLegModel[] = [];
  orgRecordList: TripLegModel[] = [];
  displayedColumnTrip: string[] = ['tripcode', 'registration', 'clientName', 'createdDate', 'dateRange', 'route', 'clientInstruction', 'clientRef', 'action'];
  displayedColumnAircraft: string[] = ['registration', 'aircraft_type', 'client_name', 'mtow', 'height', 'wingspan', 'homebase',
    'has_documents', 'has_owner_lessee', 'has_operator', 'has_trips', 'disabled', 'action'];
  displayedColumnCrewPax: string[] = ['customerName', 'name', 'title', 'role', 'comms', 'action'];
  displayedColumnUser: string[] = ['customerName', 'username', 'name', 'email', 'account_status', 'admin_access', 'effectiveDate', 'expirationDate'];

  upPII: boolean = false; 
  submitted: boolean = false;
  customerList: CustomerModel[]=[];
  orgCustomerList: CustomerModel[]=[];
  selectedCustomerList: CustomerModel[]=[];
  selectedClientCountText: string = "Client";
  showLinkMsg: boolean = false;
  showIncludeTrip: boolean = false;
  showClientList: boolean = false;
  airport_type: string = 'all';
  disableAirportType: boolean = true;
  filterOption: string = "trips";
  searchField: string = "Trip Code\nClient Account\nRegistration";
  searchText: string[] = [];
  flightType: number = 0;
  isDialog: boolean = false;
  constructor(@Optional() private readonly _dialogRef: MatDialogRef<TripCodeSearchComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) private data: any, private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,private readonly _commonService: CommonService,
    private readonly _dialogService: DialogService, private readonly _aircraftService:AircraftService, private readonly _personService:PersonService, private readonly _identityService: IdentityService) {

  }

  getAllData(): Observable<any[]> {
    //if (this.userType == "internal") {
    //  let getClientListResponse = this._tripLegService.getClientListWithTrip();
    //  return forkJoin([getClientListResponse]);
    //}
    //else {
    //  return forkJoin([null]);
    //}
    return null;
  }

  ngOnInit() {
    if(this.data) {
      this.isDialog = true;
    }
    
    this._authService.updateAccessTime();

    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    else {
      this.upPII = true;
    }
    
    this.errMsg = "";
    this.msg = "";
    this.totalRecordsText = "";
    this.recordList = [];
    this.orgRecordList = [];
    
    this.initControls();

   
  }

  initControls() {
    this.trip_code_search_form = this._formBuilder.group({
      text_search: ['', Validators.required],
      clientsearch: [''],
      selectedClient: [''],
      airport_type: [{ value: this.airport_type, disabled: this.disableAirportType }],
    });

  }

  get f() { return this.trip_code_search_form.controls; }

  updateSearchText() {
    this.searchText = this.f.text_search.value.replace(/ /g, ",").split(",");
  }

  getSearchData(): Observable<any[]>  {

    this.errMsg = "";
    let request;
    let response;
    switch (this.filterOption) {
      case "trips":
        request = new TripModel();
        request.textSearch = this.f.text_search.value;
        request.flightType = this.flightType;
        response = this._groundStopService.getTripListByTripCodes(request);
        break;
      case "crewpax":
        request = new PersonModel();
        request.textSearch = this.f.text_search.value;
        request.filterBy = "Name";
        request.isActive = true;
        response = this._personService.getCustomerPersonBySearchKey(request);
        break;
      case "users":
        request = new UserModel();
        request.searchKeyword = this.f.text_search.value;
        response = this._identityService.getUsersByKeyword(request);
        break;
      case "aircraft":
        request = new AircraftModel();
        request.customerGuid = null;
        
        request.textSearch = this.f.text_search.value;
        request.filterBy = "Registration";
        request.pageIndex = 0;
        request.pageSize = 999;
        request.isActive = true;
        response = this._aircraftService.getAircraftProfileListByCustomerGUID(request);
        break;
    }
    return forkJoin([response]);
  }
  clickSearch(popover: any) {
    if (popover !=null)
     popover.close();
    this._authService.updateAccessTime();
    this.submitted = true;

    if ((this.trip_code_search_form.invalid || this.errMsg != "")  && this.selectedCustomerList.length == 0) {
      return;
    }
    this.showSpin = true;
    //let request = new TripModel();


    //this.showClientList = false;
    //request.textSearch = this.f.text_search.value;

    //if(this.selectedCustomerList?.length > 0){
    //  // request.textSearch

    //  let custList =  Array.prototype.map.call(this.selectedCustomerList, s => s.accountID).toString();

    //  request.textSearch = request.textSearch  + ',' + custList;
    //  if(request.textSearch.startsWith(',')){
    //    request.textSearch = request.textSearch.substring(1);
    //  }
    //}

    


    this.getSearchData().subscribe(response => {
      this.recordList = [];
      this.orgRecordList = [];
      this.totalRecordsText = "";
      if (response[0] != null && response[0].code == "200") {
        if (response[0].model.length > 0) {
          this.recordList = response[0].model; 
          this.orgRecordList = response[0].model; 
          this.disableAirportType = false;
          this.f.airport_type.enable();
          if (this.recordList.length > 1) {
            this.totalRecordsText = this.recordList.length.toString() + " Records Found";
          }
          else {
            this.totalRecordsText = this.recordList.length.toString() + " Record Found";
          }

        }
        else {
          this.f.airport_type.disable();

          this.totalRecordsText = "No record found";
        }
        this.showSpin = false;

      }
      else {
        if (response[0].code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "An error has occured.";
        }
      }
      this.showSpin = false;
    });

  }

  clickRouteNew(route: string, display: string) {
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }

 
  clickReset() {
    this.errMsg = "";
    this.totalRecordsText = "";
    this.f.text_search.setValue('');   
    this.recordList = [];
    this.submitted = false;
    this.flightType = 0;
  }
  
  
  clickOpen(e: any, item: TripLegModel) {
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "tripId": item.tripCodeGUID,
    //    "aircraftId": item.aircraftGUID,
    //    "customerId": item.customerGUID,
    //    "pf": "",
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
  }

  viewNotes(trip: TripModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { dialogTitle: "Trip Notes", displayText: trip.clientInstructions };

    this._dialog.open(DisplayDialogComponent, dialogConfig);
  }
  

  getCustomerList(){

    this.showClientList = true;

    if(this.customerList.length == 0)
    this._commonService.getCustomers<ResponseModel<CustomerModel[]>>().subscribe(response =>{


      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let c = new CustomerModel();
          c.customerGUID = "all";
          c.customerName = "Select All";
          c.selected = false;
          this.customerList.push(c);
          response.model.forEach(x => {
            c = new CustomerModel();
            c.customerGUID = x.customerGUID;
            c.customerName = x.customerName;
            c.accountID = x.accountID;
            c.selected = false;
            this.customerList.push(c);
          });

          this.orgCustomerList = this.customerList;
          if (this.selectedCustomerList)
            this.customerList.forEach(x => {
              if (this.recordList[0]?.customerGUID.toLowerCase() === x.customerGUID.toLowerCase()) {
                x.selected = true;
              } else {
                x.selected = false;
              }
            });
          this.setClientList();
        }

      }
    })
  }


  
  setClientList() {

    let excludeallclist: CustomerModel[]
    excludeallclist = this.customerList.filter(x => x.customerGUID != 'all' && x.selected == false);


    this.selectedCustomerList = this.customerList.filter(u => u.selected == true);
    this.selectedClientCountText = "Client ";
    if (this.selectedCustomerList.length > 0) {
      this.selectedClientCountText = "Client: " + this.selectedCustomerList.length;
      this.showLinkMsg = true;
      this.showIncludeTrip = true;

    }
    else {
      this.showLinkMsg = false;
      this.showIncludeTrip = false;
    }
  }

  checkClientChange(e: any, item: CustomerModel) {
    if (item.customerGUID == "all") {
      this.customerList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.customerList.forEach(x => {
        if (item.customerGUID == x.customerGUID) {
          x.selected = e.checked;
        }
      });
    }
    this.setClientList();
  }



  clickDoneClient(){
    // Array.prototype.map.call(this.selectedCustomerList, s => s.customerGUID).toString()
  
    let request = new TripModel();
    request.textSearch = this.f.text_search.value;
  }

  airportTypeChange(e: any){

    switch (this.f.airport_type.value) {
      case 'dom':
        this.recordList = this.orgRecordList.filter(x => x.groundStopList.filter( y => y.icao.toLowerCase().startsWith('k') || y.icao.toLowerCase().startsWith('ph') || y.icao.toLowerCase().startsWith('pa') ).length == x.groundStopList.length);

        break;
      case 'int':
        this.recordList = this.orgRecordList.filter(x => x.groundStopList.filter( y => y.icao.toLowerCase().startsWith('k') || y.icao.toLowerCase().startsWith('ph') || y.icao.toLowerCase().startsWith('pa') ).length != x.groundStopList.length);

        break;
    
      default: this.recordList = this.orgRecordList;
        break;
    }

    if (this.recordList.length > 1) {
      this.totalRecordsText = this.recordList.length.toString() + " Records Found";
    }
    else {
      this.totalRecordsText = this.recordList.length.toString() + " Record Found";
    }

  }
    
  
  clickResetClient() {
    this.customerList.forEach(x => {
      x.selected = false;
    });
    this.f.clientsearch.setValue("");
    // this.f.text_search.setValue("");
    this.setClientList();
    this.recordList = [];
    this.totalRecordsText = '';
    this.showClientList = false;
    

  }

  
  clientSearchChange(e: any) {
    let cname: string = e.target.value;
    if (cname != "") {
      cname = cname.toLowerCase();
    }
    this.getCustomerListBykeyword(cname);
  }

  getCustomerListBykeyword(cname: string) {

    this.customerList = this.orgCustomerList.filter(option => option.customerName.toLowerCase().includes(cname) || option.customerGUID == "all");
    if (this.selectedCustomerList.length > 0) {
      this.selectedCustomerList.forEach(x => {
        let i: number;
        i = this.customerList.findIndex(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
        if (i == -1) {
          let c = new CustomerModel();
          c.customerName = x.customerName;
          c.customerGUID = x.customerGUID;
          c.selected = true;
          this.customerList.push(c);
        }
      });
    }
    this.setClientList();
    if (this.selectedCustomerList.length > 0 && this.selectedCustomerList.length != this.customerList.length) {
      let data: CustomerModel[] = [];
      data = this.customerList;
      this.customerList = [];

      data.forEach(x => {
        if (x.customerGUID == "all") {
          this.customerList.push(x);
        }
        else {
          if (x.selected) {
            this.customerList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.customerGUID != "all") {
          this.customerList.push(x);
        }
      });
    }

  }

  updateFilter() {
    this.clickReset();
    switch (this.filterOption) {
      case "trips":
        this.searchField = "Trip Code\nClient Account\nRegistration";
        break;
      case "crewpax":
        this.searchField = "Name\nComms\nTitle";
        break;
      case "users":
        this.searchField = "Name\nUser Name\nEmail";
        break;
      case "aircraft":
        this.searchField = "Registration";
        break;
    }
  }

  clickEditPerson(p: PersonModel) {
   
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.maxHeight = "95vh";
    dialogConfig.data = { personGUID: p.personGUID, customerGUID: p.customerGUID, selectedPersonClassGUID: "F82AA48E-31B7-4667-9FAA-24204DC4A65B", fromSearch: true };
    
    const dialogRef = this._dialog.open(CustomerPersonDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSearchData();
      }

    });



  }


  clickEditAircraft(aircraft: AircraftModel) {
    
      const config = new MatDialogConfig();
      config.panelClass = "custom-dialog-container3"
      //config.disableClose = true;
      config.height = "57em";//"1000px";
      //config.maxWidth = "65em";//"1000px";
      config.width = "87em";//"1000px";
      config.maxWidth = "87em";

      // config.minHeight = "20em";
      config.restoreFocus = false;
      config.hasBackdrop = false;
      config.data = {
        v: this._authService.getCurrentTimeNumber(),
        customerGUID: aircraft.customerGuid, customerName: aircraft.customerName,
        aircraftGUID: aircraft.aircraftGuid
      };
      config.position = { left: "10px", top: "140px" };

      this._dialog.open(AircraftProfileEditComponent, config);
    
  }

  updateFlightType() {
    if (this.submitted) {
      this.clickSearch(null);
    }
  }


}
