import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAlternateAirportEditDialogComponent } from '../ground-stop-advanced/ground-stop-alternate-airport-edit-dialog.component';
import { AirportDetailsComponent } from '../airports/airport-details.component';
import { GroundStopAlternateAirportModel } from '../models/ground-stop-alternate-airport.model';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
import { ClientHaltServicesViewDialogComponent } from '../ground-stop-advanced/client-halt-services-view-dialog.component';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { VendorModel } from '../models/vendor.model';
import { AddBusinessRulesTasksDialogComponent } from '../ground-stop-advanced/add-business-rules-tasks-dialog.component';
import { TripPersonEditorDialogComponent } from './trip-person-editor-dialog.component';
import { AuthenticationType } from 'azure-maps-control';
import * as atlas from 'azure-maps-control';
import { TripDashboardModel } from '../models/trip-dashboard';
import { GroundStopManifestDocumentModel } from '../models/ground-stop-manifest-document.model';
import { AircraftDocumentModel } from '../models/aircraft-document.model';
import { CustomerDocumentModel } from '../models/customer-document.model';
import { MatAccordion } from '@angular/material/expansion';
import { AzureMapComponent } from '../common-utility/azure-map.component';
import { TripLegDocumentDownloadsDialogComponent } from './trip-leg-document-downloads-dialog.component';
import { DialogService } from '../services/dialog.service';
import { LegDetailEditorDialogComponent } from './leg-detail-editor-dialog.component';
import { BusinessRulesService } from '../services/business-rules-service';
import { CustomerPersonDetailsComponent } from '../persons/customer-person-details.component';

export class TableElement {
  legItem: TripLegModel;
  expanded: boolean;
  refreshChildRow: boolean;
  updateTask: boolean;
}

export interface AlertList {
  categoryName: string;
  alertObject: any;
}

@Component({
  selector: 'app-ground-stop-leglist',
  templateUrl: './ground-stop-leglist.component.html',
  styleUrls: ['./ground-stop-leglist.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})

export class GroundStopLeglistComponent implements OnInit, OnChanges {

  @Input() requestInput: TripModel;
  @Input() reloadChildPage: boolean;
  @Input() selectedTabName: string;
  @Input() v: number;
  @Input() refreshChildColumn: string;
  @Input() updateGroundStopGUID: string;
  @Input() hidePastStop: boolean;
  @Input() includeActiveStopOnly: boolean;
  @Input() clientName: string;
  @Input() lockdownTrip: boolean;

  request: TripModel;
  //customerGUID: string;
  ground_stop_leglist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = false;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  legList: TableElement[];
  oldLegList: TableElement[];
  @Output() public updateParentPage = new EventEmitter();

  //displayedColumn: string[] = ['legOrder', 'departure', 'etd', 'depUTC', 'far','crew', 'pax','other','ete','arrival','eta','arrUTC','legStatus', 'action','expand'];
  displayedColumn: string[] = ['legOrder', 'departure', 'arrival', 'crew', 'pax', 'far', 'other', 'action', 'expand'];
  //displayedColumn2: string[] = ['expand_collapse'];
  scrHeight: number;
  upPII: boolean = false;
  gsId: string = "";
  gstId: string = "";
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";
  map: atlas.Map;
  points: any = [];
  //alerts
  acDocAlerts: AlertList[] = [];
  expiredACDocList: AircraftDocumentModel[];
  customerDocAlerts: AlertList[] = [];
  expiredCustomerDocList: CustomerDocumentModel[];
  expiredTravelDocAlerts: AlertList[] = [];
  expiredTravelDocList: GroundStopManifestModel[];
  missingTravelDocAlerts: AlertList[] = [];
  missingTravelDocuments: GroundStopManifestModel[];
  waitingClientTaskAlerts: AlertList[] = [];
  waitingTaskList: GroundStopTaskModel[];
  waitingVendorTaskAlerts: AlertList[] = [];
  waitingVendorTaskList: GroundStopTaskModel[];
  openTaskAlerts: AlertList[] = [];
  openTaskList: GroundStopTaskModel[];
  manifestList: GroundStopManifestModel[];
  missingCrewAlerts: AlertList[] = [];
  missingCrewList: TripLegModel[] = [];
  missingPaxAlerts: AlertList[] = [];
  missingPaxList: TripLegModel[] = [];
  tbaCrewAlerts: AlertList[] = [];
  tbaCrewList: GroundStopManifestModel[] = [];
  tbaPaxAlerts: AlertList[] = [];
  tbaPaxList: GroundStopManifestModel[] = [];
  followUpCrewAlerts: AlertList[] = [];
  followUpCrewList: GroundStopManifestModel[] = [];
  followUpPaxAlerts: AlertList[] = [];
  followUpPaxList: GroundStopManifestModel[] = [];
  ssnAlerts: AlertList[] = [];
  missingSSNList: TripLegModel[] = [];
  missingBRTaskList: GroundStopTaskModel[] = [];
  missingBRTaskAlerts: AlertList[] = [];
  onHoldTaskAlerts: AlertList[] = [];
  onHoldTaskList: GroundStopTaskModel[];

  @ViewChild(MatAccordion) alertAccordion: MatAccordion;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _tripLegService: TripLegService,
    private readonly _dialogService: DialogService, private readonly _businessRulesService: BusinessRulesService
  ) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //if ((!this.reloadChildPage && this.refreshChildColumn == "") || this.selectedTabName != 'leg') {     
    //    return;    
    //}
    if (this.selectedTabName != 'leg') {
      return;
    }
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.request = (JSON.parse(JSON.stringify(this.requestInput)));
    if (!this.reloadChildPage && this.refreshChildColumn == "") {
    }
    else {
      if (this.refreshChildColumn == "") {
        this.showSpin = true;

        this.scrHeight = window.innerHeight;
        if (this.request.groundStopGUID != null && this.request.groundStopGUID != undefined && this.request.groundStopGUID != "")
          this.gsId = this.request.groundStopGUID.toLowerCase();
        if (this.request.groundStopTaskGUID != null && this.request.groundStopTaskGUID != undefined && this.request.groundStopTaskGUID != "")
          this.gstId = this.request.groundStopTaskGUID.toLowerCase();

        this.initControls();
        this.getLegList();
      }
    }

  }


  initControls() {
    this.ground_stop_leglist_form = this._formBuilder.group({

    })
  }

  get f() { return this.ground_stop_leglist_form.controls; }

  getLegList() {
    this.oldLegList = [];
    if (this.legList != undefined && this.legList != null) {
      if (this.legList.length > 0) {
        this.legList.forEach(x => {
          var ol = new TableElement();
          ol.legItem = x.legItem;
          ol.expanded = x.expanded;
          ol.refreshChildRow = false;
          this.oldLegList.push(ol);
        });
      }
    }
    this.legList = [];
    this.acDocAlerts = [];
    this.expiredACDocList = [];
    this.customerDocAlerts = [];
    this.expiredCustomerDocList = [];
    this.expiredTravelDocAlerts = [];
    this.expiredTravelDocList = [];
    this.missingTravelDocAlerts = [];
    this.missingTravelDocuments = [];
    this.waitingClientTaskAlerts = [];
    this.waitingTaskList = [];
    this.waitingVendorTaskAlerts = [];
    this.waitingVendorTaskList = [];
    this.openTaskAlerts = [];
    this.openTaskList = [];
    this.manifestList = [];
    this.missingCrewAlerts = [];
    this.missingCrewList = [];
    this.missingPaxAlerts = [];
    this.missingPaxList = [];
    this.tbaCrewAlerts = [];
    this.tbaCrewList = [];
    this.tbaPaxAlerts = [];
    this.tbaPaxList = [];
    this.followUpCrewAlerts = [];
    this.followUpCrewList = [];
    this.followUpPaxAlerts = [];
    this.followUpPaxList = [];
    this.ssnAlerts = [];
    this.missingSSNList = [];
    this.missingBRTaskList = [];
    this.missingBRTaskAlerts = [];
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.totalRecordsText = "";
    this.request.isFromLegEdit = false;
    // this.request.hidePastStop = false;
    this.request.hidePastStop = this.hidePastStop;

    this._tripLegService.getGroundStopLegListByConditions<ResponseModel<TripLegModel[]>>(this.request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          var result = response.model;
          this.legList = [];
          let findex: number;
          for (var i = 0; i < result.length; i++) {
            var te = new TableElement();
            te.legItem = result[i];
            if (this.oldLegList.length > 0) {
              findex = this.oldLegList.findIndex(x => x.legItem.groundStopGUID.toLowerCase() == te.legItem.groundStopGUID.toLowerCase() && x.legItem.nextArrivalGroundStopGUID.toLowerCase() == te.legItem.nextArrivalGroundStopGUID.toLowerCase());
              if (findex >= 0) {
                te.expanded = this.oldLegList[findex].expanded;
              }
              else {
                te.expanded = false;
              }
            }
            else {
              te.expanded = false;
            }

            if (te.expanded) {
              te.refreshChildRow = true;
            }
            else {
              te.refreshChildRow = false;
            }
            this.legList.push(te);
          }

          this._tripLegService.getAlertListForTripLegs<ResponseModel<TripDashboardModel>>(result).subscribe(response => {
            if (response != null && response.code == "200") {
              let tripDashboard = response.model;
              this.expiredACDocList = tripDashboard.expiredAircraftDocuments;
              this.expiredCustomerDocList = tripDashboard.expiredCustomerDocuments;
              if (result != null) {
                //this.missingCrewList = result.filter(x => x.crewCount == 0);
                //this.missingPaxList = result.filter(x => x.paxCount == 0 && !x.isDeptFerryFlight);
                let groundStopGUID;
                let nextGroundStopGUID;
                groundStopGUID = "";
                nextGroundStopGUID = "";
                this.missingCrewList = tripDashboard.missingCrewList;
                if (this.missingCrewList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(tripDashboard.missingCrewList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.missingCrewAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: tripDashboard.missingCrewList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                nextGroundStopGUID = "";
                this.missingPaxList = tripDashboard.missingPaxList;
                if (this.missingPaxList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(tripDashboard.missingPaxList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.missingPaxAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: tripDashboard.missingPaxList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                result.forEach(x => {
                  if (x.pic != null) {
                    if (x.pic.groundStopManifestDocumentList.some(z => z.isExpiredForGroundStop)) {
                      this.expiredTravelDocList.push(x.pic);
                    }
                    if (x.pic.isBellAlert && x.pic.lastName.toLowerCase() != 'crew tba' && x.pic.lastName.toLowerCase() != 'crew' && x.pic.lastName.toLowerCase() != 'pax'
                      && x.pic.lastName.toLowerCase() != 'pax tba' && x.pic.lastName.toLowerCase() != 'crew followup' && x.pic.lastName.toLowerCase() != 'pax followup') {
                      this.missingTravelDocuments.push(x.pic);
                    }
                  }
                  if (x.sic != null) {
                    if (x.sic.groundStopManifestDocumentList?.some(z => z.isExpiredForGroundStop)) {
                      this.expiredTravelDocList.push(x.sic);
                    }
                    if (x.sic.isBellAlert && x.sic.lastName.toLowerCase() != 'crew tba' && x.sic.lastName.toLowerCase() != 'crew' && x.sic.lastName.toLowerCase() != 'pax'
                      && x.sic.lastName.toLowerCase() != 'pax tba' && x.sic.lastName.toLowerCase() != 'crew followup' && x.sic.lastName.toLowerCase() != 'pax followup') {
                      this.missingTravelDocuments.push(x.sic);
                    }
                  }
                  if (x.tripCrewList != null) {
                    x.tripCrewList.forEach(y => {
                      if (y.groundStopManifestDocumentList?.some(z => z.isExpiredForGroundStop)) {
                        this.expiredTravelDocList.push(y);
                      }
                      if (y.isBellAlert && y.lastName != null) {
                        this.missingTravelDocuments.push(y);
                      }
                      if (y.tooltip?.toLowerCase() == 'crew tba')
                        this.tbaCrewList.push(y);
                      if (y.tooltip?.toLowerCase() == 'crew followup')
                        this.followUpCrewList.push(y);
                    });
                  }
                  if (x.tripPAXList != null) {
                    x.tripPAXList.forEach(y => {
                      if (y.groundStopManifestDocumentList?.some(z => z.isExpiredForGroundStop)) {
                        this.expiredTravelDocList.push(y);
                      }
                      if (y.isBellAlert && y.lastName != null) {
                        this.missingTravelDocuments.push(y);
                      }

                      if (y.tooltip?.toLowerCase() == 'pax tba')
                        this.tbaPaxList.push(y);
                      if (y.tooltip?.toLowerCase() == 'pax followup')
                        this.followUpPaxList.push(y);

                    });
                  }
                });

                if (this.expiredTravelDocList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(this.expiredTravelDocList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.expiredTravelDocAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: this.expiredTravelDocList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }

                if (this.missingTravelDocuments != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(this.missingTravelDocuments, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.missingTravelDocAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: this.missingTravelDocuments.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                nextGroundStopGUID = "";
                if (this.tbaCrewList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(this.tbaCrewList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1 && x.departureGroundStopTypeGUID.toUpperCase() != 'A2C7BF22-D054-4564-949B-F0D55D1F6B10' && x.nextArrivalGroundStopTypeGUID.toUpperCase() != 'A2C7BF22-D054-4564-949B-F0D55D1F6B10') {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.tbaCrewAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: this.tbaCrewList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                if (this.followUpCrewList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(this.followUpCrewList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.followUpCrewAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: this.followUpCrewList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                nextGroundStopGUID = "";
                if (this.tbaPaxList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(this.tbaPaxList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.tbaPaxAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: this.tbaPaxList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                nextGroundStopGUID = "";
                if (this.followUpPaxList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(this.followUpPaxList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.followUpPaxAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: this.followUpPaxList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                nextGroundStopGUID = "";
                this.waitingTaskList = tripDashboard.waitingTaskList;
                if (this.waitingTaskList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(tripDashboard.waitingTaskList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.waitingClientTaskAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: tripDashboard.waitingTaskList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                nextGroundStopGUID = "";
                this.waitingVendorTaskList = tripDashboard.waitingVendorTaskList;
                if (this.waitingVendorTaskList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(tripDashboard.waitingVendorTaskList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.waitingVendorTaskAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: tripDashboard.waitingVendorTaskList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                nextGroundStopGUID = "";
                this.missingSSNList = tripDashboard.missingSSNList;
                if (this.missingSSNList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(tripDashboard.missingSSNList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.ssnAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: tripDashboard.missingSSNList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                nextGroundStopGUID = "";
                this.openTaskList = tripDashboard.openTaskList;
                if (this.openTaskList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(tripDashboard.openTaskList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.openTaskAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: tripDashboard.openTaskList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                nextGroundStopGUID = "";
                this.onHoldTaskList = tripDashboard.onHoldTaskList;
                if (this.onHoldTaskList != null) {
                  result.forEach(x => {
                    if ((Array.prototype.map.call(tripDashboard.onHoldTaskList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1 || Array.prototype.map.call(tripDashboard.onHoldTaskList, s => s.groundStopGUID).toString().indexOf(x.nextArrivalGroundStopGUID) > -1) && x.departureGroundStopTypeGUID.toUpperCase() != 'A2C7BF22-D054-4564-949B-F0D55D1F6B10' && x.nextArrivalGroundStopTypeGUID.toUpperCase() != 'A2C7BF22-D054-4564-949B-F0D55D1F6B10') {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        nextGroundStopGUID = x.nextArrivalGroundStopGUID;
                        this.onHoldTaskAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: tripDashboard.onHoldTaskList.filter(y => (y.groundStopGUID == groundStopGUID && y.serviceLocation == 'D') || (y.groundStopGUID == nextGroundStopGUID && y.serviceLocation == 'A'))
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                nextGroundStopGUID = "";
                this.missingBRTaskList = tripDashboard.missingBusinessRulesList;
                if (this.missingBRTaskList != null) {
                  result.forEach(x => {
                    if ((Array.prototype.map.call(this.missingBRTaskList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1 || Array.prototype.map.call(this.missingBRTaskList, s => s.groundStopGUID).toString().indexOf(x.nextArrivalGroundStopGUID) > -1) && x.departureGroundStopTypeGUID.toUpperCase() != 'A2C7BF22-D054-4564-949B-F0D55D1F6B10' && x.nextArrivalGroundStopTypeGUID.toUpperCase() != 'A2C7BF22-D054-4564-949B-F0D55D1F6B10') {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        nextGroundStopGUID = x.nextArrivalGroundStopGUID;
                        let obj = this.missingBRTaskList.filter(y => (y.groundStopGUID == groundStopGUID && y.serviceLocation == 'D') || (y.groundStopGUID == nextGroundStopGUID && y.serviceLocation == 'A'));
                        if (obj.length > 0) {
                          this.missingBRTaskAlerts.push({
                            categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                            alertObject: this.missingBRTaskList.filter(y => (y.groundStopGUID == groundStopGUID && y.serviceLocation == 'D') || (y.groundStopGUID == nextGroundStopGUID && y.serviceLocation == 'A'))
                          });
                        }
                      }
                    }
                  });
                }
              }
            }
            else {
              if (response.code == "401") {

                this._authService.signOut();
              }
              else {
                this.errMsg = "Failed to get trip alerts at this time. Please try later.";
              }
            }

          });

          //let request = new TripModel();
          //request.tripCodeGUID = this.request.tripCodeGUID;
          //let gsList: GroundStopModel[] = [];          
          //let g = new GroundStopModel();
          ////if (this.groundStopGUID != undefined)
          ////  g.groundStopGUID = this.groundStopGUID;
          ////else
          //g.groundStopGUID = "";
          //gsList.push(g);
          //request.groundStopList = gsList;
          //request.includeActiveStopOnly = true; //this.includeActiveStopsOnly;
          //this._businessRulesService.getBusinessRulesForGroundStopByTripCodeGUID<ResponseModel<GroundStopTaskModel[]>>(request).subscribe(response => {
          //  if (response != null) {
          //    if (response.code == "200" && response.message == "") {
          //      this.missingBRTaskList = response.model.filter(x => x.selected);
          //      if (this.missingBRTaskList.length > 0) {
          //        let groundStopGUID = "";
          //        let nextGroundStopGUID = "";
          //        result.forEach(x => {
          //          if (Array.prototype.map.call(this.missingBRTaskList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1 || Array.prototype.map.call(this.missingBRTaskList, s => s.groundStopGUID).toString().indexOf(x.nextArrivalGroundStopGUID) > -1) {
          //            if (groundStopGUID != x.groundStopGUID) {
          //              groundStopGUID = x.groundStopGUID;
          //              nextGroundStopGUID = x.nextArrivalGroundStopGUID;
          //              let obj = this.missingBRTaskList.filter(y => (y.groundStopGUID == groundStopGUID && y.serviceLocation == 'D') || (y.groundStopGUID == nextGroundStopGUID && y.serviceLocation == 'A'));
          //              if (obj.length > 0) {
          //                this.missingBRTaskAlerts.push({
          //                  categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
          //                  alertObject: this.missingBRTaskList.filter(y => (y.groundStopGUID == groundStopGUID && y.serviceLocation == 'D') || (y.groundStopGUID == nextGroundStopGUID && y.serviceLocation == 'A'))
          //                });
          //              }
          //            }
          //          }
          //        });
          //      }
          //    }
          //  }
          //});


        }
        else {
          this.totalRecordsText = "No record found";


        }
        this.showSpin = false;

      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.createMap();
      this.showSpin = false;
    })

  }

  expandCollapseLeg(e: any, item: TableElement, flag: string) {
    this.refreshChildColumn = "";
    if (item.expanded == false) {
      item.expanded = true;
      item.refreshChildRow = true;
    }
    else {
      item.expanded = false;
    }
    //this.createMap();
  }

  openAll() {
    this.legList.forEach(x => {
      x.expanded = true;
      x.refreshChildRow = true;
    });
  }

  closeAll() {
    this.legList.forEach(x => {
      x.expanded = false;
    });
  }

  clickEditAlternateAirport(event: any, item: TripLegModel) {
    event.stopPropagation();
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    let leg: string = "";

    leg = item.departureICAO;
    if (item.nextArrivalICAO != "") {
      leg += "-" + item.nextArrivalICAO;
    }

    dialogConfig.data = {
      groundStopGUID: item.groundStopGUID, leg: leg, nextGroundStopGUID: item.nextArrivalGroundStopGUID,
      tripCodeGUID: item.tripCodeGUID, aircraftGUID: item.aircraftGUID, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(GroundStopAlternateAirportEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        this.getTripLegBydgIdagId(item.groundStopGUID, item.nextArrivalGroundStopGUID);
      }
    });

  }

  clickTripLegDetail(event: any, item: TripLegModel) {
    event.stopPropagation();
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      groundStopGUID: item.groundStopGUID, nextGroundStopGUID: item.nextArrivalGroundStopGUID,
      tripCodeGUID: item.tripCodeGUID, aircraftGUID: item.aircraftGUID, v: this._authService.getCurrentTimeNumber(), customerGUID: item.customerGUID
    };

    const dialogRef = this._dialog.open(LegDetailEditorDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        this.getLegList();
        this.updateParentPage.emit({ updateSection: 'leg' });

      }
    });

  }

  //clickGroundStopDetail(event: any, item: GroundStopModel) {
  //  this._authService.updateAccessTime();
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.autoFocus = true;
  //  dialogConfig.disableClose = true;

  //  dialogConfig.data = {
  //    groundStopGUID: item.groundStopGUID, isFirstGroundStop: item.isFirstGroundStop, nextGroundStopGUID: item.nextGroundStopGUID,
  //    tripCodeGUID: item.tripCodeGUID, tripCodeType: item.tripCodeType, v: this._authService.getCurrentTimeNumber()
  //  };

  //  //const dialogRef = this._dialog.open(GroundStopDetailsDialogComponent, dialogConfig);
  //  //dialogRef.afterClosed().subscribe(result => {
  //  //  if (result.refresh == true) {
  //  //    let rIndex: number;
  //  //    this.getTripInfo(item.tripCodeGUID);
  //  //    this.updateParentPage.emit({ updateSection: 'groundStop' });
  //  //    this.getTripRevisionUsernameListBygIdList('');

  //  //  }
  //  //});

  //}

  getTripLegBydgIdagId(depGroundStopGUID: string, arrGroundStopGUID) {
    this._authService.updateAccessTime();
    let req = new TripLegModel();
    req.groundStopGUID = depGroundStopGUID;
    req.nextArrivalGroundStopGUID = arrGroundStopGUID;
    req.aircraftGUID = this.request.aircraftGUID
    this._tripLegService.getTripLegBydgIdagId<ResponseModel<TripLegModel>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let obj = new TripLegModel();
          obj = response.model;
          let i: number;
          i = this.legList.findIndex(x => x.legItem.groundStopGUID.toLowerCase() == depGroundStopGUID.toLowerCase() && x.legItem.nextArrivalGroundStopGUID.toLowerCase() == arrGroundStopGUID.toLowerCase());
          if (i != -1) {
            obj.tripLegOrder = this.legList[i].legItem.tripLegOrder;
          }
          this.legList[i].legItem = obj;
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }

    })

  }

  clickPersonName(e: any, itemLeg: TripLegModel, item: GroundStopManifestModel) {
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "pId": item.personGUID,
    //    "selectedPersonClassDescription": item.personClassDescription,
    //    "selectedPersonClassGUID": item.personClassGUID,
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    //this._router.navigate(['/persons/customer-person-details'], navigationExtras);
    e.stopPropagation();
    if (item.capacityID != 1) {
      return;
    }
    if (itemLeg.departureGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID || itemLeg.departureGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID
      || itemLeg.nextArrivalGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID || itemLeg.nextArrivalGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID) {
      //window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID + '&selectedPersonClassDescription=' + item.personClassDescription + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
      // window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.panelClass = "custom-dialog-container";
      dialogConfig.maxHeight = "95vh";
      dialogConfig.hasBackdrop = false;
      dialogConfig.data = { personGUID: item.personGUID, customerGUID: this.request.customerGUID, selectedPersonClassGUID: item.personClassGUID, selectedPersonClassDescription: item.personClassDescription };

      const dialogRef = this._dialog.open(CustomerPersonDetailsComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // this.getAllData();
        }

      });
    }
    else {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.hasBackdrop = false;
      let personType: string = "";
      personType = item.groundStopManifestTypeDescription;
      personType = personType.replace("Departure ", "");
      personType = personType.replace("Arrival ", "");
      let alreadyHaveCrewOnTrip: boolean = false;
      let alreadyHavePaxOnTrip: boolean = false;
      if (personType != 'PAX') {
        alreadyHaveCrewOnTrip = true;
      }
      else {
        alreadyHavePaxOnTrip = true;
      }

      // let isPassportNotRequired: boolean = false;
      // if (personType != 'PIC') {
      //   isPassportNotRequired = item.isPassportNotRequired;
      // }
      // if (personType != 'SIC') {
      //   isPassportNotRequired = item.isPassportNotRequired;
      // }
      // if (personType != 'CREW') {
      //   isPassportNotRequired = item.isPassportNotRequired;
      // }
      // if (personType != 'PAX') {
      //   isPassportNotRequired = item.isPassportNotRequired;
      // }

      dialogConfig.data = {
        tripCodeGUID: itemLeg.tripCodeGUID, customerGUID: itemLeg.customerGUID, isPassportNotRequired: item.isPassportNotRequired, personGUID: item.personGUID, groundStopGUID: itemLeg.groundStopGUID, nextArrivalGroundStopGUID: itemLeg.nextArrivalGroundStopGUID,
        departureCountryGUID: itemLeg.departureCountryGUID, personType: personType, actionType: 'Edit', v: this._authService.getCurrentTimeNumber()
      };

      const dialogRef = this._dialog.open(TripPersonEditorDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result.refresh == true) {
          this.getLegList();
          this.updateParentPage.emit({ updateSection: 'leg' });
        }
      });

    }

  }

  clickPointOfContact(e: any, legItem: TripLegModel, vItem: VendorModel, dep_arrType: string) {
    e.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    let icao: string = "";
    if (dep_arrType == 'D') {
      icao = legItem.departureICAO;
    }
    else {
      icao = legItem.nextArrivalICAO;
    }
    dialogConfig.data = {
      vendorId: vItem.vendorGUID,
      icao: icao
    };
    this._dialog.open(VendorDetailsComponent, dialogConfig);
  }

  clickAltICAO(e: any, leg: TripLegModel, a: GroundStopAlternateAirportModel, legType: string) {
    e.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    // dialogConfig.width = "45em";
    let groundStopGUID: string = "";
    if (legType == "D") {
      groundStopGUID = leg.groundStopGUID;
    }
    else {
      groundStopGUID = leg.nextArrivalGroundStopGUID;
    }
    dialogConfig.data = {
      icao: a.icao,
      icaoIata: a.icaO_IATA,
      groundStopGUID: groundStopGUID,
      tripCodeGUID: leg.tripCodeGUID
    };
    this._dialog.open(AirportDetailsComponent, dialogConfig);
  }


  clickICAO(e: any, leg: TripLegModel, legType: string) {
    e.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";

    if (legType == "D") {
      dialogConfig.data = {
        icao: leg.departureICAO,
        icaoIata: leg.departureICAO_IATA,
        groundStopGUID: leg.groundStopGUID,
        tripCodeGUID: leg.tripCodeGUID
      };
    }
    else {
      dialogConfig.data = {
        icao: leg.nextArrivalICAO,
        icaoIata: leg.nextArrivalICAO_IATA,
        groundStopGUID: leg.nextArrivalGroundStopGUID,
        tripCodeGUID: leg.tripCodeGUID
      };
    }
    this._dialog.open(AirportDetailsComponent, dialogConfig);

  }

  onUpdateParentPage(e: any) {

    switch (e.updateSection) {
      case "clearRefreshTaskStatus":
        this.legList.forEach(x => {
          if (x.expanded)
            x.refreshChildRow = false;
        })
        break;
      case "allLegTask":
        this.legList.forEach(x => {
          if (x.expanded)
            x.refreshChildRow = true;
        })
        this.updateParentPage.emit({ updateSection: 'allLegTask' });
        break;
      case "leg":
        this.updateParentPage.emit({ updateSection: 'leg' });
        break;
      case "groundStop":
        break;
    }

  }


  clickAddBRTasks(event: any, item: TripLegModel, e: TableElement) {
    event.stopPropagation();
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "34em";//"1000px";
    dialogConfig.minHeight = "23em";//"500px";
    dialogConfig.maxHeight = "40em";//"500px";
    dialogConfig.disableClose = true;

    if (item.hasHaltServices) {
      dialogConfig.data = {
        customerGUID: this.request.customerGUID, v: this._authService.getCurrentTimeNumber()
      };

      const dialogRef = this._dialog.open(ClientHaltServicesViewDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.openBRTaskSelection(item, e);

        }
      });
    }
    else {
      this.openBRTaskSelection(item, e);
    }

  }

  openBRTaskSelection(item: TripLegModel, e: TableElement) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.minWidth = "34em";//"1000px";
    //dialogConfig.minHeight = "39em";//"500px";
    //dialogConfig.maxHeight = "40em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    this.refreshChildColumn = "";
    dialogConfig.data = {
      groundStopGUID: item.groundStopGUID, tripCodeGUID: item.tripCodeGUID, dICAO: item.departureICAO, aICAO: item.nextArrivalICAO
    };

    const dialogRef = this._dialog.open(AddBusinessRulesTasksDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.legList.find(x => x.legItem.groundStopGUID == item.groundStopGUID).updateTask = !this.legList.find(x => x.legItem.groundStopGUID == item.groundStopGUID).updateTask;

      }

    });
  }

  clickDownloadTripDocument(e: any, item: TripLegModel) {
    e.stopPropagation();
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    /*dialogConfig.disableClose = true;*/

    //let leg: string = "";

    //leg = item.departureICAO;
    //if (item.nextArrivalICAO != "") {
    //  leg += "-" + item.nextArrivalICAO;
    //}

    dialogConfig.data = {
      groundStopGUID: item.groundStopGUID, nextGroundStopGUID: item.nextArrivalGroundStopGUID, tripCodeGUID: item.tripCodeGUID,
      customerGUID: item.customerGUID, aircraftGUID: item.aircraftGUID, pageFrom: 'leg', v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripLegDocumentDownloadsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        //this.getTripLegBydgIdagId(item.groundStopGUID, item.nextArrivalGroundStopGUID);
      }
    });
  }

  createMap() {

    this.map = new atlas.Map('map', {
      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      var zoomControl = new atlas.control.ZoomControl();

      /* Add the zoom control to the map*/
      self.map.controls.add(zoomControl, {
        position: atlas.ControlPosition.TopRight
      });

      if (self.legList.length > 0) {
        self.legList.forEach((v, i) => {
          var startPosition = [v.legItem.departureLongitude, v.legItem.departureLatitude];
          var endPosition = [v.legItem.nextArrivalLongitude, v.legItem.nextArrivalLatitude];
          var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
            title: v.legItem.departureICAO,
            name: v.legItem.departureAirportName
          });

          var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
            title: v.legItem.nextArrivalICAO,
            name: v.legItem.nextArrivalAirportName
          });
          self.points.push(startPosition);
          self.points.push(endPosition);
          var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
          var poly = new atlas.data.LineString(path);
          dataSource.add([poly, startPoint, endPoint]);

        });

        var popupTemplate = "<div class='popup'>{name}</div>";
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            image: 'pin-round-darkblue',
            cluster: false,
            optional: true,
            allowOverlap: true,
            size: .5
          },
          textOptions: {
            textField: ['get', 'title'],
            offset: [0, -1.5],
            cluster: false,
            allowOverlap: true,
            size: 10
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        });
        self.map.layers.add([new atlas.layer.LineLayer(dataSource, null, {
          strokeColor: "black",
          strokeWidth: 1.5
        }),
          symbolLayer
        ]);
        var popup = new atlas.Popup({
          pixelOffset: [0, -18],
          closeButton: false
        });
        //Add a hover event to the symbol layer.
        self.map.events.add('mouseover', symbolLayer, function (e) {
          //Make sure that the point exists.
          if (e.shapes && e.shapes.length > 0) {
            var pos = e.position;
            var offset = [0, 0];
            var properties;
            var content, coordinate;

            if (e.shapes[0] instanceof atlas.Shape) {
              properties = e.shapes[0].getProperties();

              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].getType() === 'Point') {
                coordinate = e.shapes[0].getCoordinates();
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            } else {
              properties = e.shapes[0].properties;
              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].type === 'Point') {
                coordinate = e.shapes[0].geometry.coordinates;
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            }
            popup.setOptions({
              //Update the content of the popup.
              content: content,

              //Update the popup's position with the symbol's coordinate.
              position: coordinate

            });
            //Open the popup.
            popup.open(self.map);
          }
        });

        self.map.events.add('mouseleave', symbolLayer, function () {
          popup.close();
        });

        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        self.map.setCamera({
          bounds: bbox,
          padding: 40
        });
      }


    });
  }

  openMapComponent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80em";
    dialogConfig.height = "40em";
    //dialogConfig.panelClass = "custom-dialog-container"

    dialogConfig.data = {
      legList: this.legList
    };

    this._dialog.open(AzureMapComponent, dialogConfig);

  }

  hasTasks(taskList: any, taskType: string): boolean {
    if (taskList.filter(x => x.serviceLocation == taskType).length > 0)
      return true;
    else
      return false;
  }



}
