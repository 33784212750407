import { ComponentPortal, Portal, PortalInjector } from '@angular/cdk/portal';
import { AfterViewInit, Component, Inject, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { CONTAINER_DATA } from '../services/help-guide.service';
import { HelpGuideCategoriesComponent } from './help-guide-categories.component';
import { HelpGuideDetailsComponent } from './help-guide-details.component';
import { HelpGuideTopicsComponent } from './help-guide-topics.component';

@Component({
  selector: 'app-help-guides',
  templateUrl: './help-guides.component.html',
  styleUrls: ['./help-guides.component.css']
})


export class HelpGuidesComponent implements AfterViewInit {
  @Input() selectedPortal: Portal<any>;
  componentPortal: ComponentPortal<HelpGuideCategoriesComponent>;
  detailsPortal: ComponentPortal<HelpGuideDetailsComponent>;
  topicPortal: ComponentPortal<HelpGuideTopicsComponent>;
  type: string;
  constructor(private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) private _data: any,
    private _injector: Injector 
    ) {
    this.type = _data.type;
  }

  ngAfterViewInit(): void {
    this.componentPortal = new ComponentPortal(HelpGuideCategoriesComponent, null, this.createInjector(this.type));
    this.detailsPortal = new ComponentPortal(HelpGuideDetailsComponent);
    this.topicPortal = new ComponentPortal(HelpGuideTopicsComponent);
    this._authService.updateAccessTime();
    this.selectedPortal = this.componentPortal;

  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

}
