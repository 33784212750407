export class HelpGuideModel {
  helpGuideGUID: string;
  audienceID: number;
  categoryID: number;
  deviceTypeID: number;
  helpGuideTitle: string;
  mainBodyRaw: string;
  mainBodyHTML: string;
  sortOrderNumber: number;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
}
