<!--<div style="background-color:#f8f8f8;height:40em">-->
<div *ngIf="upPII" style="background-color:#f8f8f8;overflow-y:hidden ">
  <!--<div style="background-color:#f8f8f8;" >-->
  <div style="display: flex; justify-content:space-between">
    <div style="display: flex;padding-left:1em; padding-top:0.25em; float:left;min-width:48em" cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>

      <div style="margin-right:1em">
        <div style="font-size: small">Registration</div>
        <div style="font-weight: bold; font-size: small">{{registration}}</div>
      </div>
      <div style="margin-right:1em">
        <div style="font-size: small">Trip Code</div>
        <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
      </div>
      <div style="margin-right:1em">
        <div style="font-size: small">Business Stop</div>
        <div style="font-size: small; display: flex"><div>{{prevICAO!=''?prevICAO+'-':''}}</div><div style="font-weight: bold">{{icao}}</div><div>{{nextICAO!=''?'-'+nextICAO:''}}</div></div>
      </div>
      <div style="margin-right:1em" *ngIf="arrivalDateTime!=''">
        <div style="font-size: small">Arrival({{icao}})</div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{arrivalDateTime}}</div>
        <div style="font-size: small; margin-top:-0.5em"> {{arrivalDateTimeLocal!=''?arrivalDateTimeLocal:''}}</div>
      </div>
      <div style="margin-right:1em" *ngIf="departureDateTime!=''">
        <div style="font-size: small">Departure({{icao}})</div>
        <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{departureDateTime}}</div>
        <div style="font-size: small;margin-top:-0.5em"> {{departureDateTimeLocal!=''?departureDateTimeLocal:''}}</div>
      </div>
      <div>
        <div style="font-size: small;">{{aircraftManufacturer}} {{aircraftModel}}</div>
        <div style="font-size: small;">{{aircraftInfo}}</div>
      </div>
    </div>
    <div *ngIf="onHoldText!=''" style="margin-top:1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
      <div style="margin-top: -0.1em; text-align: center; font-weight: normal; font-size: small;">On Hold</div>
    </div>
    <div style="float: right;padding-top:0.75em; padding-right:1em">
      <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard" [cdkCopyToClipboard]="clickCopyHeader() ">content_copy</i>
    </div>
  </div>
  <div>
    <form *ngIf="ground_stop_task_edit_client_form" [formGroup]="ground_stop_task_edit_client_form">
      <div style="display:flex; margin-top:0em; padding-left: 1em; padding-right:1em">
        <div style="margin-top:0.5em;background-color:white; padding-left:1em; padding-bottom: 0.75em;width: 54em">
          <div class="d-inline-flex">
            <div style="border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right:.25em;margin-top:.5em" [style.background-color]="taskColor"></div>  <div style="display:flex; justify-content: space-between">
              <div style="margin-top:0.25em;"><h5>{{serviceTypeDesc}}</h5></div>
            </div>
            </div>
            <!--handling-->
            <div *ngIf="serviceTypeID!=3 && serviceTypeID!=5 && serviceTypeID!=26 && vendorReq==true && showVendorToClient==true" style="display: flex;margin-top:-0.75em">
              <div style="margin-right:0.5em;">
                <label for="fbo_select" style="font-size:small">{{serviceSubTitle.replace('Choose ','')}}</label>
                <div style="margin-top:-0.5em;min-width:25em;font-size:small;min-height:1.65em; padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{selectedVendorName}}

                </div>

              </div>
              <div style="margin-top:1.25em;" *ngIf="selectedVendorName!=''">
                <button mat-button class="btn-high" (click)="openVendorDetails()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">info</i><div>Details</div></div></button>&nbsp;
              </div>

            </div>
            <!--handling end-->
            <!--flight plan-->
            <div *ngIf="serviceTypeID==35" style="display: flex;margin-top:-0.75em;font-size: small ">
              <div style="margin-right:0.5em">
                <div style="margin-left:0em;margin-top:0em">
                  <label for="recall_number" style="font-size:small">Recall Number</label>
                  <div style="display:flex;margin-top:-0.5em; min-width:10em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                    {{recall_number}}

                  </div>
                </div>
              </div>
              <div *ngIf="overflightCountryList && overflightCountryList.length>0" style="width:41.5em; margin-right: 0.5em; height:4em; background-color: #E9ECEF; border-radius:0.25em; font-size: small; padding-left: 0.25em; ">
                <div style="font-weight:500">Overflight Permit Task Associated to This Recall number:</div>
                <div style="height: 2.5em; overflow-y:auto">
                  <div *ngFor="let item of overflightCountryList; let i=index">
                    <div *ngIf="i % 6==0" style="display: flex;">
                      <div style="font-size: small; display:flex;width:7em; padding:0em;">
                        <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{item.publicNote}}" [style.background-color]="item.taskStatusBackgroundColor">{{item.countryName.length>12?item.countryName.substring(0,12)+'...': item.countryName}}</div>
                      </div>
                      <div *ngIf="i + 1 < overflightCountryList.length" style="font-size: small; display:flex; margin-left:0.5em;width:7em; padding:0em" [style.background-color]="item.TaskStatusBackgroundColor">
                        <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{overflightCountryList[i+1].publicNote}}" [style.background-color]="overflightCountryList[i+1].taskStatusBackgroundColor">{{overflightCountryList[i+1].countryName.length>12?overflightCountryList[i+1].countryName.substring(0,12)+'...':overflightCountryList[i+1].countryName}}</div>
                      </div>
                      <div *ngIf="i + 2 < overflightCountryList.length" style="font-size: small;display: flex; margin-left:0.5em;width:7em; padding:0em ">
                        <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{overflightCountryList[i+2].publicNote}}" [style.background-color]="overflightCountryList[i+2].taskStatusBackgroundColor">{{overflightCountryList[i+2].countryName.length>12?overflightCountryList[i+2].countryName.substring(0,12)+'...':overflightCountryList[i+2].countryName}}</div>
                      </div>
                      <div *ngIf="i + 3 < overflightCountryList.length" style="font-size: small;display: flex; margin-left:0.5em;width:7em; padding:0em ">
                        <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{overflightCountryList[i+3].publicNote}}" [style.background-color]="overflightCountryList[i+3].taskStatusBackgroundColor">{{overflightCountryList[i+3].countryName.length>12?overflightCountryList[i+3].countryName.substring(0,12)+'...':overflightCountryList[i+3].countryName}}</div>
                      </div>
                      <div *ngIf="i + 4 < overflightCountryList.length" style="font-size: small;display: flex; margin-left:0.5em;width:7em; padding:0em ">
                        <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{overflightCountryList[i+4].publicNote}}" [style.background-color]="overflightCountryList[i+4].taskStatusBackgroundColor">{{overflightCountryList[i+4].countryName.length>12?overflightCountryList[i+4].countryName.substring(0,12)+'...':overflightCountryList[i+4].countryName}}</div>
                      </div>
                      <div *ngIf="i + 5 < overflightCountryList.length" style="font-size: small;display: flex; margin-left:0.5em;width:7em; padding:0em ">
                        <div style="font-size: small;border-radius:0.25em" matTooltipClass="tooltipLineBreak" matTooltip="{{overflightCountryList[i+5].publicNote}}" [style.background-color]="overflightCountryList[i+5].taskStatusBackgroundColor">{{overflightCountryList[i+5].countryName.length>12?overflightCountryList[i+5].countryName.substring(0,12)+'...':overflightCountryList[i+5].countryName}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!--flight plan-->
            <!--over flight permit-->
            <div *ngIf="serviceTypeID==3 && vendorReq==true" style="display: flex;margin-top:-0.75em">
              <div style="margin-right:0.5em;">
                <label for="overflight_country_select" style="font-size:small">Overflight Country Name</label>
                <div style="margin-top:-0.5em;min-width:17em; font-size:small;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{selectedCountryName}}
                </div>
              </div>
              <div style="margin-right:0.5em; width: 12.25em" *ngIf="showVendorToClient==true">
                <div>
                  <label for="vendor_select" style="font-size:small">{{serviceSubTitle}}</label>
                  <div style="margin-top:-0.5em;min-width:15em; font-size: small;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                    {{selectedVendorName}}
                  </div>
                </div>
              </div>
              <div style="margin-right:0.5em">
                <div style="display:flex">
                  <div>
                    <div style="margin-right:0.5em;margin-top:0em">
                      <label for="permit_order_number" style="font-size:small; white-space:nowrap">Order #</label>
                      <div style="display:flex;margin-top:-0.5em; width:3em; font-size:small;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                        {{permit_order_number}}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div style="margin-right:0.5em">
                <div style="display:flex">
                  <div>
                    <div style="margin-right:0.5em;margin-top:0em">
                      <label for="permit_order_number" style="font-size:small; white-space:nowrap">Recall Number (Link to Flight Plan)</label>
                      <div style="display:flex;margin-top:-0.5em; width:5em; font-size:small;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                        {{selectedFPRecallNumber}}
                      </div>
                    </div>
                  </div>

                </div>
              </div>


            </div>
            <!--over flight permit end-->
            <!--landing permit and charter-->
            <div>
              <div *ngIf="(serviceTypeID==5 || serviceTypeID==26) && vendorReq==true" style="display: flex;margin-top:-0.75em">
                <div style="margin-right:0.5em" *ngIf="showVendorToClient==true">
                  <label for="landing_vendor_select" style="font-size:small">{{serviceSubTitle}}</label>
                  <div style="margin-top:-0.5em;min-width:15em; font-size: small;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                    {{selectedVendorName}}
                  </div>

                </div>

                <div>
                  <div style="margin-right:0.5em;">
                    <label for="landing_country_select" style="font-size:small">{{serviceTypeID==5?'Landing Country Name':'Charter Country Name'}}</label>
                    <div style="margin-top:-0.5em;min-width:17em; font-size: small;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                      {{selectedCountryName}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--landing permit end-->
            <!--hotel-->
            <div *ngIf="(serviceTypeID==7 || serviceTypeID==20) && showVendorToClient==true" style="display: flex;margin-top:-0.75em">
              <div>
                <div>
                  <label for="hotel_select" style="font-size:small">Hotel Property Name</label>
                  <div style="margin-top:-0.5em;font-size: small;min-width:31em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                    {{selectedHotelName}}

                  </div>
                </div>
              </div>
              <div style="margin-left:0.5em; margin-top:1.25em" *ngIf="selectedHotelName!=''">
                <button mat-button class="btn-high" style="padding-left:0.25em !important; padding-right:0.25em !important" (click)="clickHotel()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.15em">hotel</i><div style="margin-left: 0.25em">Details</div></div></button>&nbsp;
              </div>

            </div>
            <!--hotel end-->
            <!--fuel-->
            <div *ngIf="serviceTypeID==56 && showVendorToClient==true" style="display: flex;margin-top:-0.75em; ">
              <div style="margin-right: 0.5em">
                <label for="fuel_supplier_select" style="font-size:small">Fuel Supplier</label>
                <div style="    margin-top: -0.5em;width: 15em;font-size: small; min-height: 1.65em;padding-left: 0.25em;border-radius: 0.25em;background-color: #eaf0fd">
                  {{selectedFuelSupplierName}}
                </div>
              </div>
              <div style="width: 12.25em; margin-right:0.5em">
                <div *ngIf="selectedFuelSupplierName!=''">
                  <label for="intoplane_agent_select" style="font-size:small">Into Plane Agent</label>
                  <div style="margin-top:-0.5em; font-size: small;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                    {{selectedFuelIntoPlaneAgentName}}
                  </div>
                </div>
              </div>

            </div>
            <!--fuel end-->
            <div style="display: flex; font-size: small">
              <div>
                <div style="display:flex; margin-top:-0.25em; height:3em; margin-right:0.5em">
                  <div style="margin-left:0em; margin-right:0.5em">
                    <label for="task_status_select" style="font-size:small">Operational Status</label>
                    <div style="margin-top:-0.5em;min-width: 13.25em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd; padding-right:0.25em">
                      {{selectedTaskStatusDescription}}
                    </div>
                  </div>
                  <!--<div style="margin-top:1.25em; margin-left:0em">
            <button mat-button class="btn-high" (click)="clickEmail()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em; width:1.125em">messages</i><div>Messages</div></div></button>&nbsp;
          </div>-->
                </div>
                <div style="display:flex">
                  <div>
                    <div style="display:flex">
                      <div style="margin-left:0em;margin-top:0em">
                        <label for="confirmation_number" style="font-size:small">{{serviceTypeID!=58?serviceTypeID!=40 && serviceTypeID!=66 && serviceTypeID!=68? taskLabel!='' && taskLabel!=null? taskLabel + ' Number': 'Confirmation Number':'Recall Number':'Misc Description'}}<!--{{serviceTypeID!=58?'Confirmation Number':'Misc Description'}}--></label>
                        <div style="display:flex;margin-top:-0.5em; min-width:13.25em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                          {{confirmation_number}}
                        </div>
                      </div>
                      <!--<div style="margin-left:0.5em;">
                <label for="service_date" style="font-size:small">Service Date</label>
                <div style="display:flex;margin-top:-0.5em; width:8.5em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                  {{service_date}}
                </div>
              </div>-->
                      <div style="margin-left:0.5em;" class="d-inline-flex" *ngIf="requiresServiceDate">
                        <div>
                          v>
                          <label for="service_date" style="font-size:small">{{taskLabel!='' && taskLabel!=null? taskLabel: 'Service'}} Date (UTC)</label>
                          <div style="display:flex;margin-top:-0.5em; width:8.5em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                            {{service_date}}
                          </div>
                        </div>
                        <div style="margin-left:0.5em;">
                          <label for="service_date" style="font-size:small">{{taskLabel!='' && taskLabel!=null? taskLabel: 'Service'}} Time (UTC)</label>
                          <div style="display:flex;margin-top:-0.5em; width:8.5em;min-height:1.65em;padding-left:0.25em;border-radius:0.25em; background-color:#eaf0fd">
                            {{service_time}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="submitted && f.service_date.errors">
                      <div *ngIf="f.service_date.errors.isValidDate==false" style="background-color:#f9d2de; padding-left:0.25em; min-width:10em;margin-top:-0.5em; margin-left:13.5em ; font-size:small;">Invalid service date</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between;">
              <div class="form-inline" style="margin-top:0.5em; float:left; height:1.5em">
                <div>
                  Note
                </div>

              </div>
              <div style="margin-top: 0.75em;float: right; margin-right:3em; height:1em; font-size: small; display: flex"><i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard" [cdkCopyToClipboard]="clickCopyNote()">content_copy</i><div [cdkCopyToClipboard]="clickCopyNote()" style="cursor: pointer">Copy Note to Clipboard</div></div>
            </div>
            <div>
              <textarea [hidden]="selectedTabName!='public'" formControlName="public_note" class="form-control" readonly style="width:62em; height:23.5em;font-family:Courier New, Courier, monospace;font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>
            </div>
            <div style="display: flex; margin-top:0.5em">
              <div>
                <div style="width:45em; min-height:2em; background-color: #E9ECEF; border-radius:0.25em; font-size: small; padding-left: 0.25em ">
                  <div style="display: flex; margin-top:0.25em;">
                    Documents:
                  </div>
                  <!--document list-->
                  <div style="height:5em;overflow-y:auto">
                    <div>
                      <div *ngFor="let item of taskDocumentList; let i=index">
                        <div *ngIf="!item.isInternal" style="font-size: small; display:flex; padding:0em;">
                          <div><a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" title="{{item.documentName}}" (click)="clickPreviewDocument($event, item)">{{item.documentName}}</a></div>

                        </div>

                      </div>
                    </div>
                  </div>
                  <!--document list end-->
                </div>
              </div>
              <!--<div>
                <div style="display:flex;padding-left: 0.5em;font-size: small">

                  <div>
                    <div>
                      <label for="modifiedBy" style="font-size:small">Modified By</label>
                      <div style="margin-top:-0.5em;min-width:9em;padding-left:0.25em; background-color:lightgray;border-radius:0.25em">
                        {{modifiedBy}}
                      </div>
                    </div>
                    <div>
                      <label for="modifiedDate" style="font-size:small">Modified Date</label>
                      <div style="margin-top:-0.35em;width:9em;padding-left:0.25em;background-color:lightgray;border-radius:0.25em">
                        {{modifiedDate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>-->
            </div>

          </div>
        </div>
    </form>
  </div>
  
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display:flex; height:2.75em">
    <div style="margin-right:0.5em">
      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>&nbsp;
      <button mat-button class="btn-high" (click)="clickEmail()"><div style="display:flex"><i class="material-icons" style="padding-right: 1.3em;font-size: medium; margin-top:0.25em; width:1.125em">email</i><div>Messages</div></div></button>&nbsp;
    
      <button *ngIf="hasFeedback" mat-button class="btn-high" (click)="clickFeedback()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="padding-right: 0.3em;font-size: medium; margin-top:0.25em">message</i><div>   Feedback</div></div></button>&nbsp;

      <!--<button mat-button class="btn-high" *ngIf="hasTaskAudit==true" (click)="clickAudit()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">manage_search</i><div>Audit</div></div></button>&nbsp;-->
    </div>
    
  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>

