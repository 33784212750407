import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Output } from '@angular/core';
import { math } from 'azure-maps-control';
import { EventEmitter } from 'events';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { GlobalConstant } from '../common-utility/global-constant';

@Component({
  selector: 'app-fuel-price-upload',
  templateUrl: './fuel-price-upload.component.html',
  styleUrls: ['./fuel-price-upload.component.css']
})
export class FuelPriceUploadComponent implements OnInit{
  public message: string;
  public progress: number;

  @Output() public OnUploadFinished = new EventEmitter();

  constructor(private http: HttpClient) { }
  ngOnInit() {

  }

  //public uploadfile = (files) => {
  public uploadfile  (files)   {
    alert('aksak');
    if (files.length === 0)
      return;

    let fileToUpload = <File>files[0];
    const formdata = new FormData();
    formdata.append('fuelsupplier', fileToUpload, fileToUpload.name);
    formdata.append('file', fileToUpload, fileToUpload.name);

    var headerOptions = new HttpHeaders({
      //'Content-Type': 'application/json', 
      'Authorization': 'bearer ' + localStorage.getItem('token')
    });

    this.http.post(GlobalConstant.baseUrl + "FuelSupplier/UploadFuelPricing", formdata,
      { headers: headerOptions, reportProgress: true, observe: 'events' })
      .pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }))



    //this.http.post(GlobalConstant.baseUrl + "FuelSupplier/UploadFuelPricing", formdata, { headers: headerOptions, reportProgress: true, observe: 'events' })
    //  .subscribe(event => {
    //    if (event.type === HttpEventType.UploadProgress) {
    //      this.progress = Math.round(100 * event.loaded / event.total);
    //    }
    //    else if (event.type === HttpEventType.Response) {
    //      this.message = 'Upload Success';
    //      this.OnUploadFinished.emit(event.body.toString());
    //    }
    //  })
  }

}
