import { Component, Inject, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable } from 'rxjs';
import { AirportService } from '../services/airport.service';
import { DialogService } from '../services/dialog.service';
import { NoteTypeModel } from '../models/note-type.model';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { FileModel } from '../models/file.model';
import { ServiceArrangementModel } from '../models/service-arrangement.model';
import { GlobalConstant } from '../common-utility/global-constant';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { AirportDocumentModel } from '../models/airport-document.model';
import { saveAs } from 'file-saver';
import { DocumentTypeModel } from '../models/document-type.model';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-airport-documents-edit-dialog',
  templateUrl: './airport-documents-edit-dialog.component.html',
  styleUrls: ['./airport-documents-edit-dialog.component.css']
})
/** airport-profile-setup-add component*/
export class AirportDocumentsEditDialogComponent implements OnInit {
  airport_documents_edit_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() airportID: number;
  @Input() airportNoteID: number; 
  @Input() airportDocumentGUID: string;

  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  noteTypeList: NoteTypeModel[];
  airport_documnet_desc: string;
  selectedNoteTypeId: number;
  modifiedBy: string;
  modifiedDate: string;
  isModified: boolean;
  // isActive: number;
  showSuccessMsg: boolean = false; 
  successMsg: string ='upload Successfull'

  maxTempDocId: number = 0;
  allowedFileType: string;
  uploader: FileUploader;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  docSize: string;

  allowedFileTypeList: string[];
  maxFileSize: number;
  maxFileUploadNumber: number; 
  serviceArrangementList: ServiceArrangementModel[];
  selectedServiceArrangementID: number;
  totalMessageAttachmentFileSize: number = 0; 
  documentList: AirportDocumentModel[]=[];
  icao: string;
  document_name: string;
  showNoExpiry: boolean = false;  
  documentTypeList: DocumentTypeModel[];
  avaiDocumentTypeList: DocumentTypeModel[];
  selDocumentTypeList: DocumentTypeModel[];
  selectedDocumentTypeGuid: string="";
  selectedDocumentTypeDescription: string="";
  isActive: boolean = true;  


  @ViewChild('noteTypeList') noteTypeListRef: ElementRef
  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;
  isDisabled: boolean;
  effectiveDate: Date;
  expirationDate: Date;
  doesNotExpire: boolean = false;
  action: string;
  airportDocument:AirportDocumentModel;
  isDelete: boolean = false;
  errorMsg: any = "";
  showErrorMsg: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<AirportDocumentsEditDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService,    private readonly _commonService: CommonService,
    private readonly _airportService: AirportService,
    private readonly _dialogService: DialogService
  ) {
    this.airportID = data.airportID;
    this.airportNoteID = data.airportNoteID;
    this.dialogTitle = data.dialogTitle;
    this.icao = data.icao;
    this.action =data.action;
    this.airportDocumentGUID = data.airportDocumentGUID;
    this.airportDocument = data.document;

  }

  getAllData(): Observable<any[]> {

    let getNoteType = this._commonService.getNoteTypeListByxId<ResponseModel<NoteTypeModel[]>>(this.airportID.toString(), "Airport"); 
    let getDocTypeResponse = this._airportService.getAirportDocumentTypeList<ResponseModel<DocumentTypeModel[]>>(this.airportDocumentGUID);
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();

    return forkJoin([getAllowedDocTypeResponse, getDocTypeResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.totalMessageAttachmentFileSize = 0;
  
    this.maxTempDocId = 0;  
    this.allowedFileTypeList = [];
    this.allowedFileType = ""; // new Array(GlobalConstant.allowedFileTypeList).toString().split(",").join(", "); 
    this.maxFileSize = 0;    // 5242880;   // 5 mb
    this.maxFileUploadNumber = 0; 
    this.fileList = [];
    this.showSuccessMsg = false;  
    this.document_name = "";
    this.effectiveDate=null;
    this.expirationDate = null;

    this.avaiDocumentTypeList = [];
    this.selDocumentTypeList = [];
    this.isActive = true;  



    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false,
      // maxFileSize:  5242880, //5mb
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

    this.getAllData().subscribe(responses => {
     
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (responses[0].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[0].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;   // 5242880;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }


      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.documentTypeList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();
      this.airport_documents_edit_form.controls.document_name.setValue(this.airportDocument?.documentName);
      if (this.airportDocument) {
        this.airport_documents_edit_form.controls.effectiveDate.setValue(this.airportDocument.effectiveDate == "" ? null : formatDate(this.airportDocument.effectiveDate, 'yyyy-MM-dd', 'en'));
        this.airport_documents_edit_form.controls.expirationDate.setValue(this.airportDocument.expirationDate == "" ? null : formatDate(this.airportDocument.expirationDate, 'yyyy-MM-dd', 'en'));

        if (this.airportDocument.effectiveDate != null && (this.airportDocument.expirationDate == null || this.airportDocument.expirationDate == "")) {
          this.showNoExpiry = true;
          this.doesNotExpire = this.airportDocument.doesNotExpire;
        }
        if(this.airportDocument.effectiveDate == null){
          this.airport_documents_edit_form.controls.effectiveDate.setValue("");
          this.f.effectiveDate.setValue("");
        }
        if(this.airportDocument.expirationDate == null){
          this.airport_documents_edit_form.controls.expirationDate.setValue("");
          this.f.expirationDate.setValue("");
        }

      }
      else
      {
        this.airport_documents_edit_form.controls.effectiveDate.reset();
        this.airport_documents_edit_form.controls.expirationDate.reset();
      }
      this.airport_documents_edit_form.controls.size.setValue(this.airportDocument?this.airportDocument.fileSize+ " KB":"");
      this.airport_documents_edit_form.controls.airport_documnet_desc.setValue(this.airportDocument?.documentDescription);
      this.airport_documents_edit_form.controls.document_type.setValue(this.airportDocument?.documentTypeGUIDList);
      this.airport_documents_edit_form.controls.showToClient.setValue(this.airportDocument?.showToClient);

      this.showSpin = false;


    });


 

  }

  initControls() {
    this.airport_documents_edit_form = this._formBuilder.group({
      airport_documnet_desc: this.airport_documnet_desc,
      // note_status: this.isActive,
      // modifiedBy: this.modifiedBy,
      // modifiedDate: this.modifiedDate,
      document_name: [{ value: this.document_name, disabled: this.isDisabled }, Validators.required],
      size: [{ value: this.docSize, disabled: this.isDisabled }],
      document_type: [{ value: this.selectedDocumentTypeGuid, disabled: this.isDisabled }, Validators.required],
      isActive: [this.isActive],

      effectiveDate: [{ value: this.effectiveDate, disabled: this.isDisabled }],
      expirationDate: [{ value: this.expirationDate, disabled: this.isDisabled }],
      document: [null],
      showToClient: [false],

    });
  }

  get f() { return this.airport_documents_edit_form.controls; }





  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.airportNoteID = 0;
    this.selectedNoteTypeId = null;
    this.airport_documnet_desc = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.noteTypeList = [];
    this.document_name = "";
    this.effectiveDate = null;
    this.f.effectiveDate.setValue("");
    this.f.expirationDate.setValue("");
    this.avaiDocumentTypeList = [];
    this.selDocumentTypeList = [];
    this.selectedDocumentTypeGuid = "";
    this.airportDocument = null;
    this.action = 'add';
    this.airport_documents_edit_form.reset();

    this.documentTypeList.forEach(x => {
      x.selected = false;
    })
    this.setDocumentTypeList(); 

    // this.getAllData().subscribe(responses => {
    //   if (responses[0] != null) {
    //     if (responses[0].code == "200" && responses[0].message == "") {
    //       this.noteTypeList = responses[0].model;

    //       //   this.selectedAirportTypeId = null;// this.airportTypeList[0].airportTypeID;
    //     }
    //     else {
    //       if (responses[0].code == "401") {
    //         this._authService.signOut();
    //       }
    //     }
    //   }
    // });
    this.initControls();
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

 
  // upload file
  dropFile(event: any) {
    this.errMsg = "";
    //setTimeout(() => {
    //  this.errMsg="", 1000;
    //});

    if ((this.fileList.length + event.length) > this.maxFileUploadNumber) { 
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.length > this.maxFileUploadNumber) {    
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; 
      //this.finishAllUploads = true;
      //return;
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;
        setTimeout(() => this.removeFromUploaderQueue(filename), 100);
      }
    }
    else {
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;
        
        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event[i].size >= this.maxFileSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          //}
          if (event[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {
            if (this.errMsg != "") {
              this.errMsg += "\n";
            }
            this.errMsg += 'Total file sizes are over limit';
            setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          }
          
        }
        else {
          
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          
        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }
    this.errMsg = "";
    this.uploadSubmit();
  }

  removeFromUploaderQueue(filename: string) {
    var index = -1;
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (filename == fileItem.name) {
        index = i;
        break;
      }
    }

    if (index >= 0) {
      this.uploader.queue[index].remove();
    }

  }

  importFile(event: any) {
    this.errMsg = "";
    this.documentList = [];
    
    if ((this.fileList.length + event.target.files.length) > this.maxFileUploadNumber) { 
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.target.files.length > this.maxFileUploadNumber) {    
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; 
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;
        
        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event.target.files[i].size >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  this.finishAllUploads = true;
          //  return;
          //}
          //else {
          //  files.push(event.target.files[i]);

          //}
          if (event.target.files[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {             
            this.errMsg = 'File size is over the limit of 25 MB.';
            this.finishAllUploads = true;
            return;
          }
          else {
            files.push(event.target.files[i]);
            this.f.document_name.setValue(filename);

          }
        }
        else {
          this.finishAllUploads = true;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        
        if (result.model.length > 0) {
          result.model.forEach((item) => {
            let obj = new FileModel();
            let obj2 = new AirportDocumentModel();
            this.maxTempDocId += 1;
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            obj.tempId = this.maxTempDocId;
            this.totalMessageAttachmentFileSize += item.fileSize * 1024;
            this.fileList.push(obj);

            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.fileSize = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.airportID = this.airportID;
            obj2.containerName = 'airportdocuments';
            obj2.documentDescription = 'doc1';
            obj2.containerPath = this.icao;


            
            this.f.size.setValue(obj.fileSize.toString()+ " KB");

            this.documentList.push(obj2);
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  } 

  clickPreviewDocument() {
    this._airportService.getAirportDocument<ResponseModel<AirportDocumentModel[]>>(this.airportDocument).subscribe(response => {
      if (response != null && response.code == "200") {
        let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        let file = new Blob([byteArray], { type: this.airportDocument.mimeType });
        file.size
        var fileURL = URL.createObjectURL(file); 
        window.open(fileURL);
      }
      this.loading = false;
      this.showSpin = false;

    })
  }
 
  onFileClick(event) {
    this.errMsg = "";
    event.target.value = '';
  }
  // upload file end

  clickSave() { 
    // if (this.effectiveDateRef.nativeElement.value !== "") {
    //   if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null) {
    //     let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
    //     let f1 = CustomValidators.isValidDate(s1);
    //     if (!f1) {
    //       this.f.effectiveDate.setErrors({ isValidDate: f1 });
    //     }
    //   }
    //   else {
    //     this.f.effectiveDate.setErrors({ isValidDate: false });
    //   }


    //   if (this.expirationDateRef.nativeElement.value !== "") {
    //     if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null) {
    //       let s2 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
    //       let f2 = CustomValidators.isValidDate(s2);
    //       if (!f2) {
    //         this.f.expirationDate.setErrors({ isValidDate: f2 });
    //       }
    //     }
    //     else {
    //       this.f.expirationDate.setErrors({ isValidDate: false });
    //     }
    //   }
    // }

    if (this.f.expirationDate.value != null && this.f.expirationDate.value != '')

      if (this.f.effectiveDate.value > this.f.expirationDate.value) {
        this.f.expirationDate.setErrors({ matEndDateInvalid: true });
        return;
      }

    if (this.f.effectiveDate.value == "") {
      this.f.effectiveDate.setErrors({ required: true });
    }


    this.loading = true;
    this.errorMsg = "";

    // if(this.airport_documents_edit_form.invalid){
    //   this.errorMsg = "Fill All The Required Fields Correctly";
    //   this.showErrorMsg = true;
    //   return;
    // }
    // else{
    //   this.errorMsg = "";
    //   this.showErrorMsg = false;
    // }
    this.documentList[0].effectiveDate  = this.action == 'edit'? this.documentList[0].effectiveDate: null;
    if (this.f.effectiveDate.value != null) {
      this.documentList[0].effectiveDate = this.f.effectiveDate.value ;//this.effectiveDateRef.nativeElement.value;
    }
      
    this.documentList[0].expirationDate  = this.action == 'edit'? this.documentList[0].expirationDate: null;

    if (this.f.expirationDate.value != null) {
      this.documentList[0].expirationDate = this.f.expirationDate.value; //  this.expirationDateRef.nativeElement.value;
      
    }

    this.documentList[0].documentTypeGUIDList = "";
    this.documentList[0].documentTypeGUIDList = this.f.document_type.value;

    this.documentList[0].doesNotExpire = this.doesNotExpire;
    this.documentList[0].documentDescription =  this.f.airport_documnet_desc.value;
    this.documentList[0].isActive = this.isActive;  
    this.documentList[0].documentName =this.f.document_name.value;
    // this.documentList.push(obj);


    this.isModified = true;

    this.errorMsg = this.documentList[0].documentName ? this.errorMsg  :  "Please Enter Document Name"   ; 
    this.errorMsg = this.documentList[0].documentTypeGUIDList ? this.errorMsg  : "Please Select Document Type"   ; 

    this.documentList[0].showToClient =  this.f.showToClient.value == 'true' ? true : false;
    this.documentList[0].isActive =  this.f.isActive.value == 'false' ? false : true;
    this.documentList[0].expirationDate = this.documentList[0].expirationDate == ""? null: this.documentList[0].expirationDate;
    this.documentList[0].effectiveDate = this.documentList[0].effectiveDate == ""? null: this.documentList[0].effectiveDate;
    if((this.documentList[0].expirationDate != null && this.documentList[0].effectiveDate == null)  )
      this.errorMsg = 'Please Select Effective Date';

    if ( this.errorMsg != "" &&  this.errorMsg != null){
      this.showErrorMsg = true;
      this.loading = false;
      setTimeout(() => {
        this.showErrorMsg = false; 
      }, 2000);
    }
    else{

      this._airportService.uploadAirportDocument<ResponseModel<string>>(this.documentList[0]).subscribe(response => {
        if (response.code == "200" && response.message == "") {
          this.loading = false;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.successMsg = 'Upload Successfull'
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);
          
        }
      }  
      );
    }  
    
  }
 
  clickUpdate(){
    
    let obj = new AirportDocumentModel();

    obj.airportDocumentGUID = this.airportDocument.airportDocumentGUID;
    obj.documentName = this.f.document_name.value;
    obj.fileExtension = this.airportDocument.fileExtension;
    // obj.fileSize = item.fileSize;
    obj.mimeType = this.airportDocument.mimeType;
    // obj.bFile = item.bFile;
    obj.airportID = this.airportID;
    obj.containerName = 'airportdocuments';
    obj.containerPath = this.icao;
    obj.fileSize = this.airportDocument.fileSize;


    obj.documentDescription =  this.f.airport_documnet_desc.value  ; 

    obj.effectiveDate = this.f.effectiveDate.value == ""? null : this.f.effectiveDate.value;
    obj.expirationDate = this.f.expirationDate.value== ""? null : this.f.expirationDate.value;   
    
    
    obj.doesNotExpire =  this.doesNotExpire;
    obj.documentTypeGUIDList =  this.f.document_type.value;


    obj.isActive = this.f.isActive.value == 'false' ? false : true;   
    obj.showToClient =  this.f.showToClient.value == 'true' ? true : false;

    if(this.airport_documents_edit_form.invalid){
      this.errorMsg = "Fill All The Required Fields Correctly";
      this.showErrorMsg = true;
      return;
    }
    else{
      this.errorMsg = "";
      this.showErrorMsg = false;
    }

    this.isModified = true;
   
    this.errorMsg = obj.documentName ? "" :  "Please Enter Document Name"   ; 
    this.errorMsg = obj.documentTypeGUIDList ? "" : "Please Select Document Type"   ; 

    obj.expirationDate = obj.expirationDate == ""? null: obj.expirationDate;
    obj.effectiveDate = obj.effectiveDate == ""? null: obj.effectiveDate;
    if(obj.expirationDate != null && obj.effectiveDate == null)
      this.errorMsg = 'Please Select Effective Date';
    else
      this.errorMsg = "";
 

    if ( this.errorMsg != "" &&  this.errorMsg != null){
      this.showErrorMsg = true;
      setTimeout(() => {
        this.showErrorMsg = false; 
      }, 2000);
    }
    else{

      this.documentList.push(obj);
      
      this._airportService.uploadAirportDocument<ResponseModel<string>>(this.documentList[0]).subscribe(response => {
        if (response.code == "200" && response.message == "") {
          this.successMsg = this.isDelete == true? 'Deleted Succesfully' : 'Updated Successfull'
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            
            this.clickClose();
          }, 1000);
          
        }
      }); 
    }
  }

  clickDelete(){
    this.isActive = false;  
    this.isDelete = true;
    this.clickUpdate();
  }

  

  downloadAttachment(  item: AirportDocumentModel) {
    let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
    let file = new Blob([byteArray], { type: item.mimeType });
    saveAs(file, item.documentName);
  }

  downloadAllAttachments() {
    this.documentList.forEach((att) => {
      this.downloadAttachment(att);
    });
  }

  checkNoExpiry(isChecked: boolean) {
    if (isChecked)
      this.f.expirationDate.setValue("");
    this.doesNotExpire = isChecked;
    
  }

  
  setDocumentTypeList() {
    this.errMsg = "";
    this.avaiDocumentTypeList = this.documentTypeList.filter(
      (thing, i, arr) => arr.findIndex(t => t.documentTypeGUID === thing.documentTypeGUID && t.selected == false) === i
    );
    this.selDocumentTypeList = this.documentTypeList.filter(
      (thing, i, arr) => arr.findIndex(t => t.documentTypeGUID === thing.documentTypeGUID && t.selected == true) === i 
    );
    this.selectedDocumentTypeGuid = this.selDocumentTypeList[0].documentTypeGUID;
    this.selectedDocumentTypeDescription = this.selDocumentTypeList[0].documentTypeDescription;
    this.f.document_type.setValue(this.selectedDocumentTypeGuid);
    this.setValidation(this.selectedDocumentTypeDescription);
  }

  docTypeChange(docType: any) {
    this.selectedDocumentTypeDescription = docType[docType.selectedIndex].text;
    this.setValidation(this.selectedDocumentTypeDescription);
    
  }

  setValidation(docType: string) {
    // this.dateLabel = "Effective Date";
    // this.f.effectiveDate.clearValidators();
    // this.f.expirationDate.clearValidators();
    // if (docType == "Aircraft Registration") {
    //   this.dateLabel = "Issue Date";
    //   this.f.effectiveDate.setValidators(Validators.required);
    //   //this.f.expirationDate.setValidators(Validators.required);
    // }
    // if (docType.includes("Insurance")) {
    //   this.f.effectiveDate.setValidators(Validators.required);
    // }
    // this.f.effectiveDate.updateValueAndValidity();
    // this.f.expirationDate.updateValueAndValidity();
    
  }
 

  preventFocus(event: Event) {
    event.preventDefault();
    // Optionally, you can blur the input explicitly here if needed.
    // this.inputField.nativeElement.blur();
  }

  expiryDateChange(){
    if (this.f.effectiveDate.value != null && (this.f.expirationDate.value == null || this.f.expirationDate.value == "")) {
      this.showNoExpiry = true;
    }
    else
      this.showNoExpiry = false;

  }
  
}
