export class FuelCommissionsModel {
  fuelCommissionID: number;
  lk_FuelSupplierGUID: string;
  intoPlaneAgent: string;
  countryID: number;
  airportID: number;
  icao: string; 
  iata: string;
  commissionAmt: number;
  currencyID: string;
  lk_UnitOfMeasureID: number;
  isActive: boolean;
  isExpired: boolean;

  effectiveDate: string;
  expiryDate: string;

  modifiedBy: string;
  modifiedDate: string;


  expirationDate: string;
  selectedFuelSupplierList: string;
  selectedIntoPlaneAgentList: string;
  selectedCountryIDList: string;
  selectedICAOList: string; 

  internalNotes: string;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;

  selected: boolean;
}
