import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GlobalConstant } from '../common-utility/global-constant';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { PersonModel } from '../models/person.model';
import { Arrival_DepartureStatusModel } from '../models/arrival_departure-status.model';
import { FARTypeModel } from '../models/far-type.model';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { AirportModel } from '../models/airport.model';
import { AirportService } from '../services/airport.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopAlternateAirportModel } from '../models/ground-stop-alternate-airport.model';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopAlternateAirportEditComponent } from '../ground-stop-advanced/ground-stop-alternate-airport-edit.component';
//import { GenerateMessageTemplateComponent } from './generate-message-template.component';
import { DistanceTimeModel } from '../models/distance-time.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ReturnObjModel } from '../models/return-obj.model';
import { TripLegService } from '../services/trip-leg.service';
import { TripLegModel } from '../models/trip-leg.model';
import { TripModel } from '../models/trip.model';
import { CancelAirportDialogComponent } from '../ground-stops/cancel-airport-dialog.component';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-leg-detail-editor-dialog',
  templateUrl: './leg-detail-editor-dialog.component.html',
  styleUrls: ['./leg-detail-editor-dialog.component.css']
})

export class LegDetailEditorDialogComponent implements OnInit {
  isModified: boolean = false;
  showSpin: boolean = false;
  groundStopGUID: string;
  nextGroundStopGUID: string;
  isFirstGroundStop: boolean;
  userType: string;
  customerGUID: string;
  tripCodeGUID: string;
  tripCodeType: string;
  leg_detail_editor_form: UntypedFormGroup;
  loading: boolean = false;
  
  //reloadChildPage: boolean;

  aircraftGUID: string;
  //clientName: string;
  validatedAirport: boolean = false;
  //icao: string;
  //oldICAO: string;
  //iata: string;
  //mapLink: string;
  registration: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  ac_speed: number;
  trip_code: string;
  //airportName: string;
  //airportState_CountryCode: string;
  //runwayMaxLength: string;
  //elevation: string;
  utc_conversion: string;
  is_tech_stop: boolean
  maxGroundStopSequenceID: number;
  currentGroundStopSequenceID: number;
  submittedArrDate: boolean = false;
  arrivalDateUTC: string;
  arrivalTimeUTC: string;
  arrivalDateLocal: string;
  arrivalTimeLocal: string;
  arrStartAt: Date;
  arr_CallSign: string;
  arr_FARType: string;
  submittedDepDate: boolean = false;
  departureDateUTC: string;
  departureTimeUTC: string;
  departureDateLocal: string;
  departureTimeLocal: string;
  depStartAt: Date;
  dep_CallSign: string;
  ete: string;
  departureICAO: string;
  departureIATA: string;
  arrivalICAO: string;
  arrivalIATA: string;
  departureLatitude: number;
  departureLongitude: number;
  arrivalLatitude: number;
  arrivalLongitude: number;
  errMsg: string;
  errMsg2: string;
  msg: string;
  farTypeList: FARTypeModel[];
  arrival_departureStatusList: Arrival_DepartureStatusModel[];
  groundStopTypeList: GroundStopTypeModel[];
  selectedDepFARTypeID: number;
  selectedArrivalStatusID: number;
  selectedDepartureStatusID: number;
  selectedArrivalStatusColor: string;
  selectedDepartureStatusColor: string;
  selectedGroundStopTypeGUID: string;
  groundStopType: string;
  groundStopTypeColor: string;
  groundStopList: GroundStopModel[];
  //selectedGroundStop: GroundStopModel;
  airportList: AirportModel[];
  duplicateIATAErrMsg: string;
  wrongAirportErrMsg: string;
  latitude: number;
  longitude: number;

  nextGroundStopTypeGUID: string = "";
  nextGroundStopTypeDesc: string = "";
  nextGroundStopTypeColor: string = "";
  @ViewChild('arrivalDateUTC') arrivalDateUTCRef: ElementRef;
  @ViewChild('departureDateUTC') departureDateUTCRef: ElementRef;

  cancelAllServices: boolean = false;
  controlValueChanged: boolean = false;
  //arr_pets: boolean;
  //arr_weapons: boolean;
  dep_pets: boolean;
  dep_weapons: boolean;
  currentIndex: number;

  updateNextArrivalDateTime: boolean;
  deptCrewList: GroundStopManifestModel[];
  deptPaxList: GroundStopManifestModel[];
  arrvCrewList: GroundStopManifestModel[];
  arrvPaxList: GroundStopManifestModel[];
  isCrewMismatch: boolean = false;
  isPaxMismatch: boolean = false;
  picCount: number = 0;
  sicCount: number = 0;
  crewCount: number = 0;
  paxCount: number = 0;
  isOnHold: boolean = false;
  depFerryFlight: boolean = false;
  isTechStop: number = 0;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  countryGUID: string;

  showSuccessMsg: boolean = false;
  crewPaxTooltip: string;

  //prevICAO: string;
  //nextICAO: string;
  hasTask: boolean;
  successMsg: string = "Your changes have been saved";
  returnObj = new ReturnObjModel();
  lockdownTrip: boolean = false;
  originalGroundStopTypeGUID: string;
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  upPII: boolean = false;
  legGroundStopTypeGUID: string;
    isValidDeptDate: boolean;
    departureDateLocal_DTType: Date;
    departureDateUTC_DTType: Date;
    isValidDeptTime: boolean;
    arrivalDateLocal_DTType: Date;
    isValidArrDate: boolean;
    arrivalDateUTC_DTType: Date;
    isValidArrTime: boolean;
    departureUTCTimeConversion: string;
    arrivalUTCTimeConversion: string;
  constructor(private readonly _dialogRef: MatDialogRef<LegDetailEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _dialogService: DialogService,
    private _router: Router, private readonly _groundStopService: GroundStopService, private readonly _airportService: AirportService, private readonly _route: ActivatedRoute,
    private readonly _dialog: MatDialog, private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _tripLegService: TripLegService
  ) {
    this.groundStopGUID = _data.groundStopGUID;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.aircraftGUID = _data.aircraftGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.customerGUID = _data.customerGUID;
   // this.tripCodeType = _data.tripCodeType;

  }

  getAllData(): Observable<any[]> {
    let request = new TripLegModel();
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    request.nextArrivalGroundStopGUID = this.nextGroundStopGUID;
    request.aircraftGUID = this.aircraftGUID;
    request.customerGUID = this.customerGUID;
    let getFARListResponse = this._groundStopService.getFARTypeList();
    let getArrival_DepartureStatusList = this._groundStopService.getArrivalDepartureStatusList();
    let getGroundStopTypeList = this._groundStopService.getGroundStopTypeList();

    let getLegResponse = this._tripLegService.getTripLegBydgIdagId(request);
    let getPersonResponse = this._tripLegService.getTripLegPersonListBydgIdagId(request);
    return forkJoin([getFARListResponse, getArrival_DepartureStatusList, getGroundStopTypeList, getLegResponse, getPersonResponse]);
    
  }


  ngOnInit() {
    this.setInit();
  }

  setInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.errMsg2 = "";
    this.msg = "";
    this.duplicateIATAErrMsg = "";
    this.wrongAirportErrMsg = "";
    this.groundStopList = [];
    //this.icao = "";
    //this.iata = "";    
    this.registration = "";
    this.aircraftManufacturer = "";
    this.aircraftModel = "";
    this.aircraftInfo = "";
    this.ac_speed = 0;
    this.trip_code = "";
    //this.airportName = "";
    //this.airportState_CountryCode = "";
    //this.runwayMaxLength = "";
    //this.elevation = "";
    this.utc_conversion = "";
    //this.call_sign = "";
    this.maxGroundStopSequenceID = 0;
    this.currentGroundStopSequenceID = 0;
    this.arrivalTimeUTC = "";
    this.arrivalTimeLocal = "";
    this.departureTimeUTC = "";
    this.departureTimeLocal = "";
    this.ete = "";
    this.is_tech_stop = false;
    
    //this.arrivalDateUTC = "";
    //this.arrivalTimeUTC = "";
    
    this.arr_CallSign = "";
    this.dep_CallSign = "";
    this.arr_FARType = "";
    this.farTypeList = [];
    this.arrival_departureStatusList = [];
    this.groundStopTypeList = [];
    this.controlValueChanged = false;
    this.groundStopType = "";
    this.groundStopTypeColor = "";
    this.nextGroundStopTypeGUID = "";
    this.nextGroundStopTypeDesc = "";
    this.nextGroundStopTypeColor = "";
    this.countryGUID = "";
    this.dep_pets = false;
    this.dep_weapons = false;
    this.currentIndex = 0;
    this.hasTask = false;
    this.originalGroundStopTypeGUID = "";
    this.lockdownTrip = false;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getAllData().subscribe(responses => {

      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.farTypeList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.arrival_departureStatusList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.groundStopTypeList = responses[2].model;
          switch (this.tripCodeType) {
            case "Estimate":
              this.groundStopTypeList = this.groundStopTypeList.filter(x => x.groundStopTypeDescription.indexOf('Estimate') != -1 || x.groundStopTypeDescription == "Cancellation")
              break;
            case "Consultation":
              this.groundStopTypeList = this.groundStopTypeList.filter(x => x.groundStopTypeDescription.indexOf('Consultation') != -1 || x.groundStopTypeDescription == "Cancellation")
              break;
            default:
              this.groundStopTypeList = this.groundStopTypeList.filter(x => x.groundStopTypeDescription != "Estimates" && x.groundStopTypeDescription != "Consultation")
              break;
          }
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].model != null) {
          let leg = new TripLegModel();
          leg = responses[3].model;
          this.departureICAO = leg.departureICAO;
          this.departureIATA = leg.departureIATA;
          this.departureLatitude = leg.departureLatitude;
          this.departureLongitude = leg.departureLatitude;
          this.arrivalICAO = leg.nextArrivalICAO;
          this.arrivalIATA = leg.nextArrivalIATA;
          this.arrivalLatitude = leg.nextArrivalLatitude;
          this.arrivalLongitude = leg.nextArrivalLongitude;
          this.currentGroundStopSequenceID = leg.groundStopSequenceID
          this.maxGroundStopSequenceID = leg.nextArrivalGroundStopSequenceID;
          this.registration = leg.registration;         
          this.ac_speed = leg.speed;
          if (this.ac_speed == 0) {
            this.ac_speed = 460;
          }
          this.trip_code = leg.tripCode;
          //if (leg.isNextArrivalTechStop) {
          //  this.is_tech_stop = 1;
          //}
          //else {
          //  this.is_tech_stop = 0;
          //}
          this.is_tech_stop = leg.isNextArrivalTechStop;
          this.arrivalDateUTC = leg.nextArrivalDateUTC;
          this.arrivalDateUTC_DTType = leg.nextArrivalDateUTC_DTType;
          this.arrivalTimeUTC =leg.nextArrivalTimeUTC;
          this.arrivalDateLocal = leg.nextArrivalDateLocal;
          this.arrivalTimeLocal = leg.nextArrivalTimeLocal;
          this.arrStartAt = leg.arrStartAt;
          this.departureDateUTC = leg.departureDateUTC;
          this.departureDateUTC_DTType = leg.departureDateUTC_DTType;
          this.departureTimeUTC = leg.departureTimeUTC;
          this.departureDateLocal = "";
          if (leg.departureDateLocal!="")
            this.departureDateLocal = leg.departureDateLocal;
          this.departureTimeLocal = leg.departureTimeLocal;
          this.depStartAt = leg.depStartAt;
          this.ete = leg.ete;
          this.dep_CallSign = leg.departureCallSign;
          this.selectedDepFARTypeID = leg.departureFARTypeID;
          this.dep_pets = leg.departurePetsOnBoard;
          this.dep_weapons = leg.departureWeaponsOnBoard;
          this.depFerryFlight = leg.isDeptFerryFlight;
          this.isOnHold = leg.departureOnHold;
          
          this.selectedArrivalStatusID = leg.nextArrivalStatusID;
          if (this.selectedArrivalStatusID == 0) {
            this.selectedArrivalStatusID = this.arrival_departureStatusList[0].arrival_DepartureStatusID;
          }
          this.selectedArrivalStatusColor = this.arrival_departureStatusList.find(x => x.arrival_DepartureStatusID == this.selectedArrivalStatusID).colorCode;
          this.selectedDepartureStatusID = leg.departureStatusID;
          if (this.selectedDepartureStatusID == 0) {
            this.selectedDepartureStatusID = this.arrival_departureStatusList[0].arrival_DepartureStatusID;
          }
          this.selectedDepartureStatusColor = this.arrival_departureStatusList.find(x => x.arrival_DepartureStatusID == this.selectedDepartureStatusID).colorCode;
          this.lockdownTrip = leg.lockdownTrip;
          this.legGroundStopTypeGUID = leg.legGroundStopTypeGUID.toLowerCase();
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      //if (this.nextGroundStopTypeDesc != "" && this.nextGroundStopTypeDesc == "Contingency") {
      //  this.selectedGroundStopTypeGUID = this.nextGroundStopTypeGUID.toLowerCase();
      //  this.groundStopTypeColor = this.nextGroundStopTypeColor;
      //}
      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == ""  && responses[4].model!=null) {
          this.deptCrewList = responses[4].model.departureManifest.filter(x => x.personType.toLowerCase() != 'pax');
          this.deptPaxList = responses[4].model.departureManifest.filter(x => x.personType.toLowerCase() == 'pax');
          this.arrvCrewList = responses[4].model.arrivalManifest.filter(x => x.personType.toLowerCase() != 'pax');
          this.arrvPaxList = responses[4].model.arrivalManifest.filter(x => x.personType.toLowerCase() == 'pax');
          this.deptCrewList.forEach(v => { v.personLegType = '' });
          this.arrvCrewList.forEach(v => { v.personLegType = '' });
          this.deptPaxList.forEach(v => { v.personLegType = '' });
          this.arrvPaxList.forEach(v => { v.personLegType = '' });
          let dPic = this.deptCrewList.filter(x => x.personType.toLowerCase() == 'pic');
          let dSic = this.deptCrewList.filter(x => x.personType.toLowerCase() == 'sic');
          let dCrew = this.deptCrewList.filter(x => x.personType.toLowerCase() == 'crew');
          let aPic = this.arrvCrewList.filter(x => x.personType.toLowerCase() == 'pic');
          let aSic = this.arrvCrewList.filter(x => x.personType.toLowerCase() == 'sic');
          let aCrew = this.arrvCrewList.filter(x => x.personType.toLowerCase() == 'crew');
          this.picCount = dPic.length;
          this.sicCount = dSic.length;
          this.crewCount = dCrew.length;
          this.paxCount = this.deptPaxList.length;
          
          if (JSON.stringify(dPic.sort((a, b) => a.personGUID.localeCompare(b.personGUID))) != JSON.stringify(aPic.sort((a, b) => a.personGUID.localeCompare(b.personGUID))))
            this.isCrewMismatch = true;
          if (JSON.stringify(dSic.sort((a, b) => a.personGUID.localeCompare(b.personGUID))) != JSON.stringify(aSic.sort((a, b) => a.personGUID.localeCompare(b.personGUID))))
            this.isCrewMismatch = true;
          if (JSON.stringify(dCrew.sort((a, b) => a.personGUID.localeCompare(b.personGUID))) != JSON.stringify(aCrew.sort((a, b) => a.personGUID.localeCompare(b.personGUID))))
            this.isCrewMismatch = true;
          if (JSON.stringify(this.deptPaxList.sort((a, b) => a.personGUID.localeCompare(b.personGUID))) != JSON.stringify(this.arrvPaxList.sort((a, b) => a.personGUID.localeCompare(b.personGUID))))
            this.isPaxMismatch = true;
       
         
        }
      }
      this.initControls();

      this.checkFormControlValueChanged();

      this.showSpin = false;

    });
    //  this.initControls();
  }

  initControls() {
    this.leg_detail_editor_form = this._formBuilder.group({
      //icao: [this.icao, Validators.compose([
      //  Validators.required,
      //  Validators.pattern("^[-a-zA-Z0-9\*]*$")]
      //)],
      ground_stop_type_select: [this.legGroundStopTypeGUID],
      departureDateUTC: [new Date(this.departureDateUTC)],
      departureTimeUTC: [this.departureTimeUTC],//, Validators.pattern("^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$")],
      departureDateLocal: [this.departureDateLocal],
      departureTimeLocal: [this.departureTimeLocal],
      dep_fartype_select: [this.selectedDepFARTypeID],
      dep_CallSign: [this.dep_CallSign],
      departure_status_select: [this.selectedDepartureStatusID],
      ete: [this.ete],//, Validators.pattern("^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$")],
      dep_pets: [this.dep_pets],
      dep_weapons: [this.dep_weapons],
      tech_stop_select: [this.is_tech_stop],
      arrivalDateUTC: [new Date(this.arrivalDateUTC)],
      arrivalTimeUTC: [this.arrivalTimeUTC],//, Validators.pattern("^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$")],
      arrivalDateLocal: [this.arrivalDateLocal],
      arrivalTimeLocal: [this.arrivalTimeLocal],
      arrival_status_select: [this.selectedArrivalStatusID],
      isOnHold: this.isOnHold,
      dep_ferry_flight: this.depFerryFlight
    });


    setTimeout(() => {
      this.f.departureDateUTC.setErrors(null);
      this.f.arrivalDateUTC.setErrors(null);
    }, 1000
    );
    this.f.departureDateUTC.setErrors({ validateCompareArrival_DepartureDateTime: true });
    this.f.arrivalDateUTC.setErrors(null);

    this.f.arrivalDateUTC.updateValueAndValidity();
  }

  get f() { return this.leg_detail_editor_form.controls; }

  checkFormControlValueChanged(): void {
    ////this.ground_stop_details_form.get("icao").valueChanges.subscribe(x => {
    ////  this.controlValueChanged = true;
    ////});
    //this.ground_stop_details_form.get("ground_stop_type_select").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("tech_stop_select").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("arrivalDateUTC").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("arrivalTimeUTC").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("arrival_status_select").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("departureDateUTC").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("departureTimeUTC").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("departure_status_select").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("dep_fartype_select").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("dep_CallSign").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("ete").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    ////this.ground_stop_details_form.get("arr_pets").valueChanges.subscribe(x => {
    ////  this.controlValueChanged = true;
    ////});
    ////this.ground_stop_details_form.get("arr_weapons").valueChanges.subscribe(x => {
    ////  this.controlValueChanged = true;
    ////});
    //this.ground_stop_details_form.get("dep_pets").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("dep_weapons").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
  }

  dateUTCChange(dateType: string, e: any) {
    
    if (dateType == "A") {
      this.submittedArrDate = true;
      if (e.target.value != "" && e.target.value != null) {
        let vd = CustomValidators.isValidDate(e.target.value);
        if (!vd) {
          this.f.arrivalDateUTC.setErrors({ isValidDate: false });
        }
        else {
          let v = true;
          if (this.f.departureDateUTC.value != null) {
            v = this.validateCompareArrival_DepartureDateTime();
          }
          if (v == true) {
            this.f.arrivalDateUTC.setErrors(null);
            this.f.arrivalTimeUTC.setErrors(null);
            this.f.departureDateUTC.setErrors(null);
            this.f.departureTimeUTC.setErrors(null);
            // add to cal localtime
            if (this.f.arrivalTimeUTC.value != "") {
              let udt = e.target._elementRef.nativeElement.value + " " + this.f.arrivalTimeUTC.value;
              this.getAirportLocalTime(udt, dateType).subscribe(res => {
                if ((res != null || res != undefined) && res == true) {

                }

              });
            }
            else {
              this.f.arrivalDateLocal.setValue('');
              this.f.arrivalTimeLocal.setValue('');
            }
            // add to cal localtime end
          }
          else {
            this.f.arrivalDateUTC.setErrors({ validateCompareArrival_DepartureDateTime: false });
          }
        }
      }
      else {
        if (e.target._elementRef.nativeElement.value != "") {
          this.f.arrivalDateUTC.setErrors({ isValidDate: false });
        }
        else {
          this.f.arrivalDateUTC.setErrors(null);
          this.f.arrivalDateLocal.setValue('');
          this.f.arrivalTimeLocal.setValue('');
        }
      }
    }
    if (dateType == "D") {
      this.submittedDepDate = true;
      if (e.target.value != "" && e.target.value != null) {
        let vd = CustomValidators.isValidDate(e.target.value);
        if (!vd) {
          this.f.departureDateUTC.setErrors({ isValidDate: false });
        }
        else {
      
            this.f.departureDateUTC.setErrors(null);
            this.f.departureTimeUTC.setErrors(null);
            this.f.arrivalDateUTC.setErrors(null);
            this.f.arrivalTimeUTC.setErrors(null);
            // add to cal localtime
            if (this.f.departureTimeUTC.value != "") {
              let udt = e.target._elementRef.nativeElement.value + " " + this.f.departureTimeUTC.value;
              this.getAirportLocalTime(udt, dateType).subscribe(res => {
                if ((res != null || res != undefined) && res == true) {

                }

              });
            }
            else {
              this.f.departureDateLocal.setValue('');
              this.f.departureTimeLocal.setValue('');
            }
            // add to cal localtime end
            if (this.f.departureTimeUTC.value != "" && this.f.departureTimeUTC.value != null  && (this.arrivalDateUTC == "" || this.arrivalTimeUTC == "")) {
              this.calculateNextArrivalDateTime().subscribe(res => {
                if ((res != null || res != undefined) && res == true) {

                }

              });
            }
            else {
              if (this.arrivalDateUTC != "" && this.arrivalTimeUTC != "") {
                    if (this.f.ete.value != "") {
                      if (this.leg_detail_editor_form.controls['ete'].invalid == false) {
                        this.updateNextArrivalDateTime = true;
                        this.updateArrival();
                      }
                    }
                    else {
                      this.calculateNextArrivalDateTime().subscribe(res => {
                        if ((res != null || res != undefined) && res == true) {

                        }

                      });
                    

                  }
                

              }
            }
         
        }
      }
      else {
        if (e.target._elementRef.nativeElement.value != "") {
          this.f.departureDateUTC.setErrors({ isValidDate: false });
        }
        else {
          this.f.departureDateUTC.setErrors(null);
          this.f.departureDateLocal.setValue('');
          this.f.departureTimeLocal.setValue('');
          this.f.ete.setValue('');
        
            if (this.arrivalDateUTC != "" && this.arrivalTimeUTC != "") {
                  //this.f.ete.setValue('');
                  this.updateNextArrivalDateTime = true;
                  this.arrivalDateUTC = "";
                  this.arrivalTimeUTC = "";
                  this.f.arrivalDateUTC.setValue('');
                  this.f.arrivalTimeUTC.setValue('');
                  this.f.arrivalDateLocal.setValue('');
                  this.f.arrivalTimeLocal.setValue('');
            
            // end
          }

        }

      }
    }
  }

  timeUTCChange(dateType: string, e: any) {
    //this.reloadChildPage = false;

    if (dateType == "A") {
      if (e.target.value == "") {
        this.f.arrivalTimeUTC.setErrors(null);
        this.f.arrivalTimeUTC.setValue('');
        this.f.arrivalDateLocal.setValue('');
        this.f.arrivalTimeLocal.setValue('');
      }
      else {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        //item.arrivalTimeUTC = timeObj.timeString;
        if (!timeObj.isValidDateTime) {
          this.f.arrivalTimeUTC.setErrors({ pattern: true });
        }
        else {
          this.f.arrivalTimeUTC.setValue(timeObj.timeString);
          e.target.value = timeObj.timeString;
          let v = true;
          if (this.f.departureTimeUTC.value != "") {
            v = this.validateCompareArrival_DepartureDateTime();
          }
          if (v == true) {
            this.f.arrivalTimeUTC.setErrors(null);
            this.f.arrivalDateUTC.setErrors(null);
            this.f.departureDateUTC.setErrors(null);
            this.f.departureTimeUTC.setErrors(null);
            // add to cal localtime
            if (this.f.arrivalDateUTC.value != null) {
              let udt = this.arrivalDateUTCRef.nativeElement.value + " " + this.f.arrivalTimeUTC.value;
              this.getAirportLocalTime(udt, dateType).subscribe(res => {
                if ((res != null || res != undefined) && res == true) {

                }

              });
            }
            else {
              this.f.arrivalDateLocal.setValue('');
              this.f.arrivalTimeLocal.setValue('');
            }
            // add to cal localtime end
          }
          else {
            this.f.arrivalTimeUTC.setErrors({ validateCompareArrival_DepartureDateTime: false });
          }
        }
      }
    }
    if (dateType == "D") {
      if (e.target.value == "") {
        this.f.departureTimeUTC.setErrors(null);
        this.f.departureTimeUTC.setValue('');
        this.f.departureDateLocal.setValue('');
        this.f.departureTimeLocal.setValue('');
        this.f.ete.setValue('');
        //if (this.nextICAO != "") {
          if (this.arrivalDateUTC != "" && this.arrivalTimeUTC != "") {
                //this.f.ete.setValue('');
                this.updateNextArrivalDateTime = true;
                this.arrivalDateUTC = "";
                this.arrivalTimeUTC = "";
                this.f.arrivalDateUTC.setValue('');
                this.f.arrivalTimeUTC.setValue('');
                this.f.arrivalDateLocal.setValue('');
                this.f.arrivalTimeLocal.setValue('');

//          }

        }
      }
      else {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        //item.arrivalTimeUTC = timeObj.timeString;
        if (!timeObj.isValidDateTime) {
          this.f.departureTimeUTC.setErrors({ pattern: true });
        }
        else {
          e.target.value = timeObj.timeString;
          this.f.departureTimeUTC.setValue(timeObj.timeString);
   
            this.f.departureDateUTC.setErrors(null);
            this.f.departureTimeUTC.setErrors(null);
            this.f.arrivalTimeUTC.setErrors(null);
            this.f.arrivalDateUTC.setErrors(null);
            // add to cal localtime
            if (this.f.departureDateUTC.value != null) {
              let udt = this.departureDateUTCRef.nativeElement.value + " " + this.f.departureTimeUTC.value;
              this.getAirportLocalTime(udt, dateType).subscribe(res => {
                if ((res != null || res != undefined) && res == true) {

                }

              });
            }
            else {
              this.f.departureDateLocal.setValue('');
              this.f.departureTimeLocal.setValue('');
            }
            // add to cal localtime end
            if (this.f.departureDateUTC.value != null  && (this.arrivalDateUTC == "" || this.arrivalTimeUTC == "")) {

              this.calculateNextArrivalDateTime().subscribe(res => {
                if ((res != null || res != undefined) && res == true) {

                }

              });
            }
            else {
              if (this.arrivalDateUTC != "" && this.arrivalTimeUTC != "") {
                //this._dialogService.openAlertDialog("Changing departure date time may impact next arrival date time.");
              
                    if (this.f.ete.value != "") {
                      if (this.leg_detail_editor_form.controls['ete'].invalid == false) {
                        this.updateNextArrivalDateTime = true;
                        this.updateArrival();
                      }
                      else {
                        return;
                      }
                    }
                    else {
                      this.calculateNextArrivalDateTime().subscribe(res => {
                        if ((res != null || res != undefined) && res == true) {

                        }

                      });
                    }

                  
               
              }
            }
     

        }
      }
    }

  }

  getAirportLocalTime(utcdatetime: string, dateType: string): Observable<boolean> {
    //return null;
    return new Observable<boolean>(ob => {
      let request = new AirportModel();
      if (dateType == 'D')
        request.icao = this.departureICAO;
      else
        request.icao = this.arrivalICAO;
      request.airportUTCDateTime = utcdatetime;

      this._airportService.getAirportLocalTimeFromUTC<ResponseModel<AirportModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          let obj = new AirportModel();
          obj = response.model;
          let ldt = obj.airportLocalDateTime;
          if (ldt != "") {
            let i = ldt.indexOf(' ');
            let d = ldt.substring(0, i);
            let t = ldt.substring(i + 1);
            if (dateType == "A") {
              this.f.arrivalDateLocal.setValue(d);
              this.f.arrivalTimeLocal.setValue(t);
            }
            if (dateType == "D") {
              this.f.departureDateLocal.setValue(d);
              this.f.departureTimeLocal.setValue(t);
            }
          }
          else {
            if (dateType == "A") {
              this.f.arrivalDateLocal.setValue('');
              this.f.arrivalTimeLocal.setValue('');
            }
            if (dateType == "D") {
              this.f.departureDateLocal.setValue('');
              this.f.departureTimeLocal.setValue('');
            }
          }

        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }
        //this.loading = false;
      })
      ob.next(true);

    });

  }

  calculateNextArrivalDateTime(): Observable<boolean> {
   // return null;
    return new Observable<boolean>(ob => {
      if (this.departureLatitude != null && this.departureLongitude != null
        && this.arrivalLatitude != null && this.arrivalLongitude != null) {
        let request = new DistanceTimeModel();
        request.departureLatitude = this.departureLatitude;
        request.departureLongitude = this.departureLongitude;
        request.arrivalLatitude = this.arrivalLatitude;
        request.arrivalLongitude = this.arrivalLongitude;
        request.airSpeed = this.ac_speed;
        request.bias = 15;
        this._airportService.getDistanceTripTime<ResponseModel<DistanceTimeModel>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            let obj = new DistanceTimeModel();
            obj = response.model;
            let h = Number(obj.tripTimeHour);
            let m = Number(obj.tripTimeMinute);
            let dt = new Date(this.departureDateUTCRef.nativeElement.value + " " + this.f.departureTimeUTC.value);
            let adt = UtilityFunctions.addHours(dt, h);
            adt = UtilityFunctions.addMinutes(dt, m);
            this.arrivalDateUTC = CustomValidators.formatDateToMMDDYYYY(adt);
            let ah = adt.getHours();
            let am = adt.getMinutes();
            if (am < 10) {
              this.arrivalTimeUTC = ah.toString() + ":0" + am.toString();
            }
            else {
              this.arrivalTimeUTC = ah.toString() + ":" + am.toString();
            }
            this.ete = obj.tripTime;
            this.f.ete.setValue(this.ete);
            this.f.arrivalDateUTC.setValue(new Date(this.arrivalDateUTC));
            this.f.arrivalTimeUTC.setValue(this.arrivalTimeUTC);
            this.getAirportLocalTime(this.arrivalDateUTC + ' ' + this.arrivalTimeUTC, 'A').subscribe(res => {
              if (res) {
                ob.next(true);
                this.updateNextArrivalDateTime = true;
              }
            });
            

          }
          else {
            if (response.code == "401") {
              //this.errMsg = response.message
              this._authService.signOut();
            }

          }
          //this.loading = false;
        })
        
      }
      else
        ob.next(true);
    });

  }

  validateCompareArrival_DepartureDateTime() {
    let a = new Date(this.f.arrivalDateUTC.value);
    let d = new Date(this.f.departureDateUTC.value);
    if (a.getTime() < d.getTime()) {
      return false;
    }
    else {
      if (this.f.arrivalTimeUTC.value != "" && this.f.departureTimeUTC.value != "") {
        let a = new Date(this.arrivalDateUTCRef.nativeElement.value + " " + this.f.arrivalTimeUTC.value);
        let d = new Date(this.departureDateUTCRef.nativeElement.value + " " + this.f.departureTimeUTC.value);
        if (a.getTime() < d.getTime()) {
          return false;
        }
      }
    }
    return true;
  }

  eteTimeChange(e: any) {
    if (e.target.value == "") {
      this.f.ete.setErrors(null);
      this.f.ete.setValue('');
    }
    else {
      let timeObj = new DateTimeObjModel();
      timeObj.timeString = e.target.value;
      timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
      if (!timeObj.isValidDateTime) {
        this.f.ete.setErrors({ pattern: true });
      }
      else {
        this.f.ete.setValue(timeObj.timeString);
        e.target.value = timeObj.timeString;
        this.updateArrival();
        //this.updateNextArrivalDateTime = true;
      }
    }

  }

  arrivalDepartureStatusChange(flightType: string, e: any) {

    switch (flightType) {
      case "A":
        this.selectedArrivalStatusColor = this.arrival_departureStatusList.filter(v => v.arrival_DepartureStatusID == this.f.arrival_status_select.value)[0].colorCode;
        break;
      case "D":
        this.selectedDepartureStatusColor = this.arrival_departureStatusList.filter(v => v.arrival_DepartureStatusID == this.f.departure_status_select.value)[0].colorCode;
        break;
    }
  }

  clickSave(actionName: string) {

    this._authService.updateAccessTime();
    this.groundStopList=[];
    this.errMsg = "";
    this.errMsg2 = "";
    let gs: GroundStopModel;
    let i: number = 0;
    //departure
    gs = new GroundStopModel();
    gs.tripCodeGUID = this.tripCodeGUID;
    gs.groundStopGUID = this.groundStopGUID;
      gs.isValidArrDate = true;
      gs.isValidArrTime = true;
      gs.isValidDeptDate = true;
    gs.isValidDeptTime = true;
    gs.icao = this.departureICAO;
    gs.departureDateUTC = null;
    if (this.departureDateUTCRef.nativeElement.value!="")
    gs.departureDateUTC = this.departureDateUTCRef.nativeElement.value;// this.f.departureDateUTC.value;
    gs.departureTimeUTC = this.f.departureTimeUTC.value;
    gs.departureDateLocal = null;
    if (this.f.departureDateLocal.value!="")
      gs.departureDateLocal = new Date(this.f.departureDateLocal.value);
    gs.departureTimeLocal = this.f.departureTimeLocal.value;
    gs.ete = this.f.ete.value;
    gs.departureWeaponsOnBoard = this.f.dep_weapons.value;
    gs.departurePetsOnBoard = this.f.dep_pets.value;
    gs.isDeptFerryFlight = this.f.dep_ferry_flight.value;
    gs.departureOnHold = this.f.isOnHold.value;
    gs.isTechStop = this.f.tech_stop_select.value;
    gs.fARTypeID = this.f.dep_fartype_select.value;
    gs.callSign = this.f.dep_CallSign.value;
    gs.departureStatusID = this.f.departure_status_select.value;
    gs.groundStopSequenceID = this.currentGroundStopSequenceID;
    gs.nextGroundStopGUID = this.nextGroundStopGUID;
    gs.isValidArrDate = true;
    gs.isValidArrTime = true;
    gs.isValidDeptDate = true;
    gs.isValidDeptTime = true;
   // gs.icao = this.arrivalICAO;
    gs.arrivalDateUTC = null;
    if (this.arrivalDateUTCRef.nativeElement.value != "")
    gs.arrivalDateUTC = this.arrivalDateUTCRef.nativeElement.value;// this.f.arrivalDateUTC.value;
    gs.arrivalTimeUTC = this.f.arrivalTimeUTC.value;
    if (this.f.arrivalDateLocal.value!="")
      gs.arrivalDateLocal = new Date(this.f.arrivalDateLocal.value);
    gs.arrivalTimeLocal = this.f.arrivalTimeLocal.value;
    //gs.ete = this.f.ete.value;
    gs.arrivalWeaponsOnBoard = this.f.dep_weapons.value;
    gs.arrivalPetsOnBoard = this.f.dep_pets.value;
    gs.isArrFerryFlight = this.f.dep_ferry_flight.value;
    gs.arrivalOnHold = this.f.isOnHold.value;
    //if (this.f.tech_stop_select.value == 1)
    //  gs.isTechStop = true;
    //else
    //  gs.isTechStop = false;
    gs.fARTypeID = this.f.dep_fartype_select.value;
    gs.callSign = this.f.dep_CallSign.value;
    gs.arrivalStatusID = this.f.arrival_status_select.value;
   // gs.groundStopSequenceID = this.maxGroundStopSequenceID;
    this.groundStopList.push(gs);

    // add last active stop 
    
   // this.groundStopList.sort((a, b) => (a.groundStopSequenceID > b.groundStopSequenceID) ? 1 : -1);

    let hasAllValidFields = true;


    this.groundStopList.forEach((x, index) => {
      if (x.arrivalDateUTC != undefined) {
        if (x.arrivalDateUTC.toLocaleString() != "") {
          if (new Date(x.arrivalDateUTC).getUTCFullYear() < (new Date().getUTCFullYear() - 1)) {
            x.isValidArrDate == false;
            if (hasAllValidFields == true) {
              hasAllValidFields = x.isValidArrDate;
            }
          }
          else {
            x.isValidArrDate = CustomValidators.isValidDate(x.arrivalDateUTC.toLocaleString());
            if (hasAllValidFields == true && x.isValidArrDate == false) {
              hasAllValidFields = x.isValidArrDate;
            }
            if (x.isValidArrDate) {
              x.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.arrivalDateUTC));
              x.arrivalDateUTC = new Date(x.arrivalDateTextUTC);
            }
          }
        }
        else {
          x.arrivalDateTextUTC = null
        }
      }
      if (x.arrivalTimeUTC != "" && x.arrivalTimeUTC!=undefined) {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.arrivalTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.arrivalTimeUTC = timeObj.timeString;
        let arrtv = timeObj.isValidDateTime;
        x.isValidArrTime = arrtv;
        if (hasAllValidFields == true && arrtv == false) {
          hasAllValidFields = arrtv;
        }
      }
      // dept
      if (x.departureDateUTC != undefined) {
        if (x.departureDateUTC.toLocaleString() != "") {
          if (new Date(x.departureDateUTC).getUTCFullYear() < (new Date().getUTCFullYear() - 1)) {
            x.isValidDeptDate == false;
            if (hasAllValidFields == true) {
              hasAllValidFields = x.isValidDeptDate;
            }
          }
          else {
            x.isValidDeptDate = CustomValidators.isValidDate(x.departureDateUTC.toLocaleString());
            if (hasAllValidFields == true && x.isValidDeptDate == false) {
              hasAllValidFields = x.isValidDeptDate;
            }
            if (x.isValidDeptDate) {
              x.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.departureDateUTC));
              x.departureDateUTC = new Date(x.departureDateTextUTC);
            }
          }
        }
        else {
          x.departureDateUTC = null;
        }
      }
      if (x.departureTimeUTC != "" && x.departureTimeUTC!=undefined) {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.departureTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.departureTimeUTC = timeObj.timeString;
        let depttv = timeObj.isValidDateTime;
        x.isValidDeptTime = depttv;
        if (hasAllValidFields == true && depttv == false) {
          hasAllValidFields = depttv;
        }
      }

      if (index > 0) {
        let prev = this.groundStopList[index - 1];
        if (x.arrivalDateUTC != null && prev.departureDateUTC != null) {
          if (x.arrivalDateUTC.getTime() < prev.departureDateUTC.getTime()) {

            this.errMsg2 = "Arrival date can not be earlier than the departure date of the previous business stop.";
            return;
          }
          else {
            if (x.arrivalTimeUTC != "" && prev.departureTimeUTC != "") {
              let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);
              let td = new Date(prev.departureDateTextUTC + " " + prev.departureTimeUTC);
              if (ta.getTime() < td.getTime()) {

                this.errMsg2 = "Arrival date time can not be earlier than the departure date time of the previous business stop.";
                return;
              }
              else {
                let dif = (ta.getTime() - td.getTime()) / 1000 / 3600;
                if (dif >= 24) {
                  this.errMsg2 = "ETE can not be more than 24 hours.";
                  return;
                }

              }
            }
          }
        }

        if (x.arrivalDateUTC != null && x.departureDateUTC != null) {
          if (x.arrivalDateUTC.getTime() > x.departureDateUTC.getTime()) {
            this.errMsg2 = "Arrival date time can not be later than the next departure date time.";
            return;
          }
          else {
            if (x.arrivalTimeUTC != "" && x.departureTimeUTC != "") {
              let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);
              let td = new Date(x.departureDateTextUTC + " " + x.departureTimeUTC);
              if (ta.getTime() > td.getTime()) {

                this.errMsg2 = "Arrival date time can not be later than the next departure date time.";
                return;

              }
            }

          }

        }
      }

    });



    if (this.leg_detail_editor_form.invalid || this.errMsg2 != '' || !hasAllValidFields) {
      return;
    }

    this.loading = true;

    let obj = new TripModel();
    obj.customerGUID = this.customerGUID;
    obj.aircraftGUID = this.aircraftGUID;
    obj.tripCode = this.trip_code;
    obj.tripCodeGUID = this.tripCodeGUID;
    obj.groundStopList = this.groundStopList;

    this._tripLegService.updateGroundStopByTripLeg<ResponseModel<TripModel>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          if (response.model.message != "") {
            this.errMsg = response.model.message;
          }
          else {
            if (response.model.tripCodeGUID != "") {
              this.showSuccessMsg = true;
          this.isModified = true;
          this.returnObj.refresh = this.isModified;
          //this.returnObj.groundStopTypeDescription = request.groundStopTypeDescription;
          this.submittedDepDate = false;
          this.validatedAirport = false;
          this.controlValueChanged = false;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);


            }
            else {

              this.errMsg2 = "Failed to update the trip at this time. Please try later.";

            }
          }
        }
        else {
          this.errMsg2 = "Failed to update the trip at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg2 = "Failed to update the trip at this time. Please try later.";
        }
      }
      this.loading = false;
    })








    //////////
    //this._authService.updateAccessTime();
    //this.duplicateIATAErrMsg = "";
    //this.errMsg = "";
    //this.submittedArrDate = true;

    //if (this.isFirstGroundStop == false) {
    //  if (this.arrivalDateUTCRef.nativeElement.value != "" && this.arrivalDateUTCRef.nativeElement.value != null) {
    //    let vd = CustomValidators.isValidDate(this.arrivalDateUTCRef.nativeElement.value);
    //    if (!vd) {
    //      this.f.arrivalDateUTC.setErrors({ isValidDate: false });
    //      return;
    //    }
    //    else {
    //      let v = true;
    //      if (this.f.departureDateUTC.value != null) {
    //        v = this.validateCompareArrival_DepartureDateTime();
    //      }
    //      if (v == true) {
    //      }
    //      else {
    //        this.f.arrivalDateUTC.setErrors({ validateCompareArrival_DepartureDateTime: false });
    //        return;
    //      }
    //    }
    //  }
    //}

    //this.submittedDepDate = true;
    //if (this.nextGroundStopGUID != "") {
    //  if (this.departureDateUTCRef.nativeElement.value != "" && this.departureDateUTCRef.nativeElement.value != null) {
    //    let vd = CustomValidators.isValidDate(this.departureDateUTCRef.nativeElement.value);
    //    if (!vd) {
    //      this.f.departureDateUTC.setErrors({ isValidDate: false });
    //      return;
    //    }
    //    else {
    //      let v = true;
    //      if (this.f.arrivalDateUTC.value != null) {
    //        v = this.validateCompareArrival_DepartureDateTime();
    //      }
    //      if (v == true) {
    //      }
    //      else {
    //        this.f.departureDateUTC.setErrors({ validateCompareArrival_DepartureDateTime: false });
    //        return;
    //      }

    //    }
    //  }

    //}

    //this.validatedAirport = true;

    //if (this.leg_detail_editor_form.invalid || this.errMsg != "") {
    //  return;
    //}

    //this.loading = true;
    //let request = new GroundStopModel();
    //request.tripCodeGUID = this.tripCodeGUID;
    //request.groundStopSequenceID = this.currentGroundStopSequenceID;
    //request.groundStopGUID = this.groundStopGUID;
    //request.groundStopTypeGUID = this.f.ground_stop_type_select.value;
    //request.groundStopTypeDescription = this.groundStopTypeList.filter(v => v.groundStopTypeGUID == this.f.ground_stop_type_select.value)[0].groundStopTypeDescription;
    //request.oldGroundStopTypeGUID = this.originalGroundStopTypeGUID;
    //request.cancelAllServices = this.cancelAllServices;
    //request.icao = this.f.departureICAO.value;
    //if (this.f.tech_stop_select.value == "1") {
    //  request.isTechStop = true;
    //}
    //else {
    //  request.isTechStop = false;
    //}
    //request.arrivalDateUTC = this.f.arrivalDateUTC.value;
    //request.arrivalDateTextUTC = "";
    //if (this.isFirstGroundStop == false) {
    //  request.arrivalDateTextUTC = this.arrivalDateUTCRef.nativeElement.value;
    //}
    //request.arrivalTimeUTC = this.f.arrivalTimeUTC.value;
    //request.arrivalDateLocal = new Date(this.f.arrivalDateLocal.value);
    //request.arrivalDateTextLocal = this.f.arrivalDateLocal.value;//this.arrivalDateUTCRef.nativeElement.value;
    //request.arrivalTimeLocal = this.f.arrivalTimeLocal.value;
    //request.departureDateUTC = this.f.departureDateUTC.value;
    //request.departureDateTextUTC = "";
    //if (this.nextGroundStopGUID != "") {
    //  request.departureDateTextUTC = this.departureDateUTCRef.nativeElement.value;
    //}
    //request.departureTimeUTC = this.f.departureTimeUTC.value;
    //request.departureDateLocal = new Date(this.f.departureDateLocal.value);
    //request.departureDateTextLocal = this.f.departureDateLocal.value;//this.arrivalDateUTCRef.nativeElement.value;
    //request.departureTimeLocal = this.f.departureTimeLocal.value;
    //request.arrivalStatusID = Number(this.f.arrival_status_select.value);
    //request.departureStatusID = Number(this.f.departure_status_select.value);
    //request.departureCallSign = this.f.dep_CallSign.value;
    //request.departureFARTypeID = Number(this.f.dep_fartype_select.value);
    ////request.arrivalPetsOnBoard = this.f.arr_pets.value;
    ////request.arrivalWeaponsOnBoard = this.f.arr_weapons.value;
    //request.departurePetsOnBoard = this.f.dep_pets.value;
    //request.departureWeaponsOnBoard = this.f.dep_weapons.value;
    //request.ete = this.f.ete.value;
    //request.nextGroundStopGUID = "";
    ////request.nextArrivalDateUTC = "";
    ////request.nextArrivalTimeUTC = "";
    //request.nextArrivalDateUTC = this.arrivalDateUTC;
    //request.nextArrivalTimeUTC = this.arrivalTimeUTC;
    ////if (this.updateNextArrivalDateTime == true) {
    ////  request.nextGroundStopGUID = this.nextGroundStopGUID;
    ////  request.nextArrivalDateUTC = "";//this.arrivalDateUTC;
    ////  request.nextArrivalTimeUTC = "";//this.arrivalTimeUTC;
    ////}
    //request.speed = this.ac_speed;

    //this._groundStopService.saveGroundStopBygsId<ResponseModel<boolean>>(request).subscribe(response => {
    //  if (response != null && response.code == "200") {
    //    if (response.model) {
    //      this.showSuccessMsg = true;
    //      this.isModified = true;
    //      this.returnObj.refresh = this.isModified;
    //      this.returnObj.groundStopTypeDescription = request.groundStopTypeDescription;
    //      this.submittedDepDate = false;
    //      this.validatedAirport = false;
    //      this.controlValueChanged = false;
    //      setTimeout(() => {
    //        this.showSuccessMsg = false;
    //        this.clickClose();
    //      }, 1000);

    //    }
    //    else {
    //      this.errMsg = "Failed to update the business stop at this time. Please try later.";
    //    }
    //    //this.showSpin = false;
    //    this.loading = false;

    //  }
    //  else {
    //    if (response.code == "401") {
    //      //this.errMsg = response.message
    //      this._authService.signOut();
    //    }
    //    else {
    //      this.errMsg = "Failed to update the business stop at this time. Please try later.";
    //    }
    //    this.loading = false;
    //  }
    //  //this.showSpin = false;
    //})


  }

  clickClose() {
    //this._dialogRef.close(this.isModified);
    //if (this.controlValueChanged) {
    //  this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before close the page?");
    //  this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
    //    this.eventSubscription.unsubscribe();
    //    if (result) {
    //      this.clickSave('close');
    //    }
    //    else {
    //      this._dialogRef.close(this.returnObj);
    //    }
    //  });

    //}
    //else {
      this._dialogRef.close(this.returnObj);
    //}

  }

  updateArrival() {
    let ete:string[] = this.f.ete.value.split(':');
    let h = Number(ete[0]);
    let m = Number(ete[1]);
    let dt = new Date(this.departureDateUTCRef.nativeElement.value + " " + this.f.departureTimeUTC.value);
    let adt = UtilityFunctions.addHours(dt, h);
    adt = UtilityFunctions.addMinutes(dt, m);

    this.arrivalDateUTC_DTType = adt
    this.arrivalDateUTC = CustomValidators.formatDateToMMDDYYYY(this.arrivalDateUTC_DTType);
    let ah = adt.getHours();
    let am = adt.getMinutes();
    if (am < 10) {
      this.arrivalTimeUTC = ah.toString() + ":0" + am.toString();
    }
    else {
      this.arrivalTimeUTC = ah.toString() + ":" + am.toString();
    }
    this.f.arrivalDateUTC.setValue(new Date(this.arrivalDateUTC_DTType.toDateString()));
    this.f.arrivalTimeUTC.setValue(this.arrivalTimeUTC);
    let udt = this.arrivalDateUTC + " " + this.arrivalTimeUTC;
    this.getAirportLocalTime(udt, "A").subscribe(res1 => {
      if ((res1 != null || res1 != undefined) && res1 == true) {

      }

    });
  }

  cancelAirport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID, tripCodeGUID: this.tripCodeGUID
      
    };

    const dialogRef = this._dialog.open(CancelAirportDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

}
