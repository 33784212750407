<div style="margin-top: 0em; padding-top: 0em; width: 95%; margin-bottom: 2em; ">
  <form [formGroup]="taxesandfees_form" *ngIf="taxesandfees_form">
    <!--*ngIf="taxesandfees_form"-->
    <div class="divLeft">
      <div  *ngIf="fromMissingTaxesAirport || fromFuelTask"  style="display: flex; justify-content: space-between;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
        <div style="margin-right:1em; display: flex;"><span style="margin-right: 0.2em;  " class="material-symbols-outlined">order_approve</span><h5>Taxes And Fees</h5></div>
        <div><button mat-button class="btn-low" style="display: flex;" (click)="clickClose()">Close </button></div>
      </div>
      <div  *ngIf="!fromMissingTaxesAirport && !fromFuelTask"  style="display: flex; justify-content: space-between;">
        <div style="margin-right:1em; display: flex;"><span style="margin-right: 0.2em;  " class="material-symbols-outlined">order_approve</span><h5>Taxes And Fees</h5></div>
      </div>
      <div  *ngIf="!fromMissingTaxesAirport && !fromFuelTask" style="background-color: #f8f8f8; padding-left: 0.25em; padding-top: 0.25em; padding-right: 0.5em; margin-top: -0.5em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; width: 100%; height: 5.5em">
        <div style="margin-left:0em;padding:0em; display: flex; ">
          <!-- <div>
            <div style="border: 0.0125em solid #337dff; margin-right:0.5em; padding-left:0.25em; border-radius: 0.25em">
              <div style="display: flex;margin-top:0.25em">
                <div style="font-size: small; font-weight: bold">Enter a Date Range</div>
                <div style="font-size:x-small; margin-top:0.35em">&nbsp;(MM/DD/YYYY)</div>
              </div>
              <div style="display: flex; width: 12.25em; margin-top:-1.25em">
                <mat-date-range-input [rangePicker]="picker" style=" display: flex; margin-top:0.5em;width:100% " class="mat-date-range-input">
                  <input matStartDate formControlName="startDate" placeholder="Start date" maxlength="10" (dateChange)="dateOnchange('S', $event)" style="width:100%; font-size:small;margin-top:0.4em; margin-right:0em;background-color: white; border-radius:0.25em;"> 
                  <input matEndDate formControlName="endDate" placeholder="End date" maxlength="10" (dateChange)="dateOnchange('E', $event)" style="width: 100%; font-size: small; margin-top: 0.4em; margin-left: 0em; background-color: white; border-radius: 0.25em;">  
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker" style="margin-top:0.7em; margin-left:-0.7em">
                </mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </div>
            </div>
          </div> -->


          <div *ngIf="!fromMissingTaxesAirport && !fromFuelTask"> <!--style="min-width:32em">-->
            <div style="display: flex;">
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedCountryCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickCountryFilter()">Country{{selectedCountryCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:8em;" [ngClass]="selectedAirportCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickAirportFilter()">Airport{{selectedAirportCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:9em;" [ngClass]="selectedFuelSupplierCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickFuelSupplierFilter()">Fuel Supplier{{selectedFuelSupplierCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:12em;" [ngClass]="selectedIntoPlaneAgentCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickIntoPlaneAgentFilter()">Into Plane Agent{{selectedIntoPlaneAgentCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:15em;" [ngClass]="selectedTaxFeeDescCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickTaxFeeDescFilter()">Taxes and Fee Description{{selectedTaxFeeDescCountText}}</button>
              </div>
              <div style="margin-top:0.5em; margin-right:0.25em">
                <button type="button" mat-button class="btn-low" style="width:11em;" [ngClass]="selectedUnitOfMeasureCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickUnitOfMeasureFilter()">Unit of Measure{{selectedUnitOfMeasureCountText}}</button>
              </div>
            </div>
            <div style="display:flex; min-width: 20em">
              <div *ngIf="f.startDate.errors" class="small alert alert-danger p-0" style="font-size:small; width:max-content;margin-top:0.125em;height:1.5em;">
                <div *ngIf="f.startDate.errors.isValidDate==false">Invalid start date.</div>
              </div>
              <div *ngIf="f.endDate.errors" class="small alert alert-danger p-0" style="font-size: small; width: max-content; margin-top: 0.125em; height: 1.5em">
                <div *ngIf="f.endDate.errors.isValidDate==false">Invalid end date.</div>
                <div *ngIf="f.endDate.errors.matEndDateInvalid">End date cannot be before start date.</div>
              </div>
              <!--country list--><!--margin-left: 32.25em;-->
              <div *ngIf="showCountryList" style="float: left; min-width: 11em; margin-top: 0.25em; background-color: white; border-top: 0.0625em lightgrey solid;
              border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;
              border-radius: 0.25em; z-index: 1000; position: absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline">Countries</div>
                  <div style="float:right" (click)="showCountryList=!showCountryList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="country_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />  <!--(keyup)="countrySearchChange($event)"-->
                    </div>
                  </div>
                </div>
                <div *ngIf="countryList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let v of filteredCountryList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                    <div  [attr.countryGUID]="v.countryGUID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkCountryChange($event, v)" [checked]="v.selected">{{v.countryName}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetCountry()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneCountry()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--country list end-->
              <!--Airport list-->
              <div *ngIf="showAirportList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 7.3em; background-color: white;
               border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid;
               font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Airport ICAO</div>
                  <div style="float:right" (click)="showAirportList=!showAirportList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="airportSearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameAirport($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="airportList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let a of filteredAirportList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div #tripAirportRefs [attr.icao]="a.icao" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkAirportChange($event, a)" [checked]="a.selected">{{a.icao}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetAirport()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneAirport()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--Airport list end-->
              <!--FuelSupplier list-->
              <div *ngIf="showFuelSupplierList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 15.6em; background-color: white; border-top: 0.0625em lightgrey solid;
              border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;
                border-radius: 0.25em; z-index: 1000; position: absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Fuel Supplier Code</div>
                  <div style="float:right" (click)="showFuelSupplierList=!showFuelSupplierList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="fuelsupplier_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameFuelSupplier($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="fuelsupplierList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let a of filteredFuelSupplierList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div [attr.fuelSupplierShortname]="a.fuelSupplierShortname" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkFuelSupplierChange($event, a)" [checked]="a.selected">{{a.fuelSupplierShortname == null ? 'All' : a.fuelSupplierShortname}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetFuelSupplier()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneFuelSupplier()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--FuelSupplier list end-->
              <!--Into Plane Agent list-->
              <div *ngIf="showIntoPlaneAgentList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 25em; background-color: white;
                border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;
                border-radius: 0.25em; z-index: 1000;position:absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Into Plane Agent</div>
                  <div style="float:right" (click)="showIntoPlaneAgentList=!showIntoPlaneAgentList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="intoplaneagent_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameIntoPlaneAgent($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="intoplaneagentList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let a of filteredIntoPlaneAgentList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div [attr.intoPlaneAgent]="a.intoPlaneAgent" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkIntoPlaneAgentChange($event, a)" [checked]="a.selected">{{a.intoPlaneAgent}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetIntoPlaneAgent()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneIntoPlaneAgent()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--Into Plane Agent list end-->
              <!--Tax Description list-->
              <div *ngIf="showTaxFeeDescList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 37.2em; background-color: white; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Tax Description</div>
                  <div style="float:right" (click)="showTaxFeeDescList=!showTaxFeeDescList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="taxFeeDesc_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameTaxFeeDesc($event)" />
                    </div>
                  </div>
                </div>
                <div *ngIf="taxFeeDescList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let a of filteredtaxFeedescList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div [attr.lk_TaxFeeDescriptionID]="a.lk_TaxFeeDescriptionID" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkTaxFeeDescChange($event, a)" [checked]="a.selected">{{a.taxFeeDescription}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetTaxFeeDesc()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneTaxFeeDesc()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--Tax Description  list end-->
              <!--UNit of Measure list-->
              <div *ngIf="showunitofMeasureList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 51.5em; background-color: white; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                <div style="display:flex; justify-content:space-between">
                  <div style="font-weight: bold; text-decoration:underline;">Unit Of Measure</div>
                  <div style="float:right" (click)="showunitofMeasureList=!showunitofMeasureList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                </div>
                <!--<div style="font-size: small; margin-left:0em">
                  <div style="margin-right:0.5em;">
                    <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                      <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                      <input type="text" formControlName="unitOfMeasure_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameUnitOfMeasure($event)" />
                    </div>
                  </div>
                </div>-->
                <div *ngIf="unitofMeasureList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                  <div *ngFor="let a of filteredUnitOfMeasureList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                    <div [attr.intoPlaneAgent]="a.unitOfMeasure" style="display: flex; font-size: small; margin-top:-0.5em">
                      <div>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkUnitOfMeasureChange($event, a)" [checked]="a.selected">{{a.unitOfMeasure}}</mat-checkbox>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                <div style="display: flex; float: right">
                  <div style="margin-right:0.5em">
                    <button type="button" mat-button class="btn-low" (click)="clickResetUnitOfMeasure()" style="height:2em">RESET</button>
                  </div>
                  <div>
                    <button type="button" mat-button class="btn-high" (click)="clickDoneUnitOfMeasure()" style="height:2em">DONE</button>
                  </div>
                </div>
              </div>
              <!--UNit of Measure list end-->
            </div>

          </div>

          <div style="display:flex">
            <!-- <div style="margin-top: 0.5em; margin-left: 1em; margin-right: 0.5em; width: 14em; display: grid;">
              <div> <input type="checkbox" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)" /><label for="includeDisabledRecords" style="font-size:small">&nbsp;Include disabled records</label>
              </div> 
              <div>  <input type="checkbox" formControlName="showExpiredOnly" (change)="showExpiredOnlyOnChange($event)" /><label for="showExpiredOnly" style="font-size:small">&nbsp;Show Expired Only</label>
              </div>  
              <div style="display:flex">
                 <div *ngIf="errMsg!=''" class="d-block" style="margin-left:0.5em; display:flex;margin-top:-0.5em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
              </div>
            </div> -->





            <div  style="margin-top:.5em;  margin-right: 5em;   font-size:small">
         
              <div style="display: flex; margin-left: 1em; ">
                <div style="margin-right:0.25em">
                  <input id="factive" type="radio" name="getData_type" value="active"   [checked]="getDataConditions=='active'"
                    (change)="getDataConditionsOnChange($event)" />
                </div>
                <label style="margin-top:-0.2em;white-space: nowrap " for="factive">Show Active Taxes & Fees Only  </label>
              </div>
    
              <div style="display: flex; margin-left: 1em; ">
                <div style="margin-right:0.25em">
                  <input id="fdisabled" type="radio" name="getData_type" value="disabled"  
                    (change)="getDataConditionsOnChange($event)" />
                </div>
                <label style="margin-top:-0.2em;white-space: nowrap " for="fdisabled">Show Disabled Records</label>
              </div>
              
              <div style="display: flex; margin-left: 1em; ">
                <div style="margin-right:0.25em">
                  <input id="fexpired" type="radio" name="getData_type" value="expired"
                    (change)="getDataConditionsOnChange($event)" />
                </div>
                <label style="margin-top:-0.2em;white-space: nowrap " for="fexpired">Show Expired Records </label>
              </div>
            </div>





            <div style="margin-top:0.125em; margin-right:0.5em;width:6em">
              <div>
                <button type="button" mat-button class="btn-low" (click)="clickReset()" style="height:1.5em;width:7em">
                  <div style="display: flex;margin-top:-0.125em">
                    <div style="margin-top: 0.125em; margin-left:-0.25em">
                      <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">restart_alt</i>
                    </div>
                    <div>
                      RESET
                    </div>
                  </div>
                </button>
                <button type="button" mat-button class="btn-low" (click)="clickSubmit()" style="height:1.5em;width:7em">
                  <div style="display: flex;margin-top:-0.125em">
                    <div style="margin-top: 0.25em; margin-left:-0.25em">
                      <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>
                    </div>
                    <div>
                      REFRESH
                    </div>
                  </div>
                </button>
              </div>
              <div style="display:flex">
                <!--<div style="font-size:small; margin-top: -.25em; margin-left: 0.3125em;">{{totalRecordsText}}</div>-->
                <div *ngIf="errMsg!=''" class="d-block" style="margin-left:0.5em; display:flex;margin-top:-0.5em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
              </div>
            </div>
            <div style="margin-top:0.125em; margin-right:0.5em;">
              <div style="display: inline-grid;">
                <button mat-button class="btn-low" style="margin-top: 0.2em;display: flex;" (click)="clickRoute('fuelmenu/fuel-menu')" ><i class="material-icons" style="font-size: medium;cursor: pointer; ">arrow_back</i>Return to Fuel Dashboard</button>
                <button mat-button class="btn-low" style="margin-top: 0.2em;" (click)="exportData()">Export To Excel</button>

                <div>
                  <div style="width:6em"><app-spin *ngIf="showSpin"></app-spin></div>
                  <div><label *ngIf="sortedData.length==0" style="font-size:small">Select filters to view records.</label></div>
                </div>
                <!--<button type="button" mat-button class="btn-low" style="height:1.5em;">-->
                <!--(click)="clickSubmit()"-->
                <!--<div style="display: flex;margin-top:-0.125em">
      <div style="margin-top: 0.25em; margin-left:-0.25em">
        <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">download</i>
      </div>
      <div>
        Export to Excel
      </div>
    </div>
  </button>-->
              </div>
            </div>
          </div>




        </div>

      </div>

      <div>
        <!--class="mt-2">-->
        <div class="d-flex justify-content-between">
          <!--</div> style="margin-top: -1.05em;">-->

          <div class="d-inline-flex">
            <!--<button class="btn-high" (click)="exportData()" style=" height: 2em; border-radius: 5px; margin-top: 1.5em; ">Export to Excel</button>-->
            <div *ngIf="selection.hasValue()" style=" display: flex;padding-top: 1em;  ">
              <!-- <button mat-icon-button *ngIf="selection.hasValue()" [matMenuTriggerFor]="menu" style="margin-top:-1em"><mat-icon style="font-size:medium;margin-top:-.5em;line-height:1.5">more_horiz</mat-icon></button> -->
              <button mat-button class="btn" (click)="deleteFP(true)">Delete</button>
              <button mat-button class="btn" (click)="clearStatusFP(false)">Clear Status</button>
              <button mat-button class="btn" (click)="printFP(false)">Print</button>
            </div>
          </div>
          <mat-paginator *ngIf="sortedData.length>0" style="background-color:transparent;" [pageSize]="25"
                         [showFirstLastButtons]="true"
                         [length]="totalRecords"
                         [pageSizeOptions]="[25, 50, 100]"
                         aria-label="Select page" (page)="pageEvent = pageIndexChange($event)">
          </mat-paginator>
          <!--[pageSizeOptions]="[25, 50, 100, totalRecords]"-->
        </div>
        <table [ngStyle]="{'max-height':fromFuelTask?'25em':''}" mat-table [dataSource]="sortedData" matSort matSortActive="FuelPricingID" matSortDirection="asc" (matSortChange)="sortData($event)" class="example-container">

          <tr>
            <!--<ng-container matColumnDef="action1">
    <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 3%;width:3%;">
      <mat-checkbox style="height:1.5em;width:1.5em;" (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>

    </th>
    <td mat-cell *matCellDef="let row" style="text-align:center !important;flex:0 0 3%;width:3%">
      <mat-checkbox style="height:1.5em;width:1.5em" (click)="$event.stopPropagation()"
                    (change)="$event ? checkBox(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>-->
            <ng-container matColumnDef="Country">
              <th mat-header-cell *matHeaderCellDef   class="thStyle"  style="width: 7%;">
                Country
              </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="element.isActive == '1' ? 'Active' : 'Disabled'" [ngClass]="{ 'alert-danger':!element.isActive }">{{element.countryName}}</td>
            </ng-container>
            <ng-container matColumnDef="ICAO">
              <th mat-header-cell *matHeaderCellDef   class="thStyle" style="width: 4%;">
                ICAO
              </th>
              <td mat-cell *matCellDef="let element">{{element.icao==null?'All':element.icao}}</td>
              <!--<td mat-cell *matCellDef="let element" [ngClass]="{ 'alert-danger': element.approvalStatus=='Pending Approval' }">{{element.approvalStatus}}</td>-->
            </ng-container>
            <ng-container matColumnDef="IATA">
              <th mat-header-cell *matHeaderCellDef   class="thStyle" style="width: 4%;">
                IATA
              </th>
              <td mat-cell *matCellDef="let element">{{element.iata==null?'All':element.iata}}</td> 
            </ng-container>


            <ng-container matColumnDef="FuelSuppliershortName">
              <th mat-header-cell *matHeaderCellDef   class="thStyle">
                Fuel Supplier
              </th>
              <td mat-cell *matCellDef="let element">{{element.fuelSupplierShortName==null?'All':element.fuelSupplierShortName}}</td>
            </ng-container>
            <ng-container matColumnDef="IntoPlaneAgent">
              <th mat-header-cell *matHeaderCellDef  class="thStyle">
                Into Plane Agent
              </th>
              <td mat-cell *matCellDef="let element">{{element.intoPlaneAgent}}</td>
            </ng-container>
            <!--<ng-container matColumnDef="Currency">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="Currency">
      Currency
    </th>
    <td mat-cell *matCellDef="let element">{{ element.currencyDescription}}</td>
  </ng-container>-->
            um
            <ng-container matColumnDef="effectivedate">
              <th mat-header-cell *matHeaderCellDef class="thStyle">
                Effective Date
              </th>
              <td mat-cell *matCellDef="let element"> {{element.effectiveDate}} </td>
            </ng-container>

            <ng-container matColumnDef="expirydate">
              <th mat-header-cell *matHeaderCellDef class="thStyle">
                Expiry Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.expiryDate}}</td>
            </ng-container>

            <ng-container matColumnDef="Scale">
              <th mat-header-cell *matHeaderCellDef  class="thStyle">
                Scale
              </th>
              <td mat-cell *matCellDef="let element">{{element.minScale != 0  ? element.minScale : ''}}{{element.maxScale != 0  ? ' - ' + element.maxScale : ''}}</td>
            </ng-container>
            <!--<ng-container matColumnDef="MaxScale">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="MaxScale" class="thStyle">
      Max Scale
    </th>
    <td mat-cell *matCellDef="let element">{{element.maxScale}}</td>
  </ng-container>-->
            <ng-container matColumnDef="UnitofMeasure">
              <th mat-header-cell *matHeaderCellDef   class="thStyle">
                Unit of Measure
              </th>
              <td mat-cell *matCellDef="let element">{{element.unitofMeasure}}</td>
            </ng-container>
            <ng-container matColumnDef="currency">
              <th mat-header-cell *matHeaderCellDef class="thStyle" style="width: 3%;">
                Currency
              </th>
              <td mat-cell *matCellDef="let element">{{element.currency}}</td>
            </ng-container>
            <ng-container matColumnDef="taxfeedescription">
              <th mat-header-cell *matHeaderCellDef class=" " style="width: 10%;">
                Tax Fee Description
              </th>
              <td mat-cell *matCellDef="let element">{{element.taxFeeDescription}}</td>
            </ng-container>
            <ng-container matColumnDef="Tax">
              <th mat-header-cell *matHeaderCellDef class=" " style="width: 5%;">
                Taxes
              </th>
              <!--  style="text-align:center" -->
              <!--<td mat-cell *matCellDef="let element">{{element.amount}}</td>-->
              <td mat-cell *matCellDef="let element"> {{element.taxStandard == '1' ?  element.perUnitPrice  :element.taxStandard == '2' ?  element.flatFeeAmount  :  element.flatRatePercent+'%' }} </td><!--{{element.flatFeeAmount | number : '1.2-2' }} --->
              <!--<td mat-cell *matCellDef="let element"> {{element.flatFeeAmt == '1' ? 'Flat Rate : '+element.flatFeeAmount  : 'Flat Percent : '+element.flatRatePercent }} </td>--><!--{{element.flatFeeAmount | number : '1.2-2' }} --->
            </ng-container>
            <ng-container matColumnDef="USD">
               
              <th mat-header-cell *matHeaderCellDef class="thStyle">
                (USD/USG)
              </th>
              <td mat-cell *matCellDef="let element">{{element.usd==0?'':element.usd}}</td>
            </ng-container>
            <ng-container matColumnDef="Unit">
              <th mat-header-cell *matHeaderCellDef class=" "  style="width: 7%;">
                Unit
              </th>
              <td mat-cell *matCellDef="let element">{{element.taxStandard == '1' ? 'Per Unit'   :element.taxStandard == '2' ? 'Flat Rate'  : 'Flat Percent' }}</td>
            </ng-container>
            <ng-container matColumnDef="IsTotalCalculated">
              <th mat-header-cell *matHeaderCellDef class=" "  style="width: 4%;">
                Calculate On Total    
              </th>
              <td mat-cell *matCellDef="let element">  {{element.calculateOnTotal==true?'Yes':'No'}} </td>
            </ng-container>

            <ng-container matColumnDef="IsDomestic">
              <th mat-header-cell *matHeaderCellDef class=" "  style="width: 4%;">
                Is Domestic    
              </th>
              <td mat-cell *matCellDef="let element">  {{element.isDomestic==true?'Yes':'No'}} </td>
            </ng-container>
            <ng-container matColumnDef="exemptfartype">
              <th mat-header-cell *matHeaderCellDef style="width: 4%;">
                Exempt FAR Type
              </th>
              <td mat-cell *matCellDef="let element">{{element.exemptFARType}}</td>
            </ng-container>
            <ng-container matColumnDef="InternalNotes">
              <th mat-header-cell *matHeaderCellDef   class="thStyle">
                Supplier Notes
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.internalNotes}}
              </td>
            </ng-container>
            <!--<ng-container matColumnDef="ClientNotes">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="ClientNotes" class="thStyle">
      Client Notes
    </th>
    <td mat-cell *matCellDef="let element">{{element.clientNotes}}</td>
  </ng-container>-->
            <!-- <ng-container matColumnDef="ModifiedDate">
              <th mat-header-cell *matHeaderCellDef  class="thStyle">
                Modified Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.modifiedDate}}</td>
            </ng-container>
            <ng-container matColumnDef="modifiedBy">
              <th mat-header-cell *matHeaderCellDef  class="thStyle">
                Modified By
              </th>
              <td mat-cell *matCellDef="let element">{{element.modifiedBy}}</td>
            </ng-container> -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="thStyle">
                <div style="margin-top:-0.25em;">Action</div>
                <div><a mat-button class="btn-high grid" style="align-self:center" (click)="clickEdit( element);">Add</a></div>
              </th>
              <td mat-cell class="mat-cell2" *matCellDef="let element; let i=index ">
                <a mat-button class="btn-low grid">
                  <span>Edit</span>
                </a>
              </td>
            </ng-container>
          </tr>
          <!--<tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>-->
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row (click)="clickEdit( row)" *matRowDef="let row; columns: displayedColumn"></tr>
        </table>

        <div style="display: inline-flex; max-width: 25em !important; margin-top: 1em; margin-bottom: 0em">
          <!--*ngIf="!showNewFolderInput"-->
          <!--<button mat-button class="btn-high " style="  text-align: left; " (click)="clickEdit($event, null)">
      <i class="material-icons" style="font-size:  large; margin-top: -.2em;">add </i>
      Add
    </button>-->
          <!--<button type="button" mat-button class="btn-low" (click)="importFuelPricing()" style="margin-left: 1em;">
      <div style="display:flex">
        <i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i>
        <div>Import</div>
      </div>
    </button>-->
          <!--<button type="button" mat-button class="btn-low" (click)="clickClose()" style="margin-left: 1em;">
      <div style="display:flex">
        <i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
        <div>CLOSE</div>
      </div>
    </button>&nbsp;-->
        </div>
      </div>
    </div>
  </form>
</div>
