<div style="margin-top:0em; padding-top:0em; display:flex">
<form [formGroup]="vendor_note_form">
  <div style="margin-top:-1em">
    <div>
      <table mat-table [dataSource]="recordList" class="example-container">
        <tr>
          <ng-container matColumnDef="group_name">
            <th mat-header-cell *matHeaderCellDef width="15%">
              Group
            </th>
            <td mat-cell *matCellDef="let element">{{element.groupName}}</td>
          </ng-container>
          <ng-container matColumnDef="note_type">
            <th mat-header-cell *matHeaderCellDef width="15%">
             Note Type
            </th>
            <td mat-cell *matCellDef="let element">{{element.noteTypeDesc}}</td>
          </ng-container>          
          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef width="60%">
              Note
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:left !important;white-space:pre-wrap"><span *ngIf="element.vendorNote.length>550" style="cursor:pointer" matTooltipClass="tooltipLineBreak" matTooltip="{{element.vendorNote}}">{{element.vendorNote.substring(0,549)+'...'}}</span><span *ngIf="element.vendorNote.length<=550">{{element.vendorNote}}</span></td>
          </ng-container>
          <ng-container matColumnDef="disabled">
            <th mat-header-cell *matHeaderCellDef width="5%">
              Disabled
            </th>
            <td mat-cell *matCellDef="let element">{{element.isActive? '':'Yes'}}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef width="5%">
              Action <br /><a mat-button class="btn-high grid" (click)="clickEdit($event,null)">ADD</a>
            </th>
            <td mat-cell *matCellDef="let element; let i=index ">
              <a mat-button class="btn-low grid" (click)="clickEdit($event, element);">
                EDIT
              </a>
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
    </div>
    
  </div>
</form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
