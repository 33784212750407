<!-- <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0"> -->
<div style="background-color:#f8f8f8;">
  <div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5 style="display: flex;"><span style="color:#146ec3;">{{countryName}}&nbsp;</span> - {{segmentDetails.countryContentSegment}}</h5></div>
    
    <div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
      <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
    </div>
  </div>
  <div>
    <form *ngIf="country_segment_edit_dialog_form" [formGroup]="country_segment_edit_dialog_form" >
      <div style="display:flex; margin-top:0em; padding-left: 1em;padding-right:1em">
        <div style="background-color: white; width: 40em;max-height: 44em;">
          <div style="display:flex; margin-left:0.5em;padding-top:0.5em">
            <div style="margin-right:0em;    max-height: 38em; overflow-y: auto; overflow-x: hidden;">
              

 

              <div style="display:flex; margin:1em 0;">
                <div style="margin-left: 0em;margin-right:0.625em;"> 
                  <div style="display: flex;">

                    
                    <div  *ngIf="checkExistence(false,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID,  segmentDetails.countryContentSegment)"  style="display:flex; margin: 1em 0;">
                      <div style="  display: flex;">
                        <label style="font-size:small; margin-right: 0.4em;">Required</label>
                        <div style="margin-top: -0.35em;">
                          <select formControlName="isRequired" class="form-control form-control-sm dropdown" style="  height: 1.9em;  width: 7.5em;" >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="Restricted">Restricted</option>
                            <option value="See Notes">See Notes</option>


                          </select>
                        </div>
                      </div> 
      
                    </div>

                    <div  *ngIf="checkExistence(false,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, prefileFilingTimeVar)"  style="width: 0.5em; padding: 0 1em; margin-top: -0.5em; margin-bottom: 0.5em;">
                      <div style="width: 0.05em; height: 60%;margin-top: 1em;float: right; background-color: #b0b0b0; "></div>
                    </div>

                    <!-- <div style="display:flex;" >
                      <div style="  width: 31%;">
                        <div   *ngIf="checkExistence(false,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, prefileFilingTimeVar)"  style="display:flex; width: 20em; padding: 5px;">
                          <label for="prefileFilingTime" style="font-size:small; width: 9em;">Prefile Filing Time</label>
                          <div  style="width: 5.5em;" >
                            <input type="text"    formControlName="prefileFilingTime" [disabled]="isDisabledControl" class="form-control" style="height: 1.5em; background-color:#eaf0fd;"  />
                          </div>
                        </div>
                        <div   *ngIf="checkExistence(false,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, validTimeVar)"  style="display:flex; width: 20em; padding: 5px">
                          <label for="validTime" style="font-size:small; width: 9em;">Validity Time</label>
                          <div   style="width: 5.5em;">
                            <input type="text" formControlName="validTime" class="form-control" style="height: 1.5em; background-color:#eaf0fd;"     />
                          </div>
                        </div>
                      </div>
                      <div  *ngIf="checkExistence(false,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, prefileFilingTimeVar)"  style="width: 0.5em; padding: 0 1em; margin-top: -0.5em; margin-bottom: 0.5em;">
                        <div style="width: 0.05em; height: 60%;margin-top: 1em;float: right; background-color: #b0b0b0; "></div>
                      </div>
                      <div style="   width: 35%;">
                        <div   *ngIf="checkExistence(false,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, leadTimeVar)"  style="display:flex; width: 20em; padding: 5px;">
                          <label for="leadTime" style="font-size:small; width: 6em;">Lead Time</label>
                          <div   style="width: 5.5em;">
                            <input type="text" formControlName="leadTime" class="form-control" style="height: 1.5em; background-color:#eaf0fd;"     />
                          </div>
                        </div>
                        <div  *ngIf="checkExistence(false,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, dueDateVar)"   style="display:flex; width: 20em; padding: 5px;">
                          <label for="dueDate" style="font-size:small; width: 6em;">Due Date</label>
                          <div   style="width: 5.5em;">
                            <input type="text" formControlName="dueDate" class="form-control" style="height: 1.5em; background-color:#eaf0fd;"     />
                          </div>
                        </div> 
                      </div>  
                    </div> -->
                  </div>


                  <div style="display:flex;margin-bottom: -1.5em;" >
                    <div style="  width: 31%;">
                      <div   *ngIf="checkExistence(false,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, prefileFilingTimeVar)"  style="display:flex; width: 35em; padding: 5px;">
                        <label for="prefileFilingTime" style="font-size:small; width: 10em;">Prefile Filing Time</label>
                        <div  style="width: 22em;" >
                          <input type="text"    formControlName="prefileFilingTime" [disabled]="isDisabledControl" class="form-control" style="height: 1.5em; background-color:#eaf0fd;"  />
                        </div>
                      </div>
                      <div   *ngIf="checkExistence(false,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, validTimeVar)"  style="display:flex; width: 35em; padding: 5px">
                        <label for="validTime" style="font-size:small; width: 10em;">Validity Time</label>
                        <div   style="width: 22em;">
                          <input type="text" formControlName="validTime" class="form-control" style="height: 1.5em; background-color:#eaf0fd;"     />
                        </div>
                      </div>
 
                      <div   *ngIf="checkExistence(false,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, leadTimeVar)"  style="display:flex; width: 35em; padding: 5px;">
                        <label for="leadTime" style="font-size:small; width: 10em;">Lead Time</label>
                        <div   style="width: 22em;">
                          <input type="text" formControlName="leadTime" class="form-control" style="height: 1.5em; background-color:#eaf0fd;"     />
                        </div>
                      </div>
                      <div  *ngIf="checkExistence(false,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, dueDateVar)"   style="display:flex; width: 35em; padding: 5px;">
                        <label for="dueDate" style="font-size:small; width: 10em;">Due Date</label>
                        <div   style="width: 22em;">
                          <input type="text" formControlName="dueDate" class="form-control" style="height: 1.5em; background-color:#eaf0fd;"     />
                        </div>
                      </div> 
                    </div>  
                  </div>

                  <div  *ngIf="checkExistence(true,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, notesVar)"  class="form-group" style="margin-top:3em; margin-right:5.625em; display: flex;">
                    <label for="segmentNotes" style="font-size:small; width: 10em;">  Notes:  </label>
                    <!-- <label for="segmentNotes" style="font-size:small; width: 10em; display: flex;">{{segmentDetails.countryContentSegment}} &nbsp;<span *ngIf="segmentDetails.countryContentSegment != countryNotesSec" > Notes: </span></label> -->

                    <div style="margin-top:-0.5em;">
                      <textarea spellcheck="true" formControlName="segmentNotes" class="form-control"
                        style="width:36.5em; height:11em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">
                              </textarea>
                    </div>
                  </div>

                  <div  *ngIf="checkExistence(true,segmentDetails.countryContentSegment, segmentDetails.countryContentSegmentID, prefileRequirementsVar)"  class="form-group" style="margin-top:0.7em; margin-right:5.625em; display: flex;">
                    <label for="prefileRequirements" style="font-size:small; width: 10em;">Prefile Requirements </label>
                    <div style="margin-top:-0.5em;">
                      <textarea spellcheck="true" formControlName="prefileRequirements" class="form-control"
                        style="width:36.5em; height:11em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">
                              </textarea>
                    </div>
                  </div>


                  <div  *ngIf="segmentDetails.countryContentSegment?.toLowerCase()  != countryNotesSec   "  style="margin-left: 1em;width: 36em; display: flex; margin-top: 1.5em;">
                    <div style="width: 45%;"  >Required Permit Documents:</div>
                    <div  style="display: flex; height: 2em;width: 45%;">
                      <input  *ngIf="filteredDocArray.length ===0"   type="checkbox" formControlName="isDocsRequired"  (change)="isDocsRequiredOnChange( )" />
                      
                      <input  *ngIf="filteredDocArray.length>0"  disabled type="checkbox" formControlName="isDocsDisabled"   />
                      <label for="isDocsRequired" style="font-size:small; margin-top: 0.5em;">&nbsp;No Documents Required</label>  </div>
                      


                      <button *ngIf="!f.isDocsRequired.value" style="height: 2em;width: 10%;" mat-button class="btn-high" (click)="addDocumentTypeRows(false)">+Add</button>
                  </div> 
                  <div *ngIf="f.isDocsRequired.value" style="margin-left: 1em;">No Documents Required</div>
                </div>
              </div>
 
              <div>
                <div *ngIf="documentsListArray.length>0"  style="margin-bottom: 1em; padding-bottom: 1em;">
                  <table mat-table *ngIf="documentsListArray"  [dataSource]="documentsListArray"  
                    class="example-container mat-elevation-z8" style="width: 36em; margin: 1em;">
                    <tr> 
                      <ng-container matColumnDef="documentType">
                        <th mat-header-cell *matHeaderCellDef style="width:34%; text-align:left !important">
                          <div style="margin-top:-0.25em;">Document Type<i  *ngIf="checkDocumentsExistence()" class="material-icons" title="Download All Documents" style=" font-size: medium; cursor: pointer;padding-left: 5px;" (click)="downloadAllAttachments( )">cloud_download</i></div>
                          
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 34%;">
                          <div style="display: flex;">
                            <div *ngIf="!element.containerName"   matTooltipClass="tooltipLineBreak" matTooltip="{{element.internalNotes}}" >{{element.documentType}}</div> 
                            <a *ngIf="element.containerName" href="javascript:void(0)" (click)="clickPreviewDocument(element)"   matTooltipClass="tooltipLineBreak" matTooltip="{{element.internalNotes}}">{{element.documentType}}</a>
                            <i  *ngIf="element.containerName" class="material-icons" title="download {{element.documentType}}"  style=" font-size: medium; cursor: pointer;padding-left: 5px;"  (click)="downloadDocument(element)">cloud_download</i>

                          </div>
                        </td>
                      </ng-container> 
                      
                      <ng-container matColumnDef="showToClient">
                        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 20%;width:20%;"> 
                          <div style="margin-top:-0.25em;">Show To Client</div>  
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 20%;width:20%">
                          <mat-checkbox [(ngModel)]="element.showToClient" (change)="changeShowToClientValue(element)" [ngModelOptions]="{standalone: true}" 
                          style="transform:scale(.8)"> </mat-checkbox>
                        </td>
                      </ng-container>
                      

                      <ng-container matColumnDef="removeDocument">
                        <th mat-header-cell *matHeaderCellDef style="width:3%">
                          <div>Load Sample</div>
                          <div style="margin-top:0em;padding-bottom:0.25em"></div>
                        </th>
                        <td mat-cell *matCellDef="let element" style="width: 3%;">  
                          <label class="btn-high mat-button "  *ngIf="!element.containerName" matTooltipClass="tooltipLineBreak" matTooltip="Load a Document">
                            <input *ngIf="!element.containerName" #document formControlName="document" id="document" type="file" ng2FileDrop [uploader]="uploader"
                            multiple style="display:none" accept="*" (change)="importFile($event, element, documentsListArray.indexOf(element))" maxFileSize="28000000"
                              (click)="onFileClick($event, documentsListArray.indexOf(element))" />
                            Load</label> 
                            <button  *ngIf="element.containerName"  type="button" mat-button class="btn-low "  (click)="removeDocument( element, documentsListArray.indexOf(element) )">Remove</button>

                        </td>
                      </ng-container> 

                      <ng-container matColumnDef="internalNotes">
                        <th mat-header-cell *matHeaderCellDef style="width:10%">
                          <div>Internal Notes</div>
                          <div style="margin-top:0em;padding-bottom:0.25em"></div>
                        </th>
                        <td mat-cell *matCellDef="let element" style="width: 10%;">  

                          <button  *ngIf="!element.internalNotes" type="button" mat-button class="btn-high "  (click)="addDocumentNotes( element, documentsListArray.indexOf(element) , '')">Add</button>
                          <button  *ngIf="element.internalNotes"  type="button" mat-button class="btn-low "  (click)="addDocumentNotes( element, documentsListArray.indexOf(element) , element.internalNotes)">Edit</button>

                        </td>
                      </ng-container> 

                      <ng-container matColumnDef="removeDocumentRow">
                        <th mat-header-cell *matHeaderCellDef style="width:13%">
                          <div>Remove </div>
                          <div style="margin-top:0em;padding-bottom:0.25em"></div>
                        </th>
                        <td mat-cell *matCellDef="let element" style="width: 13%;">
                          <i class="material-icons-outlined" style="color:red;"  matTooltipClass="tooltipLineBreak" matTooltip="Remove the File"
                           (click)="removeDocumentRow( element, documentsListArray.indexOf(element) )" >cancel</i>
                          <!-- <button *ngIf="showNewFolderInput" mat-button class="btn-low"  >Load Sample</button>   --> 
                        </td>
                      </ng-container> 
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn; let i = index;" [hidden]="!(row.isActive && !(row.showToClient && userType != 'internal'))"></tr>
                    <!-- <tr mat-row *matRowDef="let element; columns: displayedColumn; let i = index;"></tr> -->

                  </table>
                </div>
              </div>

              <div>

                <div  *ngIf="segmentDetails.countryContentSegment?.toLowerCase()  != countryNotesSec   "  style="margin-left: 1em;width: 36em; display: flex; margin-top: 1.5em;justify-content: space-between;">
                  <div style="width: 45%;"  >Miscellaneous Documents:</div>
                   
 

                    <button *ngIf="!f.isDocsRequired.value" style="height: 2em;width: 10%;" mat-button class="btn-high" (click)="addDocumentTypeRows(true)">+Add</button>
                </div> 

                <div *ngIf="documentsMiscListArray.length>0"  style="margin-bottom: 1em; padding-bottom: 1em;">
                  <table mat-table *ngIf="documentsMiscListArray"  [dataSource]="documentsMiscListArray"  
                    class="example-container mat-elevation-z8" style="width: 36em; margin: 1em;">
                    <tr> 
                      <ng-container matColumnDef="documentType">
                        <th mat-header-cell *matHeaderCellDef style="width:34%; text-align:left !important">
                          <div style="margin-top:-0.25em;">Document Type<i  *ngIf="checkMiscDocumentsExistence()" class="material-icons" title="Download All Misc Documents" style=" font-size: medium; cursor: pointer;padding-left: 5px;" (click)="downloadAllMiscAttachments( )">cloud_download</i></div>
                          
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 34%;">
                          <div style="display: flex;">
                            <div *ngIf="!element.containerName"   matTooltipClass="tooltipLineBreak" matTooltip="{{element.internalNotes}}" >{{element.documentType}}</div> 
                            <a *ngIf="element.containerName" href="javascript:void(0)" (click)="clickPreviewDocument(element)"   matTooltipClass="tooltipLineBreak" matTooltip="{{element.internalNotes}}">{{element.documentType}}</a>
                            <i  *ngIf="element.containerName" class="material-icons" title="download {{element.documentType}}"  style=" font-size: medium; cursor: pointer;padding-left: 5px;"  (click)="downloadDocument(element)">cloud_download</i>

                          </div>
                        </td>
                      </ng-container> 
                      
                      <ng-container matColumnDef="showToClient">
                        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 20%;width:20%;"> 
                          <div style="margin-top:-0.25em;">Show To Client</div>  
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 20%;width:20%">
                          <mat-checkbox [(ngModel)]="element.showToClient" (change)="changeShowToClientValue(element)" [ngModelOptions]="{standalone: true}" 
                          style="transform:scale(.8)"> </mat-checkbox>
                        </td>
                      </ng-container>
                      

                      <ng-container matColumnDef="removeDocument">
                        <th mat-header-cell *matHeaderCellDef style="width:3%">
                          <div>Load Sample</div>
                          <div style="margin-top:0em;padding-bottom:0.25em"></div>
                        </th>
                        <td mat-cell *matCellDef="let element" style="width: 3%;">  
                          <label class="btn-high mat-button "  *ngIf="!element.containerName" matTooltipClass="tooltipLineBreak" matTooltip="Load a Document">
                            <input *ngIf="!element.containerName" #document formControlName="document" id="document" type="file" ng2FileDrop [uploader]="uploader"
                            multiple style="display:none" accept="*" (change)="importMiscFile($event, element, documentsMiscListArray.indexOf(element))" maxFileSize="28000000"
                              (click)="onMiscFileClick($event, documentsMiscListArray.indexOf(element))" />
                            Load</label> 
                            <button  *ngIf="element.containerName"  type="button" mat-button class="btn-low "  (click)="removeMiscDocument( element, documentsMiscListArray.indexOf(element) )">Remove</button>

                        </td>
                      </ng-container> 

                      <ng-container matColumnDef="internalNotes">
                        <th mat-header-cell *matHeaderCellDef style="width:10%">
                          <div>Internal Notes</div>
                          <div style="margin-top:0em;padding-bottom:0.25em"></div>
                        </th>
                        <td mat-cell *matCellDef="let element" style="width: 10%;">  

                          <button  *ngIf="!element.internalNotes" type="button" mat-button class="btn-high "  (click)="addMiscDocumentNotes( element, documentsMiscListArray.indexOf(element) , '')">Add</button>
                          <button  *ngIf="element.internalNotes"  type="button" mat-button class="btn-low "  (click)="addMiscDocumentNotes( element, documentsMiscListArray.indexOf(element) , element.internalNotes)">Edit</button>

                        </td>
                      </ng-container> 

                      <ng-container matColumnDef="removeDocumentRow">
                        <th mat-header-cell *matHeaderCellDef style="width:13%">
                          <div>Remove </div>
                          <div style="margin-top:0em;padding-bottom:0.25em"></div>
                        </th>
                        <td mat-cell *matCellDef="let element" style="width: 13%;">
                          <i class="material-icons-outlined" style="color:red;"  matTooltipClass="tooltipLineBreak" matTooltip="Remove the File"
                           (click)="removeMiscDocumentRow( element, documentsMiscListArray.indexOf(element) )" >cancel</i>
                          <!-- <button *ngIf="showNewFolderInput" mat-button class="btn-low"  >Load Sample</button>   --> 
                        </td>
                      </ng-container> 
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn3; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn3; let i = index;" [hidden]="!(row.isActive && !(row.showToClient && userType != 'internal'))"></tr>
                    <!-- <tr mat-row *matRowDef="let element; columns: displayedColumn3; let i = index;"></tr> -->

                  </table>
                </div>
              </div>

              <div  *ngIf="segmentDetails.countryContentSegment?.toLowerCase()  == countryNotesSec" >
                <div  >Documents:</div>
                <div  style="margin-bottom: 1em; padding-bottom: 1em;">
                  <table mat-table [dataSource]="countryDocuments" class="example-container mat-elevation-z8" 
                    style="width: 37em !important; margin: 1em; ">
                    <tr>
                      <ng-container matColumnDef="Type">
                        <th mat-header-cell *matHeaderCellDef style="width:20%; text-align:left !important">
                          <div style="margin-top:-0.25em;">Document Type</div>
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 20%;">
                          <div style="display: flex;">
                            <div>{{element.documentTypeList}}</div>
                          </div>
                        </td>
                      </ng-container>
      
                      <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef style="width:20%; text-align:left !important">
                          <div style="margin-top:-0.25em;">Document Name</div>
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 20%;">
                          <div style="display: flex;">
                            <div><a href="javascript:void(0);"
                                (click)="downloadDocument(element.containerName, element.containerPath, element.documentName, element.mimeType)">{{element.documentName}}</a>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      
      
                      <ng-container matColumnDef="Effective_Date">
                        <th mat-header-cell *matHeaderCellDef style="width:4%; text-align:left !important">
                          <div style="margin-top:-0.25em;">Effective Date</div>
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 4%;">
                          <div style="display: flex;">
                            <div>{{element.effectiveDate}}</div>
                          </div>
                        </td>
                      </ng-container>
      
                      <ng-container matColumnDef="Expiration_Date">
                        <th mat-header-cell *matHeaderCellDef style="width:4%; text-align:left !important">
                          <div style="margin-top:-0.25em;">Expiry Date</div>
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 4%;">
                          <div style="display: flex;">
                            <div>{{element.expirationDate}}</div>
                          </div>
                        </td>
                      </ng-container>
      
                      <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef style="width:5%; text-align:left !important">
                          <button   type="button" mat-button class="btn-high grid" (click)="editCountryDocument(null)">ADD</button>
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 5%;">
                          <div style="display: flex;">
                            <button    type="button" mat-button class="btn-low grid" (click)="editCountryDocument(element)">EDIT</button>
                          </div>
                        </td>
                      </ng-container>
      
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn2"  [ngClass]="{ 'redColor': isDateExpired(row.expirationDate)  }" ></tr>
                  </table>
                </div>
              </div>

            </div>
 
          </div>

        </div>
   
      </div>
    </form>
  </div>
  <div   class="ml-3" style="width:min-content">
    <app-success-message [successMsg]="successMsg" *ngIf="showSuccessMsg"></app-success-message>
  </div>
  <div *ngIf="showErrorMsg" class="small alert alert-danger p-0" style="font-size: smaller; width: max-content; margin-left: .5em;  ">
    <div>{{errMsg}}</div>
  </div>
  <div style="justify-content: space-between; display: flex;">
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; margin-top: 0.5em;">
  
      <button mat-button class="btn-high mr-1" (click)="clickSave()">&nbsp;
        <mat-icon *ngIf="isLoading && isLoading1">
          <svg class="black-spinner" viewBox="0 0 50 50">
            <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="black" stroke-width="4"></circle>
          </svg>
        </mat-icon>Save</button>
      <!-- <button    mat-button class="btn-high mr-1"  (click)="clickUpdate()" >Update</button> -->
  
      <button mat-button class="btn-low" (click)="clickClose()">Close</button>
    </div>
    <div style="font-size: smaller; margin-right: 1em;">
      <div *ngIf="lastModifiedBy!= undefined && lastModifiedBy!= null" style="margin-left: 1em;">Modified By: {{lastModifiedBy}}</div>
      <div *ngIf="lastModifiedDate!= undefined && lastModifiedDate!= null">Modified Date: {{lastModifiedDate}}</div>
    </div>
  

  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
<!--  -->
