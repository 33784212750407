import { Component } from '@angular/core';

@Component({
  selector: 'app-fpi-marketing-bi',
  templateUrl: './fpi-marketing-bi.component.html',
  styleUrls: ['./fpi-marketing-bi.component.css']
})
/** airport-bi-dashboard component*/
export class FpiMarketingBiComponent {

  constructor() {

  }
}
