import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { UserModel } from '../models/user.model';
import { AuthenticateService } from '../services/authenticate.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { CustomerModel } from '../models/customer.model';
import { VendorModel } from '../models/vendor.model';
import { Observable, forkJoin } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { CommonService } from '../services/common.service';
import { ResponseModel } from '../models/response.model';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogService } from '../services/dialog.service';
import { UserPermissionAdminEditDialogComponent } from './user-permission-admin-edit-dialog.component';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GlobalConstant } from '../common-utility/global-constant';

@Component({
  selector: 'app-user-permission-restrict-edit-dialog',
  templateUrl: './user-permission-restrict-edit-dialog.component.html',
  styleUrls: ['./user-permission-restrict-edit-dialog.component.css']
})

export class UserPermissionRestrictEditDialogComponent implements OnInit, AfterViewInit, OnChanges {
  user_permission_restrict_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  user: UserModel;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;

  @Input() userId: string;
  @Input() customerGuid: string;
  @Input() tabName: string;
  @Input() userType: string;
  isModified: boolean=false;
  userList: UserModel[];
  //isActive: boolean; 
  displayedColumn: string[] = ['username', 'name', 'email'];
  displayedColumnRes: string[] = ['crew_trip_detail', 'pax_trip_detail', 'trip_document', 'create_event', 'create_alert', 'company_document', 'aircraft_preference', 'crew_pax_data_maintenance', 'trip_messages', 'vendor_invoiceupload'];
  displayedColumnRes2: string[] = ['restrict_access'];

  //selectionAccessCrewTripDetails = new SelectionModel<UserModel>(true, []);
  //selectionAccessPAXTripDetails = new SelectionModel<UserModel>(true, []);
  //selectionAccessTripDocuments = new SelectionModel<UserModel>(true, []);
  //selectionCreateCurrentEvents = new SelectionModel<UserModel>(true, []);
  //selectionCreateTripAlerts = new SelectionModel<UserModel>(true, []);
  //selectionAccessCompanyDocuments = new SelectionModel<UserModel>(true, []);
  //selectionManageAircraftPreference = new SelectionModel<UserModel>(true, []);
  //selectionManageCrewPAXDataMaintenance = new SelectionModel<UserModel>(true, []);
  //selectionAccessTripMessages = new SelectionModel<UserModel>(true, []);
  //selectionVendor_InvoiceUpload = new SelectionModel<UserModel>(true, []);

  //@Output() tabChanged = new EventEmitter();
  @Input() admin_access_select: boolean;
  @Input() account_status_select: boolean;
  @Input() effectiveDateText: string;
  @Input() expirationDateText: string;
  @Input() isValidateEffectiveDate: boolean;
  @Input() isValidateExpirationDate: boolean;
  @Input() locked: boolean;

  showSuccessMsg: boolean = false;
  ua: number;
  ut: string;

  grantAccessToManageManifestProfiles: boolean;
  grantAccessCompanyProfiles: boolean;
  grantAccessAircraftProfiles: boolean; 
  grantAccessToMessages: boolean;
  grantAccessToLockedTrips: boolean;
  grantAccessToInvoices: boolean;
  grantAccessToCompanyBusinessRules: boolean;
  grantAccessToVendorProfiles: boolean; 
  grantAccessCreateTripAlerts: boolean;
  grantAccessToManageCompanyEvents: boolean;
  grantAccessToHeadlineNews: boolean;
  grantAccessToPIIData: boolean;
  grantAccessToManageAccountGrouping: boolean;
  grantAccessToAPISCredentials: boolean;
  grantAccessToFuel: boolean;
  grantAccessToAirportDocuments: boolean;
  grantAccessToTripCloseout: boolean;
  grantAccessSubmitToBilling: boolean;
  grantAccessToMessageTemplates: boolean;
  grantAccessToHelpGuides: boolean;
  grantAccessToAccountingDashboard: boolean;

  //grantAccessSubmitTripsandRequestQuotes: boolean;
  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService, private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService, private readonly _dialogService: DialogService, private readonly _dialogRef: MatDialogRef<UserPermissionAdminEditDialogComponent>) {

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.ua = 0;// Number(localStorage.getItem('ua'));
    this.ut = localStorage.getItem('ut').toLowerCase();
    if (localStorage.getItem('up.isA') != null && localStorage.getItem('up.isA') != "") {
      if (localStorage.getItem('up.isA').toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
        this.ua = 1;
      }
    }
    if (this.ua != 1) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.showSpin = true;
    
    var request = new UserModel()
    request.userId = this.userId;
    this._identityService.getUserWithAnyStatusByUserId<ResponseModel<UserModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        //if (response.model.length>0) {        
        this.userList.push(response.model);
        this.user = response.model;
        this.grantAccessToManageManifestProfiles = this.user.grantAccessToManageManifestProfiles;
        this.grantAccessCompanyProfiles = this.user.grantAccessCompanyProfiles;
        this.grantAccessAircraftProfiles = this.user.grantAccessAircraftProfiles;
        this.grantAccessToMessages = this.user.grantAccessToMessages;
        this.grantAccessToLockedTrips = this.user.grantAccessToLockedTrips;
        this.grantAccessToInvoices = this.user.grantAccessToInvoices;
        this.grantAccessToCompanyBusinessRules = this.user.grantAccessToCompanyBusinessRules;
        this.grantAccessToVendorProfiles = this.user.grantAccessToVendorProfiles;
        this.grantAccessCreateTripAlerts = this.user.grantAccessCreateTripAlerts;
        this.grantAccessToManageCompanyEvents = this.user.grantAccessToManageCompanyEvents;
        this.grantAccessToHeadlineNews = this.user.grantAccessToHeadlineNews;
        this.grantAccessToPIIData = this.user.grantAccessToPIIData;
        this.grantAccessToManageAccountGrouping = this.user.grantAccessToManageAccountGrouping;
        this.grantAccessToAPISCredentials = this.user.grantAccessToAPISCredentials;
        this.grantAccessToFuel = this.user.grantAccessToFuel;
        this.grantAccessToAirportDocuments = this.user.grantAccessToAirportDocuments;
        this.grantAccessToTripCloseout = this.user.grantAccessToTripCloseout;
        this.grantAccessSubmitToBilling = this.user.grantAccessSubmitToBilling;
        this.grantAccessToMessageTemplates = this.user.grantAccessToMessageTemplates;
        this.grantAccessToHelpGuides = this.user.grantAccessToHelpGuides;
        this.grantAccessToAccountingDashboard = this.user.grantAccessToAccountingDashboard;

      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }

      this.loading = false;
      this.showSpin = false;
      this.initControls();

      if (this.f.grantAccessToPIIData.value == false) {
        this.grantAccessToPIIData = false;
        this.grantAccessToManageManifestProfiles = false;
        this.grantAccessCompanyProfiles = false;
        this.grantAccessAircraftProfiles = false;
        this.grantAccessToMessages = false;
        this.grantAccessToLockedTrips = false;
        this.grantAccessToInvoices = false;
        this.grantAccessToCompanyBusinessRules = false;
        this.grantAccessToVendorProfiles = false;
        this.grantAccessCreateTripAlerts = false;
        this.grantAccessToManageCompanyEvents = false;
        this.grantAccessToHeadlineNews = false;
        this.grantAccessToManageAccountGrouping = false;

        this.user_permission_restrict_form.get('grantAccessToManageManifestProfiles').disable();
        this.user_permission_restrict_form.get('grantAccessCompanyProfiles').disable();
        this.user_permission_restrict_form.get('grantAccessAircraftProfiles').disable();
        this.user_permission_restrict_form.get('grantAccessToMessages').disable();
        this.user_permission_restrict_form.get('grantAccessToLockedTrips').disable();
        this.user_permission_restrict_form.get('grantAccessToInvoices').disable();
        this.user_permission_restrict_form.get('grantAccessToCompanyBusinessRules').disable();
        this.user_permission_restrict_form.get('grantAccessToVendorProfiles').disable();
        this.user_permission_restrict_form.get('grantAccessCreateTripAlerts').disable();
        this.user_permission_restrict_form.get('grantAccessToManageCompanyEvents').disable();
        this.user_permission_restrict_form.get('grantAccessToHeadlineNews').disable();
        this.user_permission_restrict_form.get('grantAccessToManageAccountGrouping').disable();

      }
      else {
        this.user_permission_restrict_form.get('grantAccessToManageManifestProfiles').enable();
        this.user_permission_restrict_form.get('grantAccessCompanyProfiles').enable();
        this.user_permission_restrict_form.get('grantAccessAircraftProfiles').enable();
        this.user_permission_restrict_form.get('grantAccessToMessages').enable();
        this.user_permission_restrict_form.get('grantAccessToLockedTrips').enable();
        this.user_permission_restrict_form.get('grantAccessToInvoices').enable();
        this.user_permission_restrict_form.get('grantAccessToCompanyBusinessRules').enable();
        this.user_permission_restrict_form.get('grantAccessToVendorProfiles').enable();
        this.user_permission_restrict_form.get('grantAccessCreateTripAlerts').enable();
        this.user_permission_restrict_form.get('grantAccessToManageCompanyEvents').enable();
        this.user_permission_restrict_form.get('grantAccessToHeadlineNews').enable();
        this.user_permission_restrict_form.get('grantAccessToManageAccountGrouping').enable();
      }

    })

  }

  initControls() {
    this.user_permission_restrict_form = this._formBuilder.group({
      //customer_select: null,
      //account_status_select: [this.user.isActive],
      //admin_access_select: [this.user.isAdmin]//,
      grantAccessToManageManifestProfiles: this.grantAccessToManageManifestProfiles,
      grantAccessCompanyProfiles: this.grantAccessCompanyProfiles,
      grantAccessAircraftProfiles: this.grantAccessAircraftProfiles,
      grantAccessToMessages: this.grantAccessToMessages,
      grantAccessToLockedTrips: this.grantAccessToLockedTrips,
      grantAccessToInvoices: this.grantAccessToInvoices,
      grantAccessToCompanyBusinessRules: this.grantAccessToCompanyBusinessRules,
      grantAccessToVendorProfiles: this.grantAccessToVendorProfiles,
      grantAccessCreateTripAlerts: this.grantAccessCreateTripAlerts,
      grantAccessToManageCompanyEvents: this.grantAccessToManageCompanyEvents,
      grantAccessToHeadlineNews: this.grantAccessToHeadlineNews,
      grantAccessToPIIData: this.grantAccessToPIIData,
      grantAccessToManageAccountGrouping: this.grantAccessToManageAccountGrouping,
      grantAccessToAPISCredentials: this.grantAccessToAPISCredentials,
      grantAccessToFuel: this.grantAccessToFuel,
      grantAccessToAirportDocuments: this.grantAccessToAirportDocuments,
      grantAccessToTripCloseout: this.grantAccessToTripCloseout,
      grantAccessSubmitToBilling: this.grantAccessSubmitToBilling,
      grantAccessToMessageTemplates: this.grantAccessToMessageTemplates,
      grantAccessToHelpGuides: this.grantAccessToHelpGuides,
      grantAccessToAccountingDashboard: this.grantAccessToAccountingDashboard

    });


  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.admin_access_select)
      this.admin_access_select = changes.admin_access_select.currentValue;
    if (changes.account_status_select)
      this.account_status_select = changes.account_status_select.currentValue;
    if (changes.effectiveDateText)
      this.effectiveDateText = changes.effectiveDateText.currentValue;
    if (changes.expirationDateText)
      this.expirationDateText = changes.expirationDateText.currentValue;
    if (changes.isValidateEffectiveDate)
      this.isValidateEffectiveDate = changes.isValidateEffectiveDate.currentValue;
    if (changes.isValidateExpirationDate)
      this.isValidateExpirationDate = changes.isValidateExpirationDate.currentValue;
    if (changes.locked)
      this.locked = changes.locked.currentValue;
    this.showSuccessMsg= false;
  }

  get f() { return this.user_permission_restrict_form.controls; }

  restrictChange(e: any) {
    if (this.f.grantAccessToPIIData.value == false) {
      this.grantAccessToPIIData = false;
      this.grantAccessToManageManifestProfiles = false;
      this.grantAccessCompanyProfiles = false;
      this.grantAccessAircraftProfiles = false;
      this.grantAccessToMessages = false;
      this.grantAccessToLockedTrips = false;
      this.grantAccessToInvoices = false;
      this.grantAccessToCompanyBusinessRules = false;
      this.grantAccessToVendorProfiles = false;
      this.grantAccessCreateTripAlerts = false;
      this.grantAccessToManageCompanyEvents = false;
      this.grantAccessToHeadlineNews = false;
      this.grantAccessToManageAccountGrouping = false;

      this.user_permission_restrict_form.get('grantAccessToManageManifestProfiles').disable();
      this.user_permission_restrict_form.get('grantAccessCompanyProfiles').disable();
      this.user_permission_restrict_form.get('grantAccessAircraftProfiles').disable();
      this.user_permission_restrict_form.get('grantAccessToMessages').disable();
      this.user_permission_restrict_form.get('grantAccessToLockedTrips').disable();
      this.user_permission_restrict_form.get('grantAccessToInvoices').disable();
      this.user_permission_restrict_form.get('grantAccessToCompanyBusinessRules').disable();
      this.user_permission_restrict_form.get('grantAccessToVendorProfiles').disable();
      this.user_permission_restrict_form.get('grantAccessCreateTripAlerts').disable();
      this.user_permission_restrict_form.get('grantAccessToManageCompanyEvents').disable();
      this.user_permission_restrict_form.get('grantAccessToHeadlineNews').disable();
      this.user_permission_restrict_form.get('grantAccessToManageAccountGrouping').disable();
      this.initControls();

    }
    else {
      this.user_permission_restrict_form.get('grantAccessToManageManifestProfiles').enable();
      this.user_permission_restrict_form.get('grantAccessCompanyProfiles').enable();
      this.user_permission_restrict_form.get('grantAccessAircraftProfiles').enable();
      this.user_permission_restrict_form.get('grantAccessToMessages').enable();
      this.user_permission_restrict_form.get('grantAccessToLockedTrips').enable();
      this.user_permission_restrict_form.get('grantAccessToInvoices').enable();
      this.user_permission_restrict_form.get('grantAccessToCompanyBusinessRules').enable();
      this.user_permission_restrict_form.get('grantAccessToVendorProfiles').enable();
      this.user_permission_restrict_form.get('grantAccessCreateTripAlerts').enable();
      this.user_permission_restrict_form.get('grantAccessToManageCompanyEvents').enable();
      this.user_permission_restrict_form.get('grantAccessToHeadlineNews').enable();
      this.user_permission_restrict_form.get('grantAccessToManageAccountGrouping').enable();

    }
    
  }

  //onSubmit() {
  clickSave() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";

    if (!this.isValidateEffectiveDate || !this.isValidateExpirationDate) {
      return;
    }
    if (this.loading == true) {
      return;
    }
    this.loading = true;
    this.showSpin = true;

    var request = new UserModel();
    request.isActive = this.account_status_select;
    //request.isAdmin = this.admin_access_select;
    if (this.admin_access_select) {
      request.isAB = localStorage.getItem('up.isA');
    }
    else {
      request.isAB = localStorage.getItem('up.isB');
    }
    request.effectiveDate = this.effectiveDateText;
    request.expirationDate = this.expirationDateText;
    request.locked = this.locked;
    request.grantAccessToManageManifestProfiles = this.f.grantAccessToManageManifestProfiles.value;
    request.grantAccessCompanyProfiles = this.f.grantAccessCompanyProfiles.value;
    request.grantAccessAircraftProfiles = this.f.grantAccessAircraftProfiles.value;
    request.grantAccessToMessages = this.f.grantAccessToMessages.value;
    request.grantAccessToLockedTrips = this.f.grantAccessToLockedTrips.value;
    request.grantAccessToInvoices = this.f.grantAccessToInvoices.value;
    request.grantAccessToCompanyBusinessRules = this.f.grantAccessToCompanyBusinessRules.value;
    request.grantAccessToVendorProfiles = this.f.grantAccessToVendorProfiles.value;
    request.grantAccessCreateTripAlerts = this.f.grantAccessCreateTripAlerts.value;
    request.grantAccessToManageCompanyEvents = this.f.grantAccessToManageCompanyEvents.value;
    request.grantAccessToHeadlineNews = this.f.grantAccessToHeadlineNews.value;
    request.grantAccessToPIIData = this.f.grantAccessToPIIData.value;
    request.grantAccessToManageAccountGrouping = this.f.grantAccessToManageAccountGrouping.value;
    request.grantAccessToAPISCredentials = this.f.grantAccessToAPISCredentials.value;
    request.grantAccessToFuel = this.f.grantAccessToFuel.value;
    request.grantAccessToAirportDocuments = this.f.grantAccessToAirportDocuments.value;
    request.grantAccessToTripCloseout = this.f.grantAccessToTripCloseout.value;
    request.grantAccessSubmitToBilling = this.f.grantAccessSubmitToBilling.value;
    request.grantAccessToMessageTemplates = this.f.grantAccessToMessageTemplates.value;
    request.grantAccessToHelpGuides = this.f.grantAccessToHelpGuides.value;
    request.grantAccessToAccountingDashboard = this.f.grantAccessToAccountingDashboard.value;
    request.customerGuid = this.customerGuid;
    request.userId = this.userId;
    
    this._identityService.saveUserRestrictionAccessByUserId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {
          //var s = "The user info and restriction access selections have been saved.";
          //this._dialogService.openAlertDialog(s);
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
        }
        else {
          this.errMsg = "Failed to save to our database.";
        }
      }
      else {
        this.errMsg = "Failed to save to our database.";
      }
      this.loading = false;
      this.showSpin = false;

    })

  }

  clickClose() {
    this._dialogRef.close(this.isModified);
    
  }

  

}
