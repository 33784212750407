<div style="margin-top:0em; padding-top:0em;">
  <form *ngIf="flight_watch_form" [formGroup]="flight_watch_form">
    <div class="divLeft">
      <div style="display:flex">
        <div style="background-color:#f8f8f8; padding-left:0.25em; padding-right:1.25em; border: 0.0625em lightgrey solid ;border-radius: 0.25em;min-width:65em">
          <div style="display: flex">
            <div style="margin-right:1em"><h5>Daily View</h5></div>
          </div>
          <div style="display: flex; font-size: small">
            <div style="display:flex; margin-right:1em">
              <div style="width:7em; margin-right:1em">
                Trip Schedules Rolling Window {{queryHrText}}
              </div>
              <div>
                <div class="slidecontainer" style="margin-top:-2em; margin-right:1em">
                  <input type="range" formControlName="queryHour" min="0" max="5" class="slider" [value]="queryIndex" step="1" (change)="querySlideChange($event)">
                </div>
              </div>
            </div>
            <div *ngIf="userType=='internal'" style="margin-top:0em; margin-right:1em">
              <label for="customer_select" style="font-size:small">Select a Client Account</label>
              <div style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="customer_select" style="font-size: small;border-radius:0.25em;border-color:lightgrey" (change)="customerChange($event)">
                  <option value="" selected>Client Name</option>
                  <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                </select>
              </div>
            </div>
            <div style="margin-right:1em; margin-top:1.5em">
              <button type="button" class="btn btn-primary" style="min-width: 5em;" (click)="clickRefresh()">
                <div style="display:flex">
                  <div style="margin-top: 0.25em">
                    <i class="material-icons" style="font-size: larger;cursor: pointer; color: white; ">refresh</i>
                  </div>
                  <div>Refresh</div>
                </div>
              </button>
            </div>
            <div>
              <div *ngIf="userType=='internal'" style="display: flex;margin-top:1em">
                <mat-checkbox (click)="$event.stopPropagation()" formControlName="includeDemo" style="transform: scale(0.75)" (change)="includeDemoOnChange($event)"></mat-checkbox>
                <div style="margin-top:-0.25em"><label for="includeDemo" style="font-size:small">&nbsp;Include Demo Trips</label></div>
              </div>
              <div style="margin-top:-0.5em">{{totalRecordsText}}</div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div>
        <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container mat-elevation-z8">
          <tr>
            <ng-container matColumnDef="registration">
              <th mat-header-cell *matHeaderCellDef style="width:15%; text-align:left !important">
                <div style="margin-top:-0.25em;">Registration</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important;">
                <div style="font-weight:bold">{{element.registration}}</div>
                <div style="font-size:small;margin-top:-0.25em; white-space:nowrap">{{element.departurePIC!=''?'PIC: '+element.departurePIC:''}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="departure">
              <th mat-header-cell *matHeaderCellDef style="width:25%; text-align:left !important">
                <div style="display: flex">
                  <div style="margin-right:0.25em"><i class="material-icons" style="border: black 0.0625em solid; font-size:large">flight_takeoff</i></div>
                  <div style="margin-top:-0.25em;">Departure</div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important">
                <div style="display: flex">
                  <div style="width:14em">
                    <div style="display: flex">
                      <div *ngIf="element.departureICAO!='XXXX'" class="divHover" style="cursor: pointer;font-weight: bold" (click)="clickICAO($event, element,'D')">{{element.departureICAO_IATA}}</div>
                      <div *ngIf="element.departureICAO=='XXXX'" style="font-weight: bold" >{{element.departureICAO_IATA}}</div>
                      <div></div>
                    </div>
                    <div style="font-weight:bold">{{element.departureDateTimeUTCText}}</div>
                    <div>{{element.departureDateTimeLocalText}}</div>
                    <div *ngIf="element.departureVendorList.length>0" style="display: flex">
                      <div style="margin-top:-0.25em">Handler:&nbsp;</div>
                      <div>
                        <div *ngFor="let p of element.departureVendorList" style="display:flex; margin-top:-0.25em">
                          <div style="white-space:nowrap;cursor: pointer" class="divHover" title="{{ p.vendorName }}" (click)="clickVendorName($event, element, p)">{{p.vendorName.substr(0,19)}}</div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="element.isDepartureHomeBaseAirport" style="border-radius: 0.5em; width: 6.25em; margin-bottom:0.125em; height: 1.25em; border: gray solid 0.0625em;background-color:#19967d; color: white; padding-left:0.5em"><div style="margin-top:-0.3em; margin-left:0em">Home Base</div></div>
                    <div style="display: flex">
                      <div style="border-radius: 0.5em;min-width: 6em; height: 1.25em; border: gray solid 0.0625em;" [style.background-color]="element.departureStatusColorCode">
                        <div style="margin-top:-0.3em; text-align:center">{{element.departureStatusDescription}}</div>
                      </div>
                      <div></div>
                    </div>
                    <div *ngIf="element.departureServiceClassTaskStatusList.length>0" style="display:flex; margin-top:0.25em;margin-left:-0.75em">
                      <div *ngFor="let s of element.departureServiceClassTaskStatusList">
                        <div style="display:flex;margin-left:0.8em">
                          <div style="border-radius: 50%; width: 1.75em; height: 1.25em; border: black solid 0.0625em;" [style.background-color]="s.taskStatusBackgroundColor"></div>
                          <div style="font-size: medium; margin-left:-1.1em;margin-top:-0.2em" [style.color]="s.taskStatusFontColor" title="{{s.serviceClassDescription}}">{{s.serviceClassDescriptionAbbr}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="element.ete!=''">
                    <div>ETE</div>
                    <div>{{element.ete}}</div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="arrival">
              <th mat-header-cell *matHeaderCellDef style="width:20%; text-align:left !important">
                <div style="display:flex">
                  <div style="margin-right:0.25em"><i class="material-icons" style="border: black 0.0625em solid; font-size:large">flight_land</i></div>
                  <div style="margin-top:-0.25em;">Arrival</div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important">
                <div style="display:flex">
                  <div *ngIf="element.nextArrivalICAO!='XXXX'" class="divHover" style="cursor: pointer;font-weight: bold" (click)="clickICAO($event, element,'A')">{{element.nextArrivalICAO_IATA}}</div>
                  <div *ngIf="element.nextArrivalICAO=='XXXX'" style="font-weight: bold">{{element.nextArrivalICAO_IATA}}</div>
                  <div></div>
                </div>
                <div style="font-weight:bold">{{element.nextArrivalDateTimeUTCText}}</div>
                <div>{{element.nextArrivalDateTimeLocalText}}</div>
                <div *ngIf="element.nextArrivalVendorList.length>0" style="display: flex">
                  <div style="margin-top:-0.25em">Handler:&nbsp;</div>
                  <div>
                    <div *ngFor="let p of element.nextArrivalVendorList" style="display:flex; margin-top:-0.25em">
                      <div style="white-space:nowrap;cursor: pointer" class="divHover" title="{{ p.vendorName }}" (click)="clickVendorName($event, element, p)">{{p.vendorName.substr(0,19)}}</div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div *ngIf="element.isNextArrivaHomeBaseAirport" style="border-radius: 0.5em; width: 6.25em; margin-bottom:0.125em; height: 1.25em; border: gray solid 0.0625em;background-color:#19967d; color: white; padding-left:0.5em"><div style="margin-top:-0.3em; margin-left:0em">Home Base</div></div>
                <div style="display: flex">
                  <div style="border-radius: 0.5em; min-width: 6em; height: 1.25em; border: gray solid 0.0625em;" [style.background-color]="element.nextArrivalStatusColorCode">
                    <div style="margin-top:-0.3em; text-align:center">{{element.nextArrivalStatusDescription}}</div>
                  </div>
                  <div></div>
                </div>
                <div *ngIf="element.nextArrivalServiceClassTaskStatusList.length>0" style="display:flex; margin-top:0.25em;margin-left:-0.75em">
                  <div *ngFor="let s of element.nextArrivalServiceClassTaskStatusList">
                    <div style="display:flex;margin-left:0.8em">
                      <div style="border-radius: 50%; width: 1.75em; height: 1.25em; border: black solid 0.0625em;" [style.background-color]="s.taskStatusBackgroundColor"></div>
                      <div style="font-size: medium; margin-left:-1.1em;margin-top:-0.2em" [style.color]="s.taskStatusFontColor" title="{{s.serviceClassDescription}}">{{s.serviceClassDescriptionAbbr}}</div>
                    </div>
                  </div>
                </div>

              </td>
            </ng-container>
            <ng-container matColumnDef="tripCode">
              <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important">
                <div style="margin-top:-0.25em;">Trip Codes</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important">
                <div style="font-weight:bold">{{element.tripCode}}</div>
                <div >{{element.tripCloseOutDescription}}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="clientName">
              <th mat-header-cell *matHeaderCellDef style="width:20%; text-align:left !important">
                <div style="margin-top:-0.25em;">Client Account</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important">
                <div style="font-weight:bold">{{element.customerName}}</div>
                <div style="font-size:x-small;margin-top:-0.25em; white-space:nowrap">{{element.customerCreditStatus}}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width:10%">
                <div>Action</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div style="margin-top:0.25em">
                  <a mat-button class="btn btn-group-sm2" style="align-self:center" (click)="clickOpen($event, element);">
                    Open
                  </a>
                </div>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
    </div>
</form>
</div>
  
<app-spin *ngIf="showSpin"></app-spin>

