import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType, HttpClientModule, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { GlobalConstant } from "../common-utility/global-constant";
import { AuthenticateService } from "./authenticate.service";
import { DistanceTimeModel } from "../models/distance-time.model";
import { CountryContentModel } from "../models/country-content.model";
import { CountryDocumentModel } from "../models/country-document.model";
import { CountryModel } from "../models/country.model";
import { CountryContentDocModel } from "../models/country-content-doc.model";
import { DocumentTypeModel } from "../models/document-type.model";


@Injectable()
export class CountryService {
  
  constructor(private readonly http: HttpClient, private readonly _authService: AuthenticateService) {

  }


  getCountryContentByCountryContentID<T>(countryContentId: number): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "Country/getCountryContentByCountryContentID?countryContentId=" + countryContentId, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  getCountryContentByCountryIDandSegmentID<T>(request: CountryContentModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Country/getCountryContentByCountryIDandSegmentID", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  saveCountryContent<T>(request: CountryContentModel[]): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Country/saveCountryContent", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  uploadCountryDocument<T>(request: CountryDocumentModel) {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });
  
    return this.http.post<T>(GlobalConstant.baseUrl + "Country/uploadCountryDocument", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }
  
  getCountryDocument<T>(request: CountryDocumentModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });
  
    return this.http.post<T>(GlobalConstant.baseUrl + "Country/getCountryDocument", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  
  }
  
  getCountryDocumentTypeList<T>(adId: string): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });
  
    return this.http.get<T>(GlobalConstant.baseUrl + "Country/getCountryDocumentTypeList?adId=" + adId, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  
  }

  getCountryBySearchKey<T>(request: CountryModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Country/getCountryListBySearchKey", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  downloadCountryDoc<T>(countryDoc: CountryDocumentModel):Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Country/downloadCountryDoc", countryDoc, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getCountryRequiredDocumentTypeList<T>(adId: string): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "Country/getCountryRequiredDocumentTypeList?adId=" + adId, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  uploadCountryContentDoc<T>(request: CountryContentDocModel[]) {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });
  
    return this.http.post<T>(GlobalConstant.baseUrl + "Country/uploadCountryContentDoc", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  getLastModifiedForCountrySegment<T>(ctId: number, segId: number): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "Country/getLastModifiedForCountrySegment?ctId=" + ctId + "&segId=" + segId, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

 
  getCountryRequiredDocumentTypeListForUpdates<T>(request: DocumentTypeModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Country/getCountryRequiredDocumentTypeListForUpdates", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }


}
