<button mat-icon-button   *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i" (click)="onClick(i+1)" >
  <!-- <mat-icon style="color: #ffd740; ">
    {{showIcon(i)}}
  </mat-icon> -->

  <i class="material-icons-outlined" style="color:#ffd740; font-size: 1.8em; "   >{{showIcon(i)}}</i>
  <span style="display: grid;font-size: medium; color: #c7c3c3;">{{ratingId+1}}</span>
</button>
<!-- <mat-error *ngIf="starCount == null || starCount == 0">
  Star count is <strong>required</strong> and cannot be zero
</mat-error> -->
<!-- <p class="body-2">
    Your rated <span class="body-2">{{rating}}</span> / <span class="body-2">{{starCount}}</span>
</p> -->
