import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { ReturnObjModel } from '../models/return-obj.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { DatePipe, formatDate } from '@angular/common';
import { CurrencyModel } from '../models/currency.model';
import { FuelService } from '../services/fuel.service';
import { AirportModel } from '../models/airport.model';
import { CustomerModel } from '../models/customer.model';
import { CountryModel } from '../models/country.model';
import { FuelCommissionsModel } from '../models/fuel-commissions.model';
import { FuelSupplierModel } from '../models/fuelsupplier.model';
import { UnitofmeasureModel } from '../models/unitofmeasure.model';
import { IntoPlaneAgentModel } from '../models/intoplaneagent.model';
import { stream } from 'exceljs';


@Component({
  selector: 'app-fuel-commissions-edit',
  templateUrl: './fuel-commissions-edit-dialog.component.html',
  styleUrls: ['./fuel-commissions-edit-dialog.component.css']
})

export class FuelCommissionsEditDialogComponent implements OnInit, AfterViewInit {

  //@Input() isfromfuelpricing: boolean = true;
  @Input() dialogTitle: string;
  fuel_Client_commissions_edit: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;

  countryList: CountryModel[];
  fuelSupplierList: FuelSupplierModel[];
  unitofmeasurelist: UnitofmeasureModel[];
  intoplaneagentList: IntoPlaneAgentModel[];



  airportlist: AirportModel[];
  currencylist: CurrencyModel[];

  currencyModel: CurrencyModel;


  airportid: number;


  show: boolean = false;
  showModified: boolean = false;
  fuelCommissionsID: number;
  lk_FuelSupplierGUID: string;
  intoplaneagent: string;
  currencyID: string;

  icao: string;
  iata: string;
  currency: string;

  clientGUID: string;
  clientid: number;
  countryid: number;

  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  controlValueChanged: boolean;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  customerName: any;



  scaleneedsadjustment: any;
  taxesIncluded: boolean = false;

  isAllowImport: any;

  allowfuelpriceimport: any;
  isallowfuelpriceimport: string;
  allowfuelpriceimport1: boolean;
  successMsg: string;
 // commissionsAmt: number;
  effectivedate: string = new Date().toString();
  expirydate: string = "";// = new Date().toString();  
  fuelsupplier: string;
  fuelsuppliername: string;
  isExistingPrice: boolean = false;

  isValidICAO: boolean = false;
  validateICAO: boolean = true;

  lk_UnitOfMeasureID: any;


  fuelRangeForm: FormGroup;
  isDuplicate: boolean = false;
  ifsuccess: boolean = false;
  internalnotes: string;
  commissionAmt: number;
  countryGUID: string;
  setAllCountrySelection: boolean = false;


  iscountryorairport: string = "country";
  countryName: string;
  countrylength: number;
  showDuplicateText: boolean = false;
  unitofmeasure: number = 1;
  otherintoplaneagent: string;


 // intoplaneagent: string = 'default';
  isotherintoplaneagent: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<FuelCommissionsEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    //private readonly _fuelPricing: FuelPricingService,
    //private readonly _fuel: FuelSupplierService,
    private readonly _fuel: FuelService,
    private readonly _dialogService: DialogService,
    private datePipe: DatePipe,
    private fb: FormBuilder

  ) {
    this.fuelCommissionsID = _data.fuelCommissionsID;
    this.dialogTitle = _data.dialogTitle;

    this.isAllowImport = false;



    if (_data.dialogTitle == "Add") {
      this.showModified = false;
      this.isExistingPrice = false;

    }
    else {
      this.isValidICAO = true;
      this.showModified = true;
      this.isExistingPrice = true;
    }

    //this.fuelRangeForm = this.fb.group({ 
    //  quantities: this.fb.array([]),
    //});
  }

  getAllData(): Observable<any[]> {
    // let getCustomers = this._fuel.getCustomers(); //getActiveFuelSupplier();  //  getFuelSupplierList(); getActiveFuelSupplier
    let getCountry = this._fuel.getClientMarkupCountryList();
    let getFuelSuppliers = this._fuel.getFuelSupplierActive();// getActiveFuelSupplier(); 
    let getUnitOfMeasure = this._fuel.getUnitOfMeasure();
    let getCurrency = this._fuel.getCurrency();
    //let getAirportList = this._fuel.getAirportList();
    if (this.fuelCommissionsID != 0) {// != null) {
      let getfuelCommissionscommissionsByfcId = this._fuel.getfuelCommissionsByFCId(this.fuelCommissionsID);
      return forkJoin([getCountry, getFuelSuppliers, getUnitOfMeasure, getCurrency, getfuelCommissionscommissionsByfcId]);//, getUnitOfMeasure, getCurrency]);
    }
    else {
      return forkJoin([getCountry, getFuelSuppliers, getUnitOfMeasure, getCurrency,]);//[getDomFrequency, getIntlFrequency, getUnitOfMeasure, getCurrency]);
    }
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    //this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;

    this.allowfuelpriceimport = 0;

    this.isValidICAO = false;

    //this.clientGUID = 'default';
    this.clientid = 0;
    this.countryid = 265;
    this.currency = 'USD'
    this.fuelsupplier = 'default';      ;

    //  this.fuelSupplierList = []; 
    this.countryList = [];
    this.showSuccessMsg = false;

    //this.fuel_Client_commissions_edit.get('effectivedate').patchValue(this.formatDate(new Date()));
    //this.fuel_Client_commissions_edit.get('expirydate').patchValue(this.formatDate(new Date()));
    this.getAllData().subscribe(responses => {
      if (responses != null) { }
      //this.fuelsupplier = 'default';
      //this.intlfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';


      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.countryList = responses[0].model;


          let country = new CountryModel();

          country = responses[0].model;
          if (country.countryGUID == 'undefined') {
            this.countryGUID = country.countryGUID;
          }

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.fuelSupplierList = responses[1].model;


          let fs = new FuelSupplierModel();

          fs = responses[1].model;
          if (fs.fuelSupplierGUID == 'undefined') {
            this.fuelsupplier = fs.fuelSupplierGUID;
          }

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.unitofmeasurelist = responses[2].model;


          let uom = new UnitofmeasureModel();

          uom = responses[2].model;
          if (uom.lk_UnitOfMeasureID == 'undefined') {
            this.lk_UnitOfMeasureID = uom.lk_UnitOfMeasureID;
          }

        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.currencylist = responses[3].model;


          let curr = new CurrencyModel();

          curr = responses[3].model;
          if (curr.lk_currencyID != null) {
            this.currency = curr.lk_currencyID.toString();
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }


      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          let fc = new FuelCommissionsModel();
          fc = responses[4].model;


          // this.iscountryorairport = fcm.airportID == 0 ? 'country' : 'airport';

          this.fuelCommissionsID = fc.fuelCommissionID;
         // this.fuelsupplier = fc.lk_FuelSupplierGUID;

          this.fuelsupplier = fc.lk_FuelSupplierGUID == null ? 'default' : fc.lk_FuelSupplierGUID;
          this.intoplaneagent = fc.intoPlaneAgent == 'All' ? 'default' : fc.intoPlaneAgent;

          if (this.fuelsupplier != 'default')
            this.fuelsupplierChange();

          //this.lk_FuelSupplierGUID = fc.lk_FuelSupplierGUID;
          this.countryid = fc.countryID;
          this.airportid = fc.airportID;
          this.internalnotes = fc.internalNotes;
          this.commissionAmt = fc.commissionAmt;
          this.currencyID = fc.currencyID;
          this.icao = fc.icao;
          this.iata = fc.iata;
          this.isActive = fc.isActive == true ? 1 : 0;
          this.unitofmeasure = fc.lk_UnitOfMeasureID;
          // this.icaoValidation();

          this.iscountryorairport = fc.airportID == 0 ? 'country' : 'airport';


          this.effectivedate = fc.effectiveDate == null ? '' : formatDate(fc.effectiveDate, 'yyyy-MM-dd', 'en');// taf.effectiveDate;
          this.expirydate = fc.expiryDate == null ? '' : formatDate(fc.expiryDate, 'yyyy-MM-dd', 'en');//  taf.expiryDate;

          //this.internalnotes = fc.internalNotes;
          this.modifiedBy = fc.modifiedBy;
          this.modifiedDate = fc.modifiedDate;
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.checkFormControlValueChanged();

      this.showSpin = false;



    });

    this.initControls();
    this.showSpin = false;
  }

  private formatDate(date) {
    if (date != null && date != '') {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    }
  }

  ngAfterViewInit() {

  }

  initControls() {
    this.fuel_Client_commissions_edit = this._formBuilder.group({
      fuelCommissionsID: [this.fuelCommissionsID],
      //fuelCommissionscommissionsGUID: [this.fuelCommissionscommissionsGUID, Validators.required],
      // clientGUID: [this.clientGUID, Validators.required],
      //clientid: [this.clientid, Validators.required],
      countryid: [this.countryid, Validators.required],
      icao: [this.icao, Validators.required],
      iata: [this.iata, Validators.required],
      fuelsupplier: [this.fuelsupplier],
      intoplaneagent: [this.intoplaneagent],
      commissionAmt: [this.commissionAmt, Validators.required],
      unitofmeasure: [this.unitofmeasure],
      currency: [this.currencyID],
     // intoPlaneAgent: [this.intoplaneagent],
      //airportid: [this.airportid, Validators.required],
      effectivedate: [formatDate(this.effectivedate, 'yyyy-MM-dd', 'en'), [Validators.required]],// [this.effectivedate, Validators.required],
      expirydate: [this.expirydate, Validators.required], //[formatDate(this.expirydate, 'yyyy-MM-dd', 'en'), [Validators.required]],//[this.expirydate, Validators.required],

      internalnotes: [this.internalnotes, Validators.required],
      isActive: [this.isActive],


      otherintoplaneagent: [this.otherintoplaneagent],

      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],

      setAllCountrySelection: [this.setAllCountrySelection]
    });
  }
  get f() { return this.fuel_Client_commissions_edit.controls; }

  checkFormControlValueChanged(): void {
    //this.fuel_Client_commissions_edit.get("clientid").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    this.fuel_Client_commissions_edit.get("countryid").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Client_commissions_edit.get("icao").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Client_commissions_edit.get("iata").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Client_commissions_edit.get("fuelsupplier").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Client_commissions_edit.get("intoplaneagent").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Client_commissions_edit.get("commissionAmt").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Client_commissions_edit.get("currency").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    //this.fuel_Client_commissions_edit.get("commissionAmt").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    this.fuel_Client_commissions_edit.get("effectivedate").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Client_commissions_edit.get("expirydate").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Client_commissions_edit.get("internalnotes").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Client_commissions_edit.get("isActive").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_Client_commissions_edit.get("unitofmeasure").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
  }

  setAllCountrySelectionChange(event: any) {
    this.setAllCountrySelection = this.f.setAllCountrySelection.value;
    if (this.f.setAllCountrySelection.value) {
      this.f.icao.disable();
      this.f.iata.disable();

      this.f.icao.setValue(null);
      this.f.iata.setValue(null);

      this.f.icao.setValidators(null);
      this.f.iata.setValidators(null);
    }
    else {
      this.f.icao.enable();
      this.f.iata.enable();

      this.f.icao.setValidators(Validators.required);
      this.f.iata.setValidators(Validators.required);
    }
  }


  clickSave() {
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;

    //if (!this.isExistingPrice && this.f.fuelsupplier.value == "default") {
    //if (this.f.fuelCommissionscommissionsGUID.value == "default") {
    //  this.f.fuelCommissionscommissionsGUID.setErrors({ required: true });
    //}

    if (this.iscountryorairport == "airport") {
      if (this.f.icao.value == "") {
        this.f.icao.setErrors({ required: true });
      }
      if (this.f.iata.value == "") {
        this.f.iata.setErrors({ required: true });
      }
    }

    //if (this.f.clientid.value == "default") {
    //  this.f.clientid.setErrors({ required: true });
    //}

    //if (this.f.countryid.value == "default" ) {
    //  this.f.countryid.setErrors({ required: true });
    //}

    //if (this.f.airportid.value == "") {
    //  this.f.airportid.setErrors({ required: true });
    //}


    if (this.f.intoplaneagent.value == "" || this.f.intoplaneagent.value == undefined) {
      this.f.intoplaneagent.setErrors({ required: true });
    }
    if (this.f.currency.value == "") {
      this.f.currency.setErrors({ required: true });
    }
    if (this.f.commissionAmt.value == "" || this.f.commissionAmt.value == null) {
      this.f.commissionAmt.setErrors({ required: true });
      // return;
    }

    if (this.f.expirydate.value != null && this.f.expirydate.value != '')
      if (this.f.effectivedate.value > this.f.expirydate.value) {
        this.f.expirydate.setErrors({ matEndDateInvalid: true });
        return;
      }


    if (this.f.effectivedate.value == "") {
      this.f.effectivedate.setErrors({ required: true });
    }

    //if (this.f.expirydate.value == "") {
    //  this.f.expirydate.setErrors({ required: true });
    //} 
    //if (!this.isExistingPrice && !this.isValidICAO) {
    //  return;
    //}


    //  this.loading = true;
    let obj = new FuelCommissionsModel();

    if (this.f.intoplaneagent.value == 'other' && (this.f.otherintoplaneagent.value == '' || this.f.otherintoplaneagent.value == null)) {/*&& !this.isExistingtaf*/
      this.f.otherintoplaneagent.setErrors({ required: true });
      // this.errMsg = 'Into Plane Agent cannot be empty';
      return;
    }

    obj.fuelCommissionID = this.fuelCommissionsID == 0 ? 0 : this.fuelCommissionsID;
    if (this.isDuplicate) {
      obj.fuelCommissionID = 0;
    }
    // obj.fuelCommissionscommissionsGUID = this.f.fuelCommissionscommissionsGUID.value;
    //obj.fuelCommissionscommissionsGUID = this.f.clientGUID.value;

    // obj.clientid = this.f.clientid.value;
    //  obj.customerID = this.f.clientid.value;

    if (this.iscountryorairport == 'country') {

      obj.countryID = this.f.countryid.value;
      obj.airportID = 0;// this.f.airportid.value;
    }
    else {

      obj.countryID = this.countryid;// 0;// this.f.countryid.value;
      obj.airportID = this.airportid;// this.f.airportid.value;
    }
    obj.lk_FuelSupplierGUID = this.f.fuelsupplier.value == 'default' ? null : this.f.fuelsupplier.value; 
   // obj.lk_FuelSupplierGUID = this.f.fuelsupplier.value;
    obj.intoPlaneAgent = this.f.intoplaneagent.value == 'other' ? this.f.otherintoplaneagent.value : this.f.intoplaneagent.value == 'default' ? 'All' : this.f.intoplaneagent.value;

    //obj

    //obj.countryid = this.f.countryid.value;
    //obj.airportid = this.f.airportid.value;// this.airportid;
    if (this.f.commissionAmt.value != 0) {
      obj.commissionAmt = this.f.commissionAmt.value;
    }
    else {
      return;
    }

    obj.currencyID = this.f.currency.value;
    obj.lk_UnitOfMeasureID = this.f.unitofmeasure.value;



    // obj.fuelCommissionscommissionsGUID = this.isExistingPrice == true ? this.fuelsupplier : this.f.fuelsupplier.value; 
    obj.effectiveDate = this.f.effectivedate.value;
    obj.expiryDate = this.f.expirydate.value;// == null ? 0 : this.f.unitofmeasure.value; this.unitofmeasure;// this.selectedunitofmeasure;//

    obj.internalNotes = this.f.internalnotes.value == null ? '' : this.f.internalnotes.value; 
    obj.isActive = this.f.isActive.value == '1' ? true : false;


    /*Maintain Duplicate when Adding new */
    //if (!this.isExistingPrice) {
    //  this.icao = this.f.icao.value; 
    //  this.effectivedate = this.f.effectivedate.value;
    //  this.expirydate = this.f.expirydate.value;
    //  this.currency = this.f.currency.value; 
    //  this.taxesIncluded = this.f.taxesincluded.value;// == 'true' ? true : false;
    //  this.internalnotes = this.f.internalnotes.value == null ? '' : this.f.internalnotes.value; 
    //}



    this._fuel.savefuelCommissions<ResponseModel<boolean>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {
          // this.fuelSupplierGUID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.controlValueChanged = false;
          let returnResponse = new ReturnObjModel();
          returnResponse.refresh = true;
          returnResponse.newId = this.fuelCommissionsID.toString();
          this.showSuccessMsg = true;


          // this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          this.isModified = true;

          //if (this.showModified == true && this.isDuplicate == true) {
          //  this.ifsuccess = true;
          //  this.successMsg = "Fuel Client commissions Duplicated Successfully.";
          //}
          if (this.showModified == true) {
            this.ifsuccess = true;
            this.successMsg = "Fuel Client commissions Updated Successfully.";
          }
          else {
            this.ifsuccess = true;
            this.successMsg = "Fuel Client commissions Saved Successfully.";
          }


          setTimeout(() => {
            this.showSuccessMsg = false;
            this.ifsuccess = false;
            //if (!this.isExistingPrice && this.isDuplicate == false) {
            // // this.clickDuplicate();
            //}
            //else {
            this.clickDuplicate();
            this.controlValueChanged = false;

            // this._dialogRef.close(returnResponse);
            //}
          }, 1000);


          //this._dialogRef.close(returnResponse);
          //}
        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg = "Fuel Supplier Code already exists.";
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;

    if (this.controlValueChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before close the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(response);
        }
      });

    }
    else {
      this._dialogRef.close(response);
    }
    //this._dialogRef.close(this.isModified);
  }

  countrychange() {
    this.setAllCountrySelection = true;
    this.f.setAllCountrySelection.setValue(true);


    this.f.icao.setValue(null);
    this.f.iata.setValue(null);

    this.f.icao.setValidators(null);
    this.f.iata.setValidators(null);
    //this.f.setAllCountrySelection.value ==true;

  }

  icaoValidation(e: any, isicaoiata: string) { // appending the updated value to the variable
    let length: number = 0;
    length = isicaoiata == 'icao' ? 4 : 3;
    if (e.target.value.length >= length) {
      let icao: string = e.target.value;
      this._fuel.getCountryDetailsByICAO<ResponseModel<AirportModel>>(icao).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let airportModel = new AirportModel();

            airportModel = response.model;
            //this.country = response.model;
            //   this.fuel_Client_commissions_edit.get('country').setValue(airportModel.countryName);

            if (isicaoiata == 'icao')
              this.fuel_Client_commissions_edit.get('iata').setValue(airportModel.iata);


            if (isicaoiata == 'iata')
              this.fuel_Client_commissions_edit.get('icao').setValue(airportModel.icao);

            //  this.icao = airportModel.icao;
            //this.iata = airportModel.iata;

            this.airportid = airportModel.airportID;
            this.countryid = airportModel.countryId;
            this.countryName = airportModel.countryName;
            this.countrylength = this.countryName.length;
            this.errMsg = '';
            //   this.isValidICAO = response.model;
            // this.initControls();
          }
          else {

            this.fuelsupplier = 'default';
            this.validateICAO = false;
          }

        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.errMsg = "Invalid ICAO.";
          }
        }
        this.loading = false;
      })
    }
    else {
      this.validateICAO = true;
      this.isValidICAO = false;
    }
  }

  clickDuplicate() {
    this.showDuplicateText = true;
    //this.showSuccessMsg = true;
    ////this.successMsg = "Duplicate Prices";
    //setTimeout(() => {
    //  this.showSuccessMsg = false;
    //}, 1000);
    this.dialogTitle = 'Add';
    this.isDuplicate = true
    this.isExistingPrice = false;
    this.isValidICAO = true;
    this.iscountryorairport = 'country';
    this.clientid = 0;
    // this.countryid = 265;
    this.commissionAmt = null;
    this.internalnotes = null;
    //this.minqty = null;
    //this.maxqty = null;
    //this.baseFuel = null;
    //this.fuelsupplierChange();
    // this.initControls();
    this.f.commissionAmt.reset();
    this.f.internalnotes.reset();

    this.checkFormControlValueChanged();
    this.submitted = false;
    //this.f.minqty.setErrors({ required: false });
    //this.f.maxqty.setErrors({ required: false });
    // this.clickSave();
  }

  fuelsupplierChange() {
    let request = new FuelCommissionsModel();
    this.fuelsupplier = this.f.fuelsupplier.value == 'default' ? this.fuelsupplier : this.f.fuelsupplier.value;
    request.lk_FuelSupplierGUID = this.fuelsupplier;// this.f.fuelsupplier.value;
    let ipa = new IntoPlaneAgentModel();
    ipa.intoPlaneAgent = "other";

    this.intoplaneagentList = [];
    this._fuel.getFuelCommissionsIntoPlaneAgentByFSID<ResponseModel<IntoPlaneAgentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          response.model.splice(0, 0, ipa);
          this.intoplaneagentList = response.model;

          if (this.hasSpecificValue(this.intoplaneagentList, 'All')) {
            this.intoplaneagentList = this.removeSpecificItem(this.intoplaneagentList, 'All');
          }
          else {
            let s: string = 'no';
          }


        }
        else {

          this.intoplaneagentList.push(ipa);
        }
      }
      else {
        this.msg = "";
      }
      this.loading = false;
      this.showSpin = false;

    })
  }

  intoplaneagentChange() {
    if (this.f.intoplaneagent.value == 'other') {
      this.isotherintoplaneagent = true;
      this.f.otherintoplaneagent.setValidators(Validators.required);

    }
    else {
      this.f.otherintoplaneagent.setValidators(null);
      this.isotherintoplaneagent = false;
    }


  }


  // Function to check if a specific value exists in the list of models
  hasSpecificValue(models: IntoPlaneAgentModel[], valueToCheck: string): boolean {
    return models.some(model => model.intoPlaneAgent === valueToCheck);
  }

  removeSpecificItem(models: IntoPlaneAgentModel[], valueToRemove: string): IntoPlaneAgentModel[] {
    return models.filter(model => model.intoPlaneAgent !== valueToRemove);
  }

}


