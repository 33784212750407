export class UtilityFunctions {

  static getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static base64ToArrayBuffer(base64: any): ArrayBuffer {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  static blobToBase64(blob) {
  return new Promise<string>((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve((<string>reader.result).split(',')[1]);
    reader.readAsDataURL(blob);
  });
}

  static addHours(d: Date, h: number): Date {  
    d.setTime(d.getTime() + (h * 60 * 60 * 1000));
    return d;
    
  }

  static addMinutes(d: Date, m: number): Date {
    d.setTime(d.getTime() + (m * 60 * 1000));
    return d;
  }

  static makeID(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
  }

  static convertLatLongFromDecimal(coordinate, isLatitude, returnType) {

    var s: string = "";
    var returnString: string;
    var tempdegree: number;
    var degree: number;
    var tempminute: number;
    var minute: number;
    var tempsecond: number;
    var second: number;


    tempdegree = Math.abs(coordinate);
    degree = Math.floor(tempdegree);
    tempminute = (tempdegree - degree) * 60;
    minute = Math.floor(tempminute);
    tempsecond = (tempminute - minute) * 60;
    second = Number(tempsecond.toPrecision(2));
    s = degree.toString() + '°' + minute.toString() + "'" + second.toString() + '"';
    if (coordinate > 0)
      s += isLatitude? ' N' : ' E';
    else
      s += isLatitude ? ' S' : ' W';
    switch (returnType) {
      case "degree":
        returnString = degree.toString();
        break;
      case "minute":
        returnString = minute.toString();
        break;
      case "second":
        returnString = second.toString();
        break;
      default:
        returnString = s;
        break;
    }
    return returnString;
  }

  static convertLatLongToDecimal(degree, minute, second, direction) {
    var decimal: number;
    decimal = degree + (minute / 60) + (second / 3600);
    if (direction == "S" || direction == "W")
      decimal = decimal * -1;
    return Number(decimal.toFixed(8));
  }

}
