export class PersonMailAddressModel {
  customerGUID: string;  
  personMailAddressGUID: string;
  personGUID: string;
  mailAddressTypeGUIDList: string;
  mailAddressTypeList: string;
  addressUseDescriptionList: string;
  displayDetails: string;
  fullAddress: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state_Province: string;
  postalCode: string;
  countryName: string;
  countryGUID: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  filterBy: string;
  textSearch: string;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  isPermanentAddress: boolean;
  isAPISAddress: boolean;
  dataEntryStatusID: number;
  dataEntryStatusDescription: string;
  filterDataEntryStatus: boolean;
}
