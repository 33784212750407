  <div style="  margin: -1em;">
  <!--width: 700px;-->
  <!--background-color: #f8f8f8;-->
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left: 1em; display: flex; width: 97% ;justify-content: space-between;; ">
      <h5 class="mat-h2" style="margin-bottom: 0.2em;">{{dialogTitle}} Taxes and Fees  </h5>
      <!-- <span style=" font-size: x-small; padding-top: 1em;">** This Includes Exchange Rate Markups</span> -->
    </div>

  </div>

  <div>
    <form *ngIf="taxesandfee_edit" [formGroup]="taxesandfee_edit">
      <div style=" margin-top:0em; padding-left: 1em;padding-right:1em">
        <div style="background-color:white;padding:.5em;">

          <div>
            <div style="background-color:#f8f8f8;border-radius:.25em;width: 100%;">
              <div>
                <label style="font-size:small">Select Country or Aiport</label>
              </div>
              <div style="display:flex">

                <div>
                  <mat-radio-group [disabled]="showModified" [(ngModel)]="iscountryorairport" style="display:flex;flex-direction:column;transform:scale(.8);" [ngModelOptions]="{standalone: true}">
                    <!--(change)="countryairportselect()"-->
                    <mat-radio-button [checked]="iscountryorairport=='country'" value="country">Country</mat-radio-button>
                    <mat-radio-button [checked]="iscountryorairport=='airport'" value="airport">Airport</mat-radio-button>
                  </mat-radio-group>
                </div>


                <div class="ml-2" style="padding-top: 1em;">

                  <div *ngIf="iscountryorairport=='country'" class="col"> 
                    <div style="margin-top:-0.35em;">

                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <select  [attr.disabled]="showModified ? '' : null"    [ngClass]="!showModified?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputDisabled'"
                                style="width: 26.6em;" formControlName="country">
                          <!--(change)="countryChange($event)"-->
                          <!--<option value="default">All</option>-->
                          <option *ngFor="let c of countrylist" [value]="c.countryID">{{c.countryName}}</option>
                        </select>
                        <div *ngIf="submitted && f.country.errors" class="alert alert-danger p-0 small">
                          <div *ngIf="f.country.errors.required">Please Select</div>
                        </div>
                      </div> 
                    </div>
                    <span style="  font-size: x-small;">**Taxes are applicable to <b>All Airports</b>  in the <b>Selected country</b>.</span>

                  </div>
                  <div class="col" *ngIf="iscountryorairport=='airport'" style="display: flex; margin-top: -0.7em;"> 
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                      <label for="icao" style="font-size:small">ICAO</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <input [readonly]="showModified" type="text" formControlName="icao"
                               [ngClass]="showModified?'form-control form-control-sm inputBox inputDisabled ':'form-control form-control-sm inputBox'" maxlength="255" style="width:6em" (keyup)="icaoValidation($event,'icao')" /><!--[ngClass]="{ 'is-invalid': submitted && f.icao.errors }"-->
                      </div>
                      <div *ngIf="submitted && f.icao.errors" class="alert alert-danger p-0 small">
                        <div *ngIf="f.icao.errors.required">ICAO is required.</div>
                      </div>
                    </div>
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0em;">
                      <label for="iata" style="font-size:small">IATA</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <input [readonly]="showModified" type="text" formControlName="iata"
                               [ngClass]="showModified?'form-control form-control-sm inputBox inputDisabled ':'form-control form-control-sm inputBox'" maxlength="255" style="width:6em" (keyup)="icaoValidation($event,'iata')" />
                      </div>
                      <div *ngIf="submitted && f.iata.errors" class="alert alert-danger p-0 small">
                        <div *ngIf="f.iata.required">IATA is required.</div>
                      </div>
                    </div>
                    <div *ngIf="countrylength>0" style=" padding-top: 1.2em; margin-left: -0.3em;">
                      <div style="background-color:#f8f8f8;border-radius:.25em" class="d-inline-flex ml-2 p-1">
                        <label style="font-size:small">Country:  <b>{{countryName}}</b></label>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--<div style="display:flex">
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="Country" style="font-size:small">Country</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <select *ngIf="f" formControlName="countrylist" class="form-control form-control-sm dropdown" style=" width: 12em" (change)="countryChange($event)">
                  <option *ngFor="let c of countrylist" [value]="c.countryGUId">{{c.countryName}}</option>
                </select>
              </div>
            </div>
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="Airport" style="font-size:small">Airport</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <select *ngIf="f" formControlName="selectairport" class="form-control form-control-sm dropdown">
                  <option *ngFor="let a of airportlist" [value]="a.airportId">{{t.airprotName}}</option>
                </select>
              </div>
              <div *ngIf="submitted && f.tafDesc.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.tafDesc.errors.required">Please Select</div>
              </div>
            </div>
          </div>-->
          <!--<div style="display:flex">
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="icao" style="font-size:small">ICAO</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <input type="text" formControlName="icao" class="form-control inputBox" maxlength="255" style="width:6em" (keyup)="icaoValidation($event,'icao')" />-->
          <!--[ngClass]="{ 'is-invalid': submitted && f.icao.errors }"-->
          <!--</div>
              <div *ngIf="submitted && f.icao.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.icao.errors.required">Airport Name is required.</div>
              </div>
            </div>
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="icao" style="font-size:small">IATA</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <input type="text" formControlName="iata" class="form-control inputBox" maxlength="255" style="width:6em" (keyup)="icaoValidation($event,'iata')" />
              </div>
              <div *ngIf="submitted && f.iata.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.icao.iata.required">Airport Name is required.</div>
              </div>
            </div>

            <div *ngIf="countrylength>0" style=" padding-top: 1.2em;">
              <div style="background-color:#f8f8f8;border-radius:.25em" class="d-inline-flex ml-2 p-1">
                <lable style="font-size:small"><b>Country:</b>  {{country}}</lable>
              </div>
            </div>
          </div>-->


          <div style="display:flex">
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="fuelsupplier" style="font-size:small">Fuel Supplier</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <!-- [attr.disabled]="!isExistingtaf ? '' : null"  -->
                <select formControlName="fuelsupplier" class="form-control form-control-sm dropdown" style=" width: 15em" (change)="fuelsupplierChange()">
                  <option value="default">All</option>
                  <option *ngFor="let fuel of fuelSupplierList" [value]="fuel.fuelSupplierGUID">{{fuel.fuelSupplierName}}</option>
                </select>

                <div *ngIf="submitted && f.fuelsupplier.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.fuelsupplier.errors.required">Please Select</div>
                </div>
              </div>
              <!--<div *ngIf="submitted && f.icao.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.icao.errors.required">Airport Name is required.</div>
              </div>-->
            </div>
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="country" style="font-size:small">Into Plane Agent</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <select formControlName="intoplaneagent" class=" form-control form-control-sm dropdown" (change)="intoplaneagentChange()" style="   width: 19em; margin-bottom: 0.2em">
                  <option value="default">All</option>
                  <option *ngFor="let ipa of intoplaneagentList" [value]="ipa.intoPlaneAgent">{{ ipa.intoPlaneAgent}}</option>
                </select>


                <!--<input [hidden]="!isExistingtaf" type="text" class="form-control form-control-sm inputBox" formControlName="intoplaneagent" />-->  <!--[ngClass]="!isExistingtaf?'form-control form-control-sm inputBox':'inputreadonly'"-->

                <input [hidden]="!isotherintoplaneagent" type="text" class="form-control form-control-sm inputBox" formControlName="otherintoplaneagent" />
                <div *ngIf="submitted && f.otherintoplaneagent.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.otherintoplaneagent.errors.required">Required.</div>
                </div>
              </div>
            </div>
          </div>


          <div style="display:flex; margin-top: -0.5em;">
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              
              <div style="display: flex;">
                <label for="country" style="font-size:small">Tax and Fee Description</label>
        
                <!-- <a *ngIf="!tafEdit && f.tafDesc.value != null" href="javascript:void(0)"  style="font-size:small; margin-left: 4em;" (click)="clickTAFdescEdit('edit')" >Edit</a>
                <a *ngIf="!tafEdit && f.tafDesc.value != null " href="javascript:void(0)"  style="font-size:small; margin-left: 1em;" (click)="clickTAFdescEdit('add')" >Add</a>
                <a *ngIf="!tafEdit  && f.tafDesc.value == null" href="javascript:void(0)"  style="font-size:small; margin-left: 6em;" (click)="clickTAFdescEdit('add')" >Add</a>
                <a *ngIf="tafEdit" href="javascript:void(0)"  style="font-size:small; margin-left: 2em;" (click)="clickTAFdescSave()" >Save</a>
                <a *ngIf="tafEdit" href="javascript:void(0)"  style="font-size:small; margin-left: 1em;" (click)="clickTAFdescCancel()" >Cancel</a>
               -->
              </div>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <select *ngIf="f && !tafEdit" formControlName="tafDesc" (change)="taxAndFeeDescOnChange($event)" class="form-control form-control-sm dropdown" style=" width: 19em;">
                  <option *ngFor="let t of taxandfeedesclist" [value]="t.lk_TaxFeeDescriptionID">{{t.taxFeeDescription}}</option>
                </select>

                <input *ngIf="tafEdit" type="text" formControlName="tafDescEdit"  class="form-control form-control-sm inputBox" style="width: 19em; height: 1.5625em; font-size: small; padding: 0em; border: none; " />




              </div>
              <div *ngIf="submitted && f.tafDesc.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.tafDesc.errors.required">Please Select</div>
              </div>
            </div>
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="currency" style="font-size:small">Currency</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <select *ngIf="f" formControlName="currency" class="form-control form-control-sm dropdown"  [attr.disabled]="isPercent ? '' : null"  [ngClass]="!isPercent?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputDisabled'"
                       style=" width: 15em" (change)="convertToUSD()">
                  <option *ngFor="let c of currencylist" [value]="c.currencyID"   >{{c.currencyID+' - ' + c.currencyDescription }} </option>
                  <!--<option *ngFor="let c of currencylist" [value]="c.lk_CurrencyID">{{c.currencyID}}</option>-->
                </select>
              </div>
              <div *ngIf="submitted && f.currency.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.currency.errors.required">Please Select</div>
              </div>
            </div>


          </div>

          <div style="display:flex; margin-top: -0.5em;">
            <div>
              <div style="display:flex">
                <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="minscale" style="font-size:small">Min Scale</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="number" [ngClass]="taxStandard=='2'?'form-control form-control-sm inputBox inputDisabled ':'form-control form-control-sm inputBox'" formControlName="minscale" style=" width:6em" />
                  </div>
                  <div *ngIf="submitted && f.minscale.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.minscale.errors.required">Required</div>
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="maxscale" style="font-size:small">Max Scale</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="number" [ngClass]="taxStandard=='2'?'form-control form-control-sm inputBox inputDisabled ':'form-control form-control-sm inputBox'" formControlName="maxscale" style=" width: 6em" />
                  </div>
                  <div *ngIf="submitted && f.maxscale.errors" class="alert alert-danger p-0 small">
                    <!--<div *ngIf=" f.maxscale.errors" class="alert alert-danger p-0 small">-->
                    <div *ngIf="f.maxscale.errors.required">Required</div>
                  </div>
                </div>
              </div>
              <div *ngIf="submitted && validateminmax" class="alert alert-danger p-0 small" style=" font-size: smaller;">
                <div *ngIf="validateminmax">Min should be less than Max.</div>
              </div>
            </div>
            <!--<div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="amount" style="font-size:small">Amount</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <input type="number" class="form-control form-control-sm inputBox" formControlName="amount" style=" width: 6em" />
              </div>
              <div *ngIf="submitted && f.amount.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.amount.errors.required">Required</div>
              </div>
            </div>-->
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="unitofmeasure" style="font-size:small">Unit of Measure</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <!-- [attr.disabled]="isNoTaxes ? '' : null"   [ngClass]="!isNoTaxes?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputDisabled'" -->
                <select  [attr.disabled]="isUOM ? '' : null"  [ngClass]="!isUOM?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputDisabled'"
                     *ngIf="f" formControlName="unitofmeasure" style=" width: 7em" class="form-control form-control-sm dropdown" (change)="convertToUSD()">
                  <!--[ngClass]="taxStandard=='2'?'form-control form-control-sm dropdown inputDisabled ':'form-control form-control-sm dropdown'"-->
                  <option  *ngFor="let t of unitofmeasurelist" [value]="t.lk_UnitOfMeasureID">{{t.unitOfMeasure}}</option>
                </select>
              </div>
              <div *ngIf="submitted && f.unitofmeasure.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.unitofmeasure.errors.required">Please Select</div>
              </div>
            </div>

            <div class="form-group" style="background-color: #f8f8f8; border-radius: 0.25em; margin: 0em; padding: 0 0.5em;">
              <label for="exemptfartype" style="font-size:small">Exempt FAR Type</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <!-- <input type="checkbox" (change)="exemptAllOnChange()" formControlName="exemptAll" /><label for="exemptAll" style="font-size:small">&nbsp;All</label>&nbsp; -->
                <!-- [attr.disabled]="f.exemptAll.value ? true : null" -->
                <input   type="checkbox" formControlName="exemptPart91" /><label     for="exemptPart91" style="font-size:small">&nbsp;Part 91</label>&nbsp;
                <input    type="checkbox" formControlName="exemptPart121" /><label     for="exemptPart121" style="font-size:small">&nbsp;Part 121</label>&nbsp;
                <input    type="checkbox" formControlName="exemptPart135" /><label     for="exemptPart135" style="font-size:small">&nbsp;Part 135</label>

                <!--<select formControlName="exemptfartype" class="form-control form-control-sm dropdown" (change)="fuelsupplierChange()">
    <option *ngFor="let e of farTypelist" [value]="e.farTypeDescription">{{e.farTypeDescription}}</option>
  </select>-->
              </div>
              <!--<div *ngIf="submitted && f.exemptfartype.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.exemptfartype.errors.required">Required</div>
              </div>-->
            </div>

          </div>

          <div style="display:flex">
            <div style="background-color: #f8f8f8; border-radius: .25em; margin: 0.5em 0 0 0; ">
              <div style="display: flex; ">
                <div>
                  <div>
                    <label style="font-size:small">Tax Structure</label>
                  </div>

                  <div>
                    <mat-radio-group  [disabled]="isNoTaxes"  [(ngModel)]="taxStandard"  style="display:flex;flex-direction:column;transform:scale(.8);" [ngModelOptions]="{standalone: true}" (change)="setValidation()">
                      <mat-radio-button [checked]="taxStandard=='1'" (change)="taxStructureOnChange($event)" value='1'>Per Unit</mat-radio-button>
                      <mat-radio-button [checked]="taxStandard=='2'" (change)="taxStructureOnChange($event)" value='2'>Flat Rate</mat-radio-button>
                      <mat-radio-button [checked]="taxStandard=='3'" (change)="taxStructureOnChange($event)" value='3'>Percent</mat-radio-button>
                    </mat-radio-group> <!--{{taxStandard}}-->
                  </div>

                </div>
                <div style=" margin-top: 2em;" class="ml-2">

                  <div *ngIf="taxStandard =='1'" class="col">
                    <label for="perUnitPrice" style="font-size:small">Per Unit</label>
                    <div style="margin-top:-0.35em;">
                      <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                        <input type="number" class="form-control form-control-sm inputBox" formControlName="perUnitPrice" style="width: 7em;" appDecimal [decimalPlaces]="5" (keyup)="convertToUSD()" />
                      </div>
                      <div *ngIf="submitted && f.perUnitPrice.errors" class="alert alert-danger p-0 small">
                        <div *ngIf="f.perUnitPrice.errors.required">Required</div>
                      </div>
                      <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                        Required
                      </span>
                    </div>
                  </div>

                  <div *ngIf="taxStandard =='2'" class="col">
                    <label for="flatfeeamount" style="font-size:small">Flat Rate</label>
                    <div style="margin-top:-0.35em;">
                      <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                        <input type="number" class="form-control form-control-sm inputBox" formControlName="flatfeeamount" style="width: 7em;" appDecimal [decimalPlaces]="5" (keyup)="convertToUSD()" />
                      </div>
                      <div *ngIf="submitted && f.flatfeeamount.errors" class="alert alert-danger p-0 small">
                        <div *ngIf="f.flatfeeamount.errors.required">Required</div>
                      </div>
                      <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                        Required
                      </span>
                    </div>
                  </div>

                  <div *ngIf="taxStandard =='3'" class="col">
                    <label for="flatratepercent" style="font-size:small">Percentage</label>
                    <div style="margin-top:-0.35em;">
                      <div style="margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em; margin-right: 0.25em; display: flex;">
                        <input type="number" class="form-control form-control-sm inputBox" formControlName="flatratepercent" style="width: 7em;" /><!--<span>%</span>-->
                      </div>
                      <div *ngIf="submitted && f.flatratepercent.errors" class="alert alert-danger p-0 small">
                        <div *ngIf="f.flatratepercent.errors.required">Required</div>
                      </div>
                      <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                        Required
                      </span>
                    </div>
                  </div>

                </div>
              </div>
              <div style="display: flex; ">
                <label for="calculateontotal" style="font-size:small">Calculate On Total</label>
                <div class="form-group" style="margin-left: 1.5em; margin-top:-0.35em; margin-bottom:0em">
                  <mat-slide-toggle style="transform:scale(.8); height:2em; " color="primary" [disabled]="disableCalculate || isNoTaxes" [checked]="calculateontotal" formControlName="calculateontotal"></mat-slide-toggle>
                </div>
              </div>
              <div style="display: flex; ">
                <label for="isDomestic" style="font-size:small">Is Domestic Flight</label>
                <div class="form-group" style="margin-left: 1.6em; margin-top:-0.35em; margin-bottom:0em">
                  <mat-slide-toggle style="transform:scale(.8); height:2em; " color="primary"   [checked]="isDomestic" formControlName="isDomestic"></mat-slide-toggle>
                </div>
              </div>
            </div>
            <div>
              <div style=" display: flex; padding: 1em 0 0 1em;">
                <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="effectivedate" style="font-size:small">Effective Date</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="date" class="form-control form-control-sm inputBox" formControlName="effectivedate" style=" width: 7em" />
                  </div>
                  <div *ngIf="submitted && f.effectivedate.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.effectivedate.errors.required">Required</div>
                  </div>
                </div>
                <div *ngIf="!hasNoExpiry" class="form-group" style="margin-top:0em; margin-right:0.625em">
                  <label for="expirydate" style="font-size:small">Expiry Date</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                    <input type="date" class="form-control form-control-sm inputBox" formControlName="expirydate" style=" width: 7em" />
                  </div>
                  <!--<div *ngIf="submitted && f.expirydate.errors" class="alert alert-danger p-0 small">
        <div *ngIf="f.expirydate.errors.required">Required</div>
      </div>-->
                </div>

              </div>
              <div *ngIf="f.expirydate.errors && f.expirydate.errors.matEndDateInvalid" class="alert-danger p-0" style="font-size:small;margin-top:-1em">Expiry date cannot be before effective date.</div>
              <span *ngIf="currencyConverted && !isNoTaxes && !isPercent" style="font-size: x-small; margin-left: 1em; ">Currency: <b>{{currencydesc}}</b>   Date: <b>{{currencyDate}}</b></span>
              <table *ngIf="currencyConverted && !isNoTaxes && !isPercent" class="tableConverter" style="font-size: small; margin-left: 1em; padding: 1em; width: 15em; height: 6.3em; text-align: center; ">
                <tr style="background: #eaf0fd;"><td colspan="2"><b>Currency Converter</b></td></tr>
                <tr><td> {{ originalPrice }}</td><td> <b>{{currencyconverter1}}</b></td></tr>
                <tr><td>{{USDConversion  }}</td><td><b>{{currencyconverter2  }}</b></td></tr>
              </table>
              <span  *ngIf="currencyConverted && !isNoTaxes && !isPercent" style=" font-size: x-small; padding-top: 1em;">** This Includes Exchange Rate Markups</span>

            
              <!--<div *ngIf="currencyConverted" style=" font-size: small; margin-left: 1em;">
                <div style=" border-bottom: 1px solid; text-align: center; ">Currency Converter</div>
                <div>
                  {{ originalPrice }}
                </div>
                <div>
                  {{currencyconverter1}}
                </div>
                <div>{{USDConversion  }}</div>
                <div> {{currencyconverter2  }}</div>
              </div>-->
            </div>
            <!--<div style="  padding: 1em 0 0 1em;">
              <div style="margin-left: 0em; margin-top: 1.3em; margin-right: 0.625em; display: flex;">
                 <div>
                  <input type="checkbox" formControlName="hasNoExpiry" (change)="hasnoexpirychange($event)" /><label for="hasExpiry" style="font-size:small">&nbsp;No Expiry</label>
                </div>
              </div>
            </div>-->
            <!--<label for="hasnoexpirydate" style="font-size:small">Has No Expirydate</label>-->
            <!--<div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="flatfeeamount" style="font-size:small">Flat Fee Amount</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <input type="number" class="form-control form-control-sm inputBox" formControlName="flatfeeamount" style="width: 7em;" />
              </div>
              <div *ngIf="submitted && f.flatfeeamount.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.flatfeeamount.errors.required">Required</div>
              </div>
            </div>

            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="flatratepercent" style="font-size:small">Flat Rate Percent</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <input type="number" class="form-control form-control-sm inputBox" formControlName="flatratepercent" style="width: 7em;" />
              </div>
              <div *ngIf="submitted && f.flatratepercent.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.flatratepercent.errors.required">Required</div>
              </div>
            </div>-->
          </div>



          <!--<div  >-->
          <!--<div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
            <label for="hasnoexpirydate" style="font-size:small">Has No Expirydate</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
              <mat-slide-toggle style="transform:scale(.8); height:2em; " color="primary" [checked]="hasnoexpirydate" formControlName="hasnoexpirydate" (change)="hasnoexpirychange($event)"></mat-slide-toggle>
            </div>
          </div>-->
          <!--<div class="form-group" style="margin-top:0em; margin-right:0.625em">
            <label for="autocalculate" style="font-size:small">Expiry Date</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
              <mat-slide-toggle style="transform:scale(.8); height:2em; " color="primary" [checked]="autocalculate" formControlName="autocalculate"></mat-slide-toggle>
            </div>
          </div>-->
          <!--<div class="form-group" style="margin-top:0em; margin-right:0.625em">
            <label for="flatrate" style="font-size:small">Flat Rate</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
              <mat-slide-toggle style="transform:scale(.8); height:2em; " color="primary" [checked]="flatrate" formControlName="flatrate"></mat-slide-toggle>
            </div>
          </div>
          <div class="form-group" style="margin-top:0em; margin-right:0.625em">
            <label for="flatfeeamt" style="font-size:small">Flat Fee Amount</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
              <mat-slide-toggle style="transform:scale(.8); height:2em; " color="primary" [checked]="flatfeeamt" formControlName="flatfeeamt"></mat-slide-toggle>
            </div>
          </div>-->
          <!--<div class="form-group" style="margin-top:0em; margin-right:0.625em">
            <label for="calculateontotal" style="font-size:small">Calculate On Total</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
              <mat-slide-toggle style="transform:scale(.8); height:2em; " color="primary" [checked]="calculateontotal" formControlName="calculateontotal"></mat-slide-toggle>
            </div>
          </div>-->
          <!--</div>-->
          <!--<select *ngIf="f" formControlName="formula" class="form-control form-control-sm dropdown">
            <option *ngFor="let t of formulalist" [value]="f.Lk_Formulais">{{f.Lk_FormulaDescription}}</option>
          </select>-->
          <div style="display:flex">

            <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; width: 100%;">
              <label for="internalnotes" style="font-size:small">Supplier Notes</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <textarea formControlName="internalnotes" class="form-control"  style="resize: none; height: 5em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd;  "></textarea>
              </div>
            </div>
            <!--<div class="form-group" style="margin-top: 0em; margin-right: 0.625em; width: 50%;">
              <label for="clientnotes" style="font-size:small">Client Notes</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <textarea formControlName="clientnotes" class="form-control"  style="resize: none; height: 5em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd; "></textarea>
              </div>
            </div>-->
          </div>
          <!--<div style="display:flex">
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="clientnotes" style="font-size:small">Client Notes</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <textarea formControlName="clientnotes" class="form-control"  style="resize: none; height: 5em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd; width: 35em;"></textarea>
              </div>
            </div>
          </div>-->

        </div>



        <div *ngIf="isExistingtaf">

          <div style="background-color: white; padding: .3em 0em 0 .3em; display: inline-block; width: 36%; ">
            <!--margin-top: 1em;text-align: center;-->
            <div class="d-inline-flex">
              <div class="no-gutters">

                <!--<div class="col">
                  <label style="font-size:small">Taxes And Fees Id</label>
                  <div style="margin-top: -0.35em; display: inline-block; width: 3.6em; margin-left: 0.6em;">
                    <input class="form-control form-control-sm inputBox  inputreadonly" readonly formControlName="taxesandfeesid" />
                  </div>
                </div>-->
                <div class="col">
                  <label style="font-size:small">Disable Record</label>
                  <div style="margin-top: -0.35em; display: inline-block;">
                    <select formControlName="isActive" class="form-control form-control-sm dropdown" style="margin-left: 1.9em; ">
                      <option value="1">No</option>
                      <option value="0">Yes</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div style="display: inline-block; padding-left:  5.9em; ">
            <div style="border-radius:.25em" class="d-inline-flex ml-2 p-1;">

              <table class="table" style="text-align: left; margin: 0em;">
                <!--*ngIf="showModified"-->
                <tr>
                  <td> <label for="modifiedBy" style="font-size: small; float: left;">Modified By : </label></td>
                  <td>
                    <div style="margin-top:-0.5em">
                      <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width: 8em; height: 1.5625em; font-size: small; padding: 0em; border: none; background-color: transparent;" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><label for="modifiedDate" style="font-size: small; margin-right: 1.5em;">Modified Date : </label></td>
                  <td>
                    <div style="margin-top:-0.5em">
                      <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width: 8em; height: 1.5625em; font-size: small; padding: 0em; border: none; background-color: transparent;" />
                    </div>
                  </td>
                </tr>
              </table>

            </div>
          </div>
        </div>

      </div>
    </form>

  </div>
  <div class="d-inline-flex" style=" margin-top: -0.5em;">
    <div style="justify-content: flex-start; padding-left: 1em; padding-top: 0.5em; padding-bottom: 0.5em; min-width: 33%;">
      <!--*ngIf="!ifsuccess"-->
      <button formcontrolname="savebutton" [disabled]="!controlValueChanged   ? 'disabled' : null" mat-button class="btn-high" (click)="clickSave()">
        SAVE
      </button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()">
        <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">close</span>CLOSE
      </button>&nbsp;

      <button *ngIf="dialogTitle == 'Add'"  [disabled]="!controlValueChanged   ? 'disabled' : null" mat-button class="btn-high" (click)="saveAndDuplicate()">Save And Duplicate
      </button>&nbsp;

      <button *ngIf="isExistingtaf" formcontrolname="duplicatebutton" mat-button class="btn-high"   (click)="clickDuplicate()">
        &nbsp;
        <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">content_copy</span>Duplicate
      </button>&nbsp;
    </div>
    <div class="mt-2">
      <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display: inline-block; white-space: pre-wrap;">
        <label style="font-size: small; padding: 0em; margin: 0em;">{{errMsg}}</label>
      </div>
      <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]="successMsg"></app-success-message>
      </div>
    </div>
  </div>
  <div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>





