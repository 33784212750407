<div *ngIf="upPII" style="background-color:#f8f8f8;min-height:28em">
  <div style="display: flex; justify-content: space-between;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>Documents for Task {{serviceTypeDescription}}</h5></div>

  </div>
  <div>
    <form *ngIf="task_doc_selection_form" [formGroup]="task_doc_selection_form">
      <div style="padding-left: 1em; padding-right:1em">
        <div style="height: 23em;  background-color: white; padding-top:0.5em">

          <div style="margin-left:1em;padding-right: 1em; margin-top:0em;min-height:10em; min-width: 30em; overflow-y:auto">
            <table mat-table *ngIf="taskDocList" [dataSource]="taskDocList" class="example-container2 mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Select</div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="checkDocChange($event, element)"
                                  [checked]="selection.isSelected(element)" style="margin-top:0.25em; transform: scale(0.75)"></mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="documentName">
                  <th mat-header-cell *matHeaderCellDef style="width:90%; border-bottom:none; text-align: left !important">
                    Document Name
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: left !important"><a style="color:blue; cursor:pointer; text-decoration:underline" (click)="clickPreviewDocument($event, element)">{{element.documentName}}</a>&nbsp;({{element.documentSizeInKB}}KB)</td>
                </ng-container>

              </tr>

              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>
            <div *ngIf="taskDocList.length==0" style="margin-left:0em;">No task documents returned</div>
          </div>          
          <div *ngIf="errMsg!=''" class="d-block" style="margin-top:0em; margin-left:1em; display:flex; background-color:#f9d2de; padding-left:0.25em; max-width:30em;font-size: small ">{{errMsg}}</div>
        </div>
      </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;">
    <button mat-button class="btn-high" *ngIf="taskDocList.length>0" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Select</div></div></button>&nbsp;
    <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
  </div>
</div>
