import { GroundStopTaskDocumentModel } from "./ground-stop-task-document.model";
import { TripSummaryLegModel } from "./trip-summary-leg.model";
import { VendorModel } from "./vendor.model";

export class TripSummaryModel {
  tripCodeGUID: string;
  groundStopTaskGUID: string;
  selectServiceTypeIDs: string;
  selectedGroundStopList: string[];
  tripID: number;
  customerName: string;
  minDate: string;
  maxDate: string;
  tripRoute: string;
  reportID: string;
  coverPage: GroundStopTaskDocumentModel;
  reportDate: string;
  tripSummaryLegs: TripSummaryLegModel[];
  tripSummaryDocument: GroundStopTaskDocumentModel;
  tripCode: string;
  registration: string;
  handlingVendorGUIDs: string[];
  handlingNotes: VendorModel[];
}
