import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Component, Inject, Injector, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CategoryModel } from '../models/category.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CONTAINER_DATA, HelpGuideService } from '../services/help-guide.service';
import { HelpGuideTopicsComponent } from './help-guide-topics.component';
import { HelpGuidesComponent } from './help-guides.component';
import { MessageService } from '../services/message.service';
import { MessageModel } from '../models/message.model';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NgbAccordion, NgbPanel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-help-guide-categories',
  templateUrl: './help-guide-categories.component.html',
  styleUrls: ['./help-guide-categories.component.css']
})

export class HelpGuideCategoriesComponent implements OnInit {
  categoryList: CategoryModel[];
  topicPortal: ComponentPortal<HelpGuideTopicsComponent>;
  type: string;
  feedbackMsg: string;
  activePanels: string = "custom-panel-1,fbPanel";
  showSuccessMsg: boolean = false;

  @ViewChild(NgbAccordion) accordion: NgbAccordion;

  constructor(private readonly _authService: AuthenticateService, private readonly _helpGuideService: HelpGuideService,
    private readonly _helpGuide: HelpGuidesComponent, private _injector: Injector, @Inject(CONTAINER_DATA) public componentData: any,
    private readonly _messageService: MessageService,private readonly _dialogRef: MatDialogRef<HelpGuideCategoriesComponent>) {
    this.type = componentData;
  }
    ngOnInit() {
      this._authService.updateAccessTime();
      let audienceID;
      if (localStorage.getItem('ut').toLowerCase() == 'i')
        audienceID = 1;
      else
        audienceID = 2;
      if (this.type == 'client')
        audienceID = 2; 
      this._helpGuideService.getCategoryListByAudienceID<ResponseModel<CategoryModel[]>>(audienceID).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.categoryList = response.model;
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }

          }
        }
      });
    }

  showDetails(category:CategoryModel) {
    this.topicPortal = new ComponentPortal(HelpGuideTopicsComponent, null, this.createInjector(category));
    this._helpGuide.selectedPortal = this.topicPortal;
   
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  submitFeedback() {
    if(!this.feedbackMsg) return;
    let email: MessageModel = new  MessageModel();
    email.body = this.feedbackMsg;
    email.subject = "Feedback from FPC";
    
    this._messageService.sendFeedbackEmail<ResponseModel<boolean>>(email).subscribe(resp=>{
      
      if (resp.code == "200" && resp.model) {
        this.showSuccessMsg = true;
        setTimeout(() => {
          this.showSuccessMsg = false;
          this.feedbackMsg = '';
          this.accordion.collapse("fbPanel");
        }, 2000);
      }
      else
        alert("feedback not sent");
    });
  }

}
