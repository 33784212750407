<div *ngIf="upPII" style="background-color:#f8f8f8; overflow-x:hidden; overflow-y:hidden"  >
  <!--<div style="background-color:#f8f8f8;">-->
  <div style="display: flex; padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3em;"><i class="material-icons-outlined" style="font-size: x-large;cursor: pointer; color: gray" >task_alt<!--flight--></i></div>
    <div style="margin-top:0.3125em;display:flex"><h5>Add new Services</h5>&nbsp;-&nbsp;<div style="font-size: small; font-weight:400; margin-top:0.25em">{{airportInfo}}</div></div>
  </div>
  <div>
    <form *ngIf="ground_stop_service_type_selection_form" [formGroup]="ground_stop_service_type_selection_form">
      <div style="display:flex; margin-top:0em; padding-left: 1em; padding-right:1em">
        <div style="margin-top:0em;background-color:white; padding-left:0.5em;">
          <div >
            <div class="example-action-buttons">
              <button mat-button (click)="openAll()" color="primary" style="outline:none; font-size: small">Expand All</button>
              <button mat-button (click)="closeAll()" color="primary" style="outline: none; font-size: small">Collapse All</button>
            </div>
            <div style="overflow-y:auto;height: 30em;">
              <mat-accordion class="example-headers-align" [multi]="true">
                <mat-expansion-panel *ngFor="let item of serviceClassList, let i=index" [expanded]="item.panelOpenState" (opened)="item.panelOpenState=true" (closed)="item.panelOpenState=false">
                  <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'" style="font-weight:500; font-size:medium !important">
                    <mat-panel-title>
                      <div id="panel_{{i}}" #focusOnThisPanel>
                        {{item.serviceClassDescription}}
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="min-height: 15.5em; max-height: 17em;overflow-y: auto;overflow-x:hidden;">
                    <table mat-table *ngIf="item.serviceTypeList" [dataSource]="item.serviceTypeList" class="example-container mat-elevation-z8">
                      <tr>
                        <ng-container matColumnDef="check">
                          <th mat-header-cell *matHeaderCellDef style="width:10%;">
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-left:0.0625em solid lightgrey !important">
                            <div style="margin-top:0.5em">
                              <mat-checkbox (click)="$event.stopPropagation()" (change)="checkServiceTypeChange($event, element)" [checked]="selection.isSelected(element)"></mat-checkbox>
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="serviceType">
                          <th mat-header-cell *matHeaderCellDef style="text-align: left !important">
                            <div style="margin-left: 0.5em">Available Services</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">{{element.serviceTypeDescription}}</td>
                        </ng-container>
                        <ng-container matColumnDef="qty">
                          <th mat-header-cell *matHeaderCellDef style="text-align: left !important">
                            <div style="margin-left: 0.5em">Qty</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left !important; border-right:0.0625em solid lightgrey !important">
                            <div>
                              <input type="number" min="1" max="10" matInput maxlength="2" [value]="element.quantity" [(ngModel)]="element.quantity" [ngModelOptions]="{standalone: true}" class="form-control inputBox" style="width:3em;border-radius:0.25em" (keyup)="qtyChange($event, element)" />
                            </div>
                          </td>
                        </ng-container>
                      </tr>
                      <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                    </table>
                  </div>
                  <mat-action-row>
                    <button mat-button [hidden]="i==0" color="primary" (click)="prevStep(i)" style="font-size: small">Previous</button>
                    <button mat-button color="primary" (click)="nextStep(i)" style="font-size: small">{{i<(serviceClassList.length-1)? 'Next': 'End'}}</button>
                  </mat-action-row>

                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div style="display: flex; margin-top:0.5em">
            <div style="margin-right: 0.5em;margin-top:0em; display:flex;">
              <mat-checkbox (click)="$event.stopPropagation()" formControlName="saveToAllStops"></mat-checkbox>
              <div style="margin-top:-0.25em"><label for="saveToAllStops" style="font-size:small">&nbsp;All Stops</label></div>
            </div>
            <!--<div style="margin-left: 0em;margin-top:0em; display:flex;">
              <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkVendorAssignment"></mat-checkbox>
              <div style="margin-top:-0.25em"><label for="checkVendorAssignment" style="font-size:small">&nbsp;Allow Business Rules</label></div>
            </div>-->
          </div>
            
        </div>
      </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; display: flex;height:3em">
    <div style="margin-right:1em">
      <button class="btn btn-primary" *ngIf="!lockdownTrip && !showSpin" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Add new Services</div></div></button>&nbsp;
      <button class="btn btn-secondary" (click)="clickClose()" style="background-color:grey; color: white;"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
    </div>
    <div *ngIf="errMsg!=''" class="d-block" style="margin-left:1em; display:flex;margin-top:-0.5em;background-color:#f9d2de; padding-left:0.25em; width:16em;"><label style="color:black; font-size: small">{{errMsg}}</label></div>
    <div *ngIf="showSuccessMsg" >
      <app-success-message [successMsg]=""></app-success-message>
    </div>
  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>
