import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { UploadTaskDocument } from '../ground-stop-advanced/upload-task-document';
import { CustomerModel } from '../models/customer.model';
import { FlightBriefModel } from '../models/flight-brief.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { MessageTemplateModel } from '../models/message-template.model';
import { MessageTextModel } from '../models/message-text-model';
import { ResponseModel } from '../models/response.model';
import { TripModel } from '../models/trip.model';
import { WeatherChartsModel } from '../models/weather-charts.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopService } from '../services/ground-stop.service';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { DocumentModel } from '../models/document.model';


function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
@Component({
  selector: 'app-weather-charts-dialog',
  templateUrl: './weather-charts-dialog.component.html',
  styleUrls: ['./weather-charts-dialog.component.css']
})
/** generate-message-template component*/
export class WeatherChartsDialogComponent implements OnInit {
  chartRegionList: WeatherChartsModel[];
  chartCategoryList: WeatherChartsModel[];
  chartTypeList: WeatherChartsModel[];
  chartTimeRangeList: WeatherChartsModel[];
  allChartCategoryList: WeatherChartsModel[];
  allChartTypeList: WeatherChartsModel[];
  allChartTimeRangeList: WeatherChartsModel[];
  chartURL: string;
  weatherCharts: WeatherChartsModel[];
  selectedRegionList: WeatherChartsModel[] = [];
  selectedCategoryList: WeatherChartsModel[] = [];
  selectedTypeList: WeatherChartsModel[] = [];
  selectedTimeRangeList: WeatherChartsModel[] = [];
  chartList: WeatherChartsModel[]=[];
  selChartList: WeatherChartsModel[];
  availChartList: WeatherChartsModel[];
  showSuccessMsg: boolean = false;
  isLoading: boolean = false;
  isLoadingWord: boolean = false;
  isLoadingPDF: boolean = false;
  showSpin: boolean = false;
  hasChanges: boolean = false;
  selectedCharts: WeatherChartsModel[];
  weather_charts_form: UntypedFormGroup;
  @Input() gstId: string;
  @Input() weatherChartIds: string;
  errMsg: string;
  submitted: boolean = false;
  displayedColumn: string[] = ['chartURL','preview'];

  /** generate-message-template ctor */
  constructor(private readonly _authService: AuthenticateService, private readonly _groundStopService: GroundStopService, private readonly _groundStopAdvanceService: GroundStopAdvancedService, private readonly _customerService: CustomerService,
    private readonly _commonService: CommonService, private readonly _formBuilder: UntypedFormBuilder, private readonly _dialogRef: MatDialogRef<WeatherChartsDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _httpClient:HttpClient) {
    this.gstId = _data.gstId;
   // this.weatherChartIds = _data.weatherChartIds;
  }
  ngOnInit() {
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.initControls();    
    this.getData();
  }

  getData() {
    this._groundStopService.getWeatherChartLinks<ResponseModel<WeatherChartsModel[]>>(this.gstId).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.weatherCharts = response.model;
          this.chartRegionList = this.weatherCharts.filter((n, i, arr) => arr.findIndex(c => c.weatherChartRegionID === n.weatherChartRegionID) === i);
          this.chartList = this.weatherCharts;
          this.availChartList = this.weatherCharts;
          if (this.weatherChartIds.length > 0) {
            this.chartList.forEach(x => {
              if (x.weatherChartID.toString().indexOf(this.weatherChartIds) !== -1) {
                x.selected = true;
              }
            });
          }
          this.selectedCharts = this.chartList.filter(v => v.selected == true);
          this.setChartList();
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
        
        }
      }
      this.showSpin = false;
    });
    this.checkFormControlValueChanged();

  }


  checkFormControlValueChanged(): void {
    this.weather_charts_form.valueChanges.subscribe(x => {
      this.hasChanges = true;
    });
  }


  initControls() {
    this.weather_charts_form = this._formBuilder.group({

    });
  }

  get f() { return this.weather_charts_form.controls; }
    
  onRegionChange(options:MatListOption[]) {
    this.selectedRegionList = options.map(o => o.value);
    if (this.selectedRegionList.length > 0) {
      this.allChartCategoryList = [];
      this.selectedRegionList.forEach(v => {
        this.allChartCategoryList = this.allChartCategoryList.concat(this.weatherCharts.filter(item => item.weatherChartRegion.indexOf(v.weatherChartRegion) !== -1));
        this.availChartList = this.allChartCategoryList;
      });
      this.chartCategoryList = this.allChartCategoryList.filter((n, i, arr) => arr.findIndex(c => c.weatherChartCategoryID === n.weatherChartCategoryID) === i);
    }
    else {
      this.selectedCategoryList = [];
      this.selectedTypeList = [];
      this.selectedTimeRangeList = [];
    }
    
  }

  onCategoryChange(options: MatListOption[]) {
    this.selectedCategoryList = options.map(o => o.value);
    if (this.selectedCategoryList.length > 0) {
      this.allChartTypeList = [];
      this.selectedCategoryList.forEach(v => {
        this.allChartTypeList = this.allChartTypeList.concat(this.allChartCategoryList.filter(item => item.weatherChartCategory.indexOf(v.weatherChartCategory) !== -1));
        this.availChartList = this.allChartTypeList;
      });
      this.chartTypeList = this.allChartTypeList.filter((n, i, arr) => arr.findIndex(c => c.weatherChartTypeID === n.weatherChartTypeID && n.weatherChartTypeID != null) === i);

    }
    else {
      this.availChartList = this.allChartCategoryList;
      this.selectedTypeList = [];
      this.selectedTimeRangeList = [];
    }
    
    this.setChartList();

  }

  onTypeChange(options: MatListOption[]) {
    this.selectedTypeList = options.map(o => o.value);
    if (this.selectedTypeList.length > 0) {
      this.allChartTimeRangeList = [];
      this.selectedTypeList.forEach(v => {
        this.allChartTimeRangeList = this.allChartTimeRangeList.concat(this.allChartTypeList.filter(item => item.weatherChartType.indexOf(v.weatherChartType) !== -1));
        this.availChartList = this.allChartTimeRangeList;
      });
      this.chartTimeRangeList = this.allChartTimeRangeList.filter((n, i, arr) => arr.findIndex(c => c.weatherChartTimeRangeID === n.weatherChartTimeRangeID && n.weatherChartTimeRangeID != null) === i);
    }
    else {
      this.availChartList = this.allChartTypeList;
      this.selectedTimeRangeList = [];
    }

    this.setChartList();

  }

  onTimeRangeChange(options: MatListOption[]) {
    this.selectedTimeRangeList = options.map(o => o.value);   
    if (this.selectedTimeRangeList.length > 0) {
      this.availChartList = [];
      this.selectedTimeRangeList.forEach(v => {
        this.availChartList = this.availChartList.concat(this.allChartTimeRangeList.filter(item => item.weatherChartTimeRange.indexOf(v.weatherChartTimeRange) !== -1));

      });
    }
    else {
      this.availChartList = this.allChartTimeRangeList;
    }

      this.setChartList();
  }

  clickAddItem(e: any, item: WeatherChartsModel) {
    this.chartList.forEach(x => {
      if (x.weatherChartID == item.weatherChartID) {
        x.selected = true;
      }
    });
    this.setChartList();

  }

  clickRemoveItem(e: any, item: WeatherChartsModel) {
    this.chartList.forEach(x => {
      if (x.weatherChartID == item.weatherChartID) {
        x.selected = false;
      }
    })
    this.setChartList();

  }

  setChartList() {
    this.errMsg = "";
    this.availChartList = this.availChartList.filter(u => u.selected == false);
    this.selChartList = this.chartList.filter(u => u.selected == true);
    if (this.selectedCharts.filter(item => this.selChartList.indexOf(item) < 0).length == 0 && this.selChartList.filter(item => this.selectedCharts.indexOf(item) < 0).length==0)
      this.hasChanges = false;
    else
      this.hasChanges = true;
  }

  clickSelectAll() {
    this.availChartList.forEach(x => {
      x.selected = true;
    })

    this.setChartList();
  }

  clickRemoveAll() {
    this.chartList.forEach(x => {
      x.selected = false;
    })

    this.setChartList();
  }

  saveWeatherCharts(showSuccess: boolean) {
    this.isLoading = true;
    let request = new FlightBriefModel();
    request.groundStopTaskGUID = this.gstId;
   // request.weatherCharts = Array.prototype.map.call(this.selChartList, s => s.weatherChartID).toString();
    request.weatherCharts = [];
    this.selChartList.forEach(v => {
     request.weatherCharts.push(v.weatherChartID.toString());
    });
    this._groundStopAdvanceService.saveFlightBriefWxCharts<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.isLoading = false;
          this.hasChanges = false;
          if (showSuccess) {
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
            }, 1000);
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          else {
            this.isLoading = false;
            this.errMsg="An error has occurred."
          }

        }
      }
    });
  }

  createDoc(format:string) {
    this.saveWeatherCharts(false);
    if (format == "wxword")
      this.isLoadingWord = true;
    else
      this.isLoadingPDF = true;
    
    let request = new FlightBriefModel()
    request.weatherCharts = request.weatherCharts = [];
    this.selChartList.forEach(v => {
      request.weatherCharts.push(v.weatherChartURL);
    });
    let filePath = "";
    filePath = window.origin + "/assets/templates/BlankDoc.docx";
    //filePath = "https://localhost:5001/assets/templates/BlankDoc.docx";
    var self = this;
    loadFile(filePath, function (
      error,
      content
    ) {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);
      request.docFormat = format;
      const out = zip.generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });

      var reader = new FileReader();

      reader.readAsDataURL(out);
      reader.onloadend = function () {
        let taskDocument = new DocumentModel();
        var doc = reader.result;
        taskDocument.bFile = doc.toString().split(',')[1]; //(<string>reader.result).split(',')[1];
        taskDocument.mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        request.flightBriefDocument = taskDocument;
        self._groundStopAdvanceService.compileFlightBrief<ResponseModel<FlightBriefModel>>(request).subscribe(response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              let obj = response.model.flightBriefDocument;
              let mimeType = "";
              if (format == "wxword")
                mimeType = "application / vnd.openxmlformats - officedocument.wordprocessingml.document";
              else
                mimeType = "application/pdf";
              let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
              let file = new Blob([byteArray], { type: mimeType });

              if (format == 'wxword') {
                saveAs(file, "WeatherCharts.docx");
                self.isLoadingWord = false;
              }
              else {               
                  let upload = new UploadTaskDocument();
                  upload.uploadFile(file, self.gstId, "WeatherCharts.pdf", self._groundStopAdvanceService, undefined);
                  var fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
                  self.isLoadingPDF = false;
            //    });



              }
            }
            else {

              self.isLoadingWord = false;
              self.isLoadingPDF = false;
              if (response.code == "401") {
                self._authService.signOut();
              }

            }
          }


        });
      }
    });
  }

  

}
  


