<div *ngIf="grantAccessToManageManifestProfiles" style="background-color:#f8f8f8;height:28em" >
  <div style="display: flex; justify-content: space-between;" cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>Travel Document - Passport</h5></div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
    <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
    </div>-->
  </div>
  <div>
    <div style="margin-top:0em; padding-left: 1em; padding-right:1em">
      <div style="margin-top: 0em; background-color: white; padding-left: 0.5em; height: 23em">
        <div style="display:flex; margin-left:0.5em;">
          <div style="height: 21em;width:21em; overflow-y: auto;overflow-x:hidden;margin-top:1em; margin-right:1em">
            <table mat-table *ngIf="avaiCountryList" [dataSource]="avaiCountryList" class="example-container2 mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="country">
                  <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                    Choose Countries
                  </th>
                  <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickAddItem($event, element)">{{element.countryName}}</a></td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>
            
          </div>
          <div style="margin-top: 2em;margin-left:0em">
            <button type="button" mat-button class="btn-high" [hidden]="avaiCountryList && avaiCountryList.length==0" (click)="clickSelectAll()" style="width: 7em">Select All</button><br /><br />
            <button type="button" mat-button class="btn-low" [hidden]="selCountryList && selCountryList.length==0" (click)="clickRemoveAll()" style="width: 7em">&nbsp;Remove All&nbsp;</button>
          </div>
          <div style="margin-left:1em;height: 21em;width:21em; overflow-y: auto;overflow-x:hidden; margin-top:1em">
            <table mat-table *ngIf="selCountryList" [dataSource]="selCountryList" class="example-container2 mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="country">
                  <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                    Selected Countries
                  </th>
                  <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickRemoveItem($event, element)">{{element.countryName}}</a></td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>
            
          </div>
        </div>

      </div>
    </div>
  </div>
   <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; margin-left:1em">

    <button mat-button class="btn-low" (click)="clickClose()" >Close</button>&nbsp;

  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
