import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { AdvisoryLookupModel } from '../models/advisory.model';
import { HotelModel } from '../models/hotel.model';

@Component({
  selector: 'app-advisory-hotels',
  templateUrl: './advisory-hotels.component.html',
  styleUrls: ['./advisory-hotels.component.css']
})

export class AdvisoryHotelsComponent implements OnInit {

  advisory_hotels_form: UntypedFormGroup;
  isDialog: boolean = false;

  isAirport: boolean;
  displayedColumn: string[] = ['select', 'hotel', 'distance', 'address'];
  hotelList: HotelModel[] = [];
  filteredHotel: HotelModel;

  title: string = '';
  dialogTitle: string = '';
  selectedhotelGUID: string; //= 'd43db1f2-8257-4816-a863-cce854cfffef';

  constructor(@Optional() private readonly _dialogRef: MatDialogRef<AdvisoryHotelsComponent>, private readonly _commonService: CommonService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any, private readonly _authService: AuthenticateService,
    private readonly _formBuilder: UntypedFormBuilder,
  ) {

    if (this.data) {


      this.hotelList = data.hotelList;
      this.filteredHotel = data.filteredHotel;
      this.selectedhotelGUID = this.filteredHotel?.hotelGUID;


    }

  }

  ngOnInit() {

    // this._authService.updateAccessTime();

    this.initControls();


  }

  initControls() {
    this.advisory_hotels_form = this._formBuilder.group({
      // userComments: [this.userComments]
      hotelGUID: [this.selectedhotelGUID]
    });


  }

  get f() { return this.advisory_hotels_form.controls; }




  clickClose(val) {
    this._dialogRef.close(val);
  }



  clickUpdateHotels() {

    // this.filteredAdvisoryTagsList = this.advisoryTagsList.filter(x => x.selected == true);

    this._dialogRef.close(this.filteredHotel);
  }

  changeHotel(e: any, element) {
    this.hotelList.forEach(x => x.selected = false);

    element.selected = true;
    this.selectedhotelGUID = element.hotelGUID;
    this.filteredHotel = element;
  }

  clickResetHotels() {
    this.filteredHotel = new HotelModel();
    this.selectedhotelGUID = '';
    this.advisory_hotels_form.reset();
  }

}



