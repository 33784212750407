<div class="divLeft" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div>
    <div style="display: flex; justify-content: space-between;">
      <div style="display: flex; flex-direction: column;">
        <h3 style="font-weight: 200;">My Account</h3>
        <h3>Personal Information</h3>
      </div>
      <div style="display: flex;">
        <button mat-icon-button mat-dialog-close>
          <i class="material-icons" style="font-size: x-large;">close </i>
        </button>
      </div>
    </div>
    <form [formGroup]="personal_info">
      <div style="display: inline-flex;">
        <i class="material-icons-outlined" style="margin-top: 0.2em; margin-right: 0.2em;">photo_camera_front</i>
        <h3 style="font-weight: 200;">Basic Info</h3>
      </div>
      <div class="form-group" style="padding-right: 1em;">
        <label for="first_name">First Name</label>
        <div class="row" style="margin-left:0em; white-space:nowrap;">
          <input type="text" formControlName="first_name" class="form-control" 
            [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" (keyup)="onKeyup($event, 1)" (mousedown)="$event.stopPropagation()"
            style="padding: 0em;width:27.1em;height:1.9233em;font-size:small;background-color:#eaf0fd" maxlength="50" />
        </div>
        <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
          <div *ngIf="f.first_name.errors.required">First name is required</div>
        </div>
      </div>
      <div class="form-group">
        <label for="last_name">Last Name</label>
        <input type="text" formControlName="last_name" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" (keyup)="onKeyup($event, 2)" (mousedown)="$event.stopPropagation()"
          style="padding: 0em;width:27.1em;height:1.9233em;font-size:small;background-color:#eaf0fd" maxlength="50" />
        <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
          <div *ngIf="f.last_name.errors.required">Last name is required</div>
        </div>
      </div>
      <div class="form-group">
        <label for="job-title">Job Title</label>
        <input type="text" formControlName="job_title" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" (keyup)="onKeyup($event, 3)" (mousedown)="$event.stopPropagation()"
          style="padding: 0em;width:27.1em;height:1.9233em;font-size:small;background-color:#eaf0fd" maxlength="50" />
      </div>
      <h3 style="font-weight: 200;">Contact Info</h3>
      <div class="form-group">
        <label for="job-title">Phone</label>
        <input type="text" formControlName="phone_number" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" (keyup)="onKeyup($event, 4)" (mousedown)="$event.stopPropagation()"
          style="padding: 0em;width:27.1em;height:1.9233em;font-size:small;background-color:#eaf0fd" maxlength="50" />
      </div>
      <div class="form-group">
        <label for="job-title">Email</label>
        <input type="text" formControlName="email" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" (keyup)="onKeyup($event, 5)" (mousedown)="$event.stopPropagation()"
          style="padding: 0em;width:27.1em;height:1.9233em;font-size:small;background-color:#eaf0fd" maxlength="50" />
      </div>
    </form>
    <div *ngIf="errMsg!=''" class="d-block"><label style="color:red">{{errMsg}}</label></div>
    <div class="form-group" style="display: inline-flex;">
      <button mat-button class="btn-high" style="margin-right: .5em;" [disabled]="personal_info.status !== 'VALID' || loading"
        (click)="onSave()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        <i *ngIf="!loading" class="material-icons">add</i>
        Save
      </button>
      <button mat-button class="btn-low-no-shadow" mat-dialog-close>
        <i class="material-icons">close</i>
        Close
      </button>
    </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]="successMsg"></app-success-message>
    </div>
  </div>
</div>