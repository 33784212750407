<div style="background-color:#f8f8f8;height:100%">
  <div  cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>{{dialogTitle}}</h5></div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
      <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
    </div>-->
  </div>
  <div>
    <form *ngIf="customer_accounting_profile_edit_form" [formGroup]="customer_accounting_profile_edit_form" (ngSubmit)="clickSave()">
      <div style="display:flex; margin-top:0em; padding-left: 1em;padding-right:1em">
        <div style="margin-top:0em;background-color:white; padding-left:0.5em;padding-right:.5em">
          <div style="display:flex">
            <div *ngIf="acctType=='billTo'" class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="bill_to_code" style="font-size:small">Bill To Code</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <input type="text" formControlName="bill_to_code" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.bill_to_code.errors }" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
              </div>
            </div>
            <div *ngIf="acctType=='billTo'" class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="billing_account_name" style="font-size:small">Billing Account Name</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <input type="text" formControlName="billing_account_name" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.billing_account_name.errors }" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
              </div>
            </div>
            <div *ngIf="acctType=='refPO'" class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="reference_po" style="font-size:small">Reference PO#</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <input type="text" formControlName="reference_po" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.reference_po.errors }" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
              </div>
            </div>
            <div *ngIf="acctType=='refPO'" >
              <label for="effectiveDate" style="font-size:small">Effective Date</label>
              <div style="display:flex;margin-top:-0.35em">
                <input type="text" [matDatepicker]="effectivedatepicker" placeholder="mm/dd/yyyy" formControlName="effectiveDate" #effectiveDate class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.effectiveDate.errors }" style="padding: 0.25em; width: 6.5em; height: 1.5625em; font-size: small" maxlength="10" />
                <mat-datepicker-toggle matSuffix [for]="effectivedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                <mat-datepicker #effectivedatepicker></mat-datepicker>
              </div>
            </div>
            <div *ngIf="acctType=='refPO'" >
              <label for="expirationDate" style="font-size:small">Expiration Date</label>
              <div style="display:flex;margin-top:-0.35em">
                <input type="text" [matDatepicker]="expirationdatepicker" placeholder="mm/dd/yyyy" formControlName="expirationDate" #expirationDate class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.expirationDate.errors }" style="padding: 0.25em; width: 6.5em; height: 1.5625em; font-size: small" maxlength="10" />
                <mat-datepicker-toggle matSuffix [for]="expirationdatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                <mat-datepicker #expirationdatepicker></mat-datepicker>
              </div>

            </div>
          </div>
          <div style="display:flex">
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="registration" style="font-size:small">Registration</label>
              <div class="form-group" style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="registration" class="form-control form-control-sm dropdown">
                  <option value="" selected>All</option>
                  <option *ngFor="let aircraft of aircraftList" [ngValue]="aircraft.aircraftGuid">{{aircraft.registration}}</option>
                </select>
              </div>
            </div>
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="service_type" style="font-size:small">Service</label>
              <div class="form-group" style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="service_type" class="form-control form-control-sm dropdown">
                  <option value="0" selected>All</option>
                  <option *ngFor="let service of serviceList" [ngValue]="service.serviceTypeID">{{service.serviceTypeDescription}}</option>
                </select>
              </div>
            </div>
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="crew" style="font-size:small">Crew</label>
              <div class="form-group" style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="crew" class="form-control form-control-sm dropdown">
                  <option *ngFor="let crew of crewList" [ngValue]="crew.personGUID">{{crew.personName}}</option>
                </select>
              </div>
            </div>
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="pax" style="font-size:small">Pax</label>
              <div class="form-group" style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="pax" class="form-control form-control-sm dropdown">
                  <option *ngFor="let pax of paxList" [ngValue]="pax.personGUID">{{pax.personName}}</option>
                </select>
              </div>
            </div>

          </div>


          <div style="display:flex;margin-bottom:1em">
            <div>
              <div style="display:inline-flex;">
                <div style="display:flex">
                  <div class="form-group">
                    <label for="remarks" style="font-size:small">Remarks</label>
                    <div style="margin-top:-0.35em;">
                      <textarea formControlName="remarks" class="form-control" maxlength=255 style="width:30em; height:8em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>
                      </div>
                    </div>
                  </div>
                <div *ngIf="customerAccountingProfileGUID!=''" style="display:inline-flex;">
                  
                    <div class="form-group" style="margin-left: .5em">
                      <label for="bill_to_status" style="font-size:small">Disable Record</label>
                      <div style="margin-top:-0.35em;">
                        <select *ngIf="f" formControlName="bill_to_status" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color: lightgrey">
                          <option value="1" selected>No</option>
                          <option value="0">Yes</option>
                        </select>
                      </div>
                    </div>
                  
                  <div>

                    <div class="form-group" style="margin-left: 1em;">
                      <label for="modifiedBy" style="font-size:small">Modified by</label>
                      <div style="margin-top:-0.35em;">
                        <input type="text" formControlName="modifiedBy" readonly class="form-control" style="padding:0em;width:10em;height:1.5625em;font-size:small" />
                      </div>
                    </div>
                  
                    <div class="form-group" style="margin-left: 1em;">
                      <label for="modifiedDate" style="font-size:small">Modified Date</label>
                      <div style="margin-top:-0.35em;">
                        <input type="text" formControlName="modifiedDate" readonly class="form-control" style="padding: 0em; width: 10em; height: 1.5625em; font-size: small" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; white-space:pre-wrap"><label style="color:red; font-size: small">{{errMsg}}</label></div>
                <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>
              </div>


            </div>
        </div>
      </div>
    </form>

  </div>

  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em">
    <button mat-button class="btn-high" (click)="clickSave()">Save</button>&nbsp;
    <button mat-button *ngIf="customerAccountingProfileGUID!=''" class="btn-high" (click)="clickAddNew()">Add New Record</button>&nbsp;
    <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white">Close</button>

  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>





