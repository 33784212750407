<div *ngIf="upPII" style="background-color:#f8f8f8;padding-left:1em; padding-right:1em">
  <div style="display: flex;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3em;"><i class="material-icons" style="font-size: x-large;cursor: pointer; color: gray">downloads</i></div>
    <div style="margin-top:0.3125em;margin-left:-1em"><h5>{{pageFrom!='email'?'Document Downloads':'Trip Leg Documents'}}</h5></div>
  </div>
  <div>
    <form *ngIf="trip_leg_document_downloads_form" [formGroup]="trip_leg_document_downloads_form">
      <div style="margin-top:0em; padding-left: 0em; padding-right:0em">
        <div style="display: flex; font-size:small">
          <div style="font-weight: bold; margin-right:0.25em">Trip Code</div>
          <div style="margin-right:1em">{{currentLeg.tripCode}}</div>
          <div style="font-weight: bold; margin-right:0.25em">Leg</div>
          <div style="margin-right:0.25em">{{currentLeg.departureICAO}}</div>
          <div style="margin-right:0.25em">{{currentLeg.departureDateTextUTC!=''?currentLeg.departureDateTimeUTCDisplay+' Z':'TBA'}}</div>
          <div style="margin-right:0.25em">to</div>
          <div style="margin-right:0.25em">{{currentLeg.nextArrivalICAO}}</div>
          <div style="margin-right:0.25em">{{currentLeg.nextArrivalDateTextUTC!=''?currentLeg.nextArrivalDateTimeUTCDisplay+' Z':'TBA'}}</div>
        </div>
        <div style="display: flex">
          <div style="height:1.5em; display:flex; min-width: 33em">
            <div style="font-size: small; color: Highlight; margin-right:0.25em" *ngIf="tempFileNameDisplay!=''">Filename Starting Prefix:</div><div style="color: black; font-size: small">{{tempFileNameDisplay}}</div>
          </div>
          <div style="font-size:small; color: green">{{msg}}</div>
        </div>
        <div style="margin-top: 0em; background-color: white; padding-left: 0.5em; padding-right: 0.5em; height:36em">
          <div *ngIf="documentList && documentList.length>0" style="height:36em; overflow-y: auto">
            <div class="example-action-buttons">
              <button mat-button (click)="openAll()" color="primary" style="outline:none; font-size: small">Expand All</button>
              <button mat-button (click)="closeAll()" color="primary" style="outline: none; font-size: small">Collapse All</button>
            </div>
            <div style="overflow-y:auto">
              <mat-accordion class="example-headers-align" [multi]="true">
                <mat-expansion-panel *ngFor="let item of documentCategoryList, let i=index" [expanded]="item.panelOpenState" (opened)="item.panelOpenState=true" (closed)="item.panelOpenState=false" style="border: 0.0625em solid lightgray; width: 43em">
                  <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'" style="margin-left:-1em; font-weight:500; font-size:medium !important; text-align: left !important">
                    <mat-panel-title>
                      <div id="panel_{{i}}" #focusOnThisPanel>
                        {{item.documentCategory}}&nbsp;&nbsp;{{item.documentSelectedCountDisplay}}
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="min-height: 6.5em; max-height: 15em;overflow-y: auto;overflow-x:hidden;">
                    <table mat-table *ngIf="item.documentList" [dataSource]="item.documentList" class="example-container mat-elevation-z8">
                      <tr>
                        <ng-container matColumnDef="check">
                          <th mat-header-cell *matHeaderCellDef style="width:9%;">
                            Select
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; border-left: 0.0625em solid lightgrey !important; ">
                            <div style="margin-top:0.5em" class="mat-checkbox-smaller" *ngIf="element.dataEntryStatusDescription==''">
                              <mat-checkbox (click)="$event.stopPropagation()" (change)="checkDocumentChange($event, element)" [checked]="selection.isSelected(element)"></mat-checkbox>
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="documentName">
                          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:75%">
                            <div style="margin-left: 0em">Available Documents</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; text-align: left !important"><div style="display:flex"><div style="margin-right:0.25em"><a style="color:blue; cursor:pointer; text-decoration:underline" (click)="clickPreviewDocument($event, element)">{{element.documentName}}</a>&nbsp;({{element.documentSizeInKB}}KB)</div><div *ngIf="element.dataEntryStatusID==1 || element.dataEntryStatusID==3" [ngClass]="{'alert-danger': element.dataEntryStatusDescription!=''}" style="cursor: pointer" title="Click to approve the document" (click)="clickUnApprovedDoc($event, element)">{{element.dataEntryStatusDescription}}</div></div></td>
                        </ng-container>
                        <ng-container matColumnDef="expirationDate">
                          <th mat-header-cell *matHeaderCellDef style="width:16%;">
                            <div style="margin-left: 0em">Expiration Date</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; border-right: 0.0625em solid lightgrey !important" [ngClass]="{'alert-danger': element.isExpired}">{{element.documentExpirationDate}}</td>
                        </ng-container>

                      </tr>
                      <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                    </table>
                  </div>
                  <mat-action-row>
                    <button mat-button [hidden]="i==0" color="primary" (click)="prevStep(i)" style="font-size: small">Previous</button>
                    <button mat-button color="primary" (click)="nextStep(i)" style="font-size: small">{{i<(documentCategoryList.length-1)? 'Next': 'End'}}</button>
                  </mat-action-row>

                </mat-expansion-panel>
              </mat-accordion>
            </div>

          </div>
        </div>

      </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; display: flex;height:2.75em">
    <div style="margin-right:0.55em; ">
      <button mat-button class="btn-high" *ngIf="pageFrom!='email' && this.selection.selected.length>0" [disabled]="loading" (click)="clickDownload()"><div style="display: flex"><div *ngIf="loading" class="spinner-border spinner-border-sm mr-1" style="margin-top:0.25em"></div><div><i class="material-icons" style="font-size: medium; margin-top:0.25em">file_download</i></div><div style="margin-top:0em">Download</div></div></button>
      <button mat-button class="btn-high" *ngIf="pageFrom=='email' && this.selection.selected.length>0" [disabled]="loading" (click)="clickSelectDoc()"><div style="display: flex"><div *ngIf="loading" class="spinner-border spinner-border-sm mr-1" style="margin-top:0.25em"></div><!--<div><i class="material-icons" style="font-size: medium; margin-top:0.25em">file_download</i></div>--><div style="margin-top:0em">Select</div></div></button>
    </div>
    <div style="margin-right:0.5em">
      <button mat-button class="btn-low" (click)="clickClose()" ><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
    </div>
    <div *ngIf="errMsg!=''" class="d-block" style="margin-left:1em; display:flex;margin-top:-0.5em;background-color:#f9d2de; padding-left:0.25em; max-width:26em;"><label style="color:black; font-size: small">{{errMsg}}</label></div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]="successMsg"></app-success-message>
    </div>
  </div>
</div>
<div *ngIf="showSpin" style="z-index:300; margin-top: -26em ; margin-left: 10em; position: fixed; min-width: 20em">
  <app-spin></app-spin>
</div>

