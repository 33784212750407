<div style="margin-top:-1em;border:solid;border-width:thin;border-color:lightgray;width:max-content">
  <div>
    <form *ngIf="customer_note_form" [formGroup]="customer_note_form">
      <div class="p-3" style="background-color:white;">
        <div style="display: flex; flex-direction: column;">
          <div style="display: inline-flex;">
            <label style="color:black;">Scheduling Software</label>
            <select class="form-control" formControlName="scheduling_software"
              style="width:14em;height:1.5em;padding: 0em; margin-top: 0.4em; margin-left: 0.5em; font-size: small;"
              [ngStyle]="{'background-color' : f.scheduling_software.disabled ? '#e9ecef' : '#eaf0fd'}">
              <option *ngFor="let schedulingSoftware of schedulingSoftwareList" [value]="schedulingSoftware.schedulingSoftwareID">
                {{schedulingSoftware.schedulingSoftwareName}}</option>
            </select>
          </div>
          <div style="display: inline-flex;">
            <div class="mt-2">
              <label style="color:black">Client Instruction Notes</label>
              <div style="width: 40em; height: 22em;">
                <ckeditor #notesCKEditor data="ckEditorData" formControlName="internal_note" [editor]="Editor" [config]="CKEConfig" (mousedown)="$event.stopPropagation()" (ready)="onReady($event)" [disabled]="isDisabled"></ckeditor>
              </div>
              
              <!-- <textarea (mousedown)="$event.stopPropagation()" formControlName="internal_note" class="form-control"
                style="width:40.5em; height:27.5em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">
              </textarea> -->
            </div>
            <div style="margin-left: 1em;">
              <div class="mt-2">
                <label style="color:black">Non Standard Procedures</label>
                <div style="width: 40em; height: 22em;">
                  <ckeditor #nonStandardNotesCKEditor data="ckEditorData" formControlName="non_standard_procedures" [editor]="Editor" [config]="CKEConfig" (mousedown)="$event.stopPropagation()" (ready)="onReady($event)" [disabled]="isDisabled"></ckeditor>
                </div>
                <!-- <textarea (mousedown)="$event.stopPropagation()" formControlName="non_standard_procedures"
                  class="form-control"
                  style="width:40.5em; height:10.35em;font-family:Courier New, Courier, monospace;font-size:small; padding:0em 0.25em 0em .25em;background-color:#eaf0fd"></textarea> -->
              </div>
              
  
              <!-- <div class="mt-2">
                <label style="color:black">Sales Team Notes</label>
                <textarea (mousedown)="$event.stopPropagation()" formControlName="sales_note" class="form-control"
                  style="width:40.5em; height:10.35em;font-family:Courier New, Courier, monospace;font-size:small; padding:0em 14em 0em .25em;background-color:#eaf0fd"></textarea>
              </div> -->
            </div>
  
  
            <!-- <mat-tab-group [color]="primary" mat-align-tabs="start">
              <mat-tab>
                <ng-template mat-tab-label>
                  <label style="color:black">Operations & Wx Notes</label>
                  <mat-icon *ngIf="f.internal_note.value!=''" class="example-tab-icon">notes</mat-icon>
                </ng-template>
                <div class="mt-2">
                  <textarea (mousedown)="$event.stopPropagation()" formControlName="internal_note" class="form-control" style="width:70.5em; height:20em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
              </textarea>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <label style="color:black">Sales Team Notes</label>
                  <mat-icon *ngIf="f.sales_note.value!=''" class="example-tab-icon">notes</mat-icon>
                </ng-template>
                <div class="d-inline-flex mt-2">
                  <div>
                    <label for="scheduling_software" style="font-size:small">Scheduling Software</label>
                  </div>
                  <div>
                    <select *ngIf="f" formControlName="scheduling_software" class="form-control form-control-sm dropdown ml-2 mt-1" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color: lightgrey">
                      <option *ngFor="let s of schedulingSoftwareList" [ngValue]="s.schedulingSoftwareID">{{s.schedulingSoftwareName}}</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label for="sales_note" style="font-size:small">Sales Team Note</label>
                  <textarea (mousedown)="$event.stopPropagation()" formControlName="sales_note" class="form-control" style="width:70.5em; height:15.35em;font-family:Courier New, Courier, monospace;font-size:small; padding:0em 14em 0em .25em;background-color:#eaf0fd"></textarea>
                </div>
              </mat-tab>
            </mat-tab-group> -->
  
          </div>
        </div>
      </div>

    </form>
    <div class="d-inline-flex" style="height:3em">
      <div class="pl-3 pb-3" style="background-color:white;">
        <button mat-button class="btn-high" (click)="clickSave()">Save</button>&nbsp;
      </div>
      <div class="ml-2" *ngIf="showSuccessMsg">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
    </div>
  </div>
</div>