export class AircraftOperatorOwnerModel {
  aircraftGUID: string;
  aircraftOperatorOwnerLesseeID: number;
  customerGuid: string;
  ownerOperatorType: string;
  ownerOperatorTypeID: number;
  companyName: string;
  personName: string;
  lastName: string;
  firstName: string;
  middleName: string;
  ownerOperatorAddress: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  stateProvince: string;
  countryName: string;
  zipPostal: string;
  countryGuid: string;
  phoneNumber: string;
  faxNumber: string;
  email: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
}
