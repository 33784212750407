import { FeedbackDetailsModel } from "./feedback-details.model";

export class FeedbackModel {
  feedbackID: string;
  submittedby: string;
  customerGUID: string;
  aircraftGUID: string;
  tripCodeGUID: string;
  groundStopGUID: string;
  goundStopGUID: string;
  groundStopTaskGUID: string;
  vendorGUID: string;
  assignedTo: string;
  feedbackUserRating: number;
  feedbackUserServiceExperienceNote: string;
  taskServiceSupportNames: string;
  isClientRequestFollowup: boolean;
  whoWillFollowUp: string;
  followupDate: string;
  responsibility: string;
  resolution: string;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  countryID: number;
  airportID: number;
  tripCode: string;
  registration: string;
  airportDetails: string;
  taskName: string;
  customerName: string;
  icao: string;
  iata: string;
  lk_FeedbackSourceID: number;
  lk_FeedbackTypeID: number;
  lk_FeedbackIssueTypeID: number;
  lk_FeedbackPriorityID: number;
  lk_FeedbackStatusID: number;
  
  feedBackType: FeedbackDetailsModel[];
  feedbackIssueType: FeedbackDetailsModel[];
  feedbackStatus: FeedbackDetailsModel[];
  feedbackPriority: FeedbackDetailsModel[];
  feedbackSource: FeedbackDetailsModel[];
  feedbackDetails: FeedbackModel[];
  
  lk_FeedbackTypeDescription: string;
  lk_FeedbackIssueTypeDescription: string;
  lk_FeedbackStatusDescription: string;
  lk_FeedbackPriorityDescription: string;
  isTaskFeedback: boolean;
  
  countryName: string;
  vendorName: string;
  serviceLocation: string;
}