import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { CustomerService } from '../services/customer.service';
//import { AircraftModel } from '../models/aircraft.model';
//import { CustomerModel } from '../models/customer.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { DistanceTimeModel } from '../models/distance-time.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { TripModel } from '../models/trip.model';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopAlternateAirportModel } from '../models/ground-stop-alternate-airport.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { ReturnObjModel } from '../models/return-obj.model';
import { VendorModel } from '../models/vendor.model';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';

@Component({
  selector: 'app-ground-stop-alternate-airport-edit-dialog',
  templateUrl: './ground-stop-alternate-airport-edit-dialog.component.html',
  styleUrls: ['./ground-stop-alternate-airport-edit-dialog.component.css']
})

export class GroundStopAlternateAirportEditDialogComponent implements OnInit {
  //@Input() dialogTitle: string;

  groundStopId: string;
  //selectedTabName: string;
  //icao: string;
  latitude: number;
  longitude: number;
  //reloadChildPage: boolean;
  //customerGUID: string;
  //groundStopType: string;
  leg: string;
  nextGroundStopGUID: string;
  //currentIndex: number;
  v: number;
  tripCodeGUID: string;
  aircraftGUID: string;
  ground_stop_alternate_airport_edit_form: UntypedFormGroup;
  loading = false;

  submitted = false;
  errMsg: string;
  //errMsg2: string;
  //duplicateIATAErrMsg: string;
  msg: string;

  showSpin: boolean = true;
  //userType: string;

  isModified: boolean = false;
  loadingAirport: boolean = false;
  groundStopAltAirprotList: GroundStopAlternateAirportModel[];
  submitGroundStopAltAirprotList: GroundStopAlternateAirportModel[];
  airportList: AirportModel[];
  icao_iata: string = "";
  next_icao_iata: string = "";
  airportName: string = "";
  airportLocation: string = "";
  validatedAirport: boolean = false;
  centerLatitude: number;
  centerLongitude: number;
  arr_1: string = "";
  arr_2: string = "";
  dep_1: string = "";
  dep_2: string = "";
  etop_1: string = "";
  etop_2: string = "";
  etop_3: string = "";
  etop_4: string = "";
  etop_5: string = "";
  etop_6: string = "";
  etop_7: string = "";
  etop_8: string = "";
  etp_1: string = "";
  etp_2: string = "";
  etp_3: string = "";
  etp_4: string = "";
  etp_5: string = "";
  etp_6: string = "";
  etp_7: string = "";
  etp_8: string = "";
  arr_1_wrongAirportErrMsg: string = "";
  arr_1_duplicateIATAErrMsg: string = "";
  arr_2_wrongAirportErrMsg: string = "";
  arr_2_duplicateIATAErrMsg: string = "";
  dep_1_wrongAirportErrMsg: string = "";
  dep_1_duplicateIATAErrMsg: string = "";
  dep_2_wrongAirportErrMsg: string = "";
  dep_2_duplicateIATAErrMsg: string = "";
  etop_1_wrongAirportErrMsg: string = "";
  etop_1_duplicateIATAErrMsg: string = "";
  etop_2_wrongAirportErrMsg: string = "";
  etop_2_duplicateIATAErrMsg: string = "";
  etop_3_wrongAirportErrMsg: string = "";
  etop_3_duplicateIATAErrMsg: string = "";
  etop_4_wrongAirportErrMsg: string = "";
  etop_4_duplicateIATAErrMsg: string = "";
  etop_5_wrongAirportErrMsg: string = "";
  etop_5_duplicateIATAErrMsg: string = "";
  etop_6_wrongAirportErrMsg: string = "";
  etop_6_duplicateIATAErrMsg: string = "";
  etop_7_wrongAirportErrMsg: string = "";
  etop_7_duplicateIATAErrMsg: string = "";
  etop_8_wrongAirportErrMsg: string = "";
  etop_8_duplicateIATAErrMsg: string = "";
  etp_1_wrongAirportErrMsg: string = "";
  etp_1_duplicateIATAErrMsg: string = "";
  etp_2_wrongAirportErrMsg: string = "";
  etp_2_duplicateIATAErrMsg: string = "";
  etp_3_wrongAirportErrMsg: string = "";
  etp_3_duplicateIATAErrMsg: string = "";
  etp_4_wrongAirportErrMsg: string = "";
  etp_4_duplicateIATAErrMsg: string = "";
  etp_5_wrongAirportErrMsg: string = "";
  etp_5_duplicateIATAErrMsg: string = "";
  etp_6_wrongAirportErrMsg: string = "";
  etp_6_duplicateIATAErrMsg: string = "";
  etp_7_wrongAirportErrMsg: string = "";
  etp_7_duplicateIATAErrMsg: string = "";
  etp_8_wrongAirportErrMsg: string = "";
  etp_8_duplicateIATAErrMsg: string = "";

  //includeVendorAssignment: boolean = false;;
  controlValueChanged: boolean = false;
  
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  showSuccessMsg: boolean = false;
  legDisplay: string;
  returnObj = new ReturnObjModel();
  lockdownTrip: boolean = false;
  arr_1_vendor_select: string = "";
  arr_1_vendorList: VendorModel[] = [];
  arr_2_vendor_select: string = "";
  arr_2_vendorList: VendorModel[]=[];
  dep_1_vendor_select: string = "";
  dep_1_vendorList: VendorModel[] = [];
  dep_2_vendor_select: string = "";
  dep_2_vendorList: VendorModel[] = [];
  etp_1_vendor_select: string = "";
  etp_1_vendorList: VendorModel[] = [];
  etp_2_vendor_select: string = "";
  etp_2_vendorList: VendorModel[] = [];
  etp_3_vendor_select: string = "";
  etp_3_vendorList: VendorModel[] = [];
  etp_4_vendor_select: string = "";
  etp_4_vendorList: VendorModel[] = [];
  etp_5_vendor_select: string = "";
  etp_5_vendorList: VendorModel[] = [];
  etp_6_vendor_select: string = "";
  etp_6_vendorList: VendorModel[] = [];
  etp_7_vendor_select: string = "";
  etp_7_vendorList: VendorModel[] = [];
  etp_8_vendor_select: string = "";
  etp_8_vendorList: VendorModel[] = [];
  etop_1_vendor_select: string = "";
  etop_1_vendorList: VendorModel[] = [];
  etop_2_vendor_select: string = "";
  etop_2_vendorList: VendorModel[] = [];
  etop_3_vendor_select: string = "";
  etop_3_vendorList: VendorModel[] = [];
  etop_4_vendor_select: string = "";
  etop_4_vendorList: VendorModel[] = [];
  etop_5_vendor_select: string = "";
  etop_5_vendorList: VendorModel[] = [];
  etop_6_vendor_select: string = "";
  etop_6_vendorList: VendorModel[] = [];
  etop_7_vendor_select: string = "";
  etop_7_vendorList: VendorModel[] = [];
  etop_8_vendor_select: string = "";
  etop_8_vendorList: VendorModel[] = [];
  upPII: boolean = false;
  arrivalDateTimePassed: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<GroundStopAlternateAirportEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _tripLegService: TripLegService, private readonly _groundStopService: GroundStopService,
    private readonly _airportService: AirportService, private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    private readonly _dialogService: DialogService
  ) {
    this.groundStopId = _data.groundStopGUID;
    this.leg = _data.leg;
    this.nextGroundStopGUID=_data.nextGroundStopGUID;
    //this.currentIndex = _data.currentIndex;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.aircraftGUID = _data.aircraftGUID;
  }

  getAllData(): Observable<any[]> {
    let request = new AirportModel();
    request.route = this.leg; //this.icao;
    let getAirportResponse = this._airportService.getAirportByICAO<ResponseModel<AirportModel[]>>(request)
    let obj = new GroundStopAlternateAirportModel();
    obj.groundStopGUID = this.groundStopId;
    obj.nextGroundStopGUID = this.nextGroundStopGUID;
    //let getGroundStopAltAirport = this._groundStopAdvancedService.getGroundStopAlternateAirportForEditBygsId(obj);
    let getGroundStopAltAirport = this._groundStopAdvancedService.getGroundStopAlternateAirportForEditBygsIdnextgsId(obj);
    let obj1 = new TripModel();
    obj1.tripCodeGUID = this.tripCodeGUID;
    let lockdownTripResponse = this._groundStopService.getLockdownTripBytId(obj1);

    let req = new TripLegModel();
    req.groundStopGUID = this.groundStopId;
    req.nextArrivalGroundStopGUID = this.nextGroundStopGUID;
    req.aircraftGUID = this.aircraftGUID;
    let legResponse = this._tripLegService.getTripLegBydgIdagId(req);
    return forkJoin([getAirportResponse, getGroundStopAltAirport, lockdownTripResponse, legResponse]);


  }
  ngOnInit() {
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }

    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.arr_1 = "";
    this.arr_2 = "";
    this.dep_1 = "";
    this.dep_2 = "";
    this.etop_1 = "";
    this.etop_2 = "";
    this.etop_3 = "";
    this.etop_4 = "";
    this.etop_5 = "";
    this.etop_6 = "";
    this.etop_7 = "";
    this.etop_8 = "";
    this.etp_1 = "";
    this.etp_2 = "";
    this.etp_3 = "";
    this.etp_4 = "";
    this.etp_5 = "";
    this.etp_6 = "";
    this.etp_7 = "";
    this.etp_8 = "";
    this.airportList = [];
    this.groundStopAltAirprotList = [];
    this.submitGroundStopAltAirprotList = [];
    this.validatedAirport = false;
    this.controlValueChanged = false;
    this.showSuccessMsg = false;
    this.legDisplay = "";
    this.lockdownTrip = false;
    
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (responses[0].model.length > 0) {
            let ap = new AirportModel();
            ap = responses[0].model[0];
            this.icao_iata = ap.icao;
            if (ap.iata != "") {
              this.icao_iata += " / " + ap.iata;
            }
            this.airportName = ap.airportName;
            this.airportLocation = ap.airportLocation;
            this.latitude = ap.latitude;
            this.longitude = ap.longitude;
            let aplist: AirportModel[];
            aplist = responses[0].model;
            this.legDisplay = aplist[0].icao;
            if (aplist.length == 2) {
              this.next_icao_iata = aplist[1].icao;
              this.legDisplay = "for leg " + this.icao_iata + " to " + aplist[1].icao;
              if (aplist[1].iata != "") {
                this.legDisplay += " / " + aplist[1].iata;
                this.next_icao_iata +=" / "+ aplist[1].iata;
              }
            }
            else {
              this.legDisplay = "for " + this.icao_iata;
            }
          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (responses[1].model.length > 0) {
            this.groundStopAltAirprotList = responses[1].model;
            this.setGroundStopAlternateAirport();

          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.lockdownTrip = responses[2].model;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].model != null) {
          let leg = new TripLegModel();
          leg = responses[3].model;
          this.arrivalDateTimePassed = leg.nextArrivalDateTimePassed;

        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.initControls();
      this.checkFormControlValueChanged();
      this.showSpin = false;

    });

  }

  checkFormControlValueChanged(): void {
    this.ground_stop_alternate_airport_edit_form.get("arr_1").valueChanges.subscribe(x => {
      this.controlValueChanged = true;   
    });
    this.ground_stop_alternate_airport_edit_form.get("arr_2").valueChanges.subscribe(x => {
      this.controlValueChanged = true;     
    });
    this.ground_stop_alternate_airport_edit_form.get("dep_1").valueChanges.subscribe(x => {
      this.controlValueChanged = true;   
    });
    this.ground_stop_alternate_airport_edit_form.get("dep_2").valueChanges.subscribe(x => {
      this.controlValueChanged = true;     
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_1").valueChanges.subscribe(x => {
      this.controlValueChanged = true;       
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_2").valueChanges.subscribe(x => {
      this.controlValueChanged = true;     
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_3").valueChanges.subscribe(x => {
      this.controlValueChanged = true;      
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_4").valueChanges.subscribe(x => {
      this.controlValueChanged = true;     
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_5").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_6").valueChanges.subscribe(x => {
      this.controlValueChanged = true;      
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_7").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_8").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_1").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_2").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_3").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_4").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_5").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_6").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_7").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_8").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    //
    this.ground_stop_alternate_airport_edit_form.get("arr_1_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("arr_2_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("dep_1_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("dep_2_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_1_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_2_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_3_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_4_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_5_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_6_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_7_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etop_8_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_1_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_2_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_3_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_4_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_5_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_6_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_7_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_alternate_airport_edit_form.get("etp_8_vendor_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    //
  }

  setGroundStopAlternateAirport() {
    this.arr_1 = "";
    this.arr_2 = "";
    this.dep_1 = "";
    this.dep_2 = "";
    this.etop_1 = "";
    this.etop_2 = "";
    this.etop_3 = "";
    this.etop_4 = "";
    this.etop_5 = "";
    this.etop_6 = "";
    this.etop_7 = "";
    this.etop_8 = "";
    this.etp_1 = "";
    this.etp_2 = "";
    this.etp_3 = "";
    this.etp_4 = "";
    this.etp_5 = "";
    this.etp_6 = "";
    this.etp_7 = "";
    this.etp_8 = "";
    this.groundStopAltAirprotList.forEach(x => {
      switch (x.alternateAirportTypeDescription) {
        case "arr":
          switch (x.alternateAirportSequenceNumberID) {
            case 1:
              this.arr_1 = x.icao;
              this.arr_1_vendor_select = x.vendorGUID.toLowerCase();
              if (this.arr_1 != "") {
                this.getFBOGrandHandlerByAirport("arr", 1, x.icao);
              }
              break;
            case 2:
              this.arr_2 = x.icao;
              this.arr_2_vendor_select = x.vendorGUID.toLowerCase();
              if (this.arr_2 != "") {
                this.getFBOGrandHandlerByAirport("arr", 2, x.icao);
              }
              break;
          }
          
          break;
        case "dep":
          switch (x.alternateAirportSequenceNumberID) {
            case 1:
              this.dep_1 = x.icao;
              this.dep_1_vendor_select = x.vendorGUID.toLowerCase();
              if (this.dep_1 != "") {
                this.getFBOGrandHandlerByAirport("dep", 1, x.icao);
              }
              break;
            case 2:
              this.dep_2 = x.icao;
              this.dep_2_vendor_select = x.vendorGUID.toLowerCase();
              if (this.dep_2 != "") {
                this.getFBOGrandHandlerByAirport("dep", 2, x.icao);
              }
              break;
          }
          break;
        case "etop":
          switch (x.alternateAirportSequenceNumberID) {
            case 1:
              this.etop_1 = x.icao;
              this.etop_1_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etop_1 != "") {
                this.getFBOGrandHandlerByAirport("etop", 1, x.icao);
              }
              break;
            case 2:
              this.etop_2 = x.icao;
              this.etop_2_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etop_2 != "") {
                this.getFBOGrandHandlerByAirport("etop", 2, x.icao);
              }
              break;
            case 3:
              this.etop_3 = x.icao;
              this.etop_3_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etop_3 != "") {
                this.getFBOGrandHandlerByAirport("etop", 3, x.icao);
              }
              break;
            case 4:
              this.etop_4 = x.icao;
              this.etop_4_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etop_4 != "") {
                this.getFBOGrandHandlerByAirport("etop", 4, x.icao);
              }
              break;
            case 5:
              this.etop_5 = x.icao;
              this.etop_5_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etop_5 != "") {
                this.getFBOGrandHandlerByAirport("etop", 5, x.icao);
              }
              break;
            case 6:
              this.etop_6 = x.icao;
              this.etop_6_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etop_6 != "") {
                this.getFBOGrandHandlerByAirport("etop", 6, x.icao);
              }
              break;
            case 7:
              this.etop_7 = x.icao;
              this.etop_7_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etop_7 != "") {
                this.getFBOGrandHandlerByAirport("etop", 7, x.icao);
              }
              break;
            case 8:
              this.etop_8 = x.icao;
              this.etop_8_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etop_8 != "") {
                this.getFBOGrandHandlerByAirport("etop", 8, x.icao);
              }
              break;
          }
          break;
        case "etp":
          switch (x.alternateAirportSequenceNumberID) {
            case 1:
              this.etp_1 = x.icao;
              this.etp_1_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etp_1 != "") {
                this.getFBOGrandHandlerByAirport("etp", 1, x.icao);
              }
              break;
            case 2:
              this.etp_2 = x.icao;
              this.etp_2_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etp_2 != "") {
                this.getFBOGrandHandlerByAirport("etp", 2, x.icao);
              }
              break;
            case 3:
              this.etp_3 = x.icao;
              this.etp_3_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etp_3 != "") {
                this.getFBOGrandHandlerByAirport("etp", 3, x.icao);
              }
              break;
            case 4:
              this.etp_4 = x.icao;
              this.etp_4_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etp_4 != "") {
                this.getFBOGrandHandlerByAirport("etp", 4, x.icao);
              }
              break;
            case 5:
              this.etp_5 = x.icao;
              this.etp_5_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etp_5 != "") {
                this.getFBOGrandHandlerByAirport("etp", 5, x.icao);
              }
              break;
            case 6:
              this.etp_6 = x.icao;
              this.etp_6_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etp_6 != "") {
                this.getFBOGrandHandlerByAirport("etp", 6, x.icao);
              }
              break;
            case 7:
              this.etp_7 = x.icao;
              this.etp_7_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etp_7 != "") {
                this.getFBOGrandHandlerByAirport("etp", 7, x.icao);
              }
              break;
            case 8:
              this.etp_8 = x.icao;
              this.etp_8_vendor_select = x.vendorGUID.toLowerCase();
              if (this.etp_8 != "") {
                this.getFBOGrandHandlerByAirport("etp", 8, x.icao);
              }
              break;
          }
          break;
      }
    })
  }

  initControls() {
    this.ground_stop_alternate_airport_edit_form = this._formBuilder.group({
      arr_1: [this.arr_1, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      arr_2: [this.arr_2, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      dep_1: [this.dep_1, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      dep_2: [this.dep_2, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etop_1: [this.etop_1, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etop_2: [this.etop_2, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etop_3: [this.etop_3, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etop_4: [this.etop_4, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etop_5: [this.etop_5, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etop_6: [this.etop_6, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etop_7: [this.etop_7, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etop_8: [this.etop_8, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etp_1: [this.etp_1, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etp_2: [this.etp_2, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etp_3: [this.etp_3, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etp_4: [this.etp_4, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etp_5: [this.etp_5, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etp_6: [this.etp_6, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etp_7: [this.etp_7, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      etp_8: [this.etp_8, Validators.pattern("^[-a-zA-Z0-9\*]*$")],
      arr_1_vendor_select: this.arr_1_vendor_select,
      arr_2_vendor_select: this.arr_2_vendor_select,
      dep_1_vendor_select: this.dep_1_vendor_select,
      dep_2_vendor_select: this.dep_2_vendor_select,
      etp_1_vendor_select: this.etp_1_vendor_select,
      etp_2_vendor_select: this.etp_2_vendor_select,
      etp_3_vendor_select: this.etp_3_vendor_select,
      etp_4_vendor_select: this.etp_4_vendor_select,
      etp_5_vendor_select: this.etp_5_vendor_select,
      etp_6_vendor_select: this.etp_6_vendor_select,
      etp_7_vendor_select: this.etp_7_vendor_select,
      etp_8_vendor_select: this.etp_8_vendor_select,
      etop_1_vendor_select: this.etop_1_vendor_select,
      etop_2_vendor_select: this.etop_2_vendor_select,
      etop_3_vendor_select: this.etop_3_vendor_select,
      etop_4_vendor_select: this.etop_4_vendor_select,
      etop_5_vendor_select: this.etop_5_vendor_select,
      etop_6_vendor_select: this.etop_6_vendor_select,
      etop_7_vendor_select: this.etop_7_vendor_select,
      etop_8_vendor_select: this.etop_8_vendor_select//,
      //includeVendorAssignment: [this.includeVendorAssignment]
    });

  }

  get f() { return this.ground_stop_alternate_airport_edit_form.controls; }

  icaoChange(airportType: string, sequence: number, e: any) {
    switch (airportType) {
      case "arr":
        switch (sequence) {
          case 1:
            this.arr_1_vendorList = [];
            break;
          case 2:
            this.arr_2_vendorList = [];
            break;
        }
        break;
      case "dep":
        switch (sequence) {
          case 1:
            this.dep_1_vendorList = [];
            break;
          case 2:
            this.dep_2_vendorList = [];
            break;
        }
        break;
      case "etop":
        switch (sequence) {
          case 1:
            this.etop_1_vendorList = [];
            break;
          case 2:
            this.etop_2_vendorList = [];
            break;
          case 3:
            this.etop_3_vendorList = [];
            break;
          case 4:
            this.etop_4_vendorList = [];
            break;
          case 5:
            this.etop_5_vendorList = [];
            break;
          case 6:
            this.etop_6_vendorList = [];
            break;
          case 7:
            this.etop_7_vendorList = [];
            break;
          case 8:
            this.etop_8_vendorList = [];
            break;
        }
        break;
      case "etp":
        switch (sequence) {
          case 1:
            this.etp_1_vendorList = [];
            break;
          case 2:
            this.etp_2_vendorList = [];
            break;
          case 3:
            this.etp_3_vendorList = [];
            break;
          case 4:
            this.etp_4_vendorList = [];
            break;
          case 5:
            this.etp_5_vendorList = [];
            break;
          case 6:
            this.etp_6_vendorList = [];
            break;
          case 7:
            this.etp_7_vendorList = [];
            break;
          case 8:
            this.etp_8_vendorList = [];
            break;
        }
        break;
    }
    if (e.target.value != "") {

      this.validateAirport(airportType, sequence).subscribe(res => {
        if ((res != null || res != undefined) && res == true) {

        }

      });

    }
    else {
      this.setFormControlsStatus(0, '');

    }
  }


  validateAirport(airportType: string, sequence: number,): Observable<boolean> {
    let ctlName = airportType + "_" + sequence.toString();
    return new Observable<boolean>(ob => {
      this._authService.updateAccessTime();
      this.airportList = [];
      let request = new AirportModel();

      request.route = this.ground_stop_alternate_airport_edit_form.get(airportType + "_" + sequence.toString()).value;
      
      try {
        this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            this.airportList = response.model;
            if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
              this.airportList.forEach(x => {
                this.ground_stop_alternate_airport_edit_form.controls[airportType + "_" + sequence.toString()].setValue(x.icao);
                this.getFBOGrandHandlerByAirport(airportType, sequence, x.icao);
              });
              
              this.setFormControlsStatus(0, ctlName);

              switch (airportType) {
                case "arr":
                  switch (sequence) {
                    case 1:
                      this.arr_1_wrongAirportErrMsg = "";
                      this.arr_1_duplicateIATAErrMsg = ""
                      this.arr_1 = this.airportList[0].icao;                     
                      break;
                    case 2:
                      this.arr_2_wrongAirportErrMsg = "";
                      this.arr_2_duplicateIATAErrMsg = "";
                      this.arr_2 = this.airportList[0].icao;
                      break;
                  }
                  break;
                case "dep":
                  switch (sequence) {
                    case 1:
                      this.dep_1_wrongAirportErrMsg = "";
                      this.dep_1_duplicateIATAErrMsg = ""
                      this.dep_1 = this.airportList[0].icao;
                      break;
                    case 2:
                      this.dep_2_wrongAirportErrMsg = "";
                      this.dep_2_duplicateIATAErrMsg = "";
                      this.dep_2 = this.airportList[0].icao;
                      break;
                  }
                  break;
                case "etop":
                  switch (sequence) {
                    case 1:
                      this.etop_1_wrongAirportErrMsg = "";
                      this.etop_1_duplicateIATAErrMsg = ""
                      this.etop_1 = this.airportList[0].icao;
                      break;
                    case 2:
                      this.etop_2_wrongAirportErrMsg = "";
                      this.etop_2_duplicateIATAErrMsg = "";
                      this.etop_2 = this.airportList[0].icao;
                      break;
                    case 3:
                      this.etop_3_wrongAirportErrMsg = "";
                      this.etop_3_duplicateIATAErrMsg = ""
                      this.etop_3 = this.airportList[0].icao;
                      break;
                    case 4:
                      this.etop_4_wrongAirportErrMsg = "";
                      this.etop_4_duplicateIATAErrMsg = "";
                      this.etop_4 = this.airportList[0].icao;
                      break;
                    case 5:
                      this.etop_5_wrongAirportErrMsg = "";
                      this.etop_5_duplicateIATAErrMsg = ""
                      this.etop_5 = this.airportList[0].icao;
                      break;
                    case 6:
                      this.etop_6_wrongAirportErrMsg = "";
                      this.etop_6_duplicateIATAErrMsg = "";
                      this.etop_6 = this.airportList[0].icao;
                      break;
                    case 7:
                      this.etop_7_wrongAirportErrMsg = "";
                      this.etop_7_duplicateIATAErrMsg = ""
                      this.etop_7 = this.airportList[0].icao;
                      break;
                    case 8:
                      this.etop_8_wrongAirportErrMsg = "";
                      this.etop_8_duplicateIATAErrMsg = "";
                      this.etop_8 = this.airportList[0].icao;
                      break;
                  }
                  break;
                case "etp":
                  switch (sequence) {
                    case 1:
                      this.etp_1_wrongAirportErrMsg = "";
                      this.etp_1_duplicateIATAErrMsg = ""
                      this.etp_1 = this.airportList[0].icao;
                      break;
                    case 2:
                      this.etp_2_wrongAirportErrMsg = "";
                      this.etp_2_duplicateIATAErrMsg = "";
                      this.etp_2 = this.airportList[0].icao;
                      break;
                    case 3:
                      this.etp_3_wrongAirportErrMsg = "";
                      this.etp_3_duplicateIATAErrMsg = ""
                      this.etp_3 = this.airportList[0].icao;
                      break;
                    case 4:
                      this.etp_4_wrongAirportErrMsg = "";
                      this.etp_4_duplicateIATAErrMsg = "";
                      this.etp_4 = this.airportList[0].icao;
                      break;
                    case 5:
                      this.etp_5_wrongAirportErrMsg = "";
                      this.etp_5_duplicateIATAErrMsg = "";
                      this.etp_5 = this.airportList[0].icao;
                      break;
                    case 6:
                      this.etp_6_wrongAirportErrMsg = "";
                      this.etp_6_duplicateIATAErrMsg = "";
                      this.etp_6 = this.airportList[0].icao;
                      break;
                    case 7:
                      this.etp_7_wrongAirportErrMsg = "";
                      this.etp_7_duplicateIATAErrMsg = "";
                      this.etp_7 = this.airportList[0].icao;
                      break;
                    case 8:
                      this.etp_8_wrongAirportErrMsg = "";
                      this.etp_8_duplicateIATAErrMsg = "";
                      this.etp_8 = this.airportList[0].icao;
                      break;
                  }
                  break;
              }

              
            }
            if (this.airportList[0].wrongAirportErrMsg != "") {
              //let ws = "Invalid airport: " + this.airportList[0].wrongAirportErrMsg;
              let ws = "Invalid airport"; // + this.airportList[0].wrongAirportErrMsg;
              this.setFormControlsStatus(1, ctlName);
              switch (airportType) {
                case "arr":
                  switch (sequence) {
                    case 1:
                      this.arr_1_duplicateIATAErrMsg = "";
                      this.arr_1_wrongAirportErrMsg = ws;
                      this.f.arr_1.setErrors({ wrongAirportValidate: true });
                      break;
                    case 2:
                      this.arr_2_duplicateIATAErrMsg = "";
                      this.arr_2_wrongAirportErrMsg = ws;
                      this.f.arr_2.setErrors({ wrongAirportValidate: true });
                      break;
                  }
                  break;
                case "dep":
                  switch (sequence) {
                    case 1:
                      this.dep_1_duplicateIATAErrMsg = "";
                      this.dep_1_wrongAirportErrMsg = ws;
                      this.f.dep_1.setErrors({ wrongAirportValidate: true });

                      break;
                    case 2:
                      this.dep_2_duplicateIATAErrMsg = "";
                      this.dep_2_wrongAirportErrMsg = ws;
                      this.f.dep_2.setErrors({ wrongAirportValidate: true });
                      break;
                  }
                  break;
                case "etop":
                  switch (sequence) {
                    case 1:
                      this.etop_1_wrongAirportErrMsg = ws;
                      this.etop_1_duplicateIATAErrMsg = ""
                      this.f.etop_1.setErrors({ wrongAirportValidate: true });
                      break;
                    case 2:
                      this.etop_2_wrongAirportErrMsg = ws;
                      this.etop_2_duplicateIATAErrMsg = "";
                      this.f.etop_2.setErrors({ wrongAirportValidate: true });
                      break;
                    case 3:
                      this.etop_3_wrongAirportErrMsg = ws;
                      this.etop_3_duplicateIATAErrMsg = ""
                      this.f.etop_3.setErrors({ wrongAirportValidate: true });
                      break;
                    case 4:
                      this.etop_4_wrongAirportErrMsg = ws;
                      this.etop_4_duplicateIATAErrMsg = "";
                      this.f.etop_4.setErrors({ wrongAirportValidate: true });
                      break;
                    case 5:
                      this.etop_5_wrongAirportErrMsg = ws;
                      this.etop_5_duplicateIATAErrMsg = ""
                      this.f.etop_5.setErrors({ wrongAirportValidate: true });
                      break;
                    case 6:
                      this.etop_6_wrongAirportErrMsg = ws;
                      this.etop_6_duplicateIATAErrMsg = "";
                      this.f.etop_6.setErrors({ wrongAirportValidate: true });
                      break;
                    case 7:
                      this.etop_7_wrongAirportErrMsg = ws;
                      this.etop_7_duplicateIATAErrMsg = ""
                      this.f.etop_7.setErrors({ wrongAirportValidate: true });
                      break;
                    case 8:
                      this.etop_8_wrongAirportErrMsg = ws;
                      this.etop_8_duplicateIATAErrMsg = "";
                      this.f.etop_8.setErrors({ wrongAirportValidate: true });
                      break;
                  }
                  break;
                case "etp":
                  switch (sequence) {
                    case 1:
                      this.etp_1_wrongAirportErrMsg = ws;
                      this.etp_1_duplicateIATAErrMsg = ""
                      this.f.etp_1.setErrors({ wrongAirportValidate: true });
                      break;
                    case 2:
                      this.etp_2_wrongAirportErrMsg = ws;
                      this.etp_2_duplicateIATAErrMsg = "";
                      this.f.etp_2.setErrors({ wrongAirportValidate: true });
                      break;
                    case 3:
                      this.etp_3_wrongAirportErrMsg = ws;
                      this.etp_3_duplicateIATAErrMsg = ""
                      this.f.etp_3.setErrors({ wrongAirportValidate: true });
                      break;
                    case 4:
                      this.etp_4_wrongAirportErrMsg = ws;
                      this.etp_4_duplicateIATAErrMsg = "";
                      this.f.etp_4.setErrors({ wrongAirportValidate: true });
                      break;
                    case 5:
                      this.etp_5_wrongAirportErrMsg = ws;
                      this.etp_5_duplicateIATAErrMsg = ""
                      this.f.etp_5.setErrors({ wrongAirportValidate: true });
                      break;
                    case 6:
                      this.etp_6_wrongAirportErrMsg = ws;
                      this.etp_6_duplicateIATAErrMsg = "";
                      this.f.etp_6.setErrors({ wrongAirportValidate: true });
                      break;
                    case 7:
                      this.etp_7_wrongAirportErrMsg = ws;
                      this.etp_7_duplicateIATAErrMsg = ""
                      this.f.etp_7.setErrors({ wrongAirportValidate: true });
                      break;
                    case 8:
                      this.etp_8_wrongAirportErrMsg = ws;
                      this.etp_8_duplicateIATAErrMsg = "";
                      this.f.etp_8.setErrors({ wrongAirportValidate: true });
                      break;
                  }
                  break;
              }


            }
            if (this.airportList[0].duplicateIATAErrMsg != "") {
              let ds = "Duplicated IATAs: " + this.airportList[0].duplicateIATAErrMsg + ".";
              this.setFormControlsStatus(1, ctlName);
              switch (airportType) {
                case "arr":
                  switch (sequence) {
                    case 1:
                      this.arr_1_wrongAirportErrMsg = "";
                      this.arr_1_duplicateIATAErrMsg = ds;
                      this.f.arr_1.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 2:
                      this.arr_2_wrongAirportErrMsg = "";
                      this.arr_2_duplicateIATAErrMsg = ds;
                      this.f.arr_2.setErrors({ duplicateIATAValidate: true });
                      break;
                  }
                  break;
                case "dep":
                  switch (sequence) {
                    case 1:
                      this.dep_1_wrongAirportErrMsg = "";
                      this.dep_1_duplicateIATAErrMsg = ds;
                      this.f.dep_1.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 2:
                      this.dep_2_wrongAirportErrMsg = "";
                      this.dep_2_duplicateIATAErrMsg = ds;
                      this.f.dep_2.setErrors({ duplicateIATAValidate: true });
                      break;
                  }
                  break;
                case "etop":
                  switch (sequence) {
                    case 1:
                      this.etop_1_wrongAirportErrMsg = "";
                      this.etop_1_duplicateIATAErrMsg = ds;
                      this.f.etop_1.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 2:
                      this.etop_2_wrongAirportErrMsg = "";
                      this.etop_2_duplicateIATAErrMsg = ds;
                      this.f.etop_2.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 3:
                      this.etop_3_wrongAirportErrMsg = "";
                      this.etop_3_duplicateIATAErrMsg = ds;
                      this.f.etop_3.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 4:
                      this.etop_4_wrongAirportErrMsg = "";
                      this.etop_4_duplicateIATAErrMsg = ds;
                      this.f.etop_4.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 5:
                      this.etop_5_wrongAirportErrMsg = "";
                      this.etop_5_duplicateIATAErrMsg = ds;
                      this.f.etop_5.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 6:
                      this.etop_6_wrongAirportErrMsg = "";
                      this.etop_6_duplicateIATAErrMsg = ds;
                      this.f.etop_6.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 7:
                      this.etop_7_wrongAirportErrMsg = "";
                      this.etop_7_duplicateIATAErrMsg = ds;
                      this.f.etop_7.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 8:
                      this.etop_8_wrongAirportErrMsg = "";
                      this.etop_8_duplicateIATAErrMsg = ds;
                      this.f.etop_8.setErrors({ duplicateIATAValidate: true });
                      break;
                  }
                  break;
                case "etp":
                  switch (sequence) {
                    case 1:
                      this.etp_1_wrongAirportErrMsg = "";
                      this.etp_1_duplicateIATAErrMsg = ds;
                      this.f.etp_1.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 2:
                      this.etp_2_wrongAirportErrMsg = "";
                      this.etp_2_duplicateIATAErrMsg = ds;
                      this.f.etp_2.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 3:
                      this.etp_3_wrongAirportErrMsg = "";
                      this.etp_3_duplicateIATAErrMsg = ds;
                      this.f.etp_3.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 4:
                      this.etp_4_wrongAirportErrMsg = "";
                      this.etp_4_duplicateIATAErrMsg = ds;
                      this.f.etp_4.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 5:
                      this.etp_5_wrongAirportErrMsg = "";
                      this.etp_5_duplicateIATAErrMsg = ds;
                      this.f.etp_5.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 6:
                      this.etp_6_wrongAirportErrMsg = "";
                      this.etp_6_duplicateIATAErrMsg = ds;
                      this.f.etp_6.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 7:
                      this.etp_7_wrongAirportErrMsg = "";
                      this.etp_7_duplicateIATAErrMsg = ds;
                      this.f.etp_7.setErrors({ duplicateIATAValidate: true });
                      break;
                    case 8:
                      this.etp_8_wrongAirportErrMsg = "";
                      this.etp_8_duplicateIATAErrMsg = ds;
                      this.f.etp_8.setErrors({ duplicateIATAValidate: true });
                      break;
                  }
                  break;
              }

            }
            if (this.arr_1_wrongAirportErrMsg != "" || this.arr_1_duplicateIATAErrMsg != ""
              || this.arr_2_wrongAirportErrMsg != "" || this.arr_2_duplicateIATAErrMsg != ""
              || this.dep_1_wrongAirportErrMsg != "" || this.dep_1_duplicateIATAErrMsg != ""
              || this.dep_2_wrongAirportErrMsg != "" || this.dep_2_duplicateIATAErrMsg != ""
              || this.etop_1_wrongAirportErrMsg != "" || this.etop_1_duplicateIATAErrMsg != ""
              || this.etop_2_wrongAirportErrMsg != "" || this.etop_2_duplicateIATAErrMsg != ""
              || this.etop_3_wrongAirportErrMsg != "" || this.etop_3_duplicateIATAErrMsg != ""
              || this.etop_4_wrongAirportErrMsg != "" || this.etop_4_duplicateIATAErrMsg != ""
              || this.etop_5_wrongAirportErrMsg != "" || this.etop_5_duplicateIATAErrMsg != ""
              || this.etop_6_wrongAirportErrMsg != "" || this.etop_6_duplicateIATAErrMsg != ""
              || this.etop_7_wrongAirportErrMsg != "" || this.etop_7_duplicateIATAErrMsg != ""
              || this.etop_8_wrongAirportErrMsg != "" || this.etop_8_duplicateIATAErrMsg != ""
              || this.etp_1_wrongAirportErrMsg != "" || this.etp_1_duplicateIATAErrMsg != ""
              || this.etp_2_wrongAirportErrMsg != "" || this.etp_2_duplicateIATAErrMsg != ""
              || this.etp_3_wrongAirportErrMsg != "" || this.etp_3_duplicateIATAErrMsg != ""
              || this.etp_4_wrongAirportErrMsg != "" || this.etp_4_duplicateIATAErrMsg != ""
              || this.etp_5_wrongAirportErrMsg != "" || this.etp_5_duplicateIATAErrMsg != ""
              || this.etp_6_wrongAirportErrMsg != "" || this.etp_6_duplicateIATAErrMsg != ""
              || this.etp_7_wrongAirportErrMsg != "" || this.etp_7_duplicateIATAErrMsg != ""
              || this.etp_8_wrongAirportErrMsg != "" || this.etp_8_duplicateIATAErrMsg != ""

            ) {
              ob.next(false);


            }
            else {
              ob.next(true);
            }
          }
          else {
            if (response.code == "401") {

              this._authService.signOut();
            }
            else {
              ob.next(null);
            }
          }
        })
      }
      catch (error) {
        ob.next(null);
      }

    });

  }

  getFBOGrandHandlerByAirport(airportType: string, sequence: number, icao: string) {
    
    let request = new GroundStopModel();
    request.icao = icao;  
    this._groundStopAdvancedService.getFBOGroundHandlerByAirport<ResponseModel<VendorModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          switch (airportType) {
            case "arr":
              switch (sequence) {
                case 1:
                  this.arr_1_vendorList = response.model;
                  break;
                case 2:
                  this.arr_2_vendorList = response.model;
                  break;
              }
              break;
            case "dep":
              switch (sequence) {
                case 1:
                  this.dep_1_vendorList = response.model;
                  break;
                case 2:
                  this.dep_2_vendorList = response.model;
                  break;
              }
              break;
            case "etop":
              switch (sequence) {
                case 1:
                  this.etop_1_vendorList = response.model;                
                  break;
                case 2:
                  this.etop_2_vendorList = response.model;                  
                  break;
                case 3:
                  this.etop_3_vendorList = response.model;                 
                  break;
                case 4:
                  this.etop_4_vendorList = response.model;                 
                  break;
                case 5:
                  this.etop_5_vendorList = response.model;              
                  break;
                case 6:
                  this.etop_6_vendorList = response.model;                 
                  break;
                case 7:
                  this.etop_7_vendorList = response.model;                 
                  break;
                case 8:
                  this.etop_8_vendorList = response.model;                
                  break;
              }
              break;
            case "etp":
              switch (sequence) {
                case 1:
                  this.etp_1_vendorList = response.model;                 
                  break;
                case 2:
                  this.etp_2_vendorList = response.model;                 
                  break;
                case 3:
                  this.etp_3_vendorList = response.model;             
                  break;
                case 4:
                  this.etp_4_vendorList = response.model;               
                  break;
                case 5:
                  this.etp_5_vendorList = response.model;                  
                  break;
                case 6:
                  this.etp_6_vendorList = response.model;                 
                  break;
                case 7:
                  this.etp_7_vendorList = response.model;                
                  break;
                case 8:
                  this.etp_8_vendorList = response.model;                
                  break;
              }
              break;
          }
        }
        else {
          
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
         
        }
      }
      
    })


  }

  setFormControlsStatus(statusFlag: number, ctlName: string) {
    if (statusFlag == 1) {
      if (ctlName != 'arr_1')
        this.ground_stop_alternate_airport_edit_form.get('arr_1').disable();
      if (ctlName != 'arr_2')
        this.ground_stop_alternate_airport_edit_form.get('arr_2').disable();
      if (ctlName != 'dep_1')
        this.ground_stop_alternate_airport_edit_form.get('dep_1').disable();
      if (ctlName != 'dep_2')
        this.ground_stop_alternate_airport_edit_form.get('dep_2').disable();
      if (ctlName != 'etop_1')
        this.ground_stop_alternate_airport_edit_form.get('etop_1').disable();
      if (ctlName != 'etop_2')
        this.ground_stop_alternate_airport_edit_form.get('etop_2').disable();
      if (ctlName != 'etop_3')
        this.ground_stop_alternate_airport_edit_form.get('etop_3').disable();
      if (ctlName != 'etop_4')
        this.ground_stop_alternate_airport_edit_form.get('etop_4').disable();
      if (ctlName != 'etop_5')
        this.ground_stop_alternate_airport_edit_form.get('etop_5').disable();
      if (ctlName != 'etop_6')
        this.ground_stop_alternate_airport_edit_form.get('etop_6').disable();
      if (ctlName != 'etop_7')
        this.ground_stop_alternate_airport_edit_form.get('etop_7').disable();
      if (ctlName != 'etop_8')
        this.ground_stop_alternate_airport_edit_form.get('etop_8').disable();
      if (ctlName != 'etp_1')
        this.ground_stop_alternate_airport_edit_form.get('etp_1').disable();
      if (ctlName != 'etp_2')
        this.ground_stop_alternate_airport_edit_form.get('etp_2').disable();
      if (ctlName != 'etp_3')
        this.ground_stop_alternate_airport_edit_form.get('etp_3').disable();
      if (ctlName != 'etp_4')
        this.ground_stop_alternate_airport_edit_form.get('etp_4').disable();
      if (ctlName != 'etp_5')
        this.ground_stop_alternate_airport_edit_form.get('etp_5').disable();
      if (ctlName != 'etp_6')
        this.ground_stop_alternate_airport_edit_form.get('etp_6').disable();
      if (ctlName != 'etp_7')
        this.ground_stop_alternate_airport_edit_form.get('etp_7').disable();
      if (ctlName != 'etp_8')
        this.ground_stop_alternate_airport_edit_form.get('etp_8').disable();
      if (ctlName != 'arr_1_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('arr_1_vendor_select').disable();
      if (ctlName != 'arr_2_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('arr_2_vendor_select').disable();
      if (ctlName != 'dep_1_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('dep_1_vendor_select').disable();
      if (ctlName != 'dep_2_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('dep_2_vendor_select').disable();
      if (ctlName != 'etop_1_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etop_1_vendor_select').disable();
      if (ctlName != 'etop_2_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etop_2_vendor_select').disable();
      if (ctlName != 'etop_3_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etop_3_vendor_select').disable();
      if (ctlName != 'etop_4_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etop_4_vendor_select').disable();
      if (ctlName != 'etop_5_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etop_5_vendor_select').disable();
      if (ctlName != 'etop_6_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etop_6_vendor_select').disable();
      if (ctlName != 'etop_7_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etop_7_vendor_select').disable();
      if (ctlName != 'etop_8_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etop_8_vendor_select').disable();
      if (ctlName != 'etp_1_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etp_1_vendor_select').disable();
      if (ctlName != 'etp_2_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etp_2_vendor_select').disable();
      if (ctlName != 'etp_3_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etp_3_vendor_select').disable();
      if (ctlName != 'etp_4_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etp_4_vendor_select').disable();
      if (ctlName != 'etp_5_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etp_5_vendor_select').disable();
      if (ctlName != 'etp_6_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etp_6_vendor_select').disable();
      if (ctlName != 'etp_7_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etp_7_vendor_select').disable();
      if (ctlName != 'etp_8_vendor_select')
        this.ground_stop_alternate_airport_edit_form.get('etp_8_vendor_select').disable();
    }
    else {
      this.ground_stop_alternate_airport_edit_form.get('arr_1').enable();
      this.ground_stop_alternate_airport_edit_form.get('arr_2').enable();
      this.ground_stop_alternate_airport_edit_form.get('dep_1').enable();
      this.ground_stop_alternate_airport_edit_form.get('dep_2').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_1').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_2').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_3').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_4').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_5').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_6').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_7').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_8').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_1').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_2').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_3').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_4').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_5').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_6').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_7').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_8').enable();     
      this.ground_stop_alternate_airport_edit_form.get('arr_1_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('arr_2_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('dep_1_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('dep_2_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_1_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_2_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_3_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_4_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_5_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_6_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_7_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etop_8_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_1_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_2_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_3_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_4_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_5_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_6_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_7_vendor_select').enable();
      this.ground_stop_alternate_airport_edit_form.get('etp_8_vendor_select').enable(); 

    }

  }

  clickSave(actionFrom: string) {

    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.ground_stop_alternate_airport_edit_form.invalid) {
      return;
    }

    let request = new GroundStopModel();
    request.groundStopGUID = this.groundStopId;
    request.nextGroundStopGUID = this.nextGroundStopGUID;
    this.submitGroundStopAltAirprotList = [];
    if (this.f.arr_1.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.nextGroundStopGUID;      
      obj.icao = this.f.arr_1.value;
      obj.alternateAirportTypeDescription = "arr";
      obj.vendorGUID = "";
      if (this.f.arr_1_vendor_select.value != "") {
        obj.vendorGUID = this.f.arr_1_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.arr_2.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.nextGroundStopGUID;      
      obj.icao = this.f.arr_2.value;
      obj.alternateAirportTypeDescription = "arr";
      obj.vendorGUID = "";
      if (this.f.arr_2_vendor_select.value != "") {
        obj.vendorGUID = this.f.arr_2_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.dep_1.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;      
      obj.icao = this.f.dep_1.value;
      obj.alternateAirportTypeDescription = "dep";
      obj.vendorGUID = "";
      if (this.f.dep_1_vendor_select.value != "") {
        obj.vendorGUID = this.f.dep_1_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.dep_2.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;
      obj.icao = this.f.dep_2.value;
      obj.alternateAirportTypeDescription = "dep";
      obj.vendorGUID = "";
      if (this.f.dep_2_vendor_select.value != "") {
        obj.vendorGUID = this.f.dep_2_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etop_1.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;     
      obj.icao = this.f.etop_1.value;
      obj.alternateAirportTypeDescription = "etop";
      obj.vendorGUID = "";
      if (this.f.etop_1_vendor_select.value != "") {
        obj.vendorGUID = this.f.etop_1_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etop_2.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;     
      obj.icao = this.f.etop_2.value;
      obj.alternateAirportTypeDescription = "etop";
      obj.vendorGUID = "";
      if (this.f.etop_2_vendor_select.value != "") {
        obj.vendorGUID = this.f.etop_2_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etop_3.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;     
      obj.icao = this.f.etop_3.value;
      obj.alternateAirportTypeDescription = "etop";
      obj.vendorGUID = "";
      if (this.f.etop_3_vendor_select.value != "") {
        obj.vendorGUID = this.f.etop_3_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etop_4.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;     
      obj.icao = this.f.etop_4.value;
      obj.alternateAirportTypeDescription = "etop";
      obj.vendorGUID = "";
      if (this.f.etop_4_vendor_select.value != "") {
        obj.vendorGUID = this.f.etop_4_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etop_5.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;      
      obj.icao = this.f.etop_5.value;
      obj.alternateAirportTypeDescription = "etop";
      obj.vendorGUID = "";
      if (this.f.etop_5_vendor_select.value != "") {
        obj.vendorGUID = this.f.etop_5_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etop_6.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;    
      obj.icao = this.f.etop_6.value;
      obj.alternateAirportTypeDescription = "etop";
      obj.vendorGUID = "";
      if (this.f.etop_6_vendor_select.value != "") {
        obj.vendorGUID = this.f.etop_6_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etop_7.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;   
      obj.icao = this.f.etop_7.value;
      obj.alternateAirportTypeDescription = "etop";
      obj.vendorGUID = "";
      if (this.f.etop_7_vendor_select.value != "") {
        obj.vendorGUID = this.f.etop_7_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etop_8.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;      
      obj.icao = this.f.etop_8.value;
      obj.alternateAirportTypeDescription = "etop";
      obj.vendorGUID = "";
      if (this.f.etop_8_vendor_select.value != "") {
        obj.vendorGUID = this.f.etop_8_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etp_1.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;      
      obj.icao = this.f.etp_1.value;
      obj.alternateAirportTypeDescription = "etp";
      obj.vendorGUID = "";
      if (this.f.etp_1_vendor_select.value != "") {
        obj.vendorGUID = this.f.etp_1_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etp_2.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;      
      obj.icao = this.f.etp_2.value;
      obj.alternateAirportTypeDescription = "etp";
      obj.vendorGUID = "";
      if (this.f.etp_2_vendor_select.value != "") {
        obj.vendorGUID = this.f.etp_2_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etp_3.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;      
      obj.icao = this.f.etp_3.value;
      obj.alternateAirportTypeDescription = "etp";
      obj.vendorGUID = "";
      if (this.f.etp_3_vendor_select.value != "") {
        obj.vendorGUID = this.f.etp_3_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etp_4.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;     
      obj.icao = this.f.etp_4.value;
      obj.alternateAirportTypeDescription = "etp";
      obj.vendorGUID = "";
      if (this.f.etp_4_vendor_select.value != "") {
        obj.vendorGUID = this.f.etp_4_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etp_5.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;      
      obj.icao = this.f.etp_5.value;
      obj.alternateAirportTypeDescription = "etp";
      obj.vendorGUID = "";
      if (this.f.etp_5_vendor_select.value != "") {
        obj.vendorGUID = this.f.etp_5_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etp_6.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;     
      obj.icao = this.f.etp_6.value;
      obj.alternateAirportTypeDescription = "etp";
      obj.vendorGUID = "";
      if (this.f.etp_6_vendor_select.value != "") {
        obj.vendorGUID = this.f.etp_6_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etp_7.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;      
      obj.icao = this.f.etp_7.value;
      obj.alternateAirportTypeDescription = "etp";
      obj.vendorGUID = "";
      if (this.f.etp_7_vendor_select.value != "") {
        obj.vendorGUID = this.f.etp_7_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }
    if (this.f.etp_8.value != "") {
      var obj = new GroundStopAlternateAirportModel();
      obj.groundStopGUID = this.groundStopId;      
      obj.icao = this.f.etp_8.value;
      obj.alternateAirportTypeDescription = "etp";
      obj.vendorGUID = "";
      if (this.f.etp_8_vendor_select.value != "") {
        obj.vendorGUID = this.f.etp_8_vendor_select.value;
      }
      this.submitGroundStopAltAirprotList.push(obj);
    }

    request.groundStopAlternateAirportList = this.submitGroundStopAltAirprotList;

    this.loading = true;
    this._groundStopAdvancedService.saveGroundStopAlternateAirportBygsIdnextgsId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.isModified = true;
          this.returnObj.refresh = this.isModified;
          if (request.groundStopAlternateAirportList.length == 0)
            this.returnObj.hasAlternateAirport = false;
          else
            this.returnObj.hasAlternateAirport = true;

          this.showSuccessMsg = true;
          //if (actionFrom == "") {
            
            setTimeout(() => {
              this.showSuccessMsg = false;
              this.clickClose();
            }, 1000);

          //}
          this.controlValueChanged = false;
        }
        else {
          //this._dialogService.openAlertDialog("Failed to save the ground stop alternate airports at this time. Please try later.");
          this.errMsg = "Failed to save ground stop alternate airports at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save ground stop alternate airports at this time. Please try later.";
        }
      }
      this.loading = false;
    })

  }

  clickClose() {
    //this._dialogRef.close(this.isModified);
    if (this.ground_stop_alternate_airport_edit_form.invalid == false) {
      if (this.controlValueChanged == true) {
        this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before close?");
        this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
          this.eventSubscription.unsubscribe();
          if (result == true) {
            this.clickSave('close');
          }
          else {
            this._dialogRef.close(this.returnObj);

          }
        });
      }
      else {
        this._dialogRef.close(this.returnObj);
      }
    }
    else {
      this._dialogRef.close(this.returnObj);
    }

  }


}
