<div style=" background: #f0f0f0 !important;     padding: 1em 1.5em;" >

  <div style=" background-color: #f0f0f0;margin-left:1em;margin-right:1em;width: 100%; justify-content: space-between;display: flex; " cdkDrag
              cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle> 
    <div class="d-inline-flex" style=" text-align: center; padding: 0 0.5em 0.5em 0.5em; ">
      <span class="material-symbols-outlined" style="font-size: xx-large;">
        edit_document
      </span>
      <h4 style="margin-left: 0.3em;">Edit Country Picklist Documents </h4>
    </div> 
    <div style="margin-right: 1em;" >

      <button mat-icon-button *ngIf="dialogFrom == 'main'"   (click)="openFpcPicklistDataComponent()" >  <i class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i> </button>    
      <button   *ngIf="isDialog"   mat-icon-button  (click)="clickRouteNew('country-document-type-update','')"> <i class="material-icons" style="font-size: x-large;">open_in_new </i> </button>
      <button   *ngIf="isDialog"   mat-icon-button  (click)="clickClose()"> <i class="material-icons" style="font-size: x-large;">close </i> </button>
    </div>

  </div>
  <div style="background-color: white !important;   "> 
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> 

    <div style="margin-top:.5em; padding-top: 1.5em;display:flex;   font-size:small">
      <div style="margin-top: -0.2em;margin-left:  2em;"><b>Filter</b></div>
      <div>

        <div style="display: flex; margin-left: 1em; ">
          <div style="margin-right:0.25em">
            <input id="fRequiredDoc" type="radio" name="message_type" value="requiredDoc" [checked]="searchFiltervalue==0"
            (change)="searchFilterChange($event)" />
          </div>
          <label style="margin-top:-0.2em;white-space: nowrap " for="fRequiredDoc">Country Required Documents </label>
        </div>
        
        <div style="display: flex; margin-left: 1em; ">
          <div style="margin-right:0.25em">
            <input id="fMiscDoc" type="radio" name="message_type" value="miscDoc" [checked]="searchFiltervalue==1"
            (change)="searchFilterChange($event)" />
          </div>
          <label style="margin-top:-0.2em;white-space: nowrap " for="fMiscDoc">Miscellaneous Documents </label>
        </div>
      </div>

      <div   style="margin-left: 3em;" >
        <mat-checkbox  (change)="checkNoExpiry($event.checked)"   style="font-size:small">Show Disabled Documents Only</mat-checkbox>
      </div>
      <div style="margin-left: 2em;">
        <button type="button" mat-button class="btn-low" style="height: 2em;margin-left:0em;"  (click)="exportToExcel()"> Export to Excel</button>
        <!-- <button mat-button class="btn-low"  (click)="getData()" style="height: 2em;display: flex; color: #337dff;margin-left:1em; "><i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>REFRESH</button> -->
        <div  > Total Count: {{totalRecords}}</div>
      </div>
      
    </div>

    
    <div>
      <div style="margin-bottom: 1em; padding-bottom: 1em; height: 32em; overflow-y: auto; width: 43em;">
        <table mat-table *ngIf="countryRequiredDocumentTypeList" [dataSource]="countryRequiredDocumentTypeList"
          class="example-container mat-elevation-z8" style="width: 40em; margin-left: 1em;">
          <tr> 
            <ng-container matColumnDef="messageFolder">
              <th mat-header-cell *matHeaderCellDef style="padding-left: 1em !important; width:35%; text-align:left !important">
                <div style="margin-top:-0.25em;">Document Name</div>
              </th>
              <td mat-cell *matCellDef="let element" style="padding-left: 1em !important; text-align: left !important; width: 35%;">
                <div style="display: flex;">
                  <div  >{{element.documentTypeDescription}}</div>
                  <!-- <input type="text" [(ngModel)]="editFolderName" class="form-control form-control-sm" />
                      <span style="color: red;" *ngIf="folderExist">Folder Exists</span> -->
                </div>
              </td>
            </ng-container> 
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width:5%">
                <div>Action</div>
                <button   mat-button class="btn-high"  (click)="addDocumentName( row  )"  >Add</button> 
                <div style="margin-top:0em;padding-bottom:0.25em"></div>
              </th>
              <td mat-cell *matCellDef="let element" style="width: 5%;">
                <a *ngIf="!showNewFolderInput" mat-button   class="btn-low" style="align-self:center" >
                  Edit </a>
                <button *ngIf="showNewFolderInput" mat-button class="btn-low"  >Edit</button>  

              </td>
            </ng-container> 
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="editDocumentName( row  )"></tr>
        </table>
      </div>
    </div>
 
 
 
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]="successMsg"></app-success-message>
    </div>
 
  </div>
 
 
</div>

  