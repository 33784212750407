export class TaxesAndFeesDescModel {
  lk_TaxFeeDescriptionID: number;
  taxFeeDescription: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;


  selected: boolean;
  documentExists: boolean;
}
