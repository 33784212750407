import { CountryContentDocModel } from "./country-content-doc.model";
import { CountryContentModel } from "./country-content.model";
import { CountryDocumentModel } from "./country-document.model";
import { CountryRegistrationPrefixModel } from "./country-registration-prefix.model";

export class CountryModel {
  countryGUID: string;
  countryName: string;
  selected: boolean; 
  isO2Letter: string; 
  isO3Letter: string;
  apisCountryCode: string;
  registrationPrefixes: CountryRegistrationPrefixModel[];
  countryContent: CountryContentModel[];
  countryContentSegment: CountryContentModel[];
  countryContentSegmentDocs: CountryContentDocModel[];
  hasOverflightPermitDocument: boolean;
  hasLandingPermitDocument: boolean; 
  documentList: CountryDocumentModel[]; 
  countryID: number;
  searchKey: string; 
  countryContenCategoryList: CountryContentModel[]; 
  airportID: number;
  flagFile: string;
}
