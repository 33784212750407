
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle style="margin-top:-.5em" mat-dialog-title class="d-flex justify-content-between">

      <div class="d-flex"><div>Quote Summary History</div><div style="margin-left:1em;margin-top:.125em; font-weight:500;font-size:medium;color:gray">Trip Code {{tripCode}}</div></div>
    <div><mat-icon mat-dialog-close style="margin-top:.5em">close</mat-icon></div>
  </div>
  <div mat-dialog-content style="margin-top:-.75em">
    <div style="height:30em;background-color:white;" class="p-2">
      <div style="overflow:auto;height:20em">
        <table #table mat-table [dataSource]="fuelQuoteList">
          <tr>
            <ng-container matColumnDef="createdBy">
              <th mat-header-cell *matHeaderCellDef style="flex:0 0 15%;width:15%;">
                Created By
              </th>
              <td mat-cell *matCellDef="let element" style="flex:0 0 15%;width:15%;white-space:pre-wrap"> {{element.modifiedBy}}<br />{{element.modifiedDate}}</td>
            </ng-container>

            <ng-container matColumnDef="airports">
              <th mat-header-cell *matHeaderCellDef style="flex:0 0 40%;width:40%;">
                Included Airports
              </th>
              <td mat-cell *matCellDef="let element" style="flex:0 0 40%;width:40%;">{{element.includedAirports}}</td>
            </ng-container>

            <ng-container matColumnDef="remarks">
              <th mat-header-cell *matHeaderCellDef style="flex:0 0 20%;width:20%;">
                Remarks
              </th>
              <td mat-cell *matCellDef="let element" style="flex:0 0 20%;width:20%;" matTooltip="{{element.remarks}}" matTooltipClass="tooltipLineBreak">{{element.remarks}}</td>
            </ng-container>
            <ng-container matColumnDef="report_id">
              <th mat-header-cell *matHeaderCellDef style="flex:0 0 15%;width:15%;">
                Quote ID
              </th>
              <td mat-cell *matCellDef="let element" style="flex:0 0 15%;width:15%;">{{element.reportID}}</td>
            </ng-container>

            <ng-container matColumnDef="option">
              <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 10%;width:10%;">
                <div>Option</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 10%;width:10%;">
                <div><button *ngIf="element.tripDocumentGUID!=null" mat-button class="btn-high grid" (click)="clickPreviewDocument($event, element.tripDocumentGUID)">Open</button></div>

              </td>
            </ng-container>


          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      
      <app-spin *ngIf="isLoading"></app-spin>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close class="btn-low">Close</button>
  </div>
