<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<div style="background-color:#f8f8f8;">
  <div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>Country Documents</h5></div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
      <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
    </div>-->
  </div>
  <div>
    <form *ngIf="country_documents_edit_form" [formGroup]="country_documents_edit_form" (ngSubmit)="clickSave()">
      <div style="display:flex; margin-top:0em; padding-left: 1em;padding-right:1em">
        <div style="background-color: white; width: 27em;">
          <div style="display:flex; margin-left:0.5em;padding-top:0.5em">
            <div style="margin-right:1em">
              <div  style="margin-left: 0em;margin-top:-0.5em;margin-right:0.625em;">
                <label for="document_type" style="font-size:small">Document Type<span style="color: red;">*</span></label>
                <div class="form-group" style="  margin-top:-0.5em; width: 21em; ">
                  <select *ngIf="f" formControlName="document_type" tabindex="1" class="form-control form-control-sm dropdown" (change)="docTypeChange($event.target)" [ngClass]="{ 'is-invalid': submitted && f.document_type.errors }">
                    <option value="">--Select--</option>
                    <option *ngFor="let d of documentTypeList" [ngValue]="d.documentTypeGUID">{{d.documentTypeDescription}}</option>
                  </select>
                  <div *ngIf="submitted && f.document_type.errors" class="invalid-feedback" style="margin-top:-2em">
                    <div class="alert alert-danger p-0" *ngIf="f.document_type.errors.required">Document Type is required.</div>
                  </div>
                </div>
              </div>
              <div style="display:flex; margin-top:1em;">
                <div style="margin-left: 0em;margin-right:0.625em;">
                  <div style="display: flex; margin-bottom: 0.5em;">
                    <label for="document_name"  style="font-size:small; margin-right: 2em; ">Document Name<span style="color: red;">*</span></label>
                    <div  *ngIf="action=='add' " style=" margin-top: -0.3em;"   matTooltipClass="tooltipLineBreak"  matTooltip="Allowed File Types are: {{allowedFileType}} ">
                      <!--  *ngIf="action=='add' " -->
                      <label class="btn-high mat-button grid" >
                        <input tabindex="3" #document formControlName="document" id="document" type="file" ng2FileDrop [uploader]="uploader" multiple style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000"  (click)="onFileClick($event)"  />
                        Load
                        <span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
                      </label>
                    </div>
                    <div *ngIf="(documentList && documentList.length>0) || countryDocument!=null" class="ml-1"  style="margin-left: 2em;  margin-top: -0.3em;">
                      <button mat-button type="button" class="btn-high grid" (click)="clickPreviewDocument()">Preview</button>
                    </div>
                  </div>


                  <div class="form-group" style="margin-left: 0em;margin-top:-0.5em;  margin-bottom:0em">
                    <input type="text" formControlName="document_name" class="form-control" tabindex="-1"  maxlength="150" [ngClass]="{ 'is-invalid': submitted && f.document_name.errors }" style="padding: 0em;width:26em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                  </div>
                  <div *ngIf="submitted && f.document_name.errors" class="invalid-feedback" style="margin-top:-2em">
                    <div *ngIf="f.document_name.errors.required">Document Name is required.</div>
                  </div>
                  <div *ngIf="errMsg!=''" class="small alert alert-danger p-0" style="font-size: smaller; width: max-content; margin-left: .5em;  ">
                    <div>{{errMsg}}</div>
                  </div>                 </div>
                <!-- <div class="d-inline-flex align-items-center mt-3">
                  <div  *ngIf="action=='add' "> 
                    <label class="btn-high mat-button grid" >
                      <input tabindex="3" #document formControlName="document" id="document" type="file" ng2FileDrop [uploader]="uploader" multiple style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000"  (click)="onFileClick($event)"  />
                      Load
                      <span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
                    </label>
                  </div>
                  <div *ngIf="(documentList && documentList.length>0) || countryDocument!=null" class="ml-1">
                    <button mat-button type="button" class="btn-high grid" (click)="clickPreviewDocument()">Preview</button>
                  </div>
                  <div *ngIf="fileList && fileList.length>0" style="margin-top:0.25em">
                    <button type="button" class="btn btn-danger" (click)="clickRemoveDocument()" style="width:5em">Remove<span style="display:block;margin-top:-0.5em">Document</span></button>
                  </div>
                </div> -->
              </div>
              <div style="margin-top:0em; margin-left:0em; font-size: small">
                <div style="margin-top:0em">File Maximum size 25MB</div>
              </div>
              <!-- <div style="font-size:small">
                Allowed file types:
              </div>
              <div style="font-size:small">
                {{allowedFileType}}
              </div> -->

              <!-- <div  style="display: flex;margin:0.5em 0">
                <mat-checkbox   formControlName="showToClient" style="transform: scale(0.75)"  ></mat-checkbox>
                <div style="margin-top:-0.25em"><label for="showToClient" style="font-size:small">&nbsp;Filter For Mobile</label></div>
              </div> -->



              <div class="form-group" style="margin-top:0.7em; margin-right:5.625em">
                <label for="country_documnet_desc" style="font-size:small">Remarks: </label>
                <div style="margin-top:-0.5em;">
                  <textarea spellcheck="true" formControlName="country_documnet_desc" class="form-control"
                    style="width:26em; height:8em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">
                          </textarea>
                </div>
              </div>

              <!-- <div  *ngIf="(documentList && documentList.length>0) || countryDocument!=null"  style="margin-top: 0.7em;margin-bottom: 1.5em;" >
                <label for="size" style="font-size:small">Size</label>
                <div style="margin-top:-0.5em">
                  <input type="text" formControlName="size" readonly class="form-control" tabindex="-1"  (mousedown)="preventFocus($event)" style="padding:0;width:8em;height:1.5625em;font-size:small" />
                </div>
              </div> -->
              <div style="display:flex">

                <div style="margin-right: 2em; display: flex;">
                  <label style="font-size:small; margin-right: 0.4em;">Disable Record</label>
                  <div style="margin-left: 1em;">
                    <select formControlName="isActive" class="form-control form-control-sm dropdown" style="    width: 3.5em;" >
                      <option value="true">No</option>
                      <option value="false">Yes</option>
                    </select>
                  </div>
                </div>
                <div style="margin-right: 2em; display: flex;">
                  <label style="font-size:small; margin-right: 0.4em;">Show To Client</label>
                  <div style="margin-left: 1em;">
                    <select formControlName="showToClient" class="form-control form-control-sm dropdown" style="    width: 3.5em;" >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>

              </div>
              <div style="display:flex">
              
                <div>
                  <div style=" display: flex; padding: 2em 0 0 0em;">
                    <div style="margin-left: 0em;margin-top:-1em;margin-right:1.625em;">
                      <label for="effectiveDate" style="font-size:small">Effective Date</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <input type="date" class="form-control form-control-sm inputBox" formControlName="effectiveDate"
                          style=" width: 7em" />
                      </div>
                      <div *ngIf="submitted && f.effectiveDate.errors" class="alert alert-danger p-0 small">
                        <div *ngIf="f.effectiveDate.errors.required">Required</div>
                      </div>
                    </div>
                    <div *ngIf="!hasNoExpiry" class="form-group" style="margin-top:-1em; margin-right:0.625em">
                      <label for="expirationDate" style="font-size:small">Expiry Date</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <input type="date" class="form-control form-control-sm inputBox" formControlName="expirationDate"
                          style=" width: 7em" />
                      </div>
                      <!--<div *ngIf="submitted && f.expirationDate.errors" class="alert alert-danger p-0 small">
                                              <div *ngIf="f.expirationDate.errors.required">Required</div>
                                              </div>-->
                    </div>
              
                  </div>
                  <div *ngIf="f.expirationDate.errors && f.expirationDate.errors.matEndDateInvalid" class="alert-danger p-0"
                    style="font-size:small;margin-top:-1em">Expiry date cannot be before effective date.</div>
                </div>
                <!-- <div class="ml-2 mt-3" *ngIf="showNoExpiry">
                              <mat-checkbox color="primary" (change)="checkNoExpiry($event.checked)" [checked]="doesNotExpire" style="font-size:small">No Expiry</mat-checkbox>
                            </div> -->
              </div>
           

            </div>
 
          </div>

        </div>
   
      </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; margin-top: 0.5em;">
    <button mat-button *ngIf="action == 'add'" [disabled]="(!((documentList && documentList.length>0) || airportDocument!=null)) ||   loading" class="btn-high mr-1"  (click)="clickSave()">&nbsp;
      <mat-icon *ngIf="loading">
        <svg class="black-spinner" viewBox="0 0 50 50">
          <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="black" stroke-width="4"></circle>
        </svg>
      </mat-icon>Save</button>
    <button   *ngIf="  (action=='edit' )"  mat-button class="btn-high mr-1"  (click)="clickUpdate()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Update</button>
    <button  *ngIf="  (action=='edit' ) && isActive"  mat-button type="button" class="btn-low mr-1" (click)="clickAddNew()">Add New Record</button>
    <!-- <button  *ngIf="  (action=='edit' ) && isActive"  mat-button type="button" class="btn-low mr-1" (click)="clickDelete()"> Delete</button> -->
    <button mat-button class="btn-low" (click)="clickClose()">Close</button>

  </div>
  <div class="ml-3" style="width:min-content">
      <app-success-message [successMsg]="successMsg" *ngIf="showSuccessMsg"></app-success-message>
  </div>
  <div *ngIf="showErrorMsg" class="small alert alert-danger p-0" style="font-size: smaller; width: max-content; margin-left: .5em;  ">
    <div>{{errorMsg}}</div>
  </div> 
</div>
<app-spin *ngIf="showSpin"></app-spin>
<!--  -->
