import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  constructor() { }

  //transform(value: any, args: any): any {
  //  if (!args) { return value; }
  //  var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
  //  return value.replace(re, "<mark>$&</mark>");
  //}
  transform(value: string, args: string[] | string): string {
    if (!args.length) { return value; }
    const pattern = Array.isArray(args) ? args.filter(arg => !!arg).join('|') : args;
    const regex = new RegExp(pattern.concat('|<[^>]*>'), 'gi');
    return value.replace(regex, (match) => /<[^>]*>/g.test(match) ? match : `<mark>${match}</mark>`);
  }

} 
