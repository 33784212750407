import { GroundStopManifestModel } from "./ground-stop-manifest.model";

export class TripLegPersonStateModel {
  person: GroundStopManifestModel;
  tripLegState: string[];
  expiredPassportList: boolean[];
  isPassportNotRequiredList: boolean[];
  personDocumentTooltipList: string[];
  newlyAdded: boolean;
  unSaved: boolean;
  loading: boolean[];
}
