export class CurrencyModel { 
  lk_currencyID: number;
  currencyID: string;
  currencyDescription: string;
  exchangeRate: number;
  exchangeRateDate: Date;
  IsActive: boolean;
  ModifiedBy: string;
  ModifiedDate: Date;

  isoldDate: boolean;


  AttachmentGUID: string;
  documentName: string;
  fileExtension: string;
  documentSizeInKB: number;
  containerPath: string;
  fileSize: number;
  bFile: string;
  mimeType: string;
  tempId: number;
  errMsg: string;
  groundStopTaskDocumentGUID: string;
  documentGUID: string;

  price: number;
  usd: number;  
  selected: boolean;

}
