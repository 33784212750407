import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { GroundStopService } from '../services/ground-stop.service';
import { GlobalConstant } from '../common-utility/global-constant';
import { ReturnObjModel } from '../models/return-obj.model';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-trip-setup-note-dialog',
  templateUrl: './trip-setup-note-dialog.component.html',
  styleUrls: ['./trip-setup-note-dialog.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})

export class TripSetupNoteDialogComponent implements OnInit {
  errMsg: string;
  msg: string;
  //remarks: string;
  isModified: boolean = false;
  returnObj = new ReturnObjModel();
  trip_setup_note_form: UntypedFormGroup;
  submitted: boolean = false;
  loading = false;
  upLockTrip: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _dialogRef: MatDialogRef<TripSetupNoteDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _groundStopService: GroundStopService, private readonly _dialogService: DialogService
  ) {
    //this.remarks = _data.remarks;
  }


  ngOnInit() {
    this.errMsg = "";
    this.submitted = false;
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.lockTrip') != 'true') {
      this.upLockTrip = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    else {
      this.upLockTrip = true;
    }

    this.initControls();
  }

  initControls() {
    this.trip_setup_note_form = this._formBuilder.group({
      remarks: ['', [Validators.required,  Validators.minLength(15)]]
    })
  }

  get f() { return this.trip_setup_note_form.controls; }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }


    if (this.trip_setup_note_form.invalid || this.errMsg != "" ) {
      return;
    }

    this.returnObj.refresh = true;
    this.returnObj.remarks = this.f.remarks.value;
    this.clickClose();
    
  }

  clickClose() {
    this._dialogRef.close(this.returnObj);
  }

}
