import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { ReturnObjModel } from '../models/return-obj.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { UnitofmeasureModel } from '../models/unitofmeasure.model';

import { FuelSupplierModel } from '../models/fuelsupplier.model';
import { DatePipe, formatDate } from '@angular/common';
import { CurrencyModel } from '../models/currency.model';
import { FuelPricingModel } from '../models/fuel-pricing.mode';
import { FuelService } from '../services/fuel.service';
import { GroundStopService } from '../services/ground-stop.service';
import { AirportModel } from '../models/airport.model';
import { IntoPlaneAgentModel } from '../models/intoplaneagent.model';
import { TaxesAndFeesModel } from '../models/taxesandfees.model';
import { TaxesAndFeesDescModel } from '../models/taxesandfeesdesc.model';
import { CountryModel } from '../models/country.model';
import { FARTypeModel } from '../models/far-type.model';


@Component({
  selector: 'app-taxesandfees-edit',
  templateUrl: './taxesandfees-edit-dialog.component.html',
  styleUrls: ['./taxesandfees-edit-dialog.component.css']
})

export class TaxesandFeesEditDialogComponent implements OnInit, AfterViewInit {

  //@Input() isfromfuelpricing: boolean = true;
  @Input() dialogTitle: string;
  taxesandfee_edit: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  fuelSupplierList: FuelSupplierModel[];
  unitofmeasurelist: UnitofmeasureModel[];
  intoplaneagentList: IntoPlaneAgentModel[];

  countrylist: CountryModel[];
  airportlist: AirportModel[];
  currencylist: CurrencyModel[];
  taxandfeedesclist: TaxesAndFeesDescModel[];
  farTypelist: FARTypeModel[];


  currencyFilterlist: CurrencyModel[];
  currencyModel: CurrencyModel;
  show: boolean = false;
  showModified: boolean = false;
  taxesandfeesid: number;

  modifiedBy: string;
  modifiedDate: string;
  isActive: any;
  controlValueChanged: boolean;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;


  icao: string;
  iata: string;
  currency: string;
  taxandfeedesc: number;// = 1;
  amount: number;
  minscale: number;
  maxscale: number;
  lk_UnitOfMeasureID: any;
  exemptfartype: string;
  effectivedate: string = new Date().toString();
  expirydate: string = "";//new Date().toString();
  hasnoexpirydate: boolean = false;

  autocalculate: boolean = false;
  perunit: boolean = false;
  perUnitPrice: number;
  flatrate: boolean = false;
  flatratepercent: number;
  flatfeeamt: boolean = false;
  flatfeeamount: number;
  calculateontotal: boolean = false;
  isDomestic: boolean = false;

  lk_formulaid: number;
  internalnotes: string;
  clientnotes: string;
  modifiedby: string;
  modifieddate: Date;


  intoplaneagent: string = 'default';
  lk_CurrencyID: number;
  baseFuel: number;
  fuelsupplier: any;
  isExistingtaf: boolean = false;

  isotherintoplaneagent: boolean = false;
  otherintoplaneagent: string;
  isDuplicate: boolean = false;
  countryid: any;
  countryGUID: string;
  country: number;
  lk_taxfeedescid: number;
  unitofmeasure: number = 1;
  fartype: number = 1;
  successMsg: string;


  isValidICAO: boolean = false;
  validateICAO: boolean = true;


  countrylength: number = 0;
  airportid: number;
  taxStandard: string = '1';// string = "1";
  iscountryorairport: string = "country";
  hasNoExpiry: boolean = false;

  exemptPart91: boolean = false;
  exemptPart121: boolean = false;
  exemptPart135: boolean = false;
  exemptAll: boolean = false;
  countryName: string;
  validateminmax: boolean = false;
  USDConversion: string;
  originalPrice: string;
  currencyconverter1: string;
  currencyconverter2: string;
  currencyConverted: boolean = false;


  currencydesc: string;
  currencyDate: Date;
  LkcurrencyID: CurrencyModel[];
  LkcurrencyID1: CurrencyModel[];
  disableCalculate: boolean = false;
  isPercent: boolean = false;
  isNoTaxes: boolean = false;
  // allAirportTaxes: string = '**Taxes are applicable to All Airports <br\\> in the selected country.';
  selectedIcao: string = '';
  selectedIATA: string = '';
  isAirport: string = '';
  fromMissingTaxesAirport: boolean = false;
  isUOM: boolean = false;
  LkUOM: UnitofmeasureModel[];
  callFrom: string = "";
  tafEdit: boolean = false;
  requiredTAFDescID: number;
  fromFuelTask: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<TaxesandFeesEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopService: GroundStopService,
    private readonly _fuel: FuelService,
    private readonly _dialogService: DialogService,
    private datePipe: DatePipe,
    private fb: FormBuilder

  ) {
    this.taxesandfeesid = _data.taxesandfeesid;
    this.dialogTitle = _data.dialogTitle;

    this.isActive = true;


    if (_data.dialogTitle == "Add") {
      this.showModified = false;
      this.isExistingtaf = false;
      this.currency = 'USD';

      if (_data.fromMissingTaxesAirport != undefined) {
        this.fromMissingTaxesAirport = _data.fromMissingTaxesAirport;

        if (_data.fromMissingTaxesAirport) {
          this.selectedIcao = _data.selectedIcao;
          this.selectedIATA = _data.selectedIATA;
          this.isAirport = _data.isAirport;
          this.iscountryorairport = this.isAirport;
          this.icao = this.selectedIcao;
          this.iata = this.selectedIATA;
        }
      }

      if (_data.fromFuelTask != undefined) {
        this.fromFuelTask = _data.fromFuelTask;

        if (_data.fromFuelTask) {
          this.selectedIcao = _data.selectedIcao;
          this.selectedIATA = _data.selectedIATA;
          this.isAirport = _data.isAirport;
          this.iscountryorairport = this.isAirport;
          this.icao = this.selectedIcao;
          this.iata = this.selectedIATA;
          this.fuelsupplier = _data.supplier;
          this.intoplaneagent = _data.itp;
        }
      }

    }
    else {
      this.showModified = true;
      this.isExistingtaf = true;
    }

    if (_data.callFrom != undefined) {
      this.callFrom = _data.callFrom;
    }

    //this.fuelRangeForm = this.fb.group({ 
    //  quantities: this.fb.array([]),
    //});
  }

  getAllData(): Observable<any[]> {
    let getCountryList = this._fuel.getAllCountryList();
    let getFuelSuppliers = this._fuel.getFuelSupplierActive();// getActiveFuelSupplier(); 
    let getUnitOfMeasure = this._fuel.getUnitOfMeasure();
    let getCurrency = this._fuel.getCurrency();
    let getTaxAndFeesDesc = this._fuel.getTaxAndFeesDesc();
    let getFARTypeResponse = this._fuel.getCurrency();


    if (this.taxesandfeesid != 0) {
      let gettaxesandfeesResponse = this._fuel.gettaxesandfeesBytafId(this.taxesandfeesid);
      return forkJoin([getCountryList, getFuelSuppliers, getUnitOfMeasure, getCurrency, getTaxAndFeesDesc, getFARTypeResponse, gettaxesandfeesResponse]);
    }
    else {
      return forkJoin([getCountryList, getFuelSuppliers, getUnitOfMeasure, getCurrency, getTaxAndFeesDesc, getFARTypeResponse]);
    }
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    //this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = true;

    this.currency = 'USD';

    // this.taxesandfee_edit.get('currency').setValue(this.currency);


    //  this.fuelSupplierList = [];
    this.fuelSupplierList = [];
    this.unitofmeasurelist = [];
    this.showSuccessMsg = false;

    if (this.fromMissingTaxesAirport || this.fromFuelTask) {
      this.icaoValidationForMissingTaxes(this.selectedIcao);
    }

    //this.taxesandfee_edit.get('effectivedate').patchValue(this.formatDate(new Date()));
    //this.taxesandfee_edit.get('expirydate').patchValue(this.formatDate(new Date()));
    this.getAllData().subscribe(responses => {
      if (responses != null) { }
      if (!this.fromFuelTask)
        this.fuelsupplier = 'default';
      else {
        this.fuelsupplierChange();
      }
      //this.intlfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';


      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          //let country = new CountryModel();
          //country.countryName = "All";
          //responses[0].model.splice(0, 0, country);
          this.countrylist = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.fuelSupplierList = responses[1].model;


          let fs = new FuelSupplierModel();

          fs = responses[1].model;
          if (fs.fuelSupplierGUID == 'undefined') {
            this.fuelsupplier = fs.fuelSupplierGUID;
          }

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.unitofmeasurelist = responses[2].model;


          let uom = new UnitofmeasureModel();

          uom = responses[2].model;
          if (uom.lk_UnitOfMeasureID == 'undefined') {
            this.lk_UnitOfMeasureID = uom.lk_UnitOfMeasureID;
          }

        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.currencylist = responses[3].model;
          this.LkcurrencyID = this.currencylist.filter(cl => cl.currencyID === 'USD')


          let curr = new CurrencyModel();

          curr = responses[3].model;
          if (curr.lk_currencyID != null) {
            this.currency = curr.lk_currencyID.toString();
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          this.taxandfeedesclist = responses[4].model;



        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          //this.farTypelist = responses[5].model;
        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[6] != null) {
        if (responses[6].code == "200" && responses[6].message == "") {
          let taf = new TaxesAndFeesModel();
          taf = responses[6].model;

          this.iscountryorairport = taf.airportID == null ? 'country' : 'airport';

          this.taxesandfeesid = taf.taxesAndFeesID;
          this.country = taf.countryID;
          this.countryid = taf.countryID;
          this.countryName = taf.countryName;
          this.airportid = taf.airportID;
          this.icao = taf.icao;
          this.iata = taf.iata;
          //  this.country = taf.countryName;
          this.fuelsupplier = taf.fuelSupplierGUID == null ? 'default' : taf.fuelSupplierGUID;
          this.intoplaneagent = taf.intoPlaneAgent == 'All' ? 'default' : taf.intoPlaneAgent;
          //this.lk_CurrencyID = taf.lk_CurrencyID;
          this.currency = taf.currencyID;

          if (this.fuelsupplier != 'default')
            this.fuelsupplierChange();

          // this.lk_taxfeedescid = taf.lk_TaxFeeDescriptionID;
          this.taxandfeedesc = taf.lk_TaxFeeDescriptionID;
          this.amount = taf.amount;
          this.minscale = taf.minScale == 0 ? null : taf.minScale;
          this.maxscale = taf.maxScale == 0 ? null : taf.maxScale;
          this.lk_UnitOfMeasureID = taf.lk_UnitOfMeasureID;
          this.unitofmeasure = taf.lk_UnitOfMeasureID;
          this.exemptfartype = taf.exemptFARType;
          this.exemptPart91 = taf.exempt91;
          this.exemptPart121 = taf.exempt121;
          this.exemptPart135 = taf.exempt135;
          this.exemptAll = taf.exemptAll;

          if (taf.exemptAll != undefined && taf.exemptAll == true) {
            this.exemptAll = false;
            this.controlValueChanged = true;

          }

          this.effectivedate = taf.effectiveDate == null ? '' : formatDate(taf.effectiveDate, 'yyyy-MM-dd', 'en');// taf.effectiveDate;
          this.expirydate = taf.expiryDate == null ? '' : formatDate(taf.expiryDate, 'yyyy-MM-dd', 'en');//  taf.expiryDate;
          // this.hasNoExpiry = taf.hasNoExpiryDate;

          this.autocalculate = taf.autoCalculate;
          this.taxStandard = taf.taxStandard.toString();
          this.perUnitPrice = taf.perUnitPrice;
          this.flatrate = taf.flatRate;
          this.flatratepercent = taf.flatRatePercent;
          this.flatfeeamt = taf.flatFeeAmt;
          this.flatfeeamount = taf.flatFeeAmount;
          this.calculateontotal = taf.calculateOnTotal;
          this.isDomestic = taf.isDomestic;

          this.lk_formulaid = taf.lk_FormulaID;
          this.internalnotes = taf.internalNotes;
          this.clientnotes = taf.clientNotes;
          this.isActive = taf.isActive == true ? 1 : 0;
          this.modifiedBy = taf.modifiedBy;
          this.modifiedDate = taf.modifiedDate;
          // this.isUOM = false;

          this.countrylength = this.countryName.length;

          if (this.taxStandard == '2') {                 ///   && this.calculateontotal == true

            this.controlValueChanged = this.calculateontotal == true ? true : false;
            this.calculateontotal = false;
            this.f.calculateontotal.setValue(this.calculateontotal);
            this.disableCalculate = true;
            this.isUOM = true;

          }
          if (this.taxStandard == '3') {
            this.isPercent = true;
            // this.isUOM = true; 
          }

          // this.taxStandard = '0';// taf.flatFeeAmt == true ? 'flatprice' : 'flatpercent';
        }
        else {
          if (responses[6].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();
      this.convertToUSD();

      this.checkFormControlValueChanged();

      this.showSpin = false;



    });

    this.initControls();
    this.showSpin = false;
  }

  private formatDate(date) {
    if (date != null && date != '') {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    }
  }

  ngAfterViewInit() {

  }

  clickDuplicate() {
    this.isDuplicate = true
    this.isExistingtaf = false;
    this.baseFuel = null;
    this.fuelsupplierChange();
    // this.initControls();
    this.checkFormControlValueChanged();
    // this.clickSave();
    let countryVal = this.f.country.value;
    let iataVal = this.f.iata.value;
    let icaoVal = this.f.icao.value;
    let fuelsupplierVal = this.f.fuelsupplier.value;
    let intoplaneagentVal = this.f.intoplaneagent.value;
    this.disableCalculate = false;
    this.isPercent = false;
    this.isNoTaxes = false;
    this.showModified = false;
    this.f.taxesandfeesid.setValue(0);
    this.dialogTitle = 'Add';
    this.showModified = false;
    this.isExistingtaf = false;
    this.currency = 'USD';

    // this.f.unitofmeasure.reset();
    this.f.expirydate.reset();
    // this.f.effectivedate.reset();
    this.f.perUnitPrice.setValue(0);
    this.f.flatfeeamount.setValue(0);
    this.f.flatratepercent.setValue(1);
    this.f.exemptfartype.reset();
    this.f.minscale.reset();
    this.f.maxscale.reset();
    this.f.tafDesc.reset();
    this.f.internalnotes.reset();
    this.f.taxStandard.setValue('1');
    this.taxStandard = '1';
    this.f.perUnitPrice.enable();
    // this.taxStructureOnChangeGetDetails('1');
    this.exemptfartype = 'Part91';
    this.f.exemptPart91.setValue(false);
    this.f.exemptPart121.setValue(false);
    this.f.exemptPart135.setValue(false);
    this.f.exemptAll.setValue(false);
    this.originalPrice = '';
    this.USDConversion = '';
    this.currencyConverted = false;
    // this.isExistingtaf = true;
    // this.ngOnInit();    
    this.controlValueChanged = false;


  }

  initControls() {
    this.taxesandfee_edit = this._formBuilder.group({
      country: [{ value: this.country, disabled: this.showModified }],
      icao: [this.icao, Validators.required],
      iata: [this.iata, Validators.required],
      fuelsupplier: [this.fuelsupplier],
      intoplaneagent: [this.intoplaneagent],// [this.intoplaneagent],// [this.intoplaneagent, Validators.required],
      currency: [this.currency],
      tafDesc: [this.taxandfeedesc],
      amount: [this.amount],
      minscale: [this.minscale],//, Validators.required],
      maxscale: [this.maxscale],//, Validators.required],
      unitofmeasure: [this.unitofmeasure],
      exemptfartype: [this.exemptfartype],

      effectivedate: [formatDate(this.effectivedate, 'yyyy-MM-dd', 'en'), [Validators.required]],// [this.effectivedate, Validators.required],// [formatDate(this.effectivedate, 'yyyy-MM-dd', 'en'), [Validators.required]],// [this.effectivedate, Validators.required],
      expirydate: [this.expirydate, Validators.required],// [formatDate(this.expirydate, 'yyyy-MM-dd', 'en'), [Validators.required]],//[this.expirydate, Validators.required],
      hasnoexpirydate: [this.hasnoexpirydate],

      autocalculate: [this.autocalculate],

      taxStandard: [this.taxStandard],
      perUnitPrice: [this.perUnitPrice, Validators.required],
      flatrate: [this.flatrate],
      flatratepercent: [this.flatratepercent, Validators.required],
      flatfeeamt: [this.flatfeeamt],
      flatfeeamount: [this.flatfeeamount, Validators.required],
      calculateontotal: [this.calculateontotal],
      isDomestic: [this.isDomestic],

      internalnotes: [this.internalnotes],
      clientnotes: [this.clientnotes],

      otherintoplaneagent: [this.otherintoplaneagent],

      taxesandfeesid: [this.taxesandfeesid, Validators.required],

      savebutton: [{ value: '', disabled: this.controlValueChanged }],
      isActive: [this.isActive],
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],

      //hasNoExpiry: [this.hasNoExpiry],

      exemptPart91: [this.exemptPart91],
      exemptPart121: [this.exemptPart121],
      exemptPart135: [this.exemptPart135],
      exemptAll: [this.exemptAll],
      tafDescEdit: [''],


      //otherintoplaneagent: [this.otherintoplaneagent],

      quantities: this.fb.array([this.newQuantity()]),
    });

    let index = this.taxandfeedesclist?.findIndex(tfl => tfl.taxFeeDescription == 'No Taxes');

    if (index != undefined && this.taxandfeedesclist[index].lk_TaxFeeDescriptionID == this.taxandfeedesc) {
      this.isNoTaxes = true;
      this.disableCalculate = true;
      let uomID = this.unitofmeasurelist.filter(ul => ul.unitOfMeasure == 'n/a')[0]?.lk_UnitOfMeasureID;
      this.f.unitofmeasure.setValue(uomID);
      this.f.taxStandard.setValue(0);
      this.taxStandard = '0';
      this.f.perUnitPrice.setValue(0);
      this.f.flatratepercent.setValue(0);
      this.f.flatfeeamount.setValue(0);
      this.f.calculateontotal.setValue(false);
      this.f.isDomestic.setValue(false);

    }
    else {
      this.isNoTaxes = false;
      this.disableCalculate = this.disableCalculate != true ? false : this.disableCalculate;

    }
    this.setValidation();
  }
  get f() { return this.taxesandfee_edit.controls; }

  checkFormControlValueChanged(): void {
    this.taxesandfee_edit.get("country").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("icao").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("fuelsupplier").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("intoplaneagent").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("currency").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("tafDesc").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("amount").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("minscale").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("maxscale").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("unitofmeasure").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("exemptfartype").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("exemptPart91").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("exemptPart121").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("exemptPart135").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("exemptAll").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("effectivedate").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("expirydate").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    //this.taxesandfee_edit.get("hasNoExpiry").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    this.taxesandfee_edit.get("autocalculate").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });

    this.taxesandfee_edit.get("perUnitPrice").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("flatrate").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("flatratepercent").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("flatfeeamt").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("flatfeeamount").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("calculateontotal").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("isDomestic").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("internalnotes").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("clientnotes").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.taxesandfee_edit.get("isActive").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
  }

  countryChange(e: any) {
    let e1 = e.target.value;
    let request = new TaxesAndFeesModel();
    request.countryID = this.f.country.value;
    let countryid = request.countryID;


    this.intoplaneagentList = [];
    this._fuel.getAirportByCountryID<ResponseModel<AirportModel[]>>(countryid.toString()).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.airportlist = response.model;
        }
        else {
          //this.intoplaneagentList.push(ipa);
        }
      }
      else {
        this.msg = "";
      }
      this.loading = false;
      this.showSpin = false;

    })
  }

  fuelsupplierChange() {
    let request = new TaxesAndFeesModel();
    this.fuelsupplier = this.f.fuelsupplier.value == null ? this.fuelsupplier : this.f.fuelsupplier.value;
    request.fuelSupplierGUID = this.fuelsupplier;// this.f.fuelsupplier.value;
    let ipa = new IntoPlaneAgentModel();
    ipa.intoPlaneAgent = "other";

    this.intoplaneagentList = [];
    this._fuel.getTAFIntoPlaneAgentByFuelSupplierID<ResponseModel<IntoPlaneAgentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          response.model.splice(0, 0, ipa);
          this.intoplaneagentList = response.model;

          if (this.hasSpecificValue(this.intoplaneagentList, 'All')) {
            this.intoplaneagentList = this.removeSpecificItem(this.intoplaneagentList, 'All');
          }
          else {
            let s: string = 'no';
          }


        }
        else {

          this.intoplaneagentList.push(ipa);
        }
      }
      else {
        this.msg = "";
      }
      this.loading = false;
      this.showSpin = false;

    })
  }

  intoplaneagentChange() {
    if (this.f.intoplaneagent.value == 'other') {
      this.isotherintoplaneagent = true;
      this.f.otherintoplaneagent.setValidators(Validators.required);

    }
    else {
      this.f.otherintoplaneagent.setValidators(null);
      this.isotherintoplaneagent = false;
    }


  }

  clickSave(isSaveAndDuplicate = false) {

    this.controlValueChanged = false;
    this.validateminmax = false;
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;

    if (this.fromMissingTaxesAirport || this.fromFuelTask) {
      this.f.country.setValue(this.countryName);
    }
    //   this.setValidation();
    //this.countryairportselect();
    if (this.f.country.value == null) {
      this.f.country.setErrors({ required: true });
      return;
    }
    if (this.f.icao.value == "") {
      this.f.icao.setErrors({ required: true });
    }
    //if (!this.isExistingPrice && this.f.fuelsupplier.value == "default") {
    //if (this.f.fuelsupplier.value == "default") {
    //  this.f.fuelsupplier.setErrors({ required: true });
    //}

    if (this.f.intoplaneagent.value == "" || this.f.intoplaneagent.value == undefined) {
      this.f.intoplaneagent.setErrors({ required: true });
    }
    if (this.f.currency.value == "") {
      this.f.currency.setErrors({ required: true });
    }

    if (this.f.tafDesc.value == null) {
      this.f.tafDesc.setErrors({ required: true });
      return;
    }
    //if (this.f.amount.value == "") {
    //  this.f.amount.setErrors({ required: true });
    //}

    //if (this.f.minscale.value == "") {
    //  this.f.minscale.setErrors({ required: true });
    //}
    //if (this.f.maxscale.value == "") {
    //  this.f.maxscale.setErrors({ required: true });
    //}
    if (this.f.unitofmeasure.value == "") {
      this.f.unitofmeasure.setErrors({ required: true });
    }
    //if (this.f.exemptfartype.value == "") {
    //  this.f.exemptfartype.setErrors({ required: true });
    //}

    if (this.f.expirydate.value != null && this.f.expirydate.value != '')

      if (this.f.effectivedate.value > this.f.expirydate.value) {
        this.f.expirydate.setErrors({ matEndDateInvalid: true });
        return;
      }

    if (this.f.effectivedate.value == "") {
      this.f.effectivedate.setErrors({ required: true });
    }

    //if (this.f.expirydate.value == "") {
    //  this.f.expirydate.setErrors({ required: true });
    //}
    //if (this.f.hasnoexpirydate.value == "") {
    //  this.f.hasnoexpirydate.setErrors({ required: true });
    //}

    //if (this.f.autocalculate.value == "") {
    //  this.f.autocalculate.setErrors({ required: true });
    //}
    //if (this.f.flatrate.value == "") {
    //  this.f.flatrate.setErrors({ required: true });
    //}

    if (this.f.flatratepercent.value == "") {
      this.f.flatratepercent.setErrors({ required: true });
    }
    //if (this.f.flatfeeamt.value == "") {
    //  this.f.flatfeeamt.setErrors({ required: true });
    //}

    //if (this.f.calculateontotal.value == "") {
    //  this.f.calculateontotal.setErrors({ required: true });
    //}

    if (this.f.flatfeeamount.value == "") {
      this.f.flatfeeamount.setErrors({ required: true });
    }

    //if (this.f.internalnotes.value == "") {
    //  this.f.internalnotes.setErrors({ required: true });
    //}
    if (this.f.clientnotes.value == "") {
      this.f.clientnotes.setErrors({ required: true });
    }

    //if (this.f.otherintoplaneagent.value == "" || this.f.otherintoplaneagent.value == null) {
    //  this.f.otherintoplaneagent.setErrors({ required: true });
    //}

    //if (this.taxStandard != '2' &&(this.f.minscale.value == null || this.f.maxscale.value == null )) {
    //  //this.f.maxscale.setErrors({ required: false });
    // // this.f.maxscale.clearValidators();// setErrors({ required: true });

    //  return;
    //}
    if (this.f.minscale.value > this.f.maxscale.value) {
      this.validateminmax = true; return;
    }



    //let a = this.f.quantities.value;
    //if (this.f.minscale.value > this.f.maxscale.value) {
    //  this.errMsg = 'Min value cannot be less than the Max Value';
    //  return;
    //  // this.f.maxqty.setErrors({ required: true });
    //}
    //if (this.taxesandfee_edit.invalid) {
    //  return;
    //}
    //if (!this.isExistingtaf ) {
    //  return;
    //}
    if (this.f.intoplaneagent.value == 'other' && (this.f.otherintoplaneagent.value == '' || this.f.otherintoplaneagent.value == null)) {/*&& !this.isExistingtaf*/
      this.f.otherintoplaneagent.setErrors({ required: true });
      // this.errMsg = 'Into Plane Agent cannot be empty';
      return;
    }


    if (this.taxStandard == '1' && (this.f.perUnitPrice.value == null || this.f.perUnitPrice.value == 0)) {
      return;
    }
    if (this.taxStandard == '2' && (this.f.flatfeeamount.value == null || this.f.flatfeeamount.value == 0)) {
      return;
    }
    if (this.taxStandard == '3' && (this.f.flatratepercent.value == null || this.f.flatratepercent.value == 0)) {
      return;
    }
    if (this.f.effectivedate.value == '') {
      return;
    }

    this.loading = true;
    let obj = new TaxesAndFeesModel();


    obj.taxesAndFeesID = this.taxesandfeesid == null ? '' : this.f.taxesandfeesid.value;
    if (this.isDuplicate) {
      obj.taxesAndFeesID = 0;
    }

    obj.countryID = (this.countryid == undefined || this.countryid == 0) ? this.f.country.value : this.countryid;
    obj.airportID = this.airportid;// this.f.icao.value;
    // obj.icao = this.f.icao.value;
    // '00000000-0000-0000-0000-000000000000'
    obj.fuelSupplierGUID = this.f.fuelsupplier.value == 'default' ? null : this.f.fuelsupplier.value;
    obj.intoPlaneAgent = this.f.intoplaneagent.value == 'other' ? this.f.otherintoplaneagent.value : this.f.intoplaneagent.value == 'default' ? 'All' : this.f.intoplaneagent.value;
    obj.currencyID = this.f.currency.value;
    obj.lk_TaxFeeDescriptionID = this.f.tafDesc.value;
    obj.minScale = this.f.minscale.value == null ? 0 : this.f.minscale.value;
    obj.maxScale = this.f.maxscale.value == null ? 0 : this.f.maxscale.value;;
    obj.lk_UnitOfMeasureID = this.f.unitofmeasure.value;

    let exemptFARtype = this.f.exemptPart91.value == true ? 'Part91,' : '';
    exemptFARtype += this.f.exemptPart121.value == true ? 'Part121,' : '';
    exemptFARtype += this.f.exemptPart135.value == true ? 'Part135,' : '';

    exemptFARtype = this.f.exemptAll.value == true ? 'All,' : exemptFARtype;

    obj.exemptFARType = exemptFARtype.substring(0, exemptFARtype.length - 1);


    obj.exempt91 = this.f.exemptPart91.value == '1' ? true : false;
    obj.exempt121 = this.f.exemptPart121.value == '1' ? true : false;
    obj.exempt135 = this.f.exemptPart135.value == '1' ? true : false;
    obj.exemptAll = this.f.exemptAll.value == '1' ? true : false;

    obj.effectiveDate = this.f.effectivedate.value;
    obj.expiryDate = this.f.expirydate.value;// this.f.hasNoExpiry.value == false ? this.f.expirydate.value : ''; 
    //obj.hasNoExpiryDate = this.f.hasNoExpiry.value;
    obj.autoCalculate = this.f.autocalculate.value;


    obj.taxStandard = Number(this.taxStandard);// == 'flatprice' ? true : false;// taf.flatFeeAmt
    obj.flatRate = this.f.flatrate.value;
    obj.perUnitPrice = this.f.perUnitPrice.value == null ? 0 : this.f.perUnitPrice.value;
    obj.flatFeeAmount = this.f.flatfeeamount.value == null ? 0 : this.f.flatfeeamount.value;
    obj.flatRatePercent = this.f.flatratepercent.value == null ? 0 : this.f.flatratepercent.value;
    //obj.flatFeeAmt = this.f.flatfeeamt.value;
    // obj.flatFeeAmt = this.taxStandard == 'flatprice' ? true : false;// taf.flatFeeAmt
    obj.calculateOnTotal = this.f.calculateontotal.value;
    obj.isDomestic = this.f.isDomestic.value;

    obj.internalNotes = this.f.internalnotes.value == null ? '' : this.f.internalnotes.value;
    obj.clientNotes = this.f.clientnotes.value == null ? '' : this.f.clientnotes.value;
    obj.taxesAndFeesID = this.f.taxesandfeesid.value;

    obj.isActive = this.f.isActive.value == '1' ? true : false;

    // if (obj.exemptFARType != null && obj.exemptFARType != '') { 
    this._fuel.saveTaxesAndFees<ResponseModel<number>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model > 0) {
          // this.fuelSupplierGUID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          let returnResponse = new ReturnObjModel();
          returnResponse.refresh = true;
          returnResponse.newId = this.taxesandfeesid.toString();
          if (this.callFrom == 'quote') {
            returnResponse.taxesAndFees = obj;
            obj.taxesAndFeesID = response.model;
            obj.taxFeeDescription = this.taxandfeedesclist.filter(tfl => tfl.lk_TaxFeeDescriptionID == obj.lk_TaxFeeDescriptionID)[0].taxFeeDescription;
            obj.usdConverstion = this.USDConversion;
          }
          this.showSuccessMsg = true;


          // this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          this.isModified = true;

          //if (this.showModified == true && this.isDuplicate == true) {
          if (response.model > 0) {
            //this.ifsuccess = true;
            this.successMsg = "Taxes and Fees Saved Successfully.";
          }
          else if (this.showModified == true) {
            //this.ifsuccess = true;
            this.successMsg = "Taxes and Fees Updated Successfully.";
          }
          else {
            //this.ifsuccess = true;
            this.successMsg = "Taxes and Fees Saved Successfully.";
          }


          setTimeout(() => {
            this.showSuccessMsg = false;
            if (isSaveAndDuplicate) {

              this.clickDuplicate();
              // this.controlValueChanged = true;

            }
            else
              this._dialogRef.close(returnResponse);
          }, 1000);


          //this._dialogRef.close(returnResponse);
          //}
        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg = "Fuel Supplier Code already exists.";
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })
    // }
    // else{
    //   this.errMsg = 'Select a value for ExemptFarType ';
    // }


  }

  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;

    if (this.controlValueChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before close the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(response);
        }
      });

    }
    else {
      this._dialogRef.close(response);
    }
    //this._dialogRef.close(this.isModified);
  }

  icaoValidation(e: any, airportcode: string) { // appending the updated value to the variable
    let length: number = 0;
    length = airportcode == 'icao' ? 4 : 3;
    if (e.target.value.length >= length) {
      let icao: string = e.target.value;
      this._fuel.getCountryDetailsByICAO<ResponseModel<AirportModel>>(icao).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let airportModel = new AirportModel();

            airportModel = response.model;
            //this.country = response.model;
            this.taxesandfee_edit.get('country').setValue(airportModel.countryName);

            if (airportcode == 'icao')
              this.taxesandfee_edit.get('iata').setValue(airportModel.iata);


            if (airportcode == 'iata')
              this.taxesandfee_edit.get('icao').setValue(airportModel.icao);

            //  this.icao = airportModel.icao;
            //this.iata = airportModel.iata;

            this.airportid = airportModel.airportID;
            this.countryid = airportModel.countryId;
            this.countryName = airportModel.countryName;
            this.countrylength = this.countryName.length;
            this.errMsg = '';
            //   this.isValidICAO = response.model;
            // this.initControls();
          }
          else {

            this.fuelsupplier = 'default';
            this.validateICAO = false;
          }

        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.country = 0;
            this.countrylength = 0;
            this.errMsg = "Invalid ICAO.";
          }
        }
        this.loading = false;
      })
    }
    else {
      this.validateICAO = true;
      this.isValidICAO = false;
    }
  }

  hasnoexpirychange(e: any) {
    this.hasnoexpirydate = this.f.hasNoExpiry.value;
    this.hasNoExpiry = this.f.hasNoExpiry.value;
  }

  clickDuplicate1() {
    this.isDuplicate = true
    this.isExistingtaf = false;
    this.baseFuel = null;
    this.fuelsupplierChange();
    this.initControls();
    this.checkFormControlValueChanged();
    // this.clickSave();
    let countryVal = this.f.country.value;
  }

  quantities(): FormArray {
    return this.taxesandfee_edit.get("quantities") as FormArray
  }

  newQuantity(): FormGroup {
    return this.fb.group({
      minqty: '',
      maxqty: '',
    })
  }

  addQuantity() {
    if (this.quantities().length < 5) {
      this.quantities().push(this.newQuantity());
    }
  }

  removeQuantity(i: number) {
    if (this.quantities().length > 1) {
      this.quantities().removeAt(i);
    }
  }

  setValidation() {
    this.f.flatfeeamount.clearValidators();
    if (this.taxStandard == '1') {

      this.f.perUnitPrice.enable();
      //   this.f.perUnitPrice.setValue(Validators.required);
      this.f.flatratepercent.disable();
      this.f.flatratepercent.setValidators(null);
      this.f.flatfeeamount.disable();
      this.f.flatfeeamount.setValue(null);

      this.f.minscale.enable();
      this.f.maxscale.enable();
      //this.f.unitofmeasure.enable();
    }
    else if (this.taxStandard == '2') {

      this.f.flatfeeamount.enable();
      this.f.flatfeeamount.setValidators(Validators.required);
      this.f.flatratepercent.disable();
      this.f.flatratepercent.setValue(null);
      this.f.perUnitPrice.disable();
      this.f.perUnitPrice.setValue(null);

      this.f.minscale.disable();
      this.f.maxscale.disable();
      //this.f.unitofmeasure.disable();
      this.f.minscale.setValue(null);
      this.f.maxscale.setValue(null);
    }
    else if (this.taxStandard == '3') {

      this.f.flatratepercent.enable();
      this.f.flatratepercent.setValidators(Validators.required);
      this.f.flatfeeamount.disable();
      this.f.flatfeeamount.setValue(null);
      this.f.perUnitPrice.disable();
      this.f.perUnitPrice.setValue(null);

      this.f.minscale.enable();
      this.f.maxscale.enable();
      //this.f.unitofmeasure.enable();

    }
    this.f.flatfeeamount.updateValueAndValidity();

  }

  countryairportselect() {
    //this.allAirportTaxes = 'Taxes applicable to all airports in the selected country';
    this.f.icao.clearValidators();
    //this.f.ICAO.clearValidators();
    //if (this.iscountryorairport == "country") {
    // this.iscountryorairport = 'country';
    //this.f.flatfeeamount.enable();
    //this.f.flatfeeamount.setValidators(Validators.required);
    //this.f.flatratepercent.disable();
    //this.f.flatratepercent.setValue(null);
    //}
    //else {
    // this.iscountryorairport = 'airport';
    //this.f.flatratepercent.enable();
    //this.f.flatratepercent.setValidators(Validators.required);
    //this.f.flatfeeamount.disable();
    //this.f.flatfeeamount.setValue(null);
    // }
    // this.f.flatfeeamount.updateValueAndValidity();
  }

  // Function to check if a specific value exists in the list of models
  hasSpecificValue(models: IntoPlaneAgentModel[], valueToCheck: string): boolean {
    return models.some(model => model.intoPlaneAgent === valueToCheck);
  }

  removeSpecificItem(models: IntoPlaneAgentModel[], valueToRemove: string): IntoPlaneAgentModel[] {
    return models.filter(model => model.intoPlaneAgent !== valueToRemove);
  }

  convertToUSD() { // appending the updated value to the variable


    let value: number;

    if (this.taxStandard == '1')
      value = this.f.perUnitPrice.value;//e.target.value;
    else if (this.taxStandard == '2')
      value = this.f.flatfeeamount.value;//e.target.value;

    let uom: string;
    uom = this.f.unitofmeasure.value == 1 ? 'USG' : 'LTR';

    let uomValue: number;
    uomValue = this.f.unitofmeasure.value;// == 1 ? 'USG' : 'LTR';


    let request = new CurrencyModel();
    request.currencyID = this.f.currency.value;// this.currency;
    request.price = value;


    //  if(request.currencyID == ''){

    //    this.originalPrice = '';
    //    this.USDConversion = '';
    //    this.currencyConverted = false;
    //   }
    // && request.currencyID != '' && this.f.unitofmeasure.value != ''
    if (value != null) {

      this._fuel.getUSDConversion<ResponseModel<CurrencyModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let currencyModel = new CurrencyModel();

            currencyModel = response.model;

            this.currencyConverted = true;

            let usd: string;

            if (uomValue == 1 || this.taxStandard == '2') {
              usd = ((currencyModel[0].usd * 100) / 100).toFixed(5);
              uom = 'USG';
            }
            else if (uomValue == 2 && this.taxStandard != '2') {
              usd = ((currencyModel[0].usd * 3.78541 * 100) / 100).toFixed(5);
              uom = 'LTR';
            }
            else if (uomValue == 3 && this.taxStandard != '2') {
              usd = ((currencyModel[0].usd * 0.003021148 * 100) / 100).toFixed(5);
              uom = 'MT';
            }
            else if (uomValue == 4 && this.taxStandard != '2') {
              usd = ((currencyModel[0].usd * 3.79 * 100) / 100).toFixed(5);
              uom = 'KG';
            }
            else if (uomValue == 7 && this.taxStandard != '2') {
              usd = ((currencyModel[0].usd * 0.0378541 * 100) / 100).toFixed(5);
              uom = 'HL';
            }

            this.currencyconverter1 = '(' + currencyModel[0].currencyID + '/' + uom + ')';
            this.currencyconverter2 = '(USD/USG)';

            this.originalPrice = request.price.toString();// exchangerate + ' ' + currencyModel[0].currencyID + '/' + uom;
            this.USDConversion = usd;


            this.currencyfilter(request.currencyID);
          }
          else {
            this.originalPrice = '';
            this.USDConversion = '';
          }

        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.country = 0;
            this.countrylength = 0;
            //this.errMsg = "Invalid ICAO.";
          }
        }
        this.loading = false;
      })
    }
  }


  currencyfilter(currency: string) {
    // Filter the list based on the searchTerm
    this.currencyFilterlist = this.currencylist.filter((item) =>
      item.currencyID.toLowerCase().includes(currency.toLowerCase())
    );
    if (this.currencyFilterlist.length > 0) {
      this.currencydesc = this.currencyFilterlist[0].currencyDescription;
      this.currencyDate = this.currencyFilterlist[0].exchangeRateDate;
    }
  }

  taxStructureOnChange(event) {
    // this.f.currency.reset();
    // this.f.unitofmeasure.reset(); 
    // this.currencyConverted = false;
    this.taxStructureOnChangeGetDetails(event.value);
  }


  taxStructureOnChangeGetDetails(value: string) {

    if (value == '3') {
      this.LkcurrencyID1 = this.currencylist.filter(cl => cl.currencyID === 'n/a')
      // this.LkUOM = this.unitofmeasurelist.filter(ul => ul.unitOfMeasure === 'n/a') 

      this.f.currency.setValue(this.LkcurrencyID1[0].currencyID.toString());
      this.currency = this.f.currency.value;
      this.currencydesc = this.LkcurrencyID1[0].currencyDescription;

      // let uomID = this.unitofmeasurelist.filter(ul => ul.unitOfMeasure == 'n/a')[0]?.lk_UnitOfMeasureID;
      // this.f.unitofmeasure.setValue(uomID);

      this.isPercent = true;
      this.isUOM = false;

    }
    else {
      this.isPercent = false;

    }


    if (value == '2') {
      this.calculateontotal = false;
      this.f.calculateontotal.setValue(this.calculateontotal);
      this.disableCalculate = true;
      this.isUOM = true;

      let uomID = this.unitofmeasurelist.filter(ul => ul.unitOfMeasure == 'n/a')[0]?.lk_UnitOfMeasureID;
      this.f.unitofmeasure.setValue(uomID);
    }
    else {
      // this.currencyConverted = true; 
      this.disableCalculate = false;
    }

    if (value == '1') {
      this.isUOM = false;
    }
  }

  exemptAllOnChange() {
    this.f.exemptPart91.setValue(false);
    this.f.exemptPart121.setValue(false);
    this.f.exemptPart135.setValue(false);
  }

  taxAndFeeDescOnChange(e: any) {

    let index = this.taxandfeedesclist.findIndex(tfl => tfl.taxFeeDescription == 'No Taxes');

    if (e.target.value == 125 || this.taxandfeedesclist[index].lk_TaxFeeDescriptionID == e.target.value) {
      let currencyId = this.currencylist.filter(cl => cl.currencyDescription == 'n/a')[0]?.currencyID;
      this.f.currency.setValue(currencyId);
    }

    if (this.taxandfeedesclist[index].lk_TaxFeeDescriptionID == e.target.value) {
      this.isNoTaxes = true;
      this.disableCalculate = true;
      let uomID = this.unitofmeasurelist.filter(ul => ul.unitOfMeasure == 'n/a')[0]?.lk_UnitOfMeasureID;
      this.f.unitofmeasure.setValue(uomID);
      this.f.taxStandard.setValue(0);
      this.taxStandard = '0';
      this.f.perUnitPrice.setValue(0);
      this.f.flatratepercent.setValue(0);
      this.f.flatfeeamount.setValue(0);
      this.f.calculateontotal.setValue(false);
      this.originalPrice = '0';
      this.USDConversion = '0.0000';
      this.isUOM = true;
      this.isPercent = true;
    }
    else {

      this.isUOM = this.taxStandard == '2' ? true : false;
      this.isNoTaxes = false;
      this.disableCalculate = false;

      if (this.taxStandard == '3') {
        this.isPercent = true;
        this.isUOM = true;
      }
      else {

        this.isPercent = false;
        this.isUOM = false;
      }

    }
  }

  icaoValidationForMissingTaxes(requiredIcao: string) { // appending the updated value to the variable
    let length: number = 0;
    // length = airportcode == 'icao' ? 4 : 3;
    length = 4;
    if (requiredIcao.length >= length) {
      let icao: string = requiredIcao;
      this._fuel.getCountryDetailsByICAO<ResponseModel<AirportModel>>(icao).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let airportModel = new AirportModel();

            airportModel = response.model;
            //this.country = response.model;
            this.taxesandfee_edit.get('country').setValue(airportModel.countryName);

            // if (airportcode == 'icao')
            this.taxesandfee_edit.get('iata').setValue(airportModel.iata);


            // if (airportcode == 'iata')
            //   this.taxesandfee_edit.get('icao').setValue(airportModel.icao);

            //  this.icao = airportModel.icao;
            //this.iata = airportModel.iata;

            this.airportid = airportModel.airportID;
            this.countryid = airportModel.countryId;
            this.countryName = airportModel.countryName;
            this.countrylength = this.countryName.length;
            this.errMsg = '';
            //   this.isValidICAO = response.model;
            // this.initControls();
          }
          else {

            this.fuelsupplier = 'default';
            this.validateICAO = false;
          }

        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.country = 0;
            this.countrylength = 0;
            this.errMsg = "Invalid ICAO.";
          }
        }
        this.loading = false;
      })
    }
    else {
      this.validateICAO = true;
      this.isValidICAO = false;
    }
  }

  saveAndDuplicate() {
    this.clickSave(true);
  }


  clickTAFdescEdit(title: string) {
    this.tafEdit = true;
    if (title == 'edit') {

      this.requiredTAFDescID = this.f.tafDesc.value;

      let taxFeeDescription = this.taxandfeedesclist.filter(tfl => tfl.lk_TaxFeeDescriptionID == this.f.tafDesc.value)[0].taxFeeDescription;
      this.f.tafDescEdit.setValue(taxFeeDescription);

    }
    else {
      this.requiredTAFDescID = 0;
      this.f.tafDescEdit.reset();

    }
  }

  clickTAFdescSave() {

    let request = new TaxesAndFeesDescModel();
    request.lk_TaxFeeDescriptionID = this.requiredTAFDescID;
    request.taxFeeDescription = this.f.tafDescEdit.value;
    if (request.taxFeeDescription != undefined && request.taxFeeDescription != null && request.taxFeeDescription.trim() != '') {

      this._fuel.insertUpdateTaxesAndFeesDescription<ResponseModel<TaxesAndFeesDescModel>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == '200' && response.model.lk_TaxFeeDescriptionID > 0) {

            if (this.f.tafDesc.value != response.model.lk_TaxFeeDescriptionID) {
              this.taxandfeedesclist.push(response.model);
              this.f.tafDesc.setValue(response.model.lk_TaxFeeDescriptionID);

            }
            else {
              this.taxandfeedesclist.forEach(tdl => {
                if (tdl.lk_TaxFeeDescriptionID == request.lk_TaxFeeDescriptionID) {
                  tdl.taxFeeDescription = response.model.taxFeeDescription;
                }
              })
            }
            this.tafEdit = false;
          }
        }

      });

    }
    else {
      this.errMsg = 'Taxes And Fees Description Can Not be Null';
      setTimeout(() => {
        this.errMsg = '';
      }, 2000);
    }

  }

  clickTAFdescCancel() {
    this.tafEdit = false;

    this.f.tafDescEdit.reset();
  }

}


