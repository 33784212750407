<div *ngIf="grantAccessToManageAccountGrouping" style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em; " cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div>
      <div style="margin-top:0.3125em;"><h5>ACCOUNTING GROUPING</h5></div>
      <div style="margin-top:0em;"></div>
    </div>
  </div>
  <div>
    <form *ngIf="account_group_edit_form" [formGroup]="account_group_edit_form">
      <div style="margin-top:-0.5em; padding-left: 1em; padding-right:1em;font-size: small">
        <div style="margin-top:1.5em; background-color: white;padding: 0.5em 1em 1em 1em">
          <div>
            <label for="client_group_name">Group Name</label>
            <div style="margin-top:0em;">
              <input type="text" formControlName="client_group_name" class="form-control" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.client_group_name.errors }" style="width:45em; height:1.75em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd" />
            </div>
            <div *ngIf="submitted && f.client_group_name.errors" class="invalid-feedback">
              <div *ngIf="f.client_group_name.errors.required" style="background-color:#f9d2de;margin-left:0em;  padding-left:0.25em; width:13em;margin-top:0.25em">Group name is required</div>
              <div *ngIf="f.client_group_name.value!='' && f.client_group_name.errors && f.client_group_name.errors.checkExistingGroupName==true" style="background-color:#f9d2de;margin-left:0em;  padding-left:0.25em; width:36em;margin-top:0.25em">The group name has been used in our system. Please enter a different name.</div>
            </div>
          </div>
          <div style="margin-top:0.25em">
            <label for="internal_note">Remarks</label>
            <div style="margin-top:0em;">
              <textarea formControlName="internal_note" class="form-control" style="width:45em; height: 15em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd">
              </textarea>

            </div>
          </div>
          <div *ngIf="customerGroupGUID!=''" style="margin-top:0.25em; display: flex">
            <div style="margin-right:0.25em"><label for="acct_status" style="font-size:small">Disable Record</label></div>
            <div style="margin-top:0em;">
              <select *ngIf="f" formControlName="acct_status" style="background-color:#eaf0fd;border-radius:0.25em; border-color:lightgrey">
                <option value="1" selected>No</option>
                <option value="0">Yes</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div style="justify-content:space-between;padding-left: 1em; padding-right:1em; padding-top:0.5em; padding-bottom:0.5em;display: flex;height:3em">
    <div style="display: flex;">
      <div style="margin-right:0.5em">
        <button mat-button class="btn-high" (click)="clickSave()"><!--Save and-->Next</button>
      </div>
      <div>
        <button mat-button class="btn-low" (click)="clickClose('')"><div style="display:flex"><div>Close</div></div></button>
      </div>
      <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:18em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
      <div *ngIf="showSuccessMsg" style="margin-top: -0.25em;">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
    </div>
    <div *ngIf="customerGroupGUID!=''" style="float: right; font-size: small; margin-top:-0.5em">
      <div style="text-align: right">Modified By&nbsp;{{modifiedBy}}</div>
      <div style="text-align: right; margin-top:-0.25em">Modified Date&nbsp;{{modifiedDate}}</div>
    </div>
  </div>
</div>
