export class AircraftEquipmentModel {
  aircraftGuid: string;
  aircraftEquipmentParentID: number;
  aircraftEquipmentChildID: number;
  aircraftEquipmentParentDescription: string;
  aircraftEquipmentChildDescription: string;
  aircraftEquipmentString: string;
  aircraftEquipmentTooltip: string;
  shortName: string;
  longName: string;
  selected: boolean;
  modifiedBy: string;
  modifiedDate: string;
  equipmentList: string;
  aircraftEquipmentSubcategory: string;
  isFreetext: boolean;
  remarks: string;
  navRemarks: string;
  comRemarks: string;
  datRemarks: string;
  surRemarks: string;
  departure: string;
  enroute: string;
  arrival: string;
  color: string;
  dinghyNumber: number;
  dinghyCapacity: number;
  dinghyColor: number;
  cover: boolean;
  polar: boolean;
  desert: boolean;
  maritime: boolean;
  jungle: boolean;
  light: boolean;
  floures: boolean;
  vhf: boolean;
  uhf: boolean;
  uhfRadio: boolean;
  vhfRadio: boolean;
  eltRadio: boolean;
  isDisabled: boolean;
}
