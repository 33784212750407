import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { TripLegModel } from '../models/trip-leg.model';
import { AuthenticateService } from '../services/authenticate.service';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { GroundStopService } from '../services/ground-stop.service';
import * as atlas from 'azure-maps-control';
import * as turf from '@turf/turf';
import { AuthenticationType, ControlPosition, ControlStyle } from 'azure-maps-control';
import { CustomerService } from '../services/customer.service';
import { CompanyEventModel } from '../models/company-event.model';
import { HeadlineModel } from '../models/headline.model';
import { CompanyEventDialogComponent } from '../current-events/company-event-dialog.component';
import { HeadlineDialogComponent } from '../current-events/headline-dialog.component';
import { AircraftDocumentModel } from '../models/aircraft-document.model';
import { GroundStopManifestDocumentModel } from '../models/ground-stop-manifest-document.model';
import { CustomerDocumentModel } from '../models/customer-document.model';
import { TripDashboardModel } from '../models/trip-dashboard';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { ClientAlertDialogComponent } from './client-alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { Sort } from '@angular/material/sort';
import { TripRevisionModel } from '../models/trip-revision.model';
import { ChecklistDashboardClientDialogComponent } from './checklist-dashboard-client-dialog.component';
import { TripAuditDialogComponent } from '../ground-stops/trip-audit-dialog.component';
import { TripModel } from '../models/trip.model';
import { CustomerModel } from '../models/customer.model';
import { map, startWith } from 'rxjs/operators';
import { ResponseModel } from '../models/response.model';
import { CommonService } from '../services/common.service';
import { IconLegendDialogComponent } from '../common-utility/icon-legend-dialog.component';
import { CustomerGroupService } from '../services/customer-group-service';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CompanyEventEditDialogComponent } from '../current-events/company-event-edit-dialog.component';

export interface LegRevisions {
  tripCode: string;
  revisions: TripRevisionModel[];
}

export interface HeadlineGroup {
  headlineType: string;
  headlines: HeadlineModel[];
}

@Component({
  selector: 'app-current-events-dashboard-client',
  templateUrl: './current-events-dashboard-client.component.html',
  styleUrls: ['./current-events-dashboard-client.component.css']
})
/** current-events-dashboard-client component*/
export class CurrentEventsDashboardClientComponent implements OnInit {
/** current-events-dashboard-client ctor */

  currents_event_dashboard_form: UntypedFormGroup;
  recordList: TripLegModel[];
  eventList: CompanyEventModel[];
  expiredACDocList: AircraftDocumentModel[];
  expiredCustomerDocList: CustomerDocumentModel[];
  expiredTravelDocList: GroundStopManifestDocumentModel[];
  missingTravelDocuments: GroundStopManifestModel[];
  waitingTaskList: GroundStopTaskModel[];
  manifestList: GroundStopManifestModel[];
  missingCrewList: TripLegModel[] = [];
  missingPaxList: TripLegModel[] = [];
  legRevisions: LegRevisions[] = [];
  queryHour: string = "168";
  tripRevisionList: TripRevisionModel[] = [];
  displayedColumn: string[] = ['trip_code', 'registration', 'departure_date', 'crew_count', 'pax_count', 'dep_vendor_name', 'ete', 'leg', 'arrival_date', 'arr_vendor_name', 'icon'];
  showSpin: boolean = false;
  points: any = [];
  tripCount: number=0;
  clickedRows = new Set<TripLegModel>();
  map: atlas.Map;
  upPII: boolean = false;
  sortedData: TripLegModel[];
  customerName: string;
  headlineList: HeadlineModel[];
  headlines: HeadlineGroup[] = [];
  showClientList: boolean = false;
  showClientButton: boolean = false;
  clientList: CustomerModel[];
  selectedClientCountText: string = "";
  selectedClientList: CustomerModel[] = [];
  customerGUIDList: string = "";
  filteredClientList: Observable<CustomerModel[]>;
  userType: string;
  customerList: CustomerModel[];
  customerGuid: string = "";
  onHoldList: TripLegModel[] = [];
  holdTripCount: number = 0;
  holdTaskCount: number = 0;
  grantAccessToAccountGrouping: boolean = false;
  grantAccessCompanyEvents: boolean = false;
  grantAccessAircraftProfiles: boolean = false;
  grantAccessCompanyProfiles: boolean = false;
  constructor(private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopClientService: GroundStopClientService, private readonly _commonService: CommonService, private readonly _customerGroupService: CustomerGroupService,
    private readonly _customerService: CustomerService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog     ) {

  }

  getAllData(): Observable<any[]> {
    let request = new TripLegModel();
    request.queryHour = Number(this.queryHour);
    if (this.userType == "internal") {
      request.customerGUID = Array.prototype.map.call(this.selectedClientList, s => s.customerGUID).toString();
    }
    let getTripScheduleReponse = this._groundStopClientService.getTripScheduleListBycId(request);
    let companyEvent = new CompanyEventModel();
    companyEvent.includeExpired = false;
    companyEvent.isActive = true;
    companyEvent.isDisplayOnly = true;
    let getCompanyEventResponse = this._customerService.getCompanyEventListBycId(companyEvent);
    let getHeadlineResponse = this._customerService.getHeadlineListBycId();
    let getClistResponse;
    if (this.grantAccessToAccountGrouping) {
      let req = new CustomerGroupModel();
      getClistResponse = this._customerGroupService.getSelectedCustomerListBycgrpId(req);
    }
    else {
      getClistResponse = of(null);
    }
    return forkJoin([getTripScheduleReponse, getCompanyEventResponse, getHeadlineResponse, getClistResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }
    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }
    if (localStorage.getItem('up.acPro') == 'true') {
      this.grantAccessAircraftProfiles = true;
    }
    if (localStorage.getItem('up.cPro') == 'true') {
      this.grantAccessCompanyProfiles = true;
    }
    this.customerName = localStorage.getItem('cn');
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (localStorage.getItem('up.cEvent') != 'true')
      this.grantAccessCompanyEvents = false;
    else
      this.grantAccessCompanyEvents = true;
    this.getData();

  }

  initControls() {
    this.currents_event_dashboard_form = this._formBuilder.group({
      clientSearch: "",
      customer_select: this.customerGuid
    });
  }

  get f() { return this.currents_event_dashboard_form.controls; }

  getData() {
    this.recordList = [];
    this.expiredACDocList = [];
    this.expiredTravelDocList = [];
    this.missingTravelDocuments = [];
    this.manifestList = [];
    this.sortedData = [];
    this.legRevisions = [];
    this.tripRevisionList = [];
    this.headlines = [];
    this.onHoldList = [];
    this.holdTaskCount = 0;
    this.holdTripCount = 0;
    this.tripCount = 0;
    if (this.map != undefined) 
      this.map.dispose();
    this.clickedRows = new Set<TripLegModel>();
    this.showSpin = true;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let tripDashboard = <TripDashboardModel>responses[0].model;
          this.recordList = tripDashboard.tripLegs;
          if (this.recordList!=null) {
            this.sortedData = this.recordList.slice();
            tripDashboard.tripLegs.sort((a, b) => a.tripCode.localeCompare(b.tripCode));
          }
          this.expiredACDocList = tripDashboard.expiredAircraftDocuments;
          this.expiredCustomerDocList = tripDashboard.expiredCustomerDocuments;
          if (tripDashboard.tripLegs != null) {
            this.missingCrewList = tripDashboard.tripLegs.filter(x => x.departureCrewCount == 0);
            this.missingPaxList = tripDashboard.tripLegs.filter(x => x.departurePaxCount == 0 && !x.isDeptFerryFlight);
            this.onHoldList = tripDashboard.tripLegs.filter(x => x.departureOnHold || x.nextArrivalOnHold);
            this.holdTripCount = Array.from(new Set(this.onHoldList.map((item) => item.tripCode))).length;
            Array.from(new Set(this.onHoldList.map((item) => item.departureGroundStopTaskList))).forEach(v => {
              this.holdTaskCount += v.length;
            });
            Array.from(new Set(this.onHoldList.map((item) => item.nextArrivalGroundStopTaskList))).forEach(v => {
              this.holdTaskCount += v.length;
            });
            let tempTripCode: string = "";
            tripDashboard.tripLegs.forEach(x => {
              if (tempTripCode != x.tripCode) {
                tempTripCode = x.tripCode;
                this.tripCount += 1;
              }
              x.departureManifest.forEach(y => {
                this.manifestList.push(y);
              });
              x.arrivalManifest.forEach(y => {
                this.manifestList.push(y);
              });
            });
            this.manifestList.forEach(x => {
              x.groundStopManifestDocumentList.forEach(y => {
                if (y.isExpiredForGroundStop) {
                  if (!this.expiredTravelDocList.includes(y))
                    this.expiredTravelDocList.push(y);
                }
              });
            });
            this.missingTravelDocuments = this.manifestList.filter(x => x.hasRequiredDocument == false);
            this.waitingTaskList = tripDashboard.waitingTaskList;
            tripDashboard.tripRevisionList.forEach(x => {
              x.groundStopList.forEach(y => {
                if (y.tripRevisionList.length > 0)
                  this.tripRevisionList = this.tripRevisionList.concat(y.tripRevisionList);
              });
            });
            this.tripRevisionList.sort(function (a, b) {
              if (a.tripCode < b.tripCode) { return -1; }
              if (a.tripCode > b.tripCode) { return 1; }
              return 0
            });
            let temptripCode;
            this.tripRevisionList.forEach(x => {
              if (temptripCode != x.tripCode) {
                temptripCode = x.tripCode;
                this.legRevisions.push({ tripCode: temptripCode, revisions: this.tripRevisionList.filter(y => y.tripCode == temptripCode) });
              }
            });
          }
         
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
        this.createMap();
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.eventList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.headlineList = responses[2].model;
          let tempType;
          //this.headlineList.sort(function (a, b) {
          //  if (a.headlineTypeDescription < b.headlineTypeDescription) { return -1; }
          //  if (a.headlineTypeDescription > b.headlineTypeDescription) { return 1; }
          //  return 0
          //});   
          this.headlineList.forEach(x => {
            if (tempType != x.headlineTypeDescription) {
              tempType = x.headlineTypeDescription;
              this.headlines.push({ headlineType: tempType, headlines: this.headlineList.filter(y => y.headlineTypeDescription == tempType) });
            }
          });
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.customerList = responses[3].model;
          if (this.userType == "customer" && this.grantAccessToAccountGrouping) {
            if (this.customerGuid == "")
              this.customerGuid = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.initControls();
      this.showSpin = false;
    });
  }

  createMap() {
    this.map = new atlas.Map('map', {
      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      var zoomControl = new atlas.control.ZoomControl();

      /* Add the zoom control to the map*/
      self.map.controls.add(zoomControl, {
        position: ControlPosition.TopRight
      });      

      if (self.recordList.length > 0) {
        self.recordList.forEach(v => {
          if (self.clickedRows.size == 0 || self.clickedRows.has(v)) {
            var startPosition = [v.departureLongitude, v.departureLatitude];
            var endPosition = [v.nextArrivalLongitude, v.nextArrivalLatitude];
            var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
              title: v.departureICAO,
              name:v.departureAirportName
            });

            var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
              title: v.nextArrivalICAO,
              name:v.nextArrivalAirportName
            });
            self.points.push(startPosition);
            self.points.push(endPosition);
            var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
            var poly = new atlas.data.LineString(path);
            dataSource.add([poly, startPoint, endPoint]);
          }
        });
        var popupTemplate = "<div class='popup'>{name}</div>";
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            image: 'pin-round-darkblue',
            cluster: false,
            optional: true,
            allowOverlap: true,
            size: .5
          },
          textOptions: {
            textField: ['get', 'title'],
            offset: [0, -1.5],
            cluster: false,
            allowOverlap: true,
            size: 10
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        });
        self.map.layers.add([new atlas.layer.LineLayer(dataSource, null, {
          strokeColor: 'black',
          strokeWidth: 1.5
        }),
          symbolLayer
        ]);
        var popup = new atlas.Popup({
          pixelOffset: [0, -18],
          closeButton: false
        });
        //Add a hover event to the symbol layer.
        self.map.events.add('mouseover', symbolLayer, function (e) {
          //Make sure that the point exists.
          if (e.shapes && e.shapes.length > 0) {
            var pos = e.position;
            var offset = [0, 0];
            var properties;
            var content, coordinate;
            
            if (e.shapes[0] instanceof atlas.Shape) {
              properties = e.shapes[0].getProperties();

              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].getType() === 'Point') {
                coordinate = e.shapes[0].getCoordinates();
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            } else {
              properties = e.shapes[0].properties;
              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].type === 'Point') {
                coordinate = e.shapes[0].geometry.coordinates;
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            }
            popup.setOptions({
              //Update the content of the popup.
              content: content,

              //Update the popup's position with the symbol's coordinate.
              position: coordinate

            });
            //Open the popup.
            popup.open(self.map);
          }
        });

        self.map.events.add('mouseleave', symbolLayer, function () {
          popup.close();
        });

        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        self.map.setCamera({
          bounds: bbox,
          padding: 40
        });
      }


    });
  }

  clickRow(row:any) {
    if (this.clickedRows.has(row))
      this.clickedRows.delete(row);
    else {
      this.clickedRows = new Set<TripLegModel>();
      this.clickedRows.add(row);
    }
    this.map.dispose();
    this.createMap();
  }
  openCompanyEvent(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { companyEventID:id };
    this._dialog.open(CompanyEventDialogComponent, dialogConfig);
    

  }

  openHeadline(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { headlineID: id };
    this._dialog.open(HeadlineDialogComponent, dialogConfig);

  }

  openAlert(obj: any, type: string) {
    if ((type == "aircraft" && this.grantAccessAircraftProfiles == false) ||
      (type == "customer" && this.grantAccessCompanyProfiles == false) || ((type == "expiredManifest" || type == "missingManifest") && this.upPII == false))
      return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { alertObject: obj, alertType: type, manifest:this.manifestList, legs:this.recordList};
    this._dialog.open(ClientAlertDialogComponent, dialogConfig);

  }

  openChecklist(obj: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = "70em";
    //dialogConfig.panelClass = "custom-dialog-container2";
    if (this.userType == 'internal')
      obj.customerGUID = Array.prototype.map.call(this.selectedClientList, s => s.customerGUID).toString();
    dialogConfig.data = { leg: obj };
    this._dialog.open(ChecklistDashboardClientDialogComponent, dialogConfig);

  }


  openTrip(p: TripModel) {
    window.open('/ground-stops-client/trip-details-client?tripId=' + p.tripCodeGUID + '&aircraftId=' + p.aircraftGUID + '&pf=&tType=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }

  openTripByLeg(p: TripLegModel) {
    window.open('/ground-stops-client/trip-details-client?tripId=' + p.tripCodeGUID + '&aircraftId=' + p.aircraftGUID + '&pf=&tType=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }

  sortData(sort: Sort) {
    const data = this.recordList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'trip_code':
          return compare(a.tripCode, b.tripCode, isAsc);
        case 'registration':
      return compare(a.registration, b.registration, isAsc);
        case 'departure_date':
          return compare(new Date(a.departureDateTimeUTCText), new Date(b.departureDateTimeUTCText), isAsc);
        case 'crew_count':
          return compare(a.departureCrewCount, b.departureCrewCount, isAsc);
        case 'pax_count':
          return compare(a.departurePaxCount, b.departurePaxCount, isAsc);        
        case 'ete':
          return compare(a.ete, b.ete, isAsc);
        case 'leg':
          return compare(a.departureICAO, b.departureICAO, isAsc);
        case 'arrival_date':
          return compare(new Date(a.nextArrivalDateTimeUTCText), new Date(b.nextArrivalDateTimeUTCText), isAsc);
        default:
          return 0;
      }
    });

    function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  openAudit(revision:LegRevisions) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: revision.revisions[0].customerGUID, aircraftGUID: revision.revisions[0].aircraftGUID, tripCodeGUID: revision.revisions[0].tripCodeGUID, groundStopGUID: revision.revisions[0].groundStopGUID,
      pastHours: 24,
      userName: "", v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  openLink(link: string) {
    let url: string = '';
    if (!/^http[s]?:\/\//.test(link)) {
      link = '//' + link;
    }

    window.open(link, '_blank');
  }

  // manifest
  clickClientFilter() {
    this.getClientList();
  }

  getClientList() {
    this.showClientList = true;
    this.clientList = [];

    this._commonService.getCustomerListByType<ResponseModel<CustomerModel[]>>(1).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let c = new CustomerModel();
          response.model.forEach(x => {
            c = new CustomerModel();
            c.customerName = x.customerName
            c.customerGUID = x.customerGUID;
            c.selected = false;
            if (this.selectedClientList.length > 0) {
              let f: CustomerModel[];
              f = this.selectedClientList.filter(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
              if (f.length > 0) {
                c.selected = true;
              }
            }
            this.clientList.push(c);
          });
          this.filteredClientList = this.f.clientSearch.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.customerName)),
            map(customerName => (customerName ? this._filter(customerName) : this.clientList.slice())),
          );
          this.setClientList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayName(customer: CustomerModel): string {
    return customer && customer.customerName ? customer.customerName : '';
  }

  private _filter(name: string): CustomerModel[] {
    const filterValue = name.toLowerCase();

    return this.clientList.filter(option => option.customerName.toLowerCase().includes(filterValue));
  }

  checkClientChange(e: any, item: CustomerModel) {
    this.clientList.forEach(x => {
      x.selected = false;
      if (item.customerGUID == x.customerGUID) {
        x.selected = e.checked;
      }
    });
   
   
    this.setClientList();
  }

  setClientList() {
    this.selectedClientList = this.clientList.filter(u => u.selected == true);
    this.selectedClientCountText = "";
    if (this.clientList.length > 0) {
      if (this.selectedClientList.length == this.clientList.length) {
        this.selectedClientCountText = ": All";
      }
      else {
        if (this.selectedClientList.length > 0)
          this.selectedClientCountText = ": " + this.selectedClientList[0].customerName;
      }
    }
  }

  clickDoneClient() {
    this.showClientList = false;
    this.getData();
  }

  clickResetClient() {
    this.clientList.forEach(x => {
      x.selected = false;
    });
    this.setClientList();
  }


  openLegend() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    let legendType: string = "checkList";
    dialogConfig.data = {
      dialogTitle: s, legendType: legendType
    };

    const dialogRef = this._dialog.open(IconLegendDialogComponent, dialogConfig);

  }

  customerChange(e: any) {
    this.customerName = e.target.options[e.target.options.selectedIndex].text
    let companyEvent = new CompanyEventModel();
    companyEvent.includeExpired = false;
    companyEvent.isActive = true;
    companyEvent.isDisplayOnly = true;
    companyEvent.customerGUID = this.f.customer_select.value;
    this._customerService.getCompanyEventListBycId<ResponseModel<CompanyEventModel[]>>(companyEvent).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.eventList = response.model;
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    })
  }

  addCompanyEvent() {
    if (!this.grantAccessCompanyEvents)
      return;
    let s = "";
    let companyEventID = 0;
    s = "ADD New Record";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    if (this.grantAccessToAccountGrouping)
      dialogConfig.data = { dialogTitle: s, companyEventID: companyEventID, customerGUID: this.f.customer_select.value };
    else
      dialogConfig.data = { dialogTitle: s, companyEventID: companyEventID };
    const dialogRef = this._dialog.open(CompanyEventEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let companyEvent = new CompanyEventModel();
        companyEvent.includeExpired = false;
        companyEvent.isActive = true;
        companyEvent.isDisplayOnly = true;
        if (this.grantAccessToAccountGrouping)
          companyEvent.customerGUID = this.f.customer_select.value;
        this._customerService.getCompanyEventListBycId<ResponseModel<CompanyEventModel[]>>(companyEvent).subscribe(response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              this.eventList = response.model;
            }
            else {
              if (response.code == "401") {
                this._authService.signOut();
              }
            }
          }
        });       
      }
    });
  }
}
