<!--<mat-toolbar color="primary" style="display: flex; justify-content:space-between;">-->
  <div color="primary" style="display: flex; justify-content:space-between; margin-left: 0px; background-color: #cc0000">
    <div class="row" style="width: 335px; padding:0">
      <div class="col-9 align-self-center">
        <img src="../../assets/images/fpilogo.png" style="width: 230px; height: 80px;" />
      </div>
      <div class="col-3 align-self-center">
        <ul class="toolbar">
          <li>
            <a style="cursor: pointer" (click)="onToggleSidenav()">&#9776;</a>
          </li>
        </ul>
      </div>
    </div>
    <div style="align-items:center; color: white; margin-top: auto; margin-bottom: auto;">
      <h5> {{customerName}}</h5>
    </div>
    <div style="float: right; color: white;margin-top: auto; margin-bottom: auto; margin-right: 10px;">
      <ul class="toolbar">
        <li>
          {{username}} &nbsp;&nbsp; 
          <a *ngIf="!login" [routerLink]="['/user-accounts/logon']">Sign In</a>
          <a *ngIf="login" [routerLink]="['/user-accounts/logout']">Sign Out</a>
        </li>
        <li *ngIf="login && pwdExpirationDate!='' && pwdExpirationDate!='null'">
          <!--<h6><small>Password Expiration Date: {{ pwdExpirationDate | date: 'mediumDate' }}</small></h6>--><!--both work-->
          <h6><small>Password Expiration Date: {{ pwdExpirationDate }}</small></h6>
        </li>
      </ul>
    </div>
  </div>
