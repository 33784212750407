<div class="divLeft" *ngIf="ua==1" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isPopup">
  <div>
    <div style="display: inline-flex; width: 100%; justify-content: space-between;">
      <h5 style="display: inline-flex;">ADMIN SERVICES \ Reset User Password <h5 *ngIf="selectedUserType === 'internal'">&nbsp;- FPC Operations</h5></h5>
      <div *ngIf="isPopup" style="display: flex">
        <button mat-icon-button (click)="openAdminServicesComponent()"> <i class="material-icons-outlined"
            style="font-size:xx-large;  ">arrow_circle_left</i> </button>
        <button mat-icon-button mat-dialog-close><i class="material-icons" style="font-size: x-large;">close
          </i></button>
      </div>
    </div>

    <div>
      <form *ngIf="reset_password_admin_form" [formGroup]="reset_password_admin_form" (ngSubmit)="onSubmit()">
        <div *ngIf="userType=='internal' && !isPopup" class="form-group form-inline">
          <ul class="tabul">
            <li [ngClass]="{ 'btn-high':selectedUserType=='internal','btn-low pointer':selectedUserType!='internal'  }"
              style="border-left: blue 0.0625em solid;" (click)="clickUserType('internal')">
              <div style="margin-top:0.25em">FPI Operations</div>
            </li>
            <li [ngClass]="{ 'btn-high':selectedUserType=='customer','btn-low pointer':selectedUserType!='customer' }"
              (click)="clickUserType('customer')">
              <div style="margin-top:0.25em">Client</div>
            </li>
            <li [ngClass]="{ 'btn-high':selectedUserType=='vendor','btn-low pointer':selectedUserType!='vendor' }">
              <div style="margin-top:0.25em">Vendor</div>
            </li>
          </ul>
        </div>
        <div *ngIf="userType=='internal'" style="margin-top:-0.5em">
          <select *ngIf="f && selectedUserType=='customer' && !isPopup" formControlName="customer_select"
            style="font-size:small;width:29.5em;border-radius:0.25em; border-color:lightgrey"
            (change)="customerChange($event)">
            <option value="">Choose a client</option>
            <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
          </select>
          <div style="display: inline-flex; width: 100%; justify-content: space-between;">
            <h5 *ngIf="isPopup" style="color: #1976e6;">{{selectedCustomerName}}</h5>
            <div style="font-size: small; padding: 0.5em 0em 0em 0em;">
              {{totalRecordsText}}
            </div>
          </div>
          

          <select *ngIf="f && selectedUserType=='vendor'" formControlName="vendor_select"
            style="font-size:small; width:29.5em;border-radius:0.25em; border-color: lightgrey"
            (change)="vendorChange($event)">
            <option value="">Choose a vendor</option>
            <option *ngFor="let vdr of vendorList" value={{vdr.vendorGUID}}>{{vdr.vendorNameCom}}</option>
          </select>
        </div>
        <!---->
        <div *ngIf="userType=='customer' && grantAccessToAccountGrouping && customerList.length>0"
          style="margin-top:-0.5em; display: flex">
          <div style="font-size: small; margin-right:0.5em">Select Client</div>
          <div>
            <select *ngIf="f" formControlName="customer_select"
              style="font-size:small;width:29.5em;border-radius:0.25em; border-color:lightgrey"
              (change)="customerChange($event)">
              <!--<option value="">Choose a client</option>-->
              <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
            </select>

          </div>
        </div>
        <!---->
        <div *ngIf="!isPopup" style="margin-top:0em; display: flex">
          <div style="margin-right: 0.625em">
            <label for="username" style="font-size:small">Search by Username</label>
            <div class="form-group-sm" style="margin-top:-0.5em">
              <input type="text" formControlName="username" class="form-control" style="width:9.375em; height:1.5625em"
                maxlength="30" />
            </div>
          </div>
          <div style="margin-right: 0.625em">
            <label for="email" style="font-size:small; white-space:nowrap">Search by Email Address</label>
            <div class="form-group-sm" style="margin-top:-0.5em">
              <input type="email" formControlName="email" class="form-control" style="width: 9.375em; height: 1.5625em"
                maxlength="30" />
            </div>
          </div>
          <div style="margin-right: 0.625em; margin-top:1.4em">
            <button [disabled]="loading" mat-button class="btn-high"><span *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"></span>Search</button>&nbsp;
            <button type="button" mat-button class="btn-low" (click)="clickShowAllUsers()">Show All Records</button>

          </div>
          <div style="margin-left: 6.25em;margin-top:1.65em; font-size: small">{{totalRecordsText}}</div>
        </div>
        <div *ngIf="errMsg!=''" class="d-block"><label style="color:red">{{errMsg}}</label></div>
        <div *ngIf="msg!=''" class="d-block"><label style="color:forestgreen">{{msg}}</label></div>
        <div style="margin-top:0.25em">
          <mat-table style="margin-left:0em;padding:0" *ngIf="userList && userList.length>0" [dataSource]="userList"
            class="example-container mat-elevation-z8">
            <ng-container matColumnDef="username">
              <mat-header-cell *matHeaderCellDef>Username</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.username}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.email}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="effectiveDate">
              <mat-header-cell *matHeaderCellDef>Effective Date</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.effectiveDate}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="expirationDate">
              <mat-header-cell *matHeaderCellDef>Expiration Date</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.expirationDate}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
              <mat-cell *matCellDef="let element; let i=index " style="color: blue">
                <div *ngIf="element.showResetPwdBtn==true">
                  <a mat-button *ngIf="!element.hasResetPwd" class="btn-high grid" [disabled]="element.loading==true"
                    (click)="clickResetPwd($event, element);">
                    <span *ngIf="element.loading" class="spinner-border spinner-border-sm mr-1"
                      style="color:white"></span>
                    Reset
                  </a>
                  <span *ngIf="element.hasResetPwd">Sent</span>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumn"></mat-row>
          </mat-table>
        </div>
        <!--<mat-paginator #paginator *ngIf="userList && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                 [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
  </mat-paginator>-->

      </form>
    </div>

  </div>
</div>
<div style="z-index:100; top: 11em ; left: 20em; position: absolute; min-width: 30em">
  <app-spin *ngIf="showSpin"></app-spin>
</div>