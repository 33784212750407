import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, Inject, OnInit, Optional, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
//import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs'; 
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service'; 
import { DialogService } from '../services/dialog.service';
import { FuelService } from '../services/fuel.service';
import { Title } from '@angular/platform-browser';  
import { ReviseFuelPricingModel } from '../models/revise-fuel-pricing.model';
import { FuelpricingComponent } from '../fuelpricing/fuelpricing.component';
//import { BusinessRulesEditComponent } from './business-rules-edit.component';
import Chart from 'chart.js';
import { FuelChartModel } from '../models/fuel-chart.model.';
import { TaxesAndFeesDescriptionDialog } from './taxesandfees-description-dialog.component';
import { FuelExchangeRateMarkupComponent } from './fuel-markup-exchange.component';
import { FuelClientMarkupComponent } from './fuel-client-markup.component';
import { CurrencyComponent } from '../currency/currency.component';
import { MissingPricesAirportComponent } from './missing-prices-airport.component';
import { FuelSupplierComponent } from './fuel-supplier-profile.component';
import { FuelCommissionsComponent } from './fuel-commissions.component';
import { MissingTaxesAirportComponent } from '../taxesandfees/missing-taxes-airport.component';
import { ReviseFuelPricingComponent } from './revise-fuel-pricing.component';


@Component({
  selector: 'app-fuel-menu',
  templateUrl: './fuel-menu.component.html',
  styleUrls: ['./fuel-menu.component.css']
})



export class FuelMenuComponent implements OnInit {
  reviseFuelPricing_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  revisefuelpricelist: ReviseFuelPricingModel[];

  //displayedColumn: string[] = ['IATA', 'ICAO', 'FuelSupplierShortname' ]; 
  scrHeight: number;
  websiteEnvironment: string = "";
  grantAccessToFuel: boolean = false;
  fromFuelPricingSummary: boolean;
  chart: Chart;

  @ViewChild('MyChart') canvas: any;
  isDialog: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, 
    private _router: Router, private readonly _formBuilder: UntypedFormBuilder, 
    @Optional() private readonly _dialogRef: MatDialogRef<FuelMenuComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _dialog: MatDialog,
    private readonly _fuelService: FuelService, private _titleService: Title, private readonly _dialogService: DialogService) {

      if (this.data) {
        this.isDialog = true;
      }
  }

  //getAllData(): Observable<any[]> {
  //  let getReviseFuelPricing = this._fuelService.getReviseFuelPricing<ResponseModel<ReviseFuelPricingModel[]>>(); 
  //  //let getCountryResponse = this._commonService.getCountryListWithUSAFirst<ResponseModel<CountryModel[]>>();
  //  //let getHotelChainResponse = this._commonService.getHotelChainList<ResponseModel<HotelChainModel[]>>();
  //  return forkJoin([getReviseFuelPricing]);

  //}

  ngOnInit() {
    this.grantAccessToFuel = false;
    if (localStorage.getItem('up.fuel') == 'true') {
      this.grantAccessToFuel = true;
    }


    if (!this.grantAccessToFuel) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }
    //this.getAllData().subscribe(responses => {
    //  if (responses[0] != null) {
    //    if (responses[0].code == "200" && responses[0].message == "") {
    //      this.revisefuelpricelist = responses[0].model;
    //    }
    //    else {
    //      if (responses[0].code == "401") {
    //        this._authService.signOut();
    //      }
    //    }
    //  }





    //});




    this.initControls();

    this.showSpin = false;

    //this.getSearchResults();

    //switch (this.websiteEnvironment) {
    //  case "DEV":
    //    this._titleService.setTitle("Fuel Menu");
    //    break;
    //  case "QA":
    //    this._titleService.setTitle("Fuel Menu");
    //    break;
    //  default:
    //    // this._titleService.setTitle("Trip Schedules");
    //    this._titleService.setTitle("Fuel Menu");
    //}
// setTimeout(() => {
  
//   this.createChart();
// }, 50);
  }

  initControls() {
    this.reviseFuelPricing_form = this._formBuilder.group({
      fuelSupplierName: [''],
      fuelSupplierShortname: [''],
      //airport: [''],
      //city: [''],
      //country_select:[''], 
    });
  }

  get f() { return this.reviseFuelPricing_form.controls; }

  clickRouteNew1(route: string, display: string) {
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
   
  }

  importFuelPricing() {
    let s = "";
    //  const dialogConfig = new MatDialogConfig();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "76em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    // dialogConfig.disableClose = true;
    this.fromFuelPricingSummary = true;
    dialogConfig.hasBackdrop = false;


    dialogConfig.data = {
      dialogTitle: s, fromFuelPricingSummary: this.fromFuelPricingSummary
    };


    const dialogRef = this._dialog.open(FuelpricingComponent, dialogConfig);
    // dialogRef.afterClosed().subscribe(result => {


    // });
  }


  
  clickOpenInNew( ){
    this.clickClose();
    window.open('fuelmenu/fuelmenu' + "?v=" + this._authService.getCurrentTimeNumber());
  }
  
  
  clickOpenInNewTab( routePage: string, display: string){
    this.clickClose();
    window.open(routePage + "?v=" + this._authService.getCurrentTimeNumber());
  }
  
  clickRouteNew(routePage: string, display: string) {
    if (routePage != 'fuelpricing/fuelpricing')
      this._router.navigate([routePage]);
    else
      this.importFuelPricing();
    this.clickClose();
  }

  // clickRouteInNew(routePage: string, display: string) {
   
  //   this._router.navigate([routePage]);
  
  //   this.clickClose();
  // }

  clickClose() {
    if(this.isDialog){

      this._dialogRef.close(true);
    }
  }

  
  createChart2() {

    this._fuelService.getCountryChartData<ResponseModel<FuelChartModel[]>>().subscribe(resp => {

      if (resp.code == '200') {
        var ctx = this.canvas.nativeElement;
        let fuelSuppliers:string[]=[];
        let counts:number[]=[];
        resp.model.forEach(cd => {
          fuelSuppliers.push(cd.fuelSupplierName);
          counts.push(cd.count);
        });

        this.chart = new Chart(ctx, {
          type: 'horizontalBar',
          data: {
            labels: ['A', 'B', 'C', 'd','e'],
            datasets: [{
              label: 'Easy as',
              data: [2, 2, 3,2,2],
            }],
          },
          options: {
            // indexAxis: 'y', // <-- here
            responsive: true
          }

        });
      }

    });
  }

  createChart() {

    this._fuelService.getCountryChartData<ResponseModel<FuelChartModel[]>>().subscribe(resp => {

      if (resp.code == '200') {
        var ctx = this.canvas.nativeElement;
        let fuelSuppliers:string[]=[];
        let counts:number[]=[];
        resp.model.forEach(cd => {
          fuelSuppliers.push(cd.fuelSupplierName);
          counts.push(cd.count);
        });

        this.chart = new Chart(ctx, {
          type: 'horizontalBar', //this denotes tha type of chart

          data: {// values on X-Axis
            labels: fuelSuppliers,
            datasets: [
              {
                label: "fuelSuppliers",
                data:  counts,
                backgroundColor: 'lightblue'
                
              }
            ]
          },
          options: {
            // aspectRatio: 2.5,
            responsive: true, 
          }

        });
      }

    });
  }


  
  createChart1() {

    this._fuelService.getCountryChartData<ResponseModel<FuelChartModel[]>>().subscribe(resp => {

      if (resp.code == '200') {
        var ctx = this.canvas.nativeElement;
        let countries:string[]=[];
        let counts:number[]=[];
        resp.model.forEach(cd => {
          countries.push(cd.countryName);
          counts.push(cd.count);
        });

        this.chart = new Chart(ctx, {
          type: 'doughnut',

          data: {// values on X-Axis
            labels: ['A','B','C', 'D', 'E'],
            datasets: [
              {
                label: "countries",
                data: [1,2,3,4,5],
                backgroundColor: 'lightblue'
                
              }
            ]
          },          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Chart.js Doughnut Chart'
              }
            }
          },

        });
      }

    });
  }


  openTaxesAndFeesDescriptionDialog() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "43em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    this.clickClose(); 
    config.data = { v: this._authService.getCurrentTimeNumber(), dialogFrom: 'fuel' };
    this._dialog.open(TaxesAndFeesDescriptionDialog, config);
  }

  
  openFuelExchangeRateMarkupComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "43em";//"1000px";
    config.width = "56em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this.clickClose(); 
    this._dialog.open(FuelExchangeRateMarkupComponent, config);
  }
  openFuelClientMarkupComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "38em";//"1000px";
    config.width = "68em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this.clickClose();

    this._dialog.open(FuelClientMarkupComponent, config);
  }
  openCurrencyComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "43em";//"1000px";
    config.width = "58em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    this.clickClose();
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(CurrencyComponent, config);

  }

  openMissingPricesComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "43em";//"1000px";
    config.width = "51em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    this.clickClose();
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(MissingPricesAirportComponent, config);

  }
  openFuelSupplierProfileComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "39em";//"1000px";
    config.width = "66em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    this.clickClose();
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(FuelSupplierComponent, config);

  }

  openFuelCommissionsComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "38em";//"1000px";
    config.width = "70em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    this.clickClose();
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(FuelCommissionsComponent, config);

   }

  openMissingTaxesAirportComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "38em";//"1000px";
    config.width = "68em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    this.clickClose();
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(MissingTaxesAirportComponent, config);

   }
   
  openReviseFuelPricingComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "45em";//"1000px";
    config.width = "40em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    this.clickClose();
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(ReviseFuelPricingComponent, config);

   }

}
