import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { forkJoin, Observable } from 'rxjs';
import { GlobalConstant } from '../common-utility/global-constant';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { FileModel } from '../models/file.model';
import { FlightBriefModel } from '../models/flight-brief.model';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import { ResponseModel } from '../models/response.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GenerateTaskDocumentsComponent } from './generate-task-documents.component';

@Injectable()
export class UploadTaskDocument {

  fileList: FileModel[];
  uploader: FileUploader = new FileUploader({
    url: GlobalConstant.baseUrl + "Common/uploadFiles",
    method: "post",
    authToken: 'bearer ' + localStorage.getItem('token'),
    disableMultipart: false
  });

  errMsg: string;
  @Output() public errMsgEvent = new EventEmitter();

  taskDocument: GroundStopTaskDocumentModel;
  uploadComplete: boolean = false;
  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };
  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            // this.taskDocument = new GroundStopTaskDocumentModel();
            let obj2 = new GroundStopTaskDocumentModel();

            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.documentSizeInKB = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.tempId = 1;
            obj2.groundStopTaskDocumentGUID = "";
            this.taskDocument = obj2;
          });

        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  async uploadFile(out: any, gstId: string, documentName: string, gst: GroundStopAdvancedService, dialog?: MatDialogRef<GenerateTaskDocumentsComponent, any>, tripCode?:string) {
    var files = [];
    // var taskDocument = new GroundStopTaskDocumentModel();
    let fileFromBlob: File = new File([out], documentName);
    //this.uploader.addToQueue(new Array<File>(fileFromBlob));
    //files.push(fileFromBlob);
    const groundStopAdvancedService = gst;
    this.taskDocument = new GroundStopTaskDocumentModel();

    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.taskDocument.groundStopTaskGUID = gstId;
      this.taskDocument.tripCode = tripCode;
      gst.insertGroundStopTaskDocument<ResponseModel<number>>(this.taskDocument).subscribe(response => {
        if (response != null && response.code == "200") {

          this.uploadComplete = true;
          
          if (dialog!=undefined)
            dialog.close(true);
          return true;
        }
        else {
          this.errMsg = "Error attaching document."
          this.errMsgEvent.emit(this.errMsg);
          return false;
        }
      });

      this.uploader.clearQueue();
    };

    this.uploader.addToQueue(new Array<File>(fileFromBlob));
    this.uploader.uploadAll();

  }
  
}
