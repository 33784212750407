<div  style="background-color:white;  height: 100%;width: 100%; overflow-x: auto; scrollbar-width: none;">
  <div style="padding: 0.5em; background-color:white ; height: 100%;">
    
    <div style="display: flex;padding-left:1em;" >
      <div style="margin-top:0.3125em;display: flex;">
        <span class="material-symbols-outlined" style="font-size: x-large; margin-left: -0.3em;margin-right: 0.2em;">query_stats</span><h5>Trip Closeout Queue</h5>&nbsp;&nbsp; <span style="font-size: small;margin-top: .25em;">***Excludes Billing Completed</span>
      </div>
    </div>
    
    <form *ngIf="trip_close_out_queue_form" [formGroup]="trip_close_out_queue_form">
    
    <div style="display: flex;">


      <!-- <div style="margin-top:1em; margin-left:3em;">
        <div style="display: flex; margin-left:-2em;  "  ><label for="trip_type" style="margin-right:2em; font-weight:bold">Filters</label>
        
          <div style="margin-top:0.25em; display: flex">
            <div style="font-size: small;font-weight:bold; white-space:nowrap">Enter a Date Range</div>
           </div>
        </div>
        <div style="display: flex; margin-left: 3em; margin-top:-1.25em">
          <mat-date-range-input [rangePicker]="picker" style="display: flex; margin-top:0.5em; width:10.5em !important" >
            <input matStartDate formControlName="startDate" #startDate placeholder="Start date" maxlength="10" [ngClass]="{ 'is-invalid1': isValidStartDate==false,  'is-valid1': isValidStartDate==true }" (dateChange)="dateOnchange('S', $event)" style="font-size:small;margin-top:0em; margin-right:1em;background-color: white; border-radius:0.25em;padding-left:0.125em;padding-right:0.125em; width: 11em !important">
            <input matEndDate formControlName="endDate" #endDate placeholder="End date" maxlength="10" [ngClass]="{ 'is-invalid1': !isValidEndDate,  'is-valid1': isValidEndDate }" (dateChange)="dateOnchange('E', $event)" style="font-size:small;margin-left:0em; background-color: white; border-radius:0.25em;padding-left:0.125em; width: 6em !important ">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker" style="margin-top:0.25em; margin-left:-0.7em"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
        <div style="display:flex">
          <div *ngIf="f.startDate.errors" class="small alert alert-danger p-0" style="width:max-content;margin-top:-0.5em">
            <div *ngIf="f.startDate.errors.required">Start Date is required.</div>
            <div *ngIf="f.startDate.errors.isValidDate==false">Invalid start date.</div>
          </div>
          <div *ngIf="f.endDate.errors" class="small alert alert-danger p-0" style="width:max-content;margin-top:-0.5em">
            <div *ngIf="f.endDate.errors.required">End Date is required.</div>
            <div *ngIf="f.endDate.errors.isValidDate==false">Invalid end date.</div>
          </div>
        </div> 
      </div> -->

      <div style="margin-right:1em; margin-left:1.5em; ">
        <div><label for="date_type" style="font-weight:bold">Filter by </label> trips ending</div>
        <div style="margin-top:-0.25em; display: flex;">


          <div style="display: flex; margin-left:0em;">
            <div style="margin-right:0.25em">
              <input formControlName="date_type"
                     type="radio"
                     name="date_type"
                     value="currentWeek" (change)="dateTypeChange($Event)"  />
            </div>
            <div style="margin-top:-0.2em;white-space: nowrap">Current Week</div>
          </div>

          
          <div style="display: flex; margin-left: 0.5em;">
            <div style="margin-right:0.25em">
              <input formControlName="date_type"
                     type="radio"
                     name="date_type"
                     value="15days" (change)="dateTypeChange($Event)"  />
            </div>
            <div style="margin-top:-0.2em;white-space: nowrap ">15 days out <output></output></div>
          </div>


          <div style="display: flex; margin-left: 0.5em;">
            <div style="margin-right:0.25em">
              <input formControlName="date_type"  
                     type="radio"
                     name="date_type"
                     value="30days" (change)="dateTypeChange($Event)"  />
            </div>
            <div style="margin-top:-0.2em; white-space: nowrap">30 days out</div>
          </div>
          
          <div style="display: flex; margin-left: 0.5em;">
            <div style="margin-right:0.25em">
              <input formControlName="date_type"
                     type="radio"
                     name="date_type"
                     value="choose" (change)="dateTypeChange($Event)"  />
            </div>
            <div style="margin-top:-0.2em; white-space: nowrap">Choose Month</div>
          </div>



        </div>
      </div>

  
      
      <div *ngIf="f.date_type.value == 'choose'" style="margin-top:0.5em; margin-right:0.25em;  ">
        <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedMonthCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickMonthFilter()">Month{{selectedMonthCountText}}</button>
      </div>
      
      <div style="margin-top:0.5em; margin-right:0.25em;  ">
        <button type="button" mat-button class="btn-low" style="width:10em;" [ngClass]="selectedCloseoutCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickCloseoutFilter()">Closeout Status{{selectedCloseoutCountText}}</button>
      </div>
      
      <div style="margin-top:0.5em; margin-right:0.25em">
        <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedClientCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickClientFilter()">Client{{selectedClientCountText}}</button>
      </div>
      <div style="margin-top:0.5em; margin-right:0.25em">
        <button type="button" mat-button class="btn-low" style="width:8em;" [ngClass]="selectedTripCodeCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickTripCodeFilter()">TripCode{{selectedTripCodeCountText}}</button>
      </div>
      <div style="margin-top:0.5em; margin-right:0.25em">
        <button type="button" mat-button class="btn-low" style="width:9em;" [ngClass]="selectedRegistrationCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickRegistrationFilter()">Registration{{selectedRegistrationCountText}}</button>
      </div>


      <div style="margin: 1em; margin-bottom: 0; margin-top: -2em;  margin-left: 1em;">
        <div style="margin-top:0.5em;font-weight:bold;">Include</div>
       
       
        <div style="display: flex;margin-left:-.65em">
          <div style="display: grid;">
            <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="isLockdown" (change)="onLockdownChange($event)"   style="transform:scale(.8);font-size:medium" color="primary">Lockdown</mat-slide-toggle>
            <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="isRemoveFromExternalView" (change)="removeFromExternalViewChange($event)" style="transform:scale(.8);font-size:medium" color="primary">Remove From External View </mat-slide-toggle>
          </div>
          
          <div style="display:grid;margin-left: -1em;">
            <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="isDemo" (change)="onDemoChange($event)"  style="transform:scale(.8);font-size:medium" color="primary">Demo Trips</mat-slide-toggle>
            <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="includeTBA" (change)="onincludeTBAChange($event)"  style="transform:scale(.8);font-size:medium" color="primary"> TBA</mat-slide-toggle>
            <!-- <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="isOnHoldTrip" (change)="OnHoldChange($event)" style="transform:scale(.8);font-size:medium" color="primary">On Hold Trips</mat-slide-toggle> -->
          </div>
          
        </div>
      </div>


<!-- 
      <div style="margin-right:1em; margin-left:5em; ">
        <div><label for="trip_type" style="font-weight:bold">Include Trips</label></div>
        <div style="margin-top:-0.25em; display: flex;">


          <div style="display: flex; margin-left:0em;">
            <div style="margin-right:0.25em">
              <input formControlName="trip_type"
                     type="radio"
                     name="trip_type"
                     value="all" (change)="serviceTypeChange2($Event)"  />
            </div>
            <div style="margin-top:-0.2em;white-space: nowrap">All</div>
          </div>

          
          <div style="display: flex; margin-left:1em;">
            <div style="margin-right:0.25em">
              <input formControlName="trip_type"
                     type="radio"
                     name="trip_type"
                     value="lockdown" (change)="serviceTypeChange2($Event)"  />
            </div>
            <div style="margin-top:-0.2em;white-space: nowrap ">Lockdown</div>
          </div>


          <div style="display: flex; margin-left:1em;">
            <div style="margin-right:0.25em">
              <input formControlName="trip_type"
                     type="radio"
                     name="trip_type"
                     value="removeFromExternalView" (change)="serviceTypeChange2($Event)"  />
            </div>
            <div style="margin-top:-0.2em; white-space: nowrap">Remove From External View</div>
          </div>




        </div>
      </div> -->

      <div   > 
        <div style="display: flex; margin-top: -0.5em; "> 
          <!-- <button class="btn-high" mat-button (click)="getData()" style=" background-color:grey; color: white; margin-left: 1em; height: 2em;">Search</button> -->
          <button class="btn-low" mat-button (click)="getData()" style="background-color:grey; color: white; margin-left: 1em; height: 2em;"><i class="material-icons" style="font-size: larger;cursor: pointer; ">refresh</i>Refresh</button>
          <button class="btn-low" mat-button (click)="clickReset()" style="background-color:grey; color: white; margin-left: 1em; height: 2em;"><i class="material-icons" style="font-size: larger;cursor: pointer; ">restart_alt</i>Reset</button>
          <button mat-icon-button   style="margin: -0.5em 0 0 0.5em; " (click)="openAccountingDashboardComponent()" >  <i class="material-icons-outlined" style="font-size:xx-large; ">arrow_circle_left</i> </button>    
        </div>
        <div style="display: flex; margin-top: 1em;" >
          
          
          <div style="width:8em; "><app-spin *ngIf="showSpin"></app-spin></div>
          
          <div    *ngIf="!showSpin"  [ngStyle]="{'margin-left': f.date_type.value == 'choose' ? '-12em' : '-6em'}" >{{totalRecordsText}}</div>
        </div>
      </div>
    </div>

                <!--Month list-->
                <div *ngIf="showMonthList" style="position: absolute; float: left; margin-top: 0.25em; margin-left: 38.5em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                  <div style="display: flex">
                    <div style="margin-right:2em">
                      <div style="font-weight: bold; text-decoration:underline">Year</div>
                      <div>
                       
                       
                        <div style="display: flex; margin-top:0.5em">
                          <div style="margin-right:0.25em">
                            <input formControlName="selectedyearsearch"
                                   type="radio"
                                   [value]="currentYear" selected />
                          </div>
                          <div style="white-space: nowrap;margin-top:-0.15em">
                            <label>{{currentYear}}</label>
                          </div>
                        </div>
                       
                        <div style="display: flex">
                          <div style="margin-right:0.25em">
                            <input formControlName="selectedyearsearch"
                                   type="radio"
                                   [value]="nextYear"  />
                          </div>
                          <div style="white-space: nowrap;margin-top:-0.15em">
                            <label>{{nextYear}}</label>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                    <div style="min-width: 14em">
                      <div style="display:flex; justify-content:space-between">
                        <div style="font-weight: bold; text-decoration:underline; white-space: nowrap">Months:</div>
                        <div style="float:right" (click)="showMonthList=!showMonthList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                      </div>
  
                      <!--month -->
                      <div  style="margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                        <div  *ngFor="let v of filteredMonthList | async; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                          <div #tripmonthRefs [attr.filterID]="v.filterID"  style="display: flex; font-size: small; margin-top:-0.5em">
                            <div>
                              <mat-checkbox (click)="$event.stopPropagation()" (change)="checkMonthChange($event, v)" [checked]="v.selected">{{v.filterName}}</mat-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--month  end-->
  
  
                    </div>
  
                  </div>
                  <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                  <div style="display: flex; float: right">
                    <div style="margin-right:0.5em">
                      <button type="button" mat-button class="btn-low" (click)="clickResetMonth()" style="height:2em">RESET</button>
                    </div>
                    <div>
                      <button type="button" mat-button class="btn-high" (click)="clickDoneMonth()" style="height:2em">DONE</button>
                    </div>
                  </div>
                </div>
                <!--Month list end-->  
     <!--closeout list start--> 
     <div *ngIf="showCloseoutList" style="float: left; min-width: 11em; margin-left: 38.5em; margin-top: -1em; background-color: white; border-top: 0.0625em lightgrey solid;
     border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;
     border-radius: 0.25em; z-index: 1000; position: absolute">
   <div style="display:flex; justify-content:space-between">
     <div style="font-weight: bold; text-decoration:underline">Closeout</div>
     <div style="float:right" (click)="hideAllFilters()"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
   </div>
   <div style="font-size: small; margin-left:0em">
     <div style="margin-right:0.5em;">
       <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
         <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
         <input type="text" formControlName="closeoutsearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />  <!--(keyup)="closeoutSearchChange($event)"-->
       </div>
     </div>
   </div>
   <div *ngIf="closeoutFilter.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
     <div *ngFor="let v of filteredCloseoutList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
       <div #tripcloseoutRefs [attr.filterID]="v.filterID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
         <div>
           <mat-checkbox (click)="$event.stopPropagation()" (change)="checkCloseoutChange($event, v)" [checked]="v.selected">{{v.filterName}}</mat-checkbox>
         </div>

       </div>
     </div>
   </div>
   <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
   <div style="display: flex; float: right">
     <div style="margin-right:0.5em">
       <button type="button" mat-button class="btn-low" (click)="clickResetCloseout()" style="height:2em">RESET</button>
     </div>
     <div>
       <button type="button" mat-button class="btn-high" (click)="clickDoneCloseout()" style="height:2em">DONE</button>
     </div>
   </div>
 </div>
 <!--closeout list end-->



     <!--client list start--> 
        <div *ngIf="showClientList" style="float: left; min-width: 11em; margin-left: 48.8em; margin-top: -1em; background-color: white; border-top: 0.0625em lightgrey solid;
            border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;
            border-radius: 0.25em; z-index: 1000; position: absolute">
          <div style="display:flex; justify-content:space-between">
            <div style="font-weight: bold; text-decoration:underline">Client</div>
            <div style="float:right" (click)="hideAllFilters()"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
          </div>
          <div style="font-size: small; margin-left:0em">
            <div style="margin-right:0.5em;">
              <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                <input type="text" formControlName="clientsearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />  <!--(keyup)="clientSearchChange($event)"-->
              </div>
            </div>
          </div>
          <div *ngIf="clientFilter.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
            <div *ngFor="let v of filteredClientList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
              <div #tripclientRefs [attr.filterID]="v.filterID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                <div>
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="checkClientChange($event, v)" [checked]="v.selected">{{v.filterName}}</mat-checkbox>
                </div>

              </div>
            </div>
          </div>
          <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
          <div style="display: flex; float: right">
            <div style="margin-right:0.5em">
              <button type="button" mat-button class="btn-low" (click)="clickResetClient()" style="height:2em">RESET</button>
            </div>
            <div>
              <button type="button" mat-button class="btn-high" (click)="clickDoneClient()" style="height:2em">DONE</button>
            </div>
          </div>
        </div>
        <!--client list end-->

        
     <!--tripCode list start--> 
     <div *ngIf="showTripCodeList" style="float: left; min-width: 11em; margin-left: 56em; margin-top: -1em; background-color: white; border-top: 0.0625em lightgrey solid;
          border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;
          border-radius: 0.25em; z-index: 1000; position: absolute">
        <div style="display:flex; justify-content:space-between">
          <div style="font-weight: bold; text-decoration:underline">TripCode</div>
          <div style="float:right" (click)="hideAllFilters()"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
        </div>
        <div style="font-size: small; margin-left:0em">
          <div style="margin-right:0.5em;">
            <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
              <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
              <input type="text" formControlName="tripCodesearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />  <!--(keyup)="tripCodeSearchChange($event)"-->
            </div>
          </div>
        </div>
        <div *ngIf="tripCodeFilter.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
          <div *ngFor="let v of filteredTripCodeList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
            <div #triptripCodeRefs [attr.filterID]="v.filterID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
              <div>
                <mat-checkbox (click)="$event.stopPropagation()" (change)="checkTripCodeChange($event, v)" [checked]="v.selected">{{v.filterName}}</mat-checkbox>
              </div>

            </div>
          </div>
        </div>
        <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
        <div style="display: flex; float: right">
          <div style="margin-right:0.5em">
            <button type="button" mat-button class="btn-low" (click)="clickResetTripCode()" style="height:2em">RESET</button>
          </div>
          <div>
            <button type="button" mat-button class="btn-high" (click)="clickDoneTripCode()" style="height:2em">DONE</button>
          </div>
        </div>
      </div>
 <!--tripCode list end-->

 
     <!--registration list start--> 
     <div *ngIf="showRegistrationList" style="float: left; min-width: 11em; margin-left: 64.5em; margin-top: -1em; background-color: white; border-top: 0.0625em lightgrey solid;
     border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;
     border-radius: 0.25em; z-index: 1000; position: absolute">
   <div style="display:flex; justify-content:space-between">
     <div style="font-weight: bold; text-decoration:underline">Registration</div>
     <div style="float:right" (click)="hideAllFilters()"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
   </div>
   <div style="font-size: small; margin-left:0em">
     <div style="margin-right:0.5em;">
       <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
         <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
         <input type="text" formControlName="registrationsearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />  <!--(keyup)="registrationSearchChange($event)"-->
       </div>
     </div>
   </div>
   <div *ngIf="registrationFilter.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
     <div *ngFor="let v of filteredRegistrationList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
       <div #tripregistrationRefs [attr.filterID]="v.filterID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
         <div>
           <mat-checkbox (click)="$event.stopPropagation()" (change)="checkRegistrationChange($event, v)" [checked]="v.selected">{{v.filterName}}</mat-checkbox>
         </div>

       </div>
     </div>
   </div>
   <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
   <div style="display: flex; float: right">
     <div style="margin-right:0.5em">
       <button type="button" mat-button class="btn-low" (click)="clickResetRegistration()" style="height:2em">RESET</button>
     </div>
     <div>
       <button type="button" mat-button class="btn-high" (click)="clickDoneRegistration()" style="height:2em">DONE</button>
     </div>
   </div>
 </div>
 <!--registration list end-->

  
     <!--month list start--> 
     <!-- <div *ngIf="showMonthList && false" style="float: left; min-width: 11em; margin-left: 38.5em; margin-top: -1em; background-color: white; border-top: 0.0625em lightgrey solid;
     border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;
     border-radius: 0.25em; z-index: 1000; position: absolute">
      <div style="display:flex; justify-content:space-between">
        <div style="font-weight: bold; text-decoration:underline">Month</div>
        <div style="float:right" (click)="hideAllFilters()"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
      </div>
      <div style="font-size: small; margin-left:0em">
        <div style="margin-right:0.5em;">
          <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
            <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
            <input type="text" formControlName="monthsearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />   
          </div>
        </div>
      </div>
      <div *ngIf="monthFilter.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
        <div *ngFor="let v of filteredMonthList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
          <div #tripmonthRefs [attr.filterID]="v.filterID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
            <div>
              <mat-checkbox (click)="$event.stopPropagation()" (change)="checkMonthChange($event, v)" [checked]="v.selected">{{v.filterName}}</mat-checkbox>
            </div>

          </div>
        </div>
      </div>
      <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
      <div style="display: flex; float: right">
        <div style="margin-right:0.5em">
          <button type="button" mat-button class="btn-low" (click)="clickResetMonth()" style="height:2em">RESET</button>
        </div>
        <div>
          <button type="button" mat-button class="btn-high" (click)="clickDoneMonth()" style="height:2em">DONE</button>
        </div>
      </div>
    </div> -->
    <!--month list end-->


    <div style="margin: 0.2em; height: 1em;   "></div>

    <div style="display: flex">
      <div style="justify-content:flex-start; padding-left: 1em; margin-top: -1em; padding-bottom:0.5em; margin-right:1em; ">
 
 

        <table mat-table  matSort matSortActive="leadtime_duedate"  (matSortChange)="sortData($event)"  [dataSource]="tripCloseoutList" class="example-container mat-elevation-z8">

          
          <tr>

            <ng-container matColumnDef="first_arrival_date">
              <th   mat-header-cell *matHeaderCellDef style="width:4%; text-align:left !important">
                First Departure Date 
              </th>
              <td mat-cell *matCellDef="let element"    style="text-align: left !important;"  >
                 {{element.estimatedTripStartDate == null ? 'TBA' : element.estimatedTripStartDate}}
              </td>
            </ng-container>

            <ng-container matColumnDef="last_arrival_date">
              <th    mat-header-cell *matHeaderCellDef style="width:4%; text-align:left !important">
                Last Arrival Date 
              </th>
              <td mat-cell *matCellDef="let element"    style="text-align: left !important;"  >
                {{element.estimatedTripEndDate == null ? 'TBA' : element.estimatedTripEndDate}}
              </td>
            </ng-container>

            <ng-container matColumnDef="days_to_end">
              <th  mat-header-cell *matHeaderCellDef style="width:3%; text-align:center !important">
                Days To End
              </th>
              <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;"  >
                {{element.daysToTripEnd}}
              </td>
            </ng-container>

            <ng-container matColumnDef="closeout_status">
              <th  mat-header-cell *matHeaderCellDef style="width:10%; text-align:center !important">
                Closeout Status
              </th>
              <td mat-cell *matCellDef="let element"    [ngClass]="element.daysToTripEnd < 0?'redCell':''"  style="text-align: left !important; "  >
                <span> {{element.tripCloseOutDescription}}</span>  <i *ngIf="element.remarks != null" class="material-icons"  matTooltip="{{element.remarks}}"  style="font-size: larger;cursor: pointer; ">notes</i>
              </td>
            </ng-container>

            <ng-container matColumnDef="client">
              <th  mat-header-cell *matHeaderCellDef style="width:12%; text-align:center !important">
                Client
              </th>
              <td mat-cell *matCellDef="let element"    style="text-align: left !important;"  >
                  <span>  {{element.customerName}}  </span>  
              </td>
            </ng-container>

            <ng-container matColumnDef="trip_code">
              <th  mat-header-cell *matHeaderCellDef style="width:3%;  ">
                Trip Code
              </th>
              <td mat-cell class="mat-cell2"    *matCellDef="let element" style="cursor: pointer; color:dodgerblue; text-decoration: underline; "  matTooltip="{{element.additinalReqTooltip}}"><a [routerLink]=""  (click)="clickOpenTrip($event, element)" >{{element.tripCode}}</a></td>

            </ng-container>

            <ng-container matColumnDef="registration">
              <th  mat-header-cell *matHeaderCellDef style="width:3%; text-align:left !important">
                Registration
              </th>
              <td mat-cell *matCellDef="let element"    style="text-align: left !important;"  >
                {{element.registration}}
              </td>
            </ng-container>

            <ng-container matColumnDef="route">
              <th  mat-header-cell *matHeaderCellDef style="width:13%; text-align:center !important">
                Route
              </th>
              <!-- <td mat-cell *matCellDef="let element"    style="text-align: left !important;"  >
                {{element.route}}
              </td> -->

              <td mat-cell *matCellDef="let element" style="text-align: left !important"  >
                <div *ngFor="let gs of element.groundStopList; let i=index">
                  <div *ngIf="i % 7==0" style="display: flex">
                    <div style="display: flex">
                      <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 1 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+1].groundStopTypeColor">{{element.groundStopList[i+1].icao}}</div><div>{{(i+1)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 2 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+2].groundStopTypeColor">{{element.groundStopList[i+2].icao}}</div><div>{{(i+2)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 3 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+3].groundStopTypeColor">{{element.groundStopList[i+3].icao}}</div><div>{{(i+3)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 4 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+4].groundStopTypeColor">{{element.groundStopList[i+4].icao}}</div><div>{{(i+4)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 5 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+5].groundStopTypeColor">{{element.groundStopList[i+5].icao}}</div><div>{{(i+5)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 6 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+6].groundStopTypeColor">{{element.groundStopList[i+6].icao}}</div><div>{{(i+6)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <!-- <div *ngIf="i + 7 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+7].groundStopTypeColor">{{element.groundStopList[i+7].icao}}</div><div>{{(i+7)==element.groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 8 < element.groundStopList.length" style="display: flex">
                      <div [style.background-color]="element.groundStopList[i+8].groundStopTypeColor">{{element.groundStopList[i+8].icao}}</div><div>{{(i+8)==element.groundStopList.length-1? '': '-'}}</div>
                    </div> -->
                  
                    
                  </div>
  
                </div>
  
              </td>
            </ng-container>


            <!-- <ng-container matColumnDef="leg_count">
              <th  mat-header-cell *matHeaderCellDef style="width:3%; text-align:center !important">
                Active/ Cancelled Stops
              </th>
              <td mat-cell *matCellDef="let element"    style="text-align: center !important;"  >
                {{element.activeStops}} - {{element.cancelledStops}}
              </td>
            </ng-container> -->
            <ng-container matColumnDef="activeCount">
              <th  mat-header-cell *matHeaderCellDef style="width:2%; text-align:center !important">
                Active Airports
              </th>
              <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;"  >
                {{element.activeStops}}  
              </td>
            </ng-container>
            <ng-container matColumnDef="cancelledCount">
              <th  mat-header-cell *matHeaderCellDef style="width:2%; text-align:center !important">
                Cancelled Airports
              </th>
              <td mat-cell *matCellDef="let element"  [ngClass]="element.daysToTripEnd < 0?'redCell':''"    style="text-align: center !important;"  >
                {{element.cancelledStops}}
              </td>
            </ng-container>




            <ng-container matColumnDef="task_count">
              <th  mat-header-cell *matHeaderCellDef style="width:3%; text-align:center !important">
                Task Count
              </th>
              <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;"  >
                {{element.taskCount}}
              </td>
            </ng-container>

            <ng-container matColumnDef="lockdown_trip">
              <th  mat-header-cell *matHeaderCellDef style="width:5%; text-align:center !important">
                Lockdown Trip
              </th>
              <td mat-cell *matCellDef="let element"    style="text-align: center !important;"  >
                {{element.lockdownTrip? 'Yes': ''}}
              </td>
            </ng-container>

            <ng-container matColumnDef="remove_from_external">
              <th  mat-header-cell *matHeaderCellDef style="width:6%; text-align:left !important">
                Remove From External View
              </th>
              <td mat-cell *matCellDef="let element"    style="text-align: center !important;"  >
                {{element.removeFromExternalView ? 'Yes': ''}}
              </td>
            </ng-container>

            <ng-container matColumnDef="options">
              <th  mat-header-cell *matHeaderCellDef style="width:17%; text-align:center !important">
                Options
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important;">
                <button class="btn-high" mat-button    style="margin-left: -1em; background-color:grey; color: white; margin-right: 0.5em; ">Edit</button>
                <button class="btn-low" mat-button [disabled]="element.downloading2 && element.clicked2" (click)="downloadWordReport($event,  element)" style="background-color:grey; color: white; margin-right: 0.5em; ">
                  <mat-icon *ngIf="element.downloading2 && element.clicked2">
                    <svg class="black-spinner" viewBox="0 0 50 50">
                      <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="black" stroke-width="4"></circle>
                    </svg>
                  </mat-icon>
                  <span  *ngIf="!element.downloading2 "  > Report</span></button>
                <button class="btn-low" mat-button  [disabled]="element.downloading1 && element.clicked1"   (click)="downloadExcel($event, element)" style="background-color:grey; color: white; ">
                  <mat-icon *ngIf="element.downloading1 && element.clicked1">
                    <svg class="black-spinner" viewBox="0 0 50 50">
                      <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="black" stroke-width="4"></circle>
                    </svg>
                  </mat-icon> 
                  <span *ngIf="!element.downloading1 "  >Export for BC</span></button>
              </td>

            </ng-container>

          </tr> 
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn;  let i = index;"   [ngClass]="{  'activeRow':  i == selectedIndex,
          '': row.daysToTripEnd < 0 && i != selectedIndex,
          '':  row.daysToTripEnd > -1 && i != selectedIndex }"    (click)="  clickTripCloseout( $event,  row, i)" ></tr>
        </table>



       

      </div>
      <!-- <div *ngIf="errMsg!=''" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;"><label style="color:black; font-size: small">{{errMsg}}</label></div> -->
      <!-- <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
        <app-success-message [successMsg]=""></app-success-message>
      </div> -->

      

      <div style="width: 0.5em;">
        <div style="width: 0.2em; height: 16.5em;margin-top: -1em;float: right; background-color: #f18e8e; ">
        </div>
      </div>
      
      <div style="width: 20em; margin-left: 1em;">
        <div>
          <div style="width: 17em;margin-top: -1em; text-align: center !important;">
              <!-- <div>
                  <div style="padding: 0.5em;"><b>Counts</b></div>
              </div>  -->
              <div style="display: flex;">
                  <div style="width: 9em; text-align: left; padding-left: 0.5em !important; font-weight: bold;   ">Client Count: </div>
                  <div style="padding-left: 0.5em !important;  text-align: left !important;">{{totalClientCount  | number}}</div>
              </div>
              <div style="display: flex;">
                  <div style="width: 9em; text-align: left; padding-left: 0.5em !important;font-weight: bold;   ">Trip Count: </div>
                  <div style="padding-left: 0.5em !important; text-align: left !important;">{{totalRecords | number}}</div>
              </div>
              <div style="display: flex;">
                  <div style="width: 9em; text-align: left; padding-left: 0.5em !important;font-weight: bold;   ">Airport Count: </div>
                  <div style="padding-left: 0.5em !important; text-align: left !important;">{{totalActiveAirports | number}}</div>
              </div>
              <div style="display: flex;">
                  <div style="width: 9em; text-align: left; padding-left: 0.5em !important;font-weight: bold;   ">CANX Airports: </div>
                  <div style="padding-left: 0.5em !important; text-align: left !important;">{{totalCanXAirports | number}}</div>
              </div>
              <div style="display: flex;">
                  <div style="width: 9em; text-align: left; padding-left: 0.5em !important;font-weight: bold;   ">Task count: </div>
                  <div style="padding-left: 0.5em !important; text-align: left !important;">{{totalServices | number}}</div>
              </div>
              <!-- <div>
                <div><span>&nbsp;</span> </div>
              </div> -->
              <div>
                <div><span>&nbsp;</span> </div>
              </div>
              <div style="display: flex;">
                  <div style="width: 12em; text-align: left; padding-left: 0.5em !important; " >Airport Services: </div>
                  <div style="padding-left: 0.5em !important; text-align: left !important;">{{totalAirportServices | number}}</div>
              </div>
              
              <div style="display: flex;">
                  <div style="width: 12em; text-align: left; padding-left: 0.5em !important; " >Flight Planning Services: </div>
                  <div style="padding-left: 0.5em !important; text-align: left !important;">{{totalFPServices | number}}</div>
              </div>
              
              <!-- <div style="display: flex;">
                  <div style="width: 12em; text-align: left; padding-left: 0.5em !important; " >Flight Following Services: </div>
                  <div style="padding-left: 0.5em !important; text-align: left !important;">{{totalFFServices | number}}</div>
              </div> -->
              
              <div style="display: flex;">
                  <div style="width: 12em; text-align: left; padding-left: 0.5em !important; " >Preflight Services: </div>
                  <div style="padding-left: 0.5em !important; text-align: left !important;">{{totalPreflightServices | number}}</div>
              </div>
              
              <div style="display: flex;">
                  <div style="width: 12em; text-align: left; padding-left: 0.5em !important; " >Completed Trips: </div>
                  <div style="padding-left: 0.5em !important; text-align: left !important;">{{totalCompletedTrips | number}}</div>
              </div>
              
              <div style="display: flex;">
                  <div style="width: 12em; text-align: left; padding-left: 0.5em !important; " >TBA Count: </div>
                  <div style="padding-left: 0.5em !important; text-align: left !important;">{{totalTbaCount | number}}</div>
              </div>
              
          </div>
      </div>
      
      </div>

    </div>

    

 
  </form>

  </div>
</div>
