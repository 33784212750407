import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { DOCUMENT } from '@angular/common';
import { DialogService } from '../services/dialog.service';
import { CustomerGroupModel } from '../models/customer-group.model';
import { AccountGroupEditDialogComponent } from './account-group-edit-dialog.component';
import { AccountGroupSelectionDialogComponent } from './account-group-selection-dialog.component';
import { CustomerGroupService } from '../services/customer-group-service';
import { Title } from '@angular/platform-browser';
import { MaintenanceDashboardComponent } from '../common-utility/maintenance-dashboard.component';
import { CustomerProfileSetupComponent2 } from '../customers/customer-profile-setup2.component';

@Component({
  selector: 'app-account-groupmlist',
  templateUrl: './account-grouplist.component.html',
  styleUrls: ['./account-grouplist.component.css']
})

export class AccountGrouplistComponent implements OnInit {
  account_grouplist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  
  userType: string;
  showSpin: boolean = false;
  
  includeDisabledRecords: boolean = false;
  recordList: CustomerGroupModel[];
  
  displayedColumn: string[] = ['group_name', 'linked_account _count', 'create_date',   'action'];

  grantAccessToManageAccountGrouping: boolean = false;
  isDialog: boolean = false;
  showDisabledRecords: boolean = false;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _customerGroupService: CustomerGroupService, 
    @Optional() @Inject(DOCUMENT) private _document, 
    @Optional() private readonly _dialogRef: MatDialogRef<AccountGrouplistComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _dialogService: DialogService,
  private _titleService:Title) {


    if (this.data) {
      this.isDialog = true;
    }  
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToManageAccountGrouping =false;
    if (localStorage.getItem('up.mGrp')!=null && localStorage.getItem('up.mGrp') == 'true') {
      this.grantAccessToManageAccountGrouping = true;
    }   

    if (!this.grantAccessToManageAccountGrouping) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    
    this.showSpin = false;
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    

    this.initControls();
    this.getData();
   

  }

  initControls() {
    this.account_grouplist_form = this._formBuilder.group({
      includeDisabledRecords: this.includeDisabledRecords,
      showDisabledRecords: this.showDisabledRecords
    });
  }

  

  get f() { return this.account_grouplist_form.controls; }

  includeDisabledRecordsOnChange(event: any) {
    this.recordList = [];
    this.getData();
  }  
  
  showDisabledRecordsOnChange(event: any) {
    this.recordList = [];
    this.getData();
  }
  

  clickEdit(event: any, item: CustomerGroupModel) {    
    let s = "";
    let customerGroupGUID: string = "";
    if (item != null) {
      customerGroupGUID = item.customerGroupGUID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "58em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    
    dialogConfig.data = { dialogTitle: s, customerGroupGUID: customerGroupGUID , fromDialog: this.isDialog};
    if(this.isDialog){ 
      this._dialogRef.close();
    } 
    
    const dialogRef = this._dialog.open(AccountGroupEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {               
        this.getData();
      }
      if (result.newId!="")
        this.openClientSelection(result.newId);
    });

  }

  clickClose(val) {
    this._dialogRef.close(val);
  }

  openClientProfileSetupComponent() {
    if (this.isDialog) {
      this.clickClose(true);
    }
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.width = "55em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "10px"};
    config.data = { gsId: "", tripCodeId: "", isDialog: true, v: this._authService.getCurrentTimeNumber() };

    this._dialog.open(CustomerProfileSetupComponent2, config);
  }

  openClientSelection(customerGroupGUID: string) {
    let s = "";
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "58em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, customerGroupGUID: customerGroupGUID };

    const dialogRef = this._dialog.open(AccountGroupSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {       
        this.getData();
      }
    });
  }
  

  onSubmit() {
    this.errMsg = "";
    this.msg = "";  
    this.loading = true;
    this.getData();
  }

  getData() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new CustomerGroupModel();   
    // request.isActive =!this.f.includeDisabledRecords.value;
    request.isActive =!this.f.showDisabledRecords.value;
    
    this._customerGroupService.getAllCustomerGroup<ResponseModel<CustomerGroupModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
        }
        else {
          this.recordList = [];
          //this.msg = "No data return.";          
           this.msg = "No records found.";         
        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;
    //  this._titleService.setTitle("Account Grouping");

    })

  }

  
  clickRouteNew(route: string,display: string){
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }  

  
}
