import { ComponentPortal, Portal, PortalInjector } from '@angular/cdk/portal';
import { AfterViewInit, Component, Inject, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthenticateService } from '../services/authenticate.service';
import { CONTAINER_DATA } from '../services/message.service';
import { FolderSelectionDialogComponent } from './folder-selection-dialog.component';
import { MessageImportDialogComponent } from './message-import-dialog.component';

@Component({
  selector: 'app-message-import',
  templateUrl: './message-import.component.html',
  styleUrls: ['./message-import.component.css']
})


export class MessageImportComponent implements AfterViewInit {
  @Input() selectedPortal: Portal<any>;
  componentPortal: ComponentPortal<FolderSelectionDialogComponent>;
  editPortal: ComponentPortal<MessageImportDialogComponent>;
  type: string;
  constructor(private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder,
    private _injector: Injector 
    ) {
   
  }

  ngAfterViewInit(): void {
    this.componentPortal = new ComponentPortal(FolderSelectionDialogComponent, null, this.createInjector("AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA="));
    this.editPortal = new ComponentPortal(MessageImportDialogComponent);
    this._authService.updateAccessTime();
    this.selectedPortal = this.componentPortal;

  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

}
