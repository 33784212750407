import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as atlas from 'azure-maps-control';
import { AuthenticationType, ControlPosition } from 'azure-maps-control';
import { Subject, Subscription, from } from 'rxjs';
import { MapPointsModel } from '../models/map-points.model';
import { AirportModel } from '../models/airport.model';

@Component({
  selector: 'app-airport-map',
  templateUrl: './airport-map.component.html',
  styleUrls: ['./airport-map.component.css']
})
/** azure-map2 component*/
export class AirportMapComponent {


  latLongs: MapPointsModel[];
  map2: atlas.Map;
  points: any = [];
  tileLayer: any;
  layerName: string = "";
  distance: number;
  airports: AirportModel[];
  from: string;
 weatherTileUrl:string = 'https://{azMapsDomain}/map/tile?api-version=2.0&tilesetId={layerName}&zoom={z}&x={x}&y={y}';
  constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
    
  ) {
    this.latLongs = _data.latLongs;
    this.distance = _data.distance;
    this.airports = _data.airports;
    this.from = _data.from;
    
  }


  ngOnInit() {
    this.createMap();
  }

  //Create an instance of the map2 control and set some options.
  createMap() {
    this.map2 = new atlas.Map('map2', {
      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map2.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map2.sources.add(dataSource);
      self.map2.controls.add([
        new atlas.control.ZoomControl(),
        new atlas.control.CompassControl(),
        new atlas.control.PitchControl(),
        new atlas.control.StyleControl()
      ], {
        position: atlas.ControlPosition.TopRight
      });

      if (self.from == "details") {


        if (self.latLongs.length > 1) {
          let maxRadius = self.distance * 1852;
          for (let i = 46300; i < maxRadius; i = i + 46300) {

            dataSource.add(new atlas.data.Feature(new atlas.data.Point([self.latLongs[0].longitude, self.latLongs[0].latitude]),
              {
                subType: "Circle",
                radius: i
              }));

            //Create a polygon layer to render the filled in area of the polygon.
            var polygonLayer = new atlas.layer.PolygonLayer(dataSource, 'myPolygonLayer', {
              fillColor: 'transparent'
            });

            //Create a line layer for greater control of rendering the outline of the polygon.
            var lineLayer = new atlas.layer.LineLayer(dataSource, 'myLineLayer', {
              strokeColor: 'blue',
              strokeWidth: 2
            });

            /*Create and add a polygon layer to render the polygon to the map*/
            self.map2.layers.add([polygonLayer, lineLayer]);

          }

        }

        self.latLongs.forEach((v, i) => {
          dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
            title: v.title,
            color: v.isMainPoint ? '#ffd800' : 'DodgerBlue'
          }));
          self.points.push([v.longitude, v.latitude]);


        });
      }
      else {
        self.airports.forEach((v, i) => {
          dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
            title: v.icao,
            color: 'darkblue'
          }));
          self.points.push([v.longitude, v.latitude]);


        });
      }

      //var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
      //  iconOptions: {
      //    image: ['get', 'image'],
      //    cluster: false,
      //    optional: true,
      //    allowOverlap: true,
      //    size: .75
      //  },
      //  textOptions: {
      //    textField:  [
      //      'match',
      //      ['get', 'showText'],
      //      1, '',
      //      0, ['get', 'title'],
      //      ''
      //    ],            
      //    offset: ['get', 'offset'],
      //    cluster: false,
      //    allowOverlap: true,
      //    size: 12
      //  },
      //  filter: ['any', ['==', ['geometry-type'], 'Point']]
      //});
      //self.map.layers.add([new atlas.layer.LineLayer(dataSource, null, {
      //  strokeColor: "black",
      //  strokeWidth: 1.5
      //}),
      //  symbolLayer
      //]);
      self.map2.layers.add(new atlas.layer.BubbleLayer(dataSource, null, {
        radius: 4,
        strokeColor: ['get', 'color'],
        strokeWidth: 6,
        color: "white",
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      }));

      //Add a layer for rendering point data.
      self.map2.layers.add(new atlas.layer.SymbolLayer(dataSource, null, {
        iconOptions: {
          //Hide the icon image.
          image: "none"
        },

        textOptions: {
          textField: ['get', 'title'],
          offset: [0, -1],
          allowOverlap: true,
          size: 16
        },
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      }));
        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        if ((self.from =="details" && self.latLongs.length == 1) || self.from=="manage" && self.airports.length==1) {
          let center;
          if (self.from == "details")
            center = [self.latLongs[0].longitude, self.latLongs[0].latitude]
          else
            center = [self.airports[0].longitude, self.airports[0].latitude]
          self.map2.setCamera({
            center:center,
            zoom: 12
          });
        }
        else {
          self.map2.setCamera({
            bounds: bbox,
            padding: 90
          });
        }
     //   self.updateTileLayer();
      


    });
  }

  updateTileLayer() {
    

  var tileUrl = this.weatherTileUrl.replace('{layerName}', this.layerName);

    if (!this.tileLayer) {
    //Create a tile layer and add it to the map below the label layer.
    this.tileLayer = new atlas.layer.TileLayer({
      tileUrl: tileUrl,
      opacity: 0.9,
      tileSize: 256
    });

      this.map2.layers.add(this.tileLayer, 'labels');
  } else {
    this.tileLayer.setOptions({
      tileUrl: tileUrl
    });
  }
}
}
