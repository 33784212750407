<div style="background-color:#f0f0f0;  height: 100%;">

  <div style="display: flex; justify-content: space-between;padding-left:1em; margin-top:-0.5em;   " cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div style="display: flex;">
      <h5>{{dialogTitle}} Advisory </h5>
      <span *ngIf="dialogTitle == 'Edit'" style="color:  #7a6e6e; margin: 0.1em 0 0 0.5em;"> - Alert ID :
        {{currentAdvisoryId}}</span>
    </div>

    <div style="display: flex;">

      <button mat-icon-button (click)="clickRoute('advisory-details','')"> <i class="material-icons-outlined"
          style="font-size: x-large;">arrow_circle_left
        </i>
      </button>
      <button mat-icon-button (click)="clickClose(false)"> <i class="material-icons" style="font-size: x-large;">close
        </i>
      </button>
    </div>
  </div>

  <div style="padding: 0.5em; background-color:white ;  ">




    <form *ngIf="advisory_edit_form" [formGroup]="advisory_edit_form">
      <div style="display: flex; margin: 0.5em 0 0 1em;">
        <div style="display: flex; width: 35em;">
          <label for="ratingDDL" style="font-size:small; margin-right:1em; ">Rating</label>


          <select id="ratingDDL" class="form-control form-control-sm dropdown" style="width: 8.6em;font-size:small;"
            formControlName="lk_AdvisoryRatingID">
            <option *ngFor="let r of ratings" [value]="r.lookupId">{{r.description}}</option>
          </select>

        </div>



        <div style="display: flex; margin-right: 0.5em;">
          <div style="width: 0.5em; margin: 1em 1em 0 0;">
            <div style="width: 0.2em; height: 130%;margin-top: -1em;float: right; background-color: #f18e8e; ">
            </div>
          </div>
          <div style=" display: flex;">
            <div style="margin-top:-.5em">
              <div style="font-size: small;  white-space:nowrap; display: flex;">Effective Date </div>
              <div style="margin: -2.5em 0 0 6em;">
                <div style="display: flex;margin-top:-1.25em">
                  <mat-date-range-input [rangePicker]="picker"
                    style="display: flex; margin-top:0.5em; width:11.5em !important">
                    <input matStartDate formControlName="effectiveDate" #startDate placeholder="mm/dd/yy" maxlength="10"
                      [ngClass]="{ 'is-invalid1': isValideffectiveDate==false,  'is-valid1': isValidStartDate==true }"
                      (dateChange)="dateTypeChange()"
                      style="font-size:small;margin-top:0em; margin-right:1em;background-color:#eaf0fd; border-radius:0.25em;padding-left:0.125em;padding-right:0.125em; width: 6em !important">
                    <input matEndDate formControlName="expirationDate" #endDate placeholder="mm/dd/yy" maxlength="10"
                      [ngClass]="{ 'is-invalid1': !isValidexpirationDate,  'is-valid1': isValidEndDate }"
                      (dateChange)="dateTypeChange()"
                      style="font-size:small;margin-left:0em; background-color:#eaf0fd; border-radius:0.25em;padding-left:0.125em; width: 6em !important;  ">

                  </mat-date-range-input>
                  <div style="margin: 1em 0.4em 0 -0.5em;font-size: small;">(Z)</div>
                  <mat-datepicker-toggle matSuffix [for]="picker"
                    style="margin-top:0.25em; margin-left:-0.7em"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
                <!-- <div style="display:flex">
                <div *ngIf="f.effectiveDate.errors" class="small alert alert-danger p-0"
                  style="width:max-content;margin-top:-0.5em">
                  <div *ngIf="f.effectiveDate.errors.required">Start Date is required.</div>
                  <div *ngIf="f.effectiveDate.errors.isValidDate==false">Invalid start date.</div>
                </div>
                <div *ngIf="f.expirationDate.errors" class="small alert alert-danger p-0"
                  style="width:max-content;margin-top:-0.5em">
                  <div *ngIf="f.expirationDate.errors.required">End Date is required.</div>
                  <div *ngIf="f.expirationDate.errors.isValidDate==false">Invalid end date.</div>
                </div>
              </div> -->
                <div style="display:flex; font-size: small; margin-top:-0.25em; margin-left: -3.5em;">
                  <div style="font-size: small;  white-space:nowrap; margin-left: -0.2em; margin-right:  0.9em;">
                    Times </div>
                  <div>
                    <input type="text" class="input-noborder" placeholder="HH:MM" formControlName="startTime"
                      maxlength="5" [ngClass]="{ 'is-invalid': f.startTime.errors }"
                      style="background-color:#eaf0fd; width:6em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em; border-color: lightgray"
                      (change)="timeChange('S', $event)" />
                  </div>
                  <div style="margin-left:0.25em; margin-right:0.25em"><i class="material-icons-outlined"
                      style="font-size: small;">remove
                    </i></div>
                  <div>
                    <input type="text" class="input-noborder" placeholder="HH:MM" formControlName="endTime"
                      maxlength="5" [ngClass]="{ 'is-invalid': f.endTime.errors }"
                      style="background-color:#eaf0fd; width: 6em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em; border-color: lightgray"
                      (change)="timeChange('E', $event)" />
                  </div>
                  <span style="margin-left: 0.3em;">(Z)</span>
                </div>
              </div>

            </div>

          </div>

          <div *ngIf="f.airportID.value != 0" style=" display: grid; margin: -0.8em 0 0.3em 2em;">

            <div style=" display: flex;">
              <div *ngIf="f.effectiveDate.value != null" style="width:4.5em; height:1.5em; font-size: small; ">
                <span *ngIf="f.effectiveDate.value != null">{{f.localEffectiveDate.value}}</span>
              </div>

              <div style="margin-left:0.35em; margin-right:0.35em">-</div>

              <div *ngIf="f.effectiveDate.value != null" style="width:4.5em; height:1.5em; font-size: small; ">
                <span *ngIf="f.expirationDate.value != null">{{f.localExpirationDate.value}}</span>
              </div>
              <span *ngIf="f.effectiveDate.value != null" style="margin-left: 0.7em; font-size: small;">(L)</span>
            </div>

            <div *ngIf="f.localAdvisoryValidTimeStart.value" style=" display: flex;">
              <div *ngIf="f.effectiveDate.value != null" style="width:4.5em; height:1.5em; font-size: small; ">
                <span *ngIf="f.effectiveDate.value != null">{{f.localAdvisoryValidTimeStart.value}}</span>
              </div>

              <div style="margin-left:0.35em; margin-right:0.35em">-</div>

              <div *ngIf="f.effectiveDate.value != null" style="width:4.5em; height:1.5em; font-size: small; ">
                <span *ngIf="f.expirationDate.value != null">{{f.localAdvisoryValidTimeEnd.value}}</span>
              </div>
              <span *ngIf="f.effectiveDate.value != null" style="margin-left: 0.7em; font-size: small;">(L)</span>
            </div>

          </div>

        </div>


      </div>
      <div style="display: flex; margin-top:  0em;">
        <div>
          <div style="display:flex">
            <div style="padding-top: 1.4em; display: flex; width: 36em;">
              <div>


                <div style="display: flex; margin-right: 0.5em;">
                  <div style="width: 0.5em; margin: 1em 1em 0 0;">
                    <div style="width: 0.2em; height: 130%;margin-top: -1em;float: right; background-color: #f18e8e; ">
                    </div>
                  </div>

                  <div>

                    <div class="form-group"
                      style="display: flex;  margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <label style="font-size:small; margin-right: 0.5em; "> Country </label>
                      <select [attr.disabled]="showModified ? '' : null" (change)="countryChange($event, c)"
                        [ngClass]="!showModified?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputDisabled'"
                        style="width: 26.6em;" formControlName="countryID">

                        <option value="" selected></option>
                        <option *ngFor="let c of countrylist" [value]="c.countryID">{{c.countryName}}</option>
                      </select>

                    </div>

                    <div style="display: flex;">
                      <!-- <label style="font-size:small"> Airport: </label>    (change)="countryChange($event, c)" -->

                      <div style="display: flex;margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                        <label for="icao" style="margin-left: 1.2em;  font-size:small">ICAO</label>
                        <div class="form-group" style="margin-left: 0.5em;  margin-bottom:0em">
                          <input [readonly]="showModified" type="text" formControlName="icao"
                            class="form-control form-control-sm inputBox" maxlength="255" style="width:6em"
                            (keyup)="icaoValidation($event,'icao')" />
                        </div>
                        <div *ngIf="submitted && f.icao.errors" class="alert alert-danger p-0 small">
                          <div *ngIf="f.icao.errors.required">ICAO is required.</div>
                        </div>
                      </div>
                      <div style="display: flex;margin-left: 0em;margin-top:0em;margin-right:0em;">
                        <label for="iata" style="font-size:small">IATA</label>
                        <div class="form-group" style="margin-left: 0.5em;  margin-bottom:0em">
                          <input [readonly]="showModified" type="text" formControlName="iata"
                            [ngClass]="showModified?'form-control form-control-sm inputBox inputDisabled ':'form-control form-control-sm inputBox'"
                            maxlength="255" style="width:6em" (keyup)="icaoValidation($event,'iata')" />
                        </div>
                        <div *ngIf="submitted && f.iata.errors" class="alert alert-danger p-0 small">
                          <div *ngIf="f.iata.required">IATA is required.</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>




                <div style="display: flex; margin-right: 0.5em; margin-top: 1em;">
                  <div style="width: 0.5em; margin: 1em 1em 0 0;">
                    <div style="width: 0.2em; height: 130%;margin-top: -1em;float: right; background-color: #f18e8e; ">
                    </div>
                  </div>
                  <div>

                    <div style="display: flex;">
                      <div style="font-size:small;  "> Advisory Tag List: </div>

                      <button mat-button class="btn-high" style="font-size: small; margin: -0.5em 0 0.5em 1em;"
                        [disabled]="!hasCountry" (click)="getAdvisoryTagListComponent()">
                        + Tags</button>

                    </div>
                    <div style="width: 27em; display: flex; flex-wrap: wrap;">
                      <span
                        *ngIf="filteredAdvisoryTags === 'Fuel' || filteredAdvisoryTags === 'Handling' || filteredAdvisoryTags === 'Hotels'"
                        style="font-size:small; color: #44c6fb; font-weight: 600; display: flex;">
                        {{filteredAdvisoryTags}} </span>
                      <span
                        *ngIf="filteredAdvisoryTags != 'Fuel' && filteredAdvisoryTags != 'Handling' && filteredAdvisoryTags != 'Hotels'"
                        style="font-size:small; color: #44c6fb;   display: flex;"> {{filteredAdvisoryTags}} </span>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div>



          <div *ngIf="(hasGroundHandlerTag || hasFuelTag) && hasIcao"
            style="display: flex; margin-right: 0.5em; margin-top: 1em;">
            <div style="width: 0.5em; margin: 1em 1em 0 0;">
              <div style="width: 0.2em; height: 130%;margin-top: -1em;float: right; background-color: #f18e8e; ">
              </div>
            </div>
            <div>

              <div style="display: flex;">
                <div style="font-size:small;  "> Ground Handlers SSN Fuel Vendors:
                </div>

                <button mat-button class="btn-high" style="font-size: small; margin: -0.5em 0 0.5em 1em;"
                  (click)="getVendorListComponent()">
                  + Vendors</button>

              </div>
              <div style=" display: grid; ">
                <span style="font-size:small; color: #44c6fb; font-weight: 600;"
                  *ngIf="groundHandlerVendorName && hasGroundHandlerTag">
                  Ground Handler: {{groundHandlerVendorName}} </span>
                <span style="font-size:small; color: #44c6fb; font-weight: 600;"
                  *ngIf="ssnVendorName && hasGroundHandlerTag">
                  SSN Vendor: {{ssnVendorName}}
                </span>
                <span style="font-size:small; color: #44c6fb; font-weight: 600;" *ngIf="fuelSupplierName && hasFuelTag">
                  Fuel Supplier: {{fuelSupplierName}} </span>
                <span style="font-size:small; color: #44c6fb; font-weight: 600;" *ngIf="intoplaneAgent && hasFuelTag">
                  Into Plane Agent: {{intoplaneAgent}} </span>


              </div>
            </div>

          </div>
          <div *ngIf="hasHotelTag && hasIcao" style="display: flex; margin-right: 0.5em; margin-top: 1em;">
            <div style="width: 0.5em; margin: 1em 1em 0 0;">
              <div style="width: 0.2em; height: 130%;margin-top: -1em;float: right; background-color: #f18e8e; ">
              </div>
            </div>
            <div>

              <div style="display: flex;">
                <div style="font-size:small;   "> Hotels:
                </div>

                <button mat-button class="btn-high" style="margin: -0.5em 0 0.5em 1em; font-size: small;"
                  (click)="getAdvisoryHotelListComponent()"> + Hotels </button>

              </div>
              <div style=" display: flex; flex-wrap: wrap;"><span
                  style="font-size:small;  color: #44c6fb; font-weight: 600;"> {{
                  filteredHotel?.hotelName}}</span> </div>
            </div>

          </div>



        </div>







      </div>


      <div style="margin-top: 0em;" class="row">
        <div class="col">
          <label style="font-size:small; " for="inpTitle">Title</label>
          <input [readonly]="!hasTags" style="font-size:small;width:42.7em " type="text" [disabled]="!hasTags"
            id="inpTitle" formControlName="inpTitle"
            [ngClass]="!hasTags?'form-control form-control-sm inputBox inputDisabled ':'form-control form-control-sm inputBox'">
        </div>

      </div>
      <div class="row">
        <div class="col">
          <label style="font-size:small; " for="inpIssue">Issue</label>
          <textarea [readonly]="!hasTags" style="font-size:small; " type="text" id="inpIssue" formControlName="issue"
            [ngClass]="!hasTags?'form-control form-control-sm inputBox inputDisabled  input-lg':'form-control form-control-sm inputBox  input-lg'"></textarea>
        </div>
        <div class="col">
          <label style="font-size:small; " for="inpResolution">Resolution</label>
          <textarea [readonly]="!hasTags" style="font-size:small; " type="text" id="inpResolution"
            formControlName="resolution"
            [ngClass]="!hasTags?'form-control form-control-sm inputBox inputDisabled  input-lg':'form-control form-control-sm inputBox  input-lg'"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label style="font-size:small; " for="inpWebLink">WebLink</label>
          <input [readonly]="!hasTags" style="font-size:small; width: 42.7em" type="text" id="inpWebLink"
            formControlName="inpWebLink"
            [ngClass]="!hasTags?'form-control form-control-sm inputBox inputDisabled ':'form-control form-control-sm inputBox'">
        </div>

      </div>





      <!-- <div style="overflow:auto;margin-top:1em">
        <table id="msg" mat-table matSort matSortActive="type" matSortDisableClear matSortDirection="asc"
          [dataSource]="advisoryDayHoursList"> 
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%">
              Type
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
              {{element.type}}
            </td>
          </ng-container>
 
          <ng-container *ngFor="let day of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']" [ngSwitch]="day">
            <ng-container matColumnDef="{{ day.toLowerCase() }}">
              <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%">
                {{ day }}
              </th>
              <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
                {{ element[day.toLowerCase()] == true? 'Yes': 'No' }}
              </td>
            </ng-container>
          </ng-container>
 
          <ng-container matColumnDef="twentyFourHrs">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%">
              24 Hrs
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
              {{ element.twentyFourHrs == true? 'Yes': 'No' }}
            </td>
          </ng-container>
 
          <ng-container matColumnDef="hoursStart">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 7%;width:7%">
              Hours Start
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 7%;width:7%">
              {{ element.hoursStart }}
            </td>
          </ng-container>
 
          <ng-container matColumnDef="hoursEnd">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 7%;width:7%">
              Hours End
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 7%;width:7%">
              {{ element.hoursEnd }}
            </td>
          </ng-container>
 
          <ng-container matColumnDef="remarks">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 15%;width:15%">
              Remarks
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 15%;width:15%">
              {{ element.remarks }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%">
              Action
              <button class="btn-high" mat-button>Add</button>

            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
              <button mat-button>Action</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div> -->







      <div class="row">


        <div class="col" style="font-size: small; display: inline-grid; margin-top: 1em;">
          <mat-slide-toggle labelPosition="before" formControlName="isHeadlineNews" [disabled]="!hasTags"
            color="primary">Share with HeadLine
            News</mat-slide-toggle>
          <mat-slide-toggle labelPosition="before" formControlName="isPublicNews" [disabled]="!hasTags"
            color="primary"><span style="margin-right: 3.8em;"> Share with Clients</span></mat-slide-toggle>
          <!-- 
        <div style="margin-right:0.25em">
          <input id="fAll" type="radio" name="isHeadlineNews" formControlName="isHeadlineNews" value="isHeadlineNews"
            [checked]="isHeadlineNews" (change)="searchFilterChange($event)" />
        </div>
        <div style="margin-right:0.25em">
          <input id="fAll" type="radio" name="isPublicNews" formControlName="isPublicNews" value="isPublicNews"
            [checked]="isPublicNews" (change)="searchFilterChange($event)" />
        </div> -->

          <div style="display: flex;">
            <div style="display: flex;">
              <label for="sourceDDL" style=" margin-right:1em; ">Source</label>
              <select class="form-control form-control-sm dropdown" id="sourceDDL" formControlName="advisorySourceID"
                style="width: 11em;">
                <option *ngFor="let s of sources" [value]="s.lookupId">{{s.description}}</option>
              </select>

            </div>

            <div style="display: flex;margin-left: 3em;">
              <label style="font-size:small">Disable Record</label>
              <div style="margin-top: -0.35em; display: inline-block;">
                <select formControlName="isActive" class="form-control form-control-sm dropdown"
                  style="margin-left: 1.9em; ">
                  <option value=true>No</option>
                  <option value=false>Yes</option>
                </select>
              </div>
            </div>
          </div>

        </div>


        <div class="col">

          <div style="margin-top: -1.8em;font-size: small;">
            <div style="width: 28em; display: inline-flex;">
              <label style="margin-bottom: 0em;">Documents</label>
              <label mat-button class="btn-high"
                style="border: 0.0625em solid transparent; background-color: transparent; height:1.5em; border-radius: 0.25em; cursor:pointer; margin-left: 0.5em; margin-right: 0.5em;">
                <input #document formControlName="document" id="document" type="file" ng2FileDrop [uploader]="uploader"
                  multiple style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000"
                  (click)="onFileClick($event)" />
                <div style="display: flex"><i class="material-icons"
                    style="font-size: medium; margin-top:0.25em; color: white; transform: rotate(-90deg);">attachment</i>
                  <div style="font-size: small;">Load New Documents</div>
                </div><span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
              </label>
            </div>
          </div>

          <div style="border-radius: 0.25em;border: 1px solid rgb(206, 212, 218);
                                  height: 6em; width: 32.5em;" ng2FileDrop [uploader]="uploader"
            (onFileDrop)="dropFile($event)" accept="*" multiple>
            <div style="height: 100%;     background: white;">
              <div style="overflow-y:auto; height: 100%;">
                <div *ngFor="let item of advisoryDocumentsList; let i=index" style="margin-top:-0.25em">
                  <ng-container *ngIf="item.isActive">
                    <div style="font-size: small; display:flex; padding:0em; height: 2em;">
                      <div>
                        <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline"
                          title="{{item.documentName}}"
                          (click)="previewDocument($event, item)">{{item.documentName}}</a>
                      </div>
                      <div *ngIf="item.groundStopTaskDocumentGUID=='' && hasExistingDocument"
                        style="margin-top:0.5em; font-size:x-small">
                        <i class="material-icons" style="color:green; font-size: small; ">fiber_new</i>
                      </div>
                      <div style="margin-top:0.25em; margin-right: 0.25em" *ngIf="!lockdownTrip">
                        <i class="material-icons" style="color:red; font-size: small; cursor: pointer;"
                          title="click to remove the document" (click)="clickRemoveDocument($event, item)">cancel</i>
                      </div>
                      <div *ngIf="!item.isInternal" title="Toggle Public/Internal"
                        style="cursor: pointer; user-select: none;" (click)="toggleInternalFile(item)">
                        <i class="material-symbols-outlined">more_horiz</i>
                      </div>
                      <div *ngIf="item.isInternal" title="Toggle Public/Internal"
                        style="cursor: pointer; background-color:#f9d2de; border-radius:0.25em; height: 1.5em; user-select: none;"
                        class="d-block" (click)="toggleInternalFile(item)">Internal...</div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>
    </form>



  </div>


  <div style="display: flex; width: 100%; justify-content: space-between;">
    <div style="display: flex;">

      <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em;   margin-right:1em; ">
        <button [disabled]="!hasTags" class="btn-high" mat-button (click)="clickSave()">Save</button>
        <button class="btn-low" mat-button (click)="clickClose(false)"
          style="background-color:grey; color: white; margin-left: 1em;">Close</button>
      </div>



      <div *ngIf="errMsg!=''"
        style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;height: 2em;">
        <label style="color:black; font-size: small">{{errMsg}}</label>
      </div>
      <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
    </div>

    <div>
      <div *ngIf="dialogTitle == 'Edit'" class="flex-column" style="margin-bottom: -0.8em;">
        <div>
          <label for="modifiedBy" style="font-size:small">Modified By: {{modifiedBy}}</label>
        </div>
        <div style="margin-top:-.5em">
          <label for="modifiedDate" style="font-size:small">Modified Date: {{modifiedDate}}</label>
        </div>

      </div>
    </div>
  </div>
</div>