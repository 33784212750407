<div *ngIf="ua==1 || ut=='i'" style="background-color:#f8f8f8;height:35em; overflow-y:hidden">
  <div style="display: flex; justify-content: space-between; overflow-y:hidden" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>Edit Permission and Customize User Access</h5></div>

  </div>
  <div style="padding-left:1em;padding-right:1em; ">
    <div style="background-color: white; padding-left: 1em; padding-right:1em; padding-top: 1em; height: 30em; min-width:45em">
      <form *ngIf="user_permission_client_edit_form" [formGroup]="user_permission_client_edit_form">
        <div style="display:flex;height:4.25em">
          <div style="margin-right:1em">
            <table mat-table *ngIf="userList && userList.length>0" [dataSource]="userList" class="example-container mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="username">
                  <th mat-header-cell *matHeaderCellDef style="background-color: #c1e4f7;">
                    Username
                  </th>
                  <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;font-weight:600">{{element.username}}</td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef style="background-color: #c1e4f7;">
                    Name
                  </th>
                  <td mat-cell *matCellDef="let element" style="white-space:nowrap;line-height : 1.5625em">{{element.flName}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef style="background-color: #c1e4f7;">
                    Email
                  </th>
                  <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;">{{element.email}}</td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>
          </div>
          <div style="margin-top:-1em">
            <div style="display: flex">
              <div>
                <label style="font-size:small; white-space:nowrap" for="account_status_select">Account Status</label>
                <div style="margin-top:-0.5em">
                  <select *ngIf="f" formControlName="account_status_select" style="font-size: small;border-radius:0.25em; width:8em; border-color:lightgrey" (change)="account_status_selectChange($event)">
                    <option [ngValue]="true">Active</option>
                    <option [ngValue]="false">Disabled</option>
                  </select>
                </div>
              </div>
              <div style="margin-left:1.25em">
                <label style="font-size:small; white-space:nowrap" for="admin_access">Account Access</label>
                <div style="margin-top:-0.5em">
                  <select *ngIf="f" formControlName="admin_access_select" style="font-size: small;border-radius:0.25em; width:8em; border-color:lightgrey" (change)="admin_access_selectChange($event)">
                    <option [ngValue]="true">Admin</option>
                    <option [ngValue]="false">Standard</option>
                  </select>
                </div>
              </div>
              <div style="margin-left: 1.25em;margin-top:1.5em" *ngIf="ut=='i'">
                <mat-checkbox (click)="$event.stopPropagation()" formControlName="locked" style="transform: scale(0.75)" (change)="lockedOnChange($event)">Locked</mat-checkbox>
              </div>
              <div style="margin-left:0.5em" *ngIf="ut=='i'">
                <label style="font-size:small; white-space:nowrap" for="lockedDateTime">Locked Date Time</label>
                <div style="margin-top:-0.35em">
                  <input type="text" formControlName="lockedDateTime" readonly class="form-control" style="padding: 0.25em; width: 10em; height: 1.5625em; font-size: small;" />
                </div>
              </div>
            </div>
            <!--<div style="font-size: small">Restrict Login Access to a Date Range</div>-->
            <div style="display: flex; margin-top:0.25em">
              <div>
                <div style="display:flex">
                  <label for="effectiveDate" style="font-size:small">Effective Date</label>
                  <div style="display:flex;margin-top:0em; margin-left:0.25em">
                    <input type="text" [matDatepicker]="effectivedatepicker" placeholder="MM/DD/YYYY" formControlName="effectiveDate" [min]="minDate" #effectiveDate [ngClass]="{ 'is-invalid': submittedEffectiverDate && f.effectiveDate.errors }" class="form-control" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" (dateChange)="effectiveDateChange($event)" />
                    <mat-datepicker-toggle matSuffix [for]="effectivedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                    <mat-datepicker #effectivedatepicker ></mat-datepicker>
                  </div>
                </div>
                <div *ngIf="submittedEffectiverDate && f.effectiveDate.errors" style="margin-top:0em">
                  <div *ngIf="f.effectiveDate.errors.isValidDate==false" style="margin-left:0em; background-color: #f9d2de;padding-left:0.25em;margin-top:-0.5em; width:10em; font-size:small; display:flex">Invalid effextive date.</div>
                </div>
              </div>
              <div style="margin-left:.5em;">
                <div style="display:flex">
                  <label for="expirationDate" style="font-size:small">Expiration Date</label>
                  <div style="display:flex;margin-top:0em; margin-left:0.25em">
                    <input type="text" [matDatepicker]="expirationdatepicker" placeholder="MM/DD/YYYY" formControlName="expirationDate" [min]="minDate" #expirationDate class="form-control" [ngClass]="{ 'is-invalid': submittedExpirationDate && f.expirationDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" (dateChange)="expirationDateChange($event)" />
                    <mat-datepicker-toggle matSuffix [for]="expirationdatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                    <mat-datepicker #expirationdatepicker></mat-datepicker>
                  </div>
                </div>
                <div *ngIf="submittedExpirationDate && f.expirationDate.errors" style="margin-top:0em">
                  <div *ngIf="f.expirationDate.errors.isValidDate==false" style="margin-left:0em; background-color: #f9d2de;padding-left:0.25em;margin-top:-0.5em; width:11em; font-size:small; display:flex">Invalid expiration date.</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="form-group form-inline" style="padding: 0em; margin-top: 0.75em; background-color: #f8f8f8; margin-left:-1em; margin-right:-1em">
          <ul class="tabul">
            <li [ngClass]="{ 'btn-high':tabName=='registration','btn-low pointer':tabName!='registration'  }" style="border-left: black 0.0625em solid;" (click)="clickTab('registration')"><div style="margin-top:0.25em">Registration</div></li>
            <li [ngClass]="{ 'btn-high':tabName=='pic','btn-low pointer':tabName!='pic'  }" (click)="clickTab('pic')"><div style="margin-top:0.25em">PIC / SIC</div></li>
            <!--<li [ngClass]="{ 'btn-low':tabName=='sic','btn-high pointer':tabName!='sic'  }" (click)="clickTab('sic')"><div style="margin-top:0.25em">SIC</div></li>-->
            <li [ngClass]="{ 'btn-high':tabName=='pax','btn-low pointer':tabName!='pax'  }" (click)="clickTab('pax')"><div style="margin-top:0.25em">PAX</div></li>
            <li [ngClass]="{ 'btn-high':tabName=='restrict','btn-low pointer':tabName!='restrict'  }" (click)="clickTab('restrict')"><div style="margin-top:0.25em">Manage Permissions</div></li>
          </ul>
        </div>
      </form>
      <div>
      <app-user-permission-registration-edit-dialog #acPermission *ngIf="tabName=='registration'" [tabName]="tabName" [userId]="userId" [customerGuid]="customerGuid" [userType]="userType" [admin_access_select]="admin_access_select" [account_status_select]="account_status_select" [effectiveDateText]="effectiveDateText" [expirationDateText]="expirationDateText" [isValidateExpirationDate]="isValidateExpirationDate" [isValidateEffectiveDate]="isValidateEffectiveDate" [locked]="locked" (sendDataToParent)="onSendDataToParent($event)"></app-user-permission-registration-edit-dialog>
      <app-user-permission-person-edit-dialog #pPermission *ngIf="tabName=='pic'" [tabName]="tabName" [userId]="userId" [customerGuid]="customerGuid" [userType]="userType" [admin_access_select]="admin_access_select" [account_status_select]="account_status_select" [effectiveDateText]="effectiveDateText" [expirationDateText]="expirationDateText" [isValidateExpirationDate]="isValidateExpirationDate" [isValidateEffectiveDate]="isValidateEffectiveDate" [locked]="locked" (sendDataToParent)="onSendDataToParent($event)"></app-user-permission-person-edit-dialog>
      <app-user-permission-person-edit-dialog #pPermission *ngIf="tabName=='sic'" [tabName]="tabName" [userId]="userId" [customerGuid]="customerGuid" [userType]="userType" [admin_access_select]="admin_access_select" [account_status_select]="account_status_select" [effectiveDateText]="effectiveDateText" [expirationDateText]="expirationDateText" [isValidateExpirationDate]="isValidateExpirationDate" [isValidateEffectiveDate]="isValidateEffectiveDate" [locked]="locked" (sendDataToParent)="onSendDataToParent($event)"></app-user-permission-person-edit-dialog>
      <app-user-permission-person-edit-dialog #pPermission *ngIf="tabName=='pax'" [tabName]="tabName" [userId]="userId" [customerGuid]="customerGuid" [userType]="userType" [admin_access_select]="admin_access_select" [account_status_select]="account_status_select" [effectiveDateText]="effectiveDateText" [expirationDateText]="expirationDateText" [isValidateExpirationDate]="isValidateExpirationDate" [isValidateEffectiveDate]="isValidateEffectiveDate" [locked]="locked" (sendDataToParent)="onSendDataToParent($event)"></app-user-permission-person-edit-dialog>
      <app-user-permission-restrict-client-edit-dialog #rPermission *ngIf="tabName=='restrict'" [tabName]="tabName" [userId]="userId" [customerGuid]="customerGuid" [userType]="userType" [admin_access_select]="admin_access_select" [account_status_select]="account_status_select" [effectiveDateText]="effectiveDateText" [expirationDateText]="expirationDateText" [isValidateExpirationDate]="isValidateExpirationDate" [isValidateEffectiveDate]="isValidateEffectiveDate" [locked]="locked" (sendDataToParent)="onSendDataToParent($event)"></app-user-permission-restrict-client-edit-dialog>
    </div>
    </div>
    
  </div>
  
</div>
