<app-header (sidenavToggle)="sidenav.toggle();isShowing = !isShowing" class="leftAlign"></app-header>
<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation" mode="side" style="position: fixed; min-height: 100%; background-color: #cc0000; border:none; width:250px; padding-left: 2px;">
    <app-menu (navNodeClick)="resize()"></app-menu>
    <!--<app-menu2 (navNodeClick)="resize()"></app-menu2>-->
  </mat-sidenav>
  <mat-sidenav-content autosize class="centered" style="min-width: 80%;">
    <router-outlet ></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


