<div class="divLeft">
  <div>
    <h5>Client Profile Setup</h5>
    <div>
      <form *ngIf="customer_profile_setup_form" [formGroup]="customer_profile_setup_form" (ngSubmit)="onSubmit()">
        <div style="margin-left:0em;padding:0em; display: flex">
          <div *ngIf="userType=='internal'" class="form-group" style="margin-top:0em; margin-right:0.625em">
            <label for="customer_select" style="font-size:small">Select Client</label>
            <div style="margin-top:-0.35em;">
              <select *ngIf="f" formControlName="customer_select" class="form-control form-control-sm dropdown" (change)="customerChange($event)">
                <option value="" selected>All Accounts</option>
                <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
              </select>
            </div>
          </div>
          <div style="margin-left: 0em;margin-top:0em; ">
            <label for="text_search" style="font-size:small">Search by {{textSearchBy}}</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.35em">
              <input type="text" formControlName="text_search" class="form-control form-control-sm inputBox" style="width:9.75em;" />
            </div>
          </div>
          <div style="margin-left: 0.3125em; margin-top:0em">
            <label for="filter_by" style="font-size:small">Filter by</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.35em">
              <select class="form-control form-control-sm dropdown" *ngIf="f" formControlName="filter_by">
                <option value="CreditStatus" selected>Credit Status</option>
                <option value="BillingStatus">Billing Status</option>
                <option value="SalesTerritory">Sales Territory</option>
                <option value="ClientName">Client Name</option>
              </select>
            </div>
          </div>
          <div class="form-group" style="margin-left: 0.3125em;margin-top:1.35em">
            
              <button mat-button [disabled]="loading" class="btn-high">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Search
              </button>&nbsp;
              <button mat-button type="button" class="btn-low" (click)="clickReset()">Reset</button>&nbsp;
              <button mat-button type="button" class="btn-low" (click)="clickShowAllRecords()">Show All Records</button>&nbsp;
              
                <label *ngIf="totalRecords>0" class="small ml-2">{{totalRecords}} {{totalRecords>1? 'Records':'Record'}}</label>
              
            
          </div>
          
        </div>
        <div *ngIf="msg!=''" class="inline-block"><label style="color:forestgreen">&nbsp;{{msg}}</label></div>
        <div *ngIf="errMsg!=''" class="inline-block"><label style="color: #c00000">{{errMsg}}</label></div>
        <div class="form-group form-inline">
          <ul class="tabul">
            <li [ngClass]="{ 'btn-high':selectedTabName=='active','btn-low pointer':selectedTabName!='active'  }" style="border-left: black 0.0625em solid;" (click)="clickTab('active')"><div style="margin-top:0.25em">Active Accts</div></li>
            <li [ngClass]="{ 'btn-high':selectedTabName=='suspended','btn-low pointer':selectedTabName!='suspended' }" (click)="clickTab('suspended')"><div style="margin-top:0.25em">Suspended Accts</div></li>
            <li [ngClass]="{ 'btn-high':selectedTabName=='closed','btn-low pointer':selectedTabName!='closed' }" (click)="clickTab('closed')"><div style="margin-top:0.25em">Closed Accts</div></li>
            <li [ngClass]="{ 'btn-high':selectedTabName=='prospect','btn-low pointer':selectedTabName!='prospect' }" (click)="clickTab('prospect')"><div style="margin-top:0.25em">Prospect Accts</div></li>
            <!--<li [ngClass]="{ 'btn-high':selectedTabName=='lead','btn-low pointer':selectedTabName!='lead' }" (click)="clickTab('lead')"><div style="margin-top:0.25em">Lead Accts</div></li>-->
          </ul>
        </div>
        <div style="margin-top:-1em">
          <table mat-table  [dataSource]="recordList" class="example-container">
            <tr>
              <ng-container matColumnDef="customer_name">
                <th mat-header-cell *matHeaderCellDef class="th">
                  Client Name
                </th>
                <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
              </ng-container>
              <ng-container matColumnDef="acct_create_date">
                <th mat-header-cell *matHeaderCellDef class="th">
                  Account<br /> Creation Date
                </th>
                <td mat-cell *matCellDef="let element">{{element.acctCreationDate}}</td>
              </ng-container>
              <ng-container matColumnDef="credit_status">
                <th mat-header-cell *matHeaderCellDef class="th">
                  Credit<br />Status
                </th>
                <td mat-cell *matCellDef="let element">{{element.creditStatus}}</td>
              </ng-container>
              <ng-container matColumnDef="billing_status">
                <th mat-header-cell *matHeaderCellDef class="th">
                  Billing<br />Status
                </th>
                <td mat-cell *matCellDef="let element">{{element.customerBillingStatus}}</td>
              </ng-container>
              <ng-container matColumnDef="requires_backup_invoice">
                <th mat-header-cell *matHeaderCellDef class="th">
                  Requires Third<br />Party Backup<br />Invoices
                </th>
                <td mat-cell *matCellDef="let element">{{element.requiresBackupInvoicesDesc}}</td>
              </ng-container>
              <ng-container matColumnDef="sales_territory">
                <th mat-header-cell *matHeaderCellDef class="th">
                  Sales Territory
                </th>
                <td mat-cell *matCellDef="let element">{{element.salesTerritory}}</td>
              </ng-container>
              <ng-container matColumnDef="scheduling_software">
                <th mat-header-cell *matHeaderCellDef class="th">
                  Scheduling<br />Software
                </th>
                <td mat-cell *matCellDef="let element">{{element.schedulingSoftwareName}}</td>
              </ng-container>
              <ng-container matColumnDef="halt_services">
                <th mat-header-cell *matHeaderCellDef class="th">
                  Halt Services
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.haltServices" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_notes">
                <th mat-header-cell *matHeaderCellDef class="th">
                  Ops & Wx<br />Note
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasNotes" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="sales_notes">
                <th mat-header-cell *matHeaderCellDef class="th">
                 Sales<br />Note
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasSalesNotes" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_ukapd">
                <th mat-header-cell *matHeaderCellDef class="th">
                  UK APD
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasUKAPD" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_eurocontrol">
                <th mat-header-cell *matHeaderCellDef class="th">
                  Euro Control
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasEuroControl" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_seneam">
                <th mat-header-cell *matHeaderCellDef class="th">
                  SENEAM
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasSeneam" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_cocesna">
                <th mat-header-cell *matHeaderCellDef class="th">
                  COCESNA
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasCocesna" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_boe">
                <th mat-header-cell *matHeaderCellDef class="th">
                  BOE
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasBOE" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_vwp">
                <th mat-header-cell *matHeaderCellDef class="th">
                  VWP
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasVWP" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="has_part375">
                <th mat-header-cell *matHeaderCellDef class="th">
                  Part 375<br />Auth
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.hasPart375" class="material-icons" style="color:green; margin-top:0.25em;">done</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="th">Action<br /><a mat-button class="btn-high grid" (click)="clickAdd($event, null)">Add</a></th>
                <td mat-cell *matCellDef="let element; let i=index ">
                  <a mat-button class="btn-low grid">
                    Edit
                  </a>
                </td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickEdit($event, row);"></tr>
          </table>

        </div>
        <!--<div>
          <mat-paginator style="border: none" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                         [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
          </mat-paginator>
        </div>-->


      </form>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
