import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { CustomerModel } from '../models/customer.model';
import { DialogService } from '../services/dialog.service';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CustomerService } from '../services/customer.service';
import { ResponseModel } from '../models/response.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { CustomerGroupService } from '../services/customer-group-service';
import { AccountGrouplistComponent } from './account-grouplist.component';

@Component({
  selector: 'app-account-group-edit-dialog',
  templateUrl: './account-group-edit-dialog.component.html',
  styleUrls: ['./account-group-edit-dialog.component.css']
})

export class AccountGroupEditDialogComponent implements OnInit {
  customerGroupGUID: string;  
  account_group_edit_form: UntypedFormGroup;
 
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = false;
  isModified: boolean = false;
  returnObj = new ReturnObjModel();
  showSuccessMsg: boolean = false;

  client_group_name: string="";
  internal_note: string = "";
  acct_status: string = "1";
  modifiedBy: string = "";
  modifiedDate: string = "";
  grantAccessToManageAccountGrouping: boolean = false;
  dataChanged: boolean = false;
  fromDialog: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _customerGroupService: CustomerGroupService, private readonly _route: ActivatedRoute,
    private readonly _dialog: MatDialog, private readonly _dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialogRef: MatDialogRef<AccountGroupEditDialogComponent>) {
    this.customerGroupGUID = _data.customerGroupGUID;
    
    this.fromDialog = _data.fromDialog;
  }

  getAllData(): Observable<any[]> {   
    let getClientGroupResponse;
    if (this.customerGroupGUID != "") {
      let req = new CustomerGroupModel();
      req.customerGroupGUID = this.customerGroupGUID;
      getClientGroupResponse = this._customerGroupService.getCustomerGroupBycgrpId(req);
    }
    else {
      getClientGroupResponse = of(null);
    }
    
    return forkJoin([getClientGroupResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToManageAccountGrouping = false;
    if (localStorage.getItem('up.mGrp')!=null && localStorage.getItem('up.mGrp') == 'true') {
      this.grantAccessToManageAccountGrouping = true;
    }   

    if (!this.grantAccessToManageAccountGrouping) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this._dialogRef.close();;
      return;
    }
    this.errMsg =""
    this.client_group_name = "";
    this.internal_note = "";
    this.modifiedBy= "";
    this.modifiedDate= "";
    this.acct_status = "1";

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let obj = new CustomerGroupModel();
          obj = responses[0].model;
          this.client_group_name=obj.customerGroupName
          this.internal_note = obj.internalNotes;
          if (obj.isActive)
            this.acct_status = "1";
          else
            this.acct_status = "0";
          this.modifiedBy = obj.modifiedBy;
          this.modifiedDate=obj.modifiedDate
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.checkFormControlValueChanged();

      this.showSpin = false;      

    });
   
   
  }

  initControls() {
    this.account_group_edit_form = this._formBuilder.group({
      client_group_name: [this.client_group_name, Validators.required],
      internal_note: [this.internal_note],
      acct_status: { value: this.acct_status}
     
    });

    this.f.acct_status.setValue(this.acct_status);

  }

  checkFormControlValueChanged(): void {
    this.account_group_edit_form.get("client_group_name").valueChanges.subscribe(x => {
      this.client_group_name = this.f.client_group_name.value;
      this.dataChanged=true;
    });
    this.account_group_edit_form.get("internal_note").valueChanges.subscribe(x => {
      this.internal_note = this.f.internal_note.value;
      this.dataChanged = true;
    });
    this.account_group_edit_form.get("acct_status").valueChanges.subscribe(x => {
      this.acct_status = this.f.acct_status.value;
      this.dataChanged = true;
    });

  }


  get f() { return this.account_group_edit_form.controls; }

  checkCustomerGroupName(): Observable<any> {
    return new Observable<any>(ob => {
      let request = new CustomerGroupModel();
      request.customerGroupName = this.f.client_group_name.value;
      request.customerGroupGUID = this.customerGroupGUID;
      try {
        this._customerGroupService.checkCustomerGroupName<ResponseModel<Number>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            if (Number(response.model) > 0) {
              //this.errMsg = '"' + this.f.username.value + '" has been used in our system. Please enter a different username.';                  
              ob.next(true);
            }
            else {
              ob.next(false);
            }
          }
          else {
            ob.next(null);
          }
        });
      }
      catch (error) {
        //console.log(error);
        ob.next(null);
      }
    });

  }

  clickSave() {
    this.errMsg = "";
    this._authService.updateAccessTime();

    if ((this.dataChanged && this.customerGroupGUID != "") || this.customerGroupGUID == "") {
      this.submitted = true;
      if (this.loading == true) {
        return;
      }
      if (this.account_group_edit_form.invalid) {
        return;
      }

      this.checkCustomerGroupName().subscribe(res => {
        if ((res != null || res != undefined) && res == true) {
          this.f.client_group_name.setErrors({ checkExistingGroupName: true });
          return;
        }
        else {
          this.f.client_group_name.updateValueAndValidity();

          let request = new CustomerGroupModel();
          request.customerGroupGUID = this.customerGroupGUID;
          request.customerGroupName = this.f.client_group_name.value;
          request.internalNotes = this.f.internal_note.value;
          request.isActive = true;
          if (this.f.acct_status.value == "0") {
            request.isActive = false;
          }

          this._customerGroupService.saveCustomerGroup<ResponseModel<CustomerGroupModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              if (response.model != null) {
                let ret = new CustomerGroupModel();
                ret = response.model;
                if (ret.errMsg == "") {
                  let d = new Date();
                  let m = d.getMonth() + 1;
                  let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
                  this.modifiedBy = localStorage.getItem('un');
                  this.modifiedDate = dt;
                  this.isModified = true;
                  this.returnObj.refresh = this.isModified;
                  this.returnObj.newId = ret.customerGroupGUID;
                  this.showSuccessMsg = true;
                  setTimeout(() => {
                    this.showSuccessMsg = false;
                    this.clickClose('save');
                  }, 1000);
                }
                else {
                  this.f.client_group_name.setErrors({ checkExistingGroupName: true });
                  return;
                }               
              }

            }
            else {
              if (response.code == "401") {
                if (response.message == "")
                  this._authService.signOut();
                else {
                  this.errMsg = response.message
                }
              }
              else {
                this.errMsg = "Failed to save the record. Please try again later.";
              }
            }
            this.loading = false;
            this.showSpin = false;

          })

        }
      });
    }
    else {      
      this.returnObj.refresh = this.isModified;
      this.returnObj.newId = this.customerGroupGUID;
      this.clickClose('save');
    }

  }


 
  clickClose(actionFrom: string) {
    if (actionFrom == null || actionFrom == "" || actionFrom == undefined)
      this.returnObj.newId = "";
    this._dialogRef.close(this.returnObj);
    if(this.fromDialog){

      this.openAccountGrouping();
    }
  }

  
  openAccountGrouping(){
    // clickRoute('account-groups/account-grouplist','')
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container2"
    //config.disableClose = true;
    config.height = "32.5em";//"1000px";
    config.maxWidth = "64.5em";//"1000px";
    config.width = "37.5em";//"1000px";
    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {    v: this._authService.getCurrentTimeNumber()    };
    this._dialog.open(AccountGrouplistComponent, config);
  }


}
