export class IdentityRequestModel {
  username: string;
  signInKey: string;
  oldPassword: string;
  newPassword: string;
  refreshToken: string;
  customerGuid: string;
  vendorGuid: string;
  userId: string;

  pageSize: number;
  currentPageIndex: number;
  sortCol: string;
  sortDirection: string;
}
