import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, ElementRef, ViewChildren, ViewContainerRef, QueryList } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
//import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
//import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { MatLegacyPaginator, MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { TripModel } from '../models/trip.model';
import { CustomerCreditStatusModel } from '../models/customer-credit-status.model';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { IconLegendDialogComponent } from '../common-utility/icon-legend-dialog.component';
import { DialogService } from '../services/dialog.service';
import { AircraftModel } from '../models/aircraft.model';
import { AirportModel } from '../models/airport.model';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { VendorModel } from '../models/vendor.model';
import { Title } from '@angular/platform-browser';
import { MenuService } from '../services/menu.service';
import { CustomPageSizeOptionModel } from '../models/custom-page-size-option.model';
//import { PersonModel } from '../models/person.model';
//import { CustomerPersonEditDialogComponent } from './customer-person-edit-dialog.component';
//import { PersonService } from '../services/person.service';
//import { PersonClassModel } from '../models/person-class.model';

@Component({
  selector: 'app-trip-quick-search-control-internal',
  templateUrl: './trip-quick-search-control-internal.component.html',
  styleUrls: ['./trip-quick-search-control-internal.component.css'],
  //encapsulation: ViewEncapsulation.None

})

export class TripQuickSearchControlInternalComponent implements OnInit {
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  trip_quick_search_control_internal_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg1: string;
  msg: string;
  customerList: CustomerModel[];
  gsTypeList: GroundStopTypeModel[];
  selectedGSTypeList: GroundStopTypeModel[];
  customerCreditList: CustomerCreditStatusModel[];
  selectedCustomerCreditStatusID: number;
  selectedCustomerCreditList: CustomerCreditStatusModel[];
  //userType: string;
  showSpin: boolean = true;
 
  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  selectedTabName: string;
  reloadChildPage: boolean;
  totalRecordsText: string;
  v: number = this._authService.getCurrentTimeNumber();
  request: TripModel;
  
  startDate: Date;
  endDate: Date;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  pageFrom: string = "";
  sort_by: string;
  upPII: boolean = false;
  registrationList: AircraftModel[];
  selectedRegistrationList: AircraftModel[];
  showClientList: boolean = false;
  showAircraftList: boolean = false;
  airportList: AirportModel[];
  selectedAirportList: AirportModel[];
  showAirportList: boolean = false;
  showPersonList: boolean = false;
  pilotList: GroundStopManifestModel[];
  selectedPilotList: GroundStopManifestModel[];
  crewList: GroundStopManifestModel[];
  selectedCrewList: GroundStopManifestModel[];
  paxList: GroundStopManifestModel[];
  selectedPaxList: GroundStopManifestModel[];
  person_role: string;
  showVendorList: boolean = false;
  vendorList: VendorModel[];
  orgVendorList: VendorModel[];
  selectedVendorList: VendorModel[];
  orgCustomerList: CustomerModel[];
  selectedCustomerList: CustomerModel[];
  selectedRegistrationCountText: string = "";
  selectedAirportCountText: string = "";
  selectedPersonCountText: string = "";
  selectedClientCountText: string = "";
  selectedVendorCountText: string = "";
  selectedGSTypeCountText: string = "";
  selectedCreditStatusCountText: string = "";
  selectedTripCodeCountText: string = "";
  showGSTypeList: boolean = false;
  showCustomerCreditList: boolean = false;
  showTripCodeList: boolean = false;
  @ViewChildren('tripClientRefs', { read: ViewContainerRef }) tripClientRefs: QueryList<ViewContainerRef>;
  @ViewChildren('tripAircraftRefs', { read: ViewContainerRef }) tripAircraftRefs: QueryList<ViewContainerRef>;
  @ViewChildren('tripAirportRefs', { read: ViewContainerRef }) tripAirportRefs: QueryList<ViewContainerRef>;
  @ViewChildren('tripVendorRefs', { read: ViewContainerRef }) tripVendorRefs: QueryList<ViewContainerRef>;
  @ViewChildren('tripPersonRefs', { read: ViewContainerRef }) tripPersonRefs: QueryList<ViewContainerRef>;
  @ViewChildren('tripCodeRefs', { read: ViewContainerRef }) tripCodeRefs: QueryList<ViewContainerRef>;
  tripCodeList: TripModel[] = [];
  selectedTripCodeList: TripModel[] = [];
  websiteEnvironment: string = "";
  orgTripCodeList: TripModel[] = [];
  orgRegistrationList: AircraftModel[]=[];
  orgAirportList: AirportModel[] = [];
  totalRecordsTextPage: string;
  totalRecords: number = 0;
  pageSize: number = 25;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  customPageSizeOptionList: CustomPageSizeOptionModel[] = [];
  minPageSize: number = 25;
  maxPageSize: number = 100;
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;
  flightType: number = 0;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _dialogService: DialogService, private _titleService: Title, private readonly _menuService: MenuService
  ) {

  }

  getAllData(): Observable<any[]> {
    let getGSTypeResponse = this._groundStopService.getGroundStopTypeList();
    let getCustomerCreditResponse = this._groundStopService.getCustomerCreditStatusList();
    let req = new TripModel();
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      req.tripCloseOutID = 0;
    }
    else {
      req.tripCloseOutID = 1;
    }
    req.includeDemo = this.f.includeDemo.value;
    req.flightType = this.flightType;
    let getClientResponse;
    if (localStorage.getItem('trip.clist') != null && localStorage.getItem('trip.clist') != "") {
      getClientResponse = this._groundStopService.getTripClientListByDateRange(req);
    }
    else {
      getClientResponse = of(null);
    }
    let getACResponse;
    if (localStorage.getItem('trip.aclist') != null && localStorage.getItem('trip.aclist') != "") {
      getACResponse = this._groundStopService.getTripRegistrationListByDateRange(req);
    }
    else {
      getACResponse = of(null);
    }
    let getAPResponse;
    if (localStorage.getItem('trip.aplist') != null && localStorage.getItem('trip.aplist') != "") {
      getAPResponse = this._groundStopService.getTripAirportListByDateRange(req);
    }
    else {
      getAPResponse = of(null);
    }
    let getPersonResponse;
    req.personRole = this.person_role;
    if (localStorage.getItem('trip.plist') != null && localStorage.getItem('trip.plist') != "") {
      getPersonResponse = this._groundStopService.getTripPersonListByDateRange(req);
    }
    else {
      getPersonResponse = of(null);
    }
    let getVendorResponse;
    if (localStorage.getItem('trip.vlist') != null && localStorage.getItem('trip.vlist') != "") {
      getVendorResponse = this._groundStopService.getTripVendorListByDateRange(req);
    }
    else {
      getVendorResponse = of(null);
    }
    let getTripCodeResponse;
    if (localStorage.getItem('trip.tclist') != null && localStorage.getItem('trip.tclist') != "") {
      getTripCodeResponse = this._groundStopService.getTripCodeListByDateRange(req);
    }
    else {
      getTripCodeResponse = of(null);
    }
    let getWebsiteEnvironmentResponse = this._menuService.getWebsiteEnviroment();
    return forkJoin([getGSTypeResponse, getCustomerCreditResponse, getClientResponse, getACResponse, getAPResponse, getPersonResponse, getVendorResponse, getTripCodeResponse, getWebsiteEnvironmentResponse]);
   
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    else {
      this.upPII = true;
    }
    this._route.queryParams.subscribe(params => {
      this.pageFrom = params["pf"];

    });
    if (this.pageFrom == null || this.pageFrom == undefined) {
      this.pageFrom = "";
    }

    if (localStorage.getItem('trip.filterTime') != null) {
      var lastStoreTime = Number(localStorage.getItem('trip.filterTime'));
      var now = Date.now();
      if ((now - lastStoreTime) >= (540 * 60000)) // 9 hr
      //if ((now - lastStoreTime) >= (2 * 60000)) 
      {
        localStorage.removeItem('trip.sd');
        localStorage.removeItem('trip.ed');
        localStorage.removeItem('trip.tco');
        localStorage.removeItem('trip.idemo');
        localStorage.removeItem('trip.ord');
        localStorage.removeItem('trip.clist');
        localStorage.removeItem('trip.aclist');
        localStorage.removeItem('trip.aplist');
        localStorage.removeItem('trip.prole');
        localStorage.removeItem('trip.plist');
        localStorage.removeItem('trip.vlist');
        localStorage.removeItem('trip.gstlist');
        localStorage.removeItem('trip.cclist');
        localStorage.removeItem('trip.tclist');
        localStorage.removeItem('trip.filterTime');
      }
    }

    //if (this.pageFrom == "") {     
    //  localStorage.removeItem('trip.sd');
    //  localStorage.removeItem('trip.ed');
    //  localStorage.removeItem('trip.tco');
    //  localStorage.removeItem('trip.idemo');
    //  localStorage.removeItem('trip.ord');
    //  localStorage.removeItem('trip.clist');
    //  localStorage.removeItem('trip.aclist');
    //  localStorage.removeItem('trip.aplist');
    //  localStorage.removeItem('trip.prole');
    //  localStorage.removeItem('trip.plist');
    //  localStorage.removeItem('trip.vlist');
    //  localStorage.removeItem('trip.gstlist');
    //  localStorage.removeItem('trip.cclist');
    //  localStorage.removeItem('trip.tclist');
    //  localStorage.removeItem('trip.filterTime');
    //}
    this.showSpin = true;
    
    this.errMsg = "";
    this.errMsg1 = "";
    this.msg = "";
    this.customerList = [];
    this.gsTypeList = [];
    this.selectedGSTypeList = [];
    this.customerCreditList = [];
    this.selectedCustomerCreditList = [];
    this.selectedCustomerCreditStatusID = 0;
    this.includeClosedBilledRecords = false;
    this.includeDemo = false;
    this.selectedTabName = 'tripcode_tactical';
    this.reloadChildPage = false;
   
    this.totalRecordsText = "";
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.endDate = null;
    //this.endDate = new Date();
    //this.endDate.setDate(this.endDate.getDate() + 28);
    if (this.selectedTabName != 'aircraft') {
      this.sort_by = "departureDate asc";
    }
    else {
      this.sort_by = "registration asc";
    }
    this.orgCustomerList = [];
    this.customerList = [];
    this.selectedCustomerList = [];
    this.registrationList = [];
    this.selectedRegistrationList = [];
    this.airportList = [];
    this.selectedAirportList = [];
    this.pilotList = [];
    this.selectedPilotList = [];
    this.crewList = [];
    this.selectedCrewList = [];
    this.paxList = [];
    this.selectedPaxList = [];
    this.person_role = "pilot";
    this.orgVendorList = [];
    this.vendorList = [];
    this.selectedVendorList = [];
    this.tripCodeList = [];
    this.selectedTripCodeList = [];
    let gst: string = "";
    if (this.pageFrom == "tripdetail") {
      
      if (localStorage.getItem('trip.sd') != null && localStorage.getItem('trip.sd') != "") {
        this.startDate = new Date(localStorage.getItem('trip.sd'));
      }
      if (localStorage.getItem('trip.ed') != null && localStorage.getItem('trip.ed') != "") {
        this.endDate = new Date(localStorage.getItem('trip.ed'));
      }
      
      if (localStorage.getItem('trip.tco') != null && localStorage.getItem('trip.tco') != "") {
        if (localStorage.getItem('trip.tco') == "1")
          this.includeClosedBilledRecords = false;
        else {
          this.includeClosedBilledRecords = true;
        }
      }
      
      if (localStorage.getItem('trip.idemo') != null && localStorage.getItem('trip.idemo') != "") {
        if (localStorage.getItem('trip.idemo') == "true")
          this.includeDemo = true;
        else {
          this.includeDemo = false;
        }   
      }
        
      if (localStorage.getItem('trip.ord') != null && localStorage.getItem('trip.ord') != "") {
        this.sort_by = localStorage.getItem('trip.ord');
      }
      if (localStorage.getItem('trip.prole') != null && localStorage.getItem('trip.prole') != "") {
        this.person_role = localStorage.getItem('trip.prole');
      }

    }

    //switch (localStorage.getItem('ut').toLowerCase()) {
    //  case "i":
    //    this.userType = "internal";
    //    break;
    //  case "c":
    //    this.userType = "customer";
    //    break;
    //  case "v":
    //    this.userType = "vendor";
    //    break;
    //  default:
    //    this.userType = "";
    //    break;
    //}
    this.initControls();
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {       
          let g = new GroundStopTypeModel();
          g.groundStopTypeGUID = "all";
          g.groundStopTypeDescription = "Select All";
          g.selected = false;
          this.gsTypeList.push(g);
          responses[0].model.forEach(x => {
            g = new GroundStopTypeModel();
            g.groundStopTypeGUID = x.groundStopTypeGUID;
            g.groundStopTypeDescription = x.groundStopTypeDescription;
            g.selected = false;
            this.gsTypeList.push(g);
          });
          if (localStorage.getItem('trip.gstlist') != null && localStorage.getItem('trip.gstlist') != "") {
            let g: string = localStorage.getItem('trip.gstlist');
            let g1: string[] = g.split(',');
            g1.forEach(x => {
              let i: number = this.gsTypeList.findIndex(y => y.groundStopTypeGUID.toLowerCase() == x.toLowerCase());
              if (i != -1) {
                this.gsTypeList[i].selected = true;
              }
            })
          }
          else {
            this.gsTypeList.find(x => x.groundStopTypeGUID.toLowerCase() == "868a62b1-9cd6-499b-abfa-46542ccb7424").selected = true;
          }
          this.setGSTypeList();
        }
        //else {
        //  if (responses[0].code == "401") {
        //    this._authService.signOut();
        //  }
        //}
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          let clist = responses[1].model.filter(x =>x.customerCreditStatusID!=0);
          let c = new CustomerCreditStatusModel();
          c.customerCreditStatusID = 0;
          c.customerCreditStatusDesc= "Select All";
          c.selected = false;
          this.customerCreditList.push(c);
          clist.forEach(x => {
            c = new CustomerCreditStatusModel();
            c.customerCreditStatusID = x.customerCreditStatusID;
            c.customerCreditStatusDesc = x.customerCreditStatusDesc;
            c.selected = false;            
            this.customerCreditList.push(c);
          })
          if (localStorage.getItem('trip.cclist') != null && localStorage.getItem('trip.cclist') != "") {
            let c2: string = localStorage.getItem('trip.cclist');
            let c1: string[] = c2.split(',');
            c1.forEach(x => {
              let i: number = this.customerCreditList.findIndex(y => y.customerCreditStatusID ==Number(x));
              if (i != -1) {
                this.customerCreditList[i].selected = true;
              }
            })
          }
          this.setCreditStatusList();
        }
        //else {
        //  if (responses[1].code == "401") {
        //    this._authService.signOut();
        //  }
        //}
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          let c = new CustomerModel();
          c.customerGUID = "all";
          c.customerName = "Select All";
          c.selected = false;
          this.customerList.push(c);
          responses[2].model.forEach(x => {
            c = new CustomerModel();
            c.customerGUID = x.customerGUID;
            c.customerName = x.customerName;
            c.selected = false;
            this.customerList.push(c);
          });
          if (localStorage.getItem('trip.clist') != null && localStorage.getItem('trip.clist') != "") {
            let c2: string = localStorage.getItem('trip.clist');
            let c1: string[] = c2.split(',');
            c1.forEach(x => {
              let i: number = this.customerList.findIndex(y => y.customerGUID.toLowerCase() == x.toLowerCase());
              if (i != -1) {
                this.customerList[i].selected = true;
              }
            })
          }
          this.orgCustomerList = this.customerList;
          this.setClientList();
        }
        //else {
        //  if (responses[2].code == "401") {
        //    this._authService.signOut();
        //  }
        //}
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          let ac = new AircraftModel();
          ac.aircraftGuid = "all";
          ac.registration = "Select All";
          ac.selected = false;
          this.registrationList.push(ac);
          responses[3].model.forEach(x => {
            ac = new AircraftModel();
            ac.aircraftGuid =x.aircraftGUID;
            ac.registration = x.registration;
            ac.selected = false;
            this.registrationList.push(ac);
          });
          if (localStorage.getItem('trip.aclist') != null && localStorage.getItem('trip.aclist') != "") {
            let c2: string = localStorage.getItem('trip.aclist');
            let c1: string[] = c2.split(',');
            c1.forEach(x => {
              let i: number = this.registrationList.findIndex(y => y.aircraftGuid.toLowerCase() == x.toLowerCase());
              if (i != -1) {
                this.registrationList[i].selected = true;
              }
            })
          }
          this.orgRegistrationList = this.registrationList;
          this.setRegistrationList();
        }
        //else {
        //  if (responses[3].code == "401") {
        //    this._authService.signOut();
        //  }
        //}
      }
           
      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          let ap = new AirportModel();
          ap.icao = "Select All";
          ap.iata = "";
          ap.airportID = 0;
          ap.selected = false;
          this.airportList.push(ap);        
          responses[4].model.forEach((x, index) => {
            ap = new AirportModel();
            ap.icao = x.icao;
            ap.iata = x.iata;
            ap.airportID = index+1;
            ap.selected = false;
            this.airportList.push(ap);
          });
          if (localStorage.getItem('trip.aplist') != null && localStorage.getItem('trip.aplist') != "") {
            let c2: string = localStorage.getItem('trip.aplist');
            let c1: string[] = c2.split(',');
            c1.forEach(x => {
              let i: number = this.airportList.findIndex(y => y.icao.toLowerCase() == x.toLowerCase());
              if (i != -1) {
                this.airportList[i].selected = true;
              }
            })
          }
          this.orgAirportList = this.airportList;
          this.setAirportList();
        }
        //else {
        //  if (responses[3].code == "401") {
        //    this._authService.signOut();
        //  }
        //}
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          if (responses[5].model.length > 0) {
            let p = new GroundStopManifestModel();
            p.personName = "Select All";
            p.personGUID = "all";
            p.selected = false;
            p.personType = this.person_role;
            switch (this.f.person_role.value) {
              case "pilot":
                this.pilotList.push(p);
                break;
              case "crew":
                this.crewList.push(p);
                break;
              case "passenger":
                this.paxList.push(p);
                break;
            }

            let i: number = 1;
            responses[5].model.forEach(x => {
              p = new GroundStopManifestModel();
              p.personGUID = x.personGUID;
              p.personName = x.personName;
              p.selected = false;
              p.personType = this.person_role;
              switch (this.person_role) {
                case "pilot":

                  this.pilotList.push(p);

                  break;
                case "crew":

                  this.crewList.push(p);
                  break;
                case "passenger":

                  this.paxList.push(p);
                  break;
              }

            });

            switch (this.person_role) {
              case "pilot":
                if (localStorage.getItem('trip.plist') != null && localStorage.getItem('trip.plist') != "") {
                  let s: string = localStorage.getItem('trip.plist');
                  let sa: string[] = s.split(',');
                  sa.forEach(x => {
                    let i: number = this.pilotList.findIndex(y => y.personGUID.toLowerCase() == x.toLowerCase());
                    if (i != -1) {
                      this.pilotList[i].selected = true;
                    }
                  });

                }

                this.setPersonList();
                break;
              case "crew":
                if (localStorage.getItem('trip.plist') != null && localStorage.getItem('trip.plist') != "") {
                  let s: string = localStorage.getItem('trip.plist');
                  let sa: string[] = s.split(',');
                  sa.forEach(x => {
                    //let i: number = this.crewList.findIndex(y => y.personGUID.toLowerCase() == x.toLowerCase());
                    let i: number = this.crewList.findIndex(y => y.personGUID.toLowerCase().indexOf(x.toLowerCase()) != -1);
                    if (i != -1) {
                      this.crewList[i].selected = true;
                    }
                  });

                }

                this.setPersonList();
                break;
              case "passenger":
                if (localStorage.getItem('trip.plist') != null && localStorage.getItem('trip.plist') != "") {
                  let s: string = localStorage.getItem('trip.plist');
                  let sa: string[] = s.split(',');
                  sa.forEach(x => {
                    //let i: number = this.paxList.findIndex(y => y.personGUID.toLowerCase() == x.toLowerCase());
                    let i: number = this.paxList.findIndex(y => y.personGUID.toLowerCase().indexOf(x.toLowerCase()) != -1);
                    if (i != -1) {
                      this.paxList[i].selected = true;
                    }
                  });

                }

                this.setPersonList();
                break;
            }

          }

        }
        //else {
        //  if (responses[2].code == "401") {
        //    this._authService.signOut();
        //  }
        //}
      }

      if (responses[6] != null) {
        if (responses[6].code == "200" && responses[6].message == "") {
          let v = new VendorModel();
          v.vendorName = "Select All";
          v.icao = "";
          v.vendorGUID = "all";
          v.selected = false;
          this.vendorList.push(v);
          responses[6].model.forEach(x => {
            v = new VendorModel();
            v.vendorName = x.vendorName;
            v.icao = x.icao;
            v.vendorGUID = x.vendorGUID;
            v.selected = false;
            this.vendorList.push(v);
          });
          if (localStorage.getItem('trip.vlist') != null && localStorage.getItem('trip.vlist') != "") {
            let c2: string = localStorage.getItem('trip.vlist');
            let c1: string[] = c2.split(',');
            c1.forEach(x => {
              let i: number = this.vendorList.findIndex(y => y.vendorGUID.toLowerCase() == x.toLowerCase());
              if (i != -1) {
                this.vendorList[i].selected = true;
              }
            })
          }
          this.orgVendorList = this.vendorList;
          this.setVendorList();
        }
        //else {
        //  if (responses[3].code == "401") {
        //    this._authService.signOut();
        //  }
        //}
      }

      if (responses[7] != null) {
        if (responses[7].code == "200" && responses[7].message == "") {
          let t = new TripModel();
          t.tripCode = "Select All";
          t.tripCodeGUID = "all";
          t.selected = false;
          this.tripCodeList.push(t);
          responses[7].model.forEach(x => {
            t = new TripModel();
            t.tripCode = x.tripCode;
            t.tripCodeGUID =x.tripCodeGUID;
            t.selected = false;
            this.tripCodeList.push(t);
          });
          if (localStorage.getItem('trip.tclist') != null && localStorage.getItem('trip.tclist') != "") {
            let c2: string = localStorage.getItem('trip.tclist');
            let c1: string[] = c2.split(',');
            c1.forEach(x => {
              let i: number = this.tripCodeList.findIndex(y => y.tripCodeGUID.toLowerCase() == x.toLowerCase());
              if (i != -1) {
                this.tripCodeList[i].selected = true;
              }
            })
          }
          this.orgTripCodeList = this.tripCodeList;
          this.setTripCodeList();
        }
        //else {
        //  if (responses[3].code == "401") {
        //    this._authService.signOut();
        //  }
        //}
      }

      if (responses[8] != null) {
        if (responses[8].code == "200" && responses[8].message == "") {
          this.websiteEnvironment = responses[8].model;

        }
        else {
          if (responses[8].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.showSpin = false;

      this.openChildPage();

    });

    // this._titleService.setTitle("Message C");


  }

  initControls() {
    this.trip_quick_search_control_internal_form = this._formBuilder.group({
      client_search: [''],
      person_role: this.person_role,
      person_search_keyword: [''],
      vendor_search: [''],
      includeClosedBilledRecords: [this.includeClosedBilledRecords],
      includeDemo: [this.includeDemo],
      startDate: [this.startDate],
      endDate: [this.endDate],
      sort_by: [this.sort_by],
      tripcode_search: [''],
      aircraft_search: [''],
      airport_search: [''],
      custom_page_size_select1: [this.pageSize]
    });

    //this.checkGSTypeArray = new FormControl(this.selectedGSTypeList); // for mat-select


  }

  get f() { return this.trip_quick_search_control_internal_form.controls; }

  includeClosedBilledRecordsOnChange(e: any) {
    //this.includeClosedBilledRecords = e.target.checked; // regular checkbox
    this.includeClosedBilledRecords = e.checked; // mat checkbox
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  includeDemoOnChange(e: any) {
    this.includeDemo = e.checked; // mat checkbox
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  updateFlightType() {
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  dateOnchange(dateType: string, e: any) {
    this.currentPageIndex = 0;
    if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidStartDate = false;
          this.f.startDate.setErrors({ isValidDate: false });
        }
        else {
          this.isValidStartDate = true;
        }
      }
      else {
        this.isValidStartDate = true;
      }
    }

    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidEndDate = false;
          this.f.endDate.setErrors({ isValidDate: false })
        }
        else {
          this.isValidEndDate = true;
        }
      }
      else {
        this.isValidEndDate = true;
      }
    }

    this.clickSubmit();
    
  }

  sortByChange(e: any) {
    this.currentPageIndex = 0;
    this.clickSubmit();
  }
  // client
  clickClientFilter() {
    this.currentPageIndex = 0;
    this.getTripClientList();
  }

  getTripClientList() {
    this.currentPageIndex = 0;
    var root = this;
    this.showClientList = true;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showGSTypeList = false;
    this.showCustomerCreditList = false;
    this.showTripCodeList = false;
    this.customerList = [];
    this.orgCustomerList = [];
    let req = new TripModel();
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      req.tripCloseOutID = 0;
    }
    else {
      req.tripCloseOutID = 1;
    }
    req.includeDemo = this.includeDemo; //this.f.includeDemo.value;
    this._groundStopService.getTripClientListByDateRange<ResponseModel<TripModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.customerList = [];
        this.orgCustomerList = [];
        if (response.model.length > 0) {
          let c = new CustomerModel();
          c.customerName= "Select All";
          c.customerGUID= "all";
          c.selected = false;
          this.customerList.push(c);
          response.model.forEach(x => {
            c = new CustomerModel();
            c.customerName = x.customerName;
            c.customerGUID = x.customerGUID;
            c.selected = false;
            if (this.selectedCustomerList.length > 0) {
              let f: CustomerModel[];
              f = this.selectedCustomerList.filter(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
              if (f.length > 0) {
                c.selected = true;
              }
            }
            this.customerList.push(c);
          });
          this.orgCustomerList = this.customerList;
          this.getCustomerListBykeyword(this.f.client_search.value);

          if (this.selectedCustomerList.length > 0 && this.selectedCustomerList.length != this.customerList.length) {
            let data: CustomerModel[] = [];
            data = this.customerList;
            this.customerList = [];

            data.forEach(x => {
              if (x.customerGUID == "all") {
                this.customerList.push(x);
              }
              else {
                if (x.selected) {
                  this.customerList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.customerGUID != "all") {
                this.customerList.push(x);
              }
            });
          }         

          //let i: number = this.customerList.findIndex(x => x.selected == true);
          //if (i != -1) {
          //  setTimeout(function () {
          //    var selectedItem = root.tripClientRefs.find(r => r.element.nativeElement.getAttribute('customerGUID').toLowerCase() === root.customerList[i].customerGUID.toLowerCase());
          //    selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
          //  }, 500);
          //}

        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
      
    })

  }

  checkClientChange(e: any, item: CustomerModel) {
    this.currentPageIndex = 0;
    if (item.customerGUID == 'all') {
      this.customerList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.customerList.forEach(x => {
        if (item.customerGUID.toLowerCase() == x.customerGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setClientList();
    this.reloadChildPage = false;
  }

  setClientList() {
    this.currentPageIndex = 0;
    let excludeallclist: CustomerModel[]
    excludeallclist = this.customerList.filter(x => x.customerGUID != 'all' && x.selected == false);
    if (excludeallclist.length == 0) {
      this.customerList.find(x => x.customerGUID == 'all').selected = true;
    }
    else {
      this.customerList.find(x => x.customerGUID == 'all').selected = false;
    }
    this.selectedCustomerList = this.customerList.filter(u => u.selected == true);
    this.selectedClientCountText = "";
    if (this.orgCustomerList.length > 0) {
      if (this.selectedCustomerList.length == this.orgCustomerList.length) {
        this.selectedClientCountText = ": All";
      }
      else {
        if (this.selectedCustomerList.length > 0) {
          let f: CustomerModel[] = this.selectedCustomerList.filter(x => x.customerGUID == "all")
          if (f.length > 0) {
            let n: number = this.selectedCustomerList.length - 1;
            this.selectedClientCountText = ": " + n.toString();
          }
          else {
            this.selectedClientCountText = ": " + this.selectedCustomerList.length.toString();
          }
        }       
      }
    }
    
  }

  clientSearchChange(e: any) {
    this.currentPageIndex = 0;
    let cname: string = e.target.value;
    if (cname != "") {
      cname = cname.toLowerCase();
    }

    this.getCustomerListBykeyword(cname);
    
  }

  getCustomerListBykeyword(cname: string) {
   
    this.customerList = this.orgCustomerList.filter(option => option.customerName.toLowerCase().includes(cname) || option.customerGUID=="all");
    if (this.selectedCustomerList.length > 0) {
      this.selectedCustomerList.forEach(x => {
        let i: number;
        i = this.customerList.findIndex(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
        if (i == -1) {
          let c = new CustomerModel();
          c.customerName = x.customerName;
          c.customerGUID = x.customerGUID;
          c.selected = true;
          this.customerList.push(c);
        }
      });
    }
    this.setClientList();
    if (this.selectedCustomerList.length > 0 && this.selectedCustomerList.length != this.customerList.length) {
      let data: CustomerModel[] = [];
      data = this.customerList;
      this.customerList = [];

      data.forEach(x => {
        if (x.customerGUID == "all") {
          this.customerList.push(x);
        }
        else {
          if (x.selected) {
            this.customerList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.customerGUID != "all") {
          this.customerList.push(x);
        }
      });
    }         

  }

  clickDoneClient() {
    this.showClientList = false;
    this.f.endDate.setValue(null);
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  clickResetClient() {
    this.f.client_search.setValue('');
    this.selectedCustomerList = [];
    this.customerList = this.orgCustomerList;
    this.customerList.forEach(x => {
      x.selected = false;
    });
    this.setClientList();
    this.showClientList = false;
    this.currentPageIndex = 0;
    this.clickSubmit();
  }
  // client end

  // aircraft
  clickAircraftFilter() {
    this.getTripAircraftList();
  }

  getTripAircraftList() {
    var root = this;
    this.showClientList = false;
    this.showAircraftList = true;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showGSTypeList = false;
    this.showCustomerCreditList = false;
    this.showTripCodeList = false;
    this.registrationList = [];
    let req = new TripModel();
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      req.tripCloseOutID = 0;
    }
    else {
      req.tripCloseOutID = 1;
    }
    req.includeDemo = this.f.includeDemo.value;
    this._groundStopService.getTripRegistrationListByDateRange<ResponseModel<TripModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.registrationList = [];
        if (response.model.length > 0) {
          let ac = new AircraftModel();
          ac.registration = "Select All";
          ac.aircraftGuid = "all";
          ac.selected = false;
          this.registrationList.push(ac);
          response.model.forEach(x => {
            ac = new AircraftModel();
            ac.aircraftGuid = x.aircraftGUID;
            ac.registration = x.registration;
            ac.selected = false;
            if (this.selectedRegistrationList.length > 0) {
              let f: AircraftModel[];
              f = this.selectedRegistrationList.filter(y => y.aircraftGuid.toLowerCase() == x.aircraftGUID.toLowerCase());
              if (f.length > 0) {
                ac.selected = true;
              }
            }
            this.registrationList.push(ac);
          });

          //this.setRegistrationList();
          this.orgRegistrationList = this.registrationList;
          this.getAircraftListBykeyword(this.f.aircraft_search.value);

          if (this.selectedRegistrationList.length > 0 && this.selectedRegistrationList.length != this.registrationList.length) {
            let data: AircraftModel[] = [];
            data = this.registrationList;
            this.registrationList = [];

            data.forEach(x => {
              if (x.aircraftGuid == "all") {
                this.registrationList.push(x);
              }
              else {
                if (x.selected) {
                  this.registrationList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.aircraftGuid != "all") {
                this.registrationList.push(x);
              }
            });
          }         


          //let i: number = this.registrationList.findIndex(x => x.selected == true);
          //if (i != -1) {
          //  setTimeout(function () {
          //    var selectedItem = root.tripAircraftRefs.find(r => r.element.nativeElement.getAttribute('aircraftGUID').toLowerCase() === root.registrationList[i].aircraftGuid.toLowerCase());
          //    selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
          //  }, 500);
          //}

        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
      //else {
      //  if (response.code == "401") {
      //    this._authService.signOut();
      //  }
      //  else {
      //    //this.errMsg = "Failed to get trips at this time. Please try later.";
      //  }
      //}

    })

  }

  checkRegistrationChange(e: any, item: AircraftModel) {
    if (item.aircraftGuid == 'all') {
      this.registrationList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.registrationList.forEach(x => {
        if (item.aircraftGuid.toLowerCase() == x.aircraftGuid.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setRegistrationList();
    this.reloadChildPage = false;
  }

  setRegistrationList() {
    let excludeallaclist: AircraftModel[]
    excludeallaclist = this.registrationList.filter(x => x.aircraftGuid != 'all' && x.selected == false);
    if (excludeallaclist.length == 0) {
      this.registrationList.find(x => x.aircraftGuid == 'all').selected = true;
    }
    else {
      this.registrationList.find(x => x.aircraftGuid == 'all').selected = false;
    }
    this.selectedRegistrationList = this.registrationList.filter(u => u.selected == true);
    this.selectedRegistrationCountText = "";
    if (this.orgRegistrationList.length > 0) {
      if (this.selectedRegistrationList.length == this.orgRegistrationList.length) {
        this.selectedRegistrationCountText = ": All";
      }
      else {
        if (this.selectedRegistrationList.length > 0) {
          let f: AircraftModel[] = this.selectedRegistrationList.filter(x => x.aircraftGuid == "all")
          if (f.length > 0) {
            let n: number = this.selectedRegistrationList.length - 1;
            this.selectedRegistrationCountText = ": " + n.toString();
          }
          else {
            this.selectedRegistrationCountText = ": " + this.selectedRegistrationList.length.toString();
          }
        }
      }
    }
    //if (this.registrationList.length > 0) {
    //  if (this.selectedRegistrationList.length == this.registrationList.length) {
    //    this.selectedRegistrationCountText = ": All";
    //  }
    //  else {
    //    if (this.selectedRegistrationList.length > 0)
    //      this.selectedRegistrationCountText = ": " + this.selectedRegistrationList.length.toString();
    //  }
    //}

  }

  aircraftSearchChange(e: any) {
    let cname: string = e.target.value;
    if (cname != "") {
      cname = cname.toLowerCase();
    }

    this.getAircraftListBykeyword(cname);

  }

  getAircraftListBykeyword(cname: string) {

    this.registrationList = this.orgRegistrationList.filter(option => option.registration.toLowerCase().includes(cname) || option.aircraftGuid == "all");
    if (this.selectedRegistrationList.length > 0) {
      this.selectedRegistrationList.forEach(x => {
        let i: number;
        i = this.registrationList.findIndex(y => y.aircraftGuid.toLowerCase() == x.aircraftGuid.toLowerCase());
        if (i == -1) {
          let c = new AircraftModel();
          c.registration = x.registration;
          c.aircraftGuid = x.aircraftGuid;
          c.selected = true;
          this.registrationList.push(c);
        }
      });
    }
    this.setRegistrationList();
    if (this.selectedRegistrationList.length > 0 && this.selectedRegistrationList.length != this.registrationList.length) {
      let data: AircraftModel[] = [];
      data = this.registrationList;
      this.registrationList = [];

      data.forEach(x => {
        if (x.aircraftGuid == "all") {
          this.registrationList.push(x);
        }
        else {
          if (x.selected) {
            this.registrationList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.aircraftGuid != "all") {
          this.registrationList.push(x);
        }
      });
    }

  }

  clickDoneAircraft() {
    this.showAircraftList = false;
    this.f.endDate.setValue(null);
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  clickResetAircraft() {
    this.registrationList.forEach(x => {
      x.selected = false;
    });
    this.setRegistrationList();
    this.showAircraftList = false;
    this.currentPageIndex = 0;
    this.clickSubmit();
  }
  // aircraft end

  // airport
  clickAirportFilter() {
    this.getTripAirportList();
  }

  getTripAirportList() {
    var root = this;
    this.showClientList = false;
    this.showAircraftList = false;
    this.showAirportList = true;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showGSTypeList = false;
    this.showCustomerCreditList = false;
    this.showTripCodeList = false;
    this.airportList = [];
    let req = new TripModel();
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      req.tripCloseOutID = 0;
    }
    else {
      req.tripCloseOutID = 1;
    }
    req.includeDemo = this.f.includeDemo.value;
    this._groundStopService.getTripAirportListByDateRange<ResponseModel<GroundStopModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.airportList = [];
        if (response.model.length > 0) {
          let ap = new AirportModel();
          ap.icao = "Select All";
          ap.iata = "";
          ap.airportID = 0;
          ap.selected = false;
          this.airportList.push(ap);
          
          response.model.forEach((x, index) => {
            ap = new AirportModel();           
            ap.icao = x.icao;
            ap.iata = x.iata;
            ap.airportID = index+1;
            ap.selected = false;
            if (this.selectedAirportList.length > 0) {
              let f: AirportModel[];
              f = this.selectedAirportList.filter(y => y.icao.toLowerCase() == x.icao.toLowerCase());
              if (f.length > 0) {
                ap.selected = true;
              }
            }
            this.airportList.push(ap);
            
          });
          //this.setAirportList();
          this.orgAirportList = this.airportList;
          this.getAirportListBykeyword(this.f.airport_search.value);
          if (this.selectedAirportList.length > 0 && this.selectedAirportList.length != this.airportList.length) {
            let data: AirportModel[] = [];
            data = this.airportList;
            this.airportList = [];

            data.forEach(x => {
              if (x.airportID == 0) {
                this.airportList.push(x);
              }
              else {
                if (x.selected) {
                  this.airportList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.airportID != 0) {
                this.airportList.push(x);
              }
            });
          }         

          //let i: number = this.airportList.findIndex(x => x.selected == true);
          //if (i != -1) {
          //  setTimeout(function () {
          //    var selectedItem = root.tripAirportRefs.find(r => r.element.nativeElement.getAttribute('icao').toLowerCase() === root.airportList[i].icao.toLowerCase());
          //    selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
          //  }, 500);
          //}
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
      //else {
      //  if (response.code == "401") {
      //    this._authService.signOut();
      //  }
      //  else {
      //    //this.errMsg = "Failed to get trips at this time. Please try later.";
      //  }
      //}

    })

  }

  checkAirportChange(e: any, item: AirportModel) {
    if (item.airportID == 0) {
      this.airportList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.airportList.forEach(x => {
        if (item.airportID == x.airportID) {
          x.selected = e.checked;
        }
      });
    }
    this.setAirportList();
    this.reloadChildPage = false;
  }

  setAirportList() {
    let excludeallaplist: AirportModel[]
    excludeallaplist = this.airportList.filter(x => x.airportID != 0 && x.selected == false);
    if (excludeallaplist.length == 0) {
      this.airportList.find(x => x.airportID == 0).selected = true;
    }
    else {
      this.airportList.find(x => x.airportID == 0).selected = false;
    }
    this.selectedAirportList = this.airportList.filter(u => u.selected == true);
    this.selectedAirportCountText = "";
    if (this.orgAirportList.length > 0) {
      if (this.selectedAirportList.length == this.orgAirportList.length) {
        this.selectedAirportCountText = ": All";
      }
      else {
        if (this.selectedAirportList.length > 0) {
          let f: AirportModel[] = this.selectedAirportList.filter(x => x.airportID == 0)
          if (f.length > 0) {
            let n: number = this.selectedAirportList.length - 1;
            this.selectedAirportCountText = ": " + n.toString();
          }
          else {
            this.selectedAirportCountText = ": " + this.selectedAirportList.length.toString();
          }
        }
      }
    }
    //if (this.airportList.length > 0) {
    //  if (this.selectedAirportList.length == this.airportList.length) {
    //    this.selectedAirportCountText = ": All";
    //  }
    //  else {
    //    if (this.selectedAirportList.length > 0)
    //      this.selectedAirportCountText = ": " + this.selectedAirportList.length.toString();
    //  }
    //}
  }

  airportSearchChange(e: any) {
    let cname: string = e.target.value;
    if (cname != "") {
      cname = cname.toLowerCase();
    }

    this.getAirportListBykeyword(cname);

  }

  getAirportListBykeyword(cname: string) {

    this.airportList = this.orgAirportList.filter(option => option.icao.toLowerCase().includes(cname) || option.airportID == 0);
    if (this.selectedAirportList.length > 0) {
      this.selectedAirportList.forEach(x => {
        let i: number;
        i = this.airportList.findIndex(y => y.icao.toLowerCase() == x.icao.toLowerCase());
        if (i == -1) {
          let c = new AirportModel();
          c.icao = x.icao;
          c.airportID = x.airportID;
          c.selected = true;
          this.airportList.push(c);
        }
      });
    }
    this.setAirportList();
    if (this.selectedAirportList.length > 0 && this.selectedAirportList.length != this.airportList.length) {
      let data: AirportModel[] = [];
      data = this.airportList;
      this.airportList = [];

      data.forEach(x => {
        if (x.airportID ==0) {
          this.airportList.push(x);
        }
        else {
          if (x.selected) {
            this.airportList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.airportID != 0) {
          this.airportList.push(x);
        }
      });
    }

  }

  clickDoneAirport() {
    this.showAirportList = false;
    this.f.endDate.setValue(null);
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  clickResetAirport() {
    this.airportList.forEach(x => {
      x.selected = false;
    });
    this.setAirportList();
    this.showAirportList = false;
    this.currentPageIndex = 0;
    this.clickSubmit();
  }
  // airport end

  // manifest
  clickManifestFilter() {
    this.getTripPersonList();
  }

  getTripPersonList() {
    var root = this;
    this.showClientList = false;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = true;
    this.showVendorList = false;
    this.showGSTypeList = false;
    this.showCustomerCreditList = false;
    this.showTripCodeList = false;
    switch (this.f.person_role.value) {
      case "pilot":
        this.pilotList = [];
        break;
      case "crew":
        this.crewList = [];
        break;
      case "passenger":
        this.paxList = [];
        break;
    }

    let req = new TripModel();
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      req.tripCloseOutID = 0;
    }
    else {
      req.tripCloseOutID = 1;
    }
    req.includeDemo = this.f.includeDemo.value;
    req.personTextSearch = this.f.person_search_keyword.value;
    req.personRole = this.person_role;
    this._groundStopService.getTripPersonListByDateRange<ResponseModel<GroundStopManifestModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        switch (this.f.person_role.value) {
          case "pilot":
            this.pilotList = [];
            break;
          case "crew":
            this.crewList = [];
            break;
          case "passenger":
            this.paxList = [];
            break;
        }
        if (response.model.length > 0) {
          let p = new GroundStopManifestModel();
          p.personName = "Select All";
          p.personGUID = "all";
          p.selected = false;
          p.personType = this.person_role;
          switch (this.f.person_role.value) {
            case "pilot":
              this.pilotList.push(p);
              break;
            case "crew":
              this.crewList.push(p);
              break;
            case "passenger":
              this.paxList.push(p);
              break;
          }

          response.model.forEach(x => {
            p = new GroundStopManifestModel();
            p.personGUID = x.personGUID;
            p.personName = x.personName;
            p.selected = false;
            p.personType = this.person_role;
            switch (this.person_role) {
              case "pilot":
                if (this.selectedPilotList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedPilotList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.pilotList.push(p);

                break;
              case "crew":
                if (this.selectedCrewList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedCrewList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.crewList.push(p);
                break;
              case "passenger":
                if (this.selectedPaxList.length > 0) {
                  let f: GroundStopManifestModel[];
                  f = this.selectedPaxList.filter(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (f.length > 0) {
                    p.selected = true;
                  }
                }
                this.paxList.push(p);
                break;
            }

          });
          let i: number;
          switch (this.person_role) {
            case "pilot":
              if (this.selectedPilotList.length > 0) {
                this.selectedPilotList.forEach(x => {
                  let n: number;
                  n = this.pilotList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (n == -1) {
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.pilotList.push(p);
                  }
                });
              }
              this.setPersonList();
              if (this.selectedPilotList.length > 0 && this.selectedPilotList.length != this.pilotList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.pilotList;
                this.pilotList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.pilotList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.pilotList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.pilotList.push(x);
                  }
                });
              }
              //i = this.pilotList.findIndex(x => x.selected == true);
              //if (i != -1) {
              //  setTimeout(function () {
              //    var selectedItem = root.tripPersonRefs.find(r => r.element.nativeElement.getAttribute('personGUID').toLowerCase() === root.pilotList[i].personGUID.toLowerCase());
              //    selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
              //  }, 500);
              //}
              break;
            case "crew":
              if (this.selectedCrewList.length > 0) {
                this.selectedCrewList.forEach(x => {
                  let n: number;
                  n = this.crewList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (n == -1) {
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.crewList.push(p);
                  }
                });
              }
              this.setPersonList();
              if (this.selectedCrewList.length > 0 && this.selectedCrewList.length != this.crewList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.crewList;
                this.crewList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.crewList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.crewList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.crewList.push(x);
                  }
                });
              }
              //i = this.crewList.findIndex(x => x.selected == true);
              //if (i != -1) {
              //  setTimeout(function () {
              //    var selectedItem = root.tripPersonRefs.find(r => r.element.nativeElement.getAttribute('personGUID').toLowerCase() === root.crewList[i].personGUID.toLowerCase());
              //    selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
              //  }, 500);
              //}
              break;
            case "passenger":
              if (this.selectedPaxList.length > 0) {
                this.selectedPaxList.forEach(x => {
                  let n: number;
                  n = this.paxList.findIndex(y => y.personGUID.toLowerCase() == x.personGUID.toLowerCase());
                  if (n == -1) {
                    p = new GroundStopManifestModel();
                    p.personName = x.personName;
                    p.personGUID = x.personGUID;
                    p.selected = true;
                    p.personType = this.person_role;
                    this.paxList.push(p);
                  }
                });
              }
              this.setPersonList();
              if (this.selectedPaxList.length > 0 && this.selectedPaxList.length != this.paxList.length) {
                let data: GroundStopManifestModel[] = [];
                data = this.paxList;
                this.paxList = [];

                data.forEach(x => {
                  if (x.personGUID == "all") {
                    this.paxList.push(x);
                  }
                  else {
                    if (x.selected) {
                      this.paxList.push(x);
                    }
                  }
                });
                data.forEach(x => {
                  if (!x.selected && x.personGUID != "all") {
                    this.paxList.push(x);
                  }
                });
              }
              //i = this.paxList.findIndex(x => x.selected == true);
              //if (i != -1) {
              //  setTimeout(function () {
              //    var selectedItem = root.tripPersonRefs.find(r => r.element.nativeElement.getAttribute('personGUID').toLowerCase() === root.paxList[i].personGUID.toLowerCase());
              //    selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
              //  }, 500);
              //}
              break;
          }


          

        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
      //else {
      //  if (response.code == "401") {
      //    this._authService.signOut();
      //  }
      //  else {
      //    //this.errMsg = "Failed to get trips at this time. Please try later.";
      //  }
      //}

    })

  }

  personSearchKeywordChange(e: any) {
    this.getTripPersonList();
  }

  personRoleChange(e: any) {
    this.person_role = e.target.value;
    this.f.person_search_keyword.setValue('');
    this.selectedPilotList = [];
    this.selectedCrewList = [];
    this.selectedPaxList = [];

    this.selectedPersonCountText = "";
    this.getTripPersonList();
  }

  checkPersonChange(e: any, item: GroundStopManifestModel) {
    switch (this.person_role) {
      case "pilot":
        if (item.personGUID == 'all') {
          this.pilotList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.pilotList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();

        break;
      case "crew":
        if (item.personGUID == 'all') {
          this.crewList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.crewList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();
        break;
      case "passenger":
        if (item.personGUID == 'all') {
          this.paxList.forEach(x => {
            x.selected = e.checked;
          });
        }
        else {
          this.paxList.forEach(x => {
            if (item.personGUID.toLowerCase() == x.personGUID.toLowerCase()) {
              x.selected = e.checked;
            }
          });
        }
        this.setPersonList();
        break;
    }
    this.reloadChildPage = false;

  }

  setPersonList() {
    this.selectedPersonCountText = "";
    let n: number = 0;
    switch (this.person_role) {
      case "pilot":
        let excludeallplist: GroundStopManifestModel[]
        excludeallplist = this.pilotList.filter(x => x.personGUID != 'all' && x.selected == false);
        if (excludeallplist.length == 0) {
          this.pilotList.find(x => x.personGUID == 'all').selected = true;
        }
        else {
          this.pilotList.find(x => x.personGUID == 'all').selected = false;
        }
        this.selectedPilotList = this.pilotList.filter(u => u.selected == true);
        if (this.pilotList.length > 0) {
          if (this.selectedPilotList.length == this.pilotList.length && this.f.person_search_keyword.value=='') {
            this.selectedPersonCountText = ": Pilot All";
          }
          else {
            if (this.selectedPilotList.length > 0) {
              if (this.pilotList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedPilotList.length - 1;
              }
              else {
                n = this.selectedPilotList.length;
              }
              this.selectedPersonCountText = ": Pilot " + n.toString();
            }
          }
        }
        break;
      case "crew":
        let excludeallclist: GroundStopManifestModel[]
        excludeallclist = this.crewList.filter(x => x.personGUID != 'all' && x.selected == false);
        if (excludeallclist.length == 0) {
          this.crewList.find(x => x.personGUID == 'all').selected = true;
        }
        else {
          this.crewList.find(x => x.personGUID == 'all').selected = false;
        }
        this.selectedCrewList = this.crewList.filter(u => u.selected == true);
        if (this.crewList.length > 0) {
          if (this.selectedCrewList.length == this.crewList.length && this.f.person_search_keyword.value == '') {
            this.selectedPersonCountText = ": Crew All";
          }
          else {
            //if (this.selectedCrewList.length > 0)
            //  this.selectedPersonCountText = ": Crew " + this.selectedCrewList.length.toString();
            if (this.selectedCrewList.length > 0) {
              if (this.crewList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedCrewList.length - 1;
              }
              else {
                n = this.selectedCrewList.length;
              }
              this.selectedPersonCountText = ": Crew " + n.toString();
            }

          }
        }
        break;
      case "passenger":
        let excludeallpxlist: GroundStopManifestModel[]
        excludeallpxlist = this.paxList.filter(x => x.personGUID != 'all' && x.selected == false);
        if (excludeallpxlist.length == 0) {
          this.paxList.find(x => x.personGUID == 'all').selected = true;
        }
        else {
          this.paxList.find(x => x.personGUID == 'all').selected = false;
        }
        this.selectedPaxList = this.paxList.filter(u => u.selected == true);
        if (this.paxList.length > 0) {
          if (this.selectedPaxList.length == this.paxList.length && this.f.person_search_keyword.value == '') {
            this.selectedPersonCountText = ": Passenger All";
          }
          else {
            //if (this.selectedPaxList.length > 0)
            //  this.selectedPersonCountText = ": Passenger " + this.selectedPaxList.length.toString();
            if (this.selectedPaxList.length > 0) {
              if (this.paxList.find(x => x.personGUID == 'all').selected == true) {
                n = this.selectedPaxList.length - 1;
              }
              else {
                n = this.selectedPaxList.length;
              }
              this.selectedPersonCountText = ": Passenger " + n.toString();
            }
          }
        }
        break;
    }

  }

  clickDonePerson() {
    this.showPersonList = false;
    this.f.endDate.setValue(null);
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  clickResetPerson() {
    this.f.person_search_keyword.setValue('');  
    switch (this.person_role) {
      case "pilot":
        this.selectedPilotList = [];
        //this.pilotList.forEach(x => {
        //  x.selected = false;
        //});
        //this.setPersonList();
        break;
      case "crew":
        this.selectedCrewList = [];
        //this.crewList.forEach(x => {
        //  x.selected = false;
        //});
        //this.setPersonList();
        break;
      case "passenger":
        this.selectedPaxList = [];
        //this.paxList.forEach(x => {
        //  x.selected = false;
        //});
        //this.setPersonList();
        break;
    }
    this.getTripPersonList();
    this.showPersonList = false;
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  // manifest end
  // vendor
  clickVendorFilter() {
    this.getTripVendorList();
  }

  getTripVendorList() {
    var root = this;
    this.showClientList = false;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = true;
    this.showGSTypeList = false;
    this.showCustomerCreditList = false;
    this.showTripCodeList = false;
    this.vendorList = [];
    this.orgVendorList = [];
    let req = new TripModel();
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      req.tripCloseOutID = 0;
    }
    else {
      req.tripCloseOutID = 1;
    }
    req.includeDemo = this.f.includeDemo.value;
    this._groundStopService.getTripVendorListByDateRange<ResponseModel<VendorModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.vendorList = [];
        this.orgVendorList = [];
        if (response.model.length > 0) {
          let v = new VendorModel();
          v.vendorName = "Select All";
          v.vendorGUID = "all";
          v.icao = "";
          v.selected = false;
          this.vendorList.push(v);
          response.model.forEach(x => {
            v = new VendorModel();
            v.vendorName = x.vendorName;
            v.vendorGUID = x.vendorGUID;
            v.icao = x.icao;
            v.selected = false;
            if (this.selectedVendorList.length > 0) {
              let f: VendorModel[];
              f = this.selectedVendorList.filter(y => y.vendorGUID.toLowerCase() == x.vendorGUID.toLowerCase());
              if (f.length > 0) {
                v.selected = true;
              }
            }
            this.vendorList.push(v);
          });
          this.orgVendorList = this.vendorList;
          this.getVendorListBykeyword(this.f.vendor_search.value);

          if (this.selectedVendorList.length > 0 && this.selectedVendorList.length != this.vendorList.length) {
            let data: VendorModel[] = [];
            data = this.vendorList;
            this.vendorList = [];

            data.forEach(x => {
              if (x.vendorGUID == "all") {
                this.vendorList.push(x);
              }
              else {
                if (x.selected) {
                  this.vendorList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.vendorGUID != "all") {
                this.vendorList.push(x);
              }
            });
          }

          //let i: number = this.vendorList.findIndex(x => x.selected == true);
          //if (i != -1) {
          //  setTimeout(function () {
          //    var selectedItem = root.tripVendorRefs.find(r => r.element.nativeElement.getAttribute('vendorGUID').toLowerCase() === root.vendorList[i].vendorGUID.toLowerCase());
          //    selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
          //  }, 500);
          //}
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })

  }

  checkVendorChange(e: any, item: VendorModel) {
    if (item.vendorGUID == 'all') {
      this.vendorList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.vendorList.forEach(x => {
        if (item.vendorGUID.toLowerCase() == x.vendorGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setVendorList();
    this.reloadChildPage = false;
  }

  setVendorList() {
    let excludeallvlist: VendorModel[]
    excludeallvlist = this.vendorList.filter(x => x.vendorGUID != 'all' && x.selected == false);
    if (excludeallvlist.length == 0) {
      this.vendorList.find(x => x.vendorGUID == 'all').selected = true;
    }
    else {
      this.vendorList.find(x => x.vendorGUID == 'all').selected = false;
    }
    this.selectedVendorList = this.vendorList.filter(u => u.selected == true);
    this.selectedVendorCountText = "";
    if (this.orgVendorList.length > 0) {
      if (this.selectedVendorList.length == this.orgVendorList.length) {
        this.selectedVendorCountText = ": All";
      }
      else {
        if (this.selectedVendorList.length > 0) {
          let f: VendorModel[] = this.selectedVendorList.filter(x => x.vendorGUID == "all")
          if (f.length > 0) {
            let n: number = this.selectedVendorList.length - 1;
            this.selectedVendorCountText = ": " + n.toString();
          }
          else {
            this.selectedVendorCountText = ": " + this.selectedVendorList.length.toString();
          }
        }
      }
    }
  }

  vendorSearchChange(e: any) {
    let vname: string = e.target.value;
    if (vname != "") {
      vname = vname.toLowerCase();

    }

    this.getVendorListBykeyword(vname);

  }

  getVendorListBykeyword(vname: string) {
    if (vname!="")
      vname = vname.trim();
    this.vendorList = this.orgVendorList.filter(option => option.vendorName.toLowerCase().includes(vname) || option.vendorGUID == "all");
    if (this.selectedVendorList.length > 0) {
      this.selectedVendorList.forEach(x => {
        let i: number;
        i = this.vendorList.findIndex(y => y.vendorGUID.toLowerCase() == x.vendorGUID.toLowerCase());
        if (i == -1) {
          let v = new VendorModel();
          v.vendorName = x.vendorName;
          v.vendorGUID = x.vendorGUID;
          v.icao = x.icao;
          v.selected = true;
          this.vendorList.push(v);
        }
      });
    }
    this.setVendorList();
    if (this.selectedVendorList.length > 0 && this.selectedVendorList.length != this.vendorList.length) {
      let data: VendorModel[] = [];
      data = this.vendorList;
      this.vendorList = [];

      data.forEach(x => {
        if (x.vendorGUID == "all") {
          this.vendorList.push(x);
        }
        else {
          if (x.selected) {
            this.vendorList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.vendorGUID != "all") {
          this.vendorList.push(x);
        }
      });
    }
  }

  clickDoneVendor() {
    this.showVendorList = false;
    this.f.endDate.setValue(null);
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  clickResetVendor() {
    this.f.vendor_search.setValue('');
    this.selectedVendorList = [];
    this.vendorList = this.orgVendorList;
    this.vendorList.forEach(x => {
      x.selected = false;
    });
    this.setVendorList();
    this.showVendorList = false;
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  // vendor end

  // ground stop
  clickGSTypeFilter() {
    this.showClientList = false;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showGSTypeList = true;
    this.showCustomerCreditList = false;
    this.showTripCodeList = false;
  }

  onGSTypeChange(e: any, item: GroundStopTypeModel) {
    if (item.groundStopTypeGUID == 'all') {
      this.gsTypeList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.gsTypeList.forEach(x => {
        if (item.groundStopTypeGUID.toLowerCase() == x.groundStopTypeGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setGSTypeList();
    this.reloadChildPage = false;

  }

  setGSTypeList() {
    let excludeallgstlist: GroundStopTypeModel[]
    excludeallgstlist = this.gsTypeList.filter(x => x.groundStopTypeGUID != 'all' && x.selected == false);
    if (excludeallgstlist.length == 0) {
      this.gsTypeList.find(x => x.groundStopTypeGUID == 'all').selected = true;
    }
    else {
      this.gsTypeList.find(x => x.groundStopTypeGUID == 'all').selected = false;
    }
    this.selectedGSTypeList = this.gsTypeList.filter(u => u.selected == true);
    this.selectedGSTypeCountText = "";
    if (this.gsTypeList.length > 0) {
      if (this.selectedGSTypeList.length == this.gsTypeList.length) {
        this.selectedGSTypeCountText = ": All";
      }
      else {
        if (this.selectedGSTypeList.length > 0)
          this.selectedGSTypeCountText = ": " + this.selectedGSTypeList.length.toString();
      }
    }

  }

  clickDoneGSType() {
    this.showGSTypeList = false;
    this.f.endDate.setValue(null);
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  clickResetGSType() {
    this.gsTypeList.forEach(x => {
      x.selected = false;
    });
    this.gsTypeList.find(x => x.groundStopTypeGUID.toLowerCase() == "868a62b1-9cd6-499b-abfa-46542ccb7424").selected = true;
    this.setGSTypeList();
    this.showGSTypeList = false;
    this.currentPageIndex = 0;
    this.clickSubmit();
  }
  // ground stop type end

  // credit status
  clickCreditStatusFilter() {
    this.showClientList = false;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showGSTypeList = false;
    this.showCustomerCreditList = true;
    this.showTripCodeList = false;
  }

  onCreditStatusChange(e: any, item: CustomerCreditStatusModel) {
    if (item.customerCreditStatusID == 0) {
      this.customerCreditList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.customerCreditList.forEach(x => {
        if (item.customerCreditStatusID == x.customerCreditStatusID) {
          x.selected = e.checked;
        }
      });
    }
    this.setCreditStatusList();

    this.reloadChildPage = false;

  }

  setCreditStatusList() {
    let excludealllist: CustomerCreditStatusModel[]
    excludealllist = this.customerCreditList.filter(x => x.customerCreditStatusID != 0 && x.selected == false);
    if (excludealllist.length == 0) {
      this.customerCreditList.find(x => x.customerCreditStatusID == 0).selected = true;
    }
    else {
      this.customerCreditList.find(x => x.customerCreditStatusID == 0).selected = false;
    }
    this.selectedCustomerCreditList = this.customerCreditList.filter(u => u.selected == true);
    this.selectedCreditStatusCountText = "";
    if (this.customerCreditList.length > 0) {
      if (this.selectedCustomerCreditList.length == this.customerCreditList.length) {
        this.selectedCreditStatusCountText = ": All";
      }
      else {
        if (this.selectedCustomerCreditList.length > 0)
          this.selectedCreditStatusCountText = ": " + this.selectedCustomerCreditList.length.toString();
      }
    }

  }

  clickDoneCreditStatus() {
    this.showCustomerCreditList = false;
    this.f.endDate.setValue(null);
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  clickResetCreditStatus() {
    this.customerCreditList.forEach(x => {
      x.selected = false;
    });
    this.setCreditStatusList();
    this.showCustomerCreditList = false;
    this.currentPageIndex = 0;
    this.clickSubmit();
  }
  // creidt status end

  // trip code
  clickTripCodeFilter() {
    this.getTripCodeList();
  }

  getTripCodeList() {
    var root = this;
    this.showClientList = false;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showPersonList = false;
    this.showVendorList = false;
    this.showGSTypeList = false;
    this.showCustomerCreditList = false;
    this.showTripCodeList = true;
    this.tripCodeList = [];
    let req = new TripModel();
    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      req.tripCloseOutID = 0;
    }
    else {
      req.tripCloseOutID = 1;
    }
    req.includeDemo = this.f.includeDemo.value;
    req.flightType = this.flightType;
    this._groundStopService.getTripCodeListByDateRange<ResponseModel<TripModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.tripCodeList = [];
        if (response.model.length > 0) {
          let t = new TripModel();
          t.tripCode = "Select All";
          t.tripCodeGUID = "all";
          t.selected = false;
          this.tripCodeList.push(t);
          response.model.forEach(x => {
            t = new TripModel();
            t.tripCode =x.tripCode;
            t.tripCodeGUID = x.tripCodeGUID;
            t.selected = false;
            if (this.selectedTripCodeList.length > 0) {
              let f: TripModel[];
              f = this.selectedTripCodeList.filter(y => y.tripCodeGUID.toLowerCase() == x.tripCodeGUID.toLowerCase());
              if (f.length > 0) {
                t.selected = true;
              }
            }
            this.tripCodeList.push(t);
          });
          this.orgTripCodeList = this.tripCodeList;
          this.getTripCodeListBykeyword(this.f.tripcode_search.value);
          //this.setTripCodeList();

          if (this.selectedTripCodeList.length > 0 && this.selectedTripCodeList.length != this.tripCodeList.length) {
            let data: TripModel[] = [];
            data = this.tripCodeList;
            this.tripCodeList = [];

            data.forEach(x => {
              if (x.tripCodeGUID == "all") {
                this.tripCodeList.push(x);
              }
              else {
                if (x.selected) {
                  this.tripCodeList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.tripCodeGUID != "all") {
                this.tripCodeList.push(x);
              }
            });
          }

          //let i: number = this.tripCodeList.findIndex(x => x.selected == true);
          //if (i != -1) {
          //  setTimeout(function () {
          //    var selectedItem = root.tripCodeRefs.find(r => r.element.nativeElement.getAttribute('tripCodeGUID').toLowerCase() === root.tripCodeList[i].tripCodeGUID.toLowerCase());
          //    selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
          //  }, 500);
          //}

        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }
      //else {
      //  if (response.code == "401") {
      //    this._authService.signOut();
      //  }
      //  else {
      //    //this.errMsg = "Failed to get trips at this time. Please try later.";
      //  }
      //}

    })

  }

  checkTripCodeChange(e: any, item: TripModel) {
    if (item.tripCodeGUID == 'all') {
      this.tripCodeList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.tripCodeList.forEach(x => {
        if (item.tripCodeGUID.toLowerCase() == x.tripCodeGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setTripCodeList();
    this.reloadChildPage = false;
  }

  setTripCodeList() {
    let excludealltclist: TripModel[]
    excludealltclist = this.tripCodeList.filter(x => x.tripCodeGUID != 'all' && x.selected == false);
    if (excludealltclist.length == 0) {
      this.tripCodeList.find(x => x.tripCodeGUID == 'all').selected = true;
    }
    else {
      this.tripCodeList.find(x => x.tripCodeGUID == 'all').selected = false;
    }
    this.selectedTripCodeList = this.tripCodeList.filter(u => u.selected == true);
    this.selectedTripCodeCountText = "";

    if (this.orgTripCodeList.length > 0) {
      if (this.selectedTripCodeList.length == this.orgTripCodeList.length) {
        this.selectedTripCodeCountText = ": All";
      }
      else {
        if (this.selectedTripCodeList.length > 0) {
          let f: TripModel[] = this.selectedTripCodeList.filter(x => x.tripCodeGUID == "all")
          if (f.length > 0) {
            let n: number = this.selectedTripCodeList.length - 1;
            this.selectedTripCodeCountText = ": " + n.toString();
          }
          else {
            this.selectedTripCodeCountText = ": " + this.selectedTripCodeList.length.toString();
          }
        }
      }
    }

    //if (this.tripCodeList.length > 0) {
    //  if (this.selectedTripCodeList.length == this.tripCodeList.length) {
    //    this.selectedTripCodeCountText = ": All";
    //  }
    //  else {
    //    if (this.selectedTripCodeList.length > 0)
    //      this.selectedTripCodeCountText = ": " + this.selectedTripCodeList.length.toString();
    //  }
    //}

  }

  tripCodeSearchChange(e: any) {
    let tname: string = e.target.value;
    if (tname != "") {
      tname = tname.toLowerCase();
    }

    this.getTripCodeListBykeyword(tname);

  }

  getTripCodeListBykeyword(tname: string) {

    this.tripCodeList = this.orgTripCodeList.filter(option => option.tripCode.toLowerCase().includes(tname) || option.tripCodeGUID == "all");
    if (this.selectedTripCodeList.length > 0) {
      this.selectedTripCodeList.forEach(x => {
        let i: number;
        i = this.tripCodeList.findIndex(y => y.tripCodeGUID.toLowerCase() == x.tripCodeGUID.toLowerCase());
        if (i == -1) {
          let c = new TripModel();
          c.tripCode = x.tripCode;
          c.tripCodeGUID = x.tripCodeGUID;
          c.selected = true;
          this.tripCodeList.push(c);
        }
      });
    }
    this.setTripCodeList();
    if (this.selectedTripCodeList.length > 0 && this.selectedTripCodeList.length != this.tripCodeList.length) {
      let data: TripModel[] = [];
      data = this.tripCodeList;
      this.tripCodeList = [];

      data.forEach(x => {
        if (x.tripCodeGUID == "all") {
          this.tripCodeList.push(x);
        }
        else {
          if (x.selected) {
            this.tripCodeList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.tripCodeGUID != "all") {
          this.tripCodeList.push(x);
        }
      });
    }

  }

  clickDoneTripCode() {
    this.showTripCodeList = false;
    this.f.endDate.setValue(null);
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  clickResetTripCode() {
    this.tripCodeList.forEach(x => {
      x.selected = false;
    });
    this.setTripCodeList();
    this.showTripCodeList = false;
    this.currentPageIndex = 0;
    this.clickSubmit();    
  }
  // trip code end
  

  clickSubmit() {
    this.submitted = true;

    if (this.trip_quick_search_control_internal_form.invalid || this.errMsg != "") {
      return;
    }

    if (this.showClientList) {
      this.getTripClientList();
    }
    if (this.showAircraftList) {
      this.getTripAircraftList();
    }
    if (this.showAirportList) {
      this.getTripAirportList();
    }
    if (this.showPersonList) {
      this.getTripPersonList();
    }
    if (this.showVendorList) {
      this.getTripVendorList();
    }
    if (this.showTripCodeList) {
      this.getTripCodeList();
    }
    this.openChildPage();

  }

  openChildPage() {
    this.errMsg = "";
    this.msg = "";

    this._authService.updateAccessTime();


    this.request = new TripModel();
    this.reloadChildPage = true;
    
    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      this.request.tripCloseOutID = 0;
    }
    else {
      this.request.tripCloseOutID = 1;
    }
    
    this.request.includeDemo =this.f.includeDemo.value;
    this.request.startDate = this.f.startDate.value;
    this.request.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      this.request.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    this.request.endDate = this.f.endDate.value;
    this.request.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      this.request.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    if (this.selectedCustomerList.length == this.orgCustomerList.length) {
      this.request.selectedCustomerGUIDList = "";
    }
    else {
      this.selectedCustomerList = this.selectedCustomerList.filter(x => x.customerGUID != 'all');
      this.request.selectedCustomerGUIDList = Array.prototype.map.call(this.selectedCustomerList, s => s.customerGUID).toString();
    }
    if (this.selectedRegistrationList.length == this.orgRegistrationList.length) {
      this.request.selectedAircraftGUIDList = "";
    }
    else {
      this.request.selectedAircraftGUIDList = Array.prototype.map.call(this.selectedRegistrationList, s => s.aircraftGuid).toString();
    }
    if (this.selectedAirportList.length == this.orgAirportList.length) {
      this.request.selectedICAOList = "";
    }
    else {
      this.request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    this.request.personRole = this.person_role;
    switch (this.person_role) {
      case "pilot":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedPilotList.length == this.pilotList.length) {
            this.request.selectedPersonGUIDList = "";
          }
          else {
            this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPilotList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedPilotList = this.selectedPilotList.filter(x => x.personGUID != 'all');
          this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPilotList, s => s.personGUID).toString();
        }
        break;
      case "crew":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedCrewList.length == this.crewList.length) {
            this.request.selectedPersonGUIDList = "";
          }
          else {
            this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedCrewList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedCrewList = this.selectedCrewList.filter(x => x.personGUID != 'all');
          this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedCrewList, s => s.personGUID).toString();
        }
        break;
      case "passenger":
        if (this.f.person_search_keyword.value == "") {
          if (this.selectedPaxList.length == this.paxList.length) {
            this.request.selectedPersonGUIDList = "";
          }
          else {
            this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPaxList, s => s.personGUID).toString();
          }
        }
        else {
          this.selectedPaxList = this.selectedPaxList.filter(x => x.personGUID != 'all');
          this.request.selectedPersonGUIDList = Array.prototype.map.call(this.selectedPaxList, s => s.personGUID).toString();
        }
        break;
    }
    if (this.selectedVendorList.length == this.orgVendorList.length) {
      this.request.selectedVendorGUIDList = "";
    }
    else {
      this.selectedVendorList = this.selectedVendorList.filter(x=>x.vendorGUID!='all');
      this.request.selectedVendorGUIDList = Array.prototype.map.call(this.selectedVendorList, s => s.vendorGUID).toString();
    }
    if (this.selectedGSTypeList.length == this.gsTypeList.length) {
      this.request.groundStopTypeGUIDList = "";
    }
    else {
      this.request.groundStopTypeGUIDList = Array.prototype.map.call(this.selectedGSTypeList, s => s.groundStopTypeGUID).toString();
    }
    if (this.selectedCustomerCreditList.length == this.customerCreditList.length) {
      this.request.selectedCustomerCreditStatusIDList = "";
    }
    else {
      this.request.selectedCustomerCreditStatusIDList = Array.prototype.map.call(this.selectedCustomerCreditList, s => s.customerCreditStatusID).toString();
    }
    if (this.selectedTripCodeList.length == this.orgTripCodeList.length) {
      this.request.selectedTripCodeGUIDList = "";
    }
    else {
      this.request.selectedTripCodeGUIDList = Array.prototype.map.call(this.selectedTripCodeList, s => s.tripCodeGUID).toString();
    } 
    this.request.sortBy = this.f.sort_by.value;
    this.request.pageSize = this.pageSize;
    this.request.pageIndex = this.currentPageIndex;
    this.request.totalRecords = this.totalRecords;
    this.request.flightType = this.flightType;

    localStorage.setItem('trip.sd', this.request.startDateText);
    localStorage.setItem('trip.ed', this.request.endDateText);   
    localStorage.setItem('trip.tco', this.request.tripCloseOutID.toString());
    localStorage.setItem('trip.idemo', this.request.includeDemo.toString());
    localStorage.setItem('trip.ord', this.request.sortBy);
    localStorage.setItem('trip.clist', this.request.selectedCustomerGUIDList);
    localStorage.setItem('trip.aclist', this.request.selectedAircraftGUIDList);
    localStorage.setItem('trip.aplist', this.request.selectedICAOList);
    localStorage.setItem('trip.prole', this.request.personRole);
    localStorage.setItem('trip.plist', this.request.selectedPersonGUIDList);
    localStorage.setItem('trip.vlist', this.request.selectedVendorGUIDList);
    localStorage.setItem('trip.gstlist', this.request.groundStopTypeGUIDList);
    localStorage.setItem('trip.cclist', this.request.selectedCustomerCreditStatusIDList);
    localStorage.setItem('trip.tclist', this.request.selectedTripCodeGUIDList);
    localStorage.setItem('trip.filterTime',Date.now().toString());

  }

  onSendDataToParent(e: any) {
    this.totalRecordsText = e.totalRecordsText;
    this.totalRecords = e.totalRecords;
    this.totalPageNumber = e.totalPageNumber;
    this.buildCustomPageSizeOption();
    //if (this.userType == "internal" && this.customerGUID == "") {
    //  if (e.customerId.toLowerCase() == 'b00c8207-4290-44ef-a756-ff3972839273') {
    //    this.f.customer_select.setValue(e.customerId.toLowerCase());
    //  }
    //}
  }

  clickReset() {
    localStorage.removeItem('trip.sd');
    localStorage.removeItem('trip.ed');
    localStorage.removeItem('trip.tco');
    localStorage.removeItem('trip.idemo');
    localStorage.removeItem('trip.ord');
    localStorage.removeItem('trip.clist');
    localStorage.removeItem('trip.aclist');
    localStorage.removeItem('trip.aplist');
    localStorage.removeItem('trip.prole');
    localStorage.removeItem('trip.plist');
    localStorage.removeItem('trip.vlist');
    localStorage.removeItem('trip.gstlist');
    localStorage.removeItem('trip.cclist');
    localStorage.removeItem('trip.tclist');
    localStorage.removeItem('trip.filterTime');
    this.errMsg = "";
    this.errMsg1 = "";
    this.msg = "";
    this.customerList = [];
    this.gsTypeList.find(x => x.groundStopTypeGUID.toLowerCase() == "868a62b1-9cd6-499b-abfa-46542ccb7424").selected = true;
    this.gsTypeList.forEach(x => {
      if (x.groundStopTypeGUID.toLowerCase() == "868a62b1-9cd6-499b-abfa-46542ccb7424") {
        x.selected = true;
      }
      else {
        false;
      }
      });
     this.setGSTypeList();
    this.selectedCustomerCreditList = [];
    this.selectedCustomerCreditStatusID = 0;
    this.setCreditStatusList();
    this.includeClosedBilledRecords = false;
    this.includeDemo = false;
    //this.selectedTabName = 'tripcode_tactical';
    this.reloadChildPage = false;

    this.totalRecordsText = "";
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.endDate = null;
    //this.endDate = new Date();
    //this.endDate.setDate(this.endDate.getDate() + 28);
    if (this.selectedTabName != 'aircraft') {
      this.sort_by = "departureDate asc";
    }
    else {
      this.sort_by = "registration asc";
    }
    this.orgCustomerList = [];
    this.customerList = [];
    this.selectedCustomerList = [];
    this.registrationList = [];
    this.selectedRegistrationList = [];
    this.airportList = [];
    this.selectedAirportList = [];
    this.pilotList = [];
    this.selectedPilotList = [];
    this.crewList = [];
    this.selectedCrewList = [];
    this.paxList = [];
    this.selectedPaxList = [];
    this.person_role = "pilot";
    this.orgVendorList = [];
    this.vendorList = [];
    this.selectedVendorList = [];
    this.tripCodeList = [];
    this.selectedTripCodeList = [];
    this.selectedRegistrationCountText = "";
    this.selectedAirportCountText = "";
    this.selectedPersonCountText= "";
    this.selectedClientCountText = "";
    this.selectedVendorCountText= "";
    this.selectedGSTypeCountText = "";
    this.selectedCreditStatusCountText = "";
    this.selectedTripCodeCountText= "";
    this.showGSTypeList = false;
    this.showCustomerCreditList = false;
    this.showTripCodeList = false;
    this.showClientList= false;
    this.showAircraftList= false;   
    this.showAirportList= false;
    this.showPersonList = false;  
    this.showVendorList = false;  
    
    this.f.includeDemo.setValue(this.includeDemo);
    this.f.includeClosedBilledRecords.setValue(this.includeClosedBilledRecords);
    this.f.startDate.setValue(this.startDate);
    this.f.endDate.setValue(this.endDate);
    this.f.sort_by.setValue(this.sort_by);
    //this.initControls();
    this.currentPageIndex = 0;
    this.flightType = 0;
    this.clickSubmit();
  }

  clickTab(s: string) {
    if (this.selectedTabName != s) {
      this.selectedTabName = s;
      if (this.selectedTabName == "aircraft") {
        this.f.sort_by.setValue("registration asc");
      }
      else {
        this.f.sort_by.setValue("departureDate asc");
      }
      this.v = this._authService.getCurrentTimeNumber();
      this.totalRecords= 0;
      this.currentPageIndex = 0;
      this.totalPageNumber = 0;
      this.openChildPage();
    }
  }

  clickLegend() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, legendType: 't'
    };

    const dialogRef = this._dialog.open(IconLegendDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });

  }


  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
      this.request.pageSize = this.pageSize;
      this.request.totalRecords = this.totalRecords;
    }
    this.request.pageIndex = this.currentPageIndex;
    this.clickSubmit();

  }


  buildCustomPageSizeOption() {
    this.customPageSizeOptionList = [];
    let obj: CustomPageSizeOptionModel;
    if (this.totalRecords > 0 && this.totalRecords > this.minPageSize) {
      obj = new CustomPageSizeOptionModel();
      obj.pageSizeText = this.minPageSize.toString();
      obj.pageSizeValue = this.minPageSize;
      this.customPageSizeOptionList.push(obj);

      obj = new CustomPageSizeOptionModel();
      obj.pageSizeText = this.maxPageSize.toString();
      obj.pageSizeValue = this.maxPageSize;
      this.customPageSizeOptionList.push(obj);

      if (this.totalRecords > this.maxPageSize) {
        obj = new CustomPageSizeOptionModel();
        obj.pageSizeText = "All";
        obj.pageSizeValue = 10000;
        this.customPageSizeOptionList.push(obj);
      }

    }
  }

  customPageSizeChange(e: any, controlNumber: number) {
    this.currentPageIndex = 0;
    if (controlNumber == 1) {
      this.pageSize = this.f.custom_page_size_select1.value;
      //this.f.custom_page_size_select2.setValue(this.pageSize);
    }
    else {
      this.pageSize = this.f.custom_page_size_select2.value;
      this.f.custom_page_size_select1.setValue(this.pageSize);
    }
    this.clickSubmit();
  }

}
