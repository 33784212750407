import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType, HttpClientModule, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { GlobalConstant } from "../common-utility/global-constant";
import { GroundStopTaskModel } from "../models/ground-stop-task.model";
import { AuthenticateService } from "./authenticate.service";


@Injectable()
export class GroundStopAdvancedClientService {
  constructor(private readonly http: HttpClient, private readonly _authService: AuthenticateService) {

  }

  //updateGroundStopTaskForClientBygstId<T>(request: GroundStopTaskModel): Observable<T> {
  //  //debugger;
  //  var headerOptions = new HttpHeaders({
  //    'Content-Type': 'application/json',
  //    'Authorization': 'bearer ' + localStorage.getItem('token'),
  //    'Cache-Control': 'no-cache, no-store, must-revalidate',
  //    //'Pragma': 'no-cache',
  //    'Expires': '-1'
  //  });

  //  return this.http.post<T>(GlobalConstant.baseUrl + "GroundStopAdvancedClient/updateGroundStopTaskForClientBygstId", request, {
  //    headers: headerOptions
  //  }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  //}

  getGroundStopTaskForClientBygstId<T>(request: GroundStopTaskModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStopAdvancedClient/getGroundStopTaskForClientBygstId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }


}
