<div *ngIf="upPII && grantAccessCompanyProfiles" style="background-color:#f8f8f8;" >
  <div style="display: flex; justify-content: space-between;" cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>{{dialogTitle}}</h5></div>
  </div>
  <div>
    <form *ngIf="customer_document_edit_form" [formGroup]="customer_document_edit_form" (ngSubmit)="clickSave()">
      <div style="display:flex; margin-top:0em; padding-left: 1em;padding-right:1em">
        <div style="background-color: white;">
          <div style="display:flex; margin-left:0.5em;padding-top:0.5em">
            <div style="margin-right:1em">
              <div *ngIf="userType=='internal'" style="margin-left: 0em;margin-top:-0.5em;margin-right:0.625em;">
                <label for="document_type" style="font-size:small">Document Type</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <select *ngIf="f" formControlName="document_type" tabindex="1" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.document_type.errors }">
                    <option *ngFor="let d of documentTypeList" [ngValue]="d.documentTypeGUID">{{d.documentTypeDescription}}</option>
                  </select>
                  <div *ngIf="submitted && f.document_type.errors" class="invalid-feedback" style="margin-top:-2em">
                    <div *ngIf="f.document_type.errors.required">Document Type is required.</div>
                  </div>
                </div>
              </div>
              <div style="display:flex">

                <div style="margin-left: 0em;margin-right:0.625em;">
                  <label for="document_name" style="font-size:small">Document Name</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    <input type="text" formControlName="document_name" class="form-control" maxlength="150" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.document_name.errors }" style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                  </div>
                  <div *ngIf="submitted && f.document_name.errors" class="invalid-feedback" style="margin-top:-2em">
                    <div *ngIf="f.document_name.errors.required">Document Name is required.</div>
                  </div>
                  <div *ngIf="errMsg!=''" class="d-block" style="padding-left:0em; display:flex;margin-top:-0.25em;margin-bottom:-1em; white-space:pre-wrap"><label style="color:red; font-size: small">{{errMsg}}</label></div>
                </div>
                <div>
                  <div style="height:2.5em">
                    <div style="margin-top:0.75em" *ngIf="(customerDocumentGUID=='' && userType=='customer') || userType=='internal'">
                      <!--<label class="filelabel">
      <input #document formControlName="document" id="document" type="file" tabindex="3" ng2FileSelect [uploader]="uploader" style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />
      Load<span style="display:block;margin-top:-0.5em">Document</span>
      <span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
    </label>-->
                      <label class="btn-high" mat-button style="border: 0.0625em solid #7095c8; height:2.5em; border-radius: 0.25em; cursor:pointer">
                        <input #document formControlName="document" id="document" type="file" ng2FileSelect [uploader]="uploader" style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />
                        Load<span style="display:block;margin-top:-0.5em">Document</span>
                        <span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="fileList && fileList.length>0">
                    <button type="button" mat-button class="btn-high" (click)="clickPreviewDocument()" style="width:5em">Preview</button>
                  </div>
                  <!--<div *ngIf="fileList && fileList.length>0" style="margin-top:0.25em">
            <button type="button" class="btn btn-danger" (click)="clickRemoveDocument()" style="width:5em">Remove<span style="display:block;margin-top:-0.5em">Document</span></button>
          </div>-->
                </div>

              </div>
              <div style="margin-top:0em; margin-left:0em; font-size: small">
                <div style="margin-top:0em">Image Maximum size 25MB</div>
              </div>
              <div style="font-size:small">
                Allowed file types:
              </div>
              <div style="font-size:small">
                {{allowedFileType}}
              </div>
              <div>
                <label for="size" style="font-size:small">Size</label>
                <div style="margin-top:-0.5em">
                  <input type="text" formControlName="size" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small" />
                </div>
              </div>
              <div style="display:flex">
                <div>
                  <label for="effectiveDate" style="font-size:small">Effective Date</label>
                  <div style="display:flex;margin-top:-0.5em">
                    <input type="text" [matDatepicker]="effectivedatepicker" tabindex="4" placeholder="MM/DD/YYYY" formControlName="effectiveDate" #effectiveDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.effectiveDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                    <mat-datepicker-toggle matSuffix [for]="effectivedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                    <mat-datepicker #effectivedatepicker></mat-datepicker>
                  </div>
                </div>
                <div style="margin-left:1em">
                  <label for="expirationDate" style="font-size:small">Expiration Date</label>
                  <div style="display:flex;margin-top:-0.5em">
                    <input type="text" [matDatepicker]="expirationdatepicker" tabindex="5" placeholder="MM/DD/YYYY" formControlName="expirationDate" #expirationDate class="form-control" [ngClass]="{ 'is-invalid': submitted && f.expirationDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                    <mat-datepicker-toggle matSuffix [for]="expirationdatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                    <mat-datepicker #expirationdatepicker></mat-datepicker>
                  </div>

                </div>
              </div>
              <div *ngIf="submitted && f.effectiveDate.errors" class="invalid-feedback" style="margin-top:-1em">
                <div *ngIf="f.effectiveDate.errors.isValidDate==false">Invalid effective date. format: MM/DD/YYYY</div>

              </div>
              <div *ngIf="submitted && f.expirationDate.errors" class="invalid-feedback" style="margin-top:-0.5em">
                <div *ngIf="f.expirationDate.errors.isValidDate==false">Invalid expiration date. format: MM/DD/YYYY</div>

              </div>
              <div style="display: flex">
                <div *ngIf="customerDocumentGUID!='' && userType=='internal'" style="margin-top:-0.5em; margin-right:1.75em">
                  <label for="data_entry_status_select" style="font-size:small">Data Entry Status</label>
                  <div style="margin-top:-0.35em;margin-bottom:0em">
                    <select *ngIf="f" formControlName="data_entry_status_select" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color:lightgrey">
                      <option *ngFor="let d of dataEntryStatusList" [ngValue]="d.dataEntryStatusID">{{d.dataEntryStatusDescription}}</option>
                    </select>
                  </div>
                </div>
                <div style="margin-top:-0.5em">
                  <label for="doc_status" style="font-size:small">Disable Record</label>
                  <div style="margin-top:-0.3em;">
                    <select *ngIf="f" formControlName="doc_status" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color:lightgrey">
                      <option value="1" selected>No</option>
                      <option value="0">Yes</option>
                    </select>
                  </div>
                </div>
              </div>
              <div style="display:flex" class="mb-2">
                <div>
                  <label for="modifiedBy" style="font-size:small">Modified By</label>
                  <div style="margin-top:-0.5em">
                    <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small; padding:0em" />
                  </div>
                </div>
                <div style="margin-left:1em">
                  <label for="modifiedDate" style="font-size:small">Modified Date</label>
                  <div style="margin-top:-0.5em">
                    <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small; padding:0em" />
                  </div>
                </div>
              </div>

            </div>

            <!--<div style="margin-right:1em; padding-bottom:1em">
      <div style="font-size:small">Type&nbsp;&nbsp;<button type="button" class="btn btn-primary"  [hidden]="userType!='internal'" (click)="clickCustomizeDocumentType()">Customize</button></div>
      <div style="font-size:small; border: 0.0625em solid lightgrey; border-radius: 0.25em; height: 7em; width: 17em; margin-top: 0.5em; overflow-y:auto">
        <div *ngFor="let d of selDocumentTypeList">
          {{d.documentTypeDescription}}
        </div>
      </div>
      <div *ngIf="userType=='internal'">
        <div style="font-size:small; display:flex">
          <div style="margin-right:0.5em">Vendor Service<span style="display:block">document requirement</span></div>
          <div style="margin-top:0.75em"><button type="button" class="btn btn-primary" (click)="clickCustomizeVendorSvcDocumentType()">Customize</button></div>
        </div>
        <div style="font-size:small; border: 0.0625em solid lightgrey; border-radius: 0.25em; height: 7em; width: 17em; margin-top: 0.5em; overflow-y:auto">
          <div *ngFor="let d of selVendorSvcDocTypeList">
            {{d.serviceTypeDescription}}
          </div>
        </div>
      </div>
    </div>-->
            <!--<div style="width:0.5em; background-color: #f8f8f8;"></div>
    <div *ngIf="userType=='internal'" style="margin-top:0em; padding-left: 0.5em; padding-right:0em;margin-right:1em; background-color: white">
      <div *ngIf="userType=='internal'" style="display:flex">
        <div>
          <div style="font-size:small">Document Types</div>
          <div style="font-size:small;border: 0.0625em solid lightgrey; border-radius: 0.25em; height: 17em; width: 15em; background-color:lightgray; margin-left: 0.0125em;margin-top: 0.5em; overflow-y:auto">
            <div *ngFor="let d of selDocumentTypeList">
              {{d.documentTypeDescription}}
            </div>
          </div>
          <div *ngIf="errMsgDocType!=''" class="d-block" style="padding-left:0em; display:flex;margin-top:-0.25em;margin-bottom:-1em; white-space:pre-wrap"><label style="color:red; font-size: small">{{errMsgDocType}}</label></div>
        </div>
        <div style="padding-left: 1em">
          <div>
            <label for="service_type_select" style="font-size:small">Filter by Services</label>
            <div style="margin-top:-0.5em;">
              <select *ngIf="f" formControlName="service_type_select" style="font-size: small;background-color:#eaf0fd;border-radius:0.25em; border-color:lightgrey" (change)="serviceTypeOnchange($event)">
                <option value="0" selected>All</option>
                <option *ngFor="let svc of serviceTypeList" value={{svc.serviceTypeID}}>{{svc.serviceTypeDescription}}</option>
              </select>
            </div>
          </div>
          <div style="display:flex; margin-top:0.25em;">
            <div style="height: 16.75em;width:12em; overflow-y: auto;overflow-x:hidden">
              <table mat-table *ngIf="avaiDocumentTypeList" [dataSource]="avaiDocumentTypeList" class="example-container2 mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="documentType">
                    <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">
                      Document Types
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickAddItem($event, element)">{{element.documentTypeDescription}}</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
            <div style="margin-top: 2em;margin-left:0em">
              <button type="button" class="btn btn-primary" [hidden]="avaiDocumentTypeList && avaiDocumentTypeList.length==0" (click)="clickSelectAll()" style="width: 7em">Select All</button><br /><br />
              <button type="button" class="btn btn-secondary" [hidden]="selDocumentTypeList && selDocumentTypeList.length==0" (click)="clickRemoveAll()" style="width: 7em">&nbsp;Remove All&nbsp;</button>
            </div>
            <div style="margin-left:1em;height: 16.75em;width:12em; overflow-y: auto;overflow-x:hidden">
              <table mat-table *ngIf="selDocumentTypeList" [dataSource]="selDocumentTypeList" class="example-container2 mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="documentType">
                    <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">
                      Selected Document Types
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickRemoveItem($event, element)">{{element.documentTypeDescription}}</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
          </div>
        </div>
      </div>-->
            <!--<div *ngIf="showDocType">-->
            <!--doc type-->
            <!--<div style="font-size: small; margin-left:0em">Choose Document Types</div>
      <div style="display:flex">
        <div style="height: 18em;width:12em; overflow-y: auto;overflow-x:hidden">
          <table mat-table *ngIf="avaiDocumentTypeList && avaiDocumentTypeList.length>0" [dataSource]="avaiDocumentTypeList" class="example-container2 mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                  Document Types
                </th>
                <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickAddItem($event, element)">{{element.documentTypeDescription}}</a></td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
          <table mat-table *ngIf="avaiDocumentTypeList && avaiDocumentTypeList.length==0" class="example-container2 mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                  Document Types
                </th>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
        <div style="margin-top: 2em;margin-left:0em">
          <button type="button" class="btn btn-primary" [hidden]="avaiDocumentTypeList && avaiDocumentTypeList.length==0" (click)="clickSelectAll()" style="width: 7em">Select All</button><br /><br />
          <button type="button" class="btn btn-secondary" [hidden]="selDocumentTypeList && selDocumentTypeList.length==0" (click)="clickRemoveAll()" style="width: 7em">&nbsp;Remove All&nbsp;</button>
        </div>
        <div style="margin-left:1em;height: 18em;width:12em; overflow-y: auto;overflow-x:hidden">
          <table mat-table *ngIf="selDocumentTypeList && selDocumentTypeList.length>0" [dataSource]="selDocumentTypeList" class="example-container2 mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                  Selected Types
                </th>
                <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickRemoveItem($event, element)">{{element.documentTypeDescription}}</a></td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
          <table mat-table *ngIf="selDocumentTypeList && selDocumentTypeList.length==0" class="example-container2 mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                  Selected Types
                </th>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="!showDocType">-->
            <!--vendor doc type-->
            <!--<div style="font-size: small; margin-left:0em">Choose Vendor Service Document Types</div>
      <div style="display:flex">
        <div style="height: 17em;width:12em; overflow-y: auto;overflow-x:hidden">
          <table mat-table *ngIf="avaiVendorSvcDocTypeList && avaiVendorSvcDocTypeList.length>0" [dataSource]="avaiVendorSvcDocTypeList" class="example-container2 mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                  Vendor Service Document<br />Requirement Types
                </th>
                <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickAddVendorSvcDocItem($event, element)">{{element.serviceTypeDescription}}</a></td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
          <table mat-table *ngIf="avaiVendorSvcDocTypeList && avaiVendorSvcDocTypeList.length==0" class="example-container2 mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                  Document Types
                </th>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
        <div style="margin-top: 2em;margin-left:0em">
          <button type="button" class="btn btn-primary" [hidden]="avaiVendorSvcDocTypeList && avaiVendorSvcDocTypeList.length==0" (click)="clickSelectAllVendorSvcDoc()" style="width: 7em">Select All</button><br /><br />
          <button type="button" class="btn btn-secondary" [hidden]="selVendorSvcDocTypeList && selVendorSvcDocTypeList.length==0" (click)="clickRemoveAllVendorSvcDoc()" style="width: 7em">&nbsp;Remove All&nbsp;</button>
        </div>
        <div style="margin-left:1em;height: 18em;width:12em; overflow-y: auto;overflow-x:hidden">
          <table mat-table *ngIf="selVendorSvcDocTypeList && selVendorSvcDocTypeList.length>0" [dataSource]="selVendorSvcDocTypeList" class="example-container2 mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                  Selected Types
                </th>
                <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickRemoveVendorSvcDocItem($event, element)">{{element.serviceTypeDescription}}</a></td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
          <table mat-table *ngIf="selVendorSvcDocTypeList && selVendorSvcDocTypeList.length==0" class="example-container2 mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                  Selected Types
                </th>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
      </div>
    </div>-->
          </div>
          <div *ngIf="showSuccessMsg">
            <app-success-message [successMsg]=""></app-success-message>
          </div>
        </div>
        </div>
    </form>
  </div>
  
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; display:flex">
    <div style="margin-right:1em">
      <button mat-button class="btn-high" *ngIf="(customerDocumentGUID=='' && userType=='customer') || userType=='internal'" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Save&nbsp;</button>&nbsp;
      <button mat-button *ngIf="customerDocumentGUID!='' && userType=='internal'" type="button" class="btn-high" (click)="clickAddNew()">&nbsp;Add new Record&nbsp;</button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white">&nbsp;Close&nbsp;</button>
    </div>
    
  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>
