import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AirportService } from '../services/airport.service';
import { CommonService } from '../services/common.service';
import { ActivatedRoute } from '@angular/router';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthenticateService } from '../services/authenticate.service';
import { Title } from '@angular/platform-browser';
import { ResponseModel } from '../models/response.model';
import { CountryModel } from '../models/country.model';
import { AirportModel } from '../models/airport.model';
import { AirportDetailsEditComponent } from './airport-details-edit.component';
import * as atlas from 'azure-maps-control';
import { GlobalSettingsDashboardComponent } from '../common-utility/global-settings-dashboard.component';
import { DialogService } from '../services/dialog.service';
import { AirportMapComponent } from './airport-map.component';

@Component({
  selector: 'app-manage-airports',
  templateUrl: './manage-airports.component.html',
  styleUrls: ['./manage-airports.component.css']
})
export class ManageAirportsComponent implements OnInit {
  displayedColumn: string[] = ['icao', 'iata', 'airport', 'utc','airport_of_entry', 'restrictions', 'slots', 'ppr', 'landing_permit_private', 'landing_permit_charter',
  'advisories','revisions','expired_documents','action'];
  isOpenedNew: boolean = false;
  airportList: string;
  countryID: number;
  daysOut: number = 0;
  countryList: CountryModel[];
  airports: AirportModel[]=[];
  map: atlas.Map;
  points: any[];
  minRunway: boolean = false;
  callFrom: string = "airport";
  upAirportCountry: boolean = false;
  showSpin: boolean = false;
  constructor(private readonly _airportService: AirportService, private readonly _commonService: CommonService,
    @Optional() @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _route: ActivatedRoute, private readonly _dialogService:DialogService,
    private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _titleService: Title, @Optional() private readonly _dialogRef: MatDialogRef<ManageAirportsComponent>) {
    if (_data) {
      if (_data.airportList != null && _data.airportList != undefined && _data.airportList != "undefined") {
        this.airportList = _data.airportList;
      }
      else
        this.airportList = "";
      if (_data.countryID != null && _data.countryID != undefined && _data.countryID != "undefined") {
        this.countryID = _data.countryID;
      }
      else
        this.countryID = 0;
      if (_data.minRunway != null && _data.minRunway != undefined && _data.minRunway != "undefined") {
        if (_data.minRunway == "true")
          this.minRunway = true;
        else
          this.minRunway = false;
      }
      if (_data.daysOut != null && _data.daysOut != undefined && _data.daysOut != "undefined") {
        this.daysOut = _data.daysOut;
      }
      else
        this.daysOut = 0;

      if (this.airportList != "")
        this.callFrom = "airport";
      if (this.countryID != 0)
        this.callFrom = "country";
      if (this.daysOut != 0)
        this.callFrom = "daysOut";  
      this.getAirportData(this.callFrom);
    }
  }
    ngOnInit() {
      this._authService.updateAccessTime();

      if (localStorage.getItem('up.airportDoc') != 'true') {
        this.upAirportCountry = false;
        this._dialogService.openAlertDialog("Permission denied.", "OK");
        if (!this.isOpenedNew)
          this._dialogRef.close();
        return;
      }
      else {
        this.upAirportCountry = true;
      }
      this._route.url.subscribe(o => {
        if (o[0].path == "")
          this.isOpenedNew == false;
        else {
          this.isOpenedNew = true;
          this._route.queryParams.subscribe(params => {
            if (params["airportList"] != null && params["airportList"] != undefined && params["airportList"] != "undefined") {
              this.airportList = params["airportList"];
            }
            else
              this.airportList = "";
            if (params["countryID"] != null && params["countryID"] != undefined && params["countryID"] != "undefined") {
              this.countryID = params["countryID"];
            }
            else
              this.countryID = 0;
            if (params["minRunway"] != null && params["minRunway"] != undefined && params["minRunway"] != "undefined") {
              if (params["minRunway"] == "true")
                this.minRunway = true;
              else
                this.minRunway = false;
            }
            if (params["daysOut"] != null && params["daysOut"] != undefined && params["daysOut"] != "undefined") {
              this.daysOut = params["daysOut"];
            }
            else
              this.countryID = 0;
            if (this.airportList != "")
              this.callFrom = "airport";
            if (this.countryID != 0)
              this.callFrom = "country";
            if (this.daysOut != 0)
              this.callFrom = "daysOut";  
            this.getAirportData(this.callFrom);
          });
          
        }
      });
    
   
      this._commonService.getCountryList<ResponseModel<CountryModel[]>>().subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.countryList = response.model;
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          }

        }
      });
  }

  getAirportData(callFrom) {
    switch (callFrom) {
      case "airport":
        this.countryID = 0;
        this.daysOut = 0
        break;
      case "country":
        this.airportList = "";
        this.daysOut = 0;
        break;
      case "daysout":
        this.airportList = "";
        this.countryID = 0;
    }

    if (this.countryID != 0 || this.airportList != "" || this.daysOut != 0) {
      this.showSpin = true;
      this._airportService.getAirportsByAirportListCountryID<ResponseModel<AirportModel[]>>(this.airportList, this.countryID, this.minRunway ? 5000 : 0, Number(this.daysOut)).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.airports = response.model;
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          }
          this.createMap();
          this.showSpin = false;
        }
      });
    }
  }

  createMap() {
    this.map = new atlas.Map('manage-airport-map', {
      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map SDK.
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      var zoomControl = new atlas.control.ZoomControl();

      /* Add the zoom control to the map*/
      self.map.controls.add(zoomControl, {
        position: atlas.ControlPosition.TopRight
      });

      if (self.airports.length > 0) {
        self.airports.forEach(v => {
          if (v.longitude != null && v.latitude != null) {
            var startPosition = [v.longitude, v.latitude];
            // var endPosition = [v.nextArrivalLongitude, v.nextArrivalLatitude];
            var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
              title: v.icao,
              name: v.airportName,
              airport:v
            });

            //var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
            //  title: v.nextArrivalICAO,
            //  name: v.nextArrivalAirportName
            //});

            self.points.push(startPosition);
            // self.points.push(endPosition);
            //var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
            //var poly = new atlas.data.LineString(path);
            dataSource.add([startPoint]);
          }
        });
        var popupTemplate = "<div class='popup'>{name}</div>";
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            image: 'pin-round-darkblue',
            cluster: false,
            optional: true,
            allowOverlap: true,
            size: .65
          },
          textOptions: {
            textField: ['get', 'title'],
            offset: [0, -1.75],
            cluster: false,
            allowOverlap: true,
            size: 12
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        });
        self.map.layers.add([new atlas.layer.LineLayer(dataSource, null, {
          strokeColor: 'black',
          strokeWidth: 1.5
        }),
          symbolLayer
        ]);
        var popup = new atlas.Popup({
          pixelOffset: [0, -18],
          closeButton: false
        });
        //Add a hover event to the symbol layer.
        self.map.events.add('mouseover', symbolLayer, function (e) {
          self.map.getCanvasContainer().style.cursor = 'pointer';
          //Make sure that the point exists.
          if (e.shapes && e.shapes.length > 0) {
            var pos = e.position;
            var offset = [0, 0];
            var properties;
            var content, coordinate;

            if (e.shapes[0] instanceof atlas.Shape) {
              properties = e.shapes[0].getProperties();

              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].getType() === 'Point') {
                coordinate = e.shapes[0].getCoordinates();
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            } else {
              properties = e.shapes[0].properties;
              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].type === 'Point') {
                coordinate = e.shapes[0].geometry.coordinates;
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            }
            popup.setOptions({
              //Update the content of the popup.
              content: content,

              //Update the popup's position with the symbol's coordinate.
              position: coordinate

            });
            //Open the popup.
            popup.open(self.map);
          }
        });

        self.map.events.add('mouseleave', symbolLayer, function () {
          self.map.getCanvasContainer().style.cursor = 'grab';
          popup.close();
        });

        self.map.events.add('click', symbolLayer, function (e) {
          var properties;
          var airport;
          if (e.shapes[0] instanceof atlas.Shape) {
            properties = e.shapes[0].getProperties();

            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].getType() === 'Point') {
              airport = properties.airport;
            }
          } else {
            properties = e.shapes[0].properties;
            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].type === 'Point') {
              airport = properties.airport;
            }
          }
          
          self.clickEdit(airport);
        })

        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        if (self.airports.length == 1) {
          self.map.setCamera({
            center: [self.airports[0].longitude, self.airports[0].latitude],
            zoom: 12
          });
        }
        else {
          self.map.setCamera({
            bounds: bbox,
            padding: 40
          });
        }
      }


    });
  }

  clickEdit(airport: any) {
    let airportID = 0;
    if (airport != null)
      airportID = airport.airportID;
    let s = "";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //  dialogConfig.width = "100%";
    dialogConfig.maxHeight = "95vh";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { airportID: airportID };
    const dialogRef = this._dialog.open(AirportDetailsEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAirportData(this.callFrom);
      }

    });
  }

  openInNew() {
    this._dialogRef.close();
    let route = "/airports/manage-airports?airportList=" + this.airportList + "&countryID=" + this.countryID + "&minRunway=" + this.minRunway + "&daysOut=" + this.daysOut;
    window.open(route, '_blank');
  }

  openGlobalSettingsDashboardComponent() {
    if (!this.isOpenedNew) {
      this._dialogRef.close(true);
    }
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "41.5em";//"1000px";
    config.width = "45em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", username: localStorage.getItem('un'), isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(GlobalSettingsDashboardComponent, config);

  }

  clickReset() {
    this.airportList = "";
    this.countryID = 0;
    this.airports = [];
    if (this.map)
      this.map.dispose();
  }


  openMapComponent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80em";
    dialogConfig.height = "40em";



    dialogConfig.data = {
      airports: this.airports,
     from:"manage"
    };

    this._dialog.open(AirportMapComponent, dialogConfig);

  }

}
