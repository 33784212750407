import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from "../services/authenticate.service";
import { UntypedFormBuilder } from "@angular/forms";
import { UpdateMyAccountComponent } from "./update-my-account.component";

@Component({
  selector: 'push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.css']
})

export class PushNotificationComponent implements OnInit {
  pushNotificationsOn: boolean = false;
  isDialog: boolean = false;

  constructor(@Optional() private readonly _dialogRef: MatDialogRef<PushNotificationComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
  private readonly _authService: AuthenticateService,
  private readonly _formBuilder: UntypedFormBuilder,
  private readonly _dialog: MatDialog) {
    
  }

  ngOnInit(): void {
    if(this.data) {
      this.isDialog = true;
    }
  }

  togglePushNotifications() {
    this.pushNotificationsOn = !this.pushNotificationsOn;
  }

  clickClose(val) {
    this._dialogRef.close(val);
  }

  openUpdateMyAccountComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    //config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };

    if (this.isDialog) {
      this.clickClose(true);
    }

    //this._dialog.open(UpdateMyAccountComponent, config);
  }

  clickRouteNew(route: string, display: string) {
    this.clickClose(true);
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }
}