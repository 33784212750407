import { Component, Inject, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { Router } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';

@Component({
  selector: 'app-session-expiration-alert-dialog',
  templateUrl: './session-expiration-alert-dialog.component.html',
  styleUrls: ['./session-expiration-alert-dialog.component.css']
})

export class SessionExpirationAlertDialogComponent implements OnInit {
  expirationMinutes: number;
  continuteSession: boolean = true;
  expirationMinutesDisplay: string;
  maxTokenExpirationMinutes: string;
  constructor(private _dialogRef: MatDialogRef<SessionExpirationAlertDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any) {
    this.expirationMinutes = parseInt(_data.expirationMinutes);
    //this.message = _data.message;
    //if (_data.message == "") {
    //  this.message = "Your information has been submitted successfully.";
    //}
    _dialogRef.disableClose = true;
  }

  ngOnInit() {
    //if (this.expirationMinutes < 1) {
    //  this.expirationMinutesDisplay = "less than 1 minute";
    //}
    //else {
    //  if (this.expirationMinutes == 1) {
    //    this.expirationMinutesDisplay = " 1 minute";
    //  }
    //  else {
    //    this.expirationMinutesDisplay = this.expirationMinutes.toString()+ " minute";
    //  }
      
    //}
    this.maxTokenExpirationMinutes = localStorage.getItem('maxIdleTime');
    this.showLeftMinutes();
    if (this.expirationMinutes > 1) {
      setInterval(() => { this.countdownMinute(); }, 60000);
    }
  }

  countdownMinute() {
    this.expirationMinutes -= 1;
    if (this.expirationMinutes <= 0) {
      this.clickLogout();
    }
      
    this.showLeftMinutes();
  }

  showLeftMinutes() {
    if (this.expirationMinutes < 1) {
      this.expirationMinutesDisplay = "less than 1 minute";
    }
    else {
      if (this.expirationMinutes == 1) {
        this.expirationMinutesDisplay = " 1 minute";
      }
      else {
        this.expirationMinutesDisplay = this.expirationMinutes.toString() + " minutes";
      }

    }
  }

  clickContinue() {
    this.continuteSession = true;
    this._dialogRef.close(this.continuteSession);
  }

  clickLogout() {
    this.continuteSession = false;
    this._dialogRef.close(this.continuteSession);
  }


  close() {
    this._dialogRef.close();
  }
}
