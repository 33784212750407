import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CommAddressTypeModel } from '../models/comm-address-type.model';
import { AddressRatingModel } from '../models/address-rating.model';
import { AddressUseModel } from '../models/address-use.model';
import { AircraftService } from '../services/aircraft.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { DataEntryStatusModel } from '../models/data-entry-status.model';

@Component({
  selector: 'app-aircraft-comm-edit-dialog',
  templateUrl: './aircraft-comm-edit-dialog.component.html',
  styleUrls: ['./aircraft-comm-edit-dialog.component.css']
})

export class AircraftCommEditDialogComponent implements OnInit, AfterViewInit {
  @Input() dialogTitle: string;
  @Input() personCommsAddressGUID: string;
  @Input() aircraftGUID: string;
  aircraft_comm_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  addressRatingList: AddressRatingModel[];
  commAddressTypeList: CommAddressTypeModel[];
  addressUseList: AddressUseModel[];
  avaiAddressUseList: AddressUseModel[];
  selAddressUseList: AddressUseModel[];
  selectedCommAddressType: string;
  selectedCommAddressTypeId: string
  selectedAddressRatingId: string;
  address: string;
  extension: string;
  remarks: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  displayedColumn: string[] = ['addressUse'];
  personId: string;
  isModified: boolean = false;
  isPreferred: number;
  showSuccessMsg: boolean = false;
  userType: string;
  isDisabled: boolean = false;
  action: string;
  dataEntryStatusID: number;
  dataEntryStatusList: DataEntryStatusModel[];

  constructor(private readonly _dialogRef: MatDialogRef<AircraftCommEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _aircraftService: AircraftService,
    private readonly _dialogService: DialogService
    ) {
    this.personCommsAddressGUID = _data.personCommsAddressGUID;
    if (_data.aircraftId == null) {
      this.aircraftGUID = "";
    }
    else {
      this.aircraftGUID = _data.aircraftId;
    }
    this.dialogTitle = _data.dialogTitle;
    this.action = _data.action;
  }

  getAllData(): Observable<any[]> {
    let getCommAddressTypeResponse = this._commonService.getCommAddressTypeList<ResponseModel<CommAddressTypeModel[]>>();
    let getAddressRatingResponse = this._commonService.getAddressRatingList<ResponseModel<AddressRatingModel[]>>();
    let getAddressUseResponse = this._commonService.getCommAddressUseList<ResponseModel<AddressUseModel[]>>(this.personCommsAddressGUID);    
    let getPersonIdResponse = this._aircraftService.getAircraftPersonId<ResponseModel<string>>(this.aircraftGUID);
    let getDataEntryStatusResponse = this._commonService.getDataEntryStatusList();    
    if (this.personCommsAddressGUID != null && this.personCommsAddressGUID != "") {
      let getPersonCommsResponse = this._commonService.getPersonCommsAddressBypcaId(this.personCommsAddressGUID);
      return forkJoin([getCommAddressTypeResponse, getAddressRatingResponse, getAddressUseResponse, getPersonIdResponse, getDataEntryStatusResponse, getPersonCommsResponse]);
    }
    else
      return forkJoin([getCommAddressTypeResponse, getAddressRatingResponse, getAddressUseResponse, getPersonIdResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.avaiAddressUseList = [];
    this.selAddressUseList = [];
    this.selectedCommAddressType = "";
    this.selectedCommAddressTypeId = "";
    this.selectedAddressRatingId="";
    this.address="";
    this.extension="";
    this.remarks="";
    this.modifiedBy="";
    this.modifiedDate="";
    this.isActive = 1;
    this.isPreferred = 0;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        if (this.action != "add")
          this.isDisabled = true;
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.commAddressTypeList = responses[0].model;
          this.selectedCommAddressTypeId = this.commAddressTypeList[0].commAddressTypeGUID;
          this.selectedCommAddressType = this.commAddressTypeList[0].commAddressTypeDesc;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
       
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.addressRatingList = responses[1].model;
          this.selectedAddressRatingId = this.addressRatingList[0].addressRatingGUID;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.addressUseList = responses[2].model;
          //this.setAddressUseList();
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.personId = responses[3].model;
          //this.setAddressUseList();
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.personCommsAddressGUID != null && this.personCommsAddressGUID != "") {
        if (responses[4] != null) {
          if (responses[4].code == "200" && responses[4].message == "") {
            this.dataEntryStatusList = responses[4].model;
          }
          else {
            if (responses[4].code == "401") {
              this._authService.signOut();
            }
          }
        }
        if (responses[5] != null) {
          if (responses[5].code == "200" && responses[5].message == "") {
            let personComm = new PersonCommsAddressModel();
            personComm = responses[5].model;
            this.selectedCommAddressTypeId = personComm.commAddressTypeGUID;
            this.selectedCommAddressType = personComm.commAddressType;
            this.selectedAddressRatingId = personComm.addressRatingGUID;
            this.address = personComm.address;
            this.extension = personComm.extension;
            this.remarks = personComm.commRemarks;
            this.modifiedBy = personComm.modifiedBy;
            this.modifiedDate = personComm.modifiedDate;
            this.dataEntryStatusID = personComm.dataEntryStatusID;
            if (personComm.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
            if (personComm.isPreferred == true)
              this.isPreferred = 1;
            else
              this.isPreferred = 0;
          }
          else {
            if (responses[5].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }
       
      this.initControls();
      
      this.showSpin = false;

      this.setAddressUseList();
      //setTimeout(() => {
      //  this._chr.detectChanges();
      //}, 500);

    });

  }

  ngAfterViewInit() {
    
  }

  initControls() {
    this.aircraft_comm_edit_form = this._formBuilder.group({
      comm_address_type_select: { value: this.selectedCommAddressTypeId, disabled: this.isDisabled }, //this.commAddressTypeList[0].commAddressTypeGUID,
      address_rating_select: { value: this.selectedAddressRatingId, disabled: this.isDisabled }, //[this.addressRatingList[0].addressRatingGUID],
      comm_address_status: { value: this.isActive, disabled: this.isDisabled },
      address: [{ value: this.address, disabled: this.isDisabled }, Validators.required],
      extension: [{ value: this.extension, disabled: this.isDisabled }, Validators.pattern("^[0-9- ().]*$")],
      remarks: [{ value: this.remarks, disabled: this.isDisabled }],
      modifiedBy: [{ value: this.modifiedBy, disabled: this.isDisabled }],
      modifiedDate: [{ value: this.modifiedDate, disabled: this.isDisabled }],
      preferred: { value: this.isPreferred, disabled: this.isDisabled },
      data_entry_status: this.dataEntryStatusID

    });
  }

  get f() { return this.aircraft_comm_edit_form.controls; }

  addressChange() {
    this.errMsg = "";
    let typevalue = this.f.comm_address_type_select.value; //;this.aircraft_comm_edit_form.get("comm_address_type_select").value;
    let type = this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.f.comm_address_type_select.value)[0].commAddressTypeDesc; //this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.aircraft_comm_edit_form.get("comm_address_type_select").value)[0].commAddressTypeDesc;
    this.validateCommAddress(this.aircraft_comm_edit_form.get("address").value, type);
  }

  validateCommAddress(address: string, type: string) {
    switch (type.toLowerCase()) {
      case 'aftn':         
      case 'arinc':     
      case 'sita':
        this.f.address.setValue(this.f.address.value.toUpperCase());
        break;
    }
    let r = CustomValidators.validateCommonAddress(address, type);
    if (r == false) {
      switch (type.toLowerCase()) {
        case 'phone':
          this.errMsg = "Invalid phone number.";
          return;
        case 'mobile phone':
          this.errMsg = "Invalid mobile phone number.";
          return;
        case 'fax':
          this.errMsg = "Invalid fax number.";
          return;
        case 'email':
          this.errMsg = "Invalid email.";
          return;
        case 'aftn':
          this.errMsg = "AFTN must be 8 alphabets.";
          return;
        case 'arinc':
          this.errMsg = "ARINC must be 7 alphanumeric characters.";
          return;
        case 'sita':
          this.errMsg = "SITA must be 7 alphanumeric characters.";
          return;
      }
    }
  }

  commAddressTypeChange(e: any) {
    this.errMsg = "";
    let text = e.target.options[e.target.options.selectedIndex].text;
    this.selectedCommAddressType = text;
    this.f.extension.setValue('');
  }

  clickAddItem(e: any, item: AddressUseModel) {
    this.addressUseList.forEach(x => {
      if (x.addressUseGUID.toLowerCase() == item.addressUseGUID.toLowerCase()) {
        x.selected = true;
      }
    })
    this.setAddressUseList();

  }

  clickRemoveItem(e: any, item: AddressUseModel) {
    if (this.action == "add" || this.userType == "internal") { 
    this.addressUseList.forEach(x => {
      if (x.addressUseGUID.toLowerCase() == item.addressUseGUID.toLowerCase()) {
        x.selected = false;
      }
    })
    this.setAddressUseList();
  }
  }

  setAddressUseList() {
    this.errMsg = "";
    this.avaiAddressUseList = this.addressUseList.filter(u => u.selected == false);
    this.selAddressUseList = this.addressUseList.filter(u => u.selected == true);
  }

  clickSelectAll() {
    this.addressUseList.forEach(x => {
      x.selected = true;
    })

    this.setAddressUseList();
  }

  clickRemoveAll() {
    this.addressUseList.forEach(x => {
      x.selected = false;
    })

    this.setAddressUseList();
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    let type = this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.f.comm_address_type_select.value)[0].commAddressTypeDesc;
    if (this.f.address.value != "") {
      this.validateCommAddress(this.f.address.value, type);
    }   

    if (this.selAddressUseList.length == 0) {
      if (this.errMsg != "") {
        this.errMsg += "\n";
        this.errMsg += "You must select at least one address use.";
      }
      else
        this.errMsg = "You must select at least one address use.";
    }
    if (this.aircraft_comm_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    let obj = new PersonCommsAddressModel(); 
     obj.personCommsAddressGUID="";
    if (this.personCommsAddressGUID != "" && this.personCommsAddressGUID != null)
      obj.personCommsAddressGUID = this.personCommsAddressGUID;
    obj.commAddressTypeGUID = this.f.comm_address_type_select.value;
    obj.addressRatingGUID = this.f.address_rating_select.value;
    obj.addressUseGUIDList = Array.prototype.map.call(this.selAddressUseList, s => s.addressUseGUID).toString();
    obj.address = this.f.address.value;
    obj.extension = "";   
    if (type.toLowerCase() == "phone" && this.f.extension.value!="")
      obj.extension = this.f.extension.value;
    if (this.f.comm_address_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;
    obj.commRemarks = this.f.remarks.value;
    obj.personGUID = this.personId;
    if (this.f.preferred.value == "1")
      obj.isPreferred = true;
    else
      obj.isPreferred = false;
    if (this.f.data_entry_status.value != null)
      obj.dataEntryStatusID = this.f.data_entry_status.value;
    
    this._commonService.savePersonCommsAddress<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.personCommsAddressGUID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);          
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          //this._dialogRef.close(this.isModified);

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.avaiAddressUseList = [];
    this.selAddressUseList = [];
    this.selectedCommAddressType = "";
    this.selectedCommAddressTypeId = this.commAddressTypeList[0].commAddressTypeGUID;
    this.selectedCommAddressType = this.commAddressTypeList[0].commAddressTypeDesc;
    this.selectedAddressRatingId = this.addressRatingList[0].addressRatingGUID;
    this.address = "";
    this.extension = "";
    this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.personCommsAddressGUID = "";
    this.dialogTitle = "ADD New Record";
    this.isPreferred = 0;
    this.initControls();    
    this.addressUseList.forEach(x => {
      x.selected = false;
    })
    this.avaiAddressUseList = this.addressUseList.filter(u => u.selected == false);
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }


}
