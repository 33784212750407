<div *ngIf="upPII && grantAccessToManageManifestProfiles" style="width: 80em; padding-left: .5em; padding-right: .5em; padding-bottom: .5em; "
     [cdkDragDisabled]="isOpenedNew" cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle>
  <div *ngIf="isOpenedNew" mat-dialog-title>
    <div class="d-inline-flex"><div class="material-icons-outlined mr-1" style="margin-top:.25em">inventory</div><div>Manage Contact Details</div></div>
    <div *ngIf="customer" class="d-flex justify-content-between">
      <h4 style="margin-left: 1.25em; margin-top: -.5em; color: #146ec3">{{customer.customerName}}</h4>

      <div *ngIf="customer.customerGroup" class="alert-success px-2" style="font-size:small !important">
        <ng-template ngFor let-element [ngForOf]="customer.customerGroup" let-i="index">
          <div matTooltip="{{element.tooltip}}">Account Grouping - {{element.customerGroupName}} ({{element.customerList.length}})</div>
        </ng-template>
      </div>
    </div>
  </div>
  <div *ngIf="!isOpenedNew" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle mat-dialog-title>
    <div class="d-flex justify-content-between">
      <div class="d-inline-flex"><div class="material-icons-outlined mr-1" style="margin-top:.25em">inventory</div><div>Manage Contact Details</div></div>
      <div>
        <button mat-button class="btn-low" (click)="openSelection('Acct Setup')">Client Profile</button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;"
                (click)="openSelection('Client Information')">
          Client Information
        </button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;"
                (click)="openSelection('Aircraft')">
          Aircraft
        </button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;" (click)="openSelection('Admin Services')">
          Admin
          Services
        </button>
        <button mat-button class="btn-low" style="margin-left: 0.5em;">Feedback</button>
      </div>
      <div>
        <span class="material-icons-outlined pointer" (click)="openClientProfileSetupComponent()" *ngIf="userType=='internal'" style="margin-top:.5em;margin-right:.5em">arrow_circle_left</span>
        <mat-icon (click)="openInNew()" style="margin-top:.5em;margin-right:.5em;cursor:pointer">open_in_new</mat-icon>
        <mat-icon mat-dialog-close style="margin-top:.5em">close</mat-icon>
      </div>
    </div>
    <div *ngIf="customer" class="d-flex justify-content-between">
      <h4 style="margin-left:1.25em;margin-top:-.5em;color: #146ec3">{{customer.customerName}}</h4>
      <div *ngIf="customer.customerGroup" class="alert-success px-2" style="font-size:small !important">
        <ng-template ngFor let-element [ngForOf]="customer.customerGroup" let-i="index">
          <div matTooltip="{{element.tooltip}}">Account Grouping - {{element.customerGroupName}} ({{element.customerList.length}})</div>
        </ng-template>
      </div>
    </div>
  </div>
  <app-spin *ngIf="showSpin"></app-spin>

  <div style="background-color: white;padding:1em;margin-top:-1em">
    <form *ngIf="contact_details_form" [formGroup]="contact_details_form">
      <div style="width: 100%; margin-left:0em; padding:0em; display: inline-flex; justify-content: space-between;">
        <div style="display: flex;">
          <div *ngIf="userType=='customer' && grantAccessToAccountGrouping && customerList.length>0" class="form-group mr-2">
            <label for="customer_select" style="font-size:small">Select Client</label>
            <select *ngIf="f" formControlName="customer_select" class="form-control form-control-sm dropdown" (change)="customerChange($event)">
              <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
            </select>
          </div>
          <div class="form-group mr-2">
            <label for="search_options" style="font-size:small">Search options</label>
            <select *ngIf="f" formControlName="search_options" class="form-control form-control-sm dropdown">
              <option value="Name" selected>Name</option>
              <option value="Title">Title</option>
              <option value="Comms">Comms</option>
            </select>
          </div>
          <div class="form-group mr-2">
            <label for="text_search" style="font-size:small">Search by {{textSearchBy}}</label>
            <div class="input-group searchbar" style="width: 20em; height: 1.5625em; background-color: #eaf0fd;">
              <!--<div class="input-group-prepend searchbarleft">
                <span class="input-group-text material-icons-outlined searchbarleft">search</span>
              </div>-->
              <input type="text" formControlName="text_search" class="form-control form-control-sm searchbarinput"
                     (keyup.enter)="getPersonList()">
              <div>
                <span style="font-size: x-small; background-color: #eaf0fd;line-height:1;" class="input-group-text material-icons-outlined searchbarright pointer" (click)="clickReset()"
                      cdkOverlayOrigin #trigger="cdkOverlayOrigin">close</span>
              </div>
            </div>
          </div>
          <mat-divider [vertical]="true" class="mr-2"></mat-divider>
          <div class="form-group mr-2">
            <label for="trip_Code" style="font-size:small">Filter by Trip Code</label>
            <select *ngIf="f" formControlName="trip_code" class="form-control form-control-sm dropdown" (change)="getPersonList()">
              <option value="" selected>None</option>
              <option *ngFor="let trip of tripCodeList" [ngValue]="trip.tripCodeGUID">{{trip.tripCode}}</option>
            </select>
          </div>
          <mat-divider *ngIf="userType=='internal'" [vertical]="true" class="mr-2"></mat-divider>
          <div *ngIf="userType=='internal'" class="form-group form-inline align-top" style="margin-top:-1.5em">
            <label style="font-size:small;font-weight:bold">Filters</label>
            <mat-radio-group (change)="getPersonList()" class="form-control" [(ngModel)]="filterOption" [ngModelOptions]="{standalone: true}" style="display: flex; flex-direction: column; transform: scale(.8); align-items: flex-start;border:none!important;margin-left:-1em">
              <mat-radio-button [checked]="filterOption==1" [value]="1">Show Active Records</mat-radio-button>
              <mat-radio-button [checked]="filterOption==2" [value]="2">Data Entry Status</mat-radio-button>
              <mat-radio-button [checked]="filterOption==3" [value]="3">Show Disabled Records</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        
        <div class="form-inline" style="margin-top: 0.5em;">
          <button mat-button class="btn-med" (click)="openPersonAuditReport()">Audit Report</button>
        </div>
        <!--<div class="form-group" style="margin-left: 0.3125em;margin-top:1.25em">
          <button [disabled]="loading" mat-button class="btn-high" style="width: 5em;" (click)="getPersonList()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Search
          </button>&nbsp;
          <button type="button" mat-button class="btn-low" (click)="clickReset()" style="width: 5em">Reset</button>&nbsp;

        </div>-->
      </div>
      <div class="d-flex justify-content-between">
        <section>
          <mat-button-toggle-group [(ngModel)]="selectedPersonClass" [ngModelOptions]="{standalone: true}" (change)="getPersonList()">
            <mat-button-toggle *ngFor="let pc of personClassList; let i=index" [value]="pc">{{pc.personClassDescription}} {{pc.personClassCount}}</mat-button-toggle>
          </mat-button-toggle-group>
        </section>
        <label style="font-size:small;margin-right:.25em" *ngIf="recordList">
          Total Record Count: {{recordList.length}}
        </label>

      </div>
      <div style="overflow:auto" [ngClass]="{ 'popup-height' : !isOpenedNew }">

        <table mat-table [dataSource]="recordList" class="person-table">
          <tr>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef style="width:18%; border-bottom:none">
                <div style="margin-top:-0.25em;">Name</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.lastName}}, {{element.firstName}}&nbsp;{{element.middleName}}</td>
            </ng-container>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef style="width:17%; border-bottom:none">
                <div style="margin-top:-0.25em;">Title</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.jobTitle}}</td>
            </ng-container>
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef style="width:17%; border-bottom:none">
                <div style="margin-top:-0.25em;">Role</div>
              </th>
              <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.personRoleList}}</td>
            </ng-container>
            <ng-container matColumnDef="sync_count">
              <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none" [hidden]="!customer.customerGroup">
                <div style="margin-top:-0.25em;">Sync Count</div>
              </th>
              <td [hidden]="!customer.customerGroup" mat-cell *matCellDef="let element" [ngClass]="{'alert-warning': element.syncCount!=totalClientCount}">{{element.syncCount==totalClientCount? 'Shared' : element.syncCount}}</td>
            </ng-container>
            <ng-container matColumnDef="data_entry_status">
              <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                <div style="margin-top:-0.25em;">Data Entry Status</div>
              </th>
              <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger': element.dataEntryStatusID!=2}">{{element.dataEntryStatusDescription}}</td>
            </ng-container>


            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                <div style="margin-top:-0.25em;">Action</div><div><a mat-button class="btn-high grid" style="align-self:center" (click)="clickAdd();">Add</a></div>
              </th>
              <td mat-cell *matCellDef="let element">
                <a *ngIf="userType=='internal'" mat-button class="btn-low grid" style="align-self:center">
                  Edit
                </a>
                <a *ngIf="userType=='customer'" mat-button class="btn-low grid" style="align-self:center">
                  View
                </a>
              </td>
            </ng-container>
          </tr>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="clickEdit(row);" style="cursor: pointer"></tr>
        </table>
      </div>
    </form>
  </div>

</div>
