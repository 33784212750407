
<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="display: flex; justify-content: space-between;">
  <div style="padding-left:1em;margin-top:0.3125em;"><h5 class="mat-h2">Set Daily Hours</h5></div>

</div>
<div mat-dialog-content>
  <div style="padding:1em;background-color:white">
    <form [formGroup]="hours_edit_form">
      <div style="padding:1em">
        <mat-chip-list selectable multiple>
          <mat-chip mat-mini-fab #c="matChip" *ngFor="let days of hours" [value]="days" (click)="toggleSelection(c)">
            {{days.abbreviation}}
          </mat-chip>

        </mat-chip-list>
      </div>
      <div class="mt-2 ml-2 d-flex">
        <mat-checkbox (change)="toggleCheckBox('24',$event)" formControlName="is24Hours">24 Hours</mat-checkbox>
        <mat-checkbox (change)="toggleCheckBox('closed',$event)" formControlName="isClosed">Closed</mat-checkbox>
      </div>
      <div *ngIf="f.is24Hours.value==false && f.isClosed.value==false" class="d-flex mt-2 ml-3">
        <div>
          <label style="font-size:small">Open</label>
          <div style="margin-top:-.35em">
            <input type="text" formControlName="open_time" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.open_time.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('open_time', $event)" />
            </div>
          </div>
          <div class="ml-3">
            <label style="font-size:small">Close</label>
            <div style="margin-top:-.35em">
              <input type="text" formControlName="close_time" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.close_time.errors }" style="width:4em;" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('close_time', $event)" />
            </div>
          </div>
        </div>
    </form>
  </div>
</div>
<div class="pt-1">
  <button mat-button class="btn-high" (click)="clickSave()">SAVE</button>&nbsp;

  <button mat-button mat-dialog-close class="btn-low" (click)="clickClose()">CLOSE</button>&nbsp;

</div>
