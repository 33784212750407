export class TemplateModel {


  messageTemplateID: number;
  messageTemplateName: string;
  lk_ServiceTypeID: string;
  messageTemplateText: string;
  subjectLine: string;
  isActive: boolean;
  modifiedBy: string;
  serviceList: string;
  modifiedDate: string;
  isMultipleHandling: boolean;
  invalidKeywords: string[] = [];
  validKeywords: string[] = [];
  TemplateTxt: string;
  Template: string;
}
