import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CommAddressTypeModel } from '../models/comm-address-type.model';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { HotelChainModel } from '../models/hotel-chain.model';
import { CountryModel } from '../models/country.model';
import { StateProvinceModel } from '../models/state-province.model';
import { AirportModel } from '../models/airport.model';
import { AirportService } from '../services/airport.service';
import { ReturnObjModel } from '../models/return-obj.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { HotelModel } from '../models/hotel.model';
import { FuelSupplierModel } from '../models/fuelsupplier.model';

@Component({
  selector: 'app-hotel-edit-dialog',
  templateUrl: './hotel-edit-dialog.component.html',
  styleUrls: ['./hotel-edit-dialog.component.css']
})

export class HotelEditDialogComponent implements OnInit, AfterViewInit {
  
  hotel_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  commAddressTypeList: CommAddressTypeModel[];
  countryList: CountryModel[];
  hotelChainList: HotelChainModel[];
  stateProvinceList: StateProvinceModel[];
  selectedCountryGUID: string;
  selectedHotelChainGUID: string;
  hotelGUID: string;
  hotelName: string;
  street_address: string;
  //street_address2: string;
  //street_address3: string;
  city: string;
  state_province: string;
  zipcode: string;
  near_airport: string;
  distance_from_airport: number;
  remarks: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  selectedCommAddressType1: string;
  comm_addres1: string;
  extension1: number;
  selectedCommAddressType2: string;
  comm_addres2: string;
  extension2: number;
  selectedCommAddressType3: string;
  comm_addres3: string;
  extension3: number;
  validatedAirport: boolean = false;
  invalidCommAddress1ErrMsg: string;
  invalidCommAddress2ErrMsg: string;
  invalidCommAddress3ErrMsg: string;
  selectedCommAddressType1GUID: string;
  selectedCommAddressType2GUID: string;
  selectedCommAddressType3GUID: string;
  duplicateIATAErrMsg: string;
  wrongAirportErrMsg: string;
  controlValueChanged: boolean;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  constructor(private readonly _dialogRef: MatDialogRef<HotelEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _airportService: AirportService,
    private readonly _dialogService: DialogService
  ) {

      this.hotelGUID = _data.hotelGUID;
      this.selectedCountryGUID = _data.countryId;
      if (this.selectedCountryGUID != "") {
        this.selectedCountryGUID = this.selectedCountryGUID.toLowerCase();
      }
      this.selectedHotelChainGUID = _data.hotelChainId;
    if (this.selectedHotelChainGUID != "") {
      this.selectedHotelChainGUID = this.selectedHotelChainGUID.toLowerCase();
      }
  }

  getAllData(): Observable<any[]> {
    let getCommAddressTypeResponse = this._commonService.getCommAddressTypeList<ResponseModel<CommAddressTypeModel[]>>()
    let getHotelChainResponse = this._commonService.getHotelChainList<ResponseModel<HotelChainModel[]>>();
    let getCountryResponse = this._commonService.getCountryList<ResponseModel<CountryModel[]>>();
    let getStateProvinceResponse = this._commonService.getStateProvinceList<ResponseModel<StateProvinceModel[]>>('6AA9B42F-A693-4D74-83D8-A1500B0F1133');
    if (this.hotelGUID != null && this.hotelGUID != "") {
      let gethotelResponse = this._commonService.getHotelByhId(this.hotelGUID);
      return forkJoin([getCommAddressTypeResponse, getHotelChainResponse, getCountryResponse, getStateProvinceResponse, gethotelResponse]);
    }
    else {
      return forkJoin([getCommAddressTypeResponse, getHotelChainResponse, getCountryResponse, getStateProvinceResponse]);
    }
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.hotelName = "";
    this.street_address="";
    //this.street_address2="";
    //this.street_address3="";
    this.near_airport = "";
    this.distance_from_airport = null;
    this.city="";
    this.state_province="";
    this.zipcode="";
    this.countryList = [];
    this.hotelChainList = [];
    this.commAddressTypeList = [];
    this.stateProvinceList = [];
    this.selectedCommAddressType1 = "";
    this.comm_addres1 = "";
    this.extension1 = null;
    this.selectedCommAddressType2 = "";
    this.comm_addres2 = "";
    this.extension2 = null;
    this.selectedCommAddressType3 = "";
    this.comm_addres3 = "";
    this.extension3 = null;
    this.invalidCommAddress1ErrMsg = "";
    this.invalidCommAddress2ErrMsg = "";
    this.invalidCommAddress3ErrMsg = "";
    this.selectedCommAddressType1GUID = "";
    this.selectedCommAddressType2GUID = "";
    this.selectedCommAddressType3GUID = "";
    this.duplicateIATAErrMsg = "";
    this.wrongAirportErrMsg = "";
    this.controlValueChanged = false;
    this.showSuccessMsg = false;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.commAddressTypeList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.hotelChainList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.countryList = responses[2].model;
          
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.stateProvinceList = responses[3].model;
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.hotelGUID != null && this.hotelGUID != "") {
        if (responses[4] != null) {
          if (responses[4].code == "200" && responses[4].message == "") {
            let h = new HotelModel();
            h = responses[4].model;
            this.hotelName = h.hotelName;
            this.selectedHotelChainGUID = h.hotelChainGUID;
            this.street_address = h.streetAddress;
            this.city = h.city;
            this.state_province =h.state_Province;
            this.zipcode = h.postalCode;
            this.selectedCountryGUID = h.countryGUID.toLowerCase();
            this.remarks = h.remarks;
            this.near_airport = h.icao;
            if (h.distanceFromAirport > 0) {
              this.distance_from_airport = h.distanceFromAirport;
            }
            this.selectedCommAddressType1GUID = h.commAddressType1GUID.toLowerCase();
            this.comm_addres1 = h.commAddress1;
            if (h.extension1 != "") {
              this.extension1 = Number(h.extension1);
            }
            if (h.commAddressType2GUID != "") {
              this.selectedCommAddressType2GUID = h.commAddressType2GUID.toLowerCase();
            }           
            if (h.commAddress2 != "") {
              this.comm_addres2 = h.commAddress2;
            }
            if (h.extension2 != "") {
              this.extension2 = Number(h.extension2);
            }
            if (h.commAddressType3GUID != "") {
              this.selectedCommAddressType3GUID = h.commAddressType3GUID.toLowerCase();
            }
            if (h.commAddress3 != "") {
              this.comm_addres3 = h.commAddress3;
            }
            if (h.extension3 != "") {
              this.extension3 = Number(h.extension3);
            }

            this.modifiedBy = h.modifiedBy;
            this.modifiedDate = h.modifiedDate;
            if (h.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
            
          }
          else {
            if (responses[4].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }
      
      if (this.selectedCommAddressType1GUID == "") {
        this.selectedCommAddressType1GUID = this.commAddressTypeList[0].commAddressTypeGUID.toLowerCase();
        this.selectedCommAddressType1 = this.commAddressTypeList[0].commAddressTypeDesc;
      }
      if (this.selectedCommAddressType2GUID == "") {
        this.selectedCommAddressType2GUID = this.commAddressTypeList[0].commAddressTypeGUID.toLowerCase();
        this.selectedCommAddressType2 = this.commAddressTypeList[0].commAddressTypeDesc;
      }
      if (this.selectedCommAddressType3GUID == "") {
        this.selectedCommAddressType3GUID = this.commAddressTypeList[0].commAddressTypeGUID.toLowerCase();
        this.selectedCommAddressType3 = this.commAddressTypeList[0].commAddressTypeDesc;
      }
      this.initControls();

      this.checkFormControlValueChanged();

      this.showSpin = false;

    

    });

  }

  ngAfterViewInit() {

  }

  initControls() {
    this.hotel_edit_form = this._formBuilder.group({
      hotelName: [this.hotelName, Validators.required],
      hotel_chain_select: [this.selectedHotelChainGUID, Validators.required],
      street_address: [this.street_address, Validators.required],  
      city: [this.city, Validators.required],
      state_province: [this.state_province],
      zipcode: [this.zipcode],
      country_select: [this.selectedCountryGUID, Validators.required],
      near_airport: [this.near_airport, Validators.compose([
        Validators.required,
        Validators.pattern("^[-a-zA-Z0-9\*]*$")]
      )],
      distance_from_airport: [this.distance_from_airport],
      hotel_status: [this.isActive],
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      remarks: [this.remarks],
      comm_address_type1_select: [this.selectedCommAddressType1GUID, Validators.required],
      comm_address1: [this.comm_addres1, Validators.required],
      extension1: [this.extension1],
      comm_address_type2_select: [this.selectedCommAddressType2GUID],
      comm_address2: [this.comm_addres2],
      extension2: [this.extension2],
      comm_address_type3_select: [this.selectedCommAddressType3GUID],
      comm_address3: [this.comm_addres3],
      extension3: [this.extension3],
    });
  }

  get f() { return this.hotel_edit_form.controls; }

  checkFormControlValueChanged(): void {
    this.hotel_edit_form.get("hotelName").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("hotel_chain_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("street_address").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("city").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("state_province").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("zipcode").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("country_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("near_airport").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("distance_from_airport").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("hotel_status").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("remarks").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("comm_address_type1_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("comm_address1").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("extension1").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("comm_address_type2_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("comm_address2").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("extension2").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("comm_address_type3_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("comm_address3").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.hotel_edit_form.get("extension3").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
  }

  addressChange(ctlNumner: number) {
    let type = "";
    switch (ctlNumner) {
      case 1:
        type = this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.f.comm_address_type1_select.value)[0].commAddressTypeDesc;
        if (this.f.comm_address1.value != "") {
          if (this.f.comm_address1.value.trim() != "") {
            this.validateCommAddress(ctlNumner, this.f.comm_address1.value.trim(), type);
          }
        }
        
        break;
      case 2:
        type = this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.f.comm_address_type2_select.value)[0].commAddressTypeDesc;
        if (this.f.comm_address2.value != "") {
          if (this.f.comm_address2.value.trim() != "") {
            this.validateCommAddress(ctlNumner, this.f.comm_address2.value.trim(), type);
          }
        }
        //this.validateCommAddress(ctlNumner, this.f.comm_address2.value, type);
        break;
      case 3:
        type = this.commAddressTypeList.filter(v => v.commAddressTypeGUID == this.f.comm_address_type3_select.value)[0].commAddressTypeDesc;
        if (this.f.comm_address3.value != "") {
          if (this.f.comm_address3.value.trim() != "") {
            this.validateCommAddress(ctlNumner, this.f.comm_address3.value.trim(), type);
          }
        }
        //this.validateCommAddress(ctlNumner, this.f.comm_address3.value, type);
        break;
    }
    
  }

  validateCommAddress(ctlNumner: number, address: string, type: string) {
    switch (type.toLowerCase()) {
      case 'aftn':
      case 'arinc':
      case 'sita':
        switch (ctlNumner) {
          case 1:
            this.f.comm_address1.setValue(this.f.comm_address1.value.toUpperCase());
            break;
          case 2:
            this.f.comm_address2.setValue(this.f.comm_address2.value.toUpperCase());
            break;
          case 3:
            this.f.comm_address3.setValue(this.f.comm_address3.value.toUpperCase());
            break;
        }
        
        break;
    }

    let r = CustomValidators.validateCommonAddress(address, type);
    if (r == false) {
      switch (type.toLowerCase()) {
        case 'office phone':
        case 'home phone':
        case 'mobile phone':
          
          switch (ctlNumner) {
            case 1:
              this.invalidCommAddress1ErrMsg = "Invalid phone number.";
              this.f.comm_address1.setErrors({ invalidComm: true });
              break;
            case 2:
              this.invalidCommAddress2ErrMsg = "Invalid phone number.";
              this.f.comm_address2.setErrors({ invalidComm: true });
              break;
            case 3:
              this.invalidCommAddress3ErrMsg = "Invalid phone number.";
              this.f.comm_address3.setErrors({ invalidComm: true });
              break;
          }
          
          break;
        case 'fax':
          
          switch (ctlNumner) {
            case 1:
              this.invalidCommAddress1ErrMsg = "Invalid fax number.";
              this.f.comm_address1.setErrors({ invalidComm: true });
              break;
            case 2:
              this.invalidCommAddress2ErrMsg = "Invalid fax number.";
              this.f.comm_address2.setErrors({ invalidComm: true });
              break;
            case 3:
              this.invalidCommAddress3ErrMsg = "Invalid fax number.";
              this.f.comm_address3.setErrors({ invalidComm: true });
              break;
          }
          
          break;
        case 'email':
          
          switch (ctlNumner) {
            case 1:
              this.invalidCommAddress1ErrMsg = "Invalid email.";
              this.f.comm_address1.setErrors({ invalidComm: true });
              break;
            case 2:
              this.invalidCommAddress2ErrMsg = "Invalid email.";
              this.f.comm_address2.setErrors({ invalidComm: true });
              break;
            case 3:
              this.invalidCommAddress3ErrMsg = "Invalid email.";
              this.f.comm_address3.setErrors({ invalidComm: true });
              break;
          }
          
          break;
        case 'aftn':
          
          switch (ctlNumner) {
            case 1:
              this.invalidCommAddress1ErrMsg = "AFTN must be 8 alphabets.";
              this.f.comm_address1.setErrors({ invalidComm: true });
              break;
            case 2:
              this.invalidCommAddress2ErrMsg = "AFTN must be 8 alphabets.";
              this.f.comm_address2.setErrors({ invalidComm: true });
              break;
            case 3:
              this.invalidCommAddress3ErrMsg = "AFTN must be 8 alphabets.";
              this.f.comm_address3.setErrors({ invalidComm: true });
              break;
          }
          
          break;
        case 'arinc':
          
          switch (ctlNumner) {
            case 1:
              this.invalidCommAddress1ErrMsg = "ARINC must be 7 alphanumeric characters.";
              this.f.comm_address1.setErrors({ invalidComm: true });
              break;
            case 2:
              this.invalidCommAddress2ErrMsg = "ARINC must be 7 alphanumeric characters.";
              this.f.comm_address2.setErrors({ invalidComm: true });
              break;
            case 3:
              this.invalidCommAddress3ErrMsg = "ARINC must be 7 alphanumeric characters.";
              this.f.comm_address3.setErrors({ invalidComm: true });
              break;
          }
          
          break;
        case 'sita':
          
          switch (ctlNumner) {
            case 1:
              this.invalidCommAddress1ErrMsg = "SITA must be 7 alphanumeric characters.";
              this.f.comm_address1.setErrors({ invalidComm: true });
              break;
            case 2:
              this.invalidCommAddress2ErrMsg = "SITA must be 7 alphanumeric characters.";
              this.f.comm_address2.setErrors({ invalidComm: true });
              break;
            case 3:
              this.invalidCommAddress3ErrMsg = "SITA must be 7 alphanumeric characters.";
              this.f.comm_address3.setErrors({ invalidComm: true });
              break;
          }
          
          break;
      }
    }
  }

  commAddressTypeChange(e: any, ctlNumber: number) {
    this.errMsg = "";
    let text = e.target.options[e.target.options.selectedIndex].text; 
    switch (ctlNumber) {
      case 1:
        this.selectedCommAddressType1 = text;
        break;
      case 2:
        this.selectedCommAddressType2 = text;
        break;
      case 3:
        this.selectedCommAddressType3 = text;
        break;
    }
    
  }

  icaoChange(e: any) {
    if (e.target.value != "") {      
      this.validateAirport().subscribe(res => {
        if ((res != null || res != undefined) && res == true) {

        }

      });

    }
  }

  validateAirport(): Observable<boolean> {  
    return new Observable<boolean>(ob => {
      this._authService.updateAccessTime();
      this.duplicateIATAErrMsg = "";
      this.wrongAirportErrMsg = "";
      let request = new AirportModel();
      request.route = this.f.near_airport.value;
      try {
        this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            let ap: AirportModel[];
            ap= response.model;
            if (ap[0].wrongAirportErrMsg == "" && ap[0].duplicateIATAErrMsg == "") {
              ap.forEach(x => {
                this.f.near_airport.setValue(x.icao);
                
              });
              this.validatedAirport = false;

            }
            if (ap[0].wrongAirportErrMsg != "") {
              this.wrongAirportErrMsg = "Invalid airport";// + this.airportList[0].wrongAirportErrMsg;
              this.f.near_airport.setErrors({ wrongAirportValidate: true });
              this.validatedAirport = true;
            }
            if (ap[0].duplicateIATAErrMsg != "") {
              this.duplicateIATAErrMsg = "Duplicated IATAs: " + ap[0].duplicateIATAErrMsg + ". Please change to use ICAO.";
              this.f.near_airport.setErrors({ duplicateIATAValidate: true });
              this.validatedAirport = true;
            }
            if (this.wrongAirportErrMsg != "" || this.duplicateIATAErrMsg != "") {
              ob.next(false);
            }
            else {
              ob.next(true);
            }
          }
          else {
            if (response.code == "401") {
              //this.errMsg = response.message
              this._authService.signOut();
            }
            else {
              ob.next(null);
            }
          }
        })
      }
      catch (error) {
        ob.next(null);
      }

    });

  }

  countryOnchange(e: any) {
    this.selectedCountryGUID = this.f.country_select.value;
  }

  clickSave(actionName: string) {
    
    //if (this.loading) {
    //  return;
    //}
    if (this.f.near_airport.value != "") {
      this.validateAirport().subscribe(res => {
        if ((res != null || res != undefined) && res == true) {
          this.clickSaveNext(actionName);
        }
      });
    }
    else {
      this.clickSaveNext(actionName);
    }
  }

  clickSaveNext(actionName: string) {
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;

    if (this.f.hotelName.value.trim() == "") {
      this.f.hotelName.setErrors({ required: true });    
    }
    if (this.f.city.value.trim() == "") {
      this.f.city.setErrors({ required: true });
    }
    if (this.f.street_address.value.trim() == "") {
      this.f.street_address.setErrors({ required: true });
    }
    if (this.f.comm_address1.value.trim() == "") {
      this.f.comm_address1.setErrors({ required: true });
    }
    
    if (this.hotel_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    let obj = new HotelModel();

    obj.hotelGUID = this.hotelGUID;
    obj.hotelName = this.f.hotelName.value.trim();
    obj.hotelChainGUID = this.f.hotel_chain_select.value;
    obj.streetAddress = this.f.street_address.value.trim();
    obj.city = this.f.city.value.trim();
    obj.state_Province = "";
    if (this.f.state_province.value != "") {
      obj.state_Province = this.f.state_province.value.trim();
    }
    obj.postalCode = "";
    if (this.f.zipcode.value != "") {
      obj.postalCode = this.f.zipcode.value.trim();
    }
    obj.countryGUID = this.f.country_select.value;
    obj.icao = this.f.near_airport.value;
    obj.distanceFromAirport = 0;
    if (this.f.distance_from_airport.value != "" && this.f.distance_from_airport.value!=null) {
      obj.distanceFromAirport = Number(this.f.distance_from_airport.value);
    }
    obj.isActive = true;
    if (obj.hotelGUID != "") {
      if (this.f.hotel_status.value == "1")
        obj.isActive = true;
      else
        obj.isActive = false;
    }
    obj.remarks = "";
    if (this.f.remarks.value != "") {
      obj.remarks = this.f.remarks.value.trim();
    }
    obj.commAddressType1GUID = this.f.comm_address_type1_select.value;
    obj.commAddress1 = this.f.comm_address1.value.trim();
    obj.extension1 = "";
    let type1 = this.commAddressTypeList.find(x => x.commAddressTypeGUID.toLowerCase() == obj.commAddressType1GUID.toLowerCase()).commAddressTypeDesc;
    if (type1.toLowerCase() == "office phone") {
      if (this.f.extension1.value != "" && this.f.extension1.value!=null)
        obj.extension1 = this.f.extension1.value.toString();
    }
    obj.commAddressType2GUID = "";
    obj.commAddress2 = "";
    obj.extension2 = "";
    if (this.f.comm_address2.value != "") {
      obj.commAddressType2GUID = this.f.comm_address_type2_select.value;
      obj.commAddress2 = this.f.comm_address2.value.trim();
      let type2 = this.commAddressTypeList.find(x => x.commAddressTypeGUID.toLowerCase() == obj.commAddressType2GUID.toLowerCase()).commAddressTypeDesc;
      if (type2.toLowerCase() == "office phone") {
        if (this.f.extension2.value != "" && this.f.extension2.value!=null)
          obj.extension2 = this.f.extension2.value;
      }
    }
    obj.commAddressType3GUID = "";
    obj.commAddress3 = "";
    obj.extension3 = "";
    if (this.f.comm_address3.value != "") {
      obj.commAddressType3GUID = this.f.comm_address_type3_select.value;
      obj.commAddress3 = this.f.comm_address3.value.trim();
      let type3 = this.commAddressTypeList.find(x => x.commAddressTypeGUID.toLowerCase() == obj.commAddressType3GUID.toLowerCase()).commAddressTypeDesc;
      if (type3.toLowerCase() == "office phone") {
        if (this.f.extension3.value != "" && this.f.extension3.value!=null)
          obj.extension3 = this.f.extension3.value;
      }
    }
  
    this._commonService.saveHotel<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.hotelGUID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);     
          this.controlValueChanged = false;       
          let returnResponse = new ReturnObjModel();
          returnResponse.refresh = true;
          returnResponse.newId = this.hotelGUID;
          returnResponse.hotelChainGUID = this.f.hotel_chain_select.value;
          returnResponse.countryGUID = this.f.country_select.value;
          this.showSuccessMsg = true;
          if (actionName == '') {
            //this._dialogService.openAlertDialog("");   
            setTimeout(() => {
              this.showSuccessMsg = false;
              this._dialogRef.close(returnResponse);
            }, 1000);
          }
          else
            this._dialogRef.close(returnResponse);

        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg = "Failed to save the record. Please try again later.";
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  

  clickClose() {

    let response = new ReturnObjModel();
    response.refresh = false;
    
    if (this.controlValueChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before close the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave('close');
        }
        else {
          this._dialogRef.close(response);
        }
      });

    }
    else {
      this._dialogRef.close(response);
    }
    

    //this._dialogRef.close(this.isModified);
  }


}
