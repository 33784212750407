<div style=" width: 500px; margin: -1em;">
  <!--background-color: #f8f8f8;-->
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left: 1em; display: flex; ">
      <span style="margin-top: 0.2em;" class="material-symbols-outlined">
        monetization_on
      </span><h5 class="mat-h2" style="margin-bottom: 0.2em;">{{dialogTitle}} Exchange Rate Markup  </h5>
    </div>

  </div>

  <div>
    <form *ngIf="fuel_Markup_exchange_edit" [formGroup]="fuel_Markup_exchange_edit" novalidate>
      <div style=" margin-top:0em; padding-left: 1em;padding-right:1em">
        <div style="background-color:white;padding:.5em;">
          <div style="display:flex; margin: 0.5em 0 1em;">
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;display: flex;">
              <label for="clientid" style="font-size:small">Client</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em; ">
                <select formControlName="clientid" class="form-control form-control-sm dropdown" style=" width: 24em;  margin: 0.5em 0.5em 0.5em 1em;">
                  <option value="0">All</option>
                  <option *ngFor="let customer of customerList" [value]="customer.customerID">{{customer.customerName}}</option>
                </select>

                <div *ngIf="submitted && f.clientid.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.clientid.errors.required">Please Select</div>
                </div>
              </div>

            </div>
          </div>

         
          <div style="margin-top: -0.5em;">
            <div style="background-color: #f8f8f8; border-radius: .25em; width: 100%; margin-top: 1em;">

              <div>
                <label style="font-size:small">Select Country or Aiport</label>
              </div>
              <div style="display:flex">

                <div>
                  <mat-radio-group [(ngModel)]="iscountryorairport" style="display:flex;flex-direction:column;transform:scale(.8);" [ngModelOptions]="{standalone: true}">
                    <mat-radio-button [checked]="iscountryorairport=='country'" value="country">Country</mat-radio-button>
                    <mat-radio-button [checked]="iscountryorairport=='airport'" value="airport">Airport</mat-radio-button>
                  </mat-radio-group>
                </div>


                <div class="ml-2" style="padding-top: 1em;">

                  <div *ngIf="iscountryorairport=='country'" class="col">
                    <div style="margin-top:-0.35em;">

                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <select [disabled]="showModified" class=" form-control form-control-sm dropdown"
                                style="width: 24.6em;" formControlName="countryid" (change)="countrychange()">

                          <option value="0">All</option> 
                          <option *ngFor="let country of countryList" [value]="country.countryID">{{ country.countryName}}</option>
                        </select>

                        <div *ngIf="submitted && f.countryid.errors" class="alert alert-danger p-0 small">
                          <div *ngIf="f.countryid.errors.required">Please Select</div>
                        </div>
                      </div>

                    </div>
                    <span style="  font-size: x-small;">**Rates are applicable to <b>All Airports</b>  in the <b>Selected country</b>.</span>

                  </div>
                  <div class="col" *ngIf="iscountryorairport=='airport'" style="display: flex; margin-top: -0.7em;">
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                      <label for="icao" style="font-size:small">ICAO</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <input type="text" formControlName="icao"
                               class="form-control form-control-sm inputBox" maxlength="255" style="width:6em" (keyup)="icaoValidation($event,'icao')" /><!--[ngClass]="{ 'is-invalid': submitted && f.icao.errors }"-->

                      </div>
                      <div *ngIf="submitted && f.icao.errors" class="alert alert-danger p-0 small">
                        <div *ngIf="f.icao.errors.required">Required.</div>
                      </div>
                    </div>
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0em;">
                      <label for="iata" style="font-size:small">IATA</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                        <input type="text" formControlName="iata"
                               class="form-control form-control-sm inputBox" maxlength="255" style="width:6em" (keyup)="icaoValidation($event,'iata')" />
                       
                      </div>
                      <div *ngIf="submitted && f.iata.errors" class="alert alert-danger p-0 small">
                        <div *ngIf="f.iata.required">Required.</div>
                      </div>
                    </div>
                    <div *ngIf="countrylength>0" style=" padding-top: 1.2em; margin-left: -0.3em;">
                      <div style="background-color:#f8f8f8;border-radius:.25em" class="d-inline-flex ml-2 p-1">
                        <label style="font-size:small">Country:  <b>{{countryName}}</b></label>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div style="display: flex; margin-bottom: 0em; margin-top: 0.5em;">
              <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                <label for="markupAmount" style="font-size:small">Markup Percent</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                  <input type="number" class="form-control form-control-sm inputBox" placeholder="2% - 10% Only" style="width: 7.5em;" appDecimal [decimalPlaces]="4" formControlName="markupAmount" />
                </div>
                <div *ngIf="submitted && f.markupAmount.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.markupAmount.errors.required">Required</div>
                </div>
              </div>
              <div class="form-group" style="margin-top:0em; margin-right:0.625em; margin-left: 2.5em;">
                <label for="effectivedate" style="font-size:small">Effective Date</label>
                <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                  <input type="date" class="form-control form-control-sm inputBox" formControlName="effectivedate" />
                </div>
                <div *ngIf="submitted && f.effectivedate.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.effectivedate.errors.required">Please Select</div>
                </div>
              </div>

              <div style="display:flex; margin: 0.8em;  margin-left: 2em;">

                <div style="display: flex; margin-top: 0.6em;">
                  <label for="isActive" style="font-size:small">Active</label>
                  <div class="form-group" style="margin: 0em 0 0 0.5em;">

                    <select formControlName="isActive" class="form-control form-control-sm dropdown">
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
              </div> 

            </div>
 
            <div *ngIf="f.expirydate.errors && f.expirydate.errors.matEndDateInvalid" style="text-align: -webkit-right;">
            <div  class="alert-danger p-0" style="font-size: small; width: 58%; margin-right: 1em;">Expiry date cannot be before effective date.</div>
            </div>

          </div>
          <!-- <div style="display:flex">

            <div style="margin-left: 0em; margin-top: 0em; margin-right: 0.625em; width: 100%;">
              <label for="internalnotes" style="font-size:small">Internal Notes</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <textarea formControlName="internalnotes" class="form-control" maxlength=255 style="resize: none; height: 8em; font-size: small; padding: 0em 0em 0em 0.25em; background-color: #eaf0fd;  "></textarea>
              </div>
            </div>
          </div> -->



        </div>


        <div style="display: flex; margin-top: 0.5em;">
          <div class="d-inline-flex">
            <div style="justify-content: flex-start; padding-left: 0em; padding-top: 0.5em; padding-bottom: 0.5em; display: flex; height: 2.7em;">

              <button formcontrolname="savebutton" [disabled]="!controlValueChanged   ? 'disabled' : null" mat-button class="btn-high" (click)="clickSave()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Save
              </button>&nbsp;
              <button mat-button class="btn-low" (click)="clickClose()">
                <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;" class="material-symbols-outlined">close</span>CLOSE
              </button>&nbsp;


            </div>
          </div>


          <div *ngIf="isExistingPrice">


            <div style="display: inline-block; ">
              <div style="border-radius:.25em" class="d-inline-flex ml-2 p-1;">

                <div   style="text-align: left; margin: 0em;">
                  <!--*ngIf="showModified"-->
                  <div style="display: flex; width: 10em;">
                    <div> <label for="modifiedBy" style="font-size: x-small;">Modified By : </label></div>
                    <div>
                      <div  >
                        <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width: 8em; margin: 0.5em; height: 1.5625em; font-size: x-small; padding: 0em; border: none; background-color: transparent;" />
                      </div>
                    </div>
                  </div>
                  <div  style="display: flex; width: 10em; margin: -0.5em 0;">
                    <div><label for="modifiedDate" style="font-size: x-small;">Modified Date : </label></div>
                    <div>
                      <div  >
                        <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width: 8em; margin: 0.5em; height: 1.5625em; font-size: x-small; padding: 0em; border: none; background-color: transparent;" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div class="mt-2">
          <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display: inline-block; white-space: pre-wrap;">
            <label style="font-size: small; padding: 0em; margin: 0em;">{{errMsg}}</label>
          </div>
          <div style="font-size:small" *ngIf="showDuplicateText"><span>** Add more tiers for the same Client if needed</span></div>
          <div *ngIf="showSuccessMsg">
            <app-success-message [successMsg]="successMsg"></app-success-message>
          </div>
        </div>
      </div>
    </form>

  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>





