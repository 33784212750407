import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { AircraftModel } from '../models/aircraft.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { PersonModel } from '../models/person.model';
import { CustomerAccountingProfileModel } from '../models/customer-accounting-profile.model';
import { PersonService } from '../services/person.service';


@Component({
  selector: 'app-customer-accounting-profile-edit-dialog',
  templateUrl: './customer-accounting-profile-edit-dialog.component.html',
  styleUrls: ['./customer-accounting-profile-edit-dialog.component.css']
})
/** customer-government-service-edit-dialog component*/
export class CustomerAccountingProfileEditDialogComponent {

  @Input() dialogTitle: string;
  @Input() customerAccountingProfileGUID: string;
  @Input() customerGUID: string;
  @Input() acctType: string;
  customer_accounting_profile_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  aircraftList: AircraftModel[];
  serviceList: ServiceTypeModel[];
  crewList: PersonModel[];
  paxList: PersonModel[]
  selectedAircraftGUID: string;
  selectedServiceID: number;
  selectedCrewID: string;
  selectedPaxID: string;
  remarks: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  billToCode: string;
  billing_account_name: string;
  reference_po: string;
  effectiveDate: Date;
  expirationDate: Date;
  isModified: boolean = false;
  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;
  constructor(private readonly _dialogRef: MatDialogRef<CustomerAccountingProfileEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService, private readonly _personService: PersonService,
    private readonly _customerService: CustomerService,
    private readonly _dialogService: DialogService
    ) {
   // this.customerGovernmentServiceID = data.customerGovernmentServiceID;
    this.customerGUID = data.customerGUID;
    this.dialogTitle = data.dialogTitle;
    this.customerAccountingProfileGUID = data.customerAccountingProfileGUID;
    this.acctType = data.acctType;
  }

  getAllData(): Observable<any[]> {
    let getAircraftResponse = this._customerService.getRegistrationListByCustomerGUID(this.customerGUID);
    let getServiceTypeResponse = this._commonService.getServiceTypeList();
    let getCrewListResponse = this._personService.getPersonListByCustomerGUID(this.customerGUID, true);
    let getPaxListResponse = this._personService.getPersonListByCustomerGUID(this.customerGUID, false);
    if (this.customerAccountingProfileGUID != null && this.customerAccountingProfileGUID != "") {
      let getCustomerAccountingProfileResponse = this._customerService.getCustomerAccountingProfileBycapId(this.customerAccountingProfileGUID);
      return forkJoin([getAircraftResponse, getServiceTypeResponse, getCrewListResponse, getPaxListResponse, getCustomerAccountingProfileResponse]);
    }
    else
      return forkJoin([getAircraftResponse, getServiceTypeResponse, getCrewListResponse, getPaxListResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.selectedCrewID = "";
    this.selectedPaxID = "";
    this.selectedAircraftGUID = "";
    this.selectedServiceID = 0;
    this.remarks="";
    this.modifiedBy="";
    this.modifiedDate="";
    this.isActive = 1;
    this.effectiveDate = null;
    this.expirationDate = null;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.aircraftList = responses[0].model;
         
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
       
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.serviceList = responses[1].model;         
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.crewList = responses[2].model;        
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.paxList = responses[3].model;
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.customerAccountingProfileGUID != null && this.customerAccountingProfileGUID != "") {
        if (responses[4] != null) {
          if (responses[4].code == "200" && responses[4].message == "") {
            let customerAccountingProfile = new CustomerAccountingProfileModel();
            customerAccountingProfile = responses[4].model;
            this.billToCode = customerAccountingProfile.billToID;
            this.billing_account_name = customerAccountingProfile.billTo_AccountName;
            this.reference_po = customerAccountingProfile.referencePO;
            this.selectedAircraftGUID = customerAccountingProfile.aircraftID;
            this.selectedServiceID = customerAccountingProfile.servicesID;
            this.selectedCrewID = customerAccountingProfile.crewID;
            this.selectedPaxID = customerAccountingProfile.paxID;
            if (customerAccountingProfile.effectiveDate != null && customerAccountingProfile.effectiveDate != "")
              this.effectiveDate = new Date(customerAccountingProfile.effectiveDate);
            if (customerAccountingProfile.expirationDate != null && customerAccountingProfile.expirationDate != "")
              this.expirationDate = new Date(customerAccountingProfile.expirationDate);
            this.remarks = customerAccountingProfile.remarks;
            this.modifiedBy = customerAccountingProfile.modifiedBy;
            this.modifiedDate = customerAccountingProfile.modifiedDate;
            if (customerAccountingProfile.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
               
          }
          else {
            if (responses[4].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }
       
      this.initControls();
      
      this.showSpin = false;


    });

  }

  ngAfterViewInit() {
    
  }

  initControls() {
    this.customer_accounting_profile_edit_form = this._formBuilder.group({
      registration: this.selectedAircraftGUID,
      effectiveDate: [this.effectiveDate],
      expirationDate: [this.expirationDate],
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate,
      bill_to_status: this.isActive,
      billing_account_name: this.billing_account_name,
      bill_to_code: this.billToCode,
      remarks: this.remarks,
      crew: this.selectedCrewID,
      pax: this.selectedPaxID,
      service_type: this.selectedServiceID,
      reference_po: this.reference_po

      
    });
   
  }

  get f() { return this.customer_accounting_profile_edit_form.controls; }

  clickSave() {
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;
    this.loading = true;
    if (this.acctType == "refPO") { 
    if (this.effectiveDateRef.nativeElement.value !== "") {
      if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.effectiveDate.setErrors({ isValidDate: f1 });

        }
      }
      else {
        this.f.effectiveDate.setErrors({ isValidDate: false });

      }
    }

    if (this.expirationDateRef.nativeElement.value !== "") {
      if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null) {
        let s2 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
        let f2 = CustomValidators.isValidDate(s2);
        if (!f2) {
          this.f.expirationDate.setErrors({ isValidDate: f2 });

        }
      }
      else {
        this.f.expirationDate.setErrors({ isValidDate: false });

      }
    }
  }
    
    let obj = new CustomerAccountingProfileModel(); 
     obj.customerAccountingProfileGUID=null;
    if (this.customerAccountingProfileGUID != "" && this.customerAccountingProfileGUID != null)
      obj.customerAccountingProfileGUID = this.customerAccountingProfileGUID;
    obj.billToID = this.f.bill_to_code.value;
    obj.referencePO = this.f.reference_po.value;
    obj.effectiveDate = "";
    if (this.f.effectiveDate.value != null) {
      obj.effectiveDate = this.effectiveDateRef.nativeElement.value;
    }
    obj.expirationDate = "";
    if (this.f.expirationDate.value != null) {
      obj.expirationDate = this.expirationDateRef.nativeElement.value;
    }
    obj.billTo_AccountName = this.f.billing_account_name.value;
    if (this.f.registration.value!="")
      obj.aircraftID = this.f.registration.value;
    if (this.f.crew.value != "")
      obj.crewID = this.f.crew.value;
    if (this.f.pax.value != "")
      obj.paxID = this.f.pax.value;
    obj.servicesID = this.f.service_type.value;
    obj.remarks = this.f.remarks.value;
    obj.customerGUID = this.customerGUID;
    
    if (this.f.bill_to_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;

    
    
    this._customerService.saveCustomerAccountingProfile<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.customerAccountingProfileGUID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this._dialogService.openAlertDialog("Your information has been submitted successfully.");          
          this.isModified = true;        

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
         
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.billing_account_name = "";
    this.billToCode = ""
    this.customerAccountingProfileGUID = "";
    this.effectiveDate = null;
    this.expirationDate = null;
    this.reference_po = "";
    this.selectedAircraftGUID = "";
    this.selectedCrewID = "";
    this.selectedPaxID = "";
    this.selectedServiceID = 0;
    
    
    this.initControls();    
    
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

 


}
