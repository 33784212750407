export class ContentDocumentModel {
  contentDocumentGUID: string;
  contentDocumentID: number;
  tripAlertID: number;
  headlineID: number;
  companyEventID: number;
  documentName: string;
  bFile: string;
  mimeType: string;
  fileExtension: string;
  documentSizeInKB: number;
  containerPath: string;
  autoDeleteDate: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  tempId: number;
  dataEntryStatusID: number;
  dataEntryStatusDescription: string;

}
