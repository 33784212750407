import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { ReturnObjModel } from '../models/return-obj.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { UnitofmeasureModel } from '../models/unitofmeasure.model';

import { FuelSupplierModel } from '../models/fuelsupplier.model'; 
import { DatePipe, formatDate } from '@angular/common';
import { CurrencyModel } from '../models/currency.model';
import { FuelPricingModel } from '../models/fuel-pricing.mode';
import {  FuelService } from '../services/fuel.service';
import { AirportModel } from '../models/airport.model';
import { IntoPlaneAgentModel } from '../models/intoplaneagent.model';
import { TaxesAndFeesComponent } from '../taxesandfees/taxesandfees.component';


@Component({
  selector: 'app-fuel-pricing-edit',
  templateUrl: './fuel-pricing-edit-dialog.component.html',
  styleUrls: ['./fuel-pricing-edit-dialog.component.css']
})

export class FuelPricingEditDialogComponent implements OnInit, AfterViewInit {

  //@Input() isfromfuelpricing: boolean = true;
  @Input() dialogTitle: string;
  fuel_pricing_edit: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  fuelSupplierList: FuelSupplierModel[];
  unitofmeasurelist: UnitofmeasureModel[];
  intoplaneagentList: IntoPlaneAgentModel[];

  currencylist: CurrencyModel[];
  airportlist: AirportModel[];

  show: boolean = false;
  showModified: boolean = false;
  fuelPricingId: number;

  modifiedBy: string;
  modifiedDate: string;
  isActive: any;
  controlValueChanged: boolean;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  fuelSupplierName: any;
  fuelSupplierShortname: any;
  lk_fuelGUID: any;

  fuelscaleneedsadjustment: boolean = false;
  readonly: boolean = false;
  lk_UnitOfMeasureID: any;

  //removeextrarows: any;

  fuelSupplierWebsite: any;
  fuelReleaseEmail: any;
  internalnotes: any;
  unitofmeasure: any;
  domfrequency: string;
  intlfrequency: string;
  currency: any;

  scaleneedsadjustment: any;
  taxesIncluded: boolean = false;

  isAllowImport: any;

  allowfuelpriceimport: any;
  isallowfuelpriceimport: string;
  allowfuelpriceimport1: boolean;
  successMsg: string;
  icao: string;
  intoplaneagent: string;
  effectivedate: string = new Date().toString();
  expirydate: string="";// = new Date().toString();
  minqty: number = 0;
  maxqty: number = 0;
  lk_CurrencyID: number;
  baseFuel: number;
  clientnotes: string;
  fuelsupplier: string;
  fuelsuppliername: string;
  isExistingPrice: boolean = false;

  isValidICAO: boolean = false;
  validateICAO: boolean = true;
  isotherintoplaneagent: boolean;
  otherintoplaneagent: string;


  isfromfuelpricing: boolean = true;

  fuelRangeForm: FormGroup;
  isDuplicate: boolean = false;
  ifsuccess: boolean = false;
    showDuplicateText: boolean=false;
    USDConversion: string;
    originalPrice: string;
    currencyconverter1: string;
    currencyconverter2: string;
  currencyConverted: boolean = false;
  currencydesc: string;
  currencyDate: Date;
  showConvertor: boolean = true;
  countryGUID: string;

  constructor(private readonly _dialogRef: MatDialogRef<FuelPricingEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    //private readonly _fuelPricing: FuelPricingService,
    //private readonly _fuel: FuelSupplierService,
    private readonly _fuel: FuelService,
    private readonly _dialogService: DialogService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private readonly _dialog: MatDialog,

  ) {
    this.fuelPricingId = _data.fuelPricingId;
    this.dialogTitle = _data.dialogTitle;

    this.isAllowImport = false;
    this.isActive = true;
    this.currency = 'USD';


    if (_data.dialogTitle == "Add") {
      this.showModified = false;
      this.isExistingPrice = false;

    }
    else {
      this.isValidICAO = true;
      this.showModified = true;
      this.isExistingPrice = true;
    }

    //this.fuelRangeForm = this.fb.group({ 
    //  quantities: this.fb.array([]),
    //});
  }

  getAllData(): Observable<any[]> { 
    let getFuelSuppliers = this._fuel.getFuelSupplierActive(); //getActiveFuelSupplier();  //  getFuelSupplierList(); getActiveFuelSupplier
    let getUnitOfMeasure = this._fuel.getUnitOfMeasure();
    let getCurrency = this._fuel.getCurrency();
    //let getAirportList = this._fuel.getAirportList();
    if (this.fuelPricingId != 0) {// != null) {
      let getFuelPricingResponse = this._fuel.getfuelPricingByfpId(this.fuelPricingId);
      return forkJoin([getFuelSuppliers, getUnitOfMeasure, getCurrency, getFuelPricingResponse]);//, getUnitOfMeasure, getCurrency]);
    }
    else {
      return forkJoin([getFuelSuppliers, getUnitOfMeasure, getCurrency]);//[getDomFrequency, getIntlFrequency, getUnitOfMeasure, getCurrency]);
    }
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    //this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = true;

    this.allowfuelpriceimport = 0;
    this.unitofmeasure = 1;
    this.currency = 'USD';

    this.isValidICAO = false;
    if (this.dialogTitle == "Edit") { 
      this.isValidICAO = true; 
      // this.isExistingPrice = false;
    }


  

    //  this.fuelSupplierList = [];
    this.fuelSupplierList = [];
    this.unitofmeasurelist = [];
    this.showSuccessMsg = false;

    //this.fuel_pricing_edit.get('effectivedate').patchValue(this.formatDate(new Date()));
    //this.fuel_pricing_edit.get('expirydate').patchValue(this.formatDate(new Date()));
    this.getAllData().subscribe(responses => {
      if (responses != null) { }
      this.fuelsupplier = 'default';
      //this.intlfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';


      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {

          this.fuelSupplierList = responses[0].model;

          let fuelsupplier = new FuelSupplierModel();

          fuelsupplier = responses[0].model;
          if (fuelsupplier.fuelSupplierGUID == 'undefined') {
            this.fuelSupplierName = fuelsupplier.fuelSupplierName;
          }

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.unitofmeasurelist = responses[1].model;


          let uom = new UnitofmeasureModel();

          uom = responses[1].model;
          if (uom.lk_UnitOfMeasureID == 'undefined') {
            this.unitofmeasure = uom.lk_UnitOfMeasureID;
          }

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.currencylist = responses[2].model;


          let curr = new CurrencyModel();

          curr = responses[2].model;
          if (curr.lk_currencyID != null) {
            this.currency = curr.lk_currencyID;
          }
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          let fs = new FuelPricingModel();
          fs = responses[3].model;

          this.fuelPricingId = fs.fuelPricingID;
          this.icao = fs.icao;
          this.fuelsupplier = fs.lk_FuelSupplierGUID;
          this.fuelSupplierShortname = fs.fuelSuppliershortName;
          this.intoplaneagent = fs.intoPlaneAgent;

          this.effectivedate = fs.effectiveDate == null ? '' : formatDate(fs.effectiveDate, 'yyyy-MM-dd', 'en');// taf.effectiveDate;
          this.expirydate = fs.expiryDate == null ? '' : formatDate(fs.expiryDate, 'yyyy-MM-dd', 'en');//  taf.expiryDate;
          //this.effectivedate = fs.effectiveDate;
          //this.expirydate = fs.expiryDate;
          this.minqty = fs.minQty;
          this.maxqty = fs.maxQty;
          this.unitofmeasure = fs.lk_UnitOfMeasureID;
          this.currency = fs.currencyID;
          this.baseFuel = fs.baseFuel;
          this.taxesIncluded = fs.taxesIncluded;
          this.internalnotes = fs.internalNotes;
          this.clientnotes = fs.clientNotes;
          this.isActive = fs.isActive == true ? 1 : 0;
          this.modifiedBy = fs.modifiedBy;
          this.modifiedDate = fs.modifiedDate;
          this.countryGUID = fs.countryGUID;

          this.fuelsupplierChange(true);

        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();
      this.convertToUSD();

      this.checkFormControlValueChanged();

      this.showSpin = false;



    });

    this.initControls();
    this.showSpin = false;

  }

  private formatDate(date) {
    if (date != null && date != '') {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    }
  }

  ngAfterViewInit() {

  }

  initControls() {
    this.fuel_pricing_edit = this._formBuilder.group({
      fuelPricingId: [this.fuelPricingId, Validators.required],
      icao: [this.icao, Validators.required],

      intoplaneagent: [{ value: this.intoplaneagent, disabled: !this.isValidICAO }, Validators.required],// [this.intoplaneagent, Validators.required],
      fuelsupplier: [{ value: this.fuelsupplier, disabled: !this.isValidICAO }, Validators.required],
      //  fuelsupplier: [{ value: 'default', disabled: this.isExistingPrice }],
      effectivedate: [formatDate(this.effectivedate, 'yyyy-MM-dd', 'en'), [Validators.required]],// [this.effectivedate, Validators.required],
      expirydate: [ this.expirydate , Validators.required], //[formatDate(this.expirydate, 'yyyy-MM-dd', 'en'), [Validators.required]],//[this.expirydate, Validators.required],
      minqty: [this.minqty, Validators.required],
      maxqty: [this.maxqty, Validators.required],
      unitofmeasure: [this.unitofmeasure],
      currency: [this.currency],
      basefuel: [this.baseFuel, Validators.required],
      taxesincluded: [this.taxesIncluded],
      internalnotes: [this.internalnotes],
      clientnotes: [this.clientnotes],
      savebutton: [{ value: '', disabled: this.controlValueChanged }],
      isActive: [this.isActive],
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],

      otherintoplaneagent: [this.otherintoplaneagent],

      quantities: this.fb.array([this.newQuantity()]),
    });

    this.checkCalculationDetails();
  }

  checkCalculationDetails() {
    if(this.f.basefuel?.value == undefined || this.f.basefuel.value == null || this.f.unitofmeasure.value == 5 || this.f.currency.value == 'n/a' ){
      this.showConvertor = false;
    }
    else{
      this.showConvertor = true;

    }
    
  }
  get f() { return this.fuel_pricing_edit.controls; }

  checkFormControlValueChanged(): void {
    this.fuel_pricing_edit.get("fuelPricingId").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("icao").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("fuelsupplier").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("intoplaneagent").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("effectivedate").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("expirydate").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("minqty").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("maxqty").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("unitofmeasure").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("currency").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("basefuel").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("taxesincluded").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("internalnotes").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("clientnotes").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.fuel_pricing_edit.get("isActive").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
  }

  icaoValidation(e) { // appending the updated value to the variable    --     ) { //
    if (e.target.value.length == 4 || e.target.value.length == 3) {
      let icao: string = e.target.value;
      this._fuel.isValidICAO<ResponseModel<boolean>>(icao).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            this.validateICAO = response.model;
            this.isValidICAO = response.model;
            this._fuel.getCountryDetailsByICAO<ResponseModel<AirportModel>>(icao).subscribe(response => {
              if (response != null && response.code == "200") {
                this.countryGUID = response.model.countryGUID;
              }
            });
          }
          else {
            this.fuelsupplier = 'default';
            this.validateICAO = false;
          }

        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        this.loading = false;
      })
    }
    else {
      this.validateICAO = true;
      this.isValidICAO = false;
    }
  }

  fuelsupplierChange(flagValue: boolean) {
    let request = new FuelPricingModel();
    request.lk_FuelSupplierGUID = this.f.fuelsupplier.value;
    if(flagValue){
      request.lk_FuelSupplierGUID = this.fuelsupplier;

    }

    let ipa = new IntoPlaneAgentModel();
    ipa.intoPlaneAgent = "other";

    this.intoplaneagentList = [];
    this._fuel.getIntoPlaneAgentByFuelSupplierID<ResponseModel<IntoPlaneAgentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          response.model.splice(0, 0, ipa);
          // response.model[0].push(ipa);
          //this.intoplaneagentList.push(ipa);
          this.intoplaneagentList = response.model;


        }
        else {

          this.intoplaneagentList.push(ipa);
        }
      }
      else {
        this.msg = "";
      }
      this.loading = false;
      this.showSpin = false;

    })
  }

  intoplaneagentChange() {
    if (this.f.intoplaneagent.value == 'other') {
      this.isotherintoplaneagent = true;
    }
    else {
      this.isotherintoplaneagent = false;
    }


  }

  clickSave() {
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.f.icao.value == "" &&  this.f.isActive.value == '1') {
      this.f.icao.setErrors({ required: true });
    }
    if (this.f.fuelsupplier.value == "default") {
      this.f.fuelsupplier.setErrors({ required: true });
    }

    if (this.f.intoplaneagent.value == "" || this.f.intoplaneagent.value == undefined) {
      this.f.intoplaneagent.setErrors({ required: true });
    }


    if (this.f.expirydate.value != null && this.f.expirydate.value != '')

      if (this.f.effectivedate.value > this.f.expirydate.value) {
        this.f.expirydate.setErrors({ matEndDateInvalid: true });
        return;
      }

    
    if (this.f.effectivedate.value == "") {
      this.f.effectivedate.setErrors({ required: true });
    }

    if (this.f.expirydate.value == "") {
      this.f.expirydate.setErrors({ required: true });
    }
    if (this.f.minqty.value == "") {
      this.f.minqty.setErrors({ required: true });
    }

    if (this.f.maxqty.value == "") {
      this.f.maxqty.setErrors({ required: true });
    }
    if (this.f.basefuel.value == "") {
      this.f.basefuel.setErrors({ required: true });
    }


    let a = this.f.quantities.value;
    if (this.f.minqty.value > this.f.maxqty.value) {
      this.errMsg = 'Min value cannot be less than the Max Value';
      return;
      // this.f.maxqty.setErrors({ required: true });
    }
    //if (this.fuel_pricing_edit.invalid) {
    //  return;
    //}
    if (!this.isExistingPrice && !this.isValidICAO) {
      return;
    }
    if (this.f.intoplaneagent.value == 'other' && !this.isExistingPrice && (this.f.otherintoplaneagent.value == '' || this.f.otherintoplaneagent.value == null)) {
      this.errMsg = 'Into Plane Agent cannot be empty';
      return;
    }

    this.loading = true;
    let obj = new FuelPricingModel();


    obj.fuelPricingID = this.fuelPricingId == null ? '' : this.f.fuelPricingId.value;
    if (this.isDuplicate) {
      obj.fuelPricingID = 0;
    }
    //if (!this.isDuplicate) {
    //  obj.fuelPricingID = this.fuelPricingId == null ? '' : this.f.fuelPricingId.value;
    //}
    //else {
    //  obj.fuelPricingID = 0;
    //}
    //obj.countryName = this.f.country.value;
    obj.icao = this.f.icao.value;




    // obj.lk_FuelSupplierGUID = this.isExistingPrice == true ? this.fuelsupplier : this.f.fuelsupplier.value;
    obj.lk_FuelSupplierGUID =   this.f.fuelsupplier.value;
    obj.intoPlaneAgent = this.f.intoplaneagent.value == 'other' ? this.f.otherintoplaneagent.value : this.f.intoplaneagent.value;
    obj.effectiveDate = this.f.effectivedate.value;
    obj.expiryDate = this.f.expirydate.value;// == null ? 0 : this.f.unitofmeasure.value; this.unitofmeasure;// this.selectedunitofmeasure;//
    obj.minQty = this.f.minqty.value;// == null ? 0 : this.f.currency.value;this.currency;// this.selectedcurrency;//
    obj.maxQty = this.f.maxqty.value;
    obj.lk_UnitOfMeasureID = this.f.unitofmeasure.value; //this.f.allowfuelpriceimport.value; 
    //obj.lk_CurrencyID = this.f.currency.value;
    obj.currencyID = this.f.currency.value;
    obj.baseFuel = this.f.basefuel.value;
    obj.taxesIncluded = this.f.taxesincluded.value;// == 'true' ? true : false;
    obj.internalNotes = this.f.internalnotes.value == null ? '' : this.f.internalnotes.value;
    obj.clientNotes = this.f.clientnotes.value == null ? '' : this.f.clientnotes.value;
    obj.isActive = this.f.isActive.value == '1' ? true : false;


    /*Maintain Duplicate when Adding new */
    if (!this.isExistingPrice) {
      this.icao = this.f.icao.value;
      this.fuelsupplier = this.f.fuelsupplier.value;
      this.intoplaneagent =   this.f.intoplaneagent.value;
      this.effectivedate = this.f.effectivedate.value;
      this.expirydate = this.f.expirydate.value;
      this.currency = this.f.currency.value;
      this.unitofmeasure = this.f.unitofmeasure.value;
      this.taxesIncluded = this.f.taxesincluded.value;// == 'true' ? true : false;
      this.internalnotes = this.f.internalnotes.value == null ? '' : this.f.internalnotes.value;
      this.clientnotes = this.f.clientnotes.value == null ? '' : this.f.clientnotes.value;
    }



    this._fuel.saveFuelPricing<ResponseModel<boolean>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == true) {
          // this.fuelSupplierGUID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.controlValueChanged = false;
          let returnResponse = new ReturnObjModel();
          returnResponse.refresh = true;
          returnResponse.newId = this.fuelPricingId.toString();
          this.showSuccessMsg = true;


          // this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          this.isModified = true;

          if (this.showModified == true && this.isDuplicate == true) {
            this.ifsuccess = true;
            this.successMsg = "Fuel Pricing Duplicated Successfully.";
          }
          else if (this.showModified == true) {
            this.ifsuccess = true;
            this.successMsg = "Fuel Pricing Updated Successfully.";
          }
          else {
            this.ifsuccess = true;
            this.successMsg = "Fuel Pricing Saved Successfully.";
          }


          setTimeout(() => {
            this.showSuccessMsg = false;
            this.ifsuccess = false;
            //if (!this.isExistingPrice && this.isDuplicate == false) {
              this.clickDuplicate();
            //}
            //else {
            //  this._dialogRef.close(returnResponse);
            //}
          }, 1000);


          //this._dialogRef.close(returnResponse);
          //}
        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg = "Fuel Supplier Code already exists.";
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;

    if (this.controlValueChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before closing the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(response);
        }
      });

    }
    else {
      this._dialogRef.close(response);
    }
  }

  //event handler for the select element's change event
  allowfpimport(event: any) {
    //update the ui
    if (event.target.value == 'true') {
      this.show = true;
      //  this.allowfuelpriceimport = true;
      this.isAllowImport = true;
    } else {
      this.show = false;
      //   this.allowfuelpriceimport = false;
      this.isAllowImport = false;
    }
  }

  clickDuplicate() {
    this.showDuplicateText = true;
    
    this.dialogTitle = 'Add';
    this.isDuplicate = true
    this.isExistingPrice = false;
    this.isValidICAO = true;
    this.minqty = 0;
    this.maxqty = null;
    this.baseFuel = null;
    this.icao = null;
    this.fuelsupplierChange(false);
    this.initControls();
    this.checkFormControlValueChanged();
    this.submitted = false;
    //this.f.minqty.setErrors({ required: false });
    //this.f.maxqty.setErrors({ required: false });
    // this.clickSave();
  }

  quantities(): FormArray {
    return this.fuel_pricing_edit.get("quantities") as FormArray
  }

  newQuantity(): FormGroup {
    return this.fb.group({
      minqty: '',
      maxqty: '',
    })
  }

  addQuantity() {
    if (this.quantities().length < 5) {
      this.quantities().push(this.newQuantity());
    }
  }

  removeQuantity(i: number) {
    if (this.quantities().length > 1) {
      this.quantities().removeAt(i);
    }
  }


  convertToUSD() { // appending the updated value to the variable


    if(this.f.basefuel?.value == undefined || this.f.basefuel.value == null || this.f.unitofmeasure.value == 5 || this.f.currency.value == 'n/a' ){
      this.showConvertor = false;
    }
    else{
      this.showConvertor = true;

    }
    let value: number;

    // if (this.taxStandard == '1')
    //   value = this.f.perUnitPrice.value;//e.target.value;
    // else if (this.taxStandard == '2')
    //   value = this.f.flatfeeamount.value;//e.target.value;
    value = this.f.basefuel.value;// this.currency;

    let uom: string;
    uom = this.f.unitofmeasure.value == 1 ? 'USG' : 'LTR';

    let uomValue: number;
    uomValue = this.f.unitofmeasure.value;// == 1 ? 'USG' : 'LTR';


    let request = new CurrencyModel();
    request.currencyID = this.f.currency.value;// this.currency;

    if(this.f.currency.value.toLowerCase() == 'euro'){
      request.currencyID  = 'EUR';
      this.f.currency.setValue('EUR');
    }
    request.price = value;


    if (request.price == null)
      return;

      this._fuel.getUSDConversion<ResponseModel<CurrencyModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let currencyModel = new CurrencyModel();

            currencyModel = response.model;

            this.currencyConverted = true;

            let usd: string;
            
            if (uomValue == 1) {
              usd = ((currencyModel[0].usd * 100) / 100).toFixed(5);
              uom = 'USG';
            }
            else if (uomValue == 2 ) {
              usd = ((currencyModel[0].usd * 3.78541 * 100) / 100).toFixed(5);
              uom = 'LTR';
            }
            else if (uomValue == 3 ) {
              usd = ((currencyModel[0].usd * 0.003021148 * 100) / 100).toFixed(5);
              uom = 'MT';
            }
            else if (uomValue == 4 ) {
              usd = ((currencyModel[0].usd * 3.79 * 100) / 100).toFixed(5);
              uom = 'KG';
            }

            this.currencyconverter1 = '(' + currencyModel[0].currencyID + '/' + uom + ')';
            this.currencyconverter2 = '(USD/USG)';

            this.originalPrice = request.price.toString() ; 
            this.USDConversion =  usd ;


            this.currencyfilter(request.currencyID);
          }
          else {
            this.originalPrice = '';
            this.USDConversion = '';
          }

        }
        else {
          if (response.code == "401") {
             this._authService.signOut();
          }
          else {
            // this.country = 0;
            // this.countrylength = 0;
           }
        }
        this.loading = false;
      })
    
  }
  
  
  currencyfilter(currency:string) {
    // Filter the list based on the searchTerm
    let currencyFilterlist = this.currencylist.filter((item) =>
      item.currencyID.toLowerCase().includes(currency.toLowerCase())
    );
    if (currencyFilterlist.length > 0) {
      this.currencydesc = currencyFilterlist[0].currencyDescription;
      this.currencyDate = currencyFilterlist[0].exchangeRateDate;
    }
  }

  addNewTax() {
  

      let s = "";
      //  const dialogConfig = new MatDialogConfig();
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.width = "80em";//"1000px";
      //dialogConfig.height = "28em";//"500px";
      dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    let supplier = "";
    let itp="";
    if (this.f.fuelsupplier.value != "" && this.f.fuelsupplier.value != null && this.f.fuelsupplier.value != "default")
      supplier = this.f.fuelsupplier.value;
    if (this.f.intoplaneagent.value != "" && this.f.intoplaneagent.value != null)
      itp = this.f.intoplaneagent.value;
     

      dialogConfig.data = {
        dialogTitle: s, fromFuelTask: true, selectedCountry: this.countryGUID, selectedIcao:  this.f.icao.value, iscountryorairport: 'airport', selectedFuelSupplier: supplier, selectedIntoPlaneAgent: itp
      };



      const dialogRef = this._dialog.open(TaxesAndFeesComponent, dialogConfig);
  }
  
}


