import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IdentityService } from "../services/identity.service";
import { UserModel } from "../models/user.model";
import { AuthenticateService } from "../services/authenticate.service";
import { IdentityRequestModel } from "../models/identity-request.model";
import { ResponseModel } from "../models/response.model";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})

export class PasswordRecoveryComponent implements OnInit {
  password_recovery_form: UntypedFormGroup;
  phoneNumber: string = '';
  email: string = '';
  hideOPwd: boolean = true;
  showOPwdEye: boolean = false;
  errMsg: string = '';
  successMsg: string;
  user: UserModel;
  request: IdentityRequestModel;
  currentPassword: string;
  passwordValid: boolean = false;
  submitted: boolean = false;
  userType: string;
  internalEmailEndsWithList: string[];
  showSuccessMsg: boolean;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _identityService: IdentityService,
    private readonly _authService: AuthenticateService,
    private readonly _dialogRef: MatDialogRef<PasswordRecoveryComponent>) {

  }

  ngOnInit(): void {
    this.user = new UserModel();

    this.initControls();
    this.loadDefaultInfo();

    this.password_recovery_form.controls.phone_number.disable();
  }

  initControls(): void {
    this.password_recovery_form = this._formBuilder.group({
      old_password: [null, Validators.required],
      phone_number: [{value: this.phoneNumber, disabled: true}, Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9- ().]{10,12}$")]
      )],
      email: [this.email, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,100}$")]
      )]
    })
  }

  loadDefaultInfo(): void {
    var request = new IdentityRequestModel();
    try {
      this._identityService.getUser<ResponseModel<UserModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          this.user.email = this.email = response.model.email;
          this.userType = response.model.userType;
          this.user.phoneNumber = this.phoneNumber = response.model.recoveryPhoneNumber;
          this.password_recovery_form = this._formBuilder.group({
            old_password: [null, Validators.required],
            phone_number: [this.phoneNumber, Validators.compose([
              Validators.required,
              Validators.pattern("^[0-9- ().]{10,12}$")]
            )],
            email: [this.email, Validators.compose([
              Validators.required,
              Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,100}$")]
            )]
          });
          if (this.userType.toLowerCase() == "internal") {
            this._identityService.getInternalEmailEndsWith<ResponseModel<string[]>>().subscribe(response1 => {
              if (response1 != null && response1.code == "200") {
                if (response1.message == "") {
                  this.internalEmailEndsWithList = response1.model;
                }
              }
            });
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      });
    }
    catch (error) {
      //console.log(error);
    }
  }

  onKeyup(event, controlflag: number) {
    this.errMsg = "";
    if (event.target.value != "") {
      switch (controlflag) {
        case 1:
          this.showOPwdEye = true;
          this.currentPassword = event.target.value;
          break;
        case 2:
          this.phoneNumber = event.target.value;
          break;
        case 3:
          this.email = event.target.value;
          break;
      }
    }
    else {
      switch (controlflag) {
        case 1:
          this.showOPwdEye = false;
          this.currentPassword = event.target.value;
          break;
        case 2:
          this.phoneNumber = event.target.value;
          break;
        case 3:
          this.email = event.target.value;
          break;
      }
    }
  }

  get f() { return this.password_recovery_form.controls; }

  onMobileChange(e: any) {
    this.phoneNumber = e.target.value;
    this.user.recoveryPhoneNumber = this.phoneNumber;
  }

  onEmailChange(e: any) {
    this.email = e.target.value;
    this.user.email = this.email;
  }

  sendPasswordRecoveryMessage(usingSms: boolean) {
    this.user.isUsingSms = usingSms;
    this._identityService.sendPasswordRecoveryMessage(this.user).subscribe(response => {
    });
  }

  onSave() {
    this.user.recoveryPhoneNumber = this.f.phone_number.value;
    this.user.email = this.f.email.value;
    this.user.isActive = true;
    this.user.password = this.currentPassword;
    this.user.username = localStorage.getItem('un');
    this.user.isUsingOnlyContactInfo = true;
    this.submitted = true;

    this._identityService.validatePassword<ResponseModel<boolean>>(this.user).subscribe(response => {
      this.passwordValid = response.model;

      if (!this.passwordValid) {
        this.errMsg = "Current password is incorrect.";
        return;
      }
      else {
        this._identityService.updateUser<ResponseModel<UserModel>>(this.user).subscribe(response => {
          if (response != null && response.code == "200") {
            this.showSuccessMsg = true;
            this.successMsg = "Personal info successfully updated."
            setTimeout(() => {this._dialogRef.close()}, 1000);
          }
          else {
            this.errMsg = "Error: " + response.message;
          }
        })
      }
    })
  }

  validatePassword() {
    this.user.password = this.currentPassword;
    this.user.username = localStorage.getItem('un');

    this._identityService.validatePassword<ResponseModel<boolean>>(this.user).subscribe(response => {
      this.passwordValid = response.model;

      if (!this.passwordValid) {
        this.errMsg = "Current password is incorrect.";
        return;
      }
    })
  }
}