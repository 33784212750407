import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
//import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
//import { CommAddressTypeModel } from '../models/comm-address-type.model';
//import { AddressRatingModel } from '../models/address-rating.model';
//import { AddressUseModel } from '../models/address-use.model';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopManifestDocumentModel } from '../models/ground-stop-manifest-document.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ReturnObjModel } from '../models/return-obj.model';
import { PersonTravelDocumentModel } from '../models/person-travel-document.model';
import { PersonTravelDocEditDialogComponent } from '../persons/person-travel-doc-edit-dialog.component';
import { PersonPassportEditDialogComponent } from '../persons/person-passport-edit-dialog.component';

@Component({
  selector: 'app-person-travel-doc-selection-dialog',
  templateUrl: './person-travel-doc-selection-dialog.component.html',
  styleUrls: ['./person-travel-doc-selection-dialog.component.css']
})

export class PersonTravelDocSelectionDialogComponent implements OnInit {

  dialogTitle: string;
  customerGUID: string;
  groundStopGUID: string;
  personGUID: string;
  selectedPersonTravelDocumentGUIDList: string;
  personName: string;
  personType: string;
  //selected
  person_travel_doc_selection_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  manifest_type: string;

  docList: GroundStopManifestDocumentModel[];
  selection = new SelectionModel<GroundStopManifestDocumentModel>(true, []);

  displayedColumn: string[] = ['select', 'documentType', 'documentNumber', 'documentIssuingCountry', 'whenToUse', 'subDoc', 'documentDOI', 'documentDOE'];
  isModified: boolean = false;
  upPII: boolean = false;
  isPassportNotRequired: boolean = false;
  disablePassportDocSelection: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<PersonTravelDocSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _customerService: CustomerService,
    private readonly _dialogService: DialogService, private readonly _dialog: MatDialog
  ) {
    this.personGUID = _data.personGUID;
    this.dialogTitle = _data.dialogTitle;
    this.selectedPersonTravelDocumentGUIDList = _data.selectedPersonTravelDocumentGUIDList
    this.personName = _data.personName;
    this.personType = _data.personType;


    if (_data.isPassportNotRequired != undefined && _data.isPassportNotRequired != null) {
      this.isPassportNotRequired = _data.isPassportNotRequired;
      if (this.isPassportNotRequired == true) {

        this.disablePassportDocSelection = true;
      }
    }

  }

  getAllData(): Observable<any[]> {

    let request = new GroundStopManifestDocumentModel();
    request.personGUID = this.personGUID;
    request.selectedPersonTravelDocumentGUIDList = this.selectedPersonTravelDocumentGUIDList;
    let getPersonDoc = this._groundStopAdvancedService.getCustomerPersonTravelDocumentBypId<ResponseModel<GroundStopManifestDocumentModel[]>>(request);
    return forkJoin([getPersonDoc]);

  }

  ngOnInit() {
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.docList = responses[0].model;
          this.docList.forEach(row => {
            row.disabled = false;
            if (row.selected) {
              this.selection.select(row);
              //row.disabled = false;
            }
            //else {
            //  row.disabled = row.isExpired;
            //}
          });
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;


    });

  }



  initControls() {
    this.person_travel_doc_selection_form = this._formBuilder.group({

    });
  }

  get f() { return this.person_travel_doc_selection_form.controls; }

  checkPersonTravelDocChange(e: any, item: GroundStopManifestDocumentModel) {
    e ? this.selection.toggle(item) : null;
    //if (e.checked == false) {
    //  if ((item.isExpired)) {
    //    item.disabled = true;
    //  }
    //}
  }

  clickSave() {
    //let x = this.docList;
    let selectedList = [];
    this.selection.selected.forEach(row => {
      selectedList.push(row);
    });

    let response = new ReturnObjModel();
    response.refresh = true;
    response.groundStopManifestDocumentList = selectedList;
    this._dialogRef.close(response);
  }

  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;
    this._dialogRef.close(response);
  }

  clickUnApprovedDoc(e: any, item: GroundStopManifestDocumentModel) {

    switch (item.documentTypeDescription) {
      case "Passport":
        let objP = new PersonTravelDocumentModel();
        objP.personTravelDocumentGUID = item.personTravelDocumentGUID;
        objP.personGUID = this.personGUID;
        this.openPassportEdit(objP);
        break;
      default:
        let objD = new PersonTravelDocumentModel();
        objD.personTravelDocumentGUID = item.personTravelDocumentGUID;
        objD.documentTypeGUID = item.documentTypeGUID;
        objD.personGUID = this.personGUID;
        this.openTravelDocEdit(objD);
        break;
    }

  }

  openPassportEdit(doc: PersonTravelDocumentModel) {
    let s = "Edit Record";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: doc.personGUID, personTravelDocumentGUID: doc.personTravelDocumentGUID };

    const dialogRef = this._dialog.open(PersonPassportEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.getData();
      }
    });

  }

  openTravelDocEdit(doc: PersonTravelDocumentModel) {
    let s = "";

    s = "Edit Record"

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: doc.personGUID, personTravelDocumentGUID: doc.personTravelDocumentGUID, documentTypeGUID: doc.documentTypeGUID.toLowerCase() };

    const dialogRef = this._dialog.open(PersonTravelDocEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }

    });

  }

  getData() {
    this._authService.updateAccessTime();
    let request = new GroundStopManifestDocumentModel();
    request.personGUID = this.personGUID;
    request.selectedPersonTravelDocumentGUIDList = this.selectedPersonTravelDocumentGUIDList;
    this.showSpin = true;
    this._groundStopAdvancedService.getCustomerPersonTravelDocumentBypId<ResponseModel<GroundStopManifestDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.docList = response.model;
        this.docList.forEach(row => {
          row.disabled = false;
          if (row.selected) {
            this.selection.select(row);
          }

        });
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }

      this.showSpin = false;
    })

  }
}
