import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { IndustryTypeModel } from '../models/industry-type.model';
import { CustomerTypeModel } from '../models/customer-type.model';

import { MarketingRegionModel } from '../models/marketing-region.model';
import { ThirdPartyScreeningModel } from '../models/third-party-screening.model';
import { CustomerModel } from '../models/customer.model';
import { CreditCheckModel } from '../models/credit-check.model';
import { NoteTypeModel } from '../models/note-type.model';
import { CustomerNoteModel } from '../models/customer-note.model';


@Component({
  selector: 'app-customer-halt-services-edit-dialog',
  templateUrl: './customer-halt-services-edit-dialog.component.html',
  styleUrls: ['./customer-halt-services-edit-dialog.component.css']
})
/** customer-profile-setup-add component*/
export class CustomerHaltServicesEditDialogComponent implements OnInit {
  customer_halt_services_edit_form: UntypedFormGroup;
  @Input() customerGuid: string;
  @Input() customerName: string;
  @Input() haltHandling: boolean;
  @Input() haltPermits: boolean;
  @Input() haltFuelSetup: boolean;
  @Input() haltWeatherServices: boolean;
  @Input() haltHotelSetup: boolean;
  @Input() haltWebsiteAccess: boolean;
  @Input() haltSSN: boolean;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  isModified: boolean;
  remarks: string;
  showSuccessMsg: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<CustomerHaltServicesEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _customerService: CustomerService,
    private readonly _dialogService: DialogService
  ) {
    this.customerGuid = data.customerGUID;
    this.haltHandling = data.haltHandling;
    this.haltPermits = data.haltPermits;
    this.haltFuelSetup = data.haltFuelSetup;
    this.haltWeatherServices = data.haltWeatherServices;
    this.haltHotelSetup = data.haltHotelSetup;
    this.haltWebsiteAccess = data.haltWebsite;
    this.haltSSN = data.haltSSN;
    this.customerName = data.customerName;
  }

 
  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.initControls();
    this.showSpin = false;
    
    

  }

  initControls() {
    this.customer_halt_services_edit_form = this._formBuilder.group({
      remarks: ['', [Validators.minLength(15),Validators.required]]


    });
  }

  get f() { return this.customer_halt_services_edit_form.controls; }

  

  clickSave() {
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;

    if (this.customer_halt_services_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    let obj = new CustomerModel();
    obj.customerGUID = this.customerGuid;
    obj.haltRemarks = this.f.remarks.value;
    obj.haltFuelSetup = this.haltFuelSetup;
    obj.haltHandling = this.haltHandling;
    obj.haltHotelSetup = this.haltHotelSetup;
    obj.haltPermits = this.haltPermits;
    obj.haltWeatherServices = this.haltWeatherServices;
    obj.haltWebsiteAccess = this.haltWebsiteAccess;
    obj.haltSSN = this.haltSSN;
    obj.customerName = this.customerName;

    this._customerService.updateCustomerHaltServices<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.customerGuid = response.model;
          this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this._dialogRef.close(this.isModified);
          }, 1000);
          

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
     }
      this.loading = false;
    })

  }



  clickClose() {
     this._dialogRef.close(this.isModified);
  }


}
