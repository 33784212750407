import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, Event, NavigationStart, NavigationEnd, ActivatedRoute, RouterEvent } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MenuService } from './services/menu.service';
import { ResponseModel } from './models/response.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'app';
  //showLoadingIndicator = true;
  signout: boolean = false;
  visibility: boolean = false;
  websiteEnvironment: string = "";
  
  constructor(private _router: Router, private _route: ActivatedRoute, private readonly _titleService: Title, private readonly _menuService:MenuService) {
    //this._router.events.subscribe((routeEvent: Event) => {
    //  if (routeEvent instanceof NavigationStart) {
    //    this.showLoadingIndicator = true;
    //  }
    //  if (routeEvent instanceof NavigationEnd) {
    //    this.showLoadingIndicator = false;
    //  }

    //})

    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this._route.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((data: any) => {
      if (data) {
        this._menuService.getWebsiteEnviroment<ResponseModel<string>>().subscribe(response => {
          if (response != null && response.code == "200") {
            this.websiteEnvironment = response.model;
            localStorage.setItem('websiteEnvironment', this.websiteEnvironment);
          }
          if (this.websiteEnvironment!="")
            this._titleService.setTitle(this.websiteEnvironment + "-" + data);
          else
            this._titleService.setTitle(data);
        });
       
      }
      else {
        this.getWebsiteEnviroment();
      }
    });
  
  }

  ngOnInit() {
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('token');
        if (token == undefined || token == null) { // you can update this as per your key
          // DO LOGOUT FROM THIS TAB AS WELL

          // this._router.navigate(['']); // If you are using router
          if (this.signout == false) {
            this._router.navigate(['/user-accounts/logout']);
            this.signout = true;
          }

          // OR
          //window.location.href = '<home page URL>';
        }
      }
    }, false);

  }


  getWebsiteEnviroment() {
    this._menuService.getWebsiteEnviroment<ResponseModel<string>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.websiteEnvironment = response.model;
        localStorage.setItem('websiteEnvironment', this.websiteEnvironment);
        switch (this.websiteEnvironment) {
          case "DEV":
            this._titleService.setTitle("FlightProConnectDev");
            break;
          case "QA":
            this._titleService.setTitle("FlightProConnectQA");
            break;
          case "LOCAL":
            this._titleService.setTitle("FlightProConnectLocal");
            break;
          default:
            this._titleService.setTitle("FlightProConnect");
        }
      }

    });
  }

}
