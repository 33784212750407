<div style="min-height: 17em;background-color: #f8f8f8;padding-left:1em; padding-right:1em; padding-top:1em">
  <div>
    <form *ngIf="trip_setup_note_form" [formGroup]="trip_setup_note_form">
      <div>
        <label for="remarks" style="font-size:small; font-weight: bold">Remarks</label>
        <div style="margin-left: 0em; margin-top:-0.5em">
          <textarea formControlName="remarks" [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }" style="width:37em; height:12em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd; border-radius:0.25em">   
           </textarea>
        </div>
        <div style="height:1.5em">
          <div *ngIf="submitted && f.remarks.errors" style="margin-top:0em">
            <div *ngIf="f.remarks.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:26em; font-size: small">Remarks is a required field and at least 15 characters.</div>
            <div *ngIf="f.remarks.errors?.minlength" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:26em; font-size: small">Remarks is a required field and at least 15 characters.</div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div>
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
      <div style="margin-right:0.5em">
        <button type="button" mat-button class="btn-high" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Save</div></div></button>&nbsp;
        <button type="button" mat-button class="btn-low" (click)="clickClose()"  ><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
      </div>
      <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:20em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
      <!--<div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]="successMsg"></app-success-message>
      </div>-->

    </div>
  </div>
</div>
