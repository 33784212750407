import { Component } from '@angular/core';

@Component({
    selector: 'app-airport-bi-dashboard',
    templateUrl: './airport-bi-dashboard.component.html',
    styleUrls: ['./airport-bi-dashboard.component.css']
})
/** airport-bi-dashboard component*/
export class AirportBiDashboardComponent {
    /** airport-bi-dashboard ctor */
    constructor() {

    }
}