import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
import { CustomerDocumentModel } from '../models/customer-document.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CustomerService } from '../services/customer.service';
import { CustomerDocumentEditDialogComponent } from './customer-document-edit-dialog.component';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { DialogService } from '../services/dialog.service';
import { EmailClientDialogComponent } from '../messages-client/email-client-dialog.component';
import { forkJoin, Observable, of } from 'rxjs';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CustomerGroupService } from '../services/customer-group-service';


@Component({
  selector: 'app-customer-documentlist',
  templateUrl: './customer-documentlist.component.html',
  styleUrls: ['./customer-documentlist.component.css']
})

export class CustomerDocumentlistComponent implements OnInit {
  customer_documentlist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  customerList: CustomerModel[];
  userType: string;
  showSpin: boolean = false;
  selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number =  15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  customerGuid: string;
  text_search: string;
  filter_by: string;
  includeDisabledRecords: boolean;
  recordList: CustomerDocumentModel[];

  displayedColumn: string[] = ['document_name', 'doc_type', 'effective_date', 'expiration_date', 'data_entry_status','disabled', 'action'];
  upPII: boolean = false;
  grantAccessCompanyProfiles: boolean = false;
  hideColumn: boolean = false;
  grantAccessToMessages: boolean = false;
  grantAccessToAccountGrouping: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _customerService: CustomerService, private readonly _dialogService: DialogService,
    private readonly _customerGroupService: CustomerGroupService) {

  }

  getAllData(): Observable<any[]> {
    let getClistResponse;
    if (this.userType == 'internal') {
      getClistResponse = this._commonService.getCustomers();
    }
    else {
      if (this.grantAccessToAccountGrouping) {
        let req = new CustomerGroupModel();
        getClistResponse = this._customerGroupService.getSelectedCustomerListBycgrpId(req);
      }
      else {
        getClistResponse = of(null);
      }
    }

    return forkJoin([getClistResponse]);

  }


  ngOnInit() {
    
    this._authService.updateAccessTime();
    this.grantAccessCompanyProfiles = false;
    if (localStorage.getItem('up.cPro') == 'true') {
      this.grantAccessCompanyProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }

    if (!this.upPII || !this.grantAccessCompanyProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.grantAccessToMessages = false;
    if (localStorage.getItem('up.msg') == 'true') {
      this.grantAccessToMessages = true;
    }
    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
    this.customerList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;

    this._route.queryParams.subscribe(params => {
      if (params["tabName"] == null || params["tabName"] == "undefined") {
        this.selectedTabName = 'document';
        this.customerGuid = "";
        this.text_search = "";
        this.filter_by = "DocumentName";
      }
      else {
        this.customerGuid = params["customerGuid"];
        this.selectedTabName = params["tabName"];
        this.text_search = params["text_search"];
        this.filter_by = params["filter_by"];
        //this.includeDisabledRecords = params["includeDisabledRecords"];
        if (params["includeDisabledRecords"] == "false") {
          this.includeDisabledRecords = false;
        }
        else {
          this.includeDisabledRecords = true;
        }
      }

    });


    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType == 'customer') {
      this.hideColumn = true;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerList = responses[0].model;
          if (this.userType == "customer" && this.grantAccessToAccountGrouping) {
            if (this.customerGuid == "")
              this.customerGuid = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();
          }

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      if (this.userType == 'customer') {
        if (!this.grantAccessToAccountGrouping) {
          this.getSearchResults();
        }
        else {
          if (this.customerGuid != '') {
            this.getSearchResults();
          }
        }
      }
      else {
        if (this.customerGuid != '') {
          this.getSearchResults();
        }
      }
      this.showSpin = false;

    });

    //if (this.userType == "internal") {
    //  this._commonService.getCustomers<ResponseModel<CustomerModel[]>>().subscribe(response => {
    //    if (response != null && response.code == "200") {
    //      if (response.model.length > 0) {
    //        this.customerList = response.model;
    //      }
    //      else {

    //      }
    //    }
    //    this.showSpin = false;

    //  })

    //}

    //this.initControls();

    //if (this.userType == "internal") {
    //  if (this.customerGuid != "") {
    //    this.getSearchResults();
    //  }
    //}
    //else {
    //  this.getSearchResults();
    //}

  }

  initControls() {
    this.customer_documentlist_form = this._formBuilder.group({
      customer_select: this.customerGuid,
      text_search: [this.text_search],
      filter_by: [this.filter_by],
      includeDisabledRecords: this.includeDisabledRecords
    });
  }

  get f() { return this.customer_documentlist_form.controls; }

  customerChange(e: any) {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.customerGuid = this.f.customer_select.value;
    if (e.target.value != "") {
      this.getSearchResults();
    }
    else {
      if (this.userType == "internal") {
        if (this.f.customer_select.value == "") {
          this.errMsg = "Please select a client";
          return;
        }
      }
    }

  }

  includeDisabledRecordsOnChange(event: any) {
    //let x = event.target.checked;
    if (this.userType == "internal") {
      if (this.f.customer_select.value == "") {
        this.errMsg = "Please select a client";
        return;
      }
    }
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults();
  }


  clickEdit(event: any, doc: CustomerDocumentModel) {
    if (this.userType == "internal") {
      if (this.f.customer_select.value == "") {
        this.errMsg = "Please select a client";
        return;
      }
    }
    let s = "";
    let customerDocumentGUID = "";
    if (doc == null) {
      s = "Add New Document";
    }
    else {
      s = "Edit Document";
      customerDocumentGUID = doc.customerDocumentGUID;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    
    //if (this.userType == "internal") {
    //  dialogConfig.width = "65.5em";//"1000px";
    //}
    //else {
    //  dialogConfig.width = "20em";
    //}
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    if (this.userType == "internal" || (this.userType == "customer" && this.grantAccessToAccountGrouping)) {
      dialogConfig.data = { dialogTitle: s, customerGUID: this.f.customer_select.value, customerDocumentGUID: customerDocumentGUID };
    }
    else {
      dialogConfig.data = { dialogTitle: s, customerDocumentGUID: customerDocumentGUID };
    }
    
    const dialogRef = this._dialog.open(CustomerDocumentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSearchResults();
      }
    });

  }

  clickPreviewDoc(event: any, doc: CustomerDocumentModel) {
    let customerDocumentGUID = doc.customerDocumentGUID;
    this._customerService.getCustomerDocumentBycdId<ResponseModel<CustomerDocumentModel>>(customerDocumentGUID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model!=null) {
          let docObj = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
          let file = new Blob([byteArray], { type: docObj.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);

        }
        else {
          this.errMsg = "No Document returned.";
        }
      }
      else {
        this.errMsg = "No Document returned.";
      }
    })
    
  }

  clickReset() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.selectedTabName = 'document';
    this.customerGuid = "";
    this.text_search = "";
    this.filter_by = "DocumentName";
    this.initControls();
  }

  clickShowAllRecords() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.text_search = "";
    this.f.text_search.setValue(this.text_search);
    if (this.userType == "internal") {
      if (this.f.customer_select.value == "") {
        this.errMsg = "Please select a client";
        return;
      }
    }
    this.getSearchResults();
  }

  onSubmit() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    if (this.f.customer_select.value == "") {
      this.errMsg = "Please select a client";
      return;
    }
    if (this.f.text_search.value == "") {
      this.errMsg = "Please enter a keyword.";
      return;
    }
    this.loading = true;
    this.getSearchResults();
  }

  getSearchResults() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new CustomerDocumentModel();
    request.customerGUID = this.f.customer_select.value;
    request.textSearch = this.f.text_search.value;
    request.filterBy = this.f.filter_by.value;
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = true;
    if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      request.isActive = !this.f.includeDisabledRecords.value;
    }

    this._customerService.getCustomerDocument<ResponseModel<CustomerDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

          }

        }
        else {
          this.recordList = [];
          if (this.f.text_search.value != "") {
            switch (this.f.filter_by.value) {
              case "DocumentName":
                this.msg = "Document name not found.";
                break;
              case "DocumentType":
                this.msg = "Document type not found.";
                break;
            }
          }
          else {
            this.msg = "No records found.";
          }
          //this.msg = "No data return.";
        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    })

  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    this.getSearchResults();

  }

  clickTab(s: string) {
    if (this.selectedTabName != s) {    
      this.f.filter_by.setValue("AddressUse");
      
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "tabName": s,
          "customerGuid": this.f.customer_select.value,
          "text_search": this.f.text_search.value,
          "filter_by": this.f.filter_by.value,
          "includeDisabledRecords": this.f.includeDisabledRecords.value
        }
      };
      switch (s) {
        case "comm":
          this._router.navigate(['/customers/customer-commlist'], navigationExtras);
          break;
        case "mail":
          this._router.navigate(['/customers/customer-maillist'], navigationExtras);
          break;
        case "document":
          this._router.navigate(['/customers/customer-documentlist'], navigationExtras);
          break;

      }
    }

  }

  clickSendEmail(e: any) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", groundStopGUID: "", groundStopTaskGUID: "", customerGUID: this.customerGuid
    };

    const dialogRef = this._dialog.open(EmailClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }

}
