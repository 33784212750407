<div style="background-color:#f8f8f8;overflow-y:hidden;" >
  <div style="display: flex; justify-content:space-between">
    <div style="padding-left:1em; padding-top:0.25em; float:left; min-width:60em" cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>
      <div><h5>Quote History</h5></div>
      <div style="display: flex;">
        <div style="margin-right:1em">
          <div style="font-size: small">Registration</div>
          <div style="font-weight: bold; font-size: small">{{registration}}</div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">Trip Code</div>
          <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">Business Stop</div>
          <div style="font-size: small; display: flex"><div>{{prevICAO!=''?prevICAO+'-':''}}</div><div style="font-weight: bold">{{icao}}</div><div>{{nextICAO!=''?'-'+nextICAO:''}}</div></div>
        </div>
        <div style="margin-right:1em" *ngIf="arrivalDateTime!=''">
          <div style="font-size: small">Arrival({{icao}})</div>
          <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{arrivalDateTime}}</div>
          <div style="font-size: small; margin-top:-0.5em"> {{arrivalDateTimeLocal!=''?arrivalDateTimeLocal:''}}</div>
        </div>
        <div style="margin-right:1em" *ngIf="departureDateTime!=''">
          <div style="font-size: small">Departure({{icao}})</div>
          <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{departureDateTime}}</div>
          <div style="font-size: small;margin-top:-0.5em"> {{departureDateTimeLocal!=''?departureDateTimeLocal:''}}</div>
        </div>
        <div>
          <div style="font-size: small;">{{aircraftManufacturer}} {{aircraftModel}}</div>
          <div style="font-size: small;">{{aircraftInfo}}</div>
        </div>
      </div>
    </div>

    <div style="float: right;padding-top:0.75em; padding-right:1em">
      <i class="material-icons" style="cursor:pointer;color:royalblue;font-size:medium" title="Copy note to clipboard" [cdkCopyToClipboard]="clickCopyHeader() ">content_copy</i>
    </div>
  </div>
  <div>
   
      <div style="display:flex; margin-top:0em; padding-left: 1em; padding-right:1em">
        <div style="margin-top: 0.5em; background-color: white; padding-left: 1em; padding-right: 1em; padding-top: 0.75em; padding-bottom: 0.75em; min-width: 60em;">
          
          
            <div style="height:20em">
              <table #table mat-table [dataSource]="pastFuelQuoteList" class="price-table">
                <tr>
                  <ng-container matColumnDef="modified">
                    <th mat-header-cell *matHeaderCellDef>
                      Created By
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.modifiedBy}} {{element.modifiedDate}}</td>
                  </ng-container>

                  <ng-container matColumnDef="fuel_supplier">
                    <th mat-header-cell *matHeaderCellDef>
                      Fuel Supplier
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.fuelSupplierName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="into_plane">
                    <th mat-header-cell *matHeaderCellDef>
                      Into Plane Agent
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.intoPlaneAgent}}</td>
                  </ng-container>
                  <ng-container matColumnDef="markup">
                    <th mat-header-cell *matHeaderCellDef>
                      Client<br />Markup
                    </th>
                    <td mat-cell *matCellDef="let element; let i=index" style="align-content:center !important">
                      <div style="white-space:pre-wrap">
                        {{element.markupAmountList}}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="exchange_rate_markup">
                    <th mat-header-cell *matHeaderCellDef>
                      Exchange Rate<br />Markup
                    </th>
                    <td mat-cell *matCellDef="let element; let i=index" style="align-content:center !important">
                      {{element.exchangeRateMarkupAmount | percent: '0.0-2' }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="expiry_date">
                    <th mat-header-cell *matHeaderCellDef>
                      Expiry Date
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.expiryDate}}</td>
                  </ng-container>
                  <ng-container matColumnDef="report_id">
                    <th mat-header-cell *matHeaderCellDef>
                     Quote ID
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.reportID}}</td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                      Status
                    </th>
                    <td mat-cell *matCellDef="let element; let i=index" style="align-content:center !important">
                      <div style="margin-top:.25em" [ngClass]="{ 'alert-success':element.isActive }">
                        {{element.isActive? 'Active': 'Expired'}}
                      </div>
                      <!--<select [ngValue]="element.isActive" style="margin-top:.25em;margin-left:.75em" class="form-control form-control-sm inputBox" id="status{{i}}" (change)="updateQuoteStatus($event, element, true)" value="{{element.isActive}}">
        <option value="true">Active</option>
        <option value="false">Deactivated</option>
      </select>-->
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="option">
                    <th mat-header-cell *matHeaderCellDef>
                      <div>Option</div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div><button mat-button class="btn-high grid" (click)="clickPreviewDocument($event, null, element.groundStopTaskDocumentGUID)">Open</button></div>

                    </td>
                  </ng-container>


                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumnPastQuote; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnPastQuote"></tr>
              </table>
            </div>
        

        </div>
      </div>
   
  </div>
  <div class="d-inline-flex justify-content-between" style="width: 100%; padding: 1em; ">
    <button mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>&nbsp;
    
    
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
