<div style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <!--<div style="margin-top:0.3em;">
      <i class="material-icons-outlined" style="font-size: x-large;cursor: pointer; color: gray">event_available</i>
    </div>-->
    <div style="display: flex; justify-content: space-between; width: 100%;">
      <div style="margin-top:0.3125em; display: inline-flex;">
        <i class="material-symbols-outlined" style="float: left; padding-right: 0.5em;">signature</i>
        <h5>Signature</h5>
      </div>
      <div style="display: flex;">
        <button mat-icon-button mat-dialog-close>
          <i class="material-icons" style="font-size: x-large;">close </i>
        </button>
      </div>
    </div>
  </div>
   
  <div>
    <form *ngIf="signature_editor_form" [formGroup]="signature_editor_form">
      <div style="padding-left: 1em; padding-right: 1em;">
        <div style="background-color: white; padding-left: 1em; padding-right: 1em; padding-bottom:0.5em">
          <div class="form-group">
            <label for="username">Username: {{userName}}</label>
            <div>
              <ckeditor #signatureCKEditor formControlName="signatureCKEditor" id="signatureCKEditor" name="signatureCKEditor" [editor]="Editor" [config]="CKEConfig" (ready)="onReady($event)" [disabled]="isDisabled" style="line-height:1.2em !important;"></ckeditor>
            </div>
          </div>
          
        </div>
      </div>
</form>
</div>
  <div>
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
      <div style="margin-right:0.5em">
        <button [disabled]="loading" mat-button class="btn-high" (click)="clickSend()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Save
        </button>      
      </div>
      <div>
        <button type="button" mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>

      </div>
      <div *ngIf="showSuccessMsg" style="margin-top:0em; width:20em; margin-left:0.5em">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
      <div *ngIf="errMsg!=''" class="d-block" style="margin-left:0.5em"><label style="color:red">{{errMsg}}</label></div>
    </div>

  </div>
</div>
