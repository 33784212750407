<div *ngIf="upPII" style="background-color:#f8f8f8;padding-left:1em; padding-right:1em" >
<!--<div style="background-color:#f8f8f8;">-->
  <div style="display: flex;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3em;"><i class="material-icons-outlined" style="font-size: x-large;cursor: pointer; color: gray" >manage_accounts</i></div>
    <div style="margin-top:0.3125em;"><h5>CREW & PASSENGER MANIFEST</h5></div>
  </div>
  <div style="display: flex">
    <div style="margin-right:1em">
      <div style="font-size: small">Registration</div>
      <div style="font-weight: bold; font-size: small">{{registration}}</div>
    </div>
    <div style="margin-right:1em">
      <div style="font-size: small">Trip Code</div>
      <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
    </div>
    <div style="margin-right:1em">
      <div style="font-size: small">Business Stop</div>
      <div style="font-size: small; display: flex"><div style="font-weight: bold">{{icao}}</div></div>
    </div>
    <div style="margin-right:1em" *ngIf="arrivalDateTime!=''">
      <div style="font-size: small">Arrival({{icao}})</div>
      <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{arrivalDateTime}}</div>
      <div style="font-size: small; margin-top:-0.5em"> {{arrivalDateTimeLocal!=''?arrivalDateTimeLocal:''}}</div>
    </div>
    <div style="margin-right:1em" *ngIf="departureDateTime!=''">
      <div style="font-size: small">Departure({{icao}})</div>
      <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{departureDateTime}}</div>
      <div style="font-size: small;margin-top:-0.5em"> {{departureDateTimeLocal!=''?departureDateTimeLocal:''}}</div>
    </div>
    <div style="margin-right:1em" *ngIf="utcTimeConversion!=''">
      <div style="font-size: small">UTC</div>
      <div style="font-size: small;font-weight: bold; margin-top:-0.25em">{{utcTimeConversion}}</div>
    </div>
    <div>
      <div style="font-size: small;">{{aircraftManufacturer}} {{aircraftModel}}</div>
      <div style="font-size: small;">{{aircraftInfo}}</div>
    </div>
  </div>
  <div>
    <form *ngIf="ground_stop_crew_pax_edit_form" [formGroup]="ground_stop_crew_pax_edit_form">
      <div style="display:flex; margin-top:0em; ">
        <div style="margin-top:0em;background-color:white; padding-left:0.5em; height: 36em; min-width: 25em">
          <div style="font-size: small; display: flex; margin-top:0.5em">
            <div style="display: flex">
              <div>
                <input formControlName="manifest_type"
                       type="radio"
                       value="Arrival" (click)="changeManifestType($event)" />
              </div>
              <div style="white-space: nowrap;margin-top:-0.25em">
                <label>Update for Arrival&nbsp;&nbsp;</label>
              </div>
            </div>
            <div style="display: flex">
              <div>
                <input formControlName="manifest_type"
                       type="radio"
                       value="Departure" (click)="changeManifestType($event)"  />
              </div>
              <div style="white-space: nowrap;margin-top:-0.25em">
                <label>Update for Departure&nbsp;&nbsp;</label>
              </div>
            </div>
          </div>
          <div>
            <div style="margin-left: 0em;margin-top:0em; display:flex" *ngIf="currentGroundStopSequenceID>1 && manifest_type=='Arrival'">
              <div style="display: flex; margin-right:0.25em" *ngIf="showLoadCrewPreviousDeparture">
                <input type="checkbox" formControlName="loadCrewPreviousDeparture" (change)="loadCrewPreviousDepartureChange($event)" /><div style="margin-top:-0.5em"><label for="loadCrewPreviousDeparture" style="font-size:small;">&nbsp;Load crew from previous departure</label></div>
              </div>
              <div style="display: flex;" *ngIf="showLoadPaxPreviousDeparture">
                <input type="checkbox" formControlName="loadPaxPreviousDeparture" (change)="loadPaxPreviousDepartureChange($event)"  /><div style="margin-top:-0.5em"><label for="loadPaxPreviousDeparture" style="font-size:small">&nbsp;Load pax from previous departure</label></div>
              </div>
              </div>
            <div style="margin-left: 0em;margin-top:0em; display:flex" *ngIf="currentGroundStopSequenceID>1 && manifest_type=='Departure'">
              <div style="display: flex; margin-right:0.25em" *ngIf="showLoadCrewArrival">
                <input type="checkbox" formControlName="loadCrewArrival" (change)="loadCrewArrivalChange($event)" /><div style="margin-top:-0.5em;"><label for="loadCrewArrival" style="font-size:small">&nbsp;Load crew from arrival</label></div>
              </div>
              <div style="display: flex;" *ngIf="showLoadPaxArrival">
                <input type="checkbox" formControlName="loadPaxArrival" (change)="loadPaxArrivalChange($event)" /><div style="margin-top:-0.5em"><label for="loadPaxArrival" style="font-size:small">&nbsp;Load pax from arrival</label></div>
              </div>
              </div>
            </div>
          <div>
            <div><button type="button" mat-button class="btn-high" (click)="clickSelectCrewPax('PIC')">Select PIC</button></div>
            <div>
              <div style="margin-top:0.25em; height: 3.75em; background-color: #E9ECEF; width:29em; padding-left: 0.125em;font-size: small;border-radius:0.25em; overflow-y:auto">
                <div *ngFor="let p of selPIC" style="display: flex">
                  <div style="width: 19.5em">
                    <div style="display:flex"><div class="divHover" style="cursor: pointer" (click)="clickPersonName($event, p)">{{p.personName}}</div><div>&nbsp;</div></div>
                    <div *ngFor="let d of p.groundStopManifestDocumentList">
                      <div style="margin-left:0.5em; margin-top:-0.25em">{{d.documentTypeDescription}}  {{d.documentNumber}} {{d.isO2Letter}} {{d.documentDOE}}</div>
                    </div>
                  </div>
                  <div style="margin-right:0em" *ngIf="p.hasPersonTravelDoc && !lockdownTrip"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickSelectDocument(p.personGUID, p.personName,'PIC')">Select Documents</a></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div><button type="button" mat-button class="btn-high" (click)="clickSelectCrewPax('SIC')">Select SIC</button></div>
            <div>
              <div style="margin-top:0.25em; height: 3.75em; background-color: #E9ECEF; width:29em; padding-left: 0.125em;font-size: small;border-radius:0.25em; overflow-y:auto">
                <div *ngFor="let p of selSIC" style="display: flex">
                  <div style="width: 19.5em">
                    <!--<div>{{p.personName}}</div>-->
                    <div style="display:flex"><div class="divHover" style="cursor: pointer" (click)="clickPersonName($event, p)">{{p.personName}}</div><div>&nbsp;</div></div>
                    <div *ngFor="let d of p.groundStopManifestDocumentList">
                      <div style="margin-left:0.5em; margin-top:-0.25em">{{d.documentTypeDescription}}  {{d.documentNumber}} {{d.isO2Letter}} {{d.documentDOE}}</div>
                    </div>
                  </div><div style="margin-right:0em" *ngIf="p.hasPersonTravelDoc && !lockdownTrip"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickSelectDocument(p.personGUID, p.personName,'SIC')">Select Documents</a></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div><button type="button"mat-button class="btn-high" (click)="clickSelectCrewPax('Crew')">Select Crew</button></div>
            <div>
              <div style="margin-top:0.25em; height: 9.5em; background-color: #E9ECEF; width:29em; padding-left: 0.125em;font-size: small;border-radius:0.25em; overflow-y:auto">
                <div *ngFor="let p of selCrewList" style="display: flex">
                  <div style="width: 19.5em">
                    <!--<div>{{p.personName}}</div>-->
                    <div style="display:flex"><div class="divHover" style="cursor: pointer" (click)="clickPersonName($event, p)">{{p.personName}}</div><div>&nbsp;</div></div>
                    <div *ngFor="let d of p.groundStopManifestDocumentList">
                      <div style="margin-left:0.5em; margin-top:-0.25em">{{d.documentTypeDescription}}  {{d.documentNumber}} {{d.isO2Letter}} {{d.documentDOE}}</div>
                    </div>
                  </div><div style="margin-right:0em;" *ngIf="p.hasPersonTravelDoc && !lockdownTrip"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickSelectDocument(p.personGUID, p.personName,'Crew')">Select Documents</a></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style="display: flex; margin-top:0.25em">
              <div style="margin-right:0.5em" *ngIf="(manifest_type=='Departure' && isDeptFerryFlight==false) || (manifest_type=='Arrival' && isArrFerryFlight==false)">
                <button type="button" mat-button class="btn-high" (click)="clickSelectCrewPax('PAX')">Select PAX</button>
              </div>
              <div style="margin-right:0.5em; margin-top:0.25em; font-size: small" *ngIf="(manifest_type=='Departure' && isDeptFerryFlight==true) || (manifest_type=='Arrival' && isArrFerryFlight==true)">
                Select PAX
              </div>
              <div style="margin-left: 0em;margin-top:0.25em; display:flex;" *ngIf="f.manifest_type.value=='Departure'">
                <mat-checkbox (click)="$event.stopPropagation()" formControlName="isDeptFerryFlight" (change)="isFerryFlightChange($event, 'd')"></mat-checkbox>
                <div style="margin-top:-0.25em"><label for="isDeptFerryFlight" style="font-size:small">&nbsp;Ferry Flight</label></div>
              </div>
              <div style="margin-left: 0em;margin-top:0.25em; display:flex;" *ngIf="f.manifest_type.value=='Arrival'">
                <mat-checkbox (click)="$event.stopPropagation()" formControlName="isArrFerryFlight" (change)="isFerryFlightChange($event, 'a')"></mat-checkbox>
                <div style="margin-top:-0.25em"><label for="isArrFerryFlight" style="font-size:small">&nbsp;Ferry Flight</label></div>
              </div>
            </div>
            <div>
              <div style="margin-top:0.25em; height: 9.5em; background-color: #E9ECEF; width:29em; padding-left: 0.125em;font-size: small;border-radius:0.25em; overflow-y:auto">
                <div *ngFor="let p of selPAXList" style="display: flex; padding:0em">
                  <div style="width: 19.5em">
                    <!--<div>{{p.personName}}</div>-->
                    <div style="display:flex"><div class="divHover" style="cursor: pointer" (click)="clickPersonName($event, p)">{{p.personName}}</div><div>&nbsp;</div></div>
                    <div *ngFor="let d of p.groundStopManifestDocumentList">
                      <div style="margin-left:0.5em; margin-top:-0.25em">{{d.documentTypeDescription}}  {{d.documentNumber}} {{d.isO2Letter}} {{d.documentDOE}}</div>
                    </div>
                  </div><div style="margin-right:0em; white-space:nowrap" *ngIf="p.hasPersonTravelDoc && !lockdownTrip"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickSelectDocument(p.personGUID, p.personName,'PAX')">Select Documents</a></div>
                </div>
              </div>
            </div>
          </div>
          <div style="display:flex;">
            <div style="width: 18em">
              <div>
                <div style="margin-left: 0em;margin-top:0.5em; display:flex;">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="saveCrewToAllStops"></mat-checkbox>
                  <div style="margin-top:-0.25em"><label for="saveCrewToAllStops" style="font-size:small">&nbsp;All Stops for Crews</label></div>
                </div>
              </div>
              <div>
                <div style="margin-left: 0em;margin-top:-0.25em; display:flex;">
                  <mat-checkbox (click)="$event.stopPropagation()" formControlName="savePaxToAllStops"></mat-checkbox>
                  <div style="margin-top:-0.25em"><label for="savePaxToAllStops" style="font-size:small">&nbsp;All Stops for PAXs</label></div>
                </div>
              </div>             
            </div>
            <div style="margin-left:0.5em">
              <div style="font-size: small">Crew Count: {{crewCount}}</div>
              <div style="font-size: small">PAX Count: {{paxCount}}</div>
            </div>
          </div>         
        </div>
        <div style="width:0.5em; background-color: #f8f8f8;"></div>
        <div style="margin-top:0em; padding-left: 0.5em; padding-right:0em; background-color: white; width:30.5em">
          <div style="height:33em">
            <div *ngIf="personType!=''">
              <div style="font-size: small; font-weight: bold; margin-left:0em">Select Contact Roles Type {{personType!=''?' - '+personType:''}}</div>
              <div style="font-size: small; margin-left:0em">
                <div style="margin-right:0.5em;">
                  <label for="search_keyword" style="font-size:small;">Search {{personType}}</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                    <input type="text" formControlName="search_keyword" class="form-control" style="padding: 0em;width:36em;height:1.5625em;font-size:small;background-color:#eaf0fd" (keyup)="searchKeywordChange($event)" />
                  </div>
                </div>
              </div>
              <div style="display:flex; margin-top:0.5em">
                <div style="height: 28em;width:12em; overflow-y: auto;overflow-x:hidden;">
                  <table mat-table *ngIf="avaiPersonList" [dataSource]="avaiPersonList" class="example-container2 mat-elevation-z8">
                    <tr>
                      <ng-container matColumnDef="personName">
                        <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">
                          Please select {{personType=='PIC' || personType=='SIC'? 'a '+personType: personType}}
                        </th>
                        <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickAddItem($event, element)">{{element.personName}}</a></td>
                      </ng-container>
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                  </table>

                </div>
                <div style="margin-top: 2em;margin-left:0em">
                  <button type="button" mat-button class="btn-high" [hidden]="avaiPersonList && avaiPersonList.length==0" (click)="clickSelectAll()" style="width: 7em">Select All</button><br /><br />
                  <button type="button" mat-button class="btn-low" [hidden]="selPersonList && selPersonList.length==0" (click)="clickRemoveAll()" style="width: 7em">Remove All</button>
                </div>
                <div style="margin-left:1em;height: 28em;width:12em; overflow-y: auto;overflow-x:hidden">
                  <table mat-table *ngIf="selPersonList" [dataSource]="selPersonList" class="example-container2 mat-elevation-z8">
                    <tr>
                      <ng-container matColumnDef="personName">
                        <th mat-header-cell *matHeaderCellDef style="background-color: lightgray;">
                          Selected {{personType}}
                        </th>
                        <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickRemoveItem($event, element)">{{element.personName}}</a></td>
                      </ng-container>
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                  </table>


                </div>
              </div>
            </div>
          </div>
          <div style="font-size: small" *ngIf="personType!=''">
            <div style="font-weight: bold" >Additional Options</div>
            <div style="display: flex" *ngIf="personType=='PIC'">
              <div style="display: flex; margin-right:1em">
                <div>
                  <input formControlName="additional_options_pic"
                         type="radio"
                         value="0" (click)="changePICAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>None</label>
                </div>
              </div>
              <div style="display: flex; margin-right:1em">
                <div>
                  <input formControlName="additional_options_pic"
                         type="radio"
                         value="12" (click)="changePICAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>TBA PIC</label>
                </div>
              </div>
            </div>
            <div style="display: flex" *ngIf="personType=='SIC'">
              <div style="display: flex; margin-right:1em">
                <div>
                  <input formControlName="additional_options_sic"
                         type="radio"
                         value="0" (click)="changeSICAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>None</label>
                </div>
              </div>
              <div style="display: flex; margin-right:1em">
                <div>
                  <input formControlName="additional_options_sic"
                         type="radio"
                         value="13" (click)="changeSICAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>TBA SIC</label>
                </div>
              </div>
            </div>
            <div style="display: flex" *ngIf="personType=='Crew'">
              <div style="display: flex; margin-right:1em">
                <div>
                  <input formControlName="additional_options_crew"
                         type="radio"
                         value="0" (click)="changeCrewAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>None</label>
                </div>
              </div>
              <div style="display: flex; margin-right:1em">
                <div>
                  <input formControlName="additional_options_crew"
                         type="radio"
                         value="6" (click)="changeCrewAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>TBA Crew</label>
                </div>
              </div>
              <div style="display: flex; margin-right:1em">
                <div>
                  <input formControlName="additional_options_crew"
                         type="radio"
                         value="7" (click)="changeCrewAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>Unknown Crew</label>
                </div>
              </div>
              <div style="display: flex; margin-right:0.5em">
                <div>
                  <input formControlName="additional_options_crew"
                         type="radio"
                         value="8" (click)="changeCrewAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>Followup Required</label>
                </div>
              </div>
            </div>
            <div style="display: flex" *ngIf="personType=='PAX'">
              <div style="display: flex; margin-right:1em">
                <div>
                  <input formControlName="additional_options_pax"
                         type="radio"
                         value="0" (click)="changePaxAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>None</label>
                </div>
              </div>
              <div style="display: flex; margin-right:1em">
                <div>
                  <input formControlName="additional_options_pax"
                         type="radio"
                         value="9" (click)="changePaxAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>TBA PAX</label>
                </div>
              </div>
              <div style="display: flex; margin-right:1em">
                <div>
                  <input formControlName="additional_options_pax"
                         type="radio"
                         value="10" (click)="changePaxAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>Unknown PAX</label>
                </div>
              </div>
              <div style="display: flex; margin-right:0.5em">
                <div>
                  <input formControlName="additional_options_pax"
                         type="radio"
                         value="11" (click)="changePaxAdditionalOptions($event)" />
                </div>
                <div style="white-space: nowrap;margin-top:-0.25em">
                  <label>Followup Required</label>
                </div>
              </div>
            </div>

          </div>
          </div>
      </div>
    </form>

  </div>
  <div style="display: flex">
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.75em; padding-bottom:0.5em; margin-right:1em; height:3em">
      <button mat-button class="btn-high" *ngIf="!lockdownTrip && !showSpin" [disabled]="loading" (click)="clickSave('')"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>Save</div></div></button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()" ><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
    </div>
    <div *ngIf="errMsg!=''" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;"><label style="color:black; font-size: small">{{errMsg}}</label></div>
    <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
  </div>

</div>
<app-spin *ngIf="showSpin"></app-spin>
