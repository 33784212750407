<div>
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    
    <div style="margin-top:0.3125em;">
      <div><h5>Import Messages</h5></div>    
      <div>{{title}}</div>
    </div>
      </div>
  <div>
    <app-spin *ngIf="showSpin"></app-spin>
    <div style="padding-left: 1em; padding-right: 1em;">
      <div style="background-color: white;overflow-x:auto">
        <table mat-table *ngIf="folderList" [dataSource]="folderList" class="example-container">
          <tr>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Select
              </th>
              <td mat-cell *matCellDef="let element; let i = index"><mat-radio-button (change)="updateSelection($event, element, i)" style="transform:scale(.8)" color="primary" [checked]="element.selected"></mat-radio-button></td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef width="40%">
                Folder Name
              </th>
              <td mat-cell *matCellDef="let element">{{element.displayName}}</td>
            </ng-container>

            <ng-container matColumnDef="totalItems">
              <th mat-header-cell *matHeaderCellDef width="10%">
                Total Items
              </th>
              <td mat-cell *matCellDef="let element" style="text-align:center !important" >
                {{element.totalItemCount}}
              </td>
            </ng-container>
            <ng-container matColumnDef="childFolders">
              <th mat-header-cell *matHeaderCellDef width="10%">
                Child Folders
              </th>
              <td mat-cell style="text-align:center !important" *matCellDef="let element">{{element.childFolderCount}}</td>
            </ng-container>
            <ng-container matColumnDef="client">
              <th mat-header-cell *matHeaderCellDef width="20%" [hidden]="hideCustomer">
                Client
              </th>
              <td mat-cell *matCellDef="let element" [hidden]="hideCustomer">
                <label *ngIf="element.customerGUID!=null && element.customerGUID!=''">{{element.customerName}}</label>
                <!--<select *ngIf="element.customerGUID==null || element.customerGUID==''" style="font-size: small;width:19.5em;border-radius:0.25em; border-color:lightgrey" [(ngModel)]="element.customerGUID" [ngModelOptions]="{standalone: true}">
                  <option value="" selected>Client Name</option>
                  <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                </select>-->
              </td>
            </ng-container>
            <ng-container matColumnDef="imported">
              <th mat-header-cell *matHeaderCellDef width="5%">
                {{folderID=='AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA='? 'Main Folder':''}}
                Imported
              </th>
              <td style="text-align:center !important" mat-cell *matCellDef="let element">
                {{element.isImported? 'Yes':''}}
                {{folderID=='AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA=' && element.isImported? '/' + element.importItemCount:''}}
              </td>
            </ng-container>
            <ng-container matColumnDef="imported_count">
              <th mat-header-cell *matHeaderCellDef width="10%" [hidden]="folderID=='AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA='">
                # Imported
              </th>
              <td style="text-align:center !important" mat-cell *matCellDef="let element" [hidden]="folderID=='AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA='">{{element.importItemCount}}</td>
            </ng-container>
            <ng-container matColumnDef="imported_by">
              <th mat-header-cell *matHeaderCellDef width="5%" [hidden]="folderID=='AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA='">
                Imported By
              </th>
              <td mat-cell *matCellDef="let element" [hidden]="folderID=='AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA='">{{element.importedBy}}</td>
            </ng-container>
            <ng-container matColumnDef="imported_date">
              <th mat-header-cell *matHeaderCellDef width="5"  [hidden]="folderID=='AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA='">
                Import Date
              </th>
              <td mat-cell *matCellDef="let element" [hidden]="folderID=='AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA='">{{element.lastImportDate}}</td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>

        </table>
      </div>
    </div>

    <div class="d-inline-flex">
      <div class="p-3">

        <!--<button [disabled]="buttonText=='Select'" mat-button class="btn-high mr-2" (click)="clickSave()">{{buttonText}}</button>-->
        <button *ngIf="folderID!='' && folderID!='AAMkAGQ0MGUyMzM3LTFhYTctNGZlNy04OTA1LTQyYzVkOTI5NDdmZgAuAAAAAADREfXC_2G5SIfyFmBJCccKAQDPiYf1uY1YQYwL6us9h7mYAAAAAAEMAAA='" mat-button class="btn-low mr-2" (click)="clickBack()">Cancel</button>
        <!--<button mat-button class="btn-low" [mat-dialog-close]>Close</button>-->
      </div>
        <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]="successMsg"></app-success-message>
      </div>
      <div *ngIf="errMsg!=''" class="alert alert-danger small p-0 mt-2">
        {{errMsg}}
      </div>
    </div>
  </div>
  <!--<div *ngIf="isNext">
    <app-contingency-edit-dialog [selectedGS]="selectedGS" [tripCodeGUID]="tripCodeGUID"></app-contingency-edit-dialog>
  </div>-->
  </div>
         
