import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';;
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { CountryModel } from '../models/country.model';
import { ResponseModel } from '../models/response.model';
import { FuelSupplierEditDialogComponent } from './fuel-supplier-profile-edit-dialog.component';
import { HotelChainModel } from '../models/hotel-chain.model';
import { FuelSupplierModel } from '../models/fuelsupplier.model'; 
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { FuelService } from '../services/fuel.service';
import { DialogService } from '../services/dialog.service';
import { FuelMenuComponent } from './fuel-menu.component';
import { ThirdPartyVendorsComponent } from '../common-utility/third-party-vendors.component';



@Component({
  selector: 'app-fuel-supplier-profile',
  templateUrl: './fuel-supplier-profile.component.html',
  styleUrls: ['./fuel-supplier-profile.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
})

export class FuelSupplierComponent implements OnInit {
  fuelsupplier_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  text_search: string;
  filter_by: string;
  includeDisabledRecords: boolean;
  recordList: FuelSupplierModel[];
  countryList: CountryModel[];
  selectedCountryGUID: string; 
  grantAccessToFuel: boolean = false;
  displayedColumn: string[] = ['FuelSupplierShortname', 'FuelSupplierName', 'profileCount', 'ModifiedDate', 'InternalNotes', 'action'];
  scrHeight: number;
  websiteEnvironment: string = "";
  isDialog: boolean = false;
  dialogFrom: string = '';


  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _fuelService: FuelService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private _titleService: Title, private readonly _dialogService: DialogService,
    @Optional() private readonly _dialogRef: MatDialogRef<FuelSupplierComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    ) {
      if (this.data) {
        this.isDialog = true;
        if(this.data.dialogFrom != undefined)
          this.dialogFrom = this.data.dialogFrom;

      }

  }

  getAllData(): Observable<any[]> {
    let getCountryResponse = this._commonService.getCountryListWithUSAFirst<ResponseModel<FuelSupplierModel[]>>();
   // let getHotelChainResponse = this._commonService.getHotelChainList<ResponseModel<HotelChainModel[]>>();
    //let getCountryResponse = this._commonService.getCountryListWithUSAFirst<ResponseModel<CountryModel[]>>();
    //let getHotelChainResponse = this._commonService.getHotelChainList<ResponseModel<HotelChainModel[]>>();
    return forkJoin([getCountryResponse]);//, getHotelChainResponse]);

  }

  ngOnInit() {
    this.grantAccessToFuel = false;
    if (localStorage.getItem('up.fuel') == 'true') {
      this.grantAccessToFuel = true;
    }


    if (!this.grantAccessToFuel) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.countryList = []; 
    this.selectedCountryGUID = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }


    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.countryList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      //if (responses[1] != null) {
      //  if (responses[1].code == "200" && responses[1].message == "") {
      //    this.hotelChainList = responses[1].model;
      //  }
      //  else {
      //    if (responses[1].code == "401") {
      //      this._authService.signOut();
      //    }
      //  }
      //}

      this.initControls();
      this.showSpin = false;
      this.getSearchResults();
    });

  }

  initControls() {
    this.fuelsupplier_form = this._formBuilder.group({
      fuelSupplierName: [''],
      fuelSupplierShortname: [''],
      //airport: [''],
      //city: [''],
      //country_select:[''],
      includeDisabledRecords: this.includeDisabledRecords
    });
  }

  get f() { return this.fuelsupplier_form.controls; }

  includeDisabledRecordsOnChange(e: any) {
    this.includeDisabledRecords = e.checked;
    //this.recordList = [];
    //this.totalRecordsText = '';
    //this.totalRecords = 0;
    //this.currentPageIndex = 0;
    //this.totalPageNumber = 0;
    //this.getSearchResults();
    this.resetSearch(e);
  }

  resetSearch(e: any) {
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults();
  }


  onSubmit() {

  }

  getSearchResults() {
    this.errMsg = "";
    this.msg = "";


    this.recordList = [];
    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new FuelSupplierModel();

    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = true;
    if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      request.isActive = !this.f.includeDisabledRecords.value;
    }

    this._fuelService.getActiveFuelSupplierList<ResponseModel<FuelSupplierModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          //if (this.recordList[0].totalRecords > 0) {
          //this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';

          //if (this.includeDisabledRecords == true) {

          //  this.totalRecordsText = response.model.length + ' Fuel Suppliers';
          //}
          //else {

          //  this.totalRecordsText = response.model.length + ' Active Fuel Suppliers';
          //}
          this.totalRecordsText = response.model.length + ' Records count';
          this.totalRecords = this.recordList[0].totalRecords;
          this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
          //}

        }
        else {
          this.recordList = [];
          this.msg = "No records found.";

          this.totalRecordsText = '';
          this.totalRecords = 0;
          this.currentPageIndex = 0;
          this.totalPageNumber = 0;
        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    })


  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
    }

    this.getSearchResults();

  }

  //clickReset() {
  //  this.errMsg = "";
  //  this.msg = "";
  //  this.recordList = [];
  //  this.totalRecordsText = '';
  //  this.totalRecords = 0;
  //  this.currentPageIndex = 0;
  //  this.totalPageNumber = 0;
  //  this.selectedPersonClass = this.personClassList[0];
  //  this.customerGUID = "";
  //  this.text_search = "";
  //  this.filter_by = "Name";
  //  this.initControls();
  //}

  clickEdit(event: any, p: FuelSupplierModel) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.disableClose = false;
    dialogConfig.width = "70em";//"1000px";
    //dialogConfig.height = "20em";//"500px";
    dialogConfig.disableClose = true;

    let fuelSupplierGUID = "";
    let s = "";
    if (p != null) {
      s = "EDIT Record";
      fuelSupplierGUID = p.fuelSupplierGUID;
    }
    else {
      s = "ADD New Record";
    }
    dialogConfig.data = { dialogTitle: s, fuelSupplierGUID: fuelSupplierGUID };
    const dialogRef = this._dialog.open(FuelSupplierEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // if (result.refresh) {
        //   this.getSearchResults();
        // }
        this.getSearchResults();
        //  if (this.f.hotelName.value == "" && this.f.hotel_chain_select.value == "" && this.f.airport.value == "" && this.f.city.value == "" && this.f.country_select.value == "") {
        //    this.f.country_select.setValue(result.countryGUID.toLowerCase());
        //    this.f.hotel_chain_select.setValue(result.hotelChainGUID.toLowerCase());
        //  }
        //  this.getSearchResults();
        //}

      }
    });

  }


  validDateFormat(dateString) {
   // const dateString = "11-10-23";
    const dateObject = new Date(dateString);
    if (dateString) {
      return dateObject;// dateString.replace(/\s/, 'T');
    }

    return null;

  }

  clickRoute(routePage: string) {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px"};
    if(this.isDialog){
      // this.clickclose();
      this._dialogRef.close();
    }
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(FuelMenuComponent, config);   
  }
  
  clickOpenInNew( ){
    this._dialogRef.close();

    window.open('fuelmenu/fuel-supplier-profile' + "?v=" + this._authService.getCurrentTimeNumber());
  }

  
  openThirdPartyVendorComponent(){
    this._dialogRef.close();
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "23em";//"1000px";
    config.width = "43em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {    isDialog: true,   v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(ThirdPartyVendorsComponent, config);
  }

   
}
