<div style="margin-top: 0em; padding-top: 0em; width: 100%;   padding: 0.5em 1em 0em 1em ; ">
  <form *ngIf="location_preferences_dialog_form" [formGroup]="location_preferences_dialog_form">
    <div>
      <div *ngIf="isDialog" style="display: flex; justify-content: space-between; width: 66.5em;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div style="margin-right:1em;display: flex; padding: 0.5em;"><span class="material-symbols-outlined"> description </span><h5>Fuel Supplier Location Profile - <span style="color: dodgerblue;"> {{fuelSupplierName}}</span> </h5></div>


        <div>
          <div style="width:6em">
            <app-spin *ngIf="showSpin2"></app-spin>
          </div>
        </div>



        <div *ngIf="isDialog" style="display: flex;">
          <button mat-icon-button (click)="clickOpenInNew()">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>

          <button mat-icon-button (click)="clickClose()"> <i class="material-icons" style="font-size: x-large;">close </i> </button>
        </div>
      </div>

      <div *ngIf="!isDialog" style="display: flex; justify-content: space-between; width: 49.5em;">
        <div style="margin-right:1em;display: flex; padding: 0.5em;"><span class="material-symbols-outlined"> description </span><h5>Fuel Supplier Location profile</h5></div>
        <div>
          <div style="width:6em">
            <app-spin *ngIf="showSpin2"></app-spin>
          </div>
        </div>
      </div>

      <div>

        <div style="width: 66em; display: flex;">

          <div style=" margin-right:0.25em">
            <button type="button" mat-button class="btn-low" style="width:8em;" [ngClass]="selectedStatusCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickStatusFilter()">Status{{selectedStatusCountText}}</button>
          </div>
          <div style=" margin-right:0.25em">
            <button type="button" mat-button class="btn-low" style="width:8em;" [ngClass]="selectedAirportCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickCycleFilter()">Cycle{{selectedCycleCountText}}</button>
          </div>



          <div style=" margin-right:0.25em">
            <button type="button" mat-button class="btn-low" style="width:8em;" [ngClass]="selectedAirportCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickAirportFilter()">Airport{{selectedAirportCountText}}</button>
          </div>

          <div style=" margin-right:0.25em">
            <button type="button" mat-button class="btn-low" style="width:7em;" [ngClass]="selectedCountryCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickCountryFilter()">Country{{selectedCountryCountText}}</button>
          </div>

          <div style=" margin-right:0.25em">
            <button type="button" mat-button class="btn-low" style="width:10em;" [ngClass]="selectedIntoPlaneAgentCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickIntoPlaneAgentFilter()">Into Plane Agent{{selectedIntoPlaneAgentCountText}}</button>
          </div>

          <div>
            <div style="    margin-left: 1em; margin-right: 1em; " align="end">
              <div style="  display:flex;    font-size:small">
                <div style="margin-top: -0.2em;"><b>Filter</b></div>
                <div>


                  <div style="display: flex; margin-left: 1em; ">
                    <div style="margin-right:0.25em">
                      <input id="factive" type="radio" name="filter_type" value="active" [checked]="searchFiltervalue==1"
                             (change)="searchFilterChange($event)" />
                    </div>
                    <label style="margin-top:-0.2em;white-space: nowrap " for="factive">Show Active Records</label>
                  </div>

                  <div style="display: flex; margin-left: 1em; ">
                    <div style="margin-right:0.25em">
                      <input id="fdisable" type="radio" name="filter_type" value="disable" [checked]="searchFiltervalue==0"
                             (change)="searchFilterChange($event)" />
                    </div>
                    <label style="margin-top:-0.2em;white-space: nowrap " for="fdisable">Show Disabled Records</label>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <button mat-button class="btn-high" style="height: 2em;" (click)="exportToExcel()"> Export To Excel</button>
          <button mat-button class="btn-low" (click)="clickReset()" style="margin-left: 1em; height: 2em; ">  Reset</button>
          <button mat-button class="btn-low" (click)="getData2()" style="margin-left: 1em; height: 2em; ">  Refresh</button>

        </div>
        <div style="display: flex;">

          <!--status list--><!--margin-left: 32.25em;-->
          <div *ngIf="showStatusList" style="float: left; min-width: 11em; margin-top: 0.25em; background-color: white; margin-left: 0em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
            <div style="display:flex; justify-content:space-between">
              <div style="font-weight: bold; text-decoration:underline">Status</div>
              <div style="float:right" (click)="showStatusList=!showStatusList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
            </div>
            <div style="font-size: small; margin-left:0em">
              <div style="margin-right:0.5em;">
                <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                  <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                  <input type="text" formControlName="statussearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />  <!--(keyup)="statusSearchChange($event)"-->
                </div>
              </div>
            </div>
            <div *ngIf="statusList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
              <div *ngFor="let v of filteredStatusList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                <div #tripStatusRefs [attr.statusGUID]="v.statusGUID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                  <div>
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="checkStatusChange($event, v)" [checked]="v.selected">{{v.status}}</mat-checkbox>
                  </div>

                </div>
              </div>
            </div>
            <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
            <div style="display: flex; float: right">
              <div style="margin-right:0.5em">
                <button type="button" mat-button class="btn-low" (click)="clickResetStatus()" style="height:2em">RESET</button>
              </div>
              <div>
                <button type="button" mat-button class="btn-high" (click)="clickDoneStatus()" style="height:2em">DONE</button>
              </div>
            </div>
          </div>
          <!--status list end-->
          <!--cycle list--><!--margin-left: 32.25em;-->
          <div *ngIf="showCycleList" style="float: left; min-width: 11em; margin-top: 0.25em; background-color: white; margin-left: 8em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
            <div style="display:flex; justify-content:space-between">
              <div style="font-weight: bold; text-decoration:underline">Cycles</div>
              <div style="float:right" (click)="showCycleList=!showCycleList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
            </div>
            <div style="font-size: small; margin-left:0em">
              <div style="margin-right:0.5em;">
                <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                  <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                  <input type="text" formControlName="cyclesearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />  <!--(keyup)="cycleSearchChange($event)"-->
                </div>
              </div>
            </div>
            <div *ngIf="cycleList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
              <div *ngFor="let v of filteredCycleList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                <div #tripCycleRefs [attr.cycleName]="v.cycleName" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                  <div>
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="checkCycleChange($event, v)" [checked]="v.selected">{{v.cycleName}}</mat-checkbox>
                  </div>

                </div>
              </div>
            </div>
            <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
            <div style="display: flex; float: right">
              <div style="margin-right:0.5em">
                <button type="button" mat-button class="btn-low" (click)="clickResetCycle()" style="height:2em">RESET</button>
              </div>
              <div>
                <button type="button" mat-button class="btn-high" (click)="clickDoneCycle()" style="height:2em">DONE</button>
              </div>
            </div>
          </div>
          <!--cycle list end-->
          <!--Airport list-->
          <div *ngIf="showAirportList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 16.5em; background-color: white;
          border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid;
          font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
            <div style="display:flex; justify-content:space-between">
              <div style="font-weight: bold; text-decoration:underline;">Airport ICAO</div>
              <div style="float:right" (click)="showAirportList=!showAirportList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
            </div>
            <div style="font-size: small; margin-left:0em">
              <div style="margin-right:0.5em;">
                <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                  <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                  <input type="text" formControlName="airportSearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameAirport($event)" />
                </div>
              </div>
            </div>
            <div *ngIf="airportList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
              <div *ngFor="let a of filteredAirportList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                <div #tripAirportRefs [attr.icao]="a.icao" style="display: flex; font-size: small; margin-top:-0.5em">
                  <div>
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="checkAirportChange($event, a)" [checked]="a.selected">{{a.icao}}</mat-checkbox>
                  </div>

                </div>
              </div>
            </div>
            <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
            <div style="display: flex; float: right; ">
              <div style="margin-right:0.5em">
                <button type="button" mat-button class="btn-low" (click)="clickResetAirport()" style="height:2em">RESET</button>
              </div>
              <div>
                <button type="button" mat-button class="btn-high" (click)="clickDoneAirport()" style="height:2em">DONE</button>
              </div>
            </div>
          </div>
          <!--Airport list end-->
          <!--country list--><!--margin-left: 32.25em;-->
          <div *ngIf="showCountryList" style="float: left; min-width: 11em; margin-top: 0.25em; background-color: white; margin-left: 24.8em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
            <div style="display:flex; justify-content:space-between">
              <div style="font-weight: bold; text-decoration:underline">Countries</div>
              <div style="float:right" (click)="showCountryList=!showCountryList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
            </div>
            <div style="font-size: small; margin-left:0em">
              <div style="margin-right:0.5em;">
                <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                  <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                  <input type="text" formControlName="countrysearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />  <!--(keyup)="countrySearchChange($event)"-->
                </div>
              </div>
            </div>
            <div *ngIf="countryList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
              <div *ngFor="let v of filteredCountryList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25">
                <div #tripCountryRefs [attr.countryGUID]="v.countryGUID" style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                  <div>
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="checkCountryChange($event, v)" [checked]="v.selected">{{v.countryName}}</mat-checkbox>
                  </div>

                </div>
              </div>
            </div>
            <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
            <div style="display: flex; float: right">
              <div style="margin-right:0.5em">
                <button type="button" mat-button class="btn-low" (click)="clickResetCountry()" style="height:2em">RESET</button>
              </div>
              <div>
                <button type="button" mat-button class="btn-high" (click)="clickDoneCountry()" style="height:2em">DONE</button>
              </div>
            </div>
          </div>
          <!--country list end-->
          <!--Into Plane Agent list-->
          <div *ngIf="showIntoPlaneAgentList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 32.2em; background-color: white;
          border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em;
          border-radius: 0.25em; z-index: 1000;position:absolute">
            <div style="display:flex; justify-content:space-between">
              <div style="font-weight: bold; text-decoration:underline;">Into Plane Agent</div>
              <div style="float:right" (click)="showIntoPlaneAgentList=!showIntoPlaneAgentList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
            </div>
            <div style="font-size: small; margin-left:0em">
              <div style="margin-right:0.5em;">
                <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                  <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                  <input type="text" formControlName="intoplaneagentsearch" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="displayNameIntoPlaneAgent($event)" />
                </div>
              </div>
            </div>
            <div *ngIf="intoplaneagentList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
              <div *ngFor="let a of filteredIntoPlaneAgentList | async; let i=index" [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                <div [attr.intoPlaneAgent]="a.intoPlaneAgent" style="display: flex; font-size: small; margin-top:-0.5em">
                  <div>
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="checkIntoPlaneAgentChange($event, a)" [checked]="a.selected">{{a.intoPlaneAgent}}</mat-checkbox>
                  </div>

                </div>
              </div>
            </div>
            <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
            <div style="display: flex; float: right">
              <div style="margin-right:0.5em">
                <button type="button" mat-button class="btn-low" (click)="clickResetIntoPlaneAgent()" style="height:2em">RESET</button>
              </div>
              <div>
                <button type="button" mat-button class="btn-high" (click)="clickDoneIntoPlaneAgent()" style="height:2em">DONE</button>
              </div>
            </div>
          </div>
          <!--Into Plane Agent list end-->



        </div>
        <!-- <div class="d-flex " style="  font-size: small;margin: 0.5em;"    >

          Total Records: {{totalRecords}}
        </div> -->
        <div>
          <div class="d-flex " style=" justify-content: end; margin-top: -1.1em;" align="end">
            <!--</div> style="margin-top: -1.05em;">-->
            <mat-paginator *ngIf="sortedData.length>0" style="background-color:transparent;" [pageSize]="25"
                           [showFirstLastButtons]="true" [length]="totalRecords" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page"
                           (page)="pageEvent = pageIndexChange($event)">
            </mat-paginator>
            <div *ngIf="sortedData.length == 0" style="height: 1.5em;">

            </div>
            <!--[pageSizeOptions]="[25, 50, 100, totalRecords]"-->
          </div>
        </div>
        <div style="height: 30em; overflow-y: auto; width: 68em;">
          <table mat-table [dataSource]="sortedData" matSort matSortDirection="asc" style="width: 66em; margin-left: 0.5em;">

            <tr>

              <ng-container matColumnDef="countryName">
                <th mat-header-cell *matHeaderCellDef style="flex:0 0 20%;width:20%;  text-align: left !important;padding-left: 0.5em !important">
                  Country Name
                </th>
                <td mat-cell *matCellDef="let element" style="flex:0 0 20%;width:20%;  text-align: left !important; padding-left: 0.5em !important;">{{ element.countryName}}</td>
              </ng-container>
              <ng-container matColumnDef="icao">
                <th mat-header-cell *matHeaderCellDef style="flex:0 0 5%;width:5%;   text-align: left !important;">
                  ICAO
                </th>
                <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;  text-align: left !important;">{{element.icao  }}</td>
              </ng-container>
              <ng-container matColumnDef="iata">
                <th mat-header-cell *matHeaderCellDef   style="flex:0 0 5%;width:5%;   text-align: left !important;" >
                  IATA
                </th>
                <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;  text-align: left !important;">{{element.iata }}</td>
              </ng-container>
              <ng-container matColumnDef="faaid">
                <th mat-header-cell *matHeaderCellDef   style="flex:0 0 5%;width:5%;   text-align: left !important;" >
                  FAAID
                </th>
                <td mat-cell *matCellDef="let element" style="flex:0 0 5%;width:5%;  text-align: left !important;">{{element.faaid }}</td>
              </ng-container>

              <ng-container matColumnDef="intoPlaneAgent">
                <th mat-header-cell *matHeaderCellDef style="flex:0 0 30%;width:30%;   text-align: left !important;">
                  Into Plane Agent
                </th>
                <td mat-cell *matCellDef="let element" style="flex:0 0 30%;width:30%;  text-align: left !important;   padding-left: 0.7em !important;">{{ element.intoPlaneAgent}}</td>
              </ng-container>

              <!-- <ng-container matColumnDef="effectiveDate">
                <th mat-header-cell *matHeaderCellDef   style=" text-align: left !important; flex:0 0 9%;width:9%;" >
                  Effective Date
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important; flex:0 0 9%;width:9%;   padding-left: 0.7em !important;">{{ element.effectiveDate}}</td>
              </ng-container> -->

              <ng-container matColumnDef="isDaily">
                <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%;">
                  <div style="margin-top:-0.25em;">Daily</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
                  <mat-checkbox [(ngModel)]="element.isDaily" (change)="changeIsDailyValue(element)" [ngModelOptions]="{standalone: true}"
                                style="transform:scale(.8)"> </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="isWeekly">
                <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%;">
                  <div style="margin-top:-0.25em;">Weekly</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
                  <mat-checkbox [(ngModel)]="element.isWeekly" (change)="changeIsWeeklyValue(element)" [ngModelOptions]="{standalone: true}"
                                style="transform:scale(.8)"> </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="isBiWeekly">
                <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 7%;width:7%;">
                  <div style="margin-top:-0.25em;">Bi-Weekly</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 7%;width:7%">
                  <mat-checkbox [(ngModel)]="element.isBiWeekly" (change)="changeisBiWeeklyValue(element)" [ngModelOptions]="{standalone: true}"
                                style="transform:scale(.8)"> </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="isMonthly">
                <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%;"> 
                  <div style="margin-top:-0.25em;"   matTooltipClass="tooltipLineBreak" matTooltip="{{monthlyTooltip}}">Monthly</div>  
                </th>
                <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
                  <mat-checkbox [(ngModel)]="element.isMonthly" (change)="changeIsMonthlyValue(element)" [ngModelOptions]="{standalone: true}"
                                style="transform:scale(.8)"> </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="isBiMonthly">
                <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%;">
                  <div style="margin-top:-0.25em;">BiMonthly</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
                  <mat-checkbox [(ngModel)]="element.isBiMonthly" (change)="changeIsBiMonthlyValue(element)" [ngModelOptions]="{standalone: true}"
                                style="transform:scale(.8)"> </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="is30Days">
                <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%;"> 
                  <div style="margin-top:-0.25em;"   matTooltipClass="tooltipLineBreak" matTooltip="{{thirtyDaysTooltip}}" >30 Days</div>  
                </th>
                <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
                  <mat-checkbox [(ngModel)]="element.is30Days" (change)="changeIs30DaysValue(element)" [ngModelOptions]="{standalone: true}" 
                  style="transform:scale(.8)"> </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style=" text-align: left !important; flex:0 0 7%;width:7%;">
                  Status
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important; flex:0 0 7%;width:7%;">{{element.status }}</td>
              </ng-container>

              <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%;">
                  <div style="margin-top:-0.25em;">Active</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
                  <mat-checkbox [(ngModel)]="element.isActive" (change)="changeCycleValue(element)" [ngModelOptions]="{standalone: true}"
                                style="transform:scale(.8)"> </mat-checkbox>
                </td>
              </ng-container>


            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
      </div>

      <div style="display: flex; justify-content: space-between;">

        <div style="display: flex;">

          <button mat-button class="btn-high" style="margin-top: 1.5em;" (click)="clickSave()" [disabled]="!isModified">  Save</button>
          <button mat-button class="btn-med" (click)="impotFuelPriceLocationPreferences(false)" style="margin-left: 1em;margin-top: 1.5em;"><span>Import Fuel Price Location Information </span></button>
          <button mat-button class="btn-med" (click)="impotFuelPriceLocationPreferences(true)" style="margin-left: 1em;margin-top: 1.5em; "><span class="mat-button-wrapper">Import Location Profile</span></button>
          <!-- <button mat-button class="btn-low" (click)="clickClose()" style="margin-left: 1em;margin-top: 1.5em;"><i class="material-icons" style="font-size: medium;">close </i> Close</button> -->

          <div *ngIf="showSuccessMsg" style="width: 20em; margin-left: 1em; margin-top: 1em;  height: 1em; ">
            <app-success-message [successMsg]="successMsg"></app-success-message>
            <div *ngIf="errMsg!=''" class="alert d-flex align-items-center" role="alert" style=" background-color:#f9d2de; min-width:25em; padding-left:0.25em; padding-right:0.25em; font-size:small;height:2.5em">
              <div>{{errMsg}}</div>
            </div>
          </div>
        </div>

        <button mat-button class="btn-high" *ngIf="isActive  " style="margin-top: 1.5em;margin-left:  0em;color: white !important; background: #e55c5c !important;border-color: #eb6760 !important;" (click)="showDisableConfirmationAlert()" [disabled]="sortedData.length == 0">  Deactivate All Records</button>
      </div>

    </div>
    <!-- <div style="display: flex; padding: 1em 1em 2em 1em;">
       <input style="display: none"  [attr.disabled]="isFileInputDisabled ? '' : null" #document formControlName="document" id="document" type="file" [uploader]="uploader" accept=".xlsx" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />

       <button mat-button class="button" [disabled]="isFileInputDisabled" (click)="document.click()">Select a File to Import</button>
      <input #filename [attr.disabled]="true" id="filename" type="text" class="text-line" [value]="attachedfilename" />

    </div> -->

  </form>

  <!-- <div *ngIf="showSuccessMsg">
    <app-success-message [successMsg]="successMsg"></app-success-message>
    <div *ngIf="errMsg!=''"  class="alert d-flex align-items-center" role="alert" style=" background-color:#f9d2de; min-width:25em; padding-left:0.25em; padding-right:0.25em; font-size:small;height:2.5em">
       <div>{{errMsg}}</div>
    </div>
  </div> -->
</div>
