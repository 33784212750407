<div style="margin-top:-1em;border:solid;border-width:thin;border-color:lightgray;">

    <form *ngIf="aircraft_flight_planning_form" [formGroup]="aircraft_flight_planning_form">
      <div style="background-color:white;padding-left:.5em;padding-right:.5em">
        <div class="row no-gutters">
          <div class="col-2 pr-1">
            <div style="height:.5em"></div>
            <div class="container-fluid" style="background-color:#f8f8f8;min-height:17em">
              <div class="row justify-content-start">
                <div class="col-sm-12 form-group">
                  <label class="small font-weight-bold" style="text-align:left;font-size:small" for="fp_provider">Flight Plan Provider</label>
                  <select *ngIf="f" formControlName="fp_provider" class="form-control form-control-sm dropdown" style="margin-top:-0.35em;width:min-content" [ngClass]="{ 'is-invalid': submitted && f.fp_provider.errors }" (change)="changeFPProvider()">
                    <option *ngFor="let fp of fpList" [ngValue]="fp.flightPlanProviderID">{{fp.flightPlanProviderName}}</option>
                  </select>
                  <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    Flight Plan Provider is required.
                  </span>
                </div>
              </div>
              <div class="row justify-content-start">
                <div class="col-sm-12">
                  <label class="font-weight-bold small">Datalink Information</label>
                </div>
              </div>
              <div class="row justify-content-start">
                <div class="col-sm-12 form-group">
                  <label for="uplink_vendor" style="font-size:small">Datalink Provider</label>
                  <select formControlName="uplink_vendor" *ngIf="f" class="form-control form-control-sm dropdown" style="width:min-content;margin-top:-.35em" [ngClass]="{ 'is-invalid': submitted && f.uplink_vendor.errors }">
                    <option value="None">None</option>
                    <option value="ARINCDirect">ARINCDirect</option>
                    <option value="GDC">GDC</option>
                    <option value="Satcom">Satcom</option>
                  </select>
                  <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    Datalink Provider is required.
                  </span>

                </div>
              </div>
              <div class="row justify-content-start">
                <div class="col-sm-12 form-group">
                  <label for="uplink_frequency" style="font-size:small">Flight Plan Uplink?</label>

                  <select formControlName="uplink_frequency" *ngIf="f" class="form-control form-control-sm dropdown" style="width:min-content;margin-top:-.35em" [ngClass]="{ 'is-invalid': submitted && f.uplink_frequency.errors }">
                    <option value="1">Always</option>
                    <option value="3">Never</option>
                    <option value="2">On Demand</option>

                  </select>
                  <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    Required.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5 px-1">
            <div style="height:.5em"></div>
            <div class="container-fluid" style="background-color:#f8f8f8;min-height:17em">
              <div class="row justify-content-start">
                <div class="col-sm-12">
                  <label class="small font-weight-bold" style="text-align: left; font-size: small">Reserve Policies</label>
                </div>
              </div>
              <div *ngIf="f.fp_provider.value==null || f.fp_provider.value==undefined">
                <div class="row justify-content-start">
                  <div class="col-sm-12">
                    <label  style="font-size:small">Select a Flight Plan Provider to enter Reserve Policies.</label>
                  </div>
                </div>
              </div>
              <div *ngIf="f.fp_provider.value==1">
                <div class="row justify-content-start">
                  <div class="col-sm-12 form-group">
                    <label for="intl_res" style="font-size:small">International</label>
                    <select *ngIf="f" formControlName="intl_res" style="margin-top:-.35em" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.intl_res.errors }">
                      <option *ngFor="let rs of reserveList" [ngValue]="rs.reservePolicyGuid">{{rs.policyNumber + ' | ' + rs.policyDescription}}</option>
                    </select>
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Reserve Policy is required.
                    </span>
                  </div>
                </div>
                <hr />
                <div class="row justify-content-start">
                  <div class="col-sm-6">
                    <label for="dom_res" style="font-size:small">Domestic</label>
                  </div>
                  <div class="col-sm-6">
                    <mat-checkbox #chkSameAsIntl style="transform:scale(.75)" (change)="checkSameAsIntl()" [checked]="isSame">Same as International</mat-checkbox>
                  </div>
                </div>
                <div class="row justify-content-start">
                  <div class="col-sm-12 form-group">
                    <select *ngIf="f" formControlName="dom_res" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.dom_res.errors }">
                      <option *ngFor="let rs of reserveList" [ngValue]="rs.reservePolicyGuid">{{rs.policyNumber + ' | ' + rs.policyDescription}}</option>
                    </select>
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Reserve Policy is required.
                    </span>
                  </div>
                </div>

              </div>
              <div *ngIf="f.fp_provider.value!=1  && f.fp_provider.value!=null && f.fp_provider.value!=undefined ">
                <div class="row justify-content-center text-center" style="margin-top:-.25em" >
                  <div class="col-sm-12">
                    <label  style="font-size:small">International</label>
                  </div>
                </div>
                <div class="row justify-content-center text-center" style="margin-top:-.75em">
                  <div class="col-sm-12">
                    <label style="font-size:x-small">(H+MM, XX% total fuel burn, or XXXXX lbs/kgs)</label>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-sm-6 form-group">
                    <label for="reserve" style="font-size:small">Reserve/Contingency</label>
                    <input type="text" *ngIf="f" formControlName="reserve" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.reserve.errors }" />
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Reserve is required.
                    </span>
                  </div>
                  <div class="col-sm-6 form-group">
                    <label for="hold" style="font-size:small">Hold</label>
                    <input type="text" *ngIf="f" formControlName="hold" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.hold.errors }" />
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Hold is required.
                    </span>
                  </div>
                </div>
                <hr style="margin-top:-.25em" />
                <div class="row justify-content-center text-center" style="margin-top:-.25em" >
                  <div class="col-sm-6">
                    <label  style="font-size:small">Domestic</label>
                  </div>
                  <div class="col-sm-6">
                    <mat-checkbox #chkSameAsIntlTxt (change)="checkSameAsIntlTxt()" [checked]="isSameTxt" style="transform:scale(.75);margin-top:-.2em">Same as International</mat-checkbox>
                  </div>
                </div>

                <div class="row justify-content-center text-center">
                  <div class="col-sm-12" style="margin-top:-.75em">
                    <label style="font-size:x-small">(H+MM, XX% total fuel burn, or XXXXX lbs/kgs)</label>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-sm-6 form-group">
                    <label for="domReserve" style="font-size:small">Reserve/Contingency</label>
                    <input type="text" *ngIf="f" formControlName="reserve" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.reserve.errors }" />
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Reserve is required.
                    </span>
                  </div>
                  <div class="col-sm-6 form-group">
                    <label for="domHold" style="font-size:small">Hold</label>
                    <input type="text" *ngIf="f" formControlName="hold" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.hold.errors }" />
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Hold is required.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5 pl-1">
            <div style="height:.5em"></div>
            <div class="container-fluid" style="background-color:#f8f8f8;min-height:17em">
              <div class="row justify-content-start">
                <div class="col-sm-12">
                  <label class="font-weight-bold small">ATC Filing Information</label>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-sm-3 form-group">
                  <label for="color" style="font-size:small">Aircraft Color</label>
                  <input type="text" *ngIf="f" formControlName="color" class="form-control inputBox" style="margin-top:-.35em" />
                </div>

                <div class="col-sm-2 form-group">
                  <label for="sel_cal" style="font-size:small">SELCAL</label>
                  <input type="text" *ngIf="f" formControlName="sel_cal" maxlength="4" class="form-control inputBox text-uppercase" style="margin-top:-.35em" [ngClass]="{ 'is-invalid': submitted && f.sel_cal.errors }" />
                  <div *ngIf="f.sel_cal.errors" class="invalid-tooltip alert alert-danger p-0 small" style="width:max-content">
                    <div *ngIf="submitted && f.sel_cal.errors.pattern">Must Be letters</div>
                    <div *ngIf="submitted && f.sel_cal.errors.minlength">Requries 4 characters</div>
                  </div>
                </div>

                <div class="col-sm-7 form-group">
                  <label for="elt_beacon" style="font-size:small">15 Character ELT Beacon ID</label>
                  <input type="text" *ngIf="f" formControlName="elt_beacon" class="form-control inputBox" maxlength="15" style="margin-top:-.35em" />

                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-sm-8 form-group">
                  <label for="file_by_aircraft" style="font-size:small">File by Aircraft Registration</label>

                  <select *ngIf="f" formControlName="file_by_aircraft" class="form-control form-control-sm dropdown" style="width:min-content;margin-top:-.35em" [ngClass]="{ 'is-invalid': submitted && f.file_by_aircraft.errors }">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
                <div class="col-sm-4 form-group">
                  <label for="hex_code" style="font-size:small">HEX Code</label>
                  <input type="text" *ngIf="f" formControlName="hex_code" class="form-control inputBox text-uppercase" maxlength="6" style="margin-top:-.35em" [ngClass]="{ 'is-invalid': submitted && f.hex_code.errors }" />
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-sm-12 form-group">
                  <label for="filing_company_name" style="font-size:small">Filing Company Name</label>

                  <input type="text" *ngIf="f" formControlName="filing_company_name" class="form-control inputBox" style="margin-top:-.35em" [ngClass]="{ 'is-invalid': submitted && f.filing_company_name.errors }" />
                  <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                    Filing company name is required.
                  </span>
                </div>
                </div>
              </div>
            </div>
        </div>
          </div>
      
</form>
    <div *ngIf="userType=='internal'" class="d-inline-flex" style="height:3em">
      <div>
        <button mat-button class="btn-high m-3" (click)="clickSave()">Save</button>
      </div>
      <div class="mt-2">
        <app-success-message [successMsg]="" *ngIf="showSuccessMsg"></app-success-message>
      </div>
    </div>
  </div>

