<div class="row" style="margin-bottom:15px;">
  <div class="col-md-3">
    <input #document   id="document" type="file"  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          maxFileSize="28000000"   />
    <!--<input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)">-->
    <button type="button" class="btn btn-success" (click)="uploadfile(document.files)">Upload File</button>
  </div>
  <div class="col-md-4">
    <span class="upload" *ngIf="progress > 0">
      {{progress}}%
    </span>
    <span class="upload" *ngIf="message">
      {{message}}
    </span>
  </div>
</div>
