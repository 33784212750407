<form *ngIf="business_rules_edit_form" [formGroup]="business_rules_edit_form">
  <div cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>

  <div>

    <div style="margin-top:-.5em">
      <mat-tab-group (selectedTabChange)="getSimilarBusinessRules($event)">
        <mat-tab label="{{title}}">
          <div class="d-inline-flex" style="width:69.5em;height:30.5em;">
            <div>
              <!--Busines Rule Start-->
              <div class="p-2" style="background-color:#ffffff;border-radius:.25em;">
                <div>
                  <h6>Business Rule</h6>
                  <div class="form-group form-inline">
                    <label style="width: 6em;font-size:small" for="customer_select" class="justify-content-start">Client</label>
                    <select formControlName="customer_select" (change)="updateDropDowns(f.customer_select.value)" class="form-control form-control-sm dropdown">
                      <option value="">All</option>
                      <option *ngFor="let cus of customerList" [ngValue]="cus.customerGUID">{{cus.customerName}}</option>
                    </select>
                  </div>
                  <div class="form-group form-inline">
                    <label style="width: 6em;font-size:small" class="small justify-content-start">Registration</label>
                    <mat-select #matAircraftCheck placeholder="All" panelClass="custom-mat-select-panel7 placeholder1" formControlName="aircraft_select" multiple style="border: solid lightgray 0.0625em; font-size: small; color:black; border-radius: 0.25em; padding-left:0.25em; height:1.65em;width:12em; background-color: #eaf0fd;">
                      <mat-select-trigger>
                        <div style="display:flex;">
                          <div *ngFor="let ac of aircraftGUIDList; let i=index" style="margin-top:0.4em;margin-left:0em; height:1.35em; padding:0em;display:flex "><div style="margin-top:-0.5em; margin-left:0em; white-space:nowrap;">{{i==0? ac.registration:', '+ac.registration}}</div></div>
                        </div>
                      </mat-select-trigger>
                      <div>
                        <mat-option *ngFor="let ac of aircraftList" [value]="ac" (onSelectionChange)="onRegistrationChange($event, ac)" style="height:1.5em; line-height:1.5em !important;background-color: #eaf0fd;">{{ac.registration}} </mat-option>
                      </div>
                    </mat-select>

                  </div>
                  <div class="form-group form-inline">
                    <label  style="width: 6em;font-size:small" class="small justify-content-start">PIC/SIC</label>
                    <mat-select #matPICCheck placeholder="All" panelClass="custom-mat-select-panel8 placeholder1" formControlName="crew_select" multiple style="border: solid lightgray 0.0625em; font-size: small; color:black; border-radius: 0.25em; padding-left:0.25em; height:1.65em;width:12em; background-color: #eaf0fd;">
                      <mat-select-trigger>
                        <div style="display:flex;">
                          <div *ngFor="let c of picGuidList; let i=index" style="margin-top:0.4em;margin-left:0em; height:1.35em; padding:0em;display:flex "><div style="margin-top:-0.5em; margin-left:0em; white-space:nowrap;">{{i==0? c.lastName + ', ' + c.firstName:', '+ c.lastName + ', ' + c.firstName}}</div></div>
                        </div>
                      </mat-select-trigger>
                      <div>
                        <mat-option *ngFor="let c of crewList" [value]="c" (onSelectionChange)="onCrewChange($event, c)" style="height:1.5em; line-height:1.5em !important;background-color: #eaf0fd;">{{c.lastName + ', ' + c.firstName}}</mat-option>
                      </div>
                    </mat-select>
                  </div>
                  <div class="form-group form-inline">
                    <label  style="width: 6em;font-size:small" class="small justify-content-start">PAX</label>
                    <mat-select #matPaxCheck placeholder="All" panelClass="custom-mat-select-panel8 placeholder1" formControlName="pax_select" multiple style="border: solid lightgray 0.0625em; font-size: small; color:black; border-radius: 0.25em; padding-left:0.25em; height:1.65em;width:12em; background-color: #eaf0fd;">
                      <mat-select-trigger>
                        <div style="display:flex;">
                          <div *ngFor="let p of paxGuidList; let i=index" style="margin-top:0.4em;margin-left:0em; height:1.35em; padding:0em;display:flex "><div style="margin-top:-0.5em; margin-left:0em; white-space:nowrap;">{{i==0? p.lastName + ', ' + p.firstName:', '+ p.lastName + ', ' + p.firstName}}</div></div>
                        </div>
                      </mat-select-trigger>
                      <div>
                        <mat-option *ngFor="let p of paxList" [value]="p" (onSelectionChange)="onPaxChange($event, p)" style="height:1.5em; line-height:1.5em !important;background-color: #eaf0fd;">{{p.lastName + ', ' + p.firstName}} </mat-option>
                      </div>
                    </mat-select>
                  </div>
                </div>
                <div>
                  <div class="form-group form-inline">
                    <label  style="width: 6em;font-size:small" class="small justify-content-start">MTOW</label>
                    <div>
                      <label style="font-size:x-small">Min</label>
                      <input type="text" class="form-control form-control-sm inputBox mr-2" formControlName="mtow_min" style="width:5em;margin-top:-.5em" />
                    </div>
                    <div>
                      <label style="font-size:x-small">Max</label>
                      <input type="text" class="form-control form-control-sm inputBox" formControlName="mtow_max" style="width:5em;margin-top:-.5em" />
                    </div>
                  </div>
                  <div class="form-group form-inline">
                    <label  style="width: 6em;font-size:small" class="small justify-content-start">Wing Span</label>
                    <div>
                      <label style="font-size:x-small">Min</label>
                      <input type="text" class="form-control form-control-sm inputBox mr-2" formControlName="wing_span_min" style="width:5em;margin-top:-.5em" />
                    </div>
                    <div>
                      <label style="font-size:x-small">Max</label>
                      <input type="text" class="form-control form-control-sm inputBox mr-1" formControlName="wing_span_max" style="width:5em;margin-top:-.5em" />
                    </div>

                  </div>
                  <div class="form-group form-inline">

                    <label  style="width: 6em;font-size:small" class="small justify-content-start">FAR Type</label>
                    <select formControlName="far_select" class="form-control form-control-sm dropdown">
                      <option value="0" selected>All</option>
                      <option *ngFor="let far of farTypeList" [ngValue]="far.farTypeID">{{far.farTypeDescription}}</option>
                    </select>

                  </div>

                </div>
              </div>
              <!--Busines Rule End-->
              <!--Area of Interest Start-->
              <div>
                <div class="p-2 mt-2" style="border-radius:.25em;background-color:white">
                  <h6>Area of Interest</h6>

                  <div class="form-group form-inline">

                    <label style="font-size: small; width: 6em " class="small justify-content-start">Country</label>

                    <select class="form-control form-control-sm dropdown" formControlName="country_select" (change)="setCountry()">
                      <option value="">All</option>
                      <option *ngFor="let c of countryList" [ngValue]="c.countryGUID">{{c.countryName}}</option>
                    </select>
                  </div>
                  <div class="d-flex">
                    <div class="form-group form-inline">
                      <label style="font-size: small; width: 6em" class="small justify-content-start">Airport</label>
                      <input type="text" class="form-control form-control-sm inputBox mr-1" formControlName="icao" (change)="validateAirport($event)" style="width:5em;" />
                      <label *ngIf="airportErrMsg!='' && airportErrMsg!=null && airportErrMsg!=undefined" class="alert-danger small p-1">{{airportErrMsg}}</label>
                    </div>
                    <mat-slide-toggle style="transform:scale(.8);" color="primary" [checked]="avoidLocation" formControlName="avoidLocation">Avoid Location</mat-slide-toggle>
                  </div>
                  <div class="form-inline">
                    <label style="font-size: small; width: 6em" class="small justify-content-start">Leg Type</label>
                    <select class="form-control form-control-sm dropdown" formControlName="leg_type_select">
                      <option value="0">All</option>
                      <option *ngFor="let leg of legTypeList" [ngValue]="leg.tripLegTypeID">{{leg.tripLegTypeDescription}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <!--Area of Interest End-->


            </div>
            <div>
              <!--Period Start-->
              <div class="d-inline-flex">
                <div>
                  <div class="p-2 ml-2 d-inline-flex" style="background-color:#ffffff;border-radius:.25em;">
                    <div>
                      <h6>Period</h6>
                      <div>
                        <div class="d-inline-flex">
                          <div class="form-group">

                            <label for="effectiveDate" style="font-size:small">Effective Date</label>
                            <div style="display:flex;margin-top:-0.35em">
                              <input type="text" [matDatepicker]="effectivedatepicker" tabindex="4" placeholder="MM/DD/YYYY" formControlName="effectiveDate" #effectiveDate class="form-control" (dateChange)="dateOnchange('S', $event)" [ngClass]="{ 'is-invalid': submitted && f.effectiveDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                              <mat-datepicker-toggle matSuffix [for]="effectivedatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                              <mat-datepicker #effectivedatepicker></mat-datepicker>
                            </div>
                            <div *ngIf="f.effectiveDate.errors" class="alert-danger p-0" style="font-size:small">
                              <div *ngIf="f.effectiveDate.errors.isValidDate==false">Invalid effective date. format: MM/DD/YYYY</div>
                              <div *ngIf="f.effectiveDate.errors.required && submitted">Effective date is required.</div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="expirationDate" style="font-size:small">Expiration Date</label>
                            <div style="display:flex;margin-top:-0.35em">
                              <input type="text" [matDatepicker]="expirationdatepicker" tabindex="5" placeholder="MM/DD/YYYY" formControlName="expirationDate" #expirationDate class="form-control" (dateChange)="dateOnchange('E', $event)" [ngClass]="{ 'is-invalid': submitted && f.expirationDate.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                              <mat-datepicker-toggle matSuffix [for]="expirationdatepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em"></mat-datepicker-toggle>
                              <mat-datepicker #expirationdatepicker></mat-datepicker>
                            </div>
                            <div *ngIf="f.expirationDate.errors" class="alert-danger p-0" style="font-size:small;width:max-content">
                              <div *ngIf="f.expirationDate.errors.isValidDate==false">Invalid effective date. format: MM/DD/YYYY</div>
                            </div>

                          </div>
                        </div>
                        <div *ngIf="f.expirationDate.errors && f.expirationDate.errors.matEndDateInvalid" class="alert-danger p-0" style="font-size:small;margin-top:-1em">Expiration date cannot be before effective date.</div>
                      </div>
                      <div>
                        <div class="d-inline-flex">
                          <div class="form-group">
                            <label  style="font-size:small">Hours Start</label>
                            <input type="text" formControlName="hoursStart" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.hoursStart.errors }" style="width:4em;margin-top:-0.35em" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('hoursStart', $event)" />

                          </div>
                          <div class="form-group ml-3">
                            <label  style="font-size:small">Hours End</label>
                            <input type="text" formControlName="hoursEnd" matInput placeholder="hh:mm" maxlength="5" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.hoursEnd.errors }" style="width:4em;margin-top:-0.35em" pattern="^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" (change)="timeOnchange('hoursEnd', $event)" />

                          </div>

                          <div class="form-group">
                            <div class="mt-4 ml-2">
                              <mat-slide-toggle style="transform:scale(.8)" color="primary" [checked]="isUTC" formControlName="isUTC">UTC</mat-slide-toggle>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="d-inline-flex">
                          <div class="form-group">
                            <div>
                              <label  style="font-size:small">Days of the Week</label>
                            </div>
                            <div>
                              <mat-checkbox [(ngModel)]="checkAllDays" (change)="checkDays('all')" [ngModelOptions]="{standalone: true}" style="transform: scale(.8); margin-top: -.35em">Check All</mat-checkbox>

                              <mat-checkbox [(ngModel)]="checkWeekdays" (change)="checkDays('weekdays')" [ngModelOptions]="{standalone: true}" style="transform: scale(.8); margin-top: -.35em">Check Weekdays</mat-checkbox>
                            </div>
                            <table style="font-size: small; margin-top: -.35em" class="small dayTable">
                              <tr>
                                <th>Sun</th>
                                <th>Mon</th>
                                <th>Tue</th>
                                <th>Wed</th>
                                <th>Thu</th>
                                <th>Fri</th>
                                <th>Sat</th>
                              </tr>
                              <tr>
                                <td><mat-checkbox formControlName="sun" style="transform:scale(.8)"></mat-checkbox></td>
                                <td><mat-checkbox formControlName="mon" style="transform:scale(.8)"></mat-checkbox></td>
                                <td><mat-checkbox formControlName="tue" style="transform:scale(.8)"></mat-checkbox></td>
                                <td><mat-checkbox formControlName="wed" style="transform:scale(.8)"></mat-checkbox></td>
                                <td><mat-checkbox formControlName="thu" style="transform:scale(.8)"></mat-checkbox></td>
                                <td><mat-checkbox formControlName="fri" style="transform:scale(.8)"></mat-checkbox></td>
                                <td><mat-checkbox formControlName="sat" style="transform:scale(.8)"></mat-checkbox></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <!--Aprroval Start-->
                  <div style="border-radius:.25em;background-color:lightblue" class="ml-2 p-2">
                    <h6>Approval</h6>
                    <div class="form-group form-inline">

                      <label  style="font-size:small">Approval Status</label>
                      <select class="form-control form-control-sm dropdown ml-1" formControlName="br_status">
                        <option *ngFor="let s of businessRulesApprovalStatusList" [ngValue]="s.businessRulesApprovalStatusID">{{s.businessRulesApprovalStatusDescription}}</option>
                      </select>
                    </div>

                  </div>
                  <!--Aprroval End-->
                  <!--Remarks-->
                  <div style="border-radius:.25em;background-color:white" class="ml-2 mt-2 p-2">
                    <h6>Remarks</h6>
                    <div class="form-group mt-1">
                      <textarea formControlName="remarks" class="form-control" style="width:30em; height:11.75em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd"></textarea>
                    </div>
                  </div>
                  <!--Remarks End-->




                </div>
              </div>
              <!--Period End-->
              <!--Services Start-->
              <div>
                <div class="p-2 ml-2 mt-2" style="border-radius:.25em;background-color:white">
                  <h6>Services, Tasks & Vendors</h6>
                  <div>
                    <div class="d-flex">
                      <div class="form-group form-inline">
                        <label style="font-size: small; width: 6.5em" class="small justify-content-start">Service Class</label>
                        <select class="form-control form-control-sm dropdown" formControlName="serviceClassID" (change)="updateServiceTypeList()">
                          <option value="0">All</option>
                          <option *ngFor="let sc of serviceClassList" [ngValue]="sc.serviceClassID">{{sc.serviceClassDescription}}</option>
                        </select>
                      </div>
                      <div class="form-group form-inline ml-3">
                        <label style="font-size: small; width: 6.5em" class="small justify-content-start">Task Status</label>
                        <select class="form-control form-control-sm dropdown" formControlName="taskStatus">
                          <option *ngFor="let s of taskStatusList" [ngValue]="s.taskStatusGUID">{{s.taskStatusDescription}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="form-group form-inline">
                        <label style="font-size: small; width: 6.5em" class="small justify-content-start">Service</label>

                        <select class="form-control form-control-sm dropdown" formControlName="serviceTypeID" (change)="getVendorList(f.serviceTypeID.value,f.country_select.value)">
                          <option value="0">All</option>
                          <option *ngFor="let s of serviceTypeList" [ngValue]="s.serviceTypeID">{{s.serviceTypeDescription}}</option>
                        </select>
                      </div>
                      <div class="form-group form-inline ml-3" *ngIf="f.serviceTypeID.value==3 || f.serviceTypeID.value==88">

                        <label style="font-size: small;" class="small justify-content-start mr-1">Permit Country</label>

                        <select class="form-control form-control-sm dropdown" formControlName="permit_country_select" (change)="getVendorList(f.serviceTypeID.value,f.permit_country_select.value)">
                          <option value="">All</option>
                          <option *ngFor="let c of permitCountryList" [ngValue]="c.countryGUID">{{c.countryName}}</option>
                        </select>
                      </div>
                      <div class="ml-2" *ngIf="f.serviceTypeID.value!=3 && f.serviceTypeID.value!=88">
                        <mat-slide-toggle style="transform:scale(.8)" color="primary" [checked]="excludeFromHomebase" formControlName="excludeFromHomebase">Exclude From Homebase</mat-slide-toggle>
                      </div>

                    </div>
                    
                    <div class="d-flex">
                      <div class="form-group form-inline">
                        <label class="small justify-content-start" style="font-size: small; width: 6.5em">{{f.serviceTypeID.value==7||f.serviceTypeID.value==20? 'Hotel': f.serviceTypeID.value==56? 'Fuel Supplier':'Vendor'}}</label>
                        <select *ngIf="f.serviceTypeID.value!=7 && f.serviceTypeID.value!=20 && f.serviceTypeID.value!=56" class="form-control form-control-sm dropdown" formControlName="vendorID" style="width: 28em;">
                          <option value="">All</option>
                          <option *ngFor="let v of vendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
                        </select>
                        <select *ngIf="f.serviceTypeID.value==7 || f.serviceTypeID.value==20" class="form-control form-control-sm dropdown" formControlName="hotelID" style="width: 28em;">
                          <option value="">All</option>
                          <option *ngFor="let v of hotelList" [ngValue]="v.hotelGUID">{{v.hotelName}}</option>
                        </select>
                        <select *ngIf="f.serviceTypeID.value==56" formControlName="fuel_supplier_select" class="form-control form-control-sm dropdown" style="width:12.75em" (change)="fuelSupplierChange($event)">
                          <option value="" selected>All</option>
                          <option *ngFor="let v of vendorList" [ngValue]="v.vendorName">{{v.vendorName}}</option>
                        </select>
                      </div>
                      
                          <div *ngIf="f.fuel_supplier_select.value!='' && f.serviceTypeID.value==56" class="form-group form-inline ml-2">
                            <label style="font-size: small;" class="small justify-content-start mr-1">Into Plane Agent</label>
                              <select *ngIf="f" formControlName="intoplane_agent_select" class="form-control form-control-sm dropdown" style="width:12.75em">
                                <option value="" selected></option>
                                <option *ngFor="let i of intoPlaneAgentList" [ngValue]="i.vendorGUID">{{i.intoPlaneAgent}}</option>
                              </select>
                            
                          </div>
                       
                      <div>
                        <mat-slide-toggle style="transform:scale(.8)" color="primary" [checked]="customerOnboardRule" formControlName="customerOnboardRule">Client Preference</mat-slide-toggle>
                      </div>

                    </div>
                    <!--<div class="form-group form-inline" *ngIf="relatedVendorList.length>0">
  <label class="small justify-content-start" style="width:6.5em">{{f.serviceTypeID.value==12||f.serviceTypeID.value==13? 'Handler':'SSN'}}</label>
  <select class="form-control form-control-sm dropdown" formControlName="vendorID" style="width: 28em;">
    <option value="">All</option>
    <option *ngFor="let v of relatedVendorList" [ngValue]="v.vendorGUID">{{v.vendorName}}</option>
  </select>
  </div>-->
                    

                  <div class="d-inline-flex">



                    <div class="form-inline">
                      <label style="font-size: small; width: 6.5em" class="small justify-content-start">Lead Time</label>
                      <input [attr.disabled]="immediateActionRequired" type="text" formControlName="leadTime" placeholder="?d?h?m" maxlength="9" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.leadTime.errors }" style="width:5em;" (change)="leadTimeChange($event)" />
                      <div *ngIf="(submitted || leadTimeChanged) && f.leadTime.errors">
                        <div *ngIf="f.leadTime.errors.pattern" style="background-color:#f9d2de; margin-left:-10em; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Invalid lead time. format: ?d?h?m</div>
                      </div>
                    </div>
                    <div class="form-inline ml-3">
                      <label style="font-size: small;" class="small justify-content-start mr-3">Due Date</label>
                      <input [attr.disabled]="immediateActionRequired" type="text" formControlName="dueDate" maxlength="9" placeholder="?d?h?m" class="form-control form-control-sm inputBox" [ngClass]="{ 'is-invalid': submitted && f.dueDate.errors }" style="width:5em;" (change)="dueDateChange($event)" />
                      <div *ngIf="(submitted || dueDateChanged) && f.dueDate.errors">
                        <div *ngIf="f.dueDate.errors.pattern" style="background-color:#f9d2de; padding-left:0.25em; width:15em;margin-top:0em; font-size:small;">Invalid due date. format: ?d?h?m</div>
                      </div>
                    </div>
                    <div>
                      <mat-slide-toggle style="transform:scale(.8)" color="primary" (change)="immediateActionRequired=!immediateActionRequired" [checked]="immediateActionRequired" formControlName="immediateActionRequired">Immediate Action Required</mat-slide-toggle>
                    </div>

                  </div>


                    <div>



                      <!--<div class="mb-2">-->
                      <!--<label style="width:11.5em" class="small justify-content-start">Client Approval Required</label>-->
                      <!--<mat-slide-toggle class="wide" style="transform: scale(.8); margin-left: -1em" color="primary" [checked]="customerApprovalRequired" formControlName="customerApprovalRequired" labelPosition="before">Client Approval Required</mat-slide-toggle>
    </div>
    <div class="mb-2">-->
                      <!--<label style="width:8em" class="small justify-content-start">Client Setup</label>-->
                      <!--<mat-slide-toggle class="wide" style="transform: scale(.8); margin-left: -1em" color="primary" [checked]="clientSetup" (change)="updateStatus('setup')" formControlName="clientSetup" labelPosition="before">Client Setup</mat-slide-toggle>
    </div>
    <div class="mb-2">-->
                      <!--<label style="width:8em" class="small justify-content-start">Client Setup</label>-->
                      <!--<mat-slide-toggle class="wide" style="transform: scale(.8); margin-left: -1em" color="primary" [checked]="notRequired" (change)="updateStatus('required')" formControlName="notRequired" labelPosition="before">Not Required</mat-slide-toggle>
    </div>-->
                      <!--<div class="form-group form-inline ml-2">
      <label style="width:11.5em" class="small justify-content-start">Service Status</label>
      <select class="form-control form-control-sm dropdown" formControlName="serviceStatus">
        <option value="0">All</option>
        <option *ngFor="let s of businessRulesServiceStatusList" [ngValue]="s.businessRulesServiceStatusID">{{s.businessRulesServiceStatusDescription}}</option>
      </select>
    </div>-->


                    </div>
                  </div>

                  </div>
              </div>
              <!--Services End-->
            </div>



          </div>
        </mat-tab>
        <mat-tab label="Similar Rules">
          <div style="width:68.5em;height:30.5em;">
            <table *ngIf="similarRules && similarRules.length>0" mat-table [dataSource]="similarRules" class="example-container">
              <tr>
                <ng-container matColumnDef="brID">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    BR ID
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.businessRulesID}}</td>
                </ng-container>
                <ng-container matColumnDef="customerName">
                  <th mat-header-cell *matHeaderCellDef width="20%">
                    Client
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
                </ng-container>
                <ng-container matColumnDef="registration">
                  <th mat-header-cell *matHeaderCellDef width="10%">
                    Registration
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.registrationList}}</td>
                </ng-container>
                <ng-container matColumnDef="far_type">
                  <th mat-header-cell *matHeaderCellDef width="10%">
                    FAR Type
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.farTypeDescription}}</td>
                </ng-container>
                <ng-container matColumnDef="country">
                  <th mat-header-cell *matHeaderCellDef width="10%">
                    Country
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.countryName}}</td>
                </ng-container>
                <ng-container matColumnDef="icao">
                  <th mat-header-cell *matHeaderCellDef width="5%">
                    ICAO
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.icao}}</td>
                </ng-container>
                <ng-container matColumnDef="service_class">
                  <th mat-header-cell *matHeaderCellDef width="10%">
                    Service Class
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.serviceClassDescription}}</td>
                </ng-container>
                <ng-container matColumnDef="service_type">
                  <th mat-header-cell *matHeaderCellDef width="10%">
                    Service Type
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.serviceTypeDescription}}</td>
                </ng-container>
                <ng-container matColumnDef="vendorName">
                  <th mat-header-cell *matHeaderCellDef width="20%">
                    Vendor
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.vendorName}}</td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="d-flex justify-content-between">


      <div class="d-inline-flex">
        <div class="mt-2">
          <!--<button *ngIf="tabPosition>0" mat-button class="btn btn-high mr-2" (click)="previousStep()">Previous</button>
          <button *ngIf="tabPosition<3" mat-button class="btn btn-high" (click)="nextStep()">Next</button>-->
          <button *ngIf="!readonly" mat-button class="btn btn-high mr-2" (click)="clickSave()">Save</button>
          <button mat-button mat-dialog-close class="btn btn-low">Close</button>
        </div>
        <div class="ml-2">
          <app-success-message *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>
          <label *ngIf="errMsg!=''" class="alert alert-danger py-0 px-1 small mt-2">{{errMsg}}</label>
        </div>
      </div>
      <div>
        <div class="d-inline-flex" style="margin-top:.75em">
          <div class="form-inline ml-2">
            <label for="modifiedBy" style="font-size:small">Modified by</label>
            <input type="text" formControlName="modifiedBy" readonly class="form-control ml-1" style="padding-left: 0em; width: 10em; height: 1.5625em; font-size: small" />

          </div>
          <div class="form-inline ml-2">
            <label for="modifiedDate" style="font-size:small">Modified Date</label>
            <input type="text" formControlName="modifiedDate" readonly class="form-control ml-1" style="padding-left: 0em; width: 10em; height: 1.5625em; font-size: small" />

          </div>
        </div>
      </div>
    </div>
    <!--<button (click)="getSimilarBusinessRules()">sim</button>-->
    <!--</div>-->
  </div>

</form>
<app-spin *ngIf="showSpin"></app-spin>
