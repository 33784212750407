import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { IndustryTypeModel } from '../models/industry-type.model';
import { CustomerTypeModel } from '../models/customer-type.model';
import { CreditStatusModel } from '../models/credit-status.model';
import { MarketingRegionModel } from '../models/marketing-region.model';
import { ThirdPartyScreeningModel } from '../models/third-party-screening.model';
import { VendorModel } from '../models/vendor.model';
import { CreditCheckModel } from '../models/credit-check.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { VendorService } from '../services/vendor.service';
import { VendorRoleModel } from '../models/vendor-roles.model';
import { VendorFacilityModel } from '../models/vendor-facility.model';
import { VendorComplianceStatusModel } from '../models/vendor-compliance-status.model';
import { RatingModel } from '../models/rating.model';
import { ContractStatusModel } from '../models/contract-status.model';
import { CountryModel } from '../models/country.model';
import { VendorMethodOfPaymentModel } from '../models/vendor-method-of-payment.model';
import { AirportModel } from '../models/airport.model';
import { AirportService } from '../services/airport.service';
import { VendorStatusModel } from '../models/vendor-status.model';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { ServiceArrangementModel } from '../models/service-arrangement.model';
import { PermitTypeModel } from '../models/permit-type.model';
import { CustomsTypeModel } from '../models/customs-type.model';


@Component({
  selector: 'app-vendor-profile-edit-dialog',
  templateUrl: './vendor-profile-edit-dialog.component.html',
  styleUrls: ['./vendor-profile-edit-dialog.component.css']
})

export class VendorProfileEditDialogComponent implements OnInit {
  @Input() dialogTitle: string;
  @Input() vendorGUID: string;
  @Input() pageName: string;
  @Input() icao: string;
  @Input() serviceTypeID: number;
  @Input() permitCountry: string;
  vendor_profile_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  vendorRoleList: VendorRoleModel[];
  selectedRoleList: VendorRoleModel[] = [];
  relatedVendorList: VendorModel[];
  selectedVendorList: VendorModel[] = [];
  relatedVendors: string="";
  facilityList: VendorFacilityModel[];
  complianceStatusList: VendorComplianceStatusModel[];
  regComplianceStatusList: VendorComplianceStatusModel[];
  acctComplianceStatusList: VendorComplianceStatusModel[];
  opsComplianceStatusList: VendorComplianceStatusModel[];
  vendorStatusList: VendorStatusModel[];
  serviceArrangementList: ServiceArrangementModel[];
  ratingList: RatingModel[];
  contractStatusList: ContractStatusModel[];
  countryList: CountryModel[];
  methodOfPaymentList: VendorMethodOfPaymentModel[];
  airportList: AirportModel[];
  allAirportList: AirportModel[];
  acctCreateDate: string;
  modifiedBy: string;
  modifiedDate: string;
  vendorName: string;
  legalEntityName: string;
  serviceArea: string = "airport";
  isModified: boolean = false;
  contractStatus: string;
  airportID: number;
  airportICAO: string;
  countryGUID: string;
  ratingID: number = 5;
  creationDate: string;
  accountTypeID: number;
  roleList: string;
  facilityID: number;
  complianceStatusID: number=1;
  regulatoryStatusID: number;
  operationsStatusID: number;
  accountingStatusID: number;
  methodOfPaymentID: number = 1;
  serviceArrangementID: number;
  complianceNote: string;
  regulatoryNote: string;
  operationsNote: string;
  accountingNote: string;
  showSuccessMsg: boolean = false;
  //checkRoles: FormControl;
  contractStatusID: number = 1;
  permitTypeList: PermitTypeModel[];
  permitTypeID: number;
  showPermitType: boolean = false;
  customsTypeList: CustomsTypeModel[];
  customsTypeID: number;
  showCustomsType: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<VendorProfileEditDialogComponent >, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, 
    private readonly _commonService: CommonService,
    private readonly _vendorService: VendorService,
    private readonly _dialogService: DialogService,
    private readonly _airportService: AirportService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService
  ) {
    this.vendorGUID = data.vendorGUID;
    this.dialogTitle = data.dialogTitle;
    this.pageName = data.pageName;
    this.icao = data.icao;
    this.serviceTypeID = data.serviceTypeID;
    this.permitCountry = data.permitCountry;

  }

  getAllData(): Observable<any[]> {
    let getVendorRoleResponse = this._vendorService.getVendorRoleList<ResponseModel<VendorRoleModel[]>>();
    let getFacilityResponse = this._vendorService.getFacilityList<ResponseModel<VendorFacilityModel[]>>();
    let getComplianceStatusResponse = this._vendorService.getVendorComplianceStatusList<ResponseModel<VendorComplianceStatusModel[]>>();
    let getRatingResponse = this._commonService.getRatingList<ResponseModel<RatingModel[]>>();
    let getContractStatusResponse = this._vendorService.getContractStatusList<ResponseModel<ContractStatusModel[]>>();
    let getCountryResponse = this._commonService.getCountryList<ResponseModel<CountryModel[]>>();
    let getVendorProfileResponse;
    if (this.vendorGUID != "" && this.vendorGUID!=null) {
      getVendorProfileResponse = this._vendorService.getVendorProfileByVendorGUID<ResponseModel<VendorModel>>(this.vendorGUID);
    }
    else
      getVendorProfileResponse = of(null);
    let getMethodOfPaymenReponse = this._vendorService.getMethodOfPaymentList<ResponseModel<VendorMethodOfPaymentModel[]>>();
   
    let getVendorStatusResponse = this._vendorService.getVendorStatusList<ResponseModel<VendorStatusModel[]>>();
    let getServiceArrangementResponse = this._groundStopAdvancedService.getServiceArrangementList<ResponseModel<ServiceArrangementModel[]>>();
    let getAirportResponse;
    if (this.icao != undefined) {
      let request = new AirportModel();
      request.searchKey = this.icao;
      request.searchType = "Airport";
      getAirportResponse = this._airportService.getAirportBySearchKey<ResponseModel<AirportModel>>(request);
    }
    else
      getAirportResponse = of(null)
    let getPermitTypeResponse = this._vendorService.getPermitTypeList<ResponseModel<PermitTypeModel[]>>();
    let getCustomsTypeResponse = this._vendorService.getCustomsTypeList<ResponseModel<CustomsTypeModel[]>>();
    return forkJoin([getVendorRoleResponse, getFacilityResponse, getComplianceStatusResponse, getRatingResponse, getContractStatusResponse, getCountryResponse,
      getVendorProfileResponse, getMethodOfPaymenReponse, getVendorStatusResponse, getServiceArrangementResponse,getAirportResponse,getPermitTypeResponse, getCustomsTypeResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.vendorRoleList = responses[0].model;
          
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.facilityList = responses[1].model;
          //this.selectedIndustryTypeId = this.industryTypeList[0].industryTypeID;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

    if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.complianceStatusList = responses[2].model;
          this.acctComplianceStatusList = this.complianceStatusList.filter(v => v.isAccountingCompliant == true);
          this.regComplianceStatusList = this.complianceStatusList.filter(v => v.isRegulatoryCompliant == true);
          this.opsComplianceStatusList = this.complianceStatusList.filter(v => v.isOperationsCompliant == true);
        //  this.selectedMarketingRegionId = this.marketingRegionList[0].marketingRegionID;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.ratingList = responses[3].model;
          //this.selectedThirdPartyScreeningStatusId = this.thirdPartyScreenList[0].thirdPartyScreeningStatusID;
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          this.contractStatusList = responses[4].model; 
          
          //this.selectedAccountStatusId = this.accountStatusList[0].accountStatusID;
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          this.countryList = responses[5].model;
          
        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[6] != null) {
        if (responses[6].code == "200" && responses[6].message == "") {
          let vendor = new VendorModel();
          vendor = responses[6].model;
          this.vendorName = vendor.vendorName;
          this.legalEntityName = vendor.legalEntityName;
          this.contractStatusID = vendor.contractStatusID;
          this.airportID = vendor.airportID;
          if (vendor.icao!=null && vendor.icao!="")
            this.airportICAO = vendor.icao.substring(0,4);
          this.ratingID = vendor.ratingID;
          this.facilityID = vendor.facilityID;
          this.creationDate = vendor.accountCreationDate;
          this.customsTypeID = vendor.customsTypeID;
          vendor.roleIDList.split(",").forEach(v => {
            this.vendorRoleList.forEach(x => {
              if (x.vendorRoleID.toString() == v) {
                x.selected = true;
              }
            });
          });
          this.setRoleList();          
          this.complianceStatusID = vendor.complianceStatusID;
          this.regulatoryStatusID = vendor.isRegulatoryCompliant;
          this.operationsStatusID = vendor.isOperationsCompliant;
          this.accountingStatusID = vendor.isAccountingCompliant;
          this.complianceNote = vendor.complianceStatusNote;
          this.regulatoryNote = vendor.regulatoryComplianceNote;
          this.operationsNote = vendor.operationsComplianceNote;
          this.accountingNote = vendor.accountingComplianceNote;
          this.methodOfPaymentID = vendor.vendorMethodOfPaymentID;
          this.modifiedBy = vendor.modifiedBy;
          this.modifiedDate = vendor.modifiedDate;
          this.accountTypeID = vendor.vendorStatusID;
          this.countryGUID = vendor.countryGUID;
          this.contractStatusID = vendor.contractStatusID;
          this.relatedVendors = vendor.relatedVendorList;
          this.serviceArrangementID = vendor.serviceArrangementID;
          this.permitTypeID = vendor.permitTypeID;
          this.customsTypeID = vendor.customsTypeID;
          if (this.airportID == 0)
            this.serviceArea = "country";
          else 
            this.serviceArea = "airport";
          
          
             

        }
        else {
          if (responses[6].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[7] != null) {
        if (responses[7].code == "200" && responses[7].message == "") {
         this.methodOfPaymentList = responses[7].model;

        }
        else {
          if (responses[7].code == "401") {
            this._authService.signOut();
          }
        }
      }      

      if (responses[8] != null) {
        if (responses[8].code == "200" && responses[8].message == "") {
          this.vendorStatusList = responses[8].model;

        }
        else {
          if (responses[8].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[9] != null) {
        if (responses[9].code == "200" && responses[9].message == "") {
          this.serviceArrangementList = responses[9].model;

        }
        else {
          if (responses[9].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[10] != null) {
        if (responses[10].code == "200" && responses[10].message == "") {
          let airport = responses[10].model;          
          this.airportID = airport[0].airportID;
          this.countryGUID = airport[0].countryGUID;
         
        }
        else {
          if (responses[10].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[11] != null) {
        if (responses[11].code == "200" && responses[11].message == "") {
          this.permitTypeList = responses[11].model;

        }
        else {
          if (responses[11].code == "401") {
            this._authService.signOut();
          }
        }

        if (responses[12] != null) {
          if (responses[12].code == "200" && responses[12].message == "") {
            this.customsTypeList = responses[12].model;

          }
          else {
            if (responses[12].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }
    

      this.initControls();
      if (this.pageName == "task") {
        this.accountTypeID = 6;
        switch (this.serviceTypeID) {
          case 1:
            this.vendorRoleList.find(v => v.vendorRoleID == 1).selected = true;
            break;
          case 3:
          case 5:
          case 26: {
            this.serviceArea = "country";
            this.countryGUID = this.permitCountry;
            this.vendorRoleList.find(v => v.vendorRoleID == 12).selected = true;
            break;
          }
        }
        
        this.setRoleList();
        this.initControls();
        this.f.account_type.setValue(6);
        this.f.account_type.disable();
        

      }
      if (this.serviceArea=="airport")
        this.filterAirportList();
      this.filterVendorList();
      this.showSpin = false;


    });

  }

  initControls() {
    this.vendor_profile_edit_form = this._formBuilder.group({
      vendorName: [this.vendorName,Validators.required],
      legalEntityName: [this.legalEntityName, Validators.required],
      country_select: [this.countryGUID, Validators.required],
      airport_select: this.airportICAO,
      facility_select:this.facilityID,
      compliance_status: [this.complianceStatusID,Validators.required],
      account_type: [this.accountTypeID, Validators.required],
      rating_select: [this.ratingID, Validators.required],
      contract_status: [this.contractStatusID, Validators.required],
      payment_method: [this.methodOfPaymentID, Validators.required],
      compliance_note: this.complianceNote,
      ops_note: this.operationsNote,
      reg_note: this.regulatoryNote,
      acct_note: this.accountingNote,
      regulatory_status: this.regulatoryStatusID,
      acct_status: this.accountingStatusID,
      operations_status:this.operationsStatusID,
      checkRoles: [this.selectedRoleList, Validators.required],
      checkVendors: this.selectedVendorList,
      serviceArrangement: this.serviceArrangementID,
      permit_type: this.permitTypeID,
      customs_type:this.customsTypeID
      
    });
  //  this.checkRoles = new FormControl(this.selectedRoleList,Validators.required); // for mat-select
    this.setValidation();
  }

  setValidation() {
    this.f.airport_select.clearValidators();
    if (this.serviceArea == "airport") {
      this.f.airport_select.enable();
      this.f.airport_select.setValidators(Validators.required);
    }
    else {
      this.f.airport_select.disable();
      this.f.airport_select.setValue(null);
    }
    this.f.airport_select.updateValueAndValidity();

  }

  get f() { return this.vendor_profile_edit_form.controls; }

  onRoleListChange(e: any, item: VendorRoleModel) {
    this.vendorRoleList.forEach(x => {
      if (x.vendorRoleID == e.source.value.vendorRoleID) {
        x.selected = e.source.selected;
      }

    });
    this.setRoleList();
    this.filterVendorList();
  }

  

  setRoleList() {
    this.selectedRoleList = this.vendorRoleList.filter(u => u.selected == true);
    if (this.selectedRoleList.some(x => x.vendorRoleID === 12))
      this.showPermitType = true;
    else
      this.showPermitType = false;
    if (this.selectedRoleList.some(x => x.vendorRoleID === 2))
      this.showCustomsType = true;
    else
      this.showCustomsType = false;
  }

  onVendorListChange(e: any, item: VendorModel) {
    this.relatedVendorList.forEach(x => {
      if (x.vendorID == e.source.value.vendorID) {
        x.selected = e.source.selected;
      }

    });
    this.setVendorList();
  }



  setVendorList() {
    this.selectedVendorList = this.relatedVendorList.filter(u => u.selected == true);
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";
    this.setValidation();
    if (this.vendor_profile_edit_form.invalid || this.errMsg != "") {
      return;
    }

    this.loading = true;
    let obj = new VendorModel();
    if (this.vendorGUID == "" || this.vendorGUID == null) {
      obj.vendorGUID = null;
    }
    else {
      obj.vendorGUID = this.vendorGUID;
    }
    obj.vendorName = this.f.vendorName.value;
    obj.legalEntityName = this.f.legalEntityName.value;
    obj.countryGUID = this.f.country_select.value;
    if (this.f.airport_select.value == "" || this.f.airport_select.value == null)
      obj.airportID = 0;
    else
      obj.airportID = this.airportID;
    obj.facilityID = this.f.facility_select.value;
    obj.complianceStatusID = this.f.compliance_status.value;
    obj.vendorStatusID = this.f.account_type.value;
    obj.ratingID = this.f.rating_select.value;
    obj.contractStatusID = this.f.contract_status.value;
    obj.vendorMethodOfPaymentID = this.f.payment_method.value;
    obj.complianceStatusNote = this.f.compliance_note.value;
    obj.operationsComplianceNote = this.f.ops_note.value;
    obj.regulatoryComplianceNote = this.f.reg_note.value;
    obj.accountingComplianceNote = this.f.acct_note.value;
    obj.isRegulatoryCompliant = this.f.regulatory_status.value;
    obj.isAccountingCompliant = this.f.acct_status.value;
    obj.isOperationsCompliant = this.f.operations_status.value;   
    obj.roleIDList = Array.prototype.map.call(this.selectedRoleList, s => s.vendorRoleID).toString();
    obj.roleList = Array.prototype.map.call(this.selectedRoleList, s => s.vendorRole).toString();
    obj.relatedVendorList = Array.prototype.map.call(this.selectedVendorList, s => s.vendorID).toString();
    obj.serviceArrangementID = this.f.serviceArrangement.value;
    obj.sendEmail = false;
    if (this.pageName == "task")
      obj.sendEmail = true;
    obj.permitTypeID = this.f.permit_type.value;
    obj.customsTypeID = this.f.customs_type.value;
    this._vendorService.saveVendor<ResponseModel<VendorModel>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.vendorGUID != "") {
          this.vendorGUID = response.model.vendorGUID;
          // this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          this.showSuccessMsg = true;
          this.isModified = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            let objClose = new ReturnObjModel();
            objClose.actionName = "edit"
            objClose.newId = this.vendorGUID;
            this._dialogRef.close(objClose);
          }, 1000);


        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    });

  }

 

  clickClose() {
    let obj = new ReturnObjModel();
    if (this.isModified) {
      obj.actionName = "search";
    }
    this._dialogRef.close(obj);
  }

  validateAirport(e: any) {

    this.airportList = [];
    let request = new AirportModel();

    request.route = e.target.value;

    this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.airportList = response.model;
        if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
          this.airportList.forEach(x => {
            this.f.country_select.setValue(x.countryGUID);
            this.airportID = x.airportID;
          });
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }
    });
  }


  filterAirportList() {
    let airport = new AirportModel();
    airport.searchKey = this.f.country_select.value;
    airport.searchType = "CountryGUID";
    this._airportService.getAirportBySearchKey<ResponseModel<AirportModel[]>>(airport).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.airportList = response.model;          

        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }

  filterVendorList() {
    let vendor = new VendorModel();
    this.relatedVendorList = [];
    if (this.airportID != null) {
      vendor.airportID = this.airportID;
      vendor.vendorRoleID = 0;
      if (this.selectedRoleList.some(x => x.vendorRoleID === 5) || this.selectedRoleList.some(x => x.vendorRoleID === 19))
        vendor.vendorRoleID = 5;
      else {
        if (this.selectedRoleList.some(x => x.vendorRoleID === 1))
          vendor.vendorRoleID = 1;
      }
      if (this.vendorGUID != null)
        vendor.vendorGUID = this.vendorGUID;
      if (vendor.vendorRoleID != 0) {
        this._vendorService.getRelatedVendorList<ResponseModel<VendorModel[]>>(vendor).subscribe(response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              this.relatedVendorList = response.model;
              if (this.relatedVendors != "") {
                this.relatedVendors.split(",").forEach(v => {
                  this.relatedVendorList.forEach(x => {
                    if (x.vendorID.toString() == v) {
                      x.selected = true;
                    }
                  });
                });
              }
              this.setVendorList();
              this.f.checkVendors.setValue(this.selectedVendorList);
            }
            else {
              if (response.code == "401") {
                this._authService.signOut();
              }
            }
          }
        });
      }
    }
  }
}
