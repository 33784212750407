<div *ngIf="upPII" style="padding-bottom: 0.5em">
  <div *ngIf="usernameList && usernameList.length>0" style="display: flex; font-size: small">
    <div style="font-weight: bold; margin-right:0.25em">Audit Trail</div>
    <div *ngFor="let u of usernameList; let i=index">
      <div style="margin-right:0.25em;">
        <button mat-button class="btn-low" *ngIf="!u.isExternal"
          style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
          (click)="clickAudit(u.userName)">
          <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
        </button>
        <button mat-button class="btn-green" *ngIf="u.isExternal" style="align-self:center;min-width: 4em; height:1.5em"
          (click)="clickAudit(u.userName)">
          <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
        </button>
      </div>
    </div>
  </div>
  <div style="margin-top:-1em">
    <hr style="width: 78em;border: 0.0625em solid darkgray;" />
  </div>
  <div style="display: flex; font-size: small; ">
    <div style="width: 39.5em; text-align:left; background-color: transparent; margin-right:0.5em">
      <div style="display: flex;font-weight: bold">
        <div style="width: 32.5em;">
        </div>
        <div style="width:2em"></div>
        <!-- <div style="width:5em">Messages</div> -->
      </div>
      <div style="display: flex; font-weight: bold">
        <div style="width: 32.5em">
          DEPARTURE SERVICES
        </div>
        <!-- <div style="width:2em">BR</div> -->
        <!-- <div style="width:2.5em;   margin-left: 2em ; text-align:center">In</div>
        <div style="width: 2.5em; text-align: center">Out</div> -->
      </div>
      <div *ngFor="let p of depTaskList">
        <div style="display: flex; width:39.5em" class="div-rowHover">
          <div style="width:32.5em; display:flex">
            <div style="width:1.25em">
              <div *ngIf="p.isRevisionRequiredValue==1"
                style="margin-top:0.25em; margin-right:-0.75em; cursor: pointer;"
                (click)="clickTripRevisionRequired($event, p, 'D')"><i class="material-icons-two-tone icon_orange"
                  style="font-size: medium">notifications_active</i></div>
              <div *ngIf="p.isRevisionRequiredValue==2"
                style="margin-top:0.25em; margin-right:-0.75em; cursor: pointer;"
                (click)="clickTripRevisionRequired($event, p, 'D')"><i class="material-icons-two-tone icon_red"
                  style="font-size: medium">notifications_active</i></div>
            </div>
            <div (click)="clickServiceType($event,p, 'D')">
              <div style="display: flex;margin-top:0em; ">
                <div
                  style="border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right:0.125em; margin-top:0.35em"
                  [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                <div style="display: flex;height:1.5em;margin-right:0.25em" class="divHover">
                  <div *ngIf="!p.strikeOutService" style="margin-right:0.0625em"><a
                      title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription}}</a></div>
                  <div *ngIf="p.strikeOutService" style="margin-right:0.0625em"><s style="color:red"><a
                        title="{{p.originalTaskStatusDescription}}"><span
                          class="textHover">{{p.serviceTypeDescription}}</span></a></s></div>
                  <div *ngIf="p.checklistIconColor!='green'" style="margin-top:0.25em">
                    <span class="material-icons-two-tone {{p.checklistIconColor}}" style="font-size: medium">
                      {{p.checklistIconName}}
                    </span>
                  </div>
                </div>
                <div class="ellipsis">
                  <span *ngIf="p.countryName!=''"
                    style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold"
                    title="{{p.countryName}}">{{p.countryName.substr(0,20)}}</span>
                  <span *ngIf="p.hotelName!=''"
                    style="margin-top: 0em; color: rgb(81, 81, 211); margin-right:0.25em; font-weight: bold"
                    title="{{p.hotelName}}">{{p.hotelName.substr(0,20)}}</span>
                  <span *ngIf="p.fpiConfirmationReference!=''"
                    style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold"
                    title="{{p.fpiConfirmationReference}}">{{p.countryName!='' || p.hotelName !=''? ' / ':
                    ''}}{{p.fpiConfirmationReference}}</span>
                  <span *ngIf="p.vendorName!=''"
                    style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold"
                    title="{{p.vendorName}}">{{p.countryName!='' || p.fpiConfirmationReference!=''? ' / ' +
                    p.vendorName:p.vendorName}}</span>
                  <span *ngIf="p.serviceDate!='' || p.serviceTime!=''"
                    style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold">{{p.serviceDate |
                    date:'dd-MMM'}} {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</span>
                  <span *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i class="material-icons"
                      style="font-size: medium; transform: rotate(-45deg);">attachment</i></span>
                  <span *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                    matTooltip="{{p.publicNote}}"><i class="material-icons" style="font-size: small;">notes</i></span>
                  <span *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i
                      class="material-icons-outlined" style="font-size: medium;">unpublished</i></span>
                </div>
              </div>

              <div *ngFor="let t of p.tripLegList" style="margin-left:1.25em">
                <div style="margin-top: -0.25em;">{{t.departureICAO+ ' - '+t.nextArrivalICAO}}</div>
              </div>
              <div *ngFor="let f of p.flightFollowingList" style="margin-left:1.25em">
                <div style="margin-top: -0.25em; color: darkblue" title="{{f.subServiceTypeDescription}}">
                  {{f.subServiceTypeDescription.substr(0,19)}}</div>
              </div>
            </div>
            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" (click)="openMessages($event,p, 'in')"
              style="margin-top:0.25em"><i class="material-icons" style="font-size: medium;">mail_outline</i></div>
            <!-- <div *ngIf="p.isEmailIcon" (click)="openMessages($event,p, 'in')" style="margin-top:0.25em"><i
                class="material-icons" style="font-size: medium;">mail_outline</i></div> -->

          </div>

        </div>
      </div>

    </div>
    <div style="border-left: 0.0625em solid darkgray; margin-right: 1em; margin-bottom: 1em"></div>
    <div style="width: 39.5em; text-align: left; background-color: transparent">
      <div style="display: flex;font-weight: bold">
        <div style="width: 32.5em;">
        </div>
        <div style="width:2em"></div>
        <!-- <div style="width:5em">Messages</div> -->
      </div>
      <div style="display: flex; font-weight: bold">
        <div style="width: 32.5em">
          ARRIVAL SERVICES
        </div>
        <!-- <div style="width:2em"></div> -->
        <!-- <div style="width: 2.5em; text-align: center; margin-left: 2em ;">In</div>
        <div style="width: 2.5em; text-align: center">Out</div> -->
      </div>
      <div *ngFor="let p of arrTaskList">
        <div style="display: flex; width:39.5em" class="div-rowHover">
          <div style="width:32.5em; display:flex">
            <div style="width:1.25em">
              <div *ngIf="p.isRevisionRequiredValue==1"
                style="margin-top:0.25em; margin-right:-0.75em; cursor: pointer;"
                (click)="clickTripRevisionRequired($event, p, 'A')"><i class="material-icons-two-tone icon_orange"
                  style="font-size: medium">notifications_active</i></div>
              <div *ngIf="p.isRevisionRequiredValue==2"
                style="margin-top:0.25em; margin-right:-0.75em; cursor: pointer;"
                (click)="clickTripRevisionRequired($event, p, 'A')"><i class="material-icons-two-tone icon_red"
                  style="font-size: medium">notifications_active</i></div>
            </div>
            <div (click)="clickServiceType($event,p, 'A')">
              <div style="display: flex;margin-top:0em; ">
                <div
                  style="border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em; margin-right:0.125em; margin-top:0.35em"
                  [style.background-color]="p.taskStatusFontColor" title="{{p.taskStatusDescription}}"></div>
                <div style="display: flex;height:1.5em;margin-right:0.25em" class="divHover">
                  <div *ngIf="!p.strikeOutService" style="margin-right:0.0625em"><a
                      title="{{p.serviceTypeDescription}}">{{p.serviceTypeDescription}}</a></div>
                  <div *ngIf="p.strikeOutService" style="margin-right:0.0625em"><s style="color:red"><a
                        title="{{p.originalTaskStatusDescription}}"><span
                          class="textHover">{{p.serviceTypeDescription}}</span></a></s></div>
                  <div *ngIf="p.checklistIconColor!='green'" style="margin-top:0.25em">
                    <span class="material-icons-two-tone {{p.checklistIconColor}}" style="font-size: medium">
                      {{p.checklistIconName}}
                    </span>
                  </div>
                </div>
                <div style="display: flex;">
                  <div *ngIf="p.countryName!=''"
                    style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold"
                    title="{{p.countryName}}">{{p.countryName.substr(0,10)}}</div>
                  <div *ngIf="p.hotelName!=''"
                    style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold"
                    title="{{p.hotelName}}">{{p.hotelName.substr(0,10)}}</div>
                  <div *ngIf="p.fpiConfirmationReference!=''"
                    style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold"
                    title="{{p.fpiConfirmationReference}}">{{p.countryName!='' || p.hotelName !=''? ' / ':
                    ''}}{{p.fpiConfirmationReference!='' &&
                    p.fpiConfirmationReference.length>15?p.fpiConfirmationReference.substr(0,15)+'...':p.fpiConfirmationReference}}
                  </div>
                  <div *ngIf="p.vendorName!=''"
                    style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold"
                    title="{{p.vendorName}}">{{p.countryName!='' || p.fpiConfirmationReference!=''? ' / ' +
                    p.vendorName.substr(0,14):p.vendorName.substr(0,48)}}</div>
                  <div *ngIf="p.serviceDate!='' || p.serviceTime!=''"
                    style="margin-top: 0em; color: darkblue; margin-right:0.25em; font-weight: bold">{{p.serviceDate |
                    date:'dd-MMM'}} {{p.serviceTime!=''? p.serviceTime + ' Z' : ''}}</div>
                  <div *ngIf="p.hasTaskDocument" style="margin-top:0.25em" title="documents"><i class="material-icons"
                      style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                  <div *ngIf="p.noteCount>0" style="margin-top:0.25em" matTooltipClass="tooltipLineBreak"
                    matTooltip="{{p.publicNote}}"><i class="material-icons" style="font-size: small;">notes</i></div>
                  <div *ngIf="!p.isActive" title="disabled" style="margin-top:0.25em"><i class="material-icons-outlined"
                      style="font-size: medium;">unpublished</i></div>

                </div>

              </div>

              <div *ngFor="let t of p.tripLegList" style="margin-left:1.25em">
                <div style="margin-top: -0.25em;">{{t.departureICAO+ ' - '+t.nextArrivalICAO}}</div>
              </div>
              <div *ngFor="let f of p.flightFollowingList" style="margin-left:1.25em">
                <div style="margin-top: -0.25em; color: darkblue" title="{{f.subServiceTypeDescription}}">
                  {{f.subServiceTypeDescription.substr(0,19)}}</div>
              </div>
            </div>
            <div *ngIf="p.messageInCount>0 || p.messageOutCount>0" (click)="openMessages($event,p, 'in')"
              style="margin-top:0.25em"><i class="material-icons" style="font-size: medium;">mail_outline</i></div>
            <!-- <div *ngIf="p.isEmailIcon" (click)="openMessages($event,p, 'in')" style="margin-top:0.25em"><i
                class="material-icons" style="font-size: medium;">mail_outline</i></div> -->

          </div>


        </div>
      </div>

    </div>
  </div>
  <div *ngIf="showSpin" style="margin-top: -3em; margin-left: -1em; position:relative; z-index:100">
    <span class="spinner-border spinner-border-sm mr-1"></span>
  </div>
</div>