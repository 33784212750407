<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-1em" mat-dialog-title>Build {{reportName}} - {{route}}</div>

<div class="form-group form-inline">
  <ul class="tabul">
    <li *ngIf="serviceTypeID!=79" [ngClass]="{ 'btn-high':selectedTabName=='weather','btn-low pointer':selectedTabName!='weather'  }" style="border-left: black 0.0625em solid;" (click)="clickTab('weather')"><div style="margin-top:0.25em">Weather Charts</div></li>
    <li [ngClass]="{ 'btn-high':selectedTabName=='sections','btn-low pointer':selectedTabName!='sections' }" (click)="clickTab('sections')"><div style="margin-top:0.25em">Brief Sections</div></li>
    <li [ngClass]="{ 'btn-high':selectedTabName=='documents','btn-low pointer':selectedTabName!='documents' }" (click)="clickTab('documents')"><div style="margin-top:0.25em">Included Documents</div></li>
    <li [ngClass]="{ 'btn-high':selectedTabName=='merge','btn-low pointer':selectedTabName!='merge' }" (click)="clickTab('merge')"><div style="margin-top:0.25em">Finalize/Merge</div></li>

  </ul>
</div>
<div>
  <app-weather-charts-dialog #weatherCharts *ngIf="selectedTabName=='weather'" [gstId]="gstId" [weatherChartIds]="weatherChartIds"></app-weather-charts-dialog>
  <app-flight-brief-sections #briefSections *ngIf="selectedTabName=='sections'" [gstId]="gstId" [icao]="icao"></app-flight-brief-sections>
  <app-flight-brief-documents #briefDocuments *ngIf="selectedTabName=='documents'"></app-flight-brief-documents>
  <app-compile-flight-brief-dialog #compileBrief *ngIf="selectedTabName=='merge'"></app-compile-flight-brief-dialog>

</div>
