import { Component } from '@angular/core';

@Component({
    selector: 'app-customer-profile-bi-dashboard',
  templateUrl: './customer-profile-bi-dashboard.component.html',
  styleUrls: ['./customer-profile-bi-dashboard.component.css']
})
/** airport-bi-dashboard component*/
export class CustomerProfileBiDashboardComponent {
  
    constructor() {

    }
}
