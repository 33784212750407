<table mat-table [dataSource]="airportNotes" >
  <tr>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef width="15%">
        <span style="margin-left: .5em">Category</span>
      </th>
      <td mat-cell *matCellDef="let element" style="margin-left:.25em"><span style="margin-left: .5em">{{element.noteType}}</span></td>
    </ng-container>
    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef width="57%">
        Note
      </th>
      <td mat-cell *matCellDef="let element" matTooltipClass="tooltipLineBreak" matTooltip="{{element.airportNote.length>150? element.airportNote : ''}}" style="text-align: left !important; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; flex: 0 0 60%; width: 60%">{{element.airportNote}}</td>
      <!--<span *ngIf="element.airportNote.length>550" style="cursor:pointer" matTooltipClass="tooltipLineBreak" matTooltip="{{element.airportNote}}">{{element.airportNote.substring(0,549)+'...'}}</span><span *ngIf="element.airportNote.length<=550">{{element.airportNote}}</span></td>-->
    </ng-container>
    <ng-container matColumnDef="revisionDate">
      <th mat-header-cell *matHeaderCellDef width="7%" style="text-align:center !important">
        Revision
      </th>
      <td mat-cell *matCellDef="let element"  matTooltipClass="tooltipLineBreak" matTooltip="{{element.revisionRemarks}}"  style="text-align:center !important" [ngClass]="{ 'alert-danger' : isExpired(element.revisionDate) }">{{element.revisionDate}}</td>
    </ng-container>
    <ng-container matColumnDef="br">
      <th mat-header-cell *matHeaderCellDef width="5%" style="text-align:center !important">
        BR
      </th>
      <td mat-cell *matCellDef="let element" style="text-align:center !important">{{element.businessRuleCount>0? 'Yes':''}}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Action <br /><a *ngIf="airportID!=0" mat-button class="btn-high grid" (click)="clickEdit($event,null)">ADD</a>
      </th>
      <td mat-cell *matCellDef="let element; let i=index ">
        <a mat-button class="btn-low grid">
          EDIT
        </a>
      </td>
    </ng-container>
  </tr>
  <tr mat-header-row *matHeaderRowDef="airportNotesColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: airportNotesColumns" (click)="clickEdit($event, row);" [class.row-is-clicked]="clickedRows.has(row)"></tr>
</table>
