import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, Optional, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
//import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomValidators } from '../common-utility/custom.validators';
import { AirportModel } from '../models/airport.model';
import { CountryModel } from '../models/country.model';
import { FuelPricingModel } from '../models/fuel-pricing.mode';
import { FuelSupplierModel } from '../models/fuelsupplier.model';
import { IntoPlaneAgentModel } from '../models/intoplaneagent.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { FuelService } from '../services/fuel.service';
import { Title } from '@angular/platform-browser';
import { FuelpricingComponent } from '../fuelpricing/fuelpricing.component';
import { ReturnObjModel } from '../models/return-obj.model';
import { TaxesandFeesEditDialogComponent } from './taxesandfees-edit-dialog.component';
import { TaxesAndFeesModel } from '../models/taxesandfees.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
//import { BusinessRulesEditComponent } from './business-rules-edit.component';

import * as XLSX from 'xlsx';
import { UnitofmeasureModel } from '../models/unitofmeasure.model';
import { TaxesAndFeesDescModel } from '../models/taxesandfeesdesc.model';
import { FuelMenuComponent } from '../fuelmenu/fuel-menu.component'; 
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';

@Component({
  selector: 'app-taxesandfees',
  templateUrl: './taxesandfees.component.html',
  styleUrls: ['./taxesandfees.component.css']
})

export class TaxesAndFeesComponent implements OnInit {
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),

  });

  taxesandfees_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg1: string;
  msg: string;
  showSpin: boolean = false;


  //fuelpricinglist: FuelPricingModel[];
  mattaxesandfeesList = new MatTableDataSource<TaxesAndFeesModel>(); //matfuelPricingList
  selection = new SelectionModel<TaxesAndFeesModel>(true, []);

  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  selectedTabName: string;
  reloadChildPage: boolean;
  totalRecordsText: string;
  totalRecords: number;
  pageSize: number = 15;
  totalPageNumber: number = 0;
  v: number = this._authService.getCurrentTimeNumber();
  startDate: Date;
  endDate: Date;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  pageFrom: string = "";
  sort_by: string;
  grantAccessToFuel: boolean = false;

  taxesandfeeslist: TaxesAndFeesModel[];

  airportList: AirportModel[];
  selectedAirportList: AirportModel[];
  showAirportList: boolean = false;

  fuelsupplierList: FuelSupplierModel[];
  selectedFuelSupplierList: FuelSupplierModel[];
  showFuelSupplierList: boolean = false;

  intoplaneagentList: IntoPlaneAgentModel[];
  selectedIntoPlaneAgentList: IntoPlaneAgentModel[];
  showIntoPlaneAgentList: boolean = false;

  taxFeeDescList: TaxesAndFeesDescModel[];
  selectedTaxAndFeeDescIDList: TaxesAndFeesDescModel[];
  showTaxFeeDescList: boolean = false;

  unitofMeasureList: UnitofmeasureModel[];
  selectedUnitOfMeasureIDList: UnitofmeasureModel[];
  showunitofMeasureList: boolean = false;

  currentPageIndex: number = 0;

  //service: string = "service_class"; 
  showCountryList: boolean = false;
  selectedCountryList: CountryModel[];
  filteredCountryList: Observable<CountryModel[]>;
  selectedCountryCountText: string = "";
  countryList: CountryModel[];
  filteredAirportList: Observable<AirportModel[]>;
  filteredFuelSupplierList: Observable<FuelSupplierModel[]>;
  filteredIntoPlaneAgentList: Observable<IntoPlaneAgentModel[]>;
  filteredtaxFeedescList: Observable<TaxesAndFeesDescModel[]>;
  filteredUnitOfMeasureList: Observable<UnitofmeasureModel[]>;

  selectedAirportCountText: string = "";
  selectedIntoPlaneAgentCountText: string = "";
  selectedFuelSupplierCountText: string = "";
  selectedTaxFeeDescCountText: string = "";
  selectedUnitOfMeasureCountText: string = "";

  includeDisabled: boolean = false;

  includeDisabledRecords: boolean;
  showExpiredOnly: boolean = false;
  activeTrips: string;
  sortedData: TaxesAndFeesModel[];
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;

  controlValueChanged: boolean;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  getDataConditions : string = 'active';

  displayedColumn: string[] =/*'action1',*/['Country', 'ICAO', 'IATA', 'FuelSuppliershortName', 'IntoPlaneAgent',  'effectivedate',
    'expirydate', 'Scale', 'UnitofMeasure', 'currency', 'taxfeedescription','Tax','USD','Unit', 'IsTotalCalculated', 'IsDomestic' , 
     'exemptfartype',
    'InternalNotes',    'action'];/*'ClientNotes', */
  fromFuelPricingSummary: boolean;
  websiteEnvironment: string = "";
  fromMissingTaxesAirport: boolean = false;
  fromFuelTask: boolean = false;
  selectedIcao: string = '';
  selectedIATA: string = '';
  iscountryorairport: string = ''; 
  sortedData2: TaxesAndFeesModel[]=[];
  taxesandfeeslist2: TaxesAndFeesModel[]=[];
  taxesAndFeesList: TaxesAndFeesModel;
  selectedIntoPlaneAgent: string = "";
  selectedFuelSupplier: string = "";
  selectedCountry: string = "";
  isUpdated: boolean = false;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _fuelService: FuelService,
    private readonly _dialogService: DialogService, private _titleService: Title,
    //private readonly _dialogRef: MatDialogRef<FuelpricingComponent>,
    @Optional()  private readonly _dialogRef: MatDialogRef<TaxesAndFeesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
  ) {

    if (data != null) {

      if (data.fromMissingTaxesAirport != undefined)
        this.fromMissingTaxesAirport = data.fromMissingTaxesAirport;
      if (data.selectedIcao != undefined)
        this.selectedIcao = data.selectedIcao;
      if (data.selectedIATA != undefined)
        this.selectedIATA = data.selectedIATA;
      if (data.iscountryorairport != undefined)
        this.iscountryorairport = data.iscountryorairport;
      if (data.fromMissingTaxes != undefined)
        this.fromMissingTaxesAirport = data.fromMissingTaxes;
      if (data.selectedFuelSupplier != undefined)
        this.selectedFuelSupplier = data.selectedFuelSupplier;
      if (data.selectedIntoPlaneAgent != undefined)
        this.selectedIntoPlaneAgent = data.selectedIntoPlaneAgent;
      if (data.fromFuelTask != undefined)
        this.fromFuelTask = data.fromFuelTask;
      if (data.selectedCountry != undefined)
        this.selectedCountry = data.selectedCountry;
    }

  }

  ngOnInit() {
    this.grantAccessToFuel = false;
    if (localStorage.getItem('up.fuel') == 'true') {
      this.grantAccessToFuel = true;
    }

    if (!this.grantAccessToFuel) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this._authService.updateAccessTime();

    this.errMsg = "";
    this.errMsg1 = "";
    this.msg = "";
    this.totalRecordsText = "";
    this.airportList = [];
    this.selectedAirportList = [];
    this.fuelsupplierList = [];
    this.intoplaneagentList = [];
    this.taxFeeDescList = [];
    this.unitofMeasureList = [];
    this.selectedFuelSupplierList = [];
    this.selectedIntoPlaneAgentList = [];
    this.selectedTaxAndFeeDescIDList = [];
    this.selectedUnitOfMeasureIDList = [];
    this.countryList = [];
    this.selectedCountryList = [];
    this.sortedData = [];
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate());

    //if(this.fromMissingTaxesAirport == false && this.fromFuelTask==false){

    //  switch (this.websiteEnvironment) {
    //    case "DEV":
    //      this._titleService.setTitle("Taxes and Fees");
    //      break;
    //    case "QA":
    //      this._titleService.setTitle("Taxes and Fees");
    //      break;
    //    default: 
    //      this._titleService.setTitle("Taxes and Fees");
    //  }
    //}
    this.initControls();

    this.f.includeDisabledRecords.setValue(false);
    this.clickSubmit();

    if(this.fromMissingTaxesAirport){
      // this.taxesAndFeesList.taxesAndFeesID = 'ADD';
      this.clickEdit(this.taxesAndFeesList);
    }

  }

  initControls() {
    this.taxesandfees_form = this._formBuilder.group({
      country_search: [''],
      airportSearch: [''],
      fuelsupplier_search: '',
      intoplaneagent_search: '',
      taxFeeDesc_search: '',
      unitOfMeasure_search: '',
      startDate: [this.startDate],
      endDate: [null],
      sort_by: [this.sort_by],


      includeDisabledRecords: this.includeDisabledRecords,
      showExpiredOnly: this.showExpiredOnly,

      activeTrips: this.activeTrips
    });
  }

  get f() { return this.taxesandfees_form.controls; }

  dateOnchange(dateType: string, e: any) {
    if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidStartDate = false;
          this.f.startDate.setErrors({ isValidDate: false });
        }
        else {
          this.isValidStartDate = true;
        }
      }
      else {
        this.isValidStartDate = true;
      }
    }

    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidEndDate = false;
          this.f.endDate.setErrors({ isValidDate: false })
        }
        else {
          this.isValidEndDate = true;
        }
      }
      else {
        this.isValidEndDate = true;
      }
    }

    this.clickSubmit();

  }

  sortByChange(e: any) {
    this.clickSubmit();
  }


  //country
  clickCountryFilter() {
    this.getCountryList();
  }

  getCountryList() {
    this.showCountryList = true;
    //this.showClientList = false;
    this.showAirportList = false;
    this.showFuelSupplierList = false;
    this.showIntoPlaneAgentList = false;
    this.showTaxFeeDescList = false;  
    this.showunitofMeasureList= false;  


    this.countryList = [];
    let request = new TaxesAndFeesModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }


    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).join(";");
    }

    if (this.selectedTaxAndFeeDescIDList.length == this.taxFeeDescList.length) {
      request.selectedTaxAndFeeDescIDList = "";
    }
    else {
      this.selectedTaxAndFeeDescIDList = this.selectedTaxAndFeeDescIDList.filter(x => x.taxFeeDescription != 'all');
      request.selectedTaxAndFeeDescIDList = Array.prototype.map.call(this.selectedTaxAndFeeDescIDList, s => s.lk_TaxFeeDescriptionID).toString();
    }


    if (this.selectedUnitOfMeasureIDList.length == this.unitofMeasureList.length) {
      request.selectedUnitOfMeasureIDList = "";
    }
    else {
      this.selectedUnitOfMeasureIDList = this.selectedUnitOfMeasureIDList.filter(x => x.unitOfMeasure != 'all');
      request.selectedUnitOfMeasureIDList = Array.prototype.map.call(this.selectedUnitOfMeasureIDList, s => s.lk_UnitOfMeasureID).toString();
    }

    request.isActive = !this.f.includeDisabledRecords.value;
    request.showExpiredOnly =  this.f.showExpiredOnly.value;

    this._fuelService.getTAFCountryList<ResponseModel<CountryModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let c = new CountryModel();

          response.model.forEach(x => {
            c = new CountryModel();
            c.countryName = x.countryName
            c.countryGUID = x.countryGUID;
            c.selected = false;
            if (this.selectedCountryList.length > 0) {
              let f: CountryModel[];
              f = this.selectedCountryList.filter(y => y.countryGUID.toLowerCase() == x.countryGUID.toLowerCase());
              if (f.length > 0) {
                c.selected = true;
              }
            }
            this.countryList.push(c);
          });
          this.countryList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.countryName.localeCompare(b.countryName));
          c = new CountryModel();
          c.countryGUID = "all";
          c.countryName = "Select All";
          c.selected = false;
          this.countryList.unshift(c);
          this.filteredCountryList = this.f.country_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.countryName)),
            map(countryName => (countryName ? this._filterCountry(countryName) : this.countryList.slice())),
          );
          this.setCountryList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameCountry(country: CountryModel): string {
    return country && country.countryName ? country.countryName : '';
  }

  private _filterCountry(name: string): CountryModel[] {
    const filterValue = name.toLowerCase();

    return this.countryList.filter(option => option.countryName.toLowerCase().includes(filterValue));
  }

  checkCountryChange(e: any, item: CountryModel) {
    if (item.countryGUID == 'all') {
      this.countryList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.countryList.forEach(x => {
        if (item.countryGUID == x.countryGUID) {
          x.selected = e.checked;
        }
      });
    }
    this.setCountryList();
  }

  setCountryList() {
    this.selectedCountryList = this.countryList.filter(u => u.selected == true);
    this.selectedCountryCountText = "";
    if (this.countryList.length > 0) {
      if (this.selectedCountryList.length == this.countryList.length) {
        this.selectedCountryCountText = ": All";
      }
      else {
        if (this.selectedCountryList.length > 0)
          this.selectedCountryCountText = ": " + this.selectedCountryList.length.toString();
      }
    }
  }

  clickDoneCountry() {
    this.showCountryList = false;
    this.clickSubmit();
  }

  clickResetCountry() {
    this.countryList.forEach(x => {
      x.selected = false;
    });
    this.f.country_search.setValue("");
    this.setCountryList();
    this.clickSubmit();
  }
  //country end

  // airport
  clickAirportFilter() {
    this.getAirportList();
  }

  getAirportList() {

    this.showAirportList = true;
    this.showCountryList = false;
    this.showFuelSupplierList = false;
    this.showIntoPlaneAgentList = false;
    this.showTaxFeeDescList = false;
    this.showunitofMeasureList = false;

    this.airportList = [];
    let request = new TaxesAndFeesModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).join(";");
    }


    if (this.selectedTaxAndFeeDescIDList.length == this.taxFeeDescList.length) {
      request.selectedTaxAndFeeDescIDList = "";
    }
    else {
      this.selectedTaxAndFeeDescIDList = this.selectedTaxAndFeeDescIDList.filter(x => x.lk_TaxFeeDescriptionID.toString() != 'all');
      request.selectedTaxAndFeeDescIDList = Array.prototype.map.call(this.selectedTaxAndFeeDescIDList, s => s.lk_TaxFeeDescriptionID).toString();
    }

    if (this.selectedUnitOfMeasureIDList.length == this.unitofMeasureList.length) {
      request.selectedUnitOfMeasureIDList = "";
    }
    else {
      this.selectedUnitOfMeasureIDList = this.selectedUnitOfMeasureIDList.filter(x => x.lk_UnitOfMeasureID != 'all');
      request.selectedUnitOfMeasureIDList = Array.prototype.map.call(this.selectedUnitOfMeasureIDList, s => s.lk_UnitOfMeasureID).toString();
    }


    request.isActive = !this.f.includeDisabledRecords.value;
    request.showExpiredOnly =  this.f.showExpiredOnly.value;

    this._fuelService.getTAFAirportList<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new AirportModel();

          response.model.forEach(x => {
            a = new AirportModel();
            a.icao = x.icao;
            a.airportID = x.airportID;
            a.selected = false;
            if (this.selectedAirportList.length > 0) {
              let f: AirportModel[];
              f = this.selectedAirportList.filter(y => y.airportID == x.airportID);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.airportList.push(a);
          });
          this.airportList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.icao.localeCompare(b.icao));
          a = new AirportModel();
          a.airportID = -1;
          a.icao = "Select All";
          a.selected = false;
          this.airportList.unshift(a);
          this.filteredAirportList = this.f.airportSearch.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.airportName)),
            map(airportName => (airportName ? this._filterAirport(airportName) : this.airportList.slice())),
          );
          this.setAirportList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameAirport(airport: AirportModel): string {
    return airport && airport.icao ? airport.icao : '';
  }

  private _filterAirport(name: string): AirportModel[] {
    const filterValue = name.toLowerCase();

    return this.airportList.filter(option => option.icao.toLowerCase().includes(filterValue));
  }

  checkAirportChange(e: any, item: AirportModel) {
    if (item.airportID == -1) {
      this.airportList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.airportList.forEach(x => {
        if (item.airportID == x.airportID) {
          x.selected = e.checked;
        }
      });
    }

    this.setAirportList();
  }

  setAirportList() {
    this.selectedAirportList = this.airportList.filter(u => u.selected == true);
    this.selectedAirportCountText = "";
    if (this.airportList.length > 0) {
      if (this.selectedAirportList.length == this.airportList.length) {
        this.selectedAirportCountText = ": All";
      }
      else {
        if (this.selectedAirportList.length > 0)
          this.selectedAirportCountText = ": " + this.selectedAirportList.length.toString();
      }
    }
  }

  clickDoneAirport() {
    this.showAirportList = false;
    this.clickSubmit();
  }

  clickResetAirport() {
    this.airportList.forEach(x => {
      x.selected = false;
    });
    this.f.airportSearch.setValue("");
    this.setAirportList();
    this.clickSubmit();
  }
  // airport end

  // fuelSupplier
  clickFuelSupplierFilter() {
    this.getFuelSupplierList();
  }

  getFuelSupplierList() {

    this.showFuelSupplierList = true;
    this.showAirportList = false;
    this.showCountryList = false;
    this.showIntoPlaneAgentList = false;
    this.showTaxFeeDescList = false;
    this.showunitofMeasureList = false;

    this.fuelsupplierList = [];
    let request = new TaxesAndFeesModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).join(";");
    }


    if (this.selectedTaxAndFeeDescIDList.length == this.taxFeeDescList.length) {
      request.selectedTaxAndFeeDescIDList = "";
    }
    else {
      this.selectedTaxAndFeeDescIDList = this.selectedTaxAndFeeDescIDList.filter(x => x.lk_TaxFeeDescriptionID.toString() != 'all');
      request.selectedTaxAndFeeDescIDList = Array.prototype.map.call(this.selectedTaxAndFeeDescIDList, s => s.lk_TaxFeeDescriptionID).toString();
    }

    if (this.selectedUnitOfMeasureIDList.length == this.unitofMeasureList.length) {
      request.selectedUnitOfMeasureIDList = "";
    }
    else {
      this.selectedUnitOfMeasureIDList = this.selectedUnitOfMeasureIDList.filter(x => x.lk_UnitOfMeasureID.toString() != 'all');
      request.selectedUnitOfMeasureIDList = Array.prototype.map.call(this.selectedUnitOfMeasureIDList, s => s.lk_UnitOfMeasureID).toString();
    }


    request.isActive = !this.f.includeDisabledRecords.value;
    request.showExpiredOnly =  this.f.showExpiredOnly.value;
    this._fuelService.getTAFFuelSupplierList<ResponseModel<FuelSupplierModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new FuelSupplierModel();

          response.model.forEach(x => {
            a = new FuelSupplierModel();
            a.fuelSupplierGUID = x.fuelSupplierGUID;
            a.fuelSupplierShortname = x.fuelSupplierShortname;
            // a.selected = false;
            if (this.selectedFuelSupplierList.length > 0) {
              let f: FuelSupplierModel[];
              f = this.selectedFuelSupplierList.filter(y => y.fuelSupplierGUID == x.fuelSupplierGUID);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.fuelsupplierList.push(a);
          });
          this.fuelsupplierList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.fuelSupplierShortname.localeCompare(b.fuelSupplierShortname));
          a = new FuelSupplierModel();
          a.fuelSupplierGUID = "";// -1;
          a.fuelSupplierShortname = "Select All";
          a.selected = false;
          this.fuelsupplierList.unshift(a);
          this.filteredFuelSupplierList = this.f.fuelsupplier_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.fuelSupplierShortname)),
            map(fuelSupplierShortname => (fuelSupplierShortname ? this._filterFuelSupplier(fuelSupplierShortname) : this.fuelsupplierList.slice())),
          );
          this.setFuelSupplierList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameFuelSupplier(fs: FuelSupplierModel): string {
    return fs && fs.fuelSupplierShortname ? fs.fuelSupplierShortname : '';
  }

  private _filterFuelSupplier(name: string): FuelSupplierModel[] {
    const filterValue = name.toLowerCase();

    return this.fuelsupplierList.filter(option => option.fuelSupplierShortname.toLowerCase().includes(filterValue));
  }

  checkFuelSupplierChange(e: any, item: FuelSupplierModel) {
    if (item.fuelSupplierGUID == '') {
      this.fuelsupplierList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.fuelsupplierList.forEach(x => {
        if (item.fuelSupplierGUID == x.fuelSupplierGUID) {
          x.selected = e.checked;
        }
      });
    }

    this.setFuelSupplierList();
  }

  setFuelSupplierList() {
    this.selectedFuelSupplierList = this.fuelsupplierList.filter(u => u.selected == true);
    this.selectedFuelSupplierCountText = "";
    if (this.fuelsupplierList.length > 0) {
      if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
        this.selectedFuelSupplierCountText = ": All";
      }
      else {
        if (this.selectedFuelSupplierList.length > 0)
          this.selectedFuelSupplierCountText = ": " + this.selectedFuelSupplierList.length.toString();
      }
    }
  }

  clickDoneFuelSupplier() {
    this.showFuelSupplierList = false;
    this.clickSubmit();
  }

  clickResetFuelSupplier() {
    this.fuelsupplierList.forEach(x => {
      x.selected = false;
    });
    this.f.fuelsupplier_search.setValue("");
    this.setFuelSupplierList();
    this.clickSubmit();
  }
  // fuelSupplier end

  // IntoPlaneAgent
  clickIntoPlaneAgentFilter() {
    this.getIntoPlaneAgentList();
  }

  getIntoPlaneAgentList() {
    this.showIntoPlaneAgentList = true;
    this.showAirportList = false;
    this.showCountryList = false;
    this.showFuelSupplierList = false;
    this.showTaxFeeDescList = false;
    this.showunitofMeasureList = false;

    this.intoplaneagentList = [];
    let request = new TaxesAndFeesModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).join(";");
    }


    if (this.selectedTaxAndFeeDescIDList.length == this.taxFeeDescList.length) {
      request.selectedTaxAndFeeDescIDList = "";
    }
    else {
      this.selectedTaxAndFeeDescIDList = this.selectedTaxAndFeeDescIDList.filter(x => x.lk_TaxFeeDescriptionID.toString() != 'all');
      request.selectedTaxAndFeeDescIDList = Array.prototype.map.call(this.selectedTaxAndFeeDescIDList, s => s.lk_TaxFeeDescriptionID).toString();
    }

    if (this.selectedUnitOfMeasureIDList.length == this.unitofMeasureList.length) {
      request.selectedUnitOfMeasureIDList = "";
    }
    else {
      this.selectedUnitOfMeasureIDList = this.selectedUnitOfMeasureIDList.filter(x => x.lk_UnitOfMeasureID != 'all');
      request.selectedUnitOfMeasureIDList = Array.prototype.map.call(this.selectedUnitOfMeasureIDList, s => s.lk_UnitOfMeasureID).toString();
    }



    request.isActive = !this.f.includeDisabledRecords.value;
    request.showExpiredOnly =  this.f.showExpiredOnly.value;
    this._fuelService.getTAFIntoPlaneAgentList<ResponseModel<IntoPlaneAgentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new IntoPlaneAgentModel();

          response.model.forEach(x => {
            a = new IntoPlaneAgentModel();
            a.intoPlaneAgent = x.intoPlaneAgent;
            //a.fuelSupplierShortname = x.fuelSupplierShortname;
            // a.selected = false;
            if (this.selectedIntoPlaneAgentList.length > 0) {
              let f: IntoPlaneAgentModel[];
              f = this.selectedIntoPlaneAgentList.filter(y => y.intoPlaneAgent == x.intoPlaneAgent);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.intoplaneagentList.push(a);
          });
          this.intoplaneagentList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.intoPlaneAgent.localeCompare(b.intoPlaneAgent));
          a = new IntoPlaneAgentModel();
          a.intoPlaneAgent = "";// -1;
          a.intoPlaneAgent = "Select All";
          a.selected = false;
          this.intoplaneagentList.unshift(a);
          this.filteredIntoPlaneAgentList = this.f.intoplaneagent_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.intoPlaneAgent)),
            map(intoPlaneAgent => (intoPlaneAgent ? this._filterIntoPlaneAgent(intoPlaneAgent) : this.intoplaneagentList.slice())),
          );
          this.setIntoPlaneAgentList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameIntoPlaneAgent(intoplaneagent: IntoPlaneAgentModel): string {
    return intoplaneagent && intoplaneagent.intoPlaneAgent ? intoplaneagent.intoPlaneAgent : '';
  }

  private _filterIntoPlaneAgent(name: string): IntoPlaneAgentModel[] {
    const filterValue = name.toLowerCase();

    return this.intoplaneagentList.filter(option => option.intoPlaneAgent.toLowerCase().includes(filterValue));
  }

  checkIntoPlaneAgentChange(e: any, item: IntoPlaneAgentModel) {
    if (item.intoPlaneAgent == 'Select All') {
      this.intoplaneagentList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.intoplaneagentList.forEach(x => {
        if (item.intoPlaneAgent == x.intoPlaneAgent) {
          x.selected = e.checked;
        }
      });
    }

    this.setIntoPlaneAgentList();
  }

  setIntoPlaneAgentList() {
    this.selectedIntoPlaneAgentList = this.intoplaneagentList.filter(u => u.selected == true);
    this.selectedIntoPlaneAgentCountText = "";
    if (this.intoplaneagentList.length > 0) {
      if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
        this.selectedIntoPlaneAgentCountText = ": All";
      }
      else {
        if (this.selectedIntoPlaneAgentList.length > 0)
          this.selectedIntoPlaneAgentCountText = ": " + this.selectedIntoPlaneAgentList.length.toString();
      }
    }
  }

  clickDoneIntoPlaneAgent() {
    this.showIntoPlaneAgentList = false;
    this.clickSubmit();
  }

  clickResetIntoPlaneAgent() {
    this.intoplaneagentList.forEach(x => {
      x.selected = false;
    });
    this.f.intoplaneagent_search.setValue("");
    this.setIntoPlaneAgentList();
    this.clickSubmit();
  }
  // IntoPlaneAgent end

  // TaxFeeDescription
  clickTaxFeeDescFilter() {
    this.getTaxFeeDescList();
  }

  getTaxFeeDescList() {
    this.showTaxFeeDescList = true;
    this.showAirportList = false;
    this.showCountryList = false;
    this.showFuelSupplierList = false;
    this.showIntoPlaneAgentList = false;
    this.showunitofMeasureList = false;

    this.taxFeeDescList = [];
    let request = new TaxesAndFeesModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).join(";");
    }



    if (this.selectedTaxAndFeeDescIDList.length == this.taxFeeDescList.length) {
      request.selectedTaxAndFeeDescIDList = "";
    }
    else {
      this.selectedTaxAndFeeDescIDList = this.selectedTaxAndFeeDescIDList.filter(x => x.taxFeeDescription != 'all');
      request.selectedTaxAndFeeDescIDList = Array.prototype.map.call(this.selectedTaxAndFeeDescIDList, s => s.lk_TaxFeeDescriptionID).toString();
    }

    if (this.selectedUnitOfMeasureIDList.length == this.unitofMeasureList.length) {
      request.selectedUnitOfMeasureIDList = "";
    }
    else {
      this.selectedUnitOfMeasureIDList = this.selectedUnitOfMeasureIDList.filter(x => x.unitOfMeasure != 'all');
      request.selectedUnitOfMeasureIDList = Array.prototype.map.call(this.selectedUnitOfMeasureIDList, s => s.lk_UnitOfMeasureID).toString();
    }



    request.isActive = !this.f.includeDisabledRecords.value;
    request.showExpiredOnly =  this.f.showExpiredOnly.value;
    this._fuelService.getTAFTaxFeeDescList<ResponseModel<TaxesAndFeesDescModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new TaxesAndFeesDescModel();

          response.model.forEach(x => {
            a = new TaxesAndFeesDescModel();
            a.lk_TaxFeeDescriptionID = x.lk_TaxFeeDescriptionID;
            a.taxFeeDescription = x.taxFeeDescription;
            //a.fuelSupplierShortname = x.fuelSupplierShortname;
            // a.selected = false;
            if (this.selectedTaxAndFeeDescIDList.length > 0) {
              let f: TaxesAndFeesDescModel[];
              f = this.selectedTaxAndFeeDescIDList.filter(y => y.lk_TaxFeeDescriptionID == x.lk_TaxFeeDescriptionID);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.taxFeeDescList.push(a);
          });
          this.taxFeeDescList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.taxFeeDescription.localeCompare(b.taxFeeDescription));
          a = new TaxesAndFeesDescModel();
          a.lk_TaxFeeDescriptionID =   -1;
          a.taxFeeDescription = "Select All";
          a.selected = false;
          this.taxFeeDescList.unshift(a);
          this.filteredtaxFeedescList = this.f.taxFeeDesc_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.taxFeeDescription)),
            map(TaxFeeDesc => (TaxFeeDesc ? this._filterTaxFeeDesc(TaxFeeDesc) : this.taxFeeDescList.slice())),
          );
          this.setTaxFeeDescList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameTaxFeeDesc(TaxFeeDesc: TaxesAndFeesDescModel): string {
    return TaxFeeDesc && TaxFeeDesc.taxFeeDescription ? TaxFeeDesc.taxFeeDescription : '';
  }

  private _filterTaxFeeDesc(name: string): TaxesAndFeesDescModel[] {
    const filterValue = name.toLowerCase();

    return this.taxFeeDescList.filter(option => option.taxFeeDescription.toLowerCase().includes(filterValue));
  }

  checkTaxFeeDescChange(e: any, item: TaxesAndFeesDescModel) {
    if (item.taxFeeDescription == 'Select All') {
      this.taxFeeDescList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.taxFeeDescList.forEach(x => {
        //if (item.taxFeeDescription == x.taxFeeDescription) {
        if (item.lk_TaxFeeDescriptionID == x.lk_TaxFeeDescriptionID) {
          x.selected = e.checked;
        }
      });
    }

    this.setTaxFeeDescList();
  }

  setTaxFeeDescList() {
    this.selectedTaxAndFeeDescIDList = this.taxFeeDescList.filter(u => u.selected == true);
    this.selectedTaxFeeDescCountText = "";
    if (this.taxFeeDescList.length > 0) {
      if (this.selectedTaxAndFeeDescIDList.length == this.taxFeeDescList.length) {
        this.selectedTaxFeeDescCountText = ": All";
      }
      else {
        if (this.selectedTaxAndFeeDescIDList.length > 0)
          this.selectedTaxFeeDescCountText = ": " + this.selectedTaxAndFeeDescIDList.length.toString();
      }
    }
  }

  clickDoneTaxFeeDesc() {
    this.showTaxFeeDescList = false;
    this.clickSubmit();
  }

  clickResetTaxFeeDesc() {
    this.taxFeeDescList.forEach(x => {
      x.selected = false;
    });
    this.f.taxFeeDesc_search.setValue("");
    this.setTaxFeeDescList();
    this.clickSubmit();
  }
  // TaxFeeDesc end

  // UnitOfMeasue
  clickUnitOfMeasureFilter() {
    this.getUnitOfMeasureList();
  }

  getUnitOfMeasureList() {
    this.showunitofMeasureList = true;
    this.showAirportList = false;
    this.showCountryList = false;
    this.showFuelSupplierList = false;
    this.showIntoPlaneAgentList = false;
    this.showTaxFeeDescList = false;

    this.unitofMeasureList = [];
    let request = new TaxesAndFeesModel();// new FuelSupplierModel();
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }

    //if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
    //  request.selectedIntoPlaneAgentList  = "";
    //}
    //else {
    //  this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent   != 'all');
    //  request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).join(";");
    //}

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).join(";");
    }


    if (this.selectedTaxAndFeeDescIDList.length == this.taxFeeDescList.length) {
      request.selectedTaxAndFeeDescIDList = "";
    }
    else {
      this.selectedTaxAndFeeDescIDList = this.selectedTaxAndFeeDescIDList.filter(x => x.lk_TaxFeeDescriptionID.toString() != 'all');
      request.selectedTaxAndFeeDescIDList = Array.prototype.map.call(this.selectedTaxAndFeeDescIDList, s => s.lk_TaxFeeDescriptionID).toString();
    }

    if (this.selectedUnitOfMeasureIDList.length == this.unitofMeasureList.length) {
      request.selectedUnitOfMeasureIDList = "";
    }
    else {
      this.selectedUnitOfMeasureIDList = this.selectedUnitOfMeasureIDList.filter(x => x.lk_UnitOfMeasureID != 'all');
      request.selectedUnitOfMeasureIDList = Array.prototype.map.call(this.selectedUnitOfMeasureIDList, s => s.lk_UnitOfMeasureID).toString();
    }



    request.isActive = !this.f.includeDisabledRecords.value;
    request.showExpiredOnly =  this.f.showExpiredOnly.value;
    this._fuelService.getTAFUnitOfMeasureList<ResponseModel<UnitofmeasureModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let a = new UnitofmeasureModel();

          response.model.forEach(x => {
            a = new UnitofmeasureModel();
            a.lk_UnitOfMeasureID = x.lk_UnitOfMeasureID;
            a.unitOfMeasure = x.unitOfMeasure;
            //a.fuelSupplierShortname = x.fuelSupplierShortname;
            // a.selected = false;
            if (this.selectedUnitOfMeasureIDList.length > 0) {
              let f: UnitofmeasureModel[];
              f = this.selectedUnitOfMeasureIDList.filter(y => y.lk_UnitOfMeasureID == x.lk_UnitOfMeasureID);
              if (f.length > 0) {
                a.selected = true;
              }
            }
            this.unitofMeasureList.push(a);
          });
          this.unitofMeasureList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.unitOfMeasure.localeCompare(b.unitOfMeasure));
          a = new UnitofmeasureModel();
          a.lk_UnitOfMeasureID =   "-1";
          a.unitOfMeasure = "Select All";
          a.selected = false;
          this.unitofMeasureList.unshift(a);
          this.filteredUnitOfMeasureList = this.f.unitOfMeasure_search.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.UnitOfMeasure)),
            map(UnitOfMeasure => (UnitOfMeasure ? this._filterUnitOfMeasure(UnitOfMeasure) : this.unitofMeasureList.slice())),
          );
          this.setUnitOfMeasureList();
        }
        else {
          //this.totalRecordsText = "No record found";
        }

      }

    })
  }

  displayNameUnitOfMeasure(UnitOfMeasure: UnitofmeasureModel): string {
    return UnitOfMeasure && UnitOfMeasure.unitOfMeasure ? UnitOfMeasure.unitOfMeasure : '';
  }

  private _filterUnitOfMeasure(name: string): UnitofmeasureModel[] {
    const filterValue = name.toLowerCase();

    return this.unitofMeasureList.filter(option => option.unitOfMeasure.toLowerCase().includes(filterValue));
  }

  checkUnitOfMeasureChange(e: any, item: UnitofmeasureModel) {
    if (item.unitOfMeasure == 'Select All') {
      this.unitofMeasureList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.unitofMeasureList.forEach(x => {
        if (item.unitOfMeasure == x.unitOfMeasure) {
          x.selected = e.checked;
        }
      });
    }

    this.setUnitOfMeasureList();
  }

  setUnitOfMeasureList() {
    this.selectedUnitOfMeasureIDList = this.unitofMeasureList.filter(u => u.selected == true);
    this.selectedUnitOfMeasureCountText = "";
    if (this.unitofMeasureList.length > 0) {
      if (this.selectedUnitOfMeasureIDList.length == this.unitofMeasureList.length) {
        this.selectedUnitOfMeasureCountText = ": All";
      }
      else {
        if (this.selectedUnitOfMeasureIDList.length > 0)
          this.selectedUnitOfMeasureCountText = ": " + this.selectedUnitOfMeasureIDList.length.toString();
      }
    }
  }

  clickDoneUnitOfMeasure() {
    this.showunitofMeasureList = false;
    this.clickSubmit();
  }

  clickResetUnitOfMeasure() {
    this.unitofMeasureList.forEach(x => {
      x.selected = false;
    });
    this.f.unitOfMeasure_search.setValue("");
    this.setUnitOfMeasureList();
    this.clickSubmit();
  }
  // UnitOfMeasure end

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.taxesandfeeslist.length; //this.matfuelPricingList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.taxesandfeeslist);// this.matfuelPricingList.data);
  }

  checkBox(row: any) {
    this.selection.toggle(row);

  }

  includeDisabledRecordsOnChange(event: any) {
    //let x = event.target.checked;
    //if (this.userType == "internal") {
    //if (this.f.customer_select.value == "") {
    //  this.errMsg = "Please select a client";
    //  return;
    //}
    // }
    // this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.clickSubmit();
  }

  showExpiredOnlyOnChange(event: any) {
    //let x = event.target.checked;
    //if (this.userType == "internal") {
    //if (this.f.customer_select.value == "") {
    //  this.errMsg = "Please select a client";
    //  return;
    //}
    // }
    // this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.clickSubmit();
  }
   
  clickSubmit() {
    this.submitted = true;
    this.errMsg = "";
    if (this.taxesandfees_form.invalid || this.errMsg != "") {
      return;
    }

    //if (this.showClientList) {
    //  this.getClientList();
    //}
    //if (this.showAircraftList) {
    //  this.getAircraftList();
    //}
    if (this.showCountryList) {
      this.getCountryList();
    }
    if (this.showAirportList) {
      this.getAirportList();
    }
    if (this.showFuelSupplierList) {
      this.getFuelSupplierList();
    }
    if (this.showIntoPlaneAgentList) {
      this.getIntoPlaneAgentList();
    }
    if (this.showTaxFeeDescList) {
      this.getTaxFeeDescList();
    }
    if (this.showunitofMeasureList) {
      this.getUnitOfMeasureList();
    }
    //if (this.showPersonList) {
    //  this.getTripPersonList();
    //}
    //if (this.showVendorList) {
    //  this.getVendorList();
    //}

    this.getData();
  }

  getData(isInit: boolean = false) {
    this.showSpin = true;
    let request = new TaxesAndFeesModel(); 
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }
    if (this.fromFuelTask) {
      request.selectedCountryGUIDList = this.selectedCountry;
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if(this.fromMissingTaxesAirport || this.fromFuelTask){
      request.selectedICAOList = this.selectedIcao;
    }
    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }
    if (this.fromFuelTask) {
      request.selectedFuelSupplierList = this.selectedFuelSupplier;
    }

    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).join(";");
    }
    if (this.fromFuelTask) {
      request.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgent;
    }

    if (this.selectedTaxAndFeeDescIDList.length == this.taxFeeDescList.length) {
      request.selectedTaxAndFeeDescIDList = "";
    }

    else {
      this.selectedTaxAndFeeDescIDList = this.selectedTaxAndFeeDescIDList.filter(x => x.lk_TaxFeeDescriptionID.toString() != 'all');
      request.selectedTaxAndFeeDescIDList = Array.prototype.map.call(this.selectedTaxAndFeeDescIDList, s => s.lk_TaxFeeDescriptionID).toString();
    }

    if (this.selectedUnitOfMeasureIDList.length == this.unitofMeasureList.length) {
      request.selectedUnitOfMeasureIDList = "";
    }
    else {
      this.selectedUnitOfMeasureIDList = this.selectedUnitOfMeasureIDList.filter(x => x.lk_UnitOfMeasureID.toString() != 'all');
      request.selectedUnitOfMeasureIDList = Array.prototype.map.call(this.selectedUnitOfMeasureIDList, s => s.lk_UnitOfMeasureID).toString();
    }


    request.isActive = true;
    if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      request.isActive = !this.f.includeDisabledRecords.value;
    }

    if (this.f.showExpiredOnly.value != null && this.f.showExpiredOnly.value != "") {
      request.showExpiredOnly = this.f.showExpiredOnly.value;
    }
     
    if (this.paginator == undefined) {
      request.pageIndex = 0;
      request.pageSize = 25;
    }
    else {
      request.pageIndex = this.paginator.pageIndex;
      request.pageSize = this.paginator.pageSize;
    }
    this.getDataConditions = 'active';

    this._fuelService.getTaxesAndFeesByConditions<ResponseModel<TaxesAndFeesModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.taxesandfeeslist = response.model;
          this.sortedData = this.taxesandfeeslist.slice();
          if (response.model.length > 0) {
            if (this.taxesandfeeslist != null) {
              this.totalRecords = this.taxesandfeeslist[0].totalRecords;
              this.totalRecordsText = this.totalRecords + " total records"
              this.f.includeDisabledRecords.setValue(false);

            }
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }

      this.showSpin = false;
    });
  }

  clickEdit(  taf: TaxesAndFeesModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = "38em";
    let taxesandfeesid=0;
    let dialogTitle = "Add";
    if (taf != null) {
      taxesandfeesid = taf.taxesAndFeesID;
      dialogTitle = "Edit";

    }
      dialogConfig.autoFocus = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { taxesandfeesid: taxesandfeesid, dialogTitle: dialogTitle, 
      selectedIcao: this.selectedIcao.replace('All,', ''), isAirport: this.iscountryorairport, selectedIATA: this.selectedIATA, fromMissingTaxesAirport: this.fromMissingTaxesAirport,
      supplier: this.selectedFuelSupplier.replace('allfuelsuppliers,',''), itp: this.selectedIntoPlaneAgent.replace('All,',''), fromFuelTask: this.fromFuelTask
                         };


    const dialogRef = this._dialog.open(TaxesandFeesEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
       // if (this.sortedData.length == 0)
         // this.f.brID.setValue(result);
        this.isUpdated = true;
        this.getData();
      }
    });
    //  let navigationExtras: NavigationExtras = {
    //    queryParams: {
    //      "businessRulesID": br.businessRulesID        

    //    }
    //  };
    //this._router.navigate(['/business-rules/business-rules-edit'], navigationExtras);
  }

  clickReset() {
    //this.showLegTypeList = false;
    //this.showClientList = false;
    this.showCountryList = false;
    this.showAirportList = false;
    this.showFuelSupplierList = false;
    this.showIntoPlaneAgentList = false;
    this.showTaxFeeDescList = false;
    this.showunitofMeasureList = false;

    this.includeDisabledRecords = false;
    this.showExpiredOnly = false;

    this.selection.clear();
    //this.showPersonList = false;
    //this.showVendorList = false;
    //this.showAircraftList = false;
    //this.showStatusList = false;
    //this.showServiceList = false;
    //this.showExclusionList = false;

    //this.selectedLegTypeList = [];
    //this.selectedLegTypeCountText = "";
    //this.selectedClientList = [];
    //this.selectedClientCountText = "";
    //this.selectedAircraftList = [];
    //this.selectedAircraftCountText = "";
    //this.selectedPaxList = [];
    //this.selectedCrewList = [];
    //this.selectedPersonCountText = "";
    this.selectedCountryList = [];
    this.selectedAirportList = [];
    this.selectedFuelSupplierList = [];
    this.selectedIntoPlaneAgentList = [];
    this.selectedTaxAndFeeDescIDList = [];
    this.selectedUnitOfMeasureIDList = [];

    this.selectedCountryCountText = "";
    this.selectedAirportCountText = "";
    this.selectedFuelSupplierCountText = "";
    this.selectedIntoPlaneAgentCountText = "";
    this.selectedTaxFeeDescCountText = "";
    this.selectedUnitOfMeasureCountText = "";

    //this.selectedVendorList = [];
    //this.selectedVendorCountText = "";
    //this.selectedServiceClassList = [];
    //this.selectedServiceTypeList = [];
    //this.selectedServicesCountText = "";
    //this.selectedApprovalStatusList = [];
    //this.selectedAprrovalStatusCountText = "";
    this.f.startDate.setValue(null);
    this.f.endDate.setValue(null);
    //this.businessRulesList = [];
    //this.avoidLocation = false;
    //this.excludeFromHomebase = false;
    //this.f.brID.setValue(null);
    // this.selectedExclusionCountText = "";
    this.sortedData = [];

    document.querySelectorAll('input[name="getData_type"]').forEach((radio: any, index: number) => {
      radio.checked = index === 0; // Select the first option
    });
    this.f.includeDisabledRecords.setValue(false);
    this.f.showExpiredOnly.setValue(false);
    this.getDataConditions =='active'

    //this.clickSubmit();

    this.initControls();
    this.getData();
  }

  sortData(sort: Sort) {
    const data = this.taxesandfeeslist.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        //'Country', 'ICAO', 'IATA', 'FuelSuppliershortName'
        //case 'Country':
        //  return compare(a.countryName, b.countryName, isAsc);
        //case 'ICAO':
        //  return compare(a.icao, b.icao, isAsc);
        //case 'IATA':
        //  return compare(a.iata, b.iata, isAsc);
        //case 'FuelSuppliershortName':
        //  return compare(a.fuelSuppliershortName, b.fuelSuppliershortName, isAsc);
        //case 'mtow':
        //  return compare(a.mtowMin, b.mtowMin, isAsc);       
        //case 'farType':
        //  return compare(a.farTypeDescription, b.farTypeDescription, isAsc);
        //case 'effectiveDate':
        //  return compare(new Date(a.effectiveDate), new Date(b.effectiveDate), isAsc);
        //case 'expirationDate':
        //  return compare(new Date(a.expirationDate), new Date(b.expirationDate), isAsc);
        case 'Country':
          return compare(a.countryName == null ? 'All' : a.countryName, b.countryName == null ? 'All' : b.countryName, isAsc);
        case 'ICAO':
          return compare(a.icao == null ? 'All' : a.icao, b.icao == null ? 'All' : b.icao, isAsc);
        case 'IATA':
          return compare(a.iata == null ? 'All' : a.iata, b.iata == null ? 'All' : b.iata, isAsc); 
        case 'FuelSuppliershortName':
          return compare(a.fuelSuppliershortName == null ? 'All' : a.fuelSuppliershortName, b.fuelSuppliershortName == null ? 'All' : b.fuelSuppliershortName, isAsc);
        case 'ModifiedDate':
          return compare(a.modifiedDate == null ? 'All' : a.modifiedDate, b.modifiedDate == null ? 'All' : b.modifiedDate, isAsc);
        //case 'IntoPlaneAgent':
        //  return compare(a.intoPlaneAgent == null ? 'All' : a.intoPlaneAgent, b.intoPlaneAgent == null ? 'All' : b.intoPlaneAgent, isAsc);

        default:
          return 0;
      }
    });

    function compare(a: number | string | Date | boolean, b: number | string | Date | boolean, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    //this.currentPageIndex = e.pageIndex;
    //if (this.pageSize != e.pageSize) {
    //  this.totalRecords = 0;
    //  this.totalRecordsText = "";
    //  this.totalPageNumber = 0;
    //  this.pageSize = e.pageSize;
    //this.request.pageSize = this.pageSize;
    //this.request.totalRecords = this.totalRecords;
    //}
    // this.request.pageIndex = this.currentPageIndex;
    this.clickSubmit();

  }

  exportData1() {
    this.exportToExcel(this.sortedData,'TaxesandFees');
    
  }
  
  exportToExcel(data: any[], filename: string): void {
    
    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); 
    // XLSX.writeFile(wb, filename + '.xlsx');

    let wb=new Workbook();
    let ws =wb.addWorksheet("TaxesandFees")
    
    let  header = [ "Country", "ICAO", "IATA", "Fuel Supplier", "Into Plane Agent", "Effective Date", "Expiry Date", "MinScale", "MaxScale", "Unit of Measure", "Currency", "Tax Fee Description",
     "Taxes"," (USD/USG)", "Unit", "Calculate On Total", "Exempt FAR Type", "Internal Notes", "Modified Date", "Modified By" ];
    ws.addRow(header);
    data.forEach( sd => {
      
      ws.addRow([sd.countryName, sd.icao , sd.iata, sd.fuelSupplierShortName==null?'All':sd.fuelSupplierShortName, sd.intoPlaneAgent, sd.effectiveDate, sd.expiryDate, 
      sd.minScale == 0?'':sd.minScale , sd.maxScale == 0?'':sd.maxScale  , sd.unitofMeasure, sd.currency, 
      sd.taxFeeDescription,  sd.taxStandard == 1 ?  sd.perUnitPrice  :sd.taxStandard == 2 ?  sd.flatFeeAmount  :  sd.flatRatePercent+'%' , sd.usd==0?'':sd.usd,
       sd.taxStandard == 1 ? 'Per Unit'   :sd.taxStandard == 2 ? 'Flat Rate'  : 'Flat Percent', sd.calculateOnTotal==true?'Yes':'No' , sd.exemptFARType,
        sd.internalNotes, sd.modifiedDate, sd.modifiedBy ]);
      
    } )

    wb.xlsx.writeBuffer().then((data)=>{
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob,"TaxesandFees.xlsx");
    });
  }

  clickRoute(routePage: string) {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    this._dialog.open(FuelMenuComponent, config);   
  }

  getDataConditionsOnChange(e: any){
    switch (e.target.value) {
      case 'active':
        this.f.includeDisabledRecords.setValue(false);
        this.f.showExpiredOnly.setValue(false);
        this.getData();
        break;
      case 'disabled':
        this.f.includeDisabledRecords.setValue(true);
        this.f.showExpiredOnly.setValue(false);
        this.getData();
        break;
      case 'expired':
        this.f.includeDisabledRecords.setValue(false);
        this.f.showExpiredOnly.setValue(true);
        this.getData();
        break;
    }

  }

  clickClose() {
    this._dialogRef.close(this.isUpdated);
  }

  exportData( ) {
    this.showSpin = true;
    let request = new TaxesAndFeesModel(); 
    request.effectiveDate = "";

    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      request.effectiveDate = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    request.expirationDate = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      request.expirationDate = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }

    if (this.selectedCountryList.length == this.countryList.length) {
      request.selectedCountryGUIDList = "";
    }
    else {
      this.selectedCountryList = this.selectedCountryList.filter(x => x.countryGUID != 'all');
      request.selectedCountryGUIDList = Array.prototype.map.call(this.selectedCountryList, s => s.countryGUID).toString();
    }

    if (this.selectedAirportList.length == this.airportList.length) {
      request.selectedICAOList = "";
    }
    else {
      this.selectedAirportList = this.selectedAirportList.filter(x => x.airportID.toString() != 'all');
      request.selectedICAOList = Array.prototype.map.call(this.selectedAirportList, s => s.icao).toString();
    }

    if(this.fromMissingTaxesAirport || this.fromFuelTask){
      request.selectedICAOList = this.selectedIcao;
    }
    if (this.selectedFuelSupplierList.length == this.fuelsupplierList.length) {
      request.selectedFuelSupplierList = "";
    }
    else {
      this.selectedFuelSupplierList = this.selectedFuelSupplierList.filter(x => x.fuelSupplierGUID != 'all');
      request.selectedFuelSupplierList = Array.prototype.map.call(this.selectedFuelSupplierList, s => s.fuelSupplierGUID).toString();
    }
    if (this.fromFuelTask) {
      request.selectedFuelSupplierList = this.selectedFuelSupplier;
    }
    if (this.selectedIntoPlaneAgentList.length == this.intoplaneagentList.length) {
      request.selectedIntoPlaneAgentList = "";
    }
    else {
      this.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgentList.filter(x => x.intoPlaneAgent != 'all');
      request.selectedIntoPlaneAgentList = Array.prototype.map.call(this.selectedIntoPlaneAgentList, s => s.intoPlaneAgent).join(";");
    }

    if (this.fromFuelTask) {
      request.selectedIntoPlaneAgentList = this.selectedIntoPlaneAgent;
    }

    if (this.selectedTaxAndFeeDescIDList.length == this.taxFeeDescList.length) {
      request.selectedTaxAndFeeDescIDList = "";
    }
    else {
      this.selectedTaxAndFeeDescIDList = this.selectedTaxAndFeeDescIDList.filter(x => x.lk_TaxFeeDescriptionID.toString() != 'all');
      request.selectedTaxAndFeeDescIDList = Array.prototype.map.call(this.selectedTaxAndFeeDescIDList, s => s.lk_TaxFeeDescriptionID).toString();
    }

    if (this.selectedUnitOfMeasureIDList.length == this.unitofMeasureList.length) {
      request.selectedUnitOfMeasureIDList = "";
    }
    else {
      this.selectedUnitOfMeasureIDList = this.selectedUnitOfMeasureIDList.filter(x => x.lk_UnitOfMeasureID.toString() != 'all');
      request.selectedUnitOfMeasureIDList = Array.prototype.map.call(this.selectedUnitOfMeasureIDList, s => s.lk_UnitOfMeasureID).toString();
    }


    request.isActive = true;
    if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      request.isActive = !this.f.includeDisabledRecords.value;
    }

    if (this.f.showExpiredOnly.value != null && this.f.showExpiredOnly.value != "") {
      request.showExpiredOnly = this.f.showExpiredOnly.value;
    }
     
    if (this.paginator == undefined) {
      request.pageIndex = 0;
      request.pageSize = this.totalRecords;
    }
    else {
      request.pageIndex = 0;
      request.pageSize = this.totalRecords;
    }
    this._fuelService.getTaxesAndFeesByConditions<ResponseModel<TaxesAndFeesModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.taxesandfeeslist2 = response.model;
          this.sortedData2 = this.taxesandfeeslist2.slice();
          if (response.model.length > 0) {
            this.exportToExcel(this.sortedData2,'TaxesandFees');

          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
      this.showSpin = false;
    });


  }

}



//    this.f.showExpiredOnly.value;this.f.includeDisabledRecords
