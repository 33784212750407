import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable } from 'rxjs';
import { CustomValidators } from '../common-utility/custom.validators';
import { CustomerRegulatoryServiceModel } from '../models/customer-regulatory-service.model';
import { GovernmentServiceTypeModel } from '../models/government-service-type.model';
import { RenewalStatusModel } from '../models/renewal-status.model';
import { ReportFrequecyModel } from '../models/report-frequency.model';
import { ResponseModel } from '../models/response.model';
import { VendorModel } from '../models/vendor.model';
import { CustomerService } from '../services/customer.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-customer-regulatory-service-edit-dialog',
  templateUrl: './customer-regulatory-service-edit-dialog.component.html',
  styleUrls: ['./customer-regulatory-service-edit-dialog.component.css']
})
/** customer-regulatory-services-edit-dialog component*/
export class CustomerRegulatoryServicesEditDialogComponent implements OnInit {
  customer_regulatory_services_edit_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() customerGuid: string;
  @Input() customerRegulatoryServiceGuid: string;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  governmentServiceList: GovernmentServiceTypeModel[];
  reportFrequencyList: ReportFrequecyModel[];
  renewalStatusList: RenewalStatusModel[];
  vendorList: VendorModel[];
  selectedGovernmentServiceTypeId: number;
  selectRenewalStatusId: number;
  selectReportFrequencyId: number;
  authorizationNumber: string;
  isFPIArranged: number;
  obtainedByVendorGuid: string;
  remarks: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  filingRequired: number;
  processed_by: string;
  serviceDate: Date;
  effectiveDate: Date;
  expirationDate: Date;
  contactedDate: Date;
  dueDate: Date;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  authNumber: string = "Authorization Number";
  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;
  @ViewChild('serviceDate') serviceDateRef: ElementRef;
  @ViewChild('contactedDate') contactedDateRef: ElementRef;
  @ViewChild('dueDate') dueDateRef: ElementRef;
  /** customer-regulatory-services-edit-dialog ctor */
  constructor(private readonly _dialogRef: MatDialogRef<CustomerRegulatoryServicesEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _customerService: CustomerService,
    private readonly _dialogService: DialogService,
  ) {
    this.customerGuid = data.customerGuid;
    this.customerRegulatoryServiceGuid = data.customerRegulatoryServiceGuid;
    this.dialogTitle = data.dialogTitle

  }

  getAllData(): Observable<any[]> {
    let getGovernmentServiceTypeResponse = this._commonService.getGovernmentServiceList<ResponseModel<GovernmentServiceTypeModel[]>>(true);
    let getReportFrequencyTypeResponse = this._commonService.getReportFrequencyList<ResponseModel<ReportFrequecyModel[]>>();
    let getRenewalStatusTypeResponse = this._commonService.getRenewalStatusList<ResponseModel<RenewalStatusModel[]>>();
    if (this.customerRegulatoryServiceGuid != null && this.customerRegulatoryServiceGuid != "") {
      let getCustomerRegualtoryResponse = this._customerService.getCustomerRegulatoryServiceBycrsId(this.customerRegulatoryServiceGuid);
      return forkJoin([getGovernmentServiceTypeResponse, getReportFrequencyTypeResponse, getRenewalStatusTypeResponse, getCustomerRegualtoryResponse]);
    }
    else
      return forkJoin([getGovernmentServiceTypeResponse, getReportFrequencyTypeResponse, getRenewalStatusTypeResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.selectedGovernmentServiceTypeId = null;
    this.remarks = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.effectiveDate = null;
    this.expirationDate = null;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.governmentServiceList = responses[0].model;

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.reportFrequencyList = responses[1].model;

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.renewalStatusList = responses[2].model;

        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

     
      if (this.customerRegulatoryServiceGuid != null && this.customerRegulatoryServiceGuid != "") {
        if (responses[3] != null) {
          if (responses[3].code == "200" && responses[3].message == "") {
            let customerReg = new CustomerRegulatoryServiceModel();
            customerReg = responses[3].model;
            this.selectedGovernmentServiceTypeId = customerReg.governmentServiceTypeID;
            this.selectReportFrequencyId = customerReg.reportFrequencyID;
            this.selectRenewalStatusId = customerReg.renewalStatusID;
            if (customerReg.serviceDate != null && customerReg.serviceDate != "")
              this.serviceDate = new Date(customerReg.serviceDate);
            if (customerReg.effectiveDate != null && customerReg.effectiveDate != "")
              this.effectiveDate = new Date(customerReg.effectiveDate);
            if (customerReg.expirationDate != null && customerReg.expirationDate != "")
              this.expirationDate = new Date(customerReg.expirationDate);
            this.authorizationNumber = customerReg.authorizationNumber;
            if (customerReg.isFPIArranged == true)
              this.isFPIArranged = 1;
            else
              this.isFPIArranged = 0;
            this.remarks = customerReg.remarks;
            this.modifiedBy = customerReg.modifiedBy;
            this.modifiedDate = customerReg.modifiedDate;
            if (customerReg.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;

          }
          else {
            if (responses[3].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }

      this.initControls();
      this.typeChange();

      this.showSpin = false;


    });

  }

  get f() { return this.customer_regulatory_services_edit_form.controls; }


  initControls() {
    this.customer_regulatory_services_edit_form = this._formBuilder.group({
      gov_service_type: [this.selectedGovernmentServiceTypeId, Validators.required],
      authorization_number: this.authorizationNumber,
      arranged_by: [this.isFPIArranged, Validators.required],
      effectiveDate: [this.effectiveDate, Validators.required],
      expirationDate: this.expirationDate,
      serviceDate: this.serviceDate,
      contactedDate: this.contactedDate,
      dueDate: this.dueDate,
      obtained_by: this.obtainedByVendorGuid,
      renewal_status: this.selectRenewalStatusId,
      report_frequency: this.selectReportFrequencyId,
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate,
      reg_status: this.isActive,

      remarks: this.remarks
    });
  }

  typeChange() {
    this.f.authorization_number.clearValidators();
    this.f.report_frequency.clearValidators();
    this.f.renewal_status.clearValidators();
    this.f.expirationDate.clearValidators();
    switch (this.f.gov_service_type.value) {
      case 1: //Eurocontrol
        this.authNumber = "Account Number";
        this.f.authorization_number.setValidators(Validators.required);
        break;
      case 2: //Seneam
        this.authNumber = "Account Number";
        break;
      case 3: //Cocesna
        this.authNumber = "Account Number";
        break;
      case 5: //UK APD
        this.authNumber = "Account Number";
        this.f.report_frequency.setValidators(Validators.required);
        break;
      case 7: //BOE
        this.authNumber = "Authorization Number";
        this.f.authorization_number.setValidators(Validators.required);
        this.f.expirationDate.setValidators(Validators.required);
        this.f.renewal_status.setValidators(Validators.required);
        break;
      case 8: //VWP
        this.authNumber = "Account Number";
        this.f.expirationDate.setValidators(Validators.required);
        this.f.renewal_status.setValidators(Validators.required);
        break;
      case 9: //Part 375
        this.authNumber = "Authorization Number";
        this.f.expirationDate.setValidators(Validators.required);
        this.f.renewal_status.setValidators(Validators.required);
        break;
      default:
        this.authNumber = "Account Number";

    }
    this.customer_regulatory_services_edit_form.get("authorization_number").updateValueAndValidity();
    this.customer_regulatory_services_edit_form.get("report_frequency").updateValueAndValidity();
    this.customer_regulatory_services_edit_form.get("renewal_status").updateValueAndValidity();
    this.customer_regulatory_services_edit_form.get("expirationDate").updateValueAndValidity();

  }

  reportFrequencyChange() {
    this.f.authorization_number.clearValidators();
    switch (this.f.report_frequency.value) {
      case 1:        
      case 2:
        this.f.authorization_number.setValidators(Validators.required);
        break;
    }
    this.customer_regulatory_services_edit_form.get("authorization_number").updateValueAndValidity();

  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";

    if (this.effectiveDateRef.nativeElement.value !== "") {
      if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.effectiveDate.setErrors({ isValidDate: f1 });

        }
      }
      else {
        this.f.effectiveDate.setErrors({ isValidDate: false });

      }
    }
    if (this.f.renewal_status.value != 7) {
    if (this.expirationDateRef.nativeElement.value !== "") {
      if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null) {
        let s2 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
        let f2 = CustomValidators.isValidDate(s2);
        if (!f2) {
          this.f.expirationDate.setErrors({ isValidDate: f2 });

        }
      }
      else {
        this.f.expirationDate.setErrors({ isValidDate: false });

      }
      }
    }



    if (this.customer_regulatory_services_edit_form.invalid || this.errMsg != "") {
      return;
    }

    let obj = new CustomerRegulatoryServiceModel();
    obj.customerRegulatoryServiceGuid = null;
    if (this.customerRegulatoryServiceGuid != "" && this.customerRegulatoryServiceGuid != null)
      obj.customerRegulatoryServiceGuid = this.customerRegulatoryServiceGuid;
    obj.customerGuid = this.customerGuid;
    obj.governmentServiceTypeID = this.f.gov_service_type.value;
    obj.authorizationNumber = this.f.authorization_number.value;
    obj.effectiveDate = null;
    if (this.f.effectiveDate.value != null) {
      obj.effectiveDate = this.effectiveDateRef.nativeElement.value;
    }
    obj.expirationDate = null;
    if (this.f.expirationDate.value != null) {
      obj.expirationDate = this.expirationDateRef.nativeElement.value;
    }
    obj.serviceDate = null;
    if (this.f.serviceDate.value != null) {
      obj.serviceDate = this.serviceDateRef.nativeElement.value;
    }
    obj.contactedDate = null;
    if (this.f.contactedDate.value != null) {
      obj.contactedDate = this.contactedDateRef.nativeElement.value;
    }
    obj.dueDate = null;
    if (this.f.dueDate.value != null) {
      obj.dueDate = this.dueDateRef.nativeElement.value;
    }
    obj.reportFrequencyID = this.f.report_frequency.value;
    obj.renewalStatusID = this.f.renewal_status.value;
    obj.remarks = this.f.remarks.value;
    if (this.f.arranged_by.value == "1")
      obj.isFPIArranged = true;
    else
      obj.isFPIArranged = false;

    if (this.f.reg_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;



    this._customerService.saveCustomerRegulatoryService<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.customerRegulatoryServiceGuid = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString().padStart(2, "0");
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          //this._dialogService.openAlertDialog("Your information has been submitted successfully.");

          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this._dialogRef.close(this.isModified);
          }, 1000);

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");

        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

}
