import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { CustomerModel } from '../models/customer.model';
import { VendorModel } from '../models/vendor.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
import { TripRevisionModel } from '../models/trip-revision.model';
import { TripModel } from '../models/trip.model';
import { TripAuditDialogComponent } from './trip-audit-dialog.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-trip-audit-dashboard',
  templateUrl: './trip-audit-dashboard.component.html',
  styleUrls: ['./trip-audit-dashboard.component.css']
})

export class TripAuditDashboardComponent implements OnInit {
  userType: string;
  //customerGUID: string;
  trip_audit_dashboard_form: UntypedFormGroup;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  //customerList: CustomerModel[];

  v: number = this._authService.getCurrentTimeNumber();
  queryHr: number;
  queryHrText: string;
  queryHourList: Array<number>;
  queryIndex: number;
  totalRecordsText: string;
  recordList: TripRevisionModel[]=[];
  fromDate: Date;
  toDate: Date;
  fromDateText: string;
  toDateText: string;
  displayedColumn: string[] = ['userName', 'otherInfo'];
  upPII: boolean = false;
  user_type: string = "";
  orgRecordList: TripRevisionModel[] = []; 
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService, private _titleService:Title) {

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.user_type = "";
    this.orgRecordList = [];
    this.totalRecordsText = "";
    this.recordList = [];
    this.queryHourList = [1, 4, 8, 12, 24, 48, 72];
    this.queryIndex = 3;
    this.queryHr = this.queryHourList[this.queryIndex];   
    this.queryHrText = this.queryHr.toString() + " hrs";
    this.getFromToDate();
    
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }  

    this.initControls();

    this.getData();

  }

  initControls() {
    this.trip_audit_dashboard_form = this._formBuilder.group({
      queryHour: [this.queryIndex],
      includeDemo: [false],
      showMobileOnly: [false],
      user_type:[this.user_type]
    });

  }

  get f() { return this.trip_audit_dashboard_form.controls; }

  getFromToDate() {
    let d = new Date();
    let uyear: number = d.getUTCFullYear();
    let umonth: number = d.getUTCMonth();
    let udate: number = d.getUTCDate();
    let uhour: number = d.getUTCHours();
    let uminute: number = d.getUTCMinutes();
    this.toDate = new Date(uyear, umonth, udate, uhour, uminute);
    let tempToMonth: number;
    tempToMonth = this.toDate.getMonth() + 1;
    this.toDateText = tempToMonth.toString() + '/' + this.toDate.getDate().toString() + '/' + this.toDate.getFullYear() + ' ' + this.toDate.getHours().toString() + ':' + this.toDate.getMinutes().toString()+' Z';

    let minuteQuery: number;
    minuteQuery = this.queryHr * 60 * 60 * 1000;
    this.fromDate = new Date(this.toDate.getTime() - minuteQuery);
    let tempFromMonth: number;
    tempFromMonth = this.fromDate.getMonth() + 1;
    this.fromDateText = tempFromMonth.toString() + '/' + this.fromDate.getDate().toString() + '/' + this.fromDate.getFullYear() + ' ' + this.fromDate.getHours().toString() + ':' + this.fromDate.getMinutes().toString()+' Z';


  }
  querySlideChange(e: any) {
    this.queryIndex = e.target.value;
    this.queryHr = this.queryHourList[this.queryIndex];
    if (this.queryHr == 1) {
      this.queryHrText = this.queryHr.toString() + " hr";
    }
    else {
      this.queryHrText = this.queryHr.toString() + " hrs";
    }
    
    this.getFromToDate();
    this.getData();
  }

  includeDemoOnChange() {
    this.getData();
  }

  showMobileOnlyChange() {
    this.getData();
  }


  getData() {
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.totalRecordsText = "";
    let request = new TripRevisionModel();
    request.pastHours = this.queryHr;
    request.includeDemo = false;
    request.showMobileOnly = this.f.showMobileOnly.value == true ? this.f.showMobileOnly.value :false;
    if (this.f.includeDemo.value == true) {
      request.includeDemo = this.f.includeDemo.value;
    }
    // if (this.f.showMobileOnly.value == true) {
    //   request.showMobileOnly = this.f.showMobileOnly.value;
    // }
   
    this._groundStopService.getTripRevisionByHours<ResponseModel<TripRevisionModel[]>>(request).subscribe(response => {
      this.recordList = [];
      this.totalRecordsText = "";
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.orgRecordList = response.model;
          //this.recordList = response.model;
          this.setRecordList();
          //if (this.recordList.length > 1) {
          //  this.totalRecordsText = this.recordList.length.toString() + " Records Found";
          //}
          //else {
          //  this.totalRecordsText = this.recordList.length.toString() + " Record Found";
          //}

        }
        else {
          this.totalRecordsText = "No record found";
        }
        this.showSpin = false;
      
      }
      else {
        if (response.code == "401") {
          
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    });
    
  }

  expandCollapseUsername(e: any, item: TripRevisionModel, flag: string) {
    if (flag == "e") {
      item.expand = true;
    }
    else {
      item.expand = false;
    }
  }

  expandCollapseClient(e: any, item: CustomerModel, flag: string) {
    if (flag == "e") {
      item.expand = true;
    }
    else {
      item.expand = false;
    }
  }

  clickRefresh() {
    this._authService.updateAccessTime();
    this.getFromToDate();
    this.getData();

  }

  clickTripRevisions(e: any, item: TripModel, rv: TripRevisionModel) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: "", aircraftGUID: "", tripCodeGUID: item.tripCodeGUID, pastHours: this.queryHr,
      userName: rv.userName, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  clickOpenTrip(e: any, item: TripModel) {
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "tripId": item.tripCodeGUID,
    //    "aircraftId": item.aircraftGUID,
    //    "customerId": item.customerGUID,
    //    "pf": "",
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
  }

  userTypeChange(e: any, item:string) {
    this.user_type = item;
    this.setRecordList();
  }

  setRecordList() {
    this.recordList = [];
    switch (this.user_type) {
      case "internal":
        this.recordList = this.orgRecordList.filter(x=>x.isExternal==false);
        break;
      case "customer":
        this.recordList = this.orgRecordList.filter(x => x.isExternal == true);
        break;
      default:
        this.recordList = this.orgRecordList;
        break;
    }
    if (this.recordList.length > 1) {
      this.totalRecordsText = this.recordList.length.toString() + " Records Found";
    }
    else {
      this.totalRecordsText = this.recordList.length.toString() + " Record Found";
    }
  }

}
