
<div style=" background: #f8f8f8 !important; " cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>

  <div style="background: #f8f8f8 !important; margin-bottom: 0.25em" align="end">
    <div><mat-icon class="pointer" (click)="clickClose()">close</mat-icon></div>

  </div>
  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>


  <div *ngIf="upPII" style="margin-top: 0em; margin-left: 1em; margin-right: 1em; background-color: white; padding-top: 0em; scroll-behavior: auto">


    <div>
      <form *ngIf="message_link_form" [formGroup]="message_link_form">
        <div style=" background-color: white;margin-left:1em;margin-right:1em; margin-top:-0.3em">

          <div class="d-inline-flex" style=" text-align: center; padding: 0.5em; "><mat-icon>email</mat-icon><h4 style="margin-left: 0.3em;">Link Messages </h4></div>

        </div>
        <div style="margin: 0.2em; height: 0.5em; background-color: #f8f8f8; "></div>

        <div class="divLeft">

          <div style="background-color: white; padding-left: 0.25em; padding-top: 0.25em; padding-right: 0.5em; margin-top: -0.5em;  width: 100%; height: 4.5em ">
            <div style="margin-left:0em;padding:0em; display: flex; ">

              <div style="min-width:85%">
                <div style="display: flex;">
                  <div style="margin-top:0.5em; margin-right:0.25em;">
                    <button type="button" style=" margin-left: 1em;" mat-button class="btn text-#f8f8f8 btn-high" (click)="clickClientFilter()">
                      <div style="display: flex;  "><div>{{selectedClientCountText}}</div></div>
                    </button>&nbsp;
                  </div>
                  <div style="justify-content: flex-start; padding-left: 1em; padding-top: 0.5em; padding-bottom: 0.5em; display: flex; height: 3em; ">
                    <div style="margin-right:1em;" *ngIf="showLinkMsg">
                      <!--<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>-->
                      <button type="button" mat-button class="btn-high" (click)="linkMessageToClient()">
                        <div style="display:flex"><div>Link to Client</div></div>
                      </button>&nbsp;
                    </div>
                    <div style="margin-right:1em;" *ngIf="messageLinkList">
                      <!--<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>-->
                      <button type="button" mat-button   style="background-color: #c1e4f7; color: black;" (click)="removelinkMessage()"> Remove Links </button>&nbsp;
                      <button type="button" mat-button   style="background-color: #c1e4f7; color: black;" (click)="showAllTrips()"> Show All Trips  </button>&nbsp;
                    </div>
                    <!-- {{customerName}} -->

                  </div>
                </div>
                <div style="display:flex; min-width: 20em">
                  <div *ngIf="f.startDate.errors" class="small alert alert-danger p-0" style="font-size: small; width: max-content; margin-top: 0.125em; height: 1.5em;">
                    <div *ngIf="f.startDate.errors.isValidDate==false">Invalid start date.</div>
                  </div>
                  <div *ngIf="f.endDate.errors" class="small alert alert-danger p-0" style="font-size: small; width: max-content; margin-top: 0.125em; height: 1.5em">
                    <div *ngIf="f.endDate.errors.isValidDate==false">Invalid end date.</div>
                  </div>
                  <!--client list-->
                  <div *ngIf="showClientList" style="float: left; min-width: 12em; margin-top: 0.25em; margin-left: 0em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: medium; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                    <div style="display:flex; justify-content:space-between">
                      <div style="font-weight: bold; text-decoration:underline">Client Accounts</div>
                      <div style="float:right" (click)="showClientList=!showClientList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                    </div>
                    <div style="font-size: small; margin-left:0em">
                      <div style="margin-right:0.5em;">
                        <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                          <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                          <input type="text" formControlName="client_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="clientSearchChange($event)" />
                        </div>
                      </div>
                    </div>

                    <div *ngIf="customerList.length>0" style="max-height: 20em;margin-top:0em; margin-bottom:-1.5em;" class="scroll-thin">

                      <mat-radio-group aria-label="Select an option" formControlName="selectedClient">
                        <div *ngFor="let c of customerList | slice:0.5; i: i for index">
                          <div #tripClientRefs [attr.customerGUID]="c.customerGUID" style="display: flex; margin-top:-0.1em;margin-bottom:-0.5em">
                            <mat-radio-button [value]="c" (change)="checkClientChange($event, c);clickDoneClient();">{{c.customerName}}</mat-radio-button>
                          </div>
                        </div>
                      </mat-radio-group>
                    </div>
                    <div style="margin-top:0.5em; height:0.75em"></div>

                  </div>
                  <!--client list end-->
                </div>
              </div>
              <div style="margin-left: 0em;margin-top:0em; margin-right:0.5em" *ngIf="showIncludeTrip">
                <div style="font-size: small; font-weight: bold">Include</div>
                <div class="d-flex flex-column" style="font-size: medium; margin-top:-0.5em; margin-left:-0.75em">
                  <mat-slide-toggle style="transform:scale(.8); height:2em; " [checked]="includeClosedBilledRecords" formControlName="includeClosedBilledRecords" (change)="includeClosedBilledRecordsOnChange($event)">Closeout Trips</mat-slide-toggle>
                </div>
              </div>
              <div>

              </div>
            </div>
          </div>
        </div>
        <div class="divLeft2">
          <div style="display: flex; margin-bottom: -0.75em; justify-content: space-between;  height: 80%;">
            <div style="margin-top:0em;">
            </div>
          </div>
        </div>
      </form>
    </div>

    <div style=" height: 0.3em; background-color: #f8f8f8; "></div>

    <div style="min-height:25em" class="divLeft2">
      <div>
        <app-link-message-trip-detail-viewlist *ngIf="selectedTabName=='tripcode_detail'" [selectedTabName]="selectedTabName" [request]="request" [reloadChildPage]="reloadChildPage" 
        (sendDataToParent)="onSendDataToParent($event)" [v]="v" [messageGUID]="messageGUID" [customerGUID]="selectedCustList[0].customerGUID" [messageGUIDList] ="messageGUIDList"
        [messageLinkList]="messageLinkList" [linkedTripCodedGUID]="linkedTripCodedGUID"></app-link-message-trip-detail-viewlist>

      </div>
    </div>
    <div style="height: 0.5em; background-color: #f8f8f8; "></div>


  </div>

  <div style="padding-bottom:1em;padding-left:1em" layout="row" layout-align="end">
    <div>


      <div style="display: inline-flex; max-width: 25em !important;  margin-top: 0em">


        <button type="button" mat-button class="btn-low" (click)="clickClose()">
          <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>CLOSE</div></div>
        </button>&nbsp;


      </div>
      <app-success-message style="display: inline-flex; max-width: 30em !important;" *ngIf="showSuccessMessage" [successMsg]="successMsg"></app-success-message>

    </div>
    <!--<app-success-message ></app-success-message>-->
  </div>
</div>










