import { FuelTaskModel } from "./fuel-task.model";
import { GroundStopModel } from "./ground-stop.model";

export class FuelQuoteSummary {
  gsInfo: GroundStopModel;
  fuelQuote: FuelTaskModel[];
  taxes: any[];
  hasDomestic: boolean;
  selected: boolean;
  fuelQuoteSummaryID: number;
  tripCodeGUID: string;
  includedAirports: string;
  remarks: string;
  tripDocumentGUID: string;
  modifiedBy: string;
  modifiedDate: string;
  reportID: string;
}
