import { Component } from '@angular/core';

@Component({
  selector: 'app-new-features-dialog',
  templateUrl: './new-features-dialog.component.html',
  styleUrls: ['./new-features-dialog.component.css']
})
/** airport-bi-dashboard component*/
export class NewFeaturesDialogComponent {
  
    constructor() {

    }
}
