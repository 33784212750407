import { Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of } from 'rxjs';
import { FlightPlanPreferenceModel } from '../models/flight-plan-preference';
import { GroundStopAlternateAirportModel } from '../models/ground-stop-alternate-airport.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { FlightPlanPreferenceService } from '../services/flight-plan-preference.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { FlightPlanPreferenceAuditDialogComponent } from './flight-plan-preference-audit-dialog.component';

@Component({
    selector: 'app-flight-plan-preference-edit',
    templateUrl: './flight-plan-preference-edit.component.html',
    styleUrls: ['./flight-plan-preference-edit.component.css']
})
/** flight-plan-preference-edit component*/
export class FlightPlanPreferenceEditComponent implements OnInit {
  groundStopGUID: string;
  @Input() tripCodeGUID: string;
  groundStopTaskGUID: string;
  flightPlanPreferenceID: number;
  flight_plan_preference_edit_form: UntypedFormGroup;
  tripCode: string;
  icao: string;
  icao_iata: string;
  airportName: string;
  airportCountry: string;
  arrivalDateTime: string;
  departureDateTime: string;
  arrivalDateTimeLocal: string;
  departureDateTimeLocal: string;
  nextICAO_IATA: string;
  nextAirportName: string;
  nextICAO: string;
  nextCountry: string;
  registration: string;
  aircraftModel: string;
  aircraftManufacturer: string;
  aircraftInfo: string;
  cruiseSpeed: string="";
  cruiseSpeedClientRequested: boolean=false;
  flightLevel: string;
  flightLevelClientRequested: boolean=false;
  payload: string;
  payloadClientRequested: boolean = false;
  departureFuel: string;
  departureFuelClientRequested: boolean = false;
  fuelOverDestination: string;
  fodClientRequested: boolean = false;
  hold: string;
  holdClientRequested: boolean = false;
  reserve: string;
  reserveClientRequested: boolean = false;
  tank: string;
  tankClientRequested: boolean = false;
  flightRoute: string;
  remarks: string="";
  isActive: boolean = false;
  modifiedBy: string = "";
  modifiedDate: string = "";
  etps: string="";
  etpsClientRequested: boolean=false;
  alternateAirports: string="";
  altClientRequested: boolean=false;
  flightPlanPreference: FlightPlanPreferenceModel;
  errMsg: string = "";
  showSuccessMsg: boolean = false;
  loading: boolean = false;
  cargo: string;
  cargoClientRequested: boolean = false;

    /** flight-plan-preference-edit ctor */
  constructor(private readonly _dialogRef: MatDialogRef<FlightPlanPreferenceEditComponent>, @Inject(MAT_DIALOG_DATA) private _data: any, private ngZone: NgZone,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _flightPlanPreferenceService: FlightPlanPreferenceService) {
    this.tripCodeGUID = _data.tripCodeGUID;
    this.flightPlanPreference = _data.flightPlanPreference;
    
  }
  ngOnInit() {
    this.flightPlanPreferenceID = this.flightPlanPreference.flightPlanPreferenceID;
    this.groundStopTaskGUID = this.flightPlanPreference.groundStopTaskGUID;
      this.getData();
  }

  getData() {
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let alternates = responses[0].model;
          alternates.forEach(x => {
            if (x.alternateAirportTypeID == 1 || x.alternateAirportTypeID == 2) {
              if (this.alternateAirports != "")
                this.alternateAirports += " ";
              this.alternateAirports += x.icao;
            }
            if (x.alternateAirportTypeID == 4) {
              if (this.etps != "")
                this.etps += " ";
              this.etps += x.icao;
            }
          })
          
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          let fpp = new FlightPlanPreferenceModel();
          fpp = responses[1].model;
          this.cruiseSpeed = fpp.cruiseSpeed;
          this.cruiseSpeedClientRequested = fpp.cruiseSpeedClientRequested;
          this.flightLevel = fpp.flightLevel;
          this.flightLevelClientRequested = fpp.flightLevelClientRequested;
          this.payload = fpp.payload;
          this.payloadClientRequested = fpp.payloadClientRequested;
          this.departureFuel = fpp.departureFuel;
          this.departureFuelClientRequested = fpp.departureFuelClientRequested;
          this.fuelOverDestination = fpp.fuelOverDestination;
          this.fodClientRequested = fpp.fodClientRequested;
          this.hold = fpp.hold;
          this.holdClientRequested = fpp.holdClientRequested;
          this.reserve = fpp.reserve;
          this.reserveClientRequested = fpp.reserveClientRequested;
          this.tank = fpp.tank;
          this.tankClientRequested = fpp.tankClientRequested;
          this.flightRoute = fpp.flightRoute;
          this.remarks = fpp.remarks;
          this.isActive = fpp.isActive;
          this.modifiedBy = fpp.modifiedBy;
          this.modifiedDate = fpp.modifiedDate;
          this.altClientRequested = fpp.altClientRequested;
          this.etpsClientRequested = fpp.etpsClientRequested;
          this.cargo = fpp.cargo;
          this.cargoClientRequested = fpp.cargoClientRequested;

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.initControls();
    });
  }

  get f() { return this.flight_plan_preference_edit_form.controls; }

  initControls() {
    this.flight_plan_preference_edit_form = this._formBuilder.group({
      cruise_speed: this.cruiseSpeed,
      cruise_speed_CR: this.cruiseSpeedClientRequested,
      flight_level: this.flightLevel,
      flight_level_CR: this.flightLevelClientRequested,
      payload: this.payload,
      payload_CR: this.payloadClientRequested,
      departure_fuel: this.departureFuel,
      departure_fuel_CR: this.departureFuelClientRequested,
      fod: this.fuelOverDestination,
      fod_CR: this.fodClientRequested,
      hold: this.hold,
      hold_CR: this.holdClientRequested,
      reserve: this.reserve,
      reserve_CR: this.reserveClientRequested,
      tank: this.tank,
      tank_CR: this.tankClientRequested,
      etps_CR: this.etpsClientRequested,
      alt_CR: this.altClientRequested,
      etps: this.etps,
      alternate: this.alternateAirports,
      flight_route: this.flightRoute,
      remarks: this.remarks,
      modified_by: this.modifiedBy,
      modified_date: this.modifiedDate,
      cargo: this.cargo,
      cargo_CR: this.cargoClientRequested
    });
  }

  getAllData(): Observable<any[]> {
    let obj = new GroundStopAlternateAirportModel();
    obj.groundStopGUID = this.flightPlanPreference.groundStopGUID;
    obj.nextGroundStopGUID = this.flightPlanPreference.nextGroundStopGUID;
    let getGroundStopAltAirport = this._groundStopAdvancedService.getGroundStopAlternateAirportForEditBygsIdnextgsId(obj);
    let getFPPRepsonse;
    let request = new GroundStopTaskModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    if (this.flightPlanPreferenceID != null) {
      getFPPRepsonse = this._flightPlanPreferenceService.getFlightPlanPreferenceBygstId<ResponseModel<FlightPlanPreferenceModel>>(request);
    }
    else
      getFPPRepsonse = of(null);
    return forkJoin([getGroundStopAltAirport, getFPPRepsonse]);
  }

  clickSave() {
    this.errMsg = "";
    this.loading = true;
    let request = new FlightPlanPreferenceModel();
    request.flightPlanPreferenceID = this.flightPlanPreferenceID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    request.cruiseSpeed = this.f.cruise_speed.value;
    request.cruiseSpeedClientRequested = this.f.cruise_speed_CR.value;
    if (this.f.flight_level.value != null && this.f.flight_level.value!='')
      request.flightLevel = this.f.flight_level.value;
    request.flightLevelClientRequested = this.f.flight_level_CR.value;
    if (this.f.payload.value != null && this.f.payload.value != '')
      request.payload = this.f.payload.value;
    request.payloadClientRequested = this.f.payload_CR.value;
    if (this.f.departure_fuel.value != null && this.f.departure_fuel.value != '')
      request.departureFuel = this.f.departure_fuel.value;
    request.departureFuelClientRequested = this.f.departure_fuel_CR.value;
    if (this.f.fod.value != null && this.f.fod.value != '')
      request.fuelOverDestination = this.f.fod.value;
    request.fodClientRequested = this.f.fod_CR.value;
    if (this.f.hold.value != null && this.f.hold.value != '')
      request.hold = this.f.hold.value;
    request.holdClientRequested = this.f.hold_CR.value;
    if (this.f.reserve.value != null && this.f.reserve.value != '')
      request.reserve = this.f.reserve.value;
    request.reserveClientRequested = this.f.reserve_CR.value;
    if (this.f.tank.value != null && this.f.tank.value != '')
      request.tank = this.f.tank.value;
    request.tankClientRequested = this.f.tank_CR.value;
    request.etpsClientRequested = this.f.etps_CR.value;
    request.altClientRequested = this.f.alt_CR.value;
    request.flightRoute = this.f.flight_route.value;
    request.remarks = this.f.remarks.value;
    request.isActive = true;
    if (this.f.cargo.value != null && this.f.cargo.value != '')
      request.cargo = this.f.cargo.value;
    request.cargoClientRequested = this.f.cargo_CR.value;
    this._flightPlanPreferenceService.saveFlightPlanPreference<ResponseModel<number>>(request).subscribe(response => {
      this.loading = false;
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.showSuccessMsg = true;
          setTimeout(() => {            
            this.showSuccessMsg = false;
            this.close(true);
          }, 1000);
         
        }
        else {
          this.errMsg = response.message;
        }
      }
    });
  }

  clickAudit() {
    const config = new MatDialogConfig();
    //config.panelClass = "custom-dialog-container"
    config.restoreFocus = false;
    config.data = { flightPlanPreferenceID: this.flightPlanPreferenceID };
    this._dialog.open(FlightPlanPreferenceAuditDialogComponent, config);
  }

  close(isModified: boolean) {
    this.ngZone.run(() => {
      this._dialogRef.close(isModified);
    });
    this._dialogRef.close(isModified);
   
  }
}
