import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AirportModel } from '../models/airport.model';
import * as atlas from 'azure-maps-control';
import { AirportService } from '../services/airport.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthenticateService } from '../services/authenticate.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ResponseModel } from '../models/response.model';
import { AirportMapComponent } from '../airports/airport-map.component';
import { GroundStopService } from '../services/ground-stop.service';
import { FARTypeModel } from '../models/far-type.model';
import { Observable, forkJoin, of } from 'rxjs';
import { CustomerModel } from '../models/customer.model';
import { TripModel } from '../models/trip.model';
import { FlightRouteModel } from '../models/flight-route.model';
import { FlightPlanModalPopupComponent } from '../ground-stop-advanced/flight-plan-modal-popup.component';
import { VendorSelectionComponent } from './vendor-selection.component';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { AirportBriefComponent } from './airport-brief.component';
import { CountryModel } from '../models/country.model';

@Component({
  selector: 'app-trip-planner',
  templateUrl: './trip-planner.component.html',
  styleUrls: ['./trip-planner.component.css']
})
export class TripPlannerComponent implements OnInit {
  displayedColumnOps: string[] = ['icao', 'iata', 'airport', 'maxRunway','utc', 'handler-ssn', 'airport_of_entry', 'restrictions', 'slots', 'ppr', 'landing_permit', 'advisories', 'lookup_routes', 'ops_report', 'action'];
  displayedColumnClient: string[] = ['icao', 'iata', 'airport','handler-ssn', 'airport_of_entry', 'restrictions', 'slots', 'ppr', 'landing_permit', 'advisories', 'action'];
  selectedColumns: string[];
  isOpenedNew: boolean = false;
  userType: string;
  airportList: string="";
  farTypeID: number=1;
  farTypeList: FARTypeModel[];
  customerList: CustomerModel[];
  customerGUID: string="";
  airports: AirportModel[];
  map: atlas.Map;
  points: any[];
  showSpin: boolean = false;
  tripCodeList: TripModel[] = [];
  tripCodeGUID: string = "";
  daysOut: number = 0;
  showRoute: boolean = false;
  countryList: CountryModel[];
  countryID: number = 0;
  buttonDisabled: boolean = false;
  fromTrip: boolean = false;
  @ViewChild(MatTable) table: MatTable<any>;
  constructor(private readonly _airportService: AirportService, private readonly _commonService: CommonService, private readonly _groundStopService:GroundStopService,
    @Optional() @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _route: ActivatedRoute, private readonly _dialogService: DialogService,
    private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _titleService: Title, @Optional() private readonly _dialogRef: MatDialogRef<TripPlannerComponent>) {
    if (_data) {
      if (_data.tID != null && _data.tID != undefined && _data.tID != "undefined") {
        this.tripCodeGUID = _data.tID;
        this.customerGUID = _data.cID;
        this.fromTrip = true;
      }
    
      this.getAirportData();
    }
  }
  ngOnInit() {
    this._authService.updateAccessTime();
    this.userType = localStorage.getItem('ut').toLowerCase();
    if (this.userType == "i")
      this.selectedColumns = this.displayedColumnOps;
    else
      this.selectedColumns = this.displayedColumnClient;
    this._route.url.subscribe(o => {
      if (o[0].path == "")
        this.isOpenedNew == false;
      else {
        this.isOpenedNew = true;
        this._route.queryParams.subscribe(params => {
          if (params["airportList"] != null && params["airportList"] != undefined && params["airportList"] != "undefined") {
            this.airportList = params["airportList"];
          }
          else
            this.airportList = "";
          if (params["showRoute"] != null && params["showRoute"] != undefined && params["showRoute"] != "undefined") {
            if (params["showRoute"] == "true")
              this.showRoute = true;
            else
              this.showRoute = false;
          }
          if (params["farTypeID"] != null && params["farTypeID"] != undefined && params["farTypeID"] != "undefined") {
            this.farTypeID = params["farTypeID"];
          }
          else
            this.farTypeID = 1;
          if (params["cID"] != null && params["cID"] != undefined && params["cID"] != "undefined") {
            this.customerGUID = params["cID"];
            this.getTripList();
          }
          else
            this.customerGUID = "";
          if (params["tID"] != null && params["tID"] != undefined && params["tID"] != "undefined") {
            this.tripCodeGUID = params["tID"];
          }
          else
            this.tripCodeGUID = "";
          if (params["daysOut"] != null && params["daysOut"] != undefined && params["daysOut"] != "undefined") {
            this.daysOut = params["daysOut"];
          }
          else
            this.daysOut = 0;
          if (params["cnID"] != null && params["cnID"] != undefined && params["cnID"] != "undefined") {
            this.countryID = params["cnID"];
          }
          else
            this.countryID = 0;
         
          this.getAirportData();
        });

      }
    });

    this.getData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.farTypeList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.countryList = responses[2].model;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }     
    
    });

  }

  getData(): Observable<any[]> {
    let getClientListResponse;
    if (this.userType == "i") 
      getClientListResponse = this._commonService.getCustomers();
    else
      getClientListResponse = of(null);
  
    let getFARTypeResponse = this._groundStopService.getFARTypeList();
    let getCountryList = this._commonService.getCountryList();

    return forkJoin([getClientListResponse, getFARTypeResponse, getCountryList]);
  }

  getTripList() {
    this.daysOut = 0;
    this.airports = null;
    this.tripCodeGUID = "";
    this._groundStopService.getTripCodeListBycId<ResponseModel<TripModel[]>>(this.customerGUID, true).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.tripCodeList = response.model;
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
    this.getAirportData();
  }

  search(source: string) {
    switch (source) {
      case "airport":
        this.tripCodeGUID = "";
        this.daysOut = 0;
        this.countryID = 0;
        break;
      case "trip":
        this.airportList = "";
        this.daysOut = 0;
        this.countryID = 0;
        break;
      case "daysout":
        this.tripCodeGUID = "";
        this.airportList = "";
        this.countryID = 0;
        this.showRoute = false;
        break;
      case "country":
        this.tripCodeGUID = "";
        this.airportList = "";
        this.daysOut = 0;
        this.showRoute = false;
        break;
    }
    this.getAirportData();
  }

  getAirportData() {
    if ((this.airportList != "" || this.tripCodeGUID != "" || this.daysOut > 0 || this.countryID>0) && this.farTypeID != 0) {
      if (this.tripCodeGUID != "")
        this.airportList = ""
      else
        this.tripCodeGUID = "";
      if (this.airportList != "") {
        let airports = this.airportList.replace(/;/g, ",").replace(/-/g, ",").replace(/ /g, ",").replace(/,,/g, ",").split(",");
        if (airports.some(x => x.length > 4))
          this.buttonDisabled = true;
        else
          this.buttonDisabled = false;        
      }
      if (this.daysOut > 0 || this.countryID > 0)
        this.buttonDisabled = true;
      else
        this.buttonDisabled = false;        
      this.showSpin = true;
      this._airportService.getAirportsListForTripPlanner<ResponseModel<AirportModel[]>>(this.airportList, this.customerGUID, this.farTypeID, this.tripCodeGUID, this.daysOut, this.countryID).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.airports = response.model;
            if (this.airports.length == 0 && this.airportList!="") {
              let a = new AirportModel();
              a.airportID = 0;
              a.airportName = this.airportList;
              a.airportLocationWithCountryNameAbbr = "Not Found";
              this.airports.push(a);
            }
            if (this.tripCodeGUID != "") {
              if (this.farTypeID != this.airports[0].farTypeID)
                this.farTypeID = this.airports[0].farTypeID;
              this.showRoute = true;
              if (this.fromTrip) {
                this.airportList = Array.prototype.map.call(this.airports, s => s.icao.toUpperCase()).join(" ");
              }
            }
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          }
          this.createMap();
          this.showSpin = false;
        }
      });
    }
    else {
      this.airports = null;
      if (this.map)
        this.map.dispose();
    }
  }

  createMap() {
    this.map = new atlas.Map('trip-planner-map', {
      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map SDK.
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      var zoomControl = new atlas.control.ZoomControl();

      /* Add the zoom control to the map*/
      self.map.controls.add(zoomControl, {
        position: atlas.ControlPosition.TopRight
      });

      if (self.airports.length > 0) {
        self.airports.forEach((v,i) => {
          if (v.longitude != null && v.latitude != null) {
            var startPosition = [v.longitude, v.latitude];
            var endPosition = [v.nextLongitude, v.nextLatitude];
            var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
              title: v.icao,
              name: v.airportName,
              airport: v
            });

            var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
              title: v.nextICAO,
              name: v.nextAirportName,
              airport: v.nextAirportID!=0? self.airports[i+1]:null
            });

            self.points.push(startPosition);
             self.points.push(endPosition);
            var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
            var poly = new atlas.data.LineString(path);
            if (v.nextAirportID!=null && (self.showRoute || self.userType=='c'))
              dataSource.add([poly, startPoint, endPoint]);
            else
              dataSource.add([startPoint]);
          }
        });
        var popupTemplate = "<div class='popup'>{name}</div>";
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            image: 'pin-round-darkblue',
            cluster: false,
            optional: true,
            allowOverlap: true,
            size: .65
          },
          textOptions: {
            textField: ['get', 'title'],
            offset: [0, -1.75],
            cluster: false,
            allowOverlap: true,
            size: 12
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        });
        self.map.layers.add([new atlas.layer.LineLayer(dataSource, null, {
          strokeColor: 'black',
          strokeWidth: 1.5
        }),
          symbolLayer
        ]);
        var popup = new atlas.Popup({
          pixelOffset: [0, -18],
          closeButton: false
        });
        //Add a hover event to the symbol layer.
        self.map.events.add('mouseover', symbolLayer, function (e) {
          self.map.getCanvasContainer().style.cursor = 'pointer';
          //Make sure that the point exists.
          if (e.shapes && e.shapes.length > 0) {
            var pos = e.position;
            var offset = [0, 0];
            var properties;
            var content, coordinate;

            if (e.shapes[0] instanceof atlas.Shape) {
              properties = e.shapes[0].getProperties();

              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].getType() === 'Point') {
                coordinate = e.shapes[0].getCoordinates();
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            } else {
              properties = e.shapes[0].properties;
              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].type === 'Point') {
                coordinate = e.shapes[0].geometry.coordinates;
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            }
            popup.setOptions({
              //Update the content of the popup.
              content: content,

              //Update the popup's position with the symbol's coordinate.
              position: coordinate

            });
            //Open the popup.
            popup.open(self.map);
          }
        });

        self.map.events.add('mouseleave', symbolLayer, function () {
          self.map.getCanvasContainer().style.cursor = 'grab';
          popup.close();
        });

        self.map.events.add('click', symbolLayer, function (e) {
          var properties;
          var airport;
          if (e.shapes[0] instanceof atlas.Shape) {
            properties = e.shapes[0].getProperties();

            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].getType() === 'Point') {
              airport = properties.airport;
            }
          } else {
            properties = e.shapes[0].properties;
            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].type === 'Point') {
              airport = properties.airport;
            }
          }
         
          self.openAirportBrief(airport);
        })

        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        if (self.airports.length == 1) {
          self.map.setCamera({
            center: [self.airports[0].longitude, self.airports[0].latitude],
            zoom: 12
          });
        }
        else {
          self.map.setCamera({
            bounds: bbox,
            padding: 40
          });
        }
      }


    });
  }

  openInNew() {
    this._dialogRef.close();
    let route = "/trip-planner/trip-planner?airportList=" + this.airportList + "&farTypeID=" + this.farTypeID + "&cID=" + this.customerGUID + "&showRoute=" + this.showRoute + "&tID=" + this.tripCodeGUID + "&daysOut=" + this.daysOut + "&cnID=" + this.countryID;
    window.open(route, '_blank');
  }


  clickReset() {
    this.airportList = "";
    this.airports = [];
    if (this.map)
      this.map.dispose();
  }


  openMapComponent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80em";
    dialogConfig.height = "40em";



    dialogConfig.data = {
      airports: this.airports,
      from: "manage",
      showRoute:this.showRoute
    };

    this._dialog.open(AirportMapComponent, dialogConfig);

  }

  openRoutes(airport: AirportModel) {
    if (airport.routeCount == 0)
      this.openAirportBrief(airport)
    else {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.restoreFocus = false;
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "60em";

      let request: string[] = [airport.icao, airport.nextICAO];
      this.showSpin = true;
    this._groundStopService.getAllRoutesforCityPair<ResponseModel<FlightRouteModel[]>>(request).toPromise().then(response => {
      if (response != null && response.code == "200") {
        let routeData = response.model;

        dialogConfig.data = {
          data: routeData, departureICAO: airport.icao, arrivalICAO: airport.nextICAO, view: 'lookup_routes', v: this._authService.getCurrentTimeNumber()
        };

        this.showSpin = false;
        this._dialog.open(FlightPlanModalPopupComponent, dialogConfig);
      }
    });
    }
  }


  openVendorSelection(airport: AirportModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let title = airport.icao;
    if (airport.iata != null && airport.iata != "")
      title += " - " + airport.iata;
    dialogConfig.data = {
      title: title,
      airportID: airport.airportID,
      icao: airport.icao,
      handler: airport.handler?.vendorGUID,
      ssn:airport.ssn?.vendorGUID
    };

    this._dialog.open(VendorSelectionComponent, dialogConfig).afterClosed().subscribe(response => {
      if (response != null && response!="") {
        airport.handler.vendorName = response.vendorName;
        airport.handler.vendorID = response.vendorID;
        airport.handler.isPreferred = response.isPreferred;
        airport.handler.vendorGUID = response.vendorGUID;
        if (response.relatedVendors == null) {
          airport.ssn = null;
        }
        else {
          airport.ssn.vendorName = response.relatedVendors[0].vendorName;
          airport.ssn.vendorID = response.relatedVendors[0].vendorID;
          airport.ssn.isPreferred = response.relatedVendors[0].isPreferred;
          airport.ssn.vendorGUID = response.relatedVendors[0].vendorGUID;
        }
        this.table.renderRows();
      }
    });

  }

  openAirportBrief(airport: AirportModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";
    let cName = "";
    if (this.customerGUID != "" && this.customerGUID != null && this.customerGUID != undefined)
      cName = this.customerList.filter(x => x.customerGUID.toLowerCase() == this.customerGUID.toLowerCase())[0].customerName;
    dialogConfig.data = {
      selectedAirport:airport,
      airportID: airport.airportID,
      icao: airport.icao,
      customerGUID: this.customerGUID,
      customerName: cName,
      farTypeID:airport.farTypeID,
      farType: this.farTypeList.find(v => v.farTypeID == airport.farTypeID).farTypeDescription,
      route: this.daysOut==0 && this.countryID==0 && this.buttonDisabled==false? Array.prototype.map.call(this.airports, s => s.icao.toUpperCase()).join(" "): '',
      handlerGUID: airport.handler?.vendorGUID,
      ssnGUID: airport.ssn?.vendorGUID,
      callFrom:'planner'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);
  }
  

}
