<div style="padding-left: 1em;">
  <div style="padding-top:0.5em; margin-top:0em">
    <h5 style="display: flex;"><div style="margin-top: -0.2em; margin-right: 0.5em;">
      <!-- <i class="material-icons-two-tone icon_orange" style="font-size: large">warning_amber</i> -->
      <i  class="material-icons"   style="color: red; font-size: xx-large; cursor: pointer;padding-left: 5px;  "  >warning</i>
     </div>
      <div>  Total Records -  &nbsp;{{ totalCount }}  , Active Records -    {{activeCount}}, Expired Records -    {{expiredCount}} .  </div></h5>
      <!-- <h5 style="margin-left: 2.1em;"> Expired Records -    {{expiredCount}},</h5>
      <h5 style="margin-left: 2.1em;">   Active Records -    {{activeCount}} .</h5> -->
      <h5 style="margin-left: 2.1em;"> Do you want to Proceed?</h5>
  </div>
  <div style="display: flex">
    <div style="margin-top:1em">
      
    </div>
  <div style="padding-left:1em; padding-top:1em;white-space:pre-line">
    {{message}} 
  </div>
  </div>

  <div style="display: flex; align-items: center; justify-content: center; padding-top: 2em;">
    <button mat-button class="btn-high" (click)="close('filterExpired')">Import Active Records Only</button>&nbsp;&nbsp;
    <button mat-button   style="background-color:  #39a6e1; color: white;" (click)="close('yes')">  Import All Records</button>&nbsp;&nbsp;
    <button mat-button class="btn-low" (click)="close('no')">No, Stop the import</button>&nbsp;&nbsp;
  </div>
</div>

