import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyChip as MatChip, MatLegacyChipList as MatChipList } from '@angular/material/legacy-chips';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CustomValidators } from '../common-utility/custom.validators';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { HoursObjModel } from '../models/hoursObj.model';
import { AuthenticateService } from '../services/authenticate.service';

@Component({
    selector: 'app-hours-edit-dialog',
    templateUrl: './hours-edit-dialog.component.html',
    styleUrls: ['./hours-edit-dialog.component.css']
})

/** hours-edit-dialog component*/
export class HoursEditDialogComponent implements OnInit,AfterViewInit {
  hours_edit_form: UntypedFormGroup;
  @Input() hours: HoursObjModel[];
  daysData: HoursObjModel[];
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  @ViewChild(MatChipList)
  chipList!: MatChipList;

  constructor(private readonly _dialogRef: MatDialogRef<HoursEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _dialog: MatDialog
  ) {
    this.hours = data.hours;
    
  }
    ngAfterViewInit() {
      this.chipList.chips.forEach(v => {
        if (v.value['daySelected']) {
          v.select();
        }
      });
      let selectedHours = this.hours.find(v => v.daySelected);
      if (selectedHours.openTime != "" && selectedHours.openTime != null)
        this.f.open_time.setValue(selectedHours.openTime);
      if (selectedHours.closeTime != "" && selectedHours.closeTime != null)
        this.f.close_time.setValue(selectedHours.closeTime);
      this.f.is24Hours.setValue(selectedHours.is24Hours);
      if (selectedHours.isClosed==1)
        this.f.isClosed.setValue(true);
    }

    ngOnInit() {
      this.initControls();
      
  }

  initControls() {
    this.hours_edit_form = this._formBuilder.group({
      is24Hours: false,
      isClosed: false,
      open_time: "",
      close_time: ""
    });
  }

  get f() { return this.hours_edit_form.controls; }

  toggleSelection(chip: MatChip) {
    chip.toggleSelected();
    chip.value['daySelected'] = chip.selected;
  }

  clickSave() {
    this.hours.forEach(v => {
      if (v.daySelected) {
        v.is24Hours = this.f.is24Hours.value;
        v.isClosed = this.f.isClosed.value;
        v.openTime = this.f.open_time.value;
        v.closeTime = this.f.close_time.value;
      }
    });
    this._dialogRef.close(this.hours);
  }

  clickClose() {
    this._dialogRef.close();
  }

  toggleCheckBox(name: string,e:any) {
    if (name == "24" && e.checked) {
      this.f.isClosed.setValue(false);
    }
    if (name == "closed" && e.checked) {
      this.f.is24Hours.setValue(false);
    }
  }

  timeOnchange(controlName: string, e: any) {

    if (e.target.value != "") {
      let timeObj = new DateTimeObjModel();
      timeObj.timeString = e.target.value;
      timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
      this.hours_edit_form.get(controlName).setValue(timeObj.timeString);

      if (!timeObj.isValidDateTime) {
        this.hours_edit_form.get(controlName).setErrors({ 'valid': false });
      }

    }
    else {
      this.hours_edit_form.get(controlName).setValue(null);
      this.hours_edit_form.get(controlName).setErrors(null);
    }
  }

}
