import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, PatternValidator } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of } from 'rxjs';
import { CustomValidators } from '../common-utility/custom.validators';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { HoursTypeModel } from '../models/hours-type.model';
import { HoursObjModel } from '../models/hoursObj.model';
import { ResponseModel } from '../models/response.model';
import { VendorHoursModel } from '../models/vendor-hours.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { VendorService } from '../services/vendor.service';
import { HoursEditDialogComponent } from './hours-edit-dialog.component';

@Component({
    selector: 'app-vendor-hours-edit-dialog',
    templateUrl: './vendor-hours-edit-dialog.component.html',
    styleUrls: ['./vendor-hours-edit-dialog.component.css']
})
/** vendor-hours-edit-dialog component*/
export class VendorHoursEditDialogComponent implements OnInit {
  vendor_hours_edit_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() vendorGuid: string;
  @Input() vendorHoursID: number;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  vendor_note: string;
  selectedHoursTypeId: number=2;
  hoursTypeList: HoursTypeModel;
  modifiedBy: string;
  modifiedDate: string;
  isModified: boolean;
  isActive: number;
  showSuccessMsg: boolean = false;
  sun_open: string;
  sun_close: string;
  mon_open: string;
  mon_close: string;
  tue_open: string;
  tue_close: string;
  wed_open: string;
  wed_close: string;
  thu_open: string;
  thu_close: string;
  fri_open: string;
  fri_close: string;
  sat_open: string;
  sat_close: string;
  remarks: string;
  effectiveDate: Date;
  expirationDate: Date;
  isUTC: boolean = false;
  is24Hours: boolean = false;
  isOvertime: boolean = false;
  isSeasonal: boolean = false;
  hoursData: HoursObjModel[];
  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;
  displayedColumn: string[] = ['day_of_week', 'time','action'];

  constructor(private readonly _dialogRef: MatDialogRef<VendorHoursEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _vendorService: VendorService,
    private readonly _dialogService: DialogService,
    private readonly _dialog: MatDialog
  ) {
    this.vendorGuid = data.vendorGUID;
    this.vendorHoursID = data.vendorHoursID;
    this.dialogTitle = data.dialogTitle;

  }

  getAllData(): Observable<any[]> {
    let getHoursTypeResponse = this._commonService.getHoursTypeList<ResponseModel<HoursTypeModel>>();
    let getVendorHoursResponse
    if (this.vendorHoursID != null && this.vendorHoursID != 0) {
      getVendorHoursResponse = this._vendorService.getVendorHoursByVendorHoursId<ResponseModel<VendorHoursModel>>(this.vendorHoursID);
    }
    else
      getVendorHoursResponse = of(null);
    return forkJoin([getHoursTypeResponse, getVendorHoursResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.hoursData = [
          { dayOfWeek: "Sunday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sun_Open", closeTimeObjName: "sun_Close", closedObjName: "sunClosed", hasHours: false },
          { dayOfWeek: "Monday", abbreviation: "M", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "mon_Open", closeTimeObjName: "mon_Close", closedObjName: "monClosed", hasHours: false },
          { dayOfWeek: "Tuesday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "tue_Open", closeTimeObjName: "tue_Close", closedObjName: "tueClosed", hasHours: false },
          { dayOfWeek: "Wednesday", abbreviation: "W", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "wed_Open", closeTimeObjName: "wed_Close", closedObjName: "wedClosed", hasHours: false },
          { dayOfWeek: "Thursday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "thu_Open", closeTimeObjName: "thu_Close", closedObjName: "thuClosed", hasHours: false },
          { dayOfWeek: "Friday", abbreviation: "F", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "fri_Open", closeTimeObjName: "fri_Close", closedObjName: "friClosed", hasHours: false },
          { dayOfWeek: "Saturday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sat_Open", closeTimeObjName: "sat_Close", closedObjName: "satClosed", hasHours: false }
    
    ]
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.hoursTypeList= responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.vendorHoursID != null && this.vendorHoursID != 0) {
        if (responses[1] != null) {
          if (responses[1].code == "200" && responses[1].message == "") {
            let vendorHours = new VendorHoursModel();
            vendorHours = responses[1].model;
            this.selectedHoursTypeId = vendorHours.hoursTypeID;
            this.sun_open = vendorHours.sun_Open;
            this.sun_close = vendorHours.sun_Close;
            this.mon_open = vendorHours.mon_Open;
            this.mon_close = vendorHours.mon_Close;
            this.tue_open = vendorHours.tue_Open;
            this.tue_close = vendorHours.tue_Close;
            this.wed_open = vendorHours.wed_Open;
            this.wed_close = vendorHours.wed_Close;
            this.thu_open = vendorHours.thu_Open;
            this.thu_close = vendorHours.thu_Close;
            this.fri_open = vendorHours.fri_Open;
            this.fri_close = vendorHours.fri_Close;
            this.sat_open = vendorHours.sat_Open;
            this.sat_close = vendorHours.sat_Close;
            this.remarks = vendorHours.remarks;
            this.isUTC = vendorHours.isUTC;
            this.is24Hours = vendorHours.is24Hours;
            this.isOvertime = vendorHours.isOvertimeAvailable;
            this.isSeasonal = vendorHours.isSeasonal;
            this.hoursData.forEach(v => {
              switch (v.dayOfWeek) {
                case "Sunday":
                  if (this.is24Hours || vendorHours.sunClosed == 2)
                    v.is24Hours = true;
                  else
                    v.is24Hours = false;
                  v.openTime = this.sun_open;
                  v.closeTime = this.sun_close;
                  v.isClosed = vendorHours.sunClosed;
                  break;
                case "Monday":
                  if (this.is24Hours || vendorHours.monClosed == 2)
                    v.is24Hours = true;
                  else
                    v.is24Hours = false;
                  v.openTime = this.mon_open;
                  v.closeTime = this.mon_close;
                  v.isClosed = vendorHours.monClosed;
                  break;
                case "Tuesday":
                  if (this.is24Hours || vendorHours.tueClosed == 2)
                    v.is24Hours = true;
                  else
                    v.is24Hours = false;
                  v.openTime = this.tue_open;
                  v.closeTime = this.tue_close;
                  v.isClosed = vendorHours.tueClosed;
                  break;
                case "Wednesday":
                  if (this.is24Hours || vendorHours.wedClosed == 2)
                    v.is24Hours = true;
                  else
                    v.is24Hours = false;
                  v.openTime = this.wed_open;
                  v.closeTime = this.wed_close;
                  v.isClosed = vendorHours.wedClosed;
                  break;
                case "Thursday":
                  if (this.is24Hours || vendorHours.thuClosed == 2)
                    v.is24Hours = true;
                  else
                    v.is24Hours = false;
                  v.openTime = this.thu_open;
                  v.closeTime = this.thu_close;
                  v.isClosed = vendorHours.thuClosed;
                  break;
                case "Friday":
                  if (this.is24Hours || vendorHours.friClosed == 2)
                    v.is24Hours = true;
                  else
                    v.is24Hours = false;
                  v.openTime = this.fri_open;
                  v.closeTime = this.fri_close;
                  v.isClosed = vendorHours.friClosed;
                  break;
                case "Saturday":
                  if (this.is24Hours || vendorHours.satClosed == 2)
                    v.is24Hours = true;
                  else
                    v.is24Hours = false;
                  v.openTime = this.sat_open;
                  v.closeTime = this.sat_close;
                  v.isClosed = vendorHours.satClosed;
                  break;
              }
            });
            if (vendorHours.effectiveDate != null && vendorHours.effectiveDate != "")
              this.effectiveDate = new Date(vendorHours.effectiveDate);
            if (vendorHours.endDate != null && vendorHours.endDate != "")
              this.expirationDate = new Date(vendorHours.endDate);
            this.modifiedBy = vendorHours.modifiedBy;
            this.modifiedDate = vendorHours.modifiedDate;
            if (vendorHours.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;

          }
        }

      }

      this.initControls();

      this.showSpin = false;


    });

  }

  initControls() {
    this.vendor_hours_edit_form = this._formBuilder.group({
      hours_type: this.selectedHoursTypeId,
      effectiveDate: this.effectiveDate,
      expirationDate: this.expirationDate,
      sun_open: this.sun_open,
      sun_close: this.sun_close,
      mon_open: this.mon_open,
      mon_close: this.mon_close,
      tue_open: this.tue_open,
      tue_close: this.tue_close,
      wed_open: this.wed_open,
      wed_close: this.wed_close,
      thu_open: this.thu_open,
      thu_close: this.thu_close,
      fri_open: this.fri_open,
      fri_close: this.fri_close,
      sat_open: this.sat_open,
      sat_close: this.sat_close,
      remarks: this.remarks,
      hours_status: this.isActive,
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate,
      isUTC: this.isUTC,
      is24Hours: this.is24Hours,
      isOvertime: this.isOvertime,
      isSeasonal:this.isSeasonal
    });
  }

  get f() { return this.vendor_hours_edit_form.controls; }

  clickEdit(hours: HoursObjModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    this.hoursData.forEach(v => v.daySelected = false);
    this.hoursData.find(v=>v.dayOfWeek== hours.dayOfWeek).daySelected = true;
    dialogConfig.data = { hours: this.hoursData };
    const dialogRef = this._dialog.open(HoursEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.hoursData.forEach(v => {
        if (v.is24Hours == false) {
          this.is24Hours = false;
          this.f.is24Hours.setValue(false);
        }
      });

      

    });
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";
    
    if (this.vendor_hours_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true; 
    let obj = new VendorHoursModel();
    obj.vendorHoursID = 0;
    if (this.vendorHoursID != 0 && this.vendorHoursID != null)
      obj.vendorHoursID = this.vendorHoursID;
    obj.vendorGUID = this.vendorGuid;
    obj.hoursTypeID = this.f.hours_type.value;
    obj.effectiveDate = null;
    if (this.f.effectiveDate.value != null) {
      obj.effectiveDate = this.effectiveDateRef.nativeElement.value;
    }
    obj.endDate = null;
    if (this.f.expirationDate.value != null) {
      obj.endDate = this.expirationDateRef.nativeElement.value;
    }
    obj.is24Hours = this.f.is24Hours.value;
    obj.isOvertimeAvailable = this.f.isOvertime.value;
    obj.isSeasonal = this.f.isSeasonal.value;
    if (this.f.isUTC.value == "1")
      obj.isUTC = true;
    else
      obj.isUTC = false;
    this.hoursData.forEach(v => {
      if (v.openTime != null && v.openTime != "")
        obj[v.openTimeObjName] = v.openTime;      
      if (v.closeTime != null && v.closeTime != "")
        obj[v.closeTimeObjName] = v.closeTime;      
      if (v.is24Hours) {
        obj[v.closedObjName] = 2;
        obj[v.openTimeObjName] = null;
        obj[v.closeTimeObjName] = null;
      }
      else {
        if (v.isClosed) {
          obj[v.closedObjName] = 1;
          obj[v.openTimeObjName] = null;
          obj[v.closeTimeObjName] = null;
        }
        else
          obj[v.closedObjName] = 0;
      }
    });
    //obj.sun_Open = this.hoursData.find(v => v.openTimeControlName == "sun_open").openTime;
    //obj.sun_Close = this.f.sun_close.value;
    //obj.mon_Open = this.f.mon_open.value;
    //obj.mon_Close = this.f.mon_close.value;
    //obj.tue_Open = this.f.tue_open.value;
    //obj.tue_Close = this.f.tue_close.value;
    //obj.wed_Open = this.f.wed_open.value;
    //obj.wed_Close = this.f.wed_close.value;
    //obj.thu_Open = this.f.thu_open.value;
    //obj.thu_Close = this.f.thu_close.value;
    //obj.fri_Open = this.f.fri_open.value;
    //obj.fri_Close = this.f.fri_close.value;
    //obj.sat_Open = this.f.sat_open.value;
    //obj.sat_Close = this.f.sat_close.value;
    obj.remarks = this.f.remarks.value;
    if (this.f.hours_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;

    this._vendorService.saveVendorHours<ResponseModel<number>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.vendorHoursID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);
          this.isModified = true;
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }



  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  timeOnchange(controlName: string, e: any) {

    if (e.target.value != "") {
      let timeObj = new DateTimeObjModel();
      timeObj.timeString = e.target.value;
      timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
      this.vendor_hours_edit_form.get(controlName).setValue(timeObj.timeString);

      if (!timeObj.isValidDateTime) {
        this.vendor_hours_edit_form.get(controlName).setErrors({ 'valid': false });
      }

    }
    else {
      this.vendor_hours_edit_form.get(controlName).setValue(null);
      this.vendor_hours_edit_form.get(controlName).setErrors(null);
    }
  }

  toggle24(e:any) {
    this.hoursData.forEach(v => {
      v.is24Hours = e.checked;
      if (e.checked)
        v.isClosed = 0;
    });
   
  }

  checkApplyEveryday(e: any) {
    if (e.checked) {
      if (this.f.mon_open.value != null && this.f.mon_open.value != "") {
        this.f.tue_open.setValue(this.f.mon_open.value);
        this.f.wed_open.setValue(this.f.mon_open.value);
        this.f.thu_open.setValue(this.f.mon_open.value);
        this.f.fri_open.setValue(this.f.mon_open.value);
        this.f.sat_open.setValue(this.f.mon_open.value);
        this.f.sun_open.setValue(this.f.mon_open.value);
      }
      if (this.f.mon_close.value != null && this.f.mon_close.value != "") {
        this.f.tue_close.setValue(this.f.mon_close.value);
        this.f.wed_close.setValue(this.f.mon_close.value);
        this.f.thu_close.setValue(this.f.mon_close.value);
        this.f.fri_close.setValue(this.f.mon_close.value);
        this.f.sat_close.setValue(this.f.mon_close.value);
        this.f.sun_close.setValue(this.f.mon_close.value);
      }
    }

  }

}
