import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticateService } from '../services/authenticate.service';
import { Subject } from "rxjs";
import { SignInModel } from "../models/sign-in.model";
/**
 * @title Autosize sidenav
 */
@Component({
  selector: 'sidenav-menu-layout',
  templateUrl: 'main.component.html',
  styleUrls: ['main.component.css'],
})
export class MainComponent {
  @ViewChild('sidenav')
  sidenav: MatSidenav;

  isShowing: boolean;
  auth: Subject<SignInModel>;
  login: boolean;

  constructor(private readonly _authService: AuthenticateService) {
    this.auth = _authService.getSignIn();
    this.login = _authService.isLogin();

    this.auth.subscribe(v => {
      this.login = v.login;
      if (this.login) {
        this.sidenav.toggle();
        this.isShowing = true;
      }
      else {
        if (this.isShowing) {
          this.sidenav.toggle();
          this.isShowing = false;
        }
      }
    });
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.login) {
        this.sidenav.toggle();
        this.isShowing = true;
      }
      else {
        if (this.isShowing) {
          this.sidenav.toggle();
          this.isShowing = false;
        }
      }
    },100);
  }

  resize() {
    // commented out the codes below just because side menu width is fixed and do not need resize it
    //window.dispatchEvent(new Event('resize'));
  }

  public onToggleSidenav = () => {

  }
}
