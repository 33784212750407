import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ResponseModel } from '../models/response.model';
import { MessageService } from '../services/message.service';
import { AuthenticateService } from '../services/authenticate.service';
import { MessageFolderModel } from '../models/message-folder.model';
import { MessageFolderEditDialogComponent } from './message-folder-edit-dialog.component';
 
@Component({
  selector: 'app-update-message-folder',
  templateUrl: './update-message-folder.component.html',
  styleUrls: ['./update-message-folder.component.css'],
})

export class UpdateMessageFolderComponent implements OnInit {

  loading = false;
  isModified: boolean = false;
  messageFolderList: MessageFolderModel[];
  showNewFolderInput: boolean;
  newFolderName: string;
  folderExist: boolean;

  showEditIcon: boolean = false;
  showSuccessMsg: boolean = false;
  successMsg: string = "Folder Created Succesfully. Please Refresh to view the changes";
  warningMsg: string = "Can not Enter Charecters like ~!@#$%^&*()_+{}|:<>,.;'[]\\";
  showWarningMsg: boolean = true;
  errorMsg: string = "";
  showErrorMsg: boolean = false;
  activeFolderIndex:number;
  showEditFolderInput: boolean = false;
  editFolderName: string = "";

  displayedColumn: string[] = ['messageFolder', 'action' ];
  toBeEditedID: number;
 
  hasSpecialCharacters: boolean = false;
 

  constructor(private readonly _dialogRef: MatDialogRef<UpdateMessageFolderComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
     private readonly _messageService: MessageService,
    private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog) {

  }
 
  ngOnInit() {
    this.showSuccessMsg = false;
    this.showEditFolderInput = false;
    this.showErrorMsg = false;
    this.errorMsg = "";

    this.messageFolderList = [];
    this._messageService.getMessageFolderList<ResponseModel<MessageFolderModel[]>>().subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.messageFolderList = response.model;
          // this.messageFolderList.forEach(folder=>{folder.editable=![1,4,5,6,11,12].some(folderid=>folderid==folder.messageFolderID)});
          this.messageFolderList = this.messageFolderList.filter(mf => mf.isUserCreatedFolder === true); 
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
    
  }
 

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  createNewFolder() {
    this.checkForSpecialCharacters();
    if( !this.hasSpecialCharacters && this.newFolderName.length < 16 && this.newFolderName.trim() != ''){
      this._messageService.createOrUpdateMessageFolder<ResponseModel<boolean>>(this.newFolderName).subscribe(result=>{
        if (result.code = "200")
        {
          this.folderExist = result.model
          this.errorMsg = this.folderExist ?   "Folder Already Exists" : this.errorMsg ;   
          if ( this.errorMsg.length>5){
            this.showErrorMsg = true;
            setTimeout(() => {
              this.showErrorMsg = false; 
            }, 2000);
          }
      
          if (!this.folderExist) {
            this.showNewFolderInput = false;
            this.ngOnInit();
            this.newFolderName = '';
            this.folderExist = false;
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false; 
            }, 2000);

          }
        }
    });
    // this.showSuccessMsg = false;
    }

    this.errorMsg = this.hasSpecialCharacters ?  "Cannot Enter Special Characters" : this.errorMsg;
    this.errorMsg = this.newFolderName.length > 15 ?  "Folder Name is Too Large" : this.errorMsg;
    
    if ( this.errorMsg.length>5)
      this.showErrorMsg = true;
    setTimeout(() => {
      this.showErrorMsg = false; 
    }, 1000);

  }

  cancelNewFolder(){
    this.showNewFolderInput = false; 
    this.newFolderName='';
    this.folderExist=false;
    this.showErrorMsg = false;
    this.errorMsg = "";

  }
 
  editDetails(messageFolderID) {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = "40em"; // "1000px"
    // dialogConfig.height = "30em"; // "500px"
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    // dialogConfig.hasBackdrop = false;

    dialogConfig.data = { dialogTitle: s , messageFolderList: this.messageFolderList, messageFolderID:messageFolderID};

    const dialogRef = this._dialog.open(MessageFolderEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this._dialogRef.close(result);
        this.ngOnInit();

      }
    });
  }
   
  checkForSpecialCharacters() {
    const specialChars = "`~-=|'!@#$%^&*()_+{}[]:;<>,.?~\\/\"";// Define your list of special characters
    for (let i = 0; i < this.newFolderName.length; i++) {
      if (specialChars.includes(this.newFolderName[i])) {
        this.hasSpecialCharacters = true; // Special character found
        return;
      }
    }
    this.hasSpecialCharacters = false; // No special characters found
  }
 

}
