import { MessageFolderModel } from "./message-folder.model";
import { MessageModel } from "./message.model";

export class MessageCenterModel {
  messages: MessageModel[];
  startDate: string;
  endDate: string;
  pageNumber: number;
  totalRecords: number;
  inboundRecords: number;
  outboundRecords: number;
  timeFrame: number;
  messageFolders: MessageFolderModel[];
  assignedTo: string;
  messageFolderID: number;
  sort: string;
  pageSize: number;
  clientCount: number;
  tripCount: number;
  taskCount: number;
  searchFrom: string;
  searchSubject: string;
  searchTo: string;
  searchCustomerGUID: string;
  includeTrips: boolean;
  searchAll: string;
  searchMessageGUID: string;
  searchAssigned: string;
  bothCount: number;
  showFlagMessages: boolean;
  customerGUID: string;
  tripCodeGUID: string;
  groundstopGuidList: string;
  groundstopTaskGUID: string;
}
