import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { PersonClassModel } from '../models/person-class.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { GlobalConstant } from '../common-utility/global-constant';
import { SelectionModel } from '@angular/cdk/collections';
import { CustomValidators } from '../common-utility/custom.validators';
import { ReturnObjModel } from '../models/return-obj.model';
import { TripModel } from '../models/trip.model';
import { MessageService } from '../services/message.service';



@Component({
  selector: 'app-email-address-selection-client-dialog',
  templateUrl: './email-address-selection-client-dialog.component.html',
  styleUrls: ['./email-address-selection-client-dialog.component.css']
})

export class EmailAddressSelectionClientDialogComponent implements OnInit {
  userType: string;
  email_selection_client_form: UntypedFormGroup;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  tripCodeGUID: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  openFrom: string;
  submitted: boolean = false;
  v: number = this._authService.getCurrentTimeNumber();
  loading = false;
  personClassList: PersonClassModel[];
  gsList: GroundStopModel[];
  displayedColumnPerson: string[] = ['cc', 'personType', 'contact', 'jobTitle', 'comms'];
  displayedColumnVendor: string[] = ['cc', 'jobTitle', 'contact', 'comms'];
  nextGroundStopGUID: string;
  serviceTypeID: number;
  opsEmail: string;
  salesEmail: string;
  opsPhone: string;
  fpiAddress: string;
  upPII: boolean = false;
  selectionTo = new SelectionModel<PersonCommsAddressModel>(true, []);
  selectionCc = new SelectionModel<PersonCommsAddressModel>(true, []);
  selected_to: string="";
  selected_cc: string = "";
  showSuccessMsg: boolean = false;
  successMsg: string = "Your information has been saved successfully.";
  wrongToMsg: string = "";
  wrongCcMsg: string = "";
  showCommsInTrip:boolean = true;  

  constructor(private readonly _dialogRef: MatDialogRef<EmailAddressSelectionClientDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService,
    private readonly _messageService: MessageService ) {

    this.tripCodeGUID = _data.tripCodeGUID;
    this.groundStopGUID = "";
    if (_data.groundStopGUID != null && _data.groundStopGUID != undefined && _data.groundStopGUID != "") {
      this.groundStopGUID = _data.groundStopGUID;
    }
    this.groundStopTaskGUID = "";
    if (_data.groundStopTaskGUID != null && _data.groundStopTaskGUID != undefined && _data.groundStopTaskGUID != "") {
      this.groundStopTaskGUID = _data.groundStopTaskGUID;
    }
    if (_data.showCommsInTrip != null && _data.showCommsInTrip != undefined )
      this.showCommsInTrip = _data.showCommsInTrip;

    this.serviceTypeID = _data.serviceTypeID;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.openFrom = _data.openFrom;
  }

  getAllData(): Observable<any[]> {
    let request = new GroundStopTaskModel();
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    request.serviceTypeID = this.serviceTypeID;
    request.nextGroundStopGUID = this.nextGroundStopGUID; // for vender email and serviceTypeID=69

    let getDefaultEmailResponse; 
    if (this.groundStopTaskGUID != "") {
      getDefaultEmailResponse = this._messageService.getGroundStopTaskDefaultEmailBygstId<ResponseModel<GroundStopTaskModel>>(request);
    }
    else {
      if (this.tripCodeGUID != "") {
        let reqTrip = new TripModel();
        reqTrip.tripCodeGUID = this.tripCodeGUID;
        getDefaultEmailResponse = this._messageService.getTripDefaultEmailBytId<ResponseModel<TripModel>>(reqTrip);
      }
      else {
        getDefaultEmailResponse = of(null);
      }
    }

    let getPersonResponse = this._groundStopAdvancedService.getPersonEmailsByTrip<ResponseModel<PersonClassModel[]>>(request);

    let getvendorResponse;
    if (this.userType == 'internal') {
      getvendorResponse = this._groundStopAdvancedService.getVendorEmailsByTrip<ResponseModel<GroundStopModel[]>>(request);
    }
    else {
      getvendorResponse = of(null);
    }

    return forkJoin([getDefaultEmailResponse,getPersonResponse, getvendorResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.personClassList = [];
    this.gsList = [];
    this.opsEmail = GlobalConstant.opsEmail;
    //this.salesEmail = GlobalConstant.salesEmail;
    //this.opsPhone = GlobalConstant.opsPhoneWorldwide;
    //this.fpiAddress = GlobalConstant.fpiOfficeAddress;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (this.groundStopTaskGUID != "") {
            let t = new GroundStopTaskModel();
            t = responses[0].model;
            this.selected_to = t.defaultEmailTo;
            this.selected_cc = t.defaultEmailCc;
          }
          else {
            let t = new TripModel();
            t = responses[0].model;
            this.selected_to = t.defaultEmailTo;
            this.selected_cc = t.defaultEmailCc;
          }
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.personClassList = responses[1].model;          
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.userType == 'internal') {
        if (responses[2] != null) {
          if (responses[2].code == "200" && responses[2].message == "") {
            this.gsList = responses[2].model;           
          }
          else {
            if (responses[2].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }

      this.personClassList.forEach(x => {
        x.panelOpenState = true;
        x.personList.forEach(y => {
          y.commAddressList.forEach(z => {
            z.selectedTo = false;
            if (this.selected_to != "") {
              let ti: number = this.selected_to.toLowerCase().indexOf(z.address.toLowerCase());
              if (ti >= 0) {
                z.selectedTo = true;
                this.selectionTo.select(z);
              }
            }          
            z.selectedCc = false;
            if (this.selected_cc != "") {
              let ci: number = this.selected_cc.toLowerCase().indexOf(z.address.toLowerCase());
              if (ci >= 0) {
                z.selectedTo = true;
                this.selectionCc.select(z);
              }
            }
          });
        });
      });

      this.gsList.forEach(x => {
        x.groundStopTaskList.forEach(y => {
          y.panelOpenState = true;
          y.personList.forEach(z => {
            z.commAddressList.forEach(a => {
              a.selectedTo = false;
              if (this.selected_to != "") {
                let ti: number = this.selected_to.toLowerCase().indexOf(a.address.toLowerCase());
                if (ti >= 0) {
                  a.selectedTo = true;
                  this.selectionTo.select(a);
                }
              }
              a.selectedCc = false;
              if (this.selected_cc != "") {
                let ci: number = this.selected_cc.toLowerCase().indexOf(a.address.toLowerCase());
                if (ci >= 0) {
                  a.selectedTo = true;
                  this.selectionCc.select(a);
                }
              }

            });
          });
        });
      });

      this.initControls();

      this.showSpin = false;

    });

  }

  initControls() {
    this.email_selection_client_form = this._formBuilder.group({
      selected_to: [this.selected_to],
      selected_cc: [this.selected_cc]
    });

  }

  get f() { return this.email_selection_client_form.controls; }

  openAll() {
    this.gsList.forEach(x => {
      x.groundStopTaskList.forEach(y => {
        y.panelOpenState = true;
      });
    });
    this.personClassList.forEach(x => {
      x.panelOpenState = true;
    });
  }

  closeAll() {
    this.gsList.forEach(x => {
      x.groundStopTaskList.forEach(y => {
        y.panelOpenState = false;
      });
    });
    this.personClassList.forEach(x => {
      x.panelOpenState = false;
    });
  }

  checkToChange(e: any, item: PersonCommsAddressModel) {
    this.errMsg = "";
    //this.successMsg = "";
    this.showSuccessMsg = false;
    e ? this.selectionTo.toggle(item) : null;
    let s: string="";
    if (e.checked) {
      s = this.f.selected_to.value;
      s = s.trim();
      if (s != "") {
        if (s.indexOf(item.address) == -1) {
          s += "; ";
          s += item.address;
        }       
      }
      else {
        s += item.address;
      }
      this.f.selected_to.setValue(s);
    }
    else {
      s = this.f.selected_to.value;
      if (s != '') {
        if (s.indexOf(item.address + '; ') >= 0) {
          s = s.replace(item.address + '; ', '');
        }
        else {
          if (s.indexOf(item.address) >= 0) {
            s = s.replace(item.address,'');
          }
        }
      }
      this.f.selected_to.setValue(s);
    }
    
  }

  checkCcChange(e: any, item: PersonCommsAddressModel) {
    this.errMsg = "";
    //this.successMsg = "";
    this.showSuccessMsg = false;
    e ? this.selectionCc.toggle(item) : null;

    let s: string = "";
    if (e.checked) {
      s = this.f.selected_cc.value;
      s = s.trim();
      if (s != "") {
        if (s.indexOf(item.address) == -1) {
          s += "; ";
          s += item.address;
        }
      }
      else {
        s += item.address;
      }
      this.f.selected_cc.setValue(s);
    }
    else {
      s = this.f.selected_cc.value;
      if (s != '') {
        if (s.indexOf(item.address + '; ') >= 0) {
          s = s.replace(item.address + '; ', '');
        }
        else {
          if (s.indexOf(item.address) >= 0) {
            s = s.replace(item.address, '');
          }
        }
      }
      this.f.selected_cc.setValue(s);
    }
  }

  clickSelectComm() {
    //this.successMsg = "";
    this.showSuccessMsg = false;
    this.errMsg = "";
    this.submitted = true;
    //if (this.f.selected_to.value == '') {
    //  this.errMsg = "Please select at least one email address on \"To\" field.";
    //  return;
    //}

    this.validateEmailAddress();
    
    if (this.email_selection_client_form.invalid || this.errMsg != "") {
      return;
    }

    let response = new ReturnObjModel();
    response.refresh = true;
    response.to = this.f.selected_to.value;
    response.cc = this.f.selected_cc.value;
    this._dialogRef.close(response);
  }

  clickSaveComm() {
    this._authService.updateAccessTime();
    this.submitted = true;

    if (this.loading == true) {
      return;
    }

    this.validateEmailAddress();

    if (this.email_selection_client_form.invalid || this.errMsg != "") {
      return;
    }

    if (this.groundStopTaskGUID != "") {
      let request = new GroundStopTaskModel();
      request.groundStopTaskGUID = this.groundStopTaskGUID;
      request.defaultEmailTo = this.f.selected_to.value;
      request.defaultEmailCc = this.f.selected_cc.value;

      this.loading = true;
      this._groundStopAdvancedService.updateGroundStopTaskDefaultEmailBygstId<ResponseModel<boolean>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model) {
            this.errMsg = "";
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
              /*this.clickClose();*/
            }, 1000);
          }
          else {
            this.errMsg = "Failed to save this record at this time. Please try later.";
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        this.loading = false;
      })
    }
    else {
      let request = new TripModel();
      request.tripCodeGUID = this.tripCodeGUID;
      request.defaultEmailTo = this.f.selected_to.value;
      request.defaultEmailCc = this.f.selected_cc.value;

      this.loading = true;
      this._groundStopService.updateTripDefaultEmailBytId<ResponseModel<boolean>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model) {
            this.errMsg = "";
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
              /*this.clickClose();*/
            }, 1000);
          }
          else {
            this.errMsg = "Failed to save this record at this time. Please try later.";
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        this.loading = false;
      })
    }
    
  }

  validateEmailAddress() {
    let s: string="";
    if (this.f.selected_to.value != '') {
      let re = /,/gi;
      s = this.f.selected_to.value.trim();
      s = s.replace(re, ";")
      let re1 = / /gi;
      s = s.replace(re1, ";")
      let s1 = s.split(';');
      let s2 = "";
      for (var i = 0; i < s1.length; i++) {
        s1[i] = s1[i].trim();
        if (s1[i] != "") {
          let r = CustomValidators.validateCommonAddress(s1[i], "email");
          if (r == false) {
            this.wrongToMsg = "Invalid email: " + s1[i];
            this.f.selected_to.setErrors({ validateEmail: false });
            return;
          }
          else {
            if (s2 != "") {
              s2 += ";";
            }
            s2 += s1[i];
          }
        }
      }

      this.f.selected_to.setValue(s2);
    }
    let sc: string = "";
    if (this.f.selected_cc.value != '') {
      let rec = /,/gi;
      sc = this.f.selected_cc.value.trim();
      sc = sc.replace(rec, ";")
      let re1c = / /gi;
      sc = sc.replace(re1c, ";")
      let s1c = sc.split(';');
      let s2c = "";
      for (var i = 0; i < s1c.length; i++) {
        s1c[i] = s1c[i].trim();
        if (s1c[i] != "") {
          let rc = CustomValidators.validateCommonAddress(s1c[i], "email");
          if (rc == false) {
            this.wrongCcMsg = "Invalid email: " + s1c[i];
            this.f.selected_cc.setErrors({ validateEmail: false });
            return;
          }
          else {
            if (s2c != "") {
              s2c += ";";
            }
            s2c += s1c[i];
          }
        }
      }

      this.f.selected_cc.setValue(s2c);
    }

  }

  clickClose() {
    let response = new ReturnObjModel();
    response.refresh = false;
    response.to = "";
    response.cc = "";
    this._dialogRef.close(response);
  }



}
