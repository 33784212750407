import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { interval, merge, Subject, Subscription } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { MessageCenterModel } from '../models/message-center.model';
import { MessageFolderModel } from '../models/message-folder.model';
import { MessageModel } from '../models/message.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MessageService } from '../services/message.service';
import { FormsModule, UntypedFormBuilder, UntypedFormGroup, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CustomerModel } from '../models/customer.model';
import { TripModel } from '../models/trip.model';
import { CommonService } from '../services/common.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Title } from '@angular/platform-browser';
import { EmailClientDialogComponent } from './email-client-dialog.component';

const datepipe: DatePipe = new DatePipe('en-US');

@Component({
  selector: 'app-message-list-client',
  templateUrl: './message-list-client.component.html',
  styleUrls: ['./message-list-client.component.css']
})


/** message-list component*/
export class MessageListClientComponent implements AfterViewInit, OnChanges {
  private updateSubscription: Subscription;

  @Input() messageFolder: MessageFolderModel[];
  @Input() hasChanges: boolean;
  @Output() messageFolderChange = new EventEmitter<MessageFolderModel[]>();
  data: MessageModel[] = [];
  showSpin: boolean = false;
  isLoading: boolean = false;
  messageList = new MatTableDataSource<MessageModel>();
  selection = new SelectionModel<MessageModel>(true, []);
  displayedColumn: string[] = ['linkToTrip', 'date_time', 'sender', 'subject', 'body', 'has_attachments', 'action2'];
  destroyed = new Subject<void>();
  currentScreenSize: string;
  currentUser: string = localStorage.getItem('un');
  showSearch: boolean = false;
  timeFrame: number = 0;
  resultsLength: number = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  currentDate: Date = new Date();
  messageFolderID: number;
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'xSmall'],
    [Breakpoints.Small, 'small'],
    [Breakpoints.Medium, 'medium'],
    [Breakpoints.Large, 'large'],
    [Breakpoints.XLarge, 'xLarge'],
  ]);

  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filterUnread: boolean = false;
  showAll: boolean = true;
  currentShowAll: boolean = true;
  searchFrom: string = null;
  searchSubject: string = null;
  searchTo: string = null;
  searchAll: string = null;
  searchFiltervalue: number = 3;
  searchDateValue: number = 0;
  searchQuery: string = '';
  selectedCustomerGUID: string = "";
  searchParam = null;
  searchMessageGUID: string =  null;
  searchAssigned: string =  null;

  //client
  customerList: CustomerModel[];
  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  reloadChildPage: boolean;
  message_list_form: UntypedFormGroup;
  myForm: FormGroup;

  showClientList: boolean = false;
  orgCustomerList: CustomerModel[] = [];
  selectedCustList: CustomerModel[] = [];
  selectedClientCountText: string = "Select Client Account";
  selectedValue: any ;
  selectedClient: string = "";
  selectedClient2: FormControl;
  includeTrips: boolean = false;
  //client end

  currentFolderID: number = 1;
  currentPageNumber: number = 0;
  messagesOpened: number = 0;
  showFlagMessages: boolean = false;
  isFlagged: boolean = false;

  /** message-list ctor */
  constructor(private _titleService: Title,private readonly _messageService: MessageService, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService, private readonly _dialog: MatDialog,
    private formBuilder: FormBuilder, private readonly _formBuilder: UntypedFormBuilder, breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'unknown';
          }
        }
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.messageList.data = [];
    if (this.messageFolder.length == 0)
      this.messageFolderID = 1;
    else
      this.messageFolderID = this.messageFolder.find(x => x.selected == true).messageFolderID
    if (this.messageFolderID == 1 && this.filterUnread)
      this.messageFolderID = 2;
    this._authService.updateAccessTime();
    if (this.paginator != undefined) {
      this.paginator.pageIndex = 0;
      this.getData(true);
    }
  }



  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.updateSubscription = interval(120000).subscribe(
      () => { this.getData(false) });
  }

  getData(showSpin: boolean) {
    // this.showSpin = showSpin;
    let request = new MessageCenterModel();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.showSpin = showSpin;
          request.startDate = datepipe.transform(new Date(), 'MM/dd/YYYY');
          request.endDate = datepipe.transform(new Date(), 'MM/dd/YYYY');
          if(this.currentShowAll != this.showAll){
            this.messageList.data = [];
            this.currentShowAll = this.showAll;

          }
          request.timeFrame = this.searchDateValue;
          if (this.currentPageNumber != this.paginator.pageIndex) {
            this.messageList.data = [];
            this.currentPageNumber = this.paginator.pageIndex;
          }
          request.pageNumber = this.paginator.pageIndex;
          request.messageFolderID = this.messageFolderID;
          request.sort = this.sort.direction;
          request.pageSize = this.paginator.pageSize;
          request.searchFrom = this.searchFrom;
          request.searchSubject = this.searchSubject;
          request.searchTo = this.searchTo;
          request.searchAll = this.searchAll;
          request.searchAssigned = this.searchAssigned;
          //if(this.searchMessageGUID != null)
          //  request.searchAll = null;
          request.searchMessageGUID = this.searchMessageGUID;

          if (this.messageFolderID != this.currentFolderID && (this.messageFolderID == 12 || this.messageFolderID == 4)) {
            this.currentFolderID = this.messageFolderID;
          
          }


          // request.searchCustomerGUID = this.selectedCustList[0] ? this.selectedCustList[0].customerGUID: null ;
          if (this.messageFolderID == 12 || this.messageFolderID == 4) {
            request.searchCustomerGUID = this.selectedCustomerGUID;
          }
          else
            request.searchCustomerGUID = null;
          request.includeTrips = this.includeTrips;
          request.showFlagMessages = this.showFlagMessages;
          // request.showFlagMessages = this.f.showFlagMessages.value == true ? this.f.showFlagMessages.value :false;

          return this._messageService.getMessageListByConditionsClient<ResponseModel<MessageCenterModel>>(request)
        }),
        map(response => {
          // Flip flag to show that loading has finished.
          this.showSpin = false;
          if (response!.code == "401") {
            this._authService.signOut();
          }
          if (response.model === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = response.model.totalRecords;
          return response.model;
        }),
      )
      .subscribe(data => {

        if (data['messageFolders'] != null && data['messageFolders'] != undefined) {
          if (data['messageFolders'] != null) {
            this.messageFolder.forEach(x => {
              let f;
              let i = 0;
              if (x.messageFolderID == 1) {
                f = data['messageFolders'].find(y => y.messageFolderID == 1);
                if (f != undefined)
                  i += f.messageCount;
                f = data['messageFolders'].find(y => y.messageFolderID == 2);
                if (f != undefined)
                  i += f.messageCount;
                f = data['messageFolders'].find(y => y.messageFolderID == 3);
                if (f != undefined)
                  i += f.messageCount;
              }
              else {
                f = data['messageFolders'].find(y => y.messageFolderID == x.messageFolderID);
                if (f != undefined)
                  i = f.messageCount;
              }
              x.messageCount = i;

            });
          }
          if(data['messages'] != null){
            data['messages'].every(x => x.loading = false);
            data['messages'].forEach(x => x.tripCode = (x.tripCode == null ? '' : x.tripCode));
          }

          this.messageList = new MatTableDataSource<(MessageModel)>(data['messages']);
          this.selection = new SelectionModel<(MessageModel)>(true, []);
          // this.messageList.paginator = this.paginator;

        }
        else {
          this.messageList = new MatTableDataSource<(MessageModel)>([]);
          this.selection = new SelectionModel<(MessageModel)>(true, []);
        }
      });

    this.initControls();
    // if(this.messageFolderID!=current)
    // this.getTripClientList(true);

  }



  assignMessage(message: MessageModel, isAssign: boolean) {
    let request = new MessageModel();
    message.loading = true;
    request.messageGUID = message.messageGUID;
    if (isAssign)
      request.assignedTo = this.currentUser;
    else
      request.assignedTo = "";
    this._messageService.updateMessageAssignedTo<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.getData(false);
        }
      }
    });
  }

  assignMe(isAssign: boolean) {
    let request = new MessageModel();
    request.messageGUIDList = Array.prototype.map.call(this.selection.selected, s => s.messageGUID).toString();
    if (isAssign)
      request.assignedTo = this.currentUser;
    else
      request.assignedTo = "";
    this._messageService.updateMessageAssignedTo<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.getData(true);
          this.selection.clear();
        }

      }
    });
  }


  moveFolder(folderID: number) {
    let request = new MessageModel();
    request.messageGUIDList = Array.prototype.map.call(this.selection.selected, s => s.messageGUID).toString();
    request.messageFolderID = folderID;
    this._messageService.updateMessageFolder<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.getData(true);
          this.selection.clear();
        }

      }
    });
  }


  markAsSpam() {
    let request = new MessageModel();
    request.messageGUIDList = Array.prototype.map.call(this.selection.selected, s => s.messageGUID).toString();
    if (this.messageFolderID == 7)
      request.isSpam = false;
    else
      request.isSpam = true;
    this._messageService.updateMessageSpam<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.selection.clear();
          this.getData(false);
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();

          }
        }
      }

    });
  }


  initControls() {
    this.message_list_form = this._formBuilder.group({
      client_search: [''],
      selectedClient: [''],
      // showFlagMessages: [false],

      includeClosedBilledRecords: [this.includeClosedBilledRecords]
    });

    // this.myForm = this.formBuilder.group({
    //   client_search: [''], // Add the necessary form controls and initial values
    //   selectedClient: ['']
    // });
  }

  get f() { return this.message_list_form?.controls; }


  openMessage(message: MessageModel) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.position = { top: "40px"};
    this.messagesOpened = this.messagesOpened + 1;


    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", messageGUID: message.messageGUID, isDraft: message.isDraft,
      v: this._authService.getCurrentTimeNumber(), allMessageData: this.messageList.data,
      messageFolderID: this.messageFolderID, messageKeyId: message.messageKeyID, messagesOpened: this.messagesOpened
    };

    const dialogRef = this._dialog.open(EmailClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.isModified = true;
        this.getData(true);

      }
        this.messagesOpened = this.messagesOpened - 1;

    });
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.messageList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.messageList.data);
  }

  checkBox(row: any) {
    this.selection.toggle(row);

  }

  ngOnDestroy() {
    this.updateSubscription?.unsubscribe();
  }

  updateInboxFilter(e: any) {
    this.filterUnread = e.checked;
    if (this.messageFolderID == 1 && this.filterUnread)
      this.messageFolderID = 2;
    else
      this.messageFolderID = 1;
    this.getData(true);
  }

  // searchMessages(e) {

  //   this.searchTo = e.target.value;
  //   this.getData(true);
  // }

 

  searchMessages(e) {

    this.messageList.data = [];

    // this.searchFiltervalue = e.target.value; 
    if (e.target.value.trim() == '') {
      this.searchFrom = null;
      this.searchTo = null;
      this.searchAll = null;
      this.searchSubject = null;
      this.searchMessageGUID = null;
      this.searchAssigned = null;

      this.getData(true);
    }
    else {
      switch (this.searchFiltervalue) {
        case 0:
          this.searchFrom = e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
          break;
        case 1:
          this.searchTo = e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
          break;
        case 2:
          this.searchSubject = e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
          break;
        case 3:
          this.searchAll = e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
          break;
        case 4:
          this.searchMessageGUID =e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
        case 5:
          this.searchAssigned = e.target.value;   
          this.searchParam = e.target.value;
          this.getData(true);

        default:
          if(this.searchMessageGUID != null)
            this.searchAll = e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
          return;
      }
    }

  }

  searchFilterChange(e) {
    

    // if (this.searchParam != null || this.searchParam.length > 0) {
      switch (e.target.value) {
        case 'from':
          this.searchFiltervalue = 0;
          if (this.searchParam != null || this.searchParam.length > 0) { 
            this.searchFrom = this.searchParam;
            this.searchTo = null;
            this.searchSubject = null;
            this.searchMessageGUID = null;
            this.searchAll = null;
            this.searchAssigned = null;
            this.messageList.data = [];
            this.getData(true);
          }
          break;
        case 'to':
          this.searchFiltervalue = 1;
          if (this.searchParam != null || this.searchParam.length > 0) { 
            this.searchFrom = null;
            this.searchTo = this.searchParam;
            this.searchSubject = null;
            this.searchMessageGUID = null;
            this.searchAll = null;
            this.searchAssigned = null;
            this.messageList.data = [];
            this.getData(true);
          }
          break;
        case 'subject':
          this.searchFiltervalue = 2;
          if (this.searchParam != null || this.searchParam.length > 0) { 
            this.searchFrom = null;
            this.searchTo = null;
            this.searchSubject = this.searchParam;
            this.searchAll = null;
            this.searchMessageGUID = null;
            this.searchAssigned = null;
            this.messageList.data = [];
            this.getData(true);
          }
          break; 

        case 'all':
          this.searchFiltervalue = 3;
          if (this.searchParam != null || this.searchParam.length > 0) { 
            this.searchFrom = null;
            this.searchTo = null;
            this.searchSubject = null;
            this.searchMessageGUID = null;
            this.searchAll = this.searchParam;
            this.searchAssigned = null;
            this.messageList.data = [];
            this.getData(true);
          }
          break;

        case 'messageGUID':
          this.searchFiltervalue = 4;
          if (this.searchParam != null || this.searchParam.length > 0) { 
            this.searchFrom = null;
            this.searchTo = null;
            this.searchSubject = null;
            this.searchMessageGUID = this.searchParam;
            this.searchAll = null;
            this.searchAssigned = null;
            this.getData(true);
          }
          break;

          case 'assigned':
            this.searchFiltervalue = 5;
            if (this.searchParam != null || this.searchParam.length > 0) { 
              this.searchFrom = null;
              this.searchTo = null;
              this.searchSubject = null;
              this.searchMessageGUID = null;
              this.searchAll = null;
              this.searchAssigned = this.searchParam;
              this.getData(true);
            }
            break;
          
        default:
          this.searchFiltervalue = 3;
          this.searchAll = this.searchParam;
          this.getData(true);

          return;
      }
    // }
  }

  resetSearchFilter() {
    this.messageList.data = [];

    this.searchFrom = null;
    this.searchSubject = null;
    this.searchTo = null;
    this.searchAll = null;
    this.searchAssigned = null;
    this.searchParam = null;
    this.searchMessageGUID = null;
    this.selectedClient = '';
    this.selectedCustomerGUID = '';

     this.getData(true);
    this.searchQuery = '';
    
  }


  includeTripsChange(e: any) {
    this.includeTrips = e.checked;
    this.showClientList = false;
    this.getData(true);

  }

  searchDateChange(e) {
    this.messageList.data = [];

    // if (this.searchParam != null || this.searchParam.length > 0) {
      switch (e.target.value) {
        case '24h':
          this.searchDateValue = 1440;  // 24 hours
          this.getData(true);
          break;
        case '48h':
          this.searchDateValue = 2880;  //  Messages for 48 hours
          this.getData(true);
          break;
        case '3d':
          this.searchDateValue = 4320;  //  Messages for 3 days
          this.getData(true);
          break;
        case '7d':
          this.searchDateValue = 10080;  //  Messages for 7 days
          this.getData(true);
          break;
        case '30d':
          this.searchDateValue = 43200;  //  Messages for 30 days
          this.getData(true);
          break; 

        case 'd_all':
          this.searchDateValue = 0;  // All Messages
          this.getData(true);
          break;

       
        default:
          this.searchDateValue = 4320;  //  Messages for 3 days
          this.getData(true);

          return;
      }
    // }
  }

  showFlaggedOnChange(e: any) {
    this.showFlagMessages = e.checked;
    this.showSpin = true;
    this.getData(true); 
  }

  // FlagMessage(){
  //   if(this.isFlagged == false){
  //     this.isFlagged = true;
  //   }
  //   else{
  //     this.isFlagged = false;
  //   }
  //   this.updateFlag();

  // } 
  
  flagMessages(flagValue: boolean) {
    let message: MessageModel = new MessageModel();
    message.messageGUIDList = Array.prototype.map.call(this.selection.selected, s => s.messageGUID).toString(); 
    message.isFlagged = flagValue;//this.isFlagged;

    this._messageService.updateMessageFlag<ResponseModel<boolean>>(message).subscribe(response => {
      if (response != null && response.message == "" && response.code == "200") {
 
            this.getData(true);
            this.selection.clear();

      }
    });
  }

}
// assignMe(isAssign: boolean) {
//   let request = new MessageModel();
//   request.messageGUIDList = Array.prototype.map.call(this.selection.selected, s => s.messageGUID).toString(); 
//   request.assignedTo = this.currentUser; 
//   this._messageService.updateMessageAssignedTo<ResponseModel<MessageModel>>(request).subscribe(response => {
