import { Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef,MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatAccordion } from '@angular/material/expansion';
import { forkJoin, Observable, of } from 'rxjs'; 
import { CountryContentModel } from '../models/country-content.model';
import { CountryRegistrationPrefixModel } from '../models/country-registration-prefix.model';
import { CountryModel } from '../models/country.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { HandlingNotesModel } from '../models/handling-notes.model';
import { ResponseModel } from '../models/response.model';
import { VendorModel } from '../models/vendor.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from 'file-saver';
 import { NoteTypeModel } from '../models/note-type.model';
import { CountryContentEditDialogComponent } from '../country/country-content-edit-dialog.component';
import { BusinessRulesModel } from '../models/business-rules.model';
import { AirportDocumentModel } from '../models/airport-document.model';
 import { UtilityFunctions } from '../common-utility/utility.functions';
import { DocumentTypeModel } from '../models/document-type.model';
import { ChangeDetectorRef } from '@angular/core';
import { CountryDocumentModel } from '../models/country-document.model';
import { CountryService } from '../services/country.service';
import { CountryDocumentsEditDialogComponent } from '../country/country-documents-edit-dialog.component';
import { VendorService } from '../services/vendor.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReturnStatement } from '@angular/compiler';
import { CountryContentDocModel } from '../models/country-content-doc.model';
import { CountrySegmentEditDialogComponent } from './country-segment-edit-dialog.component';
import { v4 as uuidv4 } from 'uuid';
import { BusinessRulesService } from '../services/business-rules-service';
import { CountryNotesAndDocumentsEditDialogComponent } from './country-notes-and-documents-edit-dialog.component';
import { validateBBox } from '@turf/turf';
import { BusinessRulesEditComponent } from '../business-rules/business-rules-edit.component';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

@Component({
    selector: 'app-country-details',
    templateUrl: './country-details.component.html',
    styleUrls: ['./country-details.component.css']
})
/** country-details component*/
export class CountryDetailsComponent implements OnInit, OnChanges { 
  @Input() countryID: number;
  // @Input() airports: AirportModel[] = [];
  icaoIata: string;
  groundStopGUID: string;
  tripCodeGUID: string;
  errMsg: string; 
  countryData: CountryModel = new CountryModel();
  countrySegmentContent: CountryContentModel[];
  vendorData: VendorModel[];
  vendorRole: VendorModel[];
  allVendorData: VendorModel[];
  fuelSuppliers: VendorModel[];
  registrationPrefix: CountryRegistrationPrefixModel[];
  countryContent: CountryContentModel[];
  countryContentSegment: CountryContentModel[];
  countryContentSegmentDocs: CountryContentDocModel[];
  countryContentSegmentMiscDocs: CountryContentDocModel[];
  selectedCountryContentSegment: string;

  countryContentChild: CountryContentModel[]; 
  customsNotes: HandlingNotesModel[];
  tripCode: string;
  arrivalDateTime: string;
  departureDateTime: string;
  arrivalDateTimeLocal: string;
  departureDateTimeLocal: string;
  nextICAO: string="";
  prevICAO: string="";
  registration: string;
  mapLink: string;
  countryMapLink: string;
  latitude: number;
  longitude: number;
  detailType: string = "airport";
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  isLoading: boolean = false;
  loadValue: number = 0;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  loadingWord: boolean = false;
  loadingPDF: boolean = false;
  noteCount: number = 0;
  userType: string;
  businessRulesList: BusinessRulesModel[];
  customerGuid: string = "";
  displayedColumn: string[] = ['brID', 'customerName', 'registration', 'far_type', 'country', 'icao', 'service_class', 'service_type', 'vendorName'];
  
  displayedColumn2: string[] = ['Type', 'Name', 'Effective_Date', 'Expiration_Date'  ];

  displayedColumn3: string[] = [  'clientName',   'registration', 'crew', 'pax', 'farType', 'effectiveDate', 'expirationDate',
  'timeFrame', 'isUTC', 'daysOfWeek',  'legType', 'avoid', /*'serviceClass',*/ 'serviceType', 'taskStatus', 'vendor' ];
displayedColumn4: string[] = [ 'businessRule', 'period', 'interest', 'services'];
  msg: string = "";
  showSpin: boolean = false; 
  recordList: AirportDocumentModel[];
  loading: boolean;
  countryDocumentGUID: string = "";
  documentTypeList: DocumentTypeModel[];
  openAccordions: boolean = false;
  countryDocuments: CountryDocumentModel[];
  airportDocumentGUID: string;
  // overFlightNote: string;
  // countryID: number;
  permitDocuments: FormGroup;

  isMinimized: boolean = false;
  uniqueId = uuidv4();
  sortedData: BusinessRulesModel[] = [];
  totalRecords: number;
  totalRecordsText: string;
  countryNotes: string;
  excludeList: string[];
  copied: boolean =false;
  showSuccessCopy: boolean =false;
  showSuccessMsg: string;
  username: string;
  formattedDate: any;
  isNewTab: boolean = false; 

  readonly validTime :string = 'validity time';
  readonly dueDate :string = 'due date';
  readonly leadTime :string = 'lead time';
  readonly prefileFilingTime :string = 'prefile filing time';
  readonly notes :string = 'notes';
  readonly file :string = 'file';
  readonly documents :string = 'documents';
  readonly prefileRequirements :string = 'prefile requirements';
  readonly overflight :string = 'overflight';
  readonly overflightNavCharges :string = 'overflight - nav charges';
  readonly landingPrivate :string = 'landing - private';
  readonly landingCharter :string = 'landing - charter';
  readonly countryNotesSec :string = 'country notes';
  readonly docsRequiredVar :string = 'documents required';
  isOpenedNew: boolean = false;
  showAllServices: boolean = false;
  countryAccess: boolean = false;
  upbRule: boolean = false;
  flagFile: string;

    /** country-details ctor */
  constructor(  private readonly _countryService: CountryService, private readonly _commonService: CommonService,    private readonly _vendorService: VendorService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService,@Optional() @Inject(MAT_DIALOG_DATA) private _data: any, private datePipe: DatePipe,
    private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog, private cdr: ChangeDetectorRef,private readonly dialog: MatDialog,
    @Optional() private readonly _dialogRef: MatDialogRef<CountryDetailsComponent>, private readonly _route: ActivatedRoute, private fb: FormBuilder , private readonly _businessRulesService: BusinessRulesService,
    private _titleService: Title, private sanitizer: DomSanitizer) {

      if(_data){

        this.username = _data.username;
        if(_data.countryID)
        this.countryID = _data.countryID;

        if(_data.segmentDescription)
        this.selectedCountryContentSegment = _data.segmentDescription;

        if(_data.isNewTab !== undefined)
          this.isNewTab = _data.isNewTab
      }
  }
    ngOnChanges(changes: SimpleChanges) {
      this.isLoading = true;
      this._authService.updateAccessTime(); 
      this.getData();
      this.getPermitData();
      this.getData2();
    }
  ngOnInit() {
    this.isLoading = true;
    this.isOpenedNew = false;

    if (localStorage.getItem('up.airportDoc') == 'true') 
      this.countryAccess = true;
    
    if (localStorage.getItem('up.bRule') == 'true') 
      this.upbRule = true;
    // this.countryID = this._route.snapshot.paramMap.get('countryID') as unknown as number;
    this._route.queryParams.subscribe(params => {
      if(params["countryID"]){
        
        this.countryID = params["countryID"]; 
        this.isOpenedNew = true;
      }

      if(params["segmentDescription"]) {
        this.selectedCountryContentSegment = params["segmentDescription"];
        this.isOpenedNew = true;
      }
    });

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    // this._authService.updateAccessTime();
 
    this.initControls();

    this.getData2();
    this.getData();
     this.getPermitData();

  }
  pDocs():FormArray{
    return this.permitDocuments.controls.docs as FormArray;
  }
  initControls() {
    const permDocs = this.fb.array<FormGroup>([]);
    this.permitDocuments = this.fb.group({
      docs: permDocs
    });
  }

  addPermitDocument(){
    (this.permitDocuments.controls.docs as FormArray).push(this.fb.group({
      file:["",Validators.required]
    }));
  }

  removePermitDocument(i){
    /*
    API to remove the doc
    */
    (this.permitDocuments.controls.docs as FormArray).removeAt(i);
  }

  importDoc(elem){
     /*
    API to upload the doc
    */
    console.log(elem);
  }

  getAllData(): Observable<any[]> {
    
    let getDocTypeResponse = this._countryService.getCountryDocumentTypeList<ResponseModel<DocumentTypeModel[]>>(this.countryDocumentGUID);

 
    return forkJoin([ getDocTypeResponse]);
  }

  getData() {
    this.isLoading = true;
    this._commonService.getCountryDetailsByCountryId<ResponseModel<CountryModel>>(this.countryID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.countryData = response.model;
          this.registrationPrefix = this.countryData.registrationPrefixes;
          this.countryContent = this.countryData.countryContent;
          this.countryContentSegment = this.countryData.countryContentSegment;
          this.countryContentSegmentDocs = this.countryData.countryContentSegmentDocs?.filter(doc => !doc.isMiscellaneous  )
          this.countryContentSegmentMiscDocs = this.countryData.countryContentSegmentDocs?.filter(doc => doc.isMiscellaneous  )

          if(this.isNewTab || this.isOpenedNew)
            this._titleService.setTitle(this.countryData.countryName);
          
          this.countryDocuments = this.countryData.documentList;
          this.isLoading = false;
          this.updateCountryDocumentTypes();
          this.getBusinessRules();
          this.countryNotes = this.getCategoryValue(0,'CountryNotes');
          // if (this.countryContent != null) {
          //    this.countryContentSegment = this.countryContent.filter((n, i, arr) => arr.findIndex(c => c.countryContentSegment === n.countryContentSegment) === i);
          //   //this.overFlightNote = this.countryContent.filter(c => c.countryContentSegment == "Overflight" && c.countryContentCategory == "Notes")[0].countryContentDescription;
          // }

          this.countryMapLink = "https://maps.google.com/maps?q=" + this.countryData.countryName.replace(" ", "+") + "&t=k&z=5&center=29.98444444,-95.34138889&ie=UTF8&iwloc=&output=embed";
          this.flagFile = this.countryData.flagFile;
        }
      }

    }); 

    
  }

  getData2(){
    
    this.getAllData().subscribe(responses => { 

      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.documentTypeList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      } 

    });
    this.updateCountryDocumentTypes();
  }

  getPermitData(){
    this.isLoading = true;
    this._vendorService.getPermitVendorsByCountryID<ResponseModel<VendorModel[]>>(this.countryID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.vendorData = response.model;
          this.isLoading = false;
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }
 
 

  editCountryContent(country: CountryContentModel) {
    let s = "";
    let countryContentSegmentID = 0;
    if (country == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      countryContentSegmentID = country.countryContentSegmentID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, countryID: this.countryID, countryContentSegmentID: countryContentSegmentID };
    const dialogRef = this._dialog.open(CountryContentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();

      }

    });
  } 

  downloadAllRequiredAttachments(segmentId) {

    const validDocs = this.countryContentSegmentDocs.filter(doc => doc.containerName != undefined && doc.containerName != null && doc.countryContentSegmentId === segmentId);
 
     validDocs.forEach((doc) => {
      this.downloadDocument(doc.containerName, doc.containerPath, doc.documentType, doc.mimeType);                     
                                                        //   downloadDocument(containerName: string, containerPath: string, docName:string, mimeType:string){
                                                        //  (click)="downloadDocument(doc.containerName, doc.containerPath, doc.documentType, doc.mimeType)"
    });
  } 

  downloadAllRequiredMiscAttachments(segmentId) {

    const validDocs = this.countryContentSegmentMiscDocs.filter(doc => doc.containerName != undefined && doc.containerName != null && doc.countryContentSegmentId === segmentId);
 
     validDocs.forEach((doc) => {
      this.downloadDocument(doc.containerName, doc.containerPath, doc.documentType, doc.mimeType);                     
                                                       
    });
  } 
  
  copyToClipboard(docTypeName) {
    const textField = document.createElement('textarea');
    textField.value = docTypeName;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    this.copied = true;         
    this.showSuccessCopy = true;
    this.showSuccessMsg = 'Item Copied Successfully';

    // Optional: Reset the "copied" state after a certain time
    setTimeout(() => {
      this.copied = false;
      this.showSuccessCopy = false;

    }, 2000); // Reset after 2 seconds
  }
  copyToClipboardAll(segmentId) {

    const validDocs = this.countryContentSegmentDocs?.filter(doc =>   doc.countryContentSegmentId === segmentId);

    let totalName =  validDocs.map(record => record.documentType).join('\n');
    this.copyToClipboard(totalName);
  }

  checkCopyExistence(segmentId): boolean{
    const validDocs = this.countryContentSegmentDocs?.filter(doc =>   doc.countryContentSegmentId === segmentId);
 
    if (validDocs == undefined)
      return false
    if(validDocs.length > 0){
      return true;
    }
    else return false;
  }

  copyToClipboardAllMisc(segmentId) {

    const validDocs = this.countryContentSegmentMiscDocs?.filter(doc =>   doc.countryContentSegmentId === segmentId);

    let totalName =  validDocs.map(record => record.documentType).join('\n');
    this.copyToClipboard(totalName);
  }
  
  checkCopyExistenceMisc(segmentId): boolean{
    const validDocs = this.countryContentSegmentMiscDocs?.filter(doc =>   doc.countryContentSegmentId === segmentId);
 
    if (validDocs == undefined)
      return false
    if(validDocs.length > 0){
      return true;
    }
    else return false;
  }
  

  previewDocument(containerName: string, containerPath: string,   mimeType: string) {
    let countryDoc: CountryDocumentModel = new CountryDocumentModel();
    countryDoc.containerName = containerName;
    countryDoc.containerPath = containerPath;

    this._countryService.downloadCountryDoc<ResponseModel<string>>(countryDoc).subscribe(dc => {
      if (dc.code == "200" && dc.model != '') {
        let byteArray = UtilityFunctions.base64ToArrayBuffer(dc.model);
        let file = new Blob([byteArray], { type: mimeType });
        file.size
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  } 

  checkDocumentsExistence(segmentId): boolean{
    const validDocs = this.countryContentSegmentDocs?.filter(doc => doc.containerName != undefined && doc.containerName != null && doc.countryContentSegmentId === segmentId);
 
    if (validDocs == undefined)
      return false
    if(validDocs.length > 0){
      return true;
    }
    else return false;
  }

  checkMiscDocumentsExistence(segmentId): boolean{
    const validDocs = this.countryContentSegmentMiscDocs?.filter(doc => doc.containerName != undefined && doc.containerName != null && doc.countryContentSegmentId === segmentId);
 
    if (validDocs == undefined)
      return false
    if(validDocs.length > 0){
      return true;
    }
    else return false;
  }

  expandAccordions(){
    this.openAccordions = true;
    this.cdr.detectChanges(); // Trigger change detection
  }

  collapseAccordions(){
    this.openAccordions = false;
    this.cdr.detectChanges(); // Trigger change detection
  }


  
  editCountryDocument(doc: CountryDocumentModel) {
    let s = "";
    let action = "";
    let countryDocumentGUID = "";
    if (doc == null) {
      s = "ADD New Record";
      action = "add";
    }
    else {
      s = "EDIT Record";
      action = "edit";
      countryDocumentGUID = doc.countryDocumentGUID;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = { dialogTitle: s, countryID: this.countryID,   countryName: this.countryData.countryName,
      action: action,  countryDocumentGUID: countryDocumentGUID, document: doc
    };
    const dialogRef = this._dialog.open(CountryDocumentsEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData(); 
      }

    });
  }

  downloadCountryDocument(item: CountryDocumentModel){
    let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
    let file = new Blob([byteArray], { type: item.mimeType });
    saveAs(file, item.documentName);
  } 
 
  getCountryDocuments(item: CountryDocumentModel) {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = false;
    this._authService.updateAccessTime();
    

    this._countryService.getCountryDocument<ResponseModel<CountryDocumentModel[]>>(item).subscribe(response => {
      if (response != null && response.code == "200") {

        // let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        // let file = new Blob([byteArray], { type: item.mimeType });
        // saveAs(file, item.documentName);

        let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        let file = new Blob([byteArray], { type: item.mimeType });
        file.size
        var fileURL = URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = item.documentName;
        link.click();
        // this.downloading1 = false;
 
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    });
 

  }

  updateCountryDocumentTypes() {

    this.countryDocuments?.forEach((countryDoc) => {
      const matchingDocument = this.documentTypeList?.find((docType) => docType.documentTypeGUID === countryDoc.documentTypeGUIDList);
      if (matchingDocument) {
        countryDoc.documentTypeList = matchingDocument.documentTypeDescription;
      }
    });
  }

  getCategoryValue(segId: number,cat: string):string{
    let val='';
    // console.log(segId, cat)
    
      try {
        val = this.countryContent?.filter(c => c.countryContentSegmentID == segId && c.countryContentCategory?.toLowerCase().trim() == cat.toLowerCase().trim())[0]?.countryContentDescription;
      } catch (error) {
        console.error('error finding category value with: '+cat,error);
      }
      if (val == undefined && (cat != undefined) &&  ([this.prefileFilingTime, this.leadTime, this.dueDate, this.validTime ].includes(cat.toLowerCase())  ) ) { 
        val = 'n/a'; 
      }
      if (([this.overflight, this.landingPrivate, this.landingCharter, this.overflightNavCharges].includes(cat.toLowerCase()))) {
        if (val != 'Yes' && val != 'No' && val != 'Restricted' && val != undefined) {

          val = 'See Notes';
        }
        if(val == undefined){
          val = 'n/a';
        }
      }
      if(cat == this.notes && val != undefined && val != null && val != ''){
        val = val?.length>85 ? val.substring(0,85) +'...' : val;
      }

    return val;
  }

  getVendorData(countryCategory): VendorModel[] {
    let vData = [];
    if(this.vendorData!=null)
    switch (countryCategory?.toLowerCase().trim()) {
      case this.overflight:
        vData = this.vendorData.filter(v => v.permitType?.toLowerCase().includes('overflight') && !v.permitType?.toLowerCase().includes('charges'));
        break;
      case this.landingPrivate:
        vData = this.vendorData.filter(v => v.permitType?.toLowerCase().includes('landing') && !v.permitType?.toLowerCase().includes('charter'));
        break;
      case this.landingCharter:
        vData = this.vendorData.filter(v => v.permitType?.toLowerCase().includes('charter'));
        break;
      case this.overflightNavCharges:
        vData = this.vendorData.filter(v => v.permitType?.toLowerCase().includes('charges'));
        break;
      }

      vData= this.sortPreferred(vData);
      
      return vData;
    }

  sortPreferred(vData: VendorModel[]): VendorModel[] {
    let prefVendors = vData.filter(p => p.ratingDescription == "Preferred");
    prefVendors.forEach(pv=>{
      let idx = vData.findIndex(vd => vd == pv);
      vData.splice(idx, 1);
      vData.unshift(pv);
    });
    return vData;
  }

  getCountryContentSegmentDocs(segmentid:number):CountryContentDocModel[]{
    return this.countryContentSegmentDocs?.filter(s => s.countryContentSegmentId == segmentid && s.documentTypeId != 109);
  }

  getCountryContentSegmentMiscDocs(segmentid:number):CountryContentDocModel[]{
    return this.countryContentSegmentMiscDocs?.filter(s => s.countryContentSegmentId == segmentid && s.documentTypeId != 109);
  }

  downloadDocument(containerName: string, containerPath: string, docName:string, mimeType:string){
      let countryDoc:CountryDocumentModel = new CountryDocumentModel();
      countryDoc.containerName = containerName;
      countryDoc.containerPath = containerPath;

      this._countryService.downloadCountryDoc<ResponseModel<string>>(countryDoc).subscribe(dc=>{
        if (dc.code == "200" && dc.model != '') {
          let byteArray = UtilityFunctions.base64ToArrayBuffer(dc.model);
          let file = new Blob([byteArray], { type: mimeType });
          saveAs(file, docName);
        }
      });
    } 

    
  editSegmentDetails(segment ){
    this.countrySegmentContent = this.countryData.countryContent?.filter(cc => cc.countryContentSegmentID == segment.countryContentSegmentID)

 
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    // dialogConfig.width = "50em";//"1000px";
    //  dialogConfig.height = "28em";//"500px";
    // dialogConfig.minHeight = "35em";
    dialogConfig.maxHeight = "44em";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s,  countrySegmentContent:this.countrySegmentContent, countryID: this.countryID, segmentDetails: segment,  userType: this.userType, username : this.username,

      countryData:this.countryData,
      countryName: this.countryData.countryName, segmentDocs:this.getCountryContentSegmentDocs(segment.countryContentSegmentID),
      segmentMiscDocs:this.getCountryContentSegmentMiscDocs(segment.countryContentSegmentID)
    };

    const dialogRef = this.dialog.open(CountrySegmentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => { 
      if(result){
        // this.countryContentSegmentDocs = [...this.countryContentSegmentDocs];
        // this.showSuccessMsg = false;
        // this.ngOnInit();
        //setTimeout(() => {
          this.getData();
        //}, 1500);
      }
    }); 
  }

  
  
  minimizeDialog() {
    this.isMinimized = true; 
    if (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-container') != undefined &&
      document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-container') != null)
      (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-container') as HTMLElement).style.transform = 'none';
      // (document.querySelector('.custom-dialog-container .mat-dialog-container') as HTMLElement).className = 'minimizeStyles'

    
      
      // // // // (document.getElementsByTagName('mat-dialog-container')[0] as HTMLElement).style.height = '0';
      // // // // (document.querySelector('.custom-dialog-container .mat-dialog-container') as HTMLElement).classList.add('minimizeStyles'); // = 'minimizeStyles'
  } 
  maximizeDialog() {
    this.isMinimized = false;
    // (document.querySelector('.minimizeStyles')  ).className = 'custom-dialog-container';
    // (document.getElementsByTagName('mat-dialog-container')[0] as HTMLElement).classList.add('mat-dialog-container'); 



    // // // // (document.querySelector('.custom-dialog-container .mat-dialog-container') as HTMLElement).style.all = 'initial';
    // // // // (document.querySelector('.mat-dialog-container') as HTMLElement).style.height = '100%';
    // // // // (document.querySelector('.mat-dialog-container') as HTMLElement).style.width = '100%';
    // // // // (document.querySelector('.mat-dialog-container') as HTMLElement).style.background = '#f8f8f8';
    // // // // (document.querySelector('.minimizeStyles') as HTMLElement).classList.remove("minimizeStyles"); // = 'maximizeStyles' 
  }
  currentDate: Date = new Date();

  getBusinessRules(){


    let request = new BusinessRulesModel();
    request.selectedCountryGUIDList = this.countryData.countryGUID;
    request.selectedICAOList = 'All';

    const yourDate = new Date();
    this.formattedDate = this.datePipe.transform(yourDate, 'MM/dd/yyyy'); 
    request.effectiveDate = this.formattedDate;
    request.expirationDate = '';
    request.personRole = 'crew';
    request.isActive = false; 
    this._businessRulesService.getBusinessRulesByConditions<ResponseModel<BusinessRulesModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.businessRulesList = response.model;
          if (this.businessRulesList != null) {
            if(this.showAllServices == false){
              this.businessRulesList = this.businessRulesList.filter(brl =>  brl.serviceTypeDescription.toLowerCase().includes('overflight') ||  brl.serviceTypeDescription.toLowerCase().includes('landing'))
            } 
            
            this.sortedData = this.businessRulesList.slice();
          }
          this.totalRecords = this.businessRulesList.length;
          this.totalRecordsText = this.totalRecords + " total records"
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else
            this.errMsg = "An error has occurred.";
        }
      }
      this.showSpin = false;
    });
  } 

  showAllServicesChange($event){

    if(this.showAllServices){
      this.showAllServices= false;
    } 
    else  
      this.showAllServices = true;

    this.getBusinessRules();
  }

  editCountryNotesAndDocuments(){ 
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    // dialogConfig.width = "50em";//"1000px";
    //  dialogConfig.height = "28em";//"500px";
    // dialogConfig.minHeight = "35em";
    dialogConfig.maxHeight = "44em";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s,   countryID: this.countryID , countryName: this.countryData.countryName , countryData: this.countryData,
      countryContent: this.countryContent?.filter(c=>c.countryContentCategory=='CountryNotes')[0]
    };

    const dialogRef = this.dialog.open(CountryNotesAndDocumentsEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => { 
      if(result){
        //  this.ngOnInit();
         this.getData();
      }
    }); 
  }


  sortData(e){

  }


  clickEdit(event: any, br: BusinessRulesModel) {
    if (!this.upbRule) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    let businessRulesID = 0;
    let s = "Add Business Rule";
    if (br != null) {
      businessRulesID = br.businessRulesID;
      s = "Edit Business Rule " + businessRulesID.toString();
    }
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { businessRulesID: businessRulesID, title: s ,selectedCountryGUID: this.countryData.countryGUID};


    const dialogRef = this._dialog.open(BusinessRulesEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.sortedData.length == 0)
          // this.f.brID.setValue(result);
        this.getData();
      }
    });
    //  let navigationExtras: NavigationExtras = {
    //    queryParams: {
    //      "businessRulesID": br.businessRulesID        

    //    }
    //  };
    //this._router.navigate(['/business-rules/business-rules-edit'], navigationExtras);
  }

  checkExistence(value: boolean, segName: string, segId: number, cat: string  ): boolean {
    let flag = false;
    let validList = this.countryData.countryContenCategoryList.filter(ctl => ctl.countryContentCategory.toLowerCase() === cat.toLowerCase() && ctl.countryContentSegmentID === segId);
    
    this.excludeList = [this.overflight, this.leadTime, this.dueDate, this.validTime, this.file, this.documents,this.prefileFilingTime, this.landingPrivate, this.landingCharter, this.overflightNavCharges, 'update', this.docsRequiredVar]
    if (!([this.countryNotesSec].includes(segName.toLowerCase())) && value == true) {
      // Additional filtering for 'Main 4 segments'
      this.excludeList = this.excludeList.map(value => value.toLowerCase());
      validList = validList.filter(item => !this.excludeList.includes(item.countryContentCategory.toLowerCase()));
    }
    if (([this.countryNotesSec].includes(segName.toLowerCase())) && value == true) {
      // Additional filtering for 'country notes' segment
      this.excludeList = this.excludeList.map(value => value.toLowerCase());
      validList = validList.filter(item => !this.excludeList.includes(item.countryContentCategory.toLowerCase()));
    }
  
    if (validList.length > 0) {
      flag = true;
    }
    return flag;
  }
  
  openLink(link: string): void {
    window.open(link, '_blank');
  }
  
  clickOpenInNew(event: any) {
    //this._router.navigate(['/ground-stops/trip-details', { pId: p.personGUID }]);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "countryID": this.countryID, 
        "pf": "", 
        v: this._authService.getCurrentTimeNumber()
      }
    };
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    //this._router.navigate(['/ground-stops/trip-details-internal'], navigationExtras);
    window.open('/country-details?countryID=' + this.countryID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
    // this.isOpenedNew = true;
  }
  
  isDateExpired(dateString: string): boolean {
    if(dateString != null)
      return new Date(dateString) < new Date();



   return false; 
  }

  getFlag(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/svg+xml;base64,${image}`
    );
  }
}
