<div>
  <div class="d-flex justify-content-start" style="width: 100%;">
    <div class="input-group mb-3 searchbar mr-2" style="width:29%;height: 2.5em;">
      <div class="input-group-prepend searchbarleft">
        <span class="input-group-text material-icons-outlined searchbarleft">search</span>
      </div>
      <input type="text" [(ngModel)]="searchQuery" class="form-control form-control-sm searchbarinput"
             (keyup.enter)="searchMessages($event)" placeholder="Search mail">
      <div >
        <span class="input-group-text material-icons-outlined searchbarright pointer" (click)="resetSearchFilter()"
              cdkOverlayOrigin #trigger="cdkOverlayOrigin">close</span>
      </div>
    </div>
 
  
    <div>
      <div style="display: flex;   margin-left: 1em; margin-top: -.6em;" align="end">
    
        <div style="margin-top:.5em; display:flex;   font-size:small">
          <div style="margin-top: -0.2em;"><b>Filter</b></div>
    
          <div style="display: flex; margin-left: 1em; ">
            <div style="margin-right:0.25em">
              <input id="fAll" type="radio" name="message_type" value="all" [checked]="searchFiltervalue==3"
                (change)="searchFilterChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap " for="fAll">All </label>
          </div>
    
          <div style="display: flex; margin-left: 1em; ">
            <div style="margin-right:0.25em">
              <input id="fFrom" type="radio" name="message_type" value="from" [checked]="searchFiltervalue==0"
                (change)="searchFilterChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap " for="fFrom">From </label>
          </div>
          <div style="display: flex; margin-left: 1em;">
            <div style="margin-right:0.25em">
              <input id="fTo" type="radio" name="message_type" value="to" [checked]="searchFiltervalue==1"
                (change)="searchFilterChange($event)" />
            </div>
            <label style="margin-top:-0.2em; white-space: nowrap" for="fTo">To </label>
          </div>
          <div style="display: flex; margin-left: 1em;">
            <div style="margin-right:0.25em">
              <input id="fSubject" type="radio" name="message_type" value="subject" [checked]="searchFiltervalue==2"
                (change)="searchFilterChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap" for="fSubject">Subject </label>
          </div>

 
          <div style="display: flex; margin-left: 1em;">
            <div style="margin-right:0.25em">
              <input id="fmessageGUID" type="radio" name="message_type" value="messageGUID" [checked]="searchFiltervalue==4"
                (change)="searchFilterChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap" for="fmessageGUID">Message ID </label>
          </div>

          <div style="display: flex; margin-left: 1em;">
            <div style="margin-right:0.25em">
              <input id="fassigned" type="radio" name="message_type" value="assigned" [checked]="searchFiltervalue==5"
                (change)="searchFilterChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap" for="fassigned">Assigned </label>
          </div>
        </div> 
        <div style="margin-top: -0.4em; margin-left: 0.5em">
          <mat-slide-toggle style="transform:scale(.9); height:2.5em; font-size: 0.9rem; margin-top: 0.1em;" color="primary" 
              [checked]="showFlagMessages" (change)="showFlaggedOnChange($event)"> Flagged Messages  
          </mat-slide-toggle>
        </div>
 
      </div>





      
      <div [hidden]="messageFolderID==1" style="display: flex;   margin-left: 1em; " align="end">
    
        <div style="margin-top:.5em; display:flex;   font-size:small">
          <div style="margin-top: -0.2em;"><b>View</b></div>
    
          <div style="display: flex; margin-left: 1em; ">
            <div style="margin-right:0.25em">
              <input id="f24h" type="radio" name="days_type" value="24h"  
                (change)="searchDateChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap " for="f24h">24 Hours </label>
          </div>

          <div style="display: flex; margin-left: 1em; ">
            <div style="margin-right:0.25em">
              <input id="f48h" type="radio" name="days_type" value="48h"  
                (change)="searchDateChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap " for="f48h">48 Hours </label>
          </div>
          
          <div style="display: flex; margin-left: 1em; ">
            <div style="margin-right:0.25em">
              <input id="f3d" type="radio" name="days_type" value="3d" [checked]="searchDateValue==4320"
                (change)="searchDateChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap " for="f3d">3 Days </label>
          </div>
          <div style="display: flex; margin-left: 1em;">
            <div style="margin-right:0.25em">
              <input id="f7d" type="radio" name="days_type" value="7d" 
                (change)="searchDateChange($event)" />
            </div>
            <label style="margin-top:-0.2em; white-space: nowrap" for="f7d">7 Days </label>
          </div>
          <div style="display: flex; margin-left: 1em;">
            <div style="margin-right:0.25em">
              <input id="f30d" type="radio" name="days_type" value="30d" 
                (change)="searchDateChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap" for="f30d">30 Days </label>
          </div>
          <div style="display: flex; margin-left: 1em; ">
            <div style="margin-right:0.25em">
              <input id="fd_All" type="radio" name="days_type" value="d_all" 
                (change)="searchDateChange($event)" />
            </div>
            <label style="margin-top:-0.2em;white-space: nowrap " for="fd_All">All </label>
          </div>
 
        </div>

        <!-- <div style="margin-top: -0.55em; margin-left: 0.5em">
          <mat-slide-toggle style="transform:scale(.9); height:2.5em" color="primary" 
              [checked]="showFlagMessages" (change)="showFlaggedOnChange($event)">Show Flagged Messages only 
          </mat-slide-toggle>
        </div> -->
     
      </div>
      <!-- <div style="margin-left: 4em;">
    
         <label style="font-size:small;margin-right:.5em;margin-top:.15em">All</label>
        <mat-slide-toggle color="primary" style="font-size:small" (change)="getData(true); getTripClientList(true);"
          [(ngModel)]="showAll" [ngModelOptions]="{standalone: true}">Last 72 Hours
        </mat-slide-toggle>
 
      </div> -->
      </div>

  
    <!-- Client Filter start -->
 
    <div  style="margin-top: -0.6em;" >
      <div *ngIf="messageFolderID == 12 || messageFolderID == 4" style="display: flex;">
        <div style=" margin-right:0.25em;">
          <button type="button" style=" margin-left: 1em;" mat-button class="btn text-#f8f8f8 btn-high" (click)="clickClientFilter()">
            <div style="display: flex;  "><div>{{selectedClientCountText}}</div></div>
          </button>&nbsp;
        </div>

      </div>
      <div *ngIf="messageFolderID == 12 || messageFolderID == 4" style="display:flex; min-width: 20em">
        <!--client list-->
        <div *ngIf="showClientList" style="position: absolute;float: left; min-width: 12em; margin-top: 0.25em; margin-left: 0em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: medium; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
          <div style="display:flex; justify-content:space-between">
            <div style="font-weight: bold; text-decoration:underline">Client Accounts</div>
            <div style="float:right" (click)="showClientList=!showClientList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
          </div>
          <div style="font-size: small; margin-left:0em">
            <div style="margin-right:0.5em;">
              <div style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                <div><i class="material-icons" style="font-size: medium; margin-top:0.25em; color: lightgray">search</i></div>
                <div [formGroup]="message_list_form">
                  <input type="text" formControlName="client_search" class="input-noborder" placeholder="Search" style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" (keyup)="clientSearchChange($event)" />
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="customerList.length>0" style="max-height: 20em;margin-top:0em; margin-bottom:-1.5em;" class="scroll-thin">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedClient">
              <div *ngFor="let c of customerList">
                <div #tripClientRefs [attr.customerGUID]="c.customerGUID" style="display: flex; margin-top:-0.1em;margin-bottom:-0.5em">
                  <mat-radio-button [value]="c" [checked]="c.selected == true" (change)="checkClientChange($event, c);clickDoneClient();">{{c.customerName}}</mat-radio-button>
                </div>
              </div>
            </mat-radio-group>

          </div>
          <!-- <div style="margin-top:0.5em; height:0.75em"></div> -->
          <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
          <mat-slide-toggle style="margin-left:-1em; transform:scale(.8);font-size:medium" color="primary" [checked]="includeTrips"
                            (change)="includeTripsChange($event)">Include All Trip Messages</mat-slide-toggle>

          <div style="display: flex; float: right">
            <div style="margin-right:0.5em">
              <button type="button" mat-button class="btn-low" (click)="clickResetClient()" style="height:2em">RESET</button>
            </div>
            <!-- <div>
              <button type="button" mat-button class="btn-high" (click)="clickDoneClient()" style="height:2em">DONE</button>
            </div> -->
          </div>
        </div>
        <!--client list end-->
      </div>
    </div>


    <!-- Client Filter end -->

    <div style="display: flex; justify-content: space-between;  margin-left: auto; " align="end">

      <!-- <div style=" margin-right:.25em; margin-left: 1em;">
        <button type="button" mat-button class="btn-low" style="min-width:8em;"
          (click)="resetSearchFilter()">Reset</button>
      </div> -->


      <div align="end">
        <button mat-button class="btn-low " style="margin-right: 1em;" (click)="getData(true)">
          <mat-icon>refresh</mat-icon>
          Refresh
        </button>
      </div>
    </div>



  </div>
  <!-- <p> a{{resultsLength | number}}</p> -->

  <div class="d-flex justify-content-between" style="margin-top: -1.05em;">
    <div class="d-inline-flex">
      <div *ngIf="selection.hasValue()" style=" display: flex; margin-top: -5px;">

        <!-- <button mat-icon-button *ngIf="selection.hasValue()" [matMenuTriggerFor]="menu" style="margin-top:-1em"><mat-icon style="font-size:medium;margin-top:-.5em;line-height:1.5">more_horiz</mat-icon></button> -->
        <button *ngIf="messageFolderID !=4 && messageFolderID !=12" mat-button class="btn" (click)="assignMe(true)" style="margin-left: -0.6em; margin-right: -0.6em;">Assign Me</button>
        <!--<button mat-button class="btn" (click)="assignMe(true, 'Fuel')" style="margin-left: -.6em; margin-right: -0.6em;">Assign Fuel</button>
        <button mat-button class="btn" (click)="assignMe(true,'Accounting')" style="margin-left: -.6em; margin-right: -0.6em;">Assign Accounting</button>
        <button mat-button class="btn" (click)="assignMe(true,'Travel')" style="margin-left: -.6em; margin-right: -0.6em;">Assign Travel</button>-->
        <button *ngIf="messageFolderID !=4 && messageFolderID !=12" mat-button class="btn" (click)="assignMe(false)" style="margin-left: -.6em; margin-right: -0.6em;">Remove Assigned</button>
        <!--<button  mat-button class="btn" (click)="markAsSpam()"><span *ngIf="messageFolderID == 7">Mark Not Spam</span><span *ngIf="messageFolderID != 7">Mark as Spam</span></button>-->
        <button mat-button class="btn" [matMenuTriggerFor]="folder" style="margin-left: -.6em; margin-right: -0.6em;">Move To Folder</button>
        <button mat-button class="btn" (click)="linkMessage(null)" style="margin-left: -.6em; margin-right: -0.6em;">Link </button>
        <button mat-button class="btn" (click)="flagMessages(true)" style="margin-left: -.6em; margin-right: -0.6em;"> Flag  </button>
        <button mat-button class="btn" (click)="flagMessages(false)" style="margin-left: -.6em; margin-right: -0.6em;"> UnFlag  </button>

        <mat-menu #folder="matMenu">
          <ng-template ngFor let-folder [ngForOf]="messageFolder">
            <button *ngIf="((folder.messageFolderID!=1 && folder.messageFolderID>7 && messageFolderID!=folder.messageFolderID) || (folder.messageFolderID>7 && messageFolderID!=folder.messageFolderID || folder.messageFolderID==1))" mat-menu-item (click)="moveFolder(folder.messageFolderID)">{{folder.messageFolderName}}</button>
          </ng-template>
        </mat-menu>
      </div>

      
    </div>      
    
    <!--<button mat-menu-item (click)="markAsSpam()"><span *ngIf="messageFolderID == 7">Mark Not Spam</span><span *ngIf="messageFolderID != 7">Mark as Spam</span></button>-->
    <!-- <div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="assignMe(true)">Assign Me</button>
        <button *ngIf="messageFolderID !=2 && messageFolderID !=4 && messageFolderID !=12" mat-menu-item (click)="assignMe(false)">Remove Assigned</button>
        <button mat-menu-item [matMenuTriggerFor]="folder">Move To Folder</button>
        <button mat-menu-item (click)="linkMessage(null)">Link messages</button>
      </mat-menu>
      <mat-menu #folder="matMenu">
        <ng-template ngFor let-folder [ngForOf]="messageFolder" >
          <button *ngIf="((folder.messageFolderID!=1 && folder.messageFolderID>7 && messageFolderID!=folder.messageFolderID) || (folder.messageFolderID>7 && messageFolderID!=folder.messageFolderID || folder.messageFolderID==1))" mat-menu-item (click)="moveFolder(folder.messageFolderID)">{{folder.messageFolderName}}</button>
        </ng-template>
      </mat-menu>
    </div> -->

    <mat-paginator style="background-color:transparent;margin-top:-1.5em" [pageSize]="50"
                   [showFirstLastButtons]="true"
                   [length]="resultsLength "
                   [pageSizeOptions]="[15,25, 50, 100]"
                   aria-label="Select page">
    </mat-paginator>
  </div>
  <div style="overflow:auto;margin-top:-.5em" [ngClass]="currentScreenSize">

    <table id="msg" mat-table matSort  matSortActive="created" matSortDisableClear matSortDirection="desc" [dataSource]="messageList">

      <ng-container matColumnDef="action1">
        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 3%;width:3%;">
          <mat-checkbox style="height:1.5em;width:1.5em;" (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>

        </th>
        <td mat-cell *matCellDef="let row" style="text-align:center !important;flex:0 0 3%;width:3%">
          <mat-checkbox style="height:1.5em;width:1.5em" (click)="$event.stopPropagation()"
                        (change)="$event ? checkBox(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
          <!-- <i *ngIf="row.isFlagged" class="material-icons-outlined" style="font-size: small; margin-right:-1em;color: red; ">flag</i> -->
          <i *ngIf="row.isFlagged" class="material-icons" style="font-size: small; margin-right: -1em; color: red;">flag</i>
          
         
        </td>
      </ng-container>
      <ng-container matColumnDef="assigned_to">
        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%">
          {{messageFolderID==4? 'Sent By ':'Assigned '}}
        </th>
        <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
          <span *ngIf="element.loading" class="spinner-border spinner-border-sm mr-1"></span>
          <div *ngIf="element.assignedTo.trim()=='' && !element.loading && element.messageFolderID!= 4" (click)="assignMessage(element,true)" style="cursor: pointer"><i class="material-icons-outlined" style="font-size: x-large; margin-top:0em;color: #7dacff;">add_circle</i></div>

          <div *ngIf="element.messageFolderID == 4" style="cursor: pointer;">
            {{element.actualSender? element.actualSender : ''}}
          </div>
          <div *ngIf="element.assignedTo.trim()!='' &&  element.messageFolderID!= 4" style="cursor: pointer">
            {{element.assignedTo}}
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="linkToTrip">
        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 6%;width:6%">
          Link
        </th>
        <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 6%;width:6%">
          <div *ngIf="element.tripCode=='' && !element.canAutolink" style="cursor: pointer">
            <i class="material-icons-outlined" (click)="linkMessage(element)" style="font-size: x-large; margin-top:0em;color: #7dacff;">add_circle</i>
          </div>
          <div *ngIf="element.tripCode!='' "
               matTooltipClass="tooltipLineBreak"
               matTooltip="{{ element.linkDetailsToolTip ?? (element.tripCode.split(',')[0]=='' || element.tripCode==''?element.accountId:element.tripCode.split(',')[0]) }}" (click)="openMessage(element)">
            {{element.tripCode.split(',')[0]=='' || element.tripCode==''?element.accountId:element.tripCode.split(',')[0]}}
          </div>
          <div *ngIf="element.canAutolink && element.tripCode == '' "
               matTooltipClass="tooltipLineBreak"
               matTooltip="{{ element.linkDetailsToolTip ?? (element.tripCode.split(',')[0]=='' || element.tripCode==''?element.accountId:element.tripCode.split(',')[0]) }}" (click)="openMessage(element)">
            <!-- <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" >AutoLink</a> -->
            <a style="cursor: pointer; color:blue; font-size: small; ">AutoLink</a>  <!--(click)="autoLink(element.messageKeyID, element.messageGUID)"-->
          </div>
          <!--<div *ngIf="element.messageKeyID!=null && element.tripCode == '' && element.messageFolderID !=1 " style="cursor: pointer">
      <i class="material-icons-outlined" (click)="linkMessage(element)" style="font-size: x-large; margin-top:0em;color: #7dacff;">add_circle</i></div>-->

        </td>
      </ng-container>

      <ng-container matColumnDef="date_time">
        <th *matHeaderCellDef mat-header-cell mat-sort-header disableClear style="text-align:left !important;flex:0 0 7%;width:7%">
          {{messageFolderID==5? 'Scheduled Delivery ':'Date (z)'}}
        </th>
        <td mat-cell *matCellDef="let element" style="text-align:left !important;flex:0 0 7%;width:7%" (click)="openMessage(element)">{{element.isPending? element.scheduleMessage: element.receivedDateTime==null? element.sentDateTime: element.receivedDateTime }}
          <!--<i *ngIf="element.isDelayed" class="material-symbols-outlined" title="Delayed" style="font-size: small; margin-right: -1em; color: red;">error</i>--></td>
      </ng-container>
      <ng-container matColumnDef="sender">
        <th mat-header-cell *matHeaderCellDef style="text-align:left !important;flex:0 0 15%;width:15%">
          Sender - Recipient
        </th>
        <td mat-cell style="justify-content:left !important;flex:0 0 15%;width:15%" *matCellDef="let element" (click)="openMessage(element)">
          <span class="ellipsis">
            {{element.senderName?.split(';')[0]}}
          </span>
        </td>
        <!-- {{
          (element.senderName.length>0 &&
            element.senderName.length>30 )? element.senderName.substr(0,30) : element.senderName
          }} -->
      </ng-container>
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef style="justify-content:left !important;flex:0 0 28%;width:28%; padding-right: 2em !important;">
          Subject
        </th>
        <td mat-cell style="justify-content:left !important;flex:0 0 28%;width:28%; padding-right: 2em !important;" *matCellDef="let element"
            (click)="openMessage(element)">
          <span class="ellipsis">
            {{element.subject   }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="body">
        <th mat-header-cell *matHeaderCellDef style="vertical-align: middle !important; justify-content: left !important; flex: 0 0 30%; width: 30%">
          <div class="d-flex">
            Body
            <mat-progress-bar mode="indeterminate" style="width:30%;margin-left:.5em;margin-top:.75em" color="primary" *ngIf="showSpin"></mat-progress-bar>
          </div>
        </th>
        <td mat-cell style="text-align:left !important;flex:0 0 30%;width:30%" *matCellDef="let element" (click)="openMessage(element)"><span class="ellipsis">{{element.bodyPreview=='' ? element.bodyTxt : element.bodyPreview}}</span></td>
      </ng-container>
      <ng-container matColumnDef="has_attachments">
        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 2%;width:2%"></th>
        <td mat-cell *matCellDef="let element; let i=index" style="text-align:center !important;flex:0 0 2%;width:2%" (click)="openMessage(element)">
          <span *ngIf=element.hasAttachments class="material-symbols-outlined" style="transform:rotate(-45deg);font-size:medium">
            attachment
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="action2">
        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 3%;width:3%"></th>
        <td mat-cell *matCellDef="let element; let i=index" style="text-align:center !important;flex:0 0 3%;width:3%">
          <span class="material-icons-outlined" style="cursor:pointer;font-size:medium" (click)="openMessage(element)">
            open_in_new
          </span>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumn;sticky:true"></tr>
      <tr mat-row [ngClass]="row.messageGUID == selectedMessageGUID ? 'active' : ''" *matRowDef="let row; columns: displayedColumn;"></tr>

    </table>
  </div>
</div>
