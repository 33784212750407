<div *ngIf="ua==1 || ut=='i'">
  <div style="margin-top:0em; height:21.5em">
    <form *ngIf="user_permission_registration_form" [formGroup]="user_permission_registration_form">
      <div style="font-size: small; display: flex">
        <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkAllRegistration" style="transform: scale(0.75)" (change)="checkAllRegistrationChange($event)"></mat-checkbox>
        <div style="margin-top:-0.25em"><label for="checkAllRegistration" style="font-size:small">&nbsp;Grant Access to All Registrations</label></div>
      </div>
      <div *ngIf="f.checkAllRegistration.value==false" style="margin-left: 1em">
        <div style="font-size: small; margin-left:0em; margin-top:-0.5em">Select Access to Registration</div>
        <div style="display: flex">
          <div style="height: 19em;width:15em; overflow-y: auto;overflow-x:hidden">
            <table mat-table *ngIf="avaiAircraftList" [dataSource]="avaiAircraftList" class="example-container2 mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="registration">
                  <th mat-header-cell *matHeaderCellDef style="background-color:#f8f8f8;">
                    Registration
                  </th>
                  <td mat-cell *matCellDef="let element"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickAddItem($event, element)">{{element.registration}}</a></td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumnAC; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnAC" style="height:1.5em !important;"></tr>
            </table>

          </div>
          <div style="margin-top: 2em; margin-left:2em">
            <button type="button" mat-button class="btn-high" style="width: 7em; font-size: small;height:1.875em;padding-top:0em" [hidden]="avaiAircraftList && avaiAircraftList.length==0" (click)="clickSelectAll()">Select All</button><br />

            <button type="button" mat-button class="btn-low" style="width: 7em; font-size: small;height:1.875em;padding-top:0em;margin-top:1em" [hidden]="selAircraftList && selAircraftList.length==0" (click)="clickRemoveAll()">Remove All</button>
          </div>

          <div style="height: 19em;width:15em; overflow-y: auto;overflow-x:hidden; margin-left:2em">
            <table mat-table *ngIf="selAircraftList" [dataSource]="selAircraftList" class="example-container2 mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="registration">
                  <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8; ">
                    Assigned Access
                  </th>
                  <td mat-cell *matCellDef="let element"><a style="cursor:pointer; text-decoration:underline; color: blue" (click)="clickRemoveItem($event, element)">{{element.registration}}</a></td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumnAC; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnAC" style="height:1.5em !important;"></tr>
            </table>

          </div>

        </div>
      </div>
    </form>
  </div>

  <div style="padding-left: 0em; padding-top:0em; padding-bottom:0.5em;display:flex; ">
    <div style="margin-right:1em">
      <button mat-button class="btn-high" (click)="clickSave()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Save
      </button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white">&nbsp;Close&nbsp;</button>
    </div>
    <div *ngIf="showSuccessMsg" style="margin-top:-0.25em">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
    <div style="margin-left:0em; margin-top:0em;">
      <div *ngIf="errMsg!=''" style="background-color: #f9d2de; padding-left: 0.25em; width: 18em; font-size: small">{{errMsg}}</div>
    </div>
  </div>
</div>

