import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GroundStopModel } from '../models/ground-stop.model';
import { DialogService } from '../services/dialog.service';
import { CompileFlightBriefDialogComponent } from './compile-flight-brief-dialog.component';
import { FlightBriefDocumentsComponent } from './flight-brief-documents.component';
import { FlightBriefSectionsComponent } from './flight-brief-sections.component';
import { WeatherChartsDialogComponent } from './weather-charts-dialog.component';

@Component({
    selector: 'app-build-flight-brief-dialog',
    templateUrl: './build-flight-brief-dialog.component.html',
    styleUrls: ['./build-flight-brief-dialog.component.css']
})
/** build-flight-brief-dialog component*/
export class BuildFlightBriefDialogComponent {
  selectedTabName: string = "weather";
  @Input() gstId: string;
  @Input() tripCodeGUID: string;
  @Input() selectedStops: GroundStopModel[];
  @Input() tripCode: string;
  @Input() registration: string;
  @Input() icao: string;
  @Input() weatherChartIds: string
  @Input() route: string
  @Input() serviceTypeID: number
  reportName: string = "Flight Brief";
  @ViewChild('weatherCharts') weatherChartsRef: WeatherChartsDialogComponent;
  @ViewChild('briefSections') briefSectionsRef: FlightBriefSectionsComponent;
  @ViewChild('briefDocuments') briefDocumentsRef: FlightBriefDocumentsComponent;
  @ViewChild('compileBrief') compileBriefRef: CompileFlightBriefDialogComponent;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;


    /** build-flight-brief-dialog ctor */
  constructor(@Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialogService:DialogService) {
    this.gstId = _data.gstId;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.selectedStops = _data.selectedStops;
    this.icao = _data.icao;
    this.tripCode = _data.tripCode;
    this.registration = _data.registration;
    this.weatherChartIds = _data.weatherChartIds;
    this.route = _data.route;
    this.serviceTypeID = _data.serviceTypeID;
    if (this.serviceTypeID == 79) {
      this.selectedTabName = "sections";
      this.reportName = "Trip Brief";
    }
    if (this.serviceTypeID == 32 || this.serviceTypeID == 85 || this.serviceTypeID == 87)
      this.reportName = "Wx Outlook";
  }

  clickTab(s: string) {

    switch (this.selectedTabName) {
      case "weather":
        if (this.weatherChartsRef.hasChanges) {
          this.confirmDialogRef = this._dialogService.openConfirmDialog("You have unsaved changes. Would you like to save before continuing?");
          this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            this.eventSubscription.unsubscribe();
            if (result) {
              this.weatherChartsRef.saveWeatherCharts(false);
              if (this.selectedTabName != s)
                this.selectedTabName = s;
            }
            else {
              if (this.selectedTabName != s) {
                this.selectedTabName = s;
              }
            }
          });
        }
        else {
          if (this.selectedTabName != s) {
            this.selectedTabName = s;
          }
        }
        break;
      case "sections":
        if (this.briefSectionsRef.documentList == undefined)
          this.briefSectionsRef.documentList = [];
        if (this.briefSectionsRef.selectedDocuments == undefined)
          this.briefSectionsRef.selectedDocuments = [];
        if (!(this.briefSectionsRef.initSelectedSections.filter(item => this.briefSectionsRef.flightBriefSectionList.filter(v=>v.selected==true).indexOf(item) < 0).length == 0 &&
          this.briefSectionsRef.flightBriefSectionList.filter(v => v.selected == true).filter(item => this.briefSectionsRef.initSelectedSections.indexOf(item) < 0).length == 0 && this.briefSectionsRef.selectedDocuments.filter(item => this.briefSectionsRef.documentList.filter(v=>v.selected==true).indexOf(item) < 0).length == 0 &&
          this.briefSectionsRef.documentList.filter(v => v.selected == true).filter(item => this.briefSectionsRef.selectedDocuments.indexOf(item) < 0).length == 0)) {
          this.confirmDialogRef = this._dialogService.openConfirmDialog("You have unsaved changes. Would you like to save before continuing?");
          this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            this.eventSubscription.unsubscribe();
            if (result) {
              this.briefSectionsRef.clickSave(false);
              if (this.selectedTabName != s)
                this.selectedTabName = s;
            }
            else {
              if (this.selectedTabName != s) {
                this.selectedTabName = s;
              }
            }
          });
        }
        else {
          if (this.selectedTabName != s) {
            this.selectedTabName = s;
          }
        }
        break;
      case "documents":
        if (this.briefDocumentsRef.documentList == undefined)
          this.briefDocumentsRef.documentList = [];
        if (this.briefDocumentsRef.selectedDocuments == undefined)
          this.briefDocumentsRef.selectedDocuments = [];
        if (!(this.briefDocumentsRef.selectedDocuments.filter(item => this.briefDocumentsRef.documentList.filter(v=>v.selected==true).indexOf(item) < 0).length == 0 &&
          this.briefDocumentsRef.documentList.filter(v => v.selected == true).filter(item => this.briefDocumentsRef.selectedDocuments.indexOf(item) < 0).length == 0)) {
          this.confirmDialogRef = this._dialogService.openConfirmDialog("You have unsaved changes. Would you like to save before continuing?");
          this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            this.eventSubscription.unsubscribe();
            if (result) {
              this.briefDocumentsRef.clickSave(false);
              if (this.selectedTabName != s)
                this.selectedTabName = s;
            }
            else {
              if (this.selectedTabName != s) {
                this.selectedTabName = s;
              }
            }
          });
        }
        else {
          if (this.selectedTabName != s) {
            this.selectedTabName = s;
          }
        }
        break;
      default:
        if (this.selectedTabName != s) {
          this.selectedTabName = s;
        }
    }

  }

}
