import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AirportNotesModel } from '../models/airport-notes.model';
import { AuthenticateService } from '../services/authenticate.service';
import { AirportService } from '../services/airport.service';
import { ResponseModel } from '../models/response.model';
import { AirportNotesEditDialogComponent } from './airport-notes-edit-dialog.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-airport-notes',
  templateUrl: './airport-notes.component.html',
  styleUrls: ['./airport-notes.component.css']
})
export class AirportNotesComponent implements OnInit, OnChanges {
  @Input() airportID: number;
  @Input() isActive: boolean;
  airportNotes: AirportNotesModel[];
  airportNotesAll: AirportNotesModel[];
  errMsg: string = "";
  showSpin: boolean = false;
  clickedRows = new Set<AirportNotesModel>();
  airportNotesColumns: string[] = ['category', 'note', 'revisionDate', 'br', 'action'];

  constructor(private readonly _authService: AuthenticateService, private readonly _airportService: AirportService, private readonly _dialog: MatDialog,) {

  }
  ngOnChanges(changes: SimpleChanges) {
      if (this.airportNotesAll)
      this.airportNotes = this.airportNotesAll.filter(x => x.isActive === this.isActive);
    }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.getData();
  }
  getData() {
    this._airportService.getAirportNotesByAirportID<ResponseModel<AirportNotesModel[]>>(this.airportID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.airportNotesAll = response.model;
          this.airportNotes = this.airportNotesAll.filter(x => x.isActive === this.isActive);
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMsg = "Error gettings notes.";
        }
      }
    });
  }

  clickEdit(e: any, note: AirportNotesModel) {
   
     // this.clickedRows = new Set<AirportNotesModel>();
      this.clickedRows.add(note);
    let s = "";
    let airportNoteID = 0;
    if (note == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      airportNoteID = note.airportNotesID;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, airportID: this.airportID, airportNoteID: airportNoteID };
    const dialogRef = this._dialog.open(AirportNotesEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (this.clickedRows.has(note))
        this.clickedRows.delete(note);
      if (result) {
        this.getData();
      }

    });
  }

  isExpired(revisionDate): boolean {
    if (revisionDate == null || revisionDate == "" || revisionDate == undefined)
      return false;
    let rd = new Date(revisionDate);
    let cd = new Date();
    if (rd.getTime() < cd.getTime())
      return true;
    else
      return false;
  }

}
