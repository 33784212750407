import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { CustomerModel } from '../models/customer.model';
import { TripLegModel } from '../models/trip-leg.model';
import { VendorModel } from '../models/vendor.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
import { AirportDetailsComponent } from '../airports/airport-details.component';

@Component({
  selector: 'app-incomming-trip-leg',
  templateUrl: './incomming-trip-leg.component.html',
  styleUrls: ['./incomming-trip-leg.component.css']
})

export class IncommingTripLegComponent implements OnInit {
  userType: string;
  customerGUID: string;
  flight_watch_form: UntypedFormGroup;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  customerList: CustomerModel[];

  v: number = this._authService.getCurrentTimeNumber();
  queryHr: number;
  queryHrText: string; 
  queryHourList: Array<number>;
  queryIndex: number;
  totalRecordsText: string;
  recordList: TripLegModel[];
  displayedColumn: string[] = ['registration', 'departure', 'arrival', 'tripCode', 'clientName', 'action'];

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService) {

  }

  getAllData(): Observable<any[]> {
    if (this.userType == "internal") {
      let getClientListResponse = this._groundStopService.getClientListWithTrip();
      return forkJoin([getClientListResponse]);
    }
    else {
      return forkJoin([null]);
    }

  }

  ngOnInit() {  
    this._authService.updateAccessTime();   
    this.errMsg = "";
    this.msg = "";      
    this.totalRecordsText = "";
    this.recordList = [];
    this.queryHourList = [12, 24, 48, 72, 168, 336];
    this.queryIndex = 0;
    this.queryHr = this.queryHourList[this.queryIndex];
    if (this.queryHr <= 72) {
      this.queryHrText = this.queryHr.toString() + " hrs";
    }
    else {
      this.queryHr = this.queryHr / 24;
      this.queryHrText = this.queryHr.toString() + " days";
    }
    this.customerGUID = "";
    this.customerList = [];
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getAllData().subscribe(responses => {
      if (this.userType == "internal") {
        if (responses[0] != null) {
          if (responses[0].code == "200" && responses[0].message == "") {
            this.customerList = responses[0].model;
          }
          else {
            if (responses[0].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }     

      this.initControls();

      this.getData();
      

    });


  }

  initControls() {
    this.flight_watch_form = this._formBuilder.group({
      customer_select: [this.customerGUID],   
      includeDemo: [false],
      queryHour: [this.queryIndex]
    });

  }

  get f() { return this.flight_watch_form.controls; }

  
  querySlideChange(e: any) {
    this.queryIndex = e.target.value;
    
    this.queryHr = this.queryHourList[this.queryIndex];
    if (this.queryHr <= 72) {
      this.queryHrText = this.queryHr.toString() + " hrs";
    }
    else {
      let temoquery: number = this.queryHr / 24;
      this.queryHrText = temoquery.toString() + " days";
    }
    this.getData();
  }

  customerChange() {
    this.customerGUID = this.f.customer_select.value;
    this.getData();
  }

  includeDemoOnChange(e: any) {
    //this.includeDemo = e.checked; // mat checkbox
    this.getData();
    
  }

  getData() {
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.totalRecordsText = "";
    let request = new TripLegModel();
    request.queryHour = this.queryHr;
    request.includeDemo = this.f.includeDemo.value;
    request.customerGUID = "";
    if (this.userType == "internal") {
      request.customerGUID=this.customerGUID;
    }
    //this._groundStopService.getIncommingTripLegByHourcId<ResponseModel<TripLegModel[]>>(request).subscribe(response => {
    //  this.recordList = [];
    //  this.totalRecordsText = "";
    //  if (response != null && response.code == "200") {
    //    if (response.model.length > 0) {
    //      this.recordList = response.model;
    //      if (this.recordList.length > 1) {
    //        this.totalRecordsText = this.recordList.length.toString() + " Records Found";
    //      }
    //      else {
    //        this.totalRecordsText = this.recordList.length.toString() + " Record Found";
    //      }

    //    }
    //    else {
    //      this.totalRecordsText = "No record found";

          
    //    }
    //    this.showSpin = false;
    //    //this.sendDataToParent.emit({ totalRecordsText: this.totalRecordsText });
    //  }
    //  else {
    //    if (response.code == "401") {
    //      //this.errMsg = response.message
    //      this._authService.signOut();
    //    }
    //    else {
    //      this.errMsg = "Failed to get trips at this time. Please try later.";
    //    }
    //  }
    //  this.showSpin = false;
    //});

  }

  clickRefresh() {
    
    this.getData();
    
  }

  clickOpen(e:any, item:TripLegModel) {
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "tripId": item.tripCodeGUID,
    //    "aircraftId": item.aircraftGUID,
    //    "customerId": item.customerGUID,
    //    "pf": "",
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID+"&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
  }

  clickVendorName(e: any, itemLeg: TripLegModel, itemVendor:VendorModel, stopType: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    let icao: string;
    if (stopType == "D") {
      icao = itemLeg.departureICAO;
    }
    else {
      icao = itemLeg.nextArrivalICAO;
    }

    dialogConfig.data = {
      vendorId: itemVendor.vendorGUID,
      icao: icao
    };
    this._dialog.open(VendorDetailsComponent, dialogConfig);
  }

  clickICAO(e: any, gs: TripLegModel, legType: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    if (legType == "D") {
      dialogConfig.data = {
        icao: gs.departureICAO,
        icaoIata: gs.departureICAO_IATA,
        groundStopGUID: gs.groundStopGUID,
        tripCodeGUID: gs.tripCodeGUID
      };
    }
    if (legType == "A") {
      dialogConfig.data = {
        icao: gs.nextArrivalICAO,
        icaoIata: gs.nextArrivalICAO_IATA,
        groundStopGUID: gs.nextArrivalGroundStopGUID,
        tripCodeGUID: gs.tripCodeGUID
      };
    }

    this._dialog.open(AirportDetailsComponent, dialogConfig);
  }

}
