import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { StarRatingColor } from './star-rating.component';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { TripModel } from '../models/trip.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { CommonService } from '../services/common.service';
import { FeedbackModel } from '../models/feedback.model';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.css']
})

export class FeedbackDialogComponent implements OnInit {
 
  feedback_dialog_form: UntypedFormGroup;
  // loading = false;
  // v: number = this._authService.getCurrentTimeNumber(); 
  // controlValueChanged: boolean = false;
 
  rating:number = 0;
  starCount:number = 5;
  starColor:StarRatingColor = StarRatingColor.accent;
  starColorP:StarRatingColor = StarRatingColor.primary;
  starColorW:StarRatingColor = StarRatingColor.warn;

  showColorLike: boolean = false;
  showColorDislike: boolean = false;
  iconNow: string = 'thumb_up';
  isClientRequestFollowup: boolean = null;
  feedbackUserServiceExperienceNote: string = '';
  serviceTypeDesc: string = '';
  icao_iata: string = '';
  registration: string = '';
  tripCode: string = '';
  arrivalDateTime: string = '';
  departureDateTime: string = '';
  isTaskFeedback: boolean = false;
  airportDetails: string = '';
  selectedTaskStatusGUID: string = '';
  customerGUID: string = '';
  tripCodeGUID: string = '';
  aircraftGUID: string = '';
  groundStopGUID: string = '';
  groundStopTaskGUID: string = '';

  
  routeLegList: GroundStopModel[] = [];
  activeGroundStopList: GroundStopModel[] = [];
  allGroundStopList: GroundStopModel[] = [];
  airportID: number;
  countryID: number;
  ratingExperience: string = 'Not Rated';
  showSuccessMsg: boolean = false;
  isModified: boolean = false;
  errMessage: string = '';
  loading: boolean = false;
  successMsg: string = 'Thanks For The Feedback.';
  selectedCountryName: string = '';
  selectedVendorName: string = '';
  serviceLocation: string = '';


 
  
  constructor(private readonly _dialogRef: MatDialogRef<FeedbackDialogComponent>, @Inject(MAT_DIALOG_DATA) _data: any,
  private readonly _formBuilder: UntypedFormBuilder, private readonly _groundStopClientService: GroundStopClientService,
  private readonly _authService: AuthenticateService, private readonly _commonService: CommonService, 
    ) {
   
    // this.userComments = _data.userComments;
    this.isTaskFeedback = _data.isTaskFeedback;
    if (_data.isTaskFeedback != null && _data.isTaskFeedback != false ){

      this.serviceTypeDesc = _data.serviceTypeDesc;
      this.icao_iata = _data.icao_iata;
      this.tripCode = _data.tripCode;
      this.registration = _data.registration;
      this.arrivalDateTime = _data.arrivalDateTime == ''? 'TBA':_data.arrivalDateTime;

      this.departureDateTime =  _data.departureDateTime == ''? 'TBA': _data.departureDateTime;

      let  dateDetails = this.serviceLocation == 'A'? this.arrivalDateTime : this.departureDateTime; 

      this.airportDetails = _data.icao_iata +      '  -  ' +  dateDetails;


      this.selectedTaskStatusGUID = _data.selectedTaskStatusGUID;
      this.customerGUID = _data.customerGUID;

      this.tripCodeGUID = _data.tripCodeGUID;
      this.aircraftGUID = _data.aircraftGUID;
      this.groundStopGUID = _data.groundStopGUID;
      this.groundStopTaskGUID = _data.groundStopTaskGUID;
      this.airportID = _data.airportID;
      this.countryID = _data.countryID;
      this.selectedCountryName = _data.selectedCountryName;
      this.selectedVendorName = _data.selectedVendorName;
      this.serviceLocation = _data.serviceLocation;
 

     }

    // if (_data.serviceTypeDesc != null)
    //   this.serviceTypeDesc = _data.serviceTypeDesc;

    // if (_data.icao_iata != null)
    //   this.icao_iata = _data.icao_iata;

    // if (_data.tripCode != null)
    //   this.tripCode = _data.tripCode;

    // if (_data.registration != null)
    //   this.registration = _data.registration;

    // if (_data.arrivalDateTime != null)
    //   this.arrivalDateTime = _data.arrivalDateTime;

    // if (_data.departureDateTime != null)
    // this.departureDateTime = _data.departureDateTime;
  

  }

  ngOnInit() {

    // this._authService.updateAccessTime();

    this.getRouteList();
    this.initControls();

  }

  onRatingChanged(rating) {
    // console.log(rating);

    this.rating = rating;
    switch (rating) {
      case 0: this.ratingExperience = 'Not Rated';
               break;
      case 1: this.ratingExperience = 'Bad Experience';
               break;
      case 2: this.ratingExperience = 'Issues To Report';
               break;
      case 3: this.ratingExperience = 'Good';
               break;
      case 4: this.ratingExperience = 'Very Good';
               break;
      case 5: this.ratingExperience = 'Excellent';
               break;
    
      default: this.ratingExperience = 'Not Rated';
        break;
    }
  }

  initControls() {
    this.feedback_dialog_form = this._formBuilder.group({
      // userComments: [this.userComments]
    });


  }

  get f() { return this.feedback_dialog_form.controls; }


  showIconLike() {
    this.showColorLike = true;
    this.showColorDislike = false;
    this.isClientRequestFollowup = true;
 
  }

  showIconDisike() {
    this.showColorDislike = true;
    this.showColorLike = false;
    this.isClientRequestFollowup = false;

  }

  clickSubmit(){

    let request = new FeedbackModel();
 

    request.tripCode = this.tripCode;
    request.registration = this.registration;
    request.taskName = this.serviceTypeDesc;
    request.airportDetails = this.airportDetails;
    request.tripCodeGUID = this.tripCodeGUID;
    request.aircraftGUID = this.aircraftGUID;
    request.customerGUID = this.customerGUID;

    request.goundStopGUID = this.groundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    // request.customerName = this.customerName;



    // request.taskServiceSupportNames = this.taskServiceSupportNames;
    // request.whoWillFollowUp = this.whoWillFollowUp;
    // request.followupDate = this.followupDate;
    // request.Submittedby = this.submittedby;


    if(this.isClientRequestFollowup == null){
      this.isClientRequestFollowup = false;
    }
    request.isClientRequestFollowup = this.isClientRequestFollowup; 
    request.airportID = this.airportID;
    request.countryID = this.countryID;
     

    request.countryName = this.selectedCountryName;
    request.vendorName = this.selectedVendorName;
    request.serviceLocation = this.serviceLocation;
    
    request.feedbackUserServiceExperienceNote = this.feedbackUserServiceExperienceNote;
    request.feedbackUserRating = this.rating;
    
    request.lk_FeedbackSourceID = 1;
    request.lk_FeedbackTypeID = 1;
    request.lk_FeedbackIssueTypeID = 1;
    request.lk_FeedbackPriorityID = 1;
    request.lk_FeedbackStatusID = 1;
    
    request.assignedTo = null;
    request.isTaskFeedback = this.isTaskFeedback;
    this.loading = true;


    // if(this.customerGUID == '' || this.customerGUID == null){
    //   request.customerGUID = '315A32C7-B00A-4EB1-84A8-A358725E9B90';
    // }

    this._commonService.insertUpdateFeedback<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          // alert('Success: ' + response.model)
          this.isModified = true;
          
          this.showSuccessMsg = true;
          setTimeout(() => {
            this._dialogRef.close(this.isModified);
            this.showSuccessMsg = false;

          }, 1500);

          
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMessage = "An error has occured."
        }
      }
      this.loading = false;
    });

  }

  clickClose(val) {
    this._dialogRef.close(val);
    // if (this.controlValueChanged == true) {
    //   this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save the change before close?");
    //   this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
    //     this.eventSubscription.unsubscribe();
    //     if (result == true) {
    //       //this.clickSave();
    //     }
    //     else {
    //       this._dialogRef.close(this.returnObj);
    //     }
    //   });
    // }
    // else {
    //   this._dialogRef.close(this.returnObj);
    // }

  }

  getRouteList(){
    let request = new AircraftTripModel();

    
    request.aircraftGUID = this.aircraftGUID;
    request.customerGUID = this.customerGUID;
    request.startDateText = localStorage.getItem('trip.sd');
    request.endDateText = localStorage.getItem('trip.ed');
    request.tripCodeGUID = this.tripCodeGUID;

      
    this._groundStopClientService.getTripBytId<ResponseModel<TripModel>>(request).subscribe(response => {


      if ( response != null) {
        if (response.code == "200" && response.message == "") {
          let t = new TripModel();
          t = response.model;
          this.allGroundStopList = t.groundStopList;
          this.activeGroundStopList = t.activeGroundStopList;
          // if (this.includeActiveStopOnly) {
            this.routeLegList = this.activeGroundStopList;
          // }
          // else {
          //   this.routeLegList = this.allGroundStopList;
          // }
        } else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }

    });

  }

}






 





 