export class UnitofmeasureModel {
  //lk_unitofmeasureid: string;
  //unitofmeasure: string;

  lk_UnitOfMeasureID: string;
  unitOfMeasure: string;
  isActive: string;
  modifiedBy: string;
  modifiedDate: Date;

  selected: boolean;
}
