import { ApisFlightManifestModel } from "./apis-flight-manifest";
import { ApisForeignAirportModel } from "./apis-foreign-airport";
import { ApisSubmissionModel } from "./apis-submission";

export class ApisManifest {
  tripCodeGUID: string;
  groundStopGUID: string;
  groundStopSequenceId: number;
  groundStopTaskGUID: string;
  emergencyContactLast: string;
  emergencyContactFirst: string;
  emergencyContactPhone: string;
  emergencyContactFax: string;
  emergencyContactEmail: string;
  customerName: string;
  registration: string;
  callSign: string;
  aircraftColor: string;
  aircraftType: string;
  cbpDecal: string;
  departureICAO: string;
  departureIATA: string;
  departureCity: string;
  departureState: string;
  borderCrossing: string;
  departureCountryCode: string;
  departureDateLocal: string;
  departureTimeLocal: string;
  departureDateLocalDateFormat: Date;
  departureTimeLocalTimeFormat: Date;
  departurePlaceDescription: string;
  arrivalICAO: string;
  arrivalIATA: string;
  arrivalCity: string;
  arrivalState: string;
  arrivalCountryCode: string;
  arrivalDateLocal: string;
  arrivalTimeLocal: string;
  arrivalDateLocalDateFormat: Date;
  arrivalTimeLocalTimeFormat: Date;
  arrivalPlaceDescription: string;
  operatorCompany: string;
  operatorFirstName: string;
  operatorMiddleName: string;
  operatorLastName: string;
  operatorAddress1: string;
  operatorAddress2: string;
  operatorCity: string;
  operatorStateProvince: string;
  operatorCountryCode: string;
  operatorZip: string;
  operatorPhone: string;
  operatorFax: string;
  operatorEmail: string;
  ownerCompany: string;
  ownerFirstName: string;
  ownerMiddleName: string;
  ownerLastName: string;
  ownerAddress1: string;
  ownerAddress2: string;
  ownerCity: string;
  ownerStateProvince: string;
  ownerCountryCode: string;
  ownerZip: string;
  ownerPhone: string;
  ownerFax: string;
  ownerEmail: string;
  foreignAirports: ApisForeignAirportModel[];
  crewList: ApisFlightManifestModel[];
  paxList: ApisFlightManifestModel[];
  departureIsApisApproved: boolean;
  arrivalIsApisApproved: boolean;
  deptApprovedAirport: string;
  arrvApprovedAirport: string;
  tripCode: string;
  farTypeID: number;
  carrierCode: string;
  isPrivate: boolean;
  flightNumber: string;
  isInOneHour: boolean;
  apisTypeID: number;
  revisionNumber: number;
  apisSubmissions: ApisSubmissionModel[];
  referenceNumber: string;
  isInbound: boolean;
  showApisReportFirst: boolean;
}
