<div style="background-color: #cc0000">
  <img src="../../assets/images/fpilogo.png" style="width: 230px; height: 80px;" />
</div>
<div class="nav" style="width:250px">
  <div class="multi-level">
    <ul class="expanded">
      <li *ngFor="let m of menus" class="menuItem">
        <a *ngIf="m.route!=null" routerLink="{{m.route}}">{{m.display}}</a>
        <app-menu-item *ngIf="m.route==null" [menu]="m"></app-menu-item>
      </li>
    </ul>
  </div>
</div>



