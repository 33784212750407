<div mat-dialog-content style="margin-top:-1em;padding-bottom:0;">

  <form novalidate *ngIf="flight_brief_sections_form" [formGroup]="flight_brief_sections_form">

    <div class="container" style="background-color:white;min-width:35em;padding-top:1em;padding-bottom:1em;margin-top:-.5em">
      <app-spin *ngIf="showSpin"></app-spin>

      <div>
        <div class="d-flex">
          <div>
            <h6>Sections</h6>
            <div>
              <mat-checkbox class="example-margin"
                            [checked]="allCompleteSection"
                            [indeterminate]="someComplete('section')"
                            (change)="setAll($event.checked,'section')">
                Select All
              </mat-checkbox>
            </div>
            <div class="d-flex">
              <div>
                <div class="d-flex flex-column" *ngFor="let sec of flightBriefSectionList, let i=index">
                  <mat-checkbox *ngIf="i<flightBriefSectionList.length/2 || serviceTypeID==79" (change)="checkFlightBriefSection($event,sec)" (click)="$event.stopPropagation()" [checked]="selectionSection.isSelected(sec)">{{sec.sectionName}}</mat-checkbox>
                </div>
              </div>
              <div class="ml-2" *ngIf="serviceTypeID!=79">
                <div class="d-flex flex-column" *ngFor="let sec of flightBriefSectionList, let i=index">
                  <mat-checkbox *ngIf="i>=flightBriefSectionList.length/2" (change)="checkFlightBriefSection($event,sec)" (click)="$event.stopPropagation()" [checked]="selectionSection.isSelected(sec)">{{sec.sectionName}}</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="serviceTypeID==32 || serviceTypeID==85 || serviceTypeID==87" class="ml-4">
            <h6>Prelim Plan Notes</h6>
            <div>
              <mat-checkbox class="example-margin"
                            [checked]="allCompletePrelim"
                            [indeterminate]="someComplete('prelim')"
                            (change)="setAll($event.checked,'prelim')">
                Select All
              </mat-checkbox>
            </div>
            <div class="d-flex">
              <div>
                <div class="d-flex flex-column" *ngFor="let task of prelimNoteList, let i=index">
                  <mat-checkbox (change)="checkPrelim($event,task)" (click)="$event.stopPropagation()" [checked]="selectionPrelimTask.isSelected(task)">{{task.serviceTypeDescription + ' ' + task.fpiConfirmationReference}}</mat-checkbox>
                </div>
              </div>              
            </div>
          </div>
          <div class="ml-4">
            <h6>Task Notes</h6>
            <div>
              <mat-checkbox class="example-margin"
                            [checked]="allCompleteTask"
                            [indeterminate]="someComplete('task')"
                            (change)="setAll($event.checked,'task')">
                Select All
              </mat-checkbox>
            </div>
            <div class="d-flex">
              <div>
                <div class="d-flex flex-column" *ngFor="let task of taskNoteList, let i=index">
                  <mat-checkbox *ngIf="i<taskNoteList.length/2" (change)="checkTask($event,task)" (click)="$event.stopPropagation()" [checked]="selectionTask.isSelected(task)">{{task.serviceTypeDescription}}</mat-checkbox>
                </div>
              </div>
              <div class="ml-2">
                <div class="d-flex flex-column" *ngFor="let task of taskNoteList, let i=index">
                  <mat-checkbox *ngIf="i>=taskNoteList.length/2" (change)="checkTask($event,task)" (click)="$event.stopPropagation()" [checked]="selectionTask.isSelected(task)">{{task.serviceTypeDescription}}</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="serviceTypeID==34" class="ml-4">
            <h6>Format</h6>
            <mat-radio-group id="briefFormat" style="display:flex;flex-direction:column;margin-left:-.6em" aria-label="Select an option" [(ngModel)]="briefFormat" [ngModelOptions]="{standalone: true}">
              <mat-radio-button style="transform:scale(.8)" color="primary" value="a">Format A (Standard)</mat-radio-button>
              <mat-radio-button style="transform:scale(.8)" color="primary" value="b">Format B (Flight Plan First)</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
     
      <div>

        <div *ngIf="documentList && documentList.length>0">
          <div><h6>Included Images</h6></div>
          <div>
            <table mat-table *ngIf="documentList" [dataSource]="documentList" class="example-container">
              <tr>
                <ng-container matColumnDef="documentType">
                  <th mat-header-cell *matHeaderCellDef style="width:20%">
                    Task
                  </th>
                  <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-left:0.0625em solid lightgrey !important">{{element.documentType}}</td>
                </ng-container>
                <ng-container matColumnDef="documentName">
                  <th mat-header-cell *matHeaderCellDef style="width:55%">
                    Document
                  </th>
                  <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;"><a style="color:blue; cursor:pointer; text-decoration:underline" (click)="clickPreviewDocument($event, element)">{{element.documentName}}</a>&nbsp;({{element.documentSizeInKB}}KB)</td>
                </ng-container>
                <ng-container matColumnDef="check">
                  <th mat-header-cell *matHeaderCellDef style="width:10%;">
                    Select
                  </th>
                  <td mat-cell *matCellDef="let element; let j = index;" style="line-height: 1.5625em;">
                    <div style="margin-top:0.5em">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="checkDocumentChange($event, element, i+j)" [checked]="selectionDoc.isSelected(element)"></mat-checkbox>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="sortOrderNo">
                  <th mat-header-cell *matHeaderCellDef style="width:15%;">
                    Sort Order
                  </th>
                  <td mat-cell *matCellDef="let element; let j = index;" style="line-height: 1.5625em;border-right:0.0625em solid lightgrey !important">
                    <input type="number" style="width:3em" class="form-control form-control-sm inputBox" id="sortOrder{{i+j}}" [disabled]="!selectionDoc.isSelected(element)" (change)="setSortOrder($event, element)" value="{{element.sortOrderNo}}" />
                  </td>
                </ng-container>

              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
            </table>
            <!--<mat-accordion class="example-headers-align" [multi]="true">
          <mat-expansion-panel *ngFor="let item of documentCategoryList, let i=index" [expanded]="item.panelOpenState" (opened)="item.panelOpenState=true" (closed)="item.panelOpenState=false" style="border: 0.0625em solid lightgray">
            <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'" style="margin-left:-1em; font-weight:500; font-size:medium !important; text-align: left !important">
              <mat-panel-title>
                <div id="panel_{{i}}" #focusOnThisPanel>
                  {{item.documentCategory}}&nbsp;&nbsp;{{item.documentSelectedCountDisplay}}
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <table mat-table *ngIf="item.documentList" [dataSource]="item.documentList" class="example-container">
                <tr>
                  <ng-container matColumnDef="documentType">
                    <th mat-header-cell *matHeaderCellDef style="width:20%">
                      Task
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-left:0.0625em solid lightgrey !important">{{element.documentType}}</td>
                  </ng-container>
                  <ng-container matColumnDef="documentName">
                    <th mat-header-cell *matHeaderCellDef style="width:55%">
                      Document
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;"><a style="color:blue; cursor:pointer; text-decoration:underline" (click)="clickPreviewDocument($event, element)">{{element.documentName}}</a>&nbsp;({{element.documentSizeInKB}}KB)</td>
                  </ng-container>
                  <ng-container matColumnDef="check">
                    <th mat-header-cell *matHeaderCellDef style="width:10%;">
                      Select
                    </th>
                    <td mat-cell *matCellDef="let element; let j = index;" style="line-height: 1.5625em;">
                      <div style="margin-top:0.5em">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkDocumentChange($event, element, i+j)" [checked]="selection.isSelected(element)"></mat-checkbox>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="sortOrderNo">
                    <th mat-header-cell *matHeaderCellDef style="width:15%;">
                      Sort Order
                    </th>
                    <td mat-cell *matCellDef="let element; let j = index;" style="line-height: 1.5625em;border-right:0.0625em solid lightgrey !important">
                      <input type="number" style="width:3em" class="form-control form-control-sm inputBox" id="sortOrder{{i+j}}" [disabled]="!selection.isSelected(element)" (change)="setSortOrder($event, element)" value="{{element.sortOrderNo}}" />
                    </td>
                  </ng-container>

                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>
            </div>
            <mat-action-row style="max-height:2em">
              <button mat-button [hidden]="i==0" color="primary" (click)="prevStep(i)" style="font-size: small;margin-top:-1em">Previous</button>
              <button mat-button color="primary" (click)="nextStep(i)" style="font-size: small;margin-top:-1.2em">{{i<(documentCategoryList.length-1)? 'Next': 'End'}}</button>
            </mat-action-row>

          </mat-expansion-panel>
        </mat-accordion>-->
          </div>

        </div>
      </div>
    </div>
  </form>
</div>
<div class="d-inline-flex">
  <div class="d-inline-flex">
    <button class="mt-2 btn-high" mat-button (click)="clickSave(true)"><span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>Save</button>
    <button mat-button class="mt-2 ml-2 btn-high" (click)="buildBrief()"><span *ngIf="isLoadingWord" class="spinner-border spinner-border-sm mr-1"></span>Build in Word</button>
    <button mat-button class="mt-2 ml-2 btn-low" (click)="clickClose()">Close</button>
    <div *ngIf="errMsg!=''" class="alert alert-danger small ml-2 mt-2 p-0" style="white-space:pre-wrap;width:max-content">{{errMsg}}</div>
  </div>
  <div  *ngIf="showSuccessMsg" class="mt-1 ml-2">
    <app-success-message [successMsg]=""></app-success-message>
  </div>
</div>
