import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AirportService } from '../services/airport.service';
import { CommonService } from '../services/common.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ResponseModel } from '../models/response.model';
import { AirportModel } from '../models/airport.model';
import { Observable, forkJoin, of } from 'rxjs';
import { CountryModel } from '../models/country.model';
import { StateProvinceModel } from '../models/state-province.model';
import { AirportNotesModel } from '../models/airport-notes.model';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { CityModel } from '../models/city.model';
import { map, startWith } from 'rxjs/operators';
import * as atlas from 'azure-maps-control';
import { AuthenticationType, ControlPosition } from 'azure-maps-control';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { AirportMapComponent } from './airport-map.component';
import { MapPointsModel } from '../models/map-points.model';
import { AirportOfEntryModel } from '../models/airport-of-entry.model';
import { DialogService } from '../services/dialog.service';


@Component({
  selector: 'app-airport-details-edit',
  templateUrl: './airport-details-edit.component.html',
  styleUrls: ['./airport-details-edit.component.css']
})
export class AirportDetailsEditComponent implements OnInit {
  airport_details_form: UntypedFormGroup;
  showSpin: boolean = false;
  isOpenedNew: boolean = false;
  countryList: CountryModel[];
  icao: string = "";
  iata: string = "";
  faaid: string = "";
  airportName: string = "";
  cityName: string;
  cityID: number;
  stateProvinceName: string = "";
  stateProvinceID: number = 0;
  airportID: number = 0;
  countryID: number;
  countryGUID: string;
  latitude: number;
  longitude: number;
  isPreferredAlternate: boolean=false;
  distanceFromCity: string = "";
  stateProvinceList: StateProvinceModel[]=[];
  cityList: CityModel[]=[];
  airportLocationWithCountryNameAbbr: string = "";
  runwayMaxLength: number;
  runwayMaxWidth: number;
  airportType: string;
  elevation: number;
  approach: string;
  primaryRunway: string;
  utcConversion: string;
  currentUTCConversion: string;
  utcOffsetMinutes: number;
  hasDaylightSavings: boolean=false;
  dlsStart: string;
  dlsEnd: string;
  lighting: string;
  fuelType: string;
  variation: string;
  remarks: string;
  selectedTab: string = "notes";
  showDisabled: boolean = false;
  showDLS: boolean = false;
  selectedCity: CityModel[]=[];
  selectedState: StateProvinceModel[]=[];
  errMsg: string = "";
  submitted: boolean = false;
  filteredCity: Observable<CityModel[]>;
  filteredState: Observable<StateProvinceModel[]>;
  cityControl = new FormControl<string | CityModel>('',Validators.required);
  stateControl = new FormControl<string | StateProvinceModel>('');
  points: any = [];
  point: any;
  map: atlas.Map;
  modifiedBy: string = "";
  modifiedDate: string = "";
  distance: number = 150;
  isPreferred: boolean = true;
  latitudeDMS: string;
  longitudeDMS: string;
  isActive: boolean = true;
  showSuccessMsg: boolean = false;
  latLongs: MapPointsModel;;
  alternateLatLongs: MapPointsModel[] = [];
  allLatLongs: MapPointsModel[] = [];
  showOnMap: boolean = false;
  showDegrees: boolean = false;
  latDeg: number;
  latMin: number;
  latSec: number;
  longDeg: number;
  longMin: number;
  longSec: number;
  latDirection: string="N";
  longDirection: string = "E";
  radius: number = 50;
  airportOfEntryID: number;
  flagFile: string;
  airportOfEntryList: AirportOfEntryModel[];
  upAirportCountry: boolean = false;
  requiresSlots: boolean = false;
  requiresPPR: boolean = false;
  requiresLandingPermitPrivate: boolean = false;
  requiresLandingPermitCharter: boolean = false;
  hasRestrictions: boolean = false;
  requiresRevision: boolean = false;
  hasExpiredRevisions: boolean = false;
  hasExpiredDocuments: boolean = false;
  tempLat: number;
  tempLong: number;
  constructor(private readonly _airportService: AirportService, private readonly _commonService: CommonService,
    @Optional() @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _route: ActivatedRoute,
    private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _titleService: Title, @Optional() private readonly _dialogRef: MatDialogRef<AirportDetailsEditComponent>,
    private readonly _dialogService: DialogService, private sanitizer: DomSanitizer) {
    if (_data) {     
      if (_data.airportID != null && _data.airportID != undefined) {
        this.airportID = _data.airportID;
      }
    }
  }
  

  ngOnInit() {
    this._authService.updateAccessTime();
    this._route.url.subscribe(o => {
      if (o[0].path == "")
        this.isOpenedNew == false;
      else {
        this.isOpenedNew = true;
        this._route.queryParams.subscribe(params => {
          if (params["airportID"] != null && params["airportID"] != undefined) {
            this.airportID = params["airportID"];
          }
        });
      }
    });

    if (localStorage.getItem('up.airportDoc') != 'true') {
      this.upAirportCountry = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      if (!this.isOpenedNew)
        this._dialogRef.close();
      return;
    }
    else {
      this.upAirportCountry = true;
    }

    this.initControls();

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.countryList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }

      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          let airport: AirportModel = responses[1].model;
          this.icao = airport.icao;
          this.iata = airport.iata;
          this.faaid = airport.faaid;
          this.airportName = airport.airportName;
          this.countryID = airport.countryId;
          this.countryGUID = airport.countryGUID;
          this.cityName = airport.cityName;
          this.latitude = airport.latitude;
          this.latDirection = this.latitude > 0 ? "N" : "S";
          this.latDeg = Number(UtilityFunctions.convertLatLongFromDecimal(this.latitude, true, "degree"));
          this.latMin = Number(UtilityFunctions.convertLatLongFromDecimal(this.latitude, true, "minute"));
          this.latSec = Number(UtilityFunctions.convertLatLongFromDecimal(this.latitude, true, "second"));
          this.longitude = airport.longitude;
          this.longDirection = this.longitude > 0? "E" : "W"
          this.longDeg = Number(UtilityFunctions.convertLatLongFromDecimal(this.longitude, true, "degree"));
          this.longMin = Number(UtilityFunctions.convertLatLongFromDecimal(this.longitude, true, "minute"));
          this.longSec = Number(UtilityFunctions.convertLatLongFromDecimal(this.longitude, true, "second"));
          this.isPreferredAlternate = airport.isPreferredAlternate;
          this.distanceFromCity = airport.distanceFromCity;
          this.cityID = airport.cityID;
          this.stateProvinceName = airport.stateProvinceName;
          this.airportLocationWithCountryNameAbbr = airport.airportLocationWithCountryNameAbbr;
          this.runwayMaxLength = airport.runwayMaxLength;
          this.runwayMaxWidth = airport.runwayMaxWidth;
          this.elevation = airport.elevation;
          this.airportType = airport.airportType;
          this.approach = airport.approachList;
          this.primaryRunway = airport.primaryRunway;
          this.utcConversion = airport.utcConversion;
          this.currentUTCConversion = airport.currentUTCConversion;
          this.utcOffsetMinutes = airport.utcOffsetMinutes;
          this.hasDaylightSavings = airport.hasDaylightSavingsBool;
          this.showDLS = this.hasDaylightSavings;
          this.dlsStart = airport.dlsStart;
          this.dlsEnd = airport.dlsEnd;
          this.lighting = airport.lighting;
          this.fuelType = airport.fuelType;
          this.variation = airport.variation;
          this.remarks = airport.remarks;
          this.modifiedBy = airport.modifiedBy;
          this.modifiedDate = airport.modifiedDate;
          this.latitudeDMS = airport.latitudeDMS;
          this.longitudeDMS = airport.longitudeDMS;
          this.isActive = airport.isActive;
          this.latLongs = new MapPointsModel();
          this.latLongs.latitude = this.latitude;
          this.latLongs.longitude = this.longitude;
          this.latLongs.isMainPoint = true;
          this.latLongs.title = this.icao;
          this.allLatLongs.push(this.latLongs);
          this.airportOfEntryID = airport.airportOfEntryID;
          this.flagFile = airport.flagFile;
          this.requiresSlots = airport.requiresSlotsBool;
          this.requiresPPR = airport.requiresPPR;
          this.requiresLandingPermitPrivate = airport.requiresLandingPermitPrivate;
          this.requiresLandingPermitCharter = airport.requiresLandingPermitCharter;
          this.hasRestrictions = airport.hasRestrictions;
          this.requiresRevision = airport.requiresRevision;
          this.hasExpiredRevisions = airport.hasExpiredRevisions;
          this.hasExpiredDocuments = airport.hasExpiredDocuments;
          this.tempLat = this.latitude;
          this.tempLong = this.longitude;
          this.createMap();
          
          this.getCityAndStateList(this.countryID).subscribe(responses => {
            if (responses[0] != null) {
              if (responses[0].code == "200" && responses[0].message == "") {
                this.stateProvinceList = responses[0].model;
                this.selectedState = this.stateProvinceList?.filter(x => x.stateProvinceName == this.stateProvinceName).slice();
                this.filteredState = this.stateControl.valueChanges.pipe(
                  startWith(this.selectedState[0]),
                  map(value => {
                    const name = typeof value === 'string' ? value : value?.stateProvinceName;
                    return name ? this._filterState(name as string) : this.stateProvinceList.slice();
                  }),
                );
              }
              this.stateControl.setValue(this.selectedState[0]);
            }

            if (responses[1] != null) {
              if (responses[1].code == "200" && responses[1].message == "") {
                this.cityList = responses[1].model;
                this.selectedCity = this.cityList?.filter(x => x.cityName == this.cityName).slice();
                this.filteredCity = this.cityControl.valueChanges.pipe(
                  startWith(this.selectedCity[0]),
                  map(value => {
                    const name = typeof value === 'string' ? value : value?.cityName;
                    return name ? this._filterCity(name as string) : this.cityList.slice();
                  }),
                );
                this.cityControl.setValue(this.selectedCity[0]);
                
              }
            }
          });

          if (this.isOpenedNew)
            this._titleService.setTitle(this.icao);
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }

      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.airportOfEntryList = responses[2].model;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }

      }
      this.initControls();

    

    });
  }

  get f() { return this.airport_details_form.controls; }

  getAllData(): Observable<any[]> {
    let getAirportResponse;
    if (this.airportID == 0)
      getAirportResponse = of(null)
    else
      getAirportResponse = this._airportService.getAirportforEditByAirportID(this.airportID);
    let getCountryResponse = this._commonService.getCountryList();
    let getAirportOfEntryResponse = this._commonService.getAirportOfEntryList();

    return forkJoin([getCountryResponse, getAirportResponse, getAirportOfEntryResponse]);
  }

  initControls() {
    this.airport_details_form = this._formBuilder.group({
      icao: [this.icao, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      iata: [this.iata, [Validators.minLength(3), Validators.maxLength(3)]],
      faaid: { value: this.faaid, disabled: this.countryID && this.countryID != 161 && this.countryID != 167 },
      airport_name: [this.airportName, Validators.required],
      country_select: [this.countryID, Validators.required],
      //latitude: [this.latitude, Validators.required],
      //longitude: [this.longitude, Validators.required],
      distance_from_city: this.distanceFromCity,
      isPreferredAlternate: this.isPreferredAlternate,
      remarks: this.remarks,
      airportType: this.airportType,
      elevation: this.elevation,
      runway_length: this.runwayMaxLength,
      runway_width: this.runwayMaxWidth,
      approach: this.approach,
      primary_runway: this.primaryRunway,
      utc_conversion: this.utcConversion,
      utc_offset: this.utcOffsetMinutes,
      has_dls: this.hasDaylightSavings,
      lighting: this.lighting,
      fuel_type: this.fuelType,
      variation: this.variation,
      isActive: this.isActive,
      latDeg: [this.latDeg, Validators.required],
      latMin: [this.latMin, Validators.required],
      latSec: [this.latSec, Validators.required],
      longDeg: [this.longDeg, Validators.required],
      longMin: [this.longMin, Validators.required],
      longSec: [this.longSec, Validators.required],
      latDirection: [this.latDirection, Validators.required],
      longDirection: [this.longDirection, Validators.required],
      airport_of_entry:this.airportOfEntryID
    });
  }

  getCityAndStateList(countryID: number): Observable<any[]> {
    let stateResponse = this._commonService.getStateProvinceListByCountryID(countryID);
    let cityResponse = this._commonService.getCityListByCountryID(countryID);
    return forkJoin([stateResponse, cityResponse]);
  }

  updateCityAndStateList(countryID) {
    this.countryID = countryID;
    this.countryGUID = this.countryList.find(x => x.countryID == countryID).countryGUID;
    if (countryID != 161 && countryID != 167) {
      this.f.faaid.setValue(null);
      this.f.faaid.disable();
    }
    else {
      this.f.faaid.enable();
    }
      this._commonService.getStateProvinceListByCountryID<ResponseModel<StateProvinceModel[]>>(countryID).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.stateProvinceList = response.model;
            this.filteredState = this.stateControl.valueChanges.pipe(
              startWith(''),
              map(value => {
                const name = typeof value === 'string' ? value : value?.stateProvinceName;
                return name ? this._filterState(name as string) : this.stateProvinceList.slice();
              }),
            );
          }
        }
      });

    this._commonService.getCityListByCountryID<ResponseModel<CityModel[]>>(countryID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.cityList = response.model;
          this.filteredCity = this.cityControl.valueChanges.pipe(
            startWith(''),
            map(value => {
              const name = typeof value === 'string' ? value : value?.cityName;
              return name ? this._filterCity(name as string) : this.cityList.slice();
            }),
          );
        }
        
      }
    });
    
  }

  clickSave() {
    this.errMsg = "";
    this.submitted = true;
    if (this.airport_details_form.invalid) {
      return;
    }
    let request = new AirportModel();
    request.airportID = this.airportID;
    request.airportName = this.f.airport_name.value;
    request.icao = this.f.icao.value;
    request.iata = this.f.iata.value;
    request.faaid = this.f.faaid.value;
    request.countryId = this.f.country_select.value;
    if (this.cityControl.value) {
      if (this.cityControl.value['cityID'] == undefined) {
        request.cityName = this.cityControl.value.toString();
        request.cityID = 0;
      }
      else {
        request.cityName = this.cityControl.value['cityName'];
        request.cityID = this.cityControl.value['cityID'];
      }
    }
    request.latitude = this.latitude;
    request.longitude = this.longitude;
    request.distanceFromCity = this.f.distance_from_city.value;
    if (this.stateControl.value) {
      if (this.stateControl.value['stateProvinceID'] == undefined) {
        request.stateProvinceName = this.stateControl.value.toString();
        request.stateProvinceID = 0;
      }
      else {
        request.stateProvinceName = this.stateControl.value['stateProvinceName'];
        request.stateProvinceID = this.stateControl.value['stateProvinceID'];
      }
    }
    request.isPreferredAlternate = this.f.isPreferredAlternate.value;
    request.remarks = this.f.remarks.value;
    request.airportType = this.f.airportType.value;
    request.elevation = this.f.elevation.value;
    request.runwayMaxLength = this.f.runway_length.value;
    request.runwayMaxWidth = this.f.runway_width.value;
    request.approachList = this.f.approach.value;
    request.primaryRunway = this.f.primary_runway.value;
    request.utcConversion = this.f.utc_conversion.value?.replace('+', '');
    request.utcOffsetMinutes = this.f.utc_offset.value;
    request.hasDaylightSavingsBool = this.f.has_dls.value;
    request.lighting = this.f.lighting.value;
    request.fuelType = this.f.fuel_type.value;
    request.variation = this.f.variation.value;
    request.isActive = this.f.isActive.value;
    request.airportOfEntryID = this.f.airport_of_entry.value;

    this._airportService.saveAirportData<ResponseModel<number>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.airportID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.modifiedBy = localStorage.getItem('un');
          this.modifiedDate = dt;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          this.getCityAndStateList(this.countryID);
        }
        else {
          if (response.code == "401") {
            this._authService.signOut()
          }
          else {
            this.errMsg = "An error has occurred.";
          }
        }
      }
    });
  }



  private _filterState(value: string): StateProvinceModel[] {
    const filterValue = value.toLowerCase();

    return this.stateProvinceList.filter(option => option.stateProvinceName.toLowerCase().includes(filterValue));
  }

  private _filterCity(value: string): CityModel[] {
    const filterValue = value.toLowerCase();

    return this.cityList.filter(option => option.cityName.toLowerCase().includes(filterValue));
  }

  displayCity(city: CityModel): string {
    return city && city.cityName ? city.cityName : '';
  }

  displayState(state: StateProvinceModel): string {
    return state && state.stateProvinceName ? state.stateProvinceName : '';
  }

  updateLatLong() {
    this.latDeg = Number(this.f.latDeg.value);
    this.latMin = Number(this.f.latMin.value);
    this.latSec = Number(this.f.latSec.value);
    this.longDeg = Number(this.f.longDeg.value);
    this.longMin = Number(this.f.longMin.value);
    this.longSec = Number(this.f.longSec.value);
    this.latitude = UtilityFunctions.convertLatLongToDecimal(this.latDeg, this.latMin, this.latSec,this.f.latDirection.value);
    this.longitude = UtilityFunctions.convertLatLongToDecimal(this.longDeg, this.longMin, this.longSec, this.f.longDirection.value);
    this.tempLat = this.latitude;
    this.tempLong = this.longitude;
    if (this.f.latDeg.value != null && this.f.latDeg.value != undefined && this.f.latMin.value != null && this.f.latMin.value != undefined && this.f.latSec.value != null && this.f.latSec.value != undefined
      && this.f.longDeg.value != null && this.f.longDeg.value != undefined && this.f.longMin.value != null && this.f.longMin.value != undefined && this.f.longSec.value != null && this.f.longSec.value != undefined)
    this.updateMap();
  }

  updateMap() {
    this.icao = this.f.icao.value;
    //if (this.f.longitude.value != null && this.f.longitude.value != undefined)
    //  this.longitudeDMS = UtilityFunctions.convertLatLongFromDecimal(this.f.longitude.value, false,"");
    //if (this.f.latitude.value != null && this.f.latitude.value != undefined)
    //  this.latitudeDMS = UtilityFunctions.convertLatLongFromDecimal(this.f.latitude.value, true, "");
    if (this.latitude != null && this.latitude != undefined && this.longitude != null && this.longitude != undefined) {
      this.latLongs = new MapPointsModel();
      this.latLongs.latitude = this.latitude;
      this.latLongs.longitude = this.longitude;
      this.latLongs.isMainPoint = true;
      this.latLongs.title = this.icao;
      this.allLatLongs = [];
      this.allLatLongs.push(this.latLongs);
      if (this.showOnMap) {
        this.allLatLongs = this.allLatLongs.concat(this.alternateLatLongs);
      }
      this.createMap();
    }
  }


  createMap() {
    if (this.map)
      this.map.dispose();
    this.map = new atlas.Map('airport-map' + this.airportID.toString(), {

      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      self.map.controls.add([
        new atlas.control.ZoomControl()
      ], {
        position: atlas.ControlPosition.TopRight
      });

      //Create a circle
      if (self.distance <= 100)
        self.radius = 25;
      if (self.distance > 100 && self.distance <= 200)
        self.radius = 50;
      if (self.distance > 200 && self.distance <= 500)
        self.radius = 100;
      if (self.distance > 500)
        self.radius = 150;
      if (self.allLatLongs.length>1) {
        let maxRadius = self.distance * 1852;
        let increment = self.radius * 926;
        for (let i = increment; i <= maxRadius; i = i + increment) {

          dataSource.add(new atlas.data.Feature(new atlas.data.Point([self.allLatLongs[0].longitude, self.allLatLongs[0].latitude]),
            {
              subType: "Circle",
              radius: i
            }));
          
        //Create a polygon layer to render the filled in area of the polygon.
        var polygonLayer = new atlas.layer.PolygonLayer(dataSource, 'myPolygonLayer', {
          fillColor: 'transparent'
        });

        //Create a line layer for greater control of rendering the outline of the polygon.
        var lineLayer = new atlas.layer.LineLayer(dataSource, 'myLineLayer', {
          strokeColor: 'blue',
          strokeWidth: 1.5
        });

        /*Create and add a polygon layer to render the polygon to the map*/
          self.map.layers.add([polygonLayer, lineLayer]);
       
         }

      }

      self.allLatLongs.forEach((v, i) => {
        dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
          title: v.title,
          color: v.isMainPoint ? '#ffd800':'DodgerBlue'
        }));
        self.points.push([v.longitude, v.latitude]);


      });
      var bubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
        radius: 4,
        strokeColor: ['get', 'color'],
        strokeWidth: 6,
        color: "white",
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      });
      self.map.layers.add(bubbleLayer);

      //Add a layer for rendering point data.
      var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
        iconOptions: {
          //Hide the icon image.
          image: "none"
        },

        textOptions: {
          textField: ['get', 'title'],
          offset: [0, -1],
          allowOverlap: true,
          size: 16
        },
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      })
      self.map.layers.add(symbolLayer);
      var selectedShape;

      self.map.events.add('mousedown', bubbleLayer, function (e) {
        if (e.shapes && e.shapes.length > 0) {
          //Capture the selected shape.
          selectedShape = e.shapes[0];

          //Lock the maps ability to pan so that we can drag the symbol.
          self.map.setUserInteraction({
            dragPanInteraction: false
          });
        }
      });

      self.map.events.add('mousemove', function (e) {
        //Update the position of the selected shape.
        if (selectedShape) {
          selectedShape.setCoordinates(e.position);
        
        }
      });

      self.map.events.add('mouseup', function (e) {
        if (selectedShape) {
          var coords = selectedShape.getCoordinates();
          self.tempLat = Number(coords[1].toPrecision(8));
          self.tempLong = Number(coords[0].toPrecision(8));
        }
        //Stop tracking the selected shape.
        selectedShape = null;

        //Make map panable again.
        self.map.setUserInteraction({
          dragPanInteraction: true
        });
      });
     

      var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

      if (self.allLatLongs.length == 1) {

        self.map.setCamera({
          center: [self.allLatLongs[0].longitude, self.allLatLongs[0].latitude],
          zoom: 12
        });
      }
      else {
        self.map.setCamera({
          bounds: bbox,
          padding: 40
        });
      }

    });

  }

  openInNew() {
    this._dialogRef.close();
    let route = "/airports/airport-details-edit?airportID=" + this.airportID;
     window.open(route, '_blank');
  }

  openMapComponent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80em";
    dialogConfig.height = "40em";

  
    
    dialogConfig.data = {
      latLongs: this.allLatLongs,
      distance: this.distance,
      from:"details"
    };

    this._dialog.open(AirportMapComponent, dialogConfig);

  }


  onUpdateParentPage(e: any) {
    if (e.alternateLatLongs != null && e.alternateLatLongs != undefined) {
      this.alternateLatLongs = e.alternateLatLongs;
      if (this.showOnMap)
        this.updateMap();
    }
  }

  calculateUTC(input: string) {
    let plusMinus = ""
    let offset = 0;
    let hour = "";
    let min = "";
    let conversion = "";
    if (input == 'conversion') {
      conversion = this.f.utc_conversion.value;
      if (this.f.utc_conversion.value != "") {
        if (this.f.utc_conversion.value.substring(0, 1) == "+" || this.f.utc_conversion.value.substring(0, 1) == "-") {
          plusMinus = this.f.utc_conversion.value.substring(0, 1);
          if (conversion.indexOf(":") != -1) {
            hour = conversion.substring(1, conversion.indexOf(":"));
            min = conversion.substring(conversion.indexOf(":") + 1, conversion.length);
          }
          else
            hour = conversion;
        }
        else {
          if (conversion.indexOf(":") != -1) {
            hour = conversion.substring(0, conversion.indexOf(":"));
            min = conversion.substring(conversion.indexOf(":") + 1, conversion.length);
          }
          else
            hour = conversion;
        }
        offset = Number(hour) * 60 + Number(min);
        if (plusMinus == '-')
          offset = offset * -1;
        this.f.utc_offset.setValue(offset);
      }
    }
    else {
      offset = Number(this.f.utc_offset.value);
      if (offset > 0)
        plusMinus = "+"
      hour = Math.floor(offset / 60).toString();
      min = (offset % 60).toString();
      conversion = plusMinus + hour.padStart(2, '0') + ":" + min.padStart(2, '0');
      this.f.utc_conversion.setValue(conversion);
      
    }
  }

  getFlag(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/svg+xml;base64,${image}`
    );
  }

  updateCoordinates() {
    this.latitude = this.tempLat;
    this.longitude = this.tempLong;
    this.latDirection = this.latitude > 0 ? "N" : "S";
    this.latDeg = Number(UtilityFunctions.convertLatLongFromDecimal(this.latitude, true, "degree"));
    this.latMin = Number(UtilityFunctions.convertLatLongFromDecimal(this.latitude, true, "minute"));
    this.latSec = Number(UtilityFunctions.convertLatLongFromDecimal(this.latitude, true, "second"));
    this.longDirection = this.longitude > 0 ? "E" : "W"
    this.longDeg = Number(UtilityFunctions.convertLatLongFromDecimal(this.longitude, true, "degree"));
    this.longMin = Number(UtilityFunctions.convertLatLongFromDecimal(this.longitude, true, "minute"));
    this.longSec = Number(UtilityFunctions.convertLatLongFromDecimal(this.longitude, true, "second"));
    this.f.latDeg.setValue(this.latDeg);
    this.f.latMin.setValue(this.latMin);
    this.f.latSec.setValue(this.latSec);
    this.f.longDeg.setValue(this.longDeg);
    this.f.longMin.setValue(this.longMin);
    this.f.longSec.setValue(this.longSec);
    if (this.latitude != null && this.latitude != undefined && this.longitude != null && this.longitude != undefined) {
      this.latLongs = new MapPointsModel();
      this.latLongs.latitude = this.latitude;
      this.latLongs.longitude = this.longitude;
      this.latLongs.isMainPoint = true;
      this.latLongs.title = this.icao;
      this.allLatLongs = [];
      this.allLatLongs.push(this.latLongs);
      if (this.showOnMap) {
        this.allLatLongs = this.allLatLongs.concat(this.alternateLatLongs);
      }
    }
  }
  
}
