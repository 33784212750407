<div *ngIf="upPII" style="margin-top:0em; padding-top:0em; display:flex; padding-bottom: 1em;">
  <form *ngIf="ground_stop_leglist_form" [formGroup]="ground_stop_leglist_form">
    <div class="d-inline-flex">
      <div style="min-height: 25.5em; max-height: 41.5em; overflow-y: auto;">

        <table mat-table [dataSource]="legList" multiTemplateDataRows
          class="example-container mat-elevation-z8 table-with-expansion">
          <!--<tr>
            <ng-container matColumnDef="expand_collapse">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumn.length" style="border-bottom:none; text-align: left !important ">
                <a (click)="openAll()" class="primary" style="outline:none; font-size: small; cursor:pointer;color:#337dff; font-weight:600">Expand All</a>&nbsp;&nbsp;&nbsp;&nbsp;<a (click)="closeAll()" color="primary" style="outline: none; font-size: small; cursor:pointer;color:#337dff; font-weight:600">Collapse All</a>
              </th>
            </ng-container>
          </tr>-->
          <tr>
            <ng-container matColumnDef="legOrder">
              <th mat-header-cell *matHeaderCellDef style="width:2%;">
                <div style="margin-top:-0.25em;">Leg</div>
                <div style="margin-top:-0.25em;">#</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.legItem.tripLegOrder}}</td>
            </ng-container>
            <ng-container matColumnDef="departure">
              <th mat-header-cell *matHeaderCellDef style="width:30%; text-align:left !important">
                <div style="margin-top:-0.25em;display: flex">
                  <div><i class="material-icons" style="font-size: large;">flight_takeoff</i></div>
                  <div style="width: 11.5em">Departure</div>
                  <div>ETE</div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important;"
                [style.background-color]="element.legItem.departureGroundStopTypeColor">
                <div>
                  <div style="display: flex">
                    <div *ngIf="element.legItem.departureICAO!='XXXX'"
                      [ngClass]="element.legItem.isDepartureHomeBaseAirport?'homeBaseAirport divHover':'divHover'"
                      style="cursor: pointer;font-weight: bold; font-size: medium; margin-right:0.25em"
                      (click)="clickICAO($event, element.legItem, 'D')"
                      title="{{element.legItem.departureAirportInfo}}">{{element.legItem.departureICAO_IATA}}</div>
                  </div>
                  <div *ngIf="element.legItem.departureICAO=='XXXX'" style="font-weight: bold; font-size: medium;">
                    {{element.legItem.departureICAO_IATA}}</div>
                  <div style="display: flex;margin-top:-0.25em ">
                    <div style="display: flex;width: 13em">
                      <div [style.background-color]="element.legItem.isDateTimeConflict?'#f9d2de':'transparent'"
                        style="white-space: nowrap; font-weight: bold;"
                        title="{{element.legItem.isDateTimeConflict?'Time conflict':''}}">
                        {{element.legItem.departureDateTextUTC!=''?element.legItem.departureDateTimeUTCDisplay+'
                        Z':'TBA'}}</div>
                      <div style="margin-left:0.5em">{{element.legItem.departureUTCTimeConversion}}</div>
                    </div>
                    <div>
                      <div *ngIf="!element.legItem.eteChanged">{{element.legItem.ete}}</div>
                      <div *ngIf="element.legItem.eteChanged" style="display:flex; margin-left:0em;">
                        <div style="width:2.5em">{{element.legItem.ete}}</div>
                        <div style="margin-top:0.25em"><i class="material-icons-outlined"
                            style="font-size:medium; color: darkgreen; font-weight:bold" title="ETE changed">check</i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="white-space: nowrap; margin-top:-0.5em">
                    {{element.legItem.departureDateTextLocal!=''?element.legItem.departureDateTextLocal+' L':''}}</div>
                  <div style="font-size:x-small;margin-top:-0.25em;font-weight:bold">
                    {{element.legItem.departureGroundStopTypeDescription!='Active'?element.legItem.departureGroundStopTypeDescription:''}}
                  </div>
                  <div *ngFor="let v of element.legItem.departureVendorList" style="margin-left:0em; display:flex">
                    <div style="margin-top: 0em;cursor: pointer" class="divHover"
                      (click)="clickPointOfContact($event, element.legItem, v, 'D')">
                      {{v.vendorRoleDescription.substr(0,1)+ ': '+v.vendorName}}</div>
                    <div></div>
                  </div>
                  <div *ngIf="element.legItem.departureGroundStopAlternateAirportList.length>0"
                    style="margin-left:-0.25em">
                    <div style="display: flex">
                      <div><i class="material-icons"
                          style="color: gray; font-size:large; margin-top:0.25em">where_to_vote</i></div>
                      <div>
                        <div style="font-weight: bold">Alternate</div>
                        <div style="display: flex; margin-top:-0.25em">
                          <div *ngFor="let a of element.legItem.departureGroundStopAlternateAirportList">
                            <div style="cursor: pointer; margin-right:0.25em" class="divHover"
                              (click)="clickAltICAO($event, element.legItem, a, 'D')">{{a.icao}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="element.legItem.etopGroundStopAlternateAirportList.length>0" style="margin-left:-0.25em">
                    <div style="display:flex">
                      <div><i class="material-icons"
                          style="color:gray; font-size:large; margin-top:0.25em">where_to_vote</i></div>
                      <div>
                        <div style="font-weight: bold">ETOP</div>
                        <div *ngFor="let a of element.legItem.etopGroundStopAlternateAirportList; let i=index"
                          style="margin-top:-0.25em">
                          <div *ngIf="i % 2==0" style="display: flex">
                            <div style="font-size: small;margin-right:0.25em">
                              <div style="cursor: pointer" class="divHover"
                                (click)="clickAltICAO($event, element.legItem, a, 'D')">{{a.icao}}</div>
                            </div>
                            <div *ngIf="i + 1 < element.legItem.etopGroundStopAlternateAirportList.length"
                              style="font-size: small;">
                              <div style="cursor: pointer" class="divHover"
                                (click)="clickAltICAO($event, element.legItem, element.legItem.etopGroundStopAlternateAirportList[i+1], 'D')">
                                {{element.legItem.etopGroundStopAlternateAirportList[i+1].icao}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="element.legItem.etpGroundStopAlternateAirportList.length>0" style="margin-left:-0.25em">
                    <div style="display:flex">
                      <div><i class="material-icons"
                          style="color:gray; font-size:large; margin-top:0.25em">where_to_vote</i></div>
                      <div>
                        <div style="font-weight: bold">ETP</div>
                        <div *ngFor="let a of element.legItem.etpGroundStopAlternateAirportList; let i=index"
                          style="margin-top:-0.25em">
                          <div *ngIf="i % 2==0" style="display: flex">
                            <div style="font-size: small;margin-right:0.25em">
                              <div style="cursor: pointer" class="divHover"
                                (click)="clickAltICAO($event, element.legItem, a, 'D')">{{a.icao}}</div>
                            </div>
                            <div *ngIf="i + 1 < element.legItem.etpGroundStopAlternateAirportList.length"
                              style="font-size: small;">
                              <div style="cursor: pointer" class="divHover"
                                (click)="clickAltICAO($event, element.legItem, element.legItem.etpGroundStopAlternateAirportList[i+1],'D')">
                                {{element.legItem.etpGroundStopAlternateAirportList[i+1].icao}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="element.legItem.departureStatusDescription=='Actual'"
                    style="border-radius: 0.5em; width: 7em; height: 1.25em; border: gray solid 0.0625em;margin-top:0.25em"
                    [style.background-color]="element.legItem.departureStatusColorCode">
                    <div style="margin-top:-0.3em; text-align:center">{{element.legItem.departureStatusDescription}}
                    </div>
                  </div>
                  <div *ngIf="element.legItem.departureOnHold"
                    style="margin-top:0.1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
                    <div style="margin-top:-0.3em; text-align:center">On Hold</div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="arrival">
              <th mat-header-cell *matHeaderCellDef style="width:26%; text-align:left !important">
                <div style="margin-top:-0.25em;display: flex">
                  <div><i class="material-icons" style="font-size: large;">flight_land</i></div>
                  <div>Arrival</div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important;"
                [style.background-color]="element.legItem.nextArrivalGroundStopTypeColor">
                <div>
                  <div style="display: flex">
                    <div *ngIf="element.legItem.nextArrivalICAO!='XXXX'"
                      [ngClass]="element.legItem.isNextArrivalHomeBaseAirport?'homeBaseAirport divHover':'divHover'"
                      style="cursor: pointer;font-weight: bold; font-size: medium; margin-right:0.25em"
                      (click)="clickICAO($event, element.legItem, 'A')"
                      title="{{element.legItem.nextArrivalAirportInfo}}">{{element.legItem.nextArrivalICAO_IATA}}</div>
                  </div>
                  <div *ngIf="element.legItem.nextArrivalICAO=='XXXX'" style="font-weight: bold; font-size: medium;">
                    {{element.legItem.nextArrivalICAO_IATA}}</div>
                  <div style="display: flex; margin-top: -0.25em">
                    <div style="white-space: nowrap; font-weight: bold">
                      {{element.legItem.nextArrivalDateTextUTC!=''?element.legItem.nextArrivalDateTimeUTCDisplay+'
                      Z':'TBA'}}</div>
                    <div style="margin-left: 0.5em">{{element.legItem.nextArrivalUTCTimeConversion}}</div>
                  </div>
                  <div style="white-space: nowrap; margin-top:-0.5em">
                    {{element.legItem.nextArrivalDateTextLocal!=''?element.legItem.nextArrivalDateTextLocal+' L':''}}
                  </div>
                  <div *ngIf="element.legItem.isNextArrivalTechStop"
                    style="border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color:#e5e5e5">
                    <div style="margin-top:-0.3em; margin-left:0.25em">Tech Stop</div>
                  </div>
                  <div style="font-size: x-small; margin-top: -0.25em; font-weight: bold">
                    {{element.legItem.nextArrivalGroundStopTypeDescription!='Active'?element.legItem.nextArrivalGroundStopTypeDescription:''}}
                  </div>
                  <div *ngFor="let v of element.legItem.nextArrivalVendorList" style="margin-left:0em; display: flex">
                    <div class="divHover" style="margin-top: 0em;cursor: pointer"
                      (click)="clickPointOfContact($event, element.legItem, v, 'A')">
                      {{v.vendorRoleDescription.substr(0,1)+ ': '+v.vendorName}}</div>
                    <div></div>
                  </div>
                  <div *ngIf="element.legItem.nextArrivalGroundStopAlternateAirportList.length>0"
                    style="margin-left:-0.25em">
                    <div style="display: flex">
                      <div><i class="material-icons"
                          style="color: gray; font-size:large; margin-top:0.25em">where_to_vote</i></div>
                      <div>
                        <div style="font-weight: bold">Alternate</div>
                        <div style="display: flex; margin-top:-0.25em">
                          <div *ngFor="let a of element.legItem.nextArrivalGroundStopAlternateAirportList">
                            <div style="cursor: pointer; margin-right:0.25em" class="divHover"
                              (click)="clickAltICAO($event, element.legItem, a, 'D')">{{a.icao}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="element.legItem.nextArrivalStatusDescription=='Actual'"
                    style="border-radius: 0.5em; width: 7em; height: 1.25em; border: gray solid 0.0625em;margin-top:0.25em"
                    [style.background-color]="element.legItem.nextArrivalStatusColorCode">
                    <div style="margin-top:-0.3em; text-align:center">{{element.legItem.nextArrivalStatusDescription}}
                    </div>
                  </div>
                  <div
                    *ngIf="element.legItem.nextArrivalOnHold && element.legItem.departureGroundStopTypeGUID!=cancellationGroundStopTypeGUID && element.legItem.departureGroundStopTypeGUID!=contingencyGroundStopTypeGUID?'On Hold':''"
                    style="margin-top:0.1em;border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
                    <div style="margin-top:-0.3em; text-align:center">On Hold</div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="crew">
              <th mat-header-cell *matHeaderCellDef style="width:11%;text-align: left !important ">
                <div style="margin-top:-0.25em;">PIC/SIC/Crew</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important">
                <div>
                  <div *ngIf="element.legItem.crewCount>0" style="white-space:nowrap;font-weight: bold">Crew
                    ({{element.legItem.crewCount}})</div>
                  <div style="display: flex; margin-top: -0.4em;height:1.5em" *ngIf="element.legItem.pic!=null">
                    <div style="white-space:nowrap; font-weight: bold; margin-right:0.25em">P:</div>
                    <div class="divHover" style="cursor: pointer" matTooltipClass="tooltipLineBreak"
                      matTooltip="{{element.legItem.pic.tooltip}}"
                      (click)="clickPersonName($event, element.legItem, element.legItem.pic)">
                      {{element.legItem.pic.personNameAbbr}}</div>
                    <div *ngIf="element.legItem.isPICChanged" style="cursor: pointer; margin-top:0.25em"
                      title="PIC changed"><i class="material-icons-two-tone icon_red2"
                        style="font-size: medium">people_alt</i></div>
                    <!-- <div *ngIf="element.legItem.pic.hasRequiredDocument==false"
                      title="{{element.legItem.pic.missingInfoTooltip}}" style="cursor: pointer; margin-top:0.25em"
                      (click)="clickPersonName($event, element.legItem, element.legItem.pic);"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i>
                    </div> -->
                    <div *ngIf="(element.legItem.pic.isBellAlert || element.legItem.pic.hasExpiredPassport)"
                      title="{{element.legItem.pic.missingInfoTooltip}}" style="cursor: pointer; margin-top:0.25em"
                      (click)="clickPersonName($event, element.legItem, element.legItem.pic);"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i>
                    </div>
                    <!-- <div *ngIf="element.legItem.pic.passportCount>1" title="Multiple Passports" style="margin-top:0.25em"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">content_copy</i></div> -->
                    <div *ngIf="element.legItem.pic.isMultiplePassports" title="Multiple Passports"
                      style="margin-top:0.25em"><i class="material-icons-two-tone icon_green"
                        style="font-size: medium">content_copy</i></div>
                  </div>
                  <div style="display: flex; margin-top: -0.4em; height: 1.5em" *ngIf="element.legItem.sic!=null">
                    <div style="white-space:nowrap; font-weight: bold; margin-right:0.25em">S:</div>
                    <div class="divHover" style="cursor: pointer" matTooltipClass="tooltipLineBreak"
                      matTooltip="{{element.legItem.sic.tooltip}}"
                      (click)="clickPersonName($event, element.legItem, element.legItem.sic)">
                      {{element.legItem.sic.personNameAbbr}}</div>
                    <div *ngIf="element.legItem.isSICChanged" style="cursor: pointer; margin-top:0.25em"
                      title="SIC changed"><i class="material-icons-two-tone icon_red2"
                        style="font-size: medium">people_alt</i></div>
                    <!-- <div *ngIf="element.legItem.sic.hasRequiredDocument==false"
                      title="{{element.legItem.sic.missingInfoTooltip}}" style="cursor: pointer; margin-top:0.25em"
                      (click)="clickPersonName($event, element.legItem, element.legItem.sic);"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i>
                    </div> -->
                    <div *ngIf="(element.legItem.sic.isBellAlert || element.legItem.sic.hasExpiredPassport)"
                      title="{{element.legItem.sic.missingInfoTooltip}}" style="cursor: pointer; margin-top:0.25em"
                      (click)="clickPersonName($event, element.legItem, element.legItem.sic);"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i>
                    </div>
                    <!-- <div *ngIf="element.legItem.sic.passportCount>1" title="Multiple Passports" style="margin-top:0.25em"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">content_copy</i></div> -->
                    <div *ngIf="element.legItem.sic.isMultiplePassports" title="Multiple Passports"
                      style="margin-top:0.25em"><i class="material-icons-two-tone icon_green"
                        style="font-size: medium">content_copy</i></div>
                  </div>
                  <div *ngFor="let p of element.legItem.tripCrewList; let i=index"
                    style="display: flex; margin-top: -0.4em; height: 1.5em">
                    <div style="white-space:nowrap; font-weight: bold; width:1em;">{{i==0? 'C:' :''}}</div>
                    <div style="cursor: pointer; white-space:nowrap" class="divHover" matTooltipClass="tooltipLineBreak"
                      matTooltip="{{p.tooltip}}" (click)="clickPersonName($event, element.legItem,p);">
                      {{p.personNameAbbr}}</div>
                    <!-- <div *ngIf="p.hasRequiredDocument==false" style="cursor: pointer; margin-top:0.25em"
                      (click)="clickPersonName($event, element.legItem,p)" title="{{p.missingInfoTooltip}}"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i>
                    </div> -->
                    <div *ngIf="(p.isBellAlert || p.hasExpiredPassport)" style="cursor: pointer; margin-top:0.25em"
                      (click)="clickPersonName($event, element.legItem,p)" title="{{p.missingInfoTooltip}}"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i>
                    </div>
                    <!-- <div *ngIf="p.passportCount>1" title="Multiple Passports" style="margin-top:0.25em"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">content_copy</i></div> -->
                    <div *ngIf="p.isMultiplePassports" title="Multiple Passports" style="margin-top:0.25em"><i
                        class="material-icons-two-tone icon_green" style="font-size: medium">content_copy</i></div>
                  </div>
                  <div *ngIf="element.legItem.crewCount==0" style="white-space: nowrap;">
                    <div>Missing Crew</div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="pax">
              <th mat-header-cell *matHeaderCellDef style="width:11%; text-align: left !important ">
                <div style="margin-top:-0.25em;">PAX</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important">
                <div style="margin-left:0em">
                  <div *ngIf="element.legItem.paxCount>0" style="white-space:nowrap; font-weight:bold">PAX
                    ({{element.legItem.paxCount}})</div>
                  <div *ngFor="let p of element.legItem.tripPAXList"
                    style="display: flex; margin-top: -0.4em; height: 1.5em">
                    <div style="white-space:nowrap;cursor: pointer;" class="divHover" matTooltipClass="tooltipLineBreak"
                      matTooltip="{{p.tooltip}}" (click)="clickPersonName($event, element.legItem,p)">
                      {{p.personNameAbbr}}</div>
                    <!-- <div *ngIf="p.hasRequiredDocument==false" style="cursor: pointer; margin-top:0.25em"
                      title="{{p.missingInfoTooltip}}" (click)="clickPersonName($event, element.legItem,p)"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i>
                    </div> -->
                    <div *ngIf="(p.isBellAlert || p.hasExpiredPassport)" style="cursor: pointer; margin-top:0.25em"
                      title="{{p.missingInfoTooltip}}" (click)="clickPersonName($event, element.legItem,p)"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i>
                    </div>
                    <div style="margin-top:0.25em" title="Minor on board" *ngIf="p.isMinorOnboard==true"><i
                        class="material-icons-two-tone icon_green"
                        style="font-size: medium">assignment_ind<!--warning_amber--></i></div>
                    <!-- <div *ngIf="p.passportCount>1" title="Multiple Passports" style="margin-top:0.25em"><i
                        class="material-icons-two-tone icon_orange" style="font-size: medium">content_copy</i></div> -->
                    <div *ngIf="p.isMultiplePassports" title="Multiple Passports" style="margin-top:0.25em"><i
                        class="material-icons-two-tone icon_green" style="font-size: medium">content_copy</i></div>
                  </div>
                  <div *ngIf="element.legItem.isDeptFerryFlight && element.legItem.paxCount==0">
                    <div style="white-space:nowrap; font-weight: bold">PAX</div>
                    <div>Ferry Flight</div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="far">
              <th mat-header-cell *matHeaderCellDef style="width:6%; ">
                <div style="margin-top:-0.25em; white-space:nowrap">FAR/Call Sign</div>

              </th>
              <td mat-cell *matCellDef="let element">
                <div style="white-space: nowrap; font-weight: bold; display: flex">
                  <div style="margin-right:0em"
                    [style.background-color]="element.legItem.departureFARTypeID==2?'yellow':'transparent'">
                    {{element.legItem.departureFARTypeID>0?element.legItem.departureFARTypeDescription:''}}</div>
                  <div *ngIf="element.legItem.departureFARTypeID && element.legItem.departureCallSign!=''"
                    style="margin-right:0em">/</div>
                  <div [style.background-color]="element.legItem.departureCallSign!=''?'yellow':'transparent'">
                    {{element.legItem.departureCallSign!=''?element.legItem.departureCallSign:''}}</div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="other">
              <th mat-header-cell *matHeaderCellDef style="width:8%; ">
                <div style="margin-top:-0.25em;">Other</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div>
                  <div *ngIf="element.legItem.departurePetsOnBoard">Pets</div>
                  <div *ngIf="element.legItem.departureWeaponsOnBoard" style="margin-top:-0.5em">Weapons</div>
                </div>
              </td>
            </ng-container>
            <!-- <ng-container matColumnDef="legStatus">
              <th mat-header-cell *matHeaderCellDef style="width:2%; ">
                <div style="margin-top:-0.25em;">Leg</div>
                <div style="margin-top:-0.25em;">Status</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <span class="material-icons-two-tone {{element.legItem.checklistStatusIconColor}}">
                  {{element.legItem.checklistStatusIconName}}
                </span>
              </td>
            </ng-container> -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width:2%">
                <div>Actions</div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div style="display: flex">
                  <div><i class="material-icons-outlined iconHover" style="font-size: x-large;cursor: pointer;"
                      title="Add Business Rule Tasks" (click)="clickAddBRTasks($event, element.legItem);">grading</i>
                  </div>
                  <div><i class="material-icons-outlined iconHover" style="font-size: x-large;cursor: pointer;"
                      title="Update Trip Leg Details"
                      (click)="clickTripLegDetail($event, element.legItem)">event_available</i></div>
                  <div><i class="material-icons-outlined iconHover" style="font-size: x-large;cursor: pointer;"
                      title="Configure Alternate Airports, ETP &ETOPS"
                      (click)="clickEditAlternateAirport($event, element.legItem);">edit_location_alt</i></div>
                  <div><i class="material-icons iconHover" style="font-size: x-large;cursor: pointer;"
                      title="Download trip documents"
                      (click)="clickDownloadTripDocument($event, element.legItem);">download</i></div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef style="width:2%; ">
                Expand
              </th>
              <td mat-cell *matCellDef="let element">
                <span style="margin-top:0.25em" *ngIf="element.expanded==false" title="click to expand"><i
                    class="material-icons"
                    style="font-size:xx-large; color: gray; font-weight:normal; cursor: pointer">expand_more</i></span>
                <span style="margin-top:0.25em" *ngIf="element.expanded==true" title="click to collapse"><i
                    class="material-icons"
                    style="font-size:xx-large; color: gray; font-weight:normal; cursor: pointer">expand_less</i></span>
              </td>
            </ng-container>
          </tr>
          <tr>
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumn.length">
                <div class="example-element-detail"
                  [@detailExpand]="element.expanded == true ? 'expanded' : 'collapsed'">
                  <app-ground-stop-leg-tasklist *ngIf="element.legItem!=null" [requestInput]="requestInput"
                    [refreshChildRow]="element.refreshChildRow" [updateTask]="element.updateTask"
                    [legItem]="element.legItem" [refreshChildColumn]="refreshChildColumn" [hidePastStop]="hidePastStop"
                    [includeActiveStopOnly]="includeActiveStopOnly" [lockdownTrip]="lockdownTrip"
                    (updateParentPage)="onUpdateParentPage($event)"
                    [clientName]="clientName"></app-ground-stop-leg-tasklist>
                </div>
              </td>
            </ng-container>

          </tr>
          <!--<tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>-->
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let element; columns:displayedColumn;"
            (click)="expandCollapseLeg($event, element, 'e')" class="example-element-row"
            [class.hidden]="hidePastStop?element.legItem.nextArrivalDateTimePassed:false">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
            [class.hidden]="hidePastStop?row.legItem.nextArrivalDateTimePassed:false"></tr>
        </table>
      </div>
      <div style="width:26em">
        <div style="position: relative;">
          <div style="margin-left: .5em;width: 100%; height: 30em; border-radius: .25em " id="map"></div>
          <div *ngIf="legList.length>0"
            style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer"
            class="material-icons-outlined" (click)="openMapComponent()">
            fullscreen
          </div>
        </div>
        <div *ngIf="legList.length>0" class="border p-2"
          style="background: linear-gradient(to bottom,#fff0f0, #ffe4e4); margin-left: .5em; margin-top: 1em; border: thin; border-color: black; border-radius: .25em">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <span class="material-icons-outlined">
                info
              </span>
              <h5>Alerts</h5>

            </div>
            <div class="example-action-buttons">
              <button mat-button (click)="alertAccordion.openAll()" color="primary"
                style="outline:none; font-size: small">Expand All</button>
              <button mat-button (click)="alertAccordion.closeAll()" color="primary"
                style="outline: none; font-size: small">Collapse All</button>
            </div>
          </div>
          <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel *ngIf="onHoldTaskList && onHoldTaskList.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em; margin-top: .25em; font-size: medium"
                    class="material-icons-outlined">
                    pause_circle
                  </span>
                  {{onHoldTaskList.length}} {{onHoldTaskList.length==1? 'Task':'Tasks'}} On Hold
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-obj [ngForOf]="onHoldTaskAlerts" let-i="index">
                <div style="font-size: small; margin-left: 1.7em">
                  {{obj.categoryName}}
                  <div class="d-inline-flex">
                    <div>
                      <div style="font-size:small; margin-left: 1.5em;font-weight:500">Departure Tasks</div>
                      <label style="font-size:small; margin-left: 1.5em"
                        *ngIf="!hasTasks(obj.alertObject,'D')">None</label>
                      <ng-template ngFor let-task [ngForOf]="obj.alertObject">
                        <div class="d-flex flex-column" *ngIf="task.serviceLocation=='D'">
                          <div style="font-size: small; margin-left: 1.5em">
                            {{task.serviceTypeDescription}}
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <div>
                      <div style="font-size:small; margin-left: 1.5em;font-weight:500">Arrival Tasks</div>
                      <label style="font-size:small; margin-left: 1.5em"
                        *ngIf="!hasTasks(obj.alertObject,'A')">None</label>
                      <ng-template ngFor let-task [ngForOf]="obj.alertObject">
                        <div class="d-flex flex-column" *ngIf="task.serviceLocation=='A'">
                          <div style="font-size: small; margin-left: 1.5em">
                            {{task.serviceTypeDescription}}
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="missingCrewList && missingCrewList.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em; margin-top: .25em; font-size: medium"
                    class="material-icons-outlined">
                    people
                  </span>
                  {{missingCrewList.length}} {{missingCrewList.length==1? 'Leg':'Legs'}} Missing Crew
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-obj [ngForOf]="missingCrewAlerts">
                <div style="font-size: small; margin-left: 1.7em">
                  {{obj.categoryName}}
                </div>
              </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="missingPaxList && missingPaxList.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em; margin-top: .25em; font-size: medium"
                    class="material-icons-outlined">
                    airline_seat_recline_normal
                  </span>
                  {{missingPaxList.length}} {{missingPaxList.length==1? 'Leg':'Legs'}} Missing PAX
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-obj [ngForOf]="missingPaxAlerts">
                <div style="font-size: small; margin-left: 1.7em">
                  {{obj.categoryName}}
                </div>
              </ng-template>
            </mat-expansion-panel>
            <!--<mat-expansion-panel *ngIf="tbaPaxList && tbaPaxList.length>0">
    <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
      <mat-panel-title>
        <span style="margin-right: .25em; margin-top: .25em; font-size: medium" class="material-icons-outlined">
          airline_seat_recline_normal
        </span>
        {{tbaPaxAlerts.length}} {{tbaPaxAlerts.length==1? 'Leg':'Legs'}} with TBA PAX
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template ngFor let-obj [ngForOf]="tbaPaxAlerts">
      <div style="font-size: small; margin-left: 1.7em">
        {{obj.categoryName}}
      </div>
    </ng-template>
  </mat-expansion-panel>-->
            <!--<mat-expansion-panel *ngIf="tbaCrewAlerts && tbaCrewAlerts.length>0">
    <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
      <mat-panel-title>
        <span style="margin-right: .25em; margin-top: .25em; font-size: medium" class="material-icons-outlined">
          people
        </span>
        {{tbaCrewList.length}} {{tbaCrewList.length==1? 'Leg':'Legs'}} with TBA Crew
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template ngFor let-obj [ngForOf]="tbaCrewAlerts">
      <div style="font-size: small; margin-left: 1.7em">
        {{obj.categoryName}}
      </div>
    </ng-template>
  </mat-expansion-panel>-->
            <!--<mat-expansion-panel *ngIf="followUpPaxList && followUpPaxList.length>0">
    <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
      <mat-panel-title>
        <span style="margin-right: .25em; margin-top: .25em; font-size: medium" class="material-icons-outlined">
          airline_seat_recline_normal
        </span>
        {{followUpPaxList.length}} {{followUpPaxList.length==1? 'Leg':'Legs'}} Needing PAX Follow Up
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template ngFor let-obj [ngForOf]="followUpPaxAlerts">
      <div style="font-size: small; margin-left: 1.7em">
        {{obj.categoryName}}
      </div>
    </ng-template>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="followUpCrewList && followUpCrewList.length>0">
    <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
      <mat-panel-title>
        <span style="margin-right: .25em; margin-top: .25em; font-size: medium" class="material-icons-outlined">
          people
        </span>
        {{followUpCrewList.length}} {{followUpCrewList.length==1? 'Leg':'Legs'}} Needing Crew Follow Up
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template ngFor let-obj [ngForOf]="followUpCrewAlerts">
      <div style="font-size: small; margin-left: 1.7em">
        {{obj.categoryName}}
      </div>
    </ng-template>
  </mat-expansion-panel>-->
            <mat-expansion-panel *ngIf="expiredTravelDocList && expiredTravelDocList.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em; margin-top: .25em; font-size: medium"
                    class="material-icons-outlined">
                    badge
                  </span>
                  {{expiredTravelDocList.length}} Expired Manifest {{expiredTravelDocList.length==1?
                  'Document':'Documents'}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-obj [ngForOf]="expiredTravelDocAlerts">
                <div style="font-size: small; margin-left: 1.7em">
                  {{obj.categoryName}}
                  <ng-template ngFor let-person [ngForOf]="obj.alertObject">
                    <div style="font-size: small; margin-left: 1.5em">
                      {{person.firstName}} {{person.MiddleName}} {{person.lastName}}
                      <ng-template ngFor let-doc [ngForOf]="person.groundStopManifestDocumentList">
                        <div style="font-size: small; margin-left: 1.5em">
                          {{doc.documentTypeDescription}} - {{doc.documentDOE}}
                        </div>
                      </ng-template>
                    </div>
                  </ng-template>

                </div>
              </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="missingTravelDocuments && missingTravelDocuments.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em; margin-top: .25em; font-size: medium"
                    class="material-icons-outlined">
                    description
                  </span>
                  {{missingTravelDocuments.length}} Missing Manifest {{missingTravelDocuments.length==1?
                  'Document':'Documents'}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-obj [ngForOf]="missingTravelDocAlerts">
                <div style="font-size: small; margin-left: 1.7em">
                  {{obj.categoryName}}
                  <ng-template ngFor let-person [ngForOf]="obj.alertObject">
                    <div style="font-size: small; margin-left: 1.5em" *ngIf="person.isBellAlert">
                      {{person.firstName}} {{person.MiddleName}} {{person.lastName}}
                    </div>
                  </ng-template>

                </div>
              </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="waitingTaskList && waitingTaskList.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em; margin-top: .25em; font-size: medium"
                    class="material-icons-outlined">
                    assignment
                  </span>
                  {{waitingTaskList.length}} {{waitingTaskList.length==1? 'Task':'Tasks'}} Waiting for Client Reply
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-obj [ngForOf]="waitingClientTaskAlerts">
                <div style="font-size:small;margin-left: 1.7em">
                  {{obj.categoryName}}
                  <ng-template ngFor let-task [ngForOf]="obj.alertObject">
                    <div style="font-size:small;margin-left: 1.5em">
                      {{task.serviceTypeDescription}}
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="waitingVendorTaskList && waitingVendorTaskList.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em; margin-top: .25em; font-size: medium"
                    class="material-icons-outlined">
                    assignment
                  </span>
                  {{waitingVendorTaskList.length}} {{waitingVendorTaskList.length==1? 'Task':'Tasks'}} Waiting for
                  Vendor Reply
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-obj [ngForOf]="waitingVendorTaskAlerts">
                <div style="font-size: small; margin-left: 1.7em">
                  {{obj.categoryName}}
                  <ng-template ngFor let-task [ngForOf]="obj.alertObject">
                    <div style="font-size: small; margin-left: 1.5em">
                      {{task.serviceTypeDescription}}
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="expiredACDocList && expiredACDocList.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em;margin-top:.25em; font-size: medium" class="material-icons-outlined">
                    airplane_ticket
                  </span>
                  {{expiredACDocList.length}} Expired Aircraft {{expiredACDocList.length==1? 'Document':'Documents'}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-doc [ngForOf]="expiredACDocList">
                <div style="font-size: small; margin-left: 1.7em">
                  {{doc.documentName}} ({{doc.documentTypeDescription}}) - {{doc.expirationDate}}
                </div>
              </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="expiredCustomerDocList && expiredCustomerDocList.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em;margin-top:.25em; font-size: medium" class="material-icons-outlined">
                    business
                  </span>
                  {{expiredCustomerDocList.length}} Expired Client {{expiredCustomerDocList.length==1?
                  'Document':'Documents'}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-doc [ngForOf]="expiredCustomerDocList">
                <div style="font-size: small; margin-left: 1.7em">
                  {{doc.documentName}} ({{doc.documentTypeDescription}}) - {{doc.expirationDate}}
                </div>
              </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="missingSSNList && missingSSNList.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em;margin-top:.25em; font-size: medium" class="material-icons-outlined">
                    business
                  </span>
                  {{missingSSNList.length}} {{missingSSNList.length==1? 'Leg':'Legs'}} Missing SSN Task
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-obj [ngForOf]="ssnAlerts">
                <div style="font-size: small; margin-left: 1.7em">
                  {{obj.categoryName}}
                </div>
              </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="openTaskList && openTaskList.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em; margin-top: .25em; font-size: medium"
                    class="material-icons-outlined">
                    assignment
                  </span>
                  {{openTaskList.length}} {{openTaskList.length==1? 'Task':'Tasks'}} Open on Cancelled Stops
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-obj [ngForOf]="openTaskAlerts">
                <div style="font-size: small; margin-left: 1.7em">
                  {{obj.categoryName}}
                  <ng-template ngFor let-task [ngForOf]="obj.alertObject">
                    <div style="font-size: small; margin-left: 1.5em">
                      {{task.serviceTypeDescription}}
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="missingBRTaskList && missingBRTaskList.length>0">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'">
                <mat-panel-title>
                  <span style="margin-right: .25em; margin-top: .25em; font-size: medium"
                    class="material-icons-outlined">
                    assignment
                  </span>
                  {{missingBRTaskList.length}} Missing Business Rule {{missingBRTaskList.length==1? 'Task':'Tasks'}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-obj [ngForOf]="missingBRTaskAlerts">
                <div style="font-size: small; margin-left: 1.7em">
                  {{obj.categoryName}}
                  <ng-template ngFor let-task [ngForOf]="obj.alertObject">
                    <div style="font-size: small; margin-left: 1.5em">
                      {{task.serviceTypeDescription}}
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

      </div>
    </div>
  </form>
</div>
<div style="z-index:100; top: 23.5em ; left: 22em; position:absolute; min-width: 20em">
  <app-spin *ngIf="showSpin"></app-spin>
</div>