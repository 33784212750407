import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CustomerService } from "../services/customer.service";
import { DialogService } from "../services/dialog.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { forkJoin } from "rxjs";
import { ResponseModel } from "../models/response.model";
import { PersonRevisionModel } from "../models/person-revision-model";
import { formatDate } from "@angular/common";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { AuthenticateService } from "../services/authenticate.service";
import { ManageContactDetailsComponent } from "./manage-contact-details.component";
import { ActivatedRoute } from "@angular/router";
import { GlobalSettingsDashboardComponent } from "../common-utility/global-settings-dashboard.component";

@Component({
  selector: 'app-person-audit-report',
  templateUrl: './person-audit-report.component.html',
  styleUrls: ['./person-audit-report.component.css']
})

export class PersonAuditReportComponent implements OnInit {
  selectedClientName: string = "";
  selectedClientGUID: string = "";
  isDialog: boolean = false;
  isInternalFullVersion: boolean = false;
  showClientAccountList: boolean = false;
  showPersonRecordList: boolean = false;
  showChangeTypeList: boolean = false;
  showUsernameList: boolean = false;
  effectiveDateRangeStart: Date;
  effectiveDateRangeEnd: Date;
  personType: string = "All";
  recordList: PersonRevisionModel[];
  filteredRecordList: PersonRevisionModel[];
  distinctClientAccounts: any[] = [];
  selectedClientAccounts: string = "";
  distinctPersonRecords: any[] = [];
  selectedPersonRecords: string = "";
  distinctChangeTypes: any[] = [];
  selectedChangeTypes: string = "";
  distinctUsernames: any[] = [];
  selectedUsernames: string = "";
  totalRecords: number = 0;
  displayedColumn: string[] = ['transaction_date_username', 'person_record', 'type', 'change_type', 'change_request'];
  displayedColumn2: string[] = ['transaction_date_username', 'client_acct', 'person_record', 'type', 'change_type', 'change_request'];
  customer_audit_report_form: UntypedFormGroup;
  clientAccountFilterText: string = "";
  personRecordFilterText: string = "";
  changeTypeFilterText: string = "";
  usernameFitlerText: string = "";

  constructor(private readonly _dialog: MatDialog,
    private readonly _customerService: CustomerService,
    private readonly _dialogService: DialogService,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _authService: AuthenticateService,
    private readonly _route: ActivatedRoute,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private readonly _dialogRef: MatDialogRef<PersonAuditReportComponent>) {

  }

  ngOnInit() {
    this.effectiveDateRangeStart = this.calculateWeekStart();
    this.effectiveDateRangeEnd = new Date();

    if (this.data) {
      this.isDialog = true;
      this.selectedClientName = this.data.customerName;
      this.selectedClientGUID = this.data.customerGUID;
      this.isInternalFullVersion = this.data.isInternalFullVersion;
    }

    else {
      this.isDialog = false;
      this._route.queryParams.subscribe(params => {
        if (params["customerGUID"] != null && params["customerGUID"] != undefined) {
          this.selectedClientGUID = params["customerGUID"];
        }
        if (params["customerName"] != null && params["cutomerName"] != undefined) {
          this.selectedClientName = params["customerName"];
        }
      });
    }

    if(this.isInternalFullVersion) {
      this.displayedColumn = this.displayedColumn2;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.recordList = responses[0].model;
          this.filteredRecordList = this.recordList;
          this.distinctClientAccounts = [...new Map(this.filteredRecordList.map(item =>[item['customerName'], {customerName: item['customerName'], selected: false}])).values()];
          this.distinctPersonRecords = [...new Map(this.filteredRecordList.map(item =>[item['personRecord'], {personRecord: item['personRecord'], selected: false}])).values()];
          this.distinctChangeTypes = [...new Map(this.filteredRecordList.map(item =>[item['changeType'], {changeType: item['changeType'], selected: false}])).values()];
          this.distinctUsernames = [...new Map(this.filteredRecordList.map(item =>[item['userName'], {userName: item['userName'], selected: false}])).values()];
          this.totalRecords = this.filteredRecordList.length;
        }
        else {
          if (responses[0].code == "401") {
            //this._authService.signOut();
          }
        }
      }
    })

    this.initControls();

    this.f.effectiveDateRangeStart.setValue(formatDate(this.effectiveDateRangeStart, 'yyyy-MM-dd', 'en'));
    this.f.effectiveDateRangeEnd.setValue(formatDate(this.effectiveDateRangeEnd, 'yyyy-MM-dd', 'en'));
  }

  initControls() {
    this.customer_audit_report_form = this._formBuilder.group({
      effectiveDateRangeStart: [this.effectiveDateRangeStart],
      effectiveDateRangeEnd: [this.effectiveDateRangeEnd],
      personType: [this.personType]
    })
  }

  getAllData() {
    let getCustomerAuditReport = this._customerService.getCustomerAuditReport<ResponseModel<PersonRevisionModel[]>>(this.selectedClientGUID, 
      formatDate(this.effectiveDateRangeStart, 'MM/dd/yyyy', 'en'), formatDate(this.effectiveDateRangeEnd, 'MM/dd/yyyy', 'en'), 
      this.personType, this.selectedClientAccounts, this.selectedPersonRecords, this.selectedChangeTypes, this.selectedUsernames);

    return forkJoin([getCustomerAuditReport]);
  }

  getPersonAuditReport(filter: string = null) {
    this.concatSelectedFilters(filter);

    this._customerService.getCustomerAuditReport<ResponseModel<PersonRevisionModel[]>>(this.selectedClientGUID, 
      formatDate(this.f.effectiveDateRangeStart.value, 'MM/dd/yyyy', 'en'), formatDate(this.f.effectiveDateRangeEnd.value, 'MM/dd/yyyy', 'en'), 
      this.f.personType.value, this.selectedClientAccounts, this.selectedPersonRecords, this.selectedChangeTypes, this.selectedUsernames).toPromise().then(response => {
      this.recordList = response.model;
      this.filteredRecordList = this.recordList;
      if(filter == null || (this.selectedClientAccounts == '' && this.selectedPersonRecords == '' && this.selectedChangeTypes == '' && this.selectedUsernames == '')) {
        this.distinctClientAccounts = [...new Map(this.filteredRecordList.map(item =>[item['customerName'], {customerName: item['customerName'], selected: false}])).values()];
        this.distinctPersonRecords = [...new Map(this.filteredRecordList.map(item =>[item['personRecord'], {personRecord: item['personRecord'], selected: false}])).values()];
        this.distinctChangeTypes = [...new Map(this.filteredRecordList.map(item =>[item['changeType'], {changeType: item['changeType'], selected: false}])).values()];
        this.distinctUsernames = [...new Map(this.filteredRecordList.map(item =>[item['userName'], {userName: item['userName'], selected: false}])).values()];
      }
      this.totalRecords = this.filteredRecordList.length;
    });
  }

  get f() { return this.customer_audit_report_form.controls; }

  calculateWeekStart() {
    this.effectiveDateRangeStart = new Date();
    var day = this.effectiveDateRangeStart.getDay(),
    diff = this.effectiveDateRangeStart.getDate() - day + (day == 0 ? -6 : 0);
    return new Date(this.effectiveDateRangeStart.setDate(diff));
  }

  concatSelectedFilters(filter: string) {
    switch(filter) {
      case 'Client Accounts':
        this.selectedClientAccounts = '';
        this.clientAccountFilterText = '';
        var count = 0;
        this.distinctClientAccounts.forEach(record => {
          if(record.selected) {
            count++;
            this.selectedClientAccounts += record.customerName + ';'
            this.clientAccountFilterText = ': ' + count
          }
        })
        break;
      case 'Person Records':
        this.selectedPersonRecords = '';
        this.personRecordFilterText = '';
        var count = 0;
        this.distinctPersonRecords.forEach(record => {
          if(record.selected) {
            count++;
            this.selectedPersonRecords += record.personRecord + ','
            this.personRecordFilterText = ': ' + count
          }
        })
        break;
      case 'Change Types':
        this.selectedChangeTypes = '';
        this.changeTypeFilterText = '';
        var count = 0;
        this.distinctChangeTypes.forEach(record => {
          if(record.selected) {
            count++;
            this.selectedChangeTypes += record.changeType + ','
            this.changeTypeFilterText = ': ' + count
          }
        })
        break;
      case 'Usernames':
        this.selectedUsernames = '';
        this.usernameFitlerText = '';
        var count = 0;
        this.distinctUsernames.forEach(record => {
          if(record.selected) {
            count++;
            this.selectedUsernames += record.userName + ','
            this.usernameFitlerText = ': ' + count
          }
        })
        break;
    }
  }

  filterPersonAuditsByPersonType(personType: string) {
    this.f.personType.setValue(personType);

    this.getPersonAuditReport();
  }

  toggleFilterPopup(popupIndex: number) {
    switch(popupIndex) {
      case 0:
        this.showClientAccountList = !this.showClientAccountList;
        this.showPersonRecordList = false;
        this.showChangeTypeList = false;
        this.showUsernameList = false;
        break;
      case 1:
        this.showClientAccountList = false;
        this.showPersonRecordList = !this.showPersonRecordList;
        this.showChangeTypeList = false;
        this.showUsernameList = false;
        break;
      case 2:
        this.showClientAccountList = false;
        this.showPersonRecordList = false;
        this.showChangeTypeList = !this.showChangeTypeList;
        this.showUsernameList = false;
        break;
      case 3:
        this.showClientAccountList = false;
        this.showPersonRecordList = false;
        this.showChangeTypeList = false;
        this.showUsernameList = !this.showUsernameList;
        break;
    }
  }

  exportToExcel(): void {
    let wb = new Workbook();
    let ws = wb.addWorksheet("PersonAudit") 
    let  header = [ 'Transaction Date (z) & Username', 'Person Record', 'Type', 'Change Type', 'Change Request' ];
    ws.addRow(header);
    this.filteredRecordList.forEach(sd => { 
      ws.addRow([sd.transactionDateTime, sd.personRecord, sd.isNewRecord ? 'New Record' : 'Update', sd.changeType, sd.changeRequest]); 
    } ) 
    wb.xlsx.writeBuffer().then((data)=>{
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, "PersonAudit.xlsx");
    });
  }

  clickReset(filter: string) {
    switch(filter) {
      case 'Client Accounts':
        this.selectedClientAccounts = '';
        this.clientAccountFilterText = '';
        this.distinctClientAccounts.forEach(record => {
          record.selected = false;
        })
        break;
      case 'Person Records':
        this.selectedPersonRecords = '';
        this.personRecordFilterText = '';
        this.distinctPersonRecords.forEach(record => {
          record.selected = false;
        })
        break;
      case 'Change Types':
        this.selectedChangeTypes = '';
        this.changeTypeFilterText = '';
        this.distinctChangeTypes.forEach(record => {
          record.selected = false;
        })
        break;
      case 'Usernames':
        this.selectedUsernames = '';
        this.usernameFitlerText = '';
        this.distinctUsernames.forEach(record => {
          record.selected = false;
        })
        break;
    }

    this.getPersonAuditReport();

    this.showChangeTypeList = false;
    this.showClientAccountList = false;
    this.showPersonRecordList = false;
    this.showUsernameList = false;
  }

  clickResetAll() {
    this.clickReset('Client Accounts');
    this.clickReset('Person Records');
    this.clickReset('Change Types');
    this.clickReset('Usernames');

    this.getPersonAuditReport();

    this.showChangeTypeList = false;
    this.showClientAccountList = false;
    this.showPersonRecordList = false;
    this.showUsernameList = false;
  }

  clickDone(filter: string) {
    this.getPersonAuditReport(filter);

    this.showChangeTypeList = false;
    this.showClientAccountList = false;
    this.showPersonRecordList = false;
    this.showUsernameList = false;
  }

  openManageContactDetailsComponent() {
    if(this.isDialog){  
      this._dialogRef.close(true);
    }      
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.height = "auto";
    config.maxHeight = "80em";
    config.width = "81em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = {left: "10px", top:"140px"};
    if(this.selectedClientGUID === '') {
      config.maxWidth = "92em";
      config.height = "41.5em";
      config.width = "45em";
      config.data = { isDialog: true, v: this._authService.getCurrentTimeNumber()   };
      this._dialog.open(GlobalSettingsDashboardComponent, config);
    }
    else {
      config.data = { customerGUID: this.selectedClientGUID, tripCodeId: "",  isDialog: true, v: this._authService.getCurrentTimeNumber()   };
      this._dialog.open(ManageContactDetailsComponent, config);
    }
  }

  clickRouteNew(route: string, display: string) {
    this.clickClose(true);
    window.open(route + "?customerGUID=" + this.selectedClientGUID + "&customerName=" + this.selectedClientName + "&v=" + this._authService.getCurrentTimeNumber());
  }

  clickClose(val) {
    this._dialogRef.close(val);
  }
}