export class MessageTextModel {
  tripCodeGuid: string;
  groundStopGuid: string;
  previousGroundStopGuid: string;
  groundStopGuidList: string;
  groundStopTaskId: string;
  messageTemplateText: string;
  subjectLine: string;
  messageTemplateID: number;
  isMultipleHandling: boolean;
  serviceTypeID: number;
  messageTo: string;
  messageCc: string;
  serviceArrangementID: number;
  vendorGuid: string;
}
