import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';


@Component({
  selector: 'app-template-legend-dialog',
  templateUrl: './template-legend-dialog.component.html',
  styleUrls: ['./template-legend-dialog.component.css']
})

export class TemplateLegendDialogComponent implements OnInit {
  dialogTitle: string;
  message: string;
  buttonText1: string;
  buttonText2: string;
  totalCount: number;
  expiredCount: number;
  activeCount: number;

  constructor(private _dialogRef: MatDialogRef<TemplateLegendDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any) {
    _dialogRef.disableClose = true;
    this.dialogTitle = _data.dialogTitle;
    this.message = _data.message;
    this.expiredCount = _data.expiredCount;
    this.totalCount = _data.totalCount;
    this.activeCount = this.totalCount-this.expiredCount;
  }

  ngOnInit() {

  }

  close(str: string) {
    this._dialogRef.close(str);
  }
}
