import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TemplateDashboardComponent } from '../templates/template-dashboard.component';
import { CountryDocumentTypeUpdateDialog } from '../country/country-document-type-update-dialog.component';
import { AuthenticateService } from '../services/authenticate.service';
import { ApisAccountComponent } from '../apis/apis-account.component';
import { CountryLocatorComponent } from '../country/country-locator.component';
import { MaintenanceDashboardComponent } from './maintenance-dashboard.component';
import { AirportRevisionModalComponent } from '../airports/airport-revision-modal-dialog.component';
import { ManageAirportsComponent } from '../airports/manage-airports.component';
import { DialogService } from '../services/dialog.service';
import { PersonAuditReportComponent } from '../persons/person-audit-report.component';

@Component({
  selector: 'app-global-settings-dashboard',
  templateUrl: './global-settings-dashboard.component.html',
  styleUrls: ['./global-settings-dashboard.component.css']
})

export class GlobalSettingsDashboardComponent implements OnInit {
  // userType: string;
  // tripRevisionID: number;
  // userComments: string;
  global_settings_dashboard_form: UntypedFormGroup;
  // loading = false;
  // v: number = this._authService.getCurrentTimeNumber();
  // returnObj = new ReturnObjModel();
  // controlValueChanged: boolean = false;
  // private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  // private eventSubscription: Subscription;
  isDialog: boolean = false;
  username: string;
  upAirportCountry: boolean = false;
  upMsgTemplates: boolean = false;

  constructor(
    @Optional() private readonly _dialogRef: MatDialogRef<GlobalSettingsDashboardComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly dialog: MatDialog,
    private readonly _dialogService:DialogService
  ) {

    // this.tripRevisionID = _data.tripRevisionID;
    if (this.data) {
      this.username = this.data.username;
      this.isDialog = true;
    }
  }

  ngOnInit() {

    this._authService.updateAccessTime();
    this.upAirportCountry = false;
    
    if (localStorage.getItem('up.airportDoc') == 'true') {
      this.upAirportCountry = true;
    }

    if(localStorage.getItem('up.msgTemplates') == 'true') {
      this.upMsgTemplates = true;
    }

  }

  initControls() {
    this.global_settings_dashboard_form = this._formBuilder.group({
      // userComments: [this.userComments]
    });


  }

  get f() { return this.global_settings_dashboard_form.controls; }




  clickClose(val) {
    this._dialogRef.close(val);
  }


  openTemplateComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    //config.disableClose = true;

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(TemplateDashboardComponent, config);
  }

  openCountryDocumentsPicklistComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "43em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(CountryDocumentTypeUpdateDialog, config);
  }


  clickRouteNew(route: string, display: string) {
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }


  openAPISComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    //config.disableClose = true;

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(ApisAccountComponent, config);
  }

  openMessageTemplate() {
    if (!this.upMsgTemplates) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }

    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    //config.disableClose = true;

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    if (this.isDialog) {
      this.clickClose(true);
    }
    this.dialog.open(TemplateDashboardComponent, config);
  }

  openCountryLocator() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px" };
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber(), username: this.username, };
    this.dialog.open(CountryLocatorComponent, config);
  }

  openMaintenanceComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "31em";
    config.width = "43em";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    config.position = { left: "10px", top: "140px" };
    if (this.isDialog) {
      this.clickClose(true);
    }
    this.dialog.open(MaintenanceDashboardComponent, config);
  }

  openAirportRevisionModal(isNoteRevisions: boolean) {
    if (!this.upAirportCountry) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }

    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "38em";
    config.width = "70em";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), username: this.username, isNoteRevisions: isNoteRevisions };
    config.position = { left: "10px", top: "140px" };
    if (this.isDialog) {
      this.clickClose(true);
    }


    this.dialog.open(AirportRevisionModalComponent, config);
  }


  openManageAirports() {
    if (!this.upAirportCountry) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
      const config = new MatDialogConfig();
      config.panelClass = "custom-dialog-container"
      config.minWidth = "91em";//"1000px";
      config.restoreFocus = false;
      config.hasBackdrop = false;
    config.position = { left: "100px" };
    if (this.isDialog) {
      this.clickClose(true);
    }
      this.dialog.open(ManageAirportsComponent, config);
    }

    openPersonAuditReport() {
      const config = new MatDialogConfig();
      config.panelClass = "custom-dialog-container3"
      //config.disableClose = true;
      config.height = "48em";//"1000px";
      //config.maxWidth = "65em";//"1000px";
      config.width = "82em";//"1000px";
  
      // config.minHeight = "20em";
      config.restoreFocus = false;
      config.hasBackdrop = false;
      config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: "", customerName: "", userType: "", isInternalFullVersion: true };
      config.position = { left: "10px", top: "140px" };
  
      if (this.isDialog) {
        this.clickClose(true);
      }
  
      this.dialog.open(PersonAuditReportComponent, config);
    }
}
