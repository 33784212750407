<div *ngIf="upPII && grantAccessToManageManifestProfiles" style="background-color:#f8f8f8;" >
  <div style="display: flex; justify-content: space-between;" cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div style="padding-left:1em;margin-top:0.3125em;"><h5><!--Travel-->Document - Passport</h5></div>
    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
    <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
    </div>-->
  </div>
  <form *ngIf="person_passport_form" [formGroup]="person_passport_form">
    <div style="margin-left:1em; padding-top:0.5em; padding-bottom:2em;margin-right:1em; background-color:white;border: 0.0625em lightgrey solid;">
      <div>
        <div style="display:flex; margin-top:0em; padding-left: 1em;padding-right:1em">
          <div style="background-color:#f8f8f8; padding:0.25em; padding-top:0em; display:flex;border: 0.0625em lightgrey solid;">
            <div style="padding-left: 0.5em">
              <div style="display:flex;">
                <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="issing_country_select" style="font-size:small">Issuing Country</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    <input type="text" formControlName="issing_country_select" class="form-control" readonly style="padding: 0em;width:12em;height:1.5625em;font-size:small;background-color:white" />
                  </div>
                </div>
                <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="documentNumber" style="font-size:small">Document Number</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    <input type="text" formControlName="documentNumber" class="form-control" readonly style="padding: 0em;width:10em;height:1.5625em;font-size:small;background-color:white" />
                  </div>
                </div>
                <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="effectiveDate" style="font-size:small">Effective Date</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    <input type="text" formControlName="effectiveDate" class="form-control" readonly style="padding: 0em;width:8em;height:1.5625em;font-size:small;background-color:white" />
                  </div>
                </div>
                <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                  <label for="expirationDate" style="font-size:small">Expiration Date</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    <input type="text" formControlName="expirationDate" class="form-control" readonly style="padding: 0em;width:8em;height:1.5625em;font-size:small;background-color:white" />
                  </div>
                </div>
              </div>
              <div style="display:flex">
                <div>
                  <div style="display:flex">
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                      <label for="firstName" style="font-size:small">First Name</label>
                      <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        <input type="text" formControlName="firstName" class="form-control" readonly style="padding: 0em;width:10em;height:1.5625em;font-size:small;background-color:white" />
                      </div>
                    </div>
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                      <label for="middleName" style="font-size:small">Middle Name</label>
                      <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        <input type="text" formControlName="middleName" class="form-control" readonly style="padding: 0em;width:10em;height:1.5625em;font-size:small;background-color:white" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                      <label for="lastName" style="font-size:small">Last Name</label>
                      <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        <input type="text" formControlName="lastName" class="form-control" readonly style="padding: 0em;width:21em;height:1.5625em;font-size:small;background-color:white" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                    <label for="remarks" style="font-size:small">Remarks</label>
                    <div style="margin-left: 0em; margin-top:-0.5em">
                      <textarea formControlName="remarks" class="form-control" readonly maxlength=255 style="width:18em; height:6em; font-size:small; padding:0em 0em 0em 0em;background-color:white">   
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                <label for="doc_usage_rule_select" style="font-size:small">When to use</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="doc_usage_rule_select" class="form-control" readonly style="padding: 0em;width:15em;height:1.5625em;font-size:small;background-color:white" />
                </div>
              </div>
              <div style="margin-right:1em">
                <label for="modifiedBy" style="font-size:small">Modified By</label>
                <div style="margin-top: -0.5em;">
                  <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:10em;height:1.5625em;font-size:small;padding: 0em;" />
                </div>
              </div>
              <div style="margin-right:1em">
                <label for="modifiedDate" style="font-size:small">Modified Date</label>
                <div style="margin-top: -0.5em;">
                  <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:10em;height:1.5625em;font-size:small;padding: 0em;" />
                </div>
              </div>

            </div>
            <div>
              <div style="font-size:small;margin-right: 0.625em;">Selected Country</div>
              <div style="width:16em; height:6em; font-size: small; border: 0.0625em lightgrey solid; border-radius:0.25em; background-color:white; overflow:auto; white-space:pre-wrap;margin-top:0.5em; margin-right: 0.625em;">
                <div *ngFor="let sc of selectedCountryList">
                  {{sc.countryName}}
                </div>
              </div>
              <div style="display:flex">
                <div style="margin-top:0.5em;">
                  <button *ngIf="hasDocument" type="button" mat-button class="btn-high" (click)="clickPreviewDocument()">Preview</button>
                </div>
                <div style="margin-left:1em; font-size: small">
                  <div style="margin-top:0.25em">Image Maximum</div><div style="margin-top:-0.25em">size 25MB</div>
                </div>
              </div>
            </div>
            <div>
              <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                <label for="person_doc_status" style="font-size:small">Active</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="person_doc_status" class="form-control" readonly style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:white" />
                </div>
              </div>            
                <!--<div style="margin-top:1em;">
                  <button *ngIf="hasDocument" type="button" class="btn btn-primary" (click)="clickPreviewDocument()">Preview</button>
                </div>-->                              
              <div>
                <div style="margin-top:1em;">
                  <button type="button" mat-button class="btn-high" *ngIf="userType=='internal'" (click)="clickEditPassport()">Edit</button>
                </div>
                <div style="margin-top:1em;">
                  <button type="button" mat-button class="btn-low" *ngIf="userType=='customer'" (click)="clickEditPassport()">View</button>
                </div>
              </div>
              </div>


          </div>

        </div>
        <div style="margin-left:1em;" *ngIf="errMsg!=''"><label style="color:red; font-size: small">{{errMsg}}</label></div>
      </div>
      <div style="margin-left:1em; display:flex">
        <div style="margin-top:0.3125em;"><h5>Visas</h5></div>

        <div *ngIf="userType=='internal'" style="display:flex; margin-left: 4em; margin-top:0.75em">
          <input type="checkbox" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)" /><label for="includeDisabledRecords" style="font-size:small;margin-top:-0.25em">&nbsp;Include disabled records</label>
        </div>
      </div>
      <div style="margin-left:1em;">
        <div>
          
          <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="documentNumber">
                <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Number</div>
                </th>
                <td mat-cell *matCellDef="let element"><a *ngIf="element.hasDocument" style="color:blue; cursor:pointer; text-decoration:underline" (click)="clickPreviewSubDocument($event, element)">{{element.documentNumber}}</a>{{element.hasDocument? '': element.documentNumber}}</td>
              </ng-container>
              <ng-container matColumnDef="issuingCountry">
                <th mat-header-cell *matHeaderCellDef style="width:19%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Issuing&nbsp;Country</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.documentIssuingCountryName}}</td>
              </ng-container>
              <ng-container matColumnDef="effectiveDate">
                <th mat-header-cell *matHeaderCellDef style="width:8%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Effective&nbsp;Date</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.documentDOI}}</td>
              </ng-container>
              <ng-container matColumnDef="expirationDate">
                <th mat-header-cell *matHeaderCellDef style="width:8%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Expiration&nbsp;Date</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.documentDOE}}</td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef style="width:15%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Name Used</div>
                </th>
                <td mat-cell *matCellDef="let element">{{element.fullName}}</td>
              </ng-container>
              <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Notes</div>
                </th>
                <td mat-cell *matCellDef="let element"><a *ngIf="element.documentBriefNotes.length>15" style="cursor:pointer" matTooltipClass="tooltipLineBreak" matTooltip="{{element.documentBriefNotes}}">{{ element.documentBriefNotes.substring(0,14)+'...' }}</a><span *ngIf="element.documentBriefNotes.length<=15">{{element.documentBriefNotes}}</span></td>
              </ng-container>
              <ng-container matColumnDef="data_entry_status">
                <th mat-header-cell *matHeaderCellDef style="width:10%">
                  <div style="margin-top:-0.25em;">Data Entry Status</div>
                </th>
                <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger': element.dataEntryStatusID!=2}">{{ element.dataEntryStatusDescription }}</td>
              </ng-container>
              <ng-container matColumnDef="disabled">
                <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:7%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Disabled</div>
                </th>
                <td mat-cell *matCellDef="let element" [hidden]="hideColumn">{{element.isActive? '&nbsp;': 'Yes'}}</td>
              </ng-container>
              <!--<ng-container matColumnDef="preview">
                <th mat-header-cell *matHeaderCellDef style="width:8%; border-bottom:none">
                  <div style="margin-top:-0.25em;">Preview</div>
                </th>
                <td mat-cell *matCellDef="let element"><a *ngIf="element.hasDocument" style="color:blue; cursor:pointer; text-decoration:underline" (click)="clickPreviewSubDocument($event, element)">Preview</a>{{element.hasDocument? '': '&nbsp;'}}</td>
              </ng-container>-->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                  <div>Action</div><div style="margin-top:0em;padding-bottom:0.25em"><a mat-button class="btn-high grid" style="align-self:center" (click)="clickEditVisa($event, null);">Add</a></div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a mat-button class="btn-low grid" *ngIf="userType=='internal'" style="align-self:center" (click)="clickEditVisa($event, element);">
                    Edit
                  </a>
                  <a mat-button class="btn-low grid" *ngIf="userType=='customer'" style="align-self:center" (click)="clickEditVisa($event, element);">
                    View
                  </a>
                </td>
              </ng-container>
            </tr>

            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
        <!--<div>
          <mat-paginator style="border: none;width:87em;margin-top:1em" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSize]="pageSize"
                         [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
          </mat-paginator>
        </div>-->
      </div>
    </div>
  </form>
  <div style="justify-content:flex-start;padding-left: 2em; padding-top:0.5em; padding-bottom:0.5em;">
    <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white">Close</button>&nbsp;
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
