<div style="display: inline-flex; width: 89em; justify-content: space-between; padding-bottom: 0.5em; " cdkDrag
  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isDialog">
  <div style="margin-left: 1em;margin-top:-1em; display: inline-flex;">
    <span class="material-symbols-outlined"
      style="font-size: xx-large; margin-left: -0.3em; margin-top: 0.5em;">dataset_linked</span>

    <h3 style="margin-top: 0.5em; margin-left: 0.5em;">
      Message Templates
    </h3>

  </div>
  <div>
    <div *ngIf="isDialog" style="display: flex; justify-content: end;">
      <button mat-icon-button (click)="openGlobalSettingsDashboardComponent()"> <i class="material-icons-outlined"
          style="font-size:xx-large;  ">arrow_circle_left</i> </button>
      <!-- <button mat-icon-button (click)="clickRouteNew('template/template-dashbord','')"> <i class="material-icons"
          style="font-size:x-large;  ">open_in_new</i> </button> -->

      <button mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i>
      </button>
    </div>
  </div>
</div>

<div style="background-color: white; padding-left: 1em; padding-right: 1em; padding-bottom: 1em;">
  <div *ngIf="isDialog" style="display: inline-flex; padding-bottom: 0.5em; width: 98%; justify-content: space-between;">
    <mat-slide-toggle style="transform:scale(.8); height:3em; margin-left: -1em;" color="primary"
      [checked]="includeDisabledTemplate" (change)="includeDisabledTemplateChange($event)">Show Disabled
      Templates</mat-slide-toggle>
    <div style="font-size: small; margin-top: 1.25em;">Total Records: {{totalRecords}}</div>
  </div>
  <div style="height: 28em; width: 87em;; overflow-y: auto; overflow-x: hidden;">
    <table mat-table [dataSource]="filteredRecordList" class="example-container"
      style="width: 86em !important; overflow-y: scroll;">
      <tr>
        <ng-container matColumnDef="templateName">
          <th mat-header-cell *matHeaderCellDef style="text-align:left !important; width: 10%">
            Template Name
          </th>
          <td mat-cell *matCellDef="let element" style="text-align:left !important; white-space:pre-wrap">
            {{element.messageTemplateName}}</td>
        </ng-container>
        <ng-container matColumnDef="services">
          <th mat-header-cell *matHeaderCellDef style="text-align:left !important; width: 35%">
            Services
          </th>
          <td mat-cell *matCellDef="let element" style="text-align:left !important; white-space:pre-wrap">
            {{element.serviceList}}</td>
        </ng-container>
        <ng-container matColumnDef="multipleLegs">
          <th mat-header-cell *matHeaderCellDef style="width: 4%">
            Multiple Legs
          </th>
          <td mat-cell *matCellDef="let element">{{element.isMultipleHandling == true? 'Yes':''}}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="width: 3%" [hidden]="!isAdmin">
            <a mat-button class="btn-high grid" (click)="clickEdit(null, $event);">
              Add
            </a>
          </th>
          <td mat-cell *matCellDef="let element; let i=index " [hidden]="!isAdmin">
            <a mat-button class="btn-low grid" (click)="clickEdit(element, $event);">
              Edit
            </a>
          </td>
        </ng-container>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickEdit(row, $event);"></tr>
    </table>
  </div>
</div>
<!-- <mat-dialog-actions align="left">
  <button mat-dialog-close mat-button class="btn-low">Close</button>
</mat-dialog-actions> -->

<app-spin *ngIf="isLoading"></app-spin>