import { CustomerModel } from "./customer.model";
import { GroundStopTaskModel } from "./ground-stop-task.model";

export class TripRevisionModel {
  tripRevisionID: number;
  tripCodeGUID: string;
  customerGUID: string;
  aircraftGUID: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  transactionDateTime: string;
  userName: string;
  tripCode: string;
  registration: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  icao: string;
  arrivalDateUTC: string;
  arrivalTimeUTC: string;
  arrivalDateTimeUTC: string;
  departureDateUTC: string;
  departureTimeUTC: string;
  departureDateTimeUTC: string;
  arrivalDateLocal: string;
  arrivalTimeLocal: string;
  arrivalDateTimeLocal: string;
  departureDateLocal: string;
  departureTimeLocal: string;
  departureDateTimeLocal: string;
  isNewTrip: boolean;
  isTripOpened: boolean;
  isTripLockDown: boolean;
  isRemoveTripFromExternalView: boolean;
  isTripCancellation: boolean;
  isNewGroundStop: boolean;
  isGroundStopCancellation: boolean;
  isAircraftChange: boolean;
  isAirportChange: boolean;
  isArrivalDateTimeChange: boolean;
  isDepartureDateTimeChange: boolean;
  isCrewChange: boolean;
  isPAXChange: boolean;
  isTaskStatusChange: boolean;
  isTaskVendorChange: boolean;
  isTaskConfirmationChange: boolean;
  isTaskNotesChange: boolean;
  isTaskDocumentsChange: boolean;
  isTaskCountryChange: boolean;
  isTaskRevisionRequiredChange: boolean;
  isTaskRevisionCounterChange
  changeRequest: string;
  userComments: string;
  pastHours: number;
  groundStopGUIDList: string;
  clientCount: number;
  tripCodeCount: number;
  transactionCount: number;
  customerList: CustomerModel[]
  expand: boolean;
  iata: string;
  serviceTypeDescription: string;
  isRevisionRequired: boolean;
  airportServiceTaskList: GroundStopTaskModel[];
  flightPlanServiceTaskList: GroundStopTaskModel[];
  preflightServiceTaskList: GroundStopTaskModel[];
  flightFollowingTaskList: GroundStopTaskModel[];
  includeDemo: boolean;
  isRevisionRequiredValue: number;
  taskStatusGUID: string;
  isClientInstructionsChange: boolean;
  isExternal: boolean;
  isTripNotesChange: boolean;
  nextArrivalGroundStopGUID: string;
  tripOpenCount: number;
  isMobile: boolean;
  showMobileOnly: boolean;
  isFuelQuoteChange: boolean;
  isSequenceChange: boolean;
}
