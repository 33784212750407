import { CountryModel } from "./country.model";

export class PersonTravelDocumentModel {
  personTravelDocumentGUID: string;
  personGUID: string;
  documentTypeDescription: string;
  documentTypeGUID: string;
  documentNumber: string;
  documentIssuingCountryGUID: string;
  documentIssuingCountryName: string;
  documentDOI: string;
  documentDOE: string;
  documentBriefNotes: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  lastName: string;
  firstName: string;
  middleName: string;
  fullName: string;
  documentUsageRuleGUID: string;
  documentUsageRule: string;
  countryGUIDList: string;
  countryNameList: string;
  documentSizeInKB: number;
  fileSize: number;
  bFile: string;
  fileExtension: string;
  mimeType: string;
  hasDocument: boolean;
  subDocumentCount: number;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  personTravelSubDocumentGUID: string
  countryList: CountryModel[];
  personTravelDocumentInfo: string;
  visaCountryName: string;
  isExpired: boolean;
  dataEntryStatusID: number;
  dataEntryStatusDescription: string;
  removeDocument: boolean;
  filterDataEntryStatus: boolean;
  customerGUID: string;
  
}
