import { FuelPriceModel } from "./fuelprice.model";

 
export class FuelPriceFileModel {  //extends FuelPriceModel{
  AttachmentGUID: string;
  documentName: string;
  fileExtension: string;
  documentSizeInKB: number;
  containerPath: string;
  fileSize: number;
  bFile: string;
  mimeType: string;
  tempId: number;
  errMsg: string;
  groundStopTaskDocumentGUID: string;
  documentGUID: string; 
  fuelSupplier: string; 
  modifiedBy: string; 

  unitOfMeasureID: number;
  currency: string;
  taxesIncludedDom: string;
  taxesIncludedInt: string;
  expiryDom: string;
  expiryInt: string;
  missingColumns: string[];
  expiredRecordsCount: number;
  totalRecordsCount: number;
  considerExpiredRecords: boolean;
  excludedCountryIds: string;
  fuelSupplierGUID: string;
  isAllDetails: boolean;
  
}
