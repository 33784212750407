import { Component, Inject, OnInit, ViewChildren, ViewContainerRef, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of } from 'rxjs';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { TripModel } from '../models/trip.model';
import { CustomerCreditStatusModel } from '../models/customer-credit-status.model';
import { IconLegendDialogComponent } from '../common-utility/icon-legend-dialog.component';
import { DialogService } from '../services/dialog.service';
import { AircraftModel } from '../models/aircraft.model';
import { AirportModel } from '../models/airport.model';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { VendorModel } from '../models/vendor.model';
import { Title } from '@angular/platform-browser';
import { MenuService } from '../services/menu.service';
import { MessageModel } from '../models/message.model';
import { MessageService } from '../services/message.service';
import { MessageLinkModel } from '../models/message-link';

@Component({
  selector: 'app-message-link',
  templateUrl: './message-link.component.html',
  styleUrls: ['./message-link.component.css'],
})

export class MessageLinkComponent implements OnInit {
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  message_link_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg1: string;
  msg: string;
  customerList: CustomerModel[];
  gsTypeList: GroundStopTypeModel[];
  selectedGSTypeList: GroundStopTypeModel[];
  customerCreditList: CustomerCreditStatusModel[];
  selectedCustomerCreditStatusID: number;
  selectedCustomerCreditList: CustomerCreditStatusModel[];
  showSpin: boolean = true;
  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  selectedTabName: string;
  reloadChildPage: boolean;
  totalRecordsText: string;
  v: number = this._authService.getCurrentTimeNumber();
  request: TripModel;

  startDate: Date;
  endDate: Date;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  pageFrom: string = "";
  sort_by: string;
  upPII: boolean = false;
  registrationList: AircraftModel[];
  selectedRegistrationList: AircraftModel[];
  showClientList: boolean = false;
  showAircraftList: boolean = false;
  airportList: AirportModel[];

  showAirportList: boolean = false;
  orgCustomerList: CustomerModel[];
  selectedCustList: CustomerModel[];
  selectedClientCountText: string = "Select Client Account";
  showLinkMsg: boolean = false;
  showIncludeTrip: boolean = false;
  selectedGSTypeCountText: string = "";
  selectedCreditStatusCountText: string = "";
  selectedTripCodeCountText: string = "";
  showGSTypeList: boolean = false;
  showCustomerCreditList: boolean = false;
  showTripCodeList: boolean = false;
  tripCodeList: TripModel[] = [];
  selectedTripCodeList: TripModel[] = [];
  websiteEnvironment: string = "";
  orgAirportList: AirportModel[] = [];
  selectedClient: string = "";

  isModified: boolean = false;
  isDraft: boolean = false;
  dialogTitle: string;
  tripCodeGUID: string;
  messageGUID: string;
  messageGUIDList: string;
  allMessageData: MessageModel[];
  isButtonVisible: boolean = true;
  showSuccessMessage: boolean = false;
  messageLinkList:MessageLinkModel[];
  successMsg: string;
  linkedTripCodedGUID: string;
  closeOut: boolean;
  currentUser:string;
  assignedTo: string;


  constructor(private readonly _dialogRef: MatDialogRef<MessageLinkComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _dialogService: DialogService, private _titleService: Title, private readonly _menuService: MenuService,
    private readonly _messageService: MessageService
  ) {

  }

  getAllData(): Observable<any[]> {
    let getGSTypeResponse = this._groundStopService.getGroundStopTypeList();
    let getCustomerCreditResponse = this._groundStopService.getCustomerCreditStatusList();
    let req = new TripModel();


    this.dialogTitle = this._data.dialogTitle;
    this.messageGUID = this._data.messageGUID;
    this.messageGUIDList = this._data.messageGUIDList;
    this.messageLinkList = this._data.messageLinkList;
    this.closeOut = this._data.closeOut;
    this.currentUser = this._data.currentUser;
    if (this.messageLinkList && this.messageLinkList.length == 1 && this.messageLinkList[0].tripCodeGUID)
      this.linkedTripCodedGUID = this.messageLinkList[0].tripCodeGUID;
    this.isDraft = false;
    if (this._data.isDraft != null && this._data.isDraft != undefined && this._data.isDraft != "")
      this.isDraft = this._data.isDraft
    this.allMessageData = [];
    if (this._data.allMessageData != null && this._data.allMessageData != undefined && this._data.allMessageData != "")
      this.allMessageData = this._data.allMessageData

    req.startDate = this.f.startDate.value;
    req.startDateText = "";
    if (this.f.startDate.value != null) {
      let templocal1: Date = new Date(this.f.startDate.value);
      let temputc1: Date;
      temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
      req.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);
    }

    req.endDate = this.f.endDate.value;
    req.endDateText = "";
    if (this.f.endDate.value != null) {
      let templocal2: Date = new Date(this.f.endDate.value);
      let temputc2: Date;
      temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
      req.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);
    }
    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      req.tripCloseOutID = 0;
    }
    else {
      req.tripCloseOutID = 1;
    }
    req.includeDemo = this.f.includeDemo.value;
    let getClientResponse;
    if (localStorage.getItem('trip.clist') != null && localStorage.getItem('trip.clist') != "") {
      getClientResponse = this._commonService.getCustomers();

    }

    else {
      getClientResponse = of(null);
    }

    let getWebsiteEnvironmentResponse = this._menuService.getWebsiteEnviroment();
    return forkJoin([getGSTypeResponse, getCustomerCreditResponse, getClientResponse, getWebsiteEnvironmentResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    else {
      this.upPII = true;
    }
    this._route.queryParams.subscribe(params => {
      this.pageFrom = params["pf"];

    });
    if (this.pageFrom == null || this.pageFrom == undefined) {
      this.pageFrom = "";
    }


    this.showSpin = true;

    this.errMsg = "";
    this.errMsg1 = "";
    this.msg = "";
    this.customerList = [];
    this.gsTypeList = [];
    this.selectedGSTypeList = [];
    this.customerCreditList = [];
    this.selectedCustomerCreditList = [];
    this.selectedCustomerCreditStatusID = 0;
    this.includeClosedBilledRecords = false;
    this.includeDemo = false;
    this.selectedTabName = 'tripcode_tactical';
    this.reloadChildPage = false;

    this.totalRecordsText = "";
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.endDate = null;
    if (this.selectedTabName != 'aircraft') {
      this.sort_by = "departureDate asc";
    }
    else {
      this.sort_by = "registration asc";
    }
    this.orgCustomerList = [];
    this.customerList = [];
    this.selectedCustList = [];
    this.registrationList = [];
    this.selectedRegistrationList = [];
    this.airportList = [];
    this.tripCodeList = [];
    this.selectedTripCodeList = [];
    let gst: string = "";
    if (this.pageFrom == "tripdetail") {

      if (localStorage.getItem('trip.sd') != null && localStorage.getItem('trip.sd') != "") {
        this.startDate = new Date(localStorage.getItem('trip.sd'));
      }
      if (localStorage.getItem('trip.ed') != null && localStorage.getItem('trip.ed') != "") {
        this.endDate = new Date(localStorage.getItem('trip.ed'));
      }

      if (localStorage.getItem('trip.tco') != null && localStorage.getItem('trip.tco') != "") {
        if (localStorage.getItem('trip.tco') == "1")
          this.includeClosedBilledRecords = false;
        else {
          this.includeClosedBilledRecords = true;
        }
      }
    }

    this.initControls();
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let g = new GroundStopTypeModel();
          g.groundStopTypeGUID = "all";
          g.groundStopTypeDescription = "Select All";
          g.selected = false;
          this.gsTypeList.push(g);
          responses[0].model.forEach(x => {
            g = new GroundStopTypeModel();
            g.groundStopTypeGUID = x.groundStopTypeGUID;
            g.groundStopTypeDescription = x.groundStopTypeDescription;
            g.selected = false;
            this.gsTypeList.push(g);
          });
          if (localStorage.getItem('trip.gstlist') != null && localStorage.getItem('trip.gstlist') != "") {
            let g: string = localStorage.getItem('trip.gstlist');
            let g1: string[] = g.split(',');
            g1.forEach(x => {
              let i: number = this.gsTypeList.findIndex(y => y.groundStopTypeGUID.toLowerCase() == x.toLowerCase());
              if (i != -1) {
                this.gsTypeList[i].selected = true;
              }
            })
          }
          else {
            this.gsTypeList.find(x => x.groundStopTypeGUID.toLowerCase() == "868a62b1-9cd6-499b-abfa-46542ccb7424").selected = true;
          }
        }

      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          let clist = responses[1].model.filter(x => x.customerCreditStatusID != 0);
          let c = new CustomerCreditStatusModel();
          c.customerCreditStatusID = 0;
          c.customerCreditStatusDesc = "Select All";
          c.selected = false;
          this.customerCreditList.push(c);
          clist.forEach(x => {
            c = new CustomerCreditStatusModel();
            c.customerCreditStatusID = x.customerCreditStatusID;
            c.customerCreditStatusDesc = x.customerCreditStatusDesc;
            c.selected = false;
            this.customerCreditList.push(c);
          })
          if (localStorage.getItem('trip.cclist') != null && localStorage.getItem('trip.cclist') != "") {
            let c2: string = localStorage.getItem('trip.cclist');
            let c1: string[] = c2.split(',');
            c1.forEach(x => {
              let i: number = this.customerCreditList.findIndex(y => y.customerCreditStatusID == Number(x));
              if (i != -1) {
                this.customerCreditList[i].selected = true;
              }
            })
          }
        }

      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          let c = new CustomerModel();
          c.customerGUID = "all";
          c.customerName = "Select All";
          c.selected = false;
          this.customerList.push(c);
          responses[2].model.forEach(x => {
            c = new CustomerModel();
            c.customerGUID = x.customerGUID;
            c.customerName = x.customerName;
            c.selected = false;
            this.customerList.push(c);
          });

          this.orgCustomerList = this.customerList;
          if (this.messageLinkList)
            this.customerList.forEach(x => {
              if (this.messageLinkList[0]?.customerGUID.toLowerCase() === x.customerGUID.toLowerCase()) {
                x.selected = true;
              } else {
                x.selected = false;
              }
            });
          this.setClientList();
        }

      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.websiteEnvironment = responses[3].model;

        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }


      this.showSpin = false;

      this.openChildPage();

    });
    this.clickClientFilter();
    this.getTripClientList(true);

  }

  initControls() {
    this.message_link_form = this._formBuilder.group({
      client_search: [''],
      person_search_keyword: [''],
      vendor_search: [''],
      includeClosedBilledRecords: [this.includeClosedBilledRecords],
      includeDemo: [this.includeDemo],
      startDate: [this.startDate],
      endDate: [this.endDate],
      sort_by: [this.sort_by],
      selectedClient:['']
    });

  }

  get f() { return this.message_link_form.controls; }

  includeClosedBilledRecordsOnChange(e: any) {
    this.includeClosedBilledRecords = e.checked; // mat checkbox    
    this.clickSubmit();
  }

  includeDemoOnChange(e: any) {
    this.includeDemo = e.checked; // mat checkbox
    this.clickSubmit();
  }

  dateOnchange(dateType: string, e: any) {
    if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidStartDate = false;
          this.f.startDate.setErrors({ isValidDate: false });
        }
        else {
          this.isValidStartDate = true;
        }
      }
      else {
        this.isValidStartDate = true;
      }
    }

    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidEndDate = false;
          this.f.endDate.setErrors({ isValidDate: false })
        }
        else {
          this.isValidEndDate = true;
        }
      }
      else {
        this.isValidEndDate = true;
      }
    }

    this.clickSubmit();

  }

  sortByChange(e: any) {
    this.clickSubmit();
  }
  // client
  clickClientFilter() {
    this.getTripClientList();
  }

  getTripClientList(oninit:boolean=false) {
    var root = this;
    this.showClientList = true;
    this.showAircraftList = false;
    this.showAirportList = false;
    this.showGSTypeList = false;
    this.showCustomerCreditList = false;
    this.showTripCodeList = false;
    this.customerList = [];
    this.orgCustomerList = [];
    let req = new TripModel();

    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      req.tripCloseOutID = 0;
    }
    else {
      req.tripCloseOutID = 1;
    }

    req.includeDemo = this.includeDemo; //this.f.includeDemo.value;
    this._commonService.getCustomers<ResponseModel<TripModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.customerList = [];
        this.orgCustomerList = [];
        if (response.model.length > 0) {
          let c = new CustomerModel();
          response.model.forEach(x => {
            c = new CustomerModel();
            c.customerName = x.customerName;
            c.customerGUID = x.customerGUID;
            c.selected = false;
            if (this.selectedCustList.length > 0) {
              let f: CustomerModel[];
              f = this.selectedCustList.filter(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
              if (f.length > 0) {
                c.selected = true;
              }
            }
            this.customerList.push(c);
          });
          this.orgCustomerList = this.customerList;
          if (this.messageLinkList && oninit) {
            c = new CustomerModel();
            c.customerGUID = this.messageLinkList[0]?.customerGUID;
            this.checkClientChange(null, c);
            
            this.showClientList = false;
            this.f.endDate.setValue(null);
            this.clickSubmit();
            this.clickTab('tripcode_detail');
          }
          this.getCustomerListBykeyword(this.f.client_search.value);

          if (this.selectedCustList.length > 0 && this.selectedCustList.length != this.customerList.length) {
            let data: CustomerModel[] = [];
            data = this.customerList;
            this.customerList = [];

            data.forEach(x => {
              if (x.customerGUID == "all") {
                this.customerList.push(x);
              }
              else {
                if (x.selected) {
                  this.customerList.push(x);
                }
              }
            });
            data.forEach(x => {
              if (!x.selected && x.customerGUID != "all") {
                this.customerList.push(x);
              }
            });
          }
        }
        else {
        }
      }
    })
  }

  checkClientChange(e: any, item: CustomerModel) {
    this.customerList.forEach(x => {
      if (item.customerGUID.toLowerCase() === x.customerGUID.toLowerCase()) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });
    //this.selectedClient = item;
    this.setClientList();
    this.reloadChildPage = false;
  }


  setClientList() {

    let excludeallclist: CustomerModel[]
    excludeallclist = this.customerList.filter(x => x.customerGUID != 'all' && x.selected == false);


    this.selectedCustList = this.customerList.filter(u => u.selected == true);
    this.selectedClientCountText = "Select Client Account";
    if (this.selectedCustList.length > 0) {
      this.selectedClientCountText = "Client: " + this.selectedCustList[0].customerName;
      this.showLinkMsg = true;
      this.showIncludeTrip = true;

    }
    else {
      this.showLinkMsg = false;
      this.showIncludeTrip = false;
    }
  }

  clientSearchChange(e: any) {
    let cname: string = e.target.value;
    if (cname != "") {
      cname = cname.toLowerCase();
    }
    this.getCustomerListBykeyword(cname);
  }

  getCustomerListBykeyword(cname: string) {

    this.customerList = this.orgCustomerList.filter(option => option.customerName.toLowerCase().includes(cname) || option.customerGUID == "all");
    if (this.selectedCustList.length > 0) {
      this.selectedCustList.forEach(x => {
        let i: number;
        i = this.customerList.findIndex(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
        if (i == -1) {
          let c = new CustomerModel();
          c.customerName = x.customerName;
          c.customerGUID = x.customerGUID;
          c.selected = true;
          this.customerList.push(c);
        }
      });
    }
    this.setClientList();
    if (this.selectedCustList.length > 0 && this.selectedCustList.length != this.customerList.length) {
      let data: CustomerModel[] = [];
      data = this.customerList;
      this.customerList = [];

      data.forEach(x => {
        if (x.customerGUID == "all") {
          this.customerList.push(x);
        }
        else {
          if (x.selected) {
            this.customerList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.customerGUID != "all") {
          this.customerList.push(x);
        }
      });
    }

  }

  clickDoneClient() {
    this.showClientList = false;
    this.f.endDate.setValue(null);
    if (this.selectedCustList.length > 0) {
      this.linkedTripCodedGUID = null;
      this.clickSubmit();
      this.clickTab('tripcode_detail');
    }
  }

  showAllTrips() {
    // this.f.client_search.setValue('');
    this.selectedCustList = [];
    // this.customerList = this.orgCustomerList;
    // this.customerList.forEach(x => {
    //   x.selected = false;
    // });
    this.setClientList();
    // this.showClientList = false;
    this.linkedTripCodedGUID = null;
    this.clickSubmit();
  }
  // client end

  clickSubmit() {
    this.submitted = true;

    if (this.message_link_form.invalid || this.errMsg != "") {
      return;
    }
    this.openChildPage();

  }

  openChildPage() {
    this.errMsg = "";
    this.msg = "";

    this._authService.updateAccessTime();
    this.request = new TripModel();
    this.reloadChildPage = true;
    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      this.request.tripCloseOutID = 0;
    }
    else {
      this.request.tripCloseOutID = 1;
    }

    if (this.selectedCustList.length == this.orgCustomerList.length) {
      this.request.selectedCustomerGUIDList = "";
    }
    else {
      this.selectedCustList = this.selectedCustList.filter(x => x.customerGUID != 'all');
      this.request.selectedCustomerGUIDList = Array.prototype.map.call(this.selectedCustList, s => s.customerGUID).toString();
    }

    if (this.selectedGSTypeList.length == this.gsTypeList.length) {
      this.request.groundStopTypeGUIDList = "";
    }
    else {
      this.request.groundStopTypeGUIDList = Array.prototype.map.call(this.selectedGSTypeList, s => s.groundStopTypeGUID).toString();
    }
    if (this.selectedCustomerCreditList.length == this.customerCreditList.length) {
      this.request.selectedCustomerCreditStatusIDList = "";
    }
    else {
      this.request.selectedCustomerCreditStatusIDList = Array.prototype.map.call(this.selectedCustomerCreditList, s => s.customerCreditStatusID).toString();
    }
    this.request.sortBy = this.f.sort_by.value;

  }

  onSendDataToParent(e: any) {
    this.isModified = e.modified;
    if (e.showSuccessMsg) {
      this.showSuccessMessage = true;
      setTimeout(() => {
        this._dialogRef.close(e.modified);
      }, 1500);
    }
  }


  clickTab(s: string) {
    if (this.selectedTabName != s) {
      this.selectedTabName = s;
      if (this.selectedTabName == "aircraft") {
        this.f.sort_by.setValue("registration asc");
      }
      else {
        this.f.sort_by.setValue("departureDate asc");
      }
      this.v = this._authService.getCurrentTimeNumber();
      this.openChildPage();
    }
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }



  linkMessageToClient() {
    var messages: MessageModel[] = [];

    let msgGuids;
    if (this.messageGUIDList) {
      msgGuids = this.messageGUIDList.split(',');
      msgGuids.forEach(msgGuid => {
        let message = new MessageModel();
        message.messageGUID = msgGuid;
        message.customerGUID = this.selectedCustList[0].customerGUID;
        messages.push(message);
      });
    }
    else {
      let message = new MessageModel();
      message.messageGUID = this.messageGUID;
      message.customerGUID = this.selectedCustList[0].customerGUID;
      messages.push(message);

      this.messageLinkList?.forEach(ml=>{
        
          let message = new MessageModel();
          message.messageGUID = this.messageGUID;
          message.customerGUID = this.selectedCustList[0].customerGUID;
          message.tripCodeGUID = this.tripCodeGUID;
          message.groundStopGUID = ml.groundStopGUID;
          message.groundStopTaskGUID = ml.groundStopTaskGUID;
          message.messageLinkGUID = ml.messageLinkGUID
          messages.push(message);
        
      });
    }

    this._messageService.insertMessageLink<ResponseModel<MessageModel>>(messages).subscribe(response => {
      if (response.code == "200") {

        if (this.messageGUIDList)
        msgGuids.forEach(msgGuid => {
          this._messageService.assignAndMovetoInbox(msgGuid);
        });
      else
        this._messageService.assignAndMovetoInbox(this.messageGUID);
        this.showSuccessMessage = true;
        setTimeout(() => {
          this._dialogRef.close(true);
        }, 1500);
      }
    });
  }

  
  removelinkMessage() {
    this._messageService.removeMessageLink<ResponseModel<boolean>>(this.messageGUID).subscribe(response => {
      if (response != null && response.message == "" && response.code == "200") {

       // this.messageLinkList = [];
        this.isModified = true;
        this.successMsg = 'Link Removed Succesfully';
        this.assignMe(true);
        this.showSuccessMessage = true;
        setTimeout(() => {
          this._dialogRef.close(true);
        }, 1500);

      }
      else if (response.code == "401")
        this._authService.signOut();

    });
  }

  assignMe(isAssign: boolean) {
    let request = new MessageModel();
    request.messageGUIDList = this.messageGUID;
    if (isAssign)
      request.assignedTo = this.currentUser;
    else
      request.assignedTo = "";
    this._messageService.updateMessageAssignedTo<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.assignedTo = request.assignedTo;
          this.isModified = true;
        }

      }
    });
  }

}
