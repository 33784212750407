import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { PersonModel } from '../models/person.model';
import { CustomerPersonEditDialogComponent } from './customer-person-edit-dialog.component';
import { PersonService } from '../services/person.service';
import { PersonClassModel } from '../models/person-class.model';
import { DialogService } from '../services/dialog.service';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CustomerGroupService } from '../services/customer-group-service';

@Component({
  selector: 'app-customer-personlist',
  templateUrl: './customer-personlist.component.html',
  styleUrls: ['./customer-personlist.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})

export class CustomerPersonlistComponent implements OnInit {
  personlist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  personClassList: PersonClassModel[];
  selectedPersonClass: PersonClassModel;
  customerList: CustomerModel[];
  userType: string;
  showSpin: boolean = false;
  //selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  customerGUID: string="";
  text_search: string;
  filter_by: string;
  includeDisabledRecords: boolean;
  recordList: PersonModel[];
  inputPersonClassGUID: string;
  //limitTo: number = 10;

  //displayedColumn: string[] = ['name', 'title', 'role', 'login', 'disabled', 'action'];
  displayedColumn: string[] = ['name', 'title', 'role', 'customerName', 'data_entry_status', 'disabled', 'action'];
  scrHeight: number;
  upPII: boolean = false;
  grantAccessToManageManifestProfiles: boolean;
  hideColumn: boolean = false;
  grantAccessToAccountGrouping: boolean = false;
  hideColumnForGroup: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _personService: PersonService, private readonly _dialogService: DialogService,
    private readonly _customerGroupService: CustomerGroupService ) {

  }

  getAllData(): Observable<any[]> {
    let getPersonClassResponse = this._personService.getPersonClassList<ResponseModel<PersonClassModel[]>>();
    let getClientListResponse;
    if (this.userType == "internal") {
      getClientListResponse = this._commonService.getCustomers();
    }
    else {
      if (this.grantAccessToAccountGrouping) {
        let req = new CustomerGroupModel();
        getClientListResponse = this._customerGroupService.getSelectedCustomerListBycgrpId(req);
      }
      else {
        getClientListResponse = of(null);
      }
    }
    //if (this.userType == "internal") {
    //  let getClientListResponse = this._commonService.getCustomers();
    //  return forkJoin([getPersonClassResponse, getClientListResponse]);
    //}
    //else
    //  return forkJoin([getPersonClassResponse]);
    return forkJoin([getPersonClassResponse, getClientListResponse]);
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;  
    }

    if (!this.upPII || !this.grantAccessToManageManifestProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }

    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }

    this.showSpin = true;
   
    this.errMsg = "";
    this.msg = "";
    this.personClassList = [];
    this.customerList = [];
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }
    this._route.queryParams.subscribe(params => {
      if (params["customerGUID"] == null || params["customerGUID"] == "undefined") {
        this.inputPersonClassGUID = null;
        this.customerGUID = "";
        this.text_search = "";
        this.filter_by = "Name";
      }
      else {
        this.customerGUID = params["customerGUID"];
        this.customerGUID = this.customerGUID.toLowerCase();     
        if (params["personClassGUID"] == null || params["personClassGUID"] == undefined || params["personClassGUID"] == "") {
          this.inputPersonClassGUID = 'F82AA48E-31B7-4667-9FAA-24204DC4A65B';
          this.inputPersonClassGUID = this.inputPersonClassGUID.toLowerCase();          
        }
        else {
          this.inputPersonClassGUID = params["personClassGUID"];
        }
        if (params["text_search"] == null || params["text_search"] == undefined) {
          this.text_search = "";
        }
        else
          this.text_search = params["text_search"];
        if (params["filter_by"] == null || params["filter_by"] == undefined) {
          this.filter_by = "";
        }
        else
          this.filter_by = params["filter_by"];
        //this.includeDisabledRecords = params["includeDisabledRecords"];
        if (params["includeDisabledRecords"] == null || params["includeDisabledRecords"] == undefined) {
          this.includeDisabledRecords = false;
        }
        else {
          if (params["includeDisabledRecords"] == "false") {
            this.includeDisabledRecords = false;
          }
          else {
            this.includeDisabledRecords = true;
          }
        }
      }

    });

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType == 'customer') {
      this.hideColumn = true;
      if (!this.grantAccessToAccountGrouping) {
        this.hideColumnForGroup = true;
      }
    }
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.personClassList = responses[0].model;
          if (this.inputPersonClassGUID == null) {
            this.selectedPersonClass = this.personClassList[0];
          }
          else {
            this.selectedPersonClass = this.personClassList.find(x => x.personClassGUID.toLowerCase() == this.inputPersonClassGUID.toLowerCase());
          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      //if (this.userType == "internal") {
        if (responses[1] != null) {
          if (responses[1].code == "200" && responses[1].message == "") {
            this.customerList = responses[1].model;
            //if (this.userType == "customer" && this.grantAccessToAccountGrouping) {
            //  if (this.customerGUID == "")
            //    this.customerGUID = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();
            //}

          }
          else {
            if (responses[1].code == "401") {
              this._authService.signOut();
            }
          }
        }
      //}

      this.initControls();

      this.showSpin = false;

        //this.initControls();

        if (this.userType == "internal") {
          if (this.customerGUID != "") {
            this.getSearchResults();
          }
        }
        else {
          this.getSearchResults();
        }
    });
   
  }

  initControls() {
    this.personlist_form = this._formBuilder.group({
      customer_select: this.customerGUID,
      text_search: [this.text_search],
      filter_by: [this.filter_by],
      includeDisabledRecords: this.includeDisabledRecords
    });
  }

  get f() { return this.personlist_form.controls; }

  customerChange(e: any) {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.customerGUID = this.f.customer_select.value;
    if (e.target.value != "") {
      this.getSearchResults();
    }
    //else {
    //  if (this.userType == "internal") {
    //    if (this.f.customer_select.value == "") {
    //      this.errMsg = "Please select a client";
    //      return;
    //    }
    //  }
    //}

  }

  includeDisabledRecordsOnChange(event: any) {
    //let x = event.target.checked;
    //if (this.userType == "internal") {
    //  if (this.f.customer_select.value == "") {
    //    this.errMsg = "Please select a client";
    //    return;
    //  }
    //}
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults();
  }

  clickShowAllRecords() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.text_search = "";
    this.f.text_search.setValue(this.text_search);
    //if (this.userType == "internal") {
    //  if (this.f.customer_select.value == "") {
    //    this.errMsg = "Please select a client";
    //    return;
    //  }
    //}
    this.getSearchResults();
  }

  onSubmit() {

  }

  getSearchResults() {
    this.errMsg = "";
    this.msg = "";
    if (this.userType == 'internal') {
      if (this.f.customer_select.value == "" && this.f.text_search.value == "") {
        this.errMsg = "Please either select a client or enter search keyword.";
        return;
      }
    }
    this.showSpin = true;
    this._authService.updateAccessTime();

    let request = new PersonModel();
    request.customerGUID = this.f.customer_select.value;
    request.textSearch = this.f.text_search.value;
    request.filterBy = this.f.filter_by.value;
    //request.pageIndex = this.currentPageIndex;
    //request.pageSize = this.pageSize;
    //request.totalRecords = this.totalRecords;
    request.selectedPersonClassGUID = this.selectedPersonClass.personClassGUID;
    request.isActive = true;
    if (this.userType=='internal' && this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      request.isActive = !this.f.includeDisabledRecords.value;
    }

    /*this._personService.getCustomerPerson<ResponseModel<PersonModel[]>>(request).subscribe(response => {*/
    this._personService.getCustomerPersonByConditions<ResponseModel<PersonModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          this.totalRecordsText = this.recordList.length.toString() + ' record count';
          //if (this.recordList[0].totalRecords > 0) {
          //  this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
          //  this.totalRecords = this.recordList[0].totalRecords;
          //  //this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

          //}

        }
        else {
          this.recordList = [];
          //this.msg = "No data return.";
          if (this.f.text_search.value != "") {
            switch (this.f.filter_by.value) {
              case "Name":
                this.msg = "Name not found.";
                break;
              case "Title":
                this.msg = "Title not found.";
                break;
              case "Comms":
                this.msg = "Comms not found.";
                break;
            }
          }
          else {
            this.msg = "No records found.";
          }
        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    })


  }

  //pageIndexChange(e: any) {
  //  //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
  //  this.currentPageIndex = e.pageIndex;
  //  if (this.pageSize != e.pageSize) {
  //    this.totalRecords = 0;
  //    this.totalRecordsText = "";
  //    this.totalPageNumber = 0;
  //    this.pageSize = e.pageSize;
  //  }

  //  this.getSearchResults();

  //}

  clickReset() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    //this.currentPageIndex = 0;
    //this.totalPageNumber = 0;
    this.selectedPersonClass = this.personClassList[0];
    this.customerGUID = "";
    //if (this.userType == "customer" && this.grantAccessToAccountGrouping && this.customerList.length>0) {   
    //    this.customerGUID = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();
    //}
    this.text_search = "";
    this.filter_by = "Name";
    this.initControls();

    if (this.userType != "internal") {
      this.getSearchResults();
    }
  }

  //clickTab(s: string) {
  //  if (this.selectedTabName != s) {
  //    this.selectedTabName = s;
  //  }
  //}

  clickTab(obj: PersonClassModel) {
    if (this.selectedPersonClass != obj) {
      this.selectedPersonClass = obj;
      //if (this.userType == "internal") {
      //  if (this.f.customer_select.value != "") {
      //    this.recordList = [];
      //    this.totalRecordsText = '';
      //    this.totalRecords = 0;
      //    this.currentPageIndex = 0;
      //    this.totalPageNumber = 0;
      //    this.getSearchResults();
      //  }
      //}
      //else {
        this.recordList = [];
        this.totalRecordsText = '';
        this.totalRecords = 0;
        this.currentPageIndex = 0;
        this.totalPageNumber = 0;
        this.getSearchResults();
      //}
      
    }

  }

  clickAdd(event: any, p: PersonModel) {
    if (this.userType == "internal") {
      if (this.f.customer_select.value == "") {
        this.errMsg = "Please select a client";
        return;
      }
    }
    let s = "";
    let personGUID = "";
    //if (p == null) {
    s = "Create New Contact";
    //}
    //else {
    //  s = "EDIT Contact";
    //  personGUID = p.personGUID;
    //}
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
  //  dialogConfig.width = "65em";//"1000px";
  //  dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    if (this.userType == "internal" || (this.userType=='customer' && this.grantAccessToAccountGrouping && this.customerList.length>0)) {
      dialogConfig.data = { dialogTitle: s, personGUID: "", customerGUID: this.f.customer_select.value, selectedPersonClass: this.selectedPersonClass };
    }
    else {
      dialogConfig.data = { dialogTitle: s, personGUID: "", customerGUID:"", selectedPersonClass: this.selectedPersonClass };
    }
    const dialogRef = this._dialog.open(CustomerPersonEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.getSearchResults();
      }
      else {
        if (result.newId != "") {
          // goto person details pages
          let navigationExtras: NavigationExtras = {
            queryParams: {
              "pId": result.newId,
              //"selectedPersonClassDescription": this.selectedPersonClass.personClassDescription,
              "selectedPersonClassGUID": this.selectedPersonClass.personClassGUID

            }
          };
          //this._router.navigate(['/persons/customer-person-details', { pId: result.newId, selectedPersonClass: this.selectedPersonClass }]);
          this._router.navigate(['/persons/customer-person-details'], navigationExtras);
        }
      }

    });
  }

  clickEdit(event: any, p: PersonModel) {
    //this._router.navigate(['/persons/customer-person-details', { pId: p.personGUID }]);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "pId": p.personGUID,
        "selectedPersonClassDescription": this.selectedPersonClass.personClassDescription,
        "selectedPersonClassGUID": this.selectedPersonClass.personClassGUID
        
      }
    };
    this._router.navigate(['/persons/customer-person-details'], navigationExtras);


  }

  


}
