import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { TripModel } from '../models/trip.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopManifestDocumentModel } from '../models/ground-stop-manifest-document.model';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { PersonTravelDocSelectionDialogComponent } from '../ground-stop-advanced/person-travel-doc-selection-dialog.component';
import { ReturnObjModel } from '../models/return-obj.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';

@Component({
  selector: 'app-trip-service-editor-dialog',
  templateUrl: './trip-service-editor-dialog.component.html',
  styleUrls: ['./trip-service-editor-dialog.component.css'],

})

export class TripServiceEditorDialogComponent implements OnInit, AfterViewInit {

  dialogTitle: string;
  customerGUID: string;
  tripCodeGUID: string;
  serviceTypeID: number;
  groundStopGUID: string;
  nextArrivalGroundStopGUID: string;
  //serviceClassID: number;
  //departureCountryGUID: string;
  actionType: string;
  trip_service_editor_form: UntypedFormGroup;
  loading = false;

  submitted = false;
  errMsg: string;
  errMsg2: string;
  msg: string;

  showSpin: boolean = false;

  isModified: boolean = false;

  showSuccessMsg: boolean = false;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  dataChanged: boolean;

  leg: string = "";

  returnObj = new ReturnObjModel();
  lockdownTrip: boolean;
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";
  tripLeg: TripLegModel;
  serviceType: ServiceTypeModel;
  //capacityID: number = 0;
  legOrder: number;
  arrivalDateTimePassed: boolean = false;
  serviceTypeDescription: string = "";
  tripLegOrder: number;
  depQty: number = 0;
  arrQty: number = 0;
  addToLegDep: boolean = false;
  addToLegArr: boolean = false;
  serviceLocation: string = "";
  activeLegList: TripLegModel[] = [];
  upPII: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<TripServiceEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService,
    private readonly _tripLegService: TripLegService, private readonly _dialog: MatDialog
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }
    this.tripCodeGUID = _data.tripCodeGUID;

    this.serviceTypeID = _data.serviceTypeID;
    this.groundStopGUID = _data.groundStopGUID;
    this.nextArrivalGroundStopGUID = _data.nextArrivalGroundStopGUID;
    this.actionType = _data.actionType;

  }

  getAllData(): Observable<any[]> {
    let reqTrip = new TripModel();
    reqTrip.tripCodeGUID = this.tripCodeGUID;
    reqTrip.customerGUID = this.customerGUID;
    reqTrip.personGUID = "";
    reqTrip.serviceClassID = 0
    reqTrip.serviceTypeID = this.serviceTypeID
    let getActiveLegResponse = this._tripLegService.getActiveTripLegListForPersonEditBytId(reqTrip);
    let getServiceLegResponse = this._tripLegService.getTripLegServiceTypeListBytIdsClass(reqTrip);

    return forkJoin([getActiveLegResponse, getServiceLegResponse]);
  }

  ngOnInit() {
    if (this.actionType != "Add" && this.actionType != "Edit") {
      return;
    }
    this.showSpin = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.errMsg2 = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.msg = "";

    this.showSuccessMsg = false;

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].model != null) {
          this.activeLegList = responses[0].model;
          this.tripLeg = this.activeLegList.find(x => x.groundStopGUID.toLowerCase() == this.groundStopGUID.toLowerCase() && x.nextArrivalGroundStopGUID.toLowerCase() == this.nextArrivalGroundStopGUID.toLowerCase());
          this.lockdownTrip = this.tripLeg.lockdownTrip;
          this.arrivalDateTimePassed = this.tripLeg.nextArrivalDateTimePassed;
          this.tripLegOrder = this.tripLeg.tripLegOrder;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].model != null) {
          this.serviceType = responses[1].model[0];
          this.serviceTypeDescription = this.serviceType.serviceTypeDescription;
          this.serviceLocation = this.serviceType.serviceLocation;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.actionType == "Add") {
        this.dataChanged = true;
      }

      this.leg = "LEG " + this.tripLeg.tripLegOrder.toString() + " " + this.tripLeg.leg_ICAOs;
      this.legOrder = this.tripLeg.tripLegOrder;

      if (this.serviceLocation == "D" || this.tripLegOrder == 1) {
        this.depQty = 1;
        if (this.serviceLocation == "D") {
          this.addToLegDep = true;
        }
      }

      if (this.serviceLocation == "A") {
        this.arrQty = 1;
        this.addToLegArr = true;
      }

      this.initControls();

    });



  }

  initControls() {

    this.trip_service_editor_form = this._formBuilder.group({
      addToLegDep: [this.addToLegDep],
      depQty: [this.depQty],
      addToLegArr: [this.addToLegArr],
      arrQty: [this.arrQty],
      addToAllLegs: [false],

    });
  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //  this.routeRef.nativeElement.focus();
    //}, 500);
  }

  get f() { return this.trip_service_editor_form.controls; }

  arrQtyChange(e: any) {
    if (e.target.value != "") {
      let n = Number(e.target.value);
      if (n > 10) {
        e.target.value = 10;
      }
      else {
        if (n <= 0) {
          e.target.value = 1;
        }
      }
    }
  }

  depQtyChange(e: any) {
    if (e.target.value != "") {
      let n = Number(e.target.value);
      if (n > 10) {
        e.target.value = 10;
      }
      else {
        if (n <= 0) {
          e.target.value = 1;
        }
      }
    }
  }

  clickSave(actionName: string) {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.f.addToLegDep.value == false && this.f.addToLegArr.value == false && this.f.addToAllLegs.value == false) {
      this.errMsg = "Please select at least one adding type.";
      return;
    }

    let gstList: GroundStopTaskModel[] = [];
    let gst: GroundStopTaskModel;
    gst = new GroundStopTaskModel();
    let depQty: number = this.f.depQty.value;
    let arrQty: number = this.f.arrQty.value;
    if (this.f.addToLegDep.value == true) {
      for (var i = 0; i < depQty; i++) {
        gst = new GroundStopTaskModel();
        gst.groundStopGUID = this.groundStopGUID;
        gst.serviceLocation = "D";
        gst.serviceTypeID = this.serviceTypeID;
        gstList.push(gst);
      }
    }

    if (this.f.addToLegArr.value == true) {
      for (var i = 0; i < arrQty; i++) {
        gst = new GroundStopTaskModel();
        gst.groundStopGUID = this.nextArrivalGroundStopGUID;
        gst.serviceLocation = "A";
        gst.serviceTypeID = this.serviceTypeID;
        gstList.push(gst);
      }
    }

    let legList: TripLegModel[] = [];
    let fIndex: number;
    fIndex = this.activeLegList.findIndex(x => x.groundStopGUID.toLowerCase() == this.groundStopGUID.toLowerCase() && x.nextArrivalGroundStopGUID.toLowerCase() == this.nextArrivalGroundStopGUID.toLowerCase());
    if (this.f.addToAllLegs.value == true) {
      let addedServiceToCurrentLegDep: boolean = false;
      if (this.serviceLocation == 'D') {
        if (gstList.length > 0 && gstList.findIndex(x => x.serviceLocation == this.serviceLocation) >= 0) {
          addedServiceToCurrentLegDep = true;
        }
        if (addedServiceToCurrentLegDep) {
          fIndex = this.activeLegList.findIndex(x => x.groundStopGUID.toLowerCase() == this.groundStopGUID.toLowerCase() && x.nextArrivalGroundStopGUID.toLowerCase() == this.nextArrivalGroundStopGUID.toLowerCase());
          legList = this.activeLegList.filter((x, index) => index > fIndex);
        }
        else {
          legList = this.activeLegList.filter((x, index) => index >= fIndex);
        }
        legList.forEach(x => {
          for (var i = 0; i < depQty; i++) {
            gst = new GroundStopTaskModel();
            gst.groundStopGUID = x.groundStopGUID;
            gst.serviceLocation = "D";
            gst.serviceTypeID = this.serviceTypeID;
            gstList.push(gst);
          }
        })
      }

      let addedServiceToCurrentLegArr: boolean = false;
      if (this.serviceLocation == 'A') {
        if (gstList.length > 0 && gstList.findIndex(x => x.serviceLocation == this.serviceLocation) >= 0) {
          addedServiceToCurrentLegArr = true;
        }
        if (addedServiceToCurrentLegArr) {
          fIndex = this.activeLegList.findIndex(x => x.groundStopGUID.toLowerCase() == this.groundStopGUID.toLowerCase() && x.nextArrivalGroundStopGUID.toLowerCase() == this.nextArrivalGroundStopGUID.toLowerCase());
          legList = this.activeLegList.filter((x, index) => index > fIndex);
        }
        else {
          legList = this.activeLegList.filter((x, index) => index >= fIndex);
        }
        legList.forEach(x => {
          for (var i = 0; i < arrQty; i++) {
            gst = new GroundStopTaskModel();
            gst.groundStopGUID = x.nextArrivalGroundStopGUID;
            gst.serviceLocation = "A";
            gst.serviceTypeID = this.serviceTypeID;
            gstList.push(gst);
          }
        })
      }

    }

    this.loading = true;
    this._tripLegService.createGroundStopTaskFromLeg<ResponseModel<boolean>>(gstList).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          this.returnObj.refresh = this.isModified;
          this.returnObj.actionName = "";
          this._dialogRef.close(this.returnObj);
        }
        else {
          //
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    });

  }

  clickClose() {
    if (this.dataChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes you just made before close the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave('');
        }
        else {
          this.returnObj.refresh = false;
          this._dialogRef.close(this.returnObj);
        }
      });
    }
    else {
      this.returnObj.refresh = false;
      this._dialogRef.close(this.returnObj);
    }
  }


}
