import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ResponseModel } from '../models/response.model';
import { MessageEmbeddedImageModel } from '../models/message-embedded-image';
import { MessageAttachmentModel } from '../models/message-attachment';
import { MessageService } from '../services/message.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-download-documents',
  templateUrl: './download-documents.component.html',
  styleUrls: ['./download-documents.component.css']
})

export class DownloadDocumentsComponent implements OnInit {

  download_documents_form: UntypedFormGroup;
  isDialog: boolean = false;

  isCrew: boolean;
  isPax: boolean;
  isSubDocument: boolean;
  isVendor: boolean;
  isCustomer: boolean;
  isAircraft: boolean;
  isCountry: boolean;
  isTask: boolean;
  hasSubdocument: boolean;
  isAirport: boolean;
  isPermitRequiredDoc: boolean;
  isMiscellaneous: boolean;
  downloadCount: number = 0;
  messageAttachmentList: MessageAttachmentModel[] = [];
  embeddedImageListAtt: MessageEmbeddedImageModel[] = [];
  totalDocuments: number = 0;
  showSpin: boolean = false;
  downloadFinished: boolean = false;


  constructor(@Optional() private readonly _dialogRef: MatDialogRef<DownloadDocumentsComponent>, private readonly _commonService: CommonService,
  @Optional() @Inject(MAT_DIALOG_DATA) private data: any,  private readonly _authService: AuthenticateService, 
    private readonly _formBuilder: UntypedFormBuilder, private readonly _messageService: MessageService,
    ) {
   
      if (this.data) {
        this.isDialog = true;
        // this.username = this.data.username;
      }  
      
      this.embeddedImageListAtt = this.data.embeddedImageListAtt;
    this.messageAttachmentList = this.data.messageAttachmentList;
    this.totalDocuments = this.embeddedImageListAtt?.length + this.messageAttachmentList?.length;
  }

  ngOnInit() {

    // this._authService.updateAccessTime();

    this.initControls();

    this.downloadAllAttachments();
   }

  initControls() {
    this.download_documents_form = this._formBuilder.group({
      // userComments: [this.userComments]
    });


  }

  get f() { return this.download_documents_form.controls; }


 

  clickClose() {
    this._dialogRef.close();
  }


  downloadAttachment(item: any, isEmbedded: boolean) {
    if (isEmbedded) {
      this.getEmbeddedImage(item.messageEmbeddedImageGUID, true);
    }
    else {
      this.getMessageAttachment(item.messageAttachmentGUID, true);
    }
  }

  async downloadAllAttachments() {

    this.downloadCount = 0;
    this.showSpin = true;
 

    for (const att of this.messageAttachmentList) {
      await this.downloadAttachment(att, false);  
      // await new Promise(resolve => resolve(this.downloadAttachment(att, false))); 
      await new Promise(resolve => setTimeout(resolve, 1000)); 

    }

    // Download embedded image attachments
    for (const att of this.embeddedImageListAtt) {
      await this.downloadAttachment(att, true);  
      // await new Promise(resolve => resolve(this.downloadAttachment(att, true))); 
      await new Promise(resolve => setTimeout(resolve, 1000)); 

    }

    this.showSpin = false;
    this.downloadFinished = true;

    setTimeout(() => {
      this.clickClose();
    }, 1000);

  }


  getMessageAttachment(messageAttachmentGUID: string, isDownload: boolean) {
    this._messageService.getMessageAttachmentsByMessageAttachmentGUID<ResponseModel<MessageAttachmentModel>>(messageAttachmentGUID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let att = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(att.bFile);
          let file = new Blob([byteArray], { type: att.mimeType });
          if (att.mimeType == "application/octet-stream" || isDownload) {

            // await new Promise(resolve => resolve(saveAs(file, att.documentName))); 

            saveAs(file, att.documentName);
            this.downloadCount++;
          }
          else {
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }

  getEmbeddedImage(messageEmbeddedImageGUID: string, isDownload: boolean) {
    this._messageService.getMessageEmbeddedImageByMessageEmbeddedImageGUID<ResponseModel<MessageEmbeddedImageModel>>(messageEmbeddedImageGUID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let att = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(att.imageBase64String);
          let file = new Blob([byteArray], { type: att.mimeType });
          if (att.mimeType == "application/octet-stream" || isDownload) {
            saveAs(file, att.documentName);
            this.downloadCount++;
          }
          else {
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }





}











 