<div style="margin-top:0em; padding-top:0em; display:flex">
  <div class="divLeft">
    <div>

      <div>
        <form *ngIf="reviseFuelPricing_form" [formGroup]="reviseFuelPricing_form"   [ngClass]="{ 'normalCss':!isDialog ,'dialogCss':isDialog  }" >
          <div>
            <div style="background-color: #f8f8f8; border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; ">
              <div   *ngIf="!isDialog"  style=" width: 60%; float: left;    display: flex;">
                <span style="margin-right: 0.2em;   " class="material-symbols-outlined">priority_high</span>
                <h6>Failed Airports(Fuel Import)</h6>
              </div>

              <div   *ngIf="isDialog"  style=" width: 102%; float: left;    display: flex; justify-content: space-between;"  cdkDrag  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle >
                
                <div style="display: flex;"><span style="margin-right: 0.2em;   " class="material-symbols-outlined">priority_high</span>
                <h6>Failed Airports(Fuel Import)</h6></div>
               
                <div style="display: flex;">
                  <button mat-icon-button (click)="clickOpenInNew()"> <i class="material-icons"  style="font-size:x-large;  ">open_in_new</i> </button>
                  <button *ngIf="isDialog" mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button>
                </div>
              </div>

              
              <div>
                <div class="form-group" style=" margin-bottom: 0.2em;">
                  <!--<input type="checkbox" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)" />-->
                  <!--<div style="display: flex;">
                    <mat-slide-toggle style="transform:scale(.8); height:2em;margin-top:-0.5em" color="primary" formControlName="includeDisabledRecords" [checked]="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)"></mat-slide-toggle>
                    <div style="margin-top:-0.25em"><label for="includeDisabledRecords" style="font-size:small;">&nbsp;Include disabled records</label></div>
                  </div>-->
                  <div  *ngIf="!isDialog" style=" font-size: small;text-align: end;">{{totalRecordsText}}</div>
                </div>

              </div>
              <div *ngIf="msg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:forestgreen; font-size: small">&nbsp;{{msg}}</label></div>
              <div *ngIf="errMsg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:red; font-size: small">{{errMsg}}</label></div>
            </div>
          </div>

          <div style=" margin-top: 1em;">
            <div style="display: flex; justify-content: space-between;"> 
              <button type="button" mat-button class="btn-low" (click)="exportToExcel()" style="height: 2em;">  Export to Excel</button>

              <div style=" /* margin-right: 0.5em; */ /* width: 6em; */ text-align: end; margin-bottom: 0.4em;">
                <div style="display: flex;  ">
                  <div  *ngIf="isDialog" style=" font-size: small;text-align: end;margin-right: 1em;">{{totalRecordsText}}</div>

                  <button mat-button class="btn-low" style="height: 2em;margin-top: 0.2em;display: flex;" (click)="clickRoute('fuelmenu/fuel-menu')" ><i class="material-icons" style="font-size: medium;cursor: pointer; ">arrow_back</i>Return to Fuel Dashboard</button>
  
                </div>
              </div>
            </div>

            <div   [ngClass]="{  'dialogCss2':isDialog  }"  >
              <table mat-table *ngIf="revisefuelpricelist" [dataSource]="revisefuelpricelist" class="example-container mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="IATA">
                  <th mat-header-cell *matHeaderCellDef class="thStyle">
                    <div style="margin-top:-0.25em;">IATA</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.iata}}</td>
                </ng-container>
                <ng-container matColumnDef="ICAO">
                  <th mat-header-cell *matHeaderCellDef class="thStyle">
                    <div style="margin-top:-0.25em;">ICAO</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.icao}}</td>
                </ng-container>

                <ng-container matColumnDef="FAAID">
                  <th mat-header-cell *matHeaderCellDef class="thStyle">
                    <div style="margin-top:-0.25em;">FAAID</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.faaid}}</td>
                </ng-container>

                <ng-container matColumnDef="FuelSupplierShortname">
                  <th mat-header-cell *matHeaderCellDef class="thStyle">
                    <div style="margin-top:-0.25em;">Fuel Supplier </div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.fuelSupplierName}}</td>
                </ng-container>
              </tr>

              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <!--<tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>-->
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>
            </div>
            
          </div>

          <!--<mat-paginator style="border: none;min-width:80em;" #paginator *ngIf="revisefuelpricelist && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                         [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
          </mat-paginator>-->

        </form>
      </div>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin" style="display: flex; width: 10em;"></app-spin>
