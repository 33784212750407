import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogService } from '../services/dialog.service';
import { AuthenticateService } from '../services/authenticate.service';
import { FuelSupplierComponent } from '../fuelmenu/fuel-supplier-profile.component';
import { MaintenanceDashboardComponent } from './maintenance-dashboard.component';
import { HotellistComponent } from '../hotels/hotellist.component';

@Component({
  selector: 'app-third-party-vendors',
  templateUrl: './third-party-vendors.component.html',
  styleUrls: ['./third-party-vendors.component.css']
})

export class ThirdPartyVendorsComponent implements OnInit {
  // userType: string; 
  // maintenance_dashboard_form: UntypedFormGroup;
  loading = false;
  // v: number = this._authService.getCurrentTimeNumber();
  // upPII: boolean = false;
  isDialog: boolean = false;



  constructor(
    @Optional() private readonly _dialogRef: MatDialogRef<ThirdPartyVendorsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _dialog: MatDialog, private readonly _dialogService: DialogService
  ) {

    // this.tripRevisionID = _data.tripRevisionID;
    if (this.data) {
      this.isDialog = true;
    }
  }

  ngOnInit() {

    // this._authService.updateAccessTime();



  }

  initControls() {
    // this.maintenance_dashboard_form = this._formBuilder.group({
    //   // userComments: [this.userComments]
    // });


  }

  // get f() { return this.maintenance_dashboard_form.controls; }




  clickClose(val) {
    this._dialogRef.close(val);
  }


  openFuelSupplierProfileComponent() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "39em";//"1000px";
    config.width = "66em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    if (this.isDialog) {
      this.clickClose(true);
    }

    config.data = { v: this._authService.getCurrentTimeNumber(), dialogFrom: 'main' };
    this._dialog.open(FuelSupplierComponent, config);

  }


  clickRouteNew(route: string, display: string) {
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }



  openMaintenanceComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "31em";
    config.width = "43em";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber() };
    config.position = { left: "10px", top: "140px" };

    if (this.isDialog) {
      this.clickClose(true);
    }

    this._dialog.open(MaintenanceDashboardComponent, config);
  }
  
  openHotelListComponent(){
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "45em";//"1000px";
    config.width = "83em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {    v: this._authService.getCurrentTimeNumber()   };

    if(this.isDialog){  
      this.clickClose(true);
    }     

    this._dialog.open(HotellistComponent, config);
  }

}

