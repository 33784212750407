export class OutlookMailFolder {
  mailFolderId: string;
  displayName: string;
  parentFolderId: string;
  childFolderCount: number;
  unreadItemCount: number;
  totalItemCount: number;
  sizeInBytes: number;
  isHidden: boolean;
  customerGUID: string;
  selected: boolean;
  tripCodeGUID: string;
  isImported: boolean;
  lastImportDate: string;
  importedBy: string;
  importItemCount: number;
  customerName: string;
  childFoldersImported: boolean;
}
