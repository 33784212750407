<div style="margin-top: 0em; padding-top: 0em; width: 70%; margin-bottom: 2em; padding: 0.5em 1em 1em 1em ; ">
  <form *ngIf="location_metrics_dialog_form" [formGroup]="location_metrics_dialog_form">
    <div  >
      <div  *ngIf="isDialog"  style="display: flex; justify-content: space-between; width: 49.5em;" cdkDrag  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle >
        <div style="margin-right:1em;display: flex; padding: 0.5em;"><span class="material-symbols-outlined"> description </span><h5>Location Metrics - Last 3 Months</h5></div>
       
        
        <div>
          <div style="width:6em">
            <!-- <app-spin *ngIf="showSpin"></app-spin> -->
            <app-spin *ngIf="showSpin2" ></app-spin>
          </div>
        </div>



        <div *ngIf="isDialog" style="display: flex;"> 
          <button mat-icon-button  (click)="clickOpenInNew()">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>    

          <button  mat-icon-button mat-dialog-close> <i class="material-icons"
              style="font-size: x-large;">close </i> </button>
        </div>
      </div>

      <div  *ngIf="!isDialog"  style="display: flex; justify-content: space-between; width: 49.5em;">
        <div style="margin-right:1em;display: flex; padding: 0.5em;"><span class="material-symbols-outlined"> description </span><h5>Location Metrics - Last 3 Months</h5></div>
        <div>
          <div style="width:6em">
            <!-- <app-spin *ngIf="showSpin"></app-spin> -->
            <app-spin *ngIf="showSpin2" ></app-spin>
          </div>
        </div> 
      </div>
      <div style="    margin-left: 2em;" > {{icao}} - {{countryName}} </div>
    
    <!-- <div style="background-color: #f8f8f8; padding-left: 0.25em; padding-top: 0.25em; padding-right: 0.5em; margin-top: -0.5em; border-top: 0.0625em lightgrey solid; 
                    border-bottom: 0.0625em lightgrey solid; width: 46em; height: 2.5em">
        <div style="margin-left:0em;padding:0em; display: flex; "> 
          

          <div style="display:flex;justify-content: end; width: 100%;">
            <div style="margin-top:0.125em; margin-right:0.5em;display: flex; ">
              <div style="display: flex;">
                 <button mat-button class="btn-low"  (click)="getData()" style="height: 2em;display: flex; color: #337dff;margin-left:1em; "><i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>REFRESH</button>
              </div> 
            </div>
            <div style="display: flex;"> 
          </div>

          </div>   

        </div> 
      </div> -->

      <div  ><!--class="mt-2">-->
        
        <div>
          <div class="d-flex " style="width: 55em;justify-content: end; font-size: small;margin: 0.5em;" align="end"  ><!--</div> style="margin-top: -1.05em;">-->
            <!-- <mat-paginator *ngIf="sortedData.length>0" style="background-color:transparent;" [pageSize]="25"
              [showFirstLastButtons]="true" [length]="totalRecords" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page"
              (page)="pageEvent = pageIndexChange($event)">
            </mat-paginator> -->
            Total Records: {{totalRecords}}
          </div>
        </div>
        <div style="height: 32em; overflow-y: auto; width: 47em;">
          <table mat-table [dataSource]="sortedData" matSort  matSortDirection="asc"  style="width: 45em; margin-left: 0.5em;">

            <tr>
      
              <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef   style="padding-left: 0.5em !important">
                  Client Acct
                </th>
                <td mat-cell *matCellDef="let element"  style="text-align: left !important; padding-left: 0.5em !important;">{{ element.customerName}}</td>
              </ng-container>
              <ng-container matColumnDef="tripCode">
                <th mat-header-cell *matHeaderCellDef   >
                  Trip Code
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;">{{element.tripCode  }}</td>
              </ng-container>
              <ng-container matColumnDef="arrivalDateUTC">
                <th mat-header-cell *matHeaderCellDef   >
                  Arrival Date 
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;">{{element.arrivalDateUTC }}</td>
              </ng-container>
              <!-- <ng-container matColumnDef="TripCode">
                <th mat-header-cell *matHeaderCellDef   >
                  Trip Code
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: right;"><a href="javascript:void(0)"  (click)="clickOpenTrip(element.tripCodeGUID,element.customerGUID,element.aircraftGUID);">{{ element.tripCode}}</a></td>
              </ng-container> -->
              <ng-container matColumnDef="fuelSupplierName">
                <th mat-header-cell *matHeaderCellDef   >
                  Fuel Supplier Name
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;   padding-left: 0.7em !important;">{{ element.fuelSupplierName}}</td>
              </ng-container>

              <ng-container matColumnDef="intoPlaneAgent">
                <th mat-header-cell *matHeaderCellDef   >
                  Into Plane Agent
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;   padding-left: 0.7em !important;">{{ element.intoPlaneAgent}}</td>
              </ng-container>
 
                <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="thStyle">
                  <div style="margin-top:-0.25em;">Action</div>
                </th>
                <td mat-cell class="mat-cell2" *matCellDef="let element; let i=index ">
                  <a mat-button class="btn-low grid"  >
                    <span>Open</span>
                  </a>
                </td>
              </ng-container>
            </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row  *matRowDef="let row; columns: displayedColumn" (click)="getFuelQuoteDocument(row)" ></tr>
            <!-- (click)="clickOpenTrip(row.tripCodeGUID,row.customerGUID,row.aircraftGUID);" -->
          </table>
        </div>
      </div>


    </div>
  </form>
</div>
