import { Component, Inject, OnInit, ViewChild, Optional } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogService } from '../services/dialog.service';
import { PersonClassModel } from '../models/person-class.model';
import { PersonService } from '../services/person.service';
import { PersonModel } from '../models/person.model';
import { CustomerPersonEditDialogComponent } from './customer-person-edit-dialog.component';
import { FileItem, FileUploader, ParsedResponseHeaders, FileUploadModule, FileSelectDirective } from 'ng2-file-upload';
import { GlobalConstant } from '../common-utility/global-constant';
import { FileModel } from '../models/file.model';
import { EmailClientDialogComponent } from '../messages-client/email-client-dialog.component';
import { CustomerGroupService } from '../services/customer-group-service';
import { CustomerModel } from '../models/customer.model';
import { CustomerPersonTravelDocSelectionDialogComponent } from './customer-person-travel-doc-selection-dialog.component';
import { PersonTravelDocumentModel } from '../models/person-travel-document.model';
import { PersonPassportEditDialogComponent } from './person-passport-edit-dialog.component';
import { PersonPassportVisalistDialogComponent } from './person-passport-visalist-dialog.component';
import { CustomerPersonTravelDoclistComponent } from './customer-person-travel-doclist.component';
import { PersonAccountGrouplistComponent } from './person-account-grouplist.component';
import { CustomerPersonCommEditDialogComponent } from './customer-person-comm-edit-dialog.component';
import { CustomerPersonCommlistComponent } from './customer-person-commlist.component';
import { CustomerPersonMailEditDialogComponent } from './customer-person-mail-edit-dialog.component';
import { PersonTravelDocEditDialogComponent } from './person-travel-doc-edit-dialog.component';
import { CustomerPersonMaillistComponent } from './customer-person-maillist.component';
import { CustomerPersonRewardlistComponent } from './customer-person-rewardlist.component';
import { PersonRewardEditDialogComponent } from './person-reward-edit-dialog.component';
import { ManageContactDetailsComponent } from './manage-contact-details.component';

@Component({
  selector: 'app-customer-person-details',
  templateUrl: './customer-person-details.component.html',
  styleUrls: ['./customer-person-details.component.css']
})

export class CustomerPersonDetailsComponent implements OnInit {
  userType: string;
  customerGUID: string;
  personGUID: string;
  selectedPersonClass: PersonClassModel;
  selectedTabName: string;
  person_details_form: UntypedFormGroup;
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  dob: string;
  gender: string;
  countryofbirth: string;
  countryofresidence: string;
  countryofnationality: string;
  state_provinceofbirth: string;
  cityofbirth: string;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  rolelist: string[];
  errMsg: string;
  msg: string;
  uploader: FileUploader;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  photoName: string;
  photoFilePath: string;
  includeDisabledRecords: boolean;
  imageToShow: any;
  isCrewPax: boolean = true;
  person_status: string;
  grantAccessToManageManifestProfiles: boolean = false;
  upPII: boolean = false;
  grantAccessToMessages: boolean = false;
  eesetiasOutOfScope: string = "";
  eesetiasOutOfScopeExpirationDate: string = "";

  tripCodeGUID: string;
  isOpenedNew: boolean; false;
  customer: CustomerModel;
  totalClientCount: number = 1;
  syncCount: number;
  filterOption: number = 1;

  isUpdated: boolean = false;

  @ViewChild('travelDocs') travelDocsRef: CustomerPersonTravelDoclistComponent;
  @ViewChild('comms') commsRef: CustomerPersonCommlistComponent;
  @ViewChild('mail') mailRef: CustomerPersonMaillistComponent;
  @ViewChild('rewards') rewardsRef: CustomerPersonRewardlistComponent;

  docCount: number = 0;
  commCount: number = 0;
  mailCount: number = 0;
  rewardCount: number = 0;
  customerName: string = "";
  fromSearch: boolean = false;

  constructor( private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _personService: PersonService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _dialogService: DialogService, @Optional() @Inject(MAT_DIALOG_DATA) private _data: any, @Optional() private readonly _dialogRef: MatDialogRef<CustomerPersonDetailsComponent>,
    private readonly _customerGroupService: CustomerGroupService) {
    if (_data) {
      if (_data.customerGUID != null && _data.customerGUID != undefined) {
        this.personGUID = _data.personGUID;
        this.customerGUID = _data.customerGUID;
        this.selectedPersonClass = new PersonClassModel();
        this.selectedPersonClass.personClassDescription = _data.selectedPersonClassDescription;
        this.selectedPersonClass.personClassGUID = _data.selectedPersonClassGUID;
        this.tripCodeGUID = _data.tripCodeGUID;
      }
      if (_data.fromSearch != null && _data.fromSearch != undefined)
        this.fromSearch = _data.fromSearch;

    }
  }

  ngOnInit() {
    //this.personGUID = this._route.snapshot.paramMap.get('pId');
    this._route.url.subscribe(o => {
      if (o[0].path == "")
        this.isOpenedNew == false;
      else {
        this._route.queryParams.subscribe(params => {
          if (params["pId"] != null && params["pId"] != "undefined") {
            this.personGUID = params["pId"];
            this.selectedPersonClass = new PersonClassModel();
            this.selectedPersonClass.personClassDescription = params["selectedPersonClassDescription"];
            this.selectedPersonClass.personClassGUID = params["selectedPersonClassGUID"];
            this.tripCodeGUID = params["tripCodeGUID"];
            this.customerGUID = params["customerGUID"];
            this.isOpenedNew = true;
          }
        });
      }
    });
    this._authService.updateAccessTime();

    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }

    if (!this.upPII || !this.grantAccessToManageManifestProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.grantAccessToMessages = false;
    if (localStorage.getItem('up.msg') == 'true') {
      this.grantAccessToMessages = true;
    }
    this.errMsg = "";
    this.msg = "";
    this.firstName = "";
    this.middleName = "";
    this.lastName = "";
    this.title = "";
    this.dob = "";
    this.gender = "";
    this.countryofbirth = "";
    this.countryofresidence = "";
    this.countryofnationality = "";
    this.state_provinceofbirth = "";
    this.cityofbirth = "";
    this.isActive = 1;
    this.photoName = "";
    this.rolelist = [];
    this.selectedTabName = 'mailAddress';
    this.includeDisabledRecords = false;
    this.photoFilePath = "";
    this.isCrewPax = true;
    this.person_status = "";
    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Person/uploadPersonPhoto",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('pId', this.personGUID); //note comma separating key and value
      form.append('cId', this.customerGUID); //note comma separating key and value
      //form.append('Field2', this.filedValue2);
    };

    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getPersonInfo(); 
    
  }

  initControls() {
    this.person_details_form = this._formBuilder.group({
      firstName: [this.firstName],
      middleName: [this.middleName],
      lastName: [this.lastName],
      title: [this.title],
      dob: [this.dob],
      gender: [this.gender],
      countryofbirth: [this.countryofbirth],
      countryofresidence: [this.countryofresidence],
      countryofnationality: [this.countryofnationality],
      state_provinceofbirth: [this.state_provinceofbirth],
      cityofbirth: [this.cityofbirth],
      person_status: [this.person_status],
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      includeDisabledRecords: this.includeDisabledRecords,
      photo: [null],
      eesetiasOutOfScope: this.eesetiasOutOfScope,
      eesetiasOutOfScopeExpirationDate: this.eesetiasOutOfScopeExpirationDate
    });
  }

  get f() { return this.person_details_form.controls; }

  getPersonInfo() {
    this._customerGroupService.getCustomerWithGroupingBycId<ResponseModel<CustomerModel>>(this.customerGUID).subscribe(response => {
    if (response != null) {
      if (response.code == "200" && response.message == "") {
        this.customer = response.model;
        if (this.customer.customerGroup != null) {
          if (this.customer.customerGroup.length == 1)
            this.totalClientCount = this.customer.customerGroup[0].customerList.length;
          else {

            this.customer.customerGroup.forEach(x => {
              this.totalClientCount += x.customerList.length - 1;
            });
          }
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }
      }
    })
    this._personService.getPersonBypId<ResponseModel<PersonModel>>(this.personGUID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let person = response.model;
         // this.customerGUID = person.customerGUID;
          this.firstName = person.firstName;
          this.middleName = person.middleName;
          this.lastName = person.lastName;
          this.title = person.jobTitle;
          this.dob = person.dob;
          this.gender = person.gender;
          this.countryofbirth = person.countryNameOfBirth;
          this.countryofresidence = person.countryNameOfResidence;
          this.countryofnationality = person.nationalityName;
          this.state_provinceofbirth = person.state_Prov_OfBirth;
          this.cityofbirth = person.cityOfBirth;
          if (person.isActive == true)
            this.person_status = "No";
          else
            this.person_status = "Yes";
          this.modifiedBy = person.modifiedBy;
          this.modifiedDate = person.modifiedDate;
          this.photoFilePath = person.personPhotoPath;
          this.photoName = person.personPhotoName;
          if (person.personRoleList != "")
            this.rolelist = person.personRoleList.split(";");
          if (person.personClassList.toLowerCase().indexOf('crew') == -1 && person.personClassList.toLowerCase().indexOf('pax') == -1) {
            this.isCrewPax = false;
          }
          else {
            this.isCrewPax = true;
          }
          if (person.eesetiasOutOfScope == true)
            this.eesetiasOutOfScope = "Yes";
          else
            this.eesetiasOutOfScope = "No";
          this.eesetiasOutOfScopeExpirationDate = person.eesetiasOutOfScopeExpirationDate;
          this.syncCount = person.syncCount;
        }
        else {

        }
      }
      else {
        if (response.code == "401") {
          if (response.message != "") {
            this._dialogService.openAlertDialog("Permission denied.", "OK");
          }
          else
            this._authService.signOut();
        }
      }

      this.initControls();
    })
  }

  clickEditPerson() {
    let s = "";
    let personGUID = "";
    
    s = "EDIT Contact";
    personGUID = this.personGUID;
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
   // dialogConfig.width = "65em";//"1000px";
   // dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    //if (this.userType == "internal") {
    //  dialogConfig.data = { dialogTitle: s, customerGUID: "", selectedPersonClass: this.selectedPersonClass, personGUID: this.personGUID };
    //}
    //else {
      dialogConfig.data = { dialogTitle: s, customerGUID: this.customerGUID, selectedPersonClass: this.selectedPersonClass, personGUID: this.personGUID };
    //}
    const dialogRef = this._dialog.open(CustomerPersonEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.isUpdated=true;
        this.getPersonInfo();
      }
      //else {
      //  if (result.newId != "") {
      //    // goto person details pages
      //    this._router.navigate(['/persons/person-details', { pId: result.newId }]);
      //  }
      //}

    });
  }

  // upload photo
  importPhoto(event: any) {
    this.errMsg = "";
    this.finishAllUploads = false;
    this.fileList = [];
    var files = [];
    var maxFileSize = 5 * 1024 * 1024;
    this.errMsg = "";
    this.photoFilePath = "";
    
    if (event.target.files.length >1) {
      this.errMsg = 'Only upload one file.';
      return;
    }
    else { 
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;
        //var a = this.checkValidFileType(filename);
        var k = filename.lastIndexOf('.');
        var ext = filename.substring(k + 1).toLocaleLowerCase();
        if (ext != "png" && ext != "jpg" && ext != "gif") {
          //this.errMsg += 'The file type of "' + filename + '" is not allowed to upload.';
          this.errMsg = 'The file type is not\nallowed to upload.';
          //this.uploader.removeFromQueue(event.target.files[i]);
          this.uploader.clearQueue();
          return;
        }
        else {
          if (event.target.files[i].size >= maxFileSize) {
            //if (this.errMsg != "") {
            //  this.errMsg += "\n";
            //}
            //this.errMsg += 'The file size "' + filename + '" is too big to upload.';
            this.errMsg = 'The file size is too\nbig to upload.';
            //this.uploader.removeFromQueue(event.target.files[i]);
            this.uploader.clearQueue();
            return;
          }
          else {
            files.push(event.target.files[i]);
            //this.f.document_name.setValue(filename);
          }                  
        }
        break;
      }
      
    }

    if (this.errMsg != "")
      return;

    //if (files.length > 0) {
    //  this.uploader.addToQueue(files);
    //}

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      return;
    }
    
    this.uploadSubmit();

  }


  uploadSubmit() {
    let x=this.uploader.queue.length;
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);

   // fileItem[0].customerGUID = this.customerGUID;
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            this.photoFilePath = item.filePath;
            this.photoName = item.fileName;
            //let obj = new FileModel();
            //obj.fileName = item.fileName;
            //obj.filePath = item.filePath;
            //obj.fileSize = item.fileSize;
            //obj.bFile = item.bFile;
            //obj.mimeType = item.mimeType;
            //obj.fileType = item.fileType;
           // this.f.size.setValue(obj.fileSize.toString() + " KB");
            //this.fileList.push(obj)
            //this._commonService.getImageById<ResponseModel<Blob>>(this.personGUID).subscribe(res => {
            
            //    this.createImageFromBlob(res);
              
            //});

          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  //createImageFromBlob(image: Blob) {
  //  let reader = new FileReader();
  //  reader.addEventListener("load", () => {
  //    this.imageToShow = reader.result;
  //  }, false);

  //  if (image) {
  //    reader.readAsDataURL(image);
  //  }
  //}
  //clickPreviewDocument() {
  //  let item = this.fileList[0];
  //  let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
  //  let file = new Blob([byteArray], { type: item.mimeType });
  //  var fileURL = URL.createObjectURL(file);
  //  window.open(fileURL);
  //}

  clickRemovePhoto() {
    //this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the document?");
    //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
    //  this.eventSubscription.unsubscribe();
    //  if (result) {
    //    this.fileList = [];
    //    this.document_name = '';
    //    this.f.document_name.setValue(this.document_name);
    //  }
    //})
    let request = new PersonModel();
    request.personGUID = this.personGUID;
    request.personPhotoName = this.photoName;
    request.actionName = 'removePhoto';
    request.customerGUID = this.customerGUID;
    this._personService.updatePersonPhoto<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model ==true) {
          this.photoFilePath = "";
          this.photoName = "";
        }
      }
    })

  }
  // upload file end


  clickGoToPersonListPage() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        
        "personClassGUID": this.selectedPersonClass.personClassGUID,
        "customerGUID": this.customerGUID,
        "text_search": "",
        "filter_by": "Name",
        "includeDisabledRecords": null
      }
    };
    this._router.navigate(['/persons/customer-personlist'], navigationExtras);
  }

  clickTab(s: string) {
    if (this.selectedTabName != s) {
      this.selectedTabName = s;
    }
  }

  clickSendEmail(e: any) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: "", groundStopTaskGUID: "", customerGUID: this.customerGUID
    };

    const dialogRef = this._dialog.open(EmailClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }

  addNew(type: string) {
    let dialogConfig = new MatDialogConfig();
    let dialogRef;
    switch (type) {
      case "documents":
       
        dialogConfig.autoFocus = true;
        //  dialogConfig.width = "30em";
        //dialogConfig.height = "12em";
        dialogConfig.disableClose = true;
        dialogConfig.data = { personGUID: this.personGUID ,customerGUID: this.customerGUID  };
        dialogRef = this._dialog.open(CustomerPersonTravelDocSelectionDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result != null) {
            switch (result.actionName) {
              case "passport":
                this.openPassportEditPage();
                break;
              case "traveldoc":
                let doc1 = new PersonTravelDocumentModel();
                doc1.documentTypeGUID = result.newId;
                this.openTravelDocEditPage(doc1);
                break;
              case "visa":
                let doc2 = new PersonTravelDocumentModel();
                doc2.personTravelDocumentGUID = result.newId;
                if (doc2.personTravelDocumentGUID != '') {
                  this.openPassportVisalistPage(doc2);
                }
                else {
                  this.openPassportEditPage();
                }
                break;
            }
            
          }
        });
        break;
      case "comms":
        dialogConfig.autoFocus = true;
        //dialogConfig.width = "58em";//"1000px";
        //dialogConfig.height = "25em";//"500px";
        dialogConfig.disableClose = true;

        dialogConfig.data = { dialogTitle: "ADD New Record", personId: this.personGUID,customerGUID: this.customerGUID, personCommsAddressGUID: "" };

        dialogRef = this._dialog.open(CustomerPersonCommEditDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.commsRef.getResults();

          }
        });
        break;
      case "mail":
        dialogConfig.autoFocus = true;
        // dialogConfig.width = "65.5em";//"1000px";
        // dialogConfig.height = "25em";//"500px";
        dialogConfig.disableClose = true;

        dialogConfig.data = { dialogTitle: "ADD New Record", personId: this.personGUID,customerGUID: this.customerGUID, personMailAddressGUID: "" };


        dialogRef = this._dialog.open(CustomerPersonMailEditDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.mailRef.getResults();
          }
        });

        break;
      case "rewards":
        dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        //dialogConfig.width = "26em";//"1000px";
        //dialogConfig.height = "23em";//"500px";
        dialogConfig.disableClose = true;

        dialogConfig.data = { dialogTitle: "ADD New Record", personId: this.personGUID,customerGUID: this.customerGUID, personRewardAccountsGUID: "" };

        dialogRef = this._dialog.open(PersonRewardEditDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.rewardsRef.getResults();
          }
        });

        break;
    }
  }

   openPassportEditPage() {
    let s = "New Record";
    let personTravelDocumentGUID = "";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: this.personGUID,customerGUID: this.customerGUID, personTravelDocumentGUID: personTravelDocumentGUID };
    //dialogConfig.data = { dialogTitle: s };

    const dialogRef = this._dialog.open(PersonPassportEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.travelDocsRef.getResults();
      }
      else {
        if (result.newId != "") {
          let doc = new PersonTravelDocumentModel();
          doc.personTravelDocumentGUID = result.newId;
          this.openPassportVisalistPage(doc);
          
        }
      }
    });
  }


  openPassportVisalistPage(doc: PersonTravelDocumentModel) {

    let s = "";
    let personTravelDocumentGUID = doc.personTravelDocumentGUID;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "70em";//"1000px";
    //dialogConfig.height = "40em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: this.personGUID,customerGUID: this.customerGUID, personTravelDocumentGUID: personTravelDocumentGUID };
    //dialogConfig.data = { dialogTitle: s };

    const dialogRef = this._dialog.open(PersonPassportVisalistDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.travelDocsRef.getResults();
      }

    });

  }


  openTravelDocEditPage(doc: PersonTravelDocumentModel) {
    let s = "";
    let personTravelDocumentGUID = "";
    let documentTypeGUID = "";
    if (doc != null) {
      if (doc.documentTypeGUID != "" && doc.documentTypeGUID != null && doc.documentTypeGUID != undefined) {
        documentTypeGUID = doc.documentTypeGUID;
      }
      if (doc.personTravelDocumentGUID == "" || doc.personTravelDocumentGUID == null || doc.personTravelDocumentGUID == undefined) {
        personTravelDocumentGUID = "";
        s = "New Record";
      }
      else {
        personTravelDocumentGUID = doc.personTravelDocumentGUID;
        s = "Edit Record"
      }

    }
    else {
      s = "New Record";
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "23em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: this.personGUID,customerGUID: this.customerGUID, personTravelDocumentGUID: personTravelDocumentGUID, documentTypeGUID: documentTypeGUID };
    //dialogConfig.data = { dialogTitle: s };

    const dialogRef = this._dialog.open(PersonTravelDocEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.travelDocsRef.getResults();
      }

    });

  }

  onUpdateParentPage(e: any) {
    if (e.docCount != null && e.docCount != undefined)
      this.docCount = e.docCount;
    if (e.mailCount != null && e.mailCount != undefined)
      this.mailCount = e.mailCount;
    if (e.commCount != null && e.commCount != undefined)
      this.commCount = e.commCount;
    if (e.rewardCount != null && e.rewardCount != undefined)
      this.rewardCount = e.rewardCount;
  }

  openAccountList() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
 
    dialogConfig.data = { personGUID: this.personGUID,customerGUID: this.customerGUID, customerGroup:this.customer.customerGroup };
    dialogConfig.panelClass = "custom-dialog-container3";
    dialogConfig.width = "30em";
    dialogConfig.height = "auto";
    dialogConfig.maxHeight = "50em";
    const dialogRef = this._dialog.open(PersonAccountGrouplistComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isUpdated = true;
        this.getPersonInfo();
      }

    });
  }

  openInNew() {
    this._dialogRef.close(true);
    window.open('/persons/customer-person-details' + "?v=" + this._authService.getCurrentTimeNumber() + "&pId=" + this.personGUID + "&customerGUID=" + this.customerGUID + "&selectedPersonClassDescription=" + this.selectedPersonClass.personClassDescription + "&selectedPersonClassGUID=" + this.selectedPersonClass.personClassGUID + "&tripCodeGUID=" + this.tripCodeGUID);

  }

  close() {
    this._dialogRef.close(this.isUpdated);
  }

  openContacts() {
    this.close();
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGUID, customerName: this.customerName};

    config.height = "auto";
    config.maxHeight = "80em";
    config.width = "81em";
    this._dialog.open(ManageContactDetailsComponent, config);
  }
}
