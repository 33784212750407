import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { CustomValidators } from '../common-utility/custom.validators';
import { GlobalConstant } from '../common-utility/global-constant';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { CompanyEventModel } from '../models/company-event.model';
import { ContentDocumentModel } from '../models/content-document.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { FileModel } from '../models/file.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';
import { CKEditorConfig } from '../ckeditor5/ckeditor-config';
import * as DecoupledEditor from '../ckeditor5/ckeditor';

@Component({
  selector: 'app-company-event-edit-dialog',
  templateUrl: './company-event-edit-dialog.component.html',
  styleUrls: ['./company-event-edit-dialog.component.css']
})

/** company-event-dialog component*/
export class CompanyEventEditDialogComponent implements OnInit {
  public Editor = DecoupledEditor;

  public CKEConfig = CKEditorConfig.ckeditorConfigClient;

  public onReady(editor) {

    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()

    );

    //const clipboardPlugin = editor.plugins.get('ClipboardPipeline');
    //const editingView = editor.editing.view;

    //editingView.document.on('clipboardInput', (evt, data) => {
    //  if (editor.isReadOnly) {
    //    return;
    //  }
    //  const dataTransfer = data.dataTransfer;
    //  let content = plainTextToHtml(dataTransfer.getData('text/plain'));
    //  content = content.replace(/<p>/gi, '<p class=\"email-body\">')
    //  data.content = editor.data.htmlProcessor.toView(content);

    //  editingView.scrollToTheSelection();
    //}, { priority: 'high' });


    //const imageUploadEditing = editor.plugins.get('ImageUploadEditing');

    //imageUploadEditing.on('uploadComplete', (evt, { data, imageElement }) => {

    //  let img = new MessageEmbeddedImageModel();
    //  img.imageBase64String = data.model[0].bFile;
    //  img.documentName = data.model[0].fileName;
    //  img.mimeType = data.model[0].mimeType;
    //  img.isInline = true;
    //  img.contentId = data.model[0].fileName;
    //  this.embeddedImageList.push(img);
    //});
    //let data = "";
    //if (!this.isDraft)
    //  data += "<p class=\"email-body\"><br><br></p>" + this.defaultSignature
    //data += this.emailBody;
    //editor.setData(data);
    ////editor.focus();
    //editor.execute('style', 'Email Body');
    // editor.execute('style', { name: 'Email Body' });
    //editor.execute('fontSize', { value: '14px' });
    editor.setData(this.companyEventStory);
  }

  @Input() companyEventID: number;
  
  showSpin: boolean = false;
  company_event_edit_form: UntypedFormGroup;
  companyEventTitle: string = "";
  companyEventStory: string = "";
  effectiveDate: Date;
  expirationDate: Date;
  validTimeStart: string = "";
  validTimeEnd: string = "";
  startDate: Date;
  endDate: Date;
  contentDocumentList: ContentDocumentModel[]=[];
  hasExistingDocument: boolean = false;
  maxTempDocId: number = 0;
  allowedFileType: string;
  uploader: FileUploader;
  fileList: FileModel[] = [];
  finishAllUploads: boolean = true;
  docSize: string;
  isModified: boolean = false;
  allowedFileTypeList: string[];
  maxFileSize: number;
  maxFileUploadNumber: number = 0;
  showSuccessMsg: boolean = false;
  errMsg: string = "";
  errMsg2: string = "";
  submitted: boolean = false;
  loading: boolean = false;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  webLink: string;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  isUTC: boolean = false;
  customerGUID: string;
  @ViewChild('notesCKEditor') notesCKEditorRef: any;//ElementRef;

  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;
  @ViewChild('startDate') startDateRef: ElementRef;
  @ViewChild('endDate') endDateRef: ElementRef;

    /** company-event-dialog ctor */
  constructor(private readonly _dialogRef: MatDialogRef<CompanyEventEditDialogComponent>, private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder, 
    private readonly _customerService: CustomerService, private readonly _commonService: CommonService, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialogService: DialogService) {
    this.companyEventID = _data.companyEventID;
    this.customerGUID = _data.customerGUID;

  }

  ngOnInit() {
    this.maxTempDocId = 0;
    this.hasExistingDocument = false;
    this.allowedFileTypeList = [];
    this.maxFileSize = 0;
    this.maxFileUploadNumber = 0;

    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };
   
      this.getData();
  }

  getAllData(): Observable<any[]> {
    let getCompanyEventResponse;
    if (this.companyEventID == 0)
      getCompanyEventResponse = of(null);
    else
      getCompanyEventResponse = this._customerService.getCompanyEventBycIdceId(this.companyEventID)
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();

    return forkJoin([getCompanyEventResponse, getAllowedDocTypeResponse])
  }
   
  getData() {
    this.showSpin = true;
    this.getAllData().subscribe(responses => {      
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let companyEvent = new CompanyEventModel();
          companyEvent = responses[0].model;          
          this.companyEventTitle = companyEvent.companyEventTitle;
          this.companyEventStory = companyEvent.companyEventStory;
          if (companyEvent.effectiveDate != null && companyEvent.effectiveDate != "")
            this.effectiveDate = new Date(companyEvent.effectiveDate);
          if (companyEvent.expirationDate != null && companyEvent.expirationDate != "")
            this.expirationDate = new Date(companyEvent.expirationDate);
          if (companyEvent.displayDateStart != null && companyEvent.displayDateStart != "")
            this.startDate = new Date(companyEvent.displayDateStart);
          if (companyEvent.displayDateEnd != null && companyEvent.displayDateEnd != "")
            this.endDate = new Date(companyEvent.displayDateEnd);
          this.contentDocumentList = companyEvent.contentDocuments;
          this.validTimeStart = companyEvent.validTimeStart;
          this.validTimeEnd = companyEvent.validTimeEnd;
          this.isUTC = companyEvent.isUTC;
          this.modifiedBy = companyEvent.modifiedBy;
          this.modifiedDate = companyEvent.modifiedDate;
          this.webLink = companyEvent.documentWeblink;
          if (companyEvent.isActive == true)
            this.isActive = 1;
          else
            this.isActive = 0;
          if (this.contentDocumentList.length > 0)
            this.hasExistingDocument = true;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (responses[1].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[1].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.showSpin = false;
      this.initControls();
    });
  }

  initControls() {
    this.company_event_edit_form = this._formBuilder.group({
      eventTitle: [this.companyEventTitle,Validators.required],
      eventStory: [this.companyEventStory,Validators.required],
      effectiveDate: [this.effectiveDate, Validators.required],
      expirationDate: this.expirationDate,
      validTimeStart: this.validTimeStart,
      validTimeEnd: this.validTimeEnd,
      document: null,
      isUTC: this.isUTC,
      event_status: this.isActive,
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate,
      startDate: this.startDate,
      endDate: this.endDate,
      webLink:this.webLink
    });
  }


  clickPreviewDoc(event: any, doc: ContentDocumentModel) {

    let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
    let file = new Blob([byteArray], { type: doc.mimeType });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  timeOnchange(controlName: string, e: any) {

    if (e.target.value != "") {
      let timeObj = new DateTimeObjModel();
      timeObj.timeString = e.target.value;
      timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
      this.company_event_edit_form.get(controlName).setValue(timeObj.timeString);

      if (!timeObj.isValidDateTime) {
        this.company_event_edit_form.get(controlName).setErrors({ 'valid': false });
      }

    }
    else {
      this.company_event_edit_form.get(controlName).setValue(null);
      this.company_event_edit_form.get(controlName).setErrors(null);
    }
  }

  clickClose() {
    this._dialogRef.close(this.submitted);
  }

  importFile(event: any) {
    //let fileList: GroundStopTaskDocumentModel[];
    //fileList = this.taskDocumentList.filter(x => x.groundStopTaskDocumentGUID == "" || x.groundStopTaskDocumentGUID == null || x.groundStopTaskDocumentGUID == undefined)
    if ((this.fileList.length + event.target.files.length) > this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    this.errMsg = ""
    if (event.target.files.length > this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {     
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;
        //var a = CustomValidators.checkValidFileType(filename);
        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          if (event.target.files[i].size >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {             
            this.errMsg = 'The file size "' + filename + '" is too big to upload.';
            this.finishAllUploads = true;
            return;
          }
          else {
            files.push(event.target.files[i]);

          }
        }
        else {
          this.finishAllUploads = true;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            let obj = new FileModel();
            let obj2 = new ContentDocumentModel();
            this.maxTempDocId += 1;
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            obj.tempId = this.maxTempDocId;
            this.fileList.push(obj);

            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.documentSizeInKB = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.tempId = this.maxTempDocId;
            obj2.contentDocumentGUID = "";
            this.contentDocumentList.push(obj2);
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }


  clickRemoveDocument(e: any, item: ContentDocumentModel  ) {
    this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the document: " + item.documentName + "?");
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        this.isModified = true;
        if (item.contentDocumentGUID != "") {
          // remove doc from database, get newlist and then add unsaved file list
          let request = new ContentDocumentModel();
          request.contentDocumentGUID = item.contentDocumentGUID;
          request.documentName = item.documentName;
          this._commonService.removeContentDocumentBycontentDocId<ResponseModel<boolean>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              if (response.model) {
                this.getContentDocumentList();
              }
            }
            else {
              if (response.code == "401") {
                //this.errMsg = response.message
                this._authService.signOut();
              }

            }

          });
        }
        else {
          this.fileList.forEach((x, index) => {
            if (x.tempId == item.tempId) {
              this.fileList.splice(index, 1);
            }
          }
          );
          this.contentDocumentList.forEach(
            (x, index) => {
              if (x.tempId == item.tempId && x.contentDocumentGUID == "") {
                this.contentDocumentList.splice(index, 1);
              }
            });
        }
      }
    });
  }

  onFileClick(event) {
    event.target.value = '';
  }

  getContentDocumentList() {
    let request = new ContentDocumentModel()
    request.headlineID = 0;
    request.companyEventID = this.companyEventID;
    this._commonService.getContentDocumentByxId<ResponseModel<ContentDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.contentDocumentList = [];
        this.contentDocumentList = response.model;
        if (this.contentDocumentList.length > 0) {
          this.hasExistingDocument = true;
        }
        if (this.fileList.length > 0) {
          this.fileList.forEach(x => {
            let obj = new ContentDocumentModel();
            obj.documentName = x.fileName;
            obj.fileExtension = x.fileType;
            obj.documentSizeInKB = x.fileSize;
            obj.mimeType = x.mimeType;
            //obj.bFile = x.bFile;
            obj.tempId = x.tempId;
            obj.contentDocumentGUID = "";
            this.contentDocumentList.push(obj);
          });
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    });
  }

  get f() { return this.company_event_edit_form.controls; }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.effectiveDateRef.nativeElement.value !== "") {
      if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null && this.f.effectiveDate.value != undefined) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.effectiveDate.setErrors({ isValidDate: f1 });
        }
      }
      else {
        this.f.effectiveDate.setErrors({ isValidDate: false });
      }
    }

    if (this.expirationDateRef.nativeElement.value !== "") {
      if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null && this.f.expirationDate.value != undefined) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.expirationDate.setErrors({ isValidDate: f1 });
        }
      }
      else {
        this.f.expirationDate.setErrors({ isValidDate: false });
      }
    }

    if (this.effectiveDateRef.nativeElement.value !== "" && this.expirationDateRef.nativeElement.value !== "") {
      let s1 = "";
      let s2 = "";
      if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null && this.f.effectiveDate.value != undefined)
        s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
      if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null && this.f.expirationDate.value != undefined)
        s2 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
      let f1 = CustomValidators.isValidDateRange(s1, s2);
      if (!f1) {
        this.f.effectiveDate.setErrors({ isValidDateRange: f1 });
      }
    }

    if (this.startDateRef.nativeElement.value !== "") {
      if (this.f.startDate.value != "" && this.f.startDate.value != null && this.f.startDate.value != undefined) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.startDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.startDate.setErrors({ isValidDate: f1 });

        }
      }
      else {
        this.f.startDate.setErrors({ isValidDate: false });

      }
    }


    if (this.endDateRef.nativeElement.value !== "") {
      if (this.f.endDate.value != "" && this.f.endDate.value != null && this.f.endDate.value != undefined) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.endDate.setErrors({ isValidDate: f1 });

        }
      }
      else {
        this.f.endDate.setErrors({ isValidDate: false });

      }
    }

    if (this.startDateRef.nativeElement.value !== "" && this.endDateRef.nativeElement.value !== "") {
      let s1 = "";
      let s2 = "";
      if (this.f.startDate.value != "" && this.f.startDate.value != null && this.f.startDate.value != undefined)
        s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
      if (this.f.endDate.value != "" && this.f.endDate.value != null && this.f.endDate.value != undefined)
        s2 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.endDate.value));
      let f1 = CustomValidators.isValidDateRange(s1, s2);
      if (!f1) {
        this.f.startDate.setErrors({ isValidDateRange: f1 });
      }
    }

    if (this.company_event_edit_form.invalid || this.errMsg != "") {
      return;
    }

    let request = new CompanyEventModel();
    if (this.customerGUID != "")
      request.customerGUID = this.customerGUID;
    request.companyEventID = this.companyEventID;
    request.companyEventTitle = this.f.eventTitle.value;
    request.documentWeblink = this.f.webLink.value;
    request.companyEventStory = this.notesCKEditorRef.editorInstance.getData();// this.f.eventStory.value;
    request.companyEventStoryText = this.notesCKEditorRef.elementRef.nativeElement.innerText;
    if (request.companyEventStoryText != "") {
      let re = /Paragraph\n/gi;
      request.companyEventStoryText = request.companyEventStoryText.replace(re, '');
      request.companyEventStoryText = request.companyEventStoryText.trim();
      re = /Styles\n/gi;
      request.companyEventStoryText = request.companyEventStoryText.replace(re, '');
      request.companyEventStoryText = request.companyEventStoryText.trim();
      re = /Email Body\n/gi;
      request.companyEventStoryText = request.companyEventStoryText.replace(re, '');
      request.companyEventStoryText = request.companyEventStoryText.trim();
      re = /Signature\n/gi;
      request.companyEventStoryText = request.companyEventStoryText.replace(re, '');
      request.companyEventStoryText = request.companyEventStoryText.trim();
      re = /Heading 1\n/gi;
      request.companyEventStoryText = request.companyEventStoryText.replace(re, '');
      request.companyEventStoryText = request.companyEventStoryText.trim();
      re = /Heading 2\n/gi;
      request.companyEventStoryText = request.companyEventStoryText.replace(re, '');
      request.companyEventStoryText = request.companyEventStoryText.trim();
      re = /Heading 3\n/gi;
      request.companyEventStoryText = request.companyEventStoryText.replace(re, '');
      request.companyEventStoryText = request.companyEventStoryText.trim();
    }
    if (this.f.isUTC.value == "1")
      request.isUTC = true;
    else
      request.isUTC = false;
    request.displayDateStart = null;
    if (this.f.startDate.value != null) {
      request.displayDateStart = this.startDateRef.nativeElement.value;
    }
    request.displayDateEnd = null;
    if (this.f.endDate.value != null) {
      request.displayDateEnd = this.endDateRef.nativeElement.value;
    }
    request.effectiveDate = null;
    if (this.f.effectiveDate.value != null) {
      request.effectiveDate = this.effectiveDateRef.nativeElement.value;
    }
    request.expirationDate = null;
    if (this.f.expirationDate.value != null) {
      request.expirationDate = this.expirationDateRef.nativeElement.value;
    }
    request.validTimeStart = this.f.validTimeStart.value;
    request.validTimeEnd = this.f.validTimeEnd.value;
    request.isActive = true;
    if (this.f.event_status.value == "1")
      request.isActive = true;
    else
      request.isActive = false;
   
    request.contentDocuments = [];

    if (this.fileList.length > 0) {
      this.fileList.forEach(x => {
        let obj = new ContentDocumentModel();
        obj.documentName = x.fileName;
        obj.fileExtension = x.fileType;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.contentDocumentGUID = "";
        obj.containerPath = localStorage.getItem('cn') + '/';
        request.contentDocuments.push(obj);
      });
    }

    this.loading = true;
    this._customerService.saveCompanyEvent<ResponseModel<number>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.fileList = [];
          this.maxTempDocId = 0;
          this.errMsg = "";
          this.errMsg2 = "";
          this.getContentDocumentList();
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);

        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg2 = "Failed to save this record at this time. Please try later.";
          this.loading = false;
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg2 = "Failed to save the record. Please try again later.";
          this.loading = false;
        }
      }
      this.loading = false;
    });

  }
}
