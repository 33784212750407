import { MessageModel } from "./message.model";

export class MessageAttachmentModel extends MessageModel{
  messageAttachmentGUID: string;
  documentName: string;
  fileExtension: string;
  documentSizeInKB: number;
  containerPath: string;
  fileSize: number;
  bFile: string;
  mimeType: string;
  tempId: number;
  errMsg: string;
  groundStopTaskDocumentGUID: string;
  documentGUID: string;
  outlookMessageAttachmentId: string;
}
