import { UkGarManifestModel } from '../models/uk-gar-manifest.model'

export class UkGarModel {

  flightType: string;
  departureICAO: string;
  departureDate: Date;
  departureTime: string;
  arrivalICAO: string;
  arrivalDate: Date;
  arrivalTime: string;
  operator: string;
  registration: string;
  responsibleSurname: string;
  picContactNumber: string;
  aircraftType: string;
  homebaseAirport: string;
  reasonForVisit: string;
  crewManifest: UkGarManifestModel[];
  paxManifest: UkGarManifestModel[];
  tripCodeGUID: string;
  groundStopGUID: string;
  isDeparture: boolean;
  tripCode: string;
}
