
<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div  style="margin-top:-1em;width: 100%; justify-content: space-between; display: flex;" mat-dialog-title>
    <label>
      APIS Credentials
    </label>
    <button  mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button>
    </div>
  <div  >
    <table mat-table  [dataSource]="recordList" class="example-container">
      <tr>
        <ng-container matColumnDef="apisType">
          <th mat-header-cell *matHeaderCellDef style="width: 20%">
            Apis Type
          </th>
          <td mat-cell *matCellDef="let element">{{element.apisTypeDescription}}</td>
        </ng-container>      
        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef style="width: 30%">
            User Name
          </th>
          <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.apisUserName}}</td>
        </ng-container>
        <ng-container matColumnDef="password">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">
           Password
          </th>
          <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.apisPassword}}</td>
        </ng-container>
        <ng-container matColumnDef="effectiveDate">
          <th mat-header-cell *matHeaderCellDef style="width: 15%">
            Effective Date
          </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger': element.isExpired}">{{element.effectiveDate}}</td>
        </ng-container>
        
        <ng-container matColumnDef="action" >
          <th mat-header-cell *matHeaderCellDef style="width: 5%" [hidden]="!grantAccessToAPIS">Action</th>
          <td mat-cell *matCellDef="let element; let i=index " [hidden]="!grantAccessToAPIS">
            <a mat-button class="btn-low grid" (click)="clickEdit(element);">
              Edit
            </a>
           
          </td>
        </ng-container>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
    </table>
  </div>
  <mat-dialog-actions align="left">
    <button mat-dialog-close mat-button class="btn-low">Close</button>
  </mat-dialog-actions>
  
  <app-spin *ngIf="isLoading"></app-spin>
</div>

