import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { GtsBillableServicesComponent } from './gts-billable-services.component';

@Component({
  selector: 'app-accounting-dashboard',
  templateUrl: './accounting-dashboard.component.html',
  styleUrls: ['./accounting-dashboard.component.css']
})

export class AccountingDashboardComponent implements OnInit {
  // userType: string;
  // tripRevisionID: number;
  // userComments: string;
  accounting_dashboard_form: UntypedFormGroup;
  // loading = false;
  // v: number = this._authService.getCurrentTimeNumber();
  // returnObj = new ReturnObjModel();
  // controlValueChanged: boolean = false;
  // private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  // private eventSubscription: Subscription;
  isDialog: boolean = false;

  constructor(  @Optional() private readonly _dialogRef: MatDialogRef<AccountingDashboardComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
  private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,  private readonly dialog: MatDialog,
    ) {
   
    // this.tripRevisionID = _data.tripRevisionID;
    // this.userComments = _data.userComments;
    if (this.data) {
      this.isDialog = true;
    }  

  }

  ngOnInit() {

    // this._authService.updateAccessTime();



   }

  initControls() {
    this.accounting_dashboard_form = this._formBuilder.group({
      // userComments: [this.userComments]
    });


  }

  get f() { return this.accounting_dashboard_form.controls; }


 

  clickClose(val) {
    this._dialogRef.close(val);

  }


  // clickRouteNew(route: string,display: string){
  //   window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  // }


  clickRouteNew(route: string, display: string) {
    if (this.isDialog) {
      this.clickClose(true);
    }
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }

  openGtsBillableServicesComponent() {
    if(this.isDialog){  
      this.clickClose(true);
    }      
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "38em";//"1000px";
    config.width = "47em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px"};
    config.data = {  isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(GtsBillableServicesComponent, config);
  }
  
}











 