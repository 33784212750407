<div class="mt-1 ml-1" style="width:max-content">
  <div class="d-flex justify-content-between">
    <div><h5>Headlines</h5></div>
    <div class="d-inline-flex">
      <div><mat-checkbox [(ngModel)]="includeDisabled" (change)="getData()" [ngModelOptions]="{standalone: true}" style="transform:scale(.8)">Include Disabled</mat-checkbox></div>
      <div><mat-checkbox [(ngModel)]="includeExpired" (change)="getData()" [ngModelOptions]="{standalone: true}" style="transform:scale(.8)">Include Expired</mat-checkbox></div>
    </div>
  </div>
  <div class="mt-2">

    <table mat-table [dataSource]="headlineList" class="example-container">
      <tr>
        <ng-container matColumnDef="headline_type">
          <th mat-header-cell *matHeaderCellDef width="10%">
            Type
          </th>
          <td mat-cell *matCellDef="let element">{{element.headlineTypeDescription}}</td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef width="10%">
            Title
          </th>
          <td mat-cell *matCellDef="let element">{{element.headlineTitle}}</td>
        </ng-container>
        <ng-container matColumnDef="story">
          <th mat-header-cell *matHeaderCellDef width="25%">
            Story
          </th>
          <td style="text-align:left !important;" mat-cell *matCellDef="let element">{{element.headlineStoryText.length>500? element.headlineStoryText.substring(0,496) + '...' : element.headlineStoryText}}</td>
        </ng-container>
        <ng-container matColumnDef="attachment_count">
          <th mat-header-cell *matHeaderCellDef width="5%">
            Attachments
          </th>
          <td mat-cell *matCellDef="let element">{{element.attachmentCount>0?element.attachmentCount:''}}</td>
        </ng-container>
        <ng-container matColumnDef="weblink">
          <th mat-header-cell *matHeaderCellDef width="5%">
            Weblink
          </th>
          <td mat-cell *matCellDef="let element">{{element.documentWeblink!=null?'Yes':''}}</td>
        </ng-container>
        <ng-container matColumnDef="display_start">
          <th mat-header-cell *matHeaderCellDef width="8%">
            Display Date Start
          </th>
          <td mat-cell *matCellDef="let element">{{element.displayDateStart}}</td>
        </ng-container>
        <ng-container matColumnDef="display_end">
          <th mat-header-cell *matHeaderCellDef width="8%">
            Display Date End
          </th>
          <td mat-cell *matCellDef="let element">{{element.displayDateEnd}}</td>
        </ng-container>
        <ng-container matColumnDef="effective_date">
          <th mat-header-cell *matHeaderCellDef width="10%">
            Effective Date
          </th>
          <td mat-cell *matCellDef="let element">{{element.effectiveDate}} {{element.validTimeStart}} {{element.validTimeStart==null? '': element.isUTC? 'UTC': 'Local'}}</td>
        </ng-container>
        <ng-container matColumnDef="expiration_date">
          <th mat-header-cell *matHeaderCellDef width="10%">
            Expiration Date
          </th>
          <td mat-cell *matCellDef="let element">{{element.expirationDate}} {{element.validTimeEnd}} {{element.validTimeEnd==null? '' : element.isUTC? 'UTC': 'Local'}}</td>
        </ng-container>
        <ng-container matColumnDef="is_released">
          <th mat-header-cell *matHeaderCellDef width="5%">
            Released
          </th>
          <td mat-cell *matCellDef="let element">{{element.isEventReleased? 'Yes': ''}}</td>
        </ng-container>
        <ng-container matColumnDef="is_restricted">
          <th mat-header-cell *matHeaderCellDef width="5%">
            Restricted Distribution
          </th>
          <td mat-cell *matCellDef="let element">{{element.isDistributionRestricted? 'Yes': ''}}</td>
        </ng-container>
        <ng-container matColumnDef="customer_list">
          <th mat-header-cell *matHeaderCellDef width="10%">
            Client Distribution
          </th>
          <td mat-cell *matCellDef="let element">{{element.customerNameList}}</td>
        </ng-container>
        <ng-container matColumnDef="disabled">
          <th mat-header-cell *matHeaderCellDef width="5%">
            Disabled
          </th>
          <td mat-cell *matCellDef="let element">{{element.isActive? '': 'Yes'}}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="th" width="5%">Action<br /><a mat-button class="btn-high grid" (click)="clickEdit($event, null)">ADD</a></th>
          <td mat-cell *matCellDef="let element; let i=index ">
            <a mat-button class="btn-low grid">
              EDIT
            </a>
          </td>
        </ng-container>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickEdit($event, row);"></tr>
    </table>
    <app-spin *ngIf="showSpin"></app-spin>
  </div>
</div>
