<!--<form *ngIf="vendor_details_form" [formGroup]="vendor_details_form">-->
<div class="pb-3">
  <!--<div>
    <h5 class="ml-3">Current Events Dashboard</h5>
  </div>-->
  <div class="d-flex mt-1 ml-3 p-1 border rounded" style="height:23em;width:93.6em;  box-shadow: 0 2px 5px 0 #f8f8f8;">
    <div>
      <div class="d-flex position-relative" style="background: linear-gradient(to bottom, #A1DCFF,#A1BEFF);">
        <mat-icon style="color:#cc0000">airline_stops</mat-icon>
        <h5 style="color:#cc0000">Upcoming Schedules</h5>
        <label class="small ml-5 mt-1" style="font-size: small;">Filter Schedules - Next</label>
        <mat-radio-group id="queryHour" aria-label="Select an option" [(ngModel)]="queryHour" (change)="getData(false)" [ngModelOptions]="{standalone: true}">
          <mat-radio-button style="transform:scale(.8)" color="primary" value="24">24 hours</mat-radio-button>
          <mat-radio-button style="transform:scale(.8)" color="primary" value="48">48 hours</mat-radio-button>
          <mat-radio-button style="transform:scale(.8)" color="primary" value="72">72 hours</mat-radio-button>
          <!--<mat-radio-button style="transform:scale(.8)" color="primary" value="720">30 Days</mat-radio-button>-->
        </mat-radio-group>
        <div>
          <app-spin *ngIf="showSpin"></app-spin>
        </div>
        <div class="small mt-1 ml-3" style="font-size: small;">
          <label *ngIf="clientCount>0">{{clientCount}} {{clientCount>1? 'Clients' : 'Client'}} /&nbsp;</label>
          <label *ngIf="tripCount>0">{{tripCount}} {{tripCount>1? 'Trips' : 'Trip'}} /&nbsp;</label>
          <label *ngIf="sortedData && sortedData.length>0">{{sortedData.length}} Legs</label>
        </div>
        <div class="small mt-1" style="position: absolute; right: .5em; font-size: small; ">
          <a [routerLink]="" style="color:black" (click)="openLegend()">Legend</a>
        </div>
      </div>
      <div style="max-height: 20em; overflow-y: auto;overflow-x:hidden">

        <table mat-table [dataSource]="sortedData" matSort matSortActive="departure_date" matSortDirection="asc"  (matSortChange)="sortData($event)" class="example-container">
          <tr>
            <ng-container matColumnDef="trip_code">
              <th mat-sort-header="trip_code" mat-header-cell *matHeaderCellDef width="5%">
                Trip Code
              </th>
              <td mat-cell *matCellDef="let element" matTooltip="{{element.customerName}}"><a *ngIf="upPII" [routerLink]="" (click)="openTrip(element)">{{element.tripCode}}</a><span *ngIf="upPII==false">{{element.tripCode}}</span></td>
            </ng-container>
            <ng-container matColumnDef="registration">
              <th mat-sort-header="registration" mat-header-cell *matHeaderCellDef width="5%">
                Registration
              </th>
              <td mat-cell *matCellDef="let element" (click)="openChecklist(element)" style="font-weight:bold">{{element.registration}}</td>
            </ng-container>
            <ng-container matColumnDef="departure_date">
              <th mat-sort-header="departure_date" mat-header-cell *matHeaderCellDef width="6%">
                Departure (Z)
              </th>
              <td mat-cell *matCellDef="let element" (click)="openChecklist(element)">{{element.departureDateTimeUTCText}}</td>
            </ng-container>
            <ng-container matColumnDef="crew_count">
              <th mat-sort-header="crew_count" mat-header-cell *matHeaderCellDef width="2%">
                Crew
              </th>
              <td mat-cell *matCellDef="let element" (click)="openChecklist(element)" matTooltip="{{upPII? element.departureCrewList: ''}}">{{element.departureCrewCount}}</td>
            </ng-container>
            <ng-container matColumnDef="pax_count">
              <th mat-sort-header="pax_count" mat-header-cell *matHeaderCellDef width="2%">
                Pax
              </th>
              <td mat-cell *matCellDef="let element" (click)="openChecklist(element)" matTooltip="{{upPII? element.departurePaxList: ''}}">{{element.departurePaxCount}}</td>
            </ng-container>
            <ng-container matColumnDef="dep_vendor_name">
              <th mat-header-cell *matHeaderCellDef width="13%">
                Handler/SSN
              </th>
              <td mat-cell *matCellDef="let element" (click)="openChecklist(element)">
                <span>{{element.departureVendorList[0].vendorName!=null && element.departureVendorList[0].vendorName!=''? 'H: ' + element.departureVendorList[0].vendorName : ''}}</span>
                <br />{{element.departureVendorList[1].vendorName!=null && element.departureVendorList[1].vendorName!=''? 'S: ' + element.departureVendorList[1].vendorName : ''}}
              </td>
            </ng-container>
            <ng-container matColumnDef="ete">
              <th mat-sort-header="ete" mat-header-cell *matHeaderCellDef width="3%">
                ETE
              </th>
              <td mat-cell *matCellDef="let element" (click)="openChecklist(element)">{{element.ete}}</td>
            </ng-container>
            <ng-container matColumnDef="leg">
              <th mat-sort-header="leg" mat-header-cell *matHeaderCellDef width="6%">
                Leg
              </th>
              <td mat-cell *matCellDef="let element" style="font-weight:bold" (click)="openChecklist(element)">{{element.departureICAO}} - {{element.nextArrivalICAO}}</td>
            </ng-container>
            <ng-container matColumnDef="arrival_date">
              <th mat-sort-header="arrival_date" mat-header-cell *matHeaderCellDef width="6%">
                Arrival (Z)
              </th>
              <td mat-cell *matCellDef="let element" (click)="openChecklist(element)">{{element.nextArrivalDateTimeUTCText}}</td>
            </ng-container>
            <ng-container matColumnDef="arr_vendor_name">
              <th mat-header-cell *matHeaderCellDef width="13%">
                Handler/SSN
              </th>
              <td mat-cell *matCellDef="let element" (click)="openChecklist(element)">
                {{element.nextArrivalVendorList[0].vendorName!=null && element.nextArrivalVendorList[0].vendorName!=''? 'H: ' + element.nextArrivalVendorList[0].vendorName : ''}}
                <br />{{element.nextArrivalVendorList[1].vendorName!=null && element.nextArrivalVendorList[1].vendorName!=''? 'S: ' + element.nextArrivalVendorList[1].vendorName : ''}}
              </td>
            </ng-container>
            <ng-container matColumnDef="icon">
              <th mat-header-cell *matHeaderCellDef width="3%">
                Status
              </th>
              <td style="text-align:center !important" mat-cell *matCellDef="let element" (click)="openChecklist(element)" >
                <span *ngIf="element.departureOnHold || element.arrivalOnHold" style="cursor:pointer"  class="material-icons-two-tone pink">pause_circle</span>
                <span *ngIf="!element.departureOnHold && !element.arrivalOnHold" style="cursor:pointer" class="material-icons-two-tone {{element.checklistStatusIconColor}}">{{element.checklistStatusIconName}}</span>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"
              (click)="clickRow(row)"
              [class.row-is-clicked]="clickedRows.has(row)"></tr>
        </table>
      </div>
    </div>


    <div class="ml-2" style="width:33em;">
      <!--<div class="d-flex justify-content-between" style="width:100%">
    <div>
      <h5>Trip Legs</h5>
    </div>
    <div *ngIf="map">
      <span style="font-size:xx-small"> *Great Circle Route</span>
    </div>
  </div>-->
      <div style="position: relative;">
        <div style="width:100%;height:30em;border-radius:.25em" id="map"></div>
        <div *ngIf="sortedData.length>0" style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
          fullscreen
        </div>
      </div>
      
    </div>

  </div>
  <div class="d-flex">
    <div class="mt-3 ml-3 p-1 border rounded" style="    width: 30.5em;
    height: 16.5em;
    background-color: white;
    background: linear-gradient(to bottom, #E1E1FF,#efefff);
    box-shadow: 0 2px 5px 0 #efefff;">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <span style="color:#cc0000" class="material-icons-outlined">
            campaign
          </span>
          <h5 style="color:#cc0000">{{customerName}} Events</h5>
        </div>
        <div *ngIf="grantAccessCompanyEvents">
          <span style="color:#cc0000" class="material-icons-outlined pointer" (click)="addCompanyEvent()">
            add
          </span>
        </div>
      </div>
      <div>
        <div style="max-height:14em;overflow-y:auto">
          <ng-template ngFor let-event [ngForOf]="eventList" let-i="index">
            <div class="eventDiv">
              <a style="color:black;font-weight:bold" [routerLink]="" (click)="openCompanyEvent(event.companyEventID)">{{event.companyEventTitle}} {{event.effectiveDate}} {{event.expirationDate!=null? ' - ' + event.expirationDate : ''}}</a>
              <span *ngIf="event.attachmentCount>0" class="material-icons-outlined" style="transform:rotate(-45deg);font-size:medium">attachment</span>
              <span *ngIf="event.documentWeblink!=null && event.documentWeblink!=''" class="material-icons-outlined" style="font-size:medium;cursor:pointer" (click)="openLink(event.documentWeblink)">language</span>              
            </div>
          </ng-template>
          </div>
        </div>
    </div>
    <div class="mt-3 ml-3 p-1 border rounded" style="width:30.5em;height:16.5em;background-color:white;background: linear-gradient(to bottom, #E1E1FF,#efefff);   box-shadow: 0 2px 5px 0 #efefff;">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <span style="color:#cc0000" class="material-icons-outlined">
            feed
          </span>
          <h5 style="color:#cc0000">Headlines</h5>
        </div>
        <div>
          <span style="color:#cc0000" class="material-icons-outlined pointer" (click)="addHeadline()">
            add
          </span>
        </div>
      </div>
      <div style="max-height:14em;overflow-y:auto">
        <ng-template ngFor let-headlineGroup [ngForOf]="headlines" let-i="index">
          <div class="eventDiv">
            <div><label style="font-weight:bold">{{headlineGroup.headlineType}}</label></div>
            <ng-template ngFor let-headline [ngForOf]="headlineGroup.headlines" let-i="index">
                <div>
                    <a [ngClass]="{ 'bg-warning': headlineGroup.headlineType=='FPC Maintenance' }" style="color:black;" [routerLink]="" (click)="openHeadline(headline.headlineID)">{{headline.headlineTitle}} {{headline.effectiveDate}} {{headline.expirationDate!=null? ' - ' + headline.expirationDate : ''}}</a>
                    <span *ngIf="headline.attachmentCount>0" class="material-icons-outlined" style="transform:rotate(-45deg);font-size:medium">attachment</span>
                    <span *ngIf="headline.documentWeblink!=null && headline.documentWeblink!=''" class="material-icons-outlined" style="font-size:medium;cursor:pointer" (click)="openLink(headline.documentWeblink)">language</span>
                </div>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="mt-3 ml-3 p-1 border rounded" style="width:30.5em;min-height:16.5em;max-height:max-content;background: linear-gradient(to bottom,#fff0f0, #ffe4e4);   box-shadow: 0 2px 5px 0 #ffe4e4;">
      <div class="d-flex">
        <span style="color:#cc0000" class="material-icons-outlined">
          info
        </span>
        <h5 style="color:#cc0000">Alerts</h5>
      </div>
      <div style="max-height:14em;overflow-y:auto">
        <div class="alertDiv" *ngIf="businessRulesList && businessRulesList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            grading
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(businessRulesList,'businessRules')">{{businessRulesList.length}} {{businessRulesList.length==1? 'Business Rule':'Business Rules'}} Needing Approval</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="missingCrewList && missingCrewList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            people
          </span>
          <div><a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(missingCrewList,'missingCrew')">{{missingCrewList.length}} {{missingCrewList.length==1? 'Leg':'Legs'}} Missing Crew</a></div>
        </div>
        <div class="alertDiv" *ngIf="missingPaxList && missingPaxList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            airline_seat_recline_normal
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(missingPaxList,'missingPax')">{{missingPaxList.length}} {{missingPaxList.length==1? 'Leg':'Legs'}} Missing PAX</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="tbaPaxList && tbaPaxList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            airline_seat_recline_normal
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(tbaPaxList,'tbaPax')">{{tbaPaxList.length}} <!--{{tbaPaxList.length==1? 'Leg':'Legs'}} with--> TBA PAX</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="tbaCrewList && tbaCrewList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            people
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(tbaCrewList,'tbaCrew')">{{tbaCrewList.length}} <!--{{tbaCrewList.length==1? 'Leg':'Legs'}} with--> TBA Crew</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="followUpPaxList && followUpPaxList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            airline_seat_recline_normal
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(followUpPaxList,'followUpPax')">{{followUpPaxList.length}} <!--{{followUpPaxList.length==1? 'Leg':'Legs'}} Needing--> PAX Needing Follow Up</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="followUpCrewList && followUpCrewList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            people
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(followUpCrewList,'followUpCrew')">{{followUpCrewList.length}} <!--{{followUpCrewList.length==1? 'Leg':'Legs'}} Needing--> Crew Needing Follow Up</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="expiredTravelDocList && expiredTravelDocList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            badge
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(expiredTravelDocList,'expiredManifest')">{{expiredTravelDocList.length}} Expired Manifest {{expiredTravelDocList.length==1? 'Document':'Documents'}}</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="missingTravelDocuments && missingTravelDocuments.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            description
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(missingTravelDocuments,'missingManifest')">{{missingTravelDocuments.length}} Missing Manifest {{missingTravelDocuments.length==1? 'Document':'Documents'}}</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="waitingTaskList && waitingTaskList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            assignment
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(waitingTaskList,'task')">{{waitingTaskList.length}} {{waitingTaskList.length==1? 'Task':'Tasks'}} Waiting for Client Reply</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="waitingVendorTaskList && waitingVendorTaskList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            assignment
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(waitingVendorTaskList,'vendorTask')">{{waitingVendorTaskList.length}} {{waitingVendorTaskList.length==1? 'Task':'Tasks'}} Waiting for Vendor Reply</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="expiredACDocList && expiredACDocList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            airplane_ticket
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(expiredACDocList,'aircraft')">{{expiredACDocList.length}} Expired Aircraft {{expiredACDocList.length==1? 'Document':'Documents'}}</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="expiredCustomerDocList && expiredCustomerDocList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            business
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(expiredCustomerDocList,'customer')">{{expiredCustomerDocList.length}} Expired Client {{expiredCustomerDocList.length==1? 'Document':'Documents'}}</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="missingSSNList && missingSSNList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            grading
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(missingSSNList,'ssn')">{{missingSSNList.length}} {{missingSSNList.length==1? 'Leg':'Legs'}} Missing SSN Task</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="openTaskList && openTaskList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            assignment
          </span>
          <div>
            <a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(openTaskList,'openTask')">{{openTaskList.length}} {{openTaskList.length==1? 'Open Task':'Open Tasks'}} On Cancelled Stops</a>
          </div>
        </div>
        <div class="alertDiv" *ngIf="onHoldList && onHoldList.length>0">
          <span style="margin-right:.25em" class="material-icons-outlined">
            pause_circle
          </span>
          <div><a style="color:black;font-weight:bold" [routerLink]="" (click)="openAlert(onHoldList,'onHold')">{{holdTripCount}} {{holdTripCount==1? 'Trip':'Trips'}} / {{onHoldList.length}} {{onHoldList.length==1? 'Leg':'Legs'}} / {{holdTaskCount}} {{holdTaskCount==1? 'Task':'Tasks'}} On Hold</a></div>
        </div>
      </div>
    </div>


  </div>
  <div class="d-flex">
    <div class="mt-3 ml-3 p-1 border rounded" style="min-height: 14.5em; max-height: max-content; width: 30.5em; background: linear-gradient(to bottom,#97c6de, #a8ddf7); box-shadow: 0 2px 5px 0 #a8ddf7;">
      <div class="d-flex justify-content-between">
        <div>
          <div class="d-flex" style="color:#cc0000">
            <span style="color:#cc0000" class="material-icons-outlined">
              leaderboard
            </span>
            <h5>Flight Log - Current Week</h5>
          </div>
        </div>
        <div>
          <label  style="font-size:small" *ngIf="newTripList && newTripList.length>0">{{newTripList.length}} {{newTripList.length>1? 'Trips' : 'Trip'}} /&nbsp;</label>
            <label  style="font-size:small" *ngIf="totalLegCount>0">{{totalLegCount}} {{totalLegCount>1? 'Legs' : 'Leg'}}</label>
          </div>
        </div>
      <div *ngIf="newTripList && newTripList.length>0">
        <div style="max-height: 12em; overflow-y: auto;overflow-x:hidden">

          <table mat-table [dataSource]="newTripList" class="example-container2">
            <tr>
              <ng-container matColumnDef="submit_date">
                <th mat-header-cell class="mat-header-cell2" *matHeaderCellDef width="15%">
                  Submitted
                </th>
                <td mat-cell class="mat-cell2" *matCellDef="let element">{{element.submitDate}}</td>
              </ng-container>
              <ng-container matColumnDef="customer_name">
                <th mat-header-cell class="mat-header-cell2" *matHeaderCellDef width="48%">
                  Client
                </th>
                <td mat-cell class="mat-cell2" *matCellDef="let element">{{element.customerName}}</td>
              </ng-container>
              <ng-container matColumnDef="trip_code">
                <th mat-header-cell class="mat-header-cell2" *matHeaderCellDef width="15%">
                  Trip Code
                </th>
                <td mat-cell class="mat-cell2" *matCellDef="let element" matTooltip="{{element.additinalReqTooltip}}"><a [routerLink]="" (click)="openTrip(element)">{{element.tripCode}}</a></td>
              </ng-container>
              <ng-container matColumnDef="registration">
                <th mat-header-cell class="mat-header-cell2" *matHeaderCellDef width="15%">
                  Registration
                </th>
                <td mat-cell class="mat-cell2" *matCellDef="let element">{{element.registration}}</td>
              </ng-container>
              <ng-container matColumnDef="leg_count">
                <th mat-header-cell class="mat-header-cell2" *matHeaderCellDef width="7%">
                  Legs
                </th>
                <td mat-cell class="mat-cell2" *matCellDef="let element">{{element.legCount}}</td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumnTrip; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnTrip"></tr>
          </table>
        </div>

      </div>
    </div>
    <div class="mt-3 ml-3 p-1 border rounded" style="width: 26.5em; min-height: 14.5em; max-height: max-content; background-color: white; background: linear-gradient(to bottom,#97c6de, #a8ddf7); box-shadow: 0 2px 5px 0 #a8ddf7;">
      <div class="d-flex">
        <span style="color:#cc0000" class="material-icons-outlined">
          notifications_active
        </span>
        <h5 style="color:#cc0000">Latest Trip Updates - Last 8 hrs</h5>
      </div>
      <div style="max-height:12em;overflow-y:auto" *ngIf="tripRevisionList && tripRevisionList.length>0">
        <ng-template ngFor let-revision [ngForOf]="tripRevisionList">
          <ng-template ngFor let-c [ngForOf]="revision.customerList">
            <div class="updateDiv">
              <div>
                <label style="font-weight:bold">{{c.customerName}}</label>
              </div>
              <ng-template ngFor let-list [ngForOf]="c.tripList">
                <div>
                  <a style="color:black;" [routerLink]="" (click)="openTrip(list)">{{list.tripCode}}</a> - <a style="color:black;" [routerLink]="" (click)="openAudit(list)">{{list.transactionCount}} {{list.transactionCount==1? 'Change':'Changes'}}</a>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
    <div class="mt-3 ml-3 p-1 border rounded" style="width:34.5em;min-height:14.5em;max-height:max-content;background: linear-gradient(to bottom,#fff0f0, #ffe4e4);   box-shadow: 0 2px 5px 0 #ffe4e4;">
      <div class="d-flex justify-content-between">
        <div  class="d-flex ">
          <div class="d-flex" style="color:#cc0000">
            <span style="color:#cc0000" class="material-icons-outlined">
              monetization_on
            </span>
            <h5>Upcoming Trip Closeout</h5>
          </div>
          <div >
            <button type="button" mat-button style="margin-left: 17em;" class="btn-low grid" (click)="clickRouteNew('current-events/trip-closeout-queue')"><span style="color: black;">See More</span></button>
          </div>
        </div>
        <label  style="font-size:small" *ngIf="tripCloseoutList && tripCloseoutList.length>0">{{tripCloseoutList.length}} {{tripCloseoutList.length>1? 'Trips' : 'Trip'}}</label>
        </div>
        <div *ngIf="tripCloseoutList && tripCloseoutList.length>0">
          <div style="max-height: 12em; overflow-y: auto;overflow-x:hidden">

            <table mat-table [dataSource]="tripCloseoutList" class="example-container3">
              <tr>
                <ng-container matColumnDef="last_arrival">
                  <th mat-header-cell class="mat-header-cell2" *matHeaderCellDef width="15%">
                    Last Arrival
                  </th>
                  <td mat-cell class="mat-cell2" *matCellDef="let element">{{element.estimatedTripEndDate}}</td>
                </ng-container>
                <ng-container matColumnDef="customer_name">
                  <th mat-header-cell class="mat-header-cell2" *matHeaderCellDef width="40%">
                    Client
                  </th>
                  <td mat-cell class="mat-cell2" *matCellDef="let element">{{element.customerName}}</td>
                </ng-container>
                <ng-container matColumnDef="trip_code">
                  <th mat-header-cell class="mat-header-cell2" *matHeaderCellDef width="15%">
                    Trip Code
                  </th>
                  <td mat-cell class="mat-cell2" *matCellDef="let element" matTooltip="{{element.additinalReqTooltip}}"><a [routerLink]="" (click)="openTrip(element)">{{element.tripCode}}</a></td>
                </ng-container>
                <ng-container matColumnDef="registration">
                  <th mat-header-cell class="mat-header-cell2" *matHeaderCellDef width="15%">
                    Registration
                  </th>
                  <td mat-cell class="mat-cell2" *matCellDef="let element">{{element.registration}}</td>
                </ng-container>
                <ng-container matColumnDef="days_to_end">
                  <th mat-header-cell class="mat-header-cell2" *matHeaderCellDef width="15%">
                    Days to End
                  </th>
                  <td mat-cell class="mat-cell2" *matCellDef="let element">{{element.daysToTripEnd}}</td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumnCloseout; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnCloseout"></tr>
            </table>
          </div>

        </div>
      </div>
  </div>

    <div  class="d-flex">
      <!-- <div class="mt-3 ml-3 p-1 border rounded" style="    width: 24em;
          height: 16.5em;
          background-color: white;
          background: linear-gradient(to bottom, #FBFCDD,#FBFCDD);
          box-shadow: 0 2px 5px 0 #efefff;"> -->
      <div class="mt-3 ml-3 p-1 border rounded" 
            style="width:30.5em;min-height:14.5em;max-height:max-content;
                   background: linear-gradient(to bottom, #fffefb,#e7deae);   box-shadow: 0 2px 5px 0 #e7deae;">
  
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <span style="color:#cc0000; padding-right: .2em;" class="material-icons-outlined">
              online_prediction
            </span>
            <h5 style="color:#cc0000; display: flex;">Audit Trail - Last 24 hrs</h5>
            <!-- <span style="font-size: medium; margin-top: 0.19em; margin-left: 0.5em;"></span> -->
          </div>
          <div >
            <button type="button" mat-button class="btn-low grid" (click)="clickRouteNew('/ground-stops/trip-audit-dashboard')"><span style="color: black;">See More</span></button>
          </div>
        </div>
        <div class="example-action-buttons" style="font-size: small; display: flex; margin-left: 2em;"> 
          <b style="color: #767373;"><span *ngIf="auditTrailList[0]?.totalClients > 1">{{auditTrailList[0]?.totalClients}} Client Accts -</span> {{auditTrailList[0]?.userLogins[0]?.totalUsers}} Users </b>&nbsp;
        </div>
        <section style="display: flex; font-size: small !important;">
          <div  style="width: 15.5em; margin-left: 2em;"><b>Client Accts</b></div>
          <div  style="width: 3em; margin-right: 1.8em;"><b>Users </b></div>
          <div  style="width: 3em; margin-right: 1.8em;"><b>Mobile</b></div>
          <div  style="width: 3em; margin-right: 1.8em;"><b>Web</b></div>
        </section>
        <div>
          <div style="max-height:14em;overflow-y:auto">
          <mat-accordion displayMode="flat" multi class="mat-table">
            <!-- <section class="mat-elevation-z8 mat-header-row" style="display: flex; ">
              <div class="mat-header-cell" style="width: 15.5em; margin-left: 2em;"><b>Customer Name</b></div>
              <div class="mat-header-cell" style="width: 3em; margin-right: 2.5em;"><b>Users </b></div>
              <div class="mat-header-cell" style="width: 3em; margin-right: 2.2em;"><b>Mobile</b></div>
              <div class="mat-header-cell" style="width: 3em; margin-right: 2.2em;"><b>Web</b></div>
            </section> -->
            <mat-expansion-panel *ngFor="let item of auditTrailList;" style="background-color: transparent !important; box-shadow: none;">
              <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'"  class="mat-row" style=" height: 2em;">
                <span class="mat-cell" style="width: 15em;">{{ ((  item.custumerName.length>25 )? item.custumerName.substr(0,25)+ '...' : item.custumerName)}}</span>

               
                <span class="mat-cell" style="width: 1.5em; margin-right: 3em; ">{{item.userCount}}</span>
                <span class="mat-cell" style="width: 1.5em; margin-right: 3em; ">{{item.mobileCount > 0? item.mobileCount: ''}}</span>
                <span class="mat-cell" style="width: 1.5em; margin-right: 3em; ">{{item.webCount > 0? item.webCount: ''}}</span>
              </mat-expansion-panel-header>
              <div *ngFor="let user of item.userLogins;" style="display: flex;   background-color:transparent !important;">
                <span class="mat-cell" style="margin-right: 5.55em; display: flex;    margin-left: 2em; width: 12em;">{{user.userName}}</span>
                <div style="display: flex;">
                  <span class="mat-cell" style="width: 2em;display: flex;margin-right: 2.5em;">{{user.mobileCount > 0? 'x': ''}}</span>
                  <span class="mat-cell" style="width: 2em;display: flex;margin-right: 3.5em;">{{user.webCount > 0 ?'x': ''}}</span>
                </div>
                <!-- <span>{{user.isMobile == true ? 'Mobile' : 'Web'}}</span> -->
              </div>
            </mat-expansion-panel>
          </mat-accordion>
            </div>
          </div>
      </div>

       <!-- <div   >
        <div class="mt-3 ml-3 p-1 border rounded" 
              style="width:30.5em;min-height:14.5em;max-height:max-content;
                     ">
                     
              <div class="chart-container" style="overflow-x: auto; width: 40em; max-width: 40em;">
              <div style=" height: 21em; ">

                <canvas #MyChart style="margin-left: -5em;"  >{{ chart }}</canvas>
                <div style=" margin-left:  7em;">No of Users For Web And Mobile</div>
              </div>
            </div>
        </div>
      </div> -->




    </div>

     
</div>  
