import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthenticateService } from '../services/authenticate.service';
import { AirportService } from '../services/airport.service';
import { AirportDocumentModel } from '../models/airport-document.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ResponseModel } from '../models/response.model';
import { AirportDocumentsEditDialogComponent } from './airport-documents-edit-dialog.component';
import { UtilityFunctions } from '../common-utility/utility.functions';

@Component({
  selector: 'app-airport-documents',
  templateUrl: './airport-documents.component.html',
  styleUrls: ['./airport-documents.component.css']
})
export class AirportDocumentsComponent implements OnInit, OnChanges {
  @Input() airportID: number;
  @Input() isActive: boolean;
  airportDocuments: AirportDocumentModel[];
  airportDocumentsAll: AirportDocumentModel[];
  errMsg: string = "";
  showSpin: boolean = false;
  displayedColumn: string[] = ['type', 'name','effective_date','expiration_date','show_to_client', 'action'];

  constructor(private readonly _authService: AuthenticateService, private readonly _airportService: AirportService, private readonly _dialog: MatDialog,) {

  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.airportDocumentsAll)
      this.airportDocuments = this.airportDocumentsAll.filter(x => x.isActive === this.isActive);
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.getData();
  }
  getData() {
    this._airportService.getAirportDocumentsByAirportID<ResponseModel<AirportDocumentModel[]>>(this.airportID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.airportDocumentsAll = response.model;
          this.airportDocuments = this.airportDocumentsAll.filter(x => x.isActive === this.isActive);
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMsg = "Error gettings documetns.";
        }
      }
    });
  }

  clickEdit(e: any, doc: AirportDocumentModel) {
      let s = "";
      let action = "";
      let airportDocumentGUID = "";
      if (doc == null) {
        s = "ADD New Record";
        action = "add";
      }
      else {
        s = "EDIT Record";
        action = "edit";
        airportDocumentGUID = doc.airportDocumentGUID;
      }

      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = false;
      // dialogConfig.width = "52em";//"1000px";
      //dialogConfig.height = "25em";//"500px";
      dialogConfig.disableClose = true;
      dialogConfig.hasBackdrop = false;

      dialogConfig.data = {
        dialogTitle: s, airportID: this.airportID, 
        action: action, airportDocumentGUID: airportDocumentGUID, document: doc
      };
      const dialogRef = this._dialog.open(AirportDocumentsEditDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getData();
        }

      });
  }

  clickPreview(item: AirportDocumentModel) {
      this._authService.updateAccessTime();


      this._airportService.getAirportDocument<ResponseModel<AirportDocumentModel[]>>(item).subscribe(response => {
        if (response != null && response.code == "200") {


          let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
          let file = new Blob([byteArray], { type: item.mimeType });
          file.size
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);

        }
        

      });


    
  }


}
