<div style="height: 100%;" class="greyBack" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="display: inline-flex; width: 100%; justify-content: space-between; padding: 0.5em;">
    <div>
      <h3 style="font-weight: bold;">Push Notification</h3>
      <h5>Customize your alerts messages</h5>
    </div>

    <div style="display: flex;">
      <button mat-icon-button *ngIf="isDialog" (click)="openUpdateMyAccountComponent()"> <i
          class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i> </button>
      <button mat-icon-button *ngIf="isDialog" (click)="clickRouteNew('user-accounts/update-my-account','')"> <i
          class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>
      <button mat-icon-button *ngIf="isDialog" mat-dialog-close><i class="material-icons"
          style="font-size: x-large;">close </i></button>
    </div>
  </div>
  <div style="background-color: white; padding: 1em; font-size: small;">
    <div style="display: flex; flex-direction: column;">
      <div style="display: inline-flex;">
        <div>Turn ON FPC Push Notification Alert Messages</div>
        <mat-slide-toggle (click)="togglePushNotifications()" style="transform:scale(.8);font-size:medium"
          color="primary"></mat-slide-toggle>
      </div>
      <div class="greyBack" style="display: inline-flex;">
        <div style="background-color: #f6cd26; align-content: center;">
          <i class="material-symbols-outlined" style="float: left;">warning</i>
        </div>
        <div>If you choose Text Message, you will receive one text message per request with a verification code. Message
          and Data rates may apply.</div>
      </div>
    </div>

    <div *ngIf="pushNotificationsOn">
      <div style="display: inline-flex; border-bottom: 1px solid lightgray;">
        <div style="padding: 0.5em;">
          <div style="color: #1976e6; font-weight: bold;">Alert Subject Line</div>
          <div>Use Trip Context as Subject Line (Sample below)</div>
          <div style="margin-left: 0.5em;"> Example: Tripcode XXX240101 EGGW/LIRA 03/01/2024 12:40z</div>
        </div>
        <div style="padding: 0.5em;">
          <div>Additional Information (optional)</div>
          <input type="text" style="width:14em;height:1.6em; font-size: small;"
            [ngStyle]="{'background-color' : f.text_filter.disabled ? '#e9ecef' : '#eaf0fd'}" maxlength="50"
            (mousedown)="$event.stopPropagation()" />
        </div>
      </div>

      <div style="display: inline-flex; border-bottom: 1px solid lightgray;">
        <div style="padding: 0.5em">
          <div style="color: #1976e6; font-weight: bold;">Send Alert To</div>
        </div>
        
      </div>
    </div>
  </div>
</div>