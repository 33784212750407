<div class="divLeft">
  <div>
    <h5>Vendor Profiles</h5>
    <div>
      <form *ngIf="vendor_profile_list_form" [formGroup]="vendor_profile_list_form" (ngSubmit)="getSearchResults()">
        <div style="margin-left:0em;padding:0em; display: flex">
          <div class="form-group" style="margin-top:0em;">

            <label for="airport" style="font-size:small">ICAO/IATA</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.35em">
              <input type="text" formControlName="airport" class="form-control form-control-sm inputBox" style="width:5em;" />
            </div>
          </div>
          <div style="margin-left: 0.3125em;margin-top:0em; ">
            <label for="vendor_account" style="font-size:small">Vendor Account</label>
            <div class="form-group" style="margin-left: 0em; margin-top:-0.35em">
              <input type="text" formControlName="vendor_account" class="form-control form-control-sm inputBox" style="width:9.75em;" />
            </div>
          </div>


          <div style="margin-left: 0.3125em; margin-top:0em">
            <label for="country_select" style="font-size:small">Country</label>
            <div class="form-group" style="margin-top:-0.35em;">
              <select *ngIf="f" formControlName="country_select" tabindex=1 class="form-control form-control-sm dropdown">
                <option value=""></option>
                <option *ngFor="let cty of countryList" [ngValue]="cty.countryGUID">{{cty.countryName}}</option>
              </select>
            </div>
          </div>
          <div  style="margin-left: 0.3125em;">
            <div>
              <label for="checkRoles" style="font-size:small">Role</label>
            </div>
              <div style="margin-top:-.5em">
                <mat-select #checkRoles placeholder="All" required panelClass="custom-mat-select-panel2 placeholder1" formControlName="checkRoles" multiple style="border: solid lightgray 0.0625em; font-size: small; color:black; border-radius: 0.25em; padding-left:0.25em; height:1.65em;width:17em;">
                  <div (mouseleave)="checkRoles.close()">
                    <mat-option *ngFor="let r of vendorRoleList" [value]="r" (onSelectionChange)="checkVendorRole($event, r)" style="height:1.5em; line-height:1.5em !important;">{{r.vendorRole}} </mat-option>
                  </div>
                </mat-select>
              </div>
           
          </div>
       
          <div style="margin-left: 0.3125em; margin-top:0em">
            <label for="compliance_status" style="font-size:small">Compliance Status</label>
            <div class="form-group" style="margin-top:-0.35em;">
              <select *ngIf="f" formControlName="compliance_status" tabindex=1 class="form-control form-control-sm dropdown">
                <option value=""></option>
                <option *ngFor="let compliance of complianceStatusList" [ngValue]="compliance.vendorComplianceStatusID">{{compliance.vendorComplianceStatusDescription}}</option>
              </select>
            </div>
          </div>
          <div style="margin-left: 0.3125em; margin-top:0em">
            <label for="rating" style="font-size:small">Rating</label>
            <div class="form-group" style="margin-top:-0.35em;">
              <select *ngIf="f" formControlName="rating" tabindex=1 class="form-control form-control-sm dropdown">
                <option value=""></option>
                <option *ngFor="let rating of ratingList" [ngValue]="rating.ratingID">{{rating.ratingDescription}}</option>
              </select>
            </div>
          </div>
          <div style="margin-left: 0.3125em; margin-top:0em">
            <label for="contract_status" style="font-size:small">Contract Status</label>
            <div class="form-group" style="margin-top:-0.35em;">
              <select *ngIf="f" formControlName="contract_status" tabindex=1 class="form-control form-control-sm dropdown">
                <option value=""></option>
                <option *ngFor="let contract of contractStatusList" [ngValue]="contract.contractStatusID">{{contract.contractStatusDescription}}</option>
              </select>
            </div>
          </div>

          <div class="form-group" style="margin-left: 0.3125em;margin-top:1.35em">
            <div>
              <button [disabled]="loading" mat-button class="btn-high" (click)="getSearchResults()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Search
              </button>&nbsp;
              <button mat-button type="button" class="btn-low" (click)="clickReset()" style="width: 5em">Reset</button>&nbsp;
              <button mat-icon-button   style="margin: -0.5em 0 0 0.5em; " (click)="openThirdPartyVendorComponent()" >  <i class="material-icons-outlined" style="font-size:xx-large; ">arrow_circle_left</i> </button>    

            </div>
            <div *ngIf="totalRecords>0" style="font-size: small;"class="small mt-2"> {{totalRecords}} Records</div>
          </div>
        </div>
          <div *ngIf="msg!=''" class="inline-block"><label style="color:forestgreen">&nbsp;{{msg}}</label></div>
          <div *ngIf="errMsg!=''" class="inline-block"><label style="color: #c00000">{{errMsg}}</label></div>
          <div class="form-group form-inline">
            <ul class="tabul">
              <ng-template  ngFor let-element [ngForOf]="vendorStatusList" let-i="index">
                <li [ngClass]="{ 'btn-low':selectedTabName==element.vendorStatusDescription,'btn-high pointer':selectedTabName!=element.vendorStatusDescription  }" style="border-left: black 0.0625em solid;" (click)="clickTab(element)"><div style="margin-top:0.25em">{{element.vendorStatusDescription}}</div></li>
              </ng-template>
            </ul>
            <!--<div>
              <button type="button" mat-stroked-button [matMenuTriggerFor]="menu" style="transform:scale(.8)">Sort Order <mat-icon>menu</mat-icon></button>
              <mat-menu #menu>
                <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                  <div class="example-box" *ngFor="let col of sortBy" cdkDrag>{{col.displayName}}</div>
                </div>
              </mat-menu>
            </div>-->
          </div>
          <div style="margin-top:-1em">
            <table mat-table [dataSource]="dataSource" class="example-container">
              <tr>
                <ng-container matColumnDef="country_name">
                  <th mat-header-cell *matHeaderCellDef class="th"  width="10%">
                    Country
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align:left !important">{{element.countryName}}</td>
                </ng-container>
                <ng-container matColumnDef="airport">
                  <th mat-header-cell *matHeaderCellDef class="th"  width="8%">
                    Airport
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align:left !important">{{element.icao}}</td>
                </ng-container>
                <ng-container matColumnDef="vendor_name">
                  <th mat-header-cell *matHeaderCellDef class="th" width="15%">
                    Vendor Account
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.vendorName}}</td>
                </ng-container>
                <ng-container matColumnDef="falicity_type" width="5%">
                  <th mat-header-cell *matHeaderCellDef class="th">
                    Facility Type
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.facilityDescription}}</td>
                </ng-container>
                <ng-container matColumnDef="vendor_role">
                  <th mat-header-cell *matHeaderCellDef class="th">
                    Role
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.roleList}}</td>
                </ng-container>
                <ng-container matColumnDef="contract_status">
                  <th mat-header-cell *matHeaderCellDef class="th">
                    Contract Status
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.contractStatusDescription}}</td>
                </ng-container>
                <ng-container matColumnDef="rating">
                  <th mat-header-cell *matHeaderCellDef class="th" width="8%">
                    Rating
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.ratingDescription}}</td>
                </ng-container>
                <ng-container matColumnDef="method_of_payment">
                  <th mat-header-cell *matHeaderCellDef class="th" width="8%">
                    Method of Payment
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.vendorMethodOfPaymentDescription}}</td>
                </ng-container>
                <ng-container matColumnDef="compliance_status">
                  <th mat-header-cell *matHeaderCellDef class="th" width="8%">
                    Compliance Status
                  </th>
                  <td mat-cell *matCellDef="let element"><span style="cursor:pointer" matTooltipClass="tooltipLineBreak" matTooltip="{{element.complianceTooltip}}">{{element.vendorComplianceStatusDescription}}</span></td>
                </ng-container>
                <ng-container matColumnDef="related_vendors">
                  <th mat-header-cell *matHeaderCellDef class="th" width="8%">
                    Related Vendors
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.relatedVendorList}}</td>
                </ng-container>
                <ng-container matColumnDef="service_arrangement">
                  <th mat-header-cell *matHeaderCellDef class="th" width="8%">
                    Service Arrangement
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.serviceArrangement}}</td>
                </ng-container>                
                <ng-container matColumnDef="flight_brief_note_count">
                  <th mat-header-cell *matHeaderCellDef class="th" width="8%">
                    Flight Brief Notes
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.flightBriefNoteCount>0? element.flightBriefNoteCount : ''}}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="th" width="5%">Action<br /><a mat-button class="btn-high grid" (click)="clickAdd($event, null)">ADD</a></th>
                  <td mat-cell *matCellDef="let element; let i=index ">
                    <a mat-button class="btn-low grid">
                      EDIT
                    </a>
                  </td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickEdit($event, row);"></tr>
            </table>
            <!--<div style="width:90em">
              <mat-paginator style="border: none" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                             [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
              </mat-paginator>
            </div>-->

          </div>


</form>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
