import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { VendorService } from '../services/vendor.service';
import { DialogService } from '../services/dialog.service';
import { NoteTypeModel } from '../models/note-type.model';
import { VendorNotesModel } from '../models/vendor-notes.model';


@Component({
  selector: 'app-vendor-notes-edit-dialog',
  templateUrl: './vendor-notes-edit-dialog.component.html',
  styleUrls: ['./vendor-notes-edit-dialog.component.css']
})
/** vendor-profile-setup-add component*/
export class VendorNotesEditDialogComponent implements OnInit {
  vendor_notes_edit_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() vendorGuid: string;
  @Input() vendorNoteID: number;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  noteTypeList: NoteTypeModel[];
  vendor_note: string;
  selectedNoteTypeId: number;
  modifiedBy: string;
  modifiedDate: string;
  isModified: boolean;
  isActive: number;
  showSuccessMsg: boolean = false;
  @ViewChild('noteTypeList') noteTypeListRef: ElementRef

  constructor(private readonly _dialogRef: MatDialogRef<VendorNotesEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _vendorService: VendorService,
    private readonly _dialogService: DialogService
  ) {
    this.vendorGuid = data.vendorGUID;
    this.vendorNoteID = data.vendorNoteID;
    this.dialogTitle = data.dialogTitle;

  }

  getAllData(): Observable<any[]> {

    let getNoteType = this._commonService.getNoteTypeListByxId<ResponseModel<NoteTypeModel[]>>(this.vendorGuid, "Vendor");

    let getVendorNoteResponse = this._vendorService.getVendorNoteByVendorNoteId<ResponseModel<VendorNotesModel>>(this.vendorNoteID);

    return forkJoin([getNoteType, getVendorNoteResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.noteTypeList = responses[0].model;
          
       //   this.selectedVendorTypeId = null;// this.vendorTypeList[0].vendorTypeID;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.vendorNoteID != null && this.vendorNoteID != 0) {
        if (responses[1] != null) {
          if (responses[1].code == "200" && responses[1].message == "") {
            let vendorNote = new VendorNotesModel();
            vendorNote = responses[1].model;
            this.noteTypeList = this.noteTypeList.concat([{ noteTypeID: vendorNote.noteTypeID, noteTypeDescription: vendorNote.noteTypeDesc, requiresYN: false }]);
            this.selectedNoteTypeId = vendorNote.noteTypeID;
            this.vendor_note = vendorNote.vendorNote;
            this.modifiedBy = vendorNote.modifiedBy;
            this.modifiedDate = vendorNote.modifiedDate;
            if (vendorNote.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;

          }
        }

      }

      this.initControls();

      this.showSpin = false;


    });

  }

  initControls() {
    this.vendor_notes_edit_form = this._formBuilder.group({
      note_type: this.selectedNoteTypeId,
      vendor_note: this.vendor_note,
      note_status: this.isActive,
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate


    });
  }

  get f() { return this.vendor_notes_edit_form.controls; }

  

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";
    if (this.f.note_type.value == null) {
      this.errMsg = "Note Type is required.";
    }
    if (this.f.vendor_note.value == null) {
      if (this.errMsg != "") {
        this.errMsg += "\n";
        this.errMsg += "Note is required.";
      }
      else
        this.errMsg = "Note is required.";    
    }
    if (this.vendor_notes_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    let obj = new VendorNotesModel();
    obj.vendorNotesID = 0;
    if (this.vendorNoteID != 0 && this.vendorNoteID != null)
      obj.vendorNotesID = this.vendorNoteID;
    obj.vendorGUID = this.vendorGuid;
    obj.noteTypeID = this.f.note_type.value;
    obj.vendorNote = this.f.vendor_note.value;
    if (this.f.note_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;
    
    this._vendorService.saveVendorNote<ResponseModel<number>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.vendorNoteID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          this.isModified = true;
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
     }
      this.loading = false;
    })

  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.vendorNoteID = 0;
    this.selectedNoteTypeId = null;
    this.vendor_note = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.noteTypeList = [];
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.noteTypeList = responses[0].model;

          //   this.selectedVendorTypeId = null;// this.vendorTypeList[0].vendorTypeID;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
    this.initControls();    
  }

  clickClose() {
     this._dialogRef.close(this.isModified);
  }


}
