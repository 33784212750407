import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
//import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { CustomValidators } from '../common-utility/custom.validators';
import { GlobalConstant } from '../common-utility/global-constant';
import { CurrencyModel } from '../models/currency.model';
import { FileModel } from '../models/file.model';
import { ResponseModel } from '../models/response.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { AuthenticateService } from '../services/authenticate.service';
import { FuelService } from '../services/fuel.service';

@Component({
  selector: 'app-currency-upload',
  templateUrl: './currency-upload.component.html',
  styleUrls: ['./currency-upload.component.css']
})
export class CurrencyUploadComponent   implements OnInit { 
  currency_form: FormGroup;
  //fuelSupplierList: FuelSupplierModel[];
  Lk_FuelSupplierGUID: string;
  fuelSupplier: string;
  errMsg: string;
  loading = false;
  submitted: boolean;
  isFileInputDisabled: boolean;// = true;
   currencyModel: CurrencyModel;
  //fuelpricefile: FuelPriceFileModel; 

 // fuelSuppliersList: FuelPriceFileModel[] = [];  

  finishUploads: boolean;
  finishAllUploads: boolean;
  allowedFileTypeList: ['csv', 'xlsx', 'xls'];

  maxFileSize = 0;
  allowedFileType: string;
  uploader: FileUploader; 

  file: File;
  selectedFile: File;


  showButton: boolean;
  fromCurrencyMain: boolean = false;
  attachedfilename: string = '';

  //fs_select: string;// ='default';
  maxTempDocId: number = 0;
  selectedValue: any;
  enablefileupload: boolean;
  showSuccessMsg: boolean;
  successMsg: string;

  fileList: FileModel[];
   
  successCount: number;
  failureCount: number;


  displayedColumn: string[] = ['IATA', 'ICAO', 'FuelSupplierShortname', 'ModifiedDate', 'ModifiedBy'];
  constructor(
    private readonly _formBuilder: UntypedFormBuilder, private readonly _fuelService: FuelService,
    private readonly _authService: AuthenticateService, private http: HttpClient,
    private _titleService: Title,
    @Optional() private readonly _dialogRef: MatDialogRef<CurrencyUploadComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    if (data != null) {
      this.fromCurrencyMain = data.fromCurrencyMain;
    }
    //this.isFileInputDisabled = true;
  }

  initControls() {
    this.currency_form = this._formBuilder.group({
      document: [{ value: '' }],/*, disabled: true*/
      //fs_select: [this.fs_select],
      filename: [this.attachedfilename],
    });
  }

  get f() { return this.currency_form.controls; }

  ngOnInit() {
    this.Lk_FuelSupplierGUID = ''; 

    this.showButton = false;
    //this.isFileInputDisabled = true;


   // this.fs_select = 'default';
    // this._authService.updateAccessTime();

    
    this.errMsg = "";
     
    this.allowedFileTypeList = ['csv', 'xlsx', 'xls'];
    this.maxFileSize = 0;

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    } 

    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

   // this._titleService.setTitle("Currency");
    
    this.initControls();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onFileClick(event) {
    this.errMsg = "";
    event.target.value = '';
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

    let result = JSON.parse(response); //success server response 
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {

          result.model.forEach((item, index) => {
            //let obj = new FileModel();
            //this.maxTempDocId += 1;
            //obj.fileName = item.fileName;
            //obj.filePath = item.filePath;
            //obj.fileSize = item.fileSize;
            //obj.bFile = item.bFile;
            //obj.mimeType = item.mimeType;
            //obj.fileType = item.fileType;
            //obj.tempId = this.maxTempDocId;

            //this.fileList.push(obj);

            let obj2 = new CurrencyModel();
            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.documentSizeInKB = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.tempId = this.maxTempDocId;
            obj2.AttachmentGUID = "";
            obj2.groundStopTaskDocumentGUID = "";
            obj2.documentGUID = "";

           // obj2.fuelSupplier = this.fuelSupplier;

            this.currencyModel = obj2;
            this.attachedfilename = item.fileName;
            //    this.fsFile=obj2;
          });
        }
      }
      else {
        this.errMsg = result.message;
      }

    }

  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }

  importFile(event: any) {


    this.finishAllUploads = false;

    var files = [];
    this.errMsg = ""
    if (event.target.files.length > 1) {//this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {     
      // this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;
        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {

          files.push(event.target.files[i]);


        }
        else {
          this.finishAllUploads = true;
          //this.fileInput.nativeElement.value = '';
          this.showButton = false;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }
    }

    if (this.errMsg != "") {
      this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
    this.showButton = true;
    setTimeout(() => {
      //this.setMessageBody(""),
      1000;
    });
  }

  clickSend() {
    this.errMsg = "";
    this._authService.updateAccessTime();

    this.loading = true;
    let request = new CurrencyModel();
    request = this.currencyModel;

    this._fuelService.uploadCurrencyList<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
              let m = response.model;
          let d = new Date();
          let m1 = d.getMonth() + 1;
          let dt = m1.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
           


          if (m == true) { 
            this.showSuccessMsg = true;
            this.showButton = false; 

            this.attachedfilename = '';
         //   this.fs_select = 'default';
            this.successMsg = "Currency Upload Successful.";

            if (this.fromCurrencyMain == true) {
              setTimeout(() => {
                let returnResponse = new ReturnObjModel();
                returnResponse.refresh = true;
                // returnResponse.newId = this.fuelPricingId.toString();
                this.showSuccessMsg = false;
                this._dialogRef.close(returnResponse);
              }, 1000);
            }
            else {
              setTimeout(() => {
                this.showSuccessMsg = false;
                //this.clickClose(true);
              }, 1000);
            }
          }
          else {
            this.errMsg = "Failed to Fuel Price Upload. Please try again.";
          }
        }
      }

      this.loading = false;
    })

  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  //fuelSupplierChange(event: any) {


  //  let fuelsupplier = event.target.options[event.target.options.selectedIndex].text;
  //  //  this.selectedCommAddressType = text;


  //  let index = event.target.options.selectedIndex;
  //  const selectedValue = (event.target as HTMLSelectElement).value;
  //  if (index == 0) {
  //    this.showButton = false;
  //    this.isFileInputDisabled = true;
  //   // this.fileInput.nativeElement.value = '';
  //  }
  //  else {
  //    //this.showButton = true;
  //    this.isFileInputDisabled = false;
  //    this.fuelSupplier = fuelsupplier;
  //  }
  //}

  clickClose() {
    let returnResponse = new ReturnObjModel();
    returnResponse.refresh = true;
    // returnResponse.newId = this.fuelPricingId.toString();
    //this.showSuccessMsg = true;
    this._dialogRef.close(returnResponse);
  }
}
