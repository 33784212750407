<div  style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3em;">
      <i class="material-icons-outlined" style="font-size: x-large;cursor: pointer; color: gray">event_available</i>
    </div>
    <div style="margin-top:0.3125em;">
      <div><h5>Manage Cancellations</h5></div>
      <div style="display: flex; margin-top:-0.5em">
        <div style="margin-right:1em">
          <div style="font-size: small">Registration</div>
          <div style="font-weight: bold; font-size: small">{{registration}}</div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">Trip Code</div>
          <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">Route</div>
          <div style="font-size: small;display:flex;flex-wrap:wrap;max-width:35em;">
            <div *ngFor="let gs of groundStopList; let i=index">
              <div style="display: flex">
                <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==groundStopList.length-1? '': '-'}}</div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
        </div>
      </div>
  <div>
    <app-spin *ngIf="showSpin"></app-spin>
    <div style="padding-left: 1em; padding-right: 1em;">
      <div style="background-color: white;max-height:25em;overflow-x:auto">
        <table mat-table *ngIf="activeGroundStopList" [dataSource]="activeGroundStopList" class="example-container">
          <tr>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef width="20%">
                Select
              </th>
              <td mat-cell *matCellDef="let element; let i = index"><mat-radio-button (change)="updateSelection($event, element, i)" style="transform:scale(.8)" color="primary"></mat-radio-button></td>
            </ng-container>
            <ng-container matColumnDef="icao">
              <th mat-header-cell *matHeaderCellDef width="20%">
                Airport Stop
              </th>
              <td mat-cell *matCellDef="let element" [style.background-color]="element.groundStopTypeColor">{{element.icao}}</td>
            </ng-container>

            <ng-container matColumnDef="date_time">
              <th mat-header-cell *matHeaderCellDef width="30%">
                Date/Time (Z)
              </th>
              <td mat-cell *matCellDef="let element" style="text-align:left !important">

                <div *ngIf="!element.isFirstGroundStop">A: {{element.arrivalDateTextUTC!=''?element.arrivalDateTimeUTCDisplay+' Z':'TBA'}}<br /></div>
                <div *ngIf="!element.isLastGroundStop">D: {{element.departureDateTextUTC!=''?element.departureDateTimeUTCDisplay+' Z':'TBA'}}</div>

              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef width="30%">
                Airport Status
              </th>
              <td mat-cell *matCellDef="let element" [style.background-color]="element.groundStopTypeColor">{{element.groundStopTypeDescription}}</td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>

        </table>
      </div>
    </div>
    <div class="d-inline-flex">
      <div class="p-2">

        <button [disabled]="buttonText=='Select'" mat-button class="btn-high mr-2" (click)="clickSave()">{{buttonText}}</button>
        <button mat-button class="btn-low" [mat-dialog-close]>Close</button>
      </div>
        <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]="successMsg"></app-success-message>
      </div>
      <div *ngIf="errMsg!=''" class="alert alert-danger small p-0 mt-2">
        {{errMsg}}
      </div>
    </div>
  </div>
  </div>
         
