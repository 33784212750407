import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of } from 'rxjs';
import { FlightPlanPreferenceModel } from '../models/flight-plan-preference';
import { GroundStopAlternateAirportModel } from '../models/ground-stop-alternate-airport.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { FlightPlanPreferenceService } from '../services/flight-plan-preference.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { FlightPlanPreferenceEditComponent } from './flight-plan-preference-edit.component';
import { EventEmitter } from 'protractor';

@Component({
    selector: 'app-flight-plan-preference-display',
    templateUrl: './flight-plan-preference-display.component.html',
    styleUrls: ['./flight-plan-preference-display.component.css']
})
/** flight-plan-preference-edit component*/
export class FlightPlanPreferenceDisplayComponent implements OnInit {
  @Input() groundStopGUID: string;
  @Input() tripCodeGUID: string;
  @Input() groundStopTaskGUID: string;
  @Input() nextGroundStopGUID: string;
  flightPlanPreferenceID: number;
  flight_plan_preference_edit_form: UntypedFormGroup;
  tripCode: string;
  icao: string;
  icao_iata: string;
  airportName: string;
  airportCountry: string;
  arrivalDateTime: string;
  departureDateTime: string;
  arrivalDateTimeLocal: string;
  departureDateTimeLocal: string;
  nextICAO_IATA: string;
  nextAirportName: string;
  nextICAO: string;
  nextCountry: string;
  registration: string;
  aircraftModel: string;
  aircraftManufacturer: string;
  aircraftInfo: string;
  cruiseSpeed: string="";
  cruiseSpeedClientRequested: boolean=false;
  flightLevel: string;
  flightLevelClientRequested: boolean=false;
  payload: string;
  payloadClientRequested: boolean = false;
  departureFuel: string;
  departureFuelClientRequested: boolean = false;
  fuelOverDestination: string;
  fodClientRequested: boolean = false;
  hold: string;
  holdClientRequested: boolean = false;
  reserve: string;
  reserveClientRequested: boolean = false;
  tank: string;
  tankClientRequested: boolean = false;
  flightRoute: string;
  remarks: string="";
  isActive: boolean = false;
  modifiedBy: string = "";
  modifiedDate: string = "";
  etps: string="";
  etpsClientRequested: boolean=false;
  alternateAirports: string="";
  altClientRequested: boolean=false;
  flightPlanPreference: FlightPlanPreferenceModel;
  errMsg: string = "";
  showSuccessMsg: boolean = false;
  loading: boolean = false;
  crewCount: number;
  paxCount: number;
  cargo: string;
  cargoClientRequested: boolean = false;

    /** flight-plan-preference-edit ctor */
  constructor(private readonly _dialogRef: MatDialogRef<FlightPlanPreferenceDisplayComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _flightPlanPreferenceService: FlightPlanPreferenceService) {
    
  }
  ngOnInit() {
          this.getData();
  }

  getData() {
    this.cruiseSpeed = "";
    this.cruiseSpeedClientRequested=false;
    this.flightLevel = "";
    this.flightLevelClientRequested = false;
    this.payload = "";
    this.payloadClientRequested = false;
    this.departureFuel = "";
    this.departureFuelClientRequested = false;
    this.fuelOverDestination = "";
    this.fodClientRequested = false;
    this.hold = "";
    this.holdClientRequested = false;
    this.reserve = "";
    this.reserveClientRequested = false;
    this.tank = "";
    this.tankClientRequested = false;
    this.flightRoute = "";
    this.remarks = "";
    this.etps = ""; 
    this.etpsClientRequested = false;
    this.alternateAirports= "";
    this.altClientRequested = false;
    this.flightPlanPreference = null;
    this.cargo = "";
    this.cargoClientRequested = false;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let alternates = responses[0].model;
          alternates.forEach(x => {
            if (x.alternateAirportTypeID == 1 || x.alternateAirportTypeID == 2) {
              if (this.alternateAirports != "")
                this.alternateAirports += " ";
              this.alternateAirports += x.icao;
            }
            if (x.alternateAirportTypeID == 4) {
              if (this.etps != "")
                this.etps += " ";
              this.etps += x.icao;
            }
          })
          
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          //let fpp = new FlightPlanPreferenceModel();
          this.flightPlanPreference = responses[1].model;
          this.flightPlanPreferenceID = this.flightPlanPreference.flightPlanPreferenceID;
          this.cruiseSpeed = this.flightPlanPreference.cruiseSpeed;
          this.cruiseSpeedClientRequested = this.flightPlanPreference.cruiseSpeedClientRequested;
          this.flightLevel = this.flightPlanPreference.flightLevel;
          this.flightLevelClientRequested = this.flightPlanPreference.flightLevelClientRequested;
          this.payload = this.flightPlanPreference.payload;
          this.payloadClientRequested = this.flightPlanPreference.payloadClientRequested;
          this.departureFuel = this.flightPlanPreference.departureFuel;
          this.departureFuelClientRequested = this.flightPlanPreference.departureFuelClientRequested;
          this.fuelOverDestination = this.flightPlanPreference.fuelOverDestination;
          this.fodClientRequested = this.flightPlanPreference.fodClientRequested;
          this.hold = this.flightPlanPreference.hold;
          this.holdClientRequested = this.flightPlanPreference.holdClientRequested;
          this.reserve = this.flightPlanPreference.reserve;
          this.reserveClientRequested = this.flightPlanPreference.reserveClientRequested;
          this.tank = this.flightPlanPreference.tank;
          this.tankClientRequested = this.flightPlanPreference.tankClientRequested;
          this.flightRoute = this.flightPlanPreference.flightRoute;
          this.remarks = this.flightPlanPreference.remarks;
          this.isActive = this.flightPlanPreference.isActive;
          this.modifiedBy = this.flightPlanPreference.modifiedBy;
          this.modifiedDate = this.flightPlanPreference.modifiedDate;
          this.altClientRequested = this.flightPlanPreference.altClientRequested;
          this.etpsClientRequested = this.flightPlanPreference.etpsClientRequested;
          this.cargo = this.flightPlanPreference.cargo
          this.cargoClientRequested = this.flightPlanPreference.cargoClientRequested;

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      
      this.initControls();
    });
  }

  get f() { return this.flight_plan_preference_edit_form.controls; }

  initControls() {
    this.flight_plan_preference_edit_form = this._formBuilder.group({
      cruise_speed: this.cruiseSpeed,
      cruise_speed_CR: this.cruiseSpeedClientRequested,
      flight_level: this.flightLevel,
      flight_level_CR: this.flightLevelClientRequested,
      payload: this.payload,
      payload_CR: this.payloadClientRequested,
      departure_fuel: this.departureFuel,
      departure_fuel_CR: this.departureFuelClientRequested,
      fod: this.fuelOverDestination,
      fod_CR: this.fodClientRequested,
      hold: this.hold,
      hold_CR: this.holdClientRequested,
      reserve: this.reserve,
      reserve_CR: this.reserveClientRequested,
      tank: this.tank,
      tank_CR: this.tankClientRequested,
      etps_CR: this.etpsClientRequested,
      alt_CR: this.altClientRequested,
      etps: this.etps,
      alternate: this.alternateAirports,
      flight_route: this.flightRoute,
      remarks: this.remarks
      
    });
  }

  getAllData(): Observable<any[]> {
    let obj = new GroundStopAlternateAirportModel();
    obj.groundStopGUID = this.groundStopGUID;
    obj.nextGroundStopGUID = this.nextGroundStopGUID;
    let getGroundStopAltAirport = this._groundStopAdvancedService.getGroundStopAlternateAirportForEditBygsIdnextgsId(obj);
    let request = new GroundStopTaskModel();    
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    let getFPPRepsonse;
    
    getFPPRepsonse = this._flightPlanPreferenceService.getFlightPlanPreferenceBygstId<ResponseModel<FlightPlanPreferenceModel>>(request);
    
    return forkJoin([getGroundStopAltAirport, getFPPRepsonse]);
  }


  editFPP() {
    const config = new MatDialogConfig();
    //config.panelClass = "custom-dialog-container"
   // config.restoreFocus = false;
    if (this.flightPlanPreference == null) {
      this.flightPlanPreference = new FlightPlanPreferenceModel();
      this.flightPlanPreference.flightPlanPreferenceID = null;
      this.flightPlanPreference.groundStopTaskGUID = this.groundStopTaskGUID;
    }
    config.data = { tripCodeGUID: this.tripCodeGUID, flightPlanPreference: this.flightPlanPreference };
    this._dialog.open(FlightPlanPreferenceEditComponent, config).afterClosed().subscribe(result => {
      if (result)
        this.getData();
    });
  }

}
