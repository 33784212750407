import { Component } from '@angular/core';

@Component({
    selector: 'app-dev-tickets-bi-dashboard',
  templateUrl: './dev-tickets-bi-dashboard.component.html',
  styleUrls: ['./dev-tickets-bi-dashboard.component.css']
})
/** airport-bi-dashboard component*/
export class DevTicketsBiDashboardComponent {
    /** airport-bi-dashboard ctor */
    constructor() {

    }
}
