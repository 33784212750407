import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { forkJoin, Observable } from 'rxjs';
import { UserModel } from '../models/user.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
  username: string;
  userfullname: string
  change_password_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string = '';
  successMsg: string;
  showSuccessMsg: boolean = false;
  msg: string;
  request: IdentityRequestModel
  hideOPwd: boolean = true;
  hidePwd: boolean = true;
  hideCPwd: boolean = true;
  showOPwdEye: boolean = false;
  showPwdEye: boolean = false;
  showCPwdEye: boolean = false;
  checkPastPasswordNumber: string = "";
  minPwdLength: number = 0;
  passwordValid: boolean = false;
  user: UserModel;
  isReset: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService,
    private readonly _authService: AuthenticateService, private _router: Router, private _route: ActivatedRoute,
    @Optional() private readonly _dialogRef: MatDialogRef<ChangePasswordComponent>) {
    //this.change_password_form = new FormGroup({
    //  old_password: new FormControl(),
    //  password: new FormControl(),
    //  confirm_password: new FormControl()

    //})
  }

  getAllData(): Observable<any[]> {
    let getMinPwdLengthResponse = this._identityService.getMinPasswordLength<ResponseModel<number>>();
    
    return forkJoin([getMinPwdLengthResponse]);
  }

  ngOnInit() {
    this.user = new UserModel();
    this._authService.updateAccessTime();
    this._route.queryParams.subscribe(params => {
      this.isReset = params["reset"];
    });

    this._identityService.getCheckPastPasswordNumber<ResponseModel<number>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.checkPastPasswordNumber = response.model.toString();      
      }
    })

    this.errMsg = "";
    this.msg = "";
    this.userfullname = localStorage.getItem('fn') + ' ' + localStorage.getItem('ln'); //localStorage.getItem('un');

    //
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.minPwdLength = responses[0].model;        
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }     

      this.initControls();

    });
    // 
    //this.change_password_form = this.formBuilder.group({
    //  old_password: [null, Validators.required],
    //  password: [null, Validators.compose([
    //    Validators.required,
    //    Validators.minLength(12),
    //    Validators.maxLength(50),
    //    Validators.pattern("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()_\\-+={}|;:,.?/'\"\\\\\\[\\]])([a-zA-Z0-9~`!@#$%^&*()_\\-+={}|;:,.?/'\"\\\\\\[\\]]{8,50})$")
    //  ]
    //  )],
    //  confirm_password: [null, [Validators.required]],
      
    //}
    //);
    
  }

  initControls() {
    this.change_password_form = this.formBuilder.group({
      old_password: [null, Validators.required],
      password: [null, Validators.compose([
        Validators.required,
        Validators.minLength(this.minPwdLength),
        Validators.maxLength(50),
        Validators.pattern("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()_\\-+={}|;:,.?/'\"\\\\\\[\\]])([a-zA-Z0-9~`!@#$%^&*()_\\-+={}|;:,.?/'\"\\\\\\[\\]]{8,50})$")
      ]
      )],
      confirm_password: [null, [Validators.required]],

    });
  }

  get f() { return this.change_password_form.controls; }

  onKeyup(event, controlflag: number) {
    this.errMsg = "";
    if (event.target.value != "") {
      switch (controlflag) {
        case 1:
          this.showOPwdEye = true;
          break;
        case 2:
          this.showPwdEye = true;
          break;
        case 3:
          this.showCPwdEye = true;
          break;
      }
    }
    else {
      switch (controlflag) {
        case 1:
          this.showOPwdEye = false;
          break;
        case 2:
          this.showPwdEye =false;
          break;
        case 3:
          this.showCPwdEye = false;
          break;
      }
    }
  }

  validatePassword() {
    this.user.password = this.f.old_password.value;
    this.user.username = localStorage.getItem('un');

    this._identityService.validatePassword<ResponseModel<boolean>>(this.user).subscribe(response => {
      this.passwordValid = response.model;

      if(!this.passwordValid) {
        this.errMsg = "Current password is incorrect.";
        return;
      }
      else {
        this._identityService.updateUser<ResponseModel<UserModel>>(this.user).subscribe(response => {
          if (response != null && response.code == "200") {
            this.showSuccessMsg = true;
            this.successMsg = "Personal info successfully updated."
            setTimeout(() => {this._dialogRef.close()}, 2000);
          }
          else {
            this.errMsg = "Error: " + response.message;
          }
        })
      }
    })
  }

  onSave() {
    this.errMsg = "";
    this.submitted = true;
    if (this.f.password.value != "" && this.f.password.value != null && this.f.confirm_password.value != "" && this.f.confirm_password.value != null) {
      let p = CustomValidators.textMatchValidator(this.f.password.value, this.f.confirm_password.value);
      if (this.change_password_form.invalid || p == false) {
        if (p == false) {
          this.f.confirm_password.setErrors({ passwordMatchValidator: !p });
        }
        else {
          this.f.confirm_password.setErrors(null);
        }
        return;
      }
    }

    if (this.f.old_password.value == this.f.password.value) {
      this.errMsg = "New password cannot be the same as previous " + this.checkPastPasswordNumber + " passwords.";
      return;
    }

    this.request = new IdentityRequestModel();
    //this.request.username = this.username;
    this.request.oldPassword = this.f.old_password.value;
    this.request.newPassword = this.f.password.value;
    this.username = localStorage.getItem('un');
    this.loading = true;
    this._authService.updateAccessTime();
    this._identityService.changePassword<ResponseModel<boolean>>(this.request).subscribe(response => {
      if (response != null && response.code == "200") {       
        if (response.message != "") {
          if (response.model == true) {
            this.msg = response.message;
            let keepMeSignedIn: boolean
            if (localStorage.getItem('kmsi') == null)
              keepMeSignedIn = false;
            else
              keepMeSignedIn = Boolean(localStorage.getItem('kmsi'));
            // add
            this._authService.authenticate(this.username, this.f.password.value, keepMeSignedIn).subscribe(response => {
              if (response.code == "200") {
                this.showSuccessMsg = true;
                this.successMsg = "Successfully updated password.";
                setTimeout(() => {
                  if (this.isReset) {
                    switch (localStorage.getItem('ut')) {
                      case 'C':
                        this._router.navigate(['/current-events-client/current-events-dashboard']);
                        break;
                      case 'I':
                        this._router.navigate(['/current-events/current-events-dashboard']);
                        break;
                      default:
                        this._router.navigate(['/common-utility/blank']);
                        break;
                    }

                  }
                  else { 
                  this._dialogRef.close()
                }
                }, 1000);
              }
              else {
                if (response.message != "") {
                  this.errMsg = response.message;
                }
              }
              this.loading = false;
            });
          }
          else {
            this.errMsg = response.message;
          }
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to update password. Please try again later.";
        }
      }
      this.loading = false;
    })

  }
}
