<div style="background-color:#f8f8f8">
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>

    <h5 style="padding-left: 1em; padding-top: 0.25em; ">FLIGHT PLAN PREFERENCES</h5>
    <div style="display: flex; justify-content:space-between">
      <div style="display: flex;padding-left:1em; float:left; ">
        <div style="margin-right:1em">
          <div style="font-size: small">Registration</div>
          <div style="font-weight: bold; font-size: small">{{flightPlanPreference.registration}}</div>
          <div style="font-size: small;">{{flightPlanPreference.aircraftManufacturer}}
            {{flightPlanPreference.aircraftTypeDesignator}}</div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">Dept</div>
          <div style="font-size: small; font-weight:bold">
            <div>{{flightPlanPreference.departureICAO}}</div>
          </div>
          <div style="font-size: small; display: flex">
            <div>{{flightPlanPreference.departureDateTextUTC!=''?flightPlanPreference.departureDateTimeUTCDisplay+'
              Z':'TBA'}}</div>
            <div>{{flightPlanPreference.departureUTCTimeConversion}}</div>
          </div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">Arrv</div>
          <div style="font-size: small;  font-weight:bold">
            <div>{{flightPlanPreference.nextArrivalICAO}}</div>
          </div>
          <div style="font-size: small; display: flex">
            <div>{{flightPlanPreference.nextArrivalDateTextUTC!=''?flightPlanPreference.nextArrivalDateTimeUTCDisplay+'
              Z':'TBA'}}</div>
            <div>{{flightPlanPreference.nextArrivalUTCTimeConversion}}</div>
          </div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">Crew</div>
          <div style="font-size: small;  font-weight:bold">
            <div>{{flightPlanPreference.crewCount}}</div>
          </div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">PAX</div>
          <div style="font-size: small;  font-weight:bold">
            <div>{{flightPlanPreference.paxCount}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <form *ngIf="flight_plan_preference_edit_form" [formGroup]="flight_plan_preference_edit_form">

    <div mat-dialog-content style="background-color:white;font-size:small;margin:1em;padding:.5em;">
      <div class="d-flex">
        <div>
          <div class="form-inline">
            <label style="width: 6.55em; justify-content: end ">Cruise Speed</label>
            <input type="text" class="form-control form-control-sm inputBox mx-2" formControlName="cruise_speed"
              style="width:5em" />
            <mat-slide-toggle color="primary" style="transform:scale(.8)"
              formControlName="cruise_speed_CR"></mat-slide-toggle>
          </div>
          <div class="form-inline">
            <label style="width: 6.55em; justify-content: end ">Flight Level</label>
            <input type="text" class="form-control form-control-sm inputBox mx-2" formControlName="flight_level"
              style="width:5em" />
            <mat-slide-toggle color="primary" style="transform:scale(.8)"
              formControlName="flight_level_CR"></mat-slide-toggle>
          </div>
          <div class="form-inline">
            <label style="width: 6.55em; justify-content: end ">ALT</label>
            <input type="text" class="form-control form-control-sm inputBoxReadOnly mx-2" formControlName="alternate"
              readonly style="width:5em" />
            <mat-slide-toggle color="primary" style="transform:scale(.8)" formControlName="alt_CR"></mat-slide-toggle>
          </div>
        </div>
        <div>
          <div class="form-inline">
            <label style="width: 7em; justify-content: end ">Departure Fuel</label>
            <input type="text" class="form-control form-control-sm inputBox mx-2" formControlName="departure_fuel"
              style="width:5em" />
            <mat-slide-toggle color="primary" style="transform:scale(.8)"
              formControlName="departure_fuel_CR"></mat-slide-toggle>
          </div>
          <div class="form-inline">
            <label style="width: 7em; justify-content:end">FOD</label>
            <input type="text" class="form-control form-control-sm inputBox mx-2" formControlName="fod"
              style="width:5em" />
            <mat-slide-toggle color="primary" style="transform:scale(.8)" formControlName="fod_CR"></mat-slide-toggle>
          </div>
          <div class="form-inline">
            <label style="width: 7em; justify-content:end">FUEL @ ALT</label>
            <input type="text" class="form-control form-control-sm inputBox mx-2" formControlName="tank"
              style="width:5em" />
            <mat-slide-toggle color="primary" style="transform:scale(.8)"
              formControlName="tank_CR"></mat-slide-toggle>
          </div>
        </div>

        <div>
          <div>
            <div class="form-inline">
              <label style="width:5em; justify-content:end">HOLD</label>
              <input type="text" class="form-control form-control-sm inputBox mx-2" formControlName="hold"
                style="width:5em" />
              <mat-slide-toggle color="primary" style="transform:scale(.8)"
                formControlName="hold_CR"></mat-slide-toggle>
            </div>
            <div class="form-inline">
              <label style="width: 5em; justify-content:end">RES</label>
              <input type="text" class="form-control form-control-sm inputBox mx-2" formControlName="reserve"
                style="width:5em" />
              <mat-slide-toggle color="primary" style="transform:scale(.8)"
                formControlName="reserve_CR"></mat-slide-toggle>
            </div>
          </div>
          <!-- <div class="form-inline">
              <label style="width: 5em; justify-content:end">ETPS</label>
              <input type="text" class="form-control form-control-sm inputBoxReadOnly mx-2" formControlName="etps" readonly style="width:18em" />
              <mat-slide-toggle color="primary" style="transform:scale(.8)" formControlName="etps_CR"></mat-slide-toggle>
            </div> -->
        </div>
        <div>
          <div class="form-inline">
            <label style="width: 7em; justify-content: end ">PAX WT</label>
            <input type="text" class="form-control form-control-sm inputBox mx-2" formControlName="payload"
              style="width:5em" />
            <mat-slide-toggle color="primary" style="transform:scale(.8)"
              formControlName="payload_CR"></mat-slide-toggle>
          </div>
          <div class="form-inline">
            <label style="width: 7em; justify-content:end">CARGO</label>
            <input type="text" class="form-control form-control-sm inputBox mx-2" formControlName="cargo"
              style="width:5em" />
            <mat-slide-toggle color="primary" style="transform:scale(.8)" formControlName="cargo_CR"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <!-- <div class="form-inline mt-2">
        <label style="width: 6.55em; justify-content:end">Route</label>
        <textarea class="form-control form-control-sm inputBox mx-2" style="width:52em;height:4.65em" formControlName="flight_route"></textarea>
      </div> -->
      <div class="form-inline mt-2">
        <label style="width: 6.55em; justify-content:end">Remarks</label>
        <textarea class="form-control form-control-sm inputBox mx-2" style="width: 52em; height: 3em"
          formControlName="remarks"></textarea>
      </div>

    </div>

    <div style="padding-left:1em;padding-right:1em;padding-bottom:1em" class="d-flex justify-content-between">
      <div class="d-inline-flex">
        <div>
          <button [disabled]="loading" mat-button (click)="clickSave()" class="btn-high">Save</button>
          <button mat-button (click)="close(false)" class="btn-low ml-2">Close</button>
          <button *ngIf="flightPlanPreferenceID!=null" mat-button class="btn-low ml-2"
            (click)="clickAudit()">Audit</button>
        </div>
        <div *ngIf="showSuccessMsg" class="ml-2">
          <app-success-message [successMsg]=""></app-success-message>
        </div>
        <div *ngIf="errMsg!=''" class="ml-2 alert alert-danger small p-0" style="width:max-content">
          {{errMsg}}
        </div>
      </div>
      <div>
        <div class="form-inline" style="margin-top:-.5em;padding-bottom:.5em">
          <label style="font-size:small;width:8em">Modified By</label>
          <input type="text" readonly formControlName="modified_by"
            class="form-control form-control-sm inputBoxReadOnly" style="width:8em" />
        </div>
        <div class="form-inline">
          <label style="font-size:small;width:8em">Modified Date</label>
          <input type="text" readonly formControlName="modified_date"
            class="form-control form-control-sm inputBoxReadOnly" style="width:8em" />
        </div>
      </div>
    </div>
  </form>
</div>