export class FuelClientMarkupModel {
  fuelClientMarkupGUID: string;
  customerID: number;
  clientid: number;
  client: string;
  country: string;
  countryID: number;
  icao: string;
  airportID: number;
  iata: string;
  markupAmt: number;  
  effectiveDate: string;
  expiryDate: string;
  internalNotes: string;
  isActive: boolean;
  isExpired: boolean;
  modifiedBy: string;
  modifiedDate: string;
   
  customerName: string;
  countryName: string;
  

  //effectiveDate: string;
  expirationDate: string;
  selectedICAOList: string;
  selectedClientGUIDList: string;
  selectedCountryIDList: string;
  selectedFuelSupplierList: string; 


  //countryName: string;
 // airportName: string;
  fuelSuppliershortName: string;
  unitOfMeasure: string;
  currencyDescription: string;
  currencyID: string;


  pageIndex: number;
  pageSize: number;
  totalRecords: number;

  selected: boolean;
}
