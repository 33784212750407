import { MatLegacyPaginatorIntl as MatPaginatorIntl, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';

export function CustomPaginator(s: string) {
  const customPaginatorIntl = new MatPaginatorIntl();

  if (s == "")
    customPaginatorIntl.itemsPerPageLabel = 'Records per page:';
  else {
    customPaginatorIntl.itemsPerPageLabel =s+':';
  }
  customPaginatorIntl.firstPageLabel = "";
  customPaginatorIntl.lastPageLabel = "";
  customPaginatorIntl.previousPageLabel = "";
  customPaginatorIntl.nextPageLabel = "";
  return customPaginatorIntl;
}
