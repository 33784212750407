import { Component, ElementRef, Inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { CustomValidators } from '../common-utility/custom.validators';
import { AircraftModel } from '../models/aircraft.model';
import { AirportModel } from '../models/airport.model';
import { BusinessRulesApprovalStatusModel } from '../models/business-rules-approval-status.model';
import { BusinessRulesServiceStatusModel } from '../models/business-rules-service-status.model';
import { BusinessRulesModel } from '../models/business-rules.model';
import { CountryModel } from '../models/country.model';
import { CustomerModel } from '../models/customer.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { FARTypeModel } from '../models/far-type.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { HotelModel } from '../models/hotel.model';
import { PersonModel } from '../models/person.model';
import { ResponseModel } from '../models/response.model';
import { ServiceClassModel } from '../models/service-class.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { TripLegTypeModel } from '../models/trip-leg-type.model';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { VendorModel } from '../models/vendor.model';
import { AirportService } from '../services/airport.service';
import { AuthenticateService } from '../services/authenticate.service';
import { BusinessRulesService } from '../services/business-rules-service';
import { CommonService } from '../services/common.service';
import { CustomerService } from '../services/customer.service';
import { DialogService } from '../services/dialog.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopService } from '../services/ground-stop.service';
import { PersonService } from '../services/person.service';
import { VendorService } from '../services/vendor.service';

@Component({
  selector: 'app-business-rules-edit',
  templateUrl: './business-rules-edit.component.html',
  styleUrls: ['./business-rules-edit.component.css']
})
/** business-rules-edit component*/
export class BusinessRulesEditComponent implements OnInit {
  /** business-rules-edit ctor */
  business_rules_edit_form: UntypedFormGroup;
  customerList: CustomerModel[];
  countryList: CountryModel[];
  permitCountryList: CountryModel[];
  serviceClassList: ServiceClassModel[]=[];
  serviceTypeList: ServiceTypeModel[];
  allServiceTypeList: ServiceTypeModel[];
  aircraftList: AircraftModel[] = [];
  crewList: PersonModel[] = [];
  paxList: PersonModel[] = [];
  farTypeList: FARTypeModel[];
  customerGUID: string = "";
  aircraftGUID: string = "";
  vendorList: VendorModel[];
  tabPosition: number = 0;
  legTypeList: TripLegTypeModel[];
  @Input() businessRulesID: number;
  customerID: number;
  farTypeID: number=0;
  legTypeID: number=0;
  serviceClassID: number=0;
  serviceTypeID: number=0;
  businessRules_ServiceStatusID: number=0;
  vendorGUID: string = "";
  fuelSupplierName: string;
  taskStatusGUID: string = "144b6b7d-94cb-44ff-bcf4-4006001c5ab6";
  serviceDueDateTime: number;
  businessRules_ApprovalStatusID: number=2;
  customerOnboardRule: boolean=false;
  customerApprovalRequired: boolean = false;
  aircraftGUIDList: AircraftModel[] = [];
  countryGUID: string = "";
  permitCountryGUID: string = "";
  picGuidList: PersonModel[] = [];
  paxGuidList: PersonModel[] = [];
  picGUID: string;
  paxGUID: string;
  isUTC: boolean=false;
  effectiveDate: Date;
  expirationDate: Date;
  hoursStart: string = "";
  hoursEnd: string = "";
  mtowMin: number=0;
  mtowMax: number=0;
  wingSpanMin: number=0;
  wingSpanMax: number=0;
  sun: boolean = false;
  mon: boolean = false;
  tue: boolean = false;
  wed: boolean = false;
  thu: boolean = false;
  fri: boolean = false;
  sat: boolean = false;
  airportList: AirportModel[];
  airportID: number=0;
  showSuccessMsg: boolean = false;
  @ViewChild('effectiveDate') effectiveDateRef: ElementRef;
  @ViewChild('expirationDate') expirationDateRef: ElementRef;
  icao: string;
  dueDate: string="";
  leadTime: string="";
  dueDateMin: number=0;
  leadTimeMin: number=0;
  dueDateChanged: boolean = false;
  leadTimeChanged: boolean = false;
  businessRulesApprovalStatusList: BusinessRulesApprovalStatusModel[];
  businessRulesServiceStatusList: BusinessRulesServiceStatusModel[];
  taskStatusList: TripTaskStatusModel[];
  submitted: boolean = false;
  isClientSetup: boolean = false;
  isNotRequired: boolean = false;
  remarks: string="";
  modifiedBy: string;
  modifiedDate: string;
  airportErrMsg: string;
  title: string;
  checkAllDays: boolean = false;
  checkWeekdays: boolean = false;
  showSpin: boolean = false;
  avoidLocation: boolean = false;
  errMsg: string = "";
  readonly: boolean = false;
  brData: BusinessRulesModel;
  similarRules: BusinessRulesModel[] = [];
  hotelList: HotelModel[] = [];
  relatedVendorList: VendorModel[] = [];
  excludeFromHomebase: boolean = false;
  immediateActionRequired: boolean = false;
  intoPlaneAgentList: VendorModel[];
  displayedColumn: string[] = ['brID', 'customerName', 'registration', 'far_type', 'country', 'icao', 'service_class', 'service_type', 'vendorName']; 
  selectedCountryGUID: string = null;  

  constructor(private readonly _dialogRef: MatDialogRef<BusinessRulesEditComponent>, private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _customerService: CustomerService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _groundStopService: GroundStopService,
    private readonly _personService: PersonService, private readonly _dialogService: DialogService, private readonly _vendorService: VendorService,
    private readonly _businessRulesService: BusinessRulesService,  @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _airportService:AirportService,) {
    this.businessRulesID = _data.businessRulesID;
    this.title = _data.title;
    if (_data.readonly != undefined)
      this.readonly = _data.readonly;
    this.brData = _data.brData;
    if (_data.selectedCountryGUID != undefined)
      this.selectedCountryGUID = _data.selectedCountryGUID;
 
  }
  ngOnInit() {
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.countryList = responses[1].model;
          let country = responses[1].model.slice();         
          let c = new CountryModel();
          c.countryGUID = '00000000-0000-0000-0000-000000000000';
          c.countryName = 'Not Required';
          country.push(c);
          this.permitCountryList = country;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.serviceTypeList = responses[2].model;
          let y: ServiceClassModel;
          let tempClassID = -1;
          this.serviceTypeList.forEach((x) => {
            if (tempClassID != x.serviceClassID) {
              tempClassID = x.serviceClassID;
              y = new ServiceClassModel();
              y.serviceClassID = x.serviceClassID;
              y.serviceClassDescription = x.serviceClassDescription;
              y.serviceTypeList = this.serviceTypeList.filter(a => a.serviceClassID == tempClassID);
              y.serviceTypeList.forEach(x => x.quantity = 1);
              this.serviceClassList.push(y);
            }
            this.allServiceTypeList = this.serviceTypeList;

          });
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.farTypeList = responses[3].model;
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          this.legTypeList = responses[4].model;
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          let br = new BusinessRulesModel();
          br = responses[5].model;
          this.customerGUID = br.customerGUID;
          this.aircraftGUID = br.aircraftGUIDList;
          this.farTypeID = br.farTypeID;
          this.legTypeID = br.legTypeID;
          this.countryGUID = br.countryGUID;
          this.permitCountryGUID = br.permitCountryGUID;
          this.picGUID = br.picGuidList;
          this.paxGUID = br.paxGuidList;
          this.isUTC = br.isUTC;
          if (br.effectiveDate != null && br.effectiveDate != "")
            this.effectiveDate = new Date(br.effectiveDate);
          if (br.expirationDate != null && br.expirationDate != "")
            this.expirationDate = new Date(br.expirationDate);         
          this.hoursStart = br.hoursStart;
          this.hoursEnd = br.hoursEnd;
          this.icao = br.icao;
          this.serviceClassID = br.serviceClassID;
          this.serviceTypeID = br.serviceTypeID;
          this.businessRules_ServiceStatusID = br.businessRules_ServiceStatusID;
          this.vendorGUID = br.vendorGUID;
          this.leadTimeMin = br.leadTime;
          this.leadTime = br.leadTimeValue;
          this.serviceDueDateTime = br.serviceDueDateTime;
          this.dueDate = br.dueDateValue;
          this.businessRules_ApprovalStatusID = br.businessRules_ApprovalStatusID;
          this.customerOnboardRule = br.customerOnboardRule;
          this.customerApprovalRequired = br.customerApprovalRequired;
          this.mtowMin = br.mtowMin;
          this.mtowMax = br.mtowMax;
          this.wingSpanMin = br.wingSpanMin;
          this.wingSpanMax = br.wingSpanMax;
          this.sun = br.sun;
          this.mon = br.mon;
          this.tue = br.tue;
          this.wed = br.wed;
          this.thu = br.thu;
          this.fri = br.fri;
          this.sat = br.sat;
          this.airportID = br.airportID;
          this.taskStatusGUID = br.taskStatusGUID;
          this.avoidLocation = br.avoidLocation;
          this.remarks = br.remarks;
          this.modifiedBy = br.modifiedBy;
          this.modifiedDate = br.modifiedDate;
          this.excludeFromHomebase = br.excludeFromHomebase;
          this.immediateActionRequired = br.immediateActionRequired;
          this.fuelSupplierName = br.vendorName;
          if (this.customerGUID != null && this.customerGUID!=undefined)
            this.updateDropDowns(this.customerGUID);
          if (this.serviceTypeID != null && this.serviceTypeID != 0)
            this.getVendorList(this.serviceTypeID,null);
          if (this.serviceTypeID == 56)
            this.getIntoPlaneAgentList();

        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[6] != null) {
        if (responses[6].code == "200" && responses[6].message == "") {
          this.businessRulesApprovalStatusList = responses[6].model;
        }
        else {
          if (responses[6].code == "401") {
            this._authService.signOut();
          }
        }
      }
      //if (responses[7] != null) {
      //  if (responses[7].code == "200" && responses[7].message == "") {
      //    this.businessRulesServiceStatusList = responses[7].model;
      //  }
      //  else {
      //    if (responses[7].code == "401") {
      //      this._authService.signOut();
      //    }
      //  }
      //}
      if (responses[7] != null) {
        if (responses[7].code == "200" && responses[7].message == "") {
          this.taskStatusList = responses[7].model.filter(x => x.isBusinessRuleStatus == true);
        }
        else {
          if (responses[7].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (this.brData != undefined) {
        this.customerGUID = this.brData.customerGUID;
        this.aircraftGUID = this.brData.aircraftGUIDList;
        this.serviceTypeID = this.brData.serviceTypeID;
        this.effectiveDate = new Date();
        this.icao = this.brData.icao;
        if (this.icao != "")
          this.validateAirport(this.icao);
        this.countryGUID = this.brData.countryGUID;
        this.permitCountryGUID = this.brData.permitCountryGUID;
        this.vendorGUID = this.brData.vendorGUID;
        if (this.customerGUID != null)
          this.updateDropDowns(this.customerGUID);
        if (this.serviceTypeID != null && this.serviceTypeID != 0)
          this.getVendorList(this.serviceTypeID,null);
        if (this.taskStatusList.some(v => v.taskStatusGUID == this.brData.taskStatusGUID))
          this.taskStatusGUID = this.brData.taskStatusGUID;
        if (this.brData.taskStatusGUID == '73abb6e5-ef14-4c01-b390-089f658a819f')
          this.customerOnboardRule = true;
        
      }
      let x = new PersonModel();
      x.personGUID = "";
      x.personName = "All";
      this.crewList.push(x);
      this.paxList.push(x);
      this.initControls();
      this.showSpin = false;
      if(this.selectedCountryGUID != null && this.selectedCountryGUID != undefined ){
        // this.countryGUID = this.selectedCountryGUID;
        this.setCountry();
  
      }
    });
  }

  get f() { return this.business_rules_edit_form.controls; }

  dateOnchange(dateType: string, e: any) {
    if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {         
          this.f.effectiveDate.setErrors({ isValidDate: false });
        }        
      }      
    }
    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {          
          this.f.expirationDate.setErrors({ isValidDate: false })
        }
      }
    }

    if (this.f.effectiveDate.value != null && this.f.expirationDate.value != null)
      if (this.f.effectiveDate.value > this.f.expirationDate.value)
        this.f.expirationDate.setErrors({ matEndDateInvalid: true });

  }
  nextStep() {
    this.tabPosition += 1;
  }

  previousStep() {
    this.tabPosition -= 1;
  }
  initControls() {
    
    this.business_rules_edit_form = this._formBuilder.group({
      effectiveDate: [{ value: this.effectiveDate, disabled: this.readonly }, Validators.required],
      expirationDate: [{ value: this.expirationDate, disabled: this.readonly }],
      hoursStart: [{ value: this.hoursStart, disabled: this.readonly }],
      hoursEnd: [{ value: this.hoursEnd, disabled: this.readonly }],
      isUTC: [{
        value: this.isUTC, disabled: this.readonly
      }],
      customer_select: [{
        value: this.customerGUID, disabled: this.readonly
      }],
      aircraft_select: [{
        value: this.aircraftGUIDList, disabled: this.readonly
      }],
      crew_select: [{
        value: this.picGuidList, disabled: this.readonly
      }],
      pax_select: [{
        value: this.paxGuidList, disabled: this.readonly
      }],
      clientSetup: [{
        value: this.isClientSetup, disabled: this.readonly
      }],
      notRequired: [{
        value: this.isNotRequired, disabled: this.readonly
      }],
      country_select: [{
        value: this.countryGUID, disabled: this.readonly
      }],
      permit_country_select: [{
        value: this.permitCountryGUID, disabled: this.readonly
      }],
      icao: [{
        value: this.icao, disabled: this.readonly
      }],
      far_select: [{
        value: this.farTypeID, disabled: this.readonly
      }],
      leg_type_select: [{
        value: this.legTypeID, disabled: this.readonly
      }],
      mtow_min: [{
        value: this.mtowMin, disabled: this.readonly
      }],
      mtow_max: [{
        value: this.mtowMax, disabled: this.readonly
      }],
      wing_span_min: [{
        value: this.wingSpanMin, disabled: this.readonly
      }],
      wing_span_max: [{
        value: this.wingSpanMax, disabled: this.readonly
      }],
      sun: [{
        value: this.sun, disabled: this.readonly
      }],
      mon: [{
        value: this.mon, disabled: this.readonly
      }],
      tue: [{
        value: this.tue, disabled: this.readonly
      }],
      wed: [{
        value: this.wed, disabled: this.readonly
      }],
      thu: [{
        value: this.thu, disabled: this.readonly
      }],
      fri: [{
        value: this.fri, disabled: this.readonly
      }],
      sat: [{
        value: this.sat, disabled: this.readonly
      }],
      serviceClassID: [{
        value: this.serviceClassID, disabled: this.readonly
      }],
      serviceTypeID: [{
        value: this.serviceTypeID, disabled: this.readonly
      }],
      vendorID: [{
        value: this.vendorGUID, disabled: this.readonly
      }],
      hotelID: [{
        value: this.vendorGUID, disabled: this.readonly
      }],
      leadTime: [{ value: this.leadTime, disabled: this.readonly }, Validators.pattern("^(([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?)$")],
      dueDate: [{ value: this.dueDate, disabled: this.readonly }, Validators.pattern("^([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?$")],
      br_status: [{
        value: this.businessRules_ApprovalStatusID, disabled: this.readonly
      }],
      serviceStatus: [{
        value: this.businessRules_ServiceStatusID, disabled: this.readonly
      }],
      customerOnboardRule: [{
        value: this.customerOnboardRule, disabled: this.readonly
      }],
      customerApprovalRequired: [{
        value: this.customerApprovalRequired, disabled: this.readonly
      }],
      remarks: [{
        value: this.remarks, disabled: this.readonly
      }],
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate,
      taskStatus: [{
        value: this.taskStatusGUID, disabled: this.readonly
      }],
      avoidLocation: [{
        value: this.avoidLocation, disabled: this.readonly
      }],
      excludeFromHomebase: [{
        value: this.excludeFromHomebase, disabled: this.readonly
      }],
       immediateActionRequired: [{
         value: this.immediateActionRequired, disabled: this.readonly
      }],
      fuel_supplier_select: [{ value: this.fuelSupplierName, disabled: this.readonly }],
      intoplane_agent_select: [{ value: this.vendorGUID, disabled: this.readonly }],
    });

  }

  getAllData(): Observable<any[]> {
    let getCustomerResponse = this._commonService.getCustomers();
    let getCountryResponse = this._commonService.getCountryList();
    let getServiceTypeResponse = this._groundStopAdvancedService.getServiceClass_ServiceTypeList(0);
    let getFARTypeResponse = this._groundStopService.getFARTypeList();
    let getTripLegTypeResponse = this._businessRulesService.getTripLegTypeList();
    let getBusinessRulesResponse;
    if (this.businessRulesID != 0)
      getBusinessRulesResponse = this._businessRulesService.getBusinessRulesBybrId(this.businessRulesID);
    else
      getBusinessRulesResponse = of(null);
    let getBusinessRulesApprovalStatusResponse = this._businessRulesService.getBusinessRulesApprovalStatusList();
   // let getBusinessRulesServiceStatusResponse = this._businessRulesService.getBusinessRulesServiceStatusList();
    let getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    return forkJoin([getCustomerResponse, getCountryResponse, getServiceTypeResponse, getFARTypeResponse, getTripLegTypeResponse,
      getBusinessRulesResponse, getBusinessRulesApprovalStatusResponse, getTaskStatusResponse]);
  }

  

  updateDropDowns(customerGUID:string) {
    //let customerGUID = this.f.customer_select.value;
    this._customerService.getRegistrationListByCustomerGUID<ResponseModel<AircraftModel[]>>(customerGUID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.aircraftList = response.model;
          if (this.aircraftGUID != '') {
            this.aircraftList.forEach(x => {
              if (this.aircraftGUID.indexOf(x.aircraftGuid) > -1)
                x.selected = true;
            });
            this.setRegistrationList();
            this.f.aircraft_select.setValue(this.aircraftGUIDList);
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });

    let person = new PersonModel();
    person.customerGUID = customerGUID;
    person.textSearch = "";
    person.filterBy = "";
    person.isActive = true;
    person.selectedPersonClassGUID = 'F82AA48E-31B7-4667-9FAA-24204DC4A65B'
    this._personService.getCustomerPerson<ResponseModel<PersonModel[]>>(person).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.crewList = response.model.filter(v=>v.personRoleList.includes("Pilot"));
          if (this.picGUID != '') {
            this.crewList.forEach(x => {
              if (this.picGUID.indexOf(x.personGUID) > -1 && x.personGUID!="")
                x.selected = true;
            });
            this.setCrewList();
            this.f.crew_select.setValue(this.picGuidList);
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
    person.selectedPersonClassGUID = '72433A83-D1FF-4391-B92C-564A817C5438'
    this._personService.getCustomerPerson<ResponseModel<PersonModel[]>>(person).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.paxList = response.model;
          if (this.paxGUID != '') {
            this.paxList.forEach(x => {
              if (this.paxGUID.indexOf(x.personGUID) > -1 && x.personGUID != "")
                x.selected = true;
            });
            this.setPaxList();
            this.f.pax_select.setValue(this.paxGuidList);
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }

  getVendorList(serviceTypeID: number, countryID: string) {
    if (this.business_rules_edit_form) {
      if (this.f.serviceClassID.value == 0) {
        this.f.serviceClassID.setValue(this.serviceTypeList.find(x => x.serviceTypeID == serviceTypeID).serviceClassID)
      }
    }
    this.vendorList = [];
    this.hotelList = [];
    let request = new GroundStopModel();
    request.icao = this.icao;
    request.serviceTypeDescription = this.serviceTypeList.find(x => x.serviceTypeID == serviceTypeID).serviceTypeDescription;
    request.serviceTypeIDList = serviceTypeID.toString();
    
    if (countryID==null)
      request.countryGUID = this.countryGUID;
    else
      request.countryGUID = countryID;

    switch (serviceTypeID) {

      case 3:
      case 5:
      case 26:
      case 88:
        if ((serviceTypeID == 3 || serviceTypeID == 88) && countryID != null) {
          if (serviceTypeID == 88 && (this.f.permit_country_select.value == null || this.f.permit_country_select.value == '')) {
            this.f.permit_country_select.setValue('75e3bc3f-3940-4194-94f8-09daa3f7bbb4');
            request.countryGUID = '75e3bc3f-3940-4194-94f8-09daa3f7bbb4';
          }
          if (countryID == null)
            request.countryGUID = this.permitCountryGUID;
        }
        this._groundStopAdvancedService.getVendorListForPermitByAirportCountry<ResponseModel<VendorModel[]>>(request).subscribe(response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              this.vendorList = response.model;
            }
            else {
              if (response.code == "401") {
                this._authService.signOut();
              }
            }
          }
        });
        break;
      case 54:
      case 55:
      case 98:
      case 73:
        this._groundStopAdvancedService.getVendorListForCustomsByAirport<ResponseModel<VendorModel[]>>(request).subscribe(response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              this.vendorList = response.model;
            }
            else {
              if (response.code == "401") {
                this._authService.signOut();
              }
            }
          }
        });
        break;
      case 56:
        this._groundStopAdvancedService.getFuelSupplierListByAirport<ResponseModel<VendorModel[]>>(request).subscribe(response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              this.vendorList = response.model;
            }
            else {
              if (response.code == "401") {
                this._authService.signOut();
              }
            }
          }
        });
        break;
      case 7:
      case 20:
        let req = new HotelModel();
        req.icao = this.icao;
        req.distanceFromAirport = 50;
        this._commonService.getHotelListByAirport<ResponseModel<HotelModel[]>>(req).subscribe(response => {
          if (response != null) {
            if (response.code == "200" && response.message == "") {
              this.hotelList = response.model;
            }
            else {
              if (response.code == "401") {
                this._authService.signOut();
              }
            }
          }
        });
        break;
      default:
        if (this.icao == null)
          request.icao = "XXXX";
        
        if (this.serviceTypeList.find(x => x.serviceTypeID == serviceTypeID).vendorRequired) {
          this._groundStopAdvancedService.getFBOListByAirportServiceType<ResponseModel<VendorModel[]>>(request).subscribe(response => {
            if (response != null) {
              if (response.code == "200" && response.message == "") {
                this.vendorList = response.model;
              }
              else {
                if (response.code == "401") {
                  this._authService.signOut();
                }
              }
            }
          });
        }
        break;
    }    
  }

  onRegistrationChange(e: any, item: AircraftModel) {
    this.aircraftList.forEach(x => {
      if (x.aircraftGuid.toLowerCase() == e.source.value.aircraftGuid.toLowerCase()) {
        x.selected = e.source.selected;
      }

    });
    this.setRegistrationList();
  }

  setRegistrationList() {
    this.aircraftGUIDList = this.aircraftList.filter(u => u.selected == true);
  }


  onCrewChange(e: any, item: PersonModel) {
    this.crewList.forEach(x => {
      if (x.personGUID.toLowerCase() == e.source.value.personGUID.toLowerCase()) {
        x.selected = e.source.selected;
      }

    });
    this.setCrewList();
  }

  setCrewList() {
    this.picGuidList = this.crewList.filter(u => u.selected == true);
  }


  onPaxChange(e: any, item: AircraftModel) {
    this.paxList.forEach(x => {
      if (x.personGUID.toLowerCase() == e.source.value.personGUID.toLowerCase()) {
        x.selected = e.source.selected;
      }

    });
    this.setPaxList();
  }

  setPaxList() {
    this.paxGuidList = this.paxList.filter(u => u.selected == true);
  }

  clickSave() {
    this.submitted = true;
    this.errMsg = "";
    if (this.effectiveDateRef.nativeElement.value !== "") {
      if (this.f.effectiveDate.value != "" && this.f.effectiveDate.value != null && this.f.effectiveDate.value != undefined) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.effectiveDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.effectiveDate.setErrors({ isValidDate: f1 });
        }
      }
      else {
        this.f.effectiveDate.setErrors({ isValidDate: false });
      }
    }

    if (this.expirationDateRef.nativeElement.value !== "") {
      if (this.f.expirationDate.value != "" && this.f.expirationDate.value != null && this.f.expirationDate.value != undefined) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.expirationDate.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.expirationDate.setErrors({ isValidDate: f1 });
        }
      }
      else {
        this.f.expirationDate.setErrors({ isValidDate: false });
      }
    }

    if (this.f.effectiveDate.value != null && this.f.expirationDate.value != null)
      if (this.f.effectiveDate.value > this.f.expirationDate.value)
        this.f.expirationDate.setErrors({ matEndDateInvalid: true });

    if (this.f.dueDate.value != null && this.f.dueDate.value != '' && (this.dueDateMin == null || this.dueDateMin==0))
      this.calculateLeadTime_DueDate('d', this.f.dueDate.value);

    if (this.f.leadTime.value != null && this.f.leadTime.value != '' && (this.leadTimeMin == null || this.leadTimeMin==0))
      this.calculateLeadTime_DueDate('l', this.f.leadTime.value);

    if (this.business_rules_edit_form.invalid)
      return;
    let request = new BusinessRulesModel();
    if (this.businessRulesID != undefined && this.businessRulesID != null)
      request.businessRulesID = this.businessRulesID;
    request.customerGUID = this.f.customer_select.value;
    request.aircraftGUIDList = Array.prototype.map.call(this.aircraftGUIDList, s => s.aircraftGuid).toString();
    request.picGuidList = Array.prototype.map.call(this.picGuidList, s => s.personGUID).toString();
    request.paxGuidList = Array.prototype.map.call(this.paxGuidList, s => s.personGUID).toString();
    request.mtowMin = Number(this.f.mtow_min.value);
    request.mtowMax = Number(this.f.mtow_max.value);
    request.wingSpanMin = Number(this.f.wing_span_min.value);
    request.wingSpanMax = Number(this.f.wing_span_max.value);
    request.farTypeID = Number(this.f.far_select.value);
    request.countryGUID = this.f.country_select.value;
    request.permitCountryGUID = this.f.permit_country_select.value;
    request.airportID = this.airportID;
    request.isActive = true;
    request.hoursStart = this.f.hoursStart.value;
    request.hoursEnd = this.f.hoursEnd.value;
    request.isUTC = this.f.isUTC.value;
    request.legTypeID = Number(this.f.leg_type_select.value);
    request.effectiveDate = null;
    if (this.f.effectiveDate.value != null) {
      request.effectiveDate = this.effectiveDateRef.nativeElement.value;
    }
    request.expirationDate = null;
    if (this.f.expirationDate.value != null) {
      request.expirationDate = this.expirationDateRef.nativeElement.value;
    }
    request.sun = this.f.sun.value;
    request.mon = this.f.mon.value;
    request.tue = this.f.tue.value;
    request.wed = this.f.wed.value;
    request.thu = this.f.thu.value;
    request.fri = this.f.fri.value;
    request.sat = this.f.sat.value;
    request.serviceClassID = Number(this.f.serviceClassID.value);
    request.serviceTypeID = Number(this.f.serviceTypeID.value);
    if (this.f.serviceTypeID.value == '7' || this.f.serviceTypeID.value == '20')
      request.vendorGUID = this.f.hotelID.value;
    else {
      if (this.f.serviceTypeID.value == '56')
        request.vendorGUID = this.f.intoplane_agent_select.value;
        else
      request.vendorGUID = this.f.vendorID.value;
    }
    request.businessRules_ApprovalStatusID = this.f.br_status.value;
    request.leadTime = this.leadTimeMin;
    request.serviceDueDateTime = this.dueDateMin;
    request.leadTimeValue = this.f.leadTime.value;
    request.dueDateValue = this.f.dueDate.value;   
    request.taskStatusGUID = this.f.taskStatus.value;    
    request.businessRules_ServiceStatusID = this.f.serviceStatus.value;
    request.customerOnboardRule = this.f.customerOnboardRule.value;
    request.customerApprovalRequired = this.f.customerApprovalRequired.value;
    request.remarks = this.f.remarks.value;
    request.avoidLocation = this.f.avoidLocation.value;
    request.excludeFromHomebase = this.f.excludeFromHomebase.value;
    request.immediateActionRequired = this.f.immediateActionRequired.value;
    this._businessRulesService.saveBusinessRules<ResponseModel<number>>(request).subscribe(response => {
      if (response.code == "200" && response.message == "") {
        if (response.model < 1) {
          this.errMsg = "Duplicate business rule. BR ID " + Math.abs(response.model).toString() + ".";
        }
        else {
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this._dialogRef.close(response.model);
          }, 1000);
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else
          this.errMsg = "An error has occurred.";
      }
    });
  }

  onTabGroupClick(e:any) {
    switch (e.target.innerText) {
      case "Business Rule":
        this.tabPosition = 0;
        break;
      case "Period":
        this.tabPosition = 1;
        break;
      case "Area of Interest":
        this.tabPosition = 2;
        break;
      case "Services Task & Vendors":
        this.tabPosition = 3;
        break;
    }
  }

  timeOnchange(controlName: string, e: any) {

    if (e.target.value != "") {
      let timeObj = new DateTimeObjModel();
      timeObj.timeString = e.target.value;
      timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
      this.business_rules_edit_form.get(controlName).setValue(timeObj.timeString);

      if (!timeObj.isValidDateTime) {
        this.business_rules_edit_form.get(controlName).setErrors({ 'valid': false });
      }

    }
    else {
      this.business_rules_edit_form.get(controlName).setValue(null);
      this.business_rules_edit_form.get(controlName).setErrors(null);
    }
  }

  validateAirport(e: any) {
    this.airportErrMsg = "";
    this.airportList = [];
    this.vendorList = [];
    let request = new AirportModel();
    if (e.target == undefined)
      request.route = e
    else {
      this.icao = "";
      request.route = e.target.value;
    }
    this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.airportList = response.model;
        if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
          this.airportList.forEach(x => {
            this.f.country_select.setValue(x.countryGUID);
            this.airportID = x.airportID;
            this.icao = x.icao;
            if (this.f.serviceTypeID.value != "")
              this.getVendorList(this.f.serviceTypeID.value,null);
          });
        }
        else {
          this.airportErrMsg = "Invalid airport";
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }
    });
  }

  leadTimeChange(e: any) {
    if (e.target.value != "") {
      this.leadTimeChanged = true;
      if (this.f.leadTime.errors) {
        return;
      }
      this.leadTimeChanged = false;
      this.calculateLeadTime_DueDate('l', e.target.value);
    }
    else {
      //this.f.lead_datetime.setValue('');
      this.leadTimeMin = 0;
    }
  }

  dueDateChange(e: any) {
    if (e.target.value != "") {
      this.dueDateChanged = true;
      if (this.f.dueDate.errors) {
        return;
      }
      this.dueDateChanged = false;
      this.calculateLeadTime_DueDate('d', e.target.value);
    }
    else {
      //this.f.due_datetime.setValue('');
      this.dueDateMin = 0;
    }
  }

  calculateLeadTime_DueDate(type: string, s: string) {
    if (type == 'l') {
      //this.f.lead_datetime.setValue('');
    }
    if (type == 'd') {
      //this.f.due_datetime.setValue('');
    }
    if (s != "") {
      let d = 0;
      s = s.toLowerCase();
      var i = -1;
      i = s.indexOf('d');
      if (i > 0) {
        let sd = s.substring(0, i);
        if (Number.isInteger(Number(sd))) {
          d = Number(sd);
          s = s.substring(i + 1);
        }
      }

      let h = 0;
      if (s != "") {
        i = s.indexOf('h');
        if (i > 0) {
          let sh = s.substring(0, i);
          if (Number.isInteger(Number(sh))) {
            h = Number(sh);
            s = s.substring(i + 1);
          }
        }
      }

      let mi = 0;
      if (s != "") {
        i = s.indexOf('m');
        if (i > 0) {
          let sm = s.substring(0, i);
          if (Number.isInteger(Number(sm))) {
            mi = Number(sm);
            s = s.substring(i + 1);
          }
        }
      }

      let totalmin = 0;
      if (d > 0) {
        totalmin += d * 24 * 60;
      }
      if (h > 0) {
        totalmin += h * 60;
      }
      if (mi > 0) {
        totalmin += mi;
      }
      if (type == 'l') {
        this.leadTimeMin = totalmin;
      }
      if (type == 'd') {
        this.dueDateMin = totalmin;
      }
      if (totalmin > 0) {
        if (type == 'l') {
          this.leadTimeMin = totalmin;
        }
        if (type == 'd') {
          this.dueDateMin = totalmin;
        }
        
      }

    }

  }

  setCountry() {
    if(this.selectedCountryGUID != null && this.selectedCountryGUID != undefined ){
      this.f.country_select.setValue(this.selectedCountryGUID)   ;

    }
    this.countryGUID = this.f.country_select.value;
    this.airportErrMsg = "";
    this.f.icao.setValue("");
    this.airportID = 0;
  }

  updateServiceTypeList() {
    if (this.f.serviceClassID.value == "0")
      this.serviceTypeList = this.allServiceTypeList;
    else
      this.serviceTypeList = this.allServiceTypeList.filter(x => x.serviceClassID == this.f.serviceClassID.value);
  }

  checkDays(type: string) {
    if (type == 'all') {
      if (this.checkAllDays) 
        this.checkWeekdays = false;
      this.f.sun.setValue(this.checkAllDays);
      this.f.mon.setValue(this.checkAllDays);
      this.f.tue.setValue(this.checkAllDays);
      this.f.wed.setValue(this.checkAllDays);
      this.f.thu.setValue(this.checkAllDays);
      this.f.fri.setValue(this.checkAllDays);
      this.f.sat.setValue(this.checkAllDays);      
    }
    if (type == 'weekdays') {
      this.checkAllDays = false;
      this.f.sun.setValue(this.checkAllDays);
      this.f.mon.setValue(this.checkWeekdays);
      this.f.tue.setValue(this.checkWeekdays);
      this.f.wed.setValue(this.checkWeekdays);
      this.f.thu.setValue(this.checkWeekdays);
      this.f.fri.setValue(this.checkWeekdays);
      this.f.sat.setValue(this.checkAllDays);
    }
  }

  updateStatus(control: string) {
    if (control == "setup" && this.f.clientSetup.value == true)
      this.f.notRequired.setValue(false);
    if (control == "required" && this.f.notRequired.value == true)
      this.f.clientSetup.setValue(false);
  }

  getSimilarBusinessRules(e: any) {
    if (e.index == 1) {
      let request = new BusinessRulesModel()
      if (this.businessRulesID != null && this.businessRulesID > 0)
        request.businessRulesID = this.businessRulesID;
      request.customerGUID = this.f.customer_select.value;
      request.farTypeID = Number(this.f.far_select.value);
      request.countryGUID = this.f.country_select.value;
      request.airportID = this.airportID;
      request.serviceClassID = Number(this.f.serviceClassID.value);
      request.serviceTypeID = Number(this.f.serviceTypeID.value);
      request.vendorGUID = this.f.vendorID.value;
      this._businessRulesService.findSimilarBusinessRules<ResponseModel<BusinessRulesModel[]>>(request).subscribe(response => {
        if (response.code == "200" && response.message == "") {
          this.similarRules = response.model;
        }
      });
    }
  }

  fuelSupplierChange(e: any) {
    this.fuelSupplierName = this.f.fuel_supplier_select.value;
    if (this.fuelSupplierName != "") {
      this.getIntoPlaneAgentList();
    }
  }

  getIntoPlaneAgentList() {
    let request = new VendorModel()
    request.vendorName = this.fuelSupplierName;
    request.icao = this.icao;
    this._groundStopAdvancedService.getIntoPlaneAgentListByAirportVendor<ResponseModel<VendorModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.intoPlaneAgentList = response.model;
        if (this.f.fuel_supplier_select.value == 'TBD') {
          let intoplaneagentid = this.intoPlaneAgentList.find(x => x.intoPlaneAgent == 'TBD').vendorGUID;
          this.f.intoplane_agent_select.setValue(intoplaneagentid);
        }

      }
      else {
        this.intoPlaneAgentList = [];
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    })
  }

  //vendorChange() {
  //  if (this.f.vendorID.value == "")
  //    return;
  //  this._vendorService.getRelatedVendorsByVendorGUID<ResponseModel<VendorModel[]>>(this.f.vendorID.value).subscribe(response => {
  //    if (response != null) {
  //      if (response.message == "" && response.code == "200") {
  //        this.relatedVendorList = response.model;
  //      }
  //      else {
  //        if (response.code == "401") {
  //          this._authService.signOut();
  //        }
  //      }
  //    }
  //  })
  //}
}
