import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { NoteTypeModel } from '../models/note-type.model';
import { CountryService } from '../services/country.service';
import { CountryContentModel } from '../models/country-content.model';


@Component({
  selector: 'app-country-content-edit-dialog',
  templateUrl: './country-content-edit-dialog.component.html',
  styleUrls: ['./country-content-edit-dialog.component.css']
})
/** country-profile-setup-add component*/
export class CountryContentEditDialogComponent implements OnInit {
  country_content_edit_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() countryID: number;
  @Input() countryContentSegmentID: number;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  countryContent: CountryContentModel;
  country_note: string;
  selectedNoteTypeId: number;
  modifiedBy: string;
  modifiedDate: string;
  isModified: boolean;
  isActive: number;
  showSuccessMsg: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<CountryContentEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _dialogService: DialogService,
    private readonly _countryService: CountryService
  ) {
    this.countryID = data.countryID;
    this.countryContentSegmentID = data.countryContentSegmentID;
    this.dialogTitle = data.dialogTitle;

  }

  getAllData(): Observable<any[]> {
    let request = new CountryContentModel();
    request.countryID = this.countryID;
    request.countryContentSegmentID = this.countryContentSegmentID;
    let getCountryContentResponse = this._countryService.getCountryContentByCountryIDandSegmentID<ResponseModel<CountryContentModel>>(request);

    return forkJoin([getCountryContentResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.countryContent = responses[0].model;
          
       //   this.selectedAirportTypeId = null;// this.countryTypeList[0].countryTypeID;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      //if (this.countryNoteID != null && this.countryNoteID != 0) {
      //  if (responses[1] != null) {
      //    if (responses[1].code == "200" && responses[1].message == "") {
      //      let countryNote = new AirportNotesModel();
      //      countryNote = responses[1].model;
      //        this.noteTypeList = this.noteTypeList.concat([{ noteTypeID: countryNote.noteTypeID, noteTypeDescription: countryNote.noteType }]);
      //      this.selectedNoteTypeId = countryNote.noteTypeID;
      //      this.country_note = countryNote.countryNote;
      //      this.modifiedBy = countryNote.modifiedBy;
      //      this.modifiedDate = countryNote.modifiedDate;
      //      if (countryNote.isActive == true)
      //        this.isActive = 1;
      //      else
      //        this.isActive = 0;

      //    }
      //  }

      //}

      this.initControls();

      this.showSpin = false;


    });

  }

  initControls() {
    this.country_content_edit_form = this._formBuilder.group({
      note_type: this.selectedNoteTypeId,
      country_note: this.country_note,
      note_status: this.isActive,
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate


    });
  }

  get f() { return this.country_content_edit_form.controls; }

  

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";
    if (this.f.note_type.value == null) {
      this.errMsg = "Note Type is required.";
    }
    if (this.f.country_note.value == null) {
      if (this.errMsg != "") {
        this.errMsg += "\n";
        this.errMsg += "Note is required.";
      }
      else
        this.errMsg = "Note is required.";    
    }
    if (this.country_content_edit_form.invalid || this.errMsg != "") {
      return;
    }
    this.loading = true;
    //let obj = new AirportNotesModel();
    //obj.countryNotesID = 0;
    //if (this.countryNoteID != 0 && this.countryNoteID != null)
    //  obj.countryNotesID = this.countryNoteID;
    //obj.countryID= this.countryID;
    //obj.noteTypeID = this.f.note_type.value;
    //obj.countryNote = this.f.country_note.value;
    //if (this.f.note_status.value == "1")
    //  obj.isActive = true;
    //else
    //  obj.isActive = false;
    
    //this._countryService.saveAirportNote<ResponseModel<number>>(obj).subscribe(response => {
    //  if (response != null && response.code == "200") {
    //    if (response.model != null) {
    //      this.countryNoteID = response.model;
    //      let d = new Date();
    //      let m = d.getMonth() + 1;
    //      let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
    //      this.f.modifiedBy.setValue(localStorage.getItem('un'));
    //      this.f.modifiedDate.setValue(dt);
    //      this.showSuccessMsg = true;
    //      setTimeout(() => {
    //        this.showSuccessMsg = false;
    //      }, 1000);
    //      this.isModified = true;
    //    }
    //    else {
    //      this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
    //      //this._dialogRef.close(this.isModified);
    //    }
    //  }
    //  else {
    //    if (response.code == "401") {
    //      //this.errMsg = response.message
    //      this._authService.signOut();
    //    }
    //    else {
    //      this.errMsg = "Failed to save the record. Please try again later.";
    //    }
    // }
    //  this.loading = false;
    //})

  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.selectedNoteTypeId = null;
    this.country_note = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    //this.getAllData().subscribe(responses => {
    //  if (responses[0] != null) {
    //    if (responses[0].code == "200" && responses[0].message == "") {
    //      this.noteTypeList = responses[0].model;

    //      //   this.selectedAirportTypeId = null;// this.countryTypeList[0].countryTypeID;
    //    }
    //    else {
    //      if (responses[0].code == "401") {
    //        this._authService.signOut();
    //      }
    //    }
    //  }
    //});
    this.initControls();    
  }

  clickClose() {
     this._dialogRef.close(this.isModified);
  }


}
