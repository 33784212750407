<div style="width:100%;font-size:x-small; flex-direction: column;" class="d-flex">
  <div class="d-flex" style="width: 100%;">
    <div style="width:20%">
      <div class="input-group">
        <div style="text-align: right; width:37%; align-self: flex-end">CRZ SPD</div><div class="ml-1 " style="border-bottom: solid thin black; align-self: flex-end; width: 46%">{{cruiseSpeed}}</div><span style="font-size: small; align-self: flex-end " *ngIf="cruiseSpeedClientRequested" class="material-icons-outlined">add</span>
      </div>
      <div class="input-group">
        <div style="text-align: right; width: 37%; align-self: flex-end">FLT LVL</div><div class="ml-1" style="border-bottom: solid thin black; width: 46%; align-self: flex-end">{{flightLevel}}</div><span style="font-size: small; align-self: flex-end" *ngIf="flightLevelClientRequested" class="material-icons-outlined">add</span>
      </div>
    </div>

    <div style="width:20%">
      <div class="input-group">
        <div style="text-align: right; width: 39%; align-self: flex-end">ALT</div><div class="ml-1 " style="border-bottom:solid thin black;width:45%;align-self:flex-end">{{alternateAirports}}</div><span style="font-size:small" *ngIf="altClientRequested" class="material-icons-outlined">add</span>
      </div>
      <div class="input-group">
        <div style="text-align: right; width: 39%; align-self: flex-end">DEP FUEL</div><div class="ml-1" style="border-bottom:solid thin black;width:45%;align-self:flex-end">{{departureFuel}}</div><span style="font-size:small" *ngIf="departureFuelClientRequested" class="material-icons-outlined">add</span>
      </div>
    </div>

    <div style="width:20%">
      <div class="input-group">
        <div style="text-align: right;  width: 39%; align-self: flex-end">FOD</div><div class="ml-1" style="border-bottom: solid thin black; width: 45%; align-self: flex-end">{{fuelOverDestination}}</div><span style="font-size:small" *ngIf="fodClientRequested" class="material-icons-outlined">add</span>
      </div>
      <div class="input-group">
        <div style="text-align: right; width: 39%; align-self: flex-end">Fuel@Alt</div><div class="ml-1 " style="border-bottom:solid thin black;width:45%;align-self:flex-end">{{tank}}</div><span style="font-size:small" *ngIf="tankClientRequested" class="material-icons-outlined">add</span>
      </div>
      <!-- <div class="input-group">
        <div style="text-align: right; width: 20%; align-self: flex-end">ETPS</div><div class="ml-1" style="border-bottom:solid thin black;width:72%;align-self:flex-end">{{etps}}</div><span style="font-size:small" *ngIf="etpsClientRequested" class="material-icons-outlined">add</span>
      </div> -->
    </div>

    <div style="width:20%">
      <div class="input-group">
        <div style="text-align: right; width: 23%; align-self: flex-end">HOLD</div><div class="ml-1" style="border-bottom: solid thin black; width: 50%; align-self: flex-end">{{hold}}</div><span style="font-size:small" *ngIf="holdClientRequested" class="material-icons-outlined">add</span>
      </div>
      <div class="input-group">
        <div style="text-align: right; width: 23%; align-self: flex-end">RES</div><div class="ml-1 " style="border-bottom:solid thin black;width:50%;align-self:flex-end">{{reserve}}</div><span style="font-size:small" *ngIf="reserveClientRequested" class="material-icons-outlined">add</span>
      </div>
      <!-- <div class="input-group">
        <div style="text-align: right; width: 20%; align-self: flex-end">ETPS</div><div class="ml-1" style="border-bottom:solid thin black;width:72%;align-self:flex-end">{{etps}}</div><span style="font-size:small" *ngIf="etpsClientRequested" class="material-icons-outlined">add</span>
      </div> -->
    </div>
   
    <div style="width:20%">
      <!-- <div class="input-group">
        <div style="text-align: right; width: 7%; align-self: flex-end">RTE</div><div class="ml-1" style="border-bottom:solid thin black;width:91%;text-align:left;align-self: flex-end">{{flightRoute}}</div>
      </div> -->
      <div class="input-group">
        <div style="text-align:right;width:37%">PAX WT</div><div class="ml-1" style="border-bottom:solid thin black; width: 46%">{{payload}}</div><span style="font-size:small" *ngIf="payloadClientRequested" class="material-icons-outlined">add</span>
      </div>
      <div class="input-group">
        <div style="text-align: right; width: 37%; align-self: flex-end">CARGO</div><div class="ml-1" style="border-bottom:solid thin black;width:45%;align-self:flex-end">{{cargo}}</div><span style="font-size:small" *ngIf="cargoClientRequested" class="material-icons-outlined">add</span>
      </div>
    </div>
    <div>
      <button mat-button class="btn-high" style="font-size:small;cursor:pointer" (click)="editFPP()">Edit</button>
    </div>
  </div>
  
  <div class="input-group">
    <div style="text-align: right; width: 7%; align-self: flex-end">ATC RMK</div><div class="ml-1" 
    style="border-bottom: solid thin black; width: 80%; text-align: left; align-self: flex-end; text-overflow: ellipsis; overflow: hidden; text-wrap: nowrap;" [matTooltip]="remarks">{{remarks}}</div>
  </div>
  
</div>
