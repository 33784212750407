import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'; 
import { ResponseModel } from '../models/response.model';
import { MessageService } from '../services/message.service';
import { AuthenticateService } from '../services/authenticate.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MessageFolderModel } from '../models/message-folder.model';
import { DocumentTypeModel } from '../models/document-type.model';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-country-document-type-edit-dialog',
  templateUrl: './country-document-type-edit-dialog.component.html',
  styleUrls: ['./country-document-type-edit-dialog.component.css'],
})

export class CountryDocumentTypeEditDialog implements OnInit {

  loading = false;
  isModified: boolean = true; 
  message_folder_edit_form: UntypedFormGroup;
  documentTypeList: DocumentTypeModel[];
  messageFolderID: number;
  documentTypeDescription: string;
  requiredDocumentTypeList: DocumentTypeModel[];
 
  folderExist: boolean; 
  showSuccessMsg: boolean = false;
  successMsg: string = "Folder Edited Succesfully. Please Refresh to view the changes"; 
  editDocumentName: string;

  hasSpecialCharacters: boolean = false;
  warningMsg: string = "Can not Enter Charecters like ~!@#$%^&*()_+{}|:<>,.;'[]\\";
  showWarningMsg: boolean = true;
  errorMsg: string = "";
  showErrorMsg: boolean = false;
  dialogTitle: string = '';
  documentTypeID: number;

  country_documnet_type_edit: UntypedFormGroup;
  disableRecord: boolean = false;
  isMiscellaneous: string;// boolean = false;
  isDisableRecord: string = 'false';// boolean = false;
  isMiscellaneousValue: boolean = false;
  searchFiltervalue: number = 0;
  modifiedBy: string;
  modifiedDate: string;

  constructor(private readonly _dialogRef: MatDialogRef<CountryDocumentTypeEditDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _commonService: CommonService, 
    private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder,
  ) {

    this.dialogTitle = _data.dialogTitle;
    this.documentTypeList = _data.documentTypeList;
    this.documentTypeID = _data.documentTypeID;
    this.isMiscellaneous = _data.isMiscellaneous?.toString();
    this.isDisableRecord = _data.disableRecord?.toString();
    this.isMiscellaneousValue = _data.isMiscellaneous;
    this.modifiedBy = _data.modifiedBy;
    this.modifiedDate = _data.modifiedDate;
    if(_data.isMiscellaneous){
      this.searchFiltervalue = 1;
    }
    if(_data.dialogTitle == 'Add'){
      this.isDisableRecord = 'false';
    }
  }

  
  ngOnInit() {
    this.requiredDocumentTypeList = this.documentTypeList.filter(d => d.documentTypeID === this.documentTypeID);
    this.documentTypeDescription = this.requiredDocumentTypeList[0]?.documentTypeDescription;
    this.editDocumentName = this.documentTypeDescription;
    this.showErrorMsg = false;
    this.errorMsg = "";
    this.showSuccessMsg = false;

    this.initControls();
  } 
 
  initControls() {
    this.country_documnet_type_edit = this._formBuilder.group({
      disableRecord: [this.disableRecord],
      isMiscellaneous: [this.isMiscellaneous],
    });
  }

  get f() { return this.country_documnet_type_edit.controls; }

  updateFolderName(){ 
    let request = new DocumentTypeModel();
    request.documentTypeID = this.documentTypeID;
    request.documentTypeDescription = this.editDocumentName;

     
    // let disableValue = this.disableRecord == 'true'? 
 
    // request.isActive =  this.isDisableRecord == 'true'? true: (this.isDisableRecord == 'false'? false : true);
    if(this.isDisableRecord == 'true'){
    request.isActive = false;
    }
    if(this.isDisableRecord == 'false'){
    request.isActive = true;
    }
    // if(this.isMiscellaneous == 'true'){
    // request.isMiscellaneous = true;
    // }
    // if(this.isMiscellaneous == 'false'){
    // request.isMiscellaneous = false;
    // }


      request.isMiscellaneous = this.isMiscellaneousValue;

      //  this._commonService.insertUpdateCountryDocumentType<ResponseModel<boolean>>(request).subscribe(response => {
    this._commonService.insertUpdateCountryDocumentType<ResponseModel<boolean>>(request).subscribe(response => {

        if (response.code = "200") {

        
          
        this.folderExist = response.model;
        if(!this.folderExist){
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false; 
          }, 1000);
          this.clickClose();
        }          
        this.errorMsg = this.folderExist ?   "Document Already Exists" : this.errorMsg ;   
        if ( this.errorMsg.length>5){
          this.showErrorMsg = true;
          setTimeout(() => {
            this.showErrorMsg = false; 
            this.errorMsg = '';
          }, 2000);
        }

       
      }
    });
  
 
    // this.errorMsg = this.hasSpecialCharacters ?  "Cannot Enter Special Characters" : this.errorMsg;
    // this.errorMsg = this.editDocumentName.length > 15 ?  "Folder Name is Too Large" : this.errorMsg;
    
    if ( this.errorMsg.length>5)
      this.showErrorMsg = true;
    setTimeout(() => {
      this.showErrorMsg = false; 
    }, 1000);
  }
 
  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  checkForSpecialCharacters() {
    const specialChars = "`~-=|'!@#$%^&*()_+{}[]:;<>,.?~\\/\"";// Define your list of special characters
    for (let i = 0; i < this.editDocumentName.length; i++) {
      if (specialChars.includes(this.editDocumentName[i])) {
        this.hasSpecialCharacters = true; // Special character found
        return;
      }
    }
    this.hasSpecialCharacters = false; // No special characters found
  }
 
 
  searchFilterOnChange(e) {
    
    switch (e.target.value) {
      case 'requiredDocs':
        this.searchFiltervalue = 0; 
        this.isMiscellaneousValue = false;
          // this.getData();
      
        break;
      case 'miscDocs':
        this.searchFiltervalue = 1;
        this.isMiscellaneousValue = true; 
        // this.getData();          
      
        break;
    
      default:
        this.searchFiltervalue = 0; 
        // this.getData();        
        this.isMiscellaneousValue = false; 
        return;
    }

  }


}
