import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NavigationExtras, Router } from '@angular/router';
import { AircraftManufacturerModel } from '../models/aircraft-manufacturer.model';
import { AircraftTypeModel } from '../models/aircraft-type.model';
import { AircraftModel } from '../models/aircraft.model';
import { CustomerModel } from '../models/customer.model';
import { ResponseModel } from '../models/response.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { AircraftService } from '../services/aircraft.service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-aircraft-main-edit-dialog',
  templateUrl: './aircraft-main-edit-dialog.component.html',
  styleUrls: ['./aircraft-main-edit-dialog.component.css']
})
/** aircraft-main-edit-dialog component*/
export class AircraftMainEditDialogComponent implements OnInit {
  @Input() aircraftGuid: string;
  @Input() dialogTitle: string;
  aircraft_main_edit_form: UntypedFormGroup;
  customerList: CustomerModel[];
  displayedColumn: string[] = ['clientName'];
  clientList: CustomerModel[];
  availClientList: CustomerModel[] = [];
  selClientList: CustomerModel[] = [];
  acManufacturerList: AircraftManufacturerModel[];
  acTypeList: AircraftTypeModel[];
  selectedCustomerGUID: string = "";
  selectedCustomerName: string = "";
  selectedACManufacturerID: number = null;
  selectedACTypeID: number = null;
  aircraftTypeDesignator: string = "";
  registration: string = "";
  callSign: string;
  isActive: number;
  errMsg: string="";
  loading: boolean;
  isModified: boolean;
  modifiedBy: string;
  modifiedDate: string;
  submitted = false;
  userType: string;
  showSuccessMsg: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<AircraftMainEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _commonService: CommonService,
    private readonly _aircraftService: AircraftService, private readonly _dialog: MatDialog, private readonly _dialogService: DialogService, private _router: Router) {
    this.aircraftGuid = data.aircraftGuid;
    this.dialogTitle = data.dialogTitle;
    this.selectedCustomerGUID = data.customerGUID;
    this.selectedCustomerName = data.customerName;
  }

  ngOnInit() {

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    //this._aircraftService.getCustomerListByAircraftGUID<ResponseModel<CustomerModel[]>>(this.aircraftGuid).subscribe(response => {
    //  if (response != null) {
    //    if (response.code == "200" && response.message == "") {
    //      this.clientList = response.model;
    //      this.setClientList(this.f.cus_filter.value.toLowerCase());
    //    }
    //  }
    //});

    this._aircraftService.getAircraftManufacturerList<ResponseModel<AircraftManufacturerModel[]>>().subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.acManufacturerList = response.model;
        }
      }
    });


    this._commonService.getCustomers<ResponseModel<CustomerModel[]>>().subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.customerList = response.model;
        }
      }
    });


    if (this.aircraftGuid != "") {
      this._aircraftService.getAircraftProfileByaId<ResponseModel<AircraftModel>>(this.aircraftGuid).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let aircraft = response.model;
            this.selectedCustomerGUID = aircraft.customerGuid;
            this.registration = aircraft.registration;
            this.callSign = aircraft.callSign;
            this.selectedACManufacturerID = aircraft.aircraftManufacturerId;
            this.selectedACTypeID = aircraft.aircraftTypeId;
            this.aircraftTypeDesignator = aircraft.aircraftTypeDesignator;
            this.modifiedBy = aircraft.modifiedBy;
            this.modifiedDate = aircraft.modifiedDate;
            if (aircraft.isActive == true) {
              this.isActive = 1;
            }
            else {
              this.isActive = 0;
            }
            
            this._aircraftService.getAircraftModelTypeListByACManufacturerID<ResponseModel<AircraftTypeModel[]>>(this.selectedACManufacturerID).subscribe(response => {
              if (response != null) {
                if (response.code == "200" && response.message == "") {
                  this.acTypeList = response.model;
                }
              }
            });
            this.initControls();
          }
        }
      })
    }  
    this.initControls();
  
  }

  //filterClientList() {
  //  this.setClientList(this.f.cus_filter.value.toLowerCase());
  //}

  //setClientList(cusName: string) {
  //  this.errMsg = "";
  //  this.availClientList = this.clientList.filter(
  //    (thing, i, arr) => arr.findIndex(t => t.customerGUID === thing.customerGUID && t.selected == false && (t.customerName.toLowerCase().startsWith(cusName) || cusName == "")) === i
  //  );
  //  this.selClientList = this.clientList.filter(
  //    (thing, i, arr) => arr.findIndex(t => t.customerGUID === thing.customerGUID && t.selected == true && (t.customerName.toLowerCase().startsWith(cusName) || cusName == "")) === i
  //  );
  //}

  //clickSelectAll() {
  //  this.clientList.forEach(x => {
  //    if (this.selectedCustomerGUID == "") {
  //      x.selected = true
  //    }
  //    else {
  //      if (this.selectedCustomerGUID == x.customerGUID) {
  //        x.selected = true;
  //      }
  //    }
  //    //x.selected = true;
  //  })

  //  this.setClientList(this.f.cus_filter.value.toLowerCase());
  //}

  //clickRemoveAll() {
  //  this.clientList.forEach(x => {
  //    x.selected = false;
  //  })

  //  this.setClientList(this.f.cus_filter.value.toLowerCase());
  //}

  initControls() {
    this.aircraft_main_edit_form = this._formBuilder.group({
      registration: [this.registration, Validators.required],
      customer_select: [this.selectedCustomerGUID,Validators.required],
      //cus_filter: "",
      aircraft_manufacturer: [this.selectedACManufacturerID, Validators.required],
      aircraft_model: [this.selectedACTypeID,Validators.required],
      aircraft_type: this.aircraftTypeDesignator,
      aircraft_status: this.isActive,
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate,
      call_sign:this.callSign
    });

  }
  get f() { return this.aircraft_main_edit_form.controls; }

  changeManufacturer(event: any) {
    let acManufactureId = this.f.aircraft_manufacturer.value;
    this._aircraftService.getAircraftModelTypeListByACManufacturerID<ResponseModel<AircraftTypeModel[]>>(acManufactureId).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.acTypeList = response.model;
          this.f.aircraft_type.setValue("");
        }
      }
    });
  }

  changeACModel(event: any) {
    let acTypeID = this.f.aircraft_model.value;
    let acType = this.acTypeList.find(v => v.aircraftTypeID == acTypeID);
    this.f.aircraft_type.setValue(acType.aircraftTypeDesignator);
  }

  //clickAddItem(e: any, item: CustomerModel) {
  //  this.clientList.forEach(x => {
  //    if (x.customerGUID.toLowerCase() == item.customerGUID.toLowerCase()) {
  //      x.selected = true;
  //    }
  //  })
  //  this.setClientList(this.f.cus_filter.value);

  //}

  //clickRemoveItem(e: any, item: CustomerModel) {
  //  this.clientList.forEach(x => {
  //    if (x.customerGUID.toLowerCase() == item.customerGUID.toLowerCase()) {
  //      x.selected = false;
  //    }
  //  })
  //  this.setClientList(this.f.cus_filter.value);

  //}

  clickSave() {
    this.loading = true;
    this.submitted = true;
    this.errMsg = "";
    //if (this.selClientList.length == 0)
    //  this.errMsg = "You must select at least one client."
    if (this.aircraft_main_edit_form.invalid || this.errMsg != "") {
      return;
    }
    let obj = new AircraftModel();

    obj.aircraftGuid = "";
    obj.customerList = "";
    obj.pageName = "Main";
    if (this.aircraftGuid != "" && this.aircraftGuid != null)
      obj.aircraftGuid = this.aircraftGuid;
    obj.registration = this.f.registration.value;
    obj.callSign = this.f.call_sign.value;
    obj.aircraftTypeId = this.f.aircraft_model.value;
    if (this.f.aircraft_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;
    //obj.customerList = "";
    //if (this.selClientList.length > 0)
    //  obj.customerList = Array.prototype.map.call(this.selClientList, s => s.customerGUID).toString();
    obj.customerGuid = this.f.customer_select.value;
    this._aircraftService.saveAircraft<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model == "") {
          this._dialogService.openAlertDialog(response.message);
        }
        else { 
        if (response.model != null) {
          this.aircraftGuid = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString().padStart(2, "0");
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);          
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            if (this.aircraftGuid!='' && this.dialogTitle!='EDIT Record')
              this.clickAircraftProfile();
            else
              this.clickClose();
          },2000)

        }
        else {
          this.errMsg ="Failed to save this record at this time. Please try later.";

        }
      }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = response.message;
        }
      }
      this.loading = false;
    });
  }

  clickClose() {
    let obj = new ReturnObjModel();
    if (this.isModified) {
      obj.actionName = "search";
    }
    else
      obj.actionName = "";
    this._dialogRef.close(obj);
  }

  clickAircraftProfile() {
    let obj = new ReturnObjModel();
    obj.actionName = "edit"
    obj.newId = this.aircraftGuid;
    this._dialogRef.close(obj);
    
  }
}
