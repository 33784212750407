import { GroundStopManifestModel } from "./ground-stop-manifest.model";
import { GroundStopModel } from "./ground-stop.model";
import { TripLegModel } from "./trip-leg.model";
import { TripRevisionModel } from "./trip-revision.model";

export class TripModel {
  tripCodeGUID: string;
  tripCode: string;
  tripEstimate: string;
  tripConsultation: string;
  aircraftGUID: string;
  registration: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  speed: number;
  customerGUID: string;
  customerName: string;
  //crewNameList: string;
  //paxNameList: string;
  estimatedTripStartDate: string;
  estimatedTripEndDate: string;
  route: string;
  pageSize: number;
  pageIndex: number;
  totalRecords: number;
  filterBy: string;
  textSearch: string;
  isActive: boolean;
  tripCloseOutDescription: string;
  tripCloseOutID: number;
  tripCloseOutSubmittedBy: string;
  tripCloseOutDate: string;
  tripCodeType: string;
  tripCodeDesc: string;
  customerCreditStatusID: number;
  dateRange: string;
  groundStopTypeGUIDList: string;
  groundStopCrewList: GroundStopManifestModel[];
  groundStopPAXList: GroundStopManifestModel[];
  groundStopList: GroundStopModel[];
  tripID: number;
  tripTaskStatusGUIDList: string;
  tripAlertFilter: string;
  createdDate: string;
  createdBy: string;
  crewCount: number;
  paxCount: number;
  accountingStatusGUIDList: string;
  remarks: string;
  customerReference: string;
  lockdownTrip: boolean;
  removeFromExternalView: boolean;
  emailSent: boolean;
  fARTypeID: number;
  message: string;
  failType: string;
  customerTripID: string;
  submittedBy: string;
  submitDate: string;
  startDate: Date;
  endDate: Date;
  startDateText: string;
  endDateText: string;
  sortBy: string;
  tripRevisionList: TripRevisionModel[];
  includeDemo: boolean;
  pastHours: number;
  refreshColumn: string;
  transactionCount: number;
  serviceTypeIDList: string;
  searchDateType: string;
  filterTripRevision: boolean;
  includeDisabledTask: boolean;
  tripStarted: boolean;
  includeTBATask: boolean;
  personRole: string;
  selectedPersonGUIDList: string;
  selectedICAOList: string;
  selectedAircraftGUIDList: string;
  selectedCustomerGUIDList: string;
  selectedVendorGUIDList: string;
  clientInstructions: string;
  include_TripEstimate: boolean;
  include_AcquireAllPermits: boolean;
  include_FuelQuotesArrangementsOnAllLegs: boolean;
  include_CrewHotelQuotesArrangementsOnAllLegs: boolean;
  include_PAXHotelQuotesArrangementsOnAllLegs: boolean;
  include_CrewTranspOnAllLegs: boolean;
  include_PAXTranspOnAllLegs: boolean;
  include_SecurityCoordinationOnAllLegs: boolean;
  personTextSearch: string;
  isNewTrip: boolean;
  additinalReqTooltip: string;
  fARTypeDescription: string;
  messageGUID: string;
  selected: boolean;
  legCount: number;
  daysToTripEnd: number;
  selectedCustomerCreditStatusIDList: string;
  selectedTripCodeGUIDList: string;
  wxNotes: string;
  opsNotes: string;
  queryHour: number;
  queryTimeText: string;
  isFromLegEdit: boolean;
  includeActiveStopOnly: boolean;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  personGUID: string;
  serviceClassID: number;
  serviceTypeID: number;
  addBRTasks: boolean;
  defaultEmailTo: string;
  defaultEmailCc: string;
  tempTripCode: string;
  activeGroundStopList: GroundStopModel[];
  hasCancelledStop: boolean;
  tripRevisionForTripOpenList: TripRevisionModel[];
  tripRevisionExcludeTripOpenList: TripRevisionModel[];
  isExternal: boolean;
  hideOnHold: boolean;
  tripOpenCount: number;
  hidePastStop: boolean;
  nextArrivalGroundStopGUID: string;
  tripLegList: TripLegModel[];
  modifiedBy: string;
  taskCount: number;
  clicked1: boolean = false;
  clicked2: boolean = false;
  downloading1: boolean = false;
  downloading2: boolean = false;
  activeStops: number;
  cancelledStops: number;
  aTaskCount: number;
  fpTaskCount: number;
  ffTaskCount: number;
  pTaskCount: number;
  completedTripsCount: number;
  tbaCount: number;
  flightType: number;
  internalNonStandardProcedures: string;
}
