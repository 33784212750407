import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service'

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
  //styleUrls: ['./logon.component.css']
})

export class LogoutComponent implements OnInit {

  constructor(private _router: Router, private _route: ActivatedRoute, private readonly _authservice: AuthenticateService) {

  }

  ngOnInit() {
    setTimeout(() => {
      this._authservice.signOut();
    }, 100);
  }

}
