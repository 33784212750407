import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { MailAddressTypeModel } from '../models/mail-address-type.model';
import { CountryModel } from '../models/country.model';
import { PersonMailAddressModel } from '../models/person-mail-address.model';
import { StateProvinceModel } from '../models/state-province.model';
import { DataEntryStatusModel } from '../models/data-entry-status.model';

@Component({
  selector: 'app-customer-person-mail-edit-dialog',
  templateUrl: './customer-person-mail-edit-dialog.component.html',
  styleUrls: ['./customer-person-mail-edit-dialog.component.css']
})

export class CustomerPersonMailEditDialogComponent implements OnInit {
  @Input() dialogTitle: string;
  @Input() personMailAddressGUID: string;
  @Input() personId: string;
  @Input() customerGUID: string;

  customer_person_mail_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = false;

  mailAddressTypeList: MailAddressTypeModel[];
  avaiMailAddressTypeList: MailAddressTypeModel[];
  selMailAddressTypeList: MailAddressTypeModel[];

  countryList: CountryModel[];
  stateProvinceList: StateProvinceModel[];
  address1: string;
  address2: string;
  address3: string;
  city: string;
  zipcode: string;
  state_province: string;
  //countryName: string;
  selectedCountryGUID: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: number;
  isPermanentAddress: number;
  isApisAddress: number;
  displayedColumn: string[] = ['addressType'];
  //personId: string;
  isModified: boolean = false;
  showSuccessMsg: boolean = false;
  upPII: boolean = false;
  grantAccessToManageManifestProfiles: boolean;
  userType: string;
  readonly: boolean;
  dataEntryStatusList: DataEntryStatusModel[];
  selectedDataEntryStatusID: number;
  constructor(private readonly _dialogRef: MatDialogRef<CustomerPersonMailEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _customerService: CustomerService,
    private readonly _dialogService: DialogService
  ) {
    this.personMailAddressGUID = _data.personMailAddressGUID;
    if (_data.personId == null) {
      this.personId = "";
    }
    else {
      this.personId = _data.personId;
    }
    this.dialogTitle = _data.dialogTitle;
    this.customerGUID = _data.customerGUID;

  }

  getAllData(): Observable<any[]> {
    let getCountryResponse = this._commonService.getCountryListWithUSAFirst<ResponseModel<CountryModel[]>>();
    let getMailAddressTypeResponse = this._commonService.getMailAddressTypeList<ResponseModel<MailAddressTypeModel[]>>(this.personMailAddressGUID);
    let getStateProvinceResponse = this._commonService.getStateProvinceList<ResponseModel<StateProvinceModel[]>>('6AA9B42F-A693-4D74-83D8-A1500B0F1133');
    //let getPersonIdResponse = this._customerService.getPersonId<ResponseModel<string>>(this.customerGUID);
    //let getPersonIdResponse = this._customerService.getCustomerOfficePersonId<ResponseModel<string>>(this.customerGUID);
    let getPersonMailResponse;
    if (this.personMailAddressGUID != null && this.personMailAddressGUID != "") {
      getPersonMailResponse = this._commonService.getPersonMailAddressBypmaId(this.personMailAddressGUID);
    }
    else {
      getPersonMailResponse = of(null);
    }
    let dataEntryStatusResponse;
    if (this.userType == "internal") {
      dataEntryStatusResponse = this._commonService.getDataEntryStatusList<ResponseModel<DataEntryStatusModel[]>>();
    }
    else {
      dataEntryStatusResponse = of(null);
    }
    return forkJoin([getCountryResponse, getMailAddressTypeResponse, getStateProvinceResponse, getPersonMailResponse, dataEntryStatusResponse]);

    //if (this.personMailAddressGUID != null && this.personMailAddressGUID != "") {
    //  let getPersonMailResponse = this._commonService.getPersonMailAddressBypmaId(this.personMailAddressGUID);
    //  return forkJoin([getCountryResponse, getMailAddressTypeResponse, getStateProvinceResponse, getPersonMailResponse]);
    //}
    //else {
    //  return forkJoin([getCountryResponse, getMailAddressTypeResponse, getStateProvinceResponse]);
    //}

  }


  ngOnInit() {
    
    this._authService.updateAccessTime();
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }
    if (!this.upPII || !this.grantAccessToManageManifestProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
    this.avaiMailAddressTypeList = [];
    this.selMailAddressTypeList = [];
    this.selectedCountryGUID = "";
    this.address1 = "";
    this.address2 = "";
    this.address3 = "";
    this.city = "";
    this.zipcode = ""
    this.state_province = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.isPermanentAddress = 1;
    this.isApisAddress = 1;
    this.showSuccessMsg = false;
    this.dataEntryStatusList = [];
    this.selectedDataEntryStatusID = 0;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType == 'customer' && this.personMailAddressGUID!='') {
      this.readonly = true;
    }
    else {
      this.readonly = false;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.countryList = responses[0].model;
          this.selectedCountryGUID = this.countryList[0].countryGUID;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.mailAddressTypeList = responses[1].model;

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.stateProvinceList = responses[2].model;

        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.personMailAddressGUID != null && this.personMailAddressGUID != "") {
        if (responses[3] != null) {
          if (responses[3].code == "200" && responses[3].message == "") {
            let personMail = new PersonMailAddressModel();
            personMail = responses[3].model;
            this.address1 = personMail.address1;
            this.address2 = personMail.address2;
            this.address3 = personMail.address3;
            this.city = personMail.city;
            this.zipcode = personMail.postalCode;
            this.state_province = personMail.state_Province;
            this.selectedCountryGUID = personMail.countryGUID;
            this.modifiedBy = personMail.modifiedBy;
            this.modifiedDate = personMail.modifiedDate;
            if (personMail.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
            if (personMail.isPermanentAddress == true)
              this.isPermanentAddress = 1;
            else
              this.isPermanentAddress = 0;
            if (personMail.isAPISAddress == true)
              this.isApisAddress = 1;
            else
              this.isApisAddress = 0;
            this.selectedDataEntryStatusID = personMail.dataEntryStatusID;
          }
          else {
            if (responses[3].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          this.dataEntryStatusList = responses[4].model;

        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      if (this.isApisAddress) {
        this.f.zipcode.setValidators(Validators.maxLength(9));
      }
      this.setControlStatus();

      this.showSpin = false;

      this.setMailAddressTypeList();

    });

  }

  initControls() {
    this.customer_person_mail_edit_form = this._formBuilder.group({
      mail_address_status: this.isActive,
      address1: [this.address1, Validators.required],
      address2: [this.address2],
      address3: [this.address3],
      city: [this.city, Validators.required],
      state_province: [this.state_province],
      zipcode: [this.zipcode],
      country_select: [this.selectedCountryGUID, Validators.required],
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      is_permnnent_address: this.isPermanentAddress,
      is_apis_address: this.isApisAddress,
      data_entry_status_select: [this.selectedDataEntryStatusID],
    });

  }

  setControlStatus() {
    if (this.userType == 'customer' && this.personMailAddressGUID != '') {
      this.customer_person_mail_edit_form.get('mail_address_status').disable();
      this.customer_person_mail_edit_form.get('address1').disable();
      this.customer_person_mail_edit_form.get('address2').disable();
      this.customer_person_mail_edit_form.get('address3').disable();
      this.customer_person_mail_edit_form.get('city').disable();
      this.customer_person_mail_edit_form.get('state_province').disable();
      this.customer_person_mail_edit_form.get('zipcode').disable();
      this.customer_person_mail_edit_form.get('country_select').disable();
      this.customer_person_mail_edit_form.get('is_permnnent_address').disable();
      this.customer_person_mail_edit_form.get('is_apis_address').disable();
    }
    else {
      this.customer_person_mail_edit_form.get('mail_address_status').enable();
      this.customer_person_mail_edit_form.get('address1').enable();
      this.customer_person_mail_edit_form.get('address2').enable();
      this.customer_person_mail_edit_form.get('address3').enable();
      this.customer_person_mail_edit_form.get('city').enable();
      this.customer_person_mail_edit_form.get('state_province').enable();
      this.customer_person_mail_edit_form.get('zipcode').enable();
      this.customer_person_mail_edit_form.get('country_select').enable();
      this.customer_person_mail_edit_form.get('is_permnnent_address').enable();
      this.customer_person_mail_edit_form.get('is_apis_address').enable();
    }
  }

  get f() { return this.customer_person_mail_edit_form.controls; }


  clickAddItem(e: any, item: MailAddressTypeModel) {
    this.mailAddressTypeList.forEach(x => {
      if (x.mailAddressTypeGUID.toLowerCase() == item.mailAddressTypeGUID.toLowerCase()) {
        x.selected = true;
      }
    })
    this.setMailAddressTypeList();

  }

  clickRemoveItem(e: any, item: MailAddressTypeModel) {
    this.mailAddressTypeList.forEach(x => {
      if (x.mailAddressTypeGUID.toLowerCase() == item.mailAddressTypeGUID.toLowerCase()) {
        x.selected = false;
      }
    })
    this.setMailAddressTypeList();

  }

  setMailAddressTypeList() {
    this.errMsg = "";
    this.avaiMailAddressTypeList = this.mailAddressTypeList.filter(u => u.selected == false);
    this.selMailAddressTypeList = this.mailAddressTypeList.filter(u => u.selected == true);
  }

  clickSelectAll() {
    this.mailAddressTypeList.forEach(x => {
      x.selected = true;
    })

    this.setMailAddressTypeList();
  }

  clickRemoveAll() {
    this.mailAddressTypeList.forEach(x => {
      x.selected = false;
    })

    this.setMailAddressTypeList();
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    //if (this.selMailAddressTypeList.length == 0) {
    //  if (this.errMsg != "") {
    //    this.errMsg += "\n";
    //    this.errMsg += "You must select at least one address type.";
    //  }
    //  else
    //    this.errMsg = "You must select at least one address type.";
    //}
    if (this.customer_person_mail_edit_form.invalid || this.errMsg != "" || this.selMailAddressTypeList.length == 0) {
      return;
    }
    this.loading = true;
    let obj = new PersonMailAddressModel();
    obj.personMailAddressGUID = "";
    if (this.personMailAddressGUID != "" && this.personMailAddressGUID != null)
      obj.personMailAddressGUID = this.personMailAddressGUID;
    obj.countryGUID = this.f.country_select.value;
    obj.mailAddressTypeGUIDList = Array.prototype.map.call(this.selMailAddressTypeList, s => s.mailAddressTypeGUID).toString();
    obj.address1 = this.f.address1.value;
    obj.address2 = "";
    if (this.f.address2.value != "")
      obj.address2 = this.f.address2.value;
    obj.address3 = "";
    if (this.f.address3.value != "")
      obj.address3 = this.f.address3.value;
    obj.isActive = true;
    if (obj.personMailAddressGUID != "") {
      if (this.f.mail_address_status.value == "1")
        obj.isActive = true;
      else
        obj.isActive = false;
    }
    if (this.f.is_permnnent_address.value == "1")
      obj.isPermanentAddress = true;
    else
      obj.isPermanentAddress = false;
    if (this.f.is_apis_address.value == "1")
      obj.isAPISAddress = true;
    else
      obj.isAPISAddress = false;
    obj.city = this.f.city.value;
    obj.state_Province = "";
    if (this.f.state_province.value != "")
      obj.state_Province = this.f.state_province.value;
    obj.postalCode = "";
    if (this.f.zipcode.value != "")
      obj.postalCode = this.f.zipcode.value;
    obj.personGUID = this.personId;
    obj.mailAddressTypeGUIDList = Array.prototype.map.call(this.selMailAddressTypeList, s => s.mailAddressTypeGUID).toString();
    obj.dataEntryStatusID = 0;
    if (this.userType=='internal')
      obj.dataEntryStatusID = Number(this.f.data_entry_status_select.value);
    
    obj.customerGUID = this.customerGUID;
    this._commonService.savePersonMailAddress<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.personMailAddressGUID = response.model;
          if (this.userType == 'internal' && obj.personMailAddressGUID == "" && this.personMailAddressGUID != "") {
            this.selectedDataEntryStatusID = 2;
            this.f.data_entry_status_select.setValue(this.selectedDataEntryStatusID);
          }
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          //if (obj.personMailAddressGUID == "")
          //  this._dialogService.openAlertDialog(" Your information has been submitted successfully.");
          //else
          //  this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          this.isModified = true;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          //this._dialogRef.close(this.isModified);

        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          if (response.message != "")
            this.errMsg = response.message
          else
            this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.avaiMailAddressTypeList = [];
    this.selMailAddressTypeList = [];
    this.selectedCountryGUID = this.countryList[0].countryGUID;
    this.address1 = "";
    this.address2 = "";
    this.address3 = "";
    this.city = "";
    this.state_province = "";
    this.zipcode = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.personMailAddressGUID = "";
    this.dialogTitle = "ADD New Record";
    this.initControls();
    this.mailAddressTypeList.forEach(x => {
      x.selected = false;
    })
    this.avaiMailAddressTypeList = this.mailAddressTypeList.filter(u => u.selected == false);
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }

  getStateList() {
    let countryGUID = this.f.country_select.value;
    if (countryGUID == '0B740C4D-5200-4F28-9DA1-EEE5AF7B2B63' || countryGUID == '6AA9B42F-A693-4D74-83D8-A1500B0F1133') {
    this._commonService.getStateProvinceList<ResponseModel<StateProvinceModel[]>>(countryGUID).subscribe(response => {
      if (response != null) {
      if (response.code == "200" && response.message == "") {
        this.stateProvinceList = response.model;
        }
      }
    });
    }
  }

  updatePostalValidation() {
    if (this.f.is_apis_address.value == "1") {
      this.f.zipcode.addValidators(Validators.maxLength(9))
      this.f.zipcode.updateValueAndValidity();
    }
    else {
      this.f.zipcode.clearValidators();
      this.f.zipcode.updateValueAndValidity();
    }
  
  }
}
