<div *ngIf="grantAccessToMessages">
  <div>
    <div class="sticky-div" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>


      <div style="display:flex; padding-top: 1em;">
        <div class="d-inline-flex" style="min-width: 27%; text-align: center; padding-left: 1em;">
          <mat-icon>email</mat-icon>
          <h4 style="margin-left: 0.3em;">Trip Message Center </h4>
        </div>
        <div style="display: flex; justify-content: space-between;  width: 72% !important;">
          <div style="display: flex; justify-content: space-between;">
            <div style=" margin-left:1em; margin-top: 0.5em;">
              <button type="button" mat-button class="btn-high" style="min-width:9em;" (click)="clickEmail()" [disabled]="lockdownTrip">
                <i class="material-icons" style="font-size: medium;  ">email</i>

                New Message
              </button>
            </div>
          </div>

          <div class="d-flex justify-content-start" style="width: 100%; margin-left: 2em;">
            <div class="input-group mb-3 searchbar mr-2" style="width:45%; margin-bottom: 0em !important;">
              <div class="input-group-prepend searchbarleft">
                <span class="input-group-text material-icons-outlined searchbarleft">search</span>
              </div>
              <input type="text" [(ngModel)]="searchQuery" class="form-control form-control-sm searchbarinput"
                     (keyup.enter)="searchMessages($event)" placeholder="Search mail" (mousedown)="$event.stopPropagation()">
              <div>
                <span class="input-group-text material-icons-outlined searchbarright pointer" (click)="resetSearchFilter()"
                      cdkOverlayOrigin #trigger="cdkOverlayOrigin">close</span>
              </div>
            </div>

            <div style="display: flex;   margin-left: 1em; " align="end">
              <div style="margin-top:.5em; display:flex;   font-size:small">
                <div style="margin-top: -0.2em;"><b>Filter</b></div>

                <div style="display: flex; margin-left: 1em; ">
                  <div style="margin-right:0.25em">
                    <input id="fAll" type="radio" name="filter_type" value="all" [checked]=true
                           (change)="searchFilterChange($event)" />
                  </div>
                  <label style="margin-top:-0.2em;white-space: nowrap " for="fAll">All </label>
                </div>

                <div style="display: flex; margin-left: 1em; ">
                  <div style="margin-right:0.25em">
                    <input id="fFrom" type="radio" name="filter_type" value="from" [checked]="searchFiltervalue==0"
                           (change)="searchFilterChange($event)" />
                  </div>
                  <!-- <label style="margin-top:-0.2em;white-space: nowrap " for="fBoth">Both: {{resultsLength}}</label> -->
                  <label style="margin-top:-0.2em;white-space: nowrap " for="fFrom">From </label>
                </div>
                <div style="display: flex; margin-left: 1em;">
                  <div style="margin-right:0.25em">
                    <input id="fTo" type="radio" name="filter_type" value="to" [checked]="searchFiltervalue==1"
                           (change)="searchFilterChange($event)" />
                  </div>
                  <label style="margin-top:-0.2em; white-space: nowrap" for="fTo">To </label>
                </div>
                <div style="display: flex; margin-left: 1em;">
                  <div style="margin-right:0.25em">
                    <input id="fSubject" type="radio" name="filter_type" value="subject" [checked]="searchFiltervalue==2"
                           (change)="searchFilterChange($event)" />
                  </div>
                  <label style="margin-top:-0.2em;white-space: nowrap" for="fSubject">Subject </label>
                </div>
                <div style="display: flex; margin-left: 1em;">
                  <div style="margin-right:0.25em">
                    <input id="fAssigned" type="radio" name="filter_type" value="assigned" [checked]="searchFiltervalue==4"
                           (change)="searchFilterChange($event)" />
                  </div>
                  <label style="margin-top:-0.2em;white-space: nowrap" for="fAssigned">Assigned </label>
                </div>

              </div>
            </div>
          </div>
          <div style="float: right; margin-left:2em;margin-right: 2em;  margin-top: 0.45em;">
            <button type="button" mat-button class="btn-low" (click)="clickClose()">
              <div style="display:flex">
                <!-- <i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i> -->
                <div>Close</div>
              </div>
            </button>
          </div>
        </div>
      </div>




      <div style="display:flex; padding-top: 0.4em;">
        <div style="min-width: 27%; justify-content: space-between">
          <div style="display: flex; ">
            <div style=" background-color: white;margin-left:1em;margin-right:1em; margin-top:-0.3em; width:4em;">
              <p style="margin-left: 0.3em; font-size: small;">Trip Code </p>
              <h6 style="margin-left: 0.3em; margin-top: -1.3em; font-size: small;">{{trip_code}}</h6>
            </div>
            <div style=" background-color: white; margin-left: 1em; margin-right: 1em; margin-top: -0.3em; width: 4em;">
              <p style="margin-left: 0.3em;  font-size: small;">Registration</p>
              <h6 style="margin-left: 0.3em; margin-top: -1.3em; font-size: small;">{{registration}}</h6>
            </div>
          </div>
          <div>
          </div>


        </div>

        <!-- <div style=" margin-left:1em;  ">
          <button type="button" mat-button class="btn-low" style="min-width:10em;" (click)="clientTab()">
 
            Client: {{clientCount}}
          </button>
 
        </div> -->
        <div *ngIf="isButtonDisabled">
          <div style="display:flex; ">
            <!-- FIRST ROW OF BUTTONS -->
            <!-- Service Filter -->
            <!-- <div style="margin-left:1em;">
              <div *ngIf = "false" style=" margin-right:0.25em;">
              <button  type="button" mat-button class="btn-low" style="min-width:10em;"
                      [ngClass]="selectedServiceCountText!=''?'btn-low-highlight':'btn-low'"
                      (click)="clickServiceFilter()" *ngIf="isButtonDisabled">
                Services{{selectedServiceCountText}}
              </button>
             </div>

              <div *ngIf="showServiceFilter"
                   style="position:absolute;float: left; min-width: 11em; margin-top: 0.2em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="margin-top:0.25em;" class="mat-checkbox-smaller">
                  <div style="display:flex; justify-content:space-between">
                    <div>
                      <mat-checkbox style="height:1.5em;  line-height:1.5em !important;" [checked]="allCompleteOps"
                                    [indeterminate]="someComplete(1)" (change)="selectAllServiceType($event.checked,1)">
                        Select All
                        Ops
                      </mat-checkbox>
                    </div>
                    <div style="float:right" (click)="showServiceFilter=!showServiceFilter">
                      <i class="material-icons"
                         style="font-size: medium;cursor: pointer; color: gray">close</i>
                    </div>
                  </div>
                  <div class="d-flex border-bottom pb-2">
                    <div>
                      <div *ngFor="let a of serviceTypeList; let i=index"
                           style="display:flex;flex-direction:column;width:13em">
                        <div *ngIf="a.filterColumn==1 && a.filterGroup==1">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)"
                                        [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;"
                                        title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div *ngFor="let a of serviceTypeList; let i=index"
                           style="display: flex; flex-direction: column; width: 13em">
                        <div *ngIf="a.filterColumn==2 && a.filterGroup==1">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)"
                                        [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;"
                                        title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div *ngFor="let a of serviceTypeList; let i=index"
                           style="display: flex; flex-direction: column; width: 13em">
                        <div *ngIf="a.filterColumn==3 && a.filterGroup==1">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)"
                                        [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;"
                                        title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div *ngFor="let a of serviceTypeList; let i=index"
                           style="display: flex; flex-direction: column; width: 13em">
                        <div *ngIf="a.filterColumn==4 && a.filterGroup==1">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)"
                                        [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;"
                                        title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div *ngFor="let a of serviceTypeList; let i=index"
                           style="display: flex; flex-direction: column; width: 13em">
                        <div *ngIf="a.filterColumn==5 && a.filterGroup==1">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)"
                                        [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;"
                                        title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pt-2">
                    <mat-checkbox style="height:1.5em;  line-height:1.5em !important;" [checked]="allCompleteWx"
                                  [indeterminate]="someComplete(2)" (change)="selectAllServiceType($event.checked,2)">
                      Select All
                      Wx
                    </mat-checkbox>
                  </div>
                  <div class="d-flex border-bottom pb-2">
                    <div>
                      <div *ngFor="let a of serviceTypeList; let i=index"
                           style="display: flex; flex-direction: column; width: 13em">
                        <div *ngIf="a.filterColumn==1 && a.filterGroup==2">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)"
                                        [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;"
                                        title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div *ngFor="let a of serviceTypeList; let i=index"
                           style="display: flex; flex-direction: column; width: 13em">
                        <div *ngIf="a.filterColumn==2 && a.filterGroup==2">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)"
                                        [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;"
                                        title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div *ngFor="let a of serviceTypeList; let i=index"
                           style="display: flex; flex-direction: column; width: 13em">
                        <div *ngIf="a.filterColumn==3 && a.filterGroup==2">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)"
                                        [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;"
                                        title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div *ngFor="let a of serviceTypeList; let i=index"
                           style="display: flex; flex-direction: column; width: 13em">
                        <div *ngIf="a.filterColumn==4 && a.filterGroup==2">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)"
                                        [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;"
                                        title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div *ngFor="let a of serviceTypeList; let i=index"
                           style="display: flex; flex-direction: column; width: 13em">
                        <div *ngIf="a.filterColumn==5 && a.filterGroup==2">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, a)"
                                        [checked]="a.selected" style="height:1.5em;  line-height:1.5em !important;"
                                        title="{{a.serviceTypeDescription}}">{{a.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between pt-2">
                    <div>
                      <button class="btn-low  mr-1" mat-button (click)="clickResetServiceType()">RESET</button>
                      <button class="btn-high " mat-button (click)="clickDoneServiceType()">DONE</button>
                    </div>
                    <div>
                      <button class="btn-med" mat-button (click)="clickSaveSelections('Service')">SAVE SELECTIONS</button>
                    </div>
                  </div>
                </div>
              </div>

            </div> -->
            <!--Service Filter End-->

            <!-- <div style=" margin-right:.25em; margin-left: 1em;">
              <button type="button" mat-button class="btn-low" style="min-width:8em;" [disabled]="disabled" *ngIf="false">Vendors</button>
            </div>
            <div style=" margin-right:1.25em; margin-left: 1em;">
              <button type="button" mat-button class="btn-low" style="min-width:8em;"
                      (click)="clickrecipientFilter()" *ngIf="false">
                recipients
              </button>
            </div> -->


            <div style=" display:flex;  margin-left:10em;">
              <mat-slide-toggle style="transform:scale(.8);font-size:medium" color="primary" [checked]="hidePastStop"
                                (change)="hidePastStopChange($event)">Hide Prior Legs</mat-slide-toggle>
              <mat-slide-toggle *ngIf="isButtonDisabled" style="margin-left: -1em; transform: scale(.8); font-size: medium" color="primary"
                                [checked]="includeActiveStopOnly" (change)="showActiveLegsOnly($event)">
                Show Active legs only
              </mat-slide-toggle>
              <mat-slide-toggle style="margin-left:-1em; transform:scale(.8);font-size:medium" color="primary" [checked]="includeDisabledTasks"
                                (change)="includeDisabledTasksChange($event)">Show Disabled Tasks</mat-slide-toggle>

            </div>

          </div>

          <!-- SECOND ROW OF BUTTONS -->

        </div>

      </div>
      <div *ngIf="routeLegList.length>0" style="font-size: small; margin-top:0em;">
        <div style="display: flex">
          <div style="margin-right: 0.25em; text-align: right; width:4.25em">Route:</div>
          <div>
            <div *ngIf="routeLegList.length>40" style="display: flex" class="divHover" (mouseover)="showWholeRoute=true" (mouseout)="showWholeRoute=!showWholeRoute">
              <div *ngFor="let gs of routeLegList; let i=index">
                <div *ngIf="i<=39" style="display: flex">
                  <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==routeLegList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i==40" style="display: flex">
                  <div [style.background-color]="gs.groundStopTypeColor">....</div>
                </div>
              </div>
            </div>
            <div *ngIf="routeLegList.length<=40" style="display: flex">
              <div *ngFor="let gs of routeLegList; let i=index">
                <div *ngIf="i<=39" style="display: flex">
                  <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==routeLegList.length-1? '': '-'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--  (click)="tabChanged($event)" -->
      <div style="display: flex;  margin-top: -1em; margin-left: 1em;">
        <div style="margin-top: 1.3em; width: 26%;" >
          <mat-tab-group (selectedTabChange)="tabChanged($event)"  [selectedIndex]=selectedValue  #tabGroup>

 
            <mat-tab>
              <ng-template mat-tab-label><div> Trip: {{tripCount}}</div>   </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label><div> Task: {{taskCount}} </div> </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label><div> All: {{bothCount}}   </div> </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label><div>Client: {{clientCount}}</div> </ng-template>
            </mat-tab>
          </mat-tab-group>

        </div>
        <div style="display: flex; justify-content: space-between; width: 73%;">
          <div style="display: flex;  margin-top: 1.5em; margin-left: 1em; " align="end">
            <div style="margin-top:.5em; display:flex;   font-size:small">
              <div style="margin-top: -0.2em;"><b>Message</b></div>
              <div style="display: flex; margin-left: 1em; ">
                <div style="margin-right:0.25em">
                  <input id="fBoth" type="radio" name="message_type" value="both" [checked]="inOutFiltervalue=='both'"
                         (change)="filterInboundOutbound($event)" />
                </div>
                <!-- <label style="margin-top:-0.2em;white-space: nowrap " for="fBoth">Both: {{resultsLength}}</label> -->
      
                <label style="margin-top:-0.2em;white-space: nowrap " for="fBoth">Both: {{InBoundRecords+OutBoundRecords}}</label>
              </div>
              <div style="display: flex; margin-left: 1em;">
                <div style="margin-right:0.25em">
                  <input id="fIn" type="radio" name="message_type" value="in" [checked]="inOutFiltervalue=='in'" (change)="filterInboundOutbound($event)" />
                </div>
                <label style="margin-top:-0.2em; white-space: nowrap" for="fIn">Inbound: {{InBoundRecords}}</label>
              </div>
              <div style="display: flex; margin-left: 1em;">
                <div style="margin-right:0.25em">
                  <input id="fOut" type="radio" name="message_type" value="out" [checked]="inOutFiltervalue=='out'" (change)="filterInboundOutbound($event)" />
                </div>
                <label style="margin-top:-0.2em;white-space: nowrap" for="fOut">Outbound: {{OutBoundRecords}}</label>
              </div>
            </div>
          </div>

          <div *ngIf="selectedLegIndex!=null" style="float: right;    margin-top: 1.4em;  ">
            <button  type="button" mat-button class="btn-low" (click)="clickReset()">
              Reset
            </button>
          </div>

          <div style="display: flex; margin-top: 1.7em; ">
            <div style="margin-top: -1em;">
              <i class="material-icons" style="font-size:xx-large; margin-top:0.25em"
                 (click)="getMessages()">refresh</i>
            </div>
            <mat-paginator align="end"
                           style=" display: flex; background-color: transparent; margin-top: -1.5em;  position: relative; right: 1%;" [pageSize]="50"
                           [showFirstLastButtons]="true" [length]="resultsLength" [pageSizeOptions]="[15,25, 50, 100]"
                           aria-label="Select page">
            </mat-paginator>
          </div>
        </div>

      </div>
      <!--FIRST TABLE-->

      <div style="display:flex;" class="float-container ">
        <div class="float-child table-container" style=" width: 27% !important; padding-left: .5em;">
          <table mat-table matSort matSortActive="created" matSortDisableClear matSortDirection="desc"
                 *ngIf="displayLinkList && isButtonDisabled" [dataSource]="displayLinkList">
            <tr>
              <ng-container matColumnDef="departure">
                <th *matHeaderCellDef mat-header-cell
                    style="text-align:left !important; padding-right: .5em !important; flex:0 0 4%;width:4%; padding-left: 1em !important;">
                  Dept
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-align:left !important; padding-right: .5em !important; flex:0 0 4%;width:4%; padding-left: 1em !important;">
                  {{element.departure}}
                </td>
              </ng-container>
              <ng-container matColumnDef="dep_date_time">
                <th *matHeaderCellDef mat-header-cell style=" padding-right: .5em !important; text-align:left !important;flex:0 0 6%;width:6%">
                  Date/time (z)
                  <mat-progress-bar mode="indeterminate" style="width:100%;margin-left:.5em;margin-top:.75em"
                                    color="primary" *ngIf="showspinleg"></mat-progress-bar>

                </th>

                <td mat-cell *matCellDef="let element"
                    style="text-align:left !important; padding-right: .5em !important; flex:0 0 6%;width:6%">
                  {{element.departureDateTime!=''?element.departureDateTime :'TBA'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="arrival">
                <th *matHeaderCellDef mat-header-cell style=" padding-right: .5em !important; text-align:left !important;flex:0 0 7%;width:4%">
                  Arrv
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-align:left !important; padding-right: .5em !important; flex:0 0 4%;width:4%">
                  {{element.arrival}}
                </td>
              </ng-container>
              <ng-container matColumnDef="arr_date_time">
                <th *matHeaderCellDef mat-header-cell style=" padding-right: .5em !important; text-align:left !important;flex:0 0 6%;width:6%">
                  Date/time (z)
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-align:left !important; padding-right: .5em !important; flex:0 0 6%;width:6%">
                  {{element.arrivalDateTime!=''?element.arrivalDateTime :'TBA'}}
                </td>
                <mat-progress-bar mode="indeterminate" style="width:30%;margin-left:.5em;margin-top:.75em" color="primary"
                                  *ngIf="showSpin3"></mat-progress-bar>

              </ng-container>
              <ng-container matColumnDef="messageCount">
                <th *matHeaderCellDef mat-header-cell style="text-align:left !important;flex:0 0 7%;width:4%">
                  Msgs
                </th>
                <td mat-cell *matCellDef="let element"
                    style="text-align:center !important;flex:0 0 4%;width:4%">
                  {{element.messageCount ? "x":""}}
                </td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn2;sticky:true"></tr>
            <tr style="cursor: pointer;" mat-row *matRowDef="let row;let i=index; columns: displayedColumn2;"
                [class.activeRow]="i==selectedLegIndex" [hidden]="hidePastStop?(row.arrivalDateTimePassed || isButtonEnabled):false || isButtonEnabled"
                [style.background-color]="row.colorCode??item" (click)="filterMessagesWithLegs(row,i)"></tr>
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          </table>
        </div>

        <!--SECOND TABLE-->

        <div class="float-child table-container" style="margin-left: 0.8em; width: 71% !important; max-height: 39em;">
          <table mat-table matSort matSortActive="created" matSortDisableClear matSortDirection="desc"
                 [dataSource]="messageList" style="max-height: 40em;">
            <ng-container matColumnDef="message_type">
              <th *matHeaderCellDef mat-header-cell style="text-align: left !important; flex: 0 0 4.5%; width: 4.5%; padding-right: 0.5em !important; ">
                Type
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important; flex: 0 0 4.5%; width: 4.5%; padding-right: 0.5em !important;">
                <div style="display: flex;"> 
                   {{element.isInBound?"In":"Out"}}
                   <i *ngIf="element.isFlagged" class="material-icons" style="font-size: small;  color: red;">flag</i>

                  </div>
              </td>
            </ng-container>


            <ng-container matColumnDef="date_time">
              <th *matHeaderCellDef mat-header-cell
                  style="text-align:left !important;flex:0 0 10%;width:10%; padding-right: .5em !important;">
                Date/Time
              </th>
              <td mat-cell *matCellDef="let element"
                style="text-align:left !important;flex:0 0 10%;width:10%; padding-right: .5em !important;"> 
                  {{element.sentDateTime}} 
              </td>
            </ng-container>
            <ng-container matColumnDef="services">
              <th mat-header-cell *matHeaderCellDef
                  style="text-align:left !important;flex:0 0 6%;width:6%; padding-right:  .5em !important;">
                Service
              </th>
              <td mat-cell *matCellDef="let element"
                  style="text-align:left !important;flex:0 0 6%;width:6%; padding-right: .5em !important;">
                <div *ngIf="element.tripCode=='' && (element.accountId=='' || element.accountId==null)"
                     style="cursor: pointer">
                  <i class="material-icons-outlined" (click)="linkMessage(element)"
                     style="font-size: x-large; margin-top:0em;color: #7dacff;">add_circle</i>
                </div>
                {{element.serviceName}}

              </td>
            </ng-container>

            <ng-container matColumnDef="sender">
              <th mat-header-cell *matHeaderCellDef
                  style="text-align:left !important;flex:0 0 15%;width:15%; padding-right: .5em !important;">
                Sender - Recipient
              </th>
              <td mat-cell style="justify-content:left !important;flex:0 0 15%;width:15%; padding-right:  .5em !important;"
                  *matCellDef="let element">
                <span class="ellipsis">
                  {{
                (element.sender.length>0 &&
                  element.sender.length>30 )? element.sender.substr(0,30) : element.sender
                }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef style="justify-content:left !important;flex:0 0 20%;width:20%; padding-right:  .5em !important;">
              Subject
            </th>
            <td mat-cell style="justify-content:left !important;flex:0 0 20%;width:20%; padding-right:  .5em !important;" *matCellDef="let element">
              <span class="ellipsis">
                {{
(element.subject != null &&
              element.subject.length>20 )? element.subject.substr(0,20) : element.subject
                  }}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="body">
              <th mat-header-cell *matHeaderCellDef
                  style="vertical-align: middle !important; justify-content: left !important; flex: 0 0 25%; width: 25%">
                <div class="d-flex">
                  Body
                  <mat-progress-bar mode="indeterminate" style="width:30%;margin-left:.5em;margin-top:.75em" color="primary"
                                    *ngIf="showSpin"></mat-progress-bar>
                </div>
              </th>
              <td mat-cell style="text-align:left !important;flex:0 0 25%;width:25%" *matCellDef="let element">
                <span class="ellipsis">
                  {{
                    element.bodyPreview== null ?
                        ((element.bodyTxt!=null && element.bodyTxt.length>35 )? element.bodyTxt.substr(0,35) : element.bodyTxt)
                        :( (element.bodyPreview!=null && element.bodyPreview.length>35 )? element.bodyPreview.substr(0,35) : element.bodyPreview)
                  }}
                </span>
              </td>
              <!--element.bodyPreview== null ? element.bodyTxt : element.bodyPreview-->
              <!--(element.bodyTxt!=null && element.bodyTxt.length>35 )? element.bodyTxt.substr(0,35) : element.bodyTxt-->
            </ng-container>
            <ng-container matColumnDef="has_attachments">
              <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 2%;width:2%"></th>
              <td mat-cell *matCellDef="let element; let i=index" style="text-align:center !important;flex:0 0 2%;width:2%">
                <span *ngIf=element.hasAttachments class="material-symbols-outlined"
                      style="transform:rotate(-45deg);font-size:medium">
                  attachment
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="action2">
              <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 3%;width:3%"></th>
              <td mat-cell *matCellDef="let element; let i=index" style="text-align:center !important;flex:0 0 3%;width:3%">
                <span class="material-icons-outlined" style="cursor:pointer;font-size:medium">
                  open_in_new
                </span>
              </td>

            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumn;sticky:true"></tr>
            <tr mat-row  [ngClass]="row.messageGUID == selectedMessageGUID ? 'active' : ''"  *matRowDef="let row; columns: displayedColumn;"
                (click)="openMessage(row,i)"></tr>
          </table>
        </div>
      </div>
    </div>

  </div>
</div>
