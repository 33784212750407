import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engineering-tickets',
  templateUrl: './engineering-tickets.component.html',
  styleUrls: ['./engineering-tickets.component.css']
})
/** airport-bi-dashboard component*/
export class EngineeringTicketsComponent implements OnInit{

  userType: string;
    constructor() {

  }

  ngOnInit() {
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
  }
}
