import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter, Optional } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { GroundStopAlternateAirportEditDialogComponent } from '../ground-stop-advanced/ground-stop-alternate-airport-edit-dialog.component';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService } from '../services/dialog.service';
import { FlightPlanPreferenceService } from '../services/flight-plan-preference.service';
import { FlightPlanPreferenceModel } from '../models/flight-plan-preference';
import { FlightPlanPreferenceEditComponent } from './flight-plan-preference-edit.component';
import { FlightPlanPreferenceAuditDialogComponent } from './flight-plan-preference-audit-dialog.component';

export class TableElement {
  legItem: TripLegModel;
  expanded: boolean;
  refreshChildRow: boolean;
  updateTask: boolean;
}

export interface AlertList {
  categoryName: string;
  alertObject: any;
}

@Component({
  selector: 'app-flight-plan-leglist',
  templateUrl: 'flight-plan-leglist.component.html',
  styleUrls: ['flight-plan-leglist.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  
})

export class FlightPlanLeglistComponent implements OnInit, OnChanges {

  @Input() requestInput: TripModel;
  @Input() reloadChildPage: boolean;
  @Input() selectedTabName: string;
  @Input() v: number;
  @Input() refreshChildColumn: string;
  @Input() updateGroundStopGUID: string;
  @Input() hidePastStop: boolean;
  request: TripModel;
  registration: string;
  tripCode: string;
  clientName: string;
  //customerGUID: string;
  flightplan_leglist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = false;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  legList: FlightPlanPreferenceModel[];
  @Output() public updateParentPage = new EventEmitter();

  displayedColumn: string[] = ['departure', 'arrival', 'crew', 'pax', 'flight_plan_preferences', 'action'];
  //displayedColumn2: string[] = ['expand_collapse'];
  scrHeight: number;
  upPII: boolean = false;
  gsId: string = "";
  gstId: string = "";
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";
  points: any = [];
  constructor(@Optional() private readonly _dialogRef: MatDialogRef<FlightPlanLeglistComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) private data: any,private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _dialog: MatDialog,
    private readonly _flightPlanPreferenceService: FlightPlanPreferenceService,
    private readonly _dialogService: DialogService
  ) {

  }

  ngOnInit() {
    if(this.data) {
      this.requestInput = this.data.request;
      this.hidePastStop = this.data.hidePastStop;
      this.registration = this.data.registration;
      this.tripCode = this.data.tripCode;
      this.clientName = this.data.clientName;
    }

    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;      
    }
    else {
      this.upPII = true;
    }
    
    this.request = (JSON.parse(JSON.stringify(this.requestInput)));
    if (this.request.groundStopGUID != null && this.request.groundStopGUID != undefined && this.request.groundStopGUID != "")
      this.gsId = this.request.groundStopGUID.toLowerCase();
    if (this.request.groundStopTaskGUID != null && this.request.groundStopTaskGUID != undefined && this.request.groundStopTaskGUID != "")
      this.gstId = this.request.groundStopTaskGUID.toLowerCase();

    this.initControls();
    this.getLegList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //if ((!this.reloadChildPage && this.refreshChildColumn == "") || this.selectedTabName != 'leg') {     
    //    return;    
    //}
    if (this.selectedTabName != 'flightPlan') {     
        return;    
    }   
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;      
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.request = (JSON.parse(JSON.stringify(this.requestInput)));
    if (!this.reloadChildPage && this.refreshChildColumn == "") {
    }
    else {
      if (this.refreshChildColumn == "") {
        this.showSpin = true;

        this.scrHeight = window.innerHeight;
        if (this.request.groundStopGUID != null && this.request.groundStopGUID != undefined && this.request.groundStopGUID != "")
          this.gsId = this.request.groundStopGUID.toLowerCase();
        if (this.request.groundStopTaskGUID != null && this.request.groundStopTaskGUID != undefined && this.request.groundStopTaskGUID != "")
          this.gstId = this.request.groundStopTaskGUID.toLowerCase();

        this.initControls();
        this.getLegList();
      }
    }

  }


  initControls() {
    this.flightplan_leglist_form = this._formBuilder.group({

    })
  }

  get f() { return this.flightplan_leglist_form.controls; }

  getLegList() {
   
    this.legList = [];
    
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.totalRecordsText = "";
    this.request.isFromLegEdit = false;
    this._flightPlanPreferenceService.getFlightPlanPreferenceBytId<ResponseModel<FlightPlanPreferenceModel[]>>(this.request.tripCodeGUID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.legList = response.model;
        }
        else {
          this.totalRecordsText = "No record found";

          
        }
        this.showSpin = false;
       
      }
      else {
        if (response.code == "401") {
         
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  addEditFPP(item: FlightPlanPreferenceModel) {
    const config = new MatDialogConfig();
    //config.panelClass = "custom-dialog-container"
    config.restoreFocus = false;
    config.data = { tripCodeGUID: this.request.tripCodeGUID, flightPlanPreference:item };
    this._dialog.open(FlightPlanPreferenceEditComponent, config).afterClosed().subscribe(result => {
      if (result)
        this.getLegList();
    });
  }

  clickEditAlternateAirport(event: any, item: FlightPlanPreferenceModel) {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    let leg: string = "";

    leg = item.departureICAO;
    if (item.nextArrivalICAO != "") {
      leg += "-" + item.nextArrivalICAO;
    }

    dialogConfig.data = {
      groundStopGUID: item.groundStopGUID, leg: leg, nextGroundStopGUID: item.nextGroundStopGUID,
      tripCodeGUID: this.request.tripCodeGUID, aircraftGUID: this.request.aircraftGUID, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(GroundStopAlternateAirportEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        this.getLegList();
      }
    });

  }

  clickAudit(event: any, item: FlightPlanPreferenceModel) {
    const config = new MatDialogConfig();
    //config.panelClass = "custom-dialog-container"
    config.restoreFocus = false;
    config.data = { flightPlanPreferenceID: item.flightPlanPreferenceID };
    this._dialog.open(FlightPlanPreferenceAuditDialogComponent, config);
  }

}
