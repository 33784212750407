<!--<div class="item">
  <img src="../../assets/images/Arrow2.png" (click)="menuSelected()" [ngClass]="{'arrow': !menu.expanded, 'arrowdown': menu.expanded}" style="cursor: pointer" />
  <a style="text-decoration: none; cursor: pointer" (click)="menuSelected()">{{menu.display}}</a>
  <ul [ngClass]="{'expanded': menu.expanded, 'contracted': !menu.expanded}">
    <li *ngFor="let mi of menu.items" class="menuItem">
      <a *ngIf="mi.route!=null" routerLink="{{mi.route}}">{{mi.display}}</a>
      <a *ngIf="mi.route==null"><app-menu-item [menu]="mi"></app-menu-item></a>
    </li>
  </ul>
</div>-->
<!--<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items">-->
<!-- Handle branch node menu items -->
<!--<span *ngIf="child.items && child.items.length > 0">
  <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu">
    {{child.display}}
  </button>
  <app-menu-item #menu [items]="child.items"></app-menu-item>
</span>-->
<!-- Handle leaf node menu items -->
<!--<span *ngIf="!child.items || child.items.length === 0">
      <button mat-menu-item (click)="clickRoute($event, child)">
       {{child.display}}
      </button>
    </span>
  </span>
</mat-menu>-->

<ng-template #menu>
  <div class="example-menu" cdkMenu>
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="hasChildren(item); else leafNode">
        <button class="example-menu-item" cdkMenuItem
                [cdkMenuTriggerFor]="subMenu"
                *ngIf="menuComponent.menu as subMenu"  (click)="clickRoute($event, child)">
          <div>{{ item.display }}</div>
          <div *ngIf="item.items">▸</div>
        </button>
        <app-menu-item #menuComponent [items]="item.items!"></app-menu-item>
      </ng-container>
      <ng-template #leafNode>
        <button class="example-menu-item"  (click)="clickRoute($event, child)" cdkMenuItem>
          {{ item.display }}
        </button>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
