import { Injectable, InjectionToken } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType, HttpClientModule, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { GlobalConstant } from "../common-utility/global-constant";
import { AuthenticateService } from "./authenticate.service";
import { AircraftTripModel } from "../models/aircraft-trip.model";
import { TripModel } from "../models/trip.model";
import { GroundStopModel } from "../models/ground-stop.model";
import { MessageTemplateModel } from "../models/message-template.model";
import { MessageTextModel } from "../models/message-text-model";
import { GroundStopManifestModel } from "../models/ground-stop-manifest.model";
import { DocumentModel } from "../models/document.model";
import { GroundStopTaskModel } from "../models/ground-stop-task.model";
import { FPCBillingSummaryModel } from "../models/fpc-billing-summary.model";
import { TripRevisionModel } from "../models/trip-revision.model";
import { TripLegModel } from "../models/trip-leg.model";
import { WeatherChartsModel } from "../models/weather-charts.model";
import { FlightBriefModel } from "../models/flight-brief.model";
import { TripCloseoutQueueModel } from "../models/trip-closeout-queue.model";
export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');


@Injectable()
export class GroundStopService {
  constructor(private readonly http: HttpClient, private readonly _authService: AuthenticateService) {

  }

  //getAircraftListWithTripByConditions<T>(request: TripModel): Observable<T> {
  //  //debugger;
  //  var headerOptions = new HttpHeaders({
  //    'Content-Type': 'application/json',
  //    'Authorization': 'bearer ' + localStorage.getItem('token'),
  //    'Cache-Control': 'no-cache, no-store, must-revalidate',
  //    //'Pragma': 'no-cache',
  //    'Expires': '-1'
  //  });

  //  return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getAircraftListWithTripByConditions", request, {
  //    headers: headerOptions
  //  }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  //}

  getGroundStopTypeList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getGroundStopTypeList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  createTrip<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/createTrip", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  //getTripListForTripCodeViewByConditions<T>(request: TripModel): Observable<T> {
  //  //debugger;
  //  var headerOptions = new HttpHeaders({
  //    'Content-Type': 'application/json',
  //    'Authorization': 'bearer ' + localStorage.getItem('token'),
  //    'Cache-Control': 'no-cache, no-store, must-revalidate',
  //    //'Pragma': 'no-cache',
  //    'Expires': '-1'
  //  });

  //  return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripListForTripCodeViewByConditions", request, {
  //    headers: headerOptions
  //  }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  //}

  getAircraftCustomerByByacIdcId<T>(request: AircraftTripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getAircraftCustomerByByacIdcId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  //getTripCodeListByacIdcId<T>(request: AircraftTripModel): Observable<T> {
  //  //debugger;
  //  var headerOptions = new HttpHeaders({
  //    'Content-Type': 'application/json',
  //    'Authorization': 'bearer ' + localStorage.getItem('token'),
  //    'Cache-Control': 'no-cache, no-store, must-revalidate',
  //    //'Pragma': 'no-cache',
  //    'Expires': '-1'
  //  });

  //  return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripCodeListByacIdcId", request, {
  //    headers: headerOptions
  //  }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  //}

  getTripTaskStatusList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getTripTaskStatusList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  //getTripBytcId<T>(request: TripModel): Observable<T> {
  //  //debugger;
  //  var headerOptions = new HttpHeaders({
  //    'Content-Type': 'application/json',
  //    'Authorization': 'bearer ' + localStorage.getItem('token'),
  //    'Cache-Control': 'no-cache, no-store, must-revalidate',
  //    //'Pragma': 'no-cache',
  //    'Expires': '-1'
  //  });

  //  return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripBytcId", request, {
  //    headers: headerOptions
  //  }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  //}

  getGroundStopBygsId<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getGroundStopBygsId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getGroundStopListBytId<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getGroundStopListBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getNextGroundStopBygsId<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getNextGroundStopBygsId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getArrivalDepartureStatusList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getArrivalDepartureStatusList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getFARTypeList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getFARTypeList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getCustomerCreditStatusList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getCustomerCreditStatusList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  updateGroundStops<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/updateGroundStops", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getGroundStopPairListBytId<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getGroundStopPairListBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getMessageTemplateList<T>(serviceTypeID: number): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getMessageTemplateList?serviceTypeID="+serviceTypeID, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  generateMessageTemplate<T>(request: MessageTextModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/generateMessageTemplate", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getVendorEmailsByTaskAndGroundStop<T>(request: MessageTextModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getVendorEmailsByTaskAndGroundStop", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  getTripCodeListBycId<T>(customerGuid: string, activeOnly:boolean=false): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getTripCodeListBycId?CustomerGuid=" + customerGuid + "&activeOnly="+activeOnly, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getServiceClassTaskStatusBygsId<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getServiceClassTaskStatusBygsId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getClientListWithTrip<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getClientListWithTrip", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripDocumentListByIds<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripDocumentListByIds", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripDocumentById<T>(request: DocumentModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripDocumentById", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  saveGroundStopBygsId<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/saveGroundStopBygsId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getGroundStopItineraryBygsId<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getGroundStopItineraryBygsId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  //getGroundStopListByConditions2<T>(request: TripModel): Observable<T> {
  //  //debugger;
  //  var headerOptions = new HttpHeaders({
  //    'Content-Type': 'application/json',
  //    'Authorization': 'bearer ' + localStorage.getItem('token'),
  //    'Cache-Control': 'no-cache, no-store, must-revalidate',
  //    //'Pragma': 'no-cache',
  //    'Expires': '-1'
  //  });

  //  return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getGroundStopListByConditions2", request, {
  //    headers: headerOptions
  //  }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  //}

  getTripForTripSetupBytId<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripForTripSetupBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripCloseOutList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getTripCloseOutList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  updateTripSetupBytId<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/updateTripSetupBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getCustomerAccountingProfileByTrip<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getCustomerAccountingProfileByTrip", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getLockdownTripBytId<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getLockdownTripBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getFPCBillingSummaryBytId<T>(request: FPCBillingSummaryModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getFPCBillingSummaryBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getGroundStopAlternateAirportBygsId<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getGroundStopAlternateAirportBygsId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  checkTripCode<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/checkTripCode", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripRevisionUsernameListBytIdHours<T>(request: TripRevisionModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripRevisionUsernameListBytIdHours", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripRevisionBytIdHours<T>(request: TripRevisionModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripRevisionBytIdHours", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  updateTripRevisionUserCommentsBytrId<T>(request: TripRevisionModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/updateTripRevisionUserCommentsBytrId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  //addToTripRevision<T>(request: TripRevisionModel): Observable<T> {
  //  //debugger;
  //  var headerOptions = new HttpHeaders({
  //    'Content-Type': 'application/json',
  //    'Authorization': 'bearer ' + localStorage.getItem('token'),
  //    'Cache-Control': 'no-cache, no-store, must-revalidate',
  //    //'Pragma': 'no-cache',
  //    'Expires': '-1'
  //  });

  //  return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/addToTripRevision", request, {
  //    headers: headerOptions
  //  }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  //}

  getTripRevisionUserNameListBygIdListHours<T>(request: TripRevisionModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripRevisionUserNameListBygIdListHours", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getPointOfContactListBygsId<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getPointOfContactListBygsId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  //getIncommingTripLegByHourcId<T>(request: TripLegModel): Observable<T> {
  //  //debugger;
  //  var headerOptions = new HttpHeaders({
  //    'Content-Type': 'application/json',
  //    'Authorization': 'bearer ' + localStorage.getItem('token'),
  //    'Cache-Control': 'no-cache, no-store, must-revalidate',
  //    //'Pragma': 'no-cache',
  //    'Expires': '-1'
  //  });

  //  return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getIncommingTripLegByHourcId", request, {
  //    headers: headerOptions
  //  }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  //}

  getGroundStopTaskListByscIdgsIdList<T>(request: GroundStopTaskModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getGroundStopTaskListByscIdgsIdList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getAllCrewPaxListBygsIdList<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getAllCrewPaxListBygsIdList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripRevisionByHours<T>(request: TripRevisionModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripRevisionByHours", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getGroundStopListForFlightBrief<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getGroundStopListForFlightBrief", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getWeatherChartLinks<T>(groundStopTaskGuid: string): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getWeatherChartLinks?groundStopTaskGuid=" + groundStopTaskGuid,  {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  updateAircraftBytId<T>(request: TripRevisionModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/updateAircraftBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getFlightBriefSectionsBygstId<T>(groundStopTaskGuid: string): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getFlightBriefSectionsBygstId?groundStopTaskGuid=" + groundStopTaskGuid, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getSSNVendorIDbygsID<T>(groundStopGUID: string): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getSSNVendorIDbygsID?groundStopGUID=" + groundStopGUID, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripScheduleList<T>(request: TripLegModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripScheduleList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getNewTripList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getNewTripList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripGroundStopBytcId<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripGroundStopBytcId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripCodeListForInternalByacIdcId<T>(request: AircraftTripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripCodeListForInternalByacIdcId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getUpcomingTripCloseoutList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getUpcomingTripCloseoutList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }
  
  getUpcomingTripCloseoutListDashboard<T>(request: TripCloseoutQueueModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getUpcomingTripCloseoutListDashboard", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getGroundStopListForInternalByConditions<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getGroundStopListForInternalByConditions", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripClientListByDateRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripClientListByDateRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripRegistrationListByDateRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripRegistrationListByDateRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripAirportListByDateRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripAirportListByDateRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripPersonListByDateRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripPersonListByDateRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripVendorListByDateRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripVendorListByDateRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripListForTripCodeViewInternalByConditions<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripListForTripCodeViewInternalByConditions", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getAircraftListWithTripInternalByConditions<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getAircraftListWithTripInternalByConditions", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripCodeListByDateRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripCodeListByDateRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getFlightBriefTaskNoteBygstId<T>(request: FlightBriefModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getFlightBriefTaskNoteBygstId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getPrelimTaskNoteBygstId<T>(request: FlightBriefModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getPrelimTaskNoteBygstId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  updateTripNotesBytId<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/updateTripNotesBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripListByTripCodes<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripListByTripCodes", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  updateTripDefaultEmailBytId<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/updateTripDefaultEmailBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripDocumentListBytId<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getTripDocumentListBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  uncancelGroundStopBygsId<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/uncancelGroundStopBygsId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  cancelGroundStopBygsId<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/cancelGroundStopBygsId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }


  getGroundStopList<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/getGroundStopList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getAllBillableTasksByServiceClassID<T>(serviceClassID: number): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getAllBillableTasksByServiceClassID?serviceClassID=" + serviceClassID, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getAllRoutesforCityPair<T>(request: string[]): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getAllRoutesforCityPair?departureICAO=" + request[0] + "&arrivalICAO=" + request[1], {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  getAllServiceTasksForCityPair<T>(request: string[]): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "GroundStop/getAllServiceTasksForCityPair?groundStopGUID=" + request[0] 
      + "&nextGroundStopGUID=" + request[1] + "&tripCode=" + request[2], {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  updateTaskStatusByCountryGroup<T>(request: GroundStopTaskModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "GroundStop/updateTaskStatusByCountryGroup", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }
}
