import { Component, EventEmitter, Inject, Injectable, Input, OnInit, Output } from '@angular/core';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { GenDecModel } from '../models/gendec.model';
import { ResponseModel } from '../models/response.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { AuthenticateService } from '../services/authenticate.service';
import { UploadTaskDocument } from './upload-task-document';
import { saveAs } from 'file-saver';
export interface BlankData {
  line: string;
}
function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

const blanks: BlankData[] = [
  { line: "" }
];

@Component({
  selector: 'app-generate-task-documents',
  templateUrl: './generate-task-documents.component.html',
  styleUrls: ['./generate-task-documents.component.css']
})
/** generate-task-documents component*/
export class GenerateTaskDocumentsComponent extends UploadTaskDocument implements OnInit {
  @Input() groundStopGUID: string;
  @Input() tripCodeGUID: string;
  @Input() isDeparture: boolean;
  @Input() groundStopTaskGUID: string;
  @Input() country: string;
  @Input() icao: string;
  generate_task_documents_form: UntypedFormGroup;
  fillNum: number;
  templateName: string;
  includePII: string = "1";
  loading: boolean = false;
  format: string = "pdf";
  selectedTemplate: string = "gendec";

  /** generate-task-documents ctor */
  constructor(private readonly _groundStopAdvanceService: GroundStopAdvancedService, private readonly _authService: AuthenticateService, private readonly _dialogRef: MatDialogRef<GenerateTaskDocumentsComponent>,
    private readonly _formBuilder: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) private _data: any, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super();
    this.groundStopGUID = _data.groundStopGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.isDeparture = _data.isDeparture;
    this.groundStopTaskGUID = _data.groundStopTaskGUID;
    this.country = _data.country;
    this.icao = _data.icao;

  }


  ngOnInit() {
    this._authService.updateAccessTime();
    if (this.country == "SG")
      this.selectedTemplate = "gendec-s";
    if (this.icao == "LFPB" || this.icao == "LFPG" || this.icao == "LFPO")
      this.selectedTemplate = "gendec-p";
    this.initControls();
  }


  initControls() {
    this.generate_task_documents_form = this._formBuilder.group({
      template_select: this.selectedTemplate
    })
  }

  get f() { return this.generate_task_documents_form.controls; }


  generateDoc() {
    this.loading = true;
    let request = new GenDecModel();
    let gstId = this.groundStopTaskGUID;
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    request.isDeparture = this.isDeparture;
    if (this.includePII == "1")
      request.includePII = true;
    else
      request.includePII = false;
    switch (this.f.template_select.value) {
      case "gendec":
        this.fillNum = 5;
        this.templateName = "GenDec.docx";
        break;
      case "cbp":
        this.fillNum = 6;
        this.templateName = "CBPForm7507.docx";
        break;
      case "gendec-s":
        this.templateName = "SingaporeGenDec.docx";
        this.fillNum = 1;
        break;
      case "gendec-p":
        this.templateName = "ParisGenDec.docx";
        this.fillNum = 5;
        break;
    }

    const fileName = this.templateName;
    const gst = this._groundStopAdvanceService;
    const dialog = this._dialogRef;
    const format = this.format;

    this._groundStopAdvanceService.getDataForGenDec<ResponseModel<GenDecModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        let genDec = new GenDecModel();
        genDec = response.model;

        let blankLines: BlankData[] = [
          { line: "" }
        ];
        let len = genDec.crewManifest.length;
        if (this.f.template_select.value == "gendec-s")
          len = genDec.paxManifest.length;

        for (let i = 0; i < this.fillNum - len; i++) {
          blankLines = blankLines.concat(blanks);
        }

        let filePath = window.origin + "/assets/templates/";
        //let filePath = "https://localhost:5001/assets/templates/";
        let self = this;
        loadFile(filePath + fileName, function (
          error,
          content
        ) {
          if (error) {
            throw error;
          }
          const zip = new PizZip(content);
          const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });

          doc.setData({
            clientName: genDec.customerName,
            registration: genDec.registration,
            depICAO: genDec.departureICAO,
            arrICAO: genDec.arrivalICAO,
            flightDate: genDec.flightDate,
            callSign: genDec.callSign,
            crew: genDec.crewManifest,
            pax: genDec.paxManifest,
            blanks: blankLines,
            acType: genDec.aircraftType
          });
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            function replaceErrors(key, value) {
              if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function (
                  error,
                  key
                ) {
                  error[key] = value[key];
                  return error;
                },
                  {});
              }
              return value;
            }
            console.log(JSON.stringify({ error: error }, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join("\n");
              //console.log("errorMessages", errorMessages);
              // errorMessages is a humanly readable message looking like this:
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }
          const out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          });

          let uri;
          //if (window.origin.indexOf('dev') > -1 || window.origin.indexOf('localhost') > -1)
          //  uri = 'https://fpipdfconversion-dev.azurewebsites.net/api/ConvertToPdf'
          //else
          uri = 'https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf'
          const functionURI = uri;

          let headers = new HttpHeaders();
          const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
          headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
          var documentName: string;
          if (fileName == "GenDec.docx" || fileName == "SingaporeGenDec.docx" || fileName == "ParisGenDec.docx") {
            if (self.isDeparture)
              documentName = "DEP GENDEC-";
            else
              documentName = "ARR GENDEC-";
          }
          else {
            documentName = "CBP7507-"
          }
          documentName = documentName + genDec.registration + "-" + genDec.departureICAO + "-" + genDec.arrivalICAO;

          if (format == "word") {
            documentName += ".docx"
            saveAs(out, documentName);
            dialog.close(false);
          }
          else {

            httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {

              let upload = new UploadTaskDocument();
              documentName += ".pdf"
              upload.uploadFile(data.body, gstId, documentName, gst, dialog, genDec.tripCode);

            });
          }
        });
      }
    });

  }

  changePII(event: any) {
    this.includePII = event.value;
  }

  changeFormat(event: any) {
    this.format = event.value;
  }


}







