import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef,MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CountryModel } from '../models/country.model';
import { CountryDocumentsEditDialogComponent } from './country-documents-edit-dialog.component'; 
import { CountryDocumentModel } from '../models/country-document.model';
import { CommonService } from '../services/common.service';
import { ResponseModel } from '../models/response.model';
import { DocumentTypeModel } from '../models/document-type.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CountryService } from '../services/country.service';
import { CountryContentModel } from '../models/country-content.model';
import { UtilityFunctions } from '../common-utility/utility.functions'; 
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-country-notes-and-documents-edit-dialog',
  templateUrl: './country-notes-and-documents-edit-dialog.component.html',
  styleUrls: ['./country-notes-and-documents-edit-dialog.component.css']
})

export class CountryNotesAndDocumentsEditDialogComponent implements OnInit {
 
  countryData: CountryModel = new CountryModel();
  isLoading: boolean = false;
  // v: number = this._authService.getCurrentTimeNumber();
  documentName: string;
  countryID: number;
  countryDocuments: CountryDocumentModel[];
  documentTypeList: DocumentTypeModel[];
  internalNotes:string;
  isModified: boolean = false;
  contentId: number = -1;
  userType: string;

  constructor(private readonly _dialogRef: MatDialogRef<CountryNotesAndDocumentsEditDialogComponent>,private readonly _formBuilder: UntypedFormBuilder ,
     @Inject(MAT_DIALOG_DATA) _data: any, private readonly _dialog: MatDialog,private readonly _commonService: CommonService, private readonly _authService: AuthenticateService,
     private readonly _countryService: CountryService,
    ) {
      
      this.countryData = _data.countryData;
      this.documentName = _data.documentName;
      this.countryID = _data.countryID;
      this.internalNotes = _data.internalNotes;

    if (_data.countryContent)
      {
        this.contentId = _data.countryContent.countryContentID;
        this.internalNotes = _data.countryContent.countryContentDescription;
        this.userType = _data.userType;
      }
      // this.userComments = _data.userComments;
  }

  ngOnInit() {
    this.getData();
    this.countryDocuments = this.countryData.documentList;

   }

 


  getData() {
    this.isLoading = true;
    this._commonService.getCountryDetailsByCountryId<ResponseModel<CountryModel>>(this.countryID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.countryData = response.model; 
           this.countryDocuments = this.countryData.documentList;
          this.isLoading = false;
 
         }
      } 
    });  
 
    
  }
   
 
  clickClose(val) {
    this._dialogRef.close(''); 
  }

 
  clickAdd(){ 
    this._dialogRef.close(this.internalNotes); 


  }

 
}











