<div *ngIf="upPII" style="background-color:#f8f8f8;min-height:10em;min-width: 30em">
  <div style="padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div class="d-flex">
      <div>
        <i *ngIf="insertType=='between'" class="material-icons-outlined" style="font-size: xx-large; color: gray; margin-top:0.1em">add_location</i>
        <i *ngIf="insertType=='before'" class="material-icons-outlined" style="font-size: xx-large; color: gray; margin-top: 0.1em; transform: rotate(180deg)">low_priority</i>
        <i *ngIf="insertType=='after'" class="material-icons-outlined" style="font-size: xx-large; color: gray; margin-top:0.1em">low_priority</i>
      </div>
      <div style="margin-top:0.3125em;"><h5>Insert a Trip Leg</h5></div>
    </div>
    <div style="font-size:small"><span *ngIf="insertType!='after'">Original Leg </span><span *ngIf="insertType=='after'">Last Leg </span>{{currentLeg.departureICAO}} - {{currentLeg.nextArrivalICAO}}</div>
    <!--<div *ngIf="insertType=='after'" style="font-size:small">Last Leg {{currentLeg.departureICAO}} - {{currentLeg.nextArrivalICAO}}</div>-->
  </div>
  <div>
    <form *ngIf="leg_insert_form" [formGroup]="leg_insert_form">
      <div style="padding-left:1em; padding-right:1em; ">
        <div style="background-color: white; padding-left:0.5em; padding-bottom:0.5em">
          <div style="font-size: small">
            <div class="row">
              <div class="col"></div>
              <div class="col">Departure</div>
              <div class="col">Arrival</div>
            </div>
            <div *ngIf="insertType!='before'" class="row">
              <div class="col">Prior Leg</div>
              <div class="col">{{currentLeg.departureICAO}}</div>
              <div class="col">{{insertType=='after'? currentLeg.nextArrivalICAO : newICAO}}</div>
            </div>
            <div *ngIf="insertType!='after'" class="row">
              <div class="col">Inserted Leg</div>
              <div class="col"><input type="text" #insertedLegDepICAO formControlName="insertedLegDepICAO" class="form-control" [ngClass]="{ 'is-invalid': validatedAirport && f.insertedLegDepICAO.errors }" style="padding-left: 0.25em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" maxlength="4" (change)="icaoChange($event,false)" (keyup)="icaoKeyDown($event)" /></div>
              <div class="col">{{insertType=='between' ? currentLeg.nextArrivalICAO : currentLeg.departureICAO}}</div>
            </div>
            <div *ngIf="insertType=='after'" class="row">
              <div class="col">Inserted Leg</div>
              <div class="col">{{currentLeg.nextArrivalICAO}}</div>
              <div class="col"><input type="text" #insertedLegDepICAO formControlName="insertedLegDepICAO" class="form-control" [ngClass]="{ 'is-invalid': validatedAirport && f.insertedLegDepICAO.errors }" style="padding-left: 0.25em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" maxlength="4" (change)="icaoChange($event,false)" (keyup)="icaoKeyDown($event)" /></div>

            </div>
            <div *ngIf="nextLeg && insertType!='before'" class="row">
              <div class="col">Next Leg</div>
              <div class="col">{{nextLeg.departureICAO}}</div>
              <div class="col">{{nextLeg.nextArrivalICAO}}</div>
            </div>
            <div *ngIf="insertType=='before'" class="row">
              <div class="col">Next Leg</div>
              <div class="col">{{currentLeg.departureICAO}}</div>
              <div class="col">{{currentLeg.nextArrivalICAO}}</div>
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>
  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; ">
    <div style="margin-right:1em;">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <button type="button" mat-button class="btn-high" *ngIf="loading==false && showSuccessMsg==false && !showSpin" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i><div>SAVE</div></div></button>&nbsp;
      <button type="button" mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>CLOSE</div></div></button>
    </div>
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
    <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0.0em; background-color:#f9d2de; padding-left:0.25em; min-width:22em; white-space:pre-wrap"><label style="color:black; font-size: small">{{errMsg}}</label></div>
  </div>
</div>
