<div style="margin-top:0em; padding-top:0em; display:flex">
  <form *ngIf="vendor_person_commlist_form" [formGroup]="vendor_person_commlist_form">
    <div>
      
      <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container">
        <tr>
          <ng-container matColumnDef="addressUse">
            <th mat-header-cell *matHeaderCellDef style="width:20%">
              <div style="margin-top:-0.25em;">Address Use</div>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.addressUseDescriptionList}}</td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef style="width:25%">
              <div style="margin-top:-0.25em;">Address</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.address}}</td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef style="width:10%">
              <div style="margin-top:-0.25em;">Type</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.commAddressType}}</td>
          </ng-container>
          <ng-container matColumnDef="preferred">
            <th mat-header-cell *matHeaderCellDef style="width:5%">
              <div style="margin-top:-0.25em;">Preferred</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.isPreferred? 'Yes':'&nbsp;'}}</td>
          </ng-container>
          <!--<ng-container matColumnDef="rating">
    <th mat-header-cell *matHeaderCellDef style="width:10%">
      <div style="margin-top:-0.25em;">Rating</div>
    </th>
    <td mat-cell *matCellDef="let element">{{element.addressRatingDescription}}</td>
  </ng-container>-->
          <ng-container matColumnDef="remark">
            <th mat-header-cell *matHeaderCellDef style="width:15%">
              <div style="margin-top:-0.25em;">Remark</div>
            </th>
            <td mat-cell *matCellDef="let element"><a *ngIf="element.commRemarks.length>15" style="cursor:pointer" matTooltipClass="tooltipLineBreak" matTooltip="{{element.commRemarks}}">{{ element.commRemarks.substring(0,14)+'...' }}</a><span *ngIf="element.commRemarks.length<=15">{{element.commRemarks}}</span></td>
          </ng-container>
          <ng-container matColumnDef="include_in_brief">
            <th mat-header-cell *matHeaderCellDef style="width:10%">
              <div style="margin-top:-0.25em;">Include In Brief</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.includeInBrief? 'Yes': '&nbsp;'}}</td>
          </ng-container>
          <ng-container matColumnDef="disabled">
            <th mat-header-cell *matHeaderCellDef style="width:7%">
              <div style="margin-top:-0.25em;">Disabled</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.isActive? '&nbsp;': 'Yes'}}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width:7%">
              <div>Action</div><div style="margin-top:0em;padding-bottom:0.25em"><a mat-button class="btn-high grid" style="align-self:center" (click)="clickEdit($event, null);">Add</a></div>
            </th>
            <td mat-cell *matCellDef="let element">
              <a mat-button class="btn-med grid" style="align-self:center" (click)="clickEdit($event, element);">
                Edit
              </a>
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
    </div>
    <div>
      <mat-paginator *ngIf="recordList && totalPageNumber>1" style="border: none;width:80em;" #paginator [length]="totalRecords" [pageSize]="pageSize"
                     [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
      </mat-paginator>
    </div>
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
