import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css']
})
/** contact-us component*/


export class ContactUsComponent{
  isInternal: boolean=true;
    /** contact-us ctor */
  constructor(@Inject(MAT_DIALOG_DATA) private _data: any) {
    
    this.isInternal = _data.isInternal;  

  }
}
