export class GroundStopManifestDocumentModel {
  personTravelDocumentGUID: string;
  personGUID: string;
  documentTypeDescription: string;
  documentNumber: string; 
  documentIssuingCountryName: string;
  //iSO2Letter: string;
  documentDOI: string;
  documentDOE: string;
  selected: boolean;
  selectedPersonTravelDocumentGUIDList: string;
  isExpiredForGroundStop: boolean;
  legType: string;
  isExpired: boolean;
  isO2Letter: string;
  disabled: boolean;
  dataEntryStatusID: number;
  dataEntryStatusDescription: string;
  documentTypeGUID: string;

  documentServiceLocation: string;
}
