export class FuelPricingModel {
  fuelPricingID: number;
  countryName: string;
  airportID: number;
  icao: string;
  lk_FuelSupplierGUID: string;
  fuelSupplierName: string;
  intoPlaneAgent: string;
  effectiveDate: string;
  expiryDate: string;
  minQty: number;
  maxQty: number;

  qty: string;

  lk_UnitOfMeasureID: number;
  lk_CurrencyID: number;
  baseFuel: number;
  taxesIncluded: boolean;
  internalNotes: string;
  clientNotes: string;
  isArchivedFlag: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;


  //effectiveDate: string;
  expirationDate: string;
  selectedICAOList: string;
  selectedCountryGUIDList: string;
  selectedCountryIDList: string;
  selectedFuelSupplierList: string;
  selectedIntoPlaneAgentList: string;
  selectedFuelPriceCurrencyList:string;


  //countryName: string;
 // airportName: string;
  fuelSuppliershortName: string;
  unitOfMeasure: string;
  currencyDescription: string;
  currencyID: string;


  pageIndex: number;
  pageSize: number;
  totalRecords: number;

  selected: boolean;
  isExpired: boolean;
  isNoPrice: boolean;
  countryGUID: string;
}
