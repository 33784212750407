
export class MissingTaxesAirportModel {
  countryName: string;
  icao: string;
  iata: string;
  airportId: number;
  pageSize: number;
  pageIndex: number;
  fuelReleaseCount: number;
  fuelQuoteCount: number;
  registrationCount: number;
  totalRecords: number;
  includeCountryAll:boolean;
  customerName:string;
  registration:string;
  tripCode:string;
  arrivalDateUTC:string;
  dayFilter: number;
  fuelSupplierName:string;
  intoPlaneAgent:string;
  groundStopTaskDocumentGUID: string;
  hasQuotes: boolean;
}

