import { Injectable } from "@angular/core";
import { Observable, throwError, of } from "rxjs";
import { Routes, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { MenuModel } from "../models/menu.model";
import { ResponseModel } from '../models/response.model';
import { GlobalConstant } from "../common-utility/global-constant";
import { catchError, retry } from "rxjs/operators";
import { AuthenticateService } from "./authenticate.service";


@Injectable()
export class MenuService implements CanActivate {
  menus: MenuModel[];

  constructor(private readonly http: HttpClient, private readonly _authService: AuthenticateService) {
  }

  getUserMenus(): Observable<MenuModel[]> {
    return new Observable<MenuModel[]>(ob => {
      this.getUserMenusEndPoint<ResponseModel<MenuModel[]>>().subscribe(response => {
        if (response != null) {
          if (response.code == "200") {
            this.menus = response.model;
            ob.next(this.menus);
            this._authService.setupRefreshInterval();
            //this._authService.refreshToken();
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          }
        }
      });
    });
  }

  getUserMenusEndPoint<T>(): Observable<T> {
    
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token')
    });
    //console.log(localStorage.getItem('token'));
    return this.http.get<T>(GlobalConstant.baseUrl + "Common/getUserMenus", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    var url = "/" + route.url.map(u => u.path).join("/");
    for (var i = 0; i < this.menus.length; i++) {
      var f = this.isRouteMatched(this.menus[i], url);
      if (f) {
        return true;
      }
    }

    return false;
  }

  isRouteMatched(menu: MenuModel, url: string): boolean {
    for (var i = 0; i < menu.items.length; i++) {
      if (menu.items[i].route!=null && menu.items[i].route == url) {
        return true;
      }
      if (menu.items[i].route == null) {
        var f = this.isRouteMatched(menu.items[i], url);
        if (f) {
          return true;
        }
      }
    }
    return false;
  }

  getWebsiteEnviroment<T>(): Observable<T> {

    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token')
    });
    //console.log(localStorage.getItem('token'));
    return this.http.get<T>(GlobalConstant.baseUrl + "Identity/getWebsiteEnviroment?v="+(new Date()).getTime(), {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

}
