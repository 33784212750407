<div style="width: 100%; overflow:hidden"
     [cdkDragDisabled]="isOpenedNew" cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle [ngStyle]="{'padding': isOpenedNew ? '0.5em' : '0em'}">
  <div class="d-flex justify-content-between">
    <div class="d-inline-flex" [ngStyle]="{'margin-top': isOpenedNew ? '0.5em' : '0em'}">
      <div class="material-icons-outlined mr-1">airplane_ticket</div>
      <h5>Trip Planner</h5>
    </div>
    <div class="d-inline-flex" *ngIf="!isOpenedNew">
      <div style="margin-top:-.25em;margin-right:1em">
        <app-spin *ngIf="showSpin"></app-spin>
      </div>
      <!--<span *ngIf="!fromTrip" class="material-icons-outlined pointer" (click)="openGlobalSettingsDashboardComponent()" style="margin-top:0em;margin-right:.5em">arrow_circle_left</span>
      <mat-icon *ngIf="!fromTrip" (click)="openInNew()" style="margin-right:.5em;cursor:pointer">open_in_new</mat-icon>-->
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>

  <div class="d-inline-flex justify-content-start" style="width:100%">
    
      <div class="form-group mr-2" [ngStyle]="{'width': fromTrip ? '100%' : ''}">
        <label for="text_search" style="font-size:small">Enter ICAO, IATA, Airport Name, or City</label>
        <div class="input-group searchbar" style="margin-top:-.3em; height: 1.5625em; background-color: #eaf0fd;"  [ngStyle]="{'width': fromTrip ? '71%' : '30em'}">
          <div>
            <span style="font-size: x-small; background-color: #eaf0fd;line-height:1;" class="input-group-text material-icons-outlined searchbarleft pointer" (click)="search('airport')"
                  cdkOverlayOrigin #trigger="cdkOverlayOrigin">search</span>
          </div>
          <!--<div class="input-group-prepend searchbarleft">
        <span class="input-group-text material-icons-outlined searchbarleft">search</span>
      </div>-->
          <input type="text" [(ngModel)]="airportList" class="form-control form-control-sm searchbarinput"
                 (keyup.enter)="search('airport')" (mousedown)="$event.stopPropagation()">

          <div>
            <span style="font-size: x-small; background-color: #eaf0fd;line-height:1;" class="input-group-text material-icons-outlined searchbarright pointer" (click)="clickReset()">close</span>
          </div>
        </div>
      </div>
      <div *ngIf="!fromTrip" class="d-inline-flex">
        <mat-divider vertical class="mr-2 mat-divider-orange"></mat-divider>
        <div class="form-group mr-2">
          <label style="font-size:small">FAR Type</label>
          <select [(ngModel)]="farTypeID" style="margin-top:-.35em" class="form-control form-control-sm dropdown" (change)="getAirportData()" (mousedown)="$event.stopPropagation()">
            <option *ngFor="let far of farTypeList" [ngValue]="far.farTypeID">
              {{far.farTypeDescription}}
            </option>
          </select>
        </div>
        <mat-divider *ngIf="userType=='i'" vertical class="mr-2 mat-divider-orange"></mat-divider>
        <div *ngIf="userType=='i'" class="form-group mr-2">
          <label style="font-size:small">Client Account</label>
          <select [(ngModel)]="customerGUID" style="margin-top:-.35em" class="form-control form-control-sm dropdown" (change)="getTripList()" (mousedown)="$event.stopPropagation()">
            <option value="" selected>-------</option>
            <option *ngFor="let c of customerList" [ngValue]="c.customerGUID">
              {{c.customerName}}
            </option>
          </select>
        </div>
        <div *ngIf="userType=='i'" class="form-group mr-2">
          <label for="trip_Code" style="font-size:small">Trip Code</label>
          <select [(ngModel)]="tripCodeGUID" style="margin-top:-.35em;width:7em" class="form-control form-control-sm dropdown" (change)="search('trip')">
            <option value="" selected>-------</option>
            <option *ngFor="let trip of tripCodeList" [ngValue]="trip.tripCodeGUID">{{trip.tripCode}}</option>
          </select>
        </div>
        <mat-divider *ngIf="userType=='i'" vertical class="mr-2 mat-divider-orange"></mat-divider>
        <div *ngIf="userType=='i'" class="form-group mr-2">
          <label style="font-size:small">Upcoming Trips</label>
          <select [(ngModel)]="daysOut" style="margin-top:-.35em" class="form-control form-control-sm dropdown" (change)="search('daysout')" (mousedown)="$event.stopPropagation()">
            <option value="0">-------</option>
            <option value="7">7 Days Out</option>
            <option value="14">14 Days Out</option>
            <option value="30">30 Days Out</option>
          </select>
        </div>
        <mat-divider vertical class="mr-2 mat-divider-orange"></mat-divider>
        <div class="form-group mr-2">
          <label style="font-size:small">Country</label><span style="font-size:x-small;margin-left:2em"> *Runways >5000 ft</span>
          <select [(ngModel)]="countryID" style="margin-top:-.35em" class="form-control form-control-sm dropdown" (change)="search('country')" (mousedown)="$event.stopPropagation()">
            <option value="0">-------</option>
            <option *ngFor="let country of countryList" [ngValue]="country.countryID">
              {{country.countryName}}
            </option>
          </select>
        </div>
      </div>
      <div *ngIf="isOpenedNew" style="margin-top:1em">
        <app-spin *ngIf="showSpin"></app-spin>
      </div>
   
  </div>
  
  <div>
    <div>
      <div class="d-inline-flex" style="width:100%">
        <div style="height: 1em; width: 71%">
          <div *ngIf="airports" class="d-inline-flex justify-content-between px-2" style="width:100%">
            <div class="d-inline-flex"><span style="font-size: medium; margin-top: .1em " class="material-icons-two-tone yellow">grade</span><div style="font-size:small">FPI Preferred Handler/SSN</div><!--<span style="font-size: medium; margin-top: .1em;margin-left:.5em " class="material-icons-two-tone blue">grade</span><div style="font-size:small">Client Preferred Handler/SSN</div>--></div>
            <div style="font-size:small;margin-right:1em">
              Total Records: {{airports.length}}
            </div>
          </div>
        </div>
        <div *ngIf="airports && airports.length>1 && daysOut==0 && countryID==0  && userType=='i'"  style="width:29%">
          <mat-slide-toggle style="transform:scale(.8)" color="primary" [(ngModel)]="showRoute" [ngModelOptions]="{standalone: true}" (change)="createMap()">Show Route</mat-slide-toggle>
        </div>
      </div>
    </div>
      <div class="d-inline-flex p-2" style="background-color:white;height:30em;width:100%">

        <div style="height:29em;width:71%;overflow:auto">
          <table mat-table [dataSource]="airports" class="table">
            <tr>
              <ng-container matColumnDef="icao">
                <th mat-header-cell *matHeaderCellDef width="5%">
                  ICAO
                </th>
                <td (click)="openAirportBrief(element)" style="flex: 0 0 4" mat-cell *matCellDef="let element" [ngClass]="{ 'alert-danger' : element.airportID==0 }">{{element.icao}}</td>
              </ng-container>
              <ng-container matColumnDef="iata">
                <th mat-header-cell *matHeaderCellDef width="4%">
                  IATA
                </th>
                <td (click)="openAirportBrief(element)" style="flex: 0 0 4" mat-cell *matCellDef="let element" [ngClass]="{ 'alert-danger' : element.airportID==0 }">{{element.iata}}</td>
              </ng-container>
              <ng-container matColumnDef="airport">
                <th mat-header-cell *matHeaderCellDef width="13%" style="text-align:left !important">
                  Airport
                </th>
                <td (click)="openAirportBrief(element)" mat-cell *matCellDef="let element" style="text-align:left !important;flex: 0 0 4;width:4%" [ngClass]="{ 'alert-danger' : element.airportID==0 }">
                  <div class="ellipsis">{{element.airportName}}</div>
                  <div class="ellipsis">{{element.airportLocationWithCountryNameAbbr}}</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="maxRunway">
                <th mat-header-cell *matHeaderCellDef width="4%">
                  Max<br />Rwy
                </th>
                <td (click)="openAirportBrief(element)" mat-cell *matCellDef="let element" ><div>{{element.runwayMaxLength | number}}</div></td>
              </ng-container>
              <ng-container matColumnDef="utc">
                <th mat-header-cell *matHeaderCellDef width="5%">
                  UTC
                </th>
                <td (click)="openAirportBrief(element)" mat-cell *matCellDef="let element"><div>{{element.currentUTCConversion}}</div></td>
              </ng-container>
              <ng-container matColumnDef="handler-ssn">
                <th mat-header-cell *matHeaderCellDef width="22%" style="text-align:left !important">
                  Hander/SSN
                </th>
                <td style="text-align: left !important; flex: 0 0 22; width: 22%" mat-cell *matCellDef="let element">
                  <div class="ellipsis" *ngIf="element.handler!=null" style=" color:Highlight; cursor:pointer; text-decoration:underline" (click)="openVendorSelection(element)"><i style="font-size: medium;width:1em" class="material-icons-two-tone yellow">{{element.handler.isPreferred? 'grade':''}}</i>{{'H: ' + element.handler.vendorName}}  <!--<i style="font-size: medium;" *ngIf="element.handler.isClientPreference" class="material-icons-two-tone blue">grade</i>--> </div>
                  <div class="ellipsis" *ngIf="element.ssn!=null" style=" color:Highlight; cursor:pointer; text-decoration:underline" (click)="openVendorSelection(element)"><i style="font-size: medium;width:1em" class="material-icons-two-tone yellow">{{element.ssn.isPreferred? 'grade':''}}</i>{{'S: ' + element.ssn.vendorName}}  <!--<i style="font-size: medium;" *ngIf="element.ssn.isClientPreference" class="material-icons-two-tone blue">grade</i>--> </div>

                </td>
              </ng-container>
              <ng-container matColumnDef="airport_of_entry">
                <th mat-header-cell *matHeaderCellDef width="5%">
                  AOE
                </th>
                <td (click)="openAirportBrief(element)" mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.airportOfEntry=='Yes'? 'beige':'', 'font-weight': element.airportOfEntry=='Yes'? '500':''}" style="white-space:pre-wrap !important;overflow:auto !important"><div>{{element.airportOfEntry}}</div></td>
              </ng-container>
              <ng-container matColumnDef="restrictions">
                <th mat-header-cell *matHeaderCellDef width="6%">
                  Restrictions
                </th>
                <td (click)="openAirportBrief(element)" mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.hasRestrictions? 'beige':'', 'font-weight': element.hasRestrictions? '500':''}">{{element.hasRestrictions==null? '-' : element.hasRestrictions? 'Yes':'No'}}</td>
              </ng-container>
              <ng-container matColumnDef="slots">
                <th mat-header-cell *matHeaderCellDef width="5%">
                  Slots
                </th>
                <td (click)="openAirportBrief(element)" mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.isRequiredSlots >0? 'beige':'', 'font-weight': element.isRequiredSlots !=0? '500':''}">{{element.isRequiredSlots==null? '-' : element.isRequiredSlots==1? 'Yes' : element.isRequiredSlots==2? 'Notes' : 'No'}}</td>
              </ng-container>
              <ng-container matColumnDef="ppr">
                <th mat-header-cell *matHeaderCellDef width="5%">
                  PPR
                </th>
                <td (click)="openAirportBrief(element)" mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.isRequiredPPR >0? 'beige':'', 'font-weight': element.isRequiredPPR !=0? '500':''}">{{element.isRequiredPPR==null? '-' : element.isRequiredPPR==1? 'Yes' : element.isRequiredPPR==2? 'Notes' : 'No'}}</td>
              </ng-container>
              <ng-container matColumnDef="landing_permit">
                <th mat-header-cell *matHeaderCellDef width="5%">
                  Landing Permit
                </th>
                <td (click)="openAirportBrief(element)" mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.countryRequiresLandingPermit=='Yes' || element.countryRequiresLandingPermit=='See Notes'? 'beige':'', 'font-weight': element.countryRequiresLandingPermit=='Yes' || element.countryRequiresLandingPermit=='See Notes'? '500':''}">{{element.countryRequiresLandingPermit==null? '-' : element.countryRequiresLandingPermit}}</td>
              </ng-container>

              <ng-container matColumnDef="advisories">
                <th mat-header-cell *matHeaderCellDef width="6%">
                  Advisories
                </th>
                <td mat-cell *matCellDef="let element">{{element.advisoryCount>0? element.advisoryCount:''}}</td>
              </ng-container>
              <ng-container matColumnDef="lookup_routes">
                <th mat-header-cell *matHeaderCellDef width="5%">
                  Lookup Routes
                </th>
                <td mat-cell *matCellDef="let element" style=" color:Highlight; cursor:pointer; text-decoration:underline" (click)="openRoutes(element) ">{{element.routeCount>0? element.routeCount:''}}</td>
              </ng-container>

              <ng-container matColumnDef="ops_report">
                <th mat-header-cell *matHeaderCellDef width="4%">
                  Brief
                </th>
                <td mat-cell *matCellDef="let element">
                  <i *ngIf="element.airportID!=0" class="material-icons-outlined" style="cursor:pointer">
                    cloud_download
                  </i>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width:7%; border-bottom:none">
                  Action
                </th>
                <td mat-cell *matCellDef="let element">
                  <a *ngIf="element.airportID!=0" mat-button class="btn-high grid" style="align-self:center" (click)="openAirportBrief(element)">
                    Open
                  </a>
                </td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="selectedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: selectedColumns"></tr>
          </table>

        </div>
        <div class="ml-2" style="width:29%;">
          <div style="position: relative;" (mousedown)="$event.stopPropagation()">
            <div style="width:100%;height:38.3em;border-radius:.25em" id="trip-planner-map"></div>
            <div *ngIf="airports && airports.length>0" style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
              fullscreen
            </div>
          </div>

        </div>


      </div>


    </div>
  <div class="mt-2">
    <button *ngIf="!fromTrip" [disabled]="airports?.length==0 || !airports || buttonDisabled" mat-button class="btn btn-med mr-2"><span style="font-size:medium;margin-top:-.25em;margin-right:.25em;margin-left:-.25em" class="material-symbols-outlined">add</span>Submit Trip</button>
    <button [disabled]="airports?.length==0 || !airports || buttonDisabled"  mat-button class="btn btn-med mr-2"><span style="font-size: medium; margin-top: -.25em; margin-right: .25em; margin-left: -.25em" class="material-symbols-outlined">cloud_download</span>Download All Reports</button>
    <button *ngIf="!isOpenedNew" mat-dialog-close mat-button class="btn btn-low mr-2"><span style="font-size: medium; margin-top: -.25em; margin-right: .25em; margin-left: -.25em" class="material-symbols-outlined">close</span>Close</button>
  </div>
  </div>

