<!--<div [id]="uniqueId" style="background: #f0f0f0;">-->
  <!--[ngClass]="{  'mini1': conditionValue === 1 && isMinimized === true,
      'mini2': conditionValue === 2 && isMinimized === true,
      'mini3': conditionValue === 3 && isMinimized === true }" -->
  <!--<div class="dialog-buttons minimizedDialog" cdkDrag cdkDragRootElement=".dialog-buttons" cdkDragHandle
       style="border-radius:5px; margin-bottom: -0.5em; margin-right: -0.4em; display: flex; justify-content: space-between;">


    <div *ngIf="isMinimized" style="margin-top: 0.5em; display: flex;">
      <mat-icon style="font-size: large; margin-top:0.2em; margin-left: 0.2em; margin-right: 0.2em;"> flight</mat-icon>
      <span> Airport Brief {{selectedAirport? selectedAirport.icao: ''}}</span>
    </div>-->

    <!-- <button *ngIf = "!isMinimized" mat-icon-button (click)="minimizeDialog()">  <i class="material-icons" style="font-size:x-large;  ">remove</i> </button> -->
    <!--<div style="display: flex;">
      <button *ngIf="isMinimized" mat-icon-button (click)="maximizeDialog()"> <i class="material-icons" style="font-size: large; margin-right: -1.2em; ">open_in_full</i> </button>
      <button *ngIf="isMinimized" mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button>
    </div>
  </div>

  <div class="model-content" [ngClass]="{'minimized': isMinimized}" style="padding: 0em 1em 1em 1em;">-->

    <div style="width: 100%; overflow:hidden"
         cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>
      <div class="d-flex justify-content-between">
        <div class="d-inline-flex align-items-baseline">
          <div style="margin-right:2em">
            <div style="margin-bottom:-.5em">
              <h5>Trip Planner</h5>
            </div>
            <div>
              <span style="font-size:x-large;font-weight:bold;margin-right:1em">AIRPORT BRIEF</span>
              <!--<span> <label style="font-weight:bold">FAR Type</label> {{farType}} </span><span *ngIf="route!=''"> <label style="font-weight:bold">Route</label> {{route}}</span>-->
            </div>
          </div>
          <!--<div style="position:relative;min-width:20em;max-width:80%">
            <div [ngStyle]="{'position':  route!=''? '':'absolute'}" style="margin-bottom:-.5em;width:100%">
              <label style="font-weight:bold">FAR Type</label> {{farType}} <label style="font-weight:bold">Route</label> {{route}}
            </div>

          </div>-->

        </div>
        <div>
          <!--<button *ngIf="!isMinimized" mat-icon-button (click)="minimizeDialog()">  <i class="material-icons" style="font-size:x-large;  ">remove</i> </button>-->
          <mat-icon mat-dialog-close>close</mat-icon>
        </div>
      </div>
      <div *ngIf="selectedAirport" style="background-color:white;padding:.5em;">
        <div class="d-flex justify-content-between">
          <div class="d-inline-flex">
            <div style="margin-top:-.5em">
              <span style="font-size:xx-large;font-weight:bold;margin-right:1em">{{selectedAirport.icao}} {{selectedAirport.iata}}</span> <span style="font-size:x-large;color:gray;font-weight:bold;">{{selectedAirport.airportName}} {{selectedAirport.airportLocation}}</span><span style="font-size: x-large; color: Highlight; cursor: pointer; font-weight: bold;" (click)="openCountryDetails()">{{selectedAirport.countryName}}</span>

            </div>
            <div *ngIf="selectedAirport.flagFile" style="width:4em; margin-left:1em">
              <img style="border: 1px solid lightgray" [src]="getFlag(selectedAirport.flagFile)" />
            </div>
            <!--<div style="margin-left:1em ">
              <button mat-button class="btn btn-med" (click)="openCountryDetails()">Country Details</button>
            </div>-->
          </div>
          <div>
            Current UTC Offset {{selectedAirport.utcConversion}}
          </div>
        </div>
        <div class="d-flex justify-content-start mt-2">
          <div [ngClass]="{ 'alert-primary': selectedAirport.airportOfEntry=='Yes', 'alert-danger': selectedAirport.airportOfEntry=='No', 'border': selectedAirport.airportOfEntry!='Yes' && selectedAirport.airportOfEntry!='No' }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='notes'">
            <div>Airport of Entry</div>
            <div>{{selectedAirport.airportOfEntry}}</div>
          </div>
          <div [ngClass]="{ 'alert-primary': selectedAirport.isRequiredSlots==1 || selectedAirport.isRequiredSlots==2, 'border': selectedAirport.isRequiredSlots==0 || selectedAirport.isRequiredSlots==null }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='notes'">
            <div>Slots Required</div>
            <div>{{selectedAirport.isRequiredSlots==null? '-' : selectedAirport.isRequiredSlots==1? 'Yes' : selectedAirport.isRequiredSlots==2? 'See Notes' : 'No'}}</div>
          </div>
          <div [ngClass]="{ 'alert-primary': selectedAirport.isRequiredPPR==1 || selectedAirport.isRequiredPPR==2, 'border': selectedAirport.isRequiredPPR==0 || selectedAirport.isRequiredPPR==null }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='notes'">
            <div>PPR Required</div>
            <div>{{selectedAirport.isRequiredPPR==null? '-' : selectedAirport.isRequiredPPR==1? 'Yes' : selectedAirport.isRequiredPPR==2? 'See Notes' : 'No'}}</div>
          </div>
          <div [ngClass]="{ 'alert-primary': selectedAirport.countryRequiresLandingPermit=='Yes' || selectedAirport.countryRequiresLandingPermit=='See Notes', 'border': selectedAirport.countryRequiresLandingPermit=='No' }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='notes'">
            <div>Landing Permit Required</div>
            <div>{{selectedAirport.countryRequiresLandingPermit==null? '-' : selectedAirport.countryRequiresLandingPermit}}</div>
          </div>
          <div [ngClass]="{ 'alert-danger': selectedAirport.hasRestrictions, 'border': selectedAirport.hasRestrictions==false || selectedAirport.hasRestrictions==null }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='notes'">
            <div>Restrictions</div>
            <div>{{selectedAirport.hasRestrictions==null? '-' : selectedAirport.hasRestrictions? 'Yes':'No'}}</div>
          </div>
          <div [ngClass]="{ 'alert-danger': selectedAirport.advisoryCount>0, 'border': selectedAirport.advisoryCount==0 }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='advisories'">
            <div>Advisory Alerts</div>
            <div>{{selectedAirport.advisoryCount}}</div>
          </div>
          <div class="text-center rounded py-1 px-2 mr-2 border" style="font-size:small">
            <div>FAR Type</div>
            <div>{{farType}}</div>
          </div>
          <div class="text-left rounded py-1 px-2 mr-2 border pointer" style="font-size:small" *ngIf="relatedVendorDetails || vendorDetails" (click)="selectedTab='handling';changeTab()">
            <div *ngIf="relatedVendorDetails">SSN: <span style="color:#c00000">{{relatedVendorDetails.vendorName}}</span> <i style="font-size: medium;" *ngIf="relatedVendorDetails.ratingID==4" class="material-icons-two-tone yellow">grade</i></div>
            <div *ngIf="vendorDetails">GH: <span style="color:#c00000">{{vendorDetails.vendorName}}</span> <i style="font-size: medium;" *ngIf="vendorDetails.isPreferred" class="material-icons-two-tone yellow">grade</i></div>
          </div>
        </div>

      </div>
      <section class="mt-2">
        <mat-button-toggle-group [(ngModel)]="selectedTab" [ngModelOptions]="{standalone: true}" (click)="changeTab()">
          <mat-button-toggle *ngIf="showHandler" checked [value]="'handling'"><span *ngIf="isLoadingVendor" class="spinner-border spinner-border-sm mr-1"></span>Handler Information</mat-button-toggle>
          <mat-button-toggle [value]="'notes'"><span *ngIf="isLoadingAirport" class="spinner-border spinner-border-sm mr-1"></span>Airport Notes</mat-button-toggle>
          <mat-button-toggle *ngIf="showHandler" [value]="'customs'"><span *ngIf="isLoadingVendor" class="spinner-border spinner-border-sm mr-1"></span>Customs Notes</mat-button-toggle>
          <mat-button-toggle [value]="'details'"><span *ngIf="isLoadingAirport" class="spinner-border spinner-border-sm mr-1"></span>Airport Details</mat-button-toggle>
          <mat-button-toggle [value]="'alternates'"><span *ngIf="isLoadingNearby" class="spinner-border spinner-border-sm mr-1"></span>Alternate Airports</mat-button-toggle>
          <!--<mat-button-toggle [value]="'country'" *ngIf="countryDetails && countryNotes!=''">Country Notes</mat-button-toggle>-->
          <mat-button-toggle [value]="'advisories'">Advisory Alerts</mat-button-toggle>
          <mat-button-toggle [value]="'trips'">Trip Activity</mat-button-toggle>
          <mat-button-toggle [value]="'homebase'">Client Homebase</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
      <div style="height: 40em; overflow-y: auto; overflow-x: hidden; background-color: white; font-size: small; padding: 1em;">
        <div *ngIf="selectedTab=='handling'">
          <div style="user-select:text" (mousedown)="$event.stopPropagation()">
            <div class="d-inline-flex" style="float:right"><span style="font-size: medium; margin-top: .1em " class="material-icons-two-tone yellow">grade</span><div style="font-size:small">FPI Preferred Handler/SSN</div></div>  <div class="d-inline-flex" style="width:100%">
              <div style="min-width:60%;margin-right:.5em;flex-grow:1">
                <div style="margin-bottom:1em" *ngIf="relatedVendorDetails && (relatedVendorDetails.vendorComms.length>0 || relatedVendorDetails.vendorPersons.length>0)">
                  <div><label class="font-weight-bold">Special Services Network {{relatedVendorDetails.vendorRoleID==5? '(Supervision)':'(Coordination)'}} - <span style="color:#c00000">{{relatedVendorDetails.vendorName}}</span></label><i style="font-size: medium;" *ngIf="relatedVendorDetails.ratingID==4" class="material-icons-two-tone yellow">grade</i></div>

                  <table width="100%">
                    <ng-template ngFor let-person [ngForOf]="relatedVendorDetails.vendorPersons" let-i="index">
                      <tr class="striped-row" *ngIf="briefType=='internal' || (briefType=='client' && person.includeInBrief)">
                        <td width="35%">{{person.jobTitle}}</td>
                        <td>{{person.firstName}} {{person.lastName}}</td>
                      </tr>
                      <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
                        <tr class="striped-row" *ngIf="briefType=='internal' || (briefType=='client' && comm.includeInBrief)"><td width="20%">{{person.jobTitle}} {{comm.commAddressType}}</td><td>{{comm.address}}</td></tr>
                      </ng-template>
                    </ng-template>
                    <ng-template *ngIf="relatedVendorDetails.vendorComms && relatedVendorDetails.vendorComms.length>0" ngFor let-comm [ngForOf]="relatedVendorDetails.vendorComms" let-i="index">
                      <tr><td width="35%">{{comm.commAddressType}}</td><td>{{comm.address}}</td></tr>
                    </ng-template>
                  </table>
                </div>
                <div style="margin-bottom:1em" *ngIf="vendorDetails && (vendorDetails.vendorComms) && (vendorDetails.vendorPersons!=null || relatedVendorDetails.vendorPersons.length>0)">
                  <div><label class="font-weight-bold">Ground Handler - <span style="color:#c00000">{{vendorDetails.vendorName}}</span></label><i style="font-size: medium;" *ngIf="vendorDetails.isPreferred" class="material-icons-two-tone yellow">grade</i></div>
                  <div>
                    <table width="100%">
                      <ng-template ngFor let-person [ngForOf]="vendorDetails.vendorPersons" let-i="index">
                        <tr class="striped-row" *ngIf="briefType=='internal' || (briefType=='client' && person.includeInBrief)">
                          <td width="35%">{{person.jobTitle}}</td>
                          <td>{{person.firstName}} {{person.lastName}}</td>
                        </tr>
                        <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
                          <tr class="striped-row" *ngIf="briefType=='internal' || (briefType=='client' && comm.includeInBrief)">
                            <td width="35%">{{person.jobTitle}} {{comm.commAddressType}}</td>
                            <td>{{comm.address}}</td>
                          </tr>
                        </ng-template>
                      </ng-template>
                      <ng-template ngFor let-comm [ngForOf]="vendorDetails.vendorComms" let-i="index">
                        <tr class="striped-row"><td width="35%">{{comm.commAddressType}}</td><td>{{comm.address}}</td></tr>
                      </ng-template>
                    </table>
                  </div>
                </div>
                <div *ngIf="vendorDetails && vendorDetails.vendorHours!=null && vendorDetails.vendorHours.length>0">
                  <div>
                    <label class="font-weight-bold">Handler Hours</label>
                  </div>
                  <div>
                    <ng-template ngFor let-hours [ngForOf]="vendorHours" let-i="index">
                      <table width="100%">
                        <tr><td colspan="2"><span style="text-decoration:underline">{{hours.hoursTypeDescription}} Hours</span></td></tr>
                        <tr *ngIf="hours.endDate!=null"><td width="20%">Effective Date</td><td>{{hours.effectiveDate}}</td></tr>
                        <tr *ngIf="hours.endDate!=null"><td width="20%">Expiration Date</td><td>{{hours.endDate}}</td></tr>
                        <tr *ngIf="hours.is24Hours"><td colspan="2">24 Hours</td></tr>
                        <ng-template *ngIf="hours.is24Hours==0" ngFor let-element [ngForOf]="hours.vendorDailyHours" let-i="index">
                          <tr>
                            <td width="20%">{{element.dayOfWeek}}</td>
                            <td>{{element.isClosed==1? 'Closed' : element.is24Hours? '24 Hours': element.openTime==null || element.openTime==''? ' ': element.openTime + ' - ' +  element.closeTime}}</td>
                          </tr>
                        </ng-template>
                        <tr *ngIf="hours.remarks!=null && hours.remarks!=''"><td width="20%">Remarks</td><td>{{hours.remarks}}</td></tr>
                      </table>
                      <br />
                    </ng-template>
                  </div>

                </div>
              </div>
              <div style="width:40%;margin-top:1em">
                <div style="margin-bottom:1em" *ngIf="relatedVendorDetails && relatedVendorDetails.vendorRoleID==5">
                  <div style="color:#c00000;font-weight:bold">Supervision </div>
                  <div>The Special Services Network (SSN) team coordinates your operational requirements prior to arrival and is an extension of your crew while in-country.  Upon arrival and departure, our SSN supervisory representative is dedicated to your flight only, ensuring that all your needs are met. The personal attention provided by the SSN supervisor will be over and above the services received by the ground handler. </div>
                </div>
                <div style="margin-bottom:1em" *ngIf="relatedVendorDetails && relatedVendorDetails.vendorRoleID==19">
                  <div style="color:#c00000;font-weight:bold">
                    Coordination
                  </div>
                  <div>
                    The Special Services Network (SSN) team coordinates your operational requirements prior to arrival and is an extension of your crew while in-country.  Our remote SSN coordinator is a phone call away to provide dedicated support to your flight, ensuring that all your needs are met. The attention provided by the SSN coordinator will be over and above the services received by the ground handler.
                  </div>
                </div>

                <div style="width:100%;">
                  <div style="position: relative;" (mousedown)="$event.stopPropagation()">
                    <div style="width:100%;height:25em;border-radius:.25em" id="{{'brief-map'+airportID}}"></div>
                    <div *ngIf="selectedAirport" style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
                      fullscreen
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="mt-2" *ngIf="vendorDetails && vendorDetails.handlingNotes!=null && vendorDetails.handlingNotes.length>0">

              <div>
                <table width="100%">
                  <ng-template ngFor let-note [ngForOf]="vendorDetails.handlingNotes" let-i="index">
                    <tr class="striped-row"><td width="20%" valign="top">{{note.noteTypeDescription}}</td><td style="white-space:pre-wrap">{{note.note}}</td></tr>
                  </ng-template>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab=='notes'">

          <div style="user-select:text" (mousedown)="$event.stopPropagation()" *ngIf="(selectedAirport && selectedAirport.airportNotes!=null && selectedAirport.airportNotes.length>0) || (landingPermitNotes!=null && landingPermitNotes!='')">

            <div>
              <table width="100%">
                <tr class="striped-row"><td width="20%" valign="top">Airport of Entry</td><td style="white-space:pre-wrap">{{selectedAirport.airportOfEntry}}</td></tr>
                <!--<ng-template *ngIf="vendorDetails" ngFor let-note [ngForOf]="vendorDetails.airportNotes" let-i="index">
                  <tr class="striped-row"><td width="20%" valign="top">{{note.noteTypeDescription}}</td><td style="white-space:pre-wrap">{{note.note}}</td></tr>
                </ng-template>-->
                <ng-template *ngIf="selectedAirport.airportNotes" ngFor let-note [ngForOf]="selectedAirport.airportNotes" let-i="index">
                  <tr class="striped-row"><td width="20%" valign="top">{{note.noteType}}</td><td style="white-space:pre-wrap">{{note.airportNote}}</td></tr>
                </ng-template>
                <ng-container>
                  <tr class="striped-row">
                    <td width="20%" valign="top">Landing Permit</td>
                    <td style="white-space:pre-wrap">
                      <span *ngIf="landingPermitNotes!=null && landingPermitNotes!='' && !countryPermitNotRequired">
                        {{landingPermitNotes}}
                        {{showValidity || showLeadTime || showDueDate? '\n\n':''}}
                        {{showValidity? 'Validity: ' + landingPermitValidity + ' ' : ''}}
                        {{showLeadTime? 'Lead Time: ' + landingPermitLeadTime + ' ' : ''}}
                        {{showDueDate? 'Due Date: ' + landingPermitDueDate + ' ' : ''}}
                      </span>
                      <span *ngIf="countryPermitNotRequired">Not Required</span>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="landingRequiredDocuments && landingRequiredDocuments.length>0">
                  <tr class="striped-row">
                    <td width="20%" valign="top">Landing Permit Documents</td>
                    <td style="white-space:pre-wrap">
                      <ng-template ngFor let-doc [ngForOf]="landingRequiredDocuments" let-i="index">{{doc.documentType}}<br /></ng-template>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>

        </div>
        <div *ngIf="selectedTab=='customs'">
          <div style="user-select:text" (mousedown)="$event.stopPropagation()">
            <div *ngIf="vendorDetails && vendorDetails.customsNotes!=null && vendorDetails.customsNotes.length>0">
              <!--<div>
                <label class="font-weight-bold">{{vendorDetails.customsNotes[0].groupName}}</label>
              </div>-->
              <div>
                <table width="100%">
                  <ng-template ngFor let-note [ngForOf]="vendorDetails.customsNotes" let-i="index">
                    <tr class="striped-row"><td width="20%" valign="top">{{note.noteTypeDescription}}</td><td style="white-space:pre-wrap">{{note.note}}</td></tr>
                  </ng-template>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab=='details'">
          <div style="user-select:text" (mousedown)="$event.stopPropagation()">
            <div style="font-size:x-small;float:right">Provided By Ac-u-kwik</div>
            <div class="d-inline-flex" style="width:100%">
              <div style="width:45%">

                <div style="width:100%;">
                  <div style="position: relative;" (mousedown)="$event.stopPropagation()">
                    <div style="width:100%;height:39em;border-radius:.25em" id="{{'brief-map'+airportID}}"></div>
                    <div *ngIf="selectedAirport" style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
                      fullscreen
                    </div>
                  </div>
                </div>
              </div>
              <div (mousedown)="$event.stopPropagation()" style="user-select:text;width:55%;margin-left:1em">
                <div style="margin-bottom:1em">
                  Lat/Long {{selectedAirport.latitudeDMS}} / {{selectedAirport.longitudeDMS}}
                </div>
                <div>
                  Elevation (ft) {{selectedAirport.elevation}}
                </div>
                <div>
                  Lighting {{selectedAirport.lighting}}
                </div>
                <div>
                  Approach {{selectedAirport.approachList}}
                </div>
                <div style="margin-top:1em">
                  Distance from City {{selectedAirport.distanceFromCity}}
                </div>
                <div>
                  Airport Type {{selectedAirport.airportType}}
                </div>

                <div class="d-inline-flex" style="width:100%;margin-top:1em">
                  <div style="margin-right:1.5em">
                    <h6>Longest Runway</h6>
                    <div>
                      Primary Runway {{selectedAirport.primaryRunway}}
                    </div>
                    <div>
                      Max Runway Length (ft) {{selectedAirport.runwayMaxLength}}
                    </div>
                    <div>Max Runway Width (ft) {{selectedAirport.runwayMaxWidth}}</div>
                  </div>
                  <div style="margin-right:1.5em">
                    <h6>Frequency</h6>
                    <ng-template ngFor let-element [ngForOf]="selectedAirport.airportFrequencies" let-i="index">
                      <div>{{element.frequencyCommsType}} {{element.frequency}} MHz</div>
                    </ng-template>
                  </div>
                  <div>
                    <h6>Observes Daylight Saving - {{selectedAirport.hasDaylightSavings}}</h6>
                    <div *ngIf="selectedAirport.hasDaylightSavingsBool">
                      DLS Start {{selectedAirport.dlsStart}}
                    </div>
                    <div *ngIf="selectedAirport.hasDaylightSavingsBool">
                      DLS End {{selectedAirport.dlsEnd}}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab=='country'">
          <div style="user-select: text; white-space: pre-wrap" (mousedown)="$event.stopPropagation()" *ngIf="countryDetails">
            {{countryNotes}}
          </div>

        </div>

        <div *ngIf="selectedTab=='alternates'">
          <div class="d-inline-flex" style="margin-bottom:-.5em">
            <mat-checkbox style="transform:scale(.8);font-size:medium;margin-top:.6em" color="primary" [(ngModel)]="isPreferred" [ngModelOptions]="{standalone: true}" (change)="getNearbyAirports()">Preferred</mat-checkbox>
            <label style="font-size:small;margin-top:1em;width:10em">Distance in NM: {{distance}}</label>
            <mat-slider (mousedown)="$event.stopPropagation()" thumbLabel tickInterval="50" step="50" min="50" max="1000" color="primary" [(ngModel)]="distance" [ngModelOptions]="{standalone: true}" (change)="getNearbyAirports()"></mat-slider>

          </div>
          <div class="d-inline-flex" style="width:100%">
            <div style="width:65%;max-height:34em;overflow-y:auto">
              <table mat-table [dataSource]="nearbyAirports" class="example-container">
                <tr>
                  <ng-container matColumnDef="preferred">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      Preferred
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.isPreferredAlternate? 'Yes':''}}</td>
                  </ng-container>
                  <ng-container matColumnDef="airport">
                    <th mat-header-cell *matHeaderCellDef width="20%">
                      Airport
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align:left !important;" (click)="openAirportBrief(element)">{{element.icao}} {{element.iata}} {{element.airportName}} {{element.airportLocationWithCountryNameAbbr}}</td>
                  </ng-container>
                  <ng-container matColumnDef="distance">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      Distance
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.distanceFromAirport}}</td>
                  </ng-container>
                  <ng-container matColumnDef="airport_of_entry">
                    <th mat-header-cell *matHeaderCellDef width="6%" matTooltip="Airport of Entry">
                      AOE
                    </th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.airportOfEntry=='Yes'? 'beige':'', 'font-weight': element.airportOfEntry=='Yes'? '500':''}"><div>{{element.airportOfEntry}}</div></td>
                  </ng-container>
                  <ng-container matColumnDef="restrictions">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      Restrictions
                    </th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.hasRestrictions? 'beige':'', 'font-weight': element.hasRestrictions? '500':''}">{{element.hasRestrictions==null? '-' : element.hasRestrictions? 'Yes':'No'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="slots">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      Slots
                    </th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.isRequiredSlots >0? 'beige':'', 'font-weight': element.isRequiredSlots !=0? '500':''}">{{element.isRequiredSlots==null? '-' : element.isRequiredSlots==1? 'Yes': element.isRequiredSlots==2? 'Notes':'No'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="ppr">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      PPR
                    </th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.isRequiredPPR >0? 'beige':'', 'font-weight': element.isRequiredPPR !=0? '500':''}">{{element.isRequiredPPR==null? '-' : element.isRequiredPPR==1? 'Yes': element.isRequiredPPR==2? 'Notes':'No'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="landing_permit">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      Landing Permit
                    </th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.countryRequiresLandingPermit=='Yes' || element.countryRequiresLandingPermit=='See Notes'? 'beige':'', 'font-weight': element.countryRequiresLandingPermit=='Yes' || element.countryRequiresLandingPermit=='See Notes'? '500':''}">{{element.countryRequiresLandingPermit==null? '-' : element.countryRequiresLandingPermit}}</td>
                  </ng-container>

                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="openAirportBrief(row)"></tr>
              </table>
            </div>
            <div style="width:35%; margin-left:1em">
              <div style="width:100%;">
                <div style="position: relative;" (mousedown)="$event.stopPropagation()">
                  <div style="width:100%;height:36em;border-radius:.25em" id="{{'nearby-map'+airportID}}"></div>
                  <div *ngIf="nearbyAirports" style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
                    fullscreen
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <button mat-dialog-close mat-button class="btn btn-low mr-2"><span style="font-size: medium; margin-top: -.25em; margin-right: .25em; margin-left: -.25em" class="material-symbols-outlined">close</span>Close</button>
        <button mat-button [disabled]="isLoadingDoc" class="btn btn-med mr-2" (click)="downloadReport()"><span *ngIf="isLoadingDoc" class="spinner-border spinner-border-sm mr-1"></span><span style="font-size: medium; margin-top: -.25em; margin-right: .25em; margin-left: -.25em" class="material-symbols-outlined">cloud_download</span>Download Report</button>
              </div>
            </div>
          
  <!--</div>
</div>-->
