import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { interval, merge, Subject, Subscription } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { MessageCenterModel } from '../models/message-center.model';
import { MessageFolderModel } from '../models/message-folder.model';
import { MessageModel } from '../models/message.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MessageService } from '../services/message.service';
import { EmailInternalDialogComponent } from './email-internal-dialog.component';
import { MessageLinkComponent } from './message-link.component';
import { FormsModule, UntypedFormBuilder, UntypedFormGroup, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CustomerModel } from '../models/customer.model';
import { TripModel } from '../models/trip.model';
import { CommonService } from '../services/common.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Title } from '@angular/platform-browser';

const datepipe: DatePipe = new DatePipe('en-US');

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css']
})


/** message-list component*/
export class MessageListComponent implements AfterViewInit, OnChanges {
  private updateSubscription: Subscription;

  @Input() messageFolder: MessageFolderModel[];
  @Input() hasChanges: boolean;
  @Output() messageFolderChange = new EventEmitter<MessageFolderModel[]>();
  data: MessageModel[] = [];
  showSpin: boolean = false;
  isLoading: boolean = false;
  messageList = new MatTableDataSource<MessageModel>();
  selection = new SelectionModel<MessageModel>(true, []);
  displayedColumn: string[] = ['action1', 'assigned_to', 'linkToTrip', 'date_time', 'sender', 'subject', 'body', 'has_attachments', 'action2'];
  destroyed = new Subject<void>();
  currentScreenSize: string;
  currentUser: string = localStorage.getItem('un');
  showSearch: boolean = false;
  timeFrame: number = 2;
  resultsLength: number = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  currentDate: Date = new Date();
  messageFolderID: number;
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'xSmall'],
    [Breakpoints.Small, 'small'],
    [Breakpoints.Medium, 'medium'],
    [Breakpoints.Large, 'large'],
    [Breakpoints.XLarge, 'xLarge'],
  ]);

  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filterUnread: boolean = false;
  showAll: boolean = true;
  currentShowAll: boolean = true;
  searchFrom: string = null;
  searchSubject: string = null;
  searchTo: string = null;
  searchAll: string = null;
  searchFiltervalue: number = 3;
  searchDateValue: number = 4320;
  searchQuery: string = '';
  selectedCustomerGUID: string = "";
  searchParam = null;
  searchMessageGUID: string =  null;
  searchAssigned: string =  null;
  
  //client
  customerList: CustomerModel[];
  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  reloadChildPage: boolean;
  message_list_form: UntypedFormGroup;
  myForm: FormGroup;

  showClientList: boolean = false;
  orgCustomerList: CustomerModel[] = [];
  selectedCustList: CustomerModel[] = [];
  selectedClientCountText: string = "Select Client Account";
  selectedValue: any ;
  selectedClient: string = "";
  selectedClient2: FormControl;
  includeTrips: boolean = false;
  //client end

  currentFolderID: number = 1;
  currentPageNumber: number = 0;
  messagesOpened: number = 0;
  showFlagMessages: boolean = false;
  isFlagged: boolean = false;
  selectedMessageGUID: string = "";
  refreshRate: number = 60000;
  subscription: Subscription;
  /** message-list ctor */
  constructor(private _titleService: Title,private readonly _messageService: MessageService, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService, private readonly _dialog: MatDialog,
    private formBuilder: FormBuilder, private readonly _formBuilder: UntypedFormBuilder, breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'unknown';
          }
        }
      });
  }
  ngOnChanges(changes: SimpleChanges) {   
    this.messageList.data = [];
    
    this.selectedMessageGUID = "";
    if (this.messageFolder.length == 0)
      this.messageFolderID = 1;
    else
      this.messageFolderID = this.messageFolder.find(x => x.selected == true).messageFolderID
    if (this.messageFolderID == 1 && this.filterUnread)
      this.messageFolderID = 2;
    if (this.updateSubscription)
      this.updateSubscription.unsubscribe();
    if (this.subscription)
      this.subscription.unsubscribe();
    if (this.messageFolderID == 1)
      this.refreshRate = 60000;
    else
      this.refreshRate = 120000;
     
      this.updateSubscription = interval(this.refreshRate).subscribe(
        () => { this.getData(false) });
    
    this._authService.updateAccessTime();
    if (this.paginator != undefined) {
      this.paginator.pageIndex = 0;
      this.getData(true);
    }
  }



  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    //this.updateSubscription = interval(this.refreshRate).subscribe(
    //  () => { this.getData(false) });

    this._messageService.selectedMessageGUID.subscribe(resp=>this.selectedMessageGUID = resp);
  }

  getData(showSpin: boolean) {
    // this.showSpin = showSpin;
    this.selectedMessageGUID = "";

    let request = new MessageCenterModel();
    if (this.paginator != undefined)
      this.paginator.page.observers = [];
    if (this.sort != undefined)
      this.sort.sortChange.observers = [];
      this.subscription =
    merge(this.sort.sortChange,this.paginator.page)
      .pipe(startWith({}) ,     
        switchMap(() => {
          this.showSpin = showSpin;
          request.startDate = datepipe.transform(new Date(), 'MM/dd/YYYY');
          request.endDate = datepipe.transform(new Date(), 'MM/dd/YYYY');
          if(this.currentShowAll != this.showAll){
            this.messageList.data = [];
            this.currentShowAll = this.showAll;

          }
          request.timeFrame = this.searchDateValue;
          if (this.currentPageNumber != this.paginator.pageIndex) {
            this.messageList.data = [];
            this.currentPageNumber = this.paginator.pageIndex;
          }
          request.pageNumber = this.paginator.pageIndex;
          request.messageFolderID = this.messageFolderID;
          request.sort = this.sort.direction;
          request.pageSize = this.paginator.pageSize;
          request.searchFrom = this.searchFrom;
          request.searchSubject = this.searchSubject;
          request.searchTo = this.searchTo;
          request.searchAll = this.searchAll;
          request.searchAssigned = this.searchAssigned;
          //if(this.searchMessageGUID != null)
          //  request.searchAll = null;
          request.searchMessageGUID = this.searchMessageGUID;
          
          if (this.messageFolderID != this.currentFolderID && (this.messageFolderID == 12 || this.messageFolderID == 4)) {
            this.currentFolderID = this.messageFolderID;
            this.getTripClientList(true);
          }


          // request.searchCustomerGUID = this.selectedCustList[0] ? this.selectedCustList[0].customerGUID: null ;
          if (this.messageFolderID == 12 || this.messageFolderID == 4) {
            request.searchCustomerGUID = this.selectedCustomerGUID;
          }
          else
            request.searchCustomerGUID = null;
          request.includeTrips = this.includeTrips;
          request.showFlagMessages = this.showFlagMessages;
          // request.showFlagMessages = this.f.showFlagMessages.value == true ? this.f.showFlagMessages.value :false;

          return this._messageService.getMessageListByConditions<ResponseModel<MessageCenterModel>>(request)
        }),
        map(response => {
          // Flip flag to show that loading has finished.
          this.showSpin = false;
          if (response!.code == "401") {
            this._authService.signOut();
          }
          if (response.model === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = response.model.totalRecords;
          return response.model;
        }),
      )
      .subscribe(data => {

        if (data['messageFolders'] != null && data['messageFolders'] != undefined) {
          if (data['messageFolders'] != null) {
            this.messageFolder.forEach(x => {
              let f;
              let i = 0;
              if (x.messageFolderID == 1) {
                f = data['messageFolders'].find(y => y.messageFolderID == 1);
                if (f != undefined)
                  i += f.messageCount;
                f = data['messageFolders'].find(y => y.messageFolderID == 2);
                if (f != undefined)
                  i += f.messageCount;
                f = data['messageFolders'].find(y => y.messageFolderID == 3);
                if (f != undefined)
                  i += f.messageCount;
              }
              else {
                f = data['messageFolders'].find(y => y.messageFolderID == x.messageFolderID);
                if (f != undefined)
                  i = f.messageCount;
              }
              x.messageCount = i;

            });
          }
          if(data['messages'] != null){
            data['messages'].every(x => x.loading = false);
            data['messages'].forEach(x => x.tripCode = (x.tripCode == null ? '' : x.tripCode));
          }

          this.messageList = new MatTableDataSource<(MessageModel)>(data['messages']);
          this.selection = new SelectionModel<(MessageModel)>(true, []);
          if (this.paginator.pageIndex > 0) {
            const matTable = document.getElementById('msg');
            matTable.scrollIntoView();
          }
          // this.messageList.paginator = this.paginator;

        }
        else {
          this.messageList = new MatTableDataSource<(MessageModel)>([]);
          this.selection = new SelectionModel<(MessageModel)>(true, []);
        }
      });

    this.initControls();
    // if(this.messageFolderID!=current)
    // this.getTripClientList(true);

  }



  assignMessage(message: MessageModel, isAssign: boolean) {
    let request = new MessageModel();
    message.loading = true;
    request.messageGUID = message.messageGUID;
    if (isAssign)
      request.assignedTo = this.currentUser;
    else
      request.assignedTo = "";
    this._messageService.updateMessageAssignedTo<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.getData(false);
        }
      }
    });
  }

  assignMe(isAssign: boolean,assignTo=null) {
    let request = new MessageModel();
    request.messageGUIDList = Array.prototype.map.call(this.selection.selected, s => s.messageGUID).toString();
    if (isAssign)
      request.assignedTo = assignTo == null ? this.currentUser : assignTo;
    else
      request.assignedTo = "";
    this._messageService.updateMessageAssignedTo<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.getData(true);
          this.selection.clear();
        }

      }
    });
  }


  moveFolder(folderID: number) {
    let request = new MessageModel();
    request.messageGUIDList = Array.prototype.map.call(this.selection.selected, s => s.messageGUID).toString();
    request.messageFolderID = folderID;
    this._messageService.updateMessageFolder<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.getData(true);
          this.selection.clear();
        }

      }
    });
  }


  markAsSpam() {
    let request = new MessageModel();
    request.messageGUIDList = Array.prototype.map.call(this.selection.selected, s => s.messageGUID).toString();
    if (this.messageFolderID == 7)
      request.isSpam = false;
    else
      request.isSpam = true;
    this._messageService.updateMessageSpam<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.selection.clear();
          this.getData(false);
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();

          }
        }
      }

    });
  }


  initControls() {
    this.message_list_form = this._formBuilder.group({
      client_search: [''],
      selectedClient: [''],
      // showFlagMessages: [false],

      includeClosedBilledRecords: [this.includeClosedBilledRecords]
    });

    // this.myForm = this.formBuilder.group({
    //   client_search: [''], // Add the necessary form controls and initial values
    //   selectedClient: ['']
    // });
  }

  get f() { return this.message_list_form?.controls; }



  // client start
  clickClientFilter() {

    this.getTripClientList();
    this.showClientList = !this.showClientList;


  }



  getTripClientList(folderChanged = false) {
    var root = this;
    // this.showClientList = true;
    // this.customerList = [];
    // this.orgCustomerList = [];
    let req = new TripModel();

    this.includeClosedBilledRecords = this.f.includeClosedBilledRecords.value;
    if (this.includeClosedBilledRecords) {
      req.tripCloseOutID = 0;
    }
    else {
      req.tripCloseOutID = 1;
    }


    if ((this.orgCustomerList != undefined && this.orgCustomerList.length == 0) || folderChanged)
      this._commonService.getCustomerListForMessages<ResponseModel<TripModel[]>>(this.showAll ? 4320 : 0, this.messageFolderID).subscribe(response => {
        // this._commonService.getCustomers<ResponseModel<TripModel[]>>().subscribe(response => {

        if (response != null && response.code == "200") {
          this.customerList = [];
          this.orgCustomerList = [];
          if (response.model != undefined && response.model.length > 0) {
            let c = new CustomerModel();
            response.model.forEach(x => {
              c = new CustomerModel();
              c.customerName = x.customerName;
              c.customerGUID = x.customerGUID;
              c.selected = false;
              if (this.selectedCustList.length > 0) {
                let f: CustomerModel[];
                f = this.selectedCustList.filter(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
                if (f.length > 0) {
                  c.selected = true;
                }
                // this.selectedCustomerGUID =this.selectedCustList[0].customerGUID
              }
              this.customerList.push(c);
            });
            this.orgCustomerList = this.customerList;
            this.getCustomerListBykeyword(this.f.client_search.value);

            if (this.selectedCustList.length > 0 && this.selectedCustList.length != this.customerList.length) {
              let data: CustomerModel[] = [];
              data = this.customerList;
              this.customerList = [];

              data.forEach(x => {
                if (x.customerGUID == "all") {
                  this.customerList.push(x);
                }
                else {
                  if (x.selected) {
                    this.customerList.push(x);
                  }
                }
              });
              data.forEach(x => {
                if (!x.selected && x.customerGUID != "all") {
                  this.customerList.push(x);
                }
              });
            }
          }
          else {
          }
        }
      })
  }

  checkClientChange(e: any, item: CustomerModel) {
    this.customerList.forEach(x => {
      if (item.customerGUID.toLowerCase() === x.customerGUID.toLowerCase()) {
        x.selected = true;
        this.selectedValue = x.customerName;
      } else {
        x.selected = false;
      }

    });

    //this.selectedClient = item;
    this.selectedValue = this.selectedCustList[0];
    this.setClientList();
    this.reloadChildPage = false;
    this.selectedCustomerGUID = this.selectedCustList[0].customerGUID;
    this.getData(true);

  }


  setClientList() {

    let excludeallclist: CustomerModel[]
    excludeallclist = this.customerList.filter(x => x.customerGUID != 'all' && x.selected == false);


    this.selectedCustList = this.customerList.filter(u => u.selected == true);
    if (this.selectedCustList.length > 0) {
      this.selectedClientCountText = "Client: " + this.selectedCustList[0].customerName;

    }
    else {
      this.selectedClientCountText = "Select Client Account";

    }
  }

  clientSearchChange(e: any) {
    let cname: string = e.target.value;
    if (cname != "") {
      cname = cname.toLowerCase();
    }
    this.getCustomerListBykeyword(cname);
  }

  getCustomerListBykeyword(cname: string) {

    this.customerList = this.orgCustomerList.filter(option => option.customerName.toLowerCase().includes(cname) || option.customerGUID == "all");
    if (this.selectedCustList.length > 0) {
      this.selectedCustList.forEach(x => {
        let i: number;
        i = this.customerList.findIndex(y => y.customerGUID.toLowerCase() == x.customerGUID.toLowerCase());
        if (i == -1) {
          let c = new CustomerModel();
          c.customerName = x.customerName;
          c.customerGUID = x.customerGUID;
          c.selected = true;
          this.customerList.push(c);
        }
      });
    }
    this.setClientList();
    if (this.selectedCustList.length > 0 && this.selectedCustList.length != this.customerList.length) {
      let data: CustomerModel[] = [];
      data = this.customerList;
      this.customerList = [];

      data.forEach(x => {
        if (x.customerGUID == "all") {
          this.customerList.push(x);
        }
        else {
          if (x.selected) {
            this.customerList.push(x);
          }
        }
      });
      data.forEach(x => {
        if (!x.selected && x.customerGUID != "all") {
          this.customerList.push(x);
        }
      });
    }

  }

  clickDoneClient() {
    this.messageList.data = [];

    this.showClientList = false;
    // this.f.endDate.setValue(null);
    if (this.selectedCustList.length > 0) {
      // this.clickSubmit();
      this.selectedCustomerGUID = this.selectedCustList[0].customerGUID

    }

  }

  clickResetClient() {
    this.messageList.data = [];

    this.f.client_search.setValue('');
    this.selectedCustList = [];
    this.selectedClient = '';
    this.customerList = this.orgCustomerList;
    this.customerList.forEach(x => {
      x.selected = false;
    });
    this.setClientList();
    this.showClientList = false;
    this.selectedCustomerGUID = '';
    this.getData(true);
    this.includeTrips = false;
  }


  openMessage(message: MessageModel) {
    let s = "";
    this.selectedMessageGUID = message.messageGUID;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.position = { top: "40px"};
    this.messagesOpened = this.messagesOpened + 1;


    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", messageGUID: message.messageGUID, isDraft: message.isDraft,
      v: this._authService.getCurrentTimeNumber(), allMessageData: this.messageList.data,
      messageFolderID: this.messageFolderID, messageKeyId: message.messageKeyID, messagesOpened: this.messagesOpened
    };

    const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // this.selectedMessageGUID = "";
      if (result == true) {
        //this.isModified = true;
        this.getData(true);

      }
        this.messagesOpened = this.messagesOpened - 1;

    });
  }

  linkMessage(message: MessageModel) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.width = "61em";//"1000px";
    dialogConfig.height = "38em";//"500px";
    dialogConfig.disableClose = true;
    //dialogConfig.hasBackdrop = false;
    let messageGUIDList = Array.prototype.map.call(this.selection.selected, s => s.messageGUID).toString();
    dialogConfig.data = {
      dialogTitle: s, messageGUID: message?.messageGUID, isDraft: message?.isDraft, v: this._authService.getCurrentTimeNumber(), allMessageData: this.messageList.data,
      messageGUIDList: messageGUIDList
    };
    // this.messagesOpened = this.messagesOpened + 1;
    const dialogRef = this._dialog.open(MessageLinkComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.isModified = true;
        this.getData(true);
      }

    });
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.messageList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.messageList.data);
  }

  checkBox(row: any) {
    this.selection.toggle(row);

  }

  ngOnDestroy() {
    this.updateSubscription?.unsubscribe();
  }

  updateInboxFilter(e: any) {
    this.filterUnread = e.checked;
    if (this.messageFolderID == 1 && this.filterUnread)
      this.messageFolderID = 2;
    else
      this.messageFolderID = 1;
    this.getData(true);
  }

  // searchMessages(e) {

  //   this.searchTo = e.target.value;
  //   this.getData(true);
  // }

  searchMessagesByClient() {

  }

  searchMessages(e) {

    this.messageList.data = [];

    // this.searchFiltervalue = e.target.value; 
    if (e.target.value.trim() == '') {
      this.searchFrom = null;
      this.searchTo = null;
      this.searchAll = null;
      this.searchSubject = null;
      this.searchMessageGUID = null;
      this.searchAssigned = null;

      this.getData(true);
    }
    else {
      switch (this.searchFiltervalue) {
        case 0:
          this.searchFrom = e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
          break;
        case 1:
          this.searchTo = e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
          break;
        case 2:
          this.searchSubject = e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
          break;
        case 3:
          this.searchAll = e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
          break;
        case 4:
          this.searchMessageGUID =e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
        case 5:
          this.searchAssigned = e.target.value;   
          this.searchParam = e.target.value;
          this.getData(true);

        default:
          if(this.searchMessageGUID != null)
            this.searchAll = e.target.value;
          this.searchParam = e.target.value;
          this.getData(true);
          return;
      }
    }

  }

  searchFilterChange(e) {
    

    // if (this.searchParam != null || this.searchParam.length > 0) {
      switch (e.target.value) {
        case 'from':
          this.searchFiltervalue = 0;
          if (this.searchParam != null || this.searchParam.length > 0) { 
            this.searchFrom = this.searchParam;
            this.searchTo = null;
            this.searchSubject = null;
            this.searchMessageGUID = null;
            this.searchAll = null;
            this.searchAssigned = null;
            this.messageList.data = [];
            this.getData(true);
          }
          break;
        case 'to':
          this.searchFiltervalue = 1;
          if (this.searchParam != null || this.searchParam.length > 0) { 
            this.searchFrom = null;
            this.searchTo = this.searchParam;
            this.searchSubject = null;
            this.searchMessageGUID = null;
            this.searchAll = null;
            this.searchAssigned = null;
            this.messageList.data = [];
            this.getData(true);
          }
          break;
        case 'subject':
          this.searchFiltervalue = 2;
          if (this.searchParam != null || this.searchParam.length > 0) { 
            this.searchFrom = null;
            this.searchTo = null;
            this.searchSubject = this.searchParam;
            this.searchAll = null;
            this.searchMessageGUID = null;
            this.searchAssigned = null;
            this.messageList.data = [];
            this.getData(true);
          }
          break; 

        case 'all':
          this.searchFiltervalue = 3;
          if (this.searchParam != null || this.searchParam.length > 0) { 
            this.searchFrom = null;
            this.searchTo = null;
            this.searchSubject = null;
            this.searchMessageGUID = null;
            this.searchAll = this.searchParam;
            this.searchAssigned = null;
            this.messageList.data = [];
            this.getData(true);
          }
          break;

        case 'messageGUID':
          this.searchFiltervalue = 4;
          if (this.searchParam != null || this.searchParam.length > 0) { 
            this.searchFrom = null;
            this.searchTo = null;
            this.searchSubject = null;
            this.searchMessageGUID = this.searchParam;
            this.searchAll = null;
            this.searchAssigned = null;
            this.getData(true);
          }
          break;

          case 'assigned':
            this.searchFiltervalue = 5;
            if (this.searchParam != null || this.searchParam.length > 0) { 
              this.searchFrom = null;
              this.searchTo = null;
              this.searchSubject = null;
              this.searchMessageGUID = null;
              this.searchAll = null;
              this.searchAssigned = this.searchParam;
              this.getData(true);
            }
            break;
          
        default:
          this.searchFiltervalue = 3;
          this.searchAll = this.searchParam;
          this.getData(true);

          return;
      }
    // }
  }

  resetSearchFilter() {
    this.messageList.data = [];

    this.searchFrom = null;
    this.searchSubject = null;
    this.searchTo = null;
    this.searchAll = null;
    this.searchAssigned = null;
    this.searchParam = null;
    this.searchMessageGUID = null;
    this.selectedClient = '';
    this.selectedCustomerGUID = '';

    // this.getData(true);
    this.searchQuery = '';
    this.selectedClientCountText = "Select Client Account";
    this.clickResetClient();
  }


  includeTripsChange(e: any) {
    this.includeTrips = e.checked;
    this.showClientList = false;
    this.getData(true);

  }

  searchDateChange(e) {
    this.messageList.data = [];

    // if (this.searchParam != null || this.searchParam.length > 0) {
      switch (e.target.value) {
        case '24h':
          this.searchDateValue = 1440;  // 24 hours
          this.getData(true);
          break;
        case '48h':
          this.searchDateValue = 2880;  //  Messages for 48 hours
          this.getData(true);
          break;
        case '3d':
          this.searchDateValue = 4320;  //  Messages for 3 days
          this.getData(true);
          break;
        case '7d':
          this.searchDateValue = 10080;  //  Messages for 7 days
          this.getData(true);
          break;
        case '30d':
          this.searchDateValue = 43200;  //  Messages for 30 days
          this.getData(true);
          break; 

        case 'd_all':
          this.searchDateValue = 0;  // All Messages
          this.getData(true);
          break;

       
        default:
          this.searchDateValue = 4320;  //  Messages for 3 days
          this.getData(true);

          return;
      }
    // }
  }

  showFlaggedOnChange(e: any) {
    this.showFlagMessages = e.checked;
    this.showSpin = true;
    this.getData(true); 
  }

  // FlagMessage(){
  //   if(this.isFlagged == false){
  //     this.isFlagged = true;
  //   }
  //   else{
  //     this.isFlagged = false;
  //   }
  //   this.updateFlag();

  // } 
  
  flagMessages(flagValue: boolean) {
    let message: MessageModel = new MessageModel();
    message.messageGUIDList = Array.prototype.map.call(this.selection.selected, s => s.messageGUID).toString(); 
    message.isFlagged = flagValue;//this.isFlagged;

    this._messageService.updateMessageFlag<ResponseModel<boolean>>(message).subscribe(response => {
      if (response != null && response.message == "" && response.code == "200") {
 
            this.getData(true);
            this.selection.clear();

      }
    });
  }

}
// assignMe(isAssign: boolean) {
//   let request = new MessageModel();
//   request.messageGUIDList = Array.prototype.map.call(this.selection.selected, s => s.messageGUID).toString(); 
//   request.assignedTo = this.currentUser; 
//   this._messageService.updateMessageAssignedTo<ResponseModel<MessageModel>>(request).subscribe(response => {
