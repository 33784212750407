<div style="margin-top:0em; padding-top:0em; display:flex; background-color: white; padding: 0.5em;"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="divLeft">
    <div>

      <div> 
          <div>
            <div style=" border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; ">
              <div style="  width: 100%;   display: flex; justify-content: space-between;" >
                <h3>GTS Billable Services</h3>
                <div style="display: flex;"> 

                  <button mat-icon-button    (click)="openAccountingDashboardComponent()" >  <i class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i> </button>    
                  <button mat-icon-button   *ngIf="isDialog"   (click)="clickRouteNew('accounting/gts-billable-services','')">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>    

                  <button  *ngIf="isDialog"  mat-icon-button mat-dialog-close> <i class="material-icons"
                      style="font-size: x-large;">close </i> </button>
                </div>
              </div>

            </div>
          </div>


          <div style="display: flex;" >
            <div>
              <div  style=" height: 28em; margin-top: 2em; overflow-y: auto;overflow-x: hidden !important; width: 12em;scrollbar-width: thin;">
                <table mat-table *ngIf="airportServices" [dataSource]="airportServices"
                  class="example-container mat-elevation-z8" style="width: 11em; ">
                  <tr> 
                    <ng-container matColumnDef="service_type">
                      <th  mat-header-cell *matHeaderCellDef style="width: 7%; text-align: left !important; padding-left: 0.5em !important;">
                        Airport Services
                      </th>
                      <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;text-align: left !important; padding-left: 0.5em !important;"  >
                        {{element.serviceTypeDescription}}
                      </td>
                    </ng-container>
  
      
                  </tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                </table>
              </div>
            </div>
  
            <div>
              <div  style=" height: 28em; margin-top: 2em; margin-left: 3em; overflow-y: auto;overflow-x: hidden !important; width: 12em;scrollbar-width: thin;">
                <table mat-table *ngIf="flightPlanServices" [dataSource]="flightPlanServices"
                  class="example-container mat-elevation-z8" style="width: 11em; ">
                  <tr> 
                    <ng-container matColumnDef="service_type">
                      <th  mat-header-cell *matHeaderCellDef style="width: 7%; text-align: left !important; padding-left: 0.5em !important;">
                        Flight Plan Services
                      </th>
                      <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;text-align: left !important; padding-left: 0.5em !important;"  >
                        {{element.serviceTypeDescription}}
                      </td>
                    </ng-container>
  
      
                  </tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                </table>
              </div>
            </div>
  
            <div>
              <div  style=" height: 28em; margin-top: 2em; margin-left: 3em; overflow-y: auto;overflow-x: hidden !important; width: 12em;scrollbar-width: thin;">
                <table mat-table *ngIf="preflightServices" [dataSource]="preflightServices"
                  class="example-container mat-elevation-z8" style="width: 11em; ">
                  <tr> 
                    <ng-container matColumnDef="service_type">
                      <th  mat-header-cell *matHeaderCellDef style="width: 7%; text-align: left !important; padding-left: 0.5em !important;">
                        Preflight Services
                      </th>
                      <td mat-cell *matCellDef="let element"   [ngClass]="element.daysToTripEnd < 0?'redCell':''"   style="text-align: center !important;text-align: left !important; padding-left: 0.5em !important;"  >
                        {{element.serviceTypeDescription}}
                      </td>
                    </ng-container>
  
      
                  </tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                </table>
              </div>
            </div>
          </div>

      </div>
    </div>
  </div>
</div>
