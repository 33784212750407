<div style="margin-top:0em; padding-top:0em; display:flex">
  <div class="divLeft">
    <div>

      <div>
        <form *ngIf="currency_form" [formGroup]="currency_form"> <!--style="width: 27em;">-->
          <div>
            <div style="background-color: #f8f8f8; border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; " 
               cdkDrag  cdkDragRootElement=".cdk-overlay-pane"  cdkDragHandle>
              <div style=" width: 40%; float: left;    display: flex;">
                <span style="margin-right: 0.2em;  " class="material-symbols-outlined">currency_exchange</span> <h6>Exchange Rates</h6>
              </div>
              <div style="display:flex">
                <div class="form-group" style=" margin-bottom: 0.2em;">

                  <button type="button" mat-button class="btn-low" (click)="importCurrencyList()" style="margin-right: 1em; float: right; ">
                    <div style="display:flex">
                      <i class="material-icons" style="font-size: medium; margin-top:0.25em">add</i>
                      <div>Import</div>
                    </div>
                  </button>
                </div>


                <div style=" margin-right:0.5em;width:15em;display: flex;">
                  <div style="display: flex;">
                    <button type="button" mat-button class="btn-low"  style="height: 2em;" (click)="clickRoute('fuelmenu/fuel-menu')">
                      <!--(click)="clickReset()"-->
                      <div style="display: flex; ">
                        <div style="margin-top: 0.125em; margin-left:-0.25em">
                          <i class="material-icons" style="font-size: medium;cursor: pointer;">arrow_back</i>
                        </div>
                        <div>
                          Return to Fuel Dashboard
                        </div>
                      </div>
                    </button>
                    <!-- <button type="button" mat-button class="btn-low" style="height:1.5em;" (click)="downloadTemplate()"> Download Template </button> -->
                  </div>
                  <button   *ngIf="isDialog" style="margin: -0.5em 0 0 11em;"   mat-icon-button  (click)="clickRouteNew('currency/currency','')"> <i class="material-icons" style="font-size: x-large;">open_in_new </i> </button>
                  <button  *ngIf="isDialog" style="margin-top: -0.5em;"   mat-icon-button mat-dialog-close> <i class="material-icons"  style="font-size: x-large;">close </i> </button>
                </div>

              </div>
   
               
              <div *ngIf="msg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:forestgreen; font-size: small">&nbsp;{{msg}}</label></div>
              <div *ngIf="errMsg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:red; font-size: small">{{errMsg}}</label></div>
            </div>
          </div>

          <div style="display: flex;" >
            <div style=" margin-top: 1em;">
              <div style="display: flex;justify-content: end; width: 31em;" >
                <!-- <span style="font-size:x-small">** This Includes Exchange Rate Markups</span> -->
                <span  style="font-size:small">Total Records: {{totalRecords}}</span>
              </div>
              <div style="height: 36em; overflow-y: auto;">
                <table mat-table *ngIf="currencyList" [dataSource]="currencyList" class="example-container mat-elevation-z8">
                  <tr>
                    <ng-container matColumnDef="Currency">
                      <th mat-header-cell *matHeaderCellDef class=" ">
                        <div style="margin-top:-0.25em;">Currency</div>
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.currencyID}}</td>
                    </ng-container>
                    <ng-container matColumnDef="CurrencyDescription">
                      <th mat-header-cell *matHeaderCellDef class=" ">
                        <div style="margin-top:-0.25em;">Description</div>
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.currencyDescription}}</td>
                    </ng-container>
                    <ng-container matColumnDef="ExchangeRate">
                      <th mat-header-cell *matHeaderCellDef class="thStyle">
                        <div style="margin-top:-0.25em;">Exchange Rate</div>
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.exchangeRate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Date">
                      <th mat-header-cell *matHeaderCellDef class="thStyle">
                        <div style="margin-top:-0.25em;">Effective Date</div>
                      </th>
                      <td mat-cell *matCellDef="let element" [ngClass]="{ 'alert-danger':element.isOldDate }">{{element.exchangeRateDate}} </td> 
                    </ng-container>
                    <ng-container matColumnDef="ModifiedBy">
                      <th mat-header-cell *matHeaderCellDef class="thStyle">
                        <div style="margin-top:-0.25em;">Modified By</div>
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.modifiedBy}}</td>
                    </ng-container>
                    <ng-container matColumnDef="ModifiedDate">
                      <th mat-header-cell *matHeaderCellDef class="thStyle">
                        <div style="margin-top:-0.25em;">Modified Date</div>
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.modifiedDate}}</td>
                    </ng-container>
                  </tr>

                  <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                  <!--<tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>-->
                  <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                </table>
              </div>
            </div>

            <div style=" margin-right: 3em; margin-left: 6em; margin-top: 2.5em; border: 1px solid black;  border-radius: 5px;  height: 15em;   width: 13em;">
              <table style="max-width: 22em; min-width: 18em;margin-bottom: 2em;">
                <tr>
                  <td   style=" padding-left: 0.5em !important;" style="padding: 0.5em;"><b>Required Fields</b></td>
                </tr>
                <tr>
                  <td><span>&nbsp;</span> </td>
                </tr>
                <tr>
                  <td   style=" padding-left: 0.5em !important; display:flex;" >Code<span style="color: red;" *ngIf="missingColumns?.includes('ICAO')">&nbsp; -> Missing</span></td>
                </tr>
                <tr>
                  <td   style=" padding-left: 0.5em !important; display:flex;" >Description<span  style="color: red;" *ngIf="missingColumns?.includes('IATA')">&nbsp; -> Missing</span></td>
                </tr>
                <tr>
                  <td   style=" padding-left: 0.5em !important; display:flex;"  >Exchange Rate Date<span  style="color: red;" *ngIf="missingColumns?.includes('IntoPlaneAgent')">&nbsp; -> Missing</span></td>
                </tr>
                <tr>
                  <td   style=" padding-left: 0.5em !important;">Exchange Rate</td>
                </tr>

              </table>

              <!-- <a  href="javascript:void(0)"   style="height:1.5em; margin-left: 0.5em;" (click)="downloadTemplate()"> Download Template </a> -->

            </div>
          </div>

          <!--<mat-paginator style="border: none;min-width:80em;" #paginator *ngIf="revisefuelpricelist && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                         [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
          </mat-paginator>-->

        </form>
      </div>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
