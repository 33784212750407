<div style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em " cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div style="margin-top:0.15em"><i class="material-icons" style="font-size: x-large;color: gray; margin-top:0.15em">hotel</i></div>
    <div style="margin-top:0.3125em;"><h5>Hotel Property</h5></div>
  </div>
  <div>
    <form *ngIf="hotel_edit_form" [formGroup]="hotel_edit_form">
      <div style="margin-top:0em; padding-left: 1em;padding-right:1em; ">
        <div style="background-color: white; padding-left:0.5em; padding-top: 0.5em; padding-bottom:1em; display: flex">
          <!--left-->
          <div style="margin-right:0.5em">
            <div style="display:flex">
              <div style="margin-right:0.5em">
                <label for="hotelName" style="font-size:small">Hotel Name</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="hotelName" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.hotelName.errors }" maxlength="255" style="width:17em;" />
                </div>
                <div *ngIf="submitted && f.hotelName.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.hotelName.errors.required">Hotel name is required.</div>
                </div>
              </div>
              <div>
                <label for="hotel_chain_select" style="font-size:small">Chain</label>
                <div style="margin-top:-0.5em; margin-bottom:0em">
                  <select *ngIf="f" formControlName="hotel_chain_select" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.hotel_chain_select.errors }">
                    <option *ngFor="let h of hotelChainList" [ngValue]="h.hotelChainGUID">{{h.hotelChainName}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.hotel_chain_select.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.hotel_chain_select.errors.required">Hotel chain is required.</div>
                </div>
              </div>
            </div>
            <div style="display:flex">
              <div>
                <label for="street_address" style="font-size:small">Address</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <textarea formControlName="street_address" class="form-control" maxlength=255 style="width:17em; height:8em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd" [ngClass]="{ 'is-invalid': submitted && f.street_address.errors }"></textarea>
                </div>
                <div *ngIf="submitted && f.street_address.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.street_address.errors.required">Address is required.</div>
                </div>
              </div>
              <div style="margin-left:0.5em">
                <div>
                  <label for="city" style="font-size:small">City</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    <input type="text" formControlName="city" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" maxlength="255" style="width:19em;" />
                  </div>
                  <div *ngIf="submitted && f.city.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.city.errors.required">City is required.</div>
                  </div>
                </div>
                <div>
                  <label for="state_province" style="font-size:small">State / Province</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    <input type="text" *ngIf="f && selectedCountryGUID !='6aa9b42f-a693-4d74-83d8-a1500b0f1133'" formControlName="state_province" class="form-control inputBox" maxlength="255" style="width:19em;" />
                    <select *ngIf="f && selectedCountryGUID =='6aa9b42f-a693-4d74-83d8-a1500b0f1133'" formControlName="state_province" class="form-control form-control-sm dropdown">
                      <option *ngFor="let state of stateProvinceList" [ngValue]="state.stateProvinceName">{{state.stateProvinceName}}</option>
                    </select>
                  </div>
                </div>
                <div >
                  <label for="zipcode" style="font-size:small">Zip/Postal Code</label>
                  <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    <input type="text" formControlName="zipcode" class="form-control inputBox" maxlength="255" style="width:10em;" />
                  </div>
                </div>

              </div>
            </div>
            <div>
              <label for="country_select" style="font-size:small">Country</label>
              <div class="form-group" style="margin-top:-0.5em;">
                <select *ngIf="f" formControlName="country_select" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.country_select.errors }" (change)="countryOnchange($event)">
                  <option *ngFor="let cty of countryList" [ngValue]="cty.countryGUID">{{cty.countryName}}</option>
                </select>
              </div>
              <div *ngIf="submitted && f.country_select.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.country_select.errors.required">Country is required.</div>
              </div>
            </div>
          </div>
          <!--left end-->
          <!--right-->
          <div>
            <div style="display: flex">
              <div style="margin-right: 0.5em">
                <label for="near_airport" style="font-size:small">Nearest Airport</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="near_airport" class="form-control inputBox" maxlength=4 style="width:7em;" [ngClass]="{ 'is-invalid': (submitted || validatedAirport) && f.near_airport.errors }" (change)="icaoChange($event)"/>
                </div>
              </div>
              <div style="margin-right: 0.5em">
                <label for="distance_from_airport" style="font-size:small">Distance from Airport</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; display:flex">
                  <input type="number" formControlName="distance_from_airport" class="form-control inputBox" style="width:7em;" /><div style="font-size: small">Miles</div>
                </div>
              </div>
              <div style="margin-right:0.5em">
                <label for="hotel_status" style="font-size:small; white-space:nowrap">Disable Record</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="hotel_status" class="form-control form-control-sm dropdown" >
                    <option value="1" selected>No</option>
                    <option value="0">Yes</option>
                  </select>
                </div>
              </div>
              <div style=" margin-right: 0.5em">
                <label for="modifiedBy" style="font-size:small">Modified By</label>
                <div style="margin-top:-0.5em">
                  <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
                </div>
              </div>
              <div style=" margin-right: 0.5em">
                <label for="modifiedDate" style="font-size:small">Modified Date</label>
                <div style="margin-top:-0.5em">
                  <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
                </div>
              </div>
            </div>
            <div>
              <div *ngIf="(submitted || validatedAirport) && f.near_airport.errors" class="invalid-feedback">
                <div *ngIf="f.near_airport.errors.required" style="background-color:#f9d2de; padding-left:0.25em;margin-top:0em; font-size:small;width:10em">Airport is required</div>
                <div *ngIf="f.near_airport.errors.pattern" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small;width:8em">Invalid airport</div>
                <div *ngIf="f.near_airport.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small;width:8em">{{wrongAirportErrMsg}}</div>
                <div *ngIf="f.near_airport.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small; width:33em">{{duplicateIATAErrMsg}}</div>
              </div>
            </div>
            <div>
              <label for="remarks" style="font-size:small">Remarks</label>
              <div style="margin-left: 0em; margin-top:-0.5em">
                <textarea formControlName="remarks" class="form-control" maxlength=255 style="width:41.5em; height:4em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
                </textarea>
              </div>
            </div>
            <div style="display: flex">
              <div style="margin-right:0.5em">
                <label for="comm_address_type1_select" style="font-size:small">Type</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="comm_address_type1_select" class="form-control form-control-sm dropdown" style="width:14em " (change)="commAddressTypeChange($event, 1)">
                    <option *ngFor="let cat of commAddressTypeList" [ngValue]="cat.commAddressTypeGUID">{{cat.commAddressTypeDesc}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.comm_address_type1_select.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.comm_address_type1_select.errors.required">Comm address type is required.</div>
                </div>
              </div>
              <div style="margin-right:0.5em;">
                <label for="comm_address1" style="font-size:small">Address</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="comm_address1" class="form-control inputBox" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.comm_address1.errors }" style="width:13em;" (change)="addressChange(1)" />
                </div>
                <div *ngIf="submitted && f.comm_address1.errors" class="invalid-feedback" style="margin-top:-2em">
                  <div *ngIf="f.comm_address1.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:13em; margin-top:0.25em">Comm address is required.</div>
                  <div *ngIf="f.comm_address1.errors.invalidComm==true" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small">{{invalidCommAddress1ErrMsg}}</div>
                </div>
              </div>
              <div *ngIf="selectedCommAddressType1=='Office Phone'" style="margin-left: 0em;margin-top:0em;">
                <label for="extension1" style="font-size:small">Extension</label>
                <div style="margin-left: 0em; margin-top:-0.5em">
                  <input type="number" formControlName="extension1" class="form-control inputBox" maxlength="5" style="width:5em;" />
                </div>

              </div>
            </div>
            <div style="display: flex">
              <div style="margin-right:0.5em">
                <label for="comm_address_type2_select" style="font-size:small">Type</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="comm_address_type2_select" class="form-control form-control-sm dropdown" style="width:14em" (change)="commAddressTypeChange($event, 2)">
                    <option *ngFor="let cat of commAddressTypeList" [ngValue]="cat.commAddressTypeGUID">{{cat.commAddressTypeDesc}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.comm_address_type2_select.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.comm_address_type2_select.errors.required">Comm address type is required.</div>
                </div>
              </div>
              <div style="margin-right:0.5em;">
                <label for="comm_address2" style="font-size:small">Address</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="comm_address2" class="form-control inputBox"  maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.comm_address2.errors }" style="width:13em;" (change)="addressChange(2)" />
                </div>
                <div *ngIf="submitted && f.comm_address2.errors" class="invalid-feedback" style="margin-top:-2em">                  
                  <div *ngIf="f.comm_address2.errors.invalidComm==true" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small">{{invalidCommAddress2ErrMsg}}</div>
                </div>
              </div>
              <div *ngIf="selectedCommAddressType2=='Office Phone'" style="margin-left: 0em;margin-top:0em;">
                <label for="extension2" style="font-size:small">Extension</label>
                <div style="margin-left: 0em; margin-top:-0.5em">
                  <input type="number" formControlName="extension2" class="form-control inputBox" maxlength="5" style="width:5em;" />
                </div>
              </div>
            </div>
            <div style="display: flex">
              <div style="margin-right:0.5em">
                <label for="comm_address_type3_select" style="font-size:small">Type</label>
                <div style="margin-top:-0.5em;">
                  <select *ngIf="f" formControlName="comm_address_type3_select" class="form-control form-control-sm dropdown" style="width:14em" (change)="commAddressTypeChange($event, 3)">
                    <option *ngFor="let cat of commAddressTypeList" [ngValue]="cat.commAddressTypeGUID">{{cat.commAddressTypeDesc}}</option>
                  </select>
                </div>
              </div>
              <div style="margin-right:0.5em;">
                <label for="comm_address3" style="font-size:small">Address</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="comm_address3" class="form-control inputBox" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.comm_address3.errors }" style="width:13em;" (change)="addressChange(3)" />
                </div>
                <div *ngIf="submitted && f.comm_address3.errors" class="invalid-feedback" style="margin-top:-2em">
                  <div *ngIf="f.comm_address3.errors.invalidComm==true" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small">{{invalidCommAddress3ErrMsg}}</div>
                </div>
              </div>
              <div *ngIf="selectedCommAddressType3=='Office Phone'" style="margin-left: 0em;margin-top:0em;">
                <label for="extension3" style="font-size:small">Extension</label>
                <div style="margin-left: 0em; margin-top:-0.5em">
                  <input type="number" formControlName="extension3" class="form-control inputBox" maxlength="5" style="width:5em;" />
                </div>
              </div>
            </div>

          </div>
          <!--right end-->
        </div>
      </div>
    </form>
  </div>
  <div style="display: flex">
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;margin-right:1em">
      <button class="btn btn-primary" [disabled]="loading" (click)="clickSave('')"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Save&nbsp;</button>&nbsp;
      <button class="btn btn-secondary" (click)="clickClose()" style="background-color:grey; color: white">&nbsp;Close&nbsp;</button>
    </div>
    <div *ngIf="errMsg!=''" class="d-block" style="display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em; width:19em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
    <div *ngIf="showSuccessMsg" style="margin-top: 0.5em;">
      <app-success-message [successMsg]=""></app-success-message>
    </div>
  </div>
</div>
  <app-spin *ngIf="showSpin"></app-spin>

