<div style="margin-top:0em; padding-top:0em; display:flex"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <!-- <div   cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
    style="border-radius:5px; margin-bottom: -0.5em; margin-right: 1.4em; display: flex; justify-content: space-between;">
  
 
     <div style="display: flex;"> 
      <button  mat-icon-button mat-dialog-close> <i class="material-icons"
          style="font-size: x-large;">close </i> </button>
    </div>
  </div> -->

  <div class="divLeft">
    <div>

      <div>
        <form *ngIf="reviseFuelPricing_form"  [formGroup]="reviseFuelPricing_form">
          <!--style="width: 27em;">-->
          <div>
            <div style="background-color: #f8f8f8; border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; ">
              <div style="     display: flex; justify-content: space-between;" >
                <!--<span style="margin-right: 0.2em;" class="material-symbols-outlined">home</span>-->
                <h3>Fuel - Dashboard</h3>
                <div *ngIf="isDialog" style="display: flex;"> 
                  <button mat-icon-button  (click)="clickOpenInNew()">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>    

                  <button  mat-icon-button mat-dialog-close> <i class="material-icons"
                      style="font-size: x-large;">close </i> </button>
                </div>
              </div>
              <div>
                <div class="form-group" style=" margin-bottom: 0.2em;">

                  <!--<div style=" font-size: small;text-align: end;">{{totalRecordsText}}</div>-->
                </div>
              

              </div>
              <div *ngIf="msg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:forestgreen; font-size: small">&nbsp;{{msg}}</label></div>
              <div *ngIf="errMsg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:red; font-size: small">{{errMsg}}</label></div>
            </div>
          </div>
          <div style="display:flex">
            <div>
              <div style=" margin-top: 1em;">
                <h4>Manage Fuel Pricing</h4>
                <hr />
                <button formcontrolname="fuelsupplierprofiles" mat-button class="btn-menu"  style="font-size: small;" (click)="openFuelSupplierProfileComponent()">
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    <span class="material-symbols-outlined">
                      manage_accounts
                    </span>
                  </span>Fuel Supplier Profiles
                </button>&nbsp;
                <button formcontrolname="fuelimport" mat-button class="btn-menu" style="font-size: small;" (click)="clickRouteNew('fuelpricing/fuelpricing','')">
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">publish</span>Fuel Import
                </button>&nbsp;
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;" (click)="clickRouteNew('fuelmenu/fuel-pricing-summary','')">
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    <span class="material-symbols-outlined">
                      request_quote
                    </span>
                  </span>Fuel Pricing
                </button>&nbsp;
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;" (click)="clickOpenInNewTab('fuelmenu/fuel-task-queuelist','')">
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    <span class="material-symbols-outlined">
                      task_alt
                    </span>
                  </span>Fuel Task Queue
                </button>&nbsp;
               
                <!--<button formcontrolname="invalidairports" mat-button class="btn-menu" (click)=  "clickRouteNew('fuelmenu/revise-fuel-pricing','')">
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    <span class="material-symbols-outlined">
                      feedback
                    </span>
                  </span>Invalid Airports
                </button>&nbsp;-->
              </div>
              <div style=" margin-top: 1em;">
                <h4>Backoffice</h4>
                <hr />
                <button formcontrolname="exchangerate" mat-button class="btn-menu"  style="font-size: small;" (click)="openCurrencyComponent()">
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">currency_exchange</span>Exchange Rate
                </button>&nbsp;
                <button formcontrolname="taxesandfees" mat-button class="btn-menu" style="font-size: small;" (click)="clickRouteNew('taxesandfees/taxesandfees','')">
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">order_approve</span>Taxes and Fees
                </button>&nbsp;
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;" (click)="openFuelClientMarkupComponent()">
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">paid</span>Markups
                </button>&nbsp;
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;" (click)="openFuelCommissionsComponent()">
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">payments</span>Commissions
                </button>&nbsp;
              </div>
              <div>
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" (click)="openTaxesAndFeesDescriptionDialog()">
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    description
                  </span>Taxes And Fees Description
                </button>&nbsp;

                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" (click)="openFuelExchangeRateMarkupComponent()">
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    paid
                  </span>Exchange Rate Markups
                </button>&nbsp;
              </div>
              <div style=" margin-top: 1em;">
                <h4>Reports</h4>
                <hr />
                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small;" (click)=  "openReviseFuelPricingComponent()"  >
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">priority_high</span>Import Invalid
                  Airport Report
                </button>&nbsp;
                <button formcontrolname="taxesandfees" mat-button class="btn-menu" style="font-size: small;"   (click)=  "openMissingTaxesAirportComponent()"  >
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">description</span>Missing Taxes
                  Airport List
                  
                </button>&nbsp;
                <!-- <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;" >
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">description</span>Client Setup
                  Locations
                </button>&nbsp;
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;"   >
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">description</span>Fuel Quotes
                  Metrics
                </button>&nbsp; -->
                <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small; " (click)="openMissingPricesComponent()">
                  <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                   description
                  </span>Missing Airport Fuel Prices
                </button>&nbsp;
              </div>

             
            </div>

            <div style=" margin-top: 1em; margin-left: 2em;">
              <h4>Trips Alerts and Preferences</h4>
              <hr />
              <button formcontrolname="managefuelalerts" mat-button class="btn-menu" style="font-size: small;" >
                <span style="margin-right: 0.2em; " class="material-symbols-outlined">brand_awareness</span>Manage Fuel Alerts
              </button>&nbsp;
              <button formcontrolname="clientfuelpreferences" mat-button class="btn-menu" style="font-size: small;" >
                <span style="margin-right: 0.2em; " class="material-symbols-outlined">assignment_turned_in</span>Client Fuel Preferences
              </button>&nbsp;
              <button formcontrolname="Vendorpreferences" mat-button class="btn-menu" style="font-size: small;" >
                <span style="margin-right: 0.2em; " class="material-symbols-outlined">assignment_turned_in</span>Vendor Preferences
              </button>&nbsp;
              <!-- <div class="chart-container" style="overflow-x: auto; width: 40em; max-width: 40em;">
                <div style=" height: 21em;  ">

                  <canvas #MyChart   >{{ chart }}</canvas>
                  <div style="text-align: center;">No of Airports For Fuel Supplier</div>
                </div>
              </div> -->
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
