<div style="background-color:#f8f8f8;">
    <div style="display: flex;padding-left:1em;" cdkDrag
         cdkDragRootElement=".cdk-overlay-pane"
         cdkDragHandle>
      <div style="margin-top:0.3em;">
        <i class="material-icons-outlined" style="font-size: x-large;cursor: pointer; color: gray">event_available</i>
      </div>
      <div style="margin-top:0.3125em;">
        <div><h5>Manage Route Sequence</h5></div>
        <div style="display: flex; margin-top:-0.5em">
          <div style="margin-right:1em" class="d-inline-flex">
            <div style="font-size: small">Registration:&nbsp;</div>
            <div style="font-weight: bold; font-size: small">{{registration}}</div>
          </div>
          <div style="margin-right:1em" class="d-inline-flex">
            <div style="font-size: small">Trip Code:&nbsp;</div>
            <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
          </div>
        </div>
        <div>
          <div class="d-inline-flex">
            <div style="font-size: small">Route: &nbsp;</div>
            <div style="font-size: small;display:flex;flex-wrap:wrap;max-width:35em;">
              <div *ngFor="let gs of groundStopList; let i=index">
                <div style="display: flex">
                  <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==groundStopList.length-1? '': '-'}}</div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </div>
        </div>
    <div>
      <app-spin *ngIf="showSpin"></app-spin>
      <div style="padding-left: 1em; padding-right: 1em;">
        <div style="background-color: white;max-height:25em;overflow-x:auto">
          <table mat-table *ngIf="groundStopList" [dataSource]="groundStopList" class="example-container">
            <tr>
              <ng-container matColumnDef="sequence_number">
                <th mat-header-cell *matHeaderCellDef width="20%">
                  Sequence Number
                </th>
                <td mat-cell *matCellDef="let element; let i = index"><input matInput type="number" min="1" class="form-control" [value]="groundStopList[i].groundStopSequenceID" 
                    style="margin-left: 2em; width: 3em; height: 1.4em; font-size: small; border-radius:0.25em" (change)="updateSequenceNumber($event, i)" (keyup)="updateSequenceNumber($event, i)"/></td>
              </ng-container>
              <ng-container matColumnDef="icao">
                <th mat-header-cell *matHeaderCellDef width="20%">
                  Airport Stop
                </th>
                <td mat-cell *matCellDef="let element" [style.background-color]="element.groundStopTypeColor">{{element.icao}}</td>
              </ng-container>
  
              <ng-container matColumnDef="date_time">
                <th mat-header-cell *matHeaderCellDef width="30%">
                  Date/Time (Z)
                </th>
                <td mat-cell *matCellDef="let element" style="text-align:left !important">
  
                  <div *ngIf="!element.isFirstGroundStop">A: {{element.arrivalDateTextUTC!=''?element.arrivalDateTimeUTCDisplay+' Z':'TBA'}}<br /></div>
                  <div *ngIf="!element.isLastGroundStop">D: {{element.departureDateTextUTC!=''?element.departureDateTimeUTCDisplay+' Z':'TBA'}}</div>
  
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef width="30%">
                  Airport Status
                </th>
                <td mat-cell *matCellDef="let element" [style.background-color]="element.groundStopTypeColor">{{element.groundStopTypeDescription}}</td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
  
          </table>
        </div>
      </div>
  
      <div class="d-inline-flex">
        <div class="p-2">
          <button [disabled]="!sequenceModified" mat-button class="btn-high mr-2" (click)="updateRouteSequence()">{{buttonText}}</button>
          <button mat-button class="btn-low" [mat-dialog-close]>Close</button>
        </div>
          <div *ngIf="showSuccessMsg">
          <app-success-message [successMsg]="successMsg"></app-success-message>
        </div>
        <div *ngIf="errMsg!=''" class="alert alert-danger small p-0 mt-2">
          {{errMsg}}
        </div>
      </div>
    </div>
    <!--<div *ngIf="isNext">
      <app-contingency-edit-dialog [selectedGS]="selectedGS" [tripCodeGUID]="tripCodeGUID"></app-contingency-edit-dialog>
    </div>-->
    </div>