<div *ngIf="upPII" style="background-color:#f8f8f8;padding-left:1em; padding-right:1em">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3125em;"><h5>Halt Services Details for Client {{customerName}}</h5></div>
  </div>
  <div>
    <form *ngIf="client_halt_services_view_form" [formGroup]="client_halt_services_view_form">
      <div class="d-inline-flex" >
        <div style="background-color:white;width:20em;padding-left:.5em">
          <!--<div style="margin-left: 0.5em;margin-top:.5em">
            HALT Services effective immediately
          </div>-->
          <div style="margin-left: 0.5em;margin-top:0.5em; font-size: small; font-weight:bold">
            Please do not preform any of the following Halted Services and contact FPI management for guidance.
          </div>
          <div style="margin-left: 1em; font-size: small;" class="small">
            <mat-checkbox color="primary" disabled
                          [checked]="haltAll">
              ALL SERVICES
            </mat-checkbox>

          </div>
          <div style="margin-left: 2.5em; font-size: small;" class="small">
            <mat-checkbox id="haltHandlingCk" color="primary" disabled
                          [checked]="haltHandling">
              Handling Services
            </mat-checkbox>

          </div>
          <div style="margin-left: 2.5em; font-size: small" class="small">
            <mat-checkbox color="primary" disabled
                          [checked]="haltPermits">
              Permits
            </mat-checkbox>
          </div>
          <div style="margin-left: 2.5em; font-size: small" class="small">
            <mat-checkbox color="primary" disabled
                          [checked]="haltFuel">
              Fuel Setup/Arrangements
            </mat-checkbox>
          </div>
          <div style="margin-left: 2.5em; font-size: small;" class="small">
            <mat-checkbox color="primary" disabled
                          [checked]="haltWeather">
              Weather Services
            </mat-checkbox>

          </div>
          <div style="margin-left: 2.5em; font-size: small" class="small">
            <mat-checkbox color="primary" disabled
                          [checked]="haltHotel">
              Hotel and Travel
            </mat-checkbox>

          </div>
          <div style="margin-left: 2.5em; font-size: small; " class="small">
            <mat-checkbox color="primary" disabled
                          [checked]="haltWebsite">
              Stop Access to FP Connect
            </mat-checkbox>

          </div>
          <div style="margin-left: 2.5em; font-size: small;" class="small">
            <mat-checkbox color="primary" disabled
                          [checked]="haltSSN">
              Special Services Network (SSN)
            </mat-checkbox>
          </div>
        </div>
        <div style="background-color:white;width:43em;margin-left:0.5em">
          <div style="display:flex">
            <div class="form-group" style="margin-top:0em;margin-left:1em; margin-right:0.625em">
              <label for="halt_remarks" style="font-size:small">Remarks</label>
              <div style="margin-top:-0.5em;">
                <textarea formControlName="halt_remarks" readonly class="form-control" style="width:50em; height:15em; font-size:small; padding:0em 0em 0em 0em;">
            </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
    <div style="margin-right:1em">
      <button type="button" mat-button class="btn-low" (click)="clickClose()">OK</button>
    </div>

  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
