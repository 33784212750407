<div style="margin-top:0em; padding-top:0em; display:flex">
  <form *ngIf="personlist_form" [formGroup]="personlist_form">

    <div style="margin-top:-1em">
      <div>
        <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container">
          <tr>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef style="width:20%; border-bottom:none">
                <div style="margin-top:-0.25em;">Name</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.lastName}}, {{element.firstName}}&nbsp;{{element.middleName}}</td>
            </ng-container>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef style="width:15%; border-bottom:none">
                <div style="margin-top:-0.25em;">Title</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.jobTitle}}</td>
            </ng-container>
            <ng-container matColumnDef="include_in_brief">
              <th mat-header-cell *matHeaderCellDef style="width:25%; border-bottom:none">
                <div style="margin-top:-0.25em;">Include In Brief</div>
              </th>
              <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.includeInBrief? 'Yes': '&nbsp;'}}</td>
            </ng-container>
            <ng-container matColumnDef="login">
              <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                <div style="margin-top:-0.25em;">Can Login</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.canLogin}}</td>
            </ng-container>
            <ng-container matColumnDef="disabled">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:-0.25em;">Disabled</div>
              </th>
              <td mat-cell *matCellDef="let element">{{element.isActive? '&nbsp;': 'Yes'}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                <div style="margin-top:-0.25em;">Action</div><div><a mat-button class="btn-high grid" style="align-self:center" (click)="clickAdd($event, null);">Add</a></div>
              </th>
              <td mat-cell *matCellDef="let element">
                <a mat-button class="btn-med grid" style="align-self:center" (click)="clickEdit($event, element);">
                  Edit
                </a>
              </td>
            </ng-container>
          </tr>

          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
      <div>
        <mat-paginator style="border: none;width:80em;" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                       [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
        </mat-paginator>
      </div>
      </div>
  </form>
</div>
    

<app-spin *ngIf="showSpin"></app-spin>
