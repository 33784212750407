import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, Inject, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of } from 'rxjs';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { DocumentCategoryModel } from '../models/document-category.model';
import { DocumentModel } from '../models/document.model';
import { FlightBriefModel } from '../models/flight-brief.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopService } from '../services/ground-stop.service';
import { VendorService } from '../services/vendor.service';
import { HoursObjModel } from '../models/hoursObj.model';
import { TemplateService } from '../services/template.service';
import { ServiceTypeModel } from '../models/service-type.model';
import { FlightBriefSectionModel } from '../models/flight-brief-sections.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';


@Component({
  selector: 'app-flight-brief-sections',
  templateUrl: './flight-brief-sections.component.html',
  styleUrls: ['./flight-brief-sections.component.css'],
  encapsulation: ViewEncapsulation.None
})

/** flight-brief-preferences component*/
export class FlightBriefSectionsComponent {
  /** flight-brief-preferences ctor */

  flight_brief_sections_form: UntypedFormGroup;
  documentList: DocumentModel[];
  documentCategoryList: DocumentCategoryModel[];
  flightBriefSectionList: FlightBriefSectionModel[];
  taskNoteList: ServiceTypeModel[];
  prelimNoteList: GroundStopTaskModel[]=[];
  @Input() gstId: string;
  @Input() tripCodeGUID: string;
  @Input() icao: string;
  @Input() tripCode: string;
  @Input() registration: string;
  @Input() selectedStops: GroundStopModel[];
  @Input() briefFormat: string = "a";
  displayedColumn: string[] = ['documentType', 'documentName', 'check', 'sortOrderNo'];
  selectionDoc = new SelectionModel<DocumentModel>(true, []);
  selectionSection = new SelectionModel<FlightBriefSectionModel>(true, []);
  selectionTask = new SelectionModel<ServiceTypeModel>(true, []);
  selectionPrelimTask = new SelectionModel<GroundStopTaskModel>(true, []);
  showSuccessMsg: boolean = false;
  isLoading: boolean = false;
  isLoadingWord: boolean = false;
  showSpin: boolean = false;
  errMsg: string = "";
  isUpdated: boolean = false;
  hasChanges: boolean = false;
  initSelectedSections: FlightBriefSectionModel[];
  initSelectedTasks: ServiceTypeModel[];
  initSelectedPrelimTasks: GroundStopTaskModel[];
  selectedDocuments: DocumentModel[];
  serviceTypeID: number = 34;
  ssnVendor: number;
  hoursData: HoursObjModel[];
  groundStopGUID: string;
  reportName: string = "Flight Brief";
  constructor(private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder, private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    private readonly _vendorService: VendorService, private readonly _groundStopService: GroundStopService, private readonly _dialogRef: MatDialogRef<FlightBriefSectionsComponent>, @Inject(MAT_DIALOG_DATA) private _data: any, private el: ElementRef, private readonly _templateService: TemplateService) {
    this.gstId = _data.gstId;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.selectedStops = _data.selectedStops;
    this.icao = _data.icao;
    this.tripCode = _data.tripCode;
    this.registration = _data.registration;
    this.briefFormat = _data.briefFormat;
    this.serviceTypeID = _data.serviceTypeID;
    if (_data.serviceTypeID == 79)
      this.reportName = "Trip Brief";
    if (_data.serviceTypeID == 32 || _data.serviceTypeID == 85 || _data.serviceTypeID == 87)
      this.reportName = "Wx Outlook";
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.documentCategoryList = [];
    this.showSpin = true;
    this.initControls();
    this.getData();

  }
  initControls() {
    this.flight_brief_sections_form = this._formBuilder.group({
      flight_brief_list: '',
      cover_note: '',
      summary_to: '',
      summary_address: ''
    });
  }

  get f() { return this.flight_brief_sections_form.controls; }

  getAllData(): Observable<any[]> {

    let request = new FlightBriefModel();

    request.groundStopTaskGUID = this.gstId;
    request.tripCodeGUID = this.tripCodeGUID;
    request.selectedGroundStopList = [];
    this.selectedStops.forEach(v => {
      request.selectedGroundStopList.push(v.groundStopGUID);
    });

    let sectionResponse = this._groundStopService.getFlightBriefSectionsBygstId<ResponseModel<FlightBriefSectionModel[]>>(this.gstId);
    let docResponse = this._groundStopAdvancedService.getGroundStopTaskDocumentForFlightBriefBygstId<ResponseModel<DocumentModel[]>>(request);
    let noteResponse = this._groundStopService.getFlightBriefTaskNoteBygstId<ResponseModel<ServiceTypeModel[]>>(request);
    let prelimPlanResponse;
    if (this.serviceTypeID == 32 || this.serviceTypeID == 85 || this.serviceTypeID == 87)
      prelimPlanResponse = this._groundStopService.getPrelimTaskNoteBygstId<ResponseModel<ServiceTypeModel[]>>(request);
    else
      prelimPlanResponse = of(null);
    return forkJoin([sectionResponse, docResponse, noteResponse, prelimPlanResponse]);
  }


  getData() {

    this.getAllData().subscribe(responses => {
      if (responses[0] != null && responses[0].code == "200") {
        this.flightBriefSectionList = responses[0].model;
        if (this.serviceTypeID == 79)
          this.flightBriefSectionList = this.flightBriefSectionList.filter(x => x.flightBriefReportSectionID < 5);
        if (this.serviceTypeID == 32 || this.serviceTypeID == 85 || this.serviceTypeID == 87)
          this.flightBriefSectionList = this.flightBriefSectionList.filter(x => x.flightBriefReportSectionID != 1 && x.flightBriefReportSectionID !=7);
        this.flightBriefSectionList.forEach(v => {
          v.selected == true ? this.selectionSection.toggle(v) : null;
        });
        this.initSelectedSections = this.flightBriefSectionList.filter(v => v.selected == true);
        this.updateAllComplete("section");


      }
      if (responses[1] != null && responses[1].code == "200") {
        if (responses[1].model.length > 0) {
          this.documentList = responses[1].model;
          this.documentList = this.documentList.filter(v => v.documentType == "Flight Brief" && v.fileExtension != "pdf");
          let tempCategetory: string = "";
          this.documentList.forEach(x => {
            if (tempCategetory != x.documentCategory) {
              tempCategetory = x.documentCategory;
              let y = new DocumentCategoryModel();
              y.documentCategory = x.documentCategory;
              y.documentList = this.documentList.filter(a => a.documentCategory == tempCategetory);
              let n = y.documentList.filter(d => d.selected == true).length;
              if (n > 0) {
                y.documentSelectedCountDisplay = "(Selected " + n.toString() + ")";
              }
              this.documentCategoryList.push(y);
            }
            x.selected == true ? this.selectionDoc.toggle(x) : null;
          });
          this.selectedDocuments = this.documentList.filter(v => v.selected == true);
          if (this.documentCategoryList.length > 0)
            this.documentCategoryList[0].panelOpenState = true;

        }
      }

      if (responses[2] != null && responses[2].code == "200") {
        this.taskNoteList = responses[2].model;
        if (this.serviceTypeID == 32 || this.serviceTypeID == 85 || this.serviceTypeID == 87) {
          this.taskNoteList = this.taskNoteList.filter(x => x.serviceTypeID != 31);
        }
        this.taskNoteList.forEach(v => {
          v.selected == true ? this.selectionTask.toggle(v) : null;
        });
        this.initSelectedTasks = this.taskNoteList.filter(v => v.selected == true);
        this.updateAllComplete("task");


      }

      if (responses[3] != null && responses[3].code == "200") {
        this.prelimNoteList = responses[3].model;
        this.prelimNoteList.forEach(v => {
          v.selected == true ? this.selectionPrelimTask.toggle(v) : null;
        });
        this.initSelectedPrelimTasks = this.prelimNoteList.filter(v => v.selected == true);
        this.updateAllComplete("prelim");


      }
      this.showSpin = false;
    });

    this.checkFormControlValueChanged();

  }


  checkFormControlValueChanged(): void {
    this.flight_brief_sections_form.valueChanges.subscribe(x => {
      this.hasChanges = true;
    });
  }

  allCompleteSection: boolean = false;
  allCompleteTask: boolean = false;
  allCompletePrelim: boolean = false;

  updateAllComplete(type: string) {
    switch (type) {
      case "section":
        this.allCompleteSection = this.flightBriefSectionList != null && this.flightBriefSectionList.every(t => t.selected);
        break;
      case "task":
        this.allCompleteTask = this.taskNoteList != null && this.taskNoteList.every(t => t.selected);
        break;
      case "prelim":
        this.allCompletePrelim = this.prelimNoteList != null && this.prelimNoteList.every(t => t.selected);
        break;
    }
    
      
  }

  someComplete(type: string): boolean {
    switch (type) {
      case "section":
        if (this.flightBriefSectionList == null) {
          return false;
        }
        return this.flightBriefSectionList.filter(t => t.selected).length > 0 && !this.allCompleteSection;        
      case "task":
        if (this.taskNoteList == null) {
          return false;
        }
        return this.taskNoteList.filter(t => t.selected).length > 0 && !this.allCompleteTask;
      case "prelim":
        if (this.prelimNoteList == null) {
          return false;
        }
        return this.prelimNoteList.filter(t => t.selected).length > 0 && !this.allCompletePrelim;
    }
  }

  setAll(completed: boolean, type: string) {
    switch (type) {
      case "section":
      this.allCompleteSection = completed;
      if (this.flightBriefSectionList == null) {
        return;
      }
      this.flightBriefSectionList.forEach(t => {
        t.selected = completed;
        if (completed)
          this.selectionSection.select(t);
        else
          this.selectionSection.deselect(t);
      });
        break;
      case "task":
        this.allCompleteTask = completed;
        if (this.taskNoteList == null) {
          return;
        }
        this.taskNoteList.forEach(t => {
          t.selected = completed;
          if (completed)
            this.selectionTask.select(t);
          else
            this.selectionTask.deselect(t);
        });
        break;
      case "prelim":
        this.allCompletePrelim = completed;
        if (this.prelimNoteList == null) {
          return;
        }
        this.prelimNoteList.forEach(t => {
          t.selected = completed;
          if (completed)
            this.selectionPrelimTask.select(t);
          else
            this.selectionPrelimTask.deselect(t);
        });
        break;
    }
  }

  checkFlightBriefSection(e: any, item: FlightBriefSectionModel) {
    this._authService.updateAccessTime();
    e ? this.selectionSection.toggle(item) : null;
    item.selected = e.checked;
    this.updateAllComplete("section");
  }

  checkTask(e: any, item: ServiceTypeModel) {
    this._authService.updateAccessTime();
    e ? this.selectionTask.toggle(item) : null;
    item.selected = e.checked;
    this.updateAllComplete("task");
  }

  checkPrelim(e: any, item: GroundStopTaskModel) {
    this._authService.updateAccessTime();
    e ? this.selectionPrelimTask.toggle(item) : null;
    item.selected = e.checked;
    this.updateAllComplete("prelim");
  }



  checkDocumentChange(e: any, item: DocumentModel, i: number) {
    this._authService.updateAccessTime();
    //this.errMsg = "";
    e ? this.selectionDoc.toggle(item) : null;
    item.selected = e.checked;
    let n = 0;
    let currentDoc: DocumentModel[];
    currentDoc = this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentList;
    currentDoc.forEach(y => {
      if (y.selected == true) {
        n += 1;
      }
    });

    if (n > 0) {
      this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentSelectedCountDisplay = "(Selected " + n.toString() + ")";
    }
    else {
      this.documentCategoryList.filter(x => x.documentCategory == item.documentCategory)[0].documentSelectedCountDisplay = "";
    }

    if (e.checked) {
      var control;
      control = this.el.nativeElement.querySelector('[id="sortOrder' + i.toString() + '"]');
      setTimeout(() => {
        control.focus();
      }, 50);
    }
  }

  setSortOrder(e: any, item: DocumentModel) {
    this._authService.updateAccessTime();
    item.sortOrderNo = Number(e.target.value);
  }

  nextStep(currentStep: number) {
    this._authService.updateAccessTime();
    this.documentCategoryList[currentStep].panelOpenState = false;
    if (currentStep < (this.documentCategoryList.length - 1)) {
      let next = currentStep + 1;
      this.documentCategoryList[next].panelOpenState = true;

    }

  }
  prevStep(currentStep: number) {
    this._authService.updateAccessTime();
    this.documentCategoryList[currentStep].panelOpenState = false;
    let prev = currentStep - 1;
    this.documentCategoryList[prev].panelOpenState = true;
  }

  openAll() {
    this._authService.updateAccessTime();
    this.documentCategoryList.forEach(x => x.panelOpenState = true);
  }

  closeAll() {
    this._authService.updateAccessTime();
    this.documentCategoryList.forEach(x => x.panelOpenState = false);
  }

  clickPreviewDocument(e: any, item: DocumentModel) {
    this._authService.updateAccessTime();
    let request = new DocumentModel();
    request.documentCategory = item.documentCategory;
    request.documentGUID = item.documentGUID;
    this._groundStopService.getTripDocumentById<ResponseModel<DocumentModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let doc = new DocumentModel();
          doc = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
          let file = new Blob([byteArray], { type: doc.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
        //else {
        //  this.msg = "No documents returned";
        //}
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }

    })

  }

  clickSave(showSuccess: boolean) {
    this.isLoading = true;
    this.errMsg = "";
    this.isUpdated = true;
    let selectedSections = this.flightBriefSectionList.filter(v => v.selected == true);
    let selectedTasks = this.taskNoteList.filter(v => v.selected == true);
    let selectedPrelimTasks = this.prelimNoteList.filter(v => v.selected == true);
    let flightBriefSectionsIds = Array.prototype.map.call(selectedSections, s => s.flightBriefReportSectionID).toString();
    let serviceIds = Array.prototype.map.call(selectedTasks, s => s.serviceTypeID).toString();
    let prelimIds = Array.prototype.map.call(selectedPrelimTasks, s => s.groundStopTaskGUID).toString();
    let request = new FlightBriefModel();
    request.groundStopTaskGUID = this.gstId;
    request.includedSections = flightBriefSectionsIds;
    request.includedTaskNotes = serviceIds;
    request.flightBriefFormat = this.briefFormat;
    request.wxOutlookPrelimPlanIDs = prelimIds;
    let includedDocuments = [];
    request.includedDocuments = "";
    if (this.documentList != null) {
      includedDocuments = this.documentList.filter(v => v.selected == true);

      includedDocuments.forEach(v => {
        if (request.includedDocuments != "")
          request.includedDocuments += ",";
        request.includedDocuments += v.documentGUID + "|" + v.sortOrderNo;
      });
    }
    request.docIsFlightBrief = true;

    this._groundStopAdvancedService.saveFlightBriefSettings<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.initSelectedSections = selectedSections;
          this.selectedDocuments = includedDocuments;
          if (showSuccess) {
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;

            }, 1000);
          }
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMsg = "An error has occurred."
        }
      }
      this.isLoading = false;
    });
  }

  buildBrief() {
    this.errMsg = "";
    this.clickSave(false);
    this.isLoadingWord = true;
    let request = new FlightBriefModel();
    let format = "word";

    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopTaskGUID = this.gstId;
    request.selectedGroundStopList = [];
    this.selectedStops.forEach(v => {
      request.selectedGroundStopList.push(v.groundStopGUID);
    });

    if (this.documentList != null) {
      let includedDocuments = this.documentList.filter(v => v.selected == true);
      includedDocuments.sort((a, b) => a.sortOrderNo - b.sortOrderNo);

      request.groundStopTaskDocuments = includedDocuments;
    }

    this._templateService.generateFlightBrief(request, this.briefFormat, this.flightBriefSectionList, this.tripCode, this.registration, format, this.reportName).subscribe(r => {
      if (r.remarks != "")
        this.errMsg = r.remarks;
      this.isLoadingWord = false;
    });

  }

  clickClose() {
    let obj = new ReturnObjModel();
    obj.refresh = this.isUpdated;
    obj.newId = this.briefFormat;
    obj.actionName = "sections";
    this._dialogRef.close(obj);
  }
}
