import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AircraftRegulatoryServiceModel } from '../models/aircraft-regulatory-service.model';
import { ResponseModel } from '../models/response.model';
import { AircraftService } from '../services/aircraft.service';
import { AuthenticateService } from '../services/authenticate.service';
import { DialogService } from '../services/dialog.service';
import { AircraftRegulatoryServicesEditDialogComponent } from './aircraft-regulatory-services-edit-dialog.component';

@Component({
    selector: 'app-aircraft-regulatory-services',
    templateUrl: './aircraft-regulatory-services.component.html',
    styleUrls: ['./aircraft-regulatory-services.component.css']
})
/** aircraft-regulatory-services component*/
export class AircraftRegulatoryServicesComponent implements OnInit, OnChanges {
  aircraft_regulatory_service_form: UntypedFormGroup;
  @Input() aircraftId: string;
  customerGuid: string;
  textSearch: string;
  filterBy: string;
  errMsg: string;
  showSpin: boolean;
  loading: boolean;
  msg: string;
  userType: string;
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  isActive: boolean;
  @Input() includeDisabledRecords: boolean
  totalRecordsText: string;
  recordList: AircraftRegulatoryServiceModel[];

  displayedColumn: string[] = ['service_name', 'arranged_by', 'service_date', 'authorization_number','effective_date','expiration_date','obtained_by','renewal_status','remarks','disabled','action'];
  /** aircraft-notes ctor */
  constructor(private _router: Router, private readonly _authService: AuthenticateService, private readonly _aircraftService: AircraftService, private readonly _dialogService: DialogService,
    private readonly _formBuilder: UntypedFormBuilder, private _route: ActivatedRoute, private readonly _dialog: MatDialog) {

  }

  ngOnInit() {
    

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.aircraft_regulatory_service_form = this._formBuilder.group({

    });

    // this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
      this.includeDisabledRecords = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getData();
  }


  initControls() {
    this.aircraft_regulatory_service_form = this._formBuilder.group({

    });
  }

  get f() { return this.aircraft_regulatory_service_form.controls; }

  getData() {
    this.recordList = [];
    let request = new AircraftRegulatoryServiceModel();
    request.aircraftGuid = this.aircraftId;
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = !this.includeDisabledRecords;
    //if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
    //  request.isActive = !this.f.includeDisabledRecords.value;
    //}

    this._aircraftService.getAircraftRegulatoryServicesByaId<ResponseModel<AircraftRegulatoryServiceModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          if (response.model.length > 0) {
            this.recordList = response.model;
            if (this.recordList[0].totalRecords > 0) {
              // this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
              this.totalRecords = this.recordList[0].totalRecords;
              this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
              this.msg = "";
            }
          }
          else {

          }
        }
      }
    });

  }

  clickEdit(event: any, reg: AircraftRegulatoryServiceModel) {
    let s = "";
    let aircraftRegulatoryServiceGuid = "";
    if (reg == null) {
      s = "ADD New Record";
    }
    else {
      s = "EDIT Record";
      aircraftRegulatoryServiceGuid = reg.aircraftRegulatoryServiceGuid;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
   // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, aircraftGuid: this.aircraftId, aircraftRegulatoryServiceGuid: aircraftRegulatoryServiceGuid };
    const dialogRef = this._dialog.open(AircraftRegulatoryServicesEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.totalRecords = 0;
        //this.currentPageIndex = 0;
        this.totalPageNumber = 0;
        this.getData();

      }

    });


  }


}
