<div style="height: 100%;" [ngClass]="isDialog? 'greyBack':''" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle [cdkDragDisabled]="!isDialog">
  <div style="display: inline-flex; justify-content: space-between; width: 100%;">
    <div style="margin-left:1em">
      <div style="display: inline-flex;">
        <i class="material-symbols-outlined" style="margin-top: 0.3em;">
          inventory
        </i>
        <div style="margin-left: 0.5em; margin-bottom: 0.5em;">
          <h3 style="margin-bottom: 0em;">Client Information</h3>
          <h5 style="color: #1976e6; font-size: 1em;">{{selectedClientName}}</h5>
        </div>
      </div>
    </div>
    <div *ngIf="isDialog">
      <button mat-button class="btn-low" (click)="openSelection('Acct Setup')">Client Profile</button>
      <button mat-button class="btn-low" style="margin-left: 0.5em;"
        (click)="openSelection('Aircraft')">Aircraft</button>
      <button mat-button class="btn-low" style="margin-left: 0.5em;"
        (click)="openSelection('Crew, PAX & Support')">Crew, PAX & Support</button>
      <button mat-button class="btn-low" style="margin-left: 0.5em;" (click)="openSelection('Admin Services')">Admin
        Services</button>
      <button mat-button class="btn-low" style="margin-left: 0.5em;">Feedback</button>
    </div>
    <div style="display: flex;">
      <button mat-icon-button *ngIf="isDialog" (click)="openClientProfileSetupComponent()"> <i
          class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i> </button>
      <button mat-icon-button *ngIf="isDialog" (click)="clickRouteNew('customers/customer-backoffice')"> <i
          class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>
      <button mat-icon-button *ngIf="isDialog" mat-dialog-close><i class="material-icons"
          style="font-size: x-large;">close </i></button>
    </div>
  </div>
  <div style="background-color: white; padding: 1em; width: 68em;">
    <div style="display: flex; flex-direction: column;">
      <h5>Filters</h5>
      <div style="display: inline-flex; justify-content: space-between;">
        <div style="width: 30em;">
          <mat-radio-button style="font-size: small; padding: 0.5em 0.5em 0.5em 0em;"
            (click)="filterActiveOrDisabledRecords(true)" checked>Show Active Records</mat-radio-button>
          <mat-radio-button style="font-size: small; padding: 0.5em;" (click)="filterDataEntryStatusRecords()">Data
            Entry Status</mat-radio-button>
          <mat-radio-button style="font-size: small; padding: 0.5em;"
            (click)="filterActiveOrDisabledRecords(false)">Show Disabled Records</mat-radio-button>
        </div>
        <div style="display: inline-flex; height: 1.5em; margin-top: 0.25em;">
          <button mat-button class="btn-high" style="margin-right: 1em;" (click)="openAddInfoComponent('comms')">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            <i *ngIf="!loading" class="material-icons">add</i>
            Add Comms
          </button>
          <button mat-button class="btn-high" style="margin-right: 1em;" (click)="openAddInfoComponent('mail')">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            <i *ngIf="!loading" class="material-icons">add</i>
            Add Mailing Address
          </button>
          <button mat-button class="btn-high" style="margin-right: 1em;" (click)="openAddInfoComponent('document')">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            <i *ngIf="!loading" class="material-icons">add</i>
            Add Document
          </button>
        </div>
        <div style="font-size: small; padding: 0.5em 0em 0em 0em;">
          {{totalRecordsText}}
        </div>
      </div>
      <table mat-table [dataSource]="filteredRecordList">
        <tr>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
              Type
            </th>
            <td mat-cell *matCellDef="let element">
              <i *ngIf="element.commAddressType !== undefined && element.commAddressType.toLowerCase().includes('phone')" class="material-symbols-outlined">call</i>
              <i *ngIf="element.commAddressType !== undefined && (element.commAddressType.toLowerCase() === 'email' || element.commAddressType.toLowerCase() === 'fax')" class="material-symbols-outlined">mail</i>
              <i *ngIf="element.mailAddressTypeList !== undefined" class="material-symbols-outlined">contact_mail</i>
              <i *ngIf="element.documentTypeList !== undefined" class="material-symbols-outlined">attach_file</i>
            </td>
          </ng-container>
          <ng-container matColumnDef="tag_information">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
              Tag Information
            </th>
            <td mat-cell *matCellDef="let element" style="align-content: center; text-align: left !important;">
              {{element.addressUseDescriptionList !== undefined ? element.addressUseDescriptionList : element.commAddressType}}
            </td>
          </ng-container>
          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
              Details
            </th>
            <td mat-cell *matCellDef="let element" style="align-content: center; text-align: left !important;">
              <a *ngIf="element.hasDocument" (click)="clickPreviewDoc($event, element);" class="pointer">{{element.displayDetails}}</a>
              {{element.hasDocument? '' : element.displayDetails}}
            </td>
          </ng-container>
          <ng-container matColumnDef="data_entry_status">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
              Data Entry Status
            </th>
            <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center;" 
              [ngStyle]="{'background-color': element.dataEntryStatusID === 1 || element.dataEntryStatusID === 3 ? '#f8d7da' : ''}">
              {{element.dataEntryStatusID === 1 ? "Pending Review" : (element.dataEntryStatusID === 2 ? "" :
              "Rejected")}}
            </td>
          </ng-container>
          <ng-container matColumnDef="option">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
              Option
            </th>
            <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center;">
              <button mat-button class="btn-low" (click)="openEditInfoComponent(element);">Edit</button>
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
    </div>
  </div>
</div>
