export class RemoteClearanceModel {
  groundStopGUID: string;
  arrivalDate: string;
  airportName: string;
  registration: string;
  lastName: string;
  firstName: string;
  middleName: string;
  dob: string;
  nationality: string;
  personPhone: string;
  personEmail: string;
  passportNumber: string;
  passportDOE: string;
  ukAddress: string;
  visaDOI: string;
  visaDOE: string;
}
