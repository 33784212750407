<table mat-table [dataSource]="airportDocuments" class="example-container">
  <tr>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef width="15%">
        Type
      </th>
      <td mat-cell *matCellDef="let element">{{element.documentTypeList}}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef width="30%">
        Name
      </th>
      <td mat-cell *matCellDef="let element"><a (click)="clickPreview(element)" class="pointer">{{element.documentName}}</a></td>
    </ng-container>
    <ng-container matColumnDef="effective_date">
      <th mat-header-cell *matHeaderCellDef width="10%">
        Effective Date
      </th>
      <td mat-cell *matCellDef="let element">{{element.effectiveDate}}</td>
    </ng-container>
    <ng-container matColumnDef="expiration_date">
      <th mat-header-cell *matHeaderCellDef width="10%">
        Expiration Date
      </th>
      <td mat-cell *matCellDef="let element">{{element.expirationDate}}</td>
    </ng-container>
    <ng-container matColumnDef="show_to_client">
      <th mat-header-cell *matHeaderCellDef width="10%">
        Show To Client
      </th>
      <td mat-cell *matCellDef="let element">{{element.showToClient? 'Yes' : 'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Action <br /><a *ngIf="airportID!=0" mat-button class="btn-high grid" (click)="clickEdit($event,null)">ADD</a>
      </th>
      <td mat-cell *matCellDef="let element; let i=index ">
        <a mat-button class="btn-low grid" (click)="clickEdit($event, element);">
          EDIT
        </a>
      </td>
    </ng-container>
  </tr>
  <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
</table>
