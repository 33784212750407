<div [id]="uniqueId" style="margin-top: 1em;">
  <div class="dialog-buttons minimizedDialog" cdkDrag cdkDragRootElement=".dialog-buttons" cdkDragHandle
    style="border-radius:5px; margin-bottom: -0.5em; margin-right: 1.4em; display: flex; justify-content: space-between;">


    <div *ngIf="isMinimized" style="margin-top: 0.5em; display: flex;">
      <mat-icon style="font-size: large; margin-top:0.2em; margin-left: 0.2em; margin-right: 0.2em;"> flight</mat-icon>
      <span>  {{countryData.countryName}}</span>
    </div>

    <!-- <button *ngIf = "!isMinimized" mat-icon-button (click)="minimizeDialog()">  <i class="material-icons" style="font-size:x-large;  ">remove</i> </button> -->
    <div style="display: flex;">
      <button *ngIf="isMinimized" mat-icon-button (click)="maximizeDialog()"> <i class="material-icons"
          style="font-size: large; margin-right: -1.2em; ">open_in_full</i> </button>
      <button *ngIf="isMinimized" mat-icon-button mat-dialog-close> <i class="material-icons"
          style="font-size: x-large;">close </i> </button>
    </div>
  </div>


  <div class="model-content" [ngClass]="{'minimized': isMinimized}">
    <div *ngIf="!isOpenedNew" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
         style="margin-top:-1em; display: flex; justify-content: space-between;">
      <div class="d-inline-flex">
        <div style="color:#146ec3; font-size: xx-large; padding-left: 0.8em;"> {{countryData.countryName}} </div>
        <div *ngIf="flagFile" style="width:3em;margin-top:.5em;margin-left:.5em">
          <img style="border: 1px solid lightgray" [src]="getFlag(flagFile)" />
        </div>
      </div>
      <div>
        <button *ngIf="!isMinimized && !isOpenedNew" mat-icon-button (click)="clickOpenInNew($event)">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>
        <button *ngIf="!isMinimized && !isOpenedNew" mat-icon-button (click)="minimizeDialog()">  <i class="material-icons" style="font-size:x-large;  ">remove</i> </button>
        <button *ngIf=" !isOpenedNew" mat-icon-button mat-dialog-close>
          <i class="material-icons" style="font-size: x-large;">close </i>
        </button>
      </div>
    </div>

    <div *ngIf="isOpenedNew"
         style="margin-top:-1em; display: flex; justify-content: space-between;">
      <div class="d-inline-flex">
        <div style="color:#146ec3; font-size: xx-large; padding-left: 0.8em;"> {{countryData.countryName}} </div>
        <div *ngIf="flagFile" style="width: 3em; margin-top: .5em; margin-left: .5em;">
          <img style="border: 1px solid lightgray" [src]="getFlag(flagFile)" />
        </div>
      </div>
    </div>

    <div class="row" style="width: 65em;">
      <div class="mat-cell" style=" width: 21%;  "></div>
      <span class="mat-cell" style=" width: 8%; margin-top: 1.5em; font-weight: bold;  ">Required</span>
      <span class="mat-cell" style=" width: 55.5%; margin-top: 1.5em; font-weight: bold;  ">Permit Notes</span>
      <span class="mat-cell" style=" width: 9%; margin-top: 1.5em; font-weight: bold;  ">Validity Time</span>
      <span class="mat-cell" style=" width: 3.5%; font-weight: bold; ">Reqd Docs</span>
    </div>
    <div class="example" style="margin-top:-0.5em; padding: 1em; margin-bottom: 0em; width: 65em;">
      <div>
        <div class="example" style="max-height: 36em; overflow-y: auto;overflow-x:hidden;">
          <mat-accordion multi>
            <mat-expansion-panel *ngFor="let segment of countryContentSegment" [expanded]="segment.countryContentSegment === selectedCountryContentSegment">
              <mat-expansion-panel-header class="row" [ngClass]="{  'redColor': getCategoryValue(segment.countryContentSegmentID,segment.countryContentSegment) === 'Restricted' ,
                    'blueColor': getCategoryValue(segment.countryContentSegmentID,segment.countryContentSegment) != 'Restricted'  }">

                <span *ngIf="getCategoryValue(segment.countryContentSegmentID,segment.countryContentSegment) === 'Restricted'" class="mat-cell" style=" width: 21.5%; font-weight: bold; max-width: 13em; ">{{segment.countryContentSegment}} &nbsp; <i class="material-icons-two-tone red ng-star-inserted" style="color: red; font-size: large; cursor: pointer;padding-left: 5px;  ">warning</i></span>
                <span *ngIf="getCategoryValue(segment.countryContentSegmentID,segment.countryContentSegment) != 'Restricted'" class="mat-cell" style=" width: 21.5%; font-weight: bold; max-width: 13em; ">{{segment.countryContentSegment}}</span>

                <span *ngIf="(checkExistence(false,segment.countryContentSegment, segment.countryContentSegmentID, segment.countryContentSegment))" class="mat-cell" style=" width: 10.5%; ">{{getCategoryValue(segment.countryContentSegmentID,segment.countryContentSegment)}}</span>
                <span *ngIf="(checkExistence(false,segment.countryContentSegment, segment.countryContentSegmentID,notes )) && getCategoryValue(segment.countryContentSegmentID,segment.countryContentSegment) != 'No'" class="mat-cell" style=" width: 69.5%;">{{getCategoryValue(segment.countryContentSegmentID,notes)}}</span>
                <span *ngIf="(checkExistence(false,segment.countryContentSegment, segment.countryContentSegmentID, validTime))  && getCategoryValue(segment.countryContentSegmentID,segment.countryContentSegment) != 'No'" class="mat-cell" style=" width: 10.5%; ">{{getCategoryValue(segment.countryContentSegmentID,validTime)}}</span>
                <span *ngIf="segment.countryContentSegment?.toLowerCase()  != countryNotesSec  && getCategoryValue(segment.countryContentSegmentID,segment.countryContentSegment) != 'No'" class="mat-cell" style=" width: 4.5%; ">{{getCountryContentSegmentDocs(segment.countryContentSegmentID) != undefined? getCountryContentSegmentDocs(segment.countryContentSegmentID).length : 0}}</span>
              </mat-expansion-panel-header>
              <div *ngIf="countryAccess" align="end">
                <button type="button" mat-button class="btn-low grid" style="margin-right: -2em;"
                        (click)="editSegmentDetails(segment, countryData)">
                  EDIT
                </button>

              </div>
              <div *ngIf="getCategoryValue(segment.countryContentSegmentID,segment.countryContentSegment) != 'No'" style="margin-left: -1em;">


                <div *ngFor="let item of countryData.countryContenCategoryList" style="margin: 1em 0; width: 145%; margin-left:  0em;">
                  <ng-container *ngIf="item.countryContentSegmentID === segment.countryContentSegmentID">
                    <div *ngIf="checkExistence(true,segment.countryContentSegment, segment.countryContentSegmentID, item.countryContentCategory)" class="row">
                      <span class="col-2"><b> {{item.countryContentCategory}} </b></span>
                      <span class="col-7" style="margin-left: -5em; white-space: pre-wrap;border: 0px solid black;  border-radius: 4px;  background: #eaf0fd;">{{getCategoryValue(segment.countryContentSegmentID, item.countryContentCategory)}}</span>
                    </div>
                  </ng-container>
                </div>


                <div *ngIf="segment.countryContentSegment?.toLowerCase()  == countryNotesSec">
                  <div style="font-weight: bold;">Documents:</div>
                  <div *ngIf="countryDocuments" style="margin-bottom: 1em; padding-bottom: 1em;">
                    <table mat-table [dataSource]="countryDocuments" class="example-container mat-elevation-z8"
                           style="width: 50em !important; margin: 1em; margin-left: 0em;  ">
                      <tr>
                        <ng-container matColumnDef="Type">
                          <th mat-header-cell *matHeaderCellDef style="width:13%; text-align:left !important">
                            <div style="margin-top:-0.25em;">Document Type</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 13%;">
                            <div style="display: flex;">
                              <div>{{element.documentTypeList}}</div>
                            </div>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="Name">
                          <th mat-header-cell *matHeaderCellDef style="width:13%; text-align:left !important">
                            <div style="margin-top:-0.25em;">Document Name</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 13%;">
                            <div style="display: flex;">
                              <div>
                                <a href="javascript:void(0);"
                                   (click)="downloadDocument(element.containerName, element.containerPath, element.documentName, element.mimeType)">{{element.documentName}}</a>
                              </div>
                            </div>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="Effective_Date">
                          <th mat-header-cell *matHeaderCellDef style="width:5%; text-align:left !important">
                            <div style="margin-top:-0.25em;">Effective Date</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 5%;">
                            <div style="display: flex;">
                              <div>{{element.effectiveDate}}</div>
                            </div>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="Expiration_Date">
                          <th mat-header-cell *matHeaderCellDef style="width:5%; text-align:left !important">
                            <div style="margin-top:-0.25em;">Expiration Date</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 5%;">
                            <div style="display: flex;">
                              <div>{{element.expirationDate}}</div>
                            </div>
                          </td>
                        </ng-container>
                      </tr>
                      <tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumn2" [ngClass]="{ 'redColor': isDateExpired(row.expirationDate)  }"></tr>
                    </table>
                  </div>
                </div>

                <div *ngIf="segment.countryContentSegment?.toLowerCase()  != countryNotesSec" class="row">
                  <div class="col">
                    <div style="display: flex;">
                      <b>Required Permit Documents</b> <span *ngIf="getCategoryValue(segment.countryContentSegmentID,docsRequiredVar) == 'Y'" style="margin-left: 1em;">- No Documents Required</span>
                      <i *ngIf="checkDocumentsExistence(segment.countryContentSegmentID)" class="material-icons" title="Download All Documents"
                         style=" font-size: medium; cursor: pointer;padding-left: 5px;" (click)="downloadAllRequiredAttachments(segment.countryContentSegmentID)">
                        cloud_download
                      </i>
                      <i *ngIf="checkCopyExistence(segment.countryContentSegmentID)" title="Copy All Document Names" class="material-icons-outlined" style="font-size: small; margin-top: 0.5em; color:royalblue;  cursor: pointer; margin-left: 0.25em;"
                         (click)="copyToClipboardAll(segment.countryContentSegmentID)">content_copy</i>

                    </div>
                    <table>
                      <ng-container *ngFor="let doc of getCountryContentSegmentDocs(segment.countryContentSegmentID)">
                        <tr>
                          <!-- *ngIf=" (doc.showToClient && userType != 'internal') || userType === 'internal'" -->
                          <td>
                            <div style="display: flex;">
                              <div *ngIf="doc.containerName != null" style="display: flex; ">
                                <a href="javascript:void(0);"
                                   (click)="previewDocument(doc.containerName, doc.containerPath,   doc.mimeType)" style="font-weight: bold;">
                                  -&nbsp;{{doc.documentType}}
                                </a>
                                <i *ngIf="doc.containerName != null" class="material-icons" title="download {{doc.documentType}}" style=" font-size: medium; cursor: pointer;padding-left: 5px;"
                                   (click)="downloadDocument(doc.containerName, doc.containerPath, doc.documentType, doc.mimeType)">cloud_download</i>
                              </div>
                              <div *ngIf="doc.containerName == null" style="font-weight: bold;  ">-&nbsp;{{doc.documentType}}</div>
                              <div *ngIf="doc.internalNotes != null" matTooltipClass="tooltipLineBreak" matTooltip="{{doc.internalNotes}}" style="margin-left: 1em;">-&nbsp;{{doc.internalNotes?.length>80 ?  doc.internalNotes?.substr(0,80)+'...' : doc.internalNotes?.substr(0,80)}}</div>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </table>
                    <!-- <div style="display: flex; flex-wrap: wrap;">          !(doc.isActive && !(doc.showToClient && userType === 'internal'))
                    <div *ngFor="let doc of getCountryContentSegmentDocs(segment.countryContentSegmentID)"
                         style="flex: 0 0 22%; max-width: 25%; border: 1px solid #ddd; padding: 10px; box-sizing: border-box; margin: 0.1em;">
                      <a *ngIf="doc.containerName != null" href="javascript:void(0);" (click)="downloadDocument(doc.containerName, doc.containerPath, doc.documentType, doc.mimeType)">
                        {{doc.documentType}}
                      </a>
                      <div *ngIf="doc.containerName == null" >{{doc.documentType}}</div>

                    </div>
                  </div> -->
                  </div>
                </div>

                <div *ngIf="segment.countryContentSegment?.toLowerCase()  != countryNotesSec && getCountryContentSegmentMiscDocs(segment.countryContentSegmentID)?.length>0" style="margin-top: 1.5em;" class="row">
                  <div class="col">
                    <div style="display: flex;">
                      <b>Miscellaneous Documents</b> <span *ngIf="getCategoryValue(segment.countryContentSegmentID,docsRequiredVar) == 'Y'" style="margin-left: 1em;">- No Documents Required</span>
                      <i *ngIf="checkMiscDocumentsExistence(segment.countryContentSegmentID)" class="material-icons" title="Download All Documents"
                         style=" font-size: medium; cursor: pointer;padding-left: 5px;" (click)="downloadAllRequiredMiscAttachments(segment.countryContentSegmentID)">
                        cloud_download
                      </i>
                      <i *ngIf="checkCopyExistenceMisc(segment.countryContentSegmentID)" title="Copy All Document Names" class="material-icons-outlined" style="font-size: small; margin-top: 0.5em; color:royalblue;  cursor: pointer; margin-left: 0.25em;"
                         (click)="copyToClipboardAllMisc(segment.countryContentSegmentID)">content_copy</i>

                    </div>
                    <table>
                      <ng-container *ngFor="let doc of getCountryContentSegmentMiscDocs(segment.countryContentSegmentID)">
                        <tr>

                          <td>
                            <div style="display: flex;">
                              <div *ngIf="doc.containerName != null" style="display: flex; ">
                                <a href="javascript:void(0);"
                                   (click)="previewDocument(doc.containerName, doc.containerPath,   doc.mimeType)" style="font-weight: bold;">
                                  -&nbsp;{{doc.documentType}}
                                </a>
                                <i *ngIf="doc.containerName != null" class="material-icons" title="download {{doc.documentType}}" style=" font-size: medium; cursor: pointer;padding-left: 5px;"
                                   (click)="downloadDocument(doc.containerName, doc.containerPath, doc.documentType, doc.mimeType)">cloud_download</i>
                              </div>
                              <div *ngIf="doc.containerName == null" style="font-weight: bold;  ">-&nbsp;{{doc.documentType}}</div>
                              <div *ngIf="doc.internalNotes != null" matTooltipClass="tooltipLineBreak" matTooltip="{{doc.internalNotes}}" style="margin-left: 1em;">-&nbsp;{{doc.internalNotes?.length>80 ?  doc.internalNotes?.substr(0,80)+'...' : doc.internalNotes?.substr(0,80)}}</div>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </table>

                  </div>
                </div>

                <div *ngIf="segment.countryContentSegment?.toLowerCase()  != countryNotesSec" class="row" style="margin-bottom: 1em;margin-top: 1em; margin-left: 0em;">
                  <div style="width: 25em;   padding: 0 0 0 14px;">
                    <div style="display:flex; margin-top: 1.5em; margin-left: -1em;">
                      <div style="  width: 25em;">

                        <div *ngIf="checkExistence(false,segment.countryContentSegment, segment.countryContentSegmentID, prefileFilingTime)" style="display:flex; width: 24em; padding: 5px;">
                          <label for="prefileFilingTime" style="font-size:medium; width: 9em;">Prefile filing time</label>
                          <div style="width: 15em;">
                            <span> {{getCategoryValue(segment.countryContentSegmentID,prefileFilingTime)}} </span>
                          </div>
                        </div>
                        <div *ngIf="checkExistence(false,segment.countryContentSegment, segment.countryContentSegmentID, validTime)" style="display:flex; width: 24em; padding: 5px">
                          <label for="validTime" style="font-size:medium; width: 9em;">Validity time</label>
                          <div style="width: 15em;">
                            <span> {{getCategoryValue(segment.countryContentSegmentID,validTime)}} </span>
                          </div>
                        </div>

                        <div *ngIf="checkExistence(false,segment.countryContentSegment, segment.countryContentSegmentID, leadTime)" style="display:flex; width: 24em; padding: 5px;">
                          <label for="leadTime" style="font-size:medium; width: 9em;">Lead time</label>
                          <div style="width: 15em;">
                            <span> {{getCategoryValue(segment.countryContentSegmentID,leadTime)}} </span>
                          </div>
                        </div>
                        <div *ngIf="checkExistence(false,segment.countryContentSegment, segment.countryContentSegmentID, dueDate)" style="display:flex; width: 24em; padding: 5px;">
                          <label for="dueDate" style="font-size:medium; width: 9em;">Due date</label>
                          <div style="width: 15em;">
                            <span> {{getCategoryValue(segment.countryContentSegmentID,dueDate)}} </span>
                          </div>
                        </div>

                      </div>

                      <!-- <div style="width: 0.5em; padding: 0 1em; margin-top: -0.5em; margin-bottom: 0.5em;">
                      <div style="width: 0.05em; height: 60%;margin-top: 1em;float: right;   "></div>
                    </div>

                    <div style="   width: 11em;">



                    </div>   -->
                    </div>
                  </div>
                  <div *ngIf="checkExistence(false,segment.countryContentSegment, segment.countryContentSegmentID, leadTime)" style="width: 0.5em;">
                    <div style="width: 0.2em; height: 90%;margin-top: 1em;float: right; background-color: #b0b0b0; ">
                    </div>
                  </div>
                  <div style="width: 41em;">

                    <ng-template ngFor let-element [ngForOf]="getVendorData(segment.countryContentSegment)"
                                 let-i="index">

                      <div style="margin-left: 1em; margin-top: 1.5em;">
                        <div>
                          <div class="row">
                            <span class="col-1"><b>Vendor</b></span>
                            <label class="col-10" style="font-weight: bold;padding-left: 2.5em;">{{element.vendorName}}</label><span *ngIf="element.RatingDescription === 'Preferred'">*</span>
                          </div>
                        </div>
                        <div class="row">
                          <span class="col-1"><b>Comms</b></span>
                          <div class="col-11" style="padding-left: 2.5em;">
                            <ng-template ngFor let-comm [ngForOf]="element.vendorComms" let-i="index">

                              <div class="row justify-content-end font-small">
                                <div class="col-3 align-items-end" style="padding-right: 0;">
                                  <label *ngIf="comm.commAddressType !='Weblink'">{{comm.commAddressType}}</label>

                                  <button *ngIf="comm.commAddressType=='Weblink'" style="margin-left: -10.1em;" type="button" mat-button class="btn-low" (click)="openLink(comm.address)" target="_blank"
                                          matTooltipClass="tooltipLineBreak" matTooltip="{{comm.address}}" rel="noopener noreferrer">
                                    Weblink
                                  </button>

                                </div>
                                <div class="col-6" style="word-wrap:break-word; padding-left: 1em;">
                                  <!-- <a *ngIf="comm.commAddressType=='Weblink'" href="{{comm.address}}" target="_blank"
                                  matTooltipClass="tooltipLineBreak" matTooltip="{{comm.address}}"
                                  rel="noopener noreferrer">{{comm.address}}</a> -->
                                  <label *ngIf="comm.commAddressType!='Weblink'" style="max-width: 17em;">
                                    {{comm.address}} <i *ngIf="comm.commAddressType =='Email'" title="Copy Email" class="material-symbols-outlined" style="font-size: small;  color:royalblue;  cursor: pointer; margin-left: 0.25em;"
                                                        (click)="copyToClipboard(comm.address)">content_copy</i>
                                  </label>
                                  <label *ngIf="comm.commAddressType=='Weblink'" style="margin-left: -10.1em;">{{comm.commRemarks}}</label>

                                </div>
                                <div class="col-3" *ngIf="comm.commAddressType!='Weblink'">
                                  <label>{{comm.commRemarks}}</label>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>

              </div>
            </mat-expansion-panel>


            <mat-expansion-panel style="margin-right: -0.95em;">
              <mat-expansion-panel-header class="mat-cell"
                                          style=" font-weight: bold; background: #dbf0f6 !important; margin-left: -1em; ">Business Rules({{totalRecords}}) </mat-expansion-panel-header>
              <!-- Business Rules -->
              <div style="display: flex; justify-content: space-between;">
                <div style="margin-left:-0.5em">
                  <mat-slide-toggle style="transform:scale(.9); height:2.5em; font-size: larger;" color="primary"
                                    [checked]="showAllServices" (change)="showAllServicesChange($event)">Show All Services</mat-slide-toggle>
                </div>
                <div *ngIf="upbRule">
                  <button type="button" mat-button class="btn-high grid" style="margin : 0.5em;">
                    <span style="font-size: small;"
                          (click)="clickEdit($event,null)">+Add</span>
                  </button>
                </div>
              </div>
              <div class="mt-2">

                <table style="width: 69.5em !important;" mat-table [dataSource]="sortedData" matSort matSortActive="brID" matSortDirection="asc"
                       (matSortChange)="sortData($event)" class="example-container table table-bordered">
                  <!-- Header row first group -->
                  <tr>

                    <ng-container matColumnDef="businessRule">

                      <th mat-header-cell *matHeaderCellDef
                          [style.text-align]="center"
                          [attr.colspan]="5">
                        Business Rule
                      </th>
                    </ng-container>

                    <!-- Header row second group -->
                    <ng-container matColumnDef="period">
                      <th mat-header-cell *matHeaderCellDef [attr.colspan]="5"> Period </th>
                    </ng-container>
                    <ng-container matColumnDef="interest">
                      <th mat-header-cell *matHeaderCellDef [attr.colspan]="2"> Areas of Interest</th>
                    </ng-container>
                    <ng-container matColumnDef="services">
                      <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"> Services, Tasks & Vendors</th>
                    </ng-container>
                    <!-- <ng-container matColumnDef="action">
                    <th class="mat-header-cell2" [attr.rowspan]="2" mat-header-cell *matHeaderCellDef>
                      Action <br /><a mat-button class="btn-high grid" (click)="clickEdit($event,null)">Add</a>
                    </th>

                  </ng-container> -->
                  </tr>
                  <tr>
                    <ng-container matColumnDef="clientName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="clientName">
                        Client
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="registration">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="registration">
                        Tail
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.registrationList==''? 'All': element.registrationList}}</td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="mtow">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="mtow">
                      MTOW
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.mtowMin==0 && element.mtowMax==0 ? '':element.mtowMin}} - {{element.mtowMin==0 && element.mtowMax==0 ? '':element.mtowMax}}</td>
                  </ng-container> -->
                    <ng-container matColumnDef="crew">
                      <th mat-header-cell *matHeaderCellDef>
                        Crew
                      </th>
                      <td mat-cell *matCellDef="let element" matTooltip="{{element.crewList}}">{{element.crewList==''? 'All' : element.crewCount}}</td>
                    </ng-container>
                    <ng-container matColumnDef="pax">
                      <th mat-header-cell *matHeaderCellDef>
                        Pax
                      </th>
                      <td mat-cell *matCellDef="let element" matTooltip="{{element.paxList}}">{{element.paxList==''? 'All' : element.paxCount}}</td>
                    </ng-container>
                    <ng-container matColumnDef="farType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="farType">
                        FAR Type
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.farTypeDescription==null? 'All':element.farTypeDescription}}</td>
                    </ng-container>
                    <ng-container matColumnDef="isUTC">
                      <th mat-header-cell *matHeaderCellDef>
                        UTC or<br />Local
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.isUTC? 'UTC' : 'Local'}}</td>
                    </ng-container>
                    <ng-container matColumnDef="effectiveDate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="effectiveDate">
                        Effective<br />Date
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.effectiveDate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="expirationDate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="expirationDate">
                        Expiration<br />Date
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.expirationDate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="timeFrame">
                      <th mat-header-cell *matHeaderCellDef>
                        Time Frame
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.hoursStart}} - {{element.hoursEnd}}</td>
                    </ng-container>
                    <ng-container matColumnDef="daysOfWeek">
                      <th mat-header-cell *matHeaderCellDef>
                        Days of<br />The Week
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{element.sun? 'S':''}}{{element.mon? 'M':''}}{{element.tue? 'T':''}}{{element.wed? 'W':''}}{{element.thu? 'T':''}}{{element.fri? 'F':''}}{{element.sat? 'S':''}}{{element.sun==false && element.mon==false && element.tue==false && element.wed==false && element.thu==false && element.fri==false && element.sat==false? 'All':''}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="legType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="legType">
                        Leg Type
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.legType=='' || element.legType==null? 'All':element.legType}}</td>
                    </ng-container>
                    <ng-container matColumnDef="avoid">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="avoid">
                        Avoid
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.avoidLocation? 'Yes':''}}</td>
                    </ng-container>
                    <ng-container matColumnDef="serviceType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="serviceType">
                        Service Type
                      </th>

                      <td mat-cell *matCellDef="let element">{{element.serviceTypeDescription=='' || element.serviceTypeDescription==null? 'All':element.serviceTypeDescription}}</td>
                    </ng-container>
                    <ng-container matColumnDef="taskStatus">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="taskStatus">
                        Task Status
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.taskStatusDescription}}</td>
                    </ng-container>
                    <ng-container matColumnDef="vendor">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="vendor">
                        Vendor
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.vendorName=='' || element.vendorName==null? 'All':element.vendorName}}</td>
                    </ng-container>

                  </tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumn4; sticky: true"></tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumn3; sticky: true"></tr>
                  <tr mat-row (click)="clickEdit($event, row)" *matRowDef="let row; columns: displayedColumn3"></tr>
                </table>
              </div>

              <!-- <div style="width:44em;height:30em">

              <app-azure-map-inset [defaultLat]="0" [defaultLon]="0"></app-azure-map-inset>
            </div> -->
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>


    <!--
      <mat-dialog-actions *ngIf="groundStopGUID!=''" align="left">
        <button mat-dialog-close mat-button class="btn-low">Close</button>
      </mat-dialog-actions> -->

    <app-spin *ngIf="isLoading"></app-spin>
  </div>
</div>
