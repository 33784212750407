import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { ResponseModel } from '../models/response.model';
import { TripModel } from '../models/trip.model';
import { AirportService } from '../services/airport.service';
import { AuthenticateService } from '../services/authenticate.service';
import { DialogService } from '../services/dialog.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { GroundStopService } from '../services/ground-stop.service';
import { TripLegService } from '../services/trip-leg.service';

export interface SelectedGroundStop {
  groundStop: GroundStopModel;
  isCancel: boolean;
  prevGroundStopGUID: string;
  nextGroundStopGUID: string;
} 

@Component({
  selector: 'app-cancel-airport-dialog',
  templateUrl: './cancel-airport-dialog.component.html',
  styleUrls: ['./cancel-airport-dialog.component.css']
})
export class CancelAirportDialogComponent implements OnInit {

  aircraftGUID: string;
  tripCodeGUID: string;
  customerGUID: string;
  errMsg: string="";
  groundStopList: GroundStopModel[] = [];
  activeGroundStopList: GroundStopModel[] = [];
  displayedColumn: string[] = ['select', 'icao', 'date_time', 'status'];
  tripCode: string;
  registration: string;
  showSpin: boolean = false;
  selectedGS: SelectedGroundStop;
  buttonText: string = "Select";
  showSuccessMsg: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  constructor(private readonly _dialogRef: MatDialogRef<CancelAirportDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _dialogService: DialogService,
    private readonly _groundStopService: GroundStopService, private readonly _airportService: AirportService, private readonly _groundStopClientService: GroundStopClientService,
    private readonly _dialog: MatDialog, private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _tripLegService: TripLegService
  ) {
    this.aircraftGUID = _data.aircraftGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.customerGUID = _data.customerGUID;
    this.tripCode = _data.tripCode;
    this.registration = _data.registration;
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.getData();
  }


  getData() {
    this.showSpin = true;
    let request = new AircraftTripModel();
    request.aircraftGUID = this.aircraftGUID;
    request.customerGUID = this.customerGUID;
    request.startDateText = localStorage.getItem('trip.sd');
    request.endDateText = localStorage.getItem('trip.ed');
    request.tripCodeGUID = this.tripCodeGUID;
    this._groundStopClientService.getTripBytId<ResponseModel<TripModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.groundStopList = response.model.groundStopList;
          this.activeGroundStopList = this.groundStopList.filter(x => x.groundStopTypeGUID.toUpperCase() != "A2C7BF22-D054-4564-949B-F0D55D1F6B10");
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMsg = response.message;
        }

      }
      this.showSpin = false;
    });
  }

  updateSelection(e: any, item: GroundStopModel,i:number) {
    if (item.cancelled)
      this.buttonText = "Save";
    else
      this.buttonText = "Next";
    let isActive: boolean = false;
    let prevGS = null;
    let nextGS = null;
    if (i > 0) {
      //while (!isActive && i>-1) { --removed do to uncancelling on hold
      //  if (!this.groundStopList[i - 1].cancelled)
      //    prevGS = this.groundStopList[i - 1].groundStopGUID;
      //  i--;
      //}
      prevGS = this.activeGroundStopList[i - 1].groundStopGUID;
    }
    if (i < this.activeGroundStopList.length-1) {
      //while (!isActive && i < this.groundStopList.length) {--removed do to uncancelling on hold
      //  if (!this.groundStopList[i + 1].cancelled)
      //    nextGS = this.groundStopList[i + 1].groundStopGUID;
      //  i++;
      //}
      nextGS = this.activeGroundStopList[i + 1].groundStopGUID;
    }
    this.selectedGS = { groundStop: item, isCancel: !item.cancelled, prevGroundStopGUID:prevGS, nextGroundStopGUID:nextGS }
    
  }

  clickSave() {
    //let msg;
    this.errMsg = "";
    if (this.selectedGS.isCancel == false) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Click YES to uncancel " + this.selectedGS.groundStop.icao + ".");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.showSpin = true;
          let request = new GroundStopModel();
          request.groundStopGUID = this.selectedGS.groundStop.groundStopGUID;
          request.prevGroundStopGUID = this.selectedGS.prevGroundStopGUID;
          request.nextGroundStopGUID = this.selectedGS.nextGroundStopGUID;
          this._groundStopService.uncancelGroundStopBygsId<ResponseModel<boolean>>(request).subscribe(response => {
            if (response != null) {
              if (response.code == "200" && response.message == "") {
                this.showSuccessMsg = true;
                setTimeout(() => {
                  this.showSuccessMsg = false;
                  this._dialogRef.close(true);
                });
              }
              else {
                if (response.code == "401")
                  this._authService.signOut();
                else
                  this.showSpin = false;
                  this.errMsg = "Unable to save groundstop.";
              }
            }
          });
        }
        else {
          this._dialogRef.close();
        }
      });
    }
    else {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Click YES to cancel " + this.selectedGS.groundStop.icao + " and all related services.\n\n NOTE: Please check the <Pending> message queue for outgoing cancellation messages. Also review the trip manifest for adjustments.",false);
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.showSpin = true;
          let request = new GroundStopModel();
          request.groundStopGUID = this.selectedGS.groundStop.groundStopGUID;
          request.prevGroundStopGUID = this.selectedGS.prevGroundStopGUID;
          request.nextGroundStopGUID = this.selectedGS.nextGroundStopGUID;
          request.icao = this.selectedGS.groundStop.icao;
          request.groundStopSequenceID = this.selectedGS.groundStop.groundStopSequenceID;
          request.tripCodeGUID = this.tripCodeGUID;
          request.ete = this.selectedGS.groundStop.ete;
          this._groundStopService.cancelGroundStopBygsId<ResponseModel<boolean>>(request).subscribe(response => {
            if (response != null) {
              if (response.code == "200" && response.message == "") {
                this.showSuccessMsg = true;
                setTimeout(() => {
                  this.showSuccessMsg = false;
                  this._dialogRef.close(true);
                });
              }
              else {
                if (response.code == "401")
                  this._authService.signOut();
                else {
                  this.showSpin = false;
                  this.errMsg = "Unable to save groundstop.";
                }
              }
            }
          });
        }
        else {
          this.showSpin = false;
          this._dialogRef.close();
        }
      });
    }
  }
  
 }

   
  
  

