<div style="background-color:#f8f8f8;  height: 16em;  ">
    <div style="padding: 0.5em; padding-bottom: 0em; background-color:white ; height: 100%;">

        <div style="display: flex; justify-content: space-between;  " cdkDrag cdkDragRootElement=".cdk-overlay-pane"
            cdkDragHandle>
            <div style="margin-right:1em;display: flex; padding: 0.5em;"><span class="material-symbols-outlined">
                    description </span>
                <h5>Vendor List for - <span style="color: dodgerblue;"> {{icao}}</span> </h5>
            </div>


            <button mat-icon-button (click)="clickClose()"> <i class="material-icons" style="font-size: x-large;">close
                </i>
            </button>
        </div>
        <form *ngIf="advisory_vendor_form" [formGroup]="advisory_vendor_form">





            <!-- Ground Handler List -->
            <div *ngIf="hasGroundHandlerTag">
                <label for="groundHandlerGUID" style="font-size:small">Ground Handlers</label>
                <div style="margin-top:-0.5em;">
                    <select *ngIf="f" id="groundHandlerGUID" formControlName="groundHandlerGUID"
                        class="form-control form-control-sm dropdown"
                        style="font-size: small;border-radius:0.25em; width:31em; height: 1.6em; border-color:lightgrey;background-color:#eaf0fd">
                        <option value="" selected></option>
                        <option *ngFor="let v of groundHandlerList" [ngValue]="v.vendorGUID">{{v.vendorName }}</option>
                    </select>
                </div>
            </div>


            <!-- SSN Vendor List -->
            <div *ngIf="hasGroundHandlerTag">
                <label for="ssnVendorGUID" style="font-size:small">SSN Vendors</label>
                <div style="margin-top:-0.5em;">
                    <select *ngIf="f" id="ssnVendorGUID" formControlName="ssnVendorGUID"
                        class="form-control form-control-sm dropdown"
                        style="font-size: small;border-radius:0.25em; width:31em; height: 1.6em; border-color:lightgrey;background-color:#eaf0fd">
                        <option value="" selected></option>
                        <option *ngFor="let v of ssnvendorList" [ngValue]="v.vendorGUID">{{v.vendorName }}</option>
                    </select>
                </div>
            </div>


            <!-- Fuel Supplier List -->
            <div *ngIf="hasFuelTag">
                <label for="fslookupGUID" style="font-size:small">Fuel Suppliers</label>
                <div style="margin-top:-0.5em;">
                    <select *ngIf="f" id="fslookupGUID" formControlName="fslookupGUID"
                        class="form-control form-control-sm dropdown"
                        style="font-size: small;border-radius:0.25em; width:31em; height: 1.6em; border-color:lightgrey;background-color:#eaf0fd"
                        (change)="onFuelSupplierChange($event)">
                        <option value="" selected></option>
                        <option *ngFor="let v of fuelSupplierList" [ngValue]="v.lookupGUID">{{v.description }}
                        </option>
                    </select>
                </div>
            </div>

            <!-- IntoPlane Agent List -->
            <div *ngIf="hasFuelTag">
                <label for="ipaAgent" style="font-size:small">IntoPlaneAgents</label>
                <div style="margin-top:-0.5em;">
                    <select *ngIf="f" id="ipaAgent" [attr.disabled]="showModified ? '' : null"
                        formControlName="ipaAgent"
                        [ngClass]="!showModified?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputDisabled'"
                        style="font-size: small;border-radius:0.25em; width:31em; height: 1.6em; border-color:lightgrey;background-color:#eaf0fd">
                        <option value="" selected></option>
                        <option *ngFor="let v of intoplaneAgentList" [ngValue]="v.description">{{v.description }}
                        </option>
                    </select>
                </div>
            </div>


        </form>

    </div>
    <div style="margin-top: 0.5em;">

        <button mat-button class="btn-high" style="margin-left: 0.5em;" (click)="clickUpdate()">Update</button>
        <button mat-button class="btn-low" style="margin-left: 1em;" (click)="clickClose()">Close</button>
    </div>
</div>