<div style=" background: #f8f8f8 !important; padding: 1.5em; height: 100%; " >

  <div style="background-color: white !important;  height: 100%; "> 
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> 
    <div style=" background-color: white;margin-left:1em;margin-right:1em; " cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle> 
      <div class="d-inline-flex" style=" text-align: center; padding: 0.5em; ">
        <span class="material-symbols-outlined" style="font-size: xx-large;">
          folder
        </span>
        <h4 style="margin-left: 0.3em;">Update Message Folders </h4>
      </div> 
    </div>

    <div>
      <div style="margin-bottom: 1em; padding-bottom: 1em;">
        <table mat-table *ngIf="messageFolderList" [dataSource]="messageFolderList"
          class="example-container mat-elevation-z8" style="width: 20em; margin: 1em;">
          <tr> 
            <ng-container matColumnDef="messageFolder">
              <th mat-header-cell *matHeaderCellDef style="width:5%; text-align:left !important">
                <div style="margin-top:-0.25em;">Folder Name</div>
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 5%;">
                <div style="display: flex;">
                  <div style="font-weight:bold">{{element.messageFolderName}}</div>
                  <!-- <input type="text" [(ngModel)]="editFolderName" class="form-control form-control-sm" />
                      <span style="color: red;" *ngIf="folderExist">Folder Exists</span> -->
                </div>
              </td>
            </ng-container> 
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef style="width:5%">
                <div>Action</div>
                <div style="margin-top:0em;padding-bottom:0.25em"></div>
              </th>
              <td mat-cell *matCellDef="let element" style="width: 5%;">
                <a *ngIf="!showNewFolderInput" mat-button   class="btn-low" style="align-self:center" (click)="showNewFolderInput? null : editDetails( element.messageFolderID )">
                  Edit </a>
                <button *ngIf="showNewFolderInput" mat-button class="btn-low"  >Edit</button>  

              </td>
            </ng-container> 
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
    </div>
 
    <div *ngIf="showNewFolderInput" style="width: 27em;   margin-left: 2em; margin-bottom: 1em; margin-top: -1em; padding-bottom: 1em;">
     
      <h6 style=" margin-bottom: .5em;  " >Enter Folder Name:&nbsp;  </h6> 

      <div style="display: flex;">
        <input type="text" [(ngModel)]="newFolderName" placeholder="Enter Folder Name" maxlength="15" style="margin-left: .5em; width: 13em;" class="form-control form-control-sm" />
        <div *ngIf="showErrorMsg" class="small alert alert-danger p-0" style="font-size: smaller; width: max-content; margin-left: .5em;  ">
          <div>{{errorMsg}}</div>
          
        </div>
        <!-- <div style="margin-left: 1.3em; font-size: small;"> *Can only Enter Upto 15 Characters</div> -->
      </div>
      <div style="height:1.5em; margin-top:0.5em; width: 26.5em; margin-bottom: .5em; margin-right:1em" *ngIf="showWarningMsg">
        <app-warning-message [warningMsg]="warningMsg"></app-warning-message>
      </div>
      <!-- <div style="display: flex; justify-content: center;">
      <button mat-button class="btn-high" (click)="createNewFolder()">Done</button>
      <button mat-button class="btn-low" (click)="cancelNewFolder()">Cancel</button>  
     </div> -->
    </div>
 
    <div *ngIf="showSuccessMsg">
      <app-success-message [successMsg]="successMsg"></app-success-message>
    </div>
 
  </div>
  <div *ngIf="!showNewFolderInput" style="display: inline-flex; max-width: 25em !important; margin-left: 2em; margin-bottom: 0em"> 
    <button mat-button class="btn-high " style="  text-align: left; " (click)="showNewFolderInput = true">
      <i class="material-icons" style="font-size:  large; margin-top: -.2em;">create_new_folder </i>
      New Folder
    </button> 
    <button type="button" mat-button class="btn-low" (click)="clickClose()" style="margin-left: 1em;">
      <div style="display:flex">
        <i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
        <div>CLOSE</div>
      </div>
    </button>&nbsp;
  </div> 
  
  <div *ngIf="showNewFolderInput" style="display: inline-flex; max-width: 25em !important; margin-left: 2em; margin-bottom: 0em"> 
    <button mat-button class="btn-high " style="  text-align: left; " (click)="createNewFolder()">
      <i class="material-icons" style="font-size:  large; margin-top: -.2em;">create_new_folder </i>
      Create
    </button> 
    <button type="button" mat-button class="btn-low" (click)="cancelNewFolder()" style="margin-left: 1em;">
      <div style="display:flex">
        <i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
        <div>Cancel</div>
      </div>
    </button>&nbsp;
  </div> 
</div>

  