<div style="background-color:#f8f8f8">
  <div cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5 class="mat-h2">Vendor Setup - {{dialogTitle}}</h5></div>

  </div>

  <div>
    <form *ngIf="vendor_profile_edit_form" [formGroup]="vendor_profile_edit_form" novalidate>
      <div style=" margin-top:0em; padding-left: 1em;padding-right:1em">
        <div style="margin-top:0em;padding-left:0.5em;padding-right:0.5em;padding-bottom:1em;">
          <div style="background-color:white;padding:.5em;">
            <div>
              <div class="d-inline-flex">
                <div class="no-gutters">
                  <div class="col">
                    <label  style="font-size:small">Vendor Name</label>
                    <div style="margin-top:-0.35em;">
                      <input type="text" class="form-control form-control-sm inputBox" required style="width:18em" formControlName="vendorName" [ngClass]="{ 'is-invalid': submitted && f.vendorName.errors }" />
                      <div class="invalid-tooltip alert-danger p-0" style="font-size:small">
                        Vendor Name is required.
                      </div>
                    </div>
                  </div>
                  <div class="col mt-1">
                    <label  style="font-size:small">Legal Entity Name</label>
                    <div style="margin-top:-0.35em;">
                      <input type="text" class="form-control form-control-sm inputBox" style="width:18em" formControlName="legalEntityName" [ngClass]="{ 'is-invalid': submitted && f.legalEntityName.errors }" />
                      <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                        Legal Entity Name is required.
                      </span>
                    </div>
                  </div>
                  
                </div>
                <div>
                  <div style="background-color:#f8f8f8;border-radius:.25em" class="d-inline-flex ml-2 p-1">
                    <div>
                      <div>
                        <label style="font-size:small">Vendor Service Area</label>
                      </div>

                      <div>
                        <mat-radio-group [(ngModel)]="serviceArea" style="display:flex;flex-direction:column;transform:scale(.8);" [ngModelOptions]="{standalone: true}" (change)="setValidation()">
                          <mat-radio-button [checked]="serviceArea=='airport'" value="airport">Airport</mat-radio-button>
                          <mat-radio-button [checked]="serviceArea=='country'" value="country">Country</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="ml-2">

                      <div class="col">
                        <label for="airport_select" style="font-size:small">Airport</label>
                        <div style="margin-top:-0.35em;">
                          <!--<select required *ngIf="f" style="width:max-content" formControlName="airport_select" (change)="filterVendorList()" [ngClass]="{ 'is-invalid': submitted && f.airport_select.errors }" class="form-control form-control-sm dropdown">
        <option value="" selected></option>
        <option *ngFor="let airport of airportList" [ngValue]="airport.airportID">{{airport.icao}}</option>
      </select>-->
                          <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; margin-right: 0.25em">
                            <input type="text" formControlName="airport_select" class="form-control text-uppercase" maxlength="4" [ngClass]="{ 'is-invalid': submitted && f.airport_select.errors }" style="padding: 0em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" (change)="validateAirport($event)" />
                          </div>
                          <div *ngIf="f.airport_select.errors">
                            <div *ngIf="f.airport_select.errors && f.airport_select.errors.pattern" style="background-color:#f9d2de; padding-left:0.125em;margin-top:0em; font-size:small">Invalid characters for airport</div>
                            <div *ngIf="f.airport_select.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.125em;margin-top:0em; font-size: small; white-space: nowrap;width:6.5em">{{wrongAirportErrMsg}}</div>
                          </div>
                          <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                            Airport is required.
                          </span>
                        </div>

                      </div>
                      <div class="col">
                        <label for="country_select" style="font-size:small">Country</label>
                        <div style="margin-top:-0.35em;">
                          <select required *ngIf="f" formControlName="country_select" [ngClass]="{ 'is-invalid': submitted && f.country_select.errors }" class="form-control form-control-sm dropdown">
                            <option value="" selected></option>
                            <option *ngFor="let cty of countryList" [ngValue]="cty.countryGUID">{{cty.countryName}}</option>
                          </select>
                          <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                            Country is required.
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="d-inline-flex">
                <div>
                  <div>
                    <label for="checkRoles" style="font-size:small">Role(s)</label>
                    <div style="margin-top:-0.5em;">
                      <mat-select #matRoles placeholder="None" required panelClass="custom-mat-select-panel4 placeholder1" formControlName="checkRoles" multiple style="border: solid lightgray 0.0625em; font-size: small; color:black; border-radius: 0.25em; padding-left:0.25em; height:1.65em;width:18em; background-color: #eaf0fd;">
                        <div (mouseleave)="matRoles.close()">
                          <mat-option *ngFor="let r of vendorRoleList" [value]="r" (onSelectionChange)="onRoleListChange($event, r)" style="height:1.5em; line-height:1.5em !important;background-color: #eaf0fd;">{{r.vendorRole}} </mat-option>
                        </div>
                      </mat-select>
                      <div>
                        <mat-error *ngIf="submitted && f.checkRoles.errors" class="alert alert-danger p-0" style="font-size:small;color:#cc0000">
                          At least one role is required.
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="showPermitType">
                    <label for="permit_type" style="font-size:small">Permit Type</label>
                    <div style="margin-top:-0.35em;">
                      <select *ngIf="f" formControlName="permit_type" [ngClass]="{ 'is-invalid': submitted && f.permit_type.errors }" class="form-control form-control-sm dropdown">
                        <option value="" selected></option>
                        <option *ngFor="let p of permitTypeList" [ngValue]="p.permitTypeID">{{p.permitTypeDescription}}</option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="showCustomsType">
                    <label for="customs_type" style="font-size:small">Customs Type</label>
                    <div style="margin-top:-0.35em;">
                      <select *ngIf="f" formControlName="customs_type" [ngClass]="{ 'is-invalid': submitted && f.customs_type.errors }" class="form-control form-control-sm dropdown">
                        <option value="" selected></option>
                        <option *ngFor="let c of customsTypeList" [ngValue]="c.customsTypeID">{{c.customsTypeDescription}}</option>
                      </select>
                    </div>
                  </div>
                </div>            

                <div class="ml-2">
                  <label for="facility_select" style="font-size:small">Facility Type</label>
                  <div style="margin-top:-0.35em;">
                    <select *ngIf="f" formControlName="facility_select" [ngClass]="{ 'is-invalid': submitted && f.facility_select.errors }" class="form-control form-control-sm dropdown">
                      <option value="" selected></option>
                      <option *ngFor="let f of facilityList" [ngValue]="f.vendorFacilityID">{{f.facilityDescription}}</option>
                    </select>
                  </div>
                </div>

                <div class="ml-2">
                  <label for="serviceArrangement" style="font-size:small">Service Arrangement</label>
                  <div style="margin-top:-0.35em;">
                    <select *ngIf="f" formControlName="serviceArrangement" [ngClass]="{ 'is-invalid': submitted && f.serviceArrangement.errors }" class="form-control form-control-sm dropdown">
                      <option value="" selected></option>
                      <option *ngFor="let svc of serviceArrangementList" [ngValue]="svc.serviceArrangementID">{{svc.serviceArrangementDescription}}</option>
                    </select>
                  </div>

                </div>
                <div class="ml-2" *ngIf="relatedVendorList && relatedVendorList.length>0">
                  <label for="checkVendors" style="font-size:small">Related Vendors</label>
                  <div style="margin-top:-0.5em;">
                    <mat-select #matVendors placeholder="None" panelClass="custom-mat-select-panel4 placeholder1" formControlName="checkVendors" multiple style="border: solid lightgray 0.0625em; font-size: small; color:black; border-radius: 0.25em; padding-left:0.25em; height:1.65em;width:15.5em; background-color: #eaf0fd;">
                      <div (mouseleave)="matVendors.close()">
                        <mat-option *ngFor="let r of relatedVendorList" [value]="r" (onSelectionChange)="onVendorListChange($event, r)" style="height:1.5em; line-height:1.5em !important;background-color: #eaf0fd;">{{r.vendorName}} </mat-option>
                      </div>
                    </mat-select>
                    <div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div>
              <div class="d-inline-flex no-gutters">
                <div class="col">
                  <label for="account_type" style="font-size:small">Account Type</label>
                  <div style="margin-top:-0.35em;">
                    <select *ngIf="f" required formControlName="account_type" [ngClass]="{ 'is-invalid': submitted && f.account_type.errors }" class="form-control form-control-sm dropdown">
                      <option value="" selected></option>
                      <option *ngFor="let status of vendorStatusList" [ngValue]="status.vendorStatusID">{{status.vendorStatusDescription}}</option>-->
                    </select>
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Account Type is required.
                    </span>
                  </div>
                </div>
                <div class="ml-2 col">
                  <label for="compliance_status" style="font-size:small">Compliance Status</label>
                  <div style="margin-top:-0.35em;">
                    <select *ngIf="f" required formControlName="compliance_status" [ngClass]="{ 'is-invalid': submitted && f.compliance_status.errors }" class="form-control form-control-sm dropdown">
                      <option value="" selected></option>
                      <option *ngFor="let comp of complianceStatusList" [ngValue]="comp.vendorComplianceStatusID">{{comp.vendorComplianceStatusDescription}}</option>
                    </select>
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Compliance Status is required.
                    </span>
                  </div>
                </div>
                <div class="ml-2 col">
                  <label for="rating_select" style="font-size:small">Rating</label>
                  <div style="margin-top:-0.35em;">
                    <select *ngIf="f" required formControlName="rating_select" [ngClass]="{ 'is-invalid': submitted && f.rating_select.errors }" class="form-control form-control-sm dropdown">
                      <option value="" selected></option>
                      <option *ngFor="let rating of ratingList" [ngValue]="rating.ratingID">{{rating.ratingDescription}}</option>
                    </select>
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Rating is required.
                    </span>
                  </div>
                </div>
                <div class="ml-2 col">
                  <label for="contract_status" style="font-size:small">Contract Status</label>
                  <div style="margin-top:-0.35em;">
                    <select *ngIf="f" require formControlName="contract_status" [ngClass]="{ 'is-invalid': submitted && f.contract_status.errors }" class="form-control form-control-sm dropdown">
                      <option value="" selected></option>
                      <option *ngFor="let contract of contractStatusList" [ngValue]="contract.contractStatusID">{{contract.contractStatusDescription}}</option>
                    </select>
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Contract Status is required.
                    </span>
                  </div>
                </div>
                <div class="ml-2 col">
                  <label for="airport_select" style="font-size:small">Method of Payment</label>
                  <div style="margin-top:-0.35em;">
                    <select required *ngIf="f" formControlName="payment_method" [ngClass]="{ 'is-invalid': submitted && f.payment_method.errors }" class="form-control form-control-sm dropdown">
                      <option value="" selected></option>
                      <option *ngFor="let pay of methodOfPaymentList" [ngValue]="pay.vendorMethodOfPaymentID">{{pay.vendorMethodOfPaymentDescription}}</option>
                    </select>
                    <span class="invalid-tooltip alert-danger p-0" style="font-size:small">
                      Method of Payment is required.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-inline-flex">
            <div style="background-color:white;padding:.5em;margin-top:1em">
              <div class="d-inline-flex">
                <div>
                  <label for="compliance_note" style="font-size:small">Overall Compliance Notes</label>
                  <div style="margin-top:-0.35em;">
                    <textarea formControlName="compliance_note" class="form-control" style="width:19em; height:14.75em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>
                  </div>
                </div>
                <div class="ml-2">
                  <div>
                    <label for="regulatory_status" style="font-size:small">Regulatory Status</label>
                    <div style="margin-top:-0.35em;">
                      <select *ngIf="f" formControlName="regulatory_status" [ngClass]="{ 'is-invalid': submitted && f.regulatory_status.errors }" class="form-control form-control-sm dropdown">
                        <option value="" selected></option>
                        <option *ngFor="let reg of regComplianceStatusList" [ngValue]="reg.vendorComplianceStatusID">{{reg.vendorComplianceStatusDescription}}</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label for="reg_note" style="font-size:small">Regulatory Notes</label>
                    <div style="margin-top:-0.35em;">
                      <textarea formControlName="reg_note" class="form-control" style="width:16.5em; height:3em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>
                    </div>
                  </div>
                  <div>
                    <label for="airport_select" style="font-size:small">Operations Status</label>
                    <div style="margin-top:-0.35em;">
                      <select *ngIf="f" formControlName="operations_status" [ngClass]="{ 'is-invalid': submitted && f.operations_status.errors }" class="form-control form-control-sm dropdown">
                        <option value="" selected></option>
                        <option *ngFor="let ops of opsComplianceStatusList" [ngValue]="ops.vendorComplianceStatusID">{{ops.vendorComplianceStatusDescription}}</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label for="ops_note" style="font-size:small">Operations Notes</label>
                    <div style="margin-top:-0.35em;">
                      <textarea formControlName="ops_note" class="form-control" style="width:16.5em; height:3em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="background-color:white;padding:.5em;margin-top:1em;margin-left:.5em">

              <div>
                <label for="acct_status" style="font-size:small">Accounting Status</label>
                <div style="margin-top:-0.35em;">
                  <select *ngIf="f" formControlName="acct_status" [ngClass]="{ 'is-invalid': submitted && f.acct_status.errors }" class="form-control form-control-sm dropdown">
                    <option value="" selected></option>
                    <option *ngFor="let acct of acctComplianceStatusList" [ngValue]="acct.vendorComplianceStatusID">{{acct.vendorComplianceStatusDescription}}</option>
                  </select>
                </div>
              </div>
              <div>
                <label for="acct_note" style="font-size:small">Accounting Notes</label>
                <div style="margin-top:-0.35em;">
                  <textarea formControlName="acct_note" class="form-control" style="width:16.5em; height:3em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>

    </form>

  </div>
  <div class="d-inline-flex">
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em">
      <button *ngIf="vendorGUID!=null || pageName=='task'" mat-button class="btn-high" (click)="clickSave()">SAVE</button>&nbsp;
      <button *ngIf="vendorGUID==null && pageName!='task'" mat-button class="btn-high" (click)="clickSave()">NEXT</button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()">CLOSE</button>&nbsp;

    </div>
    <div class="mt-2">
      <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display:inline-block; white-space:pre-wrap;margin-top:.5em"><label style="font-size: small">{{errMsg}}</label></div>
      <app-success-message *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>





