import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { TripModel } from '../models/trip.model';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { AccountingStatusModel } from '../models/accounting-status.model';
import { TripRevisionModel } from '../models/trip-revision.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';

@Component({
  selector: 'app-trip-audit-user-comment-dialog',
  templateUrl: './trip-audit-user-comment-dialog.component.html',
  styleUrls: ['./trip-audit-user-comment-dialog.component.css']
})

export class TripAuditUserCommentDialogComponent implements OnInit {
  userType: string;
  tripRevisionID: number;
  userComments: string;
  trip_audit_user_comment_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSuccessMsg: boolean = false;
  v: number = this._authService.getCurrentTimeNumber();
  returnObj = new ReturnObjModel();
  controlValueChanged: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  upPII: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<TripAuditUserCommentDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _dialogService: DialogService) {
   
    this.tripRevisionID = _data.tripRevisionID;
    this.userComments = _data.userComments;
  }

  ngOnInit() {

    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
      
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.errMsg = "";
    this.msg = "";
    
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.initControls();

    this.checkFormControlValueChanged();

  }

  initControls() {
    this.trip_audit_user_comment_form = this._formBuilder.group({
      userComments: [this.userComments]
    });


  }

  get f() { return this.trip_audit_user_comment_form.controls; }

  checkFormControlValueChanged(): void {
    this.trip_audit_user_comment_form.get("userComments").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    
  }

  userCommentsChange() {
    this.controlValueChanged = true;
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }


    let req = new TripRevisionModel();
    req.tripRevisionID = this.tripRevisionID;
    req.userComments = this.f.userComments.value;
      this._groundStopService.updateTripRevisionUserCommentsBytrId<ResponseModel<boolean>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.controlValueChanged = false;
          this.returnObj.refresh = true;
          this.returnObj.remarks = this.f.userComments.value;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;            
            this.clickClose();
          }, 1000);
        }        
        
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the user comments at this time. Please try it later.";
        }

      }
      
      })

  }

  clickClose() {
    //this._dialogRef.close(this.returnObj);
    if (this.controlValueChanged == true) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save the change before close?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result == true) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(this.returnObj);
        }
      });
    }
    else {
      this._dialogRef.close(this.returnObj);
    }

  }








}
