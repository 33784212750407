import { ComponentPortal, Portal, PortalInjector } from '@angular/cdk/portal';
import { AfterViewInit, Component, Inject, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { CONTAINER_DATA } from '../services/help-guide.service';
import { ContingencyEditDialogComponent } from './contingency-edit-dialog.component';
import { ManageContingencyDialogComponent } from './manage-contingency-dialog.component';

@Component({
  selector: 'app-contingency-stop',
  templateUrl: './contingency-stop.component.html',
  styleUrls: ['./contingency-stop.component.css']
})


export class ContingencyStopComponent implements AfterViewInit {
  @Input() selectedPortal: Portal<any>;
  componentPortal: ComponentPortal<ManageContingencyDialogComponent>;
  editPortal: ComponentPortal<ContingencyEditDialogComponent>;
  type: string;
  constructor(private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) private _data: any,
    private _injector: Injector 
    ) {
    this.type = _data.type;
  }

  ngAfterViewInit(): void {
    this.componentPortal = new ComponentPortal(ManageContingencyDialogComponent, null, this.createInjector(this.type));
    this.editPortal = new ComponentPortal(ContingencyEditDialogComponent);
    this._authService.updateAccessTime();
    this.selectedPortal = this.componentPortal;

  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

}
