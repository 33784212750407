export class CountryContentDocModel {
  documentType: string;
  documentTypeId: number;
  countryContentSegmentId: number;
  containerPath: string;
  containerName: string;
  mimeType: string;
  bFile: string;
  countryID: number;
  RequiredDocId: number;
  fileExtension: string;
  isActive: boolean;
  showToClient:boolean;
  documentName: string; 
  userType: string;
  modifiedBy: string;
  internalNotes: string;
  isModified: boolean = false;
  isMiscellaneous: boolean;
}