import { Component, Inject, OnInit } from '@angular/core';
import { PersonService } from '../services/person.service';
import { AuthenticateService } from '../services/authenticate.service';
import { DialogService } from '../services/dialog.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ResponseModel } from '../models/response.model';
import { CustomerPersonBridgeModel } from '../models/customer-person-bridge.model';
import { CustomerGroupModel } from '../models/customer-group.model';

@Component({
  selector: 'app-person-account-grouplist',
  templateUrl: './person-account-grouplist.component.html',
  styleUrls: ['./person-account-grouplist.component.css']
})
export class PersonAccountGrouplistComponent implements OnInit {
  personGUID: string;
  customerGroup: CustomerGroupModel[];
  personAccountList: CustomerPersonBridgeModel[] = [];
  errMsg: string = "";
  showSuccessMsg: boolean = false;
  showSpin: boolean = false;
  allChecked: boolean = false;
  customerGUID: string;

  constructor(private readonly _dialogRef: MatDialogRef<PersonAccountGrouplistComponent>, private readonly _personService: PersonService, private readonly _authService: AuthenticateService,
    private readonly _dialog: MatDialog, private readonly _dialogService: DialogService, @Inject(MAT_DIALOG_DATA) private _data: any)
  {
    this.personGUID = _data.personGUID;
    this.customerGroup = _data.customerGroup;
    this.customerGUID = _data.customerGUID;
  }
    ngOnInit() {
      this._authService.updateAccessTime();
      this.getPersonAccountList();
    }

  getPersonAccountList() {
    this.customerGroup.forEach(x => {
      this._personService.getCustomerPersonBridgeBypIDcgID<ResponseModel<CustomerPersonBridgeModel[]>>(this.personGUID,x.customerGroupGUID).subscribe(response => {
        if (response != null) {
          if (response.message == "" && response.code == "200") {
            this.personAccountList = response.model;
            x.personAccountList = this.personAccountList;
            if (this.personAccountList.every(x => x.isActive)) {
              this.allChecked = true; 
            }
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              //error
            }
          }
        }
      });
    });
  }

  updateAccountGroup(e:any, item: CustomerPersonBridgeModel) {
    item.isActive = e.checked;
  }

  toggleAll(e: any) {
    this.customerGroup.forEach(x => {
      x.personAccountList.forEach(x => x.isActive = e.checked);
    });

  }

  clickSave() {
    this.errMsg = "";


     this.customerGroup.forEach(x => {
      x.personAccountList.forEach(x => x.personCustomerGUID = this.customerGUID );
    });


    this.customerGroup.forEach(x => {
      this._personService.saveCustomerPersonBridge<ResponseModel<boolean>>(x.personAccountList).subscribe(response => {
        if (response != null) {
          if (response.message == "" && response.code == "200") {
            if (response.model) {
              this.showSuccessMsg = true;
              setTimeout(() => {
                this.showSuccessMsg = false;
                this._dialogRef.close(true);
              },1000)
            }
            else {
              this.errMsg = "An error has occured.";
            }
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              this.errMsg = "An error has occured.";
            }
          }
        }
      });
    })
  }


}
