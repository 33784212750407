<div style="background-color:#f8f8f8">
  <div style="display: flex;padding-left:1em;" cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <div style="margin-top:0.3em;">
      <i class="material-icons-outlined" style="font-size: x-large;cursor: pointer; color: gray">event_available</i>
    </div>
    <div style="margin-top:0.3125em;">
      <div><h5>Manage Contingencies</h5></div>
      <div style="display: flex; margin-top:-0.5em">
        <div style="margin-right:1em" class="d-inline-flex">
          <div style="font-size: small">Registration:&nbsp;</div>
          <div style="font-weight: bold; font-size: small">{{registration}}</div>
        </div>
        <div style="margin-right:1em" class="d-inline-flex">
          <div style="font-size: small">Trip Code:&nbsp;</div>
          <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
        </div>
      </div>
      <div>
        <div class="d-inline-flex pr-1">
          <div style="font-size: small">Route: &nbsp;</div>
          <div style="font-size: small;display:flex;flex-wrap:wrap;max-width:35em;">
            <div *ngFor="let gs of groundStopList; let i=index">
              <div style="display: flex">
                <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==groundStopList.length-1? '': '-'}}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div style="margin-left:.5em;margin-right:.5em">
    <app-spin *ngIf="showSpin"></app-spin>
    <div style="background-color:white;padding-left: 1em; padding-right: 1em;padding-bottom:.5em;padding-top:.5em">
      <div>
        <label style="font-size:small">{{isRepositioning? 'Repositioning' : 'Backup'}} for {{selectedGS.groundStop.icao}}</label>
        <!--<mat-radio-group  [(ngModel)]="isRepositioning" [ngModelOptions]="{standalone: true}">
      <mat-radio-button style="transform:scale(.8)" [value]="true">Backup</mat-radio-button>
      <mat-radio-button style="transform:scale(.8)" [value]="false">Repositioning</mat-radio-button>
    </mat-radio-group>-->
      </div>
      <div style="padding-left: 1em; padding-right: 1em;">
        <form *ngIf="contingency_edit_form" [formGroup]="contingency_edit_form">
          <div *ngIf="!isRepositioning">
            <table #table mat-table *ngIf="contingencyList" [dataSource]="contingencyList" class="example-container">
              <tr>
                <ng-container matColumnDef="sequence">
                  <th mat-header-cell *matHeaderCellDef width="33%">
                    Sequence
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.contingencySequence}}</td>
                </ng-container>

                <ng-container matColumnDef="icao">
                  <th mat-header-cell *matHeaderCellDef width="33%">
                    Airport Stop
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex justify-content-center">
                      <input type="text" value="{{element.icao}}" class="form-control form-control-sm inputBox" style="width:5em;margin-top:.5em" (change)="icaoChange($event, element)" />
                    </div>
                  </td>
                </ng-container>


                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef width="33%">
                    Action
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex justify-content-center">
                      <div style="margin-top:0.25em" *ngIf="element.contingencySequence==1"><i class="material-icons-outlined" style="font-size: x-large;cursor: pointer;color:gray;transform:rotate(180deg)" title="Insert a stop before" (click)="addContingencyStop(element,true);">low_priority</i></div>
                      <div style="margin-top:0.25em"><i class="material-icons-outlined" style="font-size: x-large; cursor: pointer; color: gray" title="Insert a stop after" (click)="addContingencyStop(element,false);">low_priority</i></div>
                      <div style="margin-top:0.25em" *ngIf="element.contingencySequence>1"><i class="material-icons-outlined" style="font-size: x-large; cursor: pointer; color: red" title="Remove stop" (click)="removeContingencyStop(element);">cancel</i></div>
                    </div>
                  </td>

                </ng-container>

              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>

            </table>
          </div>
          <div *ngIf="isRepositioning" style="font-size: small">
            <div class="row">
              <div class="col"></div>
              <div class="col">Departure</div>
              <div class="col">Arrival</div>
            </div>

            <div class="row">
              <div class="col">Inserted Leg</div>
              <div class="col">{{selectedGS.groundStop.icao}}</div>
              <div class="col"><input type="text" #insertedLegDepICAO formControlName="insertedLegDepICAO" class="form-control" [ngClass]="{ 'is-invalid': validatedAirport && f.insertedLegDepICAO.errors }" style="padding-left: 0.25em;width:5em;height:1.5625em;font-size:small;background-color:#eaf0fd" maxlength="4" (change)="icaoChange($event)" (keyup)="icaoKeyDown($event)" /></div>
            </div>

            <div class="row">
              <div class="col">Next Leg</div>
              <div class="col">{{newICAO}}</div>
              <div class="col">{{selectedGS.groundStop.icao}}</div>
            </div>


          </div>
        </form>
      </div>

    </div>
      <div>
      <div class="d-inline-flex">
        <div class="p-2">

          <button mat-button class="btn-high mr-2" (click)="clickSave()">Save</button>
          <button mat-button class="btn-high mr-2" (click)="clickBack()">Back</button>
          <button mat-button class="btn-low" [mat-dialog-close]>Close</button>
        </div>
        <div *ngIf="showSuccessMsg">
          <app-success-message [successMsg]="successMsg"></app-success-message>
        </div>
        <div *ngIf="errMsg!=''" class="alert alert-danger small px-1 py-0 mt-2">
          {{errMsg}}
        </div>
      </div>
    </div>

</div>
  </div>
