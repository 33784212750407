import { UserPermissionModel } from "./user-permission.model";

export class UserModel extends UserPermissionModel{
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactEmail: string;
  mfaEmail: string;
  phoneNumber: string;
  recoveryPhoneNumber: string;
  mfaPhoneNumber: string;
  jobTitle: string;
  username: string;
  password: string;
  token: string;
  customerGuid: string;
  vendorGuid: string;
  isActive: boolean;
  enablePasswordChangePolicy: boolean;
  applicationIdList: string;
  updatedBy: string;
  searchKeyword: string;
  imgPath: string;
  userType: string;
  userTypeId: string;
  checked: boolean;
  keepMeSignedIn: boolean;
  isAdmin: boolean;
  isNewUser: boolean;
  hasResetPwd: boolean;
  loading: boolean;
  isUsingSms: boolean;
  isUsingMfa: boolean;
  isUsingOnlyContactInfo: boolean;

  // add for permission
  adminAccess: number;
  flName: string;
  textSearch: string;
  tripAccessFilter: string;
  registrationList: string;
  aircraftGuidList: string;
  picList: string;
  sicList: string;
  paxList: string;
  personGuidList: string;
  accessCrewTripDetails: boolean;
  accessPAXTripDetails: boolean;
  accessTripDocuments: boolean;
  createCurrentEvents: boolean;
  createTripAlerts: boolean;
  accessCompanyDocuments: boolean;
  manageAircraftPreference: boolean;
  manageCrewPAXDataMaintenance: boolean;
  accessTripMessages: boolean;
  vendor_InvoiceUpload: boolean;
  personType: string;
  // add for permission end
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  effectiveDate: string;
  expirationDate: string;
  showResetPwdBtn: boolean;
  //grantAccessToManageManifestProfiles: boolean;
  //grantAccessCompanyProfiles: boolean;
  //grantAccessAircraftProfiles: boolean;
  //grantAccessSubmitTripsandRequestQuotes: boolean;
  //grantAccessToMessages: boolean;
  //grantAccessToManageCompanyEvents: boolean;
  //grantAccessCreateTripAlerts: boolean;
  //grantAccessToInvoices: boolean;
  //grantAccessToHeadlineNews: boolean;
  //grantAccessToReleaseEvents: boolean;
  //grantAccessToLockedTrips: boolean;
  //grantAccessToCompanyBusinessRules: boolean;
  //grantAccessToVendorProfiles: boolean;
  //grantAccessToPIIData: boolean;  
  customerName: string;
  //grantAccessToManageAccountGrouping: boolean;
  //grantAccessToAccountGrouping: boolean;
  //customerGroupGUID: string;
  showDisableCheckbox: boolean;
  locked: boolean;
  lockedDateTime?: Date;
  signature: string;
  signatureInCKEditorFormat: string;
  taskQueueServiceIDList: string;
  taskQueueStatusList: string;
  priorEmail: string;
}
