import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, NgZone } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first, take, takeUntil } from 'rxjs/operators';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
//import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
//import { CommAddressTypeModel } from '../models/comm-address-type.model';
//import { AddressRatingModel } from '../models/address-rating.model';
//import { AddressUseModel } from '../models/address-use.model';
import { DialogService } from '../services/dialog.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopService } from '../services/ground-stop.service';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';

import { FileItem, FileUploader, ParsedResponseHeaders, FileUploadModule, FileSelectDirective } from 'ng2-file-upload';
import { FileModel } from '../models/file.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';
import { VendorModel } from '../models/vendor.model';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import { ServiceArrangementModel } from '../models/service-arrangement.model';
import { VendorContactModel } from '../models/vendor-contact.model';
import { HandlingNotesModel } from '../models/handling-notes.model';
import { DatePipe } from '@angular/common';
import { TemplateService } from '../services/template.service';
import { BusinessRulesService } from '../services/business-rules-service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TripLegService } from '../services/trip-leg.service';
import { TripModel } from '../models/trip.model';
import { TripLegModel } from '../models/trip-leg.model';
import { FuelService } from '../services/fuel.service';
import { FuelTaskModel } from '../models/fuel-task.model';
import { FuelClientMarkupModel } from '../models/fuel-client-markup.model';
import { TaxesAndFeesModel } from '../models/taxesandfees.model';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { AirportService } from '../services/airport.service';

const datepipe: DatePipe = new DatePipe('en-US')


@Component({
  selector: 'app-fuel-quote-history-dialog',
  templateUrl: './fuel-quote-history-dialog.component.html',
  styleUrls: ['./fuel-quote-history-dialog.component.css']
})

export class FuelQuoteHistoryDialogComponent implements OnInit {

  dialogTitle: string;
  customerGUID: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  //maxGroundStopSequenceID: number;
  //currentGroundStopSequenceID: number;
  tripCodeGUID: string;
  icao: string;
  iata: string;
  serviceTypeID: number;
  serviceTypeDesc: string;
  //boxHeight: string;
  vendorReq: boolean;

  fuel_task_edit_form: UntypedFormGroup;
  loading = false;
  loadingDoc = false;
  submitted = false;
  errMsg: string;
  errMsg2: string;
  msg: string;
  showSpin: boolean = true;
  showSpinFuel: boolean = true;
  icao_iata: string;
  airportName: string;
  airportCountry: string;
  arrivalDateTime: string;
  departureDateTime: string;
  arrivalDateTimeLocal: string;
  departureDateTimeLocal: string;
  nextICAO_IATA: string;
  nextAirportName: string;
  nextCountry: string;
  nextCountryGUID: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;

  pastFuelQuoteList: FuelTaskModel[] = [];
  displayedColumnPastQuote: string[] = ['modified', 'fuel_supplier', 'into_plane', 'markup','exchange_rate_markup', 'expiry_date', 'report_id', 'status','option'];

  userType: string;
  registration: string = "";;
  lockdownTrip: boolean = false;
  upFuel: boolean = false;
  clientName: string;
  tripCode: string;
  nextICAO: string;
  prevICAO: string;
  cityName: string;
  constructor(private readonly _dialogRef: MatDialogRef<FuelQuoteHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
 private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialog: MatDialog,
    private readonly _dialogService: DialogService,
    private readonly _fuelService: FuelService, private readonly _airportService: AirportService) {
   
    this.groundStopGUID = _data.groundStopGUID; // either dep or arr
    this.serviceTypeID = _data.serviceTypeID;
    this.vendorReq = _data.vendorReq;
    this.dialogTitle = _data.dialogTitle;
    //this.currentGroundStopSequenceID = _data.currentGroundStopSequenceID;
    //this.maxGroundStopSequenceID = _data.maxGroundStopSequenceID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.icao = _data.icao;
    this.groundStopTaskGUID = _data.groundStopTaskGUID;
    //this.boxHeight = _data.boxHeight.toString() + "em";
    this.serviceTypeDesc = _data.serviceTypeDesc;
    this.customerGUID = _data.customerGUID;
    this.clientName = _data.clientName;
  }

  getAllData(): Observable<any[]> {
    let request = new GroundStopModel();
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    let getGSResponse = this._groundStopAdvancedService.getGroundStopForTaskEditBygsId<ResponseModel<GroundStopModel>>(request);
    let getFuelQuoteResponse = this._fuelService.getFuelQuoteBygstID(this.groundStopTaskGUID, true);
    return forkJoin([getGSResponse, getFuelQuoteResponse]);
  }

  ngOnInit() {
    if (localStorage.getItem('up.fuel') != 'true') {
      this.upFuel = false;
    }
    else this.upFuel = true;

    //if (!this.upFuel) {
    //  this._dialogService.openAlertDialog("Permission denied.", "OK");
    //  return;
    //}

    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.errMsg2 = "";
    this.msg = "";
    this.tripCode = "";
    this.icao_iata = "";
    this.airportName = "";
    this.airportCountry = "";
    this.arrivalDateTime = "";
    this.departureDateTime = "";
    this.arrivalDateTimeLocal = "";
    this.departureDateTimeLocal = "";
    this.nextICAO_IATA = "";
    this.nextAirportName = "";
    this.nextCountry = "";
    this.aircraftManufacturer = "";
    this.aircraftModel = "";
    this.aircraftInfo = "";
    
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }


    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let gs = new GroundStopModel();
          gs = responses[0].model;
          this.tripCode = gs.tripCode;
          this.icao = gs.icao;
          this.iata = gs.iata;
          this.icao_iata = gs.icaO_IATA;
          this.airportName = gs.airportName;
          this.airportCountry = gs.airportState_CountryCode;
          this.arrivalDateTime = gs.arrivalDateTextUTC;
          this.departureDateTime = gs.departureDateTextUTC;
          this.arrivalDateTimeLocal = gs.arrivalDateTextLocal;
          this.departureDateTimeLocal = gs.departureDateTextLocal;
          this.nextICAO_IATA = gs.nextICAO_IATA;
          this.nextAirportName = gs.nextAirportName;
          this.nextICAO = gs.nextICAO;
          this.nextCountry = gs.nextAirportCity_CountryCode;
          this.nextCountryGUID = gs.nextCountryGUID.toLowerCase();
          this.prevICAO = gs.prevICAO;
          this.registration = gs.registration;
          this.aircraftManufacturer = gs.aircraftManufacturer;
          this.aircraftModel = gs.aircraftModel;
          this.aircraftInfo = gs.aircraftInfo;
          this.lockdownTrip = gs.lockdownTrip;
          this.cityName = gs.cityName;
          this.clientName = gs.customerName;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.pastFuelQuoteList = [];
          let fq: string = "";
         // let allQuotes: FuelTaskModel[] = responses[1].model; 
          responses[1].model.forEach(x => {
            if (fq != x.fuelQuoteGUID && x.groundStopTaskDocumentGUID != '') {
              fq = x.fuelQuoteGUID;
              let y = new FuelTaskModel();
              y = x;
              let quote = responses[1].model.filter(q => q.fuelQuoteGUID == fq);
              if (!quote.every(val => val.markupAmount === quote[0].markupAmount))
                y.markupAmountList = Array.prototype.map.call(quote, s => s.qty + ": " + s.markupAmount.toFixed(2).toString()).toString().replace(/,/g, "\n");
              else
                y.markupAmountList = x.markupAmount;
              this.pastFuelQuoteList.push(y);
            }
          });

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.showSpin = false;

    });

  }


  clickPreviewDocument(e: any, item: GroundStopTaskDocumentModel, id:string="") {
    let request = new GroundStopTaskDocumentModel();
    if (id != "")
      request.groundStopTaskDocumentGUID = id;
    else
      request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
    if (request.groundStopTaskDocumentGUID != "") {
      this._groundStopAdvancedService.getGroundStopTaskDocumentBygstdId<ResponseModel<GroundStopTaskDocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new GroundStopTaskDocumentModel();
            obj = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
            let file = new Blob([byteArray], { type: obj.mimeType });
            var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display:none");
            a.href = fileURL;
            a.target = "_blank";
            switch (obj.fileExtension) {
              case "doc":
              case "docx":
              case "xls":
              case "xlsx":
                a.download = obj.documentName;
            }
            a.click();
            document.body.removeChild(a);

          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
      let file = new Blob([byteArray], { type: item.mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

  }

  clickCopyHeader() {
    let firstlinestring: string = "";
    let secondlinestring: string = "";
    // registration
    firstlinestring = "Registration"; // 12 characters
    secondlinestring = this.registration;
    if (this.registration.length > 12) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.registration, 12);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.registration, 12);
    }
    // registration end
    // trip code
    firstlinestring += "Trip Code"; // 9
    secondlinestring += this.tripCode;
    if (this.tripCode.length > 9) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.tripCode, 9);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.tripCode, 9);
    }
    // trip code end
    // business stop
    firstlinestring += "Business Stop"; // 13
    let bs: string = "";
    if (this.prevICAO != "") {
      bs = this.prevICAO + "-";
    }
    bs += this.icao;
    if (this.nextICAO != "") {
      bs += "-" + this.nextICAO;
    }
    secondlinestring += bs;
    if (bs.length > 13) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, bs, 13);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, bs, 13);
    }
    // business stop end
    // arrival
    let arr1stLineCharacterNumber = firstlinestring.length;
    let arr2ndLineCharacterNumber = secondlinestring.length;
    if (this.arrivalDateTime != "") {
      firstlinestring += "Arrival(" + this.icao + ")"; // 13
      let at: string = this.arrivalDateTime;
      secondlinestring += at;
      if (at.length > 13) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, at, 13);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, at, 13);
      }
    }
    let arr1stLineCharacterNumber2 = firstlinestring.length;
    let arr2ndLineCharacterNumber2 = secondlinestring.length;
    // arrival end
    // departure
    let dep1stLineCharacterNumber = firstlinestring.length;
    let dep2ndLineCharacterNumber = secondlinestring.length;
    if (this.departureDateTime != "") {
      firstlinestring += "Departure(" + this.icao + ")"; // 15
      let dt: string = this.departureDateTime;
      secondlinestring += dt;
      if (dt.length > 15) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, dt, 15);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, dt, 15);
      }
    }
    // departure
    // aircraft info
    firstlinestring += this.aircraftManufacturer + " " + this.aircraftModel;
    secondlinestring += this.aircraftInfo;
    // aircraft info end
    let thirdEmptySpaceNum = 0;
    let thirdLineString = "";
    if (this.arrivalDateTimeLocal != "") {
      if (arr1stLineCharacterNumber > arr2ndLineCharacterNumber) {
        thirdEmptySpaceNum = arr1stLineCharacterNumber;
      }
      else {
        thirdEmptySpaceNum = arr2ndLineCharacterNumber;
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.arrivalDateTimeLocal;
    }
    if (this.departureDateTimeLocal != "") {
      if (this.arrivalDateTime != "") {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber - arr1stLineCharacterNumber2 + 2;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber - arr2ndLineCharacterNumber2 + 2;
        }
      }
      else {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber;
        }
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.departureDateTimeLocal;
    }

    let s: string;
    s = firstlinestring + "\n" + secondlinestring;
    if (thirdLineString != "") {
      s += "\n" + thirdLineString;
    }
    return s;

  }

  getFirstLineString(firstLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (localString.length - headerCharacterNumber); i++) {
      firstLinesString += " ";
    }
    firstLinesString += "  ";
    return firstLinesString;
  }

  getSecondLineString(secondLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (headerCharacterNumber - localString.length); i++) {
      secondLinesString += " ";
    }
    secondLinesString += "  ";
    return secondLinesString;
  }

  clickClose() {
    this._dialogRef.close();
  }

}
