import { TripLegModel } from "./trip-leg.model";

export class LinkMessageLegList{
  departure: string;
  arrival: string;  
  arrivalDateTime: string;
  departureDateTime: string;
  departureDateTextUTC:string;
  nextArrivalDateTextUTC:string;
  groundStopGUID: string;
  nextGroundStopGUID: string;
  colorCode: string;
  nextColorCode: string;
  arrivalServiceList:LinkMessageServiceList[]=[];
  departureServiceList:LinkMessageServiceList[]=[];
  arrivalDateTimePassed: boolean;
  isActiveLeg:boolean;
  messageCount: number;
}

export class LinkMessageServiceList  {
  serviceTypeID: number;
  serviceTypeDescription: string;
  serviceLocation: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  selected: boolean;

  countryName: string;
  confirmation: string;
  vendorName: string;
  taskStatusColor: string;

  expanded: boolean;
  
  filterGroup: number;
  filterColumn: number;
  filterOrderBy: number;
}
