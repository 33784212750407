<div style="background-color:#f8f8f8;height:100%">
  <div cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5>Homebase Airports</h5></div>
  </div>
  <div style="margin-top:0em">
    <form *ngIf="customer_homebase_edit_form" [formGroup]="customer_homebase_edit_form" (ngSubmit)="clickSave()">
      <div style="margin-left: .5em;margin-top:0em;margin-right:0.5em;">
        <div style="background-color:white;">
          <div style="display:inline-flex;margin-top:0em; padding-left:0.5em;padding-right:0.5em;">

            <div class="form-group" style="display: inline-flex; margin-top: 0em; margin-right: 0.625em">
              <label for="airport_search" style="font-size:x-small;text-align:right">Please enter ICAO <br />or IATA code</label>
              <div style="margin-top:.5em;margin-left:.5em">
                <input type="text" formControlName="airport_search" maxlength="4" class="form-control" style="padding: 0em;width:8em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
              </div>

            </div>
            <div style="margin-top:.35em"><button mat-button class="btn-high" (click)="clickSearchAirport()">Search</button></div>
          
          </div>
          
          <div class="p-3" *ngIf="airportList && airportList.length>0" style="margin-top:-0.75em;">
            <div>
              <table mat-table [dataSource]="airportList" class="example-container">
                <tr>
                  <ng-container matColumnDef="icao">
                    <th mat-header-cell *matHeaderCellDef>
                      ICAO
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.icao}}</td>
                  </ng-container>
                  <ng-container matColumnDef="iata">
                    <th mat-header-cell *matHeaderCellDef>
                      IATA
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.iata}}</td>
                  </ng-container>
                  <ng-container matColumnDef="airport_name">
                    <th mat-header-cell *matHeaderCellDef>
                      Name
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.airportName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="airport_location">
                    <th mat-header-cell *matHeaderCellDef>
                      Location
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.airportLocation}}</td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                      Action
                    </th>
                    <td mat-cell *matCellDef="let element; let i=index ">
                      <a mat-button class="btn-high grid" (click)="clickSave($event, element);">
                        Select
                      </a>
                    </td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>
            </div>
            <div>
              <mat-paginator style="border: none;" #paginator *ngIf="airportList && totalPageNumber>1" [length]="totalRecords" [pageSize]="pageSize"
                             [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
              </mat-paginator>
            </div>
          </div>
        </div>
        <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; white-space:pre-wrap"><label style="color:red; font-size: small">{{errMsg}}</label></div>

        <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>

      </div>
      
    </form>
    <div class="d-inline-flex" style="align-content:baseline; justify-content:flex-end;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;height:3em">
      <div>
        <button mat-button class="btn-low" (click)="clickClose()" >Close</button>
      </div>
      <div class="ml-2" *ngIf="showSuccessMsg">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
    </div>
  </div>



</div>
<app-spin *ngIf="showSpin"></app-spin>





