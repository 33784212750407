import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AirportModel } from '../models/airport.model';
import { ResponseModel } from '../models/response.model';
import { VendorRoleModel } from '../models/vendor-roles.model';
import { VendorModel } from '../models/vendor.model';
import { AuthenticateService } from '../services/authenticate.service';
import { VendorService } from '../services/vendor.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

interface VendorNode {
  name: string;
  Id: Number;
  parentId: Number;
  vendorGUID: string;
  icao: string;
  groundHandler: string;
  intoPlaneAgent: string;
  mainVendorName: string;
  relatedVendorIDList: string;
  relatedVendorList: string;
  preferred: boolean;
  children?: VendorNode[];
  iata: string;

}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  id: number;
  vendorGUID: string;
  icao: string;
  groundHandler: string;
  intoPlaneAgent: string;
  mainVendorName: string;
  relatedVendorIDList: string;
  relatedVendorList: string;
  preferred: boolean;
  level: number;
  iata: string;
}

@Component({
    selector: 'app-vendor-locator',
  templateUrl: './vendor-locator.component.html',
  styleUrls: ['./vendor-locator.component.css']
})
/** airport-locator component*/
export class VendorLocatorComponent implements OnInit {
  isLoading: boolean = false;
  vendorID: string = "";
  vendor_locator_form: UntypedFormGroup;
  searchType: string = "airport";
  vendorList: VendorModel[];
  vendorDetails: VendorModel;
  errMsg: string="";
  submitted: boolean = false;
  icao: string = "";
  iata: string = "";
  vendorRoles: VendorRoleModel[];
  TREE_DATA: VendorNode[];
  TREE_DATA_child: VendorNode[];
  childData: VendorNode[];
  private _transformer = (node: VendorNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      id: node.Id,
      vendorGUID: node.vendorGUID,
      icao: node.icao,
      iata: node.iata,
      groundHandler: node.groundHandler,
      intoPlaneAgent: node.intoPlaneAgent,
      mainVendorName: node.mainVendorName,
      relatedVendorIDList: node.relatedVendorIDList,
      relatedVendorList: node.relatedVendorList,
      preferred:node.preferred,
      level: level,
    };
  }
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);
  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
 
  constructor(private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _vendorService: VendorService) {

  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;


    ngOnInit() {
      this.initControls();
  }

  
 treeConstruct(treeData) {
  let constructedTree = [];
  for (let i of treeData) {
    let treeObj = i;
    let assigned = false;
    this.constructTree(constructedTree, treeObj, assigned)
  }
  return constructedTree;
}
constructTree(constructedTree, treeObj, assigned) {
  if (treeObj.parentId == null) {
    treeObj.children = [];
    constructedTree.push(treeObj);
    return true;
  } else if (treeObj.parentId == constructedTree.Id) {
    //treeObj.children = [];
    constructedTree.children.push(treeObj);
    return true;
  }
  else {
    if (constructedTree.children != undefined) {
      for (let index = 0; index < constructedTree.children.length; index++) {
        let constructedObj = constructedTree.children[index];
        if (assigned == false) {
          assigned = this.constructTree(constructedObj, treeObj, assigned);
        }
      }
    } else {
      for (let index = 0; index < constructedTree.length; index++) {
        let constructedObj = constructedTree[index];
        if (assigned == false) {
          assigned = this.constructTree(constructedObj, treeObj, assigned);
        }
      }
    }
    return false;
  }
}
   
  treeConstructChild(treeData) {
    let constructedTree = [];
    for (let i of treeData) {
      let treeObj = i;
      let assigned = false;
      this.constructTreeChild(constructedTree, treeObj, assigned)
    }
    return constructedTree;
  }
  constructTreeChild(constructedTree, treeObj, assigned) {
    if (treeObj.mainVendorName == null || treeObj.mainVendorName=='') {
      treeObj.children = [];
      constructedTree.push(treeObj);
      return true;
    } else if (treeObj.mainVendorName == constructedTree.name && treeObj.parentId == constructedTree.parentId && treeObj.icao == constructedTree.icao) {
      treeObj.children = [];
      constructedTree.children.push(treeObj);
      return true;
    }
    else {
      if (constructedTree.children != undefined) {
        for (let index = 0; index < constructedTree.children.length; index++) {
          let constructedObj = constructedTree.children[index];
          if (assigned == false) {
            assigned = this.constructTreeChild(constructedObj, treeObj, assigned);
          }
        }
      } else {
        for (let index = 0; index < constructedTree.length; index++) {
          let constructedObj = constructedTree[index];
          if (assigned == false) {
            assigned = this.constructTreeChild(constructedObj, treeObj, assigned);
          }
        }
      }
      return false;
    }
  }


  initControls() {
    this.vendor_locator_form = this._formBuilder.group({
      search_by:['',Validators.required]

    });
  }

  get f() { return this.vendor_locator_form.controls; }

  getVendorList() {
    this.submitted = true;
    this.errMsg = "";
    this.vendorID = "";
    this.TREE_DATA = [];
    this.TREE_DATA_child = [];
    if (this.vendor_locator_form.valid) {
      this.isLoading = true;
      this.vendorRoles = [];
      let request = new VendorModel();
      request.searchKey = this.f.search_by.value;
      request.searchType = this.searchType;
      this._vendorService.getVendorBySearchKey<ResponseModel<VendorModel[]>>(request).subscribe(response => {
        if (response != null) { 
          if (response.code == "200" && response.message == "") {
            if (response.model.length == 0) {
              this.errMsg = "No results found";
              this.isLoading = false;
              return;
            }
            this.vendorList = response.model;
            let roles = this.vendorList.filter((n, i, arr) => arr.findIndex(c => c.vendorRoleDescription === n.vendorRoleDescription) === i);
            roles.forEach(v => {
              let vendorRole = this.vendorList.filter(x => x.vendorRoleID == v.vendorRoleID);
              this.vendorRoles.push({ vendorRole: v.vendorRoleDescription,vendorRoleID:v.vendorRoleID, iconName:v.iconName, vendor:vendorRole, selected:false  })
            });
            this.vendorRoles.forEach(v => {
              this.TREE_DATA.push({ name: v.vendorRole, Id: v.vendorRoleID, vendorGUID:null, parentId: null, mainVendorName:null, relatedVendorIDList:null, relatedVendorList:null, icao:null,iata:null,groundHandler:null,intoPlaneAgent:null,preferred:null });
              v.vendor.forEach(ven => {                
                this.TREE_DATA_child.push({ name: ven.vendorName, Id: ven.vendorID, vendorGUID:ven.vendorGUID, parentId: v.vendorRoleID, mainVendorName: ven.mainVendorName, relatedVendorIDList: ven.relatedVendorIDList, relatedVendorList: ven.relatedVendorList, icao: ven.icao,iata: ven.iata, groundHandler: ven.groundHandler, intoPlaneAgent: ven.intoPlaneAgent,preferred:ven.isPreferred });
              });
              this.childData = [];
              this.childData = this.treeConstructChild(this.TREE_DATA_child);
              this.TREE_DATA.concat(this.childData);
            });
            let vendors = this.TREE_DATA.filter((n, i, arr) => arr.findIndex(c => c.mainVendorName === n.mainVendorName) === i);
            var nodes: VendorNode[]=[]
            vendors.forEach(v => {
              if (v.mainVendorName != null && v.mainVendorName != '') {
                nodes.push
              }
            });
            let myData = this.treeConstruct(this.TREE_DATA.concat(this.childData));
           // console.log(myData);
            this.dataSource.data = myData;
            if (this.vendorList.length == 0)
              this.errMsg = "No results found";
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
          }
        }
        else
          this.errMsg = "No results found";
        this.isLoading = false;
      });
      this.submitted = false;
    }
    
  }
  
  selectVendor(v: any) {
    let vendor = this.vendorList.find(x => x.vendorGUID == v.vendorGUID);
    this.vendorID = vendor.vendorGUID;
    this.icao = vendor.icao;
    this.iata = vendor.iata;
    //this._vendorService.getVendorDetailsByVendorID<ResponseModel<VendorModel>>(this.vendorID).subscribe(response => {
    //  if (response != null) {
    //    if (response.code == "200" && response.message == "") {
    //      this.vendorDetails = response.model;
    //    }
    //    else {
    //      if (response.code == "401")
    //        this._authService.signOut();
    //    }
    //  }
    //});
  }

}
