import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { UserModel } from '../models/user.model';
import { AuthenticateService } from '../services/authenticate.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { CustomerModel } from '../models/customer.model';
import { VendorModel } from '../models/vendor.model';
import { Observable, forkJoin, of } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { CommonService } from '../services/common.service';
import { ResponseModel } from '../models/response.model';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
//import { UserPermissionAdminEditDialogComponent } from './user-permission-admin-edit-dialog.component';
import { DialogService } from '../services/dialog.service';
import { UserPermissionClientEditDialogComponent } from './user-permission-client-edit-dialog.component';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CustomerGroupService } from '../services/customer-group-service';
import { AdminServicesClientComponent } from './admin-services-client.component';

@Component({
  selector: 'app-user-permission-client',
  templateUrl: './user-permission-client.component.html',
  styleUrls: ['./user-permission-client.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ]
})

export class UserPermissionClientComponent implements OnInit, AfterViewInit {
  user_permission_client_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  user: UserModel;
  errMsg: string;
  msg: string;
  customerList: CustomerModel[];
  userList: UserModel[];
  userType: string;
  selectedUserType: string;
  selectedUserTypeDisplay: string="";
  textSearchBy: string;
  showSpin: boolean = false;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 10; //20;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  showPage: boolean = false;
  includeDisabledRecords: boolean;
  displayedColumn: string[] = ['username', 'name', 'email', 'account_status', 'admin_access', 'registration', 'pic', 'pax', 'effectiveDate', 'expirationDate', 'grantAdmin', 'grantManifest', 'grantCompany', 'grantAircraft', 'grantSubmitTrip', 'grantMessage', 'grantAllMessage', 'grantCompanyEvent', 'grantInvoice', 'grantPII', 'grantGrp', 'locked', 'disabled', 'action'];
  displayedColumn2: string[] = ['members_list', 'trip_access_filter', 'eeDate', 'restrict_access', 'blank']; // 5, 3, 2, 10, 2
  scrHeight: number;
  ua: number;
  grantAccessToAccountGrouping: boolean = false;
  customerGUID: string = "";
  showDisableCheckbox: boolean = true;
  hideColumn: boolean = false;
  isPopup: boolean = false;
  selectedCustomerName: string = "";
  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService, private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog, private readonly _dialogService: DialogService, private readonly _customerGroupService: CustomerGroupService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private readonly _dialogRef: MatDialogRef<UserPermissionClientComponent>) {

  }

  getAllData(): Observable<any[]> {
    let getCustomersResponse;
    if (this.userType == "internal") {
      getCustomersResponse = this._commonService.getCustomers<ResponseModel<CustomerModel[]>>();
      //let getVendorsResponse = this._commonService.getVendors<ResponseModel<VendorModel[]>>();     
    }
    else {
      if (this.userType == "customer") {
        if (this.grantAccessToAccountGrouping) {
          let req = new CustomerGroupModel();
          getCustomersResponse = this._customerGroupService.getSelectedCustomerListBycgrpId(req);
        }
        else {
          getCustomersResponse = of(null);
        }
      }
      else {
        getCustomersResponse = of(null);
      }
    }
    return forkJoin([getCustomersResponse]);
  }

  ngOnInit() {
    if(this.data) {
      this.isPopup = true;
    }
    this._authService.updateAccessTime();
    this.ua = 0;// Number(localStorage.getItem('ua'));
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType != "internal") {
      this.hideColumn = true;
    }
    else {
      this.selectedUserTypeDisplay = "Client";
    }
    if (localStorage.getItem('up.isA') != null && localStorage.getItem('up.isA') != "") {
      if (localStorage.getItem('up.isA').toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
        this.ua = 1;
      }
    }
    if (this.ua != 1) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this._router.navigate(['/common-utility/blank']);
      return;
    }
    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
    this.customerList = [];
    this.userList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.scrHeight = window.innerHeight;
    if (this.scrHeight < 900) {
      this.pageSize = 10;
    }
    else {
      this.pageSize = 15;
    }
       
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerList = responses[0].model;
          if (this.customerList.length>0 && this.userType == "customer" && this.grantAccessToAccountGrouping) {
            if (this.customerGUID == "")
              this.customerGUID = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();            
          }
            
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      

      this.initControls();

      if(this.isPopup) {
        this.customerGUID = this.data.customerGUID;
        this.selectedCustomerName = this.customerList.filter(x => x.customerGUID === this.customerGUID)[0]?.customerName;
        this.customerChange(this.customerGUID);
      }
      else {
        this._route.queryParams.subscribe(params => {
          if(params["customerGuid"]) {
            this.customerGUID = params["customerGuid"];
            this.f.customer_select.setValue(this.customerGUID);
            this.customerChange(this.customerGUID);
          }
        });
      }
        
      if (this.userType == "internal") {
        this.selectedUserType = 'customer';
      }
      else {
        this.getSearchResults();
      }
        
      this.showSpin = false;

    });
    
  }

  initControls() {
    this.user_permission_client_form = this._formBuilder.group({
      customer_select: this.customerGUID,
      account_status: ['1'],
      admin_access: ['1'],
      username: [''],
      fl_name: [''],
      email: [''],
      text_search: [''],
      permission_type: ['Username'],
      includeDisabledRecords: this.includeDisabledRecords

    });

    this.textSearchBy = "Username"; // this.f.permission_type.options[this.f.permission_type.options.selectedIndex].text;;
    if (this.userType == "internal") {
      this.f.customer_select.setValue('');
    }
  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //  this.showSpin = false;
    //}, 100);

  }

  get f() { return this.user_permission_client_form.controls; }

  clickUserType(u) {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;

    if (this.selectedUserType != u) {
      this.userList = [];
      this.selectedUserType = u;
      this.f.customer_select.setValue('');
     
      if (this.selectedUserType == "internal" || this.selectedUserType == "vendor") {
        //this.displayedColumn = ['username', 'name', 'email', 'account_status', 'admin_access', 'crew_trip_detail', 'pax_trip_detail', 'trip_document', 'create_event', 'create_alert', 'company_document', 'aircraft_preference', 'crew_pax_data_maintenance', 'trip_messages', 'vendor_invoiceupload', 'disabled', 'action'];
        //this.displayedColumn2 = ['members_list', 'admin', 'restrict_access', 'blank'];
        //this.getSearchResults();
        if (this.selectedUserType == "vendor")
          this._router.navigate(['/user-accounts/user-permission-admin', { t: 3 }]);
        else
          this._router.navigate(['/user-accounts/user-permission-admin', { t: 1 }]);
      }
      else {
        //this.displayedColumn = ['username', 'name', 'email', 'account_status', 'admin_access', 'registration', 'pic', 'sic', 'pax', 'crew_trip_detail', 'pax_trip_detail', 'trip_document', 'create_event', 'create_alert', 'company_document', 'aircraft_preference', 'crew_pax_data_maintenance', 'trip_messages', 'vendor_invoiceupload', 'disabled', 'action'];
        //this.displayedColumn2 = ['members_list', 'admin', 'trip_access_filter', 'restrict_access', 'blank'];
        this.displayedColumn = ['username', 'name', 'email', 'account_status', 'admin_access', 'registration', 'pic', 'sic', 'pax', 'effectiveDate', 'expirationDate', 'grantAdmin', 'grantManifest', 'grantCompany', 'grantAircraft', 'grantSubmitTrip', 'grantMessage','grantAllMessage', 'grantCompanyEvent', 'grantInvoice', 'disabled', 'action'];
        this.displayedColumn2 = ['members_list', 'trip_access_filter', 'eeDate', 'restrict_access', 'blank']; // 5, 4, 2, 8, 2
      }
    }
  }

  includeDisabledRecordsOnChange(event: any) {
    //let x = event.target.checked;
    if (this.userType == "internal" && this.selectedUserType != "internal") {
      if (this.f.customer_select.value == "" && !this.isPopup) {
        this.errMsg = "Please select a client";
        return;
      }
    }
    this.userList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults();
  }

  clickShowAllUsers(e: any) {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;

    this._authService.updateAccessTime();
    if (this.userType == "internal" && this.selectedUserType != "internal") {
      if (this.f.customer_select.value == "" && !this.isPopup) {
        this.errMsg = "Please select a client";
        return;
      }
    }
    //this.f.text_search.setValue('');
    this.getSearchResults();

  }

  onSubmit() {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this._authService.updateAccessTime();;
    if (this.userType == "internal" && this.selectedUserType != "internal") {
      if (this.f.customer_select.value == "" && !this.isPopup) {
        this.errMsg = "Please select a client";
        return;
      }
    }
    //else {
    //if (this.f.username.value == "" && this.f.fl_name.value == "" && this.f.email.value == "" && this.f.text_search.value == "") {
    if (this.f.text_search.value == "") {
      this.errMsg = "Please enter a keyword.";
      return;
    }
    //}
    this.getSearchResults();
  }

  getSearchResults() {
    this.errMsg = "";
    this.msg = "";
    this.loading = true;
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.user = new UserModel();
    this.user.customerGuid = null;
    if(this.isPopup) {
      this.user.customerGuid = this.customerGUID;
    }
    else {
      if (this.userType == "internal" && this.selectedUserType != "internal")
        this.user.customerGuid = this.f.customer_select.value;
      else {
        if (this.userType == "customer" && this.grantAccessToAccountGrouping && this.customerList.length > 0) {
          this.user.customerGuid = this.f.customer_select.value;
        }
      }
    }
    
    //if (this.f.account_status.value == "1")
    //  this.user.isActive = true;
    //else
    //  this.user.isActive = false;
    this.user.isActive = true;
    if (this.f.includeDisabledRecords.value != null && this.f.includeDisabledRecords.value != "") {
      this.user.isActive = !this.f.includeDisabledRecords.value;
    }
    this.user.adminAccess = Number(this.f.admin_access.value);
    //this.user.username = this.f.username.value;
    //this.user.flName = this.f.fl_name.value;
    //this.user.email = this.f.email.value;
    this.user.textSearch = this.f.text_search.value;
    this.user.tripAccessFilter = this.f.permission_type.value;
    this.user.pageIndex = this.currentPageIndex;
    this.user.pageSize = this.pageSize;
    this.user.totalRecords = this.totalRecords;
    this._identityService.getUserPermissionByConditions<ResponseModel<UserModel[]>>(this.user).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.userList = response.model;
          if (this.userList[0].totalRecords > 0) {
            this.showDisableCheckbox = this.userList[0].showDisableCheckbox;
            if (this.userList[0].totalRecords == 1)
              this.totalRecordsText = this.userList[0].totalRecords.toString() + ' record';
            else
              this.totalRecordsText = this.userList[0].totalRecords.toString() + ' records';
            this.totalRecords = this.userList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
            this.userList.forEach(x => {
              if (x.isAB.toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
                x.isAdmin = true;
              }
              else {
                x.isAdmin = false;
              }
            });
          }

        }
        else {
          this.userList = [];
          if (this.f.text_search.value != "") {
            switch (this.f.permission_type.value) {
              case "Username":
                this.msg = "Username not found.";
                break;
              case "Email":
                this.msg = "Email address not found.";
                break;
              case "Registration":
                this.msg = "Aircraft registration not found.";
                break;
              case "PIC/SIC":
                this.msg = "PIC/SIC not found.";
                break;
              case "PAX":
                this.msg = "PAX not found.";
                break;
            }
          }
          else {
            this.msg = "No records found.";
          }
          //this.msg = "No data return.";
        }
      }
      else {
        this.userList = [];
      }
      this.loading = false;
      this.showSpin = false;

    })

  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
    }
    this.getSearchResults();

  }

  customerChange(e: any) {
    this.errMsg = "";
    this.msg = "";
    this.userList = [];
    this.totalRecordsText = "";
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    if (!this.isPopup && this.f.customer_select.value == "") {
      this.errMsg = "please select a client.";
      return;
    }
    this.getSearchResults();
  }

  permission_typeChange(e: any) {
    let text = e.target.options[e.target.options.selectedIndex].text;
    this.textSearchBy = text;
    //this.f.text_search.setValue('');
  }

  clickEdit(e: any, item: UserModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    //if (item.userType.toLocaleLowerCase() != 'internal') {
      dialogConfig.data = { tabName: 'registration', userId: item.userId, customerGuid: item.customerGuid, userType: item.userType.toLocaleLowerCase() };

    //}
    //else {
    //  dialogConfig.data = { tabName: 'restrict', userId: item.userId, customerGuid: item.customerGuid, userType: item.userType.toLocaleLowerCase() };
    //}

    const dialogRef = this._dialog.open(UserPermissionClientEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSearchResults();
      }
    });

  }

  clickReset() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.initControls();
    this.userList = [];
    this.totalRecordsText = "";
    this.totalRecords = 0;
    this.textSearchBy = "Username";
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;

    if(this.isPopup) {
      this.getSearchResults();
    }
  }

  clickClose(val) {
    this._dialogRef.close(val);
  }

  openAdminServicesComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.minWidth = "76em";
    //config.maxWidth = "92em";//"1000px";
    config.height = "max-content";
    config.width = "max-content";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "10px", top: "140px" };
    config.data = { gsId: "", tripCodeId: "", isDialog: true, v: this._authService.getCurrentTimeNumber(), 
      customerGUID: this.customerGUID, customerName: this.selectedCustomerName, userType: this.data.userType };

    this.clickClose(true);

    this._dialog.open(AdminServicesClientComponent, config);
  }

}
